import type { Pattern } from './pattern.interface';
import type { TaskGroup } from './task-group.interface';

export interface cv_Frequency {
    C_Frequency_key: number;
    C_Pattern_key: number | null;
    C_VocabularySource_key: number | null;
    C_Workgroup_key: number;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    Description: string;
    ElementID: string;
    Frequency: string;
    IsActive: boolean;
    IsDefault: boolean;
    IsDeprecated: boolean;
    ModifiedBy: string;
    Pattern: Pattern;
    SortOrder: number | null;
    TaskGroup: TaskGroup[];
    ToEndTaskGroup: TaskGroup[];
    Version: number;
}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { crossBig } from '@icons';
import { buildId, supportEmail } from '@config';
import { PopoverComponent } from '@common/components/popover/popover.component';
import * as platform from 'platform';
import { CurrentWorkgroupService } from '@services/current-workgroup.service';
import { LoggingService } from '@services/logging.service';
import { UserService } from '../../../user/user.service';
import { EmailService, MessagePayload } from '@services/email.service';
import { CLIMB_SURFACE_MODE } from '@common/tokens';
import { formatDataAutomationId } from '@common/util/format-data-automation-id';

@Component({
    selector: 'climb-contact-form',
    templateUrl: 'contact-form.component.html',
    styleUrls: ['contact-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [{ provide: CLIMB_SURFACE_MODE, useValue: 'light' }],
})
export class ContactFormComponent {
    @ViewChild('popover') popover: PopoverComponent;

    message = '';
    readonly MESSAGE_LENGTH_MAX = 10000;
    readonly EMAIL_TO: string = supportEmail;
    readonly SUBJECT_PREFIX = 'Climb.Web Feedback from ';

    readonly SUCCESS_MESSAGE = 'Message sent! Thank you for your feedback.';
    readonly ERROR_MESSAGE = 'Our apologies: we were unable to send your message.';
    readonly NO_CONTENT_MESSAGE = 'Please provide a message.';
    readonly DATA_AUTOMATION_ID = "message-sent-feedback";

    icons = { crossBig };
    loading = false;
    readonly LOG_TAG = 'climb-contact-form';

    constructor(
        private currentWorkgroupService: CurrentWorkgroupService,
        private loggingService: LoggingService,
        private userService: UserService,
        private changeDetectorRef: ChangeDetectorRef,
        private emailService: EmailService
    ) {}

    async sendMessage() {
        this.loading = true;
        const user = await this.userService.getThisUser();
        const messagePayload = this.getMessagePayload(user);

        try {
            await this.emailService.sendMessage(messagePayload);
            this.loggingService.logSuccess(
                this.SUCCESS_MESSAGE, '', this.LOG_TAG, true
            ).attr("data-automation-id", formatDataAutomationId(this.DATA_AUTOMATION_ID, "", "-text"));
        } catch (error) {
            this.loggingService.logError(
                this.ERROR_MESSAGE, error, this.LOG_TAG, true
            );
        } finally {
            this.popover.close();
            this.loading = false;
            this.message = '';
            this.changeDetectorRef.markForCheck();

        }
    }

    getMessagePayload(user: any): MessagePayload {
        return {
            Recipient: this.EMAIL_TO,
            Subject: this.getSubject(user),
            HtmlContent: this.getHtmlContent(user),
            PlaintextContent: this.getPlaintextContent(user)
        };
    }

    private getSubject(user: any): string {
        const userFullName = this.getUserFullName(user);

        return this.SUBJECT_PREFIX + userFullName;
    }

    private getHtmlContent(user: any): string {
        let body = '<div style="font-family: Verdana, Arial, sans-serif;">';

        // User info
        const userFullName = this.getUserFullName(user);
        body += '<br/><b>User:</b> ' + userFullName;

        const userEmail = this.getUserEmail(user);
        if (userEmail) {
            body += '<br/><b>Email:</b> ' + userEmail;
        }

        // App info
        body += '<br/><b>Version:</b> ' + buildId;
        body += '<br/><b>Workgroup:</b> ' + this.currentWorkgroupService.getWorkgroupName();

        // Client info
        body += '<br/><b>Browser/OS:</b> ' + platform.description;
        body += '<br/><b>User Agent:</b> ' + navigator.userAgent;

        // Message (limit length)
        body += '<br/><b>Message:</b> '
            + this.message.substring(0, this.MESSAGE_LENGTH_MAX);

        body += '</div>';

        return body;
    }

    private getPlaintextContent(user: any): string {
        const userFullName = this.getUserFullName(user);
        const userEmail = this.getUserEmail(user);

        const body =
            `User: ${userFullName}\n` +
            `Email: ${userEmail}\n` +
            `Verson: ${buildId}\n` +
            `Workgroup: ${this.currentWorkgroupService.getWorkgroupName()}\n` +
            `Browser/OS: ${platform.description}\n` +
            `User Agent: ${navigator.userAgent}\n` +
            `Message: ${this.message.substring(0, this.MESSAGE_LENGTH_MAX)}\n`;

        return body;
    }

    private getUserFullName(user: any): string {
        return user.FirstName + ' ' + user.LastName;
    }

    private getUserEmail(user: any): string {
        if (user.UserContactEmailAddresses.length > 0) {
            return user.UserContactEmailAddresses[0].EmailAddress;
        } else {
            return '';
        }
    }
}

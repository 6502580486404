import {
    Component,
    EventEmitter,
    OnInit,
    OnDestroy,
    Input,
    SimpleChanges,
    Output,
} from '@angular/core';

import { AnimalService } from '../../animals/services/animal.service';
import { ClinicalService } from '../clinical.service';
import { ClinicalVocabService } from '../clinical-vocab.service';
import { SettingService } from '../../settings/setting.service';

import {
    focusElementByQuery,
    notEmpty,
    testBreezeIsDeleted,
} from '../../common/util';
import { DataContextService } from '../../services/data-context.service';
import { OnChanges } from '@angular/core';

@Component({
    selector: 'new-health-record',
    templateUrl: './new-health-record.component.html',
    styles: [`
        .btn-container {
            margin-top: 0.7em;
            text-align: right;
        }
    `]
})
export class NewHealthRecordComponent implements OnInit, OnChanges, OnDestroy {
    @Input() taskData: any; // Used to sync process
    @Input() isSyncItem: boolean;
    @Output() create: EventEmitter<any> = new EventEmitter<any>();
    @Output() cancel: EventEmitter<any> = new EventEmitter<any>();

    // vocabularies
    animalStatuses: any[];
    resources: any[];
    healthRecord: any;
    bodyConditionScores: any[];

    // CV defaults
    defaultAnimalStatusKey: number = null;
    animalStatusChangedToDefault = false;
    defaultHealthTechResourceKey: number = null;
    healthTechChangedToDefault = false;
    defaultBodyConditionScoreKey: number = null;
    bodyConditionScoreChangedToDefault = false;

    animalKey: number;
    constructor(
        private animalService: AnimalService,
        private clinicalService: ClinicalService,
        private clinicalVocabService: ClinicalVocabService,
        private dataContextService: DataContextService,
        private settingService: SettingService,
    ) { }

    // lifecycle
    ngOnInit() {
        this.initialize();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.isSyncItem && changes.taskData && !changes.taskData.firstChange) {
            if (changes.taskData.currentValue.materialKey === changes.taskData.previousValue.materialKey) {
                return;
            }
            this.setupSyncItem();
        } else if (!this.isSyncItem && this.taskData) {
            // For workflow Add Clinical Record button
            this.setupSyncItem();
        }
    }

    ngOnDestroy() {
        this.resetHealthRecord();
    }

    async initialize() {
        await this.getCVs();
        if (this.isSyncItem) {
            this.setupSyncItem();
        } else {
            focusElementByQuery('[data-auto-focus=clinical-animal] input');
        }
    }

    private getCVs(): Promise<any> {
        this.clinicalVocabService.animalStatuses$.subscribe((animalStatuses) => {
            this.animalStatuses = animalStatuses;
            this.getDefaultAnimalStatus();
        });

        this.clinicalVocabService.resources$.subscribe((resources) => {
            this.resources = resources;
            this.getDefaultHealthTechResource();
        });

        this.clinicalVocabService.bodyConditionScores$.subscribe((bodyConditionScores) => {
            this.bodyConditionScores = bodyConditionScores;
            this.getDefaultBodyConditionScore();
        });

        return Promise.resolve();
    }

    private getDefaultAnimalStatus() {
        this.defaultAnimalStatusKey = null;

        if (notEmpty(this.animalStatuses)) {
            for (const item of this.animalStatuses) {
                if (item.IsDefaultHealthRecord === true) {
                    this.defaultAnimalStatusKey = item.C_AnimalStatus_key;
                    break;
                }
            }
        }
    }

    private getDefaultHealthTechResource(): Promise<any> {
        this.defaultHealthTechResourceKey = null;

        return this.settingService.getDefaultHealthTechSetting().then((data: any) => {
            if (data) {
                this.defaultHealthTechResourceKey = data.C_DefaultHealthTechnician_key;
            }
        });
    }

    private getDefaultBodyConditionScore() {
        this.defaultBodyConditionScoreKey = null;
        if (notEmpty(this.bodyConditionScores)) {
            for (const item of this.bodyConditionScores) {
                if (item.IsDefault === true) {
                    this.defaultBodyConditionScoreKey = item.C_BodyConditionScore_key;
                    break;
                }
            }
        }
    }

    createOrFetchHealthRecord(animalKey: any): Promise<any> {
        // check if there is a health record for this animal
        // ...need the schema...
        return this.clinicalService.getHealthRecord(animalKey)
            .then((healthRecord: any) => {
                if (healthRecord) {
                    this.setAnimalStatusToDefaultHealthRecord(healthRecord);
                    this.setHealthTechToDefault(healthRecord);
                    this.setBodyConditionScoreToDefaultHealthRecord(healthRecord);

                    return healthRecord;
                }
                return this.createNewHealthRecord(animalKey);
            });
    }

    createNewHealthRecord(animalKey: any): Promise<any> {
        // load original Animal record
        return this.animalService.getAnimal(animalKey)
            .then((animalRecord: any) => {
                const initialValues = {
                    C_Material_key: animalRecord.C_Material_key
                };
                const healthRecord = this.clinicalService.createHealthRecord(initialValues);
                this.setAnimalStatusToDefaultHealthRecord(healthRecord);
                this.setHealthTechToDefault(healthRecord);
                this.setBodyConditionScoreToDefaultHealthRecord(healthRecord);
                return healthRecord;
            });
    }

    private async setAnimalStatusToDefaultHealthRecord(healthRecord: any) {
        if (this.defaultAnimalStatusKey === null) {
            await this.getDefaultAnimalStatus();
        }
        if (healthRecord &&
            healthRecord.Animal &&
            this.defaultAnimalStatusKey !== null &&
            this.defaultAnimalStatusKey !== healthRecord.Animal.C_AnimalStatus_key
        ) {
            healthRecord.Animal.C_AnimalStatus_key = this.defaultAnimalStatusKey;
            this.animalStatusChanged(healthRecord.Animal);
            this.animalStatusChangedToDefault = true;
        }
    }

    private async setHealthTechToDefault(healthRecord: any) {
        if (this.defaultHealthTechResourceKey === null) {
            await this.getDefaultHealthTechResource();
        }
        if (healthRecord &&
            this.defaultHealthTechResourceKey !== null &&
            this.defaultHealthTechResourceKey !== healthRecord.C_Resource_key
        ) {
            healthRecord.C_Resource_key = this.defaultHealthTechResourceKey;
            this.healthTechChangedToDefault = true;
        }
    }

    async setBodyConditionScoreToDefaultHealthRecord(healthRecord: any) {
        if (this.defaultBodyConditionScoreKey === null) {
            await this.getDefaultBodyConditionScore();
        }
        if (healthRecord &&
            healthRecord.Animal &&
            this.defaultBodyConditionScoreKey !== null &&
            this.defaultBodyConditionScoreKey !== healthRecord.Animal.C_BodyConditionScore_key
        ) {
            healthRecord.Animal.C_BodyConditionScore_key = this.defaultBodyConditionScoreKey;
            this.bodyConditionScoreChangedToDefault = true;
        }
    }

    selectAnimal() {
        this.resetHealthRecord();
        this.animalStatusChangedToDefault = false;
        this.healthTechChangedToDefault = false;
        this.bodyConditionScoreChangedToDefault = false;

        if (this.animalKey) {
            const promise = this.createOrFetchHealthRecord(this.animalKey);
            promise.then((newOrExistingHealthRecord: any) => {
                this.healthRecord = newOrExistingHealthRecord;
            });
        }
    }

    private resetHealthRecord() {
        if (!testBreezeIsDeleted(this.healthRecord)) {
            this.clinicalService.cancelHealthRecord(this.healthRecord);
        }
        this.healthRecord = null;
    }

    animalStatusChanged(animal: any) {
        this.animalService.statusChangePostProcess(animal);
    }

    // Exiting
    createHealthRecord() {
        this.saveAllChanges().then(() => {
            this.create.emit(this.healthRecord);
        });
    }

    private saveAllChanges(): Promise<any> {
        return this.dataContextService.saveEntities(['Animal', 'AnimalHealthRecord']);
    }

    cancelHealthRecord() {
        this.resetHealthRecord();

        this.cancel.emit();
    }

    private setupSyncItem() {
        this.resetHealthRecord();
        this.animalKey = this.taskData.materialKey;
        this.selectAnimal();
    }

    // <select> formatters
    animalStatusKeyFormatter = (value: any) => {
        return value.C_AnimalStatus_key;
    }
    animalStatusFormatter = (value: any) => {
        return value.AnimalStatus;
    }
    resourceKeyFormatter = (value: any) => {
        return value.C_Resource_key;
    }
    resourceNameFormatter = (value: any) => {
        return value.ResourceName;
    }
}

<form novalidate>
    <div class="modal-header justify-content-center">
        <h4 class="modal-title">
            Animal Audit Report
        </h4>
    </div>

    <div class="modal-body modal-body-tall">

        <loading-overlay *ngIf="loading"></loading-overlay>

        <h2>
            {{animal?.AnimalName}}
        </h2>

        <div class="audit-report-section">
            <table class="table audit-table">
                <thead>
                    <tr>
                        <th>Action</th>
                        <th>Modified Date</th>
                        <th>Modified By</th>
                        <th>ID</th>
                        <th>Name</th>
                        <th>{{'Line' | translate}}</th>
                        <th>Species</th>
                        <th>Status</th>
                        <th *ngIf="isGLP">Held For</th>
                        <th>Mating Status</th>
                        <th>Breeding Status</th>
                        <th *ngIf="isGLP">Classification</th>
                        <th>Owner</th>
                        <th>Use</th>
                        <th>Origin</th>
                        <th>Sex</th>
                        <th>IACUC Protocol</th>
                        <th>Generation</th>
                        <th>Diet</th>
                        <th>Shipment</th>
                        <th>Vendor</th>
                        <th>Order ID</th>
                        <th *ngIf="isGLP">CITES Number</th>
                        <th>Arrival Date</th>
                        <th>Birth Date</th>
                        <th>Exit Date</th>
                        <th>Exit Reason</th>
                        <th>Marker Type</th>
                        <th>Marker</th>
                        <th>Microchip ID</th>
                        <th>External ID</th>
                        <th *ngIf="isGLP">Alternate Physical ID</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let auditRow of auditData"
                        [ngClass]="{'current-value' : auditRow.IsCurrent, 'expired-value': !auditRow.IsCurrent}">
                        <td class="text-nowrap">
                            <update-type-cell [updateType]="auditRow.UpdateType"></update-type-cell>
                        </td>
                        <td>{{auditRow.ModifiedDate | formatUtcDateTime}}</td>
                        <td>{{auditRow.ModifiedBy | userFullName}}</td>
                        <td>{{auditRow.Identifier}}</td>
                        <td class="text-nowrap">{{auditRow.AnimalName}}</td>
                        <td class="text-nowrap">{{auditRow.LineName}}</td>
                        <td>{{auditRow.Species}}</td>
                        <td>{{auditRow.AnimalStatus}}</td>
                        <td *ngIf="isGLP">{{auditRow.HeldFor}}</td>
                        <td>{{auditRow.AnimalMatingStatus}}</td>
                        <td>{{auditRow.BreedingStatus}}</td>
                        <td *ngIf="isGLP">{{auditRow.AnimalClassification}}</td>
                        <td>{{auditRow.Owner}}</td>
                        <td>{{auditRow.AnimalUse}}</td>
                        <td>{{auditRow.MaterialOrigin}}</td>
                        <td>{{auditRow.Sex}}</td>
                        <td>{{auditRow.IACUCProtocol}}</td>
                        <td>{{auditRow.Generation}}</td>
                        <td>{{auditRow.Diet}}</td>
                        <td>{{auditRow.ShipmentID}}</td>
                        <td>{{auditRow.VendorID}}</td>
                        <td>{{auditRow.OrderID}}</td>
                        <td *ngIf="isGLP">{{auditRow.CITESNumber}}</td>
                        <td>{{auditRow.DateOrigin | formatUtcDateTime}}</td>
                        <td>{{auditRow.DateBorn | formatUtcDateTime}}</td>
                        <td>{{auditRow.DateExit | formatUtcDateTime}}</td>
                        <td>{{auditRow.ExitReason}}</td>
                        <td>{{auditRow.PhysicalMarkerType}}</td>
                        <td>{{auditRow.PhysicalMarker}}</td>
                        <td>{{auditRow.MicrochipIdentifier}}</td>
                        <td>{{auditRow.ExternalIdentifier}}</td>
                        <td *ngIf="isGLP">{{auditRow.AlternatePhysicalID}}</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="audit-report-section">
            <h3>{{'Jobs' | translate}}</h3>

            <p *ngIf="!(auditJobsData | notEmpty)"
               class="no-data-message">
                {{noDataMessage}}
            </p>

            <table class="table audit-table"
                   *ngIf="auditJobsData | notEmpty">
                <thead>
                    <tr>
                        <th>Action</th>
                        <th>Modified Date</th>
                        <th>Modified By</th>
                        <th>Name</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let auditRow of auditJobsData"
                        [ngClass]="{'current-value' : auditRow.IsCurrent, 'expired-value': !auditRow.IsCurrent}">
                        <td class="text-nowrap">
                            <update-type-cell [updateType]="auditRow.UpdateType"></update-type-cell>
                        </td>
                        <td>{{auditRow.ModifiedDate | formatUtcDateTime}}</td>
                        <td>{{auditRow.ModifiedBy | userFullName}}</td>
                        <td>{{auditRow.JobID}}</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="audit-report-section">
            <h3>Genotypes</h3>

            <p *ngIf="!(auditGenotypesData | notEmpty)"
               class="no-data-message">
                {{noDataMessage}}
            </p>

            <table class="table audit-table"
                   *ngIf="auditGenotypesData | notEmpty">
                <thead>
                    <tr>
                        <th>Action</th>
                        <th>Modified Date</th>
                        <th>Modified By</th>
                        <th>Genotype</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let auditRow of auditGenotypesData"
                        [ngClass]="{'current-value' : auditRow.IsCurrent, 'expired-value': !auditRow.IsCurrent}">
                        <td class="text-nowrap">
                            <update-type-cell [updateType]="auditRow.UpdateType"></update-type-cell>
                        </td>
                        <td>{{auditRow.ModifiedDate | formatUtcDateTime}}</td>
                        <td>{{auditRow.ModifiedBy | userFullName}}</td>
                        <td>{{auditRow.Genotype}}</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="audit-report-section">
            <h3>Clinical Observations</h3>

            <p *ngIf="!(auditClinicalObservationsData | notEmpty)"
               class="no-data-message">
                {{noDataMessage}}
            </p>

            <table class="table audit-table"
                   *ngIf="auditClinicalObservationsData | notEmpty">
                <thead>
                    <tr>
                        <th>Action</th>
                        <th>Modified Date</th>
                        <th>Modified By</th>
                        <th>Status</th>
                        <th>Date</th>
                        <th>By</th>
                        <th>Observations</th>
                        <th>Comments</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let auditRow of auditClinicalObservationsData"
                        [ngClass]="{'current-value' : auditRow.IsCurrent, 'expired-value': !auditRow.IsCurrent}">
                        <td class="text-nowrap">
                            <update-type-cell [updateType]="auditRow.UpdateType"></update-type-cell>
                        </td>
                        <td>{{auditRow.ModifiedDate | formatUtcDateTime}}</td>
                        <td>{{auditRow.ModifiedBy | userFullName}}</td>
                        <td>{{auditRow.ClinicalObservationStatus}}</td>
                        <td>{{auditRow.DateObserved | formatUtcDateTime}}</td>
                        <td>{{auditRow.ObservedBy}}</td>
                        <td>
                            <table class="table table-sm audit-table"
                                   *ngIf="auditClinicalObservationsData | notEmpty">
                                <thead>
                                    <tr>
                                        <th>Action</th>
                                        <th>Modified Date</th>
                                        <th>Modified By</th>
                                        <th>Observation</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let auditRowDetail of auditRow.ClinicalObservationDetails"
                                        [ngClass]="{'current-value' : auditRowDetail.IsCurrent, 'expired-value': !auditRowDetail.IsCurrent}">
                                        <td class="text-nowrap">
                                            <update-type-cell [updateType]="auditRowDetail.UpdateType"></update-type-cell>
                                        </td>
                                        <td>{{auditRowDetail.ModifiedDate | formatUtcDateTime}}</td>
                                        <td>{{auditRowDetail.ModifiedBy | userFullName}}</td>
                                        <td>{{auditRowDetail.ClinicalObservation}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                        <td class="long-text">{{auditRow.Comments}}</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="audit-report-section">
            <h3>Tasks</h3>

            <p *ngIf="!(auditCurrentTasksData | notEmpty)"
               class="no-data-message">
                {{noDataMessage}}
            </p>

            <table class="table audit-table"
                   *ngIf="auditCurrentTasksData | notEmpty">
                <thead>
                    <tr>
                        <th>Action</th>
                        <th>Modified Date</th>
                        <th>Modified By</th>
                        <th>Task Name</th>
                        <th>Task</th>
                        <th>Sequence</th>
                        <th>Due Date</th>
                        <th>Deviation</th>
                        <th>Task Location</th>
                        <th>Complete Date</th>
                        <th>Protocol</th>
                        <th>Status</th>
                        <th>Assigned To</th>
                        <th>Cost</th>
                        <th>Duration</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let auditRow of auditCurrentTasksData"
                        [ngClass]="{'current-value' : auditRow.IsCurrent, 'expired-value': !auditRow.IsCurrent}">
                        <td class="text-nowrap">
                            <update-type-cell [updateType]="auditRow.UpdateType"></update-type-cell>
                        </td>
                        <td>{{auditRow.ModifiedDate | formatUtcDateTime}}</td>
                        <td>{{auditRow.ModifiedBy | userFullName}}</td>
                        <td class="text-nowrap">{{auditRow.TaskAlias}}</td>
                        <td class="text-nowrap">{{auditRow.TaskName}}</td>
                        <td class="num-cell">{{auditRow.Sequence}}</td>
                        <td>{{auditRow.Deviation}}</td>
                        <td>{{auditRow.CurrentLocationPath}}</td>
                        <td>{{auditRow.DateDue | formatUtcDateTime}}</td>
                        <td>{{auditRow.DateComplete | formatUtcDateTime}}</td>
                        <td>{{auditRow.ProtocolName}}</td>
                        <td>{{auditRow.TaskStatus}}</td>
                        <td>{{auditRow.AssignedTo}}</td>
                        <td class="num-cell">{{auditRow.Cost}}</td>
                        <td class="num-cell">{{auditRow.Duration}}</td>
                    </tr>
                </tbody>
            </table>
        </div>

    </div><!-- /.modal-body -->

    <div class="modal-footer">
        <button type="button"
                class="btn btn-fw btn-lg btn-secondary"
                (click)="exportAudit()"
                [disabled]="loading">
            <i class="fa fa-download" aria-hidden="true"></i>
            Export
        </button>

        <button type="button"
                class="btn btn-fw btn-lg btn-secondary"
                (click)="onCancel()">
            Close
        </button>
    </div><!-- /.modal-footer -->
</form>

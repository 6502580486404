import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output
} from '@angular/core';

import { AnimalService } from '../../animals/services/animal.service';
import { CopyBufferService } from '../../common/services/copy-buffer.service';
import { MaterialService } from '../../services/material.service';
import { MaterialPoolService } from '../../services/material-pool.service';
import { SampleService } from '../../samples/sample.service';

import { DroppableEvent } from '../../common/droppable-event';
import { notEmpty, getSafeProp, lowerCaseCompare } from '../../common/util';
import { LoggingService } from '../../services/logging.service';
@Component({
    selector: 'mating-animal-table',
    templateUrl: './mating-animal-table.component.html',
    styles: [`
        th {
            min-width: 305px;
        }
    `]
})
export class MatingAnimalTableComponent implements OnChanges, OnInit {
    @Input() mating: any;
    @Input() materialPoolMaterials: any[];
    @Input() facetPrivilege: string;
    @Input() activeRemoveCheckbox: boolean;

    @Output() materialPoolMaterialsChange: EventEmitter<any[]> = new EventEmitter<any[]>();

    // state
    removeAnimalsFromExistingMatings = true;
    readonly COMPONENT_LOG_TAG = 'mating-facet';

    constructor(
        private animalService: AnimalService,
        private copyBufferService: CopyBufferService,
        private materialService: MaterialService,
        private materialPoolService: MaterialPoolService,
        private sampleService: SampleService,
        private loggingService: LoggingService
    ) {
        //
    }

    ngOnInit() {
        this.initialize();
    }

    ngOnChanges(changes: any) {
        if (this.mating && !changes.mating.firstChange) {
            this.initialize();
        }
    }

    initialize() {
        //
    }


    // Material changes
    onMaterialPoolMaterialsChange() {
        this.materialPoolMaterialsChange.emit(this.materialPoolMaterials);
    }


    // Add materials
    selectAnimal(animal: any) {
        // Fetch Material into Breeze so we can detect the Line
        this.materialService.getMaterial(animal.C_Material_key).then((material) => {

            this.materialHasExistingMaterialPools(animal.C_Material_key)
                .then((hasPools: boolean) => {

                    if (hasPools && !this.removeAnimalsFromExistingMatings) {
                        // Leave in any existing matings
                        this.addMaterialRetainExistingPools(animal, this.mating);
                    } else {
                        // Remove from any existing matings
                        this.addMaterial(animal, this.mating);
                    }

                });

            return animal;
        });
    }

    selectSample(sample: any) {
        // Fetch Material into Breeze so we can detect the Line
        this.materialService.getMaterial(sample.C_Material_key).then((material) => {
            this.addMaterial(sample, this.mating);
        });
    }

    private materialHasExistingMaterialPools(materialKey: any): Promise<boolean> {
        return this.materialPoolService.materialHasExistingMaterialPools(materialKey);
    }

    private addMaterial(material: any, mating: any) {
        const initialValues = {
            C_MaterialPool_key: mating.C_MaterialPool_key,
            C_Material_key: material.C_Material_key
        };
        this.materialPoolService.createMaterialPoolMaterial(initialValues);

        if (this.mating.C_MaterialPool_key < 0) {
            this.calculateLine();
        }

        this.onMaterialPoolMaterialsChange();
    }

    addMaterialRetainExistingPools(material: any, mating: any) {
        const initialValues = {
            C_MaterialPool_key: mating.C_MaterialPool_key,
            C_Material_key: material.C_Material_key
        };
        this.materialPoolService.createMaterialPoolMaterialRetainExistingPools(initialValues);

        if (this.mating.C_MaterialPool_key < 0) {
            this.calculateLine();
        }

        this.onMaterialPoolMaterialsChange();
    }

    calculateLine() {
        this.mating.C_Line_key = null;

        // If all animals in the mating are of the same line, the mating line should be used.
        // Otherwise, the line should be null.
        if (this.materialPoolMaterials.length > 0) {
            const seedLineKey = this.materialPoolMaterials[0].Material.C_Line_key;

            let sameLines = true;
            for (const material of this.materialPoolMaterials) {
                if (material.Material.C_Line_key !== seedLineKey) {
                    sameLines = false;
                    break;
                }
            }

            if (sameLines) {
                this.mating.C_Line_key = seedLineKey;
            }
        }
    }


    // Drop
    onDropMaterials(event: DroppableEvent) {
        if (this.facetPrivilege === 'ReadWrite') {
            this.handleDroppedAnimals();
            this.handleDroppedSamples();
        }
    }

    private handleDroppedAnimals() {
        const newAnimals = this.animalService.draggedAnimals;
        this.processAddedAnimals(newAnimals);

        this.animalService.draggedAnimals = [];
    }

    private handleDroppedSamples() {
        const newSamples = this.sampleService.draggedSamples;
        this.processAddedSamples(newSamples);

        this.sampleService.draggedSamples = [];
    }


    // Paste
    pasteMaterials() {
        if (this.facetPrivilege === 'ReadWrite') {
            this.handlePastedAnimals();
            this.handlePastedSamples();
        }
    }

    private handlePastedAnimals() {
        if (this.copyBufferService.hasAnimals()) {
            const newAnimals = this.copyBufferService.paste();
            this.processAddedAnimals(newAnimals);
        }
    }

    private handlePastedSamples() {
        if (this.copyBufferService.hasSamples()) {
            const newSamples = this.copyBufferService.paste();
            this.processAddedSamples(newSamples);
        }
    }


    // Process added materials  
    private processAddedAnimals(animals: any[]) {
        let countSexNull: any[] = [];
        if (notEmpty(animals)) {
            if (this.removeAnimalsFromExistingMatings) {
                countSexNull = animals.filter((animal) => {
                    return animal.C_Sex_key === null || animal.cv_Sex.Sex === "Undetermined";
                });
                if (countSexNull.length > 0 ) {
                    const message = 'Animals without a determined Sex cannot be added to a mating record.';
                    const showToast = true;
                    this.loggingService.logWarning(
                        message,
                        null,
                        this.COMPONENT_LOG_TAG,
                        showToast
                    );
                }
                for (const animal of animals) {
                    if (animal.C_Sex_key !== null && animal.cv_Sex.Sex !== "Undetermined") {
                        this.addMaterial(animal, this.mating);
                    }
                }
            } else {
                for (const animal of animals) {
                    if (animal.C_Sex_key !== null && animal.cv_Sex.Sex !== "Undetermined") {
                        this.addMaterialRetainExistingPools(animal, this.mating);
                    }

                }
            }

            this.onMaterialPoolMaterialsChange();
        }
    }

    private processAddedSamples(samples: any[]) {
        if (notEmpty(samples)) {
            for (const sample of samples) {
                const initialValues = {
                    C_MaterialPool_key: this.mating.C_MaterialPool_key,
                    C_Material_key: sample.C_Material_key
                };
                this.materialPoolService.createMaterialPoolMaterial(initialValues);
            }

            this.onMaterialPoolMaterialsChange();
        }
    }


    // Delete/Remove
    deleteMaterial(material: any) {
        this.materialPoolService.deleteMaterialPoolMaterial(material);

        if (this.mating.C_MaterialPool_key < 0) {
            this.calculateLine();
        }
    }

    removeMaterial(material: any) {
        material.DateOut = Date.now();
    }


    // Search functions for indirect typeahead
    getFilteredFemales = (query: string): Promise<any[]> => {
        return this.animalService.getFilteredCurrentAnimalsBySex(query, 'Female', 500)
            .then((data) => {
                return data;
            });
    }

    getFilteredMales = (query: string): Promise<any[]> => {
        return this.animalService.getFilteredCurrentAnimalsBySex(query, 'Male', 500)
            .then((data) => {
                return data;
            });
    }

    getFilteredSamples = (query: string): Promise<any[]> => {
        return this.sampleService.getFilteredSamples(query, 100).then((data) => {
            return data;
        });
    }

    /**
     * Alter exact match behavior to include matching on MicrochipIdentifer
     */
    isAnimalExactMatch = (data: any[], term: string) => {
        if (term &&
            data &&
            data.length === 1
        ) {
            return lowerCaseCompare(data[0].AnimalName, term) ||
                lowerCaseCompare(getSafeProp(data[0], 'Material.MicrochipIdentifier'), term);
        }
        return false;
    }

    /**
     * Alter exact match behavior to include matching on MicrochipIdentifer
     */
    isSampleExactMatch = (data: any[], term: string) => {
        if (term &&
            data &&
            data.length === 1
        ) {
            return lowerCaseCompare(data[0].AnimalName, term) ||
                lowerCaseCompare(getSafeProp(data[0], 'Material.MicrochipIdentifier'), term);
        }
        return false;
    }

    // <select> formatters
    animalNameFormatter = (value: any) => {
        return value.AnimalName;
    }
    sampleNameFormatter = (value: any) => {
        return value.SampleName;
    }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { ClimbCommonModule } from '../common/climb-common.module';
import { ClimbServicesModule } from '../services/climb-services.module';

import { PwdResetViewComponent } from './components/pwd-reset-view/pwd-reset-view.component';
import { RequestPwdResetViewComponent } from './components/request-pwd-reset-view/request-pwd-reset-view.component';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        ClimbCommonModule,
        ClimbServicesModule,
    ],
    declarations: [
        PwdResetViewComponent,
        RequestPwdResetViewComponent
    ],
    exports: [
        PwdResetViewComponent,
        RequestPwdResetViewComponent
    ],
    providers: [
    ]
})
export class PwdResetModule { }

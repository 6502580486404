import type { Material } from './material.interface';
import type { TaskOutputSet } from './task-output-set.interface';

export interface TaskOutputSetMaterial {
    C_Material_key: number;
    C_TaskOutputSetMaterial_key: number;
    C_TaskOutputSet_key: number;
    C_Workgroup_key: number;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    Material: Material;
    ModifiedBy: string;
    TaskOutputSet: TaskOutputSet;
    Version: number;
}

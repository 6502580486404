import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ResourceService } from '../../resources/resource.service';
import { UserService } from '../../user/user.service';

import {
    getSafeProp,
    notEmpty,
    sortObjectArrayByProperty,
} from '@common/util';
import { DateFormatterService } from '@common/util/date-time-formatting';

import {
    Recipient,
    RecipientType
} from '../../messages/models';
import { AnimalHealthRecord } from '@common/types';

@Component({
    selector: 'clinical-alert-sender',
    templateUrl: './clinical-alert-sender.component.html',
    styles: [`
        .modal-body {
            min-height: 15em;
        }
    `]
})
export class ClinicalAlertSenderComponent implements OnInit {
    @Input() healthRecord: AnimalHealthRecord;
    @Output() close: EventEmitter<any> = new EventEmitter<any>();

    animalName: string;
    defaultMessage: any;
    loading = false;

    readonly MODAL_TITLE = 'Send Urgent Clinical Alert';


    constructor(
        private activeModal: NgbActiveModal,
        private resourceService: ResourceService,
        private userService: UserService,
        private dateFormatterService: DateFormatterService
    ) { }

    ngOnInit() {
        this.animalName = getSafeProp(this.healthRecord, 'Animal.AnimalName');

        this.createDefaultMessage();
    }

    private createDefaultMessage() {
        this.loading = true;

        this.getDefaultRecipients().then((defaultRecipients: Recipient[]) => {
            this.defaultMessage = {
                recipients: defaultRecipients,
                messageSubject: this.getDefaultMessageSubject(),
                messageText: this.getDefaultMessageText(),
                messageMaps: [],
            };

            this.loading = false;
        }).catch((error) => {
            this.loading = false;
            throw error;
        });
    }

    /**
     * Sets default recipient to the User corresponding to the health tech.
     */
    private getDefaultRecipients(): Promise<Recipient[]> {
        if (!this.healthRecord.C_Resource_key) {
            return Promise.resolve([]);
        }

        // Message Recipients are Users, so must convert from the health tech Resource
        return this.resourceService.getResource(this.healthRecord.C_Resource_key)
            .then((resource: any) => {
                if (resource && resource.UserID) {
                    return this.userService.getWorkgroupUserByUserId(resource.UserID);
                } else {
                    return Promise.resolve();
                }
            }).then((workgroupUser: any) => {
                const recipient: Recipient = this.workgroupUserToRecipient(workgroupUser);

                if (recipient) {
                    return [recipient];
                } else {
                    return [];
                }
            });
    }

    private workgroupUserToRecipient(workgroupUser: any): Recipient | null {
        if (!workgroupUser?.User) {
            return null;
        }
        return {
            recipientKey: workgroupUser.User.Id,
            recipientName: workgroupUser.User.FirstName +
                ' ' +
                workgroupUser.User.LastName,
            type: RecipientType.User
        };
    }

    private getDefaultMessageSubject(): string {
        return 'Urgent Clinical Record for ' + this.animalName;
    }

    private getDefaultMessageText(): string {
        let out = 'The Clinical record for ' +
            this.animalName +
            ' has been marked urgent and requires your attention.\n\n';

        if (notEmpty(this.healthRecord.Animal.AnimalClinicalObservation)) {
            out += 'Clinical observations on this record are:\n'
                + this.getClinicalObservationsList();
        }

        return out;
    }

    private getClinicalObservationsList(): string {
        let out = '';

        // Show by date descending
        let observations = this.healthRecord.Animal.AnimalClinicalObservation;
        observations = sortObjectArrayByProperty(
            observations,
            'DateObserved',
            true
        );

        for (const observation of observations) {
            if (notEmpty(observation.ClinicalObservationDetail)) {
                const details: string[] = observation.ClinicalObservationDetail.map((detail: any) => {
                    return getSafeProp(detail, 'cv_ClinicalObservation.ClinicalObservation');
                });

                out += '- ' +
                    this.dateFormatterService.formatDateOnly(observation.DateObserved) +
                    ': ' +
                    details.join(', ') +
                    '\n';
            }
        }

        return out;
    }


    // Actions
    sendClicked() {
        this.close.emit();
        this.activeModal.close();
    }

    cancelClicked() {
        this.close.emit();
        this.activeModal.dismiss();
    }
}

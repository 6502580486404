<ng-container *ngIf="mating">

    <ng-template [ngTemplateOutlet]="labelValuePair"
        [ngTemplateOutletContext]="{
            label: 'Mating ID',
            value: mating.MatingID }"></ng-template>

    <ng-template [ngTemplateOutlet]="labelValuePair"
        [ngTemplateOutletContext]="{
            label: 'Mating Date',
            value: mating.DateMating | formatShortDate }"></ng-template>

    <ng-template [ngTemplateOutlet]="labelValuePair"
        [ngTemplateOutletContext]="{
            label: 'Mating Status',
            value: mating.cv_MatingStatus?.MatingStatus }"></ng-template>

    <ng-template [ngTemplateOutlet]="labelValuePair"
        [ngTemplateOutletContext]="{
            label: 'Line' | translate,
            value: mating.Line?.LineName }"></ng-template>

    <ng-template [ngTemplateOutlet]="labelValuePair"
        [ngTemplateOutletContext]="{
            label: 'Mating Type',
            value: mating.cv_MatingType?.MatingType }"></ng-template>

    <ng-template [ngTemplateOutlet]="labelValuePair"
        [ngTemplateOutletContext]="{
            label: 'Housing ID',
            value: mating.MaterialPool?.MaterialPoolID }"></ng-template>

    <ng-template [ngTemplateOutlet]="labelValuePair"
        [ngTemplateOutletContext]="{
            label: 'Location',
            value: mating?.location }"></ng-template>

</ng-container>

<ng-template #labelValuePair let-label="label" let-value="value">
    <div class="form-group row" *ngIf="!useTableFormat">
        <label class="col-md-2 col-form-label">{{label}}</label>
        <div class="col-md-10">
            <p class="form-control-plaintext">
                {{value}}
            </p>
        </div>
    </div>

    <tr *ngIf="useTableFormat">
        <td>{{label}}</td>
        <td>{{value}}</td>
    </tr>
</ng-template>

<div class="detail-form-wrapper">
    <div class="detail-header">
        <h3>Resource</h3>

        <pager-toolbar [itemName]="'resource'"
                       [logTag]="COMPONENT_LOG_TAG"
                       [first]="pageState.isFirst"
                       [last]="pageState.isLast"
                       (previous)="previousClicked($event)"
                       (next)="nextClicked($event)">
        </pager-toolbar>

        <detail-toolbar [facetPrivilege]="facet.Privilege"
                        [logTag]="COMPONENT_LOG_TAG"
                        (exit)="exitClicked($event)">
        </detail-toolbar>
    </div>

    <div class="alert alert-warning" *ngIf="resource | isDeleted"
         role="alert">
        <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
        This resource has been deleted.
    </div>

    <form class="detail-form" *ngIf="!(resource | isDeleted)"
          name="resourceForm" id="resourceForm"
          #resourceForm="ngForm">
        <fieldset [disabled]="facet.Privilege === 'ReadOnly'">
            <div class="form-group row">
                <label class="col-md-2 col-form-label">Active</label>
                <div class="col-md-10">
                    <input type="checkbox" name="isActive"
                           class="mt-1"
                           [(ngModel)]="resource.IsActive" />
                </div>
            </div>

            <div class="form-group row">
                <label class="col-md-2 col-form-label">Name</label>
                <div class="col-md-10">
                    <input type="text" name="resourceName"
                           class="form-control input-medium"
                           required
                           [(ngModel)]="resource.ResourceName" />
                </div>
            </div>

            <div class="form-group row">
                <label class="col-md-2 col-form-label">Email Address</label>
                <div class="col-md-10">
                    <input type="text" name="emailAddress"
                           class="form-control input-medium"
                           [(ngModel)]="resource.EmailAddress"
                           [required]="requiredFields.includes('EmailAddress')" />
                </div>
            </div>

            <div class="form-group row">
                <label class="col-md-2 col-form-label">Type</label>
                <div class="col-md-10">
                    <select name="resourceType"
                            class="form-control input-medium"
                            [(ngModel)]="resource.C_ResourceType_key">
                        <option></option>
                        <option *ngFor="let item of resourceTypes"
                                [value]="item.C_ResourceType_key">
                            {{item.ResourceType}}
                        </option>
                    </select>
                </div>
            </div>

            <div class="form-group row">
                <label class="col-md-2 col-form-label">Climb ID</label>
                <div class="col-md-10">
                    <select name="userID"
                            class="form-control input-medium"
                            [(ngModel)]="resource.UserID"
                            (ngModelChange)="onUserChange()"
                            [required]="requiredFields.includes('UserID')">
                        <option></option>
                        <option *ngFor="let item of users"
                                [value]="item.C_User_key">
                            {{item.User.UserName}}
                        </option>
                    </select>
                </div>
            </div>

            <div class="form-group row">
                <label class="col-md-2 col-form-label">Resource Group</label>
                <div class="col-md-10">
                    <active-vocab-select [(model)]="resource.C_ResourceGroup_key"
                                         [vocabChoices]="resourceGroups"
                                         [keyFormatter]="resourceGroupKeyFormatter"
                                         [optionFormatter]="resourceGroupFormatter"
                                         [nullable]="true">
                    </active-vocab-select>
                </div>
            </div>

            <div class="form-group row">
                <label class="col-md-2 col-form-label">Background Color</label>
                <div class="col-md-10">
                    <climb-colorpicker 
                        [(model)]="resource.BackgroundColor"
                        [disabled]="facet.Privilege === 'ReadOnly'"
                        [popupScrollEnabled]="[scrollTypes.WorkspaceViewComponent,]"
                    ></climb-colorpicker>
                </div>
            </div>

            <div class="form-group row">
                <label class="col-md-2 col-form-label">Foreground Color</label>
                <div class="col-md-10">
                    <climb-colorpicker 
                        [(model)]="resource.ForegroundColor"               
                        [disabled]="facet.Privilege === 'ReadOnly'"
                        [popupScrollEnabled]="[scrollTypes.WorkspaceViewComponent]"
                    ></climb-colorpicker>
                </div>
            </div>

            <div class="form-group row">
                <label class="col-md-2 col-form-label"></label>
                <div class="col-md-10">
                    <climb-color-box [backgroundColor]="resource.BackgroundColor"
                                     [foregroundColor]="resource.ForegroundColor"
                                     [height]="'17px'"
                                     [text]="'Example Text'">
                    </climb-color-box>
                </div>
            </div>
        </fieldset>
    </form>
</div>

import {
    Component,
    Input,
    OnInit
} from '@angular/core';

@Component({
    selector: 'update-type-cell',
    template: `
<span *ngIf='updateTypeConverted'
    [ngClass]="wrapperClasses">
    <i class="fa fa-fw" 
       [ngClass]="iconClasses"
       title="{{updateType}}"
       aria-hidden="true"></i>{{updateType}}
</span>
`
})
export class UpdateTypeCellComponent implements OnInit {
    @Input() updateType: string;

    updateTypeConverted = '';
    iconClasses: string[];
    wrapperClasses: string[];

    // type constants 
    // (lowercase for case-insensitive assignment)
    readonly TYPE_CLASSES: Record<string, { iconClasses: string[], wrapperClasses: string[] }> = {
        created: {
            iconClasses: ['fa-plus'],
            wrapperClasses: ['item-created']
        },
        deleted: {
            iconClasses: ['fa-minus'],
            wrapperClasses: ['item-deleted']
        },
        modified: {
            iconClasses: ['fa-pencil'],
            wrapperClasses: ['item-modified']
        }
    };


    ngOnInit() {
        this.convertUpdateType();
        this.setClasses();
    }

    private convertUpdateType() {
        this.updateTypeConverted = '';

        if (this.isValidType()) {
            this.updateTypeConverted = this.updateType.toLowerCase();
        }
    }

    private setClasses() {
        this.iconClasses = [];
        this.wrapperClasses = [];

        if (this.isValidType()) {
            const typeClass = this.TYPE_CLASSES[this.updateTypeConverted];
            this.iconClasses = typeClass.iconClasses;
            this.wrapperClasses = typeClass.wrapperClasses;
        }
    }

    private isValidType(): boolean {
        if (this.updateType) {
            // Case-insensitive
            const updateTypeLower = this.updateType.toLowerCase();
            // check if type is valid
            return updateTypeLower in this.TYPE_CLASSES;
        }
        return false;
    }
}

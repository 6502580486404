import { PlateDetailComponent } from './plate-detail.component';
import { DataContextService } from './../services/data-context.service';
import { ConfirmService } from './../common/confirm/confirm.service';
import {
    Component,
    Input,
    OnInit,
    ViewChild,

    OnDestroy
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { PlateService } from './plate.service';
import { TranslationService } from '../services/translation.service';
import { WorkspaceFilterService } from '../services/workspace-filter.service';

import { uniqueArrayFromPropertyPath } from '../common/util';

import {
    BaseFacet,
    BaseFacetService
} from '../common/facet';

import { PlateTableOptions } from './plate-table-options';
import { PlateFilterComponent } from './plate-filter.component';

import {
    CellFormatterService,
    TableState,
    DataResponse,
    ColumnsState,
    TableColumnDef,
} from '@common/datatable';
import { QueryDef } from '../services/query-def';
import { VocabularyService } from '../vocabularies/vocabulary.service';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { Entity, Plate } from '../common/types';
import { takeUntil } from 'rxjs/operators';
import { arrowClockwise, magnifier, squareOnSquare, squareOnSquareWithArrow } from '@icons';

@Component({
    selector: 'plate-facet',
    templateUrl: './plate-facet.component.html',
    providers: BaseFacet.BASE_COMPONENT_PROVIDERS
})
export class PlateFacetComponent extends BaseFacet<Entity<Plate>> implements OnInit, OnDestroy {
    @Input() facetId: string;
    @Input() facet: any;
    @ViewChild("plateDetail") plateDetail: PlateDetailComponent;

    readonly icons = { arrowClockwise, magnifier, squareOnSquare, squareOnSquareWithArrow };

    componentName = 'plate';

    plateTableOptions: PlateTableOptions;

    logError: any;
    logSuccess: any;
    logWarning: any;

    readonly COMPONENT_LOG_TAG = 'plate-facet';

    componentFilterSubscription: Subscription;
    private notifier$ = new Subject<void>();

    dataTableColumns: BehaviorSubject<TableColumnDef[]>;
    dataTableColumns$: Observable<TableColumnDef[]>;

    constructor(
        private baseFacetService: BaseFacetService,
        private cellFormatterService: CellFormatterService,
        private plateService: PlateService,
        private dataContext: DataContextService,       
        private confirmService: ConfirmService,
        private modalService: NgbModal,
        private translationService: TranslationService,
        workspaceFilterService: WorkspaceFilterService,
        private vocabularyService: VocabularyService
    ) {
        super(
            baseFacetService,
            workspaceFilterService
        );

        this.plateTableOptions = new PlateTableOptions(
            this.cellFormatterService,
            this.translationService
        );

        this.dataTableColumns = new BehaviorSubject(this.plateTableOptions.options.columns);
        this.dataTableColumns$ = this.dataTableColumns.asObservable();

        this.dataService = {
            run: (tableState: TableState) => {
                return this.loadItemsList(tableState);
            }
        };
    }

    // lifecycle
    ngOnInit() {
        super.ngOnInit();
        this.initialize();

        this.dataContext.onCancel$.pipe(takeUntil(this.notifier$)).subscribe(() => {
            this.changeView(this.LIST_VIEW);
        });
    }

    ngOnDestroy() {
        if (this.componentFilterSubscription) {
            this.componentFilterSubscription.unsubscribe();
        }
        this.notifier$.next();
        this.notifier$.complete();
    }

    initialize() {
        this.restoreFilterState();
        this.changeView(this.LIST_VIEW);
    }

    refreshData() {
        this.initialize();
        this.dataTableComm.reloadTable();
    }

    restoreFilterState() {

        // process any grid filters
        if (this.facet && this.facet.GridFilter) {
            try {
                this.filter = JSON.parse(this.facet.GridFilter);
            } catch (err) {
                console.error(err);
            }

            if (this.filter) {
                // format date filters here

            } else {
                this.filter = {};
            }
        }
    }

    async loadItemsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        this.setLoadingState(tableState.loadingMessage);

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'DateCreated DESC';
        const expands: string[] = [];

        const queryDef: QueryDef = {
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter(),
            expands
        };

        try {
            const response = await this.plateService.getPlates(queryDef);

            const visibleColumns = this.getVisibleColumns(this.plateTableOptions.options);
            await this.plateService.ensureVisibleColumnsDataLoaded(response.results, visibleColumns);

            this.stopLoading();

            this.data = response.results as Entity<Plate>[];
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        } finally {
            this.stopLoading();
        }
    }

    addItemClick() {
        this.setLoadingState();
        this.createNewItem().then((item) => {
            this.stopLoading();
            this.itemToEdit = item;
            this.changeView(this.DETAIL_VIEW);
        }).catch((error) => {
            this.loading = false;
            this.loggingService.logError("An unexpected error occurred. Please try again", error, this.componentName, true);
        });
    }

    async createNewItem(): Promise<Entity<Plate>> {
        const newPlate = this.plateService.createPlate();
        newPlate.cv_PlateStatus = await this.vocabularyService.getCVDefault('cv_PlateStatuses'); 
        return Promise.resolve(newPlate);
    }

    openFilter() {
        const component = this._openFilterModal();
        component.filter = this.filter;
        this.componentFilterSubscription = component.onFilter.subscribe((filter: any) => {
            this.filter = filter;
            this.runFilter();
        });
    }
    
    _openFilterModal(): PlateFilterComponent {
        const ref = this.modalService.open(PlateFilterComponent);
        return ref.componentInstance as PlateFilterComponent;
    }

    pasteAnimals() {
        if (this.plateDetail) {
            this.plateDetail.pasteAnimals();
        }
    }

    copyPlate() {
        if (this.plateDetail) {
            this.plateDetail.copyPlate();
        }
    }

    async doBulkDelete() {  
        const itemsToDelete = this.selectedRows;
        if (itemsToDelete.length === 0) {
            return;
        }  

        const plateKeys = uniqueArrayFromPropertyPath(this.selectedRows, 'C_Plate_key');

        const safeToDelete = await this.plateService.arePlatesSafeToDelete(plateKeys);
        if (safeToDelete) {
            this.onPlateSafeToDeleteCheck(safeToDelete);
        }
        // return this.plateService.arePlatesSafeToDelete(plateKeys).then((safeToDelete) => {
        // });
    }
    
    onPlateSafeToDeleteCheck(safeToDelete: boolean): Promise<void> {
        if (!safeToDelete) {
            this.loggingService.logWarning(
                "Cannot delete selected plates: there is associated " +
                this.translationService.translate("Genotype") +
                " record.",
                null, null, true
            );
            return;
        }
        const modalTitle = 'Delete Plates';
        const modalText = 'Delete ' +
            this.selectedRows.length +
            ' selected plates? This action cannot be undone.';
 

        return this.confirmService.confirmDelete(modalTitle, modalText).then(
            // success
            () => {
                this.onConfirmDelete();
            },
            // cancel
            () => { /* do nothing on cancel */ }
        );
    }

    onConfirmDelete() {
        this.facetLoadingState.changeLoadingState(true);

        for (const plate of this.selectedRows) {
            this.plateService.deletePlate(plate);
        }

        this.dataContext.save().then(() => {
            this.reloadTable();
        }).then(() => {
            this.facetLoadingState.changeLoadingState(false);
        }).catch((error) => {
            this.facetLoadingState.changeLoadingState(false);
            throw error;
        });
    }

    async selectedColumnsChange({ visible }: ColumnsState) {
        try {
            this.facetLoadingState.changeLoadingState(true);
            await this.plateService.ensureVisibleColumnsDataLoaded(this.data, visible);
        } finally {
            this.facetLoadingState.changeLoadingState(false);
        }
    }
}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'loading-overlay',
    template: `
        <climb-spinner size="xl"></climb-spinner>
        {{ message || defaultMessage }}
    `,
    styleUrls: ['./loading-overlay.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingOverlayComponent {
    defaultMessage = 'Loading...';
    @Input() message: string;
}

<h4>Facet Settings</h4>

<nav ngbNav
     #gridTabs="ngbNav"
     class="nav-tabs px-0"
     [destroyOnHide]="false">
    <ng-container ngbNavItem>
        <a ngbNavLink>Animals</a>
        <ng-template ngbNavContent>
            <ng-template [ngTemplateOutlet]="facetFieldList" 
                         [ngTemplateOutletContext]="{items: animalFacetSettings}">
            </ng-template>
        </ng-template>
    </ng-container>
    <ng-container ngbNavItem>
        <a ngbNavLink>Orders</a>
        <ng-template ngbNavContent>
            <ng-template [ngTemplateOutlet]="facetFieldList" 
                         [ngTemplateOutletContext]="{items: orderFacetSettings}">
            </ng-template>
        </ng-template>
    </ng-container>
    <ng-container ngbNavItem>
        <a ngbNavLink>Tasks</a>
        <ng-template ngbNavContent>
            <ng-template [ngTemplateOutlet]="facetFieldList" 
                         [ngTemplateOutletContext]="{items: taskFacetSettings}">
            </ng-template>
        </ng-template>
    </ng-container>
    <ng-container ngbNavItem>
        <a ngbNavLink>{{ 'Jobs' | translate }}</a>
        <ng-template ngbNavContent>
            <ng-template [ngTemplateOutlet]="facetFieldList" 
                         [ngTemplateOutletContext]="{items: jobFacetSettings}">
            </ng-template>
        </ng-template>
    </ng-container>
    <ng-container ngbNavItem>
        <a ngbNavLink>Resources</a>
        <ng-template ngbNavContent>
            <ng-template [ngTemplateOutlet]="facetFieldList" 
                         [ngTemplateOutletContext]="{items: resourceFacetSettings}">
            </ng-template>
        </ng-template>
    </ng-container>
    <ng-container ngbNavItem>
        <a ngbNavLink>Clinical</a>
        <ng-template ngbNavContent>
            <ng-template [ngTemplateOutlet]="facetFieldList" 
                         [ngTemplateOutletContext]="{items: clinicalFacetSettings}">
            </ng-template>
        </ng-template>
    </ng-container>
    <ng-container ngbNavItem>
        <a ngbNavLink>Housing</a>
        <ng-template ngbNavContent>
            <ng-template [ngTemplateOutlet]="facetFieldList" 
                         [ngTemplateOutletContext]="{items: housingFacetSettings}">
            </ng-template>
        </ng-template>
    </ng-container>
    <ng-container ngbNavItem>
        <a ngbNavLink>Protocols</a>
        <ng-template ngbNavContent>
            <ng-template [ngTemplateOutlet]="facetFieldList" 
                         [ngTemplateOutletContext]="{items: protocolFacetSettings}">
            </ng-template>
        </ng-template>
    </ng-container>
    <ng-container ngbNavItem>
        <a ngbNavLink>{{'Studies' | translate}}</a>
        <ng-template ngbNavContent>
            <ng-template [ngTemplateOutlet]="facetFieldList" 
                         [ngTemplateOutletContext]="{items: studyFacetSettings}">
            </ng-template>
        </ng-template>
    </ng-container>
    <ng-container ngbNavItem>
        <a ngbNavLink>Samples</a>
        <ng-template ngbNavContent>
            <ng-template [ngTemplateOutlet]="facetFieldList" 
                         [ngTemplateOutletContext]="{items: sampleFacetSettings}">
            </ng-template>
        </ng-template>
    </ng-container>
    <ng-container ngbNavItem>
        <a ngbNavLink>Matings</a>
        <ng-template ngbNavContent>
            <ng-template [ngTemplateOutlet]="facetFieldList" 
                         [ngTemplateOutletContext]="{items: matingFacetSettings}">
            </ng-template>
        </ng-template>
    </ng-container>
</nav>
<div [ngbNavOutlet]="gridTabs" class="mt-2"></div>

<ng-template #facetFieldList let-items="items">
    <table class="table">
        <thead>
            <tr>
                <th>Field</th>
                <th>Active</th>
                <th>Required</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let row of items">
                <td>{{translate(row.DisplayName)}}</td>
                <td>
                    <input type="checkbox" 
                           [disabled]="readonly || row.LockActive || (row.IsRequired && !row.AllowHideAlwaysRequired)" 
                           [(ngModel)]="row.IsActive"/>
                </td>
                <td>
                    <input type="checkbox"
                           *ngIf="row.ShowRequired"
                           [disabled]="readonly || !row.IsActive" 
                           [(ngModel)]="row.IsRequired"/>
                </td>
            </tr>
        </tbody>
    </table>
</ng-template>

import {
    Component,
    EventEmitter,
    Input,
    Output
} from '@angular/core';

import { MaterialService } from '../services/material.service';

/*
* Component to select single Animal/Sample Source
*/
@Component({
    selector: 'sample-source-single-select',
    templateUrl: './sample-source-single-select.component.html',
    styles: [`
        .sample-source-single-select {
            min-width: 300px;
        }
    `]
})
export class SampleSourceSingleSelectComponent {
    @Input() model: any;
    @Output() modelChange: EventEmitter<any> = new EventEmitter<any>();

    constructor(
        private materialService: MaterialService
    ) { }

    modelChanged() {
        this.modelChange.emit(this.model);
    }

    searchMaterialByKey = (key: number): Promise<any> => {
        const materialFilter = {
            C_Material_key: key
        };
        const queryDef = {
            page: 1,
            size: 1,
            filter: materialFilter,
            inlineCount: false
        };

        return this.materialService.searchMaterials(queryDef)
            .then((response: any) => {
                return response.results;
            });
    }

    searchMaterials = (term: string): Promise<any> => {
        const materialFilter = {
            MaterialName: term
        };
        const queryDef = {
            page: 1,
            size: 50,
            // Sort so that animals come first
            sort: 'Sample.SampleNameSortable, Animal.AnimalNameSortable',
            filter: materialFilter,
            inlineCount: false
        };

        return this.materialService.searchMaterials(queryDef)
            .then((response: any) => {
                return response.results;
            });
    }

    sourceKeyFormatter = (value: any) => {
        return value.C_Material_key;
    }

    sourceResultFormatter = (value: any) => {
        if (value.Animal) {
            return value.Animal.AnimalName;
        } else if (value.Sample) {
            return value.Sample.SampleName;
        } else {
            return "";
        }
    }

    animalStatusKeyFormatter = (value: any) => {
        return value.C_AnimalStatus_key;
    }

    animalStatusFormatter = (value: any) => {
        return value.AnimalStatus;
    }

    sampleStatusKeyFormatter = (value: any) => {
        return value.C_SampleStatus_key;
    }

    sampleStatusFormatter = (value: any) => {
        return value.SampleStatus;
    }
}

import {
    Component,
    Input,
    QueryList,
    ViewChildren,
} from '@angular/core';
import { DateFormatterService } from '@common/util/date-time-formatting';

@Component({
    selector: 'search-node',
    templateUrl: './search-node.component.html',
    styles: [`
        .tree-node-list {
            position: relative;
            margin: 0;
            padding: 0 0 0 20px;
            list-style: none;
        }

        .tree-node-content {
            border: 1px solid #dae2ea;
            background: #f8faff;
            color: #7c9eb2;
            padding: 5px;
        }

        .tree-node-list-item {
            position: relative;
            margin: -1px 0 0;
            padding: 0;
            min-height: 20px;
            line-height: 20px;
        }
        
        .advanced-search-filter-button {
            margin-left: auto;
        }
        
        .advanced-search-filter-button ~ .advanced-search-filter-button {
            margin-left: 8px;
        }
    `],
})
export class SearchNodeComponent {
    @Input() node: any = null;

    @Input() expandedNode: SearchNodeComponent = null;

    @ViewChildren(SearchNodeComponent) childSearchNodes: QueryList<SearchNodeComponent> = null;

    treeNodeExpanded = false;

    constructor(private dateFormatterService: DateFormatterService) {

    }

    toggleExpand() {
        if (this.node.type === 'Branch') {
            this.treeNodeExpanded = !this.treeNodeExpanded;
        } else {
            if (this.filterExpanded) {
                // we're clicking a node that's expanded. Treat this as a toggle action.
                this.expandedNode = null;
            } else {
                // we're clicking a node that isn't expanded so we expand that node.
                this.expandedNode = this;
            }
        }
    }

    get filterExpanded() {
        return this.expandedNode !== null &&
            this.expandedNode === this;
    }

    /**
     * Returns a display status string to use in the display button
     */
    getDisplayStatusString = (node: any): string => {
        if (node.type === 'Branch') {
            // NOTE:
            // I think that this is fine for the complexity of the
            // filters that we have today but if we have several layers of
            // nesting we will want to cache rather than recaluclate
            // at every branch.
            const allStatus: string[] = node.fields.map(this.getDisplayStatusString);
            if (allStatus.every((status) => status === 'Off' || status === 'None')) {
                // no children are visible
                return 'None';
            } else if (allStatus.every((status) => status === 'On' || status === 'Every')) {
                // every child is visible
                return 'Every';
            } else {
                // some children are visible
                return 'Some';
            }
        } else {
            if (node.showInOutput) {
                return 'On';
            } else {
                return 'Off';
            }
        }
    }

    /**
     * Toggle the display status for the given node (recursively if it's a branch node)
     */
    toggleShowInOutput() {
        if (this.node.type === 'Branch') {
            const currStatus = this.getDisplayStatusString(this.node);
            setShowInOutputRecursive(this.node, currStatus !== 'Every');
        } else {
            this.node.showInOutput = !this.node.showInOutput;
        }
    }

    /**
     * Generate a one line text description of the filter applied to the given node,
     * @param node the node to describe
     * @returns {string} the description
     */
    filterDescription(node: any) {
        if (node) {
            const op = node.operator;
            if (node.type === 'Text') {
                const text = node.text;
                if ((typeof op === 'undefined' || op === 'contains') && text) {
                    return 'Contains "' + text + '"';
                } else if (op === 'has no value') {
                    return 'Has no value';
                }
            } else if (node.type === 'Date') {
                const minVal = this.dateFormatterService.formatDateOnly(node.minValue);
                const maxVal = this.dateFormatterService.formatDateOnly(node.maxValue);

                if ((typeof op === 'undefined' || op === 'between') && minVal && maxVal) {
                    return 'Between "' + minVal + '" and "' + maxVal + '" inclusive';
                } else if (op === '<=' && maxVal) {
                    return 'Less than or equal to "' + maxVal + '"';
                } else if (op === '>=' && minVal) {
                    return 'Greater than or equal to "' + minVal + '"';
                } else if (op === '=' && minVal) {
                    return 'Equal to "' + minVal + '"';
                } else if (op === 'has no value') {
                    return 'Has no value';
                }
            } else if (node.type === 'Number') {
                const minVal = node.minValue;
                const minValIsNum = typeof minVal === 'number';
                const maxVal = node.maxValue;
                const maxValIsNum = typeof maxVal === 'number';

                if ((typeof op === 'undefined' || op === 'between') && minValIsNum && maxValIsNum) {
                    return 'Between ' + minVal + ' and ' + maxVal + ' inclusive';
                } else if (op === '<=' && maxValIsNum) {
                    return 'Less than or equal to ' + maxVal + '';
                } else if (op === '>=' && minValIsNum) {
                    return 'Greater than or equal to ' + minVal + '';
                } else if (op === '=' && minValIsNum) {
                    return 'Equal to ' + minVal + '';
                } else if (op === 'has no value') {
                    return 'Has no value';
                }
            } else if (node.type === 'Enumeration') {
                const vals = node.values;
                if ((typeof op === 'undefined' || op === 'any in') && vals && vals.length) {
                    if (vals.length === 1) {
                        return 'Matches: "' + vals[0] + '"';
                    } else {
                        return 'Matches any in: "' + vals.join('", "') + '"';
                    }
                } else if (op === 'has no value') {
                    return 'Has no value';
                }
            } else if (node.type === 'Boolean') {
                if (node.value === null) {
                    return 'None';
                } else if (node.value === true) {
                    return 'Is true';
                } else if (node.value === false) {
                    return 'Is false';
                }
            }
        }

        return 'None';
    }

    get showChildNodes(): boolean {
        return this.treeNodeExpanded && this.node.type === 'Branch' && !!this.node.fields;
    }
}

function setShowInOutputRecursive(node: any, showInOutput: boolean) {
    if (node.type === 'Branch') {
        for (const childNode of node.fields) {
            setShowInOutputRecursive(childNode, showInOutput);
        }
    } else {
        node.showInOutput = showInOutput;
    }
}

import {
    CellFormatterService,
    TableOptions,
    FormatterOptions
} from '../common/datatable';
import { TranslationService } from '../services/translation.service';
import { AuditModifiedFieldComponent } from './audit-modified-fields/audit-modified-field.component';
import { AuditClassFactory } from './audit-class-maker';
import { ignoreColumnsInOptions } from '@common/datatable/utils';

export class AuditTaskInputsTableOptions {
    options: TableOptions;

    constructor(
        private cellFormatterService: CellFormatterService,
        private translationService: TranslationService,
        private isCRO: boolean
    ) {
        this.options = ignoreColumnsInOptions()(this.getDefaultOptions());
    }

    getDefaultOptions(): TableOptions {
        return {
            csvFileName: 'task-inputs-audit.csv',
            enableDetailColumn: false,
            enableDraggable: false,
            enableSelectable: false,
            enableAuditColumns: false,
            columns: [
                {
                    displayName: 'Type',
                    field: 'UpdateType',
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Modified Fields',
                    field: 'ModifiedFields',
                    sortField: 'ModifiedFields',
                    rendererComponent: AuditModifiedFieldComponent,
                    exportFormatter: this.cellFormatterService.auditModifiedFieldsExportFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Date',
                    field: 'ModifiedDate',
                    formatter: this.cellFormatterService.dateTimeUTCFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Modified By',
                    field: 'ModifiedBy',
                    formatter: this.cellFormatterService.userNameFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Input Name',
                    field: 'InputName',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('InputName', 'InputName'),
                    cellClass: AuditClassFactory.createCellClass('inputname'),
                },
                {
                    displayName: 'Task',
                    field: 'Task',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('Task', 'Task'),
                    cellClass: AuditClassFactory.createCellClass('task'),
                },
                {
                    displayName: 'Active',
                    field: 'IsActive',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('IsActive', 'IsActive'),
                    cellClass: AuditClassFactory.createCellClass('isactive'),
                },
                {
                    displayName: 'Required',
                    field: 'IsRequired',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('IsRequired', 'IsRequired'),
                    cellClass: AuditClassFactory.createCellClass('isrequired'),
                },
                {
                    displayName: 'Data Type',
                    field: 'DataType',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('DataType', 'DataType'),
                    cellClass: AuditClassFactory.createCellClass('datatype'),
                },
                {
                    displayName: 'Minimum',
                    field: 'ValidationMin',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('ValidationMin', 'ValidationMin'),
                    cellClass: AuditClassFactory.createCellClass('validationmin'),
                    visible: false
                },
                {
                    displayName: 'Maximum',
                    field: 'ValidationMax',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('ValidationMax', 'ValidationMax'),
                    cellClass: AuditClassFactory.createCellClass('ValidationMax'),
                    visible: false
                },
                {
                    displayName: 'Number of Rows',
                    field: 'TextLineCount',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('TextLineCount', 'TextLineCount'),
                    cellClass: AuditClassFactory.createCellClass('textlinecount'),
                    visible: false
                },
                {
                    displayName: 'Enumeration',
                    field: 'Enumeration',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('Enumeration', 'Enumeration'),
                    cellClass: AuditClassFactory.createCellClass('enumeration'),
                    visible: false
                },
                {
                    displayName: 'Vocabulary',
                    field: 'Vocabulary',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('Vocabulary', 'Vocabulary'),
                    cellClass: AuditClassFactory.createCellClass('vocabulary'),
                    visible: false
                },
                {
                    displayName: 'Dosing Table',
                    field: 'DosingTable',
                    ignore: !this.isCRO,
                    formatter: this.cellFormatterService.createAuditColumnFormatter('DosingTable', 'DosingTable'),
                    cellClass: AuditClassFactory.createCellClass('dosingtable'),
                    visible: false
                },
                {
                    displayName: this.translationService.translate('Job') + ' Characteristic',
                    field: 'JobCharacteristic',
                    ignore: !this.isCRO,
                    formatter: this.cellFormatterService.createAuditColumnFormatter('JobCharacteristic', this.translationService.translate('Job') + 'Characteristic'),
                    cellClass: AuditClassFactory.createCellClass((this.translationService.translate('Job') + 'Characteristic').toLowerCase()),
                    visible: false
                },
                {
                    displayName: 'Sort Order',
                    field: 'SortOrder',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('SortOrder', 'SortOrder'),
                    cellClass: AuditClassFactory.createCellClass('sortorder'),
                    visible: false
                },
                {
                    displayName: 'Created By',
                    field: 'CreatedBy',
                    formatter: this.cellFormatterService.userNameFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Created Date',
                    field: 'DateCreated',
                    formatter: this.cellFormatterService.dateTimeUTCFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                }
            ]
        };
    }
}

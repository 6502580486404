import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

/**
 * Used by internally climb-data-table.component and children only
 */
@Injectable()
export class AgGridCommService {

    private addItemClickedSource = new Subject<void>();
    private deleteItemClickedSource = new Subject<any>();
    private detailLinkClickedSource = new Subject<any>();

    addItemClicked$ = this.addItemClickedSource.asObservable();
    deleteItemClicked$ = this.deleteItemClickedSource.asObservable();
    detailLinkClicked$ = this.detailLinkClickedSource.asObservable();

    addItemClicked() {
        this.addItemClickedSource.next();
    }

    deleteItemClicked(item: any) {
        this.deleteItemClickedSource.next(item);
    }

    detailLinkClicked(item: any) {
        this.detailLinkClickedSource.next(item);
    }
}

import {
    Component,
    EventEmitter,
    Input,
    Output
} from '@angular/core';

import { SearchService } from '../search/search.service';
import { SearchQueryDef } from '../search/search-query-def';
import { lowerCaseCompare } from '../common/util';

@Component({
    selector: 'animal-select',
    template: `
    <div class="animal-select">
        <climb-typeahead
            [(model)]="model"
            [resultFormatter]="animalDisplayFormatter"
            [keyFormatter]="animalKeyFormatter"
            [search]="searchAnimals"
            [keySearch]="searchAnimalsByKey"
            [exactMatchFunction]="isAnimalExactMatch"
            [placeholder]="placeholder"
            [required]="required"
            [namespace]="'animal'"
            (selectItem)="selectItemHandler($event)"
            #typeahead
        ></climb-typeahead>
    </div>
    `,
    styles: [`
        .animal-select {
            min-width: 100px;
        }
    `]
})
export class AnimalSelectComponent {
    @Input() model: any;
    @Input() placeholder: string;
    @Input() required: boolean;
    @Input() selectByAnimalID: boolean;

    @Output() modelChange: EventEmitter<any> = new EventEmitter<any>();

    constructor(
        private searchService: SearchService
    ) {
        //
    }

    selectItemHandler(item: any) {
        this.modelChange.emit(this.model);
    }

    searchAnimals = (search: string): Promise<any[]> => {
        let filter;
        if (this.selectByAnimalID) {
            filter = {
                Identifier: search
            };
        } else {
            filter = {
                AnimalName: search
            };
        }

        let count = null;
        // if 2 characters or less, put a limit on number returned
        if (search.length < 3) {
            count = 50;
        }

        return this._searchAnimals(filter, count);
    }

    searchAnimalsByKey = (key: any): Promise<any[]> => {
        const filter = {
            MaterialKey: key
        };

        return this._searchAnimals(filter, 1);
    }

    private _searchAnimals(animalFilter: any, count: number): Promise<any[]> {
        let searchQueryDef: SearchQueryDef;
        if (this.selectByAnimalID) {
            searchQueryDef = {
                entity: 'Animals',
                page: 1,
                size: count,
                sortColumn: 'Identifier',
                sortDirection: 'asc',
                filter: animalFilter
            };
        } else {
            searchQueryDef = {
                entity: 'Animals',
                page: 1,
                size: count,
                sortColumn: 'AnimalNameSortable',
                sortDirection: 'asc',
                filter: animalFilter
            };
        }

        return this.searchService.getEntitiesBySearch(searchQueryDef)
            .then((results: any) => {
                return results.data.map((animal: any) => {
                    // strip away all but the essential attributes from animal
                    // so that it can be easily serialized when facet is saved
                    return {
                        AnimalName: animal.AnimalName,
                        C_Material_key: animal.MaterialKey,
                        MicrochipIdentifier: animal.MicrochipIdentifier,
                        Identifier: animal.Identifier
                    };
                });
            });
    }

    /**
     * Alter exact match behavior to include matching on MicrochipIdentifer
     */
    isAnimalExactMatch = (data: any[], term: string) => {
        if (term &&
            data &&
            data.length === 1
        ) {
            return lowerCaseCompare(data[0].AnimalName, term) ||
                lowerCaseCompare(data[0].MicrochipIdentifier, term);
        }
        return false;
    }

    animalDisplayFormatter = (item: any) => {
        if (this.selectByAnimalID) {
            return item.Identifier;
        }
        return item.AnimalName;
    }

    animalKeyFormatter = (item: any) => {
        return item.C_Material_key;
    }
}

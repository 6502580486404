/**
 * Returns one of the passed text values, based on the number specified.
 *
 * @example```
 *  pluralize(1, 'man', 'men'); // man
 *  pluralize(2, 'instance'); // instances
 * ```
 */
export const pluralize = (count: number, singular: string, plural?: string): string => {
    const pluralForm = plural ?? `${singular}s`;
    return count === 1 ? singular : pluralForm;
};

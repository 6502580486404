import {
    CellFormatterService,
    FormatterOptions,
    TableOptions
} from '../common/datatable';
import { TranslationService } from '../services/translation.service';
import { AuditModifiedFieldComponent } from './audit-modified-fields/audit-modified-field.component';
import { AuditClassFactory } from './audit-class-maker';

export class AuditClinicalObservationDetailsTableOptions {
    options: TableOptions;

    constructor(
        private cellFormatterService: CellFormatterService,
        private translationService: TranslationService
    ) {
        this.options = this.getDefaultOptions();
    }

    getDefaultOptions(): TableOptions {
        return {
            csvFileName: 'clinical-observation-details-audit.csv',
            enableDetailColumn: false,
            enableDraggable: false,
            enableSelectable: false,
            enableAuditColumns: false,
            columns: [
                {
                    displayName: 'Type',
                    field: 'UpdateType',
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Modified Fields',
                    field: 'ModifiedFields',
                    sortField: 'ModifiedFields',
                    rendererComponent: AuditModifiedFieldComponent,
                    exportFormatter: this.cellFormatterService.auditModifiedFieldsExportFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Date',
                    field: 'ModifiedDate',
                    formatter: this.cellFormatterService.dateTimeUTCFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Modified By',
                    field: 'ModifiedBy',
                    formatter: this.cellFormatterService.userNameFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Urgent',
                    field: 'Urgent',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('Urgent', 'Urgent'),
                    cellClass: AuditClassFactory.createCellClass('urgent'),
                },
                {
                    displayName: 'Animal ID',
                    field: 'AnimalID',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('AnimalID', 'AnimalID'),
                    cellClass: AuditClassFactory.createCellClass('animalid'),
                },
                {
                    displayName: 'Animal Name',
                    field: 'AnimalName',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('AnimalName', 'AnimalName'),
                    cellClass: AuditClassFactory.createCellClass('animalname'),
                },
                {
                    displayName: 'Date Observed',
                    field: 'DateObserved',
                    formatter: this.cellFormatterService.dateTimeUTCFormatter,
                    cellClass: AuditClassFactory.createCellClass('dateobserved'),
                },
                {
                    displayName: 'Observed By',
                    field: 'ResourceName',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('ResourceName', 'ResourceName'),
                    cellClass: AuditClassFactory.createCellClass('resourcename'),
                },
                {
                    displayName: 'Observation Status',
                    field: 'ObservationStatus',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('ObservationStatus', 'ObservationStatus'),
                    cellClass: AuditClassFactory.createCellClass('observationstatus'),
                },
                {
                    displayName: 'Clinical Observation',
                    field: 'ClinicalObservation',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('ClinicalObservation', 'ClinicalObservation'),
                    cellClass: AuditClassFactory.createCellClass('clinicalobservation'),
                },
                {
                    displayName: this.translationService.translate('Experimental'),
                    field: 'Experimental',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('Experimental', (this.translationService.translate('Experimental'))),
                    cellClass: AuditClassFactory.createCellClass(this.translationService.translate('Experimental').toLowerCase()),
                },
                {
                    displayName: 'Created By',
                    field: 'CreatedBy',
                    formatter: this.cellFormatterService.userNameFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Created Date',
                    field: 'DateCreated',
                    formatter: this.cellFormatterService.dateTimeUTCFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                }
            ]
        };
    }
}

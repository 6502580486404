<div class="row chart-settings-container">
    <div class="col-md-7">
        <div class="row-container">

            <h4 class="mt-1">{{'Lines' | translate}}</h4>

            <!-- Selected lines -->
            <div *ngIf="selectedLines| notEmpty">
                <table class="table table-sm table-nonfluid mb-1">
                    <tbody>
                        <tr *ngFor="let item of selectedLines; let index = index">
                            <td class="icon-cell">
                                <a (click)="removeSelectedLine(index)">
                                    <i class="fa fa-remove remove-item" title="Remove {{'Line' | translate}}"></i>
                                </a>
                            </td>
                            <td>{{item.LineName}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <form role="form">
                <!-- Line -->
                <div class="form-group">
                    <label class="sr-only">{{'Line' | translate}}</label>
                    <climb-indirect-typeahead [search]="searchLines"
                                              [resultFormatter]="lineNameFormatter"
                                              [placeholder]="('Line' | translate) + ' name&hellip;'"
                                              (selectItem)="selectLine($event)"></climb-indirect-typeahead>
                </div>
            </form>
        </div>
        <div class="row-container">
            <h4 class="mt-1">Chart Options</h4>
            <form role="form" class="form-inline align-items-center">
                <div class="form-group mb-1">
                    <label class="col-sm-4 col-form-label">Birth Date Range</label>
                    <div class="col-sm-4">
                        <climb-ngb-date #dateControl="ngModel"
                                        #dateStartPicker
                                        name="Birth Date Start"
                                        [(ngModel)]="dateFilterStart"
                                        placeholder="From&hellip;"></climb-ngb-date>
                    </div>
                    <div class="col-sm-4">
                        <climb-ngb-date #dateControl="ngModel"
                                        #dateEndPicker
                                        name="Birth Date End"
                                        [(ngModel)]="dateFilterEnd"
                                        placeholder="To&hellip;"></climb-ngb-date>
                    </div>
                </div>
            </form>

            <div class="btn-container">
                <button type="submit"
                        class="btn btn-lg btn-primary"
                        [disabled]="!isInputValid()"
                        (click)="reloadBreedingEfficiencyData()">
                    Create Chart
                </button>
            </div>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-md-12">
        <div class="chart-container">
            <div *ngIf="errorMessage" class="text-danger help-container">
                <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                {{errorMessage}}
            </div>
            <div *ngIf="linesWithoutData.length && rendered" class="help-container text-warning">
                <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                These {{'Lines' | translate}} do not have breeding efficiency data:
                {{linesWithoutData | map:'LineName' | delimit:", "}}
            </div>
            <loading-overlay *ngIf="loading" [message]="'Loading'"></loading-overlay>
            <div class="d-flex justify-content-center" *ngIf="!rendered">
                <h2>Data not yet selected. Please select data to view.</h2>
            </div>
            <dx-chart id="chart"
                      [title]="'Breeding Efficiency By ' + ('Line' | translate)"
                      [dataSource]="dataSource"
                      *ngIf="!loading && rendered"
                      palette="Green Mist">
                <ng-container *ngIf="rendered">
                    <dxi-series *ngFor="let line of linesOnChart"
                                [valueField]="line"
                                [name]="line"
                                [barPadding]="0">
                        <dxo-value-error-bar [highValueField]="line + '_HIGH'"
                                             [lowValueField]="line + '_LOW'">

                        </dxo-value-error-bar>
                    </dxi-series>
                </ng-container>
                <dxo-tooltip [zIndex]="100000" [enabled]="true" contentTemplate="content"></dxo-tooltip>
                <div *dxTemplate="let info of 'content'">
                    {{formatTooltip(info)}}
                </div>
                <dxo-argument-axis title="Litter Number"></dxo-argument-axis>
                <dxi-value-axis title="Live Offspring Per Birth"></dxi-value-axis>
                <dxo-common-series-settings argumentField="litterNumber"
                                            type="bar"
                                            hoverMode="onlyPoint"
                                            selectionMode="onlyPoint">
                    <dxo-label [visible]="false">
                    </dxo-label>
                </dxo-common-series-settings>
                <dxo-legend>
                </dxo-legend>
                <dxo-export [enabled]="true"></dxo-export>
            </dx-chart>
            <p style="margin-left: 10px" *ngIf="rendered">Error bars denote &plusmn; 1 SD</p>
        </div> <!-- /.chart-container -->
    </div> <!-- /.col -->
</div> <!-- /.row -->

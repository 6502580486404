<div class="intro">
    <p>
        Bulk export your animal and test results from a task.
    </p>
</div>

<div class="list-toolbar">
    <button type="button"
            class="btn btn-fw-xwide btn-secondary"
            (click)="onClickRefresh()"
            [disabled]="loading">
        <ng-container *ngIf="!loading; else loadingIndicator">
            <i class="fa fa-refresh" aria-hidden="true"></i>
            Refresh
        </ng-container>
        <ng-template #loadingIndicator>
            <i class="fa fa-spinner fa-spin" title="Loading&hellip;"></i>
        </ng-template>
    </button>

    <button type="button"
            class="btn btn-fw-xwide btn-primary"
            (click)="requestExport(reportType.value)">
        <i class="fa fa-plus-circle" aria-hidden="true"></i>
        Request Export
    </button>
</div>

<table class="table">
    <thead>
        <tr>
            <th scope="col" class="icon-cell"></th>
            <th scope="col">Filename</th>
            <th scope="col">Status</th>
            <th scope="col">Started</th>
        </tr>
    </thead>
    <tbody>
        <ng-container *ngIf="exportFileRequests | notEmpty; else noRequests">
            <tr *ngFor="let exportFileRequest of exportFileRequests">
                <td class="icon-cell">
                    <ng-container *ngIf="exportFileRequest.StoredFileMap | notEmpty">
                        <a (click)="downloadFile(exportFileRequest.StoredFileMap[0].StoredFile)"
                           class="download-link">
                            <i class="fa fa-download" title="Download file"></i>
                        </a>
                    </ng-container>
                </td>
                <td>
                    {{exportFileRequest.DefaultFileName}}
                </td>
                <td class="text-nowrap">
                    <!-- Status icon -->
                    <ng-container [ngSwitch]="exportFileRequest.Status">
                        <i class="fa fa-spinner fa-spin"
                           title="{{exportFileRequest.Status}}"
                           aria-hidden="true"
                           *ngSwitchCase="RequestStatus.Requested"></i>
                        <i class="fa fa-spinner fa-spin"
                           title="{{exportFileRequest.Status}}"
                           aria-hidden="true"
                           *ngSwitchCase="RequestStatus.Processing"></i>
                        <i class="fa fa-check text-success"
                           title="{{exportFileRequest.Status}}"
                           aria-hidden="true"
                           *ngSwitchCase="RequestStatus.Completed"></i>
                        <i class="fa fa-exclamation-triangle text-danger"
                           title="{{exportFileRequest.Status}}"
                           aria-hidden="true"
                           *ngSwitchCase="RequestStatus.Failed"></i>
                    </ng-container>
                    {{exportFileRequest.Status}}
                </td>
                <td>
                    {{exportFileRequest.DateCreated | formatShortDateTime}}
                </td>
            </tr>
        </ng-container>

        <ng-template #noRequests>
            <tr>
                <td colspan="4">
                    <em>No exports have been requested.</em>
                </td>
            </tr>
        </ng-template>
    </tbody>
</table>

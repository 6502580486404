export const DATE_EN_GB = "dd-MMM-yyyy";
export const DATE_EN_US = "MM/dd/yyyy";
export const TIME_EN_GB = "HH:mm:ss 'UTC'ZZ";
export const TIME_EN_US = "hh:mm:ss a";

// this is how the workflow facet formats date
export const DATE_TIME_WORKFLOW = "M/d/yyyy h:mm:ss a";
// this is how the schedule facet formats date
export const DATE_SCHEDULE = "MM/dd/yyyy";

// This is the format the the formatTime function expects
export const TIME_FORMATTER_FORMAT = "hh:mm a";
export const MONTH_FORMAT = 'MMM';
export const MONTH_DATE_FORMAT = 'MMM d';
export const YEAR_FORMAT = 'yyyy';

import {
    CellFormatterService,
    FormatterOptions,
    TableOptions
} from '../common/datatable';
import { AuditModifiedFieldComponent } from './audit-modified-fields/audit-modified-field.component';
import { AuditClassFactory } from './audit-class-maker';

export class AuditRoleFacetsTableOptions {

    options: TableOptions;

    constructor(
        private cellFormatterService: CellFormatterService
    ) {
        this.options = this.getDefaultOptions();
    }

    getDefaultOptions(): TableOptions {

        return {
            csvFileName: 'role-facets-audit.csv',
            enableDetailColumn: false,
            enableDraggable: false,
            enableSelectable: false,
            enableAuditColumns: false,
            columns: [
                {
                    displayName: 'Type',
                    field: 'UpdateType',
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Modified Fields',
                    field: 'ModifiedFields',
                    sortField: 'ModifiedFields',
                    rendererComponent: AuditModifiedFieldComponent,
                    exportFormatter: this.cellFormatterService.auditModifiedFieldsExportFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Date',
                    field: 'ModifiedDate',
                    formatter: this.cellFormatterService.dateTimeUTCFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Modified By',
                    field: 'ModifiedBy',
                    formatter: this.cellFormatterService.userNameFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Facet Name',
                    field: 'FacetName',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('FacetName', 'FacetName'),
                    cellClass: AuditClassFactory.createCellClass('facetname'),
                },
                {
                    displayName: 'Role Name',
                    field: 'RoleName',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('RoleName', 'RoleName'),
                    cellClass: AuditClassFactory.createCellClass('rolename'),
                },
                {
                    displayName: 'Has Read Access',
                    field: 'HasReadAccess',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('HasReadAccess', 'HasReadAccess'),
                    cellClass: AuditClassFactory.createCellClass('hasreadaccess'),
                },
                {
                    displayName: 'Has Write Access',
                    field: 'HasWriteAccess',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('HasWriteAccess', 'HasWriteAccess'),
                    cellClass: AuditClassFactory.createCellClass('haswriteaccess'),
                },
                {
                    displayName: 'Sort Order',
                    field: 'SortOrder',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('SortOrder', 'SortOrder'),
                    cellClass: AuditClassFactory.createCellClass('sortorder'),
                },
                {
                    displayName: 'Created By',
                    field: 'CreatedBy',
                    formatter: this.cellFormatterService.userNameFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Created Date',
                    field: 'DateCreated',
                    formatter: this.cellFormatterService.dateTimeUTCFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                }
            ]
        };
    }
}

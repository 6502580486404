<ng-container [ngSwitch]="facetView">
    <export-request-list (addItem)="addItemClicked($event)"
                         [reportType]="reportType"
                         *ngSwitchCase="FacetView.LIST_VIEW"></export-request-list>

    <export-requester [reportType]="reportType"
                      (cancel)="exitRequesterClicked()"
                      (exit)="exitRequesterClicked()"
                      *ngSwitchCase="'BULK_EXPORT'"></export-requester>
    <animal-check-requester
    (exit)="exitRequesterClicked()"
    *ngSwitchCase="'ANIMAL_CHECK'">

    </animal-check-requester>
</ng-container>

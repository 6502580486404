import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppInsightsClickDirective } from '@common/directives/app-insights-click.directive';
import { ArrowKeyAsTabDirective } from './arrow-key-as-tab.directive';
import { ResizeObserverDirective } from '@common/directives/resize-observer.directive';
import { ShowControlErrorDirective, ShowControlErrorMessageDirective } from '@common/directives/show-control-error.directive';
import { TrapFocusDirective } from './trap-focus.directive';
import { CharacterCounterDirective } from './character-counter.directive';

@NgModule({
    imports: [
        CommonModule,
    ],
    declarations: [
        AppInsightsClickDirective,
        ArrowKeyAsTabDirective,
        CharacterCounterDirective,
        ResizeObserverDirective,
        ShowControlErrorDirective,
        ShowControlErrorMessageDirective,
        TrapFocusDirective,
    ],
    exports: [
        AppInsightsClickDirective,
        ArrowKeyAsTabDirective,
        CharacterCounterDirective,
        ResizeObserverDirective,
        ShowControlErrorDirective,
        ShowControlErrorMessageDirective,
        TrapFocusDirective,
    ],
})
export class BaseDirectivesModule { }

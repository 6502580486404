import {
    Component,
    OnInit,
    ViewChild,
    ViewChildren
} from '@angular/core';

import { ChartService } from './chart.service';
import { SearchService } from '../search/search.service';
import { LoggingService } from '../services/logging.service';
import { ClimbNgbDateComponent } from '@common/date/climb-ngb-date/climb-ngb-date.component';
import { NgModel } from '@angular/forms';
import { dateControlValidator } from '@common/util/date-control.validator';

const CURRENT_SELECTOR = 'breeding-efficiency-chart';
const SHOW_TOAST = true;

@Component({
    selector: CURRENT_SELECTOR,
    templateUrl: './breeding-efficiency-chart.component.html',
    styleUrls: ['./charts-facet.component.scss'],
})
export class BreedingEfficiencyChartComponent implements OnInit {
    @ViewChildren('dateControl') dateControls: NgModel[];

    // TODO: Add "No Data Found" screen to charts without data, on this branch and the branches for the Task Output and Studies Gantt Charts
    @ViewChild('dateStartPicker') datePickerStart: ClimbNgbDateComponent;
    @ViewChild('dateEndPicker') datePickerEnd: ClimbNgbDateComponent;
    breedingEfficienciesByLine: any = {};
    selectedLines: any[] = [];
    linesOnChart: string[];

    private _dateFilterStart: Date = null;
    private _dateFilterEnd: Date = null;

    dataSource: any[];

    loading: boolean;
    rendered: boolean;

    errorMessage: string;

    constructor(
        private chartService: ChartService,
        private searchService: SearchService,
        private loggingService: LoggingService
    ) {
    }

    ngOnInit() {
        this.dataSource = [];
        this.loading = false;
        this.rendered = false;
    }


    reloadBreedingEfficiencyData() {
        const errMessage = dateControlValidator(this.dateControls);
        if (errMessage) {
            this.loggingService.logError(errMessage, null, '', true);
            return;
        }
        this.loading = true;
        const filter = {
            startDate: this._dateFilterStart ? this._dateFilterStart.toISOString() : null,
            endDate: this._dateFilterEnd ? this._dateFilterEnd.toISOString() : null,
        };

        return this.chartService.getBreedingEfficiencyByLine(filter).then((result: any) => {
            this.breedingEfficienciesByLine = {};
            for (const bes of result.data) {
                this.breedingEfficienciesByLine[bes.LineKey] = bes;
            }
            this.updatePlot();
            this.loading = false;
            this.rendered = true;
        }).catch((error: Error) => {
            this.loading = false;
            this.displayLoadError();
            this.loggingService.logError(
                "Failed to load data for chart: " + error.message,
                null,
                CURRENT_SELECTOR,
                SHOW_TOAST
            );
        });
    }

    displayLoadError(): void {
        this.errorMessage = "Failed to load data for chart. Please try again.";
    }

    isInputValid(): boolean {
        return this.selectedLines.length >= 1 && this.dateFilterEnd !== null && this.dateFilterStart !== null;
    }

    get dateFilterStart(): Date {
        return this._dateFilterStart;
    }

    set dateFilterStart(newDateFilterStart: Date) {
        if (!newDateFilterStart) {
            this._dateFilterStart = null;
            return;
        }
        if (!this._dateFilterEnd) {
            this._dateFilterStart = newDateFilterStart;
        } else if (newDateFilterStart.getTime() < this._dateFilterEnd.getTime()) {
            this._dateFilterStart = newDateFilterStart;
        } else {
            this.datePickerStart.clear();
        }
    }

    get dateFilterEnd(): Date {
        return this._dateFilterEnd;
    }

    set dateFilterEnd(newDateFilterEnd: Date) {
        if (!newDateFilterEnd) {
            this._dateFilterEnd = null;
            return;
        }
        if (!this._dateFilterStart) {
            this._dateFilterEnd = newDateFilterEnd;
        } else if (newDateFilterEnd.getTime() > this._dateFilterStart.getTime()) {
            this._dateFilterEnd = newDateFilterEnd;
        } else {
            this.datePickerEnd.clear();
        }
    }

    get selectedBreedingEfficiencies(): any[] {
        const bes = [];
        for (const currLine of this.selectedLines) {
            const currBes = this.breedingEfficienciesByLine[currLine.LineKey];
            if (currBes) {
                bes.push(currBes);
            }
        }

        return bes;
    }

    get linesWithoutData(): any[] {
        return this.selectedLines.filter((currLine: any) =>
            !this.breedingEfficienciesByLine[currLine.LineKey]);
    }

    searchLines = (term: string): Promise<any> => {
        return this.searchService.searchActiveLines(term).then((results: any) => {
            return results.data;
        });
    }

    lineNameFormatter = (value: any) => {
        return value.LineName;
    }

    selectLine(line: any) {
        const isLinePresent = this.selectedLines.find((l: any) => l.LineKey === line.LineKey) !== undefined;
        if (!isLinePresent) {
            this.selectedLines.push(line);
        }
    }

    removeSelectedLine(index: number) {
        if (index >= 0 && index < this.selectedLines.length) {
            this.selectedLines.splice(index, 1);
        }
    }

    updateLinesOnChart(): void {
        const linesWithoutData = this.linesWithoutData;
        this.linesOnChart = this.selectedLines.map((line: any) => {
            if (this.doesLineHaveData(linesWithoutData, line.LineName)) {
                return line.LineName;
            }
        }).filter((lineName: string) => lineName !== undefined);
    }

    doesLineHaveData(linesWithoutData: any[], lineName: string): boolean {
        // if the line supplied is found in the list of lines WITHOUT data, that means the line supplied has no data. So a negative foundIndex is the true result.
        const foundIndex = linesWithoutData.findIndex((line: any) => line.LineName === lineName);
        return foundIndex === -1;
    }

    updatePlot(): void {
        this.updateLinesOnChart();
        const combinedSeriesMap = {};
        const combinedSeries: any[] = [];
        const linesWithoutData = this.linesWithoutData;
        // group the breeding efficiencies, agnostic of what line they are from, by litter number.
        for (const currBE of this.selectedBreedingEfficiencies) {
            if (this.doesLineHaveData(linesWithoutData, currBE.LineName)) {
                for (const efficiency of currBE.BreedingEfficiencies) {
                    if (combinedSeriesMap[efficiency.LitterNumber] === undefined) {
                        combinedSeriesMap[efficiency.LitterNumber] = [];
                    }
                    combinedSeriesMap[efficiency.LitterNumber].push(efficiency);
                }
            }
        }

        // for each litter present, create a point that contains the litter number and information about the line's mean live count ± error.
        for (let litterNumber = 1; litterNumber < Object.keys(combinedSeriesMap).length + 1; litterNumber++) {
            const seriesPoint = { litterNumber: litterNumber.toString() };
            for (const seriesGroup of combinedSeriesMap[litterNumber]) {
                const lineName = seriesGroup.LineName;
                seriesPoint[lineName] = seriesGroup.MeanLiveCount;
                seriesPoint[lineName + "_LOW"] = seriesGroup.MeanLiveCount - this.getBreedingEfficiencyError(seriesGroup);
                seriesPoint[lineName + "_HIGH"] = seriesGroup.MeanLiveCount + this.getBreedingEfficiencyError(seriesGroup);
            }
            combinedSeries.push(seriesPoint);
        }
        this.dataSource = combinedSeries;
    }

    getBreedingEfficiencyError(be: any): number {
        return be.StdDevLiveCount / Math.sqrt(be.SampleSize);
    }

    formatTooltip(arg: any): string {
        return `${+arg.originalValue.toFixed(6)} ± ${+(arg.originalValue - arg.lowErrorValue).toFixed(6)}`;
    }
}

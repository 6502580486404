import { TaxonCharacteristicInstance } from "../../common/types";

export interface EntityMap {
    [index: string]: any;
}

export interface BooleanMap {
    [index: string]: boolean;
}

export interface CharacteristicMap {
    [index: number]: TaxonCharacteristicInstance;
}
export class DataRow {
    index: any;
    key: string;

    task: any;
    job: any;
    animal: any;
    sample: any;
    material: any;
    cohorts: any[] = [];

    // Cell elision flags
    taskFirst = true;
    animalFirst = true;
    sampleFirst = true;

    // Per-row conditional classes
    classes: EntityMap = {};

    // Per-task maps of Inputs and Outputs
    inputs: EntityMap = {};
    outputs: EntityMap = {};

    hasInherited = false;
    needsInherited = false;

    hasCalculated = false;

    taskOutputSet: any;
    taskOutputs: EntityMap = {};

    taskMaterialPool: any;

    outputAnimal: any;
    outputSample: any;

    scanMaterial: any;
    scanTimeout: any;

    currentHousingId?: string;

    // key = C_TaxonCharacteristic_key , value = TaxonCharacteristicInstance
    characteristics: CharacteristicMap = {};
    /**
     * Copy this row and apply the additional properties.
     */
    extend(props: any): DataRow {
        const copy: DataRow = Object.create(this);

        Object.assign(copy, this, { outputValues: {} }, props);

        return copy;
    }
}

export class IOColumn {
    key: string;
    label: string;
    visible = true;
    showBulk = true;

    isInherited = false;
    isCalculated = false;
    hideFlagAndFlagMessageColumns: boolean;

    // Bulk output
    output: any;
    value: any = null;

    constructor(key: string, label: string, output: any = null) {
        this.key = key;
        this.label = label;
        this.output = output;
    }
}

import {
    Component,
    Input,
    OnInit,
    OnDestroy,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ClinicalService } from '../clinical.service';
import { TranslationService } from '../../services/translation.service';

import {
    BaseFacet,
    BaseFacetService,
    FacetView,
    IFacet
} from '../../common/facet';

import { ClinicalTableOptions } from '../clinical-table-options';
import { ClinicalFilterComponent } from '../clinical-filter/clinical-filter.component';

import {
    CellFormatterService,
    TableState,
    DataResponse,
    ColumnsState,
    TableColumnDef,
} from '@common/datatable';
import { CopyBufferService } from '../../common/services/copy-buffer.service';
import { WorkspaceFilterService } from '../../services/workspace-filter.service';
import { DateFormatterService } from '@common/util/date-time-formatting';
import { filterToDate, getSafeProp } from '@common/util';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { WorkspaceService } from '../../workspaces/workspace.service';
import { AnimalService } from '../../animals/services/animal.service';
import { BulkAddCommService } from '../../common/facet/bulk-add-comm.service';
import { FeatureFlagService, LARGE_ANIMAL } from '../../services/feature-flags.service';
import { WorkflowService } from '../../workflow/services/workflow.service';
import { Animal, AnimalHealthRecord, Entity } from '../../common/types';
import { DataContextService } from '@services/data-context.service';
import { takeUntil } from 'rxjs/operators';
import { arrowClockwise, brokenChain, chain, magnifier, squareOnSquare } from '@icons';

@Component({
    selector: 'clinical-facet',
    templateUrl: './clinical-facet.component.html',
    providers: BaseFacet.BASE_COMPONENT_PROVIDERS.concat([
        BulkAddCommService
    ])
})
export class ClinicalFacetComponent extends BaseFacet<Entity<AnimalHealthRecord>>
    implements OnInit, OnDestroy {
    @Input() facetId: string;
    @Input() facet: IFacet;

    readonly icons = { arrowClockwise, brokenChain, chain, magnifier, squareOnSquare };

    componentName = 'clinical';
    clinicalTableOptions: ClinicalTableOptions;

    isGLP = false;
    largeAnimalEnabled = false;

    readonly COMPONENT_LOG_TAG = 'clinical-facet';
    readonly NEW_RECORD_VIEW: FacetView = FacetView.NEW_RECORD_VIEW;
    readonly BULK_OBSERVATION_VIEW: FacetView = FacetView.BULK_OBSERVATION_VIEW;
    readonly BULK_TREATMENT_VIEW: FacetView = FacetView.BULK_TREATMENT_VIEW;
    readonly BULK_OBSERVATION_EDIT_VIEW = FacetView.BULK_OBSERVATION_EDIT_VIEW;
    readonly BULK_TREATMENT_EDIT_VIEW = FacetView.BULK_TREATMENT_EDIT_VIEW;

    componentFilterSubscription: Subscription;
    isWorkflowAdd: boolean;
    isSyncItem: boolean;
    taskData: any; // Used to sync process

    private _subscriptions: Subscription[] = [];
    private notifier$ = new Subject<void>();

    dataTableColumns: BehaviorSubject<TableColumnDef[]>;
    dataTableColumns$: Observable<TableColumnDef[]>;

    constructor(
        baseFacetService: BaseFacetService,
        private clinicalService: ClinicalService,
        private cellFormatterService: CellFormatterService,
        private copyBufferService: CopyBufferService,
        private translationService: TranslationService,
        private modalService: NgbModal,
        workspaceFilterService: WorkspaceFilterService,
        private workspaceService: WorkspaceService,
        private animalService: AnimalService,
        private featureFlagService: FeatureFlagService,
        private workflowService: WorkflowService,
        private dateFormatterService: DateFormatterService,
        private dataContext: DataContextService,
    ) {
        super(
            baseFacetService,
            workspaceFilterService
        );
        this.initIsGLP();
        this.initLargeAnimal();

        this.clinicalTableOptions = new ClinicalTableOptions(
            this.cellFormatterService,
            this.translationService,
            this.dateFormatterService,
            this.isGLP,
            this.largeAnimalEnabled
        );

        this.dataTableColumns = new BehaviorSubject(this.clinicalTableOptions.options.columns);
        this.dataTableColumns$ = this.dataTableColumns.asObservable();

        this.dataService = {
            run: (tableState: TableState) => {
                return this.loadHealthRecords(tableState);
            }
        };
    }

    // lifecycle
    ngOnInit() {
        super.ngOnInit();
        const s1 = this.workspaceService.workspaceConnectivity$.subscribe((animalKey) => {
            this.loading = true;
            this.createOrFetchHealthRecord(animalKey).then((healthRecord: any) => {
                this.loading = false;
                if (healthRecord) {
                    this.isWorkflowAdd = true;
                    this.createHealthRecord(healthRecord);
                }
            });
        });

        this._subscriptions = [s1];
        this.initialize();

        const syncSubscription = this.workflowService.clinicalSync$.subscribe((t: any) => {
            if (t === null) {
                this.taskData = {
                    materialKey: null
                };
                this.isSyncItem = false;
                return;
            }
            if (!t.animal) {
                return;
            }

            this.taskData = {
                materialKey: t.animal.C_Material_key
            };
            this.isSyncItem = true;

            if (t.animal.AnimalHealthRecord && t.animal.AnimalHealthRecord.ModifiedBy) {
                this.detailLinkClick(t.animal.AnimalHealthRecord);
            } else {
                this.changeView(this.NEW_RECORD_VIEW);
            }
        });

        this._subscriptions.push(syncSubscription);

        this.dataContext.onCancel$.pipe(takeUntil(this.notifier$)).subscribe(() => {
            this.refreshData();
        });
    }

    initIsGLP() {
        const flag = this.featureFlagService.getFlag("IsGLP");
        this.isGLP = (flag && flag.IsActive && flag.Value.toLowerCase() === "true");
    }

    initLargeAnimal() {
        this.largeAnimalEnabled = this.featureFlagService.isFlagOn(LARGE_ANIMAL);
    }
    ngOnDestroy() {
        if (this.componentFilterSubscription) {
            this.componentFilterSubscription.unsubscribe();
        }
        for (const subscription of this._subscriptions) {
            subscription.unsubscribe();
        }
        this.notifier$.next();
        this.notifier$.complete();
    }

    initialize() {
        this.restoreFilterState();
        this.changeView(this.LIST_VIEW);
    }

    refreshData() {
        this.initialize();
        this.dataTableComm.reloadTable();
    }

    restoreFilterState() {

        // process any grid filters
        if (this.facet && this.facet.GridFilter) {
            try {
                this.filter = JSON.parse(this.facet.GridFilter);
            } catch (err) {
                console.error(err);
            }

            if (this.filter) {
                this.filter.DateObservedStart = filterToDate(this.filter.DateObservedStart);
                this.filter.DateObservedEnd = filterToDate(this.filter.DateObservedEnd);
                this.filter.DateDueStart = filterToDate(this.filter.DateDueStart);
                this.filter.DateDueEnd = filterToDate(this.filter.DateDueEnd);
            } else {
                this.filter = {};
            }
        }
    }

    addItemClick() {
        this.taskData = null;
        this.changeView(this.NEW_RECORD_VIEW);
    }

    async loadHealthRecords(tableState: TableState): Promise<DataResponse<AnimalHealthRecord>> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'DateCreated DESC';

        this.setLoadingState(tableState.loadingMessage);

        try {
            const response = await this.clinicalService.getHealthRecords({
                page,
                size: pageSize,
                sort,
                filter: this.getActiveFilter()
            });

            const visibleColumns = this.getVisibleColumns(this.clinicalTableOptions.options);
            await this.clinicalService.ensureVisibleColumnsDataLoaded(response.results, visibleColumns);

            this.stopLoading();

            this.data = response.results as Entity<AnimalHealthRecord>[];
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        } finally {
            this.stopLoading();
        }
    }
    
    createHealthRecord(healthRecord: Entity<AnimalHealthRecord>) {
        this.itemToEdit = healthRecord;
        this.changeView(this.DETAIL_VIEW);
    }

    cancelHealthRecord() {
        this.changeView(this.LIST_VIEW);
    }

    openFilter() {
        const ref = this.modalService.open(ClinicalFilterComponent, { size: 'lg' });
        const component = ref.componentInstance as ClinicalFilterComponent;
        component.filter = this.filter;
        this.componentFilterSubscription = component.onFilter.subscribe((filter: any) => {
            this.filter = filter;
            this.runFilter();
        });
    }

    copyAnimals() {
        const animals: Animal[] = this.selectedRows.map((animalHealthRecord: Entity<AnimalHealthRecord>) => {
            return animalHealthRecord.Animal;
        });

        this.copyBufferService.copy(animals);
    }

    createOrFetchHealthRecord(animalKey: number): Promise<Entity<AnimalHealthRecord>> {
        // check if there is a health record for this animal
        // ...need the schema...
        return this.clinicalService.getHealthRecord(animalKey).then((healthRecord: any) => {
            if (healthRecord) {
                return healthRecord;
            }
            return this.animalService.getAnimal(animalKey).then((animal: any) => {
                this.taskData = {
                    materialKey: animal.C_Material_key
                };
                this.changeView(this.NEW_RECORD_VIEW);
            });
        });
    }

    async selectedColumnsChange({ visible }: ColumnsState) {
        try {
            this.facetLoadingState.changeLoadingState(true);
            await this.clinicalService.ensureVisibleColumnsDataLoaded(this.data, visible);
        } finally {
            this.facetLoadingState.changeLoadingState(false);
        }
    }

    editObservations() {
        const validRows = this.getValidRows(
            'clinical observation',
            'BULK_EDIT_OBSERVATION',
            'Animal.AnimalClinicalObservation[0]'
        );
        if (validRows.length > 0) {
            this.itemsToEdit = validRows;
            this.changeView(this.BULK_OBSERVATION_EDIT_VIEW);
        }
    }

    editTreatments() {
        const validRows = this.getValidRows(
            'treatment plan',
            'BULK_EDIT_TREATMENT',
            'TaskAnimalHealthRecord[0]'
        );
        if (validRows.length > 0) {
            this.itemsToEdit = validRows;
            this.changeView(this.BULK_TREATMENT_EDIT_VIEW);
        }
    }

    private getValidRows(errorType: string, source: string, path: string) {
        const validRows = [];
        for (const row of this.selectedRows) {
            if (getSafeProp(row, path)) {
                validRows.push(row);
            }
        }
        if (validRows.length < 1) {
            this.loggingService.logError(`The selected animal records do not have ${errorType}.`, null, source, true);
        } else if (validRows.length < this.selectedRows.length) {
            this.loggingService.logWarning(`One or more of the selected animal records does not have a ${errorType} and will be omitted from the Bulk Edit screen.`, null, source, true);
        }
        return validRows;
    }

    dragStart() {
        // TODO: implement
        this.loggingService.logError('Drag n Drop is not implemented for Clinical facet', null, this.COMPONENT_LOG_TAG, true);
    }

    dragStop() {
        // TODO: implement
    }
}

import { map } from 'rxjs/operators';
import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';

import { BaseFilter } from '../common/facet';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { LineVocabService } from './line-vocab.service';

import {
    focusElementByQuery
} from '../common/util/';


@Component({
    selector: 'line-filter',
    templateUrl: './line-filter.component.html'
})
export class LineFilterComponent extends BaseFilter implements OnInit {
    @Input() filter: any = {};
    @Output() onFilter = new EventEmitter<any>();
    @Output() close = new EventEmitter<any>();

    // CVs
    lineStatuses: any[] = [];
    lineTypes: any[] = [];
    taxons: any[] = [];

    readonly MULTI_PASTE_INPUT_LIMIT = 100;

    constructor(
        private activeModal: NgbActiveModal,
        private lineVocabService: LineVocabService
    ) {
        super(activeModal);
    }

    ngOnInit() {
        this.cloneFilter();

        this.getCVs();

        focusElementByQuery('[data-auto-focus="lineName"]');
    }

    private cloneFilter() {
        this.filter = this.copyFilter(this.filter);
    }

    private getCVs() {
        const p1 = this.lineVocabService.lineStatuses$.pipe(map((data) => {
            this.lineStatuses = data;
        })).toPromise();

        const p2 = this.lineVocabService.lineTypes$.pipe(map((data) => {
            this.lineTypes = data;
        })).toPromise();

        const p3 = this.lineVocabService.taxons$.pipe(map((data) => {
            this.taxons = data;
        })).toPromise();

        return Promise.all([p1, p2, p3]);
    }

    filterClicked(event: Event) {
        event.preventDefault();
        this.onFilter.emit(this.filter);
        this.close.emit();
        this.activeModal.close();
    }

    closeClicked() {
        this.close.emit();
        this.activeModal.dismiss();
    }

    clearClicked() {
        this.filter = {};
        this.onFilter.emit(this.filter);
        this.close.emit();
        this.activeModal.close();
    }
}

<div class="wizard-container container-fluid">

    <div class="card bg-success text-white">

        <div class="card-header">
            <h3 class="card-title">
                Set Up Your {{workgroupName}} Workgroup
            </h3>
        </div>

        <div class="card-body">

            <aw-wizard navBarLocation="left"
                    [disableNavigationBar]="true">

                <!-- Welcome -->
                <aw-wizard-step stepTitle="Welcome">
                    <seed-start [workgroupName]="workgroupName"></seed-start>

                    <wizard-footer [first]="true"></wizard-footer>
                </aw-wizard-step>


                <!-- Species -->
                <aw-wizard-step stepTitle="Species">
                    <choose-taxa [(selectedTaxa)]="seedValues.taxa"></choose-taxa>

                    <wizard-footer [nextDisabled]="!hasSelectedTaxa()"
                                   (backPostFinalize)="onStepMove()"
                                   (nextPostFinalize)="onStepMove()"></wizard-footer>
                </aw-wizard-step>


                <!-- Species Characteristics -->
                <aw-wizard-step stepTitle="Species Characteristics">
                    <choose-taxon-characteristics [selectedTaxa]="seedValues.taxa"
                                                  [(selectedTaxonCharacteristics)]="seedValues.taxonCharacteristics"></choose-taxon-characteristics>

                    <wizard-footer (backPostFinalize)="onStepMove()"
                                   (nextPostFinalize)="onStepMove()"></wizard-footer>
                </aw-wizard-step>


                <!-- Line Types -->
                <aw-wizard-step stepTitle="{{'Line' | translate}} Types">
                    <choose-cv-values [cvItemName]="'Line Type'"
                                      [cvTableName]="'cv_LineType'"
                                      [required]="true"
                                      [(selectedValues)]="seedValues.lineTypes">
                        <ng-container step-heading>
                            Let's define the <span class="data-type">{{'Line' | translate}} types</span> you will use.
                        </ng-container>
                    </choose-cv-values>

                    <wizard-footer [nextDisabled]="!hasSelectedLineTypes()"
                                   (backPostFinalize)="onStepMove()"
                                   (nextPostFinalize)="onMoveFromLineTypes()"></wizard-footer>
                </aw-wizard-step>


                <!-- Lines -->
                <aw-wizard-step stepTitle="{{'Lines' | translate}}">
                    <choose-lines #chooseLines
                                  [selectedTaxa]="seedValues.taxa"
                                  [selectedLineTypes]="seedValues.lineTypes"
                                  [(selectedLines)]="seedValues.lines"></choose-lines>

                    <wizard-footer [nextDisabled]="!hasSelectedLines()"
                                   (backPostFinalize)="onStepMove()"
                                   (nextPostFinalize)="onStepMove()"></wizard-footer>
                </aw-wizard-step>


                <!-- Animal Statuses -->
                <aw-wizard-step stepTitle="Animal Statuses">
                    <choose-cv-values [cvItemName]="'Animal Status'"
                                      [cvTableName]="'cv_AnimalStatus'"
                                      [(selectedValues)]="seedValues.animalStatuses">
                        <ng-container step-heading>
                            Let's define the <span class="data-type">animal statuses</span> you will use.
                        </ng-container>
                    </choose-cv-values>

                    <wizard-footer (backPostFinalize)="onStepMove()"
                                   (nextPostFinalize)="onStepMove()"></wizard-footer>
                </aw-wizard-step>


                <!-- Material Origins -->
                <aw-wizard-step stepTitle="Origins">
                    <choose-cv-values [cvItemName]="'Origin'"
                                      [cvTableName]="'cv_MaterialOrigin'"
                                      [(selectedValues)]="seedValues.materialOrigins">
                        <ng-container step-heading>
                            Let's define the <span class="data-type">animal/sample origins</span> you will use.
                        </ng-container>
                    </choose-cv-values>

                    <wizard-footer (backPostFinalize)="onStepMove()"
                                   (nextPostFinalize)="onStepMove()"></wizard-footer>
                </aw-wizard-step>


                    <!-- Exit Reasons -->
                    <aw-wizard-step stepTitle="Exit Reasons">
                        <choose-cv-values [cvItemName]="'Exit Reason'"
                                          [cvTableName]="'cv_ExitReason'"
                                          [(selectedValues)]="seedValues.exitReasons">
                            <ng-container step-heading>
                                Let's define the <span class="data-type">death/exit reasons</span> you will use.
                            </ng-container>
                        </choose-cv-values>

                    <wizard-footer (backPostFinalize)="onStepMove()"
                                   (nextPostFinalize)="onStepMove()"></wizard-footer>
                </aw-wizard-step>


                    <!-- Generations -->
                    <aw-wizard-step stepTitle="Generations">
                        <choose-cv-values [cvItemName]="'Generation'"
                                          [cvTableName]="'cv_Generation'"
                                          [(selectedValues)]="seedValues.generations">
                            <ng-container step-heading>
                                Let's define the <span class="data-type">animal generations</span> you will use.
                            </ng-container>
                        </choose-cv-values>

                    <wizard-footer (backPostFinalize)="onStepMove()"
                                   (nextPostFinalize)="onStepMove()"></wizard-footer>
                </aw-wizard-step>


                <!-- Diets -->
                <aw-wizard-step stepTitle="Diets">
                    <choose-cv-values [cvItemName]="'Diet'"
                                      [cvTableName]="'cv_Diet'"
                                      [(selectedValues)]="seedValues.diets">
                        <ng-container step-heading>
                            Let's define the <span class="data-type">diets</span> you will use.
                        </ng-container>
                    </choose-cv-values>

                    <wizard-footer (backPostFinalize)="onStepMove()"
                                   (nextPostFinalize)="onStepMove()"></wizard-footer>
                </aw-wizard-step>


                <!-- Container Types: Animals -->
                <aw-wizard-step stepTitle="Animal Containers">
                    <choose-container-types [materialType]="'Animal'"
                                            [(selectedValues)]="seedValues.animalContainerTypes">
                        <ng-container step-heading>
                            Let's define the <span class="data-type">animal container types</span> you will use.
                        </ng-container>
                    </choose-container-types>

                    <wizard-footer [nextDisabled]="!hasAnimalContainerTypes()"
                                   (backPostFinalize)="onStepMove()"
                                   (nextPostFinalize)="onStepMove()"></wizard-footer>
                </aw-wizard-step>


                <!-- Animal Name Format -->
                <aw-wizard-step stepTitle="Animal Names">
                    <set-name-format [formatName]="'Animal'"
                                     [itemName]="'animal'"
                                     [(nameFormat)]="seedValues.animalNameFormat">
                        <ng-container step-heading>
                            Let's define how your <span class="data-type">animals</span> will be named.
                        </ng-container>
                    </set-name-format>

                    <wizard-footer (backPostFinalize)="onStepMove()"
                                   (nextPostFinalize)="onStepMove()"></wizard-footer>
                </aw-wizard-step>


                <!-- Housing Name Format -->
                <aw-wizard-step stepTitle="Housing Names">
                    <set-name-format [formatName]="'Housing'"
                                     [itemName]="'housing unit'"
                                     [(nameFormat)]="seedValues.housingNameFormat">
                        <ng-container step-heading>
                            Let's define how your <span class="data-type">housing units</span> (e.g., cages, pens) will be named.
                        </ng-container>
                    </set-name-format>

                    <wizard-footer (backPostFinalize)="onStepMove()"
                                   (nextPostFinalize)="onStepMove()"></wizard-footer>
                </aw-wizard-step>

                <!-- Genotype Assays -->
                <aw-wizard-step stepTitle="Genotype Assays">
                    <choose-cv-values [cvItemName]="'Genotype Assay'"
                                      [cvTableName]="'cv_GenotypeAssay'"
                                      [(selectedValues)]="seedValues.genotypeAssays">
                        <ng-container step-heading>
                            Let's define the <span class="data-type">genotype assays</span> you will use.
                        </ng-container>

                        <ng-container step-outro>
                            For ease and quality control, you can associate the genotype assay directly to the {{'Line' | translate}}.
                            You can establish this relationship using the <em>Vocabularies</em> facet.
                        </ng-container>
                    </choose-cv-values>

                    <wizard-footer (backPostFinalize)="onStepMove()"
                                   (nextPostFinalize)="onStepMove()"></wizard-footer>
                </aw-wizard-step>

                <!-- Genotype Symbols -->
                <aw-wizard-step stepTitle="Genotype Symbols">
                    <choose-cv-values [cvItemName]="'Genotype Symbol'"
                                      [cvTableName]="'cv_GenotypeSymbol'"
                                      [(selectedValues)]="seedValues.genotypeSymbols">
                        <ng-container step-heading>
                            Let's define the <span class="data-type">genotype symbols</span> you will use.
                        </ng-container>
                    </choose-cv-values>

                    <wizard-footer (backPostFinalize)="onStepMove()"
                                   (nextPostFinalize)="onStepMove()"></wizard-footer>
                </aw-wizard-step>

                <!-- Mating Status -->
                <aw-wizard-step stepTitle="Mating Statuses">
                    <choose-cv-values [cvItemName]="'Mating Status'"
                                      [cvTableName]="'cv_MatingStatus'"
                                      [(selectedValues)]="seedValues.matingStatuses">
                        <ng-container step-heading>
                            Let's define the <span class="data-type">mating statuses</span> you will use.
                        </ng-container>
                    </choose-cv-values>

                    <wizard-footer (backPostFinalize)="onStepMove()"
                                   (nextPostFinalize)="onStepMove()"></wizard-footer>
                </aw-wizard-step>

                <!-- Mating Type -->
                <aw-wizard-step stepTitle="Mating Types">
                    <choose-cv-values [cvItemName]="'Mating Type'"
                                      [cvTableName]="'cv_MatingType'"
                                      [(selectedValues)]="seedValues.matingTypes">
                        <ng-container step-heading>
                            Let's define the <span class="data-type">mating types</span> you will use.
                        </ng-container>
                    </choose-cv-values>

                    <wizard-footer (backPostFinalize)="onStepMove()"
                                   (nextPostFinalize)="onStepMove()"></wizard-footer>
                </aw-wizard-step>

                <!-- Birth Status -->
                <aw-wizard-step stepTitle="Birth Statuses">
                    <choose-cv-values [cvItemName]="'Birth Status'"
                                      [cvTableName]="'cv_BirthStatus'"
                                      [(selectedValues)]="seedValues.birthStatuses">
                        <ng-container step-heading>
                            Let's define the <span class="data-type">birth statuses</span> you will use.
                        </ng-container>
                    </choose-cv-values>

                    <wizard-footer (backPostFinalize)="onStepMove()"
                                   (nextPostFinalize)="onStepMove()"></wizard-footer>
                </aw-wizard-step>

                <!-- Mating Name Format -->
                <aw-wizard-step stepTitle="Mating Names">
                    <set-name-format [formatName]="'Mating'"
                                     [itemName]="'mating'"
                                     [(nameFormat)]="seedValues.matingNameFormat">
                        <ng-container step-heading>
                            Let's define how your <span class="data-type">matings</span> will be named.
                        </ng-container>
                    </set-name-format>

                    <wizard-footer (backPostFinalize)="onStepMove()"
                                   (nextPostFinalize)="onStepMove()"></wizard-footer>
                </aw-wizard-step>

                <!-- Birth Name Format -->
                <aw-wizard-step stepTitle="Birth Names">
                    <set-name-format [formatName]="'Birth'"
                                     [itemName]="'birth'"
                                     [(nameFormat)]="seedValues.birthNameFormat">
                        <ng-container step-heading>
                            Let's define how your <span class="data-type">births</span> will be named.
                        </ng-container>
                    </set-name-format>

                    <wizard-footer (backPostFinalize)="onStepMove()"
                                   (nextPostFinalize)="onStepMove()"></wizard-footer>
                </aw-wizard-step>

                <!-- Sample Types -->
                <aw-wizard-step stepTitle="Sample Types">
                    <choose-cv-values [cvItemName]="'Sample Type'"
                                      [cvTableName]="'cv_SampleType'"
                                      [(selectedValues)]="seedValues.sampleTypes">
                        <ng-container step-heading>
                            Let's define the <span class="data-type">sample types</span> you will use.
                        </ng-container>
                    </choose-cv-values>

                    <wizard-footer (backPostFinalize)="onStepMove()"
                                   (nextPostFinalize)="onStepMove()"></wizard-footer>
                </aw-wizard-step>

                <!-- Sample Statuses -->
                <aw-wizard-step stepTitle="Sample Statuses">
                    <choose-cv-values [cvItemName]="'Sample Status'"
                                      [cvTableName]="'cv_SampleStatus'"
                                      [(selectedValues)]="seedValues.sampleStatuses">
                        <ng-container step-heading>
                            Let's define the <span class="data-type">sample statuses</span> you will use.
                        </ng-container>
                    </choose-cv-values>

                    <wizard-footer (backPostFinalize)="onStepMove()"
                                   (nextPostFinalize)="onStepMove()"></wizard-footer>
                </aw-wizard-step>

                    <!-- Preservation Methods -->
                    <aw-wizard-step stepTitle="Preservation Methods">
                        <choose-cv-values [cvItemName]="'Preservation Method'"
                                          [cvTableName]="'cv_PreservationMethod'"
                                          [(selectedValues)]="seedValues.preservationMethods">
                            <ng-container step-heading>
                                Let's define the <span class="data-type">sample preservation methods</span> you will use.
                            </ng-container>
                        </choose-cv-values>

                    <wizard-footer (backPostFinalize)="onStepMove()"
                                   (nextPostFinalize)="onStepMove()"></wizard-footer>
                </aw-wizard-step>

                <!-- Container Types: Samples -->
                <aw-wizard-step stepTitle="Sample Containers">
                    <choose-container-types [materialType]="'Sample'"
                                            [(selectedValues)]="seedValues.sampleContainerTypes">
                        <ng-container step-heading>
                            Let's define the <span class="data-type">sample container types</span> you will use.
                        </ng-container>
                    </choose-container-types>

                    <wizard-footer [nextDisabled]="!hasSampleContainerTypes()"
                                   (backPostFinalize)="onStepMove()"
                                   (nextPostFinalize)="onStepMove()"></wizard-footer>
                </aw-wizard-step>

                <!-- Sample Name Format -->
                <aw-wizard-step stepTitle="Sample Names">
                    <set-name-format [formatName]="'Sample'"
                                     [itemName]="'sample'"
                                     [(nameFormat)]="seedValues.sampleNameFormat">
                        <ng-container step-heading>
                            Let's define how your <span class="data-type">samples</span> will be named.
                        </ng-container>
                    </set-name-format>

                    <wizard-footer (backPostFinalize)="onStepMove()"
                                   (nextPostFinalize)="onStepMove()"></wizard-footer>
                </aw-wizard-step>


                <!-- Confirm -->
                <aw-wizard-step stepTitle="Confirm">
                    <confirm-save></confirm-save>

                    <wizard-footer (nextPostFinalize)="completeSeedDataInitialization()"></wizard-footer>
                </aw-wizard-step>


                <!-- Finish -->
                <aw-wizard-completion-step stepTitle="Finish">
                    <seed-finish [workgroupName]="workgroupName"
                                 [hasError]="hasError"
                                 [savingData]="savingSeedData"></seed-finish>

                    <wizard-footer [last]="true"
                                   [backHidden]="true"
                                   [nextDisabled]="savingSeedData"
                                   (finish)="finishWizard()"></wizard-footer>
                </aw-wizard-completion-step>

            </aw-wizard>

        </div><!-- /.card-body -->

    </div><!-- /.card -->

</div><!-- /.wizard-container -->

<div *ngIf="errorMessage" class="text-danger help-container">
    <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
    {{errorMessage}}
</div>
<div *ngIf="dataIncomplete" class="text-warning help-container">
    <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
    One or more of your groups had no related animals.
</div>
<loading-overlay *ngIf="loading" [message]="loadingMessage()"></loading-overlay>
<div *ngIf="!rendered && !loading" class="d-flex justify-content-center">
    <h2>Data not yet selected. Please select data to view.</h2>
</div>
<dx-chart *ngIf="!loading && rendered"
          [dataSource]="dataSource"
          title="Kaplan-Meier Chart"
          palette="Green Mist">
    <dxi-series *ngFor="let series of seriesLabels"
                [valueField]="series"
                [name]="series"></dxi-series>
    <dxo-common-series-settings type="stepline" argumentField="interval">
        <dxo-stepline>
            <dxo-point [visible]="false"></dxo-point>
        </dxo-stepline>
    </dxo-common-series-settings>
    <dxo-export [enabled]="true"></dxo-export>
    <dxi-value-axis title="Survival (%)">
    </dxi-value-axis>
    <dxo-argument-axis [title]="interval">
    </dxo-argument-axis>
    <dxo-crosshair [enabled]="true" color="#949494" [width]="3" dashStyle="dot">
        <dxo-label [visible]="true" backgroundColor="#949494">
            <dxo-font color="#fff" [size]="12"> </dxo-font>
        </dxo-label>
    </dxo-crosshair>
    <dxo-tooltip [zIndex]="10000" [enabled]="true" [customizeTooltip]="customizeTooltip"></dxo-tooltip>
</dx-chart>

import type { Device } from './device.interface';

export interface DeviceOutputValue {
    C_DeviceOutputValue_key: number;
    C_Device_key: string;
    Device: Device;
    OutputName: string;
    OutputTime: Date;
    OutputValue: string;
}

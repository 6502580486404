import {
    Component,
    Input,
    OnInit
} from '@angular/core';

import {
    NgbActiveModal
} from '@ng-bootstrap/ng-bootstrap';

import { AuditService } from '../../audit/audit.service';
import { ExportSampleAuditService } from './export-sample-audit.service';
import { SampleService } from '../sample.service';

import {
    getSafeProp,
} from '../../common/util/';

@Component({
    selector: 'sample-audit-report',
    templateUrl: './sample-audit-report.component.html'
})
export class SampleAuditReportComponent implements OnInit {
    @Input() materialKey: any;

    sample: any;
    auditData: any[] = [];
    auditSourcesData: any[] = [];
    auditJobsData: any[] = [];
    auditConstructsData: any[];

    loading = false;

    readonly noDataMessage: string = 'No changes.';

    constructor(
        private activeModal: NgbActiveModal,
        private auditService: AuditService,
        private exportSampleAuditService: ExportSampleAuditService,
        private sampleService: SampleService
    ) {
    }

    ngOnInit() {
        this.getData();
    }

    private getData(): Promise<void> {

        const p1 = this.sampleService.getSample(this.materialKey)
            .then((data: any) => {
                this.sample = data;
            });

        const p2 = this.auditService.getSampleAuditData(this.materialKey)
            .then((response: any) => {
                this.auditData = getSafeProp(response, 'data.results') || [];
            });

        const p3 = this.auditService.getSampleAuditJobsData(this.materialKey)
            .then((response: any) => {
                this.auditJobsData = getSafeProp(response, 'data.results') || [];
            });

        const p4 = this.auditService.getSampleAuditSourcesData(this.materialKey)
            .then((response: any) => {
                this.auditSourcesData = getSafeProp(response, 'data.results') || [];
            });

        const p5 = this.auditService.getSampleAuditConstructsData(this.materialKey)
            .then((response: any) => {
                this.auditConstructsData = getSafeProp(response, 'data.results') || [];
            });

        this.loading = true;
        return Promise.all([p1, p2, p3, p4, p5]).then(() => {
            this.loading = false;
        }).catch((error) => {
            this.loading = false;
            throw error;
        });
    }

    onCancel(): void {
        this.activeModal.dismiss();
    }

    exportAudit(): void {
        this.exportSampleAuditService.exportToCsv(
            this.auditData,
            this.auditSourcesData,
            this.auditJobsData,
            this.auditConstructsData
        );
    }
}

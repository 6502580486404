import { DataContextService } from './../../services/data-context.service';
import { 
    Injectable 
} from '@angular/core';
import { FacetLoadingStateService } from './facet-loading-state.service';
import { LoggingService } from '../../services/logging.service';
import { ViewUnsavedChangesModalService } from '../toolbars';
import { SaveChangesService } from '@services/save-changes.service';

/**
 * Wrapper to hold all the services used by the BaseDetail class
 *   These are common functions that every facet detail makes use of
 */
@Injectable()
export class BaseDetailService {

    constructor(
        public loggingService: LoggingService,
        public dataContext: DataContextService,
        public facetLoadingStateService: FacetLoadingStateService,
        public viewUnsavedChangesModalService: ViewUnsavedChangesModalService,
        public saveChangesService: SaveChangesService,
    ) {
        // do nothing
    }
   
}

import { Pipe, PipeTransform } from '@angular/core';

import { priorMaterialHousingID, MaterialPoolMaterial } from '../util';

/**
 * Returns the prior material HousingID.
 */
@Pipe({
    name: 'priorMaterialHousingID',
    pure: false
})
export class PriorMaterialHousingIDPipe implements PipeTransform {

    transform<T extends MaterialPoolMaterial>(value: T[] | null | undefined): string[] {
        if (!value) {
            return [];
        }

        const housingID = priorMaterialHousingID(value);

        // Return array so can be used in ngFors
        if (housingID) {
            return [housingID];
        } else {
            return [];
        }
    }
}

import {
    Component,
    EventEmitter,
    Input,
    Output
} from '@angular/core';
import { TranslationService } from '../../services/translation.service';

import { ImportFileDefinitionService } from '../import-file-definition.service';

@Component({
    selector: 'import-file-definition-select',
    template: `
        <climb-typeahead
            [(model)]="model"
            [resultFormatter]="importFileDefinitionIdFormatter"
            [keyFormatter]="importFileDefinitionKeyFormatter"
            [search]="searchImportFileDefinitions"
            [keySearch]="searchImportFileDefinitionsByKey"  
            [required]="required"
            (selectItem)="selectItemHandler($event)"
            #typeahead
        ><ng-content></ng-content></climb-typeahead>
    `,
    styles: [`
        .import-file-definition-select {
            min-width: 100px;
        }
    `]
})
export class ImportFileDefinitionSelectComponent {
    @Input() model: any;
    @Output() modelChange: EventEmitter<any> = new EventEmitter<any>();
    @Input() required: boolean;
    @Input() parentFileTypeKey: any;

    constructor(
        private importFileDefinitionService: ImportFileDefinitionService,
        private translationService: TranslationService
    ) { }

    selectItemHandler(item: any) {
        this.modelChange.emit(this.model);
    }

    searchImportFileDefinitions = (search: string): Promise<any[]> => {
        const filter: any = { DefinitionName: search };

        // Filter by file type if we have one
        if (this.parentFileTypeKey > 0) {
            filter.C_ImportFileType_key = this.parentFileTypeKey;
        }

        let count = null;
        // if 2 characters or less, put a limit on number returned
        if (search.length < 3) {
            count = 50;
        }

        return this._searchImportFileDefinitions(filter, count);
    }

    searchImportFileDefinitionsByKey = (key: any): Promise<any[]> => {
        const filter: any = { C_ImportFileDefinition_key: key };
        if (this.parentFileTypeKey > 0) {
            filter.C_ImportFileType_key = this.parentFileTypeKey;
        }

        return this._searchImportFileDefinitions(filter, 1);
    }

    _searchImportFileDefinitions(filter: any, count: number): Promise<any[]> {
        return this.importFileDefinitionService.getImportFileDefinitions({
            page: 1,
            size: count,
            sort: 'DefinitionName asc',
            filter
        }).then((response) => {
            response.results.forEach((importFileDefinition: any) => {
                importFileDefinition.DefinitionNameTranslated = this.translationService.translate(importFileDefinition.DefinitionName);
            });
            return response.results;
        });
    }

    importFileDefinitionIdFormatter = (item: any) => {
        return item.DefinitionNameTranslated;
    }

    importFileDefinitionKeyFormatter = (item: any) => {
        return item.C_ImportFileDefinition_key;
    }
}

<div style="width:7.75in;" *ngIf="task && workflowTaskOutputs">
    <table id="exportWorkflowTable" class="table printTable">
        <tr>
            <td>Task Name</td>
            <td>{{task.TaskAlias}}</td>
        </tr>
        <tr>
            <td>Task</td>
            <td>{{task.WorkflowTask.TaskName}}</td>
        </tr>
        <!-- For now only display locked for job tasks -->
        <tr *ngIf="taskType === TaskType.Job">
            <td>Locked</td>
            <td>{{task.IsLocked}}</td>
        </tr>

        <!-- Core details by taskType -->
        <ng-container [ngSwitch]="taskType">
            <!-- Animal -->
            <ng-container *ngSwitchCase="TaskType.Animal">
                <ng-container *ngIf="task.TaskMaterial | taskFilter:TaskType.Animal | notEmpty">
                    <workflow-animal-details [animal]="task.TaskMaterial[0].Material?.Animal"
                                             [useTableFormat]="true"></workflow-animal-details>
                </ng-container>
            </ng-container>

            <!-- Birth -->
            <ng-container *ngSwitchCase="TaskType.Birth">
                <ng-container *ngIf="task.TaskBirth | notEmpty">
                    <workflow-birth-details [birth]="task.TaskBirth[0].Birth"
                                            [useTableFormat]="true"></workflow-birth-details>
                </ng-container>
            </ng-container>

            <!-- Housing -->
            <ng-container *ngSwitchCase="TaskType.Housing">
                <ng-container *ngIf="task.TaskMaterialPool | notEmpty">
                    <workflow-housing-details [materialPool]="task.TaskMaterialPool[0].MaterialPool"
                                              [useTableFormat]="true"></workflow-housing-details>
                </ng-container>
            </ng-container>

            <!-- Job -->
            <ng-container *ngSwitchCase="TaskType.Job">
                <ng-container *ngIf="task.TaskJob | notEmpty">
                    <workflow-job-details [job]="task.TaskJob[0].Job"
                                          [useTableFormat]="true"></workflow-job-details>
                </ng-container>
            </ng-container>

            <!-- Line -->
            <ng-container *ngSwitchCase="TaskType.Line">
                <ng-container *ngIf="task.TaskLine | notEmpty">
                    <workflow-line-details [line]="task.TaskLine[0].Line"
                                           [useTableFormat]="true"></workflow-line-details>
                </ng-container>
            </ng-container>

            <!-- Mating -->
            <ng-container *ngSwitchCase="TaskType.Mating">
                <ng-container *ngIf="task.TaskMaterialPool | notEmpty">
                    <workflow-mating-details [mating]="task.TaskMaterialPool[0].MaterialPool?.Mating"
                                             [useTableFormat]="true"></workflow-mating-details>
                </ng-container>
            </ng-container>
        </ng-container><!-- /Core details by taskType -->

        <tr>
            <td>Due Date</td>
            <td>{{task.DateDue | formatShortDateOrTime}}</td>
        </tr>
        <tr>
            <td>Complete Date</td>
            <td>{{task.DateComplete | formatShortDateOrTime}}</td>
        </tr>
        <tr>
            <td>Cohorts Name:</td>        
            <td>                
                <p style="margin-top: 0; margin-bottom: 0;" *ngFor="let cohortName of task.TaskCohort">
                    {{cohortName?.Cohort?.CohortName}}
                </p>                                               
            </td>        
        <tr>
            <td>Status</td>
            <td>{{task.cv_TaskStatus?.TaskStatus}}</td>
        </tr>
        <tr>
            <td>Assigned To</td>
            <td>{{task.AssignedToResource?.ResourceName}}</td>
        </tr>
        <tr>
            <td>Created By</td>
            <td>{{task.CreatedBy | userFullName}}</td>
        </tr>
        <tr>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
        </tr>
        <tr>
            <td style="vertical-align:top;"><b>Inputs</b></td>
        </tr>
        <tr>
            <td *ngFor="let taskInput of task.TaskInput | orderBy : 'Input.SortOrder'">
                {{taskInput?.Input?.InputName}}
            </td>
        </tr>
        <tr>
            <td *ngFor="let taskInput of task.TaskInput | orderBy : 'Input.SortOrder'">
                {{taskInput?.InputValue}}
            </td>
        </tr>
        <tr>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
        </tr>
        <tr>
            <td style="vertical-align:top;"><b>Outputs</b></td>
        </tr>
        <tr>
            <td *ngFor="let workflowTaskOutput of workflowTaskOutputs | orderBy : 'SortOrder'">
                <b>{{workflowTaskOutput?.OutputName}}</b>
            </td>
            <ng-container *ngIf="task.showMaterialOutputColumn">
                <td>Animals</td>
            </ng-container>
            <ng-container *ngIf="task.showMaterialOutputColumn">
                <td>Samples</td>
            </ng-container>
            <td>Collected On</td>
            <td>Collected By</td>
        </tr>
        <tr *ngFor="let taskOutputSet of task.TaskOutputSet | orderBy : 'DateCreated' : true">
            <td *ngFor="let taskOutput of taskOutputSet.TaskOutput | orderBy : 'Output.SortOrder'">
                {{taskOutput.OutputValue}}
            </td>
            <ng-container *ngIf="task.showMaterialOutputColumn">
                <td>
                    <span *ngFor="let material of taskOutputSet.TaskOutputSetMaterial | animalFilter; let last = last;">
                        {{material?.Material?.Animal?.AnimalName }}<span *ngIf="!last">, </span>
                    </span>
                </td>
                <td>
                    <span *ngFor="let material of taskOutputSet.TaskOutputSetMaterial | sampleFilter; let last = last;">
                        {{material?.Material?.Sample?.SampleName }}<span *ngIf="!last">, </span>
                    </span>
                </td>
            </ng-container>
            <td>
                {{taskOutputSet.CollectionDateTime | formatShortDate}}
            </td>
            <td>
                {{taskOutputSet.Resource?.ResourceName}}
            </td>
        </tr>
        <tr>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
        </tr>
        <ng-container *ngIf="task.showAnimals">
            <tr>
                <td style="vertical-align:top;"><b>Animals</b></td>
            </tr>
            <tr>
                <td>Name</td>
                <td>Current Location</td>
            </tr>
            <tr *ngFor="let animal of task.TaskMaterial | animalFilter">
                <td>{{animal?.Material?.Animal?.AnimalName}}</td>
                <td>{{animal?.Material?.CurrentLocationPath}}</td>
            </tr>
            <tr>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
            </tr>
        </ng-container>
        <ng-container *ngIf="task.showSamples">
            <tr>
                <td style="vertical-align:top;"><b>Samples</b></td>
            </tr>
            <tr>
                <td>Name</td>
                <td>Current Location</td>
                <td>Source</td>
                <td>Type</td>
                <td>Time Point</td>
                <td>Preservation Method</td>
                <td>Created By</td>
            </tr>
            <tr *ngFor="let sample of task.TaskMaterial | sampleFilter">
                <td>{{sample?.Material?.Sample?.SampleName }}</td>
                <td>{{sample?.Material?.CurrentLocationPath}}</td>
                <td>
                    <span *ngFor="let source of sample?.Material?.MaterialSourceMaterial; let last = last;">
                        {{source?.SourceMaterial?.Animal?.AnimalName}}{{source?.SourceMaterial?.Sample?.SampleName}}<span *ngIf="!last">,</span>
                    </span>
                </td>
                <td>{{sample?.Material?.Sample?.cv_SampleType?.SampleType}}</td>
                <td>{{sample?.Material?.Sample?.TimePoint |
                        formatTimePoint : sample?.Material?.Sample?.cv_TimeUnit}}</td>
                <td>{{sample?.Material?.Sample?.cv_PreservationMethod?.PreservationMethod}}</td>
                <td>{{sample?.Material?.Sample?.CreatedBy | userFullName}}</td>
            </tr>

        </ng-container>
    </table>
</div>

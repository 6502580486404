import {
    Component,
    ChangeDetectionStrategy,
    Input,
    ChangeDetectorRef,
    HostListener,
    ViewChild,
    ElementRef,
} from '@angular/core';
import { ENTER, SPACE } from '@angular/cdk/keycodes';
import { checkmark } from '@icons';
import { Subject } from 'rxjs';

@Component({
    selector: 'climb-select-option',
    templateUrl: './select-option.component.html',
    styleUrls: ['./select-option.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectOptionComponent<T = any> {
    @Input() value: T;

    @HostListener('click')
    clickOption(): void {
        this.selectionChange.next(this.value);
    }

    @HostListener('keydown', ['$event'])
    private onKeyDown(event: KeyboardEvent): void {
        const keyCode = event.keyCode;
        if (keyCode === ENTER || keyCode === SPACE) {
            event.preventDefault();
            this.elementRef.nativeElement.click();
        }
    }

    @ViewChild('text') text: ElementRef<HTMLElement> | undefined;

    get viewValue(): string {
        return (this.text?.nativeElement.textContent || '').trim();
    }

    icons = { checkmark };
    selected = false;
    selectionChange = new Subject<T>();

    constructor(
        private elementRef: ElementRef<HTMLElement>,
        private changeDetectorRef: ChangeDetectorRef,
    ) { }

    select(): void {
        if (!this.selected) {
            this.selected = true;
            this.changeDetectorRef.markForCheck();
        }
    }

    deselect(): void {
        if (this.selected) {
            this.selected = false;
            this.changeDetectorRef.markForCheck();
        }
    }
}

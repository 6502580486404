<!-- Table for Lines -->
<div class="form-group row">
    <label class="col-md-2 col-form-label"
           [ngClass]="{'required-text' : required}">{{'Line' | translate}}</label>
    <div class="col-md-10">
        <table name="lines" class="table">
            <thead>
                <tr>
                    <th class="icon-cell col-size-fit">
                        <a (click)="addJobLine(job)"
                           class="add-item-link"
                           *ngIf="!readonly">
                            <i class="fa fa-plus-circle" title="Add Line"></i>
                        </a>
                    </th>
                    <th>{{'Line' | translate}}</th>
                    <th>Species</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let jobLine of job.JobLine | orderBy:'SortOrder';" [attr.data-key]="jobLine.C_JobLine_key">
                    <td>
                        <a *ngIf="!readonly" (click)="removeLine(jobLine)">
                            <i class="fa fa-fw fa-remove remove-item"
                               title="Delete Line"></i>
                        </a>
                    </td>
                    <td>
                        <line-select class="line-input"
                                     [(model)]="jobLine.C_Line_key"
                                     [disabled]="readonly"
                                     [required]="required"
                                     (modelChange)="lineChanged(jobLine)"></line-select>
                    </td>
                    <td>
                        <input type="text" name="species"
                               [value]="jobLine.Line?.cv_Taxon?.CommonName"
                               readonly="readonly"
                               class="form-control input-medium species-input" />
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

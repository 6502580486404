import { Injectable } from '@angular/core';
import {
    Predicate,
} from 'breeze-client';

import { notEmpty } from '../common/util/not-empty';
import { QueryDef } from '../services/query-def';

import { DataManagerService } from '../services/data-manager.service';
import { BaseEntityService } from '../services/base-entity.service';

@Injectable()
export class EventService extends BaseEntityService {

    readonly ENTITY_TYPE = 'Events';
    readonly ENTITY_NAME = 'Event';

    constructor(
        private dataManager: DataManagerService
    ) {
        super();
    }


    getMaterialEvents(materialKey: number): Promise<any[]> {
        const queryDef: QueryDef = {
            filter: {
                materialKey
            }
        };

        return this.getEvents(queryDef);
    }

    getEvents(queryDef: QueryDef): Promise<any[]> {

        if (!queryDef.sort) {
            queryDef.sort = 'DateOccurred DESC';
        }

        // Do not need count b/c not returning the usual QueryResult object
        queryDef.inlineCount = false;

        let query = this.buildDefaultQuery(this.ENTITY_TYPE, queryDef);

        this.ensureDefExpanded(queryDef, 'cv_EventType');
        query = query.expand(queryDef.expands.join(','));

        if (queryDef.filter) {
            let predicates: Predicate[] = [];
            predicates = predicates.concat(this.buildPredicates(queryDef.filter));

            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }

        return this.dataManager.returnQueryResults(query);
    }

    private buildPredicates(filter: any): Predicate[] {
        const predicates: Predicate[] = [];

        if (!filter) {
            return predicates;
        }

        if (filter.materialKey) {
            predicates.push(Predicate.create('C_Material_key', '==', filter.materialKey));
        }

        return predicates;
    }

    createEvent(initialValues: any): any {
        if (!initialValues.DateOccurred) {
            initialValues.DateOccurred = new Date();
        }

        return this.dataManager.createEntity(this.ENTITY_NAME, initialValues);
    }

    deleteEvent(event: any): void {
        this.dataManager.deleteEntity(event);
    }
}

<div class="detail-form-wrapper">

    <div class="detail-header">
        <h3>Mating</h3>

        <pager-toolbar [itemName]="'mating'"
                       [logTag]="COMPONENT_LOG_TAG"
                       [first]="pageState.isFirst"
                       [last]="pageState.isLast"
                       [promptForSave]="false"
                       (previous)="onSwitchView('previous')"
                       (next)="onSwitchView('next')"></pager-toolbar>

        <detail-toolbar [facetPrivilege]="facet.Privilege"
                        [logTag]="COMPONENT_LOG_TAG"
                        (exit)="onSwitchView('exit')"
                        [promptForSave]="false"
                        [emitOnSave]="true"
                        (genericSaveEmitter)="onSaveMating()">

            <div class="btn-group"
                 role="group"
                 aria-label="Mating actions"
                 additional-groups>
                 <div class="btn-group" view-notes-button
                    [readonly]="facet.Privilege === 'ReadOnly'"
                    [pkName]="'C_MaterialPool_key'"
                    [pkValue]="mating.C_MaterialPool_key"
                    [objectName]="mating.MatingID"
                    [objectType]="'Mating'"
                    [isObjectNew]="mating | isNew"
                    appInsightsClick="click-notes-from-mating">
                </div>
                <button type="button"
                        class="btn btn-secondary"
                        title="Print Cage Card"
                        (click)="requestCageCard()">
                    <i class="fa fa-print" aria-hidden="true"></i>
                    <span class="gridster-d-sm-none gridster-d-md-inline-block">Card</span>
                    <span class="sr-only">Print Cage Card</span>
                </button>
            </div>
        </detail-toolbar>
    </div>

    <form *ngIf="mating"
          name="matingForm" id="matingForm"
          class="detail-form {{housingPanelScrollContainerClass}}"
          #matingForm="ngForm">
        <fieldset [disabled]="facet.Privilege === 'ReadOnly'">

            <div class="form-group row">
                <label class="col-md-2 col-form-label">Housing ID
                    <info-tooltip [text]="'You can edit this mating\'s Housing ID here, but you can\'t select a different housing unit.'"></info-tooltip>
                </label>

                <div class="col-md-10" *ngIf="mating?.MaterialPool">
                    <input type="text"
                           name="materialPoolID"
                           #materialPoolID="ngModel"
                           [(ngModel)]="mating.MaterialPool.MaterialPoolID"
                           [disabled]="(mating | isNew) && housingNamingActive"
                           [required]="!(mating | isNew) || !housingNamingActive"
                           class="form-control input-medium material-pool-id-input"
                           validateUnique
                           uniqueEntity="MaterialPools"
                           uniqueProperty="MaterialPoolID"
                           [entityPK]="mating.C_MaterialPool_key"
                           entityPKName="C_MaterialPool_key"
                           maxlength="75" />
                    <small class="form-text text-muted"
                       *ngIf="materialPoolID.errors?.unique && materialPoolID.dirty && mating.MaterialPool.MaterialPoolID !== originalMaterialPoolID">
                        <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                        This ID already exists.
                    </small>
                    <small class="form-text text-muted"
                       *ngIf="(mating | isNew) && housingNamingActive">
                        The Housing ID will be assigned automatically.
                    </small>
                </div>
            </div>

            <div class="form-group row">
                <label class="col-md-2 col-form-label">Mating ID</label>
                <div class="col-md-10">
                    <input data-automation-id="mating-id-input"
                           type="text"
                           name="matingID"
                           #matingID="ngModel"
                           [(ngModel)]="mating.MatingID"
                           [disabled]="(mating | isNew) && matingNamingActive"
                           [required]="!(mating | isNew) || !matingNamingActive"
                           class="form-control input-medium"
                           validateUnique
                           uniqueEntity="Matings"
                           uniqueProperty="MatingID"
                           [entityPK]="mating.C_MaterialPool_key"
                           entityPKName="C_MaterialPool_key"
                           maxlength="75"/>
                    <small class="form-text text-muted"
                       *ngIf="matingID.errors?.unique && matingID.dirty && mating.MatingID !== originalMatingID">
                        <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                        This ID already exists.
                    </small>
                    <small class="form-text text-muted"
                       *ngIf="(mating | isNew) && matingNamingActive">
                        The Mating ID will be assigned automatically.
                    </small>
                </div>
            </div>

            <div class="form-group row" *ngIf="activeFields.includes('Status')">
                <label class="col-md-2 col-form-label">Status</label>
                <div class="col-md-10">
                    <active-vocab-select [(model)]="mating.C_MatingStatus_key"
                                         (modelChange)="matingStatusChanged()"
                                         [vocabChoices]="matingStatuses"
                                         [keyFormatter]="matingStatusKeyFormatter"
                                         [optionFormatter]="matingStatusFormatter"
                                         [required]="requiredFields.includes('C_MatingStatus_key')"
                                         [nullable]="!requiredFields.includes('C_MatingStatus_key')"></active-vocab-select>
                    <small *ngIf="exitStatusPromptVisible" class="form-text text-muted">
                        <a (click)="scrollToHousingDetails()">
                            <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                            You may want to change the <em>Status</em> and <em>Exit Reason</em> for animals in this mating.
                        </a>
                    </small>
                </div>
            </div>

            <div class="form-group row" *ngIf="activeFields.includes('Line')">
                <label class="col-md-2 col-form-label">{{'Line' | translate}}</label>
                <div class="col-md-10">
                    <line-select [(model)]="mating.C_Line_key"
                                 [required]="requiredFields.includes('C_Line_key')"
                                 (modelChange)="lineSelected($event)"></line-select>
                    <small class="form-text text-muted"
                       *ngIf="mating?.cv_MatingType?.IsLineOptional">
                        {{'Line' | translate}} is optional for this mating type.
                    </small>
                </div>
            </div>

            <div class="form-group row" *ngIf="activeFields.includes('Date')">
                <label class="col-md-2 col-form-label">Date</label>
                <div class="col-md-4">
                    <climb-ngb-date #dateControl="ngModel"
                                    name="Date"
                                    [(ngModel)]="mating.DateMating"
                                    [required]="requiredFields.includes('DateMating')"></climb-ngb-date>
                </div>
            </div>

            <div class="form-group row" *ngIf="activeFields.includes('Type')">
                <label class="col-md-2 col-form-label">Type</label>
                <div class="col-md-10">
                    <active-vocab-select [(model)]="mating.C_MatingType_key"
                                         (modelChange)="typeChanged(mating)"
                                         [vocabChoices]="matingTypes"
                                         [keyFormatter]="matingTypeKeyFormatter"
                                         [optionFormatter]="matingTypeFormatter"
                                         [required]="requiredFields.includes('C_MatingType_key')"
                                         [nullable]="!requiredFields.includes('C_MatingType_key')"></active-vocab-select>
                </div>
            </div>

            <div class="form-group row" *ngIf="activeFields.includes('Purpose')">
                <label class="col-md-2 col-form-label">Purpose</label>
                <div class="col-md-10">
                    <active-vocab-select [(model)]="mating.C_MatingPurpose_key"
                                         [vocabChoices]="matingPurposes"
                                         [keyFormatter]="matingPurposeKeyFormatter"
                                         [optionFormatter]="matingPurposeFormatter"
                                         [required]="requiredFields.includes('C_MatingPurpose_key')"
                                         [nullable]="!requiredFields.includes('C_MatingPurpose_key')"></active-vocab-select>
                </div>
            </div>

            <div class="form-group row" *ngIf="activeFields.includes('Generation (Offspring)')">
                <label class="col-md-2 col-form-label">Generation (Offspring)</label>
                <div class="col-md-10">
                    <active-vocab-select [(model)]="mating.C_Generation_key"
                                         [vocabChoices]="generations"
                                         [keyFormatter]="generationKeyFormatter"
                                         [optionFormatter]="generationFormatter"
                                         [required]="requiredFields.includes('C_Generation_key')"
                                         [nullable]="!requiredFields.includes('C_Generation_key')"></active-vocab-select>
                </div>
            </div>

            <div class="form-group row" *ngIf="activeFields.includes('Location')">
                <label class="col-md-2 col-form-label"
                       [ngClass]="{'required-text' : requiredFields.includes('MaterialPool.MaterialLocation[0]') && mating.MaterialPool.MaterialLocation.length === 0}">Location</label>
                <div class="col-md-10">
                    <location-history #locationHistory
                                      [materialLocations]="mating.MaterialPool.MaterialLocation"
                                      [facetPrivilege]="facet.Privilege"
                                      (createMaterialLocation)="createMaterialLocation()"
                                      (removeMaterialLocation)="removeMaterialLocation($event)"></location-history>
                </div>
            </div>

            <div class="form-group row" *ngIf="activeFields.includes('Container Type')">
                <label class="col-md-2 col-form-label">Container Type</label>
                <div class="col-md-10">
                    <active-vocab-select [(model)]="mating.MaterialPool.C_ContainerType_key"
                                         [vocabChoices]="containerTypes"
                                         [keyFormatter]="containerTypeKeyFormatter"
                                         [optionFormatter]="containerTypeFormatter"
                                         [required]="requiredFields.includes('MaterialPool.C_ContainerType_key')"
                                         [nullable]="!requiredFields.includes('MaterialPool.C_ContainerType_key')"></active-vocab-select>
                </div>
            </div>

            <div class="form-group row" *ngIf="activeFields.includes('Jobs')">
                <label class="col-md-2 col-form-label"
                       [ngClass]="{'required-text' : requiredFields.includes('MaterialPool.JobMaterialPool[0]') && mating.MaterialPool.JobMaterialPool.length === 0}">{{'Jobs' | translate}}</label>
                <div class="col-md-10">
                    <material-pool-job-select [materialPool]="mating.MaterialPool"
                                              [readonly]="facet.Privilege === 'ReadOnly'"></material-pool-job-select>
                </div>
            </div>

            <fieldset class="fieldset-bordered"
                      *ngIf="mating.MatingCharacteristicInstance.length > 0">
                <legend>Characteristics</legend>
                <div class="form-group row"
                     *ngFor="let characteristicInstance of mating.MatingCharacteristicInstance |
                     orderBy:'MatingCharacteristic.SortOrder'">
                    <div class="col-md-12">
                        <label>
                            {{characteristicInstance?.MatingCharacteristic?.CharacteristicName}}
                        </label>
                        <characteristic-input #characteristicInput
                                              [characteristic]="characteristicInstance"
                                              [readonly]="readonly"
                                              [(value)]="characteristicInstance.CharacteristicValue"></characteristic-input>
                    </div>
                </div>
            </fieldset>

            <div class="form-group row" *ngIf="activeFields.includes('Comments')">
                <label class="col-md-2 col-form-label">Comments</label>
                <div class="col-md-10">
                    <textarea name="matingComments"
                              [(ngModel)]="mating.Comments"
                              class="form-control input-medium mating-detail-comments"
                              maxlength="2000"
                              rows="2"
                              [required]="requiredFields.includes('Comments')"></textarea>
                </div>
            </div>

            <div class="form-group row">
                <label class="col-form-label col-md-2"
                       [ngClass]="{'required-text' : isAnimalsRequired()}">Animals</label>
                <div class="col-md-10">
                    <mating-animal-table [mating]="mating"
                                         [materialPoolMaterials]="mating?.MaterialPool?.MaterialPoolMaterial"
                                         [facetPrivilege]="facet.Privilege"
                                         [activeRemoveCheckbox]="activeFields.includes('Remove new animals from existing matings')"></mating-animal-table>
                </div>
            </div>

            <div class="form-group row" *ngIf="activeFields.includes('Plug Dates')">
                <label class="col-form-label col-md-2"
                       [ngClass]="{'required-text' : requiredFields.includes('MatingPlugDate[0]') && mating.MatingPlugDate.length === 0}">
                    Plug Dates
                </label>
                <div class="col-md-10">
                    <mating-plug-date-table #matingPlugDateTable
                                            [mating]="mating"
                                            [plugDates]="mating.MatingPlugDate"
                                            [facetPrivilege]="facet.Privilege"
                                            [required]="requiredFields.includes('MatingPlugDate[0]')"></mating-plug-date-table>
                </div>
            </div>

            <div class="form-group row">
                <div class="col-md-12">
                    <h4>
                        <a (click)="toggleHousingHistoryExpanded()"
                           class="toggle-housing-history-link"
                           [attr.title]="housingHistoryExpanded ? 'Hide housing history' : 'Show housing history'">
                            Housing History/Details<i class="fa fa-fw"
                                                       [ngClass]="housingHistoryExpanded ? 'fa-chevron-up' : 'fa-chevron-down'"
                                                       [attr.title]="housingHistoryExpanded ? 'Hide housing history' : 'Show housing history'"
                                                       aria-hidden="true"></i>
                        </a>
                    </h4>
                </div>
                <div class="col-md-12" style="margin-bottom: 1em;">
                    <div *ngIf="housingHistoryExpanded" class="{{housingDetailsScrollClass}}">
                        <div class="col-md-12">
                            <table class="table table-nonfluid"
                                   *ngIf="animals.length > 0">
                                <thead>
                                    <tr>
                                        <th>Animal</th>
                                        <th>Type</th>
                                        <th>ID</th>
                                        <th>Date In</th>
                                        <th>Date Out</th>
                                        <th>Locations</th>
                                    </tr>
                                </thead>
                                <tbody *ngFor="let animal of animals | matingActiveAnimals; let j = index;" [ngClass]="j > 0 ? 'no-border' : ''">
                                    <tr *ngFor="let history of animal.History; let i = index;"
                                        [ngClass]="history.DateOut ? 'disabled' : 'text-bold'">
                                        <td>
                                            {{i === 0 ? animal.Animal.Material.Animal.AnimalName : ''}}
                                        </td>
                                        <td>
                                            {{history.MaterialPool?.cv_MaterialPoolType?.MaterialPoolType}}
                                        </td>
                                        <td>
                                            {{history.MaterialPool?.MaterialPoolID}}
                                        </td>
                                        <td>
                                            {{history.DateIn | formatShortDate}}
                                        </td>
                                        <td>
                                            {{history.DateOut | formatShortDate}}
                                        </td>
                                        <td>
                                            {{history.MaterialPool?.CurrentLocationPath}}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <p *ngIf="animals.length === 0"
                           style="margin-top: 1em;">
                            None.
                        </p>
                    </div>
                </div>
            </div>

            <div class="form-group row">
                <div class="col-md-12">
                    <h4>
                        <a (click)="toggleHousingDetailsExpanded()"
                           class="toggle-housing-details-link"
                           [attr.title]="housingDetailsExpanded ? 'Hide mating details' : 'Show mating details'">
                            Mating History/Details<i class="fa fa-fw"
                                                     [ngClass]="housingDetailsExpanded ? 'fa-chevron-up' : 'fa-chevron-down'"
                                                     [attr.title]="housingDetailsExpanded ? 'Hide mating details' : 'Show mating details'"
                                                     aria-hidden="true"></i>
                        </a>
                    </h4>
                </div>
                <div class="col-md-12" style="margin-bottom: 1em;">
                    <div *ngIf="housingDetailsExpanded" class="{{housingDetailsScrollClass}}">
                        <housing-details [materialPoolMaterials]="mating?.MaterialPool?.MaterialPoolMaterial"
                                         [facetPrivilege]="facet.Privilege"></housing-details>
                        <p *ngIf="mating?.MaterialPool?.MaterialPoolMaterial.length === 0"
                           style="margin-top: 1em;">
                            None.
                        </p>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <h4>Births</h4>
                    <mating-birth-table [mating]="mating"
                                        [births]="mating.Birth"
                                        [facetPrivilege]="facet.Privilege"></mating-birth-table>
                </div>
            </div>

            <div class="row" *ngIf="anyDatedBirths()">
                <div class="col-md-12">
                    <mating-litter-size-chart-renderer [mating]="mating"></mating-litter-size-chart-renderer>
                </div>
            </div>

        </fieldset>

        <!-- New fieldset that is never disabled so as not to affect controls in child tables -->
        <fieldset>
            <div class="form-group row">
                <div class="col-md-12">
                    <detail-task-table #detailTaskTable
                                       [parent]="mating"
                                       [taskAssociations]="mating.MaterialPool.TaskMaterialPool"
                                       [taskType]="TaskType.Mating"
                                       [readonly]="facet.Privilege === 'ReadOnly'"
                                       [facet]="facet"
                                       [tableOptions]="detailTaskTableOptions"
                                       [tableSort]="taskTableSort"
                                       [(inputsExpanded)]="mating.inputsExpanded"
                                       [(tasksExpanded)]="mating.tasksExpanded"
                                       [(taskAnimalsExpanded)]="mating.taskAnimalsExpanded"
                                       [(taskCohortsExpanded)]="mating.taskCohortsExpanded"
                                       [(taskSamplesExpanded)]="mating.taskSamplesExpanded"
                                       (taskInstanceAdd)="addTaskMaterialPool($event)"></detail-task-table>
                </div>
            </div>
        </fieldset>
    </form>
</div>

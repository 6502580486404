import type { Mating } from './mating.interface';

export interface cv_MatingRetirementReason {
    C_MatingRetirementReason_key: number;
    C_VocabularySource_key: number | null;
    C_Workgroup_key: number;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    ElementID: string;
    IsActive: boolean;
    IsDefault: boolean;
    IsDeprecated: boolean;
    Mating: Mating[];
    MatingRetirementReason: string;
    ModifiedBy: string;
    SortOrder: number | null;
    Version: number;
}

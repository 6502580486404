import { VocabulariesSharedModule } from './../vocabularies/vocabularies-shared.module';
import { JobsSharedModule } from './../jobs/jobs-shared.module';
import { LinesSharedModule } from './../lines/lines-shared.module';
import { TasksSharedModule } from './../tasks/tasks-shared.module';
import { CohortSharedModule } from './../cohort/cohort-shared.module';
import { ConstructsSharedModule } from './../constructs/constructs-shared.module';
import { UsersSharedModule } from './../user/users-shared.module';
import { EnumerationsSharedModule } from './../enumerations/enumerations-shared.module';
import { DataTypeInputComponent } from './data-type-input.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { ClimbCommonModule } from '../common/climb-common.module';
import { StudiesSharedModule } from '../studies/studies-shared.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ClimbCommonModule,
        CohortSharedModule,
        ConstructsSharedModule,
        EnumerationsSharedModule,
        JobsSharedModule,
        LinesSharedModule,
        StudiesSharedModule,
        TasksSharedModule,
        UsersSharedModule,
        VocabulariesSharedModule
    ],
    declarations: [
        DataTypeInputComponent
    ],
    exports: [
        DataTypeInputComponent
    ],
    providers: [
    ]
})
export class DataTypeModule { }

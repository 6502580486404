import { FileDownloader } from './../download/file-downloader';
import { FileExporter } from './file-exporter';

/*
* Generic CSV file exporter
*/
export class CsvExporter extends FileExporter {

    readonly MIME_TYPE = "text/csv;charset=utf-8";

    private fileDownloader: FileDownloader = new FileDownloader();

    /*
    * Data can be any 2-d array you want to export as CSV
    */
    download(data: any[][], filename: string) {
        const csvContent = this.generateCsvString(data);
        this.triggerFileDownload(csvContent, filename);
    }

    generateCsvString(data: any[][]) {
        const csvRows = data.map((row: any) => {
            const csvRow = row.map((column: any) => {
                return this.formatValue(column);
            });

            return csvRow.join(',');
        });

        return csvRows.join('\n');
    }

    /*
    * Initiate file download of content string 
    *   as csv/text content type
    */
    triggerFileDownload(csvContent: string, filename: string) {
        this.fileDownloader.triggerFileDownload(csvContent, filename, this.MIME_TYPE);
    }
}

import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { uniqueId } from '@lodash';

@Injectable()
export class OverlayService {
    private defaultMessage = 'Saving';
    private overlayMap = new Map<string, string>();

    /**
     * Called whenever master overlay needs to be shown
     */
    private overlayShown = new Subject<{show: boolean, message: string}>();
    overlayShown$ = this.overlayShown.asObservable();

    show(message: string = this.defaultMessage): string {
        const messageId = uniqueId();
        this.overlayMap.set(messageId, message);
        this.overlayShown.next({ show: this.isLoading(), message });
        return messageId;
    }

    hide(messageId: string): void {
        this.overlayMap.delete(messageId);
        const activeMessage = Array.from(this.overlayMap.values()).pop() ?? '';
        this.overlayShown.next({ show: this.isLoading(), message: activeMessage });
    }

    isLoading(): boolean {
        return this.overlayMap.size > 0;
    }
}

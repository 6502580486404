<form novalidate>

    <div class="modal-header justify-content-center">
        <h4 class="modal-title">
            {{objectType}} Notes
        </h4>
    </div>

    <div class="modal-body modal-body-tall">
        <note-log [pkName]="pkName"
                  [pkValue]="pkValue"
                  [objectName]="objectName"
                  [objectType]="objectType"
                  [isObjectNew]="isObjectNew"
                  [readonly]="readonly"></note-log>
    </div>

    <div class="modal-footer">
        <button type="button"
                class="btn btn-lg btn-secondary"
                (click)="onCancel()">
            Close
        </button>
    </div>

</form>

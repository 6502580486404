import {
    Component,
    OnInit,
} from '@angular/core';

import {
    NgbActiveModal
} from '@ng-bootstrap/ng-bootstrap';
import { ColumnSelectLabel } from '@common/facet';
import { uniqueArrayFromPropertyPath } from '../../../common/util';
import { ProtocolInstance, TaskInstance } from '@common/types';

@Component({
    selector: 'bulk-assign-cohort-modal',
    templateUrl: './bulk-assign-cohort-modal.component.html',
    styleUrls: ['./bulk-assign-cohort-modal.component.scss'],
})

export class BulkAssignCohortsModalComponent implements OnInit {

    job: any;
    protocols: ProtocolInstance[] = [];
    protocolSelected: number | string = 'null';
    randomChooseCohorts: any = "individual";
    individualChooseCohorts: any;
    noOfCohorts: number;
    freeTasks: any[] = [];
    freeTasksSelected: any[] = [];
    minAnimalCount = 0;
    maxAnimalError = false;
    selectedJobCohorts = false;
    selectedPlaceholder = false;

    keyArrays: any[] = [];

    settings: any = {
        showCheckAll: false,
        showUncheckAll: false
    };


    protocolTasksSelected: number[] = [];
    protocolToWorkflowTaskMap: any = {};

    taskAliases: TaskInstance[] = [];
    taskAliasesSelected: TaskInstance[] = [];

    cohortAnimalColumns: any[] = ['Name', 'Line', 'Sex', 'Status'];
    cohortAnimalColumnLabels: any = [];
    cohortAnimalColumnVisible: any = {};

    showCohorts = true;

    constructor(
        public activeModal: NgbActiveModal
    ) {
        this.checkCohortVisible = this.checkCohortVisible.bind(this);
    }

    ngOnInit() {
        this.setColumnLabels();
        // Find Protocols in the job
        const keys: any[] = []; 

        // find selected JobCohorts
        this.selectedJobCohorts = this.job.JobCohort.some((cohort: any) => {
            return cohort.isSelected;
        });

        this.selectedPlaceholder = this.job.Placeholder.some((placeholder: any) => {
            return placeholder.isSelected;
        });

        this.protocols = uniqueArrayFromPropertyPath(this.job, 'TaskJob.TaskInstance.ProtocolInstance');

        // Filter protocols further and make them unique on the basis of protocol alias
        this.protocols = this.protocols.filter((protocol: any) => {
            const key = protocol.ProtocolAlias;
            if (keys.indexOf(key) === -1) {
                keys.push(key);
                return true;
            }
            return false;
        });


        this.keyArrays = this.protocols.map((protocol: any) => {
            return protocol.C_Protocol_key;
        });



        // Find and map workflow tasks to protocols
        this.protocols.forEach((protocol: any) => {
            const taskKeys: any[] = [];
            const protocolKey = `${protocol.C_ProtocolInstance_key}`;
            this.protocolToWorkflowTaskMap[protocolKey] = [];
            protocol.TaskInstance.forEach((task: any) => {
                if (!taskKeys.includes(task.C_WorkflowTask_key)) {
                    taskKeys.push(task.C_WorkflowTask_key);
                    this.protocolToWorkflowTaskMap[protocolKey].push(task.WorkflowTask);
                }
            });
        });
        // Find and map free tasks
        this.freeTasks = this.job.TaskJob.filter((task: any) => task.TaskInstance.ProtocolTask === null)
            .map((task: any) => {
                return task.TaskInstance.WorkflowTask;
            });
        this.freeTasks = Array.from(new Set(this.freeTasks));

        if (!this.showCohorts) {
            this.job.Placeholder.forEach((placeholder: any) => {
                placeholder.AnimalPlaceholders = this.job.AnimalPlaceholder.filter((animal: any) => animal.C_Placeholder_key === placeholder.C_Placeholder_key);
            });
        }

        // Clear all Selections for fresh view

        uniqueArrayFromPropertyPath(this.job, 'JobCohort.Cohort').forEach((animal: any) => {
            animal.allSelected = false;
        });

        uniqueArrayFromPropertyPath(this.job, 'Placeholder').forEach((animal: any) => {
            animal.allSelected = false;
        });

        uniqueArrayFromPropertyPath(this.job, 'JobCohort.Cohort.CohortMaterial').forEach((animal: any) => {
            animal.isSelected = false;
        });

        uniqueArrayFromPropertyPath(this.job, 'Placeholder.AnimalPlaceholders').forEach((animal: any) => {
            animal.isSelected = false;
        });

        this.checkMinCohortAnimal();
    }

    get columnVisible() {
        if (!this.showCohorts || this.randomChooseCohorts !== 'individual') {
            return false;
        }

        return this.job.JobCohort.some(this.checkCohortVisible);
    }

    checkCohortVisible(cohort: any) {
        return cohort.isSelected === this.selectedJobCohorts && !cohort.allHidden;
    }

    checkMinCohortAnimal() {
        const noOfCohortsAnimal: any[] = [];
        if (this.showCohorts) {
            this.job.JobCohort.forEach((cohort: any) => {
                if (cohort.Cohort.CohortMaterial.length > 0) {
                    noOfCohortsAnimal.push(cohort.Cohort.CohortMaterial.length);
                }
            });
        } else {
            this.job.Placeholder.forEach((placeholder: any) => {
                noOfCohortsAnimal.push(this.job.AnimalPlaceholder.filter((animal: any) => animal.C_Placeholder_key === placeholder.C_Placeholder_key).length);
            });
        }

        this.minAnimalCount = Math.min(...noOfCohortsAnimal);
        this.maxAnimalError = this.minAnimalCount < this.noOfCohorts;
    }
    setColumnLabels() {
        this.cohortAnimalColumnLabels = [
            new ColumnSelectLabel('ID', 'ID'),
            new ColumnSelectLabel('Name', 'Name'),
            new ColumnSelectLabel('Line', 'Line'),
            new ColumnSelectLabel('Genotype', 'Genotype'),
            new ColumnSelectLabel('Sex', 'Sex'),
            new ColumnSelectLabel('BirthDate', 'Birth Date'),
            new ColumnSelectLabel('Status', 'Status'),
            new ColumnSelectLabel('HousingId', 'Housing Id'),
        ];

        this.cohortAnimalColumns.forEach((column: any) => {
            this.cohortAnimalColumnVisible[column] = true;
        });
    }

    columnChanged(newColumnsSelection: any) {
        this.cohortAnimalColumns = newColumnsSelection;
        this.cohortAnimalColumnLabels.forEach((column: any) => {
            this.cohortAnimalColumnVisible[column.key] = this.cohortAnimalColumns.includes(column.key);
        });
    }

    protocolFormatter = (value: any) => {
        return value.ProtocolAlias;
    }

    selectedCount(cohort: any) {
        let count = 0;
        for (const cm of cohort.Cohort.CohortMaterial) {
            if (cm.isSelected) {
                count++;
            }
        }
        return count;
    }

    selectedChanged(cohort: any) {
        if (cohort.Cohort.CohortMaterial.length > 0) {
            const count = this.selectedCount(cohort);
            cohort.allSelected = cohort.Cohort.CohortMaterial.length === count ? true : false;
        }
    }

    selectedPlaceholderCount(placeholder: any) {
        let count = 0;
        for (const pl of placeholder.AnimalPlaceholders) {
            if (pl.isSelected) {
                count++;
            }
        }
        return count;
    }

    selectedPlaceholderChanged(placeholder: any) {
        if (placeholder.AnimalPlaceholders.length > 0) {
            const count = this.selectedPlaceholderCount(placeholder);
            placeholder.allSelected = placeholder.AnimalPlaceholders.length === count ? true : false;
        }
    }

    /**
     * The Select/Clear All button was clicked
     */
    allSelectedChanged(cohort: any) {
        // Select or unselect all the rows
        if (cohort.Cohort.CohortMaterial.length > 0) {
            for (const cm of cohort.Cohort.CohortMaterial) {
                cm.isSelected = cohort.allSelected;
            }
        }
    }

    /**
     * The Select/Clear All button was clicked on placeholder
     */
    allSelectedChangedPlaceholder(placeholder: any) {
        // Select or unselect all the rows
        if (placeholder.AnimalPlaceholders.length > 0) {
            for (const pl of placeholder.AnimalPlaceholders) {
                pl.isSelected = placeholder.allSelected;
            }
        }
    }

    protocolTasksSelectionChanged() {
        // empty taskAliases
        this.taskAliases = [];
        const taskAliasesText: string[] = [];
        const selectedKeys = new Set(this.protocolTasksSelected);
        const aliasesSelected = new Set(this.taskAliasesSelected);

        // find index of selected protocol
        const index = this.protocols.findIndex((protocol: ProtocolInstance) => protocol.C_ProtocolInstance_key === this.protocolSelected);

        // Map selected protocol tasks selected to matching task instances and show there aliases
        this.protocols[index].TaskInstance.forEach((task: TaskInstance) => {
            // Map all tasks
            if (selectedKeys.has(task.WorkflowTask.C_WorkflowTask_key)) {
                this.taskAliases = this.taskAliases.concat(task);
                taskAliasesText.push(task.TaskAlias);
                if (aliasesSelected.has(task)) {
                    this.taskAliasesSelected.push(task);
                }
            }
        });
    }

    protocolSelectionChanged() {
        if (this.protocolSelected !== 'null') {
            const protocolTasksSelected: any[] = [];
            this.taskAliasesSelected = [];
            this.taskAliases = [];
            this.protocolToWorkflowTaskMap[this.protocolSelected].forEach((task: any) => {
                protocolTasksSelected.push(task.C_WorkflowTask_key);
            });
            setTimeout(() => {
                this.protocolTasksSelected = protocolTasksSelected;
                this.protocolTasksSelectionChanged();
            });
        }
    }

    onCancel(): void {
        this.activeModal.dismiss();
    }

    onSubmit(): void {
        const selectedAnimals: any[] = [];
        const selectedCohorts: any[] = [];
        // If cohort or placeholder is shown 
        if (this.showCohorts) {
            // Check if random selection is selected or individual
            if (this.randomChooseCohorts === 'individual') {
                // Find selected animals
                this.job.JobCohort.forEach((cohort: any) => {
                    if (cohort.allSelected === true) {
                        selectedCohorts.push(cohort.Cohort);
                        cohort.allSelected = false;
                    } else if (cohort.Cohort.CohortMaterial.length > 0) {
                        cohort.Cohort.CohortMaterial.forEach((cm: any) => {
                            if (cm.isSelected) {
                                selectedAnimals.push(cm.Material.Animal);
                            }
                        });
                    }
                });
            } else {
                // Selecting all cohorts is equal selecting none of it
                const selectedNoneOfCohorts = this.job.JobCohort.every((cohort: any) => !cohort.isSelected);
                this.job.JobCohort.forEach((cohort: any) => {
                    if (cohort.Cohort.CohortMaterial.length > 0 && (selectedNoneOfCohorts || cohort.isSelected)) {
                        const selectedIndexes: any = [];
                        for (let i = 0; i < this.noOfCohorts; i++) {
                            // keep finding a random number unless it is not already selected
                            let randomIndex = Math.floor(Math.random() * cohort.Cohort.CohortMaterial.length);
                            while (selectedIndexes.includes(randomIndex)) {
                                randomIndex = Math.floor(Math.random() * cohort.Cohort.CohortMaterial.length);
                            }

                            selectedIndexes.push(randomIndex);
                            selectedAnimals.push(cohort.Cohort.CohortMaterial[randomIndex].Material.Animal);
                        }
                    }
                });
            }
        } else {
            // Check if random selection is selected or individual
            if (this.randomChooseCohorts === 'individual') {
                // Find selected animals
                this.job.Placeholder.forEach((placeholder: any) => {
                    if (placeholder.allSelected === true) {
                        selectedCohorts.push(placeholder);
                        placeholder.allSelected = false;
                    } else if (placeholder.AnimalPlaceholders.length > 0) {
                        placeholder.AnimalPlaceholders.forEach((cm: any) => {
                            if (cm.isSelected) {
                                selectedAnimals.push(cm);
                            }
                        });
                    }
                });
            } else {
                // Selecting all placeholders is equal selecting none of it
                const selectedNoneOfPlaceholders = this.job.Placeholder.every((placeholder: any) => !placeholder.isSelected);
                this.job.Placeholder.forEach((placeholder: any) => {
                    if (placeholder.AnimalPlaceholders.length > 0 && (selectedNoneOfPlaceholders || placeholder.isSelected)) {
                        const selectedIndexes: any = [];
                        for (let i = 0; i < this.noOfCohorts; i++) {
                            // keep finding a random number unless it is not already selected
                            let randomIndex = Math.floor(Math.random() * placeholder.AnimalPlaceholders.length);
                            while (selectedIndexes.includes(randomIndex)) {
                                randomIndex = Math.floor(Math.random() * placeholder.AnimalPlaceholders.length);
                            }

                            selectedIndexes.push(randomIndex);
                            selectedAnimals.push(placeholder.AnimalPlaceholders[randomIndex]);
                        }
                    }
                });
            }
        }
        // Loop all tasks and find the selected ones
        const selectedTasks: any[] = [];
        uniqueArrayFromPropertyPath(this.job, 'TaskJob.TaskInstance').filter((task: any) => task.IsGroup).forEach((task: any) => {
            if (this.protocolSelected === "null") {
                if (this.freeTasksSelected.includes(task.C_WorkflowTask_key) && task.ProtocolInstance === null) {

                    selectedTasks.push(task);
                }
                return;
            }
            if (this.protocolTasksSelected.includes(task.C_WorkflowTask_key)
                && task.ProtocolInstance
                && this.protocolSelected === task.ProtocolInstance.C_ProtocolInstance_key
            ) {
                if (this.taskAliases.length > 0 && this.taskAliasesSelected.length > 0) {
                    if (this.taskAliasesSelected.includes(task.C_TaskInstance_key)) {
                        selectedTasks.push(task);
                        return;
                    }
                    return;
                }
                selectedTasks.push(task);
            }
        });

        this.activeModal.close({
            selectedAnimals,
            selectedCohorts,
            selectedTasks,
        });
    }

    private get freeTaskSelectValid() {
        return this.freeTasks.length === 0 || this.freeTasksSelected.length !== 0;
    }

    private get protocolTaskSelectValid() {
        return this.protocolSelected === 'null' ||
            !this.protocolToWorkflowTaskMap[this.protocolSelected] ||
            this.protocolToWorkflowTaskMap[this.protocolSelected].length === 0 ||
            this.protocolTasksSelected.length !== 0;
    }

    get taskSelectValid() {
        return this.protocolSelected === 'null' ? this.freeTaskSelectValid : this.protocolTaskSelectValid;
    }

    get checkAddDisabled() {
        const isTaskSelected = this.taskSelectValid;

        if (!isTaskSelected) {
            return true;
        }

        if (this.randomChooseCohorts === 'individual') {
            if (this.showCohorts && !this.job.JobCohort.some((cohort: any) => this.selectedCount(cohort) > 0)) { 
                return true;
            }
            if (!this.showCohorts && !this.job.Placeholder.some((placeholder: any) => this.selectedPlaceholderCount(placeholder) > 0)) {
                return true;
            }
        } else if (this.randomChooseCohorts === 'random') {
            return !this.noOfCohorts || this.maxAnimalError;
        }
        return false;
    }
}

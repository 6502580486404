import {
    Component,
    DoCheck,
    Input,
    IterableDiffer,
    IterableDiffers,
    OnInit
} from '@angular/core';
import { Cohort, CohortMaterial } from '../../../common/types/models';
import { Entity } from "@common/types";
import { populationStandardDeviation } from '@common/util/population-standard-deviation';

@Component({
    /* Use attribute selector so we can embed component in tables */
    /* eslint-disable-next-line */
    selector: '[cohort-output-stats-rows]',
    templateUrl: './cohort-output-stats-rows.component.html'
})
export class CohortOutputStatsRowsComponent implements OnInit, DoCheck {
    @Input() cohort: Entity<Cohort>;
    // The maximum number of outputs that can be selected
    @Input() maxOutputs: number;
    // The number of columns in the enclosing table available only to ReadWrite users
    @Input() columnsReadWrite: number;
    // The total number of columns in the enclosing table
    @Input() columnsTotal: number;
    @Input() readonly: boolean;
    output1Values: number[] = [];
    output2Values: number[] = [];
    output3Values: number[] = [];

    iterableDiffer: IterableDiffer<CohortMaterial>;

    constructor(
        private iterableDiffers: IterableDiffers
    ) {
        this.iterableDiffer = iterableDiffers.find([]).create(null);
    }

    ngOnInit(): void {
        this.calculate(this.cohort);
    }

    ngDoCheck() {
        const changes = this.iterableDiffer.diff(this.cohort.CohortMaterial);
        if (changes) {
            this.calculate(this.cohort);
        }
    }

    public calculate(cohort: Cohort) {
        if (cohort) {
            this.output1Values = cohort.CohortMaterial.map((cohortMaterial: CohortMaterial) => {
                if (cohortMaterial.OutputValue1 !== null) {
                    return Number(cohortMaterial.OutputValue1);
                }
            }).filter((value: any) => {
                return !(value === null || value === undefined);
            });
            this.output2Values = cohort.CohortMaterial.map((cohortMaterial: CohortMaterial) => {
                if (cohortMaterial.OutputValue2 !== null) {
                    return Number(cohortMaterial.OutputValue2);
                }
            }).filter((value: any) => {
                return !(value === null || value === undefined);
            });
            this.output3Values = cohort.CohortMaterial.map((cohortMaterial: CohortMaterial) => {
                if (cohortMaterial.OutputValue3 !== null) {
                    return Number(cohortMaterial.OutputValue3);
                }
            }).filter((value: any) => {
                return !(value === null || value === undefined);
            });
        }
    }

    public get hasNullOutputValues() {
        return ((this.output1Values.length > 0 && (this.output1Values.length !== this.cohort.CohortMaterial.length))
            || (this.output2Values.length > 0 && (this.output2Values.length !== this.cohort.CohortMaterial.length))
            || (this.output3Values.length > 0 && (this.output3Values.length !== this.cohort.CohortMaterial.length)));
    }

    average(values: number[]): number {
        if (values && values.length > 0) {
            return values.reduce((a, b) => a + b) / values.length;
        } else {
            return 0;
        }
    }

    standardDeviation(values: number[]): number {
        return populationStandardDeviation(values);
    }

    median(values: number[]): number {
        if (values && values.length > 0) {
            values.sort((a, b) => a - b);

            // If length is odd, median = values[length/2]
            if (values.length % 2 !== 0) {
                return values[((values.length + 1) / 2) - 1];
            } else {
                // If length is even, median = (values[length/2]+values[1+length/2])/2
                return (values[((values.length) / 2) - 1] + values[((values.length) / 2)]) / 2;
            }
        } else {
            return 0;
        }
    }

    outputValues(): number[][] {
        return [this.output1Values, this.output2Values, this.output3Values];
    }
}

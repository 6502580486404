import {
    Component,
    Input,
    OnChanges,
    OnInit,
} from '@angular/core';

import {
    NgbModal,
    NgbModalOptions
} from '@ng-bootstrap/ng-bootstrap';

import {
    StoredFileService
} from './storedfile.service';

import { FileUploadModalComponent } from './file-upload-modal.component';

@Component({
    selector: 'view-files-button',
    templateUrl: './view-files-button.component.html'
})
export class ViewFilesButtonComponent implements OnInit, OnChanges {
    /* The name of the object's primary key database field */
    @Input() pkName: string;
    /* The value of the object's primary key */
    @Input() pkValue: number; 
    @Input() facetPrivilege: string;
    // State
    loading = false;
    fileCount = 0;


    constructor(
        private storedFileService: StoredFileService,
        private modalService: NgbModal,
    ) { }

    ngOnInit() {
        this.initialize();
    }

    ngOnChanges(changes: any) {
        if (
            (changes.pkValue && !changes.pkValue.firstChange) ||
            (changes.pkName && !changes.pkName.firstChange)
        ) {
            // Detail paging
            this.initialize();
        }
    }

    initialize() {
        this.getFileCount();
    }

    private getFileCount(): Promise<any> {
        
        if (this.pkName && this.pkValue) {
            this.loading = true;

            return this.storedFileService.getFileCount(this.pkName, this.pkValue)
                .then((count: number) => {
                    this.fileCount = count;
                    this.loading = false;
                })
                .catch((err: Error) => {
                    this.loading = false;
                    throw err;
                });
        }

        return Promise.resolve();
    }


    viewFiles() {
        // Open file upload modal
        const  modalOptions: NgbModalOptions = {
            backdrop: "static",
            keyboard: false,
        };
        const ref = this.modalService.open(FileUploadModalComponent, modalOptions);
        const component = ref.componentInstance as FileUploadModalComponent;
        component.pkName = this.pkName;
        component.pkValue = this.pkValue;
        component.facetPrivilege = this.facetPrivilege;

        // On modal exit, update the file count
        component.exit.subscribe(() => {
            this.getFileCount();
        });
    }
}

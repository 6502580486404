import { findElementByQuery } from './find-element';
/*
* Scrolls to target element, as defined by the
*   targetElementQuery CSS selector
*
* NOTE: Finds first scrollable parent element in the dom or body
*   as the scrollable container
*/
export function scrollToElement(targetElementQuery: string): Promise<void> {
    return findElementByQuery(targetElementQuery).then((targetElement) => {
        if (!targetElement) {
            return;
        }

        const parentElement = _getScrollParent(targetElement);
        _scrollToElement($(parentElement), $(targetElement));
    });
}

export function scrollToElementFrom(
    targetElementQuery: string,
    parentElementQuery: string
): void {
    const targetPromise = findElementByQuery(targetElementQuery);
    const parentPromise = findElementByQuery(parentElementQuery);
    Promise.all([targetPromise, parentPromise]).then((elements) => {
        const targetElement = elements[0];
        const parentElement = elements[1];
        if (!targetElement || ! parentElement) {
            return;
        }

        _scrollToElement($(parentElement), $(targetElement));
    });
}

function _getScrollParent(node: Element): Element {
    if (node === null) {
        return document.body;
    }

    if (_isScrollable(node)) {
        return node;
    } else {
        return _getScrollParent(node.parentElement);
    }
}

function _scrollToElement(parentElement: JQuery<Element>, targetElement: JQuery<Element>): void {
    const animationSpeed = 'fast';
    const parentScrollTop: number = parentElement.scrollTop();
    const parentOffsetTop: number = parentElement.offset().top;
    const targetOffsetTop: number = targetElement.offset().top;

    parentElement.animate({
        scrollTop: parentScrollTop - parentOffsetTop + targetOffsetTop
    }, animationSpeed);
}

function _isScrollable(node: any) {
    const hasOverflow = node.scrollHeight > node.clientHeight;
    const overflowY = window.getComputedStyle(node)['overflow-y'];
    return hasOverflow;
}

<ng-container [ngSwitch]="dataType">

    <input *ngSwitchCase="NUMBER"
           type="number"
           [(ngModel)]="_convertedModelValue"
           (ngModelChange)="formatValue()"
           (focusout)="focusOut()"
           step="{{numberStep}}"
           min="{{characteristicDef.ValidationMinimum }}"
           max="{{characteristicDef.ValidationMaximum }}"
           class="form-control"
           [ngClass]="cssClass" />

    <input *ngSwitchCase="TEXT"
           type="text"
           [(ngModel)]="_convertedModelValue"
           (ngModelChange)="formatValue()"
           (focusout)="focusOut()"
           class="form-control"
           [ngClass]="cssClass" />

    <textarea *ngSwitchCase="LONG_TEXT"
              rows="{{characteristicDef.TextLineCount}}"
              [(ngModel)]="_convertedModelValue"
              (ngModelChange)="formatValue()"
              (focusout)="focusOut()"
              class="form-control input-large"
              [ngClass]="cssClass"></textarea>

    <climb-ngb-date *ngSwitchCase="DATE"
                    #dateControl="ngModel"
                    [(ngModel)]="_convertedModelValue"
                    [ngModelOptions]="{ standalone: true }"
                    (ngModelChange)="formatValue()"
                    (focusout)="focusOut()"></climb-ngb-date>

    <input *ngSwitchCase="BOOLEAN"
           type="checkbox"
           [(ngModel)]="_convertedModelValue"
           (ngModelChange)="formatValue()"
           (focusout)="focusOut()" />

    <ng-container *ngSwitchCase="ENUMERATION">
        <select class="form-control"
                [ngClass]="cssClass"
                [(ngModel)]="_convertedModelValue"
                (ngModelChange)="formatValue()"
                (focusout)="focusOut()"
                *ngIf="!readonly">
            <option></option>
            <option *ngIf="enumerationItems && _convertedModelValue && _convertedModelValue !== ''">{{_convertedModelValue}}</option>
            <ng-container *ngIf="enumerationItems">
                <ng-container *ngFor="let item of enumerationItems">
                    <option *ngIf="item.ItemName !== _convertedModelValue"
                            [value]="item.ItemName">
                        {{item.ItemName}}
                    </option>
                </ng-container>
            </ng-container>
        </select>

        <p *ngIf="readonly">
            {{_convertedModelValue}}
        </p>
    </ng-container>

    <ng-container *ngSwitchCase="VOCABULARY">
        <select class="form-control"
                [ngClass]="cssClass"
                [(ngModel)]="_convertedModelValue"
                (ngModelChange)="formatValue()"
                (focusout)="focusOut()"
                *ngIf="!readonly">
            <option *ngIf="vocabularyItems && _convertedModelValue && _convertedModelValue !== ''">{{_convertedModelValue}}</option>
            <ng-container *ngIf="vocabularyItems">
                <ng-container *ngFor="let item of vocabularyItems">
                    <option *ngIf="item.ItemName !== _convertedModelValue"
                            [value]="item.ItemName">
                        {{item.ItemName}}
                    </option>
                </ng-container>
            </ng-container>
        </select>

        <p *ngIf="readonly">
            {{_convertedModelValue}}
        </p>
    </ng-container>

    <ng-container *ngSwitchCase="LONG_ENUMERATION">
        <div style="min-width: 300px"
             *ngIf="!readonly">
            <climb-typeahead [(model)]="_convertedModelValue"
                             (modelChange)="formatValue()"
                             (focusout)="focusOut()"
                             [resultFormatter]="enumFormatter"
                             [keyFormatter]="enumFormatter"
                             [search]="searchEnumerations"
                             [keySearch]="searchEnumerationsForExactMatch"
                             [namespace]="characteristicDef.C_EnumerationClass_key + ''">
            </climb-typeahead>
        </div>

        <p *ngIf="readonly">
            {{_convertedModelValue}}
        </p>
    </ng-container>

    <climb-ngb-date *ngSwitchCase="DATE_TIME"
                    #dateControl="ngModel"
                    [allowTime]="true"
                    [(ngModel)]="_convertedModelValue"
                    [ngModelOptions]="{ standalone: true }"
                    (ngModelChange)="formatValue()"
                    (focusout)="focusOut()"
                    [disabled]="readonly"></climb-ngb-date>
</ng-container>

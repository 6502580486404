import { ORDER_FACET_COMPONENT } from '../dynamic/lazy-components.token';
import { FacetSharedModule } from './../common/facet/facet-shared.module';
import { SearchSharedModule } from './../search/search-shared.module';

import { EnumerationsSharedModule } from './../enumerations/enumerations-shared.module';
import { CharacteristicsSharedModule } from './../characteristics/characteristics-shared.module';
import { OrderDetailComponent } from './order-detail.component';
import { OrderFacetComponent } from './order-facet.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { ClimbCommonModule } from '../common/climb-common.module';
import { ClimbServicesModule } from '../services/climb-services.module';

import { OrdersSharedModule } from './orders-shared.module';

import { AuditSharedModule } from '../audit/audit-shared.module';
import { LinesSharedModule } from '../lines/lines-shared.module';
import { UsersSharedModule } from '../user/users-shared.module';
import { WorkspacesSharedModule } from '../workspaces/workspaces-shared.module';
import { VocabulariesSharedModule } from '../vocabularies/vocabularies-shared.module';
import { JobsSharedModule } from '../jobs/jobs-shared.module';
import { SampleOrderDetailComponent } from './sample-order-detail.component';
import { LotsTableComponent } from './lots-table.component';

import { NgxMaskModule } from 'ngx-mask';
import { OrderAnimalTableComponent } from './order-animal-table.component';
import { LocationsSharedModule } from '../locations/locations-shared.module';
import { OrderLocationTableComponent } from './order-location-table.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgbModule,
        CharacteristicsSharedModule,
        ClimbCommonModule,
        ClimbServicesModule,
        FacetSharedModule,
        OrdersSharedModule,
        AuditSharedModule,
        EnumerationsSharedModule,
        LinesSharedModule,
        SearchSharedModule,
        UsersSharedModule,
        VocabulariesSharedModule,
        WorkspacesSharedModule,
        NgxMaskModule.forChild(),
        JobsSharedModule,
        LocationsSharedModule
    ],
    declarations: [
        OrderFacetComponent,
        OrderDetailComponent,
        SampleOrderDetailComponent,
        LotsTableComponent,
        OrderAnimalTableComponent,
        OrderLocationTableComponent,
    ],
    providers: [
        { provide: ORDER_FACET_COMPONENT, useValue: OrderFacetComponent },
    ]
})
export class OrdersModule { }


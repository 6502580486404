export class SubParameterItem {
    key: any;
    text: string;
    inputType: string;
    details: any;
    results: any;

    /**
     * Enable field initializer
     *   E.g. new SubParameterItem({ field1: 'value', ...})
     * @param init
     */
    public constructor(init?: Partial<SubParameterItem>) {
        this.details = {};

        Object.assign(this, init);
    }

}

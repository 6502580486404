import { ConfirmService } from '../../common/confirm/confirm.service';
import { map } from 'rxjs/operators';
import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import { NgForm } from '@angular/forms';

import { InstitutionService } from '../institution.service';
import { InstitutionVocabService } from '../institution-vocab.service';

import {
    BaseDetail,
    BaseDetailService,
    PageState
} from '../../common/facet';
import { ViewInstitutionAuditReportComponentService } from '../audit';
import { PrivilegeService } from '../../services/privilege.service';
import { VocabularyService } from '../../vocabularies/vocabulary.service';
import { SaveChangesService, UnsavedChanges } from '../../services/save-changes.service';

const sortBy = <K extends string>(propName: K) => <T extends { [key in K]: string }>(a: T, b: T) => (a[propName] > b[propName]) ? 1 : -1;
const sortByStateName = sortBy('StateName');
const sortByCountry = sortBy('Country');

@Component({
    selector: 'institution-detail',
    templateUrl: './institution-detail.component.html'
})
export class InstitutionDetailComponent extends BaseDetail
    implements OnChanges, OnInit {
    @Input() facet: any;
    @Input() institution: any;
    @Input() isCRL = false;
    @Input() isCRO = false;
    @Input() pageState: PageState;
    @Output() exit: EventEmitter<void> = new EventEmitter<void>();
    @Output() next: EventEmitter<void> = new EventEmitter<void>();
    @Output() previous: EventEmitter<void> = new EventEmitter<void>();

    @ViewChild("InstitutionForm") institutionForm: NgForm;

    // CVs
    institutionTypes: any[] = [];
    states: any[] = [];
    countries: any[] = [];
    filteredStates: any[] = [];

    // State
    saving = false;
    
    printPreviewId: string;

    readonly COMPONENT_LOG_TAG = 'institution-detail';

    readwrite: boolean;
    readonly: boolean;
    hide: boolean;
    // current item edited in detail
    itemToEdit: any = {};

    newSiteName = "New Site";
    existingSites: any = [];

    loadingMessage = 'Loading';

    constructor(
        baseDetailService: BaseDetailService,
        private confirmService: ConfirmService,
        private privilegeService: PrivilegeService,
        private institutionService: InstitutionService,
        private institutionVocabService: InstitutionVocabService,
        private viewInstitutionAuditReportComponentService: 
            ViewInstitutionAuditReportComponentService,
        private vocabularyService: VocabularyService,
        private saveChangesService: SaveChangesService
    ) {
        super(baseDetailService);
    }

    // lifecycle
    ngOnInit() {

        this.initialize();
        if (this.isCRL && this.institution.Identifier) {
            this.getClient().then(() => {
                this.onSave(false);
            });
        }
    }

    ngOnChanges(changes: any) {
        if (changes.institution) {
            if (this.institution && !changes.institution.firstChange) {
                if (this.institutionForm) {
                    this.institutionForm.form.markAsPristine();
                }
                this.initialize();
            }
        }
    }

    initialize() {
        this.setLoading(true);

        this.setPrivileges();

        return this.getCVs().then(() => {
            this.countryChanged();
            this.getSiteDetails();
            return this.getDetails();
        }).then(() => {
            this.setLoading(false);
        }).catch((error) => {
            this.setLoading(false);
            throw error;
        });
    }

    private getCVs(): Promise<any> {
        const p1 = this.institutionVocabService.institutionTypes$.pipe(map((data) => {
            this.institutionTypes = data;
        })).toPromise();

        const p2 = this.institutionVocabService.states$.pipe(map((data) => {
            this.states = [...data].sort(sortByStateName);
        })).toPromise();

        const p3 = this.institutionVocabService.countries$.pipe(map((data) => {
            this.countries = [...data].sort(sortByCountry);
        })).toPromise();

        return Promise.all([p1, p2, p3]);
    }

    private getDetails(): Promise<any> {
        if (this.institution && this.institution.C_Institution_key > 0) {

            return this.institutionService.getInstitution(this.institution.C_Institution_key);
        }

        return Promise.resolve(this.institution);
    }

    /**
     * Sets privilege variables.
     */
    private setPrivileges() {
        this.readonly = this.privilegeService.readonly;
        this.readwrite = this.privilegeService.readwrite;
    }

    reinitializeVocabs(): Promise<any> {
        return this.getCVs();
    }

    getClient(): Promise<any> {
        const customerID = this.institution.Identifier;
        
        if (customerID && customerID.length === 10) {
            this.setLoading(true);
            this.loadingMessage = 'Retrieving client information';

            return this.institutionService.getCRLClient(customerID).then((response: any) => {
                const customerInfo = response.data;
                if (customerInfo && customerInfo.ClientID === customerID) {
                    // Set fields based on info from CRL
                    return this.handleCrlStateAndCountry(customerInfo.State, customerInfo.Country).then((obj: any) => {
                        return this.reinitializeVocabs().then(() => {
                            // Update institution fields based on info from CRL
                            this.institution.C_Country_key = obj.countryKey;
                            this.countryChanged();
                            this.institution.C_State_key = obj.stateKey;
                            this.institution.Name = customerInfo.Name;
                            this.institution.Identifier = customerInfo.ClientID;
                            this.institution.EmailAddress = customerInfo.Email;
                            this.institution.Address = customerInfo.Address1;
                            this.institution.AddressQualifier = customerInfo.Address2;
                            this.institution.PostalCode = customerInfo.PostalCode;
                            this.institution.Phone = customerInfo.PhoneNumber;
                            this.institution.City = customerInfo.City;

                            this.setLoading(false);
                            return Promise.resolve();
                        });
                    });
                } else {
                    // Api did not return info, and must have failed on CRL side
                    this.clearInstitutionFields();
                    this.loggingService.logError(
                        "Client retrieval failed. Could not find a client with the given Client ID in the CRL database.",
                        null, this.COMPONENT_LOG_TAG, true
                    );

                    this.setLoading(false);
                    return Promise.resolve();
                }
                
            }).catch((error: any) => {
                this.setLoading(false);
                this.loggingService.logError(
                    'Could not reach CRL database',
                    error, this.COMPONENT_LOG_TAG, true
                );
                return Promise.resolve();
            });
        } else {
            this.clearInstitutionFields();
            this.loggingService.logError(
                "Client ID must be a 10 digit number.",
                null, this.COMPONENT_LOG_TAG, true
            );
            return Promise.resolve();
        }
    }

    // Check if the country & state names from CRL exist in Climb
    handleCrlStateAndCountry(state: string, country: string): Promise<any> {
        // Can't add state without country
        if (state && !country) {
            this.loggingService.logWarning(
                "Could not populate state field. No country was provided.",
                null, this.COMPONENT_LOG_TAG, true
            );
            return;
        }

        // Find Country and State keys
        return this._handleCountry(country).then((countryKey) => {
            return this._handleState(state, countryKey).then((stateKey) => {
                return Promise.resolve({stateKey, countryKey});
            });
        });
    }

    private _handleCountry(country: string): Promise<number> {
        // Look for country with the given name
        let countryItem = this.countries.find((c) => c.Country.toLowerCase() === country.toLowerCase() || c.Abbreviation.toLowerCase() === country.toLowerCase());
        // Create new country if it doesn't exist
        if (country && !countryItem) {
            countryItem = this.institutionVocabService.addNewCountry(country);
            return this.saveChangesService.saveChanges(this.COMPONENT_LOG_TAG, false).then(() => {
                return Promise.resolve(countryItem.C_Country_key);
            });
        } else {
            // Return country key
            return Promise.resolve(countryItem.C_Country_key);
        }
    }

    private _handleState(state: string, countryKey: number): Promise<number> {
        // Look for state with given name & country key
        let stateItem = this.states.find((c) => (c.StateName.toLowerCase() === state.toLowerCase() || c.Abbreviation.toLowerCase() === state.toLowerCase()) && c.C_Country_key === countryKey);
        // Create new state if it doesn't exist
        if (state && !stateItem) {
            stateItem = this.institutionVocabService.addNewState(state, countryKey);
            return this.saveChangesService.saveChanges(this.COMPONENT_LOG_TAG, false).then(() => {
                return Promise.resolve(stateItem.C_State_key);
            });
        } else {
            // Return state key
            return Promise.resolve(stateItem.C_State_key);
        }
    }

    clearInstitutionFields() {
        this.institution.Name = null;
        this.institution.EmailAddress = null;
        this.institution.Address = null;
        this.institution.PostalCode = null;
        this.institution.AddressQualifier = null;
        this.institution.Phone = null;
        this.institution.City = null;
        this.institution.C_State_key = null;
        this.institution.C_Country_key = null;
    }

    onSave(showToast?: boolean): any {
        // Check that Name field has a value
        if (!this.institution.Name || this.institution.Name === '') {
            this.loggingService.logError(
                'Name is a required field.',
                null, this.COMPONENT_LOG_TAG, true
            );
        } else {
            // Check that ContactPerson Array in institutes doesn't have any object without FirstName
            const hasEmptyContactPerson = this.institution.ContactPerson.some((c: any) => !c.FirstName || c.FirstName === '');
            if (hasEmptyContactPerson) {
                this.loggingService.logError(
                    'Contacts must have a name. Please fill in a name and try again',
                    null, this.COMPONENT_LOG_TAG, true
                );
            } else {
                this.saveChangesService.saveChanges(this.COMPONENT_LOG_TAG, showToast);
            }
        }
    }

    onSwitchView(buttonName: string): any {
        if (this.saveChangesService.hasChanges) {
            // If there are unsaved changes, prompt the user to save or discard
            return this.viewUnsavedChangesModalService.openComponent().then((result: string) => {
                if (result === 'save') {
                    // Throw error & don't save if required field is missing
                    if (!this.institution.Name || this.institution.Name === '') {
                        this.loggingService.logError(
                            'Name is a required field.',
                            null, this.COMPONENT_LOG_TAG, true
                        );
                        return Promise.resolve();
                    } else { // Save changes
                        return this.saveChangesService.saveChanges(this.COMPONENT_LOG_TAG).then(() => {
                            // Emits reload event when save a new record by clicking on next
                            if (buttonName === 'next') {
                                this.reload.emit();
                            }
                            this.emitViewChange(buttonName, UnsavedChanges.save);
                        });
                    }
                } else {
                    this.emitViewChange(buttonName, UnsavedChanges.discard);
                }
            });
        } else {
            this.emitViewChange(buttonName, UnsavedChanges.noChanges);
        }
    }

    private emitViewChange(buttonName: string, changes: UnsavedChanges) {
        switch (buttonName) {
            case 'previous':
                super.previousClicked(changes);
                this.previous.emit();
                break;
            case 'next':
                super.nextClicked(changes);
                this.next.emit();
                break;
            case 'exit':
                super.exitClicked(changes);
                this.exit.emit();
                break;
            default:
                break;
        }
    }


    onCancel() {
        this.institutionService.cancelInstitution(this.institution);
    }
    
    viewAuditReport() {
        this.viewInstitutionAuditReportComponentService
            .openComponent(this.institution.C_Institution_key);
    }

    // Filter states based on selected country
    countryChanged() {
        let filteredStates: any[] = [];

        if (this.institution.C_Country_key) {
            filteredStates = this.states
                .filter((state) => state.C_Country_key === this.institution.C_Country_key)
                .sort(sortByStateName);
        }

        this.filteredStates = filteredStates;
    }

    // Formatters for <select> input
    institutionTypeKeyFormatter = (value: any) => {
        return value.C_InstitutionType_key;
    }
    institutionTypeFormatter = (value: any) => {
        return value.InstitutionType;
    }

    institutionStateKeyFormatter = (value: any) => {
        return value.C_State_key;
    }
    institutionStateFormatter = (value: any) => {
        return value.StateName;
    }

    institutionCountryKeyFormatter = (value: any) => {
        return value.C_Country_key;
    }
    institutionCountryFormatter = (value: any) => {
        return value.Country;
    }

    onClickSite() {
        if (!this.hide) {
            this.hide = true;
        } else {
            this.hide = false;
        }
    }

    removeSite(site: any) {
        return this.confirmService.confirmDelete(
            'Delete Site',
            'Are you sure you want to delete this site?'
        ).then(
            // confirmed
            () => {
                this.institutionService.deleteSite(site);
                if (this.institution.C_Institution_key > 0 &&
                    site.C_Site_key > 0
                ) {
                    this.dataContext.save();
                }
            },
            // cancel
            () => { /* do nothing on cancel */ }
        );
    }

    createNewItem(): Promise<any> {
        if (!this.readonly) {
            const newSite = this.institutionService.createSite(this.institution.C_Institution_key);

            const p1 = this.vocabularyService.getCVDefault('cv_SiteTypes').then((value) => {
                newSite.cv_SiteType = value;
            });
            const p2 = this.vocabularyService.getCVDefault('cv_States').then((value) => {
                newSite.cv_State = value ? value.sort(sortByStateName) : value;
            });
            const p3 = this.vocabularyService.getCVDefault('cv_Countries').then((value) => {
                newSite.cv_Country = value ? value.sort(sortByCountry) : value;
            });

            return Promise.all([p1, p2, p3]).then(() => {
                return newSite;
            });
        }
    }

    private getSiteDetails(): Promise<any> {
        if (this.institution && this.institution.C_Institution_key > 0) {

            return this.institutionService.getInstitutionSites(this.institution.C_Institution_key);
        }

        return Promise.resolve(this.institution);
    }
}

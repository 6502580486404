<button style="width:70px" *ngIf="!isConfirmed && !isEndState"
        class="btn btn-success"
        (click)="onConfirmButtonClicked()">
    Confirm
</button>
<button style="width:70px" *ngIf="isConfirmed && !isEndState"
        class="btn btn-light">
    Confirm
</button>
<button style="width:70px" *ngIf="!isConfirmed && isEndState"
        class="btn btn-light disabled">
    Confirm
</button>
<button style="width:70px" *ngIf="isConfirmed && isEndState"
        class="btn btn-light disabled">
    Confirm
</button>

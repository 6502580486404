<!-- Filter and List files -->
<ng-container *ngIf="files | notEmpty">

    <!-- Filter -->
    <div class="form-group row filter-wrapper clearfix">
        <div class="col-md-12">
            <div class="input-group filter-input-group">
                <label for="{{_filterInputId}}" class="sr-only">Filter files</label>
                <input type="text"
                       class="form-control filter-control"
                       [id]="_filterInputId"
                       placeholder="Filter files&hellip;"
                       autocorrect="off"
                       spellcheck="false"
                       (ngModelChange)="filterList($event)"
                       [(ngModel)]="filterTerm" />
                <div class="input-group-append">
                    <span class="input-group-text">
                        <i class="fa fa-search" aria-hidden="true"></i>
                    </span>
                </div>
            </div>
        </div>
    </div>

    <!-- List -->
    <table class="table">
        <thead>
            <tr>
                <th *ngIf="facetPrivilege === 'ReadWrite' && !disabled">
                    <!-- Delete -->
                </th>
                <th>
                    <!-- Download -->
                </th>
                <th>
                    <!-- View Image -->
                </th>
                <th>
                    <a (click)="tableSort.toggleSort('FriendlyFileName')"
                       title="Sort by file name">
                        File
                    </a>
                </th>
                <th>
                    <a (click)="tableSort.toggleSort('DateCreated')"
                       title="Sort by date">
                        Date
                    </a>
                </th>
            </tr>
        </thead>

        <tbody>
            <tr *ngFor="let file of filesDisplay | orderBy:tableSort.propertyPath:tableSort.reverse">
                <td class="icon-cell"
                    *ngIf="facetPrivilege === 'ReadWrite' && !disabled">
                    <a (click)="removeFile(file)"
                       *ngIf="file.C_StoredFile_key > 0">
                        <i class="fa fa-times remove-item" title="Delete file"></i>
                    </a>
                </td>
                <td class="icon-cell">
                    <a (click)="downloadFile(file)"
                       *ngIf="file.C_StoredFile_key > 0">
                        <i class="fa fa-download" title="Download file"></i>
                    </a>
                </td>
                <td class="icon-cell">
                    <a (click)="viewImage(file)"
                       *ngIf="isImage(file.FileName) && file.C_StoredFile_key > 0">
                        <i class="fa fa-file-image-o" title="View image"></i>
                    </a>
                </td>
                <td class="col-size-fill">
                    {{file.FriendlyFileName}}
                </td>
                <td class="col-size-fit text-nowrap">
                    {{file.DateCreated | formatShortDateTime}}
                </td>
            </tr>
        </tbody>
    </table>
</ng-container>


<!-- Add files -->
<ng-container *ngIf="facetPrivilege === 'ReadWrite' && !disabled">
    <a *ngIf="!dropZoneExpanded"
       (click)="expandDropzoneClicked()"
       class="dropzone-link"
       aria-label="Show file drop-zone">
        Add files<i class="fa fa-fw fa-chevron-down"
                    aria-hidden="true"
                    title="Show file drop-zone"></i>
    </a>
    <a *ngIf="dropZoneExpanded"
       class="dropzone-link"
       (click)="collapseDropzoneClicked()"
       aria-label="Hide drop-zone">
        Add files<i class="fa fa-fw fa-chevron-up"
                    aria-hidden="true"
                    title="Hide file drop-zone"></i>
    </a>

    <div *ngIf="dropZoneExpanded" class="dropzone-wrapper">
        <a class="{{_scrollElementClass}}"></a>
        <climb-dropzone data-automation-id="add-file"
                        (success)="addFile($event)"
                        (removedfile)="dropzoneRemovedFile($event)"></climb-dropzone>
    </div>
</ng-container>

<div class="form-group">
    <label for="toSelect-{{domIdAddition}}">To</label>
    <recipient-select [recipients]="message.recipients"
                      [id]="'toSelect-' + domIdAddition"></recipient-select>
</div>

<div class="form-group">
    <label for="subjectInput-{{domIdAddition}}">Subject</label>
    <input id="subjectInput-{{domIdAddition}}"
           name="messageSubject"
           class="form-control"
           type="text"
           [(ngModel)]="message.messageSubject"
           required />
</div>

<div class="form-group">
    <label for="messageInput-{{domIdAddition}}">Message</label>
    <textarea id="messageInput-{{domIdAddition}}"
              name="messageText"
              class="form-control"
              rows="3"
              [(ngModel)]="message.messageText"
              required></textarea>
</div>

<div *ngIf="message.messageMaps | notEmpty"
     class="form-group">
    <b>Attached data:</b>
    <span *ngFor="let mapping of message.messageMaps">
        <message-map-label [messageMap]="mapping"></message-map-label>
    </span>
</div>

<div class="text-right">
    <button type="button" class="btn btn-fw btn-primary"
            (click)="sendClicked()"
            [disabled]="sendDisabled()">
        <i class="fa fa-spinner" title="Sending&hellip;" *ngIf="sending"></i>
        Send
    </button>
    <button type="button"
            class="btn btn-fw btn-secondary"
            [disabled]="sending"
            (click)="cancelClicked()">
        Cancel
    </button>
</div>

import { Injectable } from '@angular/core';
import { CsvExporter } from '../../common/export';
import { sortPlateMaterialsByXY } from './sort-plate-materials';

/**
 * Abstract base class for exporting plate details.
 */
@Injectable()
export abstract class BaseExportPlateDetailService {

    csvExporter: CsvExporter;


    constructor() {
        this.csvExporter = new CsvExporter();
    }


    exportToCsv(plate: any) {
        const data: any[][] = this.buildExportData(plate);
        const fileName = this.getFileName(plate);

        this.csvExporter.download(data, fileName);
    }


    private buildExportData(plate: any): any[][] {
        const rows: any[][] = [];

        // Header row: column names
        const columnHeaders: string[] = this.getColumnHeaders();
        rows.push(columnHeaders);

        // Data rows
        const plateMaterials = sortPlateMaterialsByXY(plate.PlateMaterial.slice());
        for (const plateMaterial of plateMaterials) {
            const row = this.getDataRow(plate, plateMaterial);
            rows.push(row);
        }

        return rows;
    }

    protected abstract getFileName(plate: any): string;

    protected abstract getColumnHeaders(): string[];

    protected abstract getDataRow(plate: any, plateMaterial: any): any[];
}

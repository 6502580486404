import { Injectable } from '@angular/core';
import { GlpOdataFacetSaveService } from '../../services/glp-odata-facet-save.service';
import { DataManagerService } from '../../services/data-manager.service';
import { DeletionService } from '../../services/deletion.service';
import { LoggingService } from '../../services/logging.service';
import { FeatureFlagService } from '../../services/feature-flags.service';
import { ReasonForChangeService } from '../../common/reason-for-change/reason-for-change.service';
import { ToastrService } from '../../services/toastr.service';
import { HousingStateService } from './housing-state.service';

@Injectable()
export class HousingSaveService extends GlpOdataFacetSaveService {
    protected saveSource = 'housing';

    constructor(
        protected dataManagerService: DataManagerService,
        protected deletionService: DeletionService,
        protected loggingService: LoggingService,
        protected featureFlagService: FeatureFlagService,
        protected reasonForChangeService: ReasonForChangeService,
        protected toastrService: ToastrService,
        private housingStateService: HousingStateService,
    ) {
        super(
            dataManagerService,
            deletionService,
            loggingService,
            featureFlagService,
            reasonForChangeService,
            toastrService,
            housingStateService,
        );
    }
}

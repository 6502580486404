import {
    Component,
    EventEmitter,
    Input,
    Output
} from '@angular/core';

import { InstitutionService } from './institution.service';

@Component({
    selector: 'institution-contact-multiselect',
    template: `
    <!-- Selected InstitutionContacts -->
    <div *ngIf="model && model.length > 0">
        <table class="table table-sm table-borderless table-nonfluid">
            <tbody>
                <tr *ngFor="let item of model; let i = index;">
                    <td class="icon-cell">
                        <a (click)="removeInstitutionContact(i)" title="Remove Contact">
                            <i class="fa fa-remove remove-item"></i>
                        </a>
                    </td>
                    <td>
                        {{item.FirstName}} {{item.LastName}}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <!-- Contact Name typeahead -->
    <climb-indirect-typeahead
            [search]="searchInstitutionContacts"
            [resultFormatter]="institutionContactNameFormatter"
            [disabled]="disabled"
            placeholder="Contact&hellip;"
            (selectItem)="selectInstitutionContact($event)"></climb-indirect-typeahead>
    `,
    styles: [`
        .table {
            margin-bottom: 0.25em;
        }
    `]
})
export class InstitutionContactMultiselectComponent {
    @Input() model: any[];
    @Input() disabled: boolean;
    @Input() institutionKey: number;
    @Input() jobInstitutionKey: number;
    @Output() modelChange: EventEmitter<any> = new EventEmitter<any>();
    @Output() updatedContact: EventEmitter<any> = new EventEmitter<any>();
    @Output() isRemove: EventEmitter<any> = new EventEmitter<any>();

    constructor(
        private institutionService: InstitutionService,
    ) { }

    removeInstitutionContact(index: number) {
        let contact = null;
        if (this.model && index >= 0 && index < this.model.length) {
            contact = this.model[index];
            this.model.splice(index, 1);
        }
        this.modelChange.emit(this.model);
        this.isRemove.emit(true);
        this.updatedContact.emit(contact);
    }

    searchInstitutionContacts = (term: string): Promise<any> => {

        return this.institutionService.getContacts('C_Institution_key', this.institutionKey)
            .then((results) => {
                // Convert Breeze entity to simple object
                // so it can be serialized by consumers
                const simpleResults = results.filter((contactPerson: any) => {
                    return this.jobInstitutionKey > -1 || contactPerson.IsActive;
                }).map((contactPerson: any) => {
                    return {
                        FirstName: contactPerson.FirstName,
                        LastName: contactPerson.LastName,
                        C_Institution_key: contactPerson.C_Institution_key,
                        C_ContactPerson_key: contactPerson.C_ContactPerson_key
                    };
                });

                return simpleResults.filter((item: any) => {
                    const fullName = item.FirstName.toLowerCase() + ' ' + item.LastName.toLowerCase();
                    return fullName.indexOf(term.toLowerCase()) > -1;
                });
            });
    }

    institutionContactNameFormatter = (value: any) => {
        if (value) {
            return value.FirstName + ' ' + value.LastName;
        }
    }

    selectInstitutionContact(contact: any) {
        if (this.model && this.model.length > 0) {
            this.model.push(contact);
        } else {
            this.model = [contact];
        }
        this.modelChange.emit(this.model);
        this.isRemove.emit(false);
        this.updatedContact.emit(contact);
    }
}

import { AdminManagerService } from './../services/admin-manager.service';
const MOBILE_PHONE_NUMBER_TYPE_KEY = 1;
const CURRENT_SELECTOR = "edit-profile";
const SHOW_TOAST = true;

import { Component, OnInit } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { LoggingService } from '../services/logging.service';
import { RoleService } from '../services/role.service';
import { UserService } from './user.service';
import { FeatureFlagService } from '../services/feature-flags.service';

@Component({
    selector: CURRENT_SELECTOR,
    templateUrl: './edit-profile.component.html',
    styles: [`
        input[type="checkbox"] {
            margin-top: 1px;
        }
        .form-text {
            margin-top: 2px;
        }
    `],
})
export class EditProfileComponent implements OnInit {
    user: any;
    userRole: string;
    studyAdministrator = false;

    isGLP = false;

    constructor(
        private activeModal: NgbActiveModal,
        private loggingService: LoggingService,
        private roleService: RoleService,
        private userService: UserService,
        private adminManager: AdminManagerService,
        private featureFlagService: FeatureFlagService
    ) {
        // nothing to do
    }

    ngOnInit() {
        this.initIsGLP();
        this.setupData();
    }

    initIsGLP() {
        const flag = this.featureFlagService.getFlag("IsGLP");
        this.isGLP = (flag && flag.IsActive && flag.Value.toLowerCase() === "true");
    }

    setupData(): Promise<any> {
        return this.userService.getThisUserProfile()
            .then((user: any) => {
                if (user) {
                    this.user = user;
                    this.ensureAtLeastOneEmailAddress();
                    this.ensureAtLeastOnePhoneNumber();
                }
            }).then(() => {
                return this.roleService.getUserRole().then((value) => {
                    this.userRole = value;
                });
            }).then(() => {
                return this.roleService.isStudyAdministrator().then((value: boolean) => {
                    if (value) {
                        this.studyAdministrator = value;
                    }
                });
            }).catch((error: any) => {
                this.loggingService.logError(
                    "Could not retrieve your profile: " + error.message,
                    null,
                    CURRENT_SELECTOR,
                    SHOW_TOAST
                );
            });
    }

    ensureAtLeastOneEmailAddress(): void {
        const entityCount = this.user.UserContactEmailAddresses.length;
        if (entityCount === 0) {
            // Create empty record with appropriate defaults
            const initialValues = {
                C_User_key: this.user.Id,
                IsPrimaryEmailAddress: true,
                EmailAddress: ''
            };
            const newEntity =
                this.userService.createUserContactEmailAddress(initialValues);
            this.user.UserContactEmailAddresses.push(newEntity);
        }
    }

    ensureAtLeastOnePhoneNumber(): void {
        const entityCount = this.user.UserContactPhoneNumbers.length;
        if (entityCount === 0) {
            // Create empty record with appropriate defaults
            const initialValues = {
                C_User_key: this.user.Id,
                C_PhoneNumberType_key: MOBILE_PHONE_NUMBER_TYPE_KEY,
                IsPrimaryPhoneNumber: true,
                PhoneNumber: '',
                SupportsSMS: false
            };
            const newEntity =
                this.userService.createUserContactPhoneNumber(initialValues);
            this.user.UserContactPhoneNumbers.push(newEntity);
        }
    }

    onSubmit(form: any): void {
        // regex pattern from: https://www.regular-expressions.info/email.html
        const pattern = '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9]+(?:[a-zA-Z0-9-][a-zA-Z0-9])*\.[a-zA-Z]{2,}$';
        if (form.EmailAddress.match(pattern) != null) {
            this.saveChanges()
                .then(() => {
                    this.loggingService.logSuccess(
                        "Profile saved",
                        null,
                        CURRENT_SELECTOR,
                        SHOW_TOAST
                    );
                })
                .catch((error: any) => {
                    this.loggingService.logError(
                        "Profile save failed: " + error.message,
                        null,
                        CURRENT_SELECTOR,
                        SHOW_TOAST
                    );
                });
            this.activeModal.close('Saved');
        } else {
            this.loggingService.logError(
                "Profile save failed: invalid email address",
                null,
                CURRENT_SELECTOR,
                SHOW_TOAST
            );
        }
    }

    cancelClicked(): void {
        this.revertChanges();
        this.activeModal.close('Canceled');
    }

    saveChanges(): Promise<any> {
        // Handle empty child records       
        this.user.UserContactEmailAddresses.forEach((entity: any) => {
            const allowDelete = false;
            this.handleEmptyChildValue(entity, "EmailAddress", allowDelete);
        });
        this.user.UserContactPhoneNumbers.forEach((entity: any) => {
            const allowDelete = true;
            this.handleEmptyChildValue(entity, "PhoneNumber", allowDelete);
        });

        const emailPromise = this.adminManager.saveEntity('UserContactEmailAddress');
        const phonePromise = this.adminManager.saveEntity('UserContactPhoneNumber');
        const userPromise = this.adminManager.saveEntity('User');

        return Promise.all([phonePromise, emailPromise, userPromise]);
    }

    handleEmptyChildValue(entity: any, propertyName: string, allowDelete: boolean): void {
        if (!entity[propertyName]) {
            const entityState = entity.entityAspect.entityState;

            if (entityState.isAdded()) {
                // Newly added record: revert
                entity.entityAspect.rejectChanges();
            } else if (entityState.isModified() && allowDelete) {
                // Pre-existing record: delete
                entity.entityAspect.setDeleted();
            }
        }
    }

    revertChanges(): void {
        this.user.UserContactEmailAddresses.forEach((entity: any) => {
            entity.entityAspect.rejectChanges();
        });
        this.user.UserContactPhoneNumbers.forEach((entity: any) => {
            entity.entityAspect.rejectChanges();
        });
        this.user.entityAspect.rejectChanges();
    }
}

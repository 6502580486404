import {
    Component,
    Input,
    OnChanges,
    OnInit,
    ViewChildren,
} from '@angular/core';
import { map } from 'rxjs/operators';

import { AnimalService } from '../../../animals/services/animal.service';
import { CopyBufferService } from '../../../common/services/copy-buffer.service';
import { MaterialService } from '../../../services/material.service';
import { MaterialPoolService } from '../../../services/material-pool.service';
import { HousingVocabService } from '../../services/housing-vocab.service';
import { SaveChangesService } from '../../../services/save-changes.service';

import { DroppableEvent } from '../../../common/droppable-event';
import { getSafeProp, lowerCaseCompare, uniqueArray } from '../../../common/util';
import { ConfirmService } from '../../../common/confirm';
import { LoggingService } from '../../../services/logging.service';

import { datesEqual } from '../../../common/util';
import { VocabularyService } from '../../../vocabularies/vocabulary.service';
import { NgModel } from '@angular/forms';
import { dateControlValidator } from '@common/util/date-control.validator';

@Component({
    selector: 'housing-social-table',
    templateUrl: './housing-social-table.component.html'
})
export class HousingSocialTableComponent implements OnChanges, OnInit {
    @ViewChildren('dateControl') dateControls: NgModel[];
    @Input() materialPool: any;
    @Input() socialGroupMaterials: any[];
    @Input() compatibilityMaterials: any[];
    @Input() facetPrivilege: string;

    // CVs
    socialGroupAccessLevels: any[] = [];
    compatibilityAccessLevels: any[] = [];

    readonly COMPONENT_LOG_TAG = 'housing-social-table';
    
    constructor(
        private animalService: AnimalService,
        private copyBufferService: CopyBufferService,
        private materialService: MaterialService,
        private materialPoolService: MaterialPoolService,
        private confirmService: ConfirmService,
        private housingVocabService: HousingVocabService,
        private loggingService: LoggingService,
        private saveChangesService: SaveChangesService,
        private vocabularyService: VocabularyService
    ) {
        // do nothing
    }

    ngOnInit() {
        this.initialize();
    }

    ngOnChanges(changes: any) {
        if (this.materialPool && !changes.materialPool.firstChange) {
            this.initialize();
        }
    }

    initialize() {
        return this.getCVs();
    }

    getCVs(): Promise<any> {
        const p1: Promise<any> = this.housingVocabService.socialGroupAccessLevels$.pipe(map((data) => {
            this.socialGroupAccessLevels = data;
        })).toPromise();
        const p2: Promise<any> = this.housingVocabService.compatibilityAccessLevels$.pipe(map((data) => {
            this.compatibilityAccessLevels = data;
        })).toPromise();

        return Promise.all([p1, p2]);
    }

    selectAnimal(animal: any): Promise<any> {
        return this.materialService.getMaterial(animal.C_Material_key).then(() => {
            this.addMaterial(animal);
            return animal;
        });
    }

    private addMaterial(material: any) {
        if (this.socialGroupMaterials) {
            this.getDefaultSocial().then((result) => {
                const socialGroupKey = result != null ? result.C_SocialGroupAccessLevel_key : null;
                const initialValues = {
                    C_MaterialPool_key: this.materialPool.C_MaterialPool_key,
                    C_Material_key: material.C_Material_key,
                    DateIn: new Date(),
                    C_SocialGroupAccessLevel_key: socialGroupKey
                };
                this.materialPoolService.createSocialGroupMaterial(initialValues);
            });
        } else if (this.compatibilityMaterials) {
            this.getDefaultCompatibility().then((result) => {
                const compatibilityKey = result != null ? result.C_CompatibilityAccessLevel_key : null;
                const initialValues = {
                    C_MaterialPool_key: this.materialPool.C_MaterialPool_key,
                    C_Material_key: material.C_Material_key,
                    DateDocumented: new Date(),
                    C_CompatibilityAccessLevel_key: compatibilityKey
                };
                this.materialPoolService.createCompatibilityMaterial(initialValues);
            });
        }
    }

    private getDefaultCompatibility(): Promise<any> {
        const preferLocal = true;
        return this.vocabularyService.getCVDefault('cv_CompatibilityAccessLevels', preferLocal);
    }

    private getDefaultSocial(): Promise<any> {
        const preferLocal = true;
        return this.vocabularyService.getCVDefault('cv_SocialGroupAccessLevels', preferLocal);
    }

    removeMaterial(material: any) {
        let message;
        if (this.socialGroupMaterials) {
            message = "Do you wish to remove the animal social status link ?";
        } else if (this.compatibilityMaterials) {
            message = "Do you wish to remove the animal compatibility link ?";
        }
        this.confirmService.confirm(
            {
                title: "Remove Link",
                message,
                yesButtonText: 'Yes',
                noButtonText: 'No',
            }
        ).then(
            () => {
                // Yes                           
                if (this.socialGroupMaterials) {
                    this.materialPoolService.deleteSocialGroupMaterial(material);
                } else if (this.compatibilityMaterials) {
                    this.materialPoolService.deleteCompatibilityMaterial(material);
                }
            },
            () => {
                // No
            });
    }


    // Search functions for indirect typeahead
    getFilteredFemales = (query: string): Promise<any[]> => {
        return this.animalService.getFilteredCurrentAnimalsBySex(query, 'Female', 500)
            .then((data) => {
                return data;
            });
    }

    getFilteredMales = (query: string): Promise<any[]> => {
        return this.animalService.getFilteredCurrentAnimalsBySex(query, 'Male', 500)
            .then((data) => {
                return data;
            });
    }

    getFilteredOtherSex = (query: string): Promise<any[]> => {
        return this.animalService.getFilteredCurrentAnimalsBySex(query, null, 500)
            .then((data) => {
                return data;
            });
    }


    // Drop, Paste
    onDropMaterials(event: DroppableEvent) {
        const newAnimals = this.animalService.draggedAnimals;

        this.processAddedAnimals(newAnimals);

        this.animalService.draggedAnimals = [];
    }

    pasteAnimals() {
        if (this.copyBufferService.hasAnimals()) {
            const newAnimals = this.copyBufferService.paste();

            this.processAddedAnimals(newAnimals);
        }
    }

    private processAddedAnimals(animals: any[]) {
        if (this.facetPrivilege === 'ReadWrite') {
            const animalNames: any[] = [];
            for (const animal of animals) {
                if (animal.cv_AnimalStatus.IsExitStatus) {
                    animalNames.push(animal.AnimalName);
                }
            }
            const names = uniqueArray(animalNames).join(', ');
            if (names) {
                let message = `${animalNames.length} of these animals have an end-state status. You must change the animal's statuses to a non-end-state option.`;
                if (message.length === 1) {
                    message = message.replace("have", "has");
                    message = message.replace("statuses", "status");
                }
                this.confirmService.confirm(
                    {
                        title: "Animal's status",
                        message,
                        yesButtonText: 'Skip Animals',
                        noButtonText: 'Cancel',
                        titleDetails: 'Animals: ',
                        details: [
                            names
                        ]
                    }
                ).then(
                    () => {
                        // Yes                           
                        this.addAnimals(animals);
                    },
                    () => {
                        // No
                    }); 
            } else {
                this.addAnimals(animals);
            }
        }
    }

    addAnimals(animals: any[]) {
        for (const animal of animals) {
            if (!animal.cv_AnimalStatus.IsExitStatus) {
                this.addMaterial(animal);
            }            
        }
    }

    /**
     * Alter exact match behavior to include matching on MicrochipIdentifer
     */
    isAnimalExactMatch = (data: any[], term: string) => {
        if (term &&
            data &&
            data.length === 1
        ) {
            return lowerCaseCompare(data[0].AnimalName, term) ||
                lowerCaseCompare(getSafeProp(data[0], 'Material.MicrochipIdentifier'), term);
        }
        return false;
    }

    // <select> formatters
    animalNameFormatter = (value: any) => {
        return value.AnimalName;
    }
    socialGroupAccessLevelKeyFormatter = (value: any) => {
        return value.C_SocialGroupAccessLevel_key;
    }
    socialGroupAccessLevelFormatter = (value: any) => {
        return value.SocialGroupAccessLevel;
    }
    compatibilityAccessLevelKeyFormatter = (value: any) => {
        return value.C_CompatibilityAccessLevel_key;
    }
    compatibilityAccessLevelFormatter = (value: any) => {
        return value.CompatibilityAccessLevel;
    }

    dateChanged(material: any, dateOutInput: any) {
        if (material.DateIn && material.DateOut && !datesEqual(material.DateIn, material.DateOut)) {
            if (material.DateIn > material.DateOut) {
                material.DateOut = null;
                dateOutInput.clear();
                this.loggingService.logError("Date in cannot be after Date out.", null, this.COMPONENT_LOG_TAG, true);
            }
        }
    }

    validate() {
        return dateControlValidator(this.dateControls);
    }
}

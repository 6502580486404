import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class DropzoneCommService {
    
    private removedFileSource = new Subject<any>();
    private removedAllFilesSource = new Subject<any>();

    removedFile$ = this.removedFileSource.asObservable();
    removedAllFiles$ = this.removedAllFilesSource.asObservable();

    removeFile(file: any) {
        this.removedFileSource.next(file);
    }

    removeAllFiles() {
        this.removedAllFilesSource.next();
    }
    
}

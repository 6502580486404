import { AnimalService } from './services/animal.service';
import { EnumerationService } from '../enumerations/enumeration.service';

/**
 * Shared logic for animal facet components
 * TODO: Refactor more code into this shared service
 * TODO: actually make this a service
 */
export class AnimalLogic {

    animalService: AnimalService;
    enumerationService: EnumerationService;

    constructor(
        animalService: AnimalService,
        enumerationService: EnumerationService,
    ) {
        this.animalService = animalService;
        this.enumerationService = enumerationService;
    }

    taxonChanged(animal: any): Promise<any> {
        if (animal.TaxonCharacteristicInstance) {
            // remove existing characteristics from entity cache
            while (animal.TaxonCharacteristicInstance.length > 0) {
                const characteristicInstance = animal.TaxonCharacteristicInstance[0];
                characteristicInstance.entityAspect.setDeleted();
            }
        }

        return this.animalService.createTaxonCharacteristics(animal);
    }

    deleteTaxons(animal: any) {
        if (animal.TaxonCharacteristicInstance) {
            // remove existing characteristics from entity cache
            while (animal.TaxonCharacteristicInstance.length > 0) {
                const characteristicInstance = animal.TaxonCharacteristicInstance[0];
                characteristicInstance.entityAspect.setDeleted();
            }
        }
    }
}

<div class="wizard-body">

    <h4 class="wizard-heading">
        Welcome!
    </h4>

    <p>
        <img src="/images/icon-green-3.png"
             alt="Climb logo"
             height="80" width="80"
             class="float-right inline-image-right" />
        Let's set up your {{workgroupName}} workgroup in Climb&trade;.
    </p>

    <p>
        The settings and values you define here will determine how Climb works for you and allow you to easily sort and filter your data.
    </p>

    <p>
        You will need a few minutes to complete this wizard.
    </p>

</div>

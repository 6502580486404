import { Input } from '@angular/core';
import {
    CellFormatterService,
    TableOptions
} from '../common/datatable';
import {
    daysSinceAsString
} from '../common/util';
import { FeatureFlagService } from '../services/feature-flags.service';
import { TranslationService } from '../services/translation.service';
import { addAuditColumnsToOptions } from '@common/datatable/utils';

export class JobTableOptions {

    options: TableOptions;
    croOptions: TableOptions;
    isGLP: boolean;

    constructor(
        private cellFormatterService: CellFormatterService,
        private translationService: TranslationService,        
        private featureFlagService: FeatureFlagService,
        private jobDetailsOrder: any = {}
    ) {
        this.initIsGLP();
        this.options = addAuditColumnsToOptions(cellFormatterService)(this.getDefaultOptions());
        this.croOptions = addAuditColumnsToOptions(cellFormatterService)(this.getCroOptions(jobDetailsOrder));
    }

    initIsGLP() {
        this.isGLP = this.featureFlagService.isFlagOn('IsGLP');
    }

    getCroOptions(jobDetailsOrder: any): TableOptions {
        return {
            csvFileName: this.translationService.translate('jobs').toLowerCase() + '.csv',
            enableDetailColumn: true,
            enableDraggable: false,
            enableSelectable: true,
            columns: [
                {
                    displayName: this.translationService.translate('Job') + ' Name',
                    field: 'JobID',
                    sortField: 'JobIDSortable',
                    position: jobDetailsOrder.jobID
                },
                {
                    displayName: 'Code',
                    field: 'JobCode',
                    visible: false,
                    position: jobDetailsOrder.jobCode
                },
                {
                    displayName: 'Title',
                    field: 'Goal',
                    visible: false,
                    position: jobDetailsOrder.title
                },
                {
                    displayName: 'Research Director',
                    field: 'ResearchDirector',
                    visible: false,
                    position: jobDetailsOrder.researchDirector
                },
                {
                    displayName: !this.isGLP ? 'Client Manager' : 'Alternate Contact',
                    field: 'ClientManager',
                    visible: false,
                    position: jobDetailsOrder.clientManager
                },
                {
                    displayName: 'Study Director',
                    field: 'StudyDirector',
                    visible: false,
                    position: jobDetailsOrder.studyDirector
                },
                {
                    displayName: 'Type',
                    field: 'cv_JobType.JobType',
                    position: jobDetailsOrder.jobType
                },
                {
                    displayName: 'Subtype',
                    field: 'cv_JobSubtype.JobSubtype',
                    position: jobDetailsOrder.jobSubtype
                },
                {
                    displayName: 'IACUC Protocol', field: 'cv_IACUCProtocol.IACUCProtocol',
                    visible: false,
                    position: jobDetailsOrder.iacuc
                },
                {
                    displayName: 'Compliance', field: 'Compliance.Compliance',
                    visible: false,
                    position: jobDetailsOrder.compliance
                },
                {
                    displayName: this.translationService.translate('Line'),
                    field: 'LinesSortable',
                    visible: false,
                    position: jobDetailsOrder.linesTable
                },
                {
                    displayName: 'Test Article', field: 'JobTestArticle.TestArticle',
                    visible: false,
                    formatter: (row: any, value: any) => {
                        const values = row.JobTestArticle.map((testArticle: any) => {
                            return testArticle.cv_TestArticle.TestArticle;
                        });

                        values.sort();
                        return values.join(', ');
                    },
                    sortable: false,
                    position: jobDetailsOrder.testArticles
                },
                {
                    displayName: 'Batch',
                    field: 'JobTestArticle.Batch',
                    visible: false,
                    formatter: (row: any, value: any) => {
                        const values = row.JobTestArticle.map((testArticle: any) => {
                            return testArticle.Batch;
                        });

                        values.sort();
                        return values.filter((x: any) => x !== ' ').join(', ');
                    },
                    sortable: false,
                    position: jobDetailsOrder.testArticles
                },
                {
                    displayName: 'Status',
                    field: 'cv_JobStatus.JobStatus',
                    position: jobDetailsOrder.status
                },
                {
                    displayName: 'Projected Implant Date',
                    field: 'ProjectImplantDate',
                    formatter: this.cellFormatterService.dateFormatter,
                    visible: false,
                    position: jobDetailsOrder.projectImplantDate
                },
                {
                    displayName: 'Implanted Date',
                    field: 'ImplantedDate',
                    formatter: this.cellFormatterService.dateFormatter,
                    visible: false,
                    position: jobDetailsOrder.implantedDate
                },
                {
                    displayName: 'Projected Start Date',
                    field: 'ProjectedStartDate',
                    formatter: this.cellFormatterService.dateFormatter,
                    visible: false,
                    position: jobDetailsOrder.projectedStartDate
                },
                {
                    displayName: 'Start Date',
                    field: 'DateStarted',
                    formatter: this.cellFormatterService.dateFormatter,
                    visible: false,
                    position: jobDetailsOrder.startDate
                },
                {
                    displayName: 'End Date',
                    field: 'DateEnded',
                    formatter: this.cellFormatterService.dateFormatter,
                    visible: false,
                    position: jobDetailsOrder.endDate
                },
                {
                    displayName: 'Locations',
                    field: 'CurrentLocationPath',
                    visible: false,
                    position: jobDetailsOrder.location
                },
                {
                    displayName: 'Order ID(s)',
                    field: 'Order',
                    visible: false,
                    formatter: (row: any) => {
                        const values = row.JobOrder.map((jobOrder: any) => {
                            return jobOrder.Order.OrderID;
                        });

                        values.sort();
                        return values.join(', ');
                    },
                    sortable: false,
                    position: jobDetailsOrder.orderID
                },
                {
                    displayName: this.translationService.translate('Study'),
                    field: 'Study.StudyName',
                    position: jobDetailsOrder.study
                },
                {
                    displayName: this.translationService.translate("Study") + " Approval Number",
                    field: 'Study.ApprovalNumber',
                    visible: false,
                    position: jobDetailsOrder.approvalNumber
                },
                {
                    displayName: this.translationService.translate('Institution'),
                    field: 'InstitutionNamesSortable',
                    visible: false,
                    position: jobDetailsOrder.institutions
                },
                {
                    displayName: 'Site',
                    field: 'JobInstitution.Site',
                    visible: false,
                    formatter: (row: any, value: any) => {
                        const values = row.JobInstitution.map((jobInstitution: any) => {
                            return jobInstitution.Site.Name;
                        });

                        values.sort();
                        return values.join(', ');
                    },
                    sortable: false,
                    position: jobDetailsOrder.institutions
                },
                {
                    displayName: 'Animals',
                    field: 'AnimalCount',
                    headerClass: 'text-nowrap',
                    cellClass: 'num-cell',
                    sortable: false,
                    visible: false
                },
                {
                    displayName: 'Samples',
                    field: 'SampleCount',
                    headerClass: 'text-nowrap',
                    cellClass: 'num-cell',
                    sortable: false,
                    visible: false
                },
                {
                    displayName: 'Days Since Start',
                    // fields must be unique. It's ok if it doesn't actually exist
                    field: 'DateStarted2',
                    // we need to specify explicit sort, because of altered field
                    sortField: 'DateStarted',
                    formatter: this.daysSinceStartDateFormatter,
                    headerClass: 'text-nowrap',
                    cellClass: 'num-cell',
                    visible: false
                },
                {
                    displayName: 'Locked', field: 'IsLocked',
                    formatter: this.cellFormatterService.lockedFormatter,
                    exportFormatter: this.cellFormatterService.booleanExportFormatter,
                    maxWidth: 80,
                    visible: false
                },
                {
                    displayName: 'Notes',
                    field: 'Note',
                    visible: false,
                    formatter: this.cellFormatterService.noteFormatter,
                    sortable: false
                },
            ]
        };
    }

    getDefaultOptions(): TableOptions {
        return {
            csvFileName: this.translationService.translate('jobs').toLowerCase() + '.csv',
            enableDetailColumn: true,
            enableDraggable: false,
            enableSelectable: true,
            columns: [
                {
                    displayName: this.translationService.translate('Job') + ' Name',
                    field: 'JobID',
                    sortField: 'JobIDSortable'
                },
                {
                    displayName: 'Code',
                    field: 'JobCode',
                    visible: false
                },
                {
                    displayName: 'Title',
                    field: 'Goal',
                    visible: false
                },
                {
                    displayName: 'Lead Scientist',
                    field: 'LeadScientist',
                    visible: false
                },
                {
                    displayName: 'Study Monitor',
                    field: 'StudyMonitor',
                    visible: false
                },
                {
                    displayName: 'Type',
                    field: 'cv_JobType.JobType'
                },
                {
                    displayName: 'IACUC Protocol', field: 'cv_IACUCProtocol.IACUCProtocol',
                    visible: false
                },
                {
                    displayName: 'Compliance', field: 'Compliance.Compliance',
                    visible: false
                },
                {
                    displayName: 'Order ID(s)',
                    field: 'Order',
                    visible: false,
                    formatter: (row: any) => {
                        const values = row.Order.map((order: any) => {
                            return order.OrderID;
                        });

                        values.sort();
                        return values.join(', ');
                    },
                    sortable: false
                },
                {
                    displayName: this.translationService.translate('Line'),
                    field: 'Line.LineName',
                    visible: false
                },
                {
                    displayName: 'Species',
                    field: 'Line.cv_Taxon.CommonName',
                    visible: false
                },
                {
                    displayName: 'Test Article', field: 'JobTestArticle.TestArticle',
                    visible: false,
                    formatter: (row: any, value: any) => {
                        const values = row.JobTestArticle.map((testArticle: any) => {
                            return testArticle.cv_TestArticle.TestArticle;
                        });
                        
                        values.sort();
                        return values.join(', ');
                    },
                    sortable: false
                },
                {
                    displayName: 'Batch',
                    field: 'JobTestArticle.Batch',
                    visible: false,
                    formatter: (row: any, value: any) => {
                        const values = row.JobTestArticle.map((testArticle: any) => {
                            return testArticle.Batch;
                        });

                        values.sort();
                        return values.filter((x: any) => x !== ' ').join(', ');
                    },
                    sortable: false
                },
                {
                    displayName: 'Status',
                    field: 'cv_JobStatus.JobStatus'
                },
                {
                    displayName: 'Animals',
                    field: 'AnimalCount',
                    headerClass: 'text-nowrap',
                    cellClass: 'num-cell',
                    sortable: false,
                    visible: false
                },
                {
                    displayName: 'Samples',
                    field: 'SampleCount',
                    headerClass: 'text-nowrap',
                    cellClass: 'num-cell',
                    sortable: false,
                    visible: false
                },
                {
                    displayName: 'Projected Start Date',
                    field: 'ProjectedStartDate',
                    formatter: this.cellFormatterService.dateFormatter,
                    visible: false
                },
                {
                    displayName: 'Start Date',
                    field: 'DateStarted',
                    formatter: this.cellFormatterService.dateFormatter,
                    visible: false
                },
                {
                    displayName: 'End Date',
                    field: 'DateEnded',
                    formatter: this.cellFormatterService.dateFormatter,
                    visible: false
                },
                {
                    displayName: 'Locations',
                    field: 'CurrentLocationPath',
                    visible: false
                },
                {
                    displayName: 'Days Since Start',
                    // fields must be unique. It's ok if it doesn't actually exist
                    field: 'DateStarted2',
                    // we need to specify explicit sort, because of altered field
                    sortField: 'DateStarted',
                    formatter: this.daysSinceStartDateFormatter,
                    headerClass: 'text-nowrap',
                    cellClass: 'num-cell',
                    visible: false
                },
                {
                    displayName: this.translationService.translate('Study'),
                    field: 'Study.StudyName'
                },
                {
                    displayName: 'Locked', field: 'IsLocked',
                    formatter: this.cellFormatterService.lockedFormatter,
                    exportFormatter: this.cellFormatterService.booleanExportFormatter,
                    maxWidth: 80,
                    visible: false
                },
                {
                    displayName: this.translationService.translate('Institution'),
                    field: 'InstitutionNamesSortable',
                    visible: false
                },
                {
                    displayName: 'Site',
                    field: 'JobInstitution.Site',
                    visible: false,
                    formatter: (row: any, value: any) => {
                        const values = row.JobInstitution.map((jobInstitution: any) => {
                            return jobInstitution.Site.Name;
                        });

                        values.sort();
                        return values.join(', ');
                    },
                    sortable: false
                },
                {
                    displayName: 'Notes',
                    field: 'Note',
                    visible: false,
                    formatter: this.cellFormatterService.noteFormatter,
                    sortable: false
                },
            ]
        };
    }

    daysSinceStartDateFormatter = (row: any, value: any) => {
        let text = '';
        if (row.DateStarted) {
            const daysFromToday = Number(daysSinceAsString(new Date(), row.DateStarted));
            if (daysFromToday > 0) {
                text = '' + daysFromToday;
            }
        }
        return text;
    }
}

import { Injectable } from '@angular/core';
import { GlpBaseFacetStateService } from '../../services/glp-base-facet-state.service';
import {
    Device,
    Entity as InternalEntity,    
    LocationPosition
}
    from '../../common/types';
import { DataManagerService } from '../../services/data-manager.service';
import { Entity as BreezeEntity } from 'breeze-client';

@Injectable()
export class LocationPositionStateService extends GlpBaseFacetStateService<LocationPosition> {

    constructor(protected dataManagerService: DataManagerService) {
        super(dataManagerService);
    }

    getRelatedCollectionChanges(locationPosition: InternalEntity<LocationPosition>): BreezeEntity[] {
        const changes: any = [];
        changes.push(...this.getChangesToEntityByKey('Device', 'C_LocationPosition_key', locationPosition.C_LocationPosition_key));
        return changes;
    }

    getChangesToEntityByKey(
        entityName: string,
        key: string,
        value: any
    ) {
        return this.dataManagerService.getChangesToEntityByFilter(entityName, (item: BreezeEntity) => {
            // Device has different behavior if user delete it
            if (item.entityAspect.entityState.isModified()) {
                return item[key] === value || item.entityAspect.originalValues[key] === value;
            }
            return item[key] === value;
        });
    }
    
    discardChanges(locationPosition: InternalEntity<LocationPosition>): void {
        // TODO: implement facet-level discard operation after release of GLP
        throw new Error('Method not implemented.');
    }

    getDeleteNavPropertyChanges(entity: InternalEntity<LocationPosition>): BreezeEntity[] {
        const changes: any = [];         
        return changes;
    } 
}

<div class="form-control-plaintext"
     *ngIf="facetPrivilege === 'ReadWrite'">
    <a (click)="pasteAnimals()">
        <i data-automation-id="paste-animals-button"
           class="fa fa-paste" 
           title="Paste Animals">
        </i>
    </a>
</div>

<div droppable
     (drop)="onDropMaterials($event)"
     [dropDisabled]="facetPrivilege !== 'ReadWrite'"
     [attr.title]="facetPrivilege === 'ReadWrite' ? 'Drop animals here' : ''">
    <table class="table table-borderless">
        <thead>
            <tr>
                <th>
                    <i class="fa fa-mars" aria-hidden="true" title="Males"></i>
                    Males
                </th>
                <th>
                    <i class="fa fa-venus" aria-hidden="true" title="Females"></i>
                    Females
                </th>
                <th>
                    Others
                </th>
            </tr>
        </thead>

        <tbody *ngIf="facetPrivilege === 'ReadWrite'">
            <tr>
                <td>
                    <climb-indirect-typeahead [search]="getFilteredMales"
                                              [resultFormatter]="animalNameFormatter"
                                              [exactMatchFunction]="isAnimalExactMatch"
                                              [placeholder]="'Add male&hellip;'"
                                              (selectItem)="selectAnimal($event)"></climb-indirect-typeahead>
                </td>
                <td>
                    <climb-indirect-typeahead [search]="getFilteredFemales"
                                              [resultFormatter]="animalNameFormatter"
                                              [exactMatchFunction]="isAnimalExactMatch"
                                              [placeholder]="'Add female&hellip;'"
                                              (selectItem)="selectAnimal($event)"></climb-indirect-typeahead>
                </td>
                <td>
                    <climb-indirect-typeahead [search]="getFilteredOtherSex"
                                              [resultFormatter]="animalNameFormatter"
                                              [exactMatchFunction]="isAnimalExactMatch"
                                              [placeholder]="'Add animal&hellip;'"
                                              (selectItem)="selectAnimal($event)"></climb-indirect-typeahead>
                </td>
            </tr>
        </tbody>
    </table>

    <!--Social Group Section-->
    <div *ngIf="socialGroupMaterials && socialGroupMaterials.length !== 0">
        <label class="form-control-plaintext">Current Social Group</label>
        <table class="table table-borderless">
            <thead>
                <tr>
                    <th>Animal Name</th>
                    <th>Sex</th>
                    <th>Housing ID</th>
                    <th>Access Level</th>
                    <th>Date In</th>
                    <th>Date Out</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let socialGroupMaterial of socialGroupMaterials">
                    <td>

                        <a (click)="removeMaterial(socialGroupMaterial)"
                           *ngIf="facetPrivilege === 'ReadWrite'">
                            <i class="fa fa-remove remove-item"
                               title="Remove animal social status link"></i>
                        </a>
                        {{socialGroupMaterial.Material?.Animal?.AnimalName}}

                    </td>
                    <td>
                        {{socialGroupMaterial.Material?.Animal?.cv_Sex?.Sex}}
                    </td>
                    <td>
                        {{socialGroupMaterial.Material?.MaterialPoolMaterial | currentMaterialHousingID}}
                    </td>
                    <td>
                        <active-vocab-select [(model)]="socialGroupMaterial.C_SocialGroupAccessLevel_key"
                                             [vocabChoices]="socialGroupAccessLevels"
                                             [keyFormatter]="socialGroupAccessLevelKeyFormatter"
                                             [optionFormatter]="socialGroupAccessLevelFormatter"></active-vocab-select>
                    </td>
                    <td>
                        <climb-ngb-date #dateControl="ngModel"
                                        [(ngModel)]="socialGroupMaterial.DateIn"
                                        [ngModelOptions]="{ standalone: true }">
                        </climb-ngb-date>
                    </td>
                    <td>
                        <climb-ngb-date #dateControl="ngModel"
                                        [(ngModel)]="socialGroupMaterial.DateOut"
                                        [ngModelOptions]="{ standalone: true }"
                                        (change)="dateChanged(socialGroupMaterial, dateOut)"
                                        [required]="false"
                                        #dateOut>
                        </climb-ngb-date>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <!--Compatibility section-->
    <div *ngIf="compatibilityMaterials && compatibilityMaterials.length !== 0">
        <label class="form-control-plaintext">Animal Compatibility</label>
        <table class="table table-borderless">
            <thead>
                <tr>
                    <th>Animal Name</th>
                    <th>Sex</th>
                    <th>Access Level</th>
                    <th>Date Documented</th>
                    <th>Comments</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let compatibilityMaterial of compatibilityMaterials">
                    <td>

                        <a (click)="removeMaterial(compatibilityMaterial)"
                           *ngIf="facetPrivilege === 'ReadWrite'">
                            <i class="fa fa-remove remove-item"
                               title="Remove animal compatibility link"></i>
                        </a>
                        {{compatibilityMaterial.Material?.Animal?.AnimalName}}

                    </td>
                    <td>
                        {{compatibilityMaterial.Material?.Animal?.cv_Sex?.Sex}}
                    </td>
                    <td>
                        <active-vocab-select [(model)]="compatibilityMaterial.C_CompatibilityAccessLevel_key"
                                             [vocabChoices]="compatibilityAccessLevels"
                                             [keyFormatter]="compatibilityAccessLevelKeyFormatter"
                                             [optionFormatter]="compatibilityAccessLevelFormatter"></active-vocab-select>
                    </td>
                    <td>
                        <climb-ngb-date #dateControl="ngModel"
                                        [(ngModel)]="compatibilityMaterial.DateDocumented"
                                        [ngModelOptions]="{ standalone: true }">
                        </climb-ngb-date>
                    </td>
                    <td>
                        <textarea [(ngModel)]="compatibilityMaterial.Comments"
                                  name="compatibilityMaterialComments-{{compatibilityMaterial.C_CompatibilityMaterial_key}}"
                                  class="form-control input-large"></textarea>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

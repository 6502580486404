import {
    Component,
    Input,
    OnInit,
    OnChanges
} from '@angular/core';

import { FacetLoadingStateService } from '@common/facet/facet-loading-state.service';
import { MaterialPoolService } from '@services/material-pool.service';
import { VocabularyService } from '../../../vocabularies/vocabulary.service';
import { LocationService } from '../../../locations/location.service';


@Component({
    selector: 'workflow-housing-details',
    templateUrl: './workflow-housing-details.component.html',
})
export class WorkflowHousingDetailsComponent implements OnInit, OnChanges {
    @Input() materialPool: any;
    @Input() useTableFormat: boolean;

    constructor(
        private facetLoadingStateService: FacetLoadingStateService,
        private locationService: LocationService,
        private materialPoolService: MaterialPoolService,
        private vocabularyService: VocabularyService
    ) { }

    ngOnInit() {
        this.initialize();
    }

    ngOnChanges(changes: any) {
        if (changes.materialPool && !changes.materialPool.firstChange) {
            this.getHousingDetails();
        }
    }
    
    initialize(): Promise<any> {
        this.facetLoadingStateService.changeLoadingState(true);

        return this.getHousingDetails().then(() => {
            return this.getCVs();
        }).then(() => {
            this.facetLoadingStateService.changeLoadingState(false);
        }).catch((error: Error) => {
            this.facetLoadingStateService.changeLoadingState(false);
            throw error;
        });
    }

    getCVs(): Promise<any> {
        // // Ensure CVs loaded for housing
        return this.vocabularyService.ensureCVLoaded('cv_MaterialPoolTypes');
    }

    getHousingDetails(): Promise<any> {
        if (!this.materialPool) {
            return Promise.resolve();
        }

        return this.materialPoolService.getMaterialPool(
            this.materialPool.C_MaterialPool_key
        ).then(() => {
            return this.locationService.getMaterialPoolLocationPath(
                this.materialPool.C_MaterialPool_key
            );
        }).then((path) => {
            this.materialPool.location = path;
        });
    }
}

import { AnimalNameFormatFormComponent } from './bulkedit/animal-name-format-form.component';
import { ProtocolsSharedModule } from './../protocol/protocols-shared.module';
import { AnimalBulkAddComponent } from './bulkedit/animal-bulk-add.component';
import { AnimalBulkTemplatesComponent } from './bulkedit/animal-bulk-templates.component';
import { TaskTableModule } from './../tasks/task-table.module';
import { FacetSharedModule } from './../common/facet/facet-shared.module';
import {
    ViewAnimalAuditReportComponentService
} from './audit/view-animal-audit-report-component.service';
import { ResourcesSharedModule } from './../resources/resources-shared.module';
import { EnumerationsSharedModule } from './../enumerations/enumerations-shared.module';
import { ClinicalSharedModule } from './../clinical/clinical-shared.module';
import { ExportAnimalAuditService } from './audit/export-animal-audit.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AgGridModule } from "ag-grid-angular";

import { ClimbCommonModule } from '../common/climb-common.module';
import { ClimbServicesModule } from '../services/climb-services.module';

import { AnimalsSharedModule } from './animals-shared.module';
import { AuditSharedModule } from '../audit/audit-shared.module';
import { CharacteristicsSharedModule } from '../characteristics/characteristics-shared.module';
import { CohortSharedModule } from '../cohort/cohort-shared.module';
import { ConstructsSharedModule } from '../constructs/constructs-shared.module';
import { EventsSharedModule } from '../events/events-shared.module';
import { GenotypesSharedModule } from '../genotypes/genotypes-shared.module';
import { HousingSharedModule } from '../housing/housing-shared.module';
import { JobsSharedModule } from '../jobs/jobs-shared.module';
import { LinesSharedModule } from '../lines/lines-shared.module';
import { ReportingSharedModule } from '../reporting/reporting-shared.module';
import { StudiesSharedModule } from '../studies/studies-shared.module';
import { UsersSharedModule } from '../user/users-shared.module';
import { WorkspacesSharedModule } from '../workspaces/workspaces-shared.module';
import { VocabulariesSharedModule } from '../vocabularies/vocabularies-shared.module';
import { AnimalCancelTreatmentsComponent } from './animal-cancel-treatments.component';
import { AnimalAuditReportComponent } from './audit';
import { AnimalFacetComponent } from './animal-facet.component';
import { AnimalFilterComponent } from './animal-filter/animal-filter.component';
import { AnimalDetailComponent } from './animal-detail.component';
import { AnimalBulkEditComponent } from './bulkedit/animal-bulk-edit.component';
import { AnimalMarkerCellRendererComponent } from './animal-marker-cell-renderer.component';
import { PedigreeViewerComponent } from './pedigree-viewer.component';
import { PedigreeNodeComponent } from './pedigree-node.component';
import { AnimalCohortTableComponent } from './tables';
import { SearchSharedModule } from '../search/search-shared.module';
import { TasksSharedModule } from '../tasks/tasks-shared.module';
import { AnimalBulkHousingComponent } from './bulkedit/animal-bulk-housing.component';
import { ConfirmHousingModalComponent } from './bulkedit/confirm-housing-modal.component';
import { WorkflowsModule } from '../workflow/workflows.module';
import { BirthsSharedModule } from '../birth/births-shared.module';
import { OrdersSharedModule } from '../orders/orders-shared.module';
import { AnimalCommentsTableComponent } from './animal-comments-table/animal-comments-table.component';
import { OutputChartRendererComponent } from './output-chart-renderer.component';
import { DxChartModule } from 'devextreme-angular';
import { AnimalStateService } from './services/animal-state.service';
import { AnimalSaveService } from './services/animal-save.service';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgbModule,
        ClimbCommonModule,
        FacetSharedModule,
        ClimbServicesModule,
        AgGridModule.withComponents([]),
        AnimalsSharedModule,
        AuditSharedModule,
        CharacteristicsSharedModule,
        ClinicalSharedModule,
        CohortSharedModule,
        ConstructsSharedModule,
        EnumerationsSharedModule,
        EventsSharedModule,
        GenotypesSharedModule,
        HousingSharedModule,
        JobsSharedModule,
        LinesSharedModule,
        ProtocolsSharedModule,
        ReportingSharedModule,
        ResourcesSharedModule,
        SearchSharedModule,
        StudiesSharedModule,
        TaskTableModule,
        TasksSharedModule,
        UsersSharedModule,
        VocabulariesSharedModule,
        WorkspacesSharedModule,
        WorkflowsModule,
        BirthsSharedModule,
        OrdersSharedModule,
        DxChartModule
    ],
    declarations: [
        AnimalCancelTreatmentsComponent,
        AnimalCohortTableComponent,
        AnimalAuditReportComponent,
        AnimalFacetComponent,
        AnimalFilterComponent,
        AnimalDetailComponent,
        AnimalBulkEditComponent,
        AnimalBulkAddComponent,
        AnimalBulkHousingComponent,
        AnimalBulkTemplatesComponent,
        AnimalMarkerCellRendererComponent,
        AnimalNameFormatFormComponent,
        PedigreeViewerComponent,
        PedigreeNodeComponent,
        ConfirmHousingModalComponent,
        AnimalCommentsTableComponent,
        OutputChartRendererComponent
    ],
    exports: [
        AnimalFacetComponent,
        AnimalFilterComponent,
        AnimalAuditReportComponent,
        AnimalNameFormatFormComponent
    ],
    providers: [
        ExportAnimalAuditService,
        ViewAnimalAuditReportComponentService,
        AnimalStateService,
        AnimalSaveService,
    ]
})
export class AnimalsModule { }

import { DataContextService } from './../services/data-context.service';
import {
    Component,
    OnInit
} from '@angular/core';

import { RoleService } from './../services/role.service';
import { RoutingService } from './../routing/routing.service';
import { WizardService } from './wizard.service';


@Component({
    selector: 'workgroup-initializer',
    templateUrl: './workgroup-initializer.component.html'
})
export class WorkgroupInitializerComponent implements OnInit {

    isAdmin: boolean;


    constructor(
        private roleService: RoleService,
        private routingService: RoutingService,
        private wizardService: WizardService,
        private dataContext: DataContextService
    ) {
        // Nothing to do
    }

    ngOnInit() {
        this.dataContext.init().then(() => {
            return this.initialize();
        });
    }

    initialize() {
        // Navigate to dashboard if workgroup already initialized
        this.wizardService.isInitialized().then((isInitialized) => {
            if (isInitialized) {
                this.routingService.navigateToDashboard();
            }
        });

        this.roleService.isAdministrator().then((isAdmin) => {
            this.isAdmin = isAdmin;
        });
    }
}

/**
 * A function for comparing dates, suitable for using with
 * Array.sort to sort dates in order from past to future.
 * @param date1 a Date object or number representing milliseconds since epoch
 * @param date2 a Date object or number representing milliseconds since epoch
 * @returns the differece (date1 - date2) in milliseconds
 */
export function compareDates(date1: (Date | number), date2: (Date | number)): number {
    if (typeof date1 === 'object') {
        date1 = date1.getTime();
    }

    if (typeof date2 === 'object') {
        date2 = date2.getTime();
    }

    return date1 - date2;
}

import type { Line } from './line.interface';
import type { Note } from './note.interface';
import type { SampleConstruct } from './sample-construct.interface';
import type { StoredFileMap } from './stored-file-map.interface';
import type { TaskConstruct } from './task-construct.interface';
import type { cv_ConstructStatus } from './cv-construct-status.interface';

export interface Construct {
    AlternateName: string;
    AntibioticSelection: string;
    Antibody: string;
    C_ConstructStatus_key: number | null;
    C_Construct_key: number;
    C_Workgroup_key: number;
    CloningDetails: string;
    Comments: string;
    ConstructID: string;
    CreatedBy: string;
    DNACoding: string;
    DateCreated: Date;
    DateModified: Date;
    FullName: string;
    InsertName: string;
    InsertSize: number | null;
    IsActive: boolean;
    Line: Line[];
    ModifiedBy: string;
    Note: Note[];
    Probe: string;
    Promoter: string;
    Rationale: string;
    SampleConstruct: SampleConstruct[];
    SampleStored: boolean;
    SequenceAvailability: string;
    Source: string;
    StoredFileMap: StoredFileMap[];
    TaskConstruct: TaskConstruct[];
    TotalSize: number | null;
    TransGeneSize: string;
    TransgeneRestrictionEnzymes: string;
    Vector: string;
    VectorSize: number | null;
    Version: number;
    cv_ConstructStatus: cv_ConstructStatus;
}

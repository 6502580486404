<!-- template to render combo item -->
<ng-template #comboItemTmpl let-r="result">
    <span *ngIf="!r.assay.searchAny">
        {{r.assay.display}}:
    </span>
    <span *ngIf="r.assay.searchAny" >
        <span class="badge badge-secondary">{{r.assay.display}}</span>:
    </span>

    <span *ngIf="!r.symbol.searchNulls && !r.symbol.searchAny">
      {{r.symbol.display}}
    </span>
    <span *ngIf="r.symbol.searchNulls" class="badge badge-secondary">
      {{NO_VALUE_TEXT}}
    </span>
    <span *ngIf="r.symbol.searchAny" class="badge badge-secondary">
      {{ANY_SYMBOL_TEXT}}
    </span>
</ng-template>
<!-- Selected combos -->
<div *ngIf="model && model.length > 0">
    <table class="table table-sm table-borderless table-nonfluid">
        <tbody>
            <tr *ngFor="let item of model">
                <td class="icon-cell">
                    <a (click)="removeItem(item)" title="Remove search item">
                        <i class="fa fa-remove remove-item"></i>
                    </a>
                </td>
                <td>
                    <ng-template [ngTemplateOutlet]="comboItemTmpl"
                              [ngTemplateOutletContext]="{result : item}"
                    ></ng-template>
                </td>
            </tr>
        </tbody>
    </table>
</div>
<!-- Genotype combo typeahead -->
<climb-indirect-typeahead
    [search]="searchCombos"
    [resultTemplate]="comboItemTmpl"
    [resultFormatter]="comboFormatter"
    (selectItem)="selectItem($event)"
></climb-indirect-typeahead>

import { GroupedItem, groupItems } from './../util/group-items';
import { Injectable } from '@angular/core';
import {
    Entity,
    EntityQuery
} from 'breeze-client';

import { DataManagerService } from '../../services/data-manager.service';
import { BaseEntityService } from '../../services/base-entity.service';
import { DataContextService } from '../../services/data-context.service';
import { LoggingService } from '../../services/logging.service';
import { summarizeItems } from '../util';

@Injectable()
export class StoredFileService extends BaseEntityService {

    readonly ENTITY_TYPE = 'StoredFiles';
    readonly ENTITY_NAME = 'StoredFile';

    constructor(
        private dataManager: DataManagerService,
        private dataContext: DataContextService,
        private loggingService: LoggingService,
    ) {
        super();
    }


    getFiles(mappedToKeyName: string, mappedToKey: number): Promise<any[]> {
        const query = EntityQuery.from(this.ENTITY_TYPE)
            .expand('StoredFileMap')
            .where("StoredFileMap", "any", mappedToKeyName, "eq", mappedToKey);

        return this.dataManager.returnQueryResults(query);
    }

    create(
        mappedToKeyName: string,
        mappedToKey: number,
        fileName: string,
        friendlyFileName: string
    ): any {

        const fileInitialValues: any = ({
            FileName: fileName,
            FriendlyFileName: friendlyFileName
        });
        const newStoredFile = this.dataManager.createEntity(this.ENTITY_NAME, fileInitialValues);

        const mapInitialValues = ({
            C_StoredFile_key: newStoredFile.C_StoredFile_key
        });
        mapInitialValues[mappedToKeyName] = mappedToKey;
        this.dataManager.createEntity("StoredFileMap", mapInitialValues);

        return newStoredFile;
    }

    deleteFile(file: any) {
        while (file.StoredFileMap.length > 0) {
            const fileMap = file.StoredFileMap[0];
            this.dataManager.deleteEntity(fileMap);
        }

        this.dataManager.deleteEntity(file);
    }

    requestedFileCounts: any = [];
    readonly BUNDLE_DELAY_MS = 400;
    fileCountPromise: Promise<any> = null;
    getFileCount(
        mappedToKeyName: string,
        mappedToKeyValue: number
    ): Promise<number> {

        // add values to request bundle
        this.requestedFileCounts.push({
            mappedToKeyName, 
            mappedToKeyValue
        });

        // bundle multiple requests in quick succession as one (for performance)
        if (!this.fileCountPromise) {
            this.fileCountPromise = new Promise((resolve) => {
            
                const resultsMap: any = {};
                setTimeout(() => {

                    // group requests by keyName
                    const requests: GroupedItem[] = groupItems(
                        this.requestedFileCounts,
                        (item) => item.mappedToKeyName,
                        (item) => item.mappedToKeyValue
                    );

                    // build each query bundle
                    const promises: Promise<any>[] = [];
                    for (const request of requests) {
                        const query = EntityQuery.from("StoredFileMaps")
                            .select ('C_StoredFileMap_key, ' + mappedToKeyName)
                            .where(mappedToKeyName, "in", request.values);
                        
                        resultsMap[request.key] = {};
                        
                        const promise = this.dataManager.executeQuery(
                            query
                        ).then((queryResult) => {
                            // summarize the note counts for each key
                            const results: any[] = queryResult.results;
                            for (const result of results) {
                                result.uniqueKey = result[mappedToKeyName];
                            }
                            const summaries = summarizeItems(results);
                            for (const summary of summaries) {
                                const resultKey = summary.item[mappedToKeyName];
                                resultsMap[request.key][resultKey] = summary.count;
                            }
                        });
                        promises.push(promise);
                    }

                    Promise.all(promises).then(() => {
                        // reset globals and return resultsMap
                        this.fileCountPromise = null;
                        this.requestedFileCounts = [];
                        resolve(resultsMap);
                    });
                }, this.BUNDLE_DELAY_MS);
            });
        }

        return this.fileCountPromise.then((resultsMap) => {
            // return the count for this particular request
            let count = 0;
            if (resultsMap.hasOwnProperty(mappedToKeyName) &&
                resultsMap[mappedToKeyName].hasOwnProperty(mappedToKeyValue)
            ) {
                count = resultsMap[mappedToKeyName][mappedToKeyValue];
            }
            return count;
        });
    }

    getChangesRelatedToMaterial(materialKeyValue: number) {
        const changes: Entity[] = [];
        const fileMaps = this.dataManager.getChangesToEntityByKey('StoredFileMap', 'C_Material_key', materialKeyValue);

        changes.push(...fileMaps);

        for (const fileMap of fileMaps) {
            changes.push(
                ...this.dataManager.getChangesToEntityByKey('StoredFile', 'C_StoredFile_key', (fileMap as any).C_StoredFile_key)
            );
        }

        return changes;
    }

    saveFiles(): Promise<any> {
        const entityNames = ['StoredFile', 'StoredFileMap'];
        const entities = this.dataContext.getChanges();

        const filteredEntities = entities.filter((entity) => {
            const entityShortName = entity.entityAspect.entityGroup.entityType.shortName;
            return entityNames.indexOf(entityShortName) >= 0;
        });

        if (filteredEntities.length > 0) {
            return this.dataContext.saveEntities(entityNames).then((output) => {
                this.loggingService.logSuccess('File Changes Saved', null, 'workflow', true);
            });
        }
        return Promise.resolve();
    }
}

import {
    CellFormatterService,
    FormatterOptions,
    TableOptions
} from '../common/datatable';
import { AuditModifiedFieldComponent } from './audit-modified-fields/audit-modified-field.component';
import { AuditClassFactory } from './audit-class-maker';

export class AuditMaterialPoolsTableOptions {

    options: TableOptions;

    constructor(
        private cellFormatterService: CellFormatterService
    ) {
        this.options = this.getDefaultOptions();
    }

    getDefaultOptions(): TableOptions {

        return {
            csvFileName: 'housings-audit.csv',
            enableDetailColumn: false,
            enableDraggable: false,
            enableSelectable: false,
            enableAuditColumns: false,
            columns: [
                {
                    displayName: 'Type',
                    field: 'UpdateType',
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Modified Fields',
                    field: 'ModifiedFields',
                    sortField: 'ModifiedFields',
                    rendererComponent: AuditModifiedFieldComponent,
                    exportFormatter: this.cellFormatterService.auditModifiedFieldsExportFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Date',
                    field: 'ModifiedDate',
                    formatter: this.cellFormatterService.dateTimeUTCFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Modified By',
                    field: 'ModifiedBy',
                    formatter: this.cellFormatterService.userNameFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'ID',
                    field: 'ID',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('ID', 'ID'),
                    cellClass: AuditClassFactory.createCellClass('id'),
                },
                {
                    displayName: 'Housing Type',
                    field: 'HousingType',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('HousingType', 'HousingType'),
                    cellClass: AuditClassFactory.createCellClass('housingtype'),
                },
                {
                    displayName: 'Status',
                    field: 'Status',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('Status', 'Status'),
                    cellClass: AuditClassFactory.createCellClass('status'),
                },
                {
                    displayName: 'Housing Date',
                    field: 'HousingDate',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('HousingDate', 'HousingDate', this.cellFormatterService.dateFormatter),
                    cellClass: AuditClassFactory.createCellClass('housingdate'),
                },
                {
                    displayName: 'Owner',
                    field: 'Owner',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('Owner', 'Owner'),
                    cellClass: AuditClassFactory.createCellClass('owner'),
                },
                {
                    displayName: 'Container Type',
                    field: 'ContainerType',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('ContainerType', 'ContainerType'),
                    cellClass: AuditClassFactory.createCellClass('containertype'),
                },
                {
                    displayName: 'Comments',
                    field: 'Comments',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('Comments', 'Comments'),
                    cellClass: AuditClassFactory.createCellClass('comments'),
                },
                {
                    displayName: 'Created By',
                    field: 'CreatedBy',
                    formatter: this.cellFormatterService.userNameFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Created Date',
                    field: 'DateCreated',
                    formatter: this.cellFormatterService.dateTimeUTCFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                }
            ]
        };
    }
}

import type { AnimalHealthRecord } from './animal-health-record.interface';
import type { ExportFileRequest } from './export-file-request.interface';
import type { Genotype } from './genotype.interface';
import type { Job } from './job.interface';
import type { Line } from './line.interface';
import type { Material } from './material.interface';
import type { Order } from './order.interface';
import type { Protocol } from './protocol.interface';
import type { StoredFile } from './stored-file.interface';
import type { Study } from './study.interface';
import type { TaskInput } from './task-input.interface';
import type { TaskInstance } from './task-instance.interface';
import type { TaskMaterial } from './task-material.interface';
import type { TaskOutput } from './task-output.interface';
import type { WorkflowTask } from './workflow-task.interface';

export interface StoredFileMap {
    AnimalHealthRecord: AnimalHealthRecord;
    C_AnimalHealthRecord_key: number | null;
    C_Construct_key: number | null;
    C_ExportFileRequest_key: number | null;
    C_Genotype_key: number | null;
    C_Job_key: number | null;
    C_Line_key: number | null;
    C_Material_key: number | null;
    C_Message_key: number | null;
    C_Order_key: number | null;
    C_Protocol_key: number | null;
    C_StoredFileMap_key: number;
    C_StoredFile_key: number;
    C_Study_key: number | null;
    C_TaskInput_key: number | null;
    C_TaskInstance_key: number | null;
    C_TaskMaterial_key: number | null;
    C_TaskOutput_key: number | null;
    C_WorkflowTask_key: number | null;
    C_Workgroup_key: number;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    ExportFileRequest: ExportFileRequest;
    Genotype: Genotype;
    Job: Job;
    Line: Line;
    Material: Material;
    ModifiedBy: string;
    Order: Order;
    Protocol: Protocol;
    StoredFile: StoredFile;
    Study: Study;
    TaskInput: TaskInput;
    TaskInstance: TaskInstance;
    TaskMaterial: TaskMaterial;
    TaskOutput: TaskOutput;
    Version: number;
    WorkflowTask: WorkflowTask;
}

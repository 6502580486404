/**
 * Returns whether any item in list contains the textString
 * @param list - of strings to search
 * @param textString - text to run contains search
 */
export function listContainsString(list: string[], textString: string): boolean {
    if (list && textString) {
        for (const item of list) {
            if (item.indexOf(textString) > -1) {
                return true;
            }
        }
    }

    return false;
}

import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    ViewChildren
} from '@angular/core';

import { randomId } from '../../common/util/random-id';
import * as escapeStringRegexp from 'escape-string-regexp';
import { EnumerationService } from '../../enumerations/enumeration.service';

import { VocabularyService } from '../../vocabularies/vocabulary.service';
import { DateFormatterService } from '@common/util/date-time-formatting';
import { DateTime } from 'luxon';
import { NgModel } from '@angular/forms';
import { dateControlValidator } from '@common/util/date-control.validator';

@Component({
    selector: 'characteristic-input',
    templateUrl: './characteristic-input.component.html',
    styles: [`
        input, 
        select, 
        textarea {
            display: inline-block;
        }
    `]
})
export class CharacteristicInputComponent implements OnChanges, OnInit {
    @ViewChildren('dateControl') dateControls: NgModel[];
    // CharacteristicInstance
    @Input() characteristic: any;
    // CharacteristicInstance.CharacteristicValue
    @Input() value: string;
    @Input() cssClass: string;
    @Input() readonly: boolean;
    @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();
    @Output() lostFocus: EventEmitter<string> = new EventEmitter<string>();

    // characteristic types
    readonly BOOLEAN = 'Boolean';
    readonly NUMBER = 'Number';
    readonly DATE = 'Date';
    readonly TEXT = 'Text';
    readonly LONG_TEXT = 'Long Text';
    readonly ENUMERATION = 'Enumeration';
    readonly LONG_ENUMERATION = 'Long Enumeration';
    readonly VOCABULARY = 'Vocabulary';
    readonly DATE_TIME = 'DateTime';

    _convertedModelValue: any;
    _fieldName: string;
    characteristicDef: any;
    dataType: string;
    numberStep: string;
    enumerationItems: any[];
    _enumItemsPromise: Promise<any>;
    vocabularyItems: any[];
    vocabularyClasses = [
        {
            C_VocabularyClass_key: 1,
            ClassName: "Test Article"
        },
        {
            C_VocabularyClass_key: 2,
            ClassName: "IACUC Protocol"
        }
    ];


    constructor(
        private enumerationService: EnumerationService,
        private vocabularyService: VocabularyService,
        private dateFormatterService: DateFormatterService
    ) {
        // Nothing to do
    }

    ngOnInit() {
        this._fieldName = "characteristic-" + randomId();

        if (!this.characteristic) {
            this.characteristic = {};
        } else if (this.hasDefaultValue(this.characteristic)) {
            this.value = this.characteristic.DefaultValue;
            this.valueChange.emit(this.value);
        }

        if (!this.cssClass) {
            this.cssClass = 'input-medium';
        }

        this._enumItemsPromise = Promise.resolve([]);

        this.setDataType();
        this.convertModel();
        this.setEnumerationItems();
        this.setVocabularyItems();
    }

    ngOnChanges(changes: any) {
        if (changes.characteristic) {
            if (!this.characteristic) {
                this.characteristic = {};
            }
            this.setDataType();
            this.convertModel();
            if (!changes.characteristic.firstChange) {
                this.setEnumerationItems();
                this.setVocabularyItems();
            }
        }
        if (changes.value) {
            this.convertModel();
        }
    }

    /**
     * Find and cache the dataType of this characteristic object
     */
    setDataType() {
        this.characteristicDef = {};
        this.dataType = "";
        this.numberStep = "any";

        // find the correct property for the characteristic definition
        const possibleCharTypes = [
            'JobCharacteristic',
            'MatingCharacteristic',
            'SampleCharacteristic',
            'StudyCharacteristic',
            'TaxonCharacteristic'
        ];

        for (const charType of possibleCharTypes) {
            if ((charType in this.characteristic) &&
                this.characteristic[charType]
            ) {
                this.characteristicDef = this.characteristic[charType];
                // set dataType
                if (this.characteristicDef.cv_DataType) {
                    this.dataType = this.characteristicDef.cv_DataType.DataType;
                    if (this.dataType === this.NUMBER) {
                        this.setNumberStep(this.characteristicDef.NumericScale);
                    }
                }
                break;
            }
        }
    }

    private setNumberStep(scale: number) {
        if (scale !== null && scale >= 0) {
            this.numberStep = Math.pow(10, -1 * scale) + '';
        } else {
            this.numberStep = 'any';
        }
    }

    /**
     * convert input model to proper type based on dataType
     */
    convertModel() {
        let convertedModel: any = null;

        if (this.value) {
            convertedModel = this.value;

            switch (this.dataType) {
                case this.BOOLEAN:
                    convertedModel = this.convertBoolean(this.value);
                    break;
                case this.DATE:
                    convertedModel = this.convertDate(this.value);
                    break;
                case this.DATE_TIME:
                    convertedModel = this.convertDateTime(this.value);
                    break;
            }
        }

        this._convertedModelValue = convertedModel;
    }

    convertBoolean(value: string): boolean {
        return value.toLowerCase() === 'true' ? true : false;
    }

    convertDate(value: string): Date {
        let dt;
        let format = this.dateFormatterService.resolveDateFormat();
        dt = DateTime.fromFormat(value, format);
        // Older data loads in a different format than GLP. 
        if (!dt.isValid) {
            format = 'MM/dd/yyyy';
            dt = DateTime.fromFormat(value, format);
        }
        return dt.toJSDate();
    }

    convertDateTime(value: string): Date {
        let dt;
        let format = this.dateFormatterService.resolveDateTimeFormat(false, false);
        dt = DateTime.fromFormat(value, format);
        // Older data loads in a different format than either datetime format present.
        if (!dt.isValid) {
            format = "MM/dd/yyyy hh:mm:ss a";
            dt = DateTime.fromFormat(value, format);
        }
        return dt.toJSDate();
    }

    /**
     * Format value and triggers valueChange event
     */
    formatValue() {
        this.formatValueCore();
        this.valueChange.emit(this.value);
    }

    /**
     * Triggers lostFocus event
     */
    focusOut() {
        this.formatValueCore();
        this.lostFocus.emit(this.value);
    }

    formatBoolean(value: boolean): string {
        return value ? 'true' : 'false';
    }
    
    formatDateString(value: Date): string {
        return this.dateFormatterService.formatDateOnly(value);
    }
    formatDateTimeString(value: Date): string {
        return this.dateFormatterService.formatDateTime(value);
    }

    setEnumerationItems() {
        this.enumerationItems = [];
        const classKey = this.characteristicDef.C_EnumerationClass_key;
        if (classKey) {
            this._enumItemsPromise = this.enumerationService.getEnumerationItems(classKey)
                .then((items) => {
                    this.enumerationItems = items;
                    this.sortEnumerationItemsByReverseKey();
                });
        }
    }

    private hasDefaultValue(characteristic: any): boolean {
        return characteristic.C_JobCharacteristicInstance_key < 0 && characteristic.DefaultValue;
    }

    /**
     * Format _convertedModelValue back to CharacteristicValue string
     */
    private formatValueCore() {
        switch (this.dataType) {
            case this.BOOLEAN:
                this.value = this.formatBoolean(this._convertedModelValue);
                break;
            case this.DATE:
                this.value = this.formatDateString(this._convertedModelValue);
                break;
            case this.DATE_TIME:
                this.value = this.formatDateTimeString(this._convertedModelValue);
                break;
            default:
                this.value = this._convertedModelValue;
        }
    }

    private sortEnumerationItemsByReverseKey() {
        // sorts items from most recently added to least recently added
        this.enumerationItems.sort((a, b) => {
            a = a.C_EnumerationItem_key;
            b = b.C_EnumerationItem_key;
            if (a < b) {
                return 1;
            }
            if (a > b) {
                return -1;
            }
            return 0;
        });
    }

    searchEnumerations = (search: string): Promise<any> => {
        return this._enumItemsPromise.then(() => {
            const escapedSearch = escapeStringRegexp(search);
            const regex = new RegExp(escapedSearch, 'gi');
            const matches = this.enumerationItems.filter((item) => {
                return regex.test(item.ItemName);
            });
            return Promise.resolve(matches);
        });
    }

    searchEnumerationsForExactMatch = (search: string): Promise<any> => {
        return this._enumItemsPromise.then(() => {
            const matches = this.enumerationItems.filter((item) => {
                return item.ItemName === search;
            });
            return Promise.resolve(matches);
        });
    }

    enumFormatter = (value: any) => {
        return value.ItemName;
    }

    setVocabularyItems() {
        this.enumerationItems = [];
        const classKey = this.characteristicDef.C_VocabularyClass_key;
        if (classKey === 1) {
            this.getTestArticles();
        } else {
            this.getIACUCProtocols();
        }
    }

    getTestArticles(): Promise<any> {
        return this.vocabularyService.getCV('cv_TestArticles', null, null, true).then((items) => {
            items.forEach((item: any) => {
                item.ItemName = item.TestArticle;
                item.C_VocabularyItem_key = item.C_TestArticle_key;
            });
            this.vocabularyItems = items;
        });        
    }

    getIACUCProtocols(): Promise<any> {
        return this.vocabularyService.getCV('cv_IACUCProtocols', null, null, true).then((items) => {
            items.forEach((item: any) => {
                item.ItemName = item.IACUCProtocol;
                item.C_VocabularyItem_key = item.C_IACUCProtocol_key;
            });
            this.vocabularyItems = items;
        });
    }

    validate() {
        return dateControlValidator(this.dateControls);
    }
}


import { NgModule } from '@angular/core';
import { ClimbCommonModule } from '../common/climb-common.module';
import { MultipleSessionModalComponent } from './components/multiple-session-modal.component';
import { ReloadModalComponent } from './components/reload-modal.component';
import { MultipleTabModalOpenService } from './multiple-tab-modal-open.service';
import { TabStorageService } from './tab-storage.service';

@NgModule({
  imports: [
    ClimbCommonModule,
  ],
  declarations: [
    MultipleSessionModalComponent,
    ReloadModalComponent,
  ],
  exports: [
    MultipleSessionModalComponent,
    ReloadModalComponent,
  ],
  providers: [
    MultipleTabModalOpenService,
    TabStorageService,
  ]
})
export class MultipleTabModule { }

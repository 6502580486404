<climb-popover #popover>
    <div class="copy-content">
        <div class="copy-title">Copied {{bufferType}}s</div>
        <div class="copy-item" *ngFor="let item of buffer | slice:0:10">
            <ng-container *ngIf="bufferType === 'Animal'">
                {{item.AnimalName}}
            </ng-container>
            <ng-container *ngIf="bufferType === 'Cohort'">
                {{item.CohortName}}
            </ng-container>
            <ng-container *ngIf="bufferType === 'Sample'">
                {{item.SampleName}}
            </ng-container>
            <ng-container *ngIf="bufferType === 'Protocol'">
                {{item.ProtocolName}}
            </ng-container>
            <ng-container *ngIf="bufferType === 'WorkflowTask'">
                {{item.TaskName}}
            </ng-container>
        </div>
        <div class="copy-item" *ngIf="buffer.length > 10">
            &hellip; {{buffer.length - 10}} more
        </div>
        <div class="copy-actions">
            <button climbButton size="md" variant="secondary" (click)="clearClipboard()">
                Clear
            </button>
            <button climbButton size="md" variant="primary" (click)="popover.close()">
                Close
            </button>
        </div>
    </div>
</climb-popover>

import type { Animal } from './animal.interface';
import type { Line } from './line.interface';

export interface AnimalShipment {
    Animal: Animal[];
    BoxNumber: string;
    C_AnimalShipment_key: number;
    C_Line_key: number | null;
    C_Workgroup_key: number;
    CountFemales: number;
    CountMales: number;
    CreatedBy: string;
    DateArrival: Date | null;
    DateCreated: Date;
    DateModified: Date;
    ExternalOrderID: string;
    Line: Line;
    ModifiedBy: string;
    Version: number;
}

import { Component, ViewChild } from '@angular/core';
import { CopyBufferService } from '@common/services/copy-buffer.service';
import { CLIMB_SURFACE_MODE } from '@common/tokens';
import { PopoverComponent } from '@common/components/popover/popover.component';

@Component({
    selector: 'climb-copy-buffer-popover',
    templateUrl: 'copy-buffer-popover.component.html',
    styleUrls: ['copy-buffer-popover.component.scss'],
    providers: [{ provide: CLIMB_SURFACE_MODE, useValue: 'light' }],
})
export class CopyBufferPopoverComponent {
    @ViewChild('popover') popover: PopoverComponent;

    get bufferType() {
        return this.copyBufferService.bufferType;
    }

    get buffer() {
        return this.copyBufferService.buffer;
    }

    constructor(private copyBufferService: CopyBufferService) {}

    clearClipboard() {
        this.copyBufferService.clear();
    }
}

import {
    CellFormatterService,
    TableOptions,
    FormatterOptions
} from '../common/datatable';
import { FeatureFlagService } from '../services/feature-flags.service';
import { TranslationService } from '../services/translation.service';
import { AuditModifiedFieldComponent } from './audit-modified-fields/audit-modified-field.component';
import { AuditClassFactory } from './audit-class-maker';
import { pipe, isGLP, notGLP, excludeColumnsFromOptions } from '@common/datatable/utils';

export class AuditAnimalsTableOptions {
    options: TableOptions;
    isGLP = false;

    constructor(
        private cellFormatterService: CellFormatterService,
        private translationService: TranslationService,
        private featureFlagService: FeatureFlagService,
    ) {
        this.initIsGLP();
        this.options = pipe(
            this.getDefaultOptions(),
            isGLP(this.isGLP, excludeColumnsFromOptions(['JobName'])),
            notGLP(this.isGLP, excludeColumnsFromOptions([
                'AlternatePhysicalID',
                'Classification',
                'HeldFor',
                'CITESNumber',
            ])),
        );
    }

    getDefaultOptions(): TableOptions {

        return {
            csvFileName: 'animal-audit.csv',
            enableDetailColumn: false,
            enableDraggable: false,
            enableSelectable: false,
            enableAuditColumns: false,
            columns: [
                {
                    displayName: 'Type',
                    field: 'UpdateType',
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Modified Fields',
                    field: 'ModifiedFields',
                    sortField: 'ModifiedFields',
                    rendererComponent: AuditModifiedFieldComponent,
                    exportFormatter: this.cellFormatterService.auditModifiedFieldsExportFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Date',
                    field: 'ModifiedDate',
                    formatter: this.cellFormatterService.dateTimeUTCFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Modified By',
                    field: 'ModifiedBy',
                    formatter: this.cellFormatterService.userNameFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'ID',
                    field: 'Identifier',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('Identifier', 'Identifier'),
                    cellClass: AuditClassFactory.createCellClass('identifier'),
                },
                {
                    displayName: 'Name',
                    field: 'Name',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('Name', 'Name'),
                    cellClass: AuditClassFactory.createCellClass('name'),
                },
                {
                    displayName: 'Date Origin',
                    field: 'DateOrigin',
                    visible: false,
                    formatter: this.cellFormatterService.createAuditColumnFormatter('DateOrigin', 'DateOrigin', this.cellFormatterService.dateFormatter),
                    cellClass: AuditClassFactory.createCellClass('dateorigin'),
                },
                {
                    displayName: 'Animal Mating Status',
                    field: 'AnimalMatingStatus',
                    visible: false,
                    formatter: this.cellFormatterService.createAuditColumnFormatter('AnimalMatingStatus', 'AnimalMatingStatus'),
                    cellClass: AuditClassFactory.createCellClass('animalmatingstatus'),
                },
                {
                    displayName: 'External Identifier',
                    field: 'ExternalIdentifier',
                    visible: false,
                    formatter: this.cellFormatterService.createAuditColumnFormatter('ExternalIdentifier', 'ExternalIdentifier'),
                    cellClass: AuditClassFactory.createCellClass('externalidentifier'),
                },
                {
                    displayName: 'Alternate Physical ID',
                    field: 'AlternatePhysicalID',
                    visible: false,
                    formatter: this.cellFormatterService.createAuditColumnFormatter('AlternatePhysicalID', 'AlternatePhysicalID'),
                    cellClass: AuditClassFactory.createCellClass('alternatephysicalid'),                    
                },
                {
                    displayName: 'Marker',
                    field: 'Marker',
                    visible: false,
                    formatter: this.cellFormatterService.createAuditColumnFormatter('Marker', 'Marker'),
                    cellClass: AuditClassFactory.createCellClass('marker'),
                },
                {
                    displayName: 'Marker Type',
                    field: 'MarkerType',
                    visible: false,
                    formatter: this.cellFormatterService.createAuditColumnFormatter('MarkerType', 'MarkerType'),
                    cellClass: AuditClassFactory.createCellClass('markertype'),
                },
                {
                    displayName: 'Sex',
                    field: 'Sex',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('Sex', 'Sex'),
                    cellClass: AuditClassFactory.createCellClass('sex'),
                },
                {
                    displayName: 'Status',
                    field: 'Status',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('Status', 'Status'),
                    cellClass: AuditClassFactory.createCellClass('status'),
                },
                {
                    displayName: 'Held For',
                    field: 'HeldFor',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('HeldFor', 'HeldFor'),
                    cellClass: AuditClassFactory.createCellClass('heldfor'),
                },
                {
                    displayName: this.translationService.translate('Line'),
                    field: 'Line',
                    visible: false,
                    formatter: this.cellFormatterService.createAuditColumnFormatter('Line', this.translationService.translate('Line')),
                    cellClass: AuditClassFactory.createCellClass(this.translationService.translate('Line').toLowerCase()),
                },
                {
                    displayName: this.translationService.translate('Line') + ' Short Name',
                    field: 'LineShortName',
                    visible: false,
                    formatter: this.cellFormatterService.createAuditColumnFormatter('LineShortName', this.translationService.translate('Line') + 'ShortName'),
                    cellClass: AuditClassFactory.createCellClass((this.translationService.translate('Line') + 'ShortName').toLowerCase()),
                },
                {
                    displayName: 'Species',
                    field: 'Species',
                    visible: false,
                    formatter: this.cellFormatterService.createAuditColumnFormatter('Species', 'Species'),
                    cellClass: AuditClassFactory.createCellClass('species'),
                },
                {
                    displayName: 'Breeding Status',
                    field: 'BreedingStatus',
                    visible: false,
                    formatter: this.cellFormatterService.createAuditColumnFormatter('BreedingStatus', 'BreedingStatus'),
                    cellClass: AuditClassFactory.createCellClass('breedingstatus'),
                },
                {
                    displayName: 'Classification',
                    field: 'Classification',
                    visible: false,
                    formatter: this.cellFormatterService.createAuditColumnFormatter('Classification', 'Classification'),
                    cellClass: AuditClassFactory.createCellClass('classification'),
                },
                {
                    displayName: 'Use',
                    field: 'Use',
                    visible: false,
                    formatter: this.cellFormatterService.createAuditColumnFormatter('Use', 'Use'),
                    cellClass: AuditClassFactory.createCellClass('use'),
                },
                {
                    displayName: 'Diet',
                    field: 'Diet',
                    visible: false,
                    formatter: this.cellFormatterService.createAuditColumnFormatter('Diet', 'Diet'),
                    cellClass: AuditClassFactory.createCellClass('diet'),
                },
                {
                    displayName: 'Shipment',
                    field: 'ShipmentID',
                    visible: false,
                    formatter: this.cellFormatterService.createAuditColumnFormatter('ShipmentID', 'ShipmentID'),
                    cellClass: AuditClassFactory.createCellClass('shipmentid'),
                },
                {
                    displayName: 'Vendor',
                    field: 'VendorID',
                    visible: false,
                    formatter: this.cellFormatterService.createAuditColumnFormatter('VendorID', 'VendorID'),
                    cellClass: AuditClassFactory.createCellClass('vendorid'),
                },
                {
                    displayName: 'Order ID',
                    field: 'OrderID',
                    visible: false,
                    formatter: this.cellFormatterService.createAuditColumnFormatter('OrderID', 'OrderID'),
                    cellClass: AuditClassFactory.createCellClass('orderid'),
                },
                {
                    displayName: 'CITES Number',
                    field: 'CITESNumber',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('CITESNumber', 'CITESNumber'),
                    cellClass: AuditClassFactory.createCellClass('citesnumber'),
                },
                {
                    displayName: 'IACUC Protocol',
                    field: 'IACUCProtocol',
                    visible: false,
                    formatter: this.cellFormatterService.createAuditColumnFormatter('IACUCProtocol', 'IACUCProtocol'),
                    cellClass: AuditClassFactory.createCellClass('iacucprotocol'),
                },
                {
                    displayName: 'Body Condition Score',
                    field: 'BodyConditionScore',
                    visible: false,
                    formatter: this.cellFormatterService.createAuditColumnFormatter('BodyConditionScore', 'BodyConditionScore'),
                    cellClass: AuditClassFactory.createCellClass('bodyconditionscore'),
                },
                {
                    displayName: 'Exit Date',
                    field: 'ExitDate',
                    visible: false,
                    formatter: this.cellFormatterService.createAuditColumnFormatter('ExitDate', 'ExitDate', this.cellFormatterService.dateFormatter),
                    cellClass: AuditClassFactory.createCellClass('exitdate'),
                },
                {
                    displayName: 'Exit Reason',
                    field: 'ExitReason',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('ExitReason', 'ExitReason'),
                    cellClass: AuditClassFactory.createCellClass('exitreason'),
                },
                {
                    displayName: 'Microchip ID',
                    field: 'MicrochipID',
                    visible: false,
                    formatter: this.cellFormatterService.createAuditColumnFormatter('MicrochipID', 'MicrochipID'),
                    cellClass: AuditClassFactory.createCellClass('microchipid'),
                },
                {
                    displayName: 'Origin',
                    field: 'Origin',
                    visible: false,
                    formatter: this.cellFormatterService.createAuditColumnFormatter('Origin', 'Origin'),
                    cellClass: AuditClassFactory.createCellClass('origin'),
                },
                {
                    displayName: 'Genotypes',
                    field: 'Genotypes',
                    visible: false,
                    formatter: this.cellFormatterService.createAuditColumnFormatter('Genotypes', 'Genotypes'),
                    cellClass: AuditClassFactory.createCellClass('genotypes'),
                },
                {
                    displayName: 'Generation',
                    field: 'Generation',
                    visible: false,
                    formatter: this.cellFormatterService.createAuditColumnFormatter('Generation', 'Generation'),
                    cellClass: AuditClassFactory.createCellClass('generation'),
                },
                {
                    displayName: 'Owner',
                    field: 'Owner',
                    visible: false,
                    formatter: this.cellFormatterService.createAuditColumnFormatter('Owner', 'Owner'),
                    cellClass: AuditClassFactory.createCellClass('owner'),
                },
                {
                    displayName: this.translationService.translate('Job') + ' Names',
                    field: 'JobName',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('JobName', this.translationService.translate('Job') + 'Name'),
                    cellClass: AuditClassFactory.createCellClass((this.translationService.translate('Job') + 'Name').toLowerCase()),
                },
                {
                    displayName: 'Birth ID',
                    field: 'BirthID',
                    visible: false,
                    formatter: this.cellFormatterService.createAuditColumnFormatter('BirthID', 'BirthID'),
                    cellClass: AuditClassFactory.createCellClass('birthid'),
                },
                {
                    displayName: 'Birth Date',
                    field: 'BirthDate',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('BirthDate', 'BirthDate', this.cellFormatterService.dateFormatter),
                    cellClass: AuditClassFactory.createCellClass('birthdate'),
                },
                {
                    displayName: 'Birth Comment',
                    field: 'BirthComment',
                    visible: false,
                    formatter: this.cellFormatterService.createAuditColumnFormatter('BirthComment', 'BirthComment'),
                    cellClass: AuditClassFactory.createCellClass('birthcomment'),
                },
                {
                    displayName: 'Created By',
                    field: 'CreatedBy',
                    formatter: this.cellFormatterService.userNameFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Created Date',
                    field: 'DateCreated',
                    formatter: this.cellFormatterService.dateTimeUTCFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                }
            ]
        };
    }

    initIsGLP() {
        const flag = this.featureFlagService.getFlag("IsGLP");
        this.isGLP = (flag && flag.IsActive && flag.Value.toLowerCase() === "true");
    }
}

import { AuditGridView } from '../../models/audit-grid-view.enum';

export const auditLinkMap: {[key: string]: AuditGridView} = {
        'animalcommentssortable': AuditGridView.AnimalComments,
        'cohortssortable': AuditGridView.CohortAnimals,
        'jobidssortable': AuditGridView.JobAnimals,
        'materialpoolssortable': AuditGridView.HousingAnimals,
        // *currentlocationpath should have all translations available as keys
        'jobcurrentlocationpath': AuditGridView.JobLocations,
        'studycurrentlocationpath': AuditGridView.JobLocations,
        'joblinessortable': AuditGridView.JobLines,
        'jobinstitutionnamessortable': AuditGridView.JobsInstitutions,
        'line': AuditGridView.JobLines,
        'strain': AuditGridView.JobLines,
        'location': AuditGridView.HousingLocations
};

import { DataContextService } from './../services/data-context.service';
import {
    Component,
    OnInit,
    AfterViewInit,
    ViewChild
} from '@angular/core';
import { WizardComponent } from 'angular-archwizard';

import { CurrentWorkgroupService } from '../services/current-workgroup.service';
import { LoggingService } from '../services/logging.service';
import { RoutingService } from './../routing/routing.service';
import { WizardService } from './wizard.service';
import {
    CvValue,
    SeedValues
} from './models';

import { ChooseLinesComponent } from './steps/choose-lines.component';

import { contactSupportUri } from '../config/climb-web-settings';
import { notEmpty } from '../common/util';

/**
 * Wizard for initializing workgroup.
 *
 * Depends on ng2-archwizard module:
 * https://www.npmjs.com/package/ng2-archwizard
 */
@Component({
    selector: 'seed-data-wizard',
    templateUrl: './seed-data-wizard.component.html'
})
export class SeedDataWizardComponent implements OnInit, AfterViewInit {
    @ViewChild(WizardComponent) public wizard: WizardComponent;
    @ViewChild("chooseLines") chooseLines: ChooseLinesComponent;

    initPromise: Promise<any>;

    hasError = false;
    savingSeedData = false;
    seedValues: SeedValues = new SeedValues();
    supportUri: string;
    workgroupName: string;

    readonly COMPONENT_LOG_TAG = 'seed-data-wizard';


    constructor(
        private currentWorkgroupService: CurrentWorkgroupService,
        private loggingService: LoggingService,
        private routingService: RoutingService,
        private wizardService: WizardService,
        private dataContext: DataContextService
    ) {
        // Nothing to do
    }

    ngOnInit() {
        this.initPromise = this.dataContext.init().then(() => {
            this.initialize();
        });
    }

    initialize() {
        this.initializeSeedValues();

        this.workgroupName = this.currentWorkgroupService.getWorkgroupName();
        this.supportUri = contactSupportUri;
    }

    ngAfterViewInit() {
        this.initPromise.then(() => {
            this.resumeFromLastStep();
        });
    }


    // SeedValues
    private initializeSeedValues() {
        // Check in localstorage first
        this.seedValues = this.wizardService.getSeedValues();

        if (!this.seedValues) {
            this.seedValues = new SeedValues();
        }
    }

    private getSeedValues(): SeedValues {
        return this.wizardService.getSeedValues();
    }

    private saveSeedValues() {
        this.wizardService.saveSeedValues(this.seedValues);
    }

    private deleteSeedValues() {
        this.wizardService.deleteSeedValues();
    }


    /**
     * Goes to the step other than the first if the user left off there.
     */
    private resumeFromLastStep() {
        const lastStepIndex = this.seedValues.lastWizardStepIndex;

        if (lastStepIndex > 0) {
            this.markPriorStepsCompleted(lastStepIndex);
            this.wizard.goToStep(lastStepIndex);
        }
    }

    private markPriorStepsCompleted(lastStepIndex: number) {
        let stepIndex = 0;
        for (const step of this.wizard.wizardSteps) {
            if (stepIndex === lastStepIndex) {
                break;
            }

            step.completed = true;
            stepIndex++;
        }
    }

    /**
     * Does an array of CvValues contain only defined values?
     * @param cvValues
     */
    private cvValuesNotEmpty(cvValues: CvValue[]): boolean {
        if (notEmpty(cvValues)) {
            let isNotEmpty = true;

            // Ensure each cvValue exists
            for (const item of cvValues) {
                if (!item.cvValue) {
                    isNotEmpty = false;
                    break;
                }
            }
            return isNotEmpty;
        } else {
            return false;
        }
    }


    // Taxa
    hasSelectedTaxa(): boolean {
        return (notEmpty(this.seedValues.taxa));
    }


    // Line Types
    onMoveFromLineTypes() {
        if (this.chooseLines) {
            this.chooseLines.onShowThisStep();
        }

        this.onStepMove();
    }

    hasSelectedLineTypes(): boolean {
        return this.cvValuesNotEmpty(this.seedValues.lineTypes);
    }


    // Lines
    hasSelectedLines(): boolean {
        return notEmpty(this.seedValues.lines);
    }


    // ContainerTypes
    hasAnimalContainerTypes(): boolean {
        return this.cvValuesNotEmpty(this.seedValues.animalContainerTypes);
    }

    hasSampleContainerTypes(): boolean {
        return this.cvValuesNotEmpty(this.seedValues.sampleContainerTypes);
    }


    // Moving Steps
    onStepMove() {
        this.setLastWizardStepIndex();
        this.saveSeedValues();
        console.table(this.seedValues);
    }

    private setLastWizardStepIndex() {
        this.seedValues.lastWizardStepIndex = this.wizard.currentStepIndex;
    }


    // Finish
    completeSeedDataInitialization(): Promise<void> {
        this.savingSeedData = true;

        return this.wizardService.propagateSeedValues(this.seedValues).then(() => {
            return this.wizardService.completeInitialization();
        }).then(() => {
            this.deleteSeedValues();
            this.savingSeedData = false;
        }).catch((error) => {
            this.savingSeedData = false;
            this.hasError = true;

            const seedValuesJson = JSON.stringify(this.seedValues);
            error.message += ' SeedValues: ' + seedValuesJson;

            this.loggingService.logError(
                'Workgroup initialization failed',
                error,
                this.COMPONENT_LOG_TAG,
                false);

            throw error;
        });
    }

    finishWizard() {
        this.navigateToDashboard();
    }

    private navigateToDashboard() {
        this.routingService.navigateToDashboard();
    }
}

import {
    Component,
    Input,
    Output,
    EventEmitter
} from '@angular/core';

import {
    NgbActiveModal
} from '@ng-bootstrap/ng-bootstrap';

import { StoredFileService } from './storedfile.service';
import {
    SaveChangesService
} from '../../services/save-changes.service';

@Component({
    selector: 'file-upload-modal',
    templateUrl: './file-upload-modal.component.html'
})
export class FileUploadModalComponent {
 
    /* The name of the object's primary key database field */
    @Input() pkName: string;
    /* The value of the object's primary key */
    @Input() pkValue: number;
    @Input() facetPrivilege = 'ReadWrite';

    @Output() exit: EventEmitter<any> = new EventEmitter<any>();

    saveChangesService: SaveChangesService;
    hasChanges = false;

    constructor(
        private activeModal: NgbActiveModal,
        private fileService: StoredFileService,
        saveChangesService: SaveChangesService,
    ) {
        this.saveChangesService = saveChangesService;
    }

    saveFiles() {
        // Save immediately
        return this.fileService.saveFiles();
    }

    onClose(): void {
        this.saveFiles().then(() => {
            this.exit.emit();
            this.activeModal.dismiss('closed');
        });
    }
}

/**
 * Report filter for use with the survival curve web API.
 */
export class SurvivalCurveReportFilter {
    /* String of comma-delimited GenotypeAssayKeys */
    genotypeAssayKeys: string;

    /* String of comma-delimited GenotypeSymbolKeys */
    genotypeSymbolKeys: string;

    /* String of comma-delimited JobKeys */
    jobKeys: string;

    /* String of comma-delimited LineKeys */
    lineKeys: string;

    /* String of comma-delimited WorkflowTaskKeys */
    workflowTaskKeys: string;

    /* String of comma-delimited cv_Sex._Sex_keys */
    sexKeys: string;

    /* String of comma-delimited cv_ExitReason._ExitReason_keys */
    exitReasonKeys: string;

    /* String of comma-delimited StudyKeys */
    studyKeys: string;

    /* Data interval: 'Days' | 'Weeks' | 'Months' */
    interval: string;

    /* Maximum number of intervals to return */
    maxRange: number;

    constructor(interval: string) {
        this.interval = interval;
    }
}

import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import { AuthService } from "../../services/auth.service";
import { DataManagerService } from "../../services/data-manager.service";
import { VocabularyService } from '../../vocabularies/vocabulary.service';

@Component({
    selector: 'animal-comments-table',
    templateUrl: './animal-comments-table.component.html',
    styles: [
        `
            .bolded {
                font-weight: bold;
            }
            .italicized {
                font-style: italic;
            }
        `
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AnimalCommentsTableComponent implements OnInit {
    @Input() facet: any;
    @Input() animal: any;
    @Input() animalCommentStatuses: any;

    defaultAnimalCommentStatusKey: number;
    systemGeneratedAnimalCommentStatus: Promise<any>;

    constructor(
        private _dataManager: DataManagerService,
        private _authService: AuthService,
        private vocabularyService: VocabularyService
    ) {
        // define providers
    }
    ngOnInit() {
        // initialize
        for (const ac of this.animal.AnimalComment) {
            ac.trackId = Math.random();
        }
        this.getDefaults();
        this.getSystemGeneratedStatus();
    }

    getDefaults() {
        return Promise.all([
            this.vocabularyService.getCVDefault('cv_AnimalCommentStatuses').then((value) => {
                if (value) {
                    this.defaultAnimalCommentStatusKey = value.C_AnimalCommentStatus_key;
                }
            })
        ]);
    }

    getSystemGeneratedStatus() {
        this.systemGeneratedAnimalCommentStatus = this.vocabularyService.getSystemGeneratedCV('cv_AnimalCommentStatuses');
    }

    addComment() {
        const animalComment = {
            C_Material_key: this.animal.C_Material_key,
            Animal: this.animal,
            Comment: '',
            C_AnimalCommentStatus_key: this.defaultAnimalCommentStatusKey,
            CreatedBy: this._authService.getCurrentUserName(),
            DateCreated: new Date(),
            ModifiedBy: this._authService.getCurrentUserName(),
            DateModified: new Date()
        };
        
        this._dataManager.createEntity("AnimalComment", animalComment);
        this.animal.AnimalComment[this.animal.AnimalComment.length - 1].trackId = Math.random();
    }

    removeComment(animalComment: any) {
        const commentIndex = this.animal.AnimalComment.indexOf(animalComment);
        this.animal.AnimalComment.splice(commentIndex, 1);
        this._dataManager.deleteEntity(animalComment);
    }

    animalCommentStatusKeyFormatter = (value: any) => {
        return value.C_AnimalCommentStatus_key;
    }

    animalCommentStatusFormatter = (value: any) => {
        return value.AnimalCommentStatus;
    }

    onModelChange(animalComment: any) {
        animalComment.trackId++;
    }

    // trackItem is used in the trackBy clause in the ngFor, which allows the list to be arbitrarily redrawn (thus, allowing the pipe to update the style of table row).
    // this setup is to prevent the row's style being determined only when the relevant information changes, rather than every change detection interval. 
    trackItem(index: number, item: any) {
        return item.trackId;
    }
}

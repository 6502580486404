import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChildren,
} from '@angular/core';

import { ClinicalVocabService } from '../clinical-vocab.service';
import { BaseFilter } from '../../common/facet';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
    focusElementByQuery,
} from '../../common/util';
import { Subscription } from 'rxjs';
import { FeatureFlagService } from '../../services/feature-flags.service';
import { Resource, cv_AnimalStatus, cv_BodyConditionScore, cv_ClinicalObservation, cv_ClinicalObservationStatus } from '@common/types';
import { NgModel } from '@angular/forms';
import { dateControlValidator } from '@common/util/date-control.validator';
import { LoggingService } from '@services/logging.service';

@Component({
    selector: 'clinical-filter',
    templateUrl: './clinical-filter.component.html'
})
export class ClinicalFilterComponent extends BaseFilter implements OnInit {
    @ViewChildren('dateControl') dateControls: NgModel[];

    @Input() filter: any;
    @Output() onFilter = new EventEmitter<any>();
    @Output() close = new EventEmitter<any>();
    // services

    // vocabularies
    animalStatuses: cv_AnimalStatus[];
    bodyConditionScores: cv_BodyConditionScore[];
    clinicalObservations: cv_ClinicalObservation[];
    diagnosticObservations: cv_ClinicalObservation[];
    clinicalObservationStatuses: cv_ClinicalObservationStatus[];
    resources: Resource[];
    isGLP = false;
    readonly MULTI_PASTE_INPUT_LIMIT = 150;

    constructor(
        private activeModal: NgbActiveModal,
        private clinicalVocabService: ClinicalVocabService,
        private featureFlagService: FeatureFlagService,
        private loggingService: LoggingService,
    ) {
        super(activeModal);
    }


    // lifecycle
    ngOnInit() {
        this.cloneFilter();
        this.initIsGLP();

        this.animalStatuses = [];
        this.bodyConditionScores = [];
        this.clinicalObservations = [];
        this.diagnosticObservations = [];
        this.clinicalObservationStatuses = [];
        this.resources = [];

        this.getCVs();
        focusElementByQuery('[data-auto-focus=clinical]');
    }

    // isGLP
    initIsGLP() {
        const flag = this.featureFlagService.getFlag("IsGLP");
        this.isGLP = (flag && flag.IsActive && flag.Value.toLowerCase() === "true");
    }

    private cloneFilter() {
        const dateObservedStart = this.filter.DateObservedStart;
        const dateObservedEnd = this.filter.DateObservedEnd;
        const dateDueStart = this.filter.DateDueStart;
        const dateDueEnd = this.filter.DateDueEnd;

        this.filter = this.copyFilter(this.filter);

        this.filter.DateObservedStart = dateObservedStart;
        this.filter.DateObservedEnd = dateObservedEnd;
        this.filter.DateDueStart = dateDueStart;
        this.filter.DateDueEnd = dateDueEnd;
    }

    getCVs() {
        this.clinicalVocabService.animalStatuses$.subscribe((animalStatuses) => {
            this.animalStatuses = animalStatuses;
        });
        this.clinicalVocabService.bodyConditionScores$.subscribe((data) => {
            this.bodyConditionScores = data;
        });
        this.clinicalVocabService.clinicalObservationStatuses$
            .subscribe((clinicalObservationStatuses) => {
                this.clinicalObservationStatuses = clinicalObservationStatuses;
            });
        this.clinicalVocabService.clinicalObservations$
            .subscribe((clinicalObservations: cv_ClinicalObservation[]): void => {
                if (this.isGLP) {
                    // divide observations into diagnostic and clinical
                    const observations: cv_ClinicalObservation[][] = clinicalObservations.reduce(([diagnostic, clinical]: cv_ClinicalObservation[][], item: cv_ClinicalObservation) => {
                        return item.TrackInWorkflow ? [[...diagnostic, item], clinical] : [diagnostic, [...clinical, item]];
                      }, [[], []]);

                    const [glpDiagnosticObservations, glpСlinicalObservations] = observations;
                    
                    this.diagnosticObservations = glpDiagnosticObservations;
                    this.clinicalObservations = glpСlinicalObservations;
                } else {
                    this.clinicalObservations = clinicalObservations;
                }
            });
        this.clinicalVocabService.resources$.subscribe((resources) => {
            this.resources = resources.filter((item) => {
                return !item.IsGroup;
            });
        });
    }

    filterClicked(event: Event) {
        const errMessage = dateControlValidator(this.dateControls);
        if (errMessage) {
            this.loggingService.logError(errMessage, null, '', true);
            return;
        }

        event.preventDefault();
        this.onFilter.emit(this.filter);
        this.close.emit();
        this.activeModal.close();
    }

    closeClicked() {
        this.close.emit();
        this.activeModal.dismiss();
    }

    clearClicked() {
        this.filter = {};
        this.onFilter.emit(this.filter);
        this.close.emit();
        this.activeModal.close();
    }

}


import type { LocationPosition } from './location-position.interface';
import type { Material } from './material.interface';
import type { MaterialPool } from './material-pool.interface';

export interface MaterialLocation {
    C_LocationPosition_key: number;
    C_MaterialPoolLocation_key: number;
    C_MaterialPool_key: number | null;
    C_Material_key: number | null;
    C_Workgroup_key: number;
    ContainerName: string;
    CreatedBy: string;
    DateCreated: Date;
    DateIn: Date | null;
    DateModified: Date;
    DateOut: Date | null;
    LocationPosition: LocationPosition;
    Material: Material;
    MaterialPool: MaterialPool;
    ModifiedBy: string;
    Version: number;
}

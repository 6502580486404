export interface cv_CensusDiscrepancy {
    C_CensusDiscrepancy_key: number;
    C_VocabularySource_key: number | null;
    C_Workgroup_key: number;
    CensusDiscrepancy: string;
    CreatedBy: string;
    DateCreated: string;
    DateModified: string;
    ElementID: string;
    IsActive: boolean;
    IsDefault: boolean;
    IsDeprecated: boolean;
    IsEndState: boolean;
    ModifiedBy: string;
    SortOrder: number;
    Version: number;
}

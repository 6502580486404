import {
    CellFormatterService,
    FormatterOptions,
    TableOptions
} from '../common/datatable';
import { TranslationService } from '../services/translation.service';
import { AuditModifiedFieldComponent } from './audit-modified-fields/audit-modified-field.component';
import { AuditClassFactory } from './audit-class-maker';

export class AuditObservationsDetailsTableOptions {
    options: TableOptions;

    constructor(
        private cellFormatterService: CellFormatterService,
        private isGLP: boolean
    ) {
        this.options = this.getDefaultOptions();
    }

    getDefaultOptions(): TableOptions {
        return {
            csvFileName: 'clinical-observation-details-audit.csv',
            enableDetailColumn: false,
            enableDraggable: false,
            enableSelectable: false,
            enableAuditColumns: false,
            columns: [
                {
                    displayName: 'Type',
                    field: 'UpdateType',
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Modified Fields',
                    field: 'ModifiedFields',
                    sortField: 'ModifiedFields',
                    rendererComponent: AuditModifiedFieldComponent,
                    exportFormatter: this.cellFormatterService.auditModifiedFieldsExportFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Date',
                    field: 'ModifiedDate',
                    formatter: this.cellFormatterService.dateTimeUTCFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Modified By',
                    field: 'ModifiedBy',
                    formatter: this.cellFormatterService.userNameFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Urgent',
                    field: 'Urgent',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('Urgent', 'Urgent'),
                    cellClass: AuditClassFactory.createCellClass('urgent'),
                },
                {
                    displayName: 'Animal ID',
                    field: 'AnimalID',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('AnimalID', 'AnimalID'),
                    cellClass: AuditClassFactory.createCellClass('animalid'),
                },
                {
                    displayName: 'Animal Name',
                    field: 'AnimalName',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('AnimalName', 'AnimalName'),
                    cellClass: AuditClassFactory.createCellClass('animalname'),
                },
                {
                    displayName: 'Observation Type',
                    field: 'ObservationType',
                    visible: false,
                    formatter: this.cellFormatterService.createAuditColumnFormatter('ObservationType', 'ObservationType'),
                    cellClass: AuditClassFactory.createCellClass('observationtype'),
                },
                {
                    displayName: 'Date Observed',
                    field: 'DateObserved',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('DateObserved', 'DateObserved', this.cellFormatterService.dateTimeUTCFormatter),
                    cellClass: AuditClassFactory.createCellClass('dateobserved'),
                },
                {
                    displayName: 'Observed By',
                    field: this.isGLP ? 'ObservedByUsername' : 'ResourceName',
                    formatter: this.cellFormatterService.createAuditColumnFormatter(
                        this.isGLP ? 'ObservedByUsername' : 'ResourceName',
                        this.isGLP ? 'ObservedByUsername' : 'ResourceName'
                    ),
                    cellClass: AuditClassFactory.createCellClass('resourcename'),
                },
                {
                    displayName: 'Observation Status',
                    field: 'ObservationStatus',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('ObservationStatus', 'ObservationStatus'),
                    cellClass: AuditClassFactory.createCellClass('observationstatus'),
                },
                {
                    displayName: 'Observation',
                    field: 'Observation',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('Observation', 'Observation'),
                    cellClass: AuditClassFactory.createCellClass('observation'),
                },                
                {
                    displayName: 'Review Date',
                    field: 'ReviewDate',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('ReviewDate', 'ReviewDate', this.cellFormatterService.dateTimeUTCFormatter),
                    cellClass: AuditClassFactory.createCellClass('reviewdate'),
                },
                {
                    displayName: 'Comments',
                    field: 'Comments',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('Comments', 'Comments'),
                    cellClass: AuditClassFactory.createCellClass('comments'),
                },
                {
                    displayName: 'Created By',
                    field: 'CreatedBy',
                    formatter: this.cellFormatterService.userNameFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Created Date',
                    field: 'DateCreated',
                    formatter: this.cellFormatterService.dateTimeUTCFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                }
            ]
        };
    }
}

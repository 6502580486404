import {
    Component
} from '@angular/core';
import { ICellRendererParams } from "ag-grid-community";
import { ICellRendererAngularComp } from "ag-grid-angular";

@Component({
    selector: 'animal-marker-cell',
    template: `
    <animal-marker [markerType]="animal?.cv_PhysicalMarkerType"
                   [marker]="animal?.PhysicalMarker"></animal-marker>
    `,
})
export class AnimalMarkerCellRendererComponent implements ICellRendererAngularComp {
    public animal: any;

    constructor() {
        // Nothing to do
    }

    /**
     * Required by ICellRenderedAngularComp.
     *   Called on cell refresh by ag-grid
     * @param params
     */
    agInit(params: ICellRendererParams) {
        this.animal = params.data;
    }

    /**
     * Required by ICellRenderedAngularComp.
     *   Called on cell refresh by ag-grid
     * @param params
     */
    refresh(params: any): boolean {
        this.animal = params.data;
        return true;
    }
}

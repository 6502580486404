import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';

import { maxSortOrder, sortObjectArrayByProperty } from '../../common/util';

import { LineService } from '../../lines/line.service';
import { VocabularyService } from '../../vocabularies/vocabulary.service';

import { DragulaService } from 'ng2-dragula';
import { Subscription } from 'rxjs';
import { ReasonForChangeService } from '../../common/reason-for-change/reason-for-change.service';
import { Entity, Line, cv_GenotypeAssay } from '@common/types';

@Component({
    selector: 'line-genotype-assay-select',
    templateUrl: './line-genotype-assay-select.component.html',
    styles: [`
                .table { 
                    margin-bottom: 0.25em; 
                }
            `]
})
export class LineGenotypeAssaySelectComponent implements OnChanges, OnInit, OnDestroy {
    @Input() line: Entity<Line>;
    @Input() readonly: boolean;
    @Output() selectLineGenotypeAssay: EventEmitter<any> = new EventEmitter<any>();

    componentSubscription: Subscription;

    constructor(
        private vocabularyService: VocabularyService,
        private lineService: LineService,
        dragulaService: DragulaService,
        private reasonForChangeService: ReasonForChangeService
    ) {
        this.componentSubscription = dragulaService.dropModel().subscribe(({ targetModel }) => {
            this.reorderLineGenotypeAssayOnDropModel(targetModel);
        });
    }

    ngOnChanges(changes: any) {
        if (this.line && changes.line) {
            this.sortLineGenotypeAssays();
        }
    }

    ngOnInit() {
        if (!this.line) {
            this.line = {} as Entity<Line>;
        }
    }

    ngOnDestroy() {
        if (this.componentSubscription) {
            this.componentSubscription.unsubscribe();
        }
    }

    // Handles selection from typeahead.
    selectGenotypeAssay(genotypeAssay: cv_GenotypeAssay) {
        this.addLineGenotypeAssay(genotypeAssay);
        this.selectLineGenotypeAssay.emit(genotypeAssay);
    }

    addLineGenotypeAssay(newAssay: cv_GenotypeAssay) {
        const sortOrder: number = maxSortOrder(this.line.LineGenotypeAssay) + 1;
        if (this.line.C_Line_key > 0) {
            this.reasonForChangeService.markModification([this.line]);
        }
        this.lineService.createLineGenotypeAssay({
            C_Line_key: this.line.C_Line_key,
            C_GenotypeAssay_key: newAssay.C_GenotypeAssay_key,
            SortOrder: sortOrder,
            Version: 0
        });
        this.sortLineGenotypeAssays();
    }

    sortLineGenotypeAssays() {
        this.line.LineGenotypeAssay = sortObjectArrayByProperty(
            this.line.LineGenotypeAssay,
            'SortOrder'
        );
    }

    removeLineGenotypeAssay(assayToRemove: any) {
        for (const lineGenotypeAssay of this.line.LineGenotypeAssay) {
            if (lineGenotypeAssay.C_GenotypeAssay_key === assayToRemove.C_GenotypeAssay_key) {
                if (this.line.C_Line_key > 0) {
                    this.reasonForChangeService.markModification([this.line]);
                }
                this.lineService.deleteLineGenotypeAssay(lineGenotypeAssay);
                break;
            }
        }
    }

    searchGenotypeAssays = (term: string): Promise<any> => {

        const cvName = 'cv_GenotypeAssays';
        const cvField = 'GenotypeAssay';
        const maxResultsCount = 50;

        return this.vocabularyService.getCVByFieldSubstring(
            cvName,
            cvField,
            term,
            maxResultsCount
        ).then((results: any) => {
            // filter by active items
            return results.filter((result: any) => {
                return result.IsActive;
            });
        });
    }

    genotypeAssayFormatter = (value: any) => {
        return value.GenotypeAssay;
    }

    // Handles dragula drops of LineGenotypeAssays: resets SortOrder values to the new order.
    private reorderLineGenotypeAssayOnDropModel(targetModel: any[]) {
        this.line.LineGenotypeAssay.sort((a: any, b: any) => {
            const aIndex = targetModel.findIndex((task: any) => task.C_LineGenotypeAssay_key === a.C_LineGenotypeAssay_key);
            const bIndex = targetModel.findIndex((task: any) => task.C_LineGenotypeAssay_key === b.C_LineGenotypeAssay_key);
            return aIndex - bIndex;
        });

        // dragula has by now synced the model with the new drag order, so
        // we just need to update the SortOrders based on the current order.
        for (let i = 0; i < this.line.LineGenotypeAssay.length; i++) {
            this.line.LineGenotypeAssay[i].SortOrder = i + 1;
        }
    }
}

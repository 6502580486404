import {
    AfterContentInit,
    ChangeDetectorRef,
    Directive,
    ElementRef,
    EventEmitter,
    HostBinding,
    Inject,
    Output,
    Self,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { DragDrop, DropListRef } from '@angular/cdk/drag-drop';
import { BaseDropListDirective } from './base-drop-list.directive';
import { CLIMB_TABLE } from '../table.token';
import { ROW_DROP_LIST } from './drag-drop.token';
import {
    CSS_CLASS_DROP_LIST_ROW_DRAGGING,
    CSS_VAR_GRID_TEMPLATE_COLUMNS,
    CSS_VAR_ROW_DRAG_GRID_TEMPLATE_COLUMNS,
} from '../table.const';
import type { ClimbTable } from '../table.interface';
import type { ContainerDropList, DragDropEvent, ItemDrag } from './drag-drop.interface';


@Directive({
    selector: 'climb-table[climbRowDropList], table[climbTable][climbRowDropList]',
    providers: [
        { provide: ROW_DROP_LIST, useExisting: RowDropListDirective },
    ],
})
export class RowDropListDirective extends BaseDropListDirective<ItemDrag> implements AfterContentInit {
    @Output()
    rowDropped: EventEmitter<DragDropEvent> = new EventEmitter<DragDropEvent>();

    @HostBinding('class')
    private get classes(): Record<string, boolean> {
        return {
            [CSS_CLASS_DROP_LIST_ROW_DRAGGING]: this.dropListRef?.isDragging(),
            ['drop-list-row-receiving']: this.dropListRef?.isReceiving(),
        };
    }

    get table(): HTMLElement {
        return this.elementRef.nativeElement;
    }

    constructor(
        @Inject(DOCUMENT) private readonly document: Document,
        private elementRef: ElementRef,
        @Self() @Inject(CLIMB_TABLE) private readonly parent: ClimbTable<any>,
        private dragDrop: DragDrop,
        cdr: ChangeDetectorRef,
    ) {
        super(cdr);
    }

    ngAfterContentInit(): void {
        this.initDropList(this.dragDrop, this.elementRef);
        this.dropListRef.withOrientation('vertical');
        this.handleEvents(this.dropListRef);
    }

    /** Handles events from the underlying DropListRef. */
    private handleEvents(dropListRef: DropListRef<ContainerDropList>): void {
        dropListRef.beforeStarted.subscribe(() => {
            this.disableUpdatingStickyColumnStyles();

            const gridTemplateColumns = this.table.style.getPropertyValue(CSS_VAR_GRID_TEMPLATE_COLUMNS);
            this.document.body.style.setProperty(CSS_VAR_ROW_DRAG_GRID_TEMPLATE_COLUMNS, gridTemplateColumns);
        });

        dropListRef.dropped.subscribe((event) => {
            this.document.body.style.setProperty(CSS_VAR_ROW_DRAG_GRID_TEMPLATE_COLUMNS, '');
            this.enableUpdatingStickyColumnStyles();

            this.rowDropped.emit({
                previousIndex: event.previousIndex,
                currentIndex: event.currentIndex,
                container: event.container.data,
                item: event.item.data,
                isPointerOverContainer: event.isPointerOverContainer,
                distance: event.distance,
            });

            // Mark for check since all of these events run outside of change
            // detection and we're not guaranteed for something else to have triggered it.
            this.cdr.markForCheck();
        });
    }

    /**
     * It is necessary to disable updating the styles of Sticky Columns
     * before drag&drop rows. Otherwise, sticky columns move to the left
     * which breaks the row.
     */
    private fixedLayout: boolean = this.parent.fixedLayout;
    private disableUpdatingStickyColumnStyles(): void {
        this.fixedLayout = this.parent.fixedLayout;
        this.parent.fixedLayout = true;
    }
    private enableUpdatingStickyColumnStyles(): void {
        this.parent.fixedLayout = this.fixedLayout;
    }
}

<!-- Template for animal items -->
<ng-template #animalSelector
             let-material="material">
    <div class="form-check" *ngIf="material">
        <label class="form-check-label">
            <input type="checkbox"
                   class="form-check-input"
                   name="birthMaterial"
                   [value]="material.C_Material_key"
                   [checked]="isBirthMaterial(material.C_Material_key)"
                   [disabled]="facetPrivilege !== 'ReadWrite'"
                   (change)="onBirthMaterialChange(material.C_Material_key)">
            {{material.Animal?.AnimalName}}
        </label>
    </div>
</ng-template>

<ng-container *ngIf="mating">
    <table class="table">
        <thead>
            <tr>
                <th>Sire(s)</th>
                <th>Genotype(s)</th>
                <th>Dam(s)</th>
                <th>Genotype(s)</th>
                <th>Samples</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <!-- Sires -->
                <td>
                    <ng-container *ngFor="let material of mating?.MaterialPool?.MaterialPoolMaterial | housingUniqueMaleMaterials
                         | orderBy:'Animal.AnimalNameSortable'">
                        <ng-template [ngTemplateOutlet]="animalSelector"
                                     [ngTemplateOutletContext]="{material: material}"></ng-template>
                    </ng-container>
                </td>

                <!-- Genotype(s) -->
                <td>
                    <div class="row" *ngFor="let materialPoolMaterial of mating?.MaterialPool?.MaterialPoolMaterial | housingUniqueMaleMaterials">
                        <div class="col-md-6 margin-top-4">
                            <ng-template [ngIf]="materialPoolMaterial.Animal?.Genotype.length > 0">
                                <span>{{ materialPoolMaterial.Animal?.Genotype | formatGenotypeNames}}</span>
                            </ng-template>
                        </div>
                    </div>
                </td>

                <!-- Dams -->
                <td>
                    <ng-container *ngFor="let material of mating?.MaterialPool?.MaterialPoolMaterial | housingUniqueFemaleMaterials
                          | orderBy:'Animal.AnimalNameSortable'">
                        <ng-template [ngTemplateOutlet]="animalSelector"
                                     [ngTemplateOutletContext]="{material: material}"></ng-template>
                    </ng-container>
                </td>

                <!-- Genotype(s) -->
                <td>
                    <div class="row" *ngFor="let materialPoolMaterial of mating?.MaterialPool?.MaterialPoolMaterial | housingUniqueFemaleMaterials">
                        <div class="col-md-6 margin-top-4">
                            <ng-template [ngIf]="materialPoolMaterial.Animal?.Genotype.length > 0">
                                <span>{{ materialPoolMaterial.Animal?.Genotype | formatGenotypeNames}}</span>
                            </ng-template>
                        </div>
                    </div>
                </td>

                <!-- Samples -->
                <td>
                    <div *ngFor="let materialPoolMaterial of mating?.MaterialPool?.MaterialPoolMaterial | sampleFilter
                         | orderBy:'Material.Sample.SampleNameSortable'">
                        {{materialPoolMaterial.Material?.Sample?.SampleName}}
                    </div>
                </td>
            </tr>
        </tbody>
    </table>

    <small class="form-text text-muted"
       *ngIf="facetPrivilege === 'ReadWrite' && (mating?.MaterialPool?.MaterialPoolMaterial | notEmpty)">
        Select the sires and dams for this birth.
    </small>
</ng-container>

<div class="form-control-plaintext"
     *ngIf="facetPrivilege === 'ReadWrite'">
    <a (click)="pasteMaterials()" class="paste-link">
        <i class="fa fa-paste" title="Paste animals and samples"></i>
    </a>
</div>

<table class="table table-borderless"
       droppable
       (drop)="onDropMaterials($event)"
       [dropDisabled]="facetPrivilege !== 'ReadWrite'"
       [attr.title]="facetPrivilege === 'ReadWrite' ? 'Drop animals and samples here' : ''">
    <thead>
        <tr>
            <th>
                <div class="row">
                    <div class="col-md-4">Sire(s)</div>
                    <div class="col-md-4">Date of Birth</div>
                    <div class="col-md-4">Genotype(s)</div>
                </div>
            </th>
            <th>
                <div class="row">
                    <div class="col-md-4">Dam(s)</div>
                    <div class="col-md-4">Date of Birth</div>
                    <div class="col-md-4">Genotype(s)</div>
                </div>
            </th>
            <th>
                Sample(s)
            </th>
        </tr>
    </thead>

    <tfoot *ngIf="facetPrivilege === 'ReadWrite'">
        <tr>
            <td>
                <climb-indirect-typeahead [search]="getFilteredMales"
                                          [resultFormatter]="animalNameFormatter"
                                          [exactMatchFunction]="isAnimalExactMatch"
                                          [placeholder]="'Add sire&hellip;'"
                                          (selectItem)="selectAnimal($event)"></climb-indirect-typeahead>
            </td>
            <td>
                <climb-indirect-typeahead [search]="getFilteredFemales"
                                          [resultFormatter]="animalNameFormatter"
                                          [exactMatchFunction]="isAnimalExactMatch"
                                          [placeholder]="'Add dam&hellip;'"
                                          (selectItem)="selectAnimal($event)"></climb-indirect-typeahead>
            </td>
            <td>
                <climb-indirect-typeahead [search]="getFilteredSamples"
                                          [resultFormatter]="sampleNameFormatter"
                                          [exactMatchFunction]="isSampleExactMatch"
                                          [placeholder]="'Add sample&hellip;'"
                                          (selectItem)="selectSample($event)"></climb-indirect-typeahead>
            </td>
        </tr>
        <tr *ngIf="activeRemoveCheckbox">
            <td colspan="3">
                <label>
                    <input type="checkbox"
                           [(ngModel)]="removeAnimalsFromExistingMatings" />
                    Remove new animals from existing matings
                </label>
            </td>
        </tr>
    </tfoot>

    <tbody>
        <tr>
            <td>
                <div class="row" *ngFor="let materialPoolMaterial of materialPoolMaterials | housingSexFilter:'Male'">
                    <div class="col-md-4">
                        <a (click)="removeMaterial(materialPoolMaterial)"
                           *ngIf="facetPrivilege === 'ReadWrite'">
                            <i class="fa fa-remove remove-item" title="Remove sire"></i>
                        </a>
                        {{materialPoolMaterial.Material?.Animal?.AnimalName}}
                    </div>
                    <div class="col-md-4">
                        <ng-template [ngIf]="materialPoolMaterial.Material?.Animal?.DateBorn">
                            <span>{{ materialPoolMaterial.Material?.Animal?.DateBorn | formatShortDate}}</span>
                        </ng-template>
                    </div>
                    <div class="col-md-4">
                        <ng-template [ngIf]="materialPoolMaterial.Material?.Animal?.Genotype.length > 0">
                            <span>{{ materialPoolMaterial.Material?.Animal?.Genotype | formatGenotypeNames}}</span>
                        </ng-template>
                    </div>
                </div>
            </td>
            <td>
                <div class="row" *ngFor="let materialPoolMaterial of materialPoolMaterials | housingSexFilter:'Female'">
                    <div class="col-md-4">
                        <a (click)="removeMaterial(materialPoolMaterial)" *ngIf="facetPrivilege === 'ReadWrite'">
                            <i class="fa fa-remove remove-item" title="Remove dam"></i>
                        </a>
                        {{materialPoolMaterial.Material?.Animal?.AnimalName}}
                    </div>
                    <div class="col-md-4">
                        <ng-template [ngIf]="materialPoolMaterial.Material?.Animal?.DateBorn">
                            <span>{{ materialPoolMaterial.Material?.Animal?.DateBorn | formatShortDate}}</span>
                        </ng-template>
                    </div>
                    <div class="col-md-4">
                        <ng-template [ngIf]="materialPoolMaterial.Material?.Animal?.Genotype.length > 0">
                            <span>{{ materialPoolMaterial.Material?.Animal?.Genotype | formatGenotypeNames}}</span>
                        </ng-template>
                    </div>
                </div>
            </td>
            <td>
                <div *ngFor="let materialPoolMaterial of materialPoolMaterials | sampleFilter">
                    <a (click)="deleteMaterial(materialPoolMaterial)"
                       *ngIf="facetPrivilege === 'ReadWrite'">
                        <i class="fa fa-remove remove-item" title="Delete sample"></i>
                    </a>
                    {{materialPoolMaterial.Material?.Sample?.SampleName}}
                </div>
            </td>
        </tr>
    </tbody>
</table>

import type { Animal } from './animal.interface';
import type { Mating } from './mating.interface';

export interface MatingPlugDate {
    C_DamMaterial_key: number | null;
    C_MatingMaterialPool_key: number;
    C_MatingPlugDate_key: number;
    C_Workgroup_key: number;
    CreatedBy: string;
    Dam: Animal;
    DateCreated: Date | null;
    DateModified: Date | null;
    DatePlug: Date | null;
    Mating: Mating;
    ModifiedBy: string;
    Version: number;
}

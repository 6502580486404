import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output
} from '@angular/core';

import { AnimalService } from '../../../animals/services/animal.service';
import { CopyBufferService } from '../../../common/services/copy-buffer.service';
import { MaterialService } from '../../../services/material.service';
import { MaterialPoolService } from '../../../services/material-pool.service';

import { DroppableEvent } from '../../../common/droppable-event';
import { getSafeProp, lowerCaseCompare, uniqueArray } from '../../../common/util';
import { ConfirmService } from '../../../common/confirm';

@Component({
    selector: 'housing-animal-table',
    templateUrl: './housing-animal-table.component.html'
})
export class HousingAnimalTableComponent {
    @Input() materialPool: any;
    @Input() materialPoolMaterials: any[];
    @Input() facetPrivilege: string;

    @Output() materialPoolMaterialsChange: EventEmitter<any[]> = new EventEmitter<any[]>();

    constructor(
        private animalService: AnimalService,
        private copyBufferService: CopyBufferService,
        private materialService: MaterialService,
        private materialPoolService: MaterialPoolService,
        private confirmService: ConfirmService
    ) {}

    // Materials
    onMaterialPoolMaterialsChange() {
        this.materialPoolMaterialsChange.emit(this.materialPoolMaterials);
        this.animalService.setHousingEndState(this.materialPoolMaterials, this.materialPoolMaterials[0].MaterialPool);
    }

    selectAnimal(animal: any): Promise<any> {
        return this.materialService.getMaterial(animal.C_Material_key).then((material) => {
            this.addMaterial(animal);
            return animal;
        });
    }

    private addMaterial(material: any) {
        const initialValues = {
            C_MaterialPool_key: this.materialPool.C_MaterialPool_key,
            C_Material_key: material.C_Material_key
        };
        this.materialPoolService.createMaterialPoolMaterial(initialValues);

        this.onMaterialPoolMaterialsChange();
    }

    removeMaterial(material: any) {
        material.DateOut = Date.now();

        this.onMaterialPoolMaterialsChange();
    }


    // Search functions for indirect typeahead
    getFilteredFemales = (query: string): Promise<any[]> => {
        return this.animalService.getFilteredCurrentAnimalsBySex(query, 'Female', 500)
            .then((data) => {
                return data;
            });
    }

    getFilteredMales = (query: string): Promise<any[]> => {
        return this.animalService.getFilteredCurrentAnimalsBySex(query, 'Male', 500)
            .then((data) => {
                return data;
            });
    }

    getFilteredOtherSex = (query: string): Promise<any[]> => {
        return this.animalService.getFilteredCurrentAnimalsBySex(query, null, 500)
            .then((data) => {
                return data;
            });
    }


    // Drop, Paste
    onDropMaterials(event: DroppableEvent) {
        const newAnimals = this.animalService.draggedAnimals;

        this.processAddedAnimals(newAnimals);

        this.animalService.draggedAnimals = [];
    }

    pasteAnimals() {
        if (this.copyBufferService.hasAnimals()) {
            const newAnimals = this.copyBufferService.paste();

            this.processAddedAnimals(newAnimals);
        }
    }

    private processAddedAnimals(animals: any[]) {
        if (this.facetPrivilege === 'ReadWrite') {
            const animalNames: any[] = [];
            for (const animal of animals) {
                if (animal.cv_AnimalStatus && animal.cv_AnimalStatus.IsExitStatus) {
                    animalNames.push(animal.AnimalName);
                }
            }
            const names = uniqueArray(animalNames).join(', ');
            if (names) {
                let message = `${animalNames.length} of these animals have an end-state status. You must change the animal's statuses to a non-end-state option.`;
                if (message.length === 1) {
                    message = message.replace("have", "has");
                    message = message.replace("statuses", "status");
                }
                this.confirmService.confirm(
                    {
                        title: "Animal's status",
                        message,
                        yesButtonText: 'Skip Animals',
                        noButtonText: 'Cancel',
                        titleDetails: 'Animals: ',
                        details: [
                            names
                        ]
                    }
                ).then(
                    () => {
                        // Yes                           
                        this.addAnimals(animals);
                    },
                    () => {
                        // No
                    }); 
            } else {
                this.addAnimals(animals);
            }
        }
    }

    addAnimals(animals: any[]) {
        for (const animal of animals) {
            if (!(animal.cv_AnimalStatus && animal.cv_AnimalStatus.IsExitStatus)) {
                const initialValues = {
                    C_MaterialPool_key: this.materialPool.C_MaterialPool_key,
                    C_Material_key: animal.C_Material_key
                };

                const newMaterialPoolMaterial =
                    this.materialPoolService.createMaterialPoolMaterial(initialValues);
                if (newMaterialPoolMaterial) {
                    this.materialPool.MaterialPoolMaterial.unshift(newMaterialPoolMaterial);
                }
                this.onMaterialPoolMaterialsChange();
            }            
        }
    }

    /**
     * Alter exact match behavior to include matching on MicrochipIdentifer
     */
    isAnimalExactMatch = (data: any[], term: string) => {
        if (term &&
            data &&
            data.length === 1
        ) {
            return lowerCaseCompare(data[0].AnimalName, term) ||
                lowerCaseCompare(getSafeProp(data[0], 'Material.MicrochipIdentifier'), term);
        }
        return false;
    }

    // <select> formatters
    animalNameFormatter = (value: any) => {
        return value.AnimalName;
    }
}

import type { Animal } from './animal.interface';
import type { AnimalOrder } from './animal-order.interface';
import type { Institution } from './institution.interface';
import type { Job } from './job.interface';
import type { JobOrder } from './job-order.interface';
import type { OrderLocation } from './order-location.interface';
import type { SampleOrder } from './sample-order.interface';
import type { Site } from './site.interface';
import type { StoredFileMap } from './stored-file-map.interface';
import type { cv_MaterialOrigin } from './cv-material-origin.interface';
import type { cv_MaterialType } from './cv-material-type.interface';
import type { cv_QuarantineFacility } from './cv-quarantine-facility.interface';
import type { cv_Taxon } from './cv-taxon.interface';

export interface Order {
    Animal: Animal[];
    AnimalCount: number | null;
    AnimalHousing: string;
    AnimalOrder: AnimalOrder[];
    C_Institution_key: number | null;
    C_Job_key: number | null;
    C_MaterialOrigin_key: number | null;
    C_MaterialType_key: number | null;
    C_Order_key: number;
    C_QuarantineFacility_key: number | null;
    C_Site_key: number | null;
    C_Taxon_key: number | null;
    C_Workgroup_key: number;
    Cites: string;
    Comments: string;
    CreatedBy: string;
    CurrentLocationPath: string;
    DateCreated: Date;
    DateModified: Date;
    DateOrdered: Date | null;
    DateProjectedReceipt: Date | null;
    DateReceived: Date | null;
    Institution: Institution;
    InvoiceNumber: string;
    IsAllShipmentContainersEmpty: boolean;
    IsBeddingAvailable: boolean;
    IsFoodAvailable: boolean;
    IsWaterAvailable: boolean;
    Job: Job;
    JobOrder: JobOrder[];
    JobOrderJobSortable: string;
    SampleOrderSampleConditionSortable: string;
    ModifiedBy: string;
    OrderID: string;
    OrderLocation: OrderLocation[];
    ReasonForChange: string;
    SampleOrder: SampleOrder[];
    Site: Site;
    StoredFileMap: StoredFileMap[];
    VendorOrderNumber: string;
    VendorRoomId: string;
    Version: number;
    cv_MaterialOrigin: cv_MaterialOrigin;
    cv_MaterialType: cv_MaterialType;
    cv_QuarantineFacility: cv_QuarantineFacility;
    cv_Taxon: cv_Taxon;
}

import {
    Injectable,
} from '@angular/core';

import {
    NgbModal,
} from '@ng-bootstrap/ng-bootstrap';

import { TaskOutputSelectModalComponent } from './task-output-select-modal.component';

/**
 * Service to view the task-output-select modal component.
 */
@Injectable()
export class ViewTaskOutputSelectComponentService {

    constructor(
        private modalService: NgbModal
    ) {
        //
    }

    openComponent(): Promise<any> {
        return this.modalService.open(TaskOutputSelectModalComponent)
            .result.then((selectedOutput: any) => {
                // Closed
                if (selectedOutput) {
                    return selectedOutput;
                } else {
                    return null;
                }
            }, (reason) => {
                // Dismissed
                return null;
            });
    }
}

<div class="detail-form-wrapper">

    <div class="detail-header">
        <h3>Plate</h3>

        <pager-toolbar [itemName]="'line'"
                       [logTag]="COMPONENT_LOG_TAG"
                       [first]="pageState.isFirst"
                       [last]="pageState.isLast"
                       (previous)="previousClicked($event)"
                       (next)="nextClicked($event)"></pager-toolbar>

        <detail-toolbar [facetPrivilege]="facet.Privilege"
                        [logTag]="COMPONENT_LOG_TAG"
                        (exit)="exitClicked($event)">

            <div class="btn-group" role="group" aria-label="Edit actions" additional-groups>
                <div class="btn-group" role="group">
                    <button type="button"
                            class="btn btn-secondary dropdown-toggle"
                            [disabled]="loading"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false">
                        <i class="fa fa-download" aria-hidden="true" title="Export"></i>
                        <span class="gridster-d-sm-none gridster-d-md-inline-block">Export</span>
                        <span class="sr-only">Export</span>
                    </button>
                    <div class="dropdown-menu">
                        <a (click)="exportDetailCsv()"
                           class="dropdown-item"
                           [appInsightsClick]="'click-plate-export-csv'">
                            CSV Format
                        </a>
                        <a (click)="exportDetailTyx()"
                           class="dropdown-item"
                           [hidden]="true"
                           [appInsightsClick]="'click-plate-export-tyx'">
                            Transnetyx Format
                        </a>
                    </div>
                </div>
                <button type="button"
                        class="btn btn-secondary"
                        (click)="printDetail()"
                        [appInsightsClick]="'click-plate-print'"
                        [disabled]="loading">
                    <i class="fa fa-print" aria-hidden="true" title="Print"></i>
                    <span class="gridster-d-sm-none gridster-d-md-inline-block">Print</span>
                    <span class="sr-only">Print</span>
                </button>
            </div>
        </detail-toolbar>
    </div>

    <form name="plateForm" id="plateForm"
          class="detail-form"
          *ngIf="plate"
          #plateForm="ngForm"
          droppable
          [highlightedElement]="plateLocal"
          [dropDisabled]="facet.Privilege !== 'ReadWrite'"
          (drop)="onDropMaterials()">

        <fieldset [disabled]="facet.Privilege==='ReadOnly'">

            <div class="form-group row">
                <label class="col-md-2 col-form-label">ID</label>
                <div class="col-md-10">
                    <input type="text" [(ngModel)]="plate.PlateID"
                           name="plateId"
                           class="form-control  input-medium plate-detail-id"
                           required />
                </div>
            </div>

            <div class="form-group row">
                <label class="col-md-2 col-form-label">External ID</label>
                <div class="col-md-10">
                    <input type="text" [(ngModel)]="plate.ExternalPlateID"
                           name="externalPlateId"
                           class="form-control  input-medium plate-detail-externalID" />
                    <small class="form-text text-muted">
                        E.g., Transnetyx well-plate number.
                    </small>
                </div>
            </div>

            <div class="form-group row">
                <label class="col-md-2 col-form-label">Type</label>
                <div class="col-md-10">
                    <active-vocab-select [(model)]="plate.C_PlateType_key"
                                         class="plate-detail-type"
                                         (modelChange)="typeChanged()"
                                         [vocabChoices]="plateTypes"
                                         [keyFormatter]="plateTypeKeyFormatter"
                                         [optionFormatter]="plateTypeFormatter"
                                         [required]="true"></active-vocab-select>
                </div>
            </div>

            <div class="form-group row">
                <label class="col-md-2 col-form-label">{{'Job' | translate}}</label>
                <div class="col-md-9">
                    <job-select class="plate-detail-job" [(model)]="plate.C_Job_key"></job-select>
                </div>
            </div>

            <div class="form-group row">
                <label class="col-md-2 col-form-label">Status</label>
                <div class="col-md-10">
                    <active-vocab-select [(model)]="plate.C_PlateStatus_key"
                                         class="plate-detail-status"
                                         [vocabChoices]="plateStatuses"
                                         [keyFormatter]="plateStatusKeyFormatter"
                                         [optionFormatter]="plateStatusFormatter"
                                         [required]="true"></active-vocab-select>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12 d-flex align-items-center mt-3 mb-1">
                    <h4 class="my-0 mr-2">Plate Map</h4>
                    <div class="btn-group">
                        <button type="button" class="btn btn-secondary dropdown-toggle"
                                data-toggle="dropdown"
                                aria-haspopup="true" aria-expanded="false"
                                [disabled]="!hasPlateMaterials()">
                            <i class="fa fa-cog" title="Tools"></i>
                        </button>
                        <div class="dropdown-menu">
                            <a class="dropdown-item" (click)="selectAllPositions()">Select All</a>
                            <a class="dropdown-item" (click)="selectNoPositions()">Select None</a>
                            <div class="dropdown-divider" role="separator"></div>
                            <a
                                class="dropdown-item"
                                [ngClass]="{'disabled': selectedPositions().length === 0}"
                                (click)="skipSelectedPositions()"
                            >
                                Skip Selected
                            </a>
                            <a
                                class="dropdown-item"
                                [ngClass]="{'disabled': selectedPositions().length === 0}"
                                (click)="clearSelectedPositions()"
                            >
                                Clear Selected
                            </a>
                            <a
                                class="dropdown-item"
                                [ngClass]="{'disabled': selectedPositions().length === 0}"
                                (click)="clearAndReorderSelectedPositions()"
                            >
                                Clear and Reorder Wells
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12" *ngIf="hasPlateMaterials()">
                    <small>Wells selected: {{selectedPositions().length}}</small>
                </div>
            </div>

            <div id="{{printPreviewId}}" #plateLocal>

                <!-- ID used for print preview -->
                <h2 class="d-none"><b>ID:&nbsp;</b>{{plate.PlateID}}</h2>
                <h2 class="d-none"><b>External ID:&nbsp;</b>{{plate.ExternalPlateID}}</h2>
                <h2 class="d-none"><b>{{'Job' | translate}}:&nbsp;</b>{{plate.Job?.JobID}}</h2>
                <table class="material-pool-table" *ngIf="plate.Rows && plate.Columns">
                    <tr>
                        <td></td>
                        <td class="plate-detail-columns" *ngFor="let column of plate.Columns">
                            <div class="a_all_cell" id="allColumn">
                                <input (change)="onSelectColumn($event.target.checked, column.Column)" type="checkbox" class="checkbox-lg mt-1">
                            </div>
                        </td>
                    </tr>
                    <tr class="plate-detail-rows" *ngFor="let row of plate.Rows">
                        <td>
                            <div class="a_all_cell" id="allRow">
                                <input type="checkbox" (change)="onSelectRow($event.target.checked, row.Row)" class="checkbox-lg mt-1">
                            </div>
                        </td>
                        <td class="plate-detail-cells" *ngFor="let column of plate.Columns">

                            <!-- Loop through PlateMaterials, which  -->
                            <div *ngFor="let material of plate.PlateMaterial | platePositionFilter: column.Column: row.Row">

                                <div class="well-position"
                                     id="{{material.C_PlatePosition_key}}"
                                     [ngClass]="{'skip-position': material.IsActive === 0, 'selected-position': material.IsSelected === true}">

                                    <div class="clearfix">
                                        <div class="float-left d-print-none">
                                            <input type="checkbox" class="checkbox-lg mt-1"
                                                   [(ngModel)]="material.IsSelected"
                                                   [ngModelOptions]="{standalone: true}">
                                        </div>
                                        <ul class="float-left" *ngIf="facet.Privilege==='ReadWrite'">
                                            <li class="dropdown">
                                                <a class="dropdown-toggle"
                                                   data-toggle="dropdown"
                                                   role="button"
                                                   aria-haspopup="true"
                                                   aria-expanded="false">
                                                    <span class="position-name">{{material.PlatePosition?.PositionName}}</span>
                                                </a>
                                                <div class="dropdown-menu d-print-none" style="min-width: 60px !important;">
                                                    <a class="dropdown-item" (click)="clearPosition(material)">Clear</a>
                                                    <a class="dropdown-item" (click)="skipPosition(material)">Skip</a>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>

                                    <div class="material-label" *ngIf="material?.Material?.Sample"
                                         title="{{material.Material?.Sample?.SampleName}}">
                                        {{material.Material?.Sample?.SampleName}}
                                    </div>
                                    <div class="material-label" *ngIf="material?.Material?.Animal"
                                         title="{{material.Material?.Animal?.AnimalName}}">
                                        {{material.Material?.Animal?.AnimalName}}
                                    </div>
                                    <div class="material-label" *ngIf="material?.Material?.Line"
                                         title="{{material.Material?.Line?.StockNumber}}">
                                        <em>{{material.Material?.Line?.StockNumber}}</em>
                                    </div>

                                </div>
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
        </fieldset>
    </form>

</div>

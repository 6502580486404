<climb-auth-container>
    <div class="lines">
        <label>CLIMB.Web:</label>
        <span>
            {{ webBuildNumber$ | async }}
            <span *ngIf="cachedWebBuildNumber !== (webBuildNumber$ | async)" class="current-build-number">
                {{ cachedWebBuildNumber }} is currently in use
            </span>
        </span>

        <label>CLIMB.Data:</label>
        <span>{{ dataBuildNumber$ | async }}</span>

        <label>CLIMB.Admin:</label>
        <span>{{ adminBuildNumber$ | async }}</span>
    </div>

    <button climbButton
            variant="primary"
            size="lg"
            (click)="goBack()">
        Return to Climb
    </button>
</climb-auth-container>

<div class="pedigree-parent">
    <div class="pedigree-label dropdown">
        <i *ngIf="animal.Sex"
           class="fa"
           [ngClass]="{'fa-venus': animal.Sex == 'Female', 'fa-mars': animal.Sex === 'Male'}"
           title="{{animal.Sex}}"></i>

        <a class="dropdown-toggle"
           data-toggle="dropdown"
           role="button"
           aria-haspopup="true"
           aria-expanded="false"
           title="{{animal.MaterialName}}">
            {{animal.MaterialName}}
            <div *ngFor="let genotype of animal.Genotypes">
                {{genotype.Assay}} {{genotype.Symbol}}
            </div>
        </a>

        <div class="dropdown-menu pedigree-details">
            <div class="dropdown-item">
                <table class="table table-sm">
                    <tbody>
                        <tr>
                            <th>{{'Line' | translate}}</th>
                            <td class="pedigree-detail">{{animal.Line}}</td>
                        </tr>
                        <tr>
                            <th>Sex</th>
                            <td class="pedigree-detail">{{animal.Sex}}</td>
                        </tr>
                        <tr>
                            <th>Birth Date</th>
                            <td class="pedigree-detail">{{animal.DateBorn | formatShortDate}}</td>
                        </tr>
                        <tr>
                            <th>Mating ID</th>
                            <td class="pedigree-detail">{{animal.MatingID}}</td>
                        </tr>
                        <tr>
                            <th>Housing ID</th>
                            <td class="pedigree-detail">{{animal.HousingID}}</td>
                        </tr>
                        <tr>
                            <th>Genotype</th>
                            <td class="pedigree-detail">
                                <div class="text-nowrap" *ngFor="let genotype of animal.Genotypes">
                                    {{genotype.Assay}} {{genotype.Symbol}}
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

    <div class="branch"
         *ngIf="animal.ParentAnimals && animal.ParentAnimals.length > 0">
        <div class="entry"
             [ngClass]="animal.ParentAnimals.length === 1 ? 'sole-sibling' : ''"
             *ngFor="let ParentAnimal of animal.ParentAnimals">
            <pedigree-node [animal]="ParentAnimal"></pedigree-node>
        </div>
    </div>

</div>

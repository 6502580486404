import type { Study } from './study.interface';
import type { StudyCharacteristic } from './study-characteristic.interface';

export interface StudyCharacteristicInstance {
    C_EnumerationItem_key: number | null;
    C_StudyCharacteristicInstance_key: number;
    C_StudyCharacteristic_key: number;
    C_Study_key: number;
    C_Workgroup_key: number;
    CharacteristicName: string;
    CharacteristicValue: string;
    CreatedBy: string;
    DateCreated: Date;
    DateEnd: Date | null;
    DateModified: Date;
    DateStart: Date | null;
    Description: string;
    ModifiedBy: string;
    Study: Study;
    StudyCharacteristic: StudyCharacteristic;
    Version: number;
}

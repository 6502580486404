import { delay } from 'rxjs/operators';
import {
    AfterContentInit,
    ChangeDetectorRef,
    Directive,
    ElementRef,
    EventEmitter,
    HostBinding,
    Inject,
    OnDestroy,
    Output,
    Self,
} from '@angular/core';
import { DragDrop, DropListRef } from '@angular/cdk/drag-drop';
import type { BaseRowDef } from '@angular/cdk/table';
import { BaseDropListDirective } from './base-drop-list.directive';
import { CLIMB_TABLE } from '../table.token';
import { HEADER_DROP_LIST } from './drag-drop.token';
import { getCellVariableName, moveItemInArray } from './drag-drop.util';
import type { ContainerDropList, DragDropEvent, HeaderDrag } from './drag-drop.interface';
import type { ClimbColumnDef, ClimbTable, ColumnId } from '../table.interface';


@Directive({
    selector: 'climb-table, table[climbTable]',
    providers: [
        { provide: HEADER_DROP_LIST, useExisting: HeaderDropListDirective },
    ],
})
export class HeaderDropListDirective extends BaseDropListDirective<HeaderDrag> implements AfterContentInit, OnDestroy {
    @Output()
    headerDropped: EventEmitter<DragDropEvent<HeaderDrag>> = new EventEmitter<DragDropEvent<HeaderDrag>>();

    @HostBinding('class')
    private get classes(): Record<string, boolean> {
        return {
            ['drop-list-header-dragging']: this.dropListRef?.isDragging(),
            ['drop-list-header-receiving']: this.dropListRef?.isReceiving(),
        };
    }

    constructor(
        private elementRef: ElementRef,
        @Self() @Inject(CLIMB_TABLE) private readonly parent: ClimbTable<any>,
        private dragDrop: DragDrop,
        cdr: ChangeDetectorRef,
    ) {
        super(cdr);
    }

    ngAfterContentInit(): void {
        const hasDraggable = Array
            .from(this.parent.getColumnDefsMap().values())
            .some((columnDef: ClimbColumnDef) => columnDef.draggable);

        if (hasDraggable) {
            this.initDropList(this.dragDrop, this.elementRef);
            this.dropListRef.withOrientation('horizontal');
            this.handleEvents(this.dropListRef);
        }
    }

    /** Handles events from the underlying DropListRef. */
    private handleEvents(dropListRef: DropListRef<ContainerDropList>): void {
        dropListRef.sorted.pipe(
            // Wait for drop-list-ref.ts to set styles to the drag items.
            // It can be removed from the angular CDK 14.
            delay(0),
        ).subscribe((v) => {
            this.setTransformStyle();
        });

        dropListRef.dropped.subscribe((event) => {
            this.setTransformStyle();
            const columns: ClimbColumnDef[] = this.parent.getVisibleColumnDefs();
            const draggedColumns: ColumnId[] = this.getSortedItems().map((item) => item.columnId);

            const currentIndex = columns.findIndex((item) => item.columnId === draggedColumns[event.currentIndex]);
            const previousIndex = columns.findIndex((item) => item.columnId === draggedColumns[event.previousIndex]);
            moveItemInArray(columns, previousIndex, currentIndex);

            const columnIds: ColumnId[] = columns.map((item) => item.columnId);
            const setColumnIds = (rowDef: BaseRowDef) => rowDef.columns = columnIds;

            this.parent._contentHeaderRowDefs.forEach(setColumnIds);
            this.parent._contentFooterRowDefs.forEach(setColumnIds);
            this.parent._contentRowDefs.forEach(setColumnIds);

            this.headerDropped.emit({
                previousIndex,
                currentIndex,
                container: event.container.data,
                item: event.item.data,
                isPointerOverContainer: event.isPointerOverContainer,
                distance: event.distance,
            });

            // Mark for check since all of these events run outside of change
            // detection and we're not guaranteed for something else to have triggered it.
            this.cdr.markForCheck();
        });
    }

    private setTransformStyle(): void {
        this.unsortedItems.forEach((item) => {
            const cssTransform = item.dragRef.getVisibleElement().style.transform;
            const cellVar = getCellVariableName(item.columnId);
            this.elementRef.nativeElement.style.setProperty(cellVar, cssTransform);
        });
    }
}

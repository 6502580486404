import { TaskType } from './../models/task-type.enum';
import { Pipe, PipeTransform } from '@angular/core';

import { notEmpty, getSafeProp } from '../../common/util';

/**
 * Returns Animals from a list of Materials
 */
@Pipe({ 
    name: 'taskFilter',
    pure: false
})
export class TaskFilterPipe implements PipeTransform {

    transform(items: any[], taskType: TaskType): any[] {
        let filtered = [];

        if (notEmpty(items)) {
            filtered = items.filter((item) => {
                const itemTaskType = getSafeProp(
                    item, 'TaskInstance.WorkflowTask.cv_TaskType.TaskType'
                );
                return itemTaskType === taskType;
            });
        }

        return filtered;
    }
}

import { pathStringToArray } from './private/path-string-to-array';

/**
 * Rudimentary safe-access, as you cannot access a property of null
 * @param obj
 * @param path
 */
export function getSafeProp<Result = any>(obj: Record<string, any>, path: string): Result | undefined {
    const paths = pathStringToArray(path);
    return paths.reduce((o, p) => o ? o[p] : undefined, obj);
}

<div class="detail-form-wrapper">
    <div class="detail-header">
        <pager-toolbar [itemName]="'job' | translate"
                       [logTag]="COMPONENT_LOG_TAG"
                       [first]="pageState.isFirst"
                       [last]="pageState.isLast"
                       (previous)="previousClicked($event)"
                       (next)="nextClicked($event)">
        </pager-toolbar>

        <detail-toolbar [facetPrivilege]="facet.Privilege"
                        [logTag]="COMPONENT_LOG_TAG"
                        (exit)="exitClicked($event)">

            <ng-container additional-groups>
                <div class="btn-group"
                     role="group"
                     aria-label="Edit actions">

                    <!-- Enclose Notes in btn-group b/c NG component tag messes up Bootstrap -->
                    <div class="btn-group" view-notes-button
                        [readonly]="facet.Privilege === 'ReadOnly'"
                        [pkName]="'C_Job_key'"
                        [pkValue]="job.C_Job_key"
                        [objectName]="job.JobID"
                        [objectType]="'Job' | translate"
                        [isObjectNew]="job | isNew"
                        appInsightsClick="click-notes-from-job">
                    </div>

                    <button type="button"
                            class="btn btn-secondary gridster-d-sm-none gridster-d-md-inline-block"
                            (click)="copyJob()"
                            [disabled]="loading"
                            [appInsightsClick]="'click-copy-job'"
                            *ngIf="readwrite">
                        <i class="fa fa-copy" aria-hidden="true"></i>
                        Copy
                    </button>
                    <button type="button"
                            class="btn btn-secondary gridster-d-sm-none gridster-d-md-inline-block"
                            (click)='exportJob()'
                            [disabled]="loading"
                            [appInsightsClick]="'click-job-export'"
                            title="Export to CSV">
                        <i class="fa fa-download" aria-hidden="true"></i>
                        Export
                    </button>
                    <button type="button"
                            class="btn btn-secondary gridster-d-sm-none gridster-d-md-inline-block"
                            (click)="printJob()"
                            [disabled]="loading"
                            [appInsightsClick]="'click-job-print'">
                        <i class="fa fa-print" aria-hidden="true"></i>
                        Print
                    </button>
                    <button type="button"
                            class="btn btn-secondary gridster-d-sm-none gridster-d-md-inline-block"
                            (click)="viewAuditReport()"
                            [appInsightsClick]="'click-job-audit'">
                        <i class="fa fa-flag" aria-hidden="true"></i>
                        Audit
                    </button>
                    <button type="button"
                            class="btn btn-secondary gridster-d-sm-none gridster-d-md-inline-block"
                            (click)="newMessage()"
                            title="Send {{'job' | translate | lowercase}} in message"
                            [appInsightsClick]="'click-message-from-job'">
                        <i class="fa fa-envelope-o" aria-hidden="true"></i>
                        Send
                    </button>
                </div>

                <!-- Tools group for narrow screens -->
                <div class="btn-group"
                     role="group"
                     aria-label="Tools">
                    <button type="button"
                            class="btn btn-secondary dropdown-toggle gridster-d-sm-inline-block gridster-d-md-none"
                            [disabled]="loading"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false">
                        <i class="fa fa-cog" title="Tools"></i>
                    </button>
                    <div class="dropdown-menu">
                        <a *ngIf="readwrite"
                           class="dropdown-item"
                           (click)="copyJob()"
                           [appInsightsClick]="'click-copy-job'">
                            <i class="fa fa-copy" aria-hidden="true"></i>
                            Copy
                        </a>
                        <a (click)="exportJob()"
                           class="dropdown-item"
                           [appInsightsClick]="'click-job-export'">
                            <i class="fa fa-download" aria-hidden="true"></i>
                            Export to CSV
                        </a>
                        <a (click)="printJob()"
                           class="dropdown-item"
                           [appInsightsClick]="'click-job-print'">
                            <i class="fa fa-print" aria-hidden="true"></i>
                            Print
                        </a>
                        <a (click)="viewAuditReport()"
                           class="dropdown-item"
                           [appInsightsClick]="'click-job-audit'">
                            <i class="fa fa-flag" aria-hidden="true"></i>
                            Audit
                        </a>
                        <a (click)="newMessage()"
                           class="dropdown-item"
                           [appInsightsClick]="'click-message-from-job'">
                            <i class="fa fa-envelope-o" aria-hidden="true"></i>
                            Send in Message
                        </a>
                    </div>
                </div>

            </ng-container>

        </detail-toolbar>
    </div>

    <form *ngIf="job"
          #jobForm="ngForm"
          class="detail-form"
          style="position: relative;">

        <fieldset>
            <div class="form-group row">
                <div class="offset-md-2 col-md-10">
                    <p class="form-control-plaintext">
                        <lock-control [(model)]="job.IsLocked"
                                      [administrator]="studyAdministrator"
                                      [readonly]="readonly || loading"
                                      [largeText]="true"
                                      (modelChange)="onJobLockChange()"></lock-control>
                    </p>
                </div>
            </div>
        </fieldset>


        <fieldset [disabled]="readonly || job.IsLocked">

            <div class="form-group row"
                 [ngClass]="jobID.hasError('unique') && jobID.dirty && job.JobID !== originalJobName ? 'has-warning' : ''">
                <label class="col-md-2 col-form-label">Name</label>
                <div class="col-md-10">
                    <input type="text"
                           name="jobID"
                           #jobID="ngModel"
                           [(ngModel)]="job.JobID"
                           [disabled]="(job | isNew) && jobNamingActive"
                           required
                           validateUnique
                           uniqueEntity="Jobs"
                           uniqueProperty="JobID"
                           [entityPK]="job.C_Job_key"
                           entityPKName="C_Job_key"
                           maxlength="35"
                           class="form-control input-medium" />
                    <!-- TODO Fix: displays warning if value changed than changed back to original -->
                    <small class="form-text text-muted"
                           *ngIf="jobID.errors?.unique && jobID.dirty && job.JobID !== originalJobName">
                        <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                        This {{'Job' | translate}} Name already exists.
                    </small>
                    <small class="form-text text-muted"
                           *ngIf="(job | isNew) && jobNamingActive">
                        The {{'Job' | translate}} Name will be assigned automatically.
                    </small>
                </div>
            </div>

            <div class="form-group row" *ngIf="activeFields.includes('Code')">
                <label class="col-md-2 col-form-label">Code</label>
                <div class="col-md-10">
                    <input type="text" name="jobCode"
                           class="form-control input-medium"
                           maxlength="20"
                           [(ngModel)]="job.JobCode"
                           (focusout)="jobCodeChanged()" />
                </div>
            </div>

            <div class="form-group row" *ngIf="activeFields.includes('Study')">
                <label class="col-md-2 col-form-label">{{'Study' | translate}}</label>
                <div class="col-md-10">
                    <study-select [(model)]="job.C_Study_key"
                                  (modelChange)="onStudyChange()"
                                  [excludeInactive]="true"
                                  [excludeLocked]="true">
                    </study-select>
                </div>
            </div>

            <div class="form-group row" *ngIf="activeFields.includes('Title')">
                <label class="col-md-2 col-form-label">Title</label>
                <div class="col-md-10">
                    <input name="jobTitle"
                           class="form-control input-medium"
                           [(ngModel)]="job.Goal" />
                </div>
            </div>

            <div class="form-group row" *ngIf="activeFields.includes('Lead Scientist')">
                <label class="col-md-2 col-form-label">Lead Scientist</label>
                <div class="col-md-10">
                    <user-select [(model)]="job.LeadScientist"
                                 [forceValidSelection]="false">
                    </user-select>
                </div>
            </div>

            <div class="form-group row" *ngIf="activeFields.includes('Study Monitor')">
                <label class="col-md-2 col-form-label">Study Monitor</label>
                <div class="col-md-10">
                    <user-select [(model)]="job.StudyMonitor"
                                 [forceValidSelection]="false">
                    </user-select>
                </div>
            </div>

            <div class="form-group row" *ngIf="activeFields.includes('Description')">
                <label class="col-md-2 col-form-label">Description</label>
                <div class="col-md-10">
                    <textarea name="jobNotes"
                              class="form-control input-medium"
                              rows="5"
                              [(ngModel)]="job.Notes">
                    </textarea>
                </div>
            </div>

            <div *ngIf="activeFields.includes('Line')" class="form-group row">
                <label class="col-md-2 col-form-label">{{'Line' | translate}}</label>
                <div class="col-md-10">
                    <line-select [(model)]="job.C_Line_key"
                                 (modelChange)="lineChanged()">
                    </line-select>
                </div>
            </div>

            <div class="form-group row">
                <label class="col-md-2 col-form-label">Species</label>
                <div class="col-md-10">
                    <ng-container *ngIf="job?.C_Line_key && job.Line?.cv_Taxon">
                        <input type="text" name="jobSpecies"
                               [value]="job.Line.cv_Taxon.CommonName"
                               readonly="readonly"
                               class="form-control input-medium" />
                    </ng-container>
                    <small class="form-text text-muted" *ngIf="!job?.C_Line_key">
                        Please specify a {{'Line' | translate}}.
                    </small>
                </div>
            </div>

            <job-test-article-table *ngIf="activeFields.includes('Test Article')"
                                    [job]="job"
                                    [readonly]="readonly || job.IsLocked">
            </job-test-article-table>

            <job-institution-table *ngIf="activeFields.includes('Institutions')"
                                   [job]="job"
                                   [readonly]="readonly || job.IsLocked">
            </job-institution-table>

            <div class="form-group row" *ngIf="activeFields.includes('IACUC Protocol')">
                <label class="col-md-2 col-form-label">IACUC Protocol</label>
                <div class="col-md-10">
                    <active-vocab-select [(model)]="job.C_IACUCProtocol_key"
                                         (modelChange)="iacucProtocolChanged()"
                                         [vocabChoices]="iacucProtocols"
                                         [keyFormatter]="iacucProtocolKeyFormatter"
                                         [optionFormatter]="iacucProtocolFormatter"
                                         [nullable]="true">
                    </active-vocab-select>
                </div>
            </div>

            <div class="form-group row" *ngIf="activeFields.includes('Compliance')">
                <label class="col-md-2 col-form-label">Compliance</label>
                <div class="col-md-10">
                    <active-vocab-select [(model)]="job.C_Compliance_key"
                                         (modelChange)="complianceChanged()"
                                         [vocabChoices]="compliances"
                                         [keyFormatter]="complianceKeyFormatter"
                                         [optionFormatter]="complianceFormatter"
                                         [nullable]="true">
                    </active-vocab-select>
                </div>
            </div>

            <div class="form-group row">
                <label class="col-md-2 col-form-label">Status</label>
                <div class="col-md-10">
                    <active-vocab-select [(model)]="job.C_JobStatus_key"
                                         (modelChange)="jobStatusChanged()"
                                         [vocabChoices]="jobStatuses"
                                         [keyFormatter]="jobStatusKeyFormatter"
                                         [optionFormatter]="jobStatusFormatter"
                                         [required]="true">
                    </active-vocab-select>
                </div>
            </div>

            <div class="form-group row">
                <label class="col-md-2 col-form-label">Type</label>
                <div class="col-md-10">
                    <active-vocab-select [(model)]="job.C_JobType_key"
                                         (modelChange)="typeChanged(job)"
                                         [vocabChoices]="jobTypes"
                                         [keyFormatter]="jobTypeKeyFormatter"
                                         [optionFormatter]="jobTypeFormatter"
                                         [required]="true">
                    </active-vocab-select>
                </div>
            </div>

            <div class="form-group row">
                <label class="col-md-2 col-form-label">
                    High Throughput
                </label>
                <div class="col-md-10">
                    <input type="checkbox" name="isHighThroughput"
                           class="mt-1"
                           [(ngModel)]="job.IsHighThroughput"
                           (ngModelChange)="highThroughputChanged()" />
                </div>
            </div>

            <div class="form-group row" *ngIf="activeFields.includes('Projected Start Date')">
                <label class="col-md-2 col-form-label">Projected Start Date</label>
                <div class="col-md-3">
                    <climb-ngb-date #dateControl="ngModel"
                                    name="Projected Start Date"
                                    [(ngModel)]="job.ProjectedStartDate"
                                    [required]="requiredFields.includes('ProjectedStartDate')">
                    </climb-ngb-date>
                </div>
                <div class="col-md-3">
                    <p class="form-control-plaintext text-nowrap"
                       *ngIf="(job.ProjectedStartDate | daysFromToday) > 1">
                        ({{job.ProjectedStartDate | daysFromToday}} days ago)
                    </p>
                    <p class="form-control-plaintext text-nowrap"
                       *ngIf="(job.ProjectedStartDate | daysFromToday) === 1">
                        ({{job.ProjectedStartDate | daysFromToday}} day ago)
                    </p>
                </div>
            </div>

            <div class="form-group row" *ngIf="activeFields.includes('Start Date')">
                <label class="col-md-2 col-form-label">Start Date</label>
                <div class="col-md-3">
                    <climb-ngb-date #dateControl="ngModel"
                                    name="Start Date"
                                    [(ngModel)]="job.DateStarted"
                                    [required]="requiredFields.includes('DateStarted')">
                    </climb-ngb-date>
                </div>
                <div class="col-md-3">
                    <p class="form-control-plaintext text-nowrap"
                       *ngIf="(job.DateStarted | daysFromToday) > 1">
                        ({{job.DateStarted | daysFromToday}} days ago)
                    </p>
                    <p class="form-control-plaintext text-nowrap"
                       *ngIf="(job.DateStarted | daysFromToday) === 1">
                        ({{job.DateStarted | daysFromToday}} day ago)
                    </p>
                </div>
            </div>

            <div class="form-group row" *ngIf="activeFields.includes('End Date')">
                <label class="col-md-2 col-form-label">End Date</label>
                <div class="col-md-3">
                    <climb-ngb-date #dateControl="ngModel"
                                    name="End Date"
                                    [(ngModel)]="job.DateEnded"
                                    [required]="requiredFields.includes('DateEnded')">
                    </climb-ngb-date>
                </div>
                <div class="col-md-3">
                    <p class="form-control-plaintext text-nowrap"
                       *ngIf="(job.DateEnded | daysFromToday) > 1">
                        ({{job.DateEnded | daysFromToday}} days ago)
                    </p>
                    <p class="form-control-plaintext text-nowrap"
                       *ngIf="(job.DateEnded | daysFromToday) === 1">
                        ({{job.DateEnded | daysFromToday}} day ago)
                    </p>
                </div>
            </div>

            <job-location-table class="locations-table"
                                *ngIf="activeFields.includes('Locations')"
                                [job]="job"
                                [readonly]="readonly || job.IsLocked">
            </job-location-table>

            <fieldset class="fieldset-bordered"
                      *ngIf="job.JobCharacteristicInstance.length > 0">
                <legend>Characteristics</legend>
                <div class="form-group row"
                     *ngFor="let characteristicInstance of job.JobCharacteristicInstance |
                     orderBy:'JobCharacteristic.SortOrder'">
                    <div class="col-md-12">
                        <label>
                            {{characteristicInstance.CharacteristicName}}
                        </label>
                        <characteristic-input #characteristicInput
                                              [characteristic]="characteristicInstance"
                                              [readonly]="readonly || job.IsLocked"
                                              [(value)]="characteristicInstance.CharacteristicValue">
                        </characteristic-input>
                    </div>
                </div>
            </fieldset>

            <!-- Cost -->
            <div class="form-group row">
                <label class="col-md-2 col-form-label">Cost</label>
                <div class="col-md-5">
                    <div class="input-group">
                        <input type="number"
                               name="jobCost"
                               [(ngModel)]="job.Cost"
                               step="0.01"
                               min="0.00"
                               class="form-control input-medium"
                               moneyFormatter />
                        <div class="input-group-append"
                             *ngIf="!readonly && !job.IsLocked">
                            <button type="button"
                                    class="btn btn-secondary"
                                    (click)="calculateJobCost()"
                                    [disabled]="!(job.TaskJob | notEmpty)">
                                <i class="fa fa-calculator" title="Calculate cost"></i>
                                Calculate
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Duration-->
            <div class="form-group row">
                <label class="col-md-2 col-form-label">Duration (minutes)</label>
                <div class="col-md-5">
                    <div class="input-group">
                        <input type="number"
                               name="jobDuration"
                               [(ngModel)]="job.Duration"
                               step="any"
                               min="0"
                               decimalFormatter
                               [decimalPlaces]="2"
                               class="form-control input-medium" />
                        <div class="input-group-append"
                             *ngIf="!readonly && !job.IsLocked">
                            <button type="button"
                                    class="btn btn-secondary"
                                    (click)="calculateJobDuration()"
                                    [disabled]="!(job.TaskJob | notEmpty)">
                                <i class="fa fa-calculator" title="Calculate duration"></i>
                                Calculate
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-group row" style="margin-top: 1em;">
                <label class="col-md-2 col-form-label">Created</label>
                <div class="col-md-10">
                    <p class="form-control-plaintext">
                        {{job.CreatedBy | userFullName}}
                        &middot;
                        {{job.DateCreated | formatShortDate}}
                    </p>
                </div>
            </div>

        </fieldset>


        <!-- New fieldset that is never disabled so as not to affect controls in child tables -->
        <fieldset>
            <div class="form-group row">
                <label class="col-md-2 col-form-label">Files</label>
                <div class="col-md-10">
                    <div class="form-control-plaintext">
                        <facet-detail-file-upload [pkName]="'C_Job_key'"
                                                  [pkValue]="job.C_Job_key"
                                                  [facetPrivilege]="facet.Privilege"
                                                  [disabled]="job.IsLocked"></facet-detail-file-upload>
                    </div>
                </div>
            </div>

            <div class="form-group row" style="margin-top: 2em;">
                <div class="col-md-12">
                    <job-animal-table [job]="job"
                                      [animalMaterials]="job.FilteredAnimalMaterials"
                                      [readonly]="readonly || job.IsLocked"
                                      [tableSort]="animalTableSort"></job-animal-table>
                </div>
            </div>

            <div class="form-group row">
                <div class="col-md-12">
                    <job-cohort-table [job]="job"
                                      [cohorts]="job.DerivedCohorts"
                                      [readonly]="readonly || job.IsLocked"></job-cohort-table>
                </div>
            </div>

            <div class="form-group row">
                <div class="col-md-12">
                    <job-sample-table [job]="job"
                                      [sampleMaterials]="job.FilteredSampleMaterials"
                                      [readonly]="readonly || job.IsLocked"
                                      [tableSort]="sampleTableSort"></job-sample-table>
                </div>
            </div>

            <div class="form-group row">
                <div class="col-md-12">
                    <detail-task-table #detailTaskTable
                                       [parent]="job"
                                       [taskAssociations]="job.TaskJob"
                                       [taskType]="TaskType.Job"
                                       [readonly]="readonly"
                                       [facet]="facet"
                                       [studyAdministrator]="studyAdministrator"
                                       [tableOptions]="detailTaskTableOptions"
                                       [tableSort]="taskTableSort"
                                       [parentLocked]="job.IsLocked"
                                       [allowNoMaterial]="allowNoMaterial"
                                       [(deviationExpanded)]="job.deviationExpanded"
                                       [(inputsExpanded)]="job.inputsExpanded"
                                       [(tasksExpanded)]="job.tasksExpanded"
                                       [(taskAnimalsExpanded)]="job.taskAnimalsExpanded"
                                       [(taskCohortsExpanded)]="job.taskCohortsExpanded"
                                       [(taskSamplesExpanded)]="job.taskSamplesExpanded"
                                       (materialAdd)="addMaterialToJob($event)"
                                       (taskInstanceAdd)="addTaskJob($event)"></detail-task-table>
                </div>
            </div>

        </fieldset>
    </form>
</div>

<div id="{{printDivId}}" class="d-none">
    <div style="width:7.75in;">
        <table class="printTable">
            <tr>
                <td><b>{{'Job' | translate}} Name</b></td>
                <td>{{job.JobID}}</td>
            </tr>
            <tr *ngIf="activeFields.includes('Code')">
                <td><b>Code</b></td>
                <td>{{job.JobCode}}</td>
            </tr>
            <tr *ngIf="activeFields.includes('Study')">
                <td><b>{{'Study' | translate}}</b></td>
                <td>{{job.Study?.StudyName}}</td>
            </tr>
            <tr *ngIf="activeFields.includes('Title')">
                <td><b>Title</b></td>
                <td>{{job.Goal}}</td>
            </tr>
            <tr *ngIf="activeFields.includes('Lead Scientist')">
                <td><b>Lead Scientist</b></td>
                <td>{{job.LeadScientist}}</td>
            </tr>
            <tr *ngIf="activeFields.includes('Study Monitor')">
                <td><b>Study Monitor</b></td>
                <td>{{job.StudyMonitor}}</td>
            </tr>
            <tr *ngIf="activeFields.includes('Description')">
                <td><b>Description</b></td>
                <td>{{job.Notes}}</td>
            </tr>
            <tr>
                <td><b>{{'Line' | translate}}</b></td>
                <td>{{job.Line?.LineName}}</td>
            </tr>
            <tr>
                <td><b>Species</b></td>
                <td>{{job.Line?.cv_Taxon.CommonName}}</td>
            </tr>
            <tr *ngIf="activeFields.includes('Test Article')">
                <td><b>Test Article</b></td>
                <td><b>Batch</b></td>
            </tr>
            <tr *ngFor="let testArticle of job.JobTestArticle; let last = last">
                <td>{{testArticle.cv_TestArticle?.TestArticle}}</td>
                <td>{{testArticle.Batch}}</td>
            </tr>
            <tr *ngIf="activeFields.includes('Institutions')">
                <td><b>Institution</b></td>
                <td><b>Site</b></td>
            </tr>
            <ng-container *ngIf="activeFields.includes('Institutions')">
                <tr *ngFor="let jobInstitution of job.JobInstitution; let last = last">
                    <td>{{jobInstitution?.Institution?.Name}}</td>
                    <td>{{jobInstitution?.Site?.Name}}</td>
                </tr>
            </ng-container>
            <tr *ngIf="activeFields.includes('IACUC Protocol')">
                <td><b>IACUC Protocol</b></td>
                <td>{{job.cv_IACUCProtocol?.IACUCProtocol}}</td>
            </tr>
            <tr *ngIf="activeFields.includes('IACUC Protocol')">
                <td><b>Compliance</b></td>
                <td>{{job.Compliance?.Compliance}}</td>
            </tr>
            <tr>
                <td><b>{{'Job' | translate}} Type</b></td>
                <td>{{job.cv_JobType?.JobType}}</td>
            </tr>
            <tr>
                <td><b>{{'Job' | translate}} Status</b></td>
                <td>{{job.cv_JobStatus?.JobStatus}}</td>
            </tr>
            <tr>
                <td><b>Comments</b></td>
                <td>{{job.Notes}}</td>
            </tr>
            <tr *ngIf="activeFields.includes('Projected Start Date')">
                <td><b>Projected Start Date</b></td>
                <td>{{job.ProjectedStartDate | formatShortDate }}</td>
            </tr>
            <tr *ngIf="activeFields.includes('Start Date')">
                <td><b>Start Date</b></td>
                <td>{{job.DateStarted | formatShortDate }}</td>
            </tr>
            <tr *ngIf="activeFields.includes('End Date')">
                <td><b>End Date</b></td>
                <td>{{job.DateEnded | formatShortDate }}</td>
            </tr>
            <tr *ngIf="activeFields.includes('Locations')">
                <td><b>Locations</b></td>
                <td>{{job.CurrentLocationPath}}</td>
            </tr>
            <tr>
                <td><b>Cost</b></td>
                <td>{{job.Cost}}</td>
            </tr>
            <tr>
                <td><b>Duration</b></td>
                <td>{{job.Duration}}</td>
            </tr>
            <tr>
                <td><b>Created By</b></td>
                <td>{{job.CreatedBy | userFullName}}</td>
            </tr>
            <tr>
                <td><b>Created Date</b></td>
                <td>{{job.DateCreated | formatShortDate}}</td>
            </tr>
            <tr *ngFor="let characteristicInstance of job.JobCharacteristicInstance |
                orderBy:'JobCharacteristic.SortOrder'">
                <td><b>{{characteristicInstance?.CharacteristicName}}</b></td>
                <td>{{characteristicInstance?.CharacteristicValue}}</td>
            </tr>
            <tr>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
            </tr>
            <tr>
                <td><b>Animals</b></td>
            </tr>
            <tr>
                <td><b>ID</b></td>
                <td><b>Name</b></td>
                <td><b>Genotypes</b></td>
                <td><b>Sex</b></td>
                <td><b>Status</b></td>
                <td><b>Birth Date</b></td>
                <td><b>{{'Line' | translate}}</b></td>
                <td><b>Cohorts</b></td>
            </tr>
            <tr *ngFor="let jobMaterial of job.FilteredAnimalMaterials |
                    orderBy:animalTableSort.propertyPath:animalTableSort.reverse">
                <ng-container *ngIf="jobMaterial.Material?.Animal">
                    <td>
                        {{jobMaterial.Material?.Identifier}}
                    </td>
                    <td>
                        {{jobMaterial.Material?.Animal.AnimalName}}
                    </td>
                    <td>
                        <span *ngFor="let genotype of jobMaterial.Material?.Animal?.Genotype; let last = last">
                            {{genotype.cv_GenotypeAssay?.GenotypeAssay}}
                            {{genotype.cv_GenotypeSymbol?.GenotypeSymbol}}<span *ngIf="!last">, </span>
                        </span>
                    </td>
                    <td>
                        {{jobMaterial.Material?.Animal.cv_Sex?.Sex}}
                    </td>
                    <td>
                        {{jobMaterial.Material?.Animal.cv_AnimalStatus?.AnimalStatus}}
                    </td>
                    <td>
                        {{jobMaterial.Material?.Animal.DateBorn | formatShortDate}}
                    </td>
                    <td>
                        {{jobMaterial.Material?.Line?.LineName}}
                    </td>
                    <td>
                        <span *ngFor="let cohortMaterial of jobMaterial.Material?.CohortMaterial |
                            orderBy:'Cohort.CohortName'; let last = last">{{cohortMaterial.Cohort?.CohortName}}<span *ngIf="!last">, </span></span>
                    </td>
                </ng-container>
            </tr>
            <tr>
                <td><b>Total Animals: {{job.FilteredAnimalMaterials?.length}}</b></td>
            </tr>
            <tr>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
            </tr>
            <tr>
                <td><b>Samples</b></td>
            </tr>
            <tr>
                <td><b>ID</b></td>
                <td><b>Name</b></td>
                <td><b>Source</b></td>
                <td><b>Type</b></td>
                <td><b>Time Point</b></td>
                <td><b>Status</b></td>
                <td><b>Preservation</b></td>
                <td><b>{{'Line' | translate}}</b></td>
            </tr>
            <tr *ngFor="let jobMaterial of job.FilteredSampleMaterials |
                    orderBy:sampleTableSort.propertyPath:sampleTableSort.reverse">
                <ng-container *ngIf="jobMaterial.Material?.Sample">
                    <td>
                        {{jobMaterial.Material?.Identifier}}
                    </td>
                    <td>
                        {{jobMaterial.Material?.Sample.SampleName}}
                    </td>
                    <td>
                        <span *ngFor="let source of jobMaterial.Material?.MaterialSourceMaterial; let last = last">
                            {{source.SourceMaterial.Animal?.AnimalName}}{{source.SourceMaterial.Sample?.SampleName}}<span *ngIf="!last">,</span>
                        </span>
                    </td>
                    <td>
                        {{jobMaterial.Material?.Sample.cv_SampleType?.SampleType}}
                    </td>
                    <td>
                        {{
jobMaterial.Material?.Sample.TimePoint |
                            formatTimePoint : jobMaterial.Material?.Sample.cv_TimeUnit
                        }}
                    </td>
                    <td>
                        {{jobMaterial.Material?.Sample.cv_SampleStatus?.SampleStatus}}
                    </td>
                    <td>
                        {{jobMaterial.Material?.Sample.cv_PreservationMethod?.PreservationMethod}}
                    </td>
                    <td>
                        {{jobMaterial.Material?.Line?.LineName}}
                    </td>
                </ng-container>
            </tr>
            <tr>
                <td><b>Total Samples: {{job.FilteredSampleMaterials?.length}}</b></td>
            </tr>
            <tr>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
            </tr>
            <tr>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
            </tr>
            <tr>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
            </tr>
            <ng-container *ngFor="let task of job.TaskJob |
                    orderBy:taskTableSort.propertyPath:taskTableSort.reverse">
                <ng-container *ngIf="task?.TaskInstance">
                    <tr>
                        <td><b>Task Name</b></td>
                        <td>{{task.TaskInstance.TaskAlias}}</td>
                    </tr>
                    <tr>
                        <td><b>Task</b></td>
                        <td>{{task.TaskInstance.WorkflowTask?.TaskName}}</td>
                    </tr>
                    <tr>
                        <td><b>{{'Protocol' | translate}}</b></td>
                        <td> {{task.TaskInstance.ProtocolTask?.Protocol?.ProtocolName}}</td>
                    </tr>
                    <tr>
                        <td><b>Sequence</b></td>
                        <td>{{task.Sequence}}</td>
                    </tr>
                    <tr>
                        <td><b>Due Date</b></td>
                        <td>{{task.TaskInstance.DateDue | formatShortDateOrTime}}</td>
                    </tr>
                    <tr>
                        <td><b>Deviation</b></td>
                        <td>{{task.TaskInstance.Deviation}}</td>
                    </tr>
                    <tr>
                        <td><b>Task Location</b></td>
                        <td>{{task.TaskInstance.CurrentLocationPath}}</td>
                    </tr>
                    <tr>
                        <td><b>Status</b></td>
                        <td>{{task.TaskInstance.cv_TaskStatus?.TaskStatus}}</td>
                    </tr>
                    <tr>
                        <td><b>Assigned To</b></td>
                        <td>{{task.TaskInstance.AssignedToResource?.ResourceName}}</td>
                    </tr>
                    <tr>
                        <td><b>Cost</b></td>
                        <td>{{task.TaskInstance.Cost}}</td>
                    </tr>
                    <tr>
                        <td><b>Duration</b></td>
                        <td>{{task.TaskInstance.Duration}}</td>
                    </tr>
                    <tr>
                        <td><b>Cohorts</b></td>
                    </tr>
                    <tr *ngFor="let cohort of task.TaskInstance.DerivedCohorts">
                        <ng-container *ngIf="cohort">
                            <td>
                                &nbsp;
                            </td>
                            <td>
                                {{cohort.CohortName}}
                            </td>
                        </ng-container>
                    </tr>
                    <tr>
                        <td><b>Animals</b></td>
                    </tr>
                    <tr *ngFor="let animal of task.TaskInstance.FilteredAnimalMaterials">
                        <ng-container *ngIf="animal.Material?.Animal">
                            <td>
                                &nbsp;
                            </td>
                            <td>
                                {{animal.Material?.Animal.AnimalName}}
                            </td>
                        </ng-container>
                    </tr>
                    <tr>
                        <td><b>Samples</b></td>
                    </tr>
                    <tr *ngFor="let sample of task.TaskInstance.FilteredSampleMaterials">
                        <ng-container *ngIf="sample.Material?.Sample">
                            <td>
                                &nbsp;
                            </td>
                            <td>
                                {{sample.Material?.Sample.SampleName}}
                            </td>
                        </ng-container>
                    </tr>
                    <tr>
                        <td><b>Inputs</b></td>
                    </tr>
                    <tr *ngFor="let taskInput of task.TaskInstance.TaskInput | orderBy:'Input.SortOrder'">
                        <td>
                            &nbsp;
                        </td>
                        <td>
                            {{taskInput.Input?.InputName}}
                        </td>
                        <td>
                            {{taskInput.InputValue}}
                        </td>
                    </tr>
                    <tr>
                        <td><b>Outputs</b></td>
                    </tr>
                    <tr *ngFor="let taskOutputSet of task.TaskInstance.TaskOutputSet">
                        <td>&nbsp;</td>
                        <td>
                            <div *ngFor="let animal of taskOutputSet.TaskOutputSetMaterial">
                                {{animal.Material?.Animal?.AnimalName}}
                            </div>
                        </td>
                        <ng-container *ngFor="let taskOutput of taskOutputSet.TaskOutput">
                            <td>
                                {{taskOutput.Output?.OutputName}}
                            </td>
                            <td>
                                {{taskOutput.OutputValue}}
                            </td>
                        </ng-container>
                    </tr>
                    <tr>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                    </tr>
                </ng-container>
            </ng-container>
            <tr *ngIf="job.TaskJob | notEmpty">
                <td colspan="2"><b>Total Tasks: {{job.TaskJob.length}}</b></td>
            </tr>
        </table>
    </div>
</div>

import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { Animal, Entity, LocationPosition, Material, cv_ContainerType, cv_MaterialPoolType } from "@common/types";
import { HousingVocabService } from "../../services/housing-vocab.service";
import { LocationService } from "../../../locations/location.service";
import { sortObjectArrayByProperty } from "@common/util";
import { MaterialPoolService } from "../../../services/material-pool.service";
import { VocabularyService } from "../../../vocabularies/vocabulary.service";
import { isEqual } from "lodash";
import { NgModel } from "@angular/forms";
import { NamingService } from "@services/naming.service";
import { HousingOption } from "../../../animals/bulkedit/confirm-housing-modal.component";

interface HousingUnit {
    id?: string;
    size: number;
}

@Component({
    selector: 'create-housing',
    templateUrl: './create-housing.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styles: [`
        :host ::ng-deep user-select .user-select {
            width: unset;
            min-width: unset;
        }

        :host ::ng-deep climb-ngb-date .date-input {
            width: 100%;
            min-width: unset;
        }
    `]
})
export class CreateHousingComponent implements OnInit, OnChanges {
    @Input() materials: Entity<Material>[];
    @Input() showDate: boolean;
    @Input() showOwner: boolean;
    @Input() showContainerType: boolean;
    @Input() autoNaming = true;
    @Input() disabled = false;

    @Output() autoNamingChange = new EventEmitter<boolean>();

    units: HousingUnit[];
    materialsPerUnit = 5;
    unhousedMaterialCount: number;

    materialPoolTypeKey: number;
    materialPoolTypes: cv_MaterialPoolType[];
    containerTypeKey: number;
    containerTypes: cv_ContainerType[];
    materialPoolStatusKey: number;

    housingDate: Date;
    housingOwner: string;
    housingLocation: LocationPosition;
    
    isNamingValid = false;
    isHousingNamingActive = false;
    @ViewChild('materialPoolID') materialPoolIDInput: NgModel;


    constructor(
        private materialPoolService: MaterialPoolService,
        private housingVocabService: HousingVocabService,
        private vocabularyService: VocabularyService,
        private locationService: LocationService,
        private namingService: NamingService
    ) { }


    async ngOnInit() {
        await this.initSettings();
        await this.initVocab();
        this.initHousingUnits();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!isEqual(changes?.materials?.previousValue, changes?.materials?.currentValue)) {
                this.updateUnhousedMaterialCount();
        }
    }

    private async initSettings() {
        this.isHousingNamingActive = await this.namingService.isHousingNamingActive();
        if (!this.isHousingNamingActive) {
            this.autoNaming = false;
        }
    }

    private async initVocab() {
        // Housing Type
        const p1 = this.housingVocabService.materialPoolTypes$.toPromise().then((types: cv_MaterialPoolType[]) => {
            this.materialPoolTypes = types;
            const defaultTypeKey = types.find(t => t.IsDefault).C_MaterialPoolType_key;
            if (defaultTypeKey) {
                this.materialPoolTypeKey = defaultTypeKey;
            }
        });

        const p2 = this.locationService.getDefaultLocation(true).then((housingLocation) => {
            this.housingLocation = housingLocation;
        });

        // Container Type
        const p3 = this.housingVocabService.containerTypes$.subscribe((types: cv_ContainerType[]) => {
            this.containerTypes = types;
            const defaultTypeKey = types.find(t => t.IsDefault).C_ContainerType_key;
            if (defaultTypeKey) {
                this.containerTypeKey = defaultTypeKey;
            }
        });

        const p4 = this.vocabularyService.getCVDefault('cv_MaterialPoolStatuses').then((data) => {
            if (data && data.C_MaterialPoolStatus_key) {
                this.materialPoolStatusKey = data.C_MaterialPoolStatus_key;
            }
        });

        return await Promise.all([p1, p2, p3, p4]);
    }
        
    private initHousingUnits() {
        this.unhousedMaterialCount = this.materials.length;
        this.units = [];
        while (this.unhousedMaterialCount > 0 && this.unhousedMaterialCount >= this.materialsPerUnit) {
            this.units.push(this.createHousingUnit());
            this.updateUnhousedMaterialCount();
        }
    }

    private createHousingUnit(): HousingUnit {
        const size = Math.min(this.materialsPerUnit, this.unhousedMaterialCount);
        return { size };
    }

    private updateUnhousedMaterialCount() {
        if (!this.units) {
            return;
        }
        this.unhousedMaterialCount = this.materials.length - this.units.reduce((acc, cur) => acc + cur.size, 0);
    }

    onMaterialsPerUnitChange() {
        if (this.materialsPerUnit > 0) {
            this.initHousingUnits();
            this.updateNamingValid();
        }
    }

    onHousingUnitSizeChange(unit: HousingUnit) {
        if (this.disabled) {
            return;
        }

        this.updateUnhousedMaterialCount();
        if (this.unhousedMaterialCount < 0) {
            unit.size += this.unhousedMaterialCount;
            this.updateUnhousedMaterialCount();
        }
    }

    onAddHousingUnit() {
        if (this.disabled) {
            return;
        }
        this.units.push(this.createHousingUnit());
        this.updateUnhousedMaterialCount();
        this.updateNamingValid();
    }

    onRemoveHousingUnit(index: number) {
        if (this.disabled) {
            return;
        }
        this.units.splice(index, 1);
        this.updateUnhousedMaterialCount();
        this.updateNamingValid();
    }

    updateNamingValid() {
        this.isNamingValid = this.units.every(u => u.id);
    }

    onAutoNamingChanged() {
        this.autoNamingChange.emit(this.autoNaming);
    }

    async tryToCreateHousing() {
        const totalSize = this.units.reduce((acc, cur) => acc + cur.size, 0);
        if (!this.units.length || totalSize <= 0) {
            return false;
        }
        let animalsToHouse = this.materials.map(m => m.Animal);
        sortObjectArrayByProperty(animalsToHouse, 'C_Material_key', true);
        const housedAnimals = this.materialPoolService.findAnimalsWithHousing(animalsToHouse);
        if (housedAnimals.length > 0) {
            const result = await this.materialPoolService.openConfirmHousingModal(housedAnimals);
            switch (result) {
                case HousingOption.Move:
                    return this.createHousing(animalsToHouse);
                case HousingOption.Skip:
                    animalsToHouse = animalsToHouse.filter(a => !housedAnimals.includes(a));
                    return this.createHousing(animalsToHouse);
                case HousingOption.Cancel:
                default:
                    console.log('Save cancelled');
                    return false;
            }        
        } else {    
            return this.createHousing(animalsToHouse);
        }
    }

    private createHousing(animals: Animal[]) {
        if (!animals.length) {
            return false;
        }

        this.removeEmptyUnits();

        let index = 0;
        for (const unit of this.units) {
            if (unit.size <= 0) {
                continue;
            }
            const datePooled = this.housingDate || new Date();
            const materialPoolToCreate: any = {
                MaterialPoolID: unit.id,
                C_MaterialPoolType_key: this.materialPoolTypeKey,
                C_ContainerType_key: this.containerTypeKey,
                DatePooled: datePooled,
                C_MaterialPoolStatus_key: this.materialPoolStatusKey
            };
            if (this.housingOwner) {
                materialPoolToCreate.Owner = this.housingOwner;
            }

            const materialPool = this.materialPoolService.createMaterialPool(materialPoolToCreate);

            this.locationService.createMaterialLocation({
                C_MaterialPool_key: materialPool.C_MaterialPool_key,
                C_LocationPosition_key: this.housingLocation.C_LocationPosition_key,
            });

            let items = unit.size;
            while (items > 0) {
                const animal = animals[index];
                if (!animal) {
                    // Ran out of animals... weird
                    break;
                }

                // Put the animal in the housing unit
                this.materialPoolService.createMaterialPoolMaterial({
                    C_MaterialPool_key: materialPool.C_MaterialPool_key,
                    C_Material_key: animal.C_Material_key,
                    DateIn: datePooled,
                });

                // Account for the used-up space and housed animal
                items--;
                index++;
            }
        }

        return true;
    }

    private removeEmptyUnits() {
        for (let i = this.units.length - 1; i >= 0; i--) {
            if (this.units[i].size <= 0) {
                this.onRemoveHousingUnit(i);
            }
        }
    }

     // <select> formatters
    materialPoolTypeKeyFormatter = (value: any) => {
        return value.C_MaterialPoolType_key;
    }
    materialPoolTypeFormatter = (value: any) => {
        return value.MaterialPoolType;
    }
    containerTypeKeyFormatter = (value: any) => {
        return value.C_ContainerType_key;
    }
    containerTypeFormatter = (value: any) => {
        return value.ContainerType;
    }

    get totalCount() {
        return this.units.reduce((acc, cur) => acc + cur.size, 0);
    }
}

import {
    CellFormatterService,
    TableOptions,
    FormatterOptions
} from '../common/datatable';
import { AuditModifiedFieldComponent } from './audit-modified-fields/audit-modified-field.component';
import { AuditClassFactory } from './audit-class-maker';

export class AuditTaskAnimalHealthRecordsTableOptions {

    options: TableOptions;

    constructor(
        private cellFormatterService: CellFormatterService
    ) {
        this.options = this.getDefaultOptions();
    }

    getDefaultOptions(): TableOptions {

        return {
            csvFileName: 'task-animal-health-records-audit.csv',
            enableDetailColumn: false,
            enableDraggable: false,
            enableSelectable: false,
            enableAuditColumns: false,
            columns: [
                {
                    displayName: 'Type',
                    field: 'UpdateType',
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Modified Fields',
                    field: 'ModifiedFields',
                    sortField: 'ModifiedFields',
                    rendererComponent: AuditModifiedFieldComponent,
                    exportFormatter: this.cellFormatterService.auditModifiedFieldsExportFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Date',
                    field: 'ModifiedDate',
                    formatter: this.cellFormatterService.dateTimeUTCFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Modified By',
                    field: 'ModifiedBy',
                    formatter: this.cellFormatterService.userNameFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Animal Name',
                    field: 'AnimalName',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('AnimalName', 'AnimalName'),
                    cellClass: AuditClassFactory.createCellClass('animalname'),
                },
                {
                    displayName: 'Animal ID',
                    field: 'AnimalIdentifier',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('AnimalIdentifier', 'AnimalIdentifier'),
                    cellClass: AuditClassFactory.createCellClass('animalidentifier'),
                },
                {
                    displayName: 'Due Date and Time',
                    field: 'DueDateTime',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('DueDateTime', 'DueDateTime', this.cellFormatterService.dateTimeUTCFormatter),
                    cellClass: AuditClassFactory.createCellClass('duedatetime'),
                },
                {
                    displayName: 'Assigned To',
                    field: 'AssignedTo',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('AssignedTo', 'AssignedTo'),
                    cellClass: AuditClassFactory.createCellClass('assignedto'),
                },
                {
                    displayName: 'Comments',
                    field: 'Comments',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('Comments', 'Comments'),
                    cellClass: AuditClassFactory.createCellClass('comments'),
                },
                {
                    displayName: 'Completed',
                    field: 'Completed',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('Completed', 'Completed'),
                    cellClass: AuditClassFactory.createCellClass('completed'),
                },
                {
                    displayName: 'Completed Date and Time',
                    field: 'CompletedDateTime',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('CompletedDateTime', 'CompletedDateTime', this.cellFormatterService.dateTimeUTCFormatter),
                    cellClass: AuditClassFactory.createCellClass('completeddatetime'),
                },
                {
                    displayName: 'Completed By',
                    field: 'CompletedBy',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('CompletedBy', 'CompletedBy'),
                    cellClass: AuditClassFactory.createCellClass('completedby'),
                },
                {
                    displayName: 'Status',
                    field: 'Status',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('Status', 'Status'),
                    cellClass: AuditClassFactory.createCellClass('status'),
                },
                {
                    displayName: 'Created By',
                    field: 'CreatedBy',
                    formatter: this.cellFormatterService.userNameFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Created Date',
                    field: 'DateCreated',
                    formatter: this.cellFormatterService.dateTimeUTCFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                }
            ]
        };
    }
}

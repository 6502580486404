import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';
import { WizardService } from '../wizard.service';

import {
    CvValue,
    SDLine
} from '../models';

import {
    focusElementByQuery,
    notEmpty
} from '../../common/util';

@Component({
    selector: 'choose-lines',
    templateUrl: './choose-lines.component.html',
    styles: [`
        .taxon-lines {
            margin: 1em 0;
        }
    `]
})
export class ChooseLinesComponent implements OnInit {
    @Input() selectedTaxa: CvValue[];
    @Input() selectedLineTypes: CvValue[];
    @Input() selectedLines: SDLine[];
    @Output() selectedLinesChange: EventEmitter<SDLine[]>
    = new EventEmitter<SDLine[]>();

    // Seed values
    seedLines: SDLine[];

    readonly TABLE_ID_ROOT = 'taxon-lines-table-';


    constructor(
        private wizardService: WizardService
    ) {
        // Nothing to do
    }

    ngOnInit() {
        this.initialize();
    }

    initialize() {
        if (!this.selectedLines) {
            this.selectedLines = [];
        }

        this.getSeedLines().then((values) => {
            this.seedLines = values;
            this.setSelectedFromSeedLines();
        });
    }

    public onShowThisStep() {
        this.clearInvalidLineTypes();
    }

    private getSeedLines(): Promise<SDLine[]> {
        return this.wizardService.getLineSeedValues();
    }

    private setSelectedFromSeedLines() {
        if (!this.seedLines) {
            return;
        }

        if (!notEmpty(this.selectedLines)) {
            this.initializeSelectedLines();
        }
    }

    initializeSelectedLines() {

        if (!this.selectedLines) {
            this.selectedLines = [];
        }
        for (const line of this.seedLines) {
            this.selectedLines.push(line);
            line.isSelected = true;
        }
    }

    /**
     * Clear all lineType values that do not have a match
     *   in the selectedLineTypes list
     */
    private clearInvalidLineTypes() {
        if (!this.selectedLines ||
            !this.selectedLineTypes
        ) {
            return;
        }

        const lineTypeNames = this.selectedLineTypes.map((type) => {
            return type.cvValue;
        });

        for (const line of this.selectedLines) {
            if (lineTypeNames.indexOf(line.lineType) < 0) {
                line.lineType = null;
                this.setLineDefaultLineType(line);
            }
        }
    }

    addNewLine(taxon: CvValue, taxonIndex: number) {
        const newLine: SDLine = {
            lineName: '',
            lineType: null,
            taxon: taxon.cvValue,
            stockNumber: '',
            isSelected: true
        };

        // Try to ensure lineType always has a value
        this.setLineDefaultLineType(newLine);

        this.selectedLines.push(newLine);
        this.onModelChange();

        this.focusOnNewValue(taxonIndex);
    }

    private focusOnNewValue(taxonIndex: number) {
        // Match last input element in the table
        const cssSelector = '#' +
            this.TABLE_ID_ROOT +
            taxonIndex +
            ' tbody tr:last-child input';
        focusElementByQuery(cssSelector, 250);
    }

    /**
     * Sets a default lineType if a line doesn't have one.
     * @param line
     */
    private setLineDefaultLineType(line: SDLine) {
        if (line && !line.lineType) {
            if (notEmpty(this.selectedLineTypes)) {
                line.lineType = this.selectedLineTypes[0].cvValue;
            }
        }
    }

    removeLine(line: SDLine) {
        const index = this.selectedLines.indexOf(line);
        if (index >= 0) {
            this.selectedLines.splice(index, 1);
        }
        this.onModelChange();
    }

    onModelChange(event?: Event) {
        this.selectedLinesChange.emit(this.selectedLines);
    }

    /**
     * Are there any duplicate line names?
     */
    duplicateLines(): boolean {
        let hasDuplicates = false;

        if (notEmpty(this.selectedLines)) {
            const seen: Set<any> = new Set();

            for (const line of this.selectedLines) {
                if (line.lineName != null) {
                    // Case-insensitive
                    const val = line.lineName.toLowerCase();

                    if (!seen.has(val)) {
                        seen.add(val);
                    } else {
                        // Duplicate!
                        hasDuplicates = true;
                        break;
                    }
                }
            }
        }

        return hasDuplicates;
    }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { ClimbCommonModule } from '@common/climb-common.module';
import { ClimbServicesModule } from '@services/climb-services.module';
import { LoginService } from './services/login.service';
import { OAuthModule } from 'angular-oauth2-oidc';
import { SSOModule } from '../sso/sso.module';
import { SSOService } from '../sso/sso.service';
import { LoginViewComponent } from './components/login-view/login-view.component';
import { LoggedOutViewComponent } from './components/logged-out-view/logged-out-view.component';
import { LoginSamlComponent } from './components/login-saml/login-saml.component';
import { SsoProviderButtonComponent } from './components/sso-provider-button/sso-provider-button.component';
import { LoginOktaComponent } from "./components/login-okta/login-okta.component";

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        ClimbCommonModule,
        ClimbServicesModule,
    ],
    declarations: [
        LoginViewComponent,
        LoginSamlComponent,
        LoginOktaComponent,
        LoggedOutViewComponent,
        SsoProviderButtonComponent,
    ],
    exports: [
        LoginViewComponent,
        LoginSamlComponent,
        LoginOktaComponent,
        LoggedOutViewComponent
    ],
    providers: [
        LoginService,
        OAuthModule,
        SSOModule,
        SSOService,
    ]
})
export class LoginModule { }

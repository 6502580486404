import { LuxonInput } from './date-time-formatting/types/luxon-input.type';
import { convertValueToLuxon } from './date-time-formatting/convert-value-to-luxon';

/**
 *  Are two dates the same (ignoring time)
 *
 *  Accepts null/invalid input.
 */
export function datesEqual(date1: LuxonInput, date2: LuxonInput): boolean {
    let d1 = convertValueToLuxon(date1);
    let d2 = convertValueToLuxon(date2);

    if (!d1 || !d2) {
        return false;
    }

    // ignore time
    d1 = d1.startOf('day');
    d2 = d2.startOf('day');
    
    return d1.hasSame(d2, "day") && d1.hasSame(d2, "month") && d1.hasSame(d2, "year");
}

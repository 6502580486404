<div class="bulk-edit-wrapper">
    <div class="bulk-edit-grid">
        <bulk-add #bulkAdd
                  [options]="bulkTemplates.bulkOptions"
                  (save)="saveClicked($event)"
                  (exit)="exitClicked($event)">
        </bulk-add>
        <div class="form-group row mt-3">
            <div class="col-md-12">
                <table class="table">
                    <thead>
                        <tr class="detail-grid-header">
                            <th style="width:35px;">
                                <a class="add-item-link detail-grid-button-label"
                                *ngIf="facet.Privilege!=='ReadOnly'"
                                (click)="addObservation()">
                                    <i data-automation-id="add-new-clinical-observation-button" class="fa fa-plus-circle" title="Add new clinical observation"></i>
                                </a>
                            </th>
                            <th colspan="6">
                                <div class="detail-grid-button-label">Clinical Observations</div>
                            </th>
                        </tr>
                        <tr class="detail-grid-header">
                            <th>&nbsp;</th>
                            <th>Date</th>
                            <th>Observation Status</th>
                            <th colspan="2">Observations</th>
                            <th>Comments</th>
                            <th>Observed By</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let observation of observations | orderBy:'DateObserved':true">
                            <td class="icon-cell">
                                <div class="form-control-plaintext">
                                    <a (click)="removeObservation(observation)" *ngIf="facet.Privilege!=='ReadOnly'">
                                        <i class="fa fa-remove remove-item" title="Delete this observation"></i>
                                    </a>
                                </div>
                            </td>
                            <td style="width:70px;">
                                <climb-ngb-date #dateControl="ngModel" name="Date Observed" [(ngModel)]="observation.DateObserved"></climb-ngb-date>
                            </td>
                            <td style="width:140px;">
                                <active-vocab-select [(model)]="observation.C_ClinicalObservationStatus_key"
                                                     [fullWidth]="false"
                                                     [dynamicWidth]="true"
                                                     [vocabChoices]="clinicalObservationStatuses"
                                                     [keyFormatter]="observationStatusKeyFormatter"
                                                     [optionFormatter]="observationStatusFormatter"
                                                     [nullable]="true"></active-vocab-select>
                            </td>
                            <td (click)="onObservationClicked(observation)">
                                <ng-container>
                                    <div class="form-values-static">
                                        <span *ngIf="observation.ClinicalObservationDetail | notEmpty">
                                            {{observation.ClinicalObservationDetail | map:'cv_ClinicalObservation' | map:'ClinicalObservation' | delimit:', '}}
                                        </span>
                                    </div>
                                    <clinical-multiselect [selectedObservations]="observation.ClinicalObservationDetail"
                                                          (onSelect)="onSelectObservations($event)"></clinical-multiselect>
                                </ng-container>
                            </td>
                            <td class="text-center" style="width:30px;vertical-align:top;">
                                <button type="button" class="btn btn-primary"
                                        style="height:50px;width:25px;margin:1px 0;padding:0;"
                                        (click)="openObservationChooser(observationmodal, observation)">
                                    ...
                                </button>
                            </td>
                            <td>
                                <textarea name="comments-{{observation.C_AnimalClinicalObservation_key}}"
                                          [(ngModel)]="observation.Comments"
                                          class="form-control input-large"></textarea>
                            </td>
                            <td style="width:60px">
                                <active-vocab-select [(model)]="observation.C_Resource_key"
                                                     [fullWidth]="false"
                                                     [dynamicWidth]="true"
                                                     [vocabChoices]="resources"
                                                     [keyFormatter]="resourceKeyFormatter"
                                                     [optionFormatter]="resourceNameFormatter"></active-vocab-select>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>

<observation-bulk-templates #bulkTemplates [animals]="sourceMaterials" [observations]="[observations]"></observation-bulk-templates>

<ng-template #itemsToAddTmpl
    let-state="state"
    let-itemTypeLabel="itemTypeLabel">
    <div class="row">
        <div class="col-md-6"
                droppable
                (drop)="onDropSourceMaterials($event)"
                title="Drop source animals here">
            <h5>Add Observations from Source
                <info-tooltip [text]="'Drop or paste source animals to create each observation'"></info-tooltip>
            </h5>
            <div class="dropzone">
                <animal-multiselect [(model)]="sourceMaterials"
                                    (modelChange)="onSelectSourceMaterials()">
                    <button extra-buttons
                        (click)="pasteSourceMaterials()"
                        class="btn btn-secondary">
                        <i class="fa fa-paste" title="Paste source animals"></i>
                    </button>
                </animal-multiselect>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #observationmodal let-c="close">
    <observation-chooser [selectedObservations]="selectedObservation.ClinicalObservationDetail"
                         (close)="c('Close')"
                         (onSelect)="onSelectObservations($event)"></observation-chooser>
</ng-template>

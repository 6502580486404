import { ApiResponse } from './../services/models/api-response';
import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    EntityQuery
} from 'breeze-client';

import { notEmpty } from '../common/util';

import { AuthService } from '../services/auth.service';
import { WebApiService } from '../services/web-api.service';
import { DataManagerService } from '../services/data-manager.service';
import { SearchQueryDef } from './search-query-def';
import { BaseEntityService } from '../services/base-entity.service';

@Injectable()
export class SearchService extends BaseEntityService {

    readonly SEARCH_API_BASE = 'api/search';
    readonly DEFAULT_PAGE = 1;
    readonly DEFAULT_SEARCH_LIMIT = 50;
    readonly DEFAULT_SORT_DIRECTION = 'asc';


    constructor(
        private dataManager: DataManagerService,
        private authService: AuthService,
        private webApiService: WebApiService
    ) {
        super();
    }

    getEntitiesBySearch(searchQueryDef: SearchQueryDef): Promise<ApiResponse> {
        const params: HttpParams = this.buildURLSearchParams(searchQueryDef);
    
        return this.getSearchResults(searchQueryDef.entity, params);
    }

    private getSearchResults(entityName: string, params: HttpParams): Promise<ApiResponse> {
        const apiUrl = this.SEARCH_API_BASE + '/' + entityName;

        return this.webApiService.callApi(apiUrl, params);
    }

    private buildURLSearchParams(searchQueryDef: SearchQueryDef): HttpParams {
        let params: HttpParams =
            this.webApiService.buildURLSearchParams(searchQueryDef.filter);

        if (searchQueryDef.sortColumn) {
            params = params.set('SortColumn', searchQueryDef.sortColumn);
        }
        if (searchQueryDef.sortDirection) {
            params = params.set('SortDirection', searchQueryDef.sortDirection);
        }
        if (searchQueryDef.page) {
            params = params.set('PageNumber', searchQueryDef.page.toString());
        }
        if (searchQueryDef.size) {
            params = params.set('NumberRecords', searchQueryDef.size.toString());
        }
        if (searchQueryDef.isActive) {
            params = params.set('IsActive', searchQueryDef.isActive.toString());
        }

        return params;
    }

    getCountBySearch(entity: string, filter: any): Promise<any> {
        const params: HttpParams = this.filterToUrlSearchParams(filter);

        return this.getSearchResults(entity + 'count', params).then((results: any) => {
            return results.data;
        });
    }

    private filterToUrlSearchParams(filter: any): HttpParams {
        let params: HttpParams = new HttpParams();

        if (notEmpty(filter)) {
            Object.keys(filter).forEach((key, index) => {
                params = params.set(key, filter[key]);
            });
        }

        return params;
    }

    searchAnimals(animalName: string): Promise<ApiResponse> {
        const thisFilter = {
            AnimalName: animalName
        };
        const searchQueryDef: SearchQueryDef = {
            entity: 'Animals',
            page: this.DEFAULT_PAGE,
            size: this.DEFAULT_SEARCH_LIMIT,
            sortColumn: 'AnimalNameSortable',
            sortDirection: this.DEFAULT_SORT_DIRECTION,
            filter: thisFilter
        };

        return this.getEntitiesBySearch(searchQueryDef);
    }

    searchAnimalByMaterialKey(materialKey: number): Promise<ApiResponse> {
        const thisFilter = {
            MaterialKey: materialKey
        };
        const searchQueryDef: SearchQueryDef = {
            entity: 'Animals',
            page: this.DEFAULT_PAGE,
            size: this.DEFAULT_SEARCH_LIMIT,
            sortColumn: 'AnimalNameSortable',
            sortDirection: this.DEFAULT_SORT_DIRECTION,
            filter: thisFilter
        };

        return this.getEntitiesBySearch(searchQueryDef);
    }

    getIsExitStatus(materialKey: number): Promise<ApiResponse> {
        const thisFilter = {
            MaterialKey: materialKey
        };
        const searchQueryDef: SearchQueryDef = {
            entity: 'getIsExitStatus',           
            filter: thisFilter
        };

        return this.getEntitiesBySearch(searchQueryDef);
    }

    searchActiveConstructs(fullName: string): Promise<ApiResponse> {
        const thisFilter = {
            FullName: fullName,
            IsActive: true
        };
        const searchQueryDef: SearchQueryDef = {
            entity: 'Constructs',
            page: this.DEFAULT_PAGE,
            size: this.DEFAULT_SEARCH_LIMIT,
            sortColumn: 'FullName',
            sortDirection: this.DEFAULT_SORT_DIRECTION,
            filter: thisFilter
        };

        return this.getEntitiesBySearch(searchQueryDef);
    }

    searchJobs(jobId: string, isLocked?: boolean): Promise<ApiResponse> {
        const thisFilter: any = {
            JobID: jobId
        };
        if (isLocked === true || isLocked === false) {
            thisFilter.IsLocked = isLocked;
        }

        const searchQueryDef: SearchQueryDef = {
            entity: 'Jobs',
            page: this.DEFAULT_PAGE,
            size: this.DEFAULT_SEARCH_LIMIT,
            sortColumn: 'JobID',
            sortDirection: this.DEFAULT_SORT_DIRECTION,
            filter: thisFilter
        };

        return this.getEntitiesBySearch(searchQueryDef);
    }

    searchActiveLines(lineName: string): Promise<ApiResponse> {
        const thisFilter = {
            LineName: lineName,
            IsActive: true
        };
        const searchQueryDef: SearchQueryDef = {
            entity: 'Lines',
            page: this.DEFAULT_PAGE,
            size: this.DEFAULT_SEARCH_LIMIT,
            sortColumn: 'LineName',
            sortDirection: this.DEFAULT_SORT_DIRECTION,
            filter: thisFilter
        };

        return this.getEntitiesBySearch(searchQueryDef);
    }

    searchLocations(positionName: string): Promise<ApiResponse> {
        const thisFilter = { PositionName: positionName };
        const searchQueryDef: SearchQueryDef = {
            entity: 'Locations',
            page: this.DEFAULT_PAGE,
            size: this.DEFAULT_SEARCH_LIMIT,
            sortColumn: 'PositionName',
            sortDirection: this.DEFAULT_SORT_DIRECTION,
            filter: thisFilter
        };

        return this.getEntitiesBySearch(searchQueryDef);
    }

    searchLocationsExcludeNodeSubtree(
        positionName: string,
        rootLocationPositionKey: number
    ): Promise<ApiResponse> {
        const thisFilter = {
            ParentLocationKey: rootLocationPositionKey,
            PositionName: positionName
        };
        const searchQueryDef: SearchQueryDef = {
            entity: 'LocationsExcludeNodeSubtree',
            page: this.DEFAULT_PAGE,
            size: this.DEFAULT_SEARCH_LIMIT,
            sortColumn: 'PositionName',
            sortDirection: this.DEFAULT_SORT_DIRECTION,
            filter: thisFilter
        };

        return this.getEntitiesBySearch(searchQueryDef);
    }

    searchPlates(plateId: string): Promise<ApiResponse> {
        const thisFilter = {
            PlateID: plateId
        };
        const searchQueryDef: SearchQueryDef = {
            entity: 'Plates',
            page: this.DEFAULT_PAGE,
            size: this.DEFAULT_SEARCH_LIMIT,
            sortColumn: 'PlateID',
            sortDirection: this.DEFAULT_SORT_DIRECTION,
            filter: thisFilter
        };

        return this.getEntitiesBySearch(searchQueryDef);
    }

    searchStudies(studyName: string): Promise<ApiResponse> {
        const thisFilter = {
            StudyName: studyName
        };
        const searchQueryDef: SearchQueryDef = {
            entity: 'Studies',
            page: this.DEFAULT_PAGE,
            size: this.DEFAULT_SEARCH_LIMIT,
            sortColumn: 'StudyName',
            sortDirection: this.DEFAULT_SORT_DIRECTION,
            filter: thisFilter
        };

        return this.getEntitiesBySearch(searchQueryDef);
    }

    searchSamples(sampleName: string): Promise<ApiResponse> {
        const thisFilter = {
            SampleName: sampleName
        };
        const searchQueryDef: SearchQueryDef = {
            entity: 'Samples',
            page: this.DEFAULT_PAGE,
            size: this.DEFAULT_SEARCH_LIMIT,
            sortColumn: 'SampleName',
            sortDirection: this.DEFAULT_SORT_DIRECTION,
            filter: thisFilter
        };

        return this.getEntitiesBySearch(searchQueryDef);
    }

    getAdvancedSearchSpecification(typeName: string): Promise<ApiResponse> {
        const apiUrl = this.SEARCH_API_BASE + '?objectType=' + typeName;
        return this.webApiService.callApi(apiUrl);
    }

    performAdvancedSearch(searchSpecification: any): Promise<ApiResponse> {
        const apiUrl = this.SEARCH_API_BASE;

        return this.webApiService.postApi(apiUrl, searchSpecification);
    }

    getUserSearches(): Promise<any[]> {
        const userId = this.authService.getCurrentUserId();
        const query = EntityQuery.from('UserSearches')
            .where('UserName', '==', userId);

        return this.dataManager.returnQueryResults(query);
    }

    addUserSearch(searchName: string, searchObject: string): any {
        const userId = this.authService.getCurrentUserId();
        const initialValues = {
            UserSearchName: searchName,
            SearchObject: searchObject,
            UserName: userId
        };

        return this.dataManager.createEntity('UserSearch', initialValues);
    }

    deleteUserSearch(userSearch: any) {
        this.dataManager.deleteEntity(userSearch);
    }
}

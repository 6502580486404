<!-- Table for Test Articles -->
<div class="form-group row">
    <label class="col-md-2 col-form-label"
           [ngClass]="{'required-text' : required}">Test Articles</label>
    <div class="col-md-10">
        <table class="table">
            <thead>
                <tr>
                    <th class="icon-cell col-size-fit">
                        <a (click)="addJobTestArticle(job)"
                           class="add-item-link"
                           *ngIf="!readonly">
                            <i class="fa fa-plus-circle" title="Add Test Article and Batch"></i>
                        </a>
                    </th>
                    <th *ngIf="isCRL">ID</th>
                    <th>Test Article</th>
                    <th>Batch</th>
                    <th *ngIf="isCRO">Vehicle</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let jobTestArticle of job.JobTestArticle">
                    <ng-container *ngIf="jobTestArticle.C_TestArticle_key !== systemGeneratedTestArticleKey">
                        <td>
                            <a *ngIf="!readonly && (!jobTestArticle.UsedInTreatment || !isCRO)" (click)="removeTestArticle(jobTestArticle)">
                                <i class="fa fa-fw fa-remove remove-item"
                                   title="Delete Test Article"></i>
                            </a>
                        </td>
                        <td *ngIf="isCRL">
                            {{jobTestArticle.Identifier}}
                        </td>
                        <td *ngIf="jobTestArticle.C_TestArticle_key !== systemGeneratedTestArticleKey">
                            <test-article-select [(model)]="jobTestArticle.C_TestArticle_key"
                                                 [required]="required"
                                                 [disabled]="jobTestArticle.UsedInTreatment && isCRO">
                            </test-article-select>
                        </td>
                        <td>
                            <input type="text"
                                   name="batch"
                                   #name="ngModel"
                                   [(ngModel)]="jobTestArticle.Batch"
                                   [readonly]="readonly"
                                   [disabled]="jobTestArticle.UsedInTreatment && isCRO"
                                   class="form-control input-medium" />
                        </td>
                        <td *ngIf="isCRO">
                            <input type="text"
                                   name="vehicle"
                                   #name="ngModel"
                                   [(ngModel)]="jobTestArticle.Vehicle"
                                   [readonly]="readonly"
                                   [disabled]="jobTestArticle.UsedInTreatment && isCRO"
                                   class="form-control input-medium" />
                        </td>
                    </ng-container>
                </tr>
            </tbody>
        </table>
    </div>
</div>

// Modified file upload with one file max and no StoredFile connection
import {
    Component,
    Input,
    Output,
    EventEmitter,
    OnInit,
} from '@angular/core';

import { DropzoneCommService } from '../../common/file/dropzone-comm.service';

import { apiServiceBaseUri } from '../../config/climb-web-settings';
import { DateTime } from 'luxon';

@Component({
    selector: 'import-file-upload',
    templateUrl: 'import-file-upload.component.html',
    providers: [DropzoneCommService]
})
export class ImportFileUploadComponent implements OnInit {
    readonly uploadApiUrl = apiServiceBaseUri + "api/file/import-mapping/upload";

    /* The user's privilege level for the containing facet */
    @Input() facetPrivilege: string;
    @Input() readonly: boolean;
    @Output() fileChange: EventEmitter<string> = new EventEmitter<string>();
    file: any;

    constructor(
        private dropzoneCommService: DropzoneCommService
    ) { }

    ngOnInit() {
        this.file = null;
    }

    addFile(event: any) {
        // Cannot have more than one thing in the dropzone at a time
        if (this.file !== null) {
            this.dropzoneCommService.removeFile(this.file);
        }

        this.file = {
            name: event.description,
            internalName: this.processFilename(event.fileData),
            dateCreated: DateTime.now()
        };
        this.fileChange.emit(this.file.internalName);
    }

    /**
     * Removes double quotes around filenames with spaces
     * @param name
     */
    processFilename(name: string) {
        if (name[0] === "\"" && name.slice(-1) === "\"") {
            return name.slice(1, name.length - 1);
        } else {
            return name;
        }
    }

    removeFile() {
        this.dropzoneCommService.removeAllFiles();
        this.dropzoneRemovedFile();
    }

    dropzoneRemovedFile() {
        // TODO: delete file from staging container
        this.file = null;
        this.fileChange.emit(null);
    }

    refresh() {
        this.removeFile();
    }
}

import { Injectable } from '@angular/core';
import {
    NgbModal,
    NgbModalOptions
} from '@ng-bootstrap/ng-bootstrap';
import { MultipleSessionModalComponent } from './components/multiple-session-modal.component';
import { ReloadModalComponent } from './components/reload-modal.component';


@Injectable()
export class MultipleTabModalOpenService {
  private modalOptions: NgbModalOptions = {
    backdrop: 'static',
    size: 'md',
  };

  constructor(private modalService: NgbModal) {}

  openMultipleSessionModal() {
    return this.modalService.open(MultipleSessionModalComponent, this.modalOptions);
  }

  openReloadModal(): Promise<void> {
    const modalRef = this.modalService.open(ReloadModalComponent, this.modalOptions);
    return modalRef.result;
  }
}

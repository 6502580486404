import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
} from '@angular/core';

import { TableSort } from '../../common/models';

@Component({
    selector: 'location-child-table',
    templateUrl: './location-child-table.component.html'
})
export class LocationChildTableComponent implements OnInit, OnChanges {
    @Input() childLocations: any[];
    @Input() filter: any;
    @Input() tableSort: TableSort;

    @Output() clickLocation: EventEmitter<any> = new EventEmitter<any>();

    filteredChildLocations: any[];

    constructor() {
        // do nothing
    }

    ngOnInit() {
        this.initialize();
    }

    ngOnChanges() {
        this.initialize();
    }

    initialize() {
        if (!this.childLocations) {
            this.childLocations = [];
        }

        if (!this.filter) {
            this.filter = {};
        }

        this.filterChildLocations();
    }

    filterChanged() {
        this.filterChildLocations();
    }

    filterChildLocations() {
        this.filteredChildLocations = this.childLocations.filter(location => {
            let keep = true;
            if (this.filter.PositionName) {
                keep = keep && this.stringContains(
                    location.PositionName, this.filter.PositionName, 
                );
            }

            if (this.filter.LocationType) {
                keep = keep && this.stringContains(
                    location.LocationType, this.filter.LocationType, 
                );
            }

            return keep;
        });
    }

    clickedLocation(location: any) {
        this.clickLocation.emit(location);
    }


    stringContains(str1: string | null | undefined, str2: string | null | undefined): boolean {
        if (!str1 || !str2) {
            return true;
        }
        str1 = str1 ? str1.toLowerCase() : '';
        str2 = str2 ? str2.toLowerCase() : '';
        return str1.indexOf(str2) >= 0;
    }

  }

import { Expression } from './expression-models';
import { calculateResultFactory } from './expression-types';

export function calculateExpressionResult(
    expr: Expression,
    inputMappings: any[] = [],
    taskInputs: any[] = [],
    outputMappings: any[] = [],
    taskOutputs: any[] = [],
): number {
    if (expr && !expr.validationError && expr.parsedExpression) {
        // build a variable associative array for quick lookup
        const varAssocArr = {};
        const inputValueAssocArr = {};
        for (const taskInput of taskInputs) {
            inputValueAssocArr[taskInput.C_Input_key] = parseFloat(taskInput.InputValue);
        }

        for (const inputMapping of inputMappings) {
            const currInputKey = `${inputMapping.C_Input_key}`;
            if (inputValueAssocArr.hasOwnProperty(currInputKey)) {
                varAssocArr[inputMapping.ExpressionVariableName] = inputValueAssocArr[currInputKey];
            }
        }

        const outputValueAssocArr = {};
        for (const taskOutput of taskOutputs) {
            outputValueAssocArr[taskOutput.C_Output_key] = parseFloat(taskOutput.OutputValue);
        }

        for (const outputMapping of outputMappings) {
            const currOutputKey = `${outputMapping.C_Output_key}`;
            if (outputValueAssocArr.hasOwnProperty(currOutputKey)) {
                varAssocArr[outputMapping.ExpressionVariableName] =
                    outputValueAssocArr[currOutputKey];
            }
        }

        return calculateResultFactory(expr.parsedExpression, varAssocArr);
    }

    return NaN;
}

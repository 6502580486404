import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

/**
 * Comm service for task table components
 */
@Injectable()
export class TaskTableCommService {

    private refreshMaterialSelectsSource = new Subject<void>();
    refreshMaterialSelects$ = this.refreshMaterialSelectsSource.asObservable();
    /** 
     * Refresh all the task-output-set-material-select components.
     * Reassigns the single material selector model values to keep them in sync 
     */
    refreshMaterialSelects() {
        this.refreshMaterialSelectsSource.next();
    }

}

import type { EnumerationClass } from './enumeration-class.interface';
import type { SampleCharacteristicInstance } from './sample-characteristic-instance.interface';
import type { cv_DataType } from './cv-data-type.interface';
import type { cv_Taxon } from './cv-taxon.interface';
import type { cv_Unit } from './cv-unit.interface';
import type { SampleCharacteristicSampleType } from './sample-characteristic-sample-type.interface';

export interface SampleCharacteristic {
    C_DataType_key: number;
    C_EnumerationClass_key: number | null;
    C_SampleCharacteristic_key: number;
    C_Unit_key: number | null;
    C_Workgroup_key: number;
    CharacteristicName: string;
    CreatedBy: string;
    DateCreated: Date;
    DateLastReviewed: Date | null;
    DateModified: Date;
    Description: string;
    EnumerationClass: EnumerationClass;
    HasSampleType: boolean;
    SampleTypes: string;
    IsActive: boolean;
    ModifiedBy: string;
    NumericScale: number | null;
    SampleCharacteristicInstance: SampleCharacteristicInstance[];
    SortOrder: number | null;
    TextLineCount: number | null;
    ValidationMaximum: string;
    ValidationMinimum: string;
    Version: number;
    cv_DataType: cv_DataType;
    cv_Taxon: cv_Taxon;
    cv_Unit: cv_Unit;
    SampleCharacteristicSampleType: SampleCharacteristicSampleType[];
}

import { Injectable } from '@angular/core';

import { EnumerationService } from '../enumerations/enumeration.service';
import { JobService } from '../jobs/job.service';
import { LineService } from '../lines/line.service';
import { SampleService } from './sample.service';

import {
    animalAgeWeeks,
    getSafeProp,
    notEmpty,
} from '../common/util';

/**
 * Common business logic for the Sample facet.
 */
@Injectable()
export class SampleLogicService {

    constructor(
        private enumerationService: EnumerationService,
        private jobService: JobService,
        private lineService: LineService,
        private sampleService: SampleService,
    ) {
        //
    }

    onJobSelection(jobKey: number): Promise<any> {
        if (jobKey) {
            return this.jobService.getJob(jobKey).then((data) => {
                return data;
            });
        } else {
            return Promise.resolve();
        }
    }

    onLineSelection(lineKey: number, sample: any): Promise<any> {
        if (!lineKey) {
            return Promise.resolve();
        }

        // Set sample taxon based on its line
        return this.getLineByKey(lineKey).then((fullLine) => {
            if (sample) {
                sample.Material.cv_Taxon = fullLine.cv_Taxon;
            }
        });
    }

    getLineByKey(lineKey: number): Promise<any> {
        return this.lineService.getLine(lineKey).then((data) => {
            return data;
        });
    }

    onSourceSelection(sample: any, sourceMaterial: any): Promise<void> {
        const p1 = this.setSampleLineFromSource(sample, sourceMaterial);
        const p2 = this.setSampleTaxonFromSource(sample, sourceMaterial);
        this.setSampleJobsFromSource(sample, sourceMaterial);

        return Promise.all([p1, p2]).then(() => {
            // return void
        });
    }

    private setSampleLineFromSource(sample: any, sourceMaterial: any): Promise<void> {
        if (sourceMaterial.Material.C_Line_key) {
            return this.getLineByKey(sourceMaterial.Material.C_Line_key).then((line) => {
                sample.Material.Line = line;
            });
        }
    }

    private setSampleTaxonFromSource(sample: any, sourceMaterial: any): Promise<void> {
        if (sourceMaterial.Material.C_Line_key) {
            return this.getLineByKey(sourceMaterial.Material.C_Line_key).then((line) => {
                sample.Material.cv_Taxon = line.cv_Taxon;
            });
        }
    }

    private setSampleJobsFromSource(sample: any, sourceMaterial: any) {
        // Don't set jobs if this sample already has one
        if (notEmpty(sample.Material.JobMaterial)) {
            return;
        }

        if (sourceMaterial.Material.JobMaterial) {
            for (const jobMaterial of sourceMaterial.Material.JobMaterial) {
                const initialValues = {
                    C_Job_key: jobMaterial.C_Job_key,
                    C_Material_key: sample.C_Material_key
                };
                this.jobService.createJobMaterial(initialValues);
            }
        }
    }

    loadCharacteristics(sample: any): Promise<any> {
        return this.sampleService.getSampleCharacteristics(sample.C_Material_key).then((data) => {
            sample.SampleCharacteristics = data;
            return this.attachEnumerations(sample.SampleCharacteristics);
        });
    }

    onTypeChange(sample: any): Promise<any[]> {
        if (sample.SampleCharacteristicInstance) {
            while (sample.SampleCharacteristicInstance.length > 0) {
                const characteristicInstance = sample.SampleCharacteristicInstance[0];
                characteristicInstance.entityAspect.setDeleted();
            }
        }

        return this.createCharacteristics(sample);
    }

    attachEnumerations(characteristics: any[]): Promise<any[]> {
        const promises: any[] = [];
        for (const characteristic of characteristics) {
            if (characteristic.SampleCharacteristic.C_EnumerationClass_key) {
                const promise = this.enumerationService.getEnumerationItems(
                    characteristic.SampleCharacteristic.C_EnumerationClass_key
                ).then((items) => {
                    characteristic.EnumerationItems = items;
                });

                promises.push(promise);
            }
        }

        return Promise.all(promises);
    }

    createCharacteristics(sample: any): Promise<any[]> {
        return this.sampleService.createSampleCharacteristics(sample).then((data) => {
            if (data) {
                return this.attachEnumerations(data);
            }
        });
    }

    addJobMaterial(jobKey: number, animal: any): Promise<any> {
        const initialValues = {
            C_Material_key: animal.Material.C_Material_key,
            C_Job_key: jobKey
        };
        const newJobMaterial = this.jobService.createJobMaterial(initialValues);

        if (newJobMaterial) {
            animal.Material.JobMaterial.push(newJobMaterial);
            return this.jobService.getJob(jobKey).then((data) => {
                newJobMaterial.Job = data;
            });
        } else {
            return Promise.resolve();
        }
    }

    getSourceAgeInWeeksAtHarvest(sample: any): string {
        if (!sample || !sample.DateHarvest) {
            return "";
        }

        // The sample must have source materials and none can be a sample
        const materialSourceMaterials: any[] = getSafeProp(sample, 'Material.MaterialSourceMaterial');
        if (!materialSourceMaterials ||
            materialSourceMaterials === undefined ||
            materialSourceMaterials.length === 0 ||
            this.materialSourceMaterialsContainsSamples(materialSourceMaterials)
        ) {
            return "";
        }

        // There must be only one unique DateBorn value
        const dateBorns: Date[] = this.getUniqueSourceAnimalBornDates(materialSourceMaterials);
        if (dateBorns.length !== 1) {
            return "";
        }

        return animalAgeWeeks(dateBorns[0], sample.DateHarvest);
    }

    private materialSourceMaterialsContainsSamples(materialSourceMaterials: any[]): boolean {
        if (notEmpty(materialSourceMaterials)) {
            for (const materialSourceMaterial of materialSourceMaterials) {
                const sample = getSafeProp(materialSourceMaterial, 'SourceMaterial.Sample');

                if (sample) {
                    return true;
                }
            }
        }

        return false;
    }

    private getUniqueSourceAnimalBornDates(materialSourceMaterials: any[]): Date[] {
        const dateBorns: Date[] = [];

        if (notEmpty(materialSourceMaterials)) {
            for (const materialSourceMaterial of materialSourceMaterials) {
                const animal = getSafeProp(materialSourceMaterial, 'SourceMaterial.Animal');

                if (animal && animal.DateBorn) {
                    const dateBorn = animal.DateBorn;
                    if (!this.isDateInArray(dateBorn, dateBorns)) {
                        dateBorns.push(dateBorn);
                    }
                }
            }
        }

        return dateBorns;
    }

    private isDateInArray(newDate: Date, dateArray: Date[]): boolean {
        if (newDate) {
            const newDateTime = newDate.getTime();

            for (const item of dateArray) {
                if (newDateTime === item.getTime()) {
                    return true;
                }
            }
        }

        return false;
    }
}

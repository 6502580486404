import { Injectable } from '@angular/core';
import {
    EntityQuery,
    Predicate,
    QueryResult
} from 'breeze-client';

import { notEmpty } from '../common/util/not-empty';

import { DataManagerService } from '../services/data-manager.service';
import { QueryDef } from '../services/query-def';
import { BaseEntityService } from '../services/base-entity.service';
import { AuthService } from '../services/auth.service';
import { getBooleanPredicate } from '../services/queries';

@Injectable()
export class ResourceService extends BaseEntityService {

    readonly ENTITY_TYPE = 'Resources';
    readonly ENTITY_NAME = 'Resource';


    constructor(
        private dataManager: DataManagerService,
        private authService: AuthService,
    ) {
        super();
    }

    getAllResources(): Promise<any[]> {
        const query = EntityQuery.from(this.ENTITY_TYPE)
            .expand('ResourceGroupMember.Resource')
            .orderBy('ResourceName');

        return this.dataManager.getQueryResults(query);
    }

    getAllCachedResources(): Promise<any[]> {
        return this.getAllResources().then(() => {
            // then use local cache to ensure all local entities are returned
            const preferLocal = true;
            const query = EntityQuery.from(this.ENTITY_TYPE);
            return this.dataManager.returnQueryResults(query, preferLocal);
        });
    }

    getAllResourceGroupMembers(): Promise<any[]> {
        const query = EntityQuery.from('ResourceGroupMembers');

        return this.dataManager.returnQueryResults(query);
    }

    getResources(queryDef: QueryDef): Promise<QueryResult> {
        let query = this.buildDefaultQuery(this.ENTITY_TYPE, queryDef);

        if (notEmpty(queryDef.expands)) {
            query = query.expand(queryDef.expands.join(','));
        }

        let predicates: Predicate[] = [];
        if (queryDef.filter) {
            predicates = predicates.concat(this.buildPredicates(queryDef.filter));
        }

        if (notEmpty(predicates)) {
            query = query.where(Predicate.and(predicates));
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed) as Promise<QueryResult>;
    }

    buildPredicates(filter: any): Predicate[] {
        const predicates: Predicate[] = [];

        if (!filter) {
            return predicates;
        }

        if (filter.C_Resource_key) {
            predicates.push(Predicate.create('C_Resource_key', '==', filter.C_Resource_key));
        }

        if (filter.IsGroup === true || filter.IsGroup === false) {
            predicates.push(getBooleanPredicate(filter.IsGroup, 'IsGroup'));
        }

        return predicates;
    }

    getResource(resourceKey: number, expands?: string[]): Promise<any> {
        let query = EntityQuery.from(this.ENTITY_TYPE)
            .where('C_Resource_key', '==', resourceKey);

        if (notEmpty(expands)) {
            query = query.expand(expands);
        }

        // 30 second cache
        const cacheMs = 30000;
        return this.dataManager.returnSingleQueryResultCached(query, cacheMs);
    }

    getCurrentUserResource(): Promise<any> {
        const userID: string = this.authService.getCurrentUserId();

        const query = EntityQuery.from(this.ENTITY_TYPE)
            .where('UserID', '==', userID)
            .orderBy('ResourceName');

        // 5 minute cache
        const cacheMs = 300000;
        return this.dataManager.returnSingleQueryResultCached(query, cacheMs);
    }

    async ensureVisibleColumnsDataLoaded(resources: any[], visibleColumns: string[]): Promise<void> {
        const expands = this.generateExpandsFromVisibleColumns(resources[0], visibleColumns);
        return this.dataManager.ensureRelationships(resources, expands);
    }

    createResource(): any {
        const resource = this.dataManager.createEntity(this.ENTITY_NAME);

        // Defaults
        resource.IsActive = true;
        resource.BackgroundColor = "#FFFFFF";
        resource.ForegroundColor = "#000000";

        return resource;
    }

    createResourceGroup(groupName: string): any {
        const resourceGroup = this.dataManager.createEntity(this.ENTITY_NAME);

        resourceGroup.IsGroup = true;
        resourceGroup.ResourceName = groupName;

        return resourceGroup;
    }

    createResourceGroupMember(resourceKey: number, resourceGroupKey: number): any {
        const resourceGroupMember = this.dataManager.createEntity('ResourceGroupMember');

        resourceGroupMember.C_Resource_key = resourceKey;
        resourceGroupMember.C_ParentResource_key = resourceGroupKey;

        return resourceGroupMember;
    }

    deleteResource(resource: any): void {
        this.dataManager.deleteEntity(resource);
    }

    /**
     * Cancel a newly added record
     *
     * @param resource
     */
    cancelResource(resource: any) {
        if (!resource) {
            return;
        }
        if (resource.C_Resource_key > 0) {
            this._cancelResourceEdits(resource);
        } else {
            this._cancelNewResource(resource);
        }
    }

    private _cancelResourceEdits(resource: any) {
        this.dataManager.rejectEntityAndRelatedPropertyChanges(resource);
    }

    private _cancelNewResource(resource: any) {
        try {
            this.deleteResource(resource);
        } catch (error) {
            console.error('Error cancelling new resource: ' + error);
        }
    }
}

import type { Resource } from './resource.interface';

export interface Setting {
    C_DefaultHealthTechnician_key: number | null;
    C_Setting_key: number;
    C_Workgroup_key: number;
    CreatedBy: string;
    DateCreated: Date | null;
    DateModified: Date | null;
    DefaultHealthTechnician: Resource;
    ModifiedBy: string;
    SettingName: string;
    TimeoutMinutes: number | null;
    Version: number;
}

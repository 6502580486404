import {
    Component,
    OnInit
} from '@angular/core';

import {
    NgbActiveModal
} from '@ng-bootstrap/ng-bootstrap';

/**
 * Returns the selected Output belonging to a WorkflowTask.
 */
@Component({
    selector: 'task-output-select-modal',
    templateUrl: './task-output-select-modal.component.html'
})
export class TaskOutputSelectModalComponent implements OnInit {

    selectedOutput: any;

    // The cv_DataTypes that can be selected
    readonly TARGET_DATA_TYPES: string[] = ['Calculated', 'Number'];


    constructor(
        private activeModal: NgbActiveModal
    ) {
        //
    }

    ngOnInit() {
        this.selectedOutput = null;
    }


    /**
     * Sets the selectedOutput model from the nested component's output event.
     *
     * @param output
     */
    onSelectedOutputChange(output: any) {
        this.selectedOutput = output;
    }

    /**
     * Returns the currently selected Output.
     */
    onSubmit(): void {
        this.activeModal.close(this.selectedOutput);
    }

    onCancel(): void {
        this.activeModal.dismiss();
    }
}

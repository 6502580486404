import type { Institution } from './institution.interface';
import type { Site } from './site.interface';
import type { cv_Country } from './cv-country.interface';

export interface cv_State {
    Abbreviation: string;
    C_Country_key: number;
    C_State_key: number;
    C_Workgroup_key: number;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    ElementID: string;
    Institution: Institution[];
    IsActive: boolean;
    IsDefault: boolean;
    IsDeprecated: boolean;
    ModifiedBy: string;
    Site: Site[];
    SortOrder: number | null;
    StateName: string;
    Version: number;
    cv_Country: cv_Country;
}

import { Injectable } from '@angular/core';
import { defer, Observable } from 'rxjs';

import { VocabularyService } from '../vocabularies/vocabulary.service';
import { take } from 'rxjs/operators';

@Injectable()
export class GenotypeVocabService {

    // vocab Observables
    public get genotypeAssays$(): Observable<any[]>{
        return defer(() => this.vocabularyService.getCV('cv_GenotypeAssays', 'SortOrder')).pipe(take(1));
    }
    public get genotypeSymbols$(): Observable<any[]>{
        return defer(() => this.vocabularyService.getCV('cv_GenotypeSymbols', 'SortOrder')).pipe(take(1));
    }
    public get sexes$(): Observable<any[]>{
        return defer(() => this.vocabularyService.getCV('cv_Sexes')).pipe(take(1));
    }

    constructor(
        private vocabularyService: VocabularyService
    ) {}

}

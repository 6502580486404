import type { AnimalPlaceholder } from './animal-placeholder.interface';
import type { Job } from './job.interface';
import type { JobGroupTreatment } from './job-group-treatment.interface';
import type { Line } from './line.interface';
import type { Placeholder } from './placeholder.interface';
import type { cv_MaterialOrigin } from './cv-material-origin.interface';
import type { cv_Sex } from './cv-sex.interface';

export interface JobGroup {
    AnimalPlaceholder: AnimalPlaceholder[];
    C_JobGroup_key: number;
    C_Job_key: number;
    C_Line_key: number | null;
    C_MaterialOrigin_key: number | null;
    C_Sex_key: number | null;
    C_Workgroup_key: number;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    Group: string;
    Job: Job;
    JobGroupTreatment: JobGroupTreatment[];
    Line: Line;
    MaxAge: number | null;
    MinAge: number | null;
    ModifiedBy: string;
    Number: number | null;
    Placeholder: Placeholder[];
    SortOrder: number | null;
    Version: number;
    cv_MaterialOrigin: cv_MaterialOrigin;
    cv_Sex: cv_Sex;
}

<div class="detail-form-wrapper">

    <div class="detail-header">
        <h3>Sample</h3>

        <pager-toolbar [itemName]="'sample'"
                       [logTag]="COMPONENT_LOG_TAG"
                       [first]="pageState.isFirst"
                       [last]="pageState.isLast"
                       [promptForSave]="false"
                       (previous)="onSwitchView('previous')"
                       (next)="onSwitchView('next')"></pager-toolbar>

        <detail-toolbar [facetPrivilege]="facet.Privilege"
                        [logTag]="COMPONENT_LOG_TAG"
                        (exit)="onSwitchView('exit')"
                        [promptForSave]="false"
                        [emitOnSave]="true"
                        (genericSaveEmitter)="onSaveSample()">



            <div class="btn-group" role="group"
                 aria-label="Sample actions"
                 additional-groups>
                <div class="btn-group" view-notes-button
                    [readonly]="facet.Privilege === 'ReadOnly'"
                    [pkName]="'C_Material_key'"
                    [pkValue]="sample.C_Material_key"
                    [objectName]="sample.SampleName"
                    [objectType]="'Sample'"
                    [isObjectNew]="sample | isNew"
                    appInsightsClick="click-notes-from-sample">
                </div>
                <button *ngIf="!isGLP" type="button"
                        class="btn btn-secondary"
                        (click)="viewAuditReport()">
                    <i class="fa fa-flag" aria-hidden="true" title="Audit"></i>
                    <span class="gridster-d-sm-none gridster-d-md-inline-block">Audit</span>
                    <span class="sr-only">Audit</span>
                </button>
            </div>

        </detail-toolbar>
    </div>

    <div *ngIf="sample | isDeleted"
         class="alert alert-warning"
         role="alert">
        <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
        This sample has been deleted.
    </div>

    <form *ngIf="!(sample | isDeleted)"
          name="sampleForm" id="sampleForm"
          class="detail-form"
          #sampleForm="ngForm">
        <fieldset [disabled]="facet.Privilege === 'ReadOnly'">

            <div class="form-group row"
                 *ngIf="!(sample | isNew)">
                <label class="col-md-2 col-form-label">ID</label>
                <div class="col-md-10" *ngIf="sample.Material">
                    <input type="text"
                           name="sampleID"
                           [(ngModel)]="sample.Material.Identifier"
                           class="form-control input-medium sample-detail-id"
                           readonly="readonly" />
                </div>
            </div>

            <div class="form-group row">
                <label class="col-md-2 col-form-label">Name</label>
                <div class="col-md-10">
                    <input type="text"
                           name="sampleName"
                           [(ngModel)]="sample.SampleName"
                           [disabled]="(sample | isNew) && sampleNamingActive"
                           [required]="!(sample | isNew) || !sampleNamingActive"
                           class="form-control input-medium sample-detail-name" />
                    <small class="form-text text-muted"
                           *ngIf="(sample | isNew) && sampleNamingActive">
                        The name will be assigned automatically.
                    </small>
                </div>
            </div>

            <div class="form-group row" *ngIf="activeFields.includes('Material.MaterialSourceMaterial[0]')">
                <label class="col-md-2 col-form-label"
                    [ngClass]="{'required-text' : requiredFields.includes('Material.MaterialSourceMaterial[0]') && sample.Material.MaterialSourceMaterial.length === 0}">
                    Source
                    <info-tooltip [text]="'You can add animals by Name or Microchip ID or add samples by Name.'"></info-tooltip>
                </label>
                <div class="col-md-5" *ngIf="sample">
                    <sample-source-select [sample]="sample"
                                          [readonly]="facet.Privilege === 'ReadOnly'"
                                          (selectSource)="onSourceSelection($event)"></sample-source-select>
                </div>
            </div>

            <div class="form-group row" *ngIf="activeFields.includes('Age')">
                <label class="col-md-2 col-form-label">
                    Age
                    <span class="text-nowrap">
                        (weeks)
                        <info-tooltip [text]="'The age of animal(s) at time of sample harvest. The source must contain only animals which have the same birth date.'"></info-tooltip>
                    </span>
                </label>
                <div class="col-md-10">
                    <p class="form-control-plaintext">
                        {{getSourceAgeInWeeksAtHarvest() || 'N/A'}}
                    </p>
                </div>
            </div>

            <div class="form-group row" *ngIf="activeFields.includes('Location')">
                <label class="col-md-2 col-form-label">Location</label>
                <div class="col-md-10">
                    <p class="form-control-plaintext">
                        {{sample.Material?.CurrentLocationPath}}
                    </p>
                </div>
            </div>

            <div class="form-group row" *ngIf="activeFields.includes('Material.MaterialLocation[0]')">
                <label class="col-md-2 col-form-label"
                       [ngClass]="{'required-text' : requiredFields.includes('Material.MaterialLocation[0]') && sample.Material.MaterialLocation.length === 0}">Location History</label>
                <div class="col-md-10" *ngIf="sample.Material">
                    <location-history #locationHistory
                                      [materialLocations]="sample.Material.MaterialLocation"
                                      [facetPrivilege]="facet.Privilege"
                                      (createMaterialLocation)="createMaterialLocation()"
                                      (removeMaterialLocation)="removeLocation($event)"></location-history>
                </div>
            </div>

            <div class="form-group row">
                <label class="col-md-2 col-form-label">Type</label>
                <div class="col-md-10">
                    <active-vocab-select [(model)]="sample.C_SampleType_key"
                                         [vocabChoices]="sampleTypes"
                                         [keyFormatter]="sampleTypeKeyFormatter"
                                         [optionFormatter]="sampleTypeFormatter"
                                         [required]="true"></active-vocab-select>
                </div>
            </div>

            <div class="form-group row" *ngIf="activeFields.includes('TimePoint')">
                <label class="col-md-2 col-form-label">Time Point</label>
                <div class="col-md-2">
                    <input data-automation-id="time-length-input"
                           type="number"
                           min="0"
                           step="any"
                           name="timePoint"
                           [(ngModel)]="sample.TimePoint"
                           decimalFormatter
                           [decimalPlaces]="3"
                           class="form-control input-medium sample-detail-timepoint"
                           [required]="requiredFields.includes('TimePoint')" />
                </div>
                <div class="col-md-2">
                    <active-vocab-select data-automation-id="time-unit-dropdown"
                                         [(model)]="sample.C_TimeUnit_key"
                                         [vocabChoices]="timeUnits"
                                         [keyFormatter]="timeUnitKeyFormatter"
                                         [optionFormatter]="timeUnitFormatter"
                                         [required]="requiredFields.includes('TimePoint')"
                                         [nullable]="!requiredFields.includes('TimePoint')"></active-vocab-select>
                </div>
            </div>

            <div class="form-group row" *ngIf="activeFields.includes('DateHarvest')">
                <label class="col-md-2 col-form-label">Harvest Date</label>
                <div class="col-md-4">
                    <climb-ngb-date #dateControl="ngModel"
                                    name="Harvest Date"
                                    [(ngModel)]="sample.DateHarvest"
                                    [required]="requiredFields.includes('DateHarvest')"></climb-ngb-date>
                </div>
            </div>

            <div class="form-group row" *ngIf="activeFields.includes('C_PreservationMethod_key')">
                <label class="col-md-2 col-form-label">Preservation Method</label>
                <div class="col-md-10">
                    <active-vocab-select [(model)]="sample.C_PreservationMethod_key"
                                         [vocabChoices]="preservationMethods"
                                         [keyFormatter]="preservationMethodKeyFormatter"
                                         [optionFormatter]="preservationMethodFormatter"
                                         [required]="requiredFields.includes('C_PreservationMethod_key')"
                                         [nullable]="!requiredFields.includes('C_PreservationMethod_key')"></active-vocab-select>
                </div>
            </div>

            <div class="form-group row" *ngIf="activeFields.includes('DateExpiration')">
                <label class="col-md-2 col-form-label">Expiration Date</label>
                <div class="col-md-4">
                    <climb-ngb-date #dateControl="ngModel"
                                    name="Expiration Date"
                                    [(ngModel)]="sample.DateExpiration"
                                    [required]="requiredFields.includes('DateExpiration')"></climb-ngb-date>
                </div>
            </div>

            <div class="form-group row" *ngIf="activeFields.includes('Material.C_Line_key')">
                <label class="col-md-2 col-form-label">{{'Line' | translate}}</label>
                <div class="col-md-10" *ngIf="sample.Material">
                    <line-select [(model)]="sample.Material.C_Line_key"
                                 [disabled]="sample.Material.MaterialSourceMaterial.length > 0"
                                 (modelChange)="onLineSelection($event)"
                                 [required]="requiredFields.includes('Material.C_Line_key')"></line-select>
                </div>
            </div>

            <div class="form-group row" *ngIf="activeFields.includes('Species')">
                <label class="col-md-2 col-form-label">Species</label>
                <div class="col-md-10">
                    <ng-container *ngIf="sample.Material?.cv_Taxon">
                        <input type="text"
                               name="species"
                               [(ngModel)]="sample.Material.cv_Taxon.CommonName"
                               class="form-control input-medium"
                               readonly="readonly"
                               *ngIf="sample.Material.cv_Taxon" />
                    </ng-container>
                    <small class="form-text text-muted" *ngIf="!sample.Material?.cv_Taxon">
                        Specify a source or {{'Line' | translate}} to assign a species.
                    </small>
                </div>
            </div>

            <div class="form-group row">
                <label class="col-md-2 col-form-label">Status</label>
                <div class="col-md-10">
                    <active-vocab-select [(model)]="sample.C_SampleStatus_key"
                                         [vocabChoices]="sampleStatuses"
                                         [keyFormatter]="sampleStatusKeyFormatter"
                                         [optionFormatter]="sampleStatusFormatter"
                                         [required]="true"></active-vocab-select>
                </div>
            </div>

            <div class="form-group row" *ngIf="activeFields.includes('Material.C_MaterialOrigin_key')">
                <label class="col-md-2 col-form-label">Origin</label>
                <div class="col-md-10" *ngIf="sample.Material">
                    <active-vocab-select [(model)]="sample.Material.C_MaterialOrigin_key"
                                         [vocabChoices]="materialOrigins"
                                         [keyFormatter]="materialOriginKeyFormatter"
                                         [optionFormatter]="materialOriginFormatter"
                                         [required]="requiredFields.includes('Material.C_MaterialOrigin_key')"
                                         [nullable]="!requiredFields.includes('Material.C_MaterialOrigin_key')"></active-vocab-select>
                </div>
            </div>

            <div class="form-group row" *ngIf="activeFields.includes('Material.MicrochipIdentifier')">
                <label class="col-md-2 col-form-label">Microchip ID</label>
                <div class="col-md-10" *ngIf="sample.Material">
                    <input type="text" name="sampleMicrochipIdentifier"
                           [(ngModel)]="sample.Material.MicrochipIdentifier"
                           class="form-control input-medium sample-detail-microchip"
                           [required]="requiredFields.includes('Material.MicrochipIdentifier')" />
                </div>
            </div>

            <div class="form-group row" *ngIf="activeFields.includes('Material.ExternalIdentifier')">
                <label class="col-md-2 col-form-label">External ID</label>
                <div class="col-md-10" *ngIf="sample.Material">
                    <input *ngIf="!isDotmatics"
                           type="text" name="sampleExternalIdentifier"
                           [(ngModel)]="sample.Material.ExternalIdentifier"
                           class="form-control input-medium sample-detail-external"
                           [required]="requiredFields.includes('Material.ExternalIdentifier')" />
                    <input *ngIf="isDotmatics" type="text" name="sampleExternalIdentifier"
                           [(ngModel)]="sample.Material.ExternalIdentifier"
                           class="form-control input-medium sample-detail-external"
                           [required]="requiredFields.includes('Material.ExternalIdentifier')"
                           readonly="readonly" />
                    <p *ngIf="isDotmatics && sample.Material.MaterialExternalSync.length > 0">Last Sync: {{sample.Material.MaterialExternalSync[0].DateSync | formatShortDateOrTimeUtc}}</p>
                </div>
            </div>

            <div class="form-group row" *ngIf="activeFields.includes('Material.C_ContainerType_key')">
                <label class="col-md-2 col-form-label">Container Type</label>
                <div class="col-md-10" *ngIf="sample.Material">
                    <active-vocab-select [(model)]="sample.Material.C_ContainerType_key"
                                         [vocabChoices]="containerTypes"
                                         [keyFormatter]="containerTypeKeyFormatter"
                                         [optionFormatter]="containerTypeFormatter"
                                         [required]="requiredFields.includes('Material.C_ContainerType_key')"
                                         [nullable]="!requiredFields.includes('Material.C_ContainerType_key')"></active-vocab-select>
                </div>
            </div>

            <div class="form-group row" *ngIf="activeFields.includes('Volume')">
                <label class="col-md-2 col-form-label">Measurement</label>
                <div class="col-md-2">
                    <input type="number"
                           min="0"
                           step="any"
                           name="volume"
                           [(ngModel)]="sample.Volume"
                           class="form-control input-medium sample-detail-volume"
                           [required]="requiredFields.includes('Volume')" />
                </div>
            </div>
            <div class="form-group row" *ngIf="activeFields.includes('C_Unit_key')">
                <label class="col-md-2 col-form-label">Unit</label>
                <div class="col-md-2">
                    <active-vocab-select [(model)]="sample.C_Unit_key"
                                         [vocabChoices]="units"
                                         [keyFormatter]="unitKeyFormatter"
                                         [optionFormatter]="unitFormatter"
                                         [required]="requiredFields.includes('C_Unit_key')"
                                         [nullable]="!requiredFields.includes('C_Unit_key')"></active-vocab-select>
                </div>
            </div>

            <div class="form-group row" *ngIf="activeFields.includes('C_SampleSubtype_key')">
                <label class="col-md-2 col-form-label">Subtype</label>
                <div class="col-md-2">
                    <active-vocab-select [(model)]="sample.C_SampleSubtype_key"
                                         [vocabChoices]="sampleSubtypes"
                                         [keyFormatter]="sampleSubtypeKeyFormatter"
                                         [optionFormatter]="sampleSubtypeFormatter"
                                         [required]="requiredFields.includes('C_SampleSubtype_key')"
                                         [nullable]="!requiredFields.includes('C_SampleSubtype_key')"></active-vocab-select>
                </div>
            </div>

            <div class="form-group row" *ngIf="activeFields.includes('C_SampleProcessingMethod_key')">
                <label class="col-md-2 col-form-label">Processing</label>
                <div class="col-md-2">
                    <active-vocab-select [(model)]="sample.C_SampleProcessingMethod_key"
                                         [vocabChoices]="sampleProcessingMethods"
                                         [keyFormatter]="sampleProcessingMethodKeyFormatter"
                                         [optionFormatter]="sampleProcessingMethodFormatter"
                                         [required]="requiredFields.includes('C_SampleProcessingMethod_key')"
                                         [nullable]="!requiredFields.includes('C_SampleProcessingMethod_key')"></active-vocab-select>
                </div>
            </div>

            <div class="form-group row" *ngIf="activeFields.includes('SendTo')">
                <label class="col-md-2 col-form-label">Send To</label>
                <div class="col-md-10">
                    <input type="text" name="sampleSendTo"
                           [(ngModel)]="sample.SendTo"
                           maxlength="150"
                           class="form-control input-medium sample-detail-sendTo"
                           [required]="requiredFields.includes('SendTo')" />
                </div>
            </div>

            <div class="form-group row" *ngIf="activeFields.includes('C_SampleAnalysisMethod_key')">
                <label class="col-md-2 col-form-label">Analysis</label>
                <div class="col-md-2">
                    <active-vocab-select [(model)]="sample.C_SampleAnalysisMethod_key"
                                         [vocabChoices]="sampleAnalysisMethods"
                                         [keyFormatter]="sampleAnalysisMethodKeyFormatter"
                                         [optionFormatter]="sampleAnalysisMethodFormatter"
                                         [required]="requiredFields.includes('C_SampleAnalysisMethod_key')"
                                         [nullable]="!requiredFields.includes('C_SampleAnalysisMethod_key')"></active-vocab-select>
                </div>
            </div>
            <div class="form-group row" *ngIf="activeFields.includes('Material.JobMaterial[0]')">
                <label class="col-md-2 col-form-label">{{'Jobs' | translate}}</label>
                <div class="col-md-10" *ngIf="sample.Material">
                    <material-job-select [material]="sample.Material"
                                         [readonly]="facet.Privilege === 'ReadOnly'"></material-job-select>
                </div>
            </div>

            <div class="form-group row" *ngIf="activeFields.includes('SampleConstruct[0]')">
                <label class="col-md-2 col-form-label"
                       [ngClass]="{'required-text' : requiredFields.includes('SampleConstruct[0]') && sample.SampleConstruct.length === 0}">Constructs</label>
                <div class="col-md-10">
                    <sample-construct-select [sample]="sample"
                                             [readonly]="facet.Privilege === 'ReadOnly'"></sample-construct-select>
                </div>
            </div>

            <div class="form-group row" *ngIf="activeFields.includes('Description')">
                <label class="col-md-2 col-form-label">Description</label>
                <div class="col-md-10" *ngIf="sample.Material">
                    <input type="text" name="sampleDescription"
                           [(ngModel)]="sample.Description"
                           class="form-control input-medium sample-detail-description"
                           [required]="requiredFields.includes('Description')" />
                </div>
            </div>

            <div class="form-group row" *ngIf="activeFields.includes('C_SampleOrder_key')">
                <label class="col-md-2 col-form-label">Order</label>
                <div class="col-md-10">
                    <select name="order"
                            [(ngModel)]="sample.C_SampleOrder_key"
                            class="form-control input-medium"
                            [required]="requiredFields.includes('C_SampleOrder_key')">
                        <option></option>
                        <option *ngFor="let item of sampleOrders"
                                [value]="item.C_SampleOrder_key">
                            {{item.Order.OrderID}}
                        </option>
                    </select>
                </div>
            </div>

            <div class="form-group row" *ngIf="activeFields.includes('LotNumber')">
                <label class="col-md-2 col-form-label">Lot Number</label>
                <div class="col-md-10">
                    <input type="text" name="sampleLotNumber"
                           [(ngModel)]="sample.LotNumber"
                           maxlength="2000"
                           class="form-control input-medium sample-detail-lotNumber"
                           [required]="requiredFields.includes('LotNumber')" />
                </div>
            </div>

            <div class="form-group row" *ngIf="activeFields.includes('SpecialInstructions')">
                <label class="col-md-2 col-form-label">Special Instructions</label>
                <div class="col-md-10">
                    <textarea 
                        name="sampleSpecialInstructions"
                        class="form-control input-medium"
                        maxlength="1000"
                        [(ngModel)]="sample.SpecialInstructions"
                        [required]="requiredFields.includes('SpecialInstructions')">
                    </textarea>
                </div>
            </div>

            <characteristic-instance-wrapper [model]="sample"
                                          [signalValue]="sample.cv_SampleType"
                                          [characteristicType]="'sample'"
                                          [isLoading]="loading">

            </characteristic-instance-wrapper>

            <div class="form-group row">
                <label class="col-md-2 col-form-label">Files</label>
                <div class="col-md-10">
                    <div class="form-control-plaintext">
                        <facet-detail-file-upload [pkName]="'C_Material_key'"
                                                  [pkValue]="sample.C_Material_key"
                                                  [facetPrivilege]="facet.Privilege"></facet-detail-file-upload>
                    </div>
                </div>
            </div>

        </fieldset>
    </form>
</div>

<!-- Table for Groups -->
<div class="form-group row">
    <label class="col-md-2 col-form-label"
           [ngClass]="{'required-text' : required}">Dosing Table</label>
    <div class="col-md-10">
        <table name="groups" class="table">
            <thead>
                <tr>
                    <th class="col-size-fit" style="width:4.4%">
                        <a (click)="addJobGroup(job)"
                           class="add-item-link"
                           *ngIf="!readonly">
                            <i class="fa fa-plus-circle" title="Add Group"></i>
                        </a>
                    </th>
                    <th style="width:5%">
                        <bulk-edit-header [headerText]="'Group'"
                                          [mainOptionTitle]="'Enter a number to be applied to all groups.'"
                                          (updateClicked)="bulkGroupChanged()"
                                          #bulkEditHeaderComponent>

                            <div modal-content class="text-body">
                                <div class="form-group row" style="min-width: 220px; margin-bottom: 10px;">
                                    <div class="col-6 pt-2">
                                        Start Number
                                    </div>
                                    <div class="col-6">
                                        <input type="number"
                                               name="bulkStartNumber"
                                               [(ngModel)]="bulkStartNumber"
                                               min="1"
                                               step="any"
                                               required
                                               class="form-control input-small">
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-6 pt-2">
                                        Increment by
                                    </div>
                                    <div class="col-6">
                                        <input type="number"
                                               name="bulkIncrementNumber"
                                               [(ngModel)]="bulkIncrementNumber"
                                               min="1"
                                               required
                                               step="any"
                                               class="form-control input-small">
                                    </div>
                                </div>
                            </div>
                        </bulk-edit-header>
                    </th>
                    <th style="width:4.5%">
                        <bulk-edit-header [headerText]="'N'"
                                          (updateClicked)="bulkNumberChanged()">
                            <div modal-content class="text-body">
                                <input type="number"
                                       min="0"
                                       [max]="MAX_DOSING_TABLE_N_AMOUNT"
                                       #name="ngModel"
                                       [(ngModel)]="bulk.number"
                                       [readonly]="readonly"
                                       class="form-control" />
                                <label class="d-block mt-2 mb-0">
                                    <input type="checkbox"
                                           class="align-middle"
                                           [(ngModel)]="bulk.force"
                                           name="bulk_Force" />
                                    <span class="align-middle">
                                        Overwrite existing values
                                    </span>
                                </label>
                            </div>
                        </bulk-edit-header>
                    </th>
                    <th style="width:9%">
                        <bulk-edit-header [headerText]="'Treatment'"
                                          (updateClicked)="bulkTreatmentChanged()">
                            <div modal-content class="text-body">
                                <select name="treatment"
                                        [(ngModel)]="bulk.treatmentKey"
                                        [disabled]="readonly"
                                        class="form-control input-medium">
                                    <option></option>
                                    <ng-container *ngFor="let item of job.JobTestArticle">
                                        <option *ngIf="item.C_JobTestArticle_key !== systemGeneratedJobTestArticle?.C_JobTestArticle_key"
                                                [value]="item.C_JobTestArticle_key">
                                            <ng-container *ngIf="item.Identifier && (isCRL || isDTX)">{{item.Identifier}}: </ng-container>{{item.cv_TestArticle?.TestArticle}}<ng-container *ngIf="item.Batch">-{{item.Batch}}</ng-container>
                                        </option>
                                    </ng-container>
                                    <ng-container *ngIf="systemGeneratedJobTestArticle">
                                        <option [value]="systemGeneratedJobTestArticle.C_JobTestArticle_key">{{systemGeneratedTestArticle.TestArticle}}</option>
                                    </ng-container>
                                </select>
                                <label class="d-block mt-2 mb-0">
                                    <input type="checkbox"
                                           class="align-middle"
                                           [(ngModel)]="bulk.force"
                                           name="bulk_Force" />
                                    <span class="align-middle">
                                        Overwrite existing values
                                    </span>
                                </label>
                            </div>
                        </bulk-edit-header>
                    </th>
                    <th style="width:11%">
                        <ng-container *ngIf="isDTX">
                            Formulation Dose
                        </ng-container>
                        <bulk-edit-header *ngIf="!isDTX" [headerText]="'Formulation Dose'"
                                          (updateClicked)="bulkFormulationDoseChanged()">
                            <div modal-content class="text-body">
                                <input type="text"
                                       maxlength="100"
                                       #name="ngModel"
                                       [(ngModel)]="bulk.formulationDose"
                                       [readonly]="readonly"
                                       class="form-control" />
                                <label class="d-block mt-2 mb-0">
                                    <input type="checkbox"
                                           class="align-middle"
                                           [(ngModel)]="bulk.force"
                                           name="bulk_Force" />
                                    <span class="align-middle">
                                        Overwrite existing values
                                    </span>
                                </label>
                            </div>
                        </bulk-edit-header>
                    </th>
                    <th style="width:9.5%">
                        <bulk-edit-header [headerText]="'Active Dose'"
                                          (updateClicked)="bulkActiveDoseChanged()">
                            <div modal-content class="text-body">
                                <input type="number"
                                       step="any"
                                       #name="ngModel"
                                       [(ngModel)]="bulk.activeDose"
                                       [readonly]="readonly"
                                       class="form-control" />
                                <label class="d-block mt-2 mb-0">
                                    <input type="checkbox"
                                           class="align-middle"
                                           [(ngModel)]="bulk.force"
                                           name="bulk_Force" />
                                    <span class="align-middle">
                                        Overwrite existing values
                                    </span>
                                </label>
                            </div>
                        </bulk-edit-header>
                    </th>
                    <th style="width:9.2%">
                        <bulk-edit-header [headerText]="'Active Unit'"
                                          (updateClicked)="bulkActiveUnitChanged()">
                            <div modal-content class="text-body">
                                <select class="form-control"
                                        [(ngModel)]="bulk.activeUnit"
                                        [disabled]="readonly">
                                    <option></option>
                                    <ng-container *ngIf="unitItems">
                                        <option *ngFor="let item of unitItems"
                                                [value]="item.Unit">
                                            {{item.Unit}}
                                        </option>
                                    </ng-container>
                                </select>
                                <label class="d-block mt-2 mb-0">
                                    <input type="checkbox"
                                           class="align-middle"
                                           [(ngModel)]="bulk.force"
                                           name="bulk_Force" />
                                    <span class="align-middle">
                                        Overwrite existing values
                                    </span>
                                </label>
                            </div>
                        </bulk-edit-header>
                    </th>
                    <th *ngIf="!isCRL" style="width:9%">
                        <bulk-edit-header [headerText]="'Concentration'"
                                          (updateClicked)="bulkConcentrationChanged()">
                            <div modal-content class="text-body">
                                <input type="text"
                                       maxlength="50"
                                       #name="ngModel"
                                       [(ngModel)]="bulk.concentration"
                                       [readonly]="readonly"
                                       class="form-control" />
                                <label class="d-block mt-2 mb-0">
                                    <input type="checkbox"
                                           class="align-middle"
                                           [(ngModel)]="bulk.force"
                                           name="bulk_Force" />
                                    <span class="align-middle">
                                        Overwrite existing values
                                    </span>
                                </label>
                            </div>
                        </bulk-edit-header>
                    </th>
                    <th style="width:9.5%">
                        <bulk-edit-header [headerText]="'Route'"
                                          (updateClicked)="bulkRouteChanged()">
                            <div modal-content class="text-body">
                                <select class="form-control"
                                        [(ngModel)]="bulk.route"
                                        [disabled]="readonly">
                                    <option></option>
                                    <ng-container *ngIf="enumerationItems">
                                        <option *ngFor="let item of enumerationItems"
                                                [value]="item.ItemName">
                                            {{item.ItemName}}
                                        </option>
                                    </ng-container>
                                </select>
                                <label class="d-block mt-2 mb-0">
                                    <input type="checkbox"
                                           class="align-middle"
                                           [(ngModel)]="bulk.force"
                                           name="bulk_Force" />
                                    <span class="align-middle">
                                        Overwrite existing values
                                    </span>
                                </label>
                            </div>
                        </bulk-edit-header>
                    </th>
                    <th style="width:10%">
                        <bulk-edit-header [headerText]="'Dosing Volume'"
                                          (updateClicked)="bulkDosingVolumeChanged()">
                            <div modal-content class="text-body">
                                <input type="number"
                                       step="any"
                                       #name="ngModel"
                                       [(ngModel)]="bulk.dosingVolume"
                                       [readonly]="readonly"
                                       class="form-control" />
                                <label class="d-block mt-2 mb-0">
                                    <input type="checkbox"
                                           class="align-middle"
                                           [(ngModel)]="bulk.force"
                                           name="bulk_Force" />
                                    <span class="align-middle">
                                        Overwrite existing values
                                    </span>
                                </label>
                            </div>
                        </bulk-edit-header>
                    </th>
                    <th style="width:9.5%">
                        <bulk-edit-header [headerText]="'Dosing Unit'"
                                          (updateClicked)="bulkDosingUnitChanged()">
                            <div modal-content class="text-body">
                                <select class="form-control"
                                        [(ngModel)]="bulk.dosingUnit"
                                        [disabled]="readonly">
                                    <option></option>
                                    <ng-container *ngIf="unitItems">
                                        <option *ngFor="let item of unitItems"
                                                [value]="item.Unit">
                                            {{item.Unit}}
                                        </option>
                                    </ng-container>
                                </select>
                                <label class="d-block mt-2 mb-0">
                                    <input type="checkbox"
                                           class="align-middle"
                                           [(ngModel)]="bulk.force"
                                           name="bulk_Force" />
                                    <span class="align-middle">
                                        Overwrite existing values
                                    </span>
                                </label>
                            </div>
                        </bulk-edit-header>
                    </th>
                    <th style="width:9%">
                        <bulk-edit-header [headerText]="'Schedule'"
                                          (updateClicked)="bulkScheduleChanged()">
                            <div modal-content class="text-body">
                                <input type="text"
                                       maxlength="100"
                                       #name="ngModel"
                                       [(ngModel)]="bulk.schedule"
                                       [readonly]="readonly"
                                       class="form-control" />
                                <label class="d-block mt-2 mb-0">
                                    <input type="checkbox"
                                           class="align-middle"
                                           [(ngModel)]="bulk.force"
                                           name="bulk_Force" />
                                    <span class="align-middle">
                                        Overwrite existing values
                                    </span>
                                </label>
                            </div>
                        </bulk-edit-header>
                    </th>
                    <th style="width:10%">
                        <bulk-edit-header [headerText]="'Protocol'"
                                          (updateClicked)="bulkProtocolChanged()">
                            <div modal-content class="text-body">
                                <select name="protocol"
                                        [(ngModel)]="bulk.protocol"
                                        [disabled]="readonly"
                                        class="form-control input-medium">
                                    <option></option>
                                    <option *ngFor="let item of job.TaskJob | protocols"
                                            [value]="item.C_ProtocolInstance_key">
                                        {{item.ProtocolAlias}}
                                    </option>
                                </select>

                                <label class="d-block mt-2 mb-0">
                                    <input type="checkbox"
                                           class="align-middle"
                                           [(ngModel)]="bulk.force"
                                           name="bulk_Force" />
                                    <span class="align-middle">
                                        Overwrite existing values
                                    </span>
                                </label>
                            </div>
                        </bulk-edit-header>
                    </th>
                </tr>
            </thead>
            <tbody [dragula]="dragulaBagName" [dragulaModel]="job.JobGroup">
                <tr *ngFor="let jobGroup of job.JobGroup | orderBy:'SortOrder'">
                    <td colspan="13">
                        <table class="table">
                            <tbody>
                                <tr *ngFor="let treatment of jobGroup.JobGroupTreatment; let i = index;" [attr.data-key]="treatment.C_JobGroupTreatment_key">
                                    <!-- Drag Handle -->
                                    <td class="icon-cell" style="width:1%">
                                        <i *ngIf="!readonly && i === 0"
                                           class="fa fa-sort text-muted draggable"
                                           title="Drag to reorder"></i>
                                    </td>
                                    <td style="width:1%">
                                        <a *ngIf="!readonly && i === 0" (click)="removeGroup(jobGroup)">
                                            <i class="fa fa-fw fa-remove remove-item"
                                               title="Delete Group"></i>
                                        </a>
                                    </td>
                                    <td style="width:5%">
                                        <input *ngIf="i === 0"
                                               type="text"
                                               name="group"
                                               maxlength="50"
                                               #group="ngModel"
                                               [(ngModel)]="jobGroup.Group"
                                               (ngModelChange)="updatePlaceholderName(jobGroup)"
                                               required
                                               inputTrim
                                               groupValidate
                                               [groups]="job.JobGroup"
                                               [groupKey]="jobGroup.C_JobGroup_key"
                                               [readonly]="readonly"
                                               class="form-control" />
                                    </td>
                                    <td class="text-center" style="width:5%">
                                        <input *ngIf="i === 0"
                                               type="number"
                                               name="number"
                                               min="0"
                                               [max]="MAX_DOSING_TABLE_N_AMOUNT"
                                               #name="ngModel"
                                               [(ngModel)]="jobGroup.Number"
                                               (input)="updateJobGroupNumber(jobGroup)"
                                               [readonly]="readonly"
                                               [disabled]="jobGroup.Placeholder && jobGroup.Placeholder[0].C_JobCohort_key !== null"
                                               inputNumber
                                               required
                                               class="form-control no-spin">
                                        <a *ngIf="!readonly && i > 0" (click)="removeTreatment(treatment)" class="text-center">
                                            <i class="fa fa-fw fa-remove remove-treatment"
                                               title="Delete Treatment"></i>
                                        </a>
                                    </td>
                                    <td style="width:9%">
                                        <select name="treatment"
                                                [(ngModel)]="treatment.C_JobTestArticle_key"
                                                [disabled]="readonly"
                                                (ngModelChange)="updateTreatment(treatment, treatment.C_JobTestArticle_key); updateUsedTreatment(treatment, jobGroup); updateTaskInputs([treatment], 'TREATMENT')"
                                                class="form-control input-medium">
                                            <option></option>
                                            <ng-container *ngFor="let item of job.JobTestArticle">
                                                <option *ngIf="item.C_JobTestArticle_key !== systemGeneratedJobTestArticle?.C_JobTestArticle_key"
                                                        [value]="item.C_JobTestArticle_key">
                                                        <ng-container *ngIf="item.Identifier && (isCRL || isDTX)">{{item.Identifier}}: </ng-container>{{item.cv_TestArticle?.TestArticle}}<ng-container *ngIf="item.Batch">-{{item.Batch}}</ng-container>
                                                </option>
                                            </ng-container>
                                            <ng-container *ngIf="systemGeneratedJobTestArticle">
                                                <option [value]="systemGeneratedJobTestArticle.C_JobTestArticle_key">{{systemGeneratedTestArticle.TestArticle}}</option>
                                            </ng-container>
                                        </select>
                                    </td>
                                    <td style="width:12%">
                                        <input type="number"
                                               step="any"
                                               min="0"
                                               name="formulationDose"
                                               #name="ngModel"
                                               [(ngModel)]="treatment.FormulationDose"
                                               [readonly]="readonly"
                                               (focusout)="updateTaskInputs([treatment], 'FORMULATION DOSE')"
                                               [disabled]="isDTX"
                                               class="form-control" />
                                    </td>
                                    <td style="width:10%">
                                        <input type="number"
                                               step="any"
                                               min="0"
                                               name="activeDose"
                                               #name="ngModel"
                                               [(ngModel)]="treatment.ActiveDose"
                                               [readonly]="readonly"
                                               (ngModelChange)="updateFormulationDose(treatment); updateTaskInputs([treatment], 'ACTIVE DOSE')"
                                               class="form-control" />
                                    </td>
                                    <td style="width:10%">
                                        <select class="form-control"
                                                [(ngModel)]="treatment.ActiveUnit"
                                                [disabled]="readonly">
                                            <option></option>
                                            <option *ngIf="unitItems && treatment.ActiveUnit && treatment.ActiveUnit !== ''">{{treatment.ActiveUnit}}</option>
                                            <ng-container *ngIf="unitItems">
                                                <ng-container *ngFor="let item of unitItems">
                                                    <option *ngIf="item.Unit !== treatment.ActiveUnit"
                                                            [value]="item.Unit">
                                                        {{item.Unit}}
                                                    </option>
                                                </ng-container>
                                            </ng-container>
                                        </select>
                                    </td>
                                    <td *ngIf="!isCRL" style="width:10%">
                                        <input type="text"
                                               name="concentration"
                                               maxlength="50"
                                               #name="ngModel"
                                               [(ngModel)]="treatment.Concentration"
                                               [readonly]="readonly"
                                               (focusout)="updateTaskInputs([treatment], 'CONCENTRATION')"
                                               class="form-control" />
                                    </td>
                                    <td style="width:10%">
                                        <select class="form-control"
                                                [(ngModel)]="treatment.Route"
                                                (ngModelChange)="updateTaskInputs([treatment], 'ROUTE')"
                                                [disabled]="readonly">
                                            <option></option>
                                            <option *ngIf="enumerationItems && treatment.Route && treatment.Route !== ''">{{treatment.Route}}</option>
                                            <ng-container *ngIf="enumerationItems">
                                                <ng-container *ngFor="let item of enumerationItems">
                                                    <option *ngIf="item.ItemName !== treatment.Route"
                                                            [value]="item.ItemName">
                                                        {{item.ItemName}}
                                                    </option>
                                                </ng-container>
                                            </ng-container>
                                        </select>
                                    </td>
                                    <td style="width:11%">
                                        <input type="number"
                                               step="any"
                                               name="dosingVolume"
                                               #name="ngModel"
                                               [(ngModel)]="treatment.DosingVolume"
                                               [readonly]="readonly"
                                               (focusout)="updateTaskInputs([treatment], 'DOSING VOLUME')"
                                               class="form-control" />
                                    </td>
                                    <td style="width:10%">
                                        <select class="form-control"
                                                [(ngModel)]="treatment.DosingUnit"
                                                [disabled]="readonly">
                                            <option></option>
                                            <option *ngIf="unitItems && treatment.DosingUnit && treatment.DosingUnit !== ''">{{treatment.DosingUnit}}</option>
                                            <ng-container *ngIf="unitItems">
                                                <ng-container *ngFor="let item of unitItems">
                                                    <option *ngIf="item.Unit !== treatment.DosingUnit"
                                                            [value]="item.Unit">
                                                        {{item.Unit}}
                                                    </option>
                                                </ng-container>
                                            </ng-container>
                                        </select>
                                    </td>
                                    <td style="width:10%">
                                        <input type="text"
                                               name="schedule"
                                               maxlength="100"
                                               #name="ngModel"
                                               [(ngModel)]="treatment.Schedule"
                                               [readonly]="readonly"
                                               class="form-control" />
                                    </td>

                                    <td style="width:10%">
                                        <select name="protocol"
                                                [(ngModel)]="treatment.C_ProtocolInstance_key"
                                                [disabled]="readonly || treatmentUsedInCohortInputs(treatment)"
                                                (ngModelChange)="updateUsedProtocol(); updateTaskInputs([treatment], 'PROTOCOL');"
                                                class="form-control input-medium">
                                            <option></option>
                                            <option *ngFor="let item of getProtocolOptions(treatment) | protocols"
                                                    [value]="item.C_ProtocolInstance_key">
                                                {{item.ProtocolAlias}}
                                            </option>
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="4"></td>
                                    <td class="text-center icon-cell">
                                        <a (click)="addTreatment(jobGroup)"
                                           class="add-item-link"
                                           *ngIf="!readonly">
                                            <i class="fa fa-plus-circle" title="Add Treatment to Job Group"></i>
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
            </tbody>
        </table>

        <table name="totals" class="table">
            <thead>
                <tr>
                    <th>Total</th>
                    <th>Overage (%)</th>
                    <th>Animal Total</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>{{job.JobGroup.length > 0 ? sumNumber : ''}}</td>
                    <td>
                        <input type="number"
                                name="number"
                                min="0"
                                max="9999"
                                #name="ngModel"
                                [(ngModel)]="job.JobGroupOverage"
                                [readonly]="readonly"
                                class="form-control input-medium" />
                    </td>
                    <td>{{math.ceil(sumNumber + (job.JobGroupOverage * sumNumber / 100))}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

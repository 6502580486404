import { Subscription } from 'rxjs';
import { EntityChangeService } from './../entity-changes/entity-change.service';
import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    OnDestroy,
    OnChanges
} from '@angular/core';

import { SampleService } from './sample.service';
import { uniqueArrayFromPropertyPath } from '../common/util';

/*
* Component to select Animal Source for a sample
*/
@Component({
    selector: 'sample-source-select',
    template: `
        <material-multiselect
            [model]="sampleMaterials"
            [readonly]="readonly"
            [selectionValidator]="isMaterialSectionValid"
            [enableStatusColumn]="true"
            [required]="required"
            (selectMaterial)="selectMaterial($event)"
            (removeMaterial)="removeMaterialSourceMaterial($event)"></material-multiselect>
    `
})
export class SampleSourceSelectComponent implements OnInit, OnChanges, OnDestroy {
    @Input() sample: any;
    // whether use has readonly privilege for this widget.
    //   If true all editing will be disabled
    @Input() readonly: boolean;
    @Input() required: boolean;

    @Output() selectSource: EventEmitter<any> = new EventEmitter<any>();

    public sampleMaterials: any[];

    private _subscriptions: Subscription[] = [];

    constructor(
        private entityChangeService: EntityChangeService,
        private sampleService: SampleService,
    ) {
    }

    ngOnInit() {

        if (this.readonly === null) {
            this.readonly = false;
        }

        const s1 = <Subscription> this.entityChangeService.onAnyChange(() => {
            this.refreshSampleMaterialsList();
        });
        this._subscriptions.push(s1);
    }

    ngOnDestroy() {
        for (const subscription of this._subscriptions) {
            subscription.unsubscribe();
        }
    }

    ngOnChanges(changes: any) {
        this.refreshSampleMaterialsList();
    }

    refreshSampleMaterialsList() {
        this.sampleMaterials = uniqueArrayFromPropertyPath(
            this.sample,
            'Material.MaterialSourceMaterial.SourceMaterial'
        ).filter((material) => {
            return material.Animal || material.Sample;
        }).map((material) => {
            return material.Animal || material.Sample;
        });
    }

    selectMaterial(material: any) {
        this.addMaterialSourceMaterial(material);
        this.selectSource.emit(material);
    }

    isMaterialSectionValid = (material: any): boolean => {
        if (material.Animal) {
            // Nothing to test
            return true;
        } else if (material.Sample) {
            // Prevent recursive sample selections
            return (
                material.C_Material_key !== this.sample.C_Material_key
            );
        } else {
            console.error("isMaterialSectionValid: Unsupported material type.");
            return false;
        }
    }

    private addMaterialSourceMaterial(sourceToAdd: any) {
        const newEntity = this.sampleService.createMaterialSourceMaterial({
            C_Material_key: this.sample.C_Material_key,
            C_SourceMaterial_key: sourceToAdd.C_Material_key,
            Version: 0
        });

        if (newEntity) {
            if (!this.sample.Material.MaterialSourceMaterial) {
                this.sample.Material.MaterialSourceMaterial = [newEntity];
            } else {
                this.sample.Material.MaterialSourceMaterial.push(newEntity);
            }
        }
    }

    removeMaterialSourceMaterial(sourceToRemove: any) {
        for (const sourceMaterial of this.sample.Material.MaterialSourceMaterial) {
            if (sourceMaterial.C_SourceMaterial_key === sourceToRemove.C_Material_key) {
                this.sampleService.deleteMaterialSourceMaterial(sourceMaterial);
                break;
            }
        }
    }
}

import { DeleteVocabRequest } from './models/delete-vocab-request';
import { WebApiService } from './../services/web-api.service';
import { Injectable } from '@angular/core';
import {
    EntityQuery, FilterQueryOp, Predicate,
} from 'breeze-client';

import { notEmpty } from '../common/util/not-empty';

import { DataManagerService } from '../services/data-manager.service';
import { BaseEntityService } from '../services/base-entity.service';
import { FeatureFlagService, LARGE_ANIMAL } from '../services/feature-flags.service';
import { Subject } from 'rxjs';

@Injectable()
export class VocabularyService extends BaseEntityService {
    private controlledVocabularyDefaultsCache = {};

    isGLP: boolean;

    largeAnimalEnabled: boolean;

    constructor(
        private dataManager: DataManagerService,
        private webApiService: WebApiService,
        private featureFlagService: FeatureFlagService
    ) {
        super();
        this.initIsGLP();
        this.initLargeAnimalClinical();
    }

    initIsGLP() {
        this.isGLP = this.featureFlagService.getIsGLP();
    }

    initLargeAnimalClinical() {
        this.largeAnimalEnabled = this.featureFlagService.isFlagOn(LARGE_ANIMAL);
    }
    private refreshVocabularySubject = new Subject<void>();
    refreshVocabularyCalled$ = this.refreshVocabularySubject.asObservable();

    refreshVocabularyData() {
        this.refreshVocabularySubject.next();
    }

    getCV(entityName: string, sort?: string, preferLocal?: boolean, onlyActive?: boolean): Promise<any[]> {
        if (!sort) {
            sort = 'SortOrder';
        }

        let query;
        const predicates: Predicate[] = [];

        // Exclude system-generated Animal Comment Status cv used for migration to new system
        if (entityName === 'cv_AnimalCommentStatuses') {
            predicates.push(new Predicate('IsSystemGenerated', '==', false));
        }

        if (entityName === 'cv_TestArticles') {
            predicates.push(new Predicate('IsSystemGenerated', '==', false));
        }

        if (onlyActive) {
            predicates.push(new Predicate('IsActive', '==', true));
        }

        query = EntityQuery.from(entityName)
            .where(Predicate.and(predicates))
            .orderBy(sort);

        const expands: string[] = this.getExtraExpands(entityName);
        if (notEmpty(expands)) {
            query = query.expand(expands.join(','));
        }

        return this.dataManager.returnQueryResults(query, preferLocal);
    }

    getSystemGeneratedCV(entityName: string, preferLocal?: boolean): Promise<any> {
        let query;

        query = EntityQuery.from(entityName)
            .where('IsSystemGenerated', '==', true);

        const expands: string[] = this.getExtraExpands(entityName);
        if (notEmpty(expands)) {
            query = query.expand(expands.join(','));
        }

        return this.dataManager.returnSingleQueryResult(query, preferLocal);
    }

    /**
     * Ensure that a controlled vocabulary is loaded into breeze cache.
     *   If already loaded, this does nothing.
     *   If not, it fetches from server.
     *   This is most useful for CVs that are not user editable,
     *   E.g. MaterialType, Sex, Task Type
     * @param entityName
     */
    ensureCVLoaded(entityName: string, preferLocal?: boolean): Promise<void> {
        const query = EntityQuery.from(entityName);
        if (preferLocal == undefined) {
            preferLocal = true;
        }
        return this.dataManager.executeQuery(query, preferLocal).then(() => {
            //
        });
    }

    getCVDefault(entityName: string, preferLocal?: boolean): Promise<any> {
        // console.log('getCVDefault', entityName);
        /*  
        Controlled Vocabulary (CV) Defaults Cache
        We implement a cache here because Breeze preferLocal=true option still
        sends queries when current value is empty.
        
        NB: The current version of this cache does not support invalidation if 
        the client changes a default CV value (either themselve or a collegue on another machine)
        */
        if (this.controlledVocabularyDefaultsCache[entityName] !== undefined) {
            // console.log('getCVDefault', entityName, ' - cache hit', `${entityName} = `,
            //  this.controlledVocabularyDefaultsCache[entityName]);
            return Promise.resolve(this.controlledVocabularyDefaultsCache[entityName]);
        }

        const query = EntityQuery.from(entityName)
            .where('IsDefault', '==', true)
            .where('IsActive', '==', true);

        return this.dataManager.returnSingleQueryResult(query, preferLocal)
            .then((value) => {
                // cache the value
                // console.log('getCVDefault', entityName, ' - store value in cache',
                //  `${entityName} =`, value);
                this.controlledVocabularyDefaultsCache[entityName] = value;

                return value;
            });
    }

    getCVContainerTypeDefault(defaultType: string): Promise<any> {
        let query;
        if (defaultType === 'Animal') {
            query = EntityQuery.from('cv_ContainerTypes')
                .where('IsDefaultAnimal', '==', true)
                .where('IsActive', '==', true);
        } else {
            query = EntityQuery.from('cv_ContainerTypes')
                .where('IsDefaultSample', '==', true)
                .where('IsActive', '==', true);
        }
        return this.dataManager.returnSingleQueryResult(query);
    }
        
    getCVDefaultEndStatus(entityName: string): Promise <any> {
        const query = EntityQuery.from(entityName)
                .where('IsDefaultEndState', '==', true)
                .where('IsActive', '==', true);

        return this.dataManager.returnSingleQueryResult(query);
    }

    getCVByFieldEquals(entityName: string, fieldName: string, fieldValue: string, returnQueryResults = true): Promise<any[]> {
        let query = EntityQuery.from(entityName)
            .where(fieldName, '==', fieldValue);

        const expands: string[] = this.getExtraExpands(entityName);
        if (notEmpty(expands)) {
            query = query.expand(expands.join(','));            
        }

        if (returnQueryResults) {
            return this.dataManager.returnQueryResults(query);
        } else {
            return this.dataManager.returnSingleQueryResult(query);
        }
    }

    getCVByFieldSubstring(
        entityName: string, fieldName: string, fieldValue: string, maxResults?: number
    ): Promise<any[]> {
        let query = EntityQuery.from(entityName)
            .orderBy(fieldName);

        if (fieldValue) {
            query = query.where(fieldName, FilterQueryOp.Contains, { value: fieldValue });
        }

        if (maxResults) {
            query = query.top(maxResults);
        }

        return this.dataManager.returnQueryResults(query);
    }

    getExtraExpands(entityName: string): string[] {
        const expands: string[] = [];

        if (entityName === 'cv_GenotypeAssays') {
            expands.push('GenotypeAssayGenotypeSymbol.cv_GenotypeSymbol');
        }

        if (entityName === 'cv_StandardPhrases') {
            expands.push('cv_StandardPhraseJobType');
            expands.push('cv_StandardPhraseJobSubtype');
            expands.push('cv_StandardPhraseIACUCProtocol');
        }

        if (entityName === 'cv_Frequencies') {
            expands.push('Pattern');
        }

        if (this.largeAnimalEnabled && entityName === 'cv_ClinicalObservations') {
            expands.push("cv_ClinicalObservationBodySystem.cv_BodySystem");
            expands.push("cv_ClinicalObservationModifier1");
            expands.push("cv_ClinicalObservationModifier2");
            expands.push("cv_ClinicalObservationModifier3");
            expands.push("cv_ClinicalObservationModifier4");
        }

        if (entityName === 'cv_BodySystems') {
            expands.push("cv_ClinicalObservationBodySystems.cv_ClinicalObservation");
        }

        return expands;
    }

    create(entityName: string): any {
        const initialValues = { DateCreated: new Date() };
        return this.dataManager.createEntity(entityName, initialValues);
    }

    canDeleteVocabTerm(params: DeleteVocabRequest): Promise<boolean> {
        const url = '/api/vocabdelete/candelete';
        return this.webApiService.callApi(url, params as any).then((response) => {
            return response.data;
        });
    }

    deleteVocabTerm(vocabTerm: any) {
        if (vocabTerm.cv_StandardPhraseJobType) {
            while (vocabTerm.cv_StandardPhraseJobType.length > 0) {
                this.dataManager.deleteEntity(vocabTerm.cv_StandardPhraseJobType[0]);
            }
        }

        if (vocabTerm.cv_StandardPhraseJobSubtype) {
            while (vocabTerm.cv_StandardPhraseJobSubtype.length > 0) {
                this.dataManager.deleteEntity(vocabTerm.cv_StandardPhraseJobSubtype[0]);
            }
        }

        if (vocabTerm.cv_StandardPhraseIACUCProtocol) {
            while (vocabTerm.cv_StandardPhraseIACUCProtocol.length > 0) {
                this.dataManager.deleteEntity(vocabTerm.cv_StandardPhraseIACUCProtocol[0]);
            }
        }

        this.dataManager.deleteEntity(vocabTerm);
    }

    resetCV() {
        this.controlledVocabularyDefaultsCache = {};
    }

    getStandardPhrasesWithCategory(): Promise<any> {
        const query = EntityQuery.from('cv_StandardPhrases')
            .where('C_StandardPhraseCategory_key', '!=', null)
            .expand('cv_StandardPhraseCategory')
            .orderBy('SortOrder');

        return this.dataManager.returnQueryResults(query);
    }

    getClinicalObservationModifier1(entityName: string, id: any): Promise<any> {
        return this.getClinicalObservationModifier(entityName, id, 1);
    }

    getClinicalObservationModifier2(entityName: string, id: any): Promise<any> {
        return this.getClinicalObservationModifier(entityName, id, 2);
    }

    getClinicalObservationModifier3(entityName: string, id: any): Promise<any> {
        return this.getClinicalObservationModifier(entityName, id, 3);
    }

    getClinicalObservationModifier4(entityName: string, id: any): Promise<any> {
        return this.getClinicalObservationModifier(entityName, id, 4);
    }

    private getClinicalObservationModifier(entityName: string, id: any, modifier: number): Promise<any> {
        const query = EntityQuery.from(entityName)
            .where('C_ClinicalObservation_key', '==', id)
            .expand('cv_Modifier' + modifier);
        return this.dataManager.returnQueryResults(query);
    }

    getClinicalObservationBodySystem(entityName: string) {
        const query = EntityQuery.from(entityName);
        return this.dataManager.returnQueryResults(query);
    }

    getModifiers(): Promise<any> {
        const queryModifier1 = EntityQuery.from("cv_Modifiers1");
        const queryModifier2 = EntityQuery.from("cv_Modifiers2");
        const queryModifier3 = EntityQuery.from("cv_Modifiers3");
        const queryModifier4 = EntityQuery.from("cv_Modifiers4");
        return Promise.all([
            this.dataManager.returnQueryResults(queryModifier1),
            this.dataManager.returnQueryResults(queryModifier2),
            this.dataManager.returnQueryResults(queryModifier3),
            this.dataManager.returnQueryResults(queryModifier4),
        ]);
    }

    getStandardPhrasesWithDefaults(): Promise<any> {
        const query = EntityQuery.from('cv_StandardPhrases')
            .expand([
                'cv_StandardPhraseJobType',
                'cv_StandardPhraseJobSubtype',
                'cv_JobReport',
                'cv_StandardPhraseCategory',
                'cv_StandardPhraseLinkType',
                'cv_StandardPhraseIACUCProtocol'
            ])
            .orderBy('SortOrder');

        return this.dataManager.returnQueryResults(query);
    }

    getClinicalObservationsWithModifiers(): Promise<any> {
        const query = EntityQuery.from('cv_ClinicalObservations')
            .expand([
                'cv_ClinicalObservationModifier1.cv_Modifier1',
                'cv_ClinicalObservationModifier2.cv_Modifier2',
                'cv_ClinicalObservationModifier3.cv_Modifier3',
                'cv_ClinicalObservationModifier4.cv_Modifier4',
            ])
            .orderBy('SortOrder');

        return this.dataManager.returnQueryResults(query);
    }
}

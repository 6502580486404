<section>
    <div class="container-fluid">
        <br />
        <div *ngIf="!(wip?.length > 0)">
            You have no unsaved changes.
        </div>

        <table *ngIf="wip?.length > 0" class="table">
            <thead>
                <tr>
                    <th>Discard</th>
                    <th>Item</th>
                    <th>Description</th>
                    <th>Valid</th>
                    <th>Timestamp</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of wip">
                    <td class="icon-cell">
                        <a (click)="cancelChange(item)" class="discard-change-link">
                            <i class="fa fa-undo remove-item" title="Discard change"></i>
                        </a>
                    </td>
                    <td>{{item.displayName}}</td>
                    <td>{{item.description}}</td>
                    <td>
                        <i *ngIf="item.isValid === 'Yes'"
                           class="fa fa-fw fa-check text-success"
                           title="Valid change"></i>
                        <i *ngIf="item.isValid !== 'Yes'"
                           class="fa fa-fw fa-exclamation-triangle text-danger"
                           title="Invalid change"></i>
                    </td>
                    <td>{{item.changeDateTime | formatShortDateTime}}</td>
                </tr>
            </tbody>
        </table>

        <div class="modal-footer">
            <button type="button"
                    class="btn btn-lg btn-secondary"
                    (click)="closeModal()">
                Close
            </button>
        </div>
    </div>
</section>


import { Injectable } from '@angular/core';
import {
    EntityQuery,
    FilterQueryOp,
    Predicate,
} from 'breeze-client';

import { notEmpty } from '../common/util/not-empty';

import { DataManagerService } from '../services/data-manager.service';
import { QueryDef } from '../services/query-def';
import { BaseEntityService } from '../services/base-entity.service';
import { convertValueToLuxon } from '@common/util/date-time-formatting/convert-value-to-luxon';

@Injectable()
export class ImportFileDefinitionService extends BaseEntityService {
    constructor(
        private dataManager: DataManagerService
    ) {
        super();
    }

    async getImportFileDefinitions(queryDef: QueryDef): Promise<any> {
        let query = this.buildDefaultQuery('ImportFileDefinitions', queryDef);
        this.ensureDefExpanded(queryDef, 'cv_ImportFileType');
        this.ensureDefExpanded(queryDef, 'cv_ImportFileFormat');
        this.ensureDefExpanded(queryDef, 'ImportFileComponent');
        query = query.expand(queryDef.expands.join(','));

        let predicates: Predicate[] = [];
        if (queryDef.filter) {
            predicates = predicates.concat(this.buildPredicates(queryDef.filter));
        }

        if (notEmpty(predicates)) {
            query = query.where(Predicate.and(predicates));
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    getImportFileDefinition(
        importFileDefinitionKey: number,
        expands?: string[]
    ): Promise<any> {
        if (!expands) {
            expands = [];
        }
        this.ensureExpanded(expands, 'cv_ImportFileType');
        this.ensureExpanded(expands, 'cv_ImportFileFormat');
        this.ensureExpanded(expands, 'ImportFileComponent');

        const query = EntityQuery.from('ImportFileDefinitions')
            .expand(expands.join(','))
            .where('C_ImportFileDefinition_key', 'eq', importFileDefinitionKey);

        return this.dataManager.returnSingleQueryResult(query);
    }

    getImportFileDefinitionByName(
        definitionName: string,
        expands?: string[]
    ): Promise<any> {
        if (!expands) {
            expands = [];
        }
        this.ensureExpanded(expands, 'cv_ImportFileType');
        this.ensureExpanded(expands, 'cv_ImportFileFormat');
        this.ensureExpanded(expands, 'ImportFileComponent');

        const query = EntityQuery.from('ImportFileDefinitions')
            .expand(expands.join(','))
            .where('DefinitionName', 'eq', definitionName);

        return this.dataManager.returnSingleQueryResult(query);
    }

    buildPredicates(filter: any): Predicate[] {
        const predicates: Predicate[] = [];
        if (!filter) {
            return predicates;
        }

        if (filter.C_ImportFileDefinition_key) {
            predicates.push(Predicate.create(
                'C_ImportFileDefinition_key', 'eq', filter.C_ImportFileDefinition_key
            ));
        }

        if (filter.DefinitionName) {
            predicates.push(Predicate.create(
                'DefinitionName', FilterQueryOp.Contains, { value: filter.DefinitionName },
            ));
        }

        if (filter.CreatedBy) {
            predicates.push(Predicate.create(
                'CreatedBy', FilterQueryOp.Contains, { value: filter.CreatedBy },
            ));
        }

        if (filter.ModifiedBy) {
            predicates.push(Predicate.create(
                'ModifiedBy', FilterQueryOp.Contains, { value: filter.ModifiedBy },
            ));
        }

        if (filter.DateStart) {
            predicates.push(Predicate.create(
                'DateModified', '>=', filter.DateStart
            ));
        }

        if (filter.DateEnd) {
            const filterDate = convertValueToLuxon(filter.DatePooledEnd);
            const datePredicate = filterDate.plus({"day": 1});
            predicates.push(Predicate.create(
                'DateModified', '<=', datePredicate
            ));
        }

        if (filter.C_ImportFileFormat_key) {
            predicates.push(Predicate.create(
                'C_ImportFileFormat_key',
                'eq', filter.C_ImportFileFormat_key
            ));
        }

        if (filter.ImportFileFormat) {
            predicates.push(Predicate.create(
                'cv_ImportFileFormat.ImportFileFormat',
                FilterQueryOp.Contains, { value: filter.ImportFileFormat },
            ));
        }

        if (filter.C_ImportFileType_key) {
            predicates.push(Predicate.create(
                'C_ImportFileType_key',
                'eq', filter.C_ImportFileType_key
            ));
        }

        if (filter.ImportFileType) {
            predicates.push(Predicate.create(
                'cv_ImportFileType.ImportFileType',
                FilterQueryOp.Contains, { value: filter.ImportFileType },
            ));
        }

        return predicates;
    }

    // Return list of all components tied to specific def
    getImportFileComponents(importFileDefinitionKey: number): Promise<any[]> {
        const expands = [
            'ImportMappingTarget'
        ];
        const query = EntityQuery.from('ImportFileComponents')
            .expand(expands.join(','))
            .where('C_ImportFileDefinition_key',
            '==', importFileDefinitionKey)
            .orderBy('ComponentName');

        return this.dataManager.returnQueryResults(query);
    }

    createImportFileDefinition(initialValues?: any) {
        return this.dataManager
            .createEntity('ImportFileDefinition', initialValues);
    }

    deleteImportFileDefinition(importFileDefinition: any) {
        while (importFileDefinition.ImportFileComponent.length > 0) {
            this.deleteImportFileComponent(
                importFileDefinition.ImportFileComponent[0]);
        }
        this.dataManager.deleteEntity(importFileDefinition);
    }

    deleteImportFileComponent(importFileComponent: any) {
        this.dataManager.deleteEntity(importFileComponent);
    }
}

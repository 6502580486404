import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';

/**
 * Wraps Angular's DecimalPipe to prevent errors from invalid numbers.
 */
@Pipe({
    name: 'safeNumber'
})
export class SafeNumberPipe implements PipeTransform {

    constructor(private decimalPipe: DecimalPipe) {
    }

    transform(value: any, digitsInfo?: string, locale?: string): string {
        if (isNaN(value) || value === null || value === '') {
            return '';
        } else {
            return this.decimalPipe.transform(value, digitsInfo, locale);
        }
    }
}

import {
    Component,
    Input,
    OnChanges,
    OnInit
} from '@angular/core';

import { SampleService } from '../samples/sample.service';

@Component({
    selector: 'lot-select',
    template: `
    <select name="lot"
            [(ngModel)]="sample.C_Lot_key"
            class="form-control input-medium">
        <option></option>
        <option *ngFor="let lot of lots"
                [value]="lot.C_Lot_key">
            {{lot.LotNumber}}
        </option>
    </select>
    `
})
export class LotSelectComponent implements OnChanges, OnInit {
    @Input() sample: any;
    @Input() parentSampleOrderKey: number; 


    lots: any[] = [];

    constructor(
        private sampleService: SampleService
    ) {
        
    }

    ngOnInit() {
        this.getSampleOrderLots(this.parentSampleOrderKey);
    }

    ngOnChanges(changes: any) {
        if (changes.parentSampleOrderKey) {
            this.getSampleOrderLots(this.parentSampleOrderKey);
        }
    }

    getSampleOrderLots(sampleOrderKey: number) {
        const preferLocal = true;
        this.sampleService.getSampleOrderLots(sampleOrderKey, preferLocal).then((data) => {
            this.lots = data;
        });
    }
}

<table class="table" *ngIf="materialPools">
    <thead>
        <tr>
            <th>
                <a (click)="tableSort.toggleSort('MaterialPoolID')"
                   class="header-sort-link"
                   title="Sort by Housing Unit">Housing Unit</a>
            </th>
            <th>
                Animals
            </th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let materialPool of materialPools |
                orderBy: tableSort.propertyPath:tableSort.reverse">
            <td>
                {{materialPool?.MaterialPoolID}}
            </td>
            <td>
            <ng-container *ngIf="materialPool?.MaterialPoolMaterial">
                <div *ngFor="let material of materialPool.MaterialPoolMaterial  | housingCurrentAnimals">
                    {{material?.Material?.Animal?.AnimalName}}
                </div>
            </ng-container>
            </td>
        </tr>
    </tbody>
</table>

import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    ViewChild,
} from '@angular/core';

import {
    arrayInsertAtIndex,
    notEmpty
} from '../common/util';

import {
    Expression,
    ExpressionSegment,
    ExpressionSegmentFactory
} from '../tasks/calculated-output';
import * as jsep from 'jsep';

const nbsp = '\xa0';

@Component({
    selector: 'variable-standard-phrase',
    templateUrl: 'variable-standard-phrase.component.html',
    styleUrls: ['./variable-standard-phrase.component.scss'],
})
export class VariableStandardPhraseComponent {
    helpShowing = false;
    @Input() standardPhrase: any = null;
    @Input() variablesList: any[] = [];
    @Output() expressionChange: EventEmitter<any> = new EventEmitter<any>();
    expressionSegments: ExpressionSegment[] = [ExpressionSegmentFactory.createTextSegment(nbsp)];
    @Input() disabled: boolean;
    @Input() isUsed = false;

    @ViewChild('variableStandardPhraseField')
    private _variableStandardPhraseField: ElementRef;
    private _lastSegmentCursorPosition: any = null;
    private _expressionJson: string = null;

    /**
     * getter for the current cursor position
     */
    get segmentCursorPosition() {
        // getSelection() returns location of cursor in the text field via "anchorOffset"
        const selection = getSelection();

        if (selection && selection.anchorNode) {
            const segmentElement = selection.anchorNode.parentNode;
            const segmentIndex = this.expressionSegmentElementIndex(segmentElement);
            if (segmentIndex >= 0) {
                const cursorIndex = selection.anchorOffset;

                return { segmentIndex, cursorIndex, segmentElement };
            }
        }

        return null;
    }

    /**
     * Respond to a blur event on a segment.
     * @param event
     */
    segmentBlurred(event: any) {
        this.updateLastCursorPosition();

        const segmentIndex = this.expressionSegmentElementIndex(event.target);
        const segment = this.expressionSegments[segmentIndex];
        if (segment && segment.isFreeformText) {
            const segmentElem = this._variableStandardPhraseField.nativeElement.children[segmentIndex];
            if (segment.text !== segmentElem.textContent) {
                segment.text = segmentElem.innerText;
                this.expressionChange.emit(this.expression);
            }
        }
    }

    @Input('expressionJson')
    get expressionJson(): string {
        return this._expressionJson;
    }

    set expressionJson(exprJson: string) {
        this._expressionJson = exprJson;
        if (exprJson) {
            // here we need to parse and reconstruct the expression segments. The
            // reconstruction is needed because we lose memeber functions passing
            // from JS to JSON
            const expr = JSON.parse(exprJson);
            this.expressionSegments = expr.expressionSegments.map(
                (seg: ExpressionSegment) => new ExpressionSegment(seg));
        } else {
            this.expressionSegments = [ExpressionSegmentFactory.createTextSegment(nbsp)];
        }
    }

    get expression(): Expression {
        let parsedExpression = null;
        let validationError = null;
        try {
            parsedExpression = jsep(this.expressionString);
        } catch (e) {
            let description = e.description;
            if (!description) {
                description = 'invalid expression format';
            }
            validationError = description;
        }

        return {
            parsedExpression,
            validationError,
            expressionSegments: this.expressionSegments,
        };
    }

    /**
     * Function called when the user selects an output to insert into the expression
     * @param variable
     */
    variableSelected(variable: any) {
        this.insertSegments([
            ExpressionSegmentFactory.createStandardPhraseVariableSegment(variable)
        ]);
    }

    /**
     * Save the current cursor position as the "last" position. This is used for inserting
     * variable segments.
     */
    updateLastCursorPosition(): void {
        const cursorPos = this.segmentCursorPosition;
        if (cursorPos) {
            this._lastSegmentCursorPosition = cursorPos;
        }
    }

    /**
     * Remove an existing variable (input or output) segment. This will cause flanking
     * free-text segments to merge.
     * @param segmentIndex the index of the segment to remove
     */
    removeVariableSegment(segmentIndex: number): void {
        // we'll remove the variable segment but then we also need to join
        // the surrounding freetext segments
        const deletedSegments = this.expressionSegments.splice(segmentIndex, 2);

        if (deletedSegments.length === 2 && segmentIndex >= 1) {
            const freetextToJoin = deletedSegments[1];
            const segmentToUpdate = this.expressionSegments[segmentIndex - 1];
            segmentToUpdate.text = (segmentToUpdate.text + nbsp + freetextToJoin.text);
        }

        this._lastSegmentCursorPosition = null;
        this.expressionChange.emit(this.expression);
    }

    /**
     * In our component each expression segment corresponds to DOM element. This function
     * finds the index of the segment given that element.
     * @param element the DOM element for the segment
     */
    private expressionSegmentElementIndex(element: any): number {
        const childrenArr = Array.from(this._variableStandardPhraseField.nativeElement.children);
        return childrenArr.length === this.expressionSegments.length ? childrenArr.indexOf(element) : -1;
    }

    /**
     * Here we insert expression segments into the expression at
     * the last cursor position. This may result in splitting an existing free-freeform
     * text segment into two parts.
     * @param segments
     */
    private insertSegments(segments: ExpressionSegment[]): void {
        if (!notEmpty(segments)) {
            return;
        }

        if (this._lastSegmentCursorPosition) {
            // we insert based on the last place the cursor was by splitting
            // the relevant freetext segment
            const segmentIndex = this._lastSegmentCursorPosition.segmentIndex;
            const cursorIndex = this._lastSegmentCursorPosition.cursorIndex;
            if (segmentIndex >= 0) {
                const segmentToSplit = this.expressionSegments[segmentIndex];
                if (segmentToSplit && segmentToSplit.isFreeformText) {
                    const textToSplit = segmentToSplit.text;
                    const firstPart = textToSplit.substring(0, cursorIndex);
                    const secondPart = textToSplit.substring(cursorIndex);

                    // now update the segments with our split freetext and the new variable segment
                    segmentToSplit.text = firstPart;
                    const secondPartSegment = ExpressionSegmentFactory.createTextSegment(secondPart);

                    // insert new segments at index
                    const itemsToInsert = segments.slice();
                    itemsToInsert.push(secondPartSegment);
                    arrayInsertAtIndex(this.expressionSegments, segmentIndex + 1, itemsToInsert);
                }
            }
        } else {
            // if there was no "last cursor position" add it to the end of the expression
            for (const segment of segments) {
                this.expressionSegments.push(segment);
            }
            this.expressionSegments.push(
                ExpressionSegmentFactory.createTextSegment(nbsp)
            );
        }

        this.expressionSegments = this.mergeAdjacentTextSegments(this.expressionSegments);

        this.expressionChange.emit(this.expression);
    }

    private mergeAdjacentTextSegments(segments: ExpressionSegment[]): ExpressionSegment[] {
        const mergedSegments: ExpressionSegment[] = [];
        for (const segment of segments) {
            let previousSegment: ExpressionSegment;
            if (mergedSegments.length > 0) {
                previousSegment = mergedSegments[mergedSegments.length - 1];
            }

            if (previousSegment &&
                previousSegment.isFreeformText &&
                segment.isFreeformText
            ) {
                previousSegment.text += ' ' + segment.text;
            } else {
                mergedSegments.push(segment);
            }

        }

        return mergedSegments;
    }

    /**
     * We concatenate (and transform) all expression segments to form
     * an expression string
     */
    private get expressionString(): string {
        let exprStr = '';
        for (const segment of this.expressionSegments) {
            exprStr += this.segmentToExpressionString(segment) + ' ';
        }

        exprStr = exprStr.replace(/\s+/g, ' ');
        exprStr = exprStr.trim();

        return exprStr;
    }

    /**
     * Convert the segment to a string that can be interpreted by the jsep
     * parser.
     * @param segment
     */
    private segmentToExpressionString(segment: ExpressionSegment): string {
        if (segment.isFreeformText) {
            return segment.text;
        } else {
            return segment.variableName;
        }
    }
}

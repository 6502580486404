import { ParamTypes } from './param-types';
import { ParamInputs } from './param-inputs';
import { 
    ParamGroupItem,
    ParameterItem ,
    SubParameterItem
} from '../models';

import { ReportingService } from '../reporting.service';
import { TranslationService } from '../../services/translation.service';

export class ParameterFactory {
    
    private reportingService: ReportingService;
    private translationService: TranslationService;

    readonly PARAMS_TO_IGNORE = [
        'daterangeend',
        'daterangedateend',
        'duedaterangeend',
        'duedaterangedateend',
    ];

    setReportingService(reportingService: ReportingService) {
        this.reportingService = reportingService;
    }

    setTranslationService(translationService: TranslationService) {
        this.translationService = translationService;
    }

    resolveParameterType(paramName: string): ParameterItem {
        const paramNameLower = paramName.toLocaleLowerCase();
        
        // some parameters get excluded
        // e.g. if they are part of a composite parameter like date range.
        if (this.PARAMS_TO_IGNORE.indexOf(paramNameLower) >= 0) {
            return null;
        }
        
        const paramConfig = this._createParamConfig(paramName);
     
        switch (paramConfig.paramType) {
            case ParamTypes.date:
                return this.buildParamDate(paramConfig.paramName, paramConfig.customNameLabel);
                
            case ParamTypes.daterange:
                return this.buildParamDateRange(paramConfig.paramName);

            case ParamTypes.duedaterange:
                return this.buildParamDueDateRange(paramConfig.paramName);
                
            case ParamTypes.animalids:
                return this.buildParamAnimalIds(paramConfig.paramName);
                
            case ParamTypes.weanids:
                return this.buildParamWeanIds(paramConfig.paramName);
                
            case ParamTypes.matingids:
                return this.buildParamMatingIds(paramConfig.paramName);
                
            case ParamTypes.jobid:
                return this.buildParamJobId(paramConfig.paramName);

            case ParamTypes.jobkey:
                return this.buildParamJobId(paramConfig.paramName);

            case ParamTypes.jobids:
                return this.buildParamJobIds(paramConfig.paramName);

            case ParamTypes.taskname:
                return this.buildParamTaskName(paramConfig.paramName);

            case ParamTypes.animalstatus:
                return this.buildParamAnimalStatus(paramConfig.paramName);

            case ParamTypes.iacucprotocol:
                return this.buildParamIACUCProtocol(paramConfig.paramName);
        }

        return null;
    }


    private _createParamConfig(paramName: string): ParamConfig {
        const paramConfig = { 
            paramName, 
            paramType: paramName.toLowerCase(), 
            customNameLabel: "" 
        };
        const conversions = [
            { 
                startsWith: "daterange", 
                newParamType: "daterange", 
                newParamName: "DateRange" 
            },
            {
                startsWith: "duedaterange",
                newParamType: "duedaterange",
                newParamName: "DueDateRange"
            },
            { 
                startsWith: "date", 
                newParamType: "date", 
                newParamName: null 
            }
        ];

        for (const conversion of conversions) {
            if (paramConfig.paramType.indexOf(conversion.startsWith) === 0) {
                paramConfig.customNameLabel = paramConfig.paramName
                    .substring(conversion.startsWith.length)
                    .replace(/\W+/g, ' ')
                    .replace(/([a-z\d])([A-Z])/g, '$1 $2');

                paramConfig.paramType = conversion.newParamType;
                if (conversion.newParamName) {
                    paramConfig.paramName = conversion.newParamName;
                }

                break;
            }
        }

        return paramConfig;
    }


    buildParamDate(paramName: string, labelText: string): ParameterItem {
        const paramItem = new ParameterItem({
            key: paramName, groupSelectionText: ""
        });
        const groupItem = this._buildParamGroupDate(paramName, paramName, labelText);

        paramItem.groups.push(groupItem);
        paramItem.selectedGroup = groupItem;

        return paramItem;
    }

    private _buildParamGroupDate(
        groupKey: any, 
        valueKey: any, 
        labelText: string
    ): ParamGroupItem {
        const groupItem = new ParamGroupItem({
            key: groupKey, 
            text: "",
            valueKey,
            extractValue: false
        });
        const subParam = new SubParameterItem({
            key: valueKey,
            text: labelText,
            inputType: ParamInputs.date
        });

        groupItem.subParams.push(subParam);
        subParam.results = null;

        return groupItem;
    }

    buildParamDateRange(paramName: string): ParameterItem {
        const paramItem = new ParameterItem({
            key: paramName, groupSelectionText: ""
        });
        const groupItem = this._buildParamGroupDateRange(paramName, paramName);

        paramItem.groups.push(groupItem);
        paramItem.selectedGroup = groupItem;

        return paramItem;
    }

    private _buildParamGroupDateRange(groupKey: string, valueKey: string): ParamGroupItem {
        const groupItem = new ParamGroupItem({
            key: groupKey, 
            text: "",
            valueKey,
            extractValue: true
        });
        const subParam = new SubParameterItem({
            key: valueKey,
            text: "Date Range",
            inputType: ParamInputs.daterange,
            details: {
                keyStartDate: "DateStart",
                keyEndDate: "DateEnd"
            }
        });
        const defaultValue: any = {};

        defaultValue[subParam.details.keyStartDate] = null;
        defaultValue[subParam.details.keyEndDate] = null;

        groupItem.subParams.push(subParam);
        subParam.results = defaultValue;

        return groupItem;
    }

    buildParamDueDateRange(paramName: string): ParameterItem {
        const paramItem = new ParameterItem({
            key: paramName, groupSelectionText: ""
        });
        const groupItem = this._buildParamGroupDueDateRange(paramName, paramName);

        paramItem.groups.push(groupItem);
        paramItem.selectedGroup = groupItem;

        return paramItem;
    }

    private _buildParamGroupDueDateRange(groupKey: string, valueKey: string): ParamGroupItem {
        const groupItem = new ParamGroupItem({
            key: groupKey,
            text: "",
            valueKey,
            extractValue: true,
        });
        const subParam = new SubParameterItem({
            key: valueKey,
            text: "Due Date Range",
            inputType: ParamInputs.daterange,
            details: {
                keyStartDate: "DateStart",
                keyEndDate: "DateEnd",
            },
        });
        const defaultValue: any = {};

        defaultValue[subParam.details.keyStartDate] = null;
        defaultValue[subParam.details.keyEndDate] = null;

        groupItem.subParams.push(subParam);
        subParam.results = defaultValue;

        return groupItem;
    }

    buildParamAnimalIds(paramName: string): ParameterItem {
        const paramItem = new ParameterItem({
            key: paramName, groupSelectionText: "Choose animals by"
        });

        // By Animal Ids
        const animalIdGroup = this._buildParamGroupAnimalIdsByName("001ByAnimalIds", paramName);
        paramItem.groups.push(animalIdGroup);
        paramItem.selectedGroup = animalIdGroup;

        // By Birth Ids
        const birthIdGroup = this._buildParamGroupAnimalIdsByBirth("002ByBirthIds", paramName);
        paramItem.groups.push(birthIdGroup);

        return paramItem;
    }

    private _buildParamGroupAnimalIdsByName(groupKey: string, valueKey: string): ParamGroupItem {
        const groupItem = new ParamGroupItem({
            key: groupKey, 
            text: "Name",
            valueKey,
            extractValue: true
        });

        const subParam = new SubParameterItem({
            key: valueKey,
            text: "Animal Names",
            inputType: ParamInputs.autocompletemultiselect,
            details: {
                valuesText: "Animals",
                getDataFunction: (viewText: string) => {
                    return this.reportingService.getLookupAnimalIds(viewText);
                }
            }
        });

        groupItem.subParams.push(subParam);
        subParam.results = [];

        return groupItem;
    }

    private _buildParamGroupAnimalIdsByBirth(groupKey: string, valueKey: string): ParamGroupItem {
        const groupItem = new ParamGroupItem({
            key: groupKey, 
            text: "Birth",
            valueKey,
            extractValue: true
        });

        const subParam = new SubParameterItem({
            key: valueKey,
            text: "Birth ID",
            inputType: ParamInputs.autocompletemultiselect,
            details: {
                values: null,
                valuesText: "Animals",
                getDataFunction: (parentValue: any) => {
                    return this.reportingService.getLookupBirthIds(parentValue);
                },
                getDependentDataFunction: async (parentValue: any) => {
                    const value = parentValue?.value?.toString();
                    if (!value) {
                        return;
                    }
                    subParam.results = [];
                    const data = await this.reportingService.getLookupAnimalIdsByBirthId(parentValue?.value?.toString());
                    subParam.results = data;
                }
            }
        });

        groupItem.subParams.push(subParam);
        subParam.results = [];

        return groupItem;
    }

    buildParamWeanIds(paramName: string): ParameterItem {

        const groupKey = "ByWeanIds";

        const paramItem = new ParameterItem({
            key: paramName, 
            groupSelectionText: ""
        });

        const groupItem = new ParamGroupItem({
            key: groupKey, text: "",
            valueKey: paramName,
            extractValue: true
        });

        paramItem.groups.push(groupItem);
        paramItem.selectedGroup = groupItem;

        const subParam = new SubParameterItem({
            key: paramName,
            text: "Wean Housing IDs",
            inputType: ParamInputs.autocompletemultiselect,
            details: {
                valuesText: "",
                getDataFunction: (viewText: string) => {
                    return this.reportingService.getLookupWeanIds(viewText);
                }
            }
        });
        groupItem.subParams.push(subParam);
        subParam.results = [];

        return paramItem;
    }

    buildParamMatingIds(paramName: string): ParameterItem {
        const groupKey = "ByMatingIds";

        const paramItem = new ParameterItem({
            key: paramName, 
            groupSelectionText: "",
        });

        const groupItem = new ParamGroupItem({
            key: groupKey, text: "",
            valueKey: paramName,
            extractValue: true
        });

        paramItem.groups.push(groupItem);
        paramItem.selectedGroup = groupItem;

        const subParam = new SubParameterItem({
            key: paramName,
            text: "Mating Housing IDs",
            inputType: ParamInputs.autocompletemultiselect,
            details: {
                valuesText: "",
                getDataFunction: (viewText: string) => {
                    return this.reportingService.getLookupMatingIds(viewText);
                }
            }
        });
        groupItem.subParams.push(subParam);
        subParam.results = [];

        return paramItem;
    }

    buildParamJobId(paramName: string): ParameterItem {
        const groupKey = "ByJobId";

        const paramItem = new ParameterItem({
            key: paramName, 
            groupSelectionText: "",
        });

        const groupItem = new ParamGroupItem({
            key: groupKey, text: "",
            valueKey: paramName,
            extractValue: true
        });

        paramItem.groups.push(groupItem);
        paramItem.selectedGroup = groupItem;

        const subParam = new SubParameterItem({
            key: paramName,
            text: this.translationService.translate('Job') + " Name",
            inputType: ParamInputs.autocompletesingleselect,
            details: {
                valuesText: "",
                getDataFunction: (viewText: string) => {
                    return this.reportingService.getLookupJobIds(viewText);
                }
            }
        });
        groupItem.subParams.push(subParam);
        subParam.results = [];

        return paramItem;
    }


    buildParamJobIds(paramName: string): ParameterItem {
        const groupKey = "ByJobIds";

        const paramItem = new ParameterItem({
            key: paramName, 
            groupSelectionText: "",
        });

        const groupItem = new ParamGroupItem({
            key: groupKey, text: "",
            valueKey: paramName,
            extractValue: true
        });

        paramItem.groups.push(groupItem);
        paramItem.selectedGroup = groupItem;

        const subParam = new SubParameterItem({
            key: paramName,
            text: this.translationService.translate('Job') + " Names",
            inputType: ParamInputs.autocompletemultiselect,
            details: {
                valuesText: "",
                getDataFunction: (viewText: string) => {
                    return this.reportingService.getLookupJobIds(viewText);
                }
            }
        });
        groupItem.subParams.push(subParam);
        subParam.results = [];

        return paramItem;
    }

    buildParamTaskName(paramName: string): ParameterItem {
        const groupKey = "ByTaskName";

        const paramItem = new ParameterItem({
            key: paramName,
            groupSelectionText: "",
        });

        const groupItem = new ParamGroupItem({
            key: groupKey, text: "",
            valueKey: paramName,
            extractValue: true
        });

        paramItem.groups.push(groupItem);
        paramItem.selectedGroup = groupItem;

        const subParam = new SubParameterItem({
            key: paramName,
            text: "Task Name",
            inputType: ParamInputs.autocompletesingleselect,
            details: {
                valuesText: "",
                getDataFunction: (viewText: string) => {
                    return this.reportingService.getLookupTaskNames(viewText);
                }
            }
        });
        groupItem.subParams.push(subParam);
        subParam.results = [];

        return paramItem;
    }

    buildParamAnimalStatus(paramName: string): ParameterItem {
        const groupKey = "ByAnimalStatus";

        const paramItem = new ParameterItem({
            key: paramName,
            groupSelectionText: "",
        });

        const groupItem = new ParamGroupItem({
            key: groupKey, text: "",
            valueKey: paramName,
            extractValue: true,
        });

        paramItem.groups.push(groupItem);
        paramItem.selectedGroup = groupItem;

        const subParam = new SubParameterItem({
            key: paramName,
            text: "Animal Status",
            inputType: ParamInputs.autocompletesingleselect,
            details: {
                valuesText: "",
                getDataFunction: (viewText: string) => {
                    return this.reportingService.getLookupAnimalStatuses(viewText);
                },
            },
        });
        groupItem.subParams.push(subParam);
        subParam.results = [];

        return paramItem;
    }

    buildParamIACUCProtocol(paramName: string): ParameterItem {
        const groupKey = "ByIACUCProtocol";

        const paramItem = new ParameterItem({
            key: paramName,
            groupSelectionText: "",
        });

        const groupItem = new ParamGroupItem({
            key: groupKey, text: "",
            valueKey: paramName,
            extractValue: true,
        });

        paramItem.groups.push(groupItem);
        paramItem.selectedGroup = groupItem;

        const subParam = new SubParameterItem({
            key: paramName,
            text: "IACUC Protocol",
            inputType: ParamInputs.autocompletesingleselect,
            details: {
                valuesText: "",
                getDataFunction: (viewText: string) => {
                    return this.reportingService.getLookupIACUCProtocols(viewText);
                },
            },
        });
        groupItem.subParams.push(subParam);
        subParam.results = [];

        return paramItem;
    }
}

class ParamConfig {
    paramType: string;
    paramName: string;
    customNameLabel: string;
}

import { Injectable } from '@angular/core';
import {
    NgbModal,
    NgbModalOptions
} from '@ng-bootstrap/ng-bootstrap';

import { WipModalComponent } from './wip-modal.component';


@Injectable()
export class WipModalService {

    modalOptions: NgbModalOptions;


    constructor(
        private modalService: NgbModal
    ) {
        this.init();
    }

    private init() {
        // NgbModalOptions: https://ng-bootstrap.github.io/#/components/modal/api#NgbModalOptions
        // this.modalOptions = {
        //     size: 'sm'
        // };
    }


    openWipModal(): Promise<any> {
        // Open modal
        const modalRef = this.modalService.open(WipModalComponent, this.modalOptions);
        return modalRef.result;
    }
}

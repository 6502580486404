import { TaskTableModule } from './../tasks/task-table.module';
import { BirthBulkTemplatesComponent } from './bulkedit/birth-bulk-templates.component';
import { BirthBulkEditComponent } from './bulkedit/birth-bulk-edit.component';
import { FacetSharedModule } from './../common/facet/facet-shared.module';
import { ReportingSharedModule } from './../reporting/reporting-shared.module';
import { EnumerationsSharedModule } from './../enumerations/enumerations-shared.module';
import { AnimalsSharedModule } from './../animals/animals-shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { ClimbCommonModule } from '../common/climb-common.module';
import { ClimbServicesModule } from '../services/climb-services.module';

import { BirthsSharedModule } from './births-shared.module';

import {
    BirthDetailComponent,
    BirthFacetComponent,
    BirthFilterComponent,
    BirthHousingCellRendererComponent,
    BirthLogicService
} from '.';

import {
    BirthAnimalTableComponent,
    BirthHousingTableComponent,
    BirthMatingTableComponent
} from './tables';

import { CharacteristicsSharedModule } from '../characteristics/characteristics-shared.module';
import { ConstructsSharedModule } from '../constructs/constructs-shared.module';
import { HousingSharedModule } from '../housing/housing-shared.module';
import { LinesSharedModule } from '../lines/lines-shared.module';
import { LocationsSharedModule } from '../locations/locations-shared.module';
import { MatingsSharedModule } from '../matings/matings-shared.module';
import { UsersSharedModule } from '../user/users-shared.module';
import { WorkspacesSharedModule } from '../workspaces/workspaces-shared.module';
import { VocabulariesSharedModule } from '../vocabularies/vocabularies-shared.module';
import { JobsSharedModule } from '../jobs/jobs-shared.module';
import { DatesEqualPipe } from '@common/pipes/dates-equal/dates-equal.pipe';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgbModule,
        ClimbCommonModule,
        FacetSharedModule,
        ClimbServicesModule,
        AnimalsSharedModule,
        BirthsSharedModule,
        CharacteristicsSharedModule,
        ConstructsSharedModule,
        EnumerationsSharedModule,
        HousingSharedModule,
        LinesSharedModule,
        LocationsSharedModule,
        MatingsSharedModule,
        ReportingSharedModule,
        TaskTableModule,
        UsersSharedModule,
        VocabulariesSharedModule,
        WorkspacesSharedModule,
        JobsSharedModule
    ],
    declarations: [
        BirthBulkEditComponent,
        BirthBulkTemplatesComponent,
        BirthDetailComponent,
        BirthFacetComponent,
        BirthFilterComponent,
        BirthHousingCellRendererComponent,
        BirthAnimalTableComponent,
        BirthHousingTableComponent,
        BirthMatingTableComponent,
        DatesEqualPipe
    ],
    exports: [
        BirthFacetComponent,
        BirthFilterComponent
    ],
    providers: [
        BirthLogicService
    ]
})
export class BirthsModule { }

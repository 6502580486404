import { FormGroup, ValidationErrors, ValidatorFn } from "@angular/forms";


export const atLeastOneValidationError: ValidationErrors = {
    atLeastOneRequired: {
        text: 'At least one value is required'
    }
};
/**
 * Validator function to ensure at least one of the specified controls 
 * (or any control if none specified) in the form group has a non-null, 
 * non-whitespace string value.
 *
 * @param controlNames - The list of control names to check for non-null values. If not provided, all controls are checked.
 */
export function atLeastOneValidator(controlNames?: string[]): ValidatorFn {
    return (formGroup: FormGroup): ValidationErrors | null => {
        const controlsToCheck = controlNames?.length ? controlNames : Object.keys(formGroup.controls);
        for (const controlName of controlsToCheck) {
            const control = formGroup.get(controlName);
            if (control && isValid(control.value)) {
                return null;
            }
        }

        return atLeastOneValidationError;
    }
}

function isValid(value: any): boolean {
    return value !== null && typeof value === 'string' && value.trim() !== '';
}
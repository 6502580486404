import { cv_StudyType } from "./cv-study-type.interface";
import { StudyCharacteristic } from "./study-characteristic.interface";

export interface StudyCharacteristicStudyType {
    C_StudyCharacteristicStudyType_key: number;
    C_StudyType_key: number;
    C_StudyCharacteristic_key: number;
    cv_StudyType: cv_StudyType;
    StudyCharacteristic: StudyCharacteristic; 
}

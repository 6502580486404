import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';

import {
    ReportSource,
    ReportTypeItem,
} from '../models';

import {
    randomId
} from '../../common/util';

@Component({
    selector: 'reporting-detail',
    templateUrl: './reporting-detail.component.html'
})
export class ReportingDetailComponent implements OnInit {
    @Input() facet: any;
    @Input() reportSource: ReportSource;
    @Input() reportType: ReportTypeItem;

    @Output() exit: EventEmitter<void> = new EventEmitter<void>();

    domIdAddition: string;

    // Export enum to template
    ReportSource = ReportSource;


    // lifecycle
    ngOnInit() {
        this.domIdAddition = randomId();
    }

    exitClicked() {
        this.exit.emit();
    }
}

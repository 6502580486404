import type { Job } from './job.interface';
import type { WorkflowTask } from './workflow-task.interface';

export interface JobWorkflowTask {
    C_CurrentWorkflowTask_key: number | null;
    C_JobWorkflowTask_key: number;
    C_Job_key: number | null;
    C_OriginalWorkflowTask_key: number | null;
    C_Workgroup_key: number;
    CreatedBy: string;
    CurrentWorkflowTask: WorkflowTask;
    DateCreated: Date;
    DateModified: Date;
    Job: Job;
    ModifiedBy: string;
    OriginalWorkflowTask: WorkflowTask;
    Version: number;
}

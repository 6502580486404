import {
    Pipe,
    PipeTransform
} from '@angular/core';

import { animalAgeMonths } from '../util';

/**
 * Returns the age of an animal (in months)
 */
@Pipe({ name: 'animalAgeMonths' })
export class AnimalAgeMonthsPipe implements PipeTransform {

    transform(birthDate: any, exitDate?: any): string {
        return animalAgeMonths(birthDate, exitDate);
    }
}

<div class="detail-form-wrapper">

    <div class="detail-header">
        <div class="detail-toolbar">
            <button type="button"
                    class="btn btn-secondary dropdown-toggle"
                    title="Print Cage Card"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false">
                <i class="fa fa-print" aria-hidden="true"></i>
                <span class="gridster-d-sm-none gridster-d-md-inline-block">Card</span>
                <span class="sr-only">Print Cage Card</span>
            </button>
            <div class="dropdown-menu float-right">
                <a
                    *ngFor="let item of cageCardReportTypes"
                    class="dropdown-item"
                    (click)="requestCageCard(item)"
                >
                    {{item}}
                </a>
            </div>
            <button type="button"
                    class="btn btn-secondary"
                    title="Back"
                    (click)="goBack()">
                <i class="fa fa-table" aria-hidden="true" title="Back"></i>
                <span class="gridster-d-sm-none gridster-d-md-inline-block">Back</span>
                <span class="sr-only">Back</span>
            </button>
        </div>
    </div>

    <div *ngIf="housing | isDeleted"
            class="alert alert-warning"
            role="alert">
        <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
        This housing has been deleted.
    </div>

    <form *ngIf="!(housing | isDeleted)"
            name="housingForm" id="housingForm"
            class="detail-form"
            #housingForm="ngForm">
        <fieldset [disabled]="facet.Privilege === 'ReadOnly'">
            <div class="form-group row">
                <label class="col-md-4">Housing</label>
                <div class="col-md-12">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th>Housing ID</th>
                                <th>Scan</th>
                                <th>Housing Type</th>
                                <th>Housing Status</th>
                                <th>Date Housed</th>
                                <th>Owner</th>
                                <th>Location</th>
                                <th>Comments</th>
                                <th>Discrepancy</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{{ housing.MaterialPool.MaterialPoolID }}</td>
                                <td class="text-nowrap">
                                    <input type="text"
                                            name="scan-{{housing.MaterialPoolScanValue}}"
                                            [(ngModel)]="housing.MaterialPoolScanValue"
                                            class="input-small" />
                                    <ng-container *ngIf="housing.MaterialPoolScanValue !== null">
                                        <i *ngIf="housing.MaterialPoolScanValue === housing.MaterialPool.MaterialPoolID" class="fa fa-check text-success"></i>
                                        <i *ngIf="housing.MaterialPoolScanValue !== housing.MaterialPool.MaterialPoolID" class="fa fa-exclamation-triangle text-danger"></i>
                                    </ng-container>
                                </td>
                                <td>
                                    <active-vocab-select [(model)]="housing.MaterialPool.C_MaterialPoolType_key"
                                                            [vocabChoices]="housingTypes"
                                                            [keyFormatter]="materialPoolTypesKeyFormatter"
                                                            [optionFormatter]="materialPoolTypesStatusFormatter">
                                    </active-vocab-select>
                                </td>
                                <td>
                                    <active-vocab-select [(model)]="housing.MaterialPool.C_MaterialPoolStatus_key"
                                                            [vocabChoices]="housingStatuses"
                                                            [keyFormatter]="materialPoolStatusKeyFormatter"
                                                            [optionFormatter]="materialPoolStatusStatusFormatter">
                                    </active-vocab-select>
                                </td>
                                <td>
                                    <climb-ngb-date #dateControl="ngModel" name="Date Pooled" [(ngModel)]="housing.MaterialPool.DatePooled"></climb-ngb-date>
                                </td>
                                <td>
                                    <input type="text" name="owner" [(ngModel)]="housing.MaterialPool.Owner" />
                                </td>
                                <td>
                                    {{ housing.MaterialPool.CurrentLocationPath }}
                                </td>
                                <td>
                                    <textarea name="comments"
                                        [(ngModel)]="housing.MaterialPool.Comments"
                                        class="form-control input-medium animal-detail-comments"
                                        style="display: table-cell"
                                        maxlength="100"
                                        rows="1"></textarea>
                                </td>
                                <td>
                                    <active-vocab-select [(model)]="housing.C_CensusDiscrepancy_key"
                                                            [vocabChoices]="discrepancies"
                                                            [keyFormatter]="discrepancyKeyFormatter"
                                                            [optionFormatter]="discrepancyStatusFormatter"
                                                            [defaultValue]="defaultDiscrepancy"
                                                            [loadDefaultValue]="true">
                                    </active-vocab-select>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <ng-container *ngIf="housing?.CensusMaterialPoolMaterial?.length > 0">
                <div class="form-group row">
                    <label class="col-md-4 align-self-end mb-0">Animals</label>
                    <span class="col-md-8 col-form-label text-right">
                        <climb-column-select [columnLabels]="columnSelect"
                                             (onChange)="columnSelectChanged($event)"
                                             class="old-fashioned">
                        </climb-column-select>
                </span>
                </div>
                <div class="form-group row">
                    <div class="col-md-12">
                        <table class="table table-bordered">
                            <thead>
                            <tr>
                                <th *ngIf="visible.ID">ID</th>
                                <th *ngIf="visible.Name">Name</th>
                                <th *ngIf="visible.Scan">
                                    <bulk-edit-header [headerText]="'Scan'"
                                                      (updateClicked)="bulkScanChanged()"
                                                      [disabled]="loading">
                                        <div modal-content>
                                            <multi-paste-input [(model)]="bulk.ScanIdentifiers"
                                                               [limit]="MULTI_PASTE_INPUT_LIMIT"
                                                               [rows]="10"></multi-paste-input>
                                        </div>
                                    </bulk-edit-header>
                                </th>
                                <th *ngIf="visible.MicrochipID">Microchip ID</th>
                                <th *ngIf="visible.Line">{{'Line' | translate}}</th>
                                <th *ngIf="visible.Status">
                                    <bulk-edit-header [headerText]="'Status'"
                                                      (updateClicked)="bulkStatusChanged()"
                                                      [disabled]="loading">
                                        <div modal-content class="text-body">
                                            <active-vocab-select [(model)]="bulk.statusKey"
                                                                 [vocabChoices]="animalStatuses"
                                                                 [keyFormatter]="animalStatusKeyFormatter"
                                                                 [optionFormatter]="animalStatusStatusFormatter"
                                                                 [nullable]="true"></active-vocab-select>
                                            <label>
                                                <input type="checkbox"
                                                       class="mt-1"
                                                       [(ngModel)]="bulk.force"
                                                       name="bulk_Force" />
                                                Overwrite existing values
                                            </label>
                                        </div>
                                    </bulk-edit-header>
                                </th>
                                <th *ngIf="visible.BreedingStatus">
                                    <bulk-edit-header [headerText]="'Breeding Status'"
                                                      (updateClicked)="bulkBreedingChanged()"
                                                      [disabled]="loading">
                                        <div modal-content class="text-body">
                                            <active-vocab-select [(model)]="bulk.breedingKey"
                                                                 [vocabChoices]="birthStatuses"
                                                                 [keyFormatter]="birthStatusKeyFormatter"
                                                                 [optionFormatter]="birthStatusStatusFormatter"
                                                                 [nullable]="true"></active-vocab-select>
                                            <label>
                                                <input type="checkbox"
                                                       class="mt-1"
                                                       [(ngModel)]="bulk.force"
                                                       name="bulk_Force" />
                                                Overwrite existing values
                                            </label>
                                        </div>
                                    </bulk-edit-header>
                                </th>
                                <th *ngIf="visible.Use">
                                    <bulk-edit-header [headerText]="'Use'"
                                                      (updateClicked)="bulkUseChanged()"
                                                      [disabled]="loading">
                                        <div modal-content class="text-body">
                                            <active-vocab-select [(model)]="bulk.useKey"
                                                                 [vocabChoices]="animalUses"
                                                                 [keyFormatter]="animalUseKeyFormatter"
                                                                 [optionFormatter]="animalUseStatusFormatter"
                                                                 [nullable]="true"></active-vocab-select>
                                            <label>
                                                <input type="checkbox"
                                                       class="mt-1"
                                                       [(ngModel)]="bulk.force"
                                                       name="bulk_Force" />
                                                Overwrite existing values
                                            </label>
                                        </div>
                                    </bulk-edit-header>
                                </th>
                                <th *ngIf="visible.Sex">
                                    <bulk-edit-header [headerText]="'Sex'"
                                                      (updateClicked)="bulkSexChanged()"
                                                      [disabled]="loading">
                                        <div modal-content class="text-body">
                                            <active-vocab-select [(model)]="bulk.sexKey"
                                                                 [vocabChoices]="sexes"
                                                                 [keyFormatter]="sexKeyFormatter"
                                                                 [optionFormatter]="sexStatusFormatter"
                                                                 [nullable]="true"></active-vocab-select>
                                            <label>
                                                <input type="checkbox"
                                                       class="mt-1"
                                                       [(ngModel)]="bulk.force"
                                                       name="bulk_Force" />
                                                Overwrite existing values
                                            </label>
                                        </div>
                                    </bulk-edit-header>
                                </th>
                                <th *ngIf="visible.Marker">
                                    <bulk-edit-header [headerText]="'Marker'"
                                                      (updateClicked)="bulkMarkerChanged()"
                                                      [disabled]="loading">
                                        <div modal-content class="text-body">
                                            <input type="text"
                                                   [(ngModel)]="bulk.marker"
                                                   name="bulk_Marker"
                                                   class="form-control input-medium" />
                                            <label>
                                                <input type="checkbox"
                                                       class="mt-1"
                                                       [(ngModel)]="bulk.force"
                                                       name="bulk_Force" />
                                                Overwrite existing values
                                            </label>
                                        </div>
                                    </bulk-edit-header>
                                </th>
                                <th *ngIf="visible.Discrepancy">
                                    <bulk-edit-header [headerText]="'Discrepancy'"
                                                      (updateClicked)="bulkDiscrepancyChanged()"
                                                      [disabled]="loading">
                                        <div modal-content class="text-body">
                                            <active-vocab-select [(model)]="bulk.discrepancyKey"
                                                                 [vocabChoices]="discrepancies"
                                                                 [keyFormatter]="discrepancyKeyFormatter"
                                                                 [optionFormatter]="discrepancyStatusFormatter"
                                                                 [nullable]="true"></active-vocab-select>
                                            <label>
                                                <input type="checkbox"
                                                       class="mt-1"
                                                       [(ngModel)]="bulk.force"
                                                       name="bulk_Force" />
                                                Overwrite existing values
                                            </label>
                                        </div>
                                    </bulk-edit-header>
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr *ngFor="let animal of housing.CensusMaterialPoolMaterial">
                                <td *ngIf="visible.ID">
                                    <input type="text" name="ID-{{animal.Material.Animal.AnimalName}}" disabled [(ngModel)]="animal.Material.Identifier" class="input-small" />
                                </td>
                                <td *ngIf="visible.Name" class="input-small">{{ animal.Material.Animal.AnimalName }}</td>
                                <td *ngIf="visible.Scan" class="text-nowrap">
                                    <input type="text"
                                           name="scan-{{animal.Material.Animal.AnimalName}}"
                                           [(ngModel)]="animal.MaterialScanValue"
                                           (ngModelChange)="scanValueChanged(animal)"
                                           class="input-small" />
                                    <i *ngIf="animal.MaterialScanValue === animal.Material.Animal.AnimalName" class="fa fa-check text-success"></i>
                                    <i *ngIf="animal.MaterialScanValue !== animal.Material.Animal.AnimalName" class="fa fa-exclamation-triangle text-danger"></i>
                                </td>
                                <td *ngIf="visible.MicrochipID">
                                    {{animal.Material.MicrochipIdentifier}}
                                </td>
                                <td *ngIf="visible.Line" class="input-small">
                                    {{ animal.Material?.Line?.LineName }}
                                </td>
                                <td *ngIf="visible.Status">
                                    <active-vocab-select [(model)]="animal.Material.Animal.C_AnimalStatus_key"
                                                         (modelChange)="animalStatusChanged(animal.Material.Animal)"
                                                         [vocabChoices]="animalStatuses"
                                                         [keyFormatter]="animalStatusKeyFormatter"
                                                         [optionFormatter]="animalStatusStatusFormatter">
                                    </active-vocab-select>
                                </td>
                                <td *ngIf="visible.BreedingStatus">
                                    <active-vocab-select [(model)]="animal.Material.Animal.C_BreedingStatus_key"
                                                         [vocabChoices]="birthStatuses"
                                                         [keyFormatter]="birthStatusKeyFormatter"
                                                         [optionFormatter]="birthStatusStatusFormatter">
                                    </active-vocab-select>
                                </td>
                                <td *ngIf="visible.Use">
                                    <active-vocab-select [(model)]="animal.Material.Animal.C_AnimalUse_key"
                                                         [vocabChoices]="animalUses"
                                                         [keyFormatter]="animalUseKeyFormatter"
                                                         [optionFormatter]="animalUseStatusFormatter">
                                    </active-vocab-select>
                                </td>
                                <td *ngIf="visible.Sex">
                                    <active-vocab-select [(model)]="animal.Material.Animal.C_Sex_key"
                                                         [vocabChoices]="sexes"
                                                         [keyFormatter]="sexKeyFormatter"
                                                         [optionFormatter]="sexStatusFormatter">
                                    </active-vocab-select>
                                </td>
                                <td *ngIf="visible.Marker">
                                    <input *ngIf="!(animal.Material.Animal.cv_PhysicalMarkerType?.Vendor)"
                                           type="text"
                                           name="marker-{{animal.Material.Animal.AnimalName}}"
                                           [(ngModel)]="animal.Material.Animal.PhysicalMarker"
                                           size="10"
                                           class="input-small" />
                                    <animal-marker *ngIf="animal.Material.Animal.cv_PhysicalMarkerType?.Vendor"
                                                   class="d-block mt-1"
                                                   [marker]="animal.Material.Animal.PhysicalMarker"
                                                   [markerType]="animal.Material.Animal.cv_PhysicalMarkerType">
                                    </animal-marker>
                                </td>
                                <td *ngIf="visible.Discrepancy">
                                    <active-vocab-select [(model)]="animal.C_CensusDiscrepancy_key"
                                                         [vocabChoices]="discrepancies"
                                                         [keyFormatter]="discrepancyKeyFormatter"
                                                         [optionFormatter]="discrepancyStatusFormatter"
                                                         [defaultValue]="defaultDiscrepancy"
                                                         [loadDefaultValue]="true">
                                    </active-vocab-select>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </ng-container>

            <div class="d-flex justify-content-start flex-wrap">
                <button class="btn btn-lg btn-primary ml-0 mr-2 my-1" (click)="saveChanges()">Save Changes</button>
                <button class="btn btn-lg btn-primary ml-0 mr-2 my-1" (click)="saveChangesAndScan()" style="margin-left: 20px; margin-right: 20px;">Save and Scan</button>
                <button class="btn btn-lg btn-secondary ml-0 mr-2 my-1" (click)="exitClicked()">Discard Changes</button>
            </div>

        </fieldset>
    </form>
</div>

import { ChartFilter } from './chart-filter';

/**
 * Represents a new Chart filter being created
 *  in the chart UI
 */
export class NewChartFilter {
    type: typeof ChartFilter;
    chartFilter: ChartFilter;
}

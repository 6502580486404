<div class="bulk-edit-wrapper">

    <bulk-edit-toolbar [logTag]="COMPONENT_LOG_TAG"
                       (exit)="exitClicked()"
                       [options]="bulkTemplates.bulkOptions"
                       [facet]="facet"
                       #bulkEditToolbar>
    </bulk-edit-toolbar>

    <div class="bulk-edit-grid">
        <bulk-edit-table [options]="bulkTemplates.bulkOptions"
                         [facet]="facet"
                         [itemsToEdit]="tasks"></bulk-edit-table>
    </div>
</div>
    
<workflow-bulk-templates [tasks]="tasks" #bulkTemplates></workflow-bulk-templates>

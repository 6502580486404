import { fromEvent, interval, merge, Observable, Subscription } from 'rxjs';
import {
    delayWhen,
    distinctUntilChanged,
    filter,
    finalize,
    map,
    startWith,
    switchMap,
    takeUntil,
    tap,
} from 'rxjs/operators';
import {
    AfterViewInit,
    Directive,
    ElementRef,
    Inject,
    NgZone,
    OnDestroy,
    QueryList,
    Self,
} from '@angular/core';
import { CLIMB_TABLE } from '../table.token';
import { CSS_VAR_GRID_TEMPLATE_COLUMNS } from '../table.const';
import type { ClimbTable, ClimbColumnDef, ColumnId } from '../table.interface';


// e.g minmax(64px, 1fr)
type CssWidth = string; // NOSONAR
type ColumnsMap = Map<ColumnId, CssWidth>;

const COLUMN_ONLY_ICON_WIDTH = 32;
const COLUMN_MIN_WIDTH = 64;

@Directive({
    selector: 'climb-table, table[climbTable]',
})
export class ColumnResizeDirective<T> implements AfterViewInit, OnDestroy {
    private columnsMap: ColumnsMap;

    private subscriptions = new Subscription();
    private resizeSubscription = new Subscription();

    private get table(): HTMLElement {
        return this.elementRef.nativeElement;
    }

    private get contentHeaderRowDefs(): string[] {
        return [...this.parent._contentHeaderRowDefs.first.columns];
    }

    constructor(
        private readonly ngZone: NgZone,
        private readonly elementRef: ElementRef,
        @Self() @Inject(CLIMB_TABLE) private readonly parent: ClimbTable<T>,
        private readonly window: Window,
    ) { }

    ngOnDestroy(): void {
        this.resizeSubscription.unsubscribe();
        this.subscriptions.unsubscribe();
    }

    ngAfterViewInit(): void {
        this.presetTableStyle();

        const checkTableWidth$: Observable<number> = interval(30).pipe(
            startWith(0),
            map(() => this.table?.clientWidth),
            filter((tableWidth: number) => tableWidth > 0),
        );
        const headerRowDefsChanged$: Observable<void> = this.parent._contentHeaderRowDefs.changes.pipe(
            startWith(this.parent._contentHeaderRowDefs),
            map((items: QueryList<{ columns: string[] }>) => items.first.columns.toString()),
            distinctUntilChanged(),
            delayWhen(() => checkTableWidth$),
            map(() => this.initResizeHandler()),
        );
        this.subscriptions.add(headerRowDefsChanged$.subscribe());
    }

    initResizeHandler(): void {
        this.ngZone.runOutsideAngular(() => {
            this.setResizeListener();
            this.initColumnSize();
            this.setTableStyle(this.columnsMap);
        });
    }

    setTableStyle(columnsMap: ColumnsMap): void {
        const gridTemplateColumns = this.contentHeaderRowDefs
            .map((columnId: string) => columnsMap.get(columnId))
            .join(' ');

        this.table.style.setProperty(CSS_VAR_GRID_TEMPLATE_COLUMNS, gridTemplateColumns);
    }

    /**
     * Keeps information about all columns of the table, unlike the presetTableStyle() method,
     * and uses it during manipulations with columns (adding, deleting, moving, etc.)
     */
    initColumnSize(): void {
        if (this.columnsMap) {
            return;
        }

        this.columnsMap = new Map();
        this.parent._contentColumnDefs.forEach((columnDef: ClimbColumnDef) => {
            const cssWidth = this.getDefaultCssWidth(columnDef);
            this.columnsMap.set(columnDef.columnId, cssWidth);
        });
    }

    setResizeListener(): void {
        const headers: Observable<HTMLElement>[] = [];
        this.table
            .querySelectorAll('.climb-header-cell:not(.header-cell-fixed)')
            .forEach((header: HTMLElement) => {
                const resizeHandle = header.querySelector('.resize-handle');
                const mousedown$: Observable<HTMLElement> = fromEvent(resizeHandle, 'mousedown').pipe(
                    tap((event: MouseEvent) => event.stopPropagation()),
                    map(() => header),
                );
                headers.push(mousedown$);
            });

        let headerOffsetLeft: number;
        const resizing$: Observable<MouseEvent> = merge(...headers).pipe(
            tap((header: HTMLElement) => header.classList.add('header-resizing')),
            tap((header: HTMLElement) => headerOffsetLeft = header.getBoundingClientRect().left),
            switchMap((header: HTMLElement) => fromEvent(this.window, 'mousemove').pipe(
                tap((event: MouseEvent) => requestAnimationFrame(() => {
                    // calculate the desired width
                    const width = Math.max(COLUMN_MIN_WIDTH, (event.clientX - headerOffsetLeft));
                    this.columnsMap.set(header.dataset.name, `${Math.round(width)}px`);

                    this.setTableStyle(this.columnsMap);
                })),
                takeUntil(fromEvent(this.window, 'mouseup')),
                finalize(() => header.classList.remove('header-resizing')),
            )),
        );

        this.resizeSubscription.unsubscribe();
        this.resizeSubscription = resizing$.subscribe();
    }

    /**
     * To avoid a broken table view, we preset styles at the very beginning of the component rendering.
     */
    private presetTableStyle(): void {
        const columnsMap: ColumnsMap = new Map();
        const columnDefs: ClimbColumnDef[] = this.parent.getVisibleColumnDefs();

        columnDefs.forEach((columnDef: ClimbColumnDef) => {
            const cssWidth = this.getDefaultCssWidth(columnDef);
            columnsMap.set(columnDef.columnId, cssWidth);
        });

        this.setTableStyle(columnsMap);
    }

    private getDefaultCssWidth(columnDef: ClimbColumnDef): CssWidth {
        return columnDef.onlyIcon
            ? `${COLUMN_ONLY_ICON_WIDTH}px`
            : `minmax(${COLUMN_MIN_WIDTH}px, 1fr)`;
    }
}

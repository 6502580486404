<ng-template #birthIdTmpl let-birth="item">
    <p class="form-control-plaintext"
        *ngIf="!(birth | isNew)">
        {{birth?.BirthID}}
    </p>
</ng-template>

<ng-template #matingIdTmpl let-birth="item">
    <p class="form-control-plaintext">
        {{birth?.Mating?.MatingID}}
    </p>
</ng-template>

<ng-template #housingIdTmpl let-birth="item">
    <p class="form-control-plaintext">
        {{birth?.Mating?.MaterialPool?.MaterialPoolID}}
    </p>
</ng-template>

<ng-template #statusTmpl let-birth="item">
    <active-vocab-select [(model)]="birth.C_BirthStatus_key"
        [vocabChoices]="birthStatuses"
        [keyFormatter]="birthStatusKeyFormatter"
        [optionFormatter]="birthStatusFormatter"
        [nullable]="true"></active-vocab-select>
</ng-template>

<ng-template #birthDateTmpl let-birth="item">
    <climb-ngb-date #dateControl="ngModel" name="Date Born" [(ngModel)]="birth.DateBorn"></climb-ngb-date>
</ng-template>

<ng-template #weanDateTmpl let-birth="item">
    <climb-ngb-date #dateControl="ngModel" name="Date Wean" [(ngModel)]="birth.DateWean"></climb-ngb-date>
</ng-template>

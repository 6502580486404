import {
    Component, OnInit,
} from '@angular/core';

import {
    NgbActiveModal
} from '@ng-bootstrap/ng-bootstrap';
import { WorkflowVocabService } from '../../services/workflow-vocab.service';
import { VocabularyService } from '../../../vocabularies/vocabulary.service';
import { notEmpty } from '@common/util';
import { LoggingService } from '@services/logging.service';

@Component({
    selector: 'animal-comments-modal',
    templateUrl: './animal-comments-modal.component.html'
})
export class AnimalCommentsModalComponent implements OnInit {
    comments = '';
    statuses: any = [];
    commentStatusKey: any = null;
    readonly MAX_COMMENTS_LENGTH = 10000;
    readonly COMPONENT_LOG_TAG = 'animal-comments-modal';

    constructor(
        private activeModal: NgbActiveModal,
        private workflowVocabService: WorkflowVocabService,
        private vocabularyService: VocabularyService,
        private loggingService: LoggingService
    ) {}

    ngOnInit() {
        this.workflowVocabService.animalCommentStatuses$.subscribe((data) => {
            this.statuses = data;
        });
        this.getDefaults();
    }

    getDefaults() {
        return Promise.all([
            this.vocabularyService.getCVDefault('cv_AnimalCommentStatuses').then((value) => {
                if (value) {
                    this.commentStatusKey = value.C_AnimalCommentStatus_key;
                }
            }),
        ]);
    }

    onCancel(): void {
        this.activeModal.close('cancel');
    }

    onAdd(): void {
        let errMsg;

        if (!notEmpty(this.comments)) {
            errMsg = "Save failed: Animal Comments can not be blank.";
        }

        if (!notEmpty(this.commentStatusKey)) {
            errMsg = "Save failed: Animal Comments require a Status.";
        }

        if (errMsg) {
            this.loggingService.logError(errMsg, "Validation Error", this.COMPONENT_LOG_TAG, true);
            return;
        }

        this.activeModal.close({comments: this.comments, commentStatusKey: this.commentStatusKey});
    }

    addButtonDisabled(): boolean {
        return this.comments.trim().length < 1 || this.comments.length > this.MAX_COMMENTS_LENGTH || !this.commentStatusKey;
    }

    commentStatusKeyFormatter = (value: any) => {
        return value.C_AnimalCommentStatus_key;
    }
    commentStatusFormatter = (value: any) => {
        return value.AnimalCommentStatus;
    }
}

import type { Job } from './job.interface';
import type { Material } from './material.interface';

export interface JobMaterial {
    C_JobMaterial_key: number;
    C_Job_key: number;
    C_Material_key: number;
    C_Workgroup_key: number;
    CreatedBy: string;
    DateCreated: Date;
    DateIn: Date | null;
    DateModified: Date;
    DateOut: Date | null;
    Job: Job;
    Material: Material;
    ModifiedBy: string;
    PrimaryCohortsSortable: string;
    Sequence: number;
    Version: number;
}

import { AuthService } from './../services/auth.service';
import { Injectable } from '@angular/core';
import {
    Router,
    CanActivate, 
    ActivatedRouteSnapshot,
    UrlTree,
} from '@angular/router';
import { RouteEnum } from './route.enum';

@Injectable()
export class AccessGuard implements CanActivate {
    constructor(
        private router: Router,
        private authService: AuthService,
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot
    ): boolean | UrlTree {
        if (!this.authService.isAuthenticated()) {
            // redirect to login
            this.authService.clearSSOData();
            return this.router.parseUrl(RouteEnum.Login);
        }
        return true;
    }
}

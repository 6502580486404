<div>
    <form class="form-label-right">
        <div class="form-group row">
            <label class="col-md-3 col-form-label" for="load-saved-search-select">Load saved search</label>
            <div class="col-md-9">
                <div class="input-group">
                    <select id="load-saved-search-select"
                            class="form-control"
                            [(ngModel)]="currUserSearch"
                            (ngModelChange)="loadSavedSearch()"
                            [ngModelOptions]="{standalone: true}">
                        <option></option>
                        <option *ngFor="let userSearch of userSearches" [ngValue]="userSearch">
                            {{ userSearch.UserSearchName }}
                        </option>
                    </select>
                    <div class="input-group-append">
                        <button (click)="deleteSavedSearch()" type="button" class="btn btn-fw-wide btn-secondary"
                            [disabled]="currUserSearch === null"
                            appInsightsClick="click-advanced-search-delete">
                            Delete
                        </button>
                    </div>
                </div>

            </div>
        </div>
        <div class="form-group row">
            <label class="col-md-3 col-form-label" for="save-search-as-input">Save search as</label>
            <div class="col-md-9">
                <div class="input-group">
                    <input
                           id="save-search-as-input"
                           type="text"
                           class="form-control"
                           placeholder="Search name"
                           [(ngModel)]="saveSearchAsName"
                           [ngModelOptions]="{standalone: true}"/>
                    <div class="input-group-append">
                        <button type="button" class="btn btn-fw-wide btn-secondary"
                            (click)="saveSearchAs()"
                            [disabled]="!saveSearchAsName"
                            appInsightsClick="click-advanced-search-save">
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </form>

    <hr />

    <ng-template #actionButtons>
        <div class="d-flex justify-content-end">
            <button type="button"
                    class="btn btn-fw-wide btn-primary ml-1"
                    (click)="performSearch()"
                    appInsightsClick="click-advanced-search-run">
                <i class="fa"
                   [ngClass]="{
                 'fa-search': !searching,
                 'fa-spinner fa-spin': searching
               }"></i>
                Search
            </button>
            <button data-automation-id="clear-filter-button"
                    type="button"
                    class="btn btn-fw-wide btn-secondary ml-1"
                    (click)="clearAllFilters()"
                    appInsightsClick="click-advanced-search-clear-filters">
                Clear filters
            </button>
        </div>
    </ng-template>

    <ng-container *ngTemplateOutlet="actionButtons"></ng-container>
    <br />
    <div class="row">
        <div class="col-md-12">
            <div id="tree-root" data-drag-enabled="false">
                <ol *ngIf="searchSpecification && searchSpecification.fields && searchSpecification.fields.length" class="tree-node-list">
                    <li *ngFor="let node of searchSpecification.fields" class="tree-node-list-item">
                        <search-node [node]="node" [expandedNode]="expandedNode"></search-node>
                    </li>
                </ol>
            </div>
        </div>
    </div>
    <br />
    <ng-container *ngTemplateOutlet="actionButtons"></ng-container>

    <hr *ngIf="searchPerformed" />

    <div>
        <div style="margin: 10px 0;">
            <ag-grid-angular
                             [hidden]="!searchPerformed"
                             style="width: 100%; height: 500px;"
                             class="climb-grid ag-theme-alpine"
                             [rowHeight]="rowHeight"
                             [headerHeight]="rowHeight"
                             [gridOptions]="gridOptions"
                             [columnDefs]="columnDefs"
                             [defaultColDef]="gridOptions.defaultColDef"
                             [rowModelType]="'infinite'"
                             [pagination]="true"
                             [paginationPageSize]="50"
                             [enableCellTextSelection]="true"
                             (sortChanged)="sortChanged()"></ag-grid-angular>
        </div>
        <a *ngIf="searchPerformed" class="btn-sm" title="Export Results" (click)='exportToCsv("SearchResults.csv"); false' href="">
            <i class="fa fa-fw fa-download" aria-hidden="true"></i>Export Results
        </a>
    </div>

</div>

import { InjectionToken } from '@angular/core';

export const COHORT_FACET_COMPONENT = new InjectionToken<any>(
  'COHORT_FACET_COMPONENT'
);
export const JOB_FACET_COMPONENT = new InjectionToken<any>(
  'JOB_FACET_COMPONENT'
);
export const JOB_PHARMA_FACET_COMPONENT = new InjectionToken<any>(
  'JOB_PHARMA_FACET_COMPONENT'
);
export const ORDER_FACET_COMPONENT = new InjectionToken<any>(
  'ORDER_FACET_COMPONENT'
);
export const RESOURCE_FACET_COMPONENT = new InjectionToken<any>(
  'RESOURCE_FACET_COMPONENT'
);
export const SCHEDULE_FACET_COMPONENT = new InjectionToken<any>(
  'SCHEDULE_FACET_COMPONENT'
);

import type { AnimalComment } from './animal-comment.interface';

export interface cv_AnimalCommentStatus {
    AnimalComment: AnimalComment;
    AnimalCommentStatus: string;
    C_AnimalCommentStatus_key: number;
    C_VocabularySource_key: number | null;
    C_Workgroup_key: number;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    ElementID: string | null;
    IsActive: boolean;
    IsDefault: boolean;
    IsDeprecated: boolean;
    IsEndState: boolean;
    IsPermanent: boolean;
    IsSystemGenerated: boolean;
    ModifiedBy: string;
    SortOrder: number | null;
    Version: number;
}

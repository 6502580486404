import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output
} from '@angular/core';

import { ChartFilterGroup } from './chart-filter-group';
import { NewChartFilter } from './new-chart-filter';

import {
    randomId
} from '../../common/util';
import { ChartFilter, ChartOptionLabelEnum, CohortIndividualAnimals } from './chart-filter';
import { ChartService } from '../chart.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'chart-filter-group-creator',
    templateUrl: './chart-filter-group-creator.component.html',
    styleUrls: ['../charts-facet.component.scss'],
    styles: [`
        .table tbody tr.group-start td {
            border-top: 1px dotted #ccc;
        }
    `],
})
export class ChartFilterGroupCreatorComponent implements OnInit, OnDestroy {
    @Input() model: ChartFilterGroup[];
    @Input() chartFilterTypes: typeof ChartFilter[];
    @Input() displayAnimalBirthDatesMessage = false;
    @Output() modelChange: EventEmitter<ChartFilterGroup[]> = new EventEmitter<ChartFilterGroup[]>();

    // State
    newFilters: NewChartFilter[];
    filterType: typeof ChartFilter;
    domIdAddition: string;
    private labelOption: ChartOptionLabelEnum;

    private readonly subs = new Subscription();

    constructor(private chartService: ChartService) {}

    ngOnInit() {
        const labelTypeSubscription = this.chartService.taskOutputLabel$.subscribe((value) => {
            this.labelOption = value;
            this.refreshChartLabel(value);
        });
        this.subs.add(labelTypeSubscription);
        this.domIdAddition = randomId();

        if (!this.model) {
            this.model = [];
        }

        if (!this.filterType) {
            // Don't show empty option
            this.filterType = this.chartFilterTypes[0];
        }

        this.reset();
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    private reset() {
        this.newFilters = [this.makeNewChartFilter()];
    }

    addFilterClicked() {
        this.newFilters.push(this.makeNewChartFilter());
    }

    removeNewFilterClicked(newFilter: NewChartFilter) {
        const index = this.newFilters.indexOf(newFilter);
        if (index >= 0) {
            this.newFilters.splice(index, 1);
        }
    }

    private makeNewChartFilter(): NewChartFilter {
        const type = this.chartFilterTypes[0];
        return {
            type,
            chartFilter: new type(),
        };
    }

    addGroupClicked() {
        if (this.newFilters.length === 0) {
            return;
        }

        const chartFilters: ChartFilter[] = this.getValidChartFilters();
        chartFilters.forEach(filter => filter.labelType = this.labelOption);
        // Stop if no valid ChartFilters
        if (chartFilters.length === 0) {
            return;
        }

        const label = this.getChartFilterGroupLabel(chartFilters);
        const chartFilterGroup: ChartFilterGroup = {
            filters: chartFilters,
            label
        };

        this.model.push(chartFilterGroup);
        this.refreshModel();

        this.reset();
    }

    refreshChartLabel(value: ChartOptionLabelEnum) {
        this.model.forEach((filter) => {
            filter.filters.forEach(item => item.labelType = value);
            filter.label = this.getChartFilterGroupLabel(filter.filters);
        });
        this.refreshModel();
    }

    private getChartFilterGroupLabel(chartFilters: ChartFilter[]): string {
        const DELIMITER = ' & ';
        return chartFilters
            .map((chartFilter) => chartFilter.displayLabel)
            .filter(Boolean)
            .join(DELIMITER);
    }

    private getValidChartFilters(): ChartFilter[] {
        const validNewFilters = this.newFilters.filter((newFilter) => {
            return newFilter.chartFilter &&
                newFilter.chartFilter.displayValue !== '';
        });

        return validNewFilters.map((filter) => {
            return filter.chartFilter;
        });
    }

    selectFilterType(filter: NewChartFilter, filterType: typeof ChartFilter) {
        filter.chartFilter = new filterType();
    }

    removeFilterGroup(index: number): void {
        if (this.model && this.model.length > index) {
            this.model.splice(index, 1);
            this.refreshModel();
        }
    }

    refreshModel(): void {
        // Reassign array to itself to trigger OnChanges in client components.
        this.model = this.model.slice();

        this.modelChange.emit(this.model);
    }

    containsCohortIndividualAnimalsFilter(filterGroup: ChartFilterGroup): boolean {
        return filterGroup.filters.find((chartFilter: ChartFilter) => chartFilter instanceof CohortIndividualAnimals) !== undefined;
    }
}

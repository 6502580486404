import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import { NgForm } from '@angular/forms';

import { ResourceService } from './resource.service';
import { ResourceVocabService } from './resource-vocab.service';

import {
    BaseDetail,
    BaseDetailService,
    FacetView,
    IFacet,
    PageState
} from '../common/facet';
import { getSafeProp } from '../common/util';
import { SettingService } from '../settings/setting.service';
import { IValidatable, SaveChangesService } from '../services/save-changes.service';
import { ScrollTypes } from '@common/export';
import { map } from 'rxjs/operators';
import { forkJoin } from 'rxjs';

@Component({
    selector: 'resource-detail',
    templateUrl: './resource-detail.component.html'
})
export class ResourceDetailComponent extends BaseDetail
    implements OnInit, OnChanges, OnDestroy, IValidatable {
    @Input() facet: IFacet;
    @Input() facetView: FacetView;
    @Input() resource: any;
    @Input() pageState: PageState;

    @Output() exit: EventEmitter<void> = new EventEmitter<void>();
    @Output() next: EventEmitter<void> = new EventEmitter<void>();
    @Output() previous: EventEmitter<void> = new EventEmitter<void>();

    @ViewChild("resourceForm") resourceForm: NgForm;

    // CVs
    resourceTypes: any[] = [];
    resourceGroups: any[] = [];
    users: any[] = [];

    // Required fields set by facet settings
    requiredFields: string[] = [];

    readonly COMPONENT_LOG_TAG = 'resource-detail';
    public scrollTypes = ScrollTypes;
    constructor(
        baseDetailService: BaseDetailService,
        private resourceService: ResourceService,
        private resourceVocabService: ResourceVocabService,
        private settingService: SettingService,
        private saveChangesService: SaveChangesService
    ) {
        super(baseDetailService);
    }

    // lifecycle
    ngOnInit() {
        this.saveChangesService.registerValidator(this);
        this.initialize();
    }

    ngOnDestroy() {
        this.saveChangesService.unregisterValidator(this);
    }

    ngOnChanges(changes: any) {
        if (changes.resource) {
            if (this.resource && !changes.resource.firstChange) {
                if (this.resourceForm) {
                    this.resourceForm.form.markAsPristine();
                }
                this.initialize();
            }
        }
    }

    initialize() {
        return this.settingService.getFacetSettingsByType('resource').then((facetSettings) => {
            this.requiredFields = this.settingService.getRequiredFields(facetSettings);
        }).then(() => {
            return this.getCVs();
        }).then(() => {
            return this.getDetails();
        });
    }

    private getCVs(): Promise<any> {
        return forkJoin([
            this.resourceVocabService.resourceTypes$.pipe(map(data => {
                this.resourceTypes = data;
                return data;
            })),
            this.resourceVocabService.users$.pipe(map(data => {
                this.users = data;
                return data;
            })),
            this.resourceVocabService.resourceGroups$.pipe(map(data => {
                this.resourceGroups = data;
                return data;
            }))
        ]).toPromise();
    }

    private getDetails(): Promise<any> {
        if (this.resource && this.resource.C_Resource_key > 0) {
            this.setLoading(true);

            const expands: string[] = [];

            return this.resourceService.getResource(
                this.resource.C_Resource_key,
                expands
            ).then(() => {
                this.setLoading(false);
            }).catch((error: any) => {
                this.setLoading(false);
                console.error(error);
            });
        }
        return Promise.resolve(this.resource);
    }

    onCancel() {
        this.resourceService.cancelResource(this.resource);
    }

    async validate(): Promise<string> {
        let errMsg: string;

        if (!this.resource.ResourceName) {
            errMsg = 'Resource requires a name.';
        }

        if (!errMsg) {
            errMsg = await this.settingService.validateRequiredFields(this.requiredFields, this.resource, 'resource');
        }

        return errMsg;
    }

    onUserChange() {
        let newEmail = null;
        let newUserName = null;
        if (this.resource.UserID) {
            const user = this.users.find((item) => {
                return item.C_User_key === this.resource.UserID;
            });
            newEmail = this.getUserEmail(user);
            newUserName = user && user.User ? user.User.UserName : null;
        }


        this.resource.EmailAddress = newEmail;
        this.resource.UserName = newUserName;
    }

    getUserEmail(user: any): string {
        if (!user) {
            return null;
        }

        const emails: any[] = getSafeProp(user, 'User.UserContactEmailAddresses');
        if (!emails) {
            return null;
        }

        const primaryEmail = emails.find((item) => {
            return item.IsPrimaryEmailAddress;
        });

        if (!primaryEmail) {
            return null;
        }

        return primaryEmail.EmailAddress;
    }

    // <select> formatters
    resourceGroupKeyFormatter = (value: any) => {
        return value.C_ResourceGroup_key;
    }
    resourceGroupFormatter = (value: any) => {
        return value.ResourceGroup;
    }
}

import { BulkEditCommService } from './../facet/bulk-edit-comm.service';
import { DataContextService } from './../../services/data-context.service';
import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    OnDestroy
} from '@angular/core';

import {
    BulkEditField,
    BulkEditOptions,
} from '../../common/facet/models';
import { BulkEditSharedLogic } from '../../common/facet/bulk-edit.shared';

import {
    SaveChangesService
} from '../../services/save-changes.service';
import { WorkspaceService } from '../../workspaces/workspace.service';
import { ColumnSelectLabel } from '../facet/components/toolbar-column-select/toolbar-column-select.component';
import { Subscription } from 'rxjs';
import { facetsForFacetLevelSave } from '../facet/constants/facet-level-save.const';
import { distinctUntilChanged, map } from 'rxjs/operators';

@Component({
    selector: 'bulk-edit-toolbar',
    templateUrl: './bulk-edit-toolbar.component.html',
    styleUrls: ['./bulk-edit-toolbar.component.scss'],
})
export class BulkEditToolbarComponent implements OnInit, OnChanges, OnDestroy {
    @Input() logTag: string;
    @Input() options: BulkEditOptions;
    @Input() facet: any;
    @Input() inactiveFields: string[];

    @Output() exit: EventEmitter<void> = new EventEmitter<void>();
    @Output() save: EventEmitter<void> = new EventEmitter<void>();

    sharedLogic: BulkEditSharedLogic;
    saveChangesService: SaveChangesService;
    hasChanges = false;

    // Column Select state
    columnSelect: {
        // Selected columns
        model: string[],
        // Labels
        labels: ColumnSelectLabel[]
    } = { model: [], labels: [] };

    private _subscriptions: Subscription[] = [];

    constructor(
        private bulkEditCommService: BulkEditCommService,
        saveChangesService: SaveChangesService,
        private dataContext: DataContextService,
        private workspaceService: WorkspaceService
    ) {
        this.saveChangesService = saveChangesService;
        this.sharedLogic = new BulkEditSharedLogic();
    }

    ngOnInit() {
        // init hasChanges
        this.hasChanges = this.dataContext.hasChanges();

        const isFacetLevelSaveSupported = facetsForFacetLevelSave.some(facet => facet === this.logTag);
        let s1: Subscription;
        if (isFacetLevelSaveSupported) {
            s1 = this.dataContext.entityChanges$.pipe(
                map(() => this.options.hasFacetChanges()),
                distinctUntilChanged(),
            ).subscribe((hasChanges: boolean) => {
                this.hasChanges = hasChanges;
            });
        }
        else {
            s1 = this.dataContext.checkChanges$.subscribe((hasChanges: boolean) => {
                this.hasChanges = hasChanges;
            });
        }

        const s2 = this.bulkEditCommService.optionsUpdated$.subscribe(() => {
             // Create the column selection labels
             this.updateColumnSelectLabels();

             // Figure out which columns are visible
             this.columnSelect.model = this.findVisibleColumns();
             this.applyColumnSelect();
        });

        this._subscriptions = [s1, s2];
    }

    ngOnChanges(changes: any) {
        if (changes.options) {
            // Create the column selection labels
            this.updateColumnSelectLabels();

            // Figure out which columns are visible
            this.columnSelect.model = this.findVisibleColumns();
            this.applyColumnSelect();
        }
    }

    ngOnDestroy() {
        for (const subscription of this._subscriptions) {
            subscription.unsubscribe();
        }
    }

    /**
     * Generate the column labels from the Bulk Edit fields
     */
    updateColumnSelectLabels() {
        if (this.options && this.options.fields) {
            let fields = this.options.fields;
            if (this.inactiveFields) {
                fields = this.options.fields.filter((field: BulkEditField) => {
                    return !this.inactiveFields.includes(field.label);
                });
            }
            const filteredFields = fields.filter((field: BulkEditField) => !field.inactive && !field.hideFromEditScreen);
            this.columnSelect.labels = filteredFields.map((field: any) => {
                return new ColumnSelectLabel(field.modelPath, field.label);
            });
        } else {
            this.columnSelect.labels = [];
        }
        this.applyColumnSelect();
    }

    /**
     * Determine which columns are visible
     */
    findVisibleColumns() {
        if (!this.options || !this.options.fields) {
            return [];
        }

        return this.options.fields.filter((field) => {
            return (field.visible !== false);
        }).map((field) => {
            return field.modelPath;
        });
    }

    /**
     * Column selection has changed.
     */
    columnSelectChanged(current: string[]) {
        // Get the current selections
        this.columnSelect.model = current;

        // Update the field visibilty
        this.options.fields.forEach((field) => {
            field.visible = (this.columnSelect.model.indexOf(field.modelPath) > -1);
        });

        // Rebuild the BulkEditConfiguration JSON
        this.sharedLogic.updateConfig(this.options, this.facet);

        // Save just the BulkEditConfiguration value in the facet
        this.workspaceService.saveBulkEditConfiguration(this.facet);
    }

    async saveClicked(): Promise<void> {
        if (!this.canSave()) {
            return;
        }

        const isFacetLevelSaveSupported = facetsForFacetLevelSave.some(facet => facet === this.logTag);
        if (isFacetLevelSaveSupported === false) {
            await this.saveChangesService.saveChanges(this.logTag);
        }
        this.save.emit();
    }

    exitClicked(): void {
        if (this.isValidToSave()) {
            const isFacetLevelSaveSupported = facetsForFacetLevelSave.some(facet => facet === this.logTag);
            if (isFacetLevelSaveSupported) {
                this.exit.emit(); // saving or discard operations are executed in corresponding bulk edit component for the facet. 
            } else {
                // execute global save or discard. TODO: remove this code when all facet are switched to facet-level saving approach
                this.saveChangesService.promptForUnsavedChanges(this.logTag).then(() => {
                    this.exit.emit();
                });
            }
        } else {
            this.exit.emit();
        }
    }

    canSave(): boolean {
        return !this.saveChangesService.saving && this.isValidToSave();
    }

    isValidToSave(): boolean {
        if (this.options && this.options.saveButtonValidators) {
            const validationResults = this.options.saveButtonValidators.map((x: () => boolean) => x());
            return !validationResults.some((x: boolean) => x === false);
        }

        return true;
    }

    private applyColumnSelect() {
        // recreate columnSelect to apply changes to the climb-column-select component
        this.columnSelect = {
            model: this.columnSelect.model,
            labels: this.columnSelect.labels,
        };
    }
}

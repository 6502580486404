import { Directive, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { deepClone } from '@datadog/browser-core';

/**
 * Helpers for facet filter modals
 */
@Directive()
export class BaseFilter {  // eslint-disable-line @angular-eslint/directive-class-suffix
    filter: Record<string, any>;
    onFilter = new EventEmitter<Record<string, any>>();
    close = new EventEmitter<Record<string, any>>();

    private _activeModal: NgbActiveModal;

    constructor(activeModal: NgbActiveModal) {
        this._activeModal = activeModal;
    }

    /**
     * Returns a copy of the passed in filter object
     * @param filter
     */
    copyFilter(filter: Record<string, any>) {
        if (!filter) {
            return {};
        } else {
            // shallow copy passed in filters
            //  so we don't modify original data
            return deepClone(filter);
        }
    }

    closeClicked() {
        this.close.emit();
        this._activeModal.close("close");
    }

    clearClicked() {
        this.filter = {};
        this.onFilter.emit(this.filter);
        this.close.emit();
        this._activeModal.close("clear");
    }

    okClicked(event: Event) {
        event.preventDefault();

        this.onFilter.emit(this.filter);
        this.close.emit();
        this._activeModal.close("filter");
    }
}

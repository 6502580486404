import { Predicate } from 'breeze-client';

/**
 * Creates Breeze Predicate for Boolean fields.
 * @param value the Boolean value
 */
export function getBooleanPredicate(
    value: boolean | string,
    property: string
): Predicate {
    let operator: string;
    if (value !== true && value !== 'true') {
        operator = '!=';
    } else {
        operator = '==';
    }

    return Predicate.create(property, operator, true);
}

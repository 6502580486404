<table class="table" *ngIf="file">
    <tr>
        <td class="icon-cell border-top-0">
            <a (click)="removeFile()" *ngIf="facetPrivilege!=='ReadOnly' && !readonly">
                <i class="fa fa-times remove-item" title="Delete file"></i>
            </a>
        </td>
        <td class="border-top-0">
            {{file.name}}
        </td>
        <td class="text-right border-top-0">
            {{file.dateCreated | formatShortDateTime}}
        </td>
    </tr>
</table>

<div *ngIf="!readonly">
    <div>
        <climb-dropzone data-automation-id="add-file"
                        [url]="uploadApiUrl"
                        [disallowMultiple]="true"
                        (success)="addFile($event)"
                        (removedfile)="dropzoneRemovedFile()"></climb-dropzone>
    </div>
</div>

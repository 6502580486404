import { Component, ChangeDetectionStrategy, OnInit, Injector } from '@angular/core';
import { magnifier, plus, plusCircleFilled } from '@icons';
import { DashboardDataService } from '../../services/dashboard-data.service';
import { FormControl } from '@angular/forms';
import { Observable, combineLatest, concat, of } from 'rxjs';
import { MixinUnsubscribeClass } from '@common/mixins';
import { map, tap } from 'rxjs/operators';
import { Workspace } from "@common/types/models/workspace.interface";

@Component({
    selector: 'climb-workspace',
    templateUrl: './workspace.component.html',
    styleUrls: ['./workspace.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WorkspaceComponent extends MixinUnsubscribeClass implements OnInit {
    icons = { magnifier, plus, plusCircleFilled };
    
    workspaces$ = this.dashboardDataService.workspaces$;
    workspacesAmount$ = this.dashboardDataService.workspaces$.pipe(map(workspaces => workspaces?.length));
    loading$ = this.dashboardDataService.workspacesLoading$;

    searchControl = new FormControl('');
    searchValue$ = new Observable<string>();

    constructor(
        private dashboardDataService: DashboardDataService,
        injector: Injector,
    ) {
        super(injector);
    }

    ngOnInit(): void {
        this.dashboardDataService.uploadWorkspaces();

        this.searchValue$ = concat(of(this.searchControl.value), this.searchControl.valueChanges);
        this.workspaces$ = combineLatest([this.dashboardDataService.workspaces$, this.searchValue$])
            .pipe(
                map(([workspaces, search]) => workspaces
                    .filter(workspace => workspace.WorkspaceName?.toLowerCase().includes(search?.toLowerCase() ?? ''))
                    .sort(this.workspacesSort)
                ),
            );
    }

    openWorkspace(workspace: any) {
        this.dashboardDataService.openWorkspace(workspace);
    }

    addWorkspace() {
        this.dashboardDataService.addWorkspace();
    }

    private workspacesSort(workspaceA: Workspace, workspaceB: Workspace) {
        if (workspaceA.IsFavourite && workspaceB.IsFavourite || !workspaceA.IsFavourite && !workspaceA.IsFavourite) {
            return workspaceA.WorkspaceName.toLowerCase() > workspaceB.WorkspaceName.toLowerCase() ? 1 : -1;
        }
        if (workspaceA.IsFavourite) {
            return -1;
        }
        if (workspaceB.IsFavourite) {
            return 1;
        }
        return 0;
    }
}

import { Injectable } from '@angular/core';

import {
    EntityQuery,
    Predicate,
} from 'breeze-client';

import { AdminManagerService } from '../services/admin-manager.service';
import { BaseEntityService } from '../services/base-entity.service';
import { AuthService } from '../services/auth.service';
import { CurrentWorkgroupService } from '../services/current-workgroup.service';
import { DataManagerService } from '../services/data-manager.service';
import { LatestKnownUserData } from '../common/types/models';
import { WebApiService } from '../services/web-api.service';

/**
 * Service for User entities
 */
@Injectable()
export class UserService extends BaseEntityService {

    constructor(
        private adminManager: AdminManagerService,
        private authService: AuthService,
        private currentWorkgroupService: CurrentWorkgroupService,
        private dataManager: DataManagerService,
        private webApiService: WebApiService
    ) {
        super();
    }

    getStudies(): Promise<any[]> {
        const query = EntityQuery.from('Studies');

        return this.dataManager.returnQueryResults(query);
    }

    getStudyAdministratorStudies(): Promise<any[]> {
        const query = EntityQuery.from('StudyAdministratorStudies');

        return this.dataManager.returnQueryResults(query);
    }

    getUserName(): string {
        return this.authService.getCurrentUserName();
    }

    getThisUser(): Promise<any> {
        const query = EntityQuery.from('Users')
            .expand("UserContactEmailAddresses")
            .where("Id", "==", this.authService.getCurrentUserId());

        return this.adminManager.returnSingleQueryResult(query);
    }

    getThisUserProfile(): Promise<any> {
        const expands = [
            'UserContactPhoneNumbers',
            'UserContactEmailAddresses'
        ];
        const query = EntityQuery.from('Users')
            .expand(expands.join(','))
            .where("Id", "==", this.authService.getCurrentUserId());

        return this.adminManager.returnSingleQueryResult(query);
    }

    getThisWorkgroupUser(): Promise<any> {
        return this.getWorkgroupUserByUserId(this.authService.getCurrentUserId());
    }

    getWorkgroupUsers(): Promise<any[]> {
        const workgroupPred: Predicate = this._getWorkgroupPredicate();
        const query = EntityQuery.from('WorkgroupUsers')
            .expand("User.UserContactEmailAddresses")
            .where(workgroupPred)
            .orderBy("User.FirstName, User.LastName");

        return this.adminManager.returnQueryResults(query, true);
    }

    getWorkgroupUsersList(): Promise<any> {
        const workgroupPred: Predicate = this._getWorkgroupPredicate();
        const query = EntityQuery.from('WorkgroupUsers')
            .select("User.FirstName, User.LastName, User.Id")
            .expand("User")
            .where(workgroupPred)
            .orderBy("User.FirstName, User.LastName");

        return this.adminManager.returnQueryResults(query).then((results) => {
            return results.map((item) => {
                return {
                    text: item.User.FirstName + ' ' + item.User.LastName,
                    value: item.User.Id
                };
            });
        });
    }

    getWorkgroupUserByUserName(userName: string): Promise<any> {
        const p1 = this._getWorkgroupPredicate();
        const p2 = Predicate.create(
            'User.UserName',
            '==',
            userName
        );

        const query = EntityQuery.from('WorkgroupUsers')
            .expand('User')
            .where(Predicate.and([p1, p2]));

        return this.adminManager.returnSingleQueryResult(query);
    }

    getWorkgroupUserByUserId(userId: string): Promise<any> {
        const p1 = this._getWorkgroupPredicate();
        const p2 = Predicate.create(
            'User.Id',
            '==',
            userId
        );

        const query = EntityQuery.from('WorkgroupUsers')
            .expand('User')
            .where(Predicate.and([p1, p2]));

        return this.adminManager.returnSingleQueryResult(query);
    }

    getUserWorkgroups(): Promise<any[]> {
        const query = EntityQuery.from('WorkgroupUsers')
            .select(["Workgroup.C_Workgroup_key", "Workgroup.WorkgroupName"])
            .expand("Workgroup")
            .where("C_User_key", "==", this.authService.getCurrentUserId())
            .orderBy("Workgroup.WorkgroupName");

        return this.adminManager.executeQuery(query).then((queryResult) => {
            return queryResult.results.map((item: any) => {
                return item.Workgroup;
            });
        }).catch(this.adminManager.queryFailed) as Promise<any[]>;
    }

    private _getWorkgroupPredicate(): Predicate {
        return Predicate.create(
            'C_Workgroup_key',
            '==',
            this.currentWorkgroupService.getCurrentWorkgroupKey()
        );
    }

    getUserContactKey(): Promise<number> {
        const predicates = [
            Predicate.create('UserName', '==', this.authService.getCurrentUserName()),
            this._getWorkgroupPredicate()
        ];
        const query = EntityQuery.from('UserContacts')
            .where(Predicate.and(predicates));

        return this.adminManager.returnSingleQueryResult(query).then((contact) => {
            return contact ? contact.C_UserContact_key : null;
        });
    }

    createUserContactEmailAddress(initialValues: any) {
        return this.adminManager.createEntity('UserContactEmailAddress', initialValues);
    }

    createUserContactPhoneNumber(initialValues: any) {
        return this.adminManager.createEntity('UserContactPhoneNumber', initialValues);
    }

    createStudyAdministratorStudy(initialValues: any): any {
        return this.dataManager.createEntity('StudyAdministratorStudy', initialValues);
    }

    deleteStudyAdministratorStudy(studyAdministratorStudy: any) {
        this.dataManager.deleteEntity(studyAdministratorStudy);
    }

    getLatestKnownUserDatas(): Promise<LatestKnownUserData[]> {
        const workgroupKey = this.currentWorkgroupService.getCurrentWorkgroupKey();
        const apiUrl = `api/userdatas/latestknown?workgroupKey=${workgroupKey}`;

        return this.webApiService.callGetAdminApi(apiUrl)
            .then(x => x.data);
    }
}

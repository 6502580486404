import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { DroppableEvent } from './droppable-event';

/**
 * Used internally by droppable.directive and children only
 */
@Injectable()
export class DroppableCommService {

    private onDropSource = new Subject<DroppableEvent>();
    onDrop$ = this.onDropSource.asObservable();

    onDrop(event: DroppableEvent) {
        this.onDropSource.next(event);
    }
}

import type { StoredFileMap } from './stored-file-map.interface';

export interface ExportFileRequest {
    C_ExportFileRequest_key: number;
    C_Workgroup_key: number;
    CreatedBy: string;
    DateCreated: Date | null;
    DateModified: Date | null;
    DefaultFileName: string;
    Message: string;
    ModifiedBy: string;
    SqlStatement: string;
    Status: RequestStatus;
    StoredFileMap: StoredFileMap[];
    TimeProcessedEnd: Date | null;
    TimeProcessedStart: Date | null;
    UserName: string;
    Version: number;
}

export enum RequestStatus {
    Completed = 'Completed',
    Failed = 'Failed',
    Processing = 'Processing',
    Requested = 'Requested',
}

export function isExportInProgress(exportRequest: ExportFileRequest) {
    return exportRequest.Status === RequestStatus.Requested || exportRequest.Status === RequestStatus.Processing;
}

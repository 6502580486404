import type { Allele } from './allele.interface';
import type { Marker } from './marker.interface';

export interface cv_AlleleClass {
    Allele: Allele[];
    AlleleClass: string;
    C_AlleleClass_key: number;
    C_VocabularySource_key: number | null;
    C_Workgroup_key: number;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    Description: string;
    ElementID: string;
    IsActive: boolean;
    IsDefault: boolean;
    IsDeprecated: boolean;
    Marker: Marker[];
    ModifiedBy: string;
    SortOrder: number | null;
    Version: number;
}

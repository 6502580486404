import { JobsSharedModule } from './../jobs/jobs-shared.module';
import { FacetSharedModule } from './../common/facet/facet-shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { DragAndDropModule } from 'angular-draggable-droppable';

import { ClimbCommonModule } from '../common/climb-common.module';
import { ClimbServicesModule } from '../services/climb-services.module';
import { WorkspacesSharedModule } from '../workspaces/workspaces-shared.module';

import { ResourcesSharedModule } from '../resources/resources-shared.module';
import { VocabulariesSharedModule } from '../vocabularies/vocabularies-shared.module';
import { WorkflowsSharedModule } from '../workflow/workflows-shared.module';
import { ReserveTimeModalComponent } from './details/reserve-time-modal.component';
import { ScheduleEditModalComponent } from './details/schedule-edit-modal.component';
import { ScheduleModalService } from './details/schedule-modal.service';
import { ScheduleDetailComponent } from './schedule-detail.component';
import { TasksSharedModule } from '../tasks/tasks-shared.module';
import { ScheduleHelperService } from './schedule-helper.service';
import { UsersSharedModule } from './../user/users-shared.module';
import { ScheduleSharedModule } from './schedule-shared.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgbModule,
        ScheduleSharedModule,
        DragAndDropModule,
        ClimbCommonModule,
        ClimbServicesModule,
        JobsSharedModule,
        FacetSharedModule,
        ResourcesSharedModule,
        TasksSharedModule,
        WorkspacesSharedModule,
        WorkflowsSharedModule,
        VocabulariesSharedModule,
        UsersSharedModule
    ],
    declarations: [
        ScheduleDetailComponent,
        ReserveTimeModalComponent,
        ScheduleEditModalComponent
    ],
    exports: [
        ReserveTimeModalComponent,
        ScheduleEditModalComponent
    ],
    providers: [
        ScheduleHelperService,
        ScheduleModalService
    ]
})
export class ScheduleModalsModule { }

import { Component, OnInit, ChangeDetectionStrategy, Injector, ElementRef, ChangeDetectorRef, SkipSelf } from '@angular/core';
import { ButtonComponent } from '@common/components/button/button.component';
import { plusCircleFilled } from '@icons';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'button[climbAddButton]',
    templateUrl: './toolbar-add-button.component.html',
    styleUrls: ['./toolbar-add-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToolbarAddButtonComponent extends ButtonComponent implements OnInit {
    readonly icons = { plusCircleFilled };

    constructor(
        injector: Injector,
        elementRef: ElementRef,
        @SkipSelf() cdr: ChangeDetectorRef,
    ) {
        super(injector, elementRef, cdr);
    }

    ngOnInit(): void {
        super.ngOnInit();
        super.variant = 'ghost';
        super.size = 'sm';
    }
}

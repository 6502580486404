<div class="bulk-edit-wrapper">
    <div class="bulk-edit-grid">
        <bulk-add #bulkAdd
                  [options]="bulkTemplates.bulkOptions"
                  (save)="saveClicked($event)"
                  (exit)="exitClicked($event)">
        </bulk-add>
    </div>
</div>

<sample-bulk-templates #bulkTemplates
    [samples]="[]"
    [addLineDisabled]="sourceMaterials | notEmpty"
    [requiredFields]="requiredFields"
    [activeFields]="activeFields"
    ></sample-bulk-templates>

<ng-template #itemsToAddTmpl
    let-state="state"
    let-itemTypeLabel="itemTypeLabel">
    <div class="row">
        <div class="form-group col-md-6">
            <h5>Add Standalone Samples</h5>
            <label class="col-form-label text-nowrap number-items-to-add-label">
                Number of New {{itemTypeLabel}} to Add
                <input class="number-items-to-add"
                    type="number"
                    name="numberItemsToAdd"
                    min=1
                    max=50
                    [(ngModel)]="state.numberItemsToAdd"
                    (blur)="state.limitNumberItemsToAdd()"
                    [disabled]="sourceMaterials | notEmpty" />
            </label>
            <div class="col-md-12" *ngIf="sourceMaterials | notEmpty">
                <small class="form-text text-muted">
                </small>
            </div>
        </div>
        <ng-container *ngIf="activeFields.includes('Material.MaterialSourceMaterial[0]')">
            <div class="col-md-1">
                <h5>or</h5>
            </div>
            <div class="col-md-5"
                 droppable
                 (drop)="onDropSourceMaterials($event)"
                 title="Drop source animals or samples here">
                <h5>
                    Add Samples from Source
                    <info-tooltip [text]="'Drop or paste source animals or samples to create each sample'"></info-tooltip>
                </h5>
                <div class="dropzone">
                    <material-multiselect [model]="sourceMaterials"
                                          (selectMaterial)="selectSourceMaterial($event)"
                                          (removeMaterial)="removeSourceMaterial($event)"
                                          [required]="requiredFields.includes('Material.MaterialSourceMaterial[0]') && sourceMaterials.length === 0">
                        <button extra-buttons
                                (click)="pasteSourceMaterials()"
                                class="btn btn-secondary">
                            <i class="fa fa-paste" title="Paste source animals or samples"></i>
                        </button>
                    </material-multiselect>
                </div>
            </div>
        </ng-container>
   
    </div>
</ng-template>

import { HttpParams } from '@angular/common/http';
import { ApiResponse } from './../services/models/api-response';
import { Injectable } from '@angular/core';

import { BaseEntityService } from '../services/base-entity.service';
import { WebApiService } from '../services/web-api.service';
import { BehaviorSubject } from 'rxjs';
import { ChartOptionLabelEnum } from './filters/chart-filter';

@Injectable()
export class ChartService extends BaseEntityService {
    readonly TASK_OUTPUT_LABELS = [
        ChartOptionLabelEnum.NAME,
        ChartOptionLabelEnum.MICROCHIP_ID
    ];

    private taskOutputLabel$$ = new BehaviorSubject<ChartOptionLabelEnum>(ChartOptionLabelEnum.NAME);
    taskOutputLabel$ = this.taskOutputLabel$$.asObservable();

    constructor(private webApiService: WebApiService) {
        super();
    }

    getGenotypeDistribution(lineKey: number): Promise<ApiResponse> {
        const apiUrl = 'api/charts/genotypeDistribution/' + lineKey;

        return this.webApiService.callApi(apiUrl);
    }

    /*
    * Gets the data for a survival code using a filter:
    *
    * filter['genotypeAssayKeys'] = <string of comma-delimited keys>
    * filter['genotypeSymbolKeys'] = <string of comma-delimited keys>
    * filter['jobKeys'] = <string of comma-delimited keys>
    * filter['lineKeys'] = <string of comma-delimited keys>
    * filter['workflowTaskKeys'] = <string of comma-delimited keys>
    * filter['interval'] = <'Days' | 'Weeks' | 'Months'>
    * filter['maxRange'] = <int>
    */
    getSurvivalCurve(filter: any): Promise<ApiResponse> {
        const apiUrl = 'api/charts/survivalCurve';
        const params: HttpParams = this.webApiService.buildURLSearchParams(filter);

        return this.webApiService.callApi(apiUrl, params);
    }

    /**
     * Gets a time series of task output values using a filter:
     *
     * filter['cohortKeys'] = <string of comma-delimited keys>
     * filter['jobKeys'] = <string of comma-delimited keys>
     * filter['lineKeys'] = <string of comma-delimited keys>
     * filter['materialKeys'] = <string of comma-delimited keys>
     * filter['sexKeys'] = <string of comma-delimited keys>
     * filter['taskOutputKeys'] = <string of comma-delimited keys>
     * filter['interval'] = <'Days' | 'Weeks' | 'Months'>
     */
    getTaskOutputTimeSeries(filter: any): Promise<ApiResponse> {
        const apiUrl = 'api/charts/taskOutput';
        const params: HttpParams = this.webApiService.buildURLSearchParams(filter);

        return this.webApiService.callApi(apiUrl, params);
    }

    /*
    * Gets the data for breeding efficiency using a filter:
    *
    * filter['startDate'] = <ISO date string> (optional)
    * filter['endDate'] = <ISO date string> (optional)
    */
    getBreedingEfficiencyByLine(filter: any): Promise<ApiResponse> {
        const apiUrl = 'api/breeding/BreedingEfficiencyByLine';
        const params: HttpParams = this.webApiService.buildURLSearchParams(filter);

        return this.webApiService.callApi(apiUrl, params);
    }

    getTaskOutputValuesByName(filter: any): Promise<ApiResponse> {
        const apiUrl = 'api/charts/taskNameOutput';
        const params: HttpParams = this.webApiService.buildURLSearchParams(filter);

        return this.webApiService.callApi(apiUrl, params);
    }

    selectTaskOutputLabel(label: ChartOptionLabelEnum) {
        this.taskOutputLabel$$.next(label);
    }
}

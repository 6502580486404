import { Injectable } from '@angular/core';
import {
    EntityQuery,
    Predicate,
} from 'breeze-client';

import { DataManagerService } from '../services/data-manager.service';
import { BaseEntityService } from '../services/base-entity.service';

import '../common/observable/cache-with-expiration';

@Injectable()
export class EnumerationService extends BaseEntityService {

    // 5 minutes
    readonly DEFAULT_CACHE_EXPIRATION = 300000;
    // map for enumeration item observables to cache data
    itemObservableMap: Record<string, unknown> = {};

    constructor(
        private dataManager: DataManagerService
    ) {
        super();
    }


    createClass(): any {
        const entityName = 'EnumerationClass';
        return this.dataManager.createEntity(entityName);
    }

    createItem(initialValues: any): any {
        const entityName = 'EnumerationItem';
        return this.dataManager.createEntity(entityName, initialValues);
    }

    async getEnumerationClasses(): Promise<any[]> {
        const expands = [
            'EnumerationItem',
            'Input',
            'Output',
            'TaxonCharacteristic',
            'SampleCharacteristic',
            'JobCharacteristic',
            'MatingCharacteristic',
            'StudyCharacteristic'
        ];

        const query = EntityQuery.from('EnumerationClasses')
            .expand(expands.join(','))
            .orderBy('ClassName');

        return this.dataManager.returnQueryResults(query);
    }
    
    async getEnumerationItems(classKey: number): Promise<any[]> {
        const pred = new Predicate('C_EnumerationClass_key', '==', classKey);

        const query = EntityQuery.from('EnumerationItems')
            .where(pred)
            .select('C_EnumerationItem_key, C_EnumerationClass_key, ItemName')
            .orderBy('ItemName');

        return this.dataManager.getQueryResults(query);
    }

    async getStandardEnumerationItems(className: string): Promise<any> {
        const pred1 = new Predicate('ClassName', '==', className);
        const pred2 = new Predicate('ReadOnly', '==', true);

        const query = EntityQuery.from('EnumerationClasses')
            .where(pred1.and(pred2))
            .expand('EnumerationItem')
            .orderBy('ClassName');

        return this.dataManager.returnSingleQueryResult(query);
    }

    /**
     * Attach the "EnumerationItems" property to each
     *   taskInput, by loading EnumerationItems for each
     * @param taskInputs 
     */
    async attachInputEnumerations(taskInputs: any[]): Promise<any[]> {
        const promises: any[] = [];

        for (const taskInput of taskInputs) {
            if (taskInput.Input.C_EnumerationClass_key) {
                const promise = this.getEnumerationItems(
                    taskInput.Input.C_EnumerationClass_key
                ).then((items: any[]) => {
                    taskInput.EnumerationItems = items;
                });
                promises.push(promise);
            }
        }

        return Promise.all(promises);
    }

    /**
     * Clear observable cache for enumeration items 
     * to force refresh before DEFAULT_CACHE_EXPIRATION
     */
    clearEnumerationItemCache() {
        this.itemObservableMap = {}; 
    }

    deleteClass(enumerationClass: any) {
        this.dataManager.deleteEntity(enumerationClass);
    }

    deleteItem(enumerationItem: any) {
        this.dataManager.deleteEntity(enumerationItem);
    }
}

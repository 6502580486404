import { Directive, HostListener } from "@angular/core";
import { ControlValueAccessor, NgControl } from "@angular/forms";

@Directive({
  selector: '[inputNumber]',
})
export class JobGroupInputNumberDirective {
  constructor(private ngControl: NgControl) {
    this.trimValueAccessor(ngControl.valueAccessor);
  }

  @HostListener('blur')
  blurHandler() {
    const value = this.ngControl.value;
    this.ngControl.control.setValue(this.prepareValue(value));
  }

  @HostListener("input", ["$event.target.value"])
  inputHandler = (val: string): void => {
    this.ngControl.control.setValue(this.prepareValue(val));
  }

  private trimValueAccessor(valueAccessor: ControlValueAccessor) {
    const original = valueAccessor.registerOnChange;
  
    valueAccessor.registerOnChange = (fn: (_: unknown) => void) => {
      return original.call(valueAccessor, (value: unknown) => {
        return fn(this.prepareValue(value));
      });
    };
  }

  private prepareValue(value: unknown) {
    if (typeof value === 'number' || (typeof value === 'string' && value)) {
      const preparedValue = value.toString().trim().replace(/0*(^\d+).*/, '$1');
      return Number(preparedValue);
    }
    return value;
  }
}

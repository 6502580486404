import { convertValueToLuxon } from "./date-time-formatting/convert-value-to-luxon";
import { LuxonInput } from "./date-time-formatting/types/luxon-input.type";

/**
 * Converts a value to a date with luxon
 * @param value
 */
export function filterToDate(value: LuxonInput): Date {
    if (value) {
        return convertValueToLuxon(value).toJSDate();
    }
    return null;
}

<div class="detail-form-wrapper">
    <loading-overlay *ngIf="loading" [message]="loadingMessage"></loading-overlay>

    <div class="detail-header">
        <h3>{{'Institution' | translate}}</h3>

        <pager-toolbar [itemName]="'line'"
                       [logTag]="COMPONENT_LOG_TAG"
                       [first]="pageState.isFirst"
                       [last]="pageState.isLast"
                       [promptForSave]="false"
                       (previous)="onSwitchView('previous')"
                       (next)="onSwitchView('next')"></pager-toolbar>

        <detail-toolbar [facetPrivilege]="facet.Privilege"
                        [logTag]="COMPONENT_LOG_TAG"
                        (exit)="onSwitchView('exit')"
                        [promptForSave]="false"
                        [addInstitution]="true"
                        (saveInstitution)="onSave()">

            <div class="btn-group" role="group" aria-label="Edit actions" additional-groups>
                <div class="btn-group" view-notes-button
                    [readonly]="facet.Privilege === 'ReadOnly'"
                    [pkName]="'C_Institution_key'"
                    [pkValue]="institution.C_Institution_key"
                    [objectName]="institution.Name"
                    [objectType]="'Institution' | translate"
                    [isObjectNew]="institution | isNew"
                    appInsightsClick="click-notes-from-institution">
                </div>
                <button type="button"
                        class="btn btn-secondary"
                        (click)="viewAuditReport()">
                    <i class="fa fa-flag" aria-hidden="true" title="Audit"></i>
                    <span class="gridster-d-sm-none gridster-d-md-inline-block">Audit</span>
                    <span class="sr-only">Audit</span>
                </button>
            </div>
        </detail-toolbar>
    </div>

    <form name="InstitutionForm" id="institutionForm"
          class="detail-form"
          #institutionForm="ngForm">
        <fieldset [disabled]="readonly">


            <div class="form-group row">
                <label class="col-md-2 col-form-label">Name</label>
                <div class="col-md-10">
                    <input type="text"
                           name="Name"
                           #name="ngModel"
                           [(ngModel)]="institution.Name"
                           maxlength="200"
                           class="form-control input-medium"
                           [disabled]="isCRL"
                           required />
                </div>
            </div>

            <div class="form-group row" *ngIf="!isCRL">
                <label class="col-md-2 col-form-label">Identifier</label>
                <div class="col-md-10">
                    <input type="text"
                           name="Identifier"
                           #name="ngModel"
                           [(ngModel)]="institution.Identifier"
                           maxlength="200"
                           class="form-control input-medium" />
                </div>
            </div>
            <div class="form-group row" *ngIf="isCRL">
                <label class="col-md-2 col-form-label">Account Number</label>
                <div class="col-md-10">
                    <input type="text"
                           name="Identifier"
                           #name="ngModel"
                           [(ngModel)]="institution.Identifier"
                           maxlength="10"
                           pattern="[0-9]{1}[0-9]{9}"
                           (focusout)="getClient()"
                           class="form-control input-medium"
                           required/>
                </div>
            </div>

            <div class="form-group row">
                <label *ngIf="!isCRL" class="col-md-2 col-form-label">Account Number</label>
                <label *ngIf="isCRL" class="col-md-2 col-form-label">Client ID</label>
                <div class="col-md-10">
                    <input type="text"
                           name="AccountNumber"
                           #name="ngModel"
                           [(ngModel)]="institution.AccountNumber"
                           maxlength="200"
                           class="form-control input-medium"/>
                </div>
            </div>

            <div class="form-group row">
                <label class="col-md-2 col-form-label">Email Address</label>
                <div class="col-md-10">
                    <input type="text"
                           name="EmailAddress"
                           #name="ngModel"
                           [(ngModel)]="institution.EmailAddress"
                           maxlength="200"
                           class="form-control input-medium"
                           [disabled]="isCRL" />
                </div>
            </div>

            <div class="form-group row">
                <label class="col-md-2 col-form-label">Address</label>
                <div class="col-md-10">
                    <input type="text"
                           name="Address"
                           #name="ngModel"
                           [(ngModel)]="institution.Address"
                           maxlength="200"
                           class="form-control input-medium"
                           [disabled]="isCRL" />
                </div>
            </div>

            <div class="form-group row">
                <label class="col-md-2 col-form-label">Address 2</label>
                <div class="col-md-10">
                    <input type="text"
                           name="AddressQualifier"
                           #name="ngModel"
                           [(ngModel)]="institution.AddressQualifier"
                           maxlength="200"
                           class="form-control input-medium"
                           [disabled]="isCRL" />
                </div>
            </div>

            <div class="form-group row">
                <label class="col-md-2 col-form-label">City</label>
                <div class="col-md-10">
                    <input type="text"
                           name="City"
                           #name="ngModel"
                           [(ngModel)]="institution.City"
                           maxlength="200"
                           class="form-control input-medium"
                           [disabled]="isCRL" />
                </div>
            </div>

            <div class="form-group row">
                <label class="col-md-2 col-form-label">Country</label>
                <div class="col-md-10">
                    <active-vocab-select [(model)]="institution.C_Country_key"
                                         (modelChange)="countryChanged()"
                                         [vocabChoices]="countries"
                                         [keyFormatter]="institutionCountryKeyFormatter"
                                         [optionFormatter]="institutionCountryFormatter"
                                         [nullable]="true"
                                         [readonly]="isCRL"></active-vocab-select>
                </div>
            </div>

            <div class="form-group row">
                <label class="col-md-2 col-form-label">State</label>
                <div class="col-md-10">
                    <active-vocab-select [(model)]="institution.C_State_key"
                                         [vocabChoices]="filteredStates"
                                         [keyFormatter]="institutionStateKeyFormatter"
                                         [optionFormatter]="institutionStateFormatter"
                                         [nullable]="true"
                                         [readonly]="isCRL"></active-vocab-select>
                </div>
            </div>

            <div class="form-group row">
                <label class="col-md-2 col-form-label">Zip Code</label>
                <div class="col-md-10">
                    <input type="text"
                           name="PotalCode"
                           #name="ngModel"
                           [(ngModel)]="institution.PostalCode"
                           class="form-control input-medium"
                           [disabled]="isCRL" />
                </div>
            </div>

            <div class="form-group row">
                <label class="col-md-2 col-form-label">Phone Number</label>
                <div class="col-md-10">
                    <input type="text"
                           name="Phone"
                           #name="ngModel"
                           [(ngModel)]="institution.Phone"
                           class="form-control input-medium"
                           [disabled]="isCRL" />
                </div>
            </div>

            <div class="form-group row">
                <label class="col-md-2 col-form-label">Mobile Number</label>
                <div class="col-md-10">
                    <input type="text"
                           name="MobilePhone"
                           #name="ngModel"
                           [(ngModel)]="institution.MobilePhone"
                           class="form-control input-medium"
                           [disabled]="isCRL" />
                </div>
            </div>

            <div class="form-group row">
                <label class="col-md-2 col-form-label">Type</label>
                <div class="col-md-10">
                    <active-vocab-select [(model)]="institution.C_InstitutionType_key"
                                         [vocabChoices]="institutionTypes"
                                         [keyFormatter]="institutionTypeKeyFormatter"
                                         [optionFormatter]="institutionTypeFormatter"
                                         [nullable]="true"></active-vocab-select>
                </div>
            </div>

            <div class="container-fluid px-0">
                <contacts-table [entity]="institution"
                                [pkName]="'C_Institution_key'"
                                [pkValue]="institution.C_Institution_key"
                                [isCRO]="isCRO"></contacts-table>
            </div>

            <div class="container-fluid px-0">
                <a *ngIf="!readonly" (click)="createNewItem()">
                    <i class="fa fa-fw fa-plus-circle add-item-link"
                       title="Add Site"></i>
                </a>

                <h3 style="display: inline-block">Sites</h3>

                <div *ngFor="let site of institution.Site | orderBy : 'DateCreated' : true">
                    <institution-site [site]="site"
                                      (removeSite)="removeSite(site)"></institution-site>
                </div>
            </div>

        </fieldset>
    </form>
</div>

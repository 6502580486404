import {
    Pipe,
    PipeTransform
} from '@angular/core';
import { FeatureFlagService } from '../../services/feature-flags.service';

import { UserNameService } from '../../user/user-name.service';

@Pipe({ name: 'userFullName' })
export class UserFullNamePipe implements PipeTransform {

    constructor(private userNameService: UserNameService, private featureFlagService: FeatureFlagService) {
        //
    }

    isGLP: boolean;

    transform(value: string): string {
        if (this.isGLP === undefined) {
            const flag = this.featureFlagService.getFlag("IsGLP");
            this.isGLP = (flag && flag.IsActive && flag.Value.toLowerCase() === "true");
        }
        if (this.isGLP) {
            return value;
        } else {
            return this.userNameService.toFullName(value);
        }
    }
}

import { map } from 'rxjs/operators';
import { DetailTaskTableOptions } from '../../tasks/tables/detail-task-table-options';
import { TableSort } from '../../common/models/table-sort';
import { TaskType } from '../../tasks/models/task-type.enum';
import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    ViewChild,
    ViewChildren
} from '@angular/core';
import { NgForm, NgModel } from '@angular/forms';

import { AnimalService } from '../../animals/services/animal.service';
import { BirthService } from '../birth.service';
import { BirthLogicService } from '../birth-logic.service';
import { BirthVocabService } from '../birth-vocab.service';
import { LocationService } from '../../locations/location.service';
import { MaterialPoolService } from '../../services/material-pool.service';
import { MatingService } from '../../matings/mating.service';
import { NamingService } from '../../services/naming.service';
import { VocabularyService } from '../../vocabularies/vocabulary.service';
import { SaveChangesService, UnsavedChanges } from '../../services/save-changes.service';

import { QueryDef } from '../../services/query-def';

import {
    BaseDetail,
    BaseDetailService,
    PageState,
} from '../../common/facet';

import {
    randomId,
    scrollToElement,
    testBreezeIsNew,
    maxSequence
} from '../../common/util';

import { Birth, cv_BirthStatus, Entity } from '../../common/types';
import { DateTime } from 'luxon';
import { dateControlValidator } from '@common/util/date-control.validator';
import { BirthAnimalTableComponent, BirthHousingTableComponent } from '../tables';
import { DetailTaskTableComponent } from 'src/app/tasks/tables';

export interface BirthExtended {
    JobKey: number | null;
    Mating: MatingExtended;
    animalComments: string | null;
    animalDateBorn: Date;
    animalLineKey: number | null;
    animalMarker: string | null;
    animalMatingStatusKey: number;
    animalSexKey: number | null;
    animalStatusKey?: number;
    animalUseKey: number;
    birthAnimals: any[];
    breedingStatusKey?: number;
    dietKey?: number;
    existingHousingUnitKey: number | null;
    generationKey: number | null;
    iacucProtocolKey: number;
    inputsExpanded: boolean;
    materialOriginKey: number;
    animalMicrochipIdentifier: string | null;
    animalExternalIdentifier: string | null;
    originKey?: number;
    ownerKey: number | null;
    physicalMarkerTypeKey: number;
    pools: any[];
    taskAnimalsExpanded: boolean;
    taskCohortsExpanded: boolean;
    taskSamplesExpanded: boolean;
    tasksExpanded: boolean;
}

interface MatingExtended {
    location: string;
}

@Component({
    selector: 'birth-detail',
    templateUrl: './birth-detail.component.html'
})
export class BirthDetailComponent extends BaseDetail implements OnChanges, OnInit {
    @ViewChild('animalTable') animalTable: BirthAnimalTableComponent;
    @ViewChildren('birthHousingTable') birthHousingTables: BirthHousingTableComponent[];
    @ViewChildren('detailTaskTable') detailTaskTables: DetailTaskTableComponent[];
    @ViewChildren('dateControl') dateControls: NgModel[];

    @Input() facet: any;
    @Input() birth: Entity<Birth> & BirthExtended;
    @Input() pageState: PageState;
    @Output() exit: EventEmitter<void> = new EventEmitter<void>();
    @Output() next: EventEmitter<void> = new EventEmitter<void>();
    @Output() previous: EventEmitter<void> = new EventEmitter<void>();

    @ViewChild("birthForm") birthForm: NgForm;

    // CVs
    birthStatuses: Entity<cv_BirthStatus>[] = [];

    // State
    animalScrollElementClass: string;
    birthNamingActive = false;
    private daysToWeanDefault = 21;
    daysToWean: number = this.daysToWeanDefault;
    defaults: any = {};

    // Table options
    detailTaskTableOptions: DetailTaskTableOptions;

    // Table sorting
    taskTableSort: TableSort = new TableSort();

    // Export enum to template
    TaskType = TaskType;

    originalBirthID = '';

    readonly COMPONENT_LOG_TAG = 'birth-detail';


    constructor(
        baseDetailService: BaseDetailService,
        private birthService: BirthService,
        private birthLogicService: BirthLogicService,
        private birthVocabService: BirthVocabService,
        private locationService: LocationService,
        private materialPoolService: MaterialPoolService,
        private matingService: MatingService,
        private namingService: NamingService,
        private vocabularyService: VocabularyService,
        private saveChangesService: SaveChangesService,
    ) {
        super(baseDetailService);
    }

    // lifecycle
    ngOnInit() {
        this.initialize();
        this.initTableOptions();
    }

    ngOnChanges(changes: any) {
        if (changes.birth) {
            if (this.birth && !changes.birth.firstChange) {
                if (this.birthForm) {
                    this.birthForm.form.markAsPristine();
                }

                this.initialize();
            }
        }
    }

    async initialize() {
        try {
            this.setLoading(true);
            this.animalScrollElementClass = 'birth-animals-' + randomId();
    
            if (this.birth) {
                this.setTableStates();
                this.originalBirthID = this.birth.BirthID;
            }
    
            this.initModel();
    
            if (!this.isNewBirth()) {
                this.calculateWeanDate();
            }
    
            await this.getCVs();
            await this.isNamingActive();
            await this.getDetails();
    
            if (this.birth.Mating) {
                await this.getBirthMatingDetails(this.birth.Mating.C_MaterialPool_key);
            }
                
            if (testBreezeIsNew(this.birth) && this.birth.C_MaterialPool_key && !this.birth.DateWean) {
                this.calculateWeanDate();
                await this.resetBirthMaterials();
            }
    
            await this.getTasks();
        } catch (error) {
            this.setLoading(false);
            throw error;
        }
    
        this.setLoading(false);
    }

    /**
     * Sets view defaults for tables
     */
    setTableStates() {
        // For now, these are constant
        this.birth.tasksExpanded = true;
        this.birth.inputsExpanded = true;
        this.birth.taskAnimalsExpanded = false;
        this.birth.taskCohortsExpanded = false;
        this.birth.taskSamplesExpanded = false;
    }

    private initTableOptions() {
        // Detail Task Table
        this.detailTaskTableOptions = new DetailTaskTableOptions();
        this.detailTaskTableOptions.allowLocking = false;
        this.detailTaskTableOptions.showAnimals = false;
        this.detailTaskTableOptions.showSamples = false;
    }

    /**
     * Adds placeholder derived properties to the birth.
     */
    private initModel() {
        if (!this.birth.pools) {
            this.birth.pools = [];
        }

        // The housing unit selected for weaning selected animals into
        this.birth.existingHousingUnitKey = null;

        // Default dervived properties for animals added to the birth
        this.birth.animalComments = null;
        this.birth.animalLineKey = null;
        this.birth.animalMarker = null;
        this.birth.animalMatingStatusKey = null;
        this.birth.animalStatusKey = null;
        this.birth.breedingStatusKey = null;
        this.birth.animalUseKey = null;
        this.birth.generationKey = null;
        this.birth.ownerKey = null;
        this.birth.originKey = null;
        this.birth.dietKey = null;
        this.birth.JobKey = null;
    }

    private getCVs(): Promise<any> {
        return this.birthVocabService.birthStatuses$.pipe(map((data) => {
            this.birthStatuses = data;
        })).toPromise();
    }

    private async isNamingActive(): Promise<void> {
        const active: boolean = await this.namingService.isBirthNamingActive();
        this.birthNamingActive = active;
    }



    private getDetails(): Promise<any> {
        if (this.birth && this.birth.C_Birth_key > 0) {
            return this.birthService.getBirth(this.birth.C_Birth_key);
        } else {
            return Promise.resolve(this.birth);
        }
    }

    

    private getTasks(): Promise<any> {
        return this.birthService.getBirthTasks(this.birth.C_Birth_key);
    }

    async getBirthMatingDetails(materialPoolKey: number): Promise<void> {
        if (!materialPoolKey) {
            return Promise.resolve();
        }
    
        await this.matingService.getMatingDetails(materialPoolKey);
    
        if (this.birth.Mating) {
            this.setDaysToWean(this.birth.Mating, this.daysToWeanDefault);
            this.setAnimalLineKey(this.birth.Mating);
    
            const path = await this.locationService.getMaterialPoolLocationPath(this.birth.Mating.C_MaterialPool_key);
    
            this.birth.Mating.location = path;
            this.birth.generationKey = this.birth.Mating.C_Generation_key;
        }
    }

    private isNewBirth(): boolean {
        return this.birth.BirthID !== "";
    }

    /**
     * Calculates and sets birth.DateWean if the birth has a Mating, Line, and DateBorn
     */
    calculateWeanDate(event: MouseEvent = null) {
        if (this.birth.DateBorn) {
            if (event || !this.birth.DateWean) {
                const weanDate = DateTime.fromJSDate(this.birth.DateBorn).plus({days: this.daysToWean});
                this.birth.DateWean = weanDate.toString() as any as Date;
            }
        }
    }

    private setDaysToWean(mating: any, defaultValue: number) {
        if (mating?.Line?.cv_Taxon?.DaysToWean) {
            this.daysToWean = mating.Line.cv_Taxon.DaysToWean;
        } else {
            this.daysToWean = defaultValue;
        }
    }

    private setAnimalLineKey(mating: any) {
        if (mating.C_Line_key) {
            this.birth.animalLineKey = mating.C_Line_key;
        } else {
            this.birth.animalLineKey = null;
        }
    }

    // BirthMaterials
    private resetBirthMaterials(): Promise<void> {
        return this.birthService.resetBirthMaterialsForBirth(this.birth);
    }


    // Model changes
    selectMating(materialPoolKey: number) {
        this.birth.C_MaterialPool_key = materialPoolKey;
        return this.getBirthMatingDetails(materialPoolKey).then(() => {
            this.calculateWeanDate();
            return this.resetBirthMaterials();
        });
    }

    birthDateBornChanged() {
        this.birth.animalDateBorn = this.birth.DateBorn;
    }

    // Tasks
    addTaskBirth(taskInstance: any) {
        const taskSequence = maxSequence(this.birth.TaskBirth) + 1;

        this.birthService.createTaskBirth(
            this.birth.C_Birth_key,
            taskInstance.C_TaskInstance_key,
            taskSequence
        );
    }

    // Facet
    async scrollToAnimals() {
        await scrollToElement('.' + this.animalScrollElementClass);
    }

    onCancel() {
        this.birthService.cancelBirth(this.birth);
    }

    onSaveBirth() {
        for (const materialPool of this.birth.pools) {
            if (!this.locationService.locationsValid(materialPool.MaterialLocation)) {
                this.loggingService.logError("Locations dates are not valid.", null, this.COMPONENT_LOG_TAG, true);
                return;
            }
        }

        const errors = this.birthForm.controls.birthID.errors;
        if (errors && errors.required) {
            this.loggingService.logError('A birth requires an ID. Please enter a birth ID' +
                ' and try again.', null, this.COMPONENT_LOG_TAG, true);
            return;
        }

        if (errors && errors.unique && this.birth.BirthID !== this.originalBirthID) {
            this.loggingService.logError('A birth requires a unique ID. Please enter' +
                ' a new birth ID and try again.', null, this.COMPONENT_LOG_TAG, true);
            return;
        }

        const message = dateControlValidator(this.dateControls)
            || this.animalTable?.validate()
            || this.birthHousingTables.map(item => item.validate()).find(msg => msg)
            || this.detailTaskTables.map(item => item.validate()).find(msg => msg);
        if (message) {
            this.loggingService.logError(message, null, this.COMPONENT_LOG_TAG, true);
            return;
        }

        // save changes if done looping through all materialPool locations
        this.saveChangesService.saveChanges(this.COMPONENT_LOG_TAG);
    }

    // <select> formatters
    birthStatusKeyFormatter = (value: any) => {
        return value.C_BirthStatus_key;
    }
    birthStatusFormatter = (value: any) => {
        return value.BirthStatus;
    }

    onSwitchView(buttonName: string): any {
        if (this.saveChangesService.hasChanges) {
            // If there are unsaved changes, prompt the user to save or discard
            return this.viewUnsavedChangesModalService.openComponent().then((result: string) => {
                if (result === 'save') {
                    // Throw error & don't save if location dates are invalid
                    for (const materialPool of this.birth.pools) {
                        if (!this.locationService.locationsValid(materialPool.MaterialLocation)) {
                            this.loggingService.logError("Locations dates are not valid.", null, this.COMPONENT_LOG_TAG, true);
                            return Promise.resolve();
                        }
                    }

                    return this.saveChangesService.saveChanges(this.COMPONENT_LOG_TAG).then(() => {
                        // Emits reload event when save a new record by clicking on next
                        if (buttonName === 'next') {
                            this.reload.emit();
                        }
                        this.emitViewChange(buttonName, UnsavedChanges.save);
                    });
                } else {
                    if (this.dataContext.hasChanges()) {
                        this.onCancel();
                        this.loggingService.logFacetUndoSuccess(this.COMPONENT_LOG_TAG);
                    }
                    this.emitViewChange(buttonName, UnsavedChanges.discard);
                }
            });
        } else {
            this.emitViewChange(buttonName, UnsavedChanges.noChanges);
        }
    }

    private emitViewChange(buttonName: string, changes: UnsavedChanges) {
        switch (buttonName) {
            case 'previous':
                super.previousClicked(changes);
                this.previous.emit();
                break;
            case 'next':
                super.nextClicked(changes);
                this.next.emit();
                break;
            case 'exit':
                super.exitClicked(changes);
                this.exit.emit();
                break;
            default:
                break;
        }
    }
}

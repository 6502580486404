import { Injectable } from '@angular/core';
import {
    EntityQuery,
    FilterQueryOp,
    Predicate,
    QueryResult
} from 'breeze-client';

import { notEmpty } from '../common/util/not-empty';

import { DataManagerService } from '../services/data-manager.service';
import { QueryDef } from '../services/query-def';
import { BaseEntityService } from '../services/base-entity.service';
import { getDateRangePredicates } from '../services/queries';

@Injectable()
export class ImportService extends BaseEntityService {
    constructor(private dataManager: DataManagerService) {
        super();
    }

    // We don't want people to be able to edit these through the normal interface
    // but maybe eventually people will be able to "delete" and then delete all
    // associated objects
    getImportLogs(queryDef: QueryDef): Promise<QueryResult> {
        let query = this.buildDefaultQuery('ImportLogs', queryDef);
        // this.ensureDefExpanded call for each sub-entity 
        // that we will want to include
        this.ensureDefExpanded(queryDef, 'ImportFileDefinition.cv_ImportFileType');
        this.ensureDefExpanded(queryDef, 'ImportFileDefinition.cv_ImportFileFormat');

        if (notEmpty(queryDef.expands)) {
            query = query.expand(queryDef.expands.join(','));
        }

        let predicates: Predicate[] = [];
        if (queryDef.filter) {
            predicates = predicates.concat(this.
                buildPredicates(queryDef.filter));
        }

        if (notEmpty(predicates)) {
            query = query.where(Predicate.and(predicates));
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed) as Promise<QueryResult>;
    }

    getImportLog(importLogKey: number, expands?: string[]): Promise<any> {

        if (!expands) {
            expands = [];
        }
        this.ensureExpanded(expands, 'ImportFileDefinition.cv_ImportFileType');
        this.ensureExpanded(expands, 'ImportFileDefinition.cv_ImportFileFormat');

        const query = EntityQuery.from('ImportLogs')
            .expand(expands.join(','))
            .where('C_ImportLog_key', 'eq', importLogKey);

        return this.dataManager.returnSingleQueryResult(query);
    }

    buildPredicates(filter: any): Predicate[] {
        let predicates: Predicate[] = [];
        if (filter.C_ImportLog_key) {
            predicates.push(
                Predicate.create('C_ImportLog_key', 'eq', filter.C_ImportLog_key)
            );
        }

        if (filter.FileName) {
            predicates.push(
                Predicate.create('FileName', FilterQueryOp.Contains, { value: filter.FileName })
            );
        }

        if (filter.C_ImportFileDefinition_key) {
            predicates.push(
                Predicate.create('C_ImportFileDefinition_key', 'eq',
                    filter.C_ImportFileDefinition_key)
            );
        }

        if (filter.DefinitionName) {
            predicates.push(
                Predicate.create('ImportFileDefinition.DefinitionName',
                FilterQueryOp.Contains, { value: filter.DefinitionName })
            );
        }

        if (filter.HTTPResult) {
            predicates.push(
                Predicate.create('HTTPResult', FilterQueryOp.Contains, { value: filter.HTTPResult })
            );
        }

        if (filter.Result === true || filter.Result === false) {
            predicates.push(
                Predicate.create('Result', 'eq', filter.Result)
            );
        }

        if (filter.StartDateTimeStart || filter.StartDateTimeEnd) {
            const datePredicates: Predicate[] = getDateRangePredicates(
                'StartDateTime',
                filter.StartDateTimeStart,
                filter.StartDateTimeEnd
            );
            if (notEmpty(datePredicates)) {
                predicates = predicates.concat(datePredicates);
            }
        }

        if (filter.EndDateTimeStart || filter.EndDateTimeEnd) {
            const datePredicates: Predicate[] = getDateRangePredicates(
                'EndDateTime',
                filter.EndDateTimeStart,
                filter.EndDateTimeEnd
            );
            if (notEmpty(datePredicates)) {
                predicates = predicates.concat(datePredicates);
            }
        }

        if (filter.CreatedBy) {
            predicates.push(
                Predicate.create('CreatedBy', FilterQueryOp.Contains, { value: filter.CreatedBy })
            );
        }

        if (filter.C_ImportFileType_key) {
            predicates.push(
                Predicate.create('ImportFileDefinition.C_ImportFileType_key',
                    'eq', filter.C_ImportFileType_key)
            );
        }

        if (filter.C_ImportFileFormat_key) {
            predicates.push(
                Predicate.create('ImportFileDefinition.C_ImportFileFormat_key',
                    'eq', filter.C_ImportFileFormat_key)
            );
        }

        if (filter.ImportFileType) {
            predicates.push(Predicate.create(
                'ImportFileDefinition.cv_ImportFileType.ImportFileType',
                FilterQueryOp.Contains, { value: filter.ImportFileType }));
        }

        if (filter.ImportFileFormat) {
            predicates.push(Predicate.create(
                'ImportFileDefinition.cv_ImportFileFormat.ImportFileFormat',
                FilterQueryOp.Contains, { value: filter.ImportFileFormat }));
        }

        return predicates;
    }

    async ensureVisibleColumnsDataLoaded(imports: any[], visibleColumns: string[]): Promise<void> {
        const expands = this.generateExpandsFromVisibleColumns(imports[0], visibleColumns);
        return this.dataManager.ensureRelationships(imports, expands);
    }
}

import { Injectable } from '@angular/core';
import { RouteEnum } from './route.enum';
import { Router } from '@angular/router';


@Injectable()
export class RoutingService {

    constructor(
        private router: Router,
    ) {}

    workspaceStateIs(route: RouteEnum): boolean {
        return this.router.isActive(route, false);
    }

    stateIs(route: RouteEnum): boolean {
        return this.router.isActive(route, true);
    }

    async navigateToDashboard(): Promise<boolean> {
        return this.navigateTo(RouteEnum.Dashboard);
    }

    navigateToHome(): Promise<boolean> {
        return this.navigateTo(RouteEnum.Home);
    }

    navigateToInitialization(): Promise<boolean> {
        return this.navigateTo(RouteEnum.Initialization);
    }

    navigateToLogin(): Promise<boolean> {
        return this.navigateTo(RouteEnum.Login);
    }

    navigateToVersionInfo(): Promise<boolean> {
        return this.navigateTo(RouteEnum.Versions);
    }

    navigateToWorkspaces(): Promise<boolean> {
        return this.navigateTo(RouteEnum.Workspaces);
    }
    
    navigateToWorkspacesWithID(workspace: any): Promise<boolean> {
        return this.router.navigate([RouteEnum.Workspaces, workspace.C_Workspace_key]);
    }

    navigateToWorkspacesWithKey(workspaceKey: number): Promise<boolean> {
        return this.router.navigate([RouteEnum.Workspaces, workspaceKey]);
    }

    navigateToPwdReset(id: string): Promise<boolean> {
        return this.router.navigate([RouteEnum.PwdReset, {resetid: id}]);
    }

    navigateToEmailVerification(id: string): Promise<boolean> {
        return this.router.navigate([RouteEnum.EmailVerification, { EmailVerificationCode: id }]);
    }

    private navigateTo(route: RouteEnum): Promise<boolean> {
        return this.router.navigate([route]);
    }

    navigateToOktaLogin(): Promise<boolean> {
        return this.navigateTo(RouteEnum.LoginOkta);
    }
}

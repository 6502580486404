<!-- Table for SampleConstructs -->
<div *ngIf="sample.SampleConstruct && sample.SampleConstruct.length > 0">
    <table class="table table-sm table-borderless">
        <tbody>
            <tr *ngFor="let sampleConstruct of sample.SampleConstruct">
                <td class="icon-cell">
                    <a (click)="removeSampleConstruct(sampleConstruct)"
                       *ngIf="!readonly">
                        <i class="fa fa-remove remove-item"
                            title="Remove Construct"></i>
                    </a>
                </td>
                <td class="text-nowrap">
                    {{sampleConstruct.Construct?.FullName}}
                </td>
            </tr>
        </tbody>
    </table>
</div>

<!-- Selector for SampleConstruct -->
<climb-indirect-typeahead
        [search]="searchConstructs"
        [resultFormatter]="fullNameFormatter"
        [placeholder]="placeholder"
        (selectItem)="addSampleConstruct($event)"
></climb-indirect-typeahead>

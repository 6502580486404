<form novalidate>
    <div class="modal-header justify-content-center">
        <h4 class="modal-title">
            Workflow Audit Report
        </h4>
    </div>

    <div class="modal-body modal-body-tall">

        <loading-overlay *ngIf="loading"></loading-overlay>

        <h2>
            {{workflowAuditData[0]?.TaskAlias}}
        </h2>

        <div class="audit-report-section">
            <table class="table audit-table">
                <thead>
                    <tr>
                        <th>Action</th>
                        <th>Modified Date</th>
                        <th>Modified By</th>
                        <th>Due Date</th>
                        <th>Complete Date</th>
                        <th>Status</th>
                        <th>Assigned To</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let auditRow of workflowAuditData"
                        [ngClass]="{'current-value' : auditRow.IsCurrent, 'expired-value': !auditRow.IsCurrent}">
                        <td class="text-nowrap">
                            <update-type-cell [updateType]="auditRow.UpdateType"></update-type-cell>
                        </td>
                        <td>{{auditRow.ModifiedDate | formatUtcDateTime}}</td>
                        <td>{{auditRow.ModifiedBy | userFullName}}</td>
                        <td>{{auditRow.DateDue | formatShortDateOrTimeUtc}}</td>
                        <td>{{auditRow.DateComplete | formatShortDateOrTimeUtc}}</td>
                        <td>{{auditRow.TaskStatus}}</td>
                        <td>{{auditRow.AssignedTo}}</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="audit-report-section">
            <h3>Animals</h3>

            <p *ngIf="!(workflowAuditAnimalsData | notEmpty)"
               class="no-data-message">
                {{noDataMessage}}
            </p>

            <table class="table audit-table"
                   *ngIf="workflowAuditAnimalsData | notEmpty">
                <thead>
                    <tr>
                        <th>Action</th>
                        <th>Modified Date</th>
                        <th>Modified By</th>
                        <th>Name</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let auditRow of workflowAuditAnimalsData"
                        [ngClass]="{'current-value' : auditRow.IsCurrent, 'expired-value': !auditRow.IsCurrent}">
                        <td class="text-nowrap">
                            <update-type-cell [updateType]="auditRow.UpdateType"></update-type-cell>
                        </td>
                        <td>{{auditRow.ModifiedDate | formatUtcDateTime}}</td>
                        <td>{{auditRow.ModifiedBy | userFullName}}</td>
                        <td>{{auditRow.MaterialName}}</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="audit-report-section">
            <h3>Samples</h3>

            <p *ngIf="!(workflowAuditSamplesData | notEmpty)"
               class="no-data-message">
                {{noDataMessage}}
            </p>

            <table class="table audit-table"
                   *ngIf="workflowAuditSamplesData | notEmpty">
                <thead>
                    <tr>
                        <th>Action</th>
                        <th>Modified Date</th>
                        <th>Modified By</th>
                        <th>Name</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let auditRow of workflowAuditSamplesData"
                        [ngClass]="{'current-value' : auditRow.IsCurrent, 'expired-value': !auditRow.IsCurrent}">
                        <td class="text-nowrap">
                            <update-type-cell [updateType]="auditRow.UpdateType"></update-type-cell>
                        </td>
                        <td>{{auditRow.ModifiedDate | formatUtcDateTime}}</td>
                        <td>{{auditRow.ModifiedBy | userFullName}}</td>
                        <td>{{auditRow.MaterialName}}</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="audit-report-section">
            <h3>Cohorts</h3>

            <p *ngIf="!(workflowAuditCohortsData | notEmpty)"
               class="no-data-message">
                {{noDataMessage}}
            </p>

            <table class="table audit-table"
                   *ngIf="workflowAuditCohortsData | notEmpty">
                <thead>
                    <tr>
                        <th>Action</th>
                        <th>Modified Date</th>
                        <th>Modified By</th>
                        <th>Name</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let auditRow of workflowAuditCohortsData"
                        [ngClass]="{'current-value' : auditRow.IsCurrent, 'expired-value': !auditRow.IsCurrent}">
                        <td class="text-nowrap">
                            <update-type-cell [updateType]="auditRow.UpdateType"></update-type-cell>
                        </td>
                        <td>{{auditRow.ModifiedDate | formatUtcDateTime}}</td>
                        <td>{{auditRow.ModifiedBy | userFullName}}</td>
                        <td>{{auditRow.CohortName}}</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="audit-report-section">
            <h3>Inputs</h3>

            <p *ngIf="!(workflowAuditInputsData | notEmpty)"
               class="no-data-message">
                {{noDataMessage}}
            </p>

            <table class="table audit-table"
                   *ngIf="workflowAuditInputsData | notEmpty">
                <thead>
                    <tr>
                        <th>Action</th>
                        <th>Modified Date</th>
                        <th>Modified By</th>
                        <th>Name</th>
                        <th>Value</th>
                        <th>Validated Value</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let auditRow of workflowAuditInputsData"
                        [ngClass]="{'current-value' : auditRow.IsCurrent, 'expired-value': !auditRow.IsCurrent}">
                        <td class="text-nowrap">
                            <update-type-cell [updateType]="auditRow.UpdateType"></update-type-cell>
                        </td>
                        <td>{{auditRow.ModifiedDate | formatUtcDateTime}}</td>
                        <td>{{auditRow.ModifiedBy | userFullName}}</td>
                        <td>{{auditRow.InputName}}</td>
                        <td>{{auditRow.InputValue}}</td>
                        <td>{{auditRow.ValidatedInputValue}}</td>
                    </tr>
                </tbody>
            </table>
        </div>

    </div><!-- /.modal-body -->

    <div class="modal-footer">
        <button type="button"
                class="btn btn-fw btn-lg btn-secondary"
                (click)="exportAudit()"
                [disabled]="loading">
            <i class="fa fa-download" aria-hidden="true"></i>
            Export
        </button>

        <button type="button"
                class="btn btn-fw btn-lg btn-secondary"
                (click)="onCancel()">
            Close
        </button>
    </div><!-- /.modal-footer -->
</form>

import {
    Component,
    Injector,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { DialogService } from '@common/dialog/dialog.service';
import { WorkgroupSwitchModalComponent } from '../header/components/workgroup-switch-modal/workgroup-switch-modal.component';
import { Overlay } from '@angular/cdk/overlay';
import type { DialogRef } from '@common/dialog/dialog-ref';
import { TabStorageService } from '../multiply-tab/tab-storage.service';
import { MixinUnsubscribeClass } from '@common/mixins';

@Component({
    selector: 'workgroup-modal-view',
    template: `
        <section></section>
    `
})
export class WorkgroupModalViewComponent extends MixinUnsubscribeClass implements OnInit, OnDestroy {
    private dialogRef: DialogRef;

    constructor(
        private dialogService: DialogService,
        private overlay: Overlay,
        private tabStorageService: TabStorageService,
        protected injector: Injector
    
    ) {
        super(injector);
    }

    ngOnInit() {
        super.ngOnInit();
        this.openWorkgroupModal();
        this.subscription = this.tabStorageService.closeModal$.subscribe(() => {
            this.openWorkgroupModal();
        });
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
        this.dialogRef?.close();
    }

    openWorkgroupModal() {
        this.dialogRef = this.dialogService.open(WorkgroupSwitchModalComponent, {
            disableClose: true,
            positionStrategy: this.overlay.position().global().centerHorizontally().top('80px'),
        });
    }
}

import { VocabularyService } from '../../../vocabularies/vocabulary.service';
import {
    Component,
    Input,
    OnInit,
    OnChanges
} from '@angular/core';


@Component({
    selector: 'workflow-job-details',
    templateUrl: './workflow-job-details.component.html',
})
export class WorkflowJobDetailsComponent implements OnInit, OnChanges {
    @Input() job: any;
    @Input() useTableFormat: boolean;

    constructor(
        private vocabularyService: VocabularyService
    ) { }

    ngOnInit() {
        this.initialize();
    }

    ngOnChanges(changes: any) {
        if (changes.job && !changes.job.firstChange) {
            // load any Job data
        }
    }
    
    initialize() {
        this.getCVs();
    }

    getCVs(): Promise<any> {
        // Ensure CVs loaded for samples in job-task details
        const p1 = this.vocabularyService.ensureCVLoaded('cv_PreservationMethods');
        const p2 = this.vocabularyService.ensureCVLoaded('cv_SampleStatuses');
        const p3 = this.vocabularyService.ensureCVLoaded('cv_SampleTypes');
        const p4 = this.vocabularyService.ensureCVLoaded('cv_JobTypes');

        return Promise.all([p1, p2, p3, p4]);
    }
}

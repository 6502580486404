/**
 * All avaiable types for Report variation parameters
 */
/* eslint-disable-next-line */
export const ParamTypes = {
    date: 'date',
    daterange: 'daterange',
    duedaterange: 'duedaterange',
    animalids: 'animalids',
    animalstatus: 'animalstatus',
    iacucprotocol: 'iacucprotocol',
    jobid: 'jobid',
    jobids: 'jobids',
    jobkey: 'jobkey',
    taskname: 'taskname',
    matingids: 'matingids',
    weanids: 'weanids',
};

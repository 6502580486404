import { Injectable } from '@angular/core';

import { TranslationService } from '../../services/translation.service';
import { UserNameService } from '../../user/user-name.service';
import { CsvExporter } from '../../common/export/csv-exporter';
import { DateFormatterService } from '@common/util/date-time-formatting';


/**
 * Exports a study audit report to CSV
 */
@Injectable()
export class ExportStudyAuditService {
    csvExporter: CsvExporter;

    constructor(
        private translationService: TranslationService,
        private userNameService: UserNameService,
        private dateFormatterService: DateFormatterService,
    ) {
        this.csvExporter = new CsvExporter();
    }


    exportToCsv(
        auditData: any[],
        auditCharacteristicsData: any[],
        auditLinesData: any[],
    ) {
        const EXPORTER_CSV_FILENAME = this.translationService.translate('Study') +
            'Audit.csv';

        const data: any[][] = this.buildExportData(
            auditData,
            auditCharacteristicsData,
            auditLinesData,
        );

        this.csvExporter.download(data, EXPORTER_CSV_FILENAME);
    }

    buildExportData(
        auditData: any[],
        auditCharacteristicsData: any[],
        auditLinesData: any[],
    ): any[][] {
        const data: any[][] = [];

        data.push(['Details']);
        data.push([
            'Action',
            'Modified Date',
            'Modified By',
            'Name',
            'Type',
            'Active',
            'Status',
            'Description',
            'Comments',
            'Locked'
        ]);
        for (const row of auditData) {
            data.push([
                row.UpdateType,
                this.dateFormatterService.formatDateTimeUTC(row.ModifiedDate),
                this.userNameService.toFullName(row.ModifiedBy),
                row.StudyName,
                row.StudyType,
                row.IsActive,
                row.StudyStatus,
                row.Description,
                row.Comments,
                row.IsLocked
            ]);
        }

        if (auditCharacteristicsData.length > 0) {
            data.push([]);
            data.push(['Characteristics']);
            data.push([
                'Action',
                'Modified Date',
                'Modified By',
                'Characteristic Name',
                'Value'
            ]);
            for (const row of auditCharacteristicsData) {
                data.push([
                    row.UpdateType,
                    this.dateFormatterService.formatDateTimeUTC(row.ModifiedDate),
                    this.userNameService.toFullName(row.ModifiedBy),
                    row.CharacteristicName,
                    row.CharacteristicValue
                ]);
            }
        }

        if (auditLinesData.length > 0) {
            data.push([]);
            data.push([this.translationService.translate('Lines')]);
            data.push([
                'Action',
                'Modified Date',
                'Modified By',
                this.translationService.translate('Line'),
                'Animal Count'
            ]);
            for (const row of auditLinesData) {
                data.push([
                    row.UpdateType,
                    this.dateFormatterService.formatDateTimeUTC(row.ModifiedDate),
                    this.userNameService.toFullName(row.ModifiedBy),
                    row.LineName,
                    row.AnimalCount
                ]);
            }
        }

        return data;
    }
}

import { Pipe, PipeTransform } from '@angular/core';

import {
    getSafeProp,
    notEmpty,
    uniqueHousingMaterials,
} from '../util';
import { MaterialPoolMaterial } from '@common/types';

/**
 * Returns unique male Materials from a list of MaterialPoolMaterial.Material.Animals.
 */
@Pipe({
    name: 'housingUniqueMaleMaterials',
    pure: false
})
export class HousingUniqueMaleMaterialsPipe implements PipeTransform {

    readonly TARGET_PROPERTY = 'Animal.cv_Sex.Sex';
    readonly TARGET_VALUE = 'Male';

    transform<T extends MaterialPoolMaterial>(items: T[]): T["Material"][] {
        let filtered: T["Material"][] = [];

        if (notEmpty(items)) {
            const uniqueMaterials = uniqueHousingMaterials(items);

            filtered = uniqueMaterials.filter((material) => {
                return getSafeProp(material, this.TARGET_PROPERTY) === this.TARGET_VALUE;
            });
        }

        return filtered;
    }
}

import { Component } from '@angular/core';
import { squareOnSquare } from '@icons';
import { CopyBufferService } from '@common/services/copy-buffer.service';

@Component({
    selector: 'climb-copy-buffer',
    templateUrl: 'copy-buffer.component.html',
})
export class CopyBufferComponent {
    icons = { squareOnSquare };

    get buffer() {
        return this.copyBufferService.buffer;
    }

    constructor(private copyBufferService: CopyBufferService) {}
}

import { FacetSharedModule } from './../common/facet/facet-shared.module';
import { SamplesSharedModule } from './../samples/samples-shared.module';
import { EnumerationsSharedModule } from './../enumerations/enumerations-shared.module';
import { PlateDetailComponent } from './plate-detail.component';
import { PlateFilterComponent } from './plate-filter.component';
import { PlateFacetComponent } from './plate-facet.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { ClimbCommonModule } from '../common/climb-common.module';
import { ClimbServicesModule } from '../services/climb-services.module';

import { PlatesSharedModule } from './plates-shared.module';

import { JobsSharedModule } from '../jobs/jobs-shared.module';
import { WorkspacesSharedModule } from '../workspaces/workspaces-shared.module';
import { VocabulariesSharedModule } from '../vocabularies/vocabularies-shared.module';
import { PlatePositionFilterPipe } from './pipes';
import { AnimalsSharedModule } from '../animals/animals-shared.module';
import { SearchSharedModule } from '../search/search-shared.module';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgbModule,
        ClimbCommonModule,
        ClimbServicesModule,
        FacetSharedModule,
        AnimalsSharedModule,
        EnumerationsSharedModule,
        JobsSharedModule,
        PlatesSharedModule,
        SamplesSharedModule,
        SearchSharedModule,
        VocabulariesSharedModule,
        WorkspacesSharedModule
    ],
    declarations: [
        PlateFacetComponent,
        PlateFilterComponent,
        PlatePositionFilterPipe,
        PlateDetailComponent
    ],
    exports: [
        PlateFacetComponent,
        PlateFilterComponent,
    ],
    providers: [
    ]
})
export class PlatesModule { }

import { pathStringToArray } from './private/path-string-to-array';
/*
*  Returns nested object attribute with string containing dot notation.
*    e.g. resolveByString({ parent: { child: "val1" } }, "parent.child")
*      returns "val1"
*
* Snippet from http://stackoverflow.com/a/6491621
*   of question "Accessing nested javascript objects with string key"
*/
export function resolveByString(o: any, s: string) {
    const a = pathStringToArray(s);
    for (let i = 0, n = a.length; i < n; ++i) {
        const k = a[i];
        if (k in o) {
            o = o[k];
        } else {
            return;
        }
    }
    return o;
}

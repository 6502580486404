import {
    Component,
    Input
} from '@angular/core';

import {
    NgbActiveModal
} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'note-log-modal',
    templateUrl: './note-log-modal.component.html'
})
export class NoteLogModalComponent {
    /* The name of the parent object */
    @Input() objectName: string;
    /* The name of the parent object's type */
    @Input() objectType: string;
    /* The name of the object's primary key database field */
    @Input() pkName: string;
    /* The value of the object's primary key */
    @Input() pkValue: number;
    /* Is the parent object record new and unsaved? */
    @Input() isObjectNew: boolean;
    /* Is read only? */
    @Input() readonly: boolean;


    constructor(
        private activeModal: NgbActiveModal
    ) { }


    onCancel(): void {
        this.activeModal.dismiss('closed');
    }
}

import { BehaviorSubject, Observable } from 'rxjs';
import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    ViewChild,
    TemplateRef,
    ViewChildren,
    OnDestroy,
} from '@angular/core';
import { NgForm, NgModel } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import {
    BaseDetail,
    BaseDetailService,
    FacetView,
    IFacet,
    PageState
} from '../common/facet';
import { uniqueArrayFromPropertyPath } from '../common/util';
import { CensusService } from './census.service';
import { VocabularyService } from '../vocabularies/vocabulary.service';
import { CensusTableOptions } from './census-table-options';
import { CellFormatterService, TableState, DataResponse, DataTableCommService, TableColumnDef } from '../common/datatable';
import { IValidatable, SaveChangesService, UnsavedChanges } from '../services/save-changes.service';
import { CensusScanModalService } from './census-scan-modal.service';
import { ConfirmService } from '../common/confirm';
import { DataContextService } from '../services/data-context.service';
import { LoggingService } from '../services/logging.service';
import { CageCardChooserReportType } from '../reporting/cage-card-chooser.interface';
import { TranslationService } from '../services/translation.service';
import { MaterialPoolService } from '../services/material-pool.service';
import { ViewCensusAuditReportComponentService } from './audit';
import { QueryDef } from '../services/query-def';
import { LocationService } from '../locations/location.service';
import { dateControlValidator } from '@common/util/date-control.validator';

@Component({
    selector: 'census-detail',
    templateUrl: './census-detail.component.html'
})
export class CensusDetailComponent extends BaseDetail implements OnChanges, OnInit, OnDestroy, IValidatable {
    @ViewChildren('dateControl') dateControls: NgModel[];
    @Input() facet: IFacet;
    @Input() facetView: FacetView;

    @Input() census: any;
    @Input() pageState: PageState;
    @Output() exit: EventEmitter<void> = new EventEmitter<void>();
    @Output() next: EventEmitter<void> = new EventEmitter<void>();
    @Output() previous: EventEmitter<void> = new EventEmitter<void>();
    @Output() modelCopy: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild("censusForm") censusForm: NgForm;

    // State
    saving = false;
    housingOpened = false;

    housings: any[] = [];

    censusStatuses: any[] = [];
    censusTableOptions: CensusTableOptions;
    dataService: any;
    housing: any = null;
    locationPosition: any = null;
    defaultCensusStatusKey: any = null;

    loading = false;
    loadingMessage = "Loading";

    dataContext: DataContextService;
    loggingService: LoggingService;

    selectedRows: any[] = [];
    animalsInSelectedRow: any[] = [];
    cageCardReportTypes: CageCardChooserReportType[];

    readonly COMPONENT_LOG_TAG = 'census-detail';
    readonly MAX_SAMPLES = 200;

    dataTableColumns: BehaviorSubject<TableColumnDef[]>;
    dataTableColumns$: Observable<TableColumnDef[]>;

    constructor(
        private baseDetailService: BaseDetailService,
        private censusService: CensusService,
        private vocabularyService: VocabularyService,
        private cellFormaterService: CellFormatterService,
        private saveChangesService: SaveChangesService,
        private dataTableComm: DataTableCommService,
        private censusScanModalService: CensusScanModalService,
        private confirmService: ConfirmService,
        private translationService: TranslationService,
        private modalService: NgbModal,
        private materialPoolService: MaterialPoolService,
        private viewCensusAuditReportComponentService: ViewCensusAuditReportComponentService,
        private locationService: LocationService
    ) {
        super(baseDetailService);
        this.censusTableOptions = new CensusTableOptions(cellFormaterService);

        this.dataTableColumns = new BehaviorSubject(this.censusTableOptions.detailViewOptions.columns);
        this.dataTableColumns$ = this.dataTableColumns.asObservable();

        this.dataContext = baseDetailService.dataContext;
        this.loggingService = baseDetailService.loggingService;
        this.dataService = {
            run: (tableState: TableState) => {
                 return this.loadMaterialPools(tableState);
            }
        };
    }

    // lifecycle
    ngOnInit() {
        this.loading = true;

        this.getCVs();

        this.cageCardReportTypes = [
            { key: 'Mating', display: 'Mating' },
            { key: 'Wean', display: this.translationService.translate('Wean') },
            { key: 'Job', display: this.translationService.translate('Job') }
        ];

        this.saveChangesService.registerValidator(this);
        this.checkLocationSelection();
    }

    ngOnChanges(changes: any) {
        if (changes.census) {
            this.loading = true;
            this.checkLocationSelection();
            this.dataTableComm.reloadTable();
        }
    }

    ngOnDestroy(): void {
        this.saveChangesService.unregisterValidator(this);
    }

    checkLocationSelection() {
        if (this.census.LocationPosition && this.census.LocationPosition.C_LocationPosition_key) {
            this.saveChangesService.isLocked = false;
        } else {
            this.saveChangesService.isLocked = true;
        }
    }

    onCancel(): void {
        this.censusService.cancelCensus(this.census);
    }

    async loadMaterialPools(tableState: TableState): Promise<DataResponse> {
        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 5000;
        const sort = tableState.sort || 'MaterialPool.MaterialPoolID ASC';
        const expands: string[] = ['cv_CensusDiscrepancy', 'CensusMaterialPoolMaterial', 'MaterialPool', 'CensusMaterialPoolMaterial.Material.Animal', 'CensusMaterialPoolMaterial.Material.Line'];


        return this.censusService.getCensusMaterialPools({
            page,
            size: pageSize,
            sort,
            filter: {
                C_Census_key: this.census.C_Census_key
            },
            expands
        }).then((response) => {
            this.housings = response.results;
            this.loading = false;
            return {
                results: response.results,
                inlineCount: response.inlineCount
            };
        }).catch((err: Error) => {
            this.loading = false;
            throw err;
        });
    }

    async setLocation() {
        this.loading = true;
        this.census.LocationPosition = this.locationPosition;

        await this.saveChangesService.saveChanges('census', true);
        this.checkLocationSelection();
        this.dataTableComm.reloadTable();

        await this.getSamplesInLocation();
        const samples = uniqueArrayFromPropertyPath(
            this.locationPosition, 'MaterialLocation.Material.Sample'
        );

        if (samples.length > 0) {
            this.loggingService.logWarning(
                'The selected location contains Sample type materials. Only Animal type materials will be displayed in the census record.',
                null,
                this.COMPONENT_LOG_TAG,
                true
            );
        }
    }

    detailLinkClick(itemClicked: any) {
        this.housing = itemClicked;
        this.housingOpened = true;
    }

    async updateSums(openScan: boolean): Promise<void> {
        this.loading = true;
        // discrepancy in animals
        const discrepancyItems = this.housing.CensusMaterialPoolMaterial.filter((censusMaterialPool: any) => {
            return censusMaterialPool.cv_CensusDiscrepancy && censusMaterialPool.cv_CensusDiscrepancy.IsEndState;
        });
        let discrepancySum = discrepancyItems.length;

        // discrepancy in housing
        const housingHasDiscrepancy = this.housing.cv_CensusDiscrepancy && this.housing.cv_CensusDiscrepancy.IsEndState;
        if (housingHasDiscrepancy) {
            discrepancySum += 1;
        }

        if (this.housing.IsCompleted) {
            // if already completed update current sum
            this.housing.DiscrepancyCount = discrepancySum;
            // update census level sum after this
            this.census.SumDiscrepancyCount = this.census.CensusMaterialPool.reduce((acc: any, curr: any) => acc + curr.DiscrepancyCount, 0);
        } else {
            // update original sum and set iscomplete again
            this.housing.OriginalDiscrepancyCount = discrepancySum;
            this.housing.DiscrepancyCount = discrepancySum;
            // update census level sum after this
            this.census.SumOriginalDiscrepancyCount = this.census.CensusMaterialPool.reduce((acc: any, curr: any) => acc + curr.OriginalDiscrepancyCount, 0);
            this.census.SumDiscrepancyCount = this.census.CensusMaterialPool.reduce((acc: any, curr: any) => acc + curr.DiscrepancyCount, 0);
            this.housing.IsCompleted = true;
        }
        await this.saveChangesService.saveChanges(this.COMPONENT_LOG_TAG, true);
        // close curren housing
        this.exitClickd();
        if (openScan) {                
            // show scan modal here
            this.showScanModal();
        }
    }

    async showScanModal() {
        try {
            const housing = await this.censusScanModalService.openCensusScanModal(this.census);
            housing.MaterialPoolScanValue = housing.MaterialPool.MaterialPoolID;
            this.detailLinkClick(housing);
        } catch (error) {
            // Handle errors or do nothing if the modal is closed
            console.error(error);
        }
    }

    getTitle() {
        if (this.census.LocationPosition === null) {
            return 'Confirm the location (building, room, rack, etc.) for which you are creating a census record. Note that once the location is confirmed it is locked and cannot be changed.';
        }
        return 'The location has been confirmed and cannot be edited. Please create a new census record for a different location.';
    }


    private async getCVs() {
        const data = await this.vocabularyService.getCV('cv_CensusStatuses');
        this.censusStatuses = data;
        this.censusStatuses.forEach((status) => {
            if (status.IsActive && status.IsDefault) {
                this.defaultCensusStatusKey = status.C_CensusStatus_key;
            }
        });
    }

    exitClickd() {
        this.loading = true;
        this.housing = null;
        this.housingOpened = false;
    }

    async backClicked() {
        if (this.dataContext.hasChanges()) {
            try {
                await this.confirmAndSaveChanges();
            } catch (error) {
                this.censusService.cancelHousing(this.housing);
                this.exitClickd();
                this.loggingService.logFacetUndoSuccess(this.COMPONENT_LOG_TAG);
            }
        } else {
            this.exitClickd();
        }
    }
    
    private async confirmAndSaveChanges() {
        await this.confirmService.confirm({
            title: "Save Changes",
            message: "Save your changes before continuing?",
            yesButtonText: "Save Changes",
            noButtonText: "Discard Changes"
        });
    
        await this.saveChangesService.saveChanges(this.COMPONENT_LOG_TAG, true);
        this.exitClickd();
    }

    exitClicked(changes: UnsavedChanges) {
        if (changes !== UnsavedChanges.save && this.dataContext.hasChanges()) {
            this.onCancel();
            this.loggingService.logFacetUndoSuccess(this.COMPONENT_LOG_TAG);
        }
        const uncheckedHousings = this.housings.filter((housing: any) => {
            return !housing.IsCompleted;
        }).length;
        if (uncheckedHousings > 0) {
            this.confirmService.confirm(
                {
                    title: "Are you sure you want to exit?",
                    message: `You still have ${uncheckedHousings} housing(s) to check.`
                }
            ).then(
                () => {
                    this.exit.emit();
                },
                () => {
                    // Do nothing and let them continue editing
                });
        } else {
            this.exit.emit();
        }
    }

    async openCageCardModal(cageCardModal: TemplateRef<any>) {
        this.loading = true;
        this.animalsInSelectedRow = [];

        // Fetch all MaterialPoolMaterials
        const promises: Promise<any[]>[] = [];
        this.selectedRows.forEach((censusHouse) => {
            promises.push(this.materialPoolService.getMaterialPoolMaterials(
                censusHouse.C_MaterialPool_key
            ));
        });
        // make sure we have all animals
        await Promise.all(promises);

        // pass animals to the CageCardModal
        this.selectedRows.forEach((censusHouse) => {
            if (censusHouse.MaterialPool.MaterialPoolMaterial.length > 0) {
                const animalsCurrentlyInCage = censusHouse.MaterialPool.MaterialPoolMaterial
                    .filter((animal: any) => !animal.DateOut);

                this.animalsInSelectedRow = this.animalsInSelectedRow
                    .concat(animalsCurrentlyInCage);
            }
        });

        this.modalService.open(cageCardModal);
        this.loading = false;
    }

    viewAuditReport() {
        this.viewCensusAuditReportComponentService.openComponent(
            this.census.C_Census_key
        );
    }

    private async getSamplesInLocation(): Promise<any> {
        const queryDef: QueryDef = {
            filter: {
                locationPositionKey: this.locationPosition.C_LocationPosition_key
            },
            inlineCount: false,
            size: this.MAX_SAMPLES
        };

        return this.locationService.getSamplesInLocation(queryDef);
    }

    // <select> formatters
    censusStatusKeyFormatter = (value: any) => {
        return value.C_CensusStatus_key;
    }
    censusStatusFormatter = (value: any) => {
        return value.CensusStatus;
    }

    async validate() {
        return dateControlValidator(this.dateControls);
    }
}

import type { Material } from './material.interface';
import type { MaterialPool } from './material-pool.interface';
import type { cv_SocialGroupAccessLevel } from './cv-social-group-access-level.interface';

export interface SocialGroupMaterial {
    C_MaterialPool_key: number;
    C_Material_key: number;
    C_SocialGroupAccessLevel_key: number | null;
    C_SocialGroupMaterial_key: number;
    C_Workgroup_key: number;
    CreatedBy: string;
    DateCreated: Date;
    DateIn: Date | null;
    DateModified: Date;
    DateOut: Date | null;
    Material: Material;
    MaterialPool: MaterialPool;
    ModifiedBy: string;
    Version: number;
    cv_SocialGroupAccessLevel: cv_SocialGroupAccessLevel;
}

<div #selectTrigger
     [ngClass]="classes"
     tabindex="0"
     [popoverTriggerFor]="selectBody"
     [popoverOptions]="popoverOptions"
     popoverOpenWithKeyArrows
     (toggle)="onToggle($event)">
    <div class="value" [ngClass]="{ 'selected': selectedOption }">
        {{ selectedValue }}
    </div>
    <svg class="select-arrow" [climbIcon]="isOpen ? icons.chevronUp : icons.chevronDown" size="sm"></svg>
</div>

<climb-popover #selectBody>
    <div class="select-body-wrapper" [ngStyle]="{'min-width.px': minSelectContentWidth}">
        <ng-content></ng-content>
    </div>
</climb-popover>

import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { Component } from '@angular/core';
import { auditLinkMap } from './audit-link/audit-link-map';
import { AuditService } from '../audit.service';
import { AuditGridView } from '../models/audit-grid-view.enum';
import { TranslationService } from '@services/translation.service';

export enum ModifiedFieldView {
    TEXT, 
    LINK
}

@Component({
    selector: 'audit-modified-field',
    templateUrl: "audit-modified-field.component.html",
    styleUrls: ['audit-modified-field.component.scss']
})
export class AuditModifiedFieldComponent implements ICellRendererAngularComp {
    public value = '';
    public tooltipText = '';
    public isShowTooltip = false;
    public modifiedFieldView: ModifiedFieldView = ModifiedFieldView.TEXT;

    public modifiedFields: string[] = [];

    readonly MODIFIED_FIELD_VIEWS = ModifiedFieldView;

    constructor(private auditService: AuditService, private translationService: TranslationService) {}

    agInit(params: ICellRendererParams): void {
        this.parseValue(params);
    }

    /**
     * Required by ICellRenderedAngularComp.
     *   Called on cell refresh by ag-grid
     * @param params
     */
    refresh(params: ICellRendererParams): boolean {
        this.parseValue(params);
        return true;
    }

    private parseValue(params: ICellRendererParams): void {
        if (params.data?.UpdateType?.toLowerCase() === 'deleted') {
            this.value = '';
            this.isShowTooltip = false;
            return;
        }

        if (params.value?.toLowerCase() === 'previous value outside date range') {
            const TOOLTIP_TEXT = 'The Modified Date of the previous record is outside of the filtered range.\nPlease extend the Modified Date Range to start at an earlier date.';

            this.value = params.value;
            this.tooltipText = TOOLTIP_TEXT;
            this.isShowTooltip = true;

            return;
        }
        
        if (this.doesValueContainAuditLink(params.value)) {
            this.modifiedFields = params.value?.split(",").map((field: string) => field.trim());
            this.modifiedFieldView = ModifiedFieldView.LINK;
            return;
        }
    
        if (params.value?.includes("ReasonForChange")) {
            this.value = params.value?.replace("ReasonForChange", "").slice(0, -2);
            this.isShowTooltip = false;
            return;
        }

        this.value = params.value;
        this.isShowTooltip = false;
    }

    private doesValueContainAuditLink(value: string): boolean {
        if (value) {
            const valueLowerCase = value.toLowerCase();
            const keys = Object.keys(auditLinkMap);
            const hasAuditLink = keys.some((k: string) => valueLowerCase.includes(k));
            const currentGridView = this.auditService.getCurrentGridView();
            /* we have different types of locations (Housing location, Order location), but they are tracked under same name.
               to avoid misleading hyperlinks, we show only links to housing locations.
               TODO: we should fix audit links generation logic later.
            * */
            if (valueLowerCase === 'location' && currentGridView !== AuditGridView.Housing){
                return false;
            }
            const isValueContainLine = value.includes(this.translationService.translate('Line'));
            if (currentGridView === AuditGridView.Jobs && isValueContainLine) {
                return true;
            } else if (isValueContainLine && currentGridView !== AuditGridView.Jobs) {
                return false;
            } else {
                return hasAuditLink;
            }
        } else {
            return false;
        }

    }
}

import { Directive, ElementRef, OnDestroy } from '@angular/core';
import { DragDrop, DragRef } from '@angular/cdk/drag-drop';
import type { ContainerDropList, ItemDrag } from './drag-drop.interface';

@Directive()
export class BaseDragDirective implements ItemDrag, OnDestroy {
    dragRef: DragRef<ItemDrag> = null;

    constructor(
        protected dropContainer: ContainerDropList,
    ) { }

    ngOnDestroy(): void {
        if (this.dropContainer) {
            this.dropContainer.removeItem(this);
        }

        this.dragRef?.dispose();
    }

    initDrag(dragDrop: DragDrop, element: HTMLElement | ElementRef<HTMLElement>): void {
        this.dragRef = dragDrop.createDrag(element, {
            dragStartThreshold: 5,
            pointerDirectionChangeThreshold: 5,
        });

        this.dragRef.data = this;

        if (this.dropContainer) {
            this.dragRef._withDropContainer(this.dropContainer.dropListRef);
            this.dropContainer.addItem(this);
        }
    }
}

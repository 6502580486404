// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import * as PlotlyJS from 'plotly.js/lib/core';
import * as bar from 'plotly.js/lib/bar';
import * as histogram from 'plotly.js/lib/histogram';
import { PlotlyModule as AngularPlotlyModule } from 'angular-plotly.js';

PlotlyJS.register([
    bar,
    histogram,
]);

AngularPlotlyModule.plotlyjs = PlotlyJS;

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        AngularPlotlyModule,
    ],
    exports: [
        AngularPlotlyModule,
    ],
})
export class PlotlyModule { }

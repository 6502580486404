import {
    AfterViewInit,
    Component,
    Input,
    OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core';
import {
    NgbModal,
    NgbNav,
    NgbNavChangeEvent,
} from '@ng-bootstrap/ng-bootstrap';

import { CharacteristicService } from './characteristic.service';
import { CharacteristicVocabService } from './characteristic-vocab.service';
import { JobService } from '../jobs/job.service';
import { WorkspaceFilterService } from '../services/workspace-filter.service';
import { DataContextService } from '@services/data-context.service';

import {
    CharacteristicCollection,
    CharacteristicTypeNameEnum
} from './models';

import {
    randomId,
    testBreezeIsDeleted,
    filterToDate
} from '../common/util';
import { BaseFacet, BaseFacetService, IFacet } from '../common/facet';
import { CharacteristicsFilterComponent } from './characteristics-filter/characteristics-filter.component';
import { Subject, Subscription } from 'rxjs';
import { arrowClockwise, magnifier } from '@icons';
import type { Characteristic, EditCharacteristic, EditResult } from './characteristics.interface';
import { getPageCount } from '@common/components/paginator/paginator.util';
import { notReachable } from '@common/util/not-reachable.util';

@Component({
    selector: 'characteristics-facet',
    templateUrl: './characteristics-facet.component.html',
    styleUrls: ['./characteristics-facet.component.scss'],
    providers: BaseFacet.BASE_COMPONENT_PROVIDERS
})
export class CharacteristicsFacetComponent extends BaseFacet implements OnInit, AfterViewInit, OnDestroy {
    @Input() facetId: string;
    @Input() facet: IFacet;

    @ViewChild('gridTabs') gridTabs: NgbNav;

    readonly icons = { arrowClockwise, magnifier };

    // Feature flags
    isCRO = false;
    isCRL = false;

    componentFilterSubscription: Subscription;
    // Data
    characteristics = new CharacteristicCollection<any[]>();
    // Keep initial state of the characteristic items to use it when user discards global changes
    initCharacteristics = new CharacteristicCollection<Characteristic[]>();
    currentCharacteristic: EditCharacteristic;
    totalTaxonCount: number;
    totalSampleCount: number;
    totalJobCount: number;
    totalStudyCount: number;

    // State
    activeGridTabId: string;
    allowEdit: boolean;
    domIdAddition: string;
    isEditing: boolean;
    editTypeName: string;
    filter: any;
    loading: any;
    currentTypeName: CharacteristicTypeNameEnum = CharacteristicTypeNameEnum.Taxon;
    currentPageIndex = 0;

    // Export enum to template
    CharacteristicTypeNameEnum = CharacteristicTypeNameEnum;

    readonly COMPONENT_LOG_TAG = 'characteristics-facet';
    pageSize = 50;

    private notifier$ = new Subject<void>();
    private subscriptions: Subscription = new Subscription();

    constructor(
        private characteristicService: CharacteristicService,
        private characteristicVocabService: CharacteristicVocabService,
        private modalService: NgbModal,
        private jobService: JobService,
        private baseFacetService: BaseFacetService,
        private dataContextService: DataContextService,
        workspaceFilterService: WorkspaceFilterService
    ) {
        super(
            baseFacetService,
            workspaceFilterService
        );
    }

    ngOnInit() {
        this.allowEdit = (this.facet.Privilege === 'ReadWrite');

        this.domIdAddition = randomId();

        this.initialize();

        this.handleGlobalSaveChanges();
    }

    async initialize() {
        this.isCRO = this.jobService.getIsCroFlag();
        this.isCRL = this.jobService.getIsCrlFlag();
        this.resetCharacteristicsState();
        this.resetFilterState();
        this.switchView();
        await this.onPageChange(0, this.currentTypeName);
        await this.characteristicVocabService.reloadCache();
    }

    ngAfterViewInit() {
        this.activeGridTabId = this.gridTabs.activeId;
    }

    resetCharacteristicsState() {
        this.characteristics.taxon = [];
        this.characteristics.sample = [];
        this.characteristics.job = [];
        this.characteristics.study = [];
    }

    resetFilterState() {
        if (this.facet && this.facet.GridFilter) {
            try {
                this.filter = JSON.parse(this.facet.GridFilter);
            } catch (err) {
                console.error(err);
            }
            if (!this.filter) {
                this.filter = {};
            }
            if (this.filter) {
                this.filter.DateLastReviewedStart = filterToDate(this.filter.DateLastReviewedStart);
                this.filter.DateLastReviewedEnd = filterToDate(this.filter.DateLastReviewedEnd);
            }
        }
    }

    getTaxonCharacteristics(numSkip: number): Promise<void> {
        return this.characteristicService
            .getTaxonCharacteristics(this.filter, true, numSkip, this.pageSize).then((data) => {
                this.characteristics.taxon = data.results;
                this.initCharacteristics.taxon = data.results;
                this.totalTaxonCount = data.inlineCount;
            });
    }

    getSampleCharacteristics(numSkip: number): Promise<void> {
        return this.characteristicService
            .getSampleCharacteristics(this.filter, numSkip, this.pageSize).then((data) => {
                this.characteristics.sample = data.results;
                this.initCharacteristics.sample = data.results;
                this.totalSampleCount = data.inlineCount;
            });
    }

    getJobCharacteristics(numSkip: number): Promise<void> {
        return this.characteristicService
            .getJobCharacteristics(this.filter, numSkip, this.pageSize).then((data) => {
                this.characteristics.job = data.results;
                this.initCharacteristics.job = data.results;
                this.totalJobCount = data.inlineCount;
            });
    }

    getStudyCharacteristics(numSkip: number): Promise<void> {
        return this.characteristicService
            .getStudyCharacteristics(this.filter, numSkip, this.pageSize).then((data) => {
                this.characteristics.study = data.results;
                this.initCharacteristics.study = data.results;
                this.totalStudyCount = data.inlineCount;
            });
    }

    // View and Tab Switching
    editBegin(type: CharacteristicTypeNameEnum, characteristic: EditCharacteristic) {
        this.isEditing = true;
        this.editTypeName = type;
        this.currentCharacteristic = characteristic;
    }

    async editEnd({ isSaved }: EditResult) {
        this.removeDeletedCharacteristicsFromAllViews();
        this.switchView();

        const isNewEntity = this.currentCharacteristic.isNewEntity && isSaved;
        const pageIndex = isNewEntity
            ? getPageCount(this.getListTotalCount() + 1, this.pageSize) - 1 // last page index
            : this.currentPageIndex;

        await this.onPageChange(pageIndex, this.currentTypeName);
        this.gridTabs.select(this.activeGridTabId);
    }

    private getListTotalCount(): number {
        switch (this.currentTypeName) {
            case CharacteristicTypeNameEnum.Job: return this.totalJobCount;
            case CharacteristicTypeNameEnum.Sample: return this.totalSampleCount;
            case CharacteristicTypeNameEnum.Study: return this.totalStudyCount;
            case CharacteristicTypeNameEnum.Taxon: return this.totalTaxonCount;
            default: notReachable(this.currentTypeName);
        }
    }

    async onNavChange(event: NgbNavChangeEvent) {
        this.activeGridTabId = event.nextId;
        this.switchView();
        const tabName = this.activeGridTabId.substring(0, this.activeGridTabId.indexOf("-tab"));
        const typeNameMap: Record<string, CharacteristicTypeNameEnum> = {
            animals: CharacteristicTypeNameEnum.Taxon,
            samples: CharacteristicTypeNameEnum.Sample,
            jobs: CharacteristicTypeNameEnum.Job,
            studies: CharacteristicTypeNameEnum.Study,
        };
        this.currentTypeName = typeNameMap[tabName] ?? CharacteristicTypeNameEnum.Taxon;
        await this.onPageChange(0, this.currentTypeName);
    }

    switchView() {
        this.isEditing = false;
        this.editTypeName = '';
    }


    // Handle deletes
    private removeDeletedCharacteristicsFromAllViews() {
        this.removeDeletedCharacteristics(CharacteristicTypeNameEnum.Job);
        this.removeDeletedCharacteristics(CharacteristicTypeNameEnum.Sample);
        this.removeDeletedCharacteristics(CharacteristicTypeNameEnum.Study);
        this.removeDeletedCharacteristics(CharacteristicTypeNameEnum.Taxon);
    }

    private removeDeletedCharacteristics(type: string) {
        this.characteristics[type] = this.characteristics[type].filter((characteristic: any) => {
            return !testBreezeIsDeleted(characteristic);
        });
    }


    // Filter
    openFilter() {
        const ref = this.modalService.open(CharacteristicsFilterComponent);
        const component = ref.componentInstance as CharacteristicsFilterComponent;
        component.filter = this.filter;
        component.isCRO = this.isCRO;
        component.typeName = this.currentTypeName;

        this.componentFilterSubscription = component.onFilter.subscribe((filter: any) => {
            this.filter = filter;
            this.runFilter();
            this.refresh();
        });
    }

    async runFilterModel(newFilter: any) {
        this.filter = newFilter;
        await this.onPageChange(0, this.currentTypeName);
        this.runFilter();
    }

    refresh() {
        // trigger pagination reset in characteristic list view
        this.currentPageIndex = 0;
        this.initialize();
    }

    async onPageChange(pageIndex: number, type: CharacteristicTypeNameEnum): Promise<void> {
        this.loading = true;
        this.currentPageIndex = pageIndex;
        const numSkip = pageIndex * this.pageSize;
        switch (type) {
            case CharacteristicTypeNameEnum.Taxon:
                await this.getTaxonCharacteristics(numSkip).finally(() => {
                    this.loading = false;
                });
                break;
            case CharacteristicTypeNameEnum.Sample:
                await this.getSampleCharacteristics(numSkip).finally(() => {
                    this.loading = false;
                });
                break;
            case CharacteristicTypeNameEnum.Job:
                await this.getJobCharacteristics(numSkip).finally(() => {
                    this.loading = false;
                });
                break;
            case CharacteristicTypeNameEnum.Study:
                await this.getStudyCharacteristics(numSkip).finally(() => {
                    this.loading = false;
                });
                break;
            default:
                console.error("Invalid characteristic type: " + type);
                break;
        }
    }

    handleGlobalSaveChanges(): void {
        const changesDiscardedSubscription = this.dataContextService.onCancel$.subscribe(() => {
            this.onRowChanged(this.initCharacteristics.taxon ?? [], CharacteristicTypeNameEnum.Taxon);
            this.onRowChanged(this.initCharacteristics.sample ?? [], CharacteristicTypeNameEnum.Sample);
            this.onRowChanged(this.initCharacteristics.job ?? [], CharacteristicTypeNameEnum.Job);
            this.onRowChanged(this.initCharacteristics.study ?? [], CharacteristicTypeNameEnum.Study);
        });
        this.subscriptions.add(changesDiscardedSubscription);

        const changesSavedSubscription = this.dataContextService.changesSaved$.subscribe(() => {
            this.initCharacteristics.taxon = [...this.characteristics.taxon];
            this.initCharacteristics.sample = [...this.characteristics.sample];
            this.initCharacteristics.job = [...this.characteristics.job];
            this.initCharacteristics.study = [...this.characteristics.study];
        });
        this.subscriptions.add(changesSavedSubscription);
    }

    onRowChanged(characteristics: Characteristic[], characteristicType: CharacteristicTypeNameEnum): void {
        // soft data reload
        switch (characteristicType) {
            case CharacteristicTypeNameEnum.Taxon:
                this.characteristics.taxon = [...characteristics];
                break;
            case CharacteristicTypeNameEnum.Sample:
                this.characteristics.sample = [...characteristics];
                break;
            case CharacteristicTypeNameEnum.Job:
                this.characteristics.job = [...characteristics];
                break;
            case CharacteristicTypeNameEnum.Study:
                this.characteristics.study = [...characteristics];
                break;
            default: notReachable(characteristicType);
        }
    }

    ngOnDestroy(): void {
        this.notifier$.next();
        this.notifier$.complete();
        this.subscriptions.unsubscribe();
    }
}

<climb-facet-toolbar>
    <ng-content></ng-content>

    <climb-export-dropdown #exportDropdown
                           [options]="options"
                           [dataService]="dataService"
                           componentName="audit">
    </climb-export-dropdown>

    <climb-column-select [columnDef]="dataTableColumns$ | async"
                         (onChange)="dataTable.columnSelectChanged($event)">
    </climb-column-select>
</climb-facet-toolbar>
<climb-data-table #dataTable
                  [dataService]="dataService"
                  [options]="options"
                  (columnStateChanged)="dataTableColumns.next($event)"
                  (stateChange)="exportDropdown.tableState = $event">
</climb-data-table>

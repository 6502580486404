import { Injectable } from '@angular/core';

import { TranslationService } from '../services/translation.service';
import { UserNameService } from '../user/user-name.service';
import { CsvExporter } from '../common/export/csv-exporter';
import { getSafeProp } from '../common/util/get-safe-prop';
import { sortObjectArrayByProperty } from '../common/util/sort-array';
import { DateFormatterService } from '@common/util/date-time-formatting';

/*
* Export a study detail record to CSV
*/
@Injectable()
export class ExportStudyDetailService {

    csvExporter: CsvExporter;
    studyLabel: string;

    constructor(
        private translationService: TranslationService,
        private userNameService: UserNameService,
        private dateFormatterService: DateFormatterService,
    ) {
        this.csvExporter = new CsvExporter();

        this.studyLabel = this.translationService.translate('Study');
    }

    /*
    * Assumes study record has all relationships loaded
    */
    exportToCsv(study: any) {
        const EXPORTER_CSV_FILENAME = this.studyLabel + "Details.csv";
        const data: any[][] = this.buildExportData(study);
        this.csvExporter.download(data, EXPORTER_CSV_FILENAME);
    }

    buildExportData(study: any): any[][] {
        const data: any[][] = [];

        // Basic data
        data.push.apply(data, [
            [
                this.studyLabel + ' Name',
                study.StudyName
            ],
            [
                this.studyLabel + ' Type',
                getSafeProp(study, 'cv_StudyType.StudyType')
            ],
            [
                'Active',
                study.IsActive
            ],
            [
                this.studyLabel + ' Status',
                getSafeProp(study, 'cv_StudyStatus.StudyStatus')
            ],
            ['Approval Number', study.ApprovalNumber],
            ['Description', study.Description],
            ['Comments', study.Comments],
            ['Total Animals', study.totalAnimalCount],
            ['Available Animals', study.availableAnimalCount],
            ['Created By', this.userNameService.toFullName(study.CreatedBy)],
            ['Created Date', this.dateFormatterService.formatDateOnly(study.DateCreated)]
        ]);

        // Study characteristics
        for (const studyCharacteristicInstance of sortObjectArrayByProperty(
            study.StudyCharacteristicInstance, 'StudyCharacteristic.SortOrder')
        ) {
            data.push([studyCharacteristicInstance.CharacteristicName,
            studyCharacteristicInstance.CharacteristicValue]);
        }

        // Study lines
        data.push.apply(data, [[], [this.translationService.translate('Lines')], [this.translationService.translate('Line') + ' Name', 'Animal Count']]);
        for (const studyLine of study.StudyLine) {
            data.push([getSafeProp(studyLine, 'Line.LineName'),
            studyLine.AnimalCount]);
        }

        return data;
    }
}

import {
    Component,
    Input,
    OnInit,
    OnDestroy,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { 
    BaseFacet,
    BaseFacetService 
} from '../common/facet';

import {
    TableState,
    DataResponse,
    CellFormatterService,
    ColumnsState,
    TableColumnDef,
} from '@common/datatable';
import { WorkspaceFilterService } from '../services/workspace-filter.service';

import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { CensusTableOptions } from './census-table-options';
import { CensusFilterComponent } from './census-filter.component';
import { CensusService } from './census.service';
import { DataContextService } from '../services/data-context.service';
import { filterToDate } from '../common/util';
import { arrowClockwise, magnifier } from '@icons';

@Component({
    selector: 'census-facet',
    templateUrl: './census-facet.component.html',
    providers: BaseFacet.BASE_COMPONENT_PROVIDERS
})
export class CensusFacetComponent extends BaseFacet
    implements OnInit, OnDestroy {
    @Input() facetId: string;
    @Input() facet: any;

    readonly icons = { arrowClockwise, magnifier };

    componentName = 'census';

    censusTableOptions: CensusTableOptions;

    readonly COMPONENT_LOG_TAG = 'census-facet';
    componentFilterSubscription: Subscription;

    dataTableColumns: BehaviorSubject<TableColumnDef[]>;
    dataTableColumns$: Observable<TableColumnDef[]>;

    constructor(
        private modalService: NgbModal,
        workspaceFilterService: WorkspaceFilterService,
        private censusService: CensusService,
        private baseFacetService: BaseFacetService,
        private cellFormatterService: CellFormatterService,
        private dataContext: DataContextService
    ) {
        super(
            baseFacetService,
            workspaceFilterService
        );

        this.censusTableOptions = new CensusTableOptions(cellFormatterService);

        this.dataTableColumns = new BehaviorSubject(this.censusTableOptions.options.columns);
        this.dataTableColumns$ = this.dataTableColumns.asObservable();

        this.dataService = {
            run: (tableState: TableState) => {
                return this.loadCensusList(tableState);
            }
        };
    }

    // lifecycle
    ngOnInit() {
        super.ngOnInit();
        this.initialize();
    }

    ngOnDestroy() {
        if (this.componentFilterSubscription) {
            this.componentFilterSubscription.unsubscribe();
        }
    }

    initialize() {
        this.restoreFilterState();
        this.changeView(this.LIST_VIEW);
    }

    refreshData() {
        this.initialize();
        this.dataTableComm.reloadTable();
    }

    restoreFilterState() {

        // process any grid filters
        if (this.facet && this.facet.GridFilter) {
            try {
                this.filter = JSON.parse(this.facet.GridFilter);
            } catch (err) {
                console.error(err);
            }

            if (this.filter) {
                this.filter.DateStart = filterToDate(this.filter.DateStart);
                this.filter.DateEnd = filterToDate(this.filter.DateEnd);
            } else {
                this.filter = {};
            }
        }
    }

    async loadCensusList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'DateCreated DESC';
        const expands: any = ['LocationPosition'];

        this.setLoadingState(tableState.loadingMessage);

        try {
            const response = await this.censusService.getCensuses({
                page,
                size: pageSize,
                sort,
                filter: this.getActiveFilter(),
                expands
            });

            const visibleColumns = this.getVisibleColumns(this.censusTableOptions.options);
            await this.censusService.ensureVisibleColumnsDataLoaded(response.results, visibleColumns);

            this.stopLoading();

            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        } finally {
            this.stopLoading();
        }
    }

    addItemClick() {
        this.setLoadingState();
        this.createNewCensus().then((census) => {
            this.stopLoading();
            this.itemToEdit = census;
            this.changeView(this.DETAIL_VIEW);
        }).catch((error) => {
            this.loading = false;
            this.loggingService.logError("An unexpected error occurred. Please try again", error, this.componentName, true);
        });
    }

    async createNewCensus(): Promise<any> {
        const newCensus = this.censusService.create();
        return Promise.resolve(newCensus);
    }

    openFilter() {
        const ref = this.modalService.open(CensusFilterComponent);
        const component = ref.componentInstance as CensusFilterComponent;
        component.filter = this.filter;
        this.componentFilterSubscription = component.onFilter.subscribe((filter: any) => {
            this.filter = filter;
            this.runFilter();
        });
    }

    selectedRowsChange(rows: any[]) {
        // nothing yet
    }

    async selectedColumnsChange({ visible }: ColumnsState) {
        try {
            this.facetLoadingState.changeLoadingState(true);
            await this.censusService.ensureVisibleColumnsDataLoaded(this.data, visible);
        } finally {
            this.facetLoadingState.changeLoadingState(false);
        }
    }
}


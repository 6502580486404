import { Component, Input, OnChanges, OnInit, Output, SimpleChanges, EventEmitter } from "@angular/core";
import { EntityQuery, Predicate } from "breeze-client";
import {
        Animal,
        cv_SampleType,
        cv_StudyType,
        cv_Taxon,
        Sample,
        SampleCharacteristic,
        SampleCharacteristicInstance,
        Study,
        StudyCharacteristic,
        StudyCharacteristicInstance,
        TaxonCharacteristic,
        TaxonCharacteristicInstance
    } from "../../common/types";
import { arrayDifference } from "../../common/util";
import { AuthService } from "../../services/auth.service";
import { DataManagerService } from "../../services/data-manager.service";
import { CharacteristicTypeNameEnum } from "../models";

export type CharacteristicInstance = TaxonCharacteristicInstance | SampleCharacteristicInstance | StudyCharacteristicInstance;
export type CharacteristicSignalType = cv_Taxon & cv_SampleType & cv_StudyType;
export type CharacteristicParentEntity = Animal & Sample & Study;

/**
 * A Generic Characteristic Instance List. This is intended for using in Animal, Sample, and Study characteristics.
 * The Jobs Characteristic Logic is complex and requires extra logic to occur on the JobPharmaDetailsComponent, so it is not included in this component. 
 */
@Component({
    selector: 'characteristic-instance-list',
    templateUrl: './characteristic-instance-list.component.html'
})
export class CharacteristicInstanceListComponent implements OnInit, OnChanges {
    // When this value changes, the characteristic instances will update on `model`
    @Input() signalValue: CharacteristicSignalType;
    @Input() characteristicType: CharacteristicTypeNameEnum;
    @Input() model: CharacteristicParentEntity;
    @Input() hasAllCharacteristics: boolean;

    @Input() readonly: boolean;

    @Output() updateRefreshNeeded: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() characteristicRefresh: EventEmitter<void> = new EventEmitter<void>();

    readonly CHARACTERISTIC_TYPE_NAMES = CharacteristicTypeNameEnum;

    characteristics: CharacteristicInstance[];
    sortOrderPath: string;
    characteristicEntityName: string;

    currentUsername: string;

    constructor(
        private dataManagerService: DataManagerService,
        private authService: AuthService
    ) {

    }

    ngOnInit() {
        this.setCharacteristics();
        this.sortOrderPath = this.getSortOrderPath();
        this.characteristicEntityName = this.getCharacteristicEntityName();
        this.currentUsername = this.authService.getCurrentUserName();

    }

    ngOnChanges(changes: SimpleChanges) {
        // check if the signal value has changed since it was first initialized. 
        if (changes.signalValue && !changes.signalValue.isFirstChange()) {
            this.updateCharacteristicInstances();
        }
    }

    /**
     * Executes a function based the characteristic type.
     * Only applicable for functions with void return types. 
     * @param taxonFunc
     * @param sampleFunc
     * @param jobFunc
     * @param studyFunc
     */
    characteristicsSwitch(taxonFunc: () => void, sampleFunc: () => void,  studyFunc: () => void) {
        switch (this.characteristicType) {
            case CharacteristicTypeNameEnum.Taxon:
                taxonFunc.apply(this);
                break;
            case CharacteristicTypeNameEnum.Sample:
                sampleFunc.apply(this);
                break;
            case CharacteristicTypeNameEnum.Study:
                studyFunc.apply(this);
                break;
            default:
                throw new Error("Error: Characteristic Type not implemented for " + this.characteristicType);
        }
    }

    setCharacteristics(): void {
        this.characteristicsSwitch(
            this.setTaxonCharacteristics,
            () => this.characteristics = this.model.SampleCharacteristicInstance,
            () => this.characteristics = this.model.StudyCharacteristicInstance
        );
    }

    getSortOrderPath(): string {
        if (this.characteristicType === CharacteristicTypeNameEnum.Taxon
            || this.characteristicType === CharacteristicTypeNameEnum.Sample
            || this.characteristicType === CharacteristicTypeNameEnum.Study) {
            return `${this.getCharacteristicEntityName()}.SortOrder`;
        } else {
            throw new Error("Error: Characteristic Type not implemented for " + this.characteristicType);
        }
    }

    private getCharacteristicEntityName(): string {
        return `${this.characteristicType.charAt(0).toUpperCase()}${this.characteristicType.substring(1)}Characteristic`;
    }

    updateCharacteristicInstances(): void {
        this.characteristicsSwitch(
            this.updateTaxonCharacteristicInstances,
            this.updateSampleCharacteristicInstances,
            this.updateStudyCharacteristicInstances
        );
    }

    updateTaxonCharacteristicInstances(): Promise<TaxonCharacteristicInstance[]> {
        while (this.model.TaxonCharacteristicInstance.length > 0) {
            this.dataManagerService.deleteEntity(this.model.TaxonCharacteristicInstance[0]);
        }

        if (!this.signalValue || !this.signalValue.C_Taxon_key) {
            return;
        }

        const predicates = [];
        predicates.push(Predicate.create("IsActive", "eq", true));
        predicates.push(Predicate.create("TaxonCharacteristicTaxon", "any", "C_Taxon_key", "eq", this.signalValue.C_Taxon_key));
        const query = EntityQuery.from("TaxonCharacteristics")
            .expand("TaxonCharacteristicTaxon,cv_DataType")
            .where(Predicate.and(predicates))
            .orderBy("SortOrder");

        return this.dataManagerService.returnQueryResults(query).then((results: TaxonCharacteristic[]) => {
            const newCharacteristicInstances: TaxonCharacteristicInstance[] = [];
            for (const characteristic of results) {
                const newCharacteristicInstance = this.dataManagerService.createEntity("TaxonCharacteristicInstance", {
                    C_TaxonCharacteristic_key: characteristic.C_TaxonCharacteristic_key,
                    C_Material_key: this.model.C_Material_key,
                    DateCreated: new Date(),
                    CharacteristicName: characteristic.CharacteristicName,
                    Description: characteristic.Description
                });
                newCharacteristicInstances.push(newCharacteristicInstance);
            }
            this.characteristics = this.model.TaxonCharacteristicInstance;
            return newCharacteristicInstances;
        });
    }

    updateSampleCharacteristicInstances(): Promise<SampleCharacteristicInstance[]> {
        while (this.model.SampleCharacteristicInstance.length > 0) {
            this.dataManagerService.deleteEntity(this.model.SampleCharacteristicInstance[0]);
        }

        if (!this.signalValue || !this.signalValue.C_SampleType_key) {
            return;
        }

        const predicates = [];
        predicates.push(Predicate.create("SampleCharacteristicSampleType", "any", "C_SampleType_key", "eq", this.signalValue.C_SampleType_key));
        const query = EntityQuery.from("SampleCharacteristics")
            .expand("SampleCharacteristicSampleType,cv_DataType")
            .where(Predicate.and(predicates))
            .orderBy("SortOrder");
        return this.dataManagerService.returnQueryResults(query).then((results: SampleCharacteristic[]) => {
            const newCharacteristicInstances: SampleCharacteristicInstance[] = [];
            for (const characteristic of results)
            {
                const newCharacteristicInstance = this.dataManagerService.createEntity("SampleCharacteristicInstance", {
                    C_SampleCharacteristic_key: characteristic.C_SampleCharacteristic_key,
                    C_Material_key: this.model.C_Material_key,
                    DateCreated: new Date(),
                    CharacteristicName: characteristic.CharacteristicName,
                    Description: characteristic.Description
                });
                newCharacteristicInstances.push(newCharacteristicInstance);
            }
            this.characteristics = this.model.SampleCharacteristicInstance;
            return newCharacteristicInstances;
        });
    }


    updateStudyCharacteristicInstances(): Promise<StudyCharacteristicInstance[]> {
        while (this.model.StudyCharacteristicInstance.length > 0) {
            this.dataManagerService.deleteEntity(this.model.StudyCharacteristicInstance[0]);
        }

        if (!this.signalValue || !this.signalValue.C_StudyType_key) {
            return;
        }

        const predicates = [];
        predicates.push(Predicate.create("StudyCharacteristicStudyType", "any", "C_StudyType_key", "eq", this.signalValue.C_StudyType_key));
        const query = EntityQuery.from("StudyCharacteristics")
            .expand("StudyCharacteristicStudyType, cv_DataType")
            .where(Predicate.and(predicates))
            .orderBy("SortOrder");

        return this.dataManagerService.returnQueryResults(query).then((results: StudyCharacteristic[]) => {
            const newCharacteristicInstances: StudyCharacteristicInstance[] = [];
            for (const characteristic of results) {
                const newCharacteristicInstance = this.dataManagerService.createEntity("StudyCharacteristicInstance", {
                    C_StudyCharacteristic_key: characteristic.C_StudyCharacteristic_key,
                    C_Study_key: this.model.C_Study_key,
                    DateCreated: new Date(),
                    CharacteristicName: characteristic.CharacteristicName,
                    Description: characteristic.Description
                });
                newCharacteristicInstances.push(newCharacteristicInstance);
            }
            this.characteristics = this.model.StudyCharacteristicInstance;
            return newCharacteristicInstances;
        });
    }

    /**
     * Get existing characteristics based on the taxon, then check against the model's characteristics
     */
    async setTaxonCharacteristics(): Promise<void> {
        if (!this.signalValue || !this.signalValue.C_Taxon_key) {
            this.characteristics = this.model.TaxonCharacteristicInstance;
            return;
        }

        const taxonCharacteristicPredicates = [];
        taxonCharacteristicPredicates.push(Predicate.create("IsActive", "eq", true));
        taxonCharacteristicPredicates.push(Predicate.create("TaxonCharacteristicTaxon", "any", "C_Taxon_key", "eq", this.signalValue.C_Taxon_key));
        const taxonCharacteristicQuery = EntityQuery.from("TaxonCharacteristics")
            .expand("TaxonCharacteristicTaxon")
            .where(Predicate.and(taxonCharacteristicPredicates))
            .select("C_TaxonCharacteristic_key")
            .noTracking(true);
        const taxonCharacteristicsFromTaxon = await this.dataManagerService.returnQueryResults(taxonCharacteristicQuery, false);

        const taxonCharacteristicKeysFromTaxon = taxonCharacteristicsFromTaxon.map((tc: TaxonCharacteristic) => tc.C_TaxonCharacteristic_key);

        const animalQuery = EntityQuery.from("TaxonCharacteristicInstances")
            .noTracking(true)
            .where("C_Material_key", "==", this.model.C_Material_key)
            .select("C_TaxonCharacteristic_key");

        const animalCharacteristicInstances = await this.dataManagerService.returnQueryResults(animalQuery, false);

        const taxonCharacteristicKeysFromInstance = animalCharacteristicInstances.map((tci: TaxonCharacteristicInstance) => tci.C_TaxonCharacteristic_key);
        const difference = arrayDifference(taxonCharacteristicKeysFromTaxon, taxonCharacteristicKeysFromInstance);
        this.updateRefreshNeeded.emit(difference.length > 0);

        const traversed = {};

        this.characteristics = this.model.TaxonCharacteristicInstance
            .filter((tci: TaxonCharacteristicInstance) => {
                const included = (taxonCharacteristicKeysFromTaxon.includes(tci.C_TaxonCharacteristic_key) && !traversed[tci.C_TaxonCharacteristic_key]) || tci.CharacteristicValue !== null;
                // do not count characteristic instances with values to be considered traversed. 
                traversed[tci.C_TaxonCharacteristic_key] = tci.CharacteristicValue === null;
                return included;
            });

    }
}

import type { EnumerationClass } from './enumeration-class.interface';
import type { TaxonCharacteristicInstance } from './taxon-characteristic-instance.interface';
import type { cv_DataType } from './cv-data-type.interface';
import type { cv_Unit } from './cv-unit.interface';
import type { TaxonCharacteristicTaxon } from './taxon-characteristic-taxon.interface';

export interface TaxonCharacteristic {
    C_DataType_key: number;
    C_EnumerationClass_key: number | null;
    C_TaxonCharacteristic_key: number;
    C_Unit_key: number | null;
    C_Workgroup_key: number;
    CharacteristicName: string;
    CreatedBy: string;
    DateCreated: Date;
    DateLastReviewed: Date | null;
    DateModified: Date;
    Description: string;
    EnumerationClass: EnumerationClass;
    HasTaxon: boolean;
    Species: string;
    IsActive: boolean;
    ModifiedBy: string;
    NumericScale: number | null;
    SortOrder: number | null;
    ListViewOrder: number | null;
    TaxonCharacteristicInstance: TaxonCharacteristicInstance[];
    TextLineCount: number | null;
    ValidationMaximum: string;
    ValidationMinimum: string;
    Version: number;
    cv_DataType: cv_DataType;
    cv_Unit: cv_Unit;
    TaxonCharacteristicTaxon: TaxonCharacteristicTaxon[];
}

import { Pipe, PipeTransform } from '@angular/core';

import { daysSinceAsString } from '../util';

/**
 * Returns the number of days between a date and today
 */
@Pipe({ name: 'daysFromToday' })
export class DaysFromTodayPipe implements PipeTransform {

    transform(value: Date): string {
        if (!value) {
            return '';
        }

        const today = new Date();
        return daysSinceAsString(today, value);
    }
}

import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '@services/auth.service';
import { buildId } from '@config';
import { keepErrorValidator, matchValidator } from '@common/validators';
import { mail, padlockClosed, secretCode, user } from '@icons';
import type { InputComponent } from '@common/components/input/input.component';

@Component({
    selector: 'registration-email-view',
    templateUrl: './registration-email-view.component.html',
    styleUrls: ['./registration-email-view.component.scss'],
})
export class RegistrationEmailViewComponent implements AfterViewInit {
    @ViewChild('emailAddress') emailAddress: InputComponent;

    icons = { mail, padlockClosed, secretCode, user };
    buildId: string = buildId;
    errorMessage = '';
    sending = false;
    savedSuccessfully = false;

    formData: FormGroup = this.fb.group({
        SystemId: [''],
        UserId: [0],
        FirstName: [''],
        LastName: [''],
        EmailAddress: ['', [
            Validators.required,
            Validators.email,
            Validators.minLength(6),
            Validators.maxLength(254),
        ]],
        Extension: [''],
        CountryCode: [''],
        PhoneNumber: [''],
        UserName: ['', [
            Validators.required,
            Validators.minLength(3),
            Validators.maxLength(18),
        ]],
        Password: ['', [
            Validators.required,
            Validators.minLength(8),
        ]],
        PasswordConfirm: ['', [
            Validators.required,
            keepErrorValidator(),
        ]],
        PhoneNumberTypeId: [1],
        TermsAccepted: [false],
        JobTitle: [''],
        NameSuffix: [''],
        SalutationTypeId: [1],
        RegistrationCode: ['', [
            Validators.required,
            Validators.maxLength(16),
        ]],
        SendTextMessages: [false],
    }, {
        validators: [matchValidator('Password', 'PasswordConfirm', { controlShowError: false })],
    });

    constructor(
        private fb: FormBuilder,
        private authService: AuthService,
    ) { }

    ngAfterViewInit(): void {
        this.emailAddress.focusInput();
    }

    registerEmail() {
        if (this.formData.invalid) {
            return;
        }

        this.errorMessage = '';
        this.sending = true;

        this.authService.registrationEmail(this.formData.value).subscribe(
            () => {
                this.savedSuccessfully = true;
                this.sending = false;
            },
            (response) => {
                this.sending = false;
                const errorsString = this.authService.authErrorsToString(response);
                this.errorMessage = 'We\'re sorry, but we could not complete your registration: ' + errorsString;
            },
        );
    }
}

import type { Sample } from './sample.interface';
import type { SampleCharacteristic } from './sample-characteristic.interface';

export interface SampleCharacteristicInstance {
    C_EnumerationItem_key: number | null;
    C_Material_key: number;
    C_SampleCharacteristicInstance_key: number;
    C_SampleCharacteristic_key: number;
    C_Workgroup_key: number;
    CharacteristicName: string;
    CharacteristicValue: string;
    CreatedBy: string;
    DateCreated: Date;
    DateEnd: Date | null;
    DateModified: Date;
    DateStart: Date | null;
    Description: string;
    ModifiedBy: string;
    Sample: Sample;
    SampleCharacteristic: SampleCharacteristic;
    Version: number;
}

<ng-container  *ngIf="childLocations && filteredChildLocations">
<table class="table table-nonfluid mb-0">
    <thead>
        <tr>
            <th>
                <a (click)="tableSort.toggleSort('PositionName')"
                   class="header-sort-link"
                   title="Sort by ChildLocation">Child Location</a>
            </th>
            <th>
                <a (click)="tableSort.toggleSort('LocationType')"
                   class="header-sort-link"
                   title="Sort by Type">Type</a>
            </th>
        </tr>
        <tr>
            <th>
                <input type="text" name="positionNameFilter" 
                        [(ngModel)]="filter.PositionName"
                        (ngModelChange)="filterChanged()"
                        class="form-control" />
            </th>
            <th>
                <input type="text" name="locationTypeFilter" 
                    [(ngModel)]="filter.LocationType"
                    (ngModelChange)="filterChanged()"
                    class="form-control" />
            </th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let location of filteredChildLocations |
                orderBy: tableSort.propertyPath:tableSort.reverse">
            <td>
                <a (click)="clickedLocation(location)"
                   class="location-position-name-link">
                    {{location?.PositionName}}
                </a>
            </td>
            <td>
                {{location?.LocationType}}
            </td>
        </tr>
    </tbody>
</table>

<div *ngIf="filteredChildLocations?.length === 0">
    <em>No matching locations.</em>
</div>
</ng-container>

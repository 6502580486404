import type { Job } from './job.interface';
import type { cv_TestArticle } from './cv-test-article.interface';

export interface JobTestArticle {
    Batch: string;
    C_JobTestArticle_key: number;
    C_Job_key: number;
    C_TestArticle_key: number;
    C_Workgroup_key: number;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    Identifier: number | null;
    Job: Job;
    ModifiedBy: string;
    Purity: number | null;
    Vehicle: string;
    VehicleLock: boolean;
    Version: number;
    cv_TestArticle: cv_TestArticle;
}

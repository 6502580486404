import { concatToStringList } from '../../common/util';

export enum ChartOptionLabelEnum {
  NAME = 'Name',
  MICROCHIP_ID = 'Microchip ID (if available)',
}

export enum ChartFilterType {
    Animal,
    Cohort,
    Genotype,
    Job,
    Line,
    Task,
    TaskOutput,
    Sex,
    ExitReason,
    Study,
    CohortIndividualAnimal
}

/*
 * Since the implementation of BLI20148, any new chart type added must also be added to the logic included in
 * TaskOutputChartRendererComponent#_filterAnimalsFromCohort
 * so that individual animals added from a cohort can be properly filtered pre-API call. 
 */

/**
 * Base class for Chart filters
 *   Is not meant to be instantiated
 */
export class ChartFilter {
    static readonly label: string;
    static readonly type: ChartFilterType;
    labelType = ChartOptionLabelEnum.NAME;
    model: any;

    get label(): string {
        return (<typeof ChartFilter> this.constructor).label;
    }

    get displayValue(): string {
        /* to be implemented */
        return '';
    }

    get displayLabel(): string {
      return this.displayValue;
    }

    setFilterValues(filter: any): void {
        /* to be implemented */
    }
}

export class AnimalChartFilter extends ChartFilter {
    static readonly type = ChartFilterType.Animal;
    static readonly label = 'Animal';

    get displayValue(): string {
      return this.model?.AnimalName ?? '';
    }

    get displayLabel(): string {
      let displayName = this.displayValue;
      if (this.labelType === ChartOptionLabelEnum.MICROCHIP_ID) {
        displayName = this.model?.MicrochipIdentifier
          ?? this.model?.Material?.MicrochipIdentifier
          ?? displayName;
      }
      return displayName;
    }

    setFilterValues(filter: any): void {
        if (this.model) {
            filter.materialKeys = concatToStringList(
                filter.materialKeys,
                this.model.MaterialKey
            );
        }
    }
}

export class CohortChartFilter extends ChartFilter {
    static readonly type = ChartFilterType.Cohort;
    static readonly label = 'Cohort';

    get displayValue(): string {
        return this.model ? this.model.CohortName : '';
    }

    setFilterValues(filter: any): void {
        if (this.model) {
            filter.cohortKeys = concatToStringList(
                filter.cohortKeys,
                this.model.C_Cohort_key
            );
        }
    }
}

export class GenotypeChartFilter extends ChartFilter {
    static readonly type = ChartFilterType.Genotype;
    static readonly label = 'Genotype';
    assayModel: any;
    symbolModel: any;

    get displayValue(): string {
        // Combine assay and symbol for display name.
        let display = '';
        if (this.assayModel && this.assayModel.GenotypeAssay) {
            display = this.assayModel.GenotypeAssay;
        }
        if (this.symbolModel && this.symbolModel.GenotypeSymbol) {
            display += ' ' + this.symbolModel.GenotypeSymbol;
        }

        return display;
    }

    setFilterValues(filter: any): void {
        if (this.assayModel) {
            filter.genotypeAssayKeys = concatToStringList(
                filter.genotypeAssayKeys,
                this.assayModel.C_GenotypeAssay_key
            );
        }

        if (this.symbolModel) {
            filter.genotypeSymbolKeys = concatToStringList(
                filter.genotypeSymbolKeys,
                this.symbolModel.C_GenotypeSymbol_key
            );
        }
    }
}

export class JobChartFilter extends ChartFilter {
    static readonly type = ChartFilterType.Job;
    static readonly label = 'Job';

    get displayValue(): string {
        return this.model ? this.model.JobID : '';
    }

    setFilterValues(filter: any): void {
        if (this.model) {
            filter.jobKeys = concatToStringList(
                filter.jobKeys,
                this.model.JobKey
            );
        }
    }
}

export class LineChartFilter extends ChartFilter {
    static readonly type = ChartFilterType.Line;
    static readonly label = 'Line';

    get displayValue(): string {
        return this.model ? this.model.LineName : '';
    }

    setFilterValues(filter: any): void {
        if (this.model) {
            filter.lineKeys = concatToStringList(
                filter.lineKeys,
                this.model.LineKey
            );
        }
    }
}

export class TaskChartFilter extends ChartFilter {
    static readonly type = ChartFilterType.Task;
    static readonly label = 'Task';

    get displayValue(): string {
        return this.model ? this.model.TaskName : '';
    }

    setFilterValues(filter: any): void {
        if (this.model) {
            filter.workflowTaskKeys = concatToStringList(
                filter.workflowTaskKeys,
                this.model.C_WorkflowTask_key
            );
        }
    }
}

export class TaskOutputChartFilter extends ChartFilter {
    static readonly type = ChartFilterType.TaskOutput;
    static readonly label = 'Task Output';

    get displayValue(): string {
        return this.model ? this.model.OutputName : '';
    }

    setFilterValues(filter: any): void {
        if (this.model) {
            filter.outputKeys = concatToStringList(
                filter.outputKeys,
                this.model.C_Output_key
            );
        }
    }
}

export class SexChartFilter extends ChartFilter {
    static readonly type = ChartFilterType.Sex;
    static readonly label = 'Sex';

    get displayValue(): string {
        return this.model ? this.model.Sex : '';
    }

    setFilterValues(filter: any): void {
        if (this.model) {
            filter.sexKeys = concatToStringList(
                filter.sexKeys,
                this.model.C_Sex_key
            );
        }
    }
}

export class ExitReasonChartFilter extends ChartFilter {
    static readonly type = ChartFilterType.ExitReason;
    static readonly label = 'Exit Reason';

    get displayValue(): string {
        return this.model ? this.model.ExitReason : '';
    }

    setFilterValues(filter: any): void {
        if (this.model) {
            filter.exitReasonKeys = concatToStringList(
                filter.exitReasonKeys,
                this.model.C_ExitReason_key
            );
        }
    }
}

export class StudyChartFilter extends ChartFilter {
    static readonly type = ChartFilterType.Study;
    static readonly label = 'Study';
    get displayValue(): string {
        return this.model ? this.model.StudyName : '';
    }
    setFilterValues(filter: any): void {
        if (this.model) {
            filter.studyKeys = concatToStringList(
                filter.studyKeys,
                this.model.StudyKey
            );
        }
    }
}

export class CohortIndividualAnimals extends ChartFilter {
    static readonly type = ChartFilterType.CohortIndividualAnimal;
    static readonly label = 'Cohort(Individual Animals)';

    get displayValue(): string {
      return this.model?.CohortName ?? '';
    }

    get displayLabel(): string {
      return '';
    }
    // this filter does not actually add anything to the API call, just acts as an indicator to get animals in a cohort. 
    setFilterValues(filter: any): void {
        return;
    }
}



import {
    AfterContentChecked,
    AfterContentInit,
    AfterViewChecked,
    AfterViewInit,
    Directive,
    DoCheck,
    Injector,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
} from '@angular/core';

/* eslint-disable */
@Directive()
export abstract class BaseClass
    implements
        OnChanges,
        OnInit,
        DoCheck,
        AfterContentInit,
        AfterContentChecked,
        AfterViewInit,
        AfterViewChecked,
        OnDestroy {

    constructor(protected injector: Injector) { }

    ngOnChanges(changes: SimpleChanges): void {/* intentional */}
    ngOnInit(): void {/* intentional */}
    ngDoCheck(): void {/* intentional */}
    ngAfterContentInit(): void {/* intentional */}
    ngAfterContentChecked(): void {/* intentional */}
    ngAfterViewInit(): void {/* intentional */}
    ngAfterViewChecked(): void {/* intentional */}
    ngOnDestroy(): void {/* intentional */}
}
/* eslint-enable */

/**
 * Base class to use it for construct mixins
 */
export class Base extends BaseClass {}

export type Constructor<T = BaseClass> = new(...args: any[]) => T;

export type BaseConstructor = Constructor<BaseClass>;

/**
 * The applyMixins function helps to compose a set of mixins
 * into a single class from which the component should further inherit.
 */
type MixinFunction = <T = any>(...args: any[]) => Constructor<T>;
const compose = (f: MixinFunction, g: MixinFunction) => (...args: any[]) => f(g(...args));
export const applyMixins = (...fns: MixinFunction[]) => fns.reduce(compose)(Base);

/**
 * The applyMetadata function helps incorporate @Input and @Output properties
 * to let Angular know about these metadata in the @Component decorator.
 */
type Metadata = Directive['inputs'] | Directive['outputs'];
export const applyMetadata = (...items: Metadata[]): Metadata => items.flat();

/**
 * ### How to apply mixins to component
 *
 * First create several mixins:
 *
 * The Delete Mixin
 *
 * ```ts
 * export interface CanDelete {
 *   delete: (someValue: any) => void;
 * }
 *
 * export type CanDeleteMixin = Constructor<CanDelete>;
 *
 * export function mixinDelete<T extends BaseConstructor> (SuperClass: T): CanDeleteClass & T {
 *   @Directive()
 *   class Delete extends SuperClass implements OnInit {
 *     ngOnInit(): void {
 *       super.ngOnInit(); // 👈 call super's lifecycle method
 *       // ...
 *     }
 *
 *     delete(someValue: any): void {
 *       // logic to remove someValue
 *     }
 *   }
 *   return Delete;
 * }
 * ```
 * **IMPORTANT**: Note on line "👈" above we call super’s respective lifecycle method.
 * When implementing lifecycle methods in mixins or components using mixins — remember
 * to call super.[lifecycle method] to ensure all life cycle methods in the mixin chain
 * are invoked.
 *
 *
 * The Like Mixin
 *
 * ```ts
 * export interface CanLike {
 *   like: (someValue: any) => void;
 * }
 *
 * export type CanLikeMixin = Constructor<CanLike>;
 *
 * export MIXIN_LIKE_METADATA_INPUT = ['likeCount'];
 *
 * export function mixinLike<T extends BaseConstructor> (SuperClass: T): CanLikeClass & T {
 *   @Directive()
 *   class Like extends SuperClass {
 *     @Input() likeCount: number;
 *
 *     like(someValue: any): void {
 *       // every mixin class we make can access Angular Services via the Injector.
 *       const service = this.injector.get(ServiceName);
 *     }
 *   }
 *   return Like;
 * }
 * ```
 * Note: Because the @Input decorators are in the mixin now, we have to let Angular know
 * about these inputs in the @Component decorator (see further).
 *
 * Creating the implementation component: SimpleComponent
 *
 * ```ts
 * const Mixins: CanDeleteMixin & CanLikeMixin = mixinDelete(mixinLike(Base));
 * ```
 *
 * or we can use the applyMixins function to compose our mixins:
 *
 * ```ts
 * const Mixins: CanDeleteClass & CanLikeClass = applyMixins(mixinDelete, mixinLike)(Base);
 * ```
 *
 * Once created the base component composed with the mixins, we can extend the new component:
 *
 * ```ts
 * @Component({
 *   selector: 'sd-simple-component',
 *   templateUrl: './simple.component.html',
 *   styleUrls: ['./simple.component.scss'],
 *   inputs: applyMetadata(MIXIN_LIKE_METADATA_INPUT),
 * })
 * export class SimpleComponent extends Mixins implements OnInit {
 *   constructor (injector: Injector) {
 *     super(injector); // 👈 passing the "injector" to the mixin chain
 *   }
 *
 *   ngOnInit(): void {
 *     super.ngOnInit(); // 👈 call super's lifecycle method
 *     // ...
 *   }
 * }
 * ```
 * Note — since our component extends the mixin classes, we have to provide Angular’s Injector
 * in the super call of the constructor.
 */

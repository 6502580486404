<form novalidate>

    <div class="modal-header justify-content-center">
        <h4 class="modal-title">
            Inactivity Warning
        </h4>
    </div>

    <div class="modal-body">
        <p>
            Your session is about to expire due to inactivity. You will be automatically logged out in {{countdown}} seconds if you do not click "Stay" to continue your session.
        </p>
    </div>

    <div class="modal-footer">
        <button type="button"
                class="btn btn-fw btn-lg btn-primary"
                (click)="onStay()"
                [disabled]="countdown <= 0">
            Stay
        </button>
        <button type="button"
                class="btn btn-lg btn-secondary"
                (click)="onLogoutNow()"
                [disabled]="countdown <= 0">
            Logout Now
        </button>
    </div>

</form>

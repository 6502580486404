import {
    CellFormatterService,
    END_STATE_CLASS,
    TableOptions
} from '../../common/datatable';

import { TranslationService } from '../../services/translation.service';
import { addAuditColumnsToOptions } from '@common/datatable/utils';

export class ProtocolTableOptions {

    options: TableOptions;

    constructor(
        private cellFormatterService: CellFormatterService,
        private translationService: TranslationService
    ) {
        this.options = addAuditColumnsToOptions(cellFormatterService)(this.getDefaultOptions());
    }

    getDefaultOptions(): TableOptions {
        return {
            csvFileName:
            this.translationService.translate('protocols').toLowerCase() + '.csv',
            enableDeleteColumn: true,
            enableDetailColumn: true,
            enableDraggable: true,
            dragOnlyActiveRows: true,
            enableSelectable: true,
            rowClass: (params: any) => {
                const row = params.data;

                const classes: string[] = [];
                if (row && row.IsActive !== true) {
                    classes.push(END_STATE_CLASS);
                }
                return classes;
            },
            columns: [
                {
                    displayName: 'Name', field: 'ProtocolName'
                },
                {
                    displayName: 'Description', field: 'Description'
                },
                {
                    displayName: 'Active', field: 'IsActive',
                    formatter: this.cellFormatterService.booleanFormatter,
                    exportFormatter: this.cellFormatterService.booleanExportFormatter,
                    maxWidth: 80,
                    visible: false
                }
            ]
        };
    }
}

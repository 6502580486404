import type { CohortMaterial } from './cohort-material.interface';
import type { JobCohort } from './job-cohort.interface';
import type { Note } from './note.interface';
import type { Output } from './output.interface';
import type { TaskCohort } from './task-cohort.interface';
import type { cv_MatchingMethod } from './cv-matching-method.interface';

export interface Cohort {
    BackgroundColor: string;
    C_Cohort_key: number;
    C_MatchingMethod_key: number | null;
    C_Output1_key: number | null;
    C_Output2_key: number | null;
    C_Output3_key: number | null;
    C_Workgroup_key: number;
    CohortMaterial: CohortMaterial[];
    CohortName: string;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    Description: string;
    ForegroundColor: string;
    JobCohort: JobCohort[];
    ModifiedBy: string;
    Note: Note[];
    Output1: Output;
    Output2: Output;
    Output3: Output;
    TaskCohort: TaskCohort[];
    Version: number;
    cv_MatchingMethod: cv_MatchingMethod;
}

const CURRENT_SELECTOR = "add-protocol-task";

import {
    Component,
    OnInit,
    Input
} from '@angular/core';

import {
    NgbActiveModal
} from '@ng-bootstrap/ng-bootstrap';

import { TaskService } from '../../tasks/task.service';
import { VocabularyService } from '../../vocabularies/vocabulary.service';
import { LocationService } from '../../locations/location.service';

import { DataType } from '../../data-type/data-type.enum';

@Component({
    selector: CURRENT_SELECTOR,
    templateUrl: './add-protocol-task.component.html'
})
export class AddProtocolTaskComponent implements OnInit {
    @Input() isCRO: boolean;

    // State
    error: string = null;
    tasks: any[] = [];
    workflowTaskKey: number;
    occurrences: number;
    sampleGroups: any[] = [];
    canAddSampleGroups = false;

    // Input Apply
    readonly INPUTS_APPLY_FIRST = 'First occurrence';
    readonly INPUTS_APPLY_ALL = 'All occurrences';
    inputsApply: string = this.INPUTS_APPLY_ALL;
    inputsApplyOptions: string[] = [
        this.INPUTS_APPLY_FIRST,
        this.INPUTS_APPLY_ALL,
    ];

    // Inputs & Values
    inputs: any[] = [];
    inputValues: any = {};

    // Default keys
    defaultPreservationMethodKey: string;
    defaultSampleStatusKey: string;
    defaultSampleTypeKey: string;
    defaultContainerTypeKey: string;
    defaultSampleSubtypeKey: string;
    defaultSampleProcessingMethodKey: string;
    defaultSampleAnalysisMethodKey: string;

    // CVs
    preservationMethods: any[];
    containerTypes: any[];
    sampleStatuses: any[];
    sampleTypes: any[];
    timeUnits: any[];
    sampleSubtypes: any[];
    sampleAnalysisMethods: any[];
    sampleProcessingMethods: any[];

    // Bulk update placeholders
    bulkNumSamples: number;
    bulkPreservationMethodKey: string;
    bulkContainerTypeKey: string;
    bulkSampleStatusKey: string;
    bulkSampleTypeKey: string;
    bulkDateHarvest: Date;
    bulkDateExpiration: Date;
    bulkTimePoint: number;
    bulkTimeUnitKey: string;
    bulkSampleSubtypeKey: string;
    bulkSampleProcessingMethodKey: string;
    bulkSendTo: string;
    bulkSampleAnalysisMethodKey: string;
    bulkSpecialInstructions: string;

    // Error messages
    readonly generalErrorMessage: string = "Please input all required fields.";
    readonly taskDaysRangeErrorMessage: string = "The start day should be between 0 and 150 days.";

    constructor(
        private activeModal: NgbActiveModal,
        private taskService: TaskService,
        private vocabularyService: VocabularyService,
        private locationService: LocationService
    ) {
        // Nothing to do
    }

    ngOnInit() {
        // Defaults
        this.occurrences = 1;
        this.tasks.push({
            taskKey: null,
            inputs: [],
            timeDifferences: [],
            offsets: [],
            sampleGroups: [],
            inputsApply: this.INPUTS_APPLY_ALL,
            inputValues: {}
        });
        this.getCVs();
    }

    onCancel(): void {
        this.activeModal.dismiss();
    }

    onSubmit(): void {
        this.error = null;
        if (this.isCRO) {
            // Are all tasks valid?
            for (const task of this.tasks) {
                if (!task.taskKey) {
                    this.error = this.generalErrorMessage;
                    return;
                }
                if (task.C_Frequency_key === null) {
                    this.error = this.generalErrorMessage;
                    return;
                }
                if (task.TaskCount === null || task.TaskCount === 0) {
                    this.error = this.generalErrorMessage;
                    return;
                }
                // If its ToEnd task, make sure the RelatedTo and Anchor field is set
                if (task.RelatedTo === undefined || (!task.C_ScheduleType_key && task.RelatedTo !== 'study_start_day')) {
                    this.error = this.generalErrorMessage;
                    return;
                }
                // Make Time Difference required when timeDifference is an editable option (tasks per day > 1)
                if (task.timeDifferences.length && !task.TimeDifference) {
                    this.error = this.generalErrorMessage;
                    return;
                }
                if ((task.RelatedTo > -1 && (task.AnchorDay === undefined || !(this.tasks[task.RelatedTo].Days[task.AnchorDay] > 0))) ||
                    (task.RelatedTo === 'study_start_day' && task.StartDay === undefined)) {
                    this.error = this.generalErrorMessage;
                    return;
                }
                if (task.StartDay < 0 || task.StartDay > 150) {
                    this.error = this.taskDaysRangeErrorMessage;
                    return;
                }
            }
        }
        const tasks = this.getTasks();
        this.activeModal.close(tasks);
    }

    /**
     * Clear the modal form (expcet the task selection)
     */
    resetForm() {
        this.occurrences = 1;
        this.inputsApply = this.INPUTS_APPLY_ALL;
        this.inputs = [];
        this.inputValues = {};
        this.sampleGroups = [];
        this.canAddSampleGroups = false;
    }

    /**
     * The task selection changed.
     */
    taskChanged() {
        // Clear the inputs, etc
        this.resetForm();

        if (this.workflowTaskKey > 0) {
            // Fetch the Inputs for the selected WorkflowTask
            return this.taskService.getTaskInputs(this.workflowTaskKey).then((data: any[]) => {
                this.inputs = data;
            }).then(() => {
                this.taskService.getTaskByKey(this.workflowTaskKey).then((task: any) => {
                    if (task.cv_TaskType.TaskType === 'Job' || task.cv_TaskType.TaskType === 'Study') {
                        this.canAddSampleGroups = true;
                    }
                });
            });
        }
    }

    /**
     * Build all the new ProtocolTasks
     */
    getTasks(): any[] {
        if (this.isCRO) {
            const finalTasks: any[] = [];
            this.tasks.forEach((task: any) => {
                finalTasks.push(this._createCROTask(task));
            });
            return finalTasks;
        }
        // Check required values
        if (!this.workflowTaskKey) {
            return [];
        }
        if (!this.occurrences) {
            this.occurrences = 1;
        }

        const tasks: any[] = [];
        for (let i = 0; i < this.occurrences; i++) {
            tasks.push(this._createTask(i));
        }

        return tasks;
    }

    isRegularInput(input: any): boolean {
        return input.cv_DataType.DataType !== DataType.DOSING_TABLE &&
            input.cv_DataType.DataType !== DataType.JOB_CHARACTERISTIC;
    }

    isDosingTableInput(input: any): boolean {
        return input.cv_DataType.DataType === DataType.DOSING_TABLE;
    }

    isJobCharacteristicInput(input: any): boolean {
        return input.cv_DataType.DataType === DataType.JOB_CHARACTERISTIC;
    }

    private _createCROTask(initialValues: any) {
        const task: any = {
            C_WorkflowTask_key: initialValues.taskKey,
            ...initialValues
        };

        if (initialValues.inputs.length > 0) {
            // Prepare the InputDefault list
            task.InputDefault = [];
            for (const input of initialValues.inputs) {
                // Alwauys create the InputDefault entity
                const inputDefault: any = {
                    C_Input_key: input.C_Input_key,
                };

                // But only apply the value if the Apply selection allow it.
                if (task.inputsApply === this.INPUTS_APPLY_ALL) {
                    inputDefault.InputValue = task.inputValues[input.C_Input_key];
                }

                // Add this InputDefault to the list
                task.InputDefault.push(inputDefault);
            }
        }

        if (initialValues.sampleGroups.length > 0) {
            task.SampleGroups = [];
            for (const group of initialValues.sampleGroups) {
                if (this.inputsApply === this.INPUTS_APPLY_ALL) {
                    task.SampleGroups.push(group);
                }
            }
        }
        return task;
    }

    /**
     * Create a new ProtocolTask
     * 
     * @param index index of this task among the newly created tasks
     */
    private _createTask(index: number): any {
        // Prepare the ProtocolTask
        // Note: The C_Protocol_key will be set by the component that opened this modal
        const task: any = {
            C_WorkflowTask_key: this.workflowTaskKey
        };

        if (this.inputs.length > 0) {
            // Prepare the InputDefault list
            task.InputDefault = [];
            for (const input of this.inputs) {
                // Alwauys create the InputDefault entity
                const inputDefault: any = {
                    C_Input_key: input.C_Input_key,
                };

                // But only apply the value if the Apply selection allow it.
                if ((this.inputsApply === this.INPUTS_APPLY_ALL) || (index === 0)) {
                    inputDefault.InputValue = this.inputValues[input.C_Input_key];
                }

                // Add this InputDefault to the list
                task.InputDefault.push(inputDefault);
            }
        }

        if (this.sampleGroups.length > 0) {
            task.SampleGroups = [];
            for (const group of this.sampleGroups) {
                if ((this.inputsApply === this.INPUTS_APPLY_ALL) || (index === 0)) {
                    task.SampleGroups.push(group);
                }
            }
        }

        return task;
    }

    getCVs(): Promise<any> {
        const preferLocal = true;
        return Promise.all([
            this.vocabularyService.getCV(
                'cv_PreservationMethods', 'SortOrder', preferLocal
            ).then((data: any[]) => {
                this.preservationMethods = data;
            }),
            this.locationService.getContainerTypes('Sample').then((data) => {
                this.containerTypes = data;
            }),
            this.vocabularyService.getCV(
                'cv_SampleStatuses', 'SortOrder', preferLocal
            ).then((data: any[]) => {
                this.sampleStatuses = data;
            }),
            this.vocabularyService.getCV(
                'cv_SampleTypes', 'SortOrder', preferLocal
            ).then((data: any[]) => {
                this.sampleTypes = data;
            }),
            this.vocabularyService.getCV(
                'cv_TimeUnits', 'SortOrder', preferLocal
            ).then((data: any[]) => {
                this.timeUnits = data;
            }),
            this.vocabularyService.getCV(
                'cv_SampleSubtypes', 'SortOrder', preferLocal
            ).then((data: any[]) => {
                this.sampleSubtypes = data;
                }),
            this.vocabularyService.getCV(
                'cv_SampleProcessingMethods', 'SortOrder', preferLocal
            ).then((data: any[]) => {
                this.sampleProcessingMethods = data;
                }),
            this.vocabularyService.getCV(
                'cv_SampleAnalysisMethods', 'SortOrder', preferLocal
            ).then((data: any[]) => {
                this.sampleAnalysisMethods = data;
            }),
            this.vocabularyService.getCVDefault(
                'cv_PreservationMethods', preferLocal
            ).then((value) => {
                if (value != null) {
                    this.defaultPreservationMethodKey = value.C_PreservationMethod_key;
                }
            }),
            this.vocabularyService.getCVContainerTypeDefault(
                'Sample'
            ).then((value) => {
                if (value != null) {
                    this.defaultContainerTypeKey = value.C_ContainerType_key;
                }
            }),
            this.vocabularyService.getCVDefault(
                'cv_SampleStatuses', preferLocal
            ).then((value) => {
                if (value != null) {
                    this.defaultSampleStatusKey = value.C_SampleStatus_key;
                }
            }),
            this.vocabularyService.getCVDefault(
                'cv_SampleTypes', preferLocal
            ).then((value) => {
                if (value != null) {
                    this.defaultSampleTypeKey = value.C_SampleType_key;
                }
            }),
            this.vocabularyService.getCVDefault(
                'cv_SampleSubtypes', preferLocal
            ).then((value) => {
                if (value != null) {
                    this.defaultSampleSubtypeKey = value.C_SampleSubtype_key;
                }
                }),
            this.vocabularyService.getCVDefault(
                'cv_SampleProcessingMethods', preferLocal
            ).then((value) => {
                if (value != null) {
                    this.defaultSampleProcessingMethodKey = value.C_SampleProcessingMethod_key;
                }
                }),
            this.vocabularyService.getCVDefault(
                'cv_SampleAnalysisMethods', preferLocal
            ).then((value) => {
                if (value != null) {
                    this.defaultSampleAnalysisMethodKey = value.C_SampleAnalysisMethod_key;
                }
            }),

        ]);
    }

    /**
     * Add a new default SampleGroup row to the create modal
     */
    createAddRow() {
        this.sampleGroups.push({
            NumSamples: 1,
            C_SampleType_key: this.defaultSampleTypeKey,
            C_SampleStatus_key: this.defaultSampleStatusKey,
            C_PreservationMethod_key: this.defaultPreservationMethodKey,
            C_ContainerType_key: this.defaultContainerTypeKey,
            C_SampleSubtype_key: this.defaultSampleSubtypeKey,
            C_SampleProcessingMethod_key: this.defaultSampleProcessingMethodKey,
            C_SampleAnalysis_key: this.defaultSampleAnalysisMethodKey
        });
    }

    /**
     * Remove a SampleGroup row from the create modal
     * 
     * @param index index of the reow to remove
     */
    createRemoveRow(index: number) {
        this.sampleGroups.splice(index, 1);
    }


    updateBulkNumSamples() {
        this.updateBulkValue('NumSamples', this.bulkNumSamples);
    }
    updateBulkPreservationMethodKey() {
        this.updateBulkValue('C_PreservationMethod_key', this.bulkPreservationMethodKey);
    }
    updateBulkContainerTypeKey() {
        this.updateBulkValue('C_ContainerType_key', this.bulkContainerTypeKey);
    }
    updateBulkSampleStatusKey() {
        this.updateBulkValue('C_SampleStatus_key', this.bulkSampleStatusKey);
    }
    updateBulkSampleTypeKey() {
        this.updateBulkValue('C_SampleType_key', this.bulkSampleTypeKey);
    }
    updateBulkDateHarvest() {
        this.updateBulkValue('DateHarvest', this.bulkDateHarvest);
    }
    updateBulkDateExpiration() {
        this.updateBulkValue('DateExpiration', this.bulkDateExpiration);
    }
    updateBulkTimePoint() {
        this.updateBulkValue('TimePoint', this.bulkTimePoint);
        this.updateBulkValue('C_TimeUnit_key', this.bulkTimeUnitKey);
    }
    updateBulkSampleSubtypeKey() {
        this.updateBulkValue('C_SampleSubtype_key', this.bulkSampleSubtypeKey);
    }
    updateBulkSampleProcessingMethodKey() {
        this.updateBulkValue('C_SampleProcessingMethod_key', this.bulkSampleProcessingMethodKey);
    }
    updateBulkSampleAnalysisMethodKey() {
        this.updateBulkValue('C_SampleAnalysisMethod_key', this.bulkSampleAnalysisMethodKey);
    }
    updateBulkSendTo() {
        this.updateBulkValue('SendTo', this.bulkSendTo);
    }
    updateBulkSpecialInstructions() {
        this.updateBulkValue('SpecialInstructions', this.bulkSpecialInstructions);
    }

    // Bulk update handlers
    updateBulkValue(key: string, value: any) {
        // Update the rows in the Create Modal
        for (const row of this.sampleGroups) {
            row[key] = value;
        }
    }

    // <select> formatters
    sampleTypeKeyFormatter = (value: any) => {
        return value.C_SampleType_key;
    }
    sampleTypeFormatter = (value: any) => {
        return value.SampleType;
    }
    sampleStatusKeyFormatter = (value: any) => {
        return value.C_SampleStatus_key;
    }
    sampleStatusFormatter = (value: any) => {
        return value.SampleStatus;
    }
    preservationMethodKeyFormatter = (value: any) => {
        return value.C_PreservationMethod_key;
    }
    preservationMethodFormatter = (value: any) => {
        return value.PreservationMethod;
    }
    containerTypeKeyFormatter = (value: any) => {
        return value.C_ContainerType_key;
    }
    containerTypeFormatter = (value: any) => {
        return value.ContainerType;
    }
    timeUnitKeyFormatter = (value: any) => {
        return value.C_TimeUnit_key;
    }
    timeUnitFormatter = (value: any) => {
        return value.TimeUnit;
    }
    sampleSubtypeKeyFormatter = (value: any) => {
        return value.C_SampleSubtype_key;
    }
    sampleSubtypeFormatter = (value: any) => {
        return value.SampleSubtype;
    }
    sampleProcessingMethodKeyFormatter = (value: any) => {
        return value.C_SampleProcessingMethod_key;
    }
    sampleProcessingMethodFormatter = (value: any) => {
        return value.SampleProcessingMethod;
    }
    sampleAnalysisMethodKeyFormatter = (value: any) => {
        return value.C_SampleAnalysisMethod_key;
    }
    sampleAnalysisMethodFormatter = (value: any) => {
        return value.SampleAnalysisMethod;
    }
}

/**
 * uses javascript abstract equality to test two values.
 * E.g. softCompare("1", 1) === true
 * @param value1
 * @param value2
 */
export function softCompare(value1: any, value2: any): boolean {
    /* eslint-disable-next-line */
    return value1 == value2;
}

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class FacetLoadingStateService {

    private changeLoadingStateSource = new Subject<boolean>();
    changeLoadingState$ = this.changeLoadingStateSource.asObservable();
    
    private _loading: boolean;
    private _busy = 0;
    constructor() {
        this.changeLoadingState(false);
    }
   
    changeLoadingState(value = true) {
        this._loading = value;
        // set a delay when deleting the loading state.
        // this gives extra time to render the interface
        // with data from the 'breeze-client'
        const delay = this._loading ? 0 : 2000;
        setTimeout(() => {
            this.changeLoadingStateSource.next(this._loading);
        }, delay);
    }

    setBusy(isBusy = true) {
        this._busy += isBusy ? 1 : -1;
        this.changeLoadingState(this._busy > 0);
    }
}

import type { Job } from './job.interface';
import type { cv_StandardPhrase } from './cv-standard-phrase.interface';

export interface JobStandardPhrase {
    C_JobStandardPhrase_key: number;
    C_Job_key: number;
    C_StandardPhrase_key: number;
    C_Workgroup_key: number;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    Job: Job;
    ModifiedBy: string;
    Version: number;
    cv_StandardPhrase: cv_StandardPhrase;
}

import { Injectable } from '@angular/core';
import { ScheduleService } from './schedule.service';
import { PrivilegeService } from './../services/privilege.service';
import { Appointment, MonthlyScheduleRecord } from './models';
import { TaskType } from './../tasks/models/task-type.enum';
import {
    isDateMidnight,
    getSafeProp,
    notEmpty,
    uniqueArrayFromPropertyPath
} from '../common/util';
import { convertValueToLuxon } from '@common/util/date-time-formatting/convert-value-to-luxon';
import { animals, births, calendar, clinical, housing, IconContent, lines, mating, pin } from '@icons';
import { Resource } from '@common/types';

/**
 * Logic helper class for the ScheduleFacetComponent
 */
@Injectable()
export class ScheduleHelperService {

    readonly UNASSIGNED_LABEL = 'Unassigned';
    readonly UNASSIGNED_FOREGROUND_COLOR = '#000000';
    readonly UNASSIGNED_BACKGROUND_COLOR = '#c0c0c0';
    readonly GROUP_BACKGROUND_COLOR = '#FFFFFF';

    readonly = true;

    constructor(
        // TODO (kevin.stone): figure out how to inherit PrivilegeService
        //    from component injector
        private privilegeService: PrivilegeService,
        private scheduleService: ScheduleService
    ) { }

    setReadonly(readonly: boolean) {
        this.readonly = readonly;
    }

    updateTaskInstance(appointments: Appointment[], taskInstance: any, newData: any): Promise<any> {
        // If it was dropped, the due date on the task instance needs to be updated
        taskInstance.DateDue = newData.startDate;
        // Update any related task or group task due dates
        return this.scheduleService.calculateDueDates(taskInstance);
    }

    updateNonTask(appointments: Appointment[], scheduleNonTask: any, newData: any) {
        scheduleNonTask.DateTimeStart = newData.startDate;
        scheduleNonTask.DateTimeComplete = newData.endDate;
        // Need to replace the appointment to avoid an update loop
        this.replaceAppointmentsFromNonTasks(appointments, [scheduleNonTask]);
    }

    replaceAppointmentsFromTasks(
        appointments: Appointment[], tasks: any[]
    ) {
        for (const task of tasks) {
            const appointment = this.findAppointmentByTask(appointments, task);
            if (appointment) {
                const index = appointments.indexOf(appointment);
                appointments[index] = this.createAppointmentFromTask(task);
            }
        }
    }

    replaceAppointmentsFromNonTasks(
        appointments: Appointment[], scheduleNonTasks: any[]
    ) {
        for (const scheduleNonTask of scheduleNonTasks) {
            const appointment = this.findAppointmentByNonTask(appointments, scheduleNonTask);
            if (appointment) {
                const index = appointments.indexOf(appointment);
                appointments[index] =
                    this.createAppointmentFromNonTask(scheduleNonTask);
            }
        }
    }

    removeNonTaskAppointment(appointments: Appointment[], scheduleNonTask: any) {
        const appointment = this.findAppointmentByNonTask(appointments, scheduleNonTask);
        this.removeAppointment(appointments, appointment);
    }

    private removeAppointment(appointments: Appointment[], appointment: any) {
        const index = appointments.indexOf(appointment);
        if (index >= 0) {
            appointments.splice(index, 1);
        }
    }

    private findAppointmentByTask(appointments: Appointment[], task: any) {
        return appointments.find((item) => {
            const appointmentTaskKey = getSafeProp(item, 'taskInstance.C_TaskInstance_key');
            return appointmentTaskKey === task.C_TaskInstance_key;
        });
    }

    findAppointmentByNonTask(appointments: Appointment[], scheduleNonTask: any) {
        return appointments.find((item) => {
            const nonTaskKey = getSafeProp(item, 'scheduleNonTask.C_ScheduleNonTask_key');
            return nonTaskKey === scheduleNonTask.C_ScheduleNonTask_key;
        });
    }

    createGroupedTaskAppointments(records: MonthlyScheduleRecord[]): Appointment[] {
        const appointments: Appointment[] = [];

        for (const record of records) {
            const appointment = this.createGroupedTaskAppointment(record);
            appointments.push(appointment);
        }

        return appointments;
    }

    private createGroupedTaskAppointment(record: MonthlyScheduleRecord): Appointment {
        const resourceGrouped = record.ResourceGrouped === 'True';
        const getResourceBackgroundColor = (backgroundColor: string) => {
            if (resourceGrouped) {
                return this.GROUP_BACKGROUND_COLOR;
            }
            return backgroundColor || this.UNASSIGNED_BACKGROUND_COLOR;
        };
        const getResourceForegroundColor = (foregroundColor: string) => {
            return resourceGrouped || !foregroundColor ? this.UNASSIGNED_FOREGROUND_COLOR : foregroundColor;
        };

        return {
            text: record.TaskName + ' (' + record.TaskCount + ')',
            taskName: record.TaskName,
            startDate: convertValueToLuxon(record.DateStart).toJSDate(),
            endDate: convertValueToLuxon(record.DateEnd).plus({ minutes: 1 }).toJSDate(),
            allDay: true,
            assignedTo: record.ResourceName || this.UNASSIGNED_LABEL,
            taskType: record.TaskType,
            icon: this.getIconImage(record.TaskType),
            animalCount: record.AnimalCount,
            taskCount: record.TaskCount,
            resourceBackgroundColor: getResourceBackgroundColor(record.ResourceBackgroundColor),
            resourceForegroundColor: getResourceForegroundColor(record.ResourceForegroundColor),
            resourceGrouped,
            location: record.CurrentLocationPath,
            jobId: record.JobID,
        };
    }

    createIndividualTaskAppointments(tasks: any[]): Appointment[] {
        const appointments: Appointment[] = [];

        for (const task of tasks) {
            appointments.push(this.createAppointmentFromTask(task));
        }

        return appointments;
    }

    createAppointmentFromTask(task: any): Appointment {
        let animalCount: number;
        let duration: any;
        const resource = task.AssignedToResource;
        let location = '';
        let jobId = '';

        if (notEmpty(task.TaskMaterial)) {
            animalCount = task.TaskMaterial.length;
        } else if (task.IsGroup) {
            const childAnimals = uniqueArrayFromPropertyPath(
                task, 'MemberTaskInstance.TaskMaterial.Material.Animal'
            );
            animalCount = childAnimals.length;
        } else {
            animalCount = 0;
        }

        if (task.CurrentLocationPath) {
            location = task.CurrentLocationPath;
        }


        if (notEmpty(task.TaskJob)) {
            jobId = getSafeProp(task.TaskJob[0], 'Job.JobID');
        }
        const appointment: Appointment = {
            taskInstance: task,
            text: this.getTextField(task, jobId, resource),
            taskAlias: task.TaskAlias,
            taskName: task.WorkflowTask.TaskName,
            startDate: convertValueToLuxon(task.DateDue).toJSDate(),
            endDate: convertValueToLuxon(task.DateDue).toJSDate(),
            allDay: true,
            assignedTo: resource ? resource.ResourceName : this.UNASSIGNED_LABEL,
            taskType: task.WorkflowTask.cv_TaskType.TaskType,
            icon: this.getIconImage(task.WorkflowTask.cv_TaskType.TaskType),
            animalCount,
            taskCount: 1,
            locked: task.IsLocked,
            location,
            jobId,

            // assigned via assignResourceStyleProperties()
            resourceBackgroundColor: null,
            resourceForegroundColor: null,
            resourceGrouped: false,
            effort: task.WorkflowTask.Effort,
            isEffortPerMaterial: task.WorkflowTask.IsEffortPerMaterial
        };

        this.assignResourceStyleProperties(appointment, resource);

        // Get duration from the task instance if there is one
        if (task.Duration) {
            duration = task.Duration;
            // If no duration, set to default
        } else {
            duration = 60;
        }

        if (appointment.startDate) {
            // Only set the end time if it's not midnight (default for no time component)
            if (convertValueToLuxon(appointment.startDate).toFormat("HH:mm:ss") !== '00:00:00') {
                const endDate = convertValueToLuxon(appointment.startDate).plus({"minutes": duration});
                appointment.endDate = endDate.toJSDate();
                appointment.allDay = false;
            }
            if (appointment.allDay) {
                const minutesInDay = 24 * 60;
                appointment.endDate = convertValueToLuxon(appointment.startDate).plus({"minutes": minutesInDay}).toJSDate();
            }
        }

        return appointment;
    }

    getTextField(task: any, jobId: any, resource: any): string {
        let text = '';
        text += task.WorkflowTask.TaskName;
        if (jobId && jobId !== '') {
            text = text + " - " + jobId;
        }
        if (resource) {
            const names = resource.ResourceName.split(', ');
            if (names.length > 3) {
                text = text + " - " + `${names.length} resources assigned`;
            } else {
                text = text + " - " + resource.ResourceName;
            }
        } else {
            text = text + " - " + this.UNASSIGNED_LABEL;
        }
        return text;
    }

    createNonTaskAppointments(scheduleNonTasks: any[]): Appointment[] {
        const appointments: Appointment[] = [];

        for (const scheduleNonTask of scheduleNonTasks) {
            appointments.push(this.createAppointmentFromNonTask(scheduleNonTask));
        }

        return appointments;
    }

    createAppointmentFromNonTask(scheduleNonTask: any): Appointment {
        const resource = scheduleNonTask.Resource;
        const isAllDay = isDateMidnight(scheduleNonTask.DateTimeStart);

        const appointment: Appointment = {
            scheduleNonTask,
            taskAlias: scheduleNonTask.Description,
            taskName: scheduleNonTask.Description,
            taskType: 'Schedule',
            text: scheduleNonTask.Description + " - " + (resource ? resource.ResourceName : this.UNASSIGNED_LABEL),
            icon: this.getIconImage('Schedule'),
            startDate: convertValueToLuxon(scheduleNonTask.DateTimeStart).toJSDate(),
            endDate: convertValueToLuxon(scheduleNonTask.DateTimeComplete).toJSDate(),
            allDay: isAllDay,
            canDelete: !this.readonly,
            assignedTo: resource ? resource.ResourceName : this.UNASSIGNED_LABEL,
            // assigned via assignResourceStyleProperties()
            resourceBackgroundColor: null,
            resourceForegroundColor: null,
            resourceGrouped: null,
            effort: null,
            isEffortPerMaterial: false
        };

        this.assignResourceStyleProperties(appointment, resource);

        return appointment;
    }


    private assignResourceStyleProperties(appointment: Appointment, resource: Resource | null) {
        if (resource) {
            if (resource.IsGroup) {
                // Multiple resources assigned.
                appointment.resourceGrouped = true;
                appointment.resourceBackgroundColor = this.GROUP_BACKGROUND_COLOR;
                appointment.resourceForegroundColor = this.UNASSIGNED_FOREGROUND_COLOR;

            } else {
                // Just one resource assigned
                appointment.resourceBackgroundColor = resource.BackgroundColor;
                appointment.resourceForegroundColor = resource.ForegroundColor;
            }
        } else {
            // Not assigned yet
            appointment.resourceBackgroundColor = this.UNASSIGNED_BACKGROUND_COLOR;
            appointment.resourceForegroundColor = this.UNASSIGNED_FOREGROUND_COLOR;
        }
    }

    private getIconImage(taskType: string): IconContent {
        switch (taskType) {
            case TaskType.Job: return pin;
            case TaskType.Animal: return animals;
            case TaskType.Birth: return births;
            case TaskType.Housing: return housing;
            case TaskType.Mating: return mating;
            case TaskType.Line: return lines;
            case TaskType.HealthRecord: return clinical;
            // for ScheduleNonTask items
            case 'Schedule': return calendar;
            default: return null;
        }
    }
}

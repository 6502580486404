import type { Birth } from './birth.interface';
import type { Sample } from './sample.interface';

export interface BirthSample {
    Birth: Birth;
    C_BirthSample_key: number;
    C_Birth_key: number;
    C_Sample_key: number;
    C_Workgroup_key: number;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    ModifiedBy: string;
    Sample: Sample;
    Version: number;
}

const ELLIPSIS = '\u2026';

import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';

import { TaskService } from './task.service';
import { TaskType } from './models';

@Component({
    selector: 'task-select',
    template: `
    <div class="task-select">
        <climb-typeahead
            [(model)]="model"
            [resultFormatter]="nameFormatter"
            [keyFormatter]="keyFormatter"
            [search]="searchItems"
            [keySearch]="searchItemsByKey" 
            [id]="id"
            [placeholder]="placeholder"
            [required]="required"
            [namespace]="'task'"
            (modelChange)="modelChangeHandler($event)"
            #typeahead
            [disabled]="disabled"
        ></climb-typeahead>
    </div>
    `,
})
export class TaskSelectComponent implements OnInit {
    @Input() model: any;
    // Optional cv_TaskType.TaskType by which to filter the task list
    @Input() taskType: TaskType;
    @Input() id: string;
    @Input() placeholder: string = 'Task name' + ELLIPSIS;
    @Input() showActiveOnly = true;
    @Input() required: boolean;
    @Input() disabled: boolean;

    @Output() modelChange: EventEmitter<any> = new EventEmitter<any>();

    readonly JOB_TASK_TYPE: string = 'Job';
    readonly PAGE_SIZE_DEFAULT: number = 200;

    constructor(
        private taskService: TaskService
    ) {
        // nothing to do
    }

    ngOnInit() {
        this._initValues();
    }

    private _initValues() {
        // Default showActiveOnly to true
        if (this.showActiveOnly !== false) {
            this.showActiveOnly = true;
        }
    }

    modelChangeHandler(event: any) {
        this.modelChange.emit(this.model);
    }

    searchItems = (searchTerm: string): Promise<any[]> => {
        const filter: any = {
            TaskName: searchTerm,
        };
        if (this.taskType) {
            filter.TaskType = this.taskType;
        }
        this._setFilterForActive(filter);

        return this._searchItems(filter, this.PAGE_SIZE_DEFAULT);
    }

    searchItemsByKey = (key: any): Promise<any[]> => {
        const filter: any = {
            C_WorkflowTask_key: key,
        };
        if (this.taskType) {
            filter.TaskType = this.taskType;
        }
        this._setFilterForActive(filter);

        const pageSize = 1;

        return this._searchItems(filter, pageSize);
    }

    _searchItems(filter: any, pageSize: number): Promise<any[]> {
        const pageNumber = 1;
        const sort = 'TaskName asc';

        return this.taskService.searchTasks({
            page: pageNumber,
            size: pageSize,
            sort,
            filter
        });
    }

    private _setFilterForActive(filter: any) {
        if (this.showActiveOnly) {
            filter.IsActive = true;
        }
    }

    nameFormatter = (item: any) => {
        return item.TaskName;
    }

    keyFormatter = (item: any) => {
        return item.C_WorkflowTask_key;
    }
}

import { BaseManagerService } from './../base-manager.service';
import { notEmpty } from '../../common/util';

export class TaskDeleteLogicHelper {

    constructor(private manager: BaseManagerService) {}

    deleteTask(task: any) {
        while (notEmpty(task.TaskInput)) {
            this.manager.deleteEntity(task.TaskInput[0]);
        }

        while (notEmpty(task.TaskOutputSet)) {

            const outputSet = task.TaskOutputSet[0];
            while (notEmpty(outputSet.TaskOutput)) {
                this.manager.deleteEntity(outputSet.TaskOutput[0]);
            }

            while (notEmpty(outputSet.TaskMaterial)) {
                this.manager.deleteEntity(outputSet.TaskMaterial[0]);
            }

            this.manager.deleteEntity(task.TaskOutputSet[0]);
        }

        while (notEmpty(task.TaskMaterial)) {
            this.manager.deleteEntity(task.TaskMaterial[0]);
        }

        if (task.TaskTopAnimal) {
            this.manager.detachEntity(task.TaskTopAnimal);
        }

        if (task.TaskTopHousing) {
            this.manager.detachEntity(task.TaskTopHousing);
        }

        if (task.TaskTopLine) {
            this.manager.detachEntity(task.TaskTopLine);
        }

        this.manager.deleteEntity(task);
    }
}

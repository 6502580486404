import { UsersSharedModule } from './../user/users-shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { ClimbCommonModule } from '../common/climb-common.module';
import { ClimbServicesModule } from '../services/climb-services.module';

import { EventHistoryComponent } from './event-history.component';
import { EventService } from './event.service';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ClimbCommonModule,
        ClimbServicesModule,
        UsersSharedModule
    ],
    declarations: [
        EventHistoryComponent
    ],
    exports: [
        EventHistoryComponent
    ],
    providers: [
        EventService
    ]
})
export class EventsSharedModule { }

<div class="row chart-settings-container pt-2">
    <div class="col-md-8">
        <form role="form" class="form-label-right">
            <div class="form-group row">
                <label class="col-md-3 col-form-label">Name</label>
                <div class="col-md-9">
                    <input type="text"
                           name="jobName"
                           [(ngModel)]="filter.JobID"
                           class="form-control input-medium"
                           data-auto-focus="jobName" />
                </div>
            </div>

            <div class="form-group row">
                <label class="col-md-3 col-form-label">Type</label>
                <div class="col-md-9">
                    <select name="jobType"
                            [(ngModel)]="filter.C_JobType_key"
                            class="form-control input-medium">
                        <option></option>
                        <option *ngFor="let item of jobTypes"
                                [value]="item.C_JobType_key">
                            {{item.JobType}}
                        </option>
                    </select>
                </div>
            </div>

            <div class="form-group row">
                <label class="col-md-3 col-form-label">{{'Line' | translate}}</label>
                <div class="col-md-9">
                    <line-multiselect [(model)]="filter.lines"></line-multiselect>
                </div>
            </div>

            <div class="form-group row">
                <label class="col-md-3 col-form-label">Status</label>
                <div class="col-md-9">
                    <climb-multi-select [(model)]="filter.C_JobStatus_keys"
                                        [values]="jobStatuses"
                                        [keyProperty]="'C_JobStatus_key'"
                                        [valueProperty]="'JobStatus'"
                                        [placeholder]="'Select statuses...'"></climb-multi-select>
                </div>
            </div>

            <div class="form-group row">
                <label class="col-md-3 col-form-label">{{'Study' | translate}} Name</label>
                <div class="col-md-9">
                    <input type="text"
                           name="studyName"
                           [(ngModel)]="filter.StudyName"
                           class="form-control input-medium" />
                </div>
            </div>

            <div class="form-group row">
                <label class="col-md-3 col-form-label">Start Date Range</label>
                <div class="col-md-9 d-flex flex-wrap">
                    <climb-ngb-date #dateControl="ngModel"
                                    [(ngModel)]="filter.DateStartedStart"
                                    name="Start Date Start"
                                    class="mr-1 mb-1"
                                    placeholder="From&hellip;"></climb-ngb-date>
                    <climb-ngb-date #dateControl="ngModel"
                                    [(ngModel)]="filter.DateStartedEnd"
                                    name="Start Date End"
                                    class="mr-1 mb-1"
                                    placeholder="To&hellip;"></climb-ngb-date>
                </div>
            </div>

            <div class="form-group row">
                <label class="col-md-3 col-form-label">End Date Range</label>
                <div class="col-md-9 d-flex flex-wrap">
                    <climb-ngb-date #dateControl="ngModel"
                                    [(ngModel)]="filter.DateEndedStart"
                                    name="End Date Start"
                                    class="mr-1 mb-1"
                                    placeholder="From&hellip;"></climb-ngb-date>
                    <climb-ngb-date #dateControl="ngModel"
                                    [(ngModel)]="filter.DateEndedEnd"
                                    name="End Date End"
                                    class="mr-1 mb-1"
                                    placeholder="To&hellip;"></climb-ngb-date>
                </div>
            </div>

            <div class="form-group row">
                <label class="col-md-3 col-form-label">Created Date Range</label>
                <div class="col-md-9 d-flex flex-wrap">
                    <climb-ngb-date #dateControl="ngModel"
                                    [(ngModel)]="filter.DateCreatedStart"
                                    name="Created Date Start"
                                    class="mr-1 mb-1"
                                    placeholder="From&hellip;"></climb-ngb-date>
                    <climb-ngb-date #dateControl="ngModel"
                                    [(ngModel)]="filter.DateCreatedEnd"
                                    name="Created Date End"
                                    class="mr-1 mb-1"
                                    placeholder="To&hellip;"></climb-ngb-date>
                </div>
            </div>

            <div class="form-group row">
                <label class="col-md-3 col-form-label">Created By</label>
                <div class="col-md-9">
                    <user-select [(model)]="filter.CreatedBy"
                                 [forceValidSelection]="false"></user-select>
                </div>
            </div>
        </form>
    </div><!-- /.col -->

    <div class="col-md-4">
        <form>
            <div class="form-group">
                <label>Maximum Rows</label>
                <select class="form-control input-small"
                        id="numJobs-{{domIdAddition}}"
                        name="numJobs"
                        required
                        [(ngModel)]="numJobs">
                    <option *ngFor="let item of numJobOptions" value="{{item}}">
                        {{item}}
                    </option>
                </select>
            </div>

            <div class="form-group form-check">
                <input type="checkbox"
                       class="form-check-input"
                       id="showCurrentTime-{{domIdAddition}}"
                       name="showCurrentTime"
                       [(ngModel)]="showCurrentTime" />
                <label class="col-form-label form-check-label"
                       for="showCurrentTime-{{domIdAddition}}">Show Current Time</label>
            </div>
        </form>

        <div class="btn-container">
            <button type="submit"
                    class="btn btn-lg btn-primary mb-1"
                    [disabled]="!isInputValid()"
                    (click)="createChart()">
                Create Chart
            </button>

            <button type="button"
                    class="btn btn-fw-wide btn-lg btn-secondary mb-1"
                    (click)="resetFilter()">
                Reset Filter
            </button>
        </div>

    </div><!-- /.col -->
</div> <!-- /.row -->

<div class="row">
    <div class="col-md-12">
        <div class="chart-container">
            <jobs-gantt-chart-renderer [filter]="filter"
                                       [numJobs]="numJobs"
                                       [showCurrentTime]="showCurrentTime"
                                       ></jobs-gantt-chart-renderer>
        </div> <!-- /.chart-container -->
    </div> <!-- /.col -->
</div> <!-- /.row -->

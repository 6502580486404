import {
    Injectable
} from '@angular/core';

import * as toastr from 'toastr';


import { LogLevel } from './models';

@Injectable()
export class ToastrService {

    constructor() {
        this.initialize();
    }

    private initialize() {
        toastr.options.closeButton = true;
        toastr.options.positionClass = 'toast-top-center';
        toastr.options.preventDuplicates = true;
        toastr.options.timeOut = 4000;
        toastr.options.escapeHtml = true;
    }


    showToast(message: string, logLevel: LogLevel) {
        switch (logLevel) {
            case LogLevel.Error:
                return toastr.error(message);
            case LogLevel.Info:
                return toastr.info(message);
            case LogLevel.Success:
                return toastr.success(message);
            case LogLevel.Warning:
                return toastr.warning(message);
            default:
                return toastr.info(message);
        }
    }

    showBanner(message: string, title: string, logLevel: LogLevel, clickCallBack: any) {
        const options: ToastrOptions = {
            timeOut: 0,
            extendedTimeOut: 0,
            hideDuration: 0,
            closeButton: true,
            tapToDismiss: false,
            onCloseClick: () => clickCallBack(),
            toastClass: 'toast-full-width'
        };
        switch (logLevel) {
            case LogLevel.Error:
                toastr.error(message, title, options);
                break;
            case LogLevel.Info:
                toastr.info(message, title, options);
                break;
            case LogLevel.Success:
                toastr.success(message, title, options);
                break;
            case LogLevel.Warning:
                toastr.warning(message, title, options);
                break;
            default:
                toastr.info(message, title, options);
                break;
        }
    }
}

import { 
    BulkEditOptions, 
    BulkEditField, 
    BulkEditConfig,
    BulkEditFieldConfig,
} from './models';
import { setSafeProp } from '../util';
/**
 * Logic shared between BulkAdd and BulkEditTable components
 */
export class BulkEditSharedLogic {
    updateBulkOptions(options: BulkEditOptions, facet: any = null) {
        if (!options || !options.fields) {
            return;
        }

        for (const field of options.fields) {
            if (field.fieldIsReadonly) {
                field.cellIsReadonly = true;
                field.hideFromAddScreen = true;
                field.hideFromAddScreen = true;
            }

            this.initUpdateObject(field);
        }

        this.initAddObject(options);

        // Merge the user configuration
        this.initFromConfig(options, facet);
    }

    /**
     * Ensures any required nested paths exist on the add object
     * @param options 
     */
    initAddObject(options: BulkEditOptions) {
        const addObject = {};
        for (const field of options.fields) {
            setSafeProp(addObject, field.modelPath, undefined);
        }
        options.__addObject = addObject;

        for (const field of options.fields) {
            if (field.onItemInit) {
                field.onItemInit(options.__addObject);
            }
        }
    }

    /**
     * Ensures any required nested paths exist on the update object
     */
    initUpdateObject(field: BulkEditField) {
        field.__updateObject = {};
        setSafeProp(field.__updateObject, field.modelPath, undefined);

        if (field.onItemInit) {
            field.onItemInit(field.__updateObject);
        }
    }

    /**
     *  Merge in the per-user facet configuration into the field options.
     */
    initFromConfig(options: BulkEditOptions, facet: any = null) {
        if (!facet) {
            // Facet isn't available yet
            return;
        }

        // Parse the JSON config
        const config = this.parseBulkEditConfig(facet);

        // Merge each field's config
        options.fields.forEach((field) => {
            const fieldConfig = config.fields[field.modelPath];

            if (fieldConfig || field.visible !== false) {
                // Fields are visible, unless explicitly hidden
                field.visible = !fieldConfig  || (fieldConfig.visible !== false);
            }
        });
    }

    /**
     * Parse the BulkEditConfiguration JSON string, or provide a blank config object
     */
    parseBulkEditConfig(facet: any): BulkEditConfig {
        try {
            if (facet.BulkEditConfiguration) {
                return JSON.parse(facet.BulkEditConfiguration);
            }
        } catch (e) {
            console.error('Could not parse BulkEditConfiguration', e);
        }

        return new BulkEditConfig();
    }

    /**
     * Update the facet's BulkEditConfiguration JSON string from the field options.
     */
    updateConfig(options: BulkEditOptions, facet: any) {
        // Start from scratch
        const config = new BulkEditConfig();

        options.fields.forEach((field) => {
            const fieldConfig = new BulkEditFieldConfig();

            // Fields are visible unless explicitly hidden
            fieldConfig.visible = (field.visible !== false);

            config.fields[field.modelPath] = fieldConfig;
        });

        // Pack into JSON
        // Note: this can be saved using WorkspaceService.saveBulksEditConfiguration()
        facet.BulkEditConfiguration = JSON.stringify(config);
    }
}

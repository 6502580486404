import {
    Component,
    Input,
    OnInit
} from '@angular/core';
import { TaskOutputReportReportFilter } from './task-output-report-filter.model';
import { CohortService } from '../../cohort/services/cohort.service';
import { notEmpty } from '../../common/util';
import { LoggingService } from '../../services/logging.service';
import { ChartService } from '../chart.service';
import {
    AnimalChartFilter,
    ChartFilter,
    ChartFilterGroup,
    CohortChartFilter,
    CohortIndividualAnimals,
    JobChartFilter,
    LineChartFilter,
    SexChartFilter,
    StudyChartFilter,
} from '../filters';
import { DataContextService } from './../../services/data-context.service';

const CURRENT_SELECTOR = "task-output-chart-renderer";
const SHOW_TOAST = true;


@Component({
    selector: CURRENT_SELECTOR,
    templateUrl: './task-output-chart-renderer.component.html',
    styles: [`
        .help-container {
          font-size: 13px;
          margin-bottom: 3px;
    }
    `]
})
export class TaskOutputChartRendererComponent implements OnInit {
    @Input() interval: string;
    @Input() filterGroups: ChartFilterGroup[];
    // The selected Output
    @Input() taskOutput: any;
    @Input() studies: any[];

    traces: any[];
   
    seriesLabelList: string[];

    dataSource: any[];

    // State
    loading: boolean;
    rendered: boolean;
    dataIncomplete: boolean;
    errorMessage: string;
    warningMessage: string;

    filterGroupsCount: number;

    constructor(
        private chartService: ChartService,
        private _cohortService: CohortService,
        private loggingService: LoggingService,
        private dataContext: DataContextService
    ) {}

    ngOnInit() {
        this.resetState();
    }

    resetState(): void {
        this.loading = false;
        this.dataIncomplete = false;
        this.rendered = false;
        this.errorMessage = "";
        this.warningMessage = "";
    }

    createChart(): void {
        this.resetState();

        if (this.taskOutput && notEmpty(this.filterGroups)) {
            this.loading = true;

            this.getData().then(() => {
                this.loading = false;
                this.drawChart();
            });
        }
    }

    getData(): Promise<void> {
        this.errorMessage = "";
        this.warningMessage = "";

        return this.dataContext.init().then(() => {
            this.traces = [];
            this.seriesLabelList = [];

            // Perform queries sequentially.
            // Copy filterGroups list so we can recurse it like a stack.
            const groups = this.filterGroups.slice();
            this.filterGroupsCount = groups.length;
            // when Cohort(Individual Animals) is selected, convert that cohort into it's individual animals, then get the data. 
            return this.getIndividualAnimalsFromCohort(groups).then(() => {
                return this.getDataInSequence(groups);
            });

        }).catch((error: any) => {
            this.loading = false;
            this.displayLoadError();
            this.loggingService.logError(
                "Failed to load data for chart: " + error.message,
                null,
                CURRENT_SELECTOR,
                SHOW_TOAST
            );
        });
    }

    /**
     * Gets animals from supplied cohorts, if a Cohort (Invdividual Animals) filter exists in the supplied filterGroup.
     * If the filter group contains more than one filter, the animals recieved from the cohort are filtered based on the filters in the supplied filter group.
     * This is to ensure that only relevant animals are sent to the API,so amount of series returned from the API is equal to the amount of groups, if the each animal fits the criteria. 
     * @param filterGroups
     */
    private getIndividualAnimalsFromCohort(filterGroups: any[]): Promise<void[]> {
        let promise: Promise<void[]> = Promise.all([]);

        // find the filter groups that contain a Cohort (individual animal) filter
        const individualAnimalGroups = filterGroups.filter((filterGroup: ChartFilterGroup) => {
            for (const filt of filterGroup.filters) {
                if (filt instanceof CohortIndividualAnimals) {
                    return true;
                }
            }
            return false;
        });
        this.filterGroupsCount -= individualAnimalGroups.length;

        // for that group, single out the non-cohort-indivdual animal filters and use those to filter the animals. 
        for (const group of individualAnimalGroups) {
            const nonCohortIndividualAnimalGroups = group.filters.filter((g: any) => !(g instanceof CohortIndividualAnimals));
            const cohortIndividualAnimalGroups = group.filters.filter((g: any) => g instanceof CohortIndividualAnimals);

            const cohortServicePromises: Promise<void>[] = new Array<Promise<void>>();
            for (const cohortIndivdualAnimal of cohortIndividualAnimalGroups) {
                const expands = [
                    "CohortMaterial.Material.Animal",
                    "CohortMaterial.Material.Line",
                    "CohortMaterial.Material.JobMaterial.Job"
                ];
                const cohortPromise = this._cohortService.getCohort(cohortIndivdualAnimal.model.C_Cohort_key, expands).then((cohort: any) => {
                    /* 
                     * once the cohort is received, get a list of animals that are either taken directly from the cohort's CohortMaterial array, 
                     * or filtered according to the other filters in the group.
                     */
                    let animals = [];
                    if (nonCohortIndividualAnimalGroups.length > 0) {
                        animals = this._filterAnimalsFromCohort(nonCohortIndividualAnimalGroups, cohort.CohortMaterial);
                    } else {
                        animals = cohort.CohortMaterial.map((cohortMaterial: any) => cohortMaterial.Material.Animal);
                    }
                    // for each animal, create a new FilterGroup, which will translate into a series. 
                    for (const animal of animals) {
                        const animalChartFilter = new AnimalChartFilter();
                        animalChartFilter.model = animal;
                        animalChartFilter.model.MaterialKey = animal.C_Material_key;
                        animalChartFilter.labelType = cohortIndivdualAnimal.labelType;
                        const newFilterGroup = new ChartFilterGroup();
                        newFilterGroup.filters = [animalChartFilter].concat(nonCohortIndividualAnimalGroups);
                        newFilterGroup.label = animalChartFilter.displayLabel;
                        filterGroups.push(newFilterGroup);
                        this.filterGroupsCount++;
                    }
                });
                cohortServicePromises.push(cohortPromise);
            }
            promise = Promise.all(cohortServicePromises);
        }

        return promise;
    }

    /**
     * Takes the animals from a cohort and filters them based off of the chart filters provided. 
     * The filtered animals will meet the conditions of all filters provided. 
     * Due to the nature of the filters, using the returned CohortMaterial entities from the Cohort will best represent the animals. 
     * @param chartFilter
     * @param cohortMaterials
     */
    private _filterAnimalsFromCohort(chartFilters: ChartFilter[], cohortMaterials: any[]): any[] {
        const filteredAnimals: any[] = [];

        for (const cohortMaterial of cohortMaterials) {
            let fitsFilter = true;
            for (const chartFilter of chartFilters) {
                if (chartFilter instanceof AnimalChartFilter) {
                    if (cohortMaterial.C_Material_key !== chartFilter.model.MaterialKey) {
                        fitsFilter = false;
                        break;
                    }
                } else if (chartFilter instanceof CohortChartFilter) {
                    if (cohortMaterial.C_Cohort_key !== chartFilter.model.C_Cohort_key) {
                        fitsFilter = false;
                        break;
                    }
                } else if (chartFilter instanceof JobChartFilter) {
                    // loop through all of the Jobs in the JobMaterial array and see if any of the Job keys match. 
                    let jobMatch = false;
                    for (const jobMaterial of cohortMaterial.Material.JobMaterial) {
                        if (jobMaterial.C_Job_key === chartFilter.model.JobKey) {
                            jobMatch = true;
                        }
                    }
                    if (!jobMatch) {
                        fitsFilter = false;
                        break;
                    }
                } else if (chartFilter instanceof LineChartFilter) {
                    if (cohortMaterial.Material.C_Line_key !== chartFilter.model.LineKey) {
                        fitsFilter = false;
                        break;
                    }
                } else if (chartFilter instanceof SexChartFilter) {
                    if (cohortMaterial.Material.Animal.C_Sex_key !== chartFilter.model.C_Sex_key) {
                        fitsFilter = false;
                        break;
                    }
                } else if (chartFilter instanceof StudyChartFilter) {
                    // loop through all of the Jobs in the JobMaterial array and see if any of the Study keys match. 
                    let studyMatch = false;
                    for (const jobMaterial of cohortMaterial.Material.JobMaterial) {
                        if (jobMaterial.Job.C_Study_key === chartFilter.model.StudyKey) {
                            studyMatch = true;
                        }
                    }
                    if (!studyMatch) {
                        fitsFilter = false;
                        break;
                    }
                }
            }
            if (fitsFilter) {
                filteredAnimals.push(cohortMaterial.Material.Animal);
            }
        }
        return filteredAnimals;
    }

    private getDataInSequence(filterGroups: ChartFilterGroup[]): Promise<void> {
        if (this.taskOutput && notEmpty(filterGroups)) {
            const filterGroup = filterGroups.pop();
            const reportFilter = this.createReportFilter(filterGroup);

            // if the report filter has no groups (possible, when the CohortIndividualAnimals filter is selected
            if (!reportFilter) {
                return this.getDataInSequence(filterGroups);
            }

            return this.chartService.getTaskOutputTimeSeries(reportFilter)
                .then((results: any) => {

                    if (!results.data) {
                        this.displayLoadError();
                        return;
                    }

                    // Traces with no data cause weird displays, so discard those.
                    if (results.data.length === 0) {
                        return;
                    }

                    this.seriesLabelList.push(filterGroup.label);
                    this.traces.push(results.data);
                }).then(() => {
                    // Move on to the next group
                    return this.getDataInSequence(filterGroups);
                });
        }
        return Promise.resolve();
    }

    combineSeries(seriesList: any[]): any[] {
        const newSeries: any[] = [];
        for (let i = 0; i < seriesList.length; i++) {
            const series = seriesList[i];
            for (const point of series) {
                const newPoint = {
                    interval: point.interval
                };
                newPoint[this.seriesLabelList[i] + 'value'] = point.value;
                newSeries.push(newPoint);
            }
        }
        return newSeries;
    }

    /**
     * Convert FilterGroup to TaskOutputReportReportFilter
     *
     * @param filterGroup
     */
    createReportFilter(filterGroup: ChartFilterGroup): TaskOutputReportReportFilter {
        const reportFilter = new TaskOutputReportReportFilter(this.interval);

        if (this.studies && this.studies.length) {
            reportFilter.studyKeys = this.studies.map((study: any) => study.StudyKey).join(",");
        }

        reportFilter.taskOutputKeys = this.taskOutput.C_Output_key + '';

        // do not generate a reportFilter out of a filter group that contains a CohortIndividualAnimals filter. 
        const hasCohortIndividualAnimalsFilter = filterGroup.filters.find((chartFilter: ChartFilter) => {
            return chartFilter instanceof CohortIndividualAnimals;
        }) !== undefined;
        if (hasCohortIndividualAnimalsFilter) {
            return null;
        }
        for (const chartFilter of filterGroup.filters) {
            chartFilter.setFilterValues(reportFilter);
        }

        return reportFilter;
    }

    loadingMessage(): string {
        const messageBase = 'Loading';
        const progress: number = 100 * (this.traces.length / this.filterGroupsCount);

        if (isFinite(progress)) {
            return messageBase + ' (' + Math.round(Number(progress)) + '%)';
        } else {
            return messageBase;
        }
    }

    displayLoadError(): void {
        this.errorMessage = "Failed to load data for chart. Please try again.";
    }

    drawChart(): void {
        const traceCount: number = this.traces.length;

        if (traceCount > 0) {
            if (traceCount !== this.filterGroupsCount) {
                this.dataIncomplete = true;
            }

            this.rendered = true;

            this.dataSource = this.combineSeries(this.traces);
        } else {
            this.warningMessage = "No data was found for these groups.";
        }
    }

    customizeTooltip(arg: any) {
        return {
            text: `<b>${arg.seriesName}</b>: ${arg.valueText}`,
        };
    }

}

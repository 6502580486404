import { Injectable } from '@angular/core';
import {
    EntityQuery,
    FilterQueryOp,
    Predicate,
    QueryResult
} from 'breeze-client';

import { notEmpty } from '../common/util/not-empty';

import { DataManagerService } from '../services/data-manager.service';
import { QueryDef } from '../services/query-def';
import { BaseEntityService } from '../services/base-entity.service';
import { sortObjectArrayByAccessor, getSafeProp } from '../common/util';
import { convertValueToLuxon } from '@common/util/date-time-formatting/convert-value-to-luxon';

@Injectable()
export class PlateService extends BaseEntityService {

    readonly ENTITY_TYPE = 'Plates';
    readonly ENTITY_NAME = 'Plate';

    constructor(
        private dataManager: DataManagerService
    ) {
        super();
    }

    getPlates(queryDef: QueryDef): Promise<QueryResult> {
        let query = this.buildDefaultQuery(this.ENTITY_TYPE, queryDef);

        this.ensureDefExpanded(queryDef, 'cv_PlateType');
        this.ensureDefExpanded(queryDef, 'Job');
        query = query.expand(queryDef.expands.join(','));

        let predicates: Predicate[] = [];
        if (queryDef.filter) {
            predicates = predicates.concat(this.buildPredicates(queryDef.filter));
        }

        if (notEmpty(predicates)) {
            query = query.where(Predicate.and(predicates));
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed) as Promise<QueryResult>;
    }

    buildPredicates(filter: any): Predicate[] {
        const predicates: Predicate[] = [];
        if (!filter) {
            return predicates;
        }

        if (filter.PlateID) {
            predicates.push(Predicate.create('PlateID', FilterQueryOp.Contains, { value: filter.PlateID }));
        }
        if (filter.ExternalPlateID) {
            predicates.push(
                Predicate.create('ExternalPlateID', FilterQueryOp.Contains, { value: filter.ExternalPlateID })
            );
        }
        if (filter.C_PlateType_key) {
            predicates.push(Predicate.create('C_PlateType_key', 'eq', filter.C_PlateType_key));
        }
        if (notEmpty(filter.jobs)) {
            const jobKeys = filter.jobs.map((job: any) => {
                return job.JobKey;
            });

            predicates.push(Predicate.create(
                'C_Job_key', 'in', jobKeys
            ));
        }

        if (filter.C_PlateStatus_key) {
            predicates.push(Predicate.create('C_PlateStatus_key', 'eq', filter.C_PlateStatus_key));
        }
        if (filter.DateBornStart) {
            predicates.push(Predicate.create('DateBorn', '>=', filter.DateBornStart));
        }
        if (filter.DateBornEnd) {
            const filterDate = convertValueToLuxon(filter.DateBornEnd);
            const datePredicate = filterDate.plus({"day": 1});
            predicates.push(Predicate.create('DateBorn', '<=', datePredicate));
        }
        return predicates;
    }

    getPlateByID(plateID: string): Promise<any> {
        const query = EntityQuery.from(this.ENTITY_TYPE)
            .where('PlateID', '==', plateID);

        return this.dataManager.returnSingleQueryResult(query);
    }

    getPlateByKey(plateKey: number): Promise<any> {
        const query = EntityQuery.from(this.ENTITY_TYPE)
            .where('C_Plate_key', '==', plateKey);

        return this.dataManager.returnSingleQueryResult(query);
    }

    getPlatePositions(plateTypeKey: number): Promise<any[]> {
        const query = EntityQuery.from('PlatePositions')
            .where('C_PlateType_key', '==', plateTypeKey)
            .orderBy('Ordinal');

        return this.dataManager.returnQueryResults(query);
    }

    createPlate(): any {
        const initialValues = { DateCreated: new Date() };
        return this.dataManager.createEntity(this.ENTITY_NAME, initialValues);
    }

    getPlateMaterials(plateKey: number): Promise<any[]> {

        const expands = [
            'Material.Animal',
            'Material.Line.LineGenotypeAssay.cv_GenotypeAssay',
            'Material.Sample',
            'PlatePosition'
        ];

        const query = EntityQuery.from("PlateMaterials")
            .expand(expands.join(','))
            .where('C_Plate_key', "==", plateKey)
            .orderBy('PlatePosition.Ordinal');

        return this.dataManager.returnQueryResults(query);
    }

    getFilteredPlates(
        partialOnly: boolean, filterText: string, maxResultsCount: number
    ): Promise<any[]> {
        let query = EntityQuery.from(this.ENTITY_TYPE)
            .orderBy('PlateID');

        if (filterText && filterText.length > 0) {
            query = query.where('PlateID', FilterQueryOp.Contains, { value: filterText });
        }

        if (partialOnly) {
            query = query.select('C_Plate_key, PlateID');
        }

        if (maxResultsCount) {
            query = query.top(maxResultsCount);
        }

        return this.dataManager.returnQueryResults(query);
    }   

    arePlatesSafeToDelete(plateKeys: number[]): Promise<boolean> {
        const query = EntityQuery.from('Genotypes')
            .where('C_Plate_key', 'in', plateKeys);

        // safe if there are no JobCohorts
        return this.dataManager.returnQueryCount(query).then((count) => {
            return count === 0;
        });
    }


    createPlateMaterial(initialValues: any): any {
        const manager = this.dataManager.getManager();
        return manager.createEntity('PlateMaterial', initialValues);
    }

    createPlateMaterials(
        plateKey: number,
        materialstoAdd: any[], 
        plateMaterials: any[]
    ) {

        plateMaterials = sortObjectArrayByAccessor(plateMaterials, (plateMaterial) => {
            return getSafeProp(plateMaterial, 'PlatePosition.Ordinal');
        });

        for (const material of materialstoAdd) {
            // find first open position
            const openPosition = plateMaterials.find((plateMaterial) => {
                return plateMaterial.IsActive === 1 && 
                       plateMaterial.C_Material_key === null;
            });
            if (openPosition) {
                openPosition.C_Material_key = material.C_Material_key;
            }
        }
    }

    deletePlate(plate: any): void {
        while (plate.PlateMaterial.length > 0) {
            this.deletePlateMaterial(plate.PlateMaterial[0]);
        }
        this.dataManager.deleteEntity(plate);
    }

    deletePlateMaterial(plateMaterial: any): void {
        this.dataManager.deleteEntity(plateMaterial);
    }

    deleteMaterialInPosition(plate: any, position: any): void {
        const p1 = Predicate.create('C_Plate_key', "==", plate.C_Plate_key);
        const p2 = Predicate.create('C_PlatePosition_key', '==', position.C_PlatePosition_key);
        const predicates: Predicate = Predicate.and([p1, p2]);

        const query = EntityQuery.from('PlateMaterials')
            .where(predicates);

        const manager = this.dataManager.getManager();
        const data = manager.executeQueryLocally(query);
        if (data.length > 0) {
            this.dataManager.deleteEntity(data[0]);
        }
    }

    cancelPlate(plate: any) {
        if (!plate) {
            return;
        }

        if (plate.C_Plate_key > 0) {
            this._cancelPlateEdits(plate);
        } else {
            this._cancelNewPlate(plate);
        }
    }

    private _cancelNewPlate(plate: any) {
        try {
            this.deletePlate(plate);
        } catch (error) {
            console.error('Error cancelling new plate: ' + error);
        }
    }

    private _cancelPlateEdits(plate: any) {
        this.dataManager.rejectEntityAndRelatedPropertyChanges(plate);
        this.dataManager.rejectChangesToEntityByFilter(
            'PlateMaterial', (item: any) => {
                return item.C_Plate_key === plate.C_Plate_key;
            }
        );
        this.dataManager.rejectChangesToEntityByFilter(
            'PlatePosition', (item: any) => {
                return item.C_Plate_key === plate.C_Plate_key;
            }
        );
    }

    async ensureVisibleColumnsDataLoaded(plates: any[], visibleColumns: string[]): Promise<void> {
        const expands = this.generateExpandsFromVisibleColumns(plates[0], visibleColumns);
        return this.dataManager.ensureRelationships(plates, expands);
    }
}

import {
    Component,
    Input,
} from '@angular/core';

@Component({
    selector: 'warning-tooltip',
    template: `
<a ngbTooltip="{{text}}"
   container="body"
   placement="{{placement}}">
        <i class="fa fa-exclamation-triangle text-warning" aria-hidden="true"></i>
        <span class="sr-only">{{text}}</span>
</a>
`
})
export class WarningTooltipComponent {
    @Input() text: string;
    /**
     * Location of the tooltip.
     * Valid values: https://ng-bootstrap.github.io/#/components/tooltip/api
     */
    @Input() placement = 'top';

    constructor() {
        // Nothing to do
    }
}

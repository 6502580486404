import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { WsFilterEvent } from './ws-filter-event';

@Injectable()
export class WorkspaceFilterService {

    // state variables
    filterSourceId: string = null;
    filterKind: string = null;
    filterIds: any[] = [];

    private filterChangeSource = new Subject<WsFilterEvent>();

    filterChange$ = this.filterChangeSource.asObservable();

    filterWorkspace(
        newFilterSourceId: string,
        newFilterKind: string,
        newFilterIds: any[]
    ) {
        // build an event to describe the old and new workspace filter
        const wsFilterEvent = {
            oldFilterSourceId: this.filterSourceId,
            oldFilterKind: this.filterKind,
            oldFilterIds: this.filterIds,
            filterSourceId: newFilterSourceId,
            filterKind: newFilterKind,
            filterIds: newFilterIds
        };
        this.filterSourceId = newFilterSourceId;
        this.filterKind = newFilterKind;
        this.filterIds = newFilterIds;
        this.filterChangeSource.next(wsFilterEvent);
    }

    clearWorkspaceFilter() {
        this.filterWorkspace(null, null, []);
    }

    getFilterKind(): string {
        return this.filterKind;
    }

    getFilterIds(): any[] {
        return this.filterIds;
    }

}

import { Injectable } from '@angular/core';

import { EnumerationService } from '../enumerations/enumeration.service';

/**
 * Common business logic for the Birth facet.
 */
@Injectable()
export class BirthLogicService {

    constructor(
        private enumerationService: EnumerationService
    ) {
        //
    }


    midnightTodayMilliseconds() {
        const midnight = new Date();

        midnight.setHours(0);
        midnight.setMinutes(0);
        midnight.setSeconds(0);
        midnight.setMilliseconds(0);

        return midnight.getTime();
    }

     attachEnumerations(characteristics: any[]) {
        for (const characteristic of characteristics) {
            if (characteristic.TaxonCharacteristic.C_EnumerationClass_key) {
                this.enumerationService.getEnumerationItems(
                    characteristic.TaxonCharacteristic.C_EnumerationClass_key
                ).then((items) => {
                    characteristic.EnumerationItems = items;
                });
            }
        }
    }
}

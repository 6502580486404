import { Injectable } from '@angular/core';
import { DataTableCommService } from '../datatable/services/data-table-comm.service';
import { FacetLoadingStateService } from './facet-loading-state.service';

import { AppInsightsService } from '../../services/app-insights.service';
import { LoggingService } from '../../services/logging.service';
import { PrivilegeService } from '../../services/privilege.service';

/**
 * Wrapper to hold all the services used by the BaseFacet class
 *   These are common functions that every facet makes use of
 */
@Injectable()
export class BaseFacetService {

    constructor(
        public appInsightsService: AppInsightsService,
        public dataTableCommService: DataTableCommService,
        public facetLoadingStateService: FacetLoadingStateService,
        public loggingService: LoggingService,
        public privilegeService: PrivilegeService
    ) {
        // do nothing
    }
   
}

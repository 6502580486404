import type { Sample } from './sample.interface';
import type { SampleCharacteristic } from './sample-characteristic.interface';
import type { TaxonCharacteristic } from './taxon-characteristic.interface';

export interface cv_Unit {
    Abbreviation: string;
    C_Unit_key: number;
    C_VocabularySource_key: number | null;
    C_Workgroup_key: number;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    ElementID: string;
    IsActive: boolean;
    IsDefault: boolean;
    IsDeprecated: boolean;
    ModifiedBy: string;
    Sample: Sample[];
    SampleCharacteristic: SampleCharacteristic[];
    SortOrder: number | null;
    TaxonCharacteristic: TaxonCharacteristic[];
    Unit: string;
    Version: number;
}

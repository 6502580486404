import { Injectable } from '@angular/core';
import { StoredFileService } from '@common/file';
import { ReasonForChangeService } from '@common/reason-for-change/reason-for-change.service';
import { DataManagerService } from '@services/data-manager.service';
import { FeatureFlagService } from '@services/feature-flags.service';
import { GlpOdataFacetSaveService } from '@services/glp-odata-facet-save.service';
import { LoggingService } from '@services/logging.service';
import { ToastrService } from '@services/toastr.service';
import { DeletionService } from '@services/deletion.service';
import { AnimalStateService } from './animal-state.service';

@Injectable()
export class AnimalSaveService extends GlpOdataFacetSaveService {
    protected saveSource = 'animal';

    constructor(
        protected dataManagerService: DataManagerService,
        protected deletionService: DeletionService,
        protected loggingService: LoggingService,
        protected featureFlagService: FeatureFlagService,
        protected reasonForChangeService: ReasonForChangeService,
        protected toastrService: ToastrService,
        private animalStateService: AnimalStateService,
    ) {
        super(
            dataManagerService,
            deletionService,
            loggingService,
            featureFlagService,
            reasonForChangeService,
            toastrService,
            animalStateService,
        );
    }
}

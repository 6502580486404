import {
    CellFormatterService,
    TableOptions,
    FormatterOptions
} from '../common/datatable';
import { AuditModifiedFieldComponent } from './audit-modified-fields/audit-modified-field.component';
import { AuditClassFactory } from './audit-class-maker';

export class AuditAnimalHealthRecordsTableOptions {
    options: TableOptions;

    constructor(
        private cellFormatterService: CellFormatterService
    ) {
        this.options = this.getDefaultOptions();
    }

    getDefaultOptions(): TableOptions {

        return {
            csvFileName: 'animalHealthRecords-audit.csv',
            enableDetailColumn: false,
            enableDraggable: false,
            enableSelectable: false,
            enableAuditColumns: false,
            columns: [
                {
                    displayName: 'Type',
                    field: 'UpdateType',
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Modified Fields',
                    field: 'ModifiedFields',
                    sortField: 'ModifiedFields',
                    rendererComponent: AuditModifiedFieldComponent,
                    exportFormatter: this.cellFormatterService.auditModifiedFieldsExportFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Date',
                    field: 'ModifiedDate',
                    formatter: this.cellFormatterService.dateTimeUTCFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Modified By',
                    field: 'ModifiedBy',
                    formatter: this.cellFormatterService.userNameFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Animal ID',
                    field: 'AnimalID',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('AnimalID', 'AnimalID'),
                    cellClass: AuditClassFactory.createCellClass('animalid'),
                },
                {
                    displayName: 'Animal Name',
                    field: 'AnimalName',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('AnimalName', 'AnimalName'),
                    cellClass: AuditClassFactory.createCellClass('animalname'),
                },
                {
                    displayName: 'Animal Health Tech',
                    field: 'ResourceName',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('ResourceName', 'ResourceName'),
                    cellClass: AuditClassFactory.createCellClass('resourcename'),
                },
                {
                    displayName: 'Urgent',
                    field: 'IsUrgent',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('IsUrgent', 'IsUrgent'),
                    cellClass: AuditClassFactory.createCellClass('isurgent'),
                },                
                {
                    displayName: 'Body Condition Score',
                    field: 'BodyConditionScore',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('BodyConditionScore', 'BodyConditionScore'),
                    cellClass: AuditClassFactory.createCellClass('bodyconditionscore'),
                },
                {
                    displayName: 'Last Confirmed Date and Time',
                    field: 'LastConfirmedDateAndTime',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('LastConfirmedDateAndTime', 'LastConfirmedDateAndTime', this.cellFormatterService.dateTimeUTCFormatter),
                    cellClass: AuditClassFactory.createCellClass('lastconfirmeddateandtime'),
                },
                {
                    displayName: 'Created By',
                    field: 'CreatedBy',
                    formatter: this.cellFormatterService.userNameFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Created Date',
                    field: 'DateCreated',
                    formatter: this.cellFormatterService.dateTimeUTCFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                }
            ]
        };
    }
}

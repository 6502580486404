import type { Marker } from './marker.interface';

export interface cv_MarkerType {
    C_MarkerType_key: number;
    C_VocabularySource_key: number | null;
    C_Workgroup_key: number;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    ElementID: string;
    IsActive: boolean;
    IsDefault: boolean;
    IsDeprecated: boolean;
    Marker: Marker[];
    MarkerType: string;
    ModifiedBy: string;
    SortOrder: number | null;
    Version: number;
}

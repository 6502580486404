<div class="detail-form-wrapper">
    <div class="detail-header">
        <h3>Animal Characteristic</h3>

        <detail-toolbar [facetPrivilege]="facetPrivilege"
                        [logTag]="COMPONENT_LOG_TAG"
                        (exit)="onCancel()"
                        [emitOnSave]="true"
                        (genericSaveEmitter)="onSaveCharacteristic()">
        </detail-toolbar>
    </div>

    <form class="detail-form" style="height:30rem">
        <fieldset [disabled]="facetPrivilege === 'ReadOnly'">
            <div class="form-group row">
                <label class="col-md-3 col-form-label">Active</label>
                <div class="col-md-9">
                    <input type="checkbox"
                           name="isActive"
                           class="mt-1"
                           [(ngModel)]="taxonCharacteristic.IsActive" />
                </div>
            </div>

            <div class="form-group row">
                <label class="col-md-3 col-form-label">Name</label>
                <div class="col-md-9">
                    <input type="text"
                           name="name"
                           [(ngModel)]="taxonCharacteristic.CharacteristicName"
                           class="form-control input-medium"
                           required />
                </div>
            </div>

            <div class="form-group row">
                <label class="col-md-3 col-form-label">Description</label>
                <div class="col-md-9">
                    <input type="text"
                           name="description"
                           [(ngModel)]="taxonCharacteristic.Description"
                           class="form-control input-medium" />
                </div>
            </div>

            <div class="form-group row">
                <label class="col-md-3 col-form-label">Species</label>
                <div class="col-md-9">
                    <climb-multi-select *ngIf="taxons.length"
                                        [(model)]="selectedTaxons"
                                        (modelChange)="onTaxonChange($event)"
                                        [values]="taxons"
                                        [placeholder]="'Select Species'"
                                        [keyProperty]="'C_Taxon_key'"
                                        [valueProperty]="'Taxon'"
                                        [displayAllSelectedText]="false"
                                        [buttonClasses]="selectedTaxons.length > 0 ? '' : 'ng-invalid'">
                    </climb-multi-select>
                    <br />
                    <small>For changes in this selection to be applied to existing Animals, Climb must be reloaded.</small>
                </div>
            </div>

            <characteristic-data-type [characteristic]="taxonCharacteristic"
                                      [dataTypes]="dataTypes"
                                      [characteristicType]="'taxon'">
            </characteristic-data-type>

            <div class="form-group row">
                <label class="col-md-3 col-form-label">Last Reviewed</label>
                <div class="col-md-9">
                    <climb-ngb-date [(ngModel)]="taxonCharacteristic.DateLastReviewed"
                                    [ngModelOptions]="{ standalone: true }">
                    </climb-ngb-date>
                </div>
            </div>
        </fieldset>
    </form>
</div>

export interface cv_VariablePhraseType {
    C_VariablePhraseType_key: number;
    VariablePhraseType: VariablePhrasesTypesEnum;
    IsActive: boolean;
    SortOrder: number;
    CreatedBy: string;
    DateCreated: Date;
    ModifiedBy: string;
    DateModified: Date;
}

export enum VariablePhrasesTypesEnum {
    dosing = 'Dosing',
    sampling = 'Sampling',
    specialInstructions = 'Special Instructions',
    report = 'Report',
    imaging = 'Imaging',
    generalProcedures = 'General/Procedures',
    studyConditions = 'Study Conditions',
    surgeryPhrases = 'Surgery Phrases',
}

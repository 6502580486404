/**
 * Report filter for use with the task-output report web API.
 */
export class TaskOutputReportReportFilter {

    /* String of comma-delimited CohortKeys */
    cohortKeys: string;

    /* String of comma-delimited JobKeys */
    jobKeys: string;

    /* String of comma-delimited LineKeys */
    lineKeys: string;

    /* String of comma-delimited MaterialKeys */
    materialKeys: string;

    /* String of comma-delimited cv_Sex._Sex_keys */
    sexKeys: string;

    /* String of comma-delimited OutputKeys */
    taskOutputKeys: string;

    /* String of comma-delimited StudyKeyd */
    studyKeys: string;

    /* Data interval: 'Days' | 'Weeks' | 'Months' */
    interval: string;


    constructor(interval: string) {
        this.interval = interval;
    }
}

import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EditProfileComponent } from './edit-profile.component';

/*
* Service to view the edit-profile modal.
*/
@Injectable()
export class ViewEditProfileService {

    modalService: NgbModal;

    constructor(
        modalService: NgbModal
    ) {
        this.modalService = modalService;
    }

    openEditProfileModal() {
        this.modalService.open(EditProfileComponent);
    }
}

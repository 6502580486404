import type { EnumerationClass } from './enumeration-class.interface';
import type { JobCharacteristicInstance } from './job-characteristic-instance.interface';
import type { cv_DataType } from './cv-data-type.interface';
import type { cv_JobCharacteristicLinkType } from './cv-job-characteristic-link-type.interface';
import type { cv_JobCharacteristicType } from './cv-job-characteristic-type.interface';
import type { cv_Unit } from './cv-unit.interface';
import type { JobCharacteristicJobSubtype } from './job-characteristic-job-subtype.interface';
import type { JobCharacteristicIACUCProtocol } from './job-characteristic-iacuc-protcol.interface';
import type { JobCharacteristicJobType } from './job-characteristic-job-type.interface';

export interface JobCharacteristic {
    C_DataType_key: number;
    C_EnumerationClass_key: number | null;
    C_JobCharacteristicLinkType_key: number;
    C_JobCharacteristicType_key: number | null;
    C_JobCharacteristic_key: number;
    C_Unit_key: number | null;
    C_VocabularyClass_key: number | null;
    C_Workgroup_key: number;
    CharacteristicName: string;
    CreatedBy: string;
    DateCreated: Date;
    DateLastReviewed: Date | null;
    DateModified: Date;
    DefaultValue: string;
    Description: string;
    EnumerationClass: EnumerationClass;
    HasIACUCProtocol: boolean;
    IACUCProtocols: string;
    HasJobSubtype: boolean;
    JobSubtypes: string;
    HasJobType: boolean;
    JobTypes: string;
    InClientReport: boolean;
    IsActive: boolean;
    JobCharacteristicInstance: JobCharacteristicInstance[];
    ModifiedBy: string;
    NumericScale: number | null;
    SortOrder: number | null;
    TextLineCount: number | null;
    ValidationMaximum: string;
    ValidationMinimum: string;
    Version: number;
    cv_DataType: cv_DataType;
    cv_JobCharacteristicLinkType: cv_JobCharacteristicLinkType;
    cv_JobCharacteristicType: cv_JobCharacteristicType;
    cv_Unit: cv_Unit;
    JobCharacteristicIACUCProtocol: JobCharacteristicIACUCProtocol[];
    JobCharacteristicJobSubtype: JobCharacteristicJobSubtype[];
    JobCharacteristicJobType: JobCharacteristicJobType[];
}

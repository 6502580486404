import {
    Component,
    Input,
    OnInit,
    OnChanges,
    EventEmitter,
    Output,
    SimpleChanges
} from '@angular/core';

import { JobService } from './job.service';
import { FacetLoadingStateService } from '../common/facet';
import { OrderService } from '../orders/order.service';
import { FeatureFlagService } from '../services/feature-flags.service';
import { InstitutionService } from '../institution/institution.service';
import { ContactPerson, Entity, Job, JobInstitution, JobInstitutionBillingContact, Site } from '@common/types';

export interface ExtendedJobInstitution extends JobInstitution {
    SelectedSites?: Site[];
    InstitutionContacts?: ContactPerson[];
    SelectedContacts?: ContactPerson[];
}

/**
 * Table for adding institutions to jobs
 */
@Component({
    selector: 'job-institution-table',
    templateUrl: './job-institution-table.component.html'
})
export class JobInstitutionTableComponent implements OnInit, OnChanges {
    @Input() job: Job;
    @Input() showSiteContact = false;
    @Input() isDotmatics = false;
    @Input() required: boolean;
    @Input() readonly: boolean;
    @Input() loading: boolean;

    @Output() institutionChange: EventEmitter<number> = new EventEmitter<number>();

    isCRL = false;
    isRemoveBillingContact: boolean;

    constructor(
        private jobService: JobService,
        private facetLoadingStateService: FacetLoadingStateService,
        private orderService: OrderService,
        private featureFlagService: FeatureFlagService,
        private institutionService: InstitutionService
    ) {
        //
    }

    ngOnInit(): void {
        this.initialize();
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {
        // Re-initialize table when job.C_Job_key changes (only happens when Job is copied)
        if (changes.job && changes.job.previousValue && (changes.job.currentValue.C_Job_key !== changes.job.previousValue.C_Job_key)) {
            this.initialize();
        }
        if (this.isCRL && !changes.loading?.firstChange && !changes.loading?.currentValue) {
            await this.initialize();
        }
    }

    async initialize(): Promise<void> {       
        await this.getAllInstitutionSites();
        this.initIsCRL();
        if (this.isCRL) {
            this.getJobInstitutionBillingContacts();
        }
    }

    initIsCRL(): void {
        const flag = this.featureFlagService.getFlag("IsCRL");
        this.isCRL = (flag && flag.IsActive && flag.Value.toLowerCase() === "true");
    }

    addJobInstitution(job: Job): void {
        if (this.readonly) {
            return;
        }
        const initialValues = {
            C_Job_key: job.C_Job_key,
        };
        this.jobService.createJobInstitution(initialValues);
    }


    removeInstitution(jobInstitution: Entity<JobInstitution>): void {
        if (this.readonly) {
            return;
        }
        this.jobService.deleteJobInstitution(jobInstitution);
    }

    async getAllInstitutionSites(): Promise<void> {
        this.facetLoadingStateService.changeLoadingState(true);
        try {
            for (const jobInstitution of this.job.JobInstitution as ExtendedJobInstitution[]) {
                jobInstitution.SelectedSites = await this.orderService.getInstitutionSites(jobInstitution.C_Institution_key);
                jobInstitution.InstitutionContacts = await this.institutionService.getInstitutionContacts(jobInstitution.C_Institution_key);
            }
        } finally {
            this.facetLoadingStateService.changeLoadingState(false);
        }
    }

    getJobInstitutionBillingContacts(): void {
        for (const jobInstitution of this.job.JobInstitution as ExtendedJobInstitution[]) {
            jobInstitution.SelectedContacts = jobInstitution.JobInstitutionBillingContact.map((x: JobInstitutionBillingContact) => x.ContactPerson);
        }
    }

    getSites(institutionKey: number): void {
        this.orderService.getInstitutionSites(institutionKey).then((data) => {
            this.job.JobInstitution.filter((jobInstitution: ExtendedJobInstitution) => {
                if (jobInstitution.C_Institution_key === institutionKey) {
                    jobInstitution.SelectedSites = data;
                }
            });
        });
    }

    getContacts(institutionKey: number): void {
        if (!institutionKey) {
            return;
        }
        this.institutionService.getInstitutionContacts(institutionKey).then((data) => {
            this.job.JobInstitution.filter((jobInstitution: ExtendedJobInstitution) => {
                if (jobInstitution.C_Institution_key === institutionKey) {
                    jobInstitution.InstitutionContacts = data;
                }
            });
        });
    }

    institutionChanged(institutionKey: number, jobInstitution: ExtendedJobInstitution): void {
        jobInstitution.C_Site_key = null;
        this.getSites(institutionKey);
        this.getContacts(institutionKey);
        jobInstitution.SelectedContacts = [];
        this.removeContactsFromJobInstitution(jobInstitution);

        if (this.isDotmatics) {
            this.institutionChange.emit(institutionKey);
        }
    }

    removeContactsFromJobInstitution(jobInstitution: JobInstitution): void {
        jobInstitution.C_ScientificContactPerson_key = null;
        jobInstitution.C_AuthorizationContactPerson_key = null;
        jobInstitution.C_BillingContactPerson_key = null;
        for (const billingContact of jobInstitution.JobInstitutionBillingContact) {
            this.removeJobInstitutionBillingContact(billingContact);
        }
    }

    siteChanged(siteKey: number, jobInstitution: ExtendedJobInstitution): void {
        if (!this.isCRL) {
            jobInstitution.C_ScientificContactPerson_key = null;
            jobInstitution.C_BillingContactPerson_key = null;
            jobInstitution.C_AuthorizationContactPerson_key = null;
        }
    }

    institutionBillingContactChanged(updatedContact: ContactPerson, jobInstitution: ExtendedJobInstitution): void {
        const updatedBillingContact = jobInstitution.JobInstitutionBillingContact.find((x: JobInstitutionBillingContact) => x.ContactPerson.C_ContactPerson_key === updatedContact.C_ContactPerson_key);
        if (updatedBillingContact && this.isRemoveBillingContact) {
            this.removeJobInstitutionBillingContact(updatedBillingContact);
        } else {
            this.addJobInstitutionBillingContact(jobInstitution, updatedContact);
        }
    }

    addJobInstitutionBillingContact(jobInstitution: ExtendedJobInstitution, contactPerson: ContactPerson): void {
        if (this.readonly) {
            return;
        }
        const initialValues = {
            C_JobInstitution_key: jobInstitution.C_JobInstitution_key,
            C_ContactPerson_key: contactPerson.C_ContactPerson_key
        };
        this.jobService.createJobInstitutionBillingContact(initialValues);
    }

    removeJobInstitutionBillingContact(jobInstitutionBillingContact: JobInstitutionBillingContact): void {
        if (this.readonly) {
            return;
        }
        this.jobService.deleteJobInstitutionBillingContact(jobInstitutionBillingContact);
    }
}

<div class="detail-form-wrapper">

    <div class="detail-header">
        <h3>
            <span class="category-name"
                  *ngIf="reportType?.categoryName">
                {{reportType.categoryName}}:
            </span>
            {{reportType?.text}}
        </h3>

        <div class="detail-toolbar" aria-label="Report toolbar">
            <button type="button"
                    class="btn btn-secondary"
                    (click)="exitClicked()">
                <i class="fa fa-table" aria-hidden="true" title="Report Menu"></i>
                Menu
            </button>
        </div>
    </div>

    <form name="reportForm"
          id="reportForm-{{domIdAddition}}"
          class="detail-form">
        <fieldset>

            <ng-container [ngSwitch]="reportSource">
                <div *ngSwitchCase="ReportSource.Report">
                    <report-runner [reportType]="reportType"></report-runner>
                </div>

                <div *ngSwitchCase="ReportSource.BulkExport">
                    <bulk-export-runner [reportType]="reportType"></bulk-export-runner>
                </div>
                
                <div *ngSwitchCase="ReportSource.AnimalCheck">
                    <bulk-export-runner [reportType]="reportType"></bulk-export-runner>
                </div>
                <div *ngSwitchCase="ReportSource.CustomSearch">
                    <custom-search-runner [reportType]="reportType"></custom-search-runner>
                </div>

                <p class="text-danger" *ngSwitchDefault>
                    Error - Invalid report source: [{{reportSource}}]
                </p>
            </ng-container>

        </fieldset>
    </form>
</div>

import type { AnimalClinicalObservation } from './animal-clinical-observation.interface';
import type { AnimalDiagnosticObservation } from './animal-diagnostic-observation.interface';
import type { AnimalHealthRecord } from './animal-health-record.interface';
import type { ClinicalObservationDetail } from './clinical-observation-detail.interface';
import type { ResourceGroupMember } from './resource-group-member.interface';
import type { ScheduleNonTask } from './schedule-non-task.interface';
import type { Setting } from './setting.interface';
import type { TaskInstance } from './task-instance.interface';
import type { TaskOutputSet } from './task-output-set.interface';
import type { cv_ResourceGroup } from './cv-resource-group.interface';
import type { cv_ResourceType } from './cv-resource-type.interface';

export interface Resource {
    AnimalClinicalObservation: AnimalClinicalObservation[];
    AnimalDiagnosticObservation: AnimalDiagnosticObservation[];
    AnimalHealthRecord: AnimalHealthRecord[];
    AssignedToTaskInstance: TaskInstance[];
    BackgroundColor: string;
    C_ResourceGroup_key: number | null;
    C_ResourceType_key: number | null;
    C_Resource_key: number;
    C_Workgroup_key: number;
    ClinicalObservationDetail: ClinicalObservationDetail[];
    Comments: string;
    CompletedByTaskInstance: TaskInstance[];
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    DefaultHealthTechnician: Setting[];
    EmailAddress: string;
    ForegroundColor: string;
    IsActive: boolean;
    IsGroup: boolean;
    IsUser: boolean;
    ModifiedBy: string;
    ParentResourceGroupMember: ResourceGroupMember[];
    ResourceGroupMember: ResourceGroupMember[];
    ResourceName: string;
    ReviewedByTaskInstance: TaskInstance[];
    ScheduleNonTask: ScheduleNonTask[];
    TaskOutputSet: TaskOutputSet[];
    UserID: string;
    UserName: string;
    Version: number;
    cv_ResourceGroup: cv_ResourceGroup;
    cv_ResourceType: cv_ResourceType;
}

import type { AlertSubscription } from './alert-subscription.interface';
import type { AlertTriggeredEventNotification } from './alert-triggered-event-notification.interface';

export interface AlertSubscriptionModality {
    AlertSubscription: AlertSubscription;
    AlertTriggeredEventNotification: AlertTriggeredEventNotification[];
    C_AlertSubscriptionModality_key: number;
    C_AlertSubscription_key: number;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    ModalityName: string;
    ModifiedBy: string;
    Version: number;
}

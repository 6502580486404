import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import type { DialogOptions } from './dialog-options.interface';

@Component({
    selector: 'modal-dialog',
    templateUrl: './modal-dialog.component.html'
})
export class ModalDialogComponent {
    @Input() options: Omit<DialogOptions, 'systemOptions'>;

    constructor(
        private activeModal: NgbActiveModal,
    ) { }

    onActionClick<T>(value: T) {
        this.activeModal.close(value);
    }

    split(text: string): string[] {
        if (!text || !text.trim()) {
            return [];
        }
        return text.split('\n').filter((line) => !!line.trim());
    }
}

import { Subscription, from } from 'rxjs';
import { map } from 'rxjs/operators';
import { SampleService } from './../samples/sample.service';
import { AnimalService } from '../animals/services/animal.service';
import { CopyBufferService } from '../common/services/copy-buffer.service';
import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    ViewChild,
    OnDestroy,
    SimpleChanges,
} from '@angular/core';
import { NgForm } from '@angular/forms';

import {
    ExportPlateDetailService,
    ExportPlateDetailTyxService
} from './exports';
import { PlateService } from './plate.service';
import { PlateVocabService } from './plate-vocab.service';

import {
    BaseDetail,
    BaseDetailService,
    FacetView,
    PageState
} from '../common/facet';
import { IValidatable, SaveChangesService } from '../services/save-changes.service';
import { PrintPreviewService } from '../common/services';
import {
    notEmpty,
    randomId
} from '../common/util';
import { cv_PlateStatus, cv_PlateType, Entity, Plate, PlateMaterial } from '../common/types';

export interface Column {
    Column: number;
}

export interface Row {
    Row: number;
}

export interface ExtendedPlateMaterial extends PlateMaterial {
    IsSelected: boolean;
}

export interface ExtendedPlate extends Plate {
    PlateMaterial: ExtendedPlateMaterial[];
    Columns: Column[];
    Rows: Row[];
}

@Component({
    selector: 'plate-detail',
    templateUrl: './plate-detail.component.html'
})
export class PlateDetailComponent extends BaseDetail
    implements OnChanges, OnDestroy, IValidatable, OnInit {
    @Input() facet: any;
    @Input() plate: Entity<ExtendedPlate>;
    @Input() pageState: PageState;
    @Output() exit: EventEmitter<void> = new EventEmitter<void>();
    @Output() next: EventEmitter<void> = new EventEmitter<void>();
    @Output() previous: EventEmitter<void> = new EventEmitter<void>();

    @ViewChild("plateForm") plateForm: NgForm;

    facetView: FacetView = FacetView.DETAIL_VIEW;

    // CVs
    plateTypes: Entity<cv_PlateType>[] = [];
    plateStatuses: Entity<cv_PlateStatus>[] = [];

    // State
    saving = false;

    printPreviewId: string;
    _loadPlatePositionsSubscription: Subscription;

    readonly COMPONENT_LOG_TAG = 'plate-detail';

    plateRows = 8;
    plateColumns = 12;

    constructor(
        baseDetailService: BaseDetailService,
        private exportPlateDetailService: ExportPlateDetailService,
        private exportPlateDetailTyxService: ExportPlateDetailTyxService,
        private plateService: PlateService,
        private plateVocabService: PlateVocabService,
        private printPreviewService: PrintPreviewService,
        private copyBufferService: CopyBufferService,
        private animalService: AnimalService,
        private sampleService: SampleService,
        private saveChangesService: SaveChangesService,
    ) {
        super(baseDetailService);
    }

    // lifecycle
    ngOnInit() {
        this.saveChangesService.registerValidator(this);
        this.printPreviewId = "platePrint-" + new Date().getTime();
        this.initialize();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.plate) {
            if (this.plate && !changes.plate.firstChange) {
                if (this.plateForm) {
                    this.plateForm.form.markAsPristine();
                }
                this.initialize();
            }
        }
    }

    initialize() {
        this.setLoading(true);

        return this.getCVs().then(() => {
            return this.getDetails();
        }).then(() => {
            this.setLoading(false);
        }).catch((error) => {
            this.setLoading(false);
            throw error;
        });
    }

    getCVs(): Promise<any> {
        const p1 = this.plateVocabService.plateTypes$.pipe(map((data) => {
            this.plateTypes = data;
        })).toPromise();

        const p2 = this.plateVocabService.plateStatuses$.pipe(map((data) => {
            this.plateStatuses = data;
        })).toPromise();

        return Promise.all([p1, p2]);
    }

    getDetails(): Promise<Entity<ExtendedPlate> | void> {
        if (this.plate && this.plate.C_Plate_key > 0) {
            return this.plateService.getPlateMaterials(this.plate.C_Plate_key).then(() => {
                this.loadPlate();
            });
        }

        return Promise.resolve(this.plate);
    }

    onCancel() {
        this.plateService.cancelPlate(this.plate);
    }

    typeChanged() {
        this.deletePlateMaterials();
        this.createPlateMaterials();
        this.loadPlate();
    }

    /**
     * Create all the new PlateMaterial records for the given PlateType.
     *
     *   PlateMaterial records are combinations of PlatePosition and Material.
     *   Material is null by default, until they are moved into the plate.
     */
    createPlateMaterials() {

        // Cancel any pending createMaterials events
        if (this._loadPlatePositionsSubscription) {
            this._loadPlatePositionsSubscription.unsubscribe();
        }

        // load the new PlatePositions
        const loadPlatePositions$ = from(
            this.plateService.getPlatePositions(this.plate.C_PlateType_key)
        );
        // Set the subscription (which may be cancelled if needed)
        this._loadPlatePositionsSubscription = loadPlatePositions$.subscribe((platePositions) => {
            // Use PlatePositions as template for creating all the blank
            //   PlateMaterial records
            for (const position of platePositions) {
                this.plateService.createPlateMaterial({
                    C_Plate_key: this.plate.C_Plate_key,
                    C_PlatePosition_key: position.C_PlatePosition_key,
                    // IsActive must be 1 b/c column datatype is int
                    IsActive: 1
                });
            }
        });
    }

    deletePlateMaterials() {
        while (this.plate.PlateMaterial.length > 0) {
            const plateMaterial = this.plate.PlateMaterial[0];
            this.plateService.deletePlateMaterial(plateMaterial);
        }
    }

    loadPlate() {
        this.plate.Columns = [];
        this.plate.Rows = [];

        for (let y = 1; y <= this.plate.cv_PlateType.Rows; y++) {
            this.plate.Rows.push({ Row: y });
        }
        for (let x = 1; x <= this.plate.cv_PlateType.Columns; x++) {
            this.plate.Columns.push({ Column: x });
        }
    }

    hasPlateMaterials() {
        return this.plate && notEmpty(this.plate.PlateMaterial);
    }

    selectAllPositions() {
        for (const plateMaterial of this.plate.PlateMaterial) {
            plateMaterial.IsSelected = true;
        }
    }

    removePlateMaterial(plateMaterial: any) {
        plateMaterial.Material = null;
        plateMaterial.C_Material_key = null;
    }

    skipSelectedPositions() {
        const selectedPlateMaterials = this.selectedPositions();
        for (const plateMaterial of selectedPlateMaterials) {
            this.skipPosition(plateMaterial);
        }
        this.selectNoPositions();
    }

    skipPosition(position: PlateMaterial) {
        position.IsActive = 0;
    }

    selectNoPositions() {
        for (const plateMaterial of this.plate.PlateMaterial) {
            plateMaterial.IsSelected = false;
        }
    }

    clearSelectedPositions() {
        const selectedPlateMaterials = this.selectedPositions();
        for (const plateMaterial of selectedPlateMaterials) {
            this.clearPosition(plateMaterial);
        }
        this.selectNoPositions();
    }

    clearAndReorderSelectedPositions() {
        const selectedPMs = this.selectedPositions();
        this.clearSelectedPositions();
        for (let i = 0; i < selectedPMs.length; i++) {
            if (this.isVerticalPlateType(this.plate.PlateMaterial)) {
                this.reorderMaterialByColumn(selectedPMs[i], i);
            } else {
                this.reorderMaterialByRow(selectedPMs[i], i);
            }
        }
    }
    
    isVerticalPlateType(plateMaterials: PlateMaterial[]) {
        const ordinal = 2;
        const positionName = "A2";
        const secondPM = plateMaterials[1];
        if ( secondPM.PlatePosition.Ordinal === ordinal &&
            secondPM.PlatePosition.PositionName === positionName) {
            return false;
        }
        return true;
    }

    reorderMaterialByRow(PM: PlateMaterial, count: number) {
        let YPosition = PM.PlatePosition.YPosition;
        let XPosition = PM.PlatePosition.XPosition;
        const numberOfRows = this.plateRows;
        const numberOfColumns = this.plateColumns;

        do {
            XPosition = XPosition - count % numberOfColumns;
            if (XPosition <= 0) {
                YPosition--;
                XPosition = numberOfColumns + XPosition;
            }
            count -= numberOfColumns;
        } while (count >= numberOfColumns);
        let item = this.getPlateMaterialByPosition(XPosition, YPosition);
        if (XPosition === numberOfColumns) {
            YPosition++;
            XPosition = 1;
        } else {
            XPosition = XPosition + 1;
        }
        for (let y = YPosition; y <= numberOfRows; y++) {
            for (let x = XPosition; x <= numberOfColumns; x++) {
                XPosition = 1;
                const nextItem = this.getPlateMaterialByPosition(x, y);
                item = this.shuffleMaterial(item, nextItem);
            }
        }
    }

    reorderMaterialByColumn(PM: PlateMaterial, count: number) {
        let YPosition = PM.PlatePosition.YPosition;
        let XPosition = PM.PlatePosition.XPosition;
        const numberOfRows = this.plateRows;
        const numberOfColumns = this.plateColumns;

        do {
            YPosition = YPosition - count % numberOfRows;
            if (YPosition <= 0) {
                XPosition--;
                YPosition = numberOfRows + YPosition;
            }
            count -= numberOfRows;
        } while (count >= numberOfRows);
        let item = this.getPlateMaterialByPosition(XPosition, YPosition);
        if (YPosition === numberOfRows) {
            XPosition++;
            YPosition = 1;
        } else {
            YPosition = YPosition + 1;
        }
        for (let x = XPosition; x <= numberOfColumns; x++) {
            for (let y = YPosition; y <= numberOfRows; y++) {
                YPosition = 1;
                const nextItem = this.getPlateMaterialByPosition(x, y);
                item = this.shuffleMaterial(item, nextItem);
            }
        }
    }

    // shuffle the material in wells and return next
    shuffleMaterial(item: PlateMaterial, nextItem: PlateMaterial) {
        if (nextItem) {
            if (nextItem.Material) {
                item.Material = nextItem.Material;
            } else {
                item.Material = null;
            }
        }
        return nextItem;
    }

    getPlateMaterialByPosition(x: number, y: number): PlateMaterial {
        let foundPM: PlateMaterial = null;
        for (const m of this.plate.PlateMaterial) {
            if (m.PlatePosition.XPosition === x && m.PlatePosition.YPosition === y) {
                foundPM = m;
            }
        }
        return foundPM;
    }

    clearPosition(position: PlateMaterial) {
        position.C_Material_key = null;
        position.IsActive = 1;
    }

    selectedPositions() {
        if (this.plate && this.plate.PlateMaterial) {
            return this.plate.PlateMaterial.filter((plateMaterial: any) => {
                return plateMaterial.IsSelected;
            });
        } else {
            return [];
        }
    }

    pasteAnimals() {
        if (this.facet.Privilege !== 'ReadWrite') {
            return;
        }

        if (this.copyBufferService.hasAnimals()) {

            const materials = [];
            for (const animal of this.copyBufferService.paste()) {
                materials.push({ C_Material_key: animal.C_Material_key });
            }

            this.plateService.createPlateMaterials(
                this.plate.C_Plate_key,
                materials,
                this.plate.PlateMaterial
            );
        }
    }

    copyPlate() {
        const animalsToCopy = [];
        if (notEmpty(this.plate.PlateMaterial)) {
            for (const plateMaterial of this.plate.PlateMaterial) {
                // if there is a material in the position, copy it
                if (plateMaterial.Material) {
                    animalsToCopy.push(plateMaterial.Material.Animal);
                }
            }
        }
        this.copyBufferService.copy(animalsToCopy);
    }

    onDropMaterials() {
        const materials: any[] = [];

        const animals = this.animalService.draggedAnimals;
        for (const animal of animals) {
            materials.push({ C_Material_key: animal.C_Material_key });
        }

        const samples = this.sampleService.draggedSamples;
        for (const sample of samples) {
            materials.push({ C_Material_key: sample.C_Material_key });
        }
        this.plateService.createPlateMaterials(
            this.plate.C_Plate_key,
            materials,
            this.plate.PlateMaterial
        );
    }

    // Export and print
    exportDetailCsv() {
        this.exportPlateDetailService.exportToCsv(this.plate);
    }

    exportDetailTyx() {
        this.exportPlateDetailTyxService.exportToCsv(this.plate);
    }

    printDetail() {
        const extraStyles = `<style>\n
            .d-print-none { display: none!important;}\n
            table { border-collapse: collapse; border-spacing: 0; }\n
            td { 
                border: 1px solid #000; 
                padding: 5px; 
                min-width: 75px; 
                vertical-align: top; 
            }\n
            ul { list-style: none; margin: 0; padding: 0; }\n
            li { margin-left: 0; padding-left: 0; }\n
            .position-name { font-weight: bold; }\n
            </style>\n
        `;
        this.printPreviewService.printFromId(this.printPreviewId, extraStyles);
    }

    // Formatters for <select> input
    plateTypeKeyFormatter = (value: any) => {
        return value.C_PlateType_key;
    }
    plateTypeFormatter = (value: any) => {
        return value.PlateType;
    }
    plateStatusKeyFormatter = (value: any) => {
        return value.C_PlateStatus_key;
    }
    plateStatusFormatter = (value: any) => {
        return value.PlateStatus;
    }

    async validate(): Promise<string> {
        if (!this.plate.cv_PlateStatus) {
            return "Plate Status must be set";
        }

        if (!this.plate.cv_PlateType) {
            return "Plate Type must be set";
        }

        if (!this.plate.PlateID.trim()) {
            return "Plate ID must be set";
        }
    }

    // select all boxes of this column
    onSelectColumn(checked: boolean , column: number) {
        for (const item of this.plate.PlateMaterial) {
            if (!item.PlatePosition) {
                continue;
            }
            if (item.PlatePosition.XPosition === column) {
                item.IsSelected = checked;
            }
        }
    }
    // select all boxes of this row
    onSelectRow(checked: boolean , row: number) {
        for (const item of this.plate.PlateMaterial) {
            if (!item.PlatePosition) {
                continue;
            }
            if (item.PlatePosition.YPosition === row) {
                item.IsSelected = checked;
            }
        }
    }

    ngOnDestroy() {
        this.saveChangesService.unregisterValidator(this);
    }
}

<header class="climb-header">
    <climb-logo class="header-logo" (click)="navigateToDashboard()"></climb-logo>
    <div class="left-section">
        <climb-workspace-selector *ngIf="canShowWorkspaceSelector()" [workspaces]="workspaces$ | async"></climb-workspace-selector>
        <climb-workspace-name-input *ngIf="isWorkspaceState() && isCustomizeWorkspaceActive()"></climb-workspace-name-input>
        <ng-container *ngIf="isWorkspaceState()">
            <climb-lock-toggle></climb-lock-toggle>
            <climb-auto-resize *ngIf="isCustomizeWorkspaceActive()"></climb-auto-resize>
            <climb-copy-buffer></climb-copy-buffer>
            <climb-save-changes-button></climb-save-changes-button>
        </ng-container>
    </div>
    <div class="fill"></div>
    <div class="right-section">
        <climb-info [isGLP]="isGLP"></climb-info>
        <climb-support [isGLP]="isGLP"></climb-support>
        <climb-message-center *ngIf="!isGLP"></climb-message-center>
        <climb-workgroup-switch></climb-workgroup-switch>
        <climb-user-menu></climb-user-menu>
    </div>
</header>

import type { Animal } from './animal.interface';
import type { MessageMap } from './message-map.interface';
import type { Resource } from './resource.interface';
import type { StoredFileMap } from './stored-file-map.interface';
import type { TaskAnimalHealthRecord } from './task-animal-health-record.interface';

export interface AnimalHealthRecord {
    Animal: Animal;
    C_Material_key: number;
    C_Resource_key: number | null;
    C_Workgroup_key: number;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    IsUrgent: boolean;
    MessageMap: MessageMap[];
    ModifiedBy: string;
    Resource: Resource;
    StoredFileMap: StoredFileMap[];
    TaskAnimalHealthRecord: TaskAnimalHealthRecord[];
    Version: number;
}

<ng-template #housingIdTmpl let-mating="item">
    <input type="text"
           name="MatingID"
           [(ngModel)]=" mating.MaterialPool.MaterialPoolID"
           required
           class="form-control input-medium"
           readonly="readonly">
</ng-template>

<ng-template #idTmpl let-mating="item">
    <input type="text"
           name="MatingID"
           [(ngModel)]="mating.MatingID"
           required
           class="form-control input-medium"
           readonly="readonly">
</ng-template>

<ng-template #statusTmpl let-mating="item">
    <active-vocab-select [(model)]="mating.C_MatingStatus_key"
                         [vocabChoices]="matingStatuses"
                         [keyFormatter]="matingStatusKeyFormatter"
                         [optionFormatter]="matingStatusFormatter"
                         [required]="requiredFields.includes('C_MatingStatus_key')"
                         [nullable]="!requiredFields.includes('C_MatingStatus_key')">
    </active-vocab-select>
</ng-template>

<ng-template #lineTmpl let-mating="item" let-section="section">
    <div style="min-width: 300px;" *ngIf="mating">
        <ng-container *ngIf="section===BulkEditSection.EditHeader">
            <line-select [(model)]="mating.C_Line_key"></line-select>
        </ng-container>
        <!-- line is required in AddScreen and InputCell -->
        <ng-container *ngIf="section===BulkEditSection.InputCell">
            <line-select [(model)]="mating.C_Line_key"
                         [required]="requiredFields.includes('C_Line_key')"
                         (modelChange)="onLineSelection($event, mating)"></line-select>
        </ng-container>
        <ng-container *ngIf="section===BulkEditSection.AddScreen">
            <line-select [(model)]="mating.C_Line_key"
                         [disabled]="addLineDisabled"
                         [required]="requiredFields.includes('C_Line_key')"
                         (modelChange)="onLineSelection($event, mating)"></line-select>
            <small class="form-text text-muted" *ngIf="addLineDisabled">
                {{'Line' | translate}} for each mating will be inherited from source.
            </small>
        </ng-container>
    </div>
</ng-template>

<ng-template #dateMatingTmpl let-mating="item">
    <climb-ngb-date #dateControl="ngModel"
                    name="Mating Date"
                    [(ngModel)]="mating.DateMating"
                    [ngModelOptions]="{ standalone: true }"
                    [required]="requiredFields.includes('DateMating')"></climb-ngb-date>
</ng-template>

<ng-template #typeTmpl let-mating="item">
    <active-vocab-select [(model)]="mating.C_MatingType_key"
                         [vocabChoices]="matingTypes"
                         [keyFormatter]="matingTypeKeyFormatter"
                         [optionFormatter]="matingTypeFormatter"
                         [required]="requiredFields.includes('C_MatingType_key')"
                         [nullable]="!requiredFields.includes('C_MatingType_key')">
    </active-vocab-select>
</ng-template>

<ng-template #purposeTmpl let-mating="item">
    <active-vocab-select [(model)]="mating.C_MatingPurpose_key"
                         [vocabChoices]="matingPurposes"
                         [keyFormatter]="matingPurposeKeyFormatter"
                         [optionFormatter]="matingPurposeFormatter"
                         [required]="requiredFields.includes('C_MatingPurpose_key')"
                         [nullable]="!requiredFields.includes('C_MatingPurpose_key')">
    </active-vocab-select>
</ng-template>

<ng-template #commentsTmpl let-animal="item">
    <input class="form-control input-medium"
           type="text"
           [(ngModel)]="animal.Comments"
           name="comments"
           [required]="requiredFields.includes('Comments')">
</ng-template>


import { FacetSharedModule } from './../common/facet/facet-shared.module';
import { SearchSharedModule } from './../search/search-shared.module';
import { EnumerationsSharedModule } from './../enumerations/enumerations-shared.module';
import { LocationSampleTableComponent } from './tables/location-sample-table.component';
import { LocationChildTableComponent } from './tables/location-child-table.component';
import { LocationAnimalTableComponent } from './tables/location-animal-table.component';
import { WorkspacesSharedModule } from './../workspaces/workspaces-shared.module';
import { LocationFacetComponent } from './location-facet.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { ClimbCommonModule } from '../common/climb-common.module';

import { LocationsSharedModule } from './locations-shared.module';
import { VocabulariesSharedModule } from '../vocabularies/vocabularies-shared.module';
import { LocationDetailComponent } from './location-detail.component';
import { IoTSharedModule } from '../iot/iot-shared.module';
import { SamplesSharedModule } from '../samples/samples-shared.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgbModule,
        ClimbCommonModule,
        FacetSharedModule,
        EnumerationsSharedModule,
        IoTSharedModule,
        LocationsSharedModule,
        SamplesSharedModule,
        SearchSharedModule,
        VocabulariesSharedModule,
        WorkspacesSharedModule
    ],
    declarations: [
        LocationDetailComponent,
        LocationFacetComponent,
        LocationAnimalTableComponent,
        LocationChildTableComponent,
        LocationSampleTableComponent
    ],
    exports: [
        LocationDetailComponent,
        LocationFacetComponent        
    ],
    providers: [
    ]
})
export class LocationsModule { }

<header>
  <div class="title">
    <h1>Workspaces</h1>
    <div class="title-actions">
      <climb-input *ngIf="(workspacesAmount$ | async) !== 0" size="md">
        <svg [climbIcon]="icons.magnifier"></svg>
        <input type="text"
               climbInput
               placeholder="Search"
               autocomplete="off"
               autocorrect="off"
               autocapitalize="off"
               spellcheck="false"
               [formControl]="searchControl" />
      </climb-input>
      <button data-automation-id="create-workspace-button"
              climbButton
              type="button"
              variant="primary"
              size="md"
              (click)="addWorkspace()">
        <svg [climbIcon]="icons.plusCircleFilled" size="sm"></svg>
        Create
      </button>
    </div>
  </div>
  <ng-container
    [ngTemplateOutlet]="messages"
    [ngTemplateOutletContext]="{ full: workspacesAmount$ | async, current: (workspaces$ | async).length }"
  ></ng-container>
  <ng-template #messages let-full="full" let-current="current">
    <ng-container *ngIf="!(loading$ | async); else spinner">
      <ng-container *ngIf="full === 0">
        <div class="subtitle">You do not have any workspaces yet</div>
        <div class="caption">You can use workspaces to build interfaces that accommodate your lab's processes</div>
      </ng-container>
  
      <ng-container *ngIf="full !== 0 && current === 0">
        <div class="subtitle">No matches found</div>
        <div class="caption">Try to change filter settings</div>
      </ng-container>
    </ng-container>
  </ng-template>

  <ng-template #spinner>
    <climb-spinner size="xl"></climb-spinner>
  </ng-template>
</header>
<main *ngIf="!(loading$ | async)">
  <ng-container [ngTemplateOutlet]="cards"
                [ngTemplateOutletContext]="{ workspaces: workspaces$ | async }">
  </ng-container>

  <ng-template #cards
               let-workspaces="workspaces">
    <climb-workspace-card *ngFor="let workspace of workspaces"
                          [key]="workspace.C_Workspace_key"
                          [name]="workspace.WorkspaceName"
                          [isFavorite]="workspace.IsFavourite"
                          [details]="workspace.WorkspaceDetail"
                          (open)="openWorkspace(workspace)">
    </climb-workspace-card>
  </ng-template>
 
  <button
    *ngIf="(searchValue$ | async).length === 0"
    class="add-workspace"
    (click)="addWorkspace()"
  >
    <svg [climbIcon]="icons.plus" size="lg"></svg>
    <span>Create workspace</span>
  </button>
</main>

import {
    Injectable,
} from '@angular/core';

import {
    NgbModal, NgbModalOptions,
} from '@ng-bootstrap/ng-bootstrap';
import { MonitoringServiceSubscriptionParameterComponent } from './monitoring-service-subscription-parameter-modal.component';

/*
* Service view schedule modals
*/
@Injectable()
export class MonitoringServiceSubscriptionParameterModalService {

    constructor(
        private modalService: NgbModal
    ) {
        //
    }

    openParameterModal(service: any, param: any, resources: any[]): Promise<any> {
        const modalOptions: NgbModalOptions = {
            backdrop: 'static',
            size: 'md'
        };

        const modalRef = this.modalService.open(MonitoringServiceSubscriptionParameterComponent, modalOptions);
        const component = modalRef.componentInstance as MonitoringServiceSubscriptionParameterComponent;
        component.service = service;
        component.param = param;
        component.resources = resources;

        return modalRef.result;
    }
}

<div class="row chart-settings-container pt-2">
    <div class="col-md-8">

        <form>
            <div class="task-output-controls">
                <task-output-select (outputSelectionChange)="selectOutput($event)"></task-output-select>
            </div><!-- /.task-output-controls -->
            <chart-filter-group-creator [chartFilterTypes]="filterTypes"
                                        [(model)]="filterGroups"
                                        [displayAnimalBirthDatesMessage]="true">
            </chart-filter-group-creator>

        </form>
    </div><!-- /.col -->

    <div class="col-md-4">
        <h4 class="chart-options-container">Chart Options</h4>

        <form class="chart-form">
            <div class="options-container">
                <h5>Frequency:</h5>
                <div class="radio-selection-container" *ngFor="let item of intervals">
                    <input class="radio-selection-item" 
                           type="radio"
                           id="interval-{{domIdAddition}}"
                           name={{item}}
                           [value]="item"
                           [(ngModel)]="interval"
                        />
                    <label class="radio-selection-label">{{item}}</label>
                </div>
            </div>
            <div class="options-container">
                <h5>Animal Label:</h5>
                <div class="radio-selection-container" *ngFor="let item of labels">
                    <input class="radio-selection-item"
                           type="radio"
                           id="label-{{domIdAddition}}"
                           name={{item}}
                           [value]="item"
                           [ngModel]="label$ | async"
                           (ngModelChange)="selectLabel($event)"
                        />
                    <label class="radio-selection-label">{{item}}</label>
                </div>
            </div>
            <div class="btn-container">
                <button type="submit"
                        class="surface-light button-md button-primary climb-button"
                        [disabled]="!isInputValid()"
                        (click)="createChart()">
                    Create Chart
                </button>
            </div>
        </form>
    
        <!-- Input hints -->
        <div *ngIf="!taskOutput" class="help-container text-info mb-2">
            <i class="fa fa-info-circle" aria-hidden="true"></i>
            Please select a task output.
        </div>
        <div *ngIf="filterGroups.length < 1" class="help-container text-info">
            <i class="fa fa-info-circle" aria-hidden="true"></i>
            Please add at least one animal group.
        </div>
    </div><!-- /.col -->
</div> <!-- /.row -->

<div class="row">
    <div class="col-md-12">
        <div class="chart-container">
            <task-output-chart-renderer [filterGroups]="filterGroups"
                                        [taskOutput]="taskOutput"
                                        [interval]="interval"></task-output-chart-renderer>

        </div> <!-- /.chart-container -->
    </div> <!-- /.col -->
</div> <!-- /.row -->

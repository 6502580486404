/*
* Adds .defer() method to Promises. Required by Breeze
*/

function _defer() {
    const deferred: any = {};
    deferred.promise = new Promise((resolve, reject) => {
        deferred.resolve = resolve;
        deferred.reject = reject;
    });
    return deferred;
}

// eslint-disable-next-line
Promise['defer'] = _defer;

import {
    Component
} from "@angular/core";
import { ICellRendererParams } from "ag-grid-community";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { AgGridCommService } from '../services/ag-grid-comm.service';
import { pencil } from '@icons';

@Component({
    selector: 'cell-detail-link',
    template: `
        <a data-automation-id="details-button"
           climbButton
           variant="ghost"
           size="sm"
           (click)="detailClicked()">
            <svg [climbIcon]="icons.pencil"></svg>
        </a>
    `,
    styleUrls: ['./cell-detail-link.component.scss'],
})
export class CellDetailLinkComponent implements ICellRendererAngularComp {
    public icons = { pencil };
    public params: ICellRendererParams;

    constructor(
        private agGridCommService: AgGridCommService
    ) { }

    agInit(params: ICellRendererParams): void {
        this.params = params;
    }

    /**
     * Required by ICellRenderedAngularComp.
     *   Called on cell refresh by ag-grid
     * @param params
     */
    refresh(params: any): boolean {
        this.params = params;
        return true;
    }

    detailClicked() {
        // pipe event to parent data-table component
        this.agGridCommService.detailLinkClicked(this.params.data);
    }
}

import { BehaviorSubject, Observable } from 'rxjs';
import { DataResponse, TableColumnDef, TableState } from '@common/datatable/data-table.interface';
import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { filterToDate } from '../common/util';

import { AuthService } from '../services/auth.service';
import { ImportService } from './import.service';
import {
    BaseFacet,
    BaseFacetService, 
    FacetView
} from '../common/facet';
import { WorkspaceFilterService } from '../services/workspace-filter.service';
import { CellFormatterService, ColumnsState } from '@common/datatable';
import { ImportTableOptions } from './import-table-options';
import { arrowClockwise, magnifier } from '@icons';

@Component({
    selector: 'import-facet',
    templateUrl: './import-facet.component.html'
})
export class ImportFacetComponent extends BaseFacet implements OnInit {
    @Input() facetId: string;
    @Input() facet: any;

    readonly icons = { arrowClockwise, magnifier };

    facetView: FacetView;
    filter: any;
    filterModal: any;
    ignoreWorkspaceFilter: boolean;
    isEditing: boolean;
    loading: boolean;
    loadingMessage: string;
    paginationOptions: any;
    importTableOptions: ImportTableOptions;

    readonly NEW_IMPORT_VIEW: FacetView = FacetView.NEW_IMPORT_VIEW;
    readonly componentName = 'import';

    dataTableColumns: BehaviorSubject<TableColumnDef[]>;
    dataTableColumns$: Observable<TableColumnDef[]>;

    constructor(
        private baseFacetService: BaseFacetService,
        private cellFormatterService: CellFormatterService,
        private importService: ImportService,
        private modalService: NgbModal,
        private authService: AuthService,
        workspaceFilterService: WorkspaceFilterService
    ) {
        super(
            baseFacetService,
            workspaceFilterService
        );

        this.importTableOptions = new ImportTableOptions(
            this.cellFormatterService
        );

        this.dataTableColumns = new BehaviorSubject(this.importTableOptions.options.columns);
        this.dataTableColumns$ = this.dataTableColumns.asObservable();

        this.dataService = {
            run: (tableState: TableState) => {
                return this.loadImportLogList(tableState);
            }
        };
    }

     // lifecycle
     ngOnInit() {
        super.ngOnInit();

        this.initialize();

        this.createPaginator();
    }

    initialize() {
        this.restoreFilterState();
        this.changeView(this.LIST_VIEW);
    }

    refreshData() {
        this.initialize();
        this.reloadTable();
    }

    async loadImportLogList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'StartDateTime DESC';

        this.setLoadingState(tableState.loadingMessage);

        try {
            const response = await this.importService.getImportLogs({
                page,
                size: pageSize,
                sort,
                filter: this.getActiveFilter()
            });

            const visibleColumns = this.getVisibleColumns(this.importTableOptions.options);
            await this.importService.ensureVisibleColumnsDataLoaded(response.results, visibleColumns);

            this.stopLoading();

            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        } finally {
            this.stopLoading();
        }
    }

    addItemClick() {
        this.changeView(this.NEW_IMPORT_VIEW);
    }

    cancelNewImport() {
        this.changeView(this.LIST_VIEW);
    }

    // Select newly created import log and redirect user to detail view
    createdImportLog(logKey: number) {
        this.importService.getImportLog(logKey).then((importLog: any) => {
            this.itemToEdit = importLog;
            this.changeView(this.DETAIL_VIEW);
        });
    }

    detailLinkClick(importLog: any) {
        this.itemToEdit = importLog;
        this.changeView(this.DETAIL_VIEW);
    }

    getUsername() {
        return this.authService.getCurrentUserName();
    }

    openModal(modal: any) {
        this.modalService.open(modal);
    }

    runImportFilter(newFilter: any) {
        this.filter = newFilter;
        this.loadImportLogList(this.tableState);
        this.reloadTable();
    }

    restoreFilterState() {
        // process any grid filters
        if (this.facet && this.facet.GridFilter) {
            try {
                this.filter = JSON.parse(this.facet.GridFilter);
            } catch (err) {
                console.error(err);
            }

            if (this.filter) {
                this.filter.StartDateTimeStart = filterToDate(this.filter.StartDateTimeStart);
                this.filter.StartDateTimeEnd = filterToDate(this.filter.StartDateTimeEnd);
            } else {
                this.filter = {};
            }
        }
    }

    changeView(viewName: FacetView) {
        this.facetView = viewName;
    }

    setLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }

    async selectedColumnsChange({ visible }: ColumnsState) {
        try {
            this.facetLoadingState.changeLoadingState(true);
            await this.importService.ensureVisibleColumnsDataLoaded(this.data, visible);
        } finally {
            this.facetLoadingState.changeLoadingState(false);
        }
    }
}

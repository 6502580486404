import type { ProtocolTask } from './protocol-task.interface';

export interface cv_TimeRelation {
    Abbreviation: string;
    C_TimeRelation_key: number;
    C_VocabularySource_key: number | null;
    C_Workgroup_key: number;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    ElementID: string;
    IsActive: boolean;
    IsDefault: boolean;
    IsDeprecated: boolean;
    MinutesPerUnit: number | null;
    ModifiedBy: string;
    ProtocolTask: ProtocolTask[];
    SortOrder: number | null;
    TimeRelation: string;
    Version: number;
}

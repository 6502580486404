import { Provider } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { CacheInterceptor } from './cache.interceptor';
import { GlobalHttpInterceptor } from './global-http.interceptor';
import { HttpCancelInterceptor } from './http-cancel.interceptor';
import { TokenInterceptor } from './token.interceptor';
import { UrlInterceptor } from './url.interceptor';
import { WorkgroupInterceptor } from './workgroup.interceptor';


/**
 * Http interceptor providers in outside-in order
 */
export const httpInterceptorProviders: Provider[] = [
    {
        provide: HTTP_INTERCEPTORS,
        useClass: UrlInterceptor,
        multi: true,
    },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: TokenInterceptor,
        multi: true,
    },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: WorkgroupInterceptor,
        multi: true,
    },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: CacheInterceptor,
        multi: true,
    },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: GlobalHttpInterceptor,
        multi: true
    },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: HttpCancelInterceptor,
        multi: true,
    },
];

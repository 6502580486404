<div class="bulk-edit-wrapper">
    <div class="bulk-edit-grid">
        <bulk-add [options]="bulkTemplates.bulkOptions"
                  [COMPONENT_LOG_TAG]="COMPONENT_LOG_TAG"
                  (save)="saveClicked($event)"
                  (exit)="exitClicked($event)"
                  (formClear)="formClear($event)"
                  (itemsToAddChange)="itemsToAddChanged($event)"
                  dataAutomationId="animals-saved">

            <ng-container extra-toolbar>
                <span *ngIf="bulkHousing.add && (bulkHousing.unhoused > 0)"
                      class="text-warning"
                      title="There are unhoused animals.">
                    <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                </span>
            </ng-container>

            <ng-container extra-after>
                <div *ngIf="activeFields.includes('Housing')" class="form-group row mt-3">
                    <label class="col-md-3 col-form-label">Housing</label>
                    <div class="col-md-9 housing">
                        <div class="form-group mt-1">
                            <input type="checkbox"
                                   [(ngModel)]="bulkHousing.add"
                                   (ngModelChange)="housingAddChanged()"
                                   [disabled]="!bulkHousing.enabled"
                                   name="housing_add"
                                   id="housing_add" />
                            <label for="housing_add">Create new housing units</label>
                        </div>
                        <ng-container *ngIf="bulkHousing.add">
                            <div class="form-group row">
                                <div class="col-2 col-md-3">
                                    <input type="number"
                                           class="housing-size"
                                           name="housing_size"
                                           step="1"
                                           min="1"
                                           [(ngModel)]="bulkHousing.size"
                                           (ngModelChange)="housingSizeChanged()" />
                                </div>
                                <div class="col-10 col-md-9">
                                    Animals per unit
                                </div>
                            </div>

                            <div class="form-group row">
                                <div class="col-2 col-md-3">{{bulkHousing.unhoused}}</div>
                                <div class="col-10 col-md-9">
                                    Unhoused Animals
                                    <a *ngIf="bulkHousing.unhoused"
                                       class="add-item-link"
                                       (click)="housingAddUnit()">
                                        <i class="fa fa-plus-circle" title="Add Unit"></i>
                                    </a>
                                </div>
                            </div>

                            <div class="form-group row">
                                <div class="col-2 col-md-3">
                                    <label>Units</label>
                                </div>
                                <div class="col-10 col-md-9 housing-units">
                                    <p *ngIf="bulkHousing.namingActive">
                                        Housing units will be named automatically
                                    </p>

                                    <div *ngFor="let unit of bulkHousing.units; let index = index; let last = last;"
                                         class="input-group align-items-center">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text px-2">Unit {{index + 1}}</span>
                                        </div>
                                        <input *ngIf="!bulkHousing.namingActive"
                                               type="text"
                                               class="form-control housing-unit-id"
                                               name="housing_unit_{{index}}_id"
                                               placeholder="ID"
                                               [required]="true"
                                               [(ngModel)]="unit.id" />
                                        <input type="number"
                                               class="form-control housing-unit-size"
                                               name="housing_unit_{{index}}_size"
                                               step="1"
                                               min="0"
                                               [(ngModel)]="unit.size"
                                               (change)="housingUnitChanged(unit, index)" />
                                        <a *ngIf="unit.size === 0"
                                           class="ml-1"
                                           (click)="housingDeleteUnit(unit, index)">
                                            <i class="fa fa-remove remove-item" title="Delete item"></i>
                                        </a>

                                    </div>
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text px-2">Add Unit</span>
                                        </div>
                                        <div class="input-group-append">
                                            <a class="add-item-link input-group-text px-3 border-left-0"
                                               (click)="housingAddUnit()">
                                                <i class="fa fa-plus-circle" title="Add Unit"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group row">
                                <div class="col-2 col-md-3">
                                    <label>Housing Type</label>
                                </div>
                                <div class="col-10 col-md-9">
                                    <active-vocab-select [(model)]="this.bulkHousing.materialPoolTypeKey"
                                                         [vocabChoices]="this.bulkHousing.materialPoolTypes"
                                                         [keyFormatter]="materialPoolTypeKeyFormatter"
                                                         [optionFormatter]="materialPoolTypeFormatter"
                                                         [required]="true"></active-vocab-select>
                                </div>
                            </div>

                            <div class="form-group row">
                                <div class="col-2 col-md-3">
                                    <label>Date</label>
                                </div>
                                <div class="col-10 col-md-9">
                                    <climb-ngb-date #dateControl="ngModel" name="date" [(ngModel)]="bulkHousing.date"></climb-ngb-date>
                                </div>
                            </div>

                            <div class="form-group row">
                                <div class="col-2 col-md-3">
                                    <label>Owner</label>
                                </div>
                                <div class="col-10 col-md-9">
                                    <user-select [(model)]="bulkHousing.owner"
                                                 [forceValidSelection]="false"></user-select>
                                </div>
                            </div>

                            <div class="form-group row">
                                <div class="col-2 col-md-3">
                                    <label>Location</label>
                                </div>
                                <div class="col-10 col-md-9">
                                    {{bulkHousing.location.PositionName}}
                                </div>
                            </div>

                            <div class="form-group row">
                                <div class="col-2 col-md-3">
                                    <label>Container Type</label>
                                </div>
                                <div class="col-10 col-md-9">
                                    <active-vocab-select [(model)]="this.bulkHousing.containerTypeKey"
                                                         [vocabChoices]="this.bulkHousing.containerTypes"
                                                         [keyFormatter]="containerTypeKeyFormatter"
                                                         [optionFormatter]="containerTypeFormatter"
                                                         [nullable]="true"></active-vocab-select>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </ng-container>
        </bulk-add>
    </div>
</div>

<animal-bulk-templates #bulkTemplates
                       [animals]="[]"
                       [loadDefaults]="true"
                       [animalsToAdd]="bulkHousing.itemsToAdd"
                       [activeFields]="activeFields"
                       [requiredFields]="requiredFields">
</animal-bulk-templates>

import { Injectable } from '@angular/core';
import { DataType } from '../data-type';

@Injectable()
export class TaskValidationService {
    isValidTaskInput(taskInput: any): boolean {
        const alwaysRequiredFieldsValid = taskInput.InputName && taskInput.C_DataType_key;
        const enumerationRequiredFieldsValid = this.isDataTypeEnumeration(taskInput) ? taskInput.C_EnumerationClass_key : true;
        const dosingTableRequiredFieldsValid = this.isDataTypeDosingTable(taskInput) ? taskInput.C_DosingTable_key : true;
        const jobCharacteristicRequiredFieldsValid = this.isDataTypeJobCharacteristic(taskInput) ? taskInput.C_JobCharacteristicType_key : true;
        const vocabularyRequiredFieldsValid = this.isDataTypeVocabulary(taskInput) ? taskInput.C_VocabularyClass_key : true;

        return alwaysRequiredFieldsValid && enumerationRequiredFieldsValid && dosingTableRequiredFieldsValid && jobCharacteristicRequiredFieldsValid && vocabularyRequiredFieldsValid;
    }

    isValidTaskOutput(taskOutput: any): boolean {
        const alwaysRequiredFieldsValid = taskOutput.OutputName && taskOutput.C_DataType_key;
        const enumerationRequiredFieldsValid = this.isDataTypeEnumeration(taskOutput) ? taskOutput.C_EnumerationClass_key : true;
        const vocabularyRequiredFieldsValid = this.isDataTypeVocabulary(taskOutput) ? taskOutput.C_VocabularyClass_key : true;

        return alwaysRequiredFieldsValid && enumerationRequiredFieldsValid && vocabularyRequiredFieldsValid;
    }

    isDataTypeEnumeration(taskInput: any): boolean {
        return taskInput.cv_DataType?.DataType === DataType.ENUMERATION || taskInput.cv_DataType?.DataType === DataType.LONG_ENUMERATION;
    }

    isDataTypeDosingTable(taskInput: any): boolean {
        return taskInput.cv_DataType?.DataType === DataType.DOSING_TABLE;
    }

    isDataTypeJobCharacteristic(taskInput: any): boolean {
        return taskInput.cv_DataType?.DataType === DataType.JOB_CHARACTERISTIC;
    }

    isDataTypeVocabulary(taskInput: any): boolean {
        return taskInput.cv_DataType?.DataType === DataType.VOCABULARY;
    }
}

import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { ClimbCommonModule } from '../common/climb-common.module';
import { ClimbServicesModule } from '../services/climb-services.module';

import { CharacteristicService } from './characteristic.service';
import { CharacteristicInputComponent } from './characteristic-input/characteristic-input.component';
import { CharacteristicVocabService } from './characteristic-vocab.service';
import { VocabulariesSharedModule } from '../vocabularies/vocabularies-shared.module';
import { CharacteristicInstanceListComponent } from './characteristic-instance-list/characteristic-instance-list.component';
import { CharacteristicInstanceWrapperComponent } from './characteristic-instance-wrapper/characteristic-instance-wrapper.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ClimbCommonModule,
        ClimbServicesModule,
        VocabulariesSharedModule
    ],
    declarations: [
        CharacteristicInputComponent,
        CharacteristicInstanceListComponent,
        CharacteristicInstanceWrapperComponent
    ],
    exports: [
        CharacteristicInputComponent,
        CharacteristicInstanceListComponent,
        CharacteristicInstanceWrapperComponent
    ]
})
export class CharacteristicsSharedModule {
    static forRoot(): ModuleWithProviders<CharacteristicsSharedModule> {
        return {
          ngModule: CharacteristicsSharedModule,
          providers: [
            CharacteristicService,
            CharacteristicVocabService
          ]
        };
    }
 }

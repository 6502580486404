import { Injectable, TemplateRef } from "@angular/core";

import { DataManagerService } from "@services/data-manager.service";

import { Entity } from "@common/types";
import { TableSort } from "@common/models";
import { ColumnSelect, ColumnSelectLabel } from "@common/facet";
import { getSafeProp, sortObjectArrayByAccessor, sortObjectArrayByAccessors } from "@common/util";

interface ColumnsMap {
    [key: string]: Column;
}

export interface Column {
    key: string;
    field: string;
    label: string;
    visible: boolean;
    headerTemplate?: TemplateRef<any>;
    rowTemplate?: TemplateRef<any>;
}

export interface Selectable {
    isSelected: boolean;
}

@Injectable()
export class EntityTableService<T> {
    private entities: (Entity<T> & Selectable)[];
    private columns: Column[];
    private columnsMap: ColumnsMap;
    private lastSelectedIndex = -1;
    
    public tableSort: TableSort;
    public allSelected = false;

    public columnSelect: ColumnSelect;

    constructor(
        private dataManager: DataManagerService
    ) {
        this.initialize();
    }

    private initialize() {
        if (!this.tableSort) {
            this.tableSort = new TableSort();
        }
    }

    public registerColumns(columns: Column[]) {
        this.columns = columns;

        this.columnsMap = {};
        for (const column of columns) {
            this.columnsMap[column.key] = column;
        }

        this.columnSelect = {
            model: this.columns.filter(c => c.visible).map(c => c.key),
            labels: this.columns.map(c => new ColumnSelectLabel(c.key, c.label))
        }

    }

    public registerEntities(entities: (Entity<T> & Selectable)[]) {
        this.entities = entities;
    }

    public registerTableSort(tableSort: TableSort) {
        this.tableSort = tableSort;
    }

    /**
     * Select all entities if not all are selected. Clear all entities if all are selected.
     */
    public selectAllEntities() {
        // Select or Unselect all rows
        if (this.entities) {
            const anySelected = this.isAnySelected();
            const allSelected = this.isAllSelected();

            if ((anySelected && !allSelected) || (!anySelected)) {
                this.allSelected = true;
            } else {
                this.allSelected = false;
            }

            for (const entity of this.entities) {
                entity.isSelected = this.allSelected;
            }

            this.lastSelectedIndex = -1;
        }
    }

    /**
     * Selects entity if not already selected. Unselects entity if already selected.
     */
    public selectEntity(index: number, event: any) {
        if (this.lastSelectedIndex < 0 || !event.shiftKey) {
            this.lastSelectedIndex = index;
        }
        /** Handles logic for shift selecting multiple entities */
        if (event.shiftKey) {
            let leftPointer: number;
            let rightPointer: number;
            
            const rangeOfRows = this.lastSelectedIndex - index;
            if (rangeOfRows > 0) {
                leftPointer = index;
                rightPointer = this.lastSelectedIndex;
            } else {
                leftPointer = this.lastSelectedIndex;
                rightPointer = index;
            }

            for (let i = leftPointer; i <= rightPointer; i++) {
                this.entities[i].isSelected = this.entities[this.lastSelectedIndex].isSelected;
            }
        } else {
            this.entities[index].isSelected = !this.entities[index].isSelected;
        }

        this.allSelected = this.isAllSelected();
    }

    private isAnySelected() {
        return this.entities.some(e => e.isSelected);
    }

    private isAllSelected() {
        return this.entities.every(e => e.isSelected);
    }

    /**
     * Deletes entity from Breeze
     */
    public removeEntity(entity: Entity<T>) {
        this.dataManager.deleteEntity(entity);
    }

    public sortColumn(column: string, event: any) {
        if (event.shiftKey) {
            this.tableSort.nested = true;
        } else {
            this.tableSort.nested = false;
        }

        this.tableSort.toggleSort(column);
        if (this.tableSort.nested) {
            const sortDefs = this.tableSort.properties.map((path: any) => {
                return {
                    sortAccessor: (item: any) => {
                        const prop =  getSafeProp(item, path);
                        if (prop === null) {
                            return null;
                        }
                        const compare = isNaN(prop) ? prop : parseFloat(prop);
                        return compare || 0;
                    },
                    descending: this.tableSort.nestedReverse[path]
                };
            });
            sortObjectArrayByAccessors(this.entities, sortDefs, this.tableSort.natural);
        } else {
            if (this.tableSort.propertyPath !== '') {
                sortObjectArrayByAccessor(this.entities, (item) => {
                    const prop = getSafeProp(item, this.tableSort.propertyPath);
                    if (prop === null) {
                        return null;
                    }
                    const compare = isNaN(prop) ? prop : parseFloat(prop);
                    return compare || 0;
                }, this.tableSort.reverse, this.tableSort.natural);
            }
        }
    }

    public getSortCaretClass(property: string) {
        return {
            'fa-caret-down': this.tableSort.nested ? this.tableSort.nestedReverse[property] : this.tableSort.reverse,
            'fa-caret-up': this.tableSort.nested ? !this.tableSort.nestedReverse[property] : !this.tableSort.reverse
        };
    }

    public async selectColumn(model: string[]) {
        this.columnSelect = {
            ...this.columnSelect,
            model
        };
        this.updateVisibleColumns();
    }

    private updateVisibleColumns() {
        const selected = {};
        this.columnSelect.model.forEach((key) => {
            selected[key] = true;
        });

        // Update the visibilty based on the column selections
        this.columnSelect.labels.forEach((column) => {
            this.columnsMap[column.key].visible = selected[column.key] || false;
        });
    }
}

import { map } from 'rxjs/operators';
import {
    Component,
    Input,
    OnChanges,
    OnInit,
    ViewChildren,
} from '@angular/core';

import { BirthVocabService } from '../birth-vocab.service';
import { ConfirmService } from '../../common/confirm';
import { LocationService } from '../../locations/location.service';
import { MaterialPoolService } from '../../services/material-pool.service';
import { NamingService } from '../../services/naming.service';
import { ReportingService } from '../../reporting/reporting.service';
import { TranslationService } from '../../services/translation.service';

import { DroppableEvent } from '../../common/droppable-event';

import {
    assignMaterialPoolAnimalCounts
} from '../helpers';
import { LocationHistoryComponent } from 'src/app/locations/location-history.component';
import { NgModel } from '@angular/forms';
import { dateControlValidator } from '@common/util/date-control.validator';

@Component({
    selector: 'birth-housing-table',
    templateUrl: './birth-housing-table.component.html'
})
export class BirthHousingTableComponent implements OnChanges, OnInit {
    @ViewChildren('dateControl') dateControls: NgModel[];
    @ViewChildren('locationHistory') locationHistories: LocationHistoryComponent[];
    @Input() birth: any;
    @Input() facetPrivilege: string;

    // CVs
    materialPoolTypes: any[] = [];

    // state
    housingNamingActive = false;

    // Wean card display name
    WEAN_TYPE: string;


    constructor(
        private birthVocabService: BirthVocabService,
        private confirmService: ConfirmService,
        private locationService: LocationService,
        private materialPoolService: MaterialPoolService,
        private namingService: NamingService,
        private reportingService: ReportingService,
        translationService: TranslationService
    ) {
        //
        this.WEAN_TYPE = translationService.translate('Wean');
    }

    ngOnInit() {
        this.initialize();
    }

    ngOnChanges(changes: any) {
        if (this.birth && !changes.birth.firstChange) {
            this.initialize();
        }
    }

    initialize() {
        return this.getCVs().then(() => {
            return this.isNamingActive();
        });
    }

    private getCVs(): Promise<any> {
        return this.birthVocabService.materialPoolTypes$.pipe(map((data) => {
            this.materialPoolTypes = data;
        })).toPromise();
    }

    private isNamingActive(): Promise<any> {
        return this.namingService.isHousingNamingActive().then((active: boolean) => {
            this.housingNamingActive = active;
        });
    }


    // Dropping
    onDropMaterialsToPool(event: DroppableEvent) {
        const materialPoolKey = event.dataKey;

        for (const animal of this.birth.birthAnimals) {
            if (animal.selectedForWean) {
                this.materialPoolService.createMaterialPoolMaterial({
                    C_MaterialPool_key: materialPoolKey,
                    C_Material_key: animal.C_Material_key
                });
            }
        }
    }


    // Removing
    removeWeanPool(materialPool: any) {
        if (this.poolHasOtherAnimals(materialPool)) {
            return this.confirmService.confirmDelete(
                'Delete Housing Unit',
                'Animals outside this birth exist in this housing unit. Continue with delete?'
            ).then(
                // confirmed
                () => {
                    this.deleteMaterialPool(materialPool);
                },
                // cancel
                () => { /* do nothing on cancel */ }
                );
        } else {
            // Pool has no other animals: delete w/o confirmation.
            this.deleteMaterialPool(materialPool);
        }
    }

    private deleteMaterialPool(materialPool: any) {
        this.materialPoolService.deleteMaterialPool(materialPool);

        const index = this.birth.pools.indexOf(materialPool);
        this.birth.pools.splice(index, 1);
    }

    removeMaterialFromPool(materialPoolMaterial: any) {
        this.materialPoolService.deleteMaterialPoolMaterial(materialPoolMaterial);
        assignMaterialPoolAnimalCounts(this.birth.pools);
    }

    /**
     * Does the pool have animals other than animals in this birth?
     */
    private poolHasOtherAnimals(materialPool: any) {
        // Ensure totalAnimalCount is up to date
        assignMaterialPoolAnimalCounts([materialPool]);

        const birthAnimals = [];
        // Get animals in this pool that belong to this birth
        for (const animal of this.birth.birthAnimals) {
            if (!animal.Material ||
                !animal.Material.MaterialPoolMaterial
            ) {
                continue;
            }

            const materialAssocs = animal.Material.MaterialPoolMaterial;
            for (const materialAssoc of materialAssocs) {
                if (materialAssoc.C_MaterialPool_key === materialPool.C_MaterialPool_key) {
                    birthAnimals.push(animal);
                }
            }
        }

        return materialPool.totalAnimalCount > birthAnimals.length;
    }


    // Locations
    createWeanPoolLocation(materialPool: any) {
        this.locationService.getDefaultLocationForLine(this.birth.Mating.C_Line_key)
            .then((defaultLocation) => {
                const initialValues = {
                    C_MaterialPool_key: materialPool.C_MaterialPool_key,
                    C_LocationPosition_key: defaultLocation.C_LocationPosition_key
                };
                this.locationService.createMaterialLocation(initialValues);
            });
    }

    removeLocation(materialPoolLocation: any) {
        this.materialPoolService.deleteMaterialPoolLocation(materialPoolLocation);
    }


    // Cage Cards
    requestCageCardWean(materialPool: any) {
        this.reportingService.requestCageCardWean([materialPool.C_MaterialPool_key]);
    }

    requestCageCardAnimal(materialPool: any) {
        const params = [];

        for (const animal of this.birth.birthAnimals) {
            for (const materialPoolMaterial of animal.Material.MaterialPoolMaterial) {
                if (
                    materialPoolMaterial.C_MaterialPool_key === materialPool.C_MaterialPool_key
                ) {
                    params.push(animal.C_Material_key);
                    break;
                }
            }
        }

        this.reportingService.requestCageCardAnimal(params);
    }


    // <select> formatters
    materialPoolTypeKeyFormatter = (value: any) => {
        return value.C_MaterialPoolType_key;
    }
    materialPoolTypeFormatter = (value: any) => {
        return value.MaterialPoolType;
    }

    validate() {
        return dateControlValidator(this.dateControls) ?? this.locationHistories.map(item => item.validate()).find(msg => msg);
    }
}

import { EventCallable } from 'effector';
import { InjectionToken, NgZone, Provider } from "@angular/core";
import { partial } from "lodash";
import { mappedStore$, mappedStore, StoreWrapper } from "../helpers";
import { workspaceStore, setActiveEvent } from "./workspace";
import { Store } from '../types';
import { WorkspaceState } from './types';


export * from './types';

export type WorkspaceStore = Store<WorkspaceState, {
  setActiveEvent: EventCallable<boolean>;
}>;

const Api: WorkspaceStore = {
  __store: workspaceStore,
  events: {
    setActiveEvent
  },
  getValue$: partial(mappedStore$, workspaceStore),
  getValue: partial(mappedStore, workspaceStore),
};

export const WORKSPACE_STORE_TOKEN = new InjectionToken<WorkspaceStore>('WORKSPACE_STORE_TOKEN');
export const WORKSPACE_STORE_PROVIDER: Provider = {
  provide: WORKSPACE_STORE_TOKEN,
  useFactory: (ngZone: NgZone) => {
    const wrapper = new StoreWrapper(ngZone);
    wrapper.setApi(Api as Store);
    return wrapper;
  },
  deps: [NgZone],
};

window.mfStore ??= {};
window.mfStore.workspaceStore = Api;
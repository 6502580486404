
<table class="table table-bordered job-pharma-tasks-list">
    <thead>
        <tr class="detail-grid-header">

            <th *ngIf="!readonly" class="icon-cell">
                <lock-control [(model)]="allLocked"
                [administrator]="isStudyDirector"
                (modelChange)="allLockedChanged()"></lock-control>
            </th>
            <!-- Select -->
            <th *ngIf="!readonly" class="icon-cell">
                <input type="checkbox"
                       name="selectAll"
                       [(ngModel)]="allSelected"
                       (ngModelChange)="allSelectedChanged()"
                       title="Select/Clear all" />
            </th>

            <!-- Blank header for remove column -->
            <th *ngIf="!readonly">
                <a (click)="removeProtocolInstances()"
                    [ngClass]="{'disable-delete': !isAnyProtocolSelected()}"
                    class="remove-task">
                    <i class="fa fa-remove remove-item" 
                        [title]="isAnyProtocolSelected() ? 'Remove selected protocols' : 'No protocols selected'"></i>
                </a>
            </th>

            <th *ngIf="visible.protocol"
                droppable
                (drop)="onDrop($event)"
                [dropDisabled]="readonly || !protocolService.draggedProtocols?.length"
                [attr.title]="readonly ? '' : 'Drop protocols here'">
                <a *ngIf="!readonly"
                   class="add-item-link"
                   (click)="addProtocolViaModal()">
                <i data-automation-id="add-protocol-tasks-button"
                   class="fa fa-plus-circle" 
                   title="Add protocol tasks">
                </i>
            </a>
                Protocol
            </th>

            <th *ngIf="visible.taskDefinition">Task</th>

            <th *ngIf="visible.taskAlias">Task Name</th>

            <th *ngIf="visible.cohorts">
                Cohorts
                <info-tooltip class="tool-tip"
                [text]="'Inclusion of cohorts in this column does not represent the cohort\'s assignment to all tasks'"></info-tooltip>
            </th>

            <th *ngIf="visible.individualAnimals">
                Individual Animals
                <info-tooltip class="tool-tip"
                [text]="'Inclusion of animals in this column does not represent the animal\'s assignment to all tasks'"></info-tooltip>
            </th>

            <th *ngIf="visible.sampleGroups">Sample Groups</th>

            <th *ngIf="visible.individualSamples">
                Individual Samples
                <info-tooltip class="tool-tip"
                [text]="'Inclusion of samples in this column does not represent the sample\'s assignment to all tasks'"></info-tooltip>
            </th>

            <th *ngIf="visible.status">Progress</th>
        </tr>
    </thead>
    <!-- open -->
    <loading-overlay *ngIf="loading" [message]="loadingMessage"></loading-overlay>
    <tbody *ngIf="!loading">
        <tr *ngFor="let protocol of protocols  |
                paginate: { itemsPerPage: 50, currentPage: taskPage, id: 'tasks-list-pagination' };
                let index = index"
            [ngClass]="{'selected-row': protocol.isSelected}"
            class="task-row">
            <td *ngIf="!readonly"
                class="icon-cell">
                <lock-control [(model)]="protocol.IsLocked"
                                        [administrator]="isStudyDirector"
                                        (modelChange)="isLockedChanged(protocol)"></lock-control>
            </td>
            <!-- Select -->
            <td *ngIf="!readonly"
                class="icon-cell">
                <input type="checkbox"
                    name="isSelected_{{index}}"
                    [(ngModel)]="protocol.isSelected"
                    (ngModelChange)="isSelectedChanged()" />
            </td>

            <!-- Remove Protocol -->
            <td *ngIf="!readonly" class="icon-cell">
                <a (click)="removeProtocolInstance(protocol)"
                    [ngClass]="{'disable-delete': !canRemoveProtocolInstance(protocol)}"
                    class="remove-task">
                    <i class="fa fa-remove remove-item"
                        [title]="canRemoveProtocolInstance(protocol) ? 'Remove protocol' : 'Cannot remove protocol'"></i>
                </a>
            </td>

            <!-- Protocol -->
            <td *ngIf="visible.protocol"
                droppable
                (drop)="onDropToProtocolInstance(protocol,protocol.IsLocked, $event)"
                [dropDisabled]="readonly || protocolService.draggedProtocols?.length > 0 || taskService.draggedTasks?.length > 0"
                [attr.title]="readonly ? '' : 'Add to Protocol'"
                class="protocol-name">
                {{protocol.ProtocolAlias}}
            </td>

            <!-- Task Definition -->
            <td *ngIf="visible.taskDefinition"
                class="task-definition">
                <div>
                    {{protocol.taskDefinitionNames | slice:0:5 | delimit: ', '}}<span *ngIf="protocol.taskDefinitionNames.length > 4">&hellip;</span>
                </div>
                <div class="font-italic">{{protocol.taskDefinitionNames.length}} Total</div>
            </td>

            <!-- Task -->
            <td *ngIf="visible.taskAlias"
                class="task-name">
                <div>
                    {{protocol.taskInstanceNames | slice:0:5 | delimit: ', '}}<span *ngIf="protocol.taskInstanceNames.length > 4">&hellip;</span>
                </div>
                <div class="font-italic">{{protocol.taskInstanceNames.length}} Total</div>
            </td>

            <!-- Cohorts -->
            <td *ngIf="visible.cohorts">
                <div class="label-list">
                    <ng-container *ngIf="protocol.taskPlaceholders">
                        <ng-container *ngFor="let placeholder of protocol.taskPlaceholders">
                            <ng-container *ngIf="placeholder.C_Placeholder_key">
                                <div class="cohort-badge" *ngIf="taskDontHaveMatchingCohort(placeholder, protocol.taskCohorts)">
                                    {{placeholder.Placeholder.PlaceholderName}}
                                </div>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                    <ng-container *ngFor="let tc of protocol.taskCohorts">
                        <div *ngIf="tc.Cohort as cohort"
                            class="cohort-badge"
                            [ngStyle]="{ backgroundColor: cohort.BackgroundColor, color: cohort.ForegroundColor }">
                            {{cohort.CohortName}}
                        </div>
                    </ng-container>
                </div>
            </td>

            <!-- Animals -->
            <td *ngIf="visible.individualAnimals">
                <div class="form-control-plaintext">
                    <ng-container *ngIf="protocol.taskPlaceholders">
                        <div *ngFor="let placeholder of protocol.taskPlaceholders">
                            <ng-container *ngIf="placeholder.C_AnimalPlaceholder_key">
                                <ng-container *ngIf="taskDontHaveMatchingAnimal(placeholder, protocol.taskMaterials)">
                                    {{placeholder.AnimalPlaceholder.Name}}
                                </ng-container>
                            </ng-container>
                        </div>
                    </ng-container>
                    <div *ngFor="let tm of protocol.taskMaterials">
                        <ng-container *ngIf="tm.Material?.Animal as animal">
                            {{animal.AnimalName}}
                        </ng-container>
                    </div>
                </div>
            </td>

            <!-- Sample Group -->
            <td *ngIf="visible.sampleGroups">
                <div *ngIf="protocol.numSources && protocol.numSourceAnimals">
                    {{protocol.numSourceAnimals}}
                    <img src="/images/animals.png" width="12" height="12" alt="Animal" title="Animal" />
                </div>
                <div *ngIf="protocol.numSources && protocol.numSourceSamples">
                    {{protocol.numSourceSamples}}
                    <img src="/images/samples.png" width="12" height="12" alt="Sample" title="Sample" />
                </div>
                <div *ngIf="protocol.numSources && protocol.numSourceAnimalPlaceholders">
                    {{protocol.numSourceAnimalPlaceholders}}
                    <img src="/images/AnimalPlaceholder.png" width="12" height="12" alt="Placeholder" title="Placeholder" />
                </div>
                <span *ngIf="!(protocol.numSourceAnimals) && !(protocol.numSourceSamples) && !(protocol.numSourceAnimalPlaceholders)">
                    {{(protocol.numSources) ? protocol.numSources : ''}}
                </span>
                <span class="sample-groups">
                    {{protocol.SampleGroupTypes}}
                </span>

            </td>

            <!-- Individual Samples -->
            <td *ngIf="visible.individualSamples">
                <div class="form-control-plaintext">
                    <div *ngFor="let taskMaterial of protocol.taskMaterials">
                        <ng-container *ngIf="taskMaterial.Material?.Sample">
                            {{taskMaterial.Material?.Sample.SampleName}}
                        </ng-container>
                    </div>
                </div>
            </td>

            <!-- Status -->
            <td *ngIf="visible.status"
                [ngClass]="{'done' : protocol.statusCount?.done}">
                {{protocol.statusCount?.endState}}/{{protocol.statusCount?.total}}
            </td>
        </tr>
    </tbody>
</table>

<pagination-controls (pageChange)="changeTaskPage($event)"
    [autoHide]="true"
    class="ngx-table-paginator"
    id="tasks-list-pagination"></pagination-controls>


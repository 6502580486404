import type { SampleOrder } from './sample-order.interface';

export interface cv_SampleCondition {
    Abbreviation: string;
    C_SampleCondition_key: number;
    C_VocabularySource_key: number | null;
    C_Workgroup_key: number;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    ElementID: string;
    IsActive: boolean;
    IsDefault: boolean;
    IsDeprecated: boolean;
    ModifiedBy: string;
    SampleCondition: string;
    SampleOrder: SampleOrder[];
    SortOrder: number | null;
    Version: number;
}

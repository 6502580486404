import { CalculatedFunction } from './available-functions';

export class Expression {
    /**
     * The parsed expression using the expression structure from the jsep
     * library.
     */
    parsedExpression: any;

    /**
     * Validation error message. Will be null in case of no error
     */
    validationError: string;

    /**
     * the expression segments used to build this expression. Note that
     * we could build expression segments from the parsedExpression, but
     * in that case formatting and parenthesis use would not be preserved
     */
    expressionSegments: ExpressionSegment[];
}

/**
 * This class will represent a segment of our expression which is one of:
 * input (an input variable), output (an output variable) or freetext
 */
export class ExpressionSegment {
    variableName: string = null;
    text: string = null;
    segmentKind: string = null;
    sortOrder: number = null;
    key: number;

    constructor(expSegment?: ExpressionSegment) {
        if (expSegment) {
            this.variableName = expSegment.variableName;
            this.text = expSegment.text;
            this.segmentKind = expSegment.segmentKind;
            this.key = expSegment.key;
        }
    }

    get isInput() {
        return this.segmentKind === 'input';
    }

    get isOutput() {
        return this.segmentKind === 'output';
    }

    get isFreeformText() {
        return this.segmentKind === 'freeform';
    }

    get isStandardPhraseVariable() {
        return this.segmentKind === 'standardPhraseVariable';
    }
}

/**
 * Factory functions for creating ExpressionSegments
 */
export class ExpressionSegmentFactory {
    /**
     * convenience function to create a free-text segment
     * @param text the text
     */
    static createTextSegment(text: string): ExpressionSegment {
        const segment = new ExpressionSegment();
        segment.text = text;
        segment.segmentKind = 'freeform';
        return segment;
    }

    /**
     * Convenience function to create an input variable segment
     * @param input the corresponding input
     */
    static createInputSegment(inputMapping: any): ExpressionSegment {
        const segment = new ExpressionSegment();
        segment.text = inputMapping.Input.InputName;
        segment.variableName = inputMapping.ExpressionVariableName;
        segment.segmentKind = 'input';
        segment.key = inputMapping.C_Input_key;
        return segment;
    }

    /**
     * Convenience function to create an output variable segment
     * @param output the correseponding output
     */
    static createOutputSegment(outputMapping: any): ExpressionSegment {
        const segment = new ExpressionSegment();
        segment.text = outputMapping.Output.OutputName;
        segment.variableName = outputMapping.ExpressionVariableName;
        segment.segmentKind = 'output';
        segment.key = outputMapping.C_Output_key;
        return segment;
    }

    /**
     * Convenience function to create an standard phrase variable segment
     * @param variableType the correseponding standardPhraseVariableType
     */
    static createStandardPhraseVariableSegment(variableType: any): ExpressionSegment {
        const segment = new ExpressionSegment();
        segment.text = variableType.StandardPhraseVariableType;
        segment.variableName = variableType.StandardPhraseVariableType;
        segment.segmentKind = 'standardPhraseVariable';
        return segment;
    }

    static createFunctionSegments(func: CalculatedFunction): ExpressionSegment {
        const segment = new ExpressionSegment();
        segment.text = `${func.name}( , )`;
        segment.segmentKind = 'freeform';
        return segment;
    }
}

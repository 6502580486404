import { PlateVocabService } from './plate-vocab.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { ClimbCommonModule } from '../common/climb-common.module';
import { ClimbServicesModule } from '../services/climb-services.module';

import { PlateService } from './plate.service';
import {
    ExportPlateDetailService,
    ExportPlateDetailTyxService
} from './exports';
import { PlateSelectComponent } from './plate-select.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ClimbCommonModule,
        ClimbServicesModule
    ],
    declarations: [
        PlateSelectComponent
    ],
    exports: [
        PlateSelectComponent
    ],
    providers: [
        PlateService,
        PlateVocabService,
        ExportPlateDetailService,
        ExportPlateDetailTyxService
    ]
})
export class PlatesSharedModule { }

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { ClimbCommonModule } from '../common/climb-common.module';
import { ClimbServicesModule } from '../services/climb-services.module';

import { GenotypeAssaySelectComponent } from './genotype-assay-select.component';
import { GenotypeComboSelectComponent } from './genotype-combo-select.component';
import { GenotypeSymbolSelectComponent } from './genotype-symbol-select.component';

import { GenotypeService } from './genotype.service';
import { GenotypeVocabService } from './genotype-vocab.service';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ClimbCommonModule,
        ClimbServicesModule
    ],
    declarations: [
        GenotypeAssaySelectComponent,
        GenotypeComboSelectComponent,
        GenotypeSymbolSelectComponent
    ],
    exports: [
        // needed by angular 1 or modals
        GenotypeAssaySelectComponent,
        GenotypeComboSelectComponent,
        GenotypeSymbolSelectComponent
    ],
    providers: [
        GenotypeService,
        GenotypeVocabService
    ]
})
export class GenotypesSharedModule { }

export interface MaterialLocation {
    DateOut?: Date | number;
    DateIn?: Date | number;
}

/**
 * Returns the current MaterialLocation from a list
 *   (i.e., the location with the latest DateIn and no DateOut).
 * @param materialLocations - list of MaterialLocations to search
 */
export function getLatestMaterialLocation<T extends MaterialLocation>(materialLocations: T[]): T | null {
    let out: T = null;

    if (materialLocations) {
        // Locations with a DateIn but no DateOut
        const openLocations = materialLocations.filter((materialLocation) => {
            return (!materialLocation.DateOut && materialLocation.DateIn);
        });

        if (openLocations && openLocations.length > 0) {
            // Sort by DateIn descending and return the first object
            openLocations.sort(sortObjectsByDateInDesc);
            out = openLocations[0];
        }
    }

    return out;
}

function sortObjectsByDateInDesc(o1: MaterialLocation, o2: MaterialLocation) {
    if (o1.DateIn > o2.DateIn) {
        return -1;
    }
    if (o1.DateIn < o2.DateIn) {
        return 1;
    }
    return 0;
}

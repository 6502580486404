import { Directive, Input, OnDestroy, OnInit } from '@angular/core';
import { BaseDetailService } from './base-detail.service';
import { BaseDetail } from '@common/facet/base-detail';
import { Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, map, skip } from 'rxjs/operators';
import { IValidatable } from '@services/save-changes.service';
import { IFacet } from '@common/facet/base-facet';
import { FacetView } from '@common/facet/facet-view.enum';
import { BreezeEntityChangedEventArgs, Entity } from '@common/types';
import { EntityChangeService } from '../../entity-changes/entity-change.service';
import { IGlpFacetSaveService } from '@services/interfaces/glp-facet-save-service.interface';

/**
 * New Base class for facet detail pages that will support facet-level saving
 *
 * @Directive - Angular directive decorator
 * @extends {BaseDetail} - Extends from BaseDetail
 * @implements {OnInit, IValidatable, OnDestroy} - Implements OnInit, IValidatable and OnDestroy interfaces
 */
@Directive()
export abstract class GlpBaseDetailBase<T> extends BaseDetail implements OnInit, IValidatable, OnDestroy {
    @Input() facet: IFacet;
    @Input() facetView: FacetView;

    hasChanges$ = this.dataContext.entityChanges$.pipe(
        map(() => this.hasChanges()),
        distinctUntilChanged(),
    );

    validationErrorsPresent = false;
    private facetLoadingChange = new Subject<boolean>();

    // can be triggered by child components in facet to indicate to user
    // that related data is still loading
    facetLoading$ = this.facetLoadingChange.asObservable();

    // optional loading message
    private readonly DEFAULT_LOADING_MESSAGE = 'Loading... Please wait.';
    private readonly SAVING_LOADING_MESSAGE = 'Saving... Please wait.';
    loadingMessage = this.DEFAULT_LOADING_MESSAGE;

    private _subscriptions: Subscription = new Subscription();

    constructor(
        protected baseDetailService: BaseDetailService,
        protected entitySaveService: IGlpFacetSaveService,
        protected entityChangeService: EntityChangeService,
    ) {
        super(baseDetailService);
    }

    ngOnDestroy(): void {
        this._subscriptions.unsubscribe();
        this.destroy();
    }

    /**
     * Abstract method to be implemented in child class to cleanup when component is destroyed
     */
    abstract destroy(): void;

    /**
     * Sets the loading indicator state and message
     * @param {boolean} isBusy - The loading state
     * @param {string} message - The loading message
     */
    setBusy(isBusy = true, message = this.DEFAULT_LOADING_MESSAGE): void {
        this.loadingMessage = message;
        this.facetLoadingState.setBusy(isBusy);
    }

    ngOnInit(): void {
        const s1 = this.facetLoadingState.changeLoadingState$.subscribe((v) => {
            this.facetLoadingChange.next(v);
        });
        this._subscriptions.add(s1);

        const s2 = this.entitySaveService.saveEntityLoading$
            .pipe(skip(1))
            .subscribe((isSaving) => {
                this.setBusy(
                    isSaving,
                    isSaving
                        ? this.SAVING_LOADING_MESSAGE
                        : this.DEFAULT_LOADING_MESSAGE);
            });
        this._subscriptions.add(s2);

        const s3 = this.entityChangeService.onAnyChange((entityChange: any) => {
            this.onEntityChange(entityChange);
        });
        this._subscriptions.add(s3);
    }

    /**
     * Validate and save the facet detail entity
     */
    abstract saveEntity(): Promise<void>;

    /**
     * Abstract method to get the facet entity or entities list for saving
     * @returns {Entity<T> | Entity<T>[]}
     */
    abstract getEntityForSaving(): Entity<T> | Entity<T>[];

    /**
     * Abstract method for validation
     * @returns {Promise<string>}
     */
    abstract validate(): Promise<string>;

    /**
     * Abstract method to be called before save to execute additional logic. E.g.: generate name for the entity.
     * @returns {Promise<void>}
     */
    abstract beforeSave(): Promise<void>;

    /**
     * Check if there are changes to be saved.
     * Overriding base class method to be able to check only for changes specific for facet
     * @returns {boolean}
     */
    abstract hasChanges(): boolean;

    /**
     * Handles unsaved changes when the user attempts to exit
     * @returns {Promise<boolean>} - true if frontend validation passed, otherwise false
     */
    async handleUnsavedChangesOnExit(): Promise<boolean> {
        if (this.hasChanges()) {
            const result = await this.viewUnsavedChangesModalService.openComponent(this.COMPONENT_LOG_TAG);

            if (result === 'save') {
                await this.saveEntity();
                return !this.validationErrorsPresent;
            } else {
                this.cancelAnyChanges();
            }
        }

        return true;
    }

    /**
     * TODO: Overridden base exitClicked method for backward compatibility.
     * Eventually it should be placed in base-detail.ts
     */
    async exitClicked(): Promise<void> {
        const validationPassed = await this.handleUnsavedChangesOnExit();

        if (validationPassed) {
            this.exit.emit();
        }
    }

    /**
     * TODO: Overridden base nextClicked method for backward compatibility.
     * Eventually it should be placed in base-detail.ts
     */
    async nextClicked(): Promise<void> {
        const validationPassed = await this.handleUnsavedChangesOnExit();

        if (validationPassed) {
            this.next.emit();
        }
    }

    /**
     * TODO: Overridden base previousClicked method for backward compatibility.
     * Eventually it should be placed in base-detail.ts
     */
    async previousClicked(): Promise<void> {
        const validationPassed = await this.handleUnsavedChangesOnExit();

        if (validationPassed) {
            this.previous.emit();
        }
    }

    /**
     * Abstract method to handle changes to the entity.
     * Should be implemented for synchronization of data between facets.
     * @param {BreezeEntityChangedEventArgs<T>} entityChange
     * @returns {Promise<void>}
     */
    abstract onEntityChange(entityChange: BreezeEntityChangedEventArgs<T>): Promise<void>;
}

import { Pipe, PipeTransform } from '@angular/core';

import { randomId } from '../util';

/**
 * Returns Animals from a list of Materials
 */
@Pipe({ 
    name: 'randomId',
})
export class RandomIDPipe implements PipeTransform {

    transform(value: string): string {
        return value + randomId();
    }
}

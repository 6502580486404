import { LoggingService } from './../../services/logging.service';
import { ProtocolService } from './../../protocol/protocol.service';
import {
    Component,
    Input,
    OnInit,
} from '@angular/core';

import {
    NgbActiveModal
} from '@ng-bootstrap/ng-bootstrap';

import { TaskType } from '../models';

import {
    randomId,
    uniqueArrayFromPropertyPath,
    notEmpty,
} from '../../common/util/';
import { TranslationService } from '../../services/translation.service';

@Component({
    selector: 'add-protocol',
    templateUrl: './add-protocol.component.html',
    styleUrls: ['./add-task.component.scss'],
})
export class AddProtocolComponent implements OnInit {
    @Input() taskType: TaskType;
    
    // State
    domIdAddition: string;
    protocolKey: number;
    isMixedProtocol = false;

    loading = false;

    readonly COMPONENT_LOG_TAG = 'AddProtocolComponent';

    constructor(
        private activeModal: NgbActiveModal,
        private loggingService: LoggingService,
        private protocolService: ProtocolService,
        private translationService: TranslationService,
    ) {
        //
    }

    ngOnInit() {
        this.domIdAddition = randomId();
        this.isMixedProtocol = false;

        // Defaults
        this.protocolKey = null;
    }

    onProtocolChange(): Promise<any> {
        this.isMixedProtocol = false;
        if (this.protocolKey > 0) {
            this.loading = true;
            return this.protocolService.checkIfMixedProtocol(
                this.protocolKey
            ).then((isMixed) => {
                this.isMixedProtocol = isMixed.data;
            }).then(() => {
                this.loading = false;
            }).catch((error) => {
                this.loading = false;
                throw error;
            });
        }
        return Promise.resolve();
    }

    onCancel(): void {
        this.activeModal.dismiss();
    }

    onSubmit(): void {
        if (this.taskType !== TaskType.Job) { // Create task instances on the frontend
            this.getTaskInstances().then((taskInstances: any[]) => {

                if (notEmpty(taskInstances) && this.isMixedProtocol) {
                    const message = `Not all tasks were added: only ${this.taskType} tasks can be added`;
                    const showToaster = true;
                    this.loggingService.logWarning(message, null, this.COMPONENT_LOG_TAG, showToaster);
                }

                this.activeModal.close(taskInstances);
            });
        } else { // Protocols are added to Jobs on the backend
            this.activeModal.close(this.protocolKey);
        }
    }

    getTaskInstances(): Promise<any[]> {
        // ensure WorkflowTask is loaded
        const expands = [
            'ProtocolTask.WorkflowTask.cv_TaskType',
            'ProtocolTask.WorkflowTask.Input',
            'ProtocolTask.InputDefault'
        ];
        return this.protocolService.getProtocol(
            this.protocolKey, expands
        ).then((protocol: any) => {

            // Check required values
            if (!protocol || !notEmpty(protocol.ProtocolTask)) {
                return [];
            }

            const seedDateDue: Date = null;
            const taskInputs: any[] = this._constructTaskInputs(protocol);

            const taskInstances: any[] = [];

            for (const protocolTask of protocol.ProtocolTask) {
                const workflowTask = protocolTask.WorkflowTask;

                if (workflowTask.cv_TaskType.TaskType === this.taskType) {
                    const taskInstance = {
                        protocol,
                        C_WorkflowTask_key: workflowTask.C_WorkflowTask_key,
                        C_ProtocolTask_key: protocolTask.C_ProtocolTask_key,
                        DateDue: seedDateDue,
                        TaskAlias: protocolTask.TaskAlias,
                        TaskInputs: taskInputs
                    };
                    taskInstances.push(taskInstance);
                }
            }
            return taskInstances;
        });
    }

    private _constructTaskInputs(protocol: any): any[] {
        const taskInputs: any[] = [];

        // Use InputDefaults
        const inputDefaults = uniqueArrayFromPropertyPath(
            protocol, 'ProtocolTask.InputDefault'
        );

        for (const inputDefault of inputDefaults) {
            const taskInput = {
                C_Input_key: inputDefault.C_Input_key,
                InputValue: inputDefault.InputValue
            };
            taskInputs.push(taskInput);
        }

        return taskInputs;
    }
}

import type { Pattern } from './pattern.interface';
import type { Protocol } from './protocol.interface';
import type { ProtocolTask } from './protocol-task.interface';
import type { TaskGroupSet } from './task-group-set.interface';
import type { cv_Frequency } from './cv-frequency.interface';

export interface TaskGroup {
    C_Frequency_key: number | null;
    C_Pattern_key: number | null;
    C_Protocol_key: number | null;
    C_TaskGroupSet_key: number | null;
    C_TaskGroup_key: number;
    C_ToEndFrequency_key: number | null;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    ModifiedBy: string;
    Occurrences: number | null;
    Offset: number | null;
    Pattern: Pattern;
    Protocol: Protocol;
    ProtocolTask: ProtocolTask[];
    TaskGroupSet: TaskGroupSet;
    TimeDifference: number | null;
    ToEnd: boolean;
    ToEndFrequency: cv_Frequency;
    Version: number;
    cv_Frequency: cv_Frequency;
}

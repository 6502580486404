import {
    Component,
    EventEmitter,
    Input,
    Output
} from '@angular/core';

/*
* Component to select Animal Source for a sample
*/
@Component({
    selector: 'source-material-multiselect',
    template: `
        <material-multiselect
            [model]="model"
            [enableStatusColumn]="false"
            (selectMaterial)="selectMaterial($event)"
            (removeMaterial)="removeMaterialSourceMaterial($event)"></material-multiselect>
    `
})
export class SourceMaterialMultiselectComponent {
    @Input() model: any[];
    @Output() modelChange: EventEmitter<any> = new EventEmitter<any>();

    selectMaterial(material: any) {
        this.addMaterialSourceMaterial(material);
    }

    private addMaterialSourceMaterial(sourceToAdd: any) {
        const item = {
            C_Material_key: sourceToAdd.C_Material_key,
            AnimalName: sourceToAdd.AnimalName,
            SampleName: sourceToAdd.SampleName
        };
        if (this.model) {
            this.model.push(item);
        } else {
            this.model = [item];
        }
        this.modelChange.emit(this.model);
    }

    removeMaterialSourceMaterial(sourceToRemove: any) {
        const index = this.model.findIndex((x: any) => x.C_Material_key === sourceToRemove.C_Material_key);

        if (index >= 0) {
            this.model.splice(index, 1);
        }
        this.modelChange.emit(this.model);
    }
}

/**
 * Options for Climb (ngx-barcode) barcodes.
 *
 * https://www.npmjs.com/package/ngx-barcode6
 */
export class BarcodeOptions {
    background: string;
    displayValue: boolean;
    elementType: 'svg' | 'img' | 'canvas';
    font: string;
    fontSize: number;
    format: string;
    height: number;
    lineColor: string;
    margin: number;
    marginBottom: number;
    marginLeft: number;
    marginRight: number;
    marginTop: number;
    width: number;
}

import { EntityInit, PropertyChange } from './entity-changes.interface';
import {
    notEmpty,
    sortObjectArrayByProperty
} from '../common/util';

export class TaskInstanceChangeService {

    entityChangeService: PropertyChange & EntityInit;

    constructor(entityChangeService: PropertyChange & EntityInit) {
        this.entityChangeService = entityChangeService;
    }

    initHandlers() {
        this.entityChangeService.onPropertyChange(
            'TaskInstance', 'C_AssignedTo_key', (entity: any) => {
                this.assignedToChanged(entity);
            }
        );

        this.entityChangeService.onPropertyChange(
            'TaskInstance', 'DateComplete', (entity: any) => {
                this.dateCompleteChanged(entity);
            }
        ); 

        this.entityChangeService.onPropertyChange(
            'TaskInstance', 'DateDue', (entity: any) => {
                this.dateDueChanged(entity);
            }
        ); 

        this.entityChangeService.onEntityInit(
            'TaskInstance', (entity: any) => {
                this.initTaskInstance(entity);
            }
        ); 

        this.entityChangeService.onPropertyChange(
            'TaskInput', 'InputValue', (entity: any) => {
                this.taskInputChanged(entity);
            }
        );

        this.entityChangeService.onPropertyChange(
            'TaskInstance', 'TaskInput', (entity: any) => {
                this.taskInputListChanged(entity);
            }
        );

        this.entityChangeService.onEntityInit(
            'TaskInput', (entity: any) => {
                this.initTaskInput(entity);
            }
        ); 
    }
    
    initTaskInstance(entity: any) {
        this.setHasInvalidInputs(entity);
        this.setHealthRecordAssignedTo(entity);
    }

    assignedToChanged(entity: any) {
        this.setHealthRecordAssignedTo(entity);
    }

    dateCompleteChanged(entity: any) {
        this.setHealthRecordAssignedTo(entity);
    }

    dateDueChanged(entity: any) {
        this.setHealthRecordAssignedTo(entity);
    }

    taskInputChanged(taskInput: any) {
        this.setHasInvalidInputs(taskInput.TaskInstance);
    }

    taskInputListChanged(task: any) {
        this.setHasInvalidInputs(task);
    }

    initTaskInput(entity: any) {
        this.setHasInvalidInputs(entity.TaskInstance);
    }

    setHealthRecordAssignedTo(entity: any) {
        // Set a properties on any attached HealthRecord
        // called DerivedAssignedTo and DerivedDateDue
        const records = this._getTaskHealthRecords(entity);
        for (const record of records) {
            let dateDue = null;
            let assignedTo = '';

            const latestTask = this._getLatestTaskOfHealthRecord(record);
            if (latestTask) {

                dateDue = latestTask.DateDue;
                
                if (latestTask.AssignedToResource) {
                    assignedTo = latestTask.AssignedToResource.ResourceName;
                }
            }

            record.DerivedDateDue = dateDue;
            record.DerivedAssignedTo = assignedTo;
        }
    }

    setHasInvalidInputs(task: any) {
        // Set a property called HasInvalidInputs
        task.HasInvalidInputs = false;

        if (task.IsGroup && task.TaskCohort && task.TaskCohort.length > 0) {
            for (const tc of task.TaskCohort) {
                const taskCohortInputs = tc.TaskCohortInput;
                if (taskCohortInputs) {
                    for (const input of taskCohortInputs) {
                        if (this.isInputInValid(input)) {
                            task.HasInvalidInputs = true;
                            return;
                        }
                    }
                }
            }
        } else if (task.TaskInput) {
            for (const input of task.TaskInput) {
                if (this.isInputInValid(input)) {
                    task.HasInvalidInputs = true;
                    return;
                }
            }
        }
    }

    private isInputInValid(input: any) {
        return input.Input.IsRequired && input.Input.cv_DataType.DataType !== 'Boolean' && !input.InputValue;
    }

    _getTaskHealthRecords(task: any): any[] {
        if (notEmpty(task.TaskAnimalHealthRecord)) {
            return task.TaskAnimalHealthRecord.map((item: any) => {
                return item.AnimalHealthRecord;
            });
        }
        return [];
    }

    _getLatestTaskOfHealthRecord(record: any): any {
        let latestTask = null;

        let tasks = record.TaskAnimalHealthRecord.map((item: any) => {
            return item.TaskInstance;
        });
        // get latest task that is not complete
        tasks = tasks.filter((task: any) => {
            return !task.DateComplete;
        });
        if (notEmpty(tasks)) {
            sortObjectArrayByProperty(tasks, "DateDue");
            latestTask = tasks[0];
        }

        return latestTask;
    }

}

<div class="draggable-title">
    <svg *ngIf="facetIcon$ | async; let facetIcon" [climbIcon]="facetIcon" size="md"></svg>
    <h3>{{ caption }}</h3>
</div>
<div class="facet-actions">
    <ng-content select="[climbFacetAction]"></ng-content>

    <button *ngIf="facetExpanded$ | async; else facetExpand"
            climbButton
            variant="ghost"
            size="sm"
            (click)="onCollapse()"
            title="Collapse">
        <svg [climbIcon]="icons.arrowsIn"></svg>
    </button>

    <ng-template #facetExpand>
        <button climbButton
                variant="ghost"
                size="sm"
                (click)="onExpand()"
                [appInsightsClick]="'expand-facet-' + (facetName$ | async)"
                title="Expand">
            <svg [climbIcon]="icons.arrowsOut"></svg>
        </button>
    </ng-template>

    <button data-automation-id="close-facet-button"
            *ngIf="isCustomizeWorkspaceActive"
            climbButton
            variant="ghost"
            size="sm"
            (click)="onClose()"
            title="Close">
        <svg [climbIcon]="icons.crossBig"></svg>
    </button>
</div>

import {
    Component,
    Input,
    OnInit
} from '@angular/core';

import { CohortService } from '../../cohort/services/cohort.service';
import { GenotypeVocabService } from './../../genotypes/genotype-vocab.service';
import { SearchService } from './../../search/search.service';
import { WorkflowService } from '../../workflow/services/workflow.service';
import { VocabularyService } from '../../vocabularies/vocabulary.service';

import { NewChartFilter } from './new-chart-filter';

import {
    ChartFilterType,
    GenotypeChartFilter,
} from './chart-filter';

import {
    softCompare,
    randomId
} from '../../common/util';
import { QueryDef } from '../../services/query-def';

@Component({
    selector: 'chart-filter-input',
    templateUrl: './chart-filter-input.component.html',
    styles: [`
        .inline-input {
            display: inline-block;
        }
        .inline-input::ng-deep .input-medium {
            max-width: 100px;
        }
    `]
})
export class ChartFilterInputComponent implements OnInit {
    @Input() newFilter: NewChartFilter;

    // expose enum to template
    readonly ChartFilterType = ChartFilterType;

    // Lists of values
    genotypeAssays: any[];
    genotypeSymbols: any[];
    workflowTasks: any[];
    taskOutputs: any[] = [];
    sexes: any[];
    exitReasons: any[];
    fakeModel: any = null;
    fakeModel2: any = null;

    fieldId: string;

    constructor(
        private cohortService: CohortService,
        private genotypeVocabService: GenotypeVocabService,
        private searchService: SearchService,
        private workflowService: WorkflowService,
        private vocabularyService: VocabularyService,
    ) {}

    ngOnInit() {
        this.fieldId = randomId();

        this.getCVs();
    }

    getCVs(): Promise<any> {

        const preferLocal = true;

        this.genotypeVocabService.genotypeAssays$.subscribe((data) => {
            this.genotypeAssays = data;
        });

        this.genotypeVocabService.genotypeSymbols$.subscribe((data) => {
            this.genotypeSymbols = data;
        });

        this.genotypeVocabService.sexes$.subscribe((data) => {
            this.sexes = data;
        });

        const cv1 = this.workflowService.getWorkflowTaskList(preferLocal).then((data: any[]) => {
            this.workflowTasks = data;
        });

        const cv2 = this.vocabularyService.getCV(
            'cv_ExitReasons', 'ExitReason', preferLocal
        ).then((data) => {
            this.exitReasons = data;
        });

        return Promise.all([cv1, cv2]);
    }

    // Select Value
    selectAnimal(filter: NewChartFilter, item: any) {
        filter.chartFilter.model = item;
    }

    selectCohort(filter: NewChartFilter, item: any) {
        filter.chartFilter.model = item;
    }

    selectGenotypeAssay(filter: NewChartFilter, genotypeAssayKey: any) {
        const genotypeAssay: any = this.genotypeAssays.filter((item: any) => {
            return softCompare(item.C_GenotypeAssay_key, genotypeAssayKey);
        })[0];
        (<GenotypeChartFilter> filter.chartFilter).assayModel = genotypeAssay;
    }

    selectGenotypeSymbol(filter: NewChartFilter, genotypeSymbolKey: any) {
        const genotypeSymbol: any = this.genotypeSymbols.filter((item: any) => {
            return softCompare(item.C_GenotypeSymbol_key, genotypeSymbolKey);
        })[0];
        (<GenotypeChartFilter> filter.chartFilter).symbolModel = genotypeSymbol;
    }

    selectJob(filter: NewChartFilter, item: any) {
        filter.chartFilter.model = item;
    }

    selectLine(filter: NewChartFilter, item: any) {
        filter.chartFilter.model = item;
    }

    selectStudy(filter: NewChartFilter, item: any) {
        filter.chartFilter.model = item;
    }

    // Searches
    searchAnimals = (term: string): Promise<any> => {
        return this.searchService.searchAnimals(term).then((results: any) => {
            return results.data;
        });
    }

    searchCohorts = (term: string): Promise<any> => {
        const queryDef: QueryDef = {
            inlineCount: false,
            sort: 'CohortName',
            filter: {
                cohortName: term
            }
        };
        return this.cohortService.getCohorts(queryDef).then((response: any) => {
            return response.results;
        });
    }

    searchJobs = (term: string): Promise<any> => {
        return this.searchService.searchJobs(term).then((results: any) => {
            return results.data;
        });
    }

    searchLines = (term: string): Promise<any> => {
        return this.searchService.searchActiveLines(term).then((results: any) => {
            return results.data;
        });
    }

    selectTask(workflowTask: any) {
        if (!workflowTask) {
            this.taskOutputs = [];
        }

        this.workflowService.getWorkflowTaskOutputs(
            workflowTask.C_WorkflowTask_key
        ).then((data) => {
            this.taskOutputs = data || [];
        });
    }

    // Formatters
    animalNameFormatter = (value: any) => {
        return value.AnimalName;
    }

    cohortNameFormatter = (value: any) => {
        return value.CohortName;
    }

    jobNameFormatter = (value: any) => {
        return value.JobID;
    }

    lineNameFormatter = (value: any) => {
        return value.LineName;
    }

}

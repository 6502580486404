import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { DateFormatterService } from '@common/util/date-time-formatting';

@Injectable()
export class PrintPreviewService {
    constructor(private dateFormatterService: DateFormatterService) {
    }

    /*
    * Print content from a known element with the given ID
    */
    printFromId(
        elementId: string, 
        extraStyles?: string
    ) {
        const htmlContent: string = document.getElementById(elementId).innerHTML;
        this.print(htmlContent, extraStyles);
    }

    /*
    * Print HTML content
    */
    print(
        htmlContent: string, 
        extraStyles?: string
    ): void {
        let stylesheets = "";
        const date =  this.dateFormatterService.formatDateTime(DateTime.now());

        if (extraStyles) {
            stylesheets += " " + extraStyles;
        }

        const popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
        popupWin.document.open();
        popupWin.document.write(`
        <html>
            <head>
                <title>Print tab</title>
                ${stylesheets}
                <style>
                    header {
                        display: none;
                        justify-content: space-between;
                    }
                    header > *:first-child,
                    header > *:last-child {
                        flex: 1;
                    }
                    @media print {
                        header {
                            display: flex;
                        }
                        @page {
                            margin-top: 0;
                        }
                        body {
                            padding-top: 52px;
                        }
                    }
                </style>
            </head>
            <body onload="window.print();">
                <header>
                    <div>${date}</div>
                    <div>Print tab</div>
                    <div></div>
                </header>
                ${htmlContent}
            </body>
        </html>`
        );
        popupWin.document.close();
    }

    getStylesheets(): string {
        const stylesheets: string[] = [];

        // NOTE: for...of does not work with StyleSheetList
        // eslint-disable-next-line
        for (let i = 0; i < window.document.styleSheets.length; i++) {
            const docStyleSheet = window.document.styleSheets[i];
            if (docStyleSheet.href) {
                stylesheets.push(
                    '<link rel="stylesheet" type="text/css" media="print, screen" href="' +
                    docStyleSheet.href + '"/>'
                );
            }
        }

        return stylesheets.join("");
    }

}

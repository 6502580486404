<button type="button"
        class="btn btn-secondary"
        (click)="viewFiles()"
        title="Files">
    <i class="fa fa-file-o" title="Files"></i>
    <span class="badge"
          *ngIf="!loading; else loadingIndicator">{{fileCount}}</span>
    <ng-template #loadingIndicator>
        <i class="fa fa-spinner fa-spin" title="Loading&hellip;"></i>
    </ng-template>
</button>

<form novalidate>
    <div class="modal-header justify-content-center">
        <h4 class="modal-title">Select Statuses</h4>
    </div>

    <div class="modal-body">
        <span>Alert when {{'job' | translateMessage}} status is</span>
        <div class="parameter-control">
            <climb-multi-select [(model)]="param.values"
                                (modelChange)="setParameterValue($event)"
                                [values]="resources"
                                [keyProperty]="'C_JobStatus_key'"
                                [valueProperty]="'JobStatus'"
                                [placeholder]="'Select statuses...'"></climb-multi-select>
        </div>

    </div><!-- /.modal-body -->

    <div class="modal-footer">
        <button type="button"
                class="btn btn-fw btn-lg btn-primary"
                [disabled]="param.values?.length === 0"
                (click)="onSubmit()">
            Select
        </button>
        <button type="button"
                class="btn btn-fw btn-lg btn-secondary"
                (click)="onCancel()">
            Cancel
        </button>
    </div>
</form>

import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit, ElementRef, Injector, ChangeDetectorRef, SkipSelf } from '@angular/core';
import { ButtonComponent } from '@common/components/button/button.component';
import { doc } from '@icons';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'button[climbCageCardButton]',
    templateUrl: './toolbar-cage-card-button.component.html',
    styleUrls: ['./toolbar-cage-card-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToolbarCageCardButtonComponent extends ButtonComponent implements OnInit {
    icons = { doc };

    @Input()
    @HostBinding('attr.title')
    private title = 'Print cage cards';

    @Input()
    @HostBinding('attr.aria-label')
    private ariaLabel = 'Print Cage Card';

    constructor(
        injector: Injector,
        elementRef: ElementRef,
        @SkipSelf() cdr: ChangeDetectorRef,
    ) {
        super(injector, elementRef, cdr);
    }

    ngOnInit(): void {
        super.ngOnInit();
        super.variant = 'ghost';
        super.size = 'sm';
    }
}

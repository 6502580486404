<ng-template #cagecardmodal let-c="close">
    <cage-card-chooser [animals]="selectedAnimals" (close)="c('Close')"></cage-card-chooser>
</ng-template>

<div class="detail-form-wrapper">
    <loading-overlay *ngIf="loading || (saveAnimalLoading$ | async)" message="Loading..."></loading-overlay>
    <div class="detail-header">
        <h3>Animal</h3>

        <pager-toolbar [itemName]="'animal'"
                       [logTag]="COMPONENT_LOG_TAG"
                       [first]="pageState.isFirst"
                       [last]="pageState.isLast"
                       (previous)="previousClicked()"
                       (next)="nextClicked()">
        </pager-toolbar>

        <detail-toolbar [facetPrivilege]="facet.Privilege"
                        [logTag]="COMPONENT_LOG_TAG"
                        [hasChanges]="hasChanges$ | async"
                        [loading]="loading || (saveAnimalLoading$ | async)"
                        (save)="saveEntity()"
                        (exit)="exitClicked()">

            <div class="btn-group" role="group" aria-label="Edit actions" additional-groups>
                    <div class="btn-group" 
                        [readonly]="facet.Privilege === 'ReadOnly'"
                        view-notes-button [pkName]="'C_Material_key'" [pkValue]="animal.C_Material_key"
                        [objectName]="animal.AnimalName" [objectType]="'Animal'" [isObjectNew]="animal | isNew"
                        appInsightsClick="click-notes-from-animal">
                    </div>
                <button *ngIf="!isGLP" type="button" class="btn btn-secondary" (click)="viewAuditReport()"
                        [appInsightsClick]="'click-animal-audit'">
                    <i class="fa fa-flag" aria-hidden="true" title="Audit"></i>
                    <span class="gridster-d-sm-none gridster-d-md-inline-block">Audit</span>
                    <span class="sr-only">Audit</span>
                </button>

                <button type="button" class="btn btn-secondary" 
                    (click)="openCageCardModal(cagecardmodal)"
                    title="Print Cage Card" *ngIf="!(animal | isNew)"
                    [appInsightsClick]="'click-animal-detail-cagecard'">
                    <i class="fa fa-print" aria-hidden="true"></i>
                    <span class="gridster-d-sm-none gridster-d-md-inline-block">Card</span>
                    <span class="sr-only">Print Cage Card</span>
                </button>
            </div>
        </detail-toolbar>
    </div>

    <div *ngIf="animal | isDeleted" class="alert alert-warning" role="alert">
        <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
        This animal has been deleted.
    </div>

    <form *ngIf="!(animal | isDeleted)" name="animalForm" id="animalForm" #animalForm="ngForm" class="detail-form">
        <fieldset [disabled]="facet.Privilege === 'ReadOnly'">
            <div class="form-group row" *ngIf="!(animal | isNew)">
                <label class="col-md-2 col-form-label">ID</label>
                <div class="col-md-10" *ngIf="animal.Material">
                    <input data-automation-id="animal-id-input"
                           type="text" 
                           name="animalId"
                           class="form-control input-medium animal-detail-id"
                           readonly="readonly"
                           [(ngModel)]="animal.Material.Identifier" />
                </div>
            </div>
            <div class="form-group row">
                <label class="col-md-2 col-form-label">Name</label>
                <div class="col-md-10">
                    <input data-automation-id="animal-name-input"
                           type="text" 
                           name="animalName"
                           class="form-control input-medium animal-detail-name"
                           [(ngModel)]="animal.AnimalName"
                           [disabled]="(animal | isNew) && animalNamingActive"
                           [required]="!(animal | isNew) || !animalNamingActive" />
                    <small class="form-text text-muted" *ngIf="(animal | isNew) && animalNamingActive">
                        The name will be assigned automatically.
                    </small>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-md-2 col-form-label">{{'Line' | translate}}</label>

                <div class="col-md-10" *ngIf="animal.Material">
                    <line-select [(model)]="animal.Material.C_Line_key" (modelChange)="lineChanged()" [required]="true">
                    </line-select>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-md-2 col-form-label">Species</label>
                <div class="col-md-10">
                    <ng-container *ngIf="animal.Material?.cv_Taxon">
                        <input data-automation-id="taxon-input"
                               type="text" 
                               name="animalTaxon"
                               class="form-control input-medium"
                               readonly="readonly"
                               [(ngModel)]="animal.Material.cv_Taxon.CommonName" />
                    </ng-container>
                    <small class="form-text text-muted" *ngIf="!animal.Material?.cv_Taxon">
                        Please specify a {{'Line' | translate}}.
                    </small>
                </div>
            </div>
            <div class="form-group row" *ngIf="activeFields.includes('Status')">
                <label class="col-md-2 col-form-label">Status</label>
                <div class="col-md-10">
                    <active-vocab-select [(model)]="animal.C_AnimalStatus_key"
                                         (modelChange)="statusChanged(animal)"
                                         [vocabChoices]="animalStatuses"
                                         [keyFormatter]="animalStatusKeyFormatter"
                                         [optionFormatter]="animalStatusFormatter"
                                         [required]="requiredFields.includes('C_AnimalStatus_key')"
                                         [nullable]="!requiredFields.includes('C_AnimalStatus_key')">
                    </active-vocab-select>
                </div>
            </div>
            <div class="form-group row" *ngIf="isGLP && activeFields.includes('Held For')">
                <label class="col-md-2 col-form-label">Held For</label>
                <div class="col-md-10">
                    <input type="text" name="heldFor"
                           class="form-control input-medium"
                           maxlength="250"
                           [(ngModel)]="animal.HeldFor"
                           [required]="requiredFields.includes('HeldFor')" />
                </div>
            </div>
            <div class="form-group row" *ngIf="activeFields.includes('Breeding Status')">
                <label class="col-md-2 col-form-label">Breeding Status</label>
                <div class="col-md-10">
                    <active-vocab-select [(model)]="animal.C_BreedingStatus_key"
                                         [vocabChoices]="breedingStatuses"
                                         [keyFormatter]="breedingStatusKeyFormatter"
                                         [optionFormatter]="breedingStatusFormatter"
                                         [required]="requiredFields.includes('C_BreedingStatus_key')"
                                         [nullable]="!requiredFields.includes('C_BreedingStatus_key')">
                    </active-vocab-select>
                </div>
            </div>
            <div class="form-group row" *ngIf="isGLP && activeFields.includes('Classification')">
                <label class="col-md-2 col-form-label">Classification</label>
                <div class="col-md-10">
                    <active-vocab-select [(model)]="animal.C_AnimalClassification_key"
                                         [vocabChoices]="animalClassifications"
                                         [keyFormatter]="animalClassificationKeyFormatter"
                                         [optionFormatter]="animalClassificationFormatter"
                                         [required]="requiredFields.includes('C_AnimalClassification_key')"
                                         [nullable]="!requiredFields.includes('C_AnimalClassification_key')">
                    </active-vocab-select>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-md-2 col-form-label">History</label>
                <div class="col-md-10">
                    <event-history #eventHistory [material]="animal" [facetPrivilege]="facet.Privilege"></event-history>
                </div>
            </div>
            <div class="form-group row" *ngIf="activeFields.includes('Owner')">
                <label class="col-md-2 col-form-label">Owner</label>
                <div class="col-md-10">
                    <user-select [(model)]="animal.Owner"
                                 [required]="requiredFields.includes('Owner')"
                                 [forceValidSelection]="false">
                    </user-select>
                </div>
            </div>
            <div class="form-group row" *ngIf="activeFields.includes('Use')">
                <label class="col-md-2 col-form-label">Use</label>
                <div class="col-md-10">
                    <active-vocab-select [(model)]="animal.C_AnimalUse_key"
                                         [vocabChoices]="animalUses"
                                         [keyFormatter]="animalUseKeyFormatter"
                                         [optionFormatter]="animalUseFormatter"
                                         [required]="requiredFields.includes('C_AnimalUse_key')"
                                         [nullable]="!requiredFields.includes('C_AnimalUse_key')">
                    </active-vocab-select>
                </div>
            </div>
            <div class="form-group row" *ngIf="activeFields.includes('IACUC Protocol')">
                <label class="col-md-2 col-form-label">IACUC Protocol</label>
                <div class="col-md-10">
                    <active-vocab-select [(model)]="animal.C_IACUCProtocol_key"
                                         [vocabChoices]="iacucProtocols"
                                         [keyFormatter]="iacucProtocolKeyFormatter"
                                         [optionFormatter]="iacucProtocolFormatter"
                                         [required]="requiredFields.includes('C_IACUCProtocol_key')"
                                         [nullable]="!requiredFields.includes('C_IACUCProtocol_key')">
                    </active-vocab-select>
                </div>
            </div>
            <div class="form-group row" *ngIf="activeFields.includes('Origin')">
                <label class="col-md-2 col-form-label">Origin</label>
                <div class="col-md-10" *ngIf="animal.Material">
                    <active-vocab-select [(model)]="animal.Material.C_MaterialOrigin_key"
                                         [vocabChoices]="materialOrigins"
                                         [keyFormatter]="materialOriginKeyFormatter"
                                         [optionFormatter]="materialOriginFormatter"
                                         [required]="requiredFields.includes('Material.C_MaterialOrigin_key')"
                                         [nullable]="!requiredFields.includes('Material.C_MaterialOrigin_key')">
                    </active-vocab-select>
                </div>
            </div>
            <div class="form-group row" *ngIf="activeFields.includes('Sex')">
                <label class="col-md-2 col-form-label">Sex</label>
                <div class="col-md-10">
                    <active-vocab-select [(model)]="animal.C_Sex_key"
                                         [vocabChoices]="sexes"
                                         [keyFormatter]="sexKeyFormatter"
                                         [optionFormatter]="sexFormatter"
                                         [required]="requiredFields.includes('C_Sex_key')"
                                         [nullable]="!requiredFields.includes('C_Sex_key')">
                    </active-vocab-select>
                </div>
            </div>
            <div class="form-group row" *ngIf="activeFields.includes('Generation')">
                <label class="col-md-2 col-form-label">Generation</label>
                <div class="col-md-10">
                    <active-vocab-select [(model)]="animal.C_Generation_key"
                                         [vocabChoices]="generations"
                                         [keyFormatter]="generationKeyFormatter"
                                         [optionFormatter]="generationFormatter"
                                         [required]="requiredFields.includes('C_Generation_key')"
                                         [nullable]="!requiredFields.includes('C_Generation_key')">
                    </active-vocab-select>
                </div>
            </div>
            <div class="form-group row" *ngIf="activeFields.includes('Diet')">
                <label class="col-md-2 col-form-label">Diet</label>
                <div class="col-md-10">
                    <active-vocab-select [(model)]="animal.C_Diet_key"
                                         [vocabChoices]="diets"
                                         [keyFormatter]="dietKeyFormatter"
                                         [optionFormatter]="dietFormatter"
                                         [required]="requiredFields.includes('C_Diet_key')"
                                         [nullable]="!requiredFields.includes('C_Diet_key')">
                    </active-vocab-select>
                </div>
            </div>
            <div class="form-group row" *ngIf="activeFields.includes('Shipment')">
                <label class="col-md-2 col-form-label">Shipment</label>
                <div class="col-md-10">
                    <input type="text" name="animalShipmentID"
                           class="form-control input-medium"
                           maxlength="50"
                           [(ngModel)]="animal.ShipmentID"
                           [required]="requiredFields.includes('ShipmentID')" />
                </div>
            </div>
            <div class="form-group row" *ngIf="activeFields.includes('Vendor ID')">
                <label class="col-md-2 col-form-label">Vendor ID</label>
                <div class="col-md-10">
                    <input type="text" name="animalVendorID"
                           class="form-control input-medium"
                           maxlength="50"
                           [(ngModel)]="animal.VendorID"
                           [required]="requiredFields.includes('VendorID')" />
                </div>
            </div>
            <div class="form-group row" *ngIf="activeFields.includes('Order ID')">
                <label class="col-md-2 col-form-label">Order ID</label>
                <div class="col-md-10">
                    <order-select [(model)]="animal.C_Order_key"
                                  [animalOrdersOnly]="true"
                                  [required]="requiredFields.includes('C_Order_key')">
                    </order-select>
                </div>
            </div>
            <div class="form-group row" *ngIf="isGLP && activeFields.includes('CITES Number')">
                <label class="col-md-2 col-form-label">CITES Number</label>
                <div class="col-md-10">
                    <input type="text" name="cITESNumber"
                           class="form-control input-medium"
                           maxlength="250"
                           [(ngModel)]="animal.CITESNumber"
                           [required]="requiredFields.includes('CITESNumber')" />
                </div>
            </div>
            <div class="form-group row" *ngIf="activeFields.includes('Age (days)')">
                <label class="col-md-2 col-form-label">Age (days)</label>
                <div class="col-md-10">
                    <p class="form-control-plaintext">
                        {{animal.DateBorn | animalAgeDays:animal.DateExit}}
                    </p>
                </div>
            </div>
            <div class="form-group row" *ngIf="activeFields.includes('Age (weeks)')">
                <label class="col-md-2 col-form-label">Age (weeks)</label>
                <div class="col-md-10">
                    <p class="form-control-plaintext">
                        {{animal.DateBorn | animalAgeWeeks:animal.DateExit | number}}
                    </p>
                </div>
            </div>
            <div class="form-group row" *ngIf="activeFields.includes('Arrival Date')">
                <label class="col-md-2 col-form-label">Arrival Date</label>
                <div class="col-md-4">
                    <climb-ngb-date name="Arrival Date"
                                    #dateControl="ngModel"
                                    [(ngModel)]="animal.DateOrigin"
                                    [required]="requiredFields.includes('DateOrigin')">
                    </climb-ngb-date>
                </div>
            </div>
            <div class="form-group row" *ngIf="activeFields.includes('Birth Date')">
                <label class="col-md-2 col-form-label">Birth Date</label>
                <div class="col-md-4">
                    <climb-ngb-date data-automation-id="birth-date-input"
                                    name="Birth Date"
                                    #dateControl="ngModel"
                                    [(ngModel)]="animal.DateBorn"
                                    [required]="requiredFields.includes('DateBorn')">
                    </climb-ngb-date>
                </div>
            </div>
            <div class="form-group row" *ngIf="activeFields.includes('Death/Exit Date')">
                <label class="col-md-2 col-form-label">Death/&#8203;Exit Date</label>
                <div class="col-md-4">
                    <climb-ngb-date name="Death/Exit Date"
                                    #dateControl="ngModel"
                                    [(ngModel)]="animal.DateExit"
                                    [required]="requiredFields.includes('DateExit')">
                    </climb-ngb-date>
                </div>
            </div>
            <div class="form-group row" *ngIf="activeFields.includes('Death/Exit Reason')">
                <label class="col-md-2 col-form-label">Death/&#8203;Exit Reason</label>
                <div class="col-md-10">
                    <active-vocab-select [(model)]="animal.C_ExitReason_key"
                                         [vocabChoices]="exitReasons"
                                         [keyFormatter]="exitReasonKeyFormatter"
                                         [optionFormatter]="exitReasonFormatter"
                                         [required]="requiredFields.includes('C_ExitReason_key')"
                                         [nullable]="!requiredFields.includes('C_ExitReason_key')">
                    </active-vocab-select>
                </div>
            </div>
            <div class="form-group row" *ngIf="activeFields.includes('Marker Type')">
                <label class="col-md-2 col-form-label">Marker Type</label>
                <div class="col-md-10">
                    <active-vocab-select [(model)]="animal.C_PhysicalMarkerType_key"
                                         [vocabChoices]="physicalMarkerTypes"
                                         [keyFormatter]="physicalMarkerTypeKeyFormatter"
                                         [optionFormatter]="physicalMarkerTypeFormatter"
                                         [required]="requiredFields.includes('C_PhysicalMarkerType_key')"
                                         [nullable]="!requiredFields.includes('C_PhysicalMarkerType_key')">
                    </active-vocab-select>
                </div>
            </div>
            <div class="form-group row" *ngIf="activeFields.includes('Marker')">
                <label class="col-md-2 col-form-label">Marker</label>
                <div class="col-md-10">
                    <input type="text" name="animalPhysicalMarker"
                           class="form-control input-medium animal-detail-marker"
                           *ngIf="!animal.cv_PhysicalMarkerType?.Vendor"
                           [(ngModel)]="animal.PhysicalMarker"
                           [required]="requiredFields.includes('PhysicalMarker')" />
                    <animal-marker *ngIf="animal.cv_PhysicalMarkerType?.Vendor"
                                   [marker]="animal.PhysicalMarker"
                                   [markerType]="animal.cv_PhysicalMarkerType">
                    </animal-marker>
                </div>
            </div>
            <div class="form-group row" *ngIf="activeFields.includes('Microchip ID')">
                <label class="col-md-2 col-form-label">Microchip ID</label>
                <div class="col-md-10" *ngIf="animal.Material && !isGLP">
                    <input type="text" name="animalMicrochipIdentifier"
                           class="form-control input-medium animal-detail-microchip"
                           [(ngModel)]="animal.Material.MicrochipIdentifier"
                           [required]="requiredFields.includes('Material.MicrochipIdentifier')" />
                </div>
                <div class="col-md-10" *ngIf="animal.Material && isGLP">
                    <input type="text"
                           name="animalMicrochipIdentifier"
                           class="form-control input-medium animal-detail-microchip"
                           #animalMicrochipIdentifier="ngModel"
                           validateUnique
                           uniqueEntity="Materials"
                           uniqueProperty="MicrochipIdentifier"
                           [entityPK]="animal.C_Material_key"
                           entityPkName="C_Material_key"
                           [(ngModel)]="animal.Material.MicrochipIdentifier"
                           [required]="requiredFields.includes('Material.MicrochipIdentifier')" />
                    <small class="form-text text-muted"
                           *ngIf="animalMicrochipIdentifier.errors?.unique && animalMicrochipIdentifier.dirty && animal.Material.MicrochipIdentifier !== originalMicrochipIdentifier">
                        <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                        The Microchip ID is already in use.
                    </small>
                </div>
            </div>
            <div class="form-group row" *ngIf="activeFields.includes('External ID')">
                <label class="col-md-2 col-form-label">External ID</label>
                <div class="col-md-10" *ngIf="animal.Material && !isDotmatics">
                    <input type="text" name="animalExternalIdentifier"
                           class="form-control input-medium animal-detail-external"
                           [(ngModel)]="animal.Material.ExternalIdentifier"
                           [required]="requiredFields.includes('Material.ExternalIdentifier')" />
                </div>
                <div class="col-md-10" *ngIf="animal.Material && isDotmatics">
                    <input type="text" name="animalExternalIdentifier"
                           class="form-control input-medium animal-detail-external"
                           [(ngModel)]="animal.Material.ExternalIdentifier"
                           [required]="requiredFields.includes('Material.ExternalIdentifier')"
                           readonly="readonly" />
                    <p *ngIf="isDotmatics && animal.Material.MaterialExternalSync.length > 0">
                        Last Sync:
                        {{animal.Material.MaterialExternalSync[0].DateSync | formatShortDateOrTimeUtc}}
                    </p>
                </div>
            </div>
            <div class="form-group row" *ngIf="isGLP && activeFields.includes('Alternate Physical ID')">
                <label class="col-md-2 col-form-label">Alternate Physical ID</label>
                <div class="col-md-10">
                    <input type="text" name="alternatePhysicalID"
                           class="form-control input-medium animal-detail-external"
                           #alternatePhysicalID="ngModel"
                           validateUnique
                           uniqueEntity="Animals"
                           uniqueProperty="AlternatePhysicalID"
                           [entityPK]="animal.C_Material_key"
                           entityPKName="C_Material_key"
                           [(ngModel)]="animal.AlternatePhysicalID"
                           [required]="requiredFields.includes('AlternatePhysicalID')" />
                    <small class="form-text text-muted"
                           *ngIf="alternatePhysicalID?.errors?.unique && alternatePhysicalID?.dirty &&
                            animal.AlternatePhysicalID && animal.AlternatePhysicalID !== originalAlternatePhysicalID">
                        <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                        The Alternate Physical ID is already in use.
                    </small>
                </div>
            </div>
            <div class="form-group row" *ngIf="activeFields.includes('Comments')">
                <label class="col-md-2 col-form-label">Comments</label>
                <div class="col-md-10">
                    <animal-comments-table [facet]="facet"
                                           [animal]="animal"
                                           [animalCommentStatuses]="animalCommentStatuses">
                    </animal-comments-table>
                </div>

            </div>
            <div class="form-group row" *ngIf="activeFields.includes('Cohorts')">
                <label class="col-md-2 col-form-label">Cohorts</label>
                <div class="col-md-10">
                    <p class="form-control-plaintext" *ngIf="animal.Material">
                        <animal-cohort-table [animal]="animal"
                                             [cohortMaterials]="animal.Material.CohortMaterial"
                                             [readonly]="facet.Privilege === 'ReadOnly'">
                        </animal-cohort-table>
                    </p>
                </div>
            </div>

            <!-- Studies Time Table -->
            <div class="form-group row" *ngIf="isGLP && activeFields.includes('Jobs')">
                <label class="col-md-2 col-form-label">{{'Jobs' | translate}}</label>
                <div class="col-md-10">
                    <table class="table" *ngIf="animal.Material.JobMaterial?.length > 0">
                        <thead>
                            <tr>
                                <th>{{'Job' | translate}} Name</th>
                                <th>Date In</th>
                                <th>Date Out</th>
                                <th>{{'Job' | translate}} Status</th>
                                <th>{{'Job' | translate}} Type</th>
                                <th>{{'Job' | translate}} Start</th>
                                <th>{{'Job' | translate}} End</th>
                                <th>Modified By</th>
                                <th>Modified Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let jobMaterial of animal.Material.JobMaterial">
                                <td>
                                    <span class="event-label">{{jobMaterial.Job.JobID}}</span>
                                </td>
                                <td>
                                    <span class="event-label">{{jobMaterial.DateIn | formatShortDate}}</span>
                                </td>
                                <td>
                                    <span class="event-label">{{jobMaterial.DateOut | formatShortDate}}</span>
                                </td>
                                <td>
                                    <span class="event-label">{{jobMaterial.Job.cv_JobStatus.JobStatus}}</span>
                                </td>
                                <td>
                                    <span class="event-label">{{jobMaterial.Job.cv_JobType.JobType}}</span>
                                </td>
                                <td>
                                    <span class="event-label">{{jobMaterial.Job.DateStarted | formatShortDate}}</span>
                                </td>
                                <td>
                                    <span class="event-label">{{jobMaterial.Job.DateEnded | formatShortDate}}</span>
                                </td>
                                <td>
                                    <span class="event-label">{{jobMaterial.ModifiedBy}}</span>
                                </td>
                                <td>
                                    <span class="event-label">{{jobMaterial.DateModified | formatShortDate}}</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="form-control-plaintext no-data-message" *ngIf="animal.Material.JobMaterial?.length < 1">
                        None.
                    </div>
                </div>
            </div>

            <div class="form-group row" *ngIf="!isGLP && activeFields.includes('Jobs')">
                <label class="col-md-2 col-form-label">{{'Jobs' | translate}}</label>
                <div class="col-md-10" *ngIf="animal.Material">
                    <material-job-select [material]="animal.Material"
                                         [readonly]="facet.Privilege === 'ReadOnly'">
                    </material-job-select>
                </div>
            </div>

            <characteristic-instance-wrapper *ngIf="animal.Material?.cv_Taxon"
                                          [model]="animal"
                                          [signalValue]="animal.Material?.cv_Taxon"
                                          [characteristicType]="'taxon'"
                                          [isLoading]="loading"
                                          (onCharacteristicRefresh)="getAnimalDetails()">

            </characteristic-instance-wrapper>

            <div class="form-group row" *ngIf="activeFields.includes('Genotypes')">
                <label class="col-md-2 col-form-label">Genotypes</label>
                <div class="col-md-10">
                    <table>
                        <tr *ngFor="let genotype of animal.Genotype | orderBy: 'DateGenotype':true">
                            <td>{{genotype.cv_GenotypeAssay?.GenotypeAssay}}&nbsp;{{genotype.cv_GenotypeSymbol?.GenotypeSymbol}}</td>
                            <td style="padding: 0 15px;">{{genotype.DateGenotype | formatShortDate}}</td>
                        </tr>
                    </table>
                    <p class="form-control-plaintext" *ngIf="animal.Genotype.length === 0">
                        <em>None</em>
                    </p>
                </div>
            </div>

            <div class="form-group row" *ngIf="activeFields.includes('Line (Stock)')">
                <label class="col-md-2 col-form-label">{{'Line' | translate}} (Stock)</label>
                <div class="col-md-10" *ngIf="animal.Material.Line">
                    <input type="text" name="stockID"
                           class="form-control input-medium"
                           readonly="readonly"
                           [(ngModel)]="animal.Material.Line.StockID" />
                </div>
            </div>

            <div class="form-group row" *ngIf="activeFields.includes('Protocols')">
                <label class="col-md-2 col-form-label">Protocols</label>
                <div class="col-md-10">
                    <ul class="list-unstyled form-control-plaintext" *ngIf="animal.Protocols | notEmpty">
                        <li *ngFor="let protocol of animal.Protocols">
                            {{protocol}}
                        </li>
                    </ul>
                    <p class="form-control-plaintext" *ngIf="!(animal.Protocols | notEmpty)">
                        <em>None</em>
                    </p>
                </div>
            </div>
            <div class="form-group row" *ngIf="activeFields.includes('Mating Status')">
                <label class="col-md-2 col-form-label">
                    Mating Status
                    <info-tooltip class="tool-tip"
                                  [text]="'Climb tracks mating status by housing type. Animals that have never been in mating-type housing have Virgin status. Animals currently in mating-type housing have Active status. Animals no longer in mating-type housing have Former status.'">
                    </info-tooltip>
                </label>
                <div class="col-md-10">
                    <p class="form-control-plaintext">
                        {{animal.cv_AnimalMatingStatus?.AnimalMatingStatus}}
                    </p>
                </div>
            </div>
            <div class="form-group row" *ngIf="activeFields.includes('Housing')">
                <label class="col-md-2 col-form-label">Housing</label>
                <div class="col-md-10">
                    <table class="table table-nonfluid" *ngIf="animal.History | notEmpty">
                        <thead>
                            <tr>
                                <th>Type</th>
                                <th>ID</th>
                                <th class="text-nowrap">Date In</th>
                                <th class="text-nowrap">Date Out</th>
                                <th>Locations</th>
                                <th *ngIf="isGLP">Social Partner(s)</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let history of animal.History" [ngClass]="history.DateOut ? 'disabled' : ''">
                                <th scope="row" class="text-nowrap">
                                    {{history.MaterialPool?.cv_MaterialPoolType?.MaterialPoolType}}
                                </th>
                                <td>
                                    {{history.MaterialPool?.MaterialPoolID}}
                                </td>
                                <td>
                                    {{history.DateIn | formatShortDate}}
                                </td>
                                <td>
                                    {{history.DateOut | formatShortDate}}
                                </td>
                                <td>
                                    {{history.MaterialPool?.CurrentLocationPath}}
                                </td>
                                <td *ngIf="isGLP">
                                    {{getSocialPartners(history)}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="form-group row" *ngIf="activeFields.includes('Location')">
                <label class="col-md-2 col-form-label">Location</label>
                <div class="col-md-10">
                    <p class="form-control-plaintext">
                        {{animal.Material?.CurrentLocationPath}}
                    </p>
                </div>
            </div>
            <div class="form-group row" *ngIf="activeFields.includes('Clinical Observations')">
                <label class="col-md-2 col-form-label">Clinical Observations</label>
                <div class="col-md-10">
                    <p class="form-control-plaintext">
                        {{animal.ClinicalObservationCount || 0}}
                    </p>
                </div>
            </div>
            <div class="form-group row" *ngIf="largeAnimalEnabled && activeFields.includes('Diagnostic Observations')">
                <label class="col-md-2 col-form-label">Diagnostic Observations</label>
                <div class="col-md-10">
                    <p class="form-control-plaintext">
                        {{animal.DiagnosticObservationCount || 0}}
                    </p>
                </div>
            </div>
            <div class="form-group row" *ngIf="activeFields.includes('Pedigree')">
                <label class="col-md-2 col-form-label">Pedigree</label>
                <div class="col-md-10">
                    <pedigree-viewer [animal]="animal"></pedigree-viewer>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-md-2 col-form-label">Files</label>
                <div class="col-md-10">
                    <div class="form-control-plaintext">
                        <facet-detail-file-upload [pkName]="'C_Material_key'" [pkValue]="animal.C_Material_key"
                                                  [facetPrivilege]="facet.Privilege">
                        </facet-detail-file-upload>
                    </div>
                </div>
            </div>

        </fieldset>

        <!-- New fieldset that is never disabled so as not to affect controls in child tables -->
        <fieldset>
            <div class="form-group row">
                <div class="col-md-12" *ngIf="animal?.Material">
                    <detail-task-table #detailTaskTable
                                       [logTag]="COMPONENT_LOG_TAG"
                                       [parent]="animal.Material"
                                       [taskAssociations]="animal.Material.TaskMaterial | taskFilter:TaskType.Animal"
                                       [taskType]="TaskType.Animal" [readonly]="facet.Privilege === 'ReadOnly'" [facet]="facet"
                                       [tableOptions]="detailTaskTableOptions" [tableSort]="taskTableSort"
                                       [(inputsExpanded)]="animal.inputsExpanded" [(tasksExpanded)]="animal.tasksExpanded"
                                       [(taskAnimalsExpanded)]="animal.taskAnimalsExpanded"
                                       [(taskCohortsExpanded)]="animal.taskCohortsExpanded"
                                       [(taskSamplesExpanded)]="animal.taskSamplesExpanded" (taskInstanceAdd)="addTaskMaterial($event)"
                                       (protocolAdded)="addedProtocol($event)"
                                       (taskInstanceRemove)="removeTaskInstanceFromAnimal($event)"
                    >
                    </detail-task-table>
                    <br>
                </div>
                <div class="col-md-12" *ngIf="animal?.Material">
                    <div class="animal-outputs-wrapper">
                        <div class="animal-section">
                            <div class="animal-output-types">
                                <label class="padding-right5">Animal Task Outputs</label>
                                <label style="font-weight: normal; font-style: italic;" 
                                       *ngIf="relatedAnimalWorkflowTasks.length === 0">Completed animal tasks will appear here.</label>
                                <climb-multi-select *ngIf="relatedAnimalWorkflowTasks.length > 0"
                                                    [(model)]="animalWorkflowTaskKeys"
                                                    [values]="relatedAnimalWorkflowTasks"
                                                    [keyProperty]="'C_WorkflowTask_key'"
                                                    [valueProperty]="'TaskName'"
                                                    [emitOnClose]="true"
                                                    (modelChange)="onAnimalSelect()"
                                                    [placeholder]="'Select Tasks...'">
                                </climb-multi-select>
                                <climb-multi-select *ngIf="animalTasksOutputs.length && animalWorkflowTasks.length && relatedAnimalWorkflowTasks.length > 0"
                                                    [(model)]="selectedAnimalTaskOutputKeys"
                                                    [values]="animalTasksOutputs"
                                                    [keyProperty]="'C_Output_key'"
                                                    [valueProperty]="'OutputName'"
                                                    [emitOnClose]="true"
                                                    (modelChange)="onAnimalOutputSelect()"
                                                    [maxSelectionLimit]="2"
                                                    [showCheckAll]="false"
                                                    [placeholder]="'Select Outputs to Chart'">
                                </climb-multi-select>
                            </div>
                            <div class="animal-outputs" *ngIf="relatedAnimalWorkflowTasks.length > 0">
                                <ng-container *ngTemplateOutlet="taskOutputTemplate; context: { taskOutputs: chartAnimalOutputs }">
                                </ng-container>
                                <loading-overlay *ngIf="tasksLoading"></loading-overlay>
                                <div class="tasks" *ngFor="let task of animalTasks; trackBy: trackRow">
                                    <ng-container *ngTemplateOutlet="taskTemplate; context: { task: task, type: 'animal' }">
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                        <br>
                        <div class="study-section">
                            <div class="animal-output-types">
                                <label class="padding-right5">{{'Job' | translate}} Task Outputs</label>
                                <label style="font-weight: normal; font-style: italic;" 
                                       *ngIf="relatedJobWorkflowTasks.length === 0">Completed {{'Job' | translate}} tasks will appear here.</label>
                                <climb-multi-select *ngIf="relatedJobWorkflowTasks.length > 0"
                                                    [(model)]="jobWorkflowTaskKeys"
                                                    [values]="relatedJobWorkflowTasks"
                                                    [keyProperty]="'C_WorkflowTask_key'"
                                                    [valueProperty]="'TaskName'"
                                                    [emitOnClose]="true"
                                                    (modelChange)="onJobSelect()"
                                                    [placeholder]="'Select Tasks...'">
                                </climb-multi-select>
                                <climb-multi-select *ngIf="jobTasksOutputs.length && jobWorkflowTasks.length && relatedJobWorkflowTasks.length > 0"
                                                    [(model)]="selectedJobTaskOutputKeys"
                                                    [values]="jobTasksOutputs"
                                                    [keyProperty]="'C_Output_key'"
                                                    [valueProperty]="'OutputName'"
                                                    [emitOnClose]="true"
                                                    (modelChange)="onJobOutputSelect()"
                                                    [maxSelectionLimit]="2"
                                                    [showCheckAll]="false"
                                                    [placeholder]="'Select Outputs to Chart'">
                                </climb-multi-select>
                            </div>
                            <div class="study-outputs" *ngIf="relatedJobWorkflowTasks.length > 0">
                                <ng-container *ngTemplateOutlet="taskOutputTemplate; context: { taskOutputs: chartJobOutputs }">
                                </ng-container>
                                <div class="tasks" *ngFor="let task of jobTasks; trackBy: trackRow">
                                    <ng-container *ngTemplateOutlet="taskTemplate; context: { task: task, type: 'study' }">
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                        <br><br><br><br>
                    </div>
                </div>
            </div>
        </fieldset>
    </form>
</div>

<ng-template #taskTemplate let-task="task" let-type="type">
    <div>{{task.name}}</div>
    <workflow-bulk-data-entry [facet]="workflowFacet"
                              [taskKeys]="task.endStateTaskKeys"
                              [visibleColumns]="type === 'study' ? studyColumns : visibleColumns"
                              [readonly]="true"
                              [importVisible]="false"
                              [hideCommonValues]="false"
                              [paginationRandomId]="task.randomId">
    </workflow-bulk-data-entry>
</ng-template>

<ng-template #taskOutputTemplate let-taskOutputs="taskOutputs">
    <div class="row row-no-padding">
        <output-chart-renderer
            class="{{count === 2 ? 'col-md-6' : 'col-md-12'}}"
            *ngFor="let taskOutput of taskOutputs; count as count; index as index; trackBy: trackRow"
            [taskOutput]="taskOutput"
            [chartIndex]="index"
            argumentAxisType="discrete">
        </output-chart-renderer>
    </div>
</ng-template>

import type { Line } from './line.interface';
import type { cv_GenotypeAssay } from './cv-genotype-assay.interface';

export interface LineGenotypeAssay {
    C_GenotypeAssay_key: number;
    C_LineGenotypeAssay_key: number;
    C_Line_key: number;
    C_Workgroup_key: number;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    Line: Line;
    ModifiedBy: string;
    SortOrder: number;
    Version: number;
    cv_GenotypeAssay: cv_GenotypeAssay;
}

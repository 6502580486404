import type { Allele } from './allele.interface';
import type { Genotype } from './genotype.interface';

export interface GenotypeAllele {
    Allele: Allele;
    C_Allele_key: number;
    C_GenotypeAllele_key: number;
    C_Genotype_key: number;
    C_Workgroup_key: number;
    Confidence: boolean;
    CopyNumber: number;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    Genotype: Genotype;
    ModifiedBy: string;
    Version: number;
}

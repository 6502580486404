import { SearchNodeComponent } from './search-node.component';
import { AppInsightsService } from './../services/app-insights.service';
import { DataManagerService } from './../services/data-manager.service';
import { Component, OnInit } from '@angular/core';

import { GridOptions, IDatasource, IGetRowsParams, ColumnResizedEvent } from 'ag-grid-community';

import { CsvExporter } from '../common/export/csv-exporter';

import { LoggingService } from '../services/logging.service';
import { SearchService } from './search.service';
import { DateFormatterService } from '@common/util/date-time-formatting';

@Component({
    selector: 'advanced-search',
    templateUrl: './advanced-search.component.html',
    styles: [`
        .tree-node-list {
            position: relative;
            margin: 0;
            padding: 0;
            list-style: none;
        }

        .tree-node-content {
            border: 1px solid #dae2ea;
            background: #f8faff;
            color: #7c9eb2;
        }

        .tree-node-list-item {
            position: relative;
            margin: -1px 0 0;
            padding: 0;
            min-height: 20px;
            line-height: 20px;
        }
    `],
})
export class AdvancedSearchComponent implements IDatasource, OnInit {
    dateOptions = {
        changeYear: true,
        changeMonth: true,
        yearRange: '1900:2050',
    };
    searchPerformed = false;
    searching = false;
    userSearches: any[] = [];
    currUserSearch: any = null;
    cleanSearchSpecification: any = null;
    searchSpecification: any = null;
    saveSearchAsName = '';

    expandedNode: SearchNodeComponent = null;

    headers: any[] = [];
    columnDefs: any[] = [];
    rowData: any[] = [];
    rowHeight = 26;
    gridOptions: GridOptions = {
        cacheBlockSize: 500,
        defaultColDef: {
            resizable: true,
            filter: false,
            sortable: true,
            suppressMovable: true,
        },
        onModelUpdated: () => {
            this.sizeToFit();
        },
        suppressCellFocus: true,
        suppressRowClickSelection: true,
        suppressRowHoverHighlight: true,
        // suppressRowHoverClass: true,
        localeText: {
            to: '&#8211;'
        },
    };
    csvExporter: CsvExporter;
    readonly COMPONENT_LOG_TAG = 'advanced-search';
    readonly EXPORT_SIZE = 5000;

    constructor(
        private appInsightsService: AppInsightsService,
        private loggingService: LoggingService,
        private searchService: SearchService,
        private dataManager: DataManagerService,
        private dateFormatterService: DateFormatterService
    ) {
        this.csvExporter = new CsvExporter();
    }

    async ngOnInit(): Promise<void> {
        const userSearches = await this.searchService.getUserSearches();
        this.userSearches = userSearches;

        const response = await this.searchService.getAdvancedSearchSpecification('job');
        const data = response.data;

        // we save a clean copy of the specification that we can reuse for
        // normalizing saved results
        this.cleanSearchSpecification = JSON.parse(JSON.stringify(data));

        // by default we'll always make the first field visible to avoid
        // confusion of having no columns selected
        if (data.fields && data.fields.length && data.fields[0].type !== 'Branch') {
            data.fields[0].showInOutput = true;
        }

        this.searchSpecification = data;
        this.searchSpecification.numberRecords = 500;
        this.normalizeSearchSpecification(this.searchSpecification);
    }

    private normalizeSearchSpecification(node: any) {
        // make sure the values are valid for the given operator

        if (node.type === 'Text') {
            if (!node.operator) {
                node.operator = 'contains';
            }

            if (node.operator === 'has no value') {
                node.text = null;
                node.searchNulls = true;
            }
        }

        if (node.type === 'Number' || node.type === 'Date') {
            if (!node.operator) {
                node.operator = 'between';
            }

            if (node.operator === '<=') {
                node.minValue = null;
            } else if (node.operator === '>=') {
                node.maxValue = null;
            } else if (node.operator === '=') {
                node.maxValue = node.minValue;
            } else if (node.operator === 'has no value') {
                node.minValue = null;
                node.maxValue = null;
                node.searchNulls = true;
            }

            // the JSON serializer turns dates into strings and the
            // ui-date element doesn't know how to deal with a
            // string model so we need to convert them back to dates
            if (node.type === 'Date') {
                if (typeof node.minValue === 'string' || typeof node.minValue === 'number') {
                    node.minValue = new Date(node.minValue);
                }
                if (typeof node.maxValue === 'string' || typeof node.maxValue === 'number') {
                    node.maxValue = new Date(node.maxValue);
                }
            }
        }

        if (node.type === 'Enumeration') {
            if (!node.operator) {
                node.operator = 'any in';
            }

            if (node.operator === 'has no value') {
                node.values = [];
                node.searchNulls = true;
            }
        }

        // recurse
        if (node.fields) {
            for (const field of node.fields) {
                this.normalizeSearchSpecification(field);
            }
        }
    }

    /*
    * Export current advanced search to CSV
    */
    exportToCsv(filename: string) {

        const allPageSearch = JSON.parse(JSON.stringify(this.searchSpecification));
        allPageSearch.pageNumber = 1;
        allPageSearch.numberRecords = this.EXPORT_SIZE;

        return this.searchService.performAdvancedSearch(allPageSearch).then((response: any) => {

            const data = response.data;

            if (data.totalCount > this.EXPORT_SIZE) {
                this.loggingService.logWarning(
                    "CSV file only contains first " + this.EXPORT_SIZE + " records",
                    null,
                    this.COMPONENT_LOG_TAG,
                    true);
            }

            const results = data.items;

            // format dates
            this.formatSearchResultDates(data.headers, results);

            // add headers
            const headers = data.headers.map((header: any) => header.displayName);
            results.unshift(headers);

            // generate file
            this.csvExporter.download(results, filename);
        });
    }

    private formatSearchResultDates(headerFields: any[], results: any[][]) {
        for (const currResults of results) {
            for (let i = 0; i < headerFields.length; i++) {
                if (headerFields[i].type === 'Date') {
                    currResults[i] = this.dateFormatterService.formatDateOnly(currResults[i]);
                }
            }
        }
    }

    sortChanged() {
        if (this.gridOptions.api) {
            const sortModel = this.gridOptions.columnApi
                .getColumnState()
                .filter((columnState) => columnState.sort);

            if (sortModel && sortModel.length) {
                // we only sort by a single column so just grab the 1st index
                const sortCol = sortModel[0];
                this.searchSpecification.sortName = this.headers[parseInt(sortCol.colId, 10)].name;
                this.searchSpecification.sortDir = sortCol.sort;
            } else {
                this.searchSpecification.sortName = null;
                this.searchSpecification.sortDir = null;
            }
        }
    }

    getRows(params: IGetRowsParams): void {
        if (this.searchSpecification) {
            this.searchSpecification.pageNumber =
                params.startRow / this.gridOptions.cacheBlockSize;
            this.searchSpecification.numberRecords = this.gridOptions.cacheBlockSize;

            this.searching = true;
            this.normalizeSearchSpecification(this.searchSpecification);

            this.searchService.performAdvancedSearch(this.searchSpecification).then((response) => {
                const data = response.data;
                if (data) {
                    if (this.headers.length !== data.headers.length ||
                        data.headers.some((h: any, i: number) =>
                            h.displayName !== this.headers[i].displayName ||
                            h.name !== this.headers[i].name)) {
                        this.headers = data.headers;
                        this.columnDefs = data.headers.map((header: any, colNum: number) => {
                            return {
                                headerName: header.displayName,
                                field: `${colNum}`,
                            };
                        });
                    }

                    this.rowData = data.items.map((row: any[]) => {
                        const rowRetVal = {};
                        row.forEach((item: any, colNum: number) => {
                            if (data.headers[colNum].type === 'Date') {
                                item = this.dateFormatterService.formatDateOnly(item);
                            }

                            rowRetVal[`${colNum}`] = item;
                        });

                        return rowRetVal;
                    });

                    params.successCallback(this.rowData, data.totalCount);
                }

                console.log('SEARCH COMPLETE');
                console.log(data);

                this.searching = false;
            }).catch((error) => {
                this.searching = false;
                params.failCallback();
                throw error;
            });
        }
    }

    performSearch() {
        if (this.gridOptions.api) {
            if (this.searchPerformed) {
                this.gridOptions.api.refreshInfiniteCache();
            } else {
                this.gridOptions.api.setDatasource(this);
                this.searchPerformed = true;
            }
        }
    }

    resizeEventOcurred(event: ColumnResizedEvent) {
        if (event.finished &&
            event.column
        ) {
            const column = event.column;
            const lastSizeProp = 'lastSize';
            if (column[lastSizeProp] !== column.getActualWidth()) {
                column[lastSizeProp] = column.getActualWidth();
                this.sizeToFit();
            }
        }
    }

    sizeToFit() {
        this._makeApiCall(() => {
            this.gridOptions.api.sizeColumnsToFit();
        });
    }

    private _makeApiCall(callFunc: () => void) {
        if (!this.gridOptions.api) {
            return;
        }
        setTimeout(callFunc, 0);
    }

    saveSearchAs() {
        const searchAsStr = JSON.stringify(this.searchSpecification);
        let uniqueName = this.saveSearchAsName;

        // arbitrary limit on unique name search to 100 because anything more
        // seems pretty crazy
        for (let i = 1; i < 100 && this._searchNameAlreadyExists(uniqueName); i++) {
            uniqueName = this.saveSearchAsName + ' (' + i + ')';
        }

        const newUserSearch = this.searchService.addUserSearch(uniqueName, searchAsStr);

        // now that we've added the search we need to update the list of searches
        // and clear the search name field
        this.saveSearchAsName = '';
        this.userSearches.unshift(newUserSearch);
        this.dataManager.saveEntity('UserSearch');
    }

    private _searchNameAlreadyExists(name: string) {
        return this.userSearches.some((userSearch) => {
            return userSearch.UserSearchName === name;
        });
    }

    deleteSavedSearch() {
        if (this.currUserSearch) {
            const index = this.userSearches.indexOf(this.currUserSearch);
            this.userSearches.splice(index, 1);
            this.searchService.deleteUserSearch(this.currUserSearch);
            this.dataManager.saveEntity('UserSearch');
        }
    }

    loadSavedSearch() {
        if (!this.currUserSearch) {
            return;
        }
        
        this.appInsightsService.trackEvent('advanced-search-load-saved');

        this.expandedNode = null;
        this.searchSpecification = this._pruneLoadedSearch(
            JSON.parse(this.currUserSearch.SearchObject));
        this.normalizeSearchSpecification(this.searchSpecification);

        this.performSearch();
    }

    /**
     * This function prunes the loaded search and grafts on missing branches so that
     * it matches the current search structure.
     */
    private _pruneLoadedSearch(loadedSearch: any) {
        let prunedSearch = null;
        if (this.cleanSearchSpecification) {
            // create a clean copy for pruning
            prunedSearch = JSON.parse(JSON.stringify(this.cleanSearchSpecification));
            this._pruneLoadedSearchRecursive(prunedSearch, loadedSearch);
        }

        if (loadedSearch.sortDir) {
            prunedSearch.sortDir = loadedSearch.sortDir;
        }
        if (loadedSearch.sortName) {
            prunedSearch.sortName = loadedSearch.sortName;
        }

        return prunedSearch;
    }

    private _pruneLoadedSearchRecursive(cleanSearchBranch: any, loadedSearchBranch: any) {
        // convert the loaded search branch into an associative array for quick lookup
        const loadedSearchBranchAssocArr = {};
        loadedSearchBranch.fields.forEach((node: any) => {
            loadedSearchBranchAssocArr[node.name] = node;
        });

        // copy loaded values into the clean branch
        cleanSearchBranch.fields.forEach((node: any) => {
            const loadedNode = loadedSearchBranchAssocArr[node.name];
            if (loadedNode && loadedNode.type === node.type) {
                if ('showInOutput' in loadedNode) {
                    node.showInOutput = loadedNode.showInOutput;
                }

                if (node.type === 'Branch') {
                    // recurse into branch
                    this._pruneLoadedSearchRecursive(node, loadedNode);
                } else if (node.type === 'Text') {
                    node.text = loadedNode.text;
                    node.operator = loadedNode.operator;
                } else if (node.type === 'Date' || node.type === 'Number') {
                    node.minValue = loadedNode.minValue;
                    node.maxValue = loadedNode.maxValue;
                    node.operator = loadedNode.operator;
                } else if (node.type === 'Enumeration') {
                    const loadedVals = loadedNode.values;
                    if (loadedVals) {
                        // we can only load these values if they're still valid
                        // enumerations
                        node.values = loadedVals.filter((val: any) => {
                            return node.enumerations.indexOf(val) !== -1;
                        });
                    }
                    node.operator = loadedNode.operator;
                } else if (node.type === 'Boolean') {
                    node.value = loadedNode.value;
                }
            }
        });
    }

    clearAllFilters() {
        this.clearAllFiltersRecursive(this.searchSpecification);
    }

    clearAllFiltersRecursive = (node: any) => {
        if (node.type === 'Text') {
            node.operator = 'contains';
            node.text = null;
        } else if (node.type === 'Date' || node.type === 'Number') {
            node.operator = 'between';
            node.minValue = null;
            node.maxValue = null;
        } else if (node.type === 'Enumeration') {
            node.operator = 'any in';
            node.values = null;
        } else if (node.type === 'Boolean') {
            node.value = null;
        }

        if (node.fields) {
            node.fields.forEach(this.clearAllFiltersRecursive);
        }
    }
}

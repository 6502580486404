import {
    Component,
    Input,
    OnDestroy,
    OnChanges,
    OnInit,
    EventEmitter,
    Output
} from '@angular/core';
import { Subscription } from 'rxjs';

import {
    ColumnSelect,
    ColumnSelectLabel
} from '@common/facet';

import { CohortService } from '../../../cohort';
import { CopyBufferService } from '@common/services/copy-buffer.service';
import { DataContextService } from '@services/data-context.service';
import { DataManagerService } from '@services/data-manager.service';
import { JobPharmaDetailService } from '../services/job-pharma-detail.service';
import { JobService } from '../../job.service';
import { LoggingService } from '@services/logging.service';
import { SaveChangesService } from '@services/save-changes.service';
import { SaveRecordsOverlayEvent } from './job-pharma-tasks-list-table.component';
import { BulkAssignCohortsModalService } from '../modals';
import { AuthService } from '@services/auth.service';
import { WebApiService } from '@services/web-api.service';
import { CurrentWorkgroupService } from '@services/current-workgroup.service';
import { TranslationService } from '@services/translation.service';
import { JobLogicService } from '../../job-logic.service';
import type { Cohort, Entity, JobCohort, TaskInstance, ExtendedJob, Job } from '@common/types';
import { AnimalCount, ExtendedJobCohort } from "../models/extended-job-cohort";
import { JobPharmaTableService } from '../services/job-pharma-table.service';


@Component({
    selector: 'job-pharma-animals-cohorts-table',
    templateUrl: './job-pharma-animals-cohorts-table.component.html',
    styles: [`
        .ui-draggable-dragging {
            padding: 4px;
            border-radius: 2px;
            font-size: 12px;
            margin-left: 20px;
        }

        .btn-grid-icon {

        }
    `]
})
export class JobPharmaAnimalsCohortsTableComponent implements OnChanges, OnDestroy, OnInit {
    @Input() isCRO: boolean;
    @Input() isGLP: boolean;

    @Input() readonly: boolean;
    @Input() job: Entity<Job & ExtendedJob>;

    @Input() tabset = 'animals';
    @Input() tab = 'cohorts';
    @Output() busy: EventEmitter<SaveRecordsOverlayEvent> = new EventEmitter<SaveRecordsOverlayEvent>();

    loading = false;
    loadingMessage = "Loading";
    jobCohorts: ExtendedJobCohort[] = [];

    // Column selections
    columnSelect: ColumnSelect = {
        model: [],
        labels: [],
    };

    taskNumber = this.jobPharmaDetailService.taskNumberValue;

    // Visible columns
    visible: any = {};

    allSelected = false;

    // All subscriptions
    subs = new Subscription();

    // Where to sort Animals without a Sequence value
    readonly SEQUENCE_END = 1000000;

    readonly COMPONENT_LOG_TAG = 'job-pharma-animals-cohorts-table';
    readonly AMOUNT_ENTITIES_LIMIT = 150;

    constructor(
        private cohortService: CohortService,
        private copyBufferService: CopyBufferService,
        private dataContext: DataContextService,
        private dataManager: DataManagerService,
        private jobPharmaDetailService: JobPharmaDetailService,
        private jobService: JobService,
        private loggingService: LoggingService,
        private saveChangesService: SaveChangesService,
        private bulkAssignCohortService: BulkAssignCohortsModalService,
        private authService: AuthService,
        private webApiService: WebApiService,
        private workgroupService: CurrentWorkgroupService,
        private translationService: TranslationService,
        private jobLogicService: JobLogicService,
        private jobPharmaTableService: JobPharmaTableService
    ) {
        // Do nothing
    }

    async ngOnInit() {
        this.loading = true;
        this.initColumnSelect();
        await this.initialize();
        this.initChangeDetection();
        this.loading = false;
    }

    ngOnChanges(changes: any) {
        if (changes.job && !changes.job.firstChange) {
            this.initJob();
        }
    }

    ngOnDestroy() {
        this.clearSelections();

        // Clear all the subscriptions
        this.subs.unsubscribe();
    }

    /**
     * Watch for external changes
     */
    initChangeDetection() {
        this.subs.add(this.jobPharmaDetailService.jobCohortsChanged$.subscribe(() => {
            this.initJob();
        }));

        // Watch for changes to Job.JobMaterial
        this.subs.add(this.jobPharmaDetailService.jobMaterialsChanged$.subscribe(() => {
            this.initJob();
        }));
    }

    initialize(): Promise<any> {
        this.initTaskNumber();

        return this.initJob();
    }

    initTaskNumber() {
        this.subs.add(this.jobPharmaDetailService.taskNumber$.subscribe(taskNumber => {
            this.taskNumber = taskNumber;
        }));
    }

    /**
     * Initialize the column selections
     */
    initColumnSelect() {
        // Default Visibility
        this.visible = {
            cohort: true,
            description: true,
            animals: true,
        };

        // Assemble the list of all columns that can be selected
        this.columnSelect.labels = [
            new ColumnSelectLabel('cohort', 'Cohort'),
            new ColumnSelectLabel('description', 'Description'),
            new ColumnSelectLabel('animals', 'Animals'),
        ];

        this.columnSelect.model = this.columnSelect.labels.filter(
            (item) => this.visible[item.key]
        ).map((item) => item.key);

        // Register the columns
        this.subs.add(
            this.jobPharmaDetailService.registerColumnSelect(
                this.tabset, this.tab, this.columnSelect,
                () => {
                    this.updateVisible();
                }
            )
        );

        // Update the column visiblility
        this.updateVisible();
    }

    /**
     * Update the column visibility flags.
     */
    updateVisible() {
        // Make a lookup table
        const selected = {};
        this.columnSelect.model.forEach((key) => {
            selected[key] = true;
        });

        // Update the visibilty based on the column selections
        this.columnSelect.labels.forEach((column) => {
            const key = column.key;
            this.visible[key] = (selected[key] === true);
        });
    }

    // Initialize the list of Animals
    private async initJob(): Promise<any> {
        this.loading = true;
        if (!this.job) {
            // Just in case, there is no Job
            this.jobCohorts = [];
            return Promise.resolve();
        }

        const expands = [
            'JobCohort.Cohort.CohortMaterial.Material.Animal',
            'JobCohort.Cohort.CohortMaterial.Material.Line',
            'JobMaterial',
        ];

        await this.dataManager.ensureRelationships([this.job], expands);
        // Find all the Cohorts
        this.jobCohorts = this.job.JobCohort.slice() as ExtendedJobCohort[];
        // Initialize the cohort sequence values
        await this.initCohortSequence();
        // Initialize the per-cohort animal counts
        this.initAnimalCounts();
        // Just in case, clear the selections
        this.clearSelections();
        this.loading = false;
    }

    /**
     * Initialize the Animal JobMaterial.Sequence values to assign defaults and
     * smooth out gaps.
     */
    initCohortSequence(): Promise<void> {
        // We'll want to notify the user when changing the sequences
        let changedSequence = false;

        // Assign default Sequence values just in case an animal is assigned without one.
        this.jobCohorts.forEach((jc: any, index: number) => {
            if (!jc.Sequence) {
                // Add the Cohorts to the end of the sequence in order of their key.
                // Note: new entities have increasingly negative key values.
                jc.Sequence = this.SEQUENCE_END + Math.abs(jc.C_Cohort_key);

                changedSequence = true;
            }
        });

        // Sort by Sequence
        this.jobCohorts.sort(this.sortBySequence);

        // Smooth out the sequence just in case the values are not set or there
        // are gaps;
        this.jobCohorts.forEach((jc: any, index: number) => {
            if (jc.Sequence !== index + 1) {
                // The Index in the DB is 1-based
                jc.Sequence = index + 1;

                changedSequence = true;
            }
        });

        if (changedSequence) {
            // Let the user know about the sequence change
            this.loggingService.logWarning(
                'Updated the Cohort Sequence',
                null,
                this.COMPONENT_LOG_TAG,
                true);
            if (this.canSave) {
                return this.saveChangesService.saveChanges(this.COMPONENT_LOG_TAG);
            }
        }
    }

    /**
     * Count the animals in the cohort and in this job
     */
    private initAnimalCounts() {
        // Make a lookup table of materials in this job
        const inJob = {};
        for (const jm of this.job.JobMaterial) {
            inJob[jm.C_Material_key] = true;
        }

        // Count the Materials in each Cohort and how many of those are in the
        // job
        for (const jc of this.jobCohorts) {
            const counts = new AnimalCount();
            const cohort = jc.Cohort;
            if (cohort) {
                for (const cm of cohort.CohortMaterial) {
                    counts.total += 1;
                    if (inJob[cm.C_Material_key]) {
                        counts.inJob += 1;
                    }
                }
            }

            jc.animalCounts = counts;
        }
    }

    /**
     * Unselect all the rows
     */
    clearSelections() {
        if (this.jobCohorts) {
            // Reset animal selections
            this.jobCohorts.forEach((jc) => {
                jc.isSelected = false;
            });
        }
        this.allSelected = false;
    }

    /**
     * The Select/Clear All button was clicked
     */
    allSelectedChanged() {
        // Select or unselect all the rows
        if (this.jobCohorts) {
            for (const jc of this.jobCohorts) {
                jc.isSelected = this.allSelected;
            }
        }
    }

    /**
     * A row selection checkbox was clicked.
     */
    isSelectedChanged() {
        // Check if all the rows are selected
        this.allSelected = this.jobCohorts.every((jc) => jc.isSelected);
    }

    /**
     * Handle Cohorts being dropped on the job
     */
    onDrop(): Promise<any> {
        const cohorts = this.cohortService.draggedCohorts;
        this.cohortService.draggedCohorts = [];
        this.busy.emit({state: true, message: this.loadingMessage});

        return this.addCohortsToJob(cohorts).finally(() => {
            this.busy.emit({state: false, message: this.loadingMessage});

        });
    }

    async openJobPharmaModal(): Promise<void> {
        const result = await this.bulkAssignCohortService.openComponent(this.job);
        if (result) {
            await this.jobPharmaTableService.onBulkAssign(this.job as Entity<Job>, result);
        }
    }

    saveCohorts(taskKeymap: any, protocolInstance: any): Promise<any> {
        this.busy.emit({state: true, message: this.loadingMessage});

        const requestBody = {
            CreatedBy: this.authService.getCurrentUserName(),
            Workgroup: this.workgroupService.getWorkgroupName(),
            Job_key: this.job.C_Job_key,
            Protocol_key: protocolInstance.C_Protocol_key,
            ProtocolInstance_key:
            protocolInstance.C_ProtocolInstance_key,
            Cohorts_keys: taskKeymap[0].Cohorts_keys
        };

        return this.webApiService
            .postApi("api/ApiCohortsToProtocol/SaveCohortsToProtocol", requestBody, "application/json")
            .then(() => {
                return this.reloadData();
            })
            .catch((error: any) => {
                console.error(error);
            }).finally(() => {
                this.loadingMessage = "";
                this.busy.emit({state: false, message: this.loadingMessage});
            });
    }

    async reloadData(): Promise<void> {
        await this.initialize();
        this.jobPharmaDetailService.tabRefresh('tasks', 'list');
        this.jobPharmaDetailService.tabRefresh('tasks', 'outline');
    }

    /**
     * Handle Cohorts pasted into the Job
     */
    onPaste(): Promise<any> {
        if (!this.copyBufferService.hasCohorts()) {
            return Promise.resolve();
        }
        const pastedCohorts = this.copyBufferService.paste();
        this.busy.emit({state: true, message: this.loadingMessage});
        return this.addCohortsToJob(pastedCohorts).finally(() => {
            this.busy.emit({state: false, message: this.loadingMessage});
        });
    }

    /**
     * Add all the Cohorts to the Job
     */
    async addCohortsToJob(cohorts: any[]) {
        if (!cohorts) {
            return;
        }
        if (this.job.C_Job_key < 0) {
            // If there is no Job
            this.loggingService.logWarning("Cohorts can't be added to a " +
                this.translationService.translate('Job') + " that has not been saved.",
                null, this.COMPONENT_LOG_TAG, true);
            return;
        }

        await this.cohortService.ensureMaterialsExpanded(cohorts);

        if (this.isGLP) {
            for (const cohort of cohorts) {
                try {
                    cohort.isSelected = false;
                    const jobCohort = this.jobService.createJobCohort({
                        C_Job_key: this.job.C_Job_key,
                        C_Cohort_key: cohort.C_Cohort_key
                    });

                    if (!jobCohort) {
                        // This was a duplicate
                        continue;
                    }

                    const cohortAnimals = cohort.CohortMaterial.map((cm: any) => {
                        if (cm.Material) {
                            return cm.Material.Animal;
                        } else {
                            return undefined;
                        }
                    }).filter((cm: any) => cm !== undefined);

                    const existingJobMaterials = await this.jobLogicService.transferAnimalToJob(cohortAnimals, this.job) as any;
                    if (existingJobMaterials[0] === "no animals") {
                        return;
                    } else if (existingJobMaterials[0] === "modal cancelled") {
                        this.dataManager.deleteEntity(jobCohort);
                        return Promise.reject();
                    } else if (existingJobMaterials[0] === "cannot remove") {
                        this.dataManager.deleteEntity(jobCohort);
                        this.loggingService.logWarning("Animals with active tasks cannot be removed from a job without a Default Auto End State task status", "", this.COMPONENT_LOG_TAG, true);
                        return Promise.reject();
                    } else {
                        for (const jobMaterial of existingJobMaterials) {
                            jobMaterial.DateOut = new Date();
                        }
                    }
                    await this.dataContext.saveSingleRecordBatch([jobCohort]);
                    await this.jobPharmaDetailService.refreshMaterialsFromCohort(cohort);
                } catch {
                    this.loggingService.log("Cohort addition cancelled", "", this.COMPONENT_LOG_TAG);
                }
            }
        } else {
            await this.jobPharmaDetailService.addCohortsToJobIfMissing(this.job, cohorts);
        }
    }

    /**
     * Removes JobCohort, which then removes all JobMaterial members of that cohort
     */
    async removeJobCohort(jobCohort: JobCohort): Promise<any> {
        this.loading = true;
        const cohort = jobCohort.Cohort as Entity<Cohort>;
        const taskInstanceKeys = this.job.TaskJob.map((tj: any) => tj.C_TaskInstance_key);
        const animalsWithData: any[] = [];
        cohort.CohortMaterial.forEach((cohortMaterial: any) => {
            const samples = cohortMaterial.Material.SampleGroupSourceMaterial
                .find((x: any) => x.SampleGroup && x.SampleGroup.Sample && x.SampleGroup.Sample.length > 0
                    && taskInstanceKeys.includes(x.SampleGroup.C_TaskInstance_key));
            if (samples) {
                animalsWithData.push(cohortMaterial.Material.Animal);
            }
        });

        if (animalsWithData.length > 0) {
            return this.jobPharmaDetailService.notifyAnimalsHaveSamples(animalsWithData);
        }
        this.jobPharmaDetailService.busyStart();
        try {
            const tasks = this.job.TaskJob.map(item => item.TaskInstance) as Entity<TaskInstance>[];
            const result = await this.jobPharmaDetailService.tryRemoveCohortsFromTasks(
                this.job,
                [cohort],
                tasks,
                !this.isGLP
            );
            if (!result.allRemoved) {
                return this.jobPharmaDetailService.notifyCohortsWithData(result.withData);
            }
            this.jobService.deleteJobCohort(jobCohort);
            await this.jobLogicService.removeJobMaterialsFromCohort(cohort, this.job, this.isGLP);
            this.jobPharmaDetailService.notifyJobArrayChanged("JobCohort");
            this.jobPharmaDetailService.tabRefresh('tasks', 'list');
            this.jobPharmaDetailService.tabRefresh('tasks', 'outline');
        } catch (error) {
            this.loggingService.logError("An unexpected error occurred. Please try again", error, this.COMPONENT_LOG_TAG, true);
        } finally {
            this.jobPharmaDetailService.busyStop();
            this.loading = false;
        }
    }

    // Dragging Out
    private dragId: number = null;

    dragStart() {
        // Find the selected cohorts
        const selected = this.jobCohorts
            .filter((jc) => jc.isSelected)
            .map((jc) => jc.Cohort);

        this.dragId = this.jobPharmaDetailService.startDrag('Cohort', selected);
        if (selected.length > 1) {
            jQuery('.ui-draggable-dragging')[0].textContent = `${selected.length} Cohorts Selected`;
        }
    }

    // TODO: ?
    dragStop() {
        setTimeout(() => {
            this.jobPharmaDetailService.stopDrag(this.dragId);
        }, 500);
    }

    /**
     * ngFor helper to track DOM changes to table rows.
     */
    trackRow = (index: number, item: any): string => {
        // Include the Sequence and Material key in tracking key so ngFor will
        // update properly after a multi-row drag
        return `${item.Sequence}-${item.C_Cohort_key}`;
    }

    // Comparator to sort JobMaterial by Sequence
    sortBySequence = (a: any, b: any): number => {
        return (a.Sequence || 0) - (b.Sequence || 0);
    }

    canSave(): boolean {
        return this.saveChangesService.hasChanges && !this.saveChangesService.saving;
    }
}

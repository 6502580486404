import { InboxMessage } from './models/inbox-message';
import { InboxUserGroup } from './models/inbox-user-group';
import { DataContextService } from './../services/data-context.service';
import { AdminManagerService } from './../services/admin-manager.service';
import {
    Component,
    OnInit,
    OnDestroy,
    NgZone,
    Optional,
    Inject
} from '@angular/core';
import { Subject, Subscription } from 'rxjs';

import { LoggingService } from '../services/logging.service';
import { MessageService } from './message.service';
import { ICenterPanelMessage, MessagePanelService } from './message-panel.service';

import {
    uniqueArrayFromPropertyPath,
    sortObjectArrayByProperty,
    randomId
} from '../common/util';
import { DialogRef } from '@common/dialog/dialog-ref';
import { bellFilled, crossBig } from '@icons';
import { debounceTime } from 'rxjs/operators';
import { CLIMB_DIALOG_DATA } from '@common/dialog/dialog-data.token';

@Component({
    selector: 'message-center',
    templateUrl: './message-center-panel.component.html',
    styleUrls: ['./message-center-panel.component.scss'],
})
export class MessageCenterPanelComponent implements OnInit, OnDestroy {
    icons = { crossBig, bellFilled };

    defaultMessage: ICenterPanelMessage;
    messageSubject = '';
    messageText = '';
    messageKey: number = null;
    toUserKey: number = null;

    searchText = '';

    messagesFiltered = false;
    showNewMessage = false;
    showDismissed = false;

    // Total messages in inbox (considering filters)
    inBoxMessages: any[] = [];
    // inBoxMessages grouped by user
    inBoxUserGroups: InboxUserGroup[] = [];
    sentMessages: any[] = [];

    loadingMessages = false;

    private subs = new Subscription();

    // pagination controls
    readonly INBOX_MESSAGES_PER_PAGE = 5;
    readonly SENT_MESSAGES_PER_PAGE = 10;
    sentPage: number;
    sentPaginationId: string;

    readonly COMPONENT_LOG_TAG = 'message-center';

    updateMessages$ = new Subject<void>();
    private isSavingInProgress = false;

    constructor(
        private ngZone: NgZone,
        private loggingService: LoggingService,
        private messageService: MessageService,
        private messagePanelService: MessagePanelService,
        private dataContext: DataContextService,
        private adminManager: AdminManagerService,
        private dialogRef: DialogRef,
        @Optional() @Inject(CLIMB_DIALOG_DATA) data: ICenterPanelMessage,
    ) {
        this.defaultMessage = data ?? ({} as ICenterPanelMessage);
        if (data) {
            this.showNewMessage = true;
        }
    }

    ngOnInit() {

        this.sentPage = 0;
        this.sentPaginationId = 'sent-' + randomId();

        this.dataContext.init().then(() => {
            this.initialize();
        });
    }

    ngOnDestroy() {
        this.subs.unsubscribe();
    }

    initialize(): Promise<any> {
        this.subs.add(
            this.messagePanelService.openNewMessage$
                .subscribe((defaultValues: ICenterPanelMessage) => {
                    this.openNewMessage(defaultValues);
                })
        );

        this.subs.add(
            this.messagePanelService.openMessageCenter$.subscribe(() => {
                this.getMessages();
            })
        );

        this.subs.add(
            this.updateMessages$
                .pipe(debounceTime(250))
                .subscribe(async() => {
                    if (this.isSavingInProgress) {
                        this.updateMessages$.next();
                        return;
                    }

                    try {
                        this.isSavingInProgress = true;
                        await this.saveMessageEntities();
                    } finally {
                        this.isSavingInProgress = false;
                    }
                })
        );

        this.messagePanelService.signalMessagePanelOpen();

        return this.updateMessageCount();
    }

    closeMessageCenter(): void {
        this.messageService.updateNewMessageCount();
        this.dialogRef.close();
    }


    clearNewMessageFields(): void {
        this.defaultMessage = {} as ICenterPanelMessage;
        this.toUserKey = null;
        this.messageSubject = '';
        this.messageText = '';
    }

    openNewMessage(defaultValues: ICenterPanelMessage): void {
        this.clearNewMessageFields();

        if (defaultValues) {
            this.defaultMessage = {
                messageSubject: defaultValues.messageSubject,
                messageText: defaultValues.messageText,
                messageMaps: defaultValues.messageMaps || []
            };
        }

        this.showNewMessage = true;
    }

    updateMessageCount(): Promise<void> {
        return this.messageService.updateNewMessageCount();
    }

    getMessages() {

        this.loadingMessages = true;

        // InBox
        const p1 = this.messageService.getUserMessages(
            this.searchText,
            this.showDismissed
        ).then((data) => {
            this.inBoxMessages = data;
            this.groupInboxMessages();
        });

        // Sent
        const p2 = this.messageService.getSentMessages(this.searchText)
            .then((data) => {
                this.sentMessages = data;
            });

        this.setMessagesFiltered();

        return Promise.all([p1, p2]).then(() => {
            this.loadingMessages = false;
        }).catch((error) => {
            this.loadingMessages = false;
            throw error;
        });
    }

    /**
     * Group all inBoxMessages by user
     *   Sort users by LastName.
     *
     *  NOTE: Doing this will refresh the inBoxUserGroups reference
     *    causing re-initialization of the ngb-accordion.
     */
    private groupInboxMessages() {
        if (!this.inBoxMessages) {
            this.inBoxUserGroups = [];
            return;
        }
        const senders = uniqueArrayFromPropertyPath(this.inBoxMessages, 'FromUser');
        const userGroups: InboxUserGroup[] = senders.map((user, index) => {

            return <InboxUserGroup> {
                Id: user.Id,
                FirstName: user.FirstName,
                LastName: user.LastName,
                messages: [],
                messagePage: 0,
                paginationId: 'messages-' + index
            };
        });

        sortObjectArrayByProperty(userGroups, 'LastName');

        this.inBoxUserGroups = userGroups;
        this.updateGroupedInboxMessages();
    }

    /**
     * update all the inBox user groups with the current inBox messages per user.
     *   Sort messages by DateGenerated desc
     */
    updateGroupedInboxMessages() {
        const visibleMessages = this.filterVisibleInBoxMessages(this.inBoxMessages);

        for (const userGroup of this.inBoxUserGroups) {
            const userMessages = this.filterMessagesByUser(visibleMessages, userGroup.Id);
            sortObjectArrayByProperty(userMessages, 'DateGenerated', true);
            userGroup.messages = userMessages;
        }
    }

    /**
     * Return all messages for the given user
     * @param messages
     * @param userId
     */
    filterMessagesByUser(messages: InboxMessage[], userId: string): InboxMessage[] {
        if (!messages || !userId) {
            return [];
        }
        return messages.filter((message) => {
            return message.C_FromUser_key === userId;
        });
    }

    filterVisibleInBoxMessages(messages: InboxMessage[]): InboxMessage[] {
        if (!messages) {
            return [];
        }
        return messages.filter((message) => {
            return this.showInBoxMessage(message);
        });
    }

    private setMessagesFiltered() {
        this.messagesFiltered = (this.searchText.length > 0);
    }

    showInBoxMessage(message: any): boolean {
        return (
            this.showDismissed === true || message.IsDismissed === false
        );
    }

    clearSearch() {
        this.searchText = '';
        return this.getMessages();
    }

    changeStatus(message: InboxMessage, isDismissed: boolean) {
        message.IsDismissed = isDismissed;
        this.updateMessages$.next();
        this.updateGroupedInboxMessages();
    }

    showHideClick(isDismissed: boolean) {
        this.showDismissed = isDismissed;
        return this.getMessages();
    }

    showHideMessageContent(message: any) {
        message.textExpanded = !message.textExpanded;
    }

    sendReply(message: any) {
        this.toUserKey = message.C_FromUser_key;
        this.messageSubject = 'RE: '.concat(message.Subject);
        this.messageText = message.ReplyText;
        this.messageService.createMessage(
            this.toUserKey,
            this.messageSubject,
            this.messageText
        );

        return this.saveMessageEntities().then(() => {
            this.showNewMessage = false;
            message.ReplyText = '';
            message.sendingReply = false;
            this.clearNewMessageFields();
            this.getMessages();

            this.showSuccessMessage();
        });
    }

    private showSuccessMessage() {
        this.loggingService.logSuccess("Message sent", null, this.COMPONENT_LOG_TAG, true);
    }

    saveMessageEntities() {
        return this.adminManager.saveEntity('Message');
    }

    cancelReply(message: any) {
        message.ReplyText = '';
        message.sendingReply = false;

        this.clearNewMessageFields();
    }

    newMessageSaved() {
        this.clearNewMessageFields();
        this.showNewMessage = false;
        this.getMessages();
    }

    newMessageCancelled() {
        this.clearNewMessageFields();
        this.showNewMessage = false;
    }
}

import {
    Component,
    OnInit,
    OnChanges,
    Input,
    Output,
    EventEmitter
} from '@angular/core';

import { TaskService } from '../task.service';

import {
    notEmpty,
    randomId,
    getSafeProp
} from '../../common/util/';

/**
 * Controls for selecting a task output.
 */
@Component({
    selector: 'task-output-select',
    templateUrl: './task-output-select.component.html'
})
export class TaskOutputSelectComponent implements OnInit, OnChanges {
    @Input() currentOutput?: any; 
    @Input() defaultWorkflowTaskKey?: number; 
    @Input() allowOnlyNumeric = false;

    // the selected C_Output_key
    @Input() model?: number; 
    @Output() modelChange: EventEmitter<number> = new EventEmitter<number>();

    // emit the selected Output entity
    @Output() outputSelectionChange: EventEmitter<any> = new EventEmitter<any>();
    @Input() disabled: boolean; 

    // state
    selectedOutput: any;

    domIdAddition: string;
    workflowTaskKey: number;
    outputs: any[];

    // The numeric cv_DataTypes
    readonly NUMERIC_DATA_TYPES: string[] = ['Calculated', 'Number'];


    constructor(
        private taskService: TaskService
    ) {
        //
    }

    ngOnInit() {
        this.domIdAddition = randomId();
        
        this.initialize();
    }

    ngOnChanges(changes: any) {
        if (changes.currentOutput &&
            !changes.currentOutput.firstChange
        ) {
            this.initialize();
        }

        if (changes.model &&
            !changes.model.firstChange
        ) {
            this.selectedOutput = null;
            this.initializeModel();
        }
    }

    onTaskChange(): Promise<any> {
        this._resetOutputs();

        return this.initializeTaskOutputs().then(() => {
            this._selectFirstOutput();
            this.model = getSafeProp(this.selectedOutput, 'C_Output_key');
            this._emitModelChange();
        });
    }

    initialize(): Promise<any> {
        this.workflowTaskKey = null;
        this._resetOutputs();

        return this.setWorkflowTaskFromModel().then(() => {
            // set default workflow task key, if one can't be provided
            if (!this.workflowTaskKey && this.defaultWorkflowTaskKey) {
                this.workflowTaskKey = this.defaultWorkflowTaskKey;
            } 

            return this.initializeTaskOutputs().then(() => {
                this.initializeModel();
            });
        });
    }

    setWorkflowTaskFromModel(): Promise<any> {
        if (!this.model) {
            return Promise.resolve();
        }

        return this.taskService.getTaskByOutputKey(this.model).then((task) => {
            this.workflowTaskKey = task.C_WorkflowTask_key;
        });
    }

    initializeTaskOutputs(): Promise<any> {

        if (this.workflowTaskKey <= 0) {
            return Promise.resolve();
        }

        // Get list of outputs for the new task
        return this.taskService.getTaskOutputs(this.workflowTaskKey).then((data: any[]) => {
            let filteredOutputs = this._filterOutputsByDataType(data);
            
            if (this.currentOutput) {
                // Don't include the current output in the list
                filteredOutputs = filteredOutputs.filter((value) => {
                    return value.C_Output_key !== this.currentOutput.C_Output_key;
                });
            }
            this.outputs = filteredOutputs;
        });
    }
    
    initializeModel() {
        if (this.model) {
            this.selectedOutput = this.outputs.find((item) => {
                return item.C_Output_key === this.model;
            });
        }
    }

    private _resetOutputs() {
        this.outputs = [];
        this.selectedOutput = null;
    }

    onSelectedOutputChange(output: any) {
        this.selectedOutput = output;
        this.model = this.selectedOutput.C_Output_key;
        this._emitModelChange();
    }

    private _emitModelChange() {
        this.modelChange.emit(this.model);
        this.outputSelectionChange.emit(this.selectedOutput);
    }

    private _selectFirstOutput() {
        if (notEmpty(this.outputs)) {
            this.selectedOutput = this.outputs[0];
        }
    }

    private _filterOutputsByDataType(values: any[]): any[] {
        let matches = values;
        if (this.allowOnlyNumeric) {
            matches = values.filter((value: any) => {
                return this.NUMERIC_DATA_TYPES.indexOf(value.cv_DataType.DataType) !== -1;
            });
        }

        return matches;
    }
}

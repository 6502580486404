<climb-auth-container *ngIf="isEmailVerified$ | async; else emailVerifying">
    <climb-alert-inline *ngIf="!isProcessCompletedSuccessfully; else redirectMessage">
        Email verified successfully! Please, complete registration process.
    </climb-alert-inline>
    <ng-template #redirectMessage>
        <climb-alert-inline>
            The registration process is complete. You will be redirected to the login screen in a few seconds.
        </climb-alert-inline>
    </ng-template>

    <form [formGroup]="formData">
        <climb-alert-inline *ngIf="errorMessage"
                            [message]="errorMessage"
                            status="error">
        </climb-alert-inline>

        <climb-input size="lg">
            <svg [climbIcon]="icons.user"></svg>
            <input data-automation-id="first-name-input"
                   type="text"
                   climbInput
                   name="firstName"
                   formControlName="FirstName"
                   placeholder="First Name"
                   maxlength="18"
                   required
                   autofocus />
            <div positionEnd>{{ formData.get('FirstName').value.length }} / 18</div>
        </climb-input>

        <climb-input size="lg">
            <svg [climbIcon]="icons.user"></svg>
            <input data-automation-id="last-name-input"
                   type="text"
                   climbInput
                   formControlName="LastName"
                   name="lastName"
                   placeholder="Last Name"
                   maxlength="18"
                   required />
            <div positionEnd>{{ formData.get('LastName').value.length }} / 18</div>
        </climb-input>

        <climb-input size="lg">
            <svg [climbIcon]="icons.phone"></svg>
            <input data-automation-id="mobile-phone-input"
                   type="text"
                   climbInput
                   formControlName="PhoneNumber"
                   name="mobilePhoneNumber"
                   placeholder="Mobile Phone"
                   minlength="10"
                   maxlength="24" />
            <div climbInputError
                 *hasError="formData.get('PhoneNumber'); errorCode 'minlength'; let error">
                Minimum length is {{ error.requiredLength }} digits.
            </div>
        </climb-input>

        <climb-checkbox>
            <input data-automation-id="receive-alerts-via-sms"
                   type="checkbox"
                   climbCheckbox
                   formControlName="SendTextMessages"
                   name="sendTextMessages" />
            Receive alerts via SMS texts
            <div climbCheckboxHint>Carrier message and data charges may apply.</div>
        </climb-checkbox>

        <climb-checkbox>
            <input data-automation-id="agree-terms-conditions"
                   type="checkbox"
                   climbCheckbox
                   formControlName="TermsAccepted"
                   name="termsAccepted" />
            I agree to Climb's
            <a href="https://www.rockstepsolutions.com/terms-and-conditions/"
               target="_blank"
               rel="noopener">
                Terms and Conditions
            </a>
            <div climbCheckboxError
                 *hasError="formData.get('TermsAccepted'); errorCode 'required'">
                You must accept the Terms and Conditions to continue.
            </div>
        </climb-checkbox>

        <button data-automation-id="complete-registration-button"
                climbButton
                type="submit"
                variant="primary"
                size="lg"
                (click)="completeRegistration()"
                [disabled]="formData.invalid">
            Complete Registration
        </button>
    </form>

    <div class="links">
        <a data-automation-id="return-to-login-link"
           routerLink="/login">
        Return to Login
    </a>
    </div>

    <climb-auth-footer authContainerFooter
                       [version]="buildId">
    </climb-auth-footer>
</climb-auth-container>

<ng-template #emailVerifying>
    <climb-auth-container>
        <ng-template #showSpinner>
            <climb-spinner size="lg"></climb-spinner>
        </ng-template>

        <ng-container *ngIf="errorMessage; else showSpinner">
            <climb-alert-inline [message]="errorMessage"
                                status="error">
            </climb-alert-inline>

            <div class="links">
                <a data-automation-id="return-to-login-link"
                   routerLink="/login">
                Return to Login
            </a>
            </div>
        </ng-container>

        <climb-auth-footer authContainerFooter
                           [version]="buildId">
        </climb-auth-footer>
    </climb-auth-container>
</ng-template>

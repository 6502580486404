import { Injectable } from '@angular/core';

import { getMaterialName } from '@common/export';
import { getSafeProp } from '@common/util';

import { BaseExportPlateDetailService } from './base-export-plate-detail.service';
import { getAssayValue, getLineValue, getLineNameValue } from './export-utils';
import { TranslationService } from '../../services/translation.service';

/**
 * Exports a plate detail record to CSV.
 *
 * Assumes plate record has all relationships loaded.
 */
@Injectable()
export class ExportPlateDetailService extends BaseExportPlateDetailService {

    constructor(
        private translationService: TranslationService
    ) {
        super();        
    }


    protected getFileName(plate: any): string {
        return 'plate.csv';
    }

    protected getColumnHeaders(): string[] {
        return [
            'Position',
            'Plate ID',
            'External ID',
            'Animal/Sample',
            this.translationService.translate('Line') + ' Name',
            this.translationService.translate('Line') + ' Short Name',
            'Assay'
        ];
    }

    protected getDataRow(plate: any, plateMaterial: any): any[] {
        let materialDisplay = '';
        let lineDisplay = '';
        let assayDisplay = '';
        let lineNameDisplay = '';
        if (plateMaterial.Material) {
            const material = plateMaterial.Material;
            materialDisplay = getMaterialName(material);
            lineNameDisplay = getLineNameValue(material);
            lineDisplay = getLineValue(material);
            assayDisplay = getAssayValue(material);
        }
       
        return [
            getSafeProp(plateMaterial, 'PlatePosition.PositionName'),
            getSafeProp(plate, 'PlateID'),
            getSafeProp(plate, 'ExternalPlateID'),
            materialDisplay,
            lineNameDisplay,
            lineDisplay,
            assayDisplay
        ];
    }
}

import {
    Component,
    Input,
} from '@angular/core';
import { FeatureFlagService } from '@services/feature-flags.service';
import { TaxonCharacteristic } from '../../../common/types';

import { DataRow, IOColumn, BooleanMap } from '../../models/workflow-bulk-data';

@Component({
    selector: 'workflow-bulk-data-print',
    templateUrl: './workflow-bulk-data-print.component.html',
})
export class WorkflowBulkDataPrintComponent {
    @Input() rows: DataRow[];
    @Input() common: any;
    @Input() visible: BooleanMap;

    @Input() inputColumns: IOColumn[];
    @Input() outputColumns: IOColumn[];
    @Input() taxonCharacteristicsInListView: TaxonCharacteristic[];

    isGLP = false;

    constructor(
        private featureFlagService: FeatureFlagService) {
        this.initIsGLP();
    }

    initIsGLP() {
        const flag = this.featureFlagService.getFlag("IsGLP");
        this.isGLP = (flag && flag.IsActive && flag.Value.toLowerCase() === "true");
    }
}

<div class="wizard-body">

    <h4 class="wizard-heading">
        <ng-content select="[step-heading]"></ng-content>
    </h4>

    <form *ngIf="nameFormat | notEmpty">

        <!-- isActive -->
        <div class="form-check">
            <label class="form-check-label">
                <input type="radio"
                       class="form-check-input"
                       name="isActive-{{domIdAddition}}"
                       id="isActiveFalse-{{domIdAddition}}"
                       [(ngModel)]="nameFormat.isActive"
                       [value]="false" />
                You will <strong>manually enter</strong> {{itemName}} names.
            </label>
        </div>
        <div class="form-check">
            <label class="form-check-label">
                <input type="radio"
                       class="form-check-input"
                       name="isActive-{{domIdAddition}}"
                       id="isActiveTrue-{{domIdAddition}}"
                       [(ngModel)]="nameFormat.isActive"
                       [value]="true" />
                Climb should <strong>auto-generate</strong> {{itemName}} names:
            </label>
        </div>

        <!-- Auto-naming options -->
        <fieldset class="fieldset-bordered"
                  [disabled]="!nameFormat.isActive">

            <div class="form-group">
                <label for="prefix-{{domIdAddition}}">Prefix</label>
                <input type="text"
                       name="prefix-{{domIdAddition}}"
                       id="prefix-{{domIdAddition}}"
                       [(ngModel)]="nameFormat.prefix"
                       class="form-control" />
                <small class="form-text text-muted">
                    Optional prefix for each {{itemName}} name.
                </small>
            </div>

            <div class="form-group">
                <label for="counter-{{domIdAddition}}">Counter Start</label>
                <input type="number"
                       name="counter-{{domIdAddition}}"
                       id="counter-{{domIdAddition}}"
                       [(ngModel)]="nameFormat.counter"
                       required
                       class="form-control input-number" />
                <small class="form-text text-muted">
                    Climb will increment each new {{itemName}} by 1, starting with this number.
                </small>
            </div>

            <div class="form-group">
                <label for="suffix-{{domIdAddition}}">Suffix</label>
                <input type="text"
                       name="suffix-{{domIdAddition}}"
                       id="suffix-{{domIdAddition}}"
                       [(ngModel)]="nameFormat.suffix"
                       class="form-control" />
                <small class="form-text text-muted">
                    Optional suffix for each {{itemName}} name.
                </small>
            </div>

        </fieldset>
    </form>

</div>

<form novalidate>
    <div class="modal-header justify-content-center">
        <h4 class="modal-title">{{appointment.taskInstance?.TaskAlias}}</h4>
    </div>

    <div class="modal-body">
        <schedule-detail #scheduleDetail  
                         *ngIf="appointment.taskInstance"
                         [task]="appointment.taskInstance"
                         [taskType]="appointment.taskInstance?.WorkflowTask?.cv_TaskType?.TaskType"
                         [facet]="facet"
        ></schedule-detail>

    </div><!-- /.modal-body -->

    <div class="modal-footer">
        <button type="button"
                *ngIf="!appointment.taskInstance?.IsLocked"
                class="btn btn-fw btn-lg btn-primary"
                [disabled]="saving"
                (click)="onSubmit()">
            Save
            <span *ngIf="saving">
                <i class="fa fa-spinner fa-spin" title="Saving&hellip;"></i>
            </span>
        </button>
        <button type="button"
                class="btn btn-fw btn-lg btn-secondary"
                [disabled]="saving"
                (click)="onCancel()">
            Cancel
        </button>
    </div>
</form>

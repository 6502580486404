/**
 * Returns a 2d array of batches, containing items of original
 * array, but with each batch having batchSize items
 * E.g. batchArray([1,2,3,4], 2) => [[1,2],[3,4]]
 * @param array
 * @param batchSize
 */
export function batchArray(items: any[], batchSize: number): any[][] {
    const batches: any[][] = [];

    if (items) {
        if (!batchSize) {
            batches.push(items);

        } else {
            for (let i = 0; i < items.length; i += batchSize) {
                batches.push(items.slice(i, i + batchSize));
            }
        }
    }
    return batches;
}

import { Injectable } from '@angular/core';
import { defer, Observable } from 'rxjs';

import { VocabularyService } from '../vocabularies/vocabulary.service';
import { take } from 'rxjs/operators';

@Injectable()
export class InstitutionVocabService {

    // vocab Observables
    public get institutionTypes$(): Observable<any[]>{
        return defer(() => this.vocabularyService.getCV('cv_InstitutionTypes')).pipe(take(1));
    }
    public get states$(): Observable<any[]>{
        return defer(() => this.vocabularyService.getCV('cv_States')).pipe(take(1));
    }
    public get countries$(): Observable<any[]>{
        return defer(() => this.vocabularyService.getCV('cv_Countries')).pipe(take(1));
    }
    public get siteTypes$(): Observable<any[]>{
        return defer(() => this.vocabularyService.getCV('cv_SiteTypes')).pipe(take(1));
    }
    public get contactTypes$(): Observable<any[]>{
        return defer(() => this.vocabularyService.getCV('cv_ContactPersonTypes')).pipe(take(1));
    }

    constructor(
        private vocabularyService: VocabularyService
    ) {}

    addNewCountry(country: string): any {
        const newCountry = this.vocabularyService.create('cv_Country');
        /* eslint-disable @typescript-eslint/dot-notation */
        newCountry['Country'] = country;
        newCountry['Abbreviation'] = country;
        /* eslint-enable @typescript-eslint/dot-notation */
        newCountry.IsActive = true;
        newCountry.IsDefault = false;
        newCountry.IsDeprecated = false;
        newCountry.SortOrder = 1;

        return newCountry;
    }

    addNewState(state: string, countryKey: number): any {
        const newState = this.vocabularyService.create('cv_State');
        /* eslint-disable @typescript-eslint/dot-notation */
        newState['StateName'] = state;
        newState['Abbreviation'] = state;
        newState['C_Country_key'] = countryKey;
        /* eslint-enable @typescript-eslint/dot-notation */
        newState.IsActive = true;
        newState.IsDefault = false;
        newState.IsDeprecated = false;
        newState.SortOrder = 1;

        return newState;
    }
}

import {
    Component,
    Input,
} from '@angular/core';

import { BirthService } from '../birth.service';

import {
    notEmpty
} from '../../common/util';

@Component({
    selector: 'birth-mating-table',
    templateUrl: './birth-mating-table.component.html'
})
export class BirthMatingTableComponent {
    @Input() birth: any;
    // The designated parents of the animals in this birth. 
    @Input() birthMaterials: any[] = [];
    @Input() mating: any;
    @Input() facetPrivilege: string;

    constructor(
        private birthService: BirthService
    ) {
        //
    }


    isBirthMaterial(materialKey: number): boolean {
        if (!materialKey || !this.birthMaterials) {
            return false;
        }

        let out = false;
        for (const birthMaterial of this.birthMaterials) {
            if (birthMaterial.C_Material_key === materialKey) {
                out = true;
                break;
            }
        }
        return out;
    }

    private findBirthMaterialsForMaterial(materialKey: number): any[] {
        if (!this.birthMaterials) {
            return [];
        }

        return this.birthMaterials.filter((birthMaterial) => {
            return birthMaterial.C_Material_key === materialKey;
        });
    }

    /**
     * On animal selection change, add or remove it from BirthMaterials
     *
     * @param materialKey
     */
    onBirthMaterialChange(materialKey: number) {
        if (this.facetPrivilege === 'ReadWrite') {
            const changedBirthMaterials = this.findBirthMaterialsForMaterial(materialKey);

            if (notEmpty(changedBirthMaterials)) {
                this.removeBirthMaterials(changedBirthMaterials);
            } else {
                this.addBirthMaterial(materialKey);
            }
        }
    }

    private addBirthMaterial(materialKey: number) {
        const initialValues = {
            C_Birth_key: this.birth.C_Birth_key,
            C_Material_key: materialKey
        };
        this.birthService.createBirthMaterial(initialValues);
    }

    private removeBirthMaterials(birthMaterials: any[]) {
        for (const birthMaterial of birthMaterials) {
            this.birthService.deleteBirthMaterial(birthMaterial);
        }
    }
}

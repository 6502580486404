import { convertValueToLuxon } from '@common/util/date-time-formatting/convert-value-to-luxon';
import { Predicate } from 'breeze-client';
import { DateTime } from 'luxon';

/**
 * Creates Breeze Predicates for a date range.
 * @param property the property name
 * @param dateStart the range start date
 * @param dateEnd the range end date
 */
export function getDateRangePredicates(
    property: string,
    dateStart: Date,
    dateEnd: Date,
    convertUtc?: boolean
): Predicate[] {
    const predicates: Predicate[] = [];

    if (!property) {
        return predicates;
    }

    let timeZone = "";
    if (convertUtc) {
        timeZone = "UTC";
    } else {
        timeZone = new Intl.DateTimeFormat().resolvedOptions().timeZone;
    }

    const startLuxon = convertValueToLuxon(dateStart);
    const endLuxon = convertValueToLuxon(dateEnd);
    
    const dateStartUtc = startLuxon ? startLuxon.setZone("UTC").toJSDate() : null;
    const startPredicate = getStartPredicate(property, dateStartUtc, timeZone);
    if (startPredicate) {
        predicates.push(startPredicate);
    }

    const dateEndUtc = endLuxon ? endLuxon.setZone("UTC").toJSDate() : null;
    const endPredicate = getEndPredicate(property, dateEndUtc, timeZone);
    if (endPredicate) {
        predicates.push(endPredicate);
    }

    return predicates;
}

function getStartPredicate(property: string, date: Date, timeZone: string): Predicate {
    if (!date) {
        return null;
    }

    // Truncate time
    const predicateDate = DateTime.fromJSDate(date)
        .startOf('day')
        .setZone(timeZone)
        .toJSDate();

    return Predicate.create(property, '>=', predicateDate);
}

function getEndPredicate(property: string, date: Date, timeZone: string): Predicate {
    if (!date) {
        return null;
    }

    // Truncate time and add a day
    const predicateDate = DateTime.fromJSDate(date)
        .startOf("day")
        .plus({day: 1})
        .setZone(timeZone)
        .toJSDate();

    return Predicate.create(property, '<', predicateDate);
}

export function getDateRangePredicatesUTC(property: string, dateStart: Date, dateEnd: Date): Predicate[] {
    return getDateRangePredicates(property, dateStart, dateEnd, true);
}

<form #profileForm="ngForm" (ngSubmit)="onSubmit(profileForm.value)" novalidate>
    <div class="modal-header justify-content-center">
        <h4 class="modal-title">Edit Profile<span *ngIf="user">: {{user.UserName}}</span></h4>
    </div>

    <div class="modal-body">
        <div *ngIf="user">
            <div class="form-group">
                <label for="firstNameInput">First Name</label>
                <input class="form-control" type="text" id="firstNameInput" name="FirstName"
                       [(ngModel)]="user.FirstName" maxlength="18" required />
            </div>

            <div class="form-group">
                <label for="lastNameInput">Last Name</label>
                <input class="form-control" type="text" id="lastNameInput" name="LastName"
                       [(ngModel)]="user.LastName" maxlength="18" required />
            </div>

            <fieldset>
                <div class="form-group" *ngFor="let emailAddress of user.UserContactEmailAddresses">
                    <label for="emailInput">Email</label>
                    <input class="form-control" type="email" id="emailInput" name="EmailAddress"
                           [(ngModel)]="emailAddress.EmailAddress" maxlength="254" required />
                </div>
            </fieldset>

            <ng-container *ngIf="!isGLP">
                <fieldset>
                    <div *ngFor="let phoneNumber of user.UserContactPhoneNumbers" class="mb-2">
                        <div class="form-group">
                            <label for="phoneNumberInput">Mobile Phone</label>
                            <input class="form-control" type="text" id="phoneNumberInput" name="PhoneNumber"
                                   [(ngModel)]="phoneNumber.PhoneNumber" minlength="10" maxlength="24" style="max-width:24em;" />
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                                <input type="checkbox" class="form-check-input" name="SupportsSMS" [(ngModel)]="phoneNumber.SupportsSMS">
                                Receive alerts via SMS texts
                            </label>
                            <small class="form-text text-muted">Carrier message and data charges may apply.</small>
                        </div>
                    </div>
                </fieldset>
            </ng-container>

            <div class="form-group mb-0">
                <label>Workgroup Roles</label>

                <ul class="list-unstyled mb-0">
                    <li *ngIf="userRole">{{userRole}}</li>
                    <li *ngIf="studyAdministrator">{{'Study' | translate}} Administrator</li>
                </ul>
            </div>

        </div>
    </div><!-- /.modal-body -->

    <div class="modal-footer">
        <button type="submit" class="btn btn-fw btn-lg btn-primary" [disabled]="!profileForm.form.valid">
            Save
        </button>
        <button type="button" class="btn btn-fw btn-lg btn-secondary" (click)="cancelClicked()">
            Cancel
        </button>
    </div>
</form>
<loading-overlay *ngIf="!user" [message]="'Loading your profile'"></loading-overlay>

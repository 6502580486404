import type { Job } from './job.interface';
import type { TaskInstance } from './task-instance.interface';

export interface TaskJob {
    C_Job_key: number;
    C_TaskInstance_key: number;
    C_TaskJob_key: number;
    C_Workgroup_key: number;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    Job: Job;
    ModifiedBy: string;
    Sequence: number;
    TaskInstance: TaskInstance;
    Version: number;
    IsLocked: boolean;
}

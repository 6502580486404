import { WorkflowApiFilter } from './models/workflow-api-filter';
import { APIQueryDef } from '@services/api-query-def';
import { ClinicalService } from '../clinical/clinical.service';
import { ClinicalVocabService } from '../clinical/clinical-vocab.service';
import {
    Component,
    Input,
    OnInit,
    OnDestroy,
    ViewChild,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { WorkflowService } from './services/workflow.service';
import { WorkflowVocabService } from './services/workflow-vocab.service';
import { CellFormatterService, ColumnsState, TableColumnDef } from '@common/datatable';
import { TranslationService } from '@services/translation.service';

import {
    BaseFacet,
    BaseFacetService,
    FacetView
} from '@common/facet';

import { WorkflowFilterComponent } from './components';

import { WorkflowTableOptions } from './helpers/workflow-table-options';
import {
    TableState,
    DataResponse
} from '@common/datatable';
import { WorkspaceFilterService } from '@services/workspace-filter.service';
import { WorkflowTaskInstanceRecord } from './models/workflow-task-instance-record';
import { WsFilterEvent } from '@services/ws-filter-event';

import { DateFormatterService } from '@common/util/date-time-formatting';
import { filterToDate, getSafeProp, notEmpty } from '@common/util';
import { TaskType } from '../tasks/models';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { FeatureFlagService } from '@services/feature-flags.service';
import { WorkflowBulkDataEntryComponent } from './workflow-bulk-data-entry/workflow-bulk-data-entry.component';
import { DataContextService } from "@services/data-context.service";
import { takeUntil } from "rxjs/operators";
import { DateTime } from 'luxon';
import { convertValueToLuxon } from '@common/util/date-time-formatting/convert-value-to-luxon';
import { arrowClockwise, brokenChain, chain, magnifier } from '@icons';
import { Cohort, WorkflowTask } from '../common/types/models';

@Component({
    selector: 'workflow-facet',
    templateUrl: './workflow-facet.component.html',
    providers: BaseFacet.BASE_COMPONENT_PROVIDERS,
    styleUrls: ['./workflow-facet.component.scss'],
})
export class WorkflowFacetComponent extends BaseFacet
    implements OnInit, OnDestroy {
    @Input() facetId: string;
    @Input() facet: any;
    @ViewChild(WorkflowBulkDataEntryComponent) workflowBulkDataEntryComponent: WorkflowBulkDataEntryComponent;

    readonly icons = { arrowClockwise, brokenChain, chain, magnifier };

    // export enum to template
    TaskType = TaskType;

    componentName = 'workflow';
    workflowTableOptions: WorkflowTableOptions;

    healthRecordAnimal: any = null;
    currentTaskInstance: any = null;
    selectedTaskInstances: any = null;
    selectedTaskKeys: string[];

    groupToggle: boolean;

    componentFilterSubscription: Subscription;

    isGLP: boolean;

    readonly BULK_DATA_ENTRY_VIEW: FacetView = FacetView.BULK_DATA_ENTRY_VIEW;
    private notifier$ = new Subject<void>();

    dataTableColumns: BehaviorSubject<TableColumnDef[]>;
    dataTableColumns$: Observable<TableColumnDef[]>;

    constructor(
        private workflowService: WorkflowService,
        private clinicalVocabService: ClinicalVocabService,
        private clinicalService: ClinicalService,
        private workflowVocabService: WorkflowVocabService,
        baseFacetService: BaseFacetService,
        private cellFormatterService: CellFormatterService,
        private translationService: TranslationService,
        private modalService: NgbModal,
        private featureFlagService: FeatureFlagService,
        workspaceFilterService: WorkspaceFilterService,
        private dataContext: DataContextService,
        private dateFormatterService: DateFormatterService
    ) {
        super(
            baseFacetService,
            workspaceFilterService
        );

        this.initIsGLP();

        this.workflowTableOptions = new WorkflowTableOptions(
            this.cellFormatterService,
            this.translationService,
            this.isGLP,
            this.dateFormatterService
        );

        this.dataTableColumns = new BehaviorSubject(this.workflowTableOptions.options.columns);
        this.dataTableColumns$ = this.dataTableColumns.asObservable();

        this.dataService = {
            run: (tableState: TableState) => {
                return this.loadWorkflowList(tableState);
            }
        };
    }

    // lifecycle
    ngOnInit() {
        super.ngOnInit();
        this.supportedWorkspaceFilters = ['job-filter'];

        this.initialize();

        this.createPaginator();

        this.dataContext.onCancel$.pipe(takeUntil(this.notifier$)).subscribe(() => {
            this.changeView(this.LIST_VIEW);
        });

        this.workflowService.refreshWorkflowFacet.subscribe(() => this.refreshData());
    }

    ngOnDestroy() {
        if (this.componentFilterSubscription) {
            this.componentFilterSubscription.unsubscribe();
        }
        this.notifier$.next();
        this.notifier$.complete();
    }

    initialize() {
        this.restoreFilterState();
        this.changeView(this.LIST_VIEW);
    }

    initIsGLP() {
        this.isGLP =  this.featureFlagService.getIsGLP();
    }

    refreshData() {
        this.initialize();
        this.reloadTable();
    }

    refreshDataBulkDataEntry() {
        this.workflowBulkDataEntryComponent?.refreshData();
    }
    
    restoreFilterState() {
        this.initTogglesState();

        // process any grid filters
        if (this.facet && this.facet.GridFilter) {
            try {
                this.filter = JSON.parse(this.facet.GridFilter);
            } catch (err) {
                console.error(err);
            }

            if (this.filter) {
                this.filter.DateDueStart = filterToDate(this.filter.DateDueStart);
                this.filter.DateDueEnd = filterToDate(this.filter.DateDueEnd);
            } else {
                this.filter = {};
            }
        }
    }

    // Get isGrouped property for group toggle or create one if null
    initTogglesState() {
        let gridFilter = JSON.parse(this.facet.GridFilter);

        if (!gridFilter) {
            gridFilter = {};
        }
        if (!gridFilter.isGrouped) {
            gridFilter.isGrouped = false;
        }

        this.groupToggle = gridFilter.isGrouped;
        this.facet.GridFilter = JSON.stringify(gridFilter);
    }

    groupToggleChange() {
        this.filter.isGrouped = this.groupToggle;
        this.facet.GridFilter = JSON.stringify(this.filter);

        this.dataTableComm.showColumn(this.workflowTableOptions.options.columns[0].field);
        this.reloadTable();
    }

    async loadWorkflowList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'DateDue DESC';
        const visibleColumns = this.getWorkgroupColumns();

        const columnsForQuery = [...new Set(visibleColumns)];
        const queryDef: APIQueryDef = {
            page,
            size: pageSize,
            sort,
            filter: this.processFilter(),
            group: this.groupToggle,
            columns: [JSON.stringify(columnsForQuery)]
        };

        this.setLoadingState(tableState.loadingMessage);

        try {
            const response = await this.workflowService.getAPITasks(queryDef);

            await this.workflowService.ensureVisibleColumnsDataLoaded(response.results, columnsForQuery);

            this.stopLoading();

            this.data = response.results as WorkflowTaskInstanceRecord[];

            this.totalCount = response.totalTaskCount;
            this.updatePageState();

            // preload vocab
            this.workflowVocabService.preloadCVs();
            this.clinicalVocabService.preloadCVs();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        } finally {
            this.stopLoading();
        }
    }

    getWorkgroupColumns(): string[] {
        let visibleColumns = this.getVisibleColumns(this.workflowTableOptions.options);
        visibleColumns = visibleColumns.filter((col) => col !== 'TaskCount');
        if (visibleColumns.includes('DateDueTime') && !visibleColumns.includes('DateDue')) {
            // Sort by DateDue even if DateDue column is not visible
            visibleColumns.push('DateDue');
        }
        return visibleColumns;
    }
    onDetailLinkClick(itemToEdit: any): Promise<any> {
        if (this.groupToggle) {
            // Go to the Bulk Data Entry view
            this.selectedTaskKeys = itemToEdit.TaskKeys.split(',');
            return Promise.resolve(this.BULK_DATA_ENTRY_VIEW);
        } else {
            // Go to the Details view
            const taskInstanceKey = itemToEdit.TaskKey;

            return this.workflowService.getTaskInstance(taskInstanceKey).then((taskInstance) => {
                this.currentTaskInstance = taskInstance;
                const taskType = getSafeProp(taskInstance, 'WorkflowTask.cv_TaskType.TaskType');

                if (taskType === TaskType.HealthRecord) {
                    return this.initHealthRecordAnimal(taskInstanceKey);
                }
            }).then(() => {
                return this.DETAIL_VIEW;
            });
        }
    }

    initHealthRecordAnimal(taskKey: any): Promise<any> {
        // load health record animal required for clinical detail
        this.setLoadingState();
        this.healthRecordAnimal = null;

        return this.clinicalService.getAnimalForHealthRecordTask(taskKey).then((animals) => {
            this.stopLoading();
            if (animals && animals.length > 0) {
                this.healthRecordAnimal = animals[0];
            }
        });
    }

    openFilter() {
        const ref = this.modalService.open(WorkflowFilterComponent, { size: 'lg' });
        const component = ref.componentInstance as WorkflowFilterComponent;
        component.filter = this.filter;
        component.isGLP = this.isGLP;
        this.componentFilterSubscription = component.onFilter.subscribe((filter: any) => {
            this.filter = filter;
            this.runFilter();
        });
    }

    /**
     * Convert form values to WorkflowApiFilter format
     */
    processFilter(): WorkflowApiFilter {
        const filter = this.getActiveFilter();
        filter.UtcOffset = new Date().getTimezoneOffset() / 60;

        const apiFilter: WorkflowApiFilter = { ...filter };

        // Never include Group TaskInstances
        apiFilter.IsGroup = false;

        if (filter.DateDueToday) {
            // Force the date range to start and end today
            apiFilter.DateDueStart = DateTime.now().setZone("UTC").startOf('day').toISO();
            // Bug21687: use end of the day here because DateDue field in the database is DateTime while the filter field is Date only
            apiFilter.DateDueEnd = DateTime.now().setZone("UTC").endOf('day').toISO();
        } else {
            if (filter.DateDueStart) {
                // Bug21687: use start of the day here because DateDue field in the database is DateTime while the filter field is Date only
                apiFilter.DateDueStart = convertValueToLuxon(filter.DateDueStart).setZone("UTC").startOf('day').toISO();
            }

            if (filter.DateDueEnd) {
                // Bug21687: use end of the day here because DateDue field in the database is DateTime while the filter field is Date only
                apiFilter.DateDueEnd = convertValueToLuxon(filter.DateDueEnd).setZone("UTC").endOf('day').toISO();
            }
        }

        if (notEmpty(filter.jobs)) {
            apiFilter.JobKeys = filter.jobs.map((item: any) => {
                return item.JobKey;
            });
        }

        if (notEmpty(filter.studies)) {
            apiFilter.StudyKeys = filter.studies.map((item: any) => {
                return item.StudyKey;
            });
        }

        // handle workspace filters
        if (notEmpty(filter['job-filter'])) {
            apiFilter.JobKeys = filter['job-filter'];
        }

        if (notEmpty(filter.cohortsNames)) {
            apiFilter.Cohorts = filter.cohortsNames.map((item: Cohort) => {
                return item.C_Cohort_key;
            });
        }

        if (notEmpty(filter.workflowTasks)) {
            apiFilter.WorkflowTaskKeys = filter.workflowTasks.map((item: WorkflowTask) => {
                return item.C_WorkflowTask_key;
            });
        }

        if (notEmpty(filter.C_TaskStatus_keys)) {
            apiFilter.TaskStatusKeys = filter.C_TaskStatus_keys;
        }

        if (notEmpty(filter.C_JobStatus_keys)) {
            apiFilter.JobStatusKeys = filter.C_JobStatus_keys;
        }

        if (notEmpty(filter.C_TaskType_keys)) {
            apiFilter.TaskTypeKeys = filter.C_TaskType_keys;
        }

        if (notEmpty(filter.C_Resource_keys)) {
            apiFilter.ResourceKeys = filter.C_Resource_keys;
        }

        if (filter.Identifiers && filter.Identifiers.length) {
            apiFilter.MaterialIdentifiers = filter.Identifiers;
        }

        if (filter.Animals && filter.Animals.length) {
            apiFilter.MaterialKeys = filter.Animals.map((animal: any) => animal.C_Material_key);
        }

        if (notEmpty(filter.Lines)) {
            apiFilter.LineKeys = filter.Lines.map((line: any) => {
                return line.LineKey;
            });
        }

        if (notEmpty(filter.MaterialPools)) {
            apiFilter.HousingKeys = filter.MaterialPools.map((pool: any) => {
                return pool.C_MaterialPool_key;
            });
        }
        return apiFilter;
    }

    onWorkspaceFilterChange(wsFilterEvent: WsFilterEvent) {
        // TODO (kevin.stone): this might be ok to put in base-facet
        //   Need to test
        const oldFilterSupported = this.workspaceFilterSupported(wsFilterEvent.oldFilterKind);
        const newFilterSupported = this.workspaceFilterSupported(wsFilterEvent.filterKind);
        if (!this.ignoreWorkspaceFilter && (oldFilterSupported || newFilterSupported)) {
            this.reloadTable();
        }
    }

    sendSelectedToBulkEdit() {
        const taskInstanceKeys = this.findSelectedTaskInstanceKeys();
        this.workflowService.getTaskInstances(taskInstanceKeys).then((data) => {
            this.selectedTaskInstances = data;
            this.changeView(this.BULK_EDIT_VIEW);
        });
    }

    clickBulkDataEntry() {
        const taskInstanceKeys = this.findSelectedTaskInstanceKeys();
        this.selectedTaskKeys = taskInstanceKeys;
        this.changeView(this.BULK_DATA_ENTRY_VIEW);
    }

    exitBulkDataEntry() {
        this.reloadTable();
        this.changeView(this.LIST_VIEW);
    }

    exitDetail() {
        this.reloadTable();
        this.changeView(this.LIST_VIEW);
    }

    exitBulkEdit() {
        this.reloadTable();
        this.changeView(this.LIST_VIEW);
    }

    findSelectedTaskInstanceKeys(): any[] {
        if (this.groupToggle) {
            // Merge all the TaskKeys from each row
            let taskKeys: string[] = [];
            this.selectedRows.forEach((row: any) => {
                taskKeys = taskKeys.concat(row.TaskKeys.split(','));
            });

            return taskKeys;
        } else {
            // One task per row
            return this.selectedRows.map((row: any) => row.TaskKey);
        }
    }

    async selectedColumnsChange({ visible }: ColumnsState) {
        try {
            this.facetLoadingState.changeLoadingState(true);
            await this.workflowService.ensureVisibleColumnsDataLoaded(this.data, visible);
        } finally {
            this.facetLoadingState.changeLoadingState(false);
        }
    }
}

import {
    SELECT_ALL_COLUMN,
    CellFormatterService,
    TableOptions
} from '@common/datatable';
import { TranslationService } from '@services/translation.service';
import { addAuditColumnsToOptions } from '@common/datatable/utils';

export class GenotypeTableOptions {

    options: TableOptions;

    constructor(
        private cellFormatterService: CellFormatterService,
        private translationService: TranslationService
    ) {
        this.options = addAuditColumnsToOptions(cellFormatterService)(this.getDefaultOptions());
    }

    getDefaultOptions(): TableOptions {

        return {
            csvFileName: 'genotypes.csv',
            enableDetailColumn: false,
            enableDraggable: false,
            enableSelectable: true,
            groupBy: 'Animal.C_Material_key',
            columns: [
                { 
                    displayName: 'Animal ID', field: 'Animal.Material.Identifier',
                    isGrouped: true,
                    width: 80
                },
                { 
                    displayName: 'Animal Name', field: 'Animal.AnimalName',
                    isGrouped: true,
                    width: 100
                },
                { 
                    displayName: 'Sex', field: 'Animal.cv_Sex.Sex',
                    isGrouped: true,
                    visible: false,
                },
                { 
                    displayName: 'Marker', field: 'Animal.PhysicalMarker',
                    isGrouped: true,
                    visible: false
                },
                { 
                    displayName: this.translationService.translate('Line'), field: 'Animal.Material.Line.LineName',
                    isGrouped: true,
                    visible: false
                },
                // insert checkbox column in a different location
                SELECT_ALL_COLUMN,
                { 
                    displayName: 'Assay', field: 'cv_GenotypeAssay.GenotypeAssay'
                },
                { 
                    displayName: 'Genotype', field: 'cv_GenotypeSymbol.GenotypeSymbol'
                },
                {
                    displayName: 'Date', field: 'DateGenotype',
                    formatter: this.cellFormatterService.dateFormatter,
                    visible: false
                },
                { 
                    displayName: 'Plate', field: 'Plate.PlateID'
                }
            ]
        };
    }
}

import { getSafeProp } from "../../common/util";

/**
 * sorts the PlateMaterial records by X position then Y Position
 * @param plateMaterials 
 */
export function sortPlateMaterialsByXY(plateMaterials: any[]): any[] {

    plateMaterials.sort((pm1: any, pm2: any) => {
        const x1 = getSafeProp(pm1, 'PlatePosition.XPosition');
        const y1 = getSafeProp(pm1, 'PlatePosition.YPosition');
        const x2 = getSafeProp(pm2, 'PlatePosition.XPosition');
        const y2 = getSafeProp(pm2, 'PlatePosition.YPosition');

        return (x1 - x2) || (y1 - y2);
    });
    return plateMaterials;
}

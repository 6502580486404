import { MessageService } from './message.service';
import {
    Component,
    OnInit,
    OnDestroy,
    ChangeDetectorRef
} from '@angular/core';
import { Subscription } from 'rxjs';
import { MessagePanelService } from './message-panel.service';

/**
 * Menu icon for accessing the message center
 */
@Component({
    // set as attribute for use in bootstrap navbar
    /* eslint-disable-next-line */
    selector: '[message-center-icon]',
    templateUrl: 'message-center-icon.component.html',
})
export class MessageCenterIconComponent implements OnInit, OnDestroy {

    newMessageCount = 0;
    _subscriptions: Subscription[] = [];

    constructor(
        private messageService: MessageService,
        private ref: ChangeDetectorRef,
        private messagePanelService: MessagePanelService
    ) {}

    ngOnInit() {
        // subscribe to new message counts
        const s1 = this.messageService.newMessageCount$.subscribe((count) => {
            if (this.newMessageCount !== count) {
                this.newMessageCount = count;
                // must trigger changes because message updateMessageCount runOutsideAngular zone
                this.ref.detectChanges();
            }
        });
        this._subscriptions.push(s1);
    }

    ngOnDestroy() {
        for (const subscription of this._subscriptions) {
            subscription.unsubscribe();
        }
    }

    signalMessagePanelOpen() {
        this.messagePanelService.signalMessagePanelOpen();
    }
}

import type { EnumerationClass } from './enumeration-class.interface';
import type { InputDefault } from './input-default.interface';
import type { OutputDefault } from './output-default.interface';
import type { SampleCharacteristicInstance } from './sample-characteristic-instance.interface';
import type { TaxonCharacteristicInstance } from './taxon-characteristic-instance.interface';

export interface EnumerationItem {
    C_EnumerationClass_key: number;
    C_EnumerationItem_key: number;
    C_ParentEnumerationItem_key: number | null;
    C_Workgroup_key: number;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    Description: string;
    EnumerationClass: EnumerationClass;
    InputDefault: InputDefault[];
    ItemName: string;
    ModifiedBy: string;
    OutputDefault: OutputDefault[];
    SampleCharacteristicInstance: SampleCharacteristicInstance[];
    TaxonCharacteristicInstance: TaxonCharacteristicInstance[];
    Version: number;
}

import { InjectionToken } from "@angular/core";

/**
 * Injection token that can be used to specify the
 * Surface Mode for all components within a module.
 */
export const CLIMB_SURFACE_MODE = new InjectionToken<SurfaceMode>(
    'CLIMB_SURFACE_MODE',
);

export type SurfaceMode = 'light' | 'dark';

import { Injectable } from '@angular/core';

import { UserNameService } from '../../user/user-name.service';
import { CsvExporter } from '../../common/export/csv-exporter';
import { DateFormatterService } from '@common/util/date-time-formatting';

/**
 * Exports an census audit report to CSV
 */
@Injectable()
export class ExportCensusAuditService {
    csvExporter: CsvExporter;

    constructor(
        private userNameService: UserNameService,
        private dateFormatterService: DateFormatterService
    ) {
        this.csvExporter = new CsvExporter();
    }


    exportToCsv(
        auditData: any[],
        auditDiscrepanciesData: any[]

    ) {
        const EXPORTER_CSV_FILENAME = "CensusAudit.csv";

        const data: any[][] = this.buildExportData(
            auditData,
            auditDiscrepanciesData
        );

        this.csvExporter.download(data, EXPORTER_CSV_FILENAME);
    }

    buildExportData(
        auditData: any[],
        auditDiscrepanciesData: any[]
    ): any[][] {
        const data: any[][] = [];

        data.push(['Details']);
        data.push([
            'Action',
            'Modified Date',
            'Modified By',
            'ID',
            'Status',
            'Date'
        ]);
        for (const row of auditData) {
            data.push([
                row.UpdateType,
                this.dateFormatterService.formatDateTimeUTC(row.ModifiedDate),
                this.userNameService.toFullName(row.ModifiedBy),
                row.C_Census_key,
                row.Status,
                row.Date
            ]);
        }

        if (auditDiscrepanciesData.length > 0) {
            data.push([]);
            data.push(['Discrepancies']);
            data.push([
                'Action',
                'Modified Date',
                'Modified By',
                'Housing ID',
                'Discrepancy Count',
                'Original Discrepancy Count',
                'Completed'
            ]);
            for (const row of auditDiscrepanciesData) {
                data.push([
                    row.UpdateType,
                    this.dateFormatterService.formatDateTimeUTC(row.ModifiedDate),
                    this.userNameService.toFullName(row.ModifiedBy),
                    row.HousingID,
                    row.DiscrepancyCount,
                    row.OriginalDiscrepancyCount,
                    row.IsCompleted
                ]);
            }
        }

        return data;
    }
}

import {
    CellFormatterService,
    END_STATE_CLASS,
    TableOptions
} from '../common/datatable';
import { TranslationService } from '../services/translation.service';
import { addAuditColumnsToOptions } from '@common/datatable/utils';

export class LineTableOptions {

    options: TableOptions;

    constructor(
        private cellFormatterService: CellFormatterService,
        private translationService: TranslationService
    ) {
        this.options = addAuditColumnsToOptions(cellFormatterService)(this.getDefaultOptions());
    }

    getDefaultOptions(): TableOptions {
        return {
            csvFileName: this.translationService.translate('Lines') + '.csv',
            enableDetailColumn: true,
            enableDraggable: false,
            enableSelectable: false,
            rowClass: (params: any) => {
                const row = params.data;
                const classes: string[] = [];
                if (row && row.IsActive !== true) {
                    classes.push(END_STATE_CLASS);
                }
                return classes;
            },
            columns: [
                { displayName: 'Name', field: 'LineName' },
                {
                    displayName: this.translationService.translate('Line') + 'ShortName',
                    field: 'StockNumber'
                },
                {
                    displayName: 'Stock',
                    field: 'StockID',
                    visible: false
                },
                { displayName: 'Type', field: 'cv_LineType.LineType' },
                { displayName: 'Status', field: 'cv_LineStatus.LineStatus' },
                { displayName: 'Species', field: 'cv_Taxon.CommonName' },
                {
                    displayName: 'Active', field: 'IsActive',
                    formatter: this.cellFormatterService.booleanFormatter,
                    exportFormatter: this.cellFormatterService.booleanExportFormatter,
                    maxWidth: 80
                },
                {
                    displayName: 'Technician', field: 'Technician',
                    visible: true,
                    formatter: this.cellFormatterService.userNameFormatter
                },
                { displayName: 'Comment', field: 'Comment' },
                {
                    displayName: 'Note Count',
                    field: 'Note.length',
                    sortable: false,
                    visible: false,
                },
                {
                    displayName: 'Notes',
                    field: 'Note',
                    visible: false,
                    formatter: this.cellFormatterService.noteFormatter,
                    sortable: false
                },
            ]
        };
    }
}

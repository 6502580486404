import { Pipe, PipeTransform } from '@angular/core';

import {
    notEmpty
} from '../util';

interface MaterialPoolMaterial {
    Animal: {
        DateOut: Date | null;
    };
}

/**
 * Returns animals in material pool with null date out.
 */
@Pipe({
    name: 'matingActiveAnimals',
    pure: false
})
export class MatingActiveAnimalsPipe implements PipeTransform {

    transform<T extends MaterialPoolMaterial>(items: T[]): T[] {
        let filtered: T[] = [];

        if (notEmpty(items)) {
            filtered = items.filter((materialPoolMaterial) => {
                return materialPoolMaterial.Animal.DateOut === null;
            });
        }

        return filtered;
    }
}

// methods for testing objects and their properties

const NOT_EMPTY_VALUES: any[] = [0, "0", false];
export function isSearchEmpty(object: any): boolean {
    if (object instanceof Object &&
        !(object instanceof Array) &&
        !(object instanceof Date)
    ) {
        let isEmpty = true;
        for (const key in object) {
            if (object.hasOwnProperty(key)) {
                // recurse properties to find all values
                isEmpty = isEmpty && isSearchEmpty(object[key]);
            }
        }
        return isEmpty;
    }
    return _checkSearchValue(object);
}

function _checkSearchValue(value: any) {
    if (NOT_EMPTY_VALUES.indexOf(value) > -1) {
        return false;
    }
    if (value instanceof Array) {
        return value.length === 0;
    }
    return !value && true;
}

import {
    Component,
    Input,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { IoTService } from './iot.service';

import { 
    BaseFacet,
    BaseFacetService 
} from '../common/facet';

import { DeviceTableOptions } from './device-table-options';

import {
    CellFormatterService,
    TableState,
    DataResponse,
    ColumnsState,
    TableColumnDef,
} from '@common/datatable';
import { CopyBufferService } from '../common/services/copy-buffer.service';
import { WorkspaceFilterService } from '../services/workspace-filter.service';
import { DataContextService } from '@services/data-context.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { arrowClockwise } from '@icons';

@Component({
    selector: 'device-management-facet',
    templateUrl: './device-management-facet.component.html',
    providers: BaseFacet.BASE_COMPONENT_PROVIDERS
})
export class DeviceManagementFacetComponent extends BaseFacet implements OnInit, OnDestroy {
    @Input() facetId: string;
    @Input() facet: any;

    readonly icons = { arrowClockwise };

    componentName = 'device-management';

    deviceTableOptions: DeviceTableOptions;

    readonly COMPONENT_LOG_TAG = 'device-management-facet';

    private notifier$ = new Subject<void>();

    dataTableColumns: BehaviorSubject<TableColumnDef[]>;
    dataTableColumns$: Observable<TableColumnDef[]>;

    constructor(
        private baseFacetService: BaseFacetService,
        private iotService: IoTService,
        private cellFormatterService: CellFormatterService,
        private copyBufferService: CopyBufferService,
        private modalService: NgbModal,
        workspaceFilterService: WorkspaceFilterService,
        private dataContext: DataContextService,
    ) {
        super(
            baseFacetService,
            workspaceFilterService
        );

        this.deviceTableOptions = new DeviceTableOptions(this.cellFormatterService);

        this.dataTableColumns = new BehaviorSubject(this.deviceTableOptions.options.columns);
        this.dataTableColumns$ = this.dataTableColumns.asObservable();

        this.dataService = {
            run: (tableState: TableState) => {
                return this.loadDevicesList(tableState);
            }
        };
    }

    // lifecycle
    ngOnInit() {
        super.ngOnInit();
        this.initialize();

        this.dataContext.onCancel$.pipe(takeUntil(this.notifier$)).subscribe(() => {
            this.changeView(this.LIST_VIEW);
        });
    }

    initialize() {
        this.restoreFilterState();
        this.changeView(this.LIST_VIEW);
    }

    refreshData() {
        this.initialize();
        this.dataTableComm.reloadTable();
    }

    restoreFilterState() {

        // process any grid filters
        if (this.facet && this.facet.GridFilter) {
            try {
                this.filter = JSON.parse(this.facet.GridFilter);
            } catch (err) {
                console.error(err);
            }

            if (this.filter) {
                // no filters yet
            } else {
                this.filter = {};
            }
        }
    }

    async loadDevicesList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'C_Device_key DESC';
        const expands = [
            'DeviceModel', 
            'MaterialPool', 
            'LocationPosition'
        ];

        this.setLoadingState(tableState.loadingMessage);

        try {
            const response = await this.iotService.getDevices({
                page,
                size: pageSize,
                sort,
                filter: this.getActiveFilter(),
                expands
            });

            const visibleColumns = this.getVisibleColumns(this.deviceTableOptions.options);
            await this.iotService.ensureVisibleColumnsDataLoaded(response.results, visibleColumns);

            this.stopLoading();

            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        } finally {
            this.stopLoading();
        }
    }

    dragStart() {
        this.iotService.draggedDevices = this.selectedRows;
    }

    dragStop() {
        setTimeout(() => {
            this.iotService.draggedDevices = [];
        }, 500);
    }

    async selectedColumnsChange({ visible }: ColumnsState) {
        try {
            this.facetLoadingState.changeLoadingState(true);
            await this.iotService.ensureVisibleColumnsDataLoaded(this.data, visible);
        } finally {
            this.facetLoadingState.changeLoadingState(false);
        }
    }

    ngOnDestroy(): void {
        this.notifier$.next();
        this.notifier$.complete();
    }
}

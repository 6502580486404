import { DataContextService } from './../services/data-context.service';
import { Injectable } from '@angular/core';

import {
    EntityQuery
} from 'breeze-client';

import { AdminManagerService } from '../services/admin-manager.service';
import { BaseEntityService } from '../services/base-entity.service';
import { CurrentWorkgroupService } from '../services/current-workgroup.service';
import { DataManagerService } from './../services/data-manager.service';
import { LocalStorageService } from '../services/local-storage.service';
import { WebApiService } from '../services/web-api.service';

import {
    CvValue,
    SDCharacteristic,
    SDEnumeration,
    SDLine,
    SDNameFormat,
    SeedValues
} from './models';

/**
 * Service for seed-data wizard
 */
@Injectable()
export class WizardService extends BaseEntityService {

    readonly UNINITIALIZED_STATE = 'Uninitialized';
    readonly ACTIVE_STATE = 'Active';

    readonly SEED_DATA_URL = 'api/seeddata/';

    initializationComplete = false;

    _initPromise: Promise<any>;

    readonly SEED_VALUES_STORAGE_KEY_ROOT = 'workgroupSeedValues_';

    workgroupStatuses: any[] = [];


    constructor(
        private adminManager: AdminManagerService,
        private dataManager: DataManagerService,
        private dataContext: DataContextService,
        private currentWorkgroupService: CurrentWorkgroupService,
        private localStorageService: LocalStorageService,
        private webApiService: WebApiService
    ) {
        super();
    }

    init(): Promise<any> {
        if (!this._initPromise) {
            this._initPromise = this.dataContext.init().then(() => {
                return this.loadWorkgroupStatuses();
            }).then(() => {
                return this.loadInitializedState();
            });
        }
        return this._initPromise;
    }

    /** 
     * Force service to check initialization states again.  
     * Necessary after switching workgroups
     */
    reinitialize(): Promise<any> {
        this._initPromise = null;
        return this.init();
    }

    loadWorkgroupStatuses(): Promise<void> {
        const query = EntityQuery.from('WorkgroupStatusTypes');
        return this.adminManager.returnQueryResults(query).then((statuses) => {
            this.workgroupStatuses = statuses || [];
        });
    }

    /** 
     * Load whether initialization is already complete
     */
    loadInitializedState(): Promise<void> {

        const uninitializedStatusKey = this.getWorkgroupStatusKey(this.UNINITIALIZED_STATE);

        // Check initialized status of Workgroups record
        return this.getWorkgroupRecord().then((workgroup) => {
            if (!workgroup) {
                const errorMessage = "Failed to load Workgroup record to check initialized state.";
                throw new Error(errorMessage);
            }

            return workgroup.C_WorkgroupStatusType_key !== uninitializedStatusKey;
        }).then((initialized) => {
            this.initializationComplete = initialized;
        });
    }

    isInitialized(): Promise<boolean> {
        return this.init().then(() => {
            return this.initializationComplete;
        });
    }


    completeInitialization(): Promise<void> {
        const activeStatusKey = this.getWorkgroupStatusKey(this.ACTIVE_STATE);

        if (!activeStatusKey) {
            console.warn(
                'Could not complete initialization, because \'' +
                this.ACTIVE_STATE +
                '\' WorkgroupStatusType not found'
            );
            return;
        }

        // set the Workgroups record to active
        return this.getWorkgroupRecord().then((workgroup) => {
            if (!workgroup) {
                const errorMessage =
                    "Failed to load Workgroup record to mark initialization completed.";
                throw new Error(errorMessage);
            }

            workgroup.C_WorkgroupStatusType_key = activeStatusKey;
        }).then(() => {
            return this.dataContext.save();
        }).then(() => {
            this.initializationComplete = true;
        });
    }

    getWorkgroupStatusKey(name: string): number {
        const status = this.workgroupStatuses.find((item) => {
            return item.WorkgroupStatusName === name;
        });
        return status ? status.C_WorkgroupStatusType_key : 0;
    }

    getWorkgroupRecord(): Promise<any> {
        const workgroupKey = this.currentWorkgroupService.getCurrentWorkgroupKey();
        if (!workgroupKey) {
            console.warn("Current Workgroup Key is not set.");
        }
        const query = EntityQuery.from('Workgroups')
            .where('C_Workgroup_key', '==', workgroupKey);

        return this.adminManager.returnSingleQueryResult(query);
    }

    getCVSeedValues(cvName: string): Promise<CvValue[]> {
        const url = this.SEED_DATA_URL + 'cvvalues?cvName=' + cvName;
        return this.webApiService.callApi(url).then((response) => {
            return response.data.results;
        });
    }

    getTaxonCharacteristicSeedValues(): Promise<SDCharacteristic[]> {
        const url = this.SEED_DATA_URL + 'taxoncharacteristics';
        return this.webApiService.callApi(url).then((response) => {
            return response.data.results;
        });
    }

    getLineSeedValues(): Promise<SDLine[]> {
        const url = this.SEED_DATA_URL + 'linevalues';
        return this.webApiService.callApi(url).then((response) => {
            return response.data.results;
        });
    }

    getContainerTypeSeedValue(materialType: string): Promise<CvValue[]> {
        const url = this.SEED_DATA_URL + 'containertypes?materialType=' + materialType;
        return this.webApiService.callApi(url).then((response) => {
            return response.data.results;
        });
    }

    getNameFormatSeedValue(formatName: string): Promise<SDNameFormat> {
        const url = this.SEED_DATA_URL + 'nameformat?formatName=' + formatName;
        return this.webApiService.callApi(url).then((response) => {
            return response.data;
        });
    }


    // SeedValues - localstorage
    getSeedValues(): SeedValues {
        const localStorageKey = this.getSeedValueLocalStorageKey();
        return this.localStorageService.get(localStorageKey);
    }

    saveSeedValues(seedValues: SeedValues) {
        const localStorageKey = this.getSeedValueLocalStorageKey();
        this.localStorageService.set(localStorageKey, seedValues);
    }

    deleteSeedValues() {
        const localStorageKey = this.getSeedValueLocalStorageKey();
        this.localStorageService.remove(localStorageKey);
    }

    private getSeedValueLocalStorageKey(): string {
        const workgroupKey: number = this.currentWorkgroupService.getCurrentWorkgroupKey();
        if (!workgroupKey) {
            throw new Error('Could not find current workgroupKey in localstorage');
        }

        return this.SEED_VALUES_STORAGE_KEY_ROOT + workgroupKey;
    }


    /**
     * Take selected seed values and propagate to the database.
     * 
     *  NOTE: This is a one time action. 
     *    Seeding values only happens once per workgroup.
     */
    propagateSeedValues(seedValues: SeedValues): Promise<void> {
        const newTaxa = this.createSeedTaxa(seedValues.taxa);
        const taxonCharacteristicsPromise = this.createSeedTaxonCharacteristics(
            seedValues.taxonCharacteristics,
            newTaxa
        );

        const newLineTypes = this.createCvValues(
            'cv_LineType',
            'LineType',
            seedValues.lineTypes
        );

        const newLines = this.createLines(
            seedValues.lines,
            newTaxa,
            newLineTypes
        );


        this.createCvValues(
            'cv_AnimalStatus',
            'AnimalStatus',
            seedValues.animalStatuses
        );

        this.createCvValues(
            'cv_MaterialOrigin',
            'MaterialOrigin',
            seedValues.materialOrigins
        );

        this.createCvValues(
            'cv_ExitReason',
            'ExitReason',
            seedValues.exitReasons
        );

        this.createCvValues(
            'cv_Diet',
            'Diet',
            seedValues.diets
        );

        this.createCvValues(
            'cv_Generation',
            'Generation',
            seedValues.generations
        );

        const containerTypesPromise = this.createAnimalContainerTypes(
            seedValues.animalContainerTypes
        );

        const animalNameFormatPromise = this.saveNameFormat(seedValues.animalNameFormat);
        const housingNameFormatPromise = this.saveNameFormat(seedValues.housingNameFormat);

        this.createCvValues(
            'cv_MatingStatus',
            'MatingStatus',
            seedValues.matingStatuses
        );

        this.createCvValues(
            'cv_MatingType',
            'MatingType',
            seedValues.matingTypes
        );

        this.createCvValues(
            'cv_BirthStatus',
            'BirthStatus',
            seedValues.birthStatuses
        );

        this.saveNameFormat(seedValues.matingNameFormat);
        this.saveNameFormat(seedValues.birthNameFormat);

        const newGenotypeAssays = this.createCvValues(
            'cv_GenotypeAssay',
            'GenotypeAssay',
            seedValues.genotypeAssays
        );

        const newGenotypeSymbols = this.createCvValues(
            'cv_GenotypeSymbol',
            'GenotypeSymbol',
            seedValues.genotypeSymbols
        );

        this.createGenotypeAssociations(
            newGenotypeAssays,
            newGenotypeSymbols
        );

        this.createGenotypeLineAssociations(
            newGenotypeAssays,
            newLines
        );

        this.createCvValues(
            'cv_SampleType',
            'SampleType',
            seedValues.sampleTypes
        );

        this.createCvValues(
            'cv_SampleStatus',
            'SampleStatus',
            seedValues.sampleStatuses
        );

        this.createCvValues(
            'cv_PreservationMethod',
            'PreservationMethod',
            seedValues.preservationMethods
        );

        this.createSampleContainerTypes(
            seedValues.sampleContainerTypes
        );

        const sampleNameFormatPromise = this.saveNameFormat(seedValues.sampleNameFormat);

        return Promise.all([
            taxonCharacteristicsPromise,
            containerTypesPromise,
            animalNameFormatPromise,
            housingNameFormatPromise,
            sampleNameFormatPromise
        ]).then(() => {
            // do something with created data
        });
    }

    /**
     * Creates cv_Taxon from seed values 
     * @param taxa 
     */
    createSeedTaxa(taxa: CvValue[]): any[] {
        const newTaxa = this.createCvValues(
            'cv_Taxon',
            'Taxon',
            taxa
        );
        for (let i = 0; i < newTaxa.length; i++) {
            const newTaxon = newTaxa[i];
            const selectedTaxon = taxa[i];
            newTaxon.CommonName = selectedTaxon.commonName;
            newTaxon.DaysToWean = selectedTaxon.daysToWean;
        }
        return newTaxa;
    }

    createAnimalContainerTypes(types: CvValue[]): Promise<any[]> {
        return this.createContainerTypes(types, 'Animal');
    }

    createSampleContainerTypes(types: CvValue[]): Promise<any[]> {
        return this.createContainerTypes(types, 'Sample');
    }

    createContainerTypes(types: CvValue[], materialType: string): Promise<any[]> {
        if (!types || !materialType) {
            return Promise.resolve([]);
        }

        const newContainerTypes = this.createCvValues(
            'cv_ContainerType',
            'ContainerType',
            types
        );

        // find C_MaterialType_key and set it possible
        const query = EntityQuery.from('cv_MaterialTypes')
            .where('MaterialType', '==', materialType);
        const preferLocal = true;
        return this.dataManager.returnSingleQueryResult(
            query, preferLocal
        ).then((cvMaterialType) => {
            if (!cvMaterialType) {
                return;
            }
            const materialTypeKey = cvMaterialType.C_MaterialType_key;

            for (const containerType of newContainerTypes) {
                containerType.C_MaterialType_key = materialTypeKey;
            }
        }).then(() => {
            return newContainerTypes;
        });
    }

    createCvValues(
        cvName: string,
        cvPropName: string,
        values: CvValue[]
    ): any[] {
        if (!values) {
            return [];
        }

        const newValues: any[] = [];
        let sortOrder = 0;
        for (const value of values) {
            sortOrder += 1;
            const initialValues = {
                IsDefault: value.isDefault,
                IsEndState: value.isEndState,
                IsExitStatus: value.isExitStatus,
                IsActive: true,
                SortOrder: sortOrder
            };
            initialValues[cvPropName] = value.cvValue;
            newValues.push(
                this.dataManager.createEntity(cvName, initialValues)
            );
        }
        return newValues;
    }

    /**
     * Creates TaxonCharacteristics
     *   and also EnumerationClass + EnumerationItem
     */
    createSeedTaxonCharacteristics(
        characteristics: SDCharacteristic[],
        newTaxa: any[]
    ): Promise<any[]> {
        if (!characteristics) {
            return Promise.resolve([]);
        }

        const newCharacteristics: any[] = [];
        const createdEnumerations: any = {};

        const promises: Promise<any>[] = [];

        for (const characteristic of characteristics) {
            const newTaxon = newTaxa.find((taxon) => {
                return taxon.Taxon === characteristic.taxon;
            });

            if (!newTaxon) {
                // cannot create characteristic without a taxon
                console.warn('Cannot find taxon to create characteristic: ' + characteristic.taxon);
                continue;
            }

            let newEnumClassKey: any = null;
            const className = characteristic.enumerationClass;

            if (className) {
                if (!createdEnumerations.hasOwnProperty(className)) {
                    createdEnumerations[className] = this.createEnumeration(
                        className, characteristic.enumerations
                    );
                }

                newEnumClassKey = createdEnumerations[className].C_EnumerationClass_key;
            }

            const promise = this.getDataTypeKey(characteristic.dataType).then((dataTypeKey) => {
                const initialValues = {
                    C_Taxon_key: newTaxon.C_Taxon_key,
                    C_EnumerationClass_key: newEnumClassKey,
                    C_DataType_key: dataTypeKey,
                    CharacteristicName: characteristic.name,
                    Description: characteristic.description,
                    IsActive: true
                };
                newCharacteristics.push(
                    this.dataManager.createEntity('TaxonCharacteristic', initialValues)
                );
            });
            promises.push(promise);
        }

        return Promise.all(promises).then(() => {
            return newCharacteristics;
        });
    }

    createEnumeration(className: string, items: SDEnumeration[]): any {
        const classValues = {
            ClassName: className,
            IsActive: true
        };
        const enumerationClass = this.dataManager.createEntity('EnumerationClass', classValues);

        for (const item of items) {
            const initialValues = {
                C_EnumerationClass_key: enumerationClass.C_EnumerationClass_key,
                ItemName: item.value
            };
            this.dataManager.createEntity('EnumerationItem', initialValues);
        }
        return enumerationClass;
    }


    getDataTypeKey(dataTypeName: string): Promise<number> {
        const query = EntityQuery.from('cv_DataTypes');
        const preferLocal = true;
        return this.dataManager.returnQueryResults(query, preferLocal).then((types) => {
            const foundDataType = types.find((type) => {
                return type.DataType === dataTypeName;
            });
            return foundDataType ? foundDataType.C_DataType_key : 0;
        });
    }

    createLines(
        lines: SDLine[],
        newTaxa: any[],
        newLineTypes: any[]
    ): any[] {
        if (!lines) {
            return [];
        }

        const newLines: any[] = [];
        for (const line of lines) {
            const newTaxon = newTaxa.find((taxon) => {
                return taxon.Taxon === line.taxon;
            });

            if (!newTaxon) {
                // cannot create line without a taxon
                console.warn('Cannot find taxon to create line: ' + line.taxon);
                continue;
            }

            const newLineType = newLineTypes.find((lineType) => {
                return lineType.LineType === line.lineType;
            });

            if (!newLineType) {
                // cannot create line without a line type
                console.warn('Cannot find LineType to create line: ' + line.lineType);
                continue;
            }

            const initialValues = {
                IsActive: true,
                C_Taxon_key: newTaxon.C_Taxon_key,
                C_LineType_key: newLineType.C_LineType_key,
                LineName: line.lineName,
                StockNumber: line.stockNumber
            };
            newLines.push(
                this.dataManager.createEntity('Line', initialValues)
            );
        }

        return newLines;
    }

    /**
     * Save and return the given name format
     * @param nameFormat 
     */
    saveNameFormat(seedFormat: SDNameFormat): Promise<any> {
        if (!seedFormat) {
            return Promise.resolve();
        }

        const preferLocal = true;
        const query = EntityQuery.from('NameFormats');
        return this.dataManager.returnQueryResults(query).then((formats) => {
            const dbFormat = formats.find((format) => {
                return format.FormatName === seedFormat.formatName;
            });
            if (!dbFormat) {
                return null;
            }

            dbFormat.Suffix = seedFormat.suffix;
            dbFormat.Prefix = seedFormat.prefix;
            dbFormat.Counter = seedFormat.counter;
            dbFormat.IsActive = seedFormat.isActive;

            return dbFormat;
        });
    }

    /**
     * Assign all symbols to every assay
     *   via GenotypeAssayGenotypeSymbol table
     * @param assays 
     * @param symbols 
     */
    createGenotypeAssociations(assays: any[], symbols: any[]): any[] {
        if (!assays || !symbols) {
            return [];
        }

        const newAssociations: any[] = [];
        for (const assay of assays) {
            for (const symbol of symbols) {
                const initialValues = {
                    C_GenotypeAssay_key: assay.C_GenotypeAssay_key,
                    C_GenotypeSymbol_key: symbol.C_GenotypeSymbol_key
                };

                newAssociations.push(
                    this.dataManager.createEntity('GenotypeAssayGenotypeSymbol', initialValues)
                );
            }
        }

        return newAssociations;
    }

    /**
     * Assign all assays to every line
     *   via LineGenotypeAssay table
     * @param assays 
     * @param lines 
     */
    createGenotypeLineAssociations(assays: any[], lines: any[]): any[] {
        if (!assays || !lines) {
            return [];
        }

        const newAssociations: any[] = [];
        for (const line of lines) {
            for (const assay of assays) {
                const initialValues = {
                    C_Line_key: line.C_Line_key,
                    C_GenotypeAssay_key: assay.C_GenotypeAssay_key
                };

                newAssociations.push(
                    this.dataManager.createEntity('LineGenotypeAssay', initialValues)
                );
            }
        }

        return newAssociations;
    }
}

import { map } from 'rxjs/operators';
import { CopyBufferService } from '../../../common/services/copy-buffer.service';
import {
    Component,
    Input,
    OnChanges,
    OnInit,
    ViewChildren,
} from '@angular/core';

import { AnimalService } from '../../../animals/services/animal.service';
import { BirthVocabService } from '../../birth-vocab.service';
import { BirthLogicService } from '../../birth-logic.service';
import { LineService } from '../../../lines/line.service';
import { LocationService } from '../../../locations/location.service';
import { MaterialService } from '../../../services/material.service';
import { MaterialPoolService } from '../../../services/material-pool.service';
import { NamingService } from '../../../services/naming.service';
import { VocabularyService } from '../../../vocabularies/vocabulary.service';
import { FeatureFlagService } from '../../../services/feature-flags.service';

import { QueryDef } from '../../../services/query-def';
import {
    datesEqual,
    notEmpty,
    randomId,
    uniqueArrayFromPropertyPath
} from '../../../common/util';

import {
    assignMaterialPoolAnimalCounts
} from '../../helpers';
import { ConfirmOptions, ConfirmService } from '../../../common/confirm';
import { BooleanMap } from '../../../workflow/models/workflow-bulk-data';
import { ColumnSelectLabel, ColumnSelect } from '@common/facet';
import { WorkspaceService } from '../../../workspaces/workspace.service';
import { JobService } from '../../../jobs/job.service';
import { LoggingService } from '../../../services/logging.service';
import { BirthService } from '../../birth.service';
import { TranslationService } from '../../../services/translation.service';
import { dateControlValidator } from '@common/util/date-control.validator';
import { NgModel } from '@angular/forms';
import { CharacteristicInputComponent } from 'src/app/characteristics/characteristic-input/characteristic-input.component';
import { Birth, cv_AnimalMatingStatus, cv_AnimalStatus, cv_AnimalUse, cv_BirthStatus, cv_BreedingStatus, cv_Diet, cv_Generation, cv_IACUCProtocol, cv_MaterialOrigin, cv_PhysicalMarkerType, cv_Sex } from '@common/types';
import { BirthExtended } from '../../birth-detail/birth-detail.component';

class BulkDataConfig {
    columns: { [key: string]: BulkDataColumnConfig } = {};
}
class BulkDataColumnConfig {
    visible = true;
}

@Component({
    selector: 'birth-animal-table',
    templateUrl: './birth-animal-table.component.html'
})
export class BirthAnimalTableComponent implements OnChanges, OnInit {
    @ViewChildren('dateControl') dateControls: NgModel[];
    @ViewChildren('characteristicInput') characteristicInputs: CharacteristicInputComponent[];

    @Input() birth: BirthExtended & Birth;
    @Input() readonly: boolean;
    @Input() facet: any;

    // CVs
    animalStatuses: cv_AnimalStatus[] = [];
    generations: cv_Generation[] = [];
    animalUses: cv_AnimalUse[] = [];
    sexes: cv_Sex[] = [];
    iacucProtocols: cv_IACUCProtocol[] = [];
    physicalMarkerTypes: cv_PhysicalMarkerType[] = [];
    breedingStatuses: cv_BreedingStatus[] = [];
    diets: cv_Diet[] = [];
    materialOrigins: any[] = [];

    // state
    animalNamingActive = false;
    domIdAddition: string;

    generatorCount = 1;
    generatorExpanded = true;

    weanHousingType: string;

    // Table column visibility flags
    visible: BooleanMap = {};

    // Column Select state
    columnSelect: ColumnSelect = { model: [], labels: [] };

    readonly WEAN_HOUSING_TYPE_NEW = 'new';
    readonly WEAN_HOUSING_TYPE_EXISTING = 'existing';
    readonly COMPONENT_LOG_TAG = 'birth-animal-edit';
    readonly EXTERNAL_ID_COLUMN_TITLE = "External ID";

    loading: boolean;
    isDotmatics: boolean;

    defaultCVs: any = {};

    constructor(
        private animalService: AnimalService,
        private birthLogicService: BirthLogicService,
        private birthVocabService: BirthVocabService,
        private copyBufferService: CopyBufferService,
        private lineService: LineService,
        private locationService: LocationService,
        private materialService: MaterialService,
        private materialPoolService: MaterialPoolService,
        private namingService: NamingService,
        private vocabularyService: VocabularyService,
        private confirmService: ConfirmService,
        private workspaceService: WorkspaceService,
        private jobService: JobService,
        private loggingService: LoggingService,
        private translationService: TranslationService,
        private birthService: BirthService,
        private featureFlagService: FeatureFlagService
    ) {
        //
    }

    async ngOnInit() {
        await this.getCVs();
        await this.getDefaults();
        await this.initialize();
    }

    async ngOnChanges(changes: any) {
        if (this.birth && !changes.birth.firstChange) {
            await this.initialize();
        }
    }

    async initialize() {
        try {
            this.loading = true;
            this.domIdAddition = randomId();
            this.weanHousingType = this.WEAN_HOUSING_TYPE_NEW;
            this.isDotmatics = this.featureFlagService.getIsDotmatics();

            this.initColumnSelect();

            await this.isNamingActive();
            await this.getAnimals();

            this.setBirthDerivedProperties();
        }
        catch(error) {
            console.error(error);
        }
        finally {
            this.loading = false;
        }
    }

    /**
     * Initialize the column selection and visibility flags.
     */
    initColumnSelect() {
        this.columnSelect = { model: [], labels: [] };
        // Assemble the list of all columns that can be selected
        const labels = [
            // Use [] to note columns that can be selected but are not
            // currently visible because the data is in the header.
            new ColumnSelectLabel('Use', 'Use'),
            new ColumnSelectLabel('Owner', 'Owner'),
            new ColumnSelectLabel('Origin', 'Origin'),
            new ColumnSelectLabel('Diet', 'Diet'),
            new ColumnSelectLabel('Job', 'Job'),
            new ColumnSelectLabel('Sex', 'Sex'),
            new ColumnSelectLabel('Genotype', 'Genotypes'),
            new ColumnSelectLabel('Status', 'Status'),
            new ColumnSelectLabel('BreedingStatus', 'Breeding Status'),
            new ColumnSelectLabel('Generation', 'Generation'),
            new ColumnSelectLabel('IACUCProtocol', 'IACUC Protocol'),
            new ColumnSelectLabel('BirthDate', 'Birth Date'),
            new ColumnSelectLabel('PhysicalMarkerType', 'Marker Type'),
            new ColumnSelectLabel('PhysicalMarker', 'Marker'),
            new ColumnSelectLabel('MicrochipIdentifier', 'Microchip ID'),
            new ColumnSelectLabel('ExternalIdentifier', this.EXTERNAL_ID_COLUMN_TITLE),
            new ColumnSelectLabel('Characteristics', 'Characteristics'),
        ];

        this.columnSelect.labels = labels;

        // Default column visibility
        this.visible = {
            Use: true,
            Owner: false,
            Origin: false,
            Diet: false,
            Job: false,
            Sex: true,
            Genotype: true,
            Status: true,
            BreedingStatus: false,
            Generation: true,
            IACUCProtocol: true,
            BirthDate: true,
            PhysicalMarkerType: true,
            PhysicalMarker: true,
            MicrochipIdentifier: true,
            ExternalIdentifier: false,
            Characteristics: true,
        };



        // Get the selected columns from the user configuration
        const config = this.parseBulkDataConfiguration();
        this.columnSelect.model = labels.filter((item) => {
            const columnConfig = config.columns[item.key];

            if (!columnConfig) {
                // No user config yet, so rely on the default visibility
                return this.visible[item.key];
            }

            // Columns are visible unless explicitly hidden
            return (columnConfig.visible !== false);
        }).map((item) => item.key);

        // Update the column visiblility
        this.updateVisible();
    }

    /**
     * Column selections have changed
     */
    columnSelectChanged(current: string[]) {
        // Get the current selections
        this.columnSelect.model = current;

        // Update the column visibilty
        this.updateVisible();

        // Save the selection to the database
        this.saveBulkDataConfig();
    }

    /**
     * Update the column visibility flags.
     */
    updateVisible() {
        // Make a lookup table
        const selected = {};
        this.columnSelect.model.forEach((key) => {
            selected[key] = true;
        });

        // Update the visibilty based on the column selections
        this.columnSelect.labels.forEach((column) => {
            const key = column.key;
            this.visible[key] = (selected[key] === true);
        });
    }

    /**
     * Parse the BulkDataConfiguration JSON string, or provide a blank config object
     */
    parseBulkDataConfiguration(): BulkDataConfig {
        try {
            if (this.facet.BulkDataConfiguration) {
                return JSON.parse(this.facet.BulkDataConfiguration);
            }
        } catch (e) {
            console.error('Could not parse TaskGridConfiguration', e);
        }

        return new BulkDataConfig();
    }

    /**
     * Save the column selections for this facet.
     */
    saveBulkDataConfig() {
        // Start from scratch
        const config = new BulkDataConfig();

        // Make a lookup table
        const selected: BooleanMap = {};
        this.columnSelect.model.forEach((key) => {
            selected[key] = true;
        });

        // Update each available column
        this.columnSelect.labels.forEach((column) => {
            const key = column.key;

            const columnConfig = new BulkDataColumnConfig();

            // Columns are visible unless explicitly hidden
            columnConfig.visible = selected[key] ? true : false;

            config.columns[key] = columnConfig;
        });

        // Rebuild the BulkDataConfiguration JSON
        this.facet.BulkDataConfiguration = JSON.stringify(config);

        // Save just the BulkDataConfiguration value in the facet
        this.workspaceService.saveBulkDataConfiguration(this.facet);
    }

    private getCVs(): Promise<any> {

        const p1: Promise<any> = this.birthVocabService.animalStatuses$.pipe(map((data) => {
            this.animalStatuses = data;
        })).toPromise();

        const p2: Promise<any> = this.birthVocabService.generations$.pipe(map((data) => {
            this.generations = data;
        })).toPromise();

        const p3: Promise<any> = this.birthVocabService.animalUses$.pipe(map((data) => {
            this.animalUses = data;
        })).toPromise();

        const p4: Promise<any> = this.birthVocabService.sexes$.pipe(map((data) => {
            this.sexes = data;
        })).toPromise();

        const p5: Promise<any> = this.birthVocabService.iacucProtocols$.pipe(map((data) => {
            this.iacucProtocols = data;
        })).toPromise();

        const p6: Promise<any> = this.birthVocabService.physicalMarkerTypes$.pipe(map((data) => {
            this.physicalMarkerTypes = data;
        })).toPromise();

        const p7: Promise<any> = this.birthVocabService.breedingStatuses$.pipe(map((data) => {
            this.breedingStatuses = data;
        })).toPromise();

        const p8: Promise<any> = this.birthVocabService.materialOrigins$.pipe(map((data) => {
            this.materialOrigins = data;
        })).toPromise();

        const p9: Promise<any> = this.birthVocabService.diets$.pipe(map((data) => {
            this.diets = data;
        })).toPromise();

        return Promise.all([p1, p2, p3, p4, p5, p6, p7, p8, p9]);
    }

    getDefaults() {
        return Promise.all([
            this.vocabularyService.getCVDefault('cv_AnimalMatingStatuses').then((value: cv_AnimalMatingStatus) => {
                if (value) {
                    this.defaultCVs.animalMatingStatusKey = value.C_AnimalMatingStatus_key;
                }
            }),
            this.vocabularyService.getCVDefault('cv_AnimalStatuses').then((value: cv_AnimalStatus) => {
                if (value) {
                    this.defaultCVs.animalStatusKey = value.C_AnimalStatus_key;
                }
            }),
            this.vocabularyService.getCVDefault('cv_BreedingStatuses').then((value: cv_BreedingStatus) => {
                if (value) {
                    this.defaultCVs.breedingStatusKey = value.C_BreedingStatus_key;
                }
            }),
            this.vocabularyService.getCVDefault('cv_AnimalUses').then((value: cv_AnimalUse) => {
                if (value) {
                    this.defaultCVs.animalUseKey = value.C_AnimalUse_key;
                }
            }),
            this.vocabularyService.getCVDefault('cv_BirthStatuses').then((value: cv_BirthStatus) => {
                if (value) {
                    this.defaultCVs.birthStatusKey = value.C_BirthStatus_key;
                }
            }),
            this.vocabularyService.getCVDefault('cv_IACUCProtocols').then((value: cv_IACUCProtocol) => {
                if (value) {
                    this.defaultCVs.iacucProtocolKey = value.C_IACUCProtocol_key;
                }
            }),
            this.vocabularyService.getCVDefault('cv_MaterialOrigins').then((value: cv_MaterialOrigin) => {
                if (value) {
                    this.defaultCVs.materialOriginKey = value.C_MaterialOrigin_key;
                }
            }),
            this.vocabularyService.getCVDefault('cv_PhysicalMarkerTypes').then((value: cv_PhysicalMarkerType) => {
                if (value) {
                    this.defaultCVs.physicalMarkerTypeKey = value.C_PhysicalMarkerType_key;
                }
            }),
            this.vocabularyService.getCVDefault('cv_Diets').then((value: cv_Diet) => {
                if (value) {
                    this.defaultCVs.dietKey = value.C_Diet_key;
                }
            }),
        ]);
    }

    private setBirthDerivedProperties() {
        this.birth.animalMatingStatusKey = this.defaultCVs.animalMatingStatusKey;
        this.birth.animalStatusKey = this.defaultCVs.animalStatusKey;
        this.birth.breedingStatusKey = this.defaultCVs.breedingStatusKey;
        this.birth.animalUseKey = this.defaultCVs.animalUseKey;
        this.birth.iacucProtocolKey = this.defaultCVs.iacucProtocolKey;
        this.birth.materialOriginKey = this.defaultCVs.materialOriginKey;
        this.birth.physicalMarkerTypeKey = this.defaultCVs.physicalMarkerTypeKey;
        this.birth.dietKey = this.defaultCVs.dietKey;
        this.birth.animalDateBorn = this.birth.DateBorn;
    }

    private isNamingActive(): Promise<any> {
        return this.namingService.isAnimalNamingActive().then((active: boolean) => {
            this.animalNamingActive = active;
        });
    }

    private async getAnimals(): Promise<any> {
        try {
            const data = await this.birthService.getAnimals(this.birth.C_Birth_key);
            this.birth.birthAnimals = data || [];
    
            for (const animal of this.birth.birthAnimals) {
                animal.selectedForWean = false;
    
                const poolMaterial = animal.Material.MaterialPoolMaterial[0];
                if (poolMaterial) {
                    poolMaterial.MaterialPool.locationParents = [];
                    if (this.birth.pools.indexOf(poolMaterial.MaterialPool) < 0) {
                        this.birth.pools.push(poolMaterial.MaterialPool);
                        await this.loadMaterialsForPool(poolMaterial.MaterialPool);
                    }
                }
    
                const characteristics = await this.animalService.getTaxonCharacteristics(animal.C_Material_key);
                this.birthLogicService.attachEnumerations(characteristics);
                animal.TaxonCharacteristics = characteristics;
                // For birth-animal-table UI
                animal.characteristicsExpanded = false;
            }
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    /**
     * Loads all animals for a MaterialPool into memory.
     */
    private async loadMaterialsForPool(materialPool: any): Promise<void> {
        const queryDef: QueryDef = {
            page: 1,
            size: 1,
            sort: null,
            filter: {
                C_MaterialPool_key: materialPool.C_MaterialPool_key
            },
            expands: ['MaterialPoolMaterial.Material.Animal']
        };
    
        await this.materialPoolService.getMaterialPools(queryDef);
        assignMaterialPoolAnimalCounts(this.birth.pools);
    }

    // Add Animals
    addAnimals() {
        if (this.generatorCount < 1) {
            return;
        }

        this.lineService.getTaxonKeyByLineKey(this.birth.animalLineKey).then((data) => {

            for (let i = 0; i < this.generatorCount; i++) {
                const initialValues = {
                    C_Taxon_key: data.C_Taxon_key,
                    C_Line_key: this.birth.animalLineKey
                };

                this.materialService.createAsType('Animal', initialValues).then((newMaterial) => {
                    const newAnimal = this.animalService.create();

                    newAnimal.Material = newMaterial;
                    newAnimal.Birth = this.birth;
                    newAnimal.C_Sex_key = this.birth.animalSexKey;
                    newAnimal.C_AnimalStatus_key = this.birth.animalStatusKey;
                    newAnimal.C_AnimalMatingStatus_key = this.birth.animalMatingStatusKey;
                    newAnimal.Comments = this.birth.animalComments;
                    newAnimal.C_PhysicalMarkerType_key = this.birth.physicalMarkerTypeKey;
                    newAnimal.PhysicalMarker = this.birth.animalMarker;
                    newAnimal.Material.C_MaterialOrigin_key = this.birth.materialOriginKey;
                    newAnimal.Material.MicrochipIdentifier = this.birth.animalMicrochipIdentifier;
                    newAnimal.Material.ExternalIdentifier = this.birth.animalExternalIdentifier;
                    newAnimal.C_Generation_key = this.birth.generationKey;
                    newAnimal.C_AnimalUse_key = this.birth.animalUseKey;
                    newAnimal.C_IACUCProtocol_key = this.birth.iacucProtocolKey;
                    newAnimal.DateBorn = this.birth.animalDateBorn;
                    newAnimal.DateOrigin = this.birth.animalDateBorn;
                    newAnimal.selectedForWean = false;
                    newAnimal.C_BreedingStatus_key = this.birth.breedingStatusKey;
                    newAnimal.C_Diet_key = this.birth.dietKey;
                    newAnimal.Owner = this.birth.ownerKey;
                    if (this.birth.JobKey) {
                        this.addJobMaterial(newAnimal, this.birth.JobKey);
                        // load job into breeze cache
                        this.jobService.getJob(this.birth.JobKey);
                    }
                    this.birth.birthAnimals.push(newAnimal);

                    this.createCharacteristics(newAnimal);
                });
            }
        });
    }

    private addJobMaterial(animal: any, jobKey: number) {
        const materialKey = animal.C_Material_key;
        this.jobService.createJobMaterial({
            C_Material_key: materialKey,
            C_Job_key: jobKey,
            Version: 0
        });
    }

    private createCharacteristics(animal: any) {
        this.animalService.createTaxonCharacteristics(animal).then((characteristics: any[]) => {
            if (characteristics) {
                this.birthLogicService.attachEnumerations(characteristics);
                animal.TaxonCharacteristics = characteristics;
            }
        });
    }


    // Model Changes
    selectLine(lineKey: number, animal: any) {
        if (!lineKey || !animal) {
            return;
        }

        return this.getLineByKey(lineKey).then((fullLine) => {
            let taxonOriginal;
            if (animal.Material.cv_Taxon) {
                taxonOriginal = animal.Material.cv_Taxon;
            }

            animal.Material.cv_Taxon = fullLine.cv_Taxon;
            if (taxonOriginal !== animal.Material.cv_Taxon) {
                this.taxonChanged(animal);
            }
            this.updateAnimalName(animal);
        });
    }

    updateAnimalName(animal: any) {
        // Apply new name only if is an update
        if (animal.AnimalName) {
            this.animalService.getAnimalPrefixField().then((animalPrefixField: string) => {
                if (animalPrefixField.toLowerCase() === 'line') {
                    // Automatically regenerate AnimalName
                    this.animalService.autoGenerateAnimalName(animal).then((newName: string) => {
                        if (newName !== animal.AnimalName) {
                            animal.AnimalName = newName;
                            // Alert user of automatic change
                            this.loggingService.logWarning(
                                `The Name field has been automatically changed due to changing the ${this.translationService.translate(animalPrefixField)} field.`,
                                null, this.COMPONENT_LOG_TAG, true);
                        }
                    });
                }
            });
        }
    }

    setDateArrival(animal: any) {
        // we have to make sure Date Arrival (aka DateOrigin) is always equal to DateBorn
        animal.DateOrigin = animal.DateBorn;
    }

    private getLineByKey(lineKey: number): Promise<any> {
        return this.lineService.getLine(lineKey).then((data) => {
            return data;
        });
    }

    private taxonChanged(animal: any) {
        if (animal.TaxonCharacteristicInstance) {
            while (animal.TaxonCharacteristicInstance.length > 0) {
                const characteristicInstance = animal.TaxonCharacteristicInstance[0];
                characteristicInstance.entityAspect.setDeleted();
            }
        }
        this.createCharacteristics(animal);
    }

    removeMaterial(animal: any) {
        this.confirmService.confirmDeleteWithDetails("Delete Animal", "Delete animal record? This action cannot be undone.", ["Note: This action will permanently delete the animal record across the platform."]).then(
            // success
            () => {
                this.animalService.bulkDeleteAnimals([animal]).then((result) => {
                    if (result.data.HasAssociatedData) {
                        const title = 'Animal with Data';
                        const message = 'This animal has associated data and cannot be removed.';
                        const confirmOptions: ConfirmOptions = {
                            title,
                            message,
                            yesButtonText: 'OK',
                            onlyYes: true
                        };
                        return this.confirmService.confirm(confirmOptions);
                    } else {
                        const material = animal.Material;
                        this.animalService.deleteAnimal(animal);
                        this.materialService.deleteMaterial(material);

                        const index = this.birth.birthAnimals.indexOf(animal);
                        this.birth.birthAnimals.splice(index, 1);
                    }
                });
            },
            // cancel
            () => { /* do nothing on cancel */ }
        );
    }


    // Selections
    getAnimalsSelectedForWean() {
        const selected = [];

        if (this.birth && notEmpty(this.birth.birthAnimals)) {
            for (const animal of this.birth.birthAnimals) {
                if (animal.selectedForWean) {
                    selected.push(animal);
                }
            }
        }

        return selected;
    }

    getAllAnimals() {
        return uniqueArrayFromPropertyPath(
            this.birth, 'birthAnimals'
        );
    }


    // Dragging
    dragAnimalsStart() {
        this.animalService.draggedAnimals = this.getAnimalsSelectedForWean();
    }

    dragAnimalsStop() {
        setTimeout(() => {
            this.animalService.draggedAnimals = [];
        }, 500);
    }

    copySelectedAnimals() {
        this.copyBufferService.copy(this.getAnimalsSelectedForWean());
    }

    copyAllAnimals() {
        this.copyBufferService.copy(this.getAllAnimals());
    }

    // Weaning
    isWeaningDisabled() {
        const selectedAnimals = this.getAnimalsSelectedForWean();
        return (selectedAnimals.length === 0);
    }

    async weanSelectedAnimals() {
        if (this.isWeaningDisabled()) {
            return;
        }

        const existingHousingUnitKey = this.birth.existingHousingUnitKey;

        if (this.weanHousingType === this.WEAN_HOUSING_TYPE_NEW) {
            const newMaterialPool = await this.materialPoolService.createMaterialPoolAsType('Wean');
            newMaterialPool.DatePooled = this.birth.DateWean;

            await Promise.all([
                this.locationService.getDefaultLocationForLine(this.birth.Mating.C_Line_key)
                    .then((defaultLocation) => {
                        const initialValues = {
                            C_MaterialPool_key: newMaterialPool.C_MaterialPool_key,
                            C_LocationPosition_key: defaultLocation.C_LocationPosition_key
                        };
                        this.locationService.createMaterialLocation(initialValues);
                    }),

                this.vocabularyService.getCVDefault('cv_MaterialPoolStatuses').then((value) => {
                    newMaterialPool.cv_MaterialPoolStatus = value;
                }),
                this.vocabularyService.getCVContainerTypeDefault('Animal').then((value) => {
                    newMaterialPool.cv_ContainerType = value;
                }),
            ]);

            this.addWeanPool(newMaterialPool);
        } else if (existingHousingUnitKey) {

            // Fetch the MaterialPool object user selected to wean into
            const queryDef: QueryDef = {
                page: 1,
                size: 1,
                sort: null,
                filter: {
                    C_MaterialPool_key: existingHousingUnitKey
                },
                expands: ['MaterialPoolMaterial.Material.Animal']
            };
            this.materialPoolService.getMaterialPools(queryDef).then((response) => {
                const materialPools = response.results;
                if (notEmpty(materialPools)) {
                    const materialPool = materialPools[0];
                    this.addWeanPool(materialPool);
                }
            });

        }
    }

    private addWeanPool(materialPool: any) {
        // Add material pool to birth if it is not already
        if (this.birth.pools.indexOf(materialPool) < 0) {
            this.birth.pools.unshift(materialPool);
        }

        // Loop through selected animals and add MaterialPoolMaterial records
        const selectedAnimals = this.getAnimalsSelectedForWean();
        for (const animal of selectedAnimals) {
            const initialValues = {
                C_MaterialPool_key: materialPool.C_MaterialPool_key,
                C_Material_key: animal.C_Material_key
            };
            this.materialPoolService.createMaterialPoolMaterial(initialValues);

            animal.selectedForWean = false;
        }

        assignMaterialPoolAnimalCounts(this.birth.pools);
    }

    private createWeanPoolLocation(materialPool: any) {
        return this.locationService.getDefaultLocationForLine(this.birth.Mating.C_Line_key)
            .then((defaultLocation) => {
                const initialValues = {
                    C_MaterialPool_key: materialPool.C_MaterialPool_key,
                    C_LocationPosition_key: defaultLocation.C_LocationPosition_key
                };
                this.locationService.createMaterialLocation(initialValues);
            });
    }

    private getDotmaticsErrorMessage(): string {
        if (!this.isDotmatics) {
            return null;
        }

        // User can enable the input field using DevTools and update the value. 
        // Need to check if the value has been modified.
        const modifiedExternalIds = this.birth.birthAnimals.filter(x =>
            x.Material.entityAspect.entityState.name === 'Modified' &&
            x.Material.entityAspect.originalValues.hasOwnProperty("ExternalIdentifier"));

        const addedExternalIds = this.birth.birthAnimals.filter(x =>
            x.Material.entityAspect.entityState.name === 'Added' &&
            x.Material.ExternalIdentifier);

        if (modifiedExternalIds.length > 0 || addedExternalIds.length > 0) {
            return this.EXTERNAL_ID_COLUMN_TITLE + ' cannot be modified.';
        }

        return null;
    }


    // Utils
    datesEqual(date1: any, date2: any): boolean {
        return datesEqual(date1, date2);
    }


    // <select> formatters
    animalStatusKeyFormatter = (value: any) => {
        return value.C_AnimalStatus_key;
    }
    animalStatusFormatter = (value: any) => {
        return value.AnimalStatus;
    }
    generationKeyFormatter = (value: any) => {
        return value.C_Generation_key;
    }
    generationFormatter = (value: any) => {
        return value.Generation;
    }
    animalUseKeyFormatter = (value: any) => {
        return value.C_AnimalUse_key;
    }
    animalUseFormatter = (value: any) => {
        return value.AnimalUse;
    }
    sexKeyFormatter = (value: any) => {
        return value.C_Sex_key;
    }
    sexNameFormatter = (value: any) => {
        return value.Sex;
    }
    iacucProtocolKeyFormatter = (value: any) => {
        return value.C_IACUCProtocol_key;
    }
    iacucProtocolFormatter = (value: any) => {
        return value.IACUCProtocol;
    }
    physicalMarkerTypeKeyFormatter = (value: any) => {
        return value.C_PhysicalMarkerType_key;
    }
    physicalMarkerTypeFormatter = (value: any) => {
        return value.PhysicalMarkerType;
    }
    breedingStatusKeyFormatter = (value: any) => {
        return value.C_BreedingStatus_key;
    }
    breedingStatusFormatter = (value: any) => {
        return value.BreedingStatus;
    }
    materialOriginKeyFormatter = (value: any) => {
        return value.C_MaterialOrigin_key;
    }
    materialOriginFormatter = (value: any) => {
        return value.MaterialOrigin;
    }
    dietKeyFormatter = (value: any) => {
        return value.C_Diet_key;
    }
    dietFormatter = (value: any) => {
        return value.Diet;
    }

    validate(): string | null { 
        const characteristicsInputMessage = this.characteristicInputs.map(input => input.validate()).find(msg => msg);
        const dotmaticsErrorMessage = this.getDotmaticsErrorMessage();

        return characteristicsInputMessage ?? dotmaticsErrorMessage ?? dateControlValidator(this.dateControls);
    }
}

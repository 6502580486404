import { OnInit } from "@angular/core";
import { Component } from "@angular/core";
import { DataContextService } from "../../services/data-context.service";
import { LoggingService } from "../../services/logging.service";
import { ChartService } from "../chart.service";
import { AnimalChartFilter, ChartFilter, ChartFilterGroup, ChartOptionLabelEnum, CohortChartFilter, JobChartFilter, LineChartFilter, SexChartFilter, StudyChartFilter } from "../filters";
import { notEmpty, randomId } from '../../common/util';
import { TaskOutputReportReportFilter } from "../task-output";

const CURRENT_SELECTOR = 'task-output-by-name-chart';
const SHOW_TOAST = true;
@Component({
    selector: 'task-output-by-name-chart',
    templateUrl: './task-output-by-name-chart.component.html',
    styleUrls: ['../charts-facet.component.scss'],
    styles: [`
        .task-output-controls {
            margin-bottom: 1em;
        }
    `],
})
export class TaskOutputByNameChartComponent implements OnInit {
    intervals = [
        'Days',
        'Weeks',
        'Months'
    ];
    labels = this.chartService.TASK_OUTPUT_LABELS;

    domIdAddition: string = randomId();
    // The selected chart interval
    interval = this.intervals[0];
    // The selected chart label
    label$ = this.chartService.taskOutputLabel$;
    // The selected ChartFilterGroups
    filterGroups: ChartFilterGroup[] = [];
    // The selected Output
    taskOutput: any;

    // Lists of values
    filterTypes: typeof ChartFilter[] = [
        AnimalChartFilter,
        CohortChartFilter,
        JobChartFilter,
        LineChartFilter,
        SexChartFilter,
        StudyChartFilter
    ];

    dataSource: any[];
    seriesLabelList: string[] = [];
    traces: any[];

    // State
    chartContainerId: string;
    loading: boolean;
    rendered: boolean;
    dataIncomplete: boolean;
    errorMessage: string;
    warningMessage: string;

    constructor(
        private dataContext: DataContextService,
        private loggingService: LoggingService,
        private chartService: ChartService,
    ) {}

    ngOnInit() {
        this.chartService.selectTaskOutputLabel(ChartOptionLabelEnum.NAME);
        this.resetState();
    }

    resetState(): void {
        this.loading = false;
        this.dataIncomplete = false;
        this.rendered = false;
        this.errorMessage = "";
        this.warningMessage = "";
    }

    /**
     * Sets the selectedOutput model from the nested component's output event.
     *
     * @param output
     */
    onSelectedOutputChange(output: any) {
        this.taskOutput = output;
    }

    isInputValid(): boolean {
        if (this.taskOutput && this.interval && notEmpty(this.filterGroups)) {
            return true;
        } else {
            return false;
        }
    }

    getData(): Promise<void> {
        this.errorMessage = "";
        this.warningMessage = "";

        return this.dataContext.init().then(() => {
            this.traces = [];
            this.seriesLabelList = [];

            // Perform queries sequentially.
            // Copy filterGroups list so we can recurse it like a stack.
            const groups = this.filterGroups.slice();
            return this.getDataInSequence(groups);

        }).catch((error: any) => {
            this.loading = false;
            this.displayLoadError();
            this.loggingService.logError(
                "Failed to load data for chart: " + error.message,
                null,
                CURRENT_SELECTOR,
                SHOW_TOAST
            );
        });
    }

    private getDataInSequence(filterGroups: ChartFilterGroup[]): Promise<void> {
        if (this.taskOutput && notEmpty(filterGroups)) {
            const filterGroup = filterGroups.pop();

            const reportFilter = this.createReportFilter(filterGroup);

            return this.chartService.getTaskOutputValuesByName(reportFilter)
                .then((results: any) => {

                    if (!results.data) {
                        this.displayLoadError();
                        return;
                    }

                    // Traces with no data cause weird displays, so discard those.
                    if (results.data.length === 0) {
                        return;
                    }

                    // combine series
                    this.seriesLabelList.push(filterGroup.label);
                    this.traces.push(results.data);
                }).then(() => {
                    // Move on to the next group
                    return this.getDataInSequence(filterGroups);
                });
        }
        return Promise.resolve();
    }

    combineSeries(seriesList: any[]): any[] {
        const newSeries: any[] = [];
        for (let i = 0; i < seriesList.length; i++) {
            const series = seriesList[i];
            for (const point of series) {
                const newPoint = {
                    TaskAlias: point.TaskAlias
                };
                newPoint[this.seriesLabelList[i] + 'value'] = point.AverageOutputValue;
                newSeries.push(newPoint);
            }
        }
        return newSeries;
    }
    createChart(): void {
        this.resetState();

        if (this.taskOutput && notEmpty(this.filterGroups)) {
            this.loading = true;

            this.getData().then(() => {
                this.loading = false;
                this.drawChart();
            });
        }
    }

    loadingMessage(): string {
        const messageBase = 'Loading';
        const progress: number = 100 * (this.traces.length / this.filterGroups.length);

        if (isFinite(progress)) {
            return messageBase + ' (' + Math.round(Number(progress)) + '%)';
        } else {
            return messageBase;
        }
    }

    displayLoadError(): void {
        this.errorMessage = "Failed to load data for chart. Please try again.";
    }

    createReportFilter(filterGroup: ChartFilterGroup): TaskOutputReportReportFilter {
        const reportFilter = new TaskOutputReportReportFilter(this.interval);

        // Set only one taskOutputKey (as a string)
        reportFilter.taskOutputKeys = this.taskOutput.C_Output_key + '';

        for (const filter of filterGroup.filters) {
            filter.setFilterValues(reportFilter);
        }

        return reportFilter;
    }

    drawChart(): void {
        const traceCount: number = this.traces.length;

        if (traceCount > 0) {
            if (traceCount !== this.filterGroups.length) {
                this.dataIncomplete = true;
            }

            this.rendered = true;

            this.dataSource = this.combineSeries(this.traces);

            this.naturalSortData();
            
        } else {
            this.warningMessage = "No data was found for these groups.";
        }
    }

    /**
     * Sorts the data by the occurence of the first number (i.e Day 1, Day2, Day 10) 
     */
    naturalSortData() {
        const collator = new Intl.Collator('en', { numeric: true, sensitivity: 'base' });
        this.dataSource = this.dataSource.sort((a: any, b: any) => {
            return collator.compare(a.TaskAlias, b.TaskAlias);
        }).filter((data: any) => {
            let hasData = false;
            for (const series of this.seriesLabelList) {
                if (data[series + 'value'] > 0) {
                    hasData = true;
                }
            }
            return hasData;
        });
    }
    customizeTooltip(arg: any) {
        return {
            text: `${+Number.parseFloat(arg.valueText).toFixed(2)}`,
        };
    }

    selectLabel(label: string) {
        this.chartService.selectTaskOutputLabel(label as ChartOptionLabelEnum);
    }
}

import { GroupedItem, groupItems } from './../util/group-items';
import { Injectable } from '@angular/core';
import {
    EntityQuery,
} from 'breeze-client';

import { DataManagerService } from '../../services/data-manager.service';
import { BaseEntityService } from '../../services/base-entity.service';
import { summarizeItems } from '../util';
import { Subject } from 'rxjs';

@Injectable()
export class NoteService extends BaseEntityService {

    readonly ENTITY_TYPE = 'Notes';
    readonly ENTITY_NAME = 'Note';

    private updateCount = new Subject<any>();
    updateCount$ = this.updateCount.asObservable();
    updateNoteCount(
        pkName: string,
        pkValue: number
    ) {
        this.updateCount.next({pkName, pkValue});
    }

    constructor(
        private dataManager: DataManagerService
    ) {
        super();
    }

    getNotes(
        mappedToKeyName: string,
        mappedToKeyValue: number
    ): Promise<any[]> {
        const query = EntityQuery.from(this.ENTITY_TYPE)
            .where(mappedToKeyName, "eq", mappedToKeyValue)
            .orderBy('DateCreated DESC');

        return this.dataManager.returnQueryResults(query, true);
    }

    requestedNoteCounts: any = [];
    readonly BUNDLE_DELAY_MS = 400;
    noteCountPromise: Promise<any> = null;
    getNoteCount(
        mappedToKeyName: string,
        mappedToKeyValue: number
    ): Promise<number> {

        // add values to request bundle
        this.requestedNoteCounts.push({
            mappedToKeyName, 
            mappedToKeyValue
        });

        // bundle multiple requests in quick succession as one (for performance)
        if (!this.noteCountPromise) {
            this.noteCountPromise = new Promise((resolve) => {
            
                const resultsMap: any = {};
                setTimeout(() => {

                    // group requests by keyName
                    const requests: GroupedItem[] = groupItems(
                        this.requestedNoteCounts,
                        (item) => item.mappedToKeyName,
                        (item) => item.mappedToKeyValue
                    );

                    // build each query bundle
                    const promises: Promise<any>[] = [];
                    for (const request of requests) {
                        const query = EntityQuery.from(this.ENTITY_TYPE)
                            .select('C_Note_key, ' + mappedToKeyName)
                            .where(request.key, "in", request.values);
                        
                        resultsMap[request.key] = {};
                        
                        const promise = this.dataManager.executeQuery(
                            query, true
                        ).then((queryResult) => {
                            // summarize the note counts for each key
                            const results: any[] = queryResult.results;
                            for (const result of results) {
                                result.uniqueKey = result[mappedToKeyName];
                            }
                            const summaries = summarizeItems(results);
                            for (const summary of summaries) {
                                const resultKey = summary.item[mappedToKeyName];
                                resultsMap[request.key][resultKey] = summary.count;
                            }
                        });
                        promises.push(promise);
                    }

                    Promise.all(promises).then(() => {
                        // reset globals and return resultsMap
                        this.noteCountPromise = null;
                        this.requestedNoteCounts = [];
                        resolve(resultsMap);
                    });
                }, this.BUNDLE_DELAY_MS);
            });
        }

        return this.noteCountPromise.then((resultsMap) => {
            // return the count for this particular request
            let count = 0;
            if (resultsMap.hasOwnProperty(mappedToKeyName) &&
                resultsMap[mappedToKeyName].hasOwnProperty(mappedToKeyValue)
            ) {
                count = resultsMap[mappedToKeyName][mappedToKeyValue];
            }
            return count;
        });
    }

    createNote(
        mappedToKeyName: string,
        mappedToKeyValue: number,
        noteText: string
    ): any {
        const initialValues: any = ({
            NoteText: noteText
        });
        initialValues[mappedToKeyName] = mappedToKeyValue;

        return this.dataManager.createEntity(this.ENTITY_NAME, initialValues);
    }

    saveNotes(): Promise<any> {
        return this.dataManager.saveEntity(this.ENTITY_NAME);
    }
}

<table class="table">
    <thead>
        <tr>
            <th *ngIf="facetPrivilege === 'ReadWrite'"></th>
            <th class="text-nowrap">Housing ID</th>
            <th>Created Date</th>
            <th>Animals</th>
            <th>Housing Type</th>
            <th>Locations</th>
            <th></th>
        </tr>
    </thead>
    <tbody *ngIf="birth.pools | notEmpty">
        <ng-container *ngFor="let materialPool of birth.pools">
        <tr *ngIf="materialPool"
            droppable
            [dropDataKey]="materialPool.C_MaterialPool_key"
            [dropDisabled]="facetPrivilege !== 'ReadWrite'"
            (drop)="onDropMaterialsToPool($event)"
            [attr.title]="facetPrivilege === 'ReadWrite' ? 'Drop animals here' : ''">
            <td class="icon-cell"
                *ngIf="facetPrivilege === 'ReadWrite'">
                <p class="form-control-plaintext">
                    <a (click)="removeWeanPool(materialPool)">
                        <i class="fa fa-remove remove-item" title="Delete housing unit"></i>
                    </a>
                </p>
            </td>
            <td class="text-nowrap">
                <input type="text"
                       [(ngModel)]="materialPool.MaterialPoolID"
                       [disabled]="!(materialPool | isNew) || ((materialPool | isNew) && housingNamingActive)"
                       [required]="!(materialPool | isNew) || !housingNamingActive"
                       class="form-control input-medium" />
            </td>
            <td>
                <climb-ngb-date #dateControl="ngModel" name="Date Pooled" [(ngModel)]="materialPool.DatePooled"></climb-ngb-date>
            </td>
            <td class="text-nowrap">
                <div *ngIf="materialPool.totalAnimalCount">
                    <em>Total: {{materialPool.totalAnimalCount}}</em>
                </div>
                <div *ngFor="let animal of birth.birthAnimals">
                    <div *ngFor="let materialPoolMaterial of animal?.Material?.MaterialPoolMaterial">
                        <ng-container *ngIf="materialPool.C_MaterialPool_key === materialPoolMaterial.C_MaterialPool_key">
                            <a (click)="removeMaterialFromPool(materialPoolMaterial)"
                               *ngIf="facetPrivilege === 'ReadWrite'">
                                <i class="fa fa-remove remove-item" title="Delete animal"></i>
                            </a>
                            {{animal.AnimalName || '[Unsaved]'}}
                        </ng-container>
                    </div>
                </div>
            </td>
            <td>
                <active-vocab-select [(model)]="materialPool.C_MaterialPoolType_key"
                                     [vocabChoices]="materialPoolTypes"
                                     [keyFormatter]="materialPoolTypeKeyFormatter"
                                     [optionFormatter]="materialPoolTypeFormatter"></active-vocab-select>
            </td>
            <td>
                <location-history #locationHistory
                                  [materialLocations]="materialPool.MaterialLocation"
                                  [facetPrivilege]="facetPrivilege"
                                  (createMaterialLocation)="createWeanPoolLocation(materialPool)"
                                  (removeMaterialLocation)="removeLocation($event)"></location-history>
            </td>
            <td class="text-nowrap">
                <ng-container *ngIf="materialPool.C_MaterialPool_key && !(materialPool | isNew)">
                    <div style="margin-bottom:1em;">
                        <a (click)="requestCageCardWean(materialPool)"
                           title="Print {{WEAN_TYPE}} cage card for {{materialPool.MaterialPoolID}}">
                            <i class="fa fa-print" aria-hidden="true"></i>
                            {{WEAN_TYPE}} Cage Card
                        </a>
                    </div>
                    <div>
                        <a (click)="requestCageCardAnimal(materialPool)"
                           title="Print animal cage cards for {{materialPool.MaterialPoolID}}">
                            <i class="fa fa-print" aria-hidden="true"></i>
                            Animal Cage Cards
                        </a>
                    </div>
                </ng-container>
            </td>
        </tr>
        </ng-container>
    </tbody>
</table>

<div class="bulk-edit-wrapper">
    <div class="bulk-edit-grid">
        <bulk-add #bulkAdd
                  [options]="bulkTemplates.bulkOptions"
                  (save)="saveClicked($event)"
                  (exit)="exitClicked($event)">
        </bulk-add>
        <div class="form-group row mt-3">
            <div class="col-md-12">
                <table class="table">
                    <thead>
                        <tr class="detail-grid-header">
                            <th style="width:35px;">
                                <a class="add-item-link detail-grid-button-label"
                                   (click)="addTask()">
                                    <i data-automation-id="add-new-treatment-plan-button" class="fa fa-plus-circle" title="Add new treatment plan"></i>
                                </a>
                            </th>
                            <th colspan="8">
                                <div class="detail-grid-button-label">Treatment Plan</div>
                            </th>
                        </tr>
                        <tr class="detail-grid-header">
                            <th>&nbsp;</th>
                            <th>Plan</th>
                            <th>Due Date</th>
                            <th>Assigned To</th>
                            <th>Comments</th>
                            <th>&nbsp;</th>
                            <th>Completed Date</th>
                            <th>Completed By</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let task of tasks;let i = index">
                            <td class="icon-cell">
                                <div class="form-control-plaintext">
                                    <a (click)="removeTask(task, i)">
                                        <i class="fa fa-remove remove-item" title="Delete this task"></i>
                                    </a>
                                </div>
                            </td>
                            <td>
                                <textarea [(ngModel)]="plans[i]"></textarea>
                            </td>
                            <td style="width:80px;">
                                <climb-ngb-date #dateControl="ngModel"
                                                name="Due Date"
                                                [(ngModel)]="task.DateDue"
                                                [allowTime]="true"></climb-ngb-date>
                            </td>
                            <td>
                                <active-vocab-select [(model)]="task.C_AssignedTo_key"
                                                     [vocabChoices]="resources"
                                                     [keyFormatter]="resourceKeyFormatter"
                                                     [optionFormatter]="resourceNameFormatter">
                                </active-vocab-select>
                            </td>
                            <td>
                                <textarea [(ngModel)]="task.Comments"
                                          name="taskComments-{{task.C_TaskInstance_key}}"
                                          class="form-control input-large">
                                </textarea>
                            </td>
                            <td class="text-center" style="vertical-align:top;">
                                <task-complete-button [task]="task"></task-complete-button>
                            </td>
                            <td style="width:100px;">
                                <climb-ngb-date #dateControl="ngModel"
                                                name="Date Complete"
                                                [(ngModel)]="task.DateComplete"
                                                [allowTime]="true">
                                </climb-ngb-date>
                            </td>
                            <td>
                                <active-vocab-select [(model)]="task.C_CompletedBy_key"
                                                     [vocabChoices]="resources"
                                                     [keyFormatter]="resourceKeyFormatter"
                                                     [optionFormatter]="resourceNameFormatter">
                                </active-vocab-select>
                            </td>
                            <td>
                                <active-vocab-select [(model)]="task.C_TaskStatus_key"
                                                     [vocabChoices]="taskStatuses"
                                                     [keyFormatter]="taskStatusKeyFormatter"
                                                     [optionFormatter]="taskStatusFormatter">
                                </active-vocab-select>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <br />
    </div>
</div>

<treatment-plan-bulk-templates #bulkTemplates [animals]="sourceMaterials" [tasks]="tasks">
</treatment-plan-bulk-templates>

<ng-template #itemsToAddTmpl>
    <div class="row">
        <div class="col-md-5"
             droppable
             (drop)="onDropSourceMaterials($event)"
             title="Drop source animals here">
            <div class="dropzone">
                <animal-multiselect [(model)]="sourceMaterials"
                                    (modelChange)="onSelectSourceMaterials()">
                    <button extra-buttons
                            (click)="pasteSourceMaterials()"
                            class="btn btn-secondary">
                        <i class="fa fa-paste" title="Paste source animals"></i>
                    </button>
                </animal-multiselect>
            </div>
        </div>
    </div>
</ng-template>

<div class="tree-node-content d-flex align-items-center flex-wrap"
     [ngClass]="{'disabled-control disabled-text': node.type === 'Branch' && node.fields.length == 0}"
     (click)="toggleExpand()">
    <span>{{ node.displayName | translate }}</span>
    <a class="px-2 py-1" *ngIf="node.fields && node.fields.length > 0" data-nodrag>
        <span class="fa"
              [ngClass]="{
                        'fa-chevron-up': treeNodeExpanded,
                        'fa-chevron-down': !treeNodeExpanded
                    }"></span>
    </a>

    <!-- this button doesn't need a click listener because the containing div is listening for those same clicks and responding with an expand -->
    <a class="advanced-search-filter-button px-2 py-1"
       *ngIf="node.type !== 'Branch'"
       [ngClass]="{'active': filterDescription(node) !== 'None'}">
        Filter: {{ filterDescription(node) }}
    </a>

    <!-- we have to stopPropagation on this because the containing div is listening for clicks -->
    <a class="advanced-search-filter-button px-2 py-1"
       (click)="toggleShowInOutput(); $event.stopPropagation()"
       [ngClass]="{'active':  node.showInOutput || (node.type ==='Branch' && getDisplayStatusString(node) !== 'None')}">
        Display: {{ getDisplayStatusString(node) }}
    </a>

    <!-- for all node types (except for branch) we select the filter template based on node type-->
    <div *ngIf="node.type !== 'Branch' && filterExpanded"
         [ngSwitch]="node.type"
         (click)="$event.stopPropagation()"
         class="advanced-filter-controls well well-sm mb-0 flex-grow-1 w-100">
        <text-filter *ngSwitchCase="'Text'" [node]="node"></text-filter>
        <date-filter *ngSwitchCase="'Date'" [node]="node"></date-filter>
        <number-filter *ngSwitchCase="'Number'" [node]="node"></number-filter>
        <enumeration-filter *ngSwitchCase="'Enumeration'" [node]="node"></enumeration-filter>
        <boolean-filter *ngSwitchCase="'Boolean'" [node]="node"></boolean-filter>
        <span *ngSwitchDefault>Unknown Filter Type: {{ node.type }}</span>
    </div>
</div>
<ol *ngIf="showChildNodes" class="tree-node-list">
    <li *ngFor="let field of node.fields" data-collapsed="true" class="tree-node-list-item">
        <search-node [node]="field" [expandedNode]="expandedNode"></search-node>
    </li>
</ol>

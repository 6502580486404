import { NgxBootstrapMultiselectModule } from 'ngx-bootstrap-multiselect';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { ClimbCommonModule } from '../common/climb-common.module';
import { ClimbServicesModule } from '../services/climb-services.module';

import { ResourceService } from './resource.service';
import { ResourceVocabService } from './resource-vocab.service';
import { ClimbAssignedToSelectComponent } from './climb-assigned-to-select.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgxBootstrapMultiselectModule,
        ClimbCommonModule,
        ClimbServicesModule
    ],
    declarations: [
        ClimbAssignedToSelectComponent
    ],
    exports: [
        ClimbAssignedToSelectComponent
    ]
})
export class ResourcesSharedModule {
    static forRoot(): ModuleWithProviders<ResourcesSharedModule> {
        return {
          ngModule: ResourcesSharedModule,
          providers: [
            ResourceService,
            ResourceVocabService
          ]
        };
    }
}

import type { AlertDefinition } from './alert-definition.interface';
import type { Device } from './device.interface';

export interface AlertDevice {
    AlertDefinition: AlertDefinition;
    C_AlertDefinition_key: number;
    C_AlertDevice_key: number;
    C_Device_key: string;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    Device: Device;
    ModifiedBy: string;
    Version: number;
}

import type { Input } from './input.interface';
import type { TaskPlaceholder } from './task-placeholder.interface';

export interface TaskPlaceholderInput {
    C_Input_key: number;
    C_TaskPlaceholderInput_key: number;
    C_TaskPlaceholder_key: number;
    C_Workgroup_key: number;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    Input: Input;
    InputValue: string;
    ModifiedBy: string;
    TaskPlaceholder: TaskPlaceholder;
    Version: number;
}

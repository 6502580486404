import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map } from 'rxjs/operators';

import { LocalStorageService } from "./local-storage.service";
import { CurrentWorkgroupService } from "./current-workgroup.service";

import platform from 'platform';
import { buildId } from '../config/environment';
import { LocalStorageKey } from './../config/local-storage-key.enum';
import { slackErrorWebHook, slackHandledErrorWebHook } from '../config/climb-web-settings';


export interface SlackPayload {
    text?: string;
    blocks: SlackBlock[];
    attachments?: SlackAttachments[];
}

export interface SlackBlock {
    type: 'section';
    text: SlackText;
    block_id?: string;
}

export interface SlackText {
    type: 'mrkdwn' | 'plain_text';
    text: string;
    emoji?: boolean;
    verbatim?: boolean;
}

export interface SlackAttachments {
    title?: string;
    text: string;
    color?: string;
}

@Injectable()
export class SlackService {
    constructor(
        private http: HttpClient,
        private localStorageService: LocalStorageService,
        private currentWorkgroupService: CurrentWorkgroupService
    ) {   

    }
    
    /**
     * Non-critical errors handled by our code.
     * These primarily include validation errors.
     * We want to track them for later analysis.
     * @param {*} message
     * @param {*} source
     */
    postToHandledErrorChannel(message: string, source: string) {
        const payload = this.createHandledErrorPayload(message, source);
        return this.postToChannel(slackHandledErrorWebHook, payload);
    }

    /**
     * Unexpected errors that we need to diagnose
     *
     * @param {*} error
     * @param {*} source
     */
    postToUnhandledErrorChannel(error: Error, source: string) {
        const payload = this.createUnhandledErrorPayload(error, source);
        return this.postToChannel(slackErrorWebHook, payload);
    }

    private postToChannel(webHookUrl: string, payload: SlackPayload): Promise<any> {
        // Headers
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'text/plain;charset=UTF-8');

        // Do post
        return this.http.post(
            webHookUrl, 
            JSON.stringify(payload), 
            { headers, responseType: 'text' }
        )
        .toPromise()
        .then((response) => {
            console.debug("Success posting to Slack: ", response);
        })
        .catch((err) => {
            console.debug("Error posting to Slack: ", err);
        })
    }

    private createHandledErrorPayload(message: string, source: string) {
        const payload = this.createBaseErrorPayload(source);
        payload.blocks[0].text.text += '\n*Message:* ' + message;
        return payload;
    }

    private createUnhandledErrorPayload(error: Error, source: string) {
        const payload = this.createBaseErrorPayload(source);
        payload.blocks[0].text.text += '\n*Message:* ' + error.message + '\n';
        if (error.stack) {
            payload.attachments = [{
                title: "Stack Trace",
                text: this.escapeQuotes('\nStack Trace: ' + error.stack),
                color: "#CB4335"
            }];
        }
        return payload;
    }

    private createBaseErrorPayload(source: string): SlackPayload {
        const payload: SlackPayload = { blocks: [] };

        payload.blocks.push({ 
            type: "section", 
            text: {
                type: "mrkdwn",
                text: '*Version:* ' + buildId
            }
        });

        payload.blocks[0].text.text += '\n*User:* ' + this.getUserName();
        payload.blocks[0].text.text += '\n*Workgroup:* ' + this.getWorkgroupName();
        payload.blocks[0].text.text += '\n*Browser/OS:* ' + platform.description;
        payload.blocks[0].text.text += '\n*User Agent:* ' + navigator.userAgent;

        if (source) {
            payload.blocks[0].text.text += '\n*Source:* ' + source;
        }

        return payload;
    }

    /**
     * Escapes any quotation marks in a string.
     *
     * @param value
     */
    private escapeQuotes(value: string) {
        if (!value) {
            return '';
        }
        return value.replace(/"/g, '\\"');
    }

    private getUserName(): string {
        const authData = this.localStorageService.get(LocalStorageKey.AUTH_DATA);
        if (!authData) {
            return '';
        }
        return authData.userName;
    }

    private getWorkgroupName(): string {
        const workgroupName = this.currentWorkgroupService.getWorkgroupName();
        if (!workgroupName) {
            return '';
        }
        return workgroupName;
    }
}

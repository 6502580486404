import { Subscription } from "rxjs";
import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from "@angular/core";
import { BaseDetail, BaseDetailService, FacetView, IFacet } from "../../../common/facet";
import { cv_DataType, cv_Taxon, TaxonCharacteristic } from "../../../common/types";
import { TaxonCharacteristicTaxon } from "../../../common/types/models/taxon-characteristic-taxon.interface";
import { DataManagerService } from "../../../services/data-manager.service";
import { IValidatable, SaveChangesService } from "../../../services/save-changes.service";
import { CharacteristicVocabService } from "../../characteristic-vocab.service";
import { CharacteristicService } from "../../characteristic.service";
import type { EditResult } from "../../characteristics.interface";

@Component({
    selector: 'taxon-characteristic-edit',
    templateUrl: './taxon-characteristic-edit.component.html'
})
export class TaxonCharacteristicEditComponent extends BaseDetail implements OnInit, OnDestroy, IValidatable {
    @Input() facet: IFacet;
    @Input() facetView: FacetView;
    @Input() facetPrivilege: string;
    @Input() taxonCharacteristic: TaxonCharacteristic;

    @Output() editEnd: EventEmitter<EditResult> = new EventEmitter<EditResult>();

    dataTypes: cv_DataType[];
    taxons: cv_Taxon[] = [];

    selectedTaxons: number[] = [];

    editResult: EditResult = {
        isCanceled: false,
        isSaved: false,
    };

    subscriptions: Subscription = new Subscription();

    constructor(
        baseDetailService: BaseDetailService,
        private _characteristicService: CharacteristicService,
        private _characteristicVocabService: CharacteristicVocabService,
        private _dataManagerService: DataManagerService,
        private _saveChangesService: SaveChangesService
    ) {
        super(baseDetailService);
    }

    ngOnInit(): void {
        this._saveChangesService.registerValidator(this);
        this._characteristicVocabService.getDataTypes().then((results: any[]) => {
            this.dataTypes = results.filter((item: cv_DataType) => item.ShowInCharacteristics && item.DataType !== 'Vocabulary');
        });
        this._characteristicVocabService.getTaxons().then((taxons: cv_Taxon[]) => this.taxons = taxons);

        this.setSelectedTaxons();

        this._characteristicVocabService.getTaxons().then((taxons: cv_Taxon[]) => this.taxons = taxons.filter((cv: cv_Taxon) => cv.IsActive || this.selectedTaxons.includes(cv.C_Taxon_key)));

        const subscription = this._saveChangesService.saveSuccessful$.subscribe(() => {
            this.editResult.isSaved = true;
        });
        this.subscriptions.add(subscription);
    }

    ngOnDestroy(): void {
        this._saveChangesService.unregisterValidator(this);
        this.subscriptions.unsubscribe();
    }

    setSelectedTaxons(): void {
        this.selectedTaxons = this.taxonCharacteristic.TaxonCharacteristicTaxon.map((tct: TaxonCharacteristicTaxon) => tct.cv_Taxon.C_Taxon_key);
        this.taxonCharacteristic.HasTaxon = this.selectedTaxons.length > 0;
    }

    onTaxonChange(taxonKeys: number[]): void {
        const initialTaxonCharacteristicTaxons = this.taxonCharacteristic.TaxonCharacteristicTaxon;
        const initialTaxonKeys = initialTaxonCharacteristicTaxons.map((tct: TaxonCharacteristicTaxon) => tct.cv_Taxon.C_Taxon_key);
        const initialValues: any = [];

        for (const key of taxonKeys) {
            if (!initialTaxonKeys.includes(key)) {
                initialValues.push({
                    C_Taxon_key: key,
                    C_TaxonCharacteristic_key: this.taxonCharacteristic.C_TaxonCharacteristic_key,
                    CharacteristicName: this.taxonCharacteristic.CharacteristicName
                });
            }
        }

        const toDelete = initialTaxonCharacteristicTaxons.filter((tct: TaxonCharacteristicTaxon) => {
            return !taxonKeys.includes(tct.C_Taxon_key);
        });

        for (const e of toDelete) {
            this._dataManagerService.deleteEntity(e);
        }

        const taxonCharacteristicTaxons = [];
        for (const value of initialValues) {
            taxonCharacteristicTaxons.push(this._dataManagerService.createEntity("TaxonCharacteristicTaxon", value));
        }
        this.taxonCharacteristic.HasTaxon = this.selectedTaxons.length > 0;

        const selectedTaxonNames = this.taxonCharacteristic.TaxonCharacteristicTaxon.map((tct: TaxonCharacteristicTaxon) => tct.cv_Taxon.CommonName);
        this.taxonCharacteristic.Species = selectedTaxonNames.join(', ');
    }

    onSaveCharacteristic(): void {
        this._saveChangesService.saveChanges(this.COMPONENT_LOG_TAG, true);
    }

    onCancel(): void {
        this.editResult.isCanceled = true;
        this.editEnd.emit(this.editResult);
    }

    async validate(): Promise<string> {
        if (this.selectedTaxons.length === 0) {
            return "Species is required";
        }
        return this._characteristicService.validateCommonCharacteristic(this.taxonCharacteristic);
    }
}

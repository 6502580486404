import { Directive, Input } from '@angular/core';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { CdkColumnDef } from '@angular/cdk/table';
import { CLIMB_COLUMN_DEF } from '../table.token';
import type { ClimbColumnDef } from '../table.interface';


/**
 * Column definition for the climb-table.
 * Defines a set of cells available for a table column.
 */
@Directive({
    selector: '[climbColumnDef]',
    /* eslint-disable-next-line */
    inputs: ['sticky', 'stickyEnd'],
    providers: [
        { provide: CdkColumnDef, useExisting: ColumnDefDirective },
        { provide: CLIMB_COLUMN_DEF, useExisting: ColumnDefDirective },
    ],
})
export class ColumnDefDirective extends CdkColumnDef implements ClimbColumnDef {
    /**
     * TODO: After switching to Typescript 4.3, you can remove the current property
     * and improve the setters code by using the `BooleanInput` type instead of `boolean`.
     * @see https://www.typescriptlang.org/docs/handbook/release-notes/typescript-4-3.html
     */
    /* eslint-disable @typescript-eslint/naming-convention,no-underscore-dangle,id-blacklist,id-match */
    static ngAcceptInputType_sticky: BooleanInput;
    static ngAcceptInputType_stickyEnd: BooleanInput;
    static ngAcceptInputType_fixed: BooleanInput;
    static ngAcceptInputType_onlyIcon: BooleanInput;
    static ngAcceptInputType_sortable: BooleanInput;
    static ngAcceptInputType_draggable: BooleanInput;
    /* eslint-enable @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match */

    /** Unique name for this column. */
    @Input('climbColumnDef')
    get columnId(): string {
        return this._name;
    }
    set columnId(columnId: string) {
        this._setNameInput(columnId);
    }

    @Input()
    get fixed(): boolean {
        return this.isColumnFixed;
    }
    set fixed(value: boolean) {
        this.isColumnFixed = coerceBooleanProperty(value);
    }
    private isColumnFixed = false;

    @Input()
    get onlyIcon(): boolean {
        return this.isOnlyIcon;
    }
    set onlyIcon(value: boolean) {
        this.isOnlyIcon = coerceBooleanProperty(value);
    }
    private isOnlyIcon = false;

    @Input()
    get sortable(): boolean {
        return this.isSortable;
    }
    set sortable(value: boolean) {
        this.isSortable = coerceBooleanProperty(value);
    }
    private isSortable = false;

    @Input()
    get draggable(): boolean {
        return this.isDraggable;
    }
    set draggable(value: boolean) {
        this.isDraggable = coerceBooleanProperty(value);
    }
    private isDraggable = false;

    /**
     * Add "climb-column-" prefix in addition to "cdk-column-" prefix.
     */
    protected _updateColumnCssClassName(): void {
        super._updateColumnCssClassName();
        this._columnCssClassName!.push(`climb-column-${this.cssClassFriendlyName}`);
    }
}

import type { AnimalHealthRecord } from './animal-health-record.interface';
import type { Genotype } from './genotype.interface';
import type { Job } from './job.interface';
import type { Line } from './line.interface';
import type { Material } from './material.interface';
import type { Study } from './study.interface';
import type { TaskInstance } from './task-instance.interface';

export interface MessageMap {
    AnimalHealthRecord: AnimalHealthRecord;
    C_AnimalHealthRecord_key: number | null;
    C_Genotype_key: number | null;
    C_Job_key: number | null;
    C_Line_key: number | null;
    C_Material_key: number | null;
    C_MessageMap_key: number;
    C_Message_key: number;
    C_Study_key: number | null;
    C_TaskInstance_key: number | null;
    C_Workgroup_key: number;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    Genotype: Genotype;
    Job: Job;
    Line: Line;
    Material: Material;
    ModifiedBy: string;
    Study: Study;
    TaskInstance: TaskInstance;
    Version: number;
}

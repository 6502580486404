<div class="form-control-plaintext"
     *ngIf="facetPrivilege === 'ReadWrite'">
    <a (click)="pasteAnimals()" class="animal-paste-link">
        <i data-automation-id="paste-animals-button"
           class="fa fa-paste" 
           title="Paste Animals">
        </i>
    </a>
</div>

<table class="table table-borderless"
       droppable
       (drop)="onDropMaterials($event)"
       [dropDisabled]="facetPrivilege !== 'ReadWrite'"
       [attr.title]="facetPrivilege === 'ReadWrite' ? 'Drop animals here' : ''">
    <thead>
        <tr>
            <th>
                <i class="fa fa-mars" aria-hidden="true" title="Males"></i>
                Males
            </th>
            <th>
                <i class="fa fa-venus" aria-hidden="true" title="Females"></i>
                Females
            </th>
            <th>
                Others
            </th>
        </tr>
    </thead>

    <tfoot *ngIf="facetPrivilege === 'ReadWrite'">
        <tr>
            <td>
                <climb-indirect-typeahead [search]="getFilteredMales"
                                          [resultFormatter]="animalNameFormatter"
                                          [exactMatchFunction]="isAnimalExactMatch"
                                          [placeholder]="'Add male&hellip;'"
                                          (selectItem)="selectAnimal($event)"></climb-indirect-typeahead>
            </td>
            <td>
                <climb-indirect-typeahead [search]="getFilteredFemales"
                                          [resultFormatter]="animalNameFormatter"
                                          [exactMatchFunction]="isAnimalExactMatch"
                                          [placeholder]="'Add female&hellip;'"
                                          (selectItem)="selectAnimal($event)"></climb-indirect-typeahead>
            </td>
            <td>
                <climb-indirect-typeahead [search]="getFilteredOtherSex"
                                          [resultFormatter]="animalNameFormatter"
                                          [exactMatchFunction]="isAnimalExactMatch"
                                          [placeholder]="'Add animal&hellip;'"
                                          (selectItem)="selectAnimal($event)"></climb-indirect-typeahead>
            </td>
        </tr>
    </tfoot>

    <tbody>
        <tr>
            <td>
                <div *ngFor="let materialPoolMaterial of materialPoolMaterials | housingSexFilter:'Male'">
                    <a (click)="removeMaterial(materialPoolMaterial)"
                       *ngIf="facetPrivilege === 'ReadWrite'">
                        <i class="fa fa-remove remove-item"
                           title="Remove animal from housing unit"></i>
                    </a>
                    {{materialPoolMaterial.Material?.Animal?.AnimalName}}
                </div>
            </td>
            <td>
                <div *ngFor="let materialPoolMaterial of materialPoolMaterials | housingSexFilter:'Female'">
                    <a (click)="removeMaterial(materialPoolMaterial)"
                       *ngIf="facetPrivilege === 'ReadWrite'">
                        <i class="fa fa-remove remove-item"
                           title="Remove animal from housing unit"></i>
                    </a>
                    {{materialPoolMaterial.Material?.Animal?.AnimalName}}
                </div>
            </td>
            <td>
                <div *ngFor="let materialPoolMaterial of materialPoolMaterials | housingOtherSexes">
                    <a (click)="removeMaterial(materialPoolMaterial)"
                       *ngIf="facetPrivilege === 'ReadWrite'">
                        <i class="fa fa-remove remove-item"
                           title="Remove animal from housing unit"></i>
                    </a>
                    {{materialPoolMaterial.Material?.Animal?.AnimalName}}
                </div>
            </td>
        </tr>
    </tbody>
</table>

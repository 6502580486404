import {
    Directive,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output
} from '@angular/core';

import { notEmpty } from './util';

import { DroppableEvent } from './droppable-event';
import { DroppableCommService } from './droppable-comm.service';

/**
 * Directive for jQuery UI Droppable widget
 * https://api.jqueryui.com/droppable/
 */
@Directive({
    selector: '[droppable]',
})
export class DroppableDirective implements OnInit, OnChanges {
    @Input() dropDataKey: number;
    @Input() dropDisabled: boolean;
    @Input() highlightedElement: HTMLElement;
    @Output() drop: EventEmitter<DroppableEvent> = new EventEmitter<DroppableEvent>();

    readonly PARENT_DROPPABLE_DIRECTIVE = "parentDroppable";


    constructor(
        private el: ElementRef,
        private droppableCommService: DroppableCommService
    ) {
        // Nothing to do
    }

    ngOnInit() {
        this.attachDroppable(this.el);
        // Listen for any drop events from parent components
        this.droppableCommService.onDrop$.subscribe((dropEvent) => {

            // overwrite data key from parent
            dropEvent.dataKey = this.dropDataKey;

            this.drop.emit(dropEvent);
        });
    }

    ngOnChanges(changes: any) {
        if (changes.dropDisabled && !changes.dropDisabled.firstChange) {
            this.toggleDroppable(this.el);
        }
    }

    /**
     * Method creates the droppable object on the element the directive is applied.
     * 
     * If a different element is bound to the highlightedElement property of the directive,
     * this element will be highlighted when items are dragged over it. If no element is 
     * bound to this property, the element highlighted will be the element the directive is 
     * applied to.
     */
    attachDroppable(el: ElementRef) {
        const jqEl: any = jQuery(el.nativeElement);
        // eslint-disable-next-line
        const self = this;
        let highlightedElement: any;
        if (!this.highlightedElement){
            highlightedElement = jqEl;
        } else {
            highlightedElement = jQuery(this.highlightedElement);
        }


        jqEl.droppable({
            tolerance: "pointer",
            drop(event: any, ui: any) {
                const dropEvent: DroppableEvent = {
                    event,
                    dataKey: self.dropDataKey
                };
                const dragItem = self.getDragItem(ui);
                if (dragItem) {
                    dropEvent.dragItemKey = dragItem.id;
                    const typeAttr = dragItem.attributes['data-drag-type'];
                    dropEvent.dragType = typeAttr && typeAttr.value;
                }

                self.drop.emit(dropEvent);

                if (self._hasParentDroppableDirective()) {
                    self.droppableCommService.onDrop(dropEvent);
                }
                highlightedElement.removeClass('drop-hover');
            }, 
            over(event: any, ui: any) {
                highlightedElement.addClass('drop-hover');
            },
            out(event: any, ui: any) {
                highlightedElement.removeClass('drop-hover');
            }
        });

        this.toggleDroppable(el);
    }

    /**
     * Enables or disables the Droppable based on the state of dropDisabled.
     * @param el
     */
    private toggleDroppable(el: ElementRef) {
        const jqEl: any = jQuery(el.nativeElement);
        if (this.dropDisabled === true) {
            jqEl.droppable("disable");
        } else {
            jqEl.droppable("enable");
        }
    }

    /**
     * get first dragged item
     * @param ui
     */
    getDragItem(ui: any): any {
        let dragItem: any;
        if (ui && notEmpty(ui.draggable)) {
            dragItem = ui.draggable[0];
        }

        return dragItem;
    }

    _hasParentDroppableDirective(): boolean {
        let hasParentDroppable = false;

        if (this.el && this.el.nativeElement) {
            const nativeEl: HTMLElement = this.el.nativeElement;
            hasParentDroppable = nativeEl.hasAttribute(
                this.PARENT_DROPPABLE_DIRECTIVE
            );
        }
        return hasParentDroppable;
    }
}

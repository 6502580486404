import { Injectable } from '@angular/core';
import {
    EntityQuery,
    FilterQueryOp,
    Predicate,
    QueryResult
} from 'breeze-client';

import {
    notEmpty,
    softCompare
} from '../common/util';
import { getIsActivePredicate } from '../services/queries';
import { DataManagerService } from '../services/data-manager.service';
import { QueryDef } from '../services/query-def';
import { BaseEntityService } from '../services/base-entity.service';
import { VocabularyService } from '../vocabularies/vocabulary.service';

@Injectable()
export class LineService extends BaseEntityService {

    readonly ENTITY_TYPE = 'Lines';
    readonly ENTITY_NAME = 'Line';

    constructor(
        private dataManager: DataManagerService,
        private vocabularyService: VocabularyService,
    ) {
        super();
    }

    getLines(queryDef: QueryDef): Promise<QueryResult> {
        let query = this.buildDefaultQuery(this.ENTITY_TYPE, queryDef);

        this.ensureDefExpanded(queryDef, 'LineAlias');
        this.ensureDefExpanded(queryDef, 'cv_LineType');
        this.ensureDefExpanded(queryDef, 'cv_Taxon');
        this.ensureDefExpanded(queryDef, 'cv_LineStatus');
        this.ensureDefExpanded(queryDef, 'LineGenotypeAssay.cv_GenotypeAssay');
        this.ensureDefExpanded(queryDef, 'Construct');
        this.ensureDefExpanded(queryDef, 'LocationPosition');
        query = query.expand(queryDef.expands.join(','));

        let predicates: Predicate[] = [];
        if (queryDef.filter) {
            predicates = predicates.concat(this.buildPredicates(queryDef.filter));

            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed) as Promise<QueryResult>;
    }

    buildPredicates(filter: any): Predicate[] {
        const predicates: Predicate[] = [];

        if (!filter) {
            return predicates;
        }

        if (filter.LineName) {
            predicates.push(Predicate.create('LineName', FilterQueryOp.Contains, { value: filter.LineName }));
        }
        if (filter.StockNumber) {
            predicates.push(Predicate.create('StockNumber', FilterQueryOp.Contains, { value: filter.StockNumber }));
        }        
        if (notEmpty(filter.stockIDs)) {
            predicates.push(Predicate.create(
                'StockID', 'in', filter.stockIDs
            ));
        }
        if (filter.C_Taxon_key) {
            predicates.push(Predicate.create('C_Taxon_key', 'eq', filter.C_Taxon_key));
        }
        if (filter.C_LineType_key) {
            predicates.push(Predicate.create('C_LineType_key', 'eq', filter.C_LineType_key));
        }
        if (filter.C_LineStatus_key) {
            predicates.push(Predicate.create('C_LineStatus_key', 'eq', filter.C_LineStatus_key));
        }
        if (filter.Technician) {
            predicates.push(Predicate.create('Technician', 'eq', filter.Technician));
        }
        if (filter.IsActive) {
            const isActivePredicate: Predicate = getIsActivePredicate(filter.IsActive);
            predicates.push(isActivePredicate);
        }

        return predicates;
    }

    getAllLines(): Promise<any[]> {
        const query = EntityQuery.from(this.ENTITY_TYPE)
            .orderBy('LineName ASC')
            .select('C_Line_key,LineName,C_Taxon_key');

        return this.dataManager.returnQueryResults(query);
    }

    getLineByName(lineName: string): Promise<any> {
        const query = EntityQuery.from(this.ENTITY_TYPE)
            .expand('cv_Taxon')
            .where('LineName', '==', lineName);

        return this.dataManager.returnSingleQueryResult(query);
    }

    getLine(lineKey: number, extraExpandClauses?: string[]): Promise<any> {
        let expandClauses = ['cv_Taxon'];
        if (extraExpandClauses) {
            expandClauses = expandClauses.concat(extraExpandClauses);
        }

        const query = EntityQuery.from(this.ENTITY_TYPE)
            .expand(expandClauses.join(','))
            .where('C_Line_key', '==', lineKey);

        return this.dataManager.returnSingleQueryResult(query);
    }

    getTaskLines(lineKey: number, extraExpands?: string[]): Promise<any[]> {
        const predicate = new Predicate('TaskLine', 'any', 'C_Line_key', '==', lineKey);

        const query = EntityQuery.from('TaskInstances')
            .where(predicate)
            .orderBy('ProtocolTask.SortOrder');

        let expandClauses = [
            'WorkflowTask',
            'ProtocolTask.Protocol',
            'ProtocolInstance.Protocol',
            'TaskMaterial.Material.Animal',
            'TaskMaterial.Material.Sample',
            'TaskInput.Input',
            'TaskLine'
        ];

        if (notEmpty(extraExpands)) {
            expandClauses = expandClauses.concat(extraExpands);
        }

        let tasks: any[] = [];
        return this.dataManager.returnQueryResults(query).then((results) => {
            tasks = results;
            const promises: Promise<any>[] = [
                this.vocabularyService.ensureCVLoaded('cv_TaskTypes'),
                this.vocabularyService.ensureCVLoaded('cv_AnimalStatuses'),
                this.vocabularyService.ensureCVLoaded('cv_TimeUnits'),
                this.vocabularyService.ensureCVLoaded('cv_TimeRelations'),
                this.vocabularyService.ensureCVLoaded('cv_DataTypes')
            ];

            return Promise.all(promises);
        }).then(() => {
            return this.dataManager.ensureRelationships(tasks, expandClauses);
        }).then(() => {
            return tasks;
        });
    }

    getActiveConstructs(): Promise<any[]> {
        const query = EntityQuery.from('Constructs')
            .where('IsActive', '==', 'true')
            .orderBy('FullName ASC');

        return this.dataManager.returnQueryResults(query);
    }

    getTaxonKeyByLineKey(lineKey: number): Promise<any> {
        const query = EntityQuery.from(this.ENTITY_TYPE)
            .where('C_Line_key', '==', lineKey)
            .select('C_Taxon_key');

        return this.dataManager.returnSingleQueryResult(query);
    }

    getTaxonByLineKey(lineKey: number): Promise<any> {
        const query = EntityQuery.from(this.ENTITY_TYPE)
            .expand("cv_Taxon")
            .where("C_Line_key", "==", lineKey);
        return this.dataManager.executeQuery(query).then((response: any) => {
            return response.results[0]?.cv_Taxon;
        });
    }
    create(): any {
        const initialValues = {
            DateCreated: new Date(),
            IsActive: true
        };
        return this.dataManager.createEntity(this.ENTITY_NAME, initialValues);
    }

    createLineAlias(initialValues: any): any {
        return this.dataManager.createEntity('LineAlias', initialValues);
    }

    deleteLineAlias(lineAlias: any) {
        this.dataManager.deleteEntity(lineAlias);
    }

    createTaskLine(
        lineKey: number,
        taskInstanceKey: number,
        sequence: number
    ): Promise<any> {
        const initialValues = {
            C_Line_key: lineKey,
            C_TaskInstance_key: taskInstanceKey,
            Sequence: sequence
        };

        return this.dataManager.createEntity('TaskLine', initialValues);
    }

    deleteLine(line: any) {
        this._deleteLineNotes(line);

        this.dataManager.deleteEntity(line);
    }

    private _deleteLineNotes(line: any) {
        while (line.Note.length > 0) {
            this.dataManager.deleteEntity(line.Note[0]);
        }
    }

    createLineGenotypeAssay(initialValues: any): any {
        const entityName = 'LineGenotypeAssay';
        const manager = this.dataManager.getManager();

        // Check local entities for duplicates
        const lineGenotypeAssays: any[] = this.getNonDeletedLocalEntities(manager, entityName);
        const duplicates = lineGenotypeAssays.filter((lineGenotypeAssay: any) => {
            return softCompare(lineGenotypeAssay.C_Line_key, initialValues.C_Line_key) &&
                softCompare(lineGenotypeAssay.C_GenotypeAssay_key,
                    initialValues.C_GenotypeAssay_key);
        });

        // Not a duplicate
        if (duplicates.length === 0) {
            return this.dataManager.createEntity(entityName, initialValues);
        }
    }

    deleteLineGenotypeAssay(lineGenotypeAssay: any) {
        this.dataManager.deleteEntity(lineGenotypeAssay);
    }


    cancelLine(line: any) {
        if (!line) {
            return;
        }

        if (line.C_Line_key > 0) {
            this._cancelLineEdits(line);
        } else {
            this._cancelNewLine(line);
        }
    }

    private _cancelNewLine(line: any) {
        try {
            this.deleteLine(line);
            this._cancelLineGenotypeAssayEdits(line);
            this._cancelFileEdits(line);
        } catch (error) {
            console.error('Error cancelling new line: ' + error);
        }
    }

    private _cancelLineEdits(line: any) {
        this.dataManager.rejectEntityAndRelatedPropertyChanges(line);

        this._cancelLineGenotypeAssayEdits(line);
        this._cancelTaskLineEdits(line);
        this._cancelFileEdits(line);
        this._cancelNoteEdits(line);
    }

    private _cancelLineGenotypeAssayEdits(line: any) {
        this.dataManager.rejectChangesToEntityByFilter(
            'LineGenotypeAssay', (item: any) => {
                return item.C_Line_key === line.C_Line_key;
            }
        );
    }

    private _cancelTaskLineEdits(line: any) {
        for (const taskLine of line.TaskLine) {
            this.dataManager.rejectChangesToEntityByFilter(
                'TaskInstance', (item: any) => {
                    return item.C_TaskInstance_key === taskLine.C_TaskInstance_key;
                }
            );

            this.dataManager.rejectChangesToEntityByFilter(
                'TaskMaterial', (item: any) => {
                    return item.C_TaskInstance_key === taskLine.C_TaskInstance_key;
                }
            );

            this.dataManager.rejectChangesToEntityByFilter(
                'TaskInput', (item: any) => {
                    return item.C_TaskInstance_key === taskLine.C_TaskInstance_key;
                }
            );

            const outputSets = this.dataManager.rejectChangesToEntityByFilter(
                'TaskOutputSet', (item: any) => {
                    return item.C_TaskInstance_key === taskLine.C_TaskInstance_key;
                }
            );

            for (const outputSet of outputSets) {
                this.dataManager.rejectChangesToEntityByFilter(
                    'TaskOutputSetMaterial', (item: any) => {
                        return item.C_TaskOutputSet_key === outputSet.C_TaskOutputSet_key;
                    }
                );

                this.dataManager.rejectChangesToEntityByFilter(
                    'TaskOutput', (item: any) => {
                        return item.C_TaskOutputSet_key === outputSet.C_TaskOutputSet_key;
                    }
                );
            }
        }

        // do TaskLines *after* all their children
        this.dataManager.rejectChangesToEntityByFilter(
            'TaskLine', (item: any) => {
                return item.C_Line_key === line.C_Line_key;
            }
        );
    }

    private _cancelFileEdits(line: any) {
        const fileMaps = this.dataManager.rejectChangesToEntityByFilter(
            'StoredFileMap', (item: any) => {
                return item.C_Line_key === line.C_Line_key;
            }
        );
        for (const fileMap of fileMaps) {
            this.dataManager.rejectChangesToEntityByFilter(
                'StoredFile', (item: any) => {
                    return item.C_StoredFile_key === fileMap.C_StoredFile_key;
                }
            );
        }
    }

    private _cancelNoteEdits(line: any) {
        this.dataManager.rejectChangesToEntityByFilter(
            'Note', (item: any) => {
                return item.C_Line_key === line.C_Line_key;
            }
        );
    }

    async ensureVisibleColumnsDataLoaded(lines: any[], visibleColumns: string[]): Promise<void> {
        const expands = this.generateExpandsFromVisibleColumns(lines[0], visibleColumns);
        return this.dataManager.ensureRelationships(lines, expands);
    }
}

import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';

import { MaterialPoolService } from '../services/material-pool.service';
import { ReportingService } from './reporting.service';
import { TranslationService } from '../services/translation.service';
import { CageCardChooserReportType } from './cage-card-chooser.interface';

@Component({
    selector: 'cage-card-chooser',
    templateUrl: './cage-card-chooser.component.html',
    styles: [`
        /* even out vertical spacing of alert box */
        .alert {
            margin-top: 11px;
            margin-bottom: 11px;
        }
    `]
})
export class CageCardChooserComponent implements OnInit {
    @Input() animals: any[];
    @Input() reportTypes: CageCardChooserReportType[];
    @Output() close: EventEmitter<any> = new EventEmitter<any>();

    JOB_TYPE: string;
    WEAN_TYPE: string;
    reportType: string;

    constructor(
        private materialPoolService: MaterialPoolService,
        private reportingService: ReportingService,
        translationService: TranslationService
    ) {
        this.JOB_TYPE = translationService.translate('Job');
        this.WEAN_TYPE = translationService.translate('Wean');
    }

    ngOnInit() {

        if (!this.animals) {
            this.animals = [];
        }

        this.reportType = 'Mating';
        
        if (!this.reportTypes) {
            // set default values
            this.reportType = 'Animal';
            this.reportTypes = [
                { key: 'Animal', display: 'Animal' },
                { key: 'Mating', display: 'Mating' },
                { key: 'Wean', display: this.WEAN_TYPE },
                { key: 'Job', display: this.JOB_TYPE },
                { key: 'ImplantPassage', display: 'Implant & Passage'},
            ];    
        }
    }

    cancelClicked() {
        this.close.emit();
    }

    printClicked() {
        let printPromise = Promise.resolve();

        switch (this.reportType) {
            case 'Animal':
                printPromise = this.printAnimalCageCard();
                break;
            case 'Mating':
                printPromise = this.printMatingCageCard();
                break;
            case 'Wean':
                printPromise = this.printWeanCageCard();
                break;
            case 'Job':
                printPromise = this.printJobCageCard();
                break;
            case 'ImplantPassage':
                printPromise = this.printImplantPassageDisplayCageCard();
                break;
            default: break;
        }

        printPromise.then(() => {
            this.close.emit();
        });
    }   

    printAnimalCageCard(): Promise<void> {
        const animalKeys = this.animals.map((animal) => {
            return animal.C_Material_key;
        });
        this.reportingService.requestCageCardAnimal(animalKeys);

        return Promise.resolve();
    }

    printMatingCageCard(): Promise<void> {
        return this.getMatingUnitsForAnimals().then((matingUnits: any[]) => {
            const matingKeys = matingUnits.map((matingUnit: any) => {
                return matingUnit.C_MaterialPool_key;
            });
            this.reportingService.requestCageCardMating(matingKeys);
        });
    }

    printWeanCageCard(): Promise<void> {
        return this.getWeanUnitsForAnimals().then((weanUnits: any[]) => {
            const weanKeys = weanUnits.map((weanUnit: any) => {
                return weanUnit.C_MaterialPool_key;
            });
            this.reportingService.requestCageCardWean(weanKeys);
        });
    }

    printJobCageCard(): Promise<void> {
        const animalKeys = this.animals.map((animal) => {
            return animal.C_Material_key;
        });
        this.reportingService
            .requestCageCardJobByAnimalKeys(animalKeys);

        return Promise.resolve();
    }

    printImplantPassageDisplayCageCard(): Promise<void> {
        const animalKeys = this.animals.map((animal) => {
            return animal.C_Material_key;
        });
        this.reportingService.requestCageCardImplantPassageByAnimalKeys(animalKeys);

        return Promise.resolve();
    }

    /*
    * Mating unit is a MaterialPool (Housing Unit)
    *   of type 'Mating'
    */
    getMatingUnitsForAnimals(): Promise<any[]> {
        return this.getAnimalHousingUnitsByType('Mating');
    }

    /*
    * Wean unit is a MaterialPool (Housing Unit)
    *   of type 'Wean'
    */
    getWeanUnitsForAnimals(): Promise<any[]> {
        return this.getAnimalHousingUnitsByType('Wean');
    }

    getAnimalHousingUnitsByType(housingTypeToFilter: string): Promise<any[]> {

        const animalKeys = this.animals.map((animal: any) => {
            return animal.C_Material_key;
        });
        const filter = {
            'animal-filter': animalKeys,
            'MaterialPoolType': housingTypeToFilter
        };

        return this.materialPoolService.getMaterialPools({
            page: 0,
            size: 10000,
            sort: null,
            filter
        }).then((response) => {
            return response.results;
        });
    }
}

/**
 * Generates an array of integers from a start to an end, inclusive.
 *
 * @param start
 * @param end
 */
export function range(start: number, end: number): number[] {
    if (!start ||
        !end ||
        start > end
    ) {
        return [];
    }

    return Array.from({ length: (end - start + 1) }, (v, k) => k + start);
}

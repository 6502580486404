import {
    Component,
    EventEmitter,
    Input,
    Output
} from '@angular/core';

import { BirthService } from './birth.service';

const MAX_SEARCH_RESULTS = 50;

@Component({
    selector: 'birth-multiselect',
    template: `
<!-- Selected Birth IDs -->
<div *ngIf="model && model.length > 0">
    <table class="table table-sm table-borderless table-nonfluid">
        <tbody>
            <tr *ngFor="let item of model; let i = index;">
                <td class="icon-cell">
                    <a (click)="removeMaterialPool(i)" title="Remove Birth ID">
                        <i class="fa fa-remove remove-item"></i>
                    </a>
                </td>
                <td>
                    {{item.BirthID}}
                </td>
            </tr>
        </tbody>
    </table>
</div>

<!-- Birth ID typeahead -->
<climb-indirect-typeahead
        [search]="searchBirthIds"
        [resultFormatter]="birthIdFormatter"
        placeholder="Birth ID&hellip;"
        (selectItem)="selectBirthId($event)"></climb-indirect-typeahead>
`,
    styles: [`
        .table {
            margin-bottom: 0.25em;
        }
    `]
})
export class BirthMultiselectComponent {
    @Input() model: any[];
    @Output() modelChange: EventEmitter<any> = new EventEmitter<any>();

    constructor(
        private birthService: BirthService
    ) { }

    removeMaterialPool(index: number) {
        if (this.model && index >= 0 && index < this.model.length) {
            this.model.splice(index, 1);
        }
        this.modelChange.emit(this.model);
    }

    searchBirthIds = (term: string): Promise<any> => {
        return this.birthService.getBirths({
            page: 1,
            size: MAX_SEARCH_RESULTS,
            sort: 'BirthID asc',
            filter: {
                BirthID: term
            }
        }).then((results) => {
            // Convert Breeze entity to simple object
            // so it can be serialized by consumers
            return results.results.map((birth: any) => {
                return {
                    BirthID: birth.BirthID,
                    C_Birth_key: birth.C_Birth_key
                };
            });
        });
    }

    birthIdFormatter = (value: any) => {
        return value.BirthID;
    }

    selectBirthId(birth: any) {
        if (this.model) {
            this.model.push(birth);
        } else {
            this.model = [birth];
        }
        this.modelChange.emit(this.model);
    }
}

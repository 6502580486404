import { DataTypeModule } from './../data-type/data-type.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { ClimbCommonModule } from '../common/climb-common.module';
import { ClimbServicesModule } from '../services/climb-services.module';
import { CohortSharedModule } from '../cohort/cohort-shared.module';
import { ConstructsSharedModule } from './../constructs/constructs-shared.module';
import { FacetSharedModule } from './../common/facet/facet-shared.module';
import { ReportingSharedModule } from './reporting-shared.module';
import { StudiesSharedModule } from '../studies/studies-shared.module';
import { TasksSharedModule } from '../tasks/tasks-shared.module';
import { UsersSharedModule } from '../user/users-shared.module';
import { WorkspacesSharedModule } from './../workspaces/workspaces-shared.module';
import { JobsSharedModule } from './../jobs/jobs-shared.module';
import { LinesSharedModule } from './../lines/lines-shared.module';

import {
    BulkExportRunnerComponent,
    ExportRequestListComponent,
    ExportRequesterComponent,
    ReportingDetailComponent,
    AnimalCheckRequesterComponent,
} from '.';
import { ReportingFacetComponent } from './reporting-facet.component';
import { ReportParamInputComponent } from './params/report-param-input.component';
import { ReportRunnerComponent } from './report-runner.component';
import {
    CustomSearchResultsComponent,
    CustomSearchRunnerComponent,
} from './custom-search';
import { VocabulariesSharedModule } from '../vocabularies/vocabularies-shared.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgbModule,
        ClimbCommonModule,
        ClimbServicesModule,
        CohortSharedModule,
        ConstructsSharedModule,
        DataTypeModule,
        FacetSharedModule,
        ReportingSharedModule,
        StudiesSharedModule,
        TasksSharedModule,
        UsersSharedModule,
        WorkspacesSharedModule,
        JobsSharedModule,
        LinesSharedModule,
        VocabulariesSharedModule
    ],
    declarations: [
        CustomSearchResultsComponent,
        CustomSearchRunnerComponent,
        BulkExportRunnerComponent,
        AnimalCheckRequesterComponent,
        ExportRequestListComponent,
        ExportRequesterComponent,
        ReportingDetailComponent,
        ReportingFacetComponent,
        ReportRunnerComponent,
        ReportParamInputComponent,
    ],
    exports: [
        ReportingFacetComponent
    ],
    providers: [
    ]
})
export class ReportingModule { }

import type { Birth } from './birth.interface';
import type { Line } from './line.interface';
import type { MaterialPool } from './material-pool.interface';
import type { MatingCharacteristicInstance } from './mating-characteristic-instance.interface';
import type { MatingPlugDate } from './mating-plug-date.interface';
import type { Note } from './note.interface';
import type { cv_Diet } from './cv-diet.interface';
import type { cv_Generation } from './cv-generation.interface';
import type { cv_MatingPurpose } from './cv-mating-purpose.interface';
import type { cv_MatingRetirementReason } from './cv-mating-retirement-reason.interface';
import type { cv_MatingStatus } from './cv-mating-status.interface';
import type { cv_MatingType } from './cv-mating-type.interface';
import type { cv_WeanDirective } from './cv-wean-directive.interface';

export interface Mating {
    Birth: Birth[];
    C_Diet_key: number | null;
    C_Generation_key: number | null;
    C_Line_key: number | null;
    C_MaterialPool_key: number;
    C_MatingPurpose_key: number | null;
    C_MatingRetirementReason_key: number | null;
    C_MatingStatus_key: number | null;
    C_MatingType_key: number | null;
    C_WeanDirective_key: number | null;
    C_Workgroup_key: number;
    Comments: string;
    CreatedBy: string;
    DamAgeMonthSorteable: string;
    DamAgeWeekSorteable: string;
    DamBirthDateSorteable: string;
    DamGenotypeSortable: string;
    DamNameSorteable: string;
    DateCreated: Date;
    DateMating: Date | null;
    DateModified: Date;
    DateProposedRetirement: Date | null;
    DateProposedWean: Date | null;
    Line: Line;
    MaterialPool: MaterialPool;
    MatingCharacteristicInstance: MatingCharacteristicInstance[];
    MatingID: string;
    MatingPlugDate: MatingPlugDate[];
    ModifiedBy: string;
    Note: Note[];
    OtherRetirementReason: string;
    OtherWeanDirective: string;
    ProposedGenotypeOffspring: boolean;
    ProposedPenID: number | null;
    SireAgeMonthSorteable: string;
    SireAgeWeekSorteable: string;
    SireBirthDateSorteable: string;
    SireGenotypeSortable: string;
    SireNameSorteable: string;
    TimeProposedWean: number | null;
    Version: number;
    cv_Diet: cv_Diet;
    cv_Generation: cv_Generation;
    cv_MatingPurpose: cv_MatingPurpose;
    cv_MatingRetirementReason: cv_MatingRetirementReason;
    cv_MatingStatus: cv_MatingStatus;
    cv_MatingType: cv_MatingType;
    cv_WeanDirective: cv_WeanDirective;
    MatingIDSortable: string;
}

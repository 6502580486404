import { Pipe, PipeTransform } from '@angular/core';

import { getSafeProp } from '../util';

export interface PoolMaterial {
    DateOut: Date | null;
    Material: {
        Animal: {
            cv_Sex: {
                Sex: 'Female' | 'Male' | 'Undetermined' | null
            };
        };
    };
}



/**
 * Returns females from a list of MaterialPoolMaterial.Material.Animals
 *   who are currently in the MaterialPool.
 */
@Pipe({
    name: 'housingSexFilter',
    pure: false
})
export class HousingSexFilterPipe implements PipeTransform {
    private readonly TARGET_PROPERTY = 'Material.Animal.cv_Sex.Sex'; 

    transform<T extends PoolMaterial>(items: T[] = [], sex: 'Female' | 'Male' | 'Undetermined' | null): T[] {
        return items.filter(item => !item.DateOut && getSafeProp(item, this.TARGET_PROPERTY) === sex);
    }
}

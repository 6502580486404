<div class="intro">
    <p>
        Bulk export your animal and test results from a task.
        Generating an export file may take a few minutes.
    </p>
</div>

<div class="report-param-inputs">
    <div class="form-group">
        <input id="extendedAnimalCheckbox-{{domIdAddition}}"
               type="checkbox"
               [(ngModel)]="searchParams.extendedAnimal"
               [ngModelOptions]="{standalone: true}">
        <label for="extendedAnimalCheckbox-{{domIdAddition}}">
            Include Extended Animal Information
            <info-tooltip [text]="'Include supplementary animal information such as: Marker, Microchip ID and Genotype'"></info-tooltip>
        </label>
    </div>

    <div class="form-group">
        <label for="taskSelect-{{domIdAddition}}">Task</label>
        <task-select data-automation-id="task-name-dropdown"
                     id="taskSelect-{{domIdAddition}}"
                     [(model)]="searchParams.workflowTaskKey"
                     [required]="true"
                     [showActiveOnly]="false"></task-select>
    </div>

    <div class="row">
        <div class="col-md-6">
            <div class="form-group">
                <label for="startDateRangeInput-{{domIdAddition}}">
                    Collected Date Range Start
                    <info-tooltip [text]="'The start of the date range for collection of output values.'"></info-tooltip>
                </label>
                <climb-ngb-date #dateControl="ngModel"
                                id="startDateRangeInput-{{domIdAddition}}"
                                [(ngModel)]="searchParams.startDateRange"
                                name="Collected Date Range Start"
                                [placeholder]="'From&hellip;'"></climb-ngb-date>
            </div>

            <div class="form-group">
                <label for="endDateRangeInput-{{domIdAddition}}">
                    Collected Date Range End
                    <info-tooltip [text]="'The end of the date range for collection of output values.'"></info-tooltip>
                </label>
                <climb-ngb-date #dateControl="ngModel"
                                id="endDateRangeInput-{{domIdAddition}}"
                                [(ngModel)]="searchParams.endDateRange"
                                name="Collected Date Range End"
                                [placeholder]="'To&hellip;'"></climb-ngb-date>
            </div>
        </div>

        <div class="col-md-6">
            <div class="form-group">
                <label for="startCompletedDateRangeInput-{{domIdAddition}}">
                    Completed Date Range Start
                    <info-tooltip [text]="'The start date range for the completion of the task instance.'"></info-tooltip>
                </label>
                <climb-ngb-date #dateControl="ngModel"
                                id="startCompletedDateRangeInput-{{domIdAddition}}"
                                [(ngModel)]="searchParams.startCompletedDateRange"
                                name="Completed Date Range Start"
                                [placeholder]="'From&hellip;'"></climb-ngb-date>
            </div>

            <div class="form-group">
                <label for="endCompletedDateRangeInput-{{domIdAddition}}">
                    Completed Date Range End
                    <info-tooltip [text]="'The end date range for the completion of the task instance.'"></info-tooltip>
                </label>
                <climb-ngb-date #dateControl="ngModel"
                                id="endCompletedDateRangeInput-{{domIdAddition}}"
                                [(ngModel)]="searchParams.endCompletedDateRange"
                                name="Completed Date Range End"
                                [placeholder]="'To&hellip;'"></climb-ngb-date>
            </div>
        </div>
    </div>

    <div class="form-group">
        <label for="cohortSelect-{{domIdAddition}}">Cohort</label>
        <cohort-select id="cohortSelect-{{domIdAddition}}"
                       [(model)]="searchParams.cohortKey"></cohort-select>

    </div>

    <div class="form-group">
        <label for="lineSelect-{{domIdAddition}}">{{'Line' | translate}}</label>
        <line-select id="lineSelect-{{domIdAddition}}"
                     [(model)]="searchParams.lineKey"></line-select>

    </div>

    <div class="form-group">
        <label for="studySelect-{{domIdAddition}}">{{'Study' | translate}}</label>
        <study-select id="studySelect-{{domIdAddition}}"
                      [(model)]="searchParams.studyKey"></study-select>
    </div>

    <div class="form-group">
        <label for="jobSelect-{{domIdAddition}}">{{'Job' | translate}}</label>
        <job-select id="jobSelect-{{domIdAddition}}"
                    [(model)]="searchParams.jobKey"></job-select>
    </div>

    <div class="btn-container">
        <button type="button"
                class="btn btn-fw-xwide btn-primary"
                (click)="createExport()"
                [disabled]="!formValid() || submitted">
            <ng-container *ngIf="!submitted; else submittedIndicator">
                Request Export
            </ng-container>
            <ng-template #submittedIndicator>
                <i class="fa fa-spinner fa-spin" title="Requesting&hellip;"></i>
            </ng-template>
        </button>
        <button type="button"
                class="btn btn-fw-xwide btn-secondary"
                (click)="cancelClicked()">
            Cancel
        </button>
    </div>
</div>

import {
    Component
} from '@angular/core';

import {
    NgbActiveModal
} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'data-changed-modal',
    templateUrl: './data-changed-modal.component.html'
})
export class DataChangedModalComponent {
    reason = '';
    readonly MAX_NOTE_LENGTH = 10000;

    constructor(
        private activeModal: NgbActiveModal
    ) { }


    onCancel(): void {
        this.activeModal.close('cancel');
    }

    onApply(): void {
        this.activeModal.close({reason: this.reason});
    }

    applyButtonDisabled(): boolean {
        return (this.reason.trim().length < 1 || this.reason.length > this.MAX_NOTE_LENGTH);
    }
}

<form novalidate>
    <div class="modal-header justify-content-center">
        <h4 class="modal-title">
            Order Audit Report
        </h4>
    </div>

    <div class="modal-body modal-body-tall">

        <loading-overlay *ngIf="loading"></loading-overlay>

        <h2>
            {{order?.OrderID}}
        </h2>

        <div class="audit-report-section">
            <table class="table audit-table">
                <thead>
                    <tr>
                        <th>Action</th>
                        <th>Modified Date</th>
                        <th>Modified By</th>
                        <th>Order ID</th>
                        <th>Job</th>
                        <th>{{ 'Institution' | translate }}</th>
                        <th>Site</th>
                        <th>Invoice Number</th>
                        <th>Terms</th>
                        <th>Courier</th>
                        <th>Sample Condition</th>
                        <th>Sample Count</th>
                        <th>Instructions</th>
                        <th>Received Date</th>
                        <th>Received By</th>
                        <th>Accession ID</th>

                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let auditRow of auditData"
                        [ngClass]="{'current-value' : auditRow.IsCurrent, 'expired-value': !auditRow.IsCurrent}">
                        <td class="text-nowrap">
                            <update-type-cell [updateType]="auditRow.UpdateType"></update-type-cell>
                        </td>
                        <td>{{auditRow.ModifiedDate | formatUtcDateTime}}</td>
                        <td>{{auditRow.ModifiedBy | userFullName}}</td>
                        <td>{{auditRow.OrderID}}</td>
                        <td>{{auditRow.Job}}</td>
                        <td>{{auditRow.Institution}}</td>
                        <td>{{auditRow.Site}}</td>
                        <td>{{auditRow.InvoiceNumber}}</td>
                        <td>{{auditRow.PaymentTerm}}</td>
                        <td>{{auditRow.Courier}}</td>
                        <td>{{auditRow.SampleCondition}}</td>
                        <td>{{auditRow.SampleCount}}</td>
                        <td>{{auditRow.Comments }}</td>
                        <td>{{auditRow.DateReceived }}</td>
                        <td>{{auditRow.ReceivedBy }}</td>
                        <td>{{auditRow.AccessionID}}</td>
                    </tr>
                </tbody>
            </table>
        </div>

    </div><!-- /.modal-body -->

    <div class="modal-footer">
        <button type="button"
                class="btn btn-fw btn-lg btn-secondary"
                (click)="exportAudit()"
                [disabled]="loading">
            <i class="fa fa-download" aria-hidden="true"></i>
            Export
        </button>

        <button type="button"
                class="btn btn-fw btn-lg btn-secondary"
                (click)="onCancel()">
            Close
        </button>
    </div><!-- /.modal-footer -->
</form>

import type { ProtocolTask } from './protocol-task.interface';
import type { Sample } from './sample.interface';
import type { SampleGroupSourceMaterial } from './sample-group-source-material.interface';
import type { TaskInstance } from './task-instance.interface';
import type { cv_ContainerType } from './cv-container-type.interface';
import type { cv_PreservationMethod } from './cv-preservation-method.interface';
import type { cv_SampleAnalysisMethod } from './cv-sample-analysis-method.interface';
import type { cv_SampleProcessingMethod } from './cv-sample-processing-method.interface';
import type { cv_SampleStatus } from './cv-sample-status.interface';
import type { cv_SampleSubtype } from './cv-sample-subtype.interface';
import type { cv_SampleType } from './cv-sample-type.interface';
import type { cv_TimeUnit } from './cv-time-unit.interface';

export interface SampleGroup {
    C_ContainerType_key: number | null;
    C_PreservationMethod_key: number | null;
    C_ProtocolTask_key: number | null;
    C_SampleAnalysisMethod_key: number | null;
    C_SampleGroup_key: number;
    C_SampleProcessingMethod_key: number | null;
    C_SampleStatus_key: number | null;
    C_SampleSubtype_key: number | null;
    C_SampleType_key: number | null;
    C_TaskInstance_key: number | null;
    C_TimeUnit_key: number | null;
    C_Workgroup_key: number;
    CreatedBy: string;
    DateCreated: Date;
    DateExpiration: Date | null;
    DateHarvest: Date | null;
    DateModified: Date;
    ModifiedBy: string;
    NumSamples: number;
    ProtocolTask: ProtocolTask;
    Sample: Sample[];
    SampleGroupName: string;
    SampleGroupSourceMaterial: SampleGroupSourceMaterial[];
    SamplesCreated: boolean;
    SendTo: string;
    SpecialInstructions: string;
    TaskInstance: TaskInstance;
    TimePoint: number | null;
    Version: number;
    cv_ContainerType: cv_ContainerType;
    cv_PreservationMethod: cv_PreservationMethod;
    cv_SampleAnalysisMethod: cv_SampleAnalysisMethod;
    cv_SampleProcessingMethod: cv_SampleProcessingMethod;
    cv_SampleStatus: cv_SampleStatus;
    cv_SampleSubtype: cv_SampleSubtype;
    cv_SampleType: cv_SampleType;
    cv_TimeUnit: cv_TimeUnit;
}

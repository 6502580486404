import { Injectable } from '@angular/core';

import {
    FilterQueryOp,
    Predicate,
    QueryResult
} from 'breeze-client';

import {
    notEmpty,
    softCompare
} from '../common/util';
import { getDateRangePredicates } from '../services/queries';

import { DataManagerService } from '../services/data-manager.service';
import { QueryDef } from '../services/query-def';
import { BaseEntityService } from '../services/base-entity.service';

@Injectable()
export class GenotypeService extends BaseEntityService {

    constructor(
        private dataManager: DataManagerService
    ) {
        super();
    }

    readonly ENTITY_TYPE = 'Genotypes';
    readonly ENTITY_NAME = 'Genotype';

    async getGenotypes(queryDef: QueryDef): Promise<QueryResult> {
        let query = this.buildDefaultQuery(this.ENTITY_TYPE, queryDef);

        this.ensureDefExpanded(queryDef, 'Animal.Material.Line');
        this.ensureDefExpanded(queryDef, 'Plate');
        this.ensureDefExpanded(queryDef, 'cv_GenotypeAssay');
        this.ensureDefExpanded(queryDef, 'cv_GenotypeSymbol');
        query = query.expand(queryDef.expands.join(','));

        let predicates: Predicate[] = [];
        if (queryDef.filter) {
            predicates = predicates.concat(this.buildPredicates(queryDef.filter));
        }

        if (notEmpty(predicates)) {
            query = query.where(Predicate.and(predicates));
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    buildPredicates(filter: any): Predicate[] {
        let predicates: Predicate[] = [];

        if (!filter) {
            return predicates;
        }

        if (notEmpty(filter.genotypeKeys)) {
            predicates.push(Predicate.create('C_Genotype_key', 'in', filter.genotypeKeys));
        }

        if (filter.Identifier && filter.Identifier !== 'null') {
            predicates.push(
                Predicate.create('Animal.Material.Identifier', '==', filter.Identifier)
            );
        }
        if (filter.AnimalName) {
            predicates.push(
                Predicate.create('Animal.AnimalName', FilterQueryOp.Contains, { value: filter.AnimalName })
            );
        }
        if (filter.C_Sex_key) {
            predicates.push(
                Predicate.create('Animal.C_Sex_key', 'eq', filter.C_Sex_key)
            );
        }

        /* Deprecated - Sprint 79 - replaced by filter.lines */
        if (filter.LineName) {
            predicates.push(
                Predicate.create('Animal.Material.Line.LineName', 'eq', filter.LineName)
            );
        }
        if (notEmpty(filter.lines)) {
            const lineKeys = filter.lines.map((line: any) => {
                return line.LineKey;
            });

            predicates.push(Predicate.create(
                'Animal.Material.C_Line_key', 'in', lineKeys
            ));
        }

        if (filter.DateGenotypeStart || filter.DateGenotypeEnd) {
            const datePredicates: Predicate[] = getDateRangePredicates(
                'DateGenotype',
                filter.DateGenotypeStart,
                filter.DateGenotypeEnd
            );
            if (notEmpty(datePredicates)) {
                predicates = predicates.concat(datePredicates);
            }
        }

        if (filter.Plate) {
            predicates.push(Predicate.create('Plate.PlateID', FilterQueryOp.Contains, { value: filter.Plate }));
        }
      
        if (notEmpty(filter.genotypeCombos)) {                                 
            const suboredicate = [];   
            for (const combo of filter.genotypeCombos) {               
                const comboPred = []; 

                if (combo.assay.searchAny || combo.symbol.searchAny) {
                    // any / no symbol
                    if (combo.assay.searchAny && combo.symbol.searchNulls) {
                        comboPred.push(Predicate.create(
                            'C_GenotypeAssay_key', '!=', null
                        ));
                        comboPred.push(Predicate.create(
                            'C_GenotypeSymbol_key', 'eq', null
                        ));
                    } else {
                        // any assay / any symbol
                        comboPred.push(Predicate.create(
                            'C_GenotypeAssay_key', '!=', null
                        ));
                        comboPred.push(Predicate.create(
                            'C_GenotypeSymbol_key', '!=', null
                        ));     
                    }                                   
                }               

                // assay search
                if (combo.assay.key || combo.assay.searchNulls) {
                    let assayKey = combo.assay.key;
                    if (combo.assay.searchNulls) {
                        assayKey = null;
                    }                   
                    comboPred.push(Predicate.create(
                        'C_GenotypeAssay_key', 'eq', assayKey
                    ));
                }

                // genotype / symbol search
                if (combo.symbol.key || combo.symbol.searchNulls) {
                    let symbolKey = combo.symbol.key;
                    if (combo.symbol.searchNulls) {
                        symbolKey = null;
                    }                   
                    comboPred.push(Predicate.create(
                        'C_GenotypeSymbol_key', 'eq', symbolKey
                    ));
                }  
                suboredicate.push(Predicate.and(comboPred));  
            }
            predicates.push(Predicate.or(suboredicate));  
        }

        // handle workspace filters
        if ('animal-filter' in filter) {
            predicates.push(
                Predicate.create('Animal.C_Material_key', 'in', filter['animal-filter'])
            );
        }

        return predicates;
    }

    async ensureSourceMaterialsExpanded(sourceMaterials: any[]): Promise<any> {
        return this.dataManager.ensureRelationships(
            sourceMaterials, ['Material.Line']
        );
    }

    async ensureVisibleColumnsDataLoaded(genotypes: any[], visibleColumns: string[]): Promise<void> {
        const expands = this.generateExpandsFromVisibleColumns(genotypes[0], visibleColumns);
        return this.dataManager.ensureRelationships(genotypes, expands);
    }

    create(initialValues?: any): any {
        if (!initialValues) {
            initialValues = {};
        }
        
        if (!initialValues.DateCreated) {
            initialValues.DateCreated = new Date();
        }
        return this.dataManager.createEntity(this.ENTITY_NAME, initialValues);
    }

    deleteGenotype(genotype: any) {
        this.dataManager.deleteEntity(genotype);
    }

    createGenotypeAssayGenotypeSymbol(initialValues: any): any {
        const manager = this.dataManager.getManager();
        const entityName = 'GenotypeAssayGenotypeSymbol';

        // Check local entities for duplicates
        const initialAssayKey = initialValues.C_GenotypeAssay_key;
        const initialSymbolKey = initialValues.C_GenotypeSymbol_key;
        const genotypeAssayGenotypeSymbols: any[] = manager.getEntities(entityName);
        const duplicates = genotypeAssayGenotypeSymbols.filter((assoc) => {
            return softCompare(assoc.C_GenotypeAssay_key, initialAssayKey) &&
                softCompare(assoc.C_GenotypeSymbol_key, initialSymbolKey);
        });

        if (duplicates.length === 0) {
            return this.dataManager.createEntity(entityName, initialValues);
        }
        return null;
    }

    deleteGenotypeAssayGenotypeSymbol(genotypeAssayGenotypeSymbol: any) {
        this.dataManager.deleteEntity(genotypeAssayGenotypeSymbol);
    }

    cancelGenotype(genotype: any) {
        if (!genotype) {
            return;
        }

        if (genotype.C_Genotype_key > 0) {
            this.cancelGenotypeEdits(genotype);
        } else {
            this.cancelNewGenotype(genotype);
        }
    }

    private cancelNewGenotype(genotype: any) {
        try {
            this.deleteGenotype(genotype);
        } catch (error) {
            console.error('Error cancelling new genotype: ' + error);
        }
    }

    private cancelGenotypeEdits(genotype: any) {
        this.dataManager.rejectEntityAndRelatedPropertyChanges(genotype);
    }
}

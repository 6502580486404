import {
    Component,
    ElementRef,
    ViewChild
} from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'unsaved-changes-modal',
    templateUrl: './unsaved-changes-modal.component.html'
})
export class UnsavedChangesModalComponent {
    constructor(
        private activeModal: NgbActiveModal
    ) {}

    // Auto-focus on save button
    @ViewChild('saveButton') set saveButton(element: ElementRef) {
        if (element) {
            setTimeout(() => {
                element.nativeElement.focus();
            });
        }
    }

    saveClicked(): void {
        this.activeModal.close();
    }

    discardClicked(): void {
        this.activeModal.dismiss();
    }
}

import {
    Pipe,
    PipeTransform
} from '@angular/core';

import { animalAgeDays } from '../util';
import { LuxonInput } from '@common/util/date-time-formatting/types/luxon-input.type';

/**
 * Returns the age of an animal (in days)
 */
@Pipe({ name: 'animalAgeDays' })
export class AnimalAgeDaysPipe implements PipeTransform {

    transform(birthDate: LuxonInput, exitDate?: LuxonInput): string {
        return animalAgeDays(birthDate, exitDate);
    }
}

import { InboxMessage } from "./inbox-message";

/**
 * Grouping of messages by user in the message center inbox
 */
export class InboxUserGroup {
    /* eslint-disable-next-line */
    Id: string;
    /* eslint-disable-next-line */
    FirstName: string;
    /* eslint-disable-next-line */
    LastName: string;
    
    // non-DB fields
    messages: InboxMessage[];
    // pagination variables
    messagePage: number;
    paginationId: string;
}

import type { cv_ClinicalObservationBodySystems } from './cv-clinical-observation-body-systems.interface';

export interface cv_BodySystem {
    BodySystem: string;
    C_BodySystem_key: number;
    C_VocabularySource_key: number | null;
    C_Workgroup_key: number;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    ElementID: string;
    IsActive: boolean;
    IsDefault: boolean;
    IsDeprecated: boolean;
    ModifiedBy: string;
    SortOrder: number | null;
    Version: number;
    cv_ClinicalObservationBodySystems: cv_ClinicalObservationBodySystems[];
}

import { Injectable } from '@angular/core';
import { defer, Observable } from 'rxjs';

import { VocabularyService } from '../vocabularies/vocabulary.service';
import { take } from 'rxjs/operators';

@Injectable()
export class PlateVocabService {

    // vocab Observables
    public get plateStatuses$(): Observable<any[]>{
        return defer(() => this.vocabularyService.getCV('cv_PlateStatuses')).pipe(take(1));
    }
    public get plateTypes$(): Observable<any[]>{
        return defer(() => this.vocabularyService.getCV('cv_PlateTypes')).pipe(take(1));
    }


    constructor(
        private vocabularyService: VocabularyService
    ) {}

}

import type { AlertSubscriptionModality } from './alert-subscription-modality.interface';
import type { AlertTriggeredEvent } from './alert-triggered-event.interface';

export interface AlertTriggeredEventNotification {
    AlertSubscriptionModality: AlertSubscriptionModality;
    AlertTriggeredEvent: AlertTriggeredEvent;
    C_AlertSubscriptionModality_key: number;
    C_AlertTriggeredEventNotification_key: number;
    C_AlertTriggeredEvent_key: number;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    ModifiedBy: string;
    NotificationSent: boolean;
    Version: number;
}

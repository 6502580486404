import { Pipe, PipeTransform } from "@angular/core";
import { TaskInstance } from "@common/types";
import { isAnyTaskEndState } from "../util/is-any-task-end-state";

/**
 * Returns boolean value depending on task end states
 * If at least one task is completed, pipe will return true
 */
@Pipe({ 
    name: 'tasksIsEndState'
})
export class TasksEndState implements PipeTransform {

    transform(taskInstances: TaskInstance[]): boolean {
        return isAnyTaskEndState(taskInstances);
    }
}

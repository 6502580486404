import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    OnDestroy,
    ViewChildren,
} from '@angular/core';

import { StudyVocabService } from './study-vocab.service';

import { BaseFilter } from '../common/facet';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
    focusElementByQuery
} from '../common/util/';
import { Subscription } from 'rxjs';
import { NgModel } from '@angular/forms';
import { LoggingService } from '@services/logging.service';
import { dateControlValidator } from '@common/util/date-control.validator';


@Component({
    selector: 'study-filter',
    templateUrl: './study-filter.component.html'
})
export class StudyFilterComponent extends BaseFilter implements OnInit, OnDestroy {
    @ViewChildren('dateControl') dateControls: NgModel[];
    @Input() filter: any = {};
    @Output() onFilter = new EventEmitter<any>();
    @Output() close = new EventEmitter<any>();

    // CVs
    studyStatuses: any[] = [];
    studyTypes: any[] = [];

    _subs: Subscription[] = [];

    constructor(
        private studyVocabService: StudyVocabService,
        private activeModal: NgbActiveModal,
        private loggingService: LoggingService,
    ) {
        super(activeModal);
    }

    ngOnInit() {
        this.cloneFilter();

        this.getCVs();

        focusElementByQuery('[data-auto-focus="studyName"]');
    }

    private cloneFilter() {
        const dateCreatedStart = this.filter.DateCreatedStart;
        const dateCreatedEnd = this.filter.DateCreatedEnd;

        this.filter = this.copyFilter(this.filter);

        this.filter.DateCreatedStart = dateCreatedStart;
        this.filter.DateCreatedEnd = dateCreatedEnd;
    }

    ngOnDestroy() {
        for (const s of this._subs) {
            s.unsubscribe();
        }
    }

    private getCVs() {
        this.studyVocabService.studyStatuses$
            .subscribe((data) => {
                this.studyStatuses = data;
            });
        this.studyVocabService.studyTypes$
            .subscribe((data) => {
                this.studyTypes = data;
            });
    }

    filterClicked(event: Event) {
        const errMessage = dateControlValidator(this.dateControls);
        if (errMessage) {
            this.loggingService.logError(errMessage, null, '', true);
            return;
        }
        event.preventDefault();
        this.onFilter.emit(this.filter);
        this.close.emit();
        this.activeModal.close();
    }

    closeClicked() {
        this.close.emit();
        this.activeModal.dismiss();
    }

    clearClicked() {
        this.filter = {};
        this.onFilter.emit(this.filter);
        this.close.emit();
        this.activeModal.close();
    }
}

<ng-container *ngIf="animal">
    <ng-template [ngTemplateOutlet]="labelValuePair"
                 [ngTemplateOutletContext]="{
           label: 'Animal Name',
           value: animal.AnimalName }"></ng-template>

    <ng-template [ngTemplateOutlet]="labelValuePair"
                 [ngTemplateOutletContext]="{
            label: 'Animal ID',
            value: animal.Material?.Identifier }"></ng-template>

    <div *ngIf="!useTableFormat"
         class="form-group row">
        <label class="col-md-2 col-form-label">Marker</label>
        <div class="col-md-10">
            <p class="form-control-plaintext">
                <animal-marker [marker]="animal.PhysicalMarker"
                               [markerType]="animal.cv_PhysicalMarkerType"></animal-marker>
            </p>
        </div>
    </div>
    <ng-template *ngIf="useTableFormat"
                 [ngTemplateOutlet]="labelValuePair"
                 [ngTemplateOutletContext]="{
            label: 'Marker',
            value: animal.PhysicalMarker }"></ng-template>

    <ng-template [ngTemplateOutlet]="labelValuePair"
                 [ngTemplateOutletContext]="{
            label: 'Animal Status',
            value: animal.cv_AnimalStatus?.AnimalStatus }"></ng-template>

    <ng-template [ngTemplateOutlet]="labelValuePair"
                 [ngTemplateOutletContext]="{
            label: 'Line' | translate,
            value: animal.Material?.Line?.LineName }"></ng-template>

    <ng-template [ngTemplateOutlet]="labelValuePair"
                 [ngTemplateOutletContext]="{
            label: 'Sex',
            value: animal.cv_Sex?.Sex }"></ng-template>

    <ng-template [ngTemplateOutlet]="labelValuePair"
                 [ngTemplateOutletContext]="{
            label: 'Birth Date',
            value: animal.DateBorn | formatShortDate }"></ng-template>

    <ng-template [ngTemplateOutlet]="labelValuePair"
                 [ngTemplateOutletContext]="{
            label: 'Location',
            value: animal.Material?.CurrentLocationPath }"></ng-template>
</ng-container>

<ng-template #labelValuePair let-label="label" let-value="value">
    <div class="form-group row" *ngIf="!useTableFormat">
        <label class="col-md-2 col-form-label">{{label}}</label>
        <div class="col-md-10">
            <p class="form-control-plaintext">
                {{value}}
            </p>
        </div>
    </div>

    <tr *ngIf="useTableFormat">
        <td>{{label}}</td>
        <td>{{value}}</td>
    </tr>
</ng-template>

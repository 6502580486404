import type { Study } from './study.interface';

export interface StudyAdministratorStudy {
    C_StudyAdministratorStudy_key: number;
    C_Study_key: number;
    C_User_key: string;
    C_Workgroup_key: number;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    ModifiedBy: string;
    Study: Study;
    Version: number;
}

import {
    Component,
    OnInit,
    ViewChild
} from '@angular/core';

import { KaplanMeierChartRendererComponent } from './kaplan-meier-chart-renderer.component';

import { GenotypeVocabService } from '../../genotypes/genotype-vocab.service';

import {
    ChartFilter,
    GenotypeChartFilter,
    JobChartFilter,
    LineChartFilter,
    TaskChartFilter,
    SexChartFilter,
    ExitReasonChartFilter,
    ChartFilterGroup,
    CohortChartFilter,
    StudyChartFilter
} from '../filters';

import {
    notEmpty,
    randomId
} from '../../common/util';

@Component({
    selector: 'kaplan-meier-chart',
    templateUrl: './kaplan-meier-chart.component.html',
    styleUrls: ['../charts-facet.component.scss'],
    providers: [GenotypeVocabService]
})
export class KaplanMeierChartComponent implements OnInit {
    @ViewChild(KaplanMeierChartRendererComponent)
    private chartComponent: KaplanMeierChartRendererComponent;

    // The selected ChartFilterGroups
    filterGroups: ChartFilterGroup[];
    // The selected chart interval
    interval: string;
    // The selected interval count
    intervalCount: number;
    intervalCountDefault = 50;

    chartTitle: string;

    domIdAddition: string;

    // Lists of values
    filterTypes: typeof ChartFilter[];
    intervals: string[];
    intervalCounts: number[];


    constructor() {
        // Nothing to do
    }

    ngOnInit() {
        this.domIdAddition = randomId();

        this.chartTitle = 'Kaplan-Meier Chart';

        this.filterTypes = [
            GenotypeChartFilter,
            JobChartFilter,
            LineChartFilter,
            TaskChartFilter,
            SexChartFilter,
            ExitReasonChartFilter,
            CohortChartFilter,
            StudyChartFilter
        ];

        this.intervals = ['Days', 'Weeks', 'Months'];
        this.intervalCounts = [10, 25, 50, 100, 300, 500];

        this.filterGroups = [];

        if (!this.interval) {
            // Don't show empty option
            this.interval = this.intervals[0];
        }

        if (!this.intervalCount) {
            // Don't show empty option
            this.intervalCount = this.intervalCountDefault;
        }
    }

    isInputValid(): boolean {
        if (this.interval && notEmpty(this.filterGroups)) {
            return true;
        } else {
            return false;
        }
    }

    createChart(): void {
        this.chartComponent.createChart();
    }
}

/**
 * Allowed input types
 */
/* eslint-disable-next-line */
export const ParamInputs = {
    date: 'date',
    daterange: 'daterange',
    dropdown: 'dropdown',
    autocompletesingleselect: 'autocompletesingleselect',
    autocompletemultiselect: 'autocompletemultiselect',
    dropdownindirect: 'dropdownindirect'
};

import type { Job } from './job.interface';
import type { JobCharacteristic } from './job-characteristic.interface';

export interface JobCharacteristicInstance {
    C_EnumerationItem_key: number | null;
    C_JobCharacteristicInstance_key: number;
    C_JobCharacteristic_key: number;
    C_JobSubtype_key: number | null;
    C_Job_key: number;
    C_VocabularyItem_key: number | null;
    C_Workgroup_key: number;
    CharacteristicName: string;
    CharacteristicValue: string;
    CreatedBy: string;
    DateCreated: Date;
    DateEnd: Date | null;
    DateModified: Date;
    DateStart: Date | null;
    DefaultValue: string;
    Description: string;
    Job: Job;
    JobCharacteristic: JobCharacteristic;
    ModifiedBy: string;
    Version: number;
}

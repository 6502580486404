import type { TaskGroup } from './task-group.interface';
import type { cv_Frequency } from './cv-frequency.interface';

export interface Pattern {
    C_Pattern_key: number;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    DaysPattern: string;
    ModifiedBy: string;
    TaskGroup: TaskGroup[];
    Version: number;
    cv_Frequency: cv_Frequency[];
}

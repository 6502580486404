<form novalidate>

    <div class="modal-header justify-content-center">
        <h4 class="modal-title">
            Edit Data Post Task Completion
        </h4>
    </div>

    <div class="modal-body">
        <p>
            You are attempting to edit a task that has already been completed. If you wish to continue with the action,
            you will be required to add a reason for the change in the text box below.
        </p>
        <textarea name="reason"
                required="true"
                [(ngModel)]="reason"
                class="form-control">
        </textarea>
    </div>

    <div class="modal-footer">
        <button type="button"
                class="btn btn-fw btn-lg btn-primary"
                (click)="onApply()"
                [disabled]="applyButtonDisabled()">
            Apply
        </button>
        <button type="button"
                class="btn btn-lg btn-secondary"
                (click)="onCancel()">
            Cancel
        </button>
    </div>

</form>

import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, Renderer2, SimpleChanges, ViewChild } from "@angular/core";

@Component({
    selector: 'text-slider-button',
    template: `
        <label class="text-slider-container p-1 justify-content-between" style="width: fit-content;" [ngClass]="disabled && 'text-slider-container-disabled'" #container>
            <input id="toggle" type="checkbox" [(ngModel)]="model" (ngModelChange)="onModelChange()">
            <span class="text p-1 user-select-none" #trueEl>{{ trueText }}</span>
            <span class="text p-1 user-select-none" #falseEl>{{ falseText }}</span>
            <span class="slider" #slider></span>
        </label>
    `,
    styleUrls: ['./text-slider-button.component.scss']
})
export class TextSliderButtonComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
    @Input() model: boolean;
    @Input() trueText: string;
    @Input() falseText: string;
    @Input() disabled = false;

    @ViewChild('container', { static: true}) container: ElementRef;
    @ViewChild('trueEl', { static: true }) trueEl: ElementRef;
    @ViewChild('falseEl', { static: true }) falseEl: ElementRef;
    @ViewChild('slider', { static: true }) slider: ElementRef;

    @Output() modelChange = new EventEmitter<boolean>();

    private intersectionObserver: IntersectionObserver;

    constructor(private renderer: Renderer2) {}

    ngOnInit(): void {
        this.setupIntersectionObserver();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!changes?.model?.firstChange && changes?.model?.previousValue !== changes?.model?.currentValue) {
            this.updateSliderWidth();
        }
    }

    ngOnDestroy(): void {
        if (this.intersectionObserver) {
            this.intersectionObserver.disconnect();
        }
    }
    
    ngAfterViewInit() {
        this.updateSliderWidth();
    }

    onModelChange() {
        if (this.disabled) {
            return;
        }
        this.updateSliderWidth();
        this.modelChange.emit(this.model);
    }

    updateSliderWidth() {
        const containerWidth = this.container.nativeElement.offsetWidth;
        const trueWidth = this.trueEl.nativeElement.offsetWidth;
        const falseWidth = this.falseEl.nativeElement.offsetWidth;
        const sliderWidth = this.model ? trueWidth : falseWidth;

        const distance = containerWidth - (trueWidth + falseWidth) + trueWidth;
        
        this.renderer.setStyle(this.slider.nativeElement, 'width', `${sliderWidth}px`);
        this.renderer.setStyle(this.slider.nativeElement, 'transform', this.model ? 'translateX(0px)' : `translateX(calc(${distance}px - .5rem))`);
    }

    private setupIntersectionObserver() {
        const options: IntersectionObserverInit = {
            root: null,
            threshold: 1.0
        };
        
        this.intersectionObserver = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting) {
                this.updateSliderWidth();
            }
        }, options);

        this.intersectionObserver.observe(this.container.nativeElement);
    }
}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostBinding, Injector, Input, OnInit, SkipSelf } from '@angular/core';
import { ButtonComponent } from '@common/components/button/button.component';
import { plusCircleFilled } from '@icons';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'button[climbAddItemButton]',
    templateUrl: './add-item-button.component.html',
    styleUrls: ['./add-item-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddItemButtonComponent extends ButtonComponent implements OnInit {
    readonly icons = { plusCircleFilled };

    @HostBinding('attr.title')
    @Input() title = 'Add item';

    constructor(
        injector: Injector,
        elementRef: ElementRef,
        @SkipSelf() cdr: ChangeDetectorRef,
    ) {
        super(injector, elementRef, cdr);
    }

    ngOnInit(): void {
        super.ngOnInit();
        super.variant = 'ghost';
        super.size = 'sm';
        super.onlyIcon = true;
    }
}

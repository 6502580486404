import { AnimalMarkerVendor } from './animal-marker-vendor.enum';
import {
    Component,
    Input,
    OnChanges,
    OnInit,
} from '@angular/core';

@Component({
    selector: 'animal-marker',
    template: `
    <div class="animal-marker">
      <climb-color-box *ngIf="color"
            [backgroundColor]="color"
            [height]="'1.5em'"></climb-color-box>
      <span>{{marker}}</span>
    </div>
    `,
})
export class AnimalMarkerComponent implements OnInit, OnChanges {
    @Input() animal: any;

    @Input() marker: string;
    @Input() markerType: any;

    color: string;

    /* tslint:disable:object-literal-key-quotes */
    readonly COLORS_RAPID = {
        'yellow': 'rgb(255,255,0)',
        'crème': 'rgb(240,238,228)',
        'creme': 'rgb(240,238,228)',
        'cream': 'rgb(240,238,228)',
        'orange': 'rgb(236,152,0)',
        'blue': 'rgb(111,186,232)',
        'green': 'rgb(0,240,16)',
        'gray': 'rgb(201,200,202)',
        'black': 'rgb(13,13,13)',
        'red': 'rgb(206,37,57)',
        'lavender': 'rgb(193,139,183)',
        'brown': 'rgb(155,115,3)',
    };
    /* tslint:enable:object-literal-key-quotes */

    constructor() {
        // Nothing to do
    }

    // lifecycle
    ngOnInit() {
        this.initColor();
    }

    ngOnChanges(changes: any) {
        if (changes.animal) {
            this.marker = this.animal.PhysicalMarker;
            this.markerType = this.animal.cv_PhysicalMarkerType;
        }
        this.initColor();
    }

    initColor() {
        this.color = this.findColor();
    }

    findColor(): string {
        if (!this.marker || !this.markerType) {
            return null;
        }

        if (this.markerType.Vendor === AnimalMarkerVendor.RapID) {
            return this.COLORS_RAPID[this.marker.toLowerCase()];
        }

        return null;
    }
}

import {Injectable} from '@angular/core';
import {Entity as InternalEntity} from '@common/types';
import {Entity as BreezeEntity} from 'breeze-client';
import {IGlpFacetStateServiceBulk} from "./interfaces/glp-facet-state-service.interface";
import { GlpBaseFacetStateService } from './glp-base-facet-state.service';

@Injectable()
export abstract class GlpBaseFacetStateServiceBulk<T>  extends GlpBaseFacetStateService<T> implements IGlpFacetStateServiceBulk{
    getAllRelatedChangesBulk(entities: InternalEntity<T>[]): BreezeEntity[] {
        const changes: BreezeEntity[] = [];

        for (const entity of entities) {
            changes.push(...this.getAllRelatedChanges(entity))
        }

        return changes;
    }

    /**
     * Determines if a Breeze entity has related changes for an array of entities.
     * @param {InternalEntity<T>[]} entities - The entities to check for changes.
     * @returns {boolean} Returns true if the entity has changes, false otherwise.
     */
    hasChangesBulk(entities: InternalEntity<T>[]): boolean {
            if (entities.length === 0) {
                return
            }

        const changes = this.getAllRelatedChangesBulk(entities);

            return changes.length > 0;
    }
}

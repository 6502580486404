import { SearchService } from './search.service';
import { NgModule, ModuleWithProviders } from '@angular/core';

import { ClimbServicesModule } from '../services/climb-services.module';

@NgModule({
    imports: [
        ClimbServicesModule,
    ],
    declarations: [
    ],
    exports: [
    ]
})
export class SearchSharedModule { 
    static forRoot(): ModuleWithProviders<SearchSharedModule> {
        return {
          ngModule: SearchSharedModule,
          providers: [
            SearchService
          ]
        };
    }
}

import { Injectable } from '@angular/core';
import { defer, Observable } from 'rxjs';

import { UserService } from '../user/user.service';
import { VocabularyService } from '../vocabularies/vocabulary.service';
import { take } from 'rxjs/operators';

@Injectable()
export class ProtocolVocabService {

    // vocab Observables
    public get scheduleTypes$(): Observable<any[]>{
        return defer(() => this.vocabularyService.getCV('cv_ScheduleTypes')).pipe(take(1));
    }
    public get timeRelations$(): Observable<any[]>{
        return defer(() => this.vocabularyService.getCV('cv_TimeRelations')).pipe(take(1));
    }
    public get timeUnits$(): Observable<any[]>{
        return defer(() => this.vocabularyService.getCV('cv_TimeUnits')).pipe(take(1));
    }
    public get userWorkgroups$(): Observable<any[]>{
        return defer(() => this.userService.getUserWorkgroups()).pipe(take(1));
    }
    public get preservationMethods$(): Observable<any[]>{
        return defer(() => this.vocabularyService.getCV('cv_PreservationMethods')).pipe(take(1));
    }
    public get sampleStatuses$(): Observable<any[]>{
        return defer(() => this.vocabularyService.getCV('cv_SampleStatuses')).pipe(take(1));
    }
    public get sampleTypes$(): Observable<any[]>{
        return defer(() => this.vocabularyService.getCV('cv_SampleTypes')).pipe(take(1));
    }
    public get containerTypes$(): Observable<any[]>{
        return defer(() => this.vocabularyService.getCV('cv_ContainerTypes')).pipe(take(1));
    }
    public get frequencies$(): Observable<any[]>{
        return defer(() => this.vocabularyService.getCV('cv_Frequencies')).pipe(take(1));
    }
    public get taskFrequencies$(): Observable<any[]>{
        return defer(() => this.vocabularyService.getCV('cv_TaskFrequencies')).pipe(take(1));
    }
    public get sampleSubtypes$(): Observable<any[]>{
        return defer(() => this.vocabularyService.getCV('cv_SampleSubtypes')).pipe(take(1));
    }
    public get sampleProcessingMethods$(): Observable<any[]>{
        return defer(() => this.vocabularyService.getCV('cv_SampleProcessingMethods')).pipe(take(1));
    }
    public get sampleAnalysisMethods$(): Observable<any[]>{
        return defer(() => this.vocabularyService.getCV('cv_SampleAnalysisMethods')).pipe(take(1));
    }

    constructor(
        private userService: UserService,
        private vocabularyService: VocabularyService
    ) {}
}

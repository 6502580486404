import type { MaterialPool } from './material-pool.interface';

export interface cv_MaterialPoolType {
    C_MaterialPoolType_key: number;
    C_VocabularySource_key: number | null;
    C_Workgroup_key: number;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    ElementID: string;
    IsActive: boolean;
    IsDefault: boolean;
    IsDefaultMating: boolean;
    IsDeprecated: boolean;
    MaterialPool: MaterialPool[];
    MaterialPoolType: string;
    ModifiedBy: string;
    SortOrder: number | null;
    Version: number;
}

import {
    CellFormatterService,
    FormatterOptions,
    TableOptions
} from '../common/datatable';
import { TranslationService } from '../services/translation.service';
import { AuditModifiedFieldComponent } from './audit-modified-fields/audit-modified-field.component';
import { AuditClassFactory } from './audit-class-maker';

export class AuditTaskAssignmentsTableOptions {

    options: TableOptions;

    constructor(
        private cellFormatterService: CellFormatterService,
        private translationService: TranslationService
    ) {
        this.options = this.getDefaultOptions();
    }

    getDefaultOptions(): TableOptions {

        return {
            csvFileName: 'task-assignments-audit.csv',
            enableDetailColumn: false,
            enableDraggable: false,
            enableSelectable: false,
            enableAuditColumns: false,
            columns: [
                {
                    displayName: 'Type',
                    field: 'UpdateType',
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Modified Fields',
                    field: 'ModifiedFields',
                    sortField: 'ModifiedFields',
                    rendererComponent: AuditModifiedFieldComponent,
                    exportFormatter: this.cellFormatterService.auditModifiedFieldsExportFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Date',
                    field: 'ModifiedDate',
                    formatter: this.cellFormatterService.dateTimeUTCFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Modified By',
                    field: 'ModifiedBy',
                    formatter: this.cellFormatterService.userNameFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Animal ID',
                    field: 'AnimalID',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('AnimalID', 'AnimalID'),
                    cellClass: AuditClassFactory.createCellClass('animalid'),
                },
                {
                    displayName: 'Animal Name',
                    field: 'AnimalName',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('AnimalName', 'AnimalName'),
                    cellClass: AuditClassFactory.createCellClass('animalname'),
                },
                {
                    displayName: 'Birth ID',
                    field: 'BirthID',
                    visible: false,
                    formatter: this.cellFormatterService.createAuditColumnFormatter('BirthID', 'BirthID'),
                    cellClass: AuditClassFactory.createCellClass('birthid'),
                },
                {
                    displayName: 'Housing ID',
                    field: 'HousingID',
                    visible: false,
                    formatter: this.cellFormatterService.createAuditColumnFormatter('HousingID', 'HousingID'),
                    cellClass: AuditClassFactory.createCellClass('housingid'),
                },
                {
                    displayName: this.translationService.translate('Line') + ' Name',
                    field: 'LineName',
                    visible: false,
                    formatter: this.cellFormatterService.createAuditColumnFormatter('LineName', this.translationService.translate('Line') + 'Name'),
                    cellClass: AuditClassFactory.createCellClass((this.translationService.translate('Line') + 'Name').toLowerCase()),
                },
                {
                    displayName: 'Mating ID',
                    field: 'MatingID',
                    visible: false,
                    formatter: this.cellFormatterService.createAuditColumnFormatter('MatingID', 'MatingID'),
                    cellClass: AuditClassFactory.createCellClass('matingid'),
                },
                {
                    displayName: this.translationService.translate('Job') + ' Name',
                    field: 'JobName',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('JobName', this.translationService.translate('Job') + 'Name'),
                    cellClass: AuditClassFactory.createCellClass((this.translationService.translate('Job') + 'Name').toLowerCase()),
                },
                {
                    displayName: 'Sample ID',
                    field: 'SampleID',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('SampleID', 'SampleID'),
                    cellClass: AuditClassFactory.createCellClass('sampleid'),
                },
                {
                    displayName: 'Task Type',
                    field: 'TaskType',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('TaskType', 'TaskType'),
                    cellClass: AuditClassFactory.createCellClass('tasktype'),
                },
                {
                    displayName: 'Task',
                    field: 'Task',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('Task', 'Task'),
                    cellClass: AuditClassFactory.createCellClass('task'),
                },
                {
                    displayName: 'Task Name',
                    field: 'TaskName',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('TaskName', 'TaskName'),
                    cellClass: AuditClassFactory.createCellClass('taskname'),
                },
                {
                    displayName: 'Due Date',
                    field: 'DueDate',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('DueDate', 'DueDate', this.cellFormatterService.dateTimeUTCFormatter),
                    cellClass: AuditClassFactory.createCellClass('duedate'),
                },
                {
                    displayName: 'Assigned To',
                    field: 'AssignedTo',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('AssignedTo', 'AssignedTo'),
                    cellClass: AuditClassFactory.createCellClass('assignedto'),
                },
                {
                    displayName: 'Created By',
                    field: 'CreatedBy',
                    formatter: this.cellFormatterService.userNameFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Created Date',
                    field: 'DateCreated',
                    formatter: this.cellFormatterService.dateTimeUTCFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                }
            ]
        };
    }
}

export enum RecipientType {
    Role,
    User
}

/**
 * Model for email recipient selector
 */
export class Recipient {
    recipientName: string;
    recipientKey: number;
    type: RecipientType;
}

import { DataContextService } from './../../services/data-context.service';
import { EventEmitter, Output, Directive } from '@angular/core';

import { BaseDetailService } from './base-detail.service';
import { FacetLoadingStateService } from './facet-loading-state.service';
import { LoggingService } from '../../services/logging.service';
import { ViewUnsavedChangesModalService } from '../toolbars';
import { UnsavedChanges } from '../../services/save-changes.service';

/**
 * Base class for Angular 2 Detail pages
 */
@Directive()
export abstract class BaseDetail {  // eslint-disable-line @angular-eslint/directive-class-suffix
    // To be configured in child class
    COMPONENT_LOG_TAG = 'BaseDetail';

    exit: EventEmitter<void> = new EventEmitter<void>();
    next: EventEmitter<void> = new EventEmitter<void>();
    previous: EventEmitter<void> = new EventEmitter<void>();
    @Output() reload: EventEmitter<void> = new EventEmitter<void>();


    dataContext: DataContextService;
    // loading service to trigger loading state of parent facet component
    facetLoadingState: FacetLoadingStateService;
    loggingService: LoggingService;
    viewUnsavedChangesModalService: ViewUnsavedChangesModalService;

    // loading flag to use in detail template
    loading: boolean;
   
    constructor(baseDetailService: BaseDetailService) {
        this.dataContext = baseDetailService.dataContext;
        this.facetLoadingState = baseDetailService.facetLoadingStateService;
        this.loggingService = baseDetailService.loggingService;
        this.viewUnsavedChangesModalService = baseDetailService.viewUnsavedChangesModalService;
    }

    setLoading(isLoading: boolean) {
        this.loading = isLoading;
        this.facetLoadingState.changeLoadingState(isLoading);
    }

    previousClicked(changes: UnsavedChanges) {
        if (changes !== UnsavedChanges.save) {
            this.cancelAnyChanges();
        }
        this.previous.emit();
    }

    nextClicked(changes: UnsavedChanges) {
        if (changes !== UnsavedChanges.save) {
            this.cancelAnyChanges();
        }
        this.next.emit();
    }

    exitClicked(changes: UnsavedChanges) {
        if (changes !== UnsavedChanges.save) {
            this.cancelAnyChanges();
        }
        this.exit.emit();
    }

    cancelAnyChanges() {
        if (this.hasChanges()) {
            this.onCancel();
            this.loggingService.logFacetUndoSuccess(this.COMPONENT_LOG_TAG);
        }
    }

    // TODO: Left here for backwards compatibility. Needs to be overriden in entity-details component
    //  to have custom logic. In other cases it works as before (global hasChanges check).
    //  Eventually needs to be converted to abstract method
    hasChanges(): boolean {
        return this.dataContext.hasChanges();
    }

    /**
     * To be overriden.
     *   This method should cancel pending changes for
     *   current detail entity
     */
    abstract onCancel(): void;
}

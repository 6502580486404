import type { Line } from './line.interface';

export interface LineAlias {
    C_LineAlias_key: number;
    C_Line_key: number;
    C_Workgroup_key: number;
    CreatedBy: string;
    DateAssigned: Date | null;
    DateCreated: Date;
    DateModified: Date;
    IsPrimaryName: boolean;
    Line: Line;
    LineName: string;
    LineNameMarkup: string;
    ModifiedBy: string;
    Version: number;
}

/*
* Returns whether item is falsey or empty array
*/
export function notEmpty(value: unknown): boolean {
    return !empty(value);
}

export function empty(value: unknown): boolean {
    if (Array.isArray(value)) {
        return value.length < 1;
    }
    if (typeof value === 'number') {
        return false;
    }
    return !Boolean(value);
}

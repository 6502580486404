import {
    CountSummary, 
    UniqueItem 
} from '../models';

/**
 * 
 * @param items 
 */
export function summarizeItems<T extends UniqueItem>(items: T[]): CountSummary<T>[] {
    const summaries: CountSummary<T>[] = [];

    // use map to track uniqueKeys for count grouping
    const map: Record<string, any> = {};
    for (const item of items) {
        if (!(item.uniqueKey in map)) {
            const summary = <CountSummary<T>> {
                count: 0,
                item
            };
            summaries.push(summary);
            map[item.uniqueKey] = summary;
        }
        map[item.uniqueKey].count += 1;
    }
    return summaries;
}

import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { arrowOutOfRectangle } from '@icons';
import { AuthService } from '@services/auth.service';
import { ConfirmOptions, ConfirmService } from '@common/confirm';
import { LocalStorageKey } from '@config';
import { DataContextService } from '@services/data-context.service';
import { LocalStorageService } from '@services/local-storage.service';
import { LogoutService } from '@services/logout.service';
import { ViewEditProfileService } from '../../../user/view-edit-profile.service';
import { WorkspaceManagerService } from '@services/workspace-manager.service';
import { InactivityTimeoutService } from '@services/inactivity-timeout.service';
import { WorkgroupSwitchModalComponent } from '../workgroup-switch-modal/workgroup-switch-modal.component';
import { DialogService } from '@common/dialog/dialog.service';
import { WorkspaceService } from 'src/app/workspaces/workspace.service';

@Component({
    selector: 'climb-user-menu',
    templateUrl: 'user-menu.component.html',
    styleUrls: ['user-menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserMenuComponent implements OnInit {
    icons = { arrowOutOfRectangle };
    userName = '';

    get userAvatarLabel(): string {
        return this.userName.slice(0, 1).toUpperCase();
    }

    constructor(
      private authService: AuthService,
      private confirmService: ConfirmService,
      private dataContext: DataContextService,
      private localStorageService: LocalStorageService,
      private logoutService: LogoutService,
      private viewEditProfileService: ViewEditProfileService,
      private workspaceService: WorkspaceService,
      private workspaceManager: WorkspaceManagerService,
      private dialogService: DialogService,
      private inactivityTimeoutService: InactivityTimeoutService,
    ) {}

    ngOnInit(): void {
        this.userName = this.authService.getCurrentUserName();
    }

    openUserProfileModal(): void {
        this.viewEditProfileService.openEditProfileModal();
    }

    openWorkgroupModal(): void {
        this.dialogService.open(WorkgroupSwitchModalComponent, {
            data: {
                showCloseButton: true
            },
        });
    }

    async clearAndLogOut(): Promise<void> {
        if (this.dataContext.getChangesCount() > 0) {
            const confirmOptions: ConfirmOptions = {
                title: 'Unsaved Changes',
                message: 'You have unsaved changes that will be lost. ' +
                    'Are you sure you want to log out?',
                yesButtonText: 'Log Out',
                noButtonText: 'Cancel',
                isDanger: true
            };
            await this.confirmService.confirm(confirmOptions);
        }

        this.confirmLogout();
    }

    confirmLogout(): void {
        this.clearSession();
        this.logoutService.logout();
    }

    clearSession() {
        this.dataContext.cancel();
        this.workspaceManager.cancel();
        this.workspaceService.isCustomizeWorkspaceActive = false;
        this.inactivityTimeoutService.stopTimer();
    }

    reloadClimbApp() {
        const localstorageKeysToClear = [
            LocalStorageKey.ADMIN_BREEZE_SCHEMA,
            LocalStorageKey.ADMIN_SCHEMA_KEY,
            LocalStorageKey.DATA_BREEZE_SCHEMA,
            LocalStorageKey.DATA_SCHEMA_KEY,
        ];
        for (const key of localstorageKeysToClear) {
            this.localStorageService.remove(key);
        }
        window.location.reload();
    }

}

import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CharacteristicParentEntity, CharacteristicSignalType } from '../characteristic-instance-list/characteristic-instance-list.component';
import { CharacteristicTypeNameEnum } from '../models';
import { CharacteristicService } from '../characteristic.service';
import { Animal } from '@common/types';
import { LoggingService } from '@services/logging.service';


@Component({
    selector: "characteristic-instance-wrapper",
    templateUrl: "characteristic-instance-wrapper.component.html"
})
export class CharacteristicInstanceWrapperComponent {
    @Input() signalValue: CharacteristicSignalType;
    @Input() characteristicType: CharacteristicTypeNameEnum;
    @Input() model: CharacteristicParentEntity;
    @Input() hasAllCharacteristics: boolean;

    @Input() readonly: boolean;
    @Input() isLoading: boolean;

    @Output() characteristicRefresh: EventEmitter<void> = new EventEmitter<void>();

    readonly CHARACTERISTIC_TYPE_NAMES = CharacteristicTypeNameEnum;

    isRefreshNeeded = false; 

    constructor(private characteristicService: CharacteristicService,
                private loggingService: LoggingService) {

    }

    updateRefreshNeeded(isRefreshNeeded: boolean) {
        this.isRefreshNeeded = isRefreshNeeded;
    }
    
    refreshCharacteristics() {
        this.characteristicService.refreshTaxonCharacteristics([this.model as Animal]).then(() => {
            this.characteristicRefresh.emit();
            this.loggingService.logWarning("Characteristics Refreshed. Please reload Climb to see the results", null, 'animal-detail', true);
            this.isRefreshNeeded = false;
        });
    }
}

// Like active-vocab-select, but operates on dynamic property paths
// which may or may not reference existing values. Getting and
// setting are done safely.

import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output
} from '@angular/core';

import { getSafeProp } from './util/get-safe-prop';
import { setSafeProp } from './util/set-safe-prop';
import { randomId } from './util/random-id';

@Component({
    selector: 'active-vocab-select-dynamic',
    template: `
    <select [attr.name]="_fieldName"
            class="form-control  input-medium"
            [(ngModel)]="model"
            (ngModelChange)="modelChangeHandler()"
            [disabled]="readonly"
            [required]="required"
    >
        <option *ngIf="nullable"></option>
        <option *ngFor="let item of _filteredVocabChoices"
                [value]="item.key">
            {{item.option}}
        </option>
    </select>
    `
})
export class ActiveVocabSelectDynamicComponent implements OnChanges, OnInit {
    @Input() modelObject: any;
    @Input() modelPath: string;
    model: any;

    @Output() modelChange: EventEmitter<any> = new EventEmitter<any>();

    @Input() vocabChoices: any[];
    // Function to retrieve the key for <option> value
    @Input() keyFormatter: (item: any) => any;
    // Function to display <option> item (What user will see)
    @Input() optionFormatter: (item: any) => string;

    // a blank <option> is supplied
    @Input() nullable: boolean;
    // selector is disabled if set to have readonly access
    @Input() readonly: boolean;
    @Input() required: boolean;

    _fieldName: string;

    // state variables
    _filteredVocabChoices: any[];

    ngOnInit() {
        // Angular likes to have a name on form fields.
        //  generate a unique field name
        this._fieldName = "field_" + randomId();

        // Build value from object and path
        this.model = getSafeProp(this.modelObject, this.modelPath);

        if (!this.keyFormatter) {
            this.keyFormatter = (item: any) => item;
        }

        if (!this.optionFormatter) {
            this.optionFormatter = (item: any) => item;
        }

        this.refreshActiveVocabItems();
    }

    ngOnChanges(changes: any) {
        // re-trigger active vocab filter if
        //   inputs have changed
        if (changes.modelObject || changes.modelPath) {
            this.model = getSafeProp(this.modelObject, this.modelPath);
            this.refreshActiveVocabItems();
        } else if (changes.vocabChoices) {
            this.refreshActiveVocabItems();
        }
    }

    refreshActiveVocabItems() {
        let newVocabItems = this.filterActiveVocabItems(
            this.vocabChoices,
            this.model,
            this.keyFormatter
        );

        newVocabItems = this.formatActiveVocabItems(
            newVocabItems,
            this.keyFormatter,
            this.optionFormatter
        );

        this._filteredVocabChoices = newVocabItems;
    }

    /*
    * Filter out inactive vocab items,
    *   unless equal to current model
    */
    filterActiveVocabItems(
        items: any[], model: any, keyFormatter: (item: any) => any
    ) {
        if (items) {
            return items.filter((item: any) => {
                const itemKey = keyFormatter(item);
                return item.IsActive || itemKey === model;
            });
        }
        return [];
    }

    /*
    * Format vocab items with keyFormatter
    *   and optionFormatter
    */
    formatActiveVocabItems(
        items: any[],
        keyFormatter: (item: any) => any,
        optionFormatter: (item: any) => string
    ) {
        if (items) {
            return items.map((item: any) => {
                return {
                    key: keyFormatter(item),
                    option: optionFormatter(item)
                };
            });
        }
        return [];
    }

    modelChangeHandler() {
        // Sync setting
        setSafeProp(this.modelObject, this.modelPath, this.model);
        this.modelChange.emit(this.model);
    }
}

import type { AnimalShipment } from './animal-shipment.interface';
import type { Construct } from './construct.interface';
import type { Job } from './job.interface';
import type { JobLine } from './job-line.interface';
import type { LineAlias } from './line-alias.interface';
import type { LineAllele } from './line-allele.interface';
import type { LineGenotypeAssay } from './line-genotype-assay.interface';
import type { LineMarker } from './line-marker.interface';
import type { LocationPosition } from './location-position.interface';
import type { Marker } from './marker.interface';
import type { Material } from './material.interface';
import type { Mating } from './mating.interface';
import type { MessageMap } from './message-map.interface';
import type { Note } from './note.interface';
import type { NumericLimit } from './numeric-limit.interface';
import type { StoredFileMap } from './stored-file-map.interface';
import type { StudyLine } from './study-line.interface';
import type { TaskLine } from './task-line.interface';
import type { cv_GenotypeAssay } from './cv-genotype-assay.interface';
import type { cv_LineStatus } from './cv-line-status.interface';
import type { cv_LineType } from './cv-line-type.interface';
import type { cv_Taxon } from './cv-taxon.interface';

export interface Line {
    AnimalShipment: AnimalShipment[];
    BackgroundLine: string;
    BreedingStrategy: string;
    C_Construct_key: number | null;
    C_GenotypeAssay_key: number | null;
    C_LineStatus_key: number | null;
    C_LineType_key: number;
    C_Line_key: number;
    C_LocationPosition_key: number | null;
    C_Taxon_key: number;
    C_Workgroup_key: number;
    Comment: string;
    Construct: Construct;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    Development: string;
    ExternalLink: string;
    IsActive: boolean;
    Job: Job[];
    JobLine: JobLine[];
    LineAlias: LineAlias[];
    LineAllele: LineAllele[];
    LineGenotypeAssay: LineGenotypeAssay[];
    LineMarker: LineMarker[];
    LineName: string;
    LocationPosition: LocationPosition;
    Marker: Marker[];
    Material: Material[];
    Mating: Mating[];
    MessageMap: MessageMap[];
    ModifiedBy: string;
    Note: Note[];
    NumericLimit: NumericLimit[];
    ParentLine: string;
    Reference: string;
    SortOrder: number;
    StockID: string;
    StockNumber: string;
    StoredFileMap: StoredFileMap[];
    StudyLine: StudyLine[];
    TaskLine: TaskLine[];
    Technician: string;
    Version: number;
    cv_GenotypeAssay: cv_GenotypeAssay;
    cv_LineStatus: cv_LineStatus;
    cv_LineType: cv_LineType;
    cv_Taxon: cv_Taxon;
}

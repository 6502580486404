import { SampleBulkTemplatesComponent } from './bulkedit/sample-bulk-templates.component';
import { SampleBulkEditComponent } from './bulkedit/sample-bulk-edit.component';
import { FacetSharedModule } from './../common/facet/facet-shared.module';
import { UsersSharedModule } from './../user/users-shared.module';
import { 
    ViewSampleAuditReportComponentService 
} from './audit/view-sample-audit-report-component.service';
import { EnumerationsModule } from './../enumerations/enumerations.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AgGridModule } from "ag-grid-angular";

import { ClimbCommonModule } from '../common/climb-common.module';
import { ClimbServicesModule } from '../services/climb-services.module';

import { AnimalsSharedModule } from '../animals/animals-shared.module';
import { AuditSharedModule } from '../audit/audit-shared.module';
import { CharacteristicsSharedModule } from '../characteristics/characteristics-shared.module';
import { ConstructsSharedModule } from '../constructs/constructs-shared.module';
import { JobsSharedModule } from '../jobs/jobs-shared.module';
import { LinesSharedModule } from '../lines/lines-shared.module';
import { LocationsSharedModule } from '../locations/locations-shared.module';
import { ReportingSharedModule } from '../reporting/reporting-shared.module';
import { SamplesSharedModule } from './samples-shared.module';
import { SearchSharedModule } from '../search/search-shared.module';
import { StudiesSharedModule } from '../studies/studies-shared.module';
import { VocabulariesSharedModule } from '../vocabularies/vocabularies-shared.module';
import { WorkspacesSharedModule } from '../workspaces/workspaces-shared.module';

import { SampleDetailComponent } from './sample-detail/sample-detail.component';
import { SampleFacetComponent } from './sample-facet.component';
import { SampleFilterComponent } from './sample-filter.component';
import { SampleLabelComponent } from './sample-label.component';
import { SampleLogicService } from './sample-logic.service';
import {
    SampleSourceSingleSelectComponent
} from './sample-source-single-select.component';
import { SampleSourceCellRendererComponent } from './sample-source-cell-renderer.component';
import { SampleAuditReportComponent } from './audit';
import { SampleBulkAddComponent } from './bulkedit';
import { OrdersSharedModule } from '../orders/orders-shared.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgbModule,
        AgGridModule.withComponents([]),
        ClimbCommonModule,
        ClimbServicesModule,
        FacetSharedModule,
        AnimalsSharedModule,
        AuditSharedModule,
        CharacteristicsSharedModule,
        ConstructsSharedModule,
        EnumerationsModule,
        JobsSharedModule,
        LinesSharedModule,
        LocationsSharedModule,
        ReportingSharedModule,
        SamplesSharedModule,
        SearchSharedModule,
        StudiesSharedModule,
        UsersSharedModule,
        VocabulariesSharedModule,
        WorkspacesSharedModule,
        OrdersSharedModule
    ],
    declarations: [
        SampleAuditReportComponent,
        SampleBulkEditComponent,
        SampleBulkAddComponent,
        SampleBulkTemplatesComponent,
        SampleDetailComponent,
        SampleFilterComponent,
        SampleFacetComponent,
        SampleLabelComponent,
        SampleSourceSingleSelectComponent,
        SampleSourceCellRendererComponent
    ],
    exports: [
        SampleSourceSingleSelectComponent,
        SampleSourceCellRendererComponent,
        SampleAuditReportComponent,
        SampleFilterComponent,
        SampleFacetComponent
    ],
    providers: [
        SampleLogicService,
        ViewSampleAuditReportComponentService
    ]
})
export class SamplesModule { }

import { Component, Input, OnInit } from '@angular/core';
import { TranslationService } from '@services/translation.service';
import { AuditService } from '../../audit.service';
import { auditLinkMap } from './audit-link-map';
import { AuditGridView } from '../../models/audit-grid-view.enum';

@Component({
    selector: 'audit-link',
    templateUrl: "audit-link.component.html",
    styleUrls: ['audit-link.component.scss']
})
export class AuditLinkComponent implements OnInit {
    @Input() modifiedFields: string[];

    translatedLinks: string[] = [];
    nonLinks = '';

    fieldsWithLinks: string[] = [];

    constructor(private auditService: AuditService, 
                private translationService: TranslationService) {

    }

    ngOnInit(): void {
        this.initialize();
    }

    initialize(): void {
        this.nonLinks = this.modifiedFields.filter((l: string) => !this.hasAuditLink(l) && l !== "ReasonForChange").join(",");
        this.fieldsWithLinks = this.modifiedFields
                        .filter((l: string) => this.hasAuditLink(l));
        this.translatedLinks = this.fieldsWithLinks.map((link: string) => this.translateAuditName(this.getAuditLink(link)));
    }

    /**
     * Gets the Audit section associated with the supplied field.
     * @param key the key of the audit link, most likely the modified field
     * @returns 
     */
    getAuditLink(key: string): AuditGridView {
        return auditLinkMap[key.toLowerCase()];
    }

    /**
     * Checks if the supplied key is in the audit link map
     * @param key 
     * @returns true if the audit link exists
     */
    hasAuditLink(key: string): boolean {
        return !!auditLinkMap[key.toLowerCase()];
    }
    
    /**
     * Takes the Audit Name supplied and translates it in accordance with the workgroup's translation settings. 
     * @param auditName The name of the audit that will be translated
     * @returns A translated audit name
     */
    translateAuditName(auditName: string): string {
        const auditNameSplit = auditName.split(" ");
        const splitNameTranslated = auditNameSplit.map((name: string) => this.translationService.translate(name));
        return splitNameTranslated.join(" ");
    }

    onClick(link: string): void {
        if (this.hasAuditLink(link)) {
            this.auditService.sendAuditLinkClicked(this.getAuditLink(link));
        } else {
            // this means that the modified field is not included in the audit link map and needs to be added; a problem most likely encountered by other devs
            // which would warrant a console message. 
            console.warn("The Audit Link Map for " + link + " has no value. Please update audit-link-map.ts");
        }
    }
}

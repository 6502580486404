<ng-template #animalNameTmpl let-genotype="item">
    <p class="form-control-plaintext">
        <animal-select [(model)]="genotype.C_Material_key"
            [required]="true"
            (modelChange)="selectAnimal($event)"></animal-select>
    </p>
</ng-template>

<ng-template #sexTmpl let-genotype="item">
    <p class="form-control-plaintext">
        {{genotype.Animal?.cv_Sex?.Sex}}
    </p>
</ng-template>

<ng-template #lineTmpl let-genotype="item">
    <p class="form-control-plaintext text-nowrap">
        {{genotype.Animal?.Material?.Line?.LineName}}
    </p>
</ng-template>

<ng-template #dateTmpl let-genotype="item">
    <climb-ngb-date #dateControl="ngModel" name="Date Genotype" [(ngModel)]="genotype.DateGenotype"></climb-ngb-date>
</ng-template>

<ng-template #assayTmpl let-genotype="item" let-section="section">
    <!-- Add and Edit Header has no genotype restrictions -->
    <ng-container *ngIf="section !== BulkEditSection.InputCell">
        <genotype-assay-select [(model)]="genotype.C_GenotypeAssay_key"
                                [assayChoices]="genotypeAssays"
                                [noFilterOnNullParent]="true"
                                *ngIf="genotypeAssays"></genotype-assay-select>
    </ng-container>
    <ng-container *ngIf="section === BulkEditSection.InputCell">
        <genotype-assay-select [(model)]="genotype.C_GenotypeAssay_key"
                                [assayChoices]="genotypeAssays"
                                [parentLineKey]="genotype.Animal?.Material?.C_Line_key"
                                *ngIf="genotypeAssays"></genotype-assay-select>
    </ng-container>
</ng-template>

<ng-template #symbolTmpl let-genotype="item" let-section="section">

    <!-- Add Screen ignores null assay -->
    <ng-container *ngIf="section === BulkEditSection.AddScreen">
        <genotype-symbol-select [(model)]="genotype.C_GenotypeSymbol_key"
                                [symbolChoices]="genotypeSymbols"
                                [parentAssayKey]="genotype.C_GenotypeAssay_key"
                                [noFilterOnNullParent]="false"
                                *ngIf="genotypeSymbols"></genotype-symbol-select>
    </ng-container>
    <!-- Edit Header has no genotype restrictions -->
    <ng-container *ngIf="section === BulkEditSection.EditHeader">
        <genotype-symbol-select [(model)]="genotype.C_GenotypeSymbol_key"
                                [symbolChoices]="genotypeSymbols"
                                [noFilterOnNullParent]="true"
                                *ngIf="genotypeSymbols"></genotype-symbol-select>
    </ng-container>
    <ng-container *ngIf="section === BulkEditSection.InputCell">
        <genotype-symbol-select [(model)]="genotype.C_GenotypeSymbol_key"
                                [symbolChoices]="genotypeSymbols"
                                [parentAssayKey]="genotype.C_GenotypeAssay_key"
                                *ngIf="genotypeSymbols"></genotype-symbol-select>
    </ng-container>
</ng-template>

<ng-template #plateTmpl let-genotype="item">
    <plate-select [(model)]="genotype.C_Plate_key"
                    (modelChange)="selectPlate($event)"></plate-select>
</ng-template>


/**
 * Several helper methods for interrogating breeze
 */
import {
    EntityManager,
    EntityState,
    MetadataStore,
    EntityType,
    NavigationProperty, 
    SaveOptions,
} from 'breeze-client';
import {
    getSafeProp,
    notEmpty 
} from '../common/util';
import { Entity } from '@common/types';
import {OBJECT_TYPE_MAPPING} from '@services/models';

/**
 * return foreign key property name
 *   that links entity1 type with entity2 type
 * E.g. entity1 = Material, entity2 = Line, foreignKeyName = C_Line_key
 * @param manager EntityManager
 * @param entity1 name of table
 * @param entity2 name of other table
 */
export function getForeignKeyName(
    manager: EntityManager,
    entity1: string, 
    entity2: string
): string {
    const schema = manager.metadataStore;
    const shortMapProp = '_shortNameMap';
    const shortNameMap = schema[shortMapProp];
    if (!(entity1 in shortNameMap)) {
        return null;
    }
    const fullName = shortNameMap[entity1];

    const structMapProp = '_structuralTypeMap';
    const structMap = schema[structMapProp];
    if (!(fullName in structMap)) {
        return null;
    }
    const struct = structMap[fullName];
    const np = struct.navigationProperties.find((item: any) => {
        return item.name === entity2;
    });
    if (!np) {
        return null;
    }
    let keys = np.foreignKeyNames;
    keys = keys.concat(np.invForeignKeyNames);
    if (!notEmpty(keys)) {
        return null;
    }
    return keys[0];
}

export function getPrimaryKeyName(manager: EntityManager, entityName: string): string {
    let pkName = null;
    const schema = manager.metadataStore;
    const entityType = schema.getEntityType(entityName) as EntityType;
    
    if (notEmpty(entityType.keyProperties)) {
        pkName = entityType.keyProperties[0].name;
    }
    return pkName;
}

/**
 * Returns object type number for the entity
 * @param entity
 * @constructor
 */
export function getEntityType<T>(entity: Entity<T>): number
{
    const entityName = entity.entityAspect.getKey().entityType.shortName;
    if (OBJECT_TYPE_MAPPING.hasOwnProperty(entityName.toLowerCase())) {
        return OBJECT_TYPE_MAPPING[entityName.toLowerCase()];
    }
    return 0;
}

export function entityHasType<T>(entity: Entity<T>, type: number) {
    const entityType = getEntityType(entity);
    return entityType === type;
}

/**
 * Get the data resource name for use in an EntityQuery.from() call.
 * E.g. name = Animal, resourceName = Animals
 * @param manager EntityManager
 * @param name table name
 */
export function  getResourceName(manager: EntityManager, name: string): string {

    // Accommodate alternate table names
    const alternateNames: any = {
        SourceMaterial: 'Material',
        MemberTaskInstance: 'TaskInstance',
        Compliance: 'cv_Compliance'
    };
    if (alternateNames.hasOwnProperty(name)) {
        name = alternateNames[name];
    } 

    const schema = manager.metadataStore;
    const shortMapProp = '_shortNameMap';
    const shortNameMap = schema[shortMapProp];
    if (!(name in shortNameMap)) {
        return null;
    }
    const fullName = shortNameMap[name];

    const structMapProp = '_structuralTypeMap';
    const structMap = schema[structMapProp];
    if (!(fullName in structMap)) {
        return null;
    }
    const struct = structMap[fullName];
    return struct.defaultResourceName;
}

/**
 * return items that have not been deleted
 * @param items
 * @param relationship 
 */
export function filterNotDeleted<T = any>(items: Entity<T>[], entityManager: EntityManager): T[] {
    if (!items) {
        return [];
    }
    
    return items.filter((item) => {
        // try looking up in cache to get correct entityState
        //   NOTE: We suspect entity state is sometimes 
        //     incorrectly Detached when there is an InverseProperty
        const actualEntity = _getEntityFromCache(item, entityManager);
        // otherwise fallback to original item
        item = actualEntity || item;
        const entityState = getSafeProp(item, 'entityAspect.entityState');
        if (!entityState) { 
            return true;
        } else {
            return entityState !== EntityState.Deleted &&
                entityState !== EntityState.Detached;
        }
    });
}

function _getEntityFromCache<T = any>(item: Entity<T>, entityManager: EntityManager): any {
    const typeName = getSafeProp(item, 'entityType.name');
    let key = null;
    const keyProps = getSafeProp(item, 'entityType.keyProperties');
    if (notEmpty(keyProps)) {
        const keyProp = getSafeProp(keyProps[0], 'name');
        key = getSafeProp(item, keyProp);
    }

    if (!typeName || !key) {
        return null;
    }
    return entityManager.getEntityByKey(typeName, key);
}

/**
 * return items where relationship has not been loaded yet
 * @param items
 * @param relationship 
 */
export function filterNotLoaded(items: any[], relationship: string): any[] {
    if (!items) {
        return [];
    }
    
    return items.filter((item) => {
        // do nothing if relationship is wrong
        //   and doesn't exist
        if (!(relationship in item)) {
            return;
        }
        return !isPropertyLoaded(item, relationship);
    });
}

export function isPropertyLoaded(item: any, property: string): boolean {
    return item.entityAspect.isNavigationPropertyLoaded(property);
}

export function isNavigationProperty(entity: Entity<any>, property: string): boolean {
    if (!entity) {
        return false;
    }
    const navProperty = entity.entityType?.getProperty(property);
    if (!navProperty) {
        return false;
    }
    return navProperty instanceof NavigationProperty;
}

/**
 * The following code removes the client side required validator on animal name.
 * These are generated on the server.
 *
 * @param metadataStore - breeze metadataStore
 */
export function removeValidators(metadataStore: MetadataStore) {
    _removeValidator(metadataStore, "Animal", "AnimalName", "required");
}

function _removeValidator(
    metadataStore: MetadataStore, 
    entityName: string, 
    propName: string, 
    validatorName: string
) {
    let entityType: any;
    try {
        entityType = metadataStore.getEntityType(entityName);
    } catch (e) {
        console.warn("Cannot remove validator for " + 
            entityName + 
            ": it does not exist in current MetadataStore"
        );

        return;
    }

    const property = entityType.getProperty(propName);
    const validators: any[] = property.validators;
    if (notEmpty(validators)) {
        let validatorIndex: number = null;
        for (let i = 0; i < validators.length; i++) {
            const validator = validators[i];
            const name: string = validator.context.name;
            if (name.toLowerCase() === validatorName.toLowerCase()) {
                validatorIndex = i;
            }
        }

        if (validatorIndex !== null) {
            validators.splice(validatorIndex, 1);
        }
    }
}

export const xCacheRefreshKey = '$x-cache-refresh';

/**
 * Use it to instantly get a previously saved response and then request
 * and save new data from the server. When the response from the server
 * returns, it will also be given to the consumer.
 *
 * @example ```ts
 *   const query = EntityQuery
 *     .from('Entity')
 *     .where(Predicate)
 *     .withParameters(xCacheRefresh); // <- here
 *   executeQuery(query);
 * ```
 */
export const xCacheRefresh = { [xCacheRefreshKey]: true };

/**
 * Advanced Options for saving data in Breeze. 
 * Additional properties include the facet name and operation ID associated with the save options.
 * @class FacetLevelSaveOptions
 * @extends SaveOptions
 */
export class FacetLevelSaveOptions extends SaveOptions {
    /**
     * The name of the facet for which save operation is executed.
     * @type {string}
     */
    facetName: string;

    /**
     * The ID of the user operation, represents logical transaction id.
     * @type {string}
     */
    operationId: string;

    /**
     * Reason for modification/deletion in facet
     * @type {string}
     */
    reasonForChange: string;
}

<ng-template #labelmodal let-c="close">
    <label-chooser [samples]="selectedRows"
                   (close)="c('Close')"></label-chooser>
</ng-template>

<div class="facet">
    <facet-header
        caption="Samples"
        [facetId]="facetId">
        <button *ngIf="facetView === LIST_VIEW"
                climbFacetAction
                (click)="openFilter()"
                title="Filter Samples">
            <svg [climbIcon]="icons.magnifier"></svg>
        </button>

        <button *ngIf="facetView === LIST_VIEW && isActiveWorkspaceFilterSupported()"
                climbFacetAction
                (click)="clickIgnoreWorkspaceFilter(); reloadTable();"
                [title]="ignoreWorkspaceFilter ? 'Apply Workspace Filter' : 'Ignore Workspace Filter'">
            <svg [climbIcon]="ignoreWorkspaceFilter ? icons.chain : icons.brokenChain"></svg>
        </button>

        <button data-automation-id="copy-samples-button"
                *ngIf="facetView === LIST_VIEW"
                climbFacetAction
                [disabled]="!selectedRows.length"
                (click)="copySamples()"
                title="Copy Samples">
            <svg [climbIcon]="icons.squareOnSquare"></svg>
        </button>

        <button data-automation-id="refresh-data-button"
                *ngIf="facetView === LIST_VIEW"
                climbFacetAction
                (click)="refreshData()"
                title="Refresh Data">
            <svg [climbIcon]="icons.arrowClockwise"></svg>
        </button>
    </facet-header>
    <div class="facet-body" *ngIf="facet.Privilege!=='None'">

        <!-- Define the screens this facet can view -->
        <loading-overlay *ngIf="loading" [message]="loadingMessage"></loading-overlay>

        <ng-container *ngIf="facetView === LIST_VIEW">
            <climb-facet-toolbar>
                <button climbCageCardButton
                        (click)="openLabelModal(labelmodal)"
                        [disabled]="selectedRows.length === 0"
                        title="Print label for selected samples"
                        ariaLabel="Print label"
                        appInsightsClick="click-sample-listview-labels">
                    Labels
                </button>

                <climb-bulk-dropdown *ngIf="facet.Privilege === 'ReadWrite'">
                    <button climbDropdownItem
                            (click)="changeView(BULK_ADD_VIEW)"
                            [appInsightsClick]="'click-bulk-add-' + componentName">
                        Add
                    </button>
                    <button climbDropdownItem
                            [disabled]="!selectedRows || selectedRows?.length < 1"
                            (click)="sendSelectedToBulkEdit()"
                            [appInsightsClick]="'click-bulk-edit-' + componentName">
                        Edit
                    </button>
                    <button climbDropdownItem
                            [disabled]="!selectedRows || selectedRows?.length < 1"
                            (click)="doBulkDelete()"
                            [appInsightsClick]="'click-bulk-delete-' + componentName">
                        Delete
                    </button>
                </climb-bulk-dropdown>

                <climb-export-dropdown #exportDropdown
                                       [expandExport]="true"
                                       [options]="sampleTableOptions.options"
                                       [dataService]="dataService"
                                       [componentName]="componentName">
                </climb-export-dropdown>

                <climb-column-select [columnDef]="dataTableColumns$ | async"
                                     (onChange)="dataTable.columnSelectChanged($event)">
                </climb-column-select>
            </climb-facet-toolbar>
            <climb-data-table #dataTable
                              [dataService]="dataService"
                              [options]="sampleTableOptions.options"
                              [(gridStateJson)]="facet.GridState"
                              [(selectedRows)]="selectedRows"
                              (selectedRowsChange)="selectedRowsChange($event)"
                              (selectedColumnsChange)="selectedColumnsChange($event)"
                              (dragStart)="dragStart()"
                              (dragStop)="dragStop()"
                              (addItemClick)="addItemClick()"
                              (detailLinkClick)="detailLinkClick($event)"
                              (columnStateChanged)="dataTableColumns.next($event)"
                              (stateChange)="exportDropdown.tableState = $event">
            </climb-data-table>
        </ng-container>

        <sample-detail *ngIf="facetView === DETAIL_VIEW"
                       [facet]="facet"
                       [sample]="itemToEdit"
                       [pageState]="detailPaginator.pageState"
                       [requiredFields]="requiredFields"
                       [activeFields]="activeFields"
                       (exit)="exitDetail()"
                       (previous)="detailPreviousClicked()"
                       (next)="detailNextClicked()"
                       (reload)="refreshData()"></sample-detail>

        <sample-bulk-add *ngIf="facetView === BULK_ADD_VIEW"
                         [facet]="facet"
                         [requiredFields]="requiredFields"
                         [activeFields]="activeFields"
                         (exit)="handleBulkAddExit($event)"></sample-bulk-add>

        <sample-bulk-edit *ngIf="facetView === BULK_EDIT_VIEW"
                          [facet]="facet"
                          [samples]="itemsToEdit"
                          [requiredFields]="requiredFields"
                          [activeFields]="activeFields"
                          (exit)="exitBulkEdit($event)"
                          #bulkEdit></sample-bulk-edit>

    </div>
</div>

<div class="detail-header">
    <h3>Location</h3>
    <div class="detail-toolbar">
        <button type="button"
                class="btn btn-secondary"
                (click)="cancelAdd()"
                [disabled]="facetPrivilege === 'ReadOnly'"
                *ngIf="position.C_LocationPosition_key < 0">
            <i class="fa fa-undo" aria-hidden="true"></i>
            Cancel Add
        </button>
    </div>
</div>

<form name="locationForm" id="locationForm" class="detail-form form-label-right h-100">
    <fieldset [disabled]="facetPrivilege==='ReadOnly'">
        <div class="form-group row">
            <label class="col-md-3 col-form-label">Active</label>
            <div class="col-md-9">
                <input type="checkbox"
                       id="positionIsActiveInput"
                       name="PositionIsActive"
                       [(ngModel)]="position.IsActive" />
            </div>
        </div>
        <div class="form-group row">
            <label class="col-md-3 col-form-label" for="positionNameInput">Name</label>
            <div class="col-md-5">
                <input type="text" id="positionNameInput"
                    name="PositionName"
                    [(ngModel)]="position.PositionName"
                       class="form-control" maxlength="18" required
                       [disabled]="noEdit"
                       title="{{ noEdit ? noEditMessage : null}}"/>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-md-3 col-form-label" for="parentSelect">Parent Location</label>
            <div class="col-md-5">
                <location-select [(model)]="position.C_ParentPosition_key"
                                 [disabled]="position.isChildOfRootNode || !originalParentPosition || noEdit"
                                 title="{{ noEdit ? noEditMessage : null}}"></location-select>
                <small class="form-text text-muted" *ngIf="position.isChildOfRootNode">
                    <i class="fa fa-info-circle" aria-hidden="true"></i>
                    Level 2 locations cannot be moved.
                </small>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-md-3 col-form-label">Type</label>
            <div class="col-md-5">
                <active-vocab-select [(model)]="position.C_LocationType_key"
                                     [vocabChoices]="locationTypes"
                                     [keyFormatter]="locationTypeKeyFormatter"
                                     [optionFormatter]="locationTypeFormatter"
                                     [nullable]="true"
                                     [readonly]="!originalParentPosition || noEdit"
                                     [tooltip]="noEdit ? noEditMessage : null"></active-vocab-select>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-md-3 col-form-label">Material Type</label>
            <div class="col-md-5">
                <active-vocab-select [(model)]="position.C_MaterialType_key"
                                     [vocabChoices]="materialTypes"
                                     [keyFormatter]="materialTypeKeyFormatter"
                                     [optionFormatter]="materialTypeFormatter"
                                     [nullable]="true"
                                     [readonly]="!originalParentPosition || noEdit"
                                     [tooltip]="noEdit ? noEditMessage : null"></active-vocab-select>
            </div>
        </div>
        <div class="form-group row" *ngIf="!isGLP"
            droppable
            parentDroppable>
            <label class="col-md-3 col-form-label">Devices</label>
            <div class="col-md-5">
                <device-multiselect [facetPrivilege]="facetPrivilege"
                                    [location]="position"
                                    [disabled]="!originalParentPosition"></device-multiselect>
            </div>
        </div>
        <div class="form-group row"
             *ngIf="position.materialStats?.TotalUnitsInLocation > 0 ||
                position.materialStats?.TotalAnimalsInLocation > 0 ||
                position.materialStats?.TotalSamplesInLocation > 0">
            <label class="col-md-3 col-form-label">Contents</label>
            <div class="col-md-9">
                <table class="table table-sm table-borderless table-nonfluid"
                    *ngIf="position.materialStats">
                    <tbody>
                        <tr *ngIf="position.materialStats.TotalUnitsInLocation > 0">
                            <td>Housing Units:</td>
                            <td class="num-cell">
                                {{position.materialStats.TotalUnitsInLocation}}
                            </td>
                        </tr>
                        <tr *ngIf="position.materialStats.TotalAnimalsInLocation > 0">
                            <td>Animals:</td>
                            <td class="num-cell">
                                {{position.materialStats.TotalAnimalsInLocation}}
                            </td>
                        </tr>
                        <tr *ngIf="position.materialStats.TotalSamplesInLocation > 0">
                            <td>Samples:</td>
                            <td class="num-cell">
                                {{position.materialStats.TotalSamplesInLocation}}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="form-group row" *ngIf="position.CreatedBy">
            <label class="col-md-3 col-form-label">Created</label>
            <div class="col-md-9">
                <p class="form-control-plaintext">
                    {{position.CreatedBy | userFullName}}
                    &middot;
                    {{position.DateCreated | formatShortDate}}
                </p>
            </div>
        </div>
        <div class="form-group row" *ngIf="position.ModifiedBy">
            <label class="col-md-3 col-form-label">Modified</label>
            <div class="col-md-9">
                <p class="form-control-plaintext">
                    {{position.ModifiedBy | userFullName}}
                    &middot;
                    {{position.DateModified | formatShortDate}}
                </p>
            </div>
        </div>
    </fieldset>
</form>

/**
 * Common functions for plate exports.
 */
import {
    getSafeProp,
    notEmpty
} from '../../common/util';


export function getAssayValue(material: any): string {
    const DELIMITER = ', ';
    let value = '';

    const line = material.Line;
    if (line && notEmpty(line.LineGenotypeAssay)) {
        const assays = line.LineGenotypeAssay.map((lineAssay: any) => {
            return lineAssay.cv_GenotypeAssay.GenotypeAssay;
        });
        value = assays.join(DELIMITER);
    }

    return value;
}

export function getLineValue(material: any): string {
    return getSafeProp(material.Line, 'StockNumber');
}

export function getLineNameValue(material: any): string {
    return getSafeProp(material.Line, 'LineName');
}

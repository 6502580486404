<!-- Output Table -->
<table class="table ngx-paginated-table"
       [id]="tableId"
       *ngIf="task">
    <thead>
        <tr class="detail-grid-header">
            <th [attr.colspan]="getHeaderColspan()">
                <a class="add-item-link detail-grid-button-label"
                   (click)="saveAndAddOutputSet()"
                   *ngIf="!readonly">
                    <i class="fa fa-plus-circle" title="Add output row"></i>
                </a>

                <span class="detail-grid-button-label">
                    Outputs
                    <span *ngIf="task.TaskOutputSet | notEmpty">
                        ({{task.TaskOutputSet.length}})
                    </span>
                </span>

                <span class="float-right">
                    <button type="button" class="btn btn-link"
                            (click)="clickedImportHandler();"
                            *ngIf="!readonly">
                        Import
                        <i class="fa fa-angle-double-down" aria-hidden="true"></i>
                    </button>
                </span>
            </th>
        </tr>
        <tr class="detail-grid-header">
            <!-- Materials -->
            <th *ngIf="task?.showMaterialOutputColumn !== false">Animals/Samples</th>

            <!-- Single-Animal Properties -->
            <ng-container *ngIf="task.WorkflowTask?.SingleAnimal">
                <th *ngIf="task.WorkflowTask?.ShowBirthDate"
                    class="text-nowrap">
                    Birth Date
                </th>
                <th *ngIf="task.WorkflowTask?.ShowAgeInWeeks"
                    class="text-nowrap">
                    Age (weeks)
                    <info-tooltip [text]="'Age in weeks on the collection date.'"></info-tooltip>
                </th>
                <th *ngIf="task.WorkflowTask?.ShowAgeInDays"
                    class="text-nowrap">
                    Age (days)
                    <info-tooltip [text]="'Age in days on the collection date.'"></info-tooltip>
                </th>
                <th *ngIf="task.WorkflowTask?.ShowMarker"
                    class="text-nowrap">
                    Marker
                </th>
                <th *ngIf="task.WorkflowTask?.ShowAnimalStatus"
                    class="text-nowrap">
                    Animal Status
                </th>
                <th *ngIf="task.WorkflowTask?.ShowHousingID"
                    class="text-nowrap">
                    Housing ID
                </th>
                <th *ngIf="task.WorkflowTask?.ShowAnimalComments"
                    class="text-nowrap">
                    Animal Comments
                </th>
            </ng-container>

            <!-- Column for each TaskOutput -->
            <th *ngFor="let workflowTaskOutput of workflowTaskOutputs | orderBy:'SortOrder'">
                    <ng-container *ngIf="!workflowTaskOutput.IsActive">
                        <i class="fa fa-exclamation-triangle inactive-column" aria-hidden="true" title="This output has been deactivated"></i>
                    </ng-container>
                    <div *ngIf="workflowTaskOutput.cv_DataType?.DataType !== DataType.CALCULATED &&
                                workflowTaskOutput.cv_DataType?.DataType !== DataType.INHERITED_MOST_RECENT &&
                                workflowTaskOutput.cv_DataType?.DataType !== DataType.INHERITED_FIRST_OCCURRENCE;
                                else normalHeaderTmpl" style="display: inline-block">
                        <bulk-edit-header [readonly]="readonly"
                                          [headerText]="workflowTaskOutput.OutputName"
                                          (updateClicked)="bulkOutputChanged(workflowTaskOutput)">
                            <div modal-content>
                                <data-type-input [ioObject]="workflowTaskOutput"
                                                 [readonly]="readonly"
                                                 [(value)]="bulkOutputValue"></data-type-input>
                            </div>
                        </bulk-edit-header>
                    </div>
                    <ng-template #normalHeaderTmpl>
                        {{workflowTaskOutput?.OutputName}}
                    </ng-template>
            </th>

            <!-- Actions -->
            <th colspan="2"
                class="text-right"
                *ngIf="!readonly">
                <!-- Delete All -->
                <button type="button"
                        class="btn btn-secondary remove-item"
                        (click)="deleteAllTaskOutputSets()"
                        [disabled]="deletingOutputSets"
                        *ngIf="task.TaskOutputSet | notEmpty">
                    <ng-container *ngIf="!deletingOutputSets; else deletingIndicator">
                        <i class="fa fa-remove" aria-hidden="true" title="Delete all output rows"></i>
                        All
                    </ng-container>
                    <ng-template #deletingIndicator>
                        <i class="fa fa-spinner fa-spin" title="Deleting&hellip;"></i>
                    </ng-template>
                </button>
            </th>

            <!-- Audit -->
            <th class="disabled-control">Collected Date</th>
            <th class="disabled-control">Collected By</th>
        </tr>
    </thead>

    <tbody>
        <ng-container *ngFor="let taskOutputSet of task.TaskOutputSet |
            orderBy: 'DateCreated':true |
            paginate: { itemsPerPage: 20, currentPage: task.outputPage, id: paginationId }; let index = index">

            <ng-template [ngTemplateOutlet]="editRow"
                         [ngTemplateOutletContext]="{taskOutputSet: taskOutputSet, index: index}"
                         *ngIf="!readonly; else viewRowTemplate"></ng-template>

            <ng-template #viewRowTemplate>
                <ng-template [ngTemplateOutlet]="viewRow"
                             [ngTemplateOutletContext]="{taskOutputSet: taskOutputSet, index: index}"></ng-template>
            </ng-template>

        </ng-container>
    </tbody>
</table>

<pagination-controls [id]="paginationId"
                     (pageChange)="task.outputPage = $event"
                     [autoHide]="true"
                     class="ngx-table-paginator"></pagination-controls>


<!-- Edit Row Template -->
<ng-template #editRow
             let-taskOutputSet="taskOutputSet"
             let-index="index">
    <tr droppable
        [dropDataKey]="taskOutputSet.C_TaskOutputSet_key"
        [dropDisabled]="readonly"
        (drop)="onDropMaterialToOutput($event)"
        class="edit-row">

        <!-- Materials -->
        <td class="text-nowrap" *ngIf="taskOutputSet?.TaskInstance?.showMaterialOutputColumn !== false">
            <task-output-set-material-select [singleMaterialOnly]="task.WorkflowTask?.SingleAnimal"
                                             [taskOutputSet]="taskOutputSet"
                                             [primarySamples]="primarySamples"
                                             [fieldName]="MATERIAL_SELECT_FIELD_NAME_ROOT + index"
                                             [task]="task"
                                             [readonly]="readonly || task.WorkflowTask?.cv_TaskType?.TaskType === TaskType.Animal"
                                             (selectionChange)="onMaterialChange(taskOutputSet, $event)"></task-output-set-material-select>
        </td>

        <!-- Single-Animal Properties -->
        <ng-container *ngIf="task.WorkflowTask?.SingleAnimal">
            <td *ngIf="task.WorkflowTask?.ShowBirthDate">
                <ng-template [ngTemplateOutlet]="birthDateColumn"
                             [ngTemplateOutletContext]="{taskOutputSet: taskOutputSet, readOnly: false}"></ng-template>
            </td>
            <td *ngIf="task.WorkflowTask?.ShowAgeInWeeks">
                <ng-template [ngTemplateOutlet]="ageInWeeksColumn"
                             [ngTemplateOutletContext]="{taskOutputSet: taskOutputSet, readOnly: false}"></ng-template>
            </td>
            <td *ngIf="task.WorkflowTask?.ShowAgeInDays">
                <ng-template [ngTemplateOutlet]="ageInDaysColumn"
                             [ngTemplateOutletContext]="{taskOutputSet: taskOutputSet, readOnly: false}"></ng-template>
            </td>
            <td *ngIf="task.WorkflowTask?.ShowMarker">
                <ng-template [ngTemplateOutlet]="markerColumn"
                             [ngTemplateOutletContext]="{taskOutputSet: taskOutputSet, readOnly: false}"></ng-template>
            </td>
            <td *ngIf="task.WorkflowTask?.ShowAnimalStatus">
                <ng-template [ngTemplateOutlet]="animalStatusColumn"
                             [ngTemplateOutletContext]="{taskOutputSet: taskOutputSet, readOnly: false}"></ng-template>
            </td>
            <td *ngIf="task.WorkflowTask?.ShowHousingID">
                <ng-template [ngTemplateOutlet]="housingIDColumn"
                             [ngTemplateOutletContext]="{taskOutputSet: taskOutputSet, readOnly: false}"></ng-template>
            </td>
            <td *ngIf="task.WorkflowTask?.ShowAnimalComments">
                <ng-template [ngTemplateOutlet]="animalCommentsColumn"
                             [ngTemplateOutletContext]="{taskOutputSet: taskOutputSet, readOnly: false, index: index}"></ng-template>
            </td>
        </ng-container>

        <!-- Column for each TaskOutput -->
        <td *ngFor="let taskOutput of taskOutputSet.TaskOutput | orderBy:'Output.SortOrder'">
            <div style="display: flex; align-items: center;">
                <data-type-input *ngIf="taskOutput?.Output?.cv_DataType?.DataType !== DataType.NUMBER"
                                 [ioObject]="taskOutput.Output"
                                 [readonly]="facetPrivilege === 'ReadOnly'"
                                 [(value)]="taskOutput.OutputValue"></data-type-input>
                <!-- Numeric output changes trigger recalculations -->
                <data-type-input *ngIf="taskOutput?.Output?.cv_DataType?.DataType === DataType.NUMBER"
                                 [ioObject]="taskOutput.Output"
                                 [readonly]="facetPrivilege === 'ReadOnly'"
                                 [(value)]="taskOutput.OutputValue"
                                 (valueChange)="recalculateValues(taskOutputSet)"></data-type-input>
                <i *ngIf="taskOutput.HasFlag"
                   class="fa fa-flag text-danger" style="padding-left: 2px;" aria-hidden="true"></i>
            </div>
            <em class="text-danger" *ngIf="taskOutput.HasFlag">
                {{taskOutput.FlagsMessages}}
            </em>
        </td>

        <!-- Actions -->
        <td class="col-size-fit">
            <button type="button"
                    class="btn btn-link btn-secondary add-item-link"
                    [disabled]="savingOutputSet"
                    (click)="saveAndAddOutputSet()">
                <i class="fa fa-plus-circle" title="Save then add output row"></i>
            </button>
        </td>
        <td class="col-size-fit">
            <button type="button"
                    class="btn btn-link btn-secondary add-item-link"
                    (click)="deleteTaskOutputSet(taskOutputSet)">
                <i class="fa fa-remove remove-item" title="Delete output row"></i>
            </button>
        </td>

        <!-- Audit -->
        <td class="disabled-control">
            <climb-ngb-date #dateControl="ngModel"
                            [(ngModel)]="taskOutputSet.CollectionDateTime"
                            [ngModelOptions]="{ standalone: true }"
                            class="disabled-control"></climb-ngb-date>
        </td>
        <td class="disabled-control">
            <active-vocab-select [(model)]="taskOutputSet.C_Resource_key"
                                 [vocabChoices]="resources"
                                 [keyFormatter]="resourceKeyFormatter"
                                 [optionFormatter]="resourceNameFormatter"
                                 [nullable]="true"></active-vocab-select>
        </td>

    </tr>
</ng-template>


<!-- Readonly Row Template -->
<ng-template #viewRow
             let-index="index"
             let-taskOutputSet="taskOutputSet">
    <tr class="view-row">

        <!-- Materials -->
        <td class="text-nowrap" *ngIf="taskOutputSet?.TaskInstance?.showMaterialOutputColumn !== false">
            <ul class="list-unstyled">
                <li *ngFor="let material of taskOutputSet.TaskOutputSetMaterial">
                    <ng-container *ngIf="material.Material.Animal">
                        <img src="/images/animals.png"
                             width="12" height="12"
                             alt="Animal" title="Animal" />
                        {{material?.Material?.Animal?.AnimalName}}
                    </ng-container>
                    <ng-container *ngIf="material.Material.Sample && (primarySamples.indexOf(material.Material.Sample) > -1)">
                        <img src="/images/samples.png"
                             width="12" height="12"
                             alt="Sample" title="Sample" />
                        {{material?.Material?.Sample?.SampleName}}
                    </ng-container>
                </li>
            </ul>
        </td>

        <!-- Single-Animal Properties -->
        <ng-container *ngIf="task.WorkflowTask?.SingleAnimal">
            <td *ngIf="task.WorkflowTask?.ShowBirthDate">
                <ng-template [ngTemplateOutlet]="birthDateColumn"
                             [ngTemplateOutletContext]="{taskOutputSet: taskOutputSet, readOnly: true}"></ng-template>
            </td>
            <td *ngIf="task.WorkflowTask?.ShowAgeInWeeks">
                <ng-template [ngTemplateOutlet]="ageInWeeksColumn"
                             [ngTemplateOutletContext]="{taskOutputSet: taskOutputSet, readOnly: true}"></ng-template>
            </td>
            <td *ngIf="task.WorkflowTask?.ShowAgeInDays">
                <ng-template [ngTemplateOutlet]="ageInDaysColumn"
                             [ngTemplateOutletContext]="{taskOutputSet: taskOutputSet, readOnly: true}"></ng-template>
            </td>
            <td *ngIf="task.WorkflowTask?.ShowMarker">
                <ng-template [ngTemplateOutlet]="markerColumn"
                             [ngTemplateOutletContext]="{taskOutputSet: taskOutputSet, readOnly: true}"></ng-template>
            </td>
            <td *ngIf="task.WorkflowTask?.ShowAnimalStatus">
                <ng-template [ngTemplateOutlet]="animalStatusColumn"
                             [ngTemplateOutletContext]="{taskOutputSet: taskOutputSet, readOnly: true}"></ng-template>
            </td>
            <td *ngIf="task.WorkflowTask?.ShowHousingID">
                <ng-template [ngTemplateOutlet]="housingIDColumn"
                             [ngTemplateOutletContext]="{taskOutputSet: taskOutputSet, readOnly: true}"></ng-template>
            </td>
            <td *ngIf="task.WorkflowTask?.ShowAnimalComments">
                <ng-template [ngTemplateOutlet]="animalCommentsColumn"
                             [ngTemplateOutletContext]="{taskOutputSet: taskOutputSet, readOnly: true, index: index}"></ng-template>
            </td>
        </ng-container>

        <!-- Column for each TaskOutput -->
        <td *ngFor="let output of workflowTaskOutputs | orderBy:'SortOrder'"
            [ngClass]="getOutputValueCellClass(output)">
            {{getOutputValue(taskOutputSet.TaskOutput, output.C_Output_key)}}
        </td>

        <!-- Audit -->
        <td class="disabled-control">
            {{taskOutputSet.CollectionDateTime | formatShortDate}}
        </td>
        <td class="disabled-control">
            {{taskOutputSet.Resource?.ResourceName}}
        </td>
    </tr>
</ng-template>


<!-- Birth Date Column Template -->
<ng-template #birthDateColumn
             let-taskOutputSet="taskOutputSet"
             let-readOnly="readOnly">
    <p [ngClass]="{'form-control-plaintext': !readOnly}">
        <ng-container *ngIf="taskOutputSet.TaskOutputSetMaterial?.length > 0 &&
        taskOutputSet.TaskOutputSetMaterial[0].Material?.Animal?.DateBorn">
            {{taskOutputSet.TaskOutputSetMaterial[0].Material.Animal.DateBorn | formatShortDate}}
        </ng-container>
    </p>
</ng-template>

<!-- Age In Weeks Column Template -->
<ng-template #ageInWeeksColumn
             let-taskOutputSet="taskOutputSet"
             let-readOnly="readOnly">
    <p [ngClass]="{'form-control-plaintext': !readOnly}">
        <ng-container *ngIf="taskOutputSet.TaskOutputSetMaterial?.length > 0 &&
        taskOutputSet.TaskOutputSetMaterial[0].Material?.Animal?.DateBorn &&
        taskOutputSet.CollectionDateTime">
            {{taskOutputSet.CollectionDateTime | weeksSince:taskOutputSet.TaskOutputSetMaterial[0].Material.Animal.DateBorn}}
        </ng-container>
    </p>
</ng-template>

<!-- Age In Days Column Template -->
<ng-template #ageInDaysColumn
             let-taskOutputSet="taskOutputSet"
             let-readOnly="readOnly">
    <p [ngClass]="{'form-control-plaintext': !readOnly}">
        <ng-container *ngIf="taskOutputSet.TaskOutputSetMaterial?.length > 0 &&
        taskOutputSet.TaskOutputSetMaterial[0].Material?.Animal?.DateBorn &&
        taskOutputSet.CollectionDateTime">
            {{taskOutputSet.CollectionDateTime | daysSince:taskOutputSet.TaskOutputSetMaterial[0].Material.Animal.DateBorn}}
        </ng-container>
    </p>
</ng-template>

<!-- Marker Column Template -->
<ng-template #markerColumn
             let-taskOutputSet="taskOutputSet"
             let-readOnly="readOnly">
    <p [ngClass]="{'form-control-plaintext': !readOnly}">
        <ng-container *ngIf="taskOutputSet.TaskOutputSetMaterial?.length > 0">
            <animal-marker [marker]="taskOutputSet.TaskOutputSetMaterial[0]?.Material?.Animal?.PhysicalMarker"
                           [markerType]="taskOutputSet.TaskOutputSetMaterial[0]?.Material?.Animal?.cv_PhysicalMarkerType">
            </animal-marker>
        </ng-container>
    </p>
</ng-template>

<!-- Animal Status Column Template -->
<ng-template #animalStatusColumn
             let-taskOutputSet="taskOutputSet"
             let-readOnly="readOnly">
    <p [ngClass]="{'form-control-plaintext': !readOnly}">
        <ng-container *ngIf="taskOutputSet.TaskOutputSetMaterial?.length > 0">
            {{taskOutputSet.TaskOutputSetMaterial[0]?.Material?.Animal?.cv_AnimalStatus?.AnimalStatus}}
        </ng-container>
    </p>
</ng-template>

<!-- HousingID Column Template -->
<ng-template #housingIDColumn
             let-taskOutputSet="taskOutputSet" let-readOnly="readOnly">
    <p [ngClass]="{'form-control-plaintext': !readOnly}">
        <ng-container *ngIf="taskOutputSet.TaskOutputSetMaterial?.length > 0">
            {{taskOutputSet.TaskOutputSetMaterial[0]?.Material?.MaterialPoolMaterial | currentMaterialHousingID}}
        </ng-container>
    </p>
</ng-template>

<!-- Animal Comments Column Template -->
<ng-template #animalCommentsColumn
             let-taskOutputSet="taskOutputSet"
             let-readOnly="readOnly"
             let-index="index">
    <ng-container *ngIf="taskOutputSet.TaskOutputSetMaterial?.length > 0 &&
                        taskOutputSet.TaskOutputSetMaterial[0].Material?.Animal">
        <ng-container *ngIf="!readOnly; else readOnlyView">
            <textarea name="animalComments-{{index}}"
                      [(ngModel)]="taskOutputSet.TaskOutputSetMaterial[0].Material.Animal.Comments"
                      class="form-control input-large"
                      maxlength="1000"
                      rows="1"></textarea>
        </ng-container>
        <ng-template #readOnlyView>
            <!-- Keep on one line to style correctly -->
            <p class="note-text">{{taskOutputSet.TaskOutputSetMaterial[0]?.Material?.Animal?.Comments}}</p>
        </ng-template>
    </ng-container>
</ng-template>

import {
    Component
} from "@angular/core";
import { ICellRendererParams } from "ag-grid-community";
import { ICellRendererAngularComp } from "ag-grid-angular";

@Component({
    selector: 'color-box-cell',
    template: `
    <span *ngIf="params && params.value">
        <climb-color-box [backgroundColor]="params.value"
          [height]="'17px'"></climb-color-box>
    </span>
    `
})
export class ColorBoxCellRendererComponent implements ICellRendererAngularComp {
    public params: ICellRendererParams;

    agInit(params: ICellRendererParams): void {
        this.params = params;
    }

    /**
     * Required by ICellRenderedAngularComp.
     *   Called on cell refresh by ag-grid
     * @param params
     */
    refresh(params: ICellRendererParams): boolean {
        this.params = params;
        return true;
    }
}

import { UniqueItem } from '../../common/models';
export class AnimalSummaryItem implements UniqueItem {

    cohortName: string;
    sex: string;
    strain: string;
    
    /**
     * Enable field initializer
     *   E.g. new PageState({ field1: 'value', ...})
     * @param init
     */
    public constructor(init?: Partial<AnimalSummaryItem>) {
        Object.assign(this, init);
    }

    get uniqueKey(): string {
        return [this.cohortName, this.sex, this.strain].join('--');
    }
}

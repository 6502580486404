import { FormatterOptions } from '../data-table.interface';

/*
* format data table cells with custom formatter
* 
* A FormatterOptions object is passed into any custom formatter, with additional
*   post-processing flags that can be enabled/disabled
*
* All output is HTML escaped by default
*/
export function formatCell(
    row: any,
    value: any,
    formatter: (row: any, value: any, formatterOptions: FormatterOptions) => string
) {
    if (!value && 
        value !== 0 &&
        !formatter
    ) {
        return '';
    }
    
    if (!formatter) {
        return '' + _escapeHtml(value);
    }

    let formattedValue = '';
    const formatterOptions: FormatterOptions = { escapeHTML: true };

    try {
        formattedValue = formatter(row, value, formatterOptions);
    } catch (e) {
        // Do Nothing
    }

    if (formatterOptions.escapeHTML) {
        formattedValue = _escapeHtml(formattedValue);
    }

    return formattedValue;
}

const entityMap = {
    '<': '‹',
    '>': '›',
};
  
function _escapeHtml(value: string) {
    return String(value).replace(/[<>]/g, (s) => {
        return entityMap[s];
    });
}

import { WorkflowService } from '../../services/workflow.service';
import {
    Component,
    Input,
    OnInit,
    OnChanges
} from '@angular/core';


@Component({
    selector: 'workflow-health-record-details',
    templateUrl: './workflow-health-record-details.component.html',
})
export class WorkflowHealthRecordDetailsComponent implements OnInit, OnChanges {
    @Input() healthRecord: any;
    @Input() useTableFormat: boolean;

    constructor(
        private workflowService: WorkflowService
    ) { }

    ngOnInit() {
        this.initialize();
    }

    ngOnChanges(changes: any) {
        if (changes.healthRecord && !changes.healthRecord.firstChange) {
            this.getHealthRecordDetails();
        }
    }
    
    
    initialize() {
        this.getHealthRecordDetails();
    }

    getHealthRecordDetails(): Promise<any> {
        if (!this.healthRecord) {
            return Promise.resolve();
        }
        
        const expands = [
            'Animal.Material.Line'
        ];
        return this.workflowService.ensureRelationshipsLoaded([this.healthRecord], expands);
    }
}

<div class="wizard-body">

    <h4 class="wizard-heading">
        Let's choose the <span class="data-type">animal {{'Lines' | translate}}</span> you will use.
    </h4>

    <div class="intro-text">
        <p>
            We have listed some commonly used {{'Lines' | translate}}, but you may add your own.
        </p>

        <p>
            Once your workgroup is set up, you will be able to modify your available {{'Lines' | translate}} as well as import large quantities of line data.
        </p>
    </div>

    <form>
        <div class="taxon-lines"
             *ngFor="let taxon of selectedTaxa; let taxonIndex = index">
            <h4>
                {{taxon.cvValue}} {{'Lines' | translate}}
                <span class="small"
                      *ngIf="duplicateLines()">
                    <span class="text-danger">
                        <i class="fa fa-2x fa-exclamation-triangle" aria-hidden="true"></i>
                        {{'Lines' | translate}} must be unique.
                    </span>
                </span>
            </h4>

            <table class="table table-borderless" id="{{TABLE_ID_ROOT}}{{taxonIndex}}">
                <thead>
                    <tr>
                        <th></th>
                        <th>Name</th>
                        <th>{{'LineShortName' | translate}}</th>
                        <th>Type</th>
                    </tr>
                </thead>

                <tfoot>
                    <tr *ngIf="!(selectedLines | lineTaxonFilter:taxon.cvValue | notEmpty)">
                        <td colspan="4" class="text-danger">
                            <i class="fa fa-2x fa-exclamation-triangle" aria-hidden="true"></i>
                            Please provide at least one {{'Line' | translate}}.
                        </td>
                    </tr>

                    <tr>
                        <td colspan="4">
                            <button type="button"
                                    class="btn btn-secondary add-item-link"
                                    (click)="addNewLine(taxon, taxonIndex)">
                                <i class="fa fa-plus-circle" aria-hidden="true"></i>
                                Add {{'Line' | translate}}
                            </button>
                        </td>
                    </tr>
                </tfoot>

                <tbody>
                    <tr *ngFor="let selectedLine of selectedLines |
                                lineTaxonFilter:taxon.cvValue;
                                let lineIndex = index">
                        <td class="icon-cell">
                            <div class="form-control-plaintext">
                                <a (click)="removeLine(selectedLine)">
                                    <i class="fa fa-remove remove-item" title="Remove {{'Line' | translate}}"></i>
                                </a>
                            </div>
                        </td>
                        <td>
                            <input type="text"
                                   class="form-control"
                                   name="selectedLineName-{{taxonIndex}}-{{lineIndex}}"
                                   placeholder="{{'Line' | translate}} Name&hellip;"
                                   required
                                   [(ngModel)]="selectedLine.lineName"
                                   (ngModelChange)="onModelChange()" />
                        </td>
                        <td>
                            <input type="text"
                                   class="form-control"
                                   name="selectedLineShortName-{{taxonIndex}}-{{lineIndex}}"
                                   placeholder="{{'Line' | translate}} {{'LineShortName' | translate}}&hellip;"
                                   [(ngModel)]="selectedLine.stockNumber"
                                   (ngModelChange)="onModelChange()" />

                        </td>
                        <td>
                            <select class="form-control"
                                    name="selectedLineType-{{taxonIndex}}-{{lineIndex}}"
                                    [(ngModel)]="selectedLine.lineType"
                                    (ngModelChange)="onModelChange()">
                                <option *ngFor="let lineType of selectedLineTypes"
                                        [value]="lineType.cvValue">
                                    {{lineType.cvValue}}
                                </option>
                            </select>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </form>

</div>

<form novalidate>
    <div class="modal-header justify-content-center">
        <h4 class="modal-title" *ngIf="scheduleNonTask?.C_ScheduleNonTask_key <= 0">
            Create new appointment
        </h4>
        <h4 class="modal-title" *ngIf="scheduleNonTask?.C_ScheduleNonTask_key > 0">
            Edit appointment
        </h4>
    </div>

    <div class="modal-body form-label-right" *ngIf="scheduleNonTask">

        <fieldset [disabled]="facet.Privilege === 'ReadOnly'">

            <div class="form-group row">
                <label class="col-md-2 col-form-label">Title</label>
                <div class="col-md-10">
                    <input type="text"
                           data-auto-focus="appointmentTitle"
                           [required]="true"
                           [name]="'appointmentTitle' + domIdAddition"
                           [(ngModel)]="scheduleNonTask.Description"
                           class="form-control input-medium" />
                </div>
            </div>

            <div class="form-group row">
                <label class="col-md-2 col-form-label">Start Time</label>
                <div class="col-md-6">
                    <climb-ngb-date #dateControl="ngModel"
                                    name="Start Time"
                                    [(ngModel)]="scheduleNonTask.DateTimeStart"
                                    [required]="true"
                                    [disabled]="facet.Privilege === 'ReadOnly'"
                                    [allowTime]="true"></climb-ngb-date>
                </div>
            </div>

            <div class="form-group row">
                <label class="col-md-2 col-form-label">End Time</label>
                <div class="col-md-6">
                    <climb-ngb-date #dateControl="ngModel"
                                    name="End Time"
                                    [(ngModel)]="scheduleNonTask.DateTimeComplete"
                                    [required]="true"
                                    [disabled]="facet.Privilege === 'ReadOnly'"
                                    [allowTime]="true"></climb-ngb-date>
                </div>
            </div>

            <div class="form-group row">
                <label class="col-md-2 col-form-label">Resource</label>
                <div class="col-md-10">
                    <active-vocab-select [(model)]="scheduleNonTask.C_Resource_key"
                                        [vocabChoices]="resources"
                                        [keyFormatter]="resourceKeyFormatter"
                                        [optionFormatter]="resourceNameFormatter"
                                        [nullable]="true"
                                        [readonly]="facet.Privilege === 'ReadOnly'"></active-vocab-select>
                </div>
            </div>
            <div class="form-group row"
                    *ngIf="scheduleNonTask.CreatedBy">
                <label class="col-md-2 col-form-label">Created</label>
                <div class="col-md-10">
                    <p class="form-control-plaintext">
                        {{scheduleNonTask.CreatedBy | userFullName}}
                        &middot;
                        {{scheduleNonTask.DateCreated | formatShortDate}}
                    </p>
                </div>
            </div>
        </fieldset>

    </div><!-- /.modal-body -->

    <div class="modal-footer">
        <button type="button"
                *ngIf="facet.Privilege !== 'ReadOnly'"
                class="btn btn-fw btn-lg btn-primary"
                [disabled]="saving"
                (click)="onSubmit()">
            Save
            <span *ngIf="saving">
                <i class="fa fa-spinner fa-spin" title="Saving&hellip;"></i>
            </span>
        </button>
        <button type="button"
                class="btn btn-fw btn-lg btn-secondary"
                (click)="onClose()">
            Close
        </button>
    </div>
</form>

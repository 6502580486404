/**
 * Retrieve the specified parameter (by name) value
 *   from a url
 * @param paramName 
 * @param url 
 */
export function getParameterByName(paramName: string, url?: string): string {
    if (!url) {
        url = window.location.href;
    }

    paramName = paramName.replace(/[\[\]]/g, "\\$&");
    const regex = new RegExp("[?&;]" + paramName + "(=([^&#]*)|&|#|$)");
    const results = regex.exec(url);

    if (!results || results.length < 3) {
        return '';
    }

    return decodeURIComponent(results[2].replace(/\+/g, " "));
}

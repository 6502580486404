import { Pipe, PipeTransform } from "@angular/core";
import { TaxonCharacteristicInstance } from "../types";

@Pipe({
    name: "characteristicsWithoutListView"
})
export class CharacteristicsWithoutListViewPipe implements PipeTransform {
    transform(value: TaxonCharacteristicInstance[], listViewCharacteristicNames: string[]): TaxonCharacteristicInstance[] {
        if (!value || value.length === 0) {
            return [];
        }
        if (!listViewCharacteristicNames || listViewCharacteristicNames.length === 0) {
            return value;
        }
        return value.filter((tci: TaxonCharacteristicInstance) => {
            return !listViewCharacteristicNames.includes(tci.CharacteristicName);
        });
    }
}

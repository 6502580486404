import {
    Component,
    EventEmitter,
    Input,
    Output
} from '@angular/core';

import { MaterialPoolService } from '../../../services/material-pool.service';

@Component({
    selector: 'housing-select',
    template: `
    <div class="housing-select">
        <climb-typeahead
            [(model)]="model"
            [resultFormatter]="housingIdFormatter"
            [keyFormatter]="housingKeyFormatter"
            [search]="searchHousingUnits"
            [keySearch]="searchHousingUnitsByKey"  
            [disabled]="disabled"
            [required]="required"
            [namespace]="'housing'"
            (selectItem)="selectItemHandler($event)"
            #typeahead
        ></climb-typeahead>
    </div>
    `,
    styles: [`
        .housing-select {
            min-width: 100px;
        }
    `]
})
export class HousingSelectComponent {
    @Input() model: any;
    @Output() modelChange: EventEmitter<any> = new EventEmitter<any>();

    /*
     * Don't include any MaterialPools that have Mating data
     */
    @Input() excludeMatingUnits: boolean;
    @Input() disabled: boolean;
    @Input() required: boolean;

    constructor(
        private materialPoolService: MaterialPoolService
    ) {
        // nothing to do
    }

    selectItemHandler(item: any) {
        this.modelChange.emit(this.model);
    }

    searchHousingUnits = (search: string): Promise<any[]> => {

        const filter = {
            MaterialPoolID: search,
            ExcludeMatingPools: this.excludeMatingUnits
        };


        const count = 50;

        return this._searchHousingUnits(filter, count);
    }

    searchHousingUnitsByKey = (key: any): Promise<any[]> => {
        const filter = {
            C_MaterialPool_key: key
        };
        return this._searchHousingUnits(filter, 1);
    }

    _searchHousingUnits(filter: any, count: number): Promise<any[]> {
        return this.materialPoolService.getMaterialPools({
            page: 1,
            size: count,
            sort: 'MaterialPoolID asc',
            filter
        }).then((response) => {
            return response.results;
        });
    }

    housingIdFormatter = (item: any) => {
        return item.MaterialPoolID;
    }

    housingKeyFormatter = (item: any) => {
        return item.C_MaterialPool_key;
    }
}

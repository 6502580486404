import {
    Component,
    Input,
    OnInit,
    QueryList,
    ViewChildren
} from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import {
    sortObjectArrayByProperty, uniqueArrayFromPropertyPath
} from '../common/util';

import { VocabularyService } from '../vocabularies/vocabulary.service';
import { ResourceService } from '../resources/resource.service';

import { TaskCompleteButtonComponent } from './task-complete-button.component';
import { ColumnSelect, ColumnSelectLabel } from '@common/facet';
import { BooleanMap } from '../workflow/models/workflow-bulk-data';
import { SaveChangesService } from '../services/save-changes.service';
import { TaskService } from './task.service';
import { DataManagerService } from '../services/data-manager.service';
import { NgModel } from '@angular/forms';
import { dateControlValidator } from '@common/util/date-control.validator';
import { LoggingService } from '@services/logging.service';

@Component({
    selector: 'edit-tasks',
    templateUrl: './edit-tasks.component.html',
})
export class EditTasksComponent implements OnInit {
    @ViewChildren('dateControl') dateControls: NgModel[];
    @Input() job: any;
    @Input() tasks: any[];
    @Input() title: string;
    @Input() readonly: boolean;

    @ViewChildren(TaskCompleteButtonComponent)
    completeButtons: QueryList<TaskCompleteButtonComponent>;

    fillDownTaskStatusKey: number = null;
    defaultEndState: number = null;
    taskStatuses: any[] = [];
    endStateKeys: any[] = [];
    resources: any[] = [];
    currentResource: any;
    taskPage = 1;

    // Column Select state
    columnSelect: ColumnSelect = { model: [], labels: [] };

    // Table column visibility flags
    visible: BooleanMap = {};
    // Bulk filldown placeholders
    bulk: any = {
        dateDueTaskAlias: "null",
        dateDueTaskAliases: [],
        dateDueTask: "null",
        dateDueTasks: [],
        dateDueCohort: "null",
        dateDueCohorts: [],
        dateDueAnimal: "null",
        dateDueAnimals: [],
        dateDue: new Date(),

        completed: false,
        completedByKey: null,
        completedTime: null,

        assignedTo: null,
    };

    constructor(
        public activeModal: NgbActiveModal,
        private vocabularyService: VocabularyService,
        private resourceService: ResourceService,
        private saveChangesService: SaveChangesService,
        private taskService: TaskService,
        private dataManager: DataManagerService,
        private loggingService: LoggingService,
    ) { /* do nothing */ }

    ngOnInit() {
        // Initialize the column selection and visibility flags.
        this.initColumnSelect();
        this.tasks = sortObjectArrayByProperty(this.tasks.slice(), 'Sequence');
        this.getCohorts();
        const seen: BooleanMap = {};
        this.bulk.dateDueTasks = this.tasks.filter((task: any) => {
            const key = task.WorkflowTask.TaskName;
            if (seen[key]) {
                // Already added tasks with this name
                return false;
            }

            // Remember this task
            seen[key] = true;

            return true;
        }).map<string>((task: any) => task.WorkflowTask.TaskName);
        if (this.bulk.dateDueTasks.length > 1) {
            this.bulk.dateDueTask = "all-tasks-selection";
        }

        this.initCVs().then(() => {    
            return this.initResource();
        });
    }

    /**
     * Initialize the column selection and visibility flags.
     */
    initColumnSelect() {
        // Assemble the list of all columns that can be selected
        const labels = [
            // Use [] to note columns that can be selected but are not
            // currently visible because the data is in the header.
            new ColumnSelectLabel('TaskAlias', 'Task Name (alias)'),
            new ColumnSelectLabel('Task', 'Task'),
            new ColumnSelectLabel('TaskStatus', 'Task Status'),
            new ColumnSelectLabel('AnimalName', 'Animal (name)'),
            new ColumnSelectLabel('Cohort', 'Cohort (primary)'),
            new ColumnSelectLabel('HousingID', 'Housing ID'),
            new ColumnSelectLabel('SampleName', 'Output Sample'),
            new ColumnSelectLabel('DateDue', 'Date Due'),
            new ColumnSelectLabel('TimeDue', 'Time Due'),
            new ColumnSelectLabel('CompleteTask', 'Complete Task'),
            new ColumnSelectLabel('CompletedBy', 'Completed By'),
            new ColumnSelectLabel('CompletedDate', 'Completed Date'),
            new ColumnSelectLabel('CompletedTime', 'Completed Time'),
            new ColumnSelectLabel('Lock', 'Lock'),
        ];

        this.columnSelect.labels = labels;

        // Default column visibility
        this.visible = {
            TaskAlias: true,
            Task: false,
            TaskStatus: true,
            AnimalName: true,
            Cohort: true,
            HousingID: false,
            SampleName: false,
            DateDue: true,
            TimeDue: false,
            CompleteTask: true,
            CompletedBy: false,
            CompletedDate: true,
            CompletedTime: false,
            Lock: true,
        };

       

        this.columnSelect.model = labels.filter((item) => {
            return this.visible[item.key];
        }).map((item) => item.key);
        // Update the column visiblility
        this.updateVisible();
    }

    /**
     * Column selections have changed
     */
    columnSelectChanged(current: string[]) {
        // Get the current selections
        this.columnSelect.model = current;

        // Update the column visibilty
        this.updateVisible();
    }

    /**
     * Update the column visibility flags.
     */
    updateVisible() {
        // Make a lookup table
        const selected = {};
        this.columnSelect.model.forEach((key) => {
            selected[key] = true;
        });

        // Update the visibilty based on the column selections
        this.columnSelect.labels.forEach((column) => {
            const key = column.key;
            this.visible[key] = (selected[key] === true);
        });
    }


    dueTaskChanged() {
        if (this.bulk.dateDueTask !== "null") {
            this.bulk.dateDueTaskAliases = this.tasks.filter((task: any) => {
                return task.WorkflowTask.TaskName === this.bulk.dateDueTask;
            }).map<string>((task: any) => task.TaskAlias).filter((value, index, self) => self.indexOf(value) === index);
            if ((this.bulk.dateDueTaskAliases.length === 1) && (this.bulk.dateDueTaskAliases[0] === this.bulk.dateDueTask)) {
                // There are no aliases that are different the task name, so clear
                // the list.
                this.bulk.dateDueTaskAliases.length = 0;
                this.bulk.dateDueTaskAlias = "all-tasks-alias-selection";
            }
            if (this.bulk.dateDueTaskAliases.length > 1) {
                this.bulk.dateDueTaskAlias = "all-tasks-alias-selection";
            }
        }
    }

    getCohorts() {
        const seenCohorts: any = {};
        const cohorts: any[] = [];
        for (const task of this.tasks) {
            for (const tm of task.TaskMaterial) {
                for (const cm of tm.Material.CohortMaterial) {
                    for (const jc of this.job.JobCohort) {
                        if (cm.C_Cohort_key === jc.C_Cohort_key) {
                            const cohort = cm.Cohort;
                            if (seenCohorts[cohort.C_Cohort_key]) {
                                // Already added
                                continue;
                            }

                            // This one is a keeper
                            cohorts.push(cohort.CohortName);
                            seenCohorts[cohort.C_Cohort_key] = true;
                        }
                    }
                }
            }
        }
        this.bulk.dateDueCohorts = cohorts;
        if (this.bulk.dateDueCohorts.length > 1) {
            this.bulk.dateDueCohort = "all-cohorts-selection";
        } else {
            this.bulk.dateDueCohort = "null";
        }
        this.bulkCohortChanged();
    }

    bulkCohortChanged() {
        // A Cohort is selected get its animals or all cohort based animals
        this.bulk.dateDueAnimals = [];
        if (this.bulk.dateDueCohort === "all-cohorts-selection") {
            return;
        }
        if (this.bulk.dateDueCohort !== "null") {
            this.tasks.forEach((task) => {
                task.TaskMaterial.forEach((material: any) => {
                    if (material.Material && material.Material.Animal && !this.bulk.dateDueAnimals.includes(material.Material.Animal.AnimalName)) {
                        // Check if the animal is a member of the selected cohort
                        const cohortMaterial = material.Material.CohortMaterial.filter((value: any) => {
                            return value.Cohort.CohortName === this.bulk.dateDueCohort;
                        });
                        if (cohortMaterial.length > 0) {
                            this.bulk.dateDueAnimals.push(material.Material.Animal.AnimalName);
                        }
                    }
                });
            });
        } else if (this.bulk.dateDueCohorts.length > 0) {
            // Show non cohort based animals
            const jobCohorts = this.job.JobCohort.map((cohort: any) => cohort.C_Cohort_key);
            this.tasks.forEach((task) => {
                task.TaskMaterial.forEach((material: any) => {
                    if (material.Material && material.Material.Animal && !this.bulk.dateDueAnimals.includes(material.Material.Animal.AnimalName)) {
                        // Check if the animal is not a member of any cohort inside job
                        const cohorts = material.Material.CohortMaterial.filter((cm: any) => jobCohorts.includes(cm.C_Cohort_key));
                        if (cohorts.length === 0) {
                            this.bulk.dateDueAnimals.push(material.Material.Animal.AnimalName);
                        }
                    }
                });
            });
        }
        if (this.bulk.dateDueAnimals.length > 1) {
            this.bulk.dateDueAnimal = "all-animals-selection";
        } else if (this.bulk.dateDueAnimals.length > 0) {
            this.bulk.dateDueAnimal = this.bulk.dateDueAnimals[0];
        } else {
            this.bulk.dateDueAnimal = "null";
        }
    }

    initCVs(): Promise<void> {
        return this.vocabularyService.getCV('cv_TaskStatuses').then((taskStatuses) => {
            this.taskStatuses = taskStatuses;
        }).then(() => {
            this.setDefaultFillDownStatus();
        }).then(() => {
            return this.resourceService.getAllResources();
        }).then((resources) => {
            this.resources = resources;
        });
    }

    setDefaultFillDownStatus() {
        for (const status of this.taskStatuses) {
            if (status.IsDefaultEndState) {
                this.fillDownTaskStatusKey = status.C_TaskStatus_key;
                this.defaultEndState = status.C_TaskStatus_key;
            }
            if (status.IsEndState) {
                this.endStateKeys.push(status.C_TaskStatus_key);
            }
        }
    }

    initResource(): Promise<void> {
        return this.resourceService.getCurrentUserResource().then((resource) => {
            this.currentResource = resource ? resource.C_Resource_key : null;
        });
    }

    checkIfTaskSelected(task: any) {
        if (task.WorkflowTask.TaskName !== this.bulk.dateDueTask && this.bulk.dateDueTask !== "all-tasks-selection") {
            // Not the selected task
            return false;
        }

        if (this.bulk.dateDueTaskAlias !== "null") {
            const alias = task.TaskAlias;
            if (this.bulk.dateDueTaskAlias !== "all-tasks-alias-selection" && this.bulk.dateDueTaskAlias !== alias) {
                // Alias doesn't match
                return false;
            }
        }

        // Filter the taskMaterials for animals
        const animalMaterial = task.TaskMaterial.filter((value: any) => {
            return value.Material.Animal !== null;
        });
        if (animalMaterial.length > 0) {
            if (this.bulk.dateDueCohort !== "null") {
                // Check if the animal is a member of the selected cohort
                const jobCohorts = this.job.JobCohort.map((cohort: any) => cohort.C_Cohort_key);
                // Check if the animal is not a member of any cohort inside job
                const cohorts = animalMaterial[0].Material.CohortMaterial.filter((cm: any) => jobCohorts.includes(cm.C_Cohort_key));
                if (cohorts.length > 0) {
                    // All cohorts was not selected match if cohorts have our selected cohort
                    const selectedCohort = cohorts.filter((cohort: any) => cohort.Cohort.CohortName === this.bulk.dateDueCohort);
                    if (this.bulk.dateDueCohort !== "all-cohorts-selection" && selectedCohort.length === 0) {
                        return false;
                    }
                } else {
                    if (this.bulk.dateDueCohort === "all-cohorts-selection") {
                        return false;
                    }
                }
            }

            if (this.bulk.dateDueAnimal !== "null") {
                if (this.bulk.dateDueAnimal !== "all-animals-selection") {
                    // All animals was not selected match if this material is our selected animal
                    if (animalMaterial[0].Material.Animal.AnimalName !== this.bulk.dateDueAnimal) {
                        return false;
                    }
                } else {
                    // All animals was selected match if this material is in our selected animal
                    if (!this.bulk.dateDueAnimals.includes(animalMaterial[0].Material.Animal.AnimalName)) {
                        return false;
                    }
                }
            }
        } else {
            return false;
        }
        return true;
    }

    fillDownStatus() {
        return this.dataManager.ensureRelationships(this.tasks, ['TaskOutputSet']).then(() => {
            for (const task of this.tasks) {
                if (this.checkIfTaskSelected(task) && !task.IsWorkflowLocked) {
                    // All clear set the task status and all
                    task.C_TaskStatus_key = this.fillDownTaskStatusKey;

                    if (this.endStateKeys.includes(task.C_TaskStatus_key)) {
                        task.DateComplete = new Date();
                        if (this.currentResource) {
                            task.C_CompletedBy_key = this.currentResource;
                        }
                        // Fill Collected fields if empty
                        const taskOutputSets = uniqueArrayFromPropertyPath(task, 'TaskOutputSet');
                        for (const tos of taskOutputSets) {
                            tos.CollectionDateTime = tos.CollectionDateTime ? tos.CollectionDateTime : task.DateComplete;
                            tos.C_Resource_key = tos.C_Resource_key ? tos.C_Resource_key : task.C_CompletedBy_key;
                        }
                    } else {
                        task.DateComplete = null;
                        task.C_CompletedBy_key = null;
                    }
                }
            }
        });
    }

    taskStatusChanged(task: any) {
        if (this.endStateKeys.includes(task.C_TaskStatus_key)) {
            task.DateComplete = new Date();
            if (this.currentResource) {
                task.C_CompletedBy_key = this.currentResource;
            }
            // Fill Collected fields if empty
            return this.dataManager.ensureRelationships([task], ['TaskOutputSet']).then(() => {
                const taskOutputSets = uniqueArrayFromPropertyPath(task, 'TaskOutputSet');
                for (const tos of taskOutputSets) {
                    tos.CollectionDateTime = tos.CollectionDateTime ? tos.CollectionDateTime : task.DateComplete;
                    tos.C_Resource_key = tos.C_Resource_key ? tos.C_Resource_key : task.C_CompletedBy_key;
                }
            });
        } else {
            task.DateComplete = null;
            task.C_CompletedBy_key = null;
        }
    }

    completeAllTasks(): Promise<any> {
        return this.dataManager.ensureRelationships(this.tasks, ['TaskOutputSet']).then(() => {
            for (const task of this.tasks) {
                if (!task.IsWorkflowLocked) {
                    task.DateComplete = new Date();
                    task.C_TaskStatus_key = this.defaultEndState;
                    if (this.currentResource) {
                        task.C_CompletedBy_key = this.currentResource;
                    }
                    // Fill Collected fields if empty
                    const taskOutputSets = uniqueArrayFromPropertyPath(task, 'TaskOutputSet');
                    for (const tos of taskOutputSets) {
                        tos.CollectionDateTime = tos.CollectionDateTime ? tos.CollectionDateTime : task.DateComplete;
                        tos.C_Resource_key = tos.C_Resource_key ? tos.C_Resource_key : task.C_CompletedBy_key;
                    }
                }
            }
        });
    }

    bulkDateDueChanged() {
        for (const task of this.tasks) {
            if (this.checkIfTaskSelected(task) && !task.IsWorkflowLocked) {
                task.DateDue = this.bulk.dateDue;
            }
        }
    }

    bulkTimeDueChanged() {
        for (const task of this.tasks) {
            if (!task.IsWorkflowLocked) {
                task.DateDue = this.bulk.dateDue;
            }
        }
    }

    bulkCompletedByChanged() {
        for (const task of this.tasks) {
            if (!task.IsWorkflowLocked) {
                task.C_CompletedBy_key = this.bulk.completedByKey;
            }
        }
    }

    bulkCompletedDateChanged() {
        for (const task of this.tasks) {
            if (!task.IsWorkflowLocked) {
                task.DateComplete = this.bulk.completedTime;
            }
        }
    }

    bulkCompletedTimeChanged() {
        for (const task of this.tasks) {
            if (!task.IsWorkflowLocked) {
                task.DateComplete = this.bulk.completedTime;
            }
        }
    }

    close() {
        const errMessage = dateControlValidator(this.dateControls);
        if (errMessage) {
            this.loggingService.logError(errMessage, null, '', true);
            return;
        }
        this.saveChangesService.saveChanges("edit-tasks-modal", true);
        this.activeModal.close();
    }

    discard() {
        this.taskService.cancelEditToTasks(this.tasks);
        this.activeModal.close();
    }

    resourceKeyFormatter = (value: any) => {
        return value.C_Resource_key;
    }
    resourceNameFormatter = (value: any) => {
        return value.ResourceName;
    }
    taskStatusKeyFormatter = (value: any) => {
        return value.C_TaskStatus_key;
    }
    taskStatusFormatter = (value: any) => {
        return value.TaskStatus;
    }

    changeTaskPage(newPage: number) {
        this.taskPage = newPage;
    }
    
    validate() {
        return dateControlValidator(this.dateControls);
    }
}

<ng-container *ngIf="showExtraMenuItems; else notShowExtraMenu">
    <!-- Dropdown with menu options to pick before showing bulk edit modal-->
    <div class="dropdown" id="{{_idPrefix}}">
        <a class="dropdown-toggle"
            data-toggle="dropdown"
            role="button"
            aria-haspopup="true"
            aria-expanded="false">
            <span class="position-name">{{headerText}}</span>
        </a>
        <div class="dropdown-menu" [style.max-width]="dropdownMenuMaxWidth" clickStopPropagation (click)="bodyClicked.emit()">
            <ng-container *ngIf="showMenu; else notShowMenu">
                <a
                    *ngIf="!readonly && !hideBulkEditMenuOption"
                    onClick="event.stopPropagation();"
                    (click)="viewBulkEditModal()"
                    class="dropdown-item"
                    [title]="mainOptionTitle"
                >
                    Bulk Edit
                </a>
                <div *ngIf="!hideBulkEditMenuOption"
                    role="separator" class="dropdown-divider"></div>
                <ng-content select="[extra-menu-items]"></ng-content>
            </ng-container>
            <ng-template #notShowMenu>
                <ng-template [ngTemplateOutlet]="bulkEditModal"></ng-template>
            </ng-template>
        </div>
        <!-- Optional tooltip -->
        <div style="display: inline;" *ngIf="tooltip">
            <info-tooltip [text]="tooltip"></info-tooltip>
        </div>
    </div>
</ng-container>
<ng-template #notShowExtraMenu>
    <!-- static readonly column header -->
    <span *ngIf="readonly">{{headerText}}</span>
    <!-- Dropdown to directly open bulk edit modal -->
    <div class="dropdown"
         *ngIf="!readonly"
         id="{{_idPrefix}}">
        <a class="dropdown-toggle"
            [attr.data-toggle]="dropdownToggle"
            (click)="caretClicked.emit()"
            role="button"
            aria-haspopup="true"
            aria-expanded="false">
            <span class="position-name">{{headerText}}</span>
        </a>
        <div class="dropdown-menu" clickStopPropagation (click)="bodyClicked.emit()">
            <ng-template [ngTemplateOutlet]="bulkEditModal"></ng-template>
        </div>
        <!-- Optional tooltip -->
        <div style="display: inline;" *ngIf="tooltip">
            <info-tooltip [text]="tooltip"></info-tooltip>
        </div>
    </div>
</ng-template>




<ng-template #bulkEditModal>
    <div class="dropdown-header p-0">
        <div class="bulk-edit-header-modal-content">
            <ng-content select="[modal-content]"></ng-content>
        </div>
        <hr />
        <ng-container *ngIf="bulkEditCustomButtons.length">
            <button *ngFor="let button of bulkEditCustomButtons"
                    class="btn btn-secondary float-right"
                    (click)="updateCustomButtonClicked.emit([button.fieldName, button.text])"
                    style="margin-left: 4px;"
                    [disabled]="button.isDisabled"
                    type="button">
                {{button.text}}
            </button>
        </ng-container>
        <ng-container *ngIf="!bulkEditCustomButtons.length">
            <button type="button"
                    class="btn btn-secondary float-right"
                    (click)="clickUpdate()"
                    [disabled]="disabled">
                {{updateButtonText}}
            </button>
        </ng-container>
    </div>
</ng-template>

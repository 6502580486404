import {
    Component,
    Input,
    OnInit,
} from '@angular/core';

import {
    randomId,
    softCompare
} from '../../common/util';

import {
    SubParameterItem
} from '../models';

import { ParamInputs } from './param-inputs';

@Component({
    selector: 'report-param-input',
    templateUrl: './report-param-input.component.html',
    styles: [`
        .list-unstyled li {
            margin-bottom: 0.3em;
        }
    `]
})
export class ReportParamInputComponent implements OnInit {
    @Input() subParam: SubParameterItem;

    // enum for valid input types
    readonly ParamInputs = ParamInputs;

    // unique field name for input box
    _fieldName: string;

    // used for dropdownindirect
    dropdownValue: any;

    ngOnInit() {
        this._fieldName = "report-param-" + randomId();

        this.dropdownValue = 0;
    }

    autocompleteSelect(item: any) {
        if (this.subParam.details.getDependentDataFunction) {
            this.subParam.details
                .getDependentDataFunction(item);
            return;
        }
        if (this.indexOf(this.subParam.results, item) < 0) {
            this.subParam.results.push(item);
        }
    }

    autocompleteSingleSelect(item: any) {
        this.subParam.results = [];
        if (this.subParam.details.getDependentDataFunction) {
            this.subParam.details
                .getDependentDataFunction(item);
            return;
        }
        if (this.indexOf(this.subParam.results, item) < 0) {
            this.subParam.results.push(item);
        }
    }

    getAutocompleteData = (text: string): Promise<any[]> => {
        return this.subParam.details.getDataFunction(text);
    }

    dropdownSelect(item: any) {
        this.subParam.results = [];

        this.subParam.details
            .getDependentDataFunction(this.dropdownValue);
    }

    removeItem(item: any) {
        const indexOf = this.indexOf(this.subParam.results, item);

        if (indexOf >= 0) {
            this.subParam.results.splice(indexOf, 1);
        }
    }

    indexOf(items: any[], item: any): number {
        let retval = -1;

        for (let i = 0; i < items.length; i++) {

            if (softCompare(items[i].value, item.value)) {
                retval = i;
                break;
            }
        }

        return retval;
    }

    autocompleteFormatter = (item: any) => {
        return item.text;
    }
}

import { LocalStorageKey } from '@config';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { LocalStorageService } from './local-storage.service';
import { WebApiService } from './web-api.service';
import { AppInsightsService } from './app-insights.service';

import { softCompare } from '@common/util';

import { ApiResponse } from './models/api-response';
import { RequestCache } from '../common/interceptors/request-cache.service';

@Injectable()
export class CurrentWorkgroupService {
    private switchingWorkgroupSource = new BehaviorSubject<boolean>(false);
    switchingWorkgroup$ = this.switchingWorkgroupSource.asObservable();

    private switchWorkgroupSource = new Subject<number>();
    switchWorkgroup$ = this.switchWorkgroupSource.asObservable();
    
    constructor(
        private appInsightsService: AppInsightsService,
        private localStorageService: LocalStorageService,
        private webApiService: WebApiService,
        private requestCache: RequestCache
    ) {}

    getCurrentWorkgroupKey() {
        let workgroupKey;
        try {
            workgroupKey = this.localStorageService.get(LocalStorageKey.WORKGROUP_KEY);
        } catch (error) {
            // don't crash app if workgroupKey is stored incorrectly
            console.log(error);
        }
        return workgroupKey;
    }

    setSwitchingWorkgroup(value: boolean): void {
        this.switchingWorkgroupSource.next(value);
    }

    setCurrentWorkgroupKey(workgroupKey: number): void {
        this.localStorageService.set(LocalStorageKey.WORKGROUP_KEY, workgroupKey);
    }

    getWorkgroupName(): string {
        return this.localStorageService.get(LocalStorageKey.WORKGROUP_NAME);
    }

    getWorkgroupTimeZone(): Promise<ApiResponse> {
        const workgroupKey = this.getCurrentWorkgroupKey();
        const requestURL = `api/WorkgroupInfo/TimeZone/${workgroupKey}`;

        return this.webApiService.callApi(requestURL);
    }

    setWorkgroupTimeZone(workgroupTimezone: any): void {
        this.localStorageService.set(LocalStorageKey.WORKGROUP_TIMEZONE_INFO, workgroupTimezone);
    }

    setWorkgroupName(workgroupName: string): void {
        this.localStorageService.set(LocalStorageKey.WORKGROUP_NAME, workgroupName);
    }

    switchWorkgroup(workgroupKey: number): void {
        // clear the request cache when switching workgroups
        this.requestCache.clear();

        // if workgroupKey is different, track the event
        if (!softCompare(this.getCurrentWorkgroupKey(), workgroupKey)) {
            this.appInsightsService.trackEvent("switch-workgroup");
        }

        this.setCurrentWorkgroup(workgroupKey)
        this.switchWorkgroupSource.next(workgroupKey);
    }

    /**
     * Sets current workgroup key in local storage
     *   and in database
     * @param workgroupKey
     */
    private setCurrentWorkgroup(workgroupKey: number): void {
        this.localStorageService.set(LocalStorageKey.WORKGROUP_KEY, workgroupKey);
    }
}

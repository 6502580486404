import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class DataTableCommService {

    /**
     * Tell data table to reload (I.e. refresh)
     */
    private reloadTableSource = new Subject<any>();
    reloadTable$ = this.reloadTableSource.asObservable();
    reloadTable() {
        this.reloadTableSource.next();
    }

    /**
     * Tell data table to redraw its data cells,
     *   because non-Breeze data has changed
     */
    private redrawTableCellsSource = new Subject<any>();
    redrawTableCells$ = this.redrawTableCellsSource.asObservable();
    redrawTableCells() {
        this.redrawTableCellsSource.next();
    }

    /**
     * Tell data table to load specific pageNumber
     */
    private loadSpecificPageSource = new Subject<number>();
    loadSpecificPage$ = this.loadSpecificPageSource.asObservable();
    loadSpecificPage(pageNumber: number) {
        this.loadSpecificPageSource.next(pageNumber);
    }

    /**
     * Called on completion of data table load
     */
    private onDataLoadCompletedSource = new Subject<number>();
    onDataLoadCompleted$ = this.onDataLoadCompletedSource.asObservable();
    onDataLoadCompleted() {
        this.onDataLoadCompletedSource.next();
    }

    // Show Count column on toggle switch
    private showColumnSource = new Subject<string>();
    showColumn$ = this.showColumnSource.asObservable();
    showColumn(name: string) {
        this.showColumnSource.next(name);
    }
}

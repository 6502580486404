import { FacetSharedModule } from './../common/facet/facet-shared.module';
import { IoTSharedModule } from './../iot/iot-shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { ClimbCommonModule } from '../common/climb-common.module';
import { ClimbServicesModule } from '../services/climb-services.module';
import { WorkspacesSharedModule } from '../workspaces/workspaces-shared.module';

import { IoTMonitoringTabComponent } from './iot-monitoring-tab.component';
import { MonitoringFacetComponent } from './monitoring-facet.component';
import { MonitoringService } from './monitoring.service';
import { MonitoringServiceSubscriptionParameterComponent } from './monitoring-service-subscription-parameter-modal.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgbModule,
        ClimbCommonModule,
        ClimbServicesModule,
        FacetSharedModule,
        IoTSharedModule,
        WorkspacesSharedModule
    ],
    declarations: [
        IoTMonitoringTabComponent,
        MonitoringFacetComponent,
        MonitoringServiceSubscriptionParameterComponent
    ],
    exports: [
        MonitoringFacetComponent
    ],
    providers: [
        MonitoringService
    ]
})
export class MonitoringModule { }

import { Pipe, PipeTransform } from "@angular/core";
import { DateFormatterService } from '@common/util/date-time-formatting';

@Pipe({
    name: "formatTime"
})
export class FormatTimePipe implements PipeTransform {
    constructor(private dateFormatterService: DateFormatterService) {

    }

    transform(value: any): string {
        return this.dateFormatterService.formatTime(value);
    }
}

import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { buildId } from '@config';
import { LoginService } from '../../services/login.service';
import { RoutingService } from '../../../routing/routing.service';
import { padlockClosed, user } from '@icons';
import { SsoProvider } from '../sso-provider-button/sso-provider-button.component';

type SSOProviderButton = {
    provider: SsoProvider;
    title: string;
    domainHint: string; // https://learn.microsoft.com/en-us/azure/active-directory-b2c/direct-signin?pivots=b2c-custom-policy#redirect-sign-in-to-a-social-provider
};

@Component({
    selector: 'climb-login-view',
    templateUrl: './login-view.component.html',
    styleUrls: ['./login-view.component.scss'],
})
export class LoginViewComponent {
    icons = { padlockClosed, user };
    buildId: string = buildId;
    errorMessage$: Observable<string> = this.loginService.errorMessage$;
    loading = false;
    ssoLoading$: Observable<boolean> = this.loginService.ssoLoading$;

    loginData: FormGroup = this.fb.group({
        userName: ['', [Validators.minLength(3)]],
        password: [''],
        email: [''],
        useRefreshTokens: [false],
        sso: [false],
    });

    ssoProviders: SSOProviderButton[] = [{
        provider: 'AzureAD',
        title: 'Continue with Azure',
        domainHint: 'commonaad',
    }, {
        provider: 'Okta',
        title: 'Continue with Okta',
        domainHint: 'Okta',
    }];

    constructor(
        private fb: FormBuilder,
        private loginService: LoginService,
        private routingService: RoutingService,
    ) { }

    login(): void {
        this.loading = true;
        this.loginService.login(this.loginData.value).pipe(
            tap(() => {
                this.loading = false;
                this.routingService.navigateToHome();
            }),
            catchError((error) => {
                this.loading = false;
                throw error;
            }),
        ).subscribe();
    }

    async loginSSO(): Promise<void> {
        if (this.loginService.tryRedirectToCustomerSso()) {
            return;
        }

        await this.loginService.loginSSO();
    }

    initSSOFlow(domainHint: string): void {
        if (domainHint === 'Okta') {
            this.routingService.navigateToOktaLogin();
        } else {
            this.loginService.initSSOFlow(domainHint);
        }
    }

    validateLoginDataLength(): boolean {
        return this.loginData.value.password.length === 0 || this.loginData.value.userName.length === 0;
    }
}

/**
 * Safe deep-setting by property path.
 * Recursively creates objects for nodes that do not exist in the path
 * @param obj
 * @param path
 * @param value
 */
export function setSafeProp(obj: any, path: string, value: any) {
    if (!path || !obj) {
        return;
    }

    path = path.replace(/\[(\w+)\]/g, '.$1');
    const pathList = path.split('.');

    // recurse nested nodes, filling in any non-existing nodes
    let nextProp = pathList.shift();
    let nextNode = obj;
    while (pathList.length > 0) {
        // create non-existent nodes
        if (nextNode[nextProp] === undefined) {
            nextNode[nextProp] = {};
        }
        // go to next level in the path list
        nextNode = nextNode[nextProp];
        nextProp = pathList.shift();
    }

    // after recursion, we are at the last node
    nextNode[nextProp] = value;
}

import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { ClimbCommonModule } from '../common/climb-common.module';
import { ClimbServicesModule } from '../services/climb-services.module';

import { EnumerationService } from './enumeration.service';
import { EnumerationSaveService } from './services/enumeration-save.service';
import { EnumerationStateService } from './services/enumeration-state.service';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ClimbCommonModule,
        ClimbServicesModule
    ],
    declarations: [
    ],
    exports: [
    ]
})
export class EnumerationsSharedModule {
    static forRoot(): ModuleWithProviders<EnumerationsSharedModule> {
        return {
          ngModule: EnumerationsSharedModule,
          providers: [
            EnumerationService,
            EnumerationSaveService,
            EnumerationStateService
          ]
        };
    }
}

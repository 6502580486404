import { EntityInit } from './entity-changes.interface';

export class AnimalHealthRecordChangeService {

    entityChangeService: EntityInit;

    constructor(entityChangeService: EntityInit) {
        this.entityChangeService = entityChangeService;
    }

    initHandlers() {
        this.entityChangeService.onEntityInit(
            'AnimalHealthRecord', (entity: any) => {
                this.initHealthRecord(entity);
            }
        );
    }

    initHealthRecord(entity: any) {
        this.setDefaultDerivedFields(entity);
    }

    setDefaultDerivedFields(entity: any) {
        // set some defaults to make working with properties easier
        entity.DerivedAssignedTo = entity.DerivedAssignedTo || '';
        entity.DerivedDateDue = entity.DerivedDateDue || null;
    }

}

import type { AlertDefinitionParameter } from './alert-definition-parameter.interface';
import type { DeviceModel } from './device-model.interface';

export interface DeviceModelOutput {
    AlertDefinitionParameter: AlertDefinitionParameter[];
    C_DeviceModel_key: string;
    DeviceModel: DeviceModel;
    OutputName: string;
    OutputType: string;
    OutputUnits: string;
}

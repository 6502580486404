import { QueryDef } from './../services/query-def';
import { ApiResponse } from './../services/models/api-response';
import { LocalStorageKey } from './../config';
import { Injectable } from '@angular/core';
import {
    AutoGeneratedKeyType,
    EntityQuery,
    Predicate,
    QueryResult,
} from 'breeze-client';

import { getLatestMaterialLocation } from '@common/util/get-latest-material-location';

import { DataManagerService } from '../services/data-manager.service';
import { LocalStorageService } from '../services/local-storage.service';
import { WebApiService } from '../services/web-api.service';
import { BaseEntityService } from '../services/base-entity.service';
import { notEmpty, datesEqual } from '../common/util';
import { SaveChangesService } from '../services/save-changes.service';
import { MaterialLocation } from '../common/types/models/material-location.interface';
import { Sample } from '../common/types/models/sample.interface';
import { Entity, LocationPosition } from '@common/types';
import { LocationPositionStateService } from './services/locationPosition-state.service';

@Injectable()
export class LocationService extends BaseEntityService {

    // Cache location of samples and housing units that have been modified but not yet saved.
    private cachedMaterials: any[] = [];
    private cachedMaterialPools: any[] = [];

    constructor(
        private dataManager: DataManagerService,
        private localStorageService: LocalStorageService,
        private webApiService: WebApiService,
        private saveChangesService: SaveChangesService,
        private locationPositionStateService: LocationPositionStateService,
    ) {
        super();

        this.subscribeToSaveEvents();
    }

    async getLocationPosition(locationPositionKey: number): Promise<any> {
        const query = EntityQuery.from('LocationPositions')
            .where('C_LocationPosition_key', '==', locationPositionKey);

        return this.dataManager.returnSingleQueryResult(query);
    }

    async getPositionStats(locationPositionKey: number): Promise<ApiResponse> {
        const apiUrl = `api/locations/GetLocationPositionStats/${locationPositionKey}`;
        return this.webApiService.callApi(apiUrl);
    }

    async getMaterialStats(locationPositionKey: number): Promise<ApiResponse> {
        const apiUrl = `api/locations/GetLocationMaterialStats/${locationPositionKey}`;
        return this.webApiService.callApi(apiUrl);
    }

    async getDeviceStats(locationPositionKey: number): Promise<ApiResponse> {
        const apiUrl = `api/locations/GetLocationDeviceStats/${locationPositionKey}`;
        return this.webApiService.callApi(apiUrl);
    }

    async getSingleRootLocationPosition(): Promise<any> {
        const data = await this.getRootLocationPositions();
        if (notEmpty(data)) {
            // when setting a default, there can only be one root location
            return data[0]; // root location position
        }
        return null;
    }

    async getRootLocationPositions(): Promise<any[]> {
        return this.getChildPositionsWithoutChildEntities(null);
    }

    async getChildPositionsWithoutChildEntities(parentKey: number): Promise<any[]> {
        const query = EntityQuery.from('LocationPositions')
            .where('C_ParentPosition_key', '==', parentKey || null)
            .orderBy('PositionName');

        
        return this.dataManager.getQueryResults(query);
    }

    async getParents(childKey: number, expireMs = 0): Promise<any[]> {
        const retval: any[] = [];

        const locationPosition = await this.getLocationPosition(childKey);
        if (locationPosition) {
            await this.getParent(retval, locationPosition.C_ParentPosition_key);
            return retval;
        }

        return Promise.resolve(retval);
    }

    async getParent(locationPositions: any[], childKey: number): Promise<any> {
        const locationPosition = await this.getLocationPosition(childKey);
        if (locationPosition) {
            locationPositions.splice(0, 0, locationPosition);

            if (locationPosition.C_ParentPosition_key != null) {
                return this.getParent(locationPositions, locationPosition.C_ParentPosition_key);
            }
        }

        return true;
    }

    async getAncestors(locationPositionKey: number): Promise<any> {
        const apiUrl = `api/locations/locationPositionAncestors/${locationPositionKey}`;
        return this.webApiService.callApi(apiUrl);
    }

    async checkLocationUse(locationPositionKey: number): Promise<any> {
        const apiUrl = `api/locations/locationInUse/${locationPositionKey}`;
        return this.webApiService.callApi(apiUrl);
    }

    async getDevices(locationPosition: any): Promise<void> {
        return this.dataManager.ensureRelationships([locationPosition], ['Device']);
    }

    // TODO why it's not used
    setDefaultLocation(currentLocationPositionKey: number): void {
        if (currentLocationPositionKey) {
            this.localStorageService.set(LocalStorageKey.CURRENT_POSITION_STORAGE_KEY, currentLocationPositionKey);
        }
    }

    /**
     * Returns user's cached location.
     * If there is none, returns the first root location.
     */
    async getDefaultLocationOrRoot(): Promise<any> {
        const location = await this.getDefaultLocation(true);
        if (location) {
            return location;
        } else {
            return this.getSingleRootLocationPosition();
        }
    }

    async getDefaultLocation(preferLocal?: boolean): Promise<any> {
        const currentPositionKey = this.localStorageService.get(LocalStorageKey.CURRENT_POSITION_STORAGE_KEY);

        let pred;
        if (currentPositionKey) {
            pred = Predicate.create('C_LocationPosition_key', '==', currentPositionKey);
        } else {
            pred = Predicate.create('C_ParentPosition_key', '==', null);
        }

        const query = EntityQuery.from('LocationPositions')
            .where(pred)
            .orderBy('Ordinal');

        return this.dataManager.returnSingleQueryResult(query, preferLocal);
    }

    /**
     * Returns the default location position for the line
     * @param lineKey - line identifier
     * @param fallbackToLastUsed - return last used location position if the line has no default
     */
    async getDefaultLocationForLine(lineKey: number, fallbackToLastUsed = true): Promise<any> | null {
        const pred = Predicate.create('C_Line_key', '==', lineKey);

        const query = EntityQuery.from('Lines')
            .where(pred)
            .select('C_LocationPosition_key');
        
        const line = await this.dataManager.returnSingleQueryResult(query);
        if (line && line.C_LocationPosition_key !== null) {
            return this.getLocationPosition(line.C_LocationPosition_key);
        }
        
        if (fallbackToLastUsed) {
            return this.getDefaultLocation();
        }
        
        return null;      
    }

    async addDefaultLineLocationIfNeeded(lineKey: number, materialPoolKey: number): Promise<any> {
        const defaultLocationPosition = await this.getDefaultLocationForLine(lineKey, false);
        if (!defaultLocationPosition) {
            return;
        }

        const manager = this.dataManager.getManager();
        const pred = Predicate.create(
            'C_MaterialPool_key', '==', materialPoolKey
        );
        const materialLocations: any[] = manager.executeQueryLocally(EntityQuery
            .from('MaterialLocations')
            .where(pred));
        const latestMaterialLocation = getLatestMaterialLocation(materialLocations);
        const isNotSameLocation = !latestMaterialLocation ||
            defaultLocationPosition.C_LocationPosition_key !== latestMaterialLocation.C_LocationPosition_key;
        if (isNotSameLocation) {
            const initialValues = {
                C_MaterialPool_key: materialPoolKey,
                C_LocationPosition_key: defaultLocationPosition.C_LocationPosition_key
            };
            
            await this.createMaterialLocation(initialValues);
        }
    }    

    /**
     * Requires filter "locationPositionKey"
     * @param queryDef 
     */
    async getSamplesInLocation(queryDef: QueryDef): Promise<any[]> {
        const filter: any = queryDef.filter;
        const locationPositionKey = filter.locationPositionKey;
        if (!locationPositionKey) {
            return Promise.resolve([]);
        }

        let query = this.buildDefaultQuery('MaterialLocations', queryDef);
        const p1 = Predicate.create('C_LocationPosition_key', '==', locationPositionKey);
        const p2 = Predicate.create('DateOut', '==', null);
        const p3 = Predicate.create('C_Material_key', '!=', null);
        const predicates = [p1, p2, p3];

        if (filter.materialKeys) {
            predicates.push(
                Predicate.create('C_Material_key', 'in', filter.materialKeys)
            );
        }

        let expands = ['Material.Sample'];

        if (notEmpty(queryDef.expands)) {
            expands = expands.concat(queryDef.expands);
        }

        query = query.expand(expands.join(','))
            .where(Predicate.and(predicates));

        return this.dataManager.returnQueryResults(query);
    }

    async getHousingsInLocation(queryDef: QueryDef): Promise<any[]> {
        const filter: any = queryDef.filter;
        const locationPositionKey = filter.locationPositionKey;
        if (!locationPositionKey) {
            return Promise.resolve([]);
        }

        let query = this.buildDefaultQuery('MaterialLocations', queryDef);
        const p1 = Predicate.create('C_LocationPosition_key', '==', locationPositionKey);
        const p2 = Predicate.create('DateOut', '==', null);
        const p3 = Predicate.create('C_MaterialPool_key', '!=', null);
        const predicates = [p1, p2, p3];

        if (filter.materialPoolKeys) {
            predicates.push(
                Predicate.create('C_MaterialPool_key', 'in', filter.materialPoolKeys)
            );
        }

        let expands = ['MaterialPool.MaterialPoolMaterial.Material.Animal'];

        if (notEmpty(queryDef.expands)) {
            expands = expands.concat(queryDef.expands);
        }
        query = query.expand(expands.join(','))
            .where(Predicate.and(predicates));

        return this.dataManager.returnQueryResults(query);
    }

    async getMaterialLocationPath(materialKey: number): Promise<string | null> {
        const p1 = Predicate.create('C_Material_key', '==', materialKey);
        const p2 = Predicate.create('DateOut', '==', null);
        const query = EntityQuery.from('MaterialLocations')
            .where(Predicate.and([p1, p2]));

        const results = await this.dataManager.returnQueryResults(query);
        if (results.length > 0) {
            const locationPosition = results[0];
            return this.getLocationPath('', locationPosition.C_LocationPosition_key);
        }
        return null;
    }

    async getMaterialPoolLocationPath(materialPoolKey: number): Promise<string | null> {
        const p1 = Predicate.create('C_MaterialPool_key', '==', materialPoolKey);
        const p2 = Predicate.create('DateOut', '==', null);
        let query = EntityQuery.from('MaterialLocations');
        const pred = Predicate.and([p1, p2]);

        query = query.where(pred);

        const results = await this.dataManager.returnQueryResults(query);
        if (results.length > 0) {
            const locationPosition = results[0];
            return this.getLocationPath('', locationPosition.C_LocationPosition_key);
        }
        return null;
    }

    async getLocationPath(locationPath: string, locationPositionKey: number): Promise<string> {
        const query = EntityQuery.from('LocationPositions')
            .where('C_LocationPosition_key', '==', locationPositionKey);

        const results = await this.dataManager.returnQueryResults(query);
        if (results.length > 0) {
            if (locationPath.length > 0) {
                locationPath += ' > ';
            }
            const locationPosition = results[0];

            locationPath += locationPosition.PositionName;

            return this.getLocationPath(locationPath, locationPosition.C_ParentPosition_key);
        } else {
            return locationPath;
        }
    }

    async getContainerTypes(materialType: string): Promise<any[]> {
        const typeKey = await this.getMaterialTypeKey(materialType);
        const predicates: Predicate[] = [];

        predicates.push(Predicate.create('IsActive', '==', true));     
        predicates.push(Predicate.create('C_MaterialType_key', '==', typeKey)); 

        const query = EntityQuery.from('cv_ContainerTypes')
            .where(Predicate.and(predicates))
            .orderBy('SortOrder');

        return this.dataManager.returnQueryResults(query);
    }

    private async getMaterialTypeKey(materialType: string): Promise<number | null> {
        const query = EntityQuery.from('cv_MaterialTypes')
            .select('C_MaterialType_key')
            .where('MaterialType', '==', materialType);

        const typeEntity = await this.dataManager.returnSingleQueryResult(query, true);
        return typeEntity ? typeEntity.C_MaterialType_key : null;
    }

    createLocationPosition(initialValues: any): Entity<LocationPosition> {
        const entityType = 'LocationPosition';
        const manager = this.dataManager.getManager();
        const locationPosition: any = manager.metadataStore.getEntityType(entityType);
        locationPosition.setProperties({ autoGeneratedKeyType: AutoGeneratedKeyType.Identity });

        return manager.createEntity(entityType, initialValues) as Entity<LocationPosition>;
    }

    deleteLocationPosition(locationPosition: any): void {
        this.dataManager.deleteEntity(locationPosition);
    }

    async isDeleteLocationPositionSafe(locationPosition: any): Promise<boolean> {
        const childCount = await this.getChildPositionsCount(locationPosition.C_LocationPosition_key);
        const hasMaterials = await this.hasMaterials(locationPosition);
        const hasLines = await this.hasLines(locationPosition);

        const conditionStatuses = [childCount > 0, hasMaterials, hasLines];
        const unsafeConditionCount = conditionStatuses.reduce((count, conditionStatus) => count + (conditionStatus ? 1 : 0), 0);

        return unsafeConditionCount === 0;
    }

    async getChildPositionsCount(parentKey: number, checkIfActive = false): Promise<number> {
        const predicates = [Predicate.create('C_ParentPosition_key', '==', parentKey || null)];
        if (checkIfActive) {
            predicates.push(
                Predicate.create('IsActive', '==', true)
            );
        }

        const query = EntityQuery.from('LocationPositions')
            .where(Predicate.and(predicates))
            .noTracking(true)
            .take(0)
            .inlineCount(true);

        return this.dataManager.returnCachedQueryCount(query);
    }

    async hasMaterials(locationPosition: any): Promise<boolean> {
        const query = EntityQuery.from("MaterialLocations")
            .where('C_LocationPosition_key', '==', locationPosition.C_LocationPosition_key)
            .noTracking(true)
            .take(0)
            .inlineCount(true);

        const count = await this.dataManager.returnQueryCount(query);
        return count !== 0;
    }

    async hasLines(locationPosition: any): Promise<boolean> {
        const query = EntityQuery.from("Lines")
            .where('C_LocationPosition_key', '==', locationPosition.C_LocationPosition_key)
            .noTracking(true)
            .take(0)
            .inlineCount(true);

        const count = await this.dataManager.returnQueryCount(query);
        return count !== 0;
    }

    async createMaterialLocation(initialValues: any): Promise<MaterialLocation> {
        const today = new Date();
        let dateIn = today;
        const isMaterialPool = initialValues.C_MaterialPool_key ? true : false;
        const key = isMaterialPool ? initialValues.C_MaterialPool_key : initialValues.C_Material_key;
        const getDataFromCache = isMaterialPool
            ? this.cachedMaterialPools.some(x => x === initialValues.C_MaterialPool_key)
            : this.cachedMaterials.some(x => x === initialValues.C_Material_key);
        const pred = Predicate.create(
            isMaterialPool ? 'C_MaterialPool_key' : 'C_Material_key',
            '==',
            key
        );

        const queryResult = await this.dataManager.executeQuery(EntityQuery.from('MaterialLocations').where(pred), getDataFromCache);
        queryResult.results.forEach((location: any) => {
            if (location.C_LocationPosition_key !== initialValues.C_LocationPosition_key && !location.DateOut) {
                location.DateOut = today;
            }

            if (!dateIn || (dateIn && location.DateOut > dateIn)) {
                dateIn = location.DateOut;
            }
        });

        if (!initialValues.DateIn) {
            initialValues.DateIn = dateIn;
        }

        if (!getDataFromCache) {
            if (isMaterialPool) {
                this.cachedMaterialPools.push(key);
            } else {
                this.cachedMaterials.push(key);
            }
        }

        return this.dataManager.createEntity('MaterialLocation', initialValues);
    }

    deleteMaterialLocation(materialLocation: any): void {
        this.dataManager.deleteEntity(materialLocation);
    }

    async processMaterialPoolLocationChange(materialPool: any, newLocationPosition: any): Promise<MaterialLocation | null> {
        const materialPoolKey: number = materialPool.C_MaterialPool_key;
        const newLocationPositionKey: number = newLocationPosition.C_LocationPosition_key;
        const currentLocation: any = getLatestMaterialLocation(materialPool.MaterialLocation);

        if (!currentLocation ||
            newLocationPositionKey !== currentLocation.C_LocationPosition_key) {
            // MaterialLocation has changed or is new, so add it to this MaterialPool

            const initialValues = {
                C_MaterialPool_key: materialPoolKey,
                C_LocationPosition_key: newLocationPositionKey
            };
            return this.createMaterialLocation(initialValues);
        }

        return Promise.resolve(null);
    }

    // For samples that don't need to be in a MaterialPool
    async processSampleLocationChange(sample: Sample, newLocationPosition: any): Promise<any> {
        const materialKey: number = sample.C_Material_key;
        const newLocationPositionKey: number = newLocationPosition.C_LocationPosition_key;
        const currentLocation: any = getLatestMaterialLocation(sample.Material.MaterialLocation);

        if (!currentLocation ||
            newLocationPositionKey !== currentLocation.C_LocationPosition_key) {
            // MaterialLocation has changed or is new, so add it to this Sample

            const initialValues = {
                C_Material_key: materialKey,
                C_LocationPosition_key: newLocationPositionKey
            };
            return this.createMaterialLocation(initialValues);
        }

        return Promise.resolve(null);
    }

    locationsValid(locations: any[]): boolean {
        let locationsValid = true;
        let invalidLocationsCount = 0;

        if (locations && locations.length > 0) {
            locations.forEach((location: any) => {
                const bothSet = location.DateIn && location.DateOut;
                if (bothSet) {
                    if (!datesEqual(location.DateIn, location.DateOut)) {
                        if (location.DateIn > location.DateOut) {
                            locationsValid = false;
                        }
                    }
                } else {
                    invalidLocationsCount++;
                }
            });
        }
        return locationsValid && invalidLocationsCount <= 1;
    }

    cancelLocation(location: any): void {
        if (!location) {
            return;
        }

        if (location.C_LocationPosition_key > 0) {
            this.dataManager.rejectEntityAndRelatedPropertyChanges(location);
        } else {
            this.deleteLocationPosition(location);
        }
    }

    invalidateCache(): void {
        this.cachedMaterialPools = [];
        this.cachedMaterials = [];
    }

    private subscribeToSaveEvents(): void {
        this.saveChangesService.saveSuccessful$.subscribe(() => {
            this.onSaveSuccessful();
        });
    }

    private onSaveSuccessful(): void {
        // Once the data is saved, reset the cached locations to resume retrieving data from the server.
        this.invalidateCache();
    }

    locationPositionHaveChanges(locationPosition: Entity<LocationPosition>) {
        return this.locationPositionStateService.hasChanges(locationPosition);
    }
}

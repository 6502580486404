<ng-container *ngIf="value">
    <ngx-barcode6 [bc-value]="value"
                 [bc-element-type]="options.elementType"
                 [bc-display-value]="options.displayValue"
                 [bc-font]="options.font"
                 [bc-font-size]="options.fontSize"
                 [bc-height]="options.height"
                 [bc-width]="options.width"
                 [bc-margin]="options.margin"
                 [bc-margin-top]="options.marginTop"
                 [bc-margin-bottom]="options.marginBottom"
                 [bc-margin-left]="options.marginLeft"
                 [bc-margin-right]="options.marginRight"
                 [bc-line-color]="options.lineColor"
                 [bc-background]="options.background"></ngx-barcode6>
</ng-container>

import { AfterViewChecked, Directive, ElementRef, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { NgControl } from '@angular/forms';
import { Subscription } from 'rxjs';

@Directive({
    selector: '[climbCharacterCounter]'
})
export class CharacterCounterDirective implements OnInit, OnDestroy, AfterViewChecked {
    @Input() maxCharacterCount = 128;
    @Input() container?: any;

    private subscription: Subscription;
    private containerElement: HTMLElement;
    private counterElement: HTMLElement;
    private hasScrollbar: boolean;

    constructor(
        private el: ElementRef,
        private renderer: Renderer2,
        private control: NgControl,
    ) { }

    ngOnInit(): void {
        this.containerElement = this.container?.elementRef?.nativeElement;
        if (!this.containerElement) {
            this.createContainerElement();
        }

        this.setupContainerElement();
        this.setupCounterElement();

        this.subscription = this.control.valueChanges.subscribe((value) => {
            this.updateCharacterCount(value);
            this.updateWidthIfHasScrollbar();
        });
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    ngAfterViewChecked(): void {
        this.updateElementRef();
        this.updateWidthIfHasScrollbar();
    }

    private createContainerElement() {
        const parentElement = this.renderer.parentNode(this.el.nativeElement);
        if (!parentElement) {
            throw new Error("No parent element found");
        }

        this.containerElement = this.renderer.createElement('div');
        this.renderer.setStyle(this.containerElement, 'display', 'inherit');
        this.renderer.insertBefore(parentElement, this.containerElement, this.el.nativeElement);
        this.renderer.appendChild(this.containerElement, this.el.nativeElement);
    }

    private setupContainerElement() {
        this.renderer.setStyle(this.containerElement, 'position', 'relative');
    }

    private setupCounterElement() {
        this.counterElement = this.renderer.createElement('span');
        this.renderer.setStyle(this.counterElement, 'position', 'absolute');
        this.renderer.setStyle(this.counterElement, 'top', '4px');
        this.renderer.setStyle(this.counterElement, 'color', '#666');
        this.updateCounterElementPosition();
        this.updateCharacterCount(this.control.value);
        this.renderer.appendChild(this.containerElement, this.counterElement);
    }

    private updateCounterElementPosition() {
        if (this.hasScrollbar) {
            this.renderer.setStyle(this.counterElement, 'right', '30px');
        } else {
            this.renderer.setStyle(this.counterElement, 'right', '10px');
        }
    }

    private updateCharacterCount(value?: string) {
        if (value?.length > this.maxCharacterCount) {
            value = value.slice(0, this.maxCharacterCount);
            this.control.control.setValue(value, { emitEvent: false });
        }

        const currentCharacterLength = value?.length ?? 0;
        this.counterElement.innerHTML = `${currentCharacterLength}/${this.maxCharacterCount}`;
    }

    private updateElementRef() {
        this.renderer.setStyle(this.el.nativeElement, 'padding-right', (this.counterElement.offsetWidth * 2) + 'px');
    }

    private checkIfHasScrollbar() {
        const element = this.el.nativeElement;
        return element.scrollHeight > element.clientHeight;
    }

    private updateWidthIfHasScrollbar() {
        const hasScrollBar = this.checkIfHasScrollbar();
        if (hasScrollBar !== this.hasScrollbar) {
            this.hasScrollbar = hasScrollBar;
            this.updateCounterElementPosition();
        }
    }
}
import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';
import { WizardService } from '../wizard.service';

import { CvValue } from '../models';

import { contactSupportUri } from '../../config/climb-web-settings';
import { notEmpty } from '../../common/util';

@Component({
    selector: 'choose-taxa',
    templateUrl: './choose-taxa.component.html',
})
export class ChooseTaxaComponent implements OnInit {
    @Input() selectedTaxa: CvValue[];
    @Output() selectedTaxaChange: EventEmitter<CvValue[]> = new EventEmitter<CvValue[]>();

    supportUri: string;

    // All taxa
    taxonValues: CvValue[];


    constructor(
        private wizardService: WizardService
    ) {
        // Nothing to do
    }

    ngOnInit() {
        this.initialize();
    }

    initialize() {
        this.supportUri = contactSupportUri;

        if (!this.selectedTaxa) {
            this.selectedTaxa = [];
        }

        this.getTaxonValues().then((values) => {
            this.taxonValues = values;
            this.setTaxonValuesIsSelectedFromInput();
        });
    }

    private getTaxonValues(): Promise<CvValue[]> {
        return this.wizardService.getCVSeedValues('cv_Taxon');
    }

    private setTaxonValuesIsSelectedFromInput() {
        if (notEmpty(this.taxonValues) && notEmpty(this.selectedTaxa)) {

            const selectedCvValues: string[] = this.selectedTaxa.map((item: CvValue) => {
                return item.cvValue;
            });

            for (const taxonValue of this.taxonValues) {
                if (selectedCvValues.indexOf(taxonValue.cvValue) > -1) {
                    taxonValue.isSelected = true;
                }
            }
        }
    }

    onModelChange(event: Event) {
        this.setSelectedValuesFromForm();
        this.selectedTaxaChange.emit(this.selectedTaxa);
    }

    private setSelectedValuesFromForm() {
        this.selectedTaxa = this.taxonValues.filter((item: CvValue) => {
            return item.isSelected === true;
        });
    }
}

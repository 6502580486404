<table class="table table-nonfluid"
       *ngIf="events && events.length > 0">
    <thead>
        <tr>
            <th class="icon-cell"
                *ngIf="facetPrivilege !== 'ReadOnly'">
                <!--
                    <a class="add-item-link" (click)="addEvent()">
                    <i class="fa fa-plus-circle" title="Add event"></i>
                </a>
                -->
            </th>
            <th>Event</th>
            <th>Date</th>
            <th>Comments</th>
            <th>By</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let event of events">
            <td class="icon-cell"
                *ngIf="facetPrivilege !== 'ReadOnly'">
                <div class="form-control-plaintext">
                    <a (click)="deleteEvent(event)">
                        <i class="fa fa-remove remove-item"
                           title="Delete event"></i>
                    </a>
                </div>
            </td>
            <td>
                <div class="form-control-plaintext">
                    <span class="event-type-label">{{event.cv_EventType.Label}}</span>
                    <span class="event-label">{{event.Label}}</span>
                </div>
            </td>
            <td style="max-width: 200px;">
                <climb-ngb-date #dateControl="ngModel"
                                [(ngModel)]="event.DateOccurred"
                                [ngModelOptions]="{ standalone: true }"
                                [required]="true">
                </climb-ngb-date>
            </td>
            <td>
                <textarea name="eventComments"
                          [(ngModel)]="event.Description"
                          class="form-control input-large"
                          maxlength="500"
                          rows="1"></textarea>
            </td>
            <td>
                <div class="form-control-plaintext">
                    {{event.CreatedBy | userFullName}}
                </div>
            </td>
        </tr>
    </tbody>
</table>

<p class="form-control-plaintext no-data-message"
   *ngIf="events.length < 1">
    None.
</p>

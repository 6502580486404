import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChildren,
} from '@angular/core';

import { MatingVocabService } from './mating-vocab.service';

import { BaseFilter } from '../common/facet';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
    focusElementByQuery,
} from '../common/util/';
import { NgModel } from '@angular/forms';
import { LoggingService } from '@services/logging.service';
import { dateControlValidator } from '@common/util/date-control.validator';


@Component({
    selector: 'mating-filter',
    templateUrl: './mating-filter.component.html'
})
export class MatingFilterComponent extends BaseFilter implements OnInit {
    @ViewChildren('dateControl') dateControls: NgModel[];
    @Input() filter: any = {};
    @Output() onFilter = new EventEmitter<any>();
    @Output() close = new EventEmitter<any>();

    // CVs
    matingTypes: any[] = [];
    matingStatuses: any[] = [];
    generations: any[] = [];

    matingIdMultiOpen = false;
    animalNameMultiOpen = false;
    animalIdMultiOpen = false;

    readonly MULTI_PASTE_INPUT_LIMIT = 100;

    constructor(
        private matingVocabService: MatingVocabService,
        private activeModal: NgbActiveModal,
        private loggingService: LoggingService,
    ) {
        super(activeModal);
    }

    ngOnInit() {
        this.cloneFilter();

        this.getCVs();

        focusElementByQuery('[data-auto-focus="housingID"] input');
    }

    private cloneFilter() {
        const dateMatingStart = this.filter.dateMatingStart;
        const dateMatingEnd = this.filter.dateMatingEnd;

        this.filter = this.copyFilter(this.filter);

        this.filter.dateMatingStart = dateMatingStart;
        this.filter.dateMatingEnd = dateMatingEnd;
    }

    private getCVs() {
        this.matingVocabService.matingTypes$
            .subscribe((matingTypes) => {
                this.matingTypes = matingTypes;
            });

        this.matingVocabService.matingStatuses$
            .subscribe((matingStatuses) => {
                this.matingStatuses = matingStatuses;
            });

        this.matingVocabService.generations$
            .subscribe((generations) => {
                this.generations = generations;
            });
    }

    filterClicked(event: Event) {
        const errMessage = dateControlValidator(this.dateControls);
        if (errMessage) {
            this.loggingService.logError(errMessage, null, '', true);
            return;
        }

        event.preventDefault();
        this.onFilter.emit(this.filter);
        this.close.emit();
        this.activeModal.close();
    }

    closeClicked() {
        this.close.emit();
        this.activeModal.dismiss();
    }

    clearClicked() {
        this.filter = {};
        this.onFilter.emit(this.filter);
        this.close.emit();
        this.activeModal.close();
    }
}

<!-- Template for output-value headers -->
<ng-template #outputValueHeader let-isOutputHeader="isOutputHeader" let-outputIndex="outputIndex" let-output="output">
    <ng-container *ngIf="isOutputHeader && (cohortMaterials | notEmpty)">
        <a class="add-item-link" *ngIf="!readonly && !output" (click)="addOutputAndValues(outputIndex)">
            <i *ngIf="outputIndex === 0" class="fa fa-plus-circle" title="Add output 1"></i>
            <i *ngIf="outputIndex === 1" class="fa fa-plus-circle" title="Add output 2"></i>
            <i *ngIf="outputIndex === 2" class="fa fa-plus-circle" title="Add output 3"></i>
        </a>
    </ng-container>
    <th *ngIf="!isOutputHeader" class="output-value-header nowrap">
        <ng-container *ngIf="output">
            <span>
                <a *ngIf="!readonly" (click)="removeSelectedOutput(outputIndex)">
                    <i class="fa fa-remove remove-item" title="Remove '{{output?.OutputName}}' output"></i>
                </a>
            </span>
            <span>
                <button [ngClass]="'btn-warning'"
                        *ngIf="!readonly"
                        type="button"
                        title="Refresh output values"
                        class="btn"
                        (click)="refreshOutputValues($event, outputIndex)">
                    <i class="fa fa-history"></i>
                </button>
            </span>
            <span style="padding-right: 20px;">
                <a (click)="tableSort.toggleSort('OutputValue' + (outputIndex + 1))"
                   class="sort-toggle-link"
                   title="Sort by {{output?.OutputName}}">
                    {{output?.OutputName}}
                    <i
                        class="fa"
                        [ngClass]="{
                            'fa-caret-down': tableSort.nested ? tableSort.nestedReverse['OutputValue' + outputIndex + 1] : tableSort.reverse,
                            'fa-caret-up' : tableSort.nested ? !tableSort.nestedReverse['OutputValue' + outputIndex + 1] : !tableSort.reverse
                        }"
                        *ngIf="tableSort.propertyPath === ('OutputValue' + (outputIndex + 1)) || tableSort.nested && tableSort.properties.includes('OutputValue' + (outputIndex + 1))"
                    ></i>
                </a>
            </span>
        </ng-container>
    </th>
</ng-template>

<ng-template #outputRangeSelector let-outputIndex="outputIndex" let-output="output"
             let-outputRangeModal="outputRangeModal">
    <form class="text-body" style="position: relative; overflow-x: auto;">
        <div class="form-group row" style="margin-right: 0;">
            <div class="col-12">
                <input *ngIf="outputIndex === 0" type="checkbox" id="outputSelected-{{outputIndex}}"
                       name="outputIsSelected-{{outputIndex}}" style="padding-left: 15px;"
                       [(ngModel)]="outputRangeModal.output1IsSelected" />
                <input *ngIf="outputIndex === 1" type="checkbox" id="outputSelected-{{outputIndex}}"
                       name="outputIsSelected-{{outputIndex}}" style="padding-left: 15px;"
                       [(ngModel)]="outputRangeModal.output2IsSelected" />
                <input *ngIf="outputIndex === 2" type="checkbox" id="outputSelected-{{outputIndex}}"
                       name="outputIsSelected-{{outputIndex}}" style="padding-left: 15px;"
                       [(ngModel)]="outputRangeModal.output3IsSelected" />
                <label for="outputSelected-{{outputIndex}}">{{output.OutputName}}</label>
            </div>
        </div>

        <div class="form-group row" style="margin-right: 0;">
            <div class="col-md-8">
                <label for="outputMinimum-{{outputIndex}}" class="mt-1">Validation Minimum</label>
            </div>
            <div class="col-md-4" style="padding: 0 0 0 0;">
                <input *ngIf="outputIndex === 0" type="number" id="outputMinimum-{{outputIndex}}"
                       name="outputMinimum-{{outputIndex}}" step="any" [disabled]="!outputRangeModal.output1IsSelected"
                       [(ngModel)]="outputRangeModal.output1Minimum" class="form-control">
                <input *ngIf="outputIndex === 1" type="number" id="outputMinimum-{{outputIndex}}"
                       name="outputMinimum-{{outputIndex}}" step="any" [disabled]="!outputRangeModal.output2IsSelected"
                       [(ngModel)]="outputRangeModal.output2Minimum" class="form-control">
                <input *ngIf="outputIndex === 2" type="number" id="outputMinimum-{{outputIndex}}"
                       name="outputMinimum-{{outputIndex}}" step="any" [disabled]="!outputRangeModal.output3IsSelected"
                       [(ngModel)]="outputRangeModal.output3Minimum" class="form-control">
            </div>
        </div>

        <div class="form-group row" style="margin-right: 0;">
            <div class="col-md-8">
                <label for="outputMaximum-{{outputIndex}}" class="mt-1">Validation Maximum</label>
            </div>
            <div class="col-md-4" style="padding: 0 0 0 0;">
                <input *ngIf="outputIndex === 0" type="number" id="outputMaximum-{{outputIndex}}"
                       name="outputMaximum-{{outputIndex}}" step="any" [disabled]="!outputRangeModal.output1IsSelected"
                       [(ngModel)]="outputRangeModal.output1Maximum" class="form-control">
                <input *ngIf="outputIndex === 1" type="number" id="outputMaximum-{{outputIndex}}"
                       name="outputMaximum-{{outputIndex}}" step="any" [disabled]="!outputRangeModal.output2IsSelected"
                       [(ngModel)]="outputRangeModal.output2Maximum" class="form-control">
                <input *ngIf="outputIndex === 2" type="number" id="outputMaximum-{{outputIndex}}"
                       name="outputMaximum-{{outputIndex}}" step="any" [disabled]="!outputRangeModal.output3IsSelected"
                       [(ngModel)]="outputRangeModal.output3Maximum" class="form-control">
            </div>
        </div>
    </form>
</ng-template>

<climb-column-select [columnLabels]="columnSelect"
                     class="old-fashioned float-right"
                     (onChange)="columnSelectChanged($event)">
</climb-column-select>
<table class="table" 
       droppable [dropDataKey]="cohort.C_Cohort_key"      
       [dropDisabled]="readonly" (drop)="onDropMaterialToCohort($event)"       
       [attr.title]="readonly ? '' : 'Drop new animals here'">
    <thead class="nowrap">
    <tr>
        <th [attr.colspan]="columnSelect?.model?.length + 2"
            style="border: none;">
            Animals ({{cohortMaterials?.length}})
        </th>
        <th *ngIf="cohortMaterials?.length === 0" style="width: 100%; border: none;"></th>
        <th class="nowrap text-align-left"
            style="border: none;">
            <span class="">Outputs</span>
        </th>
        <th style="border: none;">
            <ng-template [ngTemplateOutlet]="outputValueHeader"
                         [ngTemplateOutletContext]="{outputIndex: 0, output: cohort.Output1, isOutputHeader: true}"></ng-template>
        </th>
        <th style="border: none;">
            <ng-template [ngTemplateOutlet]="outputValueHeader"
                         [ngTemplateOutletContext]="{outputIndex: 1, output: cohort.Output2, isOutputHeader: true}"></ng-template>
        </th>
        <th style="border: none; width: 0%;">
            <ng-template [ngTemplateOutlet]="outputValueHeader"
                         [ngTemplateOutletContext]="{outputIndex: 2, output: cohort.Output3, isOutputHeader: true}"></ng-template>
        </th>
    </tr>
    <tr class="detail-grid-header">
        <th>
                <span class="float-right text-nowrap" *ngIf="!readonly">
                    <a class="detail-grid-button" (click)="pasteAnimalsIntoCohort()">
                        <i data-automation-id="paste-animals-button"
                           [ngClass]="{'disabled not-italic': isCohortUsed }" 
                           class="fa fa-paste"
                           [title]=" isCohortUsed ? 'Animals cannot be added or removed from cohorts actively enrolled in studies.' : 'Paste animals'">
                        </i>
                    </a>
                    <a class="detail-grid-button" (click)="copyAnimals()">
                        <i class="fa fa-copy" title="Copy selected animals"></i>
                    </a>
                </span>
        </th>
        <th class="text-nowrap">
            <div style="width: 140px;">
                <bulk-edit-header id="select-output-range-dropdown" [showExtraMenuItems]="true"
                                  [headerText]="countCohortMaterialLabel" [updateButtonText]="'Select'" [hideBulkEditMenuOption]="true"
                                  (menuOpened)="showOutputRangeModal = false;" (updateClicked)="clickSelectOutputRange()"
                                  [dropdownMenuMaxWidth]="outputRangeDropdownMaxWidth"
                                  #bulkEditHeaderComponent>
                    <ng-container extra-menu-items>
                        <a (click)="selectAllAnimals(true)" class="dropdown-item">Select All</a>
                        <a *ngIf="cohort.Output1 || cohort.Output2 || cohort.Output3"
                           class="dropdown-item"
                           onClick="event.stopPropagation();"
                           (click)="showOutputRangeModal=true; bulkEditHeaderComponent.viewBulkEditModal(false)">
                            Output Range
                        </a>
                        <a *ngIf="!cohort.Output1 && !cohort.Output2 && !cohort.Output3"
                           class="dropdown-item"
                           style="pointer-events:none; opacity: 0.6;">
                            Output Range
                        </a>
                        <a (click)="selectAllAnimals(false)" class="dropdown-item">Clear All</a>
                        <a (click)="removeSelectedAnimals()" class="dropdown-item">Delete Selection</a>
                    </ng-container>
                    <div modal-content>
                        <div *ngIf="showOutputRangeModal">
                            <div *ngIf="cohort.Output1 && cohort.Output2 && cohort.Output3" class="row"
                                 style="min-width: 675px;">
                                <div class="col-md-4">
                                    <ng-template *ngIf="cohort.Output1" [ngTemplateOutlet]="outputRangeSelector"
                                                 [ngTemplateOutletContext]="{outputIndex: 0, output: cohort.Output1, outputRangeModal: outputRangeModal}">
                                    </ng-template>
                                </div>
                                <div class="col-md-4">
                                    <ng-template *ngIf="cohort.Output2" [ngTemplateOutlet]="outputRangeSelector"
                                                 [ngTemplateOutletContext]="{outputIndex: 1, output: cohort.Output2, outputRangeModal: outputRangeModal}">
                                    </ng-template>
                                </div>
                                <div class="col-md-4">
                                    <ng-template *ngIf="cohort.Output3" [ngTemplateOutlet]="outputRangeSelector"
                                                 [ngTemplateOutletContext]="{outputIndex: 2, output: cohort.Output3, outputRangeModal: outputRangeModal}">
                                    </ng-template>
                                </div>
                            </div>
                            <div *ngIf="cohort.Output1 && cohort.Output2 && !cohort.Output3" class="row">
                                <div class="col-md-6">
                                    <ng-template *ngIf="cohort.Output1" [ngTemplateOutlet]="outputRangeSelector"
                                                 [ngTemplateOutletContext]="{outputIndex: 0, output: cohort.Output1, outputRangeModal: outputRangeModal}">
                                    </ng-template>
                                </div>
                                <div class="col-md-6">
                                    <ng-template *ngIf="cohort.Output2" [ngTemplateOutlet]="outputRangeSelector"
                                                 [ngTemplateOutletContext]="{outputIndex: 1, output: cohort.Output2, outputRangeModal: outputRangeModal}">
                                    </ng-template>
                                </div>
                            </div>
                            <div *ngIf="cohort.Output1 && !cohort.Output2 && cohort.Output3" class="row">
                                <div class="col-md-6">
                                    <ng-template *ngIf="cohort.Output1" [ngTemplateOutlet]="outputRangeSelector"
                                                 [ngTemplateOutletContext]="{outputIndex: 0, output: cohort.Output1, outputRangeModal: outputRangeModal}">
                                    </ng-template>
                                </div>
                                <div class="col-md-6">
                                    <ng-template *ngIf="cohort.Output3" [ngTemplateOutlet]="outputRangeSelector"
                                                 [ngTemplateOutletContext]="{outputIndex: 2, output: cohort.Output3, outputRangeModal: outputRangeModal}">
                                    </ng-template>
                                </div>
                            </div>
                            <div *ngIf="!cohort.Output1 && cohort.Output2 && cohort.Output3" class="row">
                                <div class="col-md-6">
                                    <ng-template *ngIf="cohort.Output2" [ngTemplateOutlet]="outputRangeSelector"
                                                 [ngTemplateOutletContext]="{outputIndex: 1, output: cohort.Output2, outputRangeModal: outputRangeModal}">
                                    </ng-template>
                                </div>
                                <div class="col-md-6">
                                    <ng-template *ngIf="cohort.Output3" [ngTemplateOutlet]="outputRangeSelector"
                                                 [ngTemplateOutletContext]="{outputIndex: 2, output: cohort.Output3, outputRangeModal: outputRangeModal}">
                                    </ng-template>
                                </div>
                            </div>
                            <div *ngIf="cohort.Output1 && !cohort.Output2 && !cohort.Output3" class="row">
                                <div class="col-md-12">
                                    <ng-template *ngIf="cohort.Output1" [ngTemplateOutlet]="outputRangeSelector"
                                                 [ngTemplateOutletContext]="{outputIndex: 0, output: cohort.Output1, outputRangeModal: outputRangeModal}">
                                    </ng-template>
                                </div>
                            </div>
                            <div *ngIf="!cohort.Output1 && cohort.Output2 && !cohort.Output3" class="row">
                                <div class="col-md-12">
                                    <ng-template *ngIf="cohort.Output2" [ngTemplateOutlet]="outputRangeSelector"
                                                 [ngTemplateOutletContext]="{outputIndex: 1, output: cohort.Output2, outputRangeModal: outputRangeModal}">
                                    </ng-template>
                                </div>
                            </div>
                            <div *ngIf="!cohort.Output1 && !cohort.Output2 && cohort.Output3" class="row">
                                <div class="col-md-12">
                                    <ng-template *ngIf="cohort.Output3" [ngTemplateOutlet]="outputRangeSelector"
                                                 [ngTemplateOutletContext]="{outputIndex: 2, output: cohort.Output3, outputRangeModal: outputRangeModal}">
                                    </ng-template>
                                </div>
                            </div>
                        </div>
                    </div>
                </bulk-edit-header>
            </div>
        </th>
        <th *ngIf="visible.AnimalID">
            <a (click)="tableSort.toggleSort('Material.Identifier')" class="sort-toggle-link" title="Sort by ID">ID {{ tableSort.nested ? tableSort.nestedIndex['Material.Identifier'] : '' }}
                <i class="fa" [ngClass]="{
            'fa-caret-down': tableSort.nested ? tableSort.nestedReverse['Material.Identifier'] : tableSort.reverse,
            'fa-caret-up' : tableSort.nested ? !tableSort.nestedReverse['Material.Identifier'] : !tableSort.reverse
        }"
                   *ngIf="tableSort.propertyPath === 'Material.Identifier' || tableSort.nested && tableSort.properties.includes('Material.Identifier')"></i>
            </a>
        </th>
        <th *ngIf="visible.AnimalName">
            <div style="display: inline-block;">
                    <span>
                        <a (click)="tableSort.toggleSort('Material.Animal.AnimalNameSortable')"
                           class="sort-toggle-link"
                           title="Sort by name">
                            Name {{ tableSort.nested ? tableSort.nestedIndex['Material.Animal.AnimalNameSortable'] : '' }}
                            <i class="fa"
                               [ngClass]="{
                        'fa-caret-down': tableSort.nested ? tableSort.nestedReverse['Material.Animal.AnimalNameSortable'] : tableSort.reverse,
                        'fa-caret-up': tableSort.nested ? !tableSort.nestedReverse['Material.Animal.AnimalNameSortable'] : !tableSort.reverse
                    }"
                               *ngIf="tableSort.propertyPath === 'Material.Animal.AnimalNameSortable' || tableSort.nested && tableSort.properties.includes('Material.Animal.AnimalNameSortable')"></i>
                        </a>
                    </span>
                <span class="text-bold">/</span>
                <ng-container *ngIf="readonly">Rename</ng-container>
                <ng-container *ngIf="!readonly">
                    <bulk-edit-header style="display: inline-block"
                                      #bulkEditHeader [headerText]="'Rename'"
                                      [tooltip]="'Enter multiple names or generate names using a name format.'"
                                      [showExtraMenuItems]="!!nameEditMenuTmpl"
                                      [hideBulkEditMenuOption]="nameEditMenuTmpl"
                                      (updateClicked)="updateClicked()">
                        <ng-container extra-menu-items>
                            <ng-container *ngIf="nameEditMenuTmpl">
                                <ng-template [ngTemplateOutlet]="nameEditMenuTmpl" [ngTemplateOutletContext]="{
                                bulkEditHeaderComponent: bulkEditHeader,
                                item: animalField.__updateObject
                            }"></ng-template>
                            </ng-container>
                        </ng-container>
                        <div modal-content>
                            <ng-container *ngIf="nameTmpl">
                                <ng-template [ngTemplateOutlet]="nameTmpl" [ngTemplateOutletContext]="{
                                item: animalField.__updateObject
                            }"></ng-template>
                            </ng-container>
                        </div>
                    </bulk-edit-header>
                    <span class="float-right">
                        </span>
                </ng-container>
            </div>
        </th>
        <th class="text-nowrap" *ngIf="visible.Genotype">
            <a (click)="customSort(TABLE_SORT_GENOTYPES_PROPERTY_PATH)" class="sort-toggle-link" title="Sort by genotype">
                Genotype  {{ tableSort.nested ? tableSort.nestedIndex[TABLE_SORT_GENOTYPES_PROPERTY_PATH] : '' }}
                <i class="fa" [ngClass]="{
                    'fa-caret-down': tableSort.nested ? tableSort.nestedReverse[TABLE_SORT_GENOTYPES_PROPERTY_PATH] : tableSort.reverse,
                    'fa-caret-up' : tableSort.nested ? !tableSort.nestedReverse[TABLE_SORT_GENOTYPES_PROPERTY_PATH] : !tableSort.reverse }"
                   *ngIf="tableSort.propertyPath === TABLE_SORT_GENOTYPES_PROPERTY_PATH || tableSort.nested && tableSort.properties.includes(TABLE_SORT_GENOTYPES_PROPERTY_PATH)">
                </i>
            </a>
        </th>
        <th *ngIf="visible.Sex">
            <a (click)="tableSort.toggleSort('Material.Animal.cv_Sex.Sex')" class="sort-toggle-link" title="Sort by sex">
                Sex  {{ tableSort.nested ? tableSort.nestedIndex['Material.Animal.cv_Sex.Sex'] : '' }}
                <i class="fa" [ngClass]="{
                    'fa-caret-down': tableSort.nested ? tableSort.nestedReverse['Material.Animal.cv_Sex.Sex'] : tableSort.reverse,
                    'fa-caret-up' : tableSort.nested ? !tableSort.nestedReverse['Material.Animal.cv_Sex.Sex'] : !tableSort.reverse }"
                   *ngIf="tableSort.propertyPath === 'Material.Animal.cv_Sex.Sex' || tableSort.nested && tableSort.properties.includes('Material.Animal.cv_Sex.Sex')">
                </i>
            </a>
        </th>
        <th *ngIf="visible.Status">
            <a (click)="tableSort.toggleSort('Material.Animal.cv_AnimalStatus.AnimalStatus')"
               class="sort-toggle-link"
               title="Sort by status">Status  {{ tableSort.nested ? tableSort.nestedIndex['Material.Animal.cv_AnimalStatus.AnimalStatus'] : '' }}
                <i class="fa" [ngClass]="{
                    'fa-caret-down': tableSort.nested ? tableSort.nestedReverse['Material.Animal.cv_AnimalStatus.AnimalStatus'] : tableSort.reverse,
                    'fa-caret-up' : tableSort.nested ? !tableSort.nestedReverse['Material.Animal.cv_AnimalStatus.AnimalStatus'] : !tableSort.reverse }"
                   *ngIf="tableSort.propertyPath === 'Material.Animal.cv_AnimalStatus.AnimalStatus' || tableSort.nested && tableSort.properties.includes('Material.Animal.cv_AnimalStatus.AnimalStatus')">
                </i>
            </a>
        </th>
        <th *ngIf="visible.MicrochipID">
            <a (click)="tableSort.toggleSort('Material.MicrochipIdentifier')"
               class="sort-toggle-link"
               title="Sort by microchip ID">Microchip ID  {{ tableSort.nested ? tableSort.nestedIndex['Material.MicrochipIdentifier'] : '' }}
                <i class="fa" [ngClass]="{
                    'fa-caret-down': tableSort.nested ? tableSort.nestedReverse['Material.MicrochipIdentifier'] : tableSort.reverse,
                    'fa-caret-up' : tableSort.nested ? !tableSort.nestedReverse['Material.MicrochipIdentifier'] : !tableSort.reverse }"
                   *ngIf="tableSort.propertyPath === 'Material.MicrochipIdentifier' || tableSort.nested && tableSort.properties.includes('Material.MicrochipIdentifier')">
                </i>
            </a>
        </th>
        <th *ngIf="visible.Marker">
            <a (click)="tableSort.toggleSort('Material.Animal.PhysicalMarker')"
               class="sort-toggle-link"
               title="Sort by marker">Marker  {{ tableSort.nested ? tableSort.nestedIndex['Material.Animal.PhysicalMarker'] : '' }}
                <i class="fa" [ngClass]="{
                    'fa-caret-down': tableSort.nested ? tableSort.nestedReverse['Material.Animal.PhysicalMarker'] : tableSort.reverse,
                    'fa-caret-up' : tableSort.nested ? !tableSort.nestedReverse['Material.Animal.PhysicalMarker'] : !tableSort.reverse }"
                   *ngIf="tableSort.propertyPath === 'Material.Animal.PhysicalMarker' || tableSort.nested && tableSort.properties.includes('Material.Animal.PhysicalMarker')">
                </i>
            </a>
        </th>
        <th *ngIf="visible.ExternalID">
            <a (click)="tableSort.toggleSort('Material.ExternalIdentifier')"
               class="sort-toggle-link"
               title="Sort by external ID">External ID  {{ tableSort.nested ? tableSort.nestedIndex['Material.ExternalIdentifier'] : '' }}
                <i class="fa" [ngClass]="{
                    'fa-caret-down': tableSort.nested ? tableSort.nestedReverse['Material.ExternalIdentifier'] : tableSort.reverse,
                    'fa-caret-up' : tableSort.nested ? !tableSort.nestedReverse['Material.ExternalIdentifier'] : !tableSort.reverse }"
                   *ngIf="tableSort.propertyPath === 'Material.ExternalIdentifier' || tableSort.nested && tableSort.properties.includes('Material.ExternalIdentifier')">
                </i>
            </a>
        </th>
        <th *ngIf="visible.HousingID">
            <a (click)="customSort(TABLE_SORT_HOUSING_ID_PROPERTY_PATH)"
               class="sort-toggle-link"
               title="Sort by housing ID">Housing ID  {{ tableSort.nested ? tableSort.nestedIndex[TABLE_SORT_HOUSING_ID_PROPERTY_PATH] : '' }}
                <i class="fa" [ngClass]="{
                    'fa-caret-down': tableSort.nesteTABLE_SORT_PRIOR_HOUSING_ID_PROPERTY_PATHd ? tableSort.nestedReverse[TABLE_SORT_HOUSING_ID_PROPERTY_PATH] : tableSort.reverse,
                    'fa-caret-up' : tableSort.nested ? !tableSort.nestedReverse[TABLE_SORT_HOUSING_ID_PROPERTY_PATH] : !tableSort.reverse }"
                   *ngIf="tableSort.propertyPath === TABLE_SORT_HOUSING_ID_PROPERTY_PATH || tableSort.nested && tableSort.properties.includes(TABLE_SORT_HOUSING_ID_PROPERTY_PATH)">
                </i>
            </a>
        </th>
        <th *ngIf="visible.PriorHousingID">
            <a (click)="customSort(TABLE_SORT_PRIOR_HOUSING_ID_PROPERTY_PATH)"
               class="sort-toggle-link"
               title="Sort by prior housing ID">Prior Housing ID  {{ tableSort.nested ? tableSort.nestedIndex[TABLE_SORT_PRIOR_HOUSING_ID_PROPERTY_PATH] : '' }}
                <i class="fa" [ngClass]="{
                    'fa-caret-down': tableSort.nested ? tableSort.nestedReverse[TABLE_SORT_PRIOR_HOUSING_ID_PROPERTY_PATH] : tableSort.reverse,
                    'fa-caret-up' : tableSort.nested ? !tableSort.nestedReverse[TABLE_SORT_PRIOR_HOUSING_ID_PROPERTY_PATH] : !tableSort.reverse }"
                   *ngIf="tableSort.propertyPath === TABLE_SORT_PRIOR_HOUSING_ID_PROPERTY_PATH || tableSort.nested && tableSort.properties.includes(TABLE_SORT_PRIOR_HOUSING_ID_PROPERTY_PATH)">
                </i>
            </a>
        </th>
        <th *ngIf="visible.AgeDays">
            <a (click)="customSort(TABLE_SORT_ANIMAL_AGE_DAYS_PROPERTY_PATH)"
               class="sort-toggle-link"
               title="Sort by prior age (days)">Age (days)  {{ tableSort.nested ? tableSort.nestedIndex[TABLE_SORT_ANIMAL_AGE_DAYS_PROPERTY_PATH] : '' }}
                <i class="fa" [ngClass]="{
                    'fa-caret-down': tableSort.nested ? !tableSort.nestedReverse[TABLE_SORT_ANIMAL_AGE_DAYS_PROPERTY_PATH] : !tableSort.reverse,
                    'fa-caret-up' : tableSort.nested ? tableSort.nestedReverse[TABLE_SORT_ANIMAL_AGE_DAYS_PROPERTY_PATH] : tableSort.reverse }"
                   *ngIf="tableSort.propertyPath === TABLE_SORT_ANIMAL_AGE_DAYS_PROPERTY_PATH || tableSort.nested && tableSort.properties.includes(TABLE_SORT_ANIMAL_AGE_DAYS_PROPERTY_PATH)">
                </i>
            </a>
        </th>
        <th *ngIf="visible.AgeWeeks">
            <a (click)="customSort(TABLE_SORT_ANIMAL_AGE_WEEKS_PROPERTY_PATH)"
               class="sort-toggle-link"
               title="Sort by prior age (weeks)">Age (weeks)  {{ tableSort.nested ? tableSort.nestedIndex[TABLE_SORT_ANIMAL_AGE_WEEKS_PROPERTY_PATH] : '' }}
                <i class="fa" [ngClass]="{
                    'fa-caret-down': tableSort.nested ? !tableSort.nestedReverse[TABLE_SORT_ANIMAL_AGE_WEEKS_PROPERTY_PATH] : !tableSort.reverse,
                    'fa-caret-up' : tableSort.nested ? tableSort.nestedReverse[TABLE_SORT_ANIMAL_AGE_WEEKS_PROPERTY_PATH] : tableSort.reverse }"
                   *ngIf="tableSort.propertyPath === TABLE_SORT_ANIMAL_AGE_WEEKS_PROPERTY_PATH || tableSort.nested && tableSort.properties.includes(TABLE_SORT_ANIMAL_AGE_WEEKS_PROPERTY_PATH)">
                </i>
            </a>
        </th>
        <th *ngIf="visible.AgeMonths">
            <a (click)="customSort(TABLE_SORT_ANIMAL_AGE_MONTHS_PROPERTY_PATH)"
               class="sort-toggle-link"
               title="Sort by prior age (months)">Age (months)  {{ tableSort.nested ? tableSort.nestedIndex[TABLE_SORT_ANIMAL_AGE_MONTHS_PROPERTY_PATH] : '' }}
                <i class="fa" [ngClass]="{
                    'fa-caret-down': tableSort.nested ? !tableSort.nestedReverse[TABLE_SORT_ANIMAL_AGE_MONTHS_PROPERTY_PATH] : !tableSort.reverse,
                    'fa-caret-up' : tableSort.nested ? tableSort.nestedReverse[TABLE_SORT_ANIMAL_AGE_MONTHS_PROPERTY_PATH] : tableSort.reverse }"
                   *ngIf="tableSort.propertyPath === TABLE_SORT_ANIMAL_AGE_MONTHS_PROPERTY_PATH || tableSort.nested && tableSort.properties.includes(TABLE_SORT_ANIMAL_AGE_MONTHS_PROPERTY_PATH)">
                </i>
            </a>
        </th>
        <th *ngIf="visible.Line">
            <a (click)="tableSort.toggleSort('Material.Line.LineName')"
               class="sort-toggle-link"
               title="Sort by {{'Line' | translate}}">{{'Line' | translate}}  {{ tableSort.nested ? tableSort.nestedIndex['Material.Line.LineName'] : '' }}
                <i class="fa" [ngClass]="{
                    'fa-caret-down': tableSort.nested ? tableSort.nestedReverse['Material.Line.LineName'] : tableSort.reverse,
                    'fa-caret-up' : tableSort.nested ? !tableSort.nestedReverse['Material.Line.LineName'] : !tableSort.reverse }"
                   *ngIf="tableSort.propertyPath === 'Material.Line.LineName' || tableSort.nested && tableSort.properties.includes('Material.Line.LineName')">
                </i>
            </a>
        </th>
        <th class="text-nowrap" *ngIf="visible.OtherCohorts">
            Other Cohorts
        </th>

        <!-- Output Values Headers -->
        <ng-template [ngTemplateOutlet]="outputValueHeader"
                     [ngTemplateOutletContext]="{outputIndex: 0, output: cohort.Output1}"></ng-template>
        <ng-template [ngTemplateOutlet]="outputValueHeader"
                     [ngTemplateOutletContext]="{outputIndex: 1, output: cohort.Output2}"></ng-template>
        <ng-template [ngTemplateOutlet]="outputValueHeader"
                     [ngTemplateOutletContext]="{outputIndex: 2, output: cohort.Output3}"></ng-template>
        <th>&nbsp;</th>
    </tr>
    </thead>

    <!-- Statistics footer -->
    <tfoot cohort-output-stats-rows #cohortOutputStatsRows [cohort]="cohort" [maxOutputs]="3" [columnsReadWrite]="1"
           [columnsTotal]="columnSelect?.model?.length + 5" [readonly]="readonly"
           *ngIf="(cohortMaterials | notEmpty) && (cohort.Output1 || cohort.Output2 || cohort.Output3)">
    </tfoot>

    <tbody>
      <tr *ngFor="let cohortMaterial of cohortMaterials |
          orderBy: tableSort.propertyPath: tableSort.reverse
          let animalIndex = index">
        <td class="icon-cell" *ngIf="!readonly">
            <a (click)="removeAnimal(cohortMaterial)">
                <i [ngClass]="{'disabled not-italic': isCohortUsed }" class="fa fa-remove remove-item"
                   [title]=" isCohortUsed ? 'Animals cannot be added or removed from cohorts actively enrolled in studies.' : 'Remove animal'"></i>
            </a>
        </td>
        <td class="icon-cell">
            <input type="checkbox"
                   name="cohortAnimalIsSelected{{animalIndex}}"
                   [(ngModel)]="cohortMaterial.isSelected"
                   (ngModelChange)="cohortMaterialSelected(cohortMaterial)" />
        </td>
        <td *ngIf="visible.AnimalID">
            <div class="draggable" data-drag-type="material" draggable (dragStart)="dragStart()"
                 (dragStop)="dragStop()" id="{{cohortMaterial?.Material?.C_Material_key}}">
                {{cohortMaterial?.Material?.Identifier}}
            </div>
        </td>
        <td class="text-nowrap" *ngIf="visible.AnimalName">
            {{cohortMaterial.Material?.Animal?.AnimalName}}
        </td>
        <td *ngIf="visible.Genotype">
            <ng-template [ngIf]="cohortMaterial.Material?.Animal?.Genotype?.length > 0">
                <span>{{ getGenotypesSortable(cohortMaterial) }}</span>
            </ng-template>
        </td>
        <td *ngIf="visible.Sex">
            {{cohortMaterial.Material?.Animal?.cv_Sex?.Sex}}
        </td>
        <td *ngIf="visible.Status">
            {{cohortMaterial.Material?.Animal?.cv_AnimalStatus?.AnimalStatus}}
        </td>
        <td *ngIf="visible.MicrochipID">
            {{cohortMaterial.Material?.MicrochipIdentifier}}
        </td>
        <td *ngIf="visible.Marker">
            <span *ngIf="!cohortMaterial.Material?.Animal?.cv_PhysicalMarkerType?.Vendor">{{cohortMaterial.Material?.Animal?.PhysicalMarker}}</span>
            <animal-marker *ngIf="cohortMaterial.Material?.Animal?.cv_PhysicalMarkerType?.Vendor"
                           [marker]="cohortMaterial.Material?.Animal?.PhysicalMarker"
                           [markerType]="cohortMaterial.Material?.Animal?.cv_PhysicalMarkerType">
            </animal-marker>
        </td>
        <td *ngIf="visible.ExternalID">
            {{cohortMaterial.Material?.ExternalIdentifier}}
        </td>
        <td *ngIf="visible.HousingID">
            <ng-template [ngIf]="cohortMaterial.Material?.Animal?.Material?.MaterialPoolMaterial?.length > 0">
                {{ getCurrentHoisingID(cohortMaterial) }}
            </ng-template>
        </td>
        <td *ngIf="visible.PriorHousingID">
            <ng-template [ngIf]="cohortMaterial.Material?.Animal?.Material?.MaterialPoolMaterial?.length > 0">
                {{ getPriorHoisingID(cohortMaterial) }}
            </ng-template>
        </td>
        <td *ngIf="visible.AgeDays">
            {{ageInDays(cohortMaterial)}}
        </td>
        <td *ngIf="visible.AgeWeeks">
            {{ageInWeeks(cohortMaterial)}}
        </td>
        <td *ngIf="visible.AgeMonths">
            {{ageInMonths(cohortMaterial)}}
        </td>
        <td *ngIf="visible.Line">
            {{cohortMaterial.Material?.Line?.LineName}}
        </td>
        <td *ngIf="visible.OtherCohorts">
            <ul class="list-unstyled">
                <ng-container *ngFor="let otherCohortMaterial of cohortMaterial.Material?.CohortMaterial">
                    <li *ngIf="otherCohortMaterial?.Cohort?.CohortName !== cohort.CohortName">
                        {{otherCohortMaterial?.Cohort?.CohortName}}
                    </li>
                </ng-container>
            </ul>
        </td>
        <td class="num-cell col-size-fit">
                <span *ngIf="cohortMaterial.OutputValue1">
                    {{cohortMaterial.OutputValue1}}
                </span>
        </td>
        <td class="num-cell col-size-fit">
                <span *ngIf="cohortMaterial.OutputValue2">
                    {{cohortMaterial.OutputValue2}}
                </span>
        </td>
        <td class="num-cell col-size-fit">
                <span *ngIf="cohortMaterial.OutputValue3">
                    {{cohortMaterial.OutputValue3}}
                </span>
        </td>
        <td>&nbsp;</td>
    </tr>
    </tbody>
</table>


<ng-template #nameEditMenuTmpl let-animal="item" let-bulkEditHeader="bulkEditHeaderComponent">
    <a onClick="event.stopPropagation();"
       class="dropdown-item"
       (click)="animal.useNameFormatInput = false; bulkEditHeader.viewBulkEditModal();"
       title="Enter, scan or paste multiple names to update each animal.  Limit is 600.">
        Enter multiple names
    </a>
    <a onClick="event.stopPropagation();"
       class="dropdown-item"
       (click)="animal.useNameFormatInput = true; bulkEditHeader.viewBulkEditModal();"
       title="Set a prefix, counter, and suffix to generate new names for each animal">
        Generate names using format
    </a>
</ng-template>

<ng-template #nameTmpl let-animal="item">
    <multi-paste-input *ngIf="!animal.useNameFormatInput" [(model)]="animal.AnimalNames" [limit]="600" [rows]="10">
    </multi-paste-input>

    <animal-name-format-form *ngIf="animal.useNameFormatInput" [animal]="animal"></animal-name-format-form>
</ng-template>

import type { Output } from './output.interface';
import type { ProtocolTask } from './protocol-task.interface';

export interface OutputDefault {
    C_EnumerationItem_key: number | null;
    C_OutputDefault_key: number;
    C_Output_key: number;
    C_ProtocolTask_key: number;
    C_Workgroup_key: number;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    ModifiedBy: string;
    Output: Output;
    OutputValue: string;
    ProtocolTask: ProtocolTask;
    Version: number;
}

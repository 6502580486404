import { FacetSharedModule } from './../common/facet/facet-shared.module';
import { AuditSharedModule } from './audit-shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { ClimbCommonModule } from '../common/climb-common.module';
import { ClimbServicesModule } from '../services/climb-services.module';
import { WorkspacesSharedModule } from '../workspaces/workspaces-shared.module';
import { UsersSharedModule } from '../user/users-shared.module';
import { VocabulariesSharedModule } from '../vocabularies/vocabularies-shared.module';
import { StudiesSharedModule } from '../studies/studies-shared.module';
import { AnimalsSharedModule } from '../animals/animals-shared.module';
import { SamplesSharedModule } from '../samples/samples-shared.module';
import { JobsSharedModule } from '../jobs/jobs-shared.module';
import { HousingSharedModule } from '../housing/housing-shared.module';
import { MatingsSharedModule } from '../matings/matings-shared.module';
import { LinesSharedModule } from '../lines/lines-shared.module';
import { InstitutionsSharedModule } from '../institution/institutions-shared.module';
import { CohortSharedModule } from '../cohort/cohort-shared.module';
import { BirthsSharedModule } from '../birth/births-shared.module';
import { LocationsSharedModule } from '../locations/locations-shared.module';
import { ProtocolsSharedModule } from '../protocol/protocols-shared.module';

import { AuditFacetComponent } from './audit-facet.component';
import { AuditFilterComponent } from './audit-filter.component';
import { AuditFilterTemplatesComponent } from './audit-filter-templates.component';
import { AuditModifiedFieldComponent } from './audit-modified-fields/audit-modified-field.component';
import { AuditLinkComponent } from './audit-modified-fields/audit-link/audit-link.component';
import { AuditDataTableComponent } from './audit-data-table/audit-data-table.component';
import { ToolbarAuditTypeDropdownComponent } from './toolbar-audit-type-dropdown/toolbar-audit-type-dropdown.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgbModule,
        ClimbCommonModule,
        FacetSharedModule,
        ClimbServicesModule,
        AuditSharedModule,
        WorkspacesSharedModule,
        UsersSharedModule,
        VocabulariesSharedModule,
        StudiesSharedModule,
        AnimalsSharedModule,
        SamplesSharedModule,
        JobsSharedModule,
        HousingSharedModule,
        MatingsSharedModule,
        LinesSharedModule,
        InstitutionsSharedModule,
        CohortSharedModule,
        BirthsSharedModule,
        LocationsSharedModule,
        ProtocolsSharedModule,
    ],
    declarations: [
        AuditFilterComponent,
        AuditFacetComponent,
        AuditFilterTemplatesComponent,
        AuditModifiedFieldComponent,
        AuditLinkComponent,
        AuditDataTableComponent,
        ToolbarAuditTypeDropdownComponent,
    ],
    exports: [
        AuditFacetComponent
    ],
    providers: [
    ]
})
export class AuditModule { }

import {
    Injectable
} from '@angular/core';

import {
    NgbModal
} from '@ng-bootstrap/ng-bootstrap';

import { AddProtocolTaskComponent } from './add-protocol-task/add-protocol-task.component';
import { AddProtocolSectionComponent } from './add-protocol-section/add-protocol-section.component';

/*
* Service to view the add-protocol-task modal.
*/
@Injectable()
export class ViewAddProtocolTaskComponentService {

    readonly EMPTY_RESULT: any[] = [];

    constructor(
        private modalService: NgbModal
    ) {
        //
    }

    openComponent(isCRO: boolean): Promise<any[]> {
        const modalRef = this.modalService.open(AddProtocolTaskComponent,
            {
                size: isCRO ? 'xl' : 'sample-group-custom',
                backdrop: "static",
                keyboard: false
            });
        const component = modalRef.componentInstance as AddProtocolTaskComponent;
        component.isCRO = isCRO;
        return modalRef.result.then((result) => {
            // Closed
            if (result) {
                return result;
            } else {
                return this.EMPTY_RESULT;
            }
        }, (reason) => {
            // Dismissed
            return this.EMPTY_RESULT;
        });
    }

    openSectionComponent(sections: any, isCRO: boolean): Promise<any[]> {
        const modalRef = this.modalService.open(AddProtocolSectionComponent, 
            {
                size: isCRO ? 'xl' : 'sample-group-custom',
                backdrop: "static",
                keyboard: false
            });
        const component = modalRef.componentInstance as AddProtocolSectionComponent;
        component.isCRO = isCRO;
        component.sections = sections;
        return modalRef.result.then((result) => {
            // Closed
            if (result) {
                return result;
            } else {
                return this.EMPTY_RESULT;
            }
        }, (reason) => {
            // Dismissed
            return this.EMPTY_RESULT;
        });
    }
}

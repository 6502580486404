import {
    AfterViewChecked,
    AfterViewInit,
    Attribute,
    ChangeDetectorRef,
    Component,
    Directive,
    ElementRef,
    HostBinding,
    Inject,
    IterableDiffers,
    QueryList,
    SkipSelf,
} from '@angular/core';
import {
    CDK_TABLE,
    CdkTable,
    RenderRow,
    RowContext,
    STICKY_POSITIONING_LISTENER,
    StickyPositioningListener,
    _COALESCED_STYLE_SCHEDULER,
    _CoalescedStyleScheduler,
} from '@angular/cdk/table';
import {
    _DisposeViewRepeaterStrategy,
    _RecycleViewRepeaterStrategy,
    _VIEW_REPEATER_STRATEGY,
    _ViewRepeater,
} from '@angular/cdk/collections';
import { DragDrop } from '@angular/cdk/drag-drop';
import { SortService } from './sort/sort.service';
import { CLIMB_TABLE } from './table.token';
import type { ClimbColumnDef, ClimbTable, ColumnId } from './table.interface';
import { Optional } from 'ag-grid-community';
import { Directionality } from '@angular/cdk/bidi';
import { DOCUMENT } from '@angular/common';
import { ViewportRuler } from '@angular/cdk/scrolling';
import { Platform } from '@angular/cdk/platform';



/**
 * Enables the recycle view repeater strategy, which reduces rendering latency.
 * Not compatible with tables that animate rows.
 */
@Directive({
    selector: 'climb-table[recycleRows], table[climbTable][recycleRows]',
    providers: [{ provide: _VIEW_REPEATER_STRATEGY, useClass: _RecycleViewRepeaterStrategy }],
})
export class RecycleRowsDirective {}

@Component({
    selector: 'climb-table, table[climbTable]',
    template: `
        <ng-content select="caption"></ng-content>
        <ng-content select="colgroup, col"></ng-content>
        <ng-container headerRowOutlet></ng-container>
        <ng-container rowOutlet></ng-container>
        <ng-container noDataRowOutlet></ng-container>
        <ng-container footerRowOutlet></ng-container>
    `,
    styleUrls: ['./table.component.scss'],
    providers: [
        { provide: CdkTable, useExisting: TableComponent },
        { provide: CDK_TABLE, useExisting: TableComponent },
        { provide: CLIMB_TABLE, useExisting: TableComponent },
        { provide: _COALESCED_STYLE_SCHEDULER, useClass: _CoalescedStyleScheduler },
        { provide: _VIEW_REPEATER_STRATEGY, useClass: _DisposeViewRepeaterStrategy },
        // Prevent nested tables from seeing this table's StickyPositioningListener.
        { provide: STICKY_POSITIONING_LISTENER, useValue: null },
        SortService,
        DragDrop,
    ],
})
export class TableComponent<T> extends CdkTable<T> implements ClimbTable<T>, AfterViewInit, AfterViewChecked {
    /** Overrides the sticky CSS class set by the `CdkTable`. */
    protected stickyCssClass = 'climb-column-sticky';

    /** Overrides the need to add position: sticky on every sticky cell element in `CdkTable`. */
    protected needsPositionStickyOnElement = false;

    private hasStickyColumns = false;

    constructor(
        protected readonly _differs: IterableDiffers,
        protected readonly _changeDetectorRef: ChangeDetectorRef,
        protected readonly _elementRef: ElementRef,
        @Attribute('role') role: string,
        @Optional() protected readonly _dir: Directionality,
        @Inject(DOCUMENT) _document: any,
        _platform: Platform,
        @Optional() @Inject(_VIEW_REPEATER_STRATEGY)
        protected readonly _viewRepeater?: _ViewRepeater<T, RenderRow<T>, RowContext<T>>,
        @Optional() @Inject(_COALESCED_STYLE_SCHEDULER)
        protected readonly _coalescedStyleScheduler?: _CoalescedStyleScheduler,
        @Optional() @Inject(STICKY_POSITIONING_LISTENER)
        protected readonly _stickyPositioningListener?: StickyPositioningListener,
        @Optional() _viewportRuler?: ViewportRuler
  ) {
      super(
          _differs,
          _changeDetectorRef,
          _elementRef,
          role,
          _dir,
          _document,
          _platform,
          _viewRepeater,
          _coalescedStyleScheduler,
          _stickyPositioningListener,
          _viewportRuler,
      );
  }

    @HostBinding('class')
    private get classes(): Record<string, boolean> {
        return {
            ['climb-table']: true,
        };
    }

    ngAfterViewInit(): void {
        this.hasStickyColumns = this._contentColumnDefs.some((columnDef) => {
            return columnDef.sticky || columnDef.stickyEnd;
        });
    }

    ngAfterViewChecked(): void {
        if (this.hasStickyColumns) {
            this.updateStickyColumnStyles();
        }
    }

    private columnDefsMap: Map<ColumnId, ClimbColumnDef>;
    getColumnDefsMap(): Map<ColumnId, ClimbColumnDef> {
        if (!this.columnDefsMap) {
            this.columnDefsMap = new Map();
            (this._contentColumnDefs as QueryList<ClimbColumnDef>).forEach((columnDef) => {
                this.columnDefsMap.set(columnDef.columnId, columnDef);
            });
        }
        return this.columnDefsMap;
    }

    getVisibleColumnDefs(): ClimbColumnDef[] {
        const visibleColumns = [...this._contentHeaderRowDefs.first.columns];
        const columnDefsMap = this.getColumnDefsMap();
        return visibleColumns.map((columnId) => columnDefsMap.get(columnId));
    }
}

/**
 * Count the amount of decimal places.
 * @param num
 */
export function countDecimalPlaces(num: string) {
    if (!num || !isNumeric(num)) {
        return 0;
    }

    const match = ('' + num).match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);
    if (!match) { return 0; }
    return Math.max(
        0,
        // Number of digits right of decimal point.
        (match[1] ? match[1].length : 0)
        // Adjust for scientific notation.
        - (match[2] ? +match[2] : 0));
}

function isNumeric(value: any): boolean {
    return !isNaN(value - parseFloat(value));
}

import {
    Component,
    EventEmitter,
    Input,
    Output
} from '@angular/core';

import { MaterialPoolService } from '../../../services/material-pool.service';

const MAX_SEARCH_RESULTS = 50;

@Component({
    selector: 'housing-multiselect',
    template: `
<!-- Selected housing IDs -->
<div *ngIf="model && model.length > 0">
    <table class="table table-sm table-borderless table-nonfluid">
        <tbody>
            <tr *ngFor="let item of model; let i = index;">
                <td class="icon-cell">
                    <a (click)="removeMaterialPool(i)" title="Remove Housing ID">
                        <i class="fa fa-remove remove-item"></i>
                    </a>
                </td>
                <td>
                    {{item.MaterialPoolID}}
                </td>
            </tr>
        </tbody>
    </table>
</div>

<!-- Housing ID typeahead -->
<climb-indirect-typeahead
        [search]="searchMaterialPools"
        [resultFormatter]="materialPoolIdFormatter"
        placeholder="Housing ID&hellip;"
        (selectItem)="selectMaterialPool($event)"></climb-indirect-typeahead>
`,
    styles: [`
        .table {
            margin-bottom: 0.25em;
        }
    `]
})
export class HousingMultiselectComponent {
    @Input() model: any[];
    @Output() modelChange: EventEmitter<any> = new EventEmitter<any>();

    constructor(
        private materialPoolService: MaterialPoolService
    ) { }

    removeMaterialPool(index: number) {
        if (this.model && index >= 0 && index < this.model.length) {
            this.model.splice(index, 1);
        }
        this.modelChange.emit(this.model);
    }

    searchMaterialPools = (term: string): Promise<any> => {
        return this.materialPoolService.getMaterialPools({
            page: 1,
            size: MAX_SEARCH_RESULTS,
            sort: 'MaterialPoolID asc',
            filter: {
                MaterialPoolID: term
            }
        }).then((results) => {
            // Convert Breeze entity to simple object
            // so it can be serialized by consumers
            return results.results.map((pool: any) => {
                return {
                    MaterialPoolID: pool.MaterialPoolID,
                    C_MaterialPool_key: pool.C_MaterialPool_key
                };
            });
        });
    }

    materialPoolIdFormatter = (value: any) => {
        return value.MaterialPoolID;
    }

    selectMaterialPool(pool: any) {
        if (this.model) {
            this.model.push(pool);
        } else {
            this.model = [pool];
        }
        this.modelChange.emit(this.model);
    }
}

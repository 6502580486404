import {
    AnimationTriggerMetadata,
    animate,
    state,
    style,
    transition,
    trigger,
} from '@angular/animations';

/**
 * This animation transforms the popover's overlay panel on and off the page.
 */
export const transformPanel: AnimationTriggerMetadata = trigger('transformPanel', [
    state(
        'void',
        style({
            opacity: 0,
            transform: 'translateY(-24px)',
        }),
    ),
    transition(
        'void => showing',
        animate(
            '120ms cubic-bezier(0, 0, 0.2, 1)',
            style({
                opacity: 1,
                transform: 'translateY(0)',
            }),
        ),
    ),
    transition(
        '* => void',
        animate(
            '100ms linear',
            style({
                opacity: 0,
                transform: 'translateY(-10px)',
            }),
        ),
    ),
]);

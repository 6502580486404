import { Injectable } from '@angular/core';
import { TranslationService } from '../services/translation.service';

import { CsvExporter } from '../common/export/csv-exporter';
import * as utils from '../common/export/utils';

import { DateFormatterService } from '@common/util/date-time-formatting';
import {
    getSafeProp,
    sortObjectArrayByAccessor,
    sortObjectArrayByProperty,
} from '@common/util';

import { TableSort } from '../common/models';

/*
* Export a line detail record to CSV
*/
@Injectable()
export class ExportLineDetailService {
    csvExporter: CsvExporter;

    constructor(
        private translationService: TranslationService,
        private dateFormatterService: DateFormatterService
    ) {
        this.csvExporter = new CsvExporter();
    }

    /*
    * Assumes line record has all relationships loaded
    */
    exportToCsv(
        line: any,
        taskTableSort: TableSort,
    ) {
        // Defaults
        if (!taskTableSort.propertyPath) {
            taskTableSort.propertyPath = 'TaskInstance.TaskAlias';
        }
        const filename = "LineDetails.csv";

        const data: any[][] = this.buildExportData(
            line,
            taskTableSort
        );

        this.csvExporter.download(data, filename);
    }

    private buildExportData(
        line: any,
        taskTableSort: TableSort
    ): any[][] {
        const data: any[][] = [];

        // First block of common data
        data.push.apply(data, [
            ['Name', line.LineName],
            // We display StockNumber as LineShortName
            [this.translationService.translate('Line') + 'ShortName', line.StockNumber],
            ['Stock', line.StockID],
            ['Type', line.cv_LineType.LineType],
            ['Status', getSafeProp(line, 'cv_LineStatus.LineStatus')],
            ['Species', getSafeProp(line, 'cv_Taxon.CommonName')],
            ['Construct', getSafeProp(line, 'Construct.FullName')]
        ]);

        data.push(['Genotype Assays']);
        // List genotype assay names associated with line
        for (const lineGenotypeAssay of sortObjectArrayByProperty
            (line.LineGenotypeAssay, 'SortOrder')) {
            data.push(['', lineGenotypeAssay.cv_GenotypeAssay.GenotypeAssay]);
        }

        data.push.apply(data, [
            ['Technician', line.Technician],
            ['Parent ' + this.translationService.translate('Line'), line.ParentLine],
            ['Background ' + this.translationService.translate('Line'), line.BackgroundLine],
            ['Breeding Strategy', line.BreedingStrategy],
            ['Development', line.Development],
            ['External Link', line.ExternalLink],
            ['References', line.Reference],
            ['Comments', line.Comment],
            ['Default Location', getSafeProp(line, 'LocationPosition.PositionName')]
        ]);

        this.addTaskData(data, line, taskTableSort);

        return data;
    }

    private addTaskData(
        data: any[][],
        line: any,
        taskTableSort: TableSort
    ) {
        data.push.apply(data, [[], []]);

        // Data on each TaskInstance associated with this line
        for (const task of sortObjectArrayByAccessor(
            line.TaskLine,
            (item) => {
                return getSafeProp(item, taskTableSort.propertyPath);
            },
            taskTableSort.reverse)
        ) {

            // Basic task data
            data.push.apply(data, [
                ['Task Name', task.TaskInstance.TaskAlias],
                ['Task', task.TaskInstance.WorkflowTask.TaskName],
                [
                    this.translationService.translate('Protocol'),
                    getSafeProp(task, "TaskInstance.ProtocolTask.Protocol.ProtocolName")
                ],
                ['Sequence', getSafeProp(task, "Sequence")],
                ['Due Date', this.dateFormatterService.formatDateOnly(task.TaskInstance.DateDue)],
                ['Deviation', getSafeProp(task, "TaskInstance.Deviation")],
                ['Task Location', getSafeProp(task, "TaskInstance.CurrentLocationPath")],
                ['Status', getSafeProp(task, "TaskInstance.cv_TaskStatus.TaskStatus")],
                ['Assigned To', getSafeProp(task, "TaskInstance.AssignedToResource.ResourceName")],
                ['Cost', getSafeProp(task, "TaskInstance.Cost")],
                ['Duration', getSafeProp(task, "TaskInstance.Duration")],
            ]);

            // Task animals
            data.push(['Animals']);
            for (const animal of utils.materialTypeFilter(
                task.TaskInstance.TaskMaterial, 'Animal')) {
                data.push(['', animal.Material.Animal.AnimalName]);
            }
            
            // Task samples
            data.push(['Samples']);
            for (const sample of utils.materialTypeFilter(
                task.TaskInstance.TaskMaterial, 'Sample')) {
                data.push(['', sample.Material.Sample.SampleName]);
            }

            // Task inputs
            data.push(['Inputs']);
            for (const taskInput of sortObjectArrayByProperty(
                task.TaskInstance.TaskInput, 'Input.SortOrder')) {
                data.push([
                    '',
                    taskInput.Input.InputName,
                    taskInput.InputValue
                ]);
            }

            // Task outputs
            data.push(['Outputs']);
            if (task.TaskInstance.TaskOutputSet.length > 0) {
                data.push(['', 'Animals', 'Samples', 'Values']);
            }
            for (const taskOutputSet of task.TaskInstance.TaskOutputSet) {
                const row: string[] = [''];

                // Each type of material involved
                const animals: any[] = utils.materialTypeFilter(
                    taskOutputSet.TaskOutputSetMaterial, 'Animal');
                const samples: any[] = utils.materialTypeFilter(
                    taskOutputSet.TaskOutputSetMaterial, 'Sample');
                row.push.apply(row, [
                    animals
                        .map((a) => a.Material.Animal.AnimalName)
                        .join(', '),
                    samples
                        .map((a) => a.Material.Sample.SampleName)
                        .join(', ')
                ]);

                // Actual key-value outputs
                for (const taskOutput of taskOutputSet.TaskOutput) {
                    row.push.apply(row, [taskOutput.Output.OutputName + ':',
                        taskOutput.OutputValue === 'NaN' ? 0 : taskOutput.OutputValue]);
                }

                data.push(row);
            }
            data.push.apply(data, [[], []]);
        }
    }
}

import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { ENTER, SPACE } from '@angular/cdk/keycodes';
import { checkmark, chevronDown, chevronUp, listView } from '@icons';
import { uniqueId } from '@lodash';
import { TableColumnDef } from '@common/datatable';
import { getUniqueId } from '@common/datatable/utils';
import { arrayContainsAllValues } from '@common/util';

export interface ColumnSelect {
    // Selected columns
    model: string[];
    // Labels
    labels: ColumnSelectLabel[];
}

export class ColumnSelectLabel {
    key: any;
    label: string;
    sortOrder: number;

    constructor(key: any, label: string, sortOrder?: number) {
        this.key = key;
        this.label = label;
        this.sortOrder = sortOrder;
    }
}

export interface ColumnData {
    id: string;
    displayName: string;
    isSelect: boolean;
}

/**
 * Climb dropdown column component.
 */
@Component({
    selector: 'climb-column-select',
    templateUrl: './toolbar-column-select.component.html',
    styleUrls: ['./toolbar-column-select.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToolbarColumnSelectComponent implements OnInit, OnChanges, OnDestroy {
    @Input() disabled = false;
    @Input() set columnDef(values: TableColumnDef[]) {
        this.columns = values.map((value: TableColumnDef) => ({
            id: getUniqueId(value),
            displayName: value.displayName,
            isSelect: value.visible ?? true,
        }));
    }

    /*
     * @deprecated Components using this @Input must be rewritten.
     * The data of the displayed columns and their state (selected or not)
     * must be in a single object but not separated as described by
     * the ColumnSelect interface
     */
    @Input() set columnLabels(value: ColumnSelect) {
        const labels = value?.labels ?? [];
        const model = value?.model ?? [];
        this.columns = labels.map((label) => ({
            id: label.key,
            displayName: label.label,
            isSelect: model.includes(label.key),
        }));
    }

    @Output() onChange: EventEmitter<ColumnData['id'][]> = new EventEmitter();
    @Output() onClose: EventEmitter<ColumnData['id'][]> = new EventEmitter();

    readonly icons = { listView, chevronDown, chevronUp, checkmark };

    /** Unique popover panel id */
    panelId: string;
    /** Unique id of the button that controls popover */
    buttonId: string;

    popoverToggle: EventEmitter<boolean> = new EventEmitter();

    columns: ColumnData[] = [];
    
    priorVisibleColumns: string[] = [];

    ngOnInit(): void {
        this.panelId = `dropdown-list-${uniqueId()}`;
        this.buttonId = `dropdown-button-${uniqueId()}`;
        this.popoverToggle.subscribe((value) => {
            if (!value && !arrayContainsAllValues(this.priorVisibleColumns, this.getVisibleColumns())) {
                this.close();
                this.priorVisibleColumns = this.getVisibleColumns();
            }
        })
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.columnDef) {
            this.priorVisibleColumns = this.getVisibleColumns().slice();
        }
    }

    ngOnDestroy(): void {
        this.popoverToggle.unsubscribe();
    }

    selectAllClick() {
        this.columns.forEach((column: ColumnData): void => {
            column.isSelect = true;
        });
        this.emitChanges();
    }

    clearAllClick() {
        this.columns.forEach((column: ColumnData, index: number): void => {
            column.isSelect = index === 0; // visible only first column
        });
        this.emitChanges();
    }

    columnChange(column: ColumnData): void {
        if (this.isLastSelectedColumn(column)) {
            return;
        }
        column.isSelect = !column.isSelect;
        this.emitChanges();
    }

    onColumnKeyDown(event: KeyboardEvent, column: ColumnData): void {
        const keyCode = event.keyCode;
        if (keyCode === ENTER || keyCode === SPACE) {
            event.preventDefault();
            this.columnChange(column);
        }
    }

    close(): void {
        this.onClose.emit(this.getVisibleColumns());
    }

    private emitChanges(): void {
        this.onChange.emit(this.getVisibleColumns());
    }

    private getVisibleColumns(): ColumnData['id'][] {
        const visibleColumnsIds: ColumnData['id'][] = this.columns
            .filter(({ isSelect }) => isSelect)
            .map(({ id }) => id);
        return visibleColumnsIds;
    }

    private isLastSelectedColumn(column: ColumnData): boolean {
        // amount of all selected columns should be 1
        // and the current column should be selected
        if (!column.isSelect) {
            return false;
        }
        const selectedColumnsCount = this.columns
            .filter(({ isSelect }: ColumnData) => isSelect)
            .length;
        return selectedColumnsCount === 1;
    }
}

import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChildren,
} from '@angular/core';

import { CensusService } from './census.service';
import { VocabularyService } from '../vocabularies/vocabulary.service';
import { ReportingService } from '../reporting/reporting.service';
import { TranslationService } from '../services/translation.service';
import { ColumnSelectLabel, ColumnSelect } from '@common/facet';
import { BooleanMap } from '../workflow/models/workflow-bulk-data';
import { notEmpty } from '../common/util';
import { ConfirmService } from '../common/confirm';
import { EntityQuery, Predicate } from 'breeze-client';
import { DataManagerService } from '../services/data-manager.service';
import { LoggingService } from '../services/logging.service';
import { AnimalService } from '../animals/services/animal.service';
import { NgModel } from '@angular/forms';
import { dateControlValidator } from '@common/util/date-control.validator';

@Component({
    selector: 'census-bulk-edit',
    templateUrl: './census-bulk-edit.component.html'
})
export class CensusBulkEditComponent implements OnInit {
    @ViewChildren('dateControl') dateControls: NgModel[];
    @Input() facet: any;
    @Input() housing: any;
    @Output() exit: EventEmitter<void> = new EventEmitter<void>();
    @Output() back: EventEmitter<void> = new EventEmitter<void>();
    @Output() updateSums: EventEmitter<boolean> = new EventEmitter<boolean>();

    // State
    saving = false;

    animalStatuses: any[] = [];
    birthStatuses: any[] = [];
    animalUses: any[] = [];
    sexes: any[] = [];
    housingTypes: any[] = [];
    housingStatuses: any[] = [];
    markerTypes: any[] = [];
    discrepancies: any[] = [];

    loading = false;
    bulk: any = null;
    defaultDiscrepancy: any = null;

    cageCardReportTypes: any[] = [];
    readonly MULTI_PASTE_INPUT_LIMIT = 150;

    // Column Select state
    columnSelect: ColumnSelect = { model: [], labels: [] };

    // Table column visibility flags
    visible: BooleanMap = {};

    scanTimeout: any;

    readonly COMPONENT_LOG_TAG = 'census-bulk-edit';

    constructor(
        private censusService: CensusService,
        private vocabularyService: VocabularyService,
        private reportingService: ReportingService,
        private translationService: TranslationService,
        private confirmService: ConfirmService,
        private dataManager: DataManagerService,
        private loggingService: LoggingService,
        private animalService: AnimalService
    ) {
        
    }

    // lifecycle
    ngOnInit() {
        this.getCVs();

        this.initialize();
        this.initColumnSelect();
    }

    initialize() {
        this.bulk = {
            statusKey: null,
            discrepancyKey: null,
            marker: null,
            sexKey: null,
            useKey: null,
            breedingKey: null,
            force: false,
            materialchipID: null,
            ScanIdentifiers: []
        };

        this.cageCardReportTypes = [
            'Mating',
            'Wean',
            this.translationService.translate('Job')
        ];
    }

    bulkScanChanged() {
        if (this.bulk.ScanIdentifiers.length < 1) {
            this.initialize();
            return;
        }
        // Scan for multiple 
        const uniqueScans: string[] = Array.from(this.bulk.ScanIdentifiers.reduce((m: any, t: string) => m.set(t, t.toUpperCase()), new Map()).values());
        const remainingScans: string[] = [];
        
        // Match for any names already there
        const namesMap = this.housing.CensusMaterialPoolMaterial.reduce((m: any, t: any, i: any) => m.set(t.Material.Animal.AnimalName, i), new Map());
        for (const uniqueScan of uniqueScans) {
            if (namesMap.has(uniqueScan)) {
                const index = namesMap.get(uniqueScan);
                this.housing.CensusMaterialPoolMaterial[index].MaterialScanValue = uniqueScan;
            } else {
                remainingScans.push(uniqueScan);
            }
        }

        // Remaining should be checked for matching animal names and be considered as microchip ID
        if (remainingScans.length > 0) {
            const leftEntries = [];
            const microchipIdsMap = this.housing.CensusMaterialPoolMaterial.reduce((m: any, t: any, i: any) => m.set(t.Material.MicrochipIdentifier, i), new Map());
            for (const remainingScan of remainingScans) {
                if (microchipIdsMap.has(remainingScan)) {
                    const index = microchipIdsMap.get(remainingScan);
                    this.housing.CensusMaterialPoolMaterial[index].MaterialScanValue = this.housing.CensusMaterialPoolMaterial[index].Material.Animal.AnimalName;
                } else {
                    leftEntries.push(remainingScan);
                }
            }
            if (leftEntries.length > 0) {
                // show caution message here
                this.loggingService.logWarning(`${leftEntries.length} scan(s) didn't match anything.`, null, this.COMPONENT_LOG_TAG, true);
            }
        }
        this.initialize();
    }

    async bulkStatusChanged() {
        const changedAnimals: any[] = [];
        for (const material of this.housing.CensusMaterialPoolMaterial) {
            if (this.bulk.force || !material.Material.Animal.C_AnimalStatus_key) {
                changedAnimals.push(material.Material.Animal);
            }
        }

        await this.animalService.statusBulkChangePostProcess(changedAnimals, this.bulk.statusKey);
        this.initialize();
    }

    animalStatusChanged(animal: any) {
        this.animalService.statusChangePostProcess(animal);
    }

    bulkBreedingChanged() {
        for (const material of this.housing.CensusMaterialPoolMaterial) {
            if (this.bulk.force || !material.Material.Animal.C_BreedingStatus_key) {
                material.Material.Animal.C_BreedingStatus_key = this.bulk.breedingKey;
            }
        }
        this.initialize();
    }

    bulkUseChanged() {
        for (const material of this.housing.CensusMaterialPoolMaterial) {
            if (this.bulk.force || !material.Material.Animal.C_AnimalUse_key) {
                material.Material.Animal.C_AnimalUse_key = this.bulk.useKey;
            }
        }
        this.initialize();
    }

    bulkSexChanged() {
        for (const material of this.housing.CensusMaterialPoolMaterial) {
            if (this.bulk.force || !material.Material.Animal.C_Sex_key) {
                material.Material.Animal.C_Sex_key = this.bulk.sexKey;
            }
        }
        this.initialize();
    }

    bulkMarkerChanged() {
        for (const material of this.housing.CensusMaterialPoolMaterial) {
            if ((this.bulk.force || !material.Material.Animal.PhysicalMarker) && (!material.Material.Animal.cv_PhysicalMarkerType || !material.Material.Animal.cv_PhysicalMarkerType.Vendor)) {
                material.Material.Animal.PhysicalMarker = this.bulk.marker;
            }
        }
        this.initialize();
    }

    bulkDiscrepancyChanged() {
        for (const material of this.housing.CensusMaterialPoolMaterial) {
            if (this.bulk.force || !material.C_CensusDiscrepancy_key) {
                material.C_CensusDiscrepancy_key = this.bulk.discrepancyKey;
            }
        }
        this.initialize();
    }

    /**
     * Check for a match when the ScanValue changes
     */
    scanValueChanged(scanRow: any) {
        // Clear any check that is underway
        window.clearTimeout(this.scanTimeout);

        if (!scanRow) {
            // Nothing to check
            return;
        }

        const animal = scanRow.Material.Animal;

        // Get the Scan input
        let scanValue = scanRow.MaterialScanValue;
        if (scanValue !== null) {
            scanValue = scanValue.trim();
        }

        if ((scanValue === null) || (scanValue === '')) {
            // Nothing was scanned
            return;
        }


        // Make it upper case just in case
        scanValue = scanValue.toUpperCase();

        if ((scanValue === animal.AnimalName) ||
            (scanValue === animal.Material.MicrochipIdentifier)) {
            // The scan matches the current animal
            scanRow.MaterialScanValue = animal.AnimalName;
            return;
        }

        // Check the DB for matching animals with a small debounce timeout
        this.scanTimeout = window.setTimeout(() => {
            // Check if the scan matches any active animals
            const query = EntityQuery.from('Animals')
                .where(Predicate.and([
                    Predicate.or([
                        Predicate.create('AnimalName', 'eq', scanValue),
                        Predicate.create('Material.MicrochipIdentifier', 'eq', scanValue)
                    ]),
                    Predicate.create('cv_AnimalStatus.IsExitStatus', 'ne', true)
                ]));

            this.dataManager.returnQueryResults(query).then((found) => {
                if ((scanRow.MaterialScanValue === null) ||
                    (scanRow.MaterialScanValue.trim() !== scanValue)) {
                    // The ScanValue has changed since the query started.
                    return;
                }
                
                if (found.length === 1) {
                    scanRow.MaterialScanValue = found[0].AnimalName;
                } else if (found.length > 1) {
                    this.loggingService.logWarning(
                        `Found ${found.length} animals that match the scan value “${scanValue}”.`,
                        null, this.COMPONENT_LOG_TAG, true
                    );
                }
            });
        }, 300);
    }

    /**
     * Initialize the column selection and visibility flags.
     */
    initColumnSelect() {
        // Assemble the list of all columns that can be selected
        const labels = [
            new ColumnSelectLabel('ID', 'ID'),
            new ColumnSelectLabel('Name', 'Name'),
            new ColumnSelectLabel('Scan', 'Scan'),
            new ColumnSelectLabel('MicrochipID', 'Microchip ID'),
            new ColumnSelectLabel('Line', this.translationService.translate('Line')),
            new ColumnSelectLabel('Status', 'Status'),
            new ColumnSelectLabel('BreedingStatus', 'Breeding Status'),
            new ColumnSelectLabel('Use', 'Use'),
            new ColumnSelectLabel('Sex', 'Sex'),
            new ColumnSelectLabel('Marker', 'Marker'),
            new ColumnSelectLabel('Discrepancy', 'Discrepancy'),
        ];

        // Default column visibility
        this.visible = {
            ID: true,
            Name: true,
            Scan: true,
            MicrochipID: true,
            Line: true,
            Status: true,
            BreedingStatus: true,
            Use: true,
            Sex: true,
            Marker: true,
            Discrepancy: true,
        };

        const model = labels.filter((item) => {
                return this.visible[item.key];
        }).map((item) => item.key);

        this.columnSelect = { model, labels };

        // Update the column visiblility
        this.updateVisible();
    }

    /**
     * Column selections have changed
     */
    columnSelectChanged(current: string[]) {
        // Get the current selections
        this.columnSelect.model = current;

        // Update the column visibilty
        this.updateVisible();

    }

    /**
     * Update the column visibility flags.
     */
    updateVisible() {
        // Make a lookup table
        const selected = {};
        this.columnSelect.model.forEach((key) => {
            selected[key] = true;
        });

        // Update the visibilty based on the column selections
        this.columnSelect.labels.forEach((column) => {
            const key = column.key;
            this.visible[key] = (selected[key] === true);
        });
    }

    private getCVs() {
        this.vocabularyService.getCV('cv_AnimalStatuses').then((data) => {
            this.animalStatuses = data;
        });        
        this.vocabularyService.getCV('cv_BreedingStatuses').then((data) => {
            this.birthStatuses = data;
        });
        this.vocabularyService.getCV('cv_AnimalUses').then((data) => {
            this.animalUses = data;
        });
        this.vocabularyService.getCV('cv_Sexes').then((data) => {
            this.sexes = data;
        });
        this.vocabularyService.getCV('cv_MaterialPoolTypes').then((data) => {
            this.housingTypes = data;
        });
        this.vocabularyService.getCV('cv_MaterialPoolStatuses').then((data) => {
            this.housingStatuses = data;
        });
        this.vocabularyService.getCV('cv_PhysicalMarkerTypes').then((data) => {
            this.markerTypes = data;
        });
        this.vocabularyService.getCV('cv_CensusDiscrepancies').then((data) => {
            this.discrepancies = data;
            data.forEach((discrepancy: any) => {
                if (discrepancy.IsDefault) {
                    this.defaultDiscrepancy = discrepancy.C_CensusDiscrepancy_key;
                }
            });
        });
    }


    saveChanges() {
        this.checkForUnscanned(false);
    }

    saveChangesAndScan() {
        this.checkForUnscanned(true);
    }

    checkForUnscanned(updateSumsBool: boolean) {
        const errorMessage = dateControlValidator(this.dateControls);
        if (errorMessage) {
            this.loggingService.logError(errorMessage, null, '', true);
            return;
        }
        const numUnscanned = this.housing.CensusMaterialPoolMaterial.filter((material: any) => {
            return material.MaterialScanValue !== material.Material.Animal.AnimalName;
        }).length;
        if (numUnscanned > 0) {
            const modalOptions = {
                title: 'Are you sure you want to exit?',
                message: `You still have ${numUnscanned} animal(s) to scan`
            };
            this.confirmService.confirm(modalOptions).then(
                () => {
                    this.updateSums.emit(updateSumsBool);
                },
                () => {
                    // Do nothing and let them continue editing
                });
        } else {
            // No unscanned animals found
            return this.updateSums.emit(updateSumsBool);
        } 
    }

    exitClicked() {
        this.censusService.cancelHousing(this.housing);
        this.exit.emit();
    }

    // Cage Cards
    requestCageCard(reportType: string) {
        const jobTranslated = this.translationService.translate('Job');

        switch (reportType) {
            case jobTranslated:
                const materialKeys: number[] = this.getAllAnimalMaterialKeys();
                if (notEmpty(materialKeys)) {
                    this.reportingService.requestCageCardJobByAnimalKeys(materialKeys);
                }
                break;
            case 'Mating':
                this.reportingService.requestCageCardMating([this.housing.C_MaterialPool_key]);
                break;
            case 'Wean':
                this.reportingService.requestCageCardWean([this.housing.C_MaterialPool_key]);
                break;
        }
    }

    private getAllAnimalMaterialKeys(): number[] {
        return this.housing.MaterialPool.MaterialPoolMaterial
            .filter((material: any) => {
                return material.DateOut === null;
            })
            .map((material: any) => {
                return material.C_Material_key;
            });
    }

    goBack() {
        this.back.emit();
    }

    // <select> formatters
    discrepancyKeyFormatter = (value: any) => {
        return value.C_CensusDiscrepancy_key;
    }
    discrepancyStatusFormatter = (value: any) => {
        return value.CensusDiscrepancy;
    }
    materialPoolStatusKeyFormatter = (value: any) => {
        return value.C_MaterialPoolStatus_key;
    }
    materialPoolStatusStatusFormatter = (value: any) => {
        return value.MaterialPoolStatus;
    }
    materialPoolTypesKeyFormatter = (value: any) => {
        return value.C_MaterialPoolType_key;
    }
    materialPoolTypesStatusFormatter = (value: any) => {
        return value.MaterialPoolType;
    }
    sexKeyFormatter = (value: any) => {
        return value.C_Sex_key;
    }
    sexStatusFormatter = (value: any) => {
        return value.Sex;
    }
    animalUseKeyFormatter = (value: any) => {
        return value.C_AnimalUse_key;
    }
    animalUseStatusFormatter = (value: any) => {
        return value.AnimalUse;
    }
    birthStatusKeyFormatter = (value: any) => {
        return value.C_BreedingStatus_key;
    }
    birthStatusStatusFormatter = (value: any) => {
        return value.BreedingStatus;
    }
    animalStatusKeyFormatter = (value: any) => {
        return value.C_AnimalStatus_key;
    }
    animalStatusStatusFormatter = (value: any) => {
        return value.AnimalStatus;
    }
}

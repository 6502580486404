import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { DragulaModule } from 'ng2-dragula';

import { ClimbCommonModule } from '../common/climb-common.module';
import { ClimbServicesModule } from '../services/climb-services.module';
import { WorkspacesSharedModule } from '../workspaces/workspaces-shared.module';

import { RolesFacetComponent } from './roles-facet.component';
import { FacetSharedModule } from '../common/facet/facet-shared.module';


@NgModule({
    imports: [
        CommonModule,
        DragulaModule,
        FormsModule,
        ClimbCommonModule,
        ClimbServicesModule,
        FacetSharedModule,
        WorkspacesSharedModule,
    ],
    declarations: [
        RolesFacetComponent,
    ],
    exports: [
        RolesFacetComponent,
    ],
})
export class RolesModule { }

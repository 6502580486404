<div class="wizard-body">

    <ng-container *ngIf="savingData; else data_saved">
        <div class="saving-message bg-info">
            <h4 class="wizard-heading">
                <i class="fa fa-2x fa-spinner fa-spin" aria-hidden="true"></i>
                Setting up your workgroup&hellip;
            </h4>

            <p>
                It may take a minute or so.
            </p>
        </div>
    </ng-container>

    <ng-template #data_saved>

        <ng-container *ngIf="!hasError">
            <h4 class="wizard-heading text-success">
                <i class="fa fa-2x fa-check-circle" aria-hidden="true"></i>
                Congratulations! Your initial workgroup set-up is complete.
            </h4>

            <p>
                <img src="/images/icon-green-3.png"
                     alt="Climb logo"
                     height="80" width="80"
                     class="float-right inline-image-right" />
                Click <em>Finish</em> to close this wizard and go to the <em>Dashboard</em> for your workgroup.
                The <em>Dashboard</em> will provide an overview of your {{'jobs' | translate}}, tasks, and animals.
            </p>

            <p>
                To learn more, please visit the Climb
                <a href="https://rockstepsolutions.zendesk.com/hc/en-us/categories/115000062888-Getting-Started"
                   target="_blank" rel="noopener">
                    <em>Getting Started</em>
                </a>
                documentation.
            </p>
        </ng-container>

        <ng-container *ngIf="hasError">
            <h4 class="wizard-heading text-danger">
                <i class="fa fa-2x fa-exclamation-triangle" aria-hidden="true"></i>
                We apologize: an error occurred while saving your workgroup.
            </h4>

            <p>
                The values you entered in the wizard are safe, though.
            </p>
            <p>
                Please contact
                <a href="{{supportUri}}" target="_blank" rel="noopener">Climb Support</a>
                for assistance.
            </p>
        </ng-container>

    </ng-template>

</div>

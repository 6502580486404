import { map } from 'rxjs/operators';
import { WorkflowLogicService } from '../../services/workflow-logic.service';
import { WorkflowService } from '../../services/workflow.service';
import { QueryDef } from '@services/query-def';
import { WorkflowVocabService } from '../../services/workflow-vocab.service';
import { TranslationService } from '@services/translation.service';
import { 
    BulkEditOptions, 
    BulkEditSection
} from '@common/facet/models';
import {
    Component,
    Input,
    OnInit,
    TemplateRef,
    ViewChild,
    AfterViewInit,
    ViewChildren,
} from '@angular/core';

import { notEmpty, getSafeProp } from '@common/util';

import { FacetLoadingStateService } from '@common/facet';
import { DotmaticsService } from '../../../dotmatics/dotmatics.service';
import { Entity, Resource, TaskInstance, cv_TaskStatus } from '@common/types';
import { AuthService } from '@services/auth.service';
import { ResourceService } from '../../../resources';
import { DataManagerService } from '@services/data-manager.service';
import { LogLevel } from '@services/models';
import { ToastrService } from '@services/toastr.service';
import { NgModel } from '@angular/forms';
import { dateControlValidator } from '@common/util/date-control.validator';

/**
 * Shared component and configuration templates
 * for BulkAdd and BulkEdit tables
 * 
 * 
 */
@Component({
    selector: 'workflow-bulk-templates',
    templateUrl: './workflow-bulk-templates.component.html'
})
export class WorkflowBulkTemplatesComponent implements OnInit, AfterViewInit {
    @ViewChildren('dateControl') dateControls: NgModel[];
    @Input() tasks: Entity<TaskInstance>[];

    // bulk edit input templates
    @ViewChild('jobIdTmpl') jobIdTmpl: TemplateRef<any>;
    @ViewChild('taskAliasTmpl') taskAliasTmpl: TemplateRef<any>;
    @ViewChild('taskNameTmpl') taskNameTmpl: TemplateRef<any>;
    @ViewChild('assignedToTmpl') assignedToTmpl: TemplateRef<any>;
    @ViewChild('dateDueTmpl') dateDueTmpl: TemplateRef<any>;
    @ViewChild('dateCompleteTmpl') dateCompleteTmpl: TemplateRef<any>;
    @ViewChild('statusTmpl') statusTmpl: TemplateRef<any>;
    @ViewChild('isLockedTmpl') isLockedTmpl: TemplateRef<any>;

    // vocabs
    taskStatuses: cv_TaskStatus[];

    readonly COMPONENT_LOG_TAG = 'workflow-bulk-edit';

    bulkOptions: BulkEditOptions;
    BulkEditSection = BulkEditSection;
    currentResourceKey: number | null;

    // Dotmatics workgroup flag
    isDotmatics: boolean;

    constructor(
        private facetLoadingState: FacetLoadingStateService,
        private translateService: TranslationService,
        private workflowService: WorkflowService,
        private workflowLogicService: WorkflowLogicService,
        private workflowVocabService: WorkflowVocabService,
        private dotmaticsService: DotmaticsService,
        private authService: AuthService,
        private resourceService: ResourceService,
        private toastrService: ToastrService,
        private dataManager: DataManagerService,
    ) {
    }

    // lifecycle
    ngOnInit() {
        this.initialize();
        this.getData();
    }

    /**
     * Configuration with TemplateRefs can only be assigned
     *   after "ngAfterViewInit".
     * Otherwise they will be undefined.
     */
    ngAfterViewInit() {
        // assign all the BulkAdd and BulkEdit configuration options
        this.bulkOptions = {
            itemTypeLabel: "Task",
            itemTypeLabelPlural: "Tasks",
            clearForm: false,
            fields: [
                {
                    label: this.translateService.translate('Job'),
                    modelPath: 'JobID',
                    template: this.jobIdTmpl,
                    hideFromAddScreen: true,
                    hideFromEditHeader: true
                },
                {
                    label: 'Task Name',
                    modelPath: 'TaskAlias',
                    template: this.taskAliasTmpl,
                    hideFromAddScreen: true,
                    hideFromEditHeader: true
                },
                {
                    label: 'Task',
                    modelPath: 'WorkflowTask.TaskName',
                    template: this.taskNameTmpl,
                    hideFromAddScreen: true,
                    hideFromEditHeader: true
                },
                {
                    label: 'Assigned To',
                    modelPath: 'C_AssignedTo_key',
                    template: this.assignedToTmpl,
                    hideFromAddScreen: true,
                    onUpdateItem: (item, value) => {
                        this.fillDownAssignedTo(value);
                    }
                },
                {
                    label: 'Due Date',
                    modelPath: 'DateDue',
                    template: this.dateDueTmpl,
                    hideFromAddScreen: true,
                    onUpdateItem: (item, value) => {
                        this.fillDownDateDue(value);
                    }
                },
                {
                    label: 'Complete Date',
                    modelPath: 'DateComplete',
                    template: this.dateCompleteTmpl,
                    hideFromAddScreen: true,
                },
                {
                    label: 'Status',
                    modelPath: 'C_TaskStatus_key',
                    template: this.statusTmpl,
                    hideFromAddScreen: true,
                    onUpdateItem: (item, value) => {
                        this.fillDownStatus(value);
                    }
                },
                {
                    label: 'Locked',
                    modelPath: 'IsLocked',
                    template: this.isLockedTmpl,
                    hideFromAddScreen: true,
                    hideFromEditHeader: true
                }
            ]
        };

    }

    initialize() {

         // Copy the input so we don't touch the grid data
        this.tasks = this.tasks.slice();
        this.setIsDotmatics();
        this.initCurrentResource();
    }

    async getData() {
        this.facetLoadingState.changeLoadingState(true);

        return this.getDetails().then(() => {
            return this.getCVs();
        }).then(() => {
            this.facetLoadingState.changeLoadingState(false);
        }).catch((error) => {
            this.facetLoadingState.changeLoadingState(false);
            throw error;
        });
    }

    async getDetails(): Promise<any[]> {
        if (notEmpty(this.tasks)) {
            const queryDef: QueryDef = {
                page: 0,
                size: this.tasks.length,
                filter: {
                    C_WorkflowTask_keys: this.tasks.map((task) => {
                        return task.C_WorkflowTask_key;
                    })
                },
                expands: [
                    'cv_TaskStatus',
                    'WorkflowTask.cv_TaskType',
                    'TaskJob.Job',
                    'AssignedToResource',
                    'TaskInput.Input'
                ]
            };

            const p1 = this.workflowService.getTasks(queryDef);

            const p2 = this.workflowService.ensureBulkEditAssociatedDataLoaded(this.tasks);

            return Promise.all([p1, p2]).then((responses) => {
                return this.tasks;
            });
        }

        return Promise.resolve(this.tasks);
    }

    getCVs(): Promise<any> {
        const p1: Promise<any> = this.workflowVocabService.taskStatuses$.pipe(map((data) => {
            this.taskStatuses = data;
        })).toPromise();

        return Promise.all([p1]);
    }

    async initCurrentResource(): Promise<void> {
        return this.resourceService.getCurrentUserResource().then((resource: Resource) => {
            this.currentResourceKey = resource ? resource.C_Resource_key : null;
        });
    }

    // Status
    fillDownStatus(taskStatusKey: number) {
        if (taskStatusKey) {
            for (const task of this.tasks) {
                task.C_TaskStatus_key = taskStatusKey;
                this.taskStatusChanged(task);
            }
        }
    }
    taskStatusChanged(task: TaskInstance) {
        this.workflowLogicService.taskStatusChanged(task);
        if (this.isDotmatics && task.C_TaskInstance_key) {
            const isDefaultEndState: boolean = getSafeProp(task, 'cv_TaskStatus.IsDefaultEndState');
            if (isDefaultEndState) {
                this.syncDotmaticsSample(task);
            }
        }
    }

    completedDataChanged(task: TaskInstance, date?: Date) {
        this.workflowLogicService.taskCompletedDataChanged(task, date);
    }

    syncDotmaticsSample(task: any) {
        const outputSamples = this.dotmaticsService.filterOutputSamples(task); 
        if (outputSamples.length === 1) {
            if (!outputSamples[0].Material.ExternalIdentifier && !task.dtxSynchronized) {
                // Post sample
                this.dotmaticsService.postDotmaticsSample(outputSamples[0].C_Material_key);
                task.dtxSynchronized = true;
            } else {
                // Update animal
                this.dotmaticsService.updateDotmaticsSample(outputSamples[0].C_Material_key);
            }
        }
    }

    fillDownAssignedTo(assignedToKey: number) {
        if (assignedToKey) {
            for (const task of this.tasks) {
                if (task.IsLocked !== true) {
                    task.C_AssignedTo_key = assignedToKey;
                }
            }
        }
    }

    fillDownDateDue(dateDue: Date) {
        if (dateDue) {
            for (const task of this.tasks) {
                if (task.IsLocked !== true) {
                    task.DateDue = dateDue;
                }
            }
        }
    }

    async saveEntities(): Promise<void> {
        return this.dataManager.saveEntity('TaskOutputSet').then(() => {
            return Promise.all([
                this.dataManager.saveEntity('TaskOutput'),
                this.dataManager.saveEntity('TaskOutputSetMaterial'),
            ]);
        }).then(() => {
            return this.dataManager.saveEntity('TaskInstance');
        }).catch((err) => {
            throw err;
        });
    }

    private handleDueDateChangeError(): void {
        this.toastrService.showToast('Could not complete the tasks update.', LogLevel.Error);
    }

    async onDueDateChange(task: Entity<TaskInstance>) {
        try {
            await this.saveEntities();
        } catch {
            this.handleDueDateChangeError();
            return;
        }

        try {
            let completedByKey = task.C_CompletedBy_key;
            if (!completedByKey) {
                // Use the current resource as the default
                completedByKey = this.currentResourceKey;
            }
            const response = await this.workflowService.taskStatusChanged(
                [task.C_TaskInstance_key],
                this.authService.getCurrentUserName(),
                task.C_TaskStatus_key,
                completedByKey,
                task.DateComplete,
                true,
                false,
                false
            );
  
            if (response.Success) {
                task.entityAspect.setUnchanged();
                if (response.DependentTasksUpdated.length) {
                    const message = 'Dependent due dates/times have been updated.';
                    this.toastrService.showToast(message, LogLevel.Warning);
                }
                response.DependentTasksUpdated.forEach((updatedTask: { C_TaskInstance_key: number, DateDue: Date }) => {
                    this.tasks.forEach((currentRow) => {
                        if (currentRow.C_TaskInstance_key === updatedTask.C_TaskInstance_key) {
                            currentRow.DateDue = updatedTask.DateDue;
                            currentRow.entityAspect.setUnchanged();
                        }
                    });
                });
            } else {
                this.handleDueDateChangeError();
            }
        } catch {
            return;
        }
    }

    // <select> formatters
    taskStatusKeyFormatter = (value: any) => {
        return value.C_TaskStatus_key;
    }
    taskStatusFormatter = (value: any) => {
        return value.TaskStatus;
    }

    validate() {
        return dateControlValidator(this.dateControls);
    }

    /**
     * Sets isDotmatics flag
     */
    private setIsDotmatics() {
        this.isDotmatics = this.dotmaticsService.setIsDotmatics();
    }
}

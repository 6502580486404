import {
    ChangeDetectionStrategy,
    Component,
} from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';
import { buildId } from '@config';
import { VersionsService } from '../services/versions.service';
import { RouteEnum } from '../../routing/route.enum';

@Component({
    selector: 'versions-view',
    templateUrl: './versions-view.component.html',
    styleUrls: ['./versions-view.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VersionsViewComponent {
    adminBuildNumber$: Observable<string> = this.versionsService.getAdminBuildNumber();
    dataBuildNumber$: Observable<string> = this.versionsService.getDataBuildNumber();
    webBuildNumber$: Observable<string> = this.versionsService.getWebBuildNumber().pipe(share());
    cachedWebBuildNumber: string = buildId;

    readonly COMPONENT_LOG_TAG = 'versions-view-component';

    constructor(
        private location: Location,
        private router: Router,
        private versionsService: VersionsService,
        private window: Window,
    ) { }

    goBack(): void {
        const { navigationId } = this.window.history.state;
        if (navigationId === 1 || this.window.history.length === 1) {
            this.router.navigate([RouteEnum.Dashboard]);
            return;
        }
        this.location.back();
    }
}

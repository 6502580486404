import { Component, ChangeDetectionStrategy, Input, HostBinding } from '@angular/core';

@Component({
    selector: 'climb-loading-bar',
    styleUrls: ['./loading-bar.component.scss'],
    template: `
        <div class="loading-bar-container">
            <div class="loading-bar"></div>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingBarComponent {
    @HostBinding('attr.title')
    @Input()
    title = 'Loading';
}

import {
    Component,
    OnInit,
    ViewChild
} from '@angular/core';

import { TaskOutputChartRendererComponent } from './task-output-chart-renderer.component';

import {
    ChartFilter,
    AnimalChartFilter,
    CohortChartFilter,
    JobChartFilter,
    LineChartFilter,
    SexChartFilter,
    ChartFilterGroup,
    StudyChartFilter,
    CohortIndividualAnimals,
    ChartOptionLabelEnum
} from '../filters';

import {
    notEmpty,
    randomId
} from '../../common/util';
import { ChartService } from '../chart.service';

@Component({
    selector: 'task-output-chart',
    templateUrl: './task-output-chart.component.html',
    styleUrls: ['../charts-facet.component.scss'],
    styles: [`
        .task-output-controls {
            margin-bottom: 1em;
        }
    `],
})
export class TaskOutputChartComponent implements OnInit {
    @ViewChild(TaskOutputChartRendererComponent)
    private chartRenderer: TaskOutputChartRendererComponent;

    // Lists of values
    filterTypes: typeof ChartFilter[] = [
        AnimalChartFilter,
        CohortChartFilter,
        CohortIndividualAnimals,
        JobChartFilter,
        LineChartFilter,
        SexChartFilter,
        StudyChartFilter
    ];
    intervals: string[] = [
        'Weeks',
        'Days'
    ];
    labels = this.chartService.TASK_OUTPUT_LABELS;

    domIdAddition: string = randomId();

    // The selected chart interval
    interval: string = this.intervals[0];
    label$ = this.chartService.taskOutputLabel$;
    // The selected ChartFilterGroups
    filterGroups: ChartFilterGroup[] = [];
    // The selected Output
    taskOutput: any;

    constructor(private chartService: ChartService) {}

    ngOnInit(): void {
      this.chartService.selectTaskOutputLabel(ChartOptionLabelEnum.NAME);
    }

    isInputValid(): boolean {
        if (this.taskOutput && this.interval && notEmpty(this.filterGroups)) {
            return true;
        } else {
            return false;
        }
    }

    createChart(): void {
        this.chartRenderer.createChart();
    }

    /**
     * Sets the selectedOutput model from the nested component's output event.
     *
     * @param taskOutput
     */
    selectOutput(output: any) {
        this.taskOutput = output;
    }

    selectLabel(label: string) {
        this.chartService.selectTaskOutputLabel(label as ChartOptionLabelEnum);
    }
}

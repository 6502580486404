import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';
import { WizardService } from '../wizard.service';

import {
    CvValue,
    SDCharacteristic
} from '../models';

import {
    notEmpty
} from '../../common/util';

@Component({
    selector: 'choose-taxon-characteristics',
    templateUrl: './choose-taxon-characteristics.component.html',
    styles: [`
        .taxon-characteristics {
            margin: 1em 0;
        }
    `]
})
export class ChooseTaxonCharacteristicsComponent implements OnInit {
    @Input() selectedTaxa: CvValue[];
    @Input() selectedTaxonCharacteristics: SDCharacteristic[];
    @Output() selectedTaxonCharacteristicsChange: EventEmitter<SDCharacteristic[]>
    = new EventEmitter<SDCharacteristic[]>();

    // All taxon characteristics
    taxonCharacteristics: SDCharacteristic[];


    constructor(
        private wizardService: WizardService
    ) {
        // Nothing to do
    }

    ngOnInit() {
        this.initialize();
    }

    initialize() {
        if (!this.selectedTaxonCharacteristics) {
            this.selectedTaxonCharacteristics = [];
        }

        this.getTaxonCharacteristics().then((values) => {
            this.taxonCharacteristics = values;
            this.setTaxonValuesIsSelectedFromInput();
        });
    }

    private getTaxonCharacteristics(): Promise<SDCharacteristic[]> {
        return this.wizardService.getTaxonCharacteristicSeedValues();
    }

    private setTaxonValuesIsSelectedFromInput() {
        if (notEmpty(this.taxonCharacteristics) && notEmpty(this.selectedTaxonCharacteristics)) {

            for (const taxonCharacteristic of this.taxonCharacteristics) {
                for (const selectedTaxonCharacteristic of this.selectedTaxonCharacteristics) {
                    if (taxonCharacteristic.name === selectedTaxonCharacteristic.name &&
                        taxonCharacteristic.taxon === selectedTaxonCharacteristic.taxon
                    ) {
                        taxonCharacteristic.isSelected = true;
                        break;
                    }
                }

            }
        }
    }

    onModelChange(event: Event) {
        this.setSelectedValuesFromForm();
        this.selectedTaxonCharacteristicsChange.emit(this.selectedTaxonCharacteristics);
    }

    private setSelectedValuesFromForm() {
        this.selectedTaxonCharacteristics = this.taxonCharacteristics
            .filter((item: SDCharacteristic) => {
                return item.isSelected === true;
            });
    }
}

import {
    CellFormatterService,
    TableOptions,
    FormatterOptions
} from '../common/datatable';
import { AuditModifiedFieldComponent } from './audit-modified-fields/audit-modified-field.component';
import { AuditClassFactory } from './audit-class-maker';

export class AuditAnimalsTasksTableOptions {
    options: TableOptions;

    constructor(
        private cellFormatterService: CellFormatterService
    ) {
        this.options = this.getDefaultOptions();
    }

    getDefaultOptions(): TableOptions {

        return {
            csvFileName: 'animals-tasks-audit.csv',
            enableDetailColumn: false,
            enableDraggable: false,
            enableSelectable: false,
            enableAuditColumns: false,
            columns: [
                {
                    displayName: 'Type',
                    field: 'UpdateType',
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Modified Fields',
                    field: 'ModifiedFields',
                    sortField: 'ModifiedFields',
                    rendererComponent: AuditModifiedFieldComponent,
                    exportFormatter: this.cellFormatterService.auditModifiedFieldsExportFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Date',
                    field: 'ModifiedDate',
                    formatter: this.cellFormatterService.dateTimeUTCFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Modified By',
                    field: 'ModifiedBy',
                    formatter: this.cellFormatterService.userNameFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Animal ID',
                    field: 'AnimalID',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('AnimalID', 'AnimalID'),
                    cellClass: AuditClassFactory.createCellClass('animalid'),
                },
                {
                    displayName: 'Protocol',
                    field: 'Protocol',
                    visible: false,
                    formatter: this.cellFormatterService.createAuditColumnFormatter('Protocol', 'Protocol'),
                    cellClass: AuditClassFactory.createCellClass('protocol'),
                },
                {
                    displayName: 'Task Name',
                    field: 'TaskName',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('TaskName', 'TaskName'),
                    cellClass: AuditClassFactory.createCellClass('taskname'),
                },
                {
                    displayName: 'Task',
                    field: 'Task',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('Task', 'Task'),
                    cellClass: AuditClassFactory.createCellClass('task'),
                },
                {
                    displayName: 'Sequence',
                    field: 'Sequence',
                    visible: false,
                    formatter: this.cellFormatterService.createAuditColumnFormatter('Sequence', 'Sequence'),
                    cellClass: AuditClassFactory.createCellClass('sequence'),
                },
                {
                    displayName: 'Due Date',
                    field: 'DueDate',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('DueDate', 'DueDate', this.cellFormatterService.dateFormatter),
                    cellClass: AuditClassFactory.createCellClass('duedate'),
                },
                {
                    displayName: 'Allowance',
                    field: 'Allowance',
                    visible: false,
                    formatter: this.cellFormatterService.createAuditColumnFormatter('Allowance', 'Allowance'),
                    cellClass: AuditClassFactory.createCellClass('allowance'),
                },
                {
                    displayName: 'Location',
                    field: 'Location',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('Location', 'Location'),
                    cellClass: AuditClassFactory.createCellClass('location'),
                },
                {
                    displayName: 'Status',
                    field: 'Status',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('Status', 'Status'),
                    cellClass: AuditClassFactory.createCellClass('status'),
                },
                {
                    displayName: 'Cost',
                    field: 'Cost',
                    visible: false,
                    formatter: this.cellFormatterService.createAuditColumnFormatter('Cost', 'Cost'),
                    cellClass: AuditClassFactory.createCellClass('cost'),
                },
                {
                    displayName: 'Duration',
                    field: 'Duration',
                    visible: false,
                    formatter: this.cellFormatterService.createAuditColumnFormatter('Duration', 'Duration'),
                    cellClass: AuditClassFactory.createCellClass('duration'),
                },
                {
                    displayName: 'Created By',
                    field: 'CreatedBy',
                    formatter: this.cellFormatterService.userNameFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Created Date',
                    field: 'DateCreated',
                    formatter: this.cellFormatterService.dateTimeUTCFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                }
            ]
        };
    }
}

import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { AuthService } from '@services/auth.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor(
        private readonly authService: AuthService,
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(this.addAuthenticationToken(request));
    }

    private addAuthenticationToken(request: HttpRequest<any>) {
        const accessToken = this.authService.getCurrentToken();

        if (!accessToken || request.url.includes("slack")) {
            return request;
        }

        return request.clone({
            setHeaders: {
                Authorization: `Bearer ${accessToken}`,
            },
        });
    }
}

import {
    Component,
    Input,
} from '@angular/core';

import { BarcodeOptions } from './models';

/**
 * Climb barcode generator component.
 *
 * Uses ngx-barcode:
 * https://www.npmjs.com/package/ngx-barcode6
 */
@Component({
    selector: 'climb-barcode',
    templateUrl: './climb-barcode.component.html'
})
export class ClimbBarcodeComponent {
    @Input() value: any;
    @Input() options: BarcodeOptions;
}

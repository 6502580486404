import { Predicate } from 'breeze-client';
import { getBooleanPredicate } from './boolean-predicate';

/**
 * Creates Breeze Predicates for IsActive fields.
 * @param value the IsActive value
 */
export function getIsActivePredicate(value: boolean | string): Predicate {
    return getBooleanPredicate(value, 'IsActive');
}
/**
 * Creates Breeze Predicates for IsFoster fields.
 * @param value the IsFoster value
 */
export function getIsFosterPredicate(value: boolean | string): Predicate {
    return getBooleanPredicate(value, 'IsFoster');
}

<form data-automation-id="workflow-filter-window"
      role="form" 
      class="form-label-right">

    <div class="modal-header justify-content-center">
        <h4 class="modal-title">Workflow Filter</h4>
    </div>

    <div class="modal-body">
        <div class="form-group row">
            <label class="col-md-3 col-form-label">{{'Study' | translate}}</label>
            <div class="col-md-9">
                <study-multiselect [(model)]="filter.studies"
                                   [excludeLocked]="false"></study-multiselect>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-3 col-form-label">{{'Job' | translate}} Name</label>
            <div class="col-md-9">
                <job-multiselect [(model)]="filter.jobs"></job-multiselect>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-3 col-form-label">{{'Job' | translate}} Name Contains</label>
            <div class="col-md-9">
                <input type="text"
                       name="jobID"
                       [(ngModel)]="filter.JobID"
                       class="form-control input-medium" />
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-3 col-form-label">{{'Job' | translate}} Status</label>
            <div class="col-md-9">
                <climb-multi-select [(model)]="filter.JobStatusKeys"
                                    [values]="jobStatuses"
                                    [keyProperty]="'C_JobStatus_key'"
                                    [valueProperty]="'JobStatus'"
                                    [placeholder]="'Select ' + ('Job' | translate) + ' Statuses&hellip;'"></climb-multi-select>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-3 col-form-label">Cohorts</label>
            <div class="col-md-9">
                <cohort-multiselect [(model)]="filter.cohortsNames"></cohort-multiselect>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-3 col-form-label">Task</label>
            <div class="col-md-9">
                <climb-multi-select [(model)]="filter.WorkflowTaskKeys"
                                    [values]="workflowTasks"
                                    [keyProperty]="'C_WorkflowTask_key'"
                                    [valueProperty]="'TaskName'"
                                    [placeholder]="'Select Tasks...'"></climb-multi-select>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-3 col-form-label">Type</label>
            <div class="col-md-9">
                <climb-multi-select [(model)]="filter.TaskTypeKeys"
                                    [values]="taskTypes"
                                    [keyProperty]="'C_TaskType_key'"
                                    [valueProperty]="'TaskTypeTranslated'"
                                    [placeholder]="'Select Task Types...'"></climb-multi-select>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-3 col-form-label">{{'Job' | translate}} Created By</label>
            <div class="col-md-9">
                <user-select [(model)]="filter.JobCreatedBy"
                             [forceValidSelection]="false"></user-select>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-3 col-form-label">Assigned To</label>
            <div class="col-md-9">
                <climb-multi-select [(model)]="filter.ResourceKeys"
                                    [values]="resources"
                                    [keyProperty]="'C_Resource_key'"
                                    [valueProperty]="'ResourceName'"
                                    [placeholder]="'Assigned to...'"></climb-multi-select>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-3 col-form-label">Due Date Range</label>
            <div class="col-md-3">
                <climb-ngb-date #dateControl="ngModel"
                                [(ngModel)]="filter.DateDueStart"
                                name="Due Date Start"
                                placeholder="From&hellip;"
                                [disabled]="filter.DateDueToday"></climb-ngb-date>
            </div>
            <div class="col-md-3">
                <climb-ngb-date #dateControl="ngModel"
                                name="Due Date End"
                                [(ngModel)]="filter.DateDueEnd"
                                placeholder="To&hellip;"
                                [disabled]="filter.DateDueToday"></climb-ngb-date>
            </div>
            <div class="col-md-3 align-checkbox-center">
                <input type="checkbox"
                       name="dateDueToday"
                       id="dateDueToday"
                       [(ngModel)]="filter.DateDueToday" />
                <label for="dateDueToday">
                    Today
                </label>
            </div>
        </div>


        <div class="form-group row">
            <label class="col-md-3 col-form-label">Status</label>
            <div class="col-md-9">
                <climb-multi-select [(model)]="filter.TaskStatusKeys"
                                    [values]="taskStatuses"
                                    [keyProperty]="'C_TaskStatus_key'"
                                    [valueProperty]="'TaskStatus'"
                                    [placeholder]="'Select Task Statuses...'"></climb-multi-select>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-3 col-form-label">Locked</label>
            <div class="col-md-4">
                <lock-status-select [(model)]="filter.IsLocked"></lock-status-select>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-3 col-form-label">Material Location</label>
            <div class="col-md-9">
                <input type="text"
                       name="materialLocation"
                       [(ngModel)]="filter.MaterialLocation"
                       class="form-control input-medium" />
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-3 col-form-label">Task Location</label>
            <div class="col-md-9">
                <input type="text"
                       name="taskLocation"
                       [(ngModel)]="filter.TaskLocation"
                       class="form-control input-medium" />
            </div>
        </div>


        <div class="form-group row">
            <label class="col-md-3 col-form-label">Animal ID</label>
            <div class="col-md-9">
                <multi-paste-input [(model)]="filter.Identifiers"
                                   [isNumeric]="true"
                                   (validInput)="setValidationItem('identifiers', $event)"
                                   [limit]="MULTI_PASTE_INPUT_LIMIT"
                                   [rows]="3"></multi-paste-input>
                <small class="form-text text-muted">
                    IDs separated by
                    <code>,</code> or <code>Enter</code>.
                    Limit: {{MULTI_PASTE_INPUT_LIMIT}}.
                </small>
            </div>
        </div>

        <div *ngIf="isGLP" class="form-group row">
            <label class="col-md-3 col-form-label">Alternate Physical ID</label>
            <div class="col-md-9">
                <multi-paste-input [(model)]="filter.AlternatePhysicalIDs"
                                   [isNumeric]="false"
                                   [limit]="MULTI_PASTE_INPUT_LIMIT"
                                   [rows]="3"></multi-paste-input>
                <small class="form-text text-muted">
                    IDs separated by
                    <code>,</code> or <code>Enter</code>.
                    Limit: {{MULTI_PASTE_INPUT_LIMIT}}.
                </small>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-3 col-form-label">Animal Name</label>
            <div class="col-md-9">
                <animal-multiselect [(model)]="filter.Animals"></animal-multiselect>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-3 col-form-label">Animal Status</label>
            <div class="col-md-9">
                <climb-multi-select
                    [(model)]="filter.AnimalStatusKeys"
                    [values]="animalStatuses"
                    [keyProperty]="'C_AnimalStatus_key'"
                    [valueProperty]="'AnimalStatus'"
                    [placeholder]="'Select Animal Statuses...'">
                </climb-multi-select>
            </div>
        </div>

        <div *ngIf="isGLP" class="form-group row">
            <label class="col-md-3 col-form-label">Animal Classification</label>
            <div class="col-md-9">
                <climb-multi-select
                    [(model)]="filter.AnimalClassificationKeys"
                    [values]="animalClassifications"
                    [keyProperty]="'C_AnimalClassification_key'"
                    [valueProperty]="'AnimalClassification'"
                    [placeholder]="'Select Classifications...'">
                </climb-multi-select>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-3 col-form-label">{{'Line' | translate}}</label>
            <div class="col-md-9">
                <line-multiselect [(model)]="filter.Lines"></line-multiselect>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-3 col-form-label">Housing ID</label>
            <div class="col-md-9">
                <housing-multiselect [(model)]="filter.MaterialPools"></housing-multiselect>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-3 col-form-label">Birth ID</label>
            <div class="col-md-9">
                <input type="text"
                       name="birthId"
                       [(ngModel)]="filter.BirthID"
                       class="form-control input-medium" />
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-3 col-form-label">Mating ID</label>
            <div class="col-md-9">
                <input type="text"
                       name="matingId"
                       [(ngModel)]="filter.MatingID"
                       class="form-control input-medium" />
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-3 col-form-label">Overdue Tasks</label>
            <div class="col-md-9">
                <input type="checkbox"
                       name="overdueTasks"
                       class="mt-1"
                       [(ngModel)]="filter.IsOverdue" />
            </div>
        </div>

    </div>

    <div class="modal-footer">
        <span class="btn-set">
            <button data-automation-id="filter-button"
                    type="submit"
                    class="btn btn-fw-wide btn-lg btn-primary"
                    [disabled]="!isValid"
                    (click)="filterClicked($event)">
                Filter
            </button>
            <button data-automation-id="clear-filter-button"
                    type="button"
                    class="btn btn-fw-wide btn-lg btn-secondary"
                    (click)="clearClicked()">
                Clear Filter
            </button>
        </span>
        <span class="btn-set">
            <button type="button"
                    class="btn btn-lg btn-secondary"
                    (click)="closeClicked()">
                Close
            </button>
        </span>
    </div>
</form>

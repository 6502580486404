import { mean } from "@lodash";

/**
 * 
 * @param values 
 * @returns 
 */
export function populationStandardDeviation(values: number[]): number {
    if (values && values.length > 0) {
        const n = values.length;
        return Math.sqrt(values.map((x) => Math.pow(x - mean(values), 2)).reduce((a, b) => a + b) / n);
    } else {
        return 0;
    }
}

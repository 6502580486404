import type { Construct } from './construct.interface';
import type { Sample } from './sample.interface';

export interface SampleConstruct {
    C_Construct_key: number;
    C_Material_key: number | null;
    C_SampleConstruct_key: number;
    C_Workgroup_key: number;
    Construct: Construct;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    ModifiedBy: string;
    Sample: Sample;
    Version: number;
}

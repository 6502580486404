import { Pipe, PipeTransform } from '@angular/core';

/*
* Return even items in a list
*/
@Pipe({ name: 'even' })
export class EvenPipe implements PipeTransform {

    transform<T>(value: T[]): T[] {
        if (value) {
            return value.filter( (item: T, idx: number) => {
                return idx % 2 === 0;
            });
        }
        return [];
    }
}

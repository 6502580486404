<ng-template #nestedTable let-rows="rows" 
                        let-columns="columns" 
                        let-depth="depth" 
                        let-rowindex="rowindex">
    <table class="table table-bordered" 
        *ngIf="columns && columns.length > 0 && rows && rows.length > 0">
            <thead>
                <tr>
                    <th *ngFor="let column of columns">
                        {{column.name}}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let row of rows; let rowindex = index;">
                    <td *ngFor="let column of columns">
                        <ng-container *ngIf="!(column.columns?.length > 0)">
                            {{row[column.name]}}
                        </ng-container>
                        <ng-container *ngIf="column.columns?.length > 0">
                            <ng-template [ngTemplateOutlet]="nestedTable"
                                [ngTemplateOutletContext]="{
                                    rows : row[column.name],
                                    columns: column.columns,
                                    depth: (depth + 1),
                                    rowindex: rowindex
                                }"
                            ></ng-template>  
                        </ng-container>
                    </td>
                </tr>
            </tbody>
        </table>
</ng-template>


<div *ngIf="results && results.length > 0">
    <table class="table table-bordered">
        <thead>
            <tr>
                <th *ngFor="let column of nestedColumns">
                    {{column.name}}
                </th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let row of results; let rowindex = index;">
                <td *ngFor="let column of nestedColumns">
                    <ng-container *ngIf="!(column.columns?.length > 0)">
                        {{row[column.name]}}
                    </ng-container>
                    <ng-container *ngIf="column.columns?.length > 0">
                        <ng-template [ngTemplateOutlet]="nestedTable"
                            [ngTemplateOutletContext]="{
                                rows : row[column.name],
                                columns: column.columns,
                                depth: 0,
                                rowindex: rowindex
                            }"
                        ></ng-template>  
                    </ng-container>
                </td>
            </tr>
        </tbody>
    </table>

    <div class="paginator climb-grid ag-theme-alpine">
        <div class="ag-paging-panel ag-font-style">
            <span ref="eSummaryPanel" class="ag-paging-row-summary-panel" role="status">
                <span ref="lbFirstRowOnPage" class="ag-paging-row-summary-panel-number">
                    {{((pageNumber - 1) * pageSize) + 1}}
                </span>
                <span>–</span>
                <span ref="lbLastRowOnPage" class="ag-paging-row-summary-panel-number">
                    {{rowsOnPage}}
                </span>
                <span>of</span>
                <span ref="lbRecordCount" class="ag-paging-row-summary-panel-number">
                    {{totalCount}}
                </span>
            </span>
            <span class="ag-paging-page-summary-panel" role="presentation">
                <button type="button"
                        class="ag-paging-button"
                        [ngClass]="{'ag-disabled': pageNumber < 2}"
                        ref="btFirst"
                        aria-label="First Page"
                        (click)="goToPage(1)"
                        [disabled]="pageNumber < 2">
                    <span class="ag-icon ag-icon-first" unselectable="on" role="presentation"></span>
                </button>
                <button type="button"
                        class="ag-paging-button"
                        [ngClass]="{'ag-disabled': pageNumber < 2}"
                        ref="btPrevious"
                        aria-label="Previous Page"
                        (click)="goToPage(pageNumber - 1)"
                        [disabled]="pageNumber < 2">
                    <span class="ag-icon ag-icon-previous" unselectable="on" role="presentation"></span>
                </button>
                <span class="ag-paging-description" role="status">
                    <span>Page</span>
                    <span ref="lbCurrent" class="ag-paging-number">{{pageNumber}}</span>
                    <span>of</span>
                    <span ref="lbTotal" class="ag-paging-number">{{totalPages}}</span>
                </span>
                <button type="button"
                        class="ag-paging-button"
                        [ngClass]="{'ag-disabled': pageNumber >= totalPages}"
                        ref="btNext"
                        aria-label="Next Page"
                        tabindex="0"
                        (click)="goToPage(pageNumber + 1)"
                        [disabled]="pageNumber >= totalPages">
                    <span class="ag-icon ag-icon-next" unselectable="on" role="presentation"></span>
                </button>
                <button type="button"
                        class="ag-paging-button"
                        [ngClass]="{'ag-disabled': pageNumber >= totalPages}"
                        ref="btLast"
                        aria-label="Last Page"
                        tabindex="0"
                        (click)="goToPage(totalPages)"
                        [disabled]="pageNumber >= totalPages">
                    <span class="ag-icon ag-icon-last" unselectable="on" role="presentation"></span>
                </button>
            </span>
        </div>
    </div>
</div>

<div *ngIf="!results || results.length === 0">
    No results found
</div>

import {
    Component,
    EventEmitter,
    Input,
    Output
} from '@angular/core';

@Component({
    selector: 'wizard-footer',
    templateUrl: './wizard-footer.component.html',
    styleUrls: ['./wizard-footer.component.scss'],
})
export class WizardFooterComponent {
    /**
     * Is this the first step?
     */
    @Input() first = false;
    /**
     *  Is this the last step?
     */
    @Input() last = false;
    /**
     * Disable backward navigation?
     */
    @Input() backDisabled = false;
    /**
     * Hide backward navigation?
     */
    @Input() backHidden = false;
    /**
     * Disable forward navigation (i.e., next or finish)?
     */
    @Input() nextDisabled = false;


    @Output() backPreFinalize: EventEmitter<void> = new EventEmitter<void>();
    @Output() backFinalize: EventEmitter<void> = new EventEmitter<void>();
    @Output() backPostFinalize: EventEmitter<void> = new EventEmitter<void>();

    @Output() nextPreFinalize: EventEmitter<void> = new EventEmitter<void>();
    @Output() nextFinalize: EventEmitter<void> = new EventEmitter<void>();
    @Output() nextPostFinalize: EventEmitter<void> = new EventEmitter<void>();

    @Output() finish: EventEmitter<void> = new EventEmitter<void>();


    constructor() {
        // Nothing to do
    }


    // Back Outputs
    backPreFinalized() {
        this.backPreFinalize.emit();
    }

    backFinalized() {
        this.backFinalize.emit();
    }

    backPostFinalized() {
        this.backPostFinalize.emit();
    }


    // Next Outputs
    nextPreFinalized() {
        this.nextPreFinalize.emit();
    }

    nextFinalized() {
        this.nextFinalize.emit();
    }

    nextPostFinalized() {
        this.nextPostFinalize.emit();
    }


    // Finish Output
    finishClicked() {
        this.finish.emit();
    }
}

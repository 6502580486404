import { DataType } from '../../data-type/data-type.enum';
import { TaskTableCommService } from './task-table-comm.service';
import { TaskType } from '../../tasks/models/task-type.enum';
import { Injectable, OnDestroy } from '@angular/core';

import { EnumerationService } from '../../enumerations/enumeration.service';
import { LoggingService } from '@services/logging.service';
import { WorkflowService } from './workflow.service';
import { ResourceService } from '../../resources';
import { ProtocolDateCalculator } from '../../tasks/tables/protocol-date-calculator';
import { DataManagerService } from '@services/data-manager.service';

import {
    getSafeProp,
    uniqueArrayFromPropertyPath,
} from '@common/util';
import { EntityQuery, Predicate } from 'breeze-client';
import { cv_TaskStatus, TaskInstance } from '@common/types';
import { WorkflowVocabService } from './workflow-vocab.service';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

/**
 * Shared functions for workflow components
 */
@Injectable()
export class WorkflowLogicService implements OnDestroy {
    private taskDefaultStatus: cv_TaskStatus;
    private taskEndStatus: cv_TaskStatus;

    private subscription = new Subscription();

    constructor(
        private enumerationService: EnumerationService,
        private loggingService: LoggingService,
        private resourceService: ResourceService,
        private taskTableCommService: TaskTableCommService,
        private workflowService: WorkflowService,
        private workflowVocabService: WorkflowVocabService,
        private dataManager: DataManagerService,
    ) {
        this.initTaskStatus();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    addOutputSet(task: any): Promise<any> {
        return this.createOutputSet(task).then((newOutputSet: any) => {

            if (this.getTaskType(task) === TaskType.Animal) {
                this.setSingleAnimalOutput(task, newOutputSet);
            }

            this.attachEnumerations(newOutputSet);
            this.workflowService.recalculateValues(task, newOutputSet);

            return newOutputSet;
        });
    }

    private createOutputSet(task: any): Promise<any> {
        const initialValues = {
            C_TaskInstance_key: task.C_TaskInstance_key,
            CollectionDateTime: new Date(),
            DateCreated: new Date()
        };
        return this.workflowService.createOutputSet(
            initialValues,
            task.C_WorkflowTask_key,
            task.C_TaskInstance_key
        ).then((newOutputSet: any) => {
            return this.setOutputSetDefaultCollectedBy(newOutputSet);
        });
    }

    private setSingleAnimalOutput(task: any, outputSet: any) {
        const taskMaterial = getSafeProp(task, 'TaskMaterial[0]');
        if (!taskMaterial) {
            return;
        }

        this.workflowService.createTaskOutputSetMaterial({
            C_TaskOutputSet_key: outputSet.C_TaskOutputSet_key,
            C_Material_key: taskMaterial.C_Material_key
        });

        this.taskTableCommService.refreshMaterialSelects();
    }

    /**
     * Sets the default collectedBy for an OutputSet to the current user's Resource
     *
     * @param outputSet
     */
    private setOutputSetDefaultCollectedBy(outputSet: any): Promise<any> {
        return this.resourceService.getCurrentUserResource().then((resource: any) => {
            if (resource) {
                outputSet.C_Resource_key = resource.C_Resource_key;
            }

            return outputSet;
        });
    }

    attachEnumerations(outputSet: any): Promise<any> {
        const promises: Promise<any>[] = [];
        for (const output of outputSet.TaskOutput) {
            const classKey = output.Output.C_EnumerationClass_key;
            if (classKey) {
                const promise = this.enumerationService.getEnumerationItems(
                    classKey
                ).then((items) => {
                    output.EnumerationItems = items;
                });
                promises.push(promise);
            }
        }
        return Promise.all(promises);
    }

    attachVocabularies(outputSet: any) {
        for (const output of outputSet.TaskOutput) {
            const classKey = output.Output.C_VocabularyClass_key;
            if (classKey) {
                output.VocabularyItems = [{
                    C_VocabularyClass_key: 1,
                    ClassName: "Test Article"
                },
                {
                    C_VocabularyClass_key: 2,
                    ClassName: "IACUC Protocol"
                }];
            }
        }
    }

    taskStatusChanged(task: TaskInstance, date?: Date) {
        const isEndState: boolean = getSafeProp(task, 'cv_TaskStatus.IsEndState');

        // Set DateComplete
        if (isEndState === true) {
            
            return this.dataManager.ensureRelationships([task], ['TaskOutputSet']).then(() => {
                return this.resourceService.getCurrentUserResource();
            }).then((resource: any) => {
                const currentResourceKey = resource ? resource.C_Resource_key : null;

                task.DateComplete = date ?? new Date();
                task.C_CompletedBy_key = currentResourceKey;

                const taskOutputSets = uniqueArrayFromPropertyPath(task, 'TaskOutputSet');

                for (const tos of taskOutputSets) {
                    tos.CollectionDateTime = tos.CollectionDateTime ? tos.CollectionDateTime : task.DateComplete;
                    tos.C_Resource_key = tos.C_Resource_key ? tos.C_Resource_key : task.C_CompletedBy_key;
                }
            });
        } else {
            task.DateComplete = null;
        }
    }

    taskCompletedDataChanged(task: TaskInstance, date?: Date) {
        if (date) {
            task.cv_TaskStatus = this.taskEndStatus;
            this.taskStatusChanged(task, date);
        } else {
            task.C_CompletedBy_key = null;
            task.DateComplete = null;
            task.cv_TaskStatus = this.taskDefaultStatus;
        }
    }

    getTaskType(taskInstance: any): string {
        return getSafeProp(taskInstance, 'WorkflowTask.cv_TaskType.TaskType');
    }

    /**
     * Update the due dates for protocol tasks that are relative to tasks that
     * have changed.
     * 
     * @param changedTasks The tasks that have changed
     * @param logTag If provided, a toaster message will be displayed when
     *        relative tasks are updated
     */
    updateRelativeTasks(changedTasks: any[], logTag: string = null): Promise<number> {
        let promise = Promise.resolve(0);

        if (!changedTasks) {
            // Nothing to do
            return promise;
        }

        const protocolDateCalculator = new ProtocolDateCalculator();

        changedTasks.forEach((changedTask: any) => {
            if (!changedTask.C_ProtocolInstance_key) {
                // Not a protocol task
                return;
            }

            promise = promise.then((count: number) => {
                // Get the other tasks in this task's protocol
                return this.workflowService.getTasksInProtocolInstance(
                    changedTask.C_ProtocolInstance_key
                ).then((tasks) => {
                    // Update the dependent due dates and note how many changed
                    count += protocolDateCalculator.scheduleDependentDueDates(tasks, changedTask);

                    return count;
                });
            });
        });

        if (logTag) {
            // Notify the user about the change.
            promise = promise.then((count: number) => {
                if (count > 0) {
                    const message = 'Dependent due dates/times have been updated.';
                    const showToast = true;
                    this.loggingService.logWarning(
                        message,
                        null,
                        logTag,
                        showToast
                    );
                }

                return count;
            });
        }

        return promise;
    }

    setInheritedOutputValues(
        task: any, taskOutputSet: any, material: any
    ): Promise<any> {
        const promises: Promise<any>[] = [];
        // Check for inherited outputs
        for (const output of taskOutputSet.TaskOutput) {
            const dataType = getSafeProp(output, 'Output.cv_DataType.DataType');

            let promise: Promise<any> = null;
            if (dataType === DataType.INHERITED_FIRST_OCCURRENCE) {
                promise = this.workflowService.getFirstOccurrenceInheritedOutputValue(
                    output, material, taskOutputSet
                );
            } else if (dataType === DataType.INHERITED_MOST_RECENT
                || dataType === DataType.INHERITED_SECOND_MOST_RECENT
                || dataType === DataType.INHERITED_THIRD_MOST_RECENT) {
                promise = this.workflowService.getNthMostRecentInheritedOutputValue(
                    output, material, taskOutputSet, 3
                );
            }

            if (!promise) {
                continue;
            }

            // Update inherited output values according to selected material value
            if (dataType === DataType.INHERITED_FIRST_OCCURRENCE) {
                promise = promise.then((materialTaskOutput: any) => {
                    this.updateInheritedOutputValues([materialTaskOutput], output, task, taskOutputSet, 0);
                });
            } else if (dataType === DataType.INHERITED_MOST_RECENT) {
                promise = promise.then((materialTaskOutputs: any[]) => {
                    this.updateInheritedOutputValues(materialTaskOutputs, output, task, taskOutputSet, 0);
                });
            } else if (dataType === DataType.INHERITED_SECOND_MOST_RECENT) {
                promise = promise.then((materialTaskOutputs: any[]) => {
                    this.updateInheritedOutputValues(materialTaskOutputs, output, task, taskOutputSet, 1);
                });
            } else if (dataType === DataType.INHERITED_THIRD_MOST_RECENT) {
                promise = promise.then((materialTaskOutputs: any[]) => {
                    this.updateInheritedOutputValues(materialTaskOutputs, output, task, taskOutputSet, 2);
                });
            }


            promises.push(promise);
        }

        return Promise.all(promises);
        
    }

    updateInheritedOutputValues(materialTaskOutputs: any[], output: any, task: any, taskOutputSet: any, selectedMaterialTaskOutput: number) {
        const materialTaskOutput = materialTaskOutputs[selectedMaterialTaskOutput];
        output.OutputValue = getSafeProp(materialTaskOutput, 'OutputValue');
        const inheritedDataType = getSafeProp(
            materialTaskOutput, 'Output.cv_DataType.DataType'
        );
        if (inheritedDataType === DataType.NUMBER) {
            // calculate other downstream outputs
            this.workflowService.recalculateValues(task, taskOutputSet);
        }
    }

    /**
     * Deterimine if outputValue should be flagged
     */
    flagOutput(taskOutput: any): boolean {
        // return false if there are no output flags
        if (!taskOutput.Output.OutputFlag || taskOutput.Output.OutputFlag.length === 0) {
            return false;
        }

        // return false if there's no OutputValue
        if (taskOutput.OutputValue === null || taskOutput.OutputValue === '') {
            return false;
        }

        // check if outputValue is within flag range
        const outputVal = Number(taskOutput.OutputValue);
        // output is flagged if previous steps haven't returned false
        return taskOutput.Output.OutputFlag
            .some((outputFlag: any) => this.outputFlagMeetRule(outputVal, outputFlag));
    }

    /**
     * Update the TaskOutput flags when an Output Value is change.
     */
    updateTaskOutputSetFlags(taskOutputSet: any) {
        if (taskOutputSet && taskOutputSet.TaskOutput) {
            for (const taskOutput of taskOutputSet.TaskOutput) {
                taskOutput.HasFlag = this.flagOutput(taskOutput);
                taskOutput.FlagsMessages = taskOutput.HasFlag ? this.getOutputFlagMessages(taskOutput) : null;
            }
        }
    }

    setTasksToAutomaticEndState(tasks: TaskInstance[]) {
        let query = EntityQuery.from("cv_TaskStatuses");
        const predicate = Predicate.create("IsDefaultAutoEndState", "eq", "true");
        query = query.where(predicate);

        return this.dataManager.returnQueryResults(query).then((response: cv_TaskStatus[]) => {
            const status: cv_TaskStatus = response[0];
            if (status) {
                for (const task of tasks) {
                    if (!task.cv_TaskStatus.IsDefaultEndState) {
                        task.C_TaskStatus_key = status.C_TaskStatus_key;
                        task.cv_TaskStatus = status;
                    }
                }
            }
        });
    }

    private getOutputFlagMessages(taskOutput: any): string {
        const outputVal = Number(taskOutput.OutputValue);
        const messages = taskOutput.Output.OutputFlag
            .filter((outputFlag: any) => this.outputFlagMeetRule(outputVal, outputFlag) && outputFlag.TaskFlagMessage !== null)
            .map((outputFlag: any) => outputFlag.TaskFlagMessage);
        return messages.join(',');
    }

    private outputFlagMeetRule(value: number, outputFlag: any): boolean {
        if (outputFlag.Minimum !== null && outputFlag.Maximum !== null) {
            if (value === outputFlag.Minimum && value === outputFlag.Maximum) {
                return true;
            }
            if (value >= outputFlag.Minimum && value <= outputFlag.Maximum) {
                return true;
            }
            return false;
        } else if (outputFlag.Minimum !== null && value >= outputFlag.Minimum) {
            return true;
        } else if (outputFlag.Maximum !== null && value <= outputFlag.Maximum) {
            return true;
        } else {
            return false;
        }
    }

    private initTaskStatus() {
        this.subscription.add(this.workflowVocabService.taskStatuses$.subscribe(data => {
            for (const status of data) {
                if (status.IsDefault) {
                    this.taskDefaultStatus = status;
                }
                if (status.IsEndState) {
                    this.taskEndStatus = status;
                }
            }
        }));
    }
}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { arrowUpRight, infoCircleFilled } from '@icons';
import { buildId } from '@config';
import { SaveChangesService } from '@services/save-changes.service';
import { RoutingService } from '../../../routing/routing.service';

@Component({
    selector: 'climb-info',
    templateUrl: 'info.component.html',
    styleUrls: ['info.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoComponent {
    @Input() isGLP = false;

    icons = { infoCircleFilled, arrowUpRight };
    readonly buildId = buildId;
    readonly LOG_TAG = 'info';

    constructor(
        private routingService: RoutingService,
        private saveChangesService: SaveChangesService
    ) {}

    async goToVersionInfo() {
        await this.saveChangesService.promptForUnsavedChanges(this.LOG_TAG);
        await this.routingService.navigateToVersionInfo();
    }
}

import type { Line } from './line.interface';
import type { Marker } from './marker.interface';

export interface LineMarker {
    C_LineMarker_key: number;
    C_Line_key: number;
    C_Marker_key: number;
    C_Workgroup_key: number;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    Line: Line;
    Marker: Marker;
    ModifiedBy: string;
    Version: number;
}

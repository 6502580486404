import { FacetLoadingStateService } from './../common/facet/facet-loading-state.service';
import { map } from 'rxjs/operators';
import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';

import { InstitutionService } from './institution.service';
import { InstitutionVocabService } from './institution-vocab.service';

import { PrivilegeService } from '../services/privilege.service';

@Component({
    selector: 'institution-site',
    templateUrl: './institution-site.component.html',
    styles: [`
        .site-block {
            overflow: hidden;
        }
        .site-sub-heading {
            display: inline-block;
            cursor: pointer;
        }
    `]
})
export class InstitutionSiteComponent implements OnInit {
    @Input() site: any;
    @Output() removeSite: EventEmitter<void> = new EventEmitter<void>();

    // CVs
    states: any[] = [];
    countries: any[] = [];
    filteredStates: any[] = [];
    siteTypes: any[] = [];

    // State
    saving = false;
    siteShown: boolean;

    readonly COMPONENT_LOG_TAG = 'institution-site';

    readwrite: boolean;
    readonly: boolean;

    constructor(
        private privilegeService: PrivilegeService,
        private facetLoadingStateService: FacetLoadingStateService,
        private institutionService: InstitutionService,
        private institutionVocabService: InstitutionVocabService
    ) { }

    // lifecycle
    ngOnInit() {

        this.initialize();
    }

    initialize() {
        this.facetLoadingStateService.changeLoadingState(true);

        this.setPrivileges();

        // Show site details on new site creation
        if (this.site.C_Site_key < 0) {
            this.siteShown = true;
        }

        return this.getCVs().then(() => {
            this.countryChanged();
        }).then(() => {
            this.facetLoadingStateService.changeLoadingState(false);
        }).catch((error) => {
            this.facetLoadingStateService.changeLoadingState(false);
            throw error;
        });
    }

    private getCVs(): Promise<any> {

        const p1 = this.institutionVocabService.states$.pipe(map((data) => {
            this.states = data;
        })).toPromise();

        const p2 = this.institutionVocabService.countries$.pipe(map((data) => {
            this.countries = data;
        })).toPromise();

        const p3 = this.institutionVocabService.siteTypes$.pipe(map((data) => {
            this.siteTypes = data;
        })).toPromise();

        return Promise.all([p1, p2, p3]);
    }

    /**
     * Sets privilege variables.
     */
    private setPrivileges() {
        this.readonly = this.privilegeService.readonly;
        this.readwrite = this.privilegeService.readwrite;
    }

    /**
     * Filter states based on selected country
     */
    countryChanged() {
        let filteredStates: any[] = [];

        if (this.site.C_Country_key) {
            filteredStates = this.states.filter((state) => {
                return state.C_Country_key === this.site.C_Country_key;
            });
        }

        this.filteredStates = filteredStates;
    }

    // Formatters for <select> input
    siteTypeKeyFormatter = (value: any) => {
        return value.C_SiteType_key;
    }
    siteTypeFormatter = (value: any) => {
        return value.SiteType;
    }

    siteStateKeyFormatter = (value: any) => {
        return value.C_State_key;
    }
    siteStateFormatter = (value: any) => {
        return value.StateName;
    }

    siteCountryKeyFormatter = (value: any) => {
        return value.C_Country_key;
    }
    siteCountryFormatter = (value: any) => {
        return value.Country;
    }

    /**
     * Delete Site on "x" click
     */
    removeSiteClicked() {
        this.removeSite.emit();
    }

    /**
     * Hide/show site details
     */
    toggleSiteShown() {
        this.siteShown = !this.siteShown;
    }
}

import {
    Component,
    Input,
    OnInit,
    OnChanges,
    SimpleChanges
} from '@angular/core';

import { JobService } from '../jobs/job.service';
import { DotmaticsService } from './dotmatics.service';
import { DataManagerService } from '../services/data-manager.service';
import { VocabularyService } from '../vocabularies/vocabulary.service';
import { LoggingService } from '../services/logging.service';
import { JobPharmaDetailService } from '../jobs/pharma/services/job-pharma-detail.service';

/**
 * Table for adding test articles to jobs in Dotmatics workgroups
 */
@Component({
    selector: 'dotmatics-test-article-table',
    templateUrl: './dotmatics-test-article-table.component.html'
})
export class DotmaticsTestArticleTableComponent implements OnInit, OnChanges {
    @Input() job: any;
    @Input() dotmaticsTestArticles: any[];
    @Input() readonly: boolean;
    @Input() required: boolean;

    // IsCRO feature flag
    @Input() isCRO = false;
    
    climbTestArticles: any;
    systemGeneratedTestArticleKey: any;

    // from drop down
    currentTestArticle: any;
    dropdownList: any[];

    loading: boolean;

    constructor(
        private jobService: JobService,
        private dotmaticsService: DotmaticsService,
        private dataManager: DataManagerService,
        private vocabularyService: VocabularyService,
        private loggingService: LoggingService,
        private jobPharmaDetailService: JobPharmaDetailService
    ) {
        //
    }

    ngOnInit() {
        this.initialize();
        this.initSystemGeneratedTestArticleKey();              
    }

    ngOnChanges(changes: SimpleChanges) {
        if (!changes.dotmaticsTestArticles.firstChange) {
            this.createDropdownList();
        }
    }

    initialize() {
        this.loading = true;
        this.getClimbTestArticles().then(() => {
            if (this.job.JobTestArticle) {
                this.job.JobTestArticle.forEach((value: any) => {
                    if (value.cv_TestArticle) {
                        value.testArticleId = value.cv_TestArticle.TestArticle;
                    }
                });
            }
            this.createDropdownList();
            for (const jobTestArticle of this.job.JobTestArticle) {
                this.getTestArticleBatches(jobTestArticle);
            }
            this.loading = false;
        });
    }

    initSystemGeneratedTestArticleKey(): Promise<any>  {
        return this.vocabularyService.getSystemGeneratedCV('cv_TestArticles').then((item) => {
            this.systemGeneratedTestArticleKey = item.C_TestArticle_key;
        });
    }

    addJobTestArticle(job: any) {
        if (this.readonly) {
            return;
        }

        this.vocabularyService.getSystemGeneratedCV('cv_TestArticles').then((item) => {
            const systemGenerated = job.JobTestArticle.find((jobTestArticle: any) => {
                return jobTestArticle.C_TestArticle_key === item.C_TestArticle_key;
            });
            if (!systemGenerated) {
                const jobTestArticle = {
                    C_Job_key: job.C_Job_key,
                    cv_TestArticle: item
                };
                this.jobService.createJobTestArticle(jobTestArticle);
            }

            let identifier = 1;
            for (const jobTestArticle of job.JobTestArticle) {
                if (jobTestArticle.Identifier >= identifier) {
                    identifier = jobTestArticle.Identifier + 1;
                }
            }

            const initialValues = {
                C_Job_key: job.C_Job_key,
                Identifier: identifier
            };
            this.jobService.createJobTestArticle(initialValues);
        });
    }

    removeTestArticle(jobTestArticle: any) {
        if (this.readonly) {
            return;
        }
        this.jobService.deleteJobTestArticle(jobTestArticle);
    }

    getTestArticleBatches(jobTestArticle: any): Promise<any> {
        if (this.isCRO) {
            return this.dotmaticsService.getDotmaticsBatches(jobTestArticle.C_TestArticle_key, this.job.C_Job_key).then((results) => {
                jobTestArticle.batches = results.data;
            });
        }
        Promise.resolve();
    }

    getTestArticle(jobTestArticle: any): Promise<any> {
        if (this.isCRO) {
            return this.dotmaticsService.getDotmaticsTestArticle(jobTestArticle.C_TestArticle_key).then((results) => {
                jobTestArticle.Vehicle = results.data.Vehicle;
                if (results.data.Vehicle) {
                    jobTestArticle.VehicleLock = true;
                } else {
                    jobTestArticle.VehicleLock = false;
                }
            });
        }
        Promise.resolve();
    }

    getClimbTestArticles(): Promise<any> {
        return this.vocabularyService.getCV('cv_TestArticles').then((testArticles) => {
            this.climbTestArticles = testArticles;
        });
    }

    testArticleChangeHandler(jobTestArticle: any, selection: any) {
        // Find the selected test article
        if (selection) {
            if (this.isCRO) {
                jobTestArticle.Batch = '';
            }
            if (!selection.C_TestArticle_key) {
                // If selected test article has null key, create a test article record and save entity
                const newCv = this.dotmaticsService.createTestArticle(selection.testArticleId);
                this.dataManager.saveEntity("cv_TestArticle").then(() => {
                    // Assign new test article key to JobTestArticle
                    jobTestArticle.C_TestArticle_key = newCv.C_TestArticle_key;
                    // Refresh dropdown list to include new test article
                    this.getClimbTestArticles().then(() => {
                        this.createDropdownList();
                        this.getTestArticleBatches(jobTestArticle);
                        this.getTestArticle(jobTestArticle);
                    });
                });
            } else {
                // Assign new test article key to JobTestArticle
                jobTestArticle.C_TestArticle_key = selection.C_TestArticle_key;
                this.getTestArticleBatches(jobTestArticle);
                this.getTestArticle(jobTestArticle);
            }
        }
    }

    batchChangeHandler(jobTestArticle: any, selectedBatch: any) {
        const batch = jobTestArticle.batches.find((item: any) => item.BatchId === selectedBatch);
        if (batch && batch.Purity) {
            jobTestArticle.Purity = batch.Purity;
        } else {
            jobTestArticle.Purity = null;
        }
    }

    /**
     * Build the test article drop down list by combining dotmatics test articles that don't exist
     * in climb with those that do
     */
    createDropdownList() {
        this.dropdownList = [];
        // Compare dotmatics list with climb list
        this.dotmaticsTestArticles.forEach((testArticle) => {
            const climbTestArticleKey = this.checkIfExists(testArticle);
            if (climbTestArticleKey) {
                // If the dotmatics test article id exists in climb use the climb test article key
                const existingTestArticle: TestArticle = {
                    testArticleId: testArticle,
                    batch: '',
                    C_TestArticle_key: climbTestArticleKey
                };
                this.dropdownList.push(existingTestArticle);
            } else {
                // The test article isn't in Climb yet, so set key to null
                const newTestArticle: TestArticle = {
                    testArticleId: testArticle,
                    batch: '',
                    C_TestArticle_key: null
                };
                this.dropdownList.push(newTestArticle);
            }
        });
    }

    /**
     * Check if dotmatics test article exists in climb
     * Returns key if found, or null if not found
     */
    checkIfExists(testArticleId: any): any {
        for (const testArticle of this.climbTestArticles) {
            const testArticleName = testArticle.TestArticle;
            if (testArticleName === testArticleId) {
                return testArticle.C_TestArticle_key;
            }
        }
        return null;
    }

    purityChanged(jobTestArticle: any) {
        if (!this.isCRO) {
            return;
        }
        // If purity is changed, find the treatments that are associated with the test article
        // calculate formulation dose for them
        // Find the test article for the said treatment
        if (this.job.JobGroup !== null) {
            this.job.JobGroup.forEach((jobGroup: any) => {
                jobGroup.JobGroupTreatment.forEach((treatment: any) => {
                    if (jobTestArticle.cv_TestArticle.TestArticle + "-" + jobTestArticle.Batch === treatment.Treatment) {
                        // We found the test article
                        // Now, see if we have activedose and purity
                        if (treatment.ActiveDose && jobTestArticle.Purity) {
                            // We have both, let's see if they both are numbers
                            if (!isNaN(treatment.ActiveDose) && !isNaN(jobTestArticle.Purity)) {
                                // parse active dose and make it absolute
                                const activeDose = Math.abs(parseFloat(treatment.ActiveDose));
                                // parse purity and make it absolute
                                const purity = Math.abs(parseFloat(jobTestArticle.Purity));
                                // calculate the formulation dose
                                let formulationDose = (activeDose / purity) * 100;
                                // make it have max 7 decimal places
                                formulationDose = Math.round(formulationDose * 10000000) / 10000000;
                                // update formulation dose
                                treatment.FormulationDose = formulationDose;
                                this.updateTaskInputs([treatment], 'FORMULATION DOSE');
                            } else {
                                treatment.FormulationDose = null;
                            }
                        } else {
                            treatment.FormulationDose = null;
                        }
                    }
                });
            });
        }
    }

    updateTaskInputs(jobGroupTreatments: any[], columnName: string) {
        if (this.job.TaskJob.length > 0) {
            this.loggingService.logWarning("Updating dosing task inputs for the edited group(s)", null, "DOSING_TABLE", true);

            this.jobPharmaDetailService.updateTaskInputsFromJobGroup(jobGroupTreatments, columnName).catch((err: any) => {
                console.error(err);
            });
        }
    }

    showMessage() {
        const showToast = true;
        this.loggingService.logWarning(
            'Please save this study before adding a Test Article.',
            null,
            "",
            showToast
        );
    }
}

class TestArticle {
    testArticleId: string;
    batch: string;
    /* eslint-disable-next-line */
    C_TestArticle_key: number;
}

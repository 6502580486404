<form data-automation-id="add-protocol-window" 
      novalidate>
    <div class="modal-header justify-content-center">
        <h4 class="modal-title">Add a Protocol</h4>
    </div>

    <div class="modal-body">

        <div class="form-group row">
            <label for="protocolInput-{{domIdAddition}}"
                   class="col-md-3 col-form-label">Protocol</label>
            <div class="col-md-9">
                <protocol-select data-automation-id="protocol-name-dropdown"
                                 id="protocolInput-{{domIdAddition}}"
                                 [(model)]="protocolKey"
                                 (modelChange)="onProtocolChange()"
                                 [taskType]="taskType"
                                 [showActiveOnly]="true"
                                 [required]="true">
                </protocol-select>
                <small class="form-text text-muted"
                       *ngIf="isMixedProtocol">
                    <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                    Not all tasks will be added: only {{taskType | translate}} tasks can be added
                </small>
            </div>
        </div>

    </div><!-- /.modal-body -->

    <div class="modal-footer">
        <button data-automation-id="add-protocol-button"
                type="button"
                class="btn btn-fw btn-lg btn-primary"
                (click)="onSubmit()"
                [disabled]="!protocolKey || loading">
            Add
        </button>
        <button type="button"
                class="btn btn-fw btn-lg btn-secondary"
                (click)="onCancel()">
            Cancel
        </button>
    </div>
</form>

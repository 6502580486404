<table climbTable
       class="characteristic-table"
       [dataSource]="characteristics"
       climbRowDropList
       (rowDropped)="rowDropped($event)">
    <!-- Header and Row Declarations -->
    <tr climbHeaderRow *climbHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr climbRow
        *climbRowDef="let row; columns: displayedColumns;"
        [climbRowDrag]="allowEdit"
        [ngClass]="{ 'end-state': row.IsActive !== true }">
    </tr>

    <!-- Add/Drag Characteristic Column -->
    <ng-container climbColumnDef="drag" fixed onlyIcon>
        <th climbHeaderCell *climbHeaderCellDef>
            <button climbAddItemButton
                    id="characteristic-type-add-item-button"
                    (click)="addCharacteristic(typeName)">
            </button>
        </th>
        <td climbCell *climbCellDef>
            <climb-drag-handle></climb-drag-handle>
        </td>
    </ng-container>

    <!-- Details Column -->
    <ng-container climbColumnDef="details" fixed onlyIcon>
        <th climbHeaderCell *climbHeaderCellDef></th>
        <td climbCell *climbCellDef="let item">
            <button climbDetailButton
                    (click)="detailsClicked(item)">
            </button>
        </td>
    </ng-container>

    <!-- Delete Column -->
    <ng-container climbColumnDef="delete" fixed onlyIcon>
        <th climbHeaderCell *climbHeaderCellDef></th>
        <td climbCell *climbCellDef="let item">
            <button climbDeleteItemButton
                    (click)="deleteCharacteristic(typeName, item)"
                    title="Delete characteristic">
            </button>
        </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container climbColumnDef="name">
        <th climbHeaderCell *climbHeaderCellDef> Name </th>
        <td climbCell *climbCellDef="let item"> {{ item.CharacteristicName }} </td>
    </ng-container>

    <!-- IACUC Protocol Column -->
    <ng-container climbColumnDef="iacucProtocol">
        <th climbHeaderCell *climbHeaderCellDef> IACUC Protocol </th>
        <td climbCell *climbCellDef="let item">
            {{ item | characteristicLinksList : typeName : 'iacuc' | truncateList : 3 }}
        </td>
    </ng-container>

    <!-- Category Name Column -->
    <ng-container climbColumnDef="categoryName">
        <th climbHeaderCell *climbHeaderCellDef> {{ categoryName }} </th>
        <td climbCell *climbCellDef="let item">
            {{ item | characteristicLinksList: typeName : jobCharacteristicLink | truncateList : 3 }}
        </td>
    </ng-container>

    <!-- Data Type Column -->
    <ng-container climbColumnDef="dataType">
        <th climbHeaderCell *climbHeaderCellDef> Data Type </th>
        <td climbCell *climbCellDef="let item"> {{ getSafe(item, 'cv_DataType.DataType') }} </td>
    </ng-container>

    <!-- Active Column -->
    <ng-container climbColumnDef="active">
        <th climbHeaderCell *climbHeaderCellDef> Active </th>
        <td climbCell *climbCellDef="let item">
            <svg *ngIf="item.IsActive === true"
                 [climbIcon]="icons.checkmarkCircleFilled"
                 class="icon-active"
                 title="Active">
            </svg>
        </td>
    </ng-container>

    <!-- Last Reviewed Column -->
    <ng-container climbColumnDef="lastReviewed">
        <th climbHeaderCell *climbHeaderCellDef> Last Reviewed </th>
        <td climbCell *climbCellDef="let item">
            {{ item.DateLastReviewed | formatShortDate }}
        </td>
    </ng-container>
</table>
<climb-paginator [itemsCount]="totalCount"
                 [pageSize]="pageSize"
                 [pageIndex]="pageIndex"
                 (pageChanged)="changeCharacteristicPage($event.pageIndex)"
                 class="line-top"
                 aria-label="Select page of characteristic items">
</climb-paginator>

<button data-automation-id="climb-support-menu"
        climbButton
        size="md"
        variant="ghost"
        [popoverTriggerFor]="supportPopover"
        popoverOpenWithKeyArrows
        #questionCircleIcon>
    <svg [climbIcon]="icons.questionCircleFilled" size="sm"></svg>
</button>

<climb-popover #supportPopover>
    <div class="popover-section" climbTrapFocus climbArrowKeyAsTab>
        <button data-automation-id="contact-support-item" climb-menu-item [popoverTriggerFor]="contactForm.popover" [popoverOptions]="popoverOptions" (click)="supportPopover.close()">
            <svg [climbIcon]="icons.chat" size="sm"></svg>
            Send message to support
        </button>
        <a *ngIf="!isGLP" climb-menu-item [href]="supportUri" (click)="supportPopover.close()" target="_blank" rel="noopener noreferrer">
            <svg [climbIcon]="icons.arrowUpRight" size="sm"></svg>
            RockStep support center
        </a>
    </div>
</climb-popover>

<climb-contact-form #contactForm></climb-contact-form>

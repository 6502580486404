import type { cv_ClinicalObservation } from './cv-clinical-observation.interface';
import type { cv_Modifier2 } from './cv-modifier2.interface';

export interface cv_ClinicalObservationModifier2 {
    C_ClinicalObservationModifier2_key: number;
    C_ClinicalObservation_key: number;
    C_Modifier2_key: number;
    C_Workgroup_key: number;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    ModifiedBy: string;
    Version: number;
    cv_ClinicalObservation: cv_ClinicalObservation;
    cv_Modifier2: cv_Modifier2;
}

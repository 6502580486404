<table class="table table-bordered job-pharma-animals-placeholder">
    <thead>
        <tr class="detail-grid-header">
            <!-- Select -->
            <th *ngIf="!readonly" class="icon-cell">
                <input type="checkbox"
                       name="selectAll"
                       [(ngModel)]="allSelected"
                       (ngModelChange)="allSelectedChanged()"
                       title="Select/Clear all" />
            </th>
            <th *ngIf="!readonly" class="icon-cell  small-width">
                <button class="btn btn-link detail-grid-btn-icon"
                   (click)="openJobPharmaModal()"
                   [disabled]="job.Placeholder.length === 0 || taskNumber === 0 "
                   *ngIf="!readonly">
                    <i class="fa fa-arrows-alt" title="Assign placeholders to tasks"></i>
                </button>
            </th>
            <th>Placeholder</th>
            <th>Cohort</th>
        </tr>
    </thead>
    <tbody>
        <loading-overlay *ngIf="loading" [message]="loadingMessage"></loading-overlay>
        <tr *ngFor="let placeholder of job.Placeholder | orderBy:'SortOrder'; index as index"
            [ngClass]="{'selected-row': placeholder.isSelected}"
            class="cohort-row">
            <!-- Select -->
            <td *ngIf="!readonly"
                class="icon-cell">
                <input type="checkbox"
                        name="placeholderIsSelected{{index}}"
                        [(ngModel)]="placeholder.isSelected"
                        (ngModelChange)="isSelectedChanged()" />
            </td>

            <td *ngIf="!readonly"
                class="icon-cell">
                
            </td>

            <!-- Placeholder -->
            <td>
                <div draggable
                     data-drag-type="placeholder"
                     (dragStart)="dragStart()"
                     (dragStop)="dragStop()"
                     [dragDisabled]="!placeholder.isSelected">
                    {{placeholder.PlaceholderName}}
                </div>
            </td>

            <!-- Cohort -->
            <td>
                <span *ngIf="placeholder.C_JobCohort_key"
                      id="drag-animals-cohort-{{placeholder.C_JobCohort_key}}"
                      class="cohort-badge"
                      [ngStyle]="getCohortStyle(placeholder.C_JobCohort_key)">
                    <a *ngIf="!readonly"
                       (click)="onRemoveCohortAssociation(placeholder)">
                        <i class="fa fa-remove remove-item" title="Remove Cohort Association"></i>
                    </a>&nbsp;{{ getCohortName(placeholder.C_JobCohort_key) }}
                </span>
            </td>
        </tr>
    </tbody>
</table>

import { Injectable } from '@angular/core';
import { defer, from, Observable } from 'rxjs';

import { OrderService } from '../orders/order.service';
import { ResourceService } from '../resources/resource.service';
import { StudyService } from '../studies/study.service';
import { VocabularyService } from '../vocabularies/vocabulary.service';
import { take } from 'rxjs/operators';
import { cv_Compliance, cv_IACUCProtocol, cv_JobStatus, cv_JobSubtype, cv_JobType, cv_VariablePhraseType, Entity } from '@common/types';

@Injectable()
export class JobVocabService {

    public get jobStatuses$(): Observable<Entity<cv_JobStatus>[]> {
        return defer(() => this.vocabularyService.getCV('cv_JobStatuses')).pipe(take(1));
    }
    public get jobTypes$(): Observable<Entity<cv_JobType>[]> {
        return defer(() => this.vocabularyService.getCV('cv_JobTypes')).pipe(take(1));
    }
    public get jobSubtypes$(): Observable<Entity<cv_JobSubtype>[]> {
        return defer(() => this.vocabularyService.getCV('cv_JobSubtypes')).pipe(take(1));
    }
    public get jobCharacteristicLinkTypes$(): Observable<any[]> {
        return defer(() => this.vocabularyService.getCV('cv_JobCharacteristicLinkTypes')).pipe(take(1));
    }
    public get taxons$(): Observable<any[]> {
        return defer(() => this.vocabularyService.getCV('cv_Taxons')).pipe(take(1));
    }
    public get resources$(): Observable<any[]> {
        return defer(() => this.resourceService.getAllResources()).pipe(take(1));
    }
    public get taskStatuses$(): Observable<any[]> {
        return defer(() => this.vocabularyService.getCV('cv_TaskStatuses')).pipe(take(1));
    }
    public get iacucProtocols$(): Observable<Entity<cv_IACUCProtocol>[]> {
        return defer(() => this.vocabularyService.getCV('cv_IACUCProtocols')).pipe(take(1));
    }
    public get standardPhrases$(): Observable<any[]> {
        return defer(() => this.vocabularyService.getCV('cv_StandardPhrases')).pipe(take(1));
    }
    public get sexes$(): Observable<any[]> {
        return defer(() => this.vocabularyService.getCV('cv_Sexes')).pipe(take(1));
    }
    public get materialOrigins$(): Observable<any[]> {
        return defer(() => this.vocabularyService.getCV('cv_MaterialOrigins')).pipe(take(1));
    }
    public get compliances$(): Observable<Entity<cv_Compliance>[]> {
        return defer(() => this.vocabularyService.getCV('cv_Compliances')).pipe(take(1));
    }
    public get jobReports$(): Observable<any[]> {
        return defer(() => this.vocabularyService.getCV('cv_JobReports', null, null, true)).pipe(take(1));
    }
    public get orders$(): Observable<any[]> {
        return defer(() => this.orderService.getAllOrders()).pipe(take(1));
    }
    public get studies$(): Observable<any[]> {
        return defer(() => this.studyService.getAllStudies()).pipe(take(1));
    }
    public get standardPhraseCategories$(): Observable<any[]> {
        return defer(() => this.vocabularyService.getCV('cv_StandardPhraseCategories', null, null, true)).pipe(take(1));
    }
    public get standardPhraseLinkTypes$(): Observable<any[]> {
        return defer(() => this.vocabularyService.getCV('cv_StandardPhraseLinkTypes')).pipe(take(1));
    }

    public get cv_VariablePhraseTypes$(): Observable<cv_VariablePhraseType[]> {
        return defer(() => this.vocabularyService.getCV('cv_VariablePhraseTypes')).pipe(take(1));
    }

    constructor(
        private orderService: OrderService,
        private studyService: StudyService,
        private resourceService: ResourceService,
        private vocabularyService: VocabularyService
    ) {}

    getStandardPhrasesWithCategory() {
        return this.vocabularyService.getStandardPhrasesWithCategory();
    }

    getStandardPhrasesWithDefaults(): Promise<any> {
        return this.vocabularyService.getStandardPhrasesWithDefaults();
    }
}

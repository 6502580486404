<form novalidate>
    <div class="modal-header justify-content-center">
        <h4 class="modal-title">
            {{'Institution' | translate}} Audit Report
        </h4>
    </div>

    <div class="modal-body modal-body-tall">

        <loading-overlay *ngIf="loading"></loading-overlay>

        <h2>
            {{institution?.Name}}
        </h2>

        <div class="audit-report-section">
            <table class="table audit-table">
                <thead>
                    <tr>
                        <th>Action</th>
                        <th>Modified Date</th>
                        <th>Modified By</th>
                        <th>Name</th>
                        <th *ngIf="isCRL">Account Number</th>
                        <th *ngIf="!isCRL">Identifier</th>
                        <th *ngIf="isCRL">Client ID</th>
                        <th *ngIf="!isCRL">Account Number</th>
                        <th>Email</th>
                        <th>Address</th>
                        <th>Address 2</th>
                        <th>City</th>
                        <th>State</th>
                        <th>Country</th>
                        <th>Zip Code</th>
                        <th>Phone Number</th>
                        <th>Mobile Number</th>
                        <th>Type</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let auditRow of auditData"
                        [ngClass]="{'current-value' : auditRow.IsCurrent, 'expired-value': !auditRow.IsCurrent}">
                        <td class="text-nowrap">
                            <update-type-cell [updateType]="auditRow.UpdateType"></update-type-cell>
                        </td>
                        <td>{{auditRow.ModifiedDate | formatUtcDateTime}}</td>
                        <td>{{auditRow.ModifiedBy | userFullName}}</td>
                        <td>{{auditRow.Name}}</td>
                        <td>{{auditRow.Identifier}}</td>
                        <td>{{auditRow.AccountNumber}}</td>
                        <td>{{auditRow.EmailAddress}}</td>
                        <td>{{auditRow.Address}}</td>
                        <td>{{auditRow.AddressQualifier}}</td>
                        <td>{{auditRow.City}}</td>
                        <td>{{auditRow.StateName}}</td>
                        <td>{{auditRow.Country}}</td>
                        <td>{{auditRow.PostalCode }}</td>
                        <td>{{auditRow.Phone }}</td>
                        <td>{{auditRow.MobilePhone }}</td>
                        <td>{{auditRow.InstitutionType}}</td>
                    </tr>
                </tbody>
            </table>
        </div>

    </div><!-- /.modal-body -->

    <div class="modal-footer">
        <button type="button"
                class="btn btn-fw btn-lg btn-secondary"
                (click)="exportAudit()"
                [disabled]="loading">
            <i class="fa fa-download" aria-hidden="true"></i>
            Export
        </button>

        <button type="button"
                class="btn btn-fw btn-lg btn-secondary"
                (click)="onCancel()">
            Close
        </button>
    </div><!-- /.modal-footer -->
</form>

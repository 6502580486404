<form novalidate>
    <div class="modal-header justify-content-center">
        <h4 class="modal-title">
            Sample Audit Report
        </h4>
    </div>

    <div class="modal-body modal-body-tall">

        <loading-overlay *ngIf="loading"></loading-overlay>

        <h2>
            {{sample?.SampleName}}
        </h2>

        <div class="audit-report-section">
            <table class="table audit-table">
                <thead>
                    <tr>
                        <th>Action</th>
                        <th>Modified Date</th>
                        <th>Modified By</th>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Type</th>
                        <th>Harvest Date</th>
                        <th>Preservation Method</th>
                        <th>Expiration Date</th>
                        <th>{{'Line' | translate}}</th>
                        <th>Species</th>
                        <th>Status</th>
                        <th>Origin</th>
                        <th>Microchip ID</th>
                        <th>Container</th>
                        <th>Measurement</th>
                        <th>Unit</th>
                        <th>Subtype</th>
                        <th>Processing</th>
                        <th>Send To</th>
                        <th>Analysis</th>
                        <th>OrderID</th>
                        <th>Lot Number</th>
                        <th>Special Instructions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let auditRow of auditData"
                        [ngClass]="{'current-value' : auditRow.IsCurrent, 'expired-value': !auditRow.IsCurrent}">
                        <td class="text-nowrap">
                            <update-type-cell [updateType]="auditRow.UpdateType"></update-type-cell>
                        </td>
                        <td>{{auditRow.ModifiedDate | formatUtcDateTime}}</td>
                        <td>{{auditRow.ModifiedBy | userFullName}}</td>
                        <td>{{auditRow.Identifier}}</td>
                        <td class="text-nowrap">{{auditRow.SampleName}}</td>
                        <td>{{auditRow.SampleType}}</td>
                        <td>{{auditRow.DateHarvest | formatShortDateUtc}}</td>
                        <td>{{auditRow.PreservationMethod}}</td>
                        <td>{{auditRow.DateExpiration | formatShortDateUtc}}</td>
                        <td class="text-nowrap">{{auditRow.LineName}}</td>
                        <td>{{auditRow.Species}}</td>
                        <td>{{auditRow.SampleStatus}}</td>
                        <td>{{auditRow.MaterialOrigin}}</td>
                        <td>{{auditRow.MicrochipIdentifier}}</td>
                        <td>{{auditRow.ContainerType}}</td>
                        <td>{{auditRow.Volume}}</td>
                        <td>{{auditRow.Unit}}</td>
                        <td>{{auditRow.SampleSubtype}}</td>
                        <td>{{auditRow.SampleProcessingMethod}}</td>
                        <td>{{auditRow.SendTo}}</td>
                        <td>{{auditRow.SampleAnalysisMethod}}</td>
                        <td>{{auditRow.OrderID}}</td>
                        <td>{{auditRow.LotNumber}}</td>
                        <td>{{auditRow.SpecialInstructions}}</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="audit-report-section">
            <h3>Sources</h3>

            <p *ngIf="!(auditSourcesData | notEmpty)"
               class="no-data-message">
                {{noDataMessage}}
            </p>
            <table class="table table-sm audit-table"
                   *ngIf="auditSourcesData | notEmpty">
                <thead>
                    <tr>
                        <th>Action</th>
                        <th>Modified Date</th>
                        <th>Modified By</th>
                        <th>Source Type</th>
                        <th>Source Name</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let auditRowDetail of auditSourcesData"
                        [ngClass]="{'current-value' : auditRowDetail.IsCurrent, 'expired-value': !auditRowDetail.IsCurrent}">
                        <td class="text-nowrap">
                            <update-type-cell [updateType]="auditRowDetail.UpdateType"></update-type-cell>
                        </td>
                        <td>{{auditRowDetail.ModifiedDate | formatUtcDateTime}}</td>
                        <td>{{auditRowDetail.ModifiedBy | userFullName}}</td>

                        <td>{{auditRowDetail.SourceType}}</td>
                        <td>{{auditRowDetail.SourceName}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="audit-report-section">
            <h3>{{'Jobs' | translate}}</h3>

            <p *ngIf="!(auditJobsData | notEmpty)"
               class="no-data-message">
                {{noDataMessage}}
            </p>

            <table class="table audit-table"
                   *ngIf="auditJobsData | notEmpty">
                <thead>
                    <tr>
                        <th>Action</th>
                        <th>Modified Date</th>
                        <th>Modified By</th>
                        <th>Number</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let auditRow of auditJobsData"
                        [ngClass]="{'current-value' : auditRow.IsCurrent, 'expired-value': !auditRow.IsCurrent}">
                        <td class="text-nowrap">
                            <update-type-cell [updateType]="auditRow.UpdateType"></update-type-cell>
                        </td>
                        <td>{{auditRow.ModifiedDate | formatUtcDateTime}}</td>
                        <td>{{auditRow.ModifiedBy | userFullName}}</td>
                        <td>{{auditRow.JobID}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="audit-report-section">
            <h3>Constructs</h3>

            <p *ngIf="!(auditConstructsData | notEmpty)"
               class="no-data-message">
                {{noDataMessage}}
            </p>

            <table class="table audit-table"
                   *ngIf="auditConstructsData | notEmpty">
                <thead>
                    <tr>
                        <th>Action</th>
                        <th>Modified Date</th>
                        <th>Modified By</th>
                        <th>Construct ID</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let auditRow of auditConstructsData"
                        [ngClass]="{'current-value' : auditRow.IsCurrent, 'expired-value': !auditRow.IsCurrent}">
                        <td class="text-nowrap">
                            <update-type-cell [updateType]="auditRow.UpdateType"></update-type-cell>
                        </td>
                        <td>{{auditRow.ModifiedDate | formatUtcDateTime}}</td>
                        <td>{{auditRow.ModifiedBy | userFullName}}</td>
                        <td>{{auditRow.ConstructID}}</td>
                    </tr>
                </tbody>
            </table>
        </div>

    </div><!-- /.modal-body -->

    <div class="modal-footer">
        <button type="button"
                class="btn btn-fw btn-lg btn-secondary"
                (click)="exportAudit()"
                [disabled]="loading">
            <i class="fa fa-download" aria-hidden="true"></i>
            Export
        </button>

        <button type="button"
                class="btn btn-fw btn-lg btn-secondary"
                (click)="onCancel()">
            Close
        </button>
    </div><!-- /.modal-footer -->
</form>

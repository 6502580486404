import { DataContextService } from './../../services/data-context.service';
const CURRENT_SELECTOR = "kaplan-meier-chart-renderer";
const SHOW_TOAST = true;

import {
    Component,
    Input,
    OnInit
} from '@angular/core';

import { ChartFilterGroup } from '../filters';
import { SurvivalCurveReportFilter } from './survival-curve-report-filter.model';

import { ChartService } from '../chart.service';
import { LoggingService } from '../../services/logging.service';

import {
    notEmpty,
    randomId
} from '../../common/util';

@Component({
    selector: CURRENT_SELECTOR,
    templateUrl: './kaplan-meier-chart-renderer.component.html',
    styles: [`
        .help-container {
          font-size: 13px;
          margin-bottom: 3px;
    }
    `]
})
export class KaplanMeierChartRendererComponent implements OnInit {
    @Input() filterGroups: ChartFilterGroup[];
    @Input() chartTitle: string;
    @Input() interval: string;
    @Input() intervalCount: number;

    dataSource: any[];

    seriesList: any[];
    seriesLabels: string[];

    // State
    loading: boolean;
    rendered: boolean;
    dataIncomplete: boolean;
    errorMessage: string;

    constructor(
        private chartService: ChartService,
        private loggingService: LoggingService,
        private dataContext: DataContextService
    ) {
        // Nothing to do
    }

    ngOnInit() {
        this.resetState();
    }

    resetState(): void {
        this.loading = false;
        this.dataIncomplete = false;
        this.rendered = false;
        this.errorMessage = "";
    }

    createChart(): void {
        this.resetState();
        this.loading = true;

        this.getData().then(() => {
            this.loading = false;
            this.drawChart();
        });
    }

    getData(): Promise<void> {
        this.errorMessage = "";

        return this.dataContext.init().then(() => {
            this.seriesList = [];
            this.seriesLabels = [];

            // perform queries sequentially.
            // copy filterGroups list so we can recurse it like a stack.
            const groups = this.filterGroups.slice();
            return this.getDataInSequence(groups);

        }).catch((error: any) => {
            this.loading = false;
            this.displayLoadError();
            this.loggingService.logError("Failed to load data for chart: " + error.message,
                null, CURRENT_SELECTOR, SHOW_TOAST);
        });
    }

    private getDataInSequence(filterGroups: ChartFilterGroup[]): Promise<void> {
        if (notEmpty(filterGroups)) {
            const filterGroup = filterGroups.pop();

            const reportFilter = this.createReportFilter(filterGroup);
            return this.chartService.getSurvivalCurve(reportFilter)
                .then((results: any) => {

                    if (!results.data) {
                        this.displayLoadError();
                        return;
                    }

                    // Traces with no data cause weird displays, so discard those.
                    if (results.data.length === 0) {
                        return;
                    }

                    this.seriesLabels.push(filterGroup.label);
                    this.seriesList.push(results);
                }).then(() => {
                    // move on to the next group
                    return this.getDataInSequence(filterGroups);
                });
        }
        return Promise.resolve();
    }

    /**
     * Convert FilterGroup to SurvivalCurveReportFilter
     *
     * @param filterGroup
     */
    createReportFilter(filterGroup: ChartFilterGroup): SurvivalCurveReportFilter {
        const reportFilter = new SurvivalCurveReportFilter(this.interval);
        reportFilter.maxRange = this.intervalCount;

        for (const filter of filterGroup.filters) {
            filter.setFilterValues(reportFilter);
        }

        return reportFilter;
    }

    loadingMessage(): string {
        const messageBase = 'Loading';
        const progress: number = 100 * (this.seriesList.length / this.filterGroups.length);

        if (isFinite(progress)) {
            return messageBase + ' (' + Math.round(Number(progress)) + '%)';
        } else {
            return messageBase;
        }
    }

    displayLoadError(): void {
        this.errorMessage = "Failed to load data for chart. Please try again.";
    }

    combineSeries(): any[] {
        const combined: any[] = [];
        for (let i = 0; i < this.seriesList.length; i++) {
            const series = this.seriesList[i];
            for (const point of series.data) {
                const key = this.seriesLabels[i];
                const seriesPoint = {
                    interval: point.interval
                };
                seriesPoint[key] = point.percentSurvival;
                combined.push(seriesPoint);
            }
        }
        return combined;
    }

    customizeTooltip(arg: any) {
        return {
            text: `<b>${arg.seriesName}</b>: ${arg.valueText}`,
        };
    }

    drawChart(): void {
        const seriesCount: number = this.seriesList.length;
        if (seriesCount > 0) {
            if (seriesCount !== this.filterGroups.length) {
                this.dataIncomplete = true;
            }
            this.rendered = true;
            this.dataSource = this.combineSeries();
        } else {
            this.errorMessage = "No related animals were found for these criteria.";
        }
    }
}

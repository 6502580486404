import { CvValue } from './cv-value';
import { SDCharacteristic } from './sd-characteristic';
import { SDLine } from './sd-line';
import { SDNameFormat } from './sd-name-format';

export class SeedValues {
    animalContainerTypes: CvValue[];
    animalNameFormat: SDNameFormat;
    animalStatuses: CvValue[];
    diets: CvValue[];
    exitReasons: CvValue[];
    generations: CvValue[];
    housingNameFormat: SDNameFormat;
    lines: SDLine[];
    lineTypes: CvValue[];
    materialOrigins: CvValue[];
    taxa: CvValue[];
    taxonCharacteristics: SDCharacteristic[];
    matingStatuses: CvValue[];
    matingTypes: CvValue[];
    birthStatuses: CvValue[];
    matingNameFormat: SDNameFormat;
    birthNameFormat: SDNameFormat;
    genotypeAssays: CvValue[];
    genotypeSymbols: CvValue[];
    sampleTypes: CvValue[];
    sampleStatuses: CvValue[];
    preservationMethods: CvValue[];
    sampleContainerTypes: CvValue[];
    sampleNameFormat: SDNameFormat;

    lastWizardStepIndex: number;


    constructor() {
        this.animalContainerTypes = [];
        this.animalNameFormat = null;
        this.animalStatuses = [];
        this.diets = [];
        this.exitReasons = [];
        this.generations = [];
        this.housingNameFormat = null;
        this.lines = [];
        this.lineTypes = [];
        this.materialOrigins = [];
        this.taxa = [];
        this.taxonCharacteristics = [];
        this.matingStatuses = [];
        this.matingTypes = [];
        this.birthStatuses = [];
        this.birthNameFormat = null;
        this.genotypeAssays = [];
        this.genotypeSymbols = [];
        this.sampleTypes = [];
        this.sampleStatuses = [];
        this.preservationMethods = [];
        this.sampleContainerTypes = [];
        this.sampleNameFormat = null;

        this.lastWizardStepIndex = 0;
    }
}

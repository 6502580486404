import type { ContactPerson } from './contact-person.interface';
import type { Institution } from './institution.interface';
import type { Site } from './site.interface';
import type { cv_State } from './cv-state.interface';

export interface cv_Country {
    Abbreviation: string;
    C_Country_key: number;
    C_VocabularySource_key: number | null;
    C_Workgroup_key: number;
    ContactPerson: ContactPerson[];
    Country: string;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    ElementID: string;
    Institution: Institution[];
    IsActive: boolean;
    IsDefault: boolean;
    IsDeprecated: boolean;
    ModifiedBy: string;
    Site: Site[];
    SortOrder: number | null;
    Version: number;
    cv_State: cv_State[];
}

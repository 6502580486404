import type { Cohort } from './cohort.interface';
import type { Material } from './material.interface';

export interface CohortMaterial {
    C_CohortMaterial_key: number;
    C_Cohort_key: number;
    C_Material_key: number;
    C_Workgroup_key: number;
    Cohort: Cohort;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    Material: Material;
    ModifiedBy: string;
    OutputValue1: string;
    OutputValue2: string;
    OutputValue3: string;
    Version: number;
    Scan: string;
    IsScanValid: boolean;
}

import type { AnimalClinicalObservation } from './animal-clinical-observation.interface';
import type { Resource } from './resource.interface';
import type { cv_ClinicalObservation } from './cv-clinical-observation.interface';

export interface ClinicalObservationDetail {
    AnimalClinicalObservation: AnimalClinicalObservation;
    C_AnimalClinicalObservation_key: number;
    C_ClinicalObservation_key: number | null;
    C_ClinicalObservationDetail_key: number;
    C_Resource_key: number | null;
    C_Workgroup_key: number;
    CreatedBy: string;
    DateComplete: Date | null;
    DateCreated: Date;
    DateDue: Date | null;
    DateModified: Date;
    Details: string;
    ModifiedBy: string;
    Notes: string;
    Resource: Resource;
    Version: number;
    cv_ClinicalObservation: cv_ClinicalObservation;
}

import type { ContactPerson } from './contact-person.interface';
import type { JobInstitution } from './job-institution.interface';

export interface JobInstitutionBillingContact {
    C_ContactPerson_key: number;
    C_JobInstitutionBillingContact_key: number;
    C_JobInstitution_key: number;
    C_Workgroup_key: number;
    ContactPerson: ContactPerson;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    JobInstitution: JobInstitution;
    ModifiedBy: string;
    Version: number;
}

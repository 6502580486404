
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class BulkAddCommService {

    private saveCompleteSource = new Subject<any>();
    saveComplete$ = this.saveCompleteSource.asObservable();
    saveComplete() {
        this.saveCompleteSource.next();
    }

    private saveCanceledSource = new Subject<any>();
    saveCanceled$ = this.saveCanceledSource.asObservable();
    saveCanceled() {
        this.saveCanceledSource.next();
    }

    private markFormPristineSource = new Subject<any>();
    markFormPristine$ = this.markFormPristineSource.asObservable();
    markFormPristine() {
        this.markFormPristineSource.next();
    }
}

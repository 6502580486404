import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from "@angular/core";
import { Column, Selectable } from "@common/entitytable/entity-table.service";
import { Cohort, CohortMaterial, Entity } from "@common/types";
import { TranslationService } from "@services/translation.service";
import { CohortService } from "../../services/cohort.service";
import { IFacet } from "@common/facet";

export interface CohortSplitTableOptions {
    columns: Column[];
    housingOptions: CohortHousingOptions;
}

export interface CohortHousingOptions {
    show: boolean;
    new: boolean;
    autoNaming: boolean;
}

@Component({
    selector: 'cohort-split-table-templates',
    templateUrl: './cohort-split-table-templates.component.html'
})
export class CohortSplitTableTemplatesComponent implements OnInit, AfterViewInit {
    @Input() facet: IFacet;
    @Input() cohort: Entity<Cohort>;
    @Input() readonly = false;

    @Output() cohortChange: EventEmitter<void> = new EventEmitter<void>();

    options: CohortSplitTableOptions;

    @ViewChild('nameHeaderTmpl') nameHeaderTmpl: TemplateRef<any>;
    @ViewChild('nameRowTmpl') nameRowTmpl: TemplateRef<any>;
    @ViewChild('genotypeRowTmpl') genotypeRowTmpl: TemplateRef<any>;
    @ViewChild('housingRowTmpl') housingRowTmpl: TemplateRef<any>;
    @ViewChild('priorHousingRowTmpl') priorHousingRowTmpl: TemplateRef<any>;
    @ViewChild('markerRowTmpl') markerRowTmpl: TemplateRef<any>;
    @ViewChild('dateBornDaysRowTmpl') dateBornDaysRowTmpl: TemplateRef<any>;
    @ViewChild('dateBornWeeksRowTmpl') dateBornWeeksRowTmpl: TemplateRef<any>;
    @ViewChild('dateBornMonthsRowTmpl') dateBornMonthsRowTmpl: TemplateRef<any>;
    @ViewChild('scanRowTmpl') scanRowTmpl: TemplateRef<any>;

    constructor(
        private translationService: TranslationService,
        private cohortService: CohortService
    ) { }

    ngOnInit(): void {
        this.options = {
            columns: [],
            housingOptions: {
                show: true,
                new: true,
                autoNaming: true
            }
        };
    }

    ngAfterViewInit(): void {
        this.initColumns();
        this.loadConfig();
    }

    private initColumns(): void {
        const columns = [
            {
                key: 'id',
                field: 'Material.Identifier',
                label: 'Animal ID', 
                visible: false
            },
            {
                key: 'name',
                field: 'Material.Animal.AnimalName',
                label: 'Name', 
                visible: true,
                headerTemplate: this.nameHeaderTmpl,
                rowTemplate: this.nameRowTmpl
            },
            /** Cohort Outputs */
            this.cohort.Output1 && {
                key: 'output1',
                field: 'OutputValue1',
                label: this.cohort.Output1.OutputName,
                visible: true,
            },
            this.cohort.Output2 && {
                key: 'output2',
                field: 'OutputValue2',
                label: this.cohort.Output2.OutputName,
                visible: true,
            },
            this.cohort.Output3 && {
                key: 'output3',
                field: 'OutputValue3',
                label: this.cohort.Output3.OutputName,
                visible: true,
            },
            {
                key: 'externalID',
                field: 'Material.ExternalIdentifier',
                label: 'External ID', 
                visible: true,
            },
            {
                key: 'housingID', 
                field: 'Material.MaterialPoolMaterial[0].MaterialPool.MaterialPoolIDSortable',
                label: 'Housing ID', 
                visible: true,
                rowTemplate: this.housingRowTmpl,
            },
            /** Sortable disabled for Prior Housing ID */
            {
                key: 'priorHousingID', 
                field: '',
                label: 'Prior Housing ID', 
                visible: true,
                rowTemplate: this.priorHousingRowTmpl,
            },
            {
                key: 'markerType',
                field: 'Material.Animal.cv_PhysicalMarkerType.PhysicalMarkerType',
                label: 'Marker Type', 
                visible: false,
            },
            {
                key: 'marker', 
                field: 'Material.Animal.PhysicalMarker',
                label: 'Marker', 
                visible: false,
                rowTemplate: this.markerRowTmpl,
            },
            {
                key: 'microchipID',
                field: 'Material.MicrochipIdentifier',
                label: 'Microchip ID', 
                visible: true,
            },
            {
                key: 'Scan',
                field: '',
                label: 'Scan',
                visible: true,
                rowTemplate: this.scanRowTmpl
            },
            {
                key: 'ageDays', 
                field: 'Material.Animal.DateBorn',
                label: 'Age (Days)', 
                visible: false,
                rowTemplate: this.dateBornDaysRowTmpl,
            },
            {
                key: 'ageWeeks', 
                field: 'Material.Animal.DateBorn',
                label: 'Age (Weeks)', 
                visible: false,
                rowTemplate: this.dateBornWeeksRowTmpl,
            },
            {
                key: 'ageMonths',
                field: 'Material.Animal.DateBorn',
                label: 'Age (Months)', 
                visible: false,
                rowTemplate: this.dateBornMonthsRowTmpl,
            },
            {
                key: 'sex',
                field: 'Material.Animal.cv_Sex.Sex',
                label: 'Sex', 
                visible: false,
            },
            {
                key: 'line',
                field: 'Material.Line.LineName',
                label: this.translationService.translate('line'), 
                visible: false,
            },
            {
                key: 'genotype', 
                field: 'Material.Animal.GenotypesSortable',
                label: 'Genotype', 
                visible: false,
                rowTemplate: this.genotypeRowTmpl
            }
        ].filter(c => c);

        this.options.columns = columns;
    }

    private loadConfig() {
        const cohortSplitConfig = this.cohortService.parseCohortSplitConfig(this.facet);
        if (!cohortSplitConfig) {
            return;
        }

        const { columnSelect, housingOptions } = cohortSplitConfig;
        if (columnSelect && columnSelect?.model.length) {
            this.options.columns.forEach(c => c.visible = false);
            this.options.columns.forEach(c => {
                if (columnSelect.model.includes(c.key)) {
                    c.visible = true;
                }
            });

            if (this.options.columns.every(c => !c.visible)) {
                this.options.columns[0].visible = true;
            }
        }

        if (housingOptions) {
            this.options.housingOptions.show = housingOptions.show ?? this.options.housingOptions.show;
            this.options.housingOptions.new = housingOptions.new ?? this.options.housingOptions.new;
            this.options.housingOptions.autoNaming = housingOptions.autoNaming ?? this.options.housingOptions.autoNaming;
        }

    }

    onDrop(cohort: Cohort) {
        this.cohortService.handleDrop(cohort);
        this.cohortChange.emit();
    }

    dragStart(cohort: Cohort) {
        const materialsToMove: CohortMaterial[] = [];
        cohort.CohortMaterial.forEach((cm: CohortMaterial & Selectable) => {
            if (cm.isSelected) {
                materialsToMove.push(cm);
            }
        });
        this.cohortService.dragStart(cohort, materialsToMove);
    }

    dragStop() {
        setTimeout(() => {
            this.cohortService.dragStop();
        }, 500);
    }

    onScanChange(cohortMaterial: CohortMaterial) {
        if (cohortMaterial.Scan === cohortMaterial.Material.MicrochipIdentifier) {
            cohortMaterial.Scan = cohortMaterial.Material.Animal.AnimalName;            
        }

        cohortMaterial.IsScanValid = cohortMaterial.Scan === cohortMaterial.Material.Animal.AnimalName;
    }
}

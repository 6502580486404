import { Injectable } from '@angular/core';
import {
    FilterQueryOp,
    Predicate,
    QueryResult,
} from 'breeze-client';

import {
    notEmpty,
} from '../common/util';

import { DataManagerService } from '../services/data-manager.service';
import { QueryDef } from '../services/query-def';
import { BaseEntityService } from '../services/base-entity.service';
import { getDateRangePredicates } from '../services/queries';

@Injectable()
export class CensusService extends BaseEntityService {

    constructor(
        private dataManager: DataManagerService
    ) {
        super();
    }


    getCensuses(queryDef: QueryDef): Promise<QueryResult> {
        let query = this.buildDefaultQuery('Censuses', queryDef);

        /*
         * Please declare expands clauses in calling function
         * instead of here
         */
        if (notEmpty(queryDef.expands)) {
            query = query.expand(queryDef.expands.join(','));
        }

        let predicates: Predicate[] = [];
        if (queryDef.filter) {
            predicates = predicates.concat(this.buildPredicates(queryDef.filter));
        }

        if (notEmpty(predicates)) {
            query = query.where(Predicate.and(predicates));
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed) as Promise<QueryResult>;
    }

    async getCensus(censusKey: number, expands?: string[]): Promise<any> {
        const queryDef = {
            expands,
            filter: {
                C_Cohort_key: censusKey
            },
        };
    
        try {
            const queryResults = await this.getCensuses(queryDef);
            return queryResults.results.length > 0 ? queryResults.results[0] : null;
        } catch (error) {
            console.error(error);
        }
    }

    getCensusMaterialPools(queryDef: QueryDef): Promise<QueryResult> {
        let query = this.buildDefaultQuery('CensusMaterialPools', queryDef);

        /*
         * Please declare expands clauses in calling function
         * instead of here
         */
        if (notEmpty(queryDef.expands)) {
            query = query.expand(queryDef.expands.join(','));
        }

        let predicates: Predicate[] = [];
        if (queryDef.filter) {
            predicates = predicates.concat(this.buildPredicates(queryDef.filter));
        }

        if (notEmpty(predicates)) {
            query = query.where(Predicate.and(predicates));
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed) as Promise<QueryResult>;
    }

    getMaterialPools(queryDef: QueryDef): Promise<QueryResult> {
        let query = this.buildDefaultQuery('MaterialPools', queryDef);
        
        if (notEmpty(queryDef.expands)) {
            query = query.expand(queryDef.expands.join(','));
        }

        let predicates: Predicate[] = [];
        if (queryDef.filter) {
            predicates = predicates.concat(this.buildPredicates(queryDef.filter));
        }

        if (notEmpty(predicates)) {
            query = query.where(Predicate.and(predicates));
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed) as Promise<QueryResult>;
    }

    getAnimals(queryDef: QueryDef): Promise<QueryResult> {
        let query = this.buildDefaultQuery('Animals', queryDef);

        this.ensureDefExpanded(queryDef, 'Material.Line');
        query = query.expand(queryDef.expands.join(','));

        let predicates: Predicate[] = [];
        if (queryDef.filter) {
            predicates = predicates.concat(this.buildPredicates(queryDef.filter));
        }

        if (notEmpty(predicates)) {
            query = query.where(Predicate.and(predicates));
        }

        return this.dataManager.executeQuery(query).catch(this.dataManager.queryFailed) as Promise<QueryResult>;
    }

    buildPredicates(filter: any): Predicate[] {
        let predicates: Predicate[] = [];

        if (!filter) {
            return;
        }

        if (filter.C_Census_key) {
            predicates.push(Predicate.create('C_Census_key', '==', filter.C_Census_key));
        }

        if (notEmpty(filter.censusIDs)) {
            predicates.push(Predicate.create(
                'C_Census_key', 'in', filter.censusIDs
            ));
        }
        
        if (notEmpty(filter.C_CensusStatus_key)) {
            predicates.push(Predicate.create(
                'C_CensusStatus_key', 'in', filter.C_CensusStatus_key
            ));
        }

        if (filter.DateStart || filter.DateEnd) {
            const datePredicates: Predicate[] = getDateRangePredicates(
                'Date',
                filter.DateStart,
                filter.DateEnd
            );
            if (notEmpty(datePredicates)) {
                predicates = predicates.concat(datePredicates);
            }
        }
        if (filter.Location) {
            predicates.push(Predicate.create(
                'CurrentLocationPath', FilterQueryOp.Contains, { value: filter.Location },
            ));
        }
        if (filter.MainPositionPath) {
            predicates.push(Predicate.create(
                'CurrentLocationPath', '==', filter.MainPositionPath
            ));
        }

        if (filter.AnimalKeys) {
            predicates.push(Predicate.create(
                'C_Material_key', 'in', filter.AnimalKeys
            ));
        }

        if (filter.HousingKeys) {
            predicates.push(Predicate.create(
                'C_MaterialPool_key', 'in', filter.HousingKeys
            ));
        }

        if (filter.HousingKey) {
            predicates.push(Predicate.create(
                'MaterialPool.MaterialPoolID', '==', filter.HousingKey
            ));
        }
        return predicates;
    }

    /**
     * create a Census entity
     */
    create(): any {
        return this.dataManager.createEntity('Census', {
            Date: new Date()
        });
    }

    deleteCensus(census: any) {
        if (census) {
            this.dataManager.deleteEntity(census);
        }
    }
    
    /**
     * Cancel a newly added census record
     * @param census
     */
    cancelCensus(census: any) {
        if (!census) {
            return;
        }
        if (census.C_Census_key > 0) {
            this.cancelCensusEdits(census);
        } else {
            this.cancelNewCensus(census);
        }
    }

    cancelHousing(housing: any) {
        if (!housing) {
            return;
        }

        this.cancelCensusHousingEdits(housing);
    }

    private cancelNewCensus(census: any) {
        try {
            this.deleteCensus(census);
        } catch (error) {
            console.error('Error cancelling census add: ' + error);
        }
    }

    private cancelCensusEdits(census: any) {
        this.dataManager.rejectEntityAndRelatedPropertyChanges(census);
    }

    private cancelCensusHousingEdits(housing: any) {
        this.dataManager.rejectEntityAndRelatedPropertyChanges(housing);
        this.dataManager.rejectChangesToEntityByFilter(
            'MaterialPool', (item: any) => {
                return item.C_MaterialPool_key === housing.C_MaterialPool_key;
            }
        );
        for (const material of housing.CensusMaterialPoolMaterial) {
            const materials = this.dataManager.rejectChangesToEntityByFilter(
                'CensusMaterialPoolMaterial', (item: any) => {
                    return item.C_CensusMaterialPoolMaterial_key === material.C_CensusMaterialPoolMaterial_key;
                }
            );
            for (const animal of materials) {
                this.dataManager.rejectChangesToEntityByFilter(
                    'Animal', (item: any) => {
                        return item.C_Material_key === animal.C_Material_key;
                    }
                );

                this.dataManager.rejectChangesToEntityByFilter(
                    'Material', (item: any) => {
                        return item.C_Material_key === animal.C_Material_key;
                    }
                );
                
            }
        }
    }

    async ensureVisibleColumnsDataLoaded(censuses: any[], visibleColumns: string[]): Promise<void> {
        const expands = this.generateExpandsFromVisibleColumns(censuses[0], visibleColumns);
        return this.dataManager.ensureRelationships(censuses, expands);
    }
}


import { Injectable } from '@angular/core';
import {
    NgbModal,
    NgbModalOptions
} from '@ng-bootstrap/ng-bootstrap';
import { Entity } from '@common/types';
import { Workspace } from '@common/types/models/workspace.interface';
import { AddWorkspaceComponent } from './components/add-workspace/add-workspace.component';


@Injectable()
export class AddWorkspaceModalService {

    modalOptions: NgbModalOptions;

    constructor(
        private modalService: NgbModal
    ) {
        this.init();
    }

    private init() {
        // NgbModalOptions: https://ng-bootstrap.github.io/#/components/modal/api#NgbModalOptions
        // this.modalOptions = {
        //     backdrop: 'static',
        //     size: 'sm'
        // };
    }


    openAddWorkspaceModal(): Promise<Entity<Workspace> | void> {
        const modalRef = this.modalService.open(AddWorkspaceComponent, this.modalOptions);
        return modalRef.result.catch((error) => {
            // ignore modal dismissal
        });
    }
}

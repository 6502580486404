import {
    Component,
    OnInit,
} from '@angular/core';

import {
    NgbActiveModal, NgbNavChangeEvent
} from '@ng-bootstrap/ng-bootstrap';
import { getSafeProp, randomId } from '../../common/util';
import { VocabularyService } from '../vocabulary.service';


@Component({
    selector: 'vocabularies-modifiers-modal',
    templateUrl: './vocabularies-modifiers-modal.component.html',
    styles: [`
        .checkbox-group {
            padding-top: 2px;
            padding-bottom: 2px;
        }

        .checkbox-column > .checkbox-group:not(:first-child) {
            border-top: 1px solid #ddd;
        }

        .checkbox-group input[type="checkbox"] {
            display: none;
        }

        .checkbox-group input[type="checkbox"] + .btn-group > label span {
            width: 20px;
        }

        .checkbox-group input[type="checkbox"] + .btn-group > label span:first-child {
            display: none;
        }

        .checkbox-group input[type="checkbox"] + .btn-group > label span:last-child {
            display: inline-block;
        }

        .checkbox-group input[type="checkbox"]:checked + .btn-group > label span:first-child {
            display: inline-block;
        }

        .checkbox-group input[type="checkbox"]:checked + .btn-group > label span:last-child {
            display: none;
        }
        
        .checkbox-group .input-shape {
            align-self: flex-start;
            margin-right: 5px;
            margin-bottom: 0;
            padding: 4px 3px;
            border-radius: 3px;
        }
        .checkbox-group .observation-label {
            margin-top: 5px;
            margin-bottom: 0;
            background-color: white;
            background-image: none;
            font-weight: normal;
        }
    `]
})
export class VocabulariesModifiersModalComponent implements OnInit {

    domIdAddition: string;
    modalData: any;
    clinicalObservationKey: any;

    // Modifiers 
    modifiers1: any[] = [];
    modifiers2: any[] = [];
    modifiers3: any[] = [];
    modifiers4: any[] = [];

    // Modifiers Copies

    mod1: any[] = [];
    mod2: any[] = [];
    mod3: any[] = [];
    mod4: any[] = [];

    // Modifiers Value from DB
    modifiers1Value: any[] = [];
    modifiers2Value: any[] = [];
    modifiers3Value: any[] = [];
    modifiers4Value: any[] = [];

    // Are all the rows selected?
    allSelectedModifier1 = false;
    allSelectedModifier2 = false;
    allSelectedModifier3 = false;
    allSelectedModifier4 = false;

    // Search Input

    searchInputModifier1 = '';
    searchInputModifier2 = '';
    searchInputModifier3 = '';
    searchInputModifier4 = '';

    // Which tab in each set is currently active
    activeTabs: { [index: string]: string } = {
        vocabulary: 'modifier1',
    };

    // loading
    loading = false;

    constructor(
        public activeModal: NgbActiveModal,
        public vocabService: VocabularyService
    ) { }

    ngOnInit() {
        this.loading = true;
        this.domIdAddition = randomId();
        this.getClinicalObservationsCVs().then(() => {
            this.reloadModifiers();
        });
    }

    reloadModifiers() {
        // get Clinical Observation Cvs
        this.modifiers1Value = this.modalData.modifiers1;
        this.modifiers2Value = this.modalData.modifiers2;
        this.modifiers3Value = this.modalData.modifiers3;
        this.modifiers4Value = this.modalData.modifiers4;

        // clone Modifiers to other array

        this.mod1 = [...this.modifiers1];
        this.mod2 = [...this.modifiers2];
        this.mod3 = [...this.modifiers3];
        this.mod4 = [...this.modifiers4];

        this.getClinicalObservationsCVs().then(() => {
            this.clearAllTabs();
            if (this.modifiers1Value.length > 0) {
                this.setSelectedModifiers(this.modifiers1Value, this.modifiers1, "C_Modifier1_key");
            }
            if (this.modifiers2Value.length > 0) {
                this.setSelectedModifiers(this.modifiers2Value, this.modifiers2, "C_Modifier2_key");
            }
            if (this.modifiers3Value.length > 0) {
                this.setSelectedModifiers(this.modifiers3Value, this.modifiers3, "C_Modifier3_key");
            }
            if (this.modifiers4Value.length > 0) {
                this.setSelectedModifiers(this.modifiers4Value, this.modifiers4, "C_Modifier4_key");
            }
            const filteredResults = this.filterModifiers([this.modifiers1, this.modifiers2, this.modifiers3, this.modifiers4]);
            this.modifiers1 = filteredResults[0];
            this.modifiers2 = filteredResults[1];
            this.modifiers3 = filteredResults[2];
            this.modifiers4 = filteredResults[3];

            this.loading = false;
        });

        this.isSelectedModifiers1Changed();
        this.isSelectedModifiers2Changed();
        this.isSelectedModifiers3Changed();
        this.isSelectedModifiers4Changed();
    }

    setSelectedModifiers(modifierValues: any[], modifierOptions: any[], modifierKeyValue: string) {
        for (const modifier of modifierValues) {
            const index = modifierOptions.findIndex((m: any) => getSafeProp(m, modifierKeyValue) === getSafeProp(modifier, modifierKeyValue));
            if (index >= 0) {
                modifierOptions[index].isSelected = true;
            }
        }
    }

    getClinicalObservationsCVs() {
        return this.vocabService.getModifiers().then((results: any[]) => {
            this.modifiers1 = results[0];
            this.modifiers2 = results[1];
            this.modifiers3 = results[2];
            this.modifiers4 = results[3];
        });
    }

    filterModifiers(modifiersList: any[]) {
        for (let i = 0; i < modifiersList.length; i++) {
            const modifiers = modifiersList[i];
            modifiersList[i] = modifiers.filter((m: any) => m.IsActive || m.isSelected);
        }
        return modifiersList;
    }

    tabChanged(tabset: string, $event: NgbNavChangeEvent) {
        // Parse the tab name from the event: tab-TABSET-TAB-RANDOM
        const tokens = $event.nextId.split(/-/);
        this.activeTabs[tabset] = tokens[2];
    }

    allSelectedChanged(tabData: any, isSelected: any) {
        // Select or unselect all tdhe rows
        if (tabData) {
            for (const data of tabData) {
                data.isSelected = isSelected;
            }
        }
    }

    selectAll() {
        if (this.activeTabs.vocabulary === 'modifier1' && this.modifiers1.length > 0) {
            this.modifiers1.forEach((modifier1) => {
                modifier1.isSelected = true;
            });
        }
        if (this.activeTabs.vocabulary === 'modifier2' && this.modifiers2.length > 0) {
            this.modifiers2.forEach((modifier2) => {
                modifier2.isSelected = true;
            });
        }
        if (this.activeTabs.vocabulary === 'modifier3' && this.modifiers3.length > 0) {
            this.modifiers3.forEach((modifier3) => {
                modifier3.isSelected = true;
            });
        }
        if (this.activeTabs.vocabulary === 'modifier4' && this.modifiers4.length > 0) {
            this.modifiers4.forEach((modifier4) => {
                modifier4.isSelected = true;
            });
        }
    }

    /**
     * Unselects all modifiers from all tabs. 
     */
    clearAllTabs() {
        this.modifiers1.concat(this.modifiers2).concat(this.modifiers3).concat(this.modifiers4).forEach((modifier: any) => {
            modifier.isSelected = false;
        });
    }
    // Search Modifier 

    searchModifier(value: string) {
        if (this.activeTabs.vocabulary === 'modifier1') {
            if (value === "") {
                this.modifiers1 = [...this.mod1].filter((m: any) => m.IsActive || m.isSelected);
                return this.modifiers1;
            } 
            if (value.length > 0 ) {
                this.modifiers1 = this.mod1.filter((data: any) => {
                    return (data.IsActive || data.isSelected) && data.Modifier1.toLowerCase().includes(value.toLowerCase());
                });
            }
        }

        if (this.activeTabs.vocabulary === 'modifier2') {
            if (value === "") {
                this.modifiers2 = [...this.mod2].filter((m: any) => m.IsActive || m.isSelected);
                return this.modifiers2;
            } 

            if (value.length > 0) {
                    this.modifiers2 = this.mod2.filter((data: any) => {
                        return (data.IsActive || data.isSelected) && data.Modifier2.toLowerCase().includes(value.toLowerCase());
                    });
            }
            
        }

        if (this.activeTabs.vocabulary === 'modifier3') {
            if (value === "") {
                this.modifiers3 = [...this.mod3].filter((m: any) => m.IsActive || m.isSelected);
                return this.modifiers3;
            } 
            
            if (value.length > 0) {
                this.modifiers3 = this.mod3.filter((data: any) => {
                    return (data.IsActive || data.isSelected) && data.Modifier3.toLowerCase().includes(value.toLowerCase());
                });
            }
        }

        if (this.activeTabs.vocabulary === 'modifier4') {
            if (value === "") {
                this.modifiers4 = [...this.mod4].filter((m: any) => m.IsActive || m.isSelected);
                return this.modifiers4;
            } 
            
            if (value.length > 0) {
                this.modifiers4 = this.mod4.filter((data: any) => {
                    return (data.IsActive || data.isSelected) && data.Modifier4.toLowerCase().includes(value.toLowerCase());
                });
            }
        }
    }


    isSelectedModifiers1Changed() {
        this.allSelectedModifier1 = this.modifiers1.every((modifier1) => modifier1.isSelected);
    }

    isSelectedModifiers2Changed() {
        this.allSelectedModifier2 = this.modifiers2.every((modifier2) => modifier2.isSelected);
    }

    isSelectedModifiers3Changed() {
        this.allSelectedModifier3 = this.modifiers3.every((modifier3) => modifier3.isSelected);
    }

    isSelectedModifiers4Changed() {
        this.allSelectedModifier4 = this.modifiers4.every((modifier4) => modifier4.isSelected);
    }

    clearSelections() {
        if (this.activeTabs.vocabulary === 'modifier1' && this.modifiers1.length > 0) {
            this.modifiers1.forEach((modifier1) => {
                modifier1.isSelected = false;
            });
        }
        if (this.activeTabs.vocabulary === 'modifier2' && this.modifiers2.length > 0) {
            this.modifiers2.forEach((modifier2) => {
                modifier2.isSelected = false;
            });
        }
        if (this.activeTabs.vocabulary === 'modifier3' && this.modifiers3.length > 0) {
            this.modifiers3.forEach((modifier3) => {
                modifier3.isSelected = false;
            });
        }
        if (this.activeTabs.vocabulary === 'modifier4' && this.modifiers4.length > 0) {
            this.modifiers4.forEach((modifier4) => {
                modifier4.isSelected = false;
            });
        }
    }

    clearAll() {
        // Clear the selections first
        this.clearSelections();
    }

    onCancel(): void {
        this.activeModal.dismiss();
        this.clearAll();
    }

    createOrDeleteOptions(modifierOptions: any[], modifierValues: any[], modifierKeyName: string, createEntity: string) {
        modifierOptions.forEach((modifier: any) => {
            // if the modifier is selected
            if (modifier.isSelected) {
                // Check to see if it already exists
                const existingModifiers = modifierValues.filter((mod: any) => getSafeProp(mod, modifierKeyName) === getSafeProp(modifier, modifierKeyName));
                if (existingModifiers.length === 0) {
                    // if it doesn't exist, create one. 
                    const item = this.vocabService.create(createEntity);
                    item[modifierKeyName] = getSafeProp(modifier, modifierKeyName);
                    item.C_ClinicalObservation_key = this.clinicalObservationKey;
                }
            } else {
                // If the modifier is not selected, delete it if it exists on the ClinicalObservation.
                const existingModifiers = modifierValues.filter((mod: any) => getSafeProp(mod, modifierKeyName) === getSafeProp(modifier, modifierKeyName));
                if (existingModifiers.length > 0) {
                    this.vocabService.deleteVocabTerm(existingModifiers[0]);
                }
            }
        });
    }

    onSubmit(): void {
        this.createOrDeleteOptions(this.modifiers1, this.modifiers1Value, "C_Modifier1_key", "cv_ClinicalObservationModifier1");
        this.createOrDeleteOptions(this.modifiers2, this.modifiers2Value, "C_Modifier2_key", "cv_ClinicalObservationModifier2");
        this.createOrDeleteOptions(this.modifiers3, this.modifiers3Value, "C_Modifier3_key", "cv_ClinicalObservationModifier3");
        this.createOrDeleteOptions(this.modifiers4, this.modifiers4Value, "C_Modifier4_key", "cv_ClinicalObservationModifier4");

        this.activeModal.dismiss();
        this.clearAll();
    }
}

<div class="intro">
    <p>
        Bulk export your animal and test results from a task.
        Generating an export file may take a few minutes.
    </p>
</div>

<div class="report-param-inputs">
    <div class="form-group">
        <label for="taskSelect-1-{{domIdAddition}}">Task 1</label>
        <task-select data-automation-id="task-name-dropdown"
                        id="taskSelect-1-{{domIdAddition}}"
                        [(model)]="taskSelections[0]"
                        [required]="!hasOneTask"
                        [showActiveOnly]="false"></task-select>
    </div>
    <div class="form-group">
        <label for="taskSelect-2-{{domIdAddition}}">Task 2</label>
        <task-select data-automation-id="task-name-dropdown"
                        id="taskSelect-2-{{domIdAddition}}"
                        [(model)]="taskSelections[1]"
                        [required]="!hasOneTask"
                        [showActiveOnly]="false"></task-select>
    </div>
    <div class="form-group">
        <label for="taskSelect-3-{{domIdAddition}}">Task 3</label>
        <task-select data-automation-id="task-name-dropdown"
                        id="taskSelect-3-{{domIdAddition}}"
                        [(model)]="taskSelections[2]"
                        [required]="!hasOneTask"
                        [showActiveOnly]="false"></task-select>
    </div>
    <div class="form-group">
        <label for="taskSelect-4-{{domIdAddition}}">Task 4</label>
        <task-select data-automation-id="task-name-dropdown"
                        id="taskSelect-4-{{domIdAddition}}"
                        [(model)]="taskSelections[3]"
                        [required]="!hasOneTask"
                        [showActiveOnly]="false"></task-select>
    </div>
    <div class="form-group">
        <label for="taskSelect-5-{{domIdAddition}}">Task 5</label>
        <task-select data-automation-id="task-name-dropdown"
                        id="taskSelect-5-{{domIdAddition}}"
                        [(model)]="taskSelections[4]"
                        [required]="!hasOneTask"
                        [showActiveOnly]="false"></task-select>
    </div>
    <div class="row">
        <div class="col-md-6">
            <div class="form-group">
                <label for="startDateRangeInput-{{domIdAddition}}">
                    Collected Date Range Start
                    <info-tooltip [text]="'The start of the date range for collection of output values.'"></info-tooltip>
                </label>
                <climb-ngb-date #dateControl="ngModel"
                                id="startDateRangeInput-{{domIdAddition}}"
                                name="Collected Date Range Start"
                                [(ngModel)]="searchParams.collectedStartDate"
                                [placeholder]="'From&hellip;'"
                                ngDefaultControl></climb-ngb-date>
            </div>

            <div class="form-group">
                <label for="endDateRangeInput-{{domIdAddition}}">
                    Collected Date Range End
                    <info-tooltip [text]="'The end of the date range for collection of output values.'"></info-tooltip>
                </label>
                <climb-ngb-date #dateControl="ngModel"
                                id="endDateRangeInput-{{domIdAddition}}"
                                name=" Collected Date Range End"
                                [(ngModel)]="searchParams.collectedEndDate"
                                [placeholder]="'To&hellip;'"
                                ngDefaultControl></climb-ngb-date>
            </div>
        </div>

        <div class="col-md-6">
            <div class="form-group">
                <label for="startCompletedDateRangeInput-{{domIdAddition}}">
                    Completed Date Range Start
                    <info-tooltip [text]="'The start date range for the completion of the task instance.'"></info-tooltip>
                </label>
                <climb-ngb-date #dateControl="ngModel"
                                id="startCompletedDateRangeInput-{{domIdAddition}}"
                                name="Completed Date Range Start"
                                [(ngModel)]="searchParams.completedStartDate"
                                [placeholder]="'From&hellip;'"
                                ngDefaultControl></climb-ngb-date>
            </div>

            <div class="form-group">
                <label for="endCompletedDateRangeInput-{{domIdAddition}}">
                    Completed Date Range End
                    <info-tooltip [text]="'The end date range for the completion of the task instance.'"></info-tooltip>
                </label>
                <climb-ngb-date #dateControl="ngModel"
                                id="endCompletedDateRangeInput-{{domIdAddition}}"
                                name="Completed Date Range End"
                                [(ngModel)]="searchParams.completedEndDate"
                                [placeholder]="'To&hellip;'"
                                ngDefaultControl></climb-ngb-date>
            </div>
        </div>
    </div>
    <div class="form-group">
        <label for="animalSelect-{{domIdAddition}}">Animals</label>
        <multi-paste-input 
            [(model)]="searchParams.materialIdentifiers" 
            [isNumeric]="true" 
            [limit]="ANIMAL_PASTE_LIMIT"
            [rows]="3"></multi-paste-input>
        <small class="form-text text-muted">
        IDs separated by
        <code>,</code> or <code>Enter</code>. 
        Limit {{ANIMAL_PASTE_LIMIT}}
        </small>
    </div>
    <div class="form-group">
        <label for="cohortSelect-{{domIdAddition}}">Cohort</label>
        <cohort-multiselect id="cohortSelect-{{domIdAddition}}"
                       [(model)]="searchParams.cohortKeys"></cohort-multiselect>

    </div>

    <div class="form-group">
        <div class="row">
            <div class="col-6">
                <label for="lineSelect-{{domIdAddition}}">{{'Line' | translate}}</label>
                <line-select id="lineSelect-{{domIdAddition}}"
                             [(model)]="searchParams.lineKey"
                             (modelChange)="onLineChange($event)"></line-select>
            </div>
            <div class="col-6">
                <label for="animalTaxon">Species</label>
                <input 
                        type="text" 
                        id="animalTaxon"
                        name="animalTaxon"
                        class="form-control input-medium"
                        readonly="readonly"
                        [value]="taxon.CommonName"/>
            </div>
        </div>
    </div>
    
    <div class="form-group">
        <label for="jobSelect-{{domIdAddition}}">{{'Job' | translate}}</label>
        <job-multiselect id="jobSelect-{{domIdAddition}}"
                    [(model)]="searchParams.jobKeys"></job-multiselect>
    </div>
</div>
<div class="btn-container">
    <button type="button"
            class="btn btn-fw-xwide btn-primary"
            (click)="submitRequest()"
            [disabled]="!isFormValid">
        <ng-container *ngIf="!isSubmitted; else submittedIndicator">
            Request Export
        </ng-container>
        <ng-template #submittedIndicator>
            <i class="fa fa-spinner fa-spin" title="Requesting&hellip;"></i>
        </ng-template>
    </button>
    <button type="button"
            class="btn btn-fw-xwide btn-secondary"
            (click)="cancelClicked()">
        Cancel
    </button>
</div>

<table class="table mb-0" *ngIf="samples">
    <thead>
        <tr>
            <th>
                <a (click)="tableSort.toggleSort('SampleName')"
                   class="header-sort-link"
                   title="Sort by Sample">Sample</a>
            </th>
            <th>
                Source
            </th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let sample of samples |
                orderBy: tableSort.propertyPath:tableSort.reverse">
            <td>
                {{sample?.SampleName}}
            </td>
            <td>
            <ng-container *ngIf="sample?.Material?.MaterialSourceMaterial">
                <span *ngFor="let source of sample.Material.MaterialSourceMaterial; let last = last;">
                    {{source?.SourceMaterial?.Animal?.AnimalName ||
                        source?.SourceMaterial?.Sample?.SampleName}}<span *ngIf="!last">,</span>
                </span>
            </ng-container>
            </td>
        </tr>
    </tbody>
</table>

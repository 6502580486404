import type { DeviceModel } from './device-model.interface';

export interface DeviceDerivedOutput {
    C_DeviceModel_key: string;
    DeviceModel: DeviceModel;
    OutputCount: number | null;
    OutputExpression: string;
    OutputName: string;
    Tag: string;
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { ClimbCommonModule } from '../common/climb-common.module';
import { ClimbServicesModule } from '../services/climb-services.module';

import { BirthService } from './birth.service';
import { BirthVocabService } from './birth-vocab.service';
import { BirthMultiselectComponent } from './birth-multiselect.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ClimbCommonModule,
        ClimbServicesModule
    ],
    declarations: [
        BirthMultiselectComponent
    ],
    exports: [
        BirthMultiselectComponent
    ],
    providers: [
        BirthService,
        BirthVocabService
    ]
})
export class BirthsSharedModule { }

<div class="detail-form-wrapper">
    <div class="detail-header border-bottom" aria-label="Import Log toolbar">
        <h3>Import</h3>

        <div class="detail-toolbar">
            <button data-automation-id="list-view-button"
                    type="button"
                    class="btn btn-secondary"
                    (click)="cancelClicked()">
                <i class="fa fa-table" aria-hidden="true"></i>
                List View
            </button>
        </div>
    </div>

    <form name="importLogForm" class="detail-form form-label-right">
        <div class="form-group row">
            <label class="col-md-2 col-form-label">Filename</label>
            <div class="col-md-10 display-detail">{{ importLog.FileName }}</div>
        </div>

        <div class="form-group row">
            <label class="col-md-2 col-form-label">Import File Definition</label>
            <div class="col-md-10 display-detail">{{ importLog.ImportFileDefinition.DefinitionName }}</div>
        </div>

        <div class="form-group row">
            <label class="col-md-2 col-form-label">Outcome</label>
            <div class="col-md-10 display-detail">{{ importLog.Result ? 'Succeeded' : 'Failed' }}</div>
        </div>

        <div class="form-group row">
            <label class="col-md-2 col-form-label">Started</label>
            <div class="col-md-10 display-detail">{{ importLog.StartDateTime | formatShortDateTime }}</div>
        </div>

        <div class="form-group row">
            <label class="col-md-2 col-form-label">Completed</label>
            <div class="col-md-10 display-detail">{{ importLog.EndDateTime | formatShortDateTime }}</div>
        </div>

        <div class="form-group row">
            <label class="col-md-2 col-form-label">Created By</label>
            <div class="col-md-10 display-detail">{{ importLog.CreatedBy | userFullName }}</div>
        </div>



        <div class="form-group row">
            <label class="col-md-2 col-form-label">Results Details</label>
            <div class="col-md-10 display-detail">
                <div class="result-detail mb-1" *ngFor="let resultDetail of resultDetailArray">
                    {{ resultDetail }}
                </div>
            </div>
        </div>
    </form>
</div>


export function isDateOverdue(date: Date): boolean {
    if (!date) {
        return false;
    }

    const inputCopy = new Date(date);
    inputCopy.setSeconds(0);
    inputCopy.setMilliseconds(0);

    const today = new Date();
    today.setSeconds(0);
    today.setMilliseconds(0);

    // ignore time component if input date is midnight
    if (inputCopy.getHours() === 0 &&
        inputCopy.getMinutes() === 0
    ) {
        today.setHours(0);
        today.setMinutes(0);
    }

    return inputCopy < today;
}

import { LocalStorageKey } from './../config';
import { Injectable } from '@angular/core';
import { LocalStorageService } from './local-storage.service';

/**
 * Get information about current environment
 */
@Injectable()
export class EnvironmentService {

    constructor(private localStorageService: LocalStorageService) {
        // nothing to do
    }

    getEnvironmentId(): any {
        return this.localStorageService.get(LocalStorageKey.ENVIRONMENT_KEY);
    }

    setEnvironmentId(environmentId: string) {
        this.localStorageService.set(LocalStorageKey.ENVIRONMENT_KEY, environmentId);
    }
}

import type { AlertDefinitionParameter } from './alert-definition-parameter.interface';
import type { AlertDevice } from './alert-device.interface';
import type { AlertSubscription } from './alert-subscription.interface';
import type { AlertTriggeredEvent } from './alert-triggered-event.interface';

export interface AlertDefinition {
    AlertDefinitionDescription: string;
    AlertDefinitionName: string;
    AlertDefinitionParameter: AlertDefinitionParameter[];
    AlertDefinitionType: string;
    AlertDevice: AlertDevice[];
    AlertSubscription: AlertSubscription[];
    AlertTriggeredEvent: AlertTriggeredEvent[];
    C_AlertDefinition_key: number;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    ModifiedBy: string;
    Version: number;
}

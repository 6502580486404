import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { ClimbCommonModule } from '../common/climb-common.module';
import { ClimbServicesModule } from '../services/climb-services.module';

import {
    UpdateTypeCellComponent
} from './report-views';
import { AuditService } from '.';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ClimbCommonModule,
        ClimbServicesModule
    ],
    declarations: [
        UpdateTypeCellComponent
    ],
    exports: [
        UpdateTypeCellComponent
    ]
})
export class AuditSharedModule { 
    static forRoot(): ModuleWithProviders<AuditSharedModule> {
        return {
          ngModule: AuditSharedModule,
          providers: [
            AuditService
          ]
        };
    }
}

import {
    CellFormatterService,
    TableOptions,
    FormatterOptions
} from '../common/datatable';
import { AuditModifiedFieldComponent } from './audit-modified-fields/audit-modified-field.component';
import { AuditClassFactory } from './audit-class-maker';

export class AuditLocationsTableOptions {

    options: TableOptions;

    constructor(
        private cellFormatterService: CellFormatterService
    ) {
        this.options = this.getDefaultOptions();
    }

    getDefaultOptions(): TableOptions {

        return {
            csvFileName: 'locations-audit.csv',
            enableDetailColumn: false,
            enableDraggable: false,
            enableSelectable: false,
            enableAuditColumns: false,
            columns: [
                {
                    displayName: 'Type',
                    field: 'UpdateType',
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Modified Fields',
                    field: 'ModifiedFields',
                    sortField: 'ModifiedFields',
                    rendererComponent: AuditModifiedFieldComponent,
                    exportFormatter: this.cellFormatterService.auditModifiedFieldsExportFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Date',
                    field: 'ModifiedDate',
                    formatter: this.cellFormatterService.dateTimeUTCFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Modified By',
                    field: 'ModifiedBy',
                    formatter: this.cellFormatterService.userNameFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Name',
                    field: 'Name',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('Name', 'Name'),
                    cellClass: AuditClassFactory.createCellClass('name'),
                },
                {
                    displayName: 'Parent Location',
                    field: 'ParentLocation',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('ParentLocation', 'ParentLocation'),
                    cellClass: AuditClassFactory.createCellClass('parentlocation'),
                },
                {
                    displayName: 'Location Type',
                    field: 'LocationType',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('LocationType', 'LocationType'),
                    cellClass: AuditClassFactory.createCellClass('locationtype'),
                },
                {
                    displayName: 'Material Type',
                    field: 'MaterialType',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('MaterialType', 'MaterialType'),
                    cellClass: AuditClassFactory.createCellClass('materialtype'),
                },                
                {
                    displayName: 'Created By',
                    field: 'CreatedBy',
                    formatter: this.cellFormatterService.userNameFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Created Date',
                    field: 'DateCreated',
                    formatter: this.cellFormatterService.dateTimeUTCFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                }
            ]
        };
    }
}

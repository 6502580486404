/*
* findElementByQuery will query the DOM for the desired element
* 	If it does not exist yet, it will try until a timeout of 10 seconds is reached.
*/
export function findElementByQuery(query: string): Promise<Element> {
    const _byQuery = () => {
        return document.querySelector(query);
    };
    return findElementsWithRetries(_byQuery, 10,
        "Failed to find element by query '" + query + "'");
}

function findElementsWithRetries(
    domQueryFunc: () => Element,
    secondsToTry: number,
    messageOnError: string
): Promise<Element> {

    const timeoutLength = 100;
    const numTimeouts = secondsToTry * 1000 / timeoutLength;

    return new Promise( (resolve, reject) => {
        function _callTimeouts(numTimeoutsLeft: number) {

            setTimeout(() => {

                const element = domQueryFunc();
                if (element) {
                    // resolve
                    resolve(element);

                } else if (numTimeoutsLeft === 0) {
                    // reject
                    reject(messageOnError + " after " + secondsToTry + " seconds");

                } else {
                    _callTimeouts(numTimeoutsLeft - 1);
                }

            }, timeoutLength);
        }

        _callTimeouts(numTimeouts);
    });
}

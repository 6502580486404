<div *ngIf="alertDefinition"
     droppable
     [dropDisabled]="facetPrivilege !== 'ReadWrite'"
     (drop)="onDropDevice($event)">

    <!-- Selected devices -->
    <div *ngIf="alertDefinition.AlertDevice">
        <table class="table table-sm table-borderless table-nonfluid">
            <tbody>
                <tr *ngFor="let item of alertDefinition.AlertDevice; let i = index;">
                    <td class="icon-cell">
                        <a *ngIf="facetPrivilege === 'ReadWrite'"
                           (click)="removeAlertDevice(item)" title="Remove device">
                            <i class="fa fa-remove remove-item"></i>
                        </a>
                    </td>
                    <td>
                        {{item.Device?.C_Device_key}}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <!-- Device typeahead -->
    <climb-indirect-typeahead *ngIf="facetPrivilege === 'ReadWrite'"
                              [search]="searchDevices"
                              [resultFormatter]="deviceIdFormatter"
                              placeholder="Device ID..."
                              (selectItem)="selectDevice($event)"></climb-indirect-typeahead>
</div>

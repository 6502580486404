import type { Job } from './job.interface';
import type { Order } from './order.interface';

export interface JobOrder {
    C_JobOrder_key: number;
    C_Job_key: number;
    C_Order_key: number;
    C_Workgroup_key: number;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    Job: Job;
    ModifiedBy: string;
    Order: Order;
    Version: number;
}

import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'climb-facet-toolbar',
    templateUrl: './facet-toolbar.component.html',
    styleUrls: ['./facet-toolbar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class FacetToolbarComponent { }

<!-- Extra Table for Groups to Assign Lines -->
<div class="form-group row">
    <label class="col-md-2 col-form-label">{{'Line' | translate}} Details  <climb-expand [(model)]="tableExpanded"
              label="{{'Line' | translate}} Details"></climb-expand></label>
    <div *ngIf="tableExpanded"
         class="col-md-10">
        <table name="groups" class="table">
            <thead>
                <tr>
                    <th style="width:6.061%">
                        Group
                    </th>
                    <th>
                        <bulk-edit-header [headerText]="translationService.translate('Line')"
                                          (updateClicked)="bulkLineChanged()">
                            <div modal-content class="text-body">
                                <select name="line"
                                        [(ngModel)]="bulk.line"
                                        [disabled]="readonly"
                                        class="form-control input-medium">
                                    <option></option>
                                    <option *ngFor="let item of job.JobLine"
                                            [value]="item.Line?.C_Line_key">
                                        {{item.Line?.LineName}}
                                    </option>
                                </select>
                                <label class="d-block mt-2 mb-0">
                                    <input type="checkbox"
                                           class="align-middle"
                                           [(ngModel)]="bulk.force"
                                           name="bulk_Force" />
                                    <span class="align-middle">
                                        Overwrite existing values
                                    </span>
                                </label>
                            </div>
                        </bulk-edit-header>
                    </th>
                    <th>
                        Species
                    </th>
                    <th>
                        <bulk-edit-header [headerText]="'Sex'"
                                          (updateClicked)="bulkSexChanged()">
                            <div modal-content class="text-body">
                                <active-vocab-select [(model)]="bulk.sex"
                                                     [vocabChoices]="sexes"
                                                     [keyFormatter]="sexKeyFormatter"
                                                     [optionFormatter]="sexFormatter"></active-vocab-select>
                                <label class="d-block mt-2 mb-0">
                                    <input type="checkbox"
                                           class="align-middle"
                                           [(ngModel)]="bulk.force"
                                           name="bulk_Force" />
                                    <span class="align-middle">
                                        Overwrite existing values
                                    </span>
                                </label>
                            </div>
                        </bulk-edit-header>
                    </th>
                    <th>
                        <bulk-edit-header [headerText]="'Min Age'"
                                          (updateClicked)="bulkMinAgeChanged()">
                            <div modal-content class="text-body">
                                <input type="number"
                                       min="0"
                                       max="9999"
                                       #name="ngModel"
                                       [(ngModel)]="bulk.minAge"
                                       [readonly]="readonly"
                                       class="form-control" />
                                <label class="d-block mt-2 mb-0">
                                    <input type="checkbox"
                                           class="align-middle"
                                           [(ngModel)]="bulk.force"
                                           name="bulk_Force" />
                                    <span class="align-middle">
                                        Overwrite existing values
                                    </span>
                                </label>
                            </div>
                        </bulk-edit-header>
                    </th>
                    <th>
                        <bulk-edit-header [headerText]="'Max Age'"
                                          (updateClicked)="bulkMaxAgeChanged()">
                            <div modal-content class="text-body">
                                <input type="number"
                                       min="0"
                                       max="9999"
                                       #name="ngModel"
                                       [(ngModel)]="bulk.maxAge"
                                       [readonly]="readonly"
                                       class="form-control" />
                                <label class="d-block mt-2 mb-0">
                                    <input type="checkbox"
                                           class="align-middle"
                                           [(ngModel)]="bulk.force"
                                           name="bulk_Force" />
                                    <span class="align-middle">
                                        Overwrite existing values
                                    </span>
                                </label>
                            </div>
                        </bulk-edit-header>
                    </th>
                    <th>
                        <bulk-edit-header [headerText]="'Origin'"
                                          (updateClicked)="bulkOriginChanged()">
                            <div modal-content class="text-body">
                                <active-vocab-select [(model)]="bulk.origin"
                                                     [vocabChoices]="materialOrigins"
                                                     [keyFormatter]="materialOriginKeyFormatter"
                                                     [optionFormatter]="materialOriginFormatter"></active-vocab-select>
                                <label class="d-block mt-2 mb-0">
                                    <input type="checkbox"
                                           class="align-middle"
                                           [(ngModel)]="bulk.force"
                                           name="bulk_Force" />
                                    <span class="align-middle">
                                        Overwrite existing values
                                    </span>
                                </label>
                            </div>
                        </bulk-edit-header>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let jobGroup of job.JobGroup | orderBy:'SortOrder';" [attr.data-key]="jobGroup.C_JobGroup_key">
                    <td>
                        <input type="text"
                               name="group"
                               [value]="jobGroup.Group"
                               readonly="readonly"
                               class="form-control" />
                    </td>
                    <td>
                        <select name="line"
                                [(ngModel)]="jobGroup.C_Line_key"
                                [disabled]="readonly"
                                class="form-control input-medium">
                            <option></option>
                            <option *ngFor="let item of job.JobLine"
                                    [value]="item.Line?.C_Line_key">
                                {{item.Line?.LineName}}
                            </option>
                        </select>
                    </td>
                    <td>
                        <input type="text" name="species"
                               [value]="jobGroup.Line?.cv_Taxon?.CommonName"
                               readonly="readonly"
                               class="form-control input-medium species-input" />
                    </td>
                    <td>
                        <active-vocab-select [(model)]="jobGroup.C_Sex_key"
                                             [vocabChoices]="sexes"
                                             [keyFormatter]="sexKeyFormatter"
                                             [optionFormatter]="sexFormatter"></active-vocab-select>
                    </td>
                    <td>
                        <input type="number" name="minAge"
                               min="0"
                               max="9999"
                               [(ngModel)]="jobGroup.MinAge"
                               [readonly]="readonly"
                               class="form-control input-medium" />
                    </td>
                    <td>
                        <input type="number" name="maxAge"
                               min="0"
                               max="9999"
                               [(ngModel)]="jobGroup.MaxAge"
                               [readonly]="readonly"
                               class="form-control input-medium" />
                    </td>
                    <td>
                        <active-vocab-select [(model)]="jobGroup.C_MaterialOrigin_key"
                                             [vocabChoices]="materialOrigins"
                                             [keyFormatter]="materialOriginKeyFormatter"
                                             [optionFormatter]="materialOriginFormatter"></active-vocab-select>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, NgZone, OnDestroy, OnInit } from '@angular/core';
import { bellFilled } from '@icons';
import { DialogService } from '@common/dialog/dialog.service';
import { Overlay } from '@angular/cdk/overlay';
import { MessageCenterPanelComponent } from '../../../messages/message-center-panel.component';
import { MixinUnsubscribeClass } from '@common/mixins';
import { MessageService } from '../../../messages/message.service';
import { DataContextService } from '@services/data-context.service';

@Component({
    selector: 'climb-message-center',
    templateUrl: 'message-center.component.html',
    styleUrls: ['message-center.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageCenterComponent extends MixinUnsubscribeClass implements OnInit, OnDestroy {
    icons = { bellFilled };
    messageCount = 0;
    getMessagesInterval: number;

    private readonly GET_MESSAGES_INTERVAL_MS = 120000;

    constructor(
        private dialogService: DialogService,
        private overlay: Overlay,
        private dataContextService: DataContextService,
        private messageService: MessageService,
        private ngZone: NgZone,
        private changeDetectorRef: ChangeDetectorRef,
        protected injector: Injector
    ) {
        super(injector);
    }

    async ngOnInit(): Promise<void> {
        super.ngOnInit();
        await this.dataContextService.init();
        this.messageService.updateNewMessageCount();
        this.subscribe(
            this.messageService.newMessageCount$.subscribe((messageCount: number) => {
                this.messageCount = messageCount;
                this.changeDetectorRef.markForCheck();
            })
        );
        this.ngZone.runOutsideAngular(() => {
            this.getMessagesInterval = window.setInterval(() => {
                this.messageService.updateNewMessageCount();
            }, this.GET_MESSAGES_INTERVAL_MS);
        });
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
        clearInterval(this.getMessagesInterval);
    }

    openMessageCenter(): void {
        // TODO: Move message center inside header module
        this.dialogService.open(MessageCenterPanelComponent, { positionStrategy: this.overlay.position().global().right('0') });
    }
}

<button climbButton
        size="sm"
        variant="ghost"
        [id]="buttonId"
        [popoverTriggerFor]="dropdownList"
        (toggle)="popoverToggle.emit($event)"
        popoverOpenWithKeyArrows
        [disabled]="disabled"
        [attr.aria-controls]="panelId"
        aria-haspopup="true">
    <svg *ngIf="icon" [climbIcon]="icon"></svg>
    {{ caption }}
    <svg [climbIcon]="(popoverToggle | async) ? icons.chevronUp : icons.chevronDown" iconPositionEnd></svg>
</button>
<climb-popover #dropdownList>
    <div class="dropdown-list"
         climbTrapFocus
         climbArrowKeyAsTab
         [id]="panelId"
         role="menu"
         [attr.aria-labelledby]="buttonId">
        <ng-content select="[climbDropdownItem], [ngTemplateOutlet]"></ng-content>
    </div>
</climb-popover>

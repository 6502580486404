import { Injectable } from '@angular/core';
import { ModalDismissReasons, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ModalDialogComponent } from './modal-dialog.component';
import { ButtonStyle, ComponentDialogOptions, DialogContent, DialogOptions } from './dialog-options.interface';
import type { ComponentType } from '@common/types';

export enum DialogCloseCodes {
    ActionClick = 'ACTION_CLICK',
    Esc = 'ESC',
    BackdropClick = 'BACKDROP_CLICK',
    Unknown = 'UNKNOWN',
}

interface DismissReason {
    code: DialogCloseCodes;
    reason?: unknown;
}

interface CloseResult<T> {
    code: DialogCloseCodes.ActionClick;
    result: T;
}

type DialogCloseResult<T> = CloseResult<T> | DismissReason;

export interface YesButtonTitle {
    yesButtonTitle?: string;
}

export interface NoButtonTitle {
    noButtonTitle?: string;
}

interface DeleteButtonTitle {
    deleteButtonTitle?: string;
}

interface CancelButtonTitle {
    cancelButtonTitle?: string;
}

export const isActionClicked = (result: DialogCloseResult<any>): result is CloseResult<any> => {
    return result.code === DialogCloseCodes.ActionClick;
};

/**
 * The service allows you to generate dialog boxes
 * with an arbitrary number of action buttons and any content.
 *
 * It is recommended to create presets of dialog windows you need
 * in services and use them in business logic.
 * See example of the `confirmYesNo(options)` of the DialogService.
 *
 * The contents of the dialog box can be specified in one of the following ways:
 * - Specify a string or several strings separated by '\n' in the `bodyText`
 *   property of the options. Each line will be output wrapped in a <p> tag
 *   in the body of the dialog box.
 * - You can also pass a html string to the `bodyHtml` parameter. This method
 *   allows you to specify any content structure in the body of the dialog box.
 *   ⚠️ **Attention!** Use this option when specifying only the previously
 *   known content of the html string, because it is not sanitized in any way!
 * - You can also pass a TemplateRef to the `bodyTemplate` parameter.
 *   In the component template, describe the content of the dialog
 *   ```
 *     <ng-template #templateRef>
 *       <div>Your template content</div>
 *     </ng-template>
 *   ```
 *   In the component use it with `@ViewChild`
 *   ```
 *     @ViewChild('templateRef') dialogTemplate: TemplateRef<any>;
 *     ...
 *     this.dialogService.open({
 *       bodyTemplate: this.dialogTemplate,
 *     });
 *   ```
 *
 * You can use all 3 approaches at the same time 😉
 *
 * Action buttons are specified using the `actions` parameter, which takes an array
 * of objects of the **DialogActionButton** type. The `value` property allows you
 * to specify the value that will be returned when the button is clicked.
 * The appearance of the button can be specified using the `style` property
 * (all styles provided by the Bootstrap are available
 * https://getbootstrap.com/docs/4.6/components/buttons/#examples).
 *
 *
 * To abstract from the **NgbModal** dependency, it is proposed to use
 * `dialogService.openComponent(SomeComponent, options)` instead of
 * `ngbModalService.open(SomeComponent, options)`. For example:
 * ```
 *   // any necessary component settings are made in a special function
 *   const customizeComponent = (component: SomeModalComponent) => {
 *     component.options = {
 *       // ...
 *     };
 *   };
 *   const result = await this.dialogService.openComponent(SomeModalComponent, { customizeComponent });
 *   console.log(result);
 * ```
 */
@Injectable()
export class DialogService {
    /**
     * NgbModalOptions:
     * @link https://ng-bootstrap.github.io/#/components/modal/api#NgbModalOptions
     */
    modalOptions: NgbModalOptions = {
        size: 'lg',
    };

    constructor(
        private modalService: NgbModal,
    ) { }

    async open<T>(options: DialogOptions<T>): Promise<DialogCloseResult<T>> {
        if (options.forceAnswer) {
            this.modalOptions.backdrop = 'static';
            this.modalOptions.keyboard = false;
        }

        const { systemOptions = {}, ...modalOptions } = options;

        const modalRef = this.modalService.open(ModalDialogComponent, {
            ...this.modalOptions,
            ...systemOptions,
        });
        const component = modalRef.componentInstance as ModalDialogComponent;
        component.options = modalOptions;

        try {
            const result: T = await modalRef.result;
            return {
                code: DialogCloseCodes.ActionClick,
                result,
            };
        } catch (reason: unknown) { // if the modal dialog was dismissed
            return this.getDismissReason(reason);
        }
    }

    async openComponent<T, C = any>(component: ComponentType<C>, options?: ComponentDialogOptions<C>): Promise<DialogCloseResult<T>> {
        const { systemOptions = {}, customizeComponent } = options;

        const modalRef = this.modalService.open(component, {
            ...this.modalOptions,
            ...systemOptions,
        });

        if (customizeComponent) {
            customizeComponent(modalRef.componentInstance);
        }

        try {
            const result: T = await modalRef.result;
            return {
                code: DialogCloseCodes.ActionClick,
                result,
            };
        } catch (reason: unknown) { // if the modal dialog was dismissed
            return this.getDismissReason(reason);
        }
    }

    private getDismissReason(reason: unknown): DismissReason {
        switch (reason) {
            case ModalDismissReasons.ESC: return { code: DialogCloseCodes.Esc };
            case ModalDismissReasons.BACKDROP_CLICK: return { code: DialogCloseCodes.BackdropClick };
            default: return {
                code: DialogCloseCodes.Unknown,
                reason,
            };
        }
    }

    async confirmYesNo(options: DialogContent & YesButtonTitle & NoButtonTitle): Promise<boolean> {
        const { yesButtonTitle, noButtonTitle, ...dialogOptions } = options;
        const action = await this.open<boolean>({
            ...dialogOptions,
            actions: [{
                title: yesButtonTitle ?? 'Yes',
                value: true,
                style: ButtonStyle.Primary,
            }, {
                title: noButtonTitle ?? 'No',
                value: false,
                style: ButtonStyle.Secondary,
            }],
        });
        return isActionClicked(action) ? action.result : false;
    }

    async confirmYes(options: DialogContent & YesButtonTitle): Promise<boolean> {
        const { yesButtonTitle, ...dialogOptions } = options;
        const action = await this.open<boolean>({
            ...dialogOptions,
            actions: [{
                title: yesButtonTitle ?? 'Yes',
                value: true,
                style: ButtonStyle.Primary,
            }],
        });
        return isActionClicked(action) ? action.result : false;
    }

    async confirmDelete(options: DialogContent & DeleteButtonTitle & CancelButtonTitle): Promise<boolean> {
        const { deleteButtonTitle, cancelButtonTitle, ...dialogOptions } = options;
        const action = await this.open<boolean>({
            ...dialogOptions,
            actions: [{
                title: deleteButtonTitle ?? 'Delete',
                value: true,
                style: ButtonStyle.Danger,
            }, {
                title: cancelButtonTitle ?? 'Cancel',
                value: false,
                style: ButtonStyle.Secondary,
            }],
        });
        return isActionClicked(action) ? action.result : false;
    }
}

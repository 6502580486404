import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ImageModalComponent } from './image-modal.component';

/*
* Service to view an image file in modal
*/
@Injectable()
export class ViewImageService {

    modalService: NgbModal;

    constructor(
        modalService: NgbModal
    ) {
        this.modalService = modalService;
    }

    openImageModal(imageFile: any) {
        const modalRef = this.modalService.open(ImageModalComponent);
        modalRef.componentInstance.imageFile = imageFile;
    }
}

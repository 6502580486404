import { Pipe, PipeTransform } from '@angular/core';

/*
* map single property of each item in array
*/
@Pipe({
    name: 'map',
    pure: false
})
export class MapPipe implements PipeTransform {

    transform<T extends Record<string, any>, K extends keyof T>(values: T[], propertyName: K): T[K][] {
        if (values) {
            return values.map((value) => {
                let mappedValue: any = '';
                if (value && propertyName in value) {
                    mappedValue = value[propertyName];
                }
                return mappedValue;
            });
        }
        return [];
    }
}

<div>
    <div class="d-flex align-content-center justify-content-between form-group pl-3 pr-3">
        <label class="align-self-center">Housing</label>
        <text-slider-button
            [(model)]="options.new"
            (modelChange)="onChange()"
            [disabled]="isHoused"
            trueText="New"
            falseText="Existing">
        </text-slider-button>
    </div>
    
    <div class="p-3">
        <create-housing #createHousing *ngIf="options.new; else existing"
            [materials]="cohortMaterials | map:'Material'"
            [(autoNaming)]='options.autoNaming'
            [disabled]="isHoused"
            (autoNamingChange)="onChange()">
        </create-housing>
        
        <ng-template #existing>
                <housing-select 
                    [(model)]="existingMaterialPoolKey" 
                    [disabled]="isHoused"
                    [excludeMatingUnits]="true">
                </housing-select>
        </ng-template>
    </div>

    <div class="d-flex align-items-center justify-content-center pb-2">
        <button type="button"
            class="btn btn-primary mr-2"
            [disabled]="isDisabled()"
            (click)="houseClicked()">
            House
        </button>
        <span *ngIf="options.new && createHousing?.unhousedMaterialCount > 0 && !isHoused"
            class="text-warning"
            title="There are unhoused animals.">
          <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
        </span>
        <span *ngIf="isHoused"
            class="text-success"
            title="Animals successfully housed.">
          <i class="fa fa-check" aria-hidden="true"></i>
        </span>
    </div>
</div>
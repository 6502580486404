import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    HostBinding,
    HostListener,
    Inject,
    Injector,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { ENTER, SPACE } from '@angular/cdk/keycodes';
import { MixinUnsubscribeClass } from '@common/mixins';
import { CLIMB_COLUMN_DEF } from '../table.token';
import { SortService } from './sort.service';
import type { ClimbColumnDef } from '../table.interface';
import type { SortDirection, ColumnSortState, SortState } from './sort.interface';


@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'button[climbSort]',
    templateUrl: './sort.component.html',
    styleUrls: ['./sort.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SortComponent extends MixinUnsubscribeClass implements OnInit {
    @Input()
    get sortDirection(): SortDirection {
        return this._sortDirection;
    }
    set sortDirection(value: SortDirection) {
        this._sortDirection = value;
        this.sort(false);
    }
    private _sortDirection: SortDirection = '';

    @Output() readonly sortChanged: EventEmitter<ColumnSortState> = new EventEmitter();

    @HostBinding('class')
    private get classes(): Record<string, boolean> {
        return {
            ['climb-sort']: true,
            ['sort-asc']: this.sortDirection === 'asc',
            ['sort-desc']: this.sortDirection === 'desc',
        };
    }

    @HostListener('click', ['$event'])
    onClick(event: MouseEvent): void {
        this.sort(event.shiftKey);
    }

    @HostListener('keydown', ['$event'])
    onKeydown(event: KeyboardEvent): void {
        if (event.keyCode === SPACE || event.keyCode === ENTER) {
            event.preventDefault();
            this.sort(event.shiftKey);
        }
    }

    isMultipleSort = false;
    sortOrder = 0;

    private columnId: string;

    constructor(
        injector: Injector,
        private cdr: ChangeDetectorRef,
        @Inject(CLIMB_COLUMN_DEF) private columnDef: ClimbColumnDef,
        private sortService: SortService,
    ) {
        super(injector);
    }

    ngOnInit(): void {
        super.ngOnInit();

        this.columnId = this.columnDef.columnId;

        this.setState(this.sortService.getState());

        this.subscription = this.sortService
            .sortState$.subscribe(this.setState.bind(this));
    }

    setState(sortState: SortState): void {
        this._sortDirection = sortState.state[this.columnId]?.direction ?? '';
        this.sortOrder = sortState.state[this.columnId]?.sortOrder ?? 0;
        this.isMultipleSort = sortState.isMultipleSort;
        this.cdr.markForCheck();
    }

    sort(isMultipleSort: boolean): void {
        if (!isMultipleSort) {
            this.sortService.clearState();
        }

        const columnSortState: ColumnSortState = {
            columnId: this.columnId,
            direction: this.getNextSortDirection(this.sortDirection),
        };

        this.sortService.addSortColumn(columnSortState);
        this.sortChanged.emit(columnSortState);
        this.sortService.emitState();
    }

    getNextSortDirection(currentSortDirection: SortDirection): SortDirection {
        switch (currentSortDirection) {
            case '': return 'asc';
            case 'asc': return 'desc';
            case 'desc': return '';
        }
    }
}

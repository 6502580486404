import {
    Component,
    Input,
    Output,
    EventEmitter,
    OnInit,
    OnChanges,
    ViewChild,
    OnDestroy,
    Renderer2,
    RendererStyleFlags2
} from '@angular/core';
import { randomId } from '../../../common/util';
import { DxColorBoxComponent } from 'devextreme-angular/ui/color-box';
import { ScrollEventService } from '@common/services';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { ScrollTypes } from '@common/export';
import { Properties } from 'devextreme/ui/popup';

@Component({
    selector: 'climb-colorpicker',
    templateUrl: './climb-colorpicker.component.html',
    styles: [`
        .colorpicker {
            max-width: 160px;
        }
    `]
})
export class ClimbColorpickerComponent implements OnInit, OnChanges, OnDestroy {
    @ViewChild('dxColorBox') dxColorBox: DxColorBoxComponent;
    @Input() model: string;
    @Input() disabled: boolean;
    @Input() popupScrollEnabled: ScrollTypes[];
    @Input() containerSelector: string;
    @Output() modelChange: EventEmitter<string> = new EventEmitter<string>();

    colorpickerId: string;
    public dropdownOptions: Properties;
    private popupElement: any;
    private subscription: Subscription;
    constructor(
        private scrollEventService: ScrollEventService,
        private renderer: Renderer2
    ) {
        // do nothing
    }

    ngOnInit() {
        this.colorpickerId = randomId() + "_cp";
        this.dropdownOptions = {
            container: this.containerSelector,
            hideOnParentScroll: false,
        };
    }

    ngOnChanges(changes: any) {
        if (changes.model) {
            // 
        }
    }

    onValueChange(value: any) {
        this.modelChange.emit(value);
    }

    private removeCloseOnScrollEvent() {
        if (!this.popupElement) {
            console.warn('Could not find DxColorBox popup ' +  'to remove closeOnTargetScroll');
            return;
        }
        this.popupElement.option('closeOnTargetScroll', () => { 
            this.popupElement.repaint();
            return true;
        });
    }

    private subscribeOnScrollEvent() {
        const elemData: {
            top: number;
            elem?: any
        } = {
            top: 0
        };
        let fromTop = 0;
        this.subscription = this.scrollEventService.scrollWorkspaceView
        .pipe(
            filter(() => this.popupElement)
        )
        .subscribe(data => {
            let scrollTop = 0;
            if (!elemData.elem) {
                elemData.elem = (this.popupElement?._$wrapper || [])[0];
            }
            for (const popupScroll of this.popupScrollEnabled) {
                if (data[popupScroll]) {
                    scrollTop += data[popupScroll]?.scroll || 0;
                }
                if (!elemData[popupScroll]) {
                    fromTop += data[popupScroll]?.scroll || 0;
                    elemData[popupScroll] = true;
                }
            }
            if (elemData.elem) {
                this.renderer.setStyle(elemData.elem, 'top', `${fromTop - scrollTop}px`, RendererStyleFlags2.Important);
            }
        }); 
    }

    public initPopup(event: any) {
        this.popupElement = event.component._popup;
        if (this.popupScrollEnabled) {
            this.subscribeOnScrollEvent();
        }
        this.removeCloseOnScrollEvent();
    }

    public onClosedPopup(event: any) {
        this.subscription?.unsubscribe();
        this.popupElement = undefined;
    }

    ngOnDestroy(): void {
        this.subscription?.unsubscribe();
    }
    
}

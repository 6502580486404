<div>
    <table class="table table-borderless">
    <thead>
        <tr>
            <th>Prefix</th>
            <th>Counter</th>
            <th>Suffix</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td>
                <input type="text"
                    [name]="'prefix'"
                    [(ngModel)]="animal.prefix"
                    class="form-control input-medium"
                    maxlength="10" />
            </td>
            <td>
                <input type="number"
                    step="any"
                    [name]="'counter'"
                    [(ngModel)]="animal.counter"
                    [required]="true"
                    class="form-control input-medium" />
            </td>
            <td>
                <input type="text"
                    [name]="'suffix'"
                    [(ngModel)]="animal.suffix"
                    class="form-control input-medium"
                    maxlength="10"/>
            </td>
        </tr>
        <tr *ngIf="animal.counter === null || animal.counter === undefined">
            <td colspan="3">
                <small class="form-text text-muted text-danger">
                    <i class="fa fa-exclamation-triangle text-danger"></i>
                    Counter must have a value to generate animal names
                </small>
            </td>
        </tr>
    </tbody>
</table>
</div>

import { Injectable } from '@angular/core';
import {
    EntityQuery,
    Predicate
} from 'breeze-client';

import { BaseEntityService } from './base-entity.service';
import { AdminManagerService } from './admin-manager.service';
import { AuthService } from './auth.service';
import { CurrentWorkgroupService } from './current-workgroup.service';
import { DataManagerService } from '../services/data-manager.service';

export interface IRoleFacet {
    Facet: {
        FacetDisplayName: string,
        FacetName: string;
        Image: string;
    };
    HasReadAccess: boolean;
    HasWriteAccess: boolean;
}

@Injectable()
export class RoleService extends BaseEntityService {

    readonly ADMINISTRATOR_ROLE = 'Administrator';

    readonly ENTITY_TYPE = 'ClimbRoles';
    readonly ENTITY_NAME = 'ClimbRole';

    constructor(
        private adminManager: AdminManagerService,
        private authService: AuthService,
        private currentWorkgroupService: CurrentWorkgroupService,
        private dataManager: DataManagerService,
    ) {
        super();
    }


    getRoles(): Promise<any[]> {
        const workgroupKey = this.currentWorkgroupService.getCurrentWorkgroupKey();
        const query = EntityQuery.from(this.ENTITY_TYPE)
            .expand('ClimbRoleFacets.Facet')
            .where('C_Workgroup_key', '==', workgroupKey)
            .orderBy('RoleName');

        return this.adminManager.returnQueryResults(query);
    }

    getUserRole(): Promise<string> {
        const workgroupKey = this.currentWorkgroupService.getCurrentWorkgroupKey();
        const pWorkgroup = Predicate.create('C_Workgroup_key', '==', workgroupKey);

        const userId = this.authService.getCurrentUserId();
        const pUser = Predicate.create('C_User_key', '==', userId);

        const pred = Predicate.and([pWorkgroup, pUser]);

        const query = EntityQuery.from('WorkgroupUsers')
            .expand("ClimbRole")
            .select('ClimbRole.RoleName')
            .where(pred);

        return this.adminManager.returnSingleQueryResult(query).then((data) => {
            if (data) {
                return data.ClimbRole.RoleName;
            } else {
                return "";
            }
        }).catch(this.adminManager.queryFailed);
    }

    isAdministrator(): Promise<boolean> {
        return this.getUserRole().then((roleName) => {
            if (!roleName) {
                return false;
            }

            return roleName.toLowerCase() === this.ADMINISTRATOR_ROLE.toLowerCase();
        });
    }

    isStudyAdministrator(): Promise<boolean | void> {
        const workgroupKey = this.currentWorkgroupService.getCurrentWorkgroupKey();
        const pWorkgroup = Predicate.create('C_Workgroup_key', '==', workgroupKey);

        const userId = this.authService.getCurrentUserId();
        const pUser = Predicate.create('C_User_key', '==', userId);

        const pred = Predicate.and([pWorkgroup, pUser]);

        const query = EntityQuery.from('WorkgroupUsers')
            .select('StudyAdministrator')
            .where(pred)
            .noTracking(true);

        return this.adminManager.returnSingleQueryResult(query).then((data) => {
            return (data && data.StudyAdministrator === true);
        }).catch(this.adminManager.queryFailed);
    }

    getCurrentUserStudyAdministratorStudies(): Promise<any> {
        const userId = this.authService.getCurrentUserId();
        const pred = Predicate.create('C_User_key', '==', userId);

        const query = EntityQuery.from('StudyAdministratorStudies')
            .where(pred)
            .noTracking(true);

        return this.dataManager.returnQueryResults(query).then((data) => {
            return data;
        }).catch(this.dataManager.queryFailed);
    }

    async getCurrentUserRoleFacets(): Promise<IRoleFacet[]> {
        const selects = [
            'HasReadAccess',
            'HasWriteAccess',
            'Facet.FacetDisplayName',
            'Facet.FacetName',
            'Facet.Image'
        ];

        const workgroupKey = this.currentWorkgroupService.getCurrentWorkgroupKey();
        const pWorkgroup = Predicate.create('ClimbRole.C_Workgroup_key', '==', workgroupKey);

        const userId = this.authService.getCurrentUserId();
        const pUser = Predicate.create(
            'ClimbRole.WorkgroupUsers', 'any',
            'C_User_key', '==', userId
        );

        const pAccess = Predicate.create('HasReadAccess', '==', true);
        const pred = Predicate.and([pWorkgroup, pUser, pAccess]);

        const query = EntityQuery.from('ClimbRoleFacets')
            .expand('Facet')
            .where(pred)
            .select(selects.join(','))
            .orderBy('SortOrder')
            .noTracking();

        await this.adminManager.init();
        return this.adminManager.returnQueryResults(query);
    }

    getUsersInRole(roleKey: number): Promise<any> {
        const pred = Predicate.create('C_ClimbRole_key', '==', roleKey);
        const query = EntityQuery.from('WorkgroupUsers')
            .expand("User")
            .where(pred);

        return this.adminManager.returnQueryResults(query);
    }

    createRole(): Promise<any> {
        // Get ordered list of facets in this workgroup
        const workgroupKey = this.currentWorkgroupService.getCurrentWorkgroupKey();
        const query = EntityQuery.from('Facets')
            .where('C_Workgroup_key', '==', workgroupKey)
            .orderBy('SortOrder');

        return this.adminManager.returnQueryResults(query).then((facets: any[]) => {
            // Create role
            const roleInitialValues = {
                RoleName: '',
                DateCreated: new Date()
            };
            const newRole = this.adminManager.createEntity(this.ENTITY_NAME, roleInitialValues);

            // Add ClimbRoleFacet to new role for each facet
            let sortOrder = 1;
            for (const facet of facets) {
                const facetInitialValues = {
                    C_Role_key: newRole.C_Role_key,
                    C_Facet_key: facet.C_Facet_key,
                    HasReadAccess: false,
                    HasWriteAccess: false,
                    SortOrder: sortOrder
                };
                const newRoleFacet = this.adminManager.createEntity(
                    'ClimbRoleFacet',
                    facetInitialValues
                );
                newRoleFacet.Facet = facet;

                newRole.ClimbRoleFacets.push(newRoleFacet);

                sortOrder++;
            }

            return newRole;
        }).catch(this.adminManager.queryFailed);
    }

    deleteRole(role: any) {
        while (role.ClimbRoleFacets.length > 0) {
            const roleFacet = role.ClimbRoleFacets[0];
            this.adminManager.deleteEntity(roleFacet);
        }

        this.adminManager.deleteEntity(role);
    }

    getFacetPrivilegeByRole(facetName: string, role: any): Promise<any> {
        const expands = [
            'Facet',
            'ClimbRole'
        ];

        const workgroupKey = this.currentWorkgroupService.getCurrentWorkgroupKey();
        const p1 = Predicate.create('ClimbRole.C_Workgroup_key', '==', workgroupKey);
        const p2 = Predicate.create('ClimbRole.RoleName', '==', role);
        const p3 = Predicate.create('Facet.FacetName', '==', facetName);
        const pred = Predicate.and([p1, p2, p3]);

        const query = EntityQuery.from('ClimbRoleFacets')
            .expand(expands.join(','))
            .where(pred)
            .orderBy('SortOrder');

        return this.adminManager.returnSingleQueryResult(query);
    }
}

import type { EnumerationItem } from './enumeration-item.interface';
import type { Input } from './input.interface';
import type { JobCharacteristic } from './job-characteristic.interface';
import type { MatingCharacteristic } from './mating-characteristic.interface';
import type { Output } from './output.interface';
import type { SampleCharacteristic } from './sample-characteristic.interface';
import type { StudyCharacteristic } from './study-characteristic.interface';
import type { TaxonCharacteristic } from './taxon-characteristic.interface';

export interface EnumerationClass {
    C_EnumerationClass_key: number;
    C_Workgroup_key: number;
    ClassName: string;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    Description: string;
    EnumerationItem: EnumerationItem[];
    Input: Input[];
    IsActive: boolean;
    JobCharacteristic: JobCharacteristic[];
    MatingCharacteristic: MatingCharacteristic[];
    ModifiedBy: string;
    Output: Output[];
    ReadOnly: boolean;
    SampleCharacteristic: SampleCharacteristic[];
    SortOrder: number;
    StudyCharacteristic: StudyCharacteristic[];
    TaxonCharacteristic: TaxonCharacteristic[];
    Version: number;
}

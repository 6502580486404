/**
 * Limited Typing for our usage of expireCache
 */
export interface ExpireCache {
    // get value by key
    get: (key?: string | number) => any;
    // set value by key, optional expireSeconds
    set: (key: string | number, value: any, expireSeconds?: number) => void;
    // remove a key value pair. 
    //   When called without a key, this clears all data in the cache
    remove: (key?: string | number) => void;
    // get a sub cache by namespace, optional expireSeconds
    namespace: (namespace: string, expireSeconds?: number) => ExpireCache;
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import * as _expireCache from 'expire-cache';
export const expireCache: ExpireCache = _expireCache;

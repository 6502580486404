import {
    Component
} from "@angular/core";
import { ICellRendererParams } from "ag-grid-community";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { AgGridCommService } from '../services/ag-grid-comm.service';
import { cross } from '@icons';

@Component({
    selector: 'cell-delete-link',
    template: `
        <a climbButton
           variant="ghost-negative"
           size="sm"
           title="Delete item"
           (click)="deleteClicked()">
            <svg [climbIcon]="icons.cross"></svg>
        </a>
    `,
    styleUrls: ['./cell-delete-link.component.scss'],
})
export class CellDeleteLinkComponent implements ICellRendererAngularComp {
    public icons = { cross };
    public params: ICellRendererParams;

    constructor(
        private agGridCommService: AgGridCommService
    ) { }

    agInit(params: ICellRendererParams): void {
        this.params = params;
    }

    /**
     * Required by ICellRenderedAngularComp.
     *   Called on cell refresh by ag-grid
     * @param params
     */
    refresh(params: any): boolean {
        this.params = params;
        return true;
    }

    deleteClicked() {
        // pipe event to parent data-table component
        this.agGridCommService.deleteItemClicked(this.params.data);
    }
}

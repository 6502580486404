import * as platform from 'platform';

export class BrowserDetection {

    static isIE(): boolean {
        if (!platform.name) {
            return false;
        }
        return platform.name === 'IE';
    }

}

import type { TaskInstance } from './task-instance.interface';

export interface cv_TaskStatus {
    C_TaskStatus_key: number;
    C_VocabularySource_key: number | null;
    C_Workgroup_key: number;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    ElementID: string;
    IsActive: boolean;
    IsDefault: boolean;
    IsDefaultAutoEndState: boolean;
    IsDefaultEndState: boolean;
    IsDeprecated: boolean;
    IsEndState: boolean;
    ModifiedBy: string;
    SortOrder: number | null;
    TaskInstance: TaskInstance[];
    TaskStatus: string;
    Version: number;
}

import {
  Component,
  Input,
  TemplateRef,
  ViewChild,
  ChangeDetectionStrategy
} from '@angular/core';
import { Popover } from './popover.interface';
import { Subject } from 'rxjs';
import { transformPanel } from './popover.animation';

@Component({
  selector: 'climb-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [transformPanel],
})
export class PopoverComponent implements Popover {
    @ViewChild(TemplateRef) templateRef: TemplateRef<HTMLElement>;

    @Input() closeOnClick = true;

    private closeSource = new Subject<void>();
    close$ = this.closeSource.asObservable();

    close(): void {
        this.closeSource.next();
    }

    onClick(event: MouseEvent): void {
        if (this.canClose(event.target as HTMLElement)) {
            this.close();
        }
    }

    private canClose(element: HTMLElement): boolean {
        if (!this.closeOnClick) {
            return false;
        }
        return element.matches(
            'button, a, input[type="radio"], [role="button"], [role="menuitem"], [role="menuitemradio"], [role="radio"], [role="option"]',
        );
    }
}

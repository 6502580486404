import { Pipe, PipeTransform } from '@angular/core';
import { TaxonCharacteristicInstance } from '../types';

/**
 * Gets a characteristic instance from a list of TaxonCharacteristicInstance
 */
@Pipe({
    name: 'getTaxonCharacteristicInstance'
})
export class GetTaxonCharacteristicInstancePipe implements PipeTransform {
    transform(value: TaxonCharacteristicInstance[], characteristicName: string): TaxonCharacteristicInstance[] {
        if (!value || value.length === 0) {
            return null;
        }
        const targetInstance = value.filter((tci: TaxonCharacteristicInstance) => tci.CharacteristicName === characteristicName);

        if (!targetInstance) {
            return null;
        }

        return targetInstance;
    }
}

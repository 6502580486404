import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';

import { notEmpty } from '../util';
import { BinaryToggleComponent } from './binary-toggle-component.interface';

@Component({
    selector: 'multi-control-wrap',
    template: `
        <div *ngIf="!toggled">
            <ng-content select="multi-control[name='single']"></ng-content>
        </div>
        <div *ngIf="toggled">
            <ng-content select="multi-control[name='many']"></ng-content>
        </div>
    `
})
export class MultiControlWrapComponent implements OnInit {
    @Input() toggleComponent: BinaryToggleComponent;
    // used for single value input
    @Input() singleModel: any;
    @Output() singleModelChange: EventEmitter<any> = new EventEmitter<any>();
    // used for many value input
    @Input() manyModel: any[];
    @Output() manyModelChange: EventEmitter<any[]> = new EventEmitter<any[]>();

    // state variables
    toggled: boolean;

    ngOnInit() {
        this.initializeState();
        this.subscribeStateChanges();
    }

    initializeState() {
        if (notEmpty(this.manyModel)) {
            // Override the toggleComponent state if the many-model is populated
            this.toggled = true;
            this.toggleComponent.setState(true);
            this.toggledMany();
        } else {
            this.toggled = this.toggleComponent.getState();
        }
    }

    subscribeStateChanges() {
        this.toggleComponent.stateChanged$.subscribe((on) => {
            this.toggled = on;
            this.stateChanged();
        });
    }

    stateChanged() {
        if (this.toggled) {
            this.toggledMany();
        } else {
            this.toggledSingle();
        }
    }

    toggledSingle() {
        if (this.manyModel && this.manyModel.length === 1) {
            this.singleModel = this.manyModel[0];
            this.singleModelChange.emit(this.singleModel);
        }
        this.manyModel = [];
        this.manyModelChange.emit(this.manyModel);
    }

    toggledMany() {
        if (this.singleModel) {
            this.manyModel = [this.singleModel];
            this.manyModelChange.emit(this.manyModel);
        }
        this.singleModel = null;
        this.singleModelChange.emit(this.singleModel);
    }
}


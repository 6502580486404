export const AVAILABLE_FUNCTIONS: CalculatedFunction[] = [
    {
        name: 'max',
        calcFunction: Math.max
    },
    {
        name: 'min',
        calcFunction: Math.min
    }
];

export interface CalculatedFunction {
    name: string;
    calcFunction: (...values: number[]) => number;
}

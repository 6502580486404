<div class="detail-form-wrapper">

    <div class="detail-header">
        <h3>Construct</h3>

        <pager-toolbar [itemName]="'construct'"
                       [logTag]="COMPONENT_LOG_TAG"
                       [first]="pageState.isFirst"
                       [last]="pageState.isLast"
                       (previous)="previousClicked($event)"
                       (next)="nextClicked($event)"></pager-toolbar>

        <detail-toolbar [facetPrivilege]="facet.Privilege"
                        [logTag]="COMPONENT_LOG_TAG"
                        (exit)="exitClicked($event)"></detail-toolbar>
    </div>

    <div *ngIf="construct | isDeleted"
         class="alert alert-warning"
         role="alert">
        <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
        This construct has been deleted.
    </div>

    <form *ngIf="!(construct | isDeleted)"
          name="constructForm" id="constructForm"
          class="detail-form"
          #constructForm="ngForm">
        <fieldset [disabled]="facet.Privilege === 'ReadOnly'">

            <div class="form-group row">
                <label class="col-md-3 col-form-label">Active</label>
                <div class="col-md-9">
                    <input type="checkbox"
                           name="isActive"
                           class="mt-1"
                           [(ngModel)]="construct.IsActive" />
                </div>
            </div>

            <div class="form-group row">
                <label class="col-md-3 col-form-label">ID</label>
                <div class="col-md-9">
                    <input type="text"
                           name="constructID"
                           [(ngModel)]="construct.ConstructID"
                           class="form-control input-medium" />
                </div>
            </div>

            <div class="form-group row">
                <label class="col-md-3 col-form-label">Name</label>
                <div class="col-md-9">
                    <input type="text"
                           name="fullName"
                           [(ngModel)]="construct.FullName"
                           class="form-control input-medium" />
                </div>
            </div>

            <div class="form-group row">
                <label class="col-md-3 col-form-label">Alternate Name</label>
                <div class="col-md-9">
                    <input type="text"
                           name="alternateName"
                           [(ngModel)]="construct.AlternateName"
                           class="form-control input-medium" />
                </div>
            </div>

            <div class="form-group row">
                <label class="col-md-3 col-form-label">Antibiotic Selection</label>
                <div class="col-md-9">
                    <input type="text"
                           name="antibioticSelection"
                           [(ngModel)]="construct.AntibioticSelection"
                           class="form-control input-medium" />
                </div>
            </div>

            <div class="form-group row">
                <label class="col-md-3 col-form-label">Antibody</label>
                <div class="col-md-9">
                    <input type="text"
                           name="antibody"
                           [(ngModel)]="construct.Antibody"
                           class="form-control input-medium" />
                </div>
            </div>

             <div class="form-group row">
                <label class="col-md-3 col-form-label">Promoter</label>
                <div class="col-md-9">
                    <input type="text"
                           name="promotor"
                           [(ngModel)]="construct.Promoter"
                           class="form-control input-medium" />
                </div>
            </div>

            <div class="form-group row">
                <label class="col-md-3 col-form-label">Vector</label>
                <div class="col-md-9">
                    <input type="text"
                           name="vector"
                           [(ngModel)]="construct.Vector"
                           class="form-control input-medium" />
                </div>
            </div>

            <div class="form-group row">
                <label class="col-md-3 col-form-label">Vector Size (bp)</label>
                <div class="col-md-4">
                    <input type="number"
                           min="0"
                           step="any"
                           name="vectorSize"
                           [(ngModel)]="construct.VectorSize"
                           class="form-control input-medium" />
                </div>
            </div>

            <div class="form-group row">
                <label class="col-md-3 col-form-label">Insert Size (bp)</label>
                <div class="col-md-4">
                    <input type="number"
                           min="0"
                           step="any"
                           name="insertSize"
                           [(ngModel)]="construct.InsertSize"
                           class="form-control input-medium" />
                </div>
            </div>

            <div class="form-group row">
                <label class="col-md-3 col-form-label">Insert Name</label>
                <div class="col-md-9">
                    <input type="text"
                           name="insertName"
                           [(ngModel)]="construct.InsertName"
                           class="form-control input-medium" />
                </div>
            </div>

            <div class="form-group row">
                <label class="col-md-3 col-form-label">Total Size (bp)</label>
                <div class="col-md-4">
                    <input type="number"
                           min="0"
                           step="any"
                           name="totalSize"
                           [(ngModel)]="construct.TotalSize"
                           class="form-control input-medium" />
                </div>
            </div>

            <div class="form-group row">
                <label class="col-md-3 col-form-label">Transgene Excision</label>
                <div class="col-md-9">
                    <input type="text"
                           name="transgeneRestrictionEnzymes"
                           [(ngModel)]="construct.TransgeneRestrictionEnzymes"
                           class="form-control input-medium" />
                </div>
            </div>

            <div class="form-group row">
                <label class="col-md-3 col-form-label">Probe</label>
                <div class="col-md-9">
                    <input type="text"
                           name="probe"
                           [(ngModel)]="construct.Probe"
                           class="form-control input-medium" />
                </div>
            </div>

            <div class="form-group row">
                <label class="col-md-3 col-form-label">Cloning Details</label>
                <div class="col-md-9">
                    <textarea name="cloningDetails"
                              [(ngModel)]="construct.CloningDetails"
                              maxlength="1000"
                              rows="2"
                              class="form-control"></textarea>
                </div>
            </div>

            <div class="form-group row">
                <label class="col-md-3 col-form-label">Source</label>
                <div class="col-md-9">
                    <input type="text"
                           name="source"
                           [(ngModel)]="construct.Source"
                           class="form-control input-medium" />
                </div>
            </div>

             <div class="form-group row">
                <label class="col-md-3 col-form-label">Sequence Availability</label>
                <div class="col-md-9">
                    <input type="text"
                           name="sequenceAvailability"
                           [(ngModel)]="construct.SequenceAvailability"
                           class="form-control input-medium" />
                </div>
            </div>

            <div class="form-group row">
                <label class="col-md-3 col-form-label">Comments</label>
                <div class="col-md-9">
                    <textarea name="comments"
                              [(ngModel)]="construct.Comments"
                              maxlength="1000"
                              rows="2"
                              class="form-control"></textarea>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-md-3 col-form-label">{{'Lines' | translate}}</label>
                <div class="col-md-9">
                    <div class="form-control-plaintext">
                        <ul class="list-unstyled" *ngIf="lines | notEmpty">
                            <li *ngFor="let line of lines">{{line.LineName}}</li>
                        </ul>
                        <span *ngIf="!(lines | notEmpty)">None.</span>
                    </div>
                </div>
            </div>

            <div class="form-group row">
                <label class="col-md-3 col-form-label">Files</label>
                <div class="col-md-9">
                    <div class="form-control-plaintext">
                        <facet-detail-file-upload [pkName]="'C_Construct_key'"
                                                  [pkValue]="construct.C_Construct_key"
                                                  [facetPrivilege]="facet.Privilege"></facet-detail-file-upload>
                    </div>
                </div>
            </div>

        </fieldset>
    </form>
</div>

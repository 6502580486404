import {
    CellFormatterService,
    TableOptions,
    FormatterOptions,
} from '../common/datatable';
import { TranslationService } from '../services/translation.service';
import { AuditModifiedFieldComponent } from './audit-modified-fields/audit-modified-field.component';
import { AuditClassFactory } from './audit-class-maker';

export class AuditJobAnimalsTableOptions {

    options: TableOptions;

    constructor(
        private cellFormatterService: CellFormatterService,
        private translationService: TranslationService,
        private isGLP: boolean,
    ) {
        this.options = this.getDefaultOptions();
        if (this.isGLP) {
            const dateInColumn = {
                displayName: 'Date In',
                field: 'DateIn',
                formatter: this.cellFormatterService.dateTimeUTCFormatter,
                cellClass: AuditClassFactory.createDeletedClass,
            };
            const dateOutColumn = {
                displayName: 'Date Out',
                field: 'DateOut',
                formatter: this.cellFormatterService.createAuditColumnFormatter('DateOut', 'DateOut', this.cellFormatterService.dateTimeUTCFormatter),
                cellClass: AuditClassFactory.createCellClass('dateout'),
            };
            this.options.columns.splice(-3, 0, dateInColumn);
            this.options.columns.splice(-2, 0, dateOutColumn);
        }
    }

    getDefaultOptions(): TableOptions {

        return {
            csvFileName:
            this.translationService.translate('job').toLowerCase() + '-animals-audit.csv',
            enableDetailColumn: false,
            enableDraggable: false,
            enableSelectable: false,
            enableAuditColumns: false,
            columns: [
                {
                    displayName: 'Type',
                    field: 'UpdateType',
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Modified Fields',
                    field: 'ModifiedFields',
                    sortField: 'ModifiedFields',
                    rendererComponent: AuditModifiedFieldComponent,
                    exportFormatter: this.cellFormatterService.auditModifiedFieldsExportFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Date',
                    field: 'ModifiedDate',
                    formatter: this.cellFormatterService.dateTimeUTCFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Modified By',
                    field: 'ModifiedBy',
                    formatter: this.cellFormatterService.userNameFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: this.translationService.translate('Job') + ' Name',
                    field: 'JobName',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('JobName', (this.translationService.translate('Job') + 'Name')),
                    cellClass: AuditClassFactory.createCellClass((this.translationService.translate('Job') + 'Name').toLowerCase()),
                },
                {
                    displayName: 'Animal ID',
                    field: 'Identifier',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('Identifier', 'Identifier'),
                    cellClass: AuditClassFactory.createCellClass('identifier'),
                },
                {
                    displayName: 'Animal Name',
                    field: 'Name',
                    visible: false,
                    formatter: this.cellFormatterService.createAuditColumnFormatter('Name', 'Name'),
                    cellClass: AuditClassFactory.createCellClass('name'),
                },
                {
                    displayName: 'Sex',
                    field: 'Sex',
                    visible: false,
                    formatter: this.cellFormatterService.createAuditColumnFormatter('Sex', 'Sex'),
                    cellClass: AuditClassFactory.createCellClass('sex'),
                },
                {
                    displayName: 'Birth Date',
                    field: 'BirthDate',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('BirthDate', 'BirthDate', this.cellFormatterService.dateFormatter),
                    cellClass: AuditClassFactory.createCellClass('birthdate'),
                },
                {
                    displayName: this.translationService.translate('Line'),
                    field: 'Line',
                    visible: false,
                    formatter: this.cellFormatterService.createAuditColumnFormatter('Line', this.translationService.translate('Line')),
                    cellClass: AuditClassFactory.createCellClass(this.translationService.translate('Line').toLowerCase()),
                },
                {
                    displayName: this.translationService.translate('Line') + ' Short Name',
                    field: 'LineShortName',
                    visible: false,
                    formatter: this.cellFormatterService.createAuditColumnFormatter('LineShortName', this.translationService.translate('Line') + 'ShortName'),
                    cellClass: AuditClassFactory.createCellClass((this.translationService.translate('Line') + 'ShortName').toLowerCase()),
                },
                {
                    displayName: 'Sequence',
                    field: 'Sequence',
                    visible: false,
                    formatter: this.cellFormatterService.createAuditColumnFormatter('Sequence', 'Sequence'),
                    cellClass: AuditClassFactory.createCellClass('sequence'),
                },
                {
                    displayName: 'Created By',
                    field: 'CreatedBy',
                    formatter: this.cellFormatterService.userNameFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Created Date',
                    field: 'DateCreated',
                    formatter: this.cellFormatterService.dateTimeUTCFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                }
            ]
        };
    }
}

import { Pipe, PipeTransform } from '@angular/core';

import {
    getSafeProp,
    notEmpty
} from '../util';

interface PoolMaterial {
    DateOut: Date | null;
    Material: {
        Animal: {
            cv_Sex: {
                Sex: 'Female' | 'Male' | 'Undetermined' | null
            };
        };
    };
}

/**
 * Returns non-males/females from a list of MaterialPoolMaterial.Material.Animals
 *   who are currently in the MaterialPool.
 */
@Pipe({
    name: 'housingOtherSexes',
    pure: false
})
export class HousingOtherSexesPipe implements PipeTransform {

    readonly TARGET_PROPERTY = 'Material.Animal.cv_Sex.Sex';
    readonly EXCLUDED_TARGET_VALUES = ['Female', 'Male'];

    transform<T extends PoolMaterial>(items: T[]): T[] {
        let filtered: T[] = [];

        if (notEmpty(items)) {
            filtered = items.filter((materialPoolMaterial) => {
                // Exclude if no Material or Animal, or if it has a DateOut
                if (!materialPoolMaterial.Material ||
                    !materialPoolMaterial.Material.Animal ||
                    materialPoolMaterial.DateOut
                ) {
                    return false;
                }

                // Include only if Sex is not Female/Male
                const propValue = getSafeProp<string>(materialPoolMaterial, this.TARGET_PROPERTY);
                return this.EXCLUDED_TARGET_VALUES.indexOf(propValue) === -1;
            });
        }

        return filtered;
    }
}

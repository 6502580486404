<div class="table-bordered d-flex justify-content-between align-items-center p-1">
    <div>
        <span class="font-weight-bold">{{tableTitle}}</span> 
        <span> {{entities.length}}</span>
    </div>
    <climb-column-select 
        [columnLabels]="entityTableService.columnSelect" 
        (onChange)="onColumnSelectChange($event)">
    </climb-column-select>
</div>
<table class="table table-bordered mb-0 h-100">
    <thead>
        <tr class="detail-grid-header">
            <th class="icon-cell">
                <input type="checkbox"
                    name="selectAll"
                    title="Select/Clear all" 
                    [(ngModel)]="entityTableService.allSelected"
                    (click)="entityTableService.selectAllEntities()" />
            </th>

            <th class="text-nowrap">
                <a class="detail-grid-button" (click)="onCopyClicked()">
                    <i class="fa fa-copy" title="Copy selected {{tableTitle}}"></i>
                </a>  
            </th>

            <ng-container *ngFor="let column of columns">
                <th *ngIf="column.visible" [style]="column.headerTemplate && 'padding: 0 !important'">
                    <ng-template
                        *ngIf="column.headerTemplate; else defaultHeader" 
                        [ngTemplateOutlet]="column.headerTemplate"
                        [ngTemplateOutletContext]="{$implicit: defaultHeader, item: entities[0]}">
                    </ng-template>
                    <ng-template #defaultHeader class="p-1">
                        <a (click)="entityTableService.sortColumn(column.field, $event)"
                            class="header-sort-link"
                            title="Sort by {{column.label}}">
                            {{column.label}} {{ entityTableService.tableSort.nested ? entityTableService.tableSort.nestedIndex[column.field] : '' }}
                            <i *ngIf="entityTableService.tableSort.propertyPath === column.field 
                                || entityTableService.tableSort.nested 
                                && entityTableService.tableSort.properties.includes(column.field)"
                                class="fa"
                                [ngClass]="column.field && entityTableService.getSortCaretClass(column.field)"
                                style="font-size: 10px !important;">
                            </i>
                        </a>
                    </ng-template>
                </th>
            </ng-container>
            
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let entity of entities; let i = index"
            [ngClass]="{'selected-row': entity.isSelected}">
            <td class="icon-cell">
                <input type="checkbox"
                    name="select-entity-{{i}}"
                    [(ngModel)]="entity.isSelected"
                    (click)="entityTableService.selectEntity(i, $event)" />
            </td>
            
            <td class="icon-cell">
                <a (click)='onRemoveClicked(entity)'>
                    <i class="fa fa-remove remove-item" title="Remove {{tableTitle}}"></i>
                </a>
            </td>

            <ng-container *ngFor="let column of columns">
                <td *ngIf="column.visible">
                    <ng-template *ngIf="column.rowTemplate; else defaultRow" 
                        [ngTemplateOutlet]="column.rowTemplate"
                        [ngTemplateOutletContext]="{ item: entity }">
                    </ng-template>
                    <ng-template #defaultRow>
                        {{entity | getPropertyValue: column.field}}
                    </ng-template>
                </td>
            </ng-container>

        </tr>
    </tbody>
</table>
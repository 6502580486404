<div class="detail-form-wrapper">
    <div>
        <a *ngIf="!readonly" (click)="removeSiteClicked()">
            <i class="fa fa-fw fa-remove remove-item"
                title="Delete Site"></i></a>

        <h4 class="site-sub-heading"
            (click)="toggleSiteShown()">{{ site.Name }}</h4>
        <a (click)="toggleSiteShown()" class="toggle-site-link">
            <i class="fa fa-fw"
                [ngClass]="siteShown ? 'fa-chevron-up' : 'fa-chevron-down'"
                [title]="siteShown ? 'Hide Site' : 'Show Site'"
            aria-hidden="true"></i>
        </a>
    </div>
    <form *ngIf="siteShown" name="InstitutionForm" id="institutionForm"
          class="site-block">
        <div class="form-group row">
            <label class="col-md-2 col-form-label">Name</label>
            <div class="col-md-10">
                <input type="text"
                       name="Name"
                       #name="ngModel"
                       [(ngModel)]="site.Name"
                       maxlength="200"
                       class="form-control input-medium" />
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-2 col-form-label">Identifier</label>
            <div class="col-md-10">
                <input type="text"
                       name="Identifier"
                       #name="ngModel"
                       [(ngModel)]="site.Identifier"
                       maxlength="200"
                       class="form-control input-medium" />
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-2 col-form-label">Email Address</label>
            <div class="col-md-10">
                <input type="text"
                       name="EmailAddress"
                       #name="ngModel"
                       [(ngModel)]="site.EmailAddress"
                       maxlength="200"
                       class="form-control input-medium" />
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-2 col-form-label">Address</label>
            <div class="col-md-10">
                <input type="text"
                       name="Address"
                       #name="ngModel"
                       [(ngModel)]="site.Address"
                       maxlength="200"
                       class="form-control input-medium" />
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-2 col-form-label">Address 2</label>
            <div class="col-md-10">
                <input type="text"
                       name="AddressQualifier"
                       #name="ngModel"
                       [(ngModel)]="site.AddressQualifier"
                       maxlength="200"
                       class="form-control input-medium" />
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-2 col-form-label">City</label>
            <div class="col-md-10">
                <input type="text"
                       name="City"
                       #name="ngModel"
                       [(ngModel)]="site.City"
                       maxlength="200"
                       class="form-control input-medium" />
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-2 col-form-label">Country</label>
            <div class="col-md-10">
                <active-vocab-select [(model)]="site.C_Country_key"
                                     (modelChange)="countryChanged()"
                                     [vocabChoices]="countries"
                                     [keyFormatter]="siteCountryKeyFormatter"
                                     [optionFormatter]="siteCountryFormatter"
                                     [nullable]="true"></active-vocab-select>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-2 col-form-label">State</label>
            <div class="col-md-10">
                <active-vocab-select [(model)]="site.C_State_key"
                                     [vocabChoices]="filteredStates"
                                     [keyFormatter]="siteStateKeyFormatter"
                                     [optionFormatter]="siteStateFormatter"
                                     [nullable]="true"></active-vocab-select>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-2 col-form-label">Zip Code</label>
            <div class="col-md-10">
                <input type="text"
                       name="PotalCode"
                       #name="ngModel"
                       [(ngModel)]="site.PostalCode"
                       class="form-control input-medium" />
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-2 col-form-label">GPS Coordinates</label>
            <div class="col-md-10">
                <input type="text"
                       name="gps"
                       #name="ngModel"
                       [(ngModel)]="site.GPSCoodinates"
                       class="form-control input-medium" />
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-2 col-form-label">Phone Number</label>
            <div class="col-md-10">
                <input type="text"
                       name="Phone"
                       #name="ngModel"
                       [(ngModel)]="site.Phone"
                       class="form-control input-medium" />
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-2 col-form-label">Mobile Number</label>
            <div class="col-md-10">
                <input type="text"
                       name="MobilePhone"
                       #name="ngModel"
                       [(ngModel)]="site.MobilePhone"
                       class="form-control input-medium" />
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-2 col-form-label">Type</label>
            <div class="col-md-10">
                <active-vocab-select [(model)]="site.C_SiteType_key"
                                     [vocabChoices]="siteTypes"
                                     [keyFormatter]="siteTypeKeyFormatter"
                                     [optionFormatter]="siteTypeFormatter"
                                     [nullable]="true"></active-vocab-select>
            </div>
        </div>

        <div class="container-fluid px-0">
            <contacts-table [entity]="site"
                            [pkName]="'C_Site_key'"
                            [pkValue]="site.C_Site_key"></contacts-table>
        </div>

    </form>
</div>

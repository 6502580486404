import { Injectable } from '@angular/core';
import { defer, Observable } from 'rxjs';

import { LineService } from './line.service';
import { VocabularyService } from '../vocabularies/vocabulary.service';
import { take } from 'rxjs/operators';

@Injectable()
export class LineVocabService {

    public get constructs$(): Observable<any[]>{
        return defer(() => this.lineService.getActiveConstructs()).pipe(take(1));
    }
    public get lineStatuses$(): Observable<any[]>{
        return defer(() => this.vocabularyService.getCV('cv_LineStatuses')).pipe(take(1));
    }
    public get lineTypes$(): Observable<any[]>{
        return defer(() => this.vocabularyService.getCV('cv_LineTypes')).pipe(take(1));
    }
    public get taxons$(): Observable<any[]>{
        return defer(() => this.vocabularyService.getCV('cv_Taxons')).pipe(take(1));
    }


    constructor(
        private lineService: LineService,
        private vocabularyService: VocabularyService
    ) {}
}

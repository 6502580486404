const ELLIPSIS = '\u2026';

import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';
import { TaskType } from '../../tasks/models';
import { ProtocolService } from '../protocol.service';

@Component({
    selector: 'protocol-select',
    template: `
    <div class="protocol-select">
        <climb-typeahead
            [(model)]="model"
            [resultFormatter]="nameFormatter"
            [keyFormatter]="keyFormatter"
            [search]="searchItems"
            [keySearch]="searchItemsByKey" 
            [id]="id"
            [placeholder]="placeholder"
            [required]="required"
            [namespace]="'protocol'"
            [disabled]="disabled"
            (modelChange)="modelChangeHandler($event)"
            #typeahead
        ></climb-typeahead>
    </div>
    `,
    styles: [`
        .protocol-select {
            min-width: 300px;
        }
    `]
})
export class ProtocolSelectComponent implements OnInit {
    @Input() model: any;
    // Optional cv_TaskType.TaskType by which to filter the task list
    @Input() taskType: TaskType;
    @Input() id: string;
    @Input() placeholder: string = 'Protocol name' + ELLIPSIS;
    @Input() showActiveOnly = true;
    @Input() required: boolean;
    @Input() disabled = false;
    @Input() filterKeys: number[] = [];

    @Output() modelChange: EventEmitter<any> = new EventEmitter<any>();

    readonly PAGE_SIZE_DEFAULT: number = 50;

    constructor(
        private protocolService: ProtocolService
    ) {
        // nothing to do
    }

    ngOnInit() {
        this._initValues();
    }

    private _initValues() {
        // Default showActiveOnly to true
        if (this.showActiveOnly !== false) {
            this.showActiveOnly = true;
        }
    }

    modelChangeHandler(event: any) {
        this.modelChange.emit(this.model);
    }

    searchItems = (searchTerm: string): Promise<any[]> => {
        const filter: any = {
            ProtocolName: searchTerm,
        };
        if (this.taskType) {
            filter.TaskType = this.taskType;
        }
        if (this.filterKeys.length > 0) {
            filter.C_Protocol_keys = this.filterKeys;
        }
        this._setFilterForActive(filter);

        return this._searchItems(filter, this.PAGE_SIZE_DEFAULT);
    }

    searchItemsByKey = (key: any): Promise<any[]> => {
        const filter: any = {
            C_Protocol_key: key,
        };
        if (this.taskType) {
            filter.TaskType = this.taskType;
        }
        this._setFilterForActive(filter);

        const pageSize = 1;

        return this._searchItems(filter, pageSize);
    }

    _searchItems(filter: any, pageSize: number): Promise<any[]> {
        const pageNumber = 1;
        const sort = 'ProtocolName';

        return this.protocolService.searchProtocols({
            page: pageNumber,
            size: pageSize,
            sort,
            filter
        });
    }

    private _setFilterForActive(filter: any) {
        if (this.showActiveOnly) {
            filter.IsActive = true;
        }
    }

    nameFormatter = (item: any) => {
        return item.ProtocolName;
    }

    keyFormatter = (item: any) => {
        return item.C_Protocol_key;
    }
}

<div class="row chart-settings-container">
    <div class="col-md-8">
        <form>
            <chart-filter-group-creator class="filter-group-creator" [chartFilterTypes]="filterTypes"
                                        [(model)]="filterGroups"></chart-filter-group-creator>
        </form>
    </div><!-- /.col -->

    <div class="col-md-4">
        <h4 class="mt-1">Chart Options</h4>

        <form class="form-inline align-items-center">
            <h5 class="mb-1 mr-1">Range</h5>
            <div class="form-group mb-1 mr-1">
                <label class="sr-only">Maximum Range</label>
                <select class="form-control"
                        id="intervalCountSelect-{{domIdAddition}}"
                        name="intervalCount"
                        required
                        [(ngModel)]="intervalCount">
                    <option *ngFor="let item of intervalCounts" value="{{item}}">
                        {{item}}
                    </option>
                </select>
            </div>
            <div class="form-group mb-1 mr-1">
                <label class="sr-only">Range Type </label>
                <select class="form-control"
                        id="intervalSelect-{{domIdAddition}}"
                        name="interval"
                        required
                        [(ngModel)]="interval">
                    <option *ngFor="let item of intervals" value="{{item}}">
                        {{item}}
                    </option>
                </select>
            </div>
        </form>
        <div class="btn-container">
            <button type="submit"
                    class="btn btn-lg btn-primary"
                    [disabled]="!isInputValid()"
                    (click)="createChart()">
                Create Chart
            </button>
        </div>

        <!-- Input hints -->
        <div *ngIf="filterGroups.length < 1" class="help-container text-info">
            <i class="fa fa-info-circle" aria-hidden="true"></i>
            Please add at least one animal group.
        </div>
    </div><!-- /.col -->
</div> <!-- /.row -->

<div class="row">
    <div class="col-md-12">
        <div class="chart-container">
            <kaplan-meier-chart-renderer [filterGroups]="filterGroups"
                                         [chartTitle]="chartTitle"
                                         [interval]="interval"
                                         [intervalCount]="intervalCount || intervalCountDefault"></kaplan-meier-chart-renderer>

        </div> <!-- /.chart-container -->
    </div> <!-- /.col -->
</div> <!-- /.row -->

<div class="variable-phrase-modal" climbTrapFocus>
    <div class="variable-phrase-modal-header">
        <span class="variable-phrase-modal-header-label">Variable Phrases</span>
    </div>
    <div class="variable-phrase-modal-body">
        <form [formGroup]="form">
            <nav ngbNav #nav="ngbNav" >
                <ng-container ngbNavItem *ngFor="let type of variablePhraseKeys; index as i">
                    <a ngbNavLink 
                       class="variable-phrase-nav-item" 
                       [ngStyle]="{'font-weight': form.controls[type]?.value.trim() ? '700' : '400'}">
                       {{variablePhraseTypes[i]}}
                    </a>
                    <ng-template ngbNavContent>
                        <climb-input class="variable-phrase-modal-field" #container>
                            <textarea type="text"
                                      climbInput
                                      class="variable-phrase-modal-textarea"
                                      placeholder="Type here"
                                      climbCharacterCounter
                                      [maxCharacterCount]="8000"
                                      [container]="container"
                                      formControlName="{{type}}"></textarea>
                        </climb-input>
                    </ng-template>
                </ng-container>
            </nav>
        </form>
        <div [ngbNavOutlet]="nav"></div>
        <div class="variable-phrase-modal-controls">
            <button climbButton size="md" (click)="cancelClicked()">
                Cancel
            </button>
            <button climbButton size="md" 
                    variant="primary"
                    [disabled]="isDefault"
                    (click)="updateClicked()">
                Update
            </button>
        </div>
    </div>
</div>
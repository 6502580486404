import { FacetSharedModule } from './../common/facet/facet-shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { WorkspacesSharedModule } from '../workspaces/workspaces-shared.module';
import { CensusFacetComponent } from './census-facet.component';
import { CensusDetailComponent } from './census-detail.component';
import { ClimbCommonModule } from '../common/climb-common.module';
import { CensusFilterComponent } from './census-filter.component';
import { CensusService } from './census.service';
import { LocationsSharedModule } from '../locations/locations-shared.module';
import { CensusBulkEditComponent } from './census-bulk-edit.component';
import { ViewCensusAuditReportComponentService } from './audit/view-census-audit-report-component.service';
import { ExportCensusAuditService } from './audit/export-census-audit.service';
import { CensusAuditReportComponent } from './audit';
import { AuditSharedModule } from '../audit/audit-shared.module';
import { ReportingSharedModule } from '../reporting/reporting-shared.module';
import { AnimalsSharedModule } from '../animals/animals-shared.module';

@NgModule({
    imports: [
        ClimbCommonModule,
        CommonModule,
        FormsModule,
        NgbModule,
        FacetSharedModule,
        WorkspacesSharedModule,
        LocationsSharedModule,
        AuditSharedModule,
        ReportingSharedModule,
        AnimalsSharedModule
    ],
    declarations: [
        CensusBulkEditComponent,
        CensusFilterComponent,
        CensusDetailComponent,
        CensusFacetComponent,
        CensusAuditReportComponent,
    ],
    exports: [
        CensusBulkEditComponent,
        CensusFacetComponent,
        CensusFilterComponent,
        CensusAuditReportComponent
    ],
    providers: [
        CensusService,
        ExportCensusAuditService,
        ViewCensusAuditReportComponentService
    ],
})
export class CensusModule { }

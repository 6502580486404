import { Entity } from "@common/types";

export function updateEntity<T extends Entity<unknown>>(
  entity: T,
  field: keyof T,
  value: T[typeof field],
): () => void {
  const currentValue = entity[field];
  entity[field] = value;
  entity.entityAspect.setUnchanged();
  return function resetEntity() {
    entity[field] = currentValue;
    entity.entityAspect.setUnchanged();
  }
}
<climb-popover #popover [closeOnClick]="false">
    <div class="contact-form" climbTrapFocus>
        <div class="contact-form-header">
            <span class="contact-form-header-label">Contact support</span>
            <button climbButton size="md" variant="ghost" (click)="popover.close()">
                <svg [climbIcon]="icons.crossBig" size="sm"></svg>
            </button>
        </div>
        <div class="contact-form-body">
            <div class="contact-form-field-label">
                  How can we help you?
            </div>
            <climb-input data-automation-id="contact-form-message-input"
                         class="contact-form-field">
                <textarea type="text"
                          climbInput
                          [(ngModel)]="message"
                          [maxlength]="MESSAGE_LENGTH_MAX"
                          class="contact-form-textarea"
                          placeholder="Type your message here"></textarea>
            </climb-input>
            <div class="contact-form-controls">
                <button climbButton size="md" (click)="popover.close()">
                    Cancel
                </button>
                <button data-automation-id="send-support-message-button"
                        climbButton size="md" 
                        variant="primary" 
                        [disabled]="!message" 
                        [pending]="loading" 
                        (click)="sendMessage()">
                    Send
                </button>
            </div>
        </div>
    </div>
</climb-popover>

import type { AnimalPlaceholder } from './animal-placeholder.interface';
import type { Job } from './job.interface';
import type { JobCohort } from './job-cohort.interface';
import type { JobGroup } from './job-group.interface';
import type { TaskPlaceholder } from './task-placeholder.interface';

export interface Placeholder {
    AnimalPlaceholder: AnimalPlaceholder[];
    C_JobCohort_key: number | null;
    C_JobGroup_key: number;
    C_Job_key: number;
    C_Placeholder_key: number;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    Job: Job;
    JobCohort: JobCohort;
    JobGroup: JobGroup;
    ModifiedBy: string;
    PlaceholderName: string;
    SortOrder: number | null;
    TaskPlaceholder: TaskPlaceholder[];
}

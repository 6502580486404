import {
    Component,
    Input,
    OnInit
} from '@angular/core';

import { ResultGrouper } from './result-grouper';
import { CustomSearchRequest } from './custom-search-request';
import { CsvExporter } from './../../common/export/csv-exporter';

import { AppInsightsService } from './../../services/app-insights.service';
import { LoggingService } from './../../services/logging.service';
import { ReportingService } from './../reporting.service';

import {
    ReportTypeItem
} from './../models';

import {
    getSafeProp,
    notEmpty,
    randomId,
} from '../../common/util';

@Component({
    selector: 'custom-search-runner',
    templateUrl: './custom-search-runner.component.html',
    styles: [`
        .report-run-button {
            margin-top: 1em;
        }
        .results-container {
            margin-top: 2em;
        }
        .btn-container {
            text-align: right;
        }
        .export-btn-container {
            margin-bottom: 0.7em;
            text-align: right;
        }
    `]
})
export class CustomSearchRunnerComponent implements OnInit {
    @Input() reportType: ReportTypeItem;

    selectedSearch: any;
    resultsData: any;

    resultGrouper: ResultGrouper;

    searchParams: any[];

    searchPerformed = false;
    searching = false;
    initialized = false;
    domIdAddition: string;
    loading = false;

    readonly COMPONENT_LOG_TAG = 'custom-search-runner';


    constructor(
        private appInsightsService: AppInsightsService,
        private loggingService: LoggingService,
        private reportingService: ReportingService,
    ) {
        this.resultGrouper = new ResultGrouper();
    }

    ngOnInit() {
        this.loadCustomSearch();

        this.domIdAddition = randomId();
    }

    loadCustomSearch(): Promise<any> {
        if (!this.reportType || !this.reportType.value) {
            return Promise.resolve();
        }
        this.loading = true;

        const customSearchQueryKey = this.reportType.value.C_CustomSearchQuery_key;

        return this.reportingService.getCustomSearch(customSearchQueryKey).then((data) => {
            if (notEmpty(data)) {
                this.selectedSearch = data;
                this.loadCustomSearchParams();
            }

            this.initialized = true;
            this.loading = false;
        });
    }

    loadCustomSearchParams() {
        if (!this.selectedSearch) {
            return;
        }

        this.searching = false;
        this.searchParams = this.selectedSearch.CustomSearchParameter;
    }

    performSearch() {
        if (!this.selectedSearch) {
            return;
        }

        this.appInsightsService.trackEvent('custom-search-run', {
            selectedSearch: this.selectedSearch.SearchName || ''
        });

        const query = this.buildQuery();
        this.searching = true;
        this.reportingService.performCustomSearch(query).then((response) => {
            this.resultsData = response.data;
            this.searching = false;
            this.searchPerformed = true;
        }).catch((error) => {
            this.searching = false;
            throw error;
        });
    }

    clickPage(pageNumber: number) {
        if (!this.selectedSearch) {
            return;
        }

        const query = this.buildQuery();
        query.pageNumber = pageNumber;

        this.searching = true;
        this.reportingService.performCustomSearch(query).then((response) => {
            this.resultsData = response.data;
            this.searching = false;
            this.searchPerformed = true;
        }).catch((error) => {
            this.searching = false;
            throw error;
        });
    }

    exportToCsv() {
        const exportSize = 5000;

        const filename = (this.selectedSearch.SearchName || 'ReportResults') + '.csv';

        const query = this.buildQuery();
        query.pageNumber = 1;
        query.rowCount = exportSize;

        this.appInsightsService.trackEvent('custom-search-export', {
            selectedSearch: this.selectedSearch.SearchName || ''
        });

        this.reportingService.performCustomSearch(query).then((response) => {
            if (!response || !response.data) {
                return;
            }

            const data = response.data;

            if (data.totalCount > exportSize) {
                this.loggingService.logWarning(
                    "CSV file only contains first " + exportSize + " records",
                    null,
                    this.COMPONENT_LOG_TAG,
                    true
                );
            }

            const results = data.results;

            const csvData = this.generateCsvData(results);

            // generate file
            const csvExporter = new CsvExporter();
            csvExporter.download(csvData, filename);
        });
    }

    generateCsvData(results: any[]): any[][] {
        const csvData: any[][] = [];
        const schema = JSON.parse(this.selectedSearch.Schema);
        const flattenedColumns = this.resultGrouper.getFlattenedColumns(schema);

        // add headers
        csvData.push(flattenedColumns);

        for (const row of results) {
            const csvRow: any[] = [];
            for (const column of flattenedColumns) {
                csvRow.push(getSafeProp(row, column));
            }
            csvData.push(csvRow);
        }

        return csvData;
    }


    buildQuery(): CustomSearchRequest {
        const parameters = this.selectedSearch.CustomSearchParameter.map((param: any) => {
            let value = param.Value;
            const name = param.ParameterName;

            const dataType = getSafeProp(param, 'cv_DataType.DataType');
            if (dataType === 'Controlled Vocabulary Multiselect') {
                value = String(value);
            }

            return { name, value };
        });

        const rowCount = this.selectedSearch.RecordCount || 50;

        return {
            searchName: this.selectedSearch.SearchName,
            rowCount,
            pageNumber: this.selectedSearch.pageNumber,
            parameters
        };
    }
}

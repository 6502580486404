import { SDEnumeration } from './sd-enumeration';

export class SDCharacteristic {
    name: string;
    taxon: string;
    description: string;
    dataType: string;
    dataTypeKey: number;
    enumerationClass: string;
    enumerations: SDEnumeration[];
    isSelected?: boolean;
}

import { AuthConfig } from 'angular-oauth2-oidc';
import { b2cEnvironmentName } from '../config/climb-web-settings';

let discoveryDocument;
let auth: AuthConfig;

if (b2cEnvironmentName.toLowerCase() === 'production') {
    discoveryDocument = {
        url: "https://climbsso.b2clogin.com/climbsso.onmicrosoft.com/v2.0/.well-known/openid-configuration?p=b2c_1a_signup_signin"
    };

    auth = {
        redirectUri: window.location.origin + '/',
        responseType: 'token id_token',
        issuer: 'https://climbsso.b2clogin.com/15e7dc44-ae84-4e75-bb75-e90170a3f393/v2.0/',
        strictDiscoveryDocumentValidation: false,
        tokenEndpoint: 'https://climbsso.b2clogin.com/climbsso.onmicrosoft.com/oauth2/v2.0/token?p=b2c_1a_signup_signin',
        loginUrl: 'https://climbsso.b2clogin.com/climbsso.onmicrosoft.com/oauth2/v2.0/authorize?p=b2c_1a_signup_signin',
        logoutUrl: 'https://climbsso.b2clogin.com/climbsso.onmicrosoft.com/oauth2/v2.0/logout?p=b2c_1a_signup_signin',
        clientId: 'ba4d83c7-8425-4d55-b060-040ae91fedc4',
        scope: 'openid profile email offline_access https://climbsso.onmicrosoft.com/f0919ea5-c0c7-4365-9352-ce013b5412da/user_impersonation',
        skipIssuerCheck: true,
        clearHashAfterLogin: true,
        oidc: true,
        showDebugInformation: true,
        jwks: {
            kid: "NsibQ4G-sHXwXwGp2ntzPhnz0q9I9i2gmZS5EZgjZOU"
        }
    };
} else if (b2cEnvironmentName.toLowerCase() === 'charlesriver') {
    discoveryDocument = {
        url: "https://crlclimb.b2clogin.com/crlclimb.onmicrosoft.com/v2.0/.well-known/openid-configuration?p=b2c_1a_signup_signin"
    };

    auth = {
        redirectUri: window.location.origin + '/',
        responseType: 'token id_token',
        issuer: 'https://crlclimb.b2clogin.com/6b44931b-fbec-472c-961d-368df72ccab2/v2.0/',
        strictDiscoveryDocumentValidation: false,
        tokenEndpoint: 'https://crlclimb.b2clogin.com/crlclimb.onmicrosoft.com/oauth2/v2.0/token?p=b2c_1a_signup_signin',
        loginUrl: 'https://crlclimb.b2clogin.com/crlclimb.onmicrosoft.com/oauth2/v2.0/authorize?p=b2c_1a_signup_signin',
        logoutUrl: 'https://crlclimb.b2clogin.com/crlclimb.onmicrosoft.com/oauth2/v2.0/logout?p=b2c_1a_signup_signin',
        clientId: '75f233e8-7e87-4aba-94e8-2c3513f22e94',
        scope: 'openid profile email offline_access https://crlclimb.onmicrosoft.com/13d97aeb-800d-4df8-a50a-0b94beacb923/user_impersonation',
        skipIssuerCheck: true,
        clearHashAfterLogin: true,
        oidc: true,
        showDebugInformation: true,
        jwks: {
            kid: "VaUM-JCWU5UD06deIxMEyRhO3kFUJoNuIkUur3Am7Y8"
        }
    };
} else { // b2cEnvironment = 'development' (or anything else)
    discoveryDocument = {
        url: "https://climbdevlocal.b2clogin.com/climbdevlocal.onmicrosoft.com/v2.0/.well-known/openid-configuration?p=b2c_1a_signup_signin"
    };

    auth = {
        redirectUri: window.location.origin + '/',
        responseType: 'token id_token',
        issuer: 'https://climbdevlocal.b2clogin.com/31acc632-cb18-4839-bee1-12c6b7bf4c3f/v2.0/',
        strictDiscoveryDocumentValidation: false,
        tokenEndpoint: 'https://climbdevlocal.b2clogin.com/climbdevlocal.onmicrosoft.com/oauth2/v2.0/token?p=b2c_1a_signup_signin',
        loginUrl: 'https://climbdevlocal.b2clogin.com/climbdevlocal.onmicrosoft.com/oauth2/v2.0/authorize?p=b2c_1a_signup_signin',
        logoutUrl: 'https://climbdevlocal.b2clogin.com/climbdevlocal.onmicrosoft.com/oauth2/v2.0/logout?p=b2c_1a_signup_signin',
        clientId: '74e1c25a-5de6-4c60-ad82-2dc773639eca',
        scope: 'openid profile email offline_access https://climbdevlocal.onmicrosoft.com/b978bda5-80f2-4908-8134-57bbec993cee/user_impersonation',
        skipIssuerCheck: true,
        clearHashAfterLogin: true,
        oidc: true,
        showDebugInformation: true,
        jwks: {
            kid: "hlJiulcSD6x0jpUenswfyqWUfty0kjS-czyYOCZ_hzI"
        }
    };
}

export const discoveryDocumentConfig = discoveryDocument;
export const authConfig: AuthConfig = auth;

import { Injectable, Output } from '@angular/core';

import { TranslationService } from '@services/translation.service';
import { UserNameService } from '../../user/user-name.service';
import {
    currentMaterialHousingID,
    getSafeProp
} from '@common/util';
import { DateFormatterService } from '@common/util/date-time-formatting';

import {
    DataRow,
    BooleanMap,
    IOColumn,
} from '../models/workflow-bulk-data';
import { WorkflowService } from './workflow.service';
import { ExportType, ExporterFactory } from '@common/export';
import { SettingService } from '../../settings/setting.service';

@Injectable()
export class WorkflowBulkDataExportService {

    constructor(
        private translationService: TranslationService,
        private userNameService: UserNameService,
        private workflowService: WorkflowService,
        private dateFormatterService: DateFormatterService,
        private settingService: SettingService
    ) {
    }

    /**
     * Export the current Workflow Bulk Data table to CSV
     * @param rows current rows
     * @param visible column visibility
     * @param inputCols all Input columns
     * @param outputCols all Output columns
     */
    export(
        rows: DataRow[],
        visible: BooleanMap,
        inputCols: IOColumn[],
        outputCols: IOColumn[],
        exportType: ExportType,
        isGLP: boolean
    ) {
        const filename = 'BulkData.csv';

        this.buildExportData(rows, visible, inputCols, outputCols, isGLP).then((data: any[][]) => {
            const exporter = ExporterFactory.create(exportType);
            exporter.download(data, filename);
        });

    }

    /**
     * Assemble the CSV data rows 
     */
    private async buildExportData(
        rows: DataRow[],
        visible: BooleanMap,
        inputCols: IOColumn[],
        outputCols: IOColumn[],
        isGLP: boolean
    ): Promise<any[][]> {
        const data: any[][] = [];

        const titleRow: any = [];
        const taxonCharacteristics = await this.settingService.getTaxonCharacteristicsShownInListView();

        if (visible.JobID) {
            titleRow.push(this.translationService.translate('Job') + ' Name');
        }
        if (visible.TaskAlias) {
            titleRow.push('Task Name');
        }
        if (visible.PhysicalMarker) {
            titleRow.push('Physical Marker');
        }
        if (visible.AnimalName) {
            titleRow.push('Animal');
        }
        if (visible.MicrochipIdentifier) {
            titleRow.push('Microchip ID');
        }
        if (visible.AlternatePhysicalID && isGLP) {
            titleRow.push('Alternate Physical ID');
        }
        if (visible.Scan) {
            titleRow.push('Scan');
        }
        if (visible.AnimalComments) {
            titleRow.push('Animal Comments');
        }
        if (visible.AnimalClassification && isGLP) {
            titleRow.push('Animal Classification');
        }
        if (visible.Cohort) {
            titleRow.push('Animal Cohort');
        }

        for (const characteristic of taxonCharacteristics) {
            if (visible[characteristic.C_TaxonCharacteristic_key]) {
                titleRow.push(characteristic.CharacteristicName);
            }
        }
        if (visible.HousingID) {
            titleRow.push('Housing ID');
        }
        if (visible.SampleName) {
            titleRow.push('Samples');
        }
        if (visible.DateDue || visible.TimeDue) {
            titleRow.push('Date Due');
        }

        for (const inputCol of inputCols) {
            titleRow.push(inputCol.label);
        }
        if (visible.OutputAnimal) {
            titleRow.push('Output Animal');
        }
        if (visible.OutputSample) {
            titleRow.push('Output Sample');
        }
        if (visible.HarvestDate) {
            titleRow.push('Harvest Date');
        }
        if (visible.SampleVolume) {
            titleRow.push('Sample Measurement');
        }
        if (visible.VolumeUnit) {
            titleRow.push('Sample Unit');
        }
        if (visible.SampleSubtype) {
            titleRow.push('Sample Subtype');
        }
        if (visible.SampleProcessingMethod) {
            titleRow.push('Sample Processing Method');
        }
        if (visible.SendTo) {
            titleRow.push('Sample Send To');
        }
        if (visible.SampleAnalysisMethod) {
            titleRow.push('Sample Analysis Method');
        }
        if (visible.SpecialInstructions) {
            titleRow.push('Special Instructions');
        }
        if (visible.SampleLotNumber) {
            titleRow.push('Sample Lot Number');
        }
        if (visible.SampleType) {
            titleRow.push('Sample Type');
        }
        if (visible.SampleStatus) {
            titleRow.push('Sample Status');
        }
        if (visible.PreservationMethod) {
            titleRow.push('Sample Preservation Method');
        }
        if (visible.ContainerType) {
            titleRow.push('Sample Container Type');
        }

        for (const outputCol of outputCols) {
            titleRow.push(outputCol.label);

            if (outputCol.output.OutputFlag.length > 0) {
                outputCol.hideFlagAndFlagMessageColumns = false;
                titleRow.push('Has Flag');
                titleRow.push('Flags Messages');
            } else {
                outputCol.hideFlagAndFlagMessageColumns = true;
            }            
        }

        if (visible.CollectedBy) {
            titleRow.push('Collected By');
        }
        if (visible.CollectedDate || visible.CollectedTime) {
            titleRow.push('Collected Date');
        }

        if (visible.CompletedBy) {
            titleRow.push('Completed By');
        }
        if (visible.CompletedDate || visible.CompletedTime) {
            titleRow.push('Completed Date');
        }

        if (visible.ReviewedBy) {
            titleRow.push('Reviewed By');
        }
        if (visible.ReviewedDate || visible.ReviewedTime) {
            titleRow.push('Reviewed Date');
        }

        if (visible.TaskStatus) {
            titleRow.push('Task Status');
        }
        if (visible.AnimalStatus) {
            titleRow.push('Animal Status');
        }
        if (visible.ExitReason) {
            titleRow.push('Exit Reason');
        }

        for (const characteristic of taxonCharacteristics) {
            if (visible[characteristic.CharacteristicName]) {
                titleRow.push(characteristic.CharacteristicName);
            }
        }
        titleRow.push('Task Notes');
        data.push(titleRow);

        for (const row of rows) {
            const dataRow: any[] = [];
            const sample = row.outputSample ? 'outputSample' : 'sample';

            if (visible.JobID) {
                dataRow.push(getSafeProp(row, 'job.JobID'));
            }
            if (visible.TaskAlias) {
                dataRow.push(getSafeProp(row, 'task.TaskAlias'));
            }
            if (visible.PhysicalMarker) {
                dataRow.push(getSafeProp(row, 'animal.PhysicalMarker'));
            }
            if (visible.AnimalName) {
                dataRow.push(getSafeProp(row, 'animal.AnimalName'));
            }
            if (visible.MicrochipIdentifier) {
                dataRow.push(getSafeProp(row, 'animal.Material.MicrochipIdentifier'));
            }
            if (visible.AlternatePhysicalID && isGLP) {
                dataRow.push(getSafeProp(row, 'animal.AlternatePhysicalID'));
            }
            if (visible.Scan) {
                dataRow.push(getSafeProp(row, 'scanMaterial.ScanValue'));
            }
            if (visible.AnimalComments) {
                dataRow.push(
                    !row.animal ?
                        '' :
                        row.animal.AnimalComment.map((animalComment: any) =>
                            `${animalComment.cv_AnimalCommentStatus.AnimalCommentStatus}: ${animalComment.Comment}`
                        ).toString()
                );
            }
            if (visible.AnimalClassification && isGLP) {
                dataRow.push(getSafeProp(row, 'animal.cv_AnimalClassification.AnimalClassification'));
            }
            if (visible.Cohort) {
                await this.getAnimalCohortNames(row).then(((cohorts: string) => { dataRow.push(cohorts); }));
            }

            for (const characteristic of taxonCharacteristics) {
                if (visible[characteristic.C_TaxonCharacteristic_key]) {
                    dataRow.push(this.getTaxonCharacteristicInstance(row, characteristic.C_TaxonCharacteristic_key));
                }
            }
            if (visible.HousingID) {
                let housingId = '';
                if(row.animalFirst && row.animal) {
                    housingId = getSafeProp(row, 'animal.currentHousingId');
                }
                if (row.taskMaterialPool) {
                    housingId = getSafeProp(row, 'taskMaterialPool.MaterialPoolID') ?? housingId;
                }
                dataRow.push(housingId);
            }
            if (visible.SampleName) {
                dataRow.push(getSafeProp(row, 'sample.SampleName'));
            }
            if (visible.DateDue || visible.TimeDue) {
                dataRow.push(this.dateFormatterService.formatDateOrTime(row.task.DateDue));
            }

            for (const inputCol of inputCols) {
                if (!row.inputs[inputCol.key]) {
                    dataRow.push(null);
                } else if (row.inputs[inputCol.key].InputValue === null && row.inputs[inputCol.key].Input.cv_DataType.DataType.toLowerCase() === 'boolean') {
                    dataRow.push('FALSE');
                } else {
                    dataRow.push(row.inputs[inputCol.key].InputValue);
                }
            }

            if (visible.OutputAnimal) {
                dataRow.push(getSafeProp(row, 'outputAnimal.AnimalName'));
            }
            if (visible.OutputSample) {
                dataRow.push(getSafeProp(row, 'outputSample.SampleName'));
            }
            if (visible.HarvestDate) {
                dataRow.push(this.dateFormatterService.formatDateOnly(getSafeProp(row, sample + '.DateHarvest')));
            }

            if (visible.SampleVolume) {
                dataRow.push(getSafeProp(row, sample + '.Volume'));
            }
            if (visible.VolumeUnit) {
                dataRow.push(getSafeProp(row, sample + '.cv_Unit.Unit'));
            }
            if (visible.SampleSubtype) {
                dataRow.push(getSafeProp(row, sample + '.cv_SampleSubtype.SampleSubtype'));
            }
            if (visible.SampleProcessingMethod) {
                dataRow.push(getSafeProp(row, sample + '.cv_SampleProcessingMethod.SampleProcessingMethod'));
            }
            if (visible.SendTo) {
                dataRow.push(getSafeProp(row, sample + '.SendTo'));
            }
            if (visible.SampleAnalysisMethod) {
                dataRow.push(getSafeProp(row, sample + '.cv_SampleAnalysisMethod.SampleAnalysisMethod'));
            }
            if (visible.SpecialInstructions) {
                dataRow.push(getSafeProp(row, sample + '.SpecialInstructions'));
            }
            if (visible.SampleLotNumber) {
                dataRow.push(getSafeProp(row, sample + '.LotNumber'));
            }
            if (visible.SampleType) {
                dataRow.push(getSafeProp(row, sample + '.cv_SampleType.SampleType'));
            }
            if (visible.SampleStatus) {
                dataRow.push(getSafeProp(row, sample + '.cv_SampleStatus.SampleStatus'));
            }
            if (visible.PreservationMethod) {
                dataRow.push(getSafeProp(row, sample + '.cv_PreservationMethod.PreservationMethod'));
            }
            if (visible.ContainerType) {
                dataRow.push(getSafeProp(row, sample + '.Material.cv_ContainerType.ContainerType'));
            }

            for (const outputCol of outputCols) {
                if (row.taskOutputs[outputCol.key]) {
                    if (row.taskOutputs[outputCol.key].OutputValue === 'NaN') {
                        dataRow.push('0');
                    } else if (row.taskOutputs[outputCol.key].OutputValue === null && outputCol.output.cv_DataType.DataType.toLowerCase() === 'boolean') {
                        dataRow.push('FALSE');
                    } else {
                        dataRow.push(row.taskOutputs[outputCol.key].OutputValue);
                    }
                    if (outputCol.hideFlagAndFlagMessageColumns === false) {
                        const hasFlag = row.taskOutputs[outputCol.key].HasFlag ? 'TRUE' : 'FALSE';
                        dataRow.push(hasFlag);
                        dataRow.push(row.taskOutputs[outputCol.key].FlagsMessages);
                    }
                } else {
                    dataRow.push(null);
                    if (outputCol.hideFlagAndFlagMessageColumns === false) {
                        dataRow.push(null);
                        dataRow.push(null);
                    }
                }                
            }

            if (visible.CollectedBy) {
                dataRow.push(getSafeProp(row, 'taskOutputSet.Resource.ResourceName'));
            }
            if (visible.CollectedDate || visible.CollectedTime) {
                dataRow.push(this.dateFormatterService.formatDateOrTime(
                    getSafeProp(row, 'taskOutputSet.CollectionDateTime')
                ));
            }

            if (visible.CompletedBy) {
                dataRow.push(getSafeProp(row, 'task.CompletedByResource.ResourceName'));
            }
            if (visible.CompletedDate || visible.CompletedTime) {
                dataRow.push(this.dateFormatterService.formatDateOrTime(
                    getSafeProp(row, 'task.DateComplete')
                ));
            }

            if (visible.ReviewedBy) {
                dataRow.push(getSafeProp(row, 'task.ReviewedByResource.ResourceName'));
            }
            if (visible.ReviewedDate || visible.ReviewedTime) {
                dataRow.push(this.dateFormatterService.formatDateOrTime(
                    getSafeProp(row, 'task.DateReviewed')
                ));
            }

            if (visible.TaskStatus) {
                dataRow.push(getSafeProp(row, 'task.cv_TaskStatus.TaskStatus'));
            }
            if (visible.AnimalStatus) {
                dataRow.push(getSafeProp(row, 'animal.cv_AnimalStatus.AnimalStatus'));
            }
            if (visible.ExitReason) {
                dataRow.push(getSafeProp(row, 'animal.cv_ExitReason.ExitReason'));
            }

            for (const characteristic of taxonCharacteristics) {
                if (visible[characteristic.CharacteristicName]) {
                    dataRow.push(this.getTaxonCharacteristicInstance(row, characteristic.C_TaxonCharacteristic_key));
                }
            }
            dataRow.push(this.getNotesString(row));

            data.push(dataRow);
        }

        return data;
    }

    getNotesString(row: any) {
        let noteString = '';
        if (row && row.task && row.task.Note && row.task.Note.length) {
            const notes = row.task.Note;
            noteString = notes.map((n: any) => `${n.NoteText} (${this.userNameService.toFullName(n.CreatedBy)} ${this.dateFormatterService.formatDateTime(n.DateCreated)})`).toString();
        }
        return noteString;
    }

    /**
     * Return only the Animal Cohort Names
     * @param row
     */
    getAnimalCohortNames(row: any): Promise<string> {
        let cohortNames = '';

        const taskKeys = [getSafeProp(row, 'task.C_TaskInstance_key'), getSafeProp(row, 'task.C_GroupTaskInstance_key')];

        // Get cohorts
        return this.workflowService.loadAllTaskCohorts(taskKeys).then((taskCohorts: any[]) => {

            if (row.animal) {
                const animalCohorts = row.animal.Material.CohortMaterial;

                if (animalCohorts && animalCohorts.length > 0 && taskCohorts) {
                    taskCohorts.forEach((cohort: any) => {
                        const index = animalCohorts.findIndex((item: any) => item.C_Cohort_key === cohort.C_Cohort_key);
                        if (index > -1) {
                            cohortNames += cohort.Cohort.CohortName + ',';
                        }
                    });
                }
            }

            return cohortNames.substring(0, cohortNames.length - 1);
        });
    }

    getTaxonCharacteristicInstance(row: any, characteristicKey: number): string {
        if (row.animal && row.animal.TaxonCharacteristicInstance && row.animal.TaxonCharacteristicInstance.length) {
            for (const characteristic of row.animal.TaxonCharacteristicInstance) {
                if (characteristic.C_TaxonCharacteristic_key === characteristicKey) {
                    return characteristic.CharacteristicValue;
                }
            }
            return "";
        } else {
            return "";
        }
    }
}

<div class="bulk-edit-toolbar" aria-label="Bulk-edit toolbar">

    <ng-content select="[additional-groups]"></ng-content>

    <climb-column-select [columnLabels]="columnSelect"
                         (onChange)="columnSelectChanged($event)"
                         class="old-fashioned">
    </climb-column-select>

    <button data-automation-id="save-button"
            type="button"
            class="btn btn-fw"
            [ngClass]="hasChanges ? 'btn-warning' : 'btn-secondary'"
            [disabled]="!hasChanges || !canSave()"
            (click)="saveClicked()">
        <span *ngIf="!saveChangesService.saving">
            <i class="fa fa-check" aria-hidden="true" title="Save"></i>
            Save
        </span>
        <span *ngIf="saveChangesService.saving">
            <i class="fa fa-spinner fa-spin" title="Saving&hellip;"></i>
        </span>
    </button>

    <button data-automation-id="list-view-button"
            type="button"
            class="btn btn-secondary"
            [disabled]="saveChangesService.saving"
            (click)="exitClicked()">
        <i class="fa fa-table" aria-hidden="true" title="List View"></i>
        List <span class="gridster-d-sm-none gridster-d-md-inline-block">View</span>
        <span class="sr-only">List View</span>
    </button>

</div>

import { Component } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: "reason-for-change-modal",
    templateUrl: "./reason-for-change-modal.component.html",
    styles: []
})
export class ReasonForChangeModalComponent {
    reasonForChange: string;

    entitiesTable: any;

    constructor(
        private activeModal: NgbActiveModal) {
    }

    /**
     * Closes the modal with the result message "close".
     */
    closeModal() {
        this.activeModal.close(this.reasonForChange);
    }

    get confirmationButtonDisabled(): boolean {
        return !this.reasonForChange || this.reasonForChange.trim().length === 0;
    }
}

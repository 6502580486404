import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';

/*
 * Given a date, output a date string
 * outlining the bounds of the week
 * that that date belongs to (i.e.,
 * Sunday to Saturday). Uses moment.
 * UNUSED AS OF 05/06/2023
 */
@Pipe({ name: 'formatWeek' })
export class FormatWeekPipe implements PipeTransform {
    transform(date: Date): string {
        let output = '';
        const dateLuxon = DateTime.fromJSDate(date);

        const RANGE_DELIMITER = '-';

        const sunday = dateLuxon.startOf('week');
        const saturday = dateLuxon.endOf('week');

        if (sunday.year !== saturday.year) {
            output = sunday.toFormat('MMMM d, yyyy') +
                RANGE_DELIMITER +
                saturday.toFormat('MMMM d, YYYY');
        } else {
            if (sunday.month === saturday.month) {
                output = sunday.toFormat('MMMM d') +
                    RANGE_DELIMITER +
                    saturday.toFormat('d, YYYY');
            } else {
                output = sunday.toFormat('MMMM d') +
                    RANGE_DELIMITER +
                    saturday.toFormat('MMMM d, YYYY');
            }
        }

        return output;
    }
}

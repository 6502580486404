const ELLIPSIS = '\u2026';

import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';

import { SearchService } from '../search/search.service';
import { SearchQueryDef } from '../search/search-query-def';

@Component({
    selector: 'order-select',
    template: `
    <div>
        <climb-typeahead
            [(model)]="model"
            [resultFormatter]="orderFormatter"
            [keyFormatter]="orderKeyFormatter"
            [search]="searchOrders"
            [keySearch]="searchOrdersByKey"  
            [required]="required"
            [disabled]="disabled"
            [placeholder]="placeholder"
            [namespace]="'order'"
            (selectItem)="selectItemHandler($event)"
            #typeahead
        ></climb-typeahead>
    </div>
    `
})
export class OrderSelectComponent implements OnInit {
    @Input() model: any;
    @Input() required: boolean;
    @Input() disabled: boolean;
    // Only returns animal type orders if true
    @Input() animalOrdersOnly = false;

    @Output() modelChange: EventEmitter<any> = new EventEmitter<any>();

    placeholder: string;

    constructor(
        private searchService: SearchService
    ) {
    }

    ngOnInit() {
        this.placeholder = 'Order ID' +
            ELLIPSIS;
    }

    selectItemHandler(item: any) {
        this.modelChange.emit(this.model);
    }

    searchOrders = (search: string): Promise<any[]> => {
        let filter = {};
        if (this.animalOrdersOnly) {
            filter = {
                OrderID: search,
                MaterialType: 'animal'
            };
        } else {
            filter = {
                OrderID: search
            };
        }

        return this._searchOrders(filter, 50);
    }

    searchOrdersByKey = (key: any): Promise<any[]> => {
        let filter = {};
        if (this.animalOrdersOnly) {
            filter = {
                OrderKey: key,
                MaterialType: 'animal'
            };
        } else {
            filter = {
                OrderKey: key
            };
        }
        return this._searchOrders(filter, 1);
    }

    _searchOrders(thisFilter: any, count: number): Promise<any[]> {
        const searchQueryDef: SearchQueryDef = {
            entity: 'Orders',
            page: null,
            size: count,
            sortColumn: 'OrderID',
            sortDirection: 'asc',
            isActive: true,
            filter: thisFilter
        };     
        return this.searchService.getEntitiesBySearch(searchQueryDef).then((results: any) => {
            return results.data;
        });
    }

    orderKeyFormatter = (value: any) => {
        return value.OrderKey;
    }
    orderFormatter = (value: any) => {
        return value.OrderID;
    }
}

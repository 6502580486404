
/**
 * Returns all the items in source array that do not exist in target array
 * @param source 
 * @param target 
 */
export function arrayDifference(source: any[], target: any[]): any[] {
    if (!source) {
        return [];
    }
    if (!target) {
        return source.slice();
    }

    const src = source.slice();
    target.forEach((value) => {
        while (src.indexOf(value) !== -1) {
            src.splice(src.indexOf(value), 1);
        }
    });

    return src;
}

export class CvValue {
    cvValue: string;
    isDefault: boolean;
    isEndState: boolean;
    isExitStatus: boolean;
    isSelected?: boolean;

    // cv_Taxon fields
    commonName?: string;
    daysToWean?: number;

    // cv_ContainerType field
    materialType?: string;
}

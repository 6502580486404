import { 
    Directive,
} from '@angular/core';

import { DroppableCommService } from './droppable-comm.service';

@Directive({ 
    selector: '[parentDroppable]',
    providers: [DroppableCommService] 
})
export class ParentDroppableDirective {
   
}

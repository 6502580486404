import { StudySharedLogic } from './study-shared-logic';
import { VocabularyService } from './../vocabularies/vocabulary.service';
import {
    Component,
    Input,
    OnInit,
    OnDestroy,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { StudyService } from './study.service';
import { TranslationService } from '../services/translation.service';
import { WorkspaceFilterService } from '../services/workspace-filter.service';

import {
    BaseFacet,
    BaseFacetService
} from '../common/facet';

import { StudyTableOptions } from './study-table-options';
import { StudyFilterComponent } from './study-filter.component';

import {
    CellFormatterService,
    TableState,
    DataResponse,
    ColumnsState,
    TableColumnDef,
} from '@common/datatable';
import { QueryDef } from '../services/query-def';
import { EnumerationService } from '../enumerations/enumeration.service';
import { filterToDate } from '../common/util';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DataContextService } from '@services/data-context.service';
import { arrowClockwise, magnifier } from '@icons';

@Component({
    selector: 'study-facet',
    templateUrl: './study-facet.component.html',
    providers: BaseFacet.BASE_COMPONENT_PROVIDERS
})
export class StudyFacetComponent extends BaseFacet implements OnInit, OnDestroy {
    @Input() facetId: string;
    @Input() facet: any;

    readonly icons = { arrowClockwise, magnifier };

    componentName = 'study';

    studyTableOptions: StudyTableOptions;

    studySharedLogic: StudySharedLogic;

    readonly COMPONENT_LOG_TAG = 'study-facet';

    componentFilterSubscription: Subscription;
    private notifier$ = new Subject<void>();

    dataTableColumns: BehaviorSubject<TableColumnDef[]>;
    dataTableColumns$: Observable<TableColumnDef[]>;

    constructor(
        private baseFacetService: BaseFacetService,
        private cellFormatterService: CellFormatterService,
        private studyService: StudyService,
        private modalService: NgbModal,
        private translationService: TranslationService,
        workspaceFilterService: WorkspaceFilterService,
        private enumerationService: EnumerationService,
        private vocabularyService: VocabularyService,
        private dataContext: DataContextService
    ) {
        super(
            baseFacetService,
            workspaceFilterService
        );

        this.studySharedLogic = new StudySharedLogic(
            studyService,
            enumerationService
        );


        this.studyTableOptions = new StudyTableOptions(
            this.cellFormatterService,
            this.translationService
        );

        this.dataTableColumns = new BehaviorSubject(this.studyTableOptions.options.columns);
        this.dataTableColumns$ = this.dataTableColumns.asObservable();

        this.dataService = {
            run: (tableState: TableState) => {
                return this.loadItemsList(tableState);
            },
            preExport: (dataResponse: DataResponse) => {
                const studies = dataResponse.results;
                if (!studies) {
                    return Promise.resolve(dataResponse);
                } else {
                    const visibleColumns = this.getVisibleColumns(this.studyTableOptions.options);
                    return this.studyService.ensureListViewAssociatedDataLoaded(
                        this.data, visibleColumns
                    ).then(() => {
                            return dataResponse;
                        });
                }
            }
        };
    }

    // lifecycle
    ngOnInit() {
        super.ngOnInit();
        this.initialize();

        this.dataContext.onCancel$.pipe(takeUntil(this.notifier$)).subscribe(() => {
            this.changeView(this.LIST_VIEW);
        });
    }

    ngOnDestroy() {
        if (this.componentFilterSubscription) {
            this.componentFilterSubscription.unsubscribe();
        }
        this.notifier$.next();
        this.notifier$.complete();
    }

    initialize() {
        this.restoreFilterState();
        this.changeView(this.LIST_VIEW);
    }

    refreshData() {
        this.initialize();
        this.dataTableComm.reloadTable();
    }

    restoreFilterState() {

        // process any grid filters
        if (this.facet && this.facet.GridFilter) {
            try {
                this.filter = JSON.parse(this.facet.GridFilter);
            } catch (err) {
                console.error(err);
            }

            if (this.filter) {
                this.filter.DateCreatedStart = filterToDate(this.filter.DateCreatedStart);
                this.filter.DateCreatedEnd = filterToDate(this.filter.DateCreatedEnd);
            } else {
                this.filter = {};
            }
        }
    }

    loadItemsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        this.setLoadingState(tableState.loadingMessage);

        const visibleColumns = this.getVisibleColumns(this.studyTableOptions.options);

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'DateCreated DESC';
        const expands: string[] = [];

        const queryDef: QueryDef = {
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter(),
            expands
        };

        const promise = this.studyService.getStudies(queryDef).then((response) => {
            this.stopLoading();

            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });

        // load associated list view data in the background
        promise
            .then(() => this.facetLoadingState.changeLoadingState(true))
            .then(() => this.studyService.ensureListViewAssociatedDataLoaded(this.data, visibleColumns))
            .then(() => this.studyService.ensureVisibleColumnsDataLoaded(this.data, visibleColumns))
            .then(() => this.dataTableComm.redrawTableCells())
            .finally(() => this.facetLoadingState.changeLoadingState(false));

        return promise;
    }

    addItemClick() {
        this.loading = true;
        this.createNewItem().then((item) => {
            this.loading = false;
            this.itemToEdit = item;
            this.changeView(this.DETAIL_VIEW);
        }).catch((error) => {
            this.loading = false;
            this.loggingService.logError("An unexpected error occurred. Please try again", error, this.componentName, true);
        });
    }

    createNewItem(): Promise<any> {
        const newStudy = this.studyService.createStudy();

        const p1 = this.vocabularyService.getCVDefault('cv_StudyTypes').then((value) => {
            newStudy.cv_StudyType = value;
            this.studySharedLogic.createStudyCharacteristics(newStudy);
        });
        const p2 = this.vocabularyService.getCVDefault('cv_StudyStatuses').then((value) => {
            newStudy.cv_StudyStatus = value;
        });

        const p3 = this.studyService.createStudyAdministratorStudies(newStudy);

        return Promise.all([p1, p2, p3]).then(() => {
            return newStudy;
        });
    }

    openFilter() {
        const ref = this.modalService.open(StudyFilterComponent);
        const component = ref.componentInstance as StudyFilterComponent;
        component.filter = this.filter;
        this.componentFilterSubscription = component.onFilter.subscribe((filter: any) => {
            this.filter = filter;
            this.runFilter();
        });
    }

    async selectedColumnsChange({ visible }: ColumnsState) {
        try {
            this.facetLoadingState.changeLoadingState(true);
            await this.studyService.ensureVisibleColumnsDataLoaded(this.data, visible);
        } finally {
            this.facetLoadingState.changeLoadingState(false);
        }
    }
}

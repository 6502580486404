import { Injectable } from '@angular/core';

import { TranslationService } from '../../services/translation.service';
import { UserNameService } from '../../user/user-name.service';
import { CsvExporter } from '../../common/export/csv-exporter';

import { DateFormatterService } from '@common/util/date-time-formatting';

/**
 * Exports a institution audit report to CSV
 */
@Injectable()
export class ExportInstitutionAuditService {
    csvExporter: CsvExporter;

    constructor(
        private translationService: TranslationService,
        private userNameService: UserNameService,
        private dateFormatterService: DateFormatterService,
    ) {
        this.csvExporter = new CsvExporter();
    }
    
    exportToCsv(
        auditData: any[],
        isCRL: boolean
    ) {
        const EXPORTER_CSV_FILENAME = this.translationService.translate('Institution') +
            'Audit.csv';

        const data: any[][] = this.buildExportData(
            auditData,
            isCRL
        );

        this.csvExporter.download(data, EXPORTER_CSV_FILENAME);
    }

    buildExportData(
        auditData: any[],
        isCRL: boolean
    ): any[][] {
        const data: any[][] = [];

        data.push(['Details']);
        data.push([
            'Action',
            'Modified Date',
            'Modified By',
            'Name',
            isCRL ? 'Account Number' : 'Identifier',
            isCRL ? 'Client ID' : 'Account Number',
            'Email',
            'Address',
            'Address 2',
            'City',
            'State',
            'Country',
            'Zip Code',
            'Phone Number',
            'Mobile Number',
            'Type'
        ]);
        for (const row of auditData) {
            data.push([
                row.UpdateType,
                this.dateFormatterService.formatDateTimeUTC(row.ModifiedDate),
                this.userNameService.toFullName(row.ModifiedBy),
                row.Name,
                row.Identifier,
                row.AccountNumber,
                row.EmailAddress,
                row.Address,
                row.AddressQualifier,
                row.City,
                row.StateName,
                row.Country,
                row.PostalCode,
                row.Phone,
                row.MobilePhone,
                row.InstitutionType
            ]);
        }


        return data;
    }

    // Format the phone numbers and postal codes for CSV
    // phoneNumberFormatter = (value: any) => {
    //    if (value.length > 0) {
    //        let formatValue = '(' + value.slice(0, 3) + ') ' + value.slice(3, 6) + '-'
    //            + value.slice(6, 11);
    //        return formatValue;
    //    } else {
    //        return value;
    //    }
    // }
    // postalCodeFormatter = (value: any) => {
    //    if (value.length > 5) {
    //        let formatValue = value.slice(0, 5) + '-' + value.slice(5, 9);
    //        return formatValue;
    //    }
    //    return value;
    // }
}

import {
    Injectable,
} from '@angular/core';

import {
    NgbModal,
} from '@ng-bootstrap/ng-bootstrap';

import { AddProtocolComponent } from './add-protocol.component';
import { TaskType } from '../models';

/*
* Service to view the add-protocol modal.
*/
@Injectable()
export class ViewAddProtocolComponentService {

    readonly EMPTY_RESULT: any = null;

    constructor(
        private modalService: NgbModal
    ) {
        //
    }

    openComponent(taskType?: TaskType): Promise<any> {
        const modalRef = this.modalService.open(AddProtocolComponent);
        const component = modalRef.componentInstance as AddProtocolComponent;
        if (taskType) {
            component.taskType = taskType;
        }

        return modalRef.result.then((result) => {
            // Closed
            if (result) {
                return result;
            } else {
                return this.EMPTY_RESULT;
            }
        }, (reason) => {
            // Dismissed
            return this.EMPTY_RESULT;
        });
    }
}

import { Observable } from 'rxjs';
import { Inject, Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { ENV_CONFIG, EnvironmentConfig } from '@config';

/**
 * The interceptor checks the URL and if it is relative (i.e. doesn't contain `http://`
 * or `https://` at the beginning), then it adds the ApiServiceBaseUri address of the backend.
 * Allows us to construct HTTP queries without worrying about the backend domain name.
 * @example ```
 *   // apiServiceBaseUri = 'https://dev-climb-data.azurewebsites.net'
 *
 *   ＠Injectable()
 *   export class SomeService {
 *     constructor(private httpClient: HttpClient) {}
 *
 *     query(): Observable<ResponseData> {
 *       // this transforms into: https://dev-climb-data.azurewebsites.net/api/some/path
 *       return this.httpClient.post('/api/some/path', options);
 *     }
 *
 *     otherQuery(): Observable<ResponseData> {
 *       // this won't transform
 *       return this.httpClient.post('https://domain.com/some/path', options);
 *     }
 *   }
 * ```
 */
@Injectable()
export class UrlInterceptor implements HttpInterceptor {
  constructor(
    @Inject(ENV_CONFIG) private config: EnvironmentConfig,
  ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const { apiServiceBaseUri } = this.config;
    const urlReq = request.clone({
      url: request.url.startsWith('http') ? request.url : apiServiceBaseUri + request.url.trim(),
    });

    return next.handle(urlReq);
  }
}

import { cv_VariablePhraseType } from './cv-variable-phrase-type.interface';
import { Job } from './job.interface';

export interface JobVariablePhrase {
    C_JobVariablePhrase_key: number;
    C_Job_key: number;
    C_VariablePhraseType_key: number;
    VariablePhraseText: string;
    CreatedBy: string;
    DateCreated: Date;
    ModifiedBy: string;
    DateModified: Date;
    Job: Job;
    cv_VariablePhraseType: cv_VariablePhraseType;
}

import { TaskDeleteLogicHelper } from '../services/delete-logic';
import { VocabularyService } from './../vocabularies/vocabulary.service';
import { Injectable } from '@angular/core';
import {
    EntityQuery,
    FilterQueryOp,
    Predicate,
    QueryResult,
} from 'breeze-client';

import {
    getSafeProp,
    notEmpty
} from '../common/util';
import { getDateRangePredicates, getIsFosterPredicate } from '../services/queries';

import { DataManagerService } from '../services/data-manager.service';
import { QueryDef } from '../services/query-def';
import { BaseEntityService } from '../services/base-entity.service';
import { NoteService } from '../common/notes';
import { Animal, Birth, BirthMaterial, Entity, TaskBirth, TaskInstance } from '../common/types';

@Injectable()
export class BirthService extends BaseEntityService {

    readonly ENTITY_TYPE = 'Births';
    readonly ENTITY_NAME = 'Birth';

    constructor(
        private dataManager: DataManagerService,
        private noteService: NoteService,
        private vocabularyService: VocabularyService
    ) {
        super();
    }


    getBirths(queryDef: QueryDef): Promise<QueryResult> {
        let query = this.buildDefaultQuery(this.ENTITY_TYPE, queryDef);

        this.ensureDefExpanded(queryDef, 'Mating.MaterialPool');
        this.ensureDefExpanded(queryDef, 'Mating.Line.cv_Taxon');
        this.ensureDefExpanded(queryDef, 'cv_BirthStatus');
        this.ensureDefExpanded(queryDef, 'MaterialPool');
        this.ensureDefExpanded(queryDef, 'Note');
        query = query.expand(queryDef.expands.join(','));

        let predicates: Predicate[] = [];
        if (queryDef.filter) {
            predicates = predicates.concat(this.buildPredicates(queryDef.filter));

            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed) as Promise<QueryResult>;
    }

    private buildPredicates(filter: any): Predicate[] {
        let predicates: Predicate[] = [];

        if (!filter) {
            return predicates;
        }

        if (notEmpty(filter.birthKeys)) {
            predicates.push(Predicate.create('C_Birth_key', 'in', filter.birthKeys));
        }
        if (filter.BirthID) {
            predicates.push(Predicate.create('BirthID', FilterQueryOp.Contains, { value: filter.BirthID }));
        }

        if (notEmpty(filter.housingUnits)) {
            const materialPoolKeys = filter.housingUnits.map((housingUnit: any) => {
                return housingUnit.C_MaterialPool_key;
            });

            predicates.push(Predicate.create(
                'C_MaterialPool_key', 'in', materialPoolKeys
            ));
        }

        if (filter.MatingID) {
            predicates.push(Predicate.create('Mating.MatingID', FilterQueryOp.Contains, { value: filter.MatingID }));
        }
        if (notEmpty(filter.C_BirthStatus_keys)) {
            predicates.push(Predicate.create('C_BirthStatus_key', 'in', filter.C_BirthStatus_keys));
        }

        if (notEmpty(filter.constructs)) {
            const constructKeys = filter.constructs.map((construct: any) => {
                return construct.ConstructKey;
            });

            const subPredicate = Predicate.create(
                'Material.Sample.SampleConstruct', 'any',
                'C_Construct_key', 'in', constructKeys
            );

            predicates.push(Predicate.create(
                'Mating.MaterialPool.MaterialPoolMaterial', 'any',
                subPredicate
            ));
        }

        /* Deprecated - Sprint 79 - replaced by filter.lines */
        if (filter.LineName) {
            predicates.push(Predicate.create('Mating.Line.LineName', 'eq', filter.LineName));
        }
        if (notEmpty(filter.lines)) {
            const lineKeys = filter.lines.map((line: any) => {
                return line.LineKey;
            });

            predicates.push(Predicate.create(
                'Mating.C_Line_key', 'in', lineKeys
            ));
        }

        if (filter.DateWeanStart || filter.DateWeanEnd) {
            const datePredicates: Predicate[] = getDateRangePredicates(
                'DateWean',
                filter.DateWeanStart,
                filter.DateWeanEnd
            );
            if (notEmpty(datePredicates)) {
                predicates = predicates.concat(datePredicates);
            }
        }

        if (filter.DateMatingStart || filter.DateMatingEnd) {
            const datePredicates: Predicate[] = getDateRangePredicates(
                'Mating.DateMating',
                filter.DateMatingStart,
                filter.DateMatingEnd
            );
            if (notEmpty(datePredicates)) {
                predicates = predicates.concat(datePredicates);
            }
        }

        if (filter.DateBornStart || filter.DateBornEnd) {
            const datePredicates: Predicate[] = getDateRangePredicates(
                'DateBorn',
                filter.DateBornStart,
                filter.DateBornEnd
            );
            if (notEmpty(datePredicates)) {
                predicates = predicates.concat(datePredicates);
            }
        }

        if (filter.CreatedBy) {
            predicates.push(Predicate.create('CreatedBy', 'eq', filter.CreatedBy));
        }
        if (filter.ParentName) {
            predicates.push(Predicate.create(
                'BirthMaterial', FilterQueryOp.Any, 'Material.Animal.AnimalName',
                FilterQueryOp.Contains, { value: filter.ParentName })
            );
        }

        if (filter.ProgenyName) {
            predicates.push(Predicate.create(
                'Animal', FilterQueryOp.Any, 'AnimalName', FilterQueryOp.Contains, { value: filter.ProgenyName },
            ));
        }

        if (filter.C_Taxon_key) {
            predicates.push(Predicate.create(
                'Mating.Line.cv_Taxon.C_Taxon_key', 'eq', filter.C_Taxon_key)
            );
        }

        if (filter.location) {
            predicates.push(Predicate.create(
                'Mating.MaterialPool.CurrentLocationPath', FilterQueryOp.Contains, { value: filter.location })
            );
        }


        // handle workspace filters
        if ('animal-filter' in filter) {
            predicates.push(
                Predicate.create('Animal', 'any', 'C_Material_key', 'in', filter['animal-filter'])
            );
        }
        if (filter.IsFoster) {
            const isFosterPredicate: Predicate = getIsFosterPredicate(filter.IsFoster);
            predicates.push(isFosterPredicate);
        }

        if (notEmpty(filter.fosterHousingUnits)) {
            const fosterMaterialPoolKeys = filter.fosterHousingUnits.map((fosterHousingUnit: any) => {
                return fosterHousingUnit.C_MaterialPool_key;
            });

            predicates.push(Predicate.create(
                'C_FosterMaterialPool_key', 'in', fosterMaterialPoolKeys
            ));
        }

        return predicates;
    }

    getBirth(birthKey: number, expands?: string[]): Promise<Entity<Birth>> {
        if (!expands) {
            expands = [];
        }

        this.ensureExpanded(expands, 'BirthMaterial');
        this.ensureExpanded(expands, 'Mating.MaterialPool');
        this.ensureExpanded(expands, 'Mating.Line.cv_Taxon');
        this.ensureExpanded(expands, 'cv_BirthStatus');

        const query = EntityQuery.from(this.ENTITY_TYPE)
            .expand(expands.join(','))
            .where('C_Birth_key', '==', birthKey);

        return this.dataManager.returnSingleQueryResult(query);
    }

    getAnimals(birthKey: number): Promise<Entity<Animal>[]> {
        const expands = [
            'Genotype',
            'Genotype.cv_GenotypeAssay',
            'Genotype.cv_GenotypeSymbol',
            'Material',
            'Material.MaterialPoolMaterial.MaterialPool.MaterialLocation.LocationPosition'
        ];

        const query = EntityQuery.from('Animals')
            .where('C_Birth_key', '==', birthKey)
            .expand(expands.join(','));

        return this.dataManager.returnQueryResults(query);
    }

    getBirthTasks(birthKey: number, extraExpands?: string[]): Promise<Entity<TaskInstance>[]> {
        const predicates = [
            new Predicate('TaskBirth', 'any', 'C_Birth_key', '==', birthKey),
            new Predicate('WorkflowTask.cv_TaskType.TaskType', 'eq', '"Birth"')
        ];

        const query = EntityQuery.from('TaskInstances')
            .where(Predicate.and(predicates))
            .orderBy('ProtocolTask.SortOrder');

        let expandClauses = [
            'WorkflowTask',
            'ProtocolTask.Protocol',
            'ProtocolInstance.Protocol',
            'TaskInput.Input',
            'TaskBirth'
        ];

        if (notEmpty(extraExpands)) {
            expandClauses = expandClauses.concat(extraExpands);
        }

        let tasks: any[] = [];
        return this.dataManager.returnQueryResults(query).then((results) => {
            tasks = results;
            const promises: Promise<any>[] = [
                this.vocabularyService.ensureCVLoaded('cv_TimeUnits'),
                this.vocabularyService.ensureCVLoaded('cv_TimeRelations'),
                this.vocabularyService.ensureCVLoaded('cv_DataTypes'),
                this.vocabularyService.ensureCVLoaded('cv_TaskTypes')
            ];

            return Promise.all(promises);
        }).then(() => {
            return this.dataManager.ensureRelationships(tasks, expandClauses);
        }).then(() => {
            return tasks;
        });
    }

    getAnimalsForBirths(births: Entity<Birth>[]): Promise<Entity<Animal>[]> {
        const birthKeys: number[] = births.map((birth) => {
            return birth.C_Birth_key;
        });

        const expands = [
            'Material'
        ];

        const query = EntityQuery.from('Animals')
            .where('C_Birth_key', 'in', birthKeys)
            .expand(expands.join(','));

        return this.dataManager.returnQueryResults(query);
    }


    // BirthMaterials
    getBirthMaterialsByBirthKey(birthKey: number): Promise<Entity<BirthMaterial>[]> {
        const query = EntityQuery.from('BirthMaterials')
            .where('C_Birth_key', '==', birthKey);

        return this.dataManager.returnQueryResults(query);
    }

    createBirthMaterial(initialValues: any): Entity<BirthMaterial> {
        return this.dataManager.createEntity('BirthMaterial', initialValues);
    }

    deleteBirthMaterial(birthMaterial: any): void {
        this.dataManager.deleteEntity(birthMaterial);
    }

    resetBirthMaterialsForBirth(birth: any): Promise<void> {
        // Ensure birth.BirthMaterial are in memory
        return this.getBirthMaterialsByBirthKey(birth.C_Birth_key).then(() => {
            this.deleteBirthMaterialsForBirth(birth);
        }).then(() => {
            this.addDefaultBirthMaterialsForBirth(birth);
        });
    }

    private deleteBirthMaterialsForBirth(birth: any) {
        if (birth.BirthMaterial) {
            for (const birthMaterial of birth.BirthMaterial) {
                this.deleteBirthMaterial(birthMaterial);
            }
        }
    }

    /**
     * Adds BirthMaterials to a birth for all its
     *   mating MaterialPoolMaterials with no DateOut.
     *
     * @param birth
     */
    private addDefaultBirthMaterialsForBirth(birth: any) {
        const materialPoolMaterials = getSafeProp(
            birth, 'Mating.MaterialPool.MaterialPoolMaterial'
        );

        if (materialPoolMaterials) {
            for (const materialPoolMaterial of materialPoolMaterials) {
                if (!materialPoolMaterial.DateOut) {
                    const initialValues = {
                        C_Birth_key: birth.C_Birth_key,
                        C_Material_key: materialPoolMaterial.C_Material_key
                    };
                    this.createBirthMaterial(initialValues);
                }
            }
        }
    }



    createBirth(): Entity<Birth> {
        const initialValues = { DateCreated: new Date() };
        return this.dataManager.createEntity(this.ENTITY_NAME, initialValues);
    }

    createTaskBirth(
        birthKey: number,
        taskInstanceKey: number,
        sequence: number
    ): Promise<Entity<TaskBirth>> {
        const initialValues = {
            C_Birth_key: birthKey,
            C_TaskInstance_key: taskInstanceKey,
            Sequence: sequence
        };

        return this.dataManager.createEntity('TaskBirth', initialValues);
    }

    cancelBirth(birth: any) {
        if (!birth) {
            return;
        }

        this._cancelBirthEdits(birth);
    }

    private _cancelBirthEdits(birth: any) {
        this.dataManager.rejectEntityAndRelatedPropertyChanges(birth);
        const taskBirths = this.dataManager.rejectChangesToEntityByFilter(
            'TaskBirth', (item: any) => {
                return item.C_Birth_key === birth.C_Birth_key;
            }
        );
        for (const taskBirth of taskBirths) {
            const taskInstances = this.dataManager.rejectChangesToEntityByFilter(
                'TaskInstance', (item: any) => {
                    return item.C_TaskInstance_key === taskBirth.C_TaskInstance_key;
                }
            );
            for (const taskInstance of taskInstances) {
                this.dataManager.rejectChangesToEntityByFilter(
                    'TaskInput', (item: any) => {
                        return item.C_TaskInstance_key === taskInstance.C_TaskInstance_key;
                    }
                );

            }
        }
        const animals = getSafeProp(birth, 'birthAnimals');
        if (animals) {
            for (const animal of animals) {

                this.dataManager.rejectChangesToEntityByFilter(
                    'TaxonCharacteristicInstance', (item: any) => {
                        return item.C_Material_key ===
                            animal.C_Material_key;
                    }
                );

                // Nested MaterialPoolMaterials, MaterialPools, and MaterialLocations
                const materialPoolMaterials = animal.Material.MaterialPoolMaterial;
                for (const materialPoolMaterial of materialPoolMaterials) {
                    this.dataManager.rejectChangesToEntityByFilter(
                        'MaterialLocation', (item: any) => {
                            return item.C_MaterialPool_key ===
                                materialPoolMaterial.C_MaterialPool_key;
                        }
                    );

                    this.dataManager.rejectChangesToEntityByFilter(
                        'MaterialPool', (item: any) => {
                            return item.C_MaterialPool_key ===
                                materialPoolMaterial.C_MaterialPool_key;
                        }
                    );

                    this.dataManager.rejectEntityAndRelatedPropertyChanges(materialPoolMaterial);
                }

                this.dataManager.rejectEntityAndRelatedPropertyChanges(animal);

                this.dataManager.rejectChangesToEntityByFilter(
                    'Material', (item: any) => {
                        return item.C_Material_key ===
                            animal.C_Material_key;
                    }
                );
            }
        }

        this.dataManager.rejectChangesToEntityByFilter(
            'BirthMaterial', (item: any) => {
                return item.C_Birth_key === birth.C_Birth_key;
            }
        );
    }

    deleteBirth(birth: any): Promise<void> {
        const birthKey: number = birth.C_Birth_key;
        // ensure some data is loaded so we can delete foreign keys
        const promises: Promise<any>[] = [
            this.getBirthMaterialsByBirthKey(birthKey),
            this.noteService.getNotes("C_Birth_key", birthKey)
        ];
        return Promise.all(promises).then(() => {
            while (notEmpty(birth.BirthMaterial)) {
                this.dataManager.deleteEntity(birth.BirthMaterial[0]);
            }

            while (notEmpty(birth.Note)) {
                this.dataManager.deleteEntity(birth.Note[0]);
            }

            while (notEmpty(birth.TaskBirth)) {
                const taskBirth = birth.TaskBirth[0];
                const task = taskBirth.TaskInstance;

                this.dataManager.deleteEntity(taskBirth);

                const taskDeleter = new TaskDeleteLogicHelper(this.dataManager);
                taskDeleter.deleteTask(task);
            }

            this.dataManager.deleteEntity(birth);
        });
    }

    ensureTasksLoaded(births: any[]): Promise<any[]> {
        const expands = [
            'TaskBirth.TaskInstance.ProtocolInstance',
            'TaskBirth.TaskInstance.ProtocolTask',
        ];
        return this.dataManager.ensureRelationships(births, expands);
    }

    async ensureVisibleColumnsDataLoaded(births: any[], visibleColumns: string[]): Promise<void> {
        const redundantFields = [
            'MaterialPool.MaterialPoolID', // Foster Housing ID
        ];
        const filteredVisibleColumns = visibleColumns.filter((column) => !redundantFields.includes(column));
        const expands = this.generateExpandsFromVisibleColumns(births[0], filteredVisibleColumns);
        return this.dataManager.ensureRelationships(births, expands);
    }
}

<!-- Templates -->
<ng-template #outputStatCountRow>
    <tr>
        <th [attr.colspan]="readonly ?
            (columnsTotal - columnsReadWrite - maxOutputs)
            : (columnsTotal - maxOutputs)"
            scope="row">
            Count
        </th>

        <ng-container *ngFor="let outputValues of [output1Values, output2Values, output3Values];">
            <td class="num-cell"
                *ngIf="!outputValues || outputValues?.length === 0">
            </td>
            <td class="num-cell"
                *ngIf="outputValues.length > 0 && outputValues.length === cohort.CohortMaterial?.length">
                {{outputValues.length}}/{{cohort.CohortMaterial?.length}}
            </td>
            <td class="num-cell table-warning"
                *ngIf="outputValues.length > 0 && outputValues?.length !== cohort.CohortMaterial?.length">
                {{outputValues.length}}/{{cohort.CohortMaterial?.length}}*
            </td>
        </ng-container>
    </tr>
</ng-template>

<ng-template #outputStatRow
             let-statName="statName">
    <tr>
        <th [attr.colspan]="readonly ?
            (columnsTotal - columnsReadWrite - maxOutputs)
            : (columnsTotal - maxOutputs)"
            scope="row">
            {{statName}}
        </th>
        <ng-container *ngFor="let outputValues of outputValues();">
            <td>
                <span *ngIf="!outputValues || outputValues?.length === 0">
                </span>
                <span class="num-cell" *ngIf="outputValues && outputValues.length > 0 && statName==='Average'">
                    {{average(outputValues) | safeNumber:'1.2-2'}}
                </span>
                <span class="num-cell" *ngIf="outputValues && outputValues.length > 0 && statName==='Median'">
                    {{median(outputValues) | safeNumber:'1.2-2'}}
                </span>
                <span class="num-cell" *ngIf="outputValues && outputValues.length > 0 && statName==='SD (σ)'">
                    {{standardDeviation(outputValues) | safeNumber:'1.2-2'}}
                </span>
            </td>
        </ng-container>
    </tr>
</ng-template>

<ng-template #nullWarningRow>
    <tr class="table-warning">
        <td [attr.colspan]="readonly ? columnsTotal - columnsReadWrite : columnsTotal"
            class="text-right">
            *Excludes null output values for some animals.
        </td>
    </tr>
</ng-template>

<!-- Rows -->
<ng-template [ngTemplateOutlet]="outputStatCountRow"></ng-template>
<ng-template [ngTemplateOutlet]="outputStatRow"
             [ngTemplateOutletContext]="{statName: 'Average'}"></ng-template>
<ng-template [ngTemplateOutlet]="outputStatRow"
             [ngTemplateOutletContext]="{statName: 'Median'}"></ng-template>
<ng-template [ngTemplateOutlet]="outputStatRow"
             [ngTemplateOutletContext]="{statName: 'SD (σ)'}"></ng-template>
<ng-template [ngTemplateOutlet]="nullWarningRow"
             *ngIf="hasNullOutputValues"></ng-template>


import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PortalModule } from '@angular/cdk/portal';
import { BasePipesModule } from '@common/pipes/base-pipes.module';
import { DialogContainerComponent } from '@common/dialog/dialog-container/dialog-container.component';
import { DialogService } from '@common/dialog/dialog.service';
import { DialogService as DeprecatedDialogService } from '@common/dialog/deprecated/dialog.service';
import { ModalDialogComponent as DeprecatedModalDialogComponent } from '@common/dialog/deprecated';

@NgModule({
    imports: [
        CommonModule,
        PortalModule,
        BasePipesModule, // remove with the deprecated dialog
    ],
    declarations: [
        DialogContainerComponent,
        DeprecatedModalDialogComponent,
    ],
    providers: [
        DialogService,
        DeprecatedDialogService,
    ],
    exports: [
        DialogContainerComponent,
        DeprecatedModalDialogComponent,
    ],
})
export class DialogModule { }

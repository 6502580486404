import { Observable, Subject, Subscription } from 'rxjs';
import { OverlayRef } from '@angular/cdk/overlay';
import { DialogConfig } from '@common/dialog/dialog-config';
import { ESCAPE } from '@angular/cdk/keycodes';

export class DialogRef<R = unknown, D = unknown, C = unknown> {
    private readonly backdropClick: Observable<MouseEvent>;
    private readonly keydownEvents: Observable<KeyboardEvent>;
    private readonly outsidePointerEvents: Observable<MouseEvent>;
    private closedSource = new Subject<R>();
    private detachSubscription: Subscription;

    componentInstance: C | null;
    closed$: Observable<R> = this.closedSource.asObservable();

    constructor(
        private readonly overlayRef: OverlayRef,
        private readonly config: DialogConfig<D>
    ) {
        this.backdropClick = overlayRef.backdropClick();
        this.keydownEvents = overlayRef.keydownEvents();
        this.outsidePointerEvents = overlayRef.outsidePointerEvents();

        this.keydownEvents.subscribe(event => {
            if (event.keyCode === ESCAPE && !config.disableClose) {
                event.preventDefault();
                this.close();
            }
        });

        this.backdropClick.subscribe(() => {
            if (!config.disableClose) {
                this.close();
            }
        });

        this.detachSubscription = overlayRef.detachments().subscribe(() => {
            this.close();
        });
    }

    close(result?: R): void {
        this.detachSubscription.unsubscribe();
        this.overlayRef.dispose();
        this.closedSource.next(result);
        this.closedSource.complete();
        this.componentInstance = null;
    }
}

import { Pipe, PipeTransform } from '@angular/core';

import { notEmpty } from '../../common/util';

/**
 * Returns PlateMaterials are Position x,y position
 */
@Pipe({ 
    name: 'platePositionFilter',
    pure: false
})
export class PlatePositionFilterPipe implements PipeTransform {

    transform(items: any[], xPos: number, yPos: number): any[] {
        const filtered = [];

        if (notEmpty(items)) {
            for (const item of items) {
                
                if (!item.PlatePosition) {
                    continue;
                }

                if (item.PlatePosition.XPosition === xPos &&
                    item.PlatePosition.YPosition === yPos
                ) {
                    filtered.push(item);
                }
            }
        }

        return filtered;
    }
}

import { Component, ComponentRef, ViewChild } from '@angular/core';
import { CdkPortalOutlet, ComponentPortal } from '@angular/cdk/portal';

@Component({
    selector: 'climb-dialog-container',
    templateUrl: './dialog-container.component.html',
    styleUrls: ['./dialog-container.component.scss']
})
export class DialogContainerComponent {
    @ViewChild(CdkPortalOutlet, {static: true}) portalOutlet: CdkPortalOutlet;

    attachComponentPortal<T>(portal: ComponentPortal<T>): ComponentRef<T> {
        return this.portalOutlet.attachComponentPortal(portal);
    }
}

import { LocationSelectComponent } from './location-select.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { ClimbCommonModule } from '../common/climb-common.module';
import { ClimbServicesModule } from '../services/climb-services.module';

import { LocationChooserComponent } from './location-chooser.component';
import { LocationHistoryComponent } from './location-history.component';
import { LocationPathComponent } from './location-path.component';
import { LocationMultiselectComponent } from './location-multiselect.component';
import { LocationService } from './location.service';
import { LocationPositionSaveService } from './services/locationPosition-save.service';
import { LocationPositionStateService } from './services/locationPosition-state.service';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ClimbCommonModule,
        ClimbServicesModule
    ],
    declarations: [
        LocationChooserComponent,
        LocationHistoryComponent,
        LocationPathComponent,
        LocationSelectComponent,
        LocationMultiselectComponent
    ],
    exports: [
        LocationChooserComponent,
        LocationHistoryComponent,
        LocationPathComponent,
        LocationSelectComponent,
        LocationMultiselectComponent
    ],
    providers: [
        LocationService,
        LocationPositionSaveService,
        LocationPositionStateService,
    ]
})
export class LocationsSharedModule { }

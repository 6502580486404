<audit-filter-templates #templates [(filter)]="filter"></audit-filter-templates>

<form role="form" class="form-label-right">
    <div class="modal-header justify-content-center">
        <h4 class="modal-title">Audit Filter</h4>
    </div>

    <div class="modal-body">
        <div class="form-group row">
            <label class="col-md-4 col-form-label">Action Type</label>
            <div class="col-md-8">
                <climb-multi-select [(model)]="filter.UpdateTypes"
                                    [values]="updateTypes"
                                    [keyProperty]="'Name'"
                                    [valueProperty]="'Name'"
                                    [placeholder]="'Select Types...'"></climb-multi-select>
            </div>
        </div>

        <ng-container *ngFor="let filter of templates.filters">
            <ng-template [ngTemplateOutlet]="filter.template" [ngTemplateOutletContext]="filter.context || {}">
            </ng-template>
        </ng-container>

        <div class="form-group row">
            <label class="col-md-4 col-form-label">Modified By</label>
            <div class="col-md-8">
                <user-multiselect [(model)]="filter.ModifiedBy" placeholder="Modified by name..."></user-multiselect>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-4 col-form-label">Modified Date Range</label>
            <div class="col-md-4">
                <climb-ngb-date #dateControl="ngModel"
                                name="Modified Date Start"
                                [(ngModel)]="filter.ModifiedDateStart"
                                placeholder="From&hellip;"
                                ngDefaultControl></climb-ngb-date>
            </div>
            <div class="col-md-4">
                <climb-ngb-date #dateControl="ngModel"
                                name="Modified Date End"
                                [(ngModel)]="filter.ModifiedDateEnd"
                                placeholder="To&hellip;"
                                ngDefaultControl></climb-ngb-date>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-4 col-form-label">Created By</label>
            <div class="col-md-8">
                <user-multiselect [(model)]="filter.CreatedBy" placeholder="Created by name..."></user-multiselect>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-4 col-form-label">Created Date Range</label>
            <div class="col-md-4">
                <climb-ngb-date #dateControl="ngModel"
                                name="Created Date Start"
                                [(ngModel)]="filter.CreatedDateStart"
                                placeholder="From&hellip;"
                                ngDefaultControl></climb-ngb-date>
            </div>
            <div class="col-md-4">
                <climb-ngb-date #dateControl="ngModel"
                                name="Created Date End"
                                [(ngModel)]="filter.CreatedDateEnd"
                                placeholder="To&hellip;"
                                ngDefaultControl></climb-ngb-date>
            </div>
        </div>

    </div><!-- /.modal-body -->

    <div class="modal-footer">
        <span class="btn-set">
            <button type="submit"
                    class="btn btn-fw-wide btn-lg btn-primary"
                    (click)="filterClicked($event)">
                Filter
            </button>
            <button data-automation-id="clear-filter-button"
                    type="button"
                    class="btn btn-fw-wide btn-lg btn-secondary"
                    (click)="clearClicked()">
                Clear Filter
            </button>
        </span>
        <span class="btn-set">
            <button type="button"
                    class="btn btn-lg btn-secondary"
                    (click)="closeClicked()">
                Close
            </button>
        </span>
    </div>
</form>

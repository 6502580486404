import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ClimbCommonModule } from '@common/climb-common.module';
import { ClimbServicesModule } from '@services/climb-services.module';

import { VersionsService } from './services/versions.service';
import { VersionsViewComponent } from './components/versions-view.component';

@NgModule({
    imports: [
        CommonModule,
        ClimbCommonModule,
        ClimbServicesModule,
    ],
    declarations: [
        VersionsViewComponent
    ],
    exports: [
        VersionsViewComponent
    ],
    providers: [
        VersionsService,
    ]
})
export class VersionsModule { }

import {
    Component,
    Input,
    Output,
    EventEmitter,
    OnInit,
    OnChanges,
    ViewChild,
    OnDestroy,
    SimpleChanges
} from '@angular/core';
import { DxDateBoxComponent } from 'devextreme-angular';

import { LocalStorageKey } from '../../../config';
import { LocalStorageService } from '../../../services/local-storage.service';
import { DateTime } from 'luxon';
import { DateFormatterService } from '@common/util/date-time-formatting';

const localStorageService = new LocalStorageService();
@Component({
    selector: 'climb-timepicker',
    templateUrl: './climb-timepicker.component.html',
    styleUrls: ['./climb-timepicker.component.scss'],
})
export class ClimbTimepickerComponent implements OnInit, OnChanges, OnDestroy {
    model: string;
    @Input() set time(time: string) {
        this.model = time;
        this.date = this.modelToDate(this.model);
    } 
    @Input() hide: boolean;
    @Input() disabled: boolean;
    @Input() extraClasses: string;
    @Input() appendToId: string;
    @Input() timeFormat: string;
    @Input() isGLP: boolean;
    @Input() externalDate: Date;

    @Output() selectTime: EventEmitter<string> = new EventEmitter<string>();
    @Output() onOpened: EventEmitter<void> = new EventEmitter<void>();
    @Output() onClosed: EventEmitter<void> = new EventEmitter<void>();

    @ViewChild(DxDateBoxComponent) timepickerInstance: DxDateBoxComponent;
    date: Date | number | string;
    timezone: any;
    buttonOptions: any[];

    constructor(private _dateFormatterService: DateFormatterService) {
        // do nothing
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.isGLP) {
            this.initTimePickerFormat();
        }
    }

    ngOnInit() {
        if (this.isGLP === undefined) {
            const flags = localStorageService.get(LocalStorageKey.FEATURE_FLAGS);
            const flag = flags ? flags.IsGLP : null;
            this.isGLP = (flag && flag.IsActive && flag.Value.toLowerCase() === 'true');
        }

        this.timezone = localStorageService.get(LocalStorageKey.WORKGROUP_TIMEZONE_INFO);

        this.initTimePickerFormat();

        this.buttonOptions = [
            {
                name: "showtimeoptions",
                options: {
                    icon: "chevrondown",
                    stylingMode: 'text',
                    onClick: () => {
                        this.timepickerInstance.opened = true;
                    }
                }
            }
        ];

        document.addEventListener('climb.timepicker.close', this.onCloseTimePicker);
    }

    ngOnDestroy(): void {
        document.removeEventListener('climb.timepicker.close', this.onCloseTimePicker);
    }

    private onCloseTimePicker = (event: CustomEvent<{target: HTMLElement}>) => {
        if (event.detail.target.className !== 'dx-gesture-cover') {
            this.onClosed.emit();
            this.timepickerInstance.opened = false;
        }
    }

    initTimePickerFormat() {
        if (this.isGLP) {
            if (this.externalDate) {
                this.timeFormat = "HH:mm UTC" + DateTime.fromJSDate(this.externalDate).toFormat("ZZ");
            } else {
                this.timeFormat = "HH:mm UTC" + DateTime.now().toFormat("ZZ");
            }
        } else {
            this.timeFormat = "hh:mm a";
        }
    }

    modelToDate(model: string): Date {
        let splitTime;
        if (!this.model) {
            return undefined;
        }
        if (this.isGLP) {
            const utcSplit = model.split("UTC");
            if (utcSplit.length > 0) {
                splitTime = utcSplit[0].split(":");
            }
        } else {
            splitTime = model.split(":");
            if (model.includes("PM") && splitTime[0] !== '12') {
                splitTime[0] = (parseInt(splitTime[0], 10) + 12).toString();
            }
            if (splitTime[0] === '12' && model.includes("AM")) {
                splitTime[0] = '0';
            }
        }

        const date = new Date();
        date.setHours(parseInt(splitTime[0], 10), parseInt(splitTime[1], 10));
        return date;
    }

    formatTime(val: Date) {
        return DateTime.fromJSDate(val).toFormat(this._dateFormatterService.resolveTimeFormat());
    }

    onSelectTime(event: any) {
        // only emit if time change was done manually and not programatically
        if (event.event !== undefined) {
            event.event.stopPropagation();
            this.selectTime.emit(this.formatTime(new Date(this.date)));
        }
    }

}

import type { Birth } from './birth.interface';
import type { Cohort } from './cohort.interface';
import type { Construct } from './construct.interface';
import type { Institution } from './institution.interface';
import type { Job } from './job.interface';
import type { Line } from './line.interface';
import type { Material } from './material.interface';
import type { MaterialPool } from './material-pool.interface';
import type { Mating } from './mating.interface';
import type { Protocol } from './protocol.interface';
import type { Study } from './study.interface';
import type { TaskInstance } from './task-instance.interface';
import type { WorkflowTask } from './workflow-task.interface';

export interface Note {
    Birth: Birth;
    C_Birth_key: number | null;
    C_Cohort_key: number | null;
    C_Construct_key: number | null;
    C_Institution_key: number | null;
    C_Job_key: number | null;
    C_Line_key: number | null;
    C_MaterialPool_key: number | null;
    C_Material_key: number | null;
    C_Note_key: number;
    C_Protocol_key: number | null;
    C_Study_key: number | null;
    C_TaskInstance_key: number | null;
    C_WorkflowTask_key: number | null;
    C_Workgroup_key: number | null;
    Cohort: Cohort;
    Construct: Construct;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    Institution: Institution;
    Job: Job;
    Line: Line;
    Material: Material;
    MaterialPool: MaterialPool;
    Mating: Mating;
    ModifiedBy: string;
    NoteText: string;
    Protocol: Protocol;
    Study: Study;
    TaskInstance: TaskInstance;
    Version: number;
    WorkflowTask: WorkflowTask;
}

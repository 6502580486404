import type { cv_IACUCProtocol } from './cv-iacuc-protocol.interface';
import type { cv_StandardPhrase } from './cv-standard-phrase.interface';

export interface cv_StandardPhraseIACUCProtocol {
    C_IACUCProtocol_key: number;
    C_StandardPhraseIACUCProtocol_key: number;
    C_StandardPhrase_key: number;
    C_Workgroup_key: number;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    ModifiedBy: string;
    Version: number;
    cv_IACUCProtocol: cv_IACUCProtocol;
    cv_StandardPhrase: cv_StandardPhrase;
}

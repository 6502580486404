import type { AlertDefinition } from './alert-definition.interface';
import type { DeviceModelOutput } from './device-model-output.interface';

export interface AlertDefinitionParameter {
    AlertDefinition: AlertDefinition;
    C_AlertDefinitionParameter_key: number;
    C_AlertDefinition_key: number;
    C_DeviceModel_key: string;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    DeviceModelOutput: DeviceModelOutput;
    ModifiedBy: string;
    OutputName: string;
    ParameterName: string;
    ParameterValue: string;
    Version: number;
}

import {
    Component,
    Input,
    OnInit,


} from '@angular/core';

import {
    NgbActiveModal
} from '@ng-bootstrap/ng-bootstrap';
import { randomId } from '../common/util';

@Component({
    selector: 'monitoring-service-subscription-parameter-modal',
    templateUrl: './monitoring-service-subscription-parameter-modal.component.html',
    styles: [`
        .label-service-name {
            cursor: pointer;
        }
        .parameter-control {
            margin-left: 4px;
            display: inline-block;
        }
    `]
})
export class MonitoringServiceSubscriptionParameterComponent implements OnInit {
    @Input() service: any;
    @Input() param: any;
    @Input() resources: any[];

    domIdAddition = '';
    originalValue = "";
    originalValues: number[] = [];

    readonly LOG_TAG = 'MonitoringServiceSubscriptionParameterModal';

    constructor(
        private activeModal: NgbActiveModal,
    ) {
        //
    }

    ngOnInit() {
        this.domIdAddition = randomId() + '_msspm';
        this.originalValue = '';
        this.originalValues = [];

        if (this.param && this.param.ParameterValue) {
            this.originalValue = this.param.ParameterValue;
        }
        if (this.param && this.param.values && Array.isArray(this.param.values)) {
            this.originalValues = [...this.param.values];
        }
    }

    onCancel(): void {
        if (this.param) {
            this.param.ParameterValue = this.originalValue;
            this.param.values = this.originalValues;
        }
        this.activeModal.close();
    }

    onSubmit(): void {
        this.activeModal.close();
    }

    setParameterValue(keys: any[]) {
        if (this.param && this.param.MonitoringServiceParameter) {
            this.param.ParameterValue = keys.join(',');
        }
    }
}

import {
    Component,
    Input,
    OnDestroy,
    OnChanges,
    OnInit,
    EventEmitter,
    Output,
} from '@angular/core';

import {
    EntityAction,
} from 'breeze-client';
import { Subscription } from 'rxjs';

import {
    ColumnSelect,
    ColumnSelectLabel
} from '@common/facet';

import { DataManagerService } from '../../../services/data-manager.service';
import { EntityChangeService } from '../../../entity-changes/entity-change.service';
import { LoggingService } from '../../../services/logging.service';
import { JobPharmaDetailService } from '../services/job-pharma-detail.service';
import { VocabularyService } from '../../../vocabularies/vocabulary.service';
import {
    BooleanMap
} from '../../../workflow/models/workflow-bulk-data';
import {
    getSafeProp, notEmpty
} from '../../../common/util';
import { ScheduleType } from '../../../protocol/models/schedule-type.enum';
import { ConfirmService } from '../../../common/confirm/confirm.service';
import { AuthService } from '../../../services/auth.service';
import { SaveRecordsOverlayEvent } from './job-pharma-tasks-list-table.component';
import { ResourceService } from '../../../resources';
import { TaskInstanceChangeService } from '../../../entity-changes/task-instance-change.service';
import { convertValueToLuxon } from '@common/util/date-time-formatting/convert-value-to-luxon';
import { DependentTasksUpdated, ICompleteTasksResponse } from '@common/types';
import { cv_TimeUnit } from '../../../common/types/models/cv-time-unit.interface';
import { ExtendedTaskInstance } from '../models/extended-task-instance';
import { StatusCount, StatusCountMap } from "../models/status-count";
import { DialogContent, DialogService, NoButtonTitle, YesButtonTitle } from '@common/dialog/deprecated';

@Component({
    selector: 'job-pharma-tasks-schedule-table',
    templateUrl: './job-pharma-tasks-schedule-table.component.html',
    styleUrls: ['./job-pharma-tasks-schedule-table.component.scss'],
})
export class JobPharmaTasksScheduleTableComponent implements OnChanges, OnDestroy, OnInit {
    @Input() readonly: boolean;
    @Input() job: any;

    @Input() tabset = 'tasks';
    @Input() tab = 'schedule';
    @Input() isStudyDirector: boolean;

    @Output() busy: EventEmitter<SaveRecordsOverlayEvent> = new EventEmitter<SaveRecordsOverlayEvent>();

    taskPage = 1;

    // Tasks to show in the table
    tasks: ExtendedTaskInstance[] = [];

    // Column selections
    columnSelect: ColumnSelect = {
        model: [],
        labels: [],
    };

    // Visible columns
    visible: any = {};

    // Lookup table of task keys for the Breeze changes
    taskKeys: any = {};

    // Are all the rows selected?
    allSelected = false;
    allLocked = false;

    // Time units for the Duration
    timeUnits: any[] = [];

    resources: any[] = [];

    // All subscriptions
    subs = new Subscription();

    // Bulk fill values
    bulkDueDate: Date;
    bulkDueDateTask: any[];
    bulkDueDateTasks: any[];
    bulkDueDateTaskAliases: any[] = [];
    bulkDueDateTaskAlias: any;

    bulkStartTime = this.initBulkTime;
    bulkStartTimeTask: any;
    bulkStartTimeTasks: any[] = [];
    bulkStartTimeTaskAliases: any[] = [];
    bulkStartTimeTaskAlias: any;

    bulkDurationTimePoint: number;
    bulkDurationTimeUnit: number;
    bulkDurationTask: any;
    bulkDurationTasks: any[] = [];
    bulkDurationTaskAliases: any[] = [];
    bulkDurationTaskAlias: any;

    bulkAssignedTo: any[] = [];
    bulkAssignedToTask: any;
    bulkAssignedToTasks: any[] = [];
    bulkAssignedToTaskAliases: any[] = [];
    bulkAssignedToTaskAlias: any;

    bulkLocation: any;
    bulkAllowance: any;

    timeUnitDefault: any;

    readonly COMPONENT_LOG_TAG = 'job-pharma-tasks-schedule-table';
    taskInstanceChangeService = new TaskInstanceChangeService(null);
    loading = false;
    loadingMessage = "Loading";

    constructor(
        private dataManager: DataManagerService,
        private entityChangeService: EntityChangeService,
        private jobPharmaDetailService: JobPharmaDetailService,
        private loggingService: LoggingService,
        private vocabularyService: VocabularyService,
        private confirmService: ConfirmService,
        private dialogService: DialogService,
        private authService: AuthService,
        private resourceService: ResourceService,
    ) {
        // Do nothing
    }

    ngOnInit() {
        this.loading = true;
        this.initChangeDetection();
        this.initColumnSelect();
        this.initialize();
        this.loading = false;
    }

    ngOnChanges(changes: any) {
        if (changes.job && !changes.job.firstChange) {
            this.initJob().then(() => {
                this.initDateDueTasks();
                this.initTasks();
            });
        }
    }

    ngOnDestroy() {
        // Clear all the subscriptions
        this.subs.unsubscribe();
    }

    async initialize(): Promise<any> {
        await this.getCVs();
        await this.initJob();
        this.initDateDueTasks();
        this.initTasks();

    }

    getCVs(): Promise<any> {
        return Promise.all([
            this.vocabularyService.ensureCVLoaded('cv_TimeRelations'),
            this.vocabularyService.ensureCVLoaded('cv_ScheduleTypes'),
            this.vocabularyService.ensureCVLoaded('cv_TaskStatuses'),
        ]).then(() => {
            return this.vocabularyService.getCV('cv_TimeUnits').then((data) => {
                // To be on the safe side, clone and sort the TimeUnits
                this.timeUnits = data.slice();
                this.timeUnits.sort((a, b) => a.MinutesPerUnit - b.MinutesPerUnit);

                // Get the default TimeUnit
                this.timeUnitDefault = this.timeUnits.find((item: cv_TimeUnit) => {
                    return item.IsDefault;
                });
            }).then(() => {
                return this.resourceService.getAllResources().then((data) => {
                    this.resources = data.filter((resource) => {
                        return !resource.IsGroup;
                    });
                });
            });
        });
    }

    get initBulkTime() {
        const date = new Date();
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        date.setMilliseconds(0);
        return date;
    }

    initDateDueTasks() {
        if (!this.tasks || (this.tasks.length === 0)) {
            this.bulkDueDateTaskAliases = [];
            return;
        }
        const seen: BooleanMap = {};
        this.bulkDueDateTaskAliases = this.tasks.filter((task: any) => {
            const scheduleType = getSafeProp(
                task, 'ProtocolTask.cv_ScheduleType.ScheduleType'
            );
            if ((task.ProtocolTask !== null) &&
                (task.ProtocolTask.C_RelativeProtocolTask_key !== null) &&
                ((scheduleType === ScheduleType.RelativeTaskDue) ||
                    (scheduleType === ScheduleType.RelativeTaskComplete))
            ) {
                // This is a dependent task from a Protocol
                return false;
            }
            const key = task.TaskAlias;
            if (seen[key]) {
                // Already added tasks with this alias
                return false;
            }
            // Remember this task
            seen[key] = true;
            return true;

        }).map<string>((task: any) => task.TaskAlias);
    }

    initTasks() {
        const tasks: any[] = [];
        const seen: any = {};

        if (notEmpty(this.tasks)) {
            for (const task of this.tasks) {
                const key = task.C_WorkflowTask_key;
                if (seen[key]) {
                    continue;
                }
                tasks.push(task);
                seen[key] = true;
            }
        }

        this.bulkDueDateTasks = tasks;
        this.bulkDueDateTask = null;
        this.bulkDueDateTaskChanged();

        this.bulkStartTimeTasks = tasks;
        this.bulkStartTimeTask = null;
        this.bulkStartTimeTaskChanged();

        this.bulkDurationTasks = tasks;
        this.bulkDurationTask = null;
        this.bulkDurationTaskChanged();

        this.bulkAssignedToTasks = tasks;
        this.bulkAssignedToTask = null;
        this.bulkAssignedToTaskChanged();
    }

    bulkDueDateTaskChanged() {
        this.bulkDueDateTaskAliases = [];
        this.bulkDueDateTaskAlias = null;

        if (!this.tasks) {
            return;
        }

        this.bulkDueDateTaskAliases = this.getAliases(this.bulkDueDateTask);
    }

    bulkStartTimeTaskChanged() {
        this.bulkStartTimeTaskAliases = [];
        this.bulkStartTimeTaskAlias = null;

        if (!this.tasks) {
            return;
        }

        this.bulkStartTimeTaskAliases = this.getAliases(this.bulkStartTimeTask);
    }

    bulkDurationTaskChanged() {
        this.bulkDurationTaskAliases = [];
        this.bulkDurationTaskAlias = null;

        if (!this.tasks) {
            return;
        }

        this.bulkDurationTaskAliases = this.getAliases(this.bulkDurationTask);
    }

    bulkAssignedToTaskChanged() {
        this.bulkAssignedToTaskAliases = [];
        this.bulkAssignedToTaskAlias = null;

        if (!this.tasks) {
            return;
        }

        this.bulkAssignedToTaskAliases = this.getAliases(this.bulkAssignedToTask);
    }

    getAliases(bulkTask: any) {
        const aliases: string[] = [];
        const seen: any = {};
        for (const task of this.tasks) {
            if (task.IsLocked || (bulkTask !== null && task.C_WorkflowTask_key !== bulkTask.C_WorkflowTask_key)) {
                continue;
            }

            const alias = task.TaskAlias;
            if (!seen[alias]) {
                aliases.push(alias);
                seen[alias] = true;
            }
        }
        return aliases;
    }

    /**
     * Watch for external changes
     */
    initChangeDetection() {
        // Watch for TaskInstance changes
        this.subs.add(this.entityChangeService
            .onAnyChange((entityChange: any) => {
                this.onTaskInstanceChange(entityChange);
            })
        );

        // Also watch for changes to Job.TaskJob
        this.subs.add(this.jobPharmaDetailService.jobTasksChanged$.subscribe(() => {
            this.initJob();
        }));
    }

    /**
     * Update derived values for TaskInstances
     */
    onTaskInstanceChange(entityChange: any) {
        if (!entityChange.entity || !entityChange.entity.entityType || !entityChange.args) {
            // Just in case
            return;
        }

        const action = entityChange.entityAction;
        const entityType = entityChange.entity.entityType.shortName;

        if (entityType !== 'TaskInstance') {
            // Only interested in TaskInstances
            return;
        }

        const task = entityChange.entity;
        if (!this.taskKeys[task.C_TaskInstance_key]) {
            // Not one of the tasks in the table
            return;
        }

        if (action === EntityAction.PropertyChange) {
            const propertyName = entityChange.args.propertyName;
            if ((propertyName === 'DateDue') || (propertyName === 'Duration')) {
                // Update the derived values (Duration fields and TimeEnd)
                this.updateDerived(task);
            }
        }
    }

    /**
     * Initialize the column selections
     */
    initColumnSelect() {
        // Default Visibility
        this.visible = {
            protocol: true,
            taskAlias: true,
            dueDate: true,
            startTime: true,
            duration: true,
            endTime: true,
            assignedTo: true,
            location: true,
            deviation: true,
            status: true,
        };

        // Assemble the list of all columns that can be selected
        this.columnSelect.labels = [
            new ColumnSelectLabel('protocol', 'Protocol'),
            new ColumnSelectLabel('taskAlias', 'Task'),
            new ColumnSelectLabel('dueDate', 'Due Date'),
            new ColumnSelectLabel('startTime', 'Start Time'),
            new ColumnSelectLabel('duration', 'Duration'),
            new ColumnSelectLabel('endTime', 'End Time'),
            new ColumnSelectLabel('assignedTo', 'Assigned To'),
            new ColumnSelectLabel('location', 'Location'),
            new ColumnSelectLabel('deviation', 'Allowance'),
            new ColumnSelectLabel('status', 'Progress'),
        ];
        this.columnSelect.model = this.columnSelect.labels.filter(
            (item) => this.visible[item.key]
        ).map((item) => item.key);

        // Register the columns
        this.subs.add(
            this.jobPharmaDetailService.registerColumnSelect(
                this.tabset, this.tab, this.columnSelect,
                () => { this.updateVisible(); }
            )
        );

        // Update the column visiblility
        this.updateVisible();
    }

    /**
     * Update the column visibility flags.
     */
    updateVisible() {
        // Make a lookup table
        const selected = {};
        this.columnSelect.model.forEach((key) => {
            selected[key] = true;
        });

        // Update the visibilty based on the column selections
        this.columnSelect.labels.forEach((column) => {
            const key = column.key;
            this.visible[key] = (selected[key] === true);
        });
    }

    /**
     * Initialize the Job-related values.
     */
    async initJob(): Promise<any> {
        this.loading = true;
        // Make sure the tasks are loaded
        await this.dataManager.ensureRelationships(
            [this.job], ['TaskJob.TaskInstance']
        );
        const tasks = this.job.TaskJob.filter((jobTask: any) => {
            const task = jobTask.TaskInstance;

            return task && !task.C_GroupTaskInstance_key;
        }).map((jobTask: any) => jobTask.TaskInstance);
        // Fill out the task entities
        const expands = [
            'AssignedTo',
            'cv_TaskStatus',
            'LocationPosition',
            'ProtocolInstance.Protocol',
            'ProtocolTask.cv_ScheduleType',
            'TaskJob',
            'WorkflowTask',
        ];
        await this.dataManager.ensureRelationships(tasks, expands);
        // Sort the tasks
        this.jobPharmaDetailService.sortTasksBySchedule(tasks);
        // Prepare each task
        this.taskKeys = {};
        for (const task of tasks) {
            this.taskInstanceChangeService.setHasInvalidInputs(task);
            task.isSelected = false;

            // We'll want to watch for Breeze changes to this task
            this.taskKeys[task.C_TaskInstance_key] = true;

            // Update the Duration fields and TimeEnd
            this.updateDerived(task);
        }
        // Count the completed tasks
        this.initStatusCounts(tasks);
        // Finally ready to show these tasks
        this.tasks = tasks;
        this.isLockedChanged();
        this.loading = false;
    }

    /**
     * Count the total and completed tasks in each group
     *
     * @param tasks tasks that will be displayed
     */
    initStatusCounts(tasks: any[]) {
        if (!this.job.TaskJob) {
            // Nothing to count
            return;
        }

        const counts: StatusCountMap = {};

        // Go through all the tasks for this Job and count them by group
        for (const jobTask of this.job.TaskJob) {
            if (!jobTask.TaskInstance) {
                // Weird...
                continue;
            }

            const task = jobTask.TaskInstance;

            if (task.IsGroup) {
                // Don't count the groups themselves
                continue;
            }

            // Which group does this task belong to.
            // Ungrouped tasks belong to themselves.
            const key = task.C_GroupTaskInstance_key || task.C_TaskInstance_key;

            let count = counts[key];
            if (!count) {
                // Initialize a new counter for this group
                counts[key] = count = new StatusCount();
            }

            // Increment the counters
            count.total += 1;
            if (task.cv_TaskStatus && task.cv_TaskStatus.IsEndState) {
                count.endState += 1;
            }
        }

        // Assign the group counts to the tasks that will appear in the table
        for (const task of tasks) {
            task.statusCount = counts[task.C_TaskInstance_key] || new StatusCount();
        }
    }

    /**
     * The Select/Clear All button was clicked
     */
    allSelectedChanged() {
        // Select or unselect all the rows
        if (this.tasks) {
            for (const task of this.tasks) {
                task.isSelected = this.allSelected;
            }
        }
    }

    allLockedChanged() {
        // locked or unlocked all the rows
        if (this.tasks) {
            for (const task of this.tasks) {
                task.IsLocked = this.allLocked;
            }
        }
    }

    /**
     * A row selection checkbox was clicked.
     */

    isSelectedChanged() {
        // Check if all the rows are selected
        this.allSelected = this.tasks.every((task) => task.isSelected);
    }

    isLockedChanged() {
        // Check if all the rows are locked
        this.allLocked = this.tasks.every((task) => task.IsLocked);
    }

    /**
     * A task's DateDue Date was changed.
     */
    async dateDueChanged(tasks: ExtendedTaskInstance[]): Promise<void> {
        await this.updateGroupAndRelativeTasks(tasks, true);
    }

    /**
     * A task's Start Time was changed.
     */
    async timeStartChanged(tasks: ExtendedTaskInstance[]) {
       await this.updateGroupAndRelativeTasks(tasks, true);
    }

    /**
     * A task's Duration TimePoint (the number) was changed.
     */
    durationTimePointChanged(task: any) {
        this.calculateDuration(task);
        this.updateGroupTasks(task);
    }
    /**
     * A task's Duration TimeUnit (the unit) was changed.
     */
    durationTimeUnitChanged(task: any) {
        this.calculateDuration(task);
        this.updateGroupTasks(task);
    }

    /**
     * A task's Assigned To value was changed.
     */
    assignedToChanged(task: any) {
        this.updateGroupTasks(task);
    }

    /**
     * A task's Location was changed.
     */
    locationChanged(task: any) {
        this.updateGroupTasks(task);
    }

    /**
     * A task's Allowance was changed.
     */
    allowanceChanged(task: any) {
        this.updateGroupTasks(task);
    }

    /**
     * Update all the derived values for a task.
     */
    updateDerived(task: any) {
        // Split the Duration into TimePoint and TimeUnit.
        this.splitDuration(task);

        // Calculate the TimeEnd text
        this.calculateTimeEnd(task);
    }

    /**
     * Split the Duration into TimePoint and TimeUnit.
     */
    splitDuration(task: any) {
        if (this.timeUnitDefault) {
            task.Duration_TimeUnit = this.timeUnitDefault.MinutesPerUnit;
        }

        if (!task.Duration) {
            // Clear out the TimePoint
            task.Duration_TimePoint = null;
            return;
        }

        // Don't reset the Duration inputs if they are valid.
        // This prevents 60 Minutes from changing to 1 Hours while the user is
        // updating a row.
        if (task.Duration_TimePoint && task.Duration_TimeUnit) {
            const duration = task.Duration_TimePoint * task.Duration_TimeUnit;
            if (task.Duration === duration) {
                return;
            }
        }

        // Find the largest TimeUnit that evenly divides the Duration
        for (let i = this.timeUnits.length - 1; i >= 0; --i) {
            const timeUnit = this.timeUnits[i];
            if (task.Duration % timeUnit.MinutesPerUnit === 0) {
                task.Duration_TimePoint = task.Duration / timeUnit.MinutesPerUnit;
                task.Duration_TimeUnit = timeUnit.MinutesPerUnit;
                break;
            }
        }

        // Just in case of something weird, default to Minutes
        if (!task.Duration_TimeUnit) {
            task.Duration_TimeUnit = 1;
        }
    }

    /**
     * Calculate the task's Duration from the TimePoint and TimeUnit elements
     */
    calculateDuration(task: any) {
        if (!task.Duration_TimePoint || !task.Duration_TimeUnit) {
            task.Duration = 0;
        } else {
            task.Duration = task.Duration_TimePoint * task.Duration_TimeUnit;
        }
    }

    /**
     * Calculate the task's TimeEnd from the DateDue and Duration
     */
    calculateTimeEnd(task: any) {
        if (!task.DateDue || !task.Duration) {
            task.TimeEnd = null;
            return;
        }

        task.TimeEnd = convertValueToLuxon(task.DateDue).plus({"minutes": task.Duration}).toFormat('hh:mm a');
    }

    /**
     * Update the group task instances and relative tasks in the same ProtocolInstance.
     */
    async updateGroupAndRelativeTasks(tasks: ExtendedTaskInstance[], dateDueChanged: boolean): Promise<void> {
        const filteredTasks = tasks.filter((item: ExtendedTaskInstance) => item.DateDue);

        if (!filteredTasks.length) {
            // Need a start date for the relative time math
            return;
        }
        this.busy.emit({ state: true, message: "Updating Dependent tasks" });

        try {
            await this.dataManager.saveEntities(['TaskInstance', 'TaskJob', 'ProtocolInstance', 'TaskInput']);
        } catch {
            this.busy.emit({ state: false, message: "Updating Dependent tasks" });
            this.loggingService.logError("Could not save tasks.", null, this.COMPONENT_LOG_TAG, false);
            return;
        }

        try {
            const taskKeys = filteredTasks.map((taskItem: ExtendedTaskInstance) => taskItem.C_TaskInstance_key);
            const response: ICompleteTasksResponse = await this.jobPharmaDetailService.scheduleTask(
                taskKeys,
                dateDueChanged,
                this.authService.getCurrentUserName(),
                this.authService.getCurrentUserId()
            );

            if (response.Success && response.DependentTasksUpdated.length > 0) {
                // Data has been saved in the backend. Now we just need to update the frontend.
                const changedTasksDepended: ExtendedTaskInstance[] = [];
                response.DependentTasksUpdated.forEach((updatedTask: DependentTasksUpdated) => {
                    const updatedRows = this.tasks.filter((currentRow) => currentRow.C_TaskInstance_key === updatedTask.C_TaskInstance_key);
                    changedTasksDepended.push(...updatedRows);
                    updatedRows.forEach((updatedRow: ExtendedTaskInstance) => {
                        updatedRow.DateDue = convertValueToLuxon(updatedTask.DateDue).setZone("UTC").toJSDate();
                        this.calculateTimeEnd(updatedRow);
                        updatedRow.entityAspect.setUnchanged();
                    });
                    this.jobPharmaDetailService.tabRefresh('tasks', 'schedule');
                });
                this.busy.emit({ state: false, message: "Updating Dependent tasks" });
                this.loggingService.logSuccess("Changes saved", null, this.COMPONENT_LOG_TAG, true);
                // Warn the user about the related date changes
                if (changedTasksDepended.length) {
                    this.loggingService.logWarning(
                        'Dependent due dates and start times have been updated. <br/>' +
                        'Please check the schedule.',
                        null,
                        this.COMPONENT_LOG_TAG,
                        true);
                }
            } else {
                this.busy.emit({ state: false, message: "Updating Dependent tasks" });
            }
        } catch {
            this.busy.emit({ state: false, message: "Updating Dependent tasks" });
            this.loggingService.logError("Could not update dependent tasks.", null, this.COMPONENT_LOG_TAG, false);
        }

    }

    updateGroupTasks(task: any) {

        const groupedTasks = this.job.TaskJob.filter((jobTask: any) => {
            const taskInstance = jobTask.TaskInstance;

            return taskInstance
                && taskInstance.C_GroupTaskInstance_key === task.C_TaskInstance_key;
        }).map((jobTask: any) => jobTask.TaskInstance);

        if (groupedTasks.length === 0) {
            return;
        }

        // Set values
        for (const childTask of groupedTasks) {
            childTask.DateDue = task.DateDue;
            childTask.Duration = task.Duration;
            childTask.Duration_TimePoint = task.Duration_TimePoint;
            childTask.C_AssignedTo_key = task.C_AssignedTo_key;
            childTask.LocationPosition = task.LocationPosition;
            childTask.Deviation = task.Deviation;
        }
    }

    /**
     * The Bulk Due Date "Update All" button was clicked.
     *
     * Update the due date for all the tasks.
     */
    async bulkDueDateUpdated(): Promise<any> {
        if (!this.tasks || !this.bulkDueDate) {
            // Nothing to do
            return;
        }

        const selectedTasks = this.getMatchingTasks(this.bulkDueDateTask, this.bulkDueDateTaskAlias);

        // Warn if any of the selected tasks already has a date due
        const foundDueDate = selectedTasks.some((task: any) => {
            return (task.DateDue !== null);
        });

        let shouldUpdateDueDate = true;
        if (foundDueDate) {
            const options: DialogContent & YesButtonTitle & NoButtonTitle = {
                title: 'Overwrite existing values?',
                bodyText: 'Some of the matching tasks already have a Due Date. ' +
                    'Are you sure you want to update the values?',
                yesButtonTitle: 'OK',
                noButtonTitle: 'Cancel'
            }
            shouldUpdateDueDate = await this.dialogService.confirmYesNo(options);
        }
        
        if (shouldUpdateDueDate) {
            this.busy.emit({ state: true, message: "Updating Due Date" });
            try {
                // Deal with the changes
                const year = this.bulkDueDate.getUTCFullYear();
                const month = this.bulkDueDate.getUTCMonth();
                const day = this.bulkDueDate.getUTCDate();
                // Update the Due Dates for each task
                for (const task of selectedTasks) {
                    if (task.DateDue) {
                        // clone the existing due date
                        const newDate = new Date(task.DateDue.getTime());
                        // change the date while preserving the time
                        newDate.setUTCFullYear(year, month, day);
                        task.DateDue = newDate;
                    } else {
                        task.DateDue = this.bulkDueDate;
                    }

                }
                // Save TaskInstance so we get the DateDue updated before getting relative tasks
                await this.dataManager.saveEntity('TaskInstance');
                await this.dateDueChanged(selectedTasks);

                // clear the bulk due date form
                this.bulkDueDateTaskAlias = null;
                this.bulkDueDateTask = null;
                this.bulkDueDate = null;
                this.bulkDueDateTaskChanged();
            }
            catch (error) {
                this.loggingService.logError("An unexpected error occurred. Please try again", error,
                    this.COMPONENT_LOG_TAG, true);
            }
            finally {
                this.busy.emit({ state: false, message: "Updating Due Date" });
            }
        }
    }

    /**
     * The Bulk Start Time "Update All" button was clicked.
     *
     * Update the Start time for all the tasks.
     */
   async bulkStartTimeUpdated(): Promise<any> {
        if (!this.tasks || !this.bulkStartTime) {
            // Nothing to do
            return;
        }
        const selectedTasks = this.getMatchingTasks(this.bulkStartTimeTask, this.bulkStartTimeTaskAlias);

        // Warn if any of the selected tasks already has a date due
        const foundDueDate = selectedTasks.some((task: any) => {
            return (task.DateDue !== null);
        });

        let shouldUpdateStartDate = true;
        if (foundDueDate) {
            const options: DialogContent & YesButtonTitle & NoButtonTitle = {
                title: 'Overwrite existing values?',
                bodyText: 'Some of the matching tasks already have a Due Date/Time. ' +
                    'Are you sure you want to update the values?',
                yesButtonTitle: 'OK',
                noButtonTitle: 'Cancel'
            }
            shouldUpdateStartDate = await this.dialogService.confirmYesNo(options);
        }
        if (shouldUpdateStartDate) {
            try {
                this.busy.emit({state: true, message: "Updating Start Time"});
                const hours = this.bulkStartTime.getUTCHours();
                const minutes = this.bulkStartTime.getUTCMinutes();
                // Update the start time for each task
                for (const task of selectedTasks) {
                    if (task.DateDue) {
                        // clone the existing due date
                        const newDate = new Date(task.DateDue.getTime());
                        // change the time while preserving the date
                        newDate.setUTCHours(hours, minutes);
                        task.DateDue = newDate;
                    } else {
                        task.DateDue = this.bulkStartTime;
                    }
                }
                // Save TaskInstance so we get the StartDate updated before getting relative tasks
                await this.dataManager.saveEntity('TaskInstance');
                await this.timeStartChanged(selectedTasks);

                // Clear the Bulk start time form
                this.bulkStartTimeTaskAlias = null;
                this.bulkStartTimeTask = null;
                this.bulkStartTime = this.initBulkTime;
                this.bulkStartTimeTaskChanged();
            }
            catch (error) {
                this.loggingService.logError("An unexpected error occurred. Please try again", error,
                    this.COMPONENT_LOG_TAG, true);
            } 
            finally {
                this.busy.emit({state: false, message: "Updating Start Time"});
            }
        }
    }

    bulkDurationUpdated() {
        if (!this.tasks || !this.bulkDurationTimePoint || !this.bulkDurationTimeUnit) {
            // Nothing to do
            return;
        }

        let promise: Promise<any> = Promise.resolve();

        const selectedTasks = this.getMatchingTasks(this.bulkDurationTask, this.bulkDurationTaskAlias);

        // Warn if any of the selected tasks already has a value
        const foundValue = selectedTasks.some((task: any) => {
            return (task.Duration !== null);
        });

        if (foundValue) {
            promise = promise.then(() => {
                const options = {
                    title: 'Overwrite existing values?',
                    message: 'Some of the matching tasks already have a Duration. ' +
                        'Are you sure you want to update the values?',
                };

                return this.confirmService.confirm(options);
            });
        }

        return promise.then(
            () => {
                // Update for each task
                for (const task of selectedTasks) {
                    if (!task.IsLocked) {
                        task.Duration_TimePoint = this.bulkDurationTimePoint;
                        task.Duration_TimeUnit = this.bulkDurationTimeUnit;
                        this.calculateDuration(task);
                    }
                }
                // Clear the Bulk form
                this.bulkDurationTaskAlias = null;
                this.bulkDurationTask = null;
                this.bulkDurationTimePoint = null;
                this.bulkDurationTimeUnit = null;
                this.bulkDurationTaskChanged();
            },
            () => {
                // Do nothing when confirm is cancelled
            }
        );
    }

    private getMatchingTasks(bulkTask: any, bulkTaskAlias: any): ExtendedTaskInstance[] {
        // Find matching tasks, in the order of the rows
        const seen: BooleanMap = {};
        return this.tasks.filter((task: any) => {
            if (task.IsLocked) {
                // this is locked
                return false;
            }

            if (seen[task.C_TaskInstance_key]) {
                // Already saw this one
                return false;
            }

            // Remember this task
            seen[task.C_TaskInstance_key] = true;

            if (bulkTask === null && bulkTaskAlias === null) {
                // Matching All TaskName & All TaskAlias
                return true;
            } else if (bulkTask !== null && bulkTaskAlias !== null) {
                // Matching TaskName & TaskAlias
                return (task.WorkflowTask.TaskName === bulkTask.WorkflowTask.TaskName && task.TaskAlias === bulkTaskAlias);
            } else if (bulkTask !== null) {
                // Matching TaskName
                return (task.WorkflowTask.TaskName === bulkTask.WorkflowTask.TaskName);
            } else {
                // Matching TaskAlias
                return (task.TaskAlias === bulkTaskAlias);
            }
        });
    }

    bulkAssignedToUpdated() {
        if (!this.tasks || !this.bulkAssignedTo) {
            // Nothing to do
            return;
        }

        let promise: Promise<any> = Promise.resolve();

        const selectedTasks = this.getMatchingTasks(this.bulkAssignedToTask, this.bulkAssignedToTaskAlias);

        if (!selectedTasks || selectedTasks.length < 1) {
            // Nothing to do
            return;
        }

        // Warn if any of the selected tasks already has a value
        const foundValue = selectedTasks.some((task: any) => {
            return (task.C_AssignedTo_key !== null);
        });

        if (foundValue) {
            promise = promise.then(() => {
                const options = {
                    title: 'Overwrite existing values?',
                    message: 'Some of the matching tasks are already assigned. ' +
                        'Are you sure you want to update the values?',
                };

                return this.confirmService.confirm(options);
            });
        }

        return promise.then(
            () => {
                this.busy.emit({ state: true, message: "Updating Assigned To" });

                if (this.bulkAssignedTo.length === 0) {
                    for (const task of selectedTasks) {
                        if (!task.IsLocked && !task.HasInvalidInputs) {
                            task.C_AssignedTo_key = null;
                            const groupedTasks = this.job.TaskJob.filter((jobTask: any) => {
                                const taskInstance = jobTask.TaskInstance;
                                return taskInstance
                                    && taskInstance.C_GroupTaskInstance_key === task.C_TaskInstance_key;
                            }).map((jobTask: any) => jobTask.TaskInstance);

                            // Set values
                            for (const childTask of groupedTasks) {
                                childTask.C_AssignedTo_key = task.C_AssignedTo_key;
                            }
                        }
                    }

                    // Clear the Bulk form
                    this.bulkAssignedToTaskAlias = null;
                    this.bulkAssignedToTask = null;
                    this.bulkAssignedTo = [];
                    this.bulkAssignedToTaskChanged();

                    this.busy.emit({ state: false, message: "Updating Assigned To" });
                } else {
                    const taskInstanceKeys = selectedTasks.map((t: any) => t.C_TaskInstance_key);
                    this.jobPharmaDetailService.bulkAssignTo(taskInstanceKeys, this.bulkAssignedTo)
                        .then((data) => {
                            return this.resourceService.getAllCachedResources().then(() => {
                            // Update for each task
                            for (const task of selectedTasks) {
                                if (!task.IsLocked && !task.HasInvalidInputs) {
                                    task.C_AssignedTo_key = data;
                                    task.entityAspect.setUnchanged();
                                    const groupedTasks = this.job.TaskJob.filter((jobTask: any) => {
                                        const taskInstance = jobTask.TaskInstance;

                                        return taskInstance
                                            && taskInstance.C_GroupTaskInstance_key === task.C_TaskInstance_key;
                                    }).map((jobTask: any) => jobTask.TaskInstance);

                                    // Set values
                                    for (const childTask of groupedTasks) {
                                        childTask.C_AssignedTo_key = task.C_AssignedTo_key;
                                        childTask.entityAspect.setUnchanged();
                                    }
                                }
                            }

                            // Clear the Bulk form
                            this.bulkAssignedToTaskAlias = null;
                            this.bulkAssignedToTask = null;
                            this.bulkAssignedTo = [];
                            this.bulkAssignedToTaskChanged();

                            this.busy.emit({ state: false, message: "Updating Assigned To" });
                            this.loggingService.logSuccess("Changes saved", null, this.COMPONENT_LOG_TAG, true);
                        });
                        })
                        .catch((error) => {
                            this.busy.emit({ state: false, message: "Updating Assigned To" });
                            this.loggingService.logError(error, null, this.COMPONENT_LOG_TAG, true);
                    });
                }
            },
            () => {
                // Do nothing when confirm is cancelled
            }
        );
    }

    bulkLocationUpdated() {
        if (!this.tasks || !this.bulkLocation) {
            // Nothing to do
            return;
        }

        // Update the Location for each task
        for (const task of this.tasks) {
            if (!task.IsLocked) {
                task.LocationPosition = this.bulkLocation;
            }
        }
        // Clear the Bulk Location form
        this.bulkLocation = null;
    }

    bulkAllowanceUpdated() {
        if (!this.tasks || !this.bulkAllowance) {
            // Nothing to do
            return;
        }

        // Update the Allowance for each task
        for (const task of this.tasks) {
            if (!task.IsLocked) {
                task.Deviation = this.bulkAllowance;
            }
        }
        // Clear the Bulk Allowance form
        this.bulkAllowance = null;
    }

    changeTaskPage(newPage: number) {
        this.taskPage = newPage;
    }
}

import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';

/*
* Generic File Downloader
*/
@Injectable()
export class FileDownloader {

    /*
    * Initiate file download of content string 
    *   with specific MIME type
    */
    triggerFileDownload(
        csvContent: string, 
        filename: string, 
        mimeType: string
    ) {
        if (this._isFileSaverSupported()) {
            this._downloadWithFileSaver(csvContent, filename, mimeType);
        } else {
            this._downloadWithDataUri(csvContent, filename, mimeType);
        }
    }

    private _isFileSaverSupported(): boolean {
        let isFileSaverSupported = false;
        try {
            /* eslint-disable-next-line */
            isFileSaverSupported = !!new Blob;
        } catch (e) { /* do nothing */ }
        return isFileSaverSupported;
    }

    private _downloadWithFileSaver(
        csvContent: string, 
        filename: string,
        mimeType: string
    ) {
        const blob = new Blob([csvContent], { type: mimeType });
        FileSaver.saveAs(blob, filename);
    }

    private _downloadWithDataUri(
        csvContent: string, 
        filename: string,
        mimeType: string
    ) {
        csvContent = "data:" + mimeType + "," + csvContent;
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
    }

}

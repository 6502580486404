<climb-auth-container>
    <div class="intro">
        <p>
            Enter your username, and we will email you a link to reset your password.
        </p>
        <p>
            For additional assistance, please contact
            <a href="{{supportUri}}" target="_blank" rel="noopener">Climb Support</a>.
        </p>
    </div>

    <form [formGroup]="formData">
        <climb-alert-inline *ngIf="errorMessage"
                            [message]="errorMessage"
                            status="error">
        </climb-alert-inline>

        <climb-alert-inline *ngIf="savedSuccessfully">
            Your request has been received. Please check your email for a link to reset your password.
        </climb-alert-inline>

        <climb-input size="lg" #userName>
            <svg [climbIcon]="icons.user"></svg>
            <input data-automation-id="username-input"
                   type="text"
                   climbInput
                   formControlName="UserName"
                   name="userName"
                   placeholder="Username"
                   spellcheck="false"
                   autofocus />
        </climb-input>

        <button climbButton
                type="submit"
                variant="primary"
                size="lg"
                (click)="requestPasswordReset()"
                [pending]="sending"
                [disabled]="formData.invalid">
            Request Password Reset
        </button>
    </form>

    <div class="links">
        <a data-automation-id="return-to-login-link"
           routerLink="/login">
        Return to Login
    </a>
    </div>

    <climb-auth-footer authContainerFooter
                       [version]="buildId">
    </climb-auth-footer>
</climb-auth-container>

import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { NgModel } from '@angular/forms';

@Directive({
  selector: '[integerInput]'
})
export class IntegerInputDirective {

    @Input() min = Number.MIN_SAFE_INTEGER;
    @Input() max = Number.MAX_SAFE_INTEGER;
    constructor(private model: NgModel) {}

    @HostListener('input', ['$event'])
    onInput() {
        const value = this.model.value;
        this.model.control.setValue(this.process(value), { emitViewToModelChange: true });
    }

    @HostListener('paste', ['$event'])
    onPaste(event: ClipboardEvent) {
        event.preventDefault();
        // Get the pasted text from the clipboard
        const pastedText = event.clipboardData.getData('text/plain');
        const value = parseFloat(pastedText);
        this.model.control.setValue(this.process(value), { emitViewToModelChange: true });
    }

    private process(value?: number) {
        if (!value || isNaN(value)) {
            return value;
        }

        value = this.convertToInt(value);
        value = this.applyBounds(value);
        return value;
    }

    private convertToInt(value: number) {
        return Math.trunc(value);
    }

    private applyBounds(value: number) {
        if (value < this.min) {
            return this.min;
        }

        if (value > this.max) {
            return this.max;
        }

        return value;
    }
}

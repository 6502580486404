<div class="modal-header justify-content-center">
    <h4 class="modal-title">Save Changes</h4>
</div>

<div class="modal-body">
    <p>
        Save your changes before continuing?
    </p>
</div>

<div class="modal-footer">
    <button type="button"
            class="btn btn-lg btn-fw-xwide btn-primary"
            (click)="saveClicked()"
            #saveButton
            autofocus>
        Save Changes
    </button>
    <button type="button"
            class="btn btn-lg btn-fw-xwide btn-secondary"
            (click)="discardClicked()">
        Discard Changes
    </button>
</div>

import { FormatTimePointPipe } from './pipes';
import { SampleService } from './sample.service';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { ClimbCommonModule } from '../common/climb-common.module';
import { ClimbServicesModule } from '../services/climb-services.module';

import { SampleVocabService } from './sample-vocab.service';
import { SampleConstructSelectComponent } from './sample-construct-select.component';
import { ExportSampleAuditService } from './audit';
import { SampleMultiselectComponent } from './sample-multiselect.component';
import { SampleSourceSelectComponent } from './sample-source-select.component';
import { MaterialMultiselectComponent } from './material-multiselect.component';
import { SourceMaterialMultiselectComponent } from './source-material-multiselect.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgbModule,
        ClimbCommonModule,
        ClimbServicesModule
    ],
    declarations: [
        SourceMaterialMultiselectComponent,
        SampleConstructSelectComponent,
        MaterialMultiselectComponent,
        SampleSourceSelectComponent,
        SampleMultiselectComponent,
        FormatTimePointPipe
    ],
    exports: [
        SourceMaterialMultiselectComponent,
        SampleConstructSelectComponent,
        MaterialMultiselectComponent,
        SampleSourceSelectComponent,
        SampleMultiselectComponent,
        FormatTimePointPipe
    ]
})
export class SamplesSharedModule {
    static forRoot(): ModuleWithProviders<SamplesSharedModule> {
        return {
          ngModule: SamplesSharedModule,
          providers: [
            ExportSampleAuditService,
            SampleService,
            SampleVocabService
          ]
        };
    }
}

import type { AnimalClinicalObservation } from './animal-clinical-observation.interface';
import type { AnimalDiagnosticObservation } from './animal-diagnostic-observation.interface';

export interface cv_ClinicalObservationStatus {
    Abbreviation: string;
    AnimalClinicalObservation: AnimalClinicalObservation[];
    AnimalDiagnosticObservation: AnimalDiagnosticObservation[];
    C_ClinicalObservationStatus_key: number;
    C_VocabularySource_key: number | null;
    C_Workgroup_key: number;
    ClinicalObservationStatus: string;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    ElementID: string;
    IsActive: boolean;
    IsDefault: boolean;
    IsDeprecated: boolean;
    IsEndStatus: boolean;
    ModifiedBy: string;
    SortOrder: number | null;
    Version: number;
}

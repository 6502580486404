import { convertValueToLuxon } from './date-time-formatting/convert-value-to-luxon';

/**
 * @deprecated use DateFormatterService#isDateMidnight instead. 
 * Checks if the date is midnight. 
 * @param date 
 * @returns 
 */
export function isDateMidnight(date: Date): boolean {
    if (!date) {
        return false;
    }
    const mDate = convertValueToLuxon(date);
    if (!mDate.isValid) {
        return false;
    }

    return mDate.hour === 0 &&
        mDate.minute === 0 &&
        mDate.second === 0;
}

import { Component, AfterViewInit, OnInit, Input, TemplateRef, ViewChild, ViewChildren } from "@angular/core";
import { AnimalVocabService } from "../../animals/services/animal-vocab.service";
import { BulkAddExitReason, BulkEditOptions, BulkEditSection, FacetLoadingStateService } from "../../common/facet";
import { uniqueArrayFromPropertyPath } from "../../common/util";
import { ResourceService } from "../../resources";
import { DataManagerService } from "../../services/data-manager.service";
import { LoggingService } from "../../services/logging.service";
import { TranslationService } from "../../services/translation.service";
import { SettingService } from "../../settings/setting.service";
import { VocabularyService } from "../../vocabularies/vocabulary.service";
import { ClinicalVocabService } from "../clinical-vocab.service";
import { NgModel } from "@angular/forms";
import { dateControlValidator } from "@common/util/date-control.validator";

@Component({
    selector: 'treatment-plan-bulk-templates',
    templateUrl: './treatment-plan-bulk-templates.component.html'
})
export class TreatmentPlanBulkTemplatesComponent implements OnInit, AfterViewInit {

    @Input() animals: any[];
    @Input() tasks: any[];
    @Input() treatments: any[];
    @Input() isEdit: boolean;

    @ViewChildren('dateControl') dateControls: NgModel[];

    @ViewChild('animalNameTmpl', { static: false }) animalNameTmpl: TemplateRef<any>;
    @ViewChild('statusAddTmpl', { static: false }) statusAddTmpl: TemplateRef<any>;
    @ViewChild('statusEditTmpl', { static: false }) statusEditTmpl: TemplateRef<any>;
    @ViewChild('healtTechTmpl', { static: false }) healtTechTmpl: TemplateRef<any>;
    @ViewChild('identifierTmpl', { static: false }) identifierTmpl: TemplateRef<any>;
    @ViewChild('animalLocationTmpl', { static: false }) animalLocationTmpl: TemplateRef<any>;
    @ViewChild('animalStudyTmpl', { static: false }) animalStudyTmpl: TemplateRef<any>;
    @ViewChild('animalBirthDateTmpl', { static: false }) animalBirthDateTmpl: TemplateRef<any>;
    @ViewChild('animalSexTmpl', { static: false }) animalSexTmpl: TemplateRef<any>;
    @ViewChild('animalBodyConditionTmpl', { static: false }) animalBodyConditionTmpl: TemplateRef<any>;
    @ViewChild('animalExitDateTmpl', { static: false }) animalExitDateTmpl: TemplateRef<any>;
    @ViewChild('animalExitReasonTmpl', { static: false }) animalExitReasonTmpl: TemplateRef<any>;
    @ViewChild('planTmpl', { static: false }) planTmpl: TemplateRef<any>;
    @ViewChild('dueDateTmpl', { static: false }) dueDateTmpl: TemplateRef<any>;
    @ViewChild('assignedToTmpl', { static: false }) assignedToTmpl: TemplateRef<any>;
    @ViewChild('commentsTmpl', { static: false }) commentsTmpl: TemplateRef<any>;
    @ViewChild('completeTmpl', { static: false }) completeTmpl: TemplateRef<any>;
    @ViewChild('completeDateTmpl', { static: false }) completeDateTmpl: TemplateRef<any>;
    @ViewChild('completedByTmpl', { static: false }) completedByTmpl: TemplateRef<any>;
    @ViewChild('taskStatusTmpl', { static: false }) taskStatusTmpl: TemplateRef<any>;
    @ViewChild('animalSpeciesTmpl', { static: false }) animalSpeciesTmpl: TemplateRef<any>;

    animalStatuses: any[] = null;
    resources: any[] = null;
    bodyConditionScores: any[] = null;
    exitReasons: any[] = null;
    taskStatuses: any[];

    complete: boolean;

    defaultResourceKey: number;

    readonly COMPONENT_LOG_TAG = 'treatment-plan-bulk-add';

    bulkOptions: BulkEditOptions;
    BulkEditSection = BulkEditSection;

    endStateKeys: any[] = [];
    
    defaultEndState: number = null;

    constructor(
        private loggingService: LoggingService,
        private settingService: SettingService,
        private resourceService: ResourceService,
        private facetLoadingState: FacetLoadingStateService,
        private animalVocabService: AnimalVocabService,
        private translationService: TranslationService,
        private clinicalVocabService: ClinicalVocabService,
        private vocabularyService: VocabularyService,
        private dataManager: DataManagerService
    ) { }

    ngAfterViewInit(): void {
        this.bulkOptions = {
            itemTypeLabel: 'Treatment Plan',
            itemTypeLabelPlural: 'Treatment Plans',
            clearForm: true,
            exitReasonConfig: {
              [BulkAddExitReason.Edit]: { exist: false },
            },
            saveButtonValidators: [() => {
                return this.isEdit || (this.animals.length > 0 && this.tasks.length > 0);
            }],
            fields: [
                {
                    label: "Animal ID",
                    modelPath: 'AnimalHealthRecord.Animal.Material.Identifier',
                    template: this.identifierTmpl,
                    hideFromAddScreen:  true,
                    hideFromEditHeader: true
                },
                {
                    label: "Animal Name",
                    modelPath: 'AnimalHealthRecord.Animal.AnimalName',
                    template: this.animalNameTmpl,
                    hideFromAddScreen:  true,
                    hideFromEditHeader: true
                },
                {
                    label: "Location",
                    modelPath: 'AnimalHealthRecord.Animal.CurrentLocationPath',
                    template: this.animalLocationTmpl,
                    hideFromAddScreen:  true,
                    hideFromEditHeader: true,
                    visible: false
                },
                {
                    label: this.translationService.translate('Job'),
                    modelPath: 'JobMaterial',
                    template: this.animalStudyTmpl,
                    hideFromAddScreen:  true,
                    hideFromEditHeader: true,
                    visible: false
                },
                {
                    label: "Birth Date",
                    modelPath: 'DateBorn',
                    template: this.animalBirthDateTmpl,
                    hideFromAddScreen:  true,
                    hideFromEditHeader: true,
                    visible: false
                },
                {
                    label: "Sex",
                    modelPath: 'Sex',
                    template: this.animalSexTmpl,
                    hideFromAddScreen:  true,
                    hideFromEditHeader: true,
                    visible: false
                },
                {
                    label: "Species",
                    modelPath: 'CommonName',
                    template: this.animalSpeciesTmpl,
                    hideFromAddScreen: true,
                    hideFromEditHeader: true,
                    visible: false
                },
                {
                    label: 'Animal Status',
                    modelPath: 'C_AnimalStatus_key',
                    template: this.statusAddTmpl,
                    visible: false,
                    hideFromEditScreen: true,
                },
                {
                    label: "Plan",
                    modelPath: 'TaskInstance.TaskInput[0].InputValue',
                    template: this.planTmpl,
                    hideFromAddScreen:  true,
                    visible: true
                },
                {
                    label: "Due Date",
                    modelPath: 'TaskInstance.DateDue',
                    template: this.dueDateTmpl,
                    hideFromAddScreen:  true,
                    visible: true
                },
                {
                    label: "Assigned To",
                    modelPath: 'TaskInstance.C_AssignedTo_key',
                    template: this.assignedToTmpl,
                    hideFromAddScreen:  true,
                    visible: true
                },
                {
                    label: "Comments",
                    modelPath: 'TaskInstance.Comments',
                    template: this.commentsTmpl,
                    hideFromAddScreen:  true,
                },
                {
                    label: "Complete",
                    modelPath: 'TaskInstance',
                    template: this.completeTmpl,
                    hideFromAddScreen:  true,
                    onUpdateItem: () => {
                        for (const task of this.tasks) {
                            if (this.complete) {
                                this.markComplete(task.TaskInstance);
                                task.C_TaskStatus_key = this.defaultEndState;
                            } else {
                                this.markIncomplete(task.TaskInstance);
                                this.getDefaultTaskStatus().then((status) => {
                                    if (status) {
                                        task.C_TaskStatus_key = status.C_TaskStatus_key;
                                    }
                                });
                            }
                        }
                        this.complete = false;
                    }
                },
                {
                    label: "Complete Date",
                    modelPath: 'TaskInstance.DateComplete',
                    template: this.completeDateTmpl,
                    hideFromAddScreen:  true,
                },
                {
                    label: "Completed By",
                    modelPath: 'TaskInstance.C_CompletedBy_key',
                    template: this.completedByTmpl,
                    hideFromAddScreen:  true,
                },
                {
                    label: "Task Status",
                    modelPath: 'TaskInstance.C_TaskStatus_key',
                    template: this.taskStatusTmpl,
                    hideFromAddScreen:  true,
                    onUpdateItem: (item, value) => {
                        const newTaskStatusKey = parseInt(value, null);
                        const isCompleted = this.endStateKeys.includes(newTaskStatusKey);
                        for (const task of this.tasks) {
                            if (isCompleted) {
                                this.markComplete(task.TaskInstance);
                            } else {
                                this.markIncomplete(task.TaskInstance);
                            }
                            task.TaskInstance.C_TaskStatus_key = newTaskStatusKey;
                        }
                    }
                },
                ...this.isEdit ? [] : [{
                    label: 'Health Tech',
                    modelPath: 'C_Resource_key',
                    template: this.healtTechTmpl,
                    hideFromEditScreen: true
                }]
            ]
        };
    }

    ngOnInit(): void {
        this.facetLoadingState.changeLoadingState(true);

        Promise.all([
            this.getSettings(),
            this.getCVs(),
        ]).then(() => {
            this.facetLoadingState.changeLoadingState(false);
        }).catch((error) => {
            this.facetLoadingState.changeLoadingState(false);
            throw error;
        });
    }

    private getCVs(): Promise<any> {
        return Promise.all([
            this.animalVocabService.animalStatuses$.toPromise().then((animalStatuses) => {
                    this.animalStatuses = animalStatuses;
                }),
            this.resourceService.getAllResources().then((resources) => {
                this.resources = resources;
            }),
            this.clinicalVocabService.taskStatuses$.toPromise().then((taskStatuses) => {
                this.taskStatuses = taskStatuses;
            })
                .then(() => {
                this.setDefaultFillDownStatus();
            }),
        ]);
    }

    setDefaultFillDownStatus() {
        for (const status of this.taskStatuses) {
            if (status.IsDefaultEndState) {
                this.defaultEndState = status.C_TaskStatus_key;
            }
            if (status.IsEndState) {
                this.endStateKeys.push(status.C_TaskStatus_key);
            }
        }
    }

    private getSettings(): Promise<any> {
        return this.settingService.getDefaultHealthTechSetting().then((data: any) => {
            if (data) {
                this.defaultResourceKey = data.C_DefaultHealthTechnician_key;
            } else {
                const errorMessage = 'Workgroup settings could not be loaded.';

                this.loggingService.logError(
                    errorMessage,
                    null,
                    this.COMPONENT_LOG_TAG,
                    true
                );

                throw new Error(errorMessage);
            }
        });
    }

    // Incomplete
    public markIncomplete(task: any) {

        // clear completed if already set
        task.C_CompletedBy_key = null;
        task.DateComplete = null;
    }

    private getDefaultTaskStatus(): Promise<any> {
        const preferLocal = true;
        return this.vocabularyService.getCVDefault('cv_TaskStatuses', preferLocal);
    }

    // Complete
    public markComplete(task: any) {
        // set completed if not 
        task.DateComplete = new Date();

        // get current user resource for completed by
        this.resourceService.getCurrentUserResource().then((resource: any) => {
            if (resource) {
                task.C_CompletedBy_key = resource.C_Resource_key;
            }

            return this.dataManager.ensureRelationships([task], ['TaskOutputSet']);
        }).then(() => {
            // Fill Collected fields if empty
            const taskOutputSets = uniqueArrayFromPropertyPath(task, 'TaskOutputSet');
            for (const tos of taskOutputSets) {
                tos.CollectionDateTime = tos.CollectionDateTime ? tos.CollectionDateTime : task.DateComplete;
                tos.C_Resource_key = tos.C_Resource_key ? tos.C_Resource_key : task.C_CompletedBy_key;
            }
        });
    }

    // <select> formatters
    animalStatusKeyFormatter = (value: any) => {
        return value.C_AnimalStatus_key;
    }
    animalStatusFormatter = (value: any) => {
        return value.AnimalStatus;
    }
    resourceKeyFormatter = (value: any) => {
        return value.C_Resource_key;
    }
    resourceNameFormatter = (value: any) => {
        return value.ResourceName;
    }
    taskStatusKeyFormatter = (value: any) => {
        return value.C_TaskStatus_key;
    }
    taskStatusFormatter = (value: any) => {
        return value.TaskStatus;
    }

    validate() {
        return dateControlValidator(this.dateControls);
    }
}

import {
    CellFormatterService,
    TableOptions
} from '@common/datatable';
import { addAuditColumnsToOptions } from '@common/datatable/utils';

export class ImportTableOptions {

    options: TableOptions;

    constructor(
        private cellFormatterService: CellFormatterService
    ) {
        this.options = addAuditColumnsToOptions(cellFormatterService)(this.getDefaultOptions());
    }

    getDefaultOptions(): TableOptions {
        return {
            csvFileName: 'importlogs.csv',
            enableAuditColumns: false,
            enableDetailColumn: true,
            enableDraggable: false,
            enableSelectable: false,
            columns: [
                { displayName: 'Filename', field: 'FileName' },
                {
                    displayName: 'File Definition', 
                    field: 'ImportFileDefinition.DefinitionName',
                    visible: false
                },
                {
                    displayName: 'Import Type', 
                    field: 'ImportFileDefinition.cv_ImportFileType.ImportFileType',
                    visible: false
                },
                { 
                    displayName: 'Imported By', field: 'CreatedBy',
                    visible: false,
                    formatter: this.cellFormatterService.userNameFormatter
                },
                { 
                    displayName: 'Started', field: 'StartDateTime',
                    visible: false,
                    formatter: this.cellFormatterService.dateTimeFormatter 
                },
                {
                    displayName: 'Completed', field: 'EndDateTime',
                    formatter: this.cellFormatterService.dateTimeFormatter
                },
                {
                    displayName: 'Succeeded', field: 'Result',
                    formatter: this.cellFormatterService.importBooleanFormatter,
                    exportFormatter: this.cellFormatterService.booleanExportFormatter,
                    maxWidth: 110,
                },
                {
                    displayName: 'HTTP Result', field: 'HTTPResult',
                    visible: false
                },
                {
                    displayName: 'Result Details', field: 'ResultDetails',
                    visible: false
                }
            ]
        };
    }
}

import { Pipe, PipeTransform } from '@angular/core';

import { notEmpty } from '../util';

interface AnimalItem {
    Material: {
        Animal: unknown;
    };
}

/**
 * Returns Animals from a list of Materials
 */
@Pipe({ 
    name: 'animalFilter',
    pure: false
})
export class AnimalFilterPipe implements PipeTransform {

    transform<T extends AnimalItem>(items: T[]): T[] {
        const filtered = [];

        if (notEmpty(items)) {
            for (const item of items) {
                if (item.Material != null) {
                    if (item.Material.Animal != null) {
                        filtered.push(item);
                    }
                }
            }
        }

        return filtered;
    }
}

<climb-dropdown [icon]="icons.listView"
                [caption]="activeGridView | mapFn : translateViewName : this">
    <button *ngFor="let option of options"
            climbDropdownItem
            [class.active]="activeGridView === option.viewName"
            (click)="changeGridView.emit(option.viewName)">
        <svg *ngIf="activeGridView === option.viewName"
             [climbIcon]="icons.checkmark">
        </svg>
        {{ option.viewDisplayName }}
    </button>
</climb-dropdown>

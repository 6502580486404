import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
    Component,
    ElementRef,
    EventEmitter,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import { WorkspaceManagerService } from '@services/workspace-manager.service';
import { WorkspaceService } from '../../workspace.service';

/*
* Modal to add a new workspace
*/
@Component({
    selector: 'add-workspace',
    templateUrl: './add-workspace.component.html'
})
export class AddWorkspaceComponent implements OnInit {
    @Output() add: EventEmitter<string> = new EventEmitter<string>();
    @Output() cancel: EventEmitter<void> = new EventEmitter<void>();

    // state variables
    selectedWsTemplate: any;
    workspaceName: string;
    wsTemplates: any[];
    loading: boolean;

    constructor(
        private activeModal: NgbActiveModal,
        private workspaceService: WorkspaceService,
        private workspaceManager: WorkspaceManagerService
    ) {}

    ngOnInit() {
        this.selectedWsTemplate = '';
        this.wsTemplates = [];
        this.loading = false;
        this.setupTemplates();
    }

    setupTemplates(): Promise<any> {
        return this.workspaceService.getWorkspaceTemplates()
            .then((response: any) => {
                this.wsTemplates = response;
            });
    }

    // Auto-focus on workspace-name input
    @ViewChild('workspaceNameInput') 
    set workspaceNameInput(element: ElementRef) {
        if (element) {
            setTimeout(() => {
                element.nativeElement.focus();
            });
        }
    }

    onTemplateSelect() {
        if (this.selectedWsTemplate) {
            this.workspaceName = this.selectedWsTemplate.WorkspaceName;
        } else {
            this.workspaceName = '';
        }
    }

    okClicked(event: Event): void {
        this.loading = true;
        event.preventDefault();
        this.createWorkspace();
    }

    cancelClicked(): void {
        this.cancel.emit();
        this.activeModal.dismiss();
    }

    createWorkspace(): Promise<any> {
        return this.workspaceService.fetchWorkspaces()
            .then((workspaces: any[]) => {

                const defaults: any = {
                    WorkspaceName: this.workspaceName,
                    WorkspaceDetail: []
                };

                let newWorkspace: any;

                const index: number = workspaces.length;

                let savePromise: Promise<any>;

                if (this.selectedWsTemplate) {
                    newWorkspace = this.workspaceService.createWorkspaceFromTemplate(
                        defaults, index, this.selectedWsTemplate
                    );

                    this.workspaceService.validateWorkspaceName(
                        newWorkspace, workspaces
                    );
                    savePromise = this.workspaceManager.save();
                } else {
                    newWorkspace = this.workspaceService.createWorkspace(
                        defaults, index
                    );
                    this.workspaceService.validateWorkspaceName(
                        newWorkspace, workspaces
                    );
                    savePromise = this.workspaceManager.save();
                }

                savePromise.then(() => {
                    this.loading = false;
                    this.workspaceService.isCustomizeWorkspaceActive = false;
                    this.add.emit(newWorkspace);
                    this.activeModal.close(newWorkspace);
                });
            });
    }
}

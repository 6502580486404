import { Predicate } from 'breeze-client';
import { getBooleanPredicate } from './boolean-predicate';

/**
 * Creates Breeze Predicates for IsUrgent fields.
 * @param value the IsUrgent value
 */
export function getIsUrgentPredicate(value: boolean | string): Predicate {
    return getBooleanPredicate(value, 'IsUrgent');
}

import {
    Component,
    OnInit,
    ViewChild,
    ViewChildren,
} from '@angular/core';

import { JobVocabService } from '../../jobs/job-vocab.service';
import { randomId } from '../../common/util';

import { JobsGanttChartRendererComponent } from './jobs-gantt-chart-renderer.component';
import { NgModel } from '@angular/forms';
import { LoggingService } from '@services/logging.service';
import { dateControlValidator } from '@common/util/date-control.validator';

@Component({
    selector: 'jobs-gantt-chart',
    templateUrl: './jobs-gantt-chart.component.html',
    styleUrls: ['../charts-facet.component.scss'],
})
export class JobsGanttChartComponent implements OnInit {
    @ViewChild(JobsGanttChartRendererComponent)
    private chartRenderer: JobsGanttChartRendererComponent;

    @ViewChildren('dateControl') dateControls: NgModel[];

    domIdAddition: string;

    // Filter values
    filter: any;

    // Layout options
    numJobs = 50;
    numJobOptions: number[] = [5, 10, 25, 50, 100];
    showCurrentTime = false;

    // CVs
    jobTypes: any[] = [];
    jobStatuses: any[] = [];

    constructor(
        private jobVocabService: JobVocabService,
        private loggingService: LoggingService,
    ) {
        // Nothing to do
    }

    ngOnInit() {
        this.domIdAddition = randomId();

        this.getCVs();

        this.resetFilter();
    }

    private getCVs() {
        this.jobVocabService.jobTypes$
            .subscribe((jobTypes: any[]) => {
                this.jobTypes = jobTypes;
            });

        this.jobVocabService.jobStatuses$
            .subscribe((jobStatuses: any[]) => {
                this.jobStatuses = jobStatuses;
            });
    }


    isInputValid(): boolean {
        return true;
    }

    resetFilter(): void {
        this.filter = {};
    }

    createChart(): void {
        const errMessage = dateControlValidator(this.dateControls);
        if (errMessage) {
            this.loggingService.logError(errMessage, null, '', true);
            return;
        }

        this.chartRenderer.createChart();
    }
}

import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    HostBinding,
    OnInit,
    ViewChild,
} from '@angular/core';
import { CdkHeaderCell } from '@angular/cdk/table';
import { ColumnDefDirective } from './column-def.directive';
import type { AriaSort, SortDirection } from '../sort/sort.interface';


/** Header cell template container. */
@Component({
    selector: 'climb-header-cell, th[climbHeaderCell]',
    template: `
        <div class="header-cell-wrapper">
            <div class="header-cell-label" 
                 (click)="onSortClick($event)">
                <ng-content></ng-content>
            </div>
            
            <button *ngIf="isSortable"
                    #sortButton
                    climbSort
                    (sortChanged)="setAriaSortAttribute($event.direction)">
            </button>
            
            <div *ngIf="!isFixedWidth"
                 class="resize-handle">
            </div>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderCellComponent extends CdkHeaderCell implements OnInit {
    @ViewChild('sortButton', { read: ElementRef }) sortButton: ElementRef<HTMLButtonElement>;

    @HostBinding('class')
    private get classes(): Record<string, boolean> {
        return {
            ['climb-header-cell']: true,
            ['header-cell-fixed']: this.isFixedWidth,
            ['cell-icon-only']: this.isOnlyIcon,
            ['sort-header']: this.isSortable,
        };
    }

    @HostBinding('role')
    private readonly role = 'columnheader';

    @HostBinding('attr.data-name')
    private dataName = this.columnDef.columnId;

    @HostBinding('attr.aria-sort')
    private ariaSort: AriaSort;

    isFixedWidth = false;
    isSortable = false;

    private isOnlyIcon = false;

    constructor(
        private columnDef: ColumnDefDirective,
        elementRef: ElementRef,
    ) {
        super(columnDef, elementRef);
    }

    ngOnInit(): void {
        this.isFixedWidth = this.columnDef.fixed;
        this.isOnlyIcon = this.columnDef.onlyIcon;
        this.isSortable = this.columnDef.sortable;

        this.setAriaSortAttribute('');
    }

    onSortClick(event: MouseEvent): void {
        if (this.isSortable) {
            // use dispatchEvent to send Shift key state
            this.sortButton.nativeElement.dispatchEvent(
                new MouseEvent('click', event),
            );
        }
    }

    /**
     * Gets the aria-sort attribute that should be applied to this header. If this header
     * is not sorted, returns null so that the attribute is removed from the host element.
     */
    getAriaSortAttribute(sortDirection: SortDirection): AriaSort {
        if (!this.isSortable) {
            return null;
        }

        switch (sortDirection) {
            case '': return 'none';
            case 'asc': return 'ascending';
            case 'desc': return 'descending';
        }
    }

    setAriaSortAttribute(sortDirection: SortDirection): void {
        this.ariaSort = this.getAriaSortAttribute(sortDirection);
    }
}

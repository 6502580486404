import type { cv_JobSubtype } from './cv-job-subtype.interface';
import type { cv_StandardPhrase } from './cv-standard-phrase.interface';

export interface cv_StandardPhraseJobSubtype {
    C_JobSubtype_key: number;
    C_StandardPhraseJobSubtype_key: number;
    C_StandardPhrase_key: number;
    C_Workgroup_key: number;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    ModifiedBy: string;
    Version: number;
    cv_JobSubtype: cv_JobSubtype;
    cv_StandardPhrase: cv_StandardPhrase;
}

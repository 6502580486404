import { Component, HostBinding } from '@angular/core';
import { animate, group, style, transition, trigger } from '@angular/animations';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: '[climbInputHint], [climbInputError]',
    template: '<ng-content></ng-content>',
    animations: [
        trigger('transitionMessages', [
            transition(':enter', [
                style({
                    height: 0,
                    opacity: 0,
                    transform: 'translateY(-5px)',
                }),
                group([
                    animate('60ms ease-out', style({ height: '*' })),
                    animate('200ms ease-out', style({
                        opacity: 1,
                        transform: 'translateY(0px)',
                    })),
                ]),
            ]),

            transition(':leave', [
                animate('100ms ease-in', style({
                    opacity: 0,
                    height: 0,
                }))
            ])
        ]),
    ],
})
export class InputMessagesAnimationComponent {
    @HostBinding('@transitionMessages')
    private state: string;
}

export interface cv_Modifier3 {
    C_Modifier3_key: number;
    C_VocabularySource_key: number | null;
    C_Workgroup_key: number;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    ElementID: string;
    IsActive: boolean;
    IsDefault: boolean;
    IsDeprecated: boolean;
    IsEndState: boolean;
    IsPermanent: boolean;
    ModifiedBy: string;
    Modifier3: string;
    SortOrder: number | null;
    Version: number;
}

import type { EnumerationClass } from './enumeration-class.interface';
import type { ExpressionInputMapping } from './expression-input-mapping.interface';
import type { InputDefault } from './input-default.interface';
import type { InputInstance } from './input-instance.interface';
import type { TaskInput } from './task-input.interface';
import type { TaskPlaceholderInput } from './task-placeholder-input.interface';
import type { WorkflowTask } from './workflow-task.interface';
import type { cv_DataType } from './cv-data-type.interface';
import type { cv_DosingTable } from './cv-dosing-table.interface';
import type { cv_InputOutputUnit } from './cv-input-output-unit.interface';
import type { cv_JobCharacteristicType } from './cv-job-characteristic-type.interface';

export interface Input {
    Abbreviation: string;
    C_DataType_key: number;
    C_DosingTable_key: number | null;
    C_EnumerationClass_key: number | null;
    C_InputOutputUnit_key: number | null;
    C_Input_key: number;
    C_JobCharacteristicType_key: number | null;
    C_VocabularyClass_key: number | null;
    C_WorkflowTask_key: number;
    C_Workgroup_key: number;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    Description: string;
    EnumerationClass: EnumerationClass;
    ExpressionInputMapping: ExpressionInputMapping[];
    InputDefault: InputDefault[];
    InputInstance: InputInstance[];
    InputName: string;
    IsActive: boolean;
    IsMultiSelectEnumeration: boolean;
    IsRequired: boolean;
    IsScheduleDependent: boolean;
    ModifiedBy: string;
    RequiresValidation: boolean;
    SortOrder: number | null;
    TaskInput: TaskInput[];
    TaskPlaceholderInput: TaskPlaceholderInput[];
    TextLineCount: number | null;
    TextValidationRegex: string;
    ValidationMax: string;
    ValidationMin: string;
    Version: number;
    WorkflowTask: WorkflowTask;
    cv_DataType: cv_DataType;
    cv_DosingTable: cv_DosingTable;
    cv_InputOutputUnit: cv_InputOutputUnit;
    cv_JobCharacteristicType: cv_JobCharacteristicType;
}

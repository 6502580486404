import { Pipe, PipeTransform } from "@angular/core";

/**
 * Takes a comma separated list string or an array and creates a truncated list based on the limit parameter.
 * Optional parameter: include a count at the end of the truncation. True by default. 
 */
@Pipe({
    name: 'truncateList'
})
export class TruncateListPipe implements PipeTransform {
    transform(value: any[] | string, limit: number, showAndMore = true) {
        if (!value || value.length === 0) {
            return "";
        }
        if (typeof (value) === "string") {
            value = value.split(",");
        }
 
        const truncatedList = value.slice(0, limit).join(",");
        if (value.length <= limit) {
            return truncatedList;
        }
        if (showAndMore) {
            return truncatedList + " and " + (value.length - limit) + " more";
        } else {
            return truncatedList + "...";
        }
    }
}

import {
    Component,
    EventEmitter,
    Input,
    Output
} from '@angular/core';

import { SearchService } from '../search/search.service';
import { SearchQueryDef } from '../search/search-query-def';

@Component({
    selector: 'study-multiselect',
    template: `
    <!-- Template for typeahead options -->
    <ng-template #resultTemplate let-r="result">
        <div class="study-option">
            {{r.StudyName}}
        </div>
        <div class="additional-info small">
            Type: {{r.StudyType}} | Status: {{r.StudyStatus}}
        </div>
    </ng-template>
    <div *ngIf="model | notEmpty">
        <table class="table table-sm table-borderless table-nonfluid">
            <tbody>
                <tr *ngFor="let item of model; let i = index;">
                    <td class="icon-cell">
                        <a (click)="removeItem(i)" title="Remove {{'Study' | translate}}">
                            <i class="fa fa-remove remove-item"></i>
                        </a>
                    </td>
                    <td>
                        {{item.StudyName}}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

<climb-indirect-typeahead
        [resultTemplate]="resultTemplate"
        [search]="searchStudies"
        [resultFormatter]="resultFormatter"
        placeholder="{{'Study' | translate}} name&hellip;"
        (selectItem)="selectItem($event)"></climb-indirect-typeahead>
`,
    styles: [`
        .table {
            margin-bottom: 0.25em;
        }
    `]
})
export class StudyMultiselectComponent {
    @Input() model: any[];
    @Input() excludeInactive = false;
    @Input() excludeLocked = false;
    @Output() modelChange: EventEmitter<any> = new EventEmitter<any>();

    readonly MAX_SEARCH_RESULTS = 50;


    constructor(
        private _searchService: SearchService
    ) { }

    removeItem(index: number) {
        if (this.model && index >= 0 && index < this.model.length) {
            this.model.splice(index, 1);
        }

        this.modelChange.emit(this.model);
    }

    searchStudies = (term: string): Promise<any> => {
        let count = null;
        if (term.length < 3) {
            count = this.MAX_SEARCH_RESULTS;
        }

        const filter: any = {
            StudyName: term
        };

        if (this.excludeInactive) {
            filter.IsActive = true;
        }

        if (this.excludeLocked) {
            filter.IsLocked = false;
        }

        const searchQueryDef: SearchQueryDef = {
            entity: 'Studies',
            page: 1,
            size: count,
            sortColumn: 'StudyName',
            sortDirection: 'asc',
            filter
        };

        return this._searchService.getEntitiesBySearch(searchQueryDef)
            .then((results: any) => {
                return results.data;
            });
    }

    resultFormatter = (item: any) => {
        return item.StudyName;
    }

    selectItem(item: any) {
        if (this.model) {
            this.model.push(item);
        } else {
            this.model = [item];
        }

        this.modelChange.emit(this.model);
    }
}

import {
    CellFormatterService,
    FormatterOptions,
    TableOptions
} from '../common/datatable';
import { AuditModifiedFieldComponent } from './audit-modified-fields/audit-modified-field.component';
import { AuditClassFactory } from './audit-class-maker';

export class AuditOutputFlagsTableOptions {

    options: TableOptions;

    constructor(
        private cellFormatterService: CellFormatterService
    ) {
        this.options = this.getDefaultOptions();
    }

    getDefaultOptions(): TableOptions {

        return {
            csvFileName: 'task-output-flags-audit.csv',
            enableDetailColumn: false,
            enableDraggable: false,
            enableSelectable: false,
            enableAuditColumns: false,
            columns: [
                {
                    displayName: 'Type',
                    field: 'UpdateType',
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Modified Fields',
                    field: 'ModifiedFields',
                    sortField: 'ModifiedFields',
                    rendererComponent: AuditModifiedFieldComponent,
                    exportFormatter: this.cellFormatterService.auditModifiedFieldsExportFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Date',
                    field: 'ModifiedDate',
                    formatter: this.cellFormatterService.dateTimeUTCFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Modified By',
                    field: 'ModifiedBy',
                    formatter: this.cellFormatterService.userNameFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Task Type',
                    field: 'TaskType',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('TaskType', 'TaskType'),
                    cellClass: AuditClassFactory.createCellClass('tasktype'),
                },                
                {
                    displayName: 'Task',
                    field: 'Task',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('Task', 'Task'),
                    cellClass: AuditClassFactory.createCellClass('task'),
                },                
                {
                    displayName: 'Output Name',
                    field: 'OutputName',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('OutputName', 'OutputName'),
                    cellClass: AuditClassFactory.createCellClass('outputname'),
                },
                {
                    displayName: 'Flag Message',
                    field: 'FlagMessage',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('FlagMessage', 'FlagMessage'),
                    cellClass: AuditClassFactory.createCellClass('flagmessage'),
                },
                {
                    displayName: 'Flag At',
                    field: 'FlagAt',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('FlagAt', 'FlagAt'),
                    cellClass: AuditClassFactory.createCellClass('flagat'),
                },                
                {
                    displayName: 'Created By',
                    field: 'CreatedBy',
                    formatter: this.cellFormatterService.userNameFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Created Date',
                    field: 'DateCreated',
                    formatter: this.cellFormatterService.dateTimeUTCFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                }
            ]
        };
    }
}

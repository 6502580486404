import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';

import { PlateVocabService } from './plate-vocab.service';

import { BaseFilter } from '../common/facet';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
    focusElementByQuery
} from '../common/util/';



@Component({
    selector: 'plate-filter',
    templateUrl: './plate-filter.component.html'
})
export class PlateFilterComponent extends BaseFilter implements OnInit {
    @Input() filter: any = {};
    @Output() onFilter = new EventEmitter<any>();
    @Output() close = new EventEmitter<any>();

    // CVs
    plateStatuses: any[] = [];
    plateTypes: any[] = [];

    constructor(
        private plateVocabService: PlateVocabService,
        private activeModal: NgbActiveModal
    ) {
        super(activeModal);
    }

    ngOnInit() {
        this.cloneFilter();

        this.getCVs();

        focusElementByQuery('[data-auto-focus="plateId"]');
    }

    private cloneFilter() {
        this.filter = this.copyFilter(this.filter);
    }

    private getCVs() {
        this.plateVocabService.plateStatuses$
        .subscribe((data) => {
            this.plateStatuses = data;
        });
        this.plateVocabService.plateTypes$
        .subscribe((data) => {
            this.plateTypes = data;
        });
    }

    filterClicked(event: Event) {
        event.preventDefault();
        this.onFilter.emit(this.filter);
        this.close.emit();
        this.activeModal.close();
    }

    closeClicked() {
        this.close.emit();
        this.activeModal.dismiss();
    }

    clearClicked() {
        this.filter = {};
        this.onFilter.emit(this.filter);
        this.close.emit();
        this.activeModal.close();
    }
}

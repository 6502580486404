
export function startsWithVowel(word: string): boolean {
    if (!word || word.length === 0) {
        return false;
    }
    word = word.toLowerCase();

    switch (word[0]) {
        case 'a':
        case 'e':
        case 'i':
        case 'o':
        case 'u':
            return true;
    }
    return false;
}

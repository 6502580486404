import {
    CellFormatterService,
    FormatterOptions,
    TableColumnDef,
    TableOptions
} from '../common/datatable';
import { AuditModifiedFieldComponent } from './audit-modified-fields/audit-modified-field.component';
import { AuditClassFactory } from './audit-class-maker';

export class AuditCharacteristicsAnimalsTableOptions {
    options: TableOptions;

    constructor(
        private cellFormatterService: CellFormatterService
    ) {
        this.options = this.getDefaultOptions();
    }

    getDefaultOptions(): TableOptions {

        return {
            csvFileName: 'characteristic-animals-audit.csv',
            enableDetailColumn: false,
            enableDraggable: false,
            enableSelectable: false,
            enableAuditColumns: false,
            columns: [
                {
                    displayName: 'Type',
                    field: 'UpdateType',
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Modified Fields',
                    field: 'ModifiedFields',
                    sortField: 'ModifiedFields',
                    rendererComponent: AuditModifiedFieldComponent,
                    exportFormatter: this.cellFormatterService.auditModifiedFieldsExportFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Date',
                    field: 'ModifiedDate',
                    formatter: this.cellFormatterService.dateTimeUTCFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Modified By',
                    field: 'ModifiedBy',
                    formatter: this.cellFormatterService.userNameFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Animal Characteristic Name',
                    field: 'AnimalCharacteristicName',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('AnimalCharacteristicName', 'AnimalCharacteristicName'),
                    cellClass: AuditClassFactory.createCellClass('animalcharacteristicname'),
                },
                {
                    displayName: 'Active',
                    field: 'Active',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('Active', 'Active'),
                    cellClass: AuditClassFactory.createCellClass('active'),
                },
                {
                    displayName: 'Species',
                    field: 'Species',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('Species', 'Species'),
                    cellClass: AuditClassFactory.createCellClass('species'),
                },
                {
                    displayName: 'Description',
                    field: 'Description',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('Description', 'Description'),
                    cellClass: AuditClassFactory.createCellClass('description'),
                },
                {
                    displayName: 'Data Type',
                    field: 'DataType',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('DataType', 'DataType'),
                    cellClass: AuditClassFactory.createCellClass('datatype'),
                },
                {
                    displayName: 'Minimum Value',
                    field: 'MinimumValue',
                    visible: false,
                    formatter: this.cellFormatterService.createAuditColumnFormatter('MinimumValue', 'MinimumValue'),
                    cellClass: AuditClassFactory.createCellClass('minimumvalue'),
                },
                {
                    displayName: 'Maximum Value',
                    field: 'MaximumValue',
                    visible: false,
                    formatter: this.cellFormatterService.createAuditColumnFormatter('MaximumValue', 'MaximumValue'),
                    cellClass: AuditClassFactory.createCellClass('maximumvalue'),
                },
                {
                    displayName: 'Decimal Places',
                    field: 'DecimalPlaces',
                    visible: false,
                    formatter: this.cellFormatterService.createAuditColumnFormatter('DecimalPlaces', 'DecimalPlaces'),
                    cellClass: AuditClassFactory.createCellClass('decimalplaces'),
                },
                {
                    displayName: 'Number Of Rows',
                    field: 'NumberOfRows',
                    visible: false,
                    formatter: this.cellFormatterService.createAuditColumnFormatter('NumberOfRows', 'NumberOfRows'),
                    cellClass: AuditClassFactory.createCellClass('numberofrows'),
                },
                {
                    displayName: 'Enumeration',
                    field: 'Enumeration',
                    visible: false,
                    formatter: this.cellFormatterService.createAuditColumnFormatter('Enumeration', 'Enumeration'),
                    cellClass: AuditClassFactory.createCellClass('enumeration'),
                },
                {
                    displayName: 'Last Reviewed',
                    field: 'LastReviewed',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('LastReviewed', 'LastReviewed', this.cellFormatterService.dateFormatter),
                    cellClass: AuditClassFactory.createCellClass('lastreviewed'),
                },
                {
                    displayName: 'Sort Order',
                    field: 'SortOrder',
                    visible: false,
                    formatter: this.cellFormatterService.createAuditColumnFormatter('SortOrder', 'SortOrder'),
                    cellClass: AuditClassFactory.createCellClass('sortorder'),
                },
                this.getListViewOrderColumn(),
                {
                    displayName: 'Created By',
                    field: 'CreatedBy',
                    formatter: this.cellFormatterService.userNameFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Created Date',
                    field: 'DateCreated',
                    formatter: this.cellFormatterService.dateTimeUTCFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                }
            ]
        };
    }

    getListViewOrderColumn(): TableColumnDef {
        return {
            displayName: 'List View Order',
            field: 'ListViewOrder',
            visible: false,
            formatter: this.cellFormatterService.createAuditColumnFormatter('ListViewOrder', 'ListViewOrder'),
            cellClass: (params: any) => {
                const row = params.data;
                const classes: string[] = [];
                if (!row || !row.UpdateType) {
                    return classes;
                }

                if (row.ModifiedFields.toLowerCase().split(', ').indexOf('listvieworder') > -1) {
                    classes.push('modified-field');
                }
                if (row.UpdateType.toLowerCase() === 'deleted') {
                    classes.push('deleted-type');
                }
                return classes;
            }
        };
    }
}

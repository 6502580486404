/* Calculates number of weeks between two dates */
import { LuxonInput } from './date-time-formatting/types/luxon-input.type';
import { convertValueToLuxon } from './date-time-formatting/convert-value-to-luxon';

/*
*  weeks between date1 and date2
*  Accepts null/invalid input
*/
export function weeksSinceAsString(laterDate: LuxonInput, earlierDate: LuxonInput): string {

    let laterMoment = convertValueToLuxon(laterDate);
    let earlierMoment = convertValueToLuxon(earlierDate);

    if (!laterMoment || !earlierMoment) {
        return '';
    }

    // ignore time
    laterMoment = laterMoment.startOf('day');
    earlierMoment = earlierMoment.startOf('day');

    return '' + laterMoment.diff(earlierMoment, 'weeks').as("weeks");
}

import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output
} from '@angular/core';

import {
    focusElementByQuery,
    notEmpty,
    randomId,
    tokenizeOnNewline
} from '../util';

@Component({
    selector: 'multi-paste-input',
    template: `
    <div style="margin: 0;"
            [ngClass]="{ 'has-error': _validationError }">
        <textarea class="form-control"
                [attr.name]="_fieldName"
                [name]="_fieldName"
                rows="{{rows}}"
                [(ngModel)]="_textModel"
                [disabled]="disabled"
                [required]="required"
                (ngModelChange)="textModelChanged($event)"></textarea>
        <small class="form-text text-danger" *ngIf="_validationError">
            {{_validationError}}
        </small>
    </div>
    `
})
export class MultiPasteInputComponent implements OnChanges, OnInit {
    @Input() model: string[];
    @Output() modelChange: EventEmitter<string[]> = new EventEmitter<string[]>();
    @Output() validInput: EventEmitter<boolean> = new EventEmitter<boolean>();

    // Default is comma
    @Input() delimiters: string[];
    // "rows" attribute for textarea
    @Input() rows: number;

    readonly DEFAULT_LIMIT = 100;
    @Input() limit: number = this.DEFAULT_LIMIT;

    // validate field for numeric tokens only
    @Input() isNumeric: boolean;

    @Input() disabled: boolean;
    @Input() required: boolean;

    readonly COMMA = ',';
    readonly NEWLINE = '\n';

    _fieldName: string;

    // state variables
    _validationError: string;
    _validators: ((tokens: string[]) => string)[];
    _textModel: string;

    ngOnInit() {
        // Angular likes to have a name on form fields.
        //  generate a unique field name
        this._fieldName = "multi_paste_" + randomId();

        if (!notEmpty(this.delimiters)) {
            // must have at least one delimiter
            this.delimiters = [this.COMMA];
        }

        // add field validators
        this._validators = [
            this.validateLimit
        ];

        if (this.isNumeric) {
            this._validators.push(this.validateNumeric);
        }

        if (this.model) {
            this.setTextFromModel();
        }

    }

    ngOnChanges(changes: any) {
        if (changes.model) {
            const model = changes.model;
            // if model was changed outside this component
            // reset the internal _textModel
            const modelFromText = this.parseTextToTokens();
            let modelChanged = false;

            // if either value is null/empty, but not both
            const currentEmpty = !notEmpty(model.currentValue);
            const previousEmpty = !notEmpty(model.previousValue);
            if ((currentEmpty && !previousEmpty) ||
                (!currentEmpty && previousEmpty)
            ) {
                modelChanged = true;
            }

            // compare literal textarea values with current model
            //   (these remain in sync when manually edited by user)
            if (notEmpty(model.currentValue) && modelFromText) {
                const currentAsText = model.currentValue.join(this.COMMA);
                const internalFormattedText = modelFromText.join(this.COMMA);
                modelChanged = currentAsText !== internalFormattedText;
            }

            if (modelChanged &&
                !model.firstChange
            ) {
                this.setTextFromModel();
            }
        }
    }

    /**
     * Public method to focus on textarea input
     */
    focus(timeoutDelay?: number) {
        focusElementByQuery('[name="' + this._fieldName + '"]', timeoutDelay);
    }

    setTextFromModel() {
        this._textModel = "";

        let delimeter = this.NEWLINE;
        if (notEmpty(this.delimiters)) {
            delimeter = this.delimiters[0] + " ";
        }

        if (this.model) {
            this._textModel = this.model.join(delimeter);
            if (this.model.length > 0) {
                // add trailing delimiter
                this._textModel += delimeter;
            }

            this.validateModel();
        }

    }

    setModelFromText() {
        this.model = this.parseTextToTokens();

        // do any validation on tokens
        this.validateModel();
    }

    validateModel() {
        this._validationError = '';
        for (const validator of this._validators) {
            if (!this._validationError) {
                this._validationError = validator(this.model);
            }
        }
        this.validInput.emit(this._validationError === '');
    }

    validateLimit = (tokens: string[]): string => {
        let message = "";
        if (tokens.length >= this.limit) {
            message = "Item limit reached.  Only the first " + this.limit +
                " values will be included in the filter.";
            if (tokens.length > this.limit) {
                this.model = tokens.slice(0, this.limit);
            }
        }
        return message;
    }

    validateNumeric = (tokens: string[]): string => {
        let message = "";
        for (const token of tokens) {
            if (isNaN(Number(token))) {
                message = "Input values must be numbers.";
                break;
            }
        }
        return message;
    }

    /**
     * parse _textModel into tokens
     */
    parseTextToTokens(): string[] {
        return tokenizeOnNewline(this._textModel, this.delimiters);
    }

    textModelChanged(textModel: string) {
        this.setModelFromText();
        this.modelChange.emit(this.model);
    }
}


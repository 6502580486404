<div class="form-group row">
    <div class="col-md-12">
        <label class="col-form-label" title="The &quot;Cohort Statistics Flag&quot; feature marks outputs in the Workflow facet Bulk Data Entry when user-defined thresholds are met in the cohort wise stats.">Cohort Statistics Flag</label>
        <input type="checkbox"
               id="flagSelected"
               name="flagSelected"
               [(ngModel)]="flagIsChecked"
               (ngModelChange)="flagIsCheckedChangeHandler()"
               [disabled]="disabled" />
    </div>
    <div class="col-md-12 cohort-stats-table" *ngIf="flagIsChecked">
        <table class="table" style="border: 1px solid #ddd; width: 10%;">
            <tbody>
                <tr>
                    <td></td>
                    <th class="text-nowrap" style="text-align: center;">Average</th>
                    <th class="text-nowrap" style="text-align: center;">Median</th>
                    <th class="text-nowrap" style="text-align: center;">Std Dev</th>
                    <td>
                        <info-tooltip [text]="'Add values to define a range for flag display. Add a value to either field to create a lower or upper limit. Negative values may be used.'"></info-tooltip>
                    </td>
                </tr>
                <tr>
                    <th class="text-nowrap">
                        Flag at
                    </th>
                    <td class="text-nowrap">
                        <label for="avgFlagMinimum">&#8805;</label>
                        <input type="text"
                               name="avgFlagMinimum"
                               style="width: 75px;"
                               [(ngModel)]="avgFlagMinimum"
                               (ngModelChange)="avgFlagMinimumChangeHandler()"
                               pattern="[+-]?(\d+([.]\d{0,10})?(e[+-]?\d+)?|[.]\d+(e[+-]?\d+)?)"
                               [disabled]="disabled" />
                    </td>
                    <td class="text-nowrap">
                        <label for="mdnFlagMinimum">&#8805;</label>
                        <input type="text"
                               name="mdnFlagMinimum"
                               style="width: 75px;"
                               [(ngModel)]="mdnFlagMinimum"
                               (ngModelChange)="mdnFlagMinimumChangeHandler()"
                               pattern="[+-]?(\d+([.]\d{0,10})?(e[+-]?\d+)?|[.]\d+(e[+-]?\d+)?)"
                               [disabled]="disabled" />
                    </td>
                    <td class="text-nowrap">
                        <label for="stDevFlagMinimum">&#8805;</label>
                        <input type="text"
                               name="stDevFlagMinimum"
                               style="width: 75px;"
                               [(ngModel)]="stDevFlagMinimum"
                               (ngModelChange)="stDevFlagMinimumChangeHandler()"
                               pattern="[+-]?(\d+([.]\d{0,10})?(e[+-]?\d+)?|[.]\d+(e[+-]?\d+)?)"
                               [disabled]="disabled" />
                    </td>
                    <td></td>
                </tr>
                <tr>
                    <td></td>
                    <td class="text-nowrap">
                        <label for="avgFlagMaximum">&#8804;</label>
                        <input type="text"
                               name="avgFlagMaximum"
                               style="width: 75px;"
                               [(ngModel)]="avgFlagMaximum"
                               (ngModelChange)="avgFlagMaximumChangeHandler()"
                               pattern="[+-]?(\d+([.]\d{0,10})?(e[+-]?\d+)?|[.]\d+(e[+-]?\d+)?)"
                               [disabled]="disabled" />
                    </td>
                    <td class="text-nowrap">
                        <label for="mdnFlagMaximum">&#8804;</label>
                        <input type="text"
                               name="mdnFlagMaximum"
                               style="width: 75px;"
                               [(ngModel)]="mdnFlagMaximum"
                               (ngModelChange)="mdnFlagMaximumChangeHandler()"
                               pattern="[+-]?(\d+([.]\d{0,10})?(e[+-]?\d+)?|[.]\d+(e[+-]?\d+)?)"
                               [disabled]="disabled" />
                    </td>
                    <td class="text-nowrap">
                        <label for="stDevFlagMaximum">&#8804;</label>
                        <input type="text"
                               name="stDevFlagMaximum"
                               style="width: 75px;"
                               [(ngModel)]="stDevFlagMaximum"
                               (ngModelChange)="stDevFlagMaximumChangeHandler()"
                               pattern="[+-]?(\d+([.]\d{0,10})?(e[+-]?\d+)?|[.]\d+(e[+-]?\d+)?)"
                               [disabled]="disabled" />
                    </td>
                    <td></td>
                </tr>
            </tbody>
        </table>
    </div>
</div>


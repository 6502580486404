<div class="wizard-body">

    <h4 class="wizard-heading text-warning">
        <i class="fa fa-2x fa-question-circle" aria-hidden="true"></i>
        Are you ready to save your settings?
    </h4>

    <div class="intro-text">
        <p>
            Click <em>Next</em> to set up your workgroup with the values you have entered.
        </p>
    </div>

</div>

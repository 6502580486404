import { Pipe, PipeTransform } from "@angular/core";
import {
    JobCharacteristic,
    SampleCharacteristic,
    StudyCharacteristic,
    TaxonCharacteristic,
    JobCharacteristicIACUCProtocol,
    JobCharacteristicJobSubtype,
    JobCharacteristicJobType,
    TaxonCharacteristicTaxon,
    SampleCharacteristicSampleType,
    StudyCharacteristicStudyType
} from "../../common/types";
import { CharacteristicTypeNameEnum } from "../models";

/**
 * Provides a comma separated list of Characteristic links, depending on the type.
 */
@Pipe({
    name: 'characteristicLinksList'
})
export class CharacteristicLinksListPipe implements PipeTransform {
    transform(value: any, characteristicType: string, jobCharacteristicLink?: string) {
        if (!value) {
            return '';
        }
        switch (characteristicType) {
            case CharacteristicTypeNameEnum.Taxon:
                return value.TaxonCharacteristicTaxon.map((tct: TaxonCharacteristicTaxon) => tct.cv_Taxon.Taxon).join(", ");
            case CharacteristicTypeNameEnum.Sample:
                return value.SampleCharacteristicSampleType.map((scst: SampleCharacteristicSampleType) => scst.cv_SampleType?.SampleType).join(", ");
            case CharacteristicTypeNameEnum.Job:
                if (jobCharacteristicLink === 'subtype') {
                    return value.JobCharacteristicJobSubtype.map((jcjs: JobCharacteristicJobSubtype) => jcjs.cv_JobSubtype?.JobSubtype).join(", ");
                } else if (jobCharacteristicLink === 'iacuc') {
                    return value.JobCharacteristicIACUCProtocol.map((jcip: JobCharacteristicIACUCProtocol) => jcip.cv_IACUCProtocol?.IACUCProtocol).join(", ");
                } else {
                    return value.JobCharacteristicJobType.map((jcjt: JobCharacteristicJobType) => jcjt.cv_JobType?.JobType).join(", ");
                }
            case CharacteristicTypeNameEnum.Study:
                return value.StudyCharacteristicStudyType.map((scst: StudyCharacteristicStudyType) => scst.cv_StudyType?.StudyType).join(", ");
        }
    }
}

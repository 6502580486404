import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';

import {
    ANOMALY_DIRECTIONS,
    IOT_ALERT_DEFINITION_TYPES,
    IoTService,
} from './iot.service';

const THRESHOLD_PARAM_NAME = 'Threshold';
const SENSITIVITY_PARAM_NAME = 'Sensitivity';
const ANOMALY_DIRECTION_PARAM_NAME = 'Direction';
const RUN_INTERVAL_SECONDS_PARAM_NAME = 'RunIntervalSeconds';
const WINDOW_SIZE_SECONDS_PARAM_NAME = 'WindowSizeSeconds';

import { 
    BaseDetail,
    BaseDetailService,
    PageState 
} from '../common/facet';

@Component({
    selector: 'iot-alert-definition-details',
    templateUrl: './iot-alert-definition-details.component.html',
})
export class IoTAlertDefinitionDetailsComponent extends BaseDetail {
    @Input() facet: any = null;
    @Input() pageState: PageState;
    @Output() exit: EventEmitter<void> = new EventEmitter<void>();
    @Output() next: EventEmitter<void> = new EventEmitter<void>();
    @Output() previous: EventEmitter<void> = new EventEmitter<void>();

    saving = false;

    readonly COMPONENT_LOG_TAG = 'iot-alert-definition-detail';
    readonly iotAlertDefinitionTypes = IOT_ALERT_DEFINITION_TYPES;
    readonly anomalyDirections = ANOMALY_DIRECTIONS;

    deviceModels: any[] = null;
    private _alertDefinition: any = null;
    private _deviceModel: any = null;
    private _deviceModelOutput: any = null;

    constructor(
        private baseDetailService: BaseDetailService,
        private iotService: IoTService
    ) {
        super(baseDetailService);
        this.iotService.getDeviceModels().then((deviceModels: any[]) => {
            this.deviceModels = deviceModels;
            this.ensureValidParams();

            if (deviceModels && deviceModels.length && this._deviceModel === null) {
                this.deviceModel = deviceModels[0];
            }
        });
    }

    @Input()
    get alertDefinition() {
        return this._alertDefinition;
    }

    set alertDefinition(alertDefinition: any) {
        this._alertDefinition = alertDefinition;

        const activeADParam = this.activeAlertDefinitionParameter;
        if (activeADParam !== null
            && activeADParam.DeviceModelOutput !== null
            && activeADParam.DeviceModelOutput.DeviceModel !== null) {
            this._deviceModelOutput = activeADParam.DeviceModelOutput;
            this._deviceModel = activeADParam.DeviceModelOutput.DeviceModel;
        }

        this.ensureValidParams();
    }

    set alertDefinitionType(alertDefinitionType: string) {
        if (this._alertDefinition) {
            const beforeActiveParam = this.activeAlertDefinitionParameter;
            this._alertDefinition.AlertDefinitionType = alertDefinitionType;
            const afterActiveParam = this.activeAlertDefinitionParameter;

            if (beforeActiveParam !== afterActiveParam && afterActiveParam !== null) {
                afterActiveParam.DeviceModelOutput = this._deviceModelOutput;
            }

            this.ensureValidParams();
        }
    }

    get alertDefinitionType(): string {
        if (this._alertDefinition) {
            return this._alertDefinition.AlertDefinitionType;
        } else {
            return null;
        }
    }

    onCancel() {
        this.iotService.cancelAlertDefinition(this.alertDefinition);
    }

    get isAnomalyDetectionAlert() {
        return this._alertDefinition.AlertDefinitionType.startsWith('IoT AD');
    }

    get isThresholdAlert() {
        return this._alertDefinition.AlertDefinitionType.startsWith('IoT Threshold');
    }

    private ensureValidParams() {
        if (this._alertDefinition === null) {
            return;
        }

        if (this._alertDefinition.AlertDefinitionType === null) {
            this._alertDefinition.AlertDefinitionType = IOT_ALERT_DEFINITION_TYPES[0].value;
        }

        if (this._alertDefinition.AlertDefinitionDescription === null) {
            this._alertDefinition.AlertDefinitionDescription = '';
        }

        if (this._alertDefinition.AlertDefinitionName === null) {
            this._alertDefinition.AlertDefinitionName = '';
        }

        if (this.isAnomalyDetectionAlert) {
            if (this.findParameterNamed(SENSITIVITY_PARAM_NAME) === null) {
                this.sensitivityValue = 5;
            }

            if (this.findParameterNamed(ANOMALY_DIRECTION_PARAM_NAME) === null) {
                this.anomalyDirectionValue = ANOMALY_DIRECTIONS[0].value;
            }

            if (this.findParameterNamed(RUN_INTERVAL_SECONDS_PARAM_NAME) === null) {
                this.runIntervalSeconds = 30;
            }

            if (this.findParameterNamed(WINDOW_SIZE_SECONDS_PARAM_NAME) === null) {
                this.windowSizeSeconds = 60 * 60 * 4;
            }
        } else if (this.isThresholdAlert) {
            if (this.findParameterNamed(THRESHOLD_PARAM_NAME) === null) {
                this.thresholdValue = 0;
            }
        }
    }

    get runIntervalSeconds(): number {
        const param = this.findParameterNamed(RUN_INTERVAL_SECONDS_PARAM_NAME);
        if (param) {
            return parseFloat(param.ParameterValue);
        } else {
            return 0;
        }
    }

    set runIntervalSeconds(value: number) {
        const param = this.findOrCreateParameterNamed(RUN_INTERVAL_SECONDS_PARAM_NAME);
        param.ParameterValue = value.toString();
    }

    get windowSizeSeconds(): number {
        const param = this.findParameterNamed(WINDOW_SIZE_SECONDS_PARAM_NAME);
        if (param !== null) {
            return parseFloat(param.ParameterValue);
        } else {
            return param;
        }
    }

    set windowSizeSeconds(value: number) {
        const param = this.findOrCreateParameterNamed(WINDOW_SIZE_SECONDS_PARAM_NAME);
        param.ParameterValue = value.toString();
    }

    get thresholdValue(): number {
        const param = this.findParameterNamed(THRESHOLD_PARAM_NAME);
        if (param !== null) {
            return parseFloat(param.ParameterValue);
        } else {
            return null;
        }
    }

    set thresholdValue(value: number) {
        const param = this.findOrCreateParameterNamed(THRESHOLD_PARAM_NAME);
        param.ParameterValue = value.toString();
    }

    get sensitivityValue(): number {
        const param = this.findParameterNamed(SENSITIVITY_PARAM_NAME);
        if (param !== null) {
            return parseFloat(param.ParameterValue);
        } else {
            return param;
        }
    }

    set sensitivityValue(value: number) {
        const param = this.findOrCreateParameterNamed(SENSITIVITY_PARAM_NAME);
        param.ParameterValue = value.toString();
    }

    get anomalyDirectionValue(): string {
        const param = this.findParameterNamed(ANOMALY_DIRECTION_PARAM_NAME);
        if (param !== null) {
            return param.ParameterValue;
        } else {
            return param;
        }
    }

    set anomalyDirectionValue(value: string) {
        const param = this.findOrCreateParameterNamed(ANOMALY_DIRECTION_PARAM_NAME);
        param.ParameterValue = value.toString();
    }

    private findParameterNamed(name: string): any {
        if (this._alertDefinition) {
            const param = this._alertDefinition.AlertDefinitionParameter.find((currParam: any) => {
                return currParam.ParameterName === name;
            });

            if (param === undefined) {
                return null;
            } else {
                return param;
            }
        } else {
            return null;
        }
    }

    private findOrCreateParameterNamed(name: string): any {
        if (this._alertDefinition) {
            let param = this.findParameterNamed(name);
            if (!param) {
                param = this.iotService.createIoTAlertDefinitionParameter(
                    this._alertDefinition,
                    name);
            }

            return param;
        } else {
            return null;
        }
    }

    get deviceModel(): any {
        return this._deviceModel;
    }

    set deviceModel(deviceModel: any) {
        if (deviceModel === this._deviceModel) {
            return;
        }

        if (deviceModel !== null
            && this._deviceModel !== null
            && deviceModel.C_DeviceModel_key === this._deviceModel.C_DeviceModel_key) {
            return;
        }

        this._deviceModel = deviceModel;

        if (deviceModel !== null && deviceModel.DeviceModelOutput.length) {
            this.deviceModelOutput = deviceModel.DeviceModelOutput[0];
        }
    }

    get deviceModelOutput(): any {
        return this._deviceModelOutput;
    }

    set deviceModelOutput(deviceModelOutput: any) {
        this._deviceModelOutput = deviceModelOutput;

        const activeParam = this.activeAlertDefinitionParameter;
        if (activeParam !== null) {
            activeParam.DeviceModelOutput = deviceModelOutput;
        }
    }

    get activeAlertDefinitionParameter() {
        if (this._alertDefinition === null) {
            return null;
        }

        const activeParamNameMap = {
            'IoT Threshold Upper': 'Threshold',
            'IoT Threshold Lower': 'Threshold',
            'IoT AD TSpike': 'Sensitivity',
            'IoT AD ZSpike': 'Sensitivity',
            'IoT AD Level Change': 'Sensitivity',
        };

        for (const adParam of this._alertDefinition.AlertDefinitionParameter) {
            if (adParam.ParameterName ===
                activeParamNameMap[this._alertDefinition.AlertDefinitionType]) {
                return adParam;
            }
        }

        return null;
    }
}

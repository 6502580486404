import { Injectable, NgZone } from '@angular/core';
import { breeze } from '@services/breeze';
import { AnimalHealthRecordChangeService } from './animal-health-record-change.service';
import { TaskInstanceChangeService } from './task-instance-change.service';
import { EntityInit, PropertyChange } from './entity-changes.interface';

@Injectable()
export class EntityChangeService implements EntityInit, PropertyChange {

    zone: NgZone;

    // state variables
    private _entityManager: any;
    private _propChangeHandlerMap: Record<string, any>;
    private _entityInitHandlerMap: Record<string, any>;
    private _anyChangeHandlers: ((changes: any) => void)[];

    constructor(
        zone: NgZone
    ) {
        this.zone = zone;

        this._propChangeHandlerMap = {};
        this._entityInitHandlerMap = {};
        this._anyChangeHandlers = [];

        this._addAllEntityHandlers();
    }

    /*
    * Add a global entity property change handler
    *  e.g. onPropertyChange('Animal', 'AnimalStatus', handlerFunction);
    *
    *  For simplicity of maintenance we are only allowing 1 callback
    *  per entity/property key.
    */
    public onPropertyChange(
        entityName: string,
        propertyName: string,
        callback: (changes: any) => void
    ) {

        if (!this._propChangeHandlerMap.hasOwnProperty(entityName)) {
            this._propChangeHandlerMap[entityName] = {};
        }

        this._propChangeHandlerMap[entityName][propertyName] = callback;
    }

    /*
    * Add a global entity init handler
    *  e.g. onEntityInit('Animal', handlerFunction);
    *
    *  For simplicity of maintenance we are only allowing 1 callback
    *  per entity.
    */
    public onEntityInit(
        entityName: string,
        callback: (changes: any) => void
    ) {
        this._entityInitHandlerMap[entityName] = callback;
    }


    public onAnyChange(
        callback: (changes: any) => void
    ): { unsubscribe: () => void } {
        this._anyChangeHandlers.push(callback);

        return {
            unsubscribe: () => {
                const index = this._anyChangeHandlers.indexOf(callback);
                if (index > -1) {
                    this._anyChangeHandlers.splice(index, 1);
                }
            }
        };
    }

    public init(entityManager: any) {
        this._entityManager = entityManager;
        this._entityManager.entityChanged.subscribe((changes: any) => {
            this._entityChangedHandler(changes);
        });
    }

    private _entityChangedHandler(changes: any) {
        // eslint-disable-next-line
        let action: any = changes.entityAction;

        const entity: any = changes.entity;
        const entityName: string = this._getEntityName(entity);

        if (action.name === breeze.EntityAction.PropertyChange.getName()) {
            const propertyName: string = changes.args.propertyName;

            if (this._propChangeHandlerMap.hasOwnProperty(entityName) &&
                this._propChangeHandlerMap[entityName].hasOwnProperty(propertyName)
            ) {
                const callback = this._propChangeHandlerMap[entityName][propertyName];
                this.zone.run(() => {
                    callback(entity);
                });
            }
        }

        // Check any events that signal we have a newly loaded entity
        //   or what our app would treat as a newly loaded entity
        if (action.name === breeze.EntityAction.Attach.getName()
            || action.name === breeze.EntityAction.AttachOnQuery.getName()
            || action.name === breeze.EntityAction.AttachOnImport.getName()
            || action.name === breeze.EntityAction.MergeOnQuery.getName()
            || action.name === breeze.EntityAction.MergeOnImport.getName()
            || action.name === breeze.EntityAction.MergeOnSave.getName()
        ) {
            if (this._entityInitHandlerMap.hasOwnProperty(entityName)) {
                const callback = this._entityInitHandlerMap[entityName];
                this.zone.run(() => {
                    callback(entity);
                });
            }
        }

        // call the any change handlers
        for (const callback of this._anyChangeHandlers) {
            callback(changes);
        }
    }

    private _getEntityName(entity: any): string {
        let name = '';
        if (entity.entityAspect &&
            entity.entityAspect._entityKey &&
            entity.entityAspect._entityKey.entityType
        ) {
            const entityType: any = entity.entityAspect._entityKey.entityType;
            name = entityType.shortName;
        }

        return name;
    }


    /*
    * NOTE: I think there is a better design pattern
    *   for composing these class relationships and initializing things,
    *   but I can't think of it right now. Ripe for refactor.
    *   -kevin.stone
    */
    private _addAllEntityHandlers() {
        (new AnimalHealthRecordChangeService(this)).initHandlers();
        (new TaskInstanceChangeService(this)).initHandlers();
    }

}

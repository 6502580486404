import { notEmpty } from '../../common/util';
import { AVAILABLE_FUNCTIONS } from './available-functions';

export function getExpressionTypeFactory(expressionTree: any): ExpressionType {
    switch (expressionTree.type) {
        case TYPE_NAMES.Literal:
            return new LiteralExpression(expressionTree);
        case TYPE_NAMES.Identifier:
            return new IdentifierExpression(expressionTree);
        case TYPE_NAMES.BinaryExpression:
            return new BinaryExpression(expressionTree);
        case TYPE_NAMES.CallExpression:
            return new CallExpression(expressionTree);
        default:
            return null;
    }
}

export function calculateResultFactory(expressionTree: any, identifierMap: any): number {
    const exprType = getExpressionTypeFactory(expressionTree);    
    if (exprType) {        
        return exprType.calculateResult(identifierMap);
    }
    return NaN;
}

export function validateExpressionFactory(expressionTree: any, validVariableSet: any): string {
    const exprType = getExpressionTypeFactory(expressionTree);
    if (exprType) {
        return exprType.validate(validVariableSet);
    }
    // generic message if we don't know the type
    return 'invalid expression';
}

interface ExpressionType {
    name: string;
    expressionTree: any;
    calculateResult: (identifierMap: any) => number;
    validate: (validVariableSet: any) => string;
}

const TYPE_NAMES = {
    BinaryExpression: 'BinaryExpression',
    CallExpression: 'CallExpression',
    Identifier: 'Identifier',
    Literal: 'Literal'
};

export class LiteralExpression implements ExpressionType {
    name = TYPE_NAMES.Literal;
    expressionTree: any;
    
    constructor(expressionTree: any) {
        this.expressionTree = expressionTree;
    }

    calculateResult(identifierMap: any) {
        const value = this.expressionTree.value;
        if (typeof value === 'number') {
            return value;
        } else {
            return NaN;
        }
    }

    validate(validVariableSet: any): string {
        const value = this.expressionTree.value;
        if (typeof value === 'number') {
            return null;
        } else {
            return `invalid expression: failed to parse at "${this.expressionTree.raw}"`;
        }
    }
}

export class IdentifierExpression implements ExpressionType {
    name = TYPE_NAMES.Identifier;
    expressionTree: any;
    
    constructor(expressionTree: any) {
        this.expressionTree = expressionTree;
    }

    calculateResult(identifierMap: any) {
        const value = identifierMap[this.expressionTree.name];
        /* eslint-disable */
        if (value === 0) {
        /* eslint-enable */
            return 0;
        } else {
            return value ? value : NaN;
        }

        
    }

    validate(validVariableSet: any): string {
        const name = this.expressionTree.name;
        if (validVariableSet[name]) {
            return null;
        } else {
            if (name.startsWith('input_')) {
                return 'invalid expression: an input variable in your ' +
                    'expression has been removed from the task';
            } else if (name.startsWith('output_')) {
                return 'invalid expression: an output variable in your ' +
                    'expression has been removed from the task';
            } else {
                return `invalid expression: cannot resolve ` +
                    `"${name}" to a valid identifier`;
            }
        }
    }
}

export class BinaryExpression implements ExpressionType {
    name = TYPE_NAMES.BinaryExpression;
    expressionTree: any;
    
    constructor(expressionTree: any) {
        this.expressionTree = expressionTree;
    }


    calculateResult(identifierMap: any): number {
        // recurse left & right expression to get operator values
        const left = calculateResultFactory(this.expressionTree.left, identifierMap);
        const right = calculateResultFactory(this.expressionTree.right, identifierMap);
        switch (this.expressionTree.operator) {
            case '+':
                return parseFloat((left + right).toFixed(10));
            case '-':
                return parseFloat((left - right).toFixed(10));
            case '*':
                return parseFloat((left * right).toFixed(10)); 
            case '/':
                return parseFloat((left / right).toFixed(10));
            default:
                return NaN;
        }
    }

    validate(validVariableSet: any): string {
        switch (this.expressionTree.operator) {
            case '+':
            case '-':
            case '*':
            case '/':
                const left = this.expressionTree.left;
                const right = this.expressionTree.right;
                // recurse left and right values for full validation
                return validateExpressionFactory(left, validVariableSet) ||
                    validateExpressionFactory(right, validVariableSet);
            default:
                return null;
        }
    }
}

export class CallExpression implements ExpressionType {
    name = TYPE_NAMES.CallExpression;
    expressionTree: any;

    constructor(expressionTree: any) {
        this.expressionTree = expressionTree;
    }

    calculateResult(identifierMap: any): number {
        if (this.isValidFunction()) {
            const args: any[] = this.getArguments();
            if (notEmpty(args)) {
                // recurse arguments to resolve values
                const argumentValues: number[] = args.map((node) => {
                    return calculateResultFactory(node, identifierMap);
                });

                return this.runFunction(argumentValues);
            }
        } else {
            return NaN;
        }
    }

    validate(validVariableSet: any): string {
        if (this.isValidFunction()) {
            const args = this.getArguments();
            if (notEmpty(args)) {
                let errorMsg = null;
                // recurse all arguments to ensure full expression is valid
                for (const node of args) {
                    errorMsg = errorMsg || validateExpressionFactory(node, validVariableSet);
                }
                return errorMsg;
            }
        } else {
            const name = this.getCallee();
            return `invalid expression: cannot resolve ` + 
                `"${name}" to a valid function`;
        }
    }

    private isValidFunction() {
        return this.getFunction();
    }

    /**
     * returns null if not found
     */
    private getFunction() {
        const name = this.getCallee();
        const func = AVAILABLE_FUNCTIONS.find((item) => {
            return item.name === name;
        });
        return func ? func.calcFunction : null;
    }

    private getCallee(): string {
        let name = this.expressionTree.callee ? this.expressionTree.callee.name : '';
        name = name ? name.toLowerCase() : name;
        return name;
    }

    private getArguments(): any[] {
        return this.expressionTree.arguments;
    }

    private runFunction(argumentValues: number[]): number {    
        const calcFunction = this.getFunction();
        let calcValue = NaN;
        try {
            calcValue = calcFunction(...argumentValues);
        } catch (error) {
            console.error(error);
        }     
        return calcValue;
    }

}



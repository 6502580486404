<form role="form" class="form-label-right">
    <div class="modal-header justify-content-center">
        <h4 class="modal-title">Cohorts Filter</h4>
    </div>

    <div class="modal-body">

        <div class="form-group row">
            <div class="col-md-3">
                <label class="col-form-label" style="display: block;">Animal Name</label>
                <div class="text-right">
                    <one-many-toggle #animalNameToggle></one-many-toggle>
                </div>
            </div>
            <div class="col-md-9">
                <multi-control-wrap [toggleComponent]="animalNameToggle"
                                    [(singleModel)]="filter.animalName"
                                    [(manyModel)]="filter.animalNames">
                    <multi-control name="single">
                        <input type="text" name="animalName"
                               [(ngModel)]="filter.animalName"
                               class="form-control input-medium"
                               data-auto-focus="animalName" />
                        <small class="form-text text-muted">
                            Find names <em>containing</em> this value.
                        </small>
                    </multi-control>
                    <multi-control name="many">
                        <multi-paste-input [(model)]="filter.animalNames"
                                           [limit]="MULTI_PASTE_INPUT_LIMIT"
                                           [rows]="3"></multi-paste-input>
                        <small class="form-text text-muted">
                            Names separated by
                            <code>,</code> or <code>Enter</code>.
                            Limit: {{MULTI_PASTE_INPUT_LIMIT}}.
                        </small>
                    </multi-control>
                </multi-control-wrap>
            </div>
        </div>

        <div class="form-group row">
            <div class="col-md-3">
                <label class="col-form-label" style="display: block;">Cohort Name</label>
                <div class="text-right">
                    <one-many-toggle #cohortNameToggle></one-many-toggle>
                </div>
            </div>
            <div class="col-md-9">
                <multi-control-wrap [toggleComponent]="cohortNameToggle"
                                    [(singleModel)]="filter.cohortName"
                                    [(manyModel)]="filter.cohortNames">
                    <multi-control name="single">
                        <input type="text" name="cohortName"
                               [(ngModel)]="filter.cohortName"
                               class="form-control input-medium" />
                        <small class="form-text text-muted">
                            Find names <em>containing</em> this value.
                        </small>
                    </multi-control>
                    <multi-control name="many">
                        <multi-paste-input [(model)]="filter.cohortNames"
                                           [limit]="MULTI_PASTE_INPUT_LIMIT"
                                           [rows]="3"></multi-paste-input>
                        <small class="form-text text-muted">
                            Names separated by
                            <code>,</code> or <code>Enter</code>.
                            Limit: {{MULTI_PASTE_INPUT_LIMIT}}.
                        </small>
                    </multi-control>
                </multi-control-wrap>
            </div>
        </div>
        <div class="form-group row" *ngIf="allowSplitting">
            <label class="col-md-3 col-form-label">Method</label>
            <div class="col-md-9">
                <climb-multi-select [(model)]="filter.Methods"
                                    [values]="matchingMethods"
                                    [keyProperty]="'C_MatchingMethod_key'"
                                    [valueProperty]="'MatchingMethod'"
                                    [placeholder]="'Select Methods...'"></climb-multi-select>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-md-3 col-form-label">Created By</label>
            <div class="col-md-9">
                <user-select [(model)]="filter.createdBy"
                             [forceValidSelection]="false"></user-select>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-md-3 col-form-label">{{'Study' | translate}}</label>
            <div class="col-md-9">
                <study-multiselect [(model)]="filter.C_Study_key"
                                   [excludeLocked]="false"></study-multiselect>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-md-3 col-form-label">{{'Job' | translate}}</label>
            <div class="col-md-9">
                <job-multiselect [(model)]="filter.jobs"></job-multiselect>
            </div>
        </div>

    </div><!-- /.modal-body -->

    <div class="modal-footer">
        <span class="btn-set">
            <button type="submit"
                    class="btn btn-fw-wide btn-lg btn-primary"
                    (click)="filterClicked($event)">
                Filter
            </button>
            <button type="button"
                    class="btn btn-fw-wide btn-lg btn-secondary"
                    (click)="clearClicked()">
                Clear Filter
            </button>
        </span>
        <span class="btn-set">
            <button type="button"
                    class="btn btn-lg btn-secondary"
                    (click)="closeClicked()">
                Close
            </button>
        </span>
    </div>
</form>

/*
* Configure Breeze to use ES6 Promises
*/
// Add some functions to ES6 Promises to work better with Breeze
// .defer()
// .finally()
import './promise-defer-shim';
import './promise-finally-shim';

import * as breeze from 'breeze-client';
/*
 *  HACK (kevin.stone): I don't know a better way to pass this
 *    Promise constructor to Breeze.
 */
breeze.config.setQ(<any> Promise);

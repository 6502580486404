import type { StoredFileMap } from './stored-file-map.interface';

export interface StoredFile {
    C_StoredFile_key: number;
    C_Workgroup_key: number;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    Description: string;
    FileName: string;
    FileType: string;
    FileURI: string;
    FriendlyFileName: string;
    ModifiedBy: string;
    StoredFileMap: StoredFileMap[];
    Version: number;
}

import { ScheduleType } from './../../protocol/models/schedule-type.enum';
import { getSafeProp, uniqueArrayFromPropertyPath, uniqueDateArray } from '../../common/util';
import { convertValueToLuxon } from '@common/util/date-time-formatting/convert-value-to-luxon';

/**
 * Calculates date values for Protocol tasks
 */
export class ProtocolDateCalculator {

    readonly MINUTES_IN_DAY = 24 * 60;

    /**
     * Schedule all dependent Due Dates for any task in `allTasks`
     *   that is dependent on `changedTask`. Continues recursively
     *   until all dependencies are scheduled.
     * 
     *   Returns the number of updated tasks
     *
     * @param allTasks 
     * @param changedTask 
     */
    scheduleDependentDueDates(allTasks: any[], changedTask: any): number {
        // recursively schedule any dependent tasks
        return this._scheduleRelativeProtocolTask(allTasks, changedTask);
    }

    /**
     * Calculate DateDue for targetTask based on Material field(s).
     * Then calculate any dependent task DateDue values.
     * 
     * @param allTasks 
     * @param targetTask 
     */
    scheduleMaterialDueDates(allTasks: any[], targetTask: any) {
        const scheduleType = getSafeProp(targetTask, 'ProtocolTask.cv_ScheduleType.ScheduleType');

        switch (scheduleType) {
            case ScheduleType.BirthDate:
                this._scheduleAnimalBirthDate(targetTask);
                break;
        }

        // recursively schedule any dependent tasks
        this._scheduleRelativeProtocolTask(allTasks, targetTask);
    }

    private _scheduleRelativeProtocolTask(allTasks: any[], changedTask: any): number {
        if (!allTasks ||
            !changedTask ||
            !changedTask.ProtocolTask
        ) {
            // do nothing if invalid data passed in
            return 0;
        }

        let taskCount = 0;
        const changedProtocolTaskKey = changedTask.ProtocolTask.C_ProtocolTask_key;

        const dependentTasks = allTasks.filter((taskInstance) => {
            const relativeProtocolTaskKey = getSafeProp(
                taskInstance, 'ProtocolTask.C_RelativeProtocolTask_key'
            );
            const scheduleType = getSafeProp(
                taskInstance, 'ProtocolTask.cv_ScheduleType.ScheduleType'
            );
            
            const hasProtocol = taskInstance.ProtocolTask && true;
            const notSelf = taskInstance !== changedTask;
            const isDependent = relativeProtocolTaskKey === changedProtocolTaskKey;
            const isRelativeTaskType = (scheduleType === ScheduleType.RelativeTaskDue) ||
                (scheduleType === ScheduleType.RelativeTaskComplete);
            
            return hasProtocol && notSelf && isDependent && isRelativeTaskType;
        });

        for (const dependentTask of dependentTasks) {

            const protocolTask = dependentTask.ProtocolTask;

            // calculate the due date of the dependent task
            const minutesPerUnit =  protocolTask.cv_TimeUnit.MinutesPerUnit;
            const relativeTime = protocolTask.TimeFromRelativeTask;
            const timeRelation = protocolTask.cv_TimeRelation.TimeRelation;
            const scheduleType = protocolTask.cv_ScheduleType.ScheduleType;

            let sourceDate = null;
            if (scheduleType === ScheduleType.RelativeTaskDue) {
                sourceDate = changedTask.DateDue;
            } else if (scheduleType === ScheduleType.RelativeTaskComplete) {
                sourceDate = changedTask.DateComplete;
                if (!sourceDate) {
                    // Fallback to the Due Date if the task is not completed
                    sourceDate = changedTask.DateDue;
                }
            }

            if (!sourceDate) {
                // don't process if we don't have a valid relative Date/Time
                continue;
            }

            // set the dependent task = the calculated due date
            dependentTask.DateDue = this._calculateScheduleDate(
                sourceDate, minutesPerUnit, relativeTime, timeRelation
            );

            // Count this task
            taskCount += 1;

            // run this again for any sub-dependencies
            taskCount += this._scheduleRelativeProtocolTask(allTasks, dependentTask);
        }

        return taskCount;
    }

    private _scheduleAnimalBirthDate(changedTask: any) {
        if (!changedTask ||
            !changedTask.ProtocolTask ||
            !changedTask.TaskMaterial
        ) {
            // do nothing if invalid data passed in
            return;
        }

        // get unique list of DateBorn values for all animals in task
        const bornDates: Date[] = uniqueArrayFromPropertyPath(
            changedTask, 'TaskMaterial.Material.Animal.DateBorn'
        );
        const uniqueBornDates = uniqueDateArray(bornDates);

        if (uniqueBornDates.length === 1) {
            
            const protocolTask = changedTask.ProtocolTask;

            // calculate the due date of the dependent task
            const minutesPerUnit =  protocolTask.cv_TimeUnit.MinutesPerUnit;
            const relativeTime = protocolTask.TimeFromRelativeTask;
            const timeRelation = protocolTask.cv_TimeRelation.TimeRelation;

            const birthDate: Date = uniqueBornDates[0];

            // set the dependent task = the calculated due date
            changedTask.DateDue = this._calculateScheduleDate(
                birthDate, minutesPerUnit, relativeTime, timeRelation
            );
        }
    }

    private _calculateScheduleDate(
        startDate: Date, 
        minutesPerUnit: number,
        relativeTime: number,
        timeRelation: 'Before' | 'After'
    ): Date {
        let differenceAmount = Math.round(relativeTime * minutesPerUnit);

        let diffUnit: 'minutes' | 'days' = 'minutes';
        if (differenceAmount >= this.MINUTES_IN_DAY) {
            // use days if we can, because it is more accurate
            //   for changes like daylight saving time (DST)
            differenceAmount = differenceAmount / this.MINUTES_IN_DAY;
            diffUnit = 'days';
        }

        let dateDue: Date;
        if (differenceAmount < 1) {
            dateDue = startDate;
        } else {
            const diffObject = {};
            diffObject[diffUnit] = Math.ceil(differenceAmount);
            console.log(diffObject);
            if (timeRelation === 'Before') {
                dateDue = convertValueToLuxon(startDate)
                    .minus(diffObject)
                    .toJSDate();
            } else {
                dateDue = convertValueToLuxon(startDate)
                    .plus(diffObject)
                    .toJSDate();
            }
        }

        return dateDue;
    }
}

import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output
} from '@angular/core';

import { sortObjectArrayByProperty } from '../common/util/sort-array';

import { LineService } from '../lines/line.service';
import { softCompare, uniqueArrayFromPropertyPath, getSafeProp } from '../common/util';

@Component({
    selector: 'genotype-assay-select',
    template: `
    <active-vocab-select
            [(model)]="model"
            (modelChange)="modelChangedHandler()"
            [vocabChoices]="_filteredAssayChoices"
            [keyFormatter]="genotypeAssayKeyFormatter"
            [optionFormatter]="genotypeAssayFormatter"
            [readonly]="!parentLineKey && !noFilterOnNullParent"
            [nullable]="true"
    ></active-vocab-select>
    `
})
export class GenotypeAssaySelectComponent implements OnChanges, OnInit {
    @Input() model: any;
    @Output() modelChange: EventEmitter<any> = new EventEmitter<any>();
    @Input() parentLineKey: any;
    @Input() assayChoices: any[];
    @Input() noFilterOnNullParent: boolean;

    // state variables
    parentLine: any;
    _filteredAssayChoices: any[];

    // track if parent has been initialized yet
    _isParentInitialized: boolean;

    constructor(
        private lineService: LineService
    ) {
        this._isParentInitialized = false;
    }

    ngOnInit() {
        this.refreshParentLine().then(() => {
            this.refreshAssayChoices();
        });
    }

    ngOnChanges(changes: any) {
        // re-trigger genotype assay filter if
        //   inputs have changed
        if (changes.parentLineKey) {
            this.refreshParentLine().then(() => {
                this.refreshAssayChoices();

                if (changes.parentLineKey.currentValue !== null &&
                    changes.parentLineKey.currentValue !== undefined
                ) {
                    this._isParentInitialized = true;
                }

                this.clearModelIfInvalid();
            });

        } else if (
            changes.model ||
            changes.assayChoices
        ) {
            this.refreshAssayChoices();
            this.clearModelIfInvalid();
        }


    }

    // Ensure Line has genotype assay relationship
    refreshParentLine(): Promise<void> {
        if (this.parentLineKey) {
            const expandClauses = ['LineGenotypeAssay'];
            return this.lineService.getLine(this.parentLineKey, expandClauses)
                .then((line: any) => {
                    this.parentLine = line;
                });
        }
        this.parentLine = null;
        return Promise.resolve();
    }

    refreshAssayChoices() {
        this._filteredAssayChoices = this.filterAssayChoices(
            this.assayChoices,
            this.parentLine,
            this.model,
            this.noFilterOnNullParent
        );
    }

    /*
    * Filter out assays not available in parent line
    */
    filterAssayChoices(
        assays: any[], 
        parentLine: any,
        modelKey: number = null,
        noFilterOnNullLine = false
    ) {

        if (!assays) {
            return [];
        }

        if (!parentLine &&
            noFilterOnNullLine
        ) {
            return assays;
        }

        const lineGenotypeAssays = uniqueArrayFromPropertyPath(
            parentLine, 'LineGenotypeAssay'
        );
        const availableAssayKeys = uniqueArrayFromPropertyPath(
            parentLine, 'LineGenotypeAssay.C_GenotypeAssay_key'
        );
        const parentLineKey = getSafeProp(parentLine, 'C_Line_key');

        // Add the LineGenotypeAssay.SortOrder to each assay
        for (const assay of assays) {
            const assayKey = assay.C_GenotypeAssay_key;

            // Set default sort order so each assay has the property for sorting
            assay.LineGenotypeAssaySortOrder = -1;

            // Find the matching Line-GenotypeAssay and add its SortOrder
            const matchingAssay = lineGenotypeAssays.find((lineGenotypeAssay) => {
                return lineGenotypeAssay.C_Line_key === parentLineKey &&
                    lineGenotypeAssay.C_GenotypeAssay_key === assayKey;
            });
            if (matchingAssay && matchingAssay.SortOrder) {
                assay.LineGenotypeAssaySortOrder = matchingAssay.sortOrder;
            }
        }

        const assaysSorted = sortObjectArrayByProperty(assays, 'LineGenotypeAssaySortOrder');

        return assaysSorted.filter((assay: any) => {
            const isModel = softCompare(assay.C_GenotypeAssay_key, modelKey);
            return isModel || (availableAssayKeys.indexOf(assay.C_GenotypeAssay_key) > -1);
        });
    }


    clearModelIfInvalid() {
        // Do not clear out current model if parent has
        //  not been initialized.
        // This would cause component to clear model on startup
        if (!this._isParentInitialized) {
            return;
        }

        if (!this.isModelValid(this.model, this._filteredAssayChoices)) {
            this.model = null;
            this.modelChange.emit(this.model);
        }
    }

    isModelValid(model: any, filteredChoices: any[]) {
        for (const assay of filteredChoices) {
            // use == here to test string and integer
            /* eslint-disable-next-line */
            if (assay.C_GenotypeAssay_key == model) {
                return true;
            }
        }

        return false;
    }

    modelChangedHandler() {
        this.modelChange.emit(this.model);
    }

    // Formatters for <select> input
    genotypeAssayKeyFormatter = (item: any) => {
        return item.C_GenotypeAssay_key;
    }
    genotypeAssayFormatter = (item: any) => {
        return item.GenotypeAssay;
    }
}

import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';

import { randomId } from './util';

/**
 * Select control for filtering by IsUrgent values.
 */
@Component({
    selector: 'urgent-status-select',
    template: `
    <select name="{{fieldName}}"
            class="form-control input-medium"
            [(ngModel)]="model"
            (ngModelChange)="modelChangeHandler()"
            [disabled]="readonly"
            [required]="required">
        <option *ngIf="nullable"></option>
        <option value="true">Urgent</option>
        <option value="false">Not Urgent</option>
    </select>
    `
})
export class UrgentStatusSelectComponent implements OnInit {
    @Input() model: number;
    @Input() nullable = true;
    @Input() readonly: boolean;
    @Input() required: boolean;
    @Output() modelChange: EventEmitter<any> = new EventEmitter<any>();

    // state
    fieldName: string;
    ngOnInit() {
        this.fieldName = "urgentStatusSelect-" + randomId();
    }

    modelChangeHandler() {
        this.modelChange.emit(this.model);
    }
}

import type { GenotypeAllele } from './genotype-allele.interface';
import type { LineAllele } from './line-allele.interface';
import type { Marker } from './marker.interface';
import type { cv_AlleleClass } from './cv-allele-class.interface';

export interface Allele {
    AlleleName: string;
    AlleleSymbol: string;
    AlleleSymbolMarkup: string;
    C_AlleleClass_key: number | null;
    C_Allele_key: number;
    C_Marker_key: number | null;
    C_Workgroup_key: number;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    ExpressedInMutant: boolean;
    GenotypeAllele: GenotypeAllele[];
    HeritabilityData: string;
    LineAllele: LineAllele[];
    Marker: Marker;
    ModifiedBy: string;
    Version: number;
    cv_AlleleClass: cv_AlleleClass;
}

<h2>
    {{objectName}}
    <small>
        <i class="fa fa-fw fa-comment-o" aria-hidden="true"></i>{{notes?.length | number}}
    </small>
</h2>


<ng-container *ngIf="!isObjectNew; else newObject">

    <loading-overlay *ngIf="loading"></loading-overlay>

    <!-- New Note -->
    <div *ngIf="!readonly" class="new-note-container">
        <div class="form-group row">
            <div class="col-md-12">
                <textarea name="newNoteText"
                          [id]="newNoteTextInputId"
                          [ngClass]="{'textarea-expanded': newNoteTextExpanded}"
                          [(ngModel)]="newNoteText"
                          (focus)="onNewNoteTextFocus()"
                          (blur)="onNewNoteTextBlur()"
                          placeholder="Add a note&hellip;"
                          [maxlength]="MAX_NOTE_LENGTH"
                          rows="1"
                          autocomplete="off"
                          class="form-control"></textarea>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-md-7 small">
                <ng-container *ngIf="showCharactersRemaining()">
                    Characters remaining: {{getCharactersRemaining() | number}}
                </ng-container>
            </div>
            <div class="col-md-5 new-note-buttons">
                <button type="button"
                        class="btn btn-fw-wide btn-primary"
                        [disabled]="addButtonDisabled()"
                        (click)="addNote()">
                    Add Note
                </button>
                <button type="button"
                        class="btn btn-fw-wide btn-secondary"
                        [disabled]="!newNoteText"
                        (click)="cancelNote()">
                    Cancel
                </button>
            </div>
        </div>
    </div>


    <ng-container *ngIf="notes | notEmpty">

        <!-- Filter -->
        <div class="form-group row filter-container clearfix">
            <div class="col-md-12 text-right">
                <hr />
                <div class="input-group filter-input-group">
                    <label for="{{filterInputId}}" class="sr-only">Filter notes</label>
                    <input type="text"
                           class="form-control filter-control"
                           [id]="filterInputId"
                           placeholder="Filter notes&hellip;"
                           autocorrect="off"
                           spellcheck="false"
                           (ngModelChange)="filterList($event)"
                           [(ngModel)]="filterTerm" />
                    <div class="input-group-append">
                        <span class="input-group-text">
                            <i class="fa fa-search" aria-hidden="true"></i>
                        </span>
                    </div>
                </div>
            </div>
        </div>


        <!-- List -->
        <div class="row notes-list"
             *ngFor="let note of notesDisplay | orderBy:'DateCreated':true |
                     paginate: { itemsPerPage: ITEMS_PER_PAGE, currentPage: notePage, id: paginationId }">

            <div class="col-md-12 note-container">
                <h4 class="note-metadata">
                    <span class="note-author">
                        {{note.CreatedBy | userFullName}}
                    </span>
                    <span class="note-timestamp small">
                        {{note.DateCreated | formatShortDateTime}}
                    </span>
                </h4>

                <!-- Keep on one line to style correctly -->
                <div class="note-text">{{note.NoteText}}</div>
            </div>

        </div>

        <pagination-controls [id]="paginationId"
                             (pageChange)="notePage = $event"
                             [autoHide]="true"
                             class="ngx-table-paginator"></pagination-controls>

    </ng-container><!-- /notes notEmpty -->

</ng-container>


<ng-template #newObject>
    <fieldset class="fieldset-bordered notes-container">

        <legend>Notes</legend>

        <p>
            <em>Please save this {{objectType | lowercase}} before adding notes.</em>
        </p>

    </fieldset>
</ng-template>

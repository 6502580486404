<div class="detail-form-wrapper">

    <div class="detail-header">
        <h3>Birth</h3>

        <pager-toolbar [itemName]="'birth'"
                       [logTag]="COMPONENT_LOG_TAG"
                       [first]="pageState.isFirst"
                       [last]="pageState.isLast"
                       [promptForSave]="false"
                       (previous)="onSwitchView('previous')"
                       (next)="onSwitchView('next')"></pager-toolbar>

        <detail-toolbar [facetPrivilege]="facet.Privilege"
                        [logTag]="COMPONENT_LOG_TAG"
                        (exit)="onSwitchView('exit')"
                        [promptForSave]="false"
                        [emitOnSave]="true"
                        (genericSaveEmitter)="onSaveBirth()">

            <span additional-groups>
                <button type="button" class="btn btn-link"
                        title="Jump to Animals"
                        (click)="scrollToAnimals()">
                    Animals
                    <i class="fa fa-angle-double-down" aria-hidden="true"></i>
                </button>
            </span>
            <div class="btn-group" role="group" additional-groups>
                <div class="btn-group" view-notes-button
                    [readonly]="facet.Privilege === 'ReadOnly'"
                    [pkName]="'C_Birth_key'"
                    [pkValue]="birth.C_Birth_key"
                    [objectName]="birth.BirthID"
                    [objectType]="'Birth'"
                    [isObjectNew]="birth | isNew"
                    appInsightsClick="click-notes-from-birth">
                </div>
            </div>

        </detail-toolbar>
    </div>

    <form name="birthForm" id="birthForm"
          class="detail-form"
          #birthForm="ngForm">
        <fieldset [disabled]="facet.Privilege === 'ReadOnly'">

            <div class="form-group row">
                <label class="col-md-2 col-form-label">Birth ID</label>
                <div class="col-md-10">
                    <input data-automation-id="birth-id-input"
                           type="text"
                           name="birthID"
                           #birthID="ngModel"
                           [(ngModel)]="birth.BirthID"
                           [disabled]="(birth | isNew) && birthNamingActive"
                           [required]="!(birth | isNew) || !birthNamingActive"
                           class="form-control input-medium birth-detail-id"
                           validateUnique
                           uniqueEntity="Births"
                           uniqueProperty="BirthID"
                           [entityPK]="birth.C_Birth_key"
                           entityPKName="C_Birth_key"
                           maxlength="75" />
                    <small class="form-text text-muted"
                           *ngIf="birthID.errors?.unique && birthID.dirty && birth.BirthID !== originalBirthID">
                        <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                        This ID already exists.
                    </small>
                    <small class="form-text text-muted"
                           *ngIf="(birth | isNew) && birthNamingActive">
                        The Birth ID will be assigned automatically.
                    </small>
                </div>
            </div>

            <div class="form-group row">
                <label class="col-md-2 col-form-label">Mating ID</label>
                <div class="col-md-10">
                    <mating-select [(model)]="birth.C_MaterialPool_key"
                                   [required]="birth.C_MaterialPool_key === null ? true : false"
                                   (modelChange)="selectMating($event)"></mating-select>
                </div>
            </div>

            <div class="form-group row">
                <label class="col-md-2 col-form-label">Housing ID</label>
                <div class="col-md-10">
                    <mating-housing-select [(model)]="birth.C_MaterialPool_key"
                                           [required]="birth.C_MaterialPool_key===null ? true : false"
                                           (modelChange)="selectMating($event)"></mating-housing-select>
                </div>
            </div>

            <div class="form-group row">
                <label class="col-md-2 col-form-label">Mating Details</label>
                <div class="col-md-10">
                    <birth-mating-table [birth]="birth"
                                        [birthMaterials]="birth.BirthMaterial"
                                        [mating]="birth.Mating"
                                        [facetPrivilege]="facet.Privilege"></birth-mating-table>
                </div>
            </div>

            <div class="form-group row">
                <label class="col-md-2 col-form-label">Mating Date</label>
                <div class="col-md-10 col-form-label" style="text-align: left;">
                    {{ birth.Mating?.DateMating | formatShortDate }}
                </div>
            </div>



            <div class="form-group row">
                <label class="col-md-2 col-form-label">{{'Line' | translate}}</label>
                <div class="col-md-10">
                    <p class="form-control-plaintext">{{birth.Mating?.Line?.LineName}}</p>
                </div>
            </div>

            <div class="form-group row">
                <label class="col-md-2 col-form-label">Location</label>
                <div class="col-md-10">
                    <p class="form-control-plaintext">{{birth.Mating?.MaterialPool?.CurrentLocationPath}}</p>
                </div>
            </div>

            <div class="form-group row">
                <label class="col-md-2 col-form-label">Status</label>
                <div class="col-md-10">
                    <active-vocab-select [(model)]="birth.C_BirthStatus_key"
                                         [vocabChoices]="birthStatuses"
                                         [keyFormatter]="birthStatusKeyFormatter"
                                         [optionFormatter]="birthStatusFormatter"
                                         [nullable]="true"></active-vocab-select>
                </div>
            </div>

            <div class="form-group row">
                <label class="col-md-2 col-form-label">Birth Date</label>
                <div class="col-md-4">
                    <climb-ngb-date data-automation-id="birth-date-input"
                                    name="Birth Date"
                                    #dateControl="ngModel"
                                    [(ngModel)]="birth.DateBorn"
                                    (ngModelChange)="birthDateBornChanged()"
                                    ngDefaultControl></climb-ngb-date>
                </div>
            </div>

            <div class="form-group row">
                <label class="col-md-2 col-form-label">Wean Date</label>
                <div class="col-md-4">
                    <climb-ngb-date name="Wean Date"
                                    #dateControl="ngModel"
                                    [(ngModel)]="birth.DateWean"
                                    ngDefaultControl></climb-ngb-date>
                </div>
                <div class="col-md-5">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text">
                                Days to Wean {{birth.Mating?.Line?.cv_Taxon?.CommonName}}
                            </span>
                        </div>
                        <input type="number"
                               name="daysToWean"
                               [(ngModel)]="daysToWean"
                               step="any"
                               min="0"
                               size="2"
                               class="form-control input-medium" />
                        <div class="input-group-append"
                              *ngIf="facet.Privilege === 'ReadWrite'">
                            <button type="button"
                                    (click)="calculateWeanDate($event)"
                                    class="btn btn-secondary">
                                <i class="fa fa-calculator" aria-hidden="true"></i>
                                Calculate Date
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-group row">
                <label class="col-md-2 col-form-label">Comments</label>
                <div class="col-md-10">
                    <textarea name="birthComments"
                              [(ngModel)]="birth.Comments"
                              class="form-control input-medium"
                              maxlength="500"
                              rows="2"></textarea>
                </div>
            </div>

            <div class="form-group row">
                <label class="col-md-2 col-form-label">Live Born Count</label>
                <div class="col-md-2">
                    <input type="number"
                           name="liveCount"
                           [(ngModel)]="birth.LiveCount"
                           min="0"
                           class="form-control input-medium">
                </div>
            </div>

            <div class="form-group row">
                <label class="col-md-2 col-form-label">Stillborn Count</label>
                <div class="col-md-2">
                    <input type="number"
                           name="stillbornCount"
                           min="0"
                           [(ngModel)]="birth.StillbornCount"
                           class="form-control input-medium">
                </div>
            </div>

            <div class="form-group row">
                <label class="col-md-2 col-form-label">Dead Count</label>
                <div class="col-md-2">
                    <input type="number"
                           name="deadCount"
                           min="0"
                           [(ngModel)]="birth.DeadCount"
                           class="form-control input-medium">
                </div>
            </div>
            <div class="form-group row">
                <label class="col-md-2 col-form-label">Foster</label>
                <div class="col-md-2">
                    <input class="birth-detail-foster mt-1" type="checkbox" name="isFoster" [(ngModel)]="birth.IsFoster" />
                </div>
                <div class="col-md-8">
                    <div  class="form-group row">
                        <label class="col-md-5 col-form-label">Foster Housing ID</label>
                        <div class="col-md-7">
                            <housing-select [(model)]="birth.C_FosterMaterialPool_key"></housing-select>
                        </div>
                    </div>
                </div>
            </div>


            <div class="form-group row">
                <label class="col-md-2 col-form-label">Created By</label>
                <div class="col-md-10">
                    <p class="form-control-plaintext">
                        {{birth.CreatedBy | userFullName}}
                    </p>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <a class="{{animalScrollElementClass}}"></a>
                    <birth-animal-table #animalTable
                                        [facet]="facet"
                                        [birth]="birth"
                                        [readonly]="facet.Privilege !== 'ReadWrite'"></birth-animal-table>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <h4>Wean Housing</h4>
                    <birth-housing-table #birthHousingTable
                                         [birth]="birth"
                                         [facetPrivilege]="facet.Privilege"></birth-housing-table>
                </div>
            </div>

        </fieldset>

        <!-- New fieldset that is never disabled so as not to affect controls in child tables -->
        <fieldset>
            <div class="form-group row">
                <div class="col-md-12">
                    <detail-task-table #detailTaskTable
                                       [parent]="birth"
                                       [taskAssociations]="birth.TaskBirth"
                                       [taskType]="TaskType.Birth"
                                       [readonly]="facet.Privilege === 'ReadOnly'"
                                       [facet]="facet"
                                       [tableOptions]="detailTaskTableOptions"
                                       [tableSort]="taskTableSort"
                                       [(inputsExpanded)]="birth.inputsExpanded"
                                       [(tasksExpanded)]="birth.tasksExpanded"
                                       [(taskAnimalsExpanded)]="birth.taskAnimalsExpanded"
                                       [(taskCohortsExpanded)]="birth.taskCohortsExpanded"
                                       [(taskSamplesExpanded)]="birth.taskSamplesExpanded"
                                       (taskInstanceAdd)="addTaskBirth($event)"></detail-task-table>
                </div>
            </div>
        </fieldset>
    </form>
</div>

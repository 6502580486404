import { Injectable } from '@angular/core';
import { BaseEntityService } from '../../services/base-entity.service';
import { DataManagerService } from '../../services/data-manager.service';
import { HousingStateService } from './housing-state.service';
import { Animal, Entity, MaterialPool } from '../../common/types';
import { AnimalStateService } from '../../animals/services/animal-state.service';
import { HousingSaveService } from './housing-save.service';

@Injectable()
export class HousingService  extends BaseEntityService {

    constructor(
        private dataManager: DataManagerService,
        private housingStateService: HousingStateService,
        private animalStateService: AnimalStateService,
        private housingSaveService: HousingSaveService,
    ) {
        super();
    }

    async bulkCreateForAnimals(animals: Entity<Animal>[], housings: Entity<MaterialPool>[]): Promise<void> {
        const changes = [];
        for (const animal of animals) {
            changes.push(...this.animalStateService.getAllRelatedChanges(animal));
        }
        for (const housing of housings) {
            changes.push(...this.housingStateService.getAllRelatedChanges(housing));
        }
        await this.housingSaveService.bulkSave(changes, true, 'animal');
    }
}

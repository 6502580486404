import { Directive } from '@angular/core';
import { BaseDetailService } from './base-detail.service';
import { Entity } from '@common/types';
import { EntityChangeService } from '../../entity-changes/entity-change.service';
import { IGlpFacetStateServiceBulk } from '@services/interfaces/glp-facet-state-service.interface';
import { IGlpFacetSaveService } from '@services/interfaces/glp-facet-save-service.interface';
import { GlpBaseDetailBase } from './glp-base-detail-base.directive';

/**
 * Base class for facet detail pages that will support facet-level saving for several
 *
 * @Directive - Angular directive decorator
 * @extends {GlpBaseDetailBase} - Extends from BaseDetail
 */
@Directive()
export abstract class GlpBaseDetailBulk<T> extends GlpBaseDetailBase<T> {
    constructor(
        protected baseDetailService: BaseDetailService,
        protected entitySaveService: IGlpFacetSaveService,
        private facetStateService: IGlpFacetStateServiceBulk,
        protected entityChangeService: EntityChangeService,
    ) {
        super(baseDetailService, entitySaveService, entityChangeService);
    }

    /**
     * Validate and save the facet detail entity
     */
    async saveEntity(): Promise<void> {
        const errorMessage = await this.validate();

        if (errorMessage) {
            this.validationErrorsPresent = true;
            this.loggingService.logError(
                this.baseDetailService.saveChangesService.generateSaveErrorMessage(
                    this.facet.FacetName,
                    this.facetView,
                    errorMessage,
                ),
                null,
                this.COMPONENT_LOG_TAG,
                true,
            );

            return;
        }

        this.validationErrorsPresent = false;
        await this.beforeSave();
        const entityForSaving = this.getEntityForSaving();

        await this.entitySaveService.bulkSave(entityForSaving);

        return;
    }

    /**
     * Abstract method to get the facet entities list for saving
     * @returns {Entity<T>[]}
     */
    abstract getEntityForSaving(): Entity<T>[];

    /**
     * Check if there are changes to be saved.
     * Overriding base class method to be able to check only for changes specific for facet
     * @returns {boolean}
     */
    hasChanges(): boolean {
        const entityForSaving = this.getEntityForSaving();

        return this.facetStateService.hasChangesBulk(entityForSaving);
    }
}

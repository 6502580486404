import type { InputDefault } from './input-default.interface';
import type { OutputDefault } from './output-default.interface';
import type { Protocol } from './protocol.interface';
import type { ProtocolTaskSection } from './protocol-task-section.interface';
import type { SampleGroup } from './sample-group.interface';
import type { TaskGroup } from './task-group.interface';
import type { TaskInstance } from './task-instance.interface';
import type { WorkflowTask } from './workflow-task.interface';
import type { cv_ScheduleType } from './cv-schedule-type.interface';
import type { cv_TimeRelation } from './cv-time-relation.interface';
import type { cv_TimeUnit } from './cv-time-unit.interface';

export interface ProtocolTask {
    AnchorDay: number | null;
    C_ProtocolTaskSection_key: number | null;
    C_ProtocolTask_key: number;
    C_Protocol_key: number;
    C_RelativeProtocolTask_key: number | null;
    C_ScheduleType_key: number | null;
    C_TaskGroup_key: number | null;
    C_TimeRelation_key: number | null;
    C_TimeUnit_key: number | null;
    C_WorkflowTask_key: number;
    C_Workgroup_key: number;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    DependentProtocolTask: ProtocolTask[];
    InputDefault: InputDefault[];
    ModifiedBy: string;
    OutputDefault: OutputDefault[];
    Protocol: Protocol;
    ProtocolTaskSection: ProtocolTaskSection;
    RelativeProtocolTask: ProtocolTask;
    RelativeToStudyStart: boolean;
    SampleGroup: SampleGroup[];
    ScheduleWindow: number | null;
    SortOrder: number | null;
    TaskAlias: string;
    TaskGroup: TaskGroup;
    TaskInstance: TaskInstance[];
    TimeFromRelativeTask: number | null;
    Version: number;
    WorkflowTask: WorkflowTask;
    cv_ScheduleType: cv_ScheduleType;
    cv_TimeRelation: cv_TimeRelation;
    cv_TimeUnit: cv_TimeUnit;
}

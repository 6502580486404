<table class="table">
    <thead>
        <tr>
            <th class="icon-cell" *ngIf="facetPrivilege !== 'ReadOnly'">
                <a class="add-item-link" (click)="create()">
                    <i class="fa fa-plus-circle" title="Add location"></i>
                </a>
            </th>
            <th class="icon-cell" *ngIf="facetPrivilege !== 'ReadOnly'"></th>
            <th>Location</th>
            <th class="text-nowrap">Date In</th>
            <th class="text-nowrap">Date Out</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let location of materialLocations; let i = index">
            <td class="icon-cell" *ngIf="facetPrivilege !== 'ReadOnly'">
                <div [ngClass]="location.selectingLocation ? 'form-control-plaintext' : ''">
                    <a (click)="remove(location)">
                        <i class="fa fa-remove remove-item" title="Delete location"></i>
                    </a>
                </div>
            </td>
            <td class="icon-cell" *ngIf="facetPrivilege !== 'ReadOnly'">
                <a *ngIf="!location.selectingLocation"
                   class="location-edit-link"
                   (click)="location.selectingLocation = true">
                    <i class="fa fa-pencil" title="Edit location"></i>
                </a>
                <div *ngIf="location.selectingLocation" class="form-control-plaintext">
                    <a (click)="editEnd(location, dateIn, dateOut)"
                       class="location-edit-complete-link">
                        <i class="fa fa-check-circle" title="Stop editing"></i>
                    </a>
                </div>
            </td>
            <td class="text-nowrap">
                <location-path *ngIf="!location.selectingLocation"
                               [locationPositionKey]="location.LocationPosition.C_LocationPosition_key"
                               [treeLevelMax]="2"></location-path>
                <location-chooser *ngIf="location.selectingLocation"
                                  [(location)]="location.LocationPosition"
                                  [readonly]="facetPrivilege === 'ReadOnly'"></location-chooser>
            </td>
            <td>
                <climb-ngb-date *ngIf="location.selectingLocation"
                                #dateControl="ngModel"
                                [(ngModel)]="location.DateIn"
                                [ngModelOptions]="{ standalone: true }"
                                (ngModelChange)="dateChanged(location, 'DateIn', dateIn, dateOut)"
                                [required]="true"
                                #dateIn></climb-ngb-date>
                <div *ngIf="!location.selectingLocation">
                    {{location.DateIn | formatShortDate}}
                </div>
            </td>
            <td>
                <climb-ngb-date *ngIf="location.selectingLocation"
                                #dateControl="ngModel"
                                [(ngModel)]="location.DateOut"
                                [ngModelOptions]="{ standalone: true }"
                                (ngModelChange)="dateChanged(location, 'DateOut', dateIn, dateOut)"
                                [required]="i < materialLocations.length - 1"
                                #dateOut></climb-ngb-date>
                <div *ngIf="!location.selectingLocation">
                    {{location.DateOut | formatShortDate}}
                </div>
            </td>
        </tr>
    </tbody>
</table>

import {Injectable} from '@angular/core';
import {DataManagerService} from '@services/data-manager.service';
import {Entity as InternalEntity} from '@common/types';
import {Entity as BreezeEntity, EntityState} from 'breeze-client';
import {IGlpFacetStateService} from "./interfaces/glp-facet-state-service.interface";

@Injectable()
export abstract class GlpBaseFacetStateService<T> implements IGlpFacetStateService {

    protected constructor(protected dataManagerService: DataManagerService) {
    }

    /**
     * Get all related changes for the facet entity.
     * @param {InternalEntity<T>} entity - The facet entity.
     * @returns {BreezeEntity[]} An array of related BreezeEntity changes.
     */
    getAllRelatedChanges(entity: InternalEntity<T>): BreezeEntity[] {
        const changes: BreezeEntity[] = [];

        const relatedChanges = this.dataManagerService.getAllEntityRelatedPropertyChanges(entity);

        const onlyModifiedEntities = this.dataManagerService.filterEntitiesByEntityState(
            [...relatedChanges.values()],
            [EntityState.Added, EntityState.Modified, EntityState.Deleted]);

        changes.push(...onlyModifiedEntities.values());
        changes.push(...this.getDeleteNavPropertyChanges(entity));
        changes.push(...this.getRelatedCollectionChanges(entity));

        return changes;
    }


    /**
     * Resets the state of an entity in Breeze cache and all related changes.
     * @async
     * @param {InternalEntity<T>} entity - The entity whose state is reset.
     * @returns {Promise<void>}
     */
    async resetStateInCache(entity: InternalEntity<T>): Promise<void> {
        const changesToDetach = this.getAllRelatedChanges(entity);
        this.resetBreezeEntityState(entity);

        for (const changesToElement of changesToDetach) {
            this.resetBreezeEntityState(changesToElement)
        }
    }

    /**
     * Marks a Breeze entity as unmodified and all related changes.
     * @async
     * @param {InternalEntity<T>} entity - The entity to mark as unmodified.
     * @returns {Promise<void>}
     */
    async markAsUnmodified(entity: InternalEntity<T>): Promise<void> {
        const changesToDetach = this.getAllRelatedChanges(entity);
        this.markBreezeEntityAsUnmodified(entity);

        for (const changesToElement of changesToDetach) {
            this.markBreezeEntityAsUnmodified(changesToElement)
        }
    }

    /**
     * Resets the BreezeEntity state based on the current entity state.
     * @private
     * @param {BreezeEntity} entity - The entity whose state is reset.
     */
    private resetBreezeEntityState(entity: BreezeEntity) {
        switch (entity.entityAspect.entityState) {
            case EntityState.Modified: {
                entity.entityAspect.setUnchanged();
            }
                break;
            case EntityState.Added: {
                entity.entityAspect.setDetached();
            }
                break;
            case EntityState.Deleted: {
                entity.entityAspect.setDetached();
            }
                break;
        }
    }

    /**
     * Marks a BreezeEntity as unmodified based on the current entity state.
     * @private
     * @param {BreezeEntity} entity - The entity to mark as unmodified.
     */
    private markBreezeEntityAsUnmodified(entity: BreezeEntity) {
        switch (entity.entityAspect.entityState) {
            case EntityState.Modified: {
                entity.entityAspect.setUnchanged();
            }
                break;
            case EntityState.Added: {
                entity.entityAspect.setUnchanged();
            }
                break;
            case EntityState.Deleted: {
                entity.entityAspect.setDetached();
            }
                break;
        }
    }

    /**
     * Determines if a Breeze entity has related changes.
     * @param {InternalEntity<T>} entity - The entity to check for changes.
     * @returns {boolean} Returns true if the entity has changes, false otherwise.
     */
    hasChanges(entity: InternalEntity<T>): boolean {
        const changes = this.getAllRelatedChanges(entity);
        return changes.length > 0;
    }

    abstract discardChanges(entity: InternalEntity<T>): void;
    /**
     * Get a collection of changes related to the facet entity's array type properties.
     *
     * @remarks
     * This method is employed to extract all modifications done to array/collection type properties via Breeze.
     * @param entity - The facet entity
     * @returns A collection of breeze changes for the entity
     */
    abstract getRelatedCollectionChanges(entity: InternalEntity<T>): BreezeEntity[];

    /**
     * Get a collection of delete changes related to the facet entity's navigation properties.
     *
     * @remarks
     * This method is needed to extract all delete modifications done to navigation properties via Breeze.
     * @param entity - The facet entity
     * @returns A collection of breeze changes for the entity
     */
    abstract getDeleteNavPropertyChanges(entity: InternalEntity<T>): BreezeEntity[];
}

import {
    Component,
    Input,
    OnInit,
} from '@angular/core';
import { DataManagerService } from '../../../services/data-manager.service';
import { softCompare } from '../../../common/util';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'create-placeholder-association-modal',
    templateUrl: './create-placeholder-association-modal.component.html'
})
export class CreatePlaceholderAssociationModalComponent implements OnInit {
    @Input() placeholders: any;
    @Input() job: any;
    jobCohorts: any[];
    showWarning = false;
    constructor(
        private dataManager: DataManagerService,
        public activeModal: NgbActiveModal
    ) {
        // Do nothing
    }

    ngOnInit() {
        this.initJob();
    }

    // Initialize the list of Animals
    private initJob(): Promise<any> {
        if (!this.job) {
            // Just in case, there is no Job
            this.jobCohorts = [];
            return Promise.resolve();
        }

        const expands = [
            'JobCohort.Cohort',
            'JobCohort.Cohort.CohortMaterial',
        ];

        return this.dataManager.ensureRelationships([this.job], expands).then(() => {
            // Find all the Cohorts
            this.jobCohorts = this.job.JobCohort.slice().map((cohort: any) => {
                cohort.placeholder = null;
                return cohort;
            }).filter((cohort: any) => {
                return cohort.Placeholder.length === 0;
            });
        }).then(() => {
            // Initialize the cohort sequence values
            this.initCohortSequence();
            this.showWarning = false;
            this.placeholders.forEach((placeholder: any, index: any) => {
                if (index < this.jobCohorts.length) {
                    placeholder.cohort = this.jobCohorts[index].C_JobCohort_key;
                    this.jobCohorts[index].placeholder = placeholder;
                } else {
                    placeholder.cohort = "null";
                }
                const jobCohort = this.jobCohorts.find(
                    (item: any) => (item.C_JobCohort_key === placeholder.cohort)
                );
                if (jobCohort && jobCohort.animalCounts) {
                    placeholder.animalCount = jobCohort.animalCounts.inJob;
                } else {
                    placeholder.animalCount = null;
                }
                if (!this.showWarning && placeholder.animalCount && placeholder.JobGroup && placeholder.animalCount !== placeholder.JobGroup.Number) {
                    this.showWarning = true;
                }
            });
        });
    }

    /**
     * Initialize the Animal JobMaterial.Sequence values to assign defaults and
     * smooth out gaps.
     */
    initCohortSequence() {
        // Sort by Sequence
        this.jobCohorts.sort(this.sortBySequence);
    }

    // Comparator to sort JobMaterial by Sequence
    sortBySequence = (a: any, b: any): number => {
        return (a.Sequence || 0) - (b.Sequence || 0);
    }

    /**
     * Make two way connection between placeholder and cohort
     */
    setPlaceholder(placeholder: any, event: any) {
        if (placeholder.cohort && placeholder.cohort !== "null") {
            // un assign old cohort
            const indexOld = this.jobCohorts.findIndex((cohort: any) => softCompare(cohort.C_JobCohort_key, placeholder.cohort));
            this.jobCohorts[indexOld].placeholder = null;
        }

        // assign new value
        placeholder.cohort = event;

        const jobCohort = this.jobCohorts.find(
            (item: any) => (item.C_JobCohort_key.toString() === placeholder.cohort)
        );
        if (jobCohort && jobCohort.animalCounts) {
            placeholder.animalCount = jobCohort.animalCounts.inJob;
        } else {
            placeholder.animalCount = null;
        }
        this.showWarning = placeholder.animalCount && placeholder.JobGroup && placeholder.animalCount !== placeholder.JobGroup.Number;        
        if (!this.showWarning) {
            this.placeholders.some((item: any) => {                
                return this.showWarning = item.animalCount && item.JobGroup && item.animalCount !== item.JobGroup.Number;
            });
        }

        if (placeholder.cohort && placeholder.cohort !== "null") {
            // assign new one
            const index = this.jobCohorts.findIndex((cohort: any) => softCompare(cohort.C_JobCohort_key, placeholder.cohort));
            this.jobCohorts[index].placeholder = placeholder;
        }
    }
}

import { 
    Directive, 
    ElementRef, 
    EventEmitter,
    Input,
    Output
} from '@angular/core';

@Directive({ selector: '[draggable]' })
export class DraggableDirective {

    @Output() dragStart: EventEmitter<void> = new EventEmitter<void>();
    @Output() dragStop: EventEmitter<void> = new EventEmitter<void>();

    constructor(private el: ElementRef) {
       this.attachDraggable(el);
    }

    // Is dragging currently disabled for this element?
    @Input() set dragDisabled(disabled: boolean) {
        const jqEl: any = jQuery(this.el.nativeElement);
        jqEl.draggable(disabled ? "disable" : "enable");
    }

    attachDraggable(el: ElementRef) {
        const jqEl: any = jQuery(el.nativeElement);
        // eslint-disable-next-line
        const self = this;

        /*
         * TODO (kstone): add template for helper drag handle
         */
        jqEl.draggable({
            animate: true,
            appendTo: ".workspace-container",
            containment: ".workspace-container",
            cursor: "move",
            // cursorAt: { top: 40, left: 50 },
            helper: 'clone',
            revert: "invalid,containment",
            start(event: any, ui: any) {
                self.dragStart.emit();
            },
            stop(event: any) {
                self.dragStop.emit();
            },
            zIndex: 9999
        });

    }
}

import {
    AfterContentInit,
    ChangeDetectionStrategy,
    Component,
    ContentChildren,
    ElementRef,
    HostBinding,
    HostListener,
    Injector,
    Input,
    QueryList,
} from '@angular/core';
import { IconDirective } from '@common/icons/icon.directive';
import { MixinSurfaceModeClass } from '@common/mixins';

export type InputSize = 'sm' | 'md' | 'lg';

@Component({
    selector: 'climb-input',
    templateUrl: './input.component.html',
    styleUrls: ['./input.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputComponent extends MixinSurfaceModeClass implements AfterContentInit {
    @ContentChildren(IconDirective) icons: QueryList<IconDirective> = new QueryList();

    private input: HTMLInputElement | null = null;

    @HostBinding('class')
    private get classes(): Record<string, boolean> {
        return {
            ['climb-input']: true,
        };
    }

    @Input()
    get size(): InputSize {
        return this.inputSize;
    }
    set size(inputSize: InputSize) {
        this.inputSize = inputSize;
        this.setIconSize();
    }
    private inputSize: InputSize = 'sm';

    @Input()
    error = false;

    @HostListener('click')
    onClick() {
        this.focusInput();
    }

    constructor(
        injector: Injector,
        private elementRef: ElementRef,
    ) {
        super(injector);
    }

    ngAfterContentInit(): void {
        this.setIconSize();
        this.input = this.elementRef.nativeElement.querySelector('input');
    }

    setIconSize(): void {
        const iconSize = this.getIconSize();
        this.icons
            .filter((icon) => icon.content.hasOwnProperty(iconSize))
            .forEach((icon) => icon.size = iconSize);
    }

    private getIconSize() {
        switch (this.size) {
            case 'sm': return 'sm';
            case 'md': return 'sm';
            case 'lg': return 'md';
        }
    }

    focusInput(options?: FocusOptions): void {
        this.input?.focus(options);
    }
}

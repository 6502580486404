import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';

const animationDuration = 300;

@Directive({
  selector: '[climbSwitch]',
})
export class SwitchDirective {
    @Output() changed: EventEmitter<boolean> = new EventEmitter();

    @HostListener('change')
    onChange(): void {
        // wait for slider animation
        setTimeout(() => {
             const state = this.elementRef.nativeElement.checked;
             this.changed.emit(state);
        }, animationDuration);
    }

    constructor(
        private elementRef: ElementRef<HTMLInputElement>,
    ) { }
}

import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    OnDestroy,
    ViewChildren,
} from '@angular/core';

import { WorkflowVocabService } from '../../services/workflow-vocab.service';

import { BaseFilter } from '@common/facet';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
    focusElementByQuery,
} from '@common/util';
import { TranslationService } from '@services/translation.service';
import { Subscription } from 'rxjs';
import { NgModel } from '@angular/forms';
import { LoggingService } from '@services/logging.service';
import { dateControlValidator } from '@common/util/date-control.validator';
import { Cohort, cv_AnimalClassification, cv_AnimalStatus, cv_JobStatus, cv_TaskStatus, cv_TaskType, WorkflowTask } from "@common/types";


@Component({
    selector: 'workflow-filter',
    templateUrl: './workflow-filter.component.html',
    styles: [`
        .align-checkbox-center {
            display: flex;
            align-items: center;
            white-space: nowrap;
        }

        .align-checkbox-center label {
            margin-bottom: 0;
        }

        .align-checkbox-center input {
            margin-top: -1px;
            margin-right: 5px;
        }
    `],
})
export class WorkflowFilterComponent extends BaseFilter implements OnInit, OnDestroy {
    @ViewChildren('dateControl') dateControls: NgModel[];
    @Input() filter: any = {};
    @Output() onFilter = new EventEmitter<any>();
    @Output() close = new EventEmitter<any>();
    @Input() isGLP = false;

    // CVs
    resources: any[] = [];
    taskStatuses: cv_TaskStatus[] = [];
    jobStatuses: cv_JobStatus[] = [];
    taskTypes: cv_TaskType[] = [];
    workflowTasks: WorkflowTask[] = [];
    cohortsNames: Cohort[] = [];
    animalStatuses: cv_AnimalStatus[] = [];
    animalClassifications: cv_AnimalClassification[] = [];

    _subs: Subscription[] = [];
    validationItems: { [key: string]: boolean; } = {

    };

    get isValid(): boolean {
        return Object.values(this.validationItems).every(item => item);
    }

    readonly MULTI_PASTE_INPUT_LIMIT = 150;

    constructor(
        private workflowVocabService: WorkflowVocabService,
        private activeModal: NgbActiveModal,
        private translationService: TranslationService,
        private loggingService: LoggingService,
    ) {
        super(activeModal);
    }

    ngOnInit() {
        this.cloneFilter();

        this.getCVs();

        focusElementByQuery('[data-auto-focus="jobName"]');
    }

    private cloneFilter() {
        const dateDueStart = this.filter.DateDueStart;
        const dateDueEnd = this.filter.DateDueEnd;

        this.filter = this.copyFilter(this.filter);
        this.filter.DateDueStart = dateDueStart;
        this.filter.DateDueEnd = dateDueEnd;

        if (this.filter.workflowTasks) {
            this.filter.WorkflowTaskKeys = this.filter.workflowTasks.map((item: WorkflowTask) => {
                return item.C_WorkflowTask_key;
            });
        }
    }

    ngOnDestroy() {
        for (const s of this._subs) {
            s.unsubscribe();
        }
    }

    private getCVs() {
        this.workflowVocabService.resources$
            .subscribe((data) => {
                this.resources = data.filter((resource) => {
                    return !resource.IsGroup;
                });
            });

        this.workflowVocabService.taskStatuses$
            .subscribe((data) => {
                this.taskStatuses = data;
            });

        this.workflowVocabService.taskTypes$
            .subscribe((data) => {
                this.taskTypes = this.setTranslatedTaskType(data);
            });

        this.workflowVocabService.workflowTasks$
            .subscribe((data) => {
                this.workflowTasks = data;
            });
        this.workflowVocabService.cohorts$
            .subscribe((data) => {
                this.cohortsNames = data;
            });
        this.workflowVocabService.jobStatuses$
            .subscribe((data) => {
                this.jobStatuses = data;
            });
        this.workflowVocabService.animalStatuses$
            .subscribe((data) => {
                this.animalStatuses = data;
            });
        this.workflowVocabService.animalClassifications$
            .subscribe((data) => {
                this.animalClassifications = data;
            });
    }

    setTranslatedTaskType(taskTypes: any) {
        for (const task of taskTypes) {
            task.TaskTypeTranslated = this.translationService.translate(task.TaskType);
        }
        return taskTypes;
    }

    filterClicked(event: Event) {
        const errMessage = dateControlValidator(this.dateControls);
        if (errMessage) {
            this.loggingService.logError(errMessage, null, '', true);
            return;
        }
        event.preventDefault();
        this.onFilter.emit(this.filter);
        this.close.emit();
        this.activeModal.close();
    }

    closeClicked() {
        this.close.emit();
        this.activeModal.dismiss();
    }

    clearClicked() {
        const isGrouped = this.filter.isGrouped;
        this.filter = {};
        this.filter.isGrouped = isGrouped;
        this.onFilter.emit(this.filter);
        this.close.emit();
        this.activeModal.close();
    }

    setValidationItem(key: string, value: boolean) {
        this.validationItems[key] = value;
    }
}

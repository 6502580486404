import { appInsightsTrackAjax } from './climb-web-settings';
import { AppInsights } from "applicationinsights-js";

export function initializeAppInsights() {
    const appInsightsKeyName = 'appInsightsInstrumentationKey';
    const appInsightsInstrumentationKey: string = window[appInsightsKeyName];
    AppInsights.downloadAndSetup({ 
        disableAjaxTracking: !appInsightsTrackAjax,
        instrumentationKey: appInsightsInstrumentationKey 
    });

    // track initial load as page view
    AppInsights.trackPageView();
}



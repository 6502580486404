import { getSafeProp } from './get-safe-prop';
import { notEmpty } from './not-empty';
import { sortObjectArrayByProperty } from './sort-array';

/**
 * Returns the prior MaterialPoolID from a list of MaterialPoolMaterials
 *   (i.e., the object with the latest DateIn and no DateOut).
 * @param materialPoolMaterials - list of MaterialPoolMaterials
 */
export function priorMaterialHousingID(materialPoolMaterials: any[]): string {
    let housingID = '';

    if (notEmpty(materialPoolMaterials)) {
        const openPools: any[] = getMaterialPoolMaterials(materialPoolMaterials);

        if (notEmpty(openPools)) {
            housingID = getLatestMaterialPoolID(openPools);
        }
    }

    return housingID;
}

function getMaterialPoolMaterials(materialPoolMaterials: any[]): any[] {
    // Items with a DateIn and DateOut
    return materialPoolMaterials.filter((materialPoolMaterial) => {
        return (materialPoolMaterial.DateOut && materialPoolMaterial.DateIn);
    });
}

function getLatestMaterialPoolID(materialPoolMaterials: any[]): string {
    // Sort by DateOut descending and use the first item
    const sorted = sortObjectArrayByProperty(materialPoolMaterials, 'DateOut', true);

    const out = getSafeProp(sorted[0], 'MaterialPool.MaterialPoolID');
    return out !== undefined ? out : '';
}

import {
    CellFormatterService,
    END_STATE_CLASS,
    TableOptions
} from '../common/datatable';
import { TranslationService } from '../services/translation.service';
import { addAuditColumnsToOptions } from '@common/datatable/utils';

export class StudyTableOptions {

    options: TableOptions;

    constructor(
        private cellFormatterService: CellFormatterService,
        private translationService: TranslationService
    ) {
        this.options = addAuditColumnsToOptions(cellFormatterService)(this.getDefaultOptions());
    }

    getDefaultOptions(): TableOptions {
        return {
            csvFileName: this.translationService.translate('studies').toLowerCase() + '.csv',
            enableDetailColumn: true,
            enableDraggable: false,
            enableSelectable: false,
            refreshOnColumnChange: true,
            rowClass: (params: any) => {
                const row = params.data;

                const classes: string[] = [];
                if (row && row.IsActive !== true) {
                    classes.push(END_STATE_CLASS);
                }
                return classes;
            },
            columns: [
                {
                    displayName: 'Name',
                    field: 'StudyName'
                },
                {
                    displayName: 'Type',
                    field: 'cv_StudyType.StudyType'
                },
                {
                    displayName: 'Status',
                    field: 'cv_StudyStatus.StudyStatus'
                },
                {
                    displayName: 'Approval Number',
                    field: 'ApprovalNumber'
                },
                {
                    displayName: 'Total Animals',
                    field: 'totalAnimalCount',
                    visible: false,
                    sortable: false
                },
                {
                    displayName: 'Available Animals',
                    field: 'availableAnimalCount',
                    visible: false,
                    sortable: false
                },
                {
                    displayName: 'Active', field: 'IsActive',
                    formatter: this.cellFormatterService.booleanFormatter,
                    exportFormatter: this.cellFormatterService.booleanExportFormatter,
                    maxWidth: 80,
                    visible: false
                },
                {
                    displayName: 'Locked', field: 'IsLocked',
                    formatter: this.cellFormatterService.lockedFormatter,
                    exportFormatter: this.cellFormatterService.booleanExportFormatter,
                    maxWidth: 80,
                    visible: false
                }
            ]
        };
    }
}

import { Component, Input, AfterViewInit, OnDestroy } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'reload-modal',
  template: `
    <div class="modal-header justify-content-center">
      <h4 class="modal-title">Page will be reloaded in {{ counter }} seconds</h4>
    </div>

    <div class="modal-body" style="vertical-align:top;">
      <div class="alert alert-warning" role="alert">
        <strong>Warning:</strong>
        {{ message }}
      </div>
    </div>
  `,
})
export class ReloadModalComponent implements AfterViewInit, OnDestroy {
  @Input() message: string;
  counter = 10;

  private interval?: number;

  constructor(
    private activeModal: NgbActiveModal,
  ) {}

  ngAfterViewInit(): void {
    this.interval = window.setInterval(() => {
      this.counter --;

      if (this.counter === 0) {
        window.clearInterval(this.interval);
        this.activeModal.close();
      }
    }, 1000);
  }

  ngOnDestroy(): void {
    window.clearInterval(this.interval);
  }
}

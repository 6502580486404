import { FormsModule } from '@angular/forms';
import { ImportFileUploadComponent } from './forms/import-file-upload.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ClimbCommonModule } from '../common/climb-common.module';
import { ClimbServicesModule } from '../services/climb-services.module';


import { ImportService } from './import.service';
import { ImportVocabService } from './import-vocab.service';
import { ImportFileDefinitionService } from './import-file-definition.service';
import { ImportMappingTargetService } from './import-mapping-target.service';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ClimbCommonModule,
        ClimbServicesModule
    ],
    declarations: [
        ImportFileUploadComponent
    ],
    exports: [
        ImportFileUploadComponent
    ],
    providers: [
        ImportService,
        ImportVocabService,
        ImportFileDefinitionService,
        ImportMappingTargetService
    ]
})
export class ImportSharedModule { }

import { Injectable } from '@angular/core';
import { Entity as InternalEntity, EnumerationItem } from '@common/types';
import { DataManagerService } from '@services/data-manager.service';
import { GlpBaseFacetStateServiceBulk } from '@services/glp-base-facet-state-bulk.service';
import { Entity as BreezeEntity } from 'breeze-client';

const ENUMERATION_KEY = 'C_EnumerationClass_key';

@Injectable()
export class EnumerationStateService extends GlpBaseFacetStateServiceBulk<EnumerationItem> {
    constructor(protected dataManagerService: DataManagerService) {
        super(dataManagerService);
    }

    getRelatedCollectionChanges(enumeration: InternalEntity<EnumerationItem>): BreezeEntity[] {
        const changes: BreezeEntity[] = [];

        changes.push(
            ...this.dataManagerService.getChangesToEntityByKey('EnumerationItem', ENUMERATION_KEY, enumeration.C_EnumerationClass_key),
        );

        return changes;
    }

    getChangesToRelatedUnlinkedEntityByKey<T>(
        entityType: string,
        relationKeyName: string,
        relationKeyValue: T[keyof T],
    ): InternalEntity<T>[] {
        return this.dataManagerService.getChangesToEntityByFilter(entityType, (item: InternalEntity<T>) => {
            if (item.entityAspect.entityState.isModified()) {
                return item.entityAspect.originalValues[relationKeyName] === relationKeyValue;
            }
        });
    }

    getDeleteNavPropertyChanges(enumeration: InternalEntity<EnumerationItem>): BreezeEntity[] {
        const changes: BreezeEntity[] = [];

        changes.push(
            ...this.getChangesToRelatedUnlinkedEntityByKey<EnumerationItem>(
                'TaxonCharacteristic',
                ENUMERATION_KEY,
                enumeration.C_EnumerationClass_key,
            ),
            ...this.getChangesToRelatedUnlinkedEntityByKey<EnumerationItem>(
                'Output',
                ENUMERATION_KEY,
                enumeration.C_EnumerationClass_key,
            ),
            ...this.getChangesToRelatedUnlinkedEntityByKey<EnumerationItem>(
                'Input',
                ENUMERATION_KEY,
                enumeration.C_EnumerationClass_key,
            ),
        );

        return changes;
    }

    discardChanges(): void {
        // TODO: implement discard
        throw new Error('Method not implemented.');
    }
}

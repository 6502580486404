import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChildren,
} from '@angular/core';

import { AppInsightsService } from '../../../services/app-insights.service';
import { LoggingService } from '../../../services/logging.service';
import { ReportingService } from '../../reporting.service';

import {
    ReportTypeItem,
} from '../../models';

import { DateFormatterService } from '@common/util/date-time-formatting';
import {
    randomId,
} from '@common/util';
import { NgModel } from '@angular/forms';
import { dateControlValidator } from '@common/util/date-control.validator';

@Component({
    selector: 'export-requester',
    templateUrl: './export-requester.component.html',
    styles: [`
        .report-param-inputs {
            margin-bottom: 10em;
        }
        .btn-container {
            margin-top: 1.5em;
            text-align: right;
        }
    `]
})
export class ExportRequesterComponent implements OnInit {
    @ViewChildren('dateControl') dateControls: NgModel[];
    @Input() reportType: ReportTypeItem;
    @Output() cancel: EventEmitter<any> = new EventEmitter<any>();
    @Output() exit: EventEmitter<any> = new EventEmitter<any>();

    // Model
    searchParams: any;

    // State
    domIdAddition: string;
    submitted = false;

    readonly COMPONENT_LOG_TAG = 'export-requester';


    constructor(
        private appInsightsService: AppInsightsService,
        private loggingService: LoggingService,
        private reportingService: ReportingService,
        private dateFormatterService: DateFormatterService
    ) {
        //
    }

    ngOnInit() {
        this.domIdAddition = randomId();

        this.initialize();
    }

    initialize() {
        this.submitted = false;
        this.searchParams = {};
    }

    // Form State
    formValid(): boolean {
        if (this.searchParams.workflowTaskKey) {
            return true;
        } else {
            return false;
        }
    }


    // Creating
    createExport() {
        const errMessage = dateControlValidator(this.dateControls);
        if (errMessage) {
            this.loggingService.logError(errMessage, null, '', true);
            return;
        }

        this.submitted = true;

        this.appInsightsService.trackEvent('export-file-request-run', {
            report: this.reportType.text || ''
        });

        const params = this.getFormattedSearchParams();
        this.reportingService.createExportFileRequest(
            this.reportType.value,
            params
        ).then(() => {
            this.loggingService.logSuccess(
                'Your task export has begun',
                this.reportType.value,
                this.COMPONENT_LOG_TAG,
                true
            );

            this.submitted = false;
            this.exit.emit();
        }).catch((error: any) => {
            this.submitted = false;

            this.loggingService.logError(
                'Bulk export request failed: ' + error.message,
                this.reportType.value,
                this.COMPONENT_LOG_TAG,
                true
            );
        });
    }

    private getFormattedSearchParams(): any {
        const params: any = {};

        for (const prop in this.searchParams) {
            if (!this.searchParams.hasOwnProperty(prop)) {
                continue;
            }

            let val = this.searchParams[prop];

            if (Object.prototype.toString.call(val) === '[object Array]') {
                for (let i = 0; i < val.length; i++) {
                    val[i] = this.reformatValue(val[i]);
                }
            } else {
                val = this.reformatValue(val);
            }

            params[prop] = val;
        }

        return params;
    }

    private reformatValue(value: any): any {
        if (this.isDate(value)) {
            const tmpDate = new Date(value);

            if (tmpDate instanceof Date && !isNaN(tmpDate.valueOf())) {
                value = this.dateFormatterService.formatDateOnly(value);
            }
        }

        return value;
    }

    private isDate(value: any): boolean {
        return (null != value) && !isNaN(value) && ("undefined" !== typeof value.getDate);
    }


    // Canceling
    cancelClicked() {
        this.cancel.emit();
    }
}

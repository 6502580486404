import { Component, OnDestroy, OnInit } from '@angular/core';
import { TabStorageService } from 'src/app/multiply-tab/tab-storage.service';
import { DataManagerService } from '@services/data-manager.service';

@Component({
    selector: 'climb-main',
    templateUrl: './main.layout.html',
    styleUrls: ['./main.layout.scss'],
})
export class MainLayoutComponent implements OnInit, OnDestroy { 
  constructor(
    private tabStorage: TabStorageService,
    private dataManagerService: DataManagerService
    ) {}

  ngOnInit(): void {
    this.tabStorage.init();
  }

  ngOnDestroy(): void {
    this.dataManagerService.cancel();
    this.tabStorage.destroy();
  }
}

import type { SampleOrder } from './sample-order.interface';
import type { cv_SampleType } from './cv-sample-type.interface';
import type { cv_Taxon } from './cv-taxon.interface';

export interface Lot {
    C_Lot_key: number;
    C_SampleOrder_key: number | null;
    C_SampleType_key: number | null;
    C_Taxon_key: number | null;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    LotNumber: string;
    ModifiedBy: string;
    Pools: number;
    SampleCount: number;
    SampleOrder: SampleOrder;
    Version: number;
    cv_SampleType: cv_SampleType;
    cv_Taxon: cv_Taxon;
}

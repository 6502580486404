import type { Animal } from './animal.interface';
import type { GenotypeAllele } from './genotype-allele.interface';
import type { MessageMap } from './message-map.interface';
import type { Plate } from './plate.interface';
import type { StoredFileMap } from './stored-file-map.interface';
import type { TaskGenotype } from './task-genotype.interface';
import type { cv_GenotypeAssay } from './cv-genotype-assay.interface';
import type { cv_GenotypeStatus } from './cv-genotype-status.interface';
import type { cv_GenotypeSymbol } from './cv-genotype-symbol.interface';

export interface Genotype {
    Animal: Animal;
    C_GenotypeAssay_key: number | null;
    C_GenotypeStatus_key: number | null;
    C_GenotypeSymbol_key: number | null;
    C_Genotype_key: number;
    C_Material_key: number;
    C_Plate_key: number | null;
    C_Workgroup_key: number;
    Comments: string;
    CopyNumber: number | null;
    CreatedBy: string;
    DateCreated: Date;
    DateGenotype: Date | null;
    DateModified: Date;
    GenotypeAllele: GenotypeAllele[];
    GenotypeDescription: string;
    MessageMap: MessageMap[];
    ModifiedBy: string;
    NotebookPage: number | null;
    Plate: Plate;
    RepeatLength: string;
    StoredFileMap: StoredFileMap[];
    TaskGenotype: TaskGenotype[];
    Version: number;
    cv_GenotypeAssay: cv_GenotypeAssay;
    cv_GenotypeStatus: cv_GenotypeStatus;
    cv_GenotypeSymbol: cv_GenotypeSymbol;
}

import type { Institution } from './institution.interface';

export interface cv_InstitutionType {
    Abbreviation: string;
    C_InstitutionType_key: number;
    C_VocabularySource_key: number | null;
    C_Workgroup_key: number;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    ElementID: string;
    Institution: Institution[];
    InstitutionType: string;
    IsActive: boolean;
    IsDefault: boolean;
    IsDeprecated: boolean;
    ModifiedBy: string;
    SortOrder: number | null;
    Version: number;
}

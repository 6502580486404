import { TaskType } from '../../../tasks/models/task-type.enum';
import { WorkflowLogicService } from '../../services/workflow-logic.service';
import { DataManagerService } from '@services/data-manager.service';
import { TaskTableCommService } from '../../services/task-table-comm.service';
import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    ViewChildren,
} from '@angular/core';
import { DroppableEvent } from '@common/droppable-event';

import { ConfirmService } from '@common/confirm';
import { LoggingService } from '@services/logging.service';
import { WorkflowService } from '../../services/workflow.service';
import { WorkflowVocabService } from '../../services/workflow-vocab.service';

import {
    focusElementByQuery,
    notEmpty,
    randomId,
    scrollToElement,
    getSafeProp
} from '@common/util';

import { DataType } from '../../../data-type/data-type.enum';
import { Subscription } from 'rxjs';
import { NgModel } from '@angular/forms';
import { dateControlValidator } from '@common/util/date-control.validator';

@Component({
    selector: 'workflow-output-table',
    templateUrl: './workflow-output-table.component.html',
    styles: [`
        .note-text {
           white-space: pre-line;
        }

        .inactive-column {
           color: orange;
           display: inline-block;
        }
    `]
})
export class WorkflowOutputTableComponent implements OnChanges, OnInit, OnDestroy {
    @ViewChildren('dateControl') dateControls: NgModel[];
    @Input() task: any;
    @Input() facetPrivilege: string;
    @Input() readonly: boolean;
    // Samples assigned to the task (not output samples)
    @Input() primarySamples: any[];
    @Output() clickedImport: EventEmitter<void> = new EventEmitter<void>();

    DataType = DataType;
    TaskType = TaskType;

    resources: any[] = [];
    workflowTaskOutputs: any[] = [];
    tableId: string;
    paginationId: string;

    savingOutputSet: boolean;
    deletingOutputSets = false;

    private _subscriptions: Subscription[] = []; 

    // value used for bulk updating output values
    bulkOutputValue: any;

    readonly COMPONENT_LOG_TAG = 'workflow-output-table';
    readonly MATERIAL_SELECT_FIELD_NAME_ROOT = 'taskOutputSetMaterial-';


    constructor(
        private confirmService: ConfirmService,
        private loggingService: LoggingService,
        private workflowService: WorkflowService,
        private workflowLogicService: WorkflowLogicService,
        private workflowVocabService: WorkflowVocabService,
        private dataManager: DataManagerService,
        private taskTableComm: TaskTableCommService
    ) {
        // do nothing
    }

    ngOnInit() {
        this.initialize();
        const s1 = this.workflowService.workflowImport$.subscribe(() => {
            this.refreshTableHeaders().then(() => {
                this.task.TaskOutputSet.forEach((taskOutputSet: any) => {
                    this.recalculateValues(taskOutputSet);
                });
            });
        });
        this._subscriptions = [s1];
    }

    ngOnChanges(changes: any) {
        if (changes.task) {
            if (!changes.task.firstChange) {
                this.initialize();
            }
        }
    }

    ngOnDestroy() {
        for (const subscription of this._subscriptions) {
            subscription.unsubscribe();
        }
    }

    initialize() {
        if (!this.task) {
            return;
        }

        this.tableId = 'workflow-output-table-' + randomId();
        this.paginationId = 'outputs-' + randomId();

        this.resetPagination();

        this.getCVs();
        this.getTaskMaterials();
        this.getTaskOutputSets().then(() => {
            this.getTaskOutputs();
        });
   }

    private resetPagination() {
        this.task.outputPage = 1;
    }

    getCVs() {
        this._subscriptions.push(this.workflowVocabService.resources$.subscribe((resources) => {
            this.resources = resources;
        }));
    }

    getTaskMaterials(): Promise<any> {
        const taskKey = this.task.C_TaskInstance_key;
        // Ensure task materials are loaded
        return this.workflowService.getTaskMaterials(taskKey);
    }

    getTaskOutputSets(): Promise<any> {
        const taskKey = this.task.C_TaskInstance_key;
        // Ensure task output sets are loaded
        return this.workflowService.getOutputSets(taskKey);
    }

    getTaskOutputs(): Promise<any> {
        const workflowTaskKey = this.task.C_WorkflowTask_key;
        if (this.task.TaskOutputSet.length > 0) {
            const referenceSet = this.task.TaskOutputSet[0];
            this.workflowLogicService.updateTaskOutputSetFlags(referenceSet);
            return this.workflowService
                .getReferenceSetTaskOutputs(referenceSet.C_TaskOutputSet_key).then((outputs) => {
                this.workflowTaskOutputs = outputs;
            });
        } else {
            return this.workflowService.getActiveWorkflowTaskOutputs(workflowTaskKey)
                .then((outputs) => {
                this.workflowTaskOutputs = outputs;
            });
        }
        }

    refreshTableHeaders(): Promise<any> {
        if (this.task.TaskOutputSet.length > 0) {
            const referenceSet = this.task.TaskOutputSet[this.task.TaskOutputSet.length - 1];
            return this.workflowService.getReferenceSetTaskOutputs(referenceSet.C_TaskOutputSet_key)
                .then((outputs) => {
                    this.workflowTaskOutputs = outputs;
                });
        }
    }


    // Add
    saveAndAddOutputSet() {
        if (this.savingOutputSet) {
            return;
        }

        this.savingOutputSet = true;
        this.addOutputSet().then(() => {
            return this.saveOutputSetChanges();
        }).then(() => {
            this.savingOutputSet = false;
        }).catch((error) => {
            this.savingOutputSet = false;
            throw error;
        });
    }

    addOutputSet(): Promise<any> {
        return this.workflowLogicService.addOutputSet(this.task).then(() => {
            return this.resetDisplayForNewOutputSet();
        });
    }

    private saveOutputSetChanges(): Promise<any> {
        return this.dataManager.saveEntity('TaskOutputSet').then((changesSaved: boolean) => {
            return this.dataManager.saveEntity('TaskOutputSetMaterial');
        }).then((changesSaved: boolean) => {
            return this.dataManager.saveEntity('TaskOutput');
        });
    }

    recalculateValues(taskOutputSet: any) {
        this.workflowService.recalculateValues(this.task, taskOutputSet);
        this.workflowLogicService.updateTaskOutputSetFlags(taskOutputSet);
    }

    async resetDisplayForNewOutputSet() {
        this.resetPagination();

        await this.scrollToComponentTop();

        this.focusOnFirstMaterialSelector();
    }

    private async scrollToComponentTop() {
        await scrollToElement('#' + this.tableId);
    }

    /**
     * Focuses on the first row's TaskOutputSetMaterialSelectComponent input
     */
    private focusOnFirstMaterialSelector() {
        // Match first input element in the table
        const cssSelector = '#' + this.tableId + ' tbody input';
        focusElementByQuery(cssSelector, 250);
    }


    // Drop Materials
    onDropMaterialToOutput(dropEvent: DroppableEvent) {
        const taskOutputSetKey = dropEvent.dataKey;

        for (const taskMaterial of this.task.TaskMaterial) {
            if (taskMaterial.isSelected) {
                this.workflowService.createTaskOutputSetMaterial({
                    C_TaskOutputSet_key: taskOutputSetKey,
                    C_Material_key: taskMaterial.Material.C_Material_key
                });
            }
        }

        this.taskTableComm.refreshMaterialSelects();
    }


    // Import
    clickedImportHandler() {
        this.clickedImport.emit();
    }


    // Delete
    deleteAllTaskOutputSets() {
        if (this.facetPrivilege !== 'ReadWrite') {
            return;
        }

        if (notEmpty(this.task.TaskOutputSet)) {
            const itemCount = this.task.TaskOutputSet.length;
            const confirmMessage = 'Delete all (' +
                itemCount +
                ') output rows? This action cannot be undone.';

            return this.confirmService.confirmDelete(
                'Delete All Output Rows',
                confirmMessage
            ).then(
                // confirmed
                () => {
                    this.deletingOutputSets = true;

                    const outputSets = this.task.TaskOutputSet.slice();
                    
                    while (this.task.TaskOutputSet.length > 0) {
                        this.workflowService.detachTaskOutputSet(this.task.TaskOutputSet[0]);
                    }

                    return this.workflowService.bulkDeleteOutputSets(outputSets).then(() => {
                        this.loggingService.logSuccess(
                            'All output rows deleted', '', this.COMPONENT_LOG_TAG, true
                        );
                        this.deletingOutputSets = false;
                        this.getTaskOutputs();
                    });
                },
                // cancel
                () => { /* do nothing on cancel */ }
            );
        }
    }

    deleteTaskOutputSet(taskOutputSet: any) {
        if (this.facetPrivilege !== 'ReadWrite') {
            return;
        }

        return this.confirmService.confirmDelete(
            'Delete Output Row',
            'Delete output row? This action cannot be undone.'
        ).then(
            // confirmed
            () => {
                this.workflowService.deleteTaskOutputSet(taskOutputSet);
                this.saveOutputSetDelete();
                this.getTaskOutputs();
            },
            // cancel
            () => { /* do nothing on cancel */ }
            );
    }

    private saveOutputSetDelete(successMessage = 'Output row deleted'): Promise<any> {
        return this.dataManager.saveEntity('TaskOutput')
            .then((changesSaved: boolean) => {
                return this.dataManager.saveEntity('TaskOutputSetMaterial');
            }).then((changesSaved: boolean) => {
                return this.dataManager.saveEntity('TaskOutputSet');
            }).then((changesSaved: boolean) => {
                if (changesSaved) {
                    this.loggingService.logSuccess(
                        successMessage, '', this.COMPONENT_LOG_TAG, true
                    );
                }
            });
    }


    // Table
    getHeaderColspan(): number {
        let colspan = 3 +
            this.workflowTaskOutputs.length +
            this.getSingleAnimalPropertyCount();

        if (!this.readonly) {
            colspan = colspan + 2;
        }

        return colspan;
    }

    private getSingleAnimalPropertyCount() {
        let count = 0;

        if (this.task.WorkflowTask.SingleAnimal === true) {
            if (this.task.WorkflowTask.ShowAgeInDays) {
                count++;
            }
            if (this.task.WorkflowTask.ShowAgeInWeeks) {
                count++;
            }
            if (this.task.WorkflowTask.ShowAnimalComments) {
                count++;
            }
            if (this.task.WorkflowTask.ShowAnimalStatus) {
                count++;
            }
            if (this.task.WorkflowTask.ShowBirthDate) {
                count++;
            }
            if (this.task.WorkflowTask.ShowHousingID) {
                count++;
            }
            if (this.task.WorkflowTask.ShowMarker) {
                count++;
            }
        }

        return count;
    }

    getOutputValueCellClass(taskOutput: any): string {
        let cellClass = '';

        if (taskOutput && taskOutput.cv_DataType) {
            const dataType = taskOutput.cv_DataType.DataType;
            if (dataType === DataType.NUMBER || dataType === DataType.CALCULATED) {
                cellClass = 'num-cell';
            }
        }

        return cellClass;
    }

    getOutputValue(taskOutputs: any[], outputKey: number): string {
        return taskOutputs.find((taskOutput) => taskOutput.C_Output_key === outputKey).OutputValue;
    }

    bulkOutputChanged(workflowTaskOutput: any) {
        this.bulkUpdateOutputs(this.bulkOutputValue, workflowTaskOutput);

        // reset bulk update variable
        this.bulkOutputValue = null;
    }

    private bulkUpdateOutputs(value: any, workflowTaskOutput: any) {
        for (const outputSet of this.task.TaskOutputSet) {
            const taskOutput = outputSet.TaskOutput.find((item: any) => {
                return item.Output &&
                    item.Output.OutputName === workflowTaskOutput.OutputName;
            });

            if (taskOutput) {
                taskOutput.OutputValue = value;
                const dataType = getSafeProp(taskOutput, 'Output.cv_DataType.DataType');
                if (dataType === DataType.NUMBER) {
                    // calculate other outputs
                    this.recalculateValues(outputSet);
                }
            }
        }
    }

    onMaterialChange(taskOutputSet: any, material: any) {
        this.workflowLogicService.setInheritedOutputValues(
            this.task, taskOutputSet, material
        );
    }

    // Vocab selects
    resourceKeyFormatter = (value: any) => {
        return value.C_Resource_key;
    }
    
    resourceNameFormatter = (value: any) => {
        return value.ResourceName;
    }

    validate() {
        return dateControlValidator(this.dateControls);
    }
}

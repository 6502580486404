import type { CalculatedOutputExpression } from './calculated-output-expression.interface';
import type { Output } from './output.interface';

export interface ExpressionOutputMapping {
    C_CalculatedOutputExpression_key: number;
    C_ExpressionOutputMapping_key: number;
    C_Output_key: number;
    CalculatedOutputExpression: CalculatedOutputExpression;
    ExpressionVariableName: string;
    Output: Output;
}

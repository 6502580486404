<ng-template #definitionEditModal let-c="close">
    <import-file-definition-edit [selectedDefinitionKey]="selectedImportFileDefinitionKey"
                                 [mode]="definitionModalMode"
                                 (close)="c('Close')"></import-file-definition-edit>
</ng-template>

<div class="facet-form h-100">
    <form name="newImportForm" (ngSubmit)="importData()" class="detail-form-wrapper form-label-right">
        <div class="detail-header border-bottom">
            <h3>Import Data File</h3>

            <div class="detail-toolbar">
                <!--Submit or cancel-->
                <button data-automation-id="begin-import-button"
                        type="submit"
                        [disabled]="!isInputValid() || importInProgress"
                        (click)="importData()"
                        class="btn btn-primary">
                    Begin Import
                </button>
                <button type="button"
                        class="btn btn-secondary"
                        (click)="cancelHandler()">
                    Cancel Import
                </button>
            </div>
        </div>

        <div class="detail-form">
            <div *ngIf="importInProgress" class="form-group row mx-0">
                <div class="col-md-12">
                    <h3>
                        <i class="fa fa-2x fa-spinner fa-spin float-right" aria-hidden="true"></i>
                        Importing your data&hellip;
                    </h3>
                </div>
            </div>
            <div class="form-group row mx-0">
                <!--Select file type-->
                <label class="col-md-3 col-form-label">File Type</label>
                <div class="col-md-7">
                    <import-file-type-select data-automation-id="file-type-dropdown"
                                             [(model)]="selectedImportFileTypeKey"
                                             [readOnly]="fileUploaded"
                                             [nullable]="false"
                                             (modelChange)="changeFileType()"></import-file-type-select>
                </div>
            </div>
            <div *ngIf="selectedImportFileTypeKey" class="form-group row mx-0">
                <!--Select file definition-->
                <label class="col-md-3 col-form-label">File Definition</label>
                <div class="col-md-7 d-flex align-items-center flex-wrap">
                    <div [ngClass]="{'disabled': importInProgress}" class="mb-1 mr-2">
                        <import-file-definition-select data-automation-id="file-definition-dropdown"
                                                       [(model)]="selectedImportFileDefinitionKey"
                                                       [required]="false"
                                                       [parentFileTypeKey]="selectedImportFileTypeKey">
                            <!-- For later, when editing and creation of import file definitions through web app is supported -->
                            <!--<span class="input-group-btn" *ngIf="selectedImportFileDefinitionKey > 0">
                                    <button class="btn btn-secondary"
                                            type="button"
                                            (click)="definitionModalMode='edit'; openModal(definitionEditModal)">
                                        <i class="fa fa-pencil" aria-hidden="true" title="Edit Definition"></i>
                                        <span class="sr-only">Edit Definition</label>
                                    </button>
                                </span>
                                <span class="input-group-btn">
                                    <button class="btn btn-secondary"
                                            type="button"
                                            (click)="definitionModalMode='create'; openModal(definitionEditModal)">
                                        <i class="fa fa-plus" aria-hidden="true" title="Create Definition"></i>
                                        <span class="sr-only">Create Definition</label>
                                    </button>
                                </span>-->
                        </import-file-definition-select>
                    </div>

                    <ng-container *ngIf="selectedImportFileDefinitionKey > 0">
                        <button class="btn btn-secondary px-2 mb-1 mr-2"
                                type="button"
                                (click)="definitionModalMode='view'; openModal(definitionEditModal)">
                            <i class="fa fa-question" aria-hidden="true" title="View Definition"></i>
                            <span class="sr-only">View Definition</span>
                        </button>
                        <button class="btn btn-secondary mb-1"
                                type="button"
                                (click)="downloadTemplate(selectedImportFileDefinitionKey)">
                            <i class="fa fa-download" aria-hidden="true" title="Download Template"></i>
                            Download Template
                        </button>
                    </ng-container>
                </div>
            </div>
            <div *ngIf="selectedImportFileTypeKey" class="form-group row mx-0">
                <!--Upload file-->
                <label class="col-md-3 col-form-label">Data File</label>
                <div class="col-md-7">
                    <import-file-upload [readonly]="false"
                                        [facetPrivilege]="facetPrivilege"
                                        (fileChange)="fileUpdate($event)"></import-file-upload>
                </div>
            </div>
        </div>
    </form>
</div>


import { Injectable } from '@angular/core';
import { TranslationService } from './translation.service';
import { ToastrService } from './toastr.service';
import { Subject } from 'rxjs';

import { LogLevel } from './models';
import { UNHANDLED_ERROR_MESSAGE } from '../services/translation.service';
import { SlackService } from './slack.service';


@Injectable()
export class LoggingService {

    private onSaveErrorSource = new Subject<string>();
    onSaveError$ = this.onSaveErrorSource.asObservable();

    constructor(
        private toastrService: ToastrService,
        private translationService: TranslationService,
        private slackService: SlackService
    ) {
        // Nothing to do
    }


    logFacetSaveSuccess(source: string, showToast = true) {
        return this.logSuccess("Changes saved", '', source, showToast);
    }

    logFacetUndoSuccess(source: string, showToast = true) {
        return this.logSuccess("Changes canceled", '', source, showToast);
    }

    // Should we use unknown here as a type for data instead of any? (same on other logs)
    log(
        message: string,
        data: any,
        source: string,
        showToast = false
    ) {
        return this.logIt(message, data, source, LogLevel.Info, showToast);
    }

    logDebug(
        message: string,
        data: any,
        source: string,
        showToast = false
    ) {
        return this.logIt(message, data, source, LogLevel.Debug, showToast);
    }

    logWarning(
        message: string,
        data: any,
        source: string,
        showToast = false
    ) {
        return this.logIt(message, data, source, LogLevel.Warning, showToast);
    }

    logSuccess(
        message: string,
        data: any,
        source: string,
        showToast = false
    ) {
        return this.logIt(message, data, source, LogLevel.Success, showToast);
    }

    logError(
        message: string,
        error: any,
        source: string,
        showToast = false
    ) {
        if (error) {
            this.logToSlack(message, error, source);
        }
        return this.logIt(message, error, source, LogLevel.Error, showToast);
    }

    /**
     * Log a dataContext save error
     *
     * Also emits onSaveError$ event
     * @param error
     * @param errorTag
     */
    logSaveError(error: Error, errorTag: string) {
        const message = this.translationService.translateSaveErrors(error);
        this.logError(message, error, errorTag, true);
        this.onSaveErrorSource.next(message);
    }

    /**
     * Does the actual logging and toasting.
     *
     * @param message
     * @param data
     * @param source
     * @param showToast
     * @param logLevel
     */
    private logIt(
        message: string,
        data: any,
        source: string,
        logLevel: LogLevel,
        showToast = false
    ) {
        if (!message) {
            return;
        }

        source = source ? '[' + source + '] ' : '';

        this.logToConsole(message, data, source, logLevel);

        if (showToast) {
            return this.toastrService.showToast(message, logLevel);
        }
    }

    // Console
    private logToConsole(message: string, data: any, source: string, logLevel: LogLevel) {
        switch (logLevel) {
            case LogLevel.Error:
                if (data) {
                    console.error(source, message, data);
                } else {
                    console.error(source, message);
                }
                break;
            case LogLevel.Warning:
                if (data) {
                    console.warn(source, message, data);
                } else {
                    console.warn(source, message);
                }
                break;
            case LogLevel.Debug:
                if (data) {
                    console.debug(source, message, data);
                } else {
                    console.debug(source, message);
                }
                break;
            default:
                if (data) {
                    console.log(source, message, data);
                } else {
                    console.log(source, message);
                }
                break;
        }
    }

    // Slack
    private logToSlack(message: string, error: Error & { status?: number }, source?: string) {
        // Ignore client side validation errors from Breeze
        const isValidationError: boolean = error?.message?.includes('validation errors');

        // Ignore errors that have a special handled user message
        //   (e.g., does not contain our custom UNHANDLED error message)
        const isHandledSaveError: boolean = (message.includes('Save failed') && !message.includes(UNHANDLED_ERROR_MESSAGE));

        // Ignore connection errors
        const isConnectionError: boolean = (error?.status === 0 && error?.message.length === 0);

        // Ignore errors coming from localhost (e.g., developers)
        const isLocalEnvironment = error?.stack?.includes('localhost');

        if (!isConnectionError && !isLocalEnvironment) {
            if (isHandledSaveError || isValidationError) {
                // log handled message for user support
                this.slackService.postToHandledErrorChannel(message, source);
            } else if (error) {
                // log unhandled error for dev team to investigate
                this.slackService.postToUnhandledErrorChannel(error, source);
            }
        }
    }
}

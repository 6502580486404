import {
    Component,
    Input,
} from '@angular/core';


@Component({
    selector: 'pedigree-node',
    templateUrl: './pedigree-node.component.html',
    // Shares same styles with pedigree-viewer
    styleUrls: ['./pedigree-viewer.component.scss'],
})
export class PedigreeNodeComponent {
    @Input() animal: any;


    constructor(
    ) {
        // Nothing to do
    }
}

import { BulkEditCommService } from './../../common/facet/bulk-edit-comm.service';
import { MatingService } from './../mating.service';
import { DataManagerService } from '../../services/data-manager.service';
import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';

import { LoggingService } from '../../services/logging.service';
import { BulkEditToolbarComponent } from '../../common/toolbars';
import { SettingService } from '../../settings/setting.service';
import { IValidatable, SaveChangesService } from '../../services/save-changes.service';
import { MatingBulkTemplatesComponent } from './mating-bulk-templates.component';

@Component({
    selector: 'mating-bulk-edit',
    templateUrl: './mating-bulk-edit.component.html',
    providers: [
        BulkEditCommService
    ]
})
export class MatingBulkEditComponent implements OnInit, OnDestroy, IValidatable {
    @Input() facet: any;
    @Input() facetView: any;
    @Input() matings: any[];
    // Active and required fields set by facet settings
    @Input() activeFields: string[];
    @Input() inactiveFields: string[];
    @Input() requiredFields: string[];
    @Output() exit: EventEmitter<any> = new EventEmitter<any[]>();
    @ViewChild('bulkEditToolbar') bulkEditToolBar: BulkEditToolbarComponent;
    @ViewChild('bulkTemplates') bulkTemplates: MatingBulkTemplatesComponent;

    readonly COMPONENT_LOG_TAG = 'mating-bulk-edit';

    constructor(
        private matingService: MatingService,
        private loggingService: LoggingService,
        private dataManager: DataManagerService,
        private settingService: SettingService,
        private saveChangesService: SaveChangesService,
    ) {
    }

    // lifecycle
    ngOnInit() {
        this.initialize();
        this.saveChangesService.registerValidator(this);
    }

    ngOnDestroy() {
        this.saveChangesService.unregisterValidator(this);
    }

    initialize() {
        // Copy the input so we don't touch the grid data
        this.matings = this.matings.slice();
    }

    exitClicked() {
        if (this.dataManager.hasChanges()) {
            for (const mating of this.matings) {
                this.matingService.cancelMating(mating);
            }

            this.loggingService.logFacetUndoSuccess(this.COMPONENT_LOG_TAG);
        }

        this.exit.emit(this.matings);
    }

    async validate(): Promise<string> {
        const dateErrorMessage = this.bulkTemplates.validate();
        if (dateErrorMessage) {
            return dateErrorMessage;
        }
        // what fields are actually present in the bulk edit view
        const fieldsInColumns = [
            "C_MatingStatus_key",
            "C_Line_key",
            "DateMating",
            "C_MatingType_key",
            "C_MatingPurpose_key",
            "Comments"
        ];
        const filteredFields = this.requiredFields.filter((field: string) => fieldsInColumns.includes(field));

        return this.settingService.bulkValidate(this.matings, filteredFields, 'mating');
    }

}

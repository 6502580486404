import {
    CellFormatterService,
    FormatterOptions,
    TableOptions
} from '../common/datatable';
import { TranslationService } from '../services/translation.service';
import { AuditModifiedFieldComponent } from './audit-modified-fields/audit-modified-field.component';
import { AuditClassFactory } from './audit-class-maker';

export class AuditNameFormatTableOptions {

    options: TableOptions;

    constructor(
        private cellFormatterService: CellFormatterService,
        private translationService: TranslationService
    ) {
        this.options = this.getDefaultOptions();
    }

    getDefaultOptions(): TableOptions {

        return {
            csvFileName: 'name-settings-audit.csv',
            enableDetailColumn: false,
            enableDraggable: false,
            enableSelectable: false,
            enableAuditColumns: false,
            columns: [
                {
                    displayName: 'Type',
                    field: 'UpdateType',
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Modified Fields',
                    field: 'ModifiedFields',
                    sortField: 'ModifiedFields',
                    rendererComponent: AuditModifiedFieldComponent,
                    exportFormatter: this.cellFormatterService.auditModifiedFieldsExportFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Date',
                    field: 'ModifiedDate',
                    formatter: this.cellFormatterService.dateTimeUTCFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Modified By',
                    field: 'ModifiedBy',
                    formatter: this.cellFormatterService.userNameFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Enabled',
                    field: 'IsActive',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('IsActive', 'IsActive'),
                    cellClass: AuditClassFactory.createCellClass('isactive'),
                },
                {
                    displayName: 'Name Type',
                    field: 'Type',
                    formatter: (row: any, value: any, formatterOptions: FormatterOptions) => {
                        return this.cellFormatterService.facetSettingAuditFormatter(row, `[${value}]`, formatterOptions, 'Type', 'Type');
                    },
                    cellClass: AuditClassFactory.createCellClass('type'),
                },
                {
                    displayName: 'Prefix',
                    field: 'Prefix',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('Prefix', 'Prefix'),
                    cellClass: AuditClassFactory.createCellClass('prefix'),
                },
                {
                    displayName: 'Animal Prefix Field',
                    field: 'AnimalPrefixField',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('AnimalPrefixField', 'AnimalPrefixField'),
                    cellClass: AuditClassFactory.createCellClass('animalprefixfield'),
                },
                {
                    displayName: this.translationService.translate("Job") + ' Prefix Field',
                    field: 'JobPrefixField',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('JobPrefixField', 'JobPrefixField'),
                    cellClass: AuditClassFactory.createCellClass('jobprefixfield'),
                },
                {
                    displayName: 'Order Prefix Field',
                    field: 'OrderPrefixField',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('OrderPrefixField', 'OrderPrefixField'),
                    cellClass: AuditClassFactory.createCellClass('orderprefixfield'),
                },
                {
                    displayName: 'Counter',
                    field: 'Counter',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('Counter', 'Counter'),
                    cellClass: AuditClassFactory.createCellClass('counter'),
                },
                {
                    displayName: 'Use ID for Counter',
                    field: 'UseIdForCounter',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('UseIdForCounter', 'UseIdForCounter'),
                    cellClass: AuditClassFactory.createCellClass('useidforcounter'),
                },
                {
                    displayName: 'Suffix',
                    field: 'Suffix',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('Suffix', 'Suffix'),
                    cellClass: AuditClassFactory.createCellClass('suffix'),
                },
                {
                    displayName: this.translationService.translate("Job") + ' Suffix Field',
                    field: 'JobSuffixField',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('JobSuffixField', 'JobSuffixField'),
                    cellClass: AuditClassFactory.createCellClass('jobsuffixfield'),
                },
                {
                    displayName: 'Order Suffix Field',
                    field: 'OrderSuffixField',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('OrderSuffixField', 'OrderSuffixField'),
                    cellClass: AuditClassFactory.createCellClass('ordersuffix'),
                },
                {
                    displayName: 'Created By',
                    field: 'CreatedBy',
                    formatter: this.cellFormatterService.userNameFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Created Date',
                    field: 'DateCreated',
                    formatter: this.cellFormatterService.dateTimeUTCFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                }
            ]
        };
    }
}

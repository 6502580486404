import type { LocationPosition } from './location-position.interface';
import type { MessageMap } from './message-map.interface';
import type { Note } from './note.interface';
import type { ProtocolInstance } from './protocol-instance.interface';
import type { ProtocolTask } from './protocol-task.interface';
import type { Resource } from './resource.interface';
import type { SampleGroup } from './sample-group.interface';
import type { StoredFileMap } from './stored-file-map.interface';
import type { TaskAnimalHealthRecord } from './task-animal-health-record.interface';
import type { TaskBirth } from './task-birth.interface';
import type { TaskCohort } from './task-cohort.interface';
import type { TaskInput } from './task-input.interface';
import type { TaskJob } from './task-job.interface';
import type { TaskLine } from './task-line.interface';
import type { TaskMaterial } from './task-material.interface';
import type { TaskMaterialPool } from './task-material-pool.interface';
import type { TaskOutputSet } from './task-output-set.interface';
import type { TaskPlaceholder } from './task-placeholder.interface';
import type { TaskTopAnimal } from './task-top-animal.interface';
import type { TaskTopHousing } from './task-top-housing.interface';
import type { TaskTopLine } from './task-top-line.interface';
import type { WorkflowTask } from './workflow-task.interface';
import type { cv_ScheduleReason } from './cv-schedule-reason.interface';
import type { cv_TaskOutcome } from './cv-task-outcome.interface';
import type { cv_TaskStatus } from './cv-task-status.interface';

export interface TaskInstance {
    AssignedToResource: Resource;
    C_AssignedTo_key: number | null;
    C_CompletedBy_key: number | null;
    C_GroupTaskInstance_key: number | null;
    C_LocationPosition_key: number | null;
    C_OriginatingTask_key: number | null;
    C_ProtocolInstance_key: number | null;
    C_ProtocolTask_key: number | null;
    C_ReviewedBy_key: number | null;
    C_ScheduleReason_key: number | null;
    C_TaskInstance_key: number;
    C_TaskOutcome_key: number | null;
    C_TaskStatus_key: number | null;
    C_WorkflowTask_key: number;
    C_Workgroup_key: number;
    Comments: string;
    CompletedByResource: Resource;
    Cost: number | null;
    CreatedBy: string;
    CurrentLocationPath: string;
    DateComplete: Date | null;
    DateCreated: Date;
    DateDue: Date | null;
    DateModified: Date;
    DateReviewed: Date | null;
    Deviation: string;
    Duration: number | null;
    GroupTaskInstance: TaskInstance;
    IsBillable: boolean;
    IsGroup: boolean;
    IsLocked: boolean;
    IsPooledAnimal: boolean;
    IsPooledSample: boolean;
    IsReviewed: boolean;
    IsWorkflowLocked: boolean;
    LocationPosition: LocationPosition;
    MemberTaskInstance: TaskInstance[];
    MessageMap: MessageMap[];
    ModifiedBy: string;
    Note: Note[];
    OriginatingTaskInstance: TaskInstance;
    ProtocolInstance: ProtocolInstance;
    ProtocolTask: ProtocolTask;
    ReviewedByResource: Resource;
    SampleGroup: SampleGroup[];
    ScheduleReasonDetails: string;
    SortOrder: number | null;
    StoredFileMap: StoredFileMap[];
    TaskAlias: string;
    TaskAliasSortable: string;
    TaskAnimalHealthRecord: TaskAnimalHealthRecord[];
    TaskBirth: TaskBirth[];
    TaskCohort: TaskCohort[];
    TaskInput: TaskInput[];
    TaskJob: TaskJob[];
    TaskLine: TaskLine[];
    TaskMaterial: TaskMaterial[];
    TaskMaterialPool: TaskMaterialPool[];
    TaskOutputSet: TaskOutputSet[];
    TaskPlaceholder: TaskPlaceholder[];
    TaskTopAnimal: TaskTopAnimal;
    TaskTopHousing: TaskTopHousing;
    TaskTopLine: TaskTopLine;
    TimeComplete: string | null;
    TimeDue: string | null;
    TimeReviewed: string | null;
    Version: number;
    WorkflowTask: WorkflowTask;
    cv_ScheduleReason: cv_ScheduleReason;
    cv_TaskOutcome: cv_TaskOutcome;
    cv_TaskStatus: cv_TaskStatus;
}

/**
 * Gets the highest Sequence in an object array.
 * @param items
 */
export function maxSequence(items: any[]) {
    let maxVal = 0;

    if (items) {
        for (const item of items) {
            if (item.Sequence) {
                const currentVal = item.Sequence;
                if (currentVal > maxVal) {
                    maxVal = currentVal;
                }
            }
        }
    }

    return maxVal;
}

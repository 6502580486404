import { ExportInstitutionAuditService } from './audit/export-institution-audit.service';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { ClimbCommonModule } from '../common/climb-common.module';
import { ClimbServicesModule } from '../services/climb-services.module';

import { InstitutionService } from './institution.service';
import { InstitutionVocabService } from './institution-vocab.service';
import { InstitutionSelectComponent } from './institution-select.component';
import { InstitutionMultiselectComponent } from './institution-multiselect.component';
import { SiteMultiselectComponent } from './site-multiselect.component';
import { InstitutionContactMultiselectComponent } from './institution-contact-multiselect.component';
import { CrlCustomerSelectComponent } from './crl-customer-select.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ClimbCommonModule,
        ClimbServicesModule
    ],
    declarations: [
        InstitutionSelectComponent,
        InstitutionMultiselectComponent,
        SiteMultiselectComponent,
        InstitutionContactMultiselectComponent,
        CrlCustomerSelectComponent
    ],
    exports: [
        InstitutionSelectComponent,
        InstitutionMultiselectComponent,
        SiteMultiselectComponent,
        InstitutionContactMultiselectComponent,
        CrlCustomerSelectComponent
    ]
})
export class InstitutionsSharedModule {
    static forRoot(): ModuleWithProviders<InstitutionsSharedModule> {
        return {
          ngModule: InstitutionsSharedModule,
          providers: [
            InstitutionService,
            InstitutionVocabService,
            ExportInstitutionAuditService
          ]
        };
    }
}

import type { Line } from './line.interface';
import type { Study } from './study.interface';

export interface StudyLine {
    AnimalCount: number | null;
    C_Line_key: number | null;
    C_StudyLine_key: number;
    C_Study_key: number;
    C_Workgroup_key: number;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    Line: Line;
    ModifiedBy: string;
    Study: Study;
    Version: number;
}

import { trimEnd } from '@lodash';
import { InjectionToken } from '@angular/core';

const serviceBaseName = "serviceBase";
const adminBaseName = "serviceBaseAdmin";
const webBaseName = "webBase";
const appInsightsTrackAjaxName = "appInsightsTrackAjax";
const testEnvironmentMessageName = "testEnvironmentMessage";
const b2cEnvironment = "b2cEnvironment";
const dotmaticsWorkgroup = "dotmaticsWorkgroup";
const supportEmailName = "supportEmail";
const customerSsoAuthRedirect = "customerSsoAuthRedirect";
const trackModifiedDateFieldInAudit = "trackModifiedDateFieldInAudit";
const oktaDomainUrlName = "oktaDomainUrl";
const oktaClientIdName = "oktaClientId";

export const apiServiceBaseUri = window[serviceBaseName];
export const apiServiceBaseUriAdmin = window[adminBaseName];
export const appInsightsTrackAjax: boolean = window[appInsightsTrackAjaxName] === 'true';
export const testEnvironmentMessage: string = window[testEnvironmentMessageName];
export const clientId = 'CLIMB.Web';
export const slackErrorWebHook =
    'https://hooks.slack.com/services/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';
export const slackHandledErrorWebHook =
    'https://hooks.slack.com/services/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';
export const appErrorPrefix = '[CLIMB Error] ';
export const webBase: string = window[webBaseName];
export const contactSupportUri = 'https://rockstepsolutions.zendesk.com/hc/en-us/requests/new';
export const supportUri = 'https://rockstepsolutions.zendesk.com/';
export const b2cEnvironmentName: string = window[b2cEnvironment] || '';
export const dotmaticsWorkgroupKeys: string = window[dotmaticsWorkgroup];
export const statusApiUrl = 'https://api.status.io/v2/status/summary/5fc50cbb035dfd0538877643';
export const statusApiAppKey = 'qYiyHDABIYwK7bYX/qA/Ef8qjaLcrOssx5JZ7WpcuOrctEuzXj2ecyga7yBPFEyNsB+pM8ujBtjhka/akVJXZw==';
export const statusApiID = '347d718b-4e31-4ab2-b714-ee84023ad4f4';
export const supportEmail: string = window[supportEmailName] || 'climb.feedback@rockstepsolutions.com';
export const customerSsoAuthRedirectUrl = window[customerSsoAuthRedirect];
export const trackModifiedDateFieldInAuditVariable = window[trackModifiedDateFieldInAudit] || false;
export const oktaDomainUrl = window[oktaDomainUrlName];
export const oktaClientId = window[oktaClientIdName];

export const ENV_CONFIG = new InjectionToken<EnvironmentConfig>('EnvironmentConfig');

export interface EnvironmentConfig {
    apiServiceBaseUri: string;
    apiServiceBaseUriAdmin: string;
    appInsightsTrackAjax: boolean;
    testEnvironmentMessage: string;
    clientId: string;
    slackErrorWebHook: string;
    slackHandledErrorWebHook: string;
    appErrorPrefix: string;
    webBase: string;
    contactSupportUri: string;
    supportUri: string;
    b2cEnvironmentName: string;
    statusApiAppKey: string;
    statusApiID: string;
    supportEmail: string;
    customerSsoAuthRedirectUrl: string;
    trackModifiedDateFieldInAuditVariable: boolean;
    oktaDomainUrl: string;
    oktaClientId: string;
}

export const environment: () => EnvironmentConfig = () => ({
    apiServiceBaseUri: trimEnd(window[serviceBaseName], '/'),
    apiServiceBaseUriAdmin,
    appInsightsTrackAjax,
    testEnvironmentMessage,
    clientId,
    slackErrorWebHook,
    slackHandledErrorWebHook,
    appErrorPrefix,
    webBase,
    contactSupportUri,
    supportUri,
    b2cEnvironmentName,
    statusApiAppKey,
    statusApiID,
    supportEmail,
    customerSsoAuthRedirectUrl,
    trackModifiedDateFieldInAuditVariable,
    oktaDomainUrl,
    oktaClientId
});

/*
* Copy buffer for copy/paste of entities
*/

import { Injectable } from '@angular/core';
import { notEmpty } from '../util/not-empty';

@Injectable()
export class CopyBufferService {

    buffer: any[] = [];
    bufferType: string;

    clear() {
        this.buffer = [];
        this.bufferType = "";
    }

    copy(objects: any[]) {
        this.buffer = objects;
        this.bufferType = this._getBufferType(this.buffer);
    }

    paste(): any[] {
        if (this.buffer) {
            return this.buffer.slice();
        }
        return [];
    }

    hasAnimals(): boolean {
        return notEmpty(this.buffer) && this.bufferType === 'Animal';
    }

    hasCohorts(): boolean {
        return notEmpty(this.buffer) && this.bufferType === 'Cohort';
    }

    hasProtocols(): boolean {
        return notEmpty(this.buffer) && this.bufferType === 'Protocol';
    }

    hasSamples(): boolean {
        return notEmpty(this.buffer) && this.bufferType === 'Sample';
    }

    hasWorkflowTasks(): boolean {
        return notEmpty(this.buffer) && this.bufferType === 'WorkflowTask';
    }

    _getBufferType(items: any[]): string {
        let type = "";

        if (notEmpty(items)) {
            const item = items[0];
            // inspect Breeze entity to get table name
            if (item.entityType) {
                type = item.entityType.shortName;
            }
        }

        return type;
    }
}

import { DateTime } from "luxon";
import { LuxonInput } from "./types/luxon-input.type";

/**
 * Converts value given into a Luxon DateTime object. 
 * @param value the value to convert. It can be a string, number, Date, a number/string array, or Moment-type. 
 * @throws an error if no conversion function is found for the type. Logs to console.
 * @returns 
 */
export function convertValueToLuxon(value: LuxonInput): DateTime {
    if (!value) {
        return null;
    }
    if (DateTime.isDateTime(value)) {
        return value;
    }
    if (typeof (value) === "string") {
        return stringToLuxon(value);
    } else if (typeof (value) === "number") {
        return numberToLuxon(value);
    } else if (value instanceof Date) {
        return dateToLuxon(value);
    } else {
        const errorMessage = "Luxon Input Type not found. Please add conversion function to convertValueToLuxon";
        console.error(errorMessage);
        throw Error(errorMessage);
    }
}

function stringToLuxon(value: string): DateTime {
    // TODO: determine the string's date format, it may not always be ISO.\
    const dt = DateTime.fromISO(value);
    if (dt.isValid) {
        return dt;
    } else {
        const date = new Date(value);
        return dateToLuxon(date);
    }
}

function numberToLuxon(value: number): DateTime {
    return DateTime.fromMillis(value);
}

function dateToLuxon(value: Date): DateTime {
    return DateTime.fromJSDate(value);
}

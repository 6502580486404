import { Pipe, PipeTransform } from '@angular/core';

import { daysSinceAsString } from '../util';

/**
 * Returns the number of days between two dates
 */
@Pipe({ name: 'daysSince' })
export class DaysSincePipe implements PipeTransform {

    transform(value: Date, secondDate: Date): string {
        if (!value || !secondDate) {
            return '';
        }

        return daysSinceAsString(value, secondDate);
    }
}

import { Injectable, NgZone } from '@angular/core';
import { Entity } from '@common/types';
import { WorkspaceDetail } from '@common/types/models/workspace-detail.interface';
import { StoreWritable } from 'effector';
import { BehaviorSubject, Observable } from 'rxjs';
import { Store } from './types';

export const isWorkspaceDetails = (facet: unknown): facet is Entity<WorkspaceDetail> => 
  typeof facet === 'object' && typeof (facet as WorkspaceDetail).C_WorkspaceDetail_key === 'number';

export function mappedStore<T>(store: StoreWritable<T>): T {
  return store.getState();
}

export function mappedStore$<T>(store: StoreWritable<T>) {
  const initValue = mappedStore(store);
  const subj$$ = new BehaviorSubject<typeof initValue>(initValue);
  store.watch(value => subj$$.next(value));

  return subj$$;
}

@Injectable()
export class StoreWrapper { 
  private store: Store;

  constructor(private ngZone: NgZone) {}

  setApi(store: Store) {
    this.store = store;
  }

  // Don't use it. Only for debug purpose
  get __store() {
    return this.store.__store;
  }

  get events() {
    const ngZone = this.ngZone;
    return new Proxy(this.store.events, {
      get(...props) {
        return (value: unknown) => {
          ngZone.runOutsideAngular(() => {
            Reflect.get(...props)?.(value);
          });
        }
      }
    });
  }

  getValue$() {
    return new Observable(subscribe => {
      this.ngZone.runOutsideAngular(() => {
        this.store.getValue$().subscribe(value => {
          this.ngZone.run(() => {
            subscribe.next(value);
          });
        });
      });
    });
  }

  getValue() {
    return this.store.getValue();
  }
}
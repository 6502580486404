import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class FileUploadCommService {
    
    private refreshedFilesSource = new Subject<any>();

    refreshedFiles$ = this.refreshedFilesSource.asObservable();

    refreshFiles() {
        this.refreshedFilesSource.next();
    }
    
}

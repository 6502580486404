<div class="detail-form-wrapper">

    <div class="detail-header">
        <h3>Device</h3>

        <pager-toolbar [itemName]="'device'"
                        [logTag]="COMPONENT_LOG_TAG"
                        [first]="pageState.isFirst"
                        [last]="pageState.isLast"
                        (previous)="previousClicked($event)"
                        (next)="nextClicked($event)"></pager-toolbar>

        <detail-toolbar [facetPrivilege]="facet.Privilege"
                        [logTag]="COMPONENT_LOG_TAG"
                        (exit)="exitClicked($event)">
        </detail-toolbar>
    </div>

    <div *ngIf="device | isDeleted"
            class="alert alert-warning"
            role="alert">
        <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
        This device has been deleted.
    </div>

    <form *ngIf="!(device | isDeleted)"
            name="deviceForm" id="deviceForm"
            class="detail-form"
            #deviceForm="ngForm">
        <fieldset [disabled]="facet.Privilege === 'ReadOnly'">
            <div class="form-group row">
                <label class="col-md-2 col-form-label">ID</label>
                <div class="col-md-10">
                    <p class="form-control-plaintext">{{device.C_Device_key}}</p>
                </div>
            </div>

            <div class="form-group row">
                <label class="col-md-2 col-form-label">Model</label>
                <div class="col-md-10">
                    <p class="form-control-plaintext">{{device.DeviceModel?.DeviceModelName}}</p>
                </div>
            </div>

            <div class="form-group row">
                <label class="col-md-2 col-form-label">Current Location</label>
                <div class="col-md-10">
                    <p class="form-control-plaintext">{{device.CurrentLocationPath}}</p>

                    <small class="form-text text-muted"
                        *ngIf="hasUnsavedLocationChanges()">
                         Current Location will only update after changes have been saved.
                     </small>
                </div>
            </div>

            <div class="form-group row">
                <label class="col-md-2 col-form-label">Location</label>
                <div class="col-md-10">
                    <location-select [(model)]="device.C_LocationPosition_key"
                        [disabled]="device.C_MaterialPool_key"></location-select>

                    <small class="form-text text-muted"
                        *ngIf="device.C_MaterialPool_key">
                         Can only specify Location or Housing Unit, but not both.
                     </small>
                </div>
            </div>

            <div class="form-group row">
                <label class="col-md-2 col-form-label">Housing Unit</label>
                <div class="col-md-10">
                    <housing-select [(model)]="device.C_MaterialPool_key"
                        [disabled]="device.C_LocationPosition_key"></housing-select>

                    <small class="form-text text-muted"
                        *ngIf="device.C_LocationPosition_key">
                            Can only specify Location or Housing Unit, but not both.
                        </small>
                </div>
            </div>

        </fieldset>
    </form>
</div>

<div class="wizard-footer">

    <div class="wizard-nav">

        <!-- Back -->
        <button type="button"
                class="btn btn-lg btn-fw btn-secondary"
                [disabled]="backDisabled"
                (preFinalize)="backPreFinalized()"
                (finalize)="backFinalized()"
                (postFinalize)="backPostFinalized()"
                previousStep
                *ngIf="!first && !backHidden">
            <i class="fa fa-fw fa-play fa-flip-horizontal" aria-hidden="true"></i>
            Back
        </button>

        <!-- Next -->
        <button type="button"
                class="btn btn-lg btn-fw btn-primary"
                [disabled]="nextDisabled"
                (preFinalize)="nextPreFinalized()"
                (finalize)="nextFinalized()"
                (postFinalize)="nextPostFinalized()"
                nextStep
                *ngIf="!last">
            Next
            <i class="fa fa-fw fa-play" aria-hidden="true"></i>
        </button>

        <!-- Finish -->
        <button type="button"
                class="btn btn-lg btn-fw btn-primary"
                (click)="finishClicked()"
                [disabled]="nextDisabled"
                *ngIf="last">
            <i class="fa fa-check-circle" aria-hidden="true"></i>
            Finish
        </button>

    </div>

</div>

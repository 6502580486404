
<form *ngIf="task && task.WorkflowTask"
        class="detail-form">

    <fieldset [disabled]="facet.Privilege === 'ReadOnly'">

        <!-- For now only display lock icon for job tasks -->
        <div class="form-group row" *ngIf="taskType === TaskType.Job">
            <div class="offset-md-2 col-md-10">
                <p class="form-control-plaintext">
                    <lock-control [(model)]="task.IsLocked"
                                    [readonly]="true"
                                    [largeText]="true"></lock-control>
                </p>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-2 col-form-label">Task Name</label>
            <div class="col-md-10">
                <p class="form-control-plaintext">{{task.TaskAlias}}</p>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-2 col-form-label">Task Location</label>
            <div class="col-md-10">
                <p class="form-control-plaintext">{{task.CurrentLocationPath}}</p>
            </div>
        </div>

        <!-- Core details by taskType -->
        <ng-container [ngSwitch]="taskType">
            <!-- Animal -->
            <ng-container *ngSwitchCase="TaskType.Animal">
                <ng-container *ngIf="task.TaskMaterial | taskFilter:TaskType.Animal | notEmpty">
                    <workflow-animal-details
                        [animal]="task.TaskMaterial[0].Material?.Animal"></workflow-animal-details>
                </ng-container>
            </ng-container>

            <!-- Birth -->
            <ng-container *ngSwitchCase="TaskType.Birth">
                <ng-container *ngIf="task.TaskBirth | notEmpty">
                        <workflow-birth-details
                            [birth]="task.TaskBirth[0].Birth"></workflow-birth-details>
                </ng-container>
            </ng-container>

            <!-- Health Record -->
            <ng-container *ngSwitchCase="TaskType.HealthRecord">
                <ng-container *ngIf="task.TaskAnimalHealthRecord | notEmpty">
                    <workflow-health-record-details
                        [healthRecord]="task.TaskAnimalHealthRecord[0].AnimalHealthRecord"></workflow-health-record-details>
                </ng-container>
            </ng-container>

            <ng-container *ngSwitchCase="TaskType.Housing">
                <ng-container *ngIf="task.TaskMaterialPool | notEmpty">
                    <workflow-housing-details
                        [materialPool]="task.TaskMaterialPool[0].MaterialPool"></workflow-housing-details>
                </ng-container>
            </ng-container>

            <!-- Job -->
            <ng-container *ngSwitchCase="TaskType.Job">
                <ng-container *ngIf="task.TaskJob | notEmpty">
                    <workflow-job-details
                        [job]="task.TaskJob[0].Job"></workflow-job-details>
                </ng-container>
            </ng-container>

            <!-- Line -->
            <ng-container *ngSwitchCase="TaskType.Line">
                <ng-container *ngIf="task.TaskLine | notEmpty">
                    <workflow-line-details
                        [line]="task.TaskLine[0].Line"></workflow-line-details>
                </ng-container>
            </ng-container>

            <!-- Mating -->
            <ng-container *ngSwitchCase="TaskType.Mating">
                <ng-container *ngIf="task.TaskMaterialPool | notEmpty">
                    <workflow-mating-details
                        [mating]="task.TaskMaterialPool[0].MaterialPool?.Mating"></workflow-mating-details>
                </ng-container>
            </ng-container>

            <!-- Invalid Type -->
            <div class="form-group row text-error" *ngSwitchDefault>
                Invalid task type: {{taskType}}
            </div>
        </ng-container><!-- /Core details by taskType -->

        <div class="form-group row">
            <label class="col-md-2 col-form-label">Due Date</label>
            <div class="col-md-6">
                <climb-ngb-date #dateControl="ngModel"
                                name="Due Date"
                                [(ngModel)]="task.DateDue"
                                [disabled]="isReadOnly()"
                                [allowTime]="true"></climb-ngb-date>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-md-2 col-form-label">Deviation</label>
            <div class="col-md-6">
                <p class="form-control-plaintext">{{task.Deviation}}</p>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-md-2 col-form-label">Duration</label>
            <div class="col-md-6">
                <input type="text" name="taskDuration"
                           [(ngModel)]="task.Duration"
                           [disabled]="task.IsLocked"
                           class="form-control input-medium" />
            </div>
        </div>
        <div class="form-group row">
            <label class="col-md-2 col-form-label">Complete Date</label>
            <div class="col-md-6">
                <climb-ngb-date #dateControl="ngModel"
                                name="Complete Date"
                                [(ngModel)]="task.DateComplete"
                                (ngModelChange)="completedDateChanged($event)"
                                [allowTime]="true"
                                [required]="false"
                                [disabled]="isReadOnly()"></climb-ngb-date>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-md-2 col-form-label">Status</label>
            <div class="col-md-10">
                <active-vocab-select [(model)]="task.C_TaskStatus_key"
                                        [vocabChoices]="taskStatuses"
                                        [keyFormatter]="taskStatusKeyFormatter"
                                        [optionFormatter]="taskStatusFormatter"
                                        [readonly]="task.IsLocked"
                                        (modelChange)="taskStatusChanged()"></active-vocab-select>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-md-2 col-form-label">Assigned To</label>
            <div class="col-md-10">
                <climb-assigned-to-select [(model)]="task.C_AssignedTo_key"
                                          [disabled]="task.IsLocked"></climb-assigned-to-select>
            </div>
        </div>
        <div class="form-group row"
                *ngIf="task.CreatedBy">
            <label class="col-md-2 col-form-label">Created</label>
            <div class="col-md-10">
                <p class="form-control-plaintext">
                    {{task.CreatedBy | userFullName}}
                    &middot;
                    {{task.DateCreated | formatShortDate}}
                </p>
            </div>
        </div>
    </fieldset>
</form>

import { Injectable } from "@angular/core";
import {Entity as BreezeEntity, EntityState } from "breeze-client";
import {
    AnimalOrder,
    Entity as InternalEntity,
    JobOrder,
    Lot,
    Order,
    OrderLocation,
    SampleOrder,
    StoredFile,
    StoredFileMap,
} from "@common/types";
import { DataManagerService } from "@services/data-manager.service";
import { GlpBaseFacetStateService } from "@services/glp-base-facet-state.service";

@Injectable()
export class OrderStateService extends GlpBaseFacetStateService<Order> {

    constructor(protected dataManagerService: DataManagerService) {
        super(dataManagerService);
    }

    getRelatedCollectionChanges(order: InternalEntity<Order>): BreezeEntity[] {
        const orderEntity = order;
        const changes: any = [];
        changes.push(...this.getChangesToJobOrders(orderEntity));
        changes.push(...this.getChangesToAnimalOrders(orderEntity));
        changes.push(...this.getChangesToLots(orderEntity));
        changes.push(...this.getChangesToSampleOrders(orderEntity));
        changes.push(...this.getChangesToOrderLocations(orderEntity));
        changes.push(...this.getChangesToFiles(orderEntity.C_Order_key));
        return changes;
    }

    public getChangesToJobOrders(
        orderEntity: Order,
    ): InternalEntity<JobOrder>[] {
        return this.dataManagerService.getChangesToRelatedCollections<JobOrder>(
            "JobOrder",
            "C_Order_key",
            orderEntity.C_Order_key,
        );
    }

    public getChangesToAnimalOrders(
        orderEntity: Order,
    ): InternalEntity<AnimalOrder>[] {
        return this.dataManagerService.getChangesToRelatedCollections<AnimalOrder>(
            "AnimalOrder",
            "C_Order_key",
            orderEntity.C_Order_key,
        );
    }

    public getChangesToSampleOrders(
        orderEntity: Order,
    ): InternalEntity<SampleOrder>[] {
        return this.dataManagerService.getChangesToRelatedCollections<SampleOrder>(
            "SampleOrder",
            "C_Order_key",
            orderEntity.C_Order_key,
        );
    }

    public getChangesToOrderLocations(
        orderEntity: Order,
    ): InternalEntity<OrderLocation>[] {
        return this.dataManagerService.getChangesToRelatedCollections<OrderLocation>(
            "OrderLocation",
            "C_Order_key",
            orderEntity.C_Order_key,
        );
    }

    getChangesToFiles(orderKey: number) {
        const changes: any = [];

        const storedFileMaps = (this.dataManagerService
            .getManager()
            .getChanges("StoredFileMap") ??
            []) as InternalEntity<StoredFileMap>[];
        const changedStoreFileMaps = storedFileMaps.filter(
            (storedFileMap) => storedFileMap.C_Order_key === orderKey,
        );

        const storedFiles = (this.dataManagerService
            .getManager()
            .getChanges("StoredFile") ?? []) as InternalEntity<StoredFile>[];

        // Get corresponding files descriptions
        const changedStoreFiles = storedFiles.filter((storedFile) =>
            changedStoreFileMaps.some(
                (storedFileMap) =>
                    storedFileMap.C_StoredFile_key === storedFile.C_StoredFile_key,
            ),
        );

        changes.push(...changedStoreFileMaps);
        changes.push(...changedStoreFiles);
        return changes;
    }

    public getChangesToLots(
        orderEntity: Order,
    ): InternalEntity<Lot>[] {

        let sampleOrderKey: number;
        if (orderEntity.SampleOrder && orderEntity.SampleOrder.length > 0) {
            sampleOrderKey = orderEntity.SampleOrder[0].C_SampleOrder_key;
        } else {
            // probably sample order was removed.
            const deletedSampleOrders = this.getChangesToSampleOrders(orderEntity)
                .filter(c => c.entityAspect.entityState === EntityState.Deleted);
            if (deletedSampleOrders.length > 0) {
                sampleOrderKey = deletedSampleOrders[0].C_SampleOrder_key;
            }
        }
        
        return this.dataManagerService.getChangesToRelatedCollections<Lot>(
            "Lot",
            "C_SampleOrder_key",
            sampleOrderKey,
        );
    }

    discardChanges(order: InternalEntity<Order>) {
        if (!order) {
            return;
        }
        this._rejectChangesToRelatedEntities(order);
        this.dataManagerService.rejectEntityAndRelatedPropertyChanges(order);
    }
    
    private _rejectChangesToRelatedEntities(order: InternalEntity<Order>) {
        this.rejectChangesToFiles(order);
        this.rejectChangesToOrderLocations(order);
        this.rejectChangesToLots(order);
        this.rejectChangesToSampleOrders(order);
        this.rejectChangesToAnimalOrders(order);
        this.rejectChangesToJobOrders(order);
    }

    private rejectChangesToOrderLocations(order: InternalEntity<Order>) {
        this.dataManagerService.rejectChangesToEntityByFilter(
            'OrderLocation', (item: InternalEntity<OrderLocation>) => {
                return item.C_Order_key === order.C_Order_key;
            }
        );
    }

    private rejectChangesToLots(order: InternalEntity<Order>) {
        const changesToLots = this.getChangesToLots(order);
        for (const lot of changesToLots){
            lot.entityAspect.rejectChanges();
        }
    }

    private rejectChangesToSampleOrders(order: InternalEntity<Order>) {
        this.dataManagerService.rejectChangesToEntityByFilter(
            'SampleOrder', (item: InternalEntity<SampleOrder>) => {
                return item.C_Order_key === order.C_Order_key;
            }
        );
    }

    private rejectChangesToAnimalOrders(order: InternalEntity<Order>) {
        this.dataManagerService.rejectChangesToEntityByFilter(
            'AnimalOrder', (item: InternalEntity<AnimalOrder>) => {
                return item.C_Order_key === order.C_Order_key;
            }
        );
    }

    private rejectChangesToJobOrders(order: InternalEntity<Order>) {
        this.dataManagerService.rejectChangesToEntityByFilter(
            'JobOrder', (item: InternalEntity<JobOrder>) => {
                return item.C_Job_key === order.C_Job_key;
            }
        );
    }
    
    private rejectChangesToFiles(order: InternalEntity<Order>) {
        const storedFileMaps = (this.dataManagerService
                .getManager()
                .getChanges("StoredFileMap") ??
            []) as InternalEntity<StoredFileMap>[];
        const changedStoreFileMaps = storedFileMaps.filter(
            (storedFileMap) => storedFileMap.C_Order_key === order.C_Order_key,
        );

        this.dataManagerService.rejectChangesToEntityByFilter('StoredFile',
            (storedFile: any) => {
                return changedStoreFileMaps.some(
                    (storedFileMap) =>
                        storedFileMap.C_StoredFile_key === storedFile.C_StoredFile_key,
                );
            })

        this.dataManagerService.rejectChangesToEntityByFilter(
            'StoredFileMap', (item: InternalEntity<StoredFileMap>) => {
                return item.C_Order_key === order.C_Order_key;
            }
        );
    }

    getDeleteNavPropertyChanges(entity: InternalEntity<Order>): BreezeEntity[] {
        return [];
    }
}

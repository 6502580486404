<form role="form" class="form-label-right">

    <div class="modal-header justify-content-center">
        <h4 class="modal-title">Plates Filter</h4>
    </div>

    <div class="modal-body">

        <div class="form-group row">
            <label class="col-md-3 col-form-label">ID</label>
            <div class="col-md-9">
                <input type="text" [(ngModel)]="filter.PlateID"
                       name="plateIdFilter"
                       class="form-control  input-medium"
                       data-auto-focus="plateId" />
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-3 col-form-label">External ID</label>
            <div class="col-md-9">
                <input type="text" [(ngModel)]="filter.ExternalPlateID"
                       name="externalPlateIdFilter"
                       class="form-control  input-medium"/>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-3 col-form-label">Type</label>
            <div class="col-md-9">
                <select [(ngModel)]="filter.C_PlateType_key"
                        name="plateTypeKey"
                        class="form-control  input-medium">
                    <option></option>
                    <option *ngFor="let item of plateTypes"
                            [value]="item.C_PlateType_key">{{item.PlateType}}</option>
                </select>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-3 col-form-label">{{'Job' | translate}} Name</label>
            <div class="col-md-9">
                <job-multiselect [(model)]="filter.jobs"></job-multiselect>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-3 col-form-label">Status</label>
            <div class="col-md-9">
                <select [(ngModel)]="filter.C_PlateStatus_key"
                        name="plateStatusKey"
                        class="form-control  input-medium">
                    <option></option>
                    <option *ngFor="let item of plateStatuses"
                            [value]="item.C_PlateStatus_key">{{item.PlateStatus}}</option>
                </select>
            </div>
        </div>

    </div>

    <div class="modal-footer">
        <span class="btn-set">
            <button type="submit"
                    class="btn btn-fw-wide btn-lg btn-primary"
                    (click)="filterClicked($event)">
                Filter
            </button>
            <button data-automation-id="clear-filter-button"
                    type="button"
                    class="btn btn-fw-wide btn-lg btn-secondary"
                    (click)="clearClicked()">
                Clear Filter
            </button>
        </span>
        <span class="btn-set">
            <button type="button"
                    class="btn btn-lg btn-secondary"
                    (click)="closeClicked()">
                Close
            </button>
        </span>
    </div>
</form>

import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import {
    apiServiceBaseUri,
    apiServiceBaseUriAdmin,
    webBase
} from '@config';


@Injectable()
export class VersionsService {
    constructor(
        private httpClient: HttpClient,
    ) { }

    getAdminBuildNumber(): Observable<string> {
        const serviceUrl = apiServiceBaseUriAdmin + 'api/ClimbInfo/GetCurrentBuildNumber';
        return this.httpClient.get(serviceUrl).pipe(
            map((data) => data.toString()),
        );
    }

    getDataBuildNumber(): Observable<string> {
        const serviceUrl = apiServiceBaseUri + 'api/ClimbInfo/GetCurrentBuildNumber';
        return this.httpClient.get(serviceUrl).pipe(
            map((data) => data.toString()),
        );
    }

    getWebBuildNumber(): Observable<string> {
        const serviceUrl = webBase + "BuildNumber.txt";
        return this.httpClient.get<File>(serviceUrl).pipe(
            map((file) => file.toString()),
            catchError(() => {
                // This should only show when running locally. The DevOps Build Pipleine always puts BuildNumber.txt on the VM
                console.warn('Cannot find BuildNumber.txt. Displaying "CLIMB.Web: Development Version" instead');
                return of('Development Version');
            }),
        );
    }
}

import type { Output } from './output.interface';

export interface OutputFlag {
    C_OutputFlag_key: number;
    C_Output_key: number;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    Maximum: number | null;
    Minimum: number | null;
    ModifiedBy: string;
    Output: Output;
    TaskFlagMessage: string;
    Version: number;
}


export class PageState {
    activeItem: any;
    dataItems: any[];
    listPage: number;
    pageSize: number;
    totalCount: number;

    /**
     * Enable field initializer
     *   E.g. new PageState({ field1: 'value', ...})
     * @param init
     */
    public constructor(init?: Partial<PageState>) {
        Object.assign(this, init);
    }

    get isFirst(): boolean {
        const index = this.activeItemIndex;
        return index <= 0 && this.isFirstListPage;
    }

    get isLast(): boolean {
        const index = this.activeItemIndex;
        const dataItems = this.dataItems;
        return index >= (dataItems.length - 1) && this.isLastListPage;
    }

    get activeItemIndex(): number {
        const data = this.dataItems;
        const item = this.activeItem;
        return data.indexOf(item);
    }

    get isFirstListPage(): boolean {
        return this.listPage === 1;
    }

    get isLastListPage(): boolean {
        const pageSize = this.pageSize;
        const listPage = this.listPage;
        const totalCount = this.totalCount;
        return (pageSize * listPage) >= totalCount;
    }
}

import {
    AfterViewInit,
    Component,
    OnDestroy,
    ViewChild,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AuthService } from '@services/auth.service';
import { buildId, contactSupportUri } from '@config';
import { user } from '@icons';
import { InputComponent } from '@common/components/input/input.component';

@Component({
    selector: 'request-pwd-reset-view',
    templateUrl: './request-pwd-reset-view.component.html',
    styleUrls: ['./request-pwd-reset-view.component.scss'],
})
export class RequestPwdResetViewComponent implements OnDestroy, AfterViewInit {
    @ViewChild('userName') userName: InputComponent;

    errorMessage = '';
    sending = false;
    savedSuccessfully = false;

    supportUri: string = contactSupportUri;
    buildId: string = buildId;
    icons = { user };

    requestSubscription: Subscription;

    formData = this.fb.group({
        UserName: ['', Validators.required],
        // forgot-password enum value
        RequestType: [2],
    });

    constructor(
        private fb: FormBuilder,
        private authService: AuthService,
    ) { }

    ngAfterViewInit(): void {
        this.userName.focusInput();
    }

    ngOnDestroy() {
        if (this.requestSubscription) {
            this.requestSubscription.unsubscribe();
        }
    }

    requestPasswordReset() {
        if (this.formData.invalid) {
            return;
        }

        this.errorMessage = '';
        this.savedSuccessfully = false;
        this.sending = true;

        this.requestSubscription =
            this.authService.requestPasswordReset(this.formData.value).subscribe(
                (response) => {
                    this.sending = false;
                    this.savedSuccessfully = true;
                },
                (response) => {
                    this.sending = false;
                    this.errorMessage = this.authService.authErrorsToString(response);
                });
    }
}

import html2canvas from 'html2canvas';
import {
    Component,
    Input,
    OnInit,
} from '@angular/core';
import { TaskService } from '../../tasks/task.service';
import { VocabularyService } from '../../vocabularies/vocabulary.service';
import { LocationService } from '../../locations/location.service';
import { DataType } from '../../data-type/data-type.enum';
import { ScheduleType } from '../models';
import { FeatureFlagService } from '../../services/feature-flags.service';

@Component({
    selector: 'cro-task-selector',
    templateUrl: './cro-task-selector.component.html'
})
export class CROTaskSelectorComponent implements OnInit {
    @Input() currentTasks: any[];

    // CVs
    dayFrequencies: any[] = [];
    customFrequency: any = null;
    weekFrequencies: any[] = [];
    timeFrequencies: any[] = [];
    scheduleTypes: any[] = [];
    timeRelations: any[] = [];
    timeUnits: any[] = [];
    preservationMethods: any[];
    containerTypes: any[];
    sampleStatuses: any[];
    sampleTypes: any[];
    sampleSubtypes: any[];
    sampleAnalysisMethods: any[];
    sampleProcessingMethods: any[];

    customFrequencyKey: any = null;
    twoFrequencyKey: any = null;
    threeFrequencyKey: any = null;

    // Default keys
    defaultPreservationMethodKey: string;
    defaultSampleStatusKey: string;
    defaultSampleTypeKey: string;
    defaultContainerTypeKey: string;
    defaultSampleSubtypeKey: string;
    defaultSampleProcessingMethodKey: string;
    defaultSampleAnalysisMethodKey: string;

    // Bulk update placeholders
    bulkNumSamples: number;
    bulkPreservationMethodKey: string;
    bulkContainerTypeKey: string;
    bulkSampleStatusKey: string;
    bulkSampleTypeKey: string;
    bulkDateHarvest: Date;
    bulkDateExpiration: Date;
    bulkTimePoint: number;
    bulkTimeUnitKey: string;
    bulkSampleSubtypeKey: string;
    bulkSampleProcessingMethodKey: string;
    bulkSendTo: string;
    bulkSampleAnalysisMethodKey: string;
    bulkSpecialInstructions: string;

    taskKeyNameMap: any = {};
    frequencyKeyNameMap: any = {};
    frequencyKeyPatternMap: any = {};
    dayMap: any = {};
    taskMap: any = {};
    timeUnitMap: any = {};

    // Feature flags
    isGLP = false;

    // Input Apply
    readonly INPUTS_APPLY_FIRST = 'First occurrence';
    readonly INPUTS_APPLY_ALL = 'All occurrences';
    inputsApplyOptions: string[] = [
        this.INPUTS_APPLY_FIRST,
        this.INPUTS_APPLY_ALL,
    ];
    readonly maxDays: number = 150;
    readonly minDays: number = 0;

    readonly COMPONENT_LOG_TAG = 'cro-task-selector';

    constructor(
        private taskService: TaskService,
        private vocabularyService: VocabularyService,
        private locationService: LocationService,
        private featureFlagService: FeatureFlagService,
    ) {
    }

    // lifecycle
    ngOnInit() {
        this.initialize();
    }

    initialize() {
        this.initIsGLP();

        return this.getCVs();
    }

    initIsGLP() {
        const flag = this.featureFlagService.getFlag("IsGLP");
        this.isGLP = (flag && flag.IsActive && flag.Value.toLowerCase() === "true");
    }

    private getCVs(): Promise<any> {
        const preferLocal = true;
        return Promise.all([
            this.vocabularyService.getCV(
                'cv_Frequencies', 'SortOrder', preferLocal
            ).then((data) => {
                this.dayFrequencies = data.filter((f) => f.Frequency !== "Custom");
                this.currentTasks.forEach((task: any) => {
                    task.frequencies = this.dayFrequencies;
                });
                data.forEach((frequency: any) => {
                    this.frequencyKeyNameMap[this.frequencyKeyFormatter(frequency)] = this.frequencyFormatter(frequency);
                    if (frequency.C_Pattern_key !== null) {
                        this.frequencyKeyPatternMap[this.frequencyKeyFormatter(frequency)] = frequency.Pattern.DaysPattern;
                    }
                    if (this.frequencyFormatter(frequency) === "Custom") {
                        this.customFrequencyKey = this.frequencyKeyFormatter(frequency);
                        this.customFrequency = frequency;
                    }
                });
            }),
            this.vocabularyService.getCV(
                'cv_ScheduleTypes'
            ).then((data) => {
                this.scheduleTypes = data.filter((item: any) => [ScheduleType.RelativeTaskDue, ScheduleType.RelativeTaskComplete].includes(this.scheduleTypesFormatter(item)));
            }),
            this.vocabularyService.getCV(
                'cv_TimeRelations'
            ).then((data) => {
                this.timeRelations = data;
            }),
            this.vocabularyService.getCV(
                'cv_PreservationMethods', 'SortOrder', preferLocal
            ).then((data: any[]) => {
                this.preservationMethods = data;
            }),
            this.locationService.getContainerTypes('Sample').then((data) => {
                this.containerTypes = data;
            }),
            this.vocabularyService.getCV(
                'cv_SampleStatuses', 'SortOrder', preferLocal
            ).then((data: any[]) => {
                this.sampleStatuses = data;
            }),
            this.vocabularyService.getCV(
                'cv_SampleTypes', 'SortOrder', preferLocal
            ).then((data: any[]) => {
                this.sampleTypes = data;
            }),

            this.vocabularyService.getCV(
                'cv_SampleSubtypes', 'SortOrder', preferLocal
            ).then((data: any[]) => {
                this.sampleSubtypes = data;
            }),
            this.vocabularyService.getCV(
                'cv_SampleProcessingMethods', 'SortOrder', preferLocal
            ).then((data: any[]) => {
                this.sampleProcessingMethods = data;
            }),
            this.vocabularyService.getCV(
                'cv_SampleAnalysisMethods', 'SortOrder', preferLocal
            ).then((data: any[]) => {
                this.sampleAnalysisMethods = data;
            }),
            this.vocabularyService.getCVDefault(
                'cv_PreservationMethods', preferLocal
            ).then((value) => {
                if (value != null) {
                    this.defaultPreservationMethodKey = value.C_PreservationMethod_key;
                }
            }),
            this.vocabularyService.getCVContainerTypeDefault(
                'Sample'
            ).then((value) => {
                if (value != null) {
                    this.defaultContainerTypeKey = value.C_ContainerType_key;
                }
            }),
            this.vocabularyService.getCVDefault(
                'cv_SampleStatuses', preferLocal
            ).then((value) => {
                if (value != null) {
                    this.defaultSampleStatusKey = value.C_SampleStatus_key;
                }
            }),
            this.vocabularyService.getCVDefault(
                'cv_SampleTypes', preferLocal
            ).then((value) => {
                if (value != null) {
                    this.defaultSampleTypeKey = value.C_SampleType_key;
                }
            }),
            this.vocabularyService.getCVDefault(
                'cv_SampleSubtypes', preferLocal
            ).then((value) => {
                if (value != null) {
                    this.defaultSampleSubtypeKey = value.C_SampleSubtype_key;
                }
            }),
            this.vocabularyService.getCVDefault(
                'cv_SampleProcessingMethods', preferLocal
            ).then((value) => {
                if (value != null) {
                    this.defaultSampleProcessingMethodKey = value.C_SampleProcessingMethod_key;
                }
            }),
            this.vocabularyService.getCVDefault(
                'cv_SampleAnalysisMethods', preferLocal
            ).then((value) => {
                if (value != null) {
                    this.defaultSampleAnalysisMethodKey = value.C_SampleAnalysisMethod_key;
                }
            }),
        ]);
    }

    addTask() {
        this.currentTasks.push({
            taskKey: null,
            timeDifferences: [],
            offsets: [],
            inputs: [],
            sampleGroups: [],
            inputsApply: this.INPUTS_APPLY_ALL,
            inputValues: {},
            frequencies: this.dayFrequencies,
        });
    }

    taskChanged(task: any) {
        // Clear the inputs, etc
        this.resetForm(task);

        if (task.taskKey > 0) {
            // Fetch the Inputs for the selected WorkflowTask
            return this.taskService.getTaskInputs(task.taskKey).then((data: any[]) => {
                task.inputs = data;
            }).then(() => {
                this.taskService.getTaskByKey(task.taskKey).then((t: any) => {
                    if (t.cv_TaskType.TaskType === 'Job' || t.cv_TaskType.TaskType === 'Study') {
                        task.canAddSampleGroups = true;
                    }
                    this.taskKeyNameMap[task.taskKey] = t.TaskName;
                });
            });
        } else {
            task.canAddSampleGroups = false;
        }

    }

    frequencyChanged(task: any) {
        // if we have changed to something else from custom, clear the custom value
        if (task.frequencyKey !== this.customFrequencyKey) {
            task.frequencies = this.dayFrequencies.filter((f) => f.Frequency !== "Custom");
        }
        this.calculateCalendar(task);
    }

    timeUnitChanged(task: any) {
        this.calculateCalendar(task);
    }

    taskToEndChanged(task: any) {
        // if the task count wasnt set and its set to to end, set it to 1
        if (task.ToEnd && (!task.hasOwnProperty("TaskCount") || task.TaskCount === 0 || task.TaskCount === null)) {
            // See if the current frequency is weekly or daily
            if (task.hasOwnProperty("C_Frequency_key") && task.C_Frequency_key > 0) {
                if (this.frequencyKeyNameMap[task.C_Frequency_key].includes("wk")) {
                    task.TaskCount = 14;
                    this.calculateCalendar(task);
                } else {
                    const frequency = this.frequencyKeyToCount(task.C_Frequency_key).dayDiff;
                    task.TaskCount = Math.ceil  (35 / frequency); // days
                    this.calculateCalendar(task);
                }
            } else {
                task.TaskCount = 35;
                this.calculateCalendar(task);
            }
            task.offset = null;
        }
    }

    taskCountChanged(task: any) {
        this.calculateCalendar(task, false);

        // If we set it to 1 or less, we need to set offset to null
        if (task.TaskCount <= 1) {
            task.offset = null;
        }
    }

    calculateCalendar(task: any, redoOffsets = true, calculateOffset = true) {
        if (redoOffsets) {
            this.calculateOffsetsAndTimeDifferences(task);
        }
        if (task.TaskCount > 0 && task.C_Frequency_key > 0) {
            const frequency = this.customFrequencyKey === task.C_Frequency_key ? task.OldFrequencyKey : task.C_Frequency_key;
            // Find the selected task Pattern from frequency
            const taskPattern = this.frequencyKeyPatternMap[frequency].split(",").join("");
            // Take the count and multiply the pattern count times
            let count = task.TaskCount;
            
            task.Pattern = "";
            while (count > 0) {
                task.Pattern = task.Pattern + taskPattern;
                count--;
            }

            if (task.offset > 0) {
                task.Pattern += "1";
            }
            // Set the days array to the pattern by splitting it on ',' and then mapping the result to integer
            task.Days = task.Pattern.split('').map((d: any) => parseInt(d, 10));
            if (task.RelatedTo === 'study_start_day' && task.hasOwnProperty("StartDay") && task.StartDay !== null) {
                task.Days = [...Array(task.StartDay).fill(0), ...task.Days];
            } else if (task.RelatedTo !== 'null' && task.hasOwnProperty("AnchorDay") && task.AnchorDay !== null) {
                task.Days = [...Array(task.AnchorDay).fill(0), ...task.Days];
            }
            this.divideWeeks(task);
            this.divideDays(task);
            if (calculateOffset) {
                this.offsetChanged(task);
            }
        }

        // Validate if any of the dependant tasks has invalid day selection
        this.validateDependantTasks();
    }

    validateDependantTasks() {
        this.currentTasks.forEach((task: any) => {
            if (task.RelatedTo > -1) {
                const dependantTask = this.currentTasks[task.RelatedTo];
                if (dependantTask) {
                    // Check if the AnchorDay of this task on dependant task day is valid
                    if (dependantTask.Days !== undefined && dependantTask.Days[task.AnchorDay] === 0) {
                        task.AnchorDay = undefined;
                    }
                }
            }
        });
    }

    // Divide the task.Weeks array into task.MultipleWeeks array by multiple of 4
    divideWeeks(task: any) {
        // Calculate the weeks to display on header
        const multiplier = this.frequencyKeyNameMap[task.C_Frequency_key].includes("wk") ? 7 : 1;
        task.Weeks = [];
        // If we are in weeks, count total weeks to show in table
        if (multiplier === 7) {
            let i = 1;
            const totalWeeks = Math.ceil(task.Days.length / 7);
            while (i <= totalWeeks) {
                task.Weeks.push(i);
                i++;
            }
            task.MultipleWeeks = [];
            let j = 0;
            while (j <= task.Weeks.length) {
                const start = j;
                const end = j + 4;
                task.MultipleWeeks.push(task.Weeks.slice(start, end));
                j = j + 4;
            }
        }

    }

    // Divide the days array in 2d array of multiple of 28 days
    divideDays(task: any) {
        task.WeekWise = [];
        let week = 0;
        while (week < task.Days.length / 28) {
            task.WeekWise.push(task.Days.slice(week * 28, (week * 28) + 28));
            week++;
        }
    }

    // Calculate the offsets and time differences for the selected task
    calculateOffsetsAndTimeDifferences(task: any) {
        if (task.C_Frequency_key > 0) {
            // if the count on task frequency is more than 1, then we need to populate the offset array 
            // with the correct number of offsets
            const count = this.frequencyKeyToCount(task.C_Frequency_key).count;
            task.offsets = [];

            if (count > 1) {
                for (let i = 1; i < count; i++) {
                    task.offsets.push(i);
                }
            }

            // if the offset is not in the offset array, set it to null
            if (task.offset != null && !task.offsets.includes(parseInt(task.offset, 10))) {
                task.offset = null;
            }

            // if there are more than one task in a day we need to populate the time differences array
            // with the correct number of time differences
            task.timeDifferences = [1, 2, 3, 4, 5, 6];
            if (count === 1) {
                task.timeDifferences = [];
                task.TimeDifference = null;
            } else if (count === 3) {
                task.timeDifferences.push(7);
                task.timeDifferences.push(8);
            } else if (count === 2) {
                task.timeDifferences.push(7);
                task.timeDifferences.push(8);
                task.timeDifferences.push(9);
                task.timeDifferences.push(10);
                task.timeDifferences.push(11);
                task.timeDifferences.push(12);
            }
        }
    }

    offsetChanged(task: any, redoCalendar = true) {
        if (redoCalendar) {
            this.calculateCalendar(task, false, false);
        }

        if (task.Days && task.Days.length > 0 && task.TaskCount > 0) {
            // Minus the offset from the task from first day and set it to the last day
            // And decrease the amount from the first day
            const offset = task.offset;
            let count = 0;
            if (task.C_Frequency_key === this.customFrequencyKey) {
                const countSetting = this.frequencyKeyToCount(task.OldFrequencyKey);
                count = countSetting.count;
                
            } else {
                const countSetting = this.frequencyKeyToCount(task.C_Frequency_key);
                count = countSetting.count;
            }

            // update the last index of the day which had atleast one task on it
            let lastIndex = 0;
            for (let i = task.Days.length - 1; i > 0; i--) {
                if (task.Days[i] > 0) {
                    lastIndex = i;
                    break;
                }
            }
            // update the first index of the day which has atleast one task on it
            let firstIndex = 0;
            for (let i = 0; i < task.Days.length; i++) {
                if (task.Days[i] > 0) {
                    firstIndex = i;
                    break;
                }
            }

            if (offset > 0) {
                // Update the first day
                task.Days[firstIndex] = offset;
                // Update the last day
                task.Days[lastIndex] = count - offset;

                // Update the inner tasks so that they have correct task count if there is a task
                for (let i = firstIndex + 1; i < lastIndex; i++) {
                    if (task.Days[i] > 0) {
                        task.Days[i] = count;
                    }
                }
            } 
            this.divideDays(task);
        } 
    }

    downloadCalendar(index: any, task: any) {
        task.calendarExpand = true;
        // wait for calendar expanded
        setTimeout(() => {
            html2canvas(document.querySelector(`#task-calendar-${index}`))
                .then((canvas: any) => {
                    document.body.appendChild(canvas);
                    const link = document.createElement("a");
                    link.href = canvas.toDataURL();
                    link.download = "TaskCalendar.png";
                    document.body.appendChild(link);
                    link.click();
                    canvas.remove();
                });   
        }, 100);  
    }

    selectDay(task: any, day: any, index: any) {
        // get the current count if task frequency is not custom frequency
        if (task.C_Frequency_key !== this.customFrequencyKey) {
            // If we are not already on custom save current frequency key
            task.OldFrequencyKey = task.C_Frequency_key;
            task.C_Frequency_key = this.customFrequencyKey;
            task.frequencies.push(this.customFrequency);
        }
        const count = this.frequencyKeyToCount(task.OldFrequencyKey).count;

        if (day > 0) {
            task.Days[index] = 0;
        } else {
            task.Days[index] = count;
        }
        // Update the offset for new day pattern
        this.offsetChanged(task, false);
    }

    /* 
    map the frequency keys to count and day difference according to following rules:
    qd - every day 1 task daily
    bid - every day 2 tasks daily
    tid - every day 3 tasks daily
    qid- every day 4 tasks daily
    qod - every other day 1 task daily
    biod - every other day 2 tasks daily
    tiod - every other day 3 tasks daily
    qiod - every other day 4 tasks daily
    q3d - every 3rd day 1 task daily
    bi3d - every 3rd day 2 tasks daily 
    ti3d - every 3rd day 3 tasks daily
    qi3d - every 3rd day 4 tasks daily
    q4d - every 4th day 1 task daily
    bi4d - every 4th day 2 tasks daily
    ti4d - every 4th day 3 tasks daily
    qi4d - every 4th day 4 tasks daily
    q5d - every 5th day 1 task daily
    bi5d - every 5th day 2 tasks daily
    ti5d - every 5th day 3 tasks daily
    qi5d - every 5th day 4 tasks daily
    q6d - every 6th day 1 task daily
    bi6d - every 6th day 2 tasks daily
    ti6d - every 6th day 3 tasks daily
    qi6d - every 6th day 4 tasks daily
    biwk - twice weekly 1 task daily
    biwk bi - twice weekly 2 tasks daily 
    biwk ti - twice weekly 3 tasks daily 
    biwk qi - twice weekly 4 tasks daily 
    tiwk - 3 times weekly 1 task daily
    tiwk bi - 3 times weekly 2 tasks daily
    tiwk ti - 3 times weekly 3 tasks daily
    tiwk qi - 3 times weekly 4 tasks daily
    */
    frequencyKeyToCount(frequencyKey: string) {
        switch (this.frequencyKeyNameMap[frequencyKey]) {
            case 'qd':
                return { count: 1, dayDiff: 1 };
            case 'bid':
                return { count: 2, dayDiff: 1 };
            case 'tid':
                return { count: 3, dayDiff: 1 };
            case 'qid':
                return { count: 4, dayDiff: 1 };
            case 'qod':
                return { count: 1, dayDiff: 2 };
            case 'biod':
                return { count: 2, dayDiff: 2 };
            case 'tiod':
                return { count: 3, dayDiff: 2 };
            case 'qiod':
                return { count: 4, dayDiff: 2 };
            case 'q3d':
                return { count: 1, dayDiff: 3 };
            case 'bi3d':
                return { count: 2, dayDiff: 3 };
            case 'ti3d':
                return { count: 3, dayDiff: 3 };
            case 'qi3d':
                return { count: 4, dayDiff: 3 };
            case 'q4d':
                return { count: 1, dayDiff: 4 };
            case 'bi4d':
                return { count: 2, dayDiff: 4 };
            case 'ti4d':
                return { count: 3, dayDiff: 4 };
            case 'qi4d':
                return { count: 4, dayDiff: 4 };
            case 'q5d':
                return { count: 1, dayDiff: 5 };
            case 'bi5d':
                return { count: 2, dayDiff: 5 };
            case 'ti5d':
                return { count: 3, dayDiff: 5 };
            case 'qi5d':
                return { count: 4, dayDiff: 5 };
            case 'q6d':
                return { count: 1, dayDiff: 6 };
            case 'bi6d':
                return { count: 2, dayDiff: 6 };
            case 'ti6d':
                return { count: 3, dayDiff: 6 };
            case 'qi6d':
                return { count: 4, dayDiff: 6 };
            case 'qwk':
                return { count: 1, type: 'once' };
            case 'qwk bi':
                return { count: 2, type: 'once' };
            case 'qwk ti':
                return { count: 3, type: 'once' };
            case 'qwk qi':
                return { count: 4, type: 'once' };
            case 'biwk':
                return { count: 1, type: 'twice' };
            case 'biwk bi':
                return { count: 2, type: 'twice' };
            case 'biwk ti':
                return { count: 3, type: 'twice' };
            case 'biwk qi':
                return { count: 4, type: 'twice' };
            case 'tiwk':
                return { count: 1, type: 'thrice' };
            case 'tiwk bi':
                return { count: 2, type: 'thrice' };
            case 'tiwk ti':
                return { count: 3, type: 'thrice' };
            case 'tiwk qi':
                return { count: 4, type: 'thrice' };
            default:
                return { count: 1, dayDiff: 1 };
        }
    }

    removeTask(index: any) {
        this.currentTasks.splice(index, 1);
        for (const task of this.currentTasks) {
            if (task.RelatedTo === index) {
                delete task.RelatedTo;
                delete task.AnchorDay;
            }
        }
    }

    startDayChanged(task: any) {
        this.calculateCalendar(task);
    }

    /**
     * Clear the form for the task
     */
    resetForm(task: any) {
        task.inputs = [];
        task.inputValues = {};
        task.sampleGroups = [];
        task.canAddSampleGroups = false;
        task.inputsApply = this.INPUTS_APPLY_ALL;
        task.inputValues = {};
    }

    /**
     * Add a new default SampleGroup row to the create modal
     * @param task task where sample group is to be added
     */
    createAddRow(task: any) {
        task.sampleGroups.push({
            NumSamples: 1,
            C_SampleType_key: this.defaultSampleTypeKey,
            C_SampleStatus_key: this.defaultSampleStatusKey,
            C_PreservationMethod_key: this.defaultPreservationMethodKey,
            C_ContainerType_key: this.defaultContainerTypeKey,
            C_SampleSubtype_key: this.defaultSampleSubtypeKey,
            C_SampleProcessingMethod_key: this.defaultSampleProcessingMethodKey,
            SendTo: '',
            C_SampleAnalysisMethod_key: this.defaultSampleAnalysisMethodKey
        });
    }

    /**
     * Remove a SampleGroup row from the create modal
     * 
     * @param task task whose sample group to be deleted
     * @param index index of the reow to remove
     */
    createRemoveRow(task: any, index: number) {
        task.sampleGroups.splice(index, 1);
    }

    updateBulkNumSamples(task: any) {
        this.updateBulkValue('NumSamples', this.bulkNumSamples, task);
    }
    updateBulkPreservationMethodKey(task: any) {
        this.updateBulkValue('C_PreservationMethod_key', this.bulkPreservationMethodKey, task);
    }
    updateBulkContainerTypeKey(task: any) {
        this.updateBulkValue('C_ContainerType_key', this.bulkContainerTypeKey, task);
    }
    updateBulkSampleStatusKey(task: any) {
        this.updateBulkValue('C_SampleStatus_key', this.bulkSampleStatusKey, task);
    }
    updateBulkSampleTypeKey(task: any) {
        this.updateBulkValue('C_SampleType_key', this.bulkSampleTypeKey, task);
    }
    updateBulkDateHarvest(task: any) {
        this.updateBulkValue('DateHarvest', this.bulkDateHarvest, task);
    }
    updateBulkDateExpiration(task: any) {
        this.updateBulkValue('DateExpiration', this.bulkDateExpiration, task);
    }
    updateBulkTimePoint(task: any) {
        this.updateBulkValue('TimePoint', this.bulkTimePoint, task);
        this.updateBulkValue('C_TimeUnit_key', this.bulkTimeUnitKey, task);
    }
    updateBulkSampleSubtypeKey(task: any) {
        this.updateBulkValue('C_SampleSubtype_key', this.bulkSampleSubtypeKey, task);
    }
    updateBulkSampleProcessingMethodKey(task: any) {
        this.updateBulkValue('C_SampleProcessingMethod_key', this.bulkSampleProcessingMethodKey, task);
    }
    updateBulkSampleAnalysisMethodKey(task: any) {
        this.updateBulkValue('C_SampleAnalysisMethod_key', this.bulkSampleAnalysisMethodKey, task);
    }
    updateBulkSendTo(task: any) {
        this.updateBulkValue('SendTo', this.bulkSendTo, task);
    }
    updateBulkSpecialInstructions(task: any) {
        this.updateBulkValue('SpecialInstructions', this.bulkSpecialInstructions, task);
    }


    // Bulk update handlers
    updateBulkValue(key: string, value: any, task: any) {
        // Update the rows in the Create Modal
        for (const row of task.sampleGroups) {
            row[key] = value;
        }
    }

    isRegularInput(input: any): boolean {
        return input.cv_DataType.DataType !== DataType.DOSING_TABLE &&
            input.cv_DataType.DataType !== DataType.JOB_CHARACTERISTIC;
    }

    isDosingTableInput(input: any): boolean {
        return input.cv_DataType.DataType === DataType.DOSING_TABLE;
    }

    isJobCharacteristicInput(input: any): boolean {
        return input.cv_DataType.DataType === DataType.JOB_CHARACTERISTIC;
    }

    // <select> formatters
    taskFrequencyKeyFormatter = (value: any) => {
        return value.C_TaskFrequency_key;
    }
    taskFrequencyFormatter = (value: any) => {
        return value.TaskFrequency;
    }
    timeUnitKeyFormatter = (value: any) => {
        return value.C_TimeUnit_key;
    }
    timeUnitFormatter = (value: any) => {
        return value.TimeUnit;
    }
    frequencyKeyFormatter = (value: any) => {
        return value.C_Frequency_key;
    }
    frequencyFormatter = (value: any) => {
        return value.Frequency;
    }
    timeRelationsKeyFormatter = (value: any) => {
        return value.C_TimeRelation_key;
    }
    timeRelationsTypeFormatter = (value: any) => {
        return value.TimeRelation;
    }
    scheduleTypesKeyFormatter = (value: any) => {
        return value.C_ScheduleType_key;
    }
    scheduleTypesFormatter = (value: any) => {
        return value.ScheduleType;
    }
    sampleTypeKeyFormatter = (value: any) => {
        return value.C_SampleType_key;
    }
    sampleTypeFormatter = (value: any) => {
        return value.SampleType;
    }
    sampleStatusKeyFormatter = (value: any) => {
        return value.C_SampleStatus_key;
    }
    sampleStatusFormatter = (value: any) => {
        return value.SampleStatus;
    }
    preservationMethodKeyFormatter = (value: any) => {
        return value.C_PreservationMethod_key;
    }
    preservationMethodFormatter = (value: any) => {
        return value.PreservationMethod;
    }
    containerTypeKeyFormatter = (value: any) => {
        return value.C_ContainerType_key;
    }
    containerTypeFormatter = (value: any) => {
        return value.ContainerType;
    }
    sampleSubtypeKeyFormatter = (value: any) => {
        return value.C_SampleSubtype_key;
    }
    sampleSubtypeFormatter = (value: any) => {
        return value.SampleSubtype;
    }
    sampleProcessingMethodKeyFormatter = (value: any) => {
        return value.C_SampleProcessingMethod_key;
    }
    sampleProcessingMethodFormatter = (value: any) => {
        return value.SampleProcessingMethod;
    }
    sampleAnalysisMethodKeyFormatter = (value: any) => {
        return value.C_SampleAnalysisMethod_key;
    }
    sampleAnalysisMethodFormatter = (value: any) => {
        return value.SampleAnalysisMethod;
    }
}

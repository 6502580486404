import type { Protocol } from './protocol.interface';
import type { ProtocolTask } from './protocol-task.interface';

export interface ProtocolTaskSection {
    C_ParentProtocol_key: number;
    C_ProtocolTaskSection_key: number;
    C_Workgroup_key: number;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    ModifiedBy: string;
    ParentProtocol: Protocol;
    ProtocolTask: ProtocolTask[];
    SectionName: string;
    SortOrder: number | null;
    Version: number;
}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostBinding, Injector, Input, OnInit, SkipSelf } from '@angular/core';
import { ButtonComponent } from '@common/components/button/button.component';
import { cross } from '@icons';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'button[climbDeleteItemButton]',
    templateUrl: './delete-item-button.component.html',
    styleUrls: ['./delete-item-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeleteItemButtonComponent extends ButtonComponent implements OnInit {
    readonly icons = { cross };

    @HostBinding('attr.title')
    @Input() title = 'Delete item';

    constructor(
        injector: Injector,
        elementRef: ElementRef,
        @SkipSelf() cdr: ChangeDetectorRef,
    ) {
        super(injector, elementRef, cdr);
    }

    ngOnInit(): void {
        super.ngOnInit();
        super.variant = 'ghost-negative';
        super.size = 'sm';
        super.onlyIcon = true;
    }
}

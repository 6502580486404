import {
    Component,
    Input,
    OnDestroy,
    OnChanges,
    OnInit,
} from '@angular/core';
import { JobPharmaDetailService } from '../services/job-pharma-detail.service';
import { getSafeProp, sortObjectArrayByAccessor, sortObjectArrayByAccessors } from '../../../common/util';
import { TableSort } from '../../../common/models';

@Component({
    selector: 'job-pharma-animals-individual-placeholder-table',
    templateUrl: './job-pharma-animals-individual-placeholder-table.component.html',
    styles: [`
       .ui-draggable-dragging {
         padding: 4px;
         border-radius: 2px;
         font-size: 12px;
         margin-left: 20px;
       }
    `]
})
export class JobPharmaAnimalsIndividualPlaceholderTableComponent implements OnDestroy, OnInit, OnChanges {
    @Input() readonly: boolean;
    @Input() job: any;

    @Input() tabset = 'animals';
    @Input() tab = 'individual-placeholder';

    animalPlaceholders: any[] = [];
    tableSort: TableSort = new TableSort();

    // Are all the rows selected?
    allSelected = false;

    readonly COMPONENT_LOG_TAG = 'job-pharma-animals-individual-placeholder-table';

    constructor(
        private jobPharmaDetailService: JobPharmaDetailService,
    ) {
        // Do nothing
    }

    ngOnInit() {
        this.initialize();
    }

    ngOnDestroy() {
        this.clearSelections();
    }

    ngOnChanges(changes: any) {
        if (changes.job && !changes.job.firstChange) {
            this.initJob();
        }
    }

    initialize() {
        this.initJob();
    }

    // Initialize the list of placeholders
    private initJob() {
        this.animalPlaceholders = this.job.AnimalPlaceholder;
    }

    /**
     * Unselect all the rows
     */
    clearSelections() {
        if (this.job.AnimalPlaceholder) {
            // Reset animal selections
            this.job.AnimalPlaceholder.forEach((placeholder: any) => { placeholder.isSelected = false; });
        }
        this.allSelected = false;
    }

    /**
     * The "Clear All" button was clicked
     */
    clearAllClicked($event: any) {
        // Clear the selections first
        this.clearSelections();

        // Also clear out the header the checkbox
        $event.target.checked = false;
    }

    /**
     * The Select/Clear All button was clicked
     */
    allSelectedChanged() {
        // Select or unselect all the rows
        if (this.job.AnimalPlaceholder) {
            for (const placeholder of this.job.AnimalPlaceholder) {
                placeholder.isSelected = this.allSelected;
            }
        }
    }

    /**
     * A row selection checkbox was clicked.
     */
    isSelectedChanged() {
        // Check if all the rows are selected
        this.allSelected = this.job.AnimalPlaceholder.every((placeholder: any) => placeholder.isSelected);
    }

    // Dragging Out
    private dragId: number = null;
    dragStart() {
        // Find the selected animal placeholders
        const selected = this.job.AnimalPlaceholder
            .filter((placeholder: any) => placeholder.isSelected);

        this.dragId = this.jobPharmaDetailService.startDrag('AnimalPlaceholder', selected);
        if (selected.length > 1) {
            jQuery('.ui-draggable-dragging')[0].textContent = `${selected.length} Animal Placeholders Selected`;
        }
    }

    // TODO: ?
    dragStop() {
        setTimeout(() => {
            this.jobPharmaDetailService.stopDrag(this.dragId);
        }, 500);
    }

    sortColumn(column: any, event: any) {
        if (event.shiftKey) {
            this.tableSort.nested = true;
        } else {
            this.tableSort.nested = false;
        }

        this.tableSort.toggleSort(column);


        if (this.tableSort.nested) {
            const sortDefs = this.tableSort.properties.map((path: any) => {
                return {
                    sortAccessor: (item: any) => {
                        return getSafeProp(item, path);
                    },
                    descending: this.tableSort.nestedReverse[path]
                };
            });
            sortObjectArrayByAccessors(this.animalPlaceholders, sortDefs, this.tableSort.natural);
        } else {
            if (this.tableSort.propertyPath !== '') {
                sortObjectArrayByAccessor(this.animalPlaceholders, (item) => {
                    return getSafeProp(item, this.tableSort.propertyPath);
                }, this.tableSort.reverse, this.tableSort.natural);
            }
        }
    }
}

export interface cv_Courier {
    C_Courier_key: number;
    Courier: string;
    Abbreviation: string;
    CreatedBy: string;
    DateCreated: string;
    DateModified: string;
    ElementID: string;
    IsActive: boolean;
    IsDefault: boolean;
    IsDeprecated: boolean;
    IsEndState: boolean;
    ModifiedBy: string;
    SortOrder: number;
    Version: number;
}

import { MatingHousingSelectComponent } from './components/selects/mating-housing-select.component';
import { HousingVocabService } from './services/housing-vocab.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { ClimbCommonModule } from '../common/climb-common.module';
import { ClimbServicesModule } from '../services/climb-services.module';

import { HousingAnimalTableComponent } from './components/animal-table/housing-animal-table.component';
import { HousingSocialTableComponent } from './components/social-table/housing-social-table.component';
import { HousingDetailsComponent } from './components/detail/housing-details.component';
import { HousingSelectComponent } from './components/selects/housing-select.component';
import { HousingMultiselectComponent } from './components/selects/housing-multiselect.component';
import { CreateHousingComponent } from './components/create-housing/create-housing.component';
import { UsersSharedModule } from '../user/users-shared.module';
import { LocationsSharedModule } from '../locations/locations-shared.module';
import { HousingStateService } from './services/housing-state.service';
import { HousingService } from './services/housing.service';
import { HousingSaveService } from './services/housing-save.service';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ClimbCommonModule,
        ClimbServicesModule,
        UsersSharedModule,
        LocationsSharedModule
    ],
    declarations: [
        HousingAnimalTableComponent,
        HousingSocialTableComponent,
        HousingDetailsComponent,
        HousingSelectComponent,
        HousingMultiselectComponent,
        MatingHousingSelectComponent,
        CreateHousingComponent
    ],
    exports: [
        HousingAnimalTableComponent,
        HousingSocialTableComponent,
        HousingDetailsComponent,
        HousingSelectComponent,
        HousingMultiselectComponent,
        MatingHousingSelectComponent,
        CreateHousingComponent
    ],
    providers: [
        HousingVocabService,
        HousingStateService,
        HousingService,
        HousingSaveService
    ]
})
export class HousingSharedModule { }

import {
    CellFormatterService,
    FormatterOptions,
    TableOptions
} from '../common/datatable';
import { AuditClassFactory } from './audit-class-maker';

export class AuditSettingsTableOptions {

    options: TableOptions;

    constructor(private cellFormatterService: CellFormatterService) {
        this.options = this.getDefaultOptions();
    }

    getDefaultOptions(): TableOptions {

        return {
            csvFileName: 'settings-audit.csv',
            enableDetailColumn: false,
            enableDraggable: false,
            enableSelectable: false,
            enableAuditColumns: false,
            columns: [
                {
                    displayName: 'Type',
                    field: 'UpdateType',
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Modified Fields',
                    field: 'ModifiedFields',
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Date',
                    field: 'ModifiedDate',
                    formatter: this.cellFormatterService.dateTimeUTCFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Modified By',
                    field: 'ModifiedBy',
                    formatter: this.cellFormatterService.userNameFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Default Health Technician',
                    field: 'DefaultHealthTechnician',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('DefaultHealthTechnician',  'DefaultHealthTechnician'),
                    cellClass: AuditClassFactory.createCellClass('defaulthealthtechnician'),
                },
                {
                    displayName: 'Timeout Minutes',
                    field: 'TimeoutMinutes',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('TimeoutMinutes', 'TimeoutMinutes'),
                    cellClass: AuditClassFactory.createCellClass('timeoutminutes'),
                },
                {
                    displayName: 'Created By',
                    field: 'CreatedBy',
                    formatter: this.cellFormatterService.userNameFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Created Date',
                    field: 'DateCreated',
                    formatter: this.cellFormatterService.dateTimeUTCFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                }
            ]
        };
    }
}

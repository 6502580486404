import type { Animal } from './animal.interface';
import type { AnimalClinicalObservation } from './animal-clinical-observation.interface';
import type { AnimalDiagnosticObservation } from './animal-diagnostic-observation.interface';
import type { cv_EventType } from './cv-event-type.interface';

export interface EventType {
    Animal: Animal;
    AnimalClinicalObservation: AnimalClinicalObservation;
    AnimalDiagnosticObservation: AnimalDiagnosticObservation;
    C_AnimalClinicalObservation_key: number | null;
    C_AnimalDiagnosticObservation_key: number | null;
    C_EventType_key: number;
    C_Material_key: number | null;
    C_Workgroup_key: number;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    DateOccurred: Date;
    Description: string;
    Event_key: number;
    Label: string;
    ModifiedBy: string;
    Version: number;
    cv_EventType: cv_EventType;
}

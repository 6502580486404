import type { Job } from './job.interface';
import type { Line } from './line.interface';

export interface JobLine {
    C_JobLine_key: number;
    C_Job_key: number;
    C_Line_key: number | null;
    C_Workgroup_key: number;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    Job: Job;
    Line: Line;
    ModifiedBy: string;
    SortOrder: number | null;
    Version: number;
}

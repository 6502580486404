import { CellFormatterService, FormatterOptions, TableOptions } from "../common/datatable";
import { TranslationService } from "../services/translation.service";
import { AuditModifiedFieldComponent } from './audit-modified-fields/audit-modified-field.component';
import { AuditClassFactory } from "./audit-class-maker";
 
export class AuditJobLocationsTableOptions {
    options: TableOptions;

    constructor(
        private cellFormatterService: CellFormatterService,
        private translationService: TranslationService
    ) {
        this.options = this.getJobLocationOptions();
    }

    getJobLocationOptions(): TableOptions {
        return {
            csvFileName: this.translationService.translate("job") + '-locations-audit.csv',
            enableDetailColumn: false,
            enableDraggable: false,
            enableSelectable: false,
            enableAuditColumns: false,
            columns:
                [
                    {
                        displayName: 'Type',
                        field: 'UpdateType',
                        cellClass: AuditClassFactory.createDeletedClass,
                    },
                    {
                        displayName: 'Modified Fields',
                        field: 'ModifiedFields',
                        sortField: 'ModifiedFields',
                        rendererComponent: AuditModifiedFieldComponent,
                        exportFormatter: this.cellFormatterService.auditModifiedFieldsExportFormatter,
                        cellClass: AuditClassFactory.createDeletedClass,
                    },
                    {
                        displayName: 'Date',
                        field: 'ModifiedDate',
                        formatter: this.cellFormatterService.dateTimeUTCFormatter,
                        cellClass: AuditClassFactory.createDeletedClass,
                    },
                    {
                        displayName: 'Modified By',
                        field: 'ModifiedBy',
                        formatter: this.cellFormatterService.userNameFormatter,
                        cellClass: AuditClassFactory.createDeletedClass,
                    },
                    {
                        displayName: this.translationService.translate("Job") + ' Name',
                        field: 'JobID',
                        formatter: this.cellFormatterService.createAuditColumnFormatter('JobID', this.translationService.translate("Job") + ' ID'),
                        cellClass: AuditClassFactory.createCellClass('jobid'),
                    },
                    {
                        displayName: 'Location',
                        field: 'Location',
                        formatter: this.cellFormatterService.createAuditColumnFormatter('Location', 'Location'),
                        cellClass: AuditClassFactory.createCellClass('location'),
                    },
                    {
                        displayName: 'Created By',
                        field: 'CreatedBy',
                        formatter: this.cellFormatterService.userNameFormatter,
                        cellClass: AuditClassFactory.createDeletedClass,
                    },
                    {
                        displayName: 'Created Date',
                        field: 'DateCreated',
                        formatter: this.cellFormatterService.dateTimeUTCFormatter,
                        cellClass: AuditClassFactory.createDeletedClass,
                    }
                ]
        };
    }
}

import type { cv_ClinicalObservation } from './cv-clinical-observation.interface';
import type { cv_Modifier1 } from './cv-modifier1.interface';

export interface cv_ClinicalObservationModifier1 {
    C_ClinicalObservationModifier1_key: number;
    C_ClinicalObservation_key: number;
    C_Modifier1_key: number;
    C_Workgroup_key: number;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    ModifiedBy: string;
    Version: number;
    cv_ClinicalObservation: cv_ClinicalObservation;
    cv_Modifier1: cv_Modifier1;
}

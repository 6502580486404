<table class="table" *ngIf="materialPoolMaterials | animalFilter | notEmpty">
    <thead>
        <tr>
            <th class="icon-cell" *ngIf="facetPrivilege !== 'ReadOnly'"></th>
            <th class="text-nowrap">Animal ID</th>
            <th>Name</th>
            <th>Sex</th>
            <th>Birth Date</th>
            <th>{{'Line' | translate}}</th>
            <th>Genotype(s)</th>
            <th class="text-nowrap">
                <bulk-edit-header
                    [readonly]="facetPrivilege === 'ReadOnly'"
                    [headerText]="'Status'"
                    (updateClicked)="bulkAnimalStatusChanged()">
                    <div modal-content>
                        <active-vocab-select [(model)]="bulkAnimalStatusKey"
                            [vocabChoices]="animalStatuses"
                            [keyFormatter]="animalStatusKeyFormatter"
                            [optionFormatter]="animalStatusFormatter"
                            [readonly]="facetPrivilege==='ReadOnly'"></active-vocab-select>
                    </div>
                </bulk-edit-header>
            </th>
            <th class="text-nowrap">
                <bulk-edit-header
                    [readonly]="facetPrivilege === 'ReadOnly'"
                    [headerText]="'Exit Reason'"
                    (updateClicked)="bulkExitReasonChanged()">
                    <div modal-content>
                        <active-vocab-select [(model)]="bulkExitReasonKey"
                            [vocabChoices]="exitReasons"
                            [keyFormatter]="exitReasonKeyFormatter"
                            [optionFormatter]="exitReasonFormatter"
                            [readonly]="facetPrivilege==='ReadOnly'"
                            [nullable]="true"></active-vocab-select>
                    </div>
                </bulk-edit-header>
            </th>
            <th class="text-nowrap">
                <bulk-edit-header
                    [readonly]="facetPrivilege === 'ReadOnly'"
                    [headerText]="'Date In'"
                    (updateClicked)="bulkDateInChanged()">
                    <div modal-content>
                        <climb-ngb-date #dateControl="ngModel"
                                        [(ngModel)]="bulkDateIn"
                                        [ngModelOptions]="{ standalone: true }">
                        </climb-ngb-date>
                    </div>
                </bulk-edit-header>
            </th>
            <th class="text-nowrap">
                <bulk-edit-header
                    [readonly]="facetPrivilege === 'ReadOnly'"
                    [headerText]="'Date Out'"
                    (updateClicked)="bulkDateOutChanged()">
                    <div modal-content>
                        <climb-ngb-date #dateControl="ngModel"
                                        [(ngModel)]="bulkDateOut"
                                        [ngModelOptions]="{ standalone: true }">
                        </climb-ngb-date>
                    </div>
                </bulk-edit-header>
            </th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let materialAssoc of materialPoolMaterials | animalFilter">
            <td class="icon-cell"
                *ngIf="facetPrivilege !== 'ReadOnly'">
                <div class="form-control-plaintext">
                    <ul>
                        <li class="dropdown" style="margin-left: -25px;">
                            <a class="dropdown-toggle"
                               data-toggle="dropdown"
                               role="button"
                               aria-haspopup="true"
                               aria-expanded="false">
                                <i class="fa fa-remove remove-item"></i>
                            </a>
                            <div class="dropdown-menu" style="min-width: 100px !important;">
                                <a class="dropdown-item" (click)="takeOutMaterialAssoc(materialAssoc)">Remove/Take Out</a>
                                <a class="dropdown-item" (click)="deleteMaterialAssoc(materialAssoc)">Delete</a>
                            </div>
                        </li>
                    </ul>
                </div>
            </td>
            <td class="text-nowrap">
                <div class="form-control-plaintext">
                    {{materialAssoc.Material?.Identifier}}
                </div>
            </td>
            <td class="text-nowrap">
                <div class="form-control-plaintext">
                    {{materialAssoc.Material?.Animal?.AnimalName}}
                </div>
            </td>
            <td>
                <div class="form-control-plaintext">
                    {{materialAssoc.Material?.Animal?.cv_Sex?.Sex}}
                </div>
            </td>
            <td>
                <div class="form-control-plaintext">
                    {{materialAssoc.Material?.Animal?.DateBorn | formatShortDate}}
                </div>
            </td>
            <td class="text-nowrap">
                <div class="form-control-plaintext">
                    {{materialAssoc.Material?.Line?.LineName}}
                </div>
            </td>
            <td>
                <ng-template [ngIf]="materialAssoc.Material?.Animal?.Genotype.length > 0">
                    <span>{{ materialAssoc.Material?.Animal?.Genotype | formatGenotypeNames}}</span>
                </ng-template>
            </td>
            <td>
                <active-vocab-select *ngIf="materialAssoc.Material.Animal"
                                     [(model)]="materialAssoc.Material.Animal.C_AnimalStatus_key"
                                     (modelChange)="animalStatusChanged(materialAssoc.Material.Animal)"
                                     [vocabChoices]="animalStatuses"
                                     [keyFormatter]="animalStatusKeyFormatter"
                                     [optionFormatter]="animalStatusFormatter"
                                     [readonly]="facetPrivilege==='ReadOnly'"></active-vocab-select>
            </td>
            <td>
                <active-vocab-select *ngIf="materialAssoc.Material.Animal"
                                     [(model)]="materialAssoc.Material.Animal.C_ExitReason_key"
                                     [vocabChoices]="exitReasons"
                                     [keyFormatter]="exitReasonKeyFormatter"
                                     [optionFormatter]="exitReasonFormatter"
                                     [readonly]="facetPrivilege==='ReadOnly'"
                                     [nullable]="true"></active-vocab-select>
            </td>
            <td>
                <climb-ngb-date #dateControl="ngModel"
                                [(ngModel)]="materialAssoc.DateIn"
                                [disabled]="facetPrivilege==='ReadOnly'"
                                [ngModelOptions]="{ standalone: true }">
                </climb-ngb-date>
            <td>
                <climb-ngb-date #dateControl="ngModel"
                                [(ngModel)]="materialAssoc.DateOut"
                                [disabled]="facetPrivilege==='ReadOnly'"
                                [ngModelOptions]="{ standalone: true }"
                                (ngModelChange)="dateOutChanged(materialAssoc)">
                </climb-ngb-date>
            </td>
        </tr>
    </tbody>
</table>

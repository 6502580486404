/*
 * Assigns totalAnimalCount for each MaterialPool
 * @param pools - list of MaterialPools
 */
export function assignMaterialPoolAnimalCounts(pools: any[]): void {
    if (!pools) {
        return;
    }
    for (const pool of pools) {
        if (!pool.MaterialPoolMaterial) {
            continue;
        }
        
        const animalAssocs = pool.MaterialPoolMaterial.filter((poolMaterial: any) => {
            return poolMaterial.Material &&
                poolMaterial.Material.Animal
                && !poolMaterial.DateOut;
        });
        pool.totalAnimalCount = animalAssocs.length;
    }
}

import { map } from 'rxjs/operators';
import { DetailTaskTableOptions } from '../../tasks/tables/detail-task-table-options';
import { TableSort } from '../../common/models/table-sort';
import { TaskType } from '../../tasks/models/task-type.enum';
import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    ViewChild,
    ViewChildren
} from '@angular/core';
import { NgForm, NgModel } from '@angular/forms';

import { EnumerationService } from '../../enumerations/enumeration.service';
import { LocationService } from '../../locations/location.service';
import { MaterialPoolService } from '../../services/material-pool.service';
import { MatingService } from '../mating.service';
import { MatingVocabService } from '../mating-vocab.service';
import { NamingService } from '../../services/naming.service';
import { ReportingService } from '../../reporting/reporting.service';
import { SaveChangesService, UnsavedChanges } from '../../services/save-changes.service';
import { SettingService } from '../../settings/setting.service';

import {
    notEmpty,
    randomId,
    scrollToElementFrom,
    maxSequence
} from '../../common/util';

import {
    BaseDetail,
    BaseDetailService,
    PageState
} from '../../common/facet';
import { AnimalService } from '../../animals/services/animal.service';
import { cv_ContainerType, cv_Generation, cv_MatingPurpose, cv_MatingStatus, cv_MatingType, Entity, Mating, MaterialPoolMaterial, TaskInstance } from '../../common/types';
import { dateControlValidator } from '@common/util/date-control.validator';
import { MatingPlugDateTableComponent } from '../tables';
import { CharacteristicInputComponent } from 'src/app/characteristics/characteristic-input/characteristic-input.component';
import { LocationHistoryComponent } from 'src/app/locations/location-history.component';
import { DetailTaskTableComponent } from 'src/app/tasks/tables';

interface ExtendedMatings extends Mating {
    inputsExpanded: boolean;
    taskAnimalsExpanded: boolean;
    taskCohortsExpanded: boolean;
    taskSamplesExpanded: boolean;
    tasksExpanded: boolean;
}

@Component({
    selector: 'mating-detail',
    templateUrl: './mating-detail.component.html'
})
export class MatingDetailComponent extends BaseDetail implements OnChanges, OnInit {
    @ViewChildren('characteristicInput') characteristicInputs: CharacteristicInputComponent[];
    @ViewChildren('locationHistory') locationHistories: LocationHistoryComponent[];
    @ViewChildren('detailTaskTable') detailTaskTables: DetailTaskTableComponent[];
    @ViewChildren('dateControl') dateControls: NgModel[];

    @Input() facet: any;
    @Input() mating: Entity<ExtendedMatings>;
    @Input() pageState: PageState;
    // Active and required fields set by facet settings
    @Input() activeFields: string[];
    @Input() requiredFields: string[];

    @Output() exit: EventEmitter<void> = new EventEmitter<void>();
    @Output() next: EventEmitter<void> = new EventEmitter<void>();
    @Output() previous: EventEmitter<void> = new EventEmitter<void>();

    @ViewChild('matingPlugDateTable') matingPlugDateTable: MatingPlugDateTableComponent;
    @ViewChild("matingForm") matingForm: NgForm;

    // CVs
    containerTypes: Entity<cv_ContainerType>[] = [];
    generations: Entity<cv_Generation>[] = [];
    matingPurposes: Entity<cv_MatingPurpose>[] = [];
    matingStatuses: Entity<cv_MatingStatus>[] = [];
    matingTypes: Entity<cv_MatingType>[] = [];

    // State
    exitStatusPromptVisible = false;
    housingDetailsExpanded = false;
    housingHistoryExpanded = false;

    housingPanelScrollContainerClass: string;
    housingDetailsScrollClass: string;

    housingNamingActive = false;
    matingNamingActive = false;

    saving = false;

    // Table options
    detailTaskTableOptions: DetailTaskTableOptions;

    // Table sorting
    taskTableSort: TableSort = new TableSort();

    // Export enum to template
    TaskType = TaskType;

    originalMatingID = '';

    originalMaterialPoolID = '';

    // Housing History
    animals: any[] = [];

    readonly COMPONENT_LOG_TAG = 'mating-detail';


    constructor(
        private animalService: AnimalService,
        private baseDetailService: BaseDetailService,
        private enumerationService: EnumerationService,
        private locationService: LocationService,
        private materialPoolService: MaterialPoolService,
        private matingService: MatingService,
        private matingVocabService: MatingVocabService,
        private namingService: NamingService,
        private reportingService: ReportingService,
        private saveChangesService: SaveChangesService,
        private settingService: SettingService
    ) {
        super(baseDetailService);
    }

    // lifecycle
    ngOnInit() {
        this.initialize();
        this.initTableOptions();
    }

    ngOnChanges(changes: any) {
        if (changes.mating) {
            if (this.mating && !changes.mating.firstChange) {
                if (this.matingForm) {
                    this.matingForm.form.markAsPristine();
                }

                this.initialize();
            }
        }
    }

    initialize() {
        this.setBusy(true);

        if (this.mating) {
            this.originalMaterialPoolID = this.mating.MaterialPool.MaterialPoolID;

            this.setTableStates();
            this.originalMatingID = this.mating.MatingID;
        }

        this.housingPanelScrollContainerClass = 'housing-panel-scroll-container-' + randomId();
        this.housingDetailsScrollClass = 'housing-details-' + randomId();

        return this.isNamingActive().then(() => {
            return this.getCVs();
        }).then(() => {
            return this.getDetails();
        }).then(() => {
            return this.getMaterialPoolMaterials();
        }).then(() => {
            this.animals = [];
            return this.getHousingHistory();
        }).then(() => {
            return this.matingService.getMatingCharacteristics(
                this.mating.C_MaterialPool_key
            );
        }).then((matingCharacteristicInstances: any[]) => {
            return this.attachCharacteristicEnumerations(
                this.mating.MatingCharacteristicInstance
            );
            }).then(() => {
                return this.getTasks();
            }).then(() => {
                this.setBusy(false);
        }).catch((error) => {
            this.setBusy(false);
            throw error;
        });
    }

    /**
     * Sets view defaults for tables
     */
    setTableStates() {
        // For now, these are constant
        this.mating.tasksExpanded = true;
        this.mating.inputsExpanded = true;
        this.mating.taskAnimalsExpanded = false;
        this.mating.taskCohortsExpanded = false;
        this.mating.taskSamplesExpanded = false;
    }

    private initTableOptions() {
        // Detail Task Table
        this.detailTaskTableOptions = new DetailTaskTableOptions();
        this.detailTaskTableOptions.allowLocking = false;
        this.detailTaskTableOptions.showAnimals = false;
        this.detailTaskTableOptions.showSamples = false;
    }

    private isNamingActive(): Promise<void> {
        return this.namingService.isHousingNamingActive()
            .then((active: boolean) => {
                this.housingNamingActive = active;
            }).then(() => {
                return this.namingService.isMatingNamingActive();
            }).then((active: boolean) => {
                this.matingNamingActive = active;
            });
    }

    getCVs(): Promise<any> {
        const p1: Promise<any> = this.matingVocabService.containerTypes$.pipe(map((data) => {
            this.containerTypes = data;
        })).toPromise();

        const p2: Promise<any> = this.matingVocabService.generations$.pipe(map((data) => {
            this.generations = data;
        })).toPromise();

        const p3: Promise<any> = this.matingVocabService.matingPurposes$.pipe(map((data) => {
            this.matingPurposes = data;
        })).toPromise();

        const p4: Promise<any> = this.matingVocabService.matingStatuses$.pipe(map((data) => {
            this.matingStatuses = data;
        })).toPromise();

        const p5: Promise<any> = this.matingVocabService.matingTypes$.pipe(map((data) => {
            this.matingTypes = data;
        })).toPromise();

        return Promise.all([p1, p2, p3, p4, p5]);
    }

    private getDetails(): Promise<Entity<Mating>> {
        if (this.mating && this.mating.C_MaterialPool_key > 0) {
            return this.matingService.getMatingDetails(this.mating.C_MaterialPool_key);
        }

        return Promise.resolve(this.mating);
    }

    private getMaterialPoolMaterials(): Promise<Entity<MaterialPoolMaterial>[]> {
        return this.materialPoolService.getMaterialPoolMaterials(
            this.mating.C_MaterialPool_key
        );
    }

    private getTasks(): Promise<Entity<TaskInstance>[]> {
        return this.matingService.getMatingTasks(this.mating.C_MaterialPool_key);
    }

    private getHousingHistory(): Promise<any> {
        const animals: MaterialPoolMaterial[] = [];
        this.mating.MaterialPool.MaterialPoolMaterial.forEach((pool: MaterialPoolMaterial) => {
            if (pool.Material.Animal) {
                if (animals.findIndex((animal) => animal.C_Material_key === pool.Material.C_Material_key) === -1) {
                    animals.push(pool);
                }
            }
        });
        const housingHistories: any[] = [];
        animals.forEach((animal) => {
            housingHistories.push(this.animalService.getMaterialPoolHistory(animal.Material.Animal.C_Material_key)
                .then((data: any[]) => {
                    this.animals.push({
                        Animal: animal,
                        History: data.length > 3 ? data.slice(data.length - 3, data.length) : data
                    });
                }));
        });
        return Promise.all(housingHistories);
    }


    onCancel() {
        this.matingService.cancelMating(this.mating);
    }

    // Tasks
    addTaskMaterialPool(taskInstance: Entity<TaskInstance>) {
        const taskSequence = maxSequence(this.mating.MaterialPool.TaskMaterialPool) + 1;

        this.matingService.createTaskMating(
            this.mating.C_MaterialPool_key,
            taskInstance.C_TaskInstance_key,
            taskSequence
        );
    }



    /**
     * On mating type change, deletes and adds appropriate MatingCharacteristics.
     * @param mating
     */
    typeChanged(mating: Entity<Mating>) {
        while (mating.MatingCharacteristicInstance.length > 0) {
            const characteristicInstance = mating.MatingCharacteristicInstance[0];
            this.matingService.deleteMatingCharacteristic(characteristicInstance);
        }

        this.matingService.createMatingCharacteristics(mating).then((data: any[]) => {
            this.attachCharacteristicEnumerations(this.mating.MatingCharacteristicInstance);
        });
    }

    private attachCharacteristicEnumerations(characteristicInstances: any[]) {
        const promises = [];

        for (const characteristicInstance of characteristicInstances) {
            if (characteristicInstance.MatingCharacteristic.C_EnumerationClass_key) {
                const promise = this.enumerationService.getEnumerationItems(
                    characteristicInstance.MatingCharacteristic.C_EnumerationClass_key
                ).then((items: any[]) => {
                    characteristicInstance.EnumerationItems = items;
                });

                promises.push(promise);
            }
        }

        return Promise.all(promises);
    }


    // Mating Status
    matingStatusChanged() {
        // If exit status, display link to change animal statuses.
        if (this.mating.cv_MatingStatus && this.mating.cv_MatingStatus.IsExitStatus) {
            this.showExitStatusPrompt();
        } else {
            this.hideExitStatusPrompt();
        }
    }

    showExitStatusPrompt() {
        this.exitStatusPromptVisible = true;
    }

    hideExitStatusPrompt() {
        this.exitStatusPromptVisible = false;
    }

    scrollToHousingDetails() {
        this.showHousingDetails();

        setTimeout(() => {
            scrollToElementFrom(
                '.' + this.housingDetailsScrollClass,
                '.' + this.housingPanelScrollContainerClass
            );
        });
    }


    // Housing Details
    toggleHousingDetailsExpanded() {
        this.housingDetailsExpanded = !this.housingDetailsExpanded;
    }

    toggleHousingHistoryExpanded() {
        this.housingHistoryExpanded = !this.housingHistoryExpanded;
    }

    showHousingDetails() {
        this.housingDetailsExpanded = true;
    }

    hideHousingDetails() {
        this.housingDetailsExpanded = false;
    }


    // Line
    lineSelected(lineKey: number) {
        if (!lineKey) {
            return;
        }

        return this.locationService.addDefaultLineLocationIfNeeded(lineKey, this.mating.C_MaterialPool_key);
    }

    // Locations
    createMaterialLocation() {
        return this.locationService.getDefaultLocationForLine(this.mating.C_Line_key)
            .then((defaultLocation) => {
                if (defaultLocation) {
                    const initialValues = {
                        C_LocationPosition_key: defaultLocation.C_LocationPosition_key,
                        C_MaterialPool_key: this.mating.C_MaterialPool_key
                    };
                    return this.locationService.createMaterialLocation(initialValues);
                }
            });
    }

    removeMaterialLocation(materialPoolLocation: any) {
        this.materialPoolService.deleteMaterialPoolLocation(materialPoolLocation);
    }

    async onSaveMating() {
        let errMsg;

        // Validate fields required by facet settings
        if (!errMsg) {
            errMsg = this.characteristicInputs.map(input => input.validate()).find(msg => msg)
                || this.locationHistories.map(input => input.validate()).find(msg => msg)
                || this.detailTaskTables.map(input => input.validate()).find(msg => msg)
                || dateControlValidator(this.dateControls)
                || this.matingPlugDateTable.validate()
                || await this.settingService.validateRequiredFields(this.requiredFields, this.mating, 'mating');
        }

        if (errMsg) {
            this.loggingService.logError(errMsg, 'Validation Error', this.COMPONENT_LOG_TAG, true);
            return;
        }

        if (this.locationService.locationsValid(this.mating.MaterialPool.MaterialLocation)) {

            const matingErrors = this.matingForm.controls.matingID.errors;
            if (matingErrors && matingErrors.required) {
                this.loggingService.logError('A mating requires an ID. Please enter a mating ID' +
                    ' and try again.', null, this.COMPONENT_LOG_TAG, true);
                return;
            }

            if (matingErrors && matingErrors.unique && this.mating.MatingID !== this.originalMatingID) {
                this.loggingService.logError('A mating requires a unique ID. Please enter' +
                    ' a new mating ID and try again.', null, this.COMPONENT_LOG_TAG, true);
                return;
            }

            const housingErrors = this.matingForm.controls.materialPoolID.errors;
            if (housingErrors && housingErrors.required) {
                this.loggingService.logError('A mating requires a housing ID. Please enter a housing ID' +
                    ' and try again.', null, this.COMPONENT_LOG_TAG, true);
                return;
            }
            if (housingErrors && housingErrors.unique && this.mating.MaterialPool.MaterialPoolID !== this.originalMaterialPoolID) {
                this.loggingService.logError('A mating requires a unique housing ID. Please enter' +
                    ' a new housing ID and try again.', null, this.COMPONENT_LOG_TAG, true);
                return;
            }

            this.saveChangesService.saveChanges(this.COMPONENT_LOG_TAG);
        } else {
            this.loggingService.logError("Locations dates are not valid.", null, this.COMPONENT_LOG_TAG, true);
        }
    }

    onSwitchView(buttonName: string): any {
        if (this.saveChangesService.hasChanges) {
            // If there are unsaved changes, prompt the user to save or discard
            return this.viewUnsavedChangesModalService.openComponent().then((result: string) => {
                if (result === 'save') {
                    // Throw error & don't save if location dates are invalid
                    if (this.locationService.locationsValid(this.mating.MaterialPool.MaterialLocation)) {
                        return this.saveChangesService.saveChanges(this.COMPONENT_LOG_TAG).then(() => {
                            // Emits reload event when save a new record by clicking on next
                            if (buttonName === 'next') {
                                this.reload.emit();
                            }
                            this.emitViewChange(buttonName, UnsavedChanges.save);
                        });
                    } else {
                        this.loggingService.logError("Locations dates are not valid.", null, this.COMPONENT_LOG_TAG, true);
                    }
                } else {
                    if (this.dataContext.hasChanges()) {
                        this.onCancel();
                        this.loggingService.logFacetUndoSuccess(this.COMPONENT_LOG_TAG);
                    }
                    this.emitViewChange(buttonName, UnsavedChanges.discard);
                }
            });
        } else {
            this.emitViewChange(buttonName, UnsavedChanges.noChanges);
        }
    }

    private emitViewChange(buttonName: string, changes: UnsavedChanges) {
        switch (buttonName) {
            case 'previous':
                super.previousClicked(changes);
                this.previous.emit();
                break;
            case 'next':
                super.nextClicked(changes);
                this.next.emit();
                break;
            case 'exit':
                super.exitClicked(changes);
                this.exit.emit();
                break;
            default:
                break;
        }
    }

    isAnimalsRequired() {
        return this.requiredFields.includes('MaterialPool.MaterialPoolMaterial[0]')
            && (this.mating.MaterialPool.MaterialPoolMaterial.length === 0
                || !this.mating.MaterialPool.MaterialPoolMaterial.find((material: any) => material.DateOut === null));
    }

    // Births
    anyDatedBirths() {
        if (this.mating && notEmpty(this.mating.Birth)) {
            return this.mating.Birth.some((birth: any) => {
                return birth.DateBorn !== null && birth.DateBorn !== undefined;
            });
        } else {
            return false;
        }
    }


    // Cage Cards
    requestCageCard() {
        this.reportingService.requestCageCardMating([this.mating.C_MaterialPool_key]);
    }


    // <select> formatters
    generationKeyFormatter = (value: any) => {
        return value.C_Generation_key;
    }
    generationFormatter = (value: any) => {
        return value.Generation;
    }
    matingPurposeKeyFormatter = (value: any) => {
        return value.C_MatingPurpose_key;
    }
    matingPurposeFormatter = (value: any) => {
        return value.MatingPurpose;
    }
    matingStatusKeyFormatter = (value: any) => {
        return value.C_MatingStatus_key;
    }
    matingStatusFormatter = (value: any) => {
        return value.MatingStatus;
    }
    matingTypeKeyFormatter = (value: any) => {
        return value.C_MatingType_key;
    }
    matingTypeFormatter = (value: any) => {
        return value.MatingType;
    }
    containerTypeKeyFormatter = (value: any) => {
        return value.C_ContainerType_key;
    }
    containerTypeFormatter = (value: any) => {
        return value.ContainerType;
    }
    setBusy(busy: boolean) {
        this.setLoading(busy);
    }
}

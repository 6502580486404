import { DeviceDetailComponent } from './device-detail.component';
import { LocationsSharedModule } from './../locations/locations-shared.module';
import { HousingSharedModule } from './../housing/housing-shared.module';
import { FacetSharedModule } from './../common/facet/facet-shared.module';
import { AlertDeviceMultiselectComponent } from './alert-device-multiselect.component';
import { IoTSharedModule } from './iot-shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { ClimbCommonModule } from '../common/climb-common.module';
import { ClimbServicesModule } from '../services/climb-services.module';
import { WorkspacesSharedModule } from '../workspaces/workspaces-shared.module';
import { PlotlyModule } from '@common/plotly/plotly.module';

import { DeviceManagementFacetComponent } from './device-management-facet.component';
import { IoTAlertDefinitionDetailsComponent } from './iot-alert-definition-details.component';
import { IoTAlertDefinitionFacetComponent } from './iot-alert-definition-facet.component';
import { IoTPlotsFacetComponent } from './iot-plots-facet.component';

import { DeviceManagementService } from './device-management.service';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgbModule,
        ClimbCommonModule,
        ClimbServicesModule,
        FacetSharedModule,
        HousingSharedModule,
        LocationsSharedModule,
        IoTSharedModule,
        WorkspacesSharedModule,
        PlotlyModule,
    ],
    declarations: [
        DeviceManagementFacetComponent,
        DeviceDetailComponent,
        IoTAlertDefinitionDetailsComponent,
        IoTAlertDefinitionFacetComponent,
        IoTPlotsFacetComponent,
        AlertDeviceMultiselectComponent
    ],
    exports: [
        DeviceManagementFacetComponent,
        IoTAlertDefinitionFacetComponent,
        IoTPlotsFacetComponent,
    ],
    providers: [
        DeviceManagementService
    ]
})
export class IoTModule { }

import {
    CellFormatterService,
    FormatterOptions,
    TableOptions
} from '../common/datatable';
import { TranslationService } from '../services/translation.service';
import { AuditModifiedFieldComponent } from './audit-modified-fields/audit-modified-field.component';
import { AuditClassFactory } from './audit-class-maker';

export class AuditJobInstitutionsTableOptions {

    options: TableOptions;

    constructor(
        private cellFormatterService: CellFormatterService,
        private translationService: TranslationService,
        private isCRO: boolean,
        private isCRL: boolean,
    ) {
        this.options = this.getDefaultOptions();
    }

    getDefaultOptions(): TableOptions {

        let columns = [
            this.getUpdateTypeColumn(),
            this.getModifiedFieldsColumn(),
            this.getModifiedDateColumn(),
            this.getModifiedByColumn(),
            this.getJobNameColumn(),
            this.getInstitutionNameColumn(),
            this.getSiteNameColumn()
        ];

        if (this.isCRO) {
            if (this.isCRL) {
                columns = [
                    ...columns,
                    this.getScientificContactPersonColumn(),
                    this.getBillingContactsColumn(),
                    this.getAuthorizationContactPersonColumn()
                ];
            } else {
                columns = [
                    ...columns,
                    this.getScientificContactPersonColumn(),
                    this.getBillingContactPersonColumn(),
                    this.getAuthorizationContactPersonColumn()
                ];
            }
        }

        columns.push(this.getCreatedBy(), this.getDateCreated());

        return {
            csvFileName:
                this.translationService.translate('job').toLowerCase() + '-' +
                this.translationService.translate('institution').toLocaleLowerCase() +
                '-audit.csv',
            enableDetailColumn: false,
            enableDraggable: false,
            enableSelectable: false,
            enableAuditColumns: false,
            columns
        };
    }

    private getUpdateTypeColumn(): any {
        return {
            displayName: 'Type',
            field: 'UpdateType',
            cellClass: AuditClassFactory.createDeletedClass,
        };
    }

    private getModifiedFieldsColumn(): any {
        return {
            displayName: 'Modified Fields',
            field: 'ModifiedFields',
            sortField: 'ModifiedFields',
            rendererComponent: AuditModifiedFieldComponent,
            exportFormatter: this.cellFormatterService.auditModifiedFieldsExportFormatter,
            cellClass: AuditClassFactory.createDeletedClass,
        };
    }

    private getModifiedDateColumn(): any {
        return {
            displayName: 'Date',
            field: 'ModifiedDate',
            formatter: this.cellFormatterService.dateTimeUTCFormatter,
            cellClass: AuditClassFactory.createDeletedClass,
        };
    }

    private getModifiedByColumn(): any {
        return {
            displayName: 'Modified By',
            field: 'ModifiedBy',
            formatter: this.cellFormatterService.userNameFormatter,
            cellClass: AuditClassFactory.createDeletedClass,
        };
    }

    private getJobNameColumn(): any {
        return {
            displayName: this.translationService.translate('Job') + ' Name',
            field: 'JobName',
            formatter: this.cellFormatterService.createAuditColumnFormatter('JobName', (this.translationService.translate('Job') + 'Name')),
            cellClass: AuditClassFactory.createCellClass((this.translationService.translate('Job') + 'Name').toLowerCase()),
        };
    }

    private getInstitutionNameColumn(): any {
        return {
            displayName: this.translationService.translate('Institution'),
            field: 'InstitutionName',
            formatter: this.cellFormatterService.createAuditColumnFormatter('InstitutionName', this.translationService.translate('Institution') + 'Name'),
            cellClass: AuditClassFactory.createCellClass((this.translationService.translate('Institution') + 'Name').toLowerCase()),
        };
    }

    private getSiteNameColumn(): any {
        return {
            displayName: 'Site',
            field: 'SiteName',
            formatter: this.cellFormatterService.createAuditColumnFormatter('SiteName', 'SiteName'),
            cellClass: AuditClassFactory.createCellClass('sitename'),
        };
    }

    private getScientificContactPersonColumn(): any {
        return {
            displayName: 'Scientific Contact',
            field: 'ScientificContactPerson',
            formatter: this.cellFormatterService.createAuditColumnFormatter('ScientificContactPerson', 'ScientificContactPerson'),
            cellClass: AuditClassFactory.createCellClass('scientificcontactperson'),
        };
    }

    private getBillingContactPersonColumn(): any {
        return {
            displayName: 'Billing Contact',
            field: 'BillingContactPerson',
            formatter: this.cellFormatterService.createAuditColumnFormatter('BillingContactPerson', 'BillingContactPerson'),
            cellClass: AuditClassFactory.createCellClass('billingcontactperson'),
        };
    }

    private getBillingContactsColumn(): any {
        return {
            displayName: 'Billing Contacts',
            field: 'JobInstitutionBillingContacts',
            formatter: this.cellFormatterService.createAuditColumnFormatter('JobInstitutionBillingContacts', 'JobInstitutionBillingContacts'),
            cellClass: AuditClassFactory.createCellClass('jobinstitutionbillingcontacts'),
        };
    }

    private getAuthorizationContactPersonColumn(): any {
        return {
            displayName: 'Authorization Contact',
            field: 'AuthorizationContactPerson',
            formatter: this.cellFormatterService.createAuditColumnFormatter('AuthorizationContactPerson', 'AuthorizationContactPerson'),
            cellClass: AuditClassFactory.createCellClass('authorizationcontactperson'),
        };
    }

    private getCreatedBy(): any {
        return {
            displayName: 'Created By',
            field: 'CreatedBy',
            formatter: this.cellFormatterService.userNameFormatter,
            cellClass: AuditClassFactory.createDeletedClass,
        };
    }

    private getDateCreated(): any {
        return {
            displayName: 'Created Date',
            field: 'DateCreated',
            formatter: this.cellFormatterService.dateTimeUTCFormatter,
            cellClass: AuditClassFactory.createDeletedClass,
        };
    }
}

<div class="modal-header justify-content-center">
    <h3 class="modal-title">
        {{ options.title }}
    </h3>
</div>

<div class="modal-body">
    <ng-container *ngFor="let line of options.bodyText | mapFn : split">
        <p>{{ line }}</p>
    </ng-container>

    <ng-container *ngIf="options.bodyHtml">
        <div [innerHTML]="options.bodyHtml"></div>
    </ng-container>

    <ng-container *ngTemplateOutlet="options.bodyTemplate"></ng-container>
</div>

<div class="modal-footer">
    <button *ngFor="let action of options.actions"
            type="button"
            class="btn btn-lg btn-fw-wide"
            [ngClass]="action.style"
            (click)="onActionClick(action.value)"
    >
        {{ action.title }}
    </button>
</div>

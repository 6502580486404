<button type="button"
        class="btn btn-secondary text-nowrap"
        (click)="viewNotes()"
        [disabled]="isObjectNew"
        title="Notes">
    <i class="fa fa-comment-o" title="Notes"></i>
    <span class="badge"
          *ngIf="!loading; else loadingIndicator">{{noteCount}}</span>
    <ng-template #loadingIndicator>
        <i class="fa fa-spinner fa-spin" title="Loading&hellip;"></i>
    </ng-template>
</button>

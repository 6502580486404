import { Injectable } from "@angular/core";
import { DataManagerService } from "@services/data-manager.service";
import { LoggingService } from "@services/logging.service";
import { FeatureFlagService } from "@services/feature-flags.service";
import { GlpOdataFacetSaveService } from "@services/glp-odata-facet-save.service";
import { ToastrService } from "@services/toastr.service";
import { OrderStateService } from "./order-state.service";
import { ReasonForChangeService } from '@common/reason-for-change/reason-for-change.service';
import { DeletionService } from '@services/deletion.service';
@Injectable()
export class OrderSaveService extends GlpOdataFacetSaveService {
    protected saveSource = "order";

    constructor(
        protected dataManagerService: DataManagerService,
        protected deletionService: DeletionService,
        protected loggingService: LoggingService,
        protected featureFlagService: FeatureFlagService,
        protected reasonForChangeService: ReasonForChangeService,
        protected toastrService: ToastrService,
        private orderStateService: OrderStateService,
    ) {
        super(
            dataManagerService,
            deletionService,
            loggingService,
            featureFlagService,
            reasonForChangeService,
            toastrService,
            orderStateService,
        );
    }
}

import {
    CellFormatterService,
    END_STATE_CLASS,
    TableOptions
} from '../common/datatable';
import { DataType } from '../data-type';
import { TranslationService } from '../services/translation.service';
import { addAuditColumnsToOptions } from '@common/datatable/utils';

export class TaskTableOptions {

    options: TableOptions;

    constructor(
        private cellFormatterService: CellFormatterService,
        private translationService: TranslationService
    ) {
        this.options = addAuditColumnsToOptions(cellFormatterService)(this.getDefaultOptions());
    }

    getDefaultOptions(): TableOptions {
        return {
            csvFileName: 'tasks.csv',
            enableDeleteColumn: true,
            enableDetailColumn: true,
            enableDraggable: true,
            dragOnlyActiveRows: true,
            enableSelectable: true,
            rowClass: (params: any) => {
                const row = params.data;

                const classes: string[] = [];
                if (row && row.IsActive !== true) {
                    classes.push(END_STATE_CLASS);
                }
                return classes;
            },
            columns: [
                {
                    displayName: 'Name', field: 'TaskName'
                },
                {
                    displayName: 'Description', field: 'Description'
                },
                {
                    displayName: 'Type', field: 'cv_TaskType.TaskType',
                    formatter: (row: any, value: any) => {
                        return value ? this.translationService.translate(value) : '';
                    }
                },
                {
                    displayName: 'Active', field: 'IsActive',
                    formatter: this.cellFormatterService.booleanFormatter,
                    exportFormatter: this.cellFormatterService.booleanExportFormatter,
                    maxWidth: 80,
                    visible: false
                },
                {
                    displayName: 'Effort', field: 'Effort', visible: false
                },
                {
                    displayName: 'Flags',
                    field: 'Output',
                    visible: false,
                    formatter: (row: any, value: any) => {
                        const outputs = value
                            .filter((output: any) => output.cv_DataType &&
                                (output.cv_DataType.DataType === DataType.NUMBER ||
                                    output.cv_DataType.DataType === DataType.CALCULATED))
                            .map((output: any) => this.renderOutput(output));

                        return outputs.filter((o: any) => o.length > 0).join(' | ');
                    }
                }
            ]
        };
    }

    private renderOutput(output: any): string {
        const flags = output.OutputFlag.map((flag: any) => this.renderFlag(flag)).join(', ');

        if (flags.length === 0) {
            return '';
        }

        return `${output.OutputName}: ${flags}`;
    }

    private renderFlag(flag: any): string {
        const message = flag.TaskFlagMessage !== null
            ? `${flag.TaskFlagMessage} → `
            : '';
        let rule = '';

        if (flag.Minimum !== null && flag.Maximum !== null) {
            rule = flag.Minimum === flag.Maximum
                ? `Χ = ${flag.Minimum}`
                : `${flag.Minimum} ≤ Χ ≤ ${flag.Maximum}`;
        } else if (flag.Maximum !== null) {
            rule = `Χ ≤ ${flag.Maximum}`;
        } else if (flag.Minimum !== null) {
            rule = `Χ ≥ ${flag.Minimum}`;
        }

        return `${message}${rule}`;
    }
}

import type { AnimalPlaceholder } from './animal-placeholder.interface';
import type { Placeholder } from './placeholder.interface';
import type { TaskInstance } from './task-instance.interface';
import type { TaskPlaceholderInput } from './task-placeholder-input.interface';

export interface TaskPlaceholder {
    AnimalPlaceholder: AnimalPlaceholder;
    C_AnimalPlaceholder_key: number | null;
    C_Placeholder_key: number | null;
    C_TaskInstance_key: number;
    C_TaskPlaceholder_key: number;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    ModifiedBy: string;
    Placeholder: Placeholder;
    TaskInstance: TaskInstance;
    TaskPlaceholderInput: TaskPlaceholderInput[];
}

import {
    Component,
    Input,
    OnInit,
    Output,
    AfterViewInit,
    EventEmitter,
    OnChanges,
    SimpleChanges,
} from '@angular/core';
import { randomId } from './util';
import { BulkEditField, BulkEditOptions } from './facet';

export interface IButtonBulkEdit {
    text: string;
    fieldName: string;
    isDisabled?: boolean;
}

/**
 * Bulk edit header modal for updating all rows
 */
@Component({
    selector: 'bulk-edit-header',
    templateUrl: './bulk-edit-header.component.html',
    styles: [`
        .dropdown-toggle {
            white-space: nowrap;
        }
        .dropdown-menu {
            padding: 10px;
        }
    `]
})
export class BulkEditHeaderComponent implements OnInit, OnChanges, AfterViewInit {
    @Input() headerText: string;
    @Input() showExtraMenuItems: boolean;
    @Input() tooltip: string;
    @Input() mainOptionTitle = 'Bulk Edit';
    @Input() updateButtonText = 'Update All';
    @Input() readonly: boolean;
    @Input() disabled: boolean;
    @Input() skipDropdown: boolean;
    @Input() hideBulkEditMenuOption: boolean;
    @Input()
        set bulkEditCustomButtons(value: IButtonBulkEdit[]) {
            this._bulkEditCustomButtons = value ?? [];
        }
        get bulkEditCustomButtons() {
            return this._bulkEditCustomButtons;
        }
    @Input() options: BulkEditOptions;
    @Input() field: string;
    @Input()
        set dropdownMenuMaxWidth(value: string) {
            if (value === '') {
                value = null;
            }
            this._dropdownMenuMaxWidth = value ?? '600px';
        }
        get dropdownMenuMaxWidth() {
            return this._dropdownMenuMaxWidth;
        }
    @Output() updateClicked: EventEmitter<void> = new EventEmitter<void>();
    @Output() bodyClicked: EventEmitter<void> = new EventEmitter<void>();
    @Output() menuOpened: EventEmitter<void> = new EventEmitter<void>();
    @Output() caretClicked: EventEmitter<void> = new EventEmitter<void>();
    @Output() updateCustomButtonClicked: EventEmitter<unknown> = new EventEmitter<unknown>();

    showMenu: boolean;
    _idPrefix: string;
    dropdownToggle = 'dropdown';

    private _bulkEditCustomButtons: IButtonBulkEdit[] = [];
    private _dropdownMenuMaxWidth = '600px';

    ngOnInit() {
        this._idPrefix = "be_" + randomId();
        this.showMenu = true;
        if (this.skipDropdown) {
            this.dropdownToggle = '';
        }
        if (this.options && this.bulkEditCustomButtons.length) {
            this.filterButtonsByFieldNames();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.bulkEditCustomButtons && !changes.bulkEditCustomButtons.firstChange) {
            this.filterButtonsByFieldNames();
        }
    }

    ngAfterViewInit() {
        this.registerEventListeners();
    }

    private filterButtonsByFieldNames() {
        this.bulkEditCustomButtons = this.bulkEditCustomButtons.filter((button: IButtonBulkEdit) => this.options.fields.find((field: BulkEditField) => {
            return field.modelPath === this.field && field.modelPath === button.fieldName;
        }));
    }

    registerEventListeners() {
        let dropdownMenu: JQuery<HTMLElement>;
        setTimeout(() => {
            // make sure extra menu is shown every time the dropdown is opened
            $('#' + this._idPrefix).on('show.bs.dropdown', (event: JQuery.TriggeredEvent) => {
                this.showMenu = true;
            });

            const onMouseUpDocumentEvent = (event: MouseEvent) => {
                const eventTimePickerClose = new CustomEvent('climb.timepicker.close', {
                    detail: {
                      target: event.target,
                    },
                });

                document.dispatchEvent(eventTimePickerClose);
            };

            const onClickDocumentEvent = () => {
                dropdownMenu.hide();
                document.removeEventListener('click', onClickDocumentEvent);
            }

            $('#' + this._idPrefix).on('show.bs.dropdown', () => {
                document.addEventListener('mouseup', onMouseUpDocumentEvent);
            });

            $('#' + this._idPrefix).on('shown.bs.dropdown', () => {
                document.addEventListener('click', onClickDocumentEvent);
            });

            $('#' + this._idPrefix).on('hide.bs.dropdown', (event: JQuery.TriggeredEvent) => {
                const target = event.target as HTMLElement;
                const timePickerOpened = target.querySelector('.time-picker-opened');
                if (timePickerOpened) {
                    event.preventDefault();
                } else {
                    document.removeEventListener('mouseup', onMouseUpDocumentEvent);
                }
            });

            // and when we show it, move it to the body
            $(`#${this._idPrefix}`).on('show.bs.dropdown', (event: JQuery.TriggeredEvent<HTMLElement, HTMLElement, HTMLElement, HTMLElement>) => {
                // grab the menu
                dropdownMenu = $(event.target).find('.dropdown-menu');

                // detach it and append it to the body
                $('body').append(dropdownMenu.detach());

                // grab the new offset position
                const { top, left } = $(event.target).offset();

                // make sure to place it where it would normally go
                dropdownMenu.css({
                    display: 'block',
                    top: top + $(event.target).outerHeight(),
                    left,
                    zIndex: 1050,
                });
            });

            // and when we hide it, reattach the drop-down, and hide it normally
            $(`#${this._idPrefix}`).on('hide.bs.dropdown', (event: JQuery.TriggeredEvent<HTMLElement, HTMLElement, HTMLElement, HTMLElement>) => {
                $(event.target).append(dropdownMenu.detach());
                dropdownMenu.hide();
            });
        }, 500);
    }


    viewBulkEditModal(sendEventBack = true) {
        if (sendEventBack) {
            this.menuOpened.emit();
        }
        this.showMenu = false;
    }

    clickUpdate() {
        jQuery('#' + this._idPrefix).click();
        this.updateClicked.emit();
        this.showMenu = true;
    }
}

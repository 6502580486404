import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    ViewChildren
} from '@angular/core';

import { MatingService } from '../mating.service';
import { NgModel } from '@angular/forms';
import { dateControlValidator } from '@common/util/date-control.validator';

@Component({
    selector: 'mating-plug-date-table',
    templateUrl: './mating-plug-date-table.component.html'
})
export class MatingPlugDateTableComponent implements OnChanges, OnInit {
    @ViewChildren('dateControl') dateControls: NgModel[];
    @Input() mating: any;
    @Input() plugDates: any[];
    @Input() facetPrivilege: string;
    @Input() required: boolean;

    @Output() plugDatesChange: EventEmitter<any[]> = new EventEmitter<any[]>();


    constructor(
        private matingService: MatingService
    ) {
        //
    }

    ngOnInit() {
        this.initialize();
    }

    ngOnChanges(changes: any) {
        if (this.mating && !changes.mating.firstChange) {
            this.initialize();
        }
    }

    initialize() {
        //
    }


    onPlugDatesChange() {
        this.plugDatesChange.emit(this.plugDates);
    }

    addPlugDate() {
        this.matingService.createPlugDate(this.mating);
        this.onPlugDatesChange();
    }

    removePlugDate(plugDate: any) {
        this.matingService.deletePlugDate(plugDate);
        this.onPlugDatesChange();
    }

    validate() {
        return dateControlValidator(this.dateControls);
    }
}

import {
    Directive,
    Input,
    OnInit
} from '@angular/core';
import {
    NG_ASYNC_VALIDATORS,
    AbstractControl,
    Validator
} from '@angular/forms';

import {
    DataManagerService
} from '../../services/data-manager.service';

@Directive({
    selector:
    '[validateUnique][ngModel], [validateUnique][formControl], [validateUnique][formControlName]',
    providers: [
        {
            provide: NG_ASYNC_VALIDATORS,
            useExisting: UniqueValidatorDirective,
            multi: true
        }
    ]
})
export class UniqueValidatorDirective implements Validator {
    @Input() uniqueEntity: string;
    @Input() uniqueProperty: string;
    @Input() entityPK: number;
    @Input() entityPKName: string;
    // if true, the validator will run immediately
    @Input() validateInitialState: boolean;

    constructor(
        private dataManager: DataManagerService
    ) {}

    async validate(c: AbstractControl): Promise<{ [key: string]: any }> {
        let isEdited = c.dirty;
        if (this.validateInitialState) {
            isEdited = true;
        }
        if (c.value && isEdited && this.uniqueEntity && this.uniqueProperty && this.entityPK && this.entityPKName) {
            const isUnique = await this.dataManager.isPropertyValueUnique(
                this.uniqueEntity,
                this.uniqueProperty,
                c.value,
                this.entityPK,
                this.entityPKName
            );
            if (!isUnique) {
                return {
                    unique: {
                        valid: false
                    }
                };
            }
        }
    }
}

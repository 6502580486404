import {
    Component,
    Input,
    OnInit
} from '@angular/core';

import { contactSupportUri } from '../../config/climb-web-settings';

@Component({
    selector: 'seed-finish',
    templateUrl: './seed-finish.component.html',
    styles: [`
        .saving-message {
            padding: 0.5em;
            margin-bottom: 2em;
        }
    `]
})
export class SeedFinishComponent implements OnInit {
    @Input() workgroupName = '';
    @Input() hasError = false;
    @Input() savingData = false;

    supportUri: string;

    ngOnInit() {
        this.initialize();
    }

    initialize() {
        this.supportUri = contactSupportUri;
    }
}

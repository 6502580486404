import type { Genotype } from './genotype.interface';
import type { GenotypeAssayGenotypeSymbol } from './genotype-assay-genotype-symbol.interface';

export interface cv_GenotypeSymbol {
    C_GenotypeSymbol_key: number;
    C_VocabularySource_key: number | null;
    C_Workgroup_key: number;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    ElementID: string;
    Genotype: Genotype[];
    GenotypeAssayGenotypeSymbol: GenotypeAssayGenotypeSymbol[];
    GenotypeSymbol: string;
    IsActive: boolean;
    IsDefault: boolean;
    IsDeprecated: boolean;
    ModifiedBy: string;
    SortOrder: number | null;
    Version: number;
}

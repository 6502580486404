<loading-overlay *ngIf="saving" [message]="savingMessage"></loading-overlay>

<div class="bulk-add-heading-container">
    <div class="bulk-add-toolbar-wrap">
        <h3 class="my-0">Bulk Add</h3>
        <div class="detail-toolbar">
            <ng-content select="[extra-toolbar]"></ng-content>
            <div class="btn-group" role="group">
                <button data-automation-id="save-dropdown"
                        type="button"
                        class="btn btn-secondary dropdown-toggle"
                        [disabled]="loading || !(addState?.numberItemsToAdd > 0)"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false">
                    <i class="fa fa-check" aria-hidden="true" title="Save"></i>&nbsp;Save
                </button>
                <div class="dropdown-menu dropdown-menu-right">
                    <ng-container *ngFor="let item of exitItems">
                        <button
                            *ngIf="item.exist()"
                            [ngbTooltip]="item.tooltip()"
                            container="body"
                            placement="top"
                            type="button"
                            [attr.data-automation-id]="item.dateAutomationId"
                            (click)="item.click()"
                            class="dropdown-item"
                            [disabled]="!item.disabled()"
                            [appInsightsClick]="item.appInsights()"
                        >
                            {{ item.label }}
                        </button>
                    </ng-container>
                </div>
            </div>
            <button data-automation-id="list-view-button"
                    type="button"
                    class="btn btn-secondary"
                    [disabled]="loading"
                    (click)="exitClicked()">
                <i class="fa fa-table" aria-hidden="true" title="List View"></i>
                List <span class="gridster-d-sm-none gridster-d-md-inline-block">View</span>
                <span class="sr-only">List View</span>
            </button>
        </div>
    </div>
</div>

<form #bulkAddForm="ngForm" class="bulk-add-form">
    <div class="bulk-add-section-wrap" *ngIf="addState">
        <ng-container *ngIf="!options?.itemsToAddTemplate">
            <div class="form-group row">
                <label class="col-form-label text-nowrap col-md-6">
                    Number of New {{options?.itemTypeLabelPlural}} to Add
                    <input [attr.data-automation-id]="options?.itemTypeLabelPlural | dataAutomationId: 'number-of-new-': '-to-add-input'"
                           class="number-items-to-add"
                           type="number"
                           name="numberItemsToAdd"
                           min=1
                           [attr.max]="options?.maxNumberItemsToAdd || DEFAULT_MAX_ITEMS"
                           [(ngModel)]="addState.numberItemsToAdd"
                           (ngModelChange)="numberItemsToAddBlurred($event)"/>
                </label>
                <small class="form-text text-muted offset-md-7" style="padding-right: 10px;" *ngIf="addState.numberItemsToAdd > 200">
                    When creating more than 200 animals, editing will be disabled
                </small>
            </div>
        </ng-container>
        <ng-container *ngIf="options?.itemsToAddTemplate">
            <ng-template [ngTemplateOutlet]="options.itemsToAddTemplate"
                         [ngTemplateOutletContext]="{
                            itemTypeLabel: options?.itemTypeLabelPlural,
                            state: addState
                        }"></ng-template>
        </ng-container>
    </div>

    <div class="bulk-add-section-wrap">
        <ng-container *ngFor="let field of options?.fields">
            <div class="form-group row" *ngIf="field.hideFromAddScreen !== true && !field.inactive">
                <label class="col-md-3 col-form-label">{{field.label}}</label>
                <div class="col-md-9">
                    <ng-container *ngIf="field.template">
                        <ng-template [ngTemplateOutlet]="field.template"
                                     [ngTemplateOutletContext]="{
                            item: options.__addObject,
                            field: field,
                            section: BulkEditSection.AddScreen
                        }"></ng-template>
                    </ng-container>
                </div>
            </div>
        </ng-container>

        <ng-content select="[extra-after]"></ng-content>
    </div>
</form>




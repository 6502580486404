import {
    Component,
    EventEmitter,
    Input,
    Output
} from '@angular/core';

import { SearchService } from '../search/search.service';
import { lowerCaseCompare } from '../common/util';

@Component({
    selector: 'animal-multiselect',
    template: `
<!-- Selected animals -->
<div *ngIf="model && model.length > 0">
    <table class="table table-sm table-borderless table-nonfluid">
        <tbody>
            <tr *ngFor="let item of model; let i = index;">
                <td class="icon-cell">
                    <a (click)="removeAnimal(i)" title="Remove animal">
                        <i class="fa fa-remove remove-item"></i>
                    </a>
                </td>
                <td>
                    {{item.AnimalName}}
                </td>
            </tr>
        </tbody>
    </table>
</div>

<!-- Animal typeahead -->
<climb-indirect-typeahead
        [search]="searchAnimals"
        [resultFormatter]="animalNameFormatter"
        [exactMatchFunction]="isAnimalExactMatch"
        placeholder="Animal name..."
        (selectItem)="selectAnimal($event)">
    <ng-content select="[extra-buttons]"></ng-content>        
</climb-indirect-typeahead>
`,
    styles: [`
        .table {
            margin-bottom: 0.25em;
        }
        .y-scroll {
            overflow-y: scroll;
        }
    `]
})
export class AnimalMultiselectComponent {
    @Input() model: any[];
    @Output() modelChange: EventEmitter<any> = new EventEmitter<any>();

    constructor(
        private searchService: SearchService,
    ) { }

    removeAnimal(index: number) {
        if (this.model && index >= 0 && index < this.model.length) {
            this.model.splice(index, 1);
        }
        this.modelChange.emit(this.model);
    }

    searchAnimals = (term: string): Promise<any> => {
        return this.searchService.searchAnimals(term)
            .then((queryResults: any) => {
                return queryResults.data.map((animal: any) => {
                    // strip away all but the essential attributes from animal
                    // so that it can be easily serialized when facet are
                    // saved
                    return {
                        AnimalName: animal.AnimalName,
                        C_Material_key: animal.MaterialKey,
                        MicrochipIdentifier: animal.MicrochipIdentifier
                    };
                });
            });
    }

    /**
     * Alter exact match behavior to include matching on MicrochipIdentifer
     */
    isAnimalExactMatch = (data: any[], term: string) => {
        if (term &&
            data &&
            data.length === 1
        ) {
            return lowerCaseCompare(data[0].AnimalName, term) ||
                lowerCaseCompare(data[0].MicrochipIdentifier, term);
        }
        return false;
    }

    animalNameFormatter = (value: any) => {
        return value.AnimalName;
    }

    selectAnimal(animal: any) {
        if (this.model) {
            this.model.push(animal);
        } else {
            this.model = [animal];
        }
        this.modelChange.emit(this.model);
    }
}

import { CurrentWorkgroupService } from './current-workgroup.service';
import { AuthService } from './auth.service';
import { LogoutService } from './logout.service';
import { Injectable } from '@angular/core';
import { breeze } from './breeze';
import { 
    EntityManager,
    EntityState 
} from 'breeze-client';

import { EntityManagerFactoryService } from './entity-manager-factory.service';
import { BaseManagerService } from './base-manager.service';
import { LoggingService } from './logging.service';
import { TranslationService } from './translation.service';

/**
 * Entity Manager for the 'Admin' database
 */
@Injectable()
export class AdminManagerService extends BaseManagerService {

    // state variables
    private _managerPromise: Promise<void>;

    constructor(
        private authService: AuthService,
        private currentWorkgroupService: CurrentWorkgroupService,
        private entityManagerFactory: EntityManagerFactoryService,
        loggingService: LoggingService,
        logoutService: LogoutService,
        translationService: TranslationService,
    ) {
        super(loggingService, logoutService, translationService);
        this.entityManagerFactory = entityManagerFactory;
    }

    init() {
        if (!this._managerPromise) {
            this._managerPromise = this.entityManagerFactory.newAdminManager()
                .then((manager) => {
                    this._manager = manager;
                });
        }
        return this._managerPromise;
    }

    getManager(): EntityManager {
        if (!this._manager) {
            throw new Error("Breeze entity manager for 'Admin' not initialized.");
        }
        return this._manager;
    }

    saveEntity(entityName: string): Promise<any> {
        let promise: Promise<any> = Promise.resolve();
        const saveBatches: any[] = [];

        const manager = this._manager;
        this.assignRequiredSystemValues();

        saveBatches.push(manager.getEntities([entityName], [breeze.EntityState.Added]));
        saveBatches.push(manager.getEntities([entityName], [breeze.EntityState.Modified]));
        saveBatches.push(manager.getEntities([entityName], [breeze.EntityState.Deleted]));

        for (const batch of saveBatches) {
            if (batch.length > 0) {
                promise = promise.then(() => { 
                    return manager.saveChanges(batch); 
                });
            }
        }
        
        return promise.catch(this.queryFailed);

    }

    /**
     * DO NOT USE THIS METHOD DIRECTLY
     * 
     * This is called by DataContextService to save Breeze entities for Admin DB
     */
    save(): Promise<any> {

        let promise: Promise<any> = Promise.resolve();
        const saveBatches = this.prepareSaveBatches();

        for (const batch of saveBatches) {
            // ignore empty batches (except 'null' which means "save everything else")
            if (batch === null || batch.length > 0) {
                promise = promise.then(() => { 
                    return this._manager.saveChanges(batch); 
                });
            }
        }

        return promise.catch(this.queryFailed);
    }

    prepareSaveBatches(): any[] {
        this.assignRequiredSystemValues();

        const manager = this._manager;
        const batches: any[] = [];
        batches.push(manager.getEntities(['ClimbRoleFacet'], [breeze.EntityState.Deleted]));
        batches.push(manager.getEntities(['ClimbRole'], [breeze.EntityState.Deleted]));
        batches.push(manager.getEntities(['ClimbRole'], [breeze.EntityState.Added]));
        batches.push(manager.getEntities(['ClimbRoleFacet'], [breeze.EntityState.Added]));
        // empty = save all remaining pending changes
        batches.push(null);

        return batches;
    }

    assignRequiredSystemValues() {
        
        const currentUserName = this.authService.getCurrentUserName();
        const workgroupKey = this.currentWorkgroupService.getCurrentWorkgroupKey();
        const entities: any[] = this._manager.getEntities(
            null, [EntityState.Added, EntityState.Modified]
        );
        for (const entity of entities) {
            switch (entity.entityAspect.entityState) {
                case EntityState.Added:
                    // Breeze sets all default dates to 1900
                    const dateCreatedSet = entity.DateCreated && 
                                         (entity.DateCreated.getYear() > 1990);

                    entity.CreatedBy = currentUserName;
                    entity.DateCreated = dateCreatedSet ? entity.DateCreated : Date.now();
                    entity.C_Workgroup_key = workgroupKey;
                    entity.ModifiedBy = entity.CreatedBy;
                    entity.DateModified = entity.DateCreated;

                    break;
                case EntityState.Modified:
                    entity.ModifiedBy = currentUserName;
                    entity.DateModified = Date.now();

                    break;
                default:
                    break;
            }
        }
    }

}

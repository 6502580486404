<div class="detail-form-wrapper">
    <div class="detail-header">
        <h3>{{jobNameTranslated}} Characteristic</h3>

        <detail-toolbar [facetPrivilege]="facetPrivilege"
                        [logTag]="COMPONENT_LOG_TAG"
                        (exit)="onCancel()"
                        [emitOnSave]="true"
                        (genericSaveEmitter)="onSaveCharacteristic()">
        </detail-toolbar>
    </div>

    <form class="detail-form" style="height:30rem">
        <fieldset [disabled]="facetPrivilege === 'ReadOnly'">
            <div class="form-group row">
                <label class="col-md-3 col-form-label">Active</label>
                <div class="col-md-9">
                    <input type="checkbox"
                           name="isActive"
                           class="mt-1"
                           [(ngModel)]="jobCharacteristic.IsActive" />
                </div>
            </div>

            <div class="form-group row">
                <label class="col-md-3 col-form-label">Name</label>
                <div class="col-md-9">
                    <input type="text"
                           name="name"
                           [(ngModel)]="jobCharacteristic.CharacteristicName"
                           class="form-control input-medium"
                           required />
                </div>
            </div>

            <div class="form-group row">
                <label class="col-md-3 col-form-label">Description</label>
                <div class="col-md-9">
                    <input type="text"
                           name="description"
                           [(ngModel)]="jobCharacteristic.Description"
                           class="form-control input-medium" />
                </div>
            </div>

            <div class="form-group row"
                 *ngIf="isCRO">
                <label class="col-md-3 col-form-label">{{jobNameTranslated}} Characteristic Type</label>
                <div class="col-md-9">
                    <active-vocab-select [(model)]="jobCharacteristic.C_JobCharacteristicType_key"
                                         [vocabChoices]="jobCharacteristicTypes"
                                         [keyFormatter]="jobCharacteristicTypeKeyFormatter"
                                         [optionFormatter]="jobCharacteristicTypeFormatter"
                                         [nullable]="true"
                                         [required]="false"></active-vocab-select>
                </div>
            </div>

            <div class="form-group row">
                <label class="col-md-3 col-form-label">Link Type</label>
                <div class="col-md-9">
                    <active-vocab-select [(model)]="jobCharacteristic.C_JobCharacteristicLinkType_key"
                                         (modelChange)="onJobCharacteristicLinkTypeChange($event)"
                                         [vocabChoices]="jobCharacteristicLinkTypes"
                                         [keyFormatter]="jobCharacteristicLinkTypeKeyFormatter"
                                         [optionFormatter]="jobCharacteristicLinkTypeFormatter"
                                         [nullable]="false"
                                         [required]="true"></active-vocab-select>
                </div>
            </div>

            <div class="form-group row" *ngIf="jobCharacteristicLinkType == IACUC_PROTOCOL">
                <label class="col-md-3 col-form-label">IACUC Protocols</label>
                <div class="col-md-9">
                    <climb-multi-select *ngIf="iacucProtocols.length"
                                        [(model)]="selectedIACUCProtocols"
                                        (modelChange)="onIACUCProtocolChange($event)"
                                        [values]="iacucProtocols"
                                        [placeholder]="'Select IACUC Protocol'"
                                        [keyProperty]="'C_IACUCProtocol_key'"
                                        [valueProperty]="'IACUCProtocol'"
                                        [displayAllSelectedText]="false"
                                        [buttonClasses]="selectedIACUCProtocols.length > 0 ? '' : 'ng-invalid'">
                    </climb-multi-select>
                </div>
            </div>

            <div class="form-group row" *ngIf="jobCharacteristicLinkType == JOB_SUBTYPE">
                <label class="col-md-3 col-form-label">{{jobNameTranslated}} Subtypes</label>
                <div class="col-md-9">
                    <climb-multi-select *ngIf="jobSubtypes.length"
                                        [(model)]="selectedJobSubtypes"
                                        (modelChange)="onJobSubtypeChange($event)"
                                        [values]="jobSubtypes"
                                        [placeholder]="'Select ' + jobNameTranslated + ' Subtype'"
                                        [keyProperty]="'C_JobSubtype_key'"
                                        [valueProperty]="'JobSubtype'"
                                        [displayAllSelectedText]="false"
                                        [buttonClasses]="selectedJobSubtypes.length > 0 ? '' : 'ng-invalid'">
                    </climb-multi-select>
                </div>
            </div>

            <div class="form-group row" *ngIf="jobCharacteristicLinkType == JOB_TYPE">
                <label class="col-md-3 col-form-label">{{jobNameTranslated}} Types</label>
                <div class="col-md-9">
                    <climb-multi-select *ngIf="jobTypes.length"
                                        [(model)]="selectedJobTypes"
                                        (modelChange)="onJobTypeChange($event)"
                                        [values]="jobTypes"
                                        [placeholder]="'Select ' + jobNameTranslated + ' Type'"
                                        [keyProperty]="'C_JobType_key'"
                                        [valueProperty]="'JobType'"
                                        [displayAllSelectedText]="false"
                                        [buttonClasses]="selectedJobTypes ? '' : 'ng-invalid'">
                    </climb-multi-select>
                </div>
            </div>

            <characteristic-data-type [characteristic]="jobCharacteristic"
                                      [dataTypes]="dataTypes"
                                      [characteristicType]="'job'"
                                      (dataTypeChanged)="updateDefaultShown()"
                                      (vocabularyChanged)="setVocabularyItems()"
                                      (enumerationChanged)="setEnumerationItems()">

            </characteristic-data-type>

            <div *ngIf="isDefaultShown" class="form-group row">
                <label class="col-md-3 col-form-label">Default Value</label>
                <div class="col-md-9">
                    <ng-container [ngSwitch]="jobCharacteristic.cv_DataType?.DataType">
                        <input *ngSwitchCase="'Number'"
                               type="number"
                               name="numericScaleDefault"
                               [(ngModel)]="jobCharacteristic.DefaultValue"
                               step="{{numberStep}}"
                               min="{{jobCharacteristic.ValidationMinimum}}"
                               max="{{jobCharacteristic.ValidationMaximum}}"
                               class="form-control input-medium" />

                        <select *ngSwitchCase="'Enumeration'"
                                class="form-control"
                                name="enumerationClassDefault"
                                [(ngModel)]="jobCharacteristic.DefaultValue">
                            <option></option>
                            <ng-container *ngIf="enumerationItems">
                                <option *ngFor="let item of enumerationItems"
                                        [value]="item.ItemName">
                                    {{item.ItemName}}
                                </option>
                            </ng-container>
                        </select>

                        <select *ngSwitchCase="'Vocabulary'"
                                class="form-control"
                                name="vocabularyClassDefault"
                                [(ngModel)]="jobCharacteristic.DefaultValue">
                            <option></option>
                            <option *ngFor="let item of vocabularyItems"
                                    [value]="item.ItemName">
                                {{item.ItemName}}
                            </option>
                        </select>

                        <climb-typeahead *ngSwitchCase="'Long Enumeration'"
                                         [(model)]="jobCharacteristic.DefaultValue"
                                         [resultFormatter]="enumFormatter"
                                         [keyFormatter]="enumFormatter"
                                         [search]="searchEnumerations"
                                         [keySearch]="searchEnumerationsForExactMatch"
                                         [namespace]="jobCharacteristic.C_EnumerationClass_key + ''">
                        </climb-typeahead>

                        <textarea *ngSwitchCase="'Long Text'"
                                  name="textLineCountDefault"
                                  rows="{{jobCharacteristic.TextLineCount}}"
                                  [(ngModel)]="jobCharacteristic.DefaultValue"
                                  class="form-control input-large"></textarea>

                        <input *ngSwitchCase="'Text'"
                               type="text"
                               name="textDefault"
                               [(ngModel)]="jobCharacteristic.DefaultValue"
                               class="form-control" />

                        <climb-ngb-date *ngSwitchCase="'Date'"
                                        [(ngModel)]="defaultValue"
                                        (ngModelChange)="formatValue()"
                                        [ngModelOptions]="{ standalone: true }">
                        </climb-ngb-date>

                        <input *ngSwitchCase="'Boolean'"
                               type="checkbox"
                               name="booleanDefault"
                               [(ngModel)]="defaultValue"
                               (ngModelChange)="formatValue()" />

                        <climb-ngb-date *ngSwitchCase="'DateTime'"
                                        [allowTime]="true"
                                        [(ngModel)]="defaultValue"
                                        (ngModelChange)="formatValue()"
                                        [ngModelOptions]="{ standalone: true }">
                        </climb-ngb-date>
                    </ng-container>
                </div>
            </div>

            <div class="form-group row">
                <label class="col-md-3 col-form-label">Last Reviewed</label>
                <div class="col-md-9">
                    <climb-ngb-date [(ngModel)]="jobCharacteristic.DateLastReviewed"
                                    [ngModelOptions]="{ standalone: true }">
                    </climb-ngb-date>
                </div>
            </div>

            <div class="form-group row"
                 *ngIf="isCRL">
                <label class="col-md-3 col-form-label">Include in Client Report</label>
                <div class="col-md-9">
                    <input type="checkbox"
                           name="inClientReport"
                           [(ngModel)]="jobCharacteristic.InClientReport" />
                </div>
            </div>

        </fieldset>
    </form>
</div>

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslationService } from '../services/translation.service';
import { ImportFileDefinitionService } from './import-file-definition.service';
import { ImportMappingTargetService } from './import-mapping-target.service';

@Component({
    selector: 'import-file-definition-edit',
    templateUrl: './import-file-definition-edit.component.html',
    styles: [`
        .component-table-wrapper {
            margin-top: 1em;
        }
        .datatype-cell {
            font-style: italic;
        }
    `]
})
export class ImportFileDefinitionEditComponent implements OnInit {
    @Input() selectedDefinitionKey: number;
    // Mode can either be 'view', 'edit', or 'create'
    @Input() mode: string;

    @Output() created: EventEmitter<any> = new EventEmitter<any>();
    @Output() close: EventEmitter<void> = new EventEmitter<void>();

    fileDefinition: any;
    modalTitle: string;

    importMappingTargets: any[];
    componentList: any[] = [];
    characteristicTargets: any[] = [];
    constructor(
        private importFileDefinitionService: ImportFileDefinitionService,
        private importMappingTargetService: ImportMappingTargetService,
        private translationService: TranslationService
    ) { }

    ngOnInit() {
        switch (this.mode) {
            case 'view':
                this.modalTitle = 'View Import File Definition';
                break;
            case 'create':
                this.modalTitle = 'Create Import File Definition';
                break;
            case 'edit':
                this.modalTitle = 'Edit Import File Definition';
                break;
        }

        // Pull data if not in create mode
        if (this.mode === 'view' || this.mode === 'edit') {
            this.importFileDefinitionService
                .getImportFileDefinition(this.selectedDefinitionKey)
                .then((fileDefinition: any) => {
                    this.fileDefinition = fileDefinition;
                    // Retrieve import mapping target information
                    this.loadImportMappingTargets();
                });
        } else {
            this.fileDefinition = this.importFileDefinitionService
                .createImportFileDefinition(null);
        }
    }

    loadImportMappingTargets() {
        this.importMappingTargetService.getImportMappingTargets(
            this.fileDefinition.cv_ImportFileType.ImportFileType, this.fileDefinition.C_ImportFileDefinition_key)
            .then((targets) => {
                this.importMappingTargets = targets;       
                const components = (this.fileDefinition.ImportFileComponent as any[]).sort((a, b) => a.SortOrder - b.SortOrder);
                for (const component of components) {
                    // Find the component if it already exists in the collection
                    // Components may be mapped to multiple targets
                    let listItem = this.componentList.find(
                        (x) => x.ComponentName === component.ComponentName
                    );
                    if (listItem == null) {
                        const target = this.importMappingTargets.find(
                            (item: any) => {
                                return item.TargetName === component.TargetName;
                            });
                        // Show only Visible targets
                        if (target && target.Visible) {
                            let translated;
                            if (target.DisplayName && target.DisplayName.includes("Characteristic - ")) {
                                translated = target.DisplayName;
                                this.characteristicTargets.push({
                                    DisplayName: target.DisplayName,
                                    Description: target.Description
                                });
                                continue;
                            } else {
                                translated = component.ComponentName;
                                const entitytranslatable = ["Job", "Study", "Line"];
                                const entity = entitytranslatable.find((term: any) => {
                                    return component.ComponentName.includes(term);
                                });
                                if (entity) {
                                    translated = translated.replace(entity, this.translationService.translate(entity));
                                }
                            }
                            this.componentList.push({
                                ComponentName: component.ComponentName,
                                ComponentNameTranslated: translated,
                                Targets: []
                            });
                            listItem = this.componentList.find(
                                (x) => x.ComponentName === component.ComponentName
                            );
                        }                            
                    }
                    // Add the target to the component's target collection
                    if (listItem != null) {
                        listItem.Targets.push(
                            {
                                Target: component.Target = this.importMappingTargets.find(
                                    (target: any) => {
                                        return target.TargetName === component.TargetName;
                                    })
                            });
                    }                                 
                }
            });
    }

    closeClicked() {
        this.close.emit();
    }

    okClicked() {
        this.created.emit();
    }
}

import type { Job } from './job.interface';
import type { JobGroup } from './job-group.interface';
import type { Material } from './material.interface';
import type { Placeholder } from './placeholder.interface';
import type { SampleGroupSourceMaterial } from './sample-group-source-material.interface';
import type { TaskPlaceholder } from './task-placeholder.interface';

export interface AnimalPlaceholder {
    C_AnimalPlaceholder_key: number;
    C_JobGroup_key: number | null;
    C_Job_key: number;
    C_Material_key: number | null;
    C_Placeholder_key: number | null;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    Job: Job;
    JobGroup: JobGroup;
    Material: Material;
    ModifiedBy: string;
    Name: string;
    Placeholder: Placeholder;
    SampleGroupSourceMaterial: SampleGroupSourceMaterial[];
    TaskPlaceholder: TaskPlaceholder[];
}

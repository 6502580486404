import {
    Pipe,
    PipeTransform
} from '@angular/core';
import { datesEqual } from '@common/util';

import { LuxonInput } from '@common/util/date-time-formatting/types/luxon-input.type';

/**
 * Returns the age of an animal (in days)
 */
@Pipe({ name: 'datesEqual' })
export class DatesEqualPipe implements PipeTransform {

    transform(date: LuxonInput, exitDate: LuxonInput): boolean {
        if (!date && !exitDate) {
            return true;
        }
        try {
            return datesEqual(date, exitDate);
        } catch {
            return false;
        }
    }
}

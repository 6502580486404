import { uniqueId } from '@lodash';
import { Component, OnInit, ChangeDetectionStrategy, Input, EventEmitter } from '@angular/core';
import { chevronDown, chevronUp, IconContent } from '@icons';

@Component({
    selector: 'climb-dropdown',
    templateUrl: './dropdown.component.html',
    styleUrls: ['./dropdown.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownComponent implements OnInit {
    @Input() icon: IconContent = null;

    @Input() caption = '';

    @Input() disabled = false;

    readonly icons = { chevronDown, chevronUp };

    /** Unique popover panel id */
    panelId: string;
    /** Unique id of the button that controls popover */
    buttonId: string;

    popoverToggle: EventEmitter<boolean> = new EventEmitter();

    ngOnInit(): void {
        this.panelId = `dropdown-list-${uniqueId()}`;
        this.buttonId = `dropdown-button-${uniqueId()}`;
    }
}

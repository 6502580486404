import {
    TableOptions, CellFormatterService
} from '../common/datatable';
import { addAuditColumnsToOptions } from '@common/datatable/utils';


export class CensusTableOptions {

    options: TableOptions;
    detailViewOptions: TableOptions;

    constructor(private cellFormatterService: CellFormatterService) {
        this.options = addAuditColumnsToOptions(cellFormatterService)(this.getDefaultOptions());
        this.detailViewOptions = this.getDetailViewOptions();
    }

    getDetailViewOptions(): TableOptions {

        return {
            csvFileName: 'census-details.csv',
            enableDetailColumn: true,
            enableDraggable: false,
            enableSelectable: true,
            enableAddButton: false,
            enableAuditColumns: false,
            columns: [
                {
                    displayName: 'Housing ID', field: 'MaterialPool.MaterialPoolID',
                },
                {
                    displayName: 'Complete',
                    field: 'IsCompleted',
                    formatter: this.cellFormatterService.isCompleteCensusObservation,
                    exportFormatter: this.cellFormatterService.booleanExportFormatter
                },
                {
                    displayName: 'Discrepancy Count',
                    field: 'DiscrepancyCount',
                    formatter: this.cellFormatterService.sumCount,
                    exportFormatter: this.cellFormatterService.sumCountExportFormatter
                },
                {
                    displayName: 'Original Discrepancy Count',
                    field: 'OriginalDiscrepancyCount',
                    formatter: this.cellFormatterService.sumCount,
                    exportFormatter: this.cellFormatterService.sumCountExportFormatter
                },
                {
                    displayName: 'Created By', field: 'CreatedBy', formatter: this.cellFormatterService.userNameFormatter
                },
                {
                    displayName: 'Created Date', field: 'DateCreated', formatter: this.cellFormatterService.dateFormatter
                },
                {
                    displayName: 'Modified By', field: 'ModifiedBy', formatter: this.cellFormatterService.userNameFormatter
                },
                {
                    displayName: 'Modified Date', field: 'DateModified', formatter: this.cellFormatterService.dateFormatter
                },
            ]
        };
    }

    getDefaultOptions(): TableOptions {

        return {
            csvFileName: 'census.csv',
            enableDetailColumn: true,
            enableDraggable: false,
            enableSelectable: true,
            columns: [
                {
                    displayName: 'ID', field: 'C_Census_key',
                },
                {
                    displayName: 'Date',
                    field: 'Date',
                    formatter: this.cellFormatterService.dateUtcFormatter
                },
                {
                    displayName: 'Location', field: 'CurrentLocationPath',
                },
                {
                    displayName: 'Discrepancy Count',
                    field: 'SumDiscrepancyCount',
                    formatter: this.cellFormatterService.sumCount,
                    exportFormatter: this.cellFormatterService.sumCountExportFormatter
                },
                {
                    displayName: 'Original Discrepancy Count',
                    field: 'SumOriginalDiscrepancyCount',
                    formatter: this.cellFormatterService.sumCount,
                    exportFormatter: this.cellFormatterService.sumCountExportFormatter
                },
            ]
        };
    }
}

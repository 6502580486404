import { Pipe, PipeTransform } from '@angular/core';
import { DateFormatterService } from '../util/date-time-formatting/date-formatter.service';

/*
* Return short formatted date, given the date is already in UTC timezone
*/
@Pipe({ name: 'formatUtcDateTime' })
export class FormatUtcDateTimePipe implements PipeTransform {
    constructor(private dateFormatterService: DateFormatterService) {
        //
    }

    isGLP: boolean;
    timezone: any;

    transform(value: any): string {
        return this.dateFormatterService.formatDateOnlyUTC(value);
    }
}

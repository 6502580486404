import { 
    CellFormatterService,
    TableOptions 
} from '../common/datatable';

import { TranslationService } from '../services/translation.service';
import { addAuditColumnsToOptions } from '@common/datatable/utils';

export class PlateTableOptions {
    
    options: TableOptions;

    constructor(
        private cellFormatterService: CellFormatterService,
        private translationService: TranslationService
    ) {
        this.options = addAuditColumnsToOptions(cellFormatterService)(this.getDefaultOptions());
    }

    getDefaultOptions(): TableOptions {
        
        return {
            csvFileName: 'plates.csv',
            enableDetailColumn: true,
            enableDraggable: false,
            enableSelectable: true,
            columns: [
                {
                    displayName: 'ID',
                    field: 'PlateID'
                },
                {
                    displayName: 'External ID',
                    field: 'ExternalPlateID',
                    visible: false
                },
                {
                    displayName: 'Type',
                    field: 'cv_PlateType.PlateType'
                },
                {
                    displayName: this.translationService.translate('Job'),
                    field: 'Job.JobID'
                },
                {
                    displayName: 'Status',
                    field: 'cv_PlateStatus.PlateStatus',
                }
            ]
        };
    }
}

<ng-container *ngIf="materialPool">

    <ng-template [ngTemplateOutlet]="labelValuePair"
        [ngTemplateOutletContext]="{
            label: 'Housing ID',
            value: materialPool.MaterialPoolID }"></ng-template>

    <ng-template [ngTemplateOutlet]="labelValuePair"
        [ngTemplateOutletContext]="{
            label: 'Type',
            value: materialPool.cv_MaterialPoolType?.MaterialPoolType }"></ng-template>

    <ng-template [ngTemplateOutlet]="labelValuePair"
        [ngTemplateOutletContext]="{
            label: 'Location',
            value: materialPool.location }"></ng-template>

</ng-container>

<ng-template #labelValuePair let-label="label" let-value="value">
    <div class="form-group row" *ngIf="!useTableFormat">
        <label class="col-md-2 col-form-label">{{label}}</label>
        <div class="col-md-10">
            <p class="form-control-plaintext">
                {{value}}
            </p>
        </div>
    </div>

    <tr *ngIf="useTableFormat">
        <td>{{label}}</td>
        <td>{{value}}</td>
    </tr>
</ng-template>

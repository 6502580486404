import type { cv_BodySystem } from './cv-body-system.interface';
import type { cv_ClinicalObservation } from './cv-clinical-observation.interface';

export interface cv_ClinicalObservationBodySystem {
    C_BodySystem_key: number;
    C_ClinicalObservationBodySystem_key: number;
    C_ClinicalObservation_key: number;
    C_Workgroup_key: number;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    ModifiedBy: string;
    Version: number;
    cv_BodySystem: cv_BodySystem;
    cv_ClinicalObservation: cv_ClinicalObservation;
}

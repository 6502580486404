<table class="table">
    <thead>
        <tr>
            <th>Birth ID</th>
            <th>Dam</th>
            <th>Live Count</th>
            <th>Stillborn Count</th>
            <th>Birth Date</th>
            <th>Wean Date</th>
            <th>Comments</th>
            <th>Animals</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let birth of births">
            <td class="text-nowrap">
                {{birth.BirthID}}
            </td>
            <td class="text-nowrap">
                <ul class="list-unstyled">
                    <ng-container *ngFor="let materialPoolMaterial of mating?.MaterialPool?.MaterialPoolMaterial | housingSexFilter:'Female'">
                        <li *ngIf="isBirthMaterial(materialPoolMaterial.C_Material_key, birth.BirthMaterial)">
                            {{materialPoolMaterial.Material?.Animal?.AnimalName}}
                        </li>
                    </ng-container>
                </ul>
            </td>
            <td class="num-cell">
                {{birth.LiveCount}}
            </td>
            <td class="num-cell">
                {{birth.StillbornCount}}
            </td>
            <td>
                {{birth.DateBorn | formatShortDate}}
            </td>
            <td>
                {{birth.DateWean | formatShortDate}}
            </td>
            <td>
                {{birth.Comments}}
            </td>
            <td class="text-nowrap">
                <ul class="list-unstyled">
                    <ng-container *ngFor="let animal of birth.Animal">
                        <li *ngIf="animal.C_Birth_key === birth.C_Birth_key">
                            {{animal.AnimalName}}
                        </li>
                    </ng-container>
                </ul>
            </td>
        </tr>
    </tbody>
</table>

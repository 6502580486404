<button climbButton size="md" variant="ghost" [popoverTriggerFor]="info" popoverOpenWithKeyArrows>
    <svg [climbIcon]="icons.infoCircleFilled" size="sm"></svg>
</button>

<climb-popover #info>
    <div climbTrapFocus climbArrowKeyAsTab>
        <div class="popover-section">
            <button climb-menu-item disabled>Climb™</button>
            <button climb-menu-item (click)="goToVersionInfo()">Version {{buildId}}</button>
        </div>
        <div class="popover-section bottom-addition" *ngIf="!isGLP">
            <a climb-menu-item href="https://www.rockstepsolutions.com" (click)="info.close()" target="_blank" rel="noopener noreferrer">
                <svg [climbIcon]="icons.arrowUpRight" size="sm"></svg>
                RockStep Solutions
            </a>
            <a climb-menu-item href="https://www.youtube.com/channel/UCV-F7DrG2rBl5KS_3H0kXWg" (click)="info.close()" target="_blank" rel="noopener noreferrer">
                <svg [climbIcon]="icons.arrowUpRight" size="sm"></svg>
                YouTube channel
            </a>
        </div>
    </div>
</climb-popover>

import { Injectable } from '@angular/core';

import { TranslationService } from '@services/translation.service';
import { ExportType, ExporterFactory, PdfExporter, FileExporter } from '@common/export';
import { DateFormatterService } from '@common/util/date-time-formatting';
import { notEmpty, sortObjectArrayByProperty } from '@common/util';
import { AnimalClinicalObservation, AnimalDiagnosticObservation } from "@common/types";

/*
* Export a clinical detail record to CSV
*/
@Injectable()
export class ExportClinicalDetailService {
    exporter: FileExporter;

    constructor(
        private translationService: TranslationService,
        private dateFormatterService: DateFormatterService
    ) {
       //
    }

    /*
    * Assumes health record has all relationships loaded
    */
    export(healthRecord: any, exportType: ExportType, isGLP: boolean) {
        const filename = 'ClinicalRecord.csv';
        this.exporter = ExporterFactory.create(exportType, { isCustomContent: true });
        const forPdf = exportType === ExportType.PDF;
        const data: any[][] = this.buildExportData(healthRecord, forPdf, isGLP);
        this.exporter.download(data, filename);
    }

    buildExportData(healthRecord: any, forPdf: boolean, isGLP: boolean): any[][] {
        const data: any[][] = [];

        this.addAnimalData(data, healthRecord, forPdf, isGLP);
        this.addClinicalOrDiagnosticData(data, 'Clinical Observations',
            healthRecord, healthRecord.Animal.AnimalClinicalObservation, forPdf, isGLP);
        if (isGLP) {
            this.addClinicalOrDiagnosticData(data, 'Diagnostic Observations',
                healthRecord, healthRecord.Animal.AnimalDiagnosticObservation, forPdf, isGLP);
        }
        this.addTaskData(data, healthRecord, forPdf);

        return data;
    }

    makeColumns(label: string, value: any) {
        return {
            columns: [
                [
                    {
                        text: label,
                        bold: true
                    }
                ],
                [
                    {
                        text: value,
                    }
                ]
            ],
            columnGap: 0
        };
    }

    makeTable(data: any) {
        const pdfExporter = this.exporter as PdfExporter;
        return pdfExporter.defaultTable(data);
    }

    addAnimalData(data: any[], healthRecord: any, forPdf = false, isGLP ?: boolean) {
        let dataArr: any = [];

        dataArr.push([
            'Urgent',
            healthRecord.IsUrgent
        ]);

        dataArr.push([
            'Animal ID',
            healthRecord.Animal.Material.Identifier
        ]);

        dataArr.push([
            'Animal Name',
            healthRecord.Animal.AnimalName
        ]);

        dataArr.push([
            this.translationService.translate('Line'),
            healthRecord.Animal.Material.Line ? healthRecord.Animal.Material.Line.LineName : ''
        ]);

        dataArr.push([
            'Location',
            healthRecord.Animal.Material.CurrentLocationPath
        ]);

        dataArr.push([
            this.translationService.translate('Job') + ' Name' + (isGLP ? '' : 's'),
            `"${this.healthRecordJobNameFormat(healthRecord, isGLP)}"`
        ]);

        dataArr.push([
            'Birth Date',
            this.dateFormatterService.formatDateOnly(healthRecord.Animal.DateBorn)
        ]);

        dataArr.push([
            'Sex',
            healthRecord.Animal.cv_Sex ? healthRecord.Animal.cv_Sex.Sex : ''
        ]);

        dataArr.push([
            'Species',
            healthRecord.Animal.Material.cv_Taxon ? healthRecord.Animal.Material.cv_Taxon.CommonName : ''
        ]);

        dataArr.push([
            'Status',
            healthRecord.Animal.cv_AnimalStatus ? healthRecord.Animal.cv_AnimalStatus.AnimalStatus : ''
        ]);

        dataArr.push([
            'Body Condition Score',
            healthRecord.Animal.cv_BodyConditionScore ? healthRecord.Animal.cv_BodyConditionScore.BodyConditionScore : ''
        ]);

        dataArr.push([
            'Exit Date',
            this.dateFormatterService.formatDateOnly(healthRecord.Animal.DateExit)
        ]);

        dataArr.push([
            'Death/Exit Reason',
            healthRecord.Animal.cv_ExitReason ? healthRecord.Animal.cv_ExitReason.ExitReason : ''
        ]);

        dataArr.push([
            'Microchip ID',
            healthRecord.Animal?.Material?.MicrochipIdentifier || ''
        ]);

        dataArr.push([
            'Health Tech',
            healthRecord.Resource ? healthRecord.Resource.ResourceName : ''
        ]);

        dataArr.push([
            'Due Date',
            this.dateFormatterService.formatDateOnly(healthRecord.DerivedDateDue)
        ]);

        dataArr.push([
            'Created Date',
            this.dateFormatterService.formatDateOnly(healthRecord.DateCreated)
        ]);

        dataArr.push(['']);

        if (forPdf) {
            dataArr = dataArr.map(([label, value]: any) => this.makeColumns(label, value));
        }

        data.push(...dataArr);
    }

    addClinicalOrDiagnosticData(
        data: any[], 
        tableTitle: string, 
        healthRecord: any, 
        items: AnimalClinicalObservation[]|AnimalDiagnosticObservation[], 
        forPdf = false, 
        isGLP = false) {
        if (items && items.length > 0) {
            let tableColumns;
            if (isGLP) {
                tableColumns = [this.translationService.translate('Job') + ' Name', 'Date', 'Observation Status',
                    'Last Confirmed Date and Time', 'Observations', 'Comments', 'Observed By'];
            } else {
                tableColumns = [this.translationService.translate('Job') + ' Names', 'Date', 'Observation Status',
                    'Observations', 'Comments', 'Observed By'];
            }
            let tableRowsPdf: any = [];

            if (forPdf) {
                data.push({
                    text: tableTitle,
                    style: 'sectionHeader'
                });
            } else {
                data.push([tableTitle]);
            }

            for (const observation of sortObjectArrayByProperty(items, 'DateObserved', true)) {
                tableRowsPdf = [];
                const observedBy = isGLP
                    ? observation.ObservedByUsername ?? ''
                    : observation.Resource?.ResourceName ?? '';

                let observationText = '';
                if (isGLP) {
                    const clinicalObservationVocab = observation.cv_ClinicalObservation;
                    if (clinicalObservationVocab) {
                        const clinicalObservation = clinicalObservationVocab.ClinicalObservation;
                        const bodySystem = observation.cv_BodySystem ? observation.cv_BodySystem.BodySystem : '';
                        const modifier1 = observation.cv_Modifier1 ? observation.cv_Modifier1.Modifier1 : null;
                        const modifier2 = observation.cv_Modifier2 ? observation.cv_Modifier2.Modifier2 : null;
                        const modifier3 = observation.cv_Modifier3 ? observation.cv_Modifier3.Modifier3 : null;
                        const modifier4 = observation.cv_Modifier4 ? observation.cv_Modifier4.Modifier4 : null;

                        const modifiers = [modifier1, modifier2, modifier3, modifier4].filter((m: any) => m);
                        observationText = `"${bodySystem}: ${clinicalObservation}, ${modifiers.join(', ')}"`;
                    }
                } else {
                    if (observation.ClinicalObservationDetail) {
                        const values = observation.ClinicalObservationDetail
                            .map((item: any) => {
                                let value = '';
                                if (item.cv_ClinicalObservation) {
                                    value = item.cv_ClinicalObservation.ClinicalObservation;
                                }
                                return value;
                            });
                        observationText = `"${values.join(', ')}"`;
                    }
                }

                if (!forPdf) {
                    data.push(tableColumns);
                }

                if (isGLP) {
                    (forPdf ? tableRowsPdf : data).push([
                        `"${this.clinicalJobNameFormat(healthRecord, 'observations', observation.JobName, 0)}"`,
                        this.dateFormatterService.formatDateOnly(observation.DateObserved),
                        observation.cv_ClinicalObservationStatus ? observation.cv_ClinicalObservationStatus.ClinicalObservationStatus : '',
                        this.dateFormatterService.formatDateOrTime(observation.ReviewDate),
                        observationText,
                        observation.Comments,
                        observedBy
                    ]);
                } else {
                    (forPdf ? tableRowsPdf : data).push([
                        `"${this.clinicalJobNameFormat(healthRecord, 'observations', observation.JobName, 0)}"`,
                        this.dateFormatterService.formatDateOnly(observation.DateObserved),
                        observation.cv_ClinicalObservationStatus ? observation.cv_ClinicalObservationStatus.ClinicalObservationStatus : '',
                        observationText,
                        observation.Comments,
                        observedBy
                    ]);
                }
                this.FormatResult(forPdf, data, tableColumns, tableRowsPdf);

                this.addHistoryData(data, observation, forPdf);
            }
        }

        data.push(['']);
    }

    addHistoryData(data: any[], observation: any, forPdf = false) {
        if (observation.Event && observation.Event.length > 0) {
            const tableColumns = ['Observation History', 'Event', 'Date', 'By'];
            const tableRowsPdf: any = [];

            if (!forPdf) {
                data.push(tableColumns);
            }
            for (const event of sortObjectArrayByProperty(observation.Event, 'DateOccurred', true)) {
                if (forPdf) {
                    tableRowsPdf.push([
                        '',
                        event.cv_EventType.Label + (event.cv_EventType.Label.includes('Reviewed') ? this.dateFormatterService.formatDateOrTime(event.DateReviewed) : event.Label),
                        this.dateFormatterService.formatDateOrTime(event.DateOccurred),
                        event.CreatedBy
                    ]);
                } else {
                    data.push([
                        '',
                        event.cv_EventType.Label + (event.cv_EventType.Label.includes('Reviewed') ? this.dateFormatterService.formatDateOrTime(event.DateReviewed) : event.Label),
                        this.dateFormatterService.formatDateOrTime(event.DateOccurred),
                        event.CreatedBy
                    ]);
                }
            }
            this.FormatResult(forPdf, data, tableColumns, tableRowsPdf);
        }
    }

    addTaskData(data: any[], healthRecord: any, forPdf = false) {
        if (healthRecord.TaskAnimalHealthRecord && healthRecord.TaskAnimalHealthRecord.length > 0) {
            const tableTitle = 'Treatment Plans';
            const tableColumns = [this.translationService.translate('Job') + ' Name', 'Plan', 'Due Date', 'Assigned To', 'Comments', 'Completed Date', 'Completed By', 'Status'];
            const tableRowsPdf: any = [];

            if (forPdf) {
                data.push({
                    text: tableTitle,
                    style: 'sectionHeader'
                });
            } else {
                data.push([tableTitle]);
                data.push(tableColumns);
            }

            let i = 0;
            for (const taskHealthRecord of healthRecord.TaskAnimalHealthRecord) {
                const task = taskHealthRecord.TaskInstance;

                (forPdf ? tableRowsPdf : data).push([
                    this.clinicalJobNameFormat(healthRecord, 'treatment', '', i),
                    notEmpty(task.TaskInput) ? task.TaskInput[0].InputValue : '',
                    this.dateFormatterService.formatDateOrTime(task.DateDue),
                    task.AssignedToResource ? task.AssignedToResource.ResourceName : '',
                    task.Comments,
                    this.dateFormatterService.formatDateOrTime(task.DateComplete),
                    task.CompletedByResource ? task.CompletedByResource.ResourceName : '',
                    task.cv_TaskStatus ? task.cv_TaskStatus.TaskStatus : '',
                ]);
                i++;
            }
            data.push(['']);

            this.FormatResult(forPdf, data, tableColumns, tableRowsPdf);
        }
    }

    private FormatResult(forPdf: boolean, data: any[], tableColumns: string[], tableRowsPdf: any) {
        if (forPdf) {
            data.push(this.makeTable([
                tableColumns,
                ...tableRowsPdf
            ]));
        }
    }

    private healthRecordJobNameFormat(healthRecord: any, isGLP: boolean): string {
        let jobMaterials = healthRecord.Animal.Material.JobMaterial;
        let jobNames = '';
        if (jobMaterials) {
            if (isGLP) {
                jobMaterials = jobMaterials.filter((jm: any) => !jm.DateOut);
            } else {
                jobMaterials = jobMaterials.sort((a: any, b: any) => b.DateCreated - a.DateCreated);
            }
            jobNames = jobMaterials.map((jm: any) => jm.Job.JobID).join(";");
        }
        return jobNames;
    }

    private clinicalJobNameFormat(healthRecord: any, tableType: string, jobNames: string, i: number): string {
        let result = "";
        if (tableType.toLowerCase() === 'treatment') {
            const taskAnimalHealthRecords = healthRecord.TaskAnimalHealthRecord;
            if (taskAnimalHealthRecords) {
                const taskAnimalHealthRecordsLength = taskAnimalHealthRecords.length;
                const index = (taskAnimalHealthRecordsLength - i) - 1;
                const taskAnimalHealthRecord = taskAnimalHealthRecords[index];

                if (taskAnimalHealthRecord.JobName) {
                    const jobNamesFromRecord = taskAnimalHealthRecord.JobName;
                    const jobNamesArr = jobNamesFromRecord.split(",");
                    if (jobNamesArr.length > 3) {
                        result = jobNamesArr.slice(0, 3).join(", ") + " and " + (jobNamesArr.length - 3) + " more";
                    } else {
                        result = jobNamesArr.join(", ");
                    }
                }
            }
        } else if (tableType.toLowerCase() === 'observations') {
            if (jobNames) {
                const jobNamesArr = jobNames.split(",");
                if (jobNamesArr.length > 3) {
                    result = jobNamesArr.slice(0, 3).join(", ") + " and " + (jobNamesArr.length - 3) + " more";
                } else {
                    result = jobNamesArr.join(", ");
                }
            }
        }
        return result;
    }
}

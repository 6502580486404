<table class="table table-bordered">
    <thead>
        <tr class="detail-grid-header">
            <th [attr.colspan]= 9>
                <a class="add-item-link detail-grid-button-label"
                   (click)="addClicked()"
                   *ngIf="!readonly && sampleMaterials.length > 0">
                    <i class="fa fa-plus-circle" title="Add outputs for selected samples"></i>
                </a>
                <span class="detail-grid-button-label">
                    Samples
                    <span *ngIf="sampleMaterials.length > 0">
                        ({{sampleMaterials.length}})
                        <a (click)="task.samplesExpanded = !task.samplesExpanded" class="toggle-samples-link">
                            <i class="fa"
                               [ngClass]="task.samplesExpanded ? 'fa-chevron-up' : 'fa-chevron-down'"
                               [attr.title]="task.samplesExpanded ? 'Hide Samples' : 'Show Samples'"></i>
                        </a>
                    </span>
                </span>
            </th>
        </tr>
        <tr *ngIf="sampleMaterials.length > 0 && task.samplesExpanded"
            class="detail-grid-header">
            <th style="width: 40px;" 
                class="text-center">
                <input *ngIf="!readonly" type="checkbox" (click)="selectAllSamples($event)" />
            </th>
            <th>ID</th>
            <th>Name</th>
            <th>Sources</th>
            <th>Type</th>
            <th>Time Point</th>
            <th>Status</th>
            <th>{{'Line' | translate}}</th>
            <th>Location</th>
        </tr>
    </thead>
    <tbody *ngIf="task.samplesExpanded">
        <tr *ngFor="let sampleMaterial of sampleMaterials">
            <td class="text-center"
                style="width:40px;">
                <div *ngIf="(!readonly && (primarySamples.indexOf(sampleMaterial.Material.Sample) > -1)); else locked_sample">
                    <input type="checkbox" [(ngModel)]="sampleMaterial.isSelected" />
                </div>
                <ng-template #locked_sample>
                    <i class="fa fa-fw fa-lock" title="Output samples cannot be selected"></i>
                </ng-template>
            </td>
            <td class="text-nowrap">
                <div *ngIf="(primarySamples.indexOf(sampleMaterial.Material.Sample) > -1); else not_draggable_sample"
                     class="draggable" draggable>
                    {{sampleMaterial?.Material?.Identifier}}
                </div>
                <ng-template #not_draggable_sample>
                    {{sampleMaterial?.Material?.Identifier}}
                </ng-template>
            </td>
            <td class="text-nowrap">
                {{sampleMaterial?.Material?.Sample?.SampleName}}
            </td>
            <td>
                <span *ngFor="let source of sampleMaterial.Material.MaterialSourceMaterial; let last = last;">
                    <span class="text-nowrap">{{source?.SourceMaterial?.Animal?.AnimalName}}{{source?.SourceMaterial?.Sample?.SampleName}}
                        <span *ngIf="!last">, </span></span>
                </span>
            </td>
            <td>
                {{sampleMaterial?.Material?.Sample?.cv_SampleType?.SampleType}}
            </td>
            <td>
                {{sampleMaterial?.Material?.Sample?.TimePoint |
                    formatTimePoint : sampleMaterial?.Material?.Sample?.cv_TimeUnit}}
            </td>
            <td>
                {{sampleMaterial?.Material?.Sample?.cv_SampleStatus?.SampleStatus}}
            </td>
            <td>
                {{sampleMaterial?.Material?.Line?.LineName}}
            </td>
            <td>
                {{sampleMaterial?.Material?.CurrentLocationPath}}
            </td>
        </tr>
    </tbody>
</table>

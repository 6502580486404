import { VocabularyService } from './../vocabularies/vocabulary.service';
import {
    Component,
    EventEmitter,
    Input,
    Output,
    OnInit
} from '@angular/core';
import { LocationService } from './location.service';
import { FeatureFlagService } from '../services/feature-flags.service';

@Component({
    selector: 'location-detail',
    templateUrl: './location-detail.component.html'
})
export class LocationDetailComponent implements OnInit {
    @Input() position: any;
    @Input() facetPrivilege: string;

    @Output() exitClicked: EventEmitter<void> = new EventEmitter<void>();

    isGLP = false;

    locationTypes: string[] = [];
    materialTypes: string[] = [];

    originalParentPosition: any;
    noEdit = true;
    noEditMessage = 'Locations with contents cannot be edited';

    constructor(
        private vocabularyService: VocabularyService,
        private locationService: LocationService,
        private featureFlagService: FeatureFlagService,
    ) {
        this.initIsGLP();
    }

    initIsGLP() {
        this.isGLP = this.featureFlagService.getIsGLP();
    }

    async ngOnInit() {        
        this.getCVs();
        if (this.position.C_LocationPosition_key < 0) {
            this.noEdit = false;
        } else {
            const response = await this.locationService.checkLocationUse(this.position.C_LocationPosition_key);
            this.noEdit = response.data;
        }
        
        this.originalParentPosition = this.position?.C_ParentPosition_key;
    }

    async getCVs(): Promise<string[][]> {
        this.locationTypes = await this.vocabularyService.getCV('cv_LocationTypes', 'LocationType', true);
        this.materialTypes = await this.vocabularyService.getCV('cv_MaterialTypes', 'MaterialType', true);

        return Promise.all([this.locationTypes, this.materialTypes]);
    }

    cancelAdd() {
        this.originalParentPosition = null;
        this.exitClicked.emit();
    }

    // <select> formatters
    locationTypeKeyFormatter = (value: any) => {
        return value.C_LocationType_key;
    }
    locationTypeFormatter = (value: any) => {
        return value.LocationType;
    }
    materialTypeKeyFormatter = (value: any) => {
        return value.C_MaterialType_key;
    }
    materialTypeFormatter = (value: any) => {
        return value.MaterialType;
    }
}

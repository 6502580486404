import { Pipe, PipeTransform } from '@angular/core';

/*
* Return odd items in a list
*/
@Pipe({ name: 'odd' })
export class OddPipe implements PipeTransform {

    transform<T>(value: T[]): T[] {
        if (value) {
            return value.filter( (item: T, idx: number) => {
                return idx % 2 === 1;
            });
        }
        return [];
    }
}

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ClinicalDetailComponent } from './clinical-detail/clinical-detail.component';
import { ExportClinicalDetailService } from './export-clinical-detail.service';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ClimbCommonModule } from '../common/climb-common.module';
import { ClimbServicesModule } from '../services/climb-services.module';
import { ClinicalService } from './clinical.service';
import { TasksSharedModule } from '../tasks/tasks-shared.module';
import { ClinicalVocabService } from './clinical-vocab.service';
import { ClinicalConfirmButtonComponent } from './clinical-confirm-button/clinical-confirm-button.component';
import { ClinicalMultiselectComponent } from './clinical-multiselect/clinical-multiselect.component';
import { ObservationChooserComponent } from './observation-chooser/observation-chooser.component';
import { ResourcesSharedModule } from '../resources/resources-shared.module';
import { ObservationsTableComponent } from './clinical-detail/observations-table/observations-table.component';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgbModule,
        ClimbCommonModule,
        ClimbServicesModule,
        TasksSharedModule,
        ResourcesSharedModule,
    ],
    declarations: [
        ClinicalConfirmButtonComponent,
        ClinicalDetailComponent,
        ClinicalMultiselectComponent,
        ObservationChooserComponent,
        ObservationsTableComponent
    ],
    exports: [
        ClinicalConfirmButtonComponent,
        ClinicalDetailComponent,
        ClinicalMultiselectComponent,
        ObservationChooserComponent
    ]
})
export class ClinicalSharedModule { 
    static forRoot(): ModuleWithProviders<ClinicalSharedModule> {
        return {
          ngModule: ClinicalSharedModule,
          providers: [
            ClinicalService,
            ClinicalVocabService,
            ExportClinicalDetailService
          ]
        };
    }
}

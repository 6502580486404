const ELLIPSIS = '\u2026';

import {
    Component,
    EventEmitter,
    Input,
    Output
} from '@angular/core';

import { QueryDef } from '../services/query-def';
import { ConstructService } from './construct.service';

@Component({
    selector: 'construct-select',
    template: `
    <div class="construct-select">
        <climb-typeahead
            [(model)]="model"
            [resultFormatter]="constructNameFormatter"
            [keyFormatter]="constructKeyFormatter"
            [search]="searchConstructs"
            [keySearch]="searchConstructsByKey"  
            [id]="id"
            [disabled]="disabled"
            [placeholder]="placeholder"
            [required]="required"
            [namespace]="'construct'"
            (selectItem)="selectItemHandler($event)"
            #typeahead
        ></climb-typeahead>
    </div>
    `,
    styles: [`
        .construct-select {
            min-width: 100px;
        }
    `]
})
export class ConstructSelectComponent {
    @Input() model: any;
    @Input() disabled: boolean;
    @Input() id: string;
    @Input() placeholder: string = 'Construct name' + ELLIPSIS;
    @Input() required: boolean;

    @Output() modelChange: EventEmitter<any> = new EventEmitter<any>();


    constructor(
        private constructService: ConstructService
    ) {
        // Nothing to do
    }

    selectItemHandler(item: any) {
        this.modelChange.emit(this.model);
    }


    // Search
    searchConstructs = (search: string): Promise<any[]> => {
        const filter = {
            FullName: search
        };

        let count = null;
        if (search.length < 3) {
            count = 50;
        }

        return this._searchConstructs(filter, count);
    }

    searchConstructsByKey = (key: any): Promise<any[]> => {
        const filter = {
            C_Construct_key: key
        };

        return this._searchConstructs(filter, 1);
    }

    private _searchConstructs(filter: any, count: number): Promise<any[]> {
        const queryDef: QueryDef = {
            inlineCount: false,
            page: 1,
            size: count,
            sort: 'FullName',
            filter
        };

        return this.constructService.getConstructs(queryDef).then((response: any) => {
            return response.results;
        });
    }


    // Formatters
    constructNameFormatter = (value: any) => {
        return value.FullName;
    }

    constructKeyFormatter = (item: any) => {
        return item.C_Construct_key;
    }
}

<form #addWorkspaceForm="ngForm">
    <div class="modal-header justify-content-center">
        <h4 class="modal-title">Add Workspace</h4>
    </div>
    <div class="modal-body" style="vertical-align:top;">
        <div class="form-group">
            <label for="wsTemplate">Create From a Template</label>
            <select id="wsTemplate" name="wsTemplate"
                    class="form-control"
                    [(ngModel)]="selectedWsTemplate"
                    (ngModelChange)="onTemplateSelect()"
                    autofocus>
                <option value="">Blank Workspace</option>
                <option *ngFor="let item of wsTemplates"
                        [ngValue]="item">
                    {{item.WorkspaceName}}
                </option>
            </select>
        </div>
        <div class="form-group" *ngIf="selectedWsTemplate">
            <p>{{selectedWsTemplate.Description}}</p>
        </div>
        <div class="form-group">
            <label for="workspaceName">Workspace Name</label>
            <input data-automation-id="workspace-name-input"
                   id="workspaceName"
                   name="workspaceName"
                   type="text"
                   maxlength="35"
                   required
                   class="form-control"
                   [(ngModel)]="workspaceName"
                   #workspaceNameInput />
        </div>
    </div>
    <div class="modal-footer">
        <button data-automation-id="add-workspace-button"
                type="submit" 
                class="btn btn-fw btn-lg btn-primary"
                [disabled]="!addWorkspaceForm.form.valid || loading"
                (click)="okClicked($event)"
                [appInsightsClick]="'click-add-workspace'">
            <i class="fa fa-spinner fa-spin" title="Loading"
               *ngIf="loading"></i>
            <span *ngIf="!loading">Add</span>
        </button>
        <button type="button" class="btn btn-fw btn-lg btn-secondary"
                (click)="cancelClicked()">
            Cancel
        </button>
    </div>
</form>

<div class="search">
    <h2>Add facets</h2>
    <climb-input size="sm">
        <svg [climbIcon]="icons.magnifier"></svg>
        <input
            data-automation-id="filter-facets-input"
            #input
            type="text"
            climbInput
            placeholder="Filter facets"
            autocomplete="off"
            autocorrect="off"
            autocapitalize="off"
            spellcheck="false"
            [formControl]="termControl"
        />
    </climb-input>
</div>
<div class="facets">
    <div *ngIf="!loading && isEmpty" class="no-matches">
        No matches found
    </div>

    <div *ngIf="loading" class="loader">
        <climb-spinner size="sm" [light]="true"></climb-spinner>
    </div>

    <ul *ngIf="!loading && !isEmpty" class="list">
        <li *ngFor="let facet of facets"
            [attr.data-automation-id]="facet?.Facet?.FacetName | dataAutomationId: '': '-item'"
            role="button"
            tabindex="0"
            (click)="selectFacet(facet)"
            (keydown.enter)="selectFacet(facet)"
            (mousedown)="preventFocus($event)"
            [appInsightsClick]="'select-facet-' + facet?.Facet?.FacetName">
            <svg *ngIf="workspaceIcons.get(facet?.Facet?.FacetName)?.name"
                [climbIcon]="workspaceIcons.get(facet?.Facet?.FacetName)?.name"
                size="md">
            </svg>
            <span class="name">
                {{facet.Facet.FacetDisplayName}}
            </span>
        </li>
        <li
            role="button"
            tabindex="0"
            *ngFor="let facet of modernFacets$ | async"
            (click)="selectModernFacet(facet)"
            (mousedown)="preventFocus($event)"
        >
            <svg
              *ngIf="workspaceIcons.get(facet.icon)?.name"
              [climbIcon]="workspaceIcons.get(facet.icon)?.name"
              size="md"
            >
            </svg>
            <span class="name">
                {{ facet.name }}
            </span>
        </li>
    </ul>
</div>

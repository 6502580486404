import { BulkEditCommService } from './../../common/facet/bulk-edit-comm.service';
import { GenotypeService } from './../genotype.service';
import { DataManagerService } from '../../services/data-manager.service';
import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';

import { LoggingService } from '../../services/logging.service';
import { BulkEditToolbarComponent } from '../../common/toolbars';
import { Entity, Genotype } from '../../common/types';
import { GenotypeBulkTemplatesComponent } from './genotype-bulk-templates.component';


@Component({
    selector: 'genotype-bulk-edit',
    templateUrl: './genotype-bulk-edit.component.html',
    providers: [
        BulkEditCommService
    ]
})
export class GenotypeBulkEditComponent implements OnInit {
    @Input() facet: any;
    @Input() genotypes: Entity<Genotype>[];
    @Output() exit: EventEmitter<any> = new EventEmitter<any[]>();
    @ViewChild('bulkEditToolbar') bulkEditToolBar: BulkEditToolbarComponent;
    @ViewChild('bulkTemplates') bulkTemplates: GenotypeBulkTemplatesComponent;

    readonly COMPONENT_LOG_TAG = 'genotype-bulk-edit';

    constructor(
        private genotypeService: GenotypeService,
        private loggingService: LoggingService,
        private dataManager: DataManagerService
    ) {
    }

    // lifecycle
    ngOnInit() {
        this.initialize();
    }

    initialize() {
         // Copy the input so we don't touch the grid data
        this.genotypes = this.genotypes.slice();
    }

    exitClicked() {
        if (this.dataManager.hasChanges()) {
            for (const genotype of this.genotypes) {
                this.genotypeService.cancelGenotype(genotype);
            }

            this.loggingService.logFacetUndoSuccess(this.COMPONENT_LOG_TAG);
        }

        this.exit.emit(this.genotypes);
    }

    // TODO: validate
    async validate(): Promise<string> {
        return this.bulkTemplates.validate();
    }
}

<div class="form-group row">
    <label class="col-sm-2 col-form-label">Reports</label>
    <div class="col-sm-10">
        <table class="table">
            <thead>
                <tr>
                    <th></th>
                    <th>
                        Report
                    </th>
                    <th>
                        Date Created
                    </th>
                    <th>
                        Created By
                    </th>
                </tr>
            </thead>
            <tbody *ngIf="!loading">
                <tr *ngFor="let report of reports | orderBy: 'DateCreated': true" id="job-pharma-report-table-row">
                    <td>
                        <a class="download-link" (click)="downloadReport(report)"><i class="fa fa-download"></i></a>
                    </td>
                    <td>
                       {{job.JobID}}-{{report.DateCreated | date : "ddMMyyyy-hhmmss"}}
                    </td>
                    <td>
                        {{report.DateCreated | formatShortDateTime}}
                    </td>
                    <td>
                        {{report.CreatedBy}}
                    </td>
                </tr>
            </tbody>
        </table>
        <loading-overlay *ngIf="loading"></loading-overlay>
    </div>
</div>

import {
    CellFormatterService,
    FormatterOptions,
    TableOptions
} from '../common/datatable';

import { DateFormatterService } from '@common/util/date-time-formatting';
import {
    getSafeProp,
    uniqueArrayFromPropertyPath
} from '@common/util';

import {
    MatingLitterSizeCellRendererComponent
} from './mating-litter-size-cell-renderer.component';

import { TranslationService } from '../services/translation.service';
import { addAuditColumnsToOptions } from '@common/datatable/utils';
import { MaterialPoolMaterial } from '../common/types/models';

export class MatingTableOptions {

    options: TableOptions;

    constructor(
        private cellFormatterService: CellFormatterService,
        private translationService: TranslationService,
        private dateFormatterService: DateFormatterService
    ) {
        this.options = addAuditColumnsToOptions(cellFormatterService)(this.getDefaultOptions());
    }

    getDefaultOptions(): TableOptions {
        return {
            csvFileName: 'matings.csv',
            enableDetailColumn: true,
            enableDraggable: false,
            enableSelectable: true,
            columns: [
                {
                    displayName: 'Housing ID',
                    sortField: 'MaterialPool.MaterialPoolIDSortable',
                    field: 'MaterialPool.MaterialPoolID'                    
                },
                {
                    displayName: 'Mating ID',
                    field: 'MatingID',
                    sortField: 'MatingIDSortable',
                },
                {
                    displayName: 'Type',
                    field: 'cv_MatingType.MatingType'
                },
                {
                    displayName: 'Status',
                    field: 'cv_MatingStatus.MatingStatus'
                },
                {
                    displayName: this.translationService.translate('Line'),
                    field: 'Line.LineName',
                    visible: false
                },
                {
                    displayName: 'Mating Date',
                    field: 'DateMating',
                    formatter: this.cellFormatterService.dateFormatter
                },
                {
                    displayName: 'Generation (Offspring)',
                    field: 'cv_Generation.Generation',
                    visible: false
                },
                {
                    displayName: 'Sire(s) Name(s)',
                    field: 'SireNameSorteable',
                    formatter: this.prepareNameBySex('Male'),
                    visible: false
                },
                {
                    displayName: 'Dam(s) Name(s)',
                    field: 'DamNameSorteable',
                    formatter: this.prepareNameBySex('Female'),
                    visible: false
                },
                {
                    displayName: 'Sire(s) Birth Date(s)',
                    field: 'SireBirthDateSorteable',
                    formatter: this.dateListFormatter,
                    visible: false
                },
                {
                    displayName: 'Dam(s) Birth Date(s)',
                    field: 'DamBirthDateSorteable',
                    formatter: this.dateListFormatter,
                    visible: false
                },
                {
                    displayName: 'Sire(s) Age(months)',
                    field: 'SireAgeMonthSorteable',
                    visible: false
                },
                {
                    displayName: 'Dam(s) Age(months)',
                    field: 'DamAgeMonthSorteable',
                    visible: false
                },
                {
                    displayName: 'Sire(s) Age(weeks)',
                    field: 'SireAgeWeekSorteable',
                    visible: false
                },
                {
                    displayName: 'Dam(s) Age(weeks)',
                    field: 'DamAgeWeekSorteable',
                    visible: false
                },
                {
                    displayName: 'Sire(s) Genotype(s)',                    
                    field: 'SireGenotypeSortable',            
                    visible: false,
                    sortable: true,
                    formatter: this.prepareGenotypeBySex('Male'),
                },
                {
                    displayName: 'Dam(s) Genotype(s)',
                    field: 'DamGenotypeSortable',
                    visible: false,
                    sortable: true,
                    formatter: this.prepareGenotypeBySex('Female'),
                },
                {
                    displayName: 'Plug Date',
                    field: 'MatingPlugDate',
                    sortable: false,
                    visible: false,
                    formatter: (row: any, value: any) => {
                        const values = row.MatingPlugDate.map((plugDate: any) => {
                            return this.dateFormatterService.formatDateOnly(plugDate.DatePlug);
                        });
                        return values.join(', ');
                    }
                },
                {
                    displayName: 'Births',
                    field: 'Birth.length',
                    formatter: (row: any, value: any) => {
                        return value || 0;
                    },
                    sortable: false,
                    headerClass: 'text-nowrap',
                    cellClass: 'num-cell'
                },                
                {
                    displayName: 'Location',
                    field: 'MaterialPool.CurrentLocationPath',
                    visible: false
                },
                {
                    displayName: 'Litter Size',
                    field: 'LitterSize',
                    sortable: false,
                    visible: false,
                    exportable: false,
                    minWidth: 100,
                    rendererComponent: <new () => MatingLitterSizeCellRendererComponent>
                    MatingLitterSizeCellRendererComponent
                },
                {
                    displayName: this.translationService.translate('Job'),
                    field: 'JobMaterialPool',
                    sortable: false,
                    visible: false,
                    formatter: (row: any, value: any) => {
                        const values = row.MaterialPool.JobMaterialPool
                            .map((jobMaterialPool: any) => {
                                return getSafeProp(jobMaterialPool, 'Job.JobID');
                            });
                        return values.join(', ');
                    }
                },
                {
                    displayName: 'Comments',
                    field: 'Comments',
                    visible: false
                },
                {
                    displayName: 'Notes',
                    field: 'MaterialPool.Note',
                    visible: false,
                    formatter: this.cellFormatterService.noteFormatter,
                    sortable: false
                },
            ]
        };
    }

    filterAnimalsBySex(row: any, sex: 'Male' | 'Female') {
        if (!row || !row.MaterialPool?.MaterialPoolMaterial) {
            return [];
        }

        const materialPoolMaterials = (row.MaterialPool.MaterialPoolMaterial as MaterialPoolMaterial[])?.filter(mpm =>
            mpm.DateIn && !mpm.DateOut) ?? [];

        return uniqueArrayFromPropertyPath(materialPoolMaterials, 'Material.Animal')
            .filter(animal => animal && getSafeProp(animal, 'cv_Sex.Sex') === sex);               
    }

    prepareNameBySex(sex: 'Male' | 'Female') {
        return (row: any) => this.filterAnimalsBySex(row, sex)
            .map(animal => animal.AnimalName)
            .join(', ');
    }

    prepareGenotypeBySex(sex: 'Male' | 'Female') {
        return (row: any) => {
            const parents = this.filterAnimalsBySex(row, sex);
            const values = new Set<string>();

            for (const parent of parents) {
                for (const genotype of parent.Genotype) {
                    const symbol = genotype.cv_GenotypeSymbol?.GenotypeSymbol;
                    const assay = genotype.cv_GenotypeAssay?.GenotypeAssay;
                    if (!assay || !symbol) {
                        continue;
                    }
                    values.add(assay + ' ' + symbol);
                }
            }
    
            return [...values].join(', ');
        };
    }

    dateListFormatter = (row: unknown, value: string | undefined, options: FormatterOptions) => {
        if (!value) {
            return '';
        }
        return value
            ?.trim()
            .split(', ')
            .map(item => {
                const date = new Date(item);
                return Number.isNaN(date.valueOf()) ? item : this.cellFormatterService.dateFormatter(row, date, options);
            })
            .join(', ');
    }
}

import { UniqueItem } from '../../common/models';
export class SampleSummaryItem implements UniqueItem {

    strain: string;
    type: string;
    
    /**
     * Enable field initializer
     *   E.g. new PageState({ field1: 'value', ...})
     * @param init
     */
    public constructor(init?: Partial<SampleSummaryItem>) {
        Object.assign(this, init);
    }

    get uniqueKey(): string {
        return [this.strain, this.type].join('--');
    }
}

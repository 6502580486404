<!-- Table for JobMaterials -->
<div *ngIf="material.JobMaterial && material.JobMaterial.length > 0">
    <table class="table table-sm table-borderless">
        <tbody>
            <tr *ngFor="let jobMaterial of getJobMaterial()">
                <td class="icon-cell"
                    *ngIf="!readonly">
                    <a (click)="removeJobMaterial(jobMaterial)"
                       *ngIf="jobMaterial.Job && !jobMaterial.Job.IsLocked">
                        <i class="fa fa-remove remove-item"
                            title="Remove {{'Job' | translate}}"></i>
                    </a>
                    <ng-container *ngIf="jobMaterial.Job && jobMaterial.Job.IsLocked">
                        <i class="fa fa-fw fa-lock text-muted" title="Locked"></i>
                    </ng-container>
                </td>

                <td class="text-nowrap">
                    {{jobMaterial.Job?.JobID}}
                </td>
            </tr>
        </tbody>
    </table>
</div>

<!-- Selector for JobMaterial -->
<climb-indirect-typeahead
        *ngIf="isGLP && !hasActiveJobs()"
        [search]="searchJobs"
        [resultFormatter]="jobIdFormatter"
        [placeholder]="placeholder"
        [required]="required"
        (selectItem)="addJobMaterial($event)"
></climb-indirect-typeahead>

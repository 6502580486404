<button data-automation-id="columns-dropdown"
        climbButton
        size="sm"
        variant="ghost"
        [id]="buttonId"
        [popoverTriggerFor]="columnsList"
        (toggle)="popoverToggle.emit($event)"
        popoverOpenWithKeyArrows
        [disabled]="disabled"
        [attr.aria-controls]="panelId"
        aria-haspopup="true">
    <svg [climbIcon]="icons.listView"></svg>
    Columns
    <svg [climbIcon]="(popoverToggle | async) ? icons.chevronUp : icons.chevronDown" iconPositionEnd></svg>
</button>
<climb-popover #columnsList [closeOnClick]="false">
    <div class="dropdown-list"
         [id]="panelId"
         climbTrapFocus
         climbArrowKeyAsTab
         role="menu"
         [attr.aria-labelledby]="buttonId">
        <div class="actions">
            <button data-automation-id="select-all-item"
                    (click)="selectAllClick()" 
                    role="menuitem">
                Select all
            </button>
            <button (click)="clearAllClick()" role="menuitem">Clear all</button>
        </div>
        <label [attr.data-automation-id]="column.displayName | dataAutomationId: '': '-item'"
               *ngFor="let column of columns"
               [class.selected]="column.isSelect"
               (keydown)="onColumnKeyDown($event, column)"
               role="menuitem">
            <svg *ngIf="column.isSelect"
                 [climbIcon]="icons.checkmark">
            </svg>
            <input type="checkbox"
                   [value]="column.id"
                   [checked]="column.isSelect"
                   (change)="columnChange(column)">
            {{ column.displayName }}
        </label>
    </div>
</climb-popover>

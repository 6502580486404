import {
    Component,
    Input
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { ConfirmOptions } from './confirm-options';

/**
 * The component displayed in the confirmation modal opened by the ConfirmService.
 */
@Component({
    selector: 'confirm-modal-component',
    templateUrl: './confirm-modal.component.html'
})
export class ConfirmModalComponent {
    @Input() options: ConfirmOptions;

    constructor(
        private activeModal: NgbActiveModal
    ) {
       // Nothing to do
    }

    // Handle events
    yes() {
        this.activeModal.close('yes');
    }

    no() {
        this.activeModal.dismiss('no');
    }

    extra() {
        this.activeModal.close('extra');
    }
}

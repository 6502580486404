import { HousingBulkEditComponent } from './bulkedit/housing-bulk-edit.component';
import { FacetSharedModule } from './../common/facet/facet-shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { ClimbCommonModule } from '../common/climb-common.module';
import { ClimbServicesModule } from '../services/climb-services.module';

import {
    HousingDetailComponent,
    HousingFilterComponent,
    HousingFacetComponent
} from '.';

import { HousingSharedModule } from './housing-shared.module';

import { AnimalsSharedModule } from '../animals/animals-shared.module';
import { LocationsSharedModule } from '../locations/locations-shared.module';
import { UsersSharedModule } from '../user/users-shared.module';
import { VocabulariesSharedModule } from '../vocabularies/vocabularies-shared.module';
import { WorkspacesSharedModule } from '../workspaces/workspaces-shared.module';
import { IoTSharedModule } from '../iot/iot-shared.module';
import { ReportingSharedModule } from '../reporting/reporting-shared.module';
import { HousingBulkTemplatesComponent } from './bulkedit';
import { TaskTableModule } from '../tasks/task-table.module';
import { TasksSharedModule } from '../tasks/tasks-shared.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgbModule,
        ClimbCommonModule,
        ClimbServicesModule,
        FacetSharedModule,
        HousingSharedModule,
        AnimalsSharedModule,
        IoTSharedModule,
        LocationsSharedModule,
        ReportingSharedModule,
        UsersSharedModule,
        VocabulariesSharedModule,
        WorkspacesSharedModule,
        TaskTableModule,
        TasksSharedModule,
    ],
    declarations: [
        HousingBulkEditComponent,
        HousingBulkTemplatesComponent,
        HousingDetailComponent,
        HousingFilterComponent,
        HousingFacetComponent
    ],
    exports: [
        HousingFacetComponent,
        HousingFilterComponent
    ],
    providers: [
    ]
})
export class HousingModule { }

import type { Protocol } from './protocol.interface';
import type { TaskGroup } from './task-group.interface';

export interface TaskGroupSet {
    C_Protocol_key: number;
    C_TaskGroupSet_key: number;
    C_Workgroup_key: number;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    ModifiedBy: string;
    Protocol: Protocol;
    SortOrder: number | null;
    TaskGroup: TaskGroup[];
    Version: number;
}

import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { drag } from '@icons';


@Component({
    selector: 'climb-drag-handle',
    templateUrl: './drag-row-handle.component.html',
    styleUrls: ['./drag-row-handle.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DragRowHandleComponent {
    @HostBinding('title')
    @Input() title = 'Drag to reorder';

    readonly icons = { drag };
}

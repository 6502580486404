<form novalidate>
    <div class="modal-header justify-content-center">
        <h4 class="modal-title">
            {{'Study' | translate}} Audit Report
        </h4>
    </div>

    <div class="modal-body modal-body-tall">

        <loading-overlay *ngIf="loading"></loading-overlay>

        <h2>
            {{study?.StudyName}}
        </h2>

        <div class="audit-report-section">
            <table class="table audit-table">
                <thead>
                    <tr>
                        <th>Action</th>
                        <th>Modified Date</th>
                        <th>Modified By</th>
                        <th>Name</th>
                        <th>Type</th>
                        <th>Active</th>
                        <th>Status</th>
                        <th>Approval Number</th>
                        <th>Description</th>
                        <th>Comments</th>
                        <th>Locked</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let auditRow of auditData"
                        [ngClass]="{'current-value' : auditRow.IsCurrent, 'expired-value': !auditRow.IsCurrent}">
                        <td class="text-nowrap">
                            <update-type-cell [updateType]="auditRow.UpdateType"></update-type-cell>
                        </td>
                        <td>{{auditRow.ModifiedDate | formatUtcDateTime}}</td>
                        <td>{{auditRow.ModifiedBy | userFullName}}</td>
                        <td>{{auditRow.StudyName}}</td>
                        <td>{{auditRow.StudyType}}</td>
                        <td>{{auditRow.IsActive}}</td>
                        <td>{{auditRow.StudyStatus}}</td>
                        <td class="long-text">{{auditRow.ApprovalNumber}}</td>
                        <td class="long-text">{{auditRow.Description}}</td>
                        <td class="long-text">{{auditRow.Comments}}</td>
                        <td>{{auditRow.IsLocked}}</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="audit-report-section">
            <h3>Characteristics</h3>

            <p *ngIf="!(auditCharacteristicsData | notEmpty)"
               class="no-data-message">
                {{noDataMessage}}
            </p>

            <table class="table audit-table"
                   *ngIf="auditCharacteristicsData | notEmpty">
                <thead>
                    <tr>
                        <th>Action</th>
                        <th>Modified Date</th>
                        <th>Modified By</th>
                        <th>Name</th>
                        <th>Value</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let auditRow of auditCharacteristicsData"
                        [ngClass]="{'current-value' : auditRow.IsCurrent, 'expired-value': !auditRow.IsCurrent}">
                        <td class="text-nowrap">
                            <update-type-cell [updateType]="auditRow.UpdateType"></update-type-cell>
                        </td>
                        <td>{{auditRow.ModifiedDate | formatUtcDateTime}}</td>
                        <td>{{auditRow.ModifiedBy | userFullName}}</td>
                        <td>{{auditRow.CharacteristicName}}</td>
                        <td>{{auditRow.CharacteristicValue}}</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="audit-report-section">
            <h3>{{'Lines' | translate}}</h3>

            <p *ngIf="!(auditLinesData | notEmpty)"
               class="no-data-message">
                {{noDataMessage}}
            </p>

            <table class="table audit-table"
                   *ngIf="auditLinesData | notEmpty">
                <thead>
                    <tr>
                        <th>Action</th>
                        <th>Modified Date</th>
                        <th>Modified By</th>
                        <th>Name</th>
                        <th>Animal Count</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let auditRow of auditLinesData"
                        [ngClass]="{'current-value' : auditRow.IsCurrent, 'expired-value': !auditRow.IsCurrent}">
                        <td class="text-nowrap">
                            <update-type-cell [updateType]="auditRow.UpdateType"></update-type-cell>
                        </td>
                        <td>{{auditRow.ModifiedDate | formatUtcDateTime}}</td>
                        <td>{{auditRow.ModifiedBy | userFullName}}</td>
                        <td>{{auditRow.LineName}}</td>
                        <td class="num-cell">{{auditRow.AnimalCount}}</td>
                    </tr>
                </tbody>
            </table>
        </div>

    </div><!-- /.modal-body -->

    <div class="modal-footer">
        <button type="button"
                class="btn btn-fw btn-lg btn-secondary"
                (click)="exportAudit()"
                [disabled]="loading">
            <i class="fa fa-download" aria-hidden="true"></i>
            Export
        </button>

        <button type="button"
                class="btn btn-fw btn-lg btn-secondary"
                (click)="onCancel()">
            Close
        </button>
    </div><!-- /.modal-footer -->
</form>

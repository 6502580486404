import type { Input } from './input.interface';

export interface InputInstance {
    C_InputInstance_key: number;
    C_Input_key: number;
    C_TaskInstance_key: number;
    C_Workgroup_key: number;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    Input: Input;
    InputValue: string;
    ModifiedBy: string;
    Version: number;
}

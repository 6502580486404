<div class="form-group">
    <div class="col-md-12">
        <table class="table">
            <thead>
                <tr class="detail-grid-header">
                    <th style="width:35px;">
                        <a class="add-item-link detail-grid-button-label"
                           *ngIf="facet.Privilege!=='ReadOnly'"
                           (click)="addComment()">
                            <i class="fa fa-plus-circle" title="Add new animal comment"></i>
                        </a>
                    </th>
                    <th colspan="2">Comment</th>
                    <th>Status</th>
                    <th>Created By</th>
                    <th>Created Date</th>
                    <th>Modified By</th>
                    <th>Modified Date</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let animalComment of animal.AnimalComment;let i=index;trackBy: trackItem" [ngClass]="animalComment | animalCommentStatusStyle ">
                    <td class="icon-cell">
                        <a (click)="removeComment(animalComment)" *ngIf="facet.Privilege!=='ReadOnly'">
                            <i class="fa fa-remove remove-item" title="Delete this comment"></i>
                        </a>
                    </td>
                    <td colspan="2">
                        <textarea [name]="'comments' + animalComment.C_AnimalComment_key"
                                  class="form-control input-large"
                                  [(ngModel)]="animalComment.Comment"
                                  [required]="true">
                        </textarea>
                    </td>
                    <td>
                        <!-- Only render when system-generated status promise has resolved 
                            (has to be defined to be default option on component init since
                            it isn't included in vocab choices observable') -->
                        <active-vocab-select *ngIf="systemGeneratedAnimalCommentStatus | async"
                                             [(model)]="animalComment.C_AnimalCommentStatus_key"
                                             (modelChange)="onModelChange(animalComment)"
                                             [vocabChoices]="animalCommentStatuses"
                                             [keyFormatter]="animalCommentStatusKeyFormatter"
                                             [optionFormatter]="animalCommentStatusFormatter"
                                             [loadSystemGeneratedValue]="true"
                                             [systemGeneratedValue]="systemGeneratedAnimalCommentStatus | async"
                                             [required]="true">
                        </active-vocab-select>
                    </td>
                    <td>
                        {{animalComment.CreatedBy}}
                    </td>
                    <td>
                        {{animalComment.DateCreated | formatShortDateOrTime}}
                    </td>
                    <td>
                        {{animalComment.ModifiedBy}}
                    </td>
                    <td>
                        {{animalComment.DateModified | formatShortDateOrTime}}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

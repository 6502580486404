import { Injectable } from '@angular/core';
import { defer, Observable } from 'rxjs';

import { VocabularyService } from '../vocabularies/vocabulary.service';
import { take } from 'rxjs/operators';

@Injectable()
export class OrderVocabService {

    // vocab Observables
    public get materialTypes$(): Observable<any[]> {
        return defer(() => this.vocabularyService.getCV('cv_MaterialTypes')).pipe(take(1));
    }
    public get taxons$(): Observable<any[]> {
        return defer(() => this.vocabularyService.getCV('cv_Taxons')).pipe(take(1));
    }
    public get sampleConditions$(): Observable<any[]> {
        return defer(() => this.vocabularyService.getCV('cv_SampleConditions')).pipe(take(1));
    }
    public get sampleTypes$(): Observable<any[]> {
        return defer(() => this.vocabularyService.getCV('cv_SampleTypes')).pipe(take(1));
    }
    public get materialOrigins$(): Observable<any[]> {
        return defer(() => this.vocabularyService.getCV('cv_MaterialOrigins')).pipe(take(1));
    }
    public get quarantineFacilities$(): Observable<any[]> {
        return defer(() => this.vocabularyService.getCV('cv_QuarantineFacilities')).pipe(take(1));
    }
    public get sexes$(): Observable<any[]> {
        return defer(() => this.vocabularyService.getCV('cv_Sexes')).pipe(take(1));
    }
    public get shipmentAnimals$(): Observable<any[]> {
        return defer(() => this.vocabularyService.getCV('cv_ShipmentAnimalConditions')).pipe(take(1));
    }
    public get shipmentContainers$(): Observable<any[]> {
        return defer(() => this.vocabularyService.getCV('cv_ShipmentContainerConditions')).pipe(take(1));
    }


    constructor(
        private vocabularyService: VocabularyService
    ) {}


}

import { Entity } from 'breeze-client';
import { Pipe, PipeTransform } from '@angular/core';
import { testBreezeIsDeleted } from '../util';


/**
 * Return whether or not object is valid in Breeze.
 *  Returns true on null
 *  Returns true on Deleted state
 *  Returns true on Detached state
 */
@Pipe({
    name: 'isDeleted',
    pure: false
})
export class IsDeletedPipe implements PipeTransform {

    transform(value: Entity): boolean {
        if (value &&
            value.entityAspect &&
            value.entityAspect.entityState
        ) {
            return testBreezeIsDeleted(value);
        }
        return true;
    }
}

import type { ProtocolTaskSection } from './protocol-task-section.interface';
import type { Note } from './note.interface';
import type { ProtocolTask } from './protocol-task.interface';
import type { StoredFileMap } from './stored-file-map.interface';
import type { TaskGroup } from './task-group.interface';
import type { TaskGroupSet } from './task-group-set.interface';

export interface Protocol {
    C_ParentProtocol_key: number | null;
    C_Protocol_key: number;
    C_Workgroup_key: number;
    ChildProtocol: Protocol;
    ChildProtocolTaskSection: ProtocolTaskSection[];
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    Description: string;
    IsActive: boolean;
    ModifiedBy: string;
    Note: Note[];
    ParentProtocol: Protocol;
    ProtocolName: string;
    ProtocolTask: ProtocolTask[];
    SortOrder: number | null;
    StoredFileMap: StoredFileMap[];
    TaskGroup: TaskGroup[];
    TaskGroupSet: TaskGroupSet[];
    Version: number;
}

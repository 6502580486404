import { DecimalFormatterDirective } from './decimal-formatter.directive';
import {
    Directive,
    HostListener,
    ElementRef,
    Input,
    OnChanges
} from "@angular/core";

@Directive({
    selector: "[moneyFormatter]"
})
export class MoneyFormatterDirective extends DecimalFormatterDirective implements OnChanges {
    @Input() ngModel: any;

    readonly DECIMAL_PLACES = 2;

    constructor(
        elementRef: ElementRef
    ) {
        super(elementRef);
        this.decimalPlaces = this.DECIMAL_PLACES;
    }

    ngOnChanges(changes: any) {
        super.ngOnChanges(changes);
    }

    @HostListener("blur", ["$event.target.value"])
    onBlur(value: any) {
        super.onBlur(value);
    }
}

<table class="table table-bordered ngx-paginated-table">
    <thead>
        <tr class="detail-grid-header"
            droppable
            (drop)="onDropTask($event)"
            [dropDisabled]="readonly || parentLocked"
            [attr.title]="readonly || parentLocked ? '' : 'Drop tasks here'">
            <th [attr.colspan]="visibleCount">
                <a class="add-item-link"
                   *ngIf="!readonly && !parentLocked"
                   (click)="addTasksViaModal()"><i class="fa fa-plus-circle" title="Add task"></i></a>
                Tasks
                <ng-container *ngIf="taskAssociations | notEmpty">
                    ({{taskCount}})

                    <a (click)="toggleTasksExpanded()" class="header-task-toggle-link">
                        <i class="fa"
                           [ngClass]="tasksExpanded ? 'fa-chevron-up' : 'fa-chevron-down'"
                           [title]="tasksExpanded ? 'Hide Tasks' : 'Show Tasks'"
                           aria-hidden="true"></i>
                    </a>
                </ng-container>
                <span class="float-right d-inline-flex align-items-center">
                    <climb-column-select *ngIf="tasksExpanded && taskAssociations | notEmpty"
                                         [columnLabels]="columnSelect"
                                         class="old-fashioned"
                                         (onChange)="columnSelectChanged($event)">
                    </climb-column-select>
                    <a *ngIf="!readonly"
                       class="detail-grid-button"
                       (click)="pasteTasksOrProtocols()">
                        <i class="fa fa-paste" title="Paste tasks/protocols"></i>
                    </a>
                </span>
            </th>
        </tr>

        <tr *ngIf="tasksExpanded && taskAssociations | notEmpty"
            class="detail-grid-header">

            <!-- Remove -->
            <th *ngIf="visible.remove" class="icon-cell"></th>

            <!-- Lock/Unlock All -->
            <th *ngIf="visible.lock" class="icon-cell">
                <ng-container *ngIf="studyAdministrator && !readonly">
                    <div class="text-nowrap dropdown-wrapper">
                        <a class="dropdown-toggle"
                           data-toggle="dropdown"
                           aria-haspopup="true"
                           aria-expanded="false"
                           title="Lock/unlock all">
                            <i class="fa fa-lock" title="Lock/unlock all"></i>
                        </a>
                        <div class="dropdown-menu">
                            <a class="dropdown-item" (click)="lockAllTaskInstances()">
                                <i class="fa fa-lock" aria-hidden="true"></i>
                                Lock All
                            </a>
                            <a class="dropdown-item" (click)="unlockAllTaskInstances()">
                                <i class="fa fa-unlock-alt" aria-hidden="true"></i>
                                Unlock All
                            </a>
                        </div>
                    </div>
                </ng-container>
            </th>

            <th *ngIf="visible.protocol">
                <a (click)="tableSort.toggleSort('TaskInstance.ProtocolTask?.Protocol?.ProtocolName')"
                   class="header-sort-link"
                   title="Sort by {{'protocol' | translate}}">{{'Protocol' | translate}}</a>
            </th>
            <th *ngIf="visible.taskAlias">
                <a (click)="tableSort.toggleSort('TaskInstance.TaskAlias')"
                   class="header-sort-link"
                   title="Sort by task name">Task Name</a>
            </th>
            <th *ngIf="visible.taskName">
                <a (click)="tableSort.toggleSort('TaskInstance.WorkflowTask.TaskName')"
                   class="header-sort-link"
                   title="Sort by task">Task</a>
            </th>
            <th *ngIf="visible.sequence">
                <a (click)="tableSort.toggleSort('Sequence')"
                   class="header-sort-link"
                   title="Sort by sequence">Sequence</a>
            </th>
            <!-- Paste Materials column -->
            <th *ngIf="visible.pasteMaterials" class="icon-cell">
                <div class="form-control-plaintext">
                    <a (click)="pasteMaterialsIntoAllTasks()" class="header-paste-icon-link">
                        <i class="fa fa-paste" title="Paste animals or samples into all tasks"></i>
                    </a>
                </div>
            </th>
            <th *ngIf="visible.animals">
                <div class="text-nowrap dropdown-wrapper">
                    <a class="dropdown-toggle"
                       data-toggle="dropdown"
                       aria-haspopup="true"
                       aria-expanded="false">
                        Animals
                    </a>
                    <div class="dropdown-menu">
                        <a class="dropdown-item" (click)="viewAnimalsPerTask()">View Animals</a>
                        <a class="dropdown-item" (click)="viewAnimalsLinesPerTask()">View Animals {{'Lines' | translate}}</a>
                        <a class="dropdown-item" (click)="viewCohortsPerTask()">View Cohorts</a>
                    </div>
                </div>
            </th>
            <th *ngIf="visible.samples">
                <div class="text-nowrap dropdown-wrapper">
                    <a class="dropdown-toggle"
                       data-toggle="dropdown"
                       aria-haspopup="true"
                       aria-expanded="false">
                        Samples
                    </a>
                    <div class="dropdown-menu">
                        <a class="dropdown-item" (click)="viewSampleNames()">View Sample Names</a>
                        <a class="dropdown-item" (click)="viewSamplesWithSources()">View Samples with Sources</a>
                    </div>
                </div>
            </th>
            <th *ngIf="visible.inputs" class="text-nowrap">
                <span *ngIf="!bulkInputTasks.length">
                    Inputs
                </span>
                <div *ngIf="bulkInputTasks.length"
                     class="bulk-edit-header-with-hide">
                    <a (click)="toggleInputsExpanded()" class="header-chevron-link">
                        <i class="fa"
                           [ngClass]="inputsExpanded ? 'fa-chevron-up' : 'fa-chevron-down'"
                           [title]="inputsExpanded ? 'Hide inputs' : 'Show inputs'"></i>
                    </a>
                    <bulk-edit-header [readonly]="readonly"
                                      [headerText]="'Inputs'"
                                      (updateClicked)="bulkInputUpdated()"
                                      [disabled]="!bulkInputTask || !bulkInputInput">
                        <div modal-content style="width: 360px;" class="form-label-right text-body">
                            <div class="form-group row">
                                <label for="bulkInputTask"
                                       class="col-md-3 col-form-label">Task</label>
                                <div class="col-md-9">
                                    <select name="bulkInputTask"
                                            [(ngModel)]="bulkInputTask"
                                            (ngModelChange)="bulkInputTaskChanged()"
                                            class="form-control input-medium">
                                        <option [ngValue]="null"></option>
                                        <option *ngFor="let task of bulkInputTasks"
                                                [ngValue]="task">
                                            {{task?.WorkflowTask?.TaskName}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="bulkInputTaskKey"
                                       class="col-md-3 col-form-label">Task Name</label>
                                <div class="col-md-9">
                                    <select name="bulkInputTaskKey"
                                            [(ngModel)]="bulkInputTaskAlias"
                                            [disabled]="bulkInputTaskAliases.length === 0"
                                            class="form-control input-medium">
                                        <option [ngValue]="null">[All]</option>
                                        <option *ngFor="let alias of bulkInputTaskAliases"
                                                [ngValue]="alias">
                                            {{alias}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="bulkInputInput"
                                       class="col-md-3 col-form-label">Input</label>
                                <div class="col-md-9">
                                    <select name="bulkInputInput"
                                            [(ngModel)]="bulkInputInput"
                                            (ngModelChange)="bulkInputInputChanged()"
                                            [disabled]="bulkInputInputs.length === 0"
                                            class="form-control input-medium">
                                        <option *ngFor="let input of bulkInputInputs"
                                                [ngValue]="input">
                                            {{input?.InputName}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="bulkInputInput"
                                       class="col-md-3 col-form-label">Value</label>
                                <div class="col-md-9">
                                    <data-type-input [id]="'bulkInputValue'"
                                                     [ioObject]="bulkInputInput"
                                                     [(value)]="bulkInputValue"></data-type-input>
                                </div>
                            </div>
                        </div>
                    </bulk-edit-header>
                </div>
            </th>
            <th *ngIf="visible.dueDate">
                <bulk-edit-header [readonly]="readonly"
                                  [showExtraMenuItems]="true"
                                  [headerText]="'Due Date'"
                                  (updateClicked)="bulkDateDueChanged()">

                    <ng-container extra-menu-items>
                        <a (click)="tableSort.toggleSort('TaskInstance.DateDue')"
                           class="dropdown-item"
                           title="Sort by due date">Sort</a>
                    </ng-container>
                    <div modal-content>
                        <climb-ngb-date #dateControl="ngModel"
                                        [(ngModel)]="bulkDateDue"
                                        [ngModelOptions]="{ standalone: true }"
                                        [allowTime]="true"></climb-ngb-date>
                    </div>
                </bulk-edit-header>
            </th>
            <th *ngIf="visible.deviation">
                <div class="bulk-edit-header-with-hide">
                    <a (click)="toggleDeviationExpanded()" class="header-chevron-link">
                        <i class="fa"
                           [ngClass]="deviationExpanded ? 'fa-chevron-up' : 'fa-chevron-down'"
                           [title]="deviationExpanded ? 'Hide allowance' : 'Show allowance'"></i>
                    </a>
                    <bulk-edit-header [readonly]="readonly"
                                      [showExtraMenuItems]="true"
                                      [headerText]="'Allowance'"
                                      (updateClicked)="bulkDeviationChanged()">

                        <ng-container extra-menu-items>
                            <a (click)="tableSort.toggleSort('TaskInstance.Deviation')"
                               class="dropdown-item"
                               title="Sort by allowance">Sort</a>
                        </ng-container>
                        <div modal-content>
                            <textarea name="bulkDeviation"
                                      [(ngModel)]="bulkDeviation"
                                      class="form-control input-medium"
                                      maxlength="75"
                                      rows="2"></textarea>
                        </div>
                    </bulk-edit-header>
                </div>
            </th>
            <th *ngIf="visible.location">
                <bulk-edit-header [readonly]="readonly"
                                  [showExtraMenuItems]="true"
                                  [headerText]="'Location'"
                                  (updateClicked)="bulkLocationChanged()">

                    <ng-container extra-menu-items>
                        <a (click)="tableSort.toggleSort('TaskInstance.CurrentLocationPath')"
                           class="dropdown-item"
                           title="Sort by location">Sort</a>
                    </ng-container>
                    <div modal-content>
                        <location-chooser [(location)]="bulkLocation"
                                          [required]="false"></location-chooser>
                    </div>
                </bulk-edit-header>
            </th>
            <th *ngIf="visible.status">
                <bulk-edit-header [readonly]="readonly"
                                  [showExtraMenuItems]="true"
                                  [headerText]="'Status'"
                                  (updateClicked)="bulkTaskStatusChanged()">

                    <ng-container extra-menu-items>
                        <a (click)="tableSort.toggleSort('TaskInstance.cv_TaskStatus?.TaskStatus')"
                           class="dropdown-item"
                           title="Sort by status">Sort</a>
                    </ng-container>
                    <div modal-content>
                        <active-vocab-select [(model)]="bulkTaskStatusKey"
                                             [vocabChoices]="taskStatuses"
                                             [keyFormatter]="taskStatusKeyFormatter"
                                             [optionFormatter]="taskStatusFormatter"></active-vocab-select>
                    </div>
                </bulk-edit-header>
            </th>
            <th *ngIf="visible.assignedTo">
                <bulk-edit-header
                    [readonly]="readonly"
                    [showExtraMenuItems]="true"
                    [headerText]="'Assigned To'"
                    (updateClicked)="bulkAssignedToChanged()">

                    <ng-container extra-menu-items>
                        <a (click)="tableSort.toggleSort('TaskInstance.AssignedToResource.ResourceName')"
                           class="dropdown-item"
                           title="Sort by due date">Sort</a>
                    </ng-container>
                    <div modal-content>
                        <climb-assigned-to-select [(model)]="bulkAssignedToKey"></climb-assigned-to-select>
                    </div>
                </bulk-edit-header>
            </th>

            <!-- Accounting -->
            <th *ngIf="visible.cost">
                <a (click)="tableSort.toggleSort('TaskInstance.Cost')"
                   class="header-cost-link"
                   title="Sort by cost">Cost</a>

                <a class="float-right"
                   (click)="calculateAllTaskCosts()"
                   *ngIf="!readonly && !parentLocked">
                    <i class="fa fa-calculator" title="Calculate all task costs"></i>
                </a>
            </th>
            <th *ngIf="visible.duration">
                <a (click)="tableSort.toggleSort('TaskInstance.Duration')"
                   class="header-duration-link"
                   title="Sort by duration">Duration</a>

                <a class="float-right"
                   (click)="calculateAllTaskDurations()"
                   *ngIf="!readonly && !parentLocked">
                    <i class="fa fa-calculator" title="Calculate all task durations"></i>
                </a>
            </th>
        </tr>
    </thead>

    <tbody *ngIf="tasksExpanded">
        <ng-container *ngFor="let taskAssociation of taskAssociations |
                orderBy:tableSort.propertyPath:tableSort.reverse |
                paginate: { itemsPerPage: 20, currentPage: taskPage };
                let taskIndex = index">

            <tr *ngIf="taskAssociation?.TaskInstance && taskAssociation.TaskInstance.WorkflowTask?.cv_TaskType?.TaskType === taskType"
                droppable
                [dropDataKey]="taskAssociation.TaskInstance?.C_TaskInstance_key"
                [dropDisabled]="taskAssociation.TaskInstance?.IsLocked"
                (drop)="onDropMaterialToTask($event)">

                <!-- Remove -->
                <td *ngIf="visible.remove" class="icon-cell">
                    <button (click)="removeTaskInstance(taskAssociation.TaskInstance)"  class="btn btn-link remove-item py-0"
                            [disabled]="!(taskAssociation.TaskInstance.cv_TaskStatus?.IsEndState !== 1 && !taskAssociation.TaskInstance.IsLocked && !taskAssociation.TaskInstance.cv_TaskStatus?.IsEndState && !taskAssociation.TaskInstance.IsWorkflowLocked)">
                        <i class="fa fa-remove" title="Remove task"></i>
                    </button>
                </td>

                <!-- Lock -->
                <td *ngIf="visible.lock" class="icon-cell">
                    <div class="form-control-plaintext">
                        <lock-control [(model)]="taskAssociation.TaskInstance.IsLocked"
                                      [administrator]="studyAdministrator"
                                      [readonly]="readonly"></lock-control>
                    </div>
                </td>

                <td *ngIf="visible.protocol" class="text-nowrap">
                    <div class="form-control-plaintext">
                        {{taskAssociation.TaskInstance.ProtocolInstance?.ProtocolAlias}}
                    </div>
                </td>
                <td *ngIf="visible.taskAlias" class="text-nowrap">
                    <ng-container *ngIf="allowNoMaterial">
                        <div class="form-control-plaintext">
                            {{taskAssociation.TaskInstance.TaskAlias}}
                        </div>
                    </ng-container>
                    <ng-container *ngIf="!allowNoMaterial">
                        <ng-container *ngIf="!taskAssociation.TaskInstance?.WorkflowTask?.NoMaterials">
                            <div class="form-control-plaintext">
                                {{ taskAssociation.TaskInstance.TaskAlias }}
                            </div>
                        </ng-container>
                        <ng-container *ngIf="taskAssociation.TaskInstance?.WorkflowTask?.NoMaterials">
                            <em>{{ taskAssociation.TaskInstance.TaskAlias }} </em>
                            <info-tooltip [text]="'Animals cannot be added to No Material Tasks.'"></info-tooltip>
                        </ng-container>
                    </ng-container>
                </td>
                <td *ngIf="visible.taskName" class="text-nowrap">
                    <div class="form-control-plaintext">
                        {{taskAssociation.TaskInstance.WorkflowTask?.TaskName}}
                    </div>
                </td>
                <td *ngIf="visible.sequence" class="num-cell">
                    <div class="form-control-plaintext">
                        {{taskAssociation.Sequence}}
                    </div>
                </td>

                <!-- Paste materials -->
                <td *ngIf="visible.pasteMaterials" class="icon-cell">
                    <div class="form-control-plaintext"
                         *ngIf="!taskAssociation.TaskInstance.IsLocked">
                        <a (click)="pasteMaterialsIntoTask(taskAssociation.TaskInstance)"
                           class="paste-link">
                            <i class="fa fa-paste" title="Paste animals or samples into task"></i>
                        </a>
                    </div>
                </td>

                <!-- Cohorts and Animals -->
                <td *ngIf="visible.animals" class="text-nowrap">
                    <div class="form-control-plaintext">
                        <ng-container *ngIf="!taskAssociation.TaskInstance?.WorkflowTask?.NoMaterials">
                            <!-- Cohorts -->
                            <div *ngIf="taskCohortsExpanded && taskAssociation.TaskInstance?.DerivedCohorts | notEmpty"
                                 class="half-bottom-margin">
                                <h5 class="font-italic">
                                    Cohorts
                                </h5>
                                <div *ngFor="let cohort of taskAssociation.TaskInstance?.DerivedCohorts">
                                    <ng-container *ngIf="cohort">
                                        <a (click)="removeTaskCohort(taskAssociation.TaskInstance, cohort)"
                                           *ngIf="!readonly && !taskAssociation.TaskInstance.IsLocked">
                                            <i class="fa fa-remove remove-item" title="Remove cohort"></i>
                                        </a>
                                        {{cohort.CohortName}}
                                    </ng-container>
                                </div>
                            </div>

                            <!-- Animals -->
                            <div *ngIf="taskAnimalsExpanded && taskAssociation.TaskInstance?.FilteredAnimalMaterials | notEmpty">
                                <h5 class="font-italic">
                                    Animals
                                </h5>
                                <div *ngFor="let animal of taskAssociation.TaskInstance?.FilteredAnimalMaterials">
                                    <ng-container *ngIf="animal.Material?.Animal">
                                        <a (click)="removeTaskMaterial(animal)"
                                           *ngIf="!readonly && !taskAssociation.TaskInstance.IsLocked">
                                            <i class="fa fa-remove remove-item" title="Remove animal"></i>
                                        </a>
                                        {{animal.Material?.Animal.AnimalName}}
                                    </ng-container>
                                </div>
                            </div>

                            <!-- Animals Lines-->
                            <div *ngIf="taskAnimalsLinesExpanded && taskAssociation.TaskInstance?.FilteredAnimalMaterials | notEmpty">
                                <h5 class="font-italic">
                                    Animals {{'Lines' | translate}}
                                </h5>
                                <div *ngFor="let animal of taskAssociation.TaskInstance?.FilteredAnimalMaterials">
                                    <ng-container>
                                        <span *ngIf="animal.Material?.Animal">
                                            <a (click)="removeTaskMaterial(animal)"
                                               *ngIf="!readonly && !taskAssociation.TaskInstance.IsLocked">
                                                <i class="fa fa-remove remove-item" title="Remove animal"></i>
                                            </a>
                                            {{animal.Material?.Animal.AnimalName}}
                                        </span>
                                        <span *ngIf="animal.Material?.Line">
                                            {{animal.Material?.Line.LineName}}
                                        </span>
                                    </ng-container>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </td>

                <!-- Samples -->
                <td *ngIf="visible.samples"
                    [ngClass]="{'text-nowrap': !taskSamplesExpanded}">
                    <!-- Not expanded -->
                    <div class="form-control-plaintext"
                         *ngIf="!taskSamplesExpanded">
                        <div *ngFor="let sample of taskAssociation.TaskInstance?.FilteredSampleMaterials">
                            <ng-container *ngIf="sample.Material?.Sample">
                                <a (click)="removeTaskMaterial(sample)"
                                   *ngIf="!readonly && !taskAssociation.TaskInstance.IsLocked">
                                    <i class="fa fa-remove remove-item" title="Remove sample"></i>
                                </a>
                                {{sample.Material?.Sample.SampleName}}
                            </ng-container>
                        </div>
                    </div>

                    <!-- Expanded -->
                    <table class="table table-sm"
                           *ngIf="taskSamplesExpanded && taskAssociation.TaskInstance?.FilteredSampleMaterials | notEmpty">
                        <thead>
                            <tr>
                                <th *ngIf="!readonly && !taskAssociation.TaskInstance.IsLocked"></th>
                                <th>ID</th>
                                <th>Name</th>
                                <th>Sources</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let sample of taskAssociation.TaskInstance?.FilteredSampleMaterials">
                                <ng-container *ngIf="sample?.Material?.Sample">
                                    <td class="icon-cell"
                                        *ngIf="!readonly && !taskAssociation.TaskInstance.IsLocked">
                                        <a (click)="removeTaskMaterial(sample)">
                                            <i class="fa fa-remove remove-item" title="Remove sample"></i>
                                        </a>
                                    </td>
                                    <td class="text-nowrap">
                                        {{sample.Material?.Identifier}}
                                    </td>
                                    <td class="text-nowrap">
                                        {{sample.Material?.Sample.SampleName}}
                                    </td>
                                    <td class="text-nowrap">
                                        <span *ngIf="sample.Material?.MaterialSourceMaterial?.length">
                                            <span *ngFor="let source of sample.Material.MaterialSourceMaterial; let last = last">
                                                {{source?.SourceMaterial?.Animal?.AnimalName}}{{source?.SourceMaterial?.Sample?.SampleName}}
                                                <span *ngIf="!last">, </span>
                                            </span>
                                        </span>
                                    </td>
                                </ng-container>
                            </tr>
                        </tbody>
                    </table>
                </td>

                <!-- Inputs -->
                <td *ngIf="visible.inputs" class="text-nowrap">
                    <table class="table table-borderless table-sm"
                           *ngIf="inputsExpanded">
                        <tbody>
                            <tr *ngFor="let taskInput of taskAssociation.TaskInstance.TaskInput | orderBy:'Input.SortOrder'">
                                <td class="text-right">
                                    <label for="tii-{{detailId}}-{{taskInput.C_Input_key}}{{taskInput.C_TaskInput_key}}"
                                           class="col-form-label">{{taskInput.Input?.InputName}}</label>
                                </td>
                                <td class="full-width">
                                    <data-type-input *ngIf="taskInput.Input"
                                                     [id]="'tii-' + detailId + '-' + taskInput.C_Input_key + taskInput.C_TaskInput_key"
                                                     [ioObject]="taskInput.Input"
                                                     [readonly]="readonly || taskAssociation.TaskInstance.IsLocked"
                                                     [(value)]="taskInput.InputValue"></data-type-input>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </td>

                <td *ngIf="visible.dueDate">
                    <climb-ngb-date #dateControl="ngModel"
                                    [(ngModel)]="taskAssociation.TaskInstance.DateDue"
                                    [ngModelOptions]="{ standalone: true }"
                                    (ngModelChange)="calculateDueDates(taskAssociation.TaskInstance)"
                                    [disabled]="readonly || taskAssociation.TaskInstance.IsLocked"
                                    [allowTime]="true"></climb-ngb-date>
                </td>
                <td *ngIf="visible.deviation">
                    <ng-container *ngIf="deviationExpanded">
                        <textarea name="deviation-{{taskIndex}}"
                                  [(ngModel)]="taskAssociation.TaskInstance.Deviation"
                                  [readonly]="readonly || taskAssociation.TaskInstance.IsLocked"
                                  class="form-control input-medium"
                                  maxlength="75"
                                  rows="2"></textarea>
                    </ng-container>
                </td>
                <td *ngIf="visible.location">
                    <location-chooser [(location)]="taskAssociation.TaskInstance.LocationPosition"
                                      [locationKey]="taskAssociation.TaskInstance.C_LocationPosition_key"
                                      [readonly]="readonly || taskAssociation.TaskInstance.IsLocked"
                                      [required]="false"></location-chooser>
                </td>
                <td *ngIf="visible.status">
                    <active-vocab-select [(model)]="taskAssociation.TaskInstance.C_TaskStatus_key"
                                         (modelChange)="taskStatusChanged(taskAssociation.TaskInstance)"
                                         [vocabChoices]="taskStatuses"
                                         [keyFormatter]="taskStatusKeyFormatter"
                                         [optionFormatter]="taskStatusFormatter"
                                         [readonly]="readonly || taskAssociation.TaskInstance.IsLocked"></active-vocab-select>
                </td>
                <td *ngIf="visible.assignedTo">
                    <climb-assigned-to-select [(model)]="taskAssociation.TaskInstance.C_AssignedTo_key"
                                        [disabled]="readonly || taskAssociation.TaskInstance.IsLocked || taskAssociation.TaskInstance.HasInvalidInputs"></climb-assigned-to-select>
                </td>

                <!-- Cost -->
                <td *ngIf="visible.cost">
                    <div class="input-group flex-nowrap">
                        <input type="number"
                               name="taskCost-{{taskIndex}}"
                               [(ngModel)]="taskAssociation.TaskInstance.Cost"
                               [disabled]="readonly || taskAssociation.TaskInstance.IsLocked"
                               step="0.01"
                               min="0.00"
                               class="form-control input-medium"
                               moneyFormatter />
                        <div class="input-group-append">
                            <button type="button"
                                    class="btn btn-secondary"
                                    (click)="calculateTaskCost(taskAssociation)"
                                    [disabled]="readonly || taskAssociation.TaskInstance.IsLocked">
                                <i class="fa fa-calculator"
                                   title="Calculate cost"></i>
                            </button>
                        </div>
                    </div>
                </td>

                <!-- Duration -->
                <td *ngIf="visible.duration">
                    <div class="input-group flex-nowrap">
                        <input type="number"
                               name="taskDuration-{{taskIndex}}"
                               [(ngModel)]="taskAssociation.TaskInstance.Duration"
                               [disabled]="readonly || taskAssociation.TaskInstance.IsLocked"
                               step="any"
                               min="0"
                               decimalFormatter
                               [decimalPlaces]="2"
                               class="form-control input-medium" />
                        <div class="input-group-append">
                            <button type="button"
                                    class="btn btn-secondary"
                                    (click)="calculateTaskDuration(taskAssociation)"
                                    [disabled]="readonly || taskAssociation.TaskInstance.IsLocked">
                                <i class="fa fa-calculator"
                                   title="Calculate duration"></i>
                            </button>
                        </div>
                    </div>
                </td>
            </tr>
        </ng-container>
    </tbody>
</table>

<pagination-controls *ngIf="tasksExpanded"
                     (pageChange)="changeTaskPage($event)"
                     [autoHide]="true"
                     class="ngx-table-paginator"></pagination-controls>

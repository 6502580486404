/**
 * values in cv_DosingTable
 */

export enum DosingTable {
    TREATMENT = 'Treatment',
    FORMULATION_DOSE = 'Formulation Dose',
    ACTIVE_DOSE = 'Active Dose',
    CONCENTRATION = 'Concentration',
    ROUTE = 'Route',
    DOSING_VOLUME = 'Dosing Volume'
}
<div class="colorpicker">
    <dx-color-box class="position-relative" #dxColorBox
        [(value)]="model"
        (valueChange)="onValueChange($event)"
        (onOpened)="initPopup($event)"
        (onClosed)="onClosedPopup($event)"
        [dropDownOptions]="dropdownOptions"
        [disabled]="disabled"
        [editAlphaChannel]="false">
    </dx-color-box>
</div>

import type { Input } from './input.interface';
import type { ProtocolTask } from './protocol-task.interface';

export interface InputDefault {
    C_EnumerationItem_key: number | null;
    C_InputDefault_key: number;
    C_Input_key: number;
    C_ProtocolTask_key: number;
    C_Workgroup_key: number;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    Input: Input;
    InputValue: string;
    ModifiedBy: string;
    ProtocolTask: ProtocolTask;
    Version: number;
}

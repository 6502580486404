import {
    CellFormatterService,
    TableOptions
} from '../common/datatable';
import { AuditModifiedFieldComponent } from './audit-modified-fields/audit-modified-field.component';
import { AuditClassFactory } from './audit-class-maker';

export class AuditVocabulariesCouriersTableOptions {

    options: TableOptions;

    constructor(
        private cellFormatterService: CellFormatterService
    ) {
        this.options = this.getDefaultOptions();
    }

    getDefaultOptions(): TableOptions {

        return {
            csvFileName: 'animal-statuses-audit.csv',
            enableDetailColumn: false,
            enableDraggable: false,
            enableSelectable: false,
            enableAuditColumns: false,
            columns: [
                {
                    displayName: 'Type',
                    field: 'UpdateType',
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Modified Fields',
                    field: 'ModifiedFields',
                    sortField: 'ModifiedFields',
                    rendererComponent: AuditModifiedFieldComponent,
                    exportFormatter: this.cellFormatterService.auditModifiedFieldsExportFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Date',
                    field: 'ModifiedDate',
                    formatter: this.cellFormatterService.dateTimeUTCFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Modified By',
                    field: 'ModifiedBy',
                    formatter: this.cellFormatterService.userNameFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Courier',
                    field: 'Courier',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('Courier', 'Courier'),
                    cellClass: AuditClassFactory.createCellClass('courier'),
                },
                {
                    displayName: 'Active',
                    field: 'Active',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('Active', 'Active'),
                    cellClass: AuditClassFactory.createCellClass('active'),
                },                
                {
                    displayName: 'Default',
                    field: 'Default',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('Default', 'Default'),
                    cellClass: AuditClassFactory.createCellClass('default'),
                },               
                {
                    displayName: 'Sort Order',
                    field: 'SortOrder',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('SortOrder', 'SortOrder'),
                    cellClass: AuditClassFactory.createCellClass('sortorder'),
                },
                {
                    displayName: 'Created By',
                    field: 'CreatedBy',
                    formatter: this.cellFormatterService.userNameFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Created Date',
                    field: 'DateCreated',
                    formatter: this.cellFormatterService.dateTimeUTCFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                }
            ]
        };
    }
}

<div>
    <loading-overlay *ngIf="loading" [message]="loadingMessage"></loading-overlay>
    <ng-container *ngIf="taskRowsOpen">
        <div *ngFor="let groups of taskGroups" class="task-group-table">
            <div>
                <ng-container *ngFor="let taskGroup of groups; let index = index;">
                    <div class="task-title" *ngIf="index < 1">{{taskGroup.SectionName}}</div>
                    <div class="text-nowrap row-border shadow">
                        <p class="task-title">
                            <span>{{taskGroup.ProtocolTask[0].WorkflowTask.TaskName}}</span>
                            <span>{{frequencyKeyMap[taskGroup?.C_Frequency_key]}}</span>
                            <span *ngIf="taskGroup.ToEnd">to end</span>
                            <span *ngIf="!taskGroup.ToEnd">x{{taskGroup.Occurrences}}</span>
                        </p>
                        <div class="flex-container">
                            <p class="sub-title" *ngIf="taskGroup.TimeDifference">
                                {{taskGroup.TimeDifference}}hrs
                            </p>
                            <p class="sub-title"># of task first day: {{taskGroup.FirstDayTasks}}</p>
                        </div>
                        <div class="flex-container">
                            <p class="italic-title" *ngIf="taskGroup.TimeDifference">Time difference</p>
                            <p class="italic-title" *ngIf="taskGroup.Offset">offset</p>
                        </div>
                        <p *ngIf="!isGLP" class="italic-title" style="color: #000000;">Start Day: Study Day: {{taskGroup.FirstDay}}</p>
                        <p *ngIf="isGLP" class="italic-title" style="color: #000000;">Start Day: Study Day: {{taskGroup.FirstDay + 1}}</p>
                        <div class="col-12" style="text-align: right;">
                            <!-- Calendar -->
                            Calendar
                            <a (click)="taskGroup.calendarExpand = !taskGroup.calendarExpand"
                               class="toggle-calendar-link">
                                <i class="fa"
                                   [ngClass]="taskGroup.calendarExpand ? 'fa-chevron-up' : 'fa-chevron-down'"
                                   [title]="(taskGroup.calendarExpand ? 'Hide ' : 'Show ')" aria-hidden="true"></i>
                            </a>
                            <div class="fieldset-bordered" *ngIf="taskGroup.calendarExpand" style="text-align: left;">
                                <table class="table table-bordered">
                                    <thead>
                                        <tr class="detail-grid-header">
                                            <th>
                                                Day
                                            </th>
                                            <th *ngFor="let row of taskGroup.Days; let index = index">
                                                <div *ngIf="!isGLP">
                                                    {{ index }}
                                                </div>
                                                <div *ngIf="isGLP">
                                                    {{ index + 1 }}
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                Task Count
                                            </td>
                                            <td *ngFor="let row of taskGroup.Days">{{ row }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </ng-container>

    <div class="col-md-1 nowrap" style="margin-bottom: 20px">
        <a *ngIf="!readonly" class="add-item-link detail-grid-button" (click)="addSectionViaModal()">
            <i class="fa fa-plus-circle" title="Select a task or any pre-existing protocol"></i>
        </a>
        <label class="control-label">Protocol</label>
    </div>
    <div class="col-md-12">
        <table
            class="table protocol-task-table"
            droppable
            [dropDataKey]="protocol.C_Protocol_key"
            [dropDisabled]="readonly"
            (drop)="onDropTaskToProtocol($event, undefined)"
            [attr.title]="readonly ? '' : 'Drop new tasks here'">
            <thead *ngIf="!readonly">
                <tr>
                    <th>&nbsp;</th>
                    <th>&nbsp;</th>
                    <th>&nbsp;</th>
                    <th>&nbsp;</th>
                    <th class="nowrap">
                        <a class="add-item-link detail-grid-button" (click)="addTasksViaModal(undefined)">
                            <i data-automation-id="add-task-button"
                               class="fa fa-plus-circle" 
                               title="Add task">
                            </i>
                        </a>
                        <label class="control-label">Task</label>
                    </th>
                    <th [attr.colspan]=" isCRO ? 5 : 4">
                        Schedule
                    </th>
                    <th colspan="2"></th>
                    <th class="nowrap">
                        <a (click)="toggleInputsExpanded(undefined)" class="toggle-inputs-link">
                            <i class="fa" [ngClass]="inputsExpanded ? 'fa-chevron-up' : 'fa-chevron-down'"
                            [title]="inputsExpanded ? 'Hide inputs' : 'Show inputs'"></i>
                        </a>
                        Default Inputs
                    </th>
                    <th class="nowrap">
                        <a (click)="toggleSampleGroupsExpanded(undefined)" class="toggle-sample-link">
                            <i class="fa" [ngClass]="sampleGroupsExpanded ? 'fa-chevron-up' : 'fa-chevron-down'"
                            [title]="sampleGroupsExpanded ? 'Hide sample group' : 'Show sample group'"></i>
                        </a>
                        Sample Group Details
                    </th>
                    <th>
                        <button (click)="actionCreateSampleGroups(undefined)" class="btn btn-secondary"
                                title="Create Sample Groups">
                            <i class="fa fa-cubes" aria-hidden="true"></i>
                        </button>
                    </th>
                </tr>
                <tr class="detail-grid-header">
                    <th colspan="4"></th>
                    <th>
                        <!-- Tasks Selector -->
                        <div class="dropdown" id="tasksSelectorDropdown">
                            <a class="dropdown-toggle task-selector nowrap"
                            data-toggle="dropdown"
                            role="button"
                            aria-haspopup="true"
                            aria-expanded="false">
                                <span class="position-name">{{tasksSelectorLabel}}</span>
                            </a>
                            <div class="dropdown-menu" style="padding:10px;" onClick="event.stopPropagation();">
                                <a class="dropdown-item" [attr.title]="'Select all tasks'" (click)="selectAll()">Select All</a>
                                <a class="dropdown-item" [attr.title]="'Clear selected tasks'" (click)="clearAll()">Clear All</a>
                                <a [class.disable-delete]="!canRemoveTasks"
                                class="dropdown-item"
                                [attr.title]="canRemoveTasks ? 'Remove all tasks' : 'Tasks with associated workflow data cannot be deleted.'"
                                (click)="bulkRemoveProtocolTask()">Delete Selection</a>
                            </div>
                        </div>
                    </th>
                    <th>
                        <bulk-edit-header
                            [showExtraMenuItems]="true" [headerText]="'Time'"
                            [mainOptionTitle]="'Enter a number to be applied to all tasks.'"
                            (updateClicked)="bulkTimeFromRelativeTaskChanged(undefined)"
                            (menuOpened)="bulkTimeIncremental = false;" #bulkEditHeaderComponent>

                            <ng-container extra-menu-items>
                                <a onClick="event.stopPropagation();"
                                class="dropdown-item"
                                (click)="bulkTimeIncremental = true; bulkEditHeaderComponent.viewBulkEditModal(false);"
                                title="Enter a start number and increment to be applied to each subsequent task.">
                                    Incremental Counter
                                </a>
                            </ng-container>
                            <div modal-content>
                                <div *ngIf="bulkTimeIncremental">
                                    <div class="row" style="min-width: 220px; margin-bottom: 10px;">
                                        <div class="col-6">
                                            Start Number
                                        </div>
                                        <div class="col-6">
                                            <input type="number" [disabled]="readonly" name="bulkTimeStartNumber"
                                                [(ngModel)]="bulkTimeStartNumber" min="0" step="any" required
                                                class="form-control input-small">
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-6">
                                            Increment by
                                        </div>
                                        <div class="col-6">
                                            <input type="number" [disabled]="readonly" name="bulkTimeIncrementNumber"
                                                [(ngModel)]="bulkTimeIncrementNumber" min="1" required step="any"
                                                class="form-control input-small">
                                        </div>
                                    </div>
                                </div>
                                <input *ngIf="!bulkTimeIncremental" [disabled]="readonly" type="number" name="bulkTimeFromRelativeTask"
                                    [(ngModel)]="bulkTimeFromRelativeTask" min="0" step="any"
                                    class="form-control input-small">
                            </div>
                        </bulk-edit-header>
                    </th>
                    <th>
                        <bulk-edit-header [headerText]="'Time Unit'" (updateClicked)="bulkTimeUnitChanged(undefined)">
                            <div modal-content>
                                <select
                                    name="timeUnit"
                                    [(ngModel)]="bulkTimeUnitKey"
                                    class="form-control input-medium"
                                    [disabled]="readonly">
                                    <option [ngValue]="null"></option>
                                    <option *ngFor="let item of timeUnits" [ngValue]="item.C_TimeUnit_key">
                                        {{item.TimeUnit}}
                                    </option>
                                </select>
                            </div>
                        </bulk-edit-header>
                    </th>
                    <th>
                        <bulk-edit-header [headerText]="'Time Relation'" (updateClicked)="bulkTimeRelationChanged(undefined)">
                            <div modal-content>
                                <select
                                    name="bulkTimeRelationKey"
                                    [(ngModel)]="bulkTimeRelationKey"
                                    class="form-control input-medium"
                                    [disabled]="readonly">
                                    <option [ngValue]="null"></option>
                                    <option *ngFor="let item of timeRelations" [ngValue]="item.C_TimeRelation_key">
                                        {{item.TimeRelation}}
                                    </option>
                                </select>
                            </div>
                        </bulk-edit-header>
                    </th>
                    <th>
                        <bulk-edit-header [headerText]="'Relation to'" (updateClicked)="bulkScheduleTypeChanged(undefined)">
                            <div modal-content>
                                <select
                                    name="bulkScheduleTypeKey"
                                    [(ngModel)]="bulkScheduleTypeKey"
                                    class="form-control input-medium"
                                    [disabled]="readonly">
                                    <option [ngValue]="null"></option>
                                    <option *ngFor="let item of scheduleTypes |
                                            orderBy:'SortOrder'" [ngValue]="item.C_ScheduleType_key">
                                        {{item.ScheduleType}}
                                    </option>
                                </select>
                                <select
                                    *ngIf="relativeScheduleTypeKeys[bulkScheduleTypeKey]"
                                    name="bulkRelativeProtocolTaskKey"
                                    [(ngModel)]="bulkRelativeProtocolTaskKey"
                                    class="form-control input-medium mt-1"
                                    [disabled]="readonly">
                                    <option [ngValue]="null"></option>
                                    <option *ngFor="let item of protocolTasks |
                                            orderBy:'SortOrder'" [ngValue]="item.C_ProtocolTask_key">
                                        {{ item.TaskAlias }}
                                    </option>
                                    <ng-container *ngFor="let section of protocol.ChildProtocolTaskSection">
                                        <option [ngValue]="" disabled>{{ section.SectionName }}</option>
                                        <option *ngFor="let item of section.ProtocolTask |
                                            orderBy:'SortOrder'" [ngValue]="item.C_ProtocolTask_key">
                                            {{ item.TaskAlias }}
                                        </option>
                                    </ng-container>
                                </select>
                            </div>
                        </bulk-edit-header>
                    </th>
                    <th *ngIf="isCRO" class="text-nowrap">
                        Study Start
                    </th>
                    <th>&nbsp;</th>
                    <th class="nowrap">
                        Task Name
                        <info-tooltip [text]="'The Task Name can be customized by first selecting the checkbox next to this column.  Unselecting the checkbox will restore the default Task Name.'">
                        </info-tooltip>
                    </th>
                    <th>&nbsp;</th>
                    <th *ngIf="sampleGroupsExpanded" class="full-width">
                        <table class="table-borderless" style="margin-bottom: -2px">
                            <tr>
                                <td class="parent-table-header" [style.width.px]="widths[0]"></td>
                                <td class="parent-table-header" [style.width.px]="widths[1]">Samples</td>
                                <td class="parent-table-header" [style.width.px]="widths[2]">Type</td>
                                <td class="parent-table-header" [style.width.px]="widths[3]">Status</td>
                                <td class="parent-table-header" [style.width.px]="widths[4]">Preservation</td>
                                <td class="parent-table-header" [style.width.px]="widths[5]">Container</td>
                                <td class="parent-table-header" [style.width.px]="widths[6]">Subtype</td>
                                <td class="parent-table-header" [style.width.px]="widths[7]">Processing</td>
                                <td class="parent-table-header nowrap" [style.width.px]="widths[8]">Send To</td>
                                <td class="parent-table-header" [style.width.px]="widths[9]">Analysis</td>
                                <td class="parent-table-header" [style.width.px]="widths[10]">Special Instructions</td>
                            </tr>
                        </table>
                    </th>
                    <th *ngIf="!sampleGroupsExpanded">&nbsp;</th>
                    <th>&nbsp;</th>
                </tr>
            </thead>
            <thead *ngIf="readonly">
                <tr>
                    <th>&nbsp;</th>
                    <th>&nbsp;</th>
                    <th>&nbsp;</th>
                    <th>&nbsp;</th>
                    <th class="nowrap">
                        Task
                    </th>
                    <th [attr.colspan]=" isCRO ? 5 : 4">
                        Schedule
                    </th>
                    <th colspan="2"></th>
                    <th class="nowrap">
                        <a class="toggle-inputs-link" (click)="toggleInputsExpanded()">
                            <i class="fa" [ngClass]="inputsExpanded ? 'fa-chevron-up' : 'fa-chevron-down'"
                            [title]="inputsExpanded ? 'Hide inputs' : 'Show inputs'"></i>
                        </a>
                        Default Inputs
                    </th>
                    <th class="nowrap">
                        <a class="toggle-sample-link" (click)="toggleSampleGroupsExpanded()">
                            <i class="fa" [ngClass]="sampleGroupsExpanded ? 'fa-chevron-up' : 'fa-chevron-down'"
                            [title]="sampleGroupsExpanded ? 'Hide sample group' : 'Show sample group'"></i>
                        </a>
                        Sample Group Details
                    </th>
                    <th></th>
                </tr>
                <tr class="detail-grid-header">
                    <th>&nbsp;</th><th>&nbsp;</th>
                    <th>
                        <!-- Tasks Selector -->
                        Tasks Selected
                    </th>
                    <th>
                        Time
                    </th>
                    <th>
                        Time Unit
                    </th>
                    <th>
                        Time Relation
                    </th>
                    <th>
                        Relation to
                    </th>
                    <th *ngIf="isCRO" class="text-nowrap">
                        Study Start
                    </th>
                    <th>&nbsp;</th>
                    <th class="nowrap">
                        Task Name
                        <info-tooltip [text]="'The Task Name can be customized by first selecting the checkbox next to this column.  Unselecting the checkbox will restore the default Task Name.'">
                        </info-tooltip>
                    </th>
                    <th>&nbsp;</th>
                    <th *ngIf="sampleGroupsExpanded" class="full-width">
                        <table class="table-borderless" style="margin-bottom: -2px">
                            <tr>
                                <td class="parent-table-header" [style.width.px]="widths[0]"></td>
                                <td class="parent-table-header" [style.width.px]="widths[1]">Samples</td>
                                <td class="parent-table-header" [style.width.px]="widths[2]">Type</td>
                                <td class="parent-table-header" [style.width.px]="widths[3]">Status</td>
                                <td class="parent-table-header" [style.width.px]="widths[4]">Preservation</td>
                                <td class="parent-table-header" [style.width.px]="widths[5]">Container</td>
                                <td class="parent-table-header" [style.width.px]="widths[6]">Subtype</td>
                                <td class="parent-table-header" [style.width.px]="widths[7]">Processing</td>
                                <td class="parent-table-header nowrap" [style.width.px]="widths[8]">Send To</td>
                                <td class="parent-table-header" [style.width.px]="widths[9]">Analysis</td>
                                <td class="parent-table-header" [style.width.px]="widths[10]">Special Instructions</td>
                            </tr>
                        </table>
                    </th>
                    <th *ngIf="!sampleGroupsExpanded">&nbsp;</th>
                    <th>&nbsp;</th>
                </tr>
            </thead>
            <tbody
                *ngIf="readonly; else draggableContentParent">
            <!-- BULK FILL DOWN ROW-->
                <ng-container *ngTemplateOutlet="item"></ng-container>
            </tbody>
            <ng-template #draggableContentParent>
                <tbody
                    [dragula]="dragulaBagName"
                    [dragulaModel]="protocolTasks">
                <!-- BULK FILL DOWN ROW-->
                    <ng-container *ngTemplateOutlet="item"></ng-container>
                </tbody>
            </ng-template>
            <ng-template #item>
                <!-- BULK FILL DOWN ROW-->
                <ng-container *ngFor="let protocolTask of protocolTasks | orderBy:'SortOrder'; trackBy: trackRow">
                    <tr *ngIf="isNotDeleted(protocolTask) && protocolTask.C_ProtocolTaskSection_key === null && !taskRowsOpen "
                        [attr.data-key]="protocolTask.C_ProtocolTask_key">
                        <td *ngIf="!readonly" class="icon-cell"></td>

                        <!-- Delete -->
                        <td class="icon-cell" *ngIf="!readonly">
                            <p class="form-control-plaintext">
                                <a [class.disable-delete]="protocolTask.notRemovable"
                                [attr.title]="protocolTask.notRemovable ? 'Tasks with associated workflow data cannot be deleted.' : 'Remove task'">
                                    <i class="fa fa-remove remove-item"
                                    [class.disable-click]="protocolTask.notRemovable"
                                    (click)="removeProtocolTask(protocolTask)"></i>
                                </a>
                            </p>
                        </td>

                        <!-- Drag Handle -->
                        <td class="icon-cell">
                            <i style="margin-top: 4px" class="fa fa-sort text-muted mt-1" [ngClass]="{
                            'draggable': (!anySelected || protocolTask.isSelected) && !readonly,
                            'disabled-draggable-icon': readonly
                        }" title="Drag to reorder"></i>
                        </td>

                        <!-- Select -->
                        <td  class="icon-cell">
                            <input
                                [disabled]="readonly"
                                type="checkbox"
                                name="isSelected_{{protocolTask.SortOrder}}"
                                class="mt-1"
                                [(ngModel)]="protocolTask.isSelected"
                                (ngModelChange)="isSelectedChanged(undefined)" />
                        </td>

                        <td class="text-nowrap">
                            <p class="form-control-plaintext input-medium">
                                {{protocolTask.WorkflowTask?.TaskName}}
                            </p>
                        </td>
                        <td>
                            <input
                                type="number"
                                name="timeFromRelativeTask"
                                [(ngModel)]="protocolTask.TimeFromRelativeTask" (change)="timeChanged(protocolTask)"
                                min="0" step="any" [disabled]="protocolTask.C_TaskGroup_key !== null || readonly"
                                [attr.title]="protocolTask.C_TaskGroup_key !== null ? 'Tasks created using Enhanced Modal cannot be edited' : ''"
                                class="form-control input-small">
                        </td>
                        <td>
                            <select
                                name="timeUnit" [(ngModel)]="protocolTask.C_TimeUnit_key"
                                (change)="timeUnitChanged(protocolTask)"
                                [disabled]="protocolTask.C_TaskGroup_key !== null || readonly"
                                [attr.title]="protocolTask.C_TaskGroup_key !== null ? 'Tasks created using Enhanced Modal cannot be edited' : ''"
                                class="form-control input-medium">
                                <option [ngValue]="null"></option>
                                <option *ngFor="let item of timeUnits" [ngValue]="item.C_TimeUnit_key">
                                    {{item.TimeUnit}}
                                </option>
                            </select>
                        </td>
                        <td>
                            <select
                                name="timeRelation" [(ngModel)]="protocolTask.C_TimeRelation_key"
                                [disabled]="protocolTask.C_TaskGroup_key !== null || readonly"
                                [attr.title]="protocolTask.C_TaskGroup_key !== null ? 'Tasks created using Enhanced Modal cannot be edited' : ''"
                                class="form-control input-medium">
                                <option [ngValue]="null"></option>
                                <option *ngFor="let item of timeRelations" [ngValue]="item.C_TimeRelation_key">
                                    {{item.TimeRelation}}
                                </option>
                            </select>
                        </td>
                        <td>
                            <select
                                [disabled]="protocolTask.RelativeToStudyStart || protocolTask.C_TaskGroup_key !== null || readonly"
                                name="scheduleType"
                                [attr.title]="protocolTask.C_TaskGroup_key !== null ? 'Tasks created using Enhanced Modal cannot be edited' : ''"
                                [(ngModel)]="protocolTask.C_ScheduleType_key" class="form-control input-medium">
                                <option [ngValue]="null"></option>
                                <option *ngFor="let item of scheduleTypes |
                                    orderBy:'SortOrder'" [ngValue]="item.C_ScheduleType_key">
                                    {{item.ScheduleType}}
                                </option>
                            </select>
                            <select
                                *ngIf="relativeScheduleTypeKeys[protocolTask.C_ScheduleType_key]"
                                [disabled]="protocolTask.RelativeToStudyStart || protocolTask.C_TaskGroup_key !== null || readonly"
                                name="relativeProtocolTask"
                                [attr.title]="protocolTask.C_TaskGroup_key !== null ? 'Tasks created using Enhanced Modal cannot be edited' : ''"
                                [(ngModel)]="protocolTask.C_RelativeProtocolTask_key" class="form-control input-medium mt-1"
                                (ngModelChange)="timeRelationChanged(protocolTask)"
                                    >
                                <option [ngValue]="null"></option>
                                <option *ngFor="let item of protocolTasks |
                                            orderBy:'SortOrder'" [ngValue]="item.C_ProtocolTask_key">
                                    {{ item.TaskAlias }}
                                </option>
                                <ng-container *ngFor="let section of protocol.ChildProtocolTaskSection">
                                    <option [ngValue]="" disabled>{{ section.SectionName }}</option>
                                    <ng-container *ngFor="let item of section.ProtocolTask |
                                            orderBy:'SortOrder'">
                                        <option *ngIf="protocolTask.C_ProtocolTask_key !== item.C_ProtocolTask_key"
                                                [ngValue]="item.C_ProtocolTask_key">
                                            {{ item.TaskAlias }}
                                        </option>
                                    </ng-container>
                                </ng-container>
                            </select>
                        </td>
                        <td *ngIf="isCRO" class="col-size-fit">
                            <input
                                type="checkbox"
                                name="_studyStart"
                                [disabled]="protocolTask.C_TaskGroup_key !== null || readonly"
                                title="Start this task with study"
                                [(ngModel)]="protocolTask.RelativeToStudyStart"
                                class="mt-1"/>
                        </td>
                        <td class="col-size-fit">
                            <input
                                type="checkbox"
                                name="_aliasChanged"
                                title="Customize Task Name"
                                [disabled]="protocolTask.C_TaskGroup_key !== null || readonly"
                                [attr.title]="protocolTask.C_TaskGroup_key !== null ? 'Tasks created using Enhanced Modal cannot be edited' : ''"
                                class="mt-1"
                                [(ngModel)]="protocolTask._aliasChanged"
                                (ngModelChange)="taskAliasChanged(protocolTask)" />
                        </td>

                        <td class="text-nowrap right-border">
                            <input type="text" [required]="true" [(ngModel)]="protocolTask.TaskAlias"
                                (change)="taskAliasChanged(protocolTask)" name="taskAlias"
                                class="form-control input-medium"
                                [disabled]="!protocolTask._aliasChanged || protocolTask.C_TaskGroup_key !== null || readonly" />
                        </td>
                        <td>
                            <table *ngIf="inputsExpanded" class="table table-borderless table-sm">
                                <tbody>
                                    <tr *ngFor="let input of protocolTask.WorkflowTask?.Input | orderBy:'SortOrder'">
                                        <td class="text-right">
                                            <label for="pwi-{{protocolTask.C_ProtocolTask_key}}-{{input.C_Input_key}}"
                                                class="col-form-label">{{input.InputName}}</label>
                                        </td>
                                        <td class="full-width">
                                            <data-type-input
                                                *ngIf="mapInputDefault[inputDefaultKey(protocolTask,input)] && isRegularInput(input)"
                                                [id]="'pwi-' + protocolTask.C_ProtocolTask_key + '-' + input.C_Input_key"
                                                [ioObject]="input" [readonly]="readonly"
                                                [(value)]="mapInputDefault[inputDefaultKey(protocolTask,input)].InputValue">
                                            </data-type-input>
                                            <input *ngIf="mapInputDefault[inputDefaultKey(protocolTask,input)] && isDosingTableInput(input)"
                                                type="text"
                                                id="'pwi-' + protocolTask.C_ProtocolTask_key + '-' + input.C_Input_key"
                                                class="form-control input-medium"
                                                [(ngModel)]="input.cv_DosingTable.DosingTable" disabled />
                                            <input *ngIf="mapInputDefault[inputDefaultKey(protocolTask,input)] && isJobCharacteristicInput(input)"
                                                type="text"
                                                id="'pwi-' + protocolTask.C_ProtocolTask_key + '-' + input.C_Input_key"
                                                class="form-control input-medium"
                                                [(ngModel)]="input.cv_JobCharacteristicType.JobCharacteristicType"
                                                disabled />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                        <td>
                            <table *ngIf="sampleGroupsExpanded" class="table table-borderless table-sm">
                                <tbody>
                                    <tr *ngFor="let sample of protocolTask.SampleGroup; let index = index">
                                        <td class="icon-cell child-table-item">
                                            <p class="form-control-plaintext" *ngIf="!readonly">
                                                <a (click)="removeSampleGroup(sample)">
                                                    <i class="fa fa-remove remove-item" title="Remove Sample Group"></i>
                                                </a>
                                            </p>
                                        </td>
                                        <!-- NumSamples -->
                                        <td class="child-table-item">
                                            <input
                                                [(ngModel)]="sample.NumSamples"
                                                type="number"
                                                min="1"
                                                step="1"
                                                [disabled]="readonly"
                                                class="form-control input-small"
                                                name="sampleGroup-{{sample.C_SampleGroup_key}}-numSamples" />
                                        </td>

                                        <!-- Type -->
                                        <td class="child-table-item">
                                            <active-vocab-select
                                                [(model)]="sample.C_SampleType_key"
                                                [readonly]="readonly"
                                                [vocabChoices]="sampleTypes" [keyFormatter]="sampleTypeKeyFormatter"
                                                [optionFormatter]="sampleTypeFormatter" [nullable]="true"
                                                class="input-small">
                                            </active-vocab-select>
                                        </td>

                                        <!-- Status -->
                                        <td class="child-table-item">
                                            <active-vocab-select
                                                [(model)]="sample.C_SampleStatus_key"
                                                [vocabChoices]="sampleStatuses"
                                                [readonly]="readonly"
                                                [keyFormatter]="sampleStatusKeyFormatter"
                                                [optionFormatter]="sampleStatusFormatter" [nullable]="true"
                                                class="input-small">
                                            </active-vocab-select>
                                        </td>

                                        <!-- Preservation -->
                                        <td class="child-table-item">
                                            <active-vocab-select
                                                [(model)]="sample.C_PreservationMethod_key"
                                                [vocabChoices]="preservationMethods"
                                                [readonly]="readonly"
                                                [keyFormatter]="preservationMethodKeyFormatter"
                                                [optionFormatter]="preservationMethodFormatter" [nullable]="true"
                                                class="input-small">
                                            </active-vocab-select>
                                        </td>

                                        <!-- Container Type-->
                                        <td class="child-table-item">
                                            <active-vocab-select
                                                [(model)]="sample.C_ContainerType_key"
                                                [vocabChoices]="containerTypes"
                                                [readonly]="readonly"
                                                [keyFormatter]="containerTypeKeyFormatter"
                                                [optionFormatter]="containerTypeFormatter" [nullable]="true">
                                            </active-vocab-select>
                                        </td>

                                        <!-- Subtype -->
                                        <td class="child-table-item">
                                            <active-vocab-select
                                                [(model)]="sample.C_SampleSubtype_key"
                                                [vocabChoices]="sampleSubtypes"
                                                [readonly]="readonly"
                                                [keyFormatter]="sampleSubtypeKeyFormatter"
                                                [optionFormatter]="sampleSubtypeFormatter" [nullable]="true">
                                            </active-vocab-select>
                                        </td>

                                        <!-- Processing -->
                                        <td class="child-table-item">
                                            <active-vocab-select
                                                [(model)]="sample.C_SampleProcessingMethod_key"
                                                [vocabChoices]="sampleProcessingMethods"
                                                [readonly]="readonly"
                                                [keyFormatter]="sampleProcessingMethodKeyFormatter"
                                                [optionFormatter]="sampleProcessingMethodFormatter" [nullable]="true">
                                            </active-vocab-select>
                                        </td>

                                        <!-- Send To -->
                                        <td class="child-table-item">
                                            <input [(ngModel)]="sample.SendTo" type="text" maxlength="150"
                                                [disabled]="readonly"
                                                class="form-control input-small" name="sampleGroup-sendTo-{{index}}" />
                                        </td>

                                        <!-- Analysis -->
                                        <td class="child-table-item">
                                            <active-vocab-select
                                                [(model)]="sample.C_SampleAnalysisMethod_key"
                                                [readonly]="readonly"
                                                [vocabChoices]="sampleAnalysisMethods"
                                                [keyFormatter]="sampleAnalysisMethodKeyFormatter"
                                                [optionFormatter]="sampleAnalysisMethodFormatter" [nullable]="true">
                                            </active-vocab-select>
                                        </td>

                                        <!-- Special Instructions -->
                                        <td class="child-table-item">
                                            <textarea 
                                                [(ngModel)]="sample.SpecialInstructions"
                                                [disabled]="readonly"
                                                rows="1"
                                                name="sampleGroup-specialInstructions-{{index}}"
                                                maxlength="1000"
                                                class="form-control input-large">
                                            </textarea>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                        <th>&nbsp;</th>
                    </tr>
                </ng-container>
            </ng-template>
        </table>
    </div>
    <div class="col-md-12">
        <ng-container *ngFor="let section of protocol.ChildProtocolTaskSection | orderBy:'SortOrder';  trackBy: trackSection index as i">
            <span *ngIf="!readonly" class="d-flex align-items-center">
                <a (click)="removeProtocolSection(section)" class="icon-cell">
                    <i class="fa fa-remove remove-item" title="Remove section"></i>
                </a>
                <p *ngIf="!section.editingName" class="mb-0 text-bold">
                    {{ section.SectionName }}
                </p>
                <p *ngIf="section.editingName" class="mb-0">
                    <input type="text"
                        [name]="section.SectionName"
                        [disabled]="readonly"
                        [(ngModel)]="section.SectionName"
                        (ngModelChange)="anySectionNameChanged(section)" />
                </p>
                <a *ngIf="!section.editingName" (click)="section.editingName = true" class="edit-section-link icon-cell">
                    <i class="fa fa-pencil" title="Edit Section Name"></i>
                </a>
                <ng-container *ngIf="section.editingName">
                    <i class="fa fa-exclamation-triangle" title="Invalid Section Name"
                    *ngIf="!validSectionName(section)"></i>
                    <a (click)="section.editingName = false" *ngIf="validSectionName(section)" class="icon-cell">
                        <i class="fa fa-check-circle" title="Stop editing"></i>
                    </a>
                </ng-container>
            </span>
            <p *ngIf="readonly" class="text-bold">{{ section.SectionName }}</p>
            <table class="table protocol-task-table"
                droppable [dropDataKey]="section.C_ProtocolTaskSection_key"
                [dropDisabled]="readonly || isDraggedProtocols"
                (drop)="onDropTaskToProtocol($event, section)" [attr.title]="readonly ? '' : 'Drop new tasks here'">
                <thead  *ngIf="!readonly">
                    <tr>
                        <th>&nbsp;</th>
                        <th>&nbsp;</th>
                        <th>&nbsp;</th>
                        <th>&nbsp;</th>
                        <th>
                            <a class="add-item-link detail-grid-button" (click)="addTasksViaModal(section)">
                                <i class="fa fa-plus-circle" title="Add task to section"></i>
                            </a>
                            Task
                        </th>
                        <th [attr.colspan]=" isCRO ? 5 : 4">
                            Schedule
                        </th>
                        <th [attr.colspan]="2"></th>
                        <th class="nowrap">
                            <a (click)="toggleInputsExpanded(section)" class="toggle-inputs-link">
                                <i class="fa" [ngClass]="section.inputsExpanded ? 'fa-chevron-up' : 'fa-chevron-down'"
                                [title]="section.inputsExpanded ? 'Hide inputs' : 'Show inputs'"></i>
                            </a>&nbsp;Default Inputs
                        </th>
                        <th class="nowrap">
                            <a (click)="toggleSampleGroupsExpanded(section)" class="toggle-sample-link">
                                <i class="fa"
                                [ngClass]="section.sampleGroupsExpanded ? 'fa-chevron-up' : 'fa-chevron-down'"
                                [title]="section.sampleGroupsExpanded ? 'Hide sample group' : 'Show sample group'"></i>
                            </a>
                            Sample Group Details
                        </th>
                        <th>
                            <button (click)="actionCreateSampleGroups(section)" class="btn btn-secondary"
                                    title="Create Sample Groups">
                                <i class="fa fa-cubes" aria-hidden="true"></i>
                            </button>
                        </th>
                    </tr>
                    <tr class="detail-grid-header">
                        <th colspan="4"></th>
                        <th>
                            <!-- Tasks Selector -->
                            <div class="dropdown" id="tasksSelectorDropdown">
                                <a class="dropdown-toggle task-selector nowrap"
                                data-toggle="dropdown"
                                role="button"
                                aria-haspopup="true"
                                aria-expanded="false">
                                    <span class="position-name">{{sectionTasksSelectorLabels[section.SortOrder]}}</span>
                                </a>
                                <div class="dropdown-menu" style="padding:10px;" onClick="event.stopPropagation();">
                                    <a class="dropdown-item" [attr.title]="'Select all tasks'" (click)="selectAll(section)">Select All</a>
                                    <a class="dropdown-item" [attr.title]="'Clear selected tasks'" (click)="clearAll(section)">Clear All</a>
                                    <a [class.disable-delete]="!canRemoveTasks"
                                    class="dropdown-item"
                                    [attr.title]="canRemoveTasks ? 'Remove all tasks' : 'Tasks with associated workflow data cannot be deleted.'"
                                    (click)="bulkRemoveProtocolTask(section)">Delete Selection</a>
                                </div>
                            </div>
                        </th>
                        <th>
                            <bulk-edit-header
                                [showExtraMenuItems]="true" [headerText]="'Time'"
                                [mainOptionTitle]="'Enter a number to be applied to all tasks.'"
                                (updateClicked)="bulkTimeFromRelativeTaskChanged(section)"
                                (menuOpened)="bulkTimeIncremental = false;" #bulkEditHeaderComponent>
    
                                <ng-container extra-menu-items>
                                    <a onClick="event.stopPropagation();"
                                    class="dropdown-item"
                                    (click)="bulkTimeIncremental = true; bulkEditHeaderComponent.viewBulkEditModal(false);"
                                    title="Enter a start number and increment to be applied to each subsequent task.">
                                        Incremental Counter
                                    </a>
                                </ng-container>
                                <div modal-content>
                                    <div *ngIf="bulkTimeIncremental">
                                        <div class="row" style="min-width: 220px; margin-bottom: 10px;">
                                            <div class="col-6">
                                                Start Number
                                            </div>
                                            <div class="col-6">
                                                <input
                                                    type="number"
                                                    name="bulkTimeStartNumber"
                                                    [disabled]="readonly"
                                                    [(ngModel)]="bulkTimeStartNumber" min="1" step="any" required
                                                    class="form-control input-small">
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-6">
                                                Increment by
                                            </div>
                                            <div class="col-6">
                                                <input
                                                    type="number"
                                                    [disabled]="readonly"
                                                    name="bulkTimeIncrementNumber"
                                                    [(ngModel)]="bulkTimeIncrementNumber" min="1" required step="any"
                                                    class="form-control input-small">
                                            </div>
                                        </div>
                                    </div>
                                    <input
                                        [disabled]="readonly"
                                        *ngIf="!bulkTimeIncremental"
                                        type="number"
                                        name="bulkTimeFromRelativeTask"
                                        [(ngModel)]="bulkTimeFromRelativeTask" min="0" step="any"
                                        class="form-control input-small">
                                </div>
                            </bulk-edit-header>
                        </th>
                        <th>
                            <bulk-edit-header [headerText]="'Time Unit'" (updateClicked)="bulkTimeUnitChanged(section)">
                                <div modal-content>
                                    <select name="timeUnit"
                                        [disabled]="readonly"
                                        [(ngModel)]="bulkTimeUnitKey"
                                        class="form-control input-medium">
                                        <option [ngValue]="null"></option>
                                        <option *ngFor="let item of timeUnits" [ngValue]="item.C_TimeUnit_key">
                                            {{item.TimeUnit}}
                                        </option>
                                    </select>
                                </div>
                            </bulk-edit-header>
                        </th>
                        <th>
                            <bulk-edit-header [headerText]="'Time Relation'"
                                            (updateClicked)="bulkTimeRelationChanged(section)">
                                <div modal-content>
                                    <select
                                        name="bulkTimeRelationKey"
                                        [(ngModel)]="bulkTimeRelationKey"
                                        [disabled]="readonly"
                                        class="form-control input-medium">
                                        <option [ngValue]="null"></option>
                                        <option *ngFor="let item of timeRelations" [ngValue]="item.C_TimeRelation_key">
                                            {{item.TimeRelation}}
                                        </option>
                                    </select>
                                </div>
                            </bulk-edit-header>
                        </th>
                        <th>
                            <bulk-edit-header [headerText]="'Relation to'"
                                            (updateClicked)="bulkScheduleTypeChanged(section)">
                                <div modal-content>
                                    <select
                                        name="bulkScheduleTypeKey"
                                        [(ngModel)]="bulkScheduleTypeKey"
                                        [disabled]="readonly"
                                        class="form-control input-medium">
                                        <option [ngValue]="null"></option>
                                        <option *ngFor="let item of scheduleTypes |
                                                orderBy:'SortOrder'" [ngValue]="item.C_ScheduleType_key">
                                            {{item.ScheduleType}}
                                        </option>
                                    </select>
                                    <select
                                        *ngIf="relativeScheduleTypeKeys[bulkScheduleTypeKey]"
                                        name="bulkRelativeProtocolTaskKey"
                                        [(ngModel)]="bulkRelativeProtocolTaskKey"
                                        class="form-control input-medium mt-1"
                                        [disabled]="readonly">
                                        <option [ngValue]="null"></option>
                                        <option *ngFor="let item of protocolTasks |
                                                orderBy:'SortOrder'" [ngValue]="item.C_ProtocolTask_key">
                                            {{ item.TaskAlias }}
                                        </option>
                                        <ng-container *ngFor="let section of protocol.ChildProtocolTaskSection">
                                            <option [ngValue]="" disabled>{{ section.SectionName }}</option>
                                            <option *ngFor="let item of section.ProtocolTask |
                                                orderBy:'SortOrder'" [ngValue]="item.C_ProtocolTask_key">
                                                {{ item.TaskAlias }}
                                            </option>
                                        </ng-container>
                                    </select>
                                </div>
                            </bulk-edit-header>
                        </th>
                        <th *ngIf="isCRO" class="nowrap">
                            Study Start
                        </th>
                        <th>&nbsp;</th>
                        <th class="nowrap">
                            Task Name
                            <info-tooltip [text]="'The Task Name can be customized by first selecting the checkbox next to this column.  Unselecting the checkbox will restore the default Task Name.'">
                            </info-tooltip>
                        </th>
                        <th>&nbsp;</th>
                        <th *ngIf="section.sampleGroupsExpanded" class="full-width">
                            <table class="table-borderless">
                                <tr>
                                    <td class="parent-table-header" [style.width.px]="widths[0]"></td>
                                    <td class="parent-table-header" [style.width.px]="widths[1]">Samples</td>
                                    <td class="parent-table-header" [style.width.px]="widths[2]">Type</td>
                                    <td class="parent-table-header" [style.width.px]="widths[3]">Status</td>
                                    <td class="parent-table-header" [style.width.px]="widths[4]">Preservation</td>
                                    <td class="parent-table-header" [style.width.px]="widths[5]">Container</td>
                                    <td class="parent-table-header" [style.width.px]="widths[6]">Subtype</td>
                                    <td class="parent-table-header" [style.width.px]="widths[7]">Processing</td>
                                    <td class="parent-table-header" [style.width.px]="widths[8]">Send To</td>
                                    <td class="parent-table-header" [style.width.px]="widths[9]">Analysis</td>
                                    <td class="parent-table-header" [style.width.px]="widths[10]">Special Instructions</td>
                                </tr>
                            </table>
                        </th>
                        <th *ngIf="!section.sampleGroupsExpanded">&nbsp;</th>
                        <th>&nbsp;</th>
                    </tr>
                </thead>
                <thead  *ngIf="readonly">
                    <tr>
                        <th>&nbsp;</th>
                        <th>&nbsp;</th>
                        <th>&nbsp;</th>
                        <th>&nbsp;</th>
                        <th>
                            Task
                        </th>
                        <th [attr.colspan]=" isCRO ? 5 : 4">
                            Schedule
                        </th>
                        <th [attr.colspan]="2"></th>
                        <th class="nowrap">
                            <a (click)="toggleInputsExpanded(section)" class="toggle-inputs-link">
                                <i class="fa" [ngClass]="section.inputsExpanded ? 'fa-chevron-up' : 'fa-chevron-down'"
                                [title]="section.inputsExpanded ? 'Hide inputs' : 'Show inputs'"></i>
                            </a>&nbsp;Default Inputs
                        </th>
                        <th class="nowrap">
                            <a (click)="toggleSampleGroupsExpanded(section)" class="toggle-sample-link">
                                <i class="fa"
                                [ngClass]="section.sampleGroupsExpanded ? 'fa-chevron-up' : 'fa-chevron-down'"
                                [title]="section.sampleGroupsExpanded ? 'Hide sample group' : 'Show sample group'"></i>
                            </a>
                            Sample Group Details
                        </th>
                        <th>
                        </th>
                    </tr>
                    <tr class="detail-grid-header">
                        <th>&nbsp;</th><th>&nbsp;</th>
                        <!-- <th colspan="4"></th> -->
                        <th>
                            <!-- Tasks Selector -->
                            <span class="position-name">Tasks Selected</span>
                        </th>
                        <th>
                            Time
                        </th>
                        <th>
                            Time Unit
                        </th>
                        <th>
                            Time Relation
                        </th>
                        <th>
                            Relation to
                        </th>
                        <th *ngIf="isCRO" class="nowrap">
                            Study Start
                        </th>
                        <th>&nbsp;</th>
                        <th class="nowrap">
                            Task Name
                            <info-tooltip [text]="'The Task Name can be customized by first selecting the checkbox next to this column.  Unselecting the checkbox will restore the default Task Name.'">
                            </info-tooltip>
                        </th>
                        <th>&nbsp;</th>
                        <th *ngIf="section.sampleGroupsExpanded" class="full-width">
                            <table class="table-borderless">
                                <tr>
                                    <td class="parent-table-header" [style.width.px]="widths[0]"></td>
                                    <td class="parent-table-header" [style.width.px]="widths[1]">Samples</td>
                                    <td class="parent-table-header" [style.width.px]="widths[2]">Type</td>
                                    <td class="parent-table-header" [style.width.px]="widths[3]">Status</td>
                                    <td class="parent-table-header" [style.width.px]="widths[4]">Preservation</td>
                                    <td class="parent-table-header" [style.width.px]="widths[5]">Container</td>
                                    <td class="parent-table-header" [style.width.px]="widths[6]">Subtype</td>
                                    <td class="parent-table-header" [style.width.px]="widths[7]">Processing</td>
                                    <td class="parent-table-header" [style.width.px]="widths[8]">Send To</td>
                                    <td class="parent-table-header" [style.width.px]="widths[9]">Analysis</td>
                                    <td class="parent-table-header" [style.width.px]="widths[10]">Special Instructions</td>
                                </tr>
                            </table>
                        </th>
                        <th *ngIf="!section.sampleGroupsExpanded">&nbsp;</th>
                        <th>&nbsp;</th>
                    </tr>
                </thead>
                <tbody
                    *ngIf="readonly; else draggableContent"
                    >
                    <!-- BULK FILL DOWN ROW-->
                    <ng-container *ngTemplateOutlet="items"></ng-container>
                </tbody>
                <ng-template #draggableContent>
                    <tbody
                        [dragula]="dragulaBagName + section.SectionName"
                        [dragulaModel]="section.ProtocolTask">
                        <!-- BULK FILL DOWN ROW-->
                        <ng-container *ngTemplateOutlet="items"></ng-container>
                    </tbody>
                </ng-template>
    
                <ng-template #items>
                    <ng-container *ngIf="!taskRowsOpen">
                        <tr *ngFor="let protocolTask of section.ProtocolTask | orderBy:'SortOrder'; trackBy: trackRow"
                            [attr.data-key]="protocolTask.C_ProtocolTask_key"
                            [attr.data-section]="protocolTask.C_ProtocolTaskSection_key">
                            <td *ngIf="!readonly" class="icon-cell"></td>
    
                            <!-- Delete -->
                            <td class="icon-cell" *ngIf="!readonly">
                                <p class="form-control-plaintext">
                                    <a [class.disable-delete]="protocolTask.notRemovable"
                                    [attr.title]="protocolTask.notRemovable ? 'Tasks with associated workflow data cannot be deleted.' : 'Remove task'">
                                        <i class="fa fa-remove remove-item" [class.disable-click]="protocolTask.notRemovable"
                                        (click)="removeProtocolTask(protocolTask)"></i>
                                    </a>
                                </p>
                            </td>
    
                            <!-- Drag Handle -->
                            <td class="icon-cell">
                                <i class="fa fa-sort text-muted mt-1" [ngClass]="{
                                'draggable': (!anySelected || protocolTask.isSelected) && !readonly,
                                'disabled-draggable-icon': readonly
                            }" title="Drag to reorder"></i>
                            </td>
    
                            <!-- Select -->
                            <td class="icon-cell">
                                <input
                                    type="checkbox"
                                    name="isSelected_{{protocolTask.SortOrder}}"
                                    class="mt-1"
                                    [disabled]="readonly"
                                    [(ngModel)]="protocolTask.isSelected"
                                    (ngModelChange)="isSelectedChanged(section)" />
                            </td>
    
    
                            <td class="input-medium">
                                <p class="form-control-plaintext input-medium">
                                    {{protocolTask.WorkflowTask?.TaskName}}
                                </p>
                            </td>
                            <td>
                                <input type="number" name="timeFromRelativeTask"
                                    [(ngModel)]="protocolTask.TimeFromRelativeTask"
                                    [attr.title]="protocolTask.C_TaskGroup_key !== null ? 'Tasks created using Enhanced Modal cannot be edited' : ''"
                                    [disabled]="protocolTask.C_TaskGroup_key !== null || readonly"
                                    (change)="timeChanged(protocolTask)"
                                    min="0" step="any" class="form-control input-small">
                            </td>
                            <td>
                                <select name="timeUnit" [(ngModel)]="protocolTask.C_TimeUnit_key"
                                        (change)="timeUnitChanged(protocolTask)"
                                        [attr.title]="protocolTask.C_TaskGroup_key !== null ? 'Tasks created using Enhanced Modal cannot be edited' : ''"
                                        [disabled]="protocolTask.C_TaskGroup_key !== null || readonly"
                                        class="form-control input-medium">
                                    <option [ngValue]="null"></option>
                                    <option *ngFor="let item of timeUnits" [ngValue]="item.C_TimeUnit_key">
                                        {{item.TimeUnit}}
                                    </option>
                                </select>
                            </td>
                            <td>
                                <select name="timeRelation"
                                        [attr.title]="protocolTask.C_TaskGroup_key !== null ? 'Tasks created using Enhanced Modal cannot be edited' : ''"
                                        [disabled]="protocolTask.C_TaskGroup_key !== null || readonly"
                                        [(ngModel)]="protocolTask.C_TimeRelation_key"
                                        class="form-control input-medium">
                                    <option [ngValue]="null"></option>
                                    <option *ngFor="let item of timeRelations" [ngValue]="item.C_TimeRelation_key">
                                        {{item.TimeRelation}}
                                    </option>
                                </select>
                            </td>
                            <td>
                                <select [disabled]="protocolTask.RelativeToStudyStart || protocolTask.C_TaskGroup_key !== null || readonly"
                                        name="scheduleType"
                                        [attr.title]="protocolTask.C_TaskGroup_key !== null ? 'Tasks created using Enhanced Modal cannot be edited' : ''"
                                        [(ngModel)]="protocolTask.C_ScheduleType_key"
                                        class="form-control input-medium">
                                    <option [ngValue]="null"></option>
                                    <option *ngFor="let item of scheduleTypes |
                                        orderBy:'SortOrder'" [ngValue]="item.C_ScheduleType_key">
                                        {{item.ScheduleType}}
                                    </option>
                                </select>
                                <select [disabled]="protocolTask.RelativeToStudyStart || protocolTask.C_TaskGroup_key !== null || readonly"
                                        *ngIf="relativeScheduleTypeKeys[protocolTask.C_ScheduleType_key]"
                                        name="relativeProtocolTask"
                                        [attr.title]="protocolTask.C_TaskGroup_key !== null ? 'Tasks created using Enhanced Modal cannot be edited' : ''"
                                        [(ngModel)]="protocolTask.C_RelativeProtocolTask_key"
                                        class="form-control input-medium mt-1"
                                        (ngModelChange)="timeRelationChanged(protocolTask)"
                                        >
                                    <option [ngValue]="null"></option>
                                    <option *ngFor="let item of protocolTasks |
                                                orderBy:'SortOrder'" [ngValue]="item.C_ProtocolTask_key">
                                        {{ item.TaskAlias }}
                                    </option>
                                    <ng-container *ngFor="let section of protocol.ChildProtocolTaskSection">
                                        <option [ngValue]="" disabled>{{ section.SectionName }}</option>
                                        <ng-container *ngFor="let item of section.ProtocolTask |
                                                orderBy:'SortOrder'">
                                            <option *ngIf="protocolTask.C_ProtocolTask_key !== item.C_ProtocolTask_key"
                                                    [ngValue]="item.C_ProtocolTask_key">
                                                {{ item.TaskAlias }}
                                            </option>
                                        </ng-container>
                                    </ng-container>
                                </select>
                            </td>
                            <td *ngIf="isCRO" class="col-size-fit">
                                <input
                                    type="checkbox" name="_studyStart"
                                    [disabled]="protocolTask.C_TaskGroup_key !== null || readonly"
                                    title="Start this task with study"
                                    [(ngModel)]="protocolTask.RelativeToStudyStart"
                                    class="mt-1"/>
                            </td>
                            <td class="col-size-fit">
                                <input type="checkbox"
                                    [attr.title]="protocolTask.C_TaskGroup_key !== null ? 'Tasks created using Enhanced Modal cannot be edited' : ''"
                                    name="_aliasChanged" title="Customize Task Name"
                                    class="mt-1"
                                    [disabled]="protocolTask.C_TaskGroup_key !== null || readonly"
                                    [(ngModel)]="protocolTask._aliasChanged"
                                    (ngModelChange)="taskAliasChanged(protocolTask)" />
                            </td>
    
                            <td class="text-nowrap right-border">
                                <input
                                    type="text" [required]="true"
                                    [(ngModel)]="protocolTask.TaskAlias"
                                    (change)="taskAliasChanged(protocolTask)"
                                    name="taskAlias"
                                    class="form-control input-medium"
                                    [disabled]="!protocolTask._aliasChanged || protocolTask.C_TaskGroup_key !== null || readonly" />
                            </td>
                            <td>
                                <table *ngIf="section.inputsExpanded" class="table table-borderless table-sm">
                                    <tbody>
                                        <tr *ngFor="let input of protocolTask.WorkflowTask?.Input | orderBy:'SortOrder'">
                                            <td class="text-right">
                                                <label for="pwi-{{protocolTask.C_ProtocolTask_key}}-{{input.C_Input_key}}"
                                                    class="col-form-label">{{input.InputName}}</label>
                                            </td>
                                            <td class="full-width">
                                                <data-type-input
                                                    *ngIf="mapInputDefault[inputDefaultKey(protocolTask,input)] && isRegularInput(input)"
                                                    [id]="'pwi-' + protocolTask.C_ProtocolTask_key + '-' + input.C_Input_key"
                                                    [ioObject]="input" [readonly]="readonly"
                                                    [(value)]="mapInputDefault[inputDefaultKey(protocolTask,input)].InputValue">
                                                </data-type-input>
                                                <input
                                                    *ngIf="mapInputDefault[inputDefaultKey(protocolTask,input)] && isDosingTableInput(input)"
                                                    type="text"
                                                    id="'pwi-' + protocolTask.C_ProtocolTask_key + '-' + input.C_Input_key"
                                                    class="form-control input-medium"
                                                    [(ngModel)]="input.cv_DosingTable.DosingTable" disabled />
                                                <input
                                                    *ngIf="mapInputDefault[inputDefaultKey(protocolTask,input)] && isJobCharacteristicInput(input)"
                                                    type="text"
                                                    id="'pwi-' + protocolTask.C_ProtocolTask_key + '-' + input.C_Input_key"
                                                    class="form-control input-medium"
                                                    [(ngModel)]="input.cv_JobCharacteristicType.JobCharacteristicType"
                                                    disabled />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                            <td>
                                <table *ngIf="section.sampleGroupsExpanded" class="table table-borderless table-sm">
                                    <tbody>
                                        <tr *ngFor="let sample of protocolTask.SampleGroup; let index = index">
                                            <td class="icon-cell child-table-item">
                                                <p class="form-control-plaintext" *ngIf="!readonly">
                                                    <a (click)="removeSampleGroup(sample)">
                                                        <i class="fa fa-remove remove-item" title="Remove Sample Group"></i>
                                                    </a>
                                                </p>
                                            </td>
                                            <!-- NumSamples -->
                                            <td class="child-table-item">
                                                <input [(ngModel)]="sample.NumSamples" type="number" min="1" step="1"
                                                    class="form-control input-small"
                                                    [disabled]="readonly"
                                                    name="sampleGroup-{{sample.C_SampleGroup_key}}-numSamples" />
                                            </td>
    
                                            <!-- Type -->
                                            <td class="child-table-item">
                                                <active-vocab-select
                                                    [(model)]="sample.C_SampleType_key"
                                                    [readonly]="readonly"
                                                    [vocabChoices]="sampleTypes"
                                                    [keyFormatter]="sampleTypeKeyFormatter"
                                                    [optionFormatter]="sampleTypeFormatter" [nullable]="true"
                                                    class="input-small">
                                                </active-vocab-select>
                                            </td>
    
                                            <!-- Status -->
                                            <td class="child-table-item">
                                                <active-vocab-select
                                                    [(model)]="sample.C_SampleStatus_key"
                                                    [vocabChoices]="sampleStatuses"
                                                    [readonly]="readonly"
                                                    [keyFormatter]="sampleStatusKeyFormatter"
                                                    [optionFormatter]="sampleStatusFormatter" [nullable]="true"
                                                    class="input-small">
                                                </active-vocab-select>
                                            </td>
    
                                            <!-- Preservation -->
                                            <td class="child-table-item">
                                                <active-vocab-select
                                                    [(model)]="sample.C_PreservationMethod_key"
                                                    [vocabChoices]="preservationMethods"
                                                    [readonly]="readonly"
                                                    [keyFormatter]="preservationMethodKeyFormatter"
                                                    [optionFormatter]="preservationMethodFormatter" [nullable]="true"
                                                    class="input-small">
                                                </active-vocab-select>
                                            </td>
    
                                            <!-- Container Type-->
                                            <td class="child-table-item">
                                                <active-vocab-select
                                                    [(model)]="sample.C_ContainerType_key"
                                                    [vocabChoices]="containerTypes"
                                                    [readonly]="readonly"
                                                    [keyFormatter]="containerTypeKeyFormatter"
                                                    [optionFormatter]="containerTypeFormatter" [nullable]="true">
                                                </active-vocab-select>
                                            </td>
    
                                            <!-- Subtype -->
                                            <td class="child-table-item">
                                                <active-vocab-select
                                                    [(model)]="sample.C_SampleSubtype_key"
                                                    [vocabChoices]="sampleSubtypes"
                                                    [readonly]="readonly"
                                                    [keyFormatter]="sampleSubtypeKeyFormatter"
                                                    [optionFormatter]="sampleSubtypeFormatter" [nullable]="true">
                                                </active-vocab-select>
                                            </td>
    
                                            <!-- Processing -->
                                            <td class="child-table-item">
                                                <active-vocab-select
                                                    [(model)]="sample.C_SampleProcessingMethod_key"
                                                    [vocabChoices]="sampleProcessingMethods"
                                                    [readonly]="readonly"
                                                    [keyFormatter]="sampleProcessingMethodKeyFormatter"
                                                    [optionFormatter]="sampleProcessingMethodFormatter" [nullable]="true">
                                                </active-vocab-select>
                                            </td>
    
                                            <!-- Send To -->
                                            <td class="child-table-item">
                                                <input [(ngModel)]="sample.SendTo" type="text" maxlength="150"
                                                    [disabled]="readonly"
                                                    class="form-control input-small" name="sampleGroup-sendTo-{{index}}" />
                                            </td>
    
                                            <!-- Analysis -->
                                            <td class="child-table-item">
                                                <active-vocab-select
                                                    [(model)]="sample.C_SampleAnalysisMethod_key"
                                                    [vocabChoices]="sampleAnalysisMethods"
                                                    [readonly]="readonly"
                                                    [keyFormatter]="sampleAnalysisMethodKeyFormatter"
                                                    [optionFormatter]="sampleAnalysisMethodFormatter" [nullable]="true">
                                                </active-vocab-select>
                                            </td>
    
                                            <!-- Special Instructions -->
                                            <td class="child-table-item">
                                                <textarea 
                                                    [(ngModel)]="sample.SpecialInstructions"
                                                    [disabled]="readonly"
                                                    rows="1"
                                                    name="sampleGroup-specialInstructions-{{index}}"
                                                    maxlength="1000"
                                                    class="form-control input-large">
                                                </textarea>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                            <td>&nbsp;</td>
                        </tr>
                    </ng-container>
                </ng-template>
            </table>
        </ng-container>

    </div>
</div>

<div [ngSwitch]="ioObject?.cv_DataType?.DataType"
     [ngClass]="{ 'form-group': inputLabel }">

    <ng-template [ngTemplateOutlet]="inputLabelTmpl"
                 *ngIf="ioObject?.cv_DataType?.DataType !== DataType.OUTPUT_SELECT"></ng-template>

    <ng-container *ngIf="forceInputOnNewLine">
        <br>
    </ng-container>

    <ng-container *ngSwitchCase="DataType.NUMBER">
        <input type="number"
               id="{{id}}"
               [(ngModel)]="_convertedModelValue"
               (ngModelChange)="formatValue()"
               [ngModelOptions]="{ updateOn: emitOnBlur ? 'blur' : 'change' }"
               (change)="updateInputWidth()"
               (focusout)="onBlur();"
               step="any"
               min="{{ioObject.ValidationMin}}"
               max="{{ioObject.ValidationMax}}"
               [required]="ioObject.IsRequired"
               class="form-control"
               [ngClass]="cssClass"
               *ngIf="!readonly"
               [ngStyle]="{
                    'width': inputWidth + 'px'
               }"/>
        <span #inputWidthCheck style="position:absolute; top: -10000px; white-space: nowrap">
            value: {{value}}
        </span>
        <p class="form-control-plaintext"
           *ngIf="readonly">
            {{_convertedModelValue}}
        </p>
    </ng-container>

    <ng-container *ngSwitchCase="DataType.TEXT">
        <input type="text"
               id="{{id}}"
               [(ngModel)]="_convertedModelValue"
               [ngModelOptions]="{ updateOn: emitOnBlur ? 'blur' : 'change' }"
               (ngModelChange)="formatValue()"
               (change)="updateInputWidth()"
               [required]="ioObject.IsRequired"
               class="form-control"
               [ngClass]="cssClass"
               *ngIf="!readonly"
               [ngStyle]="{
                    'width': inputWidth + 'px'
               }"/>
        <span #inputWidthCheck style="position:absolute; top: -10000px; white-space: nowrap">
            value: {{value}}
        </span>
        <p class="form-control-plaintext"
           *ngIf="readonly">
            {{_convertedModelValue}}
        </p>
    </ng-container>

    <ng-container *ngSwitchCase="DataType.LONG_TEXT">
        <textarea rows="{{ioObject.TextLineCount}}"
                  id="{{id}}"
                  [(ngModel)]="_convertedModelValue"
                  [ngModelOptions]="{ updateOn: emitOnBlur ? 'blur' : 'change' }"
                  (ngModelChange)="formatValue()"
                  [required]="ioObject.IsRequired"
                  class="form-control input-large"
                  [ngClass]="cssClass"
                  *ngIf="!readonly"></textarea>

        <p class="form-control-plaintext"
           *ngIf="readonly">
            {{_convertedModelValue}}
        </p>
    </ng-container>

    <ng-container *ngSwitchCase="DataType.DATE">
        <climb-ngb-date #dateControl="ngModel"
                        [id]="id"
                        [(ngModel)]="_convertedModelValue"
                        [ngModelOptions]="{ standalone: true }"
                        (ngModelChange)="formatValue()"
                        [required]="ioObject.IsRequired"
                        *ngIf="!readonly"
                        [container]="container"></climb-ngb-date>

        <p class="form-control-plaintext-Datetime"
           *ngIf="readonly">
            {{_convertedModelValue | formatShortDate}}
        </p>
    </ng-container>

    <ng-container *ngSwitchCase="DataType.DATETIME">
        <div>
            <climb-ngb-date #dateControl="ngModel"
                            [id]="id"
                            [(ngModel)]="_convertedModelValue"
                            [ngModelOptions]="{ standalone: true }"
                            (ngModelChange)="formatValue()"
                            [required]="ioObject.IsRequired"
                            [allowTime]="true"
                            [allowNow]="true"
                            *ngIf="!readonly"
                            [container]="container"></climb-ngb-date>
        </div>
        <p class="form-control-plaintext"
           *ngIf="readonly">
            {{_convertedModelValue | formatShortDateTime}}
        </p>
    </ng-container>

    <p *ngSwitchCase="DataType.BOOLEAN"
       class="form-control-plaintext">
        <input type="checkbox"
               id="{{id}}"
               [disabled]="readonly"
               [(ngModel)]="_convertedModelValue"
               (ngModelChange)="formatValue()" />
    </p>

    <ng-container *ngSwitchCase="DataType.ENUMERATION">
        <select id="{{id}}"
                [(ngModel)]="_convertedModelValue"
                (ngModelChange)="formatValue()"
                (change)="updateSelectWidth(id)"
                [required]="ioObject.IsRequired"
                class="form-control"
                [ngClass]="cssClass"
                *ngIf="!readonly">
            <option></option>
            <option *ngIf="enumerationItems && _convertedModelValue && _convertedModelValue !== ''">{{_convertedModelValue}}</option>
            <ng-container *ngIf="enumerationItems">
                <ng-container *ngFor="let item of enumerationItems">
                    <option *ngIf="item.ItemName !== _convertedModelValue"
                            [value]="item.ItemName">
                        {{item.ItemName}}
                    </option>
                </ng-container>
            </ng-container>
        </select>

        <p class="form-control-plaintext"
           *ngIf="readonly">
            {{_convertedModelValue}}
        </p>
    </ng-container>

    <ng-container *ngSwitchCase="DataType.VOCABULARY">
        <select id="{{id}}"
                [(ngModel)]="_convertedModelValue"
                (ngModelChange)="formatValue()"
                [required]="ioObject.IsRequired"
                class="form-control"
                [ngClass]="cssClass"
                *ngIf="!readonly">
            <option></option>
            <ng-container *ngIf="vocabularyItems">
                <option *ngFor="let item of vocabularyItems"
                        [value]="item.ItemName">
                    {{item.ItemName}}
                </option>
            </ng-container>
        </select>

        <p class="form-control-plaintext"
           *ngIf="readonly">
            {{_convertedModelValue}}
        </p>
    </ng-container>

    <ng-container *ngSwitchCase="DataType.LONG_ENUMERATION"
                  style="min-width: 300px;">
        <climb-typeahead [(model)]="_convertedModelValue"
                         (modelChange)="formatValue()"
                         [id]="id"
                         [required]="ioObject.IsRequired"
                         [resultFormatter]="enumFormatter"
                         [keyFormatter]="enumFormatter"
                         [search]="searchEnumerations"
                         [keySearch]="searchEnumerationsForExactMatch"
                         [namespace]="ioObject.C_EnumerationClass_key + ''"
                         *ngIf="!readonly"></climb-typeahead>

        <p class="form-control-plaintext"
           *ngIf="readonly">
            {{_convertedModelValue}}
        </p>
    </ng-container>

    <input *ngSwitchCase="DataType.CALCULATED"
           type="number"
           tabindex="-1"
           id="{{id}}"
           [ngModel]="_convertedModelValue"
           step="any"
           readonly="readonly"
           class="form-control"
           [ngClass]="cssClass" />

    <input *ngSwitchCase="DataType.INHERITED_MOST_RECENT"
           type="text"
           tabindex="-1"
           id="{{id}}"
           [ngModel]="_convertedModelValue"
           step="any"
           readonly="readonly"
           class="form-control"
           [ngClass]="cssClass" />

    <input *ngSwitchCase="DataType.INHERITED_FIRST_OCCURRENCE"
           type="text"
           tabindex="-1"
           id="{{id}}"
           [ngModel]="_convertedModelValue"
           step="any"
           readonly="readonly"
           class="form-control"
           [ngClass]="cssClass" />

    <input *ngSwitchCase="DataType.INHERITED_SECOND_MOST_RECENT"
           type="text"
           tabindex="-1"
           id="{{id}}"
           [ngModel]="_convertedModelValue"
           step="any"
           readonly="readonly"
           class="form-control"
           [ngClass]="cssClass" />

    <input *ngSwitchCase="DataType.INHERITED_THIRD_MOST_RECENT"
           type="text"
           tabindex="-1"
           id="{{id}}"
           [ngModel]="_convertedModelValue"
           step="any"
           readonly="readonly"
           class="form-control"
           [ngClass]="cssClass" />

    <ng-container *ngSwitchCase="DataType.CV_SELECT">
        <active-vocab-select [(model)]="_convertedModelValue"
                             (modelChange)="formatValue()"
                             [vocabChoices]="vocabChoices"
                             [keyFormatter]="vocabKeyFormatter"
                             [optionFormatter]="vocabNameFormatter"
                             [includeInactive]="true"
                             [nullable]="true"></active-vocab-select>
    </ng-container>

    <ng-container *ngSwitchCase="DataType.CV_MULTISELECT">
        <ng-container *ngIf="ioObject.ControlledVocabulary">
            <climb-multi-select [(model)]="_convertedModelValue"
                                (modelChange)="formatValue()"
                                [values]="vocabChoices"
                                [keyProperty]="'C' + ioObject.ControlledVocabulary.PKColumnName"
                                [valueProperty]="ioObject.ControlledVocabulary.ValueColumnName"></climb-multi-select>
        </ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="DataType.COHORT_SELECT">
        <cohort-select [(model)]="_convertedModelValue"
                       (modelChange)="formatValue()"></cohort-select>
    </ng-container>

    <ng-container *ngSwitchCase="DataType.CONSTRUCT_SELECT">
        <construct-select [(model)]="_convertedModelValue"
                          (modelChange)="formatValue()"></construct-select>
    </ng-container>

    <ng-container *ngSwitchCase="DataType.JOB_SELECT">
        <job-select [(model)]="_convertedModelValue"
                    (modelChange)="formatValue()"></job-select>
    </ng-container>

    <ng-container *ngSwitchCase="DataType.LINE_SELECT">
        <line-select [(model)]="_convertedModelValue"
                     (modelChange)="formatValue()"></line-select>
    </ng-container>

    <ng-container *ngSwitchCase="DataType.STUDY_SELECT">
        <study-select [(model)]="_convertedModelValue"
                      (modelChange)="formatValue()"></study-select>
    </ng-container>

    <ng-container *ngSwitchCase="DataType.TASK_SELECT">
        <task-select [id]="id"
                     [(model)]="_convertedModelValue"
                     [required]="true"
                     (modelChange)="formatValue()"></task-select>
    </ng-container>

    <ng-container *ngSwitchCase="DataType.OUTPUT_SELECT">
        <fieldset class="fieldset-bordered">
            <task-output-select (outputSelectionChange)="onSelectedOutputChange($event)"></task-output-select>
        </fieldset>
    </ng-container>


    <ng-container *ngSwitchCase="DataType.USER_SELECT">
        <user-select [id]="id"
                     [(model)]="_convertedModelValue"
                     (modelChange)="formatValue()"
                     [forceValidSelection]="false"></user-select>
    </ng-container>

</div>

<ng-template #inputLabelTmpl>
    <label for="{{id}}" *ngIf="inputLabel">
        {{inputLabel}}
    </label>
</ng-template>

import { ResourcesSharedModule } from './../resources/resources-shared.module';
import { ViewAddProtocolComponentService } from './add-task/view-add-protocol-component.service';
import { AddProtocolComponent } from './add-task/add-protocol.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { TasksSharedModule } from './tasks-shared.module';
import { DataTypeModule } from './../data-type/data-type.module';
import { ViewAddTaskComponentService } from './add-task/view-add-task-component.service';
import { AddTaskComponent } from './add-task/add-task.component';
import { DetailTaskTableComponent } from './tables/detail-task-table.component';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { ClimbCommonModule } from '../common/climb-common.module';
import { ProtocolsSharedModule } from '../protocol/protocols-shared.module';
import { LocationsSharedModule } from '../locations/locations-shared.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgxPaginationModule,
        ClimbCommonModule,
        DataTypeModule,
        ProtocolsSharedModule,
        TasksSharedModule,
        LocationsSharedModule,
        ResourcesSharedModule
    ],
    declarations: [
        AddTaskComponent,
        AddProtocolComponent,
        DetailTaskTableComponent
    ],
    exports: [
        DetailTaskTableComponent
    ]
})
export class TaskTableModule {
    static forRoot(): ModuleWithProviders<TaskTableModule> {
        return {
          ngModule: TaskTableModule,
          providers: [
            ViewAddTaskComponentService,
            ViewAddProtocolComponentService
          ]
        };
    }
 }

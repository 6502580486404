import type { cv_ContainerType } from './cv-container-type.interface';
import type { cv_MaterialType } from './cv-material-type.interface';

export interface MaterialTypeContainerType {
    C_ContainerType_key: number;
    C_MaterialTypeContainerType_key: number;
    C_MaterialType_key: number;
    C_Workgroup_key: number;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    MaxMaterialCount: number | null;
    ModifiedBy: string;
    Version: number;
    cv_ContainerType: cv_ContainerType;
    cv_MaterialType: cv_MaterialType;
}

import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';
import * as escapeStringRegexp from 'escape-string-regexp';

import { UserService } from '../user/user.service';

interface UserData {
    FirstName: string;
    LastName: string;
    UserName: string;
}

@Component({
    selector: 'user-select',
    template: `
    <div class="user-select">
        <climb-typeahead
            [(model)]="model"
            [resultFormatter]="resultFormatter"
            [keyFormatter]="keyFormatter"
            [search]="searchUsers"
            [keySearch]="searchUsersByKey"
            [id]="id"
            [placeholder]="placeholder"
            [required]="required"
            [namespace]="'user'"
            [forceValidSelection]="forceValidSelection"
            [disabled]="disabled"
            (selectItem)="selectItemHandler($event)"
        ></climb-typeahead>
    </div>
    `,
    styles: [`
        .user-select {
            min-width: 200px;
        }
    `]
})
export class UserSelectComponent implements OnInit {
    @Input() model: any;
    @Input() id: string;
    // Disallow free-text entries?
    @Input() forceValidSelection = true;
    @Input() placeholder: string;
    @Input() required: boolean;
    @Input() useLatestKnownUsers = false;
    @Input() disabled = false;

    @Output() modelChange: EventEmitter<any> = new EventEmitter<any>();

    private _currentUsersPromise: Promise<UserData[]>;
    private _latestKnownUsersPromise: Promise<UserData[]>;

    readonly MAX_RESULTS = 50;


    constructor(
        private userService: UserService
    ) {
        //
    }

    ngOnInit() {
        this._getUsers();
    }

    private async _getUsers() {
        if (!this.useLatestKnownUsers && !this._currentUsersPromise){
            this._currentUsersPromise = this.userService.getWorkgroupUsers()
                .then((data: any[]) => {
                    return data.map( x => <UserData> {
                        FirstName: x.User.FirstName,
                        LastName: x.User.LastName,
                        UserName: x.User.UserName
                    });
                });
        }
        
        if (this.useLatestKnownUsers && !this._latestKnownUsersPromise){
            this._latestKnownUsersPromise = this.userService.getLatestKnownUserDatas();
        }

        const usersPromise =  this.useLatestKnownUsers
            ? this._latestKnownUsersPromise
            : this._currentUsersPromise;
        
        const users = await usersPromise;
        return users || [];
    }

    searchUsers = async (search: string) => {
        const users = await this._getUsers();
        const results = users.filter((choice) => {
            // return match if text is empty
            return !search || this._matchTypeaheadChoice(search, choice);
        });

        return this._limitResults(results);
    }

    private _matchTypeaheadChoice(search: string, choice: UserData): boolean {
        const display = this.resultFormatter(choice);
        const escapedSearch = escapeStringRegexp(search);
        return new RegExp(escapedSearch, 'gi').test(display);
    }

    private _limitResults(results: UserData[]) {
        if (results.length > this.MAX_RESULTS) {
            return results.splice(0, this.MAX_RESULTS);
        } else {
            return results;
        }
    }

    searchUsersByKey = async (key: string) => {
        const users = await this._getUsers();
        const results: any[] = users.filter((choice: any) => {
            return this._matchKeyChoice(key, choice);
        });
        
        // Use the key if no matching results
        if (results.length === 0) {
            results.push(key);
        }

        return results;
    }

    private _matchKeyChoice(search: string, choice: any): boolean {
        const choiceKey = this.keyFormatter(choice);

        return (choiceKey.toLowerCase() === search.toLowerCase());
    }

    selectItemHandler(item: any) {
        this.modelChange.emit(this.model);
    }

    resultFormatter = (item: any) => {
        if (item?.UserName) {
            return item.FirstName +
                ' ' +
                item.LastName +
                ' (' +
                item.UserName +
                ')';
        } else {
            return item;
        }
    }

    keyFormatter = (item: any) => {
        if (item ) {
            return item.UserName;
        } else {
            return item;
        }
    }
}

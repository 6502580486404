<!-- Template for typeahead options -->
<ng-template #sourceResultTemplate let-r="result">
    <span *ngIf="r && r.Animal" class="material-option material-option-animal">
        {{r.Animal.AnimalName}}
    </span>
    <span *ngIf="r && r.Sample" class="material-option material-option-sample">
        {{r.Sample.SampleName}}
    </span>
</ng-template>
<div class="sample-source-single-select">
    <climb-typeahead 
        [(model)]="model"
        (modelChange)="modelChanged()"
        [resultTemplate]="sourceResultTemplate"
        [resultFormatter] = "sourceResultFormatter"
        [keyFormatter] = "sourceKeyFormatter"
        [search] = "searchMaterials"
        [keySearch] = "searchMaterialByKey"
        [namespace] = "'material'"
    ></climb-typeahead>
</div>

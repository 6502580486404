import type { Genotype } from './genotype.interface';
import type { TaskInstance } from './task-instance.interface';

export interface TaskGenotype {
    C_Genotype_key: number;
    C_TaskGenotype_key: number;
    C_TaskInstance_key: number;
    C_Workgroup_key: number;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    Genotype: Genotype;
    ModifiedBy: string;
    Sequence: number;
    TaskInstance: TaskInstance;
    Version: number;
}

import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { DATE_EN_GB, DATE_EN_US } from '@common/util/date-time-formatting/date-format-constants';
import { DateTime } from 'luxon';
import { Injectable } from '@angular/core';
import { FeatureFlagService } from '@services/feature-flags.service';

/**
 * Solution from
 * https://ng-bootstrap.github.io/#/components/datepicker/api#NgbDateParserFormatter
 * 
 * TODO: Replace this with a luxon implementation
 */
@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {
    private dateFormat = DATE_EN_US;

    format(date: NgbDateStruct): string {
        if (date === null) {
            return '';
        }

        const d = DateTime.fromObject(date);
        return d.isValid ? d.toFormat(this.dateFormat) : '';
    }

    parse(value: string): NgbDateStruct {
        if (!value) {
            return null;
        }
        try {
            const d = DateTime.fromFormat(value, this.dateFormat);
            return d.isValid ? { 
                year: d.year, 
                month: d.month, 
                day: d.day 
            } : null;
        } catch {
            return null;
        }

    }
}

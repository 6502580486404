import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export interface ICenterPanelMessage {
    messageSubject: string;
    messageText?: string;
    messageMaps: Record<string, unknown>[];
}

/*
* Service to communicate with messages panel
*/
@Injectable()
export class MessagePanelService {
    
    private openMessageCenterSource = new Subject<void>();
    openMessageCenter$ = this.openMessageCenterSource.asObservable();

    private openNewMessageSource = new Subject<ICenterPanelMessage>();
    openNewMessage$ = this.openNewMessageSource.asObservable();

    openNewMessage(defaultValues: ICenterPanelMessage): void {
        this.openNewMessageSource.next(defaultValues);
        this.openMessageCenterSource.next();
    }

    signalMessagePanelOpen(): void {
        this.openMessageCenterSource.next();
    }
}

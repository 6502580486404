<form role="form" class="form-label-right">

    <div class="modal-header justify-content-center">
        <h4 class="modal-title">{{'Institutions' | translate}} Filter</h4>
    </div>

    <div class="modal-body">

        <div class="form-group row">
            <label class="col-md-3 col-form-label">Name</label>
            <div class="col-md-9">
                <input type="text"
                       name="Name"
                       [(ngModel)]="filter.Name"
                       class="form-control input-medium"
                       data-auto-focus="Name" />
            </div>
        </div>

        <div class="form-group row">
            <label *ngIf="!isCRL" class="col-md-3 col-form-label">Identifier</label>
            <label *ngIf="isCRL" class="col-md-3 col-form-label">Account Number</label>
            <div class="col-md-9">
                <input type="text"
                       name="Identifier"
                       [(ngModel)]="filter.Identifier"
                       class="form-control input-medium" />
            </div>
        </div>

        <div class="form-group row">
            <label *ngIf="!isCRL" class="col-md-3 col-form-label">Account Number</label>
            <label *ngIf="isCRL" class="col-md-3 col-form-label">Client ID</label>
            <div class="col-md-9">
                <input type="text"
                       name="AccountNumber"
                       [(ngModel)]="filter.AccountNumber"
                       class="form-control input-medium" />
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-3 col-form-label">City</label>
            <div class="col-md-9">
                <input type="text"
                       name="City"
                       [(ngModel)]="filter.City"
                       class="form-control input-medium" />
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-3 col-form-label">State</label>
            <div class="col-md-9">
                <climb-multi-select [(model)]="filter.C_State_keys"
                                    [values]="states"
                                    [keyProperty]="'C_State_key'"
                                    [valueProperty]="'StateName'"
                                    [placeholder]="'Select States...'"></climb-multi-select>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-3 col-form-label">Country</label>
            <div class="col-md-9">
                <climb-multi-select [(model)]="filter.C_Country_keys"
                                    [values]="countries"
                                    [keyProperty]="'C_Country_key'"
                                    [valueProperty]="'Country'"
                                    [placeholder]="'Select Countries...'"></climb-multi-select>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-3 col-form-label">Zip Code</label>
            <div class="col-md-9">
                <input type="text"
                       name="PostalCode"
                       [(ngModel)]="filter.PostalCode"
                       class="form-control input-medium" />
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-3 col-form-label">Type</label>
            <div class="col-md-9">
                <climb-multi-select [(model)]="filter.C_InstitutionType_keys"
                                    [values]="institutionTypes"
                                    [keyProperty]="'C_InstitutionType_key'"
                                    [valueProperty]="'InstitutionType'"
                                    [placeholder]="'Select types...'"></climb-multi-select>
            </div>
        </div>

    </div>

    <div class="modal-footer">
        <span class="btn-set">
            <button type="submit"
                    class="btn btn-fw-wide btn-lg btn-primary"
                    (click)="filterClicked($event)">
                Filter
            </button>
            <button data-automation-id="clear-filter-button"
                    type="button"
                    class="btn btn-fw-wide btn-lg btn-secondary"
                    (click)="clearClicked()">
                Clear Filter
            </button>
        </span>
        <span class="btn-set">
            <button type="button"
                    class="btn btn-lg btn-secondary"
                    (click)="closeClicked()">
                Close
            </button>
        </span>
    </div>
</form>

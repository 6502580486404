<climb-auth-container>
    <form [formGroup]="formData">
        <climb-alert-inline *ngIf="errorMessage"
                            [message]="errorMessage"
                            status="error">
        </climb-alert-inline>

        <climb-alert-inline *ngIf="savedSuccessfully">
            An email was sent to {{ formData.get('EmailAddress').value }}, please go to your mailbox and click on the link to complete the registration process.
        </climb-alert-inline>

        <climb-input size="lg" #emailAddress>
            <svg [climbIcon]="icons.mail"></svg>
            <input data-automation-id="email-address-input"
                   type="email"
                   climbInput
                   formControlName="EmailAddress"
                   name="emailAddress"
                   placeholder="Email Address"
                   minlength="6"
                   maxlength="254"
                   required />
            <div climbInputError *hasError="formData.get('EmailAddress'); let errors">
                <ng-template hasErrorCode="minlength">
                    Minimum length is {{ errors.minlength.requiredLength }} characters.
                </ng-template>
                <ng-template hasErrorCode="email">
                    Invalid email address.
                </ng-template>
            </div>
        </climb-input>

        <climb-input size="lg">
            <svg [climbIcon]="icons.user"></svg>
            <input data-automation-id="username-input"
                   type="text"
                   climbInput
                   formControlName="UserName"
                   name="userName"
                   placeholder="Username"
                   minlength="3"
                   maxlength="18"
                   required />
            <div positionEnd>{{ formData.get('UserName').value.length }} / 18</div>
            <div climbInputError
                 *hasError="formData.get('UserName'); errorCode 'minlength'; let error">
                Minimum length is {{ error.requiredLength }} characters.
            </div>
        </climb-input>

        <climb-input size="lg">
            <svg [climbIcon]="icons.padlockClosed"></svg>
            <input data-automation-id="password-input"
                   type="password"
                   climbInput
                   formControlName="Password"
                   name="password"
                   placeholder="Password"
                   minlength="8"
                   required />
            <div climbInputError
                 *hasError="formData.get('Password'); errorCode 'minlength'; let error">
                Minimum length is {{ error.requiredLength }} characters.
            </div>
        </climb-input>

        <climb-input size="lg">
            <svg [climbIcon]="icons.padlockClosed"></svg>
            <input data-automation-id="confirm-password-input"
                   type="password"
                   climbInput
                   formControlName="PasswordConfirm"
                   name="passwordConfirm"
                   placeholder="Confirm Password"
                   required />
            <div climbInputError
                 *hasError="formData.get('PasswordConfirm'); errorCode 'match'">
                Passwords must match.
            </div>
        </climb-input>

        <climb-input size="lg">
            <svg [climbIcon]="icons.secretCode"></svg>
            <input data-automation-id="registration-code-input"
                    type="text"
                   climbInput
                   formControlName="RegistrationCode"
                   name="registrationCode"
                   placeholder="Registration Code"
                   maxlength="16"
                   required />
        </climb-input>

        <button data-automation-id="register-button"
                climbButton
                type="submit"
                variant="primary"
                size="lg"
                (click)="registerEmail()"
                [pending]="sending"
                [disabled]="formData.invalid || savedSuccessfully">
            Register
        </button>
    </form>

    <div class="links">
        <a data-automation-id="return-to-login-link" routerLink="/login">Return to Login</a>
    </div>

    <climb-auth-footer authContainerFooter
                       [version]="buildId">
    </climb-auth-footer>
</climb-auth-container>

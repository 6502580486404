import { JobGroup } from "@common/types";
import { MAX_DOSING_TABLE_N_AMOUNT } from "../components/job-group-table/job-group-table.component";

export const makeUniqueGroupMap = (groups: JobGroup[]) => {
  const groupMap: Record<string, number> = {};
  for (const group of groups) {
    const key = group.Group;
    if (!(key in groupMap)) {
      groupMap[key] = 0;
    }
    groupMap[key] ++;
  }
  return groupMap;
};

export const validateJobGroupsUnique = (groups: JobGroup[]) => {
  const uniqueMap = makeUniqueGroupMap(groups);
  return groups.every(group => {
    if (!group.Group) {
      return false;
    }
    const map = uniqueMap ?? makeUniqueGroupMap(groups);
    return map[group.Group] === 1;
  });
};

export const validateJobGroupExisting = (group: string | undefined | null) => Boolean(group);

export const validateJobGroupsExisting = (groups: JobGroup[]) => groups.every(group => validateJobGroupExisting(group.Group));

export const validateJobGroupNExisting = (groups: JobGroup[]) => groups.every(group => typeof group.Number === 'number');

export const validateJobGroupNMax = (groups: JobGroup[]) => groups.some((group) => group.Number > MAX_DOSING_TABLE_N_AMOUNT);

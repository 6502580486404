/**
 * User configuration of the Task Grid columns
 */
export class TaskGridConfig {
    columns: { [key: string]: TaskGridColumnConfig } = {};
}

/**
 * User configuration of a single Task Grid column
 */
export class TaskGridColumnConfig {
    visible = true;
}

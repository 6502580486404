import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { ImportVocabService } from '../import-vocab.service';

@Component({
    selector: 'import-file-type-select',
    template: `
        <active-vocab-select [(model)]="model"
                             [vocabChoices]="importFileTypes"
                             [keyFormatter]="importFileTypeKeyFormatter"
                             [optionFormatter]="importFileTypeFormatter"
                             [readonly]="readOnly"
                             [nullable]="nullable"
                             (modelChange)="selectItemHandler()"></active-vocab-select>
`
})
export class ImportFileTypeSelectComponent implements OnInit {
    @Input() model: any;
    @Input() readOnly: boolean;
    @Input() nullable: boolean;

    @Output() modelChange: EventEmitter<any> = new EventEmitter<any>();

    importFileTypes: any[];

    constructor(private importVocabService: ImportVocabService) { }

    ngOnInit() {
        this.setupCVs();
    }

    setupCVs(): Promise<any> {
        const cv1 = this.importVocabService.getImportFileTypes()
            .then((data: any[]) => {
                this.importFileTypes = data.filter((item) => {
                    return item.IsSelectable;
                });
            });
        return Promise.all([cv1]);
    }

    // Formatters for active vocab selects
    importFileTypeKeyFormatter = (item: any) => {
        return item.C_ImportFileType_key;
    }
    importFileTypeFormatter = (item: any) => {
        return item.ImportFileTypeTranslated;
    }

    selectItemHandler() {
        this.modelChange.emit(this.model);
    }
}

<div droppable
     [dropDisabled]="facetPrivilege !== 'ReadWrite'"
     (drop)="onDropDevice($event)">

    <!-- Selected devices -->
    <div *ngIf="devices && devices.length > 0">
        <table class="table table-sm table-borderless table-nonfluid">
            <tbody>
                <tr *ngFor="let item of devices; let i = index;">
                    <td class="icon-cell">
                        <a *ngIf="facetPrivilege === 'ReadWrite'"
                           (click)="removeDevice(item)" title="Remove device">
                            <i class="fa fa-remove remove-item"></i>
                        </a>
                    </td>
                    <td>
                        {{item.C_Device_key}}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <!-- Device typeahead -->
    <climb-indirect-typeahead *ngIf="facetPrivilege === 'ReadWrite'"
                              [search]="searchDevices"
                              [resultFormatter]="deviceIdFormatter"
                              placeholder="Device ID..."
                              (selectItem)="selectDevice($event)"></climb-indirect-typeahead>
</div>

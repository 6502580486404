import type { Resource } from './resource.interface';
import type { TaskInstance } from './task-instance.interface';
import type { TaskOutput } from './task-output.interface';
import type { TaskOutputSetMaterial } from './task-output-set-material.interface';

export interface TaskOutputSet {
    C_Resource_key: number | null;
    C_TaskInstance_key: number;
    C_TaskOutputSet_key: number;
    C_Workgroup_key: number;
    CollectionDateTime: Date | null;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    ModifiedBy: string;
    Resource: Resource;
    SortOrder: number | null;
    TaskInstance: TaskInstance;
    TaskOutput: TaskOutput[];
    TaskOutputSetMaterial: TaskOutputSetMaterial[];
    Version: number;
}

import { Injectable } from '@angular/core';
import { defer, Observable } from 'rxjs';

import { EnumerationService } from '../enumerations/enumeration.service';
import { VocabularyService } from '../vocabularies/vocabulary.service';

@Injectable()
export class TaskVocabService {

    // vocab Observables
    public get dataTypes$(): Observable<any[]>{
        return defer(() => this.vocabularyService.getCV('cv_DataTypes'));
    }
    public get enumerationClasses$(): Observable<any[]>{
        return defer(() => this.enumerationService.getEnumerationClasses());
    }
    public get taskTypes$(): Observable<any[]>{
        return defer(() => this.vocabularyService.getCV('cv_TaskTypes', null, null, false));
    }
    public get dosingTableFields$(): Observable<any[]>{
        return defer(() => this.vocabularyService.getCV('cv_DosingTables'));
    }
    public get jobCharacteristicTypeFields$(): Observable<any[]>{
        return defer(() => this.vocabularyService.getCV('cv_JobCharacteristicTypes'));
    }

    constructor(
        private enumerationService: EnumerationService,
        private vocabularyService: VocabularyService
    ) {}
}

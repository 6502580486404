import { ChangeDetectionStrategy, Component } from '@angular/core';
import { topBar } from '@icons';
import { WorkspaceService } from '../../../workspaces/workspace.service';

@Component({
    selector: 'climb-auto-resize',
    templateUrl: 'auto-resize.component.html',
    styleUrls: ['auto-resize.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutoResizeComponent {
    icons = { topBar };

    constructor(private workspaceService: WorkspaceService) {}

    forceAutoSizeFacets() {
        this.workspaceService.forceAutoSizeFacets();
    }
}

import { Injectable } from '@angular/core';
import {
    EntityQuery,
    FilterQueryOp,
    Predicate,
    QueryResult
} from 'breeze-client';

import { notEmpty } from '../common/util/not-empty';
import { getIsActivePredicate } from '../services/queries';

import { DataManagerService } from '../services/data-manager.service';
import { QueryDef } from '../services/query-def';
import { BaseEntityService } from '../services/base-entity.service';

@Injectable()
export class ConstructService extends BaseEntityService {

    readonly ENTITY_TYPE = 'Constructs';
    readonly ENTITY_NAME = 'Construct';

    constructor(
        private dataManager: DataManagerService,
    ) {
        super();
    }

    buildPredicates(filter: any): Predicate[] {
        const predicates: Predicate[] = [];

        if (!filter) {
            return predicates;
        }

        if (filter.ConstructID) {
            predicates.push(Predicate.create('ConstructID', FilterQueryOp.Contains, { value: filter.ConstructID }));
        }

        if (filter.FullName) {
            predicates.push(Predicate.create('FullName', FilterQueryOp.Contains, { value: filter.FullName }));
        }

        if (filter.IsActive) {
            const isActivePredicate: Predicate = getIsActivePredicate(filter.IsActive);
            predicates.push(isActivePredicate);
        }

        return predicates;
    }

    getConstructs(queryDef: QueryDef): Promise<QueryResult> {
        let query = this.buildDefaultQuery(this.ENTITY_TYPE, queryDef);

        let predicates: Predicate[] = [];
        if (queryDef.filter) {
            predicates = predicates.concat(this.buildPredicates(queryDef.filter));

            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed) as Promise<QueryResult>;
    }

    createConstruct(): any {
        const initialValues = { DateCreated: new Date(), IsActive: true };
        return this.dataManager.createEntity(this.ENTITY_NAME, initialValues);
    }

    getConstruct(constructKey: number, expands?: string[]): Promise<any> {
        const query = EntityQuery.from(this.ENTITY_TYPE)
            .where('C_Construct_key', '==', constructKey);

        if (notEmpty(expands)) {
            query.expand(expands.join(','));
        }

        return this.dataManager.returnSingleQueryResult(query);
    }

    getLines(constructKey: number): Promise<any[]> {
        const pred = new Predicate('C_Construct_key', '==', constructKey);

        const query = EntityQuery.from('Lines')
            .where(pred)
            .orderBy('LineName');

        return this.dataManager.returnQueryResults(query);
    }

    isConstructSafeToDelete(construct: any) {
        const pred = new Predicate('C_Construct_key', '==', construct.C_Construct_key);

        const query = EntityQuery.from('Lines')
            .where(pred);

        // safe if there are no Lines
        return this.dataManager.returnQueryCount(query).then((count) => {
            return count === 0;
        });
    }

    deleteConstruct(construct: any) {
        this.dataManager.deleteEntity(construct);
    }

    /**
     * Cancel a newly added construct record
     *
     * @param construct
     */
    cancelConstruct(construct: any) {
        if (!construct) {
            return;
        }

        if (construct.C_Construct_key > 0) {
            this.cancelConstructEdits(construct);
        } else {
            this.cancelNewConstruct(construct);
        }
    }

    private cancelConstructEdits(construct: any) {
        this.dataManager.rejectEntityAndRelatedPropertyChanges(construct);
    }

    private cancelNewConstruct(construct: any) {
        try {
            this.deleteConstruct(construct);
        } catch (error) {
            console.error('Error cancelling new construct: ' + error);
        }
    }

    async ensureVisibleColumnsDataLoaded(constructs: any[], visibleColumns: string[]): Promise<void> {
        const expands = this.generateExpandsFromVisibleColumns(constructs[0], visibleColumns);
        return this.dataManager.ensureRelationships(constructs, expands);
    }
}

import { MatingBulkEditComponent } from './bulkedit/mating-bulk-edit.component';
import { FacetSharedModule } from './../common/facet/facet-shared.module';
import { TaskTableModule } from './../tasks/task-table.module';
import { SearchSharedModule } from './../search/search-shared.module';
import { SamplesSharedModule } from './../samples/samples-shared.module';
import { ReportingSharedModule } from './../reporting/reporting-shared.module';
import { EnumerationsSharedModule } from './../enumerations/enumerations-shared.module';
import { AnimalsSharedModule } from './../animals/animals-shared.module';
import { InjectionToken, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { ClimbCommonModule } from '../common/climb-common.module';
import { PlotlyModule } from '@common/plotly/plotly.module';

import { CharacteristicsSharedModule } from '../characteristics/characteristics-shared.module';
import { ConstructsSharedModule } from '../constructs/constructs-shared.module';
import { GenotypesSharedModule } from '../genotypes/genotypes-shared.module';
import { HousingSharedModule } from '../housing/housing-shared.module';
import { JobsSharedModule } from '../jobs/jobs-shared.module';
import { LinesSharedModule } from '../lines/lines-shared.module';
import { LocationsSharedModule } from '../locations/locations-shared.module';
import { MatingsSharedModule } from './matings-shared.module';
import { UsersSharedModule } from '../user/users-shared.module';
import { VocabulariesSharedModule } from '../vocabularies/vocabularies-shared.module';
import { WorkspacesSharedModule } from '../workspaces/workspaces-shared.module';
import { MatingBulkTemplatesComponent } from './bulkedit';
import { MatingLogicService } from './mating-logic.service';

import {
    MatingDetailComponent,
    MatingFacetComponent,
    MatingFilterComponent,
    MatingLitterSizeCellRendererComponent
} from '.';
import { MatingAnimalTableComponent } from './tables';
import { MatingLitterSizeSparklineComponent } from './mating-litter-size-sparkline.component';
import { MatingLitterSizeChartRendererComponent } from './mating-litter-size-chart-renderer.component';

import {
    MatingBirthTableComponent,
    MatingPlugDateTableComponent
} from './tables';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgbModule,
        ClimbCommonModule,
        FacetSharedModule,
        PlotlyModule,
        AnimalsSharedModule,
        CharacteristicsSharedModule,
        ConstructsSharedModule,
        EnumerationsSharedModule,
        GenotypesSharedModule,
        HousingSharedModule,
        JobsSharedModule,
        LinesSharedModule,
        LocationsSharedModule,
        MatingsSharedModule,
        ReportingSharedModule,
        SamplesSharedModule,
        SearchSharedModule,
        TaskTableModule,
        UsersSharedModule,
        VocabulariesSharedModule,
        WorkspacesSharedModule
    ],
    declarations: [
        MatingAnimalTableComponent,
        MatingBirthTableComponent,
        MatingDetailComponent,
        MatingFacetComponent,
        MatingFilterComponent,
        MatingLitterSizeCellRendererComponent,
        MatingLitterSizeChartRendererComponent,
        MatingLitterSizeSparklineComponent,
        MatingPlugDateTableComponent,
        MatingBulkTemplatesComponent,
        MatingBulkEditComponent
    ],
    exports: [
        // needed by modals
        MatingFilterComponent,
        MatingFacetComponent
    ],
    providers: [
        MatingLogicService,
    ]
})
export class MatingsModule { }

import { SearchQueryDef } from './../search/search-query-def';
import { SearchService } from './../search/search.service';
import {
    Component,
    EventEmitter,
    Input,
    Output
} from '@angular/core';

@Component({
    selector: 'mating-select',
    template: `
    <div class="mating-select">
        <climb-typeahead
            [(model)]="model"
            [resultFormatter]="matingIdFormatter"
            [keyFormatter]="matingKeyFormatter"
            [search]="searchMatings"
            [keySearch]="searchMatingsByKey"  
            [required]="required"
            [namespace]="'mating'"
            (selectItem)="selectItemHandler($event)"
            #typeahead
        ></climb-typeahead>
    </div>
    `,
    styles: [`
        .mating-select {
            min-width: 100px;
        }
    `]
})
export class MatingSelectComponent {
    @Input() model: any;
    @Input() required: boolean;

    @Output() modelChange: EventEmitter<any> = new EventEmitter<any>();

    constructor(
        private searchService: SearchService
    ) {
    }

    selectItemHandler(item: any) {
        this.modelChange.emit(this.model);
    }

    searchMatings = (search: string): Promise<any[]> => {
        const filter = {
            matingID: search,
        };

        const count = 50;

        return this._searchMatings(filter, count);
    }

    searchMatingsByKey = (key: any): Promise<any[]> => {
        const filter = {
            MatingKey: key
        };
        return this._searchMatings(filter, 1);
    }

    _searchMatings(matingFilter: any, count: number): Promise<any[]> {
        const searchQueryDef: SearchQueryDef = {
            entity: 'Matings',
            page: 1,
            size: count,
            sortColumn: 'MatingID',
            sortDirection: 'asc',
            filter: matingFilter
        };

        return this.searchService.getEntitiesBySearch(searchQueryDef)
            .then((queryResult: any) => {
                return queryResult.data;
            });
    }

    matingIdFormatter = (item: any) => {
        return item.MatingID;
    }

    matingKeyFormatter = (item: any) => {
        return item.MatingKey;
    }
}

<div class="detail-form-wrapper">
    <div class="detail-header">
        <h3>IoT Alert Definition</h3>

            <pager-toolbar [itemName]="'IoT Alert Definition'"
                [logTag]="COMPONENT_LOG_TAG"
                [first]="pageState.isFirst"
                [last]="pageState.isLast"
                (previous)="previousClicked($event)"
                (next)="nextClicked($event)"></pager-toolbar>

            <detail-toolbar [facetPrivilege]="facet.Privilege"
                        [logTag]="COMPONENT_LOG_TAG"
                        (exit)="exitClicked($event)">
            </detail-toolbar>
    </div>

    <form
          *ngIf="alertDefinition"
          class="detail-form">
        <fieldset [disabled]="facet.Privilege === 'ReadOnly'">

            <div class="form-group row">
                <label class="col-md-2 col-form-label">Name</label>
                <div class="col-md-10">
                    <input type="text"
                           [(ngModel)]="alertDefinition.AlertDefinitionName"
                           [ngModelOptions]="{standalone: true}"
                           class="form-control input-medium"/>
                </div>
            </div>

            <div class="form-group row">
                <label class="col-md-2 col-form-label">Alert Type</label>
                <div class="col-md-10">
                    <select class="form-control"
                            [(ngModel)]="alertDefinitionType"
                            [ngModelOptions]="{standalone: true}">
                        <option *ngFor="let alertItem of iotAlertDefinitionTypes"
                                [ngValue]="alertItem.value">
                            {{ alertItem.label }}
                        </option>
                    </select>
                </div>
            </div>

            <ng-container *ngIf="isAnomalyDetectionAlert">
                <div class="form-group row">
                    <label class="col-md-2 col-form-label">Sensitivity</label>
                    <div class="col-md-10">
                        <input type="number"
                               min="1"
                               max="10"
                               [(ngModel)]="sensitivityValue"
                               [ngModelOptions]="{standalone: true}"
                               class="form-control input-medium" />
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-md-2 col-form-label">Anomaly Direction</label>
                    <div class="col-md-10">
                        <select class="form-control"
                                [(ngModel)]="anomalyDirectionValue"
                                [ngModelOptions]="{standalone: true}">
                            <option *ngFor="let anomalyDirectionItem of anomalyDirections"
                                    [ngValue]="anomalyDirectionItem.value">
                                {{ anomalyDirectionItem.label }}
                            </option>
                        </select>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-md-2 col-form-label">Run Interval (Seconds)</label>
                    <div class="col-md-10">
                        <input type="number"
                               min="5"
                               step="1"
                               [(ngModel)]="runIntervalSeconds"
                               [ngModelOptions]="{standalone: true}"
                               class="form-control input-medium" />
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-md-2 col-form-label">Window Size (Seconds)</label>
                    <div class="col-md-10">
                        <input type="number"
                               min="5"
                               step="1"
                               [(ngModel)]="windowSizeSeconds"
                               [ngModelOptions]="{standalone: true}"
                               class="form-control input-medium" />
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="isThresholdAlert">
                <div class="form-group row">
                    <label class="col-md-2 col-form-label">Threshold Value</label>
                    <div class="col-md-10">
                        <input type="number"
                               [(ngModel)]="thresholdValue"
                               [ngModelOptions]="{standalone: true}"
                               class="form-control input-medium"/>
                    </div>
                </div>
            </ng-container>

            <div class="form-group row">
                <label class="col-md-2 col-form-label">Device Model</label>
                <div class="col-md-10">
                    <select class="form-control"
                            [(ngModel)]="deviceModel"
                            [ngModelOptions]="{standalone: true}">
                        <ng-container *ngIf="deviceModels">
                            <option *ngFor="let currDeviceModel of deviceModels"
                                    [ngValue]="currDeviceModel">
                                {{ currDeviceModel.DeviceModelName }}
                            </option>
                        </ng-container>
                    </select>
                </div>
            </div>
            <div class="form-group row">
                    <label class="col-md-2 col-form-label">Devices</label>
                    <div class="col-md-10">
                        <alert-device-multiselect
                            [alertDefinition]="alertDefinition"
                            [facetPrivilege]="facet.Privilege"
                        ></alert-device-multiselect>
                    </div>
                </div>

            <div class="form-group row">
                <label class="col-md-2 col-form-label">Device Sensor</label>
                <div class="col-md-10">
                    <select class="form-control"
                            [(ngModel)]="deviceModelOutput"
                            [ngModelOptions]="{standalone: true}">
                        <ng-container *ngIf="deviceModel">
                            <option *ngFor="let currDeviceModelOutput of deviceModel.DeviceModelOutput"
                                    [ngValue]="currDeviceModelOutput">
                                {{ currDeviceModelOutput.OutputName }}
                            </option>
                        </ng-container>
                    </select>
                </div>
            </div>

            <div class="form-group row">
                <label class="col-md-2 col-form-label">Alert Description</label>
                <div class="col-md-10">
                    <textarea class="form-control"
                              rows="3"
                              [(ngModel)]="alertDefinition.AlertDefinitionDescription"
                              [ngModelOptions]="{standalone: true}"></textarea>
                </div>
            </div>

        </fieldset>
    </form>
</div>

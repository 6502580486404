import { LocalStorageKey } from './../config/local-storage-key.enum';
import { Injectable } from '@angular/core';
import { AppInsights } from "applicationinsights-js";

import { PREFIX } from './local-storage.service';

/**
 * Send event tracking messages to Azure App Insights
 */
@Injectable()
export class AppInsightsService {

    /**
     * Track an event with App Insights service
     * @param event 
     * @param properties - optional string properties to track
     */
    trackEvent(
        event: string, 
        properties?: { [name: string]: string}
    ) {
        if (!properties) {
            properties = {};
        }

        properties.workgroupName = this._getWorkgroupName();

        try {
            AppInsights.trackEvent(event, properties);
        } catch (e) {
            // app insight error is non-critical
            //   and should fail silently.
            console.error(e);
        }
        // console.log("tracked '" + event + "'");
    }

    trackException(
        error: Error,  
        properties?: { [name: string]: string}
    ) {

        if (!properties) {
            properties = {};
        }
        
        try {
            // log user info for debugging purposes
            properties.workgroupName = this._getWorkgroupName();
            properties.userName = this ._getUserName();
        } catch (e) {
            // don't want problems with getting user info
            //   to prevent error logging
            console.error(e);
        }

        try {

            AppInsights.trackException(error, null, properties);
        } catch (e) {
            // app insight error is non-critical
            //   and should fail silently.
            console.error(e);
        }
    }

    /**
     * We need AppInsightsService to be dependency free, so
     *   we are duplicating the logic for retrieving workgroup name
     *   for audit purposes
     */
    private _getWorkgroupName(): string {
        const key = PREFIX + LocalStorageKey.WORKGROUP_NAME;
        const value = localStorage.getItem(key);
        const workgroupName = JSON.parse(value);
        return workgroupName || 'unknown';   
    }

    /**
     * We need AppInsightsService to be dependency free, so
     *   we are duplicating the logic for retrieving user name
     *   for audit purposes
     */
    private _getUserName(): string {
        const key = PREFIX + LocalStorageKey.AUTH_DATA;
        const value = localStorage.getItem(key);
        if (!value) {
            return '';
        }
        const authData = JSON.parse(value);
        return (authData == null) ? '' : authData.userName;
    }
}

import type { CalculatedOutputExpression } from './calculated-output-expression.interface';
import type { Input } from './input.interface';

export interface ExpressionInputMapping {
    C_CalculatedOutputExpression_key: number;
    C_ExpressionInputMapping_key: number;
    C_Input_key: number;
    CalculatedOutputExpression: CalculatedOutputExpression;
    ExpressionVariableName: string;
    Input: Input;
}

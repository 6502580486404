import { cv_Taxon } from "./cv-taxon.interface";
import { TaxonCharacteristic } from "./taxon-characteristic.interface";

export interface TaxonCharacteristicTaxon {
    C_TaxonCharacteristicTaxon_key: number;
    C_Taxon_key: number;
    C_TaxonCharacteristic_key: number;
    TaxonCharacteristic: TaxonCharacteristic;
    cv_Taxon: cv_Taxon;
}

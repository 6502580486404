<div class="modal-header justify-content-center">
    <h4 class="modal-title">Health Records Filter</h4>
</div>
<div class="modal-body">
    <form role="form" class="form-label-right">
        <div class="form-group row">
            <label class="col-md-3 col-form-label" for="animalID">Animal ID</label>
            <div class="col-md-9">
                <input type="number" step="any"
                       name="animalID"
                       [(ngModel)]="filter.Identifier"
                       class="form-control  input-medium"
                       data-auto-focus="clinical" />
            </div>
        </div>
        <div class="form-group row">
            <label class="col-md-3 col-form-label" for="animalName">Animal Name</label>
            <div class="col-md-9">
                <input type="text" name="animalName"
                       [(ngModel)]="filter.AnimalName"
                       class="form-control  input-medium" />
            </div>
        </div>
        <div class="form-group row">
            <label class="col-md-3 col-form-label" for="microchipIdentifiers">Microchip ID</label>
            <div class="col-md-4">
                <multi-paste-input [(model)]="filter.microchipIdentifiers" [isNumeric]="false"
                                   [limit]="MULTI_PASTE_INPUT_LIMIT" [rows]="3"></multi-paste-input>
                <small class="form-text text-muted">
                    IDs separated by
                    <code>,</code> or <code>Enter</code>.
                    Limit: {{MULTI_PASTE_INPUT_LIMIT}}.
                </small>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-md-3 col-form-label" for="animalID">Animal External ID</label>
            <div class="col-md-9">
                <input type="text" name="animalExternalID"
                       [(ngModel)]="filter.AnimalExternalID"
                       class="form-control  input-medium" />
            </div>
        </div>
        <div class="form-group row">
            <label class="col-md-3 col-form-label" for="animalStatusKey">Animal Status</label>
            <div class="col-md-9">
                <climb-multi-select [(model)]="filter.C_AnimalStatus_keys"
                                    [values]="animalStatuses"
                                    [keyProperty]="'C_AnimalStatus_key'"
                                    [valueProperty]="'AnimalStatus'"
                                    [placeholder]="'Select statuses...'"></climb-multi-select>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-md-3 col-form-label">Urgent</label>
            <div class="col-md-9">
                <urgent-status-select [(model)]="filter.IsUrgent"
                                      [nullable]="true"></urgent-status-select>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-md-3 col-form-label" *ngIf="isGLP">Clinical Observations</label>
            <label class="col-md-3 col-form-label" *ngIf="!isGLP">Observation</label>
            <div class="col-md-9">
                <climb-multi-select [(model)]="filter.C_ClinicalObservation_keys"
                                    [values]="clinicalObservations"
                                    [keyProperty]="'C_ClinicalObservation_key'"
                                    [valueProperty]="'ClinicalObservation'"
                                    [placeholder]="'Select observations...'"></climb-multi-select>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-md-3 col-form-label" *ngIf="isGLP">Clinical Observation Status</label>
            <label class="col-md-3 col-form-label" *ngIf="!isGLP">Observation Status</label>
            <div class="col-md-9">
                <climb-multi-select [(model)]="filter.C_ClinicalObservationStatus_keys"
                                    [values]="clinicalObservationStatuses"
                                    [keyProperty]="'C_ClinicalObservationStatus_key'"
                                    [valueProperty]="'ClinicalObservationStatus'"
                                    [placeholder]="'Select statuses...'"></climb-multi-select>
            </div>
        </div>
        <div class="form-group row" *ngIf="isGLP">
            <label class="col-md-3 col-form-label">Diagnostic Observation</label>
            <div class="col-md-9">
                <climb-multi-select [(model)]="filter.C_DiagnosticObservation_keys"
                                    [values]="diagnosticObservations"
                                    [keyProperty]="'C_ClinicalObservation_key'"
                                    [valueProperty]="'ClinicalObservation'"
                                    [placeholder]="'Select diagnostic observations...'"></climb-multi-select>
            </div>
        </div>
        <div class="form-group row" *ngIf="isGLP">
            <label class="col-md-3 col-form-label">Diagnostic Observation Status</label>
            <div class="col-md-9">
                <climb-multi-select [(model)]="filter.C_DiagnosticObservationStatus_keys"
                                    [values]="clinicalObservationStatuses"
                                    [keyProperty]="'C_ClinicalObservationStatus_key'"
                                    [valueProperty]="'ClinicalObservationStatus'"
                                    [placeholder]="'Select diagnostic statuses...'"></climb-multi-select>
            </div>
        </div>
        <div *ngIf="!isGLP" class="form-group row">
            <label class="col-md-3 col-form-label" for="observedBy">Observed By</label>
            <div class="col-md-9">
                <select name="observedBy"
                        class="form-control  input-medium"
                        [(ngModel)]="filter.C_Resource_key">
                    <option></option>
                    <option *ngFor="let item of resources"
                            [value]="item.C_Resource_key">
                        {{item.ResourceName}}
                    </option>
                </select>
            </div>
        </div>
        <div *ngIf="isGLP" class="form-group row">
            <label class="col-md-3 col-form-label">Observed By</label>
            <div class="col-md-9">
                <user-select  [(model)]="filter.ObservedByUsername"
                             [forceValidSelection]="false"></user-select>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-md-3 col-form-label"
                   for="animalHealthTech">Animal Health Tech</label>
            <div class="col-md-9">
                <select name="animalHealthTech"
                        class="form-control  input-medium"
                        [(ngModel)]="filter.C_AssignedTo_key">
                    <option></option>
                    <option *ngFor="let item of resources"
                            [value]="item.C_Resource_key">
                        {{item.ResourceName}}
                    </option>
                </select>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-md-3 col-form-label">Observed<br />Date Range</label>
            <div class="col-md-9 d-flex flex-wrap flex-gap-1">
                <climb-ngb-date #dateControl="ngModel" name="Observed Start" [(ngModel)]="filter.DateObservedStart" [placeholder]="'From&hellip;'"></climb-ngb-date>
                <climb-ngb-date #dateControl="ngModel" name="Observed End" [(ngModel)]="filter.DateObservedEnd" [placeholder]="'To&hellip;'"></climb-ngb-date>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-md-3 col-form-label">Due<br />Date Range</label>
            <div class="col-md-9 d-flex flex-wrap flex-gap-1">
                <climb-ngb-date #dateControl="ngModel" name="Due Date Start" [(ngModel)]="filter.DateDueStart" [placeholder]="'From&hellip;'"></climb-ngb-date>
                <climb-ngb-date #dateControl="ngModel" name="Due Date End" [(ngModel)]="filter.DateDueEnd" [placeholder]="'To&hellip;'"></climb-ngb-date>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-md-3 col-form-label">Body Condition Score</label>
            <div class="col-md-9">
                <climb-multi-select [(model)]="filter.C_BodyConditionScore_keys"
                                    [values]="bodyConditionScores"
                                    [keyProperty]="'C_BodyConditionScore_key'"
                                    [valueProperty]="'BodyConditionScore'"
                                    [placeholder]="'Select values...'"></climb-multi-select>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-md-3 col-form-label">{{'Line' | translate}}</label>
            <div class="col-md-9">
                <line-multiselect [(model)]="filter.lines"></line-multiselect>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-md-3 col-form-label">{{'Job' | translate}}</label>
            <div class="col-md-9">
                <input type="text" name="jobID"
                       [(ngModel)]="filter.JobID"
                       class="form-control  input-medium" />
            </div>
        </div>

        <br />
        <div class="modal-footer">
            <span class="btn-set">
                <button type="submit"
                        class="btn btn-fw-wide btn-lg btn-primary"
                        (click)="filterClicked($event)">
                    Filter
                </button>
                <button type="button"
                        class="btn btn-fw-wide btn-lg btn-secondary"
                        (click)="clearClicked()">
                    Clear Filter
                </button>
            </span>
            <span class="btn-set">
                <button type="button"
                        class="btn btn-lg btn-secondary"
                        (click)="closeClicked()">
                    Close
                </button>
            </span>
        </div>
    </form>
</div>

import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChildren,
} from '@angular/core';

import { BaseFilter } from '../common/facet';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
    focusElementByQuery,
} from '../common/util/';
import { VocabularyService } from '../vocabularies/vocabulary.service';
import { NgModel } from '@angular/forms';
import { LoggingService } from '@services/logging.service';
import { dateControlValidator } from '@common/util/date-control.validator';


@Component({
    selector: 'census-filter',
    templateUrl: './census-filter.component.html'
})
export class CensusFilterComponent extends BaseFilter implements OnInit {
    @ViewChildren('dateControl') dateControls: NgModel[];
    @Input() filter: any = {};
    @Output() onFilter = new EventEmitter<any>();
    @Output() close = new EventEmitter<any>();
    censusStatuses: any[] = [];

    readonly MULTI_PASTE_INPUT_LIMIT = 100;

    constructor(
        private activeModal: NgbActiveModal,
        private vocabularyService: VocabularyService,
        private loggingService: LoggingService,
    ) {
        super(activeModal);
    }

    ngOnInit() {
        this.cloneFilter();

        this.getCVs();
        
        focusElementByQuery('[data-auto-focus="censusID"]');
    }

    private cloneFilter() {
        const dateStart = this.filter.DateStart;
        const dateEnd = this.filter.DateEnd;

        this.filter = this.copyFilter(this.filter);

        this.filter.DateStart = dateStart;
        this.filter.DateEnd = dateEnd;
    }

    filterClicked(event: Event) {
        const errMessage = dateControlValidator(this.dateControls);
        if (errMessage) {
            this.loggingService.logError(errMessage, null, '', true);
            return;
        }
        event.preventDefault();
        this.onFilter.emit(this.filter);
        this.close.emit();
        this.activeModal.close();
    }

    closeClicked() {
        this.close.emit();
        this.activeModal.dismiss();
    }

    clearClicked() {
        this.filter = {};
        this.onFilter.emit(this.filter);
        this.close.emit();
        this.activeModal.close();
    }

    private getCVs() {
        this.vocabularyService.getCV('cv_CensusStatuses').then((data) => {
            this.censusStatuses = data;
        });
    }
}

import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, of, Subscription } from 'rxjs';
import { catchError, delay, map, switchMap, tap } from 'rxjs/operators';
import { AuthService } from '@services/auth.service';
import { RoutingService } from '../../../routing/routing.service';
import { buildId } from '@config';
import { phone, user } from '@icons';

@Component({
    selector: 'email-verification-view',
    templateUrl: './email-verification-view.component.html',
    styleUrls: ['./email-verification-view.component.scss'],
})
export class EmailVerificationViewComponent implements OnDestroy {
    icons = { phone, user };
    buildId: string = buildId;
    errorMessage  = '';
    isProcessCompletedSuccessfully = false;

    subscriptions: Subscription = new Subscription();

    formData: FormGroup = this.fb.group({
        SystemId: [''],
        UserId: [0],
        FirstName: ['', [Validators.required, Validators.maxLength(18)]],
        LastName: ['', [Validators.required]],
        EmailAddress: [''],
        Extension: [''],
        CountryCode: [''],
        PhoneNumber: ['', [Validators.minLength(10), Validators.maxLength(24)]],
        UserName: [''],
        Password: [''],
        PasswordConfirm: [''],
        PhoneNumberTypeId: [1],
        TermsAccepted: [false, [Validators.requiredTrue]],
        JobTitle: [''],
        NameSuffix: [''],
        SalutationTypeId: [1],
        RegistrationCode: [''],
        SendTextMessages: [false],
    });

    emailVerificationCode$: Observable<string> = this.route.queryParamMap.pipe(
        map((params: ParamMap) => params.get('EmailVerificationCode')),
    );
    isEmailVerified$: Observable<boolean> = this.emailVerificationCode$.pipe(
        switchMap((emailVerificationCode) => this.authService.verificationEmail(emailVerificationCode)),
        map(() => true),
        catchError((error) => {
            const errorsString = this.authService.authErrorsToString(error);
            this.errorMessage = error.Message ?? `We're sorry, the registration code is expired. ${errorsString}`;
            return of(false);
        }),
    );

    constructor(
        private route: ActivatedRoute,
        private fb: FormBuilder,
        private authService: AuthService,
        private routingService: RoutingService,
    ) { }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    completeRegistration(): void {
        this.errorMessage = '';

        const completeRegistration$ = this.emailVerificationCode$.pipe(
            switchMap((emailVerificationCode) => this.authService.completeRegistration(this.formData.value, emailVerificationCode)),
            tap(() => this.isProcessCompletedSuccessfully = true),
            delay(5000), // waits for 5 sec. before redirecting to the Login page
            tap(() => this.routingService.navigateToLogin()),
            catchError((error) => {
                const errorsString = this.authService.authErrorsToString(error);
                this.errorMessage = error.Message ?? `We're sorry, but we could not complete your registration: ${errorsString}`;
                return of(false);
            }),
        );
        this.subscriptions.add(completeRegistration$.subscribe());
    }
}

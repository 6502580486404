import { ArchwizardModule } from 'angular-archwizard';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { ClimbCommonModule } from '../common/climb-common.module';
import { ClimbServicesModule } from '../services/climb-services.module';
import { WizardSharedModule } from './wizard-shared.module';
import { SeedDataWizardComponent } from './seed-data-wizard.component';
import { WizardFooterComponent } from './wizard-footer.component';
import { WorkgroupInitializerComponent } from './workgroup-initializer.component';
import {
    CharacteristicTaxonFilterPipe,
    LineTaxonFilterPipe
} from './pipes';
import {
    ChooseContainerTypesComponent,
    ChooseCvValuesComponent,
    ChooseLinesComponent,
    ChooseTaxaComponent,
    ChooseTaxonCharacteristicsComponent,
    ConfirmSaveComponent,
    SeedFinishComponent,
    SeedStartComponent,
    SetNameFormatComponent
} from './steps';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgbModule,
        ClimbCommonModule,
        ClimbServicesModule,
        ArchwizardModule,
        WizardSharedModule,
    ],
    declarations: [
        SeedDataWizardComponent,
        CharacteristicTaxonFilterPipe,
        LineTaxonFilterPipe,
        ChooseContainerTypesComponent,
        ChooseCvValuesComponent,
        ChooseLinesComponent,
        ChooseTaxaComponent,
        ChooseTaxonCharacteristicsComponent,
        ConfirmSaveComponent,
        SeedFinishComponent,
        SeedStartComponent,
        SetNameFormatComponent,
        WizardFooterComponent,
        WorkgroupInitializerComponent
    ],
    exports: [
        WorkgroupInitializerComponent
    ],
    providers: [
    ]
})
export class WizardModule { }

import type { Device } from './device.interface';
import type { DeviceDerivedOutput } from './device-derived-output.interface';
import type { DeviceModelOutput } from './device-model-output.interface';

export interface DeviceModel {
    C_DeviceModel_key: string;
    Device: Device[];
    DeviceDerivedOutput: DeviceDerivedOutput[];
    DeviceModelName: string;
    DeviceModelOutput: DeviceModelOutput[];
    MessageIntervalSeconds: number;
}

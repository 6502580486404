import {
    Component,
    Input,
    OnChanges,
    OnInit,
    OnDestroy,
    ViewChildren
} from '@angular/core';

import { AnimalService } from '../../../animals/services/animal.service';
import { HousingVocabService } from '../../services/housing-vocab.service';
import { MaterialPoolService } from '../../../services/material-pool.service';
import { Subscription } from 'rxjs';
import { ConfirmOptions, ConfirmService } from '../../../common/confirm';
import { uniqueArray } from '../../../common/util';
import { NgModel } from '@angular/forms';
import { dateControlValidator } from '@common/util/date-control.validator';

@Component({
    selector: 'housing-details',
    templateUrl: './housing-details.component.html',
})
export class HousingDetailsComponent implements OnChanges, OnInit, OnDestroy {
    @ViewChildren('dateControl') dateControls: NgModel[];
    @Input() facetPrivilege: string;
    // MaterialPoolMaterial records
    @Input() materialPoolMaterials: any[];

    animalStatuses: any[];
    exitReasons: any[];
    bulkAnimalStatusKey: any;
    bulkExitReasonKey: any;
    bulkDateIn: Date;
    bulkDateOut: Date;
    _subs: Subscription[] = [];
    materialPoolMaterialOriginalDateOuts: any[] = [];

    constructor(
        private animalService: AnimalService,
        private housingVocabService: HousingVocabService,
        private materialPoolService: MaterialPoolService,
        private confirmService: ConfirmService,

    ) {
        // nothing to do
    }

    ngOnInit() {
        this.initialize();
    }

    ngOnDestroy() {
        for (const s of this._subs) {
            s.unsubscribe();
        }
    }

    ngOnChanges(changes: any) {
        if (changes.materialPoolMaterials && !changes.materialPoolMaterials.firstChange) {
            this.initialize();
        }
        this.updateDateÓuts();
    }

    initialize() {
        this.animalStatuses = [];

        this.setupCVs();

        this.bulkDateIn = new Date();
        this.bulkDateOut = new Date();

        this.updateDateÓuts();
    }

    updateDateÓuts() {
        this.materialPoolMaterialOriginalDateOuts = [];
        this.materialPoolMaterials.map((materialPoolMaterial) => {
            if (materialPoolMaterial.Material.Animal) {
                this.materialPoolMaterialOriginalDateOuts.push({
                    materialPoolMaterialKey: materialPoolMaterial.C_MaterialPoolMaterial_key,
                    dateOut: materialPoolMaterial.DateOut
                });
            }
        });
    }

    setupCVs() {
        this.housingVocabService.animalStatuses$.subscribe((data) => {
            this.animalStatuses = data;
        });

        this.housingVocabService.exitReasons$.subscribe((data) => {
            this.exitReasons = data;
        });
    }

    // Changes
    animalStatusChanged(animal: any) {
        this.animalService.statusChangePostProcess(animal);
    }

    bulkAnimalStatusChanged() {
        const changedAnimals: any[] = [];
        for (const materialAssoc of this.materialPoolMaterials) {
            changedAnimals.push(materialAssoc.Material.Animal);
        }
        this.animalService.statusBulkChangePostProcess(changedAnimals, this.bulkAnimalStatusKey).then(() => {
            this.updateDateÓuts();
        });
    }

    getMaterialOriginalDateOut(materialPoolMaterialKey: any) {
        const materialPoolMaterialBeforeChange = this.materialPoolMaterialOriginalDateOuts.find((item: any) => {
            return item.materialPoolMaterialKey === materialPoolMaterialKey;
        });
        return materialPoolMaterialBeforeChange.dateOut;
    }

    updateMaterialOriginalDateOut(materialPoolMaterialKey: any, dateOut: any) {
        const materialPoolMaterial = this.materialPoolMaterialOriginalDateOuts.find((item: any) => {
            return item.materialPoolMaterialKey === materialPoolMaterialKey;
        });
        return materialPoolMaterial.dateOut = dateOut;
    }

    bulkDateOutChanged() {
        this.updateDateÓuts();

        const forLoop = async (skip: boolean) => {
            for (const materialAssoc of this.materialPoolMaterials) {
                materialAssoc.DateOut = this.bulkDateOut;
                this.dateOutChanged(materialAssoc, true, skip);
            }
        };

        if (this.bulkDateOut === null) {
            const animalNames: any[] = [];
            for (const materialAssoc of this.materialPoolMaterials) {
                if (materialAssoc.Material.Animal.cv_AnimalStatus.IsExitStatus) {
                    animalNames.push(materialAssoc.Material.Animal.AnimalName);
                }
            }
            const names = uniqueArray(animalNames).join(', ');
            if (names) {
                let message = `${animalNames.length} of these animals have an end-state status. You must change the animal's statuses to a non-end-state option.`;
                if (message.length === 1) {
                    message = message.replace("have", "has");
                    message = message.replace("statuses", "status");
                }
                this.confirmService.confirm(
                    {
                        title: "Animal's status",
                        message,
                        yesButtonText: 'Skip Animals',
                        noButtonText: 'Cancel',
                        titleDetails: 'Animals: ',
                        details: [
                            names
                        ]
                    }
                ).then(
                    () => {
                        // Yes                           
                        return forLoop(true);
                    },
                    () => {
                        // No
                    }); 
            } else {
                forLoop(false);
            }
        } else {
            forLoop(false);
        }
    }

    dateOutChanged(materialAssoc: any, skip = false, animalsInManyHousings = true) {
        // animal added
        if (materialAssoc.DateOut == null) {
            if (materialAssoc.Material.Animal.cv_AnimalStatus.IsExitStatus) {
                const dateOut = this.getMaterialOriginalDateOut(materialAssoc.C_MaterialPoolMaterial_key);
                materialAssoc.DateOut = dateOut ? new Date(dateOut) : new Date();

                if (!skip) {
                    const message = "The animal '" + materialAssoc.Material.Animal.AnimalName + "' has an end-state status. You must change the animal's status to a non-end-state option.";

                    const confirmOptions: ConfirmOptions = {
                        title: "Animal´s status",
                        message,
                        yesButtonText: 'OK',
                        onlyYes: true
                    };
                    this.confirmService.confirm(confirmOptions);
                }
            } else {
                const materialInSameHousing = materialAssoc.Material.MaterialPoolMaterial.filter((item: any) => {
                    return item.C_Material_key === materialAssoc.C_Material_key && item.DateOut == null && item.C_MaterialPool_key === materialAssoc.C_MaterialPool_key;
                });
                if (materialInSameHousing.length > 1) {
                    // animal already in the same housing
                    materialAssoc.DateOut = new Date(this.getMaterialOriginalDateOut(materialAssoc.C_MaterialPoolMaterial_key));
                } else {
                    const materialInOthersHousings = materialAssoc.Material.MaterialPoolMaterial.filter((item: any) => {
                        return item.C_Material_key === materialAssoc.C_Material_key && item.DateOut == null && item.C_MaterialPool_key !== materialAssoc.C_MaterialPool_key;
                    });
                    if (materialInOthersHousings.length > 0) {
                        // animal already in other housing
                        const message = "Animal '" + materialAssoc.Material.Animal.AnimalName + "' currently resides within Housing Unit '" + materialInOthersHousings[0].MaterialPool.MaterialPoolID + "' are you sure you want to move this animal?";

                        if (!animalsInManyHousings) {
                            this.confirmService.confirm(
                                {
                                    title: "Confirm default end state",
                                    message,
                                    yesButtonText: "Yes",
                                    noButtonText: "No"
                                }
                            ).then(
                                () => {
                                    // Yes                           
                                    materialInOthersHousings.forEach((item: any) => {
                                        if (item.C_MaterialPool_key !== materialAssoc.C_MaterialPool_key) {
                                            item.DateOut = new Date(this.getMaterialOriginalDateOut(materialAssoc.C_MaterialPoolMaterial_key));
                                            this.animalService.setHousingEndState(item.MaterialPool.MaterialPoolMaterial, item.MaterialPool);
                                        }
                                    });
                                    this.updateMaterialOriginalDateOut(materialAssoc.C_MaterialPoolMaterial_key, materialAssoc.DateOut);
                                },
                                () => {
                                    // No
                                    materialAssoc.DateOut = new Date(this.getMaterialOriginalDateOut(materialAssoc.C_MaterialPoolMaterial_key));
                                });
                        }
                    }
                }
            }
        } else {
            // animal removed, check if was already remove
            if (this.getMaterialOriginalDateOut(materialAssoc.C_MaterialPoolMaterial_key) === null) {
                this.animalService.setHousingEndState(this.materialPoolMaterials, this.materialPoolMaterials[0].MaterialPool);
            }
            this.updateMaterialOriginalDateOut(materialAssoc.C_MaterialPoolMaterial_key, materialAssoc.DateOut);
        }
    }

    bulkExitReasonChanged() {
        for (const materialAssoc of this.materialPoolMaterials) {
            materialAssoc.Material.Animal.C_ExitReason_key = this.bulkExitReasonKey;
        }
    }

    bulkDateInChanged() {
        for (const materialAssoc of this.materialPoolMaterials) {
            materialAssoc.DateIn = this.bulkDateIn;
        }
    }

    deleteMaterialAssoc(material: any) {
        this.materialPoolService.deleteMaterialPoolMaterial(material);
        this.animalService.setHousingEndState(this.materialPoolMaterials, this.materialPoolMaterials[0].MaterialPool);
    }

    takeOutMaterialAssoc(materialAssoc: any) {
        materialAssoc.DateOut = Date.now();
        this.animalService.setHousingEndState(this.materialPoolMaterials, this.materialPoolMaterials[0].MaterialPool);
    }

    // <select> formatters
    animalStatusKeyFormatter = (value: any) => {
        return value.C_AnimalStatus_key;
    }
    animalStatusFormatter = (value: any) => {
        return value.AnimalStatus;
    }
    exitReasonKeyFormatter = (value: any) => {
        return value.C_ExitReason_key;
    }
    exitReasonFormatter = (value: any) => {
        return value.ExitReason;
    }

    validate() {
        return dateControlValidator(this.dateControls);
    }
}

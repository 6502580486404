import {
    Component,
    Input,
    OnInit
} from '@angular/core';

import {
    NgbActiveModal
} from '@ng-bootstrap/ng-bootstrap';

import { AuditService } from '../../audit/audit.service';
import { ExportCensusAuditService } from './export-census-audit.service';
import { CensusService } from '../census.service';

import {
    getSafeProp
} from '../../common/util/';

@Component({
    selector: 'census-audit-report',
    templateUrl: './census-audit-report.component.html'
})
export class CensusAuditReportComponent implements OnInit {
    @Input() censusKey: any;

    census: any;
    auditData: any[] = [];
    auditDiscrepanciesData: any[] = [];

    loading = false;

    readonly noDataMessage: string = 'No changes.';

    constructor(
        private activeModal: NgbActiveModal,
        private auditService: AuditService,
        private exportCensusAuditService: ExportCensusAuditService,
        private censusService: CensusService
    ) {
    }

    ngOnInit() {
        this.getData();
    }

    private getData(): Promise<void> {

        const p1 = this.censusService.getCensus(this.censusKey)
            .then((data: any) => {
                this.census = data;
            });

        const p2 = this.auditService.getCensusAuditData(this.censusKey)
            .then((response: any) => {
                this.auditData = getSafeProp(response, 'data.results') || [];
            });

        const p3 = this.auditService.getCensusAuditDiscrepanciesData(this.censusKey)
            .then((response: any) => {
                this.auditDiscrepanciesData = getSafeProp(response, 'data.results') || [];
            });

        this.loading = true;
        return Promise.all([p1, p2, p3]).then(() => {
            this.loading = false;
        }).catch((error) => {
            this.loading = false;
            throw error;
        });
    }

    onCancel(): void {
        this.activeModal.dismiss();
    }

    exportAudit(): void {
        this.exportCensusAuditService.exportToCsv(
            this.auditData,
            this.auditDiscrepanciesData
        );
    }
}

<form novalidate>
    <div class="modal-header justify-content-center">
        <h4 class="modal-title">Edit - {{taskAlias}}</h4>
    </div>

    <div class="modal-body">
        <task-detail
            [isReadOnly]="true"
            [hideDecimalPlaces]="true"
            [task]="task"
            [facet]="facet"
        ></task-detail>

    </div><!-- /.modal-body -->

    <div class="modal-footer">
        <button type="button"
                class="btn btn-fw btn-lg btn-primary"
                (click)="onApplyToAll(task)">
            Apply To All Tasks
        </button>
        <button type="button"
                class="btn btn-fw btn-lg btn-primary"
                (click)="onApplyToTask(task)">
            Apply To This Task Only
        </button>
        <button type="button"
                class="btn btn-fw btn-lg btn-secondary"
                (click)="onCancel()">
            Cancel
        </button>
    </div>
</form>

import { OnInit, Component, Optional, Inject, ChangeDetectionStrategy } from '@angular/core';
import { FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { CLIMB_DIALOG_DATA } from '@common/dialog/dialog-data.token';
import { DialogRef } from '@common/dialog/dialog-ref';
import { Job, JobVariablePhrase, VariablePhrasesTypesEnum, cv_VariablePhraseType } from '@common/types';
import { atLeastOneValidator } from '@common/validators';
import { DataManagerService } from '@services/data-manager.service';
import { Subscription } from 'rxjs';

type VariablePhrasesKeys = 'dosing' | 'sampling' | 'specialInstructions' | 'report' | 'imaging' | 'generalProcedures' | 'studyConditions' | 'surgeryPhrases';

@Component({
    selector: 'variable-phrase-modal',
    templateUrl: './variable-phrase-modal.component.html',
    styleUrls: ['./variable-phrase-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VariablePhraseModalComponent implements OnInit {
    private readonly MAX_SYMBOLS = 8000;
    job: Job;
    subs: Subscription = new Subscription();
    form: FormGroup;
    isDefault: boolean;
    variablePhrasesTypes: cv_VariablePhraseType[] = [];
    originalVariablePhrasesValues = {} as { [key in VariablePhrasesKeys]: { value: string, variablePhraseKey: number } };

    constructor(
        private dialogRef: DialogRef,
        private fb: FormBuilder,
        private dataManager: DataManagerService,
        @Optional() @Inject(CLIMB_DIALOG_DATA) data: { job: Job, variablePhrasesTypes: cv_VariablePhraseType[] },
    ) {
        this.job = data?.job;
        this.variablePhrasesTypes = data?.variablePhrasesTypes;
    }

    variablePhraseTypes: VariablePhrasesTypesEnum[] = [
        VariablePhrasesTypesEnum.dosing,
        VariablePhrasesTypesEnum.sampling,
        VariablePhrasesTypesEnum.specialInstructions,
        VariablePhrasesTypesEnum.report,
        VariablePhrasesTypesEnum.imaging,
        VariablePhrasesTypesEnum.generalProcedures,
        VariablePhrasesTypesEnum.studyConditions,
        VariablePhrasesTypesEnum.surgeryPhrases,
    ];

    variablePhraseValues: { [key in VariablePhrasesKeys]: string | null } = {
        dosing: null,
        sampling: null,
        specialInstructions: null,
        report: null,
        imaging: null,
        generalProcedures: null,
        studyConditions: null,
        surgeryPhrases: null
    }

    variablePhraseKeys = Object.keys(this.variablePhraseValues);

    ngOnInit(): void {
        const formControls = {
            dosing: ['', [Validators.max(this.MAX_SYMBOLS)]],
            sampling: ['', [Validators.max(this.MAX_SYMBOLS)]],
            specialInstructions: ['', [Validators.max(this.MAX_SYMBOLS)]],
            report: ['', [Validators.max(this.MAX_SYMBOLS)]],
            imaging: ['', [Validators.max(this.MAX_SYMBOLS)]],
            generalProcedures: ['', [Validators.max(this.MAX_SYMBOLS)]],
            studyConditions: ['', [Validators.max(this.MAX_SYMBOLS)]],
            surgeryPhrases: ['', [Validators.max(this.MAX_SYMBOLS)]],
        };

        const validators: ValidatorFn[] = [];

        this.form = this.fb.group(formControls, { validators });

        if (this.job.JobVariablePhrase.length) {
            const formValues = this.job.JobVariablePhrase.reduce((acc, phrase) => {
                const key = Object.keys(VariablePhrasesTypesEnum).find(type => {
                    return VariablePhrasesTypesEnum[type] === phrase.cv_VariablePhraseType.VariablePhraseType;
                });
                if (key) {
                    acc[key] = phrase.VariablePhraseText;
                    this.originalVariablePhrasesValues[key] = { value: phrase.VariablePhraseText, variablePhraseKey: phrase.C_JobVariablePhrase_key };
                }
                return acc;
            }, {});
    
            this.form.patchValue(formValues);
        }

        this.checkIfFormIsDefault();

        this.subs.add(this.form.valueChanges.subscribe(() => {
            this.checkIfFormIsDefault();
        }));
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    updateClicked(): void {
        const variablePhraseTypeMap = new Map<string, unknown>();
        const jobVariablePhraseMap = new Map<number, JobVariablePhrase>();

        for (const phrase of this.variablePhrasesTypes) {
            variablePhraseTypeMap.set(phrase.VariablePhraseType, phrase.C_VariablePhraseType_key);
        }
    
        for (const variablePhrase of this.job.JobVariablePhrase) {
            jobVariablePhraseMap.set(variablePhrase.C_JobVariablePhrase_key, variablePhrase);
        }
    
        for (const [key, value] of Object.entries<string>(this.form.value)) {
            const originalPhrase = this.originalVariablePhrasesValues[key];
            const variablePhraseTypeKey = variablePhraseTypeMap.get(VariablePhrasesTypesEnum[key]);
            const trimmedValue = value.trim();

            // JobVariablePhrase created
            if (trimmedValue && !originalPhrase) {
                const initialValues = {
                    C_Job_key: this.job.C_Job_key,
                    C_VariablePhraseType_key: variablePhraseTypeKey,
                    VariablePhraseText: value,
                };
                this.dataManager.createEntity('JobVariablePhrase', initialValues);
            }
    
            // JobVariablePhrase modified
            if (trimmedValue && originalPhrase) {
                const variablePhrase = jobVariablePhraseMap.get(originalPhrase.variablePhraseKey);
                if (variablePhrase) {
                    variablePhrase.VariablePhraseText = value;
                }
            }
    
            // JobVariablePhrase deleted
            if (!trimmedValue && originalPhrase) {
                const variablePhrase = jobVariablePhraseMap.get(originalPhrase.variablePhraseKey);
                if (variablePhrase) {
                    this.dataManager.deleteEntity(variablePhrase);
                }
            }
        }
    
        this.dialogRef.close();
    }

    cancelClicked(): void {
        this.dialogRef.close();
    }

    private checkIfFormIsDefault() {
        for (const [key, value] of Object.entries<string>(this.form.value)) {
            const originalValue = this.originalVariablePhrasesValues[key]?.value ?? '';
            if (originalValue.trim() !== value.trim()) {
                this.isDefault = false;
                return;
            }
        }

        this.isDefault = true;
    }
}
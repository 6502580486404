import type { Input } from './input.interface';
import type { TaskCohort } from './task-cohort.interface';

export interface TaskCohortInput {
    C_Input_key: number;
    C_TaskCohortInput_key: number;
    C_TaskCohort_key: number;
    C_Workgroup_key: number;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    Input: Input;
    InputValue: string;
    ModifiedBy: string;
    TaskCohort: TaskCohort;
    Version: number;
}

<!-- template to render combo item -->
<ng-template #locationTmpl let-r="result">
    {{r.PositionName}}
    <small>Type: {{r.LocationType}}</small>
</ng-template>

<div class="location-select">
    <climb-typeahead
        [(model)]="model"
        [resultFormatter]="locationNameFormatter"
        [resultTemplate]="locationTmpl" 
        [keyFormatter]="locationKeyFormatter"
        [search]="searchLocations"
        [keySearch]="searchLocationsByKey"
        [placeholder]="placeholder"
        [required]="required"
        [namespace]="'location'"
        (selectItem)="selectItemHandler($event)"
        [disabled]="disabled"
        #typeahead
    ></climb-typeahead>
</div>

import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { ClimbCommonModule } from '../common/climb-common.module';

import { LineGenotypeAssaySelectComponent } from './line-genotype-assay-select/line-genotype-assay-select.component';
import { StockNumberMultiselectComponent } from './stock-number-multiselect.component';
import { LineMultiselectComponent } from './line-multiselect.component';
import { LineSelectComponent } from './line-select.component';

import { ExportLineDetailService } from './export-line-detail.service';
import { LineService } from './line.service';
import { LineVocabService } from './line-vocab.service';

import { DragulaModule } from "ng2-dragula";
import { LineSaveService } from './services/line-save.service';
import { LineStateService } from './services/line-state.service';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ClimbCommonModule,
        DragulaModule
    ],
    declarations: [
        LineGenotypeAssaySelectComponent,
        StockNumberMultiselectComponent,
        LineMultiselectComponent,
        LineSelectComponent
    ],
    exports: [
        LineGenotypeAssaySelectComponent,
        StockNumberMultiselectComponent,
        LineMultiselectComponent,
        LineSelectComponent
    ]
})
export class LinesSharedModule {
    static forRoot(): ModuleWithProviders<LinesSharedModule> {
        return {
          ngModule: LinesSharedModule,
          providers: [
            ExportLineDetailService,
            LineService,
            LineVocabService,
            LineSaveService,
            LineStateService          
          ]
        };
    }
 }

import {
    CellFormatterService,
    TableOptions,
    FormatterOptions
} from '../common/datatable';
import { AuditModifiedFieldComponent } from './audit-modified-fields/audit-modified-field.component';
import { AuditClassFactory } from './audit-class-maker';

export class AuditWorkflowTasksTableOptions {

    options: TableOptions;

    constructor(
        private cellFormatterService: CellFormatterService
    ) {
        this.options = this.getDefaultOptions();
    }

    getDefaultOptions(): TableOptions {

        return {
            csvFileName: 'tasks-audit.csv',
            enableDetailColumn: false,
            enableDraggable: false,
            enableSelectable: false,
            enableAuditColumns: false,
            columns: [
                {
                    displayName: 'Type',
                    field: 'UpdateType',
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Modified Fields',
                    field: 'ModifiedFields',
                    sortField: 'ModifiedFields',
                    rendererComponent: AuditModifiedFieldComponent,
                    exportFormatter: this.cellFormatterService.auditModifiedFieldsExportFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Date',
                    field: 'ModifiedDate',
                    formatter: this.cellFormatterService.dateTimeUTCFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Modified By',
                    field: 'ModifiedBy',
                    formatter: this.cellFormatterService.userNameFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Name',
                    field: 'TaskName',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('TaskName', 'TaskName'),
                    cellClass: AuditClassFactory.createCellClass('taskname'),
                },
                {
                    displayName: 'Description',
                    field: 'Description',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('Description', 'Description'),
                    cellClass: AuditClassFactory.createCellClass('description'),
                },
                {
                    displayName: 'Task Type',
                    field: 'TaskType',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('TaskType', 'TaskType'),
                    cellClass: AuditClassFactory.createCellClass('tasktype'),
                },
                {
                    displayName: 'Active',
                    field: 'IsActive',
                    visible: false,
                    formatter: this.cellFormatterService.createAuditColumnFormatter('IsActive', 'IsActive'),
                    cellClass: AuditClassFactory.createCellClass('isactive'),
                },
                {
                    displayName: 'Cost',
                    field: 'Cost',
                    visible: false,
                    formatter: this.cellFormatterService.createAuditColumnFormatter('Cost', 'Cost'),
                    cellClass: AuditClassFactory.createCellClass('cost'),
                },
                {
                    displayName: 'Cost Per',
                    field: 'CostPer',
                    visible: false,
                    formatter: this.cellFormatterService.createAuditColumnFormatter('CostPer', 'CostPer'),
                    cellClass: AuditClassFactory.createCellClass('costper'),
                },
                {
                    displayName: 'Duration',
                    field: 'Duration',
                    visible: false,
                    formatter: this.cellFormatterService.createAuditColumnFormatter('Duration', 'Duration'),
                    cellClass: AuditClassFactory.createCellClass('duration'),
                },
                {
                    displayName: 'Duration Per',
                    field: 'DurationPer',
                    visible: false,
                    formatter: this.cellFormatterService.createAuditColumnFormatter('DurationPer', 'DurationPer'),
                    cellClass: AuditClassFactory.createCellClass('durationper'),
                },
                {
                    displayName: 'Effort',
                    field: 'Effort',
                    visible: false,
                    formatter: this.cellFormatterService.createAuditColumnFormatter('Effort', 'Effort'),
                    cellClass: AuditClassFactory.createCellClass('effort'),
                },
                {
                    displayName: 'Effort Per',
                    field: 'EffortPer',
                    visible: false,
                    formatter: this.cellFormatterService.createAuditColumnFormatter('EffortPer', 'EffortPer'),
                    cellClass: AuditClassFactory.createCellClass('effortper'),
                },
                {
                    displayName: 'No Materials',
                    field: 'NoMaterials',
                    visible: false,
                    formatter: this.cellFormatterService.createAuditColumnFormatter('NoMaterials', 'NoMaterials'),
                    cellClass: AuditClassFactory.createCellClass('nomaterials'),
                },
                {
                    displayName: 'Automatically End Task',
                    field: 'AutomaticallyEndTask',
                    visible: false,
                    formatter: this.cellFormatterService.createAuditColumnFormatter('AutomaticallyEndTask', 'AutomaticallyEndTask'),
                    cellClass: AuditClassFactory.createCellClass('automaticallyendtask'),
                },
                {
                    displayName: 'Created By',
                    field: 'CreatedBy',
                    formatter: this.cellFormatterService.userNameFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Created Date',
                    field: 'DateCreated',
                    formatter: this.cellFormatterService.dateTimeUTCFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                }
            ]
        };
    }
}

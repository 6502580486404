import { FileExporter } from './file-exporter';
import * as pdfMake from 'pdfmake/build/pdfmake';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import * as pdfMakeUnicode from 'pdfmake-unicode';

(pdfMake as any).vfs = pdfMakeUnicode.pdfMake.vfs;

export interface PdfConfig {
    isCustomContent?: boolean;
    docDefinition?: any;
}

/*
* Generic PDF file exporter
*/
export class PdfExporter extends FileExporter {

    public defaultDocDefinition = {
        pageSize: 'A0',
        pageOrientation: "landscape",
        styles: {
            header: {
                bold: true
            },
            sectionHeader: {
                bold: true,
                fontSize: 14,
                margin: [0, 15, 0, 15]          
            }
        }
    };

    public defaultTable(data: any[]) {
        const tableData = data.map((row: any, index: number) => {
            return row.map((column: any) => {
                return { text: column, bold: index === 0 };
            });
        });
        return {
            table: {
                dontBreakRows: true,
                pageBreak: 'before',
                widths: tableData[0].map(() => 'auto'),
                body: tableData
            }
        };
    }

    constructor(private config: PdfConfig) {
        super();
    }

    download(data: any, filename: string) {
        const docDefinition = this.generateDocDefinition(data);
        this.triggerFileDownload(docDefinition, filename);
    }

    triggerFileDownload(docDefinition: any, filename: string) {
        pdfMake.createPdf(docDefinition).download(filename.replace('.csv', '.pdf'));
    }

    private generateDocDefinition(data: any) {
        const { docDefinition, isCustomContent } = this.config;
        const definition = docDefinition || this.defaultDocDefinition;
        return { ...definition, content: [isCustomContent ? data : this.defaultTable(data)] };
    }
}

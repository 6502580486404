import { Component, Input, OnChanges, OnDestroy, OnInit } from "@angular/core";
import { Cohort, Entity } from "@common/types";
import { getSafeProp, truncateSentence } from "@common/util";
import { populationStandardDeviation } from "@common/util/population-standard-deviation";
import { max, mean, min, round } from "lodash";
import { Subscription } from "rxjs";

interface OutputData {
    name: string;
    mean: number;
    low: number;
    high: number;
    standardDeviation: number;
    min: number;
    max: number;
}

@Component({
    selector: 'cohort-output-graph',
    templateUrl: './cohort-output-graph.component.html',
})
export class CohortOutputGraphComponent implements OnInit, OnChanges {
    @Input() cohorts: Entity<Cohort>[];
    @Input() outputName: string;
    @Input() outputValueKey: string;

    data: OutputData[];
    subs: Subscription[];

    dynamicHeight = 400;

    ngOnInit() {
        this.setupOutputCalculations();
        this.calculateDynamicHeight();
    }

    ngOnChanges() {
        this.setupOutputCalculations();
        this.calculateDynamicHeight();
    }

    private setupOutputCalculations() {
        this.data = [];
        for (const cohort of this.cohorts) {
            const values = cohort.CohortMaterial.map((cohortMaterial) => {
                const value = getSafeProp(cohortMaterial, this.outputValueKey);
                if (value !== null) { 
                    return Number(value);
                }
            }).filter(v => v);
            if (!values.length) {
                continue;
            }

            const standardDeviation = round(populationStandardDeviation(values), 4);
            const average = round(mean(values), 4);

            this.data.push({
                name: cohort.CohortName,
                mean: average,
                high: average + standardDeviation,
                low: average - standardDeviation,
                standardDeviation,
                min: min(values),
                max: max(values),
            });
        }
    }

    private calculateDynamicHeight() {
        const minHeight = 400;
        this.dynamicHeight = Math.max(minHeight, this.cohorts.length * 30);
    }

    customizeArgumentLabels(arg: { value: string | number | Date; valueText: string}) {
        return truncateSentence(arg.valueText, 40);
    }
}

import type { Device } from './device.interface';
import type { JobLocation } from './job-location.interface';
import type { Line } from './line.interface';
import type { MaterialLocation } from './material-location.interface';
import type { OrderLocation } from './order-location.interface';
import type { cv_LocationType } from './cv-location-type.interface';
import type { cv_MaterialType } from './cv-material-type.interface';

export interface LocationPosition {
    C_LocationPosition_key: number;
    C_LocationType_key: number | null;
    C_MaterialType_key: number | null;
    C_ParentPosition_key: number | null;
    C_Workgroup_key: number;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    Device: Device[];
    IsActive: boolean;
    JobLocation: JobLocation[];
    Line: Line[];
    MaterialLocation: MaterialLocation[];
    ModifiedBy: string;
    OrderLocation: OrderLocation[];
    Ordinal: number;
    PositionName: string;
    Version: number;
    XPosition: number | null;
    YPosition: number | null;
    ZPosition: number | null;
    cv_LocationType: cv_LocationType;
    cv_MaterialType: cv_MaterialType;
}

<div class="output-flag-main-container">
    <div class="main-label">
        Add flag
    </div>
    <div class="output-flag-container">
        <div>
            <div class="form-group row">
                <div class="col-md-12">
                    Message
                </div>
            </div>
            <div class="form-group row">
                <div class="col-md-12">
                    <active-vocab-select [(model)]="flagMessageKey"
                                         [vocabChoices]="flagMessages"
                                         [keyFormatter]="taskFlagMessageKeyFormatter"
                                         [optionFormatter]="taskFlagMessageFormatter"></active-vocab-select>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-md-12 d-flex justify-content-end">
                    <button class="btn btn-secondary btn-success"
                            (click)="addFlag()"
                            [disabled]="!isFlagRuleValid(currentOption)">
                        Add
                    </button>
                </div>
            </div>
        </div>

        <div>
            <div class="tasks-flags-options">
                <div class="form-group row">
                    <div class="col-md-12">
                        Flag at
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-12">
                        <input type="radio"
                               id="flagGreaterThan_{{index}}"
                               name="flagAt_{{index}}"
                               #minimumOption
                               (change)="optionChange('minimum')" />
                        <label for="flagGreaterThan_{{index}}">Greater than or equal to</label>
                        <input type="number"
                               step="any"
                               class="form-control"
                               [(ngModel)]="minimum"

                               [disabled]="!minimumOption.checked" />
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-12">
                        <input type="radio"
                               id="flagLessThan_{{index}}"
                               name="flagAt_{{index}}"
                               #maximumOption
                               (change)="optionChange('maximum')" />
                        <label for="flagLessThan_{{index}}">Less than or equal to</label>
                        <input type="number"
                               step="any"
                               class="form-control"
                               [(ngModel)]="maximum"

                               [disabled]="!maximumOption.checked" />
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-12">
                        <input type="radio"
                               id="flagEquals_{{index}}"
                               name="flagAt_{{index}}"
                               #equalsOption
                               (change)="optionChange('equals')" />
                        <label for="flagEquals_{{index}}">Equal to</label>
                        <input type="number"
                               step="any"
                               class="form-control"
                               [(ngModel)]="equals"

                               [disabled]="!equalsOption.checked" />
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-12">
                        <input type="radio"
                               id="flagBetween_{{index}}"
                               name="flagAt_{{index}}"
                               #betweenOption
                               (change)="optionChange('between')" />
                        <label for="flagBetween_{{index}}">
                            Between
                            <input type="number"
                                   step="any"
                                   class="form-control"
                                   #minInput
                                   [(ngModel)]="betweenMin"
                                   (ngModelChange)="betweenValuesChanged(minInput, maxInput)"
                                   [disabled]="!betweenOption.checked" />
                            and
                            <input type="number"
                                   step="any"
                                   class="form-control"
                                   #maxInput
                                   [(ngModel)]="betweenMax"
                                   (ngModelChange)="betweenValuesChanged(minInput, maxInput)"
                                   [disabled]="!betweenOption.checked" />
                        </label>
                    </div>
                </div>
            </div>

            <div class="current-flags-table" *ngIf="taskOutput.OutputFlag | notEmpty">
                <table class="table table-sm table-borderless">
                    <caption>
                        Current Flags
                    </caption>
                    <thead>
                        <tr class="border-bottom">
                            <th></th>
                            <th>Flag at</th>
                            <th>Message</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of taskOutput.OutputFlag; let i = index;">
                            <td class="icon-cell" *ngIf="facet?.Privilege !== 'ReadOnly'">
                                <a (click)="removeFlag(item)" title="Remove flag">
                                    <i class="fa fa-remove remove-item"></i>
                                </a>
                            </td>
                            <td class="icon-cell" *ngIf="facet?.Privilege === 'ReadOnly'">
                                <span class="remove-disabled">
                                    <i class="fa fa-remove remove-item"></i>
                                </span>
                            </td>
                            <td>
                                {{renderFlag(item)}}
                            </td>
                            <td>
                                {{item.TaskFlagMessage}}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>

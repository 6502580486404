import {
    Component,
    EventEmitter,
    Input,
    Output
} from '@angular/core';

import { SearchService } from '../search/search.service';
import { SearchQueryDef } from '../search/search-query-def';

@Component({
    selector: 'job-multiselect',
    template: `
<div *ngIf="model | notEmpty">
    <table class="table table-sm table-borderless table-nonfluid">
        <tbody>
            <tr *ngFor="let item of model; let i = index;">
                <td class="icon-cell">
                    <a (click)="removeItem(i)" title="Remove {{'Job' | translate}}">
                        <i class="fa fa-remove remove-item"></i>
                    </a>
                </td>
                <td>
                    {{item.JobID}}
                </td>
            </tr>
        </tbody>
    </table>
</div>

<climb-indirect-typeahead
        [search]="searchJobs"
        [resultFormatter]="jobIdFormatter"
        placeholder="{{'Job' | translate}} name&hellip;"
        (selectItem)="selectItem($event)"></climb-indirect-typeahead>
`,
    styles: [`
        .table {
            margin-bottom: 0.25em;
        }
    `]
})
export class JobMultiselectComponent {
    @Input() model: any[];
    @Output() modelChange: EventEmitter<any> = new EventEmitter<any>();

    readonly MAX_SEARCH_RESULTS = 50;


    constructor(
        private _searchService: SearchService
    ) { }

    removeItem(index: number) {
        if (this.model && index >= 0 && index < this.model.length) {
            this.model.splice(index, 1);
        }

        this.modelChange.emit(this.model);
    }

    searchJobs = (term: string): Promise<any> => {
        let count = null;
        if (term.length < 3) {
            count = this.MAX_SEARCH_RESULTS;
        }

        const filter = {
            JobID: term
        };

        const searchQueryDef: SearchQueryDef = {
            entity: 'Jobs',
            page: 1,
            size: count,
            sortColumn: 'JobID',
            sortDirection: 'asc',
            filter
        };

        return this._searchService.getEntitiesBySearch(searchQueryDef)
            .then((results: any) => {
                return results.data;
            });
    }

    jobIdFormatter = (value: any) => {
        return value.JobID;
    }

    selectItem(job: any) {
        if (this.model) {
            this.model.push(job);
        } else {
            this.model = [job];
        }

        this.modelChange.emit(this.model);
    }
}

import { Directive } from '@angular/core';
import { BaseDetailService } from './base-detail.service';
import { EntityChangeService } from '../../entity-changes/entity-change.service';
import { IGlpFacetStateService } from '@services/interfaces/glp-facet-state-service.interface';
import { IGlpFacetSaveService } from '@services/interfaces/glp-facet-save-service.interface';
import { GlpBaseDetailBase } from './glp-base-detail-base.directive';
import { Entity } from '@common/types';

/**
 * New Base class for facet detail pages that will support facet-level saving for the several entities
 *
 * @Directive - Angular directive decorator
 * @extends {GlpBaseDetailBase} - Extends from BaseDetail
 */
@Directive()
export abstract class GlpBaseDetail<T> extends GlpBaseDetailBase<T> {
    constructor(
        protected baseDetailService: BaseDetailService,
        protected entitySaveService: IGlpFacetSaveService,
        private facetStateService: IGlpFacetStateService,
        protected entityChangeService: EntityChangeService,
    ) {
        super(baseDetailService, entitySaveService, entityChangeService);
    }

    /**
     * Validate and save the facet detail entity
     */
    public async saveEntity(): Promise<void> {
        const errorMessage = await this.validate();

        if (errorMessage) {
            this.validationErrorsPresent = true;
            this.loggingService.logError(
                this.baseDetailService.saveChangesService.generateSaveErrorMessage(
                    this.facet.FacetName,
                    this.facetView,
                    errorMessage,
                ),
                null,
                this.COMPONENT_LOG_TAG,
                true,
            );

            return;
        }

        this.validationErrorsPresent = false;
        await this.beforeSave();
        const entityForSaving = this.getEntityForSaving();

        await this.entitySaveService.save(entityForSaving);
    }

    /**
     * Abstract method to get the facet entity for saving
     * @returns {Entity<T>}
     */
    abstract getEntityForSaving(): Entity<T>;

    /**
     * Check if there are changes to be saved.
     * Overriding base class method to be able to check only for changes specific for facet
     * @returns {boolean}
     */
    hasChanges(): boolean {
        const entityForSaving = this.getEntityForSaving();

        return this.facetStateService.hasChanges(entityForSaving);
    }
}

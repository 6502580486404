<table class="table table-bordered contacts-table">
    <thead>
        <tr class="detail-grid-header">
            <th [attr.colspan]="readonly ? 10 : 11">
                <a *ngIf="!readonly"
                   (click)="createContact()">
                    <i class="fa fa-fw fa-plus-circle add-item-link" title="Add Contact"></i>
                </a>
                <span class="float-right">
                </span>
                <span *ngIf="pkName=='C_Site_key'">Site </span>Contacts ({{ entity?.ContactPerson?.length }})
                <a (click)="toggleContactsShown()" class="contacts-toggle-link">
                    <i class="fa fa-fw"
                       [ngClass]="contactsShown ? 'fa-chevron-up' : 'fa-chevron-down'"
                       [title]="contactsShown ? 'Hide Contacts' : 'Show Contacts'"
                       aria-hidden="true"></i>
                </a>
            </th>
        </tr>
    </thead>
    <tbody *ngIf="contactsShown">
        <tr>
            <th>

            </th>
            <th>
                First Name
            </th>
            <th>
                Last Name
            </th>
            <th>
                Email
            </th>
            <th>
                Phone Number
            </th>
            <th>
                Contact Type
            </th>
            <th *ngIf="isCRO">
                Ship To Address
            </th>
            <th *ngIf="isCRO">
                Ship To Email Address
            </th>
            <th>
                Active
            </th>
        </tr>
        <tr *ngFor="let contact of entity.ContactPerson">
            <td>
                <a *ngIf="!readonly" (click)="removeContact(contact)">
                    <i class="fa fa-fw fa-remove remove-item mt-1"
                       title="Delete Contact"></i>
                </a>
            </td>
            <td>
                <input type="text"
                       name="FirstName"
                       #name="ngModel"
                       [(ngModel)]="contact.FirstName"
                       required
                       [readonly]="readonly"
                       class="form-control input-medium" />
            </td>
            <td>
                <input type="text"
                       name="LastName"
                       #name="ngModel"
                       [(ngModel)]="contact.LastName"
                       [readonly]="readonly"
                       class="form-control input-medium" />
            </td>
            <td>
                <input type="text"
                       name="Email"
                       #name="ngModel"
                       [(ngModel)]="contact.EmailAddress"
                       [readonly]="readonly"
                       class="form-control input-medium" />
            </td>
            <td>
                <input type="text"
                       name="Phone"
                       #name="ngModel"
                       [(ngModel)]="contact.Phone"
                       [readonly]="readonly"
                       class="form-control input-medium" />
            </td>
            <td>
                <active-vocab-select [(model)]="contact.C_ContactPersonType_key"
                                     [vocabChoices]="contactTypes"
                                     [keyFormatter]="contactTypeKeyFormatter"
                                     [optionFormatter]="contactTypeFormatter"
                                     [readonly]="readonly"
                                     [nullable]="true"></active-vocab-select>
            </td>
            <td *ngIf="isCRO">
                <input type="text"
                       name="ShippingAddress"
                       #name="ngModel"
                       [(ngModel)]="contact.ShippingAddress"
                       [readonly]="readonly"
                       class="form-control input-medium" />
            </td>
            <td *ngIf="isCRO">
                <input type="text"
                       name="ShippingEmail"
                       #name="ngModel"
                       [(ngModel)]="contact.ShippingEmailAddress"
                       [readonly]="readonly"
                       class="form-control input-medium" />
            </td>
            <td class="icon-cell">
                <input type="checkbox"
                        name="Active"
                       class="mt-1"
                        [(ngModel)]="contact.IsActive"
                        [readonly]="readonly" />
            </td>
        </tr>
    </tbody>
</table>

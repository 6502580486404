import { pathStringToArray } from './private/path-string-to-array';

/*
* Returns array of unique items
*/
export function uniqueArray<T>(array: T[]): T[] {
    return _uniqueArray(array);
}

function _uniqueArray<T>(array: T[]): T[] {
    return array.filter(
        (item: T, index: number, self: any) => {
            return self.indexOf(item) === index;
        }
    );
}

/**
 * Return unique list of array items, 
 *    where propertyName is not duplicated between array items
 * @param array 
 * @param propertyName 
 */
export function uniqueArrayOnProperty(array: any[], propertyName: string) {
    const parallelArray = array.map((item: any) => {
        return item[propertyName];
    });
    return array.filter(
        (item: any, index: number, self: any) => {
            return parallelArray.indexOf(item[propertyName]) === index;
        }
    );
}

/**
 *  Returns a unique array of objects from the specified property path
 *    Returns empty list if path does not exist
 * @param source - can be single object or array
 * @param path - can include arrays in the path
 */
export function uniqueArrayFromPropertyPath<T = any>(source: any | any[], path: string): T[] {
    const uniqueValues: any[] = [];

    // get unique set of items from path
    const seen: Set<any> = new Set();
    _forEachItemInPath(source, path, (item) => {
        // add if item is defined and not seen before
        if (item && !seen.has(item)) {
            seen.add(item);
            uniqueValues.push(item);
        }
    });

    return uniqueValues;
}

/**
 * return unique array of dates
 * @param dates 
 */
export function uniqueDateArray(dates: Date[]): Date[] {
    if (!dates) {
        return [];
    }
    return dates.filter((date, i, self) => {
        return self.findIndex((d) => { 
            return d.getTime() === date.getTime();
        }) === i;
    });
}

function _forEachItemInPath(
    source: any, 
    path: string, 
    forEachFunction: (item: any) => void
): void {
    if (!source) {
        return;
    }
    if (!Array.isArray(source)) {
        source = [source];
    }
    const paths = pathStringToArray(path);
    for (const item of source) {
        if (paths.length === 0) {
            forEachFunction(item);
        } else {
            const nextSource = item[paths[0]];
            const nextPath = paths.slice(1).join('.');
            _forEachItemInPath(nextSource, nextPath, forEachFunction);
        }
    }
}

import { RouteEnum } from './../routing/route.enum';
import {
    Inject,
    Injectable
} from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { RequestCache } from '../common/interceptors/request-cache.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpCancelService } from './http-cancel.service';
import { DialogService } from '@common/dialog/dialog.service';
import { OKTA_AUTH } from "@okta/okta-angular";
import { OktaAuth } from "@okta/okta-auth-js";

/**
 * Service for logging out of Climb.
 * 
 * NOTE: Must live in this package to prevent circular dependencies
 */
@Injectable()
export class LogoutService {

    constructor(
        private httpCancelService: HttpCancelService,
        private authService: AuthService,
        private router: Router,
        private requestCache: RequestCache,
        private modalService: NgbModal,
        private dialogService: DialogService,
        @Inject(OKTA_AUTH) public oktaAuth: OktaAuth
    ) {}

    sessionExpired(): void {
        this.modalService.dismissAll();
        this.dialogService.closeAll();
        this.clearData();
        this.router.navigate([RouteEnum.LoggedOutRoute]);
    }

    async logout(): Promise<void> {
        const isOktaSSO = this.authService.isAuthenticatedOkta();

        this.clearData();

        if (isOktaSSO) {
            await this.logoutOkta();
        } else {
            await this.router.navigate([RouteEnum.Login]);
        }

        /**
         * Add operation to EventLoop queue so that background
         * requests are called and successfully canceled
         * using the cancelPendingRequests()
         */
        setTimeout(() => {
            this.httpCancelService.cancelPendingRequests();
        });
    }

    async logoutOkta(): Promise<void> {
        this.oktaAuth.token.revoke(this.authService.getOktaAccessToken());
        this.oktaAuth.token.revoke(this.authService.getOktaRefreshToken());
        await this.oktaAuth.signOut({ 
            clearTokensBeforeRedirect: true, 
            revokeAccessToken: true, 
            revokeRefreshToken: true, 
            postLogoutRedirectUri: window.location.origin + '/' + RouteEnum.Login 
        });
    }

    private clearData(): void {
        this.requestCache.clear();
        this.authService.clearAuthData();
        this.authService.clearSSOData();
        this.authService.clearFeatureFlagData();
        this.authService.clearSessionData();
        this.authService.clearWorkgroupData();
    }
}

<climb-auth-container>
    <form [formGroup]="loginData">
        <climb-alert-inline *ngIf="errorMessage$ | async as message"
                            [message]="message"
                            status="error">
        </climb-alert-inline>

        <climb-input size="lg">
            <svg [climbIcon]="icons.user"></svg>
            <input data-automation-id="username-input"
                   type="text"
                   climbInput
                   formControlName="userName"
                   name="userName"
                   placeholder="Username"
                   autocorrect="off"
                   autocapitalize="off"
                   spellcheck="false"
                   autofocus />
        </climb-input>

        <climb-input size="lg">
            <svg [climbIcon]="icons.padlockClosed"></svg>
            <input data-automation-id="password-input"
                   type="password"
                   climbInput
                   formControlName="password"
                   name="password"
                   placeholder="Password"
                   autocomplete="off"
                   autocorrect="off"
                   autocapitalize="off"
                   spellcheck="false" />
        </climb-input>

        <button data-automation-id="login-button"
                climbButton
                type="submit"
                variant="primary"
                size="lg"
                (click)="login()"
                [disabled]="validateLoginDataLength()"
                [pending]="loading">
            Login
        </button>
    </form>

    <div class="divider">or</div>

    <div *ngIf="ssoProviders.length; else interstitialPage" class="sso-providers">
        <button *ngFor="let ssoProvider of ssoProviders"
                climbSsoButton
                [provider]="ssoProvider.provider"
                (click)="initSSOFlow(ssoProvider.domainHint)">
            {{ ssoProvider.title }}
        </button>
    </div>

    <ng-template #interstitialPage>
        <button climbButton
                size="lg"
                (click)="loginSSO()"
                [pending]="ssoLoading$ | async">
            Login with SSO
        </button>
    </ng-template>

    <div class="links">
        <span>
            New to Climb? <a data-automation-id="register-link" routerLink="/preregistrationemail">Register</a>
        </span>
        <a routerLink="/requestpwdreset">
            Forgot your password?
        </a>
    </div>

    <climb-auth-footer authContainerFooter
                       [version]="buildId">
    </climb-auth-footer>
</climb-auth-container>

import { ViewEditProfileService } from './view-edit-profile.service';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { TypeaheadModule } from '@common/typeahead/typeahead.module';
import { ClimbServicesModule } from '../services/climb-services.module';

import { UserSelectComponent } from './user-select.component';
import { UserService } from './user.service';
import { UserNameService } from './user-name.service';
import { UserMultiselectComponent } from './user-multiselect.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgbModule,
        TypeaheadModule,
        ClimbServicesModule
    ],
    declarations: [
        UserSelectComponent,
        UserMultiselectComponent
    ],
    exports: [
        UserSelectComponent,
        UserMultiselectComponent
    ]
})
export class UsersSharedModule { 
    static forRoot(): ModuleWithProviders<UsersSharedModule> {
        return {
          ngModule: UsersSharedModule,
          providers: [
            UserService,
            UserNameService,
            ViewEditProfileService
          ]
        };
    }
}

import type { NumericLimit } from './numeric-limit.interface';

export interface LimitDefinition {
    C_LimitDefinition_key: number;
    C_LimitType_key: number;
    C_Workgroup_key: number;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    ModifiedBy: string;
    NumericLimit: NumericLimit[];
    Version: number;
}

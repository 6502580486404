<table class="table table-bordered">
    <thead>
        <tr class="detail-grid-header"
            droppable
            [dropDisabled]="readonly"
            (drop)="onDropMaterialToJob()"
            [attr.title]="readonly ? '' : 'Drop samples here'">
            <th [attr.colspan]="readonly ? 9 : 10">
                <span class="float-right">
                    <a class="detail-grid-button"
                       (click)="pasteSamplesIntoJob()"
                       *ngIf="!readonly">
                        <i class="fa fa-paste" title="Paste samples"></i>
                    </a>
                    <a class="detail-grid-button"
                       (click)="copySamples()">
                        <i class="fa fa-copy" title="Copy selected samples"></i>
                    </a>
                </span>
                Samples
                <ng-container *ngIf="sampleMaterials && sampleMaterials.length > 0">
                    ({{sampleMaterials.length}})
                    <a (click)="job.samplesExpanded = !job.samplesExpanded"
                       class="samples-toggle-link">
                        <i class="fa"
                           [ngClass]="job.samplesExpanded ? 'fa-chevron-up' : 'fa-chevron-down'"
                           [title]="job.samplesExpanded ? 'Hide Samples' : 'Show Samples'"
                           aria-hidden="true"></i>
                    </a>
                </ng-container>
            </th>
        </tr>
        <!-- closed state -->
        <!-- NOT READY YET
            <ng-container *ngIf="job.SampleSummary && job.SampleSummary.length > 0 &&
                !job.samplesExpanded">
            <tr class="detail-grid-header">
                <th>Count</th>
                <th>Type</th>
                <th>{{'Line' | translate}}</th>
            </tr>
        </ng-container> -->
        <!-- open state -->
        <tr *ngIf="(sampleMaterials && sampleMaterials.length > 0) &&
                    job.samplesExpanded"
            class="detail-grid-header">

            <!-- Select -->
            <th class="icon-cell">
                <input type="checkbox"
                       (click)="selectAllSamples($event)"
                       title="Select/clear all" />
            </th>

            <!-- Remove -->
            <th *ngIf="!readonly">
                &nbsp;
            </th>

            <th>
                <a (click)="tableSort.toggleSort('Material.Identifier')"
                   class="header-sort-link"
                   title="Sort by ID">ID</a>
            </th>
            <th>
                <a (click)="tableSort.toggleSort('Material.Sample.SampleNameSortable')"
                   class="header-sort-link"
                   title="Sort by name">Name</a>
            </th>
            <th>
                Source
            </th>
            <th>
                <a (click)="tableSort.toggleSort('Material.Sample.cv_SampleType.SampleType')"
                   class="header-sort-link"
                   title="Sort by type">Type</a>
            </th>
            <th>
                <a (click)="tableSort.toggleSort('Material.Sample.TimePoint')"
                   class="header-sort-link"
                   title="Sort by time point">Time Point</a>
            </th>
            <th>
                <a (click)="tableSort.toggleSort('Material.Sample.cv_SampleStatus.SampleStatus')"
                   class="header-sort-link"
                   title="Sort by status">Status</a>
            </th>
            <th>
                <a (click)="tableSort.toggleSort('Material.Sample.cv_PreservationMethod.PreservationMethod')"
                   class="header-sort-link"
                   title="Sort by preservation method">Preservation</a>
            </th>
            <th>
                <a (click)="tableSort.toggleSort('Material.Line.LineName')"
                   class="header-sort-link"
                   title="Sort by {{'Line' | translate}}">{{'Line' | translate}}</a>
            </th>
        </tr>
    </thead>

    <!-- closed state -->
    <!-- NOT READY YET
        <tbody *ngIf="job.SampleSummary && job.SampleSummary.length > 0 &&
            !job.samplesExpanded">
        <tr *ngFor="let summary of job.SampleSummary">
            <td>{{summary.count}}</td>
            <td>{{summary.item?.type}}</td>
            <td>{{summary.item?.strain}}</td>
        </tr>
    </tbody> -->
    <!-- open state -->
    <tbody *ngIf="job.samplesExpanded">
        <tr *ngFor="let jobMaterial of sampleMaterials |
                orderBy:tableSort.propertyPath:tableSort.reverse;
                let sampleIndex = index">
            <ng-container *ngIf="jobMaterial.Material?.Sample">

                <!-- Select -->
                <td class="icon-cell">
                    <input type="checkbox"
                           name="jobSampleIsSelected{{sampleIndex}}"
                           [(ngModel)]="jobMaterial.isSelected" />
                </td>

                <!-- Remove -->
                <td class="icon-cell"
                    *ngIf="!readonly">
                    <a (click)="removeJobMaterial(jobMaterial)">
                        <i class="fa fa-remove remove-item" title="Remove sample"></i>
                    </a>
                </td>

                <td>
                    <div style="cursor:move;"
                         data-drag-type="material"
                         draggable
                         (dragStart)="dragSamplesStart()"
                         (dragStop)="dragSamplesStop()"
                         id="{{jobMaterial?.Material?.C_Material_key}}">
                        {{jobMaterial?.Material?.Identifier}}
                    </div>
                </td>
                <td class="text-nowrap">
                    {{jobMaterial.Material?.Sample.SampleName}}
                </td>
                <td>
                    <span *ngFor="let source of jobMaterial.Material?.MaterialSourceMaterial; let last = last"
                          class="text-nowrap">
                        {{source.SourceMaterial.Animal?.AnimalName}}{{source.SourceMaterial.Sample?.SampleName}}<span *ngIf="!last">, </span>
                    </span>
                </td>
                <td>
                    {{jobMaterial.Material?.Sample.cv_SampleType?.SampleType}}
                </td>
                <td>
                    {{jobMaterial.Material?.Sample.TimePoint |
                        formatTimePoint : jobMaterial.Material?.Sample.cv_TimeUnit}}
                </td>
                <td>
                    {{jobMaterial.Material?.Sample.cv_SampleStatus?.SampleStatus}}
                </td>
                <td>
                    {{jobMaterial.Material?.Sample.cv_PreservationMethod?.PreservationMethod}}
                </td>
                <td>
                    {{jobMaterial.Material?.Line?.LineName}}
                </td>
            </ng-container>
        </tr>
    </tbody>
</table>

import type { Animal } from './animal.interface';

export interface cv_AnimalMatingStatus {
    Abbreviation: string;
    Animal: Animal[];
    AnimalMatingStatus: string;
    C_AnimalMatingStatus_key: number;
    C_VocabularySource_key: number | null;
    C_Workgroup_key: number;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    ElementID: string;
    IsActive: boolean;
    IsDefault: boolean;
    IsDeprecated: boolean;
    IsExitStatus: boolean;
    ModifiedBy: string;
    SortOrder: number | null;
    Version: number;
}

import {
    CellFormatterService,
    END_STATE_CLASS,
    TableOptions
} from '../common/datatable';
import { addAuditColumnsToOptions } from '@common/datatable/utils';

export class ConstructTableOptions {

    options: TableOptions;

    constructor(
        private cellFormatterService: CellFormatterService
    ) {
        this.options = addAuditColumnsToOptions(cellFormatterService)(this.getDefaultOptions());
    }

    getDefaultOptions(): TableOptions {
        return {
            csvFileName: 'constructs.csv',
            enableDeleteColumn: true,
            enableDetailColumn: true,
            enableDraggable: false,
            enableSelectable: false,
            rowClass: (params: any) => {
                const row = params.data;
                const classes: string[] = [];
                if (row && row.IsActive !== true) {
                    classes.push(END_STATE_CLASS);
                }
                return classes;
            },
            columns: [
                { displayName: 'ID', field: 'ConstructID' },
                { displayName: 'Name', field: 'FullName' },
                { displayName: 'Alternate Name', field: 'AlternateName' },
                
                {
                    displayName: 'Antibiotic Selection', field: 'AntibioticSelection',
                    visible: false
                },
                {
                    displayName: 'Antibody', field: 'Antibody',
                    visible: false
                },
                {
                    displayName: 'Promoter', field: 'Promoter',
                    visible: false
                },
                { displayName: 'Vector', field: 'Vector' },                
                {
                    displayName: 'Vector Size', field: 'VectorSize',
                    cellClass: 'num-cell',
                    visible: false
                },
                {
                    displayName: 'Insert Size', field: 'InsertSize',
                    cellClass: 'num-cell',
                    visible: false
                },
                {
                    displayName: 'Insert Name', field: 'InsertName',
                    visible: false
                },
                {
                    displayName: 'Total Size', field: 'TotalSize',
                    cellClass: 'num-cell',
                    visible: false
                },
                {
                    displayName: 'Transgene Excision', field: 'TransgeneRestrictionEnzymes',
                    visible: false
                },
                { displayName: 'Probe', field: 'Probe' },
                {
                    displayName: 'Cloning Details', field: 'CloningDetails',
                    visible: false
                },
                {
                    displayName: 'Source', field: 'Source',
                    visible: false
                },
                {
                    displayName: 'Sequence Availability', field: 'SequenceAvailability',
                    visible: false
                },
                {
                    displayName: 'Comments', field: 'Comments',
                    visible: false
                },
                {
                    displayName: 'Active', field: 'IsActive',
                    formatter: this.cellFormatterService.booleanFormatter,
                    exportFormatter: this.cellFormatterService.booleanExportFormatter,
                    maxWidth: 80,
                    visible: false
                }
            ]
        };
    }
}

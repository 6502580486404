import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    EntityQuery,
    FilterQueryOp,
    Predicate,
    QueryResult
} from 'breeze-client';

import { notEmpty } from '../common/util/not-empty';
import { QueryDef } from './query-def';

import { DataManagerService } from './data-manager.service';
import { BaseEntityService } from './base-entity.service';
import { WebApiService } from '../services/web-api.service';

@Injectable()
export class MaterialService extends BaseEntityService {

    readonly ENTITY_TYPE = 'Materials';
    readonly ENTITY_NAME = 'Material';

    constructor(
        private dataManager: DataManagerService,
        private webApiService: WebApiService
    ) {
        super();
    }


    getMaterial(materialKey: number): Promise<any> {
        const query = EntityQuery.from(this.ENTITY_TYPE)
            .where('C_Material_key', '==', materialKey);
        return this.dataManager.returnSingleQueryResult(query);
    }

    getMaterialByIdentifier(identifier: number, materialType: string): Promise<any> {
        let query = EntityQuery.from(this.ENTITY_TYPE);

        const predicates: Predicate[] = [];
        predicates.push(Predicate.create('Identifier', '==', identifier));
        predicates.push(Predicate.create('cv_MaterialType.MaterialType', '==', materialType));
        query = query.where(Predicate.and(predicates));

        return this.dataManager.returnSingleQueryResult(query);
    }

    searchMaterials(queryDef: QueryDef): Promise<QueryResult> {
        let query = this.buildDefaultQuery(this.ENTITY_TYPE, queryDef);

        // for speed: fetch only certain columns and no entit
        const selects = [
            'C_Material_key',
            'Identifier',
            'MicrochipIdentifier',
            'Animal.AnimalName',
            'Sample.SampleName'
        ];
        query = query.select(selects.join(','))
            .noTracking();

        this.ensureDefExpanded(queryDef, 'Animal');
        this.ensureDefExpanded(queryDef, 'Sample');
        query = query.expand(queryDef.expands.join(','));

        let predicates: Predicate[] = [
            this.buildValidMaterialPredicate()
        ];
        if (queryDef.filter) {
            const namePredicates = this.buildNamePredicates(queryDef.filter);
            if (notEmpty(namePredicates)) {
                predicates = predicates.concat(Predicate.or(namePredicates));
            }
        }
        query = query.where(Predicate.and(predicates));

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    private buildNamePredicates(filter: any): Predicate[] {
        const predicates: Predicate[] = [];
        if (!filter) {
            return predicates;
        }

        if (filter.MaterialName) {
            // Search all types of materials
            predicates.push(
                Predicate.create('Sample.SampleName', FilterQueryOp.Contains, { value: filter.MaterialName })
            );
            predicates.push(
                Predicate.create('Animal.AnimalName', FilterQueryOp.Contains, { value: filter.MaterialName })
            );
            predicates.push(
                Predicate.create('MicrochipIdentifier', 'eq', filter.MaterialName)
            );
        }

        if (filter.C_Material_key) {
            predicates.push(Predicate.create('C_Material_key', '==', filter.C_Material_key));
        }

        return predicates;
    }

    getLatestTaskOutputValues(materialKeys: number[], outputKey: number): Promise<any> {
        let params = new HttpParams();
        params = params.set('outputKey', outputKey.toString());
        params = params.set('materialKeys', materialKeys.join(','));

        return this.webApiService.callApi(
            'api/outputcalculator/GetLatestValuesForMaterials',
            params
        ).then((response) => {
            return response.data.results;
        });
    }

    getTaskOutputStatistics(materialKeys: number[], outputKey: number): Promise<any> {
        let params = new HttpParams();
        params = params.set('outputKey', outputKey.toString());
        params = params.set('materialKeys', materialKeys.join(','));

        return this.webApiService.callApi(
            'api/outputcalculator/GetStatisticsForMaterials',
            params
        ).then((response) => {
            return response.data.results;
        });
    }

    private buildValidMaterialPredicate(): Predicate {
        // must have either sample or animal
        return Predicate.or([
            Predicate.create('Sample.SampleName', '!=', null),
            Predicate.create('Animal.AnimalName', '!=', null),
        ]);
    }

    create(initialValues: any): any {
        initialValues.DateCreated = new Date();
        return this.dataManager.createEntity('Material', initialValues);
    }

    /**
     * Looks up C_MaterialType_key from materialType string
     *   Uses as initial value for new Material
     * @param materialType
     * @param initialValues
     */
    createAsType(materialType: string, initialValues?: any): Promise<any> {
        if (!initialValues) {
            initialValues = {};
        }

        const query = EntityQuery.from('cv_MaterialTypes')
            .select('C_MaterialType_key')
            .where('MaterialType', '==', materialType);
        const preferLocal = true;
        return this.dataManager.returnSingleQueryResult(query, preferLocal)
            .then((materialTypeEntity) => {
                initialValues.C_MaterialType_key = materialTypeEntity.C_MaterialType_key;
                return this.create(initialValues);
            });
    }

    /**
     * Looks up C_MaterialType_key from materialType string
     * @param materialType
     */
    getMaterialTypeKey(materialType: string): any {
        const query = EntityQuery.from('cv_MaterialTypes')
            .select('C_MaterialType_key')
            .where('MaterialType', '==', materialType);
        const preferLocal = true;
        return this.dataManager.returnSingleQueryResult(query, preferLocal)
            .then((materialTypeEntity) => {
                return materialTypeEntity.C_MaterialType_key;
            });
    }

    deleteMaterial(material: any) {
        if (material.MaterialPoolMaterial) {
            while (material.MaterialPoolMaterial.length > 0) {
                this.dataManager.deleteEntity(material.MaterialPoolMaterial[0]);
            }
        }

        this.dataManager.deleteEntity(material);
    }

    private deleteStoredFileMaps(storedFileMaps: any) {
        if (storedFileMaps) {
            while (storedFileMaps.length > 0) {
                const storedFileMap = storedFileMaps[0];
                this.dataManager.deleteEntity(storedFileMap);
                // Intentionally does not delete related StoredFile
            }
        }
    }
}

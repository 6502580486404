import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from "@angular/core";

export interface SelectionOption {
    value: string;
    label: string;
    checked: boolean;
}
@Component({
    selector: 'checkbox-multi-select',
    templateUrl: './checkbox-multi-select.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxMultiSelectComponent implements OnInit, OnChanges, OnDestroy {
    @Input() options: SelectionOption[];
    @Input() isDefaultSelection = false;
    @Output() selectionChange: EventEmitter<string[]> = new EventEmitter<string[]>();

    selections: string[] = [];

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.options && !changes.options.firstChange) {
            this.resetSelections();
        }
    }

    ngOnInit(): void {
        if (this.isDefaultSelection) {
            this.selections = this.options.reduce((acc, item) => {
                if (item.checked) {
                    acc.push(item.value);
                }
                return acc;
            }, []);
        }
    }

    ngOnDestroy(): void {
        this.resetSelections();
    }

    onSelectionChange(option: SelectionOption, event: Event) {
        const isChecked = (<HTMLInputElement> event.target).checked;
        option.checked = isChecked;
        if (isChecked) {
            this.selections.push(option.value);
        } else {
            const index = this.selections.indexOf(option.value);
            this.selections.splice(index, 1);
        }
        this.selectionChange.emit(this.selections);
    }

    private resetSelections() {
        this.selections = [];
        this.selectionChange.emit(this.selections);
    }
}

import {
    Component,
    EventEmitter,
    Input,
    Output,
    OnInit
} from '@angular/core';

import { UserService } from './user.service';
import * as escapeStringRegexp from 'escape-string-regexp';

@Component({
    selector: 'user-multiselect',
    template: `
<!-- Selected users -->
<div *ngIf="model && model.length > 0">
    <table class="table table-sm table-borderless table-nonfluid">
        <tbody>
            <tr *ngFor="let item of model; let i = index;">
                <td class="icon-cell">
                    <a (click)="removeUser(i)" title="Remove">
                        <i class="fa fa-remove remove-item"></i>
                    </a>
                </td>
                <td>
                    {{ resultFormatter(item) }}
                </td>
            </tr>
        </tbody>
    </table>
</div>

<!-- user typeahead -->
    <climb-indirect-typeahead
        [search]="searchUsers"
        [resultFormatter]="resultFormatter"
        placeholder="{{ placeholder }}"
        (selectItem)="selectUser($event)"></climb-indirect-typeahead>
`,
    styles: [`
        .table {
            margin-bottom: 0.25em;
        }
    `]
})
export class UserMultiselectComponent implements OnInit {
    @Input() model: any[];
    @Input() id: string;
    // Disallow free-text entries?
    @Input() forceValidSelection = true;
    @Input() placeholder = 'Created By name...';
    @Input() required: boolean;

    @Output() modelChange: EventEmitter<any> = new EventEmitter<any>();

    private _usersPromise: Promise<any>;

    readonly MAX_RESULTS = 50;


    constructor(
        private userService: UserService
    ) {
        //
    }


    ngOnInit() {
        this._getUsers();
    }

    private _getUsers() {
        this._usersPromise = this.userService.getWorkgroupUsers()
            .then((data: any[]) => {
                return data.map((user: any) => {
                    return {
                        C_WorkgroupUser_key: user.C_WorkgroupUser_key,
                        User: {
                            Id: user.User.Id,
                            FirstName: user.User.FirstName,
                            LastName: user.User.LastName,
                            UserName: user.User.UserName,
                        },
                    };
                });
            });
    }

    searchUsers = (search: string): Promise<any[]> => {
        if (!this._usersPromise) {
            this._getUsers();
        }

        return this._usersPromise.then((users) => {
            let results: any[] = [];

            if (users) {
                results = users.filter((choice: any) => {
                    // return match if text is empty
                    return !search || this._matchTypeaheadChoice(search, choice);
                });
            }

            results = this._limitResults(results);

            return results;
        });
    }

    private _matchTypeaheadChoice(search: string, choice: any): boolean {
        const display = this.resultFormatter(choice);
        const escapedSearch = escapeStringRegexp(search);
        return new RegExp(escapedSearch, 'gi').test(display);
    }
    private _limitResults(results: any[]): any[] {
        if (results.length > this.MAX_RESULTS) {
            return results.splice(0, this.MAX_RESULTS);
        } else {
            return results;
        }
    }

    removeUser(index: number) {
        if (this.model && index >= 0 && index < this.model.length) {
            this.model.splice(index, 1);
        }
        this.modelChange.emit(this.model);
    }

    selectUser(user: any) {
        if (this.model) {
            this.model.push(user);
        } else {
            this.model = [user];
        }

        this.modelChange.emit(this.model);
    }

    resultFormatter = (item: any) => {
        if (item && item.User) {
            return item.User.FirstName +
                ' ' +
                item.User.LastName +
                ' (' +
                item.User.UserName +
                ')';
        } else {
            return item;
        }
    }

    keyFormatter = (item: any) => {
        if (item && item.User) {
            return item.User.UserName;
        } else {
            return item;
        }
    }

}

<div class="bulk-edit-wrapper">

    <bulk-edit-toolbar [logTag]="COMPONENT_LOG_TAG"
                       (exit)="exitClicked()"
                       [options]="bulkTemplates.bulkOptions"
                       [facet]="facet"
                       (save)="onSaveHousings()"
                       [inactiveFields]="inactiveFields"
                       #bulkEditToolbar>
    </bulk-edit-toolbar>

    <div class="bulk-edit-grid">
        <loading-overlay *ngIf="saving" [message]="savingMessage"></loading-overlay>
        <bulk-edit-table [options]="bulkTemplates.bulkOptions"
                         [facet]="facet"
                         [itemsToEdit]="materialPools"></bulk-edit-table>
    </div>
</div>
    
<housing-bulk-templates [materialPools]="materialPools"
                        [activeFields]="activeFields"
                        [requiredFields]="requiredFields" #bulkTemplates></housing-bulk-templates>

import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { HttpCancelService } from '@services/http-cancel.service';

@Injectable()
export class HttpCancelInterceptor implements HttpInterceptor {
    constructor(
        private readonly httpCancelService: HttpCancelService,
    ) { }

    intercept<T>(request: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
        return next.handle(request).pipe(
            takeUntil(this.httpCancelService.onCancelPendingRequests()),
        );
    }
}

import { Entity, EntityState, MetadataStore } from 'breeze-client';
import { sortObjectArrayByProperty } from '../../common/util/sort-array';
import { notEmpty } from '../../common/util/not-empty';


export class BatchOrder {
    deleteOrder: string[];
    addOrder: string[];
}

export function generateBatchOrder(metadataStore: MetadataStore): BatchOrder {
    return getBatchOrder(genParentTree(metadataStore));
}

/*
* Generate a tree of child -> parent entities from
*   Breeze's current metadata schema
*/
function genParentTree(metadataStore: any): Record<string, any> {
    const structMap: any = metadataStore._structuralTypeMap;

    // get child -> parent edges 
    const parentTree: any = {};

    for (const entityName in structMap) {
        if (!structMap.hasOwnProperty(entityName)) {
            continue;
        }
        const entity: any = structMap[entityName];
        const childName = entity.name;

        if (!parentTree.hasOwnProperty(childName)) {
            parentTree[childName] = [];
        }

        const keys: any[] = entity.foreignKeyProperties;
        
        for (const key of keys) {
            const navProp = key.relatedNavigationProperty;
            if (!navProp) {
                continue;
            }
            const parent: string = navProp.entityTypeName;
            const parentName: string = parent;

            // ignore direct recursion relationship
            // e.g. Material -> Material
            if (parentName === childName) {
                continue;
            }

            parentTree[childName].push(parentName);
        }
    }

    return parentTree;
}

/*
* Return the delete and add order for all possible entity batches
*  given a tree of child -> parent relationships
*
* NOTE: exported for testing
*/
export function getBatchOrder(parentTree: Record<string, any>) {
    const entityList: any[] = [];
    for (const node in parentTree) {
        if (!parentTree.hasOwnProperty(node)) {
            continue;
        }
        const numParents = getMaxDepth(node, parentTree, 0);
        entityList.push({name: node, maxParents: numParents});
    }
    
    sortObjectArrayByProperty(entityList, 'maxParents');

    const addOrder = entityList.map((entity) => {
        return entity.name;
    });

    const deleteOrder = addOrder.slice().reverse();

    return {
        deleteOrder,
        addOrder
    };
}

/*
* Return max depth of a node in a string: string object tree
* NOTE: does not handle recursion loops
*/
function getMaxDepth(key: string, tree: Record<string, any>, depth: number) {

    if (tree.hasOwnProperty(key)) {
        const nodes: string[] = tree[key];
        let newDepth = depth;

        for (const node of nodes) {
            newDepth = Math.max(newDepth,
                getMaxDepth(node, tree, depth + 1)
            );
        }

        depth = newDepth;
    }

    return depth;
}

export function createBatches(manager: any, batchOrder: BatchOrder, entities?: Entity[]): any[] {
    const batches: any[] = [];

    // do deletes
    for (const entityName of batchOrder.deleteOrder) {
        let batch: Entity[] = [];
        if (entities) {
            batch = entities.filter(entity => entity.entityAspect.entityState.isDeleted() && entity.entityType.name === entityName);
        } else {
            batch = manager.getEntities([entityName], [EntityState.Deleted]);
        }
        if (notEmpty(batch)) {
            batches.push(batch);
        }
    }

    // do adds
    for (const entityName of batchOrder.addOrder) {
        let batch: Entity[] = [];
        if (entities) {
            batch = entities.filter(entity => entity.entityAspect.entityState.isAdded() && entity.entityType.name === entityName);
        } else {
            batch = manager.getEntities([entityName], [EntityState.Added]);
        }
        if (notEmpty(batch)) {
            batches.push(batch);
        }
    }

    if (entities) {
        batches.push(entities.filter(entity => entity.entityAspect.entityState.isModified()));
    }

    return batches;
}

<form role="form" class="form-label-right">

    <div class="modal-header justify-content-center">
        <h4 class="modal-title">Housing Filter</h4>
    </div>

    <div class="modal-body">

        <div class="form-group row">
            <div class="col-md-3">
                <label class="col-form-label" style="display: block;">ID</label>
            </div>
            <div class="col-md-9">
                <housing-multiselect [(model)]="filter.MaterialPoolIDs"></housing-multiselect>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-3 col-form-label">Type</label>
            <div class="col-md-9">
                <select name="resourceKey"
                        [(ngModel)]="filter.C_MaterialPoolType_key"
                        class="form-control input-medium">
                    <option></option>
                    <option *ngFor="let item of materialPoolTypes"
                            [value]="item.C_MaterialPoolType_key">
                        {{item.MaterialPoolType}}
                    </option>
                </select>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-3 col-form-label">Status</label>
            <div class="col-md-9">
                <climb-multi-select [(model)]="filter.C_MaterialPoolStatus_keys"
                                    [values]="materialPoolStatuses"
                                    [keyProperty]="'C_MaterialPoolStatus_key'"
                                    [valueProperty]="'MaterialPoolStatus'"
                                    [placeholder]="'Select Statuses...'"></climb-multi-select>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-3 col-form-label">Date Range</label>
            <div class="col-md-4">
                <climb-ngb-date #dateControl="ngModel"
                                name="Date Start"
                                [(ngModel)]="filter.DatePooledStart"
                                placeholder="From&hellip;"></climb-ngb-date>
            </div>
            <div class="col-md-4">
                <climb-ngb-date #dateControl="ngModel"
                                name="Date End"
                                [(ngModel)]="filter.DatePooledEnd"
                                placeholder="To&hellip;"></climb-ngb-date>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-3 col-form-label">Owner</label>
            <div class="col-md-9">
                <user-select [(model)]="filter.Owner"
                             [forceValidSelection]="false"></user-select>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-3 col-form-label">Location</label>
            <div class="col-md-9">
                <input type="text"
                       name="location"
                       [(ngModel)]="filter.Location"
                       class="form-control input-medium" />
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-3 col-form-label">{{'Job' | translate}} Name</label>
            <div class="col-md-9">
                <input type="text"
                       name="jobNumber"
                       [(ngModel)]="filter.JobID"
                       class="form-control input-medium" />
            </div>
        </div>
        <div class="form-group row">
            <label class="col-md-3 col-form-label">Species</label>
            <div class="col-md-9">
                <select name="taxon"
                        [(ngModel)]="filter.C_Taxon_key"
                        class="form-control input-medium">
                    <option></option>
                    <option *ngFor="let item of taxons"
                            [value]="item.C_Taxon_key">
                        {{item.Taxon}}
                    </option>
                </select>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-md-3 col-form-label">Count Range</label>
            <div class="col-md-9">
                <input type="number"
                       name="countPooledStart"
                       placeholder="From&hellip;"
                       [(ngModel)]="filter.CountPooledStart"
                       class="form-control-inline input-small" />
                -
                <input type="number"
                       name="countPooledEnd"
                       placeholder="To&hellip;"
                       [(ngModel)]="filter.CountPooledEnd"
                       class="form-control-inline input-small" />
            </div>
        </div>
        <div class="form-group row">
            <label class="col-sm-3 col-form-label">Empty Housing Units Only</label>
            <div class="col-md-9">
                <input class="mt-1"
                       type="checkbox"
                       name="includeOnlyEmptyPools"
                       [(ngModel)]="filter.IncludeOnlyEmptyPools"/>
            </div>
        </div>
    </div>

    <div class="modal-footer">
        <span class="btn-set">
            <button type="submit"
                    class="btn btn-fw-wide btn-lg btn-primary"
                    (click)="filterClicked($event)">
                Filter
            </button>
            <button data-automation-id="clear-filter-button"
                    type="button"
                    class="btn btn-fw-wide btn-lg btn-secondary"
                    (click)="clearClicked()">
                Clear Filter
            </button>
        </span>
        <span class="btn-set">
            <button type="button"
                    class="btn btn-lg btn-secondary"
                    (click)="closeClicked()">
                Close
            </button>
        </span>
    </div>
</form>

import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  name: 'mapFn',
})
export class MapFnPipe implements PipeTransform {
  /**
   * Pipe for memoize function execution in the template
   *
   * {{ argument | mapFn : method [: context] }}
   *
   * @param value an item to transform
   * @param handler a mapping function
   * @param context a function's context if it requires
   */
  transform<T, G>(value: T, handler: (value: T) => G, context?: unknown): G {
    if (context) {
      return handler.call(context, value);
    }

    return handler(value);
  }
}

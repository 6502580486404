import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    OnDestroy,
    ViewChildren,
} from '@angular/core';

import { HousingVocabService } from '../../services/housing-vocab.service';

import { BaseFilter } from '../../../common/facet';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
    focusElementByQuery
} from '../../../common/util';
import { Subscription } from 'rxjs';
import { NgModel } from '@angular/forms';
import { dateControlValidator } from '@common/util/date-control.validator';
import { LoggingService } from '@services/logging.service';


@Component({
    selector: 'housing-filter',
    templateUrl: './housing-filter.component.html'
})
export class HousingFilterComponent extends BaseFilter implements OnInit, OnDestroy {
    @ViewChildren('dateControl') dateControls: NgModel[];

    @Input() filter: any = {};
    @Output() onFilter = new EventEmitter<any>();
    @Output() close = new EventEmitter<any>();

    // CVs
    materialPoolTypes: any[] = [];
    materialPoolStatuses: any[] = [];
    taxons: any[] = [];
    _subs: Subscription[] = [];

    readonly MULTI_PASTE_INPUT_LIMIT = 100;

    constructor(
        private housingVocabService: HousingVocabService,
        private activeModal: NgbActiveModal,
        private loggingService: LoggingService,
    ) {
        super(activeModal);
    }

    ngOnInit() {
        this.cloneFilter();

        this.getCVs();

        focusElementByQuery('[data-auto-focus="materialPoolID"] input');
    }

    private cloneFilter() {
        const datePooledStart = this.filter.DatePooledStart;
        const datePooledEnd = this.filter.DatePooledEnd;

        this.filter = this.copyFilter(this.filter);

        this.filter.DatePooledStart = datePooledStart;
        this.filter.DatePooledEnd = datePooledEnd;
    }

    ngOnDestroy() {
        for (const s of this._subs) {
            s.unsubscribe();
        }
    }

    private getCVs() {
        this.housingVocabService.materialPoolTypes$
            .subscribe((data) => {
                this.materialPoolTypes = data;
            });
        this.housingVocabService.materialPoolStatuses$
            .subscribe((data) => {
                this.materialPoolStatuses = data;
            });
        this.housingVocabService.taxons$
            .subscribe((taxons) => {
                this.taxons = taxons;
            });
    }

    filterClicked(event: Event) {
        const errMessage = dateControlValidator(this.dateControls);
        if (errMessage) {
            this.loggingService.logError(errMessage, null, '', true);
            return;
        }

        event.preventDefault();
        this.onFilter.emit(this.filter);
        this.close.emit();
        this.activeModal.close();
    }

    closeClicked() {
        this.close.emit();
        this.activeModal.dismiss();
    }

    clearClicked() {
        this.filter = {};
        this.onFilter.emit(this.filter);
        this.close.emit();
        this.activeModal.close();
    }
}

import type { Study } from './study.interface';
import type { TaskInstance } from './task-instance.interface';

export interface TaskStudy {
    C_Study_key: number;
    C_TaskInstance_key: number;
    C_TaskStudy_key: number;
    C_Workgroup_key: number;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    ModifiedBy: string;
    Sequence: number;
    Study: Study;
    TaskInstance: TaskInstance;
    Version: number;
}

import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';
import { GenotypeVocabService } from './genotype-vocab.service';
import { GenotypeComboItem, SearchItem } from './genotype-combo-item';
import * as escapeStringRegexp from 'escape-string-regexp';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
    selector: 'genotype-combo-select',
    templateUrl: './genotype-combo-select.component.html',
    styles: [`
        .table {
            margin-bottom: 0.25em;
        }
    `]
})
export class GenotypeComboSelectComponent implements OnInit {
    @Input() model: GenotypeComboItem[];
    @Output() modelChange: EventEmitter<any> = new EventEmitter<any>();


    // display strings
    ANY_ASSAY_TEXT = 'Any assay';
    NO_VALUE_TEXT = 'No symbol';
    ANY_SYMBOL_TEXT = 'Any symbol';

    // state variables
    genotypeAssays: any[];
    choices: GenotypeComboItem[];

    constructor(
        private genotypeVocabService: GenotypeVocabService
    ) {}

    ngOnInit() {
        this.choices = [];
        this.genotypeAssays = [];

        if (!this.model) {
            this.model = [];
        }

        this.getCVs().subscribe(() => {
            this.refreshChoices();
        });
    }

    getCVs(): Observable<any> {
        return this.genotypeVocabService.genotypeAssays$.pipe(
            map((data) => {
                this.genotypeAssays = data;
                return data;
            }));
    }

    refreshChoices() {
        this.choices = [];

        // global options
        this.choices.push(this._makeAnyAssayAnySymbolCombo());
        this.choices.push(this._makeAnyAssayNullSymbolCombo());

        for (const assay of this.genotypeAssays) {

            // add all/any symbol search
            const anySymbol: GenotypeComboItem = {
                assay: this._makeAssayItem(assay),
                symbol: {
                    display: this.ANY_SYMBOL_TEXT,
                    key: null,
                    searchNulls: false,
                    searchAny: true
                }
            };

            // add null symbol search
            const nullSymbol: GenotypeComboItem = {
                assay: this._makeAssayItem(assay),
                symbol: {
                    display: this.NO_VALUE_TEXT,
                    key: null,
                    searchNulls: true,
                    searchAny: false
                }
            };

            const symbolCombos: GenotypeComboItem[] = assay.GenotypeAssayGenotypeSymbol
                .map((item: any) => {
                    return this._makeCombo(assay, item.cv_GenotypeSymbol);
                });

            this.choices.push(anySymbol);
            this.choices.push(nullSymbol);
            this.choices = this.choices.concat(symbolCombos);
        }
    }

    _makeAnyAssayAnySymbolCombo(): GenotypeComboItem {
        return {
            assay: {
                display: this.ANY_ASSAY_TEXT,
                key: null,
                searchNulls: false,
                searchAny: true
            },
            symbol: {
                display: this.ANY_SYMBOL_TEXT,
                key: null,
                searchNulls: false,
                searchAny: true
            }
        };
    }

    _makeAnyAssayNullSymbolCombo(): GenotypeComboItem {
        return {
            assay: {
                display: this.ANY_ASSAY_TEXT,
                key: null,
                searchNulls: false,
                searchAny: true
            },
            symbol: {
                display: this.NO_VALUE_TEXT,
                key: null,
                searchNulls: true,
                searchAny: false
            }
        };
    }

    _makeCombo(genotypeAssay: any, genotypeSymbol: any): GenotypeComboItem {
        return {
            assay: this._makeAssayItem(genotypeAssay),
            symbol: this._makeSymbolItem(genotypeSymbol)
        };
    }

    _makeAssayItem(genotypeAssay: any): SearchItem {
        return {
            display: genotypeAssay.GenotypeAssay,
            key: genotypeAssay.C_GenotypeAssay_key,
            searchNulls: false,
            searchAny: false
        };
    }

    _makeSymbolItem(genotypeSymbol: any): SearchItem {
        return {
            display: genotypeSymbol.GenotypeSymbol,
            key: genotypeSymbol.C_GenotypeSymbol_key,
            searchNulls: false,
            searchAny: false
        };
    }

    searchCombos = (text: string): Promise<any[]> => {
        let results: GenotypeComboItem[] = [];
        const maxResults = 100;

        if (this.choices) {
            results = this.choices.filter((choice) => {
                return this.matchTypeaheadChoice(text, choice);
            });
        }

        if (results.length > maxResults) {
            results = results.splice(0, maxResults);
        }

        return Promise.resolve(results);
    }

    matchTypeaheadChoice(text: string, choice: GenotypeComboItem): boolean {
        const display = this.comboFormatter(choice);
        const escapedSearch = escapeStringRegexp(text);
        return new RegExp(escapedSearch, 'gi').test(display);
    }

    comboFormatter = (item: GenotypeComboItem) => {
        const assayDisplay = item.assay ? item.assay.display : "";
        const symbolDisplay = item.symbol ? item.symbol.display : "";
        if (assayDisplay.length !== 0 && symbolDisplay.length !== 0) {
            return assayDisplay + ": " + symbolDisplay;
        } else {
            return "";
        }
    }

    selectItem(item: GenotypeComboItem) {
        this.model.push(item);
        this.modelChange.emit(this.model);
    }

    removeItem(item: GenotypeComboItem) {
        const index = this.model.indexOf(item);
        if (index > -1) {
            this.model.splice(index, 1);
            this.modelChange.emit(this.model);
        }
    }
}

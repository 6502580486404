import { VocabularyService } from './../vocabularies/vocabulary.service';
import { DataType } from './data-type.enum';
import {
    AfterContentChecked,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';

import { DateFormatterService } from '@common/util/date-time-formatting';
import {
    randomId,
    getSafeProp,
    formatDecimal,
} from '@common/util';
import * as escapeStringRegexp from 'escape-string-regexp';
import { EnumerationService } from '../enumerations/enumeration.service';

import { FeatureFlagService } from '../services/feature-flags.service';
import { DateTime } from 'luxon';

@Component({
    selector: 'data-type-input',
    templateUrl: './data-type-input.component.html',
    styles: [`
        input, 
        select, 
        textarea {
            display: inline-block;
        }
    `]
})
export class DataTypeInputComponent implements OnChanges, OnInit, AfterContentChecked {
    // Input or output object
    @Input() ioObject: any;
    // TaskInput.InputValue or TaskOutput.OutputValue, e.g.
    @Input() value: string;
    @Input() inputLabel: string;
    @Input() id: string;
    @Input() cssClass: string;
    @Input() readonly: boolean;
    @Input() forceInputOnNewLine: boolean;
    @Input() container = 'body';
    @Input() emitOnBlur: boolean;
    @Input() dynamicWidth: boolean;

    @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();

    @ViewChild("inputWidthCheck") inputWidthCheck: ElementRef;

    // state
    _convertedModelValue: any;
    enumerationItems: any[];
    _enumItemsPromise: Promise<any>;
    vocabularyItems: any[];

    // data-type constants
    readonly DataType = DataType;

    vocabChoices: any[] = [];

    selectLengthChecked: boolean;
    inputLengthChecked: boolean;

    inputWidth: number;

    isGLP: boolean;

    luxonDateFormatting: string;
    luxonDateTimeFormatting: string;

    constructor(
        private enumerationService: EnumerationService,
        private vocabularyService: VocabularyService,
        private featureFlagService: FeatureFlagService,
        private dateFormatterService: DateFormatterService
    ) {
        //
    }

    initIsGLP() {
        const flag = this.featureFlagService.getFlag("IsGLP");
        this.isGLP = (flag && flag.IsActive && flag.Value.toLowerCase() === "true");
    }

    ngOnInit() {
        if (!this.ioObject) {
            this.ioObject = {};
        }

        if (!this.id) {
            this.id = randomId();
        }

        if (!this.cssClass) {
            this.cssClass = 'input-medium';
        }

        this._enumItemsPromise = Promise.resolve([]);
        this.initIsGLP();

        this.initLuxonTimeFormat();

        this.convertModel();
        this.setEnumerationItems();
        this.setVocabularyItems();
        this.setVocabItems();
        this.inputWidth = 0;
    }

    ngOnChanges(changes: any) {
        if (changes.ioObject) {
            if (!this.ioObject) {
                this.ioObject = {};
            }
            this.initLuxonTimeFormat();
            this.convertModel();

            if (!changes.ioObject.firstChange) {
                this.setEnumerationItems();
                this.setVocabularyItems();
                this.setVocabItems();
            }
        }

        if (changes.value) {
            this.convertModel();
        }
    }

    ngAfterContentChecked() {
        if (this.dynamicWidth && !this.selectLengthChecked) {
            this.updateSelectWidth(this.id);
        }
        
        if (this.dynamicWidth && !this.inputLengthChecked && this.inputWidthCheck) {
            this.updateInputWidth();
        }
        
    }
    
    initLuxonTimeFormat(): void {
        this.luxonDateFormatting = this.dateFormatterService.resolveDateFormat();
        this.luxonDateTimeFormatting = this.dateFormatterService.resolveDateTimeFormat(false, false);
    }

    updateSelectWidth(id: string): void {
        if (this.getDataType() === DataType.ENUMERATION) {
            const selected = jQuery("#" + id).first();
            const selectedOption = jQuery("#" + id + " option:selected").first();
            const aux = jQuery("<select/>").append(jQuery("<option/>").text(selectedOption.text()));
            selected.after(aux);
            selected.width(aux.width());
            if (aux.width() > 0) {
                this.selectLengthChecked = true;
            }
            aux.remove();
        }
    }

    updateInputWidth(): void {
        if (this.getDataType() === DataType.NUMBER || this.getDataType() === DataType.TEXT) {
            this.inputWidth = this.inputWidthCheck.nativeElement.offsetWidth;
            if (this.inputWidth > 0) {
                this.inputLengthChecked = true;
            }
        }

    }

    getDataType(): string {
        return getSafeProp(this.ioObject, 'cv_DataType.DataType') || "";
    }

    /**
     * Convert model to proper type based on dataType
     */
    convertModel() {
        let convertedModel: any = null;

        if (this.value) {
            convertedModel = this.value === "NaN" ? 0 : this.value;

            switch (this.getDataType()) {
                case DataType.BOOLEAN:
                    convertedModel = this.convertBoolean(this.value);
                    break;
                case DataType.DATE:
                    convertedModel = this.convertDateString(this.value);
                    break;
                case DataType.DATETIME:
                    convertedModel = this.convertDateTimeString(this.value);
                    break;
                case DataType.NUMBER:
                    convertedModel = +this.value;
                    break;
            }
        }

        this._convertedModelValue = convertedModel;
    }

    convertBoolean(value: string): boolean {
        return value.toLowerCase() === 'true' ? true : false;
    }

    convertDateString(value: string): Date {
        // Slice is to make sure the time is removed from input
        const lDate = DateTime.fromFormat(value.slice(0, 10), this.luxonDateFormatting);
        if (lDate.isValid) {
            return lDate.toJSDate();
        }
        return null;
    }

    convertDateTimeString(value: string): Date {
        const lDate = DateTime.fromFormat(value, this.luxonDateTimeFormatting);
        if (lDate.isValid) {
            return lDate.toJSDate();
        }
        return null;
    }

    /**
     * Format _convertedModelValue back to InputValue/OutputValue string
     */
    formatValue() {
        const internalValue = this._convertedModelValue;
        let newValue = internalValue;

        switch (this.getDataType()) {
            case DataType.BOOLEAN:
                newValue = this.formatBoolean(internalValue);
                break;
            case DataType.DATE:
                newValue = this.formatDateString(internalValue);
                break;
            case DataType.DATETIME:
                newValue = this.formatDateTimeString(internalValue);
                break;
        }

        this.value = newValue;

        this.valueChange.emit(this.value);
    }

    onBlur() {
        if (this.ioObject.DecimalPlaces && this.ioObject.DecimalPlaces >= 0) {
            switch (this.getDataType()) {
                case DataType.NUMBER:
                    this._convertedModelValue = formatDecimal(this._convertedModelValue, this.ioObject.DecimalPlaces);
                    this.value = this._convertedModelValue;
                    this.valueChange.emit(this.value);
                    break;
            }
        }
    }

    formatBoolean(value: boolean): string {
        return value ? 'true' : 'false';
    }

    formatDateString(value: Date): string {
        return this.dateFormatterService.formatDateOnly(value);
    }

    formatDateTimeString(value: Date): string {
        return this.dateFormatterService.formatDateTimeAsLocal(value);
    }

    setEnumerationItems() {
        this.enumerationItems = [];
        const classKey = this.ioObject.C_EnumerationClass_key;
        if (classKey) {
            this._enumItemsPromise = this.enumerationService.getEnumerationItems(classKey)
                .then((items) => {
                    this.enumerationItems = items;
                    this.sortEnumerationItemsByReverseKey();
                });
        }
    }

    private sortEnumerationItemsByReverseKey() {
        // sorts items from most recently added to least recently added
        this.enumerationItems.sort((a, b) => {
            a = a.C_EnumerationItem_key;
            b = b.C_EnumerationItem_key;

            if (a < b) {
                return 1;
            }
            if (a > b) {
                return -1;
            }

            return 0;
        });
    }

    searchEnumerations = (search: string): Promise<any> => {
        return this._enumItemsPromise.then(() => {
            const escapedSearch = escapeStringRegexp(search);
            const regex = new RegExp(escapedSearch, 'gi');
            const matches = this.enumerationItems.filter((item) => {
                return regex.test(item.ItemName);
            });
            return Promise.resolve(matches);
        });
    }

    searchEnumerationsForExactMatch = (search: string): Promise<any> => {
        return this._enumItemsPromise.then(() => {
            const matches = this.enumerationItems.filter((item) => {
                return item.ItemName === search;
            });
            return Promise.resolve(matches);
        });
    }

    enumFormatter = (value: any) => {
        return value.ItemName;
    }

    setVocabularyItems() {
        this.vocabularyItems = [];
        const classKey = this.ioObject.C_VocabularyClass_key;
        if (classKey === 1) {
            this.getTestArticles();
        } else if (classKey === 2) {
            this.getIACUCProtocols();
        }
    }

    getTestArticles(): Promise<any> {
        return this.vocabularyService.getCV('cv_TestArticles', null, null, true).then((items) => {
            items.forEach((item: any) => {
                item.ItemName = item.TestArticle;
                item.C_VocabularyItem_key = item.C_TestArticle_key;
            });
            this.vocabularyItems = items;
        });
    }

    getIACUCProtocols(): Promise<any> {
        return this.vocabularyService.getCV('cv_IACUCProtocols', null, null, true).then((items) => {
            items.forEach((item: any) => {
                item.ItemName = item.IACUCProtocol;
                item.C_VocabularyItem_key = item.C_IACUCProtocol_key;
            });
            this.vocabularyItems = items;
        });
    }

    /**
     * Sets the SearchParam Value to the output key.
     *
     * @param output
     */
    onSelectedOutputChange(output: any) {
        if (this.getDataType() === DataType.OUTPUT_SELECT && output) {
            this._convertedModelValue = output.C_Output_key;
            this.formatValue();
        }
    }

    setVocabItems(): Promise<any> {
        const vocabName = getSafeProp(this.ioObject, 'ControlledVocabulary.TableName');
        if (!vocabName) {
            return Promise.resolve();
        }
        const entityName = this.pluralizeVocab(vocabName);
        const sort = getSafeProp(this.ioObject, 'ControlledVocabulary.ValueColumnName')
            + ' asc';
        const preferLocal = true;
        return this.vocabularyService.ensureCVLoaded(entityName).then(() => {
            return this.vocabularyService.getCV(entityName, sort, preferLocal);
        }).then((data) => {
            this.vocabChoices = data;
        });
    }

    pluralizeVocab(vocabName: string): string {
        if (vocabName.endsWith('Status') || vocabName.endsWith('Sex')) {
            return vocabName + 'es';
        }
        return vocabName + 's';
    }
    
    vocabKeyFormatter = (item: any) => {
        const pkName = 'C' + this.ioObject.ControlledVocabulary.PKColumnName;
        return item[pkName];
    }

    vocabNameFormatter = (item: any) => {
        const valueName = this.ioObject.ControlledVocabulary.ValueColumnName;
        return item[valueName];
    }
}

import type { Input } from './input.interface';
import type { JobWorkflowTask } from './job-workflow-task.interface';
import type { Note } from './note.interface';
import type { Output } from './output.interface';
import type { ProtocolTask } from './protocol-task.interface';
import type { StoredFileMap } from './stored-file-map.interface';
import type { TaskInstance } from './task-instance.interface';
import type { cv_TaskType } from './cv-task-type.interface';

export interface WorkflowTask {
    AutomaticallyEndTask: boolean;
    C_ParentWorkflowTask_key: number | null;
    C_TaskType_key: number | null;
    C_WorkflowTask_key: number;
    C_Workgroup_key: number;
    CreatedBy: string;
    CurrentJobWorkflowTask: JobWorkflowTask[];
    DateCreated: Date;
    DateModified: Date;
    Description: string;
    Input: Input[];
    IsActive: boolean;
    IsEditable: boolean;
    IsHidden: boolean;
    ModifiedBy: string;
    NoMaterials: boolean;
    Note: Note[];
    OriginalJobWorkflowTask: JobWorkflowTask[];
    Output: Output[];
    ParentWorkflowTask: WorkflowTask;
    ProtocolTask: ProtocolTask[];
    SingleAnimal: boolean;
    SingleOutputPerAnimal: boolean;
    SortOrder: number | null;
    StoredFileMap: StoredFileMap[];
    TaskInstance: TaskInstance[];
    TaskName: string;
    TaskNameSortable: string;
    Version: number;
    // Accounting
    Cost: number | null;
    IsCostPerMaterial: boolean;
    Duration: number | null;
    IsDurationPerMaterial: boolean;
    Effort: number | null;
    IsEffortPerMaterial: boolean;
    // Animal context data
    ShowAgeInDays: boolean;
    ShowAgeInWeeks: boolean;
    ShowAnimalComments: boolean;
    ShowAnimalStatus: boolean;
    ShowBirthDate: boolean;
    ShowHousingID: boolean;
    ShowMarker: boolean;

    cv_TaskType: cv_TaskType;
}

import type { Material } from './material.interface';
import type { Plate } from './plate.interface';
import type { PlatePosition } from './plate-position.interface';

export interface PlateMaterial {
    C_Material_key: number | null;
    C_PlateMaterial_key: number;
    C_PlatePosition_key: number;
    C_Plate_key: number;
    C_Workgroup_key: number;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    IsActive: number | null;
    Material: Material;
    ModifiedBy: string;
    Plate: Plate;
    PlatePosition: PlatePosition;
    Version: number;
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CdkTableModule } from '@angular/cdk/table';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { IconModule } from '@icons';
import { TableComponent, RecycleRowsDirective } from './table.component';
import { CellDefDirective, FooterCellDefDirective, HeaderCellDefDirective } from './cell/cell-def.directive';
import { ColumnDefDirective } from './cell/column-def.directive';
import {
    FooterRowComponent,
    FooterRowDefDirective,
    HeaderRowComponent,
    HeaderRowDefDirective,
    NoDataRowDirective,
    RowComponent,
    RowDefDirective,
} from './row.component';
import { CellComponent } from './cell/cell.component';
import { FooterCellComponent } from './cell/footer-cell.component';
import { HeaderCellComponent } from './cell/header-cell.component';
import { ColumnResizeDirective } from './resize/column-resize.directive';
import { TableSortDirective } from './sort/table-sort.directive';
import { SortComponent } from './sort/sort.component';
import { HeaderDragDirective } from './drag-drop/header-drag.directive';
import { HeaderDropListDirective } from './drag-drop/header-drop-list.directive';
import { RowDragDirective } from './drag-drop/row-drag.directive';
import { RowDropListDirective } from './drag-drop/row-drop-list.directive';
import { AddItemButtonComponent } from './add-item-button/add-item-button.component';
import { DetailButtonComponent } from './detail-button/detail-button.component';
import { DeleteItemButtonComponent } from './delete-item-button/delete-item-button.component';
import { DragRowHandleComponent } from './drag-row-handle/drag-row-handle.component';

const EXPORTED_DECLARATIONS = [
    CellDefDirective,
    ColumnDefDirective,
    ColumnResizeDirective,
    FooterCellDefDirective,
    FooterRowDefDirective,
    HeaderCellDefDirective,
    HeaderDragDirective,
    HeaderDropListDirective,
    HeaderRowDefDirective,
    NoDataRowDirective,
    RecycleRowsDirective,
    RowDefDirective,
    RowDragDirective,
    RowDropListDirective,
    TableSortDirective,
    AddItemButtonComponent,
    CellComponent,
    DeleteItemButtonComponent,
    DetailButtonComponent,
    DragRowHandleComponent,
    FooterCellComponent,
    FooterRowComponent,
    HeaderCellComponent,
    HeaderRowComponent,
    RowComponent,
    SortComponent,
    TableComponent,
];

@NgModule({
    imports: [
        CommonModule,
        CdkTableModule,
        DragDropModule,
        IconModule,
    ],
    declarations: EXPORTED_DECLARATIONS,
    exports: EXPORTED_DECLARATIONS,
})
export class TableModule {}

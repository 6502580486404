import {
    Component,
    EventEmitter,
    Input,
    Output,
    OnInit
} from '@angular/core';

import { SearchService } from '../search/search.service';
import { SearchQueryDef } from '../search/search-query-def';
import { TranslationService } from '../services/translation.service';

const MAX_SEARCH_RESULTS = 50;

@Component({
    selector: 'site-multiselect',
    template: `
<!-- Selected sites -->
<div *ngIf="model && model.length > 0">
    <table class="table table-sm table-borderless table-nonfluid">
        <tbody>
            <tr *ngFor="let item of model; let i = index;">
                <td class="icon-cell">
                    <a (click)="removeSite(i)" title="Remove">
                        <i class="fa fa-remove remove-item"></i>
                    </a>
                </td>
                <td>
                    {{item.Name}}
                </td>
            </tr>
        </tbody>
    </table>
</div>

<!-- Site typeahead -->
<climb-indirect-typeahead
        [search]="searchSites"
        [resultFormatter]="siteNameFormatter"
        [placeholder]="placeholder"
        (selectItem)="selectSite($event)"></climb-indirect-typeahead>
`,
    styles: [`
        .table {
            margin-bottom: 0.25em;
        }
    `]
})
export class SiteMultiselectComponent implements OnInit {
    @Input() model: any[];
    @Output() modelChange: EventEmitter<any> = new EventEmitter<any>();

    placeholder: string;

    constructor(
        private _searchService: SearchService,
        private translationService: TranslationService
    ) {}

    ngOnInit() {
        this.placeholder = this.translationService.translate('Site') + " name...";
    }

    removeSite(index: number) {
        if (this.model && index >= 0 && index < this.model.length) {
            this.model.splice(index, 1);
        }
        this.modelChange.emit(this.model);
    }

    searchSites = (term: string): Promise<any> => {

        let count = null;
        if (term.length < 3) {
            count = MAX_SEARCH_RESULTS;
        }

        const siteFilter = {
            Name: term
        };

        const searchQueryDef: SearchQueryDef = {
            entity: 'Sites',
            page: 1,
            size: count,
            sortColumn: 'Name',
            sortDirection: 'asc',
            filter: siteFilter
        };

        return this._searchService.getEntitiesBySearch(searchQueryDef)
            .then((results: any) => {
                return results.data;
            });
    }

    siteNameFormatter = (value: any) => {
        return value.Name;
    }

    selectSite(site: any) {
        if (this.model) {
            this.model.push(site);
        } else {
            this.model = [site];
        }

        this.modelChange.emit(this.model);
    }
}

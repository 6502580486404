import type { Animal } from './animal.interface';
import type { BirthMaterial } from './birth-material.interface';
import type { MaterialPool } from './material-pool.interface';
import type { Mating } from './mating.interface';
import type { Note } from './note.interface';
import type { PlugDate } from './plug-date.interface';
import type { TaskBirth } from './task-birth.interface';
import type { cv_BirthStatus } from './cv-birth-status.interface';

export interface Birth {
    Animal: Animal[];
    BirthID: string;
    BirthMaterial: BirthMaterial[];
    C_BirthStatus_key: number | null;
    C_Birth_key: number;
    C_FosterMaterialPool_key: number | null;
    C_MaterialPool_key: number;
    C_Workgroup_key: number;
    Comments: string;
    CreatedBy: string;
    DateBorn: Date | null;
    DateCreated: Date;
    DateModified: Date;
    DateTag: Date | null;
    DateWean: Date | null;
    DeadCount: number | null;
    GenotypeOffspring: boolean;
    IsFoster: boolean;
    LiveCount: number | null;
    MaterialPool: MaterialPool;
    Mating: Mating;
    ModifiedBy: string;
    Note: Note[];
    PlugDate: PlugDate[];
    StillbornCount: number | null;
    TaskBirth: TaskBirth[];
    TimeWean: number | null;
    Version: number;
    WeanComments: number | null;
    cv_BirthStatus: cv_BirthStatus;
}

<table class="table table-bordered job-pharma-tasks-inputs">
    <thead>
        <tr class="detail-grid-header">
            <th *ngIf="!readonly" class="icon-cell">
                <lock-control [(model)]="allLocked"
                [administrator]="isStudyDirector"
                (modelChange)="allLockedChanged()"></lock-control>
            </th>
            <th *ngIf="visible.protocol">Protocol</th>
            <th *ngIf="visible.taskAlias">Task Name</th>
            <th *ngIf="visible.materials">Animals/Samples</th>
            <th *ngIf="visible.inputs" class="text-nowrap">
                <span *ngIf="!bulkInputTasks.length">
                    Inputs
                </span>
                <bulk-edit-header *ngIf="bulkInputTasks.length"
                                  [headerText]="'Inputs'"
                                  (updateClicked)="bulkInputUpdated()"
                                  [disabled]="!bulkInputTask || !bulkInputInput || isDosingTableInput(bulkInputInput) || isJobCharacteristicInput(bulkInputInput)"
                                  [readonly]="allLocked">
                    <div modal-content style="width: 360px;" class="text-body">
                        <div class="form-group row">
                            <label for="bulkInputTask"
                                   class="col-md-4 col-form-label">Task</label>
                            <div class="col-md-8">
                                <select name="bulkInputTask"
                                        [(ngModel)]="bulkInputTask"
                                        (ngModelChange)="bulkInputTaskChanged()"
                                        class="form-control input-medium">
                                    <option [ngValue]="null"></option>
                                    <option *ngFor="let task of bulkInputTasks"
                                            [ngValue]="task">
                                        {{task.WorkflowTask.TaskName}}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="bulkInputTaskKey"
                                   class="col-md-4 col-form-label">Task Name</label>
                            <div class="col-md-8">
                                <select name="bulkInputTaskKey"
                                        [(ngModel)]="bulkInputTaskAlias"
                                        [disabled]="bulkInputTaskAliases.length === 0"
                                        class="form-control input-medium">
                                    <option [ngValue]="null">[All]</option>
                                    <option *ngFor="let alias of bulkInputTaskAliases"
                                            [ngValue]="alias">
                                        {{alias}}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="bulkInputTaskKey"
                                   class="col-md-4 col-form-label">Cohort</label>
                            <div class="col-md-8">
                                <select name="bulkInputTaskKey"
                                        [(ngModel)]="bulkInputCohort"
                                        [disabled]="bulkInputCohorts.length === 0"
                                        class="form-control input-medium">
                                    <option [ngValue]="null">[All]</option>
                                    <option *ngFor="let cohort of bulkInputCohorts"
                                            [ngValue]="cohort">
                                        {{cohort.CohortName}}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="bulkInputInput"
                                   class="col-md-4 col-form-label">Input</label>
                            <div class="col-md-8">
                                <select name="bulkInputInput"
                                        [(ngModel)]="bulkInputInput"
                                        (ngModelChange)="bulkInputInputChanged()"
                                        [disabled]="bulkInputInputs.length === 0"
                                        class="form-control input-medium">
                                    <option *ngFor="let input of bulkInputInputs"
                                            [ngValue]="input">
                                        {{input.InputName}}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="bulkInputInput"
                                   class="col-md-4 col-form-label">Value</label>
                            <div class="col-md-8">
                                <data-type-input *ngIf="isRegularInput(bulkInputInput)"
                                                 [id]="'bulkInputValue'"
                                                 [ioObject]="bulkInputInput"
                                                 [(value)]="bulkInputValue"></data-type-input>
                                <input *ngIf="isDosingTableInput(bulkInputInput)"
                                       type="text"
                                       [id]="'bulkInputValue'"
                                       class="form-control input-medium"
                                       [(ngModel)]="bulkInputInput.cv_DosingTable.DosingTable"
                                       disabled />
                                <input *ngIf="isJobCharacteristicInput(bulkInputInput)"
                                       type="text"
                                       [id]="'bulkInputValue'"
                                       class="form-control input-medium"
                                       [(ngModel)]="bulkInputInput.cv_JobCharacteristicType.JobCharacteristicType"
                                       disabled />
                            </div>
                        </div>
                    </div>
                </bulk-edit-header>
            </th>
        </tr>
    </thead>
    <!-- open -->
    <loading-overlay *ngIf="loading" [message]="loadingMessage"></loading-overlay>
    <tbody *ngIf="!loading">
        <tr *ngFor="let row of rows  |
                paginate: { itemsPerPage: 50, currentPage: taskPage, id: 'tasks-inputs-pagination' };"
            class="task-input-row">
            <ng-container *ngIf="row.task as task">
                <td *ngIf="!readonly" class="icon-cell" [ngClass]="row.classes.task">
                    <ng-container *ngIf="row.taskFirst">
                        <lock-control [(model)]="task.IsLocked"
                                      [administrator]="isStudyDirector"
                                      (modelChange)="isLockedChanged()"></lock-control>
                    </ng-container>
                </td>
                <!-- Protocol -->
                <td *ngIf="visible.protocol"
                    [ngClass]="row.classes.task"
                    class="protocol-name">
                    <ng-container *ngIf="row.taskFirst">
                        {{task.ProtocolInstance?.ProtocolAlias}}
                    </ng-container>
                </td>
                <!-- Task -->
                <td *ngIf="visible.taskAlias"
                    [ngClass]="row.classes.task"
                    class="task-name">
                    <ng-container *ngIf="row.taskFirst">
                        {{task.TaskAlias}}
                    </ng-container>
                </td>
                <!-- Animals/Samples -->
                <td *ngIf="visible.materials">
                    <ng-container *ngIf="row.cohort as cohort">
                        <div class="cohort-badge"
                             [ngStyle]="{ backgroundColor: cohort.BackgroundColor, color: cohort.ForegroundColor }">
                            {{cohort.CohortName}}
                        </div>
                    </ng-container>
                    <ng-container *ngIf="row.placeholder as placeholder">
                        <div class="cohort-badge">
                            {{placeholder.PlaceholderName}}
                        </div>
                    </ng-container>
                    <ng-container *ngFor="let material of row.materials; let last = last">
                        {{material.AnimalName}}{{material.SampleName}}<ng-container *ngIf="!last">, </ng-container>
                    </ng-container>
                </td>
                <!-- Inputs -->
                <td *ngIf="visible.inputs">
                    <div *ngIf="row.inputs | notEmpty"
                         class="inputs-wrapper">
                        <table class="table table-borderless">
                            <tbody>
                                <ng-container *ngFor="let input of row.inputs | orderBy:'Input.SortOrder'; let inputIndex = index">
                                    <tr *ngIf="input.Input.IsActive">
                                        <td class="input-label">
                                            <label for="task-{{task.C_TaskInstance_key}}-input-{{inputIndex}}"
                                                   class="col-form-label">{{input.Input.InputName}}</label>
                                        </td>
                                        <td>
                                            <data-type-input *ngIf="isRegularInput(input.Input)"
                                                             id="task-{{task.C_TaskInstance_key}}-input-{{inputIndex}}"
                                                             [ioObject]="input.Input"
                                                             [(value)]="input.InputValue"
                                                             [readonly]="task.IsLocked || input?.TaskInstance?.cv_TaskStatus?.IsEndState"
                                                             (valueChange)="onInputValueChange(row, input)">
                                            </data-type-input>
                                            <input *ngIf="isDosingTableInput(input.Input) || isJobCharacteristicInput(input.Input)"
                                                   type="text"
                                                   id="task-{{task.C_TaskInstance_key}}-input-{{inputIndex}}"
                                                   class="form-control input-medium"
                                                   [(ngModel)]="input.InputValue"
                                                   disabled />
                                        </td>
                                    </tr>
                                </ng-container>
                            </tbody>
                        </table>
                    </div>
                    <ng-container *ngIf="!(row.inputs | notEmpty)">
                        &#x2014;
                    </ng-container>
                </td>
            </ng-container>
        </tr>
    </tbody>
</table>

<pagination-controls (pageChange)="changeTaskPage($event)"
                     [autoHide]="true"
                     class="ngx-table-paginator"
                     id="tasks-inputs-pagination"></pagination-controls>

import { pickBy } from '@lodash';
import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';
import { CharacteristicService } from '../characteristic.service';
import { CharacteristicTypeNameEnum } from '../models';
import { ConfirmService } from '../../common/confirm';
import { LoggingService } from '../../services/logging.service';
import { SaveChangesService } from '../../services/save-changes.service';
import { TranslationService } from '../../services/translation.service';
import { checkmarkCircleFilled } from '@icons';
import type { DragDropEvent } from '@common/components/table/drag-drop/drag-drop.interface';
import type { ColumnId } from '@common/components/table/table.interface';
import {
    getSafeProp,
    reorderAndUpdateRelativeSortOrder,
} from '../../common/util';
import type { Characteristic, EditCharacteristic } from '../characteristics.interface';

@Component({
    selector: 'characteristic-type-list',
    templateUrl: './characteristic-type-list.component.html',
    styleUrls: ['./characteristic-type-list.component.scss'],
})
export class CharacteristicTypeListComponent implements OnInit {
    @Input() typeName: string;
    @Input() categoryName: string;
    @Input() jobCharacteristicLink: string;
    @Input() characteristics: Characteristic[];
    @Input() allowEdit = false;
    @Input() totalCount: number;
    @Input() pageSize: number;
    @Input() pageIndex = 0;

    @Output() editBegin: EventEmitter<EditCharacteristic> = new EventEmitter<EditCharacteristic>();
    @Output() pageChange: EventEmitter<number> = new EventEmitter<number>();
    @Output() rowChanged: EventEmitter<Characteristic[]> = new EventEmitter();

    // Included so that we can access from template
    getSafe = getSafeProp;

    readonly COMPONENT_LOG_TAG = 'characteristic-type-list';
    readonly icons = { checkmarkCircleFilled };
    displayedColumns: string[] = [];

    constructor(
        private characteristicService: CharacteristicService,
        private confirmService: ConfirmService,
        private loggingService: LoggingService,
        private saveChangesService: SaveChangesService,
        private translationService: TranslationService
    ) { }

    ngOnInit() {
        if (!this.categoryName) {
            switch (this.typeName) {
                case "animal":
                    this.categoryName = "Species";
                    break;
                case "sample":
                    this.categoryName = "Sample Type";
                    break;
                case "job":
                    this.categoryName = this.translationService.translate('Job') + " Type";
                    break;
                case "study":
                    this.categoryName = this.translationService.translate('Study') + " Type";
                    break;  
            }
        }

        this.displayedColumns = this.getDisplayedColumns();
    }

    addCharacteristic(type: string) {
        let newCharacteristic: any;
        switch (type) {
            case CharacteristicTypeNameEnum.Taxon:
                newCharacteristic = this.characteristicService.createTaxonCharacteristic();
                newCharacteristic.C_Taxon_key = null;
                break;
            case CharacteristicTypeNameEnum.Sample:
                newCharacteristic = this.characteristicService.createSampleCharacteristic();
                newCharacteristic.C_SampleType_key = null;
                break;
            case CharacteristicTypeNameEnum.Job:
                newCharacteristic = this.characteristicService.createJobCharacteristic();
                newCharacteristic.C_JobType_key = null;
                newCharacteristic.C_JobSubtype_key = null;
                newCharacteristic.C_IACUCProtocol_key = null;
                newCharacteristic.C_JobCharacteristicLinkType_key = null;
                break;
            case CharacteristicTypeNameEnum.Study:
                newCharacteristic = this.characteristicService.createStudyCharacteristic();
                newCharacteristic.C_StudyType_key = null;
                break;
            default:
                console.error("Invalid characteristic type: " + type);
                break;
        }

        newCharacteristic.DateLastReviewed = null;
        newCharacteristic.C_DataType_key = null;
        newCharacteristic.SortOrder = this.totalCount + 1;
        this.characteristics.push(newCharacteristic);
        this.editBegin.emit({
            characteristic: newCharacteristic,
            isNewEntity: true,
        });
    }

    deleteCharacteristic(type: string, characteristic: any) {
        let beingUsedPromise: Promise<boolean>;
        let warning: string;

        switch (type) {
            case CharacteristicTypeNameEnum.Taxon:
                beingUsedPromise = this.characteristicService
                    .isTaxonCharacteristicBeingUsed(characteristic);
                warning = 'Cannot delete characteristic: it is either associated with animals or selected in the Settings facet.';
                break;
            case CharacteristicTypeNameEnum.Sample:
                beingUsedPromise = this.characteristicService
                    .isSampleCharacteristicBeingUsed(characteristic);
                warning = 'Cannot delete characteristic: it is associated with samples.';
                break;
            case CharacteristicTypeNameEnum.Job:
                beingUsedPromise = this.characteristicService
                    .isJobCharacteristicBeingUsed(characteristic);
                warning = 'Cannot delete characteristic: it is associated with ' +
                    this.translationService.translate('Jobs').toLowerCase() + '.';
                break;
            case CharacteristicTypeNameEnum.Study:
                beingUsedPromise = this.characteristicService
                    .isStudyCharacteristicBeingUsed(characteristic);
                warning = 'Cannot delete characteristic: it is associated with ' +
                    this.translationService.translate('Studies').toLowerCase() + '.';
                break;
            default:
                console.error("Invalid characteristic type: " + type);
                break;
        }

        beingUsedPromise.then((beingUsed: boolean) => {
            if (!beingUsed) {
                this.doDelete(characteristic, type);
            } else {
                this.loggingService.logWarning(
                    warning,
                    null,
                    this.COMPONENT_LOG_TAG,
                    true
                );
            }
        });
    }

    private async doDelete(characteristic: any, type: string): Promise<any> {
        const modalTitle = 'Delete Characteristic';
        const itemName = characteristic.CharacteristicName;
        const modalText = 'Delete "' + itemName + '"? This action cannot be undone.';

        try { // Required only to show the legacy dialog box. Need to redo and use a new dialog box.
            await this.confirmService.confirmDelete(modalTitle, modalText);

            try {
                if (type === CharacteristicTypeNameEnum.Taxon) {
                    await this.characteristicService.bulkDeleteTaxonCharacteristics(characteristic);
                }
                await this.characteristicService.deleteCharacteristic(characteristic, type);
                await this.saveChangesService.saveChanges(this.COMPONENT_LOG_TAG);
            } finally {
                let pageIndex = this.pageIndex;
                if (this.characteristics.length <= 1) { // there was the last item on the page
                    pageIndex = this.pageIndex > 0 ? this.pageIndex - 1 : 0;
                }
                this.changeCharacteristicPage(pageIndex);
            }
        } catch (e) {
            // cancel
            /* do nothing on cancel */
        }
    }

    detailsClicked(characteristic: any) {
        this.editBegin.emit({ characteristic, isNewEntity: false });
    }

    changeCharacteristicPage(pageIndex: number): void {
        this.pageIndex = pageIndex;
        this.pageChange.emit(pageIndex);
    }

    private getDisplayedColumns(): ColumnId[] {
        const columnsMap: Record<ColumnId, boolean> = {
            drag: this.allowEdit,
            details: true,
            delete: this.allowEdit,
            name: true,
            iacucProtocol: this.typeName === 'job',
            categoryName: true,
            dataType: true,
            active: true,
            lastReviewed: true,
        };

        return Object.keys(pickBy(columnsMap));
    }

    rowDropped(event: DragDropEvent): void {
        const characteristics = [...this.characteristics];
        reorderAndUpdateRelativeSortOrder(characteristics, event.previousIndex, event.currentIndex);
        this.rowChanged.emit(characteristics);
        this.characteristics = characteristics;
    }
}

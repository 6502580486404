export const apiUserSuffix = ' via API';

/**
 * Returns the corresponding api audit username for specified user climbId
 * @param {string} climbId - climbId of user
 * @returns {string} The username that is used for audit of api operations
 */
export function getApiAuditUsername(climbId: string){
    return climbId + apiUserSuffix;
}

<div class="bulk-edit-wrapper">

    <bulk-edit-toolbar [logTag]="COMPONENT_LOG_TAG"
                       (exit)="exitClicked()"
                       [options]="bulkTemplates.bulkOptions"
                       [facet]="facet"
                       #bulkEditToolbar>
    </bulk-edit-toolbar>

    <div class="bulk-edit-grid">
        <bulk-edit-table [options]="bulkTemplates.bulkOptions"
                         [facet]="facet"
                         [itemsToEdit]="observations"></bulk-edit-table>
    </div>
</div>
    
<observation-bulk-templates [isEdit]="true" [observations]="observations" #bulkTemplates></observation-bulk-templates>

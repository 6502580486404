import type { Input } from './input.interface';
import type { StoredFileMap } from './stored-file-map.interface';
import type { TaskInstance } from './task-instance.interface';

export interface TaskInput {
    C_Input_key: number;
    C_TaskInput_key: number;
    C_TaskInstance_key: number;
    C_Workgroup_key: number;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    Input: Input;
    InputValue: string;
    ModifiedBy: string;
    StoredFileMap: StoredFileMap[];
    TaskInstance: TaskInstance;
    ValidatedInputValue: string;
    Version: number;
}

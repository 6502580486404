import { VocabularyService } from './../vocabularies/vocabulary.service';
import {
    Component,
    Input,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { InstitutionService } from './institution.service';
import { TranslationService } from '../services/translation.service';
import { WorkspaceFilterService } from '../services/workspace-filter.service';

import {
    BaseFacet,
    BaseFacetService
} from '../common/facet';

import { InstitutionTableOptions } from './institution-table-options';
import { InstitutionFilterComponent } from './institution-filter.component';
import { FeatureFlagService } from '../services/feature-flags.service';

import {
    CellFormatterService,
    TableState,
    DataResponse,
    ColumnsState,
    TableColumnDef,
} from '@common/datatable';
import { QueryDef } from '../services/query-def';
import { DataContextService } from '@services/data-context.service';
import { arrowClockwise, magnifier } from '@icons';

@Component({
    selector: 'institution-facet',
    templateUrl: './institution-facet.component.html',
    providers: BaseFacet.BASE_COMPONENT_PROVIDERS
})
export class InstitutionFacetComponent extends BaseFacet implements OnInit, OnDestroy {
    @Input() facetId: string;
    @Input() facet: any;

    readonly icons = { arrowClockwise, magnifier };

    componentName = 'institution';

    institutionTableOptions: InstitutionTableOptions;

    isCRL = false;
    isCRO = false;

    readonly COMPONENT_LOG_TAG = 'institution-facet';

    private notifier$ = new Subject<void>();

    dataTableColumns: BehaviorSubject<TableColumnDef[]>;
    dataTableColumns$: Observable<TableColumnDef[]>;

    constructor(
        private baseFacetService: BaseFacetService,
        private cellFormatterService: CellFormatterService,
        private institutionService: InstitutionService,
        private modalService: NgbModal,
        private translationService: TranslationService,
        workspaceFilterService: WorkspaceFilterService,
        private vocabularyService: VocabularyService,
        private featureFlagService: FeatureFlagService,
        private dataContext: DataContextService,
    ) {
        super(
            baseFacetService,
            workspaceFilterService
        );
        this.initIsCRL();
        this.initIsCRO();

        this.institutionTableOptions = new InstitutionTableOptions(
            this.cellFormatterService,
            this.translationService,
            this.isCRL
        );

        this.dataTableColumns = new BehaviorSubject(this.institutionTableOptions.options.columns);
        this.dataTableColumns$ = this.dataTableColumns.asObservable();

        this.dataService = {
            run: (tableState: TableState) => {
                return this.loadItemsList(tableState);
            }
        };
    }

    // lifecycle
    ngOnInit() {
        super.ngOnInit();
        this.initialize();

        this.dataContext.onCancel$.pipe(takeUntil(this.notifier$)).subscribe(() => {
            this.changeView(this.LIST_VIEW);
        });
    }

    initialize() {
        this.restoreFilterState();
        this.changeView(this.LIST_VIEW);
    }

    refreshData() {
        this.initialize();
        this.dataTableComm.reloadTable();
    }

    initIsCRL() {
        const flag = this.featureFlagService.getFlag("IsCRL");
        this.isCRL = (flag && flag.IsActive && flag.Value.toLowerCase() === "true");
    }
    initIsCRO() {
        const flag = this.featureFlagService.getFlag("IsCRO");
        this.isCRO = (flag && flag.IsActive && flag.Value.toLowerCase() === "true");
    }

    restoreFilterState() {

        // process any grid filters
        if (this.facet && this.facet.GridFilter) {
            try {
                this.filter = JSON.parse(this.facet.GridFilter);
            } catch (err) {
                console.error(err);
            }

            if (!this.filter) {
                this.filter = {};
            }            
        }
    }

    async loadItemsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        this.setLoadingState(tableState.loadingMessage);

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'DateCreated DESC';
        const expands: string[] = [];

        const queryDef: QueryDef = {
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter(),
            expands
        };

        try {
            const response = await this.institutionService.getInstitutions(queryDef);

            const visibleColumns = this.getVisibleColumns(this.institutionTableOptions.options);
            await this.institutionService.ensureVisibleColumnsDataLoaded(response.results, visibleColumns);

            this.stopLoading();

            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        } finally {
            this.stopLoading();
        }
    }

    addItemClick() {
        this.loading = true;
        this.createNewItem().then((item) => {
            this.loading = false;
            this.itemToEdit = item;
            this.changeView(this.DETAIL_VIEW);
        }).catch((error) => {
            this.loading = false;
            this.loggingService.logError("An unexpected error occurred. Please try again", error, this.componentName, true);
        });
    }

    createNewItem(): Promise<any> {
        const newInstitution = this.institutionService.createInstitution();

        const p1 = this.vocabularyService.getCVDefault('cv_InstitutionTypes').then((value) => {
            newInstitution.cv_InstitutionType = value;
        });
        const p2 = this.vocabularyService.getCVDefault('cv_States').then((value) => {
            newInstitution.cv_State = value;
        });
        const p3 = this.vocabularyService.getCVDefault('cv_Countries').then((value) => {
            newInstitution.cv_Country = value;
        });

        return Promise.all([p1, p2, p3]).then(() => {
            return newInstitution;
        });
    }

    openFilter() {
        const ref = this.modalService.open(InstitutionFilterComponent);
        const component = ref.componentInstance as InstitutionFilterComponent;
        component.filter = this.filter;
        component.isCRL = this.isCRL;
        component.onFilter.subscribe((filter: any) => {
            this.filter = filter;
            this.runFilter();
        });
    }

    async selectedColumnsChange({ visible }: ColumnsState) {
        try {
            this.facetLoadingState.changeLoadingState(true);
            await this.institutionService.ensureVisibleColumnsDataLoaded(this.data, visible);
        } finally {
            this.facetLoadingState.changeLoadingState(false);
        }
    }

    ngOnDestroy(): void {
        this.notifier$.next();
        this.notifier$.complete();
    }
}

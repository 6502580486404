import {
    CellFormatterService,
    FormatterOptions,
    TableOptions
} from '../common/datatable';
import { TranslationService } from '../services/translation.service';
import { AuditModifiedFieldComponent } from './audit-modified-fields/audit-modified-field.component';
import { AuditClassFactory } from './audit-class-maker';

export class AuditStudyCharacteristicsTableOptions {

    options: TableOptions;

    constructor(
        private cellFormatterService: CellFormatterService,
        private translationService: TranslationService,
    ) {
        this.options = this.getDefaultOptions();
    }

    getDefaultOptions(): TableOptions {

        return {
            csvFileName: this.translationService.translate('study').toLowerCase() + '-characteristic-audit.csv',
            enableDetailColumn: false,
            enableDraggable: false,
            enableSelectable: false,
            enableAuditColumns: false,
            columns: [
                {
                    displayName: 'Type',
                    field: 'UpdateType',
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Modified Fields',
                    field: 'ModifiedFields',
                    sortField: 'ModifiedFields',
                    rendererComponent: AuditModifiedFieldComponent,
                    exportFormatter: this.cellFormatterService.auditModifiedFieldsExportFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Date',
                    field: 'ModifiedDate',
                    formatter: this.cellFormatterService.dateTimeUTCFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Modified By',
                    field: 'ModifiedBy',
                    formatter: this.cellFormatterService.userNameFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: this.translationService.translate('Study') + ' Name',
                    field: 'StudyName',
                    formatter: (row: any, value: any, formatterOptions: FormatterOptions) => {
                        return this.cellFormatterService.auditColumnFormatter(row, value, formatterOptions, 'StudyName',
                            this.translationService.translate('Study') + 'Name');
                    },
                    cellClass: AuditClassFactory.createCellClass((this.translationService.translate('Study') + 'Name').toLowerCase()),
                },
                {
                    displayName: this.translationService.translate('Study') + ' Type',
                    field: 'StudyType',
                    formatter: (row: any, value: any, formatterOptions: FormatterOptions) => {
                        return this.cellFormatterService.auditColumnFormatter(row, value, formatterOptions, 'StudyType',
                            this.translationService.translate('Study') + 'Type');
                    },
                    cellClass: AuditClassFactory.createCellClass((this.translationService.translate('Study') + 'Type').toLowerCase()),
                },   
                {
                    displayName: 'Characteristic Name',
                    field: 'CharacteristicName',
                    formatter: (row: any, value: any, formatterOptions: FormatterOptions) => {
                        return this.cellFormatterService.auditColumnFormatter(row, value, formatterOptions, 'CharacteristicName', 'CharacteristicName');
                    },
                    cellClass: AuditClassFactory.createCellClass('characteristicname'),
                },
                {
                    displayName: 'Characteristic Value', 
                    field: 'CharacteristicValue',
                    formatter: (row: any, value: any, formatterOptions: FormatterOptions) => {
                        return this.cellFormatterService.auditColumnFormatter(row, value, formatterOptions, 'CharacteristicValue', 'CharacteristicValue');
                    },
                    cellClass: AuditClassFactory.createCellClass('characteristicvalue'),
                },
                {
                    displayName: 'Created By',
                    field: 'CreatedBy',
                    formatter: this.cellFormatterService.userNameFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Created Date',
                    field: 'DateCreated',
                    formatter: this.cellFormatterService.dateTimeUTCFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                }
            ]
        };
    }
}

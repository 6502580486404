<div class="detail-toolbar float-right" aria-label="Details toolbar">
    <ng-content select="[additional-groups]"></ng-content>

    <button data-automation-id="save-button"
            type="button"
            class="btn btn-fw"
            [ngClass]="hasPendingChanges ? 'btn-warning' : 'btn-secondary'"
            [disabled]="isSaveDisabled"
            (click)="saveClicked()"
            *ngIf="canWrite">
        <span *ngIf="!loading">
            <i data-automation-id="save-item"
               class="fa fa-check" 
               aria-hidden="true" 
               title="Save">
            </i>
            Save
        </span>
        <span *ngIf="loading">
            <i class="fa fa-spinner fa-spin" title="Saving&hellip;"></i>
        </span>
    </button>

    <button data-automation-id="list-view-button"
            *ngIf="showListViewButton"
            type="button"
            class="btn btn-secondary"
            [disabled]="loading"
            (click)="exitClicked()">
        <i class="fa fa-table" aria-hidden="true" title="List View"></i>
        List <span class="gridster-d-sm-none gridster-d-md-inline-block">View</span>
        <span class="sr-only">List View</span>
    </button>
</div>

import type { Material } from './material.interface';
import type { MaterialPool } from './material-pool.interface';
import type { cv_RoleInPool } from './cv-role-in-pool.interface';

export interface MaterialPoolMaterial {
    C_MaterialPoolMaterial_key: number;
    C_MaterialPool_key: number;
    C_Material_key: number;
    C_RoleInPool_key: number | null;
    C_Workgroup_key: number;
    CreatedBy: string;
    DateCreated: Date;
    DateIn: Date | null;
    DateModified: Date;
    DateOut: Date | null;
    Material: Material;
    MaterialPool: MaterialPool;
    ModifiedBy: string;
    Version: number;
    cv_RoleInPool: cv_RoleInPool;
}

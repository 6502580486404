import { Component, OnInit, ChangeDetectionStrategy, Injector, ElementRef, ChangeDetectorRef, SkipSelf } from '@angular/core';
import { ButtonComponent } from '@common/components/button/button.component';
import { checkmark } from '@icons';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'button[climbSaveButton]',
  templateUrl: './toolbar-save-button.component.html',
  styleUrls: ['./toolbar-save-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToolbarSaveButtonComponent extends ButtonComponent implements OnInit {
    readonly icons = { checkmark };

    constructor(
        injector: Injector,
        elementRef: ElementRef,
        @SkipSelf() cdr: ChangeDetectorRef,
    ) {
        super(injector, elementRef, cdr);
    }

    ngOnInit(): void {
        super.ngOnInit();
        super.variant = 'ghost';
        super.size = 'sm';
        super.onlyIcon = false;
    }
}

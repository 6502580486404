import type { Sample } from './sample.interface';

export interface cv_SampleStatus {
    C_SampleStatus_key: number;
    C_VocabularySource_key: number | null;
    C_Workgroup_key: number;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    ElementID: string;
    IsActive: boolean;
    IsDefault: boolean;
    IsDeprecated: boolean;
    IsInStorage: boolean;
    ModifiedBy: string;
    Sample: Sample[];
    SampleStatus: string;
    SortOrder: number | null;
    Version: number;
}

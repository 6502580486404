<div *ngIf="materialPool.JobMaterialPool | notEmpty">
    <table class="table table-sm table-borderless">
        <tbody>
            <tr *ngFor="let jobMaterialPool of materialPool.JobMaterialPool">
                <td class="icon-cell"
                    *ngIf="!readonly">
                    <a (click)="removeItem(jobMaterialPool)"
                       *ngIf="jobMaterialPool.Job">
                        <i class="fa fa-remove remove-item"
                           title="Remove {{'Job' | translate}}"></i>
                    </a>
                </td>

                <td class="text-nowrap">
                    {{jobMaterialPool.Job?.JobID}}
                </td>
            </tr>
        </tbody>
    </table>
</div>

<climb-indirect-typeahead [search]="searchJobs"
                          [resultFormatter]="jobIdFormatter"
                          [placeholder]="placeholder"
                          (selectItem)="addItem($event)"></climb-indirect-typeahead>

import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { DialogService } from '@common/dialog/deprecated';
import { LoggingService } from '@services/logging.service';
import { applyGroupByMarkers } from '@common/datatable/utils';
import type { DataService, TableOptions, TableState } from '@common/datatable';
import { ExportType } from '@common/export';
import { arrowsBordersVertical } from '@icons';
import { DataTableExportService } from '../../services/data-table-export.service';

const EXPORT_DEFAULT_LIMIT = 2000;
const EXPORT_EXPANDED_LIMIT = 5000;

@Component({
    selector: 'climb-export-dropdown',
    templateUrl: './toolbar-export-dropdown.component.html',
    styleUrls: ['./toolbar-export-dropdown.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToolbarExportDropdownComponent {
    @Input() dataService: DataService;
    @Input() componentName: string;
    @Input() options: TableOptions;
    @Input() expandExport = false;
    @Input() tableState: TableState & { totalCount: number } = {
        pageNumber: 1,
        pageSize: 50,
        rowOffset: 0,
        sort: '',
        sorts: [],
        totalCount: 0,
    };

    readonly icons = { arrowsBordersVertical };
    exportTypes = ExportType;

    constructor(
        private dataTableExportService: DataTableExportService,
        private dialogService: DialogService,
        private loggingService: LoggingService,
    ) { }

    async clickExport(exportType: ExportType): Promise<void> {
        const filename = this.options.csvFileName || 'climb-data.csv';

        if (!this.expandExport) {
            const logMessage = `The ${exportType} file will only contain first ${EXPORT_DEFAULT_LIMIT} records.`;
            this.loggingService.logWarning(logMessage, null, 'climb-export-dropdown', true);

            await this.exportData(EXPORT_DEFAULT_LIMIT, filename, exportType);
            return;
        }

        const maxLimit = EXPORT_EXPANDED_LIMIT;

        if (this.tableState.totalCount <= EXPORT_DEFAULT_LIMIT) {
            await this.exportData(maxLimit, filename, exportType);
            return;
        }

        const tip = 'Tip: Filtering the facet list will result in a faster export.';
        const message = this.tableState.totalCount < maxLimit
            ? `${this.tableState.totalCount} records will be exported, and it might take a few minutes. Would you like to continue?\n${tip}`
            : `The ${exportType} file will only contain the first ${maxLimit} records, and it might take a few minutes to export. Would you like to continue?\n${tip}`;

        const isExport = await this.dialogService.confirmYesNo({
            yesButtonTitle: 'Export',
            noButtonTitle: 'Cancel',
            title: 'Large Export',
            bodyText: message,
        });

        if (isExport) {
            await this.exportData(maxLimit, filename, exportType);
        }
    }

    private async exportData(limit: number, filename: string, exportType: ExportType): Promise<void> {
        const tableState: TableState = {
            ...this.tableState,
            pageSize: limit,
            pageNumber: 1,
            rowOffset: 0,
            loadingMessage: `Exporting ${exportType} file...`,
        };

        const dataResponse = await this.dataService.run(tableState);
        if (this.options.groupBy) {
            applyGroupByMarkers(dataResponse.results, this.options.groupBy);
        }

        // load extra data before export if needed
        const responseWithPreExport = this.dataService.preExport
            ? await this.dataService.preExport(dataResponse)
            : null;

        this.dataTableExportService.export(
            responseWithPreExport ?? dataResponse,
            this.options.columns,
            filename,
            exportType,
            this.options.columnsForcedToExport,
        );
    }
}

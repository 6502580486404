import { Component, Input, ViewChildren } from '@angular/core';
import { NgModel } from '@angular/forms';

export const DATE_FILTER_COMPONENT_OPERATORS: string[] = ['between', '=', '<=', '>=', 'has no value'];

@Component({
    selector: 'date-filter',
    template: `
        <form class="form-inline" *ngIf="node">
            <select
                    [(ngModel)]="node.operator"
                    (ngModelChange)="changeOperator()"
                    [ngModelOptions]="{standalone: true}"
                    class="form-control">
                <option *ngFor="let op of operators" [ngValue]="op">{{ op }}</option>
            </select>

            <climb-ngb-date
                *ngIf="showMinValue()"
                #dateControl="ngModel"
                name="minValue"
                [(ngModel)]="node.minValue"></climb-ngb-date>
            <label *ngIf="node.operator === 'between'">and</label>
            <climb-ngb-date
                *ngIf="showMaxValue()"
                #dateControl="ngModel"
                name="maxValue"
                [(ngModel)]="node.maxValue"></climb-ngb-date>

            <button
                type="button"
                class="form-control btn btn-secondary"
                (click)="clear()">Clear</button>
        </form>
    `,
})
export class DateFilterComponent {
    @ViewChildren('dateControl') dateControls: NgModel[];
    @Input() node: any = null;
    readonly operators = DATE_FILTER_COMPONENT_OPERATORS;

    changeOperator() {
        if (this.node.operator === 'has no value') {
            this.node.searchNulls = true;
        } else {
            this.node.searchNulls = false;
        }
    }

    clear() {
        this.node.minValue = null;
        this.node.maxValue = null;
        this.node.searchNulls = false;
        if (this.node.operator === 'has no value') {
            this.node.operator = 'between';
        }
    }

    showMinValue(): boolean {
        if (this.node.searchNulls) {
            return false;
        }

        return this.node.operator === 'between' ||
            this.node.operator === '>=' ||
            this.node.operator === '=';
    }

    showMaxValue(): boolean {
        if (this.node.searchNulls) {
            return false;
        }

        return this.node.operator === 'between' ||
            this.node.operator === '<=';
    }
}

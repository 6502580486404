import { Directive } from '@angular/core';
import { CdkCellDef, CdkFooterCellDef, CdkHeaderCellDef } from '@angular/cdk/table';


/**
 * Cell definition for the climb-table.
 * Captures the template of a column's data row cell as well as cell-specific properties.
 */
@Directive({
    selector: '[climbCellDef]',
    providers: [{ provide: CdkCellDef, useExisting: CellDefDirective }],
})
export class CellDefDirective extends CdkCellDef {}

/**
 * Header cell definition for the climb-table.
 * Captures the template of a column's header cell and as well as cell-specific properties.
 */
@Directive({
    selector: '[climbHeaderCellDef]',
    providers: [{ provide: CdkHeaderCellDef, useExisting: HeaderCellDefDirective }],
})
export class HeaderCellDefDirective extends CdkHeaderCellDef {}

/**
 * Footer cell definition for the climb-table.
 * Captures the template of a column's footer cell and as well as cell-specific properties.
 */
@Directive({
    selector: '[climbFooterCellDef]',
    providers: [{ provide: CdkFooterCellDef, useExisting: FooterCellDefDirective }],
})
export class FooterCellDefDirective extends CdkFooterCellDef {}

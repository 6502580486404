import {
    CellFormatterService,
    FormatterOptions,
    TableOptions
} from '../common/datatable';
import { TranslationService } from '../services/translation.service';
import { AuditModifiedFieldComponent } from './audit-modified-fields/audit-modified-field.component';
import { AuditClassFactory } from './audit-class-maker';


export class AuditOrdersTableOptions {

    options: TableOptions;

    constructor(
        private cellFormatterService: CellFormatterService,
        private translationService: TranslationService
    ) {
        this.options = this.getDefaultOptions();
    }

    getDefaultOptions(): TableOptions {

        return {
            csvFileName: 'orders-audit.csv',
            enableDetailColumn: false,
            enableDraggable: false,
            enableSelectable: false,
            enableAuditColumns: false,
            columns: [
                {
                    displayName: 'Type',
                    field: 'UpdateType',
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Modified Fields',
                    field: 'ModifiedFields',
                    sortField: 'ModifiedFields',
                    rendererComponent: AuditModifiedFieldComponent,
                    exportFormatter: this.cellFormatterService.auditModifiedFieldsExportFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Date',
                    field: 'ModifiedDate',
                    formatter: this.cellFormatterService.dateTimeUTCFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Modified By',
                    field: 'ModifiedBy',
                    formatter: this.cellFormatterService.userNameFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Order ID',
                    field: 'OrderID',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('OrderID', 'OrderID'),
                    cellClass: AuditClassFactory.createCellClass('orderid'),
                },
                {
                    displayName: 'Order Type',
                    field: 'OrderType',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('OrderType', 'OrderType'),
                    cellClass: AuditClassFactory.createCellClass('ordertype'),
                },
                {
                    displayName: this.translationService.translate('Job') + ' Name',
                    field: 'JobName',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('JobName', this.translationService.translate('Job') + 'Name'),
                    cellClass: AuditClassFactory.createCellClass((this.translationService.translate('Job') + 'Name').toLowerCase()),
                },
                {
                    displayName: 'PO Number',
                    field: 'PONumber',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('PONumber', 'PONumber'),
                    cellClass: AuditClassFactory.createCellClass('ponumber'),
                },
                {
                    displayName: 'Species',
                    field: 'Species',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('Species', 'Species'),
                    cellClass: AuditClassFactory.createCellClass('species'),
                },
                {
                    displayName: 'Farm/Vendor',
                    field: 'FarmVendor',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('FarmVendor', 'FarmVendor'),
                    cellClass: AuditClassFactory.createCellClass('farmvendor'),
                },
                {
                    displayName: 'Country of Origin',
                    field: 'CountryOfOrigin',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('CountryOfOrigin', 'CountryOfOrigin'),
                    cellClass: AuditClassFactory.createCellClass('countryoforigin'),
                },
                {
                    displayName: 'Vendor Order Number',
                    field: 'VendorOrderNumber',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('VendorOrderNumber', 'VendorOrderNumber'),
                    cellClass: AuditClassFactory.createCellClass('vendorordernumber'),
                },
                {
                    displayName: 'Animals Ordered',
                    field: 'AnimalsOrdered',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('AnimalsOrdered', 'AnimalsOrdered'),
                    cellClass: AuditClassFactory.createCellClass('animalsordered'),
                },
                {
                    displayName: 'Quarantine Services Facility',
                    field: 'QuarantineServicesFacility',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('QuarantineServicesFacility', 'QuarantineServicesFacility'),
                    cellClass: AuditClassFactory.createCellClass('quarantineservicesfacility'),
                },
                {
                    displayName: 'Vendor Room ID',
                    field: 'VendorRoomID',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('VendorRoomID', 'VendorRoomID'),
                    cellClass: AuditClassFactory.createCellClass('vendorroomid'),
                },
                {
                    displayName: 'Date Ordered',
                    field: 'DateOrdered',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('DateOrdered', 'DateOrdered', this.cellFormatterService.dateFormatter),
                    cellClass: AuditClassFactory.createCellClass('dateordered'),
                },
                {
                    displayName: 'Projected Receipt Date',
                    field: 'ProjectedReceiptDate',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('ProjectedReceiptDate', 'ProjectedReceiptDate', this.cellFormatterService.dateFormatter),
                    cellClass: AuditClassFactory.createCellClass('projectedreceiptdate'),
                },
                {
                    displayName: 'Instructions',
                    field: 'Instructions',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('Instructions', 'Instructions'),
                    cellClass: AuditClassFactory.createCellClass('instructions'),
                },
                {
                    displayName: 'CITES',
                    field: 'CITES',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('CITES', 'CITES'),
                    cellClass: AuditClassFactory.createCellClass('cites'),
                },
                {
                    displayName: 'Animal Housing',
                    field: 'AnimalHousing',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('AnimalHousing', 'AnimalHousing'),
                    cellClass: AuditClassFactory.createCellClass('animalhousing'),
                },
                {
                    displayName: 'Water Available',
                    field: 'WaterAvailable',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('WaterAvailable', 'WaterAvailable'),
                    cellClass: AuditClassFactory.createCellClass('wateravailable'),
                },
                {
                    displayName: 'Food Available',
                    field: 'FoodAvailable',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('FoodAvailable', 'FoodAvailable'),
                    cellClass: AuditClassFactory.createCellClass('foodavailable'),
                },
                {
                    displayName: 'Bedding Available',
                    field: 'BeddingAvailable',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('BeddingAvailable', 'BeddingAvailable'),
                    cellClass: AuditClassFactory.createCellClass('beddingavailable'),
                },
                {
                    displayName: 'Shipment Containers Empty',
                    field: 'ShipmentContainersEmpty',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('ShipmentContainersEmpty', 'ShipmentContainersEmpty'),
                    cellClass: AuditClassFactory.createCellClass('shipmentcontainersempty'),
                },
                {
                    displayName: 'Created By',
                    field: 'CreatedBy',
                    formatter: this.cellFormatterService.userNameFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Created Date',
                    field: 'DateCreated',
                    formatter: this.cellFormatterService.dateTimeUTCFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                }
            ]
        };
    }
}

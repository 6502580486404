import { VocabularyService } from '../../vocabularies/vocabulary.service';
import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import { map } from 'rxjs/operators';

import { AuthService } from '@services/auth.service';
import { DataManagerService } from '@services/data-manager.service';

import { FileUploadCommService } from '@common/file/file-upload-comm.service';
import { TaskTableCommService } from '../services/task-table-comm.service';
import { WorkflowLogicService } from '../services/workflow-logic.service';
import { ImportFileDefinitionService } from '../../import/import-file-definition.service';
import {
    ViewWorkflowAuditReportComponentService
} from '../services/view-workflow-audit-report-component.service';
import { WorkflowVocabService } from '../services/workflow-vocab.service';
import { WorkflowService } from '../services/workflow.service';
import { ExportWorkflowDetailService } from '../services/export-workflow-detail.service';
import { PrintPreviewService } from '@common/services';
import { WebApiService } from '@services/web-api.service';

import {
    BaseDetail,
    BaseDetailService,
    PageState,
} from '@common/facet';

import {
    notEmpty,
    randomId,
    scrollToElement,
    getSafeProp,
    uniqueArrayFromPropertyPath,
    softCompare,
} from '@common/util';

import {
    BooleanMap,
    EntityMap,
} from '../models/workflow-bulk-data';

import { QueryDef } from '@services/query-def';

import { TaskType } from '../../tasks/models';
import { DotmaticsService } from '../../dotmatics/dotmatics.service';
import { SaveChangesService } from '@services/save-changes.service';
import { ExportType } from '@common/export';
import { FeatureFlagService } from '@services/feature-flags.service';
import { DataType } from '../../data-type';
import { Cohort, TaskCohort, TaskCohortInput, TaskInput, TaskMaterial } from '@common/types';
import { TemplateRow } from '../workflow-bulk-data-entry/workflow-bulk-data-entry.component';
import { WorkflowOutputTableComponent } from '../components';

@Component({
    selector: 'workflow-detail',
    templateUrl: './workflow-detail.component.html',
    providers: [FileUploadCommService],
    styles: [`
        .hide-print {
            display: none;
        }
    `]
})
export class WorkflowDetailComponent extends BaseDetail implements OnChanges, OnInit {
    @ViewChild('workflowOutputTable') workflowOutputTable: WorkflowOutputTableComponent;
    @Input() facet: any;
    @Input() task: any;
    @Input() taskType: TaskType;
    @Input() pageState: PageState;

    @Output() exit: EventEmitter<void> = new EventEmitter<void>();
    @Output() next: EventEmitter<void> = new EventEmitter<void>();
    @Output() previous: EventEmitter<void> = new EventEmitter<void>();

    fileUploadCommService: FileUploadCommService;

    // CVs
    taskStatuses: any[] = [];
    taskTypes: any[] = [];
    jobTypes: any[] = [];
    cohorts: any[] = [];

    // State
    workflowTaskOutputs: any[] = [];
    importDetailsExpanded = false;
    importInProgress = false;
    areInputsValid = false;
    filenameInCloud: string;
    attachImportedFile = true;
    showTaskName = false;

    includeAnimalNames = false;
    includeSampleNames = false;
    // Samples assigned to the task (not output samples)
    primarySamples: any[] = null;

    printPreviewId: string;
    importDivId: string;

    // export enum to template
    TaskType = TaskType;

    readonly COMPONENT_LOG_TAG = 'workflow-detail';
    readonly DEFAULT_OUTPUT_FILE_DEFINITION = "Workflow Task Output";

    // Dotmatics workgroup flag
    isDotmatics: boolean;

    exportTypes = ExportType;

    isGLP = false;
    isStudyDirector: boolean;

    taskInputs: TaskCohortInput[] | TaskInput[] = [];

    constructor(
        fileUploadCommService: FileUploadCommService,
        private authService: AuthService,
        private baseDetailService: BaseDetailService,
        private dataManager: DataManagerService,
        private exportWorkflowDetailService: ExportWorkflowDetailService,
        private importFileDefinitionService: ImportFileDefinitionService,
        private printPreviewService: PrintPreviewService,
        private taskTableCommService: TaskTableCommService,
        private viewWorkflowAuditReportComponentService: ViewWorkflowAuditReportComponentService,
        private webApiService: WebApiService,
        private workflowService: WorkflowService,
        private workflowLogicService: WorkflowLogicService,
        private workflowVocabService: WorkflowVocabService,
        private vocabularyService: VocabularyService,
        private dotmaticsService: DotmaticsService,
        private saveChangesService: SaveChangesService,
        private featureFlagService: FeatureFlagService,
    ) {
        super(baseDetailService);
        this.fileUploadCommService = fileUploadCommService;        
    }

    // lifecycle
    ngOnInit() {
        this.printPreviewId = "workflowPrint-" + randomId();
        this.importDivId = "importOutputsDiv-" + randomId();       
        this.initIsGLP();

        this.initialize();
    }


    ngOnChanges(changes: any) {
        if (changes.task) {
            if (this.task && !changes.task.firstChange) {
                this.initialize();
            }
        }
    }

    initIsGLP() {
        const flag = this.featureFlagService.getFlag("IsGLP");
        this.isGLP = (flag && flag.IsActive && flag.Value.toLowerCase() === "true");
    }

    initialize() {
        this.setLoading(true);
        this.setIsDotmatics();
        return this.getCVs().then(() => {
            return this.initTask();
        }).then(() => {
            return this.workflowService.ensureTaskRelationshipsExpanded([this.task]);
        }).then(() => {
            this.setLoading(false);
        }).then(() => {
            this.getCohortsNames();
        }).then(() => {
            if (this.task.showSamples) {
                this.initPrimarySamples();
            } else {
                this.primarySamples = [];
            }
        }).catch((err) => {
            this.setLoading(false);
            throw err;
        });
    }

    getCVs(): Promise<any> {
        const p1 = this.workflowVocabService.taskStatuses$.pipe(map((data) => {
            this.taskStatuses = data;
        })).toPromise();

        const p2 = this.workflowVocabService.taskTypes$.pipe(map((data) => {
            this.taskTypes = data;
        })).toPromise();

        const p3 = this.workflowVocabService.jobTypes$.pipe(map((data) => {
            this.jobTypes = data;
        })).toPromise();     

        const p4 = this.vocabularyService.ensureCVLoaded('cv_TimeUnits');
               
        return Promise.all([p1, p2, p3, p4]);
    }

    async initTask(): Promise<any> {
        const task = this.task;
        task.animalsExpanded = true;
        task.samplesExpanded = true;
        task.loadingTask = true;
        this.validateInputs();

        // Show the task name if it is not already present in the alias
        this.showTaskName = this.taskAliasContainsName(task);

        switch (this.taskType) {
            case TaskType.Animal:
                this.initAnimalTask();
                break;
            case TaskType.Birth:
                this.initBirthTask();
                break;
            case TaskType.Housing:
                this.initHousingTask();
                break;
            case TaskType.Job:
                this.initJobTask();
                break;
            case TaskType.Line:
                this.initLineTask();
                break;
            case TaskType.Mating:
                this.initMatingTask();
                break;
            default:
                console.error("Invalid task type: " + this.taskType);
                break;
        }

        const p1 = this.workflowService.getTaskMaterials(this.task.C_TaskInstance_key);

        const p2 = this.workflowService.getInputs(task.C_TaskInstance_key);

        const p3 = this.workflowService.getOutputSets(task.C_TaskInstance_key).then((data) => {
            if (data.length > 0) {
                const referenceSet = data[0];
                return this.workflowService
                    .getReferenceSetTaskOutputs(referenceSet.C_TaskOutputSet_key);
            } else {
                return this.workflowService.getActiveWorkflowTaskOutputs(task.C_WorkflowTask_key);
            }
        }).then((data) => {
            this.workflowTaskOutputs = data;
            // new outputs may have been defined since this task was created, add them here
            let createdNewOutputs = false;
            for (const outputSet of task.TaskOutputSet) {
                this.workflowLogicService.attachEnumerations(outputSet); 
                this.workflowLogicService.attachVocabularies(outputSet); 

                for (const outputDef of this.workflowTaskOutputs) {
                    const output = outputSet.TaskOutput.find((item: any) => {
                        return item.C_Output_key === outputDef.C_Output_key;
                    });

                    if (!output) {
                        this.workflowService.createOutput(
                            outputDef.C_Output_key, outputSet.C_TaskOutputSet_key
                        );
                        createdNewOutputs = true;
                    }
                }
            }

            // auto-save newly created outputs
            if (createdNewOutputs) {
                this.dataManager.saveEntity('TaskOutput');
            }
                        
            this.workflowTaskOutputs = this.getOnlyValidImportOutputs(this.workflowTaskOutputs);

            return Promise.resolve();
        });

        const p4 = p1.then(() => {
            // if showing Housing IDs, also ensure MaterialPools are loaded
            const showHousingID = getSafeProp(this.task, 'WorkflowTask.ShowHousingID');
            if (showHousingID) {
                const materials = uniqueArrayFromPropertyPath(this.task, 'TaskMaterial.Material');
                return this.workflowService.ensureMaterialPoolsLoaded(materials);
            }
            return Promise.resolve();
        });

        return Promise.all([p1, p2, p3, p4]).then(() => {
            task.loadingTask = false;
            this.taskInputs = this.task.TaskInput;
        }).catch((error) => {
            task.loadingTask = false;
            throw error;
        });
    }

    getOnlyValidImportOutputs(outputs: any) {
        const dataTypes = new Set([
            DataType.BOOLEAN,
            DataType.DATE,
            DataType.DATETIME,
            DataType.VOCABULARY,
            DataType.ENUMERATION,
            DataType.NUMBER,
            DataType.LONG_ENUMERATION,
            DataType.LONG_TEXT,
            DataType.TEXT
        ]);
        return outputs.filter((output: any) => dataTypes.has(getSafeProp(output, 'cv_DataType.DataType')));
    }

    private initAnimalTask() {
        this.task.showAnimals = false;
        this.task.showSamples = false;
        this.task.showMaterialOutputColumn = true;
    }

    private initBirthTask() {
        this.task.showAnimals = false;
        this.task.showSamples = false;
        this.task.showMaterialOutputColumn = false;
    }

    private initHousingTask() {
        this.task.showAnimals = false;
        this.task.showSamples = false;
        this.task.showMaterialOutputColumn = false;
    }

    private initJobTask() {
        this.task.showAnimals = true;
        this.task.showSamples = true;
        this.task.showMaterialOutputColumn = true;        

        if (notEmpty(this.task.TaskJob)) {
            const job = this.task.TaskJob[0].Job;
            if (job && job.IsHighThroughput) {
                this.task.animalsExpanded = false;
                this.task.samplesExpanded = false;
            }
        }
    }

    private initLineTask() {
        this.task.showAnimals = true;
        this.task.showSamples = true;
        this.task.showMaterialOutputColumn = true;
    }

    private initMatingTask() {
        this.task.showAnimals = false;
        this.task.showSamples = false;
        this.task.showMaterialOutputColumn = false;
    }


    // Cancelling
    onCancel() {
        if (this.task) {
            this.workflowService.cancelTaskInstance(this.task);
            this.loggingService.logFacetUndoSuccess('workflow-detail');
        }
    }

    // Inputs
    inputValidationChanged() {
        this.validateInputs();
    }

    requiresInputValidationRow() {
        for (const taskInput of this.task.TaskInput) {
            if (taskInput.Input.RequiresValidation && taskInput.InputValue) {
                return true;
            }
        }
        return false;
    }

    validateInputs() {
        const invalidInputs = this.task.TaskInput.filter((taskInput: any) => {
            return !this.isInputValid(taskInput);
        });

        this.areInputsValid = invalidInputs.length === 0;
    }

    validate() {
        return this.workflowOutputTable.validate();
    }

    isInputValid(taskInput: any): boolean {
        const requiresValidation = getSafeProp(taskInput, 'Input.RequiresValidation');
        if (!requiresValidation) {
            return true;
        }
        return taskInput.InputValue !== null &&
               taskInput.InputValue !== undefined &&
               taskInput.InputValue !== "" && 
            softCompare(taskInput.InputValue, taskInput.ValidatedInputValue);
    }
    
    // Outputs
    addOutputsForAnimals(selectedAnimals: any[]) {
        let alertUser = false;
        const existingAnimals = uniqueArrayFromPropertyPath(
            this.task.TaskOutputSet, 'TaskOutputSetMaterial.Material.Animal.AnimalName'
        );

        for (const taskMaterial of selectedAnimals) {
            const rowExists = existingAnimals.some((item) => {
                return item === taskMaterial.Material.Animal.AnimalName;
            });
            if ((this.task.WorkflowTask.SingleOutputPerAnimal && !rowExists)
                || !this.task.WorkflowTask.SingleOutputPerAnimal
            ) {
                this.addOutputsForMaterial(taskMaterial);
            }
            if (rowExists && this.task.WorkflowTask.SingleOutputPerAnimal) {
                alertUser = true;
            }
        }
        if (alertUser) {
            const message = 'Only new animals have been added to output rows.';
            const showToast = true;
            this.loggingService.logWarning(
                message,
                null,
                this.COMPONENT_LOG_TAG,
                showToast
            );
        }
    }

    addOutputsForSamples(selectedSamples: any[]) {
        let alertUser = false;
        const existingSamples = uniqueArrayFromPropertyPath(
            this.task.TaskOutputSet, 'TaskOutputSetMaterial.Material.Sample.SampleName'
        );

        for (const taskMaterial of selectedSamples) {
            const rowExists = existingSamples.some((item) => {
                return item === taskMaterial.Material.Sample.SampleName;
            });
            if ((this.task.WorkflowTask.SingleOutputPerAnimal && !rowExists)
                || !this.task.WorkflowTask.SingleOutputPerAnimal
            ) {
                this.addOutputsForMaterial(taskMaterial);
            }
            if (rowExists && this.task.WorkflowTask.SingleOutputPerAnimal) {
                alertUser = true;
            }
        }
        if (alertUser) {
            const message = 'Only new samples have been added to output rows.';
            const showToast = true;
            this.loggingService.logWarning(
                message,
                null,
                this.COMPONENT_LOG_TAG,
                showToast
            );
        }
    }

    addOutputsForMaterial(taskMaterial: any) {
        return this.workflowLogicService.addOutputSet(this.task).then((newOutputSet) => {
            this.workflowService.createTaskOutputSetMaterial({
                C_TaskOutputSet_key: newOutputSet.C_TaskOutputSet_key,
                C_Material_key: taskMaterial.Material.C_Material_key
            });

            this.taskTableCommService.refreshMaterialSelects();

            return newOutputSet;
        }).then((newOutputSet) => {
            return this.workflowLogicService.setInheritedOutputValues(
                this.task, newOutputSet, taskMaterial
            );
        });
    }

    dueDateChanged(): Promise<any> {
        return this.updateRelativeTasks();
    }
    completeDateChanged(date: Date): Promise<any> {
        this.workflowLogicService.taskCompletedDataChanged(this.task, date);
        return this.updateRelativeTasks();
    }

    // Status
    taskStatusChanged(): Promise<number> {
        this.workflowLogicService.taskStatusChanged(this.task);
        return this.updateRelativeTasks();
    }

    /**
     * Update the due dates of relative tasks in a protocol
     */
    updateRelativeTasks(): Promise<number> {
        this.setLoading(true);
        this.saveChangesService.isLocked = true;
        return this.workflowLogicService.updateRelativeTasks([this.task], this.COMPONENT_LOG_TAG
        ).then(async (count: number) => {
            await this.saveChangesService.saveChanges(this.COMPONENT_LOG_TAG);
            return count;
        }).catch((err) => {
            throw err;
        }).finally(() => {
            this.saveChangesService.isLocked = false;
            this.setLoading(false);
        });
    }

    // Importing Outputs
    fileUpdate(filename: string) {
        this.filenameInCloud = filename ? filename : null;
    }

    async importOutputs() {
        this.importInProgress = true;
        try {
            await this.webApiService.postApi('api/file/import-mapping/move', JSON.stringify(this.filenameInCloud), 'application/json');

            // Get file definition
            const importDef = await this.importFileDefinitionService.getImportFileDefinitionByName(this.DEFAULT_OUTPUT_FILE_DEFINITION);
            const requestBody = await this.getRequestBody(importDef.C_ImportFileDefinition_key);
            
            await this.webApiService.postApi('api/file/import/', requestBody, 'application/json');

            await this.initTask();

            this.workflowService.workflowImportCompleted();
            this.fileUploadCommService.refreshFiles();
            this.loggingService.logSuccess("Outputs imported", null, this.COMPONENT_LOG_TAG, true);
            this.resetImportState();
        } catch (errorResponse) {
            this.loggingService.logError('Import failed. Please see the "Import" facet for error details.',
                null, this.COMPONENT_LOG_TAG, true);
            this.resetImportState();
            throw errorResponse.error;
        }
    }        

    private async getRequestBody(importFileDefinitionKey: number) {
        const utcOffset = new Date().getTimezoneOffset() / 60;
        return {
            FileName: this.filenameInCloud,
            DefinitionKey: importFileDefinitionKey,
            GlobalVariables: {
                // taxon N/A for output import
                taxon: '',
                user: this.authService.getCurrentUserName(),
                utcOffset,
                // Use an array to be consistent with workflow-bulk-data-entry.component
                taskInstanceKeys: [this.task.C_TaskInstance_key],
                attachToTasks: this.attachImportedFile
            }
        };
    }

    resetImportState() {
        this.importInProgress = false;
        this.filenameInCloud = null;
    }

    async scrollToImport() {
        this.importDetailsExpanded = true;
        await scrollToElement('#' + this.importDivId);
    }

    exportOutputsForImport() {
        const templateRows: TemplateRow[] = this.task.TaskMaterial.map((taskMaterial: TaskMaterial) => {
            return {
                key: taskMaterial.C_TaskMaterial_key,
                animal: this.includeAnimalNames ? taskMaterial.Material?.Animal ?? null : null,
                sample: this.includeSampleNames ? taskMaterial.Material?.Sample ?? null : null
            } as TemplateRow
        });

        this.exportWorkflowDetailService.exportOutputsToCsv(            
            this.workflowTaskOutputs,
            templateRows,
            this.isGLP,
            this.includeAnimalNames,
            this.includeSampleNames,
            false
        );
    }

    // Exporting, Printing, Auditing
    exportWorkflow(exportType: ExportType) {
        this.exportWorkflowDetailService.export(
            this.task,
            this.taskType,
            this.workflowTaskOutputs,
            exportType
        );
    }

    printWorkflow() {  
        this.printPreviewService.printFromId(this.printPreviewId);
    }

    viewAuditReport() {
        this.viewWorkflowAuditReportComponentService.openComponent(this.task.C_TaskInstance_key);
    }


    // <select> formatters
    taskStatusKeyFormatter = (value: any) => {
        return value.C_TaskStatus_key;
    }
    taskStatusFormatter = (value: any) => {
        return value.TaskStatus;
    }

    /**
     * Is the task name (via WorkflowTask) contained in the task alias.
     * 
     * For example, the alias "2 week Ship Mice" contains the task name "Ship Mice"
     * 
     * @param task
     */
    taskAliasContainsName(task: any): boolean {
        if (!task || !task.WorkflowTask || !task.TaskAlias) {
            return false;
        }

        return (task.TaskAlias.indexOf(task.WorkflowTask.TaskName) === -1);
    }

    getCohortsNames() {

        let taskInstanceKey;
        if (this.task.C_GroupTaskInstance_key == null) {
            taskInstanceKey = this.task.C_TaskInstance_key;
        } else {
            taskInstanceKey = this.task.C_GroupTaskInstance_key;
        }
        
        this.workflowService.loadTaskCohorts(taskInstanceKey).then((taskCohorts: TaskCohort[]) => {
            this.cohorts = [];
            const seen = new Set<Cohort>();
            for (const taskCohort of taskCohorts) {
                if (taskCohort.Cohort && !seen.has(taskCohort.Cohort)) {
                    seen.add(taskCohort.Cohort);
                    this.cohorts.push(taskCohort.Cohort);
                    this.task.TaskCohort.push(taskCohort.Cohort);
                    if (!this.task?.cv_TaskStatus?.IsEndState) {
                        this.task.TaskMaterial.forEach((taskMaterial: TaskMaterial) => {
                            const cohortMaterial = taskMaterial.Material.CohortMaterial?.find(item => item.C_Cohort_key === taskCohort.C_Cohort_key);
                            if (cohortMaterial) {
                                this.taskInputs = taskCohort.TaskCohortInput;
                            }
                        });
                    }
                }
            }
        });

        return this.cohorts;
    }

    /**
     * Return only the Animal MaterialSourceMaterial associations
     * @param sample 
     */
    getAnimalSources(sample: any): any[] {
        let sources = getSafeProp(sample, 'Material.MaterialSourceMaterial');
        sources = sources.filter((item: any) => {
            return getSafeProp(item, 'SourceMaterial.Animal');
        });
        return sources;
    }

    /**
     * Return only the Sample MaterialSourceMaterial associations
     * @param sample 
     */
    getSampleSources(sample: any): any[] {
        let sources = getSafeProp(sample, 'Material.MaterialSourceMaterial');
        sources = sources.filter((item: any) => {
            return getSafeProp(item, 'SourceMaterial.Sample');
        });
        return sources;
    }

    /**
     * Gets the primarySamples (SourceMaterials of the Output Samples) in this task.
     * This is a subsection of initRows() in workflow-bulk-data-entry.component.ts
     */
    initPrimarySamples() {
        // Split the Materials into Animals and Samples
        const animals: any[] = this.task.TaskMaterial.map((tm: any) => {
            return getSafeProp(tm, 'Material.Animal');
        }).filter((animal: any) => animal);

        const samples: any[] = this.task.TaskMaterial.map((tm: any) => {
            return getSafeProp(tm, 'Material.Sample');
        }).filter((sample: any) => sample);

        // Map Animals keys to entities
        const animalMap: EntityMap = {};
        animals.forEach((animal: any) => {
            animalMap[animal.C_Material_key] = animal;
        });

        // Map Samples keys to entities
        const sampleMap: EntityMap = {};
        samples.forEach((sample: any) => {
            sampleMap[sample.C_Material_key] = sample;
        });

        // Map Animals keys to Samples and track which Samples are accounted for
        const sampleHasAnimal: BooleanMap = {};
        const animalToSamples: { [key: string]: any[] } = {};

        // Map Samples keys to PrimarySamples and track which Samples are accounted for
        const sampleHasPrimarySample: BooleanMap = {};
        const primarySampleToSamples: { [key: string]: any[] } = {};

        samples.forEach((sample: any) => {
            const animalSources = this.getAnimalSources(sample);
            const sampleSources = this.getSampleSources(sample);

            if ((!animalSources || (animalSources.length === 0)) && (!sampleSources || (sampleSources.length === 0))) {
                // No source Material... weird
                return;
            }

            if ((animalSources.length > 1) || (sampleSources.length > 1)) {
                // Multiple source materials
                // TODO: 
            }

            // Associate this Sample with an Animal
            if (animalSources.length === 1) {
                const animalKey = animalSources[0].C_SourceMaterial_key;
                if (animalMap[animalKey]) {
                    if (!animalToSamples[animalKey]) {
                        animalToSamples[animalKey] = [];
                    }
                    animalToSamples[animalKey].push(sample);
                    sampleHasAnimal[sample.C_Material_key] = true;
                }
            }
            // Associate this Sample with a PrimarySample
            if (sampleSources.length === 1) {
                const sampleKey = sampleSources[0].C_SourceMaterial_key;
                if (sampleMap[sampleKey]) {
                    if (!primarySampleToSamples[sampleKey]) {
                        primarySampleToSamples[sampleKey] = [];
                    }
                    primarySampleToSamples[sampleKey].push(sample);
                    sampleHasPrimarySample[sample.C_Material_key] = true;
                }
            }
        });

        // Filter out the samples that are associated with an animal or primary sample
        this.primarySamples = samples.filter((sample) => {
            if (sample.C_SampleGroup_key) {
                return (!sampleHasAnimal[sample.C_Material_key] && !sampleHasPrimarySample[sample.C_Material_key]);
            }
            return true;
        });
    }

    onSaveTaskInstance(): any {
        this.saveChangesService.saveChanges(this.COMPONENT_LOG_TAG).then(() => {
            if (this.isDotmatics && this.task.TaskMaterial.length > 0) {
                const isEndState: boolean = getSafeProp(this.task, 'cv_TaskStatus.IsDefaultEndState');
                // Update samples in dotmatics
                if (isEndState) {
                    const sampleMaterials = this.dotmaticsService.filterOutputSamples(this.task);
                    sampleMaterials.forEach((sampleMaterial: any) => {
                        if (sampleMaterial.Material.MaterialExternalSync.length > 0) {
                            this.dotmaticsService.updateDotmaticsSample(sampleMaterial.C_Material_key);
                        } else if (!this.task.dtxSynchronized) {
                            this.dotmaticsService.postDotmaticsSample(sampleMaterial.C_Material_key);
                            this.task.dtxSynchronized = true;
                        }
                    });
                }
            }
        });
    }

    /**
     * Sets isDotmatics flag
     */
    private setIsDotmatics() {
        this.isDotmatics = this.dotmaticsService.setIsDotmatics();
    }
}

import { cv_JobSubtype } from "./cv-job-subtype.interface";

export interface JobCharacteristicJobSubtype {
    C_JobCharacteristicJobSubtype_key: number;
    C_JobSubtype_key: number;
    C_JobCharacteristic_key: number;
    DateCreated: Date;
    CreatedBy: string;
    DateModified: Date;
    ModifiedBy: string;

    cv_JobSubtype: cv_JobSubtype;
}

import {
    Injectable
} from '@angular/core';

import {
    NgbModal
} from '@ng-bootstrap/ng-bootstrap';

import { JobAuditReportComponent } from './job-audit-report.component';

/*
* Service to view the audit-report modal.
*/
@Injectable()
export class ViewJobAuditReportComponentService {

    constructor(
        private modalService: NgbModal
    ) {
        //
    }

    openComponent(jobKey: number, showSampleGroups: boolean, isCRL: boolean, isCRO: boolean, isGLP: boolean) {
        const modalRef = this.modalService.open(JobAuditReportComponent, { size: 'lg' });
        modalRef.componentInstance.jobKey = jobKey;
        modalRef.componentInstance.showSampleGroups = showSampleGroups;
        modalRef.componentInstance.isCRL = isCRL;
        modalRef.componentInstance.isCRO = isCRO;
        modalRef.componentInstance.isGLP = isGLP;
    }
}

import {
    Component,
    EventEmitter,
    Input,
    Output
} from '@angular/core';

import { CohortService } from '../../services/cohort.service';

const MAX_SEARCH_RESULTS = 50;

@Component({
    selector: 'cohort-multiselect',
    template: `
    <!-- Selected cohorts -->
    <div *ngIf="model && model.length > 0">
        <table class="table table-sm table-borderless table-nonfluid">
            <tbody>
                <tr *ngFor="let item of model; let i = index;">
                    <td class="icon-cell">
                        <a (click)="removeCohort(i)" title="Remove Cohort">
                            <i class="fa fa-remove remove-item"></i>
                        </a>
                    </td>
                    <td>
                        {{item.CohortName}}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <!-- Cohort Name typeahead -->
    <climb-indirect-typeahead
            [search]="searchCohorts"
            [resultFormatter]="cohortNameFormatter"
            placeholder="Cohort&hellip;"
            (selectItem)="selectCohort($event)"></climb-indirect-typeahead>
    `,
    styles: [`
        .table {
            margin-bottom: 0.25em;
        }
    `]
})
export class CohortMultiselectComponent {
    @Input() model: any[];
    @Output() modelChange: EventEmitter<any> = new EventEmitter<any>();

    constructor(
        private cohortService: CohortService
    ) { }

    removeCohort(index: number) {
        if (this.model && index >= 0 && index < this.model.length) {
            this.model.splice(index, 1);
        }
        this.modelChange.emit(this.model);
    }

    searchCohorts = (term: string): Promise<any> => {
        return this.cohortService.getCohorts({
            page: 1,
            size: MAX_SEARCH_RESULTS,
            sort: 'CohortName asc',
            filter: {
                cohortName: term
            }
        }).then((results) => {
            // Convert Breeze entity to simple object
            // so it can be serialized by consumers
            return results.results.map((cohort: any) => {
                return {
                    CohortName: cohort.CohortName,
                    C_Cohort_key: cohort.C_Cohort_key
                };
            });
        });
    }

    cohortNameFormatter = (value: any) => {
        return value.CohortName;
    }

    selectCohort(cohort: any) {
        if (this.model) {
            this.model.push(cohort);
        } else {
            this.model = [cohort];
        }
        this.modelChange.emit(this.model);
    }
}

import { BulkEditOptions } from './models/bulk-edit.classes';

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class BulkEditCommService {

    // triggered when bulkEditOptions have been modified
    private optionsUpdatedSource = new Subject<BulkEditOptions>();
    optionsUpdated$ = this.optionsUpdatedSource.asObservable();
    optionsUpdated() {
        this.optionsUpdatedSource.next();
    }
}

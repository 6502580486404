import {
    Component,
    Input
} from '@angular/core';

/**
 * Display a messageMap label
 */
@Component({
    selector: 'message-map-label',
    template: `
        <span class="label label-primary">
            <span *ngIf="messageMap.Job">
                {{messageMap.Job.JobID}}
            </span>
            <span *ngIf="messageMap?.Material && messageMap.Material.Animal">
                {{messageMap.Material.Animal.AnimalName}}
            </span>
            <span *ngIf="messageMap?.Material && messageMap.Material.Sample">
                {{messageMap.Material.Sample.SampleName}}
            </span>
            <span *ngIf="messageMap?.Genotype">
                {{messageMap.Genotype.cv_GenotypeAssay.GenotypeAssay}}
                {{messageMap.Genotype.cv_GenotypeSymbol.GenotypeSymbol}}
            </span>
            <span *ngIf="messageMap?.Line">
                {{messageMap.Line.LineName}}
            </span>
            <span *ngIf="messageMap?.TaskInstance">
                {{messageMap.TaskInstance.TaskAlias}}
            </span>
        </span>
    `
})
export class MessageMapLabelComponent {
    @Input() messageMap: any;
}

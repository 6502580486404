
/*
* Truncate string to specific number of characters. Ends on most recent space if it splits a word.
*/
export function truncateSentence(str: string, numChar: number) {
    if (!str || !numChar || numChar <= 0) {
        return '';
    }
    if (str.length <= numChar) {
        return str;
    }
    return str.substr(0, str.lastIndexOf(' ', numChar)) + '...';
}

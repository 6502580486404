import type { JobGroupTreatment } from './job-group-treatment.interface';
import type { Protocol } from './protocol.interface';
import type { TaskInstance } from './task-instance.interface';

export interface ProtocolInstance {
    C_ProtocolInstance_key: number;
    C_Protocol_key: number | null;
    C_Workgroup_key: number;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    JobGroupTreatment: JobGroupTreatment[];
    ModifiedBy: string;
    Protocol: Protocol;
    ProtocolAlias: string;
    TaskInstance: TaskInstance[];
    Version: number;
}

import {
    AfterViewInit,
    Component,
    ElementRef,
    HostBinding,
    OnInit,
} from '@angular/core';
import { CdkCell } from '@angular/cdk/table';
import { getCellVariableName } from '../drag-drop/drag-drop.util';
import { ColumnDefDirective } from './column-def.directive';


/** Cell template container that adds the right classes and role. */
@Component({
    selector: 'climb-cell, td[climbCell]',
    template: `
        <div class="cell-wrapper">
            <ng-content></ng-content>
        </div>
    `,
})
export class CellComponent extends CdkCell implements AfterViewInit, OnInit {
    @HostBinding('class')
    private get classes(): Record<string, boolean> {
        return {
            ['climb-cell']: true,
            ['cell-icon-only']: this.isOnlyIcon,
        };
    }

    private isOnlyIcon = false;

    constructor(
        private columnDef: ColumnDefDirective,
        private elementRef: ElementRef,
    ) {
        super(columnDef, elementRef);
    }

    ngAfterViewInit(): void {
        const cellCssVar = getCellVariableName(this.columnDef.columnId);
        this.elementRef.nativeElement.style.transform = `var(${cellCssVar})`;
    }

    ngOnInit(): void {
        this.isOnlyIcon = this.columnDef.onlyIcon;
    }
}

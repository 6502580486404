/**
 * debounce a promise function
 * @param fn
 * @param ms
 */
export function debouncePromise(fn: any, ms = 0) {
    let timeoutId: any;
    return function (...args: any) {
        return new Promise((res, rej) => {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => {
                Promise.resolve(fn.apply(this, args)).then(
                    (data) => {
                        res(data);
                    },
                    (error) => {
                        rej(error);
                    }
                );
            }, ms);
        });
    };
}

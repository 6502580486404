import { Pipe, PipeTransform } from '@angular/core';

interface TaskAnimalHealthRecord {
    JobName: string;
}

interface HealthRecord {
    TaskAnimalHealthRecord: TaskAnimalHealthRecord[];
}

/**
 * Depending on the type of table on a Health Record, return the proper display string 
 * depending on the amount of JobNames present in the list.
 */
@Pipe({
    name: 'clinicalJobNameFormat',
    pure: false
})
export class ClinicalJobNameFormatPipe implements PipeTransform {

    transform<T extends HealthRecord>(healthRecord: T, tableType: string, jobNames: string, i: number): string {
        if (tableType.toLowerCase() === 'treatment') {
            const taskAnimalHealthRecords = healthRecord.TaskAnimalHealthRecord;
            if (taskAnimalHealthRecords) {
                const taskAnimalHealthRecordsLength = taskAnimalHealthRecords.length;
                const index = (taskAnimalHealthRecordsLength - i) - 1;
                const taskAnimalHealthRecord = taskAnimalHealthRecords[index];

                if (taskAnimalHealthRecord.JobName) {
                    const jobNamesFromRecord = taskAnimalHealthRecord.JobName;
                    const jobNamesArr = jobNamesFromRecord.split(",");
                    if (jobNamesArr.length > 3) {
                        return jobNamesArr.slice(0, 3).join(", ") + " and " + (jobNamesArr.length - 3) + " more";
                    } else {
                        return jobNamesArr.join(", ");
                    }
                } else {
                    return "";
                }
            } else {
                return "";
            }
        } else if (tableType.toLowerCase() === 'observations') {
            if (jobNames) {
                const jobNamesArr = jobNames.split(",");
                if (jobNamesArr.length > 3) {
                    return jobNamesArr.slice(0, 3).join(", ") + " and " + (jobNamesArr.length - 3) + " more";
                } else {
                    return jobNamesArr.join(", ");
                }
            } else {
                return "";
            }
        } else {
            return "";
        }
    }
}

import type { Study } from './study.interface';

export interface cv_StudyStatus {
    Abbreviation: string;
    C_StudyStatus_key: number;
    C_VocabularySource_key: number | null;
    C_Workgroup_key: number;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    ElementID: string;
    IsActive: boolean;
    IsDefault: boolean;
    IsDeprecated: boolean;
    IsEndStatus: boolean;
    ModifiedBy: string;
    SortOrder: number | null;
    Study: Study[];
    StudyStatus: string;
    Version: number;
}

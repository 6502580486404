import { Component, Input, OnInit } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { arrayBufferToBase64 } from './array-to-base-64';
import { FileService } from '../../services/file.service';

@Component({
    selector: 'image-modal',
    template: `
    <div class="modal-header justify-content-center">
        <h4 class="modal-title">{{imageFile.FriendlyFileName}}</h4>
    </div>
    <div class="modal-body" style="vertical-align:top;">
        <loading-overlay *ngIf="!imageFile.imageData" [message]="'Loading image'"></loading-overlay>
        <img *ngIf="imageFile.imageData" style="max-width:100%;max-height:100%;"
            src="data:image/PNG;base64,{{imageFile.imageData}}" >
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary btn-lg" (click)="closeModal()">Close</button>
    </div>
  `
})
export class ImageModalComponent implements OnInit {
    @Input() imageFile: any;

    // services
    activeModal: NgbActiveModal;
    fileService: FileService;

    constructor(
        activeModal: NgbActiveModal,
        fileService: FileService,
    ) {
        this.activeModal = activeModal;
        this.fileService = fileService;
    }

    ngOnInit() {
        this.setupImageData();
    }

    setupImageData() {
        const file = this.imageFile;

        // retrieve image data from database and convert to base 64
        this.fileService.getFile(file.FileName, file.FriendlyFileName).subscribe((response) => {
            file.imageData = arrayBufferToBase64(response);
        });
    }

    closeModal() {
        this.activeModal.close('Close click');
    }
}

import type { CompatibilityMaterial } from './compatibility-material.interface';
import type { Device } from './device.interface';
import type { JobMaterialPool } from './job-material-pool.interface';
import type { MaterialLocation } from './material-location.interface';
import type { MaterialPoolMaterial } from './material-pool-material.interface';
import type { Mating } from './mating.interface';
import type { Note } from './note.interface';
import type { SocialGroupMaterial } from './social-group-material.interface';
import type { TaskMaterialPool } from './task-material-pool.interface';
import type { cv_ContainerType } from './cv-container-type.interface';
import type { cv_MaterialPoolStatus } from './cv-material-pool-status.interface';
import type { cv_MaterialPoolType } from './cv-material-pool-type.interface';

export interface MaterialPool {
    C_ContainerType_key: number | null;
    C_MaterialPoolStatus_key: number | null;
    C_MaterialPoolType_key: number;
    C_MaterialPool_key: number;
    C_Workgroup_key: number;
    Comments: string;
    CompatibilityMaterial: CompatibilityMaterial[];
    Count: number;
    CreatedBy: string;
    CurrentLocationPath: string;
    DateCreated: Date;
    DateModified: Date;
    DatePooled: Date | null;
    Device: Device[];
    JobMaterialPool: JobMaterialPool[];
    MaterialLocation: MaterialLocation[];
    MaterialPoolID: string;
    MaterialPoolIDSortable: string;
    MaterialPoolMaterial: MaterialPoolMaterial[];
    Mating: Mating;
    ModifiedBy: string;
    Note: Note[];
    Owner: string;
    SocialGroupMaterial: SocialGroupMaterial[];
    TaskMaterialPool: TaskMaterialPool[];
    Version: number;
    cv_ContainerType: cv_ContainerType;
    cv_MaterialPoolStatus: cv_MaterialPoolStatus;
    cv_MaterialPoolType: cv_MaterialPoolType;
}

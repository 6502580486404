<div class="modal-header justify-content-center">
    <h4 class="modal-title">Leave Bulk Add</h4>
</div>

<div class="modal-body">
    <p>
        You have unfinished changes that cannot be saved.
        Required fields are missing.
    </p>
</div>

<div class="modal-footer">
    <button type="button"
            class="btn btn-lg btn-fw-xwide btn-primary"
            (click)="stayClicked()"
            autofocus>
        Return to Form
    </button>
    <button type="button"
            class="btn btn-lg btn-fw-xwide btn-secondary"
            (click)="discardClicked()">
        Discard Changes
    </button>
</div>

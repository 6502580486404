<form role="form" class="form-label-right">

    <div class="modal-header justify-content-center">
        <h4 class="modal-title">{{'Studies' | translate}} Filter</h4>
    </div>

    <div class="modal-body">
        <div class="form-group row">
            <label class="col-md-3 col-form-label">Name</label>
            <div class="col-md-9">
                <input type="text"
                       name="studyName"
                       [(ngModel)]="filter.StudyName"
                       class="form-control input-medium"
                       data-auto-focus="studyName" />
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-3 col-form-label">Type</label>
            <div class="col-md-9">
                <climb-multi-select [(model)]="filter.C_StudyType_keys"
                                    [values]="studyTypes"
                                    [keyProperty]="'C_StudyType_key'"
                                    [valueProperty]="'StudyType'"
                                    [placeholder]="'Select types...'"></climb-multi-select>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-3 col-form-label">Status</label>
            <div class="col-md-9">
                <climb-multi-select [(model)]="filter.C_StudyStatus_keys"
                                    [values]="studyStatuses"
                                    [keyProperty]="'C_StudyStatus_key'"
                                    [valueProperty]="'StudyStatus'"
                                    [placeholder]="'Select statuses...'"></climb-multi-select>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-3 col-form-label">Approval Number</label>
            <div class="col-md-9">
                <input type="text"
                       name="approvalNumber"
                       [(ngModel)]="filter.ApprovalNumber"
                       class="form-control input-medium"
                       data-auto-focus="approvalNumber" />
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-3 col-form-label">Active</label>
            <div class="col-md-9">
                <active-status-select [(model)]="filter.IsActive"
                                      [nullable]="true"></active-status-select>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-3 col-form-label">Created By</label>
            <div class="col-md-9">
                <user-select [(model)]="filter.CreatedBy"
                             [forceValidSelection]="false"></user-select>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-3 col-form-label">Created Date Range</label>
            <div class="col-md-4">
                <climb-ngb-date #dateControl="ngModel"
                                name="Created Date Start"
                                [(ngModel)]="filter.DateCreatedStart"
                                [placeholder]="'From&hellip;'"></climb-ngb-date>
            </div>
            <div class="col-md-4">
                <climb-ngb-date #dateControl="ngModel"
                                name="Created Date End"
                                [(ngModel)]="filter.DateCreatedEnd"
                                [placeholder]="'To&hellip;'"></climb-ngb-date>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-3 col-form-label">{{'Line' | translate}}</label>
            <div class="col-md-9">
                <line-multiselect [(model)]="filter.lines"></line-multiselect>
            </div>
        </div>

    </div>

    <div class="modal-footer">
        <span class="btn-set">
            <button type="submit"
                    class="btn btn-fw-wide btn-lg btn-primary"
                    (click)="filterClicked($event)">
                Filter
            </button>
            <button data-automation-id="clear-filter-button"
                    type="button"
                    class="btn btn-fw-wide btn-lg btn-secondary"
                    (click)="clearClicked()">
                Clear Filter
            </button>
        </span>
        <span class="btn-set">
            <button type="button"
                    class="btn btn-lg btn-secondary"
                    (click)="closeClicked()">
                Close
            </button>
        </span>
    </div>
</form>

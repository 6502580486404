import {
    Component,
    EventEmitter,
    Input,
    Output
} from '@angular/core';

import { SearchService } from '../../search/search.service';
import { SearchQueryDef } from '../../search/search-query-def';

const MAX_SEARCH_RESULTS = 50;

@Component({
    selector: 'protocol-multiselect',
    template: `
<!-- Selected protocols -->
<div *ngIf="model && model.length > 0">
    <table class="table table-sm table-borderless table-nonfluid">
        <tbody>
            <tr *ngFor="let item of model; let i = index;">
                <td class="icon-cell">
                    <a (click)="removeProtocol(i)" title="Remove protocol">
                        <i class="fa fa-remove remove-item"></i>
                    </a>
                </td>
                <td>
                    {{item.ProtocolName}}
                </td>
            </tr>
        </tbody>
    </table>
</div>

<!-- Protocol typeahead -->
<climb-indirect-typeahead
        [search]="searchProtocols"
        [resultFormatter]="protocolNameFormatter"
        placeholder="Name&hellip;"
        (selectItem)="selectProtocol($event)"></climb-indirect-typeahead>
`,
    styles: [`
        .table {
            margin-bottom: 0.25em;
        }
    `]
})
export class ProtocolMultiselectComponent {
    @Input() model: any[];
    @Output() modelChange: EventEmitter<any> = new EventEmitter<any>();

    constructor(
        private _searchService: SearchService
    ) {
        //
    }

    removeProtocol(index: number) {
        if (this.model && index >= 0 && index < this.model.length) {
            this.model.splice(index, 1);
        }
        this.modelChange.emit(this.model);
    }

    searchProtocols = (term: string): Promise<any> => {

        let count = null;
        if (term.length < 3) {
            count = MAX_SEARCH_RESULTS;
        }

        const protocolFilter = {
            ProtocolName: term
        };

        const searchQueryDef: SearchQueryDef = {
            entity: 'Protocols',
            page: 1,
            size: count,
            sortColumn: 'ProtocolName',
            sortDirection: 'asc',
            filter: protocolFilter
        };

        return this._searchService.getEntitiesBySearch(searchQueryDef)
            .then((results: any) => {
                return results.data;
            });
    }

    protocolNameFormatter = (value: any) => {
        return value.ProtocolName;
    }

    selectProtocol(protocol: any) {
        if (this.model) {
            this.model.push(protocol);
        } else {
            this.model = [protocol];
        }

        this.modelChange.emit(this.model);
    }
}


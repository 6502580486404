import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { LoggingService } from '../services/logging.service';

import { apiServiceBaseUri } from '../config/climb-web-settings';
import { AuthService } from './auth.service';
import { ClimbHttpParamEncoder } from '@common/util/climb-http-param-encoder';

@Injectable()
export class FileService {

    readonly COMPONENT_LOG_TAG = 'file-service';

    constructor(
        private _http: HttpClient,
        private _loggingService: LoggingService,
        private _authService: AuthService
    ) { }

    private _buildFileReqOptions(fileName: string, friendlyFileName: string) {
        const headers = new HttpHeaders({
            'Cache-Control': 'no-store',
            'Content-Type': 'application/octet-stream; charset=utf-8',
        });

        const params = new HttpParams({encoder: new ClimbHttpParamEncoder() })
          .append('fileName', fileName)
          .append('friendlyFileName', friendlyFileName);

        return {
            headers,
            params,
            responseType: 'arraybuffer' as const,
        };
    }

    getFile(fileName: string, friendlyFileName: string): Observable<ArrayBuffer> {
        const getFileUrl = `${apiServiceBaseUri}api/storage/getfile`;
        const options = this._buildFileReqOptions(fileName, friendlyFileName);
        return this._http.get(getFileUrl, options);
    }

    downloadFile(fileName: string, friendlyFileName: string): Observable<ArrayBuffer> {
        return this.getFile(fileName, friendlyFileName).pipe(tap((response) => {
            const blob = new Blob([response]);

            try {
                /* eslint-disable */
                window['saveAs'](blob, friendlyFileName);
                /* eslint-enable */
            } catch (ex) {
                this._loggingService.logError(
                    "Simulated download is not supported by your browser.",
                    ex,
                    this.COMPONENT_LOG_TAG,
                    true
                );
            }
        }));
    }
}

import { cv_IACUCProtocol } from "./cv-iacuc-protocol.interface";

export interface JobCharacteristicIACUCProtocol {
    C_JobCharacteristicIACUCProtocol_key: number;
    C_IACUCProtocol_key: number;
    C_JobCharacteristic_key: number;
    DateCreated: Date;
    CreatedBy: string;
    DateModified: Date;
    ModifiedBy: string;

    cv_IACUCProtocol: cv_IACUCProtocol;
}

import type { Job } from './job.interface';

export interface cv_JobStatus {
    C_JobStatus_key: number;
    C_VocabularySource_key: number | null;
    C_Workgroup_key: number;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    ElementID: string;
    IsActive: boolean;
    IsDefault: boolean;
    IsDeprecated: boolean;
    IsDraft: boolean;
    IsEndState: boolean;
    Job: Job[];
    JobStatus: string;
    ModifiedBy: string;
    SortOrder: number | null;
    Version: number;
}

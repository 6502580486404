import {
    Component
} from '@angular/core';

import {
    NgbActiveModal
} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'bulk-add-prompt-modal',
    templateUrl: './bulk-add-prompt-modal.component.html'
})
export class BulkAddPromptModalComponent {

    constructor(
        private activeModal: NgbActiveModal
    ) {
        //
    }

    stayClicked(): void {
        this.activeModal.dismiss('stay');
    }

    discardClicked(): void {
        this.activeModal.dismiss('discard');
    }
}

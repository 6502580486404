import type { ClinicalObservationDetail } from './clinical-observation-detail.interface';
import type { cv_ClinicalObservationBodySystem } from './cv-clinical-observation-body-system.interface';
import type { cv_ClinicalObservationModifier1 } from './cv-clinical-observation-modifier1.interface';
import type { cv_ClinicalObservationModifier2 } from './cv-clinical-observation-modifier2.interface';
import type { cv_ClinicalObservationModifier3 } from './cv-clinical-observation-modifier3.interface';
import type { cv_ClinicalObservationModifier4 } from './cv-clinical-observation-modifier4.interface';

export interface cv_ClinicalObservation {
    Abbreviation: string;
    C_ClinicalObservation_key: number;
    C_VocabularySource_key: number | null;
    C_Workgroup_key: number;
    ClinicalObservation: string;
    ClinicalObservationDetail: ClinicalObservationDetail[];
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    ElementID: string;
    IsActive: boolean;
    IsDefault: boolean;
    IsDeprecated: boolean;
    ModifiedBy: string;
    SortOrder: number | null;
    TrackInWorkflow: boolean;
    Version: number;
    cv_ClinicalObservationBodySystem: cv_ClinicalObservationBodySystem[];
    cv_ClinicalObservationModifier1: cv_ClinicalObservationModifier1[];
    cv_ClinicalObservationModifier2: cv_ClinicalObservationModifier2[];
    cv_ClinicalObservationModifier3: cv_ClinicalObservationModifier3[];
    cv_ClinicalObservationModifier4: cv_ClinicalObservationModifier4[];
}

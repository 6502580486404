import { map } from 'rxjs/operators';
import {
    Component,
    EventEmitter,
    Input,
    Output
} from '@angular/core';

import { lowerCaseCompare } from '../../common/util';
import { ClinicalVocabService } from '../clinical-vocab.service';
import { cv_ClinicalObservation } from '@common/types';

@Component({
    selector: 'clinical-multiselect',
    template: `
<!-- Clinical observation typeahead -->
<climb-indirect-typeahead
        [search]="searchClinicalObservations"
        [resultFormatter]="clinicalObservationNameFormatter"
        [exactMatchFunction]="isClinicalObservationExactMatch"
        placeholder="Clinical Observation..."
        (selectItem)="selectClinicalObservation($event)">
    <ng-content select="[extra-buttons]"></ng-content>
</climb-indirect-typeahead>
`,
    styles: [`
        .table {
            margin-bottom: 0.25em;
        }
        .y-scroll {
            overflow-y: scroll;
        }
    `]
})
export class ClinicalMultiselectComponent {
    @Input() selectedObservations: any[];
    @Output() onSelect: EventEmitter<any[]> = new EventEmitter<any[]>();

    observationCVs: any[] = [];

    constructor(
       private clinicalVocabService: ClinicalVocabService,
    ) {

    }

    searchClinicalObservations = (term: string): Promise<any> => {
        return this.clinicalVocabService.clinicalObservations$
            .pipe(map((clinicalObservations) => {
                this.observationCVs = clinicalObservations;
                this.assignPreselected();
            })).toPromise().then(() => {
                return this.observationCVs.filter((observationCV: cv_ClinicalObservation) => {
                    const termCooked = term.trim().toLowerCase();
                    if (termCooked && termCooked.length > 0) {
                        return observationCV.IsActive && observationCV.ClinicalObservation.toLowerCase().includes(termCooked);
                    }

                    return observationCV.IsActive;
                });
            });
    }

    assignPreselected() {
        if (!this.selectedObservations) {
            return;
        }

        const selectedKeys = this.selectedObservations.map((item: any) => {
            return item.C_ClinicalObservation_key;
        });

        for (const observationCV of this.observationCVs) {
            observationCV.IsSelected = selectedKeys.indexOf(
                observationCV.C_ClinicalObservation_key
            ) > -1;
        }
    }

    isClinicalObservationExactMatch = (data: any[], term: string) => {
        if (term && data && data.length === 1) {
            return lowerCaseCompare(data[0].ClinicalObservation, term);
        }

        return false;
    }

    clinicalObservationNameFormatter = (value: any) => {
        return value.ClinicalObservation;
    }

    selectClinicalObservation(observation: any) {
        observation.IsSelected = !observation.IsSelected;
        const selectedObservations = this.observationCVs.filter((item: any) => item.IsSelected);

        this.onSelect.emit(selectedObservations);
    }
}

import { map } from 'rxjs/operators';
import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';

import { BaseFilter } from '../common/facet';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TaskVocabService } from './task-vocab.service';

import {
    focusElementByQuery
} from '../common/util/';
import { TranslationService } from '@services/translation.service';


@Component({
    selector: 'task-filter',
    templateUrl: './task-filter.component.html'
})
export class TaskFilterComponent extends BaseFilter implements OnInit {
    @Input() filter: any = {};
    @Output() onFilter = new EventEmitter<any>();
    @Output() close = new EventEmitter<any>();

    // CVs
    taskTypes: any[] = [];


    constructor(
        private activeModal: NgbActiveModal,
        private taskVocabService: TaskVocabService,
        private translationService: TranslationService
    ) {
        super(activeModal);
    }

    ngOnInit() {
        this.cloneFilter();

        this.getCVs();

        focusElementByQuery('[data-auto-focus="taskName"]');
    }

    private cloneFilter() {
        this.filter = this.copyFilter(this.filter);
    }


    private getCVs(): Promise<any> {
        return this.taskVocabService.taskTypes$.pipe(map((data) => {
            this.taskTypes = data;
        })).toPromise();
    }

    filterClicked(event: Event) {
        event.preventDefault();
        this.onFilter.emit(this.filter);
        this.close.emit();
        this.activeModal.close();
    }

    closeClicked() {
        this.close.emit();
        this.activeModal.dismiss();
    }

    clearClicked() {
        this.filter = {};
        this.onFilter.emit(this.filter);
        this.close.emit();
        this.activeModal.close();
    }
    
    taskTypeKeyFormatter = (value: any) => {
        return value.C_TaskType_key;
    }
    taskTypeFormatter = (value: any) => {
        return this.translationService.translate(value.TaskType);
    }
}

import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
    selector: 'climb-chart-line',
    templateUrl: './chart-line.component.html',
    styleUrls: ['./chart-line.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartLineComponent {
    @Input() label: string;
    @Input() value: number;
    @Input() maxValue: number;
}

import {
    Component,
    EventEmitter,
    Input,
    Output
} from '@angular/core';

import { SearchService } from '../search/search.service';
import { SearchQueryDef } from '../search/search-query-def';

@Component({
    selector: 'location-select',
    templateUrl: './location-select.component.html',
    styles: [`
        .location-select {
            min-width: 100px;
        }
    `]
})
export class LocationSelectComponent {
    @Input() model: any;
    @Input() placeholder: string;
    @Input() required: boolean;
    @Input() disabled: boolean;

    @Output() modelChange: EventEmitter<any> = new EventEmitter<any>();

    constructor(
        private searchService: SearchService
    ) {
        //
    }

    selectItemHandler(item: any) {
        this.modelChange.emit(this.model);
    }

    searchLocations = (search: string): Promise<any[]> => {
        const filter = {
            PositionName: search
        };

        let count = null;
        // if 2 characters or less, put a limit on number returned
        if (search.length < 3) {
            count = 50;
        }

        return this._searchLocations(filter, count);
    }

    searchLocationsByKey = (key: any): Promise<any[]> => {
        const filter = {
            LocationKey: key
        };

        return this._searchLocations(filter, 1);
    }

    private _searchLocations(locationFilter: any, count: number): Promise<any[]> {
        const searchQueryDef: SearchQueryDef = {
            entity: 'Locations',
            page: 1,
            size: count,
            sortColumn: 'PositionName',
            sortDirection: 'asc',
            filter: locationFilter
        };

        return this.searchService.getEntitiesBySearch(searchQueryDef).then((results) => {
            return results.data;
        });
    }

    locationNameFormatter = (item: any) => {
        return item.PositionName;
    }

    locationKeyFormatter = (item: any) => {
        return item.LocationKey;
    }
}

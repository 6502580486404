<span *ngIf="isMultipleSort && sortOrder > 0"
      class="sort-order"
      aria-hidden="true">
    {{ sortOrder }}
</span>

<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 16">
    <path class="caret-up" d="M5.3 2 9 5.98v.52H1v-.52L4.7 2h.6Z"/>
    <path class="caret-down" d="M5.3 14 9 10.02V9.5H1v.52L4.7 14h.6Z"/>
</svg>

import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpEvent, HttpRequest } from '@angular/common/http';

export interface RequestCacheEntry {
    url: string;
    response: Observable<HttpEvent<any>>;
    createdAt: number;
}

const maxAge = 5000; // maximum cache age (ms)
const isExpired = (createdAt: number): boolean => createdAt < (Date.now() - maxAge);

@Injectable()
export class RequestCache {
    private cache = new Map<string, RequestCacheEntry>();

    get(request: HttpRequest<any>): Observable<HttpEvent<unknown>> | undefined {
        const url = request.urlWithParams;
        const cached = this.cache.get(url);

        if (!cached) {
            return undefined;
        }

        if (isExpired(cached.createdAt)) {
            this.cache.delete(url);
            return undefined;
        }

        return cached.response;
    }

    set(request: HttpRequest<any>, response: Observable<HttpEvent<any>>): void {
        const url = request.urlWithParams;

        const newEntry = { url, response, createdAt: Date.now() };
        this.cache.set(url, newEntry);

        this.removeExpiredEntries();
    }

    clear(): void {
        this.cache.clear();
    }

    private removeExpiredEntries(): void {
        const expired = Date.now() - maxAge;
        this.cache.forEach((entry) => {
            if (entry.createdAt < expired) {
                this.cache.delete(entry.url);
            }
        });
    }
}

export enum RouteEnum {
    Dashboard = 'dashboard',
    Home = 'home',
    Initialization = 'initialization',
    Login = 'login',
    PwdReset = 'pwdreset',
    RequestPwdReset = 'requestpwdreset',
    PreRegistrationEmail = 'preregistrationemail',
    EmailVerification = 'emailverification',
    Workspaces = 'workspaces',
    Versions = 'versions',
    LoggedOutRoute = 'logged-out',
    SamlJwt = 'samljwt',
    LoginOkta = 'login-okta',
    LoginCallback = 'login/callback',
}

import { DynamicComponentLoader } from './dynamic-component-loader.service';
import { DYNAMIC_COMPONENT_MANIFESTS } from './dynamic-component-manifests.token';
import {
    NgModule,
    ModuleWithProviders, 
} from '@angular/core';
import { ROUTES } from '@angular/router';

@NgModule({
    providers: [],
  })
  export class DynamicComponentLoaderModule {
    public static forRoot(): ModuleWithProviders<DynamicComponentLoaderModule> {
      return {
        ngModule: DynamicComponentLoaderModule,
        providers: [
          { provide: ROUTES, useValue: [], multi: true },
          { provide: DYNAMIC_COMPONENT_MANIFESTS, useValue: [] },
          DynamicComponentLoader
        ],
      };
    }
  }

import {
    CellFormatterService,
    TableOptions
} from '../common/datatable';
import { TranslationService } from '../services/translation.service';
import { addAuditColumnsToOptions } from '@common/datatable/utils';

export class InstitutionTableOptions {

    options: TableOptions;

    constructor(
        private cellFormatterService: CellFormatterService,
        private translationService: TranslationService,
        private isCRL: boolean
    ) {
        this.options = addAuditColumnsToOptions(cellFormatterService)(this.getDefaultOptions());
    }

    getDefaultOptions(): TableOptions {
        return {
            csvFileName: this.translationService.translate('institution').toLowerCase() + '.csv',
            enableDetailColumn: true,
            enableDraggable: false,
            enableSelectable: false,
            refreshOnColumnChange: true,
            columns: [
                {
                    displayName: 'Name',
                    field: 'Name'
                },
                {
                    displayName: this.isCRL ? 'Account Number' : 'Identifier',
                    field: 'Identifier'
                },

                {
                    displayName: this.isCRL ? 'Client ID' : 'Account Number',
                    field: 'AccountNumber'
                },
                {
                    displayName: 'Email Address',
                    field: 'EmailAddress',
                    visible: false
                },
                {
                    displayName: 'Address',
                    field: 'Address',
                    visible: false
                },
                {
                    displayName: 'Address 2',
                    field: 'AddressQualifier',
                    visible: false
                },
                {
                    displayName: 'City',
                    field: 'City',
                },
                {
                    displayName: 'State',
                    field: 'cv_State.StateName',
                },
                {
                    displayName: 'Country',
                    field: 'cv_Country.Country',
                },
                {
                    displayName: 'Zip Code',
                    field: 'PostalCode',
                    visible: false,
                    // formatter: this.cellFormatterService.postalCodeFormatter
                },
                {
                    displayName: 'Phone Number',
                    field: 'Phone',
                    visible: false,
                    // formatter: this.cellFormatterService.phoneNumberFormatter
                },
                {
                    displayName: 'Mobile Number',
                    field: 'MobilePhone',
                    visible: false,
                    // formatter: this.cellFormatterService.phoneNumberFormatter
                },
                {
                    displayName: 'Type',
                    field: 'cv_InstitutionType.InstitutionType'
                },
                {
                    displayName: 'Notes',
                    field: 'Note',
                    visible: false,
                    formatter: this.cellFormatterService.noteFormatter,
                    sortable: false
                },
            ]
        };
    }
}

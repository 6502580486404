import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { apiServiceBaseUri, apiServiceBaseUriAdmin } from "@config";
import { Observable } from "rxjs";
import { LocalStorageKey } from '../../config/local-storage-key.enum';

@Injectable()
export class WorkgroupInterceptor implements HttpInterceptor {
  constructor() { /* empty constructor */ }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        const url = request.url;
        if (!url.includes(apiServiceBaseUri) && !url.includes(apiServiceBaseUriAdmin)) {
            return next.handle(request);
        }

        const workgroupKey = window.localStorage.getItem(`ls.${LocalStorageKey.WORKGROUP_KEY}`);
        return next.handle(workgroupKey
            ? request.clone({ setHeaders: { 'X-Workgroup-Key': workgroupKey }})
            : request
        );
    }
}

<h4>Workgroup Animal Characteristics</h4>
<table class="table table-bordered">
    <thead>
        <tr>
            <th>
                Characteristic 1
            </th>
            <th>
                Characteristic 2
            </th>
            <th>
                Characteristic 3
            </th>
            <th>
                Characteristic 4
            </th>
            <th>
                Characteristic 5
            </th>
            <th>
                Characteristic 6
            </th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td>
                <ng-container *ngTemplateOutlet="characteristicDropdown;context: {listViewOrder: '1'}"></ng-container>
            </td>
            <td>
                <ng-container *ngTemplateOutlet="characteristicDropdown;context: {listViewOrder: '2'}"></ng-container>
            </td>
            <td>
                <ng-container *ngTemplateOutlet="characteristicDropdown;context: {listViewOrder: '3'}"></ng-container>
            </td>
            <td>
                <ng-container *ngTemplateOutlet="characteristicDropdown;context: {listViewOrder: '4'}"></ng-container>
            </td>
            <td>
                <ng-container *ngTemplateOutlet="characteristicDropdown;context: {listViewOrder: '5'}"></ng-container>
            </td>
            <td>
                <ng-container *ngTemplateOutlet="characteristicDropdown;context: {listViewOrder: '6'}"></ng-container>
            </td>
        </tr>
    </tbody>
</table>

<ng-template #characteristicDropdown let-listViewOrder="listViewOrder" let-listViewOrderRaw="listViewOrderRaw">
    <climb-typeahead [model]="taxonCharacteristicMap[listViewOrder]?.C_TaxonCharacteristic_key"
                     [search]="searchTaxonCharacteristics"
                     [keySearch]="searchTaxonCharacteristicsByKey"
                     [keyFormatter]="taxonCharacteristicKeyFormatter"
                     [resultFormatter]="taxonCharacteristicValueFormatter"
                     [disabled]="readonly"
                     (selectItem)="setTaxonCharacteristicListOrder($event, listViewOrder)"></climb-typeahead>
</ng-template>

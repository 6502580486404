import { Injectable } from '@angular/core';
import { Entity as InternalEntity, StoredFile, StoredFileMap, Study } from '@common/types';
import { DataManagerService } from '@services/data-manager.service';
import { GlpBaseFacetStateService } from '@services/glp-base-facet-state.service';
import { Entity as BreezeEntity } from 'breeze-client';

@Injectable()
export class StudyStateService extends GlpBaseFacetStateService<Study> {
    constructor(protected dataManagerService: DataManagerService) {
        super(dataManagerService);
    }

    getRelatedCollectionChanges(study: InternalEntity<Study>): BreezeEntity[] {
        const changes: BreezeEntity[] = [];

        changes.push(
            ...this.getChangesToEntityByKey('StudyLine', 'C_Study_key', study.C_Study_key),
            ...this.getChangesToEntityByKey('StudyAdministratorStudy', 'C_Study_key', study.C_Study_key),
            ...this.getChangesToEntityByKey('StudyCharacteristicInstance', 'C_Study_key', study.C_Study_key),
            ...this.getChangesToFiles(study.C_Study_key),
        );

        return changes;
    }

    getChangesToFiles(studyKey: number): BreezeEntity[] {
        const changes: BreezeEntity[] = [];

        const storedFileMaps = (this.dataManagerService.getManager().getChanges('StoredFileMap') ??
            []) as InternalEntity<StoredFileMap>[];

        const changedStoreFileMaps = storedFileMaps.filter((storedFileMap) => storedFileMap.C_Study_key === studyKey);

        const storedFiles = (this.dataManagerService.getManager().getChanges('StoredFile') ??
            []) as InternalEntity<StoredFile>[];

        // Get corresponding files descriptions
        const changedStoreFiles = storedFiles.filter((storedFile) =>
            changedStoreFileMaps.some(
                (storedFileMap) => storedFileMap.C_StoredFile_key === storedFile.C_StoredFile_key,
            ),
        );

        changes.push(...changedStoreFileMaps);
        changes.push(...changedStoreFiles);
        return changes;
    }

    getDeleteNavPropertyChanges(): BreezeEntity[] {
        return [];
    }

    getChangesToEntityByKey(entityName: string, key: string, value: number): InternalEntity<Study>[] {
        return this.dataManagerService.getChangesToEntityByFilter(entityName, (item: BreezeEntity) => {
            if (item.entityAspect.entityState.isDeleted()) {
                return item[key] === value || item.entityAspect.originalValues[key] === value;
            }

            return item[key] === value;
        });
    }

    discardChanges(): void {
        // TODO: implement discard
        throw new Error('Method not implemented.');
    }
}

<table class="table table-bordered bulk-edit-table">
    <thead>
        <tr>
            <ng-container *ngFor="let field of options?.fields">
                <th *ngIf="field.visible && !field.inactive && field.hideFromEditScreen !== true">
                    <!-- Label -->
                    <ng-container *ngIf="field.hideFromEditHeader">
                        <div class="d-flex justify-content-start">
                            {{field.label}}
                            <ng-container custom-side-button>
                                <ng-container *ngIf="field.customSideButtonTemplate">
                                    <ng-template [ngTemplateOutlet]="field.customSideButtonTemplate"
                                                 [ngTemplateOutletContext]="{
                                                item: field.__updateObject,
                                                field: field,
                                                section: BulkEditSection.EditHeader
                                            }">

                                    </ng-template>
                                </ng-container>
                            </ng-container>
                        </div>
                    </ng-container>

                    <bulk-edit-header *ngIf="field.hideFromEditHeader !== true"
                                      #bulkEditHeader
                                      [bulkEditCustomButtons]="bulkEditCustomButtons"
                                      [options]="options"
                                      [headerText]="field.label"
                                      [field]="field.modelPath"
                                      [tooltip]="field.labelTooltip"
                                      [showExtraMenuItems]="field.extraMenuItemsTemplate"
                                      [hideBulkEditMenuOption]="field.extraMenuItemsTemplate"
                                      [skipDropdown]="field.skipDropdown"
                                      (updateClicked)="updateClicked(field)"
                                      (updateCustomButtonClicked)="updateCustomButtonClicked.emit($event)"
                                      (caretClicked)="field.caretClicked && field.caretClicked()">
                        <!-- TODO: not sure how to trigger modal to open from extra menu item -->
                        <ng-container extra-menu-items>
                            <ng-container *ngIf="field.extraMenuItemsTemplate">
                                <ng-template [ngTemplateOutlet]="field.extraMenuItemsTemplate"
                                             [ngTemplateOutletContext]="{
                                        bulkEditHeaderComponent: bulkEditHeader,
                                        item: field.__updateObject,
                                        field: field,
                                        section: BulkEditSection.EditHeader
                                    }"></ng-template>
                            </ng-container>
                        </ng-container>
                        <div modal-content>
                            <ng-container *ngIf="field.template">
                                <ng-template [ngTemplateOutlet]="field.template"
                                             [ngTemplateOutletContext]="{
                                        item: field.__updateObject,
                                        field: field,
                                        section: BulkEditSection.EditHeader
                                    }"></ng-template>
                            </ng-container>
                        </div>
                    </bulk-edit-header>

                    <!-- Optional sub-label -->
                    <div class="th-qualifier"
                         *ngIf="field.subLabel">
                        {{field.subLabel}}
                    </div>
                </th>
            </ng-container>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let item of itemsToEdit; let i = index;">
            <ng-container *ngFor="let field of options?.fields">
                <td *ngIf="field.visible && !field.inactive && field.hideFromEditScreen !== true">
                    <ng-container *ngIf="field.template">
                        <ng-template [ngTemplateOutlet]="field.template"
                                     [ngTemplateOutletContext]="{
                                        item: item,
                                        field: field,
                                        section: BulkEditSection.InputCell
                                    }"></ng-template>
                    </ng-container>
                </td>
            </ng-container>
        </tr>
    </tbody>
</table>


import { AdminManagerService } from './../services/admin-manager.service';
import { DataManagerService } from './../services/data-manager.service';
import {
    Component,
    Input,
    OnInit,
    Output,
    EventEmitter
} from '@angular/core';

import { LoggingService } from '../services/logging.service';
import { MessageService } from './message.service';
import { RoleService } from '../services/role.service';

import {
    Message,
    RecipientType
} from './models';

import {
    notEmpty,
    randomId,
} from '../common/util';

@Component({
    selector: 'new-message',
    templateUrl: './new-message.component.html'
})
export class NewMessageComponent implements OnInit {
    @Input() defaultValues: any;
    @Output() save: EventEmitter<void> = new EventEmitter<void>();
    @Output() cancel: EventEmitter<void> = new EventEmitter<void>();

    message: Message;

    // state
    domIdAddition: string;
    sending: boolean;

    readonly COMPONENT_LOG_TAG = 'new-message';


    constructor(
        private loggingService: LoggingService,
        private messageService: MessageService,
        private roleService: RoleService,
        private adminManager: AdminManagerService,
        private dataManager: DataManagerService
    ) { }

    ngOnInit() {
        this.domIdAddition = randomId();
        this.sending = false;

        if (!this.defaultValues) {
            this.defaultValues = {};
        }

        this.message = {
            recipients: this.defaultValues.recipients || [],
            messageText: this.defaultValues.messageText || '',
            messageSubject: this.defaultValues.messageSubject || '',
            messageMaps: this.defaultValues.messageMaps || []
        };
    }


    // Form
    sendDisabled(): boolean {
        return this.sending || !this.formValid();
    }

    formValid(): boolean {
        return notEmpty(this.message.recipients) &&
            this.message.messageSubject.length > 0 &&
            this.message.messageText.length > 0;
    }

    cancelClicked(): void {
        this.cancel.emit();
    }

    sendClicked(): void {
        this.saveMessage().then(() => {
            this.save.emit();
        });
    }


    // Sending
    saveMessage(): Promise<any> {
        this.sending = true;

        return this.sendNewMessages().then((newMessages) => {
            if (this.message.messageMaps) {
                return this.saveMessageMaps(newMessages, this.message.messageMaps);
            }
            return Promise.resolve();
        }).then(() => {
            this.showSuccessMessage();
            this.sending = false;
        }).catch((error) => {
            console.error(error);
            this.sending = false;
        });
    }

    private showSuccessMessage() {
        this.loggingService.logSuccess(
            "Message sent",
            null,
            this.COMPONENT_LOG_TAG,
            true
        );
    }


    /**
     * Sends all new messages
     * (1 per recipient)
     */
    sendNewMessages(): Promise<any> {
        const promises: any[] = [];
        let newMessages: any[] = [];

        for (const recipient of this.message.recipients) {
            // They selected a role from the list, gather all users in the role
            let promise;
            if (recipient.type === RecipientType.Role) {
                promise = this.roleService.getUsersInRole(recipient.recipientKey)
                    .then((users: any[]) => {
                        const messages = [];
                        for (const user of users) {
                            messages.push(this.messageService.createMessage(
                                user.C_User_key,
                                this.message.messageSubject,
                                this.message.messageText
                            ));
                        }

                        newMessages = newMessages.concat(messages);
                        return messages;
                    });
            } else {
                // Send to individual user
                const newMessage = this.messageService.createMessage(
                    recipient.recipientKey,
                    this.message.messageSubject,
                    this.message.messageText
                );
                newMessages.push(newMessage);
                promise = Promise.resolve([newMessage]);
            }

            promises.push(promise);

        }

        return Promise.all(promises).then(() => {
            return this.saveMessageEntities();
        }).then(() => {
            // we want to return the actual Message entities after Breeze saved them
            return newMessages;
        });
    }

    saveMessageMaps(messages: any[], messageMaps: any[]): Promise<any> {
        for (const message of messages) {
            for (const mapping of messageMaps) {
                const jobKey = mapping.Job ? mapping.Job.C_Job_key : null;
                const materialKey = mapping.Material ? mapping.Material.C_Material_key : null;
                const genotypeKey = mapping.Genotype ? mapping.Genotype.C_Genotype_key : null;
                const lineKey = mapping.Line ? mapping.Line.C_Line_key : null;
                const taskKey = mapping.TaskInstance ?
                    mapping.TaskInstance.C_TaskInstance_key : null;
                const initialValues = {
                    C_Message_key: message.C_Message_key,
                    C_Job_key: jobKey,
                    C_Material_key: materialKey,
                    C_Genotype_key: genotypeKey,
                    C_Line_key: lineKey,
                    C_TaskInstance_key: taskKey
                };
                // create 'MessageMap' entities
                this.messageService.createMessageMap(initialValues);
            }
        }

        return this.dataManager.saveEntity('MessageMap');
    }

    saveMessageEntities(): Promise<any> {
        return this.adminManager.saveEntity('Message');
    }
}

import { 
  Directive, 
  ElementRef,
  AfterViewInit,
} from '@angular/core';

@Directive({ selector: '[climbTitle]' })
export class TitleDirective implements AfterViewInit {
  constructor(
      private elRef: ElementRef<HTMLElement>
  ) { }

  ngAfterViewInit(): void {
    const element = this.elRef.nativeElement;
    if (element.offsetWidth < element.scrollWidth) {
      element.title = element.innerText;
    }
  }
}
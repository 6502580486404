/**
 * Sorts an object array on a property
 * @param array
 * @param sortPropertyKey
 * @param descending
 */
export function sortObjectArrayByProperty(
    array: any[],
    sortPropertyKey: string,
    descending = false
): any[] {
    return sortObjectArrayByProperties(
        array, [{
            key: sortPropertyKey,
            descending
        }]);
}

export function sortObjectArrayByProperties(
    array: any[],
    sortProperties: SortPropertyDef[]
): any[] {
    if (!sortProperties) {
        return array;
    }
    const sortDefs: SortArrayDef[] = sortProperties.map((prop) => {
        return {
            sortAccessor: (item: any) => {
                return item[prop.key];
            },
            descending: prop.descending
        };
    });
    return sortObjectArrayByAccessors(array, sortDefs);
}

/**
 * Sorts an object array on the result of an accessor function
 * @param array
 * @param sortAccessor
 * @param descending
 */
export function sortObjectArrayByAccessor(
    array: any[],
    sortAccessor: (x: any) => any,
    descending = false,
    naturalSort = false
): any[] {
    if (array && sortAccessor) {
        return sortObjectArrayByAccessors(array, [{
            sortAccessor, descending
        }], naturalSort);
    }

    return array;
}

export function sortObjectArrayByAccessors(
    array: any[],
    sortDefs: SortArrayDef[],
    naturalSort = false
): any[] {
    if (array && sortDefs) {
        array.sort((a: any, b: any) => {
            let cmp = 0;

            for (const sortDef of sortDefs) {
                cmp = cmp || _accessorCompare(
                    a, b,
                    sortDef.sortAccessor,
                    sortDef.descending === true || false,
                    naturalSort
                );

                // stop loop when we reach non-equal comparison
                if (cmp !== 0) {
                    break;
                }
            }

            return cmp;
        });
    }
    return array;
}

function _accessorCompare(
    a: any, 
    b: any, 
    accessor: (x: any) => any,
    descending: boolean,
    naturalSort = false
): number {
    const aVal: any = accessor(a);
    const bVal: any = accessor(b);
    const aValNull = aVal === null || aVal === undefined;
    const bValNull = bVal === null || bVal === undefined;

    let cmp = 0;
    
    if (aValNull || bValNull) {

        // nulls sort last
        if (!aValNull && bValNull) {
            cmp = -1;
        } else if (aValNull && !bValNull) {
            cmp = 1;
        }

    } else {
        if (naturalSort) {
            cmp = aVal.localeCompare(bVal, undefined, { numeric: true, sensitivity: 'base' });
        } else {
            if (aVal < bVal) {
                cmp = -1;
            } else if (aVal > bVal) {
                cmp = 1;
            }
        }
        
    }

    if (descending) {
        cmp = -cmp;
    }

    return cmp;
}

export class SortArrayDef {
    sortAccessor: (x: any) => any;
    descending?: boolean;
}

export class SortPropertyDef {
    key: string;
    descending?: boolean;
}

import { 
    ColorBoxCellRendererComponent 
} from '../common/colorbox/color-box-cell-renderer.component';
import {
    CellFormatterService,
    END_STATE_CLASS,
    TableOptions
} from '../common/datatable';
import { addAuditColumnsToOptions } from '@common/datatable/utils';

export class ResourceTableOptions {

    options: TableOptions;

    constructor(
        private cellFormatterService: CellFormatterService
    ) {
        this.options = addAuditColumnsToOptions(cellFormatterService)(this.getDefaultOptions());
    }

    getDefaultOptions(): TableOptions {
        return {
            csvFileName: 'resources.csv',
            enableDetailColumn: true,
            enableDraggable: false,
            enableSelectable: false,
            rowClass: (params: any) => {
                const row = params.data;
                const classes: string[] = [];
                if (row && row.IsActive !== true) {
                    classes.push(END_STATE_CLASS);
                }
                return classes;
            },
            columns: [
                {
                    displayName: 'Name', field: 'ResourceName',
                },
                {
                    displayName: 'Type', field: 'cv_ResourceType.ResourceType'
                },
                {
                    displayName: 'Background Color',
                    field: 'BackgroundColor',
                    visible: false,
                    sortable: false,
                    exportable: false,
                    rendererComponent: <new () => ColorBoxCellRendererComponent>
                    ColorBoxCellRendererComponent
                },
                {
                    displayName: 'Foreground Color',
                    field: 'ForegroundColor',
                    visible: false,
                    sortable: false,
                    exportable: false,
                    rendererComponent: <new () => ColorBoxCellRendererComponent>
                    ColorBoxCellRendererComponent
                },
                {
                    displayName: 'Active', field: 'IsActive',
                    formatter: this.cellFormatterService.booleanFormatter,
                    exportFormatter: this.cellFormatterService.booleanExportFormatter,
                    maxWidth: 80
                },
                {
                    displayName: 'Email Address', field: 'EmailAddress'
                },
                {
                    displayName: 'Resource Group', field: 'cv_ResourceGroup.ResourceGroup',
                    visible: false
                }
            ]
        };
    }
}

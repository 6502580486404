const CURRENT_SELECTOR = 'jobs-gantt-chart-renderer';
const SHOW_TOAST = true;

import {
    Component,
    Input,
    OnInit,
    ViewChild
} from '@angular/core';

import { JobService } from '../../jobs/job.service';
import { LoggingService } from '../../services/logging.service';
import { TranslationService } from '../../services/translation.service';

import { jsPDF } from 'jspdf';
import { exportGantt as exportGanttToPdf } from 'devextreme/pdf_exporter';
import { DxGanttComponent } from 'devextreme-angular';

import 'jspdf-autotable';


@Component({
    selector: CURRENT_SELECTOR,
    templateUrl: './jobs-gantt-chart-renderer.component.html',
    styles: [`
        .help-container {
          font-size: 13px;
          margin-bottom: 3px;
        }
        .legend-item {
            margin-top: 16px;
            margin-right: 5px;
            display: inline-flex;
        }
        .legend-box {
            height: 16px;
            width: 16px;
            border-radius: 7px;
            border: 1px solid black;
            margin-right: 5px;
        }
        .task-tooltip {
            background-color: white;
            color: black;
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
            padding: 10px 12px 12px 12px;
            border-radius: 3px;
        }
        .tooltip-title {
            font-weight: bold;
        }
    `]
})
export class JobsGanttChartRendererComponent implements OnInit {
    @ViewChild(DxGanttComponent) gantt: DxGanttComponent;

    @Input() filter: any;

    @Input() numJobs: number;
    @Input() showCurrentTime: boolean;

    // State
    loading: boolean;
    rendered: boolean;
    errorMessage: string;
    warningMessage: string;

    exportButtonOptions: any;

    jobsData: any[];
    studyNames: string[];
    // since extra values are not tracked on DevExtreme's Gantt Chart tasks, this will allow us to access job information from a Tasks "title" value.
    jobTaskMap: any = {};
    scaleType: string;

    constructor(
        private loggingService: LoggingService,
        private jobService: JobService,
        private translationService: TranslationService,

    ) {
        // Nothing to do
    }

    ngOnInit() {
        this.resetState();
        this.exportButtonOptions = {
            hint: 'Export to PDF',
            icon: 'exportpdf',
            stylingMode: 'text',
            onClick: () => this.exportButtonClick(),
        };
    }

    resetState(): void {
        this.loading = false;
        this.rendered = false;
        this.errorMessage = '';
        this.warningMessage = '';
        this.scaleType = 'months';
    }

    changeScale(scale: string) {
        this.scaleType = scale;
    }
    createChart(): void {
        this.resetState();

        this.loading = true;

        this.getData().then((jobs: any) => {
            this.loading = false;
            if (jobs.length === 0) {
                this.warningMessage
                    = this.translationService.translateMessage('There are no matching jobs');

                // Clear the data on the chart, showing the "No Data" screen on the Gantt Chart.
                this.jobsData = [];
                return;
            }
            this.jobsData = jobs;
            this.rendered = true;
        });
    }

    exportButtonClick(): void {
        const gantt = this.gantt.instance;
        const format = 'a0';
        const isLandscape = true;
        const exportMode = 'all';

        exportGanttToPdf(
            {
                component: gantt,
                createDocumentMethod: (args?: any) => new jsPDF(args),
                format,
                landscape: isLandscape,
                exportMode,
                dateRange: 'all',
            },
        ).then((doc) => doc.save('gantt.pdf'));
    }

    getData(): Promise<any[]> {
        this.errorMessage = '';
        this.warningMessage = '';

        // Copy the filter, just in case
        const filter = { ...this.filter };
        if (!filter.DateStartedStart && !filter.DateStartedEnd) {
            // Jobs must have a start date, so fake a start date filter
            filter.DateStartedStart = new Date(0, 0, 0);
        }
        const now = new Date();

        // Fetch a page of matching jobs in order of their start/end dates
        return this.jobService.getJobsWithAllDependencies({
            page: 0,
            size: Number(this.numJobs),
            sort: 'DateStarted ASC, DateEnded ASC',
            filter,
        }).then((response: any) => {
            this.studyNames = this.isolateStudyNames(response.results);
            return response.results.map((job: any) => {
                // Repackage the job for the chart, according to the DevExtreme Gantt "Task" definition.
                const item: any = {
                    id: job.C_Job_key,
                    title: job.JobID,
                    start: new Date(job.DateStarted),
                    taskColor: job.Study ? this.deriveColor(job.Study.StudyName) : '#495461',
                    extraValue: "EXTRA"
                };

                this.jobTaskMap[job.JobID] = job;
                // Deal with possibly missing DateEnded
                if (job.DateEnded) {
                    // Use the actual end date. Yeah!
                    item.end = new Date(job.DateEnded);
                } else if ((job.DateStarted < now) && !job.cv_JobStatus.IsEndState) {
                    // Job has started and is still ongoing - use today as the end date
                    item.end = now;
                } else {
                    // Default to the start date
                    item.end = item.start;
                }
                return item;
            });
        }).catch((error: Error) => {
            this.loading = false;
            this.displayLoadError();
            this.loggingService.logError(
                "Failed to load data for chart: " + error.message,
                null,
                CURRENT_SELECTOR,
                SHOW_TOAST
            );
        });
    }

    // takes the ASCII values of the study name and converts it into an HSL value.
    deriveColor(studyName: string): string {
        let hash = 0;
        for (let i = 0; i < studyName.length; i++) {
            // since we are actually using hash values here, bitwise operations are appropriate.
            // eslint-disable-next-line no-bitwise
            hash = studyName.charCodeAt(i) + ((hash << 5) - hash);
        }
        return `hsl(${hash % 360}, 100%, 25%)`;
    }

    isolateStudyNames(jobList: any[]): string[] {
        return jobList.map((job: any) => {
            if (job.Study) {
                return job.Study.StudyName;
            }
        }).filter((value: string, index: number, self: string[]) => {
            return value !== undefined && self.indexOf(value) === index;
        });
    }

    getDurationInDays(start: Date, end: Date) {
        const difference = end.getTime() - start.getTime();
        const millisPerDay = (1000 * 3600 * 24);
        return Math.floor(difference / millisPerDay).toLocaleString();
    }

    loadingMessage(): string {
        return "Loading";
    }

    displayLoadError(): void {
        this.errorMessage = "Failed to load data for chart. Please try again.";
    }

    onTaskDblClick(e: any): void {
        if (e.key !== 0) {
            e.cancel = true;
        }
    }
}

import {
    ChangeDetectorRef,
    Component,
    Input,
    OnDestroy,
    OnChanges,
    OnInit,
} from '@angular/core';

import { Subscription, defer, forkJoin } from 'rxjs';
import { DragulaOptions, DragulaService } from 'ng2-dragula';

import { LoggingService } from '../../services/logging.service';
import { ProtocolService } from '../protocol.service';
import { ProtocolVocabService } from '../protocol-vocab.service';
import { TaskService } from '../../tasks/task.service';
import {
    ViewAddProtocolTaskComponentService
} from '../view-add-protocol-task-component.service';
import {
    notEmpty,
    randomId,
} from '../../common/util';
import { DroppableEvent } from '../../common/droppable-event';
import { ScheduleType } from '../models';
import { DataContextService } from '../../services/data-context.service';
import { CreateSampleGroupsProtocolModalService } from '../modals';
import { FacetLoadingStateService } from '../../common/facet';
import { DataManagerService } from '../../services/data-manager.service';
import { LocationService } from '../../locations/location.service';
import { SaveChangesService } from '../../services/save-changes.service';
import { EntityState } from 'breeze-client';
import { DataType } from '../../data-type/data-type.enum';
import { FeatureFlagService } from '../../services/feature-flags.service';
import { take, tap } from 'rxjs/operators';

import { ToastrService } from '@services/toastr.service';
import { LogLevel } from '@services/models/log-level';
import { ProtocolTask } from '@common/types/models/protocol-task.interface';
import { IMultiStepSavingContext } from '@services/interfaces/multi-step-saving-context.interface';

@Component({
    selector: 'protocol-task-table',
    templateUrl: './protocol-task-table.component.html',
    styleUrls: ['./protocol-task-table.component.scss'],
    providers: [
        CreateSampleGroupsProtocolModalService
    ]
})
export class ProtocolTaskTableComponent implements OnChanges, OnDestroy, OnInit {
    @Input() isCRO: boolean;
    @Input() taskRowsOpen: boolean;
    @Input() protocol: any;
    @Input() protocolTasks: any[];
    @Input() protocolSection: any[];
    @Input() readonly: boolean;

    // Showing/Hiding the Default Inputs column
    inputsExpanded = false;

    // Showing/Hiding the Sample Groups column
    sampleGroupsExpanded = false;

    // Are all the rows selected?
    allSelected = false;
    // Are any rows selected?
    anySelected = false;
    customFrequencyKey: any = null;

    // CVs
    scheduleTypes: any[] = [];
    timeRelations: any[] = [];
    timeUnits: any[] = [];
    preservationMethods: any[];
    sampleStatuses: any[];
    sampleTypes: any[];
    containerTypes: any[];
    sampleSubtypes: any[];
    sampleAnalysisMethods: any[];
    sampleProcessingMethods: any[];

    // Bulk Update
    bulkTimeUnitKey: any;
    bulkTimeFromRelativeTask: any;
    bulkTimeRelationKey: any;
    bulkScheduleTypeKey: any;
    bulkRelativeProtocolTaskKey: any;
    bulkTimeIncremental: any = false;
    bulkTimeStartNumber: any = 0;
    bulkTimeIncrementNumber: any = 1;

    relativeScheduleTypeKeys: any = {};

    // Mapping of ProtocolTask/Input to InputDefault
    mapInputDefault: any = {};

    readonly COMPONENT_LOG_TAG = 'protocol-task-table';

    // export enum to template
    ScheduleType = ScheduleType;

    // Dragula 
    dragulaBagName: string;

    // Task Selectors
    tasksSelectorLabel: string;
    sectionTasksSelectorLabels: string[] = [];

    // All subscriptions
    subs = new Subscription();

    facetLoadingState: FacetLoadingStateService;
    loading = false;
    loadingMessage: string = null;

    // sizes of parent header for sample groups
    widths: any = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    sectionAllSelected: any[] = [];
    sectionMap: any;

    // units for mapping
    dayKey: any = 0;
    hourKey: any = 0;
    afterKey: any = 0;
    dueKey: any = 0;
    canRemoveTasks = true;

    frequencyKeyMap: any = {};
    taskGroups: any[] = [];

    // Feature flags
    isGLP = false;

    constructor(
        private dataManager: DataManagerService,
        private createSampleGroupsModalService: CreateSampleGroupsProtocolModalService,
        private dragulaService: DragulaService,
        private loggingService: LoggingService,
        private protocolService: ProtocolService,
        private protocolVocabService: ProtocolVocabService,
        private taskService: TaskService,
        private viewAddProtocolTaskComponentService: ViewAddProtocolTaskComponentService,
        private locationService: LocationService,
        private saveChangesService: SaveChangesService,
        private featureFlagService: FeatureFlagService,
        private ref: ChangeDetectorRef,
        private toastrService: ToastrService,
    ) {
        // do nothing
    }

    ngOnInit() {
        this.dragOnInit();
        this.initialize();
    }

    ngOnDestroy() {
        this.dragOnDestroy();
        // Clear all the subscriptions
        this.subs.unsubscribe();
    }

    ngOnChanges(changes: any) {
        if (changes.protocol) {
            if (!changes.protocol.firstChange) {
                this.initialize();
            }
        }
    }

    initialize() {
        this.initIsGLP();

        this.getCVs().then(() => {
            return this.initializeTasks();
        }).then(() => {
            this.getTasksSelectorConfig();
            for (const section of this.protocolSection) {
                this.getTasksSelectorConfig(section);
            }
        });
    }

    initIsGLP() {
        const flag = this.featureFlagService.getFlag("IsGLP");
        this.isGLP = (flag && flag.IsActive && flag.Value.toLowerCase() === "true");
    }

    get isDraggedProtocols(): boolean {
        return this.protocolService.draggedProtocols && this.protocolService.draggedProtocols.length > 0;
    }

    private getCVs(): Promise<any> {
        return forkJoin([
            this.protocolVocabService.scheduleTypes$.pipe(tap(data => {
                if (!data) {
                    data = [];
                }
                // only use Active types
                this.scheduleTypes = data.filter((item) => {
                    return item.IsActive;
                }),

                this.relativeScheduleTypeKeys = {};
                this.scheduleTypes.forEach((item) => {
                    this.relativeScheduleTypeKeys[item.C_ScheduleType_key] = (
                        (item.ScheduleType === ScheduleType.RelativeTaskDue) ||
                        (item.ScheduleType === ScheduleType.RelativeTaskComplete)
                    );
                    if (item.ScheduleType === ScheduleType.RelativeTaskDue) {
                        this.dueKey = item.C_ScheduleType_key;
                    }
                });
            })),

            this.protocolVocabService.timeRelations$.pipe(tap(data => {
                this.timeRelations = data;
                this.timeRelations.forEach((item: any) => {
                    if (item.TimeRelation === "After") {
                        this.afterKey = item.C_TimeRelation_key;
                    }
                });
            })),

            this.protocolVocabService.timeUnits$.pipe(tap(data => {
                this.timeUnits = data;
                this.timeUnits.forEach((item: any) => {
                    if (item.TimeUnit === "Hours") {
                        this.hourKey = item.C_TimeUnit_key;
                    } else if (item.TimeUnit === "Days") {
                        this.dayKey = item.C_TimeUnit_key;
                    }
                });
            })),

            this.protocolVocabService.preservationMethods$.pipe(tap(data => {
                this.preservationMethods = data;
            })),

            this.protocolVocabService.sampleStatuses$.pipe(tap(data => {
                this.sampleStatuses = data;
            })),

            this.protocolVocabService.sampleTypes$.pipe(tap(data => {
                this.sampleTypes = data;
            })),

            defer(() => this.locationService.getContainerTypes('Sample')).pipe(tap(data => {
                this.containerTypes = data;
            }), take(1)),

            this.protocolVocabService.sampleSubtypes$.pipe(tap(data => {
                this.sampleSubtypes = data;
            })),

            this.protocolVocabService.sampleProcessingMethods$.pipe(tap(data => {
                this.sampleProcessingMethods = data;
            })),

            this.protocolVocabService.sampleAnalysisMethods$.pipe(tap(data => {
                this.sampleAnalysisMethods = data;
            })),

            this.protocolVocabService.frequencies$.pipe(tap(data => {
                data.forEach((item: any) => {
                    this.frequencyKeyMap[item.C_Frequency_key] = item.Frequency;
                    if (item.Frequency === "Custom") {
                        this.customFrequencyKey = item.C_Frequency_key;
                    }
                });
            }))
        ]).toPromise();
        
    }

    private initializeTasks(initializeAliases = true): Promise<any> {
        if (!this.protocolTasks) {
            return Promise.resolve();
        }
        return this.protocolService.getProtocolTasks(this.protocol.C_Protocol_key).then(() => {
            this.protocolTasks.sort(this.sortBySortOrder);
            return this.initializeInputDefaults();
        }).then(() => {
            if (initializeAliases) {
                this.initializeTaskAliases();
            }
        }).then(() => {
            this.sectionMap = {};
            this.protocol.ChildProtocolTaskSection.forEach((section: any) => {
                section.ProtocolTask.sort(this.sortBySortOrder);
                section.editingName = false;
                this.sectionMap[section.C_ProtocolTaskSection_key] = section.SectionName;
                if (!this.dragulaService.find(this.dragulaBagName + section.SectionName)) {
                    this.setDragulaOptions(section.SectionName);
                }
            });
        }).then(() => {
            this.setBulkRemove();
        }).then(() => {
            if (this.isCRO) {
                // Find unique TaskGroup from all tasks
                this.taskGroups = [];
                const taskGroupKeys: any[] = [];
                const freeTaskGroups: any[] = [];
                const tasksWithTaskGroups = this.protocolTasks.filter((task) => task.C_TaskGroup_key !== null);
                tasksWithTaskGroups.forEach((task) => {
                    if (!taskGroupKeys.includes(task.TaskGroup.C_TaskGroup_key)) {
                        taskGroupKeys.push(task.TaskGroup.C_TaskGroup_key);
                        task.TaskGroup.Days = task.TaskGroup.Pattern.DaysPattern.split(',');
                        // Read first non zero day from the days array
                        task.TaskGroup.FirstDay = task.TaskGroup.Days.findIndex((day: any) => day !== '0');
                        task.TaskGroup.FirstDayTasks = task.TaskGroup.Days.find((day: any) => day !== '0');
                        freeTaskGroups.push(task.TaskGroup);
                    }
                });
                this.taskGroups.push(freeTaskGroups);

                this.protocol.ChildProtocolTaskSection.forEach((section: any) => {
                    const sectionTaskGroups: any[] = [];
                    const sectionTasksWithTaskGroups = section.ProtocolTask.filter((task: any) => task.C_TaskGroup_key !== null);
                    sectionTasksWithTaskGroups.forEach((task: any) => {
                        if (!taskGroupKeys.includes(task.TaskGroup.C_TaskGroup_key)) {
                            taskGroupKeys.push(task.TaskGroup.C_TaskGroup_key);
                            task.TaskGroup.Days = task.TaskGroup.Pattern.DaysPattern.split(',');
                            // Read first non zero day from the days array
                            task.TaskGroup.FirstDay = task.TaskGroup.Days.findIndex((day: any) => day !== '0');
                            task.TaskGroup.FirstDayTasks = task.TaskGroup.Days.find((day: any) => day !== '0');
                            task.TaskGroup.SectionName = section.SectionName;
                            sectionTaskGroups.push(task.TaskGroup);
                        }
                    });
                    this.taskGroups.push(sectionTaskGroups);
                });
            }
        });
    }

    isNotDeleted(protocolTask: any) {
        return protocolTask.entityAspect.entityState !== EntityState.Deleted;
    }

    setBulkRemove() {
        this.loading = true;
        const promises: any = [];
        this.protocolTasks
            .filter((protocolTask: any) => protocolTask.C_ProtocolTaskSection_key === null)
            .forEach((protocolTask) => {
                const promise = this.protocolService.isProtocolTaskSafeToDelete(protocolTask).then((canRemove) => {
                    protocolTask.notRemovable = !canRemove;
                    return canRemove;
                });
                promises.push(promise);
            });
        Promise.all(promises).then((isSafe) => {
            return isSafe.some((safe) => !!safe);
        }).then((canRemoveTasks) => {
            this.canRemoveTasks = canRemoveTasks;
            this.loading = false;
        });
        this.protocol.ChildProtocolTaskSection.forEach((section: any) => {
            const sectionPromises: any = [];
            section.ProtocolTask.forEach((protocolTask: any) => {
                const promise = this.protocolService.isProtocolTaskSafeToDelete(protocolTask).then((canRemove) => {
                    protocolTask.notRemovable = !canRemove;
                    return canRemove;
                });
                sectionPromises.push(promise);
            });
            Promise.all(sectionPromises).then((isSafe) => {
                return isSafe.some((safe) => !!safe);
            }).then((canRemoveTasks) => {
                section.canRemoveTasks = canRemoveTasks;
                this.loading = false;
            });
        });
    }

    async bulkRemoveProtocolTask(section?: any) {
        this.loading = true;
        const promises: any = [];
        const taskNames: any = [];
        (section ? section.ProtocolTask : this.protocolTasks)
            .filter((protocolTask: any) => section ? true : protocolTask.C_ProtocolTaskSection_key === null)
            .forEach((protocolTask: any) => {
                if (!protocolTask.isSelected) {
                    // Skip non-selected tasks
                    return;
                }
                promises.push(this.protocolService.isProtocolTaskSafeToDelete(protocolTask)
                    .then((isSafe: boolean) => {
                    if (isSafe) {
                        this.protocolService.deleteProtocolTask(protocolTask);
                        if (section) {
                            const index = section.ProtocolTask.indexOf(protocolTask);
                            if (index > -1) {
                                section.ProtocolTask.splice(index, 1);
                            }
                        } else {
                            const index = this.protocolTasks.indexOf(protocolTask);
                            if (index > -1) {
                                this.protocolTasks.splice(index, 1);
                            }
                        }
                    } else {
                        taskNames.push(protocolTask.WorkflowTask.TaskName);
                    }
                }));
            });
        await Promise.all(promises);
        this.loading = false;
        if (taskNames.length > 0) {
            const message = `Cannot delete task(s): ${taskNames.join(', ')}`;
            const showToast = true;
            this.loggingService.logWarning(
                message,
                null,
                this.COMPONENT_LOG_TAG,
                showToast
            );
            if (section) {
                section.canRemoveTasks = false;
            } else {
                this.canRemoveTasks = false;
            }
        }
        if (section) {
            this.getTasksSelectorConfig(section);
        } else {
            this.getTasksSelectorConfig();
        }
        jQuery('#tasksSelectorDropdown').trigger('click');
    }

    removeProtocolTask(protocolTask: any) {
        this.loading = true;
        return this.protocolService.isProtocolTaskSafeToDelete(protocolTask)
            .then((isSafe: boolean) => {
                if (isSafe) {
                    this.protocolService.deleteProtocolTask(protocolTask);
                    const index = this.protocolTasks.indexOf(protocolTask);
                    if (index > -1) {
                        this.protocolTasks.splice(index, 1);
                    }
                } else {
                    const message = 'Cannot delete task: it is associated with workflow data.';
                    const showToast = true;
                    this.loggingService.logWarning(
                        message,
                        null,
                        this.COMPONENT_LOG_TAG,
                        showToast
                    );
                }
                this.loading = false;
            });
    }

    removeProtocolSection(section: any) {
        this.loading = true;
        const promises: any[] = [];
        section.ProtocolTask.forEach((protocolTask: any) => {
            promises.push(this.protocolService.isProtocolTaskSafeToDelete(protocolTask)
                .then((isSafe: boolean) => {
                    if (isSafe) {
                        this.protocolService.deleteProtocolTask(protocolTask);
                    } else {
                        const message = 'Cannot delete task: it is associated with workflow data.';
                        const showToast = true;
                        this.loggingService.logWarning(
                            message,
                            null,
                            this.COMPONENT_LOG_TAG,
                            showToast
                        );
                    }
                }));
        });
        Promise.all(promises).then(() => {
            const protocolIndex = this.protocolSection.findIndex((i) => i.C_ProtocolTaskSection_key === section.C_ProtocolTaskSection_key);
            this.protocolSection.splice(protocolIndex, 1);
            if (section.ProtocolTask.length === 0) {
                if (this.dragulaService.find(this.dragulaBagName + section.SectionName)) {
                    this.dragulaService.destroy(this.dragulaBagName + section.SectionName);
                }
                this.protocolService.deleteProtocolSection(section);
            }
            this.loading = false;
        });
    }

    /**
     * Remove a SampleGroup from the Task
     */
    removeSampleGroup(sampleGroup: any): Promise<any> {
        // Remove the SampleGroup from the task
        this.dataManager.deleteEntity(sampleGroup);

        this.initialize();

        // Try to remove the Sample from the tasks as well
        return Promise.resolve();
    }

    /**
     * Setup for row dragging
     */
    private dragOnInit() {
        // Need a unique name for our table in the Dragule config
        this.dragulaBagName = randomId() + '-bag';
        this.setDragulaOptions();
        this.subs.add(this.dragulaService.dropModel().subscribe(({ el, targetModel, sourceIndex, targetIndex }) => {
            this.loading = true;
            // At this point we know which table row was dragged ...
            // ... but we need to know which Tasks was dragged.
            const draggedKey = parseInt((el as HTMLElement).dataset.key, 10);
            const draggedSection = parseInt((el as HTMLElement).dataset.section, 10);
            if (draggedSection) {
                const section = this.protocolSection.find((sect: any) => sect.C_ProtocolTaskSection_key === draggedSection);
                if (section) {
                    section.ProtocolTask.sort((a: any, b: any) => {
                        const aIndex = targetModel.findIndex((task: any) => task.C_ProtocolTask_key === a.C_ProtocolTask_key);
                        const bIndex = targetModel.findIndex((task: any) => task.C_ProtocolTask_key === b.C_ProtocolTask_key);
                        return aIndex - bIndex;
                    });
                    const selectedTasks = section.ProtocolTask.filter((task: any) => {
                        return task.isSelected || (task.C_ProtocolTask_key === draggedKey);
                    });
                    // if multiple tasks are dragged
                    if (selectedTasks.length > 1) {
                        // Here's the deal...
                        // At this point the row that was dragged is in the correct
                        // position in the protocolTasks array. However, the other
                        // selected rows have not been moved. We are going to rebuild the
                        // array and insert all the selected rows when we find the one row
                        // that was moved.

                        // sort the selected task to original order
                        const tasks: any[] = [];
                        selectedTasks.sort(this.sortBySortOrder);
                        section.ProtocolTask.forEach((task: any) => {
                            if (task.C_ProtocolTask_key === draggedKey) {
                                // Push on all the dragged tasks at the same time
                                tasks.push(...selectedTasks);
                            } else if (!task.isSelected) {
                                // Not dragged
                                tasks.push(task);
                            }
                        });
                        for (let i = 0; i < tasks.length; i++) {
                            const index = section.ProtocolTask.findIndex((task: any) => task.C_ProtocolTask_key === tasks[i].C_ProtocolTask_key);
                            section.ProtocolTask[index].SortOrder = i + 1;
                        }
                    } else {
                        for (let i = 0; i < section.ProtocolTask.length; i++) {
                            section.ProtocolTask[i].SortOrder = i + 1;
                        }
                    }
                    this.unSelectAll(section);
                }
            } else {
                this.protocolTasks = targetModel;
                const selectedTasks = this.protocolTasks.filter((task: any) => {
                    return task.isSelected || (task.C_ProtocolTask_key === draggedKey);
                });
                // if multiple tasks are dragged
                if (selectedTasks.length > 1) {
                    // Here's the deal...
                    // At this point the row that was dragged is in the correct
                    // position in the protocolTasks array. However, the other
                    // selected rows have not been moved. We are going to rebuild the
                    // array and insert all the selected rows when we find the one row
                    // that was moved.

                    // sort the selected task to original order
                    const tasks: any[] = [];
                    selectedTasks.sort(this.sortBySortOrder);
                    this.protocolTasks.forEach((task: any) => {
                        if (task.C_ProtocolTask_key === draggedKey) {
                            // Push on all the dragged tasks at the same time
                            tasks.push(...selectedTasks);
                        } else if (!task.isSelected) {
                            // Not dragged
                            tasks.push(task);
                        }
                    });
                    // Use the updated array
                    for (let i = 0; i < tasks.length; i++) {
                        const index = this.protocol.ProtocolTask.findIndex((task: any) => task.C_ProtocolTask_key === tasks[i].C_ProtocolTask_key);
                        this.protocol.ProtocolTask[index].SortOrder = i + 1;
                    }
                } else {
                    for (let i = 0; i < this.protocolTasks.length; i++) {
                        this.protocolTasks[i].SortOrder = i + 1;
                    }
                }
                this.unSelectAll();
            }
            setTimeout(() => {
                this.loading = false;
            }, 1000);
        }));

        // Register a callback to update the drag helper element
        this.subs.add(this.dragulaService.cloned().subscribe(({ clone }) => {
            // Update the mirror (aka drag helper);
            this.dragUpdateHelper(clone as HTMLElement);
        }));
    }

    private setDragulaOptions(section = "") {
        // Dragula options
        const opts: DragulaOptions<any> = {};

        // Only allow dragging by the drag handle
        opts.moves = (el: Element, source: Element, handle: Element, sibling: Element) => {
            return handle.classList.contains('draggable');
        };
        // Configure the service for this table
        this.dragulaService.createGroup(this.dragulaBagName + section, opts);
    }

    /**
     * Remove Dragula configuration and listeners
     */
    private dragOnDestroy() {
        if (this.dragulaService.find(this.dragulaBagName)) {
            this.dragulaService.destroy(this.dragulaBagName);
        }
    }

    /**
     * Update the Dragula mirror element (aka drag helper) that is displayed
     * under the cursor while dragging rows.
     * 
     * By default, the dragged element is cloned. Instead, we want to display
     * some information about the selected rows.
     * 
     * @param mirror DOM element displayed under the cursor
     */
    private dragUpdateHelper(mirror: HTMLElement) {
        // Figure out which row was dragged
        const draggedKey: number = parseInt(mirror.dataset.key, 10);

        //  Get the names of all the tasks to be dragged
        const names = this.protocol.ProtocolTask.filter((task: any) => {
            return task.C_ProtocolTask_key === draggedKey || task.isSelected;
        }).map((task: any) => {
            return task.WorkflowTask ? task.WorkflowTask.TaskName : 'Draging Task';
        });

        let text = '';
        text = names.join('<br>');

        // Swap out the contents of the mirror element
        mirror.innerHTML = `<div class="dragula-helper">${text}</div>`;
    }


    addTasksViaModal(section: any) {
        this.viewAddProtocolTaskComponentService.openComponent(this.isCRO)
            .then((newTasks) => {
                if (newTasks && newTasks.length > 0) {
                    this.loading = true;
                    if (section) {
                        newTasks.forEach((task: any) => {
                            task.C_ProtocolTaskSection_key = section.C_ProtocolTaskSection_key;
                            task.SortOrder = section.ProtocolTask.length + 1;
                        });
                    }
                    this.addProtocolTasks(newTasks, section ? true : false).then(() => {
                        this.loading = false;
                    }).catch(() => {
                        this.loading = false;
                    });
                }
            });
    }

    addSectionViaModal() {
        this.viewAddProtocolTaskComponentService.openSectionComponent(this.protocolSection, this.isCRO)
            .then((result: any) => {
                if (result.tasks && result.section.length > 0) {
                    this.loading = true;
                    this.addProtocolTasksWithSection(result.tasks, result.section).then(() => {
                        this.loading = false;
                    }).catch(() => {
                        this.loading = false;
                    });
                }
            });
    }

    async onDropTaskToProtocol(event: DroppableEvent, section: any): Promise<void> {
        if (notEmpty(this.taskService.draggedTasks)) {
            const newTasks = this.taskService.draggedTasks.map((workflowTask: any) => {
                return {
                    C_WorkflowTask_key: workflowTask.C_WorkflowTask_key,
                    C_ProtocolTaskSection_key: section ? section.C_ProtocolTaskSection_key : null,
                    SortOrder: section ? section.ProtocolTask.length + 1 : 0,
                };
            });

            this.taskService.draggedTasks = [];

            await this.addProtocolTasks(newTasks, section ? true : null);
        }
        if (notEmpty(this.protocolService.draggedProtocols) && !section) {
            this.loading = true;
            let hadProtocolWithEmptyTasks = false;
            let multiStepSavingContext: IMultiStepSavingContext = null;
            try {
                for (const protocol of this.protocolService.draggedProtocols) {
                    if (protocol.ProtocolName && protocol.ProtocolName.length > 0) {
                        const name = protocol.ProtocolName;
                        let finalName = name;
                        const names = this.protocolSection.map((protocolSection: any) => protocolSection.SectionName);
                        let index = 1;
                        while (names.includes(finalName)) {
                            index = index + 1;
                            finalName = name + ` (${index})`;
                        }
                        const tasks = protocol.ProtocolTask.
                        filter((task: any) => task.C_ProtocolTaskSection_key === null)
                            .map((task: any) => ({
                            C_WorkflowTask_key: task.C_WorkflowTask_key,
                            C_Frecuency_key: task.C_Frecuency_key,
                            C_TaskFrequency_key: task.C_TaskFrequency_key,
                            C_TimeRelation_key: task.C_TimeRelation_key,
                            C_TimeUnit_key: task.C_TimeUnit_key,
                            C_ScheduleType_key: task.C_ScheduleType_key,
                            TimeDifference: task.TimeDifference,
                            RelativeToStudyStart: task.RelativeToStudyStart,
                            TimeFromRelativeTask: task.TimeFromRelativeTask,
                            RelativeTaskKey: task.C_RelativeProtocolTask_key,
                            OldKey: task.C_ProtocolTask_key,
                            TaskAlias: task.TaskAlias,
                            SortOrder: task.SortOrder,
                            SampleGroup: task.SampleGroup.map((s: any) => ({
                                C_ContainerType_key: s.C_ContainerType_key,
                                C_PreservationMethod_key: s.C_PreservationMethod_key,
                                C_SampleType_key: s.C_SampleType_key,
                                C_SampleStatus_key: s.C_SampleStatus_key,
                                C_SampleSubtype_key: s.C_SampleSubtype_key,
                                C_SampleAnalysisMethod_key: s.C_SampleAnalysisMethod_key,
                                C_SampleProcessingMethod_key: s.C_SampleProcessingMethod_key,
                                C_TimeUnit_key: s.C_TimeUnit_key,
                                NumSamples: s.NumSamples,
                                SendTo: s.SendTo,
                                SpecialInstructions: s.SpecialInstructions
                            })),
                            InputDefault: task.InputDefault.map((t: any) => ({
                                C_EnumerationItem_key: t.C_EnumerationItem_key,
                                C_Input_key: t.C_Input_key,
                                InputValue: t.InputValue
                            })),
                            TaskGroup: task.TaskGroup
                        }));
                        if (tasks.length > 0) {
                            multiStepSavingContext = await this.addProtocolTasksWithSection(tasks,
                                finalName, 
                                multiStepSavingContext);
                        } else {
                            hadProtocolWithEmptyTasks = true;
                        }
                    }
                }
                this.loading = false;
                if (hadProtocolWithEmptyTasks) {
                    this.loggingService.logWarning(
                        "Only tasks not assigned to subsections can be added, please ensure there are free tasks in dragged protocols.",
                        null,
                        this.COMPONENT_LOG_TAG,
                        true
                    );
                }
            } catch (error) {
                this.loading = false;
                this.loggingService.logError(error, null, this.COMPONENT_LOG_TAG, true);
            }
        }
    }

    bulkTimeFromRelativeTaskChanged(section: any) {
        let tasks = this.protocolTasks;
        if (section) {
            tasks = section.ProtocolTask;
        }
        if (this.bulkTimeIncremental) {
            let start = this.bulkTimeStartNumber;
            const increment = this.bulkTimeIncrementNumber;
            if (start < 0 || !increment || increment < 1) {
                return;
            }
            const sortedTasks = [...tasks].sort(this.sortBySortOrder);
            for (const protocolTask of sortedTasks) {
                if (protocolTask.C_TaskGroup_key !== null) {
                    continue;
                }
                protocolTask.TimeFromRelativeTask = start;
                start += increment;
                this.updateTaskAlias(protocolTask);
            }
        } else {
            for (const protocolTask of tasks) {
                if (protocolTask.C_TaskGroup_key !== null) {
                    continue;
                }
                protocolTask.TimeFromRelativeTask = this.bulkTimeFromRelativeTask;
                this.updateTaskAlias(protocolTask);
            }
        }
    }

    bulkTimeUnitChanged(section: any) {
        let tasks = this.protocolTasks;
        if (section) {
            tasks = section.ProtocolTask;
        }
        for (const protocolTask of tasks) {
            if (protocolTask.C_TaskGroup_key !== null) {
                continue;
            } else {
                protocolTask.C_TimeUnit_key = this.bulkTimeUnitKey;
                this.updateTaskAlias(protocolTask);
            }
        }
    }

    bulkTimeRelationChanged(section: any) {
        let tasks = this.protocolTasks;
        if (section) {
            tasks = section.ProtocolTask;
        }
        for (const protocolTask of tasks) {
            if (protocolTask.C_TaskGroup_key !== null) {
                continue;
            } else {
                protocolTask.C_TimeRelation_key = this.bulkTimeRelationKey;
            }
            
        }
    }
    
    showCircularReferenceErrorToast(protocolTask: ProtocolTask): void {
        this.toastrService.showToast(`Task ${protocolTask.TaskAlias} causes a circular reference. Please change the dependency to correct the error.`, LogLevel.Error);
    }

    clearRelativeProtocolTaskKey(protocolTask: ProtocolTask): void {
        setTimeout(() => {
            protocolTask.C_RelativeProtocolTask_key = null;
        });
    }
    
    timeRelationChanged(protocolTask: ProtocolTask) {
        //if no protocol task is selected, return
        if (!protocolTask) {
            return;
        }

        const hasCircularReference: boolean = this.protocolService.detectCircularReference(protocolTask);

        if (hasCircularReference) {
            // Clear the selection to force the user to select another item
            this.clearRelativeProtocolTaskKey(protocolTask);
            //alert the user
            this.showCircularReferenceErrorToast(protocolTask);
        }

        return hasCircularReference;

    }

    bulkScheduleTypeChanged(section: any) {
        const bulkRelativeProtocolTaskKey = this.bulkRelativeProtocolTaskKey;
        const bulkScheduleTypeKey = this.bulkScheduleTypeKey;
        let tasks = this.protocolTasks;
        if (section) {
            tasks = section.ProtocolTask;
        }
        for (const protocolTask of tasks) {
            if (protocolTask.C_TaskGroup_key !== null) {
                continue;
            }
            if (protocolTask.RelativeToStudyStart) {
                // If its relative to study start, then we cannot set its schedule
                continue;
            }

            //check for circular reference, if found, undo the change, alert the user and break the loop
            if (this.protocolService.detectCircularReference(protocolTask)) {
                //call the time out function to clear the relative protocol task key
                this.clearRelativeProtocolTaskKey(protocolTask);
                //alert the user
                this.showCircularReferenceErrorToast(protocolTask);
                break;
            }

            if (this.isRelativeScheduleType(bulkScheduleTypeKey)) {
                // Update the type and relative task if it's not the relative task row
                if (protocolTask.C_ProtocolTask_key !== bulkRelativeProtocolTaskKey) {
                    protocolTask.C_RelativeProtocolTask_key = bulkRelativeProtocolTaskKey;
                    protocolTask.C_ScheduleType_key = bulkScheduleTypeKey;
                }
            } else {
                // Only update the type, no relative task
                protocolTask.C_ScheduleType_key = bulkScheduleTypeKey;
            }
        }
    }

    isBulkRelativeTaskType(scheduleTypeKey: number): boolean {
        const scheduleType = this.scheduleTypes.find((item) => {
            return item.C_ScheduleType_key === scheduleTypeKey;
        });
        if (!scheduleType) {
            return false;
        }

        return (scheduleType.ScheduleType === ScheduleType.RelativeTaskDue) ||
            (scheduleType.ScheduleType === ScheduleType.RelativeTaskComplete);
    }

    isRelativeScheduleType(scheduleTypeKey: number): boolean {
        return this.relativeScheduleTypeKeys[scheduleTypeKey] || false;
    }

    /**
     * Respond to a change in the ProtocolTask's TimeFromRelativeTask
     * 
     * - Update the default TaskAlias
     * 
     * @param protocolTask
     */
    timeChanged(protocolTask: any) {
        this.updateTaskAlias(protocolTask);
        for (const sampleGroup of protocolTask.SampleGroup) {
            sampleGroup.TimePoint = protocolTask.TimeFromRelativeTask;
        }
    }

    /**
     * Respond to a change in the ProtocolTask's TimeUnit
     *
     * - Update the default TaskAlias
     * 
     * @param protocolTask
     */
    timeUnitChanged(protocolTask: any) {
        this.updateTaskAlias(protocolTask);
        for (const sampleGroup of protocolTask.SampleGroup) {
            sampleGroup.C_TimeUnit_key = protocolTask.C_TimeUnit_key;
        }
    }

    /**
     * Respond to a change in the ProtocolTask's _aliasChanged ("Customize Task Name") checkbox
     *
     * - Restore the default TaskAlias when unchecked.
     */
    taskAliasChanged(protocolTask: any) {
        if (!protocolTask._aliasChanged) {
            // Not customizing, so restore the default alias.
            if (protocolTask.C_TaskGroup_key === null) {
                protocolTask.TaskAlias = this.buildTaskAlias(protocolTask);
            }
        }
    }

    /**
     * Check if the ProtocolTask TaskAlias values have been customized 
     */
    initializeTaskAliases() {
        for (const protocolTask of this.protocolTasks) {
            if (protocolTask.TaskAlias !== null) {
                // Compare the current alias with the default alias
                const defaultAlias = this.buildTaskAlias(protocolTask);
                protocolTask._aliasChanged = (defaultAlias !== protocolTask.TaskAlias);
            } else {
                // Nothing set yet, so assume the default
                protocolTask._aliasChanged = false;
            }
        }

        this.protocolSection.forEach((section) => {
            section.ProtocolTask.forEach((protocolTask: any) => {
                if (protocolTask.TaskAlias !== null) {
                    // Compare the current alias with the default alias
                    const defaultAlias = this.buildTaskAlias(protocolTask);
                    protocolTask._aliasChanged = (defaultAlias !== protocolTask.TaskAlias);
                } else {
                    // Nothing set yet, so assume the default
                    protocolTask._aliasChanged = false;
                }
            });
        });
    }

    /**
     * Update a ProtocoTask's TaskAlias based on the related inputs.
     * 
     * @param protocolTask
     */
    updateTaskAlias(protocolTask: any) {
        if (protocolTask._aliasChanged) {
            // Using a custom alias, so do nothing here.
            return;
        }

        // Rebuild and update the alias
        protocolTask.TaskAlias = this.buildTaskAlias(protocolTask);
    }

    // Map of plural TimeUnits to their singular version
    readonly TIME_UNIT_MAP: any = {
        Minutes: 'Minute',
        Hours: 'Hour',
        Days: 'Day',
        Weeks: 'Week',
    };

    /**
     * Build the default TaskAlias from the WorkflowTask and relative time settings and day of study
     * 
     * @param protocolTask
     * @param day
     */
    buildTaskAliasStudyTimingWise(protocolTask: any, day: any, index: any): string {
        if (!protocolTask || !protocolTask.WorkflowTask) {
            return "";
        }

        // The TaskAlias is formatted as "TIME UNIT TASK", e.g. "2 Day Weigh"
        // Since some values won't be set at first, let's assemble what we do have
        const tokens: string[] = [];

        // Add day
        this.isGLP ? tokens.push(`Day ${day + 1}`) : tokens.push(`Day ${day}`);

        // UNIT
        if (protocolTask.cv_TimeUnit && this.TIME_UNIT_MAP[protocolTask.cv_TimeUnit.TimeUnit] === 'Hour' && protocolTask.TimeFromRelativeTask) {
            tokens.push(`${protocolTask.TimeFromRelativeTask * index} hr`);
        }

        // TASK
        tokens.push(protocolTask.WorkflowTask.TaskName);

        // Join it all together
        return tokens.join(' ');
    }

    /**
     * Build the default TaskAlias from the WorkflowTask and relative time settings.
     * 
     * @param protocolTask
     */
    buildTaskAlias(protocolTask: any): string {
        if (!protocolTask || !protocolTask.WorkflowTask) {
            return "";
        }

        // The TaskAlias is formatted as "TIME UNIT TASK", e.g. "2 Day Weigh"
        // Since some values won't be set at first, let's assemble what we do have
        const tokens: string[] = [];

        // TIME
        if (protocolTask.TimeFromRelativeTask) {
            tokens.push(protocolTask.TimeFromRelativeTask);
        }

        // UNIT
        if (protocolTask.cv_TimeUnit) {
            // Try to convert the unit to singular
            let timeUnit = protocolTask.cv_TimeUnit.TimeUnit;
            timeUnit = this.TIME_UNIT_MAP[timeUnit] || timeUnit;

            tokens.push(timeUnit);
        }

        // TASK
        tokens.push(protocolTask.WorkflowTask.TaskName);

        // Join it all together
        return tokens.join(' ');
    }

    isProtocolNameInvalid(operation: string): boolean {
        if (!this.protocolService.isNameValid(this.protocol)) {
            this.loading = false;
            const errMsg = operation + " failed: " + this.protocolService.ERROR_MSG_INVALID_NAME;
            this.loggingService.logError(errMsg, "Validation Error", this.COMPONENT_LOG_TAG, true);
            return true;
        } else {
            return false;
        }
    }
    private addProtocolTasks(workflowTasks: any[], section = false): Promise<any> {
        const promises: Promise<any>[] = [];
        this.loading = true;
        const taskMapping: any = {};
        const protocolKey = this.protocol.C_Protocol_key;
        let hasDays = false;

        if (this.isProtocolNameInvalid("Task addition")) {
            return;
        }

        if (this.isCRO) {
            for (let i = 0; i < workflowTasks.length; i++) {
                const initialValues = workflowTasks[i];
                initialValues.C_Protocol_key = protocolKey;
                if (initialValues.hasOwnProperty("RelatedTo") && initialValues.hasOwnProperty("AnchorDay")) {
                    taskMapping[i] = {RelatedTo: initialValues.RelatedTo, Day: initialValues.AnchorDay};
                }
            }
            for (const initialValues of workflowTasks) {
                if (initialValues.hasOwnProperty("Days")) {
                    hasDays = true;
                    initialValues.tasks = Array(initialValues.Days.length);
                    for (let k = 0; k < initialValues.Days.length; k++) {
                        const day = initialValues.Days[k];
                        initialValues.tasks[k] = Array(day);
                        for (let j = 0; j < day; j++) {
                            promises.push(this._addProtocolTask(initialValues, section).then((task: any) => {
                                initialValues.tasks[k][j] = task;
                            }));
                        }
                    }
                } else {
                    initialValues.C_Protocol_key = protocolKey;
                    promises.push(this._addProtocolTask(initialValues, section));
                }
            }

        } else {
            // OLD Code
            for (const initialValues of workflowTasks) {
                initialValues.C_Protocol_key = protocolKey;
                promises.push(this._addProtocolTask(initialValues, section));
            }
        }
        return Promise.all(promises)
            .then(async () => {
                const multiStepSavingContext = await this.protocolService.saveInMultipleSteps(this.protocol, null);

                if (this.isCRO && hasDays) {
                    this.mapAndAssignDays(workflowTasks, taskMapping);
                }

                await this.initializeTasks(!(this.isCRO && hasDays));
                await this.protocolService.saveInMultipleSteps(this.protocol, multiStepSavingContext);
            })
            .then(() => {
                this.loading = false;
            })
            .catch((error) => {
                console.error(error);
                this.loading = false;
            });
    }

    private async addProtocolTasksWithSection(workflowTasks: any[], 
                                              section: string, 
                                              multiStepSavingContext: IMultiStepSavingContext = null): Promise<any> {
        const taskMapping: any = {};
        let hasDays = false;
        if (this.isProtocolNameInvalid("Protocol addition")) {
            return;
        }
        const createTasksPromises = await this._createProtocolSection(section, workflowTasks, taskMapping);
        const step1SavingContext = await this.protocolService.saveInMultipleSteps(this.protocol, 
            multiStepSavingContext);

        const newOldKeyMap = new Map();
        for (const initialValues of workflowTasks) {
            if (initialValues.hasOwnProperty("Days")) {
                hasDays = true;
            }
            if (initialValues.OldKey) {
                newOldKeyMap.set(initialValues.OldKey, initialValues.newTask.C_ProtocolTask_key);
            }
        }

        this.loading = true;
        try {
            await Promise.all(createTasksPromises);

            // Are these tasks coming from a protocol being added via the moal?
            const copiedProtocol = workflowTasks[0].OldKey ? true : false;
            // if the protocol was added via the moal, we need to imitate same relationships between tasks in the new section
            if (copiedProtocol) {
                const taskKeys = this.protocol.ProtocolTask.map((x: any) => x.C_ProtocolTask_key);
                for (const initialValues of workflowTasks) {
                    if (initialValues.RelativeTaskKey && newOldKeyMap.has(initialValues.RelativeTaskKey)) {
                        const key = newOldKeyMap.get(initialValues.RelativeTaskKey);
                        // See if there exists any task in protocol with this key
                        if (taskKeys.includes(key)) {
                            initialValues.newTask.C_RelativeProtocolTask_key = newOldKeyMap.get(initialValues.RelativeTaskKey);
                        }
                    }
                }
            }
            if (this.isCRO && !hasDays) {
                // update TaskAlias for new section
                this.protocol.ChildProtocolTaskSection[this.protocol.ChildProtocolTaskSection.length - 1].ProtocolTask.forEach((protocolTask: any) => {
                    if (!protocolTask.TaskAlias || protocolTask.TaskAlias === '') {
                        protocolTask.TaskAlias = this.buildTaskAlias(protocolTask);
                        protocolTask._aliasChanged = false;
                    }
                });
            }

            if (this.isCRO && hasDays) {
                this.mapAndAssignDays(workflowTasks, taskMapping);
            }

            await this.initializeTasks(!(this.isCRO && hasDays));
            const step2SavingContext = await this.protocolService.saveInMultipleSteps(this.protocol, step1SavingContext);
            this.loading = false;
            return step2SavingContext;
        } catch (error) {
            this.loading = false;
        }
    }

    private async _createProtocolSection(section: string, workflowTasks: any[], taskMapping: any): Promise<Promise<any>[]> {

        const protocolKey = this.protocol.C_Protocol_key;
        const promises: Promise<any>[] = [];
        const protocolSection = {
            C_ParentProtocol_key: protocolKey,
            SectionName: section,
            sampleGroupsExpanded: workflowTasks.some((x) => x.SampleGroup && x.SampleGroup.length > 0)
        };
        await this._addProtocolSection(protocolSection).then((newSection) => {
            if (this.isCRO) {
                for (let i = 0; i < workflowTasks.length; i++) {
                    const initialValues = workflowTasks[i];
                    initialValues.C_ProtocolTaskSection_key = newSection.C_ProtocolTaskSection_key;
                    initialValues.C_Protocol_key = protocolKey;
                    if (initialValues.hasOwnProperty("RelatedTo") && initialValues.hasOwnProperty("AnchorDay")) {
                        taskMapping[i] = {RelatedTo: initialValues.RelatedTo, Day: initialValues.AnchorDay};
                    }
                }
                const taskGroupMapping: any = {};
                for (const initialValues of workflowTasks) {
                    if (initialValues.hasOwnProperty("Days")) {
                        initialValues.tasks = Array(initialValues.Days.length);
                        for (let k = 0; k < initialValues.Days.length; k++) {
                            const day = initialValues.Days[k];
                            initialValues.tasks[k] = Array(day);
                            for (let j = 0; j < day; j++) {
                                promises.push(this._addProtocolTask(initialValues, true).then((task: any) => {
                                    initialValues.tasks[k][j] = task;
                                }));
                            }
                        }
                    } else {
                        initialValues.C_ProtocolTaskSection_key = newSection.C_ProtocolTaskSection_key;
                        initialValues.C_Protocol_key = protocolKey;
                        initialValues.newTask = null;

                        if (initialValues.TaskGroup && !taskGroupMapping[initialValues.TaskGroup.C_TaskGroup_key]) {
                            const taskGroup = this.dataManager.createEntity('TaskGroup', {
                                C_Pattern_key: initialValues.TaskGroup.C_Pattern_key,
                                C_Frequency_key: initialValues.TaskGroup.C_Frequency_key,
                                TimeDifference: initialValues.TaskGroup.TimeDifference,
                                Occurrences: initialValues.TaskGroup.TaskCount,
                                ToEnd: initialValues.TaskGroup.ToEnd,
                                C_Protocol_key: this.protocol.C_Protocol_key,
                                C_ToEndFrequency_key: initialValues.TaskGroup.OldFrequencyKey,
                                Offset: initialValues.TaskGroup.offset,
                            });
                            taskGroupMapping[initialValues.TaskGroup.C_TaskGroup_key] = taskGroup;
                        }

                        promises.push(this._addProtocolTask(initialValues, true).then((task: any) => {
                            // If task has TaskGroup, see if there is already a task group 
                            // assign task group to task
                            if (initialValues.TaskGroup && taskGroupMapping[initialValues.TaskGroup.C_TaskGroup_key]) {
                                const taskGroup = taskGroupMapping[initialValues.TaskGroup.C_TaskGroup_key];
                                task.C_TaskGroup_key = taskGroup.C_TaskGroup_key;
                            }
                            initialValues.newTask = task;

                        }));
                    }
                }
            } else {
                for (const initialValues of workflowTasks) {
                    initialValues.C_ProtocolTaskSection_key = newSection.C_ProtocolTaskSection_key;
                    initialValues.C_Protocol_key = protocolKey;
                    initialValues.newTask = null;
                    promises.push(this._addProtocolTask(initialValues, true).then((task: any) => {
                        initialValues.newTask = task;
                    }));
                }
            }

            this.protocolSection.push(newSection);
        });
        return promises;
    }

    private mapAndAssignDays(workflowTasks: any[], taskMapping: any) {
        workflowTasks.forEach((wt: any) => {
            const newPattern = this.dataManager.createEntity('Pattern', {
                DaysPattern: wt.Days.join(',')
            });
            const taskGroup = this.dataManager.createEntity('TaskGroup', {
                C_Pattern_key: newPattern.C_Pattern_key,
                C_Frequency_key: wt.C_Frequency_key,
                TimeDifference: wt.TimeDifference,
                Occurrences: wt.TaskCount,
                ToEnd: wt.ToEnd,
                C_Protocol_key: this.protocol.C_Protocol_key,
                C_ToEndFrequency_key: wt.hasOwnProperty("OldFrequencyKey") ? wt.OldFrequencyKey : wt.C_Frequency_key,
                Offset: wt.offset,
            });
            const firstIndex = wt.tasks.findIndex((x: any) => x.length !== 0);
            const firstTask: any = wt.tasks[firstIndex][0];
            wt.tasks.forEach((day: any, dayIndex: any) => {
                let lastTask: any = null;
                day.forEach((task: any, index: any) => {
                    task.C_TimeUnit_key = null;
                    task.C_TaskGroup_key = taskGroup.C_TaskGroup_key;
                    if (index > 0) {
                        // if second or more assign it
                        task.TimeFromRelativeTask = wt.TimeDifference;
                        task.C_RelativeProtocolTask_key = lastTask.C_ProtocolTask_key;
                        task.C_ScheduleType_key = this.dueKey;
                        task.C_TimeRelation_key = this.afterKey;
                        task.C_TimeUnit_key = this.hourKey;
                    } else if (dayIndex > 0) {
                        // if task is first but day is not assign it to first task of first day
                        task.TimeFromRelativeTask = dayIndex - firstIndex;
                        task.C_RelativeProtocolTask_key = firstTask.C_ProtocolTask_key;
                        task.C_ScheduleType_key = this.dueKey;
                        task.C_TimeRelation_key = this.afterKey;
                        task.C_TimeUnit_key = this.dayKey;
                    }
                    // keep reference of last task
                    lastTask = task;
                });
            });

            if (wt.hasOwnProperty("RelatedTo") && wt.RelatedTo === "study_start_day") {
                firstTask.RelativeToStudyStart = true;
                firstTask.TimeFromRelativeTask = wt.StartDay;
                firstTask.C_RelativeProtocolTask_key = null;
                firstTask.C_ScheduleType_key = null;
            }
        });
        // Build task aliases according to study days
        workflowTasks.forEach((wt: any) => {
            wt.tasks.forEach((day: any, dayIndex: any) => {
                day.forEach((task: any, index: any) => {
                    task.TaskAlias = this.buildTaskAliasStudyTimingWise(task, dayIndex, index);
                    task._aliasChanged = false;
                });
            });
        });
        for (const t of Object.keys(taskMapping)) {
            const from = workflowTasks[taskMapping[t].RelatedTo].tasks[taskMapping[t].Day][0];
            const firstIndex = workflowTasks[t].tasks.findIndex((x: any) => x.length !== 0);
            const pt: any = workflowTasks[t].tasks[firstIndex][0];
            if (pt && from) {
                // Set time relationship to 0 days after for first instance of task
                pt.TimeFromRelativeTask = 0;
                pt.C_TimeUnit_key = this.dayKey;
                pt.C_TimeRelation_key = this.afterKey;
                pt.C_RelativeProtocolTask_key = from.C_ProtocolTask_key;
                pt.RelativeToStudyStart = false;
                if (pt.C_ScheduleType_key === null) {
                    pt.C_ScheduleType_key = this.dueKey;
                }
            }
        }
    }

    private _addProtocolSection(initialValues: any): Promise<any> {
        const newProtocolTaskSection = this.protocolService.createProtocolSection(initialValues);
        this.sectionMap[newProtocolTaskSection.C_ProtocolTaskSection_key] = newProtocolTaskSection.SectionName;
        this.protocol.ChildProtocolTaskSection.unshift(newProtocolTaskSection);
        newProtocolTaskSection.SortOrder = this.protocol.ChildProtocolTaskSection.length;
        newProtocolTaskSection.sampleGroupsExpanded = initialValues.sampleGroupsExpanded;
        this.setDragulaOptions(initialValues.SectionName);
        return Promise.resolve(newProtocolTaskSection);
    }

    private _addProtocolTask(initialValues: any, section = false): Promise<any> {
        const taskValues = Object.assign({}, initialValues);
        if (section && this.protocol.ChildProtocolTaskSection && !initialValues.SortOrder) {
            const currentSection = this.protocol.ChildProtocolTaskSection
                .find((x: any) => x.C_ProtocolTaskSection_key === initialValues.C_ProtocolTaskSection_key);
            if (currentSection) {
                taskValues.SortOrder = currentSection.ProtocolTask.length + 1;
            }
        }

        const newProtocolTask = this.protocolService.createProtocolTask(taskValues);
        if (!section) {
            this.protocolTasks.unshift(newProtocolTask);
            newProtocolTask.SortOrder = this.protocolTasks.length;
        }

        return this._getTask(initialValues.C_WorkflowTask_key).then(() => {
            if (!newProtocolTask.TaskAlias || newProtocolTask.TaskAlias === '') {
                // Start with the default TaskAlias
                newProtocolTask.TaskAlias = this.buildTaskAlias(newProtocolTask);
                newProtocolTask._aliasChanged = false;
            }
        }).then(() => {
            if (initialValues.hasOwnProperty('SampleGroups')) {
                let timePoint = {};
                if (newProtocolTask.ProtocolTask) {
                    timePoint = {
                        C_TimeUnit_key: newProtocolTask.ProtocolTask.C_TimeUnit_key,
                        TimePoint: newProtocolTask.ProtocolTask.TimeFromRelativeTask
                    };
                }
                for (const row of initialValues.SampleGroups) {
                    this.dataManager.createEntity('SampleGroup', {
                        C_ContainerType_key: row.C_ContainerType_key,
                        C_PreservationMethod_key: row.C_PreservationMethod_key,
                        C_SampleType_key: row.C_SampleType_key,
                        C_SampleStatus_key: row.C_SampleStatus_key,
                        C_SampleSubtype_key: row.C_SampleSubtype_key,
                        C_SampleAnalysisMethod_key: row.C_SampleAnalysisMethod_key,
                        C_SampleProcessingMethod_key: row.C_SampleProcessingMethod_key,
                        C_TimeUnit_key: row.C_TimeUnit_key,
                        NumSamples: row.NumSamples,
                        SendTo: row.SendTo,
                        SpecialInstructions: row.SpecialInstructions,
                        C_TaskInstance_key: null,
                        C_ProtocolTask_key: newProtocolTask.C_ProtocolTask_key,
                        ...timePoint,
                    });
                }
            }
            return newProtocolTask;
        });
    }

    private _getTask(taskKey: number): Promise<any> {
        return this.taskService.getTaskByKey(taskKey);
    }

    /**
     * Show/Hide the Default Inputs column
     */
    toggleInputsExpanded(section: any) {
        if (section) {
            section.inputsExpanded = !section.inputsExpanded;
            return;
        }
        this.inputsExpanded = !this.inputsExpanded;
    }

    /**
     * Show/Hide the Sample Group column
     */
    toggleSampleGroupsExpanded(section: any) {
        if (section) {
            section.sampleGroupsExpanded = !section.sampleGroupsExpanded;
            this.resizeTableColumn();
            return;
        }
        this.sampleGroupsExpanded = !this.sampleGroupsExpanded;
        this.resizeTableColumn();
    }

    /**
     * Initialize the InputDefaults and their mapping to ProtocolTask/Input
     */
    private initializeInputDefaults() {
        // Clear the prior mappings
        this.mapInputDefault = {};

        if (!this.protocolTasks) {
            // No tasks yet
            return;
        }
        this.protocolTasks.forEach((protocolTask: any) => protocolTask.isSelected = false);

        this.protocolSection.forEach((section) => {
            section.isSelected = false;
            section.inputsExpanded = false;
            section.ProtocolTask.forEach((protocolTask: any) => protocolTask.isSelected = false);
        });
        // Ensure that each Input has a corresponding InputDefault
        for (const protocolTask of this.protocol.ProtocolTask) {
            const workflowTask = protocolTask.WorkflowTask;

            if (!workflowTask.Input || (workflowTask.Input.length === 0)) {
                // No inputs for this task
                continue;
            }

            for (const input of workflowTask.Input) {
                // Find, or create, a matching InputDefault
                let inputDefault = this.findInputDefault(protocolTask, input);
                if (!inputDefault) {
                    inputDefault = this.addInputDefault(protocolTask, input);
                }

                //  Map the InputDefault to the ProtocolTask/Input for the UI
                const key = this.inputDefaultKey(protocolTask, input);
                if (!this.mapInputDefault[key]) {
                    this.mapInputDefault[key] = inputDefault;
                }
            }
        }
    }

    /**
     * Generate a lookup key for the InputDefault associated with the Input of a ProtocolTask
     *
     * @param {ProtocolTask} protocolTask - the task in the protocol
     * @param {Input} input - the input for the task
     * @returns {string} lookup key into mapInputDefault ("{PROTOCOL_KEY}-{INPUT_KEY}")
     */
    inputDefaultKey(protocolTask: any, input: any): string {
        return `${protocolTask.C_ProtocolTask_key}-${input.C_Input_key}`;
    }

    /**
     * Find the InputDefault object in the ProtocolTask that matches the Input
     *
     * @param {ProtocolTask} protocolTask - the task in the protocol
     * @param {Input} input - the input for the task
     * @returns {InputDefault} matching InputDefault, or null
     */
    private findInputDefault(protocolTask: any, input: any): any {
        if (!protocolTask.InputDefault) {
            // Nothing to search
            return null;
        }

        // Look for the first matching InputDefault
        return protocolTask.InputDefault.find(
            (inputDefault: any) => inputDefault.C_Input_key === input.C_Input_key
        );
    }

    /**
     * Associate a new InputDefault to the Input for the PrototcolTask
     * 
     * @param {ProtocolTask} protocolTask - the task in the protocol
     * @param {Input} input - the input for the task
     * @returns {InputDefault} - new InputDefault associated with ProtocolTask and Input
     */
    private addInputDefault(protocolTask: any, input: any) {
        return this.protocolService.createInputDefault({
            C_ProtocolTask_key: protocolTask.C_ProtocolTask_key,
            C_Input_key: input.C_Input_key,
        });
    }

    /**
     * The Select All button was clicked
     */
    selectAll(section?: any) {
        // Select or unselect all the rows
        if (section) {
            for (const task of section.ProtocolTask) {
                task.isSelected = true;
            }
            this.getTasksSelectorConfig(section);
        } else if (this.protocolTasks) {
            for (const task of this.protocolTasks) {
                task.isSelected = true;
            }
            this.getTasksSelectorConfig();
        }
        jQuery('#tasksSelectorDropdown').trigger('click');
    }

    /**
     * The Clear All button was clicked
     */
    clearAll(section?: any) {
        // Select or unselect all the rows
        if (section) {
            for (const task of section.ProtocolTask) {
                task.isSelected = false;
            }
            this.getTasksSelectorConfig(section);
        } else if (this.protocolTasks) {
            for (const task of this.protocolTasks) {
                task.isSelected = false;
            }
            this.getTasksSelectorConfig();
        }
        jQuery('#tasksSelectorDropdown').trigger('click');
    }

    /**
     * A row selection checkbox was clicked.
     */
    isSelectedChanged(section: any) {
        if (section) {
            this.getTasksSelectorConfig(section);
            // Check if all the rows are selected
            this.allSelected = section.ProtocolTask.every((protocolTask: any) => protocolTask.isSelected);
            this.anySelected = section.ProtocolTask.some((protocolTask: any) => protocolTask.isSelected);
        } else {
            this.getTasksSelectorConfig();
            // Check if all the rows are selected
            this.allSelected = this.protocolTasks.every((protocolTask) => protocolTask.isSelected);
            this.anySelected = this.protocol.ProtocolTask.some((protocolTask: any) => protocolTask.isSelected);
        }
    }

    getSelectedGroupTasks(section: any): any[] {
        if (section) {
            return section.ProtocolTask.filter((protocolTask: any) => {
                return protocolTask.isSelected;
            });
        }
        return this.protocolTasks.filter((protocolTask) => {
            return protocolTask.isSelected;
        });
    }

    /**
     * Handle the "Create Sample Groups" button click
     */
    actionCreateSampleGroups(section: any) {
        this.createSampleGroups(section);
    }

    /**
     * Present a modal to configure new SampleGroups to be added to the
     * selected tasks.
     */
    private createSampleGroups(section: any): Promise<any> {
        // Check for selected tasks
        const selectedTasks = this.getSelectedGroupTasks(section);
        if (!selectedTasks || (selectedTasks.length === 0)) {
            // Nothing to do
            return Promise.resolve();
        }

        // Show the modal
        this.createSampleGroupsModalService.openCreateSampleGroupsModal(
            selectedTasks
        ).then((create) => {
            if (!create) {
                return Promise.resolve();
            }
            this.loading = true;
            this.loadingMessage = "Creating Group Samples";
            for (const task of create.tasks) {
                // Get time point value if task is associated with protocol
                let timePoint = {};
                timePoint = {
                    C_TimeUnit_key: task.C_TimeUnit_key,
                    TimePoint: task.TimeFromRelativeTask
                };
                for (const row of create.rows) {

                    this.dataManager.createEntity('SampleGroup', {
                        C_TaskInstance_key: null,
                        C_ProtocolTask_key: task.C_ProtocolTask_key,
                        ...row,
                        ...timePoint,
                    });
                }
            }

            this.loading = false;
            this.loadingMessage = "";
        }).catch((error) => {
            console.log('Creating Group Samples', error);
            return Promise.resolve();
        });
    }

    // <select> formatters
    sampleTypeKeyFormatter = (value: any) => {
        return value.C_SampleType_key;
    }
    sampleTypeFormatter = (value: any) => {
        return value.SampleType;
    }
    sampleStatusKeyFormatter = (value: any) => {
        return value.C_SampleStatus_key;
    }
    sampleStatusFormatter = (value: any) => {
        return value.SampleStatus;
    }
    preservationMethodKeyFormatter = (value: any) => {
        return value.C_PreservationMethod_key;
    }
    preservationMethodFormatter = (value: any) => {
        return value.PreservationMethod;
    }
    containerTypeKeyFormatter = (value: any) => {
        return value.C_ContainerType_key;
    }
    containerTypeFormatter = (value: any) => {
        return value.ContainerType;
    }
    sampleSubtypeKeyFormatter = (value: any) => {
        return value.C_SampleSubtype_key;
    }
    sampleSubtypeFormatter = (value: any) => {
        return value.SampleSubtype;
    }
    sampleProcessingMethodKeyFormatter = (value: any) => {
        return value.C_SampleProcessingMethod_key;
    }
    sampleProcessingMethodFormatter = (value: any) => {
        return value.SampleProcessingMethod;
    }
    sampleAnalysisMethodKeyFormatter = (value: any) => {
        return value.C_SampleAnalysisMethod_key;
    }
    sampleAnalysisMethodFormatter = (value: any) => {
        return value.SampleAnalysisMethod;
    }

    // size formatting

    resizeTableColumn() {
        this.ref.detectChanges();
        const childTableItems = jQuery('.child-table-item');
        if (childTableItems.length > 0) {
            for (let i = 0; i < this.widths.length; i++) {
                this.widths[i] = jQuery(childTableItems[i]).outerWidth();
            }
        }
    }

    unSelectAll(section?: any) {
        if (section) {
            section.ProtocolTask.forEach((task: any) => task.isSelected = false);
        }
        this.protocol.ProtocolTask.forEach((task: any) => task.isSelected = false);
        this.allSelected = false;
        this.anySelected = false;
    }

    // Comparator to sort Task by Sort Order
    sortBySortOrder = (a: any, b: any): number => {
        return (a.SortOrder || 0) - (b.SortOrder || 0);
    }

    /**
     * ngFor helper to track DOM changes to table rows.
     */
    trackRow = (index: number, item: any): string => {
        // Include the SortOrder and C_ProtocolTask_key key in tracking key so ngFor will
        // update properly after a multi-row drag
        return `${item.SortOrder}-${item.C_ProtocolTask_key}-${index}`;
    }

    /**
     * ngFor helper to track DOM changes to table section.
     */
    trackSection = (index: number, item: any): string => {
        // Include the SortOrder and C_ProtocolTask_key key in tracking key so ngFor will
        // update properly after a multi-row drag
        return `${index}-${item.SortOrder}-${item.C_ProtocolTaskSection_key}`;
    }

    validSectionName(section: any) {
        for (const sec of this.protocolSection) {
            if (sec.SectionName === section.SectionName && sec.C_ProtocolTaskSection_key !== section.C_ProtocolTaskSection_key) {
                return false;
            }
        }
        return true;
    }

    anySectionNameChanged(section: any) {
        for (const sec of this.protocolSection) {
            if (sec.SectionName === section.SectionName && sec.C_ProtocolTaskSection_key !== section.C_ProtocolTaskSection_key) {
                this.saveChangesService.isLocked = true;
                return;
            }
        }
        this.saveChangesService.isLocked = false;
    }

    isRegularInput(input: any): boolean {
        return input.cv_DataType.DataType !== DataType.DOSING_TABLE &&
            input.cv_DataType.DataType !== DataType.JOB_CHARACTERISTIC;
    }

    isDosingTableInput(input: any): boolean {
        return input.cv_DataType.DataType === DataType.DOSING_TABLE;
    }

    isJobCharacteristicInput(input: any): boolean {
        return input.cv_DataType.DataType === DataType.JOB_CHARACTERISTIC;
    }

    private getTasksSelectorConfig(section?: any): void {
        let numSelected;
        if (section) {
            numSelected = section.ProtocolTask.filter((protocolTask: any) => protocolTask.isSelected).length;
        } else {
            numSelected = this.protocolTasks.filter((protocolTask: any) => protocolTask.isSelected).length;
        }
        if (section) {
            this.sectionTasksSelectorLabels[section.SortOrder] = numSelected + ' Tasks Selected';
        } else {
            this.tasksSelectorLabel = numSelected + ' Tasks Selected';
        }
    }
}

import type { cv_ContainerType } from './cv-container-type.interface';

export interface Container {
    C_ContainerPosition_key: number | null;
    C_ContainerType_key: number;
    C_Container_key: number;
    C_ParentContainer_key: number | null;
    C_Workgroup_key: number;
    ContainerName: string;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    Identifier: string;
    ModifiedBy: string;
    Owner: string;
    Version: number;
    cv_ContainerType: cv_ContainerType;
}

import type { Animal } from './animal.interface';

export interface cv_AnimalStatus {
    Abbreviation: string;
    Animal: Animal[];
    AnimalStatus: string;
    C_AnimalStatus_key: number;
    C_VocabularySource_key: number | null;
    C_Workgroup_key: number;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    ElementID: string;
    IsActive: boolean;
    IsDefault: boolean;
    IsDefaultHealthRecord: boolean;
    IsDeprecated: boolean;
    IsExitStatus: boolean;
    ModifiedBy: string;
    SortOrder: number | null;
    Version: number | null;
}

import type { Material } from './material.interface';
import type { MaterialPool } from './material-pool.interface';
import type { cv_CompatibilityAccessLevel } from './cv-compatibility-access-level.interface';

export interface CompatibilityMaterial {
    C_CompatibilityAccessLevel_key: number | null;
    C_CompatibilityMaterial_key: number;
    C_MaterialPool_key: number;
    C_Material_key: number;
    C_Workgroup_key: number;
    Comments: string;
    CreatedBy: string;
    DateCreated: Date;
    DateDocumented: Date | null;
    DateModified: Date;
    Material: Material;
    MaterialPool: MaterialPool;
    ModifiedBy: string;
    Version: number;
    cv_CompatibilityAccessLevel: cv_CompatibilityAccessLevel;
}

import { Injectable } from '@angular/core';
import {
    Entity,
    EntityManager,
    EntityQuery,
    EntityState,
    Predicate
} from 'breeze-client';

import { QueryDef } from './query-def';
import { isNavigationProperty } from '@services/breeze-helpers';

/**
 * Base class for our Entity Services (E.g. AnimalService)
 * Provides common functions for building Breeze queries,
 * and dealing with breeze data.
 */
@Injectable()
export class BaseEntityService {

    constructor() {
        // Nothing to do
    }

    /**
     * Build an EntityQuery from the provided QueryDef.
     * Defaults some values in queryDef when not provided.
     * The returned EntityQuery has no where() or expand() applied.
     *  
     * @param entityType - Entity type, e.g. 'Animals'
     * @param queryDef
     */
    buildDefaultQuery(entityType: string, queryDef: QueryDef): EntityQuery {

        let take = 0;
        let skip = 0;
        if (queryDef.size) {
            take = queryDef.size;
            skip = queryDef.page ? (queryDef.page - 1) * take : 0;
        }

        // default inlineCount to true
        let inlineCount = true;
        if (queryDef.inlineCount === false) {
            inlineCount = false;
        }

        let query = EntityQuery.from(entityType);
        if (take) {
            query = query.take(take);
            query = query.skip(skip);
        }

        if (queryDef.sort) {
            const uniqueSorts = this.removeDuplicateSorts(queryDef.sort);
            query = query.orderBy(uniqueSorts);
        }

        if (inlineCount) {
            query = query.inlineCount(true);
        }

        return query;
    }


    /**
     * Removes any duplicate sort columns, 
     *   favoring the first occurrence
     * @param sort 
     */
    removeDuplicateSorts(sort: string): string {
        // if no sorts Breeze requires null or undefined
        const defaultReturn: string = null;

        if (!sort) {
            return defaultReturn;
        }

        const sortValues = sort.split(',');
        const seenSorts: any = {};
        const uniqueSorts: string[] = [];
        for (const sortValue of sortValues) {
            const tokens = sortValue
                .trim()
                .replace(/\s\s+/g, ' ') // remove extra whitespace
                .split(' ');
            // must have sort column and direction
            if (tokens.length < 2) {
                continue;
            }

            const sortCol = tokens[0];
            const direction = tokens[1];

            // track case-insensitive key
            const sortKey = sortCol.toLocaleLowerCase().trim();

            // ignore duplicate sort columns
            if (seenSorts[sortKey]) {
                continue;
            }

            seenSorts[sortKey] = true;
            uniqueSorts.push(sortCol + ' ' + direction);
        }

        return uniqueSorts.join(', ') || defaultReturn;
    }

    /**
     * Adds expand clause to queryDef if queryDef.expands
     *   does not already contain it.
     * @param queryDef
     * @param expand - a Breeze entity expand clause
     */
    ensureDefExpanded(queryDef: QueryDef, expand: string) {
        if (!queryDef.expands) {
            queryDef.expands = [];
        }
        this.ensureExpanded(queryDef.expands, expand);
    }

    /**
     * Adds expand clause to previousExpands if it
     *   does not already contain it.
     * @param previousExpands
     * @param expand
     */
    ensureExpanded(previousExpands: string[], expand: string) {
        for (const previousExpand of previousExpands) {
            if (previousExpand === expand ||
                previousExpand.startsWith(expand + '.')
            ) {
                return;
            }
        }
        previousExpands.push(expand);
    }

    /**
     * Creates a Breeze Predicate, treating the value 
     * as a literal rather than a property expression.
     *
     * TODO: should be applied more broadly to all text searches
     *
     * @param property
     * @param operator
     * @param value literal value or variable
     */
    createLiteralPredicate(property: string, operator: any, value: any) {
        const valueLiteral = {
            value,
            isLiteral: true
        };
        return Predicate.create(property, operator, valueLiteral);
    }

    /**
     * Gets all local entities of a specific type that have not been deleted.
     *
     * @param entityManager
     * @param entityType
     */
    getNonDeletedLocalEntities(entityManager: EntityManager, entityType: string): any[] {
        return entityManager.getEntities(entityType,
            // Ignore deleted entities
            [EntityState.Added,
            EntityState.Unchanged,
            EntityState.Modified]
        );
    }

    /**
     * Converts a set of fields from some TableOptions to give a list of `expand`
     * items that can be used to load missing data.
     *
     * @example```
     *   async ensureVisibleColumnsDataLoaded(entities: Entity[], visibleColumns: string[]): Promise<void> {
     *     const expands = this.generateExpandsFromVisibleColumns(entities[0], visibleColumns);
     *     return this.dataManager.ensureRelationships(entities, expands);
     *   }
     * ```
     *
     * @param item
     * @param visibleColumns
     */
    generateExpandsFromVisibleColumns(item: Entity, visibleColumns: string[]): string[] {
        const expands = new Set<string>();

        for (const field of visibleColumns) {
            if (isNavigationProperty(item, field)) {
                expands.add(field);
                continue;
            }

            // Assume that the fields given as a path (with dots) are a NavigationProperty.
            // We add to the 'expands' the entire path except for the last value.
            if (field.includes('.')) {
                const expand = field.split('.').slice(0, -1).join('.');
                expands.add(expand);
            }
        }

        return [...expands];
    }
}

import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';
import * as escapeStringRegexp from 'escape-string-regexp';

@Component({
    selector: 'crl-customer-select',
    template: `
    <div>
        <climb-typeahead
            [(model)]="model"
            [resultFormatter]="resultFormatter"
            [keyFormatter]="keyFormatter"
            [search]="searchCustomers"
            [keySearch]="searchCustomersByClientID"
            [id]="id"
            [placeholder]="placeholder"
            [required]="required"
            [namespace]="'institution'"
            [forceValidSelection]="forceValidSelection"
            (selectItem)="selectItemHandler($event)"
        ></climb-typeahead>
    </div>
    `
})
export class CrlCustomerSelectComponent implements OnInit {
    @Input() model: any;
    @Input() id: string;
    // Disallow free-text entries?
    @Input() forceValidSelection = true;
    @Input() placeholder: string;
    @Input() required: boolean;

    @Output() modelChange: EventEmitter<any> = new EventEmitter<any>();

    private _customersPromise: Promise<any>;

    readonly MAX_RESULTS = 50;

    ngOnInit() {
        this._getCustomers();
    }

    // Will make SAP api call to get customers. Right now, just hard-coded one option for proof of concept
    private _getCustomers() {
        this._customersPromise = Promise.resolve([{ Name: 'A&G Pharmaceutical Incorporated', ClientID: '0000804635' }]);
    }

    searchCustomers = (search: string): Promise<any[]> => {
        if (!this._customersPromise) {
            this._getCustomers();
        }

        return this._customersPromise.then((customers) => {
            let results: any[] = [];

            if (customers) {
                results = customers.filter((choice: any) => {
                    // return match if text is empty
                    return !search || this._matchTypeaheadChoice(search, choice);
                });
            }

            results = this._limitResults(results);

            return results;
        });
    }

    private _matchTypeaheadChoice(search: string, choice: any): boolean {
        const display = this.resultFormatter(choice);
        const escapedSearch = escapeStringRegexp(search);
        return new RegExp(escapedSearch, 'gi').test(display);
    }

    private _limitResults(results: any[]): any[] {
        if (results.length > this.MAX_RESULTS) {
            return results.splice(0, this.MAX_RESULTS);
        } else {
            return results;
        }
    }

    searchCustomersByClientID = (clientID: string): Promise<any[]> => {
        if (!this._customersPromise) {
            this._getCustomers();
        }

        return this._customersPromise.then((customers) => {
            let results: any[] = [];

            if (customers) {
                results = customers.filter((choice: any) => {
                    return this._matchKeyChoice(clientID, choice);
                });
            }

            // Use the clientID if no matching results
            if (results.length === 0) {
                results.push(clientID);
            }

            return results;
        });
    }

    private _matchKeyChoice(search: string, choice: any): boolean {
        const choiceKey = this.keyFormatter(choice);

        return (choiceKey.toLowerCase() === search.toLowerCase());
    }

    selectItemHandler(item: any) {
        this.modelChange.emit(this.model);
    }

    resultFormatter = (item: any) => {
        return item.Name +
            ' (' +
            item.ClientID +
            ')';
    }

    keyFormatter = (item: any) => {
        return item.ClientID;
    }
}

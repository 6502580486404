<ng-template #animalStatusTmpl let-healthRecord="item">
    <active-vocab-select [(model)]="healthRecord.C_AnimalStatus_key"
                        [vocabChoices]="animalStatuses"
                        [keyFormatter]="animalStatusKeyFormatter"
                        [optionFormatter]="animalStatusFormatter">
    </active-vocab-select>
</ng-template>

<ng-template #healthTechTmpl let-healthRecord="item">
    <active-vocab-select [(model)]="healthRecord.C_Resource_key"
                        [vocabChoices]="resources"
                        [keyFormatter]="resourceKeyFormatter"
                        [optionFormatter]="resourceNameFormatter"
                        [defaultValue]="defaultResourceKey"
                        [loadDefaultValue]="true">
    </active-vocab-select>
</ng-template>

<ng-template #urgentTmpl let-observation="item">
    <input type="checkbox"
           class="mt-1"
            name="isUrgent"
            [(ngModel)]="observation.IsUrgent"
            (ngModelChange)="isUrgentChanged(observation)" />
</ng-template>

<ng-template #identifierTmpl let-observation="item">
    {{observation.Animal.Material?.Identifier}}
</ng-template>

<ng-template #animalNameTmpl let-observation="item">
    {{observation.Animal.AnimalName}}
</ng-template>

<ng-template #animalLocationTmpl let-observation="item">
    {{observation.Animal.Material?.CurrentLocationPath}}
</ng-template>

<ng-template #animalStudyTmpl let-observation="item">
    <span *ngIf="observation.Animal.Material?.JobMaterial | notEmpty">
        {{observation.Animal.Material?.JobMaterial | map:'Job' | map:'JobID' | delimit:', '}}
    </span>
</ng-template>

<ng-template #animalBirthDateTmpl let-observation="item">
    {{observation.Animal.DateBorn | formatShortDate}}
</ng-template>

<ng-template #animalSexTmpl let-observation="item">
    <span *ngIf="observation.Animal.cv_Sex">{{observation.Animal.cv_Sex.Sex}}</span>
</ng-template>

<ng-template #animalSpeciesTmpl let-observation="item">
    <span *ngIf="observation.Animal.Material?.cv_Taxon">{{observation.Animal.Material?.cv_Taxon.CommonName}}</span>
</ng-template>

<ng-template #observedDateTmpl let-observation="item">
    <climb-ngb-date #dateControl="ngModel" name="Date Observed" [(ngModel)]="observation.DateObserved"></climb-ngb-date>
</ng-template>

<ng-template #observedByTmpl let-observation="item">
    <active-vocab-select [(model)]="observation.C_Resource_key"
                        [vocabChoices]="resources"
                        [keyFormatter]="resourceKeyFormatter"
                        [optionFormatter]="resourceNameFormatter">
    </active-vocab-select>
</ng-template>

<ng-template #observationStatusTmpl let-observation="item">
    <active-vocab-select [(model)]="observation.C_ClinicalObservationStatus_key"
                        [vocabChoices]="clinicalObservationStatuses"
                        [keyFormatter]="observationStatusKeyFormatter"
                        [optionFormatter]="observationStatusFormatter"
                        [nullable]="true">
    </active-vocab-select>
</ng-template>

<ng-template #observationsTmpl let-observation="item" let-section="section">
    <button type="button" class="btn btn-primary"
            style="height:25px;width:25px;margin:1px 0;padding:0;"
            (click)="openObservationChooser(observationmodal, observation, section)">
        ...
    </button>
    <p *ngIf="observation.ClinicalObservationDetail | notEmpty">
        {{observation.ClinicalObservationDetail | map:'cv_ClinicalObservation' | map:'ClinicalObservation' | delimit:', '}}
    </p>
</ng-template>

<ng-template #commentsTmpl let-observation="item">
    <textarea name="comments-{{observation.C_AnimalClinicalObservation_key}}"
            [(ngModel)]="observation.Comments"
            class="form-control  input-large">
    </textarea>
</ng-template>

<ng-template #observationmodal let-c="close">
    <observation-chooser [selectedObservations]="selectedObservation.ClinicalObservationDetail"
                         (close)="c('Close')"
                         (onSelect)="onSelectObservations($event)"></observation-chooser>
</ng-template>

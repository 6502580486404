<div class="workspace-creation-title">Workspace creation</div>
<div class="tip-wrapper">
    <div class="workspace-creation-tip">
        <img class="workspace-creation-image" src="images/facet-menu-panel.png" alt="Screenshot of facet menu panel">
        <div class="workspace-creation-tip-text">You can add facets by clicking the menu items on the left</div>
    </div>
    <div class="workspace-creation-tip">
        <img class="workspace-creation-image" src="images/resize-facets.png" alt="Picture of resize functionality">
        <div class="workspace-creation-tip-text">You can also arrange and resize facets by dragging them wherever you want</div>
    </div>
</div>

<div class="bulk-edit-wrapper">
    <div class="bulk-edit-grid">
        <bulk-add #bulkAdd
                  [options]="bulkTemplates.bulkOptions"
                  (save)="saveClicked($event)"
                  (exit)="exitClicked($event)">
        </bulk-add>
    </div>
</div>

<genotype-bulk-templates #bulkTemplates [genotypes]="[]"></genotype-bulk-templates>

<ng-template #itemsToAddTmpl
    let-state="state"
    let-itemTypeLabel="itemTypeLabel">
    <div class="row">
        <div class="form-group col-md-6">
            <h5>Add Standalone Genotypes</h5>
            <label class="col-form-label text-nowrap number-items-to-add-label">
                Number of New {{itemTypeLabel}} to Add
                <input class="number-items-to-add"
                    type="number"
                    name="numberItemsToAdd"
                    min=1
                    max=50
                    [(ngModel)]="state.numberItemsToAdd"
                    (blur)="state.limitNumberItemsToAdd()"
                    [disabled]="sourceMaterials | notEmpty" />
            </label>
            <div class="col-md-12" *ngIf="sourceMaterials | notEmpty">
                <small class="form-text text-muted">
                </small>
            </div>
        </div>
        <div class="col-md-1">
            <h5>or</h5>
        </div>
        <div class="col-md-5"
                droppable
                (drop)="onDropSourceMaterials()"
                title="Drop source animals here">
            <h5>Add Genotypes from Source
                <info-tooltip [text]="'Drop or paste source animals to create each genotype'"></info-tooltip>
            </h5>
            <div class="dropzone">
                <animal-multiselect [(model)]="sourceMaterials"
                                    (modelChange)="syncItemsToAdd()">
                    <button extra-buttons
                        (click)="pasteSourceMaterials()"
                        class="btn btn-secondary">
                        <i class="fa fa-paste" title="Paste source animals"></i>
                    </button>
                </animal-multiselect>
            </div>
        </div>
    </div>
</ng-template>

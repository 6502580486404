import type { Animal } from './animal.interface';
import type { TaxonCharacteristic } from './taxon-characteristic.interface';

export interface TaxonCharacteristicInstance {
    Animal: Animal;
    C_EnumerationItem_key: number | null;
    C_Material_key: number;
    C_TaxonCharacteristicInstance_key: number;
    C_TaxonCharacteristic_key: number;
    C_Workgroup_key: number;
    CharacteristicName: string;
    CharacteristicValue: string;
    CreatedBy: string;
    DateCreated: Date;
    DateEnd: Date | null;
    DateModified: Date;
    DateStart: Date | null;
    Description: string;
    ModifiedBy: string;
    TaxonCharacteristic: TaxonCharacteristic;
    Version: number;
}

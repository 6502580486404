import {
    Component,
    EventEmitter,
    Input,
    Output
} from '@angular/core';

import { SearchService } from '../search/search.service';
import { SearchQueryDef } from '../search/search-query-def';

const MAX_SEARCH_RESULTS = 50;

@Component({
    selector: 'construct-multiselect',
    template: `
<!-- Selected constructs -->
<div *ngIf="model && model.length > 0">
    <table class="table table-sm table-borderless table-nonfluid">
        <tbody>
            <tr *ngFor="let item of model; let i = index;">
                <td class="icon-cell">
                    <a (click)="removeConstruct(i)" title="Remove construct">
                        <i class="fa fa-remove remove-item"></i>
                    </a>
                </td>
                <td>
                    {{item.FullName}}
                </td>
            </tr>
        </tbody>
    </table>
</div>

<!-- Construct typeahead -->
<climb-indirect-typeahead
        [search]="searchConstructs"
        [resultFormatter]="fullNameFormatter"
        placeholder="Name&hellip;"
        (selectItem)="selectConstruct($event)"></climb-indirect-typeahead>
`,
    styles: [`
        .table {
            margin-bottom: 0.25em;
        }
    `]
})
export class ConstructMultiselectComponent {
    @Input() model: any[];
    @Output() modelChange: EventEmitter<any> = new EventEmitter<any>();

    constructor(
        private _searchService: SearchService
    ) {
        //
    }

    removeConstruct(index: number) {
        if (this.model && index >= 0 && index < this.model.length) {
            this.model.splice(index, 1);
        }
        this.modelChange.emit(this.model);
    }

    searchConstructs = (term: string): Promise<any> => {

        let count = null;
        if (term.length < 3) {
            count = MAX_SEARCH_RESULTS;
        }

        const constructFilter = {
            FullName: term
        };

        const searchQueryDef: SearchQueryDef = {
            entity: 'Constructs',
            page: 1,
            size: count,
            sortColumn: 'FullName',
            sortDirection: 'asc',
            filter: constructFilter
        };

        return this._searchService.getEntitiesBySearch(searchQueryDef)
            .then((results: any) => {
                return results.data;
            });
    }

    fullNameFormatter = (value: any) => {
        return value.FullName;
    }

    selectConstruct(construct: any) {
        if (this.model) {
            this.model.push(construct);
        } else {
            this.model = [construct];
        }

        this.modelChange.emit(this.model);
    }
}

import {
    Component,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { filter } from 'rxjs/operators';

import { NoteService } from './note.service';
import { ViewNoteLogModalService } from './view-note-log-modal.service';

@Component({
    // HACK: set as attribute for use in Bootstrap btn-group;
    // otherwise, the Angular component tag messes up the formatting
    /* eslint-disable-next-line */
    selector: '[view-notes-button]',
    templateUrl: './view-notes-button.component.html'
})
export class ViewNotesButtonComponent implements OnInit, OnChanges, OnDestroy {
    /* The name of the parent object */
    @Input() objectName: string;
    /* The name of the parent object's type */
    @Input() objectType: string;
    /* The name of the object's primary key database field */
    @Input() pkName: string;
    /* The value of the object's primary key */
    @Input() pkValue: number;
    /* Is the parent object record new and unsaved? */
    @Input() isObjectNew: boolean;
    /* Is read only? */
    @Input() readonly: boolean;

    // State
    loading = false;
    noteCount = 0;

    noteSub: any;


    constructor(
        private noteService: NoteService,
        private viewNoteLogModalService: ViewNoteLogModalService
    ) { }

    ngOnInit() {
        this.initialize();
        this.noteSub = this.noteService.updateCount$
        .pipe(
            filter(({pkName, pkValue}) => {
                return pkName === this.pkName && pkValue === this.pkValue;
            })
        ).subscribe(() => {
            this.getNoteCount();
        });
    }

    ngOnChanges(changes: any) {
        if (
            (changes.pkValue && !changes.pkValue.firstChange) ||
            (changes.pkName && !changes.pkName.firstChange)
        ) {
            // Detail paging
            this.initialize();
        }
    }

    ngOnDestroy() {
        this.noteSub.unsubscribe();
    }

    initialize() {
        this.getNoteCount();
    }

    private getNoteCount(): Promise<any> {
        this.noteCount = 0;

        if (this.pkName && this.pkValue) {
            this.noteService.getNotes(this.pkName, this.pkValue); // for some reason breeze doesn't get local cache entities if this isn't called
            this.loading = true;

            return this.noteService.getNoteCount(this.pkName, this.pkValue)
                .then((count: number) => {
                    this.noteCount = count;
                    this.loading = false;
                })
                .catch((err: Error) => {
                    this.loading = false;
                    throw err;
                });
        }

        return Promise.resolve();
    }


    viewNotes() {
        this.viewNoteLogModalService.openComponent(
            this.objectName,
            this.objectType,
            this.pkName,
            this.pkValue,
            this.isObjectNew,
            this.readonly
        ).then(() => {
            this.getNoteCount();
        });
    }
}

<div class="line-genotype-assay-select">
    <div *ngIf="line.LineGenotypeAssay && line.LineGenotypeAssay.length > 0">
        <table class="table table-nonfluid" [ngClass]="{'table-hover': !readonly}">
            <tbody *ngIf="!readonly; else readOnly" [dragula]='"line-genotype-assay-bag"' [dragulaModel]='line.LineGenotypeAssay'>
                <tr *ngFor="let lineGenotypeAssay of line.LineGenotypeAssay" class="draggable">
                    <td class="icon-cell">
                        <i class="fa fa-sort text-muted" title="Drag to reorder"></i>
                    </td>
                    <td class="icon-cell">
                        <a (click)="removeLineGenotypeAssay(lineGenotypeAssay)" title="Remove assay"><i class="fa fa-remove remove-item"></i></a>
                    </td>
                    <td>
                        {{lineGenotypeAssay.cv_GenotypeAssay.GenotypeAssay}}
                    </td>
                </tr>
            </tbody>
            <ng-template #readOnly>
                <tbody>
                    <tr *ngFor="let lineGenotypeAssay of line.LineGenotypeAssay">
                        <td>
                            {{lineGenotypeAssay.cv_GenotypeAssay.GenotypeAssay}}
                        </td>
                    </tr>
                </tbody>
            </ng-template>
           
        </table>
        <small class="form-text text-muted" *ngIf="line.LineGenotypeAssay.length > 1">
            Drag to reorder.
        </small>
    </div>

    <climb-indirect-typeahead [search]="searchGenotypeAssays"
                              [resultFormatter]="genotypeAssayFormatter"
                              [placeholder]="'Add a genotype assay&hellip;'"
                              (selectItem)="selectGenotypeAssay($event)"></climb-indirect-typeahead>

</div><!-- /line-genotype-assay-select component -->

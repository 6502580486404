import { Component, Input, Output, EventEmitter } from "@angular/core";

@Component({
    selector: 'clinical-confirm-button',
    templateUrl: './clinical-confirm-button.component.html'
})
export class ClinicalConfirmButtonComponent {
    @Input() isConfirmed = false;
    @Input() isEndState = false;
    @Output() onConfirm: EventEmitter<void> = new EventEmitter<void>();

    onConfirmButtonClicked(): void {
        this.onConfirm.emit();
    }
}

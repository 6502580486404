import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { ClimbCommonModule } from '../common/climb-common.module';
import { ClimbServicesModule } from '../services/climb-services.module';

import { VocabularyService } from './vocabulary.service';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ClimbCommonModule,
        ClimbServicesModule
    ]
})
export class VocabulariesSharedModule {
    static forRoot(): ModuleWithProviders<VocabulariesSharedModule> {
        return {
          ngModule: VocabulariesSharedModule,
          providers: [
            VocabularyService
          ]
        };
    }
}

import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';
import { WizardService } from '../wizard.service';

import { CvValue } from '../models';

import {
    focusElementByQuery,
    randomId
} from '../../common/util';

@Component({
    selector: 'choose-cv-values',
    templateUrl: './choose-cv-values.component.html',
})
export class ChooseCvValuesComponent implements OnInit {
    // The singular, Title Case name of the CV items
    @Input() cvItemName: string;
    // The db table that contains the CV items
    @Input() cvTableName: string;
    // Is at least one value required?
    @Input() required = false;
    @Input() selectedValues: CvValue[];
    @Output() selectedValuesChange: EventEmitter<CvValue[]> = new EventEmitter<CvValue[]>();

    // All seed values
    seedValues: CvValue[];

    tableId: string;


    constructor(
        private wizardService: WizardService
    ) {
        // Nothing to do
    }

    ngOnInit() {
        this.initialize();
    }

    initialize() {
        this.setDefaultValues();

        this.getSeedValues().then((values) => {
            this.seedValues = values;

            if (this.selectedValues.length === 0) {
                // Use all seed values if there are no selected values
                this.selectedValues = this.seedValues.slice();
                setTimeout(() => {
                    this.selectedValuesChange.emit(this.selectedValues);
                });
            }
        });
    }

    private setDefaultValues() {
        this.tableId = 'cv-table-' + randomId();

        if (!this.cvItemName) {
            this.cvItemName = 'item';
        }

        if (!this.selectedValues) {
            this.selectedValues = [];
        }
    }

    private getSeedValues(): Promise<CvValue[]> {
        return this.wizardService.getCVSeedValues(this.cvTableName);
    }

    onModelChange(event: Event) {
        this.selectedValuesChange.emit(this.selectedValues);
    }


    // Form controls
    addValue() {
        const newValue = this.createCvValue();
        this.selectedValues.push(newValue);

        this.focusOnNewValue();

        this.selectedValuesChange.emit(this.selectedValues);
    }

    private createCvValue(): CvValue {
        return {
            cvValue: '',
            isDefault: false,
            isEndState: false,
            isExitStatus: false
        };
    }

    private focusOnNewValue() {
        // Match last input element in the table
        const cssSelector = '#' + this.tableId + ' tbody tr:last-child input';
        focusElementByQuery(cssSelector, 250);
    }

    removeValue(index: number) {
        if (this.selectedValues && index >= 0 && index < this.selectedValues.length) {
            this.selectedValues.splice(index, 1);
        }

        this.selectedValuesChange.emit(this.selectedValues);
    }
}

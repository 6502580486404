
<table class="table table-bordered job-pharma-tasks-list">
    <thead>
        <tr class="detail-grid-header">

            <th *ngIf="!readonly" class="icon-cell">
                <lock-control [(model)]="allLocked"
                [administrator]="isStudyDirector"
                (modelChange)="allLockedChanged()"></lock-control>
            </th>
            <!-- Select -->
            <th *ngIf="!readonly" class="icon-cell">
                <input type="checkbox"
                       name="selectAll"
                       [(ngModel)]="allSelected"
                       (ngModelChange)="allSelectedChanged()"
                       title="Select/Clear all" />
            </th>

            <!-- Blank header for remove column -->
            <th *ngIf="!readonly">
                <button type="button"
                        class="btn btn-link remove-item py-0"
                        (click)="removeTasks()"
                        [disabled]="!canRemoveAnyTask()">
                    <i class="fa fa-remove" title="Delete all eligible tasks"></i>
                </button>
            </th>

            <th *ngIf="visible.protocol"
                droppable
                (drop)="onDrop($event)"
                [dropDisabled]="readonly || !protocolService.draggedProtocols?.length"
                [attr.title]="readonly ? '' : 'Drop protocols here'">
                <a *ngIf="!readonly"
                   class="add-item-link"
                   (click)="addProtocolViaModal()">
                   <i data-automation-id="add-protocol-tasks-button"
                      class="fa fa-plus-circle" 
                      title="Add protocol tasks">
                    </i>
                </a>
                Protocol
            </th>

            <th *ngIf="visible.taskAlias"
                droppable
                (drop)="onDrop($event)"
                [dropDisabled]="readonly || !taskService.draggedTasks?.length"
                [attr.title]="readonly ? '' : 'Drop tasks here'">
                <a *ngIf="!readonly"
                   class="add-item-link"
                   (click)="addTasksViaModal()">
                   <i data-automation-id="add-task-button"
                      class="fa fa-plus-circle" 
                      title="Add task">
                    </i>
                </a>
                Task Name
            </th>

            <th *ngIf="visible.cohorts">Cohorts</th>

            <th *ngIf="visible.individualAnimals">Individual Animals</th>

            <th *ngIf="visible.sampleGroups">Sample Groups</th>

            <th *ngIf="visible.individualSamples">Individual Samples</th>

            <th *ngIf="visible.status">Progress</th>
        </tr>
    </thead>
    <!-- open -->
    <loading-overlay *ngIf="loading" [message]="loadingMessage"></loading-overlay>
    <tbody *ngIf="!loading">
        <tr *ngFor="let task of tasks  |
                paginate: { itemsPerPage: 50, currentPage: taskPage, id: 'tasks-list-pagination' };
                let index = index"
            [ngClass]="{'selected-row': task.isSelected}"
            class="task-row">
            <td *ngIf="!readonly"
                class="icon-cell">
                <lock-control [(model)]="task.IsLocked"
                                        [administrator]="isStudyDirector"
                                        (modelChange)="isLockedChanged()"></lock-control>
            </td>
            <!-- Select -->
            <td *ngIf="!readonly"
                class="icon-cell">
                <input type="checkbox"
                       name="isSelected_{{index}}"
                       [(ngModel)]="task.isSelected"
                       (ngModelChange)="isSelectedChanged()" />
            </td>

            <!-- Remove: Only tasks that don't have child tasks, a complete date,
            or output sets will have this option -->
            <td *ngIf="!readonly" class="icon-cell">
                <button type="button"
                        class="btn btn-link remove-item py-0"
                        (click)="removeTask(task)"
                        [disabled]="!canRemoveTask(task)">
                    <i class="fa fa-remove" [title]="getTaskDeleteTitle(task)"></i>
                </button>
            </td>

            <!-- Protocol -->
            <td *ngIf="visible.protocol"
                droppable
                (drop)="onDropToProtocolInstance(task.ProtocolInstance,task.IsLocked, $event)"
                [dropDisabled]="readonly || !task.ProtocolInstance || protocolService.draggedProtocols?.length > 0 || taskService.draggedTasks?.length > 0"
                [attr.title]="readonly ? '' : 'Add to Protocol'"
                class="protocol-name">
                {{task.ProtocolInstance?.ProtocolAlias}}
            </td>

            <!-- Task -->
            <ng-container *ngIf="!task.WorkflowTask?.NoMaterials">
                <td *ngIf="visible.taskAlias"
                    droppable
                    (drop)="onDropToTask(task, $event)"
                    [dropDisabled]="readonly || protocolService.draggedProtocols?.length > 0 || taskService.draggedTasks?.length > 0"
                    [attr.title]="readonly ? '' : 'Add to Task'"
                    class="task-name">
                    <a *ngIf="!readonly && isCRL && !task.cv_TaskStatus?.IsEndState && task.statusCount.endState === 0 && task.C_TaskInstance_key > -1"
                       class="edit-task-name-link"
                       (click)="editTasksViaModal(task)">
                        <i class="fa fa-pencil" title="Edit"></i>
                    </a>
                    {{task.TaskAlias}}
                </td>
            </ng-container>
            <ng-container *ngIf="task.WorkflowTask?.NoMaterials">
                <td *ngIf="visible.taskAlias">
                    <em>{{task.TaskAlias}} </em>
                    <info-tooltip [text]="'Animals cannot be added to No Material Tasks.'"></info-tooltip>
                </td>
            </ng-container>

            <!-- Cohorts -->
            <td *ngIf="visible.cohorts">
                <div class="label-list">
                    <ng-container *ngIf="task.TaskPlaceholder">
                        <ng-container *ngFor="let placeholder of task.TaskPlaceholder">
                            <ng-container *ngIf="placeholder.C_Placeholder_key">
                                <div class="cohort-badge" *ngIf="taskDontHaveMatchingCohort(placeholder, task.TaskCohort)">
                                    <a *ngIf="!readonly && !task.IsLocked"
                                       (click)="onRemovePlaceholder(placeholder, task)">
                                        <i class="fa fa-remove remove-item" title="Remove placeholder"></i>
                                    </a>&nbsp;{{placeholder.Placeholder.PlaceholderName}}
                                </div>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                    <ng-container *ngFor="let tc of task.TaskCohort">
                        <div *ngIf="tc.Cohort as cohort"
                             class="cohort-badge"
                             [ngStyle]="{ backgroundColor: cohort.BackgroundColor, color: cohort.ForegroundColor }">
                            <a *ngIf="!readonly && !task.IsLocked"
                               (click)="onRemoveCohort(task, cohort, tc)">
                                <i class="fa fa-remove remove-item" title="Remove cohort"></i>
                            </a>&nbsp;{{cohort.CohortName}}
                        </div>
                    </ng-container>
                </div>
            </td>

            <!-- Animals -->
            <td *ngIf="visible.individualAnimals">
                <div class="form-control-plaintext">
                    <ng-container *ngIf="task.TaskPlaceholder">
                        <div *ngFor="let placeholder of task.TaskPlaceholder">
                            <ng-container *ngIf="placeholder.C_AnimalPlaceholder_key">
                                <ng-container *ngIf="taskDontHaveMatchingAnimal(placeholder, task.TaskMaterial)">
                                    <a *ngIf="!readonly && !task.IsLocked"
                                        (click)="onRemoveAnimalPlaceholder(placeholder, task)">
                                        <i class="fa fa-remove remove-item" title="Remove placeholder"></i>
                                    </a>&nbsp;{{placeholder.AnimalPlaceholder.Name}}
                                </ng-container>
                            </ng-container>
                        </div>
                    </ng-container>
                    <div *ngFor="let tm of task.TaskMaterial">
                        <ng-container *ngIf="tm.Material?.Animal as animal"
                             class="label-with-remove">
                            <a *ngIf="!readonly && !task.IsLocked"
                               (click)="onRemoveAnimal(task, animal)">
                                <i class="fa fa-remove remove-item" title="Remove animal"></i>
                            </a>
                            {{animal.AnimalName}}
                        </ng-container>
                    </div>
                </div>
            </td>

            <!-- Sample Group -->
            <td *ngIf="visible.sampleGroups">
                <div *ngIf="task.numSources && task.numSourceAnimals">
                    {{task.numSourceAnimals}}
                    <img src="/images/animals.png" width="12" height="12" alt="Animal" title="Animal" />
                </div>
                <div *ngIf="task.numSources && task.numSourceSamples">
                    {{task.numSourceSamples}}
                    <img src="/images/samples.png" width="12" height="12" alt="Sample" title="Sample" />
                </div>
                <div *ngIf="task.numSources && task.numSourceAnimalPlaceholders">
                    {{task.numSourceAnimalPlaceholders}}
                    <img src="/images/AnimalPlaceholder.png" width="12" height="12" alt="Placeholder" title="Placeholder" />
                </div>
                <span *ngIf="!(task.numSourceAnimals) && !(task.numSourceSamples) && !(task.numSourceAnimalPlaceholders)">
                    {{(task.numSources) ? task.numSources : ''}}
                </span>
                <span class="sample-groups">
                    {{task.SampleGroupTypes}}
                </span>

            </td>

            <!-- Individual Samples -->
            <td *ngIf="visible.individualSamples">
                <div class="form-control-plaintext">
                    <div *ngFor="let taskMaterial of task.TaskMaterial">
                        <ng-container *ngIf="taskMaterial.Material?.Sample">
                            <a *ngIf="!readonly && !task.IsLocked"
                               (click)="onRemoveSample(taskMaterial)"
                               class="individual-samples">
                                <i class="fa fa-remove remove-item" title="Remove sample"></i>
                            </a>
                            {{taskMaterial.Material?.Sample.SampleName}}
                        </ng-container>
                    </div>
                </div>
            </td>

            <!-- Status -->
            <td *ngIf="visible.status"
                [ngClass]="{'done' : task.statusCount?.done}">
                <ng-container *ngIf="!task.WorkflowTask?.NoMaterials">
                    {{task.statusCount?.endState}}/{{task.statusCount?.total}}
                </ng-container>
                <ng-container *ngIf="task.WorkflowTask?.NoMaterials">
                    {{task.statusCount?.done ? '1' : '0'}}/1
                </ng-container>
            </td>
        </tr>
    </tbody>
</table>

<pagination-controls (pageChange)="changeTaskPage($event)"
                     [autoHide]="true"
                     class="ngx-table-paginator"
                     id="tasks-list-pagination"></pagination-controls>


<button class="user-avatar" [popoverTriggerFor]="userOptions" popoverOpenWithKeyArrows>
    {{ userAvatarLabel }}
</button>

<climb-popover #userOptions>
    <div climbTrapFocus climbArrowKeyAsTab>
        <div class="popover-section">
            <button climb-menu-item disabled>{{ userName }}</button>
            <button climb-menu-item (click)="openUserProfileModal()">Edit my profile</button>
            <button climb-menu-item (click)="openWorkgroupModal()">Switch workgroup</button>
            <button climb-menu-item (click)="reloadClimbApp()">Reload Climb</button>
        </div>
        <div class="popover-section bottom-addition">
            <button climb-menu-item (click)="clearAndLogOut()">
                <svg [climbIcon]="icons.arrowOutOfRectangle" size="sm"></svg>
                Log out
            </button>
        </div>
    </div>
</climb-popover>

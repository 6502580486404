import {
    CellFormatterService,
    FormatterOptions,
    TableOptions
} from '../common/datatable';
import { TranslationService } from '../services/translation.service';
import { AuditModifiedFieldComponent } from './audit-modified-fields/audit-modified-field.component';
import { AuditClassFactory } from './audit-class-maker';

export class AuditJobTasksTableOptions {

    options: TableOptions;

    constructor(
        private cellFormatterService: CellFormatterService,
        private translationService: TranslationService,
    ) {
        this.options = this.getDefaultOptions();
    }

    getDefaultOptions(): TableOptions {

        return {
            csvFileName:
            this.translationService.translate('job').toLowerCase() + '-tasks-audit.csv',
            enableDetailColumn: false,
            enableDraggable: false,
            enableSelectable: false,
            enableAuditColumns: false,
            columns: [
                {
                    displayName: 'Type',
                    field: 'UpdateType',
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Modified Fields',
                    field: 'ModifiedFields',
                    sortField: 'ModifiedFields',
                    rendererComponent: AuditModifiedFieldComponent,
                    exportFormatter: this.cellFormatterService.auditModifiedFieldsExportFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Edit Post Completion',
                    field: 'IsEditPostCompletion',
                    cellClass: (params: any) => {
                        const row = params.data;
                        const classes: string[] = [];
                        if (!row) {
                            return classes;
                        }
                        if ((row.IsEditPostCompletion && row.IsEditPostCompletion.toLowerCase() === 'true')
                            || (row.UpdateType && row.UpdateType.toLowerCase() === 'deleted')) {
                            classes.push('deleted-type'); // Make red if IsEditPostCompletion is true
                        }
                        return classes;
                    }
                },
                {
                    displayName: 'Date',
                    field: 'ModifiedDate',
                    formatter: this.cellFormatterService.dateTimeUTCFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Modified By',
                    field: 'ModifiedBy',
                    formatter: this.cellFormatterService.userNameFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: this.translationService.translate('job') + ' Name',
                    field: 'JobName',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('JobName', (this.translationService.translate('Job') + 'Name')),
                    cellClass: AuditClassFactory.createCellClass((this.translationService.translate('Job') + 'Name').toLowerCase()),
                },
                {
                    displayName: this.translationService.translate('Study'),
                    field: 'StudyName',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('StudyName', (this.translationService.translate('Study') + 'Name')),
                    cellClass: AuditClassFactory.createCellClass((this.translationService.translate('Study') + 'Name').toLowerCase()),
                },
                {
                    displayName: 'Protocol',
                    field: 'ProtocolName',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('ProtocolName', 'ProtocolName'),
                    cellClass: AuditClassFactory.createCellClass('protocolname'),
                },
                {
                    displayName: 'Task Name',
                    field: 'TaskName',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('TaskName', 'TaskName'),
                    cellClass: AuditClassFactory.createCellClass('taskname'),
                },
                {
                    displayName: 'Task',
                    field: 'Task',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('Task', 'Task'),
                    cellClass: AuditClassFactory.createCellClass('task'),
                },
                {
                    displayName: 'Due Date and Time',
                    field: 'DueDate',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('DueDate', 'DueDate', this.cellFormatterService.dateTimeUTCFormatter),
                    cellClass: AuditClassFactory.createCellClass('duedate'),
                },
                {
                    displayName: 'Duration (minutes)',
                    field: 'DurationMinutes',
                    visible: false,
                    formatter: this.cellFormatterService.createAuditColumnFormatter('DurationMinutes', 'DurationMinutes'),
                    cellClass: AuditClassFactory.createCellClass('durationminutes'),
                },
                {
                    displayName: 'Location',
                    field: 'Location',
                    visible: false,
                    formatter: this.cellFormatterService.createAuditColumnFormatter('Location', 'Location'),
                    cellClass: AuditClassFactory.createCellClass('location'),
                },
                {
                    displayName: 'Allowance',
                    field: 'Allowance',
                    visible: false,
                    formatter: this.cellFormatterService.createAuditColumnFormatter('Allowance', 'Allowance'),
                    cellClass: AuditClassFactory.createCellClass('allowance'),
                },
                {
                    displayName: 'Status',
                    field: 'TaskStatus',
                    visible: false,
                    formatter: this.cellFormatterService.createAuditColumnFormatter('TaskStatus', 'TaskStatus'),
                    cellClass: AuditClassFactory.createCellClass('taskstatus'),
                },
                {
                    displayName: 'Data Lock',
                    field: 'DataLock',
                    visible: false,
                    formatter: this.cellFormatterService.createAuditColumnFormatter('DataLock', 'DataLock'),
                    cellClass: AuditClassFactory.createCellClass('datalock'),
                },
                {
                    displayName: 'Assigned To',
                    field: 'AssignedTo',
                    visible: false,
                    formatter: this.cellFormatterService.createAuditColumnFormatter('AssignedTo', 'AssignedTo'),
                    cellClass: AuditClassFactory.createCellClass('assignedto'),
                },
                {
                    displayName: 'Created By',
                    field: 'CreatedBy',
                    formatter: this.cellFormatterService.userNameFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Created Date',
                    field: 'DateCreated',
                    formatter: this.cellFormatterService.dateTimeUTCFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                }
            ]
        };
    }
}

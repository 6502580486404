import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import escapeStringRegexp from "escape-string-regexp";
import { DateTime } from "luxon";
import { Subscription } from "rxjs";
import { BaseDetail, BaseDetailService, FacetView, IFacet } from "../../../common/facet";
import {
        cv_DataType,
        cv_IACUCProtocol,
        cv_JobCharacteristicLinkType,
        cv_JobCharacteristicType,
        cv_JobSubtype,
        cv_JobType,
        cv_TestArticle,
        EnumerationItem,
        JobCharacteristic,
      } from "../../../common/types";
import { JobCharacteristicIACUCProtocol } from "../../../common/types/models/job-characteristic-iacuc-protcol.interface";
import { JobCharacteristicJobSubtype } from "../../../common/types/models/job-characteristic-job-subtype.interface";
import { JobCharacteristicJobType } from "../../../common/types/models/job-characteristic-job-type.interface";
import { getSafeProp } from "@common/util";
import { DateFormatterService } from "@common/util/date-time-formatting";
import { EnumerationService } from "../../../enumerations/enumeration.service";
import { DataManagerService } from "../../../services/data-manager.service";
import { FeatureFlagService } from "../../../services/feature-flags.service";
import { IValidatable, SaveChangesService } from "../../../services/save-changes.service";
import { TranslationService } from "../../../services/translation.service";
import { CharacteristicVocabService } from "../../characteristic-vocab.service";
import { CharacteristicService } from "../../characteristic.service";
import type { EditResult } from "../../characteristics.interface";

@Component({
    selector: "job-characteristic-edit",
    templateUrl: "./job-characteristic-edit.component.html"
})
export class JobCharacteristicEditComponent extends BaseDetail implements OnInit, OnDestroy, IValidatable {
    readonly IACUC_PROTOCOL = "IACUC Protocol";
    readonly JOB_SUBTYPE = "Job Subtype";
    readonly JOB_TYPE = "Job Type";

    readonly JOB_TYPE_ID = "1";
    readonly IACUC_PROTOCOL_ID = "2";

    @Input() facet: IFacet;
    @Input() facetView: FacetView;
    @Input() facetPrivilege: string;
    @Input() jobCharacteristic: JobCharacteristic;

    @Output() editEnd: EventEmitter<EditResult> = new EventEmitter<EditResult>();

    dataTypes: cv_DataType[];

    jobTypes: cv_JobType[] = [];
    jobSubtypes: cv_JobSubtype[] = [];
    iacucProtocols: cv_IACUCProtocol[] = [];

    selectedJobTypes: number[] = [];
    selectedJobSubtypes: number[] = [];
    selectedIACUCProtocols: number[] = [];

    isCRO = false;
    isCRL = false;

    jobCharacteristicLinkType: string;
    jobCharacteristicLinkTypes: cv_JobCharacteristicLinkType[];

    jobNameTranslated: string;

    jobCharacteristicTypes: cv_JobCharacteristicType[];

    isDefaultShown: boolean;
    enumerationItems: EnumerationItem[];
    vocabularyItems: cv_IACUCProtocol[] | cv_TestArticle[];
    defaultValue: any;

    editResult: EditResult = {
        isCanceled: false,
        isSaved: false,
    };

    subscriptions: Subscription = new Subscription();

    constructor(
        baseDetailService: BaseDetailService,
        private _characteristicService: CharacteristicService,
        private _characteristicVocabService: CharacteristicVocabService,
        private _dataManagerService: DataManagerService,
        private _dateFormatterService: DateFormatterService,
        private _enumerationService: EnumerationService,
        private _featureFlagService: FeatureFlagService,
        private _saveChangesService: SaveChangesService,
        private _translationService: TranslationService
    ) {
        super(baseDetailService);
    }

    ngOnInit() {
        this._saveChangesService.registerValidator(this);

        this._characteristicVocabService.getDataTypes().then((results: any[]) => {
            this.dataTypes = results.filter((item: cv_DataType) => item.ShowInCharacteristics || item.DataType.toLowerCase() === 'vocabulary');
        });

        this.isCRL = this._featureFlagService.getIsCRL();
        this.isCRO = this._featureFlagService.getIsCRO();
        this.setSelectedValues();

        this._characteristicVocabService.getJobTypes().then((results: cv_JobType[]) => this.jobTypes = results.filter((cv: cv_JobType) =>
            cv.IsActive || this.selectedJobTypes.includes(cv.C_JobType_key)));
        this._characteristicVocabService.getJobSubtypes().then((results: cv_JobSubtype[]) => this.jobSubtypes = results.filter((cv: cv_JobSubtype) =>
            cv.IsActive || this.selectedJobSubtypes.includes(cv.C_JobSubtype_key)));
        this._characteristicVocabService.getIACUCProtocols().then((results: cv_IACUCProtocol[]) => this.iacucProtocols = results.filter((cv: cv_IACUCProtocol) =>
            cv.IsActive || this.selectedIACUCProtocols.includes(cv.C_IACUCProtocol_key)));

        this._characteristicVocabService.getJobCharacteristicTypes().then((results: cv_JobCharacteristicType[]) => this.jobCharacteristicTypes = results);
        this._characteristicVocabService.getJobCharacteristicLinkTypes().then((results: cv_JobCharacteristicLinkType[]) => this.jobCharacteristicLinkTypes = results);

        this.initJobCharacteristicLinkType();
        this.setSelectedValues();
        this.setVocabularyItems();
        this.setEnumerationItems();

        this.convertDefaultValue();

        this.jobNameTranslated = this._translationService.getTranslatedJobName();

        const subscription = this._saveChangesService.saveSuccessful$.subscribe(() => {
            this.editResult.isSaved = true;
        });
        this.subscriptions.add(subscription);
    }

    initJobCharacteristicLinkType(): void {
        if (this.jobCharacteristic.C_JobCharacteristicLinkType_key) {
            this.setJobCharacteristicLinkType(this.jobCharacteristic.C_JobCharacteristicLinkType_key.toString());
        }
    }

    setJobCharacteristicLinkType(linkType: string): void {
        if (linkType === this.IACUC_PROTOCOL_ID) {
            this.jobCharacteristicLinkType = this.IACUC_PROTOCOL;
        } else if (linkType === this.JOB_TYPE_ID && this.isCRO) {
            this.jobCharacteristicLinkType = this.JOB_SUBTYPE;
        } else if (linkType === this.JOB_TYPE_ID && !this.isCRO) {
            this.jobCharacteristicLinkType = this.JOB_TYPE;
        }
    }

    setSelectedValues(): void {
        this.selectedIACUCProtocols = this.jobCharacteristic.JobCharacteristicIACUCProtocol.map((jcip: JobCharacteristicIACUCProtocol) => jcip.C_IACUCProtocol_key);
        this.selectedJobSubtypes = this.jobCharacteristic.JobCharacteristicJobSubtype.map((jcjs: JobCharacteristicJobSubtype) => jcjs.C_JobSubtype_key);
        this.selectedJobTypes = this.jobCharacteristic.JobCharacteristicJobType.map((jcjt: JobCharacteristicJobType) => jcjt.C_JobType_key);

        this.jobCharacteristic.HasIACUCProtocol = this.selectedIACUCProtocols.length > 0;
        this.jobCharacteristic.HasJobSubtype = this.selectedJobSubtypes.length > 0;
        this.jobCharacteristic.HasJobType = this.selectedJobTypes.length > 0;
    }

    onJobSubtypeChange(jobSubtypeKeys: number[]): void {
        const initialJobCharacteristicJobSubtypes = this.jobCharacteristic.JobCharacteristicJobSubtype;
        const initialJobSubtypeKeys = initialJobCharacteristicJobSubtypes.map((jcjs: JobCharacteristicJobSubtype) => jcjs.cv_JobSubtype.C_JobSubtype_key);
        const initialValues: any = [];

        for (const key of jobSubtypeKeys) {
            if (!initialJobSubtypeKeys.includes(key)) {
                initialValues.push({
                    C_JobSubtype_key: key,
                    C_JobCharacteristic_key: this.jobCharacteristic.C_JobCharacteristic_key
                });      
            }
        }

        const toDelete = initialJobCharacteristicJobSubtypes.filter((jcjs: JobCharacteristicJobSubtype) => {
            return !jobSubtypeKeys.includes(jcjs.C_JobSubtype_key);
        });

        for (const e of toDelete) {
            this._dataManagerService.deleteEntity(e);
        }

        for (const value of initialValues) {
            this._dataManagerService.createEntity("JobCharacteristicJobSubtype", value);
        }
        this.jobCharacteristic.HasJobSubtype = this.selectedJobSubtypes.length > 0;

        const selectedJobSubtypes = this.jobCharacteristic.JobCharacteristicJobSubtype.map((jcjs: JobCharacteristicJobSubtype) => jcjs.cv_JobSubtype.JobSubtype);
        this.jobCharacteristic.JobSubtypes = selectedJobSubtypes.join(', ');
    }


    onIACUCProtocolChange(iacucProtocolKeys: number[]): void {
        const initialJobCharacteristicIACUCProtocols = this.jobCharacteristic.JobCharacteristicIACUCProtocol;
        const initialJobIACUCProtocolKeys = initialJobCharacteristicIACUCProtocols.map((jcip: JobCharacteristicIACUCProtocol) => jcip.cv_IACUCProtocol.C_IACUCProtocol_key);
        const initialValues: any = [];

        for (const key of iacucProtocolKeys) {
            if (!initialJobIACUCProtocolKeys.includes(key)) {
                initialValues.push({
                    C_IACUCProtocol_key: key,
                    C_JobCharacteristic_key: this.jobCharacteristic.C_JobCharacteristic_key
                });
            }
        }

        const toDelete = initialJobCharacteristicIACUCProtocols.filter((jcip: JobCharacteristicIACUCProtocol) => {
            return !iacucProtocolKeys.includes(jcip.C_IACUCProtocol_key);
        });

        for (const e of toDelete) {
            this._dataManagerService.deleteEntity(e);
        }

        for (const value of initialValues) {
            this._dataManagerService.createEntity("JobCharacteristicIACUCProtocol", value);
        }
        this.jobCharacteristic.HasIACUCProtocol = this.selectedIACUCProtocols.length > 0;

        const selectedIACUCProtocols = this.jobCharacteristic.JobCharacteristicIACUCProtocol.map((jci: JobCharacteristicIACUCProtocol) => jci.cv_IACUCProtocol.IACUCProtocol);
        this.jobCharacteristic.IACUCProtocols = selectedIACUCProtocols.join(', ');
    }

    onJobTypeChange(jobTypeKeys: number[]): void {
        const initialJobCharacteristicJobTypes = this.jobCharacteristic.JobCharacteristicJobType;
        const initialJobTypeKeys = initialJobCharacteristicJobTypes.map((jcjt: JobCharacteristicJobType) => jcjt.cv_JobType.C_JobType_key);
        const initialValues: any = [];

        for (const key of jobTypeKeys) {
            if (!initialJobTypeKeys.includes(key)) {
                initialValues.push({
                    C_JobType_key: key,
                    C_JobCharacteristic_key: this.jobCharacteristic.C_JobCharacteristic_key
                });
            }
        }

        const toDelete = initialJobCharacteristicJobTypes.filter((jcjt: JobCharacteristicJobType) => {
            return !jobTypeKeys.includes(jcjt.C_JobType_key);
        });

        for (const e of toDelete) {
            this._dataManagerService.deleteEntity(e);
        }

        for (const value of initialValues) {
            this._dataManagerService.createEntity("JobCharacteristicJobType", value);
        }
        this.jobCharacteristic.HasJobType = this.selectedJobTypes.length > 0;

        const selectedJobTypes = this.jobCharacteristic.JobCharacteristicJobType.map((jcjt: JobCharacteristicJobType) => jcjt.cv_JobType.JobType);
        this.jobCharacteristic.JobTypes = selectedJobTypes.join(', ');
    }

    // if a job's characteristic link type is changed, delete all characteristic links. 
    onJobCharacteristicLinkTypeChange(jobCharacteristicLinkType: string) {
        this.setJobCharacteristicLinkType(jobCharacteristicLinkType.toString());
        while (this.jobCharacteristic.JobCharacteristicIACUCProtocol && this.jobCharacteristic.JobCharacteristicIACUCProtocol.length > 0) {
            this._dataManagerService.deleteEntity(this.jobCharacteristic.JobCharacteristicIACUCProtocol[0]);
        }
        while (this.jobCharacteristic.JobCharacteristicJobSubtype && this.jobCharacteristic.JobCharacteristicJobSubtype.length > 0) {
            this._dataManagerService.deleteEntity(this.jobCharacteristic.JobCharacteristicJobSubtype[0]);
        }
        while (this.jobCharacteristic.JobCharacteristicJobType && this.jobCharacteristic.JobCharacteristicJobType.length > 0) {
            this._dataManagerService.deleteEntity(this.jobCharacteristic.JobCharacteristicJobType[0]);
        }
        this.jobCharacteristic.HasIACUCProtocol = false;
        this.jobCharacteristic.HasJobSubtype = false;
        this.jobCharacteristic.HasJobType = false;
    }

    ngOnDestroy() {
        this._saveChangesService.unregisterValidator(this);
        this.subscriptions.unsubscribe();
    }

    onSaveCharacteristic(): void {
        this._saveChangesService.saveChanges(this.facet.FacetName, true);
    }

    async validate(): Promise<string> {
        if (this.jobCharacteristicLinkType === this.IACUC_PROTOCOL && this.selectedIACUCProtocols.length === 0) {
            return "IACUC Protocol is required";
        }
        if (this.jobCharacteristicLinkType === this.JOB_TYPE && this.selectedJobTypes.length === 0) {
            return "Job Type is required";
        }
        if (this.jobCharacteristicLinkType === this.JOB_SUBTYPE && this.selectedJobSubtypes.length === 0) {
            return "Job Subtype is required";
        }

        return this._characteristicService.validateCommonCharacteristic(this.jobCharacteristic);
    }

    onCancel(): void {
        this.editResult.isCanceled = true;
        this.editEnd.emit(this.editResult);
    }

    updateDefaultShown(): void {
        this.isDefaultShown = this.jobCharacteristic.C_DataType_key &&
            ['Number', 'Enumeration', 'Long Enumeration', 'Long Text', 'Text', 'Date', 'Boolean', 'Vocabulary', 'DateTime'].includes(this.jobCharacteristic.cv_DataType.DataType);
    }

    setEnumerationItems(): void {
        this.enumerationItems = [];
        const classKey = this.jobCharacteristic.C_EnumerationClass_key;
        if (classKey) {
            this._enumerationService.getEnumerationItems(classKey)
                .then((items: EnumerationItem[]) => {
                    this.enumerationItems = items.sort((a, b) => {
                        const aKey = a.C_EnumerationItem_key;
                        const bKey = b.C_EnumerationItem_key;
                        if (aKey < bKey) {
                            return 1;
                        }
                        if (aKey > bKey) {
                            return -1;
                        }
                        return 0;
                    });
                });
        }
    }

    setVocabularyItems(): void {
        this.vocabularyItems = [];
        // TODO: "VocabularyClass" is not an actual database entry and should not be structured like a join. 
        const classKey = this.jobCharacteristic.C_VocabularyClass_key;
        if (classKey === 1) {
            this.getTestArticles();
        } else {
            this.getIACUCProtocols();
        }
    }

    getTestArticles(): Promise<void> {
        return this._characteristicVocabService.getTestArticles().then((items: cv_TestArticle[]) => {
            items = items.filter((testArticle: cv_TestArticle) => testArticle.IsActive);
            items.forEach((item: any) => {
                item.ItemName = item.TestArticle;
                item.C_VocabularyItem_key = item.C_TestArticle_key;
            });
            this.vocabularyItems = items;
        });
    }

    getIACUCProtocols(): Promise<void> {
        return this._characteristicVocabService.getIACUCProtocols().then((items: cv_IACUCProtocol[]) => {
            items = items.filter((iacucProtocol: cv_IACUCProtocol) => iacucProtocol.IsActive);

            items.forEach((item: any) => {
                item.ItemName = item.IACUCProtocol;
                item.C_VocabularyItem_key = item.C_IACUCProtocol_key;
            });
            this.vocabularyItems = items;
        });
    }

    searchEnumerations = (search: string): Promise<any> => {
        const escapedSearch = escapeStringRegexp(search);
        const regex = new RegExp(escapedSearch, 'gi');
        const matches = this.enumerationItems.filter((item) => {
            return regex.test(item.ItemName);
        });
        return Promise.resolve(matches);
    }

    searchEnumerationsForExactMatch = (search: string): Promise<any> => {
        const matches = this.enumerationItems.filter((item) => {
            return item.ItemName === search;
        });
        return Promise.resolve(matches);
    }

    enumFormatter = (value: any) => {
        return value.ItemName;
    }

    // TODO: The conversion of string values logic should be moved to a separate service
    convertDefaultValue() {
        let convertedModel: any = null;

        if (this.jobCharacteristic.DefaultValue) {
            convertedModel = this.jobCharacteristic.DefaultValue;

            switch (getSafeProp(this.jobCharacteristic, 'cv_DataType.DataType')) {
                case 'Boolean':
                    convertedModel = this.convertBoolean(convertedModel);
                    break;
                case 'Date':
                    convertedModel = this.convertDateString(convertedModel);
                    break;
                case 'DateTime':
                    convertedModel = this.convertDateTimeString(convertedModel);
            }
        }

        this.defaultValue = convertedModel;
    }

    convertBoolean(value: string): boolean {
        return value === 'true' ? true : false;
    }

    convertDateString(value: string): Date {
        try {
            return DateTime.fromFormat(value, this._dateFormatterService.resolveDateFormat()).toJSDate();
        } catch {
            return null;
        }
    }

    convertDateTimeString(value: string): Date {
        try {
            return DateTime.fromFormat(value, this._dateFormatterService.resolveDateTimeFormat(false, false)).toJSDate();
        } catch {
            return null;
        }
    }

    // TODO: the formatting of data types to string should be moved to a separate service. 
    formatValue() {
        let newValue = this.defaultValue;

        switch (getSafeProp(this.jobCharacteristic, 'cv_DataType.DataType')) {
            case 'Boolean':
                newValue = this.formatBoolean(newValue);
                break;
            case 'Date':
                newValue = this.formatDateString(newValue);
                break;
            case 'DateTime':
                newValue = this.formatDateTimeString(newValue);
                break;
        }

        this.jobCharacteristic.DefaultValue = newValue;
    }

    formatBoolean(value: boolean): string {
        return value ? 'true' : 'false';
    }

    formatDateString(value: Date): string {
        return this._dateFormatterService.formatDateOnly(value);
    }

    formatDateTimeString(value: Date): string {
        return this._dateFormatterService.formatDateTime(value);
    }

    jobCharacteristicLinkTypeFormatter = (d: any) => {
        const type = getSafeProp(d, 'JobCharacteristicLinkType');
        if (type === 'Job Type') {
            return this.isCRO ? this._translationService.translate('Job') + ' Subtype' : this._translationService.translate('Job') + ' Type';
        }
        return type;
    }

    jobCharacteristicLinkTypeKeyFormatter = (d: any) => getSafeProp(d, 'C_JobCharacteristicLinkType_key');

    jobCharacteristicTypeFormatter = (d: any) => getSafeProp(d, 'JobCharacteristicType');
    jobCharacteristicTypeKeyFormatter = (d: any) => getSafeProp(d, 'C_JobCharacteristicType_key');


}

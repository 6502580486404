<div class="modal-header justify-content-center">
    <h4 class="modal-title">Print Labels</h4>
</div>
<div class="modal-body">
    <form>

        <div *ngIf="noSamplesSelected" class="alert alert-warning">
            <p>
                <i class="fa fa-exclamation-triangle"></i>
                Please select at least one sample.
            </p>
        </div>

        <div class="form-group row"
             *ngIf="!noSamplesSelected">
            <label class="col-md-4 col-form-label">Label Type</label>
            <div class="col-md-6">
                <select name="reportType" [(ngModel)]="reportType"
                        class="form-control input-medium"
                >
                    <option *ngFor="let type of reportTypes" [value]="type.key">
                        {{type.display}}
                    </option>
                </select>
            </div>
        </div>

        <div class="modal-footer">
            <button type="button" class="btn btn-fw btn-lg btn-primary" (click)="printClicked()"
                     [disabled]="noSamplesSelected">
                Print
            </button>
            <button type="button" class="btn btn-fw btn-lg btn-secondary" (click)="cancelClicked()">
                Cancel
            </button>
        </div>
    </form>
</div>

export interface FacetDimensions {
  x: number;
  y: number;
  w: number;
  h: number;
}

export interface Facet extends FacetDimensions {
  id: string;
  componentId: string;
  type: 'old' | 'modern'
  collapsedDimensions: FacetDimensions;
  expanded: boolean;
  manually: boolean;
  facet: unknown;
}

export const FACET_DEFAULT_W = 3;
export const FACET_DEFAULT_H = 3;
export const FACET_FULL_W = 6;
export const FACET_HALF_W = FACET_FULL_W / 2;
export const FACET_FULL_H = 4;
export const FACET_HALF_H = FACET_FULL_H / 2;

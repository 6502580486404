import { Entity, EntityState, EntityStateSymbol } from 'breeze-client';

export function testBreezeIsNew(obj: Entity): boolean {
    return testBreezeState(obj, EntityState.Added);
}

export function testBreezeIsDeleted(obj: Entity): boolean {
    return testBreezeState(obj, EntityState.Deleted) ||
        testBreezeState(obj, EntityState.Detached);
}


export function testBreezeState(obj: Entity, state: EntityStateSymbol): boolean {
    if (obj &&
        obj.entityAspect
    ) {
        const entityState = obj.entityAspect.entityState;
        const stateMatches = entityState === state;
        return (entityState || false) && stateMatches;
    }
    return false;
}

import { FacetSharedModule } from './../common/facet/facet-shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { ClimbCommonModule } from '../common/climb-common.module';
import { ClimbServicesModule } from '../services/climb-services.module';
import { WorkspacesSharedModule } from '../workspaces/workspaces-shared.module';

import { EnumerationsSharedModule } from './enumerations-shared.module';
import { EnumerationsFacetComponent } from './enumerations-facet.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgbModule,
        ClimbCommonModule,
        ClimbServicesModule,
        FacetSharedModule,
        EnumerationsSharedModule,
        WorkspacesSharedModule,
    ],
    declarations: [
        EnumerationsFacetComponent,
    ],
    exports: [
        EnumerationsFacetComponent,
    ],
})
export class EnumerationsModule { }

<form role="form" class="form-label-right">
    <div class="modal-header justify-content-center">
        <h4 class="modal-title">Tasks Filter</h4>
    </div>

    <div class="modal-body">

        <div class="form-group row">
            <label class="col-md-3 col-form-label">Name</label>
            <div class="col-md-9">
                <input 
                    type="text"
                    [(ngModel)]="filter.TaskName"
                    name="taskName"
                    class="form-control input-medium"
                    data-auto-focus="taskName" />
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-3 col-form-label">Type</label>
            <div class="col-md-9">
                <active-vocab-select 
                    [(model)]="filter.C_TaskType_key"
                    [vocabChoices]="taskTypes"
                    [keyFormatter]="taskTypeKeyFormatter"
                    [optionFormatter]="taskTypeFormatter"
                    [id]="'taskTypeFilterSelect'"
                    [required]="false"
                    [nullable]="true">
                </active-vocab-select>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-3 col-form-label">{{'Protocol' | translate}}</label>
            <div class="col-md-9">
                <input 
                    type="text"
                    [(ngModel)]="filter.Protocol"
                    name="taskProtocol"
                    class="form-control input-medium" />
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-3 col-form-label">Input</label>
            <div class="col-md-9">
                <input 
                    type="text"
                    [(ngModel)]="filter.Input"
                    name="taskInput"
                    class="form-control input-medium" />
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-3 col-form-label">Output</label>
            <div class="col-md-9">
                <input 
                    type="text"
                    [(ngModel)]="filter.Output"
                    name="taskOutput"
                    class="form-control input-medium" />
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-3 col-form-label">Active</label>
            <div class="col-md-9">
                <active-status-select 
                    [(model)]="filter.IsActive"
                    [nullable]="true">
                </active-status-select>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-3 col-form-label">Effort</label>
            <div class="col-md-9">
                <input 
                    type="number"
                    name="taskEffort"
                    [(ngModel)]="filter.Effort"
                    step="any"
                    min="0"
                    class="form-control input-medium" />
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-3 col-form-label">Created By</label>
            <div class="col-md-9">
                <user-select 
                    [(model)]="filter.CreatedBy"
                    [forceValidSelection]="false">
                </user-select>
            </div>
        </div>

    </div><!-- /.modal-body -->

    <div class="modal-footer">
        <span class="btn-set">
            <button type="submit"
                    class="btn btn-fw-wide btn-lg btn-primary"
                    (click)="filterClicked($event)">
                Filter
            </button>
            <button data-automation-id="clear-filter-button"
                    type="button"
                    class="btn btn-fw-wide btn-lg btn-secondary"
                    (click)="clearClicked()">
                Clear Filter
            </button>
        </span>
        <span class="btn-set">
            <button type="button"
                    class="btn btn-lg btn-secondary"
                    (click)="closeClicked()">
                Close
            </button>
        </span>
    </div>
</form>

import { Directive, Input } from "@angular/core";
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from "@angular/forms";
import { JobGroup } from "@common/types";
import { makeUniqueGroupMap } from "../../utils/validators";

@Directive({
  selector:
  '[groupValidate][ngModel], [groupValidate][formControl], [groupValidate][formControlName]',
  providers: [
      {
          provide: NG_VALIDATORS,
          useExisting: JobGroupValidator,
          multi: true
      }
  ]
})
export class JobGroupValidator implements Validator {
  @Input() groups: JobGroup[];
  @Input() groupKey: number;
  @Input() name: string;

  validate(control: AbstractControl): ValidationErrors | null {
    const filtered = this.groups.filter(group => group.C_JobGroup_key !== this.groupKey);
    const unique = makeUniqueGroupMap(filtered);
    const value = control.value;
    const valid = Boolean(value && !unique[control.value]);

    return valid ? null : { [this.name]: valid };
  }
}

import { Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";

import { MaterialPoolService } from "@services/material-pool.service";

import { IFacet } from "@common/facet";
import { Animal, CohortMaterial } from "@common/types";
import { CohortHousingOptions } from "../cohort-split-table/cohort-split-table-templates.component";
import { CreateHousingComponent } from "../../../housing/components/create-housing/create-housing.component";
import { HousingOption } from "../../../animals/bulkedit/confirm-housing-modal.component";
import { empty } from "@common/util";

@Component({
    selector: 'cohort-housing',
    templateUrl: './cohort-housing.component.html'
})
export class CohortHousingComponent {
    @Input() facet: IFacet;
    @Input() cohortMaterials: CohortMaterial[];
    @Input() options: CohortHousingOptions;

    @Output() change = new EventEmitter<void>();

    existingMaterialPoolKey: number;
    isHoused = false;

    @ViewChild('createHousing') createHousing: CreateHousingComponent;

    constructor(
        private materialPoolService: MaterialPoolService
    ) {}
    
    async houseClicked() {
        let success: boolean;
        if (this.options.new) {
            success = await this.createHousing.tryToCreateHousing();
        } else {
            success = await this.tryToAddToExistingHousing();
        }

        if (success) {
            this.isHoused = true;
        }
    }

    async tryToAddToExistingHousing() {
        let animalsToHouse = this.cohortMaterials.map(cm => cm.Material).map(m => m.Animal);
        const housedAnimals = this.materialPoolService.findAnimalsWithHousing(animalsToHouse);
        if (housedAnimals.length > 0) {
            const result = await this.materialPoolService.openConfirmHousingModal(housedAnimals);
            switch (result) {
                case HousingOption.Move:
                    return this.addToExistingHousing(animalsToHouse);
                case HousingOption.Skip:
                    animalsToHouse = animalsToHouse.filter(a => !housedAnimals.includes(a));
                    return this.addToExistingHousing(animalsToHouse);
                case HousingOption.Cancel:
                default:
                    console.log('Save cancelled');
                    return false;
            }        
        } else {    
            return this.addToExistingHousing(animalsToHouse);
        }
    }

    private addToExistingHousing(animals: Animal[]) {
        if (!this.existingMaterialPoolKey || !animals.length) {
            return false;
        }

        for (const animal of animals) {
            const initialValues = {
                C_MaterialPool_key: this.existingMaterialPoolKey,
                C_Material_key: animal.C_Material_key
            };
            this.materialPoolService.createMaterialPoolMaterial(initialValues);
        }

        return true;
    }

    onChange() {
        this.change.emit();
    }

    isDisabled() {
        // If housing button was already clicked
        if (this.isHoused) {
            return true;
        } 

        // If auto-naming is disabled and any unit has an invalid ID
        if (
            this.options.new 
            && !this.options.autoNaming
            && !this.createHousing?.isNamingValid
        ) {
            return true;
        }

        // If auto-naming is disabled, input has been touched, and is not unique
        if (
            this.options.new 
            && !this.options.autoNaming 
            && this.createHousing.materialPoolIDInput.dirty
            && this.createHousing.materialPoolIDInput.errors?.unique
        ) {
            return true;
        }

        // If housing units is empty
        if (this.options.new && empty!(this.createHousing?.units)) {
            return true;
        }

        // If materials per unit is less than or equal to 0
        if (this.options.new && this.createHousing?.materialsPerUnit <= 0) {
            return true;
        }

        // If total count between units is less than or equal to 0
        if (this.options.new && this.createHousing?.totalCount <= 0) {
            return true;
        }

        // If no existing housing option is selected
        if (!this.options.new && !this.existingMaterialPoolKey){
            return true;
        }

        return false;
    }
}

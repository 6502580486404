import {
    Component
} from "@angular/core";
import { ICellRendererParams } from "ag-grid-community";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { plus } from '@icons';

@Component({
    selector: 'housing-cell',
    template: `
        <button *ngIf="params"
                climbButton
                size="sm"
                variant="ghost"
                (click)="clicked()">
            <svg [climbIcon]="icons.plus" title="Add birth in mating"></svg>
        </button>
        <span>{{ params.value }}</span>
    `,
    styleUrls: ['./birth-housing-cell-renderer.component.scss'],
})
export class BirthHousingCellRendererComponent implements ICellRendererAngularComp {
    readonly icons = { plus };

    public params: ICellRendererParams;

    agInit(params: ICellRendererParams): void {
        this.params = params;
    }

    /**
     * Required by ICellRenderedAngularComp.
     *   Called on cell refresh by ag-grid
     * @param params
     */
    refresh(params: any): boolean {
        this.params = params;
        return true;
    }

    clicked() {
        // call outputClick
        // this.params.data = Entity
        // this.params.value = formatted field value
        const colDef: any = this.params.colDef;
        colDef.outputClick(this.params.data);
    }
}

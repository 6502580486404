import type { Input } from './input.interface';
import type { Output } from './output.interface';
import type { SampleCharacteristic } from './sample-characteristic.interface';
import type { TaxonCharacteristic } from './taxon-characteristic.interface';
import type { DataType } from '../../../data-type';

export interface cv_DataType {
    C_DataType_key: number;
    C_VocabularySource_key: number | null;
    C_Workgroup_key: number;
    CreatedBy: string;
    DataType: DataType;
    DateCreated: Date;
    DateModified: Date;
    ElementID: string;
    Input: Input[];
    IsActive: boolean;
    IsDefault: boolean;
    IsDeprecated: boolean;
    ModifiedBy: string;
    Output: Output[];
    SampleCharacteristic: SampleCharacteristic[];
    ShowInCharacteristics: boolean;
    ShowInWorkflow: boolean;
    SortOrder: number | null;
    TaxonCharacteristic: TaxonCharacteristic[];
    Version: number;
}

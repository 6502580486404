import { moveItemInArray } from "@angular/cdk/drag-drop";

/**
 * Handles moving an element from one index to another. 
 * SortOrder is updated based on the SortOrder values of the original array before modifications took place.
 * Modifications are done in-place.
 * 
 * @param array Array to be modified in-place
 * @param fromIndex Index of element to be moved
 * @param toIndex Index to which element should be moved
 */
export function reorderAndUpdateRelativeSortOrder<T extends { SortOrder: number }>(array: T[], fromIndex: number, toIndex: number): void {
    if (typeof(array) === 'object' && array?.length > 0) {
        const originalSortOrders = array.map(a => a.SortOrder);
        moveItemInArray(array, fromIndex, toIndex);
        for (let i = 0; i < array.length; i++) {
            array[i].SortOrder = originalSortOrders[i];
        }
    }
}
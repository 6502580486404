import {
    Pipe,
    PipeTransform
} from '@angular/core';

interface AnimalComment {
    cv_AnimalCommentStatus?: {
        IsEndState: boolean;
        IsPermanent: boolean;
    };
}

/**
 * Returns a class corresponding to the Animal Comment Status value
 */
@Pipe({ name: 'animalCommentStatusStyle' })
export class AnimalCommentStatusStylePipe implements PipeTransform {
    transform(animalComment: AnimalComment): string {
        const status = animalComment.cv_AnimalCommentStatus;
        if (status) {
            if (status.IsEndState) {
                return 'italicized';
            } else if (status.IsPermanent) {
                return 'bolded';
            } else {
                return '';
            }
        }
    }
}

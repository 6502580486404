import type { Input } from './input.interface';
import type { Output } from './output.interface';

export interface cv_InputOutputUnit {
    C_InputOutputUnit_key: number;
    C_VocabularySource_key: number | null;
    C_Workgroup_key: number;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    Description: string;
    ElementID: string;
    Input: Input[];
    InputOutputUnit: string;
    IsActive: boolean;
    IsDefault: boolean;
    IsDeprecated: boolean;
    ModifiedBy: string;
    Output: Output[];
    SortOrder: number;
    Version: number;
}

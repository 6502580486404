<h4>
    <ng-container *ngIf="common.JobID">
        {{'Job' | translate}}&nbsp;Name:&nbsp;{{common.JobID}}
    </ng-container>
    <ng-container *ngIf="common.JobID && common.TaskAlias">
        &nbsp;—&nbsp;
    </ng-container>
    <ng-container *ngIf="common.TaskAlias">
        Task Name: &nbsp;{{common.TaskAlias}}
    </ng-container>
</h4>

<table class="table table-bordered workflow-bulk-data-entry">
    <thead>
        <tr>
            <!-- Job ID, if not common -->
            <th *ngIf="visible.JobID">{{'Job' | translate}} Name</th>
            <!-- Task Alias, if not common -->
            <th *ngIf="visible.TaskAlias">Task Name</th>
            <!-- Animal Marker -->
            <th *ngIf="visible.PhysicalMarker">Marker</th>
            <!-- Task Animals -->
            <th *ngIf="visible.AnimalName">Animal</th>
            <!-- Microchip ID -->
            <th *ngIf="visible.MicrochipIdentifier">Microchip ID</th>
            <!-- Alternate Physical ID -->
            <th *ngIf="visible.AlternatePhysicalID && isGLP">Alternate Physical ID</th>
            <!-- Scan -->
            <th *ngIf="visible.Scan">Scan</th>
            <!-- Animal Classification -->
            <th *ngIf="visible.AnimalClassification && isGLP">Animal Classification</th>
            <!-- Cohort -->
            <th *ngIf="visible.Cohort">Cohort</th>
            <ng-container *ngFor="let characteristic of taxonCharacteristicsInListView">
                <th *ngIf="visible[characteristic.C_TaxonCharacteristic_key]">
                    {{characteristic.CharacteristicName}}
                </th>
            </ng-container>
            <!-- Housing ID -->
            <th *ngIf="visible.HousingID">Housing ID</th>
            <!-- Task Samples -->
            <th *ngIf="visible.SampleName">Samples</th>
            <!-- Time Due : bulk fill (with increment) -->
            <th *ngIf="visible.DateDue || visible.TimeDue">Date/Time Due</th>
            <!-- Inputs: Loop over inputs -->
            <ng-container *ngIf="visible.Inputs">
                <ng-container *ngFor="let inputCol of inputColumns">
                    <th *ngIf="inputCol.visible" class="input">{{inputCol.label}}</th>
                </ng-container>
            </ng-container>

            <!-- Output : Loop over outputs -->
            <ng-container *ngIf="visible.Outputs">
                <th *ngIf="visible.OutputAnimal" class="output">Output Animal</th>
                <th *ngIf="visible.OutputSample" class="output">Output Sample</th>
                <!-- Sample Type -->
                <th *ngIf="visible.SampleType">Sample Type</th>
                <!-- Sample Status -->
                <th *ngIf="visible.SampleStatus">Sample Status</th>
                <!-- Sample Preservation Method -->
                <th *ngIf="visible.PreservationMethod">Sample Preservation Method</th>
                <!-- Sample Container Type -->
                <th *ngIf="visible.ContainerType">Sample Container Type</th>
                <ng-container *ngFor="let outputCol of outputColumns">
                    <th *ngIf="outputCol.visible" class="output">{{outputCol.label}}</th>
                </ng-container>
                <th *ngIf="visible.CollectedBy" class="output">Collected By</th>
                <th *ngIf="visible.CollectedDate || visible.CollectedTime" class="output">Collected Date/Time</th>
            </ng-container>
            <th *ngIf="visible.CompletedBy">Completed By</th>
            <th *ngIf="visible.CompletedDate || visible.CompletedTime">Completed Date/Time</th>
            <th *ngIf="visible.TaskStatus">Task Status</th>
            <th *ngIf="visible.AnimalStatus">Animal Status</th>
            <th *ngIf="visible.ExitReason">Exit Reason</th>
        </tr>
    </thead>

    <tbody>
        <ng-container *ngFor="let row of rows">
            <tr>
                <!-- Job ID, if not common -->
                <td *ngIf="visible.JobID"
                    [ngClass]="row.classes.task">
                    <ng-container *ngIf="row.taskFirst">
                        {{row.job?.JobID}}
                    </ng-container>
                </td>
                <!-- Task Alias, if not common -->
                <td *ngIf="visible.TaskAlias"
                    [ngClass]="row.classes.task">
                    <ng-container *ngIf="row.taskFirst">
                        {{row.task?.TaskAlias}}
                    </ng-container>
                </td>
                <!-- Animal Marker -->
                <td *ngIf="visible.PhysicalMarker"
                    [ngClass]="row.classes.animal">
                    <ng-container *ngIf="row.animalFirst">
                        <animal-marker *ngIf="row.animal"
                                       [marker]="row.animal.PhysicalMarker"
                                       [markerType]="row.animal.cv_PhysicalMarkerType"></animal-marker>
                    </ng-container>
                </td>
                <!-- Task Animal -->
                <td *ngIf="visible.AnimalName"
                    [ngClass]="row.classes.animal"
                    class="nowrap">
                    <ng-container *ngIf="row.animalFirst">
                        {{row.animal?.AnimalName}}
                    </ng-container>
                </td>
                <!-- Microchip ID -->
                <td *ngIf="visible.MicrochipIdentifier"
                    [ngClass]="row.classes.animal"
                    class="nowrap">
                    <ng-container *ngIf="row.animalFirst && row.animal">
                        {{row.animal.Material?.MicrochipIdentifier}}
                    </ng-container>
                </td>
                <!-- Alternate Physical ID -->
                <td *ngIf="visible.AlternatePhysicalID && isGLP"
                    [ngClass]="row.classes.animal"
                    class="nowrap">
                    <ng-container *ngIf="row.animalFirst && row.animal">
                        {{row.animal.AlternatePhysicalID}}
                    </ng-container>
                </td>
                <!-- Scan -->
                <td *ngIf="visible.Scan"
                    [ngClass]="row.classes.animal"
                    class="nowrap">
                    <ng-container *ngIf="row.animalFirst && row.animal">
                        {{row.scanMaterial?.ScanValue}}
                        <i *ngIf="row.scanMaterial?.ScanValue === null" class="fa fa-fw"></i>
                        <ng-container *ngIf="row.scanMaterial?.ScanValue !== null">
                            <i *ngIf="row.scanMaterial?.IsScanValid" class="fa fa-check text-success"></i>
                            <i *ngIf="!row.scanMaterial?.IsScanValid" class="fa fa-exclamation-triangle text-danger"></i>
                        </ng-container>
                    </ng-container>
                </td>
                <!-- Animal Classification -->
                <td *ngIf="visible.AnimalClassification && isGLP"
                    [ngClass]="row.classes.animal"
                    class="nowrap">
                    <ng-container *ngIf="row.animalFirst && row.animal">
                        {{row.animal.cv_AnimalClassification?.AnimalClassification}}
                    </ng-container>
                </td>
                <!-- Cohort -->
                <td *ngIf="visible.Cohort"
                    [ngClass]="row.classes.animal"
                    class="nowrap"></td>
                <!--List View Characteristics-->
                <ng-container *ngFor="let taxonCharacteristic of taxonCharacteristicsInListView">
                    <td *ngIf="visible[taxonCharacteristic.C_TaxonCharacteristic_key]" class="characteristic-column">
                        <ng-container *ngIf="row.characteristics[taxonCharacteristic.C_TaxonCharacteristic_key]">
                            {{row.characteristics[taxonCharacteristic.C_TaxonCharacteristic_key].CharacteristicValue}}
                        </ng-container>
                        <ng-container *ngIf="!row.characteristics[taxonCharacteristic.C_TaxonCharacteristic_key]">&nbsp;</ng-container>
                    </td>
                </ng-container>
                <!-- Housing ID -->
                <td *ngIf="visible.HousingID"
                    [ngClass]="row.classes.animal"
                    class="nowrap">
                    <ng-container *ngIf="row.animalFirst && row.animal">
                        {{row.animal.Material?.MaterialPoolMaterial | currentMaterialHousingID}}
                    </ng-container>
                </td>
                <!-- Task Sample -->
                <td *ngIf="visible.SampleName"
                    class="nowrap">
                    {{row.sample?.SampleName}}
                </td>
                <!-- Date/Time Due -->
                <td *ngIf="visible.DateDue || visible.TimeDue"
                    [ngClass]="row.classes.task">
                    <ng-container *ngIf="row.taskFirst">
                        {{row.task?.DateDue | formatShortDateOrTime}}
                    </ng-container>
                </td>

                <ng-container *ngIf="visible.Inputs">
                    <ng-container *ngFor="let inputCol of inputColumns">
                        <td *ngIf="inputCol.visible"
                            [ngClass]="row.classes.task">
                            <ng-container *ngIf="row.taskFirst && row.inputs[inputCol.key]">
                                <span *ngIf="row.inputs[inputCol.key].Input?.cv_DataType?.DataType === 'Date'">
                                    {{row.inputs[inputCol.key].InputValue | formatShortDate}}
                                </span>
                                <span *ngIf="row.inputs[inputCol.key].Input?.cv_DataType?.DataType !== 'Date'">
                                    {{row.inputs[inputCol.key].InputValue || '-'}}
                                </span>
                            </ng-container>
                        </td>
                    </ng-container>
                </ng-container>

                <!-- Outputs and Collected -->
                <ng-container *ngIf="visible.Outputs">
                    <td *ngIf="visible.OutputAnimal"
                        class="nowrap">
                        {{row.outputAnimal?.AnimalName}}
                    </td>
                    <td *ngIf="visible.OutputSample"
                        class="nowrap">
                        {{row.outputSample?.SampleName}}
                    </td>

                    <!-- Sample Type-->
                    <td *ngIf="visible.SampleType"
                        [ngClass]="row.classes.animal">
                        <ng-container *ngIf="row.sample">
                            {{row.sample?.cv_SampleType?.SampleType}}
                        </ng-container>
                    </td>

                    <!-- Sample Status-->
                    <td *ngIf="visible.SampleStatus"
                        [ngClass]="row.classes.animal">
                        <ng-container *ngIf="row.sample">
                            {{row.sample?.cv_SampleStatus?.SampleStatus}}
                        </ng-container>
                    </td>

                    <!-- Sample Preservation Method-->
                    <td *ngIf="visible.PreservationMethod"
                        [ngClass]="row.classes.animal">
                        <ng-container *ngIf="row.sample">
                            {{row.sample?.cv_PreservationMethod?.PreservationMethod}}
                        </ng-container>
                    </td>

                    <!-- Sample Container Type-->
                    <td *ngIf="visible.ContainerType"
                        [ngClass]="row.classes.animal">
                        <ng-container *ngIf="row.sample">
                            {{row.material?.cv_ContainerType?.ContainerType}}
                        </ng-container>
                    </td>

                    <!-- Outputs: loop over outputs -->
                    <ng-container *ngFor="let outputCol of outputColumns">
                        <td *ngIf="outputCol.visible"
                            class="output">
                            <ng-container *ngIf="row.taskOutputs[outputCol.key]">
                                {{row.taskOutputs[outputCol.key].OutputValue}}
                            </ng-container>
                        </td>
                    </ng-container>
                    <td *ngIf="visible.CollectedBy">
                        {{row.taskOutputSet?.Resource?.ResourceName}}
                    </td>
                    <td *ngIf="visible.CollectedDate || visible.CollectedTime">
                        {{row.taskOutputSet?.CollectionDateTime | formatShortDateOrTime}}
                    </td>
                </ng-container>
                <td *ngIf="visible.CompletedBy"
                    [ngClass]="row.classes.task">
                    {{row.task?.CompletedByResource?.ResourceName}}
                </td>
                <td *ngIf="visible.CompletedDate || visible.CompletedTime"
                    [ngClass]="row.classes.task">
                    {{row.task?.DateComplete | formatShortDateOrTime}}
                </td>

                <!-- Task Status -->
                <td *ngIf="visible.TaskStatus"
                    [ngClass]="row.classes.task">
                    {{row.task?.cv_TaskStatus?.TaskStatus}}
                </td>
                <!-- Animal Status -->
                <td *ngIf="visible.AnimalStatus"
                    [ngClass]="row.classes.animal">
                    <ng-container *ngIf="row.animalFirst">
                        {{row.animal?.cv_AnimalStatus?.AnimalStatus}}
                    </ng-container>
                </td>

                <!-- Animal Exit Reason -->
                <td *ngIf="visible.ExitReason"
                    [ngClass]="row.classes.animal">
                    <ng-container *ngIf="row.animalFirst">
                        {{row.animal?.cv_ExitReason?.ExitReason}}
                    </ng-container>
                </td>
            </tr>
        </ng-container>
    </tbody>
</table>

<div *ngIf="options | notEmpty" class="d-flex align-items-center">
    <ng-container *ngFor="let option of options">
        <div class="form-control mr-2 d-flex align-items-center">
            <input
                type="checkbox"
                [checked]="option.checked"
                (change)="onSelectionChange(option, $event)">
            <label class="mb-0 ml-1 text-nowrap">{{ option.label }}</label>
        </div>
    </ng-container>
</div>
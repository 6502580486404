import { from, Observable } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from '@services/toastr.service';
import { LogLevel } from '@services/models';
import { RoutingService } from '../../../routing/routing.service';
import { LoginService } from '../../services/login.service';

@Component({
  selector: 'climb-login-saml',
  template: '<ng-container *ngIf="login$ | async"></ng-container>',
})
export class LoginSamlComponent implements OnInit {
    login$: Observable<boolean>;

    constructor(
        private route: ActivatedRoute,
        private toasterService: ToastrService,
        private routingService: RoutingService,
        private loginService: LoginService,
    ) { }

    ngOnInit(): void {
        // get the jwt
        const base64encodedJwtToken: string = this.route.snapshot.queryParamMap.get('samljwt');
        const loginData = {
            userName: '',
            password: '',
            useRefreshTokens: false,
            SAMLJWTToken: base64encodedJwtToken,
        };

        this.login$ = this.loginService.login(loginData).pipe(
            switchMap(() => from(this.routingService.navigateToHome())),
            catchError((error) => {
                const errorMessage = error.error_description ?? 'An unknown error has occurred.';
                this.toasterService.showToast(errorMessage, LogLevel.Error);
                return from(this.routingService.navigateToLogin());
            }),
        );
    }
}

import type { Birth } from './birth.interface';

export interface PlugDate {
    Birth: Birth;
    C_Birth_key: number | null;
    C_DamsInMatingkey: number;
    C_PlugDate_key: number;
    C_Workgroup_key: number;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    DatePlug: Date;
    ModifiedBy: string;
    Version: number;
}

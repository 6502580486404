import { Pipe, PipeTransform } from '@angular/core';

/*
* Delimits array by delimiter
* E.g.
*   ["1","2","3"] | delimit:", " => "1, 2, 3"
*/
@Pipe({
    name: 'delimit',
    pure: false
})
export class DelimitPipe implements PipeTransform {

    transform(value: (string | number)[], delimiter: string): string {
        if (value) {
            return value.join(delimiter);
        }
        return "";
    }
}

/*
* Returns whether the given filename is an image file
*/
export function isImageFile(fileName: string) {
    if (!fileName) {
        return false;
    }

    const regex = new RegExp("([a-zA-Z0-9\s_\\.\-:\ \(\)])+(\\.png|\\.jpg|\\.gif|\\.jpeg|\\.bmp)$");
    return regex.test(fileName.toLowerCase());
}

<form role="form" class="form-label-right">

    <div class="modal-header justify-content-center">
        <h4 class="modal-title">Genotypes Filter</h4>
    </div>

    <div class="modal-body">

        <div class="form-group row">
            <label class="col-md-3 col-form-label">Animal ID</label>
            <div class="col-md-9">
                <input type="number"
                       step="any"
                       name="genotypeIdentifier"
                       [(ngModel)]="filter.Identifier"
                       class="form-control input-medium"
                       data-auto-focus="genotypeIdentifier"/>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-3 col-form-label">Animal Name</label>
            <div class="col-md-9">
                <input type="text"
                       name="animalName"
                       [(ngModel)]="filter.AnimalName"
                       class="form-control input-medium" />
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-3 col-form-label">Sex</label>
            <div class="col-md-9">
                <select name="sexKey"
                        [(ngModel)]="filter.C_Sex_key"
                        class="form-control input-medium">
                    <option></option>
                    <option *ngFor="let item of sexes"
                            [value]="item.C_Sex_key">
                        {{item.Sex}}
                    </option>
                </select>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-3 col-form-label">{{'Line' | translate}}</label>
            <div class="col-md-9">
                <line-multiselect [(model)]="filter.lines"></line-multiselect>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-3 col-form-label">Genotype</label>
            <div class="col-md-9">
                <genotype-combo-select [(model)]="filter.genotypeCombos"></genotype-combo-select>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-3 col-form-label">Plate</label>
            <div class="col-md-9">
                <input type="text"
                       name="plate"
                       [(ngModel)]="filter.Plate"
                       class="form-control input-medium" />
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-3 col-form-label">Genotype Date Range</label>
            <div class="col-md-4">
                <climb-ngb-date #dateControl="ngModel"
                                name="Date Genotype Start"
                                [(ngModel)]="filter.DateGenotypeStart"
                                [placeholder]="'From&hellip;'"></climb-ngb-date>
            </div>
            <div class="col-md-4">
                <climb-ngb-date #dateControl="ngModel"
                                name="Date Genotype End"
                                [(ngModel)]="filter.DateGenotypeEnd"
                                [placeholder]="'To&hellip;'"></climb-ngb-date>
            </div>
        </div>

    </div>

    <div class="modal-footer">
        <span class="btn-set">
            <button type="submit"
                    class="btn btn-fw-wide btn-lg btn-primary"
                    (click)="filterClicked($event)">
                Filter
            </button>
            <button data-automation-id="clear-filter-button"
                    type="button"
                    class="btn btn-fw-wide btn-lg btn-secondary"
                    (click)="clearClicked()">
                Clear Filter
            </button>
        </span>
        <span class="btn-set">
            <button type="button"
                    class="btn btn-lg btn-secondary"
                    (click)="closeClicked()">
                Close
            </button>
        </span>
    </div>
</form>

import type { Material } from './material.interface';

export interface MaterialSourceMaterial {
    C_MaterialPoolMaterial_key: number;
    C_Material_key: number;
    C_SourceMaterial_key: number;
    C_Workgroup_key: number;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    Material: Material;
    ModifiedBy: string;
    SourceMaterial: Material;
    Version: number;
}

import { Subscription } from 'rxjs';
import { IoTService } from './iot.service';
import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    OnDestroy
} from '@angular/core';

import { SaveChangesService } from '../services/save-changes.service';

import {
    BaseDetail,
    BaseDetailService,
    PageState
} from '../common/facet';

import { getSafeProp, notEmpty } from '../common/util';

@Component({
    selector: 'device-detail',
    templateUrl: './device-detail.component.html'
})
export class DeviceDetailComponent extends BaseDetail
    implements OnInit, OnDestroy {
    @Input() facet: any;
    @Input() device: any;
    @Input() pageState: PageState;
    @Output() exit: EventEmitter<void> = new EventEmitter<void>();
    @Output() next: EventEmitter<void> = new EventEmitter<void>();
    @Output() previous: EventEmitter<void> = new EventEmitter<void>();

    // State
    saving = false;

    private _subscriptions: Subscription[] = [];

    readonly COMPONENT_LOG_TAG = 'device-detail';

    constructor(
        private baseDetailService: BaseDetailService,
        private iotService: IoTService,
        private saveChangesService: SaveChangesService
    ) {
        super(baseDetailService);
    }

    // lifecycle
    ngOnInit() {
        const s1 = this.dataContext.changesSaved$.subscribe(() => {
            this.reloadDevice();
        });

        this._subscriptions.push(s1);
    }

    ngOnDestroy() {
        if (notEmpty(this._subscriptions)) {
            for (const subscription of this._subscriptions) {
                subscription.unsubscribe();
            }
        }
    }

    reloadDevice(): Promise<any> {
        return this.iotService.getDevices({
            page: 1,
            size: 1,
            filter: {
                C_Device_key: this.device.C_Device_key
            }
        }).then((response) => {
            if (response.results.length > 0) {
                this.device = response.results[0];
            }
        });
    }

    onCancel() {
        this.iotService.cancelDevice(this.device);
    }


    hasUnsavedLocationChanges(): boolean {
        const entityOriginalValues = getSafeProp(this.device, 'entityAspect.originalValues');
        if (!entityOriginalValues) {
            return false;
        }

        return this.saveChangesService.hasChanges &&
            (entityOriginalValues.hasOwnProperty('C_LocationPosition_key') ||
            entityOriginalValues.hasOwnProperty('C_MaterialPool_key'));
    }
}

import { Pipe, PipeTransform } from '@angular/core';

import {
    notEmpty
} from '../util';

interface PoolMaterial {
    DateOut: Date | null;
}

/**
 * Returns animals in material pool with null date out.
 */
@Pipe({
    name: 'housingCurrentAnimals',
    pure: false
})
export class HousingCurrentAnimalsPipe implements PipeTransform {

    transform<T extends PoolMaterial>(items: T[]): T[] {
        let filtered: T[] = [];

        if (notEmpty(items)) {
            filtered = items.filter((materialPoolMaterial) => {
                return materialPoolMaterial.DateOut === null;
            });
        }

        return filtered;
    }
}

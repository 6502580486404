import * as escapeStringRegexp from 'escape-string-regexp'; 

export function tokenizeOnWhitespace(
    text: string,
    extraDelimeters?: string[]
): string[] {
    let tokens: string[] = [];
    if (!text) {
        return tokens;
    }

    const delimRegex = _buildDelimeterRegex(extraDelimeters);
    const regex = new RegExp("[\\s" + delimRegex + "]+", "g");
    tokens = text.split(regex);

    tokens = _trimValues(tokens);
    tokens = _removeBlanks(tokens);

    return tokens;
}

export function tokenizeOnNewline(
    text: string,
    extraDelimeters?: string[]
): string[] {
    let tokens: string[] = [];
    if (!text) {
        return tokens;
    }

    const delimRegex = _buildDelimeterRegex(extraDelimeters);
    // [^\\S ] means any white space EXCEPT single space
    const regex = new RegExp("[^\\S ]+|[" + delimRegex + "]+", "g");
    tokens = text.split(regex);

    tokens = _trimValues(tokens);
    tokens = _removeBlanks(tokens);

    return tokens;
}


function _buildDelimeterRegex(delimiters: string[]): string {
    let delimRegex = '';
    if (delimiters) {
        for (const delimeter of delimiters) {
            delimRegex += escapeStringRegexp(delimeter);
        }
    }
    return delimRegex;
}

/**
 * remove trailing whitespace
 * @param tokens
 */
function _trimValues(tokens: string[]) {
    return tokens.map((token) => {
        return token.trim();
    });
}


function _removeBlanks(tokens: string[]) {
    return tokens.filter((token) => {
        return token;
    });
}

<form role="form" class="form-label-right">

    <div class="modal-header justify-content-center">
        <h4 class="modal-title">Births Filter</h4>
    </div>

    <div class="modal-body">

        <div class="form-group row">
            <label class="col-md-3 col-form-label">Birth ID</label>
            <div class="col-md-9">
                <input type="text"
                       name="birthID"
                       [(ngModel)]="filter.BirthID"
                       class="form-control input-medium"
                       data-auto-focus="birthID" />
            </div>
        </div>
        <div class="form-group row">
            <label class="col-md-3 col-form-label">Mating ID</label>
            <div class="col-md-9">
                <input type="text"
                       name="matingID"
                       [(ngModel)]="filter.MatingID"
                       class="form-control input-medium" />
            </div>
        </div>
        <div class="form-group row">
            <label class="col-md-3 col-form-label">Housing ID</label>
            <div class="col-md-9">
                <housing-multiselect [(model)]="filter.housingUnits"></housing-multiselect>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-md-3 col-form-label">Status</label>
            <div class="col-md-9">
                <climb-multi-select [(model)]="filter.C_BirthStatus_keys"
                                    [values]="birthStatuses"
                                    [keyProperty]="'C_BirthStatus_key'"
                                    [valueProperty]="'BirthStatus'"
                                    [placeholder]="'Select statuses...'"></climb-multi-select>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-md-3 col-form-label">Construct</label>
            <div class="col-md-9">
                <construct-multiselect [(model)]="filter.constructs"></construct-multiselect>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-md-3 col-form-label">Created By</label>
            <div class="col-md-9">
                <user-select [(model)]="filter.CreatedBy"
                             [forceValidSelection]="false"></user-select>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-md-3 col-form-label">{{'Line' | translate}}</label>
            <div class="col-md-9">
                <line-multiselect [(model)]="filter.lines"></line-multiselect>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-md-3 col-form-label">Parent Name</label>
            <div class="col-md-9">
                <input type="text"
                       name="parentName"
                       [(ngModel)]="filter.ParentName"
                       class="form-control input-medium" />
            </div>
        </div>
        <div class="form-group row">
            <label class="col-md-3 col-form-label">Progeny Name</label>
            <div class="col-md-9">
                <input type="text"
                       name="progenyName"
                       [(ngModel)]="filter.ProgenyName"
                       class="form-control input-medium" />
            </div>
        </div>
        <div class="form-group row">
            <label class="col-md-3 col-form-label">Birth Date Range</label>
            <div class="col-md-4">
                <climb-ngb-date #dateControl="ngModel"
                                name="Birth Date Start"
                                [(ngModel)]="filter.DateBornStart"
                                placeholder="From&hellip;"></climb-ngb-date>
            </div>
            <div class="col-md-4">
                <climb-ngb-date #dateControl="ngModel"
                                name="Birth Date End"
                                [(ngModel)]="filter.DateBornEnd"
                                placeholder="To&hellip;"></climb-ngb-date>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-md-3 col-form-label">Mating Date Range</label>
            <div class="col-md-4">
                <climb-ngb-date #dateControl="ngModel"
                                name="Mating Date Start"
                                [(ngModel)]="filter.DateMatingStart"
                                placeholder="From&hellip;"></climb-ngb-date>
            </div>
            <div class="col-md-4">
                <climb-ngb-date #dateControl="ngModel"
                                name="Mating Date End"
                                [(ngModel)]="filter.DateMatingEnd"
                                placeholder="To&hellip;"></climb-ngb-date>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-md-3 col-form-label">Wean Date Range</label>
            <div class="col-md-4">
                <climb-ngb-date #dateControl="ngModel"
                                name="Wean Date Start"
                                [(ngModel)]="filter.DateWeanStart"
                                placeholder="From&hellip;"></climb-ngb-date>
            </div>
            <div class="col-md-4">
                <climb-ngb-date #dateControl="ngModel"
                                name="Wean Date End"
                                [(ngModel)]="filter.DateWeanEnd"
                                placeholder="To&hellip;"></climb-ngb-date>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-md-3 col-form-label">Species</label>
            <div class="col-md-9">
                <select name="taxon"
                        [(ngModel)]="filter.C_Taxon_key"
                        class="form-control input-medium">
                    <option></option>
                    <option *ngFor="let item of taxons"
                            [value]="item.C_Taxon_key">
                        {{item.CommonName}}
                    </option>
                </select>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-md-3 col-form-label">Location</label>
            <div class="col-md-9">
            <input type="text" name="location"
                   [(ngModel)]="filter.location"
                   class="form-control input-medium" />
            </div>
        </div>
        <div class="form-group row">
            <label class="col-md-3 col-form-label">Foster</label>
            <div class="col-md-9">
                <select name="isFoster"
                        [(ngModel)]="filter.IsFoster"
                        class="form-control input-medium">
                    <option></option>
                    <option value="true">Fostered</option>
                    <option value="false">Not Fostered</option>
                </select>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-md-3 col-form-label">Foster Housing ID</label>
            <div class="col-md-9">
                <housing-multiselect [(model)]="filter.fosterHousingUnits"></housing-multiselect>
            </div>
        </div>
    </div>

    <div class="modal-footer">
        <span class="btn-set">
            <button type="submit"
                    class="btn btn-fw-wide btn-lg btn-primary"
                    (click)="filterClicked($event)">
                Filter
            </button>
            <button data-automation-id="clear-filter-button"
                    type="button"
                    class="btn btn-fw-wide btn-lg btn-secondary"
                    (click)="clearClicked()">
                Clear Filter
            </button>
        </span>
        <span class="btn-set">
            <button type="button"
                    class="btn btn-lg btn-secondary"
                    (click)="closeClicked()">
                Close
            </button>
        </span>
    </div>
</form>

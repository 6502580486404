import { Pipe, PipeTransform } from '@angular/core';
import { Genotype } from '../types/models/genotype.interface';
import { getGenotypesString } from '../util/genotypes';

/**
 * Returns Genotype names from Genotypes
 */
@Pipe({ 
    name: 'formatGenotypeNames',
    pure: false
})
export class FormatGenotypeNamePipe implements PipeTransform {

    transform<T extends Genotype>(items: T[]): string {
        return getGenotypesString(items);
    }
}

<div class="controls-container">

    <div class="form-group row">
        <label class="col-md-3 col-form-label">Include</label>
        <div class="col-md-9">
            <div class="form-check">
                <label class="form-check-label">
                    <input type="radio"
                           class="form-check-input"
                           name="materialsToInclude"
                           [(ngModel)]="materialsToInclude"
                           (ngModelChange)="onChartConfigChange($event)"
                           value="{{ALL_MATERIALS}}">
                    All Animals
                    ({{taskOutputsData.cohortMaterials.length}})
                </label>
            </div>
            <div class="form-check">
                <label class="form-check-label">
                    <input type="radio"
                           class="form-check-input"
                           name="materialsToInclude"
                           [(ngModel)]="materialsToInclude"
                           (ngModelChange)="onChartConfigChange($event)"
                           value="{{SELECTED_MATERIALS}}">
                    Selected Animals
                    ({{taskOutputsData.selectedCohortMaterials.length}})
                </label>
            </div>
        </div>
    </div>

    <div class="form-group row">
        <label class="col-md-3 col-form-label">Maximum Bins</label>
        <div class="col-md-9">
            <div class="form-check">
                <label class="form-check-label">
                    <input type="checkbox"
                           class="form-check-input"
                           name="useAutoBinCount"
                           [(ngModel)]="useAutoBinCount"
                           (ngModelChange)="onChartConfigChange($event)" />
                    Auto
                </label>
            </div>
        </div>
    </div>
    <div class="form-group row">
        <div class="offset-md-3 col-md-3">
            <input type="number"
                   step="1"
                   min="1"
                   max="10000"
                   name="animalID"
                   [(ngModel)]="binCountMax"
                   (ngModelChange)="onChartConfigChange($event)"
                   [disabled]="useAutoBinCount"
                   class="form-control" />
        </div>
    </div>

</div> <!-- /.controls-container -->
<!-- Chart -->
<div class="row">
    <div class="col-md-12">
        <div class="chart-container">

            <div *ngIf="!hasSelectedOutputs()"
                 class="help-container text-info">
                <i class="fa fa-info-circle" aria-hidden="true"></i>
                Select a task output.
            </div>

            <ng-container *ngIf="isChartDataExists !== undefined">
                <div *ngIf="!isChartDataExists; else cohortTaskOutputsChartElem"
                     class="help-container text-warning">
                    <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                    There are no values for the specified animals.
                </div>

                <ng-template #cohortTaskOutputsChartElem>
                    <plotly-plot [data]="chartData.traces" [layout]="chartData.layout" [config]="chartData.config"></plotly-plot>
                </ng-template>
            </ng-container>

        </div>
    </div>
</div>

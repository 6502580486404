import { Pipe, PipeTransform } from '@angular/core';

import { formatTimePoint } from '../util';

/**
 * return timePoint + TimeUnit
 */
@Pipe({ name: 'formatTimePoint' })
export class FormatTimePointPipe implements PipeTransform {

    transform(value: any, cvTimeUnit: any): string {
        return formatTimePoint(value, cvTimeUnit);
    }
}

import { WorkflowService } from '../../services/workflow.service';
import { VocabularyService } from '../../../vocabularies/vocabulary.service';
import {
    Component,
    Input,
    OnInit,
    OnChanges
} from '@angular/core';


@Component({
    selector: 'workflow-animal-details',
    templateUrl: './workflow-animal-details.component.html',
})
export class WorkflowAnimalDetailsComponent implements OnInit, OnChanges {
    @Input() animal: any;
    @Input() useTableFormat: boolean;

    constructor(
        private workflowService: WorkflowService,
        private vocabularyService: VocabularyService
    ) { }

    ngOnInit() {
        this.initialize();
    }

    ngOnChanges(changes: any) {
        if (changes.animal && !changes.animal.firstChange) {
            this.initAnimal();
        }
    }
    
    initialize() {
        this.getCVs().then(() => {
            return this.initAnimal();
        });
    }

    getCVs(): Promise<any> {
        // Ensure CVs loaded for samples in job-task details
        const p1 = this.vocabularyService.ensureCVLoaded('cv_PreservationMethods');
        const p2 = this.vocabularyService.ensureCVLoaded('cv_SampleStatuses');
        const p3 = this.vocabularyService.ensureCVLoaded('cv_SampleTypes');
        const p4 = this.vocabularyService.ensureCVLoaded('cv_Sexes');
        const p5 = this.vocabularyService.ensureCVLoaded('cv_AnimalStatuses');

        return Promise.all([p1, p2, p3, p4, p5]);
    }

    initAnimal(): Promise<any> {
        if (!this.animal) {
            return Promise.resolve();
        }
        const expands = [
            'Material.Line'
        ];
        return this.workflowService.ensureRelationshipsLoaded([this.animal], expands);
    }
}

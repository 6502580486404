import { CustomSearchSchema } from './custom-search-schema';
import { NestedColumn } from './nested-column';

export class ResultGrouper {

    getFlattenedColumns(schema: CustomSearchSchema): string[] {
        let flatColumns: string[] = [];
        for (const col of schema.columns) {
            if (this.isString(col)) {
                flatColumns.push(<string> col);
            } else {
                // recurse children of this node
                flatColumns = flatColumns.concat(
                    this.getFlattenedColumns(<CustomSearchSchema> col)
                );
            }

        }
        return flatColumns;
    }

    getNestedColumns(schema: CustomSearchSchema): NestedColumn[] {
        const columns: NestedColumn[] = [];
        this._getNestedColumns(columns, schema);
        return columns;
    }

    _getNestedColumns(columns: NestedColumn[], schema: CustomSearchSchema) {
        if (!columns || !schema.columns) {
            return;
        }

        for (const col of schema.columns) {
            const nestedColumn = new NestedColumn();
            nestedColumn.columns = [];
            columns.push(nestedColumn);

            if (this.isString(col)) {
                nestedColumn.name = <string> col;
            } else {
                const nestedSchema = <CustomSearchSchema> col;
                nestedColumn.name = nestedSchema.name;
                nestedColumn.key = nestedSchema.key;
                this._getNestedColumns(nestedColumn.columns, nestedSchema);
            }
        }
    }

    groupResults(
        results: any[], 
        nestedColumns: NestedColumn[],
        groupedResult: any = {}
    ): any[] {

        for (const nestedColumn of nestedColumns) {
            if (nestedColumn.key) {
                // group by this column
                const groups = this._groupResults(
                    results, nestedColumn.key
                );

                groupedResult[nestedColumn.name] = groups;

                if (nestedColumn.columns) {
                    for (const group of groups) {
                        this.groupResults(
                            group._rawData,
                            nestedColumn.columns,
                            group
                        );
                    }
                }
            } else if (groupedResult.isGroup) {
                groupedResult[nestedColumn.name] = groupedResult._repRow[nestedColumn.name];
            }
        }
        return [groupedResult];
    }

    _groupResults(results: any, key: string): any[] {
        const groupedResults: any[] = [];
        
        const seen: any = {};

        for (const result of results) {    
            const keyValue = result[key];
            if (!keyValue) {
                continue;
            }

            if (!seen.hasOwnProperty(keyValue)) {
                const newResult = {
                    isGroup: true,
                    _repRow: result,
                    _rawData: <any> []
                };
                seen[keyValue] = newResult;
                groupedResults.push(newResult);
            }

            seen[keyValue]._rawData.push(result);
        }

        return groupedResults;
    }

    isString(obj: any) {
        return typeof obj === 'string';
    }
}

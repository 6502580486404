import { Injectable } from '@angular/core';
import {
    EntityQuery,
    EntityState
} from 'breeze-client';

import { DataManagerService } from '../services/data-manager.service';
import { BaseEntityService } from '../services/base-entity.service';

import { AuthService } from '../services/auth.service';

@Injectable()
export class MonitoringService extends BaseEntityService {

    constructor(
        private authService: AuthService,
        private dataManager: DataManagerService
    ) {
        super();
    }


    getServices(): Promise<any[]> {
        const query = EntityQuery.from('MonitoringServices')
            .expand('MonitoringServiceParameters')
            .orderBy('ServiceName');

        return this.dataManager.returnQueryResults(query);
    }

    getMonitoringServiceSubscriptions(): Promise<any[]> {
        const userName = this.authService.getCurrentUserName();

        const query = EntityQuery.from('MonitoringServiceSubscriptions')
            .expand('MonitoringServiceSubscriptionParameter')
            .where('UserName', '==', userName);

        return this.dataManager.returnQueryResults(query);
    }

    async createServiceSubscription(monitoringService: any) {
        const manager = this.dataManager.getManager();
        const userName: string = this.authService.getCurrentUserName();
        const monitoringServiceKey: number = monitoringService.C_MonitoringService_key;


        // Try to restore any deleted subscriptions
        const deletedSubscriptions: any[] = manager.getEntities(
            'MonitoringServiceSubscription',
            [EntityState.Deleted]
        );

        let restoredCount = 0;
        for (const subscription of deletedSubscriptions) {
            if (subscription.C_MonitoringService_key === monitoringServiceKey &&
                subscription.UserName === userName
            ) {

                subscription.entityAspect.rejectChanges();

                const deletedParams: any[] = manager.getEntities(
                    'MonitoringServiceSubscriptionParameter',
                    [EntityState.Deleted]
                );
                for (const param of deletedParams) {
                    if (param.C_MonitoringServiceSubscription_key ===
                        subscription.C_MonitoringServiceSubscription_key) {
                        param.entityAspect.rejectChanges();
                    }
                }

                restoredCount++;
            }
        }

        // None restored, so create new
        if (restoredCount === 0) {
            const subscriptionInitialValues = {
                C_MonitoringService_key: monitoringServiceKey,
                UserName: userName
            };
            const newSubscription = this.dataManager.createEntity(
                'MonitoringServiceSubscription',
                subscriptionInitialValues
            );

            const query = EntityQuery.from('MonitoringServiceParameters')
                .where('C_MonitoringService_key', '==', monitoringServiceKey);

            const params: any[] = await this.dataManager.returnQueryResults(query);

            for (const param of params) {
                const paramInitialValues = {
                    C_MonitoringServiceSubscription_key:
                        newSubscription.C_MonitoringServiceSubscription_key,
                    C_MonitoringServiceParameter_key: param.C_MonitoringServiceParameter_key,
                    ParameterValue: param.DefaultValue
                };
                this.dataManager.createEntity(
                    'MonitoringServiceSubscriptionParameter',
                    paramInitialValues
                );
            }

            monitoringService.MonitoringServiceSubscription = newSubscription;
        } else {
            return Promise.resolve();
        }
    }

    deleteServiceSubscription(monitoringServiceKey: number) {
        const manager = this.dataManager.getManager();
        const userName: string = this.authService.getCurrentUserName();

        const addedSubscriptions: any[] = manager.getEntities(
            'MonitoringServiceSubscription',
            [EntityState.Added]
        );

        for (const subscription of addedSubscriptions) {
            if (subscription.C_MonitoringService_key === monitoringServiceKey &&
                subscription.UserName === userName
            ) {

                for (const param of subscription.MonitoringServiceSubscriptionParameter) {
                    param.entityAspect.rejectChanges();
                }

                subscription.entityAspect.rejectChanges();
            }
        }

        const existingSubscriptions: any[] = manager.getEntities(
            'MonitoringServiceSubscription',
            [EntityState.Unchanged]
        );

        for (const subscription of existingSubscriptions) {
            if (subscription.C_MonitoringService_key === monitoringServiceKey &&
                subscription.UserName === userName
            ) {
                const params: any[] = manager.getEntities(
                    'MonitoringServiceSubscriptionParameter',
                    [EntityState.Unchanged, EntityState.Modified]
                );

                for (const param of params) {
                    if (param.C_MonitoringServiceSubscription_key ===
                        subscription.C_MonitoringServiceSubscription_key
                    ) {
                        param.entityAspect.setDeleted();
                    }
                }

                subscription.entityAspect.setDeleted();
            }
        }
    }
}

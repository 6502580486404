import { BulkEditCommService } from './../../common/facet/bulk-edit-comm.service';
import { DataManagerService } from '../../services/data-manager.service';
import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';

import { LoggingService } from '../../services/logging.service';
import { BulkEditToolbarComponent } from '../../common/toolbars';
import { ClinicalService } from '../clinical.service';
import { ObservationBulkTemplatesComponent } from './observation-bulk-templates.component';


@Component({
    selector: 'observation-bulk-edit',
    templateUrl: './observation-bulk-edit.component.html',
    providers: [
        BulkEditCommService
    ]
})
export class ObservationBulkEditComponent implements OnInit {
    @Input() facet: any;
    @Input() animalHealthRecords: any[];
    @Output() exit: EventEmitter<any> = new EventEmitter<any[]>();
    @ViewChild('bulkEditToolbar', { static: false }) bulkEditToolBar: BulkEditToolbarComponent;
    @ViewChild('bulkTemplates') bulkTemplates: ObservationBulkTemplatesComponent;

    observations: any[] = [];

    readonly COMPONENT_LOG_TAG = 'observation-bulk-edit';

    constructor(
        private clinicalService: ClinicalService,
        private loggingService: LoggingService,
        private dataManager: DataManagerService
    ) {
    }

    // lifecycle
    ngOnInit() {
        this.initialize();
    }

    initialize() {
         // Copy the input so we don't touch the grid data
        this.animalHealthRecords = this.animalHealthRecords.slice();
        for (const record of this.animalHealthRecords) {
            this.observations = this.observations.concat(record.Animal.AnimalClinicalObservation);
        }
    }

    exitClicked() {
        if (this.dataManager.hasChanges()) {
            for (const observation of this.observations) {
                this.clinicalService.cancelHealthRecord(observation);
            }

            this.loggingService.logFacetUndoSuccess(this.COMPONENT_LOG_TAG);
        }

        this.exit.emit(this.observations);
    }

    async validate() {
        return this.bulkTemplates.validate();
    }

}

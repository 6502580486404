import { DataContextService } from './../services/data-context.service';
import {
    Injectable
} from '@angular/core';

import { getSafeProp } from '../common/util/get-safe-prop';

import { UserService } from './user.service';

@Injectable()
export class UserNameService {

    private users: any = {};
    private usersByKey: any = {};

    private _initPromise: Promise<any>;

    constructor(
        private userService: UserService,
        private dataContext: DataContextService,
    ) {
        this.initialize();
    }

    initialize(): Promise<any> {
        this._initPromise = this.dataContext.init().then(() => {
            this._getData();
        });

        return this._initPromise;
    }

    initializeForAudit(): Promise<any> {
        this._initPromise = this.dataContext.init().then(() => {
            return this._getDataForAudit();
        });

        return this._initPromise;
    }

    onInitComplete(): Promise<any> {
        return this._initPromise;
    }

    private _getData(): Promise<any> {
        return this.userService.getWorkgroupUsers().then((results: any[]) => {
            if (results) {
                this.users = this._userArrayToKeyValueObject(results, 'User.UserName');
                this.usersByKey = this._userArrayToKeyValueObject(results, 'C_User_key');
            } else {
                console.error("No users found for this workgroup.");
            }
        }).catch((error) => {
            console.error(error);
        });
    }

    private _userArrayToKeyValueObject = (
        array: any[],
        keyPath: string
    ) =>
        array.reduce((obj: any, item: any) => {
            const value = {
                fullName: item.User.FirstName + ' ' + item.User.LastName,
                firstName: item.User.FirstName,
                lastName: item.User.LastName,
                userName: item.User.UserName
            };
            const key = (getSafeProp(item, keyPath) || '').toLowerCase();
            obj[key] = value;
            return obj;
        }, {})

    private _getDataForAudit(): Promise<any> {
        return this.userService.getLatestKnownUserDatas().then((results: any) => {
            if (results) {
                this.users = this._userArrayToKeyValueObjectForAudit(results, 'UserName');
                this.usersByKey = this._userArrayToKeyValueObjectForAudit(results, 'UserKey');
            } else {
                console.error("No users found.");
            }
        }).catch((error) => {
            console.error(error);
        });
    }

    private _userArrayToKeyValueObjectForAudit = (
        array: any[],
        keyPath: string
    ) =>
        array.reduce((obj: any, item: any) => {
            const value = {
                fullName: item.FirstName + ' ' + item.LastName,
                firstName: item.FirstName,
                lastName: item.LastName,
                userName: item.UserName
            };
            const key = (getSafeProp(item, keyPath) || '').toLowerCase();
            obj[key] = value;
            return obj;
        }, {})

    private _convertKey(key: string): string {
        if (!key) {
            return "";
        }

        return key.toLowerCase();
    }

    /**
     * Converts a userName ('jjd') to the user's full name ('Jane Doe').
     */
    toFullName(userName: string): string {
        let value = this._getPropFromCollection(this.users, this._convertKey(userName), 'fullName');
        if (value === "") {
            value = userName;
        }
        return value;
    }

    /**
     * Converts a userName ('jjd') to the user's first name ('Jane').
     */
    toFirstName(userName: string): string {
        return this._getPropFromCollection(this.users, this._convertKey(userName), 'firstName');
    }

    getFirstNameByKey(userKey: string): string {
        return this._getPropFromCollection(this.usersByKey, this._convertKey(userKey), 'firstName');
    }

    getLastNameByKey(userKey: string): string {
        return this._getPropFromCollection(this.usersByKey, this._convertKey(userKey), 'lastName');
    }

    getUserNameByKey(userKey: string): string {
        return this._getPropFromCollection(this.usersByKey, this._convertKey(userKey), 'userName');
    }

    private _getPropFromCollection(collection: any[], key: string, prop: string): string {
        if (!key) {
            return "";
        }

        const userValue = collection[key];
        const out = getSafeProp(userValue, prop);

        if (out) {
            return out;
        } else {
            return "";
        }
    }
}

export enum FacetView {
    LIST_VIEW = 'List',
    DETAIL_VIEW = 'Details',
    HOME_VIEW = 'Home',
    BULK_EDIT_VIEW = 'Bulk Edit',
    BULK_ADD_VIEW = 'Bulk Add',
    BULK_ADD_AND_EDIT_VIEW = 'Bulk Add and Edit',
    BULK_DATA_ENTRY_VIEW = 'Bulk Data Entry',
    BULK_HOUSING_VIEW = 'Bulk Housing',
    BULK_OBSERVATION_VIEW = 'Bulk Observation',
    BULK_TREATMENT_VIEW = 'Bulk Treatment',
    BULK_OBSERVATION_EDIT_VIEW = 'Bulk Observation Edit',
    BULK_TREATMENT_EDIT_VIEW = 'Bulk Treatment Edit',
    NEW_RECORD_VIEW = 'New Record',
    NEW_IMPORT_VIEW = 'New Import',
    COHORT_VIEW = 'Cohort view',
}

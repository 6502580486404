import { WebApiService } from './../services/web-api.service';
import { Injectable } from '@angular/core';
import {
    EntityQuery,
    FilterQueryOp,
    Predicate,
    QueryResult
} from 'breeze-client';

import { 
    notEmpty,
    softCompare 
} from '../common/util';

import { DataManagerService } from '../services/data-manager.service';
import { QueryDef } from '../services/query-def';
import { BaseEntityService } from '../services/base-entity.service';
import { IoTPlotFilter } from './models';

export const IOT_ALERT_DEFINITION_TYPES = [
    {
        value: 'IoT Threshold Upper',
        label: 'Upper Threshold',
    },
    {
        value: 'IoT Threshold Lower',
        label: 'Lower Threshold',
    },
    {
        value: 'IoT AD TSpike',
        label: 'Anomaly Detection (TSpike)',
    },
    {
        value: 'IoT AD ZSpike',
        label: 'Anomaly Detection (ZSpike)',
    },
    {
        value: 'IoT AD Level Change',
        label: 'Anomaly Detection (Level Change)',
    },
];

export const ANOMALY_DIRECTIONS = [
    {
        value: 'spike',
        label: 'Spike',
    },
    {
        value: 'dip',
        label: 'Dip',
    },
    {
        value: 'both',
        label: 'Both',
    },
];

@Injectable()
export class IoTService extends BaseEntityService {

    draggedDevices: any[] = [];

    constructor(
        private dataManager: DataManagerService,
        private webApiService: WebApiService
    ) {
        super();
    }


    getAlertDefinitions(queryDef: QueryDef): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AlertDefinitions', queryDef);

        this.ensureDefExpanded(
            queryDef,
            'AlertDefinitionParameter.DeviceModelOutput.DeviceModel'
        );
        this.ensureDefExpanded(queryDef, 'AlertDevice');
        this.ensureDefExpanded(queryDef, 'AlertDevice.Device');
        this.ensureDefExpanded(queryDef, 'AlertSubscription.AlertSubscriptionModality');
        query = query.expand(queryDef.expands.join(','));

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    getDeviceModels(): Promise<any[]> {
        const expands = [
            'DeviceModelOutput',
            'DeviceDerivedOutput',
        ];
        const query = EntityQuery.from('DeviceModels')
            .expand(expands.join(','));

        return this.dataManager.returnQueryResults(query);
    }

    getDevices(queryDef: QueryDef): Promise<QueryResult> {
        let query = this.buildDefaultQuery('Devices', queryDef);

        if (notEmpty(queryDef.expands)) {
            query = query.expand(queryDef.expands.join(','));
        }

        let predicates: Predicate[] = [];
        if (queryDef.filter) {
            predicates = predicates.concat(this.buildPredicates(queryDef.filter));
        }

        if (notEmpty(predicates)) {
            query = query.where(Predicate.and(predicates));
        }

        return this.dataManager.executeQuery(query);
    }
    
    buildPredicates(filter: any): Predicate[] {
        const predicates: Predicate[] = [];

        if (!filter) {
            return predicates;
        }

        if (filter.C_Device_key) {
            predicates.push(Predicate.create('C_Device_key', '==', filter.C_Device_key));
        }

        if (filter.hasOwnProperty('deviceKey')) {
            predicates.push(Predicate.create('C_Device_key', '==', filter.deviceKey));
        }

        if (filter.hasOwnProperty('outputName')) {
            predicates.push(Predicate.create('OutputName', '==', filter.outputName));
        }

        if (filter.hasOwnProperty('plotDate')) {
            const plotDateEnd = new Date(filter.plotDate);
            plotDateEnd.setTime(plotDateEnd.getTime() + 24 * 60 * 60 * 1000);
            predicates.push(Predicate.create('OutputTime', '<=', plotDateEnd));
            predicates.push(Predicate.create('OutputTime', '>=', filter.plotDate));
        }

        return predicates;
    }

    searchDevices(text: string): Promise<any[]> {
        let query = EntityQuery.from('Devices');

        if (text) {
            query = query.where('C_Device_key', FilterQueryOp.Contains, { value: text });
        }

        return this.dataManager.returnQueryResults(query);
    }

    getDeviceModelOutputs(): Promise<any[]> {
        const query = EntityQuery.from('DeviceModelOutputs');

        return this.dataManager.returnQueryResults(query);
    }

    getDeviceOutputValues(queryDef: QueryDef): Promise<any[]> {
        queryDef.inlineCount = false;
        let query = this.buildDefaultQuery('DeviceOutputValues', queryDef);

        let predicates: Predicate[] = [];
        if (queryDef.filter) {
            predicates = predicates.concat(this.buildPredicates(queryDef.filter));

            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }

        return this.dataManager.returnQueryResults(query);
    }

    getIoTPlotData(filter: IoTPlotFilter): Promise<any[]> {
        const url = 'api/charts/iotplots';
        return this.webApiService.callApi(url, filter as any).then((response) => {
            return response.data;
        });
    }

    createIoTAlertDefinition() {
        return this.dataManager.createEntity('AlertDefinition');
    }

    /**
     * Cancel changes to an alert
     * @param alertDef
     */
    cancelAlertDefinition(alertDef: any) {
        if (alertDef) {
            if (alertDef.C_AlertDefinition_key > 0) {
                // it's an existing alert so we need to reject changes
                this.dataManager.rejectEntityAndRelatedPropertyChanges(alertDef);

                this.dataManager.rejectChangesToEntityByFilter(
                    'AlertDefinitionParameter', (item: any) => {
                        return item.C_AlertDefinition_key === alertDef.C_AlertDefinition_key;
                    }
                );

            } else {
                // it's a newly created entity so we need to delete it
                while (alertDef.AlertDefinitionParameter.length) {
                    this.dataManager.deleteEntity(alertDef.AlertDefinitionParameter[0]);
                }
                while (alertDef.AlertSubscription.length) {
                    this.dataManager.deleteEntity(alertDef.AlertSubscription[0]);
                }
                this.dataManager.deleteEntity(alertDef);
            }
        }
    }

    createIoTAlertDefinitionParameter(alertDef: any, paramName: string): any {
        const alertDefParam = this.dataManager.createEntity('AlertDefinitionParameter');
        alertDefParam.ParameterName = paramName;
        alertDef.AlertDefinitionParameter.push(alertDefParam);

        return alertDefParam;
    }

    deleteAlertDevice(alertDevice: any) {
        this.dataManager.deleteEntity(alertDevice);
    }

    createAlertDevice(initialValues: any) {

        const manager = this.dataManager.getManager();
        const entityType = 'AlertDevice';
        
        const initialDeviceKey = initialValues.C_Device_key;
        const initialAlertDefKey = initialValues.C_AlertDefinition_key;

        // Check local entities for duplicates
        const alertDevices: any[] = this.getNonDeletedLocalEntities(manager, entityType);
        const duplicates = alertDevices.filter((alertDevice) => {
            return softCompare(alertDevice.C_Device_key, initialDeviceKey) &&
                softCompare(alertDevice.C_AlertDefinition_key, initialAlertDefKey);
        });

        // Not a duplicate
        if (duplicates.length === 0) {
            return this.dataManager.createEntity(entityType, initialValues);
        }

        return null;
        
    }

    subscribeToAlertModality(alertDef: any, userName: string, modalityName: string): any {
        // first we find the alert subscription for this user, if it doesn't exist
        // we need to create it too
        let userSub = alertDef.AlertSubscription.find((alertSub: any) => {
            return alertSub.UserName === userName;
        });
        if (!userSub) {
            userSub = this.dataManager.createEntity('AlertSubscription');
            userSub.UserName = userName;
            alertDef.AlertSubscription.push(userSub);
        }

        // now we find the modality and subscribe if it's missing
        let modality = userSub.AlertSubscriptionModality.find((currModality: any) => {
            return currModality.ModalityName === modalityName;
        });
        if (!modality) {
            modality = this.dataManager.createEntity('AlertSubscriptionModality');
            modality.ModalityName = modalityName;
            userSub.AlertSubscriptionModality.push(modality);
        }

        return modality;
    }

    unsubscribeToAlertModality(alertDef: any, userName: string, modalityName: string): any {
        // first we find the alert subscription for this user
        const userSub = alertDef.AlertSubscription.find((alertSub: any) => {
            return alertSub.UserName === userName;
        });
        if (userSub) {
            // now find the modality
            const modality = userSub.AlertSubscriptionModality.find((currModality: any) => {
                return currModality.ModalityName === modalityName;
            });

            if (modality) {
                this.dataManager.deleteEntity(modality);
            }

            // if there are no modalities left we can just delete the whole subscription
            if (userSub.AlertSubscriptionModality.length === 0) {
                this.dataManager.deleteEntity(userSub);
            }
        }
    }

    /**
     * Cancel a newly added/edited device record
     * @param device
     */
    cancelDevice(device: any) {
        if (!device) {
            return;
        }
        this._cancelDeviceEdits(device);
    }

    private _cancelNewDevice(device: any) {
        // Adding new devices not yet supported
        // try {
        //     this.deleteDevice(device);
        // } catch (error) {
        //     console.error('Error cancelling device add: ' + error);
        // }
    }

    private _cancelDeviceEdits(device: any) {
        this.dataManager.rejectEntityAndRelatedPropertyChanges(device);
    }

    async ensureVisibleColumnsDataLoaded(devices: any[], visibleColumns: string[]): Promise<void> {
        const expands = this.generateExpandsFromVisibleColumns(devices[0], visibleColumns);
        return this.dataManager.ensureRelationships(devices, expands);
    }
}

import { Injectable } from '@angular/core';
import { VocabularyService } from '../vocabularies/vocabulary.service';

@Injectable()
export class ImportVocabService {
    // cached vocabs
    private _importFileTypes: any[];
    private _importFileFormats: any[];
    private _activePromise: Promise<any[]>;

    constructor(
        private vocabularyService: VocabularyService
    ) {
        this._activePromise = Promise.resolve([]);
    }

    /*
   * All vocabs resolve after the cache has finished
   *   loading, if cache load is in progress
   */
    getImportFileTypes(): Promise<any[]> {
        return this._resolveAfterCacheLoaded(() => this._importFileTypes);
    }

    getImportFileFormats(): Promise<any[]> {
        return this._resolveAfterCacheLoaded(() => this._importFileFormats);
    }

    reloadCache(): Promise<any[]> {
        this._activePromise = this._getCVs();
        return this._activePromise;
    }

    private _getCVs(): Promise<any[]> {

        const cv1 = this.vocabularyService.getCV('cv_ImportFileTypes').then((data: any[]) => {
            this._importFileTypes = data;
        });

        const cv2 = this.vocabularyService.getCV('cv_ImportFileFormats')
            .then((data: any[]) => {
                this._importFileFormats = data;
            });

        return Promise.all([cv1, cv2]);
    }

    private _resolveAfterCacheLoaded(cacheValueFunc: () => any[]): Promise<any[]> {
        return this._activePromise.then(cacheValueFunc);
    }
}

import {
    Component
} from '@angular/core';

@Component({
    selector: 'confirm-save',
    templateUrl: './confirm-save.component.html',
    styles: [`
        .wizard-heading {
            font-weight: bold;
        }
    `]
})
export class ConfirmSaveComponent {

}

/**
 * Utilities help to form (add, filter, modify, etc.) the final value of options
 * for data tables that are used in the <climb-data-table/> component.
 */

import { identity } from '@lodash';
import { TableColumnDef, TableOptions } from '@common/datatable';

export type TableOptionsOperator = (options: TableOptions) => TableOptions;

/**
 * @example```
 *  import { pipe, addAuditColumnsToOptions, isGLP, notGLP, excludeColumnsFromOptions } from '@common/datatable/utils';
 *
 *  export class ExampleTableOptions {
 *    options: TableOptions;
 *    isGLP: boolean = false;
 *
 *    constructor(
 *      private cellFormatterService: CellFormatterService,
 *    ) {
 *      this.options = pipe(
 *        this.getDefaultOptions(), // initial value of table options
 *        addAuditColumnsToOptions(cellFormatterService), // add extra columns like 'Created By', 'Modified Date', etc.
 *        isGLP(this.isGLP, excludeColumnsFromOptions(['JobNumber'])), // if isGLP === true removes 'JobNumber' from the 'options.columns' list
 *        notGLP(this.isGLP, excludeColumnsFromOptions(['AlternatePhysicalID', 'Classification'])), // if isGLP === false removes others columns
 *      );
 *    }
 *
 *    getDefaultOptions(): TableOptions {
 *      //...
 *    }
 *  }
 * ```
 */
export const pipe = (
    initialValue: TableOptions,
    ...fns: TableOptionsOperator[]
) => fns.reduce(
    (options, fn) => fn(options),
    initialValue,
);

/* Modifiers */

export const isGLP = (glp: boolean, fn: TableOptionsOperator): TableOptionsOperator => glp ? fn : identity;
export const notGLP = (glp: boolean, fn: TableOptionsOperator): TableOptionsOperator => !glp ? fn : identity;

/* Operators */

export const excludeColumnsFromOptions = (columnNames: string[]): TableOptionsOperator => (
    options: TableOptions,
): TableOptions => {
    const columns: TableColumnDef[] = options.columns.filter(({ field }: TableColumnDef) => !columnNames.includes(field));
    return {
        ...options,
        columns,
    };
};

export const ignoreColumnsInOptions = (): TableOptionsOperator => (
    options: TableOptions,
): TableOptions => {
    const columns: TableColumnDef[] = options.columns.filter(({ ignore = false }: TableColumnDef) => !ignore);
    return {
        ...options,
        columns,
    };
};

export const addColumnsToOptions = (columns: TableColumnDef[]): TableOptionsOperator => (
    options: TableOptions,
): TableOptions => {
    const tableOptions: TableOptions = { ...options };
    tableOptions.columns.push(...columns);
    return tableOptions;
};

import type { Sample } from './sample.interface';

export interface cv_PreservationMethod {
    Abbreviation: string;
    C_PreservationMethod_key: number;
    C_VocabularySource_key: number | null;
    C_Workgroup_key: number;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    ElementID: string;
    IsActive: boolean;
    IsDefault: boolean;
    IsDeprecated: boolean;
    ModifiedBy: string;
    PreservationMethod: string;
    Sample: Sample[];
    SortOrder: number | null;
    Version: number;
}

<!-- Template for typeahead options -->
<ng-template #resultTemplate let-r="result">
    <span *ngIf="r && r.AnimalName" 
          class="material-option material-option-animal">
        {{r.AnimalName}}
    </span>
    <span *ngIf="r && r.SampleName" 
          class="material-option material-option-sample">
        {{r.SampleName}}
    </span>
</ng-template>



<ng-container *ngIf="!singleMaterialOnly">
    <div *ngIf="taskOutputSet.TaskOutputSetMaterial | notEmpty">
        <table class="table table-bordered">
            <tbody>
                <tr *ngFor="let item of taskOutputSet.TaskOutputSetMaterial">
                    <td class="icon-cell" *ngIf="!readonly">
                        <a (click)="removeJobTaskOutputSetMaterial(item)" title="Remove animal/sample">
                            <i class="fa fa-remove remove-item"></i>
                        </a>
                    </td>
                    <td>
                        <ng-container *ngIf="item.Material.Animal">
                            <img src="/images/animals.png"
                                width="12" height="12"
                                alt="Animal" title="Animal" />
                            {{item.Material.Animal.AnimalName}}
                        </ng-container>
                        <ng-container *ngIf="item.Material.Sample">
                            <img src="/images/samples.png"
                                width="12" height="12"
                                alt="Sample" title="Sample" />
                            {{item.Material.Sample.SampleName}}
                        </ng-container>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <div class="material-selector-wrapper"
        *ngIf="!readonly">
        <climb-indirect-typeahead [search]="searchMaterials"
                                [resultTemplate]="resultTemplate"
                                [resultFormatter]="resultFormatter"
                                [exactMatchFunction]="isMaterialExactMatch"
                                [fieldName]="fieldName"
                                [placeholder]="'Add animal or sample&hellip;'"
                                (selectItem)="selectMaterial($event)"></climb-indirect-typeahead>
    </div>

</ng-container>

<ng-container *ngIf="singleMaterialOnly">
    <div class="material-selector-wrapper">

        <climb-typeahead [(model)]="singleMaterialKey"
                            [keySearch]="searchMaterialsByKey"
                            [search]="searchMaterials"
                            [keyFormatter]="keyFormatter"
                            [resultTemplate]="resultTemplate"
                            [resultFormatter]="resultFormatter"
                            [namespace]="'taskOutputSetMaterial'"
                            [fieldName]="fieldName"
                            [placeholder]="'Add animal or sample&hellip;'"
                            [disabled]="readonly"
                            (selectItem)="selectSingleMaterial($event)"
                            (onBlur)="onBlurHandler($event)"></climb-typeahead>

    </div>
</ng-container>

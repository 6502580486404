import {
    Pipe,
    PipeTransform
} from '@angular/core';

import { animalAgeWeeks } from '../util';
import { LuxonInput } from '@common/util/date-time-formatting/types/luxon-input.type';

/**
 * Returns the age of an animal (in weeks)
 */
@Pipe({ name: 'animalAgeWeeks' })
export class AnimalAgeWeeksPipe implements PipeTransform {

    transform(birthDate: LuxonInput, exitDate?: LuxonInput): string {
        return animalAgeWeeks(birthDate, exitDate);
    }
}

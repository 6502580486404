import { FacetSharedModule } from './../common/facet/facet-shared.module';
import { UsersSharedModule } from './users-shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { ClimbCommonModule } from '../common/climb-common.module';
import { ClimbServicesModule } from '../services/climb-services.module';
import { WorkspacesSharedModule } from '../workspaces/workspaces-shared.module';

import { EditProfileComponent } from './edit-profile.component';
import { UsersFacetComponent } from './users-facet.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgbModule,
        ClimbCommonModule,
        ClimbServicesModule,
        FacetSharedModule,
        UsersSharedModule,
        WorkspacesSharedModule,
    ],
    declarations: [
        EditProfileComponent,
        UsersFacetComponent,
    ],
    exports: [
        // needed by angular 1 or modals
        EditProfileComponent,
        UsersFacetComponent,
    ],
    providers: [
    ]
})
export class UsersModule { }

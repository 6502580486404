import { CreateSampleGroupProcotolData } from './create-sample-groups-protocol-models';
import { CreateSampleGroupsProtocolModalComponent } from './create-sample-groups-protocol-modal.component';

import { Injectable } from '@angular/core';
import {
    NgbModal,
    NgbModalOptions
} from '@ng-bootstrap/ng-bootstrap';


@Injectable()
export class CreateSampleGroupsProtocolModalService {

    modalOptions: NgbModalOptions;

    constructor(
        private modalService: NgbModal
    ) {
        this.init();
    }

    private init() {
        // NgbModalOptions: https://ng-bootstrap.github.io/#/components/modal/api#NgbModalOptions
        this.modalOptions = {
            size: 'sample-group-custom'
        };
    }


    openCreateSampleGroupsModal(taskInstances: any[]): Promise<CreateSampleGroupProcotolData> {

        const create: CreateSampleGroupProcotolData = {
            tasks: taskInstances,
            rows: [],
        };

        // Open modal
        const modalRef = this.modalService.open(
            CreateSampleGroupsProtocolModalComponent, this.modalOptions
        );
        const component = modalRef.componentInstance as CreateSampleGroupsProtocolModalComponent;
        component.create = create;

        return modalRef.result.then((result) => {
            if (result !== 'create') {
                // Not the Create Sample Groups button
                return null;
            }

            return create;
        });
    }
}

<div class="wizard-body">

    <h4 class="wizard-heading" step-heading>
        <ng-content select="[step-heading]"></ng-content>
    </h4>


    <div class="intro-text">
        <ng-content select="[step-intro]"></ng-content>

        <p>
            Once your workgroup is set up, you will be able to modify these values
            using the <em>Vocabularies</em> facet.
        </p>
    </div>


    <table class="table table-borderless" [id]="tableId">
        <tfoot>
            <tr *ngIf="!(selectedValues | notEmpty)">
                <td colspan="2" class="text-danger">
                    <i class="fa fa-2x fa-exclamation-triangle" aria-hidden="true"></i>
                    Please provide at least one {{cvItemName | lowercase}}.
                </td>
            </tr>

            <tr>
                <td colspan="2">
                    <button type="button"
                            class="btn btn-secondary add-item-link"
                            (click)="addValue()">
                        <i class="fa fa-plus-circle" aria-hidden="true"></i>
                        Add {{cvItemName}}
                    </button>
                </td>
            </tr>
        </tfoot>

        <tbody>
            <tr *ngFor="let item of selectedValues; let itemIndex = index">
                <td class="icon-cell">
                    <p class="form-control-plaintext">
                        <a (click)="removeValue(itemIndex)" title="Delete {{cvItemName}}">
                            <i class="fa fa-remove remove-item"></i>
                        </a>
                    </p>
                </td>
                <td>
                    <input type="text"
                           name="newValue{{itemIndex}}"
                           [(ngModel)]="item.cvValue"
                           class="form-control"
                           placeholder="{{cvItemName}}&hellip;"
                           required />
                </td>
            </tr>
        </tbody>
    </table>


    <ng-content select="[step-outro]"></ng-content>

</div>

import { Pipe, PipeTransform } from '@angular/core';
import { DateFormatterService } from '@common/util/date-time-formatting';


@Pipe({
    name: 'validDate'
})


export class ValidDatePipe implements PipeTransform {

    constructor(private dateFormatter: DateFormatterService) {

    }

    transform(event: any, isDiagnostic: boolean): string {

        const type = event.cv_EventType?.Label;

        if (type.includes('Reviewed')) {
            return this.dateFormatter.formatDateTime(event.DateReviewed);
        }
        else if (type.includes('Date changed') && !isDiagnostic) {
            return this.dateFormatter.formatDateOnly(event.AnimalClinicalObservation.DateObserved);
        }
        else if (type.includes('Date changed') && isDiagnostic) {
            return this.dateFormatter.formatDateOnly(event.AnimalDiagnosticObservation.DateObserved);
        }
        else {
            return event.Label;
        }
    
    }
}


import {
    CellFormatterService,
    FormatterOptions,
    TableOptions
} from '../common/datatable';
import { AuditModifiedFieldComponent } from './audit-modified-fields/audit-modified-field.component';
import { AuditClassFactory } from './audit-class-maker';

export class AuditMaterialPoolMaterialsTableOptions {

    options: TableOptions;

    constructor(
        private cellFormatterService: CellFormatterService
    ) {
        this.options = this.getDefaultOptions();
    }

    getDefaultOptions(): TableOptions {

        return {
            csvFileName: 'housings-materials-audit.csv',
            enableDetailColumn: false,
            enableDraggable: false,
            enableSelectable: false,
            enableAuditColumns: false,
            columns: [
                {
                    displayName: 'Type',
                    field: 'UpdateType',
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Modified Fields',
                    field: 'ModifiedFields',
                    sortField: 'ModifiedFields',
                    rendererComponent: AuditModifiedFieldComponent,
                    exportFormatter: this.cellFormatterService.auditModifiedFieldsExportFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Date',
                    field: 'ModifiedDate',
                    formatter: this.cellFormatterService.dateTimeUTCFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Modified By',
                    field: 'ModifiedBy',
                    formatter: this.cellFormatterService.userNameFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Housing ID',
                    field: 'HousingID',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('HousingID', 'HousingID'),
                    cellClass: AuditClassFactory.createCellClass('housingid'),
                },
                {
                    displayName: 'Housing Type',
                    field: 'HousingType',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('HousingType', 'HousingType'),
                    cellClass: AuditClassFactory.createCellClass('housingtype'),
                },
                {
                    displayName: 'Housing Status',
                    field: 'HousingStatus',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('HousingStatus', 'HousingStatus'),
                    cellClass: AuditClassFactory.createCellClass('housingstatus'),
                },
                {
                    displayName: 'Animal ID',
                    field: 'AnimalID',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('AnimalID', 'AnimalID'),
                    cellClass: AuditClassFactory.createCellClass('animalid'),
                },
                {
                    displayName: 'Animal Name',
                    field: 'AnimalName',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('AnimalName', 'AnimalName'),
                    cellClass: AuditClassFactory.createCellClass('animalname'),
                },
                {
                    displayName: 'Animal Status',
                    field: 'AnimalStatus',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('AnimalStatus', 'AnimalStatus'),
                    cellClass: AuditClassFactory.createCellClass('animalstatus'),
                },
                {
                    displayName: 'Exit Reason',
                    field: 'ExitReason',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('ExitReason', 'ExitReason'),
                    cellClass: AuditClassFactory.createCellClass('exitreason'),
                },
                {
                    displayName: 'Date In',
                    field: 'DateIn',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('DateIn', 'DateIn', this.cellFormatterService.dateFormatter),
                    cellClass: AuditClassFactory.createCellClass('datein'),
                },
                {
                    displayName: 'Date Out',
                    field: 'DateOut',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('DateOut', 'DateOut', this.cellFormatterService.dateFormatter),
                    cellClass: AuditClassFactory.createCellClass('dateout'),
                },
                {
                    displayName: 'Created By',
                    field: 'CreatedBy',
                    formatter: this.cellFormatterService.userNameFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Created Date',
                    field: 'DateCreated',
                    formatter: this.cellFormatterService.dateTimeUTCFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                }
            ]
        };
    }
}

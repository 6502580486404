/**
 * Truncates and formats a number to a constant number of decimal places.
 * @param value
 * @param decimalPlaces
 */
export function formatDecimal(value: any, decimalPlaces?: number): string {
    if (value === null || !isNumeric(value)) {
        return '';
    }

    if (decimalPlaces === null) {
        return value.toString();
    }

    if (decimalPlaces === 0) {
        return Math.round(value).toString();
    }

    const multiplier: number = 10 ** decimalPlaces;

    const numValue = Number(value);
    let rounded: number = Math.round(Math.abs(Number((numValue * multiplier).toFixed(decimalPlaces)))) / multiplier;
    if (numValue < 0) {
        rounded *= -1;
    }
    return parseFloat(rounded.toString()).toFixed(decimalPlaces);
}

function isNumeric(value: any): boolean {
    return !isNaN(value - parseFloat(value));
}

import {
    Component,
    Input,
    OnInit
} from '@angular/core';

import { AppInsightsService } from './../services/app-insights.service';
import { ReportingService } from './reporting.service';
import { TranslationService } from '../services/translation.service';

import {
    ReportTypeItem,
    ReportVariationItem,
    ParameterItem,
    ParametersConfig,
    ParamGroupItem,
} from './models';

import {
    ParameterFactory,
    ParamInputs
} from './params';

import { DateFormatterService } from '@common/util/date-time-formatting';
import {
    notEmpty
} from '@common/util';

@Component({
    selector: 'report-runner',
    templateUrl: './report-runner.component.html',
    styles: [`
        .layout-container {
            margin-bottom: 1.3em;
        }
        .btn-container {
            margin-top: 1.5em;
            text-align: right;
        }
    `]
})
export class ReportRunnerComponent implements OnInit {
    @Input() reportType: ReportTypeItem;

    parameterFactory: ParameterFactory;

    reportVariations: ReportVariationItem[] = [];
    selectedVariation: ReportVariationItem;

    parametersConfig: ParametersConfig;

    loading = false;


    constructor(
        private appInsightsService: AppInsightsService,
        private reportingService: ReportingService,
        private translationService: TranslationService,
        private dateFormatterService: DateFormatterService
    ) {
        this.parameterFactory = new ParameterFactory();
        this.parameterFactory.setReportingService(reportingService);
        this.parameterFactory.setTranslationService(translationService);
    }

    ngOnInit() {
        this.initialize();
    }

    initialize() {
        this.clearParametersSourceInfo();
        this.selectedVariation = null;

        this.loading = true;
        return this.buildReportVariations().then(() => {
            if (this.reportVariations.length > 0) {
                this.selectedVariation = this.reportVariations[0];

                return this.buildVariationParameters().then(() => {
                    this.loading = false;
                });
            }
        });
    }

    buildReportVariations(): Promise<void> {
        if (!this.reportType) {
            Promise.resolve();
        }

        const reportTypeKey = this.reportType.value;
        return this.reportingService.getReportTypeVariations(reportTypeKey).then((data) => {
            this.reportVariations = [];

            for (const reportVariation of data) {
                const textTranslated = this.translationService.translateMessage(
                    reportVariation.Name
                );

                this.reportVariations.push({
                    text: textTranslated,
                    value: reportVariation.C_ReportVariation_key,
                });
            }
        });
    }

    selectReportVariationItem(item: ReportVariationItem) {
        this.selectedVariation = item;

        this.buildVariationParameters();
    }

    clearParametersSourceInfo() {
        this.parametersConfig = {
            parameters: [],
            resultsValidated: true,
            getData: (group: ParamGroupItem) => {
                for (const subParam of group.subParams) {
                    switch (subParam.inputType) {
                        case ParamInputs.dropdown:
                        case ParamInputs.dropdownindirect:
                            subParam.details.getDataFunction();

                            break;
                    }
                }
            }
        };
    }

    buildVariationParameters(): Promise<void> {
        if (!this.selectedVariation) {
            return Promise.resolve();
        }

        const reportVariationId = this.selectedVariation.value;

        return this.reportingService.getReportTypeVariationParameterNames(reportVariationId)
            .then((data) => {

                this.clearParametersSourceInfo();

                if (!notEmpty(data)) {
                    return;
                }

                const paramNames = data[0].Parameters.split(';');

                for (const paramName of paramNames) {
                    const paramItem = this.parameterFactory.resolveParameterType(paramName);

                    if (paramItem) {
                        this.parametersConfig.parameters.push(paramItem);
                    }
                }

                this.preloadGroupData();
            });
    }

    preloadGroupData() {
        const parameters = this.parametersConfig.parameters;
        for (const paramItem of parameters) {

            if (paramItem.selectedGroup) {
                const groupItem = paramItem.selectedGroup;

                for (const subParam of groupItem.subParams) {

                    if (subParam.inputType === ParamInputs.dropdown) {
                        subParam.details.getDataFunction();
                    }
                }
            }
        }
    }

    runReport() {
        let params = null;

        if (this.parametersConfig.resultsValidated) {
            params = this.getParameterValues();
        }

        let currentVariationId = "";
        if (this.selectedVariation) {
            currentVariationId = this.selectedVariation.value;
        }

        this.appInsightsService.trackEvent('report-run', {
            report: this.reportType.text || ''
        });

        this.reportingService.runReportVariation(currentVariationId, params);
    }

    /**
     * Collect all the parameter arguments
     */
    getParameterValues(): any {
        const paramValues: any = {};

        const parameters = this.parametersConfig.parameters;
        for (const paramItem of parameters) {
            const groupItem = paramItem.selectedGroup;

            paramValues[paramItem.key] = null;

            for (const subParam of groupItem.subParams) {

                const paramValue = subParam.results;
                if (!paramValue) {
                    continue;
                }

                paramValues[paramItem.key] = paramValue;

                if (groupItem.extractValue) {
                    if (Object.prototype.toString.call(paramValue) === '[object Array]') {
                        paramValues[paramItem.key] = [];

                        for (let i = 0; i < paramValue.length; i++) {
                            paramValues[paramItem.key][i] = paramValue[i].value;
                        }
                    } else if (typeof paramValue === "object") {
                        for (const prop in paramValue) {
                            if (paramValue.hasOwnProperty(prop)) {
                                paramValues[paramItem.key.concat(prop)] = paramValue[prop];
                            }
                        }
                    }
                }
            }
        }

        // reformat values as necessary (dates, for instance)
        for (const prop in paramValues) {
            if (!paramValues.hasOwnProperty(prop)) {
                continue;
            }

            let paramValue = paramValues[prop];

            if (Object.prototype.toString.call(paramValue) === '[object Array]') {
                for (let i = 0; i < paramValue.length; i++) {
                    paramValue[i] = this.reformatValue(paramValue[i]);
                }
            } else {
                paramValue = this.reformatValue(paramValue);
            }

            paramValues[prop] = paramValue;
        }

        return paramValues;
    }

    private reformatValue(value: any): any {
        if (this.isDate(value)) {
            const tmpDate = new Date(value);

            if (tmpDate instanceof Date && !isNaN(tmpDate.valueOf())) {
                value = this.dateFormatterService.formatDateOnly(value);
            }
        }

        return value;
    }

    private isDate(value: any): boolean {
        return (null != value) && !isNaN(value) && ("undefined" !== typeof value.getDate);
    }

    selectGroup(paramItem: ParameterItem, groupItem: ParamGroupItem) {
        paramItem.selectedGroup = groupItem;
        this.parametersConfig.getData(groupItem);
    }
}

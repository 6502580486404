import { Injectable } from '@angular/core';
import { ExporterFactory, ExportType } from '../index';
import { resolveByString } from '@common/util';

import type {
    DataResponse,
    TableColumnDef,
    FormatterOptions,
} from '@common/datatable';
import { formatCell } from '../../datatable/utils';

@Injectable()
export class DataTableExportService {

    export(
        dataResponse: DataResponse,
        columns: TableColumnDef[],        
        filename: string,
        exportType: ExportType,
        columnsForcedToExport?: TableColumnDef[],
    ) {        
        if (columnsForcedToExport !== undefined) {
            columns = columnsForcedToExport.concat(columns);
            columns = this._filterColumns(columns);
            columns = columns.sort((a, b) => a.position - b.position);
        } else {
            columns = this._filterColumns(columns);
        }

        const headers = columns.map((col: TableColumnDef) => {
            return col.displayName;
        });

        // add headers to top of file
        let rows = [headers];

        rows = rows.concat(
            this._buildExportDataArray(dataResponse.results, columns)
        );

        const exporter = ExporterFactory.create(exportType);
        exporter.download(rows, filename);
    }

    _filterColumns(columns: TableColumnDef[]) {
        return columns.filter((column) => {
            return (column.visible && column.exportable) || column.forcedToExport;
        });
    }

    _buildExportDataArray(data: any, columns: TableColumnDef[]) {
        return data.map((row: any) => {
            const csvRow = [];
            for (const col of columns) {
                let value = '';
                try {
                    value = resolveByString(row, col.field);
                } catch (err) {
                    // ignore if object is missing value
                    //   for given field path
                }

                // Use export or normal formatter?
                let formatter = null;
                if (col.exportFormatter) {
                    formatter = col.exportFormatter;
                } else if (col.formatter) {
                    formatter = col.formatter;
                } else {
                    formatter = this.plainTextFormatter;
                }

                value = formatCell(row,
                    value,
                    formatter
                );
                csvRow.push(value);
            }

            return csvRow;
        });
    }

    // Simple formatter to avoid HTML escaping
    plainTextFormatter = (row: any, value: any, formatterOptions: FormatterOptions) => {
        // Let formatCell() know not to escape the HTML
        formatterOptions.escapeHTML = false;

        if (!value && (value !== 0)) {
            return '';
        }

        return '' + value;
    }
}

import { BulkAddPromptModalComponent } from './bulk-add-prompt-modal.component';
import {
    Injectable,
} from '@angular/core';

import {
    NgbModal,
    NgbModalOptions
} from '@ng-bootstrap/ng-bootstrap';

/**
 * Service to view the bulk add prompt modal.
 */
@Injectable()
export class ViewBulkAddPromptModalService {

    // Prevent exit other than thru buttons
    modalOptions: NgbModalOptions = {
        backdrop: "static",
        keyboard: false
    };


    constructor(
        private modalService: NgbModal
    ) {
        //
    }

    openComponent(): Promise<any> {
        return this.modalService.open(BulkAddPromptModalComponent, this.modalOptions)
            .result.then(
            (result) => {
                return result;
            },
            (reason) => {
                // either 'stay' or 'discard'
                return reason;
            });
    }
}

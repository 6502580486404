<div data-automation-id="select-workgroup-window">
    <div class="dialog-title">Select Workgroup</div>
    <div class="dialog-body">
        <climb-select data-automation-id="workgroups-dropdown"
                      [(ngModel)]="selectedWorkgroupKey" 
                      size="md">
            <climb-select-option *ngFor="let workgroup of workgroups"
                                [value]="workgroup.C_Workgroup_key">
                {{ workgroup.WorkgroupName }}
            </climb-select-option>
        </climb-select>
        <climb-alert-inline *ngIf="hasUnsavedChanges"
                            [message]="message"
                            status="warning"
                            class="unsaved-changes-warning"></climb-alert-inline>
        <div class="modal-actions">
            <button *ngIf="showCloseButton" (click)="close()" climbButton size="md" variant="secondary">Cancel</button>
            <button data-automation-id="select-workgroup-button"
                    (click)="selectWorkgroup()" 
                    climbButton [pending]="switchingWorkgroup$ | async" size="md" 
                    variant="primary">
                Select
            </button>
        </div>
    </div>
</div>

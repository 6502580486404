<climb-auth-container *ngIf="isLoaded; else loading">
    <div *ngIf="isError; else resetPasswordForm"
         class="load-error-message">
        <climb-alert-inline [message]="loadErrorMessage"
                            status="error">
        </climb-alert-inline>
        <button climbButton
                size="lg"
                routerLink="/requestpwdreset">
            Request Password Reset
        </button>
        <button climbButton
                size="lg"
                routerLink="/login">
            Login
        </button>
    </div>

    <ng-template #resetPasswordForm>
        <form [formGroup]="formData">
            <climb-alert-inline *ngIf="errorMessage"
                                [message]="errorMessage"
                                status="error">
            </climb-alert-inline>

            <climb-alert-inline *ngIf="savedSuccessfully">
                Your password has been reset!
                <p>Please <a routerLink="/login">log in</a>…</p>
            </climb-alert-inline>

            <climb-input size="lg">
                <svg [climbIcon]="icons.padlockClosed"></svg>
                <input data-automation-id="password-input"
                       type="password"
                       climbInput
                       formControlName="Password"
                       name="password"
                       placeholder="New Password"
                       minlength="8"
                       required
                       autocomplete="off"
                       autocorrect="off"
                       autocapitalize="off"
                       spellcheck="false" />
                <div climbInputError
                     *hasError="formData.get('Password'); errorCode 'minlength'; let error">
                    Minimum length is {{ error.requiredLength }} characters.
                </div>
            </climb-input>

            <climb-input size="lg">
                <svg [climbIcon]="icons.padlockClosed"></svg>
                <input data-automation-id="confirm-password-input"
                       type="password"
                       climbInput
                       formControlName="PasswordConfirm"
                       name="passwordConfirm"
                       placeholder="Confirm New Password"
                       required
                       autocomplete="off"
                       autocorrect="off"
                       autocapitalize="off"
                       spellcheck="false" />
                <div climbInputError
                     *hasError="formData.get('PasswordConfirm'); errorCode 'match'">
                    Passwords must match.
                </div>
            </climb-input>

            <button climbButton
                    type="submit"
                    variant="primary"
                    size="lg"
                    (click)="resetPassword()"
                    [pending]="sending"
                    [disabled]="formData.invalid">
                Reset Password
            </button>
        </form>

        <div class="links">
            <a data-automation-id="return-to-login-link"
               routerLink="/login">
            Return to Login
        </a>
        </div>
    </ng-template>

    <climb-auth-footer authContainerFooter
                       [version]="buildId">
    </climb-auth-footer>
</climb-auth-container>

<ng-template #loading>
    <climb-auth-container>
        <climb-spinner size="lg"></climb-spinner>
    </climb-auth-container>
</ng-template>

import { map } from 'rxjs/operators';
import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    ViewChild,
    ViewChildren
} from '@angular/core';
import { NgForm } from '@angular/forms';

import { ExportLineDetailService } from '../export-line-detail.service';
import { EnumerationService } from '../../enumerations/enumeration.service';
import { LineService } from '../line.service';
import { LineVocabService } from '../line-vocab.service';
import { LocationService } from '../../locations/location.service';

import {
    BaseDetail,
    BaseDetailService,
    GlpBaseDetail,
    PageState
} from '../../common/facet';

import {
    TableSort,
} from '../../common/models';

import {
    maxSequence,
} from '../../common/util';

import { TaskType } from '../../tasks/models';
import { DetailTaskTableComponent, DetailTaskTableOptions } from '../../tasks/tables';
import {
    BreezeEntityChangedEventArgs,
    Construct,
    cv_LineStatus,
    cv_LineType,
    cv_Taxon,
    Entity,
    Line,
    TaskInstance
} from '../../common/types';
import { LineSaveService } from '../services/line-save.service';
import { LineStateService } from '../services/line-state.service';
import { EntityChangeService } from '../../entity-changes/entity-change.service';
import { Subscription } from 'rxjs';
import { WorkflowService } from '../../workflow/services/workflow.service';

export interface ExtendedLine extends Line {
    inputsExpanded: boolean;
    taskAnimalsExpanded: boolean;
    taskAnimalsLinesExpanded: boolean;
    taskCohortsExpanded: boolean;
    taskSamplesExpanded: boolean;
    tasksExpanded: boolean;
}

@Component({
    selector: 'line-detail',
    templateUrl: './line-detail.component.html'
})
export class LineDetailComponent extends GlpBaseDetail<Line> implements OnChanges, OnInit {
    @ViewChildren('detailTaskTable') detailTaskTables: DetailTaskTableComponent[];
    @Input() facet: any;
    @Input() line: Entity<ExtendedLine>;
    @Input() pageState: PageState;
    @Output() exit: EventEmitter<void> = new EventEmitter<void>();
    @Output() next: EventEmitter<void> = new EventEmitter<void>();
    @Output() previous: EventEmitter<void> = new EventEmitter<void>();

    @ViewChild("lineForm") lineForm: NgForm;

    // CVs
    constructs: Entity<Construct>[] = [];
    lineStatuses: Entity<cv_LineStatus>[] = [];
    lineTypes: Entity<cv_LineType>[] = [];
    taxons: Entity<cv_Taxon>[] = [];

    // State
    saving = false;

    // Table options
    detailTaskTableOptions: DetailTaskTableOptions;

    // Table sorting
    taskTableSort: TableSort = new TableSort();

    // Export enum to template
    TaskType = TaskType;

    readonly COMPONENT_LOG_TAG = 'line-detail';

    workgroupSubscription: Subscription;

    constructor(
        baseDetailService: BaseDetailService,
        private enumerationService: EnumerationService, 
        private exportLineDetailService: ExportLineDetailService,
        private lineService: LineService,
        private lineVocabService: LineVocabService,
        private locationService: LocationService,
        private lineSaveService: LineSaveService,
        private lineStateService: LineStateService,
        entityChangeService: EntityChangeService,
        private workflowService: WorkflowService,
    ) {
        super(baseDetailService, lineSaveService, lineStateService, entityChangeService);
    }

    // lifecycle
    ngOnInit() {
        super.ngOnInit();
        this.baseDetailService.saveChangesService.registerValidator(this);
        this.initialize();
        this.initTableOptions();
    }

    ngOnChanges(changes: any) {
        if (changes.line) {
            if (this.line && !changes.line.firstChange) {
                if (this.lineForm) {
                    this.lineForm.form.markAsPristine();
                }
                this.initialize();
            }
        }
    }

    initialize() {
        this.setLoading(true);

        if (this.line) {
            this.setTableStates();
        }

        return this.getCVs().then(() => {
            return this.getDetails();
        }).then(() => {
            this.setLoading(false);
        }).catch((error) => {
            this.setLoading(false);
            throw error;
        });
    }

    /**
     * Sets view defaults for tables
     */
    setTableStates() {
        // For now, these are constant
        this.line.tasksExpanded = true;
        this.line.inputsExpanded = true;
        this.line.taskAnimalsExpanded = true;
        this.line.taskAnimalsLinesExpanded = !this.line.taskAnimalsExpanded;
        this.line.taskCohortsExpanded = !this.line.taskAnimalsExpanded;
        this.line.taskSamplesExpanded = true;
    }

    private initTableOptions() {
        // Detail Task Table
        this.detailTaskTableOptions = new DetailTaskTableOptions();
        this.detailTaskTableOptions.allowLocking = false;
        this.detailTaskTableOptions.showAnimals = true;
        this.detailTaskTableOptions.showSamples = true;
    }

    private getCVs(): Promise<any> {
        const p1 = this.lineVocabService.constructs$.pipe(map((data) => {
            this.constructs = data;
        })).toPromise();

        const p2 = this.lineVocabService.lineStatuses$.pipe(map((data) => {
            this.lineStatuses = data;
        })).toPromise();

        const p3 = this.lineVocabService.lineTypes$.pipe(map((data) => {
            this.lineTypes = data;
        })).toPromise();

        const p4 = this.lineVocabService.taxons$.pipe(map((data) => {
            this.taxons = data;
        })).toPromise();

        return Promise.all([p1, p2, p3, p4]);
    }

    private getDetails(): Promise<Entity<ExtendedLine>> {
        if (this.line && this.line.C_Line_key > 0) {
            const expands: string[] = [];

            return this.lineService.getLine(this.line.C_Line_key, expands).then(() => {
                const extraExpands: string[] = [
                    'TaskOutputSet.TaskOutputSetMaterial.Material.Animal',
                    /* eslint-disable-next-line */
                    'TaskOutputSet.TaskOutput.Output.CalculatedOutputExpression.ExpressionOutputMapping',
                    /* eslint-disable-next-line */
                    'TaskOutputSet.TaskOutput.Output.CalculatedOutputExpression.ExpressionInputMapping',
                ];

                return this.lineService.getTaskLines(
                    this.line.C_Line_key, extraExpands
                );
            }).then((taskLines: any[]) => {
                return this.attachInputEnumerationsToTaskLines(taskLines);
            });
        }

        return Promise.resolve(this.line);
    }

    private attachInputEnumerationsToTaskLines(taskLines: any[]): Promise<any> {
        const promises = [];

        for (const taskLine of taskLines) {
            const promise = this.enumerationService.attachInputEnumerations(taskLine.TaskInput);
            promises.push(promise);
        }

        return Promise.all(promises);
    }


    // Cancelling
    onCancel() {
        this.lineService.cancelLine(this.line);
    }


    // Location
    addLocation() {
        this.locationService.getDefaultLocation()
            .then((defaultLocation: any) => {
                this.line.C_LocationPosition_key = defaultLocation.C_LocationPosition_key;
            });
    }

    clearLocation() {
        this.line.C_LocationPosition_key = null;
    }


    // Tasks
    addTaskLine(taskInstance: any) {
        const taskSequence = maxSequence(this.line.TaskLine) + 1;

        this.lineService.createTaskLine(
            this.line.C_Line_key,
            taskInstance.C_TaskInstance_key,
            taskSequence
        );
    }

   async removeTaskInstanceFromLine(taskInstance: TaskInstance): Promise<void> {
       this.workflowService.deleteTask(taskInstance);
       await this.saveEntity();
       this.workflowService.refreshWorkflowFacet.next();
   }


    // Export and print
    exportDetail() {
        this.exportLineDetailService.exportToCsv(
            this.line,
            this.taskTableSort
        );
    }

    printDetail() {
        const printContents = document.getElementById('exportLineTableDiv').innerHTML;

        let popupWin: any;

        if (navigator.userAgent.toLowerCase().indexOf('chrome') > -1) {
            popupWin = window.open(
                '',
                '_blank',
                'width=600,height=600,' +
                'scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no'
            );
            popupWin.window.focus();
            popupWin.document.write(
                '<!DOCTYPE html>' +
                '<html><head>' +
                '<link rel="stylesheet" type="text/css" href="/css/main.css" />' +
                '</head><body onload="window.print()">' +
                printContents +
                '</div></html>'
            );
            popupWin.onbeforeunload = (event: any) => {
                popupWin.close();
                return '.\n';
            };
            popupWin.onabort = (event: any) => {
                popupWin.document.close();
                popupWin.close();
            };
        } else {
            popupWin = window.open('', '_blank', 'width=800,height=600');
            popupWin.document.open();
            popupWin.document.write(
                '<html>' +
                '<head><link rel="stylesheet" type="text/css" href="/css/main.css" /></head>' +
                '<body onload="window.print()">' +
                printContents +
                '</html>'
            );
        }
        popupWin.document.close();

        return true;
    }


    // Formatters for <select> input
    lineTypeKeyFormatter = (value: any) => {
        return value.C_LineType_key;
    }
    lineTypeFormatter = (value: any) => {
        return value.LineType;
    }
    lineStatusKeyFormatter = (value: any) => {
        return value.C_LineStatus_key;
    }
    lineStatusFormatter = (value: any) => {
        return value.LineStatus;
    }
    async validate() {
        return this.detailTaskTables.map(item => item.validate()).find(msg => msg);
    }

    async beforeSave(): Promise<void> {
        return;
    }

    destroy(): void {
        this.baseDetailService.saveChangesService.unregisterValidator(this);
        if (this.workgroupSubscription) {
            this.workgroupSubscription.unsubscribe();
        }
    }

    getEntityForSaving(): Entity<Line> {
        return this.line;
    }

    async onEntityChange(entityChange: BreezeEntityChangedEventArgs<Line>): Promise<void> {
        return;
    }
}

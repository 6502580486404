import {
    Component,
    Input,
    OnInit
} from '@angular/core';

import {
    NgbActiveModal
} from '@ng-bootstrap/ng-bootstrap';

import { AuditService } from '../../audit/audit.service';
import { ExportStudyAuditService } from './export-study-audit.service';
import { StudyService } from '../study.service';

import {
    getSafeProp
} from '../../common/util/';

@Component({
    selector: 'study-audit-report',
    templateUrl: './study-audit-report.component.html'
})
export class StudyAuditReportComponent implements OnInit {
    @Input() studyKey: any;

    study: any;
    auditData: any[] = [];
    auditCharacteristicsData: any[] = [];
    auditLinesData: any[] = [];

    loading = false;

    readonly noDataMessage: string = 'No changes.';

    constructor(
        private activeModal: NgbActiveModal,
        private auditService: AuditService,
        private exportStudyAuditService: ExportStudyAuditService,
        private studyService: StudyService
    ) {
    }

    ngOnInit() {
        this.getData();
    }

    private getData(): Promise<void> {

        const p1 = this.studyService.getStudy(this.studyKey)
            .then((data) => {
                this.study = data;
            });

        const p2 = this.auditService.getStudyAuditData(this.studyKey)
            .then((response: any) => {
                this.auditData = getSafeProp(response, 'data.results') || [];
            });

        const p3 = this.auditService.getStudyAuditCharacteristicsData(this.studyKey)
            .then((response: any) => {
                this.auditCharacteristicsData = getSafeProp(response, 'data.results') || [];
            });

        const p4 = this.auditService.getStudyAuditLinesData(this.studyKey)
            .then((response: any) => {
                this.auditLinesData = getSafeProp(response, 'data.results') || [];
            });

        this.loading = true;
        return Promise.all([p1, p2, p3, p4]).then(() => {
            this.loading = false;
        }).catch((error) => {
            this.loading = false;
            throw error;
        });
    }

    onCancel(): void {
        this.activeModal.dismiss();
    }

    exportAudit(): void {
        this.exportStudyAuditService.exportToCsv(
            this.auditData,
            this.auditCharacteristicsData,
            this.auditLinesData
        );
    }
}

import type { Line } from './line.interface';

export interface cv_LineType {
    C_LineType_key: number;
    C_VocabularySource_key: number | null;
    C_Workgroup_key: number;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    ElementID: string;
    IsActive: boolean;
    IsDefault: boolean;
    IsDeprecated: boolean;
    Line: Line[];
    LineType: string;
    ModifiedBy: string;
    SortOrder: number | null;
    Version: number;
}

import {
    Pipe,
    PipeTransform
} from '@angular/core';

import { TranslationService } from '../../services/translation.service';

@Pipe({ name: 'translateMessage' })
export class TranslateMessagePipe implements PipeTransform {

    constructor(private translationService: TranslationService) {
        //
    }

    transform(value: string): string {
        return this.translationService.translateMessage(value);
    }
}

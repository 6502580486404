const ELLIPSIS = '\u2026';

import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';

@Component({
    selector: 'dotmatics-test-article-select',
    template: `
    <div>
        <climb-typeahead
            [(model)]="model"
            [resultFormatter]="testArticleFormatter"
            [keyFormatter]="testArticleKeyFormatter"
            [search]="searchTestArticles" 
            [keySearch]="searchTestArticlesByKey"
            [required]="required"
            [disabled]="disabled"
            [placeholder]="placeholder"
            [namespace]="'testArticle'"
            (selectItem)="selectItemHandler($event)"
            #typeahead
        ></climb-typeahead>
    </div>
    `
})
export class DotmaticsTestArticleSelectComponent implements OnInit {
    @Input() model: any;
    @Input() excludeLocked = false;
    @Input() required: boolean;
    @Input() disabled: boolean;
    @Input() testArticles: any[];
    @Input() jobTestArticle: any;

    @Output() modelChange: EventEmitter<any> = new EventEmitter<any>();

    placeholder: string;

    constructor(
        
    ) {
        //
    }

    ngOnInit() {
        this.placeholder = 'Test Article' +
            ELLIPSIS;
    }

    selectItemHandler(item: any) {
        this.modelChange.emit(item);
    }

    searchTestArticles = (search: string) => {
        const filterResults = this.testArticles.filter((v) => v.testArticleId.toLowerCase().indexOf(search.toLowerCase()) > -1).slice(0, 50);
        return [filterResults];
    }

    searchTestArticlesByKey = (search: any) => {
        const filterResults = this.testArticles.filter((v) => v.C_TestArticle_key === this.model);
        return [filterResults];
    }

    testArticleKeyFormatter = (value: any) => {
        return value.C_TestArticle_key;
    }
    testArticleFormatter = (value: any) => {
        return value.testArticleId;
    }
}


export interface CreateSampleGroupDataRow {
    NumSamples: number;
    C_SampleType_key: string;
    C_SampleStatus_key: string;
    C_PreservationMethod_key: string;
    C_ContainerType_key: string;
    C_SampleSubtype_key: string;
    C_SampleProcessingMethod_key: string;
    SendTo: string;
    C_SampleAnalysisMethod_key: string;
}

export interface CreateSampleGroupData {
    tasks: any[];
    rows: CreateSampleGroupDataRow[];
}

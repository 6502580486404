<a *ngIf="newMessageCount === 0"
    (click)="signalMessagePanelOpen()"
    class="nav-link nav-link-icon"
    data-toggle="collapse" data-target="#messagePanel" title="Message Center">
    <i class="fa fa-bell-o fa-2x"></i>
</a>
<a *ngIf="newMessageCount > 0"
    class="nav-link nav-link-icon bg-warning warning-icon"
    (click)="signalMessagePanelOpen()"
    data-toggle="collapse" data-target="#messagePanel" title="Message Center">
    <i class="fa fa-bell fa-2x"></i>
    <span class="badge">{{newMessageCount}}</span>
</a>

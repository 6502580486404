import { isEmpty, pick } from '@lodash';
import { interval, merge, Subscription } from 'rxjs';
import { distinctUntilChanged, map, takeUntil, tap } from 'rxjs/operators';
import {
    ChangeDetectorRef,
    Directive,
    DoCheck,
    Host,
    Input,
    OnDestroy,
    OnInit,
    Optional,
    TemplateRef,
    ViewContainerRef,
} from '@angular/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';

export type ControlState = (control: AbstractControl) => boolean;

/**
 * A [structural directive](https://angular.io/guide/structural-directives) that includes
 * a template based on the value of FormControl validation input.
 *
 * When the FormControl contains validation errors and the input is in the dirty or touched state
 * the template is rendered. Error data can be obtained through the exported value `errorValue`
 * by alias to a local variable, or you can specify any other local variable where the data
 * will be passed implicitly. You can also track a certain error by specifying its code
 * using the `errorCode` parameter. And if necessary, you can specify a rule by the `controlState`
 * parameter, that determines under what conditions to show or hide the bound template
 *
 *
 * Simple form with shorthand syntax:
 *
 * ```
 * <div *hasError="formGroup.get('fieldName'); errorCode 'minlength'; let errorData">
 *     Content to render when an error exists.
 *     E.g. Value must be at least {{ errorData.requiredLength }} characters long, got {{ errorData.actualLength }}.
 * </div>
 * ```
 *
 * Simple form with expanded syntax:
 *
 * ```
 * <ng-template [hasError]="formGroup.get('fieldName')" [errorCode]="'required'" let-errorData>
 *     <div>
 *         Content to render when an error exists.
 *         E.g. Value must be at least {{ errorData.requiredLength }} characters long, got {{ errorData.actualLength }}.
 *     </div>
 * </ng-template>
 * ```
 */
@Directive({
    selector: '[hasError]',
})
export class ShowControlErrorDirective<T = unknown> implements OnInit, OnDestroy {
    @Input()
    set hasError(control: AbstractControl) {
        this.control = control;
    }
    private control: AbstractControl;

    @Input()
    set hasErrorErrorCode(code: string) {
        this.errorCode = code;
        this.observeErrorCode(code);
    }
    private errorCode: string;

    @Input()
    set hasErrorControlState(predicate: ControlState) {
        this.isControlInvalid = predicate;
    }
    private isControlInvalid: ControlState = (control) => {
        return control.invalid && (control.dirty || control.touched);
    }

    private hasView = false;

    private context: Context<T>;

    private subscriptions: Subscription = new Subscription();

    private observedErrorCodes: Set<string> = new Set();

    constructor(
        private viewContainerRef: ViewContainerRef,
        private templateRef: TemplateRef<Context<T>>,
        private cdr: ChangeDetectorRef,
    ) { }

    ngOnInit(): void {
        if (!this.control) {
            throw new Error('Cannot find a control to handle it\'s errors.');
        }

        const initialStatusChanges$ = interval(500).pipe(
            map(() => {
                return this.isControlInvalid(this.control)
                    ? this.getErrorData()
                    : null;
            }),
            takeUntil(this.control.statusChanges),
        );
        const activeStatusChanges$ = this.control.statusChanges.pipe(
            map(() => {
                return this.isControlInvalid(this.control)
                    ? this.getErrorData()
                    : null;
            }),
        );

        const updateView$ = merge(
            initialStatusChanges$,
            activeStatusChanges$,
        ).pipe(
            distinctUntilChanged(),
            tap((errorData) => this.updateView(errorData)),
        );

        this.subscriptions.add(updateView$.subscribe());

        this.initEmbeddedView();
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    /**
     * To check for the presence of a ShowControlErrorMessageDirective
     * with a tracked ErrorCode value, we execute detectChanges().
     * After that, all nested templates of the ShowControlErrorMessageDirective
     * will be initialized and the observedErrorCodes list will be filled
     * with the values of the monitored errors.
     * Upon completion of the initialization process, we clear the template.
     */
    private initEmbeddedView(): void {
        const context = new Context({});
        const embeddedView = this.viewContainerRef.createEmbeddedView(this.templateRef, context);
        embeddedView.detectChanges();
        this.viewContainerRef.clear();
    }

    private updateView(errorData: (ValidationErrors | any) | null): void {
        if (!errorData) {
            this.hasView = false;
            this.viewContainerRef.clear();
            this.cdr.markForCheck();
            return;
        }

        if (this.hasView) {
            this.context.$implicit = errorData;
            this.context.errorValue = errorData;
            this.cdr.markForCheck();
            return;
        }

        this.hasView = true;
        this.context = new Context(errorData);
        this.viewContainerRef.createEmbeddedView(this.templateRef, this.context);
        this.cdr.markForCheck();
    }

    private getErrorData(): (ValidationErrors | any) | null {
        if (this.errorCode) {
            return this.control.getError(this.errorCode);
        }

        if (this.observedErrorCodes.size) {
            const errors = pick(this.control.errors, [...this.observedErrorCodes]);
            return isEmpty(errors) ? null : errors;
        }

        return this.control.errors;
    }

    matchErrorCode(value: string): boolean {
        // fast check to improve performance
        if (!this.control.errors[value]) {
            return false;
        }
        const firstErrorCode = Object.keys(this.control.errors)
            .filter((code: string) => this.observedErrorCodes.has(code))
            .shift();
        return value === firstErrorCode;
    }

    observeErrorCode(value: string): void {
        this.observedErrorCodes.add(value);
    }

    static ngTemplateContextGuard<T>(
        directive: ShowControlErrorDirective<T>,
        context: unknown,
    ): context is Context<T> {
        return true;
    }
}

class Context<T> {
    $implicit: T;

    constructor(public errorValue: T) {
        this.$implicit = errorValue;
    }
}

/**
 * Provides functionality to render a template whose specified error code matches
 * the active error of the nested form control in the `hasError` directive.
 * When error codes match, the given `hasErrorCode` template is rendered. If multiple
 * error codes match the current state of the form control, the first one is displayed.
 *
 * ```
 * <div *hasError="formGroup.get('fieldName'); let errors">
 *     <ng-template [hasErrorCode]="'required'">
 *         Required field.
 *     </ng-template>
 *     <ng-template [hasErrorCode]="'minlength'">
 *         Value must be at least {{ errors.minlength.requiredLength }} characters long.
 *     </ng-template>
 *     <ng-template [hasErrorCode]="'maxlength'">
 *         Value must be maximum {{ errors.maxlength.requiredLength }} characters long.
 *     </ng-template>
 * </div>
 * ```
 */
@Directive({
    selector: '[hasErrorCode]',
})
export class ShowControlErrorMessageDirective implements OnInit, DoCheck {
    private hasView = false;

    @Input() hasErrorCode: string;

    constructor(
        private viewContainerRef: ViewContainerRef,
        private templateRef: TemplateRef<Record<string, unknown>>,
        @Optional() @Host() private parent: ShowControlErrorDirective,
    ) {
        if (!parent) {
            throw Error('ShowControlErrorDirective provider not found');
        }
    }

    ngOnInit(): void {
        this.parent.observeErrorCode(this.hasErrorCode);
    }

    /**
     * Performs errorCode matching.
     */
    ngDoCheck(): void {
        this.setState(this.parent.matchErrorCode(this.hasErrorCode));
    }

    show(): void {
        this.hasView = true;
        this.viewContainerRef.createEmbeddedView(this.templateRef);
    }

    hide(): void {
        this.hasView = false;
        this.viewContainerRef.clear();
    }

    setState(show: boolean) {
        if (show === this.hasView) {
            return;
        }
        show ? this.show() : this.hide();
    }
}

import { findElementByQuery } from './find-element';

/**
 * Focus on given DOM element
 * @param element
 * @param timeoutDelay
 */
export function focusElement(element: any, timeoutDelay?: number): Promise<void> {
    return new Promise((resolve) => {
        if (timeoutDelay) {
            setTimeout(() => {
                element.focus();
                resolve();
            }, timeoutDelay);
        } else {
            element.focus();
            resolve();
        }
    });
}

/**
 * Focus on first DOM element that matches cssQuery
 * @param query
 * @param timeoutDelay
 */
export function focusElementByQuery(query: string, timeoutDelay?: number): Promise<void> {
    return new Promise((resolve) => {
        if (timeoutDelay) {
            setTimeout(() => {
                _focusElementByQuery(query).then(resolve);
            }, timeoutDelay);
        } else {
            _focusElementByQuery(query).then(resolve);
        }
    });
}


function _focusElementByQuery(query: string): Promise<void> {
    return findElementByQuery(query).then(focusElement).catch((error: Error) => {
        // don't care about not being able to Focus an element.
        // sometimes elements just aren't available
        // uncomment if you need to debug
        // console.error(error);
    });
}


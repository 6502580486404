import { Pipe, PipeTransform } from '@angular/core';

import { notEmpty } from '../util';

interface MaterialBase {
    Material: {
        Sample: any;
    };
}

/**
 * Returns Samples from a list of Materials
 */
@Pipe({ 
    name: 'sampleFilter',
    pure: false
 })
export class SampleFilterPipe implements PipeTransform {

    transform<T extends MaterialBase>(items: T[]): T[] {
        const filtered: T[] = [];

        if (notEmpty(items)) {
            for (const item of items) {
                if (item.Material != null) {
                    if (item.Material.Sample != null) {
                        filtered.push(item);
                    }
                }
            }
        }

        return filtered;
    }
}

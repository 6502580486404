import { cv_JobType } from "./cv-job-type.interface";

export interface JobCharacteristicJobType {
    C_JobCharacteristicJobType_key: number;
    C_JobType_key: number;
    C_JobCharacteristic_key: number;
    DateCreated: Date;
    CreatedBy: string;
    DateModified: Date;
    ModifiedBy: string;

    cv_JobType: cv_JobType;
}

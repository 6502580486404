import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
    Component,
    Input
} from '@angular/core';
import { CensusService } from './census.service';

@Component({
    selector: 'census-scan',
    templateUrl: './census-scan-modal.component.html'
})
export class CensusScanModalComponent {
    @Input() census: any;

    finding = false;
    scanValue: any = "";
    error: any = null;

    constructor(
        private activeModal: NgbActiveModal,
        private censusService: CensusService
    ) {
        // Nothing to do
    }


    okModal() {
        if (this.scanValue && this.scanValue.length > 0) {
            // Make it upper case just in case
            this.scanValue = this.scanValue.toUpperCase();
            this.finding = true;
            const page = 0;
            const pageSize = 15000;
            const sort = 'MaterialPool.MaterialPoolID ASC';
            const expands: any = ['cv_CensusDiscrepancy', 'CensusMaterialPoolMaterial', 'MaterialPool', 'CensusMaterialPoolMaterial.Material.Animal', 'CensusMaterialPoolMaterial.Material.Line'];

            return this.censusService.getCensusMaterialPools({
                page,
                size: pageSize,
                sort,
                filter: {
                    C_Census_key: this.census.C_Census_key,
                    HousingKey: this.scanValue
                },
                expands
            }).then((response: any) => {
                if (response.results && response.results.length > 0) {
                    this.error = null;
                    this.activeModal.close(response.results[0]);
                } else {
                    this.error = "No such housing found in this Census";
                    this.finding = false;
                }
            }).catch((err: Error) => {
                this.error = "No such housing found in this Census";
                this.finding = false;
            });            
        }
    }

    cancelModal() {
        this.activeModal.dismiss();
    }
}

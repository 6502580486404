import {
    Component,
    EventEmitter,
    Input,
    Output,
    OnInit,
    OnChanges
} from '@angular/core';

import { LocationService } from './location.service';

@Component({
    selector: 'location-chooser',
    templateUrl: './location-chooser.component.html',
    styles: [`
      .add-child-control {
        padding: 2px;
      }
    `]
})
export class LocationChooserComponent implements OnInit, OnChanges {
    @Input() location: any;

    // TODO (kevin.stone): This should not be necessary
    //      but don't have time to figure out the correct interface.
    // Optional pass in key to automatically load LocationPosition if it is not in memory
    @Input() locationKey: number;

    @Input() readonly: boolean;
    @Input() required: boolean;
    @Output() locationChange: EventEmitter<any> = new EventEmitter<any>();
    @Output() rootLocationChange: EventEmitter<any> = new EventEmitter<any>();

    // cache all location queries for 1 minute
    readonly msToCacheData = 60 * 1000;

    // state
    ancestors: any[];
    locationList: any[];
    childCount: number;

    constructor(
        private locationService: LocationService
    ) {
        // nothing to do
    }

    ngOnInit() {
        // default required to true
        if (this.required !== false) {
            this.required = true;
        }
    }

    ngOnChanges(changes: any) {
        if (changes.location) {
            if (this.location) {
                this.initializeLocation();
            } else {
                this.ancestors = [];
                this.locationList = [];
                this.childCount = 0;
                this.initializeLocation();
            }
        }
    }

    initializeLocation(): Promise<void> {
        const locationPositionKey = this.location ? 
            this.location.C_LocationPosition_key : this.locationKey;

        if (!locationPositionKey) {
            return this.getDataForNullLocation();
        }

        return this.getAllLocationData(locationPositionKey);
    }

    /**
     * Handles selection of a location from the ancestor list.
     */
    selectAncestor(selectedLocation: any): void {
        this.location = selectedLocation;

        this.locationChange.emit(this.location);
    }

    /**
     * Handles selection of a location from the select list.
     */
    selectLocation(selectedLocation: any): void {
        if (this.ancestors.length === 0) {
            this.rootLocationChange.emit(selectedLocation);
        }
        this.locationChange.emit(this.location);
    }

    /**
     * Adds a child level beneath the current location.
     */
    addLevel(): void {
        const currentKey = this.location.C_LocationPosition_key;

        this.getChildren(currentKey).then((children: any[]) => {
            if (children.length > 0) {
                this.ancestors.push(this.location);

                // Set new location to first child of current location
                this.location = children[0];

                this.locationChange.emit(this.location);
            }
        });
    }

    /**
     * Gets all data for a location; sets member variables.
     */
    private getAllLocationData(locationKey: number): Promise<void> {
        return this.getAncestors(locationKey).then(() => {
            return this.getChildCount(locationKey);
        }).then(() => {
            return this.getLocationList();
        });
    }

    /**
     * Only loads root locations for workgroup
     *   to fill selector
     */
    private getDataForNullLocation(): Promise<void> {
        return this.getLocationList();
    }

    /**
     * Gets the ancestors of a location.
     */
    private getAncestors(locationKey: number): Promise<void> {
        return this.locationService.getParents(locationKey, this.msToCacheData)
            .then((data: any[]) => {
                this.ancestors = data;
            });
    }

    /**
     * Gets the child count for a location.
     */
    private getChildCount(locationKey: number): Promise<void> {
        return this.locationService.getChildPositionsCount(locationKey, true)
            .then((count: number) => {
                this.childCount = count;
            });
    }

    /**
     * Gets the location list containing the children of the current location's parent
     * (i.e., siblings of the current).
     */
    private getLocationList(): Promise<void> {
        let parentLocationKey: number = null;

        const ancestorCount: number = this.ancestors.length;
        if (ancestorCount > 0) {
            parentLocationKey = this.ancestors[ancestorCount - 1].C_LocationPosition_key;
        }

        return this.getChildren(parentLocationKey)
            .then((data: any[]) => {
                this.locationList = data.filter((item: any) => {
                    // only include active locations in list unless it's the current location
                    return item.IsActive === true || item.C_LocationPosition_key === this.location.C_LocationPosition_key;
                });
            });
    }

    private getChildren(parentLocationKey: number): Promise<any[]> {
        return this.locationService.getChildPositionsWithoutChildEntities(
            parentLocationKey,
        );
    }
}

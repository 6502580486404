<div *ngIf="options" class="climb-grid-wrap"
     #gridWrap
     climbResizeObserver
     (resize)="gridWrapResize.next($event)">
    <ag-grid-angular #agGrid
                     class="climb-grid ag-theme-alpine"
                     [rowHeight]="rowHeight"
                     [headerHeight]="rowHeight"
                     [gridOptions]="gridOptions"
                     [columnDefs]="gridOptions.columnDefs"
                     [defaultColDef]="gridOptions.defaultColDef"
                     [rowModelType]="'infinite'"
                     [pagination]="true"
                     [paginationPageSize]="pageSize"
                     [enableCellTextSelection]="true"
                     [ensureDomOrder]="true"
                     (dragStopped)="columnDragStopped($event)"
                     (selectionChanged)="selectionChanged()"
                     (sortChanged)="sortChanged()"></ag-grid-angular>
</div>

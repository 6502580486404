import { Subscription } from "rxjs";
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { BaseDetail, BaseDetailService, FacetView, IFacet } from "../../../common/facet";
import { cv_DataType, cv_StudyType, StudyCharacteristic } from "../../../common/types";
import { StudyCharacteristicStudyType } from "../../../common/types/models/study-characteristic-study-type.interface";
import { DataManagerService } from "../../../services/data-manager.service";
import { IValidatable, SaveChangesService } from "../../../services/save-changes.service";
import { TranslationService } from "../../../services/translation.service";
import { CharacteristicVocabService } from "../../characteristic-vocab.service";
import { CharacteristicService } from "../../characteristic.service";
import type { EditResult } from "../../characteristics.interface";

@Component({
    selector: "study-characteristic-edit",
    templateUrl: "./study-characteristic-edit.component.html"
})
export class StudyCharacteristicEditComponent extends BaseDetail implements OnInit, OnDestroy, IValidatable {
    @Input() facet: IFacet;
    @Input() facetView: FacetView;
    @Input() facetPrivilege: string;
    @Input() studyCharacteristic: StudyCharacteristic;

    @Output() editEnd: EventEmitter<EditResult> = new EventEmitter<EditResult>();

    dataTypes: cv_DataType[];
    studyTypes: cv_StudyType[] = [];

    selectedStudyTypes: number[] = [];
    studyNameTranslated: string;

    editResult: EditResult = {
        isCanceled: false,
        isSaved: false,
    };

    subscriptions: Subscription = new Subscription();

    constructor(
        baseDetailService: BaseDetailService,
        private _characteristicService: CharacteristicService,
        private _characteristicVocabService: CharacteristicVocabService,
        private _dataManagerService: DataManagerService,
        private _saveChangesService: SaveChangesService,
        private _translationService: TranslationService
    ) {
        super(baseDetailService);
    }

    ngOnInit() {
        this._saveChangesService.registerValidator(this);

        this._characteristicVocabService.getDataTypes().then((results: any[]) => {
            this.dataTypes = results.filter((item: cv_DataType) => item.ShowInCharacteristics && item.DataType !== 'Vocabulary');
        });

        this._characteristicVocabService.getStudyTypes().then((results: cv_StudyType[]) => this.studyTypes = results);
        this.setSelectedStudyTypes();

        this._characteristicVocabService.getStudyTypes()
            .then((results: cv_StudyType[]) => this.studyTypes = results.filter((cv: cv_StudyType) => cv.IsActive || this.selectedStudyTypes.includes(cv.C_StudyType_key)));

        this.studyNameTranslated = this._translationService.getTranslatedStudyName();

        const subscription = this._saveChangesService.saveSuccessful$.subscribe(() => {
            this.editResult.isSaved = true;
        });
        this.subscriptions.add(subscription);
    }


    setSelectedStudyTypes(): void {
        this.selectedStudyTypes = this.studyCharacteristic.StudyCharacteristicStudyType.map((scst: StudyCharacteristicStudyType) => scst.cv_StudyType.C_StudyType_key);
        this.studyCharacteristic.HasStudyType = this.selectedStudyTypes.length > 0;
    }

    onStudyTypeChange(studyTypeKeys: number[]): void {
        const initialStudyCharacteristicStudyTypes = this.studyCharacteristic.StudyCharacteristicStudyType;
        const initialStudyTypeKeys = initialStudyCharacteristicStudyTypes.map((scst: StudyCharacteristicStudyType) => scst.cv_StudyType.C_StudyType_key);
        const initialValues: any = [];

        for (const key of studyTypeKeys) {
            if (!initialStudyTypeKeys.includes(key)) {
                initialValues.push({
                    C_StudyType_key: key,
                    C_StudyCharacteristic_key: this.studyCharacteristic.C_StudyCharacteristic_key
                });
            }
        }

        const toDelete = initialStudyCharacteristicStudyTypes.filter((scst: StudyCharacteristicStudyType) => {
            return !studyTypeKeys.includes(scst.C_StudyType_key);
        });

        for (const e of toDelete) {
            this._dataManagerService.deleteEntity(e);
        }

        for (const value of initialValues) {
            this._dataManagerService.createEntity("StudyCharacteristicStudyType", value);
        }
        this.studyCharacteristic.HasStudyType = this.selectedStudyTypes.length > 0;

        const selectedStudyTypes = this.studyCharacteristic.StudyCharacteristicStudyType.map((scst: StudyCharacteristicStudyType) => scst.cv_StudyType.StudyType);
        this.studyCharacteristic.StudyTypes = selectedStudyTypes.join(', ');
    }

    ngOnDestroy() {
        this._saveChangesService.unregisterValidator(this);
        this.subscriptions.unsubscribe();
    }

    onSaveCharacteristic(): void {
        this._saveChangesService.saveChanges(this.facet.FacetName, true);
    }

    async validate(): Promise<string> {
        if (this.selectedStudyTypes.length === 0) {
            return "Study Type is required";
        }
        return this._characteristicService.validateCommonCharacteristic(this.studyCharacteristic);
    }

    onCancel(): void {
        this.editResult.isCanceled = true;
        this.editEnd.emit(this.editResult);
    }
}

import { AfterViewChecked, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Column, EntityTableService } from "./entity-table.service";
import { Entity } from "@common/types";
import { IFacet } from "@common/facet";

export interface ICohortMaterialChangeEvent {
    action: 'remove';
    entity: Entity<any>;
}

@Component({
    selector: 'entity-table',
    templateUrl: './entity-table.component.html',
    providers: [EntityTableService]
})
export class EntityTableComponent<T> implements OnInit, AfterViewChecked {
    @Input() tableTitle: string;
    @Input() facet: IFacet;
    @Input() entities: Entity<any>[];
    @Input() columns: Column[];

    @Output() copy = new EventEmitter();
    @Output() remove = new EventEmitter<ICohortMaterialChangeEvent>();
    @Output() columnSelectChange = new EventEmitter();
    
    prior: string;

    constructor(public entityTableService: EntityTableService<T>) {}

    ngOnInit(): void {
        this.entityTableService.registerColumns(this.columns);
        this.entityTableService.registerEntities(this.entities);
    }

    ngAfterViewChecked() {
        /**
         * This will update the column select options in case multiple tables need to be in sync with each other
         */
        if (this.columns) {
            const current = this.columns.map(c => c.visible).toString();
            if (this.prior !== current) {
                this.prior = current;
                this.entityTableService.registerColumns(this.columns);
            }
        }
    }

    onCopyClicked() {
        this.copy.emit();
    }

    onRemoveClicked(event: Entity<T>): void {
        if (event.entityAspect.setDeleted) {
            this.entityTableService.removeEntity(event);
        }
        this.remove.emit({ action: 'remove', entity: event });
    }
    
    async onColumnSelectChange(model: string[]) {
        await this.entityTableService.selectColumn(model);
        this.columnSelectChange.emit();
    }
}

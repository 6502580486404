<table class="table table-nonfluid">
    <thead>
        <tr>
            <th class="icon-cell"
                *ngIf="facetPrivilege === 'ReadWrite'">
                <a class="add-item-link" (click)="addPlugDate()">
                    <i class="fa fa-plus-circle" title="Add plug date"></i>
                </a>
            </th>
            <th>Dam</th>
            <th>Plug Date</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let plugDate of plugDates">
            <td class="icon-cell"
                *ngIf="facetPrivilege === 'ReadWrite'">
                <div class="form-control-plaintext">
                    <a (click)="removePlugDate(plugDate)">
                        <i class="fa fa-remove remove-item" title="Delete plug date"></i>
                    </a>
                </div>
            </td>
            <td>
                <select name="plugDateDam"
                        [(ngModel)]="plugDate.C_DamMaterial_key"
                        [required]="required"
                        class="form-control input-medium">
                    <option></option>
                    <option *ngFor="let item of mating.MaterialPool.MaterialPoolMaterial | housingSexFilter:'Female'"
                            [value]="item.C_Material_key">
                        {{item.Material?.Animal?.AnimalName}}
                    </option>
                </select>
            </td>
            <td>
                <climb-ngb-date #dateControl="ngModel"
                                name="Date Plug"
                                [(ngModel)]="plugDate.DatePlug"
                                [required]="required"></climb-ngb-date>
            </td>
        </tr>
    </tbody>
</table>

import {BehaviorSubject, Subject} from 'rxjs';
import {DataManagerService} from './data-manager.service';
import {LoggingService} from './logging.service';
import {FeatureFlagService} from './feature-flags.service';
import {Entity, Entity as BreezeEntity, EntityState, EntityStateSymbol} from 'breeze-client';
import {ReasonForChangeService} from '@common/reason-for-change/reason-for-change.service';
import {ToastrService} from '@services/toastr.service';
import {IGlpFacetSaveService} from '@services/interfaces/glp-facet-save-service.interface';
import {IGlpFacetStateService} from '@services/interfaces/glp-facet-state-service.interface';
import {DeletionService} from '@services/deletion.service';
import { IMultiStepSavingContext } from '@services/interfaces/multi-step-saving-context.interface';
import { FacetLevelSaveOptions } from '@services/breeze-helpers';
import { faker } from '@faker-js/faker';


export abstract class GlpOdataFacetSaveService implements IGlpFacetSaveService {
    protected saveEntityLoadingSource = new BehaviorSubject<boolean>(false);
    saveEntityLoading$ = this.saveEntityLoadingSource.asObservable();

    protected saveSuccessfulSource = new Subject();
    saveSuccessful$ = this.saveSuccessfulSource.asObservable();

    protected saveFailedSource = new Subject<void>();
    saveFailed$ = this.saveFailedSource.asObservable();

    protected abstract saveSource = '';

    protected constructor(
        protected dataManagerService: DataManagerService,
        protected deletionService: DeletionService,
        protected loggingService: LoggingService,
        protected featureFlagService: FeatureFlagService,
        protected reasonForChangeService: ReasonForChangeService,
        protected toastrService: ToastrService,
        protected facetStateService: IGlpFacetStateService,
    ) {
    }

    async save(entity: BreezeEntity): Promise<void> {
        this.saveEntityLoadingSource.next(true);

        let changes = this.facetStateService.getAllRelatedChanges(entity);
        let reasonForChange = '';

        try {
            // mark current entity as modified if unchanged
            if (entity.entityAspect.entityState == EntityState.Unchanged) {
                changes = [entity, ...changes];
                entity.entityAspect.setModified();
            }

            if (this.featureFlagService.isFlagOn('IsGLP')) {
                let statesToFilter: EntityStateSymbol[];

                if (entity.entityAspect.entityState == EntityState.Added) {
                    statesToFilter = [EntityState.Modified, EntityState.Deleted];
                } else {
                    statesToFilter = [EntityState.Added, EntityState.Modified, EntityState.Deleted];
                }

                const modifiedOrDeletedEntities: BreezeEntity[] = this.dataManagerService.filterEntitiesByEntityState(changes, statesToFilter);

                if (modifiedOrDeletedEntities.length > 0) {
                    const reasonForChangeResult = await this.reasonForChangeService.getChangeInfoForFacet(modifiedOrDeletedEntities);
                    changes = [...changes, ...reasonForChangeResult.modificationRecords];
                    reasonForChange = reasonForChangeResult.reasonForChange;
                }
            } else {
                const deletionRecordBatch = this.deletionService.handleDeletedItemsInBatch(changes);
                changes = [...changes, ...deletionRecordBatch];
            }
            const batches: Entity[][] = this.dataManagerService.prepareSaveBatches(changes);
            await this.dataManagerService.saveFacetChanges(batches, this.saveSource, reasonForChange);

            this.loggingService.logFacetSaveSuccess(this.saveSource, true);
            this.saveSuccessfulSource.next();
        } catch (error) {
            this.loggingService.logError(error.message, error, this.saveSource, false);
            this.saveFailedSource.next();

            throw error;
        } finally {
            this.saveEntityLoadingSource.next(false);
        }
    }

    async saveInMultipleSteps(multiStepSavingContext: IMultiStepSavingContext, entity: BreezeEntity): Promise<IMultiStepSavingContext> {
        this.saveEntityLoadingSource.next(true);
        let saveOptions;
        let changes = this.facetStateService.getAllRelatedChanges(entity);
        let reasonForChange = '';
        try {
            // mark current entity as modified if unchanged
            if (entity.entityAspect.entityState == EntityState.Unchanged) {
                changes = [entity, ...changes];
                entity.entityAspect.setModified();
            }

            if (this.featureFlagService.isFlagOn('IsGLP')) {
                let statesToFilter: EntityStateSymbol[];

                if (entity.entityAspect.entityState == EntityState.Added) {
                    statesToFilter = [EntityState.Modified, EntityState.Deleted];
                } else {
                    statesToFilter = [EntityState.Added, EntityState.Modified, EntityState.Deleted];
                }

                const modifiedOrDeletedEntities: BreezeEntity[] = this.dataManagerService.filterEntitiesByEntityState(changes, statesToFilter);

                if (modifiedOrDeletedEntities.length > 0) {
                    const reasonForChangeResult = await this.reasonForChangeService.getChangeInfoForFacet(
                        modifiedOrDeletedEntities, multiStepSavingContext);
                    changes = [...changes, ...reasonForChangeResult.modificationRecords];
                    reasonForChange = reasonForChangeResult.reasonForChange;
                }
            } else {
                const deletionRecordBatch = this.deletionService.handleDeletedItemsInBatch(changes);
                changes = [...changes, ...deletionRecordBatch];
            }
            const batches: Entity[][] = this.dataManagerService.prepareSaveBatches(changes);


            if (!multiStepSavingContext) {
                saveOptions = new FacetLevelSaveOptions();
                saveOptions.operationId = faker.datatype.uuid();
                saveOptions.facetName = this.saveSource;
                saveOptions.reasonForChange = reasonForChange;
            } else {
                saveOptions = multiStepSavingContext.facetLevelSaveOptions;
            }

            await this.dataManagerService.saveChangeBatches(batches, saveOptions);
            this.loggingService.logFacetSaveSuccess(this.saveSource, true);
            this.saveSuccessfulSource.next();

        } catch (error) {
            this.loggingService.logError(error.message, error, this.saveSource, false);
            this.saveFailedSource.next();

            throw error;
        } finally {
            this.saveEntityLoadingSource.next(false);
        }
        return { facetLevelSaveOptions: saveOptions };
    }

    async bulkSave(entities: BreezeEntity[], isAddOperation = false, saveSource: string = this.saveSource): Promise<void> {
        this.saveEntityLoadingSource.next(true);

        let changes = entities;

        let reasonForChange = '';
        try {
            if (this.featureFlagService.isFlagOn('IsGLP')) {
                let statesToFilter: EntityStateSymbol[];

                if (isAddOperation) {
                    statesToFilter = [EntityState.Modified, EntityState.Deleted];
                } else {
                    statesToFilter = [EntityState.Added, EntityState.Modified, EntityState.Deleted];
                }

                const modifiedOrDeletedEntities: BreezeEntity[] = this.dataManagerService.filterEntitiesByEntityState(changes, statesToFilter);

                if (modifiedOrDeletedEntities.length > 0) {
                    const reasonForChangeResult = await this.reasonForChangeService.getChangeInfoForFacet(modifiedOrDeletedEntities);
                    changes = [...changes, ...reasonForChangeResult.modificationRecords];
                    reasonForChange = reasonForChangeResult.reasonForChange;
                }
            } else {
                const deletionRecordBatch = this.deletionService.handleDeletedItemsInBatch(changes);
                changes = [...changes, ...deletionRecordBatch];
            }
            const batches: Entity[][] = this.dataManagerService.prepareSaveBatches(changes);
            await this.dataManagerService.saveFacetChanges(batches, saveSource, reasonForChange);

            this.loggingService.logFacetSaveSuccess(saveSource, true);
            this.saveSuccessfulSource.next();
        } catch (error) {
            this.loggingService.logError(error.message, error, this.saveSource, false);
            this.saveFailedSource.next();

            throw error;
        } finally {
            this.saveEntityLoadingSource.next(false);
        }
    }
}

<climb-spinner *ngIf="pending; else buttonContent"
               [size]="size | mapFn : spinnerSize"
               [light]="variant | mapFn : isLightSpinner">
</climb-spinner>
<ng-template #buttonContent>
    <ng-content select="[climbIcon]:not([iconPositionEnd]), [positionStart]"></ng-content>

    <span #buttonLabel class="button-label"><ng-content></ng-content></span>

    <ng-content select="[climbIcon][iconPositionEnd], [positionEnd]"></ng-content>
</ng-template>

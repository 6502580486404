import { map } from 'rxjs/operators';
import { MatingService } from './../mating.service';
import { MatingVocabService } from './../mating-vocab.service';
import { QueryDef } from './../../services/query-def';
import { 
    BulkEditOptions, 
    BulkEditSection 
} from '../../common/facet/models';
import {
    Component,
    Input,
    OnInit,
    TemplateRef,
    ViewChild,
    AfterViewInit,
    ViewChildren,
} from '@angular/core';

import { MatingLogicService } from './../mating-logic.service';

import { notEmpty } from '../../common/util';

import { FacetLoadingStateService } from '../../common/facet';
import { TranslationService } from '../../services/translation.service';
import { NgModel } from '@angular/forms';
import { dateControlValidator } from '@common/util/date-control.validator';
import { Mating } from '@common/types';

/**
 * Shared component and configuration templates
 * for BulkAdd and BulkEdit tables
 * 
 * 
 */
@Component({
    selector: 'mating-bulk-templates',
    templateUrl: './mating-bulk-templates.component.html'
})
export class MatingBulkTemplatesComponent implements OnInit, AfterViewInit {
    @ViewChildren('dateControl') dateControls: NgModel[];

    @Input() matings: any[];
    // Active and required fields set by facet settings
    @Input() activeFields: string[];
    @Input() requiredFields: string[];

    // bulk edit input templates
    @ViewChild('housingIdTmpl') housingIdTmpl: TemplateRef<any>;
    @ViewChild('idTmpl') idTmpl: TemplateRef<any>;
    @ViewChild('statusTmpl') statusTmpl: TemplateRef<any>;
    @ViewChild('lineTmpl') lineTmpl: TemplateRef<any>;
    @ViewChild('dateMatingTmpl') dateMatingTmpl: TemplateRef<any>;
    @ViewChild('typeTmpl') typeTmpl: TemplateRef<any>;
    @ViewChild('purposeTmpl') purposeTmpl: TemplateRef<any>;
    @ViewChild('commentsTmpl') commentsTmpl: TemplateRef<any>;

    // vocabs
    containerTypes: any[] = []; 
    matingTypes: any[] = [];        
    matingPurposes: any[] = [];
    matingLines: any[] = [];
    matingStatuses: any[] = [];
    
    rootLocationPositionPromise: Promise<any>;
    rootLocationPosition: any;

    readonly COMPONENT_LOG_TAG = 'mating-bulk-edit';

    bulkOptions: BulkEditOptions;
    BulkEditSection = BulkEditSection;

    constructor(
        private matingVocabService: MatingVocabService,
        private facetLoadingState: FacetLoadingStateService,
        private matingService: MatingService,
        private matingLogicService: MatingLogicService,
        private translationService: TranslationService
    ) {
    }

    // lifecycle
    ngOnInit() {
        this.initialize();
        this.getData();
    }

    /**
     * Configuration with TemplateRefs can only be assigned
     *   after "ngAfterViewInit".
     * Otherwise they will be undefined.
     */
    ngAfterViewInit() {
        // assign all the BulkAdd and BulkEdit configuration options
        this.bulkOptions = {
            itemTypeLabel: "Mating Unit",
            itemTypeLabelPlural: "Mating Units",
            clearForm: false,
            fields: [            
                {
                    label: "Housing ID",
                    modelPath: 'MaterialPool?.MaterialPoolID',
                    template: this.housingIdTmpl,
                    hideFromAddScreen: true,
                    hideFromEditHeader: true
                },
                {
                    label: "Mating ID",
                    modelPath: 'MatingID',
                    template: this.idTmpl,
                    hideFromAddScreen: true,
                    hideFromEditHeader: true
                },
                {
                    label: 'Status',
                    modelPath: 'C_MatingStatus_key',
                    template: this.statusTmpl,
                    inactive: this.activeFields && !this.activeFields.includes('Status')
                },
                {
                    label: this.translationService.translate('Line'),
                    modelPath: 'C_Line_key',
                    template: this.lineTmpl,
                    onUpdateItem: (item, value) => {
                        this.fillDownLine(value);
                    },
                    inactive: this.activeFields && !this.activeFields.includes('Line')
                },
                {
                    label: 'Date',
                    modelPath: 'DateMating',
                    template: this.dateMatingTmpl,
                    inactive: this.activeFields && !this.activeFields.includes('Date'),
                    onItemInit: (item: Partial<Mating>) => {
                        item.DateMating = new Date();
                    },
                },
                {
                    label: 'Type',
                    modelPath: 'C_MatingType_key',
                    template: this.typeTmpl,
                    inactive: this.activeFields && !this.activeFields.includes('Type')
                },
                {
                    label: 'Purpose',
                    modelPath: 'C_MatingPurpose_key',
                    template: this.purposeTmpl,
                    inactive: this.activeFields && !this.activeFields.includes('Purpose')
                },
                {
                    label: 'Comments',
                    modelPath: 'Comments',
                    template: this.commentsTmpl,
                    inactive: this.activeFields && !this.activeFields.includes('Comments')
                },                
            ]
        };
    }

    initialize() {
        // Copy the input so we don't touch the grid data
        this.matings = this.matings.slice();
    }

    getData() {
        this.facetLoadingState.changeLoadingState(true);

        return this.getDetails().then(() => {
            return this.getCVs();
        }).then(() => {
            this.facetLoadingState.changeLoadingState(false);
        }).catch((error) => {
            this.facetLoadingState.changeLoadingState(false);
            throw error;
        });
    }

    getDetails(): Promise<any[]> {
        if (notEmpty(this.matings)) {
            const queryDef: QueryDef = {
                page: 0,
                size: this.matings.length,
                filter: {
                    matingsKeys: this.matings.map((mating) => {
                        return mating.C_Mating_key;
                    })
                }
            };

            return this.matingService.getMatings(queryDef).then((result) => {                
                return result.results;
            });
        }

        return Promise.resolve(this.matings);
    }

    getCVs(): Promise<any> {
        const p1: Promise<any> = this.matingVocabService.matingTypes$.pipe(map((data) => {
            this.matingTypes = data;
        })).toPromise();

        const p2: Promise<any> = this.matingVocabService.matingPurposes$.pipe(map((data) => {
            this.matingPurposes = data;
        })).toPromise();
                
        const p3: Promise<any> = this.matingVocabService.matingStatuses$.pipe(map((data) => {
            this.matingStatuses = data;
        })).toPromise();

        return Promise.all([p1, p2, p3]);
    }

    // <select> formatters
    matingTypeKeyFormatter = (value: any) => {
        return value.C_MatingType_key;
    }    
    matingTypeFormatter = (value: any) => {
        return value.MatingType;
    }   
    matingPurposeKeyFormatter = (value: any) => {
        return value.C_MatingPurpose_key;
    }
    matingPurposeFormatter = (value: any) => {
        return value.MatingPurpose;
    }
    matingStatusKeyFormatter = (value: any) => {
        return value.C_MatingStatus_key;
    }
    matingStatusFormatter = (value: any) => {
        return value.MatingStatus;
    }

    fillDownLine(lineKey: number) {
        for (const mating of this.matings) {
            mating.C_Line_key = lineKey;
            this.onLineSelection(lineKey, mating);
        }
    }
    onLineSelection(lineKey: number, mating: any): Promise<any> {
        return this.matingLogicService.onLineSelection(lineKey, mating);
    }

    validate() {
        return dateControlValidator(this.dateControls);
    }
}

/**
 * defines count of sources done per task
 */
export class StatusCount {
    endState = 0;
    total = 0;

    get done(): boolean {
        return (this.total > 0) && (this.endState === this.total);
    }
}

export interface StatusCountMap {
    [index: number]: StatusCount;
}

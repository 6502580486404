import { Injectable } from "@angular/core";
import { ScrollTypes } from "@common/export";
import { ScrollEventInterface } from "@common/types/models/scroll-event.interface";
import { BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class ScrollEventService { 
    private scrollWorkspaceView$: BehaviorSubject<Partial<{
        [key in ScrollTypes]: ScrollEventInterface
    }>> = new BehaviorSubject({});

    public get scrollWorkspaceView() {
        return this.scrollWorkspaceView$.asObservable();
    }

    public set scrollEventEmit({event, name}: {event: Event, name: ScrollTypes}) {
        this.scrollWorkspaceView$.next({
            ...this.scrollWorkspaceView$.getValue(),
            [name]: {
                event,
                element: event.target as HTMLElement,
                scroll: (event.target as HTMLElement)?.scrollTop,
                name 
            }
        });
    }
}

import {
    Component,
    Input,
    OnChanges,
    OnInit,
} from '@angular/core';

@Component({
    selector: 'animal-cohort-table',
    templateUrl: './animal-cohort-table.component.html'
})
export class AnimalCohortTableComponent {
    @Input() animal: any;
    @Input() cohortMaterials: any[];
    @Input() readonly: boolean;
}

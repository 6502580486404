import type { Job } from './job.interface';
import type { LocationPosition } from './location-position.interface';

export interface JobLocation {
    C_JobLocation_key: number;
    C_Job_key: number | null;
    C_LocationPosition_key: number;
    C_Workgroup_key: number;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    Job: Job;
    LocationPosition: LocationPosition;
    ModifiedBy: string;
    Version: number;
}

import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { cv_DataType, EnumerationClass, JobCharacteristic, SampleCharacteristic, StudyCharacteristic, TaxonCharacteristic } from "../../common/types";
import { getSafeProp } from "../../common/util";
import { TranslationService } from "../../services/translation.service";
import { CharacteristicVocabService } from "../characteristic-vocab.service";
import { CharacteristicService } from "../characteristic.service";
import { CharacteristicTypeNameEnum } from "../models/characteristic-type-name.enum";

@Component({
    selector: 'characteristic-data-type',
    templateUrl: './characteristic-data-type.component.html'
})
export class CharacteristicDataTypeComponent implements OnInit {
    @Input() characteristic: JobCharacteristic | TaxonCharacteristic | StudyCharacteristic | SampleCharacteristic;
    @Input() enumerationClasses: EnumerationClass[];
    @Input() dataTypes: cv_DataType[];
    @Input() characteristicType: string;

    @Output() enumerationChanged: EventEmitter<EnumerationClass> = new EventEmitter<EnumerationClass>();
    @Output() vocabularyChanged: EventEmitter<any> = new EventEmitter<any>();
    @Output() dataTypeChanged: EventEmitter<cv_DataType> = new EventEmitter<cv_DataType>();

    beingUsed: boolean;
    tooltip: string;
    numberStep: string;

    vocabularyClasses = [
        {
            C_VocabularyClass_key: 1,
            ClassName: "Test Article"
        },
        {
            C_VocabularyClass_key: 2,
            ClassName: "IACUC Protocol"
        }
    ];

    dataTypeFormatter = (d: any) => getSafeProp(d, 'DataType');
    dataTypeKeyFormatter = (d: any) => getSafeProp(d, 'C_DataType_key');

    constructor(
        private _characteristicService: CharacteristicService,
        private _characteristicVocabService: CharacteristicVocabService,
        private _translationService: TranslationService) {
    }

    async ngOnInit(): Promise<void> {
        this.beingUsed = await this.isBeingUsed();
        this.enumerationClasses = await this._characteristicVocabService.getEnumerationClasses();
        if (this.beingUsed) {
            this.tooltip = this.getDisabledTooltip();
        }
        this.dataChanged(true);
    }

    onEnumerationChanged(enumerationClass: EnumerationClass): void {
        this.enumerationChanged.emit(enumerationClass);
    }

    onVocabularyChanged(vocabulary: any): void {
        this.vocabularyChanged.emit(vocabulary);
    }


    dataChanged(isInit: boolean) {
        if (this.dataTypeIsNumber()) {
            this.setNumberStep(this.characteristic.NumericScale);
        }
        // Clear previous values
        if (!isInit) {
            const fields = ['ValidationMaximum', 'ValidationMinimum', 'NumericScale',
                'DefaultValue', 'C_EnumerationClass_key', 'TextLineCount', 'C_VocabularyClass_key'];
            for (const field of fields) {
                if (this.characteristic[field]) {
                    this.characteristic[field] = null;
                }
            }
        }
        this.dataTypeChanged.emit(this.characteristic.cv_DataType);
    }

    dataTypeIsNumber(): boolean {
        return getSafeProp(this.characteristic, 'cv_DataType.DataType') === 'Number';
    }

    setNumberStep(scale: number) {
        if (scale !== null && scale >= 0) {
            this.numberStep = Math.pow(10, -1 * scale) + '';
        } else {
            this.numberStep = 'any';
        }
    }

    isBeingUsed(): Promise<boolean> {
        let beingUsedPromise: Promise<boolean>;

        switch (this.characteristicType) {
            case CharacteristicTypeNameEnum.Taxon:
                beingUsedPromise = this._characteristicService
                    .isTaxonCharacteristicBeingUsed(this.characteristic);
                break;
            case CharacteristicTypeNameEnum.Sample:
                beingUsedPromise = this._characteristicService
                    .isSampleCharacteristicBeingUsed(this.characteristic);
                break;
            case CharacteristicTypeNameEnum.Job:
                beingUsedPromise = this._characteristicService
                    .isJobCharacteristicBeingUsed(this.characteristic);
                break;
            case CharacteristicTypeNameEnum.Study:
                beingUsedPromise = this._characteristicService
                    .isStudyCharacteristicBeingUsed(this.characteristic);
                break;
        }

        return beingUsedPromise;
    }

    getDisabledTooltip(): string {
        let tooltip = '';

        switch (this.characteristicType) {
            case CharacteristicTypeNameEnum.Taxon:
                tooltip = 'This Characteristic has been used in previous animals. Changing the Data Type is not permitted.';
                break;
            case CharacteristicTypeNameEnum.Sample:
                tooltip = 'This Characteristic has been used in previous samples. Changing the Data Type is not permitted.';
                break;
            case CharacteristicTypeNameEnum.Job:
                const jobs = this._translationService.translate('Jobs').toLowerCase();
                tooltip = `This Characteristic has been used in previous ${jobs}. Changing the Data Type is not permitted.`;
                break;
            case CharacteristicTypeNameEnum.Study:
                const studies = this._translationService.translate('Studies').toLowerCase();
                tooltip = `This Characteristic has been used in previous ${studies}. Changing the Data Type is not permitted.`;
                break;
        }

        return tooltip;
    }


}

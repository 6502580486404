<!-- Table for Test Articles -->
<div class="form-group row">
    <label class="col-md-2 col-form-label"
           [ngClass]="{'required-text' : required}">Test Article</label>
    <div class="col-md-10">
        <table class="table">
            <thead>
                <tr>
                    <th class="icon-cell col-size-fit" *ngIf="job.C_Job_key > 0">
                        <a (click)="addJobTestArticle(job)"
                           class="add-item-link"
                           *ngIf="!readonly">
                            <i class="fa fa-plus-circle" title="Add Test Article and Batch"></i>
                        </a>
                    </th>
                    <th class="icon-cell col-size-fit" *ngIf="job.C_Job_key < 0">
                        <a (click)="showMessage()"
                           class="add-item-link"
                           *ngIf="!readonly">
                            <i class="fa fa-plus-circle" style="color: #8996A4;"></i>
                        </a>
                    </th>
                    <th>ID</th>
                    <th>Test Article</th>
                    <th>Batch</th>
                    <th *ngIf="isCRO">Vehicle</th>
                    <th *ngIf="isCRO">Purity</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let jobTestArticle of job.JobTestArticle">
                    <ng-container *ngIf="jobTestArticle.C_TestArticle_key !== systemGeneratedTestArticleKey">
                        <td>
                            <a *ngIf="!readonly && (!jobTestArticle.UsedInTreatment || !isCRO)" (click)="removeTestArticle(jobTestArticle)">
                                <i class="fa fa-fw fa-remove remove-item"
                                   title="Delete Test Article"></i>
                            </a>
                        </td>
                        <td>
                            {{jobTestArticle.Identifier}}
                        </td>
                        <td>
                            <dotmatics-test-article-select *ngIf="dropdownList && !loading"
                                                           [(model)]="jobTestArticle.C_TestArticle_key"
                                                           [required]="required"
                                                           [disabled]="jobTestArticle.UsedInTreatment && isCRO"
                                                           [testArticles]="dropdownList"
                                                           [jobTestArticle]="jobTestArticle"
                                                           (modelChange)="testArticleChangeHandler(jobTestArticle,$event)">
                            </dotmatics-test-article-select>
                        </td>
                        <td>
                            <select *ngIf="isCRO"
                                    name="batch"
                                    [(ngModel)]="jobTestArticle.Batch"
                                    class="form-control input-medium"
                                    (ngModelChange)="batchChangeHandler(jobTestArticle, $event)">
                                <option></option>
                                <option *ngFor="let batch of jobTestArticle.batches"
                                        [value]="batch.BatchId">
                                    {{batch.BatchId}}
                                </option>
                            </select>
                            <input *ngIf="!isCRO"
                                   type="text"
                                   maxlength="200"
                                   name="batch-nonCRO"
                                   #name="ngModel"
                                   [(ngModel)]="jobTestArticle.Batch"
                                   [readonly]="readonly"
                                   class="form-control input-medium" />
                        </td>
                        <td *ngIf="isCRO">
                            <div class="input-group">
                                <input type="text"
                                       maxlength="200"
                                       name="vehicle"
                                       #name="ngModel"
                                       [(ngModel)]="jobTestArticle.Vehicle"
                                       [readonly]="readonly || jobTestArticle.VehicleLock"
                                       class="form-control input-large" />
                                <div class="input-group-append">
                                    <button type="button"
                                            title="Refresh Vehicle"
                                            class="btn btn-secondary"
                                            (click)="getTestArticle(jobTestArticle)">
                                        <i class="fa fa-history"></i>
                                    </button>
                                </div>
                            </div>
                        </td>
                        <td *ngIf="isCRO">
                            <input type="number"
                                   step="any"
                                   min="0"
                                   name="purity"
                                   #name="ngModel"
                                   [(ngModel)]="jobTestArticle.Purity"
                                   (ngModelChange)="purityChanged(jobTestArticle)"
                                   [readonly]="readonly || jobTestArticle.Batch && jobTestArticle.Batch !== ''"
                                   class="form-control input-medium" />
                        </td>
                    </ng-container>
                </tr>
            </tbody>
        </table>
    </div>
</div>

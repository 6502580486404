import type { Institution } from './institution.interface';
import type { JobInstitution } from './job-institution.interface';
import type { JobInstitutionBillingContact } from './job-institution-billing-contact.interface';
import type { Site } from './site.interface';
import type { cv_ContactPersonType } from './cv-contact-person-type.interface';
import type { cv_Country } from './cv-country.interface';

export interface ContactPerson {
    Address: string;
    AuthorizationContactJobInstitution: JobInstitution[];
    BillingContactJobInstitution: JobInstitution[];
    C_ContactPersonType_key: number | null;
    C_ContactPerson_key: number;
    C_Country_key: number | null;
    C_Institution_key: number | null;
    C_Site_key: number | null;
    C_VocabularySource_key: number | null;
    C_Workgroup_key: number;
    City: string;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    EmailAddress: string;
    Extension: string;
    ExternalID: string;
    FirstName: string;
    Institution: Institution;
    IsActive: boolean;
    JobInstitutionBillingContact: JobInstitutionBillingContact[];
    LastName: string;
    MobilePhone: string;
    ModifiedBy: string;
    NameSuffix: string;
    Phone: string;
    PostalCode: string;
    Salutation: string;
    ScientificContactJobInstitution: JobInstitution[];
    ShippingAddress: string;
    ShippingEmailAddress: string;
    Site: Site;
    State: string;
    Title: string;
    Version: number;
    cv_ContactPersonType: cv_ContactPersonType;
    cv_Country: cv_Country;
}

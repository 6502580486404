import { UsersSharedModule } from './../user/users-shared.module';
import { DataTypeModule } from './../data-type/data-type.module';
import { FacetSharedModule } from './../common/facet/facet-shared.module';
import { TasksSharedModule } from './tasks-shared.module';
import { EnumerationsSharedModule } from './../enumerations/enumerations-shared.module';
import { ClimbCommonModule } from './../common/climb-common.module';
import { WorkspacesSharedModule } from './../workspaces/workspaces-shared.module';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { CalculatedOutputComponent } from './calculated-output.component';
import { OutputFlagComponent } from './output-flag.component';
import { ResourcesSharedModule } from '../resources/resources-shared.module';
import { TaskFacetComponent } from './task-facet.component';
import { TaskDetailComponent } from './task-detail.component';
import { TaskFilterComponent } from './task-filter.component';
import { VocabulariesSharedModule } from '../vocabularies/vocabularies-shared.module';
import { CohortStatsFlagComponent } from './cohort-stats-flag.component';
import { TaskModalComponent } from './task-modal.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgbModule,
        ClimbCommonModule,
        FacetSharedModule,
        DataTypeModule,
        EnumerationsSharedModule,
        ResourcesSharedModule,
        TasksSharedModule,
        WorkspacesSharedModule,
        VocabulariesSharedModule,
        UsersSharedModule
    ],
    declarations: [
        CalculatedOutputComponent,
        OutputFlagComponent,
        CohortStatsFlagComponent,
        TaskFacetComponent,
        TaskDetailComponent,
        TaskFilterComponent,
        TaskModalComponent
    ],
    exports: [
        CalculatedOutputComponent,
        OutputFlagComponent,
        CohortStatsFlagComponent,
        TaskFilterComponent,
        TaskFacetComponent,
        TaskModalComponent
    ],
    providers: []
})
export class TasksModule { }

import { Pipe, PipeTransform } from '@angular/core';

interface JobMaterial {
    Job: {
        JobID: number;
    };
    DateOut?: Date;
    DateCreated: Date;
}

interface HealthRecord {
    Animal: {
        Material: {
            JobMaterial: JobMaterial[];
        }
    };
}

/**
 * A pipe representation of _getJobName of clinical-detail.component.ts
 */
@Pipe({
    name: 'healthRecordJobNameFormat',
    pure: false
})
export class HealthRecordJobNameFormatPipe implements PipeTransform {

    transform<T extends HealthRecord>(healthRecord: T, isGLP: boolean): string {
        let jobMaterials = healthRecord.Animal.Material.JobMaterial;
        let jobNames = '';
        if (jobMaterials) {
            if (isGLP) {
                jobMaterials = jobMaterials.filter((jm: JobMaterial) => !jm.DateOut);
            } else {
                jobMaterials = jobMaterials.sort((a: JobMaterial, b: JobMaterial) => b.DateCreated.getTime() - a.DateCreated.getTime());
            }
            jobNames = jobMaterials.map((jm: JobMaterial) => jm.Job.JobID).join(",");
        }
        return jobNames;
    }
}

import type { Job } from './job.interface';
import type { MessageMap } from './message-map.interface';
import type { Note } from './note.interface';
import type { StoredFileMap } from './stored-file-map.interface';
import type { StudyAdministratorStudy } from './study-administrator-study.interface';
import type { StudyCharacteristicInstance } from './study-characteristic-instance.interface';
import type { StudyLine } from './study-line.interface';
import type { StudyMaterial } from './study-material.interface';
import type { TaskStudy } from './task-study.interface';
import type { cv_StudyStatus } from './cv-study-status.interface';
import type { cv_StudyType } from './cv-study-type.interface';

export interface Study {
    ApprovalNumber: string;
    C_StudyStatus_key: number | null;
    C_StudyType_key: number;
    C_Study_key: number;
    C_Workgroup_key: number;
    Comments: string;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    Description: string;
    ExternalIdentifier: string;
    IsActive: boolean;
    IsLocked: boolean;
    Job: Job[];
    MessageMap: MessageMap[];
    ModifiedBy: string;
    Note: Note[];
    Owner: string;
    StoredFileMap: StoredFileMap[];
    StudyAdministratorStudy: StudyAdministratorStudy[];
    StudyCharacteristicInstance: StudyCharacteristicInstance[];
    StudyLine: StudyLine[];
    StudyMaterial: StudyMaterial[];
    StudyName: string;
    TaskStudy: TaskStudy[];
    Version: number;
    cv_StudyStatus: cv_StudyStatus;
    cv_StudyType: cv_StudyType;
}

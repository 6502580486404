import { FacetSharedModule } from './../common/facet/facet-shared.module';
import { SearchSharedModule } from './../search/search-shared.module';
import { 
    ViewInstitutionAuditReportComponentService 
} from './audit/view-institution-audit-report-component.service';
import { EnumerationsSharedModule } from './../enumerations/enumerations-shared.module';
import { CharacteristicsSharedModule } from './../characteristics/characteristics-shared.module';
import { InstitutionDetailComponent } from './institution-detail/institution-detail.component';
import { InstitutionFacetComponent } from './institution-facet.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { ClimbCommonModule } from '../common/climb-common.module';
import { ClimbServicesModule } from '../services/climb-services.module';

import { InstitutionsSharedModule } from './institutions-shared.module';

import { AuditSharedModule } from '../audit/audit-shared.module';
import { LinesSharedModule } from '../lines/lines-shared.module';
import { UsersSharedModule } from '../user/users-shared.module';
import { WorkspacesSharedModule } from '../workspaces/workspaces-shared.module';
import { VocabulariesSharedModule } from '../vocabularies/vocabularies-shared.module';

import { InstitutionAuditReportComponent } from './audit';
import { InstitutionFilterComponent } from './institution-filter.component';
import { InstitutionSiteComponent } from './institution-site.component';
import { ContactsTableComponent } from './contacts-table.component';

import { NgxMaskModule } from 'ngx-mask';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgbModule,
        CharacteristicsSharedModule,
        ClimbCommonModule,
        ClimbServicesModule,
        FacetSharedModule,
        InstitutionsSharedModule,
        AuditSharedModule,
        EnumerationsSharedModule,
        LinesSharedModule,
        SearchSharedModule,
        UsersSharedModule,
        VocabulariesSharedModule,
        WorkspacesSharedModule,
        NgxMaskModule.forChild()
    ],
    declarations: [
        InstitutionAuditReportComponent,
        InstitutionFilterComponent,
        InstitutionFacetComponent,
        InstitutionDetailComponent,
        InstitutionSiteComponent,
        ContactsTableComponent
    ],
    exports: [
        InstitutionAuditReportComponent,
        InstitutionFilterComponent,
        InstitutionFacetComponent,
        InstitutionSiteComponent,
        ContactsTableComponent
    ],
    providers: [
        ViewInstitutionAuditReportComponentService
    ]
})
export class InstitutionsModule { }

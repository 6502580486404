import {
    Component,
    Input,
} from '@angular/core';

import { ResourceService } from '../resources/resource.service';
import { VocabularyService } from '../vocabularies/vocabulary.service';
import { uniqueArrayFromPropertyPath } from '../common/util';
import { DataManagerService } from '../services/data-manager.service';

@Component({
    selector: 'task-complete-button',
    template: `
    <button [ngClass]="task.DateComplete ? 'btn-primary' : 'btn-secondary'" 
            type="button"
            class="btn task-complete-button"
            [disabled]="disabled"
            (click)="changeMark()">
        <i class="fa fa-check" title="Mark complete"></i>
    </button>
    `,
    styles: [`
        .task-complete-button {
            height:25px;
            width:25px;
            margin:1px 0;
            padding:0;
        }
    `]
})
export class TaskCompleteButtonComponent {
    @Input() task: any;
    @Input() disabled: boolean;

    constructor(
        private resourceService: ResourceService,
        private vocabularyService: VocabularyService,
        private dataManager: DataManagerService,
    ) {
        // nothing to do
    }

    changeMark() {
        if (this.task.DateComplete) {
            this.markIncomplete();
        } else {
            this.markComplete();
        }
    }


    // Incomplete
    public markIncomplete() {
        const task = this.task;

        // clear completed if already set
        task.C_CompletedBy_key = null;
        task.DateComplete = null;

        this.getDefaultTaskStatus().then((status) => {
            if (status) {
                task.C_TaskStatus_key = status.C_TaskStatus_key;
            }
        });
    }

    private getDefaultTaskStatus(): Promise<any> {
        const preferLocal = true;
        return this.vocabularyService.getCVDefault('cv_TaskStatuses', preferLocal);
    }


    // Complete
    public markComplete() {
        const task = this.task;

        // set completed if not
        task.DateComplete = new Date();

        // get current user resource for completed by
        this.resourceService.getCurrentUserResource().then((resource: any) => {
            if (resource) {
                task.C_CompletedBy_key = resource.C_Resource_key;
            }

            this.dataManager.ensureRelationships([task], ['TaskOutputSet']);
        }).then(() => {
            // Fill Collected fields if empty
            const taskOutputSets = uniqueArrayFromPropertyPath(task, 'TaskOutputSet');
            for (const tos of taskOutputSets) {
                tos.CollectionDateTime = tos.CollectionDateTime ? tos.CollectionDateTime : task.DateComplete;
                tos.C_Resource_key = tos.C_Resource_key ? tos.C_Resource_key : task.C_CompletedBy_key;
            }
        });

        this.getDefaultEndTaskStatus().then((defaultEndTaskStatus) => {
            if (defaultEndTaskStatus) {
                task.C_TaskStatus_key = defaultEndTaskStatus.C_TaskStatus_key;
            }
        });
    }

    private getDefaultEndTaskStatus(): Promise<any> {
        const preferLocal = true;
        return this.vocabularyService.getCV(
            'cv_TaskStatuses', null, preferLocal
        ).then((taskStatuses) => {
            const defaultEndStatuses = taskStatuses.filter((status) => {
                return status.IsDefaultEndState;
            });
            return defaultEndStatuses.length > 0 ? defaultEndStatuses[0] : null;
        });
    }
}

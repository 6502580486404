import {
    CellFormatterService,
    TableOptions,
    FormatterOptions
} from '../common/datatable';
import { TranslationService } from '../services/translation.service';
import { AuditModifiedFieldComponent } from './audit-modified-fields/audit-modified-field.component';
import { AuditClassFactory } from './audit-class-maker';

export class AuditSamplesTableOptions {

    options: TableOptions;

    constructor(
        private cellFormatterService: CellFormatterService,
        private translationService: TranslationService,
    ) {
        this.options = this.getDefaultOptions();
    }

    getDefaultOptions(): TableOptions {

        return {
            csvFileName: 'samples-audit.csv',
            enableDetailColumn: false,
            enableDraggable: false,
            enableSelectable: false,
            enableAuditColumns: false,
            columns: [
                {
                    displayName: 'Type',
                    field: 'UpdateType',
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Modified Fields',
                    field: 'ModifiedFields',
                    sortField: 'ModifiedFields',
                    rendererComponent: AuditModifiedFieldComponent,
                    exportFormatter: this.cellFormatterService.auditModifiedFieldsExportFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Date',
                    field: 'ModifiedDate',
                    formatter: this.cellFormatterService.dateTimeUTCFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Modified By',
                    field: 'ModifiedBy',
                    formatter: this.cellFormatterService.userNameFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'ID',
                    field: 'Identifier',
                    formatter: (row: any, value: any, formatterOptions: FormatterOptions) => {
                        return this.cellFormatterService.auditColumnFormatter(row, value, formatterOptions, 'Identifier', 'Identifier');
                    },
                    cellClass: AuditClassFactory.createCellClass('identifier'),
                },
                {
                    displayName: 'Name',
                    field: 'Name',
                    formatter: (row: any, value: any, formatterOptions: FormatterOptions) => {
                        return this.cellFormatterService.auditColumnFormatter(row, value, formatterOptions, 'Name', 'Name');
                    },
                    cellClass: AuditClassFactory.createCellClass('name'),
                },
                {
                    displayName: 'Source Names',
                    field: 'SourceNames',
                    visible: false,
                    formatter: (row: any, value: any, formatterOptions: FormatterOptions) => {
                        return this.cellFormatterService.auditColumnFormatter(row, value, formatterOptions, 'SourceNames', 'SourceNames');
                    },
                    cellClass: AuditClassFactory.createCellClass('sourcenames'),
                },
                {
                    displayName: 'Source IDs',
                    field: 'SourceIdentifiers',
                    visible: false,
                    formatter: (row: any, value: any, formatterOptions: FormatterOptions) => {
                        return this.cellFormatterService.auditColumnFormatter(row, value, formatterOptions, 'SourceIdentifiers', 'SourceIdentifiers');
                    },
                    cellClass: AuditClassFactory.createCellClass('sourceidentifiers'),
                },
                {
                    displayName: 'Sample Type',
                    field: 'SampleType',
                    formatter: (row: any, value: any, formatterOptions: FormatterOptions) => {
                        return this.cellFormatterService.auditColumnFormatter(row, value, formatterOptions, 'SampleType', 'SampleType');
                    },
                    cellClass: AuditClassFactory.createCellClass('sampletype'),
                },
                {
                    displayName: 'Harvest Date',
                    field: 'HarvestDate',
                    formatter: (row: any, value: any, formatterOptions: FormatterOptions) => {
                        return this.cellFormatterService.auditColumnFormatter(row, value, formatterOptions, 'HarvestDate', 'HarvestDate', this.cellFormatterService.dateFormatter);
                    },
                    cellClass: AuditClassFactory.createCellClass('harvestdate'),
                },
                {
                    displayName: 'Expiration Date',
                    field: 'ExpirationDate',
                    formatter: (row: any, value: any, formatterOptions: FormatterOptions) => {
                        return this.cellFormatterService.auditColumnFormatter(row, value, formatterOptions, 'ExpirationDate', 'ExpirationDate', this.cellFormatterService.dateFormatter);
                    },
                    cellClass: AuditClassFactory.createCellClass('expirationdate'),
                },
                {
                    displayName: this.translationService.translate('Line'),
                    field: 'Line',
                    visible: false,
                    formatter: (row: any, value: any, formatterOptions: FormatterOptions) => {
                        return this.cellFormatterService.auditColumnFormatter(row, value, formatterOptions, 'Line', this.translationService.translate('Line'));
                    },
                    cellClass: AuditClassFactory.createCellClass(this.translationService.translate('Line').toLowerCase()),
                },
                {
                    displayName: this.translationService.translate('Line') + ' Short Name',
                    field: 'LineShortName',
                    visible: false,
                    formatter: (row: any, value: any, formatterOptions: FormatterOptions) => {
                        return this.cellFormatterService.auditColumnFormatter(row, value, formatterOptions, 'LineShortName', this.translationService.translate('Line') + 'ShortName');
                    },
                    cellClass: AuditClassFactory.createCellClass((this.translationService.translate('Line') + 'ShortName').toLowerCase()),
                },
                {
                    displayName: 'Species',
                    field: 'Species',
                    visible: false,
                    formatter: (row: any, value: any, formatterOptions: FormatterOptions) => {
                        return this.cellFormatterService.auditColumnFormatter(row, value, formatterOptions, 'Species', 'Species');
                    },
                    cellClass: AuditClassFactory.createCellClass('species'),
                },
                {
                    displayName: 'Status',
                    field: 'Status',
                    formatter: (row: any, value: any, formatterOptions: FormatterOptions) => {
                        return this.cellFormatterService.auditColumnFormatter(row, value, formatterOptions, 'Status', 'Status');
                    },
                    cellClass: AuditClassFactory.createCellClass('status'),
                },
                {
                    displayName: 'Measurement',
                    field: 'Measurement',
                    formatter: (row: any, value: any, formatterOptions: FormatterOptions) => {
                        return this.cellFormatterService.auditColumnFormatter(row, value, formatterOptions, 'Measurement', 'Measurement');
                    },
                    cellClass: AuditClassFactory.createCellClass('measurement'),
                },
                {
                    displayName: 'Unit',
                    field: 'Unit',
                    formatter: (row: any, value: any, formatterOptions: FormatterOptions) => {
                        return this.cellFormatterService.auditColumnFormatter(row, value, formatterOptions, 'Unit', 'Unit');
                    },
                    cellClass: AuditClassFactory.createCellClass('unit'),
                },
                {
                    displayName: 'Time Point',
                    field: 'TimePoint',
                    formatter: (row: any, value: any, formatterOptions: FormatterOptions) => {
                        return this.cellFormatterService.auditColumnFormatter(row, value, formatterOptions, 'TimePoint', 'TimePoint');
                    },
                    cellClass: AuditClassFactory.createCellClass('timepoint'),
                },
                {
                    displayName: 'Preservation Method',
                    field: 'PreservationMethod',
                    formatter: (row: any, value: any, formatterOptions: FormatterOptions) => {
                        return this.cellFormatterService.auditColumnFormatter(row, value, formatterOptions, 'PreservationMethod', 'PreservationMethod');
                    },
                    cellClass: AuditClassFactory.createCellClass('preservationmethod'),
                },
                {
                    displayName: 'Sample Subtype',
                    field: 'SampleSubtype',
                    formatter: (row: any, value: any, formatterOptions: FormatterOptions) => {
                        return this.cellFormatterService.auditColumnFormatter(row, value, formatterOptions, 'SampleSubtype', 'SampleSubtype');
                    },
                    cellClass: AuditClassFactory.createCellClass('samplesubtype'),
                },
                {
                    displayName: 'Sample Processing',
                    field: 'SampleProcessingMethod',
                    formatter: (row: any, value: any, formatterOptions: FormatterOptions) => {
                        return this.cellFormatterService.auditColumnFormatter(row, value, formatterOptions, 'SampleProcessingMethod', 'SampleProcessingMethod');
                    },
                    cellClass: AuditClassFactory.createCellClass('sampleprocessingmethod'),
                },
                {
                    displayName: 'Send To',
                    field: 'SendTo',
                    formatter: (row: any, value: any, formatterOptions: FormatterOptions) => {
                        return this.cellFormatterService.auditColumnFormatter(row, value, formatterOptions, 'SendTo', 'SendTo');
                    },
                    cellClass: AuditClassFactory.createCellClass('sendto'),
                },
                {
                    displayName: 'Sample Analysis',
                    field: 'SampleAnalysisMethod',
                    formatter: (row: any, value: any, formatterOptions: FormatterOptions) => {
                        return this.cellFormatterService.auditColumnFormatter(row, value, formatterOptions, 'SampleAnalysisMethod', 'SampleAnalysisMethod');
                    },
                    cellClass: AuditClassFactory.createCellClass('sampleanalysismethod'),
                },
                {
                    displayName: 'Lot Number',
                    field: 'LotNumber',
                    formatter: (row: any, value: any, formatterOptions: FormatterOptions) => {
                        return this.cellFormatterService.auditColumnFormatter(row, value, formatterOptions, 'LotNumber', 'LotNumber');
                    },
                    cellClass: AuditClassFactory.createCellClass('lotnumber'),
                },
                {
                    displayName: 'Special Instructions',
                    field: 'SpecialInstructions',
                    formatter: (row: any, value: any, formatterOptions: FormatterOptions) => {
                        return this.cellFormatterService.auditColumnFormatter(row, value, formatterOptions, 'SpecialInstructions', 'SpecialInstructions');
                    },
                    cellClass: AuditClassFactory.createCellClass('specialinstructions'),
                },
                {
                    displayName: 'Location',
                    field: 'Location',
                    formatter: (row: any, value: any, formatterOptions: FormatterOptions) => {
                        return this.cellFormatterService.auditColumnFormatter(row, value, formatterOptions, 'Location', 'Location');
                    },
                    cellClass: AuditClassFactory.createCellClass('location'),
                },
                {
                    displayName: 'Created By',
                    field: 'CreatedBy',
                    formatter: this.cellFormatterService.userNameFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Created Date',
                    field: 'DateCreated',
                    formatter: this.cellFormatterService.dateTimeUTCFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                }
            ]
        };
    }
}

import { Pipe, PipeTransform } from '@angular/core';
import { 
    expireCache,
    ExpireCache,
    getObjectId,
    getSafeProp,
    sortObjectArrayByAccessor,
    sortObjectArrayByAccessors
} from '../util';


/*
* Order array by single property
*/
@Pipe({
    name: 'orderBy',
    pure: false
})
export class OrderByPipe implements PipeTransform {

    // create a cache for order bys
    // invalidate every 0.5 seconds
    // this throttles the calls to sort method
    public readonly EXPIRE_SECONDS = 0.5;
    expireCache: ExpireCache = expireCache.namespace('order-by-pipe');

    transform(value: any[], propertyPath: any, reverse = false, natural = false, nested = false, properties: any[] = []): any[] {       

        if (value && propertyPath) {
            
            // try cache first for performance optimization
            const objectId = getObjectId(value); 
            const cacheId = objectId + propertyPath + reverse;
            const cachedValue = expireCache.get(cacheId);
            if (cachedValue) {
                return cachedValue;
            }


            // copy array to sort
            value = value.slice();
            if (nested) {
                const sortDefs = properties.map((path: any) => {
                    return {
                        sortAccessor: (item: any) => {
                            return getSafeProp(item, path);
                        },
                        reverse
                    };
                });
                sortObjectArrayByAccessors(value, sortDefs, natural);
            } else {
                sortObjectArrayByAccessor(value, (item) => {
                    return getSafeProp(item, propertyPath);
                }, reverse, natural);
            }
            
            // cache for EXPIRE_SECONDS as a throttle
            expireCache.set(cacheId, value, this.EXPIRE_SECONDS);

            return value;
        } else if (value) {
            // value but no propertyPath
            return value;
        }

        return [];
    }
}

import {
    Injectable
} from '@angular/core';

import {
    NgbModal
} from '@ng-bootstrap/ng-bootstrap';

import { CensusAuditReportComponent } from './census-audit-report.component';

/*
* Service to view the census audit-report modal.
*/
@Injectable()
export class ViewCensusAuditReportComponentService {

    constructor(
        private modalService: NgbModal
    ) {
        //
    }

    openComponent(censusKey: number) {
        const modalRef = this.modalService.open(CensusAuditReportComponent, { size: 'lg' });
        modalRef.componentInstance.censusKey = censusKey;
    }
}

import { fromEvent, merge, Observable, pipe, Subscription } from 'rxjs';
import { delay, map, switchMap, tap } from 'rxjs/operators';
import {
    AfterViewInit,
    Directive,
    ElementRef,
    HostBinding,
    Inject,
    Input,
    OnDestroy,
    Optional,
    SkipSelf,
} from '@angular/core';
import { DragDrop, DragRef } from '@angular/cdk/drag-drop';
import { coerceBooleanProperty, coerceElement } from '@angular/cdk/coercion';
import { BaseDragDirective } from './base-drag.directive';
import { ROW_DROP_LIST } from './drag-drop.token';
import { CSS_CLASS_DROP_LIST_ROW_DRAGGING } from '../table.const';
import type { ContainerDropList, ItemDrag } from './drag-drop.interface';


@Directive({
    selector: 'climb-row[climbRowDrag], tr[climbRow][climbRowDrag]',
})
export class RowDragDirective extends BaseDragDirective implements AfterViewInit, OnDestroy {
    @Input() set climbRowDrag(isActive: boolean) {
        const prevState = this.isActive;
        this.isActive = coerceBooleanProperty(isActive);
        if (prevState !== this.isActive) {
            this.ngAfterViewInit();
        }
    }
    private isActive = true;

    @HostBinding('class')
    private get classes(): Record<string, boolean> {
        return {
            ['row-drag']: true,
            ['row-dragging']: this.dragRef?.isDragging(),
        };
    }

    private handles: HTMLElement[] = [];
    private subscriptions: Subscription = new Subscription();

    constructor(
        @Inject(ROW_DROP_LIST) @Optional() @SkipSelf() dropContainer: ContainerDropList,
        private readonly dragDrop: DragDrop,
        private readonly elementRef: ElementRef,
        private readonly window: Window,
    ) {
        super(dropContainer);
        if (!dropContainer) {
            throw Error('ContainerDropList provider not found');
        }
    }

    ngAfterViewInit(): void {
        if (!this.isActive) {
            this.dragRef?.dispose();
            return;
        }

        this.initDrag(this.dragDrop, this.elementRef);

        /** Elements that can be used to drag the draggable item. */
        const handles: NodeList = this.elementRef.nativeElement.querySelectorAll('climb-drag-handle');
        if (handles.length) {
            this.handles = Array.from(handles) as HTMLElement[];
            this.dragRef.withHandles(this.handles);
        }

        this.handleEvents(this.dragRef);
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
        this.subscriptions.unsubscribe();
    }

    /** Handles events from the underlying DragRef. */
    private handleEvents(dragRef: DragRef<ItemDrag>): void {
        const toggleDropListClass = pipe(
            map(() => coerceElement(this.dropContainer.dropListRef.element)),
            tap((element: HTMLElement) => element.classList.add(CSS_CLASS_DROP_LIST_ROW_DRAGGING)),
            switchMap((element: HTMLElement) => fromEvent(this.window, 'mouseup').pipe(
                delay(500),
                tap(() => element.classList.remove(CSS_CLASS_DROP_LIST_ROW_DRAGGING)),
            )),
        );

        const mouseDown$: Observable<Event>[] = this.handles.map((handle) => fromEvent(handle, 'mousedown'));
        const source$: Observable<Event> = this.handles.length
            ? merge(...mouseDown$).pipe(toggleDropListClass)
            : dragRef.beforeStarted.pipe(toggleDropListClass);

        this.subscriptions.add(source$.subscribe());
    }
}

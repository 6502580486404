import { PageState } from './page-state';

export class DetailPaginator {

    public pageState: PageState;
    listRefreshFunction: (pageNumber: number) => Promise<any>;

    constructor() {
        this.pageState = new PageState({
            activeItem: null,
            dataItems: [],
            listPage: 0,
            pageSize: 0,
            totalCount: 0
        });
    }

    setPageStage(pageState: PageState) {
        this.pageState.activeItem = pageState.activeItem;
        this.pageState.listPage = pageState.listPage;
        this.pageState.pageSize = pageState.pageSize;
        this.pageState.totalCount = pageState.totalCount;
        this.pageState.dataItems = pageState.dataItems;
    }

    getPrevious() {
        let index = this.pageState.activeItemIndex;
        index -= 1;
        return this.getItem(index);
    }

    getNext() {
        let index = this.pageState.activeItemIndex;
        index += 1;
        return this.getItem(index);
    }

    getItem(index: number): Promise<any> {
        const listPage = this.pageState.listPage;
        const dataItems = this.pageState.dataItems;
        const pageSize = this.pageState.pageSize;

        // test if the next item index requires a new list view page
        //   also guard against index overflows
        let newListPage = listPage;
        if (index < 0 && !this.pageState.isFirstListPage) {
            // go back 1 list view page
            newListPage -= 1;
            index = pageSize - 1;

        } else if (index > (dataItems.length - 1) &&
            !this.pageState.isLastListPage
        ) {
            // go forward 1 list view page
            newListPage += 1;
            index = 0;

        }
        
        if (newListPage !== listPage) {
            // need to refresh list view to get new list page
            return this._refreshListPage(newListPage).then(() => {
                return this.getNewIndexedItem(index);
            });
        }

        return Promise.resolve(this.getNewIndexedItem(index));
    }

    getNewIndexedItem(index: number): any {
        const dataItems = this.pageState.dataItems;
        // guard against overflows
        if (index < 0) {
            index = 0;

        } else if (index >= (dataItems.length - 1)) {
            index = dataItems.length - 1;
        }
        return dataItems[index];
    }

    _refreshListPage(listPage: number): Promise<void> {
        if (this.listRefreshFunction) {
            return this.listRefreshFunction(listPage);
        }
        return Promise.resolve();
    }
}

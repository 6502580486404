import { formatDecimal } from './format-decimal';
import { convertValueToLuxon } from './date-time-formatting/convert-value-to-luxon';
import { LuxonInput } from './date-time-formatting/types/luxon-input.type';
import { DateTime } from 'luxon';

/**
 * Calculates the age of an animal in months from the first to occur of
 * (a) exit date, and (b) today.
 * 
 * // formatted to 2 decimals
 *
 * @param birthDate
 * @param exitDate
 */
export function animalAgeMonths(birthDate: LuxonInput, exitDate?: LuxonInput): string {
    if (!birthDate) {
        return '';
    }

    const todayLuxon = DateTime.now();
    let testLuxon: DateTime;

    const exitDateLuxon = convertValueToLuxon(exitDate);
    if (exitDate) {
        // There's an exit date, so test from the earlier of exit date and today.
        testLuxon = DateTime.min(todayLuxon, exitDateLuxon);
    } else {
        // No exit date, so test from today
        testLuxon = todayLuxon;
    }

    let birthLuxon = convertValueToLuxon(birthDate);

    // ignore time
    birthLuxon = birthLuxon.startOf('day');
    testLuxon = testLuxon.startOf('day');

    const diffUnit = 'days';
    const daysDiff = testLuxon.diff(birthLuxon, diffUnit).as("days");

    // Calculate the average days per month using a weighted average and the gregorian calendar,
    // rather than an unweighted average, increasing the precision for the calculated age in months
    // Formula: ((2800 * 31 + 1600 * 30 + 303 * 28 + 97 * 29) / 4800)
    const averageMonth = 30.436875;

    return formatDecimal(daysDiff / averageMonth, 1);
}

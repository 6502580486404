import type { Construct } from './construct.interface';
import type { TaskInstance } from './task-instance.interface';

export interface TaskConstruct {
    C_Construct_key: number;
    C_TaskConstruct_key: number;
    C_TaskInstance_key: number;
    C_Workgroup_key: number;
    Construct: Construct;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    ModifiedBy: string;
    Sequence: number;
    TaskInstance: TaskInstance;
    Version: number;
}

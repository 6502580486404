

export class GroupedItem {
    key: string;
    values: any;
}

export function groupItems(
    items: any, 
    keyFunc: (item: any) => any,
    valueFunc: (item: any) => any
): GroupedItem[] {

    const groupedItems: GroupedItem[] = [];
    const groupMap: any = {};

    for (const item of items) {
        const key = keyFunc(item);
        const value = valueFunc(item);

        if (!groupMap.hasOwnProperty(key)) {
            groupMap[key] = [];
        }
        groupMap[key].push(value);
    }

    /* eslint-disable-next-line */
    for (let key in groupMap) {
        groupedItems.push({
            key,
            values: groupMap[key]
        });
    }

    return groupedItems;
}

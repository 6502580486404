import {
    Component,
    EventEmitter,
    Input,
    Output
} from '@angular/core';

/**
 * Expand/Hide chevron element
 */
@Component({
    selector: 'climb-expand',
    template: `<a (click)="toggle()" class="climb-expand-link"><i class="fa"
           [ngClass]="model ? 'fa-chevron-up' : 'fa-chevron-down'"
           [title]="(model ? 'Hide ' : 'Show ') + label"
           aria-hidden="true"></i></a>`
})
export class ClimbExpandComponent {
    @Input() model: boolean;
    @Input() label: string;
    @Output() modelChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    toggle() {
        this.model = !this.model;
        this.modelChange.emit(this.model);
    }
}

import { FacetSharedModule } from './../common/facet/facet-shared.module';
import { VocabulariesSharedModule } from './../vocabularies/vocabularies-shared.module';
import { UsersSharedModule } from './../user/users-shared.module';
import { ViewAddProtocolTaskComponentService } from './view-add-protocol-task-component.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DragulaModule } from 'ng2-dragula';

import { ProtocolsSharedModule } from './protocols-shared.module';
import {
    AddProtocolTaskComponent,
    ProtocolDetailComponent,
    ProtocolFacetComponent,
    ProtocolFilterComponent
} from '.';

import {
    ProtocolTaskTableComponent
} from './tables';

import { ClimbCommonModule } from '../common/climb-common.module';
import { ClimbServicesModule } from '../services/climb-services.module';
import { WorkspacesSharedModule } from '../workspaces/workspaces-shared.module';
import { TasksSharedModule } from '../tasks/tasks-shared.module';
import { DataTypeModule } from './../data-type/data-type.module';
import { AddProtocolSectionComponent } from './add-protocol-section/add-protocol-section.component';
import { CROTaskSelectorComponent } from './cro-task-selector/cro-task-selector.component';


@NgModule({
    imports: [
        DragulaModule,
        CommonModule,
        FormsModule,
        NgbModule,
        ClimbCommonModule,
        ClimbServicesModule,
        FacetSharedModule,
        ProtocolsSharedModule,
        TasksSharedModule,
        DataTypeModule,
        WorkspacesSharedModule,
        UsersSharedModule,
        VocabulariesSharedModule
    ],
    declarations: [
        AddProtocolSectionComponent,
        AddProtocolTaskComponent,
        ProtocolDetailComponent,
        ProtocolFacetComponent,
        ProtocolFilterComponent,
        ProtocolTaskTableComponent,
        CROTaskSelectorComponent
    ],
    exports: [
        AddProtocolSectionComponent,
        AddProtocolTaskComponent,
        ProtocolFacetComponent
    ],
    providers: [
        ViewAddProtocolTaskComponentService
    ]
})
export class ProtocolsModule { }

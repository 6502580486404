import { BehaviorSubject, Observable } from 'rxjs';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { DataService, TableColumnDef, TableOptions } from '@common/datatable';

@Component({
    selector: 'climb-audit-data-table',
    templateUrl: './audit-data-table.component.html',
    styleUrls: ['./audit-data-table.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuditDataTableComponent implements OnInit {
    @Input()
    dataService: DataService;

    @Input()
    options: TableOptions;

    dataTableColumns: BehaviorSubject<TableColumnDef[]>;
    dataTableColumns$: Observable<TableColumnDef[]>;

    ngOnInit(): void {
        this.dataTableColumns = new BehaviorSubject(this.options.columns);
        this.dataTableColumns$ = this.dataTableColumns.asObservable();
    }
}

import type { Resource } from './resource.interface';

export interface ScheduleNonTask {
    C_Resource_key: number | null;
    C_ScheduleNonTask_key: number;
    C_Workgroup_key: number;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    DateTimeComplete: Date;
    DateTimeStart: Date;
    Description: string;
    ModifiedBy: string;
    Resource: Resource;
    Version: number;
}

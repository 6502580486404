import { Observable } from "rxjs";
import { finalize } from "rxjs/operators";

export const fromResizeObserver = (elements: HTMLElement[]) => {
  let resizeObserver: ResizeObserver;
  return new Observable<ResizeObserverEntry[]>(subscribe => {
    resizeObserver = new ResizeObserver(entries => {
      subscribe.next(entries);
    });
    for (const element of elements) {
      resizeObserver.observe(element);
    }
  }).pipe(finalize(() => resizeObserver?.disconnect()));
};
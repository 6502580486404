import { END_STATE_CLASS } from './../common/datatable/climb-data-table.component';
import {
    CellFormatterService,
    TableOptions
} from '../common/datatable';
import { addAuditColumnsToOptions } from '@common/datatable/utils';

export class DeviceTableOptions {

    options: TableOptions;

    constructor(
        private cellFormatterService: CellFormatterService
    ) {
        this.options = addAuditColumnsToOptions(cellFormatterService)(this.getDefaultOptions());
    }

    getDefaultOptions(): TableOptions {

        return {
            csvFileName: 'devices.csv',
            enableAddButton: false,
            enableDetailColumn: true,
            enableDraggable: true,
            enableSelectable: true,
            enableAuditColumns: false,
            rowClass: (params: any) => {
                const row = params.data;

                const classes: string[] = [];
                if (row && row.IsActive !== true) {
                    classes.push(END_STATE_CLASS);
                }
                return classes;
            },
            columns: [
                {
                    displayName: 'ID', field: 'C_Device_key',
                },
                {
                    displayName: 'Model', field: 'DeviceModel.DeviceModelName',
                },
                {
                    displayName: 'Location', field: 'CurrentLocationPath',
                },
                {
                    displayName: 'Housing ID', field: 'MaterialPool.MaterialPoolID',
                    visible: false
                },
                {
                    displayName: 'Active', field: 'IsActive',
                    formatter: this.cellFormatterService.booleanFormatter,
                    exportFormatter: this.cellFormatterService.booleanExportFormatter,
                    maxWidth: 80,
                    visible: false
                },
            ]
        };
    }
}

import {
    Component,
    Input,
    OnInit
} from '@angular/core';

@Component({
    selector: 'animal-name-format-form',
    templateUrl: './animal-name-format-form.component.html',
    styles: [`
        thead th {
            padding-bottom: 0;
            font-size: 11px;
        }
    `],
})
export class AnimalNameFormatFormComponent implements OnInit {
    @Input() animal: any;

    ngOnInit() {
        if (!this.animal.counter) {
            this.animal.counter = 1;
        }
    }
}

import {
    CellFormatterService,
    TableOptions,
    FormatterOptions
} from '../common/datatable';
import { AuditModifiedFieldComponent } from './audit-modified-fields/audit-modified-field.component';
import { AuditClassFactory } from './audit-class-maker';

export class AuditWorkflowTaskDetailsTableOptions {

    options: TableOptions;

    constructor(
        private cellFormatterService: CellFormatterService
    ) {
        this.options = this.getDefaultOptions();
    }

    getDefaultOptions(): TableOptions {

        return {
            csvFileName: 'workflowTaskDetails-audit.csv',
            enableDetailColumn: false,
            enableDraggable: false,
            enableSelectable: false,
            enableAuditColumns: false,
            columns: [
                {
                    displayName: 'Type',
                    field: 'UpdateType',
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Modified Fields',
                    field: 'ModifiedFields',
                    sortField: 'ModifiedFields',
                    rendererComponent: AuditModifiedFieldComponent,
                    exportFormatter: this.cellFormatterService.auditModifiedFieldsExportFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Date',
                    field: 'ModifiedDate',
                    formatter: this.cellFormatterService.dateTimeUTCFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Modified By',
                    field: 'ModifiedBy',
                    formatter: this.cellFormatterService.userNameFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Task Name',
                    field: 'TaskName',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('TaskName', 'TaskName'),
                    cellClass: AuditClassFactory.createCellClass('taskname'),
                },
                {
                    displayName: 'Due Date',
                    field: 'DateDue',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('DateDue', 'DateDue', this.cellFormatterService.dateTimeUTCFormatter),
                    cellClass: AuditClassFactory.createCellClass('datedue'),
                },
                {
                    displayName: 'Protocol',
                    field: 'ProtocolName',
                    visible: false,
                    formatter: this.cellFormatterService.createAuditColumnFormatter('ProtocolName', 'ProtocolName'),
                    cellClass: AuditClassFactory.createCellClass('protocolname'),
                },
                {
                    displayName: 'Allowance',
                    field: 'Allowance',
                    visible: false,
                    formatter: this.cellFormatterService.createAuditColumnFormatter('Allowance', 'Allowance'),
                    cellClass: AuditClassFactory.createCellClass('allowance'),
                },
                {
                    displayName: 'Location',
                    field: 'Location',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('Location', 'Location'),
                    cellClass: AuditClassFactory.createCellClass('location'),
                },
                {
                    displayName: 'Cost',
                    field: 'Cost',
                    visible: false,
                    formatter: this.cellFormatterService.createAuditColumnFormatter('Cost', 'Cost'),
                    cellClass: AuditClassFactory.createCellClass('cost'),
                },
                {
                    displayName: 'Duration',
                    field: 'Duration',
                    visible: false,
                    formatter: this.cellFormatterService.createAuditColumnFormatter('Duration', 'Duration'),
                    cellClass: AuditClassFactory.createCellClass('duration'),
                },
                {
                    displayName: 'Collected',
                    field: 'Collected',
                    visible: false,
                    formatter: this.cellFormatterService.createAuditColumnFormatter('Collected', 'Collected'),
                    cellClass: AuditClassFactory.createCellClass('collected'),
                },
                {
                    displayName: 'Collected By',
                    field: 'CollectedBy',
                    visible: false,
                    formatter: this.cellFormatterService.createAuditColumnFormatter('CollectedBy', 'CollectedBy', this.cellFormatterService.userNameFormatter),
                    cellClass: AuditClassFactory.createCellClass('collectedby'),
                },
                {
                    displayName: 'Collected Date/Time',
                    field: 'DateCollected',
                    visible: false,
                    formatter: this.cellFormatterService.createAuditColumnFormatter('DateCollected', 'DateCollected', this.cellFormatterService.dateTimeUTCFormatter),
                    cellClass: AuditClassFactory.createCellClass('datecollected'),
                },
                {
                    displayName: 'Completed',
                    field: 'Completed',
                    visible: false,
                    formatter: this.cellFormatterService.createAuditColumnFormatter('Completed', 'Completed'),
                    cellClass: AuditClassFactory.createCellClass('completed'),
                },
                {
                    displayName: 'Completed By',
                    field: 'CompletedBy',
                    visible: false,
                    formatter: this.cellFormatterService.createAuditColumnFormatter('CompletedBy', 'CompletedBy', this.cellFormatterService.userNameFormatter),
                    cellClass: AuditClassFactory.createCellClass('completedby'),
                },
                {
                    displayName: 'Completed Date/Time',
                    field: 'DateCompleted',
                    visible: false,
                    formatter: this.cellFormatterService.createAuditColumnFormatter('DateCompleted', 'DateCompleted', this.cellFormatterService.dateTimeUTCFormatter),
                    cellClass: AuditClassFactory.createCellClass('datecompleted'),
                },
                {
                    displayName: 'Reviewed',
                    field: 'Reviewed',
                    visible: false,
                    formatter: this.cellFormatterService.createAuditColumnFormatter('Reviewed', 'Reviewed'),
                    cellClass: AuditClassFactory.createCellClass('reviewed'),
                },
                {
                    displayName: 'Reviewed By',
                    field: 'ReviewedBy',
                    visible: false,
                    formatter: this.cellFormatterService.createAuditColumnFormatter('ReviewedBy', 'ReviewedBy', this.cellFormatterService.userNameFormatter),
                    cellClass: AuditClassFactory.createCellClass('reviewedby'),
                },
                {
                    displayName: 'Reviewed Date/Time',
                    field: 'DateReviewed',
                    visible: false,
                    formatter: this.cellFormatterService.createAuditColumnFormatter('DateReviewed', 'DateReviewed', this.cellFormatterService.dateTimeUTCFormatter),
                    cellClass: AuditClassFactory.createCellClass('datereviewed'),
                },
                {
                    displayName: 'Task Status',
                    field: 'TaskStatus',
                    visible: false,
                    formatter: this.cellFormatterService.createAuditColumnFormatter('TaskStatus', 'TaskStatus'),
                    cellClass: AuditClassFactory.createCellClass('taskstatus'),
                },
                {
                    displayName: 'Created By',
                    field: 'CreatedBy',
                    formatter: this.cellFormatterService.userNameFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Created Date',
                    field: 'DateCreated',
                    formatter: this.cellFormatterService.dateTimeUTCFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                }
            ]
        };
    }
}

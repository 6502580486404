import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { ClimbCommonModule } from '../common/climb-common.module';
import { ClimbServicesModule } from '../services/climb-services.module';

import { CageCardChooserComponent } from './cage-card-chooser.component';
import { LabelChooserComponent } from './label-chooser.component';
import { ReportingService } from './reporting.service';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ClimbCommonModule,
        ClimbServicesModule
    ],
    declarations: [
        CageCardChooserComponent,
        LabelChooserComponent
    ],
    exports: [
        CageCardChooserComponent,
        LabelChooserComponent
    ],
    providers: [
        ReportingService
    ]
})
export class ReportingSharedModule { }

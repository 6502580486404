import { EntityChangeService } from './entity-change.service';
import { NgModule, ModuleWithProviders } from '@angular/core';

@NgModule({
    imports: [
    ],
    declarations: [
    ],
    exports: [
    ],
})
export class EntityChangesModule { 
    static forRoot(): ModuleWithProviders<EntityChangesModule> {
        return {
          ngModule: EntityChangesModule,
          providers: [
            EntityChangeService
          ]
        };
    }
}

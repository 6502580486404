<div class="form-group row">
    <label class="col-md-2 col-form-label">Sample Condition</label>
    <div class="col-md-10">
        <active-vocab-select [(model)]="sampleOrder.C_SampleCondition_key"
                             [vocabChoices]="sampleConditions"
                             [keyFormatter]="sampleConditionKeyFormatter"
                             [optionFormatter]="sampleConditionFormatter"
                             [nullable]="true"></active-vocab-select>
    </div>
</div>

<div class="form-group row">
    <label class="col-md-2 col-form-label">Sample Count</label>
    <div class="col-md-10">
        <input type="number"
               name="SampleCount"
               #name="ngModel"
               [(ngModel)]="sampleOrder.SampleCount"
               class="form-control input-medium" />
    </div>
</div>

<div class="form-group row">
    <label class="col-md-2 col-form-label">Sample Type</label>
    <div class="col-md-10">
        <active-vocab-select [(model)]="sampleOrder.C_SampleType_key"
                             [vocabChoices]="sampleTypes"
                             [keyFormatter]="sampleTypeKeyFormatter"
                             [optionFormatter]="sampleTypeFormatter"
                             [nullable]="false"
                             required="true"></active-vocab-select>
    </div>
</div>

<!-- Waiting on more information
<div class="form-group row">
    <label class="col-md-2 col-form-label">Species</label>
    <div class="col-md-10">
        <active-vocab-select [(model)]="order.C_Taxon_key"
                             [vocabChoices]="taxons"
                             [keyFormatter]="taxonKeyFormatter"
                             [optionFormatter]="taxonFormatter"
                             [nullable]="true"></active-vocab-select>
    </div>
</div>
-->

<div class="form-group row">
    <label class="col-md-2 col-form-label">Accession ID</label>
    <div class="col-md-10">
        <input type="text"
               name="AccessionID"
               #name="ngModel"
               [(ngModel)]="sampleOrder.AccessionID"
               class="form-control input-medium" />
    </div>
</div>

<lots-table [pkName]="'C_SampleOrder_key'"
            [pkValue]="sampleOrder.C_SampleOrder_key"
            [entity]="sampleOrder"></lots-table>

import { Pipe, PipeTransform } from '@angular/core';
import { DateFormatterService } from '../util/date-time-formatting/date-formatter.service';

/*
* Return short formatted date
*/
@Pipe({ name: 'formatShortDateTime' })
export class FormatShortDateTimePipe implements PipeTransform {

    constructor(private dateFormatterService: DateFormatterService) {

    }

    transform(value: any): string {
        return this.dateFormatterService.formatDateTime(value);
    }
}

import { Component, Input } from '@angular/core';

export const TEXT_FILTER_COMPONENT_OPERATORS: string[] = ['contains', 'has no value'];

@Component({
    selector: 'text-filter',
    template: `
        <form class="form-inline" *ngIf="node">
            <select
                    [(ngModel)]="node.operator"
                    (ngModelChange)="changeOperator()"
                    [ngModelOptions]="{standalone: true}"
                    class="form-control">
                <option *ngFor="let op of operators" [ngValue]="op">{{ op }}</option>
            </select>
            <input
                    type="text"
                    *ngIf="showTextValue()"
                    [(ngModel)]="node.text"
                    [ngModelOptions]="{standalone: true}"
                    class="form-control input-medium">
            <button
                    type="button"
                    class="form-control btn btn-secondary"
                    (click)="clear()">Clear</button>
        </form>
    `,
})
export class TextFilterComponent {
    @Input() node: any = null;

    readonly operators = TEXT_FILTER_COMPONENT_OPERATORS;

    changeOperator() {
        if (this.node.operator === 'has no value') {
            this.node.searchNulls = true;
        } else {
            this.node.searchNulls = false;
        }
    }
    
    showTextValue(): boolean {
        return !this.node.searchNulls;
    }

    clear() {
        this.node.text = null;
        this.node.searchNulls = false;
        if (this.node.operator === 'has no value') {
            this.node.operator = 'contains';
        }
    }
}

import {
    Component,
    EventEmitter,
    Input,
    Output
} from '@angular/core';
import { SearchService } from '../search/search.service';

const MAX_SEARCH_RESULTS = 50;

@Component({
    selector: 'mating-multiselect',
    template: `
<!-- Selected mating IDs -->
<div *ngIf="model && model.length > 0">
    <table class="table table-sm table-borderless table-nonfluid">
        <tbody>
            <tr *ngFor="let item of model; let i = index;">
                <td class="icon-cell">
                    <a (click)="removeMating(i)" title="Remove Mating ID">
                        <i class="fa fa-remove remove-item"></i>
                    </a>
                </td>
                <td>
                    {{item.MatingID}}
                </td>
            </tr>
        </tbody>
    </table>
</div>

<!-- Mating ID typeahead -->
<climb-indirect-typeahead
        [search]="searchMatings"
        [resultFormatter]="matingIDFormatter"
        placeholder="Mating ID&hellip;"
        (selectItem)="selectMating($event)"></climb-indirect-typeahead>
`,
    styles: [`
        .table {
            margin-bottom: 0.25em;
        }
    `]
})
export class MatingMultiselectComponent {
    @Input() model: any[];
    @Output() modelChange: EventEmitter<any> = new EventEmitter<any>();

    constructor(
        private searchService: SearchService
    ) { }

    removeMating(index: number) {
        if (this.model && index >= 0 && index < this.model.length) {
            this.model.splice(index, 1);
        }
        this.modelChange.emit(this.model);
    }

    searchMatings = (term: string): Promise<any> => {
        return this.searchService.getEntitiesBySearch({
            entity: 'Matings',
            page: 1,
            size: MAX_SEARCH_RESULTS,
            sortColumn: 'MatingID',
            sortDirection: 'asc',
            filter: {
                MatingID: term
            }
        }).then((results) => {
            // Convert Breeze entity to simple object
            // so it can be serialized by consumers
            return results.data.map((mating: any) => {
                return {
                    MatingID: mating.MatingID,
                    C_MaterialPool_key: mating.MatingKey
                };
            });
        });
    }

    matingIDFormatter = (value: any) => {
        return value.MatingID;
    }

    selectMating(mating: any) {
        if (this.model) {
            this.model.push(mating);
        } else {
            this.model = [mating];
        }
        this.modelChange.emit(this.model);
    }
}

import type { PlateMaterial } from './plate-material.interface';
import type { cv_PlateType } from './cv-plate-type.interface';

export interface PlatePosition {
    C_PlatePosition_key: number;
    C_PlateType_key: number | null;
    C_Workgroup_key: number;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    IsActive: number | null;
    ModifiedBy: string;
    Ordinal: number;
    PlateMaterial: PlateMaterial[];
    PositionName: string;
    Version: number;
    XPosition: number | null;
    YPosition: number | null;
    ZPosition: number | null;
    cv_PlateType: cv_PlateType;
}

<div class="calculated-output-container">
    <div #calculatedOutputField class="calculated-output-field"  [ngClass]="disabled ? 'calculated-output-field-disabled' : ''">
        <!-- NOTE: segment text cannot have any trailing whitespace -->
        <div *ngFor="let expressionSegment of expressionSegments; let last = last; let index = index"
             (blur)="segmentBlurred($event)"
             [contentEditable]="expressionSegment.isFreeformText && !disabled"
             class="expression-segment"
             [ngClass]="
                    {
                        'last-expression-segment': last,
                        'freeform-segment': expressionSegment.isFreeformText,
                        'variable-segment': !expressionSegment.isFreeformText
                    }
              ">{{expressionSegment.text}}<span *ngIf="!expressionSegment.isFreeformText">
                <a *ngIf="!disabled" (click)="removeVariableSegment(index)">
                    <i class="fa fa-times remove-item" role="button" title="Remove variable"></i>
                </a>
            </span>
        </div>
    </div>

    <div class="btn-toolbar">

        <!-- Variables dropdown -->
        <div class="btn-group"
             *ngIf="inputMappings.length || outputMappings.length">
            <button class="btn btn-secondary dropdown-toggle" type="button"
                    id="dropdownVariables"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    [disabled]="disabled">
                Insert Variable
            </button>
            <div class="dropdown-menu scrollable" aria-labelledby="dropdownVariables" dropdown-append-to-body>
                <h6 *ngIf="inputMappings.length" class="dropdown-header">Input Variables</h6>
                <a *ngFor="let inputMapping of inputMappings"
                   class="dropdown-item"
                   href="#" (click)="inputSelected(inputMapping); false">
                    {{inputMapping.Input.InputName}}
                </a>
                <h6 *ngIf="outputMappings.length" class="dropdown-header">Output Variables</h6>
                <a *ngFor="let outputMapping of outputMappings"
                   class="dropdown-item"
                   href="#" (click)="outputSelected(outputMapping); false">
                    {{outputMapping.Output.OutputName}}
                </a>
            </div>
        </div>

        <!-- Functions dropdown -->
        <div class="btn-group">
            <button class="btn btn-secondary dropdown-toggle" type="button"
                    id="dropdownFunctions"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    [disabled]="disabled">
                Insert Function
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownFunctions" dropdown-append-to-body>
                <a *ngFor="let func of availableFunctions"
                   class="dropdown-item"
                   href="#" (click)="functionSelected(func); false">
                    {{func.name}} ( value1, value2, &hellip; )
                </a>
            </div>
        </div>

        <!-- Help show/hide -->
        <div class="btn-group">
            <button type="button"
                    class="btn btn-link"
                    (click)="helpShowing = !helpShowing"
                    [title]="helpShowing ? 'Hide help' : 'Show help'">
                Help<i class="fa fa-fw"
                       [ngClass]="helpShowing ? 'fa-chevron-up' : 'fa-chevron-down'"
                       [title]="helpShowing ? 'Hide help' : 'Show help'"></i>
            </button>
        </div>

    </div>

    <div id="calculated-output-help" [ngbCollapse]="!helpShowing">
        <div class="well mt-1">
            <p>
                Calculated outputs allow you to create arithmetic expressions
                that use numeric inputs, outputs, and functions to calculate a value.
                You may use:
            </p>

            <ul style="list-style: circle;">
                <li>Binary operators: +, -, * and /</li>
                <li>Parentheses to group sub-expressions</li>
                <li>Numeric constants</li>
                <li>Numeric input and output variables</li>
                <li>Functions: min() and max()</li>
            </ul>

            <p>
                Imagine we have an input named "score factor" and two outputs named
                "forelimb length (mm)" and "hindlimb length (mm)".
                We use them to construct an expression to derive a scoring value:
            </p>

            <div class="calculated-output-field" style="width: 500px;">
                <div class="expression-segment freeform-segment"> ( </div>
                <div class="expression-segment variable-segment">
                    forelimb length (mm)
                    <span>
                        <i aria-hidden="true" class="fa fa-times remove-item" title="Remove variable"></i>
                    </span>
                </div>
                <div class="expression-segment freeform-segment"> + </div>
                <div class="expression-segment variable-segment">
                    hindlimb length (mm)
                    <span>
                        <i aria-hidden="true" class="fa fa-times remove-item" title="Remove variable"></i>
                    </span>
                </div>
                <div class="expression-segment freeform-segment"> ) * 2 * </div>
                <div class="expression-segment variable-segment">
                    score factor
                    <span>
                        <i aria-hidden="true" class="fa fa-times remove-item" title="Remove variable"></i>
                    </span>
                </div>
            </div>

            <p>
                This expression will be used to automatically calculate an output value when you modify
                input and output values in the Job or Workflow facet.
            </p>
        </div>
    </div>

    <div class="alert alert-warning" role="alert" *ngIf="expression.validationError">
        <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
        {{expression.validationError}}
    </div>
</div>

export abstract class FileExporter {

    /*
    * format and escape values
    */
    formatValue(value: any): string {
        // we want to catch anything null-ish, hence just == not ===
        if (value == null) {
            return '';
        }
        if (typeof (value) === 'number') {
            return value + '';
        }
        if (typeof (value) === 'boolean') {
            return (value ? 'TRUE' : 'FALSE');
        }
        if (typeof (value) === 'string') {
            // escape commas (,) and double-quote (") https://en.wikipedia.org/wiki/Comma-separated_values
            return `"${value.replace(/"/g, '""')}"`;
        }

        return value;
    }

    abstract download(data: any[][], filename: string): void;
}

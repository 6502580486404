import {
    Component,
    Input,
    OnInit
} from '@angular/core';

import {
    NgbActiveModal
} from '@ng-bootstrap/ng-bootstrap';

import { AuditService } from '../../audit/audit.service';
import { ExportInstitutionAuditService } from './export-institution-audit.service';
import { InstitutionService } from '../institution.service';

import {
    getSafeProp
} from '../../common/util/';
import { FeatureFlagService } from '../../services/feature-flags.service';

@Component({
    selector: 'institution-audit-report',
    templateUrl: './institution-audit-report.component.html'
})
export class InstitutionAuditReportComponent implements OnInit {
    @Input() institutionKey: any;

    institution: any;
    auditData: any[] = [];

    loading = false;
    isCRL = false;
    readonly noDataMessage: string = 'No changes.';

    constructor(
        private activeModal: NgbActiveModal,
        private auditService: AuditService,
        private exportInstitutionAuditService: ExportInstitutionAuditService,
        private institutionService: InstitutionService,
        private featureFlagService: FeatureFlagService
    ) {
    }

    ngOnInit() {
        this.getData();
        this.initIsCRL();
    }

    private getData(): Promise<void> {

        const p1 = this.institutionService.getInstitution(this.institutionKey)
            .then((data: any) => {
                this.institution = data;
            });

        const p2 = this.auditService.getInstitutionAuditData(this.institutionKey)
            .then((response: any) => {
                this.auditData = getSafeProp(response, 'data.results') || [];
            });

        this.loading = true;
        return Promise.all([p1, p2]).then(() => {
            this.loading = false;
        }).catch((error) => {
            this.loading = false;
            throw error;
        });
    }

    onCancel(): void {
        this.activeModal.dismiss();
    }

    exportAudit(): void {
        this.exportInstitutionAuditService.exportToCsv(
            this.auditData,
            this.isCRL
        );
    }

    initIsCRL() {
        const flag = this.featureFlagService.getFlag("IsCRL");
        this.isCRL = (flag && flag.IsActive && flag.Value.toLowerCase() === "true");
    }
}

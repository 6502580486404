<div class="modal-header justify-content-center">
    <h4 class="modal-title">Import Log Filter</h4>
</div>

<div class="modal-body">
    <form role="form" (keyup.enter)="okClicked()" class="form-label-right">
        <div class="form-group row">
            <label class="col-md-3 col-form-label">File Name</label>
            <div class="col-md-9">
                <input type="text" name="fileName"
                       [(ngModel)]="filter.FileName"
                       class="form-control input-medium"
                       data-auto-focus="import-filter" />
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-3 col-form-label">Import File Type</label>
            <div class="col-md-9">
                <import-file-type-select [(model)]="filter.C_ImportFileType_key"
                                         [readOnly]="false"
                                         [nullable]="true"></import-file-type-select>
            </div>
        </div>


        <div class="form-group row">
            <label class="col-md-3 col-form-label">Import File Format</label>
            <div class="col-md-9">
                <import-file-format-select [(model)]="filter.C_ImportFileFormat_key"
                                           [readOnly]="false"
                                           [nullable]="true"></import-file-format-select>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-3 col-form-label">Status</label>
            <div class="col-md-9">
                <select name="status"
                        [(ngModel)]="filter.Result"
                        class="form-control input-medium">
                    <option></option>
                    <option [ngValue]="true">Successful</option>
                    <option [ngValue]="false">Unsuccessful</option>
                </select>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-3 col-form-label">Date Range</label>
            <div class="col-md-9 d-flex">
                <climb-ngb-date #dateControl="ngModel"
                                name="Start Date Start"
                                [(ngModel)]="filter.StartDateTimeStart"
                                placeholder="From&hellip;"
                                class="mr-2"
                                [required]="false">
                </climb-ngb-date>
                <climb-ngb-date #dateControl="ngModel"
                                name="Start Date End"
                                [(ngModel)]="filter.StartDateTimeEnd"
                                placeholder="To&hellip;"
                                [required]="false">
                </climb-ngb-date>
            </div>
        </div>

        <div class="modal-footer row">
            <span class="btn-set">
                <button type="button" 
                        class="btn btn-fw-wide btn-lg btn-primary" 
                        (click)="okClicked()">
                    Filter
                </button>
                <button data-automation-id="clear-filter-button" 
                        type="button" 
                        class="btn btn-fw-wide btn-lg btn-secondary" 
                        (click)="clearClicked()">
                    Clear Filter
                </button>
            </span>
            <span class="btn-set">
                <button type="button" class="btn btn-lg btn-secondary" (click)="closeClicked()">Close</button>
            </span>
        </div>
    </form>
</div>

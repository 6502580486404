<div *ngIf="errorMessage" class="text-danger help-container">
    <i class="fa fa-exclamation-triangle" aria-hidden="true"> </i>
    {{ errorMessage }}
</div>
<div *ngIf="warningMessage" class="text-warning help-container">
    <i class="fa fa-exclamation-triangle" aria-hidden="true"> </i>
    {{ warningMessage }}
</div>
<div *ngIf="dataIncomplete" class="text-warning help-container">
    <i class="fa fa-exclamation-triangle" aria-hidden="true"> </i>
    One or more of your groups had no related data.
</div>
<loading-overlay *ngIf="loading" [message]="loadingMessage()"></loading-overlay>
<div *ngIf="!rendered && !loading" class="d-flex" style="justify-content: center">
    <h2>Data not yet selected. Please select data to view.</h2>
</div>
<dx-chart *ngIf="rendered" [dataSource]="dataSource" palette="Green Mist">

    <dxo-common-series-settings argumentField="interval"
                                [type]="'line'">
    </dxo-common-series-settings>
    <dxo-zoom-and-pan argumentAxis="both"></dxo-zoom-and-pan>
    <dxi-series *ngFor="let seriesLabel of seriesLabelList" [valueField]="seriesLabel + 'value'" [name]="seriesLabel"></dxi-series>
    <dxi-value-axis *ngIf="taskOutput" [title]="taskOutput.OutputName"></dxi-value-axis>
    <dxo-argument-axis [title]="interval + ' Since Birth'"></dxo-argument-axis>
    <dxo-crosshair [enabled]="true" color="#949494" [width]="3" dashStyle="dot">
        <dxo-label [visible]="true" backgroundColor="#949494">
            <dxo-font color="#fff" [size]="12"> </dxo-font>
        </dxo-label>
    </dxo-crosshair>
    <dxo-common-axis-settings>
        <dxo-grid [visible]="true"></dxo-grid>
    </dxo-common-axis-settings>
    <dxo-legend verticalAlignment="top" horizontalAlignment="right">
    </dxo-legend>
    <dxo-export [enabled]="true"></dxo-export>
    <dxo-tooltip [enabled]="true" [zIndex]="5000" [customizeTooltip]="customizeTooltip"></dxo-tooltip>
</dx-chart>


import { cv_SampleType } from "./cv-sample-type.interface";
import { SampleCharacteristic } from "./sample-characteristic.interface";

export interface SampleCharacteristicSampleType {
    C_SampleCharacteristicSampleType_key: number;
    C_SampleType_key: number;
    C_SampleCharacteristic_key: number;
    cv_SampleType: cv_SampleType;
    SampleCharacteristic: SampleCharacteristic;
}

import type { Marker } from './marker.interface';

export interface Primer {
    C3Prime: number;
    C5Prime: number;
    C_Marker_key: number;
    C_Primer_key: number;
    C_Workgroup_key: number;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    Marker: Marker;
    ModifiedBy: string;
    Version: number;
}

<div class="modal-header justify-content-center">
    <h4 class="modal-title">Print Cage Cards</h4>
</div>
<div class="modal-body">
    <form>
        <div class="form-group row">
            <label class="col-md-4 col-form-label">Cage Card Type</label>
            <div class="col-md-6">
                <select name="reportType" [(ngModel)]="reportType"
                        class="form-control input-medium"
                >
                    <option *ngFor="let type of reportTypes" [value]="type.key">
                        {{type.display}}
                    </option>
                </select>
            </div>
        </div>

        <div class="modal-footer pb-0">
            <button type="button" class="btn btn-fw btn-lg btn-primary" (click)="printClicked()">
                Print
            </button>
            <button type="button" class="btn btn-fw btn-lg btn-secondary" (click)="cancelClicked()">
                Cancel
            </button>
        </div>
    </form>
</div>

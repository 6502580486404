import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';

import { InstitutionVocabService } from './institution-vocab.service';

import { BaseFilter } from '../common/facet';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
    focusElementByQuery
} from '../common/util/';


@Component({
    selector: 'institution-filter',
    templateUrl: './institution-filter.component.html'
})
export class InstitutionFilterComponent extends BaseFilter implements OnInit {
    @Input() filter: any = {};
    @Input() isCRL = false;
    @Output() onFilter = new EventEmitter<any>();
    @Output() close = new EventEmitter<any>();

    // CVs
    institutionTypes: any[] = [];
    countries: any[] = [];
    states: any[] = [];
    
    constructor(
        private institutionVocabService: InstitutionVocabService,
        private activeModal: NgbActiveModal
    ) {
        super(activeModal);
    }

    ngOnInit() {
        this.cloneFilter();

        this.getCVs();

        focusElementByQuery('[data-auto-focus="Name"]');
    }

    private cloneFilter() {
        this.filter = this.copyFilter(this.filter);
    }

    private getCVs() {

        this.institutionVocabService.institutionTypes$
            .subscribe((data) => {
                this.institutionTypes = data;
            });

        this.institutionVocabService.countries$
            .subscribe((data) => {
                this.countries = data.sort((a, b) => (a.Country > b.Country) ? 1 : -1);
            });

        this.institutionVocabService.states$
            .subscribe((data) => {
                this.states = data.sort((a, b) => (a.StateName > b.StateName) ? 1 : -1);
            });
    }

    filterClicked(event: Event) {
        event.preventDefault();
        this.onFilter.emit(this.filter);
        this.close.emit();
        this.activeModal.close();
    }

    closeClicked() {
        this.close.emit();
        this.activeModal.dismiss();
    }

    clearClicked() {
        this.filter = {};
        this.onFilter.emit(this.filter);
        this.close.emit();
        this.activeModal.close();
    }
}

<div class="wizard-body">

    <h4 class="wizard-heading">
        Let's define the <span class="data-type">characteristics (traits)</span> you will track for each species.
</h4>

    <div class="intro-text">
        <p>
            Once your workgroup is set up, you will be able to add and modify characteristics
            using the <em>Characteristics</em> facet.
        </p>
    </div>

    <form>
        <div class="taxon-characteristics"
             *ngFor="let taxon of selectedTaxa; let taxonIndex = index">
            <h4>
                {{taxon.cvValue}} Characteristics
            </h4>
            <div class="form-check"
                 *ngFor="let taxonCharacteristic of taxonCharacteristics | characteristicTaxonFilter:taxon.cvValue; let characteristicIndex = index">
                <label class="form-check-label">
                    <input type="checkbox"
                           class="form-check-input"
                           name="selectedCharacteristic-{{taxonIndex}}-{{characteristicIndex}}"
                           [(ngModel)]="taxonCharacteristic.isSelected"
                           (ngModelChange)="onModelChange($event)" />
                    {{taxonCharacteristic.name}}
                </label>
            </div>
        </div>
    </form>

</div>

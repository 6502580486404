<div>
    <div style="margin:10px;">
        <table class="table">
            <thead>
                <tr>
                    <th>Message&nbsp;Center</th>
                    <th>Email</th>
                    <th>SMS</th>
                    <th>IoT&nbsp;Alert&nbsp;Name</th>
                </tr>
            </thead>

            <tbody>
                <tr *ngFor="let alertDef of iotAlertDefinitions">
                    <td class="icon-cell">
                        <input type="checkbox" [(ngModel)]="alertDef.subscribedMessageCenter" />
                    </td>
                    <td class="icon-cell">
                        <input type="checkbox" [(ngModel)]="alertDef.subscribedEmail" />
                    </td>
                    <td class="icon-cell">
                        <input type="checkbox" [(ngModel)]="alertDef.subscribedSMS" />
                    </td>
                    <td>
                        <div class="text-success">{{ alertDef.name }}</div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

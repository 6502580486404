import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    OnDestroy,
    ViewChildren,
} from '@angular/core';

import { SampleVocabService } from './sample-vocab.service';

import { BaseFilter } from '../common/facet';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
    focusElementByQuery
} from '../common/util/';
import { SampleService } from './sample.service';
import { Subscription } from 'rxjs';
import { NgModel } from '@angular/forms';
import { LoggingService } from '@services/logging.service';
import { dateControlValidator } from '@common/util/date-control.validator';


@Component({
    selector: 'sample-filter',
    templateUrl: './sample-filter.component.html'
})
export class SampleFilterComponent extends BaseFilter implements OnInit, OnDestroy {
    @ViewChildren('dateControl') dateControls: NgModel[];
    @Input() filter: any = {};
    @Output() onFilter = new EventEmitter<any>();
    @Output() close = new EventEmitter<any>();

    // CVs
    preservationMethods: any[] = [];
    sampleStatuses: any[] = [];
    sampleTypes: any[] = [];
    taxons: any[] = [];
    timeUnits: any[] = [];
    sampleSubtypes: any[] = [];
    sampleProcessingMethods: any[] = [];
    sampleAnalysisMethods: any[] = [];

    sampleOrders: any[] = [];
  
    readonly MULTI_PASTE_INPUT_LIMIT = 150;

    _subs: Subscription[] = [];

    constructor(
        private sampleVocabService: SampleVocabService,
        private activeModal: NgbActiveModal,
        private sampleService: SampleService,
        private loggingService: LoggingService,
    ) {
        super(activeModal);
    }

    ngOnInit() {
        this.cloneFilter();

        this.getCVs();
        this.getSampleOrders();
        
        focusElementByQuery('[data-auto-focus="SampleName"]');
    }

    private cloneFilter() {
        const dateHarvestStart = this.filter.DateHarvestStart;
        const dateHarvestEnd = this.filter.DateHarvestEnd;

        this.filter = this.copyFilter(this.filter);

        this.filter.DateHarvestStart = dateHarvestStart;
        this.filter.DateHarvestEnd = dateHarvestEnd;
    }

    ngOnDestroy() {
        for (const s of this._subs) {
            s.unsubscribe();
        }
    }

    private getCVs() {
        this.sampleVocabService.preservationMethods$
            .subscribe((data) => {
                this.preservationMethods = data;
            });

        this.sampleVocabService.sampleStatuses$
            .subscribe((data) => {
                this.sampleStatuses = data;
            });

        this.sampleVocabService.sampleTypes$
            .subscribe((data) => {
                this.sampleTypes = data;
            });

        this.sampleVocabService.taxons$
            .subscribe((data) => {
                this.taxons = data;
            });

        this.sampleVocabService.timeUnits$
            .subscribe((data) => {
                this.timeUnits = data;
            });

        this.sampleVocabService.sampleSubtypes$
            .subscribe((data) => {
                this.sampleSubtypes = data;
            });

        this.sampleVocabService.sampleProcessingMethods$
            .subscribe((data) => {
                this.sampleProcessingMethods = data;
            });

        this.sampleVocabService.sampleAnalysisMethods$
            .subscribe((data) => {
                this.sampleAnalysisMethods = data;
            });
    }

    getSampleOrders() {
        this.sampleService.getSampleOrders().then((data) => {
            this.sampleOrders = data;
        });
    }
   
    filterClicked(event: Event) {
        const errMessage = dateControlValidator(this.dateControls);
        if (errMessage) {
            this.loggingService.logError(errMessage, null, '', true);
            return;
        }

        event.preventDefault();
        this.onFilter.emit(this.filter);
        this.close.emit();
        this.activeModal.close();
    }

    closeClicked() {
        this.close.emit();
        this.activeModal.dismiss();
    }

    clearClicked() {
        this.filter = {};
        this.onFilter.emit(this.filter);
        this.close.emit();
        this.activeModal.close();
    }
}

import { Pipe, PipeTransform } from '@angular/core';

import { currentMaterialHousingID, MaterialPoolMaterial } from '../util';

/**
 * Returns the current material HousingID.
 */
@Pipe({
    name: 'currentMaterialHousingID',
    pure: false
})
export class CurrentMaterialHousingIDPipe implements PipeTransform {

    transform<T extends MaterialPoolMaterial>(value: T[]): string[] {
        if (!value) {
            return [];
        }

        const housingID = currentMaterialHousingID(value);

        // Return array so can be used in ngFors
        if (housingID) {
            return [housingID];
        } else {
            return [];
        }
    }
}

<div class="form-group">
    <label for="taskSelect-{{domIdAddition}}">Task</label>
    <task-select data-automation-id="task-name-dropdown"
                 id="taskSelect-{{domIdAddition}}"
                 [(model)]="workflowTaskKey"
                 (modelChange)="onTaskChange()"
                 [showActiveOnly]="false"
                 [disabled]="disabled"></task-select>
</div>

<div class="form-group">
    <label for="outputSelect-{{domIdAddition}}">Output</label>
    <select id="outputSelect-{{domIdAddition}}"
            name="outputSelect-{{domIdAddition}}"
            class="form-control"
            [disabled]="!(outputs | notEmpty) || disabled"
            [(ngModel)]="selectedOutput"
            (ngModelChange)="onSelectedOutputChange($event)">
        <option *ngFor="let output of outputs"
                [ngValue]="output">
            {{output.OutputName}}
        </option>
    </select>

    <small class="form-text text-warning"
       *ngIf="workflowTaskKey && !(outputs | notEmpty) && !currentOutput">
        This task has no numeric outputs.
    </small>

    <small class="form-text text-warning"
       *ngIf="workflowTaskKey && !(outputs | notEmpty) && currentOutput">
        This task has no outputs.
    </small>

    <small class="form-text text-muted"
       *ngIf="!workflowTaskKey">
        Please select a task.
    </small>
</div>

<ng-template #valuesList let-subParam="subParam">
    <!--
        Value list for indirect selectors
        E.g. dropdownindirect & autocompletemultiselect
    -->
    <div *ngIf="subParam.results | notEmpty">
        <!--<div *ngIf="subParam.details && subParam.details.valuesText">
            {{subParam.details?.valuesText}}<span *ngIf="subParam.details?.valuesText > 0">:</span>
        </div>-->
        <ul class="list-unstyled">
            <li *ngFor="let item of subParam.results">
                <a (click)="removeItem(item)">
                    <i class="fa fa-fw fa-remove remove-item"
                       title="Remove item"></i>
                </a>{{item.text}}
            </li>
        </ul>
    </div>
</ng-template>

<ng-container [ngSwitch]="subParam?.inputType"
              *ngIf="subParam">

    <ng-container *ngSwitchCase="ParamInputs.date">
        <div class="form-group d-flex align-items-center">
            <label for="{{_fieldName}}" class="mr-2 mb-0">{{subParam.text}}</label>
            <climb-ngb-date #dateControl="ngModel"
                            name="{{subParam.text}}"
                            [(ngModel)]="subParam.results"
                            [id]="_fieldName"></climb-ngb-date>
        </div>
    </ng-container>

    <ng-container *ngSwitchCase="ParamInputs.daterange">
        <div class="form-group d-flex align-items-center">
            <label for="{{_fieldName}}Start" class="mr-2 mb-0">{{subParam.text}} Start</label>
            <climb-ngb-date #dateControl="ngModel"
                            name="{{subParam.text}} Start"
                            [(ngModel)]="subParam.results[subParam.details.keyStartDate]"
                            [id]="_fieldName + 'Start'"
                            [placeholder]="'From&hellip;'"
                            *ngIf="subParam.details"></climb-ngb-date>
        </div>
        <div class="form-group d-flex align-items-center">
            <label for="{{_fieldName}}End" class="mr-2 mb-0">{{subParam.text}} End</label>
            <climb-ngb-date #dateControl="ngModel"
                            name="{{subParam.text}} End"
                            [(ngModel)]="subParam.results[subParam.details.keyEndDate]"
                            [id]="_fieldName + 'End'"
                            [placeholder]="'To&hellip;'"
                            *ngIf="subParam.details"></climb-ngb-date>
        </div>
    </ng-container>

    <ng-container *ngSwitchCase="ParamInputs.dropdown">
        <div class="form-group">
            <label for="{{_fieldName}}">{{subParam.text}}</label>
            <select [(ngModel)]="subParam.results"
                    [name]="_fieldName"
                    [id]="_fieldName"
                    class="form-control"
                    *ngIf="subParam.details?.values">
                <option *ngFor="let item of subParam.details.values"
                        [value]="item.value">
                    {{item.text}}
                </option>
            </select>
        </div>
    </ng-container>

    <ng-container *ngSwitchCase="ParamInputs.autocompletesingleselect">
        <div class="form-group">
            <label>{{subParam.text}}</label>
            <climb-typeahead [search]="getAutocompleteData"
                             [placeholder]="subParam.text + '&hellip;'"
                             [resultFormatter]="autocompleteFormatter"
                             (selectItem)="autocompleteSingleSelect($event)"></climb-typeahead>
        </div>
    </ng-container>

    <ng-container *ngSwitchCase="ParamInputs.autocompletemultiselect">
        <div class="form-group">
            <label>{{subParam.text}}</label>
            <ng-template [ngTemplateOutlet]="valuesList"
                         [ngTemplateOutletContext]="{
                    subParam : subParam
                    }"></ng-template>
            <climb-indirect-typeahead [search]="getAutocompleteData"
                                      [placeholder]="subParam.placeholderText"
                                      [resultFormatter]="autocompleteFormatter"
                                      (selectItem)="autocompleteSelect($event)"></climb-indirect-typeahead>
        </div>
    </ng-container>

    <ng-container *ngSwitchCase="ParamInputs.dropdownindirect">
        <div class="form-group">
            <label for="{{_fieldName}}">{{subParam.text}}</label>
            <ng-template [ngTemplateOutlet]="valuesList"
                         [ngTemplateOutletContext]="{
                    subParam : subParam
                    }"></ng-template>
            <select [(ngModel)]="dropdownValue"
                    (ngModelChange)="dropdownSelect($event)"
                    [name]="_fieldName"
                    [id]="_fieldName"
                    class="form-control"
                    *ngIf="subParam.details?.values">
                <option *ngFor="let item of subParam.details.values"
                        [value]="item.value">
                    {{item.text}}
                </option>
            </select>
        </div>
    </ng-container>

</ng-container>

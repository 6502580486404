import { Pipe, PipeTransform } from '@angular/core';

import { weeksSinceAsString } from '../util';
import { LuxonInput } from '@common/util/date-time-formatting/types/luxon-input.type';

/**
 * Returns the number of days between two dates
 */
@Pipe({ name: 'weeksSince' })
export class WeeksSincePipe implements PipeTransform {

    transform(value: LuxonInput, secondDate: any): string {
        if (!value || !secondDate) {
            return '';
        }

        return weeksSinceAsString(value, secondDate);
    }
}

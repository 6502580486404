import { Directive } from '@angular/core';
import { Base, BaseConstructor, Constructor } from './mixin-boilerplate';
import { ControlValueAccessor } from '@angular/forms';

export interface CanControlValue extends ControlValueAccessor {
    value: any;
    onChange: (_: any) => void;
    onTouched: () => void;
}

export type CanControlValueAccessorMixin = Constructor<CanControlValue>;

export const ControlValueAccessorClass = mixinControlValueAccessor(Base);

/**
 * Mixin to augment a component for unsubscribing
 * from all observables when onDestroy firing.
 */
export function mixinControlValueAccessor<T extends BaseConstructor = BaseConstructor>(SuperClass: T): CanControlValueAccessorMixin & T {
    @Directive()
    class ValueAccessor extends SuperClass implements ControlValueAccessor {
        value: any;
        onChange: (_: any) => void;
        onTouched: () => void;

        writeValue(value: any) {
            this.value = value;
        }

        registerOnChange(fn: (_: any) => void): void {
            this.onChange = fn;
        }

        registerOnTouched(fn: () => void): void {
            this.onTouched = fn;
        }
    }

    return ValueAccessor;
}

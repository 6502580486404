import {
    Directive,
    HostListener,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    Output
} from "@angular/core";

import { formatDecimal } from './util';

@Directive({
    selector: "[decimalFormatter]"
})
export class DecimalFormatterDirective implements OnChanges {
    @Input() ngModel: any;
    @Output() ngModelChange: EventEmitter<any> = new EventEmitter<any>();
    @Input() decimalPlaces: number;

    private el: HTMLInputElement;

    constructor(
        private elementRef: ElementRef
    ) {
        this.el = this.elementRef.nativeElement;
    }

    ngOnChanges(changes: any) {
        if (!this.isElementFocused()) {
            setTimeout(() => {
                this.setFormattedValue(this.ngModel);
            }, 100);
        }
    }

    @HostListener("blur", ["$event.target.value"])
    onBlur(value: any) {
        this.setFormattedValue(value);
    }

    private isElementFocused(): boolean {
        try {
            return (document.activeElement === this.el);
        } catch (error) {
            return false;
        }
    }

    private setFormattedValue(value: any) {
        const formattedValue = formatDecimal(value, this.decimalPlaces);
        this.ngModelChange.emit(formattedValue);
    }
}

import {
    Component, HostBinding,
    Input,
} from '@angular/core';

/**
 * For displaying a square of color
 */
@Component({
    selector: 'climb-color-box',
    template: `
        {{text}}
    `,
    styles: [`
        :host {
            display: inline-block;
            border: 1px solid #ccc;
            padding: 4px;
        }
    `]
})
export class ClimbColorBoxComponent  {
    @Input() backgroundColor: string;
    @Input() foregroundColor: string;
    @Input() height: string;
    @Input() text: string;

    @HostBinding('style.background-color')
    get _backgroundColor(): string {
        return this.backgroundColor ?? '#FFFFFF';
    }

    @HostBinding('style.color')
    get _foregroundColor(): string {
        return this.foregroundColor ?? '#000000';
    }

    @HostBinding('style.min-width')
    get _minWidth(): string {
        return this.height ?? '100%';
    }

    @HostBinding('style.min-height')
    get _minHeight(): string {
        return this.height ?? '100%';
    }

    @HostBinding('class.white')
    get _whiteClass(): boolean {
        return !this.backgroundColor || this.backgroundColor.toLowerCase() === '#ffffff';
    }
}

import type { Line } from './line.interface';
import type { TaskInstance } from './task-instance.interface';

export interface TaskLine {
    C_Line_key: number;
    C_TaskInstance_key: number;
    C_TaskLine_key: number;
    C_Workgroup_key: number;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    Line: Line;
    ModifiedBy: string;
    Sequence: number;
    TaskInstance: TaskInstance;
    Version: number;
}

<climb-select [(ngModel)]="currentWorkspaceKey"
              size="md"
              [popoverOptions]="popoverOptions"
              class="workspace-selector"
              [ngStyle]="{ 'width.ch': currentWorkspaceNameLength }">
    <div class="options-wrapper" climbTrapFocus climbArrowKeyAsTab>
        <div class="dashboard-option">
            <climb-select-option (click)="navigateToDashboard()"
                                 tabindex="0">
                Dashboard
            </climb-select-option>
        </div>
        <div class="workspace-options" *ngIf="workspaces?.length">
            <climb-select-option *ngFor="let workspace of workspaces"
                                 [value]="workspace.C_Workspace_key"
                                 (click)="navigateToWorkspace(workspace)"
                                 tabindex="0">
                {{ workspace.WorkspaceName }}
            </climb-select-option>
        </div>
        <div class="workspace-actions">
            <button data-automation-id="add-workspace-item" 
                    climb-menu-item 
                    (click)="openAddWorkspaceModal()">
                <svg [climbIcon]="icons.plusCircleFilled"></svg>
                Add workspace
            </button>
            <button *ngIf="isWorkspaceState()"
                    (click)="deleteWorkspace()"
                    climb-menu-item>
                <svg [climbIcon]="icons.trashcan"></svg>
                Delete workspace
            </button>
            <button *ngIf="isWorkspaceState()"
                    (click)="customizeWorkspaceLayout()"
                    climb-menu-item>
                <svg [climbIcon]="icons.padlockOpenedFilled"></svg>
                Unlock workspace
            </button>
        </div>
    </div>
</climb-select>

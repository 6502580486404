import { ElementRef } from '@angular/core';
import { 
    Observable,
    from,
    of
} from 'rxjs';
import {
    catchError,
    debounceTime,
    finalize,
    tap,
    switchMap
} from 'rxjs/operators';

import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';

import { createNewEvent } from '../util';

enum Key {
    ArrowDown = 40
}

/*
* Shared logic for the Climb typeaheads
*/
export class TypeaheadHelper {
    
    ngbTypeahead: NgbTypeahead;

    // state variables
    searching: boolean;
    searchFailed: boolean;

    constructor() {
        this.searching = false;
        this.searchFailed = false;
    }

    setNgbTypeahead(ngbTypeahead: NgbTypeahead) {
        this.ngbTypeahead = ngbTypeahead;
    }

    /*
    * observable function for ng-bootstrap typeahead
    */
    searchObservable = (
        text$: Observable<string>,
        search: (text: string) => Promise<any[]>,
        resultFormatter: (value: any) => string,
        exactMatchFunction?: (data: any[], term: string) => boolean
    ): Observable<any[]> => {

        if (!exactMatchFunction) {
            exactMatchFunction = (data: any[], term: string) => {
                return this.isExactMatch(data, term, resultFormatter);
            };
        }

        return text$.pipe(
            debounceTime(200),
            tap((data: any) => {
                this.searching = true;
            }),
            switchMap((term: string) =>
                from(search(term)).pipe(
                    tap((data: any) => {
                        this.searchFailed = false;

                        if (exactMatchFunction(data, term)) {
                            this.manuallySelectMatch(data[0]);
                            data.pop();
                        }
                        this.searching = false;
                    }),
                    catchError((error) => {
                        console.error(error);
                        this.searchFailed = true;
                        return of([]);
                    })
                )
            ),
            finalize(() => {
                this.searching = false;
            })
        );
    }

    /*
    * Check if a term is an exact match of given result data
    */
    isExactMatch(
        data: any[],
        term: string,
        resultFormatter: (value: any) => string
    ) {

        if (term &&
            data &&
            data.length === 1
        ) {
            const matchValue = resultFormatter(data[0]);
            return term.toLowerCase() === matchValue.toLowerCase();
        }

        return false;
    }

    manuallySelectMatch(match: any) {
        if (this.ngbTypeahead) {
            const selectResultMethod = '_selectResult';
            const closePopupMethod = '_closePopup';
            this.ngbTypeahead[selectResultMethod](match);
            this.ngbTypeahead[closePopupMethod]();
        }
    }

    triggerTypeahead() {
        if (this.ngbTypeahead) {
            const inputElement = this._getNativeInput();

            // fire change event
            if (inputElement) {
                const inputEvent = createNewEvent('input');
                inputElement.dispatchEvent(inputEvent);
            }
        }
    }

    triggerTypeaheadOnDownArrow(event: KeyboardEvent) {
        if (event.which === Key.ArrowDown) {
            this.triggerTypeahead();
        }
    }

    /**
     * Manually writes a value to the input box
     *   For display only
     * @param value 
     */
    writeInputValue(value: string): void {
        if (this.ngbTypeahead) {
            this.ngbTypeahead.writeValue(value);
        }
    }


    _getNativeInput(): HTMLInputElement {
        let inputElement: HTMLInputElement = null;
        if (this.ngbTypeahead) {
            const elementRefProp = '_elementRef';
            const elementRef: ElementRef = this.ngbTypeahead[elementRefProp];
            inputElement = <HTMLInputElement> elementRef.nativeElement;
        }
        return inputElement;
    }
}

import { Pipe, PipeTransform } from '@angular/core';

import { notEmpty } from '../../common/util';

import { SDCharacteristic } from '../models';

/**
 * Returns SDCharacteristics for a specified taxon
 */
@Pipe({
    name: 'characteristicTaxonFilter',
    pure: false
})
export class CharacteristicTaxonFilterPipe implements PipeTransform {

    transform(characteristics: SDCharacteristic[], taxon: string): any[] {
        let filtered: SDCharacteristic[] = [];

        if (notEmpty(characteristics)) {
            filtered = characteristics.filter((characteristic: SDCharacteristic) => {
                return characteristic.taxon === taxon;
            });
        }

        return filtered;
    }
}

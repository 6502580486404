import type { TaskOutputSet } from './task-output-set.interface';
import type { Output } from './output.interface';
import type { StoredFileMap } from './stored-file-map.interface';

export interface TaskOutput {
    C_Output_key: number;
    C_TaskOutputSet_key: number;
    C_TaskOutput_key: number;
    C_Workgroup_key: number;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    HasCohortStatsFlag: boolean;
    HasFlag: boolean;
    ModifiedBy: string;
    Output: Output;
    OutputValue: string;
    SortOrder: number | null;
    StoredFileMap: StoredFileMap[];
    TaskOutputSet: TaskOutputSet;
    Version: number;
}

import type { ExpressionInputMapping } from './expression-input-mapping.interface';
import type { ExpressionOutputMapping } from './expression-output-mapping.interface';
import type { Output } from './output.interface';

export interface CalculatedOutputExpression {
    C_CalculatedOutputExpression_key: number;
    C_Output_key: number;
    ExpressionInputMapping: ExpressionInputMapping[];
    ExpressionOutputMapping: ExpressionOutputMapping[];
    Output: Output;
    OutputExpression: string;
}

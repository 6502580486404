import { 
    CellFormatterService,
    TableOptions 
} from '../../common/datatable';
import { uniqueArrayFromPropertyPath } from '../../common/util';
import { addAuditColumnsToOptions } from '@common/datatable/utils';

export class HousingTableOptions {

    options: TableOptions;

    constructor(
        private cellFormatterService: CellFormatterService
    ) {
        this.options = addAuditColumnsToOptions(cellFormatterService)(this.getDefaultOptions());
    }

    getDefaultOptions(): TableOptions {
        return {
            csvFileName: 'housing.csv',
            enableDetailColumn: true,
            enableDraggable: true,
            enableSelectable: true,
            columns: [
                {
                    displayName: 'ID',
                    sortField: 'MaterialPoolIDSortable',
                    field: 'MaterialPoolID',
                },
                {
                    displayName: 'Type',
                    field: 'cv_MaterialPoolType.MaterialPoolType'
                },
                {
                    displayName: 'Status',
                    field: 'cv_MaterialPoolStatus.MaterialPoolStatus',
                    visible: false
                },
                {
                    displayName: 'Date',
                    field: 'DatePooled',
                    formatter: this.cellFormatterService.dateFormatter
                },
                {
                    displayName: 'Owner',
                    field: 'Owner',
                    visible: false,
                    formatter: this.cellFormatterService.userNameFormatter
                },
                {
                    displayName: 'Location',
                    field: 'CurrentLocationPath',
                    visible: false
                },
                {
                    displayName: 'Comments',
                    field: 'Comments',
                    visible: false
                },
                {
                    displayName: 'Species',
                    field: 'MaterialPoolMaterial.Material.cv_Taxon',
                    sortable: false,
                    visible: false,
                    formatter: (row: any, value: any) => {                        
                        const uniqueValues = uniqueArrayFromPropertyPath(
                            row,
                            'MaterialPoolMaterial.Material.cv_Taxon.CommonName'
                        );
                        return uniqueValues.join(', ');
                    }
                },
                {
                    displayName: 'Count',
                    field: 'Count',
                    visible: false
                },
                {
                    displayName: 'Notes',
                    field: 'Note',
                    visible: false,
                    formatter: this.cellFormatterService.noteFormatter,
                    sortable: false
                },
            ]
        };
    }
}

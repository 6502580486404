import {
    CellFormatterService,
    TableOptions,
    FormatterOptions
} from '../common/datatable';
import { AuditModifiedFieldComponent } from './audit-modified-fields/audit-modified-field.component';
import { AuditClassFactory } from './audit-class-maker';

export class AuditBirthsTableOptions {
    options: TableOptions;

    constructor(
        private cellFormatterService: CellFormatterService
    ) {
        this.options = this.getDefaultOptions();
    }

    getDefaultOptions(): TableOptions {

        return {
            csvFileName: 'births-audit.csv',
            enableDetailColumn: false,
            enableDraggable: false,
            enableSelectable: false,
            enableAuditColumns: false,
            columns: [
                {
                    displayName: 'Type',
                    field: 'UpdateType',
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Modified Fields',
                    field: 'ModifiedFields',
                    sortField: 'ModifiedFields',
                    rendererComponent: AuditModifiedFieldComponent,
                    exportFormatter: this.cellFormatterService.auditModifiedFieldsExportFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Date',
                    field: 'ModifiedDate',
                    formatter: this.cellFormatterService.dateTimeUTCFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Modified By',
                    field: 'ModifiedBy',
                    formatter: this.cellFormatterService.userNameFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Birth ID',
                    field: 'BirthID',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('BirthID', 'BirthID'),
                    cellClass: AuditClassFactory.createCellClass('birthid'),
                },
                {
                    displayName: 'Mating ID',
                    field: 'MatingID',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('MatingID', 'MatingID'),
                    cellClass: AuditClassFactory.createCellClass('matingid'),
                },
                {
                    displayName: 'Housing ID',
                    field: 'HousingID',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('HousingID', 'HousingID'),
                    cellClass: AuditClassFactory.createCellClass('housingid'),
                },
                {
                    displayName: 'Status',
                    field: 'BirthStatus',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('BirthStatus', 'BirthStatus'),
                    cellClass: AuditClassFactory.createCellClass('birthstatus'),
                },
                {
                    displayName: 'Birth Date',
                    field: 'DateBorn',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('DateBorn', 'DateBorn', this.cellFormatterService.dateFormatter),
                    cellClass: AuditClassFactory.createCellClass('dateborn'),
                },
                {
                    displayName: 'Wean Date',
                    field: 'DateWean',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('DateWean', 'DateWean', this.cellFormatterService.dateFormatter),
                    cellClass: AuditClassFactory.createCellClass('datewean'),
                },
                {
                    displayName: 'Comments',
                    field: 'Comments',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('Comments', 'Comments'),
                    cellClass: AuditClassFactory.createCellClass('comments'),
                },
                {
                    displayName: 'Live Born Count',
                    field: 'LiveCount',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('LiveCount', 'LiveCount'),
                    cellClass: AuditClassFactory.createCellClass('livecount'),
                },
                {
                    displayName: 'Stillborn Count',
                    field: 'StillbornCount',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('StillbornCount', 'StillbornCount'),
                    cellClass: AuditClassFactory.createCellClass('stillborncount'),
                },
                {
                    displayName: 'Dead Count',
                    field: 'DeadCount',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('DeadCount', 'DeadCount'),
                    cellClass: AuditClassFactory.createCellClass('deadcount'),
                },
                {
                    displayName: 'Is Foster',
                    field: 'IsFoster',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('IsFoster', 'IsFoster'),
                    cellClass: AuditClassFactory.createCellClass('isfoster'),
                },
                {
                    displayName: 'Foster ID',
                    field: 'FosterID',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('FosterID', 'FosterID'),
                    cellClass: AuditClassFactory.createCellClass('fosterid'),
                },
                {
                    displayName: 'Created By',
                    field: 'CreatedBy',
                    formatter: this.cellFormatterService.userNameFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Created Date',
                    field: 'DateCreated',
                    formatter: this.cellFormatterService.dateTimeUTCFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                }
            ]
        };
    }
}

import { Injectable } from '@angular/core';
import { defer, Observable } from 'rxjs';

import { LocationService } from '../locations/location.service';
import { VocabularyService } from '../vocabularies/vocabulary.service';
import { take } from 'rxjs/operators';
import { cv_SampleType, Entity } from '@common/types';

@Injectable()
export class SampleVocabService {

    // vocab Observables
    public get animalStatuses$(): Observable<any[]>{
        return defer(() => this.vocabularyService.getCV('cv_AnimalStatuses')).pipe(take(1));
    }
    public get containerTypes$(): Observable<any[]>{
        return defer(() => this.locationService.getContainerTypes('Sample')).pipe(take(1));
    }
    public get materialOrigins$(): Observable<any[]>{
        return defer(() => this.vocabularyService.getCV('cv_MaterialOrigins')).pipe(take(1));
    }
    public get preservationMethods$(): Observable<any[]>{
        return defer(() => this.vocabularyService.getCV('cv_PreservationMethods')).pipe(take(1));
    }
    public get sampleStatuses$(): Observable<any[]>{
        return defer(() => this.vocabularyService.getCV('cv_SampleStatuses')).pipe(take(1));
    }
    public get sampleTypes$(): Observable<Entity<cv_SampleType>[]>{
        return defer(() => this.vocabularyService.getCV('cv_SampleTypes')).pipe(take(1));
    }
    public get sexes$(): Observable<any[]>{
        return defer(() => this.vocabularyService.getCV('cv_Sexes')).pipe(take(1));
    }
    public get taxons$(): Observable<any[]>{
        return defer(() => this.vocabularyService.getCV('cv_Taxons')).pipe(take(1));
    }
    public get units$(): Observable<any[]>{
        return defer(() => this.vocabularyService.getCV('cv_Units')).pipe(take(1));
    }
    public get timeUnits$(): Observable<any[]>{
        return defer(() => this.vocabularyService.getCV('cv_TimeUnits')).pipe(take(1));
    }
    public get sampleSubtypes$(): Observable<any[]>{
        return defer(() => this.vocabularyService.getCV('cv_SampleSubtypes')).pipe(take(1));
    }
    public get sampleProcessingMethods$(): Observable<any[]>{
        return defer(() => this.vocabularyService.getCV('cv_SampleProcessingMethods')).pipe(take(1));
    }
    public get sampleAnalysisMethods$(): Observable<any[]>{
        return defer(() => this.vocabularyService.getCV('cv_SampleAnalysisMethods')).pipe(take(1));
    }

    constructor(
        private locationService: LocationService,
        private vocabularyService: VocabularyService,
    ) {
        this._init();
    }

    _init() {
        this.vocabularyService.ensureCVLoaded('cv_MaterialTypes');
    }
}

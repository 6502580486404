import { Subscription } from 'rxjs';
import {
    Component,
    Input,
    OnChanges,
    OnInit,
    NgZone,
    OnDestroy
} from '@angular/core';
import {
    NgbModal
} from '@ng-bootstrap/ng-bootstrap';
import * as escapeStringRegexp from 'escape-string-regexp';

import { DropzoneCommService } from './dropzone-comm.service';
import { FileUploadCommService } from './file-upload-comm.service';
import { StoredFileService } from './storedfile.service';
import { ViewImageService } from './view-image.service';
import {
    isImageFile
} from './is-image-file';

import {
    notEmpty,
    randomId,
    scrollToElement
} from '../util';
import { TableSort } from '../../common/models';
import { FileService } from '../../services/file.service';
import { ReasonForChangeService } from '../reason-for-change/reason-for-change.service';

@Component({
    selector: 'facet-detail-file-upload',
    templateUrl: 'facet-detail-file-upload.component.html',
    providers: [DropzoneCommService, ViewImageService],
    styles: [`
        .filter-input-group {
            float: right;
            width: 20em;
        }
        .dropzone-wrapper {
            margin-top: 0.5em;
        }
    `]
})
export class FacetDetailFileUploadComponent implements OnChanges, OnInit, OnDestroy {
    /* The user's privilege level for the parent facet */
    @Input() facetPrivilege: string;
    /* The name of the object's primary key database field */
    @Input() pkName: string;
    /* The value of the object's primary key */
    @Input() pkValue: number;
    @Input() disabled: boolean;

    /* All files */
    files: any[];
    /* Files, potentially filtered, that will be displayed */
    filesDisplay: any[];

    dropzoneCommService: DropzoneCommService;
    modalService: any;
    zone: NgZone;

    _filterInputId: string;
    _scrollElementClass: string;

    // state
    filterTerm: string;
    tableSort: TableSort;
    dropZoneExpanded: boolean;

    private _subscriptions: Subscription[] = [];

    readonly TABLE_SORT_PROPERTY_PATH_DEFAULT = 'FriendlyFileName';


    constructor(
        dropzoneCommService: DropzoneCommService,        
        modalService: NgbModal,
        zone: NgZone,
        private viewImageService: ViewImageService,
        private fileService: FileService,
        private fileUploadCommService: FileUploadCommService,
        private storedFileService: StoredFileService,
        private reasonForChangeService: ReasonForChangeService
    ) {
        this.dropzoneCommService = dropzoneCommService;
        this.modalService = modalService;
        this.zone = zone;
    }

    ngOnInit() {
        this.files = [];
        this.filesDisplay = [];
        this.filterTerm = "";

        const idSuffix = randomId();

        this._filterInputId = 'filter-input-' + idSuffix;
        this._scrollElementClass = "dropzone-scroll-" + idSuffix;
        this.setDefaultTableSort();

        const s1 = this.fileUploadCommService.refreshedFiles$
            .subscribe(() => {
                this.setupFiles();
        });
        this._subscriptions.push(s1);
    }

    ngOnChanges(changes: any) {

        if ((changes.pkName || changes.pkValue) && !this.wasNewRecordCreated(changes)) {
            this.dropZoneExpanded = false;
            this.setupFiles();
        }
    }

    ngOnDestroy() {
        for (const subscription of this._subscriptions) {
            subscription.unsubscribe();
        }
    }

    private setDefaultTableSort() {
        if (!this.tableSort) {
            this.tableSort = new TableSort();
        }
        if (!this.tableSort.propertyPath) {
            this.tableSort.propertyPath = this.TABLE_SORT_PROPERTY_PATH_DEFAULT;
        }
    }

    wasNewRecordCreated(changes: any): boolean {
        return (
            changes.pkValue &&
            changes.pkValue.previousValue < 0 &&
            changes.pkValue.currentValue > 0
        );
    }

    async expandDropzoneClicked(): Promise<void> {
        this.dropZoneExpanded = true;

        await scrollToElement('.' + this._scrollElementClass);
    }

    collapseDropzoneClicked(): void {
        this.dropZoneExpanded = false;
    }
    
    addFile(event: any) {
        const newStoredFile = this.storedFileService.create(
            this.pkName,
            this.pkValue,
            event.fileData,
            event.description
        );
        this.files.push(newStoredFile);
        this.updateFilterList();
    }

    removeFile(file: any) {
        this._markExtractedEntityAsModified(file);
        this.storedFileService.deleteFile(file);
        const index = this.files.indexOf(file);
        this.files.splice(index, 1);
        this.updateFilterList();
        this.removeDropzoneFile(file);
    }

    private _markExtractedEntityAsModified(file: any): void {
        if (file.StoredFileMap) {
            const storedFileMap = file.StoredFileMap[0];
            if (storedFileMap) {
                const material = storedFileMap.Material;
                if (material) {
                    if (material.Animal) {
                        this.reasonForChangeService.markModification([material.Animal]);
                    } else if (material.Sample) {
                        this.reasonForChangeService.markModification([material.Sample]);
                    }
                }
                if (storedFileMap.Order) {
                    this.reasonForChangeService.markModification([storedFileMap.Order]);
                }
                const job = storedFileMap.Job;
                if (job) {
                    this.reasonForChangeService.markModification([job]);
                }
            }
        }
    }

    dropzoneRemovedFile(event: any) {
        // find filename delete event applies to
        const matchingFiles = this.files.filter((file: any) => {
            return file.FriendlyFileName === event.name;
        });

        if (notEmpty(matchingFiles)) {
            // delete the file
            const file = matchingFiles[0];
            this.storedFileService.deleteFile(file);
            const index = this.files.indexOf(file);
            this.files.splice(index, 1);
            this.updateFilterList();
        }
    }

    removeDropzoneFile(file: any) {
        this.dropzoneCommService.removeFile(file);
    }

    removeAllDropzoneFiles() {
        this.dropzoneCommService.removeAllFiles();
    }

    setupFiles(): Promise<any> {
        this.files = [];
        this.removeAllDropzoneFiles();

        if (this.pkName && this.pkValue) {
            return this.storedFileService.getFiles(
                this.pkName,
                this.pkValue
            ).then((files: any[]) => {
                if (!files) {
                    files = [];
                }

                for (const file of files) {
                    file.url = file.FileURI;
                }
                this.files = files;

                this.updateFilterList();
            }).then(() => {
                return this.zone.run(() => { /* refresh NG2 views */ });
            });
        }

        return Promise.resolve();
    }


    isImage(filename: string) {
        return isImageFile(filename);
    }

    viewImage(file: any) {
        this.viewImageService.openImageModal(file);
    }

    downloadFile(file: any) {
        this.fileService.downloadFile(file.FileName, file.FriendlyFileName).subscribe();
    }

    updateFilterList() {
        this.filterList(this.filterTerm);
    }

    // Filtering
    filterList(term: string): void {
        if (term) {
            this.filesDisplay = this.files.filter((file: any) => {
                return this.matchFilterTerm(file.FriendlyFileName, term);
            });
        } else {
            this.filesDisplay = this.files.slice();
        }
    }

    private matchFilterTerm(textToSearch: string, query: string): boolean {
        const escapedQuery = escapeStringRegexp(query);
        return new RegExp(escapedQuery, 'gi').test(textToSearch);
    }

}

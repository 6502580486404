import { Injectable } from '@angular/core';
import { GlpBaseFacetStateService } from '../../services/glp-base-facet-state.service';
import {
    Entity as InternalEntity,
    Input,
    Line,
    LineGenotypeAssay,
    StoredFile,
    StoredFileMap,
    TaskInput,
    TaskInstance,
    TaskLine,
    TaskMaterial
}
    from '../../common/types';
import { DataManagerService } from '../../services/data-manager.service';
import { Entity as BreezeEntity } from 'breeze-client';

@Injectable()
export class LineStateService extends GlpBaseFacetStateService<Line> {

    constructor(protected dataManagerService: DataManagerService) {
        super(dataManagerService);
    }

    getRelatedCollectionChanges(line: InternalEntity<Line>): BreezeEntity[] {
        const changes: BreezeEntity[] = [];
        changes.push(...this.getChangesToLineGenotypeAssays(line));
        changes.push(...this.getChangesToTaskLine(line));
        changes.push(...this.getChangesToTaskInstance(line));
        changes.push(...this.getChangesToTaskInputs(line));
        changes.push(...this.getChangesToTaskMaterials(line));
        changes.push(...this.getChangesToInputs(line));
        changes.push(...this.getChangesToFiles(line.C_Line_key));
        return changes;
    }

    public getChangesToLineGenotypeAssays(
        line: Line,
    ): InternalEntity<LineGenotypeAssay>[] {
        const LineGenotypeAssaysId = line.LineGenotypeAssay?.map(LineGenotypeAssay => LineGenotypeAssay.C_LineGenotypeAssay_key);

        const allChangedLineGenotypeAssays = (this.dataManagerService
            .getManager()
            .getChanges("LineGenotypeAssay") ?? []) as InternalEntity<LineGenotypeAssay>[];

        const changedLineGenotypeAssaysForTask = allChangedLineGenotypeAssays
            .filter((LineGenotypeAssay) =>
                LineGenotypeAssaysId.some(
                    (LineGenotypeAssayId) =>
                        LineGenotypeAssayId === LineGenotypeAssay.C_LineGenotypeAssay_key,
                ),
            );

        return changedLineGenotypeAssaysForTask;
    }

    public getChangesToTaskLine(
        line: Line,
    ): InternalEntity<TaskLine>[] {
        const TaskLinesId = line.TaskLine.map(TaskLine => TaskLine.C_TaskLine_key);

        const allChangedTaskLines = (this.dataManagerService
            .getManager()
            .getChanges("TaskLine") ?? []) as InternalEntity<TaskLine>[];

        const changedTaskLinesForLine = allChangedTaskLines
            .filter((TaskLine) =>
                TaskLinesId.some(
                    (TaskLineId) =>
                        TaskLineId === TaskLine.C_TaskLine_key,
                ),
            );

        return changedTaskLinesForLine;
    }

    public getChangesToTaskInstance(
        line: Line,
    ): InternalEntity<TaskInstance>[] {
        const TakInstancesIdForLine = line.TaskLine.map(taskLine => taskLine.C_TaskInstance_key);

        const allChangedTaskInstances = (this.dataManagerService
            .getManager()
            .getChanges("TaskInstance") ?? []) as InternalEntity<TaskInstance>[];

        const changedTaskInstancesForLine = allChangedTaskInstances
            .filter((taskInstance) =>
                TakInstancesIdForLine.some(
                    (taskInstanceId) =>
                        taskInstanceId === taskInstance.C_TaskInstance_key,
                ),
            );

        return changedTaskInstancesForLine;
    }

    public getChangesToTaskInputs(
        line: Line,
    ): InternalEntity<TaskInput>[] {
        const taskInputsForLine
            = line.TaskLine.flatMap(taskLine =>
                taskLine.TaskInstance.TaskInput.map(taskInput => taskInput.C_TaskInput_key));

        const allChangedTaskInputs = (this.dataManagerService
            .getManager()
            .getChanges("TaskInput") ?? []) as InternalEntity<TaskInput>[];

        const changedTaskInputsForLine = allChangedTaskInputs
            .filter((taskInput) =>
                taskInputsForLine.some(
                    (taskInputId) =>
                        taskInputId === taskInput.C_TaskInput_key,
                ),
            );
        return changedTaskInputsForLine;
    }

    public getChangesToTaskMaterials(
        line: Line,
    ): InternalEntity<TaskMaterial>[] {
        const taskMaterialsForLine
            = line.TaskLine.flatMap(taskLine =>
                taskLine.TaskInstance.TaskMaterial.map(TaskMaterial => TaskMaterial.C_TaskMaterial_key));

        const allChangedTaskMaterials = (this.dataManagerService
            .getManager()
            .getChanges("TaskMaterial") ?? []) as InternalEntity<TaskMaterial>[];

        const changedTaskMaterialsForLine = allChangedTaskMaterials
            .filter((taskMaterial) =>
                taskMaterialsForLine.some(
                    (taskMaterialId) =>
                        taskMaterialId === taskMaterial.C_TaskMaterial_key,
                ),
            );
        return changedTaskMaterialsForLine;
    }

    public getChangesToInputs(
        line: Line,
    ): InternalEntity<Input>[] {
        const inputsForLine
            = line.TaskLine.flatMap(taskLine =>
                taskLine.TaskInstance.TaskInput.map(taskInput => taskInput.C_Input_key));

        const allChangedInputs = (this.dataManagerService
            .getManager()
            .getChanges("Input") ?? []) as InternalEntity<Input>[];

        const changedInputsForLine = allChangedInputs
            .filter((input) =>
                inputsForLine.some(
                    (inputId) =>
                        inputId === input.C_Input_key,
                ),
            );
        return changedInputsForLine;
    }

    getChangesToFiles(lineKey: number): BreezeEntity[] {
        const changes: BreezeEntity[] = [];

        const storedFileMaps = (this.dataManagerService
            .getManager()
            .getChanges("StoredFileMap") ??
            []) as InternalEntity<StoredFileMap>[];
        const changedStoreFileMaps = storedFileMaps.filter(
            (storedFileMap) => storedFileMap.C_Line_key === lineKey,
        );

        const storedFiles = (this.dataManagerService
            .getManager()
            .getChanges("StoredFile") ?? []) as InternalEntity<StoredFile>[];

        // Get corresponding files descriptions
        const changedStoreFiles = storedFiles.filter((storedFile) =>
            changedStoreFileMaps.some(
                (storedFileMap) =>
                    storedFileMap.C_StoredFile_key === storedFile.C_StoredFile_key,
            ),
        );

        changes.push(...changedStoreFileMaps);
        changes.push(...changedStoreFiles);
        return changes;
    }

    
    discardChanges(line: InternalEntity<Line>): void {
        // TODO: implement facet-level discard operation after release of GLP
        throw new Error('Method not implemented.');
    }

    getDeleteNavPropertyChanges(entity: InternalEntity<Line>): BreezeEntity[] {
        const changes: BreezeEntity[] = [];       
        changes.push(...this.getChangesToDeletedLineGenotypeAssays(entity))
        changes.push(...this.getChangesToDeletedTaskLines(entity))
        changes.push(...this.getChangesToDeletedTaskInstances(entity))
        changes.push(...this.getChangesToDeletedTaskInputs(entity))
        changes.push(...this.getChangesToDeletedTaskMaterials(entity))
        return changes;
    }    

    private getChangesToDeletedLineGenotypeAssays(line: Line): InternalEntity<LineGenotypeAssay>[] {
        return this.dataManagerService
            .getChangesToRelatedDeletedEntityByKey<LineGenotypeAssay>('LineGenotypeAssay',
                'C_Line_key', line.C_Line_key);
    }

    private getChangesToDeletedTaskLines(line: Line): InternalEntity<TaskLine>[] {
        return this.dataManagerService
            .getChangesToRelatedDeletedEntityByKey<TaskLine>('TaskLine',
                'C_Line_key', line.C_Line_key);
    }

    private getTasksBelongingToLine(line: Line): TaskLine[] {
        const tasksBelongingToLine: TaskLine[] = [];
        const taskLines
            = line.TaskLine.map(taskLine => taskLine);

        tasksBelongingToLine.push(...taskLines);
        const deletedTaskLines = this.getChangesToDeletedTaskLines(line);
        tasksBelongingToLine.push(...deletedTaskLines);
        return tasksBelongingToLine;
    }

    private getTaskInstancesBelongingToLine(line: Line): TaskInstance[] {
        const taskInstancesBelongingToLine: TaskInstance[] = [];
        const taskInstances
            = line.TaskLine.map(taskLine => taskLine.TaskInstance);

        taskInstancesBelongingToLine.push(...taskInstances);
        const changesToDeletedTaskInstances = this.getChangesToDeletedTaskInstances(line);
        taskInstancesBelongingToLine.push(...changesToDeletedTaskInstances);
        return taskInstancesBelongingToLine;
    }
    private getChangesToDeletedTaskInstances(line: Line): InternalEntity<TaskInstance>[] {
        const tasksBelongingToLine = this.getTasksBelongingToLine(line);
        const changes: InternalEntity<TaskInstance>[] = [];
        for (const taskLine of tasksBelongingToLine) {
            const deletedTaskInstances = this.dataManagerService
                .getChangesToRelatedDeletedEntityByKey<TaskInstance>('TaskInstance',
                    'C_TaskInstance_key', taskLine.C_TaskInstance_key);
            changes.push(...deletedTaskInstances);
        }
        return changes;
    }

    private getChangesToDeletedTaskInputs(line: Line): InternalEntity<TaskInput>[] {
        const taskInstancesBelongingToLine = this.getTaskInstancesBelongingToLine(line);
        const changes: InternalEntity<TaskInput>[] = [];
        for (const taskInstance of taskInstancesBelongingToLine) {
            const deletedTaskInputs = this.dataManagerService
                .getChangesToRelatedDeletedEntityByKey<TaskInput>('TaskInput',
                    'C_TaskInstance_key', taskInstance.C_TaskInstance_key);
            changes.push(...deletedTaskInputs);
        }
        return changes;
    }
    

    private getChangesToDeletedTaskMaterials(line: Line): InternalEntity<TaskMaterial>[] {
        const allChangesToTaskInstances = this.getTaskInstancesBelongingToLine(line);
        const changes: InternalEntity<TaskMaterial>[] = [];
        for (const deletedTaskInstance of allChangesToTaskInstances) {
            const deletedTaskInputs = this.dataManagerService
                .getChangesToRelatedDeletedEntityByKey<TaskMaterial>('TaskMaterial',
                    'C_TaskInstance_key', deletedTaskInstance.C_TaskInstance_key);
            changes.push(...deletedTaskInputs);
        }
        return changes;
    }
}

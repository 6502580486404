import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';

import { BaseFilter } from '../common/facet';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
    focusElementByQuery
} from '../common/util/';


@Component({
    selector: 'construct-filter',
    templateUrl: './construct-filter.component.html'
})
export class ConstructFilterComponent extends BaseFilter implements OnInit {
    @Input() filter: any = {};
    @Output() onFilter = new EventEmitter<any>();
    @Output() close = new EventEmitter<any>();


    constructor(
        private activeModal: NgbActiveModal
    ) {
        super(activeModal);
    }

    ngOnInit() {
        this.cloneFilter();

        focusElementByQuery('[data-auto-focus="constructID"]');
    }

    private cloneFilter() {
        this.filter = this.copyFilter(this.filter);
    }

    filterClicked(event: Event) {
        event.preventDefault();
        this.onFilter.emit(this.filter);
        this.close.emit();
        this.activeModal.close();
    }

    closeClicked() {
        this.close.emit();
        this.activeModal.dismiss();
    }

    clearClicked() {
        this.filter = {};
        this.onFilter.emit(this.filter);
        this.close.emit();
        this.activeModal.close();
    }
}

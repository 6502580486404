import {
    CellFormatterService,
    FormatterOptions,
    TableOptions
} from '../common/datatable';
import { TranslationService } from '../services/translation.service';
import { AuditModifiedFieldComponent } from './audit-modified-fields/audit-modified-field.component';
import { AuditClassFactory } from './audit-class-maker';
import { ignoreColumnsInOptions } from '@common/datatable/utils';

export class AuditCharacteristicsJobsTableOptions {
    options: TableOptions;

    constructor(
        private cellFormatterService: CellFormatterService,
        private translationService: TranslationService,
        private isCRO: boolean,
        private isCRL: boolean
    ) {
        this.options = ignoreColumnsInOptions()(this.getDefaultOptions());
    }

    getDefaultOptions(): TableOptions {

        return {
            csvFileName: 'characteristic-' + this.translationService.translate('jobs').toLowerCase() + '-audit.csv',
            enableDetailColumn: false,
            enableDraggable: false,
            enableSelectable: false,
            enableAuditColumns: false,
            columns: [
                {
                    displayName: 'Type',
                    field: 'UpdateType',
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Modified Fields',
                    field: 'ModifiedFields',
                    sortField: 'ModifiedFields',
                    rendererComponent: AuditModifiedFieldComponent,
                    exportFormatter: this.cellFormatterService.auditModifiedFieldsExportFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Date',
                    field: 'ModifiedDate',
                    formatter: this.cellFormatterService.dateTimeUTCFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Modified By',
                    field: 'ModifiedBy',
                    formatter: this.cellFormatterService.userNameFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: this.translationService.translate('Job') + ' Characteristic Name',
                    field: 'JobCharacteristicName',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('JobCharacteristicName', this.translationService.translate('Job') + 'CharacteristicName'),
                    cellClass: AuditClassFactory.createCellClass((this.translationService.translate('Job') + 'CharacteristicName').toLowerCase()),
                },
                {
                    displayName: 'Active',
                    field: 'Active',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('Active', 'Active'),
                    cellClass: AuditClassFactory.createCellClass('active'),
                },                
                {
                    displayName: 'Link Type',
                    field: 'LinkType',
                    formatter: (row: any, value: any, formatterOptions: FormatterOptions) => {
                        if (value?.toLowerCase()?.includes("job")){
                            value = `${this.translationService.translate("Job")} ${value?.split(' ')[1]}`;
                        }
                        return this.cellFormatterService.auditColumnFormatter(row, value, formatterOptions, 'LinkType', 'LinkType');
                    },
                    cellClass: AuditClassFactory.createCellClass('linktype'),
                },                
                {
                    displayName: this.translationService.translate('Job') + ' Types',
                    field: 'JobTypes',
                    ignore: this.isCRO,
                    formatter: this.cellFormatterService.createAuditColumnFormatter('JobTypes', this.translationService.translate('Job') + 'Types'),
                    cellClass: AuditClassFactory.createCellClass((this.translationService.translate('Job') + 'Types').toLowerCase()),
                },
                {
                    displayName: this.translationService.translate('Job') + ' Subtypes',
                    field: 'JobSubtypes',
                    ignore: !this.isCRO,
                    formatter: this.cellFormatterService.createAuditColumnFormatter('JobSubtypes', this.translationService.translate('Job') + 'Subtypes'),
                    cellClass: AuditClassFactory.createCellClass((this.translationService.translate('Job') + 'Subtypes').toLowerCase()),
                },
                {
                    displayName: 'IACUCProtocols',
                    field: 'IACUCProtocols',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('IACUCProtocols', 'IACUCProtocols'),
                    cellClass: AuditClassFactory.createCellClass('iacucprotocols'),
                },
                {
                    displayName: 'Description',
                    field: 'Description',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('Description', 'Description'),
                    cellClass: AuditClassFactory.createCellClass('description'),
                },
                {
                    displayName: this.translationService.translate('Job') + ' Characteristic Type',
                    field: 'JobCharacteristicType',
                    ignore: !this.isCRO,
                    formatter: this.cellFormatterService.createAuditColumnFormatter('JobCharacteristicType', this.translationService.translate('Job') + 'CharacteristicType'),
                    cellClass: AuditClassFactory.createCellClass((this.translationService.translate('Job') + 'CharacteristicType').toLowerCase()),
                },                  
                {
                    displayName: 'Data Type',
                    field: 'DataType',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('DataType', 'DataType'),
                    cellClass: AuditClassFactory.createCellClass('datatype'),
                },
                {
                    displayName: 'Minimum Value',
                    field: 'MinimumValue',
                    visible: false,
                    formatter: this.cellFormatterService.createAuditColumnFormatter('MinimumValue', 'MinimumValue'),
                    cellClass: AuditClassFactory.createCellClass('minimumvalue'),
                },
                {
                    displayName: 'Maximum Value',
                    field: 'MaximumValue',
                    visible: false,
                    formatter: this.cellFormatterService.createAuditColumnFormatter('MaximumValue', 'MaximumValue'),
                    cellClass: AuditClassFactory.createCellClass('maximumvalue'),
                },
                {
                    displayName: 'Decimal Places',
                    field: 'DecimalPlaces',
                    visible: false,
                    formatter: this.cellFormatterService.createAuditColumnFormatter('DecimalPlaces', 'DecimalPlaces'),
                    cellClass: AuditClassFactory.createCellClass('decimalplaces'),
                },
                {
                    displayName: 'Number Of Rows',
                    field: 'NumberOfRows',
                    visible: false,
                    formatter: this.cellFormatterService.createAuditColumnFormatter('NumberOfRows', 'NumberOfRows'),
                    cellClass: AuditClassFactory.createCellClass('numberofrows'),
                },
                {
                    displayName: 'Enumeration',
                    field: 'Enumeration',
                    visible: false,
                    formatter: this.cellFormatterService.createAuditColumnFormatter('Enumeration', 'Enumeration'),
                    cellClass: AuditClassFactory.createCellClass('enumeration'),
                },
                {
                    displayName: 'Default Value',
                    field: 'DefaultValue',
                    visible: false,
                    formatter: this.cellFormatterService.createAuditColumnFormatter('DefaultValue', 'DefaultValue'),
                    cellClass: AuditClassFactory.createCellClass('defaultvalue'),
                },
                {
                    displayName: 'Vocabulary',
                    field: 'Vocabulary',
                    visible: false,
                    formatter: this.cellFormatterService.createAuditColumnFormatter('Vocabulary', 'Vocabulary'),
                    cellClass: AuditClassFactory.createCellClass('vocabulary'),
                },
                {
                    displayName: 'Last Reviewed',
                    field: 'LastReviewed',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('LastReviewed', 'LastReviewed', this.cellFormatterService.dateFormatter),
                    cellClass: AuditClassFactory.createCellClass('lastreviewed'),
                },
                {
                    displayName: 'Include in Client Report',
                    field: 'InClientReport',
                    ignore: !this.isCRL,
                    visible: false,
                    formatter: this.cellFormatterService.createAuditColumnFormatter('InClientReport', 'InClientReport'),
                    cellClass: AuditClassFactory.createCellClass('inclientreport'),
                }, 
                {
                    displayName: 'Sort Order',
                    field: 'SortOrder',
                    visible: false,
                    formatter: this.cellFormatterService.createAuditColumnFormatter('SortOrder', 'SortOrder'),
                    cellClass: AuditClassFactory.createCellClass('sortorder'),
                },
                {
                    displayName: 'Created By',
                    field: 'CreatedBy',
                    formatter: this.cellFormatterService.userNameFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Created Date',
                    field: 'DateCreated',
                    formatter: this.cellFormatterService.dateTimeUTCFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                }
            ]
        };
    }
}

import {
    Component,
    Input,
    Output,
    OnInit,
    OnDestroy,
    EventEmitter,
    ViewChildren,
} from '@angular/core';

import { JobService } from '../../job.service';
import { DragulaOptions, DragulaService } from 'ng2-dragula';
import { Subscription } from 'rxjs';
import { LoggingService } from '../../../services/logging.service';
import { JobPharmaDetailService } from "../../pharma/services/job-pharma-detail.service";
import { SaveRecordsOverlayEvent } from '../../pharma/tables';
import { EnumerationService } from '../../../enumerations/enumeration.service';
import { VocabularyService } from '../../../vocabularies/vocabulary.service';
import { Entity, ExtendedJob, JobGroup } from '@common/types';
import { NgModel } from '@angular/forms';


export const MAX_DOSING_TABLE_N_AMOUNT = 250;

/**
 * Table for adding groups to jobs
 */
@Component({
    selector: 'job-group-table',
    templateUrl: './job-group-table.component.html'
})
export class JobGroupTableComponent implements OnInit, OnDestroy {
    readonly COMPONENT_LOG_TAG = 'job-group-table';
    readonly MAX_DOSING_TABLE_N_AMOUNT = MAX_DOSING_TABLE_N_AMOUNT;
    @Input() job: Entity<ExtendedJob>;
    @Input() required: boolean;
    @Input() readonly: boolean;

    @Input() isCRL: boolean;
    @Input() isDTX: boolean;
    @Output() busy: EventEmitter<SaveRecordsOverlayEvent> = new EventEmitter<SaveRecordsOverlayEvent>();

    @ViewChildren('group') groupControls: NgModel[];

    bulk: any = null;

    bulkStartNumber: any = 1;
    bulkIncrementNumber: any = 1;

    enumerationItems: any[];
    unitItems: any[];
    systemGeneratedTestArticle: any;
    systemGeneratedJobTestArticle: any;

    treatmentToProtocolMap: { [jobGroupTreatmentKey: number]: number } = {};

    // Dragula 
    dragulaBagName: string;
    // All subscriptions
    subs = new Subscription();
    math = Math;

    constructor(
        private jobService: JobService,
        private dragulaService: DragulaService,
        private loggingService: LoggingService,
        private jobPharmaDetailService: JobPharmaDetailService,
        private enumerationService: EnumerationService,
        private vocabularyService: VocabularyService
    ) {
        //
    }

    // lifecycle
    ngOnInit() {
        this.initSystemGeneratedTestArticle();
        this.dragOnInit();        
        this.initBulkValues();
        this.setEnumerationItems();
        this.setUnitItems();
        this.initTreatmentProtocolMap();
    }

    ngOnDestroy() {
        this.dragOnDestroy();
        // Clear all the subscriptions
        this.subs.unsubscribe();
    }

    initTreatmentProtocolMap() {
        for (const jobGroup of this.job.JobGroup) {
            this.updateTreatmentToProtocolMap(jobGroup.JobGroupTreatment);
        }
    }

    initSystemGeneratedTestArticle(): Promise<any> {
        return this.vocabularyService.getSystemGeneratedCV('cv_TestArticles').then((item) => {
            this.systemGeneratedTestArticle = item;
            this.systemGeneratedJobTestArticle = this.job.JobTestArticle.find((jobTestArticle: any) => {
                return jobTestArticle.C_TestArticle_key === this.systemGeneratedTestArticle.C_TestArticle_key;
            });
            if (!this.systemGeneratedJobTestArticle) {
                const jobTestArticle = {
                    C_Job_key: this.job.C_Job_key,
                    cv_TestArticle: this.systemGeneratedTestArticle
                };
                this.systemGeneratedJobTestArticle = this.jobService.createJobTestArticle(jobTestArticle);
            }
        });
    }

    initBulkValues() {
        this.bulk = {
            group: null,
            number: null,
            treatmentKey: null,
            formulationDose: null,
            activeDose: null,
            activeUnit: null,
            concentration: null,
            route: null,
            dosingVolume: null,
            dosingUnit: null,
            schedule: null,
            protocol: null,
            force: false
        };
    }

    /**
     * Setup for row dragging
     */
    private dragOnInit() {
        // Need a unique name for our table in the Dragule config
        this.dragulaBagName = 'job-groups-bag';
        // Dragula options
        const opts: DragulaOptions<any> = {};

        // Only allow dragging by the drag handle
        opts.moves = (el: Element, source: Element, handle: Element, sibling: Element) => {
            return handle.classList.contains('draggable');
        };
        // Configure the service for this table
        this.dragulaService.createGroup(this.dragulaBagName, opts);
        this.subs.add(this.dragulaService.dropModel().subscribe(({ targetModel }) => {
            this.job.JobGroup.sort((a: any, b: any) => {
                const aIndex = targetModel.findIndex((task: any) => task.C_JobGroup_key === a.C_JobGroup_key);
                const bIndex = targetModel.findIndex((task: any) => task.C_JobGroup_key === b.C_JobGroup_key);
                return aIndex - bIndex;
            });

            for (let i = 0; i < this.job.JobGroup.length; i++) {
                this.job.JobGroup[i].SortOrder = i + 1;
            }

            // Update the sort order of placeholders to get same sequence
            for (const placeholder of this.job.Placeholder) {
                placeholder.SortOrder = placeholder.JobGroup.SortOrder;
            }
        }));

        // Register a callback to update the drag helper element
        this.subs.add(this.dragulaService.cloned().subscribe(({ clone }) => {
            // Update the mirror (aka drag helper);
            this.dragUpdateHelper(clone as HTMLElement);
        }));
    }

    /**
     * Remove Dragula configuration and listeners
     */
    private dragOnDestroy() {
        if (this.dragulaService.find(this.dragulaBagName)) {
            this.dragulaService.destroy(this.dragulaBagName);
        }
    }

    /**
     * Update the Dragula mirror element (aka drag helper) that is displayed
     * under the cursor while dragging rows.
     * 
     * By default, the dragged element is cloned. Instead, we want to display
     * some information about the selected row.
     * 
     * @param mirror DOM element displayed under the cursor
     */
    private dragUpdateHelper(mirror: HTMLElement) {
        // Figure out which row was dragged
        const draggedKey: number = parseInt(mirror.dataset.key, 10);

        //  Get the names of all the tasks to be dragged
        const names = this.job.JobGroup.filter((task: any) => {
            return task.C_JobGroup_key === draggedKey;
        }).map((jobGroup: any) => {
            return jobGroup.Group;
        });

        let text = '';
        text = names.join('<br>');

        // Swap out the contents of the mirror element
        mirror.innerHTML = `<div class="dragula-helper">${text}</div>`;
    }

    treatmentUsedInCohortInputs(jobGroupTreatment: any): boolean {
        return jobGroupTreatment.ProtocolInstance
            && jobGroupTreatment.JobGroup.Placeholder[0].JobCohort
            && !!jobGroupTreatment.ProtocolInstance.TaskInstance.find((x: any) => !!x.TaskCohort.find((c: any) => c.C_Cohort_key === jobGroupTreatment.JobGroup.Placeholder[0].JobCohort.C_Cohort_key));
    }

    getProtocolOptions(treatment: any): any[] {
        // Get ProtocolIntanceKeys already used by another treatment row in this treatment's Group
        const usedProtocolInstanceKeys = treatment.JobGroup.JobGroupTreatment
            .filter((x: any) => x.C_JobGroupTreatment_key !== treatment.C_JobGroupTreatment_key)
            .map((y: any) => y.C_ProtocolInstance_key);

        // Filter for TaskJobs linked to ProtocolInstances not yet used by another treatment row for this Group
        return this.job.TaskJob.filter((tj: any) => {
            return tj.TaskInstance.C_GroupTaskInstance_key === null
                && !usedProtocolInstanceKeys.find((key: any) => key === tj.TaskInstance.C_ProtocolInstance_key);
            });
    }

    get sumNumber(): number {
        let sum = 0;
        for (const jobGroup of this.job.JobGroup) {
            sum += jobGroup.Number;
        }
        return sum;
    }

    addJobGroup(job: any) {
        if (this.readonly) {
            return;
        }
        const newJobGroup = this.jobService.createJobGroup({
            C_Job_key: job.C_Job_key,
            SortOrder: job.JobGroup.length + 1,
            DateCreated: new Date(),
        });
        this.jobService.createPlaceholder({
            C_Job_key: job.C_Job_key,
            C_JobGroup_key: newJobGroup.C_JobGroup_key,
            PlaceholderName: job.JobID + "_G",
            SortOrder: newJobGroup.SortOrder,
            DateCreated: new Date(),
        });
        this.jobService.createJobGroupTreatment({
            C_JobGroup_key: newJobGroup.C_JobGroup_key,
            DateCreated: new Date(),
        });
    }

    removeGroup(jobGroup: any) {
        if (this.readonly) {
            return;
        }
        const placeholders = this.job.Placeholder.filter((placeholder: any) => placeholder.C_JobGroup_key === jobGroup.C_JobGroup_key);
        if (placeholders && placeholders.length > 0) {
            // Check if its already associated with a cohort
            if (placeholders[0].C_JobCohort_key) {
                const message = `Cannot delete Dose\nRemove related Cohort/Placeholder configuration first.`;
                const showToastr = true;
                this.loggingService.logWarning(message, null, "JOB_GROUP_TABLE", showToastr);
                return;
            }
            this.jobService.deletePlaceholder(placeholders[0]);
        }
        this.jobService.deleteJobGroup(jobGroup);
        this.updateUsedTreatment();
        this.updateUsedProtocol();
        this.validateGroups();
    }

    bulkGroupChanged() {
        let start = this.bulkStartNumber;
        const increment = this.bulkIncrementNumber;
        if (!start || start < 1 || !increment || increment < 1) {
            return;
        }
        for (const jobGroup of this.job.JobGroup) {
            jobGroup.Group = start;
            start += increment;
            this.updatePlaceholderName(jobGroup);
        }       
    }

    bulkNumberChanged() {
        for (const jobGroup of this.job.JobGroup) {
            // Only bulk update a number if the placeholder isnt linked to a cohort because the field is disabled
            if (jobGroup.Placeholder && jobGroup.Placeholder.length > 0 && jobGroup.Placeholder[0].C_JobCohort_key === null) {
                if (this.bulk.force || jobGroup.Number === null) {
                    jobGroup.Number = this.bulk.number;
                    this.updateJobGroupNumber(jobGroup);
                }
            }
        }
        this.initBulkValues();
    }

    updateUsedTreatment(treatment: any = null, jobGroup: any = null) {
        if (this.job.JobTestArticle !== null) {
            this.job.JobTestArticle.forEach((jobTestArticle: any) => {
                jobTestArticle.UsedInTreatment = false;
                if (this.job.JobGroup) {
                    this.job.JobGroup.forEach((entity: any) => {
                        entity.JobGroupTreatment.forEach((jobGroupTreatment: any) => {
                            if (jobTestArticle.C_JobTestArticle_key === jobGroupTreatment.C_JobTestArticle_key) {
                                jobTestArticle.UsedInTreatment = true;
                            }
                        });
                    });
                }
            });
            // if its a DTX job, check for purity and update formulation dose
            if (this.isDTX && treatment && jobGroup) {
                this.updateFormulationDose(treatment);
            }
        }
    }

    updateTreatment(treatment: any = null, jobTestArticleKey: number = null) {
        treatment.Treatment = '';
        if (jobTestArticleKey) {
            const jobTestArticle = this.job.JobTestArticle.find((testArticle: any) => testArticle.C_JobTestArticle_key === jobTestArticleKey);
            if (jobTestArticle) {
                const name = jobTestArticle.cv_TestArticle.TestArticle;
                let batch = '';
                if (jobTestArticle.Batch) {
                    batch = `-${jobTestArticle.Batch}`;
                }
                let id = '';
                if (jobTestArticle.Identifier && (this.isCRL || this.isDTX)) {
                    id = `${jobTestArticle.Identifier}: `;
                }
                treatment.Treatment = `${id}${name}${batch}`;
            }
        }
    }

    bulkTreatmentChanged() {       
        const updatedJobGroupTreatments: any[] = [];
        for (const jobGroup of this.job.JobGroup) {
            for (const treatment of jobGroup.JobGroupTreatment) {
                if (this.bulk.force || !treatment.Treatment) {
                    treatment.C_JobTestArticle_key = this.bulk.treatmentKey;
                    updatedJobGroupTreatments.push(treatment);
                    this.updateTreatment(treatment, Number(this.bulk.treatmentKey));
                    this.updateUsedTreatment(treatment, jobGroup);
                }
            }
        }
        this.initBulkValues();
        this.updateTaskInputs(updatedJobGroupTreatments, 'TREATMENT');
    }    

    bulkFormulationDoseChanged() {
        const updatedJobGroupTreatments: any[] = [];
        for (const jobGroup of this.job.JobGroup) {
            for (const treatment of jobGroup.JobGroupTreatment) {
                if (this.bulk.force || !treatment.FormulationDose) {
                    treatment.FormulationDose = this.bulk.formulationDose;
                    updatedJobGroupTreatments.push(treatment);
                }
            }
        }
        this.initBulkValues();
        this.updateTaskInputs(updatedJobGroupTreatments, 'FORMULATION DOSE');
    }

    bulkActiveDoseChanged() {
        const updatedJobGroupTreatments: any[] = [];
        for (const jobGroup of this.job.JobGroup) {
            for (const treatment of jobGroup.JobGroupTreatment) {
                if (this.bulk.force || !treatment.ActiveDose) {
                    treatment.ActiveDose = this.bulk.activeDose;
                    updatedJobGroupTreatments.push(treatment);
                    this.updateFormulationDose(treatment);
                }
            }
        }
        this.initBulkValues();
        this.updateTaskInputs(updatedJobGroupTreatments, 'ACTIVE DOSE');
    }

    bulkActiveUnitChanged() {
        for (const jobGroup of this.job.JobGroup) {
            for (const treatment of jobGroup.JobGroupTreatment) {
                if (this.bulk.force || !treatment.ActiveUnit) {
                    treatment.ActiveUnit = this.bulk.activeUnit;
                }
            }
        }
        this.initBulkValues();
    }

    bulkDosingUnitChanged() {
        for (const jobGroup of this.job.JobGroup) {
            for (const treatment of jobGroup.JobGroupTreatment) {
                if (this.bulk.force || !treatment.DosingUnit) {
                    treatment.DosingUnit = this.bulk.dosingUnit;
                }
            }
        }
        this.initBulkValues();
    }

    bulkConcentrationChanged() {
        const updatedJobGroupTreatments: any[] = [];
        for (const jobGroup of this.job.JobGroup) {
            for (const treatment of jobGroup.JobGroupTreatment) {
                if (this.bulk.force || !treatment.Concentration) {
                    treatment.Concentration = this.bulk.concentration;
                    updatedJobGroupTreatments.push(treatment);
                }
            }
        }
        this.initBulkValues();
        this.updateTaskInputs(updatedJobGroupTreatments, 'CONCENTRATION');
    }

    bulkRouteChanged() {
        const updatedJobGroupTreatments: any[] = [];
        for (const jobGroup of this.job.JobGroup) {
            for (const treatment of jobGroup.JobGroupTreatment) {
                if (this.bulk.force || !treatment.Route) {
                    treatment.Route = this.bulk.route;
                    updatedJobGroupTreatments.push(treatment);
                }
            }
        }
        this.initBulkValues();
        this.updateTaskInputs(updatedJobGroupTreatments, 'ROUTE');
    }

    bulkDosingVolumeChanged() {
        const updatedJobGroupTreatments: any[] = [];
        for (const jobGroup of this.job.JobGroup) {
            for (const treatment of jobGroup.JobGroupTreatment) {
                if (this.bulk.force || !treatment.DosingVolume) {
                    treatment.DosingVolume = this.bulk.dosingVolume;
                    updatedJobGroupTreatments.push(treatment);
                }
            }
        }
        this.initBulkValues();
        this.updateTaskInputs(updatedJobGroupTreatments, 'DOSING VOLUME');
    }

    bulkScheduleChanged() {
        for (const jobGroup of this.job.JobGroup) {
            for (const treatment of jobGroup.JobGroupTreatment) {
                if (this.bulk.force || !treatment.Schedule) {
                    treatment.Schedule = this.bulk.schedule;
                }
            }
        }
        this.initBulkValues();
    }

    updateUsedProtocol() {
        this.job.TaskJob
            .filter((taskJob: any) => taskJob.TaskInstance && taskJob.TaskInstance.ProtocolInstance)
            .forEach((taskJob: any) => taskJob.TaskInstance.ProtocolInstance.UsedInProtocol = false);

        this.job.JobGroup.forEach((jobGroup: any) => {
            jobGroup.JobGroupTreatment.forEach((jobGroupTreatment: any) => {

                this.job.TaskJob
                    .filter((taskJob: any) => taskJob.TaskInstance && taskJob.TaskInstance.ProtocolInstance)
                    .map((taskJob: any) => taskJob.TaskInstance.ProtocolInstance)
                    .forEach((protocolInstance: any) => {
                        if (jobGroupTreatment.C_ProtocolInstance_key === protocolInstance.C_ProtocolInstance_key) {
                            protocolInstance.UsedInProtocol = true;
                        }
                    });
            });
        });
    }

    bulkProtocolChanged() {
        const updatedJobGroupTreatments: any[] = [];
        for (const jobGroup of this.job.JobGroup) {
            for (const treatment of jobGroup.JobGroupTreatment) {
                // Get ProtocolIntanceKeys already used by another treatment row in this jobGroup
                const usedProtocolInstanceKeys = jobGroup.JobGroupTreatment
                    .filter((x: any) => x.C_JobGroupTreatment_key !== treatment.C_JobGroupTreatment_key && x.C_ProtocolInstance_key !== null)
                    .map((y: any) => y.C_ProtocolInstance_key);

                // Update protocol if treatment isn't already being used in inputs & isn't already assigned to another treatment in this group
                if ((this.bulk.force || treatment.C_ProtocolInstance_key === null)
                    && !this.treatmentUsedInCohortInputs(treatment)
                    && !usedProtocolInstanceKeys.find((key: any) => key === +this.bulk.protocol)
                ) {
                    treatment.C_ProtocolInstance_key = this.bulk.protocol;
                    updatedJobGroupTreatments.push(treatment);
                }
            }
        }
        this.initBulkValues();
        this.updateUsedProtocol();
        this.updateTaskInputs(updatedJobGroupTreatments, 'PROTOCOL');
    }

    setUnitItems(): Promise<any> {
        return this.vocabularyService.getCV('cv_Units')
            .then((values) => {
                this.unitItems = values;
            });
    }

    setEnumerationItems(): Promise<any> {
        return this.enumerationService.getStandardEnumerationItems('Route of Administration')
            .then((enumClass) => {
                this.enumerationItems = enumClass.EnumerationItem;
            });
    }

    updatePlaceholderName(jobGroup: JobGroup) {
        this.jobPharmaDetailService.updatePlaceholderName(this.job, jobGroup);
        this.validateGroups();
    }

    updateTaskInputs(jobGroupTreatments: any[], columnName: string) {
        const isProtocolColumn = columnName.toUpperCase() === 'PROTOCOL';

        if (this.job.TaskJob.length > 0) {
            this.loggingService.logWarning("Updating dosing task inputs for the edited group(s)", null, "DOSING_TABLE", true);
            this.busy.emit({ state: true, message: 'Loading' });

            const treatmentToProtocol = isProtocolColumn ? this.treatmentToProtocolMap : {};
            this.jobPharmaDetailService.updateTaskInputsFromJobGroup(jobGroupTreatments, columnName, treatmentToProtocol).then(() => {
                if (isProtocolColumn) {
                    this.updateTreatmentToProtocolMap(jobGroupTreatments);
                }
                this.busy.emit({ state: false, message: 'Loading' });
            }).catch(err => {
                if (isProtocolColumn) {
                    this.updateTreatmentToProtocolMap(jobGroupTreatments);
                }
                this.busy.emit({ state: false, message: 'Loading' });
                console.error(err);
            });
        } else if (isProtocolColumn) {
            this.updateTreatmentToProtocolMap(jobGroupTreatments);
        }
    }

    updateTreatmentToProtocolMap(jobGroupTreatments: any[]) {
        if (jobGroupTreatments && jobGroupTreatments.length) {
            for (const treatment of jobGroupTreatments) {
                this.treatmentToProtocolMap[treatment.C_JobGroupTreatment_key] = treatment.C_ProtocolInstance_key;
            }
        }
    }

    addTreatment(jobGroup: JobGroup) {
        this.jobService.createJobGroupTreatment({
            C_JobGroup_key: jobGroup.C_JobGroup_key,
        });
    }

    removeTreatment(treatment: any) {
        this.busy.emit({ state: true, message: 'Loading' });
        this.jobPharmaDetailService.checkIfJobGroupTreatmentUsedInInputs(treatment.C_JobGroupTreatment_key)
            .then((response: any) => {
                if (response.data) {
                    this.loggingService.logError("Cannot delete row associated with task inputs.", null, this.COMPONENT_LOG_TAG, true);
                } else {
                    this.jobService.deleteTreatment(treatment);
                    this.updateUsedTreatment();
                    this.updateUsedProtocol();
                }
                this.busy.emit({ state: false, message: 'Loading' });
            }).catch((error: any) => {
                this.busy.emit({ state: false, message: 'Loading' });
                this.loggingService.logError(error.message, null, this.COMPONENT_LOG_TAG, true);
            });
    }

    // When active dose is updated in the treatment, update the formulation dose with 
    // the formula (Acitve Dose / Purity) * 100
    updateFormulationDose(treatment: any) {
        // if isDTX 
        if (this.isDTX) {
            // if treatement is empty we need to reset the formulation dose
            if (treatment.Treatment === null || treatment.Treatment === undefined || treatment.Treatment === "") {
                treatment.FormulationDose = null;
            } else if (this.job.JobTestArticle !== null) {
                // Find the test article for the said treatment
                this.job.JobTestArticle.forEach(jobTestArticle => {
                    if (jobTestArticle.C_JobTestArticle_key === treatment.C_JobTestArticle_key) {
                        // We found the test article
                        // Now, see if we have activedose and purity
                        if (treatment.ActiveDose && jobTestArticle.Purity) {
                            // We have both, let's see if they both are numbers
                            if (!isNaN(treatment.ActiveDose) && !isNaN(jobTestArticle.Purity)) {
                                // parse active dose and make it absolute
                                const activeDose = Math.abs(parseFloat(treatment.ActiveDose));
                                // parse purity and make it absolute
                                const purity = Math.abs(parseFloat(jobTestArticle.Purity?.toString()));
                                // calculate the formulation dose
                                let formulationDose = (activeDose / purity) * 100;
                                // make it have max 7 decimal places
                                formulationDose = Math.round(formulationDose * 10000000) / 10000000;
                                // update formulation dose
                                treatment.FormulationDose = formulationDose;
                                this.updateTaskInputs([treatment], 'FORMULATION DOSE');
                            } else {
                                treatment.FormulationDose = null;
                            }
                        } else {
                            treatment.FormulationDose = null;
                        }
                    }
                });
            } else {
                treatment.FormulationDose = null;
            }
        }
    }

    trackRow = (index: number, item: JobGroup): string => {
        return `${item.C_JobGroup_key}-${index}`;
    }

    updateJobGroupNumber(jobGroup: JobGroup) {
        if (jobGroup.Number > this.MAX_DOSING_TABLE_N_AMOUNT) {
            return;
        }
        // Check if AnimalPlaceholders exist for the job group and if more are supposed to be created or needs removing
        const placeholders = jobGroup.AnimalPlaceholder;
        if (placeholders && placeholders.length > 0) {
            if (jobGroup.Number > placeholders.length) {
                // Create more placeholders
                this.jobService.createAnimalPlaceholders(jobGroup, jobGroup.Number, placeholders.length);
            } else {
                // Remove some placeholders
                // count how many placeholders need to be removed
                const removePlaceholders = placeholders.length - jobGroup.Number;
                this.jobService.deleteAnimalPlaceholders(jobGroup, removePlaceholders);
            }
        } else {
            // Create new AnimalPlaceholder list for the jobGroup
            // count how many new placeholders need creation
            const newPlaceholders = jobGroup.Number;
            this.jobService.createAnimalPlaceholders(jobGroup, newPlaceholders);
        }
    }

    private validateGroups() {
        for (const groupControl of this.groupControls) {
            groupControl.control.updateValueAndValidity();
        }
    }
}

/**
 * return timePoint + TimeUnit
 * @param timePoint 
 * @param cvTimeUnit 
 */
export function formatTimePoint(timePoint: number, cvTimeUnit?: any): string {
    if (!timePoint) {
        return '';
    }

    let output: string = '' + timePoint;
    if (cvTimeUnit) {
        output += ' ' + cvTimeUnit.TimeUnit;
    }
    return output;
}

import { difference, isEmpty } from '@lodash';
import { WebApiService } from '../../../services/web-api.service';
import { MaterialService } from '../../../services/material.service';
import { CreateSampleGroupData } from '../modals/create-sample-groups-models';
import {
    Injectable,
} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';

import {
    maxSequence,
    notEmpty,
    uniqueArray,
    uniqueArrayFromPropertyPath,
} from '../../../common/util';

import { ColumnSelect } from '@common/facet';
import { ConfirmOptions } from '../../../common/confirm/confirm-options';
import { ConfirmService } from '../../../common/confirm/confirm.service';
import { DataManagerService } from '../../../services/data-manager.service';
import { FacetLoadingStateService } from '../../../common/facet/facet-loading-state.service';
import { JobService } from '../../job.service';
import { LoggingService } from '../../../services/logging.service';
import { TaskService } from '../../../tasks/task.service';
import { VocabularyService } from '../../../vocabularies/vocabulary.service';
import { WorkflowService } from '../../../workflow/services/workflow.service';
import { WorkspaceService } from '../../../workspaces/workspace.service';
import { SampleService } from '../../../samples';
import { FeatureFlagService } from '../../../services/feature-flags.service';
import {
    Entity,
    ExtendedJob,
    Animal,
    Cohort,
    ProtocolInstance,
    TaskInstance,
    SampleGroup,
    JobGroup,
    Sample,
    Material,
    MaterialSourceMaterial,
    Job,
    JobCohort,
    JobMaterial,
    Protocol,
    TaskInput,
    TaskJob,
    TaskCohort,
    CohortMaterial,
    SampleGroupSourceMaterial,
    TaskPlaceholder,
    TaskMaterial
} from '@common/types';
import { pluralize } from '@common/util/pluralize';
import { DialogService } from '@common/dialog/deprecated/dialog.service';
import { CohortService } from '../../../cohort/services/cohort.service';
import { TranslationService } from '@services/translation.service';
import { AuthService } from '@services/auth.service';
import { CurrentWorkgroupService } from '@services/current-workgroup.service';
import { JobLogicService } from '../../job-logic.service';
import { EntityQuery, Predicate, QueryResult } from 'breeze-client';
import { ProtocolService } from '../../../protocol/protocol.service';
import { ReasonForChangeService } from '@common/reason-for-change/reason-for-change.service';
import { StatusCount, StatusCountMap } from "../models/status-count";
import { ExtendedTaskInstance } from "../models/extended-task-instance";
import { lowerCase } from "lodash";
import { DataContextService } from "@services/data-context.service";
import { isAnyTaskEndState } from 'src/app/tasks/util/is-any-task-end-state';
import { LocationService } from '../../../locations/location.service';
import { PartialMaterial } from '../models/partial-material';
import { SampleGroupExtended } from './job-pharma-core.service';
import { hasEndStateTasks } from '../../utils/has-end-state-tasks';
import { hasAssociatedSamples } from '../../utils/has-associated-samples';

interface BooleanMap {
    [index: string]: boolean;
}

export interface ColumnSelectConfig {
    [index: string]: {
        visible: boolean;
    };
}

interface TabMap<T> {
    // Tab Set
    [index: string]: {
        // Tab
        [index: string]: T
    };
}

interface DraggedEntities {
    id: number;
    type: string;
    entities?: any[];
    callback?: () => Promise<any[]>
}

export interface TabColumnSelectEvent {
    tabset: string;
    tab: string;
    columnSelect: ColumnSelect;
}

export interface TabRefreshEvent {
    tabset: string;
    tab: string;
}

export interface TryRemoveResult {
    allRemoved: boolean;
    removed: any[];
    withData: any[];
}

export interface CohortExtended {
    isSelected: boolean;
}

export interface AnimalExtended {
    isSelected: boolean;
}

export interface SampleBatch {
    sampleGroup: SampleGroup;
    newSamples: Sample[];
}

export interface SampleGroupChangesMap {
    [key: number]: SampleGroupChange;
}

export interface SampleGroupChange {
    sampleGroup?: SampleGroup;
    C_ContainerType_key?: number;
    C_PreservationMethod_key?: number;
    C_SampleAnalysisMethod_key?: number;
    C_SampleProcessingMethod_key?: number;
    C_SampleStatus_key?: number;
    C_SampleSubtype_key?: number;
    C_SampleType_key?: number;
    C_TimeUnit_key?: number;
    DateExpiration?: Date;
    DateHarvest?: Date;
    NumSamples?: number;
    SendTo?: string;
    TimePoint?: number;
    SpecialInstructions?: string;
}

enum ChangeAction {
    EDIT = 'Edit',
    CREATE = 'Create',
    DELETE = 'Delete'
}

type TaskFilter = (task: any) => boolean;

enum ItemsToAdd {
    NothingToAdd = 'NOTHING_TO_ADD',
    HaveDuplicates = 'HAVE_DUPLICATES',
    OnlyNewItems = 'ONLY_NEW_ITEMS',
}

export interface TaskValue {
    C_AssignedTo_key?: number;
    C_WorkflowTask_key: number;
    C_TaskStatus_key: number;
    DateDue?: Date;
    SampleGroups?: SampleGroup[];
    TaskInputs?: TaskInput[];
    IsGroup?: boolean;
    NoMaterials: boolean;
}

interface AddProtocolToStudyRequest {
    JobKey: any;
    ProtocolKey: number;
    IsCRO: boolean;
    ReasonForChange?: string;
}

@Injectable()
export class JobPharmaDetailService {
    readonly COMPONENT_LOG_TAG = 'job-pharma-detail-service';
    readonly AMOUNT_ENTITIES_LIMIT = 150;

    private facet: any;

    private columnSelectConfig: TabMap<ColumnSelectConfig>;
    columnSelectMap: TabMap<ColumnSelect> = {
        tasks: {},
        animals: {},
        samples: {},
    };

    private taskNumber = new BehaviorSubject(0);
    taskNumber$ = this.taskNumber.asObservable();

    private tabColumnSelectChangedSource = new Subject<TabColumnSelectEvent>();
    tabColumnSelectChanged$ = this.tabColumnSelectChangedSource.asObservable();

    private tabRefreshSource = new Subject<TabRefreshEvent>();
    tabRefresh$ = this.tabRefreshSource.asObservable();

    private jobCohortsChangedSource = new Subject<void>();
    jobCohortsChanged$ = this.jobCohortsChangedSource.asObservable();

    private jobMaterialsChangedSource = new Subject<void>();
    jobMaterialsChanged$ = this.jobMaterialsChangedSource.asObservable();

    private jobTasksChangedSource = new Subject<void>();
    jobTasksChanged$ = this.jobTasksChangedSource.asObservable();

    private dragged: DraggedEntities = null;

    busy = 0;
    private cvUnitDefault: any = null;

    constructor(
        private confirmService: ConfirmService,
        private dataManager: DataManagerService,
        private facetLoadingStateService: FacetLoadingStateService,
        private jobService: JobService,
        private taskService: TaskService,
        private loggingService: LoggingService,
        private materialService: MaterialService,
        private sampleService: SampleService,
        private vocabularyService: VocabularyService,
        private webApiService: WebApiService,
        private httpClient: HttpClient,
        private workflowService: WorkflowService,
        private workspaceService: WorkspaceService,
        private featureFlagService: FeatureFlagService,
        private dialogService: DialogService,
        private dataContextService: DataContextService,
        private cohortService: CohortService,
        private translationService: TranslationService,
        private authService: AuthService,
        private currentWorkgroupService: CurrentWorkgroupService,
        private jobLogicService: JobLogicService,
        private protocolService: ProtocolService,
        private reasonForChangeService: ReasonForChangeService,
        private locationService: LocationService,

    ) {
        this.warmUpCache();
    }

    async warmUpCache() {
        if (typeof this.vocabularyService.getCVDefault === "function") {
            // safe to use the function
            this.cvUnitDefault = await this.vocabularyService.getCVDefault('cv_Units');
        }
    }

    get taskNumberValue() {
        return this.taskNumber.getValue();
    }

    /**
     * Initialize the job's grouped and non-member tasks in schedule order.
     *
     * @param job The current job
     * @param taskExpands (optional) Array of additional relationships to
     *        expand for the returned tasks
     */
    public async initTasks(
        job: any,
        extraTaskExpands: string[] = [],
    ): Promise<any[]> {
        // Start with the core expands
        let taskExpands = [
            'ProtocolInstance.Protocol',
            'ProtocolTask',
            'TaskJob',
            'WorkflowTask.cv_TaskType',
            'TaskMaterial.Material.Sample'
        ];

        // Merge in the extra expands
        if (extraTaskExpands) {
            taskExpands = uniqueArray(taskExpands.concat(extraTaskExpands));
        }

        // Make sure the tasks are loaded
        await this.dataManager.ensureRelationships([job], []);

        const tasks = this.getJobPharmaTaskRows(job);

        // Fill out these tasks
        await this.dataManager.ensureRelationships(tasks, taskExpands);

        // Put the tasks in schedule order
        this.sortTasksBySchedule(tasks);

        this.taskNumber.next(tasks.length ?? 0);
        return tasks;
    }

    async initStatusCounts(tasks: ExtendedTaskInstance[], job: ExtendedJob, preferLocal?: boolean) {
        if (!job.TaskJob) {
            // Nothing to count
            return Promise.resolve();
        }

        const counts: StatusCountMap = {};

        const query = new EntityQuery('TaskJobs')
            .where(Predicate.create('C_Job_key', 'eq', job.C_Job_key)
                            .and('C_TaskJob_key', '!=', null)
                            .and('TaskInstance.IsGroup', '==', false));

        const result = await this.dataManager.executeQuery(query, preferLocal);
        const taskJobs = result.results.filter(item => !!item) as Entity<TaskJob>[];

        const jobEntityTaskJobs = job.TaskJob.filter(taskJob => !taskJob.TaskInstance.IsGroup);

        if (jobEntityTaskJobs.length < taskJobs.length) {
            for (const taskJob of taskJobs) {
                const taskJobOld = job.TaskJob.find((item) => {
                    return item.C_TaskJob_key === taskJob.C_TaskJob_key;
                });
                if (taskJobOld == null) {
                    job.TaskJob.push(taskJob);
                }
            }
        }

        const jobTasks = jobEntityTaskJobs.filter(taskJob => !!taskJob.TaskInstance).map(taskJob => taskJob.TaskInstance);

        for (const task of jobTasks) {
            // Which group does this task belong to.
            // Ungrouped tasks belong to themselves.
            const key = task.C_GroupTaskInstance_key || task.C_TaskInstance_key;

            let count = counts[key];
            if (!count) {
                // Initialize a new counter for this group
                counts[key] = count = new StatusCount();
            }

            // Increment the counters
            count.total += 1;
            if (task.cv_TaskStatus && task.cv_TaskStatus.IsEndState) {
                count.endState += 1;
            }
        }
        // Assign the group counts to the tasks that will appear in the table
        for (const task of tasks) {
            task.statusCount = counts[task.C_TaskInstance_key] || new StatusCount();
        }
        return tasks;
    }

    /**
     * Get the isCRO feature flag
     */
    getIsCroFlag(): boolean {
        const flag = this.featureFlagService.getFlag("IsCRO");
        return flag && (flag.Value.toLowerCase() === 'true') && (flag.IsActive === true);
    }

    /**
     * Get the isGLP feature flag
     */
    getIsGLPFlag(): boolean {
        const flag = this.featureFlagService.getFlag("IsGLP");
        return flag && (flag.Value.toLowerCase() === 'true') && (flag.IsActive === true);
    }

    /**
     * Get the selected placeholders
     * @param job
     */
    getJobPlaceholders(job: any): any[] {
        return uniqueArrayFromPropertyPath(job, 'Placeholder');
    }

    /**
     * Excludes tasks that are part of a group
     * @param job
     */
    getJobPharmaTaskRows(job: any): any[] {
        let taskInstances = uniqueArrayFromPropertyPath(job, 'TaskJob.TaskInstance');
        taskInstances = taskInstances.filter((task) => {
            return task && !task.C_GroupTaskInstance_key;
        });
        return taskInstances;
    }


    getJobPharmaProtocolRows(job: any): any[] {
        let protocolInstances = uniqueArrayFromPropertyPath(job, 'TaskJob.TaskInstance.ProtocolInstance') as ProtocolInstance[];
        const aliasSeen = new Set();
        protocolInstances = protocolInstances.filter((instance) => {
            for (const taskInstance of instance.TaskInstance) {
                if (taskInstance.WorkflowTask.NoMaterials) {
                    continue
                }
                if (!taskInstance.IsGroup) {
                    return false;
                }
            }
            if (aliasSeen.has(instance.ProtocolAlias)) {
                return false;
            }
            aliasSeen.add(instance.ProtocolAlias);
            return true;
        });
        return protocolInstances;
    }

    /**
     * Register the current facet (WorkspaceDetails) so the columns selections can be saved.
     */
    public registerFacet(facet: any) {
        this.facet = facet;
        this.columnSelectConfig = this.parseColumnSelectConfig();
    }

    /**
     * Register the columns for a tab's table.
     *
     * The initial ColumnSelect.model value will be used as the default column
     * selection and be merged with the saved column selection.
     *
     * @param tabset Key for the tab set (e.g 'tasks', 'animals', 'samples')
     * @param tab Key for the tab within the tabset (e.g. 'schedule')
     * @param columnSelect The ColumnSelect for the table
     * @param callback A function to call when the ColumnSelect model has changed.
     * @returns A Subscription for model change events
     */
    public registerColumnSelect(
        tabset: string,
        tab: string,
        columnSelect: ColumnSelect,
        callback: ($event: TabColumnSelectEvent) => void
    ): Subscription {
        if (!this.columnSelectMap[tabset]) {
            // Initialize the top-level map
            this.columnSelectMap[tabset] = {};
        }

        // Map the column select
        this.columnSelectMap[tabset][tab] = columnSelect;

        // Merge the default model with the saved model
        if (this.columnSelectConfig[tabset] && this.columnSelectConfig[tabset][tab]) {
            // Get this tab's saved config
            const tabConfig = this.columnSelectConfig[tabset][tab];

            // Map the default selected columns
            const defaultSelected: BooleanMap = {};
            for (const key of columnSelect.model) {
                defaultSelected[key] = true;
            }

            // Determine the initially selected columns
            const model = columnSelect.labels.filter((item) => {
                const key = item.key;
                const columnConfig = tabConfig[key];
                if (!columnConfig) {
                    // No saved config for this column, so use the default
                    return defaultSelected[key];
                }

                // Columns are visible unless explictly hidden.
                return (columnConfig.visible !== false);
            }).map((item) => item.key);

            // Use the updated model
            columnSelect.model = model;
        }

        // Subscribe to the model change events
        return this.tabColumnSelectChanged$.subscribe(
            ($event: TabColumnSelectEvent) => {
                if (($event.tabset === tabset) && ($event.tab === tab)) {
                    callback($event);
                }
            }
        );
    }

    /**
     * Called when the column selections have changed.
     */
    columnSelectChanged(tabset: string, tab: string, model: string[]): Promise<any> {
        // Get the registered ColumnSelect
        const columnSelect = this.columnSelectMap[tabset][tab];

        // Update its model
        columnSelect.model = model;

        // Save the change to the DB
        return this.saveColumnSelectConfig().then(() => {
            // Let everybody know
            this.tabColumnSelectChangedSource.next({
                tabset,
                tab,
                columnSelect
            });
        });
    }

    /**
     * Parse the TaskGridConfiguration JSON string, or provide a blank config object
     *
     * Note: Currently this is "reusing" (squatting in) the
     * TaskGridConfiguration field in WorkspaceDetails.
     */
    private parseColumnSelectConfig(): TabMap<ColumnSelectConfig> {
        try {
            if (this.facet && this.facet.TaskGridConfiguration) {
                return JSON.parse(this.facet.TaskGridConfiguration);
            }
        } catch (e) {
            console.error('Could not parse TaskGridConfiguration', e);
        }

        return {};
    }

    /**
     * Save the column selections for this facet.
     *
     * Note: Currently this is "reusing" (squatting in) the
     * TaskGridConfiguration field in WorkspaceDetails.
     */
    private saveColumnSelectConfig(): Promise<any> {
        if (!this.facet) {
            return Promise.resolve();
        }

        // Start from scratch
        const config: TabMap<ColumnSelectConfig> = {};

        for (const tabset of Object.keys(this.columnSelectMap)) {
            config[tabset] = {};

            for (const tab of Object.keys(this.columnSelectMap[tabset])) {
                // Get the registered ColumnSelect
                const columnSelect = this.columnSelectMap[tabset][tab];

                // Start with a blank config
                const tabConfig: ColumnSelectConfig = {};

                // Map the selected columns
                const selected: BooleanMap = {};
                for (const key of columnSelect.model) {
                    selected[key] = true;
                }

                // Getthe config for each column
                for (const item of columnSelect.labels) {
                    const key = item.key;
                    tabConfig[key] = {
                        visible: selected[key] || false
                    };
                }

                // Use this tab config
                config[tabset][tab] = tabConfig;
            }
        }

        // Use this config
        this.columnSelectConfig = config;

        // Rebuild the TaskGridConfiguration JSON
        this.facet.TaskGridConfiguration = JSON.stringify(config);

        // Save just the TaskGridConfiguration value in the facet
        return this.workspaceService.saveTaskGridConfiguration(this.facet);
    }

    tabRefresh(tabset: string, tab: string) {
        this.tabRefreshSource.next({ tabset, tab});
    }

    /**
     * Notify listeners that one of the Job arrays has changed.
     * @param entityType Changed array (JobCohort, JobMaterial, TaskJob)
     */
    notifyJobArrayChanged(entityType: string) {
        switch (entityType) {
            case 'JobCohort':
                this.jobCohortsChangedSource.next();
                break;
            case 'JobMaterial':
                this.jobMaterialsChangedSource.next();
                break;
            case 'TaskJob':
                this.jobTasksChangedSource.next();
                break;
            case 'TaskInstance':
                this.jobTasksChangedSource.next();
                break;
            default:
                break;
        }
    }

    /**
     * Map Animals to their existing ProtocolInstances that match the given
     * group ProtocolInstance.
     *
     * The ProtocolInstances are trail of tasks that a thingy (animal, sample,
     * set of group tasks) goes thourhg when following a protocol. Each thingy
     * needs its own ProtocolInstance for dealing dependant times.
     *
     * This function tries to find the ProtocolInstances that already exist for
     * the animals associated with the group tasks that are attached to a group
     * ProtocolInstance. That way, the animal should have a single
     * ProtocolInstance even if it was added to the separate tasks rather than
     * the protocol itself.
     *
     * @param job The current Job
     * @param groupProtocolInstanceKey The key of the ProtocolInstance for the
     * group task
     */
    findAnimalProtocolInstances(job: any, groupProtocolInstanceKey: any): any {
        const animalProtocolKeys = {};

        if (!job || !job.TaskJob || !groupProtocolInstanceKey) {
            // Nothing to do
            return animalProtocolKeys;
        }

        // Pass 1: Find all related Group Tasks
        const groupTasks = job.TaskJob.filter((jt: any) => {
            const task = jt.TaskInstance;
            if (!task) {
                return false;
            }

            return task.IsGroup &&
                (task.C_ProtocolInstance_key === groupProtocolInstanceKey);
        }).map((jt: any) => jt.TaskInstance);

        // Make a lookup table for the group tasks
        const groupTaskKeys = {};
        for (const groupTask of groupTasks) {
            groupTaskKeys[groupTask.C_TaskInstance_key] = true;
        }

        // Pass 2: Find all member tasks for these group tasks
        const memberTasks = job.TaskJob.filter((jt: any) => {
            const task = jt.TaskInstance;
            if (!task || !task.C_GroupTaskInstance_key) {
                return false;
            }

            return groupTaskKeys[task.C_GroupTaskInstance_key];
        }).map((jt: any) => jt.TaskInstance);

        // Pass 3: Map the materials to the existing ProtocolInstance keys.
        for (const task of memberTasks) {
            if (!task.C_ProtocolInstance_key || !task.TaskMaterial) {
                // Just in case.
                continue;
            }

            // Map all these animals to the task's ProtocolInstance
            for (const tm of task.TaskMaterial) {
                // Just in case there are already duplicate ProtocolInstances, use the first one.
                if (!animalProtocolKeys[tm.C_Material_key]) {
                    animalProtocolKeys[tm.C_Material_key] = task.C_ProtocolInstance_key;
                }
            }
        }

        return animalProtocolKeys;
    }


    /**
     * Find all the tasks that are part of the ProtocolInstance.
     */
    findProtocolInstanceTasks(job: any, protocolInstance: any): any[] {
        if (!job.TaskJob) {
            return [];
        }

        return job.TaskJob.filter((jt: any) => {
            const task = jt.TaskInstance;
            if (!task) {
                return false;
            }

            return task.C_ProtocolInstance_key === protocolInstance.C_ProtocolInstance_key;
        }).map((jt: any) => jt.TaskInstance);
    }

    /**
     * Find all the member tasks for this group task.
     */
    findMemberTasks(job: any, groupTask: any): any[] {
        if (!job.TaskJob || !groupTask.IsGroup) {
            // Not actually a group task
            return [];
        }

        // Look for tasks in the group
        return job.TaskJob.filter((jt: any) => {
            if (!jt.TaskInstance) {
                return false;
            }

            return jt.TaskInstance.C_GroupTaskInstance_key === groupTask.C_TaskInstance_key;
        }).map((jt: any) => jt.TaskInstance);
    }

    getDefaultTaskStatusKey(): Promise<any> {
        return this.vocabularyService.getCVDefault(
            'cv_TaskStatuses', true
        ).then((status: any) => {
            return (status) ? status.C_TaskStatus_key : null;
        });
    }

    /**
     * Make sure all the relationships are filled out before trying to deal with added entities.
     */
    private _ensureDropRelationships(job: any, type: string, entities: any[]): Promise<any> {
        return this.dataManager.ensureRelationships(job.TaskJob, [
            'TaskInstance.TaskMaterial',
            'TaskInstance.TaskInput',
            'TaskInstance.ProtocolTask.InputDefault',
        ]).then(() => {
            if (type === 'Animal') {
                return this.dataManager.ensureRelationships(entities, [
                    'Material.TaskMaterial'
                ]);
            }
            if (type === 'Cohort') {
                return this.dataManager.ensureRelationships(entities, [
                    'CohortMaterial.Material.TaskMaterial',
                    'CohortMaterial.Material.Animal'
                ]);
            }
        });
    }

    /**
     * Utility to prepare for adding Animals/Cohorts to Tasks/Protocols
     *
     * @param job the current job
     * @param type Entity type ('Animal' or 'Cohort')
     * @param entities entities to add
     * @param target Task or ProtocolInstance that the entites are being added to
     */
    private _setupAddAnimalsToTasks(
        job: any,
        type: string,
        entities: any[],
        target: any
    ): Promise<any> {
        return this._ensureDropRelationships(job, type, entities).then(() => {
            return this.findAnimalProtocolInstances(job, target.C_ProtocolInstance_key);
        }).then((animalProtocolInstances: any) => {
            return this.getDefaultTaskStatusKey().then((taskStatusKey) => {
                return {
                    animalProtocolInstances,
                    taskStatusKey,
                };
            });
        });
    }

    async showModalAnimalsToProtocol(
        protocolInstance: ProtocolInstance,
        animals: (PartialMaterial | Animal)[],
    ): Promise<boolean> {
        const statusAnimalsToAdd = this.getStatusAnimalsToAdd(protocolInstance, animals);
        const modalTitle = 'Creating Task Instances';

        if (statusAnimalsToAdd === ItemsToAdd.NothingToAdd) {
            const confirmText = `${pluralize(animals.length, 'Animal')} already been added, and duplicate records will not be created.`;
            await this.dialogService.confirmYes({
                yesButtonTitle: 'Close',
                title: modalTitle,
                bodyText: confirmText,
            });
            return false;
        }

        // Exclude NoMaterials tasks
        const taskAmount = protocolInstance.TaskInstance.filter(({ WorkflowTask }) => !WorkflowTask?.NoMaterials).length;
        const amountToApply = taskAmount * animals.length;
        if (amountToApply < this.AMOUNT_ENTITIES_LIMIT) {
            return true;
        }

        let bodyText = `Climb will create ${amountToApply} task ${pluralize(amountToApply, 'instance')}. This may take several minutes.`;
        if (statusAnimalsToAdd === ItemsToAdd.HaveDuplicates) {
            bodyText = `
                At least one of these task instances already exists and duplicate records won't be created.
                Climb will create and/or update ${amountToApply} task ${pluralize(amountToApply, 'instance')}. This may take several minutes.
            `;
        }
        bodyText += '\nDo you wish to save changes and continue?';

        return await this.dialogService.confirmYesNo({
            yesButtonTitle: 'Continue',
            noButtonTitle: 'Discard Changes',
            title: modalTitle,
            bodyText,
        });
    }

    async addProtocols(
        job: Job,
        protocols: Protocol[]
    ): Promise<any> {
        if (!this.isJobSaved(job)) {
            return;
        }

        if (!protocols.every(p => this.isProtocolSaved(p))) {
            return;
        }

        try {
            this.busyStart();

            const httpOptions = {
                headers: new HttpHeaders({
                    'x-single-request': '1', // disable multiple requests when network errors
                }),
            };

            const promises: Promise<any>[] = [];
            for (const protocol of protocols) {
                const requestBody: AddProtocolToStudyRequest = {
                    JobKey: job.C_Job_key,
                    ProtocolKey: protocol.C_Protocol_key,
                    IsCRO: this.getIsCroFlag()
                };

                if (this.getIsGLPFlag()) {
                    const protocolTasks = await this.protocolService.getProtocolTasks(protocol.C_Protocol_key);
                    const reasonForChange = await this.reasonForChangeService.getRFCForAddProtocolToStudyAction(protocolTasks, job.C_Job_key);
                    if (reasonForChange){
                        requestBody.ReasonForChange = reasonForChange;
                    }
                }

                promises.push(this.httpClient.post('/api/jobdata/addprotocol', requestBody, httpOptions).toPromise());
            }


            const results = await Promise.all(promises);
            if (results.some(r => r.InvalidTasks)) {
                this.showInvalidTaskWarning();
            }
            this.tabRefresh('job', 'main');
            this.tabRefresh('tasks', 'list');
            this.tabRefresh('tasks', 'outline');
            this.tabRefresh('samples', 'groups');
        } finally {
            this.busyStop();
        }
    }

    private showInvalidTaskWarning() {
        const message = `Not all tasks were added.\nOnly "${this.translationService.translate('Job')}" type tasks are allowed.`;
        this.loggingService.logWarning(message, null, this.COMPONENT_LOG_TAG, true);
    }

    async addTasks(job: Job, taskValues: TaskValue[]) {
        if (!taskValues || taskValues.length === 0) {
            return;
        }

        try {
            this.busyStart();
            const tasks: TaskJob[] = [];
            const defaultTaskStatusKey = await this.getDefaultTaskStatus();
            let taskSequence = this.maxGroupSequence(job);

            for (const initialValues of taskValues) {
                // Use the default TaskStatus
                initialValues.C_TaskStatus_key = defaultTaskStatusKey;
                const workflowTask = await this.taskService.getTaskByKey(initialValues.C_WorkflowTask_key);
                if (this.getIsCroFlag()) {
                    const foundWorkflowTask = workflowTask.OriginalJobWorkflowTask.find((x: any) => x.C_Job_key === job.C_Job_key);
                    if (foundWorkflowTask) {
                        initialValues.C_WorkflowTask_key = foundWorkflowTask.CurrentWorkflowTask.C_WorkflowTask_key;
                    }
                }
                if (!workflowTask.NoMaterials) {
                    // Default to group tasks
                    initialValues.IsGroup = true;
                } else {
                    initialValues.IsGroup = false;
                }
                const taskInstance = await this.taskService.createTaskInstance(initialValues, this.getIsCroFlag(), job);

                ++taskSequence;
                // Add the task to the Job
                tasks.push(this.jobService.createTaskJob(
                    job.C_Job_key,
                    taskInstance.C_TaskInstance_key,
                    taskSequence
                ));
            }
            this.tabRefresh("tasks", "list");
            this.tabRefresh('samples', 'groups');
            this.tabRefresh('samples', 'individuals');
            return tasks;
        } finally {
            this.busyStop();
        }
    }



    private maxGroupSequence(job: Job) {
        if (!job || !job.TaskJob) {
            // Nothing to look at yet
            return 0;
        }

        // Find the TaskJobs for Group TaskInstances and get the maximum Sequence value
        return job.TaskJob.filter((jobTask: any) => {
            return jobTask.TaskInstance && (jobTask.TaskInstance.C_GroupTaskInstance_key === null);
        }).reduce((max: number, jobTask: any) => Math.max(max, jobTask.Sequence), 0);
    }

    private async getDefaultTaskStatus() {
        await this.vocabularyService.ensureCVLoaded('cv_TaskStatuses');
        const status = await this.vocabularyService.getCVDefault('cv_TaskStatuses', true);
        return status?.C_TaskStatus_key;
    }

    /**
     * Add Animals to all the tasks of a ProtocolInstance.
     */
    async addAnimalsToProtocol(
        job: Job,
        protocolInstance: ProtocolInstance,
        materials: PartialMaterial[],
    ): Promise<boolean> {
        if (!this.isJobSaved(job) || !this.isProtocolInstanceSaved(protocolInstance)) {
            return false;
        }

        if (this.isOnlyNonMaterialTasks(protocolInstance.TaskInstance)) {
            return false;
        }

        try {
            this.busyStart();

            const httpOptions = {
                headers: new HttpHeaders({
                    'x-single-request': '1', // disable multiple requests when network errors
                }),
            };
            await this.httpClient.post('/api/jobtaskinstances/add-animals-to-protocol', {
                JobKey: job.C_Job_key,
                ProtocolInstanceKey: protocolInstance.C_ProtocolInstance_key,
                MaterialKeys: materials.map(({ C_Material_key }) => C_Material_key),
            }, httpOptions).toPromise();

            this.notifyJobArrayChanged('TaskJob');
            return true;
        } catch {
            return false;
        } finally {
            this.busyStop();
        }
    }

    private isJobSaved(job: Job) {
        const isJobSaved = job.C_Job_key > 0;
        if (!isJobSaved) {

            this.loggingService.logWarning(this.translationService.translate('Job') + " needs to be saved before proceeding.", '', this.COMPONENT_LOG_TAG, true);
        }
        return isJobSaved;
    }

    private isProtocolSaved(protocol: Protocol) {
        const isProtocolSaved = protocol.C_Protocol_key > 0;
        if (!isProtocolSaved) {
            this.loggingService.logWarning("Protocol needs to be saved before proceeding.", '', this.COMPONENT_LOG_TAG, true);
        }
        return isProtocolSaved;
    }

    private isProtocolInstanceSaved(protocolInstance: ProtocolInstance) {
        const isProtocolInstanceSaved = protocolInstance.C_ProtocolInstance_key > 0;
        if (!isProtocolInstanceSaved) {
            this.loggingService.logWarning("Protocol needs to be saved before proceeding.", '', this.COMPONENT_LOG_TAG, true);
        }
        return isProtocolInstanceSaved;
    }

    private isOnlyNonMaterialTasks(taskInstances: TaskInstance[]) {
        const isOnlyNonMaterialTasks = this.hasAllNoMaterialsTasks(taskInstances);
        if (isOnlyNonMaterialTasks) {
            this.loggingService.logWarning('Cannot attach entities to "No Material" tasks', '', this.COMPONENT_LOG_TAG, true);
        }

        if (this.hasNoMaterialsTasks(taskInstances)) {
            this.loggingService.logWarning('"No Material" tasks will be skipped for attaching entities', '', this.COMPONENT_LOG_TAG, true);
        }

        return isOnlyNonMaterialTasks;
    }

    private async handleAnimalsIfEnrolledInOtherJobs(job: Job, animals: Animal[]) {
        const animalsInOtherJobs = await this.jobLogicService.getAnimalsEnrolledOnlyInOtherJobs(animals, job);
        if (notEmpty(animalsInOtherJobs)) {
            try {
                await this.jobLogicService.showAddAnimalsToMultipleJobsConfirm(animalsInOtherJobs);
            } catch {
                return false;
            }
        }
        return true;
    }

    async addCohortsToProtocol(
        job: Job,
        protocolInstance: ProtocolInstance,
        cohorts: (Cohort & CohortExtended)[]
    ): Promise<boolean> {
        if (!this.isJobSaved(job) || !this.isProtocolInstanceSaved(protocolInstance)) {
            return false;
        }

        if (this.isOnlyNonMaterialTasks(protocolInstance.TaskInstance)) {
            return false;
        }

        try {
            this.busyStart();

            await this.addCohortsToJobIfMissing(job, cohorts, false);
            const materials = cohorts.flatMap(cohort => cohort.CohortMaterial.flatMap(cm => cm.Material));
            const isContinue = await this.addMaterialsToJobIfMissing(job, materials, false);
            if (!isContinue) {
                return false;
            }

            const httpOptions = {
                headers: new HttpHeaders({
                    'x-single-request': '1', // disable multiple requests when network errors
                }),
            };
            await this.httpClient.post('/api/ApiCohortsToProtocol/SaveCohortsToProtocol', {
                CreatedBy: this.authService.getCurrentUserName(),
                Workgroup: this.currentWorkgroupService.getWorkgroupName(),
                Job_key: job.C_Job_key,
                Protocol_key: protocolInstance.C_Protocol_key,
                ProtocolInstance_key: protocolInstance.C_ProtocolInstance_key,
                Cohorts_keys: cohorts.map(c => c.C_Cohort_key)
            }, httpOptions).toPromise();
            return true;
        } catch {
            return false;
        } finally {
            this.busyStop();
        }
    }

    private getStatusAnimalsToAdd(protocolInstance: ProtocolInstance, animals: (PartialMaterial | Animal)[]): ItemsToAdd {
        return this.getStatusEntitiesToAdd(
            protocolInstance,
            ({ TaskMaterial }) => TaskMaterial.map(({ C_Material_key }) => C_Material_key),
            animals.map(a => a.C_Material_key),
        );
    }

    private getStatusEntitiesToAdd(
        protocolInstance: ProtocolInstance,
        getExistsEntityKeysCallback: (task: TaskInstance) => number[],
        entityKeysToAdd: number[],
    ): ItemsToAdd {
        const keysToAdd = new Set<number>();
        let hasDuplicates = false;

        for (const task of protocolInstance.TaskInstance) {
            const alreadyExistsKeys = getExistsEntityKeysCallback(task);

            // difference([2, 1], [2, 3]); // => [1]
            const keysNeedToAdd = difference(entityKeysToAdd, alreadyExistsKeys);
            keysNeedToAdd.forEach((key) => keysToAdd.add(key));
            hasDuplicates = hasDuplicates || keysNeedToAdd.length < entityKeysToAdd.length;
        }

        if (keysToAdd.size === 0) {
            return ItemsToAdd.NothingToAdd;
        }

        if (keysToAdd.size < entityKeysToAdd.length || hasDuplicates) {
            return ItemsToAdd.HaveDuplicates;
        }

        return ItemsToAdd.OnlyNewItems;
    }

    async showModalCohortsToProtocol(
        protocolInstance: ProtocolInstance,
        cohorts: Cohort[],
    ): Promise<boolean> {
        const statusCohortsToAdd = this.getStatusCohortsToAdd(protocolInstance, cohorts);
        const modalTitle = 'Creating Task Instances';

        if (statusCohortsToAdd === ItemsToAdd.NothingToAdd) {
            const confirmText = `${pluralize(cohorts.length, 'Cohort')} already been added, and duplicate records will not be created.`;
            await this.dialogService.confirmYes({
                yesButtonTitle: 'Close',
                title: modalTitle,
                bodyText: confirmText,
            });
            return false;
        }

        const amounts = this.getCohortsToProtocolAmounts(protocolInstance, cohorts);
        const animalsCount = amounts.animals * amounts.taskInstance;
        if (animalsCount < this.AMOUNT_ENTITIES_LIMIT) {
            return true;
        }

        let bodyText = `Climb will create ${animalsCount} task ${pluralize(animalsCount, 'instance')}. This may take several minutes.`;
        if (statusCohortsToAdd === ItemsToAdd.HaveDuplicates) {
            bodyText = `
                At least one of these task instances already exists and duplicate records won't be created.
                Climb will create and/or update ${animalsCount} task ${pluralize(animalsCount, 'instance')}. This may take several minutes.
            `;
        }
        bodyText += '\nDo you wish to save changes and continue?';

        return await this.dialogService.confirmYesNo({
            yesButtonTitle: 'Continue',
            noButtonTitle: 'Discard Changes',
            title: modalTitle,
            bodyText,
        });
    }

    /**
     * Get status cohorts that has to be added to a protocol
     */
    private getStatusCohortsToAdd(protocolInstance: ProtocolInstance, cohorts: Cohort[]): ItemsToAdd {
        return this.getStatusEntitiesToAdd(
            protocolInstance,
            ({ TaskCohort: tc }) => tc.map(({ C_Cohort_key }) => C_Cohort_key),
            cohorts.map(({ C_Cohort_key }) => C_Cohort_key),
        );
    }

    getCohortsToProtocolAmounts = (protocolInstance: ProtocolInstance, entities: Cohort[]) => {
        const amountAnimals = entities.reduce((sum, { CohortMaterial: cm }) => {
            return sum + cm.length;
        }, 0);
        const amountTaskInputs = protocolInstance.TaskInstance.reduce((sum, { TaskInput: ti }) => {
            return sum + ti.length;
        }, 0);
        // Exclude NoMaterials tasks
        const amountTaskInstance = protocolInstance.TaskInstance.filter(({ WorkflowTask }) => !WorkflowTask?.NoMaterials).length;
        const amountCohorts = entities.length;
        const total = amountAnimals * amountTaskInstance * 4
            + amountAnimals * amountTaskInputs
            + amountCohorts * amountTaskInputs
            + amountTaskInstance * amountCohorts;
        return {
            total,
            animals: amountAnimals,
            cohorts: amountCohorts,
            taskInputs: amountTaskInputs,
            taskInstance: amountTaskInstance,
        };
    }

    /**
     * Add Cohorts to all the tasks of a Protocolnstance.
     */
    addCohortsToProtocolInstance(
        job: any,
        protocolInstance: any,
        cohorts: any[],
        stopBusy = true
    ): Promise<any> {
        this.busyStart();
        return this._setupAddAnimalsToTasks(job, 'Cohort', cohorts, protocolInstance).then(
            ({ animalProtocolInstances, taskStatusKey }) => {
                return this._addCohortsToProtocolInstance(
                    job, protocolInstance, cohorts, animalProtocolInstances, taskStatusKey
                );
            }
        ).then(() => {
            // Force a table update
            this.notifyJobArrayChanged('TaskJob');
            this.tabRefresh('samples', 'groups');
            this.tabRefresh('samples', 'individuals');
            if (stopBusy) {
                this.busyStop();
            }
        }).catch((error: any) => {
            this.busyStop();
            throw error;
        });
    }

    /**
     * Add Cohorts to all the tasks of a Protocolnstance.
     */
    private _addCohortsToProtocolInstance(
        job: any,
        protocolInstance: any,
        cohorts: any[],
        animalProtocolInstances: any,
        taskStatusKey: any,
    ): Promise<any> {
        let promise = Promise.resolve();

        const tasks = this.findProtocolInstanceTasks(job, protocolInstance);

        if (tasks) {
            for (const task of tasks) {
                promise = promise.then(() => {
                    return this._addCohortsToTask(
                        job, task, cohorts, animalProtocolInstances, taskStatusKey
                    );
                });
            }
        }

        return promise;
    }

    /**
     * Add Animals to a single task
     * @deprecated Use addAnimalsToTask() instead
     */
    legacyAddAnimalsToTask(
        job: any,
        task: any,
        animals: any[],
        stopBusy = true,
        addedViaPlaceholder = false
    ): Promise<any> {
        this.busyStart();
        return this._setupAddAnimalsToTasks(job, 'Animal', animals, task).then(
            ({ animalProtocolInstances, taskStatusKey }) => {
                return this._addAnimalsToTask(
                    job, task, animals, animalProtocolInstances, taskStatusKey, addedViaPlaceholder
                );
            }
        ).then(() => {
            // Force a table update
            this.notifyJobArrayChanged('TaskJob');
            this.tabRefresh('samples', 'groups');
            this.tabRefresh('samples', 'individuals');
            if (stopBusy) {
                this.busyStop();
            }
        }).catch((error: any) => {
            this.busyStop();
            throw error;
        });
    }

    async showModalAnimalsToTask(
        taskInstance: TaskInstance,
        animals: Animal[] | PartialMaterial[],
    ): Promise<boolean> {
        const taskInstanceWrapper = {
            TaskInstance: [taskInstance],
        } as ProtocolInstance;
        return this.showModalAnimalsToProtocol(taskInstanceWrapper, animals);
    }

    /**
     * Add Animals to a single task
     */
    async addAnimalsToTask(
        job: Job,
        task: TaskInstance,
        materials: PartialMaterial[],
    ): Promise<boolean> {
        if (!this.isJobSaved(job) || this.isOnlyNonMaterialTasks([task])) {
            return false;
        }

        try {
            this.busyStart();

            const httpOptions = {
                headers: new HttpHeaders({
                    'x-single-request': '1', // disable multiple requests when network errors
                }),
            };
            await this.httpClient.post('/api/jobtaskinstances/add-animals-to-task', {
                JobKey: job.C_Job_key,
                TaskInstanceKey: task.C_TaskInstance_key,
                MaterialKeys: materials.map(({ C_Material_key }) => C_Material_key),
            }, httpOptions).toPromise();

            // Force a table update
            this.notifyJobArrayChanged('TaskJob');
            return true;
        } catch {
            return false;
        } finally {
            this.busyStop();
        }
    }

    async addMaterialsToJobIfMissing(job: (Job & ExtendedJob), materials: Material[], notifyAboutChanges = true) {
        // Make sure materials created on the backend are loaded
        const jobExpands = [
            'JobMaterial',
        ];
        await this.dataManager.ensureRelationships([job], jobExpands);

        const jobMaterialKeys = job.JobMaterial.map(jc => jc.C_Material_key);
        const materialsToAddToJob = materials.filter(m => !jobMaterialKeys.includes(m.C_Material_key));
        if (notEmpty(materialsToAddToJob)) {
            if (!this.getIsGLPFlag()) {
                const animals = materialsToAddToJob.map(m => m.Animal).filter(a => a);
                const isContinue = await this.handleAnimalsIfEnrolledInOtherJobs(job, animals);
                if (!isContinue) {
                    return false;
                }
            }

            const jobMaterials = await this.addMaterialsToJob(job, materialsToAddToJob);
            await this.dataContextService.saveSingleRecordBatch(jobMaterials as Entity<JobMaterial>[]);
            if (notifyAboutChanges) {
                this.notifyJobArrayChanged('JobMaterial');
            }
        }
        return true;
    }

    async addCohortsToJobIfMissing(job: Job, cohorts: Cohort[], notifyAboutChanges = true) {
        const jobCohortKeys = job.JobCohort.map(jc => jc.C_Cohort_key);
        const cohortsToAddToJob = cohorts.filter(c => !jobCohortKeys.includes(c.C_Cohort_key)) as (Cohort & CohortExtended)[];
        if (notEmpty(cohortsToAddToJob)) {
            const jobCohorts = await this.addCohortsToJob(job, cohortsToAddToJob);
            await this.dataContextService.saveSingleRecordBatch(jobCohorts as Entity<JobCohort>[]);
            if (notifyAboutChanges) {
                this.loggingService.logFacetSaveSuccess(this.COMPONENT_LOG_TAG, true);
                this.notifyJobArrayChanged('JobCohort');
            }
        }
    }

    private hasNoMaterialsTasks(taskInstances: TaskInstance[]): boolean {
        return taskInstances.some(({ WorkflowTask }) => WorkflowTask?.NoMaterials);
    }

    private hasAllNoMaterialsTasks(taskInstances: TaskInstance[]): boolean {
        return taskInstances.every(({ WorkflowTask }) => WorkflowTask?.NoMaterials);
    }

    /**
     * Add dropped Animals to a single task.
     */
    private _addAnimalsToTask(
        job: any,
        task: any,
        animals: any[],
        animalProtocolInstances: any,
        taskStatusKey: any,
        addedViaPlaceholder = false
    ): Promise<any> {
        let promise = Promise.resolve();

        for (const animal of animals) {
            promise = promise.then(() => {
                return this._addAnimalToTask(task, animal, addedViaPlaceholder);
            });
        }

        if (task.IsGroup) {
            promise = promise.then(() => {
                return this._addMemberTasks(
                    job, task, animals, animalProtocolInstances, taskStatusKey, null, addedViaPlaceholder
                );
            });
        }

        return promise;
    }

    /**
     * Add an Animal to a task.
     */
    private _addAnimalToTask(task: any, animal: any, addedViaPlaceholder = false): any {
        if (!task?.WorkflowTask?.NoMaterials) {
            this.taskService.createTaskMaterial({
                C_TaskInstance_key: task.C_TaskInstance_key,
                C_Material_key: animal.C_Material_key,
                Sequence: maxSequence(animal.Material.TaskMaterial) + 1
            });

            if (addedViaPlaceholder) {
                // For placeholders assigned to task
                // Only create SampleGroupSourceMaterial if the corresponding placeholder is there
                // In the source materials of the Sample Group
                if (task.GroupTaskInstance && task.GroupTaskInstance.SampleGroup) {
                    task.GroupTaskInstance.SampleGroup.forEach((sampleGroup: any) => {
                        const canAddSampleGroupSourceMaterials = sampleGroup?.Sample?.length === 0 || this.isSampleGroupsEditableFlag();
                        if (canAddSampleGroupSourceMaterials) {
                            // Find the associated placeholders in SampleGroup
                            const animalPlaceholderKeys = sampleGroup.SampleGroupSourceMaterial
                                .filter((sm: any) => sm.AnimalPlaceholder !== null)
                                .map((sm: any) => sm.C_AnimalPlaceholder_key);
                            console.log({animalPlaceholderKeys});
                            // Find the associated placeholders in task which belong to current animal
                            const animalPlaceholderKeysInTask = task.GroupTaskInstance.TaskPlaceholder
                                .filter((taskPlaceholder: any) => taskPlaceholder.AnimalPlaceholder !== null && taskPlaceholder.AnimalPlaceholder.C_Material_key === animal.C_Material_key)
                                .map((taskPlaceholder: any) => taskPlaceholder.C_AnimalPlaceholder_key);
                            console.log({animalPlaceholderKeysInTask});
                                // Go through all the placeholders in task and
                                // match if has a associated placeholder in sample group
                                // Create the source material for that placeholder
                            animalPlaceholderKeysInTask.forEach((animalPlaceholder: any) => {
                                if (animalPlaceholderKeys.includes(animalPlaceholder)) {
                                    this.dataManager.createEntity('SampleGroupSourceMaterial', {
                                        C_SampleGroup_key: sampleGroup.C_SampleGroup_key,
                                        C_Material_key: animal.C_Material_key,
                                        SampleGroup: sampleGroup
                                    });
                                }
                            });
                        }
                    });
                }
            } else {
                // If this is coming without placeholders
                if (task.GroupTaskInstance && task.GroupTaskInstance.SampleGroup) {
                    task.GroupTaskInstance.SampleGroup.forEach((sampleGroup: SampleGroup) => {
                        const animalIsAlreadySourceMaterial = sampleGroup.SampleGroupSourceMaterial.find(sm => sm.C_Material_key === animal.C_Material_key);
                        const canAddSampleGroupSourceMaterials = sampleGroup?.Sample?.length === 0 || this.isSampleGroupsEditableFlag();
                        if (!animalIsAlreadySourceMaterial && canAddSampleGroupSourceMaterials) {
                            this.dataManager.createEntity('SampleGroupSourceMaterial', {
                                C_SampleGroup_key: sampleGroup.C_SampleGroup_key,
                                C_Material_key: animal.C_Material_key,
                                SampleGroup: sampleGroup
                            });
                        }
                    });
                }
            }
            return null;
        } else {
            this.loggingService.logWarning('Cannot attach animals to "No Material" tasks',
                null, 'job-pharma-task-animal-table', true);
        }
    }

    /**
     * This method adds cohorts to a task and saves it immediately.
     * @param {Job} job - The job (study) entity to which the task belongs.
     * @param {TaskInstance} task - The task instance to which the cohorts are being added.
     * @param {Cohort[]} cohorts - The array of cohorts to add to the task.
     * @returns {Promise<boolean>} - A promise that resolves to a boolean value indicating whether the changes were saved successfully.
     */
    async assignCohortToTaskImmediately(
        job: Job,
        task: TaskInstance,
        cohorts: Cohort[]
    ): Promise<boolean> {
        const changesCanBeSaved = await this.addCohortsToTask(job, task, cohorts, false);
        if (changesCanBeSaved) {
            await this.dataContextService.save();
            return true;
        }
        return false;
    }
    
    /**
     * Add Cohorts to a single task
     */
    async addCohortsToTask(
        job: Job,
        task: TaskInstance,
        cohorts: Cohort[],
        notifyAboutChanges = true
    ): Promise<boolean> {
        if (!task.IsGroup) {
            // Not a group task, so nothing to do
            this.loggingService.logWarning(
                'Cohorts can only be added to group tasks.',
                null,
                this.COMPONENT_LOG_TAG,
                true);
            return;
        }

        if (!this.isJobSaved(job) || this.isOnlyNonMaterialTasks([task])) {
            return false;
        }

        try {
            this.busyStart();

            await this.addCohortsToJobIfMissing(job, cohorts, notifyAboutChanges);
            const materials = cohorts.flatMap(cohort => cohort.CohortMaterial.flatMap(cm => cm.Material));
            const isContinue = await this.addMaterialsToJobIfMissing(job, materials, notifyAboutChanges);
            if (!isContinue) {
                return false;
            }

            const { animalProtocolInstances, taskStatusKey } = await this._setupAddAnimalsToTasks(job, 'Cohort', cohorts, task);
            await this._addCohortsToTask(job, task, cohorts, animalProtocolInstances, taskStatusKey);

            if (notifyAboutChanges) {
                this.notifyJobArrayChanged('TaskJob');
            }
            return true;
        } catch {
            return false;
        } finally {
            this.busyStop();
        }

    }

    /**
     * Add Cohorts to a single task.
     */
    private _addCohortsToTask(
        job: any,
        task: any,
        cohorts: any[],
        animalProtocolInstances: any,
        taskStatusKey: any
    ): Promise<any> {
        let promise = Promise.resolve();

        for (const cohort of cohorts) {
            promise = promise.then(() => {
                return this._addCohortToTask(
                    job, task, cohort, animalProtocolInstances, taskStatusKey
                );
            });
        }

        return promise;
    }

    addPlaceholderToTask(task: any, placeholder: any) {
        if (!task.WorkflowTask.NoMaterials) {
            if (!this.checkIfHasPlaceholder(task, placeholder)) {
                const initialValues = {
                    C_TaskInstance_key: task.C_TaskInstance_key,
                    C_Placeholder_key: placeholder.C_Placeholder_key
                };
                const taskPlaceholder = this.jobService.createPlaceholderForTask(initialValues);
                placeholder.AnimalPlaceholder.forEach((animalPlaceholder: any) => {
                    task.SampleGroup.forEach((sampleGroup: SampleGroup) => {
                        const placeholderIsAlreadySourceMaterial = sampleGroup.SampleGroupSourceMaterial.find(sgsm => {
                            return sgsm.C_AnimalPlaceholder_key === animalPlaceholder.C_AnimalPlaceholder_key
                        });
                        const canAddSampleGroupSourceMaterials = sampleGroup?.Sample?.length === 0 || this.isSampleGroupsEditableFlag();
                        if (!placeholderIsAlreadySourceMaterial && canAddSampleGroupSourceMaterials) {
                            this.dataManager.createEntity('SampleGroupSourceMaterial', {
                                C_SampleGroup_key: sampleGroup.C_SampleGroup_key,
                                C_AnimalPlaceholder_key: animalPlaceholder.C_AnimalPlaceholder_key,
                                SampleGroup: sampleGroup
                            });
                        }
                    });
                });
                if (taskPlaceholder && task.TaskInput) {
                    // Set input values for each input
                    for (const taskInput of task.TaskInput) {
                        let inputValue = taskInput.InputValue;
                        // Get default input value
                        const defaultInput = task.ProtocolTask && task.ProtocolTask.InputDefault ? task.ProtocolTask.InputDefault.find((x: any) => x.C_Input_key === task.TaskInput.C_Input_key) : null;
                        if (defaultInput) {
                            inputValue = defaultInput.InputValue;
                        }
                        // Get Dosing Table input value
                        if (task.C_ProtocolInstance_key && placeholder.JobGroup && placeholder.JobGroup.JobGroupTreatment.length > 0 && taskInput.Input.cv_DataType.DataType.toUpperCase() === 'DOSING TABLE') {
                            const treatment = placeholder.JobGroup.JobGroupTreatment.find((x: any) => x.C_ProtocolInstance_key === task.C_ProtocolInstance_key);
                            if (treatment) {
                                inputValue = this.getJobGroupValueByDosingTableType(treatment, taskInput.Input.cv_DosingTable.DosingTable);
                            }
                        }

                        this.dataManager.createEntity('TaskPlaceholderInput', {
                            C_Input_key: taskInput.C_Input_key,
                            C_TaskPlaceholder_key: taskPlaceholder.C_TaskPlaceholder_key,
                            InputValue: inputValue,
                        });
                    }
                }
                this.tabRefresh('tasks', 'list');
                this.tabRefresh('samples', 'groups');

            } else {
                this.loggingService.logWarning('Placeholders cannot be added to a task more than once.',
                    null, 'job-pharma-tasks-list-table', true);
            }
        } else {
            this.loggingService.logWarning('Cannot attach Placeholders to "No Material" tasks',
                null, 'job-pharma-tasks-list-table', true);
        }
    }

    private checkIfHasPlaceholder(task: any, placeholder: any) {
        const taskPlaceholders = task.TaskPlaceholder.filter((taskPlaceholder: any) => taskPlaceholder.C_Placeholder_key === placeholder.C_Placeholder_key);
        return taskPlaceholders.length > 0;
    }

    /**
     * Add a AnimalPlaceholder to Task
     * @param task
     * @param animalPlaceholder
     * @retunr {Promise<any>}
     */
    addAnimalPlaceholderToTask(task: any, animalPlaceholder: any) {
        if (!task.WorkflowTask.NoMaterials) {
            if (!this.checkIfHasAnimalPlaceholder(task, animalPlaceholder)) {
                const initialValues = {
                    C_TaskInstance_key: task.C_TaskInstance_key,
                    C_AnimalPlaceholder_key: animalPlaceholder.C_AnimalPlaceholder_key
                };

                const taskAnimalPlaceholder = this.jobService.createAnimalPlaceholderForTask(initialValues);
                task.SampleGroup.forEach((sampleGroup: SampleGroup) => {
                    const placeholderIsAlreadySourceMaterial = sampleGroup.SampleGroupSourceMaterial.find(sgsm => {
                        return sgsm.C_AnimalPlaceholder_key === animalPlaceholder.C_AnimalPlaceholder_key
                    });
                    const canAddSampleGroupSourceMaterials = sampleGroup?.Sample?.length === 0 || this.isSampleGroupsEditableFlag();
                    if (!placeholderIsAlreadySourceMaterial && canAddSampleGroupSourceMaterials) {
                        this.dataManager.createEntity('SampleGroupSourceMaterial', {
                            C_SampleGroup_key: sampleGroup.C_SampleGroup_key,
                            C_AnimalPlaceholder_key: animalPlaceholder.C_AnimalPlaceholder_key,
                            SampleGroup: sampleGroup
                        });
                    }
                });
                /*
                TODO: Add TaskAnimalPlaceholderInputs for each TaskInput after finalising how it works on the dosing table side
                if (taskAnimalPlaceholder && task.TaskInput) {
                    for (const taskInput of task.TaskInput) {
                        if (task.C_ProtocolInstance_key &&
                            animalPlaceholder.JobGroup &&
                            animalPlaceholder.JobGroup.JobGroupTreatment.length > 0 &&
                            taskInput.Input.cv_DataType.DataType.toUpperCase() === 'DOSING TABLE') {
                            let treatment = animalPlaceholder.JobGroup.JobGroupTreatment.find((x: any) => x.C_ProtocolInstance_key === task.C_ProtocolInstance_key);
                            if (treatment) {
                                taskInput.InputValue = this.getJobGroupValueByDosingTableType(treatment, taskInput.Input.cv_DosingTable.DosingTable);
                            }
                        }

                        this.dataManager.createEntity('TaskPlaceholderInput', {
                            C_Input_key: taskInput.C_Input_key,
                            C_TaskPlaceholder_key: taskAnimalPlaceholder.C_TaskPlaceholder_key,
                            InputValue: taskInput.InputValue,
                        });
                    }
                }
                */
                this.tabRefresh('tasks', 'list');
                this.tabRefresh('samples', 'groups');
            } else {
                this.loggingService.logWarning('Animal Placeholders cannot be added to a task more than once.',
                    null, 'job-pharma-tasks-list-table', true);
            }
        } else {
            this.loggingService.logWarning('Cannot attach Animal Placeholders to "No Material" tasks',
                null, 'job-pharma-tasks-list-table', true);
        }
    }

    /**
     * Check if animal placeholder is already added to task
     * @param task
     * @param placeholder
     * @returns boolean
     */
    private checkIfHasAnimalPlaceholder(task: any, placeholder: any): boolean {
        const taskPlaceholders = task.TaskPlaceholder.filter((taskPlaceholder: any) => taskPlaceholder.C_AnimalPlaceholder_key === placeholder.C_AnimalPlaceholder_key);
        return taskPlaceholders.length > 0;
    }

    /**
     * Add Cohort to a single task.
     */
    private _addCohortToTask(
        job: any,
        task: any,
        cohort: any,
        animalProtocolInstances: any,
        taskStatusKey: any
    ): Promise<any> {
        if (!task.WorkflowTask.NoMaterials) {
            const taskCohort = this.taskService.createTaskCohort({
                C_TaskInstance_key: task.C_TaskInstance_key,
                C_Cohort_key: cohort.C_Cohort_key,
                Sequence: maxSequence(task.TaskCohort) + 1
            });

            const isCRO = this.getIsCroFlag();

            // Get the TaskPlaceholder associated with this task and cohort
            let placeholder: any = null;
            let curTaskPlaceholder: any = null;
            if (isCRO) {
                placeholder = this.getPlaceholderFromCohort(cohort, job.C_Job_key);
                // Add placeholder to cohort's task if not already assigned
                if (placeholder && !this.checkIfHasPlaceholder(task, placeholder)) {
                    this.addPlaceholderToTask(task, placeholder);
                }

                // Get all placeholder's tasks
                if (placeholder && task.TaskPlaceholder.length) {
                    curTaskPlaceholder = task.TaskPlaceholder.find((tp: any) => tp.C_Placeholder_key === placeholder.C_Placeholder_key);
                }
            }

            if (taskCohort) {
                if (task.TaskInput) {
                    for (const taskInput of task.TaskInput) {
                        let inputValue = null;
                        if (isCRO) {
                            // Copy the input values from TaskPlaceholderInput to TaskCohortInput if there's a corresponding placeholder assigned to the task
                            if (placeholder && curTaskPlaceholder && curTaskPlaceholder.TaskPlaceholderInput.length) {
                                const taskPlaceholderInput = curTaskPlaceholder.TaskPlaceholderInput.find((tpi: any) => tpi.C_Input_key === taskInput.C_Input_key);
                                inputValue = taskPlaceholderInput.InputValue;
                            }

                            // Assign Dosing Table inputs based on the Dosing Table values
                            if (placeholder && placeholder.JobGroup
                                && !curTaskPlaceholder
                                && placeholder.JobGroup.JobGroupTreatment.length > 0
                                && taskInput.Input.cv_DataType.DataType.toUpperCase() === 'DOSING TABLE') {
                                const treatment = placeholder.JobGroup.JobGroupTreatment.find((x: any) => x.C_ProtocolInstance_key === task.C_ProtocolInstance_key);
                                if (treatment) {
                                    inputValue = this.getJobGroupValueByDosingTableType(treatment, taskInput.Input.cv_DosingTable.DosingTable);
                                }
                            }
                        }

                        this.dataManager.createEntity('TaskCohortInput', {
                            C_Input_key: taskInput.C_Input_key,
                            C_TaskCohort_key: taskCohort.C_TaskCohort_key,
                            InputValue: inputValue ? inputValue : taskInput.InputValue,
                        });
                    }
                }
            } else {
                this.loggingService.logWarning('Cohorts cannot be added to a task more than once.',
                    null, 'job-pharma-task-animal-table', true);
            }

            const animals = this._getCohortAnimals(cohort);
            return this._addMemberTasks(
                job, task, animals, animalProtocolInstances, taskStatusKey, taskCohort
            );
        } else {
            this.loggingService.logWarning('Cannot attach cohorts to "No Material" tasks',
                null, 'job-pharma-task-animal-table', true);
        }
    }

    updateTaskCohortInputsFromPlaceholders(placeholders: any[]): Promise<any> {
        const expands = [
            'Job',
            'JobGroup.JobGroupTreatment',
            'JobCohort.Cohort.CohortMaterial.Material.Animal',
            'JobCohort.Cohort.TaskCohort.TaskCohortInput.Input.cv_DataType',
            'JobCohort.Cohort.TaskCohort.TaskCohortInput.Input.cv_DosingTable',
            'JobCohort.Cohort.TaskCohort.TaskInstance.TaskJob',
            'JobCohort.Cohort.TaskCohort.TaskInstance.cv_TaskStatus',
            'JobCohort.Cohort.TaskCohort.TaskInstance.TaskMaterial',
            'JobCohort.Cohort.TaskCohort.TaskInstance.TaskPlaceholder',
            'JobCohort.Cohort.TaskCohort.TaskInstance.TaskInput.Input.cv_DataType',
            'JobCohort.Cohort.TaskCohort.TaskInstance.TaskInput.Input.cv_DosingTable',
            'JobCohort.Cohort.TaskCohort.TaskInstance.WorkflowTask.Input.cv_DataType',
            'JobCohort.Cohort.TaskCohort.TaskInstance.WorkflowTask.Input.cv_DosingTable'
        ];

        return this.dataManager.ensureRelationships(placeholders, expands).then(() => {
            // Update inputs for each placeholder (assumes placeholders are linked to cohorts)
            for (const placeholder of placeholders) {
                const jobGroup = placeholder.JobGroup;
                const cohort = placeholder.JobCohort.Cohort;

                // Go through each task assigned to the cohort
                for (const task of cohort.TaskCohort.filter((tc: any) => tc.TaskInstance.TaskJob[0].C_Job_key === jobGroup.C_Job_key)) {
                    // Add placeholder to cohort's task if not already assigned
                    if (placeholder && task.TaskInstance && !this.checkIfHasPlaceholder(task.TaskInstance, placeholder)) {
                        this.addPlaceholderToTask(task.TaskInstance, placeholder);
                    }

                    // Update the Dosing Table inputs
                    for (const treatment of jobGroup.JobGroupTreatment) {
                        if (!treatment.C_ProtocolInstance_key
                            || task.TaskInstance.C_ProtocolInstance_key !== treatment.C_ProtocolInstance_key
                            || task.TaskInstance.cv_TaskStatus?.IsEndState
                            || task.TaskInstance.IsLocked) {
                            continue;
                        }

                        // Get Dosing Table type inputs from this task
                        const taskCohortInputsDosing = task.TaskCohortInput.filter((tci: any) => tci.Input.cv_DataType.DataType.toUpperCase() === 'DOSING TABLE');
                        // If there are Dosing Table type inputs and this task is part of this Job
                        if (taskCohortInputsDosing && taskCohortInputsDosing.length > 0 && task.TaskInstance.TaskJob[0].C_Job_key === jobGroup.C_Job_key) {
                            // Update the input values for each task input based on the Dosing Table value
                            for (const tci of taskCohortInputsDosing) {
                                const newValue = this.getJobGroupValueByDosingTableType(treatment, tci.Input.cv_DosingTable.DosingTable);

                                // Update the input value on the TaskCohortInput entity
                                tci.InputValue = newValue;

                                // Update the Task Inputs for each individual animal in the cohort
                                const animals = this._getCohortAnimals(cohort);

                                // Find all animals that assigned to this groupTask
                                const memberTasks = {};
                                for (const taskInstance of this.findMemberTasks(placeholder.Job, task.TaskInstance)) {
                                    if (!taskInstance.TaskMaterial || taskInstance.IsLocked || taskInstance.cv_TaskStatus?.IsEndState) {
                                        continue;
                                    }

                                    for (const material of taskInstance.TaskMaterial) {
                                        memberTasks[material.C_Material_key] = taskInstance;
                                    }
                                }

                                // Update inputs for the member tasks associated with the animals in this cohort
                                for (const animal of animals) {
                                    const childTask = memberTasks[animal.C_Material_key];
                                    if (childTask) {
                                        const taskInput = childTask.TaskInput.find((ti: any) => ti.C_Input_key === tci.C_Input_key);
                                        taskInput.InputValue = newValue;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            return Promise.resolve();
        });
    }

    updateTaskInputsFromJobGroup(jobGroupTreatments: any[], columnName: string, treatmentToProtocolMap: { [jobGroupTreatmentKey: number]: number } = {}): Promise<any> {
        const expands = [
            'JobGroup.Job',
            'JobGroup.Placeholder.JobCohort.Cohort.CohortMaterial.Material.Animal',
            'JobGroup.Placeholder.JobCohort.Cohort.TaskCohort.TaskCohortInput.Input.cv_DataType',
            'JobGroup.Placeholder.JobCohort.Cohort.TaskCohort.TaskCohortInput.Input.cv_DosingTable',
            'JobGroup.Placeholder.JobCohort.Cohort.TaskCohort.TaskInstance.cv_TaskStatus',
            'JobGroup.Placeholder.TaskPlaceholder.TaskPlaceholderInput.Input.cv_DataType',
            'JobGroup.Placeholder.TaskPlaceholder.TaskPlaceholderInput.Input.cv_DosingTable',
            'JobGroup.Placeholder.TaskPlaceholder.TaskInstance.cv_TaskStatus',
            'JobGroup.Placeholder.TaskPlaceholder.TaskInstance.TaskCohort.TaskCohortInput.Input.cv_DataType',
            'JobGroup.Placeholder.TaskPlaceholder.TaskInstance.TaskCohort.TaskCohortInput.Input.cv_DosingTable'
        ];

        return this.dataManager.ensureRelationships(jobGroupTreatments, expands).then(() => {
            for (const jobGroupTreatment of jobGroupTreatments) {
                if (columnName.toUpperCase() !== 'PROTOCOL') {
                    const newInputValue = this.getJobGroupValueByDosingTableType(jobGroupTreatment, columnName);
                    // Get the placeholder associated with this JobGroupTreatment
                    let placeholder: any = null;
                    if (jobGroupTreatment.JobGroup.Placeholder.length) {
                        placeholder = jobGroupTreatment.JobGroup.Placeholder[0];
                    }

                    // Theoretically this should never happen (JobGroups always have a Placeholder)
                    if (!placeholder) {
                        continue;
                    }

                    // Update placeholder's task inputs
                    for (const taskPlaceholder of placeholder.TaskPlaceholder) {
                        // Only update task inputs for the placeholder's assigned protocol
                        if (!jobGroupTreatment.C_ProtocolInstance_key
                            || taskPlaceholder.TaskInstance.C_ProtocolInstance_key !== jobGroupTreatment.C_ProtocolInstance_key) {
                            continue;
                        }

                        // Update Placeholder task inputs
                        if (taskPlaceholder.TaskPlaceholderInput.length) {
                            const taskPlaceholderInputs = taskPlaceholder.TaskPlaceholderInput.filter((tpi: any) => {
                                return tpi.Input.cv_DataType.DataType.toUpperCase() === 'DOSING TABLE' && tpi.Input.cv_DosingTable.DosingTable.toUpperCase() === columnName.toUpperCase();
                            });

                            for (const taskPlaceholderInput of taskPlaceholderInputs) {
                                taskPlaceholderInput.InputValue = newInputValue;
                            }
                        }

                        // Update Cohort task inputs if Placeholder has a linked Cohort
                        if (placeholder.JobCohort) {
                            const groupTask = taskPlaceholder.TaskInstance;

                            // Update the group Task Cohort Inputs
                            const taskCohort = groupTask.TaskCohort.find((tc: any) => tc.C_Cohort_key === placeholder.JobCohort.C_Cohort_key);
                            if (taskCohort && !taskCohort.TaskInstance.IsLocked && !taskCohort.TaskInstance.cv_TaskStatus?.IsEndState) {
                                const taskCohortInputs = taskCohort.TaskCohortInput.filter((tci: any) => {
                                    return tci.Input.cv_DataType.DataType.toUpperCase() === 'DOSING TABLE' && tci.Input.cv_DosingTable.DosingTable.toUpperCase() === columnName.toUpperCase();
                                });

                                for (const taskCohortInput of taskCohortInputs) {
                                    taskCohortInput.InputValue = newInputValue;
                                }

                                // Update the Task Inputs for each individual animal in the cohort
                                const animals = this._getCohortAnimals(placeholder.JobCohort.Cohort);

                                // Find all animals that assigned to this groupTask
                                const memberTasks = {};
                                for (const task of this.findMemberTasks(jobGroupTreatment.JobGroup.Job, taskCohort.TaskInstance)) {
                                    if (!task.TaskMaterial || task.IsLocked || task.cv_TaskStatus?.IsEndState) {
                                        continue;
                                    }

                                    for (const material of task.TaskMaterial) {
                                        memberTasks[material.C_Material_key] = task;
                                    }
                                }

                                // Update inputs for the member tasks associated with the animals in this cohort
                                for (const animal of animals) {
                                    const childTask = memberTasks[animal.C_Material_key];
                                    if (childTask) {
                                        const taskInputs = childTask.TaskInput.filter((ti: any) => {
                                            return ti.Input.cv_DataType.DataType.toUpperCase() === 'DOSING TABLE' && ti.Input.cv_DosingTable.DosingTable.toUpperCase() === columnName.toUpperCase();
                                        });

                                        for (const taskInput of taskInputs) {
                                            taskInput.InputValue = newInputValue;
                                        }
                                    }
                                }
                            }
                        }
                    }
                } else if (columnName.toUpperCase() === 'PROTOCOL') {
                    // Get the placeholder associated with this JobGroupTreatment
                    let placeholder: any = null;
                    if (jobGroupTreatment.JobGroup.Placeholder.length) {
                        placeholder = jobGroupTreatment.JobGroup.Placeholder[0];
                    }

                    // Theoretically this should never happen (JobGroups always have a Placeholder)
                    if (!placeholder) {
                        continue;
                    }

                    // Clear inputs for previously assigned ProtocolInstance
                    const previousProtocolInstanceKey = treatmentToProtocolMap[jobGroupTreatment.C_JobGroupTreatment_key];

                    // Update task inputs
                    for (const taskPlaceholder of placeholder.TaskPlaceholder) {
                        // Only update task inputs for the placeholder's assigned protocol or previous protocol
                        if (!taskPlaceholder.TaskInstance.C_ProtocolInstance_key
                            || (taskPlaceholder.TaskInstance.C_ProtocolInstance_key !== jobGroupTreatment.C_ProtocolInstance_key
                                && taskPlaceholder.TaskInstance.C_ProtocolInstance_key !== previousProtocolInstanceKey)
                        ) {
                            continue;
                        }

                        // Update Placeholder task inputs
                        if (taskPlaceholder.TaskPlaceholderInput.length) {
                            const taskPlaceholderInputs = taskPlaceholder.TaskPlaceholderInput.filter((tpi: any) => {
                                return tpi.Input.cv_DataType.DataType.toUpperCase() === 'DOSING TABLE' && tpi.Input.cv_DosingTable;
                            });

                            for (const taskPlaceholderInput of taskPlaceholderInputs) {
                                const newInputValue = !jobGroupTreatment.C_ProtocolInstance_key
                                    || (previousProtocolInstanceKey && taskPlaceholder.TaskInstance.C_ProtocolInstance_key === previousProtocolInstanceKey)
                                    ? null
                                    : this.getJobGroupValueByDosingTableType(jobGroupTreatment, taskPlaceholderInput.Input.cv_DosingTable.DosingTable);
                                taskPlaceholderInput.InputValue = newInputValue;
                            }
                        }

                        // Update Cohort task inputs if Placeholder has a linked Cohort
                        if (placeholder.JobCohort) {
                            const groupTask = taskPlaceholder.TaskInstance;

                            // Update the group Task Cohort Inputs
                            const taskCohort = groupTask.TaskCohort.find((tc: any) => tc.C_Cohort_key === placeholder.JobCohort.C_Cohort_key);

                            if (taskCohort && !groupTask.IsLocked && !groupTask.cv_TaskStatus?.IsEndState) {
                                const taskCohortInputs = taskCohort.TaskCohortInput.filter((tci: any) => {
                                    return tci.Input.cv_DataType.DataType.toUpperCase() === 'DOSING TABLE' && tci.Input.cv_DosingTable;
                                });

                                for (const taskCohortInput of taskCohortInputs) {
                                    const newInputValue = !jobGroupTreatment.C_ProtocolInstance_key || (previousProtocolInstanceKey && groupTask.C_ProtocolInstance_key === previousProtocolInstanceKey)
                                        ? null
                                        : this.getJobGroupValueByDosingTableType(jobGroupTreatment, taskCohortInput.Input.cv_DosingTable.DosingTable);
                                    taskCohortInput.InputValue = newInputValue;
                                }

                                // Update the Task Inputs for each individual animal in the cohort
                                const animals = this._getCohortAnimals(placeholder.JobCohort.Cohort);

                                // Find all animals that assigned to this groupTask
                                const memberTasks = {};
                                for (const task of this.findMemberTasks(jobGroupTreatment.JobGroup.Job, groupTask)) {
                                    if (!task.TaskMaterial || task.IsLocked || task.cv_TaskStatus?.IsEndState) {
                                        continue;
                                    }

                                    for (const material of task.TaskMaterial) {
                                        memberTasks[material.C_Material_key] = task;
                                    }
                                }

                                // Update inputs for the member tasks associated with the animals in this cohort
                                for (const animal of animals) {
                                    const childTask = memberTasks[animal.C_Material_key];
                                    if (childTask) {
                                        const taskInputs = childTask.TaskInput.filter((ti: any) => {
                                            return ti.Input.cv_DataType.DataType.toUpperCase() === 'DOSING TABLE' && ti.Input.cv_DosingTable;
                                        });

                                        for (const taskInput of taskInputs) {
                                            const newInputValue = !jobGroupTreatment.C_ProtocolInstance_key
                                                || (previousProtocolInstanceKey && groupTask.C_ProtocolInstance_key === previousProtocolInstanceKey)
                                                ? null
                                                : this.getJobGroupValueByDosingTableType(jobGroupTreatment, taskInput.Input.cv_DosingTable.DosingTable);
                                            taskInput.InputValue = newInputValue;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            this.tabRefresh('tasks', 'inputs');
        });
    }

    /**
     * Get the Placeholder record associated with the given Cohort.
     */
    getPlaceholderFromCohort(cohort: any, jobKey: number): any {
        const curJobCohort = cohort.JobCohort.find((jobCohort: any) => jobCohort.C_Job_key === jobKey);
        if (curJobCohort && curJobCohort.Placeholder && curJobCohort.Placeholder.length > 0) {
            return curJobCohort.Placeholder[0];
        } else {
            return null;
        }
    }

    /**
     * Get the JobGroupTreatment column value associated with the given Dosing Table column type.
     */
    getJobGroupValueByDosingTableType(jobGroupTreatment: any, dosingTableType: string): any {
        switch (dosingTableType.toLowerCase()) {
            case 'treatment':
                return jobGroupTreatment.Treatment;
            case 'formulation dose':
                return jobGroupTreatment.FormulationDose;
            case 'active dose':
                return jobGroupTreatment.ActiveDose;
            case 'concentration':
                return jobGroupTreatment.Concentration;
            case 'route':
                return jobGroupTreatment.Route;
            case 'dosing volume':
                return jobGroupTreatment.DosingVolume;
            default:
                return null;
        }
    }

    taskDontHaveMatchingAnimal(placeholder: any, taskMaterial: any[]) {
        if (taskMaterial.length === 0) {
            return true;
        }
        if (!placeholder.AnimalPlaceholder || !placeholder.AnimalPlaceholder.Material) {
            return true;
        }
        const placeholderMaterial = placeholder.AnimalPlaceholder.C_Material_key;
        const taskMaterialKeys = taskMaterial.map((m: any) => m.C_Material_key);
        return !taskMaterialKeys.includes(placeholderMaterial);
    }

    taskDontHaveMatchingCohort(placeholder: any, taskCohort: any[]) {
        if (taskCohort.length === 0) {
            return true;
        }
        if (!placeholder.Placeholder || !placeholder.Placeholder.JobCohort) {
            return true;
        }
        const key = placeholder.Placeholder.JobCohort.C_Cohort_key;
        const cohorts = taskCohort.filter((tf: any) => tf.Cohort.C_Cohort_key === key);
        return cohorts.length === 0;
    }

    /**
     * Get all the Animals in a Cohort.
     *
     * Note: This assumes the Cohort.CohortMaterial.Material.Animal
     * relationships are already expanded.
     */
    _getCohortAnimals(cohort: Cohort): Animal[] {
        if (!cohort || !cohort.CohortMaterial) {
            return [];
        }

        return cohort.CohortMaterial.filter((cm: any) => {
            return cm.Material && cm.Material.Animal;
        }).map(cm => cm.Material.Animal);
    }

    /**
     * Add member tasks for Animals to a group task.
     */
    private _addMemberTasks(
        job: any,
        groupTask: any,
        animals: any[],
        animalProtocolInstances: any,
        taskStatusKey: any,
        taskCohort: any = null,
        addedViaPlaceholder = false
    ): Promise<any> {
        let promise = Promise.resolve();

        if (!groupTask.IsGroup) {
            // Not a group task, so nothing to do
            return promise;
        }
        // We'll copy some info from this later.
        const groupProtocolInstance = groupTask.ProtocolInstance;

        // Find all animals that already have member tasks to avoid duplicates
        const memberKeys = {};
        for (const task of this.findMemberTasks(job, groupTask)) {
            if (!task.TaskMaterial) {
                continue;
            }

            for (const material of task.TaskMaterial) {
                memberKeys[material.C_Material_key] = true;
            }
        }

        // Add member tasks for all the Animals
        for (const animal of animals) {
            if (memberKeys[animal.C_Material_key]) {
                // Already a member of this task
                continue;
            }

            promise = promise.then(() => {
                // See if the animal needs a ProtocolInstance
                if (groupProtocolInstance && !animalProtocolInstances[animal.C_Material_key]) {
                    // Create a new ProtocolInstance
                    const protocolInstance = this.taskService.createProtocolInstance({
                        C_Protocol_key: groupProtocolInstance.C_Protocol_key,
                        ProtocolAlias: groupProtocolInstance.ProtocolAlias,
                    });

                    // We'll be using this when creating the TaskInstance later
                    animalProtocolInstances[animal.C_Material_key] =
                        protocolInstance.C_ProtocolInstance_key;
                }

                // Create a new TaskInstance, copying many of the values from
                // the group TaskInstance and ProtocolInstance.
                return this.taskService.createTaskInstance({
                    C_WorkflowTask_key: groupTask.C_WorkflowTask_key,

                    // Join the group
                    C_GroupTaskInstance_key: groupTask.C_TaskInstance_key,

                    // Associate with a Protocol (if any)
                    C_ProtocolTask_key: groupTask.C_ProtocolTask_key,
                    C_ProtocolInstance_key: animalProtocolInstances[animal.C_Material_key],

                    // Start with the default status
                    C_TaskStatus_key: taskStatusKey,

                    // Copy other values from the group task
                    TaskAlias: groupTask.TaskAlias,
                    DateDue: groupTask.DateDue,
                    C_AssignedTo_key: groupTask.C_AssignedTo_key,
                    C_LocationPosition_key: groupTask.C_LocationPosition_key,
                    CurrentLocationPath: groupTask.CurrentLocationPath,
                    Deviation: groupTask.Deviation,
                    Duration: groupTask.Duration,

                    // Copy the TaskInputs
                    TaskInputs: taskCohort ? taskCohort.TaskCohortInput : groupTask.TaskInput,
                });
            }).then((task: any) => {
                // Add the animal to the member task
                this._addAnimalToTask(task, animal, addedViaPlaceholder);

                // Add the member task to the Job
                this.jobService.createTaskJob(
                    job.C_Job_key,
                    task.C_TaskInstance_key,
                    0 // TODO: Sequence?
                );
            });
        }

        return promise;
    }

    /**
     * Build function to filter tasks that match the given tasks or are members
     * of the given tasks.
     */
    buildTaskFilter(tasks: any[]): TaskFilter {
        if (!tasks) {
            // Default to accepting all tasks
            return () => true;
        }

        // Create a lookup table for the tasks
        const taskKeys = {};
        if (tasks) {
            for (const task of tasks) {
                taskKeys[task.C_TaskInstance_key] = true;
            }
        }

        return (task: any) => {
            // Accept the provided tasks and members of the provided tasks.
            return taskKeys[task.C_TaskInstance_key] || taskKeys[task.C_GroupTaskInstance_key];
        };
    }

    /**
     * Try to remove Animals from the Job's tasks.
     *
     * @param job the current Job
     * @param animals array of Animals to remove
     * @param tasks If provided, animals will only be removed from these group
     * or individual tasks
     * @returns TryRemoveResult Array of removed animals and those still with data
     */
    async tryRemoveAnimalsFromTasks(
        job: (Job & ExtendedJob),
        animals: Animal[],
        tasks: TaskInstance[] = null,
    ): Promise<TryRemoveResult> {
        // Prepare the task filter
        const taskFilter = this.buildTaskFilter(tasks);

        // Make sure everything is loaded first
        this.busyStart();
        const jobExpands = [
            'JobMaterial',
            'TaskJob.TaskInstance.TaskOutputSet.TaskOutputSetMaterial',
            'TaskJob.TaskInstance.TaskOutputSet.TaskOutput',
            'TaskJob.TaskInstance.TaskMaterial.Material.SampleGroupSourceMaterial',
            'TaskJob.TaskInstance.TaskMaterial',
            'TaskJob.TaskInstance.cv_TaskStatus',
        ];
        try {
            await this.dataManager.ensureRelationships([job], jobExpands);
            const materials = animals.map(a => a.Material);
            // Find Animals that have data (completed tasks or collected output) in this Job.
            const withData = await this.findMaterialsWithData(job, materials, taskFilter);

            // Make a lookup table of Material keys
            const withDataKeys = {};
            for (const animal of withData) {
                withDataKeys[animal.C_Material_key] = true;
            }

            // Find Animals that are safe to remove
            const canRemove = animals.filter((animal: any) => {
                return !withDataKeys[animal.C_Material_key];
            });

            if (canRemove.length > 0) {
                if (tasks) {
                    const animalKeys = canRemove.map((animal) => animal.C_Material_key);
                    for (const task of tasks) {
                        task.SampleGroup.forEach(sampleGroup => {
                            // collect all SampleGroupSources that belong to the Animal that is being removed.
                            const sampleGroupSourcesToDelete = sampleGroup.SampleGroupSourceMaterial.filter((sampleGroupSourceMaterial: any) => {
                                return animalKeys.includes(sampleGroupSourceMaterial.C_Material_key);
                            });

                            sampleGroupSourcesToDelete.forEach((toDelete: any) => {
                                this.dataManager.deleteEntity(toDelete);
                            });
                        });
                    }
                }
                // Remove the Animals that are safe (have no data)
                this.removeAnimalsFromTasks(job, canRemove, taskFilter);
            }

            return {
                allRemoved: (withData.length === 0),
                removed: canRemove,
                withData,
            };
        } finally {
            this.busyStop();
        }
    }

    /**
     * Try to remove Cohorts from the Job's tasks.
     *
     * Will prompt for confirmation before removing animals from tasks that are
     * completed or removing collected TaskOutputSets.
     *
     * @param job the current Job
     * @param cohorts array of Cohorts to remove
     * @param tasks If provided, animals will only be removed from these group
     * @param removeAnimals
     * or individual tasks
     * @returns TryRemoveResult Array of removed cohorts and those still with data
     */
    tryRemoveCohortsFromTasks(
        job: (Job & ExtendedJob),
        cohorts: Cohort[],
        tasks: TaskInstance[] = null,
        removeAnimals = true
    ): Promise<TryRemoveResult> {
        // Prepare the task filter
        const taskFilter = this.buildTaskFilter(tasks);

        // Make sure everything is loaded first
        this.busyStart();
        const jobExpands = [
            'JobMaterial',
            'TaskJob.TaskInstance.TaskOutputSet.TaskOutputSetMaterial',
            'TaskJob.TaskInstance.TaskOutputSet.TaskOutput',
            'TaskJob.TaskInstance.TaskMaterial.Material.SampleGroupSourceMaterial',
            'TaskJob.TaskInstance.cv_TaskStatus',
            'TaskJob.TaskCohort.TaskCohortInput',
            'TaskJob.TaskCohort.Cohort',
            'TaskJob.TaskCohort.Cohort.CohortMaterial',
        ];

        const removed: any[] = [];
        const withData: any[] = [];

        const cohortKeysToDelete = cohorts.map(item => item.C_Cohort_key);

        return this.dataManager.ensureRelationships([job], jobExpands).then(async () => {
            for (const cohort of cohorts) {
                const animals = cohort.CohortMaterial.map(cm => cm.Material.Animal);
                const materials = animals.map(a => a.Material);
                // Find Animals that have data (completed tasks or collected output) in this Job.
                const animalsWithData = await this.findMaterialsWithData(job, materials, taskFilter);

                // Find all the Material keys for this cohort
                const cohortMaterialKeys = cohort.CohortMaterial ? cohort.CohortMaterial.map((cohortMaterial: any) => cohortMaterial.C_Material_key) : [];

                if (animalsWithData.length > 0) {
                    // Cannot remove this cohort
                    withData.push(cohort);
                } else {
                    if (tasks) {
                        for (const task of tasks) {
                            let restTaskCohortsInTaskKeys: Set<number>;
                            if (task.TaskCohort) {
                                // it contains other cohorts that belong to task instance
                                const restTaskCohortsInTask: TaskCohort[] = task.TaskCohort.filter((taskCohort: TaskCohort) => {
                                    return !cohortKeysToDelete.includes(taskCohort.Cohort.C_Cohort_key);
                                });
                                // it contains cohort materials from other cohorts in task instance
                                restTaskCohortsInTaskKeys = new Set(restTaskCohortsInTask.flatMap(item => {
                                    return item.Cohort.CohortMaterial.map(cohortMaterial => cohortMaterial.C_Material_key);
                                }));
                            }
                            task.SampleGroup.forEach((sampleGroup: any) => {
                                // collect all SampleGroupSourceMaterials that belong to the Cohort that is being removed.
                                const sampleGroupSourcesToDelete = sampleGroup.SampleGroupSourceMaterial.filter(({ C_Material_key }: SampleGroupSourceMaterial) => {
                                    return cohortMaterialKeys.indexOf(C_Material_key) >= 0 && !restTaskCohortsInTaskKeys.has(C_Material_key);
                                });

                                sampleGroupSourcesToDelete.forEach((toDelete: any) => {
                                    this.dataManager.deleteEntity(toDelete);
                                });
                            });
                        }
                    }
                    if (removeAnimals) {
                        // Remove the Animals that are safe (have no data)
                        this.removeAnimalsFromTasks(job, animals, taskFilter);
                    }

                    // Remove the Cohort from the tasks
                    this.removeCohortFromTasks(job, cohort, taskFilter);

                    // Note that it was removed
                    removed.push(cohort);
                }
            }

            return {
                allRemoved: (withData.length === 0),
                removed,
                withData,
            };
        }).then((result: TryRemoveResult) => {
            this.busyStop();
            return result;
        }).catch((error: any) => {
            this.busyStop();
            throw error;
        });
    }

    /**
     * Determine which of the provided Materials have data (completed tasks or
     * collected output) for the Job.
     *
     * @param job the current Job
     * @param animals array of Animals to remove
     * @returns array of Animals with data
     */
    async findMaterialsWithData(job: Job, materials: Material[], taskFilter: TaskFilter) {
        if (!job.TaskJob) {
            // Nothing to do
            return [];
        }

        // Go through all the tasks and look for data
        const hasData = {};
        for (const jt of job.TaskJob) {
            const task = jt.TaskInstance;
            if (!task || !taskFilter(task)) {
                continue;
            }

            if (task.TaskMaterial.length === 0) {
                await this.dataManager.ensureRelationships([task], ['TaskMaterial']);
            }

            // Look for completed tasks
            if (task.TaskMaterial && task.cv_TaskStatus && task.cv_TaskStatus?.IsEndState) {
                // All these materials have data
                for (const tm of task.TaskMaterial) {
                    // If the animal isn't in another cohort assigned to this Job
                    if (!this.animalIsInAnotherCohortOnThisTask(tm.C_Material_key, task, job)) {
                        hasData[tm.C_Material_key] = true;
                    }
                }
            }

            // Look for collected outputs
            if (task.TaskOutputSet) {
                for (const tos of task.TaskOutputSet) {
                    if (!tos.CollectionDateTime) {
                        // Not collected
                        continue;
                    }

                    if (!tos.TaskOutputSetMaterial) {
                        // No materials
                        continue;
                    }

                    // All these materials have data
                    for (const tosm of tos.TaskOutputSetMaterial) {
                        // If the animal isn't in another cohort assigned to this Job
                        if (!this.animalIsInAnotherCohortOnThisTask(tosm.C_Material_key, task, job)) {
                            hasData[tosm.C_Material_key] = true;
                        }
                    }
                }
            }
        }

        // Find the Materials with data
        return materials.filter((m) => {
            const key = m.C_Material_key;
            return hasData[key];
        });
    }

    private animalIsInAnotherCohortOnThisTask(materialKey: any, task: any, job: any): boolean {
        let groupTaskInstance: any = task;

        // Get the group task instance
        if (task.C_GroupTaskInstance_key) {
            groupTaskInstance = job.TaskJob.find((x: any) => x.C_TaskInstance_key === task.C_GroupTaskInstance_key);
            groupTaskInstance = groupTaskInstance.TaskInstance;
        }

        // Count the number of Cohorts that the animal belongs to on this task
        let numAnimalTaskCohorts = 0;
        for (const cohort of groupTaskInstance.TaskCohort) {
            if (cohort.Cohort.CohortMaterial.find((x: any) => x.C_Material_key === materialKey)) {
                numAnimalTaskCohorts += 1;
            }
        }

        // Return true if the animal is part of multiple cohorts assigned to this task
        return numAnimalTaskCohorts > 1;
    }

    /**
     * Remove the Animals from the Job tasks
     */
    private removeAnimalsFromTasks(job: any, animals: any[], taskFilter: TaskFilter) {
        if (!job.TaskJob) {
            // Nothing to do
            return;
        }
        // Create a lookup table of animals to remove
        const animalKeys: any = {};
        for (const animal of animals) {
            animalKeys[animal.C_Material_key] = true;
        }

        // Note: Array.slice() is used to copy the entity arrays when looping
        // since Breeze may be altering the arrays when the entities are
        // deleted.

        // Remove the Animals and output sets from tasks
        const tmpTaskJob = job.TaskJob.slice();
        for (const jt of tmpTaskJob) {
            const task = jt.TaskInstance;
            if (!task || !taskFilter(task)) {
                continue;
            }

            if (!task.IsGroup && task.C_GroupTaskInstance_key) {
                // Member tasks are created for each animal and should be
                // removed with the animal.

                // Check if all the task materials are being deleted
                let hasOtherMaterials = false;
                if (task.TaskMaterial) {
                    for (const tm of task.TaskMaterial) {
                        if (!animalKeys[tm.C_Material_key]) {
                            hasOtherMaterials = true;
                            break;
                        }
                    }
                }

                if (!hasOtherMaterials) {
                    // Delete the entire task if animal isn't part of another Cohort assigned to this task
                    if (!task.TaskMaterial[0] || !this.animalIsInAnotherCohortOnThisTask(task.TaskMaterial[0].C_Material_key, task, job)) {
                        this.workflowService.deleteTask(task);
                    }
                    continue;
                }
            }

            // Remove the Animals from TaskMaterial if not part of another Cohort assigned to this task
            if (task.TaskMaterial) {
                const tmpTaskMaterial = task.TaskMaterial.slice();
                for (const tm of tmpTaskMaterial) {
                    if (animalKeys[tm.C_Material_key] && !this.animalIsInAnotherCohortOnThisTask(tm.C_Material_key, task, job)) {
                        this.taskService.deleteTaskMaterial(tm);
                    }
                }
            }

            // Remove the OutputSets
            if (task.TaskOutputSet) {
                const tmpTaskOutputSet = task.TaskOutputSet.slice();
                for (const tos of tmpTaskOutputSet) {
                    if (!tos.TaskOutputSetMaterial) {
                        continue;
                    }

                    // Check if all the materials are being deleted
                    let hasOtherMaterials = false;
                    for (const tosm of tos.TaskOutputSetMaterial) {
                        if (!animalKeys[tosm.C_Material_key]) {
                            hasOtherMaterials = true;
                            break;
                        }
                    }
                    if (!hasOtherMaterials) {
                        // Delete the entire OutputSet if animal isn't part of another Cohort assigned to this task
                        if (!this.animalIsInAnotherCohortOnThisTask(task.TaskOutputSetMaterial[0].C_Material_key, task, job)) {
                            this.workflowService.deleteTaskOutputSet(tos);
                        }
                        continue;
                    }

                    // Remove the animals from the OutputSet if not part of another Cohort assigned to this task
                    const tmpTaskOutputSetMaterial = tos.TaskOutputSetMaterial.slice();
                    for (const tosm of tmpTaskOutputSetMaterial) {
                        if (animalKeys[tosm.C_Material_key] && !this.animalIsInAnotherCohortOnThisTask(tosm.C_Material_key, task, job)) {
                            this.workflowService.deleteTaskOutputSetMaterial(tosm);
                        }
                    }
                }
            }
        }
    }

    /**
     * Remove the Cohort from the Job tasks
     */
    private removeCohortFromTasks(job: any, cohort: any, taskFilter: TaskFilter) {
        if (!job.TaskJob) {
            // Nothing to do
            return;
        }

        const tmpTaskJob = job.TaskJob.slice();
        for (const jt of tmpTaskJob) {
            const task = jt.TaskInstance;
            if (!task || !taskFilter(task) || !task.IsGroup || !task.TaskCohort) {
                continue;
            }

            const tmpTaskCohort = task.TaskCohort.slice();
            for (const tc of tmpTaskCohort) {
                if (tc.C_Cohort_key === cohort.C_Cohort_key) {
                    this.taskService.deleteTaskCohort(tc);
                }
            }
        }
    }

    /**
     * Notify the user that some animals have data and therefore cannot be
     * removed from the job or task.
     */
    notifyAnimalsHaveSamples(animals: Animal[]): Promise<any> {
        if (!animals || (animals.length === 0)) {
            return Promise.resolve();
        }

        // Need to confirm that the data can be removed
        const confirmTitle = 'Animals with Data';
        const isDanger = false;

        let confirmMessage = (animals.length === 1)
            ? 'This animal has'
            : `These ${animals.length} animals have`;

        confirmMessage += ' completed tasks or collected data and cannot be removed.\n';

        // Add animal details to modal
        const names = animals.map((animal) => animal.AnimalName).join(', ');

        const confirmOptions: ConfirmOptions = {
            title: confirmTitle,
            message: confirmMessage,
            yesButtonText: 'OK',
            onlyYes: true,
            isDanger,
            details: [
                names,
            ]
        };

        return this.confirmService.confirm(confirmOptions);
    }

    /**
     * Notify the user that some cohorts have animals that have data and
     * therefore cannot be removed from the job or task.
     */
    notifyCohortsWithData(cohorts: any[]): Promise<any> {
        if (!cohorts || (cohorts.length === 0)) {
            return Promise.resolve();
        }

        // Need to confirm that the data can be removed
        const confirmTitle = 'Cohorts with Data';
        const isDanger = false;

        let confirmMessage = (cohorts.length === 1)
            ? 'This cohort has'
            : `These ${cohorts.length} cohorts have`;

        confirmMessage
            += ' animals with completed tasks or collected data and cannot be removed.\n';

        // Add cohort details to modal
        const names = cohorts.map((cohort) => cohort.CohortName).join(', ');

        const confirmOptions: ConfirmOptions = {
            title: confirmTitle,
            message: confirmMessage,
            yesButtonText: 'OK',
            onlyYes: true,
            isDanger,
            details: [
                names,
            ]
        };

        return this.confirmService.confirm(confirmOptions);
    }

    /**
     * Start a drag between tables
     *
     * @param type Entity type (e.g. 'Animal')
     * @param entities dragged entities
     * @returns unique ID for this drag.
     */
    startDrag(type: string, entities: any[]): number {
        const id = new Date().getTime();
        this.dragged = { id, type, entities };

        return id;
    }

    /**
     * Start a drag between tables
     *
     * @param type Entity type (e.g. 'Animal')
     * @param callback callback function that returns promise to resolve to entities
     * @returns unique ID for this drag.
     */
    startDragAsync(type: string, callback: () => Promise<any[]>): number {
        const id = new Date().getTime();
        this.dragged = { id, type, callback };

        return id;
    }


    /**
     * Gets dragged entities.
     */
    async getDragged(): Promise<DraggedEntities> {
        const dragged = this.dragged;
        if (!dragged) {
            return;
        }
        
        if (dragged.callback) {
            dragged.entities = await dragged.callback();
        }

        this.dragged = null;

        return dragged;
    }

    /**
     * Stop the current drag if the IDs match.
     *
     * @param id Drag ID returned by startDrag()
     */
    stopDrag(id: number) {
        if (this.dragged && (this.dragged.id === id)) {
            this.dragged = null;
        }
    }

    /**
     * Sort the tasks by DateDue, position in Protocol, and WorkflowTask.
     */
    sortTasksBySchedule(tasks: any[]) {
        // Build and caache the sort keys first
        tasks.forEach((task) => { task._sort = this.buildScheduleSortKey(task); });

        // Do the sort
        tasks.sort(this.compareTasksBySchedule);

        // Remove the sort keys
        tasks.forEach((task) => { delete task._sort; });
    }

    // Sort tasks without a DateDue after those with
    private readonly SORT_NO_DATEDUE = new Date(9999, 11, 31).getTime();
    // Sort tasks without a Sequence after those with
    private readonly SORT_NO_SEQUENCE = 9999999;

    /**
     * Assemble a sort key for each task.
     *
     * The sorting is complex and we don't wnat to have to redo this for every comparison.
     */
    private buildScheduleSortKey(task: any) {
        return [
            // Date Due
            task.DateDue ? task.DateDue.getTime() : this.SORT_NO_DATEDUE,
            // Sequence
            (task.TaskJob && task.TaskJob[0] && task.TaskJob[0].Sequence) || this.SORT_NO_SEQUENCE,
            // ProtocolInstance
            task.C_ProtocolInstance_key || 0,
            // ProtocolTask.SortOrder
            task.ProtocolTask ? task.ProtocolTask.SortOrder : 0,
            // WorkflowTask
            task.C_WorkflowTask_key || 0,
            // TaskInstance
            task.C_TaskInstance_key || 0
        ];
    }

    /**
     * Task sorting comparator.
     */
    private compareTasksBySchedule = (a: any, b: any): number => {
        // Find the first difference
        for (let i = 0; i < a._sort.length; ++i) {
            const rv = a._sort[i] - b._sort[i];
            if (rv !== 0) {
                return rv;
            }
        }

        // Well, they must be equal
        return 0;
    }

    /**
     * Start the busy indicator before a possibly lengthy process.
     *
     * Note: calls to this can be nested, just be careful to pair the starts with stops
     */
    busyStart() {
        ++this.busy;
        this.updateBusy();
    }

    /**
     * Stop the busy indicator
     */
    busyStop() {
        this.busy = Math.max(this.busy - 1, 0);
        this.updateBusy();
    }

    /**
     * Update the facet loading spinner based on the busy state.
     */
    private updateBusy() {
        this.facetLoadingStateService.changeLoadingState(this.busy > 0);
    }

    /**
     * return the materials in a task that do not have a source material in the
     * task.
     *
     * Note: This method assumes that Material.MaterialSourceMaterial has
     * already been expanded.
     *
     * This bundles the member tasks together.
     *
     * @param task
     */
    getTaskSourceMaterials(task: any): any[] {
        let sources: any[] = [];


        // Pass 1: Build a map of all the materials in this task
        const materials: { [key: string]: boolean } = {};
        if (task.IsGroup) {
            // Look through the member tasks for materials
            if (task.MemberTaskInstance) {
                for (const memberTask of task.MemberTaskInstance) {
                    if (memberTask.TaskMaterial) {
                        for (const tm of memberTask.TaskMaterial) {
                            materials[tm.C_Material_key] = true;
                        }
                    }
                }
            }
        } else if (task.TaskMaterial) {
            // Look through this task for materials
            for (const tm of task.TaskMaterial) {
                materials[tm.C_Material_key] = true;
            }
        }

        // Pass 2: Count the materials that don't have sources in this task
        if (task.IsGroup) {
            // Look through the member tasks for sources
            if (task.MemberTaskInstance) {
                for (const memberTask of task.MemberTaskInstance) {
                    if (memberTask.TaskMaterial) {
                        for (const tm of memberTask.TaskMaterial) {
                            // See if any source materials is in this task
                            let hasTaskSource = false;
                            if (tm.Material && tm.Material.MaterialSourceMaterial) {
                                hasTaskSource = tm.Material.MaterialSourceMaterial.some(
                                    (msm: any) => materials[msm.C_SourceMaterial_key]
                                );
                            }

                            if (!hasTaskSource && tm.Material !== null) {
                                sources.push(tm.Material);
                            }
                        }
                    }
                }
            }
        } else if (task.TaskMaterial) {
            // Look through this task for sources
            for (const tm of task.TaskMaterial) {
                // See if any source materials is in this task
                let hasTaskSource = false;
                if (tm.Material && tm.Material.MaterialSourceMaterial) {
                    hasTaskSource = tm.Material.MaterialSourceMaterial.some(
                        (msm: any) => materials[msm.C_SourceMaterial_key]
                    );
                }

                if (!hasTaskSource && tm.Material !== null) {
                    sources.push(tm.Material);
                }
            }
        }

        sources = uniqueArray(sources);

        return sources;
    }

    async createSampleGroups(create: CreateSampleGroupData, job: any): Promise<SampleGroup[]> {
        const sampleGroups: SampleGroup[] = [];
        const jobMaterialKeys = job.JobMaterial.filter((jm: any) => jm.Material.Animal).map((jm: any) => jm.C_Material_key);
        // Add  new SampleGroups to the tasks
        for (const task of create.tasks) {
            // Get time point value if task is associated with protocol
            let timePoint = {};
            if (task.ProtocolTask) {
                timePoint = {
                    C_TimeUnit_key: task.ProtocolTask.C_TimeUnit_key,
                    TimePoint: task.ProtocolTask.TimeFromRelativeTask
                };
            }
            for (const row of create.rows) {
                const newSampleGroup = this.dataManager.createEntity('SampleGroup', {
                    C_TaskInstance_key: task.C_TaskInstance_key,
                    C_ProtocolTask_key: null,
                    ...row,
                    ...timePoint,
                });

                sampleGroups.push(newSampleGroup);
                // Fill in animals data in sample group

                // Check for cohorts
                if (task.TaskCohort && task.TaskCohort.length > 0) {
                    task.TaskCohort.forEach((tc: any) => {
                        if (tc.Cohort && tc.Cohort.CohortMaterial && tc.Cohort.CohortMaterial.length > 0) {
                            tc.Cohort.CohortMaterial.forEach((cm: any) => {
                                if (jobMaterialKeys.includes(cm.C_Material_key)) {
                                    this.dataManager.createEntity('SampleGroupSourceMaterial', {
                                        C_SampleGroup_key: newSampleGroup.C_SampleGroup_key,
                                        C_Material_key: cm.C_Material_key,
                                        SampleGroup: newSampleGroup
                                    });
                                }
                            });
                        }
                    });
                }

                if (task.TaskMaterial && task.TaskMaterial.length > 0) {
                    task.TaskMaterial.forEach((tm: any) => {
                        if (tm.Material.Animal || tm.Material.Sample) {
                            this.dataManager.createEntity('SampleGroupSourceMaterial', {
                                C_SampleGroup_key: newSampleGroup.C_SampleGroup_key,
                                C_Material_key: tm.C_Material_key,
                                SampleGroup: newSampleGroup
                            });
                        }
                    });
                }

                // Check for placeholders
                if (task.TaskPlaceholder && task.TaskPlaceholder.length > 0) {
                    task.TaskPlaceholder.forEach((tp: any) => {
                        if (tp.AnimalPlaceholder) {
                            this.dataManager.createEntity('SampleGroupSourceMaterial', {
                                C_SampleGroup_key: newSampleGroup.C_SampleGroup_key,
                                C_AnimalPlaceholder_key: tp.C_AnimalPlaceholder_key,
                                SampleGroup: newSampleGroup
                            });
                        } else if (tp.Placeholder && tp.Placeholder.AnimalPlaceholder && tp.Placeholder.AnimalPlaceholder.length > 0) {
                            tp.Placeholder.AnimalPlaceholder.forEach((ap: any) => {
                                this.dataManager.createEntity('SampleGroupSourceMaterial', {
                                    C_SampleGroup_key: newSampleGroup.C_SampleGroup_key,
                                    C_AnimalPlaceholder_key: ap.C_AnimalPlaceholder_key,
                                    SampleGroup: newSampleGroup
                                });
                            });
                        }
                    });
                }
            }
        }
        await this.dataManager.saveEntities(['SampleGroup', 'SampleGroupSourceMaterial']);
        return sampleGroups;
    }

    /**
     * Create the new TaskMaterial associations for the samples in this sample group
     * @param sampleKeys
     * @param sampleGroupKey
     */
    createSampleGroupTaskAssociations(samples: any[], sampleGroup: any): Promise<any[]> {
        const sampleKeys = samples.map((item) => {
            return item.C_Material_key;
        });
        const request = {
            sampleKeys,
            sampleGroupKey: sampleGroup.C_SampleGroup_key
        };

        const url = 'api/jobpharma/createSampleGroupTaskAssociations';

        return this.webApiService.postApi(url, request).then(() => {
            return this.dataManager.ensureRelationships(samples, ['TaskMaterials']);
        }).then(() => {
            return uniqueArrayFromPropertyPath(samples, 'TaskMaterial');
        });
    }

    /**
     * Create the new TaskMaterial associations for the samples in this sample group
     * @param sampleKeys
     * @param sampleGroupKey
     */
    createAllSampleGroupTaskAssociations(batches: SampleBatch[]): Promise<any[]> {
        const listRequest: any[] = [];
        const allSamples: any[] = [];

        for (const batch of batches) {
            const sampleKeys = batch.newSamples.map((item: { C_Material_key: any; }) => {
                return item.C_Material_key;
            });
            const individualRequest = {
                sampleKeys,
                sampleGroupKey: batch.sampleGroup.C_SampleGroup_key
            };
            listRequest.push(individualRequest);
            for (const sample of batch.newSamples) {
                allSamples.push(sample);
            }
        }

        const request = {
            SampleGroupTaskAssociationList: listRequest
        };
        const url = 'api/jobpharma/createAllSampleGroupTaskAssociations';

        return this.webApiService.postApi(url, request).then(() => {
            return this.dataManager.ensureRelationships(allSamples, ['TaskMaterials']);
        }).then(() => {
            return uniqueArrayFromPropertyPath(allSamples, 'TaskMaterial');
        });
    }

    calculateNumberOfSamplesToCreate(sampleGroups: any): number {
        let numSamplesToCreate = 0;
        // Multiply sources * NumSamples per sample group
        for (const sampleGroup of sampleGroups) {
            const validSampleSources = sampleGroup.SampleGroupSourceMaterial.filter((sm: any) => sm.Material);
            numSamplesToCreate += (validSampleSources.length * sampleGroup.NumSamples);
        }

        return numSamplesToCreate;
    }

    /**
     * Make individual samples from sample groups
     */
    async createIndividualSamplesFromGroup(sampleGroup: SampleGroupExtended): Promise<Sample[]> {
        if (!sampleGroup) {
            // Nothing to do
            return [];
        }
        const sourceLabel = 'job-pharma-samples-group-table';
        if (hasAssociatedSamples(sampleGroup)) {
            this.loggingService.logWarning('Sample group has already been created', null, sourceLabel, true);
            return [];
        }

        let sources: Material[] = [];
        sources = sampleGroup.SampleGroupSourceMaterial
            .filter(sm => sm.Material)
            .map(sm => sm.Material);
        if (sources.length === 0) {
            // Warn user that sample groups need a source
            //  to be made into individual samples
            this.loggingService.logWarning('Sample group must have at least one source to create individual samples', null, sourceLabel, true);
            return [];
        }

        const newSamples: Sample[] = [];
        for (const source of sources) {
            const samples = await Promise.all(this.createNewSamples(sampleGroup, source));
            newSamples.push(...samples);
        }

        return newSamples;
    }

    /**
     * Gets sample groups that have samples and are associated with the provided task instances.
     */
    async getSampleGroupsWithSamples(tasks: TaskInstance[]): Promise<SampleGroupExtended[]> {
        const sampleGroups = new Set<SampleGroupExtended>();
        for (const task of tasks) {
            await this.dataManager.ensureRelationships([task], ['SampleGroup.Sample.Material.MaterialSourceMaterial']);

            for (const sampleGroup of task.SampleGroup) {
                if (!isEmpty(sampleGroup.Sample)) {
                    sampleGroups.add(sampleGroup as SampleGroupExtended);
                }
            }
        }

        return Array.from(sampleGroups);
    }

    async createNewSample(sampleGroup: SampleGroup, source?: PartialMaterial): Promise<Sample> {
        let newSample: any = null;
        let newMaterial: any = null;

        return this.materialService.createAsType('Sample').then((material) => {
            newSample = this.sampleService.create();
            newMaterial = material;
            newSample.Material = newMaterial;
            newMaterial.C_ContainerType_key = sampleGroup.C_ContainerType_key;
            if (source) {
                newMaterial.C_Taxon_key = source.C_Taxon_key;
                newMaterial.C_Line_key = source.C_Line_key;
            } else {
                // TODO: How do we get taxon and line key if there is no source material?
            }
            newSample.SampleName = null;
            newSample.C_SampleGroup_key = sampleGroup.C_SampleGroup_key;
            newSample.C_SampleType_key = sampleGroup.C_SampleType_key;
            newSample.C_SampleStatus_key = sampleGroup.C_SampleStatus_key;
            newSample.C_PreservationMethod_key = sampleGroup.C_PreservationMethod_key;
            newSample.DateHarvest = sampleGroup.DateHarvest;
            newSample.DateExpiration = sampleGroup.DateExpiration;
            newSample.TimePoint = sampleGroup.TimePoint;
            newSample.C_TimeUnit_key = sampleGroup.C_TimeUnit_key;
            newSample.C_SampleSubtype_key = sampleGroup.C_SampleSubtype_key;
            newSample.C_SampleProcessingMethod_key = sampleGroup.C_SampleProcessingMethod_key;
            newSample.SendTo = sampleGroup.SendTo;
            newSample.C_SampleAnalysisMethod_key = sampleGroup.C_SampleAnalysisMethod_key;
            newSample.SpecialInstructions = sampleGroup.SpecialInstructions;
            newSample.SampleCharacteristics = [];
            newSample.cv_Unit = this.cvUnitDefault;
        }).then(() => {
            this.addMaterialSourceMaterial(newSample, source);
            return newSample;
        });
    }

    /**
     * Creates samples from the provided sample group
     */
    createNewSamples(sampleGroup: SampleGroup, source: PartialMaterial, num = 0) {
        const newSamples: Promise<Sample>[] = [];
        const numSamples = num || sampleGroup.NumSamples;
        // If there are sources, make numSample*samples per source
        for (let i = 0; i < numSamples; i++) {
            // Create new sample record
            newSamples.push(this.createNewSample(sampleGroup, source));
        }

        return newSamples;
    }

    /**
     * Add Samples to the job
     */
    addSamplesToJob(job: any, samples: any[]) {
        const jobMaterials = [];
        for (const sample of samples) {
            const jobMaterial = this.jobService.createJobMaterial({
                C_Job_key: job.C_Job_key,
                C_Material_key: sample.C_Material_key,
                DateIn: new Date(),
                DateModified: new Date(),
                Version: 0
            });

            sample.isSelected = false;

            if (jobMaterial) {
                jobMaterials.push(jobMaterial);
            }
        }

        return jobMaterials;
    }

    private addMaterialSourceMaterial(sample: Sample, sourceMaterial: PartialMaterial) {
        const initialValues = {
            C_Material_key: sample.C_Material_key,
            C_SourceMaterial_key: sourceMaterial.C_Material_key
        };
        const newEntity = this.sampleService.createMaterialSourceMaterial(initialValues);

        if (newEntity) {
            sample.Material.MaterialSourceMaterial.push(newEntity);
        }

        return newEntity;
    }

    scheduleTask(tasks: any[], dateDueChanged: boolean, userName: any, userKey: any): Promise<any> {
        return this.workflowService.completeTasks(tasks, userName, userKey, null, null, dateDueChanged, false, false, true);
    }

    bulkAssignTo(taskInstanceKeys: any, resourceKeys: any[]): Promise<any> {
        return this.workflowService.assignResources(taskInstanceKeys, resourceKeys);
    }

    updatePlaceholderNames(job: ExtendedJob) {
        for (const jobGroup of job.JobGroup) {
            this.updatePlaceholderName(job, jobGroup);
        }
    }

    updatePlaceholderName(job: ExtendedJob, jobGroup: JobGroup) {
        const placeholders = job.Placeholder.filter((placeholder: any) => placeholder.C_JobGroup_key === jobGroup.C_JobGroup_key);
        if (placeholders && placeholders.length > 0) {
            placeholders[0].PlaceholderName = job.JobID + "_G" + jobGroup.Group;
            // if there are AnimalPlaceholder in jobGroup update their names
            for (let i = 0; i < jobGroup.AnimalPlaceholder.length; i++) {
                jobGroup.AnimalPlaceholder[i].Name = `${placeholders[0].PlaceholderName}_${i + 1}`;
            }
        }
    }

    checkIfJobGroupTreatmentUsedInInputs(jobGroupTreatmentKey: any): Promise<any> {
        const apiUrl = 'api/jobdata/isjobgrouptreatmentused/' +
            jobGroupTreatmentKey;
        return this.webApiService.callApi(apiUrl);
    }

    changeJobLock(job: ExtendedJob){
        // Lock all tasks when the job is locked
        if (job.IsLocked === true) {
            for (const taskJob of job.TaskJob) {
                taskJob.TaskInstance.IsLocked = true;
            }
        }
        // Force a table update
        this.notifyJobArrayChanged('TaskJob');
    }

    calculateTotalNumberOfSamples(sampleGroups: SampleGroup[], numberOfSources: number): number {
        const total = sampleGroups.reduce((acc, cur) => acc + cur.NumSamples ?? 0, 0) * numberOfSources;
        return total;
    }

    showCreateSamplesModal(total: number) {
        return this.dialogService.confirmYesNo({
            yesButtonTitle: "Continue",
            noButtonTitle: "Cancel",
            title: "Creating Sample Records",
            bodyText: `Create ${total} ${pluralize(total, 'sample')}?`
        });
    }

    showCompletedTasksModal(): Promise<any> {
        return this.confirmService.confirm({
            yesButtonText: "Ok",
            title: "Completed Tasks",
            onlyYes: true,
            message: "You cannot add animals/samples to completed tasks with samples created from sample groups. To take additional samples, please create a new task with one or more sample groups."
        });
    }

    /**
     *
     * Will handle creating samples from the provided sample groups and sources
     *
     * If any member task instance related to the sample groups is set to end state, no samples
     * will be created.
     *
     * @param sampleGroups Sample groups to create samples from
     * @param sources Sources to create samples from
     * @param notifyAboutChanges Notifies subscriptions about updated samples
     * @returns Samples that were created
     */
    async handleSampleCreates(sampleGroups: SampleGroupExtended[], sources: PartialMaterial[], notifyAboutChanges = true): Promise<Sample[] | null> {
        if (isEmpty(sampleGroups) || isEmpty(sources) || this.sampleGroupsTasksHasEndState(sampleGroups)) {
            return;
        }

        const sampleBatches = await this.createSamplesFromSampleGroups(sampleGroups, sources);
        if (!isEmpty(sampleBatches)) {
            await this.addSampleAssociations(sampleBatches, notifyAboutChanges);
        }

        return sampleBatches.flatMap(sb => sb.newSamples);
    }

    /**
     * Will return materials that are not source materials to any of the samples within
     * the provided sample groups.
     */
    getNonSampleSourceMaterials(sampleGroups: SampleGroup[], materials: PartialMaterial[]): PartialMaterial[] {
        const nonSampleSourceMaterials: PartialMaterial[] = [];
        const sourceMaterials = sampleGroups
            .flatMap(sg => sg.Sample)
            .flatMap(sample => sample.Material?.MaterialSourceMaterial)
            .filter(sm => sm);
        for (const material of materials) {
            if (!sourceMaterials.find(sm => sm.C_SourceMaterial_key === material.C_Material_key)) {
                nonSampleSourceMaterials.push(material);
            }
        }

        return nonSampleSourceMaterials;
    }

    /**
     * Make samples from sample groups for the provided sources and returns batches of new samples
     * grouped by sample group.
     */
    async createSamplesFromSampleGroups(sampleGroups: SampleGroup[], sources: PartialMaterial[]) {
        const sampleBatches: SampleBatch[] = [];
        for (const sampleGroup of sampleGroups) {
            const newSamples: Sample[] = [];
            const currentBatch = { sampleGroup, newSamples };
            for (const source of sources) {
                const samples = await Promise.all(this.createNewSamples(sampleGroup, source));
                currentBatch.newSamples.push(...samples);
            }
            sampleBatches.push(currentBatch);
        }

        return sampleBatches;
    }

    /**
     * Adds task and job associations related to the provided sample batches
     * @param sampleBatches New samples to be saved
     * @param notifyAboutChanges Notifies subscriptions about updated samples
     */
    async addSampleAssociations(sampleBatches: SampleBatch[], notifyAboutChanges = true) {
        const materials = sampleBatches
            .flatMap(sb => sb.newSamples)
            .map(newSample => newSample.Material);
        await this.dataContextService.saveSingleRecordBatch(materials as Entity<Material>[]);

        const materialSourceMaterials = materials
            .flatMap(material => material.MaterialSourceMaterial);
        await this.dataContextService.saveSingleRecordBatch(materialSourceMaterials as Entity<MaterialSourceMaterial>[]);
        
        const samples = sampleBatches.flatMap(sb => sb.newSamples)
        await this.dataContextService.saveSingleRecordBatch(samples as Entity<Sample>[]);
        

        await this.createAllSampleGroupTaskAssociations(sampleBatches);

        if (notifyAboutChanges) {
            this.notifyJobArrayChanged('TaskJob');
            this.notifyJobArrayChanged('JobMaterial');
        }
        return samples;
    }

    async handleSampleAssociationDeletes(sampleGroups: SampleGroupExtended[], sources?: Material[], notify = false) {
        const samples = this.getSamplesFromSampleGroups(sampleGroups, sources);
        if (!samples || samples.length === 0) {
            return [];
        }

        if (this.sampleGroupsTasksHasEndState(sampleGroups)) {
            await this.showTasksCompletedModal();
            return;
        }

        const confirm = await this.showDeleteSamplesModal(samples);
        if (!confirm) {
            if (notify) {
                await this.showNotifyMaterialsWithDataModal(sources);
            }
            return;
        }

        return this.deleteSampleAssociations(samples);
    }

    showTasksCompletedModal() {
        return this.dialogService.confirmYes({
            yesButtonTitle: "OK",
            title: "Tasks Completed",
            bodyText: "You cannot edit tasks or sample groups if any tasks are set to an end state."
        });
    }

    private showDeleteSamplesModal(samples: Sample[]) {
        return this.dialogService.confirmYesNo({
            yesButtonTitle: "Continue",
            noButtonTitle: "Cancel",
            title: "Deleting Sample Records",
            bodyText: `Delete ${samples.length} ${pluralize(samples.length, 'sample')}?`
        });
    }

    showNotifyMaterialsWithDataModal(sources: Material[]) {
        const isAnimals = sources.some(s => s.Animal);
        const isSamples = sources.some(s => s.Sample);

        let materialText = '';
        if (isAnimals && isSamples) {
            materialText = 'Animals & Samples';
        } else if (isAnimals) {
            materialText = pluralize(sources.length, 'Animal');
        } else if (isSamples) {
            materialText = pluralize(sources.length, 'Sample');
        }
        const title = materialText + ' With Data';

        let message = `${pluralize(sources.length, 'This', 'These')} ${lowerCase(materialText)} ${pluralize(sources.length, 'has', 'have')} completed tasks or collected data and cannot be removed:`;
        if (isAnimals) {
            message += `\n${pluralize(sources.length, 'Animal')}: ${sources.filter(s => s.Animal).map(a => a.Animal.AnimalName).join(', ')}`;
        }
        if (isSamples) {
            message += `\n${pluralize(sources.length, 'Sample')}: ${sources.filter(s => s.Sample).map(a => a.Sample.SampleName).join(', ')}`;
        }

        return this.dialogService.confirmYes({
            yesButtonTitle: "OK",
            title,
            bodyText: message
        });
    }

    /**
     * Deletes task and job associations of the provided samples respective to their sample groups.
     * This will also delete extra task instances that were created by samples.
     */
    async deleteSampleAssociations(samples: Sample[]) {
        const samplesWithAssociationsDeleted: Sample[] = [];
        for (const sample of samples) {
            const groupTask = sample?.SampleGroup?.TaskInstance;
            if (!groupTask) {
                continue;
            }

            const sourceMaterialKeys = new Set(sample.Material.MaterialSourceMaterial.map(msm => msm.C_SourceMaterial_key));

            if (!sample.Material.TaskMaterial?.length) {
                await this.dataManager.ensureRelationships([sample], ['Material.TaskMaterial']);
                const taskInstancesToLoadTaskMaterials = sample.Material.TaskMaterial
                    .map(tm => tm.TaskInstance)
                    .filter(ti => ti.C_GroupTaskInstance_key === groupTask.C_TaskInstance_key);
                if (taskInstancesToLoadTaskMaterials?.length) {
                    await this.dataManager.ensureRelationships(taskInstancesToLoadTaskMaterials, ['TaskMaterial'])
                }
            }

            const taskMaterial = sample.Material.TaskMaterial.find(({ TaskInstance: ti }) => {
                return ti.C_GroupTaskInstance_key === groupTask.C_TaskInstance_key
                    && ti.TaskMaterial.some(tm => tm.C_Material_key === sample.C_Material_key)
                    && ti.TaskMaterial.some(tm => sourceMaterialKeys.has(tm.C_Material_key));
            });

            if (taskMaterial) {
                const taskInstanceToDelete = groupTask.MemberTaskInstance.find((taskInstance: TaskInstance) => taskInstance.C_TaskInstance_key === taskMaterial.C_TaskInstance_key);
                if (taskInstanceToDelete) {
                    const memberTaskInstancesRemaining = groupTask.MemberTaskInstance.filter(mti => {
                        return mti.TaskMaterial.some(tm => sourceMaterialKeys.has(tm.C_Material_key))
                            && mti.TaskMaterial
                                .filter(tm => tm.Material.MaterialSourceMaterial.length)
                                .flatMap(tm => tm.Material.MaterialSourceMaterial)
                                .some(msm => sourceMaterialKeys.has(msm.C_SourceMaterial_key));
                    });

                    if (memberTaskInstancesRemaining.length > 1) {
                        this.workflowService.deleteTask(taskInstanceToDelete);
                    }
                }

                this.taskService.deleteTaskMaterial(taskMaterial);
            }

            const job = groupTask.TaskJob[0].Job;
            const jobMaterial = job.JobMaterial.find(jm => jm.C_Material_key === sample.C_Material_key);
            if (jobMaterial) {
                const groupTasks = job.TaskJob.filter(tj => tj.TaskInstance.IsGroup);
                const taskMaterials = groupTasks.flatMap(tj => tj.TaskInstance.TaskMaterial);
                // If the material is assigned to any group task, do not delete job material
                if (!taskMaterials.find(tm => tm.C_Material_key === sample.C_Material_key)) {
                    this.jobService.deleteJobMaterial(jobMaterial);
                }
            }

            sample.C_SampleGroup_key = null;
            samplesWithAssociationsDeleted.push(sample);
        }

        return samplesWithAssociationsDeleted;
    }

    async handlePendingSampleGroupChanges(changes: SampleGroupChangesMap) {
        const sampleGroupKeys = Object.keys(changes).map(Number);
        const confirm = await this.showModifySamplesModal(changes);
        if (!confirm) {
            this.cancelPendingSampleGroupChanges(sampleGroupKeys);
            return false;
        }
        
        const sampleBatches: SampleBatch[] = [];
        for (const key in changes) {
            if (!key) {
                continue;
            }
            const change = changes[key];
            const action = this.getSampleGroupChangeAction(change);
            const sources = change.sampleGroup.SampleGroupSourceMaterial.filter(sm => sm.C_Material_key);

            for (const source of sources) {
                switch (action) {
                    case ChangeAction.CREATE:
                        const sampleBatch = await this.createSamplesFromPendingSampleGroupChange(change, source.Material);
                        if (sampleBatch) {
                            sampleBatches.push(sampleBatch);
                        }
                        break;
                    case ChangeAction.DELETE:
                        this.deleteSamplesFromPendingSampleGroupChange(change, source.Material);
                        break;
                }

            }

            const editKeys = this.getEditKeysFromPendingSampleGroupChange(change);
            if (!editKeys || editKeys.length === 0) {
                continue;
            }
            this.editSamplesFromPendingSampleGroupChange(change);
        }

        if (sampleBatches.length > 0) {
            await this.addSampleAssociations(sampleBatches);
        }

        this.notifyJobArrayChanged('TaskJob');
        return true;
    }

    private showModifySamplesModal(changes: SampleGroupChangesMap) {
        const values = Object.values(changes) as any[];
        if (!values || values.length === 0) {
            return;
        }

        let createCount = 0;
        let deleteCount = 0;
        let editCount = 0;
        let bodyText = '';
        for (const key in changes) {
            if (!key) {
                continue;
            }
            const change = changes[key];
            const action = this.getSampleGroupChangeAction(change);
            const deltaNumSamples = this.getChangeInNumSamples(change.sampleGroup, change?.NumSamples);
            switch (action) {
                case ChangeAction.CREATE:
                    createCount += deltaNumSamples;
                    break;
                case ChangeAction.DELETE:
                    deleteCount +=  Math.abs(deltaNumSamples);
                    break;
                case ChangeAction.EDIT:
                    editCount += change.sampleGroup.Sample.length;
                    break;
                default:
                    continue;
            }
        }

        if (createCount) {
            bodyText += `
                ${ChangeAction.CREATE} ${createCount} ${pluralize(createCount, 'sample')}?
            `;
        }

        if (deleteCount) {
            bodyText += `
                ${ChangeAction.DELETE} ${deleteCount} ${pluralize(deleteCount, 'sample')} from sample group?
            `;
        }

        if (editCount) {
            bodyText += `
                ${ChangeAction.EDIT} ${editCount} ${pluralize(editCount, 'sample')}?
            `;
        }

        return this.dialogService.confirmYesNo({
            yesButtonTitle: "Continue",
            noButtonTitle: "Cancel",
            title: "Modify Sample Records",
            bodyText
        });
    }


    private getSampleGroupChangeAction(change: SampleGroupChange) {
        const numSamples = this.getChangeInNumSamples(change.sampleGroup, change?.NumSamples);
        if (numSamples > 0) {
            return ChangeAction.CREATE;
        } else if (numSamples < 0) {
            return ChangeAction.DELETE;
        }
        return ChangeAction.EDIT;
    }

    private cancelPendingSampleGroupChanges(sampleGroupKeys: number[]) {
        this.dataManager.rejectChangesToEntityByFilter('SampleGroup', (item: SampleGroup) => {
            return sampleGroupKeys.includes(item.C_SampleGroup_key);
        });
        
        this.loggingService.logWarning("SampleGroup changes have been discarded.", null, this.COMPONENT_LOG_TAG, true);
    }

    /**
     * Gets the difference between current samples associated with a sample group from the number of
     * samples per source multiplied by the number of sample group source materials.
     *
     * Ex:
     * - A sample group has 5 sample group source materials
     * - The number of samples per source is 2 and so that equates to 10 samples in total (Assuming individual samples have already been created)
     * - If the number of samples per source is changed to 3, then the delta number of samples is +5
     * - If the number of samples per source is changed to 1, then the delta number of samples is -5
     */
    getChangeInNumSamples(sampleGroup: SampleGroup, numSamples?: number) {
        if (numSamples === null || numSamples === undefined) {
            return 0;
        }
        const sources = sampleGroup.SampleGroupSourceMaterial.filter(sm => sm.C_Material_key);
        const samples = sampleGroup.Sample;
        return numSamples * sources.length - samples.length;
    }

    private async createSamplesFromPendingSampleGroupChange(change: SampleGroupChange, source: Material) {
        const samples = this.getSamplesFromSampleGroup(change.sampleGroup, [source]);
        const numToAdd = change.NumSamples - samples.length;
        if (numToAdd <= 0) {
            return;
        }
        const newSamples = await Promise.all(this.createNewSamples(change.sampleGroup, source, numToAdd));
        const sampleBatch: SampleBatch = {sampleGroup: change.sampleGroup, newSamples};
        return sampleBatch;
    }

    private async deleteSamplesFromPendingSampleGroupChange(change: SampleGroupChange, source: Material) {
        const samples = this.getSamplesFromSampleGroup(change.sampleGroup, [source]);
        const numToDelete = samples.length - change.NumSamples;
        if (numToDelete <= 0) {
            return;
        }
        const samplesToDelete = samples.slice(0, numToDelete);
        await this.deleteSampleAssociations(samplesToDelete);
    }

    private getEditKeysFromPendingSampleGroupChange(change: SampleGroupChange) {
        return Object.keys(change).filter(key => key !== 'sampleGroup' && key !== 'NumSamples');
    }

    private editSamplesFromPendingSampleGroupChange(change: SampleGroupChange) {
        const samples = change.sampleGroup.Sample;
        for (const sample of samples) {
            if (change.C_ContainerType_key !== undefined) {
                sample.Material.C_ContainerType_key = change.C_ContainerType_key;
            }
            if (change.C_PreservationMethod_key !== undefined) {
                sample.C_PreservationMethod_key = change.C_PreservationMethod_key;
            }
            if (change.C_SampleAnalysisMethod_key !== undefined) {
                sample.C_SampleAnalysisMethod_key = change.C_SampleAnalysisMethod_key;
            }
            if (change.C_SampleProcessingMethod_key !== undefined) {
                sample.C_SampleProcessingMethod_key = change.C_SampleProcessingMethod_key;
            }
            if (change.C_SampleStatus_key !== undefined) {
                sample.C_SampleStatus_key = change.C_SampleStatus_key;
            }
            if (change.C_SampleSubtype_key !== undefined) {
                sample.C_SampleSubtype_key = change.C_SampleSubtype_key;
            }
            if (change.C_SampleType_key !== undefined) {
                sample.C_SampleType_key = change.C_SampleType_key;
            }
            if (change.C_TimeUnit_key !== undefined) {
                sample.C_TimeUnit_key = change.C_TimeUnit_key;
            }
            if (change.DateExpiration !== undefined) {
                sample.DateExpiration = change.DateExpiration;
            }
            if (change.DateHarvest !== undefined) {
                sample.DateHarvest = change.DateHarvest;
            }
            if (change.SendTo !== undefined) {
                sample.SendTo = change.SendTo;
            }
            if (change.TimePoint !== undefined) {
                sample.TimePoint = change.TimePoint;
            }
            if (change.SpecialInstructions !== undefined) {
                sample.SpecialInstructions = change.SpecialInstructions;
            }
        }
    }

    /**
     * Get samples within sample groups.
     * If source materials are provided, this will return the samples with matching source materials.
     */
    private getSamplesFromSampleGroups(sampleGroups: SampleGroup[], sources?: Material[]) {
        const samples: Sample[] = [];
        for (const sampleGroup of sampleGroups) {
            samples.push(...this.getSamplesFromSampleGroup(sampleGroup, sources));
        }

        return samples;
    }

    /**
     * Get samples within a sample group.
     * If source materials are provided, this will return the samples with matching source materials.
     */
    private getSamplesFromSampleGroup(sampleGroup: SampleGroup, sources?: Material[]) {
        if (!sources || sources.length === 0) {
            return sampleGroup.Sample;
        }

        const sourceMaterialKeys = sources
            .map(sm => sm.C_Material_key);

        const samples: Sample[] = [];
        for (const sample of sampleGroup.Sample) {
            const sampleSourceMaterialKeys = sample.Material.MaterialSourceMaterial.map(sm => sm.C_SourceMaterial_key);
            if (sourceMaterialKeys.some(key => sampleSourceMaterialKeys.includes(key))) {
                samples.push(sample);
            }
        }

        return samples;
    }

    sampleGroupsTasksHasEndState(sampleGroups: SampleGroupExtended[]) {
        return sampleGroups.some(sg => hasEndStateTasks(sg));
    }

    /**
     * All cohort members get added to the job or removed from the job,
     *      so load them into memory and refresh them for display
     */
    refreshMaterialsFromCohort(cohort: Cohort): Promise<any> {
        return this.cohortService.ensureMaterialsExpanded([cohort]).then(() => {
            const materialKeys = uniqueArrayFromPropertyPath(
                cohort, 'CohortMaterial.C_Material_key'
            );
            return this.dataManager.refreshEntityCollection(
                'Material', 'JobMaterial', materialKeys
            );
        });
    }

    async addCohortsToJob(job: (Job & ExtendedJob), cohorts: (Cohort & CohortExtended)[]) {
        if (!this.isJobSaved(job)) {
            return [];
        }

        const jobCohorts: JobCohort[] = [];
        for (const cohort of cohorts) {
            const jobCohort = this.jobService.createJobCohort({
                C_Job_key: job.C_Job_key,
                C_Cohort_key: cohort.C_Cohort_key,
                Sequence: job.JobCohort.length + 1
            });

            cohort.isSelected = false;

            if (!jobCohort) {
                continue;
            }
            jobCohorts.push(jobCohort);
        }

        if (this.getIsGLPFlag()) {
            const animals = cohorts
                .flatMap(c => c.CohortMaterial)
                .map(cm => cm.Material?.Animal)
                .filter(a => a);
            const existingJobMaterials = await this.jobLogicService.transferAnimalToJob(animals, job);
            switch (existingJobMaterials[0]) {
                case 'no animals':
                    return [];
                case 'cannot remove':
                    this.loggingService.logWarning("Animals with active tasks cannot be removed from a job without a Default Auto End State task status", "", this.COMPONENT_LOG_TAG, true);
                case 'modal cancelled':
                    this.dataManager.deleteEntity(jobCohorts);
                    return [];
                default:
                    for (const jobMaterial of existingJobMaterials) {
                        (<JobMaterial> jobMaterial).DateOut = new Date();
                    }
            }
        }

        return jobCohorts;
    }

    async addMaterialsToJob(job: (Job & ExtendedJob), materials: Material[]) {
        if (!this.isJobSaved(job)) {
            return;
        }

        const jobMaterials: JobMaterial[] = [];
        for (const material of materials) {
            const jobMaterial = this.jobService.createJobMaterial({
                C_Job_key: job.C_Job_key,
                C_Material_key: material.C_Material_key,
                DateIn: new Date(),
                DateOut: null,
            });

            if (!jobMaterial) {
                continue;
            }
            if (job.JobCohort?.length && material.Animal) {
                this.addAnimalToJobCohort(job, material.Animal as any);
            }
            jobMaterials.push(jobMaterial);
        }

        if (this.getIsGLPFlag()) {
            const animals = materials.map(m => m.Animal).filter(a => a);
            const existingJobMaterials = await this.jobLogicService.transferAnimalToJob(animals, job);
            switch (existingJobMaterials[0]) {
                case 'no animals':
                    break;
                case 'cannot remove':
                    this.loggingService.logWarning("Animals with active tasks cannot be removed from a job without a Default Auto End State task status", "", this.COMPONENT_LOG_TAG, true);
                case 'modal cancelled':
                    this.dataManager.deleteEntity(jobMaterials);
                    return [];
                default:
                    for (const jobMaterial of existingJobMaterials) {
                        (<JobMaterial> jobMaterial).DateOut = new Date();
                    }
            }
        }

        return jobMaterials;
    }

    private addAnimalToJobCohort(job: ExtendedJob, animal: (Animal & AnimalExtended)): void {
        const filteredTaskJobs = job.TaskJob.filter(taskJob => {
            return taskJob.TaskInstance.TaskCohort.find(taskCohort => {
                return taskCohort.Cohort.CohortMaterial.find((cohortMaterial: CohortMaterial) => {
                    return cohortMaterial.Material.Animal && cohortMaterial.C_Material_key === animal.C_Material_key;
                });
            });
        });
        if (filteredTaskJobs.length) {
            for (const taskJob of filteredTaskJobs) {
                this.jobService.addMaterialToJob(taskJob, animal);
            }
            this.notifyJobArrayChanged('JobCohort');
            this.notifyJobArrayChanged('TaskJob');
        }
    }

    isSampleGroupsEditableFlag() {
        const flag = this.featureFlagService.getFlag("AB_test_sample_groups_remain_editable");
        return flag && (flag.Value.toLowerCase() === 'true') && (flag.IsActive === true);
    }

    public async loadProtocolInstanceKeysForJob(jobKey: string): Promise<QueryResult> {
        let loadProtocolInstancesQuery: EntityQuery = new EntityQuery('TaskInstances');
        const belongsToStudyPredicate = Predicate.create("TaskJob", "any", "C_Job_key", "eq", jobKey);
        const predicate = belongsToStudyPredicate
            .and("IsGroup", "eq", "true");
        loadProtocolInstancesQuery = loadProtocolInstancesQuery.where(predicate)
            .select('ProtocolInstance.C_ProtocolInstance_key')
            .expand('ProtocolInstance');
        return await this.dataManager.executeQuery(loadProtocolInstancesQuery);
    }

    public async loadTaskInstances(jobKey: number): Promise<QueryResult> {
        // TODO: consider usage of select statement
        let loadTaskInstancesQuery: EntityQuery = new EntityQuery('TaskInstances');
        const belongsToStudyPredicate = Predicate.create("TaskJob", "any", "C_Job_key", "eq", jobKey);
        loadTaskInstancesQuery = loadTaskInstancesQuery.where(belongsToStudyPredicate);
        return await this.dataManager.executeQuery(loadTaskInstancesQuery);
    }

    async loadTaskJobs(jobKey: number): Promise<QueryResult> {
        let query: EntityQuery = new EntityQuery('TaskJobs');
        const jobIDPredicate = Predicate.create('C_Job_key', 'eq', jobKey);
        query = query.where(jobIDPredicate);
        return await this.dataManager.executeQuery(query);
    }

    loadJobStandardPhrases(jobKey: number) {
        const predicate = Predicate.create('C_Job_key', 'eq', jobKey);
        const query = new EntityQuery('JobStandardPhrases').where(predicate);
        return this.dataManager.executeQuery(query);
    }

    loadJobVariablePhrases(jobKey: number) {
        const predicate = Predicate.create('C_Job_key', 'eq', jobKey);
        const query = new EntityQuery('JobVariablePhrases').where(predicate);
        return this.dataManager.executeQuery(query);
    }
    
    removeJobMaterial(jobMaterial: JobMaterial) {
        const sample = jobMaterial.Material?.Sample;
        if (sample) {
            return this.removeSampleFromJob(jobMaterial);
        }
    }

    private async removeSampleFromJob(jobMaterial: JobMaterial) {
        const job = jobMaterial.Job;
        const sample = jobMaterial.Material?.Sample;
        if (!sample) {
            return;
        }
        
        /**
         * Check if the sample being removed is a source material to other samples within the job.
         * If so, prompt to delete.
         */
        if (this.isSampleGroupsEditableFlag()) {
            const taskInstances = job.TaskJob.flatMap(tj => tj.TaskInstance).filter(ti => ti.IsGroup);
            const sampleGroupsWithSamples = await this.getSampleGroupsWithSamples(taskInstances);
            const samplesWithAssociationsDeleted = await this.handleSampleAssociationDeletes(sampleGroupsWithSamples, [jobMaterial.Material], true);
            if (!samplesWithAssociationsDeleted) {
                return;
            }

            const sampleGroupKey = sample.C_SampleGroup_key;
            if (sampleGroupsWithSamples.find(sg => sg.C_SampleGroup_key === sampleGroupKey)) {
                await this.deleteSampleAssociations([sample]);
            }
        }

        // Remove the Sample from the Job and from Task Instances
        await Promise.all(job.TaskJob.map(taskJob => this.taskService.deleteSampleFromTask(taskJob, sample)));
        this.jobService.deleteJobMaterial(jobMaterial);

        this.tabRefresh('samples', 'individuals');
        this.tabRefresh('tasks', 'list');

        return Promise.resolve(true);
    }

    async removeAnimal(job: (Job & ExtendedJob), task: TaskInstance, animal: Animal) {
        const sampleGroupsWithSamples = await this.getSampleGroupsWithSamples([task]);
        const isSampleGroupsEditableFlag = this.isSampleGroupsEditableFlag();

        if (isSampleGroupsEditableFlag) {
            const samplesWithAssociationsDeleted = await this.handleSampleAssociationDeletes(sampleGroupsWithSamples, [animal.Material], true);
            if (!samplesWithAssociationsDeleted) {
                return;
            }
        }

        const materialIsRelatedToSampleGroupWithSamples = this.isMaterialRelatedToSampleGroupWithSamples(animal.Material, task);
        if (materialIsRelatedToSampleGroupWithSamples && ((isSampleGroupsEditableFlag && isAnyTaskEndState(task.MemberTaskInstance)) || !isSampleGroupsEditableFlag)) {
            return this.showNotifyMaterialsWithDataModal([animal.Material]);
        }

        const result = await this.tryRemoveAnimalsFromTasks(job, [animal], [task]);
        if (result.withData.length > 0) {
            return this.notifyAnimalsHaveSamples(result.withData);
        }

        const taskPlaceholder = task.TaskPlaceholder.find((tp: any) => tp.AnimalPlaceholder.C_Material_key === animal.C_Material_key);
        if (taskPlaceholder) {
            this.removeAnimalPlaceholder(taskPlaceholder, task);
        }

        this.tabRefresh('tasks', 'list');
        this.tabRefresh('samples', 'groups');
    }

    async removeCohort(job: (Job & ExtendedJob), task: TaskInstance, cohort: Cohort, taskCohort: TaskCohort) {
        const sampleGroupsWithSamples = await this.getSampleGroupsWithSamples([task]);
        const materialsWithSamples: Material[] = [];
        const isSampleGroupsEditableFlag = this.isSampleGroupsEditableFlag();

        for (const cohortMaterial of cohort.CohortMaterial) {
            const materialIsRelatedToSampleGroupWithSamples = this.isMaterialRelatedToSampleGroupWithSamples(cohortMaterial.Material, task);
            if (materialIsRelatedToSampleGroupWithSamples) {
                materialsWithSamples.push(cohortMaterial.Material);
            }
        }

        if (materialsWithSamples.length && isSampleGroupsEditableFlag) {
            const samplesWithAssociationsDeleted = await this.handleSampleAssociationDeletes(sampleGroupsWithSamples, materialsWithSamples, true);
            if (!samplesWithAssociationsDeleted) {
                return;
            }
        } else if (materialsWithSamples.length > 0  && ((isSampleGroupsEditableFlag && isAnyTaskEndState(task.MemberTaskInstance)) || !isSampleGroupsEditableFlag)) {
            return this.showNotifyMaterialsWithDataModal(materialsWithSamples)
        }

        const result = await this.tryRemoveCohortsFromTasks(job, [cohort], [task]);
        if (result.withData.length > 0) {
            return this.notifyCohortsWithData(result.withData);
        }

        const placeholder = this.getPlaceholderFromCohort(cohort, job.C_Job_key);
        if (placeholder && placeholder.TaskPlaceholder.length > 0) {
            this.removePlaceholder(placeholder.TaskPlaceholder.find((tp: any) => tp.C_TaskInstance_key === taskCohort.C_TaskInstance_key), task);
        }

        this.tabRefresh('tasks', 'list');
        this.tabRefresh('samples', 'groups');
    }

    async removeSample(job: (Job & ExtendedJob), taskMaterial: TaskMaterial) {
        const task = taskMaterial.TaskInstance;
        const sampleGroupsWithSamples = await this.getSampleGroupsWithSamples([task]);
        const isSampleGroupsEditableFlag = this.isSampleGroupsEditableFlag();

        if (isSampleGroupsEditableFlag) {
            const samplesWithAssociationsDeleted = await this.handleSampleAssociationDeletes(sampleGroupsWithSamples, [taskMaterial.Material], true);
            if (!samplesWithAssociationsDeleted) {
                return;
            }
        }

        const materialIsRelatedToSampleGroupWithSamples = this.isMaterialRelatedToSampleGroupWithSamples(taskMaterial.Material, task);
        if (materialIsRelatedToSampleGroupWithSamples && ((isSampleGroupsEditableFlag && isAnyTaskEndState(task.MemberTaskInstance)) || !isSampleGroupsEditableFlag)) {
            return this.showNotifyMaterialsWithDataModal([taskMaterial.Material]);
        }

        const material = taskMaterial.Material;
        const taskFilter = this.buildTaskFilter([taskMaterial.TaskInstance]);
        const materialsWithData = await this.findMaterialsWithData(job, [material], taskFilter);
        if (materialsWithData.length > 0) {
            return this.showNotifyMaterialsWithDataModal(materialsWithData);
        }
        
        await this.taskService.deleteSampleFromTask(task.TaskJob[0], taskMaterial.Material.Sample);

        this.tabRefresh("tasks", "list");
        this.tabRefresh('samples', 'groups');
    }

    private isMaterialRelatedToSampleGroupWithSamples(sourceMaterial: Material, groupTask: TaskInstance) {
        if (sourceMaterial.SampleGroupSourceMaterial) {
            const sampleGroupSourceMaterials = sourceMaterial.SampleGroupSourceMaterial.filter(x => x?.SampleGroup?.Sample?.length > 0 && groupTask.C_TaskInstance_key === x.SampleGroup.C_TaskInstance_key);
            return sampleGroupSourceMaterials.length > 0;
        }
        return false;
    }

    

    removeAnimalPlaceholder(taskPlaceholder: TaskPlaceholder, task: TaskInstance) {
        if (task) {
            task.SampleGroup.forEach((sampleGroup: any) => {
                // Find and delete SampleGroupSourceMaterial of the Animal that is being removed.
                this.dataManager.deleteEntity(sampleGroup.SampleGroupSourceMaterial.find((sampleGroupSourceMaterial: any) => {
                    return sampleGroupSourceMaterial.C_AnimalPlaceholder_key === taskPlaceholder.AnimalPlaceholder.C_AnimalPlaceholder_key;
                }));
            });
        }

        this.jobService.deleteTaskPlaceholder(taskPlaceholder);
        this.tabRefresh('tasks', 'list');
        this.tabRefresh('samples', 'groups');
    }

    removePlaceholder(taskPlaceholder: TaskPlaceholder, task: TaskInstance) {
        const animalPlaceholderKeys = taskPlaceholder.Placeholder.AnimalPlaceholder ? taskPlaceholder.Placeholder.AnimalPlaceholder.map((ap: any) => ap.C_AnimalPlaceholder_key) : [];

        if (task) {
            task.SampleGroup.forEach((sampleGroup: any) => {
                // collect all SampleGroupSourceMaterials that belong to the Cohort that is being removed.
                const sampleGroupSourcesToDelete = sampleGroup.SampleGroupSourceMaterial.filter((sampleGroupSourceMaterial: any) => {
                    return animalPlaceholderKeys.indexOf(sampleGroupSourceMaterial.C_AnimalPlaceholder_key) >= 0;
                });

                sampleGroupSourcesToDelete.forEach((toDelete: any) => {
                    this.dataManager.deleteEntity(toDelete);
                });
            });
        }

        this.jobService.deleteTaskPlaceholder(taskPlaceholder);
        this.tabRefresh('tasks', 'list');
        this.tabRefresh('samples', 'groups');
    }

    public async ensureMaterialDataLoaded(job: ExtendedJob): Promise<void> {
        await this.dataManager.ensureRelationships([job], [
            'JobMaterial.Material.Animal.Genotype',
            'JobMaterial.Material.Animal.Genotype.cv_GenotypeAssay',
            'JobMaterial.Material.Animal.Genotype.cv_GenotypeSymbol',
            'JobMaterial.Material.Line',
            'JobMaterial.Material.MaterialPoolMaterial',
            'JobMaterial.Material.MaterialPoolMaterial.MaterialPool',
            'JobMaterial.Material.MaterialSourceMaterial',
            'JobMaterial.Material.Sample',
            'TaskJob.TaskInstance.TaskMaterial.Material',
        ]);

        await Promise.all([
            this.vocabularyService.getCV('cv_TimeUnits'),
            this.vocabularyService.getCV('cv_SampleTypes'),
            this.vocabularyService.getCV('cv_SampleStatuses'),
            this.vocabularyService.getCV('cv_PreservationMethods'),
            this.vocabularyService.getCV('cv_ContainerTypes'),
            this.vocabularyService.getCV('cv_SampleSubtypes'),
            this.vocabularyService.getCV('cv_SampleProcessingMethods'),
            this.vocabularyService.getCV('cv_SampleAnalysisMethods'),
            this.vocabularyService.getCV('cv_Sexes'),
            this.locationService.getContainerTypes('Sample'),
        ]);
    }
}

import {
    Component,
    Input,
    OnInit
} from '@angular/core';

import { LocationService } from './location.service';

@Component({
    selector: 'location-path',
    template: `
<span *ngFor="let position of pathPositions; let first = first" title="{{fullPathString}}">
  <span *ngIf="first && treeLevelMax && position.TreeLevel > treeLevelMax">&hellip;</span>
  <span *ngIf="!treeLevelMax || position.TreeLevel <= treeLevelMax">
    <span *ngIf="position.TreeLevel > 1">{{position.PositionName}}&nbsp;&gt;</span>
    <span *ngIf="position.TreeLevel === 1">{{position.PositionName}}</span>
  </span>
</span>
`,
})
/**
 * Displays the ancestor path to the current location (including the current location).
 */
export class LocationPathComponent implements OnInit {
    @Input() locationPositionKey: number;
    @Input() treeLevelMax: number;

    // State
    pathPositions: any[] = [];
    fullPathString = "";

    constructor(
        private locationService: LocationService
    ) { }

    ngOnInit() {
        if (this.locationPositionKey) {
            this.getAncestors(this.locationPositionKey).then((results: any) => {
                this.pathPositions = results.data;
                this.createFullPathString();
            });
        }
    }

    private getAncestors(locationPositionKey: number): Promise<any> {
        return this.locationService.getAncestors(locationPositionKey);
    }

    private createFullPathString() {
        const delimiter = ' > ';

        this.fullPathString = this.pathPositions.map((position) => {
            return position.PositionName;
        }).join(delimiter);
    }
}

import type { Input } from './input.interface';
import type { JobCharacteristic } from './job-characteristic.interface';

export interface cv_JobCharacteristicType {
    C_JobCharacteristicType_key: number;
    C_VocabularySource_key: number | null;
    C_Workgroup_key: number;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    ElementID: string;
    Input: Input[];
    IsActive: boolean;
    IsDefault: boolean;
    IsDeprecated: boolean;
    JobCharacteristic: JobCharacteristic[];
    JobCharacteristicType: string;
    ModifiedBy: string;
    SortOrder: number | null;
    Version: number;
}

<div class="detail-form-wrapper">
    <div class="detail-header">
        <h3>Sample Characteristic</h3>

        <detail-toolbar [facetPrivilege]="facetPrivilege"
                        [logTag]="COMPONENT_LOG_TAG"
                        (exit)="onCancel()"
                        [emitOnSave]="true"
                        (genericSaveEmitter)="onSaveCharacteristic()">
        </detail-toolbar>
    </div>

    <form class="detail-form" style="height:30rem">
        <fieldset [disabled]="facetPrivilege === 'ReadOnly'">
            <div class="form-group row">
                <label class="col-md-3 col-form-label">Active</label>
                <div class="col-md-9">
                    <input type="checkbox"
                           name="isActive"
                           class="mt-1"
                           [(ngModel)]="sampleCharacteristic.IsActive" />
                </div>
            </div>

            <div class="form-group row">
                <label class="col-md-3 col-form-label">Name</label>
                <div class="col-md-9">
                    <input type="text"
                           name="name"
                           [(ngModel)]="sampleCharacteristic.CharacteristicName"
                           class="form-control input-medium"
                           required />
                </div>
            </div>

            <div class="form-group row">
                <label class="col-md-3 col-form-label">Description</label>
                <div class="col-md-9">
                    <input type="text"
                           name="description"
                           [(ngModel)]="sampleCharacteristic.Description"
                           class="form-control input-medium" />
                </div>
            </div>

            <div class="form-group row">
                <label class="col-md-3 col-form-label">Sample Types</label>
                <div class="col-md-9">
                    <climb-multi-select *ngIf="sampleTypes.length"
                                        [(model)]="selectedSampleTypes"
                                        (modelChange)="onSampleTypeChange($event)"
                                        [values]="sampleTypes"
                                        [placeholder]="'Select Sample Type'"
                                        [keyProperty]="'C_SampleType_key'"
                                        [valueProperty]="'SampleType'"
                                        [displayAllSelectedText]="false"
                                        [buttonClasses]="selectedSampleTypes.length > 0 ? '' : 'ng-invalid'">
                    </climb-multi-select>
                </div>
            </div>

            <characteristic-data-type [characteristic]="sampleCharacteristic"
                                      [dataTypes]="dataTypes"
                                      [characteristicType]="'sample'">

            </characteristic-data-type>

            <div class="form-group row">
                <label class="col-md-3 col-form-label">Last Reviewed</label>
                <div class="col-md-9">
                    <climb-ngb-date [(ngModel)]="sampleCharacteristic.DateLastReviewed"
                                    [ngModelOptions]="{ standalone: true }">
                    </climb-ngb-date>
                </div>
            </div>
        </fieldset>
    </form>
</div>

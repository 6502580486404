<div class="pager-toolbar {{containerClasses}}"
     aria-label="Paging toolbar">

    <button type="button"
            class="btn btn-secondary"
            [disabled]="first || saveChangesService.saving"
            (click)="previousClicked()">
        <i class="fa fa-fw fa-play fa-flip-horizontal"
           title="Previous {{itemName}}"></i>
    </button>

    <button type="button"
            class="btn btn-secondary ml-1"
            [disabled]="last || saveChangesService.saving"
            (click)="nextClicked()">
        <i class="fa fa-fw fa-play"
           title="Next {{itemName}}"></i>
    </button>

</div>

<form novalidate>

    <div class="modal-header justify-content-center">
        <h4 class="modal-title">
            Add Animal Comment
        </h4>
    </div>

    <div class="modal-body">
        <div class="form-group row">
            <div class="col-md-12">
                <textarea name="comments"
                    class="form-control p-2"
                    [(ngModel)]="comments"
                    [required]="true">
                </textarea>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-md-2 col-form-label">Status</label>
            <div class="col-md-2">
                <active-vocab-select [(model)]="commentStatusKey"
                                    [vocabChoices]="statuses"
                                    [keyFormatter]="commentStatusKeyFormatter"
                                    [optionFormatter]="commentStatusFormatter"
                                    [required]="true">
                </active-vocab-select>
            </div>
        </div>
    </div>

    <div class="modal-footer">
        <button type="button"
                class="btn btn-fw btn-lg btn-primary"
                (click)="onAdd()"
                [disabled]="addButtonDisabled()">
            Add
        </button>
        <button type="button"
                class="btn btn-lg btn-secondary"
                (click)="onCancel()">
            Cancel
        </button>
    </div>

</form>

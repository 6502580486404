import {
    Component,
    Input,
} from '@angular/core';

import { IoTService } from './iot.service';
import { DroppableEvent } from '../common/droppable-event';

import { notEmpty } from '../common/util';

@Component({
    selector: 'alert-device-multiselect',
    templateUrl: './alert-device-multiselect.component.html',
    styles: [`
        .table {
            margin-bottom: 0.25em;
        }
    `]
})
export class AlertDeviceMultiselectComponent {
    @Input() alertDefinition: any;
    @Input() facetPrivilege: string;

    constructor(
        private iotService: IoTService,
    ) { }

    removeAlertDevice(alertDevice: any) {
        this.iotService.deleteAlertDevice(alertDevice);
    }

    selectDevice(device: any) {
        if (this.facetPrivilege !== 'ReadWrite') {
            return;
        }
        
        const initialValues = {
            C_Device_key: device.C_Device_key,
            C_AlertDefinition_key: this.alertDefinition.C_AlertDefinition_key
        };

        this.iotService.createAlertDevice(initialValues);
    }

    onDropDevice(event: DroppableEvent) {
        if (notEmpty(this.iotService.draggedDevices)) {
            for (const device of this.iotService.draggedDevices) {
                this.selectDevice(device);
            }
            
            this.iotService.draggedDevices = [];
        }
    }

    searchDevices = (term: string): Promise<any> => {
        return this.iotService.searchDevices(term);
    }

    deviceIdFormatter = (value: any) => {
        return value.C_Device_key;
    }
}

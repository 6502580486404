import { GenotypeComboItem } from '../../genotypes/genotype-combo-item';
import { Predicate } from 'breeze-client';

/*
* Build a breeze predicate for a list of genotype combo items
*   returns single breeze predicate
*/
export function buildGenotypeCombosFilter( combos: GenotypeComboItem[]):
    Predicate {
    
    const predicates = [];  
    
    for (const combo of combos) {
        const comboPred = _buildSingleComboFilter(combo);            
        if (comboPred.length > 0) {
            predicates.push(Predicate.and(comboPred));
        }            
    }                
    // OR each combo item
    return Predicate.or(predicates);    
}

function _buildSingleComboFilter(combo: GenotypeComboItem): Predicate[] {
    const comboPred = [];
    
    if (combo.assay.searchAny && combo.symbol.searchAny) {
        // any assay / any symbol
        comboPred.push(Predicate.create(
            'C_GenotypeAssay_key', '!=', null
        ));
        comboPred.push(Predicate.create(
            'C_GenotypeSymbol_key', '!=', null
        ));
        return comboPred;
    }
    
    // assay search
    if (combo.assay.key || combo.assay.searchNulls) {
        let assayKey = combo.assay.key;
        if (combo.assay.searchNulls) {
            assayKey = null;
        }
        comboPred.push(Predicate.create(
            'C_GenotypeAssay_key', 'eq', assayKey
        ));
    }

    // genotype / symbol search
    if (combo.symbol.key || combo.symbol.searchNulls) {
        let symbolKey = combo.symbol.key;
        if (combo.symbol.searchNulls) {
            symbolKey = null;
        }
        comboPred.push(Predicate.create(
            'C_GenotypeSymbol_key', 'eq', symbolKey
        ));
    }
    
    return comboPred;
}

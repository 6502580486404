import { InjectionMapFactory, DynamicComponentType } from './injection-token-map';

import { 
    Injectable, 
    Injector,
    Compiler
} from '@angular/core';
import { ComponentFactory, NgModuleFactory } from '@angular/core';

@Injectable()
export class DynamicComponentLoader {

  constructor(
    private injector: Injector,
    private compiler: Compiler,
  ) { }

  getComponentFactory<T>(
        type: DynamicComponentType,
        injector?: Injector
    ): Promise<ComponentFactory<T>> {

    const injectionMap = InjectionMapFactory.getInjectionMap(type);
    return this.loadModule(type).then((ngModuleFactory) => {
        const moduleRef = ngModuleFactory.create(injector || this.injector);   
    
        // Read from the moduleRef injector and locate the dynamic component type
        const dynamicComponentType = moduleRef.injector.get(injectionMap.token);
        // Resolve this component factory
        return <any> moduleRef.componentFactoryResolver
            .resolveComponentFactory<T>(dynamicComponentType);
      
    });
  }

  loadModule(type: DynamicComponentType): Promise<NgModuleFactory<any>> {
    const injectionMap = InjectionMapFactory.getInjectionMap(type);
    return injectionMap.loadModule().then((mod) => {
        return this.compiler.compileModuleAsync(mod);
    });
  }
}

import { Injectable } from '@angular/core';
import { OAuthErrorEvent, OAuthService, NullValidationHandler } from 'angular-oauth2-oidc';
import { filter } from 'rxjs/operators';
import { authConfig, discoveryDocumentConfig } from './auth.config';
import { Subject } from 'rxjs';
import { RoutingService } from '../routing/routing.service';
import { ToastrService } from '@services/toastr.service';
import { LogLevel } from '@services/models';
import { LoginService } from '../login/services/login.service';
import type { LoginData } from '../login/login.interface';

const USERNAME_CLAIM = 'extension_climbUsername';
const EMAIL_CLAIM = 'email';

@Injectable({ providedIn: 'root' })
export class SSOService {

    private idTokenReceivedSource = new Subject<void>();
    idTokenReceived$ = this.idTokenReceivedSource.asObservable();

    private discoveryDocumentIsLoaded = false;

    constructor(
        private oauthService: OAuthService,
        private toasterService: ToastrService,
        private routingService: RoutingService,
        private loginService: LoginService,
    ) {
        // Useful for debugging:
        this.oauthService.events.subscribe((event) => {
            if (event instanceof OAuthErrorEvent) {
                console.error('OAuthErrorEvent Object:', event);
            } else {
                console.warn('OAuthEvent Object:', event);
            }
        });

        // Redirect when discovery document loads
        this.oauthService.events
            .pipe(filter((e: any) => e.type === 'discovery_document_loaded' ))
            .subscribe((e: any) => {
                this.discoveryDocumentIsLoaded = true;
            });

        // Emit idTokenReceived event when token is received
        this.oauthService.events
            .pipe(filter((e: any) => e.type === 'token_received' ))
            .subscribe((e: any) => {
                this.idTokenReceivedSource.next();
            });
    }

    // Checks if the hash fragment is  B2C response
    isResponse(str: string): boolean {
        const checks = [/[\?|&|#]code=/, /[\?|&|#]error=/, /[\?|&|#]token=/, /[\?|&|#]id_token=/];
        if (!str) { return false; }
        for (const check of checks) {
            if (str.match(check)) { return true; }
        }
        return false;
    }

    // Attempt login on app entry
    public runInitialLoginSequence(hashFragment: string): Promise<boolean> {
        // For debugging
        if (location.hash) {
            console.log('Encountered hash fragment, plotting as table....');
            console.table(location.hash.slice(1).split('&').map((kvp) => kvp.split('=')));
        }

        // Send B2C hash response to the window to be used after redirect
        const message = this.isResponse(location.hash) ? location.hash : '#' + location.search;
        (window.opener || window.parent).postMessage(message, location.origin);

        // Configure oauthService
        this.oauthService.configure(authConfig);
        return this.oauthService.loadDiscoveryDocument(discoveryDocumentConfig.url)

            .then(() => {
                this.oauthService.tokenValidationHandler = new NullValidationHandler();
                return Promise.resolve();

            })

            .then(() => {
                return this.oauthService.tryLogin({
                    customHashFragment: hashFragment,
                    onTokenReceived: this.onTokenReceived.bind(this),
                });
            })

            .then((isLoggedIn: boolean) => {
                // timeout after 10 seconds
                setTimeout(function () {
                    if (!this.discoveryDocumentIsLoaded) {
                        this.discoveryDocumentIsLoaded = true;
                    }
                }, 10000);

                while (!this.discoveryDocumentIsLoaded) {
                    // wait until discovery doc is loaded or 10 seconds pass
                }
                if (!isLoggedIn) {
                    return this.routingService.navigateToLogin();
                }
                return true;
            })

            .catch((err) => {
                console.error('[sso.service] ', err);
                this.loginService.errorMessage.next({ message: 'Invalid token.' });
                throw err;
            });
    }

    async onTokenReceived(): Promise<void> {
        const hasValidIdToken: boolean = await this.loginService.validateIdToken();
        if (!hasValidIdToken) {
            this.toasterService.showToast('Invalid id token.', LogLevel.Error);
        }

        this.loginService.login(this.loginSSOData).subscribe(
            () => this.routingService.navigateToHome(),
        );
    }

    private get loginSSOData(): LoginData {
        const claims = this.oauthService.getIdentityClaims();
        return {
            userName: claims[USERNAME_CLAIM],
            password: '',
            email: claims[EMAIL_CLAIM],
            useRefreshTokens: false,
            sso: true,
        };
    }
}

import { StudyService } from './study.service';
import { EnumerationService } from "../enumerations/enumeration.service";


export class StudySharedLogic {

    constructor(
        private studyService: StudyService,
        private enumerationService: EnumerationService
    ) {
        //
    }

    createStudyCharacteristics(study: any): Promise<any> {
        return this.studyService.createStudyCharacteristics(study).then((data) => {
            return this.attachEnumerations(study);
        });
    }

    attachEnumerations(study: any): Promise<any> {
        const promises: Promise<any>[] = [];
        const characteristics: any[] = study.StudyCharacteristicInstance;
        for (const characteristic of characteristics) {
            const classKey = characteristic.StudyCharacteristic.C_EnumerationClass_key;
            if (classKey) {
                const promise = this.enumerationService
                    .getEnumerationItems(classKey).then((items) => {
                        characteristic.EnumerationItems = items;
                    });
                promises.push(promise);
            }
        }

        return Promise.all(promises);
    }

}

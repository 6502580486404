import type { Material } from './material.interface';
import type { Study } from './study.interface';

export interface StudyMaterial {
    C_Material_key: number;
    C_StudyMaterial_key: number;
    C_Study_key: number;
    C_Workgroup_key: number;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    Material: Material;
    ModifiedBy: string;
    Study: Study;
    Version: number;
}

import { Component, ChangeDetectionStrategy } from '@angular/core';
import { stack } from '@icons';

@Component({
    selector: 'climb-bulk-dropdown',
    templateUrl: './toolbar-bulk-dropdown.component.html',
    styleUrls: ['./toolbar-bulk-dropdown.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToolbarBulkDropdownComponent {
    readonly icons = { stack };
}

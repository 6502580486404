<div class="header">
  <h3 climbTitle>{{ name }}</h3>
  <div class="favorite-container">
    <climb-spinner size="md" *ngIf="isLoading"></climb-spinner>
    <button
      *ngIf="!isLoading"
      type="button"
      [ngClass]="{ 'favorite': isFavorite }"
      (click)="toggle($event)">
      <svg [climbIcon]="isFavorite ? icons.starFilled : icons.star" size="md"></svg>
    </button>
  </div>
</div>

<div class="facets">
  <div *ngIf="facets.details.length === 0" class="empty-facets">No facets added yet</div>
  <div class="facet" *ngFor="let item of facets.details">
    <svg *ngIf="workspaceIcons.get(item.FacetName)?.name"
        [climbIcon]="workspaceIcons.get(item.FacetName)?.name"
        size="md">
    </svg>
  <span>{{ item.FacetDisplayName }}</span>
  </div>
  <div class="facet" *ngIf="facets.plus > 0">
    <span>+{{ facets.plus }} Facets</span>
  </div>
</div>

import { RouteEnum } from '../routing/route.enum';
import { WorkspaceService } from '../workspaces/workspace.service';
import { WorkspaceManagerService } from '@services/workspace-manager.service';

import { Component, Injector, OnInit, } from '@angular/core';
import { RoutingService } from '../routing/routing.service';
import { FeatureFlagService } from '@services/feature-flags.service';
import { padlockClosedFilled, padlockOpenedFilled } from '@icons';
import { Workspace } from '@common/types/models/workspace.interface';
import { CLIMB_SURFACE_MODE } from '@common/tokens';
import { MixinUnsubscribeClass } from '@common/mixins';
import { Entity } from '@common/types';
import { Observable } from 'rxjs';
import { CurrentWorkgroupService } from '@services/current-workgroup.service';
import { ViewUnsavedChangesModalService } from '@common/toolbars';
import { SaveChangesService } from '@services/save-changes.service';
import { DataContextService } from '@services/data-context.service';

@Component({
    selector: 'climb-header',
    templateUrl: 'header.component.html',
    styleUrls: ['header.component.scss'],
    providers: [{ provide: CLIMB_SURFACE_MODE, useValue: 'dark' }],
})
export class HeaderComponent extends MixinUnsubscribeClass implements OnInit {
    icons = { padlockOpenedFilled, padlockClosedFilled };

    readonly LOG_TAG = 'climb-header';

    workspaces$: Observable<Entity<Workspace>[]>;
    workspaceChangesCount: number;

    isGLP: boolean;

    constructor(
        private routingService: RoutingService,
        private workspaceManager: WorkspaceManagerService,
        private workspaceService: WorkspaceService,
        private featureFlagService: FeatureFlagService,
        private currentWorkgroupService: CurrentWorkgroupService,
        private viewUnsavedChangesModalService: ViewUnsavedChangesModalService,
        private saveChangesService: SaveChangesService,
        private dataContext: DataContextService,
        protected injector: Injector,
    ) {
        super(injector);
    }

    async ngOnInit() {
        super.ngOnInit();
        this.workspaces$ = this.workspaceService.workspaces$;
        await this.workspaceManager.init();
        this.initialize();
    }

    initialize() {
        this.workspaceChangesCount = this.workspaceManager.getChangesCount();

        this.subscribe(
            this.currentWorkgroupService.switchWorkgroup$.subscribe(() => {
                this.workspaceService.fetchWorkspaces();
            }),
        );

        this.initIsGLP();
    }

    initIsGLP() {
        this.isGLP = this.featureFlagService.isFlagOn('IsGLP');
    }

    canNavToDashboard() {
        return !this.isCustomizeWorkspaceActive() &&
            this.routingService.workspaceStateIs(RouteEnum.Workspaces);
    }

    isCustomizeWorkspaceActive(): boolean {
        return this.workspaceService.isCustomizeWorkspaceActive;
    }

    isWorkspaceState(): boolean {
        return this.routingService.workspaceStateIs(RouteEnum.Workspaces);
    }

    async navigateToDashboard(): Promise<void> {
        if (this.canNavToDashboard()) {
            if (this.saveChangesService.hasChanges) {
                try {
                    await this.handleSaveBeforeNavigate();
                } catch (error) {
                    return;
                }
            }
            this.workspaceService.setCurrentWorkspace(null);
            this.routingService.navigateToDashboard();
        }
    }

    canShowWorkspaceSelector(): boolean {
        return !this.isCustomizeWorkspaceActive() && (
            this.routingService.stateIs(RouteEnum.Dashboard) ||
            this.routingService.workspaceStateIs(RouteEnum.Workspaces)
        );
    }

    async handleSaveBeforeNavigate(): Promise<void> {
        const result = await this.viewUnsavedChangesModalService.openComponent();
        if (result === 'save') {
            try {
                await this.saveChangesService.saveChanges('GLOBAL');
            } catch (error) {
                throw Promise.reject(error);
            }
        } else {
            this.dataContext.cancel(true);
        }
    }
}

import { Injectable } from '@angular/core';
import { defer, Observable } from 'rxjs';

import { LocationService } from '../../locations/location.service';
import { VocabularyService } from '../../vocabularies/vocabulary.service';
import { take } from 'rxjs/operators';

@Injectable()
export class HousingVocabService {

    // vocab Observables
    public get animalStatuses$(): Observable<any[]>{
        return defer(() => this.vocabularyService.getCV('cv_AnimalStatuses')).pipe(take(1));
    }
    public get containerTypes$(): Observable<any[]>{
        return defer(() => this.locationService.getContainerTypes('Animal')).pipe(take(1));
    }
    public get exitReasons$(): Observable<any[]>{
        return defer(() => this.vocabularyService.getCV('cv_ExitReasons')).pipe(take(1));
    }
    public get materialPoolTypes$(): Observable<any[]>{
        return defer(() => this.vocabularyService.getCV('cv_MaterialPoolTypes')).pipe(take(1));
    }
    public get materialPoolStatuses$(): Observable<any[]>{
        return defer(() => this.vocabularyService.getCV('cv_MaterialPoolStatuses')).pipe(take(1));
    }
    public get taxons$(): Observable<any[]>{
        return defer(() => this.vocabularyService.getCV('cv_Taxons')).pipe(take(1));
    }
    public get socialGroupAccessLevels$(): Observable<any[]>{
        return defer(() => this.vocabularyService.getCV('cv_SocialGroupAccessLevels')).pipe(take(1));
    }
    public get compatibilityAccessLevels$(): Observable<any[]>{
        return defer(() => this.vocabularyService.getCV('cv_CompatibilityAccessLevels')).pipe(take(1));
    }

    constructor(
        private locationService: LocationService,
        private vocabularyService: VocabularyService
    ) {
        this._init();
    }

    _init() {
        // Loads data into Breeze for local lookup (maybe related to Animals?)
        // TODO (kevin.stone): not sure if this is necessary. 
        // At least could be candidate for refactor
        this.vocabularyService.ensureCVLoaded('cv_MaterialTypes');
        // cv_Sex is required for Housing detail animal filters (male/female/other)
        this.vocabularyService.ensureCVLoaded('cv_Sexes');
    }
}

<div style="width:7.75in;" *ngIf="study">
    <table id="exportStudyTable" class="printTable">
        <tr>
            <td><b>{{'Study'| translate}}</b></td>
        </tr>
        <tr>
            <td>{{study.StudyName}}</td>
        </tr>
        <tr>
            <td><b>{{'Study'| translate}} Type</b></td>
        </tr>
        <tr>
            <td>{{study.cv_StudyType?.StudyType}}</td>
        </tr>
        <tr>
            <td><b>Active</b></td>
        </tr>
        <tr>
            <td>{{study.IsActive}}</td>
        </tr>
        <tr>
            <td><b>{{'Study'| translate}} Status</b></td>
        </tr>
        <tr>
            <td>{{study.cv_StudyStatus?.StudyStatus}}</td>
        </tr>
        <tr>
            <td><b>Approval Number</b></td>
        </tr>
        <tr>
            <td>{{study.ApprovalNumber}}</td>
        </tr>
        <tr>
            <td><b>Description</b></td>
        </tr>
        <tr>
            <td>{{study.Description}}</td>
        </tr>
        <tr>
            <td><b>Comments</b></td>
        </tr>
        <tr>
            <td>{{study.Comments}}</td>
        </tr>
        <tr>
            <td><b>Total Animals</b></td>
        </tr>
        <tr>
            <td>{{study.totalAnimalCount}}</td>
        </tr>
        <tr>
            <td><b>Available Animals</b></td>
        </tr>
        <tr>
            <td>{{study.availableAnimalCount}}</td>
        </tr>
        <tr>
            <td><b>Created By</b></td>
        </tr>
        <tr>
            <td>{{study.CreatedBy | userFullName}}</td>
        </tr>
        <tr>
            <td><b>Created Date</b></td>
        </tr>
        <tr>
            <td>{{study.DateCreated | formatShortDate}}</td>
        </tr>
        <ng-container *ngFor="let characteristic of study.StudyCharacteristicInstance">
            <tr>
                <td><b>{{characteristic.CharacteristicName}}</b></td>
            </tr>
            <tr>
                <td>{{characteristic?.CharacteristicValue}}</td>
            </tr>
        </ng-container>
        <tr>
            <td colspan="2"><b>{{'Lines' | translate}}</b></td>
        </tr>
        <tr *ngFor="let line of study.StudyLine">
            <td>{{line?.Line?.LineName}}</td>
            <td>{{line?.AnimalCount}}</td>
        </tr>
    </table>
</div>

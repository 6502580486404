<div class="modal-header justify-content-center">
    <h4 class="modal-title ml-auto">Cancel Treatment Plans</h4>
    <button type="button"
            class="close"
            aria-label="Close"
            (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <ag-grid-angular style="width: 100%; height: 500px;"
                     class="climb-grid ag-theme-alpine"
                     [gridOptions]="gridOptions"
                     [columnDefs]="columnDefs"
                     [rowData]="treatmentPlans"
                     (selectionChanged)="rowSelectionChanged()"
                     sizeColumnsToFit></ag-grid-angular>
</div>
<div class="modal-footer">
    <span class="btn-set">
        <button *ngIf="defaultEndStatus"
                type="submit"
                class="btn btn-fw-wide btn-lg btn-primary"
                [disabled]="selectedTreatmentPlans.length === 0"
                (click)="cancelSelectedAndClose()">
            Mark Selected Treatment Plans {{ defaultEndStatus.TaskStatus }}
        </button>
        <button type="button"
                class="btn btn-lg btn-secondary"
                (click)="close()">
            Close
        </button>
    </span>
</div>

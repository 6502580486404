import type { EnumerationClass } from './enumeration-class.interface';
import type { MatingCharacteristicInstance } from './mating-characteristic-instance.interface';
import type { cv_DataType } from './cv-data-type.interface';
import type { cv_MatingType } from './cv-mating-type.interface';
import type { cv_Unit } from './cv-unit.interface';

export interface MatingCharacteristic {
    C_DataType_key: number;
    C_EnumerationClass_key: number | null;
    C_MatingCharacteristic_key: number;
    C_MatingType_key: number;
    C_Unit_key: number | null;
    C_Workgroup_key: number;
    CharacteristicName: string;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    Description: string;
    EnumerationClass: EnumerationClass;
    IsActive: boolean;
    MatingCharacteristicInstance: MatingCharacteristicInstance[];
    ModifiedBy: string;
    NumericScale: number | null;
    SortOrder: number | null;
    TextLineCount: number | null;
    ValidationMaximum: string;
    ValidationMinimum: string;
    Version: number;
    cv_DataType: cv_DataType;
    cv_MatingType: cv_MatingType;
    cv_Unit: cv_Unit;
}

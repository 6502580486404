
let _counter = 1;
/**
 * Get unique ID for a given object reference
 * @param obj 
 */
export function getObjectId(obj: any): string {
    if (!obj) {
        return '';
    }
    if (!obj.__obj_id) {
        _counter += 1;
        obj.__obj_id = 'obj' + _counter;
    }
    return obj.__obj_id;
}

import {
    CellFormatterService,
    TableOptions,
    FormatterOptions
} from '../common/datatable';
import { TranslationService } from '../services/translation.service';
import { AuditModifiedFieldComponent } from './audit-modified-fields/audit-modified-field.component';
import { AuditClassFactory } from './audit-class-maker';

export class AuditFilesTableOptions {

    options: TableOptions;

    constructor(
        private cellFormatterService: CellFormatterService,
        private translationService: TranslationService
    ) {
        this.options = this.getDefaultOptions();
    }

    getDefaultOptions(): TableOptions {
        return {
            csvFileName: 'files-audit.csv',
            enableDetailColumn: false,
            enableDraggable: false,
            enableSelectable: false,
            enableAuditColumns: false,
            columns: [
                {
                    displayName: 'Type',
                    field: 'UpdateType',
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Modified Fields',
                    field: 'ModifiedFields',
                    rendererComponent: AuditModifiedFieldComponent,
                    exportFormatter: this.cellFormatterService.auditModifiedFieldsExportFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Date',
                    field: 'ModifiedDate',
                    formatter: this.cellFormatterService.dateTimeUTCFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Modified By',
                    field: 'ModifiedBy',
                    formatter: this.cellFormatterService.userNameFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Facet Name',
                    field: 'FileFacet',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('FileFacet', 'FileFacet'),
                    cellClass: AuditClassFactory.createCellClass('filefacet'),
                },
                {
                    displayName: 'File Name',
                    field: 'FileName',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('FileName', 'FileName'),
                    cellClass: AuditClassFactory.createCellClass('filename'),

                },
                {
                    displayName: 'Animal ID',
                    field: 'AnimalID',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('AnimalID', 'AnimalID'),
                    cellClass: AuditClassFactory.createCellClass('animalid'),

                },
                {
                    displayName: 'Task Name',
                    field: 'TaskName',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('TaskName', 'TaskName'),
                    cellClass: AuditClassFactory.createCellClass('taskname'),

                },
                {
                    displayName: 'Protocol Name',
                    field: 'ProtocolName',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('ProtocolName', 'ProtocolName'),
                    cellClass: AuditClassFactory.createCellClass('protocolname'),
                },
                {
                    displayName: 'Sample ID',
                    field: 'SampleID',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('SampleID', 'SampleID'),
                    cellClass: AuditClassFactory.createCellClass('sampleid'),
                },
                {
                    displayName: this.translationService.translate('Study') + ' Name',
                    field: 'StudyName',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('StudyName', this.translationService.translate('Study') + 'Name'),
                    cellClass: AuditClassFactory.createCellClass((this.translationService.translate('Study') + 'Name').toLowerCase()),
                },
                {
                    displayName: this.translationService.translate('Line') + ' Name',
                    field: 'LineName',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('LineName', this.translationService.translate('Line') + 'Name'),
                    cellClass: AuditClassFactory.createCellClass((this.translationService.translate('Line') + 'Name').toLowerCase()),
                },
                {
                    displayName: 'Construct ID',
                    field: 'ConstructID',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('ConstructID', 'ConstructID'),
                    cellClass: AuditClassFactory.createCellClass('constructid'),
                },
                {
                    displayName: 'Order ID',
                    field: 'OrderID',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('OrderID', 'OrderID'),
                    cellClass: AuditClassFactory.createCellClass('orderid'),
                },
                {
                    displayName: this.translationService.translate('Job') + ' Name',
                    field: 'JobID',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('JobID', this.translationService.translate('Job') + 'ID'),
                    cellClass: AuditClassFactory.createCellClass((this.translationService.translate('Job') + 'ID').toLowerCase()),
                },
                {
                    displayName: 'Created By',
                    field: 'CreatedBy',
                    formatter: this.cellFormatterService.userNameFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Created Date',
                    field: 'DateCreated',
                    formatter: this.cellFormatterService.dateTimeUTCFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                }
            ]
        };
    }
}

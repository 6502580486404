import {
    Component,
    ChangeDetectionStrategy,
    Input,
    Injector,
    forwardRef,
    ContentChildren,
    QueryList,
    AfterViewInit,
    NgZone,
    ChangeDetectorRef,
    ViewChild,
    ElementRef
} from '@angular/core';
import { chevronDown, chevronUp } from '@icons';
import { AddSurfaceModeMixin, applyMixins, Base, CanUnsubscribeMixin, mixinSurfaceMode, mixinUnsubscribe } from '@common/mixins';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { SelectOptionComponent } from '@common/components/select/select-option/select-option.component';
import { defer, merge } from 'rxjs';
import { PopoverComponent } from '@common/components/popover/popover.component';
import { startWith, switchMap } from 'rxjs/operators';
import { PopoverOptions } from '@common/components/popover/popover-options.interface';
import { CanControlValueAccessorMixin, mixinControlValueAccessor } from '@common/mixins/control-value-accessor.mixin';

export type SelectSize = 'sm' | 'md' | 'lg';
const Mixins: AddSurfaceModeMixin & CanUnsubscribeMixin & CanControlValueAccessorMixin & typeof Base = applyMixins(mixinSurfaceMode, mixinUnsubscribe, mixinControlValueAccessor);

@Component({
    selector: 'climb-select',
    templateUrl: './select.component.html',
    styleUrls: ['./select.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        // eslint-disable-next-line @angular-eslint/no-forward-ref
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => SelectComponent), multi: true }
    ]
})
export class SelectComponent<T = any> extends Mixins implements AfterViewInit {
    @Input() size: SelectSize = 'sm';
    @Input() placeholder = '';
    @Input() disabled = false;
    @Input() error = false;
    @Input() popoverOptions: PopoverOptions = {
        positions: [
            {
                originX: 'end',
                originY: 'bottom',
                overlayX: 'end',
                overlayY: 'top',
                offsetY: 4
            }
        ]
    };
    @ViewChild('selectBody') selectBody: PopoverComponent;
    @ViewChild('selectTrigger') selectTrigger: ElementRef<HTMLDivElement>;
    @ContentChildren(SelectOptionComponent, { descendants: true }) options: QueryList<SelectOptionComponent<T>>;

    isOpen = false;
    minSelectContentWidth = 0;

    readonly optionSelectionChange$ = defer(() => {
        return this.options.changes.pipe(
            startWith(this.options),
            switchMap(() => merge(...this.options.map((option: SelectOptionComponent<T>) => option.selectionChange))),
        );
    });

    get classes(): Record<string, boolean> {
        return {
            ['select-wrapper'] : true,
            [`select-${this.size}`] : true,
            ['disabled'] : this.disabled,
            ['error'] : this.error,
        };
    }

    get selectedOption(): SelectOptionComponent<T> | undefined {
        return this.options?.toArray().find((option: SelectOptionComponent<T>) => option.value === this.value);
    }

    get selectedValue(): string {
        const selectedValue = this.selectedOption?.viewValue;
        return selectedValue ?? this.placeholder;
    }

    icons = { chevronDown, chevronUp };

    constructor(
        protected injector: Injector,
        private changeDetectorRef: ChangeDetectorRef,
        private ngZone: NgZone
    ) {
        super(injector);
    }

    ngAfterViewInit() {
        super.ngAfterViewInit();
        this.subscribe(
            this.optionSelectionChange$.subscribe((value: T) => {
                this.selectBody.close();
                this.value = value;
                this.onChange(value);
                this.onTouched();
                this.updateSelection();
            }),
            this.ngZone.onStable.subscribe(() => {
                this.minSelectContentWidth = this.selectTrigger.nativeElement.getBoundingClientRect().width;
            })
        );
    }

    onToggle(isOpen: boolean): void {
        this.isOpen = isOpen;
    }

    close(): void {
        this.selectBody.close();
    }

    writeValue(value: T) {
        super.writeValue(value);
        this.updateSelection();
        this.changeDetectorRef.markForCheck();
    }

    private updateSelection(): void {
        this.options?.toArray().forEach((option: SelectOptionComponent<T>) => {
            if (this.value === option.value) {
                option.select();
            } else {
                option.deselect();
            }
        });
    }
}

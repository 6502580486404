import type { Material } from './material.interface';
import type { TaskInstance } from './task-instance.interface';

export interface TaskMaterial {
    C_Material_key: number;
    C_TaskInstance_key: number;
    C_TaskMaterial_key: number;
    C_Workgroup_key: number;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    IsScanValid: boolean;
    Material: Material;
    ModifiedBy: string;
    ScanValue: string;
    Sequence: number;
    TaskInstance: TaskInstance;
    Version: number;
}

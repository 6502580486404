import {
    Component,
    EventEmitter,
    Input,
    Output,
    OnInit
} from '@angular/core';

import { SearchService } from '../search/search.service';
import { SearchQueryDef } from '../search/search-query-def';

const MAX_SEARCH_RESULTS = 50;

@Component({
    selector: 'order-multiselect',
    template: `
<!-- Selected orders -->
<div *ngIf="model && model.length > 0">
    <table class="table table-sm table-borderless table-nonfluid">
        <tbody>
            <tr *ngFor="let item of model; let i = index;">
                <td class="icon-cell">
                    <a (click)="removeOrder(i)" title="Remove">
                        <i class="fa fa-remove remove-item"></i>
                    </a>
                </td>
                <td>
                    {{item.OrderID}}
                </td>
            </tr>
        </tbody>
    </table>
</div>

<!-- Order typeahead -->
<climb-indirect-typeahead
        [search]="searchOrders"
        [resultFormatter]="orderFormatter"
        [placeholder]="placeholder"
        (selectItem)="selectOrder($event)"></climb-indirect-typeahead>
`,
    styles: [`
        .table {
            margin-bottom: 0.25em;
        }
    `]
})
export class OrderMultiselectComponent implements OnInit  {
    @Input() model: any[];
    @Output() modelChange: EventEmitter<any> = new EventEmitter<any>();
    // Only returns animal type orders if true
    @Input() animalOrdersOnly = false;

    placeholder: string;

    constructor(
        private _searchService: SearchService
    ) {
        //
    }

    ngOnInit() {
        this.placeholder = "Order ID...";
    }

    removeOrder(index: number) {
        if (this.model && index >= 0 && index < this.model.length) {
            this.model.splice(index, 1);
        }
        this.modelChange.emit(this.model);
    }

    searchOrders = (term: string): Promise<any> => {

        let count = null;
        if (term.length < 3) {
            count = MAX_SEARCH_RESULTS;
        }

        let orderFilter = {};
        if (this.animalOrdersOnly) {
            orderFilter = {
                OrderID: term,
                MaterialType: 'animal'
            };
        } else {
            orderFilter = {
                OrderID: term
            };
        }

        const searchQueryDef: SearchQueryDef = {
            entity: 'Orders',
            page: 1,
            size: count,
            sortColumn: 'OrderID',
            sortDirection: 'asc',
            filter: orderFilter
        };

        return this._searchService.getEntitiesBySearch(searchQueryDef)
            .then((results: any) => {
                return results.data;
            });
    }

    orderFormatter = (value: any) => {
        return value.OrderID;
    }

    selectOrder(order: any) {
        if (this.model) {
            this.model.push(order);
        } else {
            this.model = [order];
        }

        this.modelChange.emit(this.model);
    }
}



import { HttpParams } from '@angular/common/http';
import { Component, Output, EventEmitter, OnInit, ViewChildren } from '@angular/core';
import { FormArray, FormControl, NgModel } from '@angular/forms';
import { randomId } from '@common/util';
import { AuthService } from '@services/auth.service';
import { LoggingService } from '@services/logging.service';
import { LineService } from '../../../lines/line.service';
import { IAnimalCheckParams } from '../../params/animal-check-params.interface';
import { ReportingService } from '../../reporting.service';
import { dateControlValidator } from '@common/util/date-control.validator';

@Component({
    selector: "animal-check-requester",
    templateUrl: './animal-check-requester.component.html',
})
export class AnimalCheckRequesterComponent implements OnInit {
    @ViewChildren('dateControl') dateControls: NgModel[];

    @Output() exit = new EventEmitter<void>();

    readonly ANIMAL_PASTE_LIMIT = 500;
    
    searchParams: IAnimalCheckParams;

    domIdAddition: string;
    isSubmitted: boolean;

    taxon: any = {
        CommonName: ''
    };

    // used for the data binding of the various task inputs. In the future (as of 03/13/2023), this will be a Settings facet option. 
    taskSelections: any[] = [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
    ];
    constructor(private authService: AuthService,
                private reportingService: ReportingService,
                private lineService: LineService,
                private loggingService: LoggingService) {
    }

    ngOnInit() {
        this._initialize();
    }

    submitRequest(): void {
        const errMessage = dateControlValidator(this.dateControls);
        if (errMessage) {
            this.loggingService.logError(errMessage, null, '', true);
            return;
        }

        // convert the search params into actual http request params
        this.isSubmitted = true;
        const httpParams = this._parseSearchParams();
        this.reportingService.createAnimalCheckFileRequest(httpParams).then(() => {
            this.loggingService.logSuccess(
                'Your task export has begun',
                "AnimalCheck",
                "animal-check-requester",
                true
            );

            this.isSubmitted = true;
            this.exit.emit();
        }).catch((error: any) => {
            this.isSubmitted = true;

            this.loggingService.logError(
                'Bulk export request failed: ' + error.message,
                "AnimalCheck",
                "animal-check-requester",
                true
            );
        });
    }

    cancelClicked(): void {
        this.exit.emit();
    }

    /**
     * Gets the taxon from the line and assigns it to the "Species" input
     */
    onLineChange(lineKey: number): void {
        this.lineService.getTaxonByLineKey(lineKey).then((taxon: any) => {
            if (taxon) {
                this.taxon = taxon;
            } else {
                this.taxon = {
                    CommonName: ''
                };
            }
        });
    }

    /**
     * If the form has a collection date or a completed date and at least one task selected, it is valid.
     */
    get isFormValid(): boolean {
        // !! coerces the value to be a boolean
        const hasCollectionDate: boolean = !!this.searchParams.collectedEndDate || !!this.searchParams.collectedStartDate;
        const hasCompletedDate: boolean = !!this.searchParams.completedEndDate || !!this.searchParams.completedStartDate;
        const hasOneTask: boolean = this.taskSelections.filter((e: any) => e).length > 0;
        return (hasCollectionDate || hasCompletedDate) && hasOneTask;
    }

    /**
     * @returns true if there is at least one task that is non-null
     */
    get hasOneTask(): boolean {
        return this.taskSelections.filter((e: any) => !!e).length > 0;
    }

    private _initialize(): void {
        this.domIdAddition = randomId();
        this.isSubmitted = false;
        this.searchParams = {} as IAnimalCheckParams;
    }

    private _parseSearchParams(): HttpParams {
        let params = new HttpParams();

        params = params.set("Username", this.authService.getCurrentUserName());        
        const workflowTaskKeys = this.taskSelections
            .filter((wft: any) => wft)
            .join(",");
        params = params.set("WorkflowTaskKeys", workflowTaskKeys);
        if (this.searchParams.collectedStartDate) {
            params = params.set("CollectedStartDate", this.searchParams.collectedStartDate.toISOString());
        }
        if (this.searchParams.collectedEndDate) {
            params = params.set("CollectedEndDate", this.searchParams.collectedEndDate.toISOString());
        }
        if (this.searchParams.completedStartDate) {
            params = params.set("CompletedStartDate", this.searchParams.completedStartDate.toISOString());
        }
        if (this.searchParams.completedEndDate) {
            params = params.set("CompletedEndDate", this.searchParams.completedEndDate.toISOString());
        }
        if (this.searchParams.cohortKeys) {
            const cohortKeys = this.searchParams.cohortKeys.map((c: any) => c.C_Cohort_key).join(",");
            params = params.set("CohortKeys", cohortKeys);
        }
        if (this.searchParams.jobKeys) {
            const jobKeys = this.searchParams.jobKeys.map((j: any) => j.JobKey).join(",");
            params = params.set("JobKeys", jobKeys);
        }
        if (this.searchParams.lineKey) {
            params = params.set("LineKey", this.searchParams.lineKey);
        }
        if (this.searchParams.materialIdentifiers) {
            params = params.set("MaterialIdentifiers", this.searchParams.materialIdentifiers.join(","));
        }
        return params;
    }

}

import {
    Component,
    ChangeDetectionStrategy,
    Input,
    Output,
    EventEmitter,
    HostBinding,
    HostListener,
    ChangeDetectorRef
} from '@angular/core';
import {star, starFilled} from '@icons';
import {WorkspaceService} from '../../../workspaces/workspace.service';
import { TranslationService } from '@services/translation.service';
import { DashboardDataService } from '../../services/dashboard-data.service';
import { WorkspaceDetail } from '@common/types/models/workspace-detail.interface';

@Component({
    selector: 'climb-workspace-card',
    templateUrl: './workspace-card.component.html',
    styleUrls: ['./workspace-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkspaceCardComponent {
    icons = { star, starFilled };
    facets!: { details: { FacetName: string, FacetDisplayName: string }[], plus: number };
    isLoading = false;

    @Input() key!: number;
    @Input() name!: string;
    @Input() set details(value: WorkspaceDetail[]) {
        this.prepareDetails(value);
        this.cdr.markForCheck();
    }
    @Input() isFavorite = false;

    @Output() open = new EventEmitter<void>();

    @HostBinding('role')
    get role() {
        return 'button';
    }

    @HostBinding('tabindex')
    get tabindex() {
        return '0';
    }

    @HostListener('click') onClick() {
        this.openFacet();
    }

    @HostListener('keydown.enter') onEnter() {
        this.openFacet();
    }

    constructor(
        private cdr: ChangeDetectorRef,
        private dashboardService: DashboardDataService,
        private workspaceService: WorkspaceService,
        private translationService: TranslationService,
    ) {}

    get workspaceIcons() {
        return this.workspaceService.facetIcons;
    }

    openFacet() {
        this.open.emit();
    }

    toggle(event: Event) {
        event.stopPropagation();
        this.isLoading = true;
        this.cdr.markForCheck();

        const finish = () => {
            this.isLoading = false;
            this.cdr.markForCheck();
        }
        this.dashboardService.toggleFavorite(this.key)
            .subscribe(finish, finish);
    }
    
    private prepareDetails(details: WorkspaceDetail[] = []) {
        const isMore6 = details.length > 6;
        const preparedDetails = (isMore6 ? details.slice(0, 5) : details).map(item => ({
            FacetName: item.FacetName,
            FacetDisplayName: this.translationService.translate(item.FacetDisplayName ?? item.FacetName ?? '')
        }));
        
        this.facets = {
            details: preparedDetails,
            plus: isMore6 ? details.length - 5 : 0,
        };
    }
}

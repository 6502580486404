import { Pipe, PipeTransform } from '@angular/core';

interface Observation {
    cv_ClinicalObservation: unknown;
    cv_Modifier1?: {
        Modifier1: string;
    };
    cv_Modifier2?: {
        Modifier2: string;
    };
    cv_Modifier3?: {
        Modifier3: string;
    };
    cv_Modifier4?: {
        Modifier4: string;
    };
}

/**
 * Given an observation, gives the format "BODY SYSTEM: OBSERVATION MODIFIER1, MODIFIER2, MODIFIER3, MODIFIER4"
 */
@Pipe({
    name: 'clinicalObservationModifiersFormat',
    pure: false
})
export class ClinicalObservationModifierFormatPipe implements PipeTransform {

    transform<T extends Observation>(observation: T): string {
        const clinicalObservationVocab = observation.cv_ClinicalObservation;
        if (clinicalObservationVocab) {
            const modifier1 = observation.cv_Modifier1;
            const modifier2 = observation.cv_Modifier2;
            const modifier3 = observation.cv_Modifier3;
            const modifier4 = observation.cv_Modifier4;

            const mod1String = modifier1 ? modifier1.Modifier1 : null;
            const mod2String = modifier2 ? modifier2.Modifier2 : null;
            const mod3String = modifier3 ? modifier3.Modifier3 : null;
            const mod4String = modifier4 ? modifier4.Modifier4 : null;

            const parts = [mod1String, mod2String, mod3String, mod4String].filter((p: string | null) => p);
            return parts.join(', ');
        } else {
            return '';
        }
    }
}

import type { Genotype } from './genotype.interface';
import type { GenotypeAssayGenotypeSymbol } from './genotype-assay-genotype-symbol.interface';
import type { LineGenotypeAssay } from './line-genotype-assay.interface';

export interface cv_GenotypeAssay {
    C_GenotypeAssay_key: number;
    C_VocabularySource_key: number | null;
    C_Workgroup_key: number;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    ElementID: string;
    Genotype: Genotype[];
    GenotypeAssay: string;
    GenotypeAssayGenotypeSymbol: GenotypeAssayGenotypeSymbol[];
    IsActive: boolean;
    IsDefault: boolean;
    IsDeprecated: boolean;
    LineGenotypeAssay: LineGenotypeAssay[];
    ModifiedBy: string;
    SortOrder: number | null;
    Version: number;
}

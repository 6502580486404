import type { Animal } from './animal.interface';
import type { Mating } from './mating.interface';

export interface cv_Diet {
    Animal: Animal[];
    C_Diet_key: number;
    C_VocabularySource_key: number | null;
    C_Workgroup_key: number;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    Description: string;
    Diet: string;
    ElementID: string;
    IsActive: boolean;
    IsDefault: boolean;
    IsDeprecated: boolean;
    Mating: Mating[];
    ModifiedBy: string;
    SortOrder: number | null;
    Version: number;
}

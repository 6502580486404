import type { Birth } from './birth.interface';
import type { Material } from './material.interface';

export interface BirthMaterial {
    Birth: Birth;
    C_BirthMaterial_key: number;
    C_Birth_key: number;
    C_Material_key: number;
    C_Workgroup_key: number;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    Material: Material;
    ModifiedBy: string;
    Version: number;
}

import { chain, pick } from "lodash";
import { FACET_FULL_H, FACET_FULL_W, FACET_HALF_H, FACET_HALF_W, Facet } from "./types";
import { isWorkspaceDetails } from "../helpers";
import { EntityState } from "breeze-client";

export const collapse = (facet: Facet) => ({
  ...facet,
  ...facet.collapsedDimensions,
  expanded: false,
});

export const collapseAll = (facets: Facet[]) => facets.map(item => item.expanded ? collapse(item) : item);

export const expand = (facet: Facet) => ({
  ...facet,
  x: -1,
  y: 0,
  w: FACET_FULL_W,
  h: FACET_FULL_H,
  collapsedDimensions: pick(facet, ['x', 'y', 'w', 'h']),
  expanded: true
});

const setSize = (facet: Facet, w: number, h: number) => ({ ...facet, w, h });
const setPosition = (facet: Facet, x: number, y: number) => ({ ...facet, x, y });
export const autoSize = (facets: Facet[]) => chain(facets)
  .map((item, index, list) => {
    // last odd element
    if (index === list.length - 1 && list.length % 2 === 1) {
      return setSize(item, FACET_FULL_W, list.length === 1 ? FACET_FULL_H : FACET_HALF_H);
    }
    // full H if elements count less 3
    return setSize(item, FACET_HALF_W, list.length < 3 ? FACET_FULL_H : FACET_HALF_H);
  })
  .map((item, index) => {
    const yOffset = Math.floor(index / 2) * FACET_HALF_H + (index < 3 ? 0 : 1);
    const xOffset = (index % 2) * FACET_HALF_W;
    return setPosition(item, xOffset, yOffset)
  })
  .value();

// put all related fields in breezy for each of facet
export const syncWithBreezy = (facets: Facet[]): Facet[] => facets.map(facet => {
  if (isWorkspaceDetails(facet.facet)) {
    facet.facet.Column = facet.x;
    facet.facet.Row = facet.y;
    facet.facet.SizeX = facet.w;
    facet.facet.SizeY = facet.h;
    facet.facet.collapseColumn = facet.collapsedDimensions.x;
    facet.facet.collapseRow = facet.collapsedDimensions.y;
    facet.facet.collapseSizeX = facet.collapsedDimensions.w;
    facet.facet.collapseSizeY = facet.collapsedDimensions.h;
    return { ...facet };
  }

  return facet;
});

export const closeFacet = (
  facets: Facet[],
  { id }: { id: string},
): Facet[] => facets.filter(facet => {
  if (facet.id === id) {
    if (isWorkspaceDetails(facet.facet)) {
      if (facet.facet.entityAspect.entityState !== EntityState.Detached) {
        facet.facet.entityAspect.setDeleted();
        return;
    }
    }
    return false;
  }
  return true;
});


import { Injectable } from '@angular/core';

import { TranslationService } from '../../services/translation.service';
import { UserNameService } from '../../user/user-name.service';
import { CsvExporter } from '../../common/export/csv-exporter';
import { DateFormatterService } from '@common/util/date-time-formatting';

/**
 * Exports a sample audit report to CSV
 */
@Injectable()
export class ExportSampleAuditService {
    csvExporter: CsvExporter;

    constructor(
        private translationService: TranslationService,
        private userNameService: UserNameService,
        private dateFormatterService: DateFormatterService
    ) {
        this.csvExporter = new CsvExporter();
    }


    exportToCsv(
        auditData: any[],
        auditSourceData: any[],
        auditJobsData: any[],
        auditConstructsData: any[]
    ) {
        const EXPORTER_CSV_FILENAME = "SampleAudit.csv";

        const data: any[][] = this.buildExportData(
            auditData,
            auditSourceData,
            auditJobsData,
            auditConstructsData
        );

        this.csvExporter.download(data, EXPORTER_CSV_FILENAME);
    }

    buildExportData(
        auditData: any[],
        auditSourceData: any[],
        auditJobsData: any[],
        auditConstructsData: any[]
    ): any[][] {
        const data: any[][] = [];

        data.push(['Details']);
        data.push([
            'Action',
            'Modified Date',
            'Modified By',
            'ID',
            'Name',
            'Type',
            'Harvest Date',
            'Preservation Method',
            'Expiration Date',
            this.translationService.translate('Line'),
            'Species',
            'Status',
            'Origin',
            'Microchip ID',
            'Container',
            'Measurement',
            'Unit',
            'Subtype',
            'Processing',
            'Send To',
            'Analysis',
            'OrderID',
            'Lot Number',
            'Special Instructions'
        ]);
        for (const row of auditData) {
            data.push([
                row.UpdateType,
                this.dateFormatterService.formatDateTimeUTC(row.ModifiedDate),
                this.userNameService.toFullName(row.ModifiedBy),
                row.Identifier,
                row.SampleName,
                row.SampleType,
                this.dateFormatterService.formatDateOnlyUTC(row.DateHarvest),
                row.PreservationMethod,
                this.dateFormatterService.formatDateOnlyUTC(row.DateExpiration),
                row.LineName,
                row.Species,
                row.SampleStatus,
                row.MaterialOrigin,
                row.MicrochipIdentifier,
                row.ContainerType,
                row.Volume,
                row.Unit,
                row.SampleSubtype,
                row.SampleProcessingMethod,
                row.SendTo,
                row.SampleAnalysisMethod,
                row.OrderID,
                row.LotNumber,
                row.SpecialInstructions
            ]);
        }

        if (auditSourceData.length > 0) {
            data.push([]);
            data.push(['Sources']);
            data.push([
                'Action',
                'Modified Date',
                'Modified By',
                'Source Type',
                'Source Name'
            ]);
            for (const row of auditSourceData) {
                data.push([
                    row.UpdateType,
                    this.dateFormatterService.formatDateTimeUTC(row.ModifiedDate),
                    this.userNameService.toFullName(row.ModifiedBy),
                    row.SourceType,
                    row.SourceName
                ]);
            }
        }

        if (auditJobsData.length > 0) {
            data.push([]);
            data.push([this.translationService.translate('Jobs')]);
            data.push([
                'Action',
                'Modified Date',
                'Modified By',
                this.translationService.translate('Job') + ' Name'
            ]);
            for (const row of auditJobsData) {
                data.push([
                    row.UpdateType,
                    this.dateFormatterService.formatDateTimeUTC(row.ModifiedDate),
                    this.userNameService.toFullName(row.ModifiedBy),
                    row.JobID
                ]);
            }
        }

        if (auditConstructsData.length > 0) {
            data.push([]);
            data.push([this.translationService.translate('Constructs')]);
            data.push([
                'Action',
                'Modified Date',
                'Modified By',
                'Construct ID'
            ]);
            for (const row of auditConstructsData) {
                data.push([
                    row.UpdateType,
                    this.dateFormatterService.formatDateTimeUTC(row.ModifiedDate),
                    this.userNameService.toFullName(row.ModifiedBy),
                    row.ConstructID
                ]);
            }
        }

        return data;
    }
}

import { BulkAddExitReason } from './bulk-add-exit-reason.enum';
import { TemplateRef } from '@angular/core';

export type ExitReasonConfig = {
  [key in BulkAddExitReason]?: {
    exist?: boolean;
    disabled?: boolean;
    tooltip?: string;
  };
};

/**
 * Options object to pass into BulkAdd or BulkEditTable components
 */
export class BulkEditOptions {
    itemTypeLabel: string;
    itemTypeLabelPlural: string;
    fields: BulkEditField[];
    clearForm = false;
    // default to 50
    maxNumberItemsToAdd?: number;
    // optional template for "number of items to add" section
    itemsToAddTemplate?: TemplateRef<any>;

    // optional validator functions to disable the Save button
    saveButtonValidators?: [() => boolean];

    exitReasonConfig?: ExitReasonConfig;

    // used internally by the bulk add component
    /* eslint-disable-next-line */
    __addObject?: any;

    // optional function to check if there are pending facet-level changes
    hasFacetChanges?: () => boolean;
}

/**
 * Configuration for individual field in a bulk edit/add screen
 */
export class BulkEditField {
    label: string;
    labelTooltip?: string;
    subLabel?: string;
    modelPath: string;
    modelSettingName?: string;
    // Any additional information to inform custom template formatting.  
    fieldInformation?: any;
    onUpdateItem?: (item: any, value: any) => void;
    // option function to initialize any properties on the 
    // __updateObject or __addObject
    onItemInit?: (item: any) => void;
    template: TemplateRef<any>;
    // set the showExtraMenuItems option on bulk-edit-header component
    extraMenuItemsTemplate?: TemplateRef<any>;
    // show a custom button on the side of the column header
    customSideButtonTemplate?: TemplateRef<any>;
    // optional flags
    hideFromAddScreen?: boolean;
    hideFromEditHeader?: boolean;
    hideFromEditScreen?: boolean;
    cellIsReadonly?: boolean;
    // sets all above flags for Add/Edit/Cell inputs
    fieldIsReadonly?: boolean;
    // Sets the field as marked inactive in facet settings
    inactive?: boolean;

    // used internally by the bulk edit component
    /* eslint-disable-next-line */
    __updateObject?: any;

    // User Config
    // Is the field visible in the edit table ?
    visible?: boolean = true;
    skipDropdown?: boolean;
    caretClicked?: () => void;
}

/**
 * Result of completing the BulkAdd screen (save/edit/cancel)
 */
export class BulkAddResult {
    reason: BulkAddExitReason;
    numberItemsToAdd: number;
    // initial values for newItems (submitted by the form)
    initialValues: any;
    // any newly created items
    newItems?: any[];
    clearForm: boolean;
}

/**
 * config variables and functions to expose to 
 * optional "itemsToAddTemplate"
 */
export class BulkAddState {
    numberItemsToAdd: number;
    limitNumberItemsToAdd: () => void;
}

/**
 * User configuration of the Bulk Edit fields
 */
export class BulkEditConfig {
    fields: { [key: string]: BulkEditFieldConfig } = {};
}

/**
 * User configuration of a single Bulk Edit field
 */
export class BulkEditFieldConfig {
    visible = true;
}

import { Directive, HostBinding, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { IconContent, IconSize } from './icon.interface';

@Directive({
    selector: 'svg[climbIcon]',
})
export class IconDirective implements OnInit {
    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input('climbIcon')
    get content(): IconContent {
        return this.iconContent;
    }
    set content(value: IconContent) {
        this.iconContent = value;
        this.clearCache();
    }
    private iconContent: IconContent;

    @Input()
    get title(): string {
        return this.iconTitle;
    }
    set title(value: string) {
        this.iconTitle = value;
        this.clearCache();
    }
    private iconTitle?: string;

    @HostBinding('attr.xmlns')
    @Input() xmlns = 'http://www.w3.org/2000/svg';

    // eslint-disable-next-line @angular-eslint/no-input-rename
    @HostBinding('attr.pointer-events')
    @Input('pointer-events') pointerEvents = 'none';

    @HostBinding('attr.role')
    @Input() role = 'img';

    @Input()
    get size(): IconSize {
        return this.iconSize;
    }
    set size(iconSize: IconSize) {
        this.iconSize = iconSize;
        this.setClasses();
        this.clearCache();
    }
    private iconSize: IconSize = 'sm';

    @HostBinding('attr.viewBox')
    @Input()
    set viewBox(viewBox: string) {
        this.iconViewBox = viewBox;
        this.clearCache();
    }
    get viewBox(): string {
        return this.iconViewBox ?? this.getViewBox();
    }
    private iconViewBox: string;

    @HostBinding('class')
    private classes = '';

    @HostBinding('innerHtml')
    get innerHtml() {
        if (!this.sanitizedCode) {
            this.sanitizedCode = this.sanitizer.bypassSecurityTrustHtml(this.getCode());
        }
        return this.sanitizedCode;
    }
    private sanitizedCode: SafeHtml = null;

    constructor(
        private sanitizer: DomSanitizer,
    ) { }

    ngOnInit(): void {
        this.setClasses();
    }

    getCode(): string {
        const title = this.title ? `<title>${this.title}</title>` : '';
        const code = this.content[this.size] ?? '';
        return title + code;
    }

    getViewBox(): string {
        switch (this.size) {
            case 'sm': return '0 0 16 16';
            case 'md': return '0 0 24 24';
            case 'lg': return '0 0 32 32';
        }
    }

    setClasses(): void {
        this.classes = `icon icon-${this.size}`;
    }

    clearCache(): void {
        this.sanitizedCode = null;
    }
}

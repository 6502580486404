import { InjectionToken } from "@angular/core";
import {
  COHORT_FACET_COMPONENT,
  JOB_FACET_COMPONENT,
  JOB_PHARMA_FACET_COMPONENT,
  ORDER_FACET_COMPONENT,
  RESOURCE_FACET_COMPONENT,
  SCHEDULE_FACET_COMPONENT,
} from './lazy-components.token';

export enum DynamicComponentType {
    CohortFacet = 'cohort-facet',
    JobFacet = 'job-facet',
    JobPharmaFacet = 'job-pharma-facet',
    OrderFacet = 'order-facet',
    ResourceFacet = 'resource-facet',
    ScheduleFacet = 'schedule-facet'
}

export class InjectionMap {
    token: InjectionToken<any>;
    loadModule: () => Promise<any>;
}

export class InjectionMapFactory {

    static getInjectionMap(type: DynamicComponentType): InjectionMap {
        let injectionMap: InjectionMap = null;
        switch (type) {
            case DynamicComponentType.CohortFacet:
                injectionMap = {
                    token: COHORT_FACET_COMPONENT,
                    loadModule: () => {
                        return import(
                            '../cohort/cohort.module'
                        ).then((mod) => {
                            return mod.CohortModule;
                        });
                    } 
                };
                break;
            case DynamicComponentType.JobFacet:
                    injectionMap = {
                        token: JOB_FACET_COMPONENT,
                        loadModule: () => {
                            return import(
                                '../jobs/jobs.module'
                            ).then((mod) => {
                                return mod.JobsModule;
                            });
                        } 
                    };
                    break;
            case DynamicComponentType.JobPharmaFacet:
                injectionMap = {
                    token: JOB_PHARMA_FACET_COMPONENT,
                    loadModule: () => {
                        return import(
                            '../jobs/jobs.module'
                        ).then((mod) => {
                            return mod.JobsModule;
                        });
                    } 
                };
                break;
            case DynamicComponentType.OrderFacet:
                injectionMap = {
                    token: ORDER_FACET_COMPONENT,
                    loadModule: () => {
                        return import(
                            '../orders/orders.module'
                        ).then((mod) => {
                            return mod.OrdersModule;
                        });
                    } 
                };
                break;
            case DynamicComponentType.ResourceFacet:
                injectionMap = {
                    token: RESOURCE_FACET_COMPONENT,
                    loadModule: () => {
                        return import(
                            '../resources/resources.module'
                        ).then((mod) => {
                            return mod.ResourcesModule;
                        });
                    } 
                };
                break;
            case DynamicComponentType.ScheduleFacet:
                injectionMap = {
                    token: SCHEDULE_FACET_COMPONENT,
                    loadModule: () => {
                        return import(
                            '../schedule/schedule.module'
                        ).then((mod) => {
                            return mod.ScheduleModule;
                        });
                    } 
                };
                break;
        }

        return injectionMap;
    }
}


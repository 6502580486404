import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { ClimbCommonModule } from '../common/climb-common.module';
import { ClimbServicesModule } from '../services/climb-services.module';

import { AnimalService } from './services/animal.service';
import { AnimalVocabService } from './services/animal-vocab.service';
import { AnimalMarkerComponent } from './animal-marker.component';
import { AnimalMultiselectComponent } from './animal-multiselect.component';
import { AnimalNameRangeSelectComponent } from './animal-name-range-select.component';
import { AnimalSelectComponent } from './animal-select.component';
import { AnimalPlaceholderMultiselectComponent } from './animal-placeholder-multiselect.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ClimbCommonModule,
        ClimbServicesModule
    ],
    declarations: [
        AnimalMarkerComponent,
        AnimalMultiselectComponent,
        AnimalNameRangeSelectComponent,
        AnimalSelectComponent,
        AnimalPlaceholderMultiselectComponent
    ],
    exports: [
        AnimalMarkerComponent,
        AnimalMultiselectComponent,
        AnimalNameRangeSelectComponent,
        AnimalSelectComponent,
        AnimalPlaceholderMultiselectComponent
    ]
})
export class AnimalsSharedModule { 
    static forRoot(): ModuleWithProviders<AnimalsSharedModule> {
        return {
          ngModule: AnimalsSharedModule,
          providers: [
            AnimalService,
            AnimalVocabService
          ]
        };
    }
}

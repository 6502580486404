import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { apiServiceBaseUri } from '../config/climb-web-settings';


@Injectable()
export class DeviceManagementService {
    constructor(
        private http: HttpClient,
    ) { }

    getDevices() {
        return this.http.get(this.devicesRoot);
    }

    approveRegistrationRequest(requestKeys: string[]) {
        const body = {
            action: 'approve-requests',
            requestKeys,
        };
        return this.http.post(this.devicesRoot, body);
    }

    removeDevice(deviceId: string) {
        return this.http.delete(`${this.devicesRoot}/${deviceId}`);
    }

    getRegistrationRequests() {
        return this.http.get(this.registrationRequestRoot);
    }

    rejectRegistrationRequest(requestId: string) {
        return this.http.delete(`${this.registrationRequestRoot}/${requestId}`);
    }

    private get devicesRoot() {
        return `${apiServiceBaseUri}/api/devicesiothub`;
    }

    private get registrationRequestRoot() {
        return `${apiServiceBaseUri}/api/registrationrequest`;
    }
}

<!-- Template for typeahead options -->
<ng-template #sourceResultTemplate let-r="result">
    <span *ngIf="r && r.Animal" class="material-option material-option-animal">
        {{r.Animal.AnimalName}}
    </span>
    <span *ngIf="r && r.Sample" class="material-option material-option-sample">
        {{r.Sample.SampleName}}
    </span>
</ng-template>

<!-- Table for source materials -->
<div *ngIf="model?.length > 0">
    <table class="table table-sm table-borderless sample-source-material-table">
        <thead *ngIf="enableStatusColumn">
            <tr>
                <th *ngIf="!readonly">&nbsp;</th>
                <th>Animal/Sample</th>
                <th class="text-nowrap">Source Microchip ID</th>
                <th>Status</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let sourceMaterial of model">
                <td class="icon-cell" *ngIf="!readonly">
                    <div [ngClass]="{ 'form-control-plaintext': enableStatusColumn }"
                        *ngIf="sourceMaterial.AnimalName">
                        <a (click)="removeMaterialSourceMaterial(sourceMaterial)">
                            <i class="fa fa-remove remove-item" title="Remove animal source"></i>
                        </a>
                    </div>
                    <div [ngClass]="{ 'form-control-plaintext': enableStatusColumn }"
                        *ngIf="sourceMaterial.SampleName">
                        <a (click)="removeMaterialSourceMaterial(sourceMaterial)">
                            <i class="fa fa-remove remove-item" title="Remove sample source"></i>
                        </a>
                    </div>
                </td>
                <td class="text-nowrap">
                    <div [ngClass]="{ 'form-control-plaintext': enableStatusColumn }"
                         *ngIf="sourceMaterial.AnimalName">
                        <img src="/images/animals.png" width="12" height="12" alt="Animal" title="Animal" />
                        {{sourceMaterial.AnimalName}}
                    </div>
                    <div [ngClass]="{ 'form-control-plaintext': enableStatusColumn }"
                         *ngIf="sourceMaterial.SampleName">
                        <img src="/images/samples.png" width="12" height="12" alt="Sample" title="Sample" />
                        {{sourceMaterial.SampleName}}
                    </div>
                </td>
                <td class="text-nowrap">
                    <div [ngClass]="{ 'form-control-plaintext': enableStatusColumn }">
                        {{sourceMaterial.Material.MicrochipIdentifier}}
                    </div>
                </td>
                <td *ngIf="enableStatusColumn">
                    <span *ngIf="sourceMaterial.AnimalName">
                        <active-vocab-select [(model)]="sourceMaterial.C_AnimalStatus_key"
                                             (modelChange)="animalStatusChanged(sourceMaterial)"
                                             [vocabChoices]="animalStatuses"
                                             [keyFormatter]="animalStatusKeyFormatter"
                                             [optionFormatter]="animalStatusFormatter"
                                             [readonly]="readonly"></active-vocab-select>

                    </span>
                    <span *ngIf="sourceMaterial.SampleName">
                        <active-vocab-select [(model)]="sourceMaterial.C_SampleStatus_key"
                                             (modelChange)="sampleStatusChanged(sourceMaterial)"
                                             [vocabChoices]="sampleStatuses"
                                             [keyFormatter]="sampleStatusKeyFormatter"
                                             [optionFormatter]="sampleStatusFormatter"
                                             [readonly]="readonly"></active-vocab-select>
                    </span>
                </td>
            </tr>
        </tbody>
    </table>
</div>

<!-- Selector for Animal/Sample Material -->
<climb-indirect-typeahead [search]="searchMaterials"
                          [resultTemplate]="sourceResultTemplate"
                          [resultFormatter]="sourceResultFormatter"
                          [exactMatchFunction]="isMaterialExactMatch"
                          [placeholder]="'Add animal or sample&hellip;'"
                          (selectItem)="selectMaterialAction($event)"
                          [required]="required">
    <ng-content select="[extra-buttons]"></ng-content>
</climb-indirect-typeahead>

import { Component, Input } from '@angular/core';

import { compareDates } from '@common/date/compare-dates';

@Component({
    selector: 'mating-litter-size-sparkline',
    template: `
        <svg [attr.width]="width" [attr.height]="height">
            <g attr.transform="translate(0, {{height}}) scale(1, -1)">
                <rect
                    *ngFor="let birth of births; let i = index"
                    class="live-count-bar"
                    [attr.x]="binWidth * i"
                    [attr.width]="binWidth"
                    [attr.height]="birthBarHeight(birth)"></rect>
                <rect
                    *ngFor="let birth of births; let i = index"
                    class="stillborn-count-bar"
                    [attr.x]="binWidth * i"
                    [attr.y]="birthBarHeight(birth)"
                    [attr.width]="binWidth"
                    [attr.height]="stillbornBarHeight(birth)"></rect>
            </g>
        </svg>
    `,
    styles: [`
        .live-count-bar {
            fill: rgb(31, 119, 180);
            stroke: rgb(0, 0, 0);
        }

        .stillborn-count-bar {
            fill: rgb(255, 127, 14);
            stroke: rgb(0, 0, 0);
        }
    `]
})
export class MatingLitterSizeSparklineComponent {
    @Input() mating: any = null;
    @Input() width = 200;
    @Input() height = 50;

    get births(): any[] {
        if (this.mating) {
            return this.mating.Birth
                .filter((birth: any) => birth.DateBorn !== null && birth.DateBorn !== undefined)
                .sort((birth1: any, birth2: any) => compareDates(birth1.DateBorn, birth2.DateBorn));
        } else {
            return [];
        }
    }

    get binWidth(): number {
        return this.width / this.births.length;
    }

    get maxBarCount(): number {
        let maxCount = 0;
        if (this.mating) {
            for (const currBirth of this.mating.Birth) {
                const currCount = currBirth.LiveCount + currBirth.StillbornCount;
                if (currCount > maxCount) {
                    maxCount = currCount;
                }
            }
        }

        return maxCount;
    }

    birthBarHeight(birth: any): number {
        return this._barHeight(birth.LiveCount);
    }

    stillbornBarHeight(birth: any): number {
        return this._barHeight(birth.StillbornCount);
    }

    private _barHeight(count: number): number {
        const height = (count / this.maxBarCount) * this.height;
        if (isNaN(height) || !isFinite(height)) {
            return 0;
        } else {
            return height;
        }
    }
}

<dx-chart
    id="chart"
    palette="soft"
    [dataSource]="data"
    [rotated]="true">
    <dxo-size [height]="dynamicHeight" width="500"></dxo-size>
    <dxo-title [text]="outputName"></dxo-title>
    <dxo-common-series-settings 
        argumentField="name"
        type="bar"
        valueField="mean"
        [showInLegend]="false"
        [ignoreEmptyPoints]="true">
        <dxo-value-error-bar
            lowValueField="low"
            highValueField="high"
            [lineWidth]="1"
            [opacity]="0.8">
        </dxo-value-error-bar>
    </dxo-common-series-settings>
    <dxo-series-template nameField="name"></dxo-series-template>
    <dxo-argument-axis>
        <dxo-label [customizeText]="customizeArgumentLabels"></dxo-label>
    </dxo-argument-axis>
    <dxi-value-axis 
        name="mean"
        position="bottom">
        <dxo-grid [visible]="true"></dxo-grid>
        <dxo-title [text]="'Average ' + outputName"></dxo-title>
    </dxi-value-axis>
    <dxo-tooltip
        [enabled]="true"
        contentTemplate="content">
    </dxo-tooltip>
    <div *dxTemplate="let info of 'content'">
        <h4>
            {{info.seriesName}}
        </h4>
        <hr/>
        <span>
            Average {{outputName}}: {{info.value}}
        </span>
        <br/>
        <span>
            SD (σ): {{info.point.data.standardDeviation}}
        </span>
        <br/>
        <span>
            Min: {{info.point.data.min}}
        </span>
        <br/>
        <span>
            Max: {{info.point.data.max}}
        </span>
    </div>
</dx-chart>
import {
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter,
} from '@angular/core';
import * as Dropzone from 'dropzone';
import { DropzoneCommService } from './dropzone-comm.service';
import { findElementByQuery } from '../util/find-element';
import { randomId } from '../util/random-id';
import { AuthService } from '@services/auth.service';
import { apiServiceBaseUri } from '../../config/climb-web-settings';
import { LocalStorageKey } from '@config';

@Component({
    selector: 'climb-dropzone',
    template: `
        <div class="dropzone {{_elementClass}} d-flex justify-content-center align-items-center"></div>
    `
})

/*Dropzone for file uploads*/
export class ClimbDropzoneComponent implements OnInit {
    readonly DEFAULT_URL = apiServiceBaseUri + "api/storage/files";

    // Optionally change url from DEFAULT_URL
    @Input() url: string;
    // Disallow multiple files to be uploaded (limit to 1)
    @Input() disallowMultiple: boolean;
    // Event on successful upload
    @Output() success: EventEmitter<any> = new EventEmitter<any>();
    // Event on click to remove file (in dropzone)
    @Output() removedfile: EventEmitter<any> = new EventEmitter<any>();

    dropzone: Dropzone;

    // class identifying new element to be used for dropzone
    _elementClass: string;

    constructor(
        private dropzoneCommService: DropzoneCommService,
        private authService: AuthService
    ) { }

    ngOnInit() {
        if (!this.url) {
            this.url = this.DEFAULT_URL;
        }
        this._elementClass = "dropzone_" + randomId();

        // make sure DOM element with class exists before initializing
        findElementByQuery("." + this._elementClass).then((element: any) => {
            this.initDropzone();
        });
    }

    initDropzone() {
        const workgroupKey = window.localStorage.getItem(`ls.${LocalStorageKey.WORKGROUP_KEY}`); 
        const authToken = this.authService.getCurrentToken();
        this.dropzone = new Dropzone("." + this._elementClass, {
            url: this.url,
            uploadMultiple: false,
            addRemoveLinks: true,
            maxFilesize: 512,
            dictDefaultMessage: 'Drop files here or click to select files to upload',
            headers: { 
                'X-Workgroup-Key': workgroupKey,
                'Authorization': 'Bearer ' + authToken
         },
        });

        this.dropzone.on("success", (file: any, json: any) => {

            // If necessary, limit file upload to 1 file
            if (this.disallowMultiple &&
                this.dropzone.files[1] != null
            ) {
                this.dropzone.removeFile(this.dropzone.files[0]);
            }
            this.success.emit({
                fileData: json,
                description: file.name
            });
        });

        this.dropzone.on("removedfile", (file: any) => {
            this.removedfile.emit(file);
        });

        // wire up incoming communication from parent component
        this.dropzoneCommService.removedFile$
        .subscribe((file: any) => {
            // find specific file to remove
            for (const dropzoneFile of this.dropzone.files) {
                if (file.FriendlyFileName === dropzoneFile.name) {
                    this.dropzone.removeFile(dropzoneFile);
                }
            }
        });

        this.dropzoneCommService.removedAllFiles$
        .subscribe(() => {
            this.dropzone.removeAllFiles();
        });

    }
}

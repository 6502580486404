import { Directive, EventEmitter, Injector, OnInit, Output } from '@angular/core';
import { MixinUnsubscribeClass } from '@common/mixins';
import { SortService } from './sort.service';
import type { SortState } from './sort.interface';

@Directive({
    selector: 'climb-table, table[climbTable]',
})
export class TableSortDirective extends MixinUnsubscribeClass implements OnInit {
    @Output() sortChanged: EventEmitter<SortState> = new EventEmitter();

    constructor(
        injector: Injector,
        private sortService: SortService,
    ) {
        super(injector);
    }

    ngOnInit(): void {
        super.ngOnInit();

        this.subscription = this.sortService.sortState$.subscribe((state: SortState) => {
            this.sortChanged.emit(state);
        });
    }
}

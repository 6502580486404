<ng-container *ngIf="newFilter.chartFilter"
              [ngSwitch]="newFilter.type.type">

    <ng-container *ngSwitchCase="ChartFilterType.Animal">
        <label class="sr-only">Animal</label>
        <climb-typeahead [(model)]="fakeModel"
                         [search]="searchAnimals"
                         [resultFormatter]="animalNameFormatter"
                         [placeholder]="'Animal Name or Microchip ID&hellip;'"
                         (selectItem)="selectAnimal(newFilter, $event)"></climb-typeahead>
    </ng-container>

    <ng-container *ngSwitchCase="ChartFilterType.Cohort">
        <label class="sr-only">Cohort</label>
        <climb-typeahead [(model)]="fakeModel"
                         [search]="searchCohorts"
                         [resultFormatter]="cohortNameFormatter"
                         [placeholder]="'Cohort name&hellip;'"
                         (selectItem)="selectCohort(newFilter, $event)"></climb-typeahead>
    </ng-container>

    <ng-container *ngSwitchCase="ChartFilterType.Genotype">
        <div class="inline-input mr-1 mb-1">
            <label class="sr-only">Assay</label>
            <genotype-assay-select [(model)]="fakeModel"
                                   [assayChoices]="genotypeAssays"
                                   [parentLineKey]=""
                                   [noFilterOnNullParent]="true"
                                   (modelChange)="selectGenotypeAssay(newFilter, $event)"></genotype-assay-select>
        </div>
        <div class="inline-input">
            <label class="sr-only">Genotype</label>
            <genotype-symbol-select [(model)]="fakeModel2"
                                    [symbolChoices]="genotypeSymbols"
                                    [parentAssayKey]="fakeModel"
                                    (modelChange)="selectGenotypeSymbol(newFilter, $event)"></genotype-symbol-select>
        </div>
    </ng-container>

    <ng-container *ngSwitchCase="ChartFilterType.Job">
        <label class="sr-only">{{'Job' | translate}}</label>
        <climb-typeahead [(model)]="fakeModel"
                         [search]="searchJobs"
                         [resultFormatter]="jobNameFormatter"
                         [placeholder]="('Job' | translate) + ' name&hellip;'"
                         (selectItem)="selectJob(newFilter, $event)"></climb-typeahead>
    </ng-container>

    <ng-container *ngSwitchCase="ChartFilterType.Line">
        <label class="sr-only">{{'Line' | translate}}</label>
        <climb-typeahead [(model)]="fakeModel"
                         [search]="searchLines"
                         [resultFormatter]="lineNameFormatter"
                         [placeholder]="('Line' | translate) + ' name&hellip;'"
                         (selectItem)="selectLine(newFilter, $event)"></climb-typeahead>
    </ng-container>

    <ng-container *ngSwitchCase="ChartFilterType.Task">
        <label class="sr-only" for="workflowTaskSelect-{{fieldId}}">Task</label>
        <select class="form-control"
                id="workflowTaskSelect-{{fieldId}}"
                name="workflowTask-{{fieldId}}"
                [(ngModel)]="newFilter.chartFilter.model">
            <option *ngFor="let task of workflowTasks"
                    [ngValue]="task">
                {{task.TaskName}}
            </option>
        </select>
    </ng-container>

    <ng-container *ngSwitchCase="ChartFilterType.TaskOutput">
        <div class="inline-input">
            <label class="sr-only" for="workflowTaskSelect-{{fieldId}}">Task</label>
            <select class="form-control"
                    id="workflowTaskSelect-{{fieldId}}"
                    name="workflowTask-{{fieldId}}"
                    [(ngModel)]="fakeModel"
                    (ngModelChange)="selectTask($event)">
                <option *ngFor="let task of workflowTasks"
                        [ngValue]="task">
                    {{task.TaskName}}
                </option>
            </select>
        </div>
        <div class="inline-input">
            <label class="sr-only" for="taskOutputSelect-{{fieldId}}">Output</label>
            <select class="form-control"
                    id="taskOutputSelect-{{fieldId}}"
                    name="taskOutput-{{fieldId}}"
                    [(ngModel)]="newFilter.chartFilter.model"
                    [disabled]="!(taskOutputs | notEmpty)">
                <option *ngFor="let output of taskOutputs"
                        [ngValue]="output">
                    {{output.OutputName}}
                </option>
            </select>
        </div>
    </ng-container>

    <ng-container *ngSwitchCase="ChartFilterType.Sex">
        <label class="sr-only" for="sexSelect-{{fieldId}}">Sex</label>
        <select class="form-control"
                id="sexSelect-{{fieldId}}"
                name="sex-{{fieldId}}"
                [(ngModel)]="newFilter.chartFilter.model">
            <option *ngFor="let sex of sexes"
                    [ngValue]="sex">
                {{sex.Sex}}
            </option>
        </select>
    </ng-container>

    <ng-container *ngSwitchCase="ChartFilterType.ExitReason">
        <label class="sr-only" for="exitReasonSelect-{{fieldId}}">Exit Reason</label>
        <select class="form-control"
                id="exitReasonSelect-{{fieldId}}"
                name="exitReason-{{fieldId}}"
                [(ngModel)]="newFilter.chartFilter.model">
            <option *ngFor="let exitReason of exitReasons"
                    [ngValue]="exitReason">
                {{exitReason.ExitReason}}
            </option>
        </select>
    </ng-container>

    <ng-container *ngSwitchCase="ChartFilterType.Study">
        <study-select [sendObject]="true" (modelChange)="selectStudy(newFilter, $event)"></study-select>
    </ng-container>

    <ng-container *ngSwitchCase="ChartFilterType.CohortIndividualAnimal">
        <label class="sr-only">Cohort(Individual Animals)</label>
        <climb-typeahead [(model)]="fakeModel"
                         [search]="searchCohorts"
                         [resultFormatter]="cohortNameFormatter"
                         [placeholder]="'Cohort name&hellip;'"
                         (selectItem)="selectCohort(newFilter, $event)"></climb-typeahead>
    </ng-container>
</ng-container>

import { Injectable, Injector, StaticProvider } from '@angular/core';
import { ComponentPortal, ComponentType } from '@angular/cdk/portal';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { DialogConfig } from '@common/dialog/dialog-config';
import { DialogRef } from '@common/dialog/dialog-ref';
import { CLIMB_DIALOG_DATA } from '@common/dialog/dialog-data.token';
import { DialogContainerComponent } from '@common/dialog/dialog-container/dialog-container.component';

@Injectable({ providedIn: 'root' })
export class DialogService {
    private readonly defaultDialogOptions: DialogConfig<never> = {
        hasBackdrop: true,
        backdropClass: 'cdk-overlay-dark-backdrop',
        panelClass: '',
        positionStrategy: this.overlay.position().global().centerHorizontally().top('80px'),
    } as const;
    private dialogRefs: DialogRef[] = [];

    constructor(
        private overlay: Overlay,
        private injector: Injector
    ) {}

    open<R, D, C>(component: ComponentType<C>, config?: DialogConfig<D>): DialogRef {
        config = { ...this.defaultDialogOptions, ...config };
        const overlayRef: OverlayRef = this.overlay.create(config);

        const dialogRef = new DialogRef<R, D, C>(overlayRef, config);
        const dialogContainer = this.attachContainer(overlayRef);

        this.attachDialogContent(component, dialogRef, dialogContainer, config);
        this.dialogRefs.push(dialogRef);

        return dialogRef;
    }

    private createInjector<D>(
        config: DialogConfig<D>,
        dialogRef: DialogRef,
    ): Injector {
        const providers: StaticProvider[] = [
            { provide: CLIMB_DIALOG_DATA, useValue: config.data },
            { provide: DialogRef, useValue: dialogRef },
        ];

        return Injector.create({ parent: this.injector, providers });
    }

    private attachContainer(
        overlay: OverlayRef,
    ): DialogContainerComponent {
        const containerPortal = new ComponentPortal(
            DialogContainerComponent,
        );
        const containerRef = overlay.attach(containerPortal);
        return containerRef.instance;
    }

    private attachDialogContent<R, D, C>(
        component: ComponentType<C>,
        dialogRef: DialogRef<R, D, C>,
        dialogContainer: DialogContainerComponent,
        config: DialogConfig<D>,
    ) {
        const injector = this.createInjector(config, dialogRef);
        const contentRef = dialogContainer.attachComponentPortal(
            new ComponentPortal(
                component,
                undefined,
                injector,
                undefined,
            ),
        );
        dialogRef.componentInstance = contentRef.instance;
    }

    public closeAll(): void {
        for (const dialogRef of this.dialogRefs) {
            dialogRef.close();
        }
        this.dialogRefs = [];
    }
}

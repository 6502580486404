/*
 * Handles dragula drops of object arrays: resets element SortOrder values in the new order.
 */
export function reorderOnDropModel(array: any[]): void {
    if (array) {
        // dragula has by now synced the model with the new drag order, so 
        // we just need to update the SortOrders based on the current order.
        for (let i = 0; i < array.length; i++) {
            array[i].SortOrder = i + 1;
        }
    }
}

import {
    CellFormatterService,
    TableOptions,
    FormatterOptions
} from '../common/datatable';
import { AuditModifiedFieldComponent } from './audit-modified-fields/audit-modified-field.component';
import { AuditClassFactory } from './audit-class-maker';

export class AuditCohortsTableOptions {

    options: TableOptions;

    constructor(
        private cellFormatterService: CellFormatterService,
    ) {
        this.options = this.getDefaultOptions();
    }

    getDefaultOptions(): TableOptions {

        return {
            csvFileName: 'cohorts-audit.csv',
            enableDetailColumn: false,
            enableDraggable: false,
            enableSelectable: false,
            enableAuditColumns: false,
            columns: [
                {
                    displayName: 'Type',
                    field: 'UpdateType',
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Modified Fields',
                    field: 'ModifiedFields',
                    sortField: 'ModifiedFields',
                    rendererComponent: AuditModifiedFieldComponent,
                    exportFormatter: this.cellFormatterService.auditModifiedFieldsExportFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Date',
                    field: 'ModifiedDate',
                    formatter: this.cellFormatterService.dateTimeUTCFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Modified By',
                    field: 'ModifiedBy',
                    formatter: this.cellFormatterService.userNameFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Name',
                    field: 'Name',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('Name', 'Name'),
                    cellClass: AuditClassFactory.createCellClass('name'),
                },
                {
                    displayName: 'Description',
                    field: 'Description',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('Description', 'Description'),
                    cellClass: AuditClassFactory.createCellClass('description'),
                },
                {
                    displayName: 'Output1',
                    field: 'Output1',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('Output1', 'Output1'),
                    cellClass: AuditClassFactory.createCellClass('output1'),
                },
                {
                    displayName: 'Output2',
                    field: 'Output2',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('Output2', 'Output2'),
                    cellClass: AuditClassFactory.createCellClass('output2'),
                },
                {
                    displayName: 'Output3',
                    field: 'Output3',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('Output3', 'Output3'),
                    cellClass: AuditClassFactory.createCellClass('output3'),
                },
                {
                    displayName: 'Created By',
                    field: 'CreatedBy',
                    formatter: this.cellFormatterService.userNameFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Created Date',
                    field: 'DateCreated',
                    formatter: this.cellFormatterService.dateTimeUTCFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                }
            ]
        };
    }
}

import { Inject, Injectable, NgZone } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { EVENTS_TOKEN, Events } from '../mf-store';

@Injectable({
  providedIn: 'root'
})
export class ModernAppService {
  private readonly APP_NAME = 'climbWeb';
  private container: {
    init(value: unknown): Promise<void>
    get<T = unknown>(value: string): Promise<() => T>
  }

  private isInitialized$$ = new BehaviorSubject<boolean>(false);

  isInitialized$ = this.isInitialized$$.asObservable();

  facets$$ = new BehaviorSubject<ModernFacet[]>([]);
  
  public facets$ = this.facets$$.asObservable();

  get facets(): ModernFacet[] {
    return this.facets$$.value;
  }

  constructor(
    private ngZone: NgZone,
    @Inject(EVENTS_TOKEN)
    private events: Events,
  ) {}

  init(): void {
    this.ngZone.runOutsideAngular(() => {
      this.loadApp()
        .then(() => this.initApp())
        .then(() => this.loadModule<{ bootstrap: () => void }>('./bootstrap'))
        .then(({ bootstrap }) => bootstrap())
        .then(() => this.loadModule<{ FACET_LIST: ModernFacet[] }>('./facets'))
        .then(({ FACET_LIST }) => this.facets$$.next(FACET_LIST))
        .then(() => this.isInitialized$$.next(true))
        .catch(console.error);
    });
  }

  openFacet(elementId: string, componentId: string): void {
    this.ngZone.runOutsideAngular(() => {
      this.events.openFacet.emit({ elementId, componentId });
    });
  }

  private loadApp() {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      const newFacetsUrl = window['newFacetsUrl'];
      if (!newFacetsUrl) {
        reject(new Error('newFacetsUrl is not provided'));
        return;
      }
      script.src = `${newFacetsUrl}remoteEntry.js`;
      script.onload = () => {
        resolve({
          get: (request: unknown) => window[this.APP_NAME].get(request),
          init: (arg: unknown) => {
            try {
              return window[this.APP_NAME].init(arg);
            } catch (e) {
              console.log("climbWeb has already been loaded");
            }
          },
        })
      }

      document.body.append(script);
    });
  }

  private async initApp() {
    await __webpack_init_sharing__("default");
    this.container = window[this.APP_NAME]; // or get the container somewhere else
    // Initialize the container, it may provide shared modules
    await this.container.init(__webpack_share_scopes__.default);
  }

  private async loadModule<T = unknown>(moduleName: string) {
    const module = await this.container.get<T>(moduleName);
    return module();
  }
}

import { VocabularyService } from '../../../vocabularies/vocabulary.service';
import {
    Component,
    Input,
    OnInit,
    OnChanges
} from '@angular/core';


@Component({
    selector: 'workflow-line-details',
    templateUrl: './workflow-line-details.component.html',
})
export class WorkflowLineDetailsComponent implements OnInit, OnChanges {
    @Input() line: any;
    @Input() useTableFormat: boolean;

    constructor(
        private vocabularyService: VocabularyService
    ) { }

    ngOnInit() {
        this.initialize();
    }

    ngOnChanges(changes: any) {
        if (changes.line && !changes.line.firstChange) {
            // load any Line data
        }
    }

    initialize() {
        this.getCVs();
    }

    getCVs(): Promise<any> {
        // Ensure CVs loaded for line-task details
        const p1 = this.vocabularyService.ensureCVLoaded('cv_LineStatuses');
        const p2 = this.vocabularyService.ensureCVLoaded('cv_LineTypes');
        const p3 = this.vocabularyService.ensureCVLoaded('cv_Taxons');

        return Promise.all([p1, p2, p3]);
    }
}

import { Injectable } from '@angular/core';
import {
    EntityQuery,
    FilterQueryOp,
    Predicate,
    QueryResult
} from 'breeze-client';

import {
    getSafeProp,
    notEmpty,
    softCompare,
    uniqueArrayFromPropertyPath
} from '../common/util';
import { getDateRangePredicates } from '../services/queries';

import { DataManagerService } from '../services/data-manager.service';
import { QueryDef } from '../services/query-def';
import { BaseEntityService } from '../services/base-entity.service';
import { MaterialService } from '../services/material.service';
import { WebApiService } from '../services/web-api.service';

@Injectable()
export class SampleService extends BaseEntityService {

    // state variables
    draggedSamples: any[];

    constructor(
        private dataManager: DataManagerService,
        private materialService: MaterialService,
        private webApiService: WebApiService
    ) {
        super();
        this.draggedSamples = [];
    }

    getSamples(queryDef: QueryDef): Promise<QueryResult> {
        let query = this.buildDefaultQuery('Samples', queryDef);

        if (queryDef.expands) {
            query = query.expand(queryDef.expands.join(','));
        }

        let predicates: Predicate[] = [];
        if (queryDef.filter) {
            predicates = predicates.concat(this.buildPredicates(queryDef.filter));
        }

        if (notEmpty(predicates)) {
            query = query.where(Predicate.and(predicates));
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed) as Promise<QueryResult>;
    }

    buildPredicates(filter: any): Predicate[] {
        let predicates: Predicate[] = [];
        if (!filter) {
            return predicates;
        }

        if (notEmpty(filter.materialKeys)) {
            predicates.push(Predicate.create('C_Material_key', 'in', filter.materialKeys));
        }
        if (filter.Identifier && filter.Identifier !== 'null') {
            predicates.push(Predicate.create('Material.Identifier', 'eq', filter.Identifier));
        }
        if (notEmpty(filter.Identifiers)) {
            predicates.push(Predicate.create('Material.Identifier', 'in', filter.Identifiers));
        }
        if (notEmpty(filter.microchipIdentifiers)) {
            predicates.push(Predicate.create(
                'Material.MicrochipIdentifier', 'in', filter.microchipIdentifiers
            ));
        }
        if (filter.SampleName) {
            predicates.push(Predicate.create('SampleName', FilterQueryOp.Contains, { value: filter.SampleName }));
        }
        if (notEmpty(filter.SampleNames)) {
            predicates.push(Predicate.create('SampleName', 'in', filter.SampleNames));
        }
        if (filter.SourceName) {
            const pAnimal = Predicate.create(
                'SourceMaterial.Animal.AnimalName', FilterQueryOp.Contains, { value: filter.SourceName },
            );
            const pSample = Predicate.create(
                'SourceMaterial.Sample.SampleName', FilterQueryOp.Contains, { value: filter.SourceName },
            );
            const pCombo = Predicate.or(pAnimal, pSample);

            predicates.push(Predicate.create(
                'Material.MaterialSourceMaterial', 'any',
                pCombo
            ));
        }
        if (filter.sourceMicrochipIdentifier && filter.sourceMicrochipIdentifier !== 'null') {
            predicates.push(Predicate.create(
                'Material.MaterialSourceMaterial', 'any',
                'SourceMaterial.MicrochipIdentifier', 'eq', filter.sourceMicrochipIdentifier
            ));
        }
        if (notEmpty(filter.sourceMicrochipIdentifiers)) {
            predicates.push(Predicate.create(
                'Material.MaterialSourceMaterial', 'any',
                'SourceMaterial.MicrochipIdentifier', 'in', filter.sourceMicrochipIdentifiers
            ));
        }
        if (filter.C_Taxon_key) {
            predicates.push(Predicate.create('Material.C_Taxon_key', 'eq', filter.C_Taxon_key));
        }
        if (filter.C_SampleStatus_keys && filter.C_SampleStatus_keys.length > 0) {
            predicates.push(Predicate.create(
                'C_SampleStatus_key', 'in', filter.C_SampleStatus_keys
            ));
        }
        if (filter.C_SampleType_keys && filter.C_SampleType_keys.length > 0) {
            predicates.push(Predicate.create(
                'C_SampleType_key', 'in', filter.C_SampleType_keys
            ));
        }
        if (filter.C_PreservationMethod_key) {
            predicates.push(Predicate.create(
                'C_PreservationMethod_key', 'eq', filter.C_PreservationMethod_key
            ));
        }
        if (filter.C_ContainerType_key) {
            predicates.push(Predicate.create(
                'C_ContainerType_key', 'eq', filter.C_ContainerType_key
            ));
        }
        if (filter.TimePoint) {
            predicates.push(Predicate.create(
                'TimePoint', 'eq', filter.TimePoint
            ));
        }
        if (filter.C_TimeUnit_key) {
            predicates.push(Predicate.create(
                'C_TimeUnit_key', 'eq', filter.C_TimeUnit_key
            ));
        }

        if (filter.C_SampleSubtype_key) {
            predicates.push(Predicate.create(
                'C_SampleSubtype_key', 'eq', filter.C_SampleSubtype_key
            ));
        }

        if (filter.C_SampleProcessingMethod_key) {
            predicates.push(Predicate.create(
                'C_SampleProcessingMethod_key', 'eq', filter.C_SampleProcessingMethod_key
            ));
        }

        if (filter.SendTo) {
            predicates.push(Predicate.create(
                'SendTo', FilterQueryOp.Contains, { value: filter.SendTo },
            ));
        }

        if (filter.C_SampleAnalysisMethod_key) {
            predicates.push(Predicate.create(
                'C_SampleAnalysisMethod_key', 'eq', filter.C_SampleAnalysisMethod_key
            ));
        }

        // Deprecated: replaced by filter.lines
        if (filter.C_Line_Keys && filter.C_Line_Keys.length > 0) {
            predicates.push(Predicate.create(
                'Material.C_Line_key', 'in', filter.C_Line_Keys
            ));
        }
        if (notEmpty(filter.lines)) {
            const lineKeys = filter.lines.map((line: any) => {
                return line.LineKey;
            });

            predicates.push(Predicate.create(
                'Material.C_Line_key', 'in', lineKeys
            ));
        }
        if (notEmpty(filter.constructs)) {
            const constructKeys = filter.constructs.map((construct: any) => {
                return construct.ConstructKey;
            });

            predicates.push(Predicate.create(
                'SampleConstruct', 'any', 'Construct.C_Construct_key', 'in', constructKeys
            ));
        }
        if (filter.DateHarvestStart || filter.DateHarvestEnd) {
            const datePredicates: Predicate[] = getDateRangePredicates(
                'DateHarvest',
                filter.DateHarvestStart,
                filter.DateHarvestEnd
            );
            if (notEmpty(datePredicates)) {
                predicates = predicates.concat(datePredicates);
            }
        }
        if (filter.Location) {
            predicates.push(Predicate.create(
                'Material.CurrentLocationPath', FilterQueryOp.Contains, { value: filter.Location },
            ));
        }
        if (notEmpty(filter.Jobs)) {
            const jobIDs = filter.Jobs.map((job: any) => {
                return job.JobID;
            });

            predicates.push(Predicate.create(
                'Material.JobMaterial', 'any',
                'Job.JobID', 'in', jobIDs
            ));
        }
        if (filter.JobID) {
            predicates.push(Predicate.create(
                'Material.JobMaterial', FilterQueryOp.Any,
                'Job.JobID', FilterQueryOp.Contains, { value: filter.JobID },
            ));
        }
        if (filter.C_Study_key) {
            predicates.push(Predicate.create(
                'Material.JobMaterial', 'any',
                'Job.C_Study_key', 'eq', filter.C_Study_key
            ));
        }
        if (filter.C_SampleOrder_key) {
            predicates.push(Predicate.create(
                'C_SampleOrder_key', 'eq', filter.C_SampleOrder_key
            ));
        }
        if (filter.LotNumber) {
            predicates.push(Predicate.create(
                'LotNumber', FilterQueryOp.Contains, { value: filter.LotNumber },
            ));
        }

        if (filter.SpecialInstructions) {
            predicates.push(Predicate.create(
                'SpecialInstructions', FilterQueryOp.Contains, { value: filter.SpecialInstructions }
            ));
        }

        // handle workspace filters
        if ('animal-filter' in filter) {
            predicates.push(Predicate.create(
                'Material.MaterialSourceMaterial', 'any',
                'SourceMaterial.C_Material_key', 'in', filter['animal-filter']
            ));
        }
        if ('job-filter' in filter) {
            predicates.push(Predicate.create(
                'Material.JobMaterial', 'any',
                'Job.C_Job_key', 'in', filter['job-filter']
            ));
        }

        return predicates;
    }

    getSample(materialKey: number, expands?: string[]): Promise<any> {
        if (!expands) {
            expands = [];
        }

        this.ensureExpanded(expands, 'Material.JobMaterial.Job');
        this.ensureExpanded(expands, 'Material.Line');
        this.ensureExpanded(expands, 'Material.MaterialLocation.LocationPosition');
        this.ensureExpanded(expands, 'Material.MaterialSourceMaterial.SourceMaterial.Animal');
        this.ensureExpanded(expands, 'Material.MaterialSourceMaterial.SourceMaterial.Sample');
        this.ensureExpanded(expands, 'Material.MaterialExternalSync');

        const query = EntityQuery.from('Samples')
            .expand(expands.join(','))
            .where('C_Material_key', '==', materialKey);

        return this.dataManager.returnSingleQueryResult(query);
    }

    getFilteredSamples(filterText: string, limit: number): Promise<any[]> {
        const predicates: Predicate[] = [];

        if (filterText) {
            predicates.push(
                Predicate.or([
                    Predicate.create('SampleName', FilterQueryOp.Contains, { value: filterText }),
                    Predicate.create('Material.MicrochipIdentifier', "eq", filterText)
                ])
            );
        }
        else {
            predicates.push(
                Predicate.create('SampleName', "!=", null)
            );
        }

        let query = EntityQuery.from('Samples')
            .where(Predicate.and(predicates))
            .expand('Material')
            .orderBy('SampleNameSortable');

        if (limit) {
            query = query.top(limit);
        }

        return this.dataManager.returnQueryResults(query);
    }

    getSampleCharacteristics(materialKey: number): Promise<any[]> {
        const query = EntityQuery.from('SampleCharacteristicInstances')
            .expand('SampleCharacteristic.cv_DataType')
            .where('C_Material_key', '==', materialKey)
            .orderBy('SampleCharacteristic.SortOrder');

        return this.dataManager.returnQueryResults(query);
    }

    getSampleOrders(preferLocal = false): Promise<any[]> {
        const query = EntityQuery.from('SampleOrders')
            .expand('Order');

        return this.dataManager.returnQueryResults(query, preferLocal);
    }

    getLots(preferLocal = false): Promise<any> {

        const query = EntityQuery.from('Lots');

        return this.dataManager.returnQueryResults(query, preferLocal);
    }

    getSampleOrderLots(sampleOrderKey: number, preferLocal?: boolean): Promise<any> {

        const query = EntityQuery.from('Lots')
            .where('C_SampleOrder_key', '==', sampleOrderKey);

        return this.dataManager.returnQueryResults(query, preferLocal);
    }

    ensureSourceMaterialsExpanded(sourceMaterials: any[]): Promise<any> {
        return this.dataManager.ensureRelationships(
            sourceMaterials, ['Material.Line']
        );
    }

    async ensureVisibleColumnsDataLoaded(samples: any[], visibleColumns: string[]): Promise<void> {
        const expands = this.generateExpandsFromVisibleColumns(samples[0], visibleColumns);
        return this.dataManager.ensureRelationships(samples, expands);
    }

    create(): any {
        return this.dataManager.createEntity('Sample', { DateCreated: new Date() });
    }

    bulkDeleteSamples(samples: any[]): Promise<any> {
        return this.webApiService.postApi('api/bulkdata/sampleswithassociateddata', {
            materialKeys: uniqueArrayFromPropertyPath(samples, 'C_Material_key')
        });
    }

    deleteSample(sample: any) {
        if (sample.SampleCharacteristicInstance) {
            while (sample.SampleCharacteristicInstance.length > 0) {
                this.dataManager.deleteEntity(sample.SampleCharacteristicInstance[0]);
            }
        }
        
        this.dataManager.deleteEntity(sample);
    }

    createMaterialSourceMaterial(initialValues: any): any {
        const manager = this.dataManager.getManager();
        const entityType = 'MaterialSourceMaterial';
        const initialMaterialKey = initialValues.C_Material_key;
        const initialSourceKey = initialValues.C_SourceMaterial_key;

        // Check local entities for duplicates
        const sourceMaterials: any[] = this.getNonDeletedLocalEntities(manager, entityType);
        const duplicates = sourceMaterials.filter((sourceMaterial) => {
            return softCompare(sourceMaterial.C_Material_key, initialMaterialKey) &&
                softCompare(sourceMaterial.C_SourceMaterial_key, initialSourceKey);
        });

        if (duplicates.length === 0) {
            return this.dataManager.createEntity(entityType, initialValues);
        }
        return null;
    }

    createSampleConstruct(initialValues: any): any {
        const manager = this.dataManager.getManager();
        const entityType = 'SampleConstruct';
        const initialSampleKey = initialValues.C_Material_key;
        const initialConstructKey = initialValues.C_Construct_key;

        // Check local entities for duplicates
        const sampleConstructs: any[] = this.getNonDeletedLocalEntities(manager, entityType);
        const duplicates = sampleConstructs.filter((sampleConstruct) => {
            return softCompare(sampleConstruct.C_Material_key, initialSampleKey) &&
                softCompare(sampleConstruct.C_Construct_key, initialConstructKey);
        });

        // Not a duplicate
        if (duplicates.length === 0) {
            return this.dataManager.createEntity(entityType, initialValues);
        }

        return null;
    }

    deleteMaterialSourceMaterial(item: any) {
        item.entityAspect.setDeleted();
    }

    createSampleCharacteristics(sample: any): Promise<any[]> {

        return this.getActiveCharacteristics(sample.C_SampleType_key).then((characteristics) => {
            const newCharacteristics: any[] = [];

            for (const characteristic of characteristics) {
                const newCharacteristic: any = this.dataManager.createEntity(
                    'SampleCharacteristicInstance',
                    {
                        C_SampleCharacteristic_key: characteristic.C_SampleCharacteristic_key,
                        C_Material_key: sample.Material.C_Material_key,
                        CharacteristicName: characteristic.CharacteristicName,
                        Description: characteristic.Description
                    }
                );
                newCharacteristic.SampleCharacteristic = characteristic;
                newCharacteristics.push(newCharacteristic);
            }
            return newCharacteristics;
        });
    }
    
    getActiveCharacteristics(sampleTypeKey: number): Promise<any[]> {
        const predicates: Predicate[] = [];
        predicates.push(Predicate.create('IsActive', '==', true));
        predicates.push(Predicate.create('SampleCharacteristicSampleType', 'any', 'C_SampleType_key', '==', sampleTypeKey));

        const query = EntityQuery.from('SampleCharacteristics')
            .expand('cv_DataType')
            .where(Predicate.and(predicates))
            .orderBy('SortOrder');

        return this.dataManager.returnQueryResults(query);
    }

    deleteSampleCharacteristic(sampleCharacteristic: any) {
        this.dataManager.deleteEntity(sampleCharacteristic);
    }

    deleteSampleConstruct(sampleConstruct: any) {
        sampleConstruct.entityAspect.setDeleted();
    }

    cancelSample(sample: any) {
        if (!sample) {
            return;
        }

        if (sample.C_Material_key > 0) {
            this._cancelSampleEdits(sample);
        } else {
            this._cancelNewSample(sample);
        }
    }

    private _cancelNewSample(sample: any) {
        try {
            const material = sample.Material;
            this._cancelSampleEdits(sample);
            this.materialService.deleteMaterial(material);
        } catch (error) {
            console.log(error);
            console.log('error cancelling new sample');
        }
    }

    private _cancelSampleEdits(sample: any) {
        // also reject source animal/sample changes
        const sourceMaterials = getSafeProp(sample, 'Material.MaterialSourceMaterial');
        if (sourceMaterials) {
            for (const sourceMaterial of sourceMaterials) {
                const sourceAnimal = getSafeProp(sourceMaterial, 'SourceMaterial.Animal');
                const sourceSample = getSafeProp(sourceMaterial, 'SourceMaterial.Sample');
                if (sourceAnimal) {
                    this.dataManager.rejectEntityAndRelatedPropertyChanges(sourceAnimal);
                }
                if (sourceSample) {
                    this.dataManager.rejectEntityAndRelatedPropertyChanges(sourceSample);
                }
            }
        }

        this.dataManager.rejectChangesToEntityByFilter(
            'SampleCharacteristicInstance', (item: any) => {
                return item.C_Material_key === sample.C_Material_key;
            }
        );
        this.dataManager.rejectChangesToEntityByFilter(
            'MaterialLocation', (item: any) => {
                return item.C_Material_key === sample.C_Material_key;
            }
        );

        this.dataManager.rejectChangesToEntityByFilter(
            'MaterialSourceMaterial', (item: any) => {
                return item.C_Material_key === sample.C_Material_key;
            }
        );

        this.dataManager.rejectChangesToEntityByFilter(
            'MaterialPoolMaterial', (item: any) => {
                return item.C_Material_key === sample.C_Material_key;
            }
        );
        this.dataManager.rejectChangesToEntityByFilter(
            'JobMaterial', (item: any) => {
                return item.C_Material_key === sample.C_Material_key;
            }
        );

        const fileMaps = this.dataManager.rejectChangesToEntityByFilter(
            'StoredFileMap', (item: any) => {
                return item.C_Material_key === sample.C_Material_key;
            }
        );
        // also reject files associated with each fileMap
        for (const fileMap of fileMaps) {
            this.dataManager.rejectChangesToEntityByFilter(
                'StoredFile', (item: any) => {
                    return item.C_StoredFile_key === fileMap.C_StoredFile_key;
                }
            );
        }

        this.dataManager.rejectEntityAndRelatedPropertyChanges(sample);
    }
}

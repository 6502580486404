import { Pipe, PipeTransform } from '@angular/core';

/*
 * Returns either 'first' or 'last' half of an array
 *   First half is larger if length is odd
 */
@Pipe({ name: 'half' })
export class HalfPipe implements PipeTransform {
    transform<T>(items: T[], position: 'first' | 'last'): T[] {
        if (!items) {
            return [];
        }

        const halfIndex = (items.length / 2) + (items.length % 2);

        if (position === 'last') {
            return items.slice(halfIndex);
        } else {
            // position === 'first'
            return items.slice(0, halfIndex);
        }
    }
}

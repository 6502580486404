/*
* NOTICE:
* 
*  I thought I needed this to manage a list inside a <select> component.
*    Turned out I didn't really need it.
*  However, I feel it could still be valuable.
*  -- kevin.stone
*
* IF THIS FUNCTION IS STILL NOT USED AFTER 06/01/2017
*    PLEASE FEEL FREE TO DELETE
*/

export function filterArrayInPlace(arr: any[], condition: any) {
    let index = 0;
    let newIndex = 0;

    while (index < arr.length) {
        const value = arr[index];
        if (condition(value, index, arr)) {
            arr[newIndex++] = value;
        }
        index++;
    }

    arr.length = newIndex;
    return arr;
}

import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';

import {
    IMultiSelectOption,
    IMultiSelectSettings,
    IMultiSelectTexts
} from 'ngx-bootstrap-multiselect';

import { notEmpty } from './util/not-empty';

/**
 * In the next versions of ngxDropdownMultiselect, you can import NgxDropdownMultiselectComponent.
 * Now, unfortunately, the library does not allow importing NgxDropdownMultiselectComponent.
 */
interface NgxDropdownMultiselectComponent {
    isOpened: boolean;
    closeDropdown: () => void;
}

/**
 * Climb dropdown multi-select component.
 *
 * Uses softsimon's Angular Multiselect Dropdown for Bootstrap component:
 * https://github.com/softsimon/ngx-bootstrap-multiselect
 */
@Component({
    selector: 'climb-multi-select',
    template: `
     <ngx-bootstrap-multiselect
        #ngxBootstrapMultiselect
        [settings]="settings"
        [texts]="texts"
        [options]="options"
        [(ngModel)]="model"
        (ngModelChange)="onModelChange($event)"
        (dropdownClosed)="dropdownClosed()"
        [disabled]="disabled">
     </ngx-bootstrap-multiselect>
    `
})
export class ClimbMultiSelectComponent implements OnChanges, OnInit {
    @Input() model: any;
    @Input() values: any[];
    @Input() keyProperty: string;
    @Input() valueProperty: string;
    @Input() limit: any;
    @Input() multipleSections = false;
    // Placeholder must be text not HTML
    @Input() placeholder: string;
    @Input() disabled: boolean;
    @Input() emitOnClose = false;
    @Input() showCheckAll = true;
    @Input() showUncheckAll = true;
    @Input() maxSelectionLimit: number;
    @Input() displayAllSelectedText = true;
    @Input() fixedTitle = false;
    @Input() enableSearch = true;
    @Input() buttonClasses = '';

    @Output() modelChange: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild('ngxBootstrapMultiselect') dropdownMultiselect: NgxDropdownMultiselectComponent;

    // state
    options: IMultiSelectOption[];

    // Configuration
    settings: IMultiSelectSettings;
    texts: IMultiSelectTexts;

    readonly DEFAULT_PLACEHOLDER: string = 'Select...';

    ngOnInit() {
        this.actualizeSettings();

        this.texts = {
            checkAll: 'Select all',
            uncheckAll: 'Clear all',
            checked: 'item selected',
            checkedPlural: 'items selected',
            // Title must be text not HTML
            defaultTitle: this.placeholder || this.DEFAULT_PLACEHOLDER,
            allSelected: 'All selected',
            searchEmptyResult: 'No matches found.'
        };

        this.options = this.toMultiSelectOptions(
            this.values,
            this.keyProperty,
            this.valueProperty
        );
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.buttonClasses?.currentValue !== changes.buttonClasses?.previousValue) {
            this.actualizeSettings();
        }
        if (changes.values) {
            this.options = this.toMultiSelectOptions(
                this.values,
                this.keyProperty,
                this.valueProperty
            );
        }
    }

    actualizeSettings() {
        this.settings = {
            enableSearch: this.enableSearch,
            checkedStyle: 'fontawesome',
            displayAllSelectedText: this.displayAllSelectedText,
            buttonClasses: `btn btn-secondary ${this.buttonClasses}`,
            showCheckAll: this.showCheckAll,
            showUncheckAll: this.showUncheckAll,
            ignoreLabels: true,
            fixedTitle: this.fixedTitle,
            focusBack: false,
        };

        if (this.maxSelectionLimit && this.maxSelectionLimit > 0) {
            this.settings.selectionLimit = this.maxSelectionLimit;
        }

        // Support single select
        if (this.limit === 1) {
            this.settings.selectionLimit = 1;
            this.settings.showCheckAll = false;
            this.settings.showUncheckAll = false;
            this.settings.autoUnselect = true;
            this.settings.displayAllSelectedText = false;
        }
    }

    toMultiSelectOptions(
        values: any[],
        keyProperty: string,
        valueProperty: string
    ): IMultiSelectOption[] {
        let options: IMultiSelectOption[] = [];

        if (notEmpty(values)) { 
            if (keyProperty && valueProperty) {
                options = values.map((value: any) => { 
                    if (this.multipleSections) { 
                        const labelKey = "isLabel";
                        const parentIdKey = "parentId";
                        if (value[labelKey]) {
                            return {
                                id: value[keyProperty],
                                name: value[valueProperty],
                                isLabel: value[labelKey],
                            };
                        } else {
                            return {
                                id: value[keyProperty],
                                name: value[valueProperty],
                                parentId: value[parentIdKey]
                            };
                        }
                    } 
                    return {
                        id: value[keyProperty],
                        name: value[valueProperty],
                    };
                });
            } else if (!keyProperty && !valueProperty) {
                options = values.map((value: any) => {
                    return {
                        id: value,
                        name: value
                    };
                });
            }
        }
        
        return options;
    }

    onModelChange(model: any) {
        if (this.emitOnClose) {
            return;
        }
        if (this.limit === 1) {
            this.model = this.model[0];
        }
        setTimeout(() => {
            this.modelChange.emit(this.model);
        }, 0);
    }

    dropdownClosed() {
        if (this.emitOnClose) {
            this.modelChange.emit(this.model);
        }
    }

    close() {
        this.dropdownMultiselect.closeDropdown();
    }
}

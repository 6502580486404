import { LocationService } from './../../locations/location.service';
import { ConfirmOptions } from './../../common/confirm/confirm-options';
import { ConfirmService } from './../../common/confirm/confirm.service';
import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    OnDestroy,
    Output,
    ViewChildren,
    SimpleChanges,
} from '@angular/core';
import { Subscription } from 'rxjs';

import { AnimalService } from '../../animals/services/animal.service';
import { CohortService } from '../../cohort/services/cohort.service';
import { CopyBufferService } from '../../common/services/copy-buffer.service';
import { EntityChangeService } from '../../entity-changes/entity-change.service';
import { JobVocabService } from '../../jobs/job-vocab.service';
import { LoggingService } from '../../services/logging.service';
import { ProtocolService } from '../../protocol/protocol.service';
import { SampleService } from '../../samples/sample.service';
import { TaskService } from '../task.service';
import { TranslationService } from '../../services/translation.service';
import { VocabularyService } from '../../vocabularies/vocabulary.service';
import { WorkflowLogicService } from '../../workflow/services/workflow-logic.service';
import { WorkflowService } from '../../workflow/services/workflow.service';
import { WorkspaceService } from '../../workspaces/workspace.service';
import { ViewAddTaskComponentService } from '../add-task/view-add-task-component.service';

import {
    getSafeProp,
    notEmpty,
    arrayContainsAllObjects,
    uniqueArrayFromPropertyPath,
} from '@common/util';

import { DroppableEvent } from '../../common/droppable-event';
import { TableSort } from '../../common/models';
import { TaskType, TaskGridConfig, TaskGridColumnConfig } from '../models';
import { DetailTaskTableOptions } from './detail-task-table-options';
import {
    calculateExpressionResult
} from '../calculated-output/calculate-expression-result';
import { ProtocolDateCalculator } from './protocol-date-calculator';

import { startsWithVowel } from './starts-with-vowel';
import { NgModel } from '@angular/forms';
import { dateControlValidator } from '@common/util/date-control.validator';
import { ReasonForChangeService } from '../../common/reason-for-change/reason-for-change.service';
import { SaveChangesService } from '../../services/save-changes.service';
import { facetsForFacetLevelSave } from '@common/facet';

@Component({
    selector: 'detail-task-table',
    templateUrl: './detail-task-table.component.html',
    styles: [`
        .half-bottom-margin {
            margin-bottom: 0.5em;
        }
        .dropdown-wrapper {
            position: relative;
        }
    `]
})
export class DetailTaskTableComponent implements OnChanges, OnInit, OnDestroy {
    @ViewChildren('dateControl') dateControls: NgModel[];
    @Input() logTag = 'detail-task-table';
    // The parent object (e.g., Job or Line)
    @Input() parent: any;
    // The array of association objects holding TaskInstances (e.g., TaskJobs, TaskLines)
    @Input() taskAssociations: any[];
    // The cv_TaskType.TaskType for the tasks
    @Input() taskType: TaskType;
    // Table options
    @Input() tableOptions: DetailTaskTableOptions;
    // Is the parent object (e.g., job) locked?
    @Input() parentLocked = false;
    // Does the user have readonly access?
    @Input() readonly = false;
    // Is the user a StudyAdministrator?
    @Input() studyAdministrator = false;
    // Animals cannot be added to No Material Tasks
    @Input() allowNoMaterial = true;

    // Workspace Facet
    @Input() facet: any;

    // Table State
    @Input() tableSort: TableSort;
    @Input() deviationExpanded: boolean;
    @Input() inputsExpanded: boolean;
    @Input() tasksExpanded: boolean;
    @Input() taskAnimalsExpanded: boolean;
    @Input() taskAnimalsLinesExpanded: boolean;
    @Input() taskCohortsExpanded: boolean;
    @Input() taskSamplesExpanded: boolean;
    // Outputs for the properties that can be modified herein
    @Output() deviationExpandedChange = new EventEmitter<boolean>();
    @Output() inputsExpandedChange = new EventEmitter<boolean>();
    @Output() tasksExpandedChange = new EventEmitter<boolean>();
    @Output() taskAnimalsExpandedChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() taskCohortsExpandedChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() taskSamplesExpandedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    taskPage = 1;

    // Data Output Events
    @Output() materialAdd: EventEmitter<any> = new EventEmitter<any>();
    @Output() taskInstanceAdd: EventEmitter<any> = new EventEmitter<any>();
    @Output() taskInstanceRemove: EventEmitter<any> = new EventEmitter<any>();
    @Output() protocolAdded: EventEmitter<any> = new EventEmitter<any>();

    // CVs
    taskStatuses: any[] = [];

    breezeChangeEvent: any;
    breezeUpdateInProgress: any;

    // Bulk Input form
    bulkInputTasks: any[] = [];
    bulkInputTask: any;
    bulkInputTaskAliases: string[] = [];
    bulkInputTaskAlias: string;
    bulkInputInputs: any[] = [];
    bulkInputInput: any = null;
    bulkInputValue: any = null;

    // bulk edit fields
    bulkDateDue: Date;
    bulkTaskStatusKey: number;
    bulkAssignedToKey: number;
    bulkDeviation: string;
    bulkLocation: any;
    taskCount: number;

    // Column Select
    columnSelect: {
        // Selected columns
        model: string[],
        // Column labels
        labels: any[],
    } = {model: [], labels: []};
    visible: any;
    visibleCount: number;

    // All subscriptions
    subs = new Subscription();

    private isFacetLevelSaveSupported = false;

    constructor(
        private animalService: AnimalService,
        private cohortService: CohortService,
        private confirmService: ConfirmService,
        private copyBufferService: CopyBufferService,
        private entityChangeService: EntityChangeService,
        private jobVocabService: JobVocabService,
        private locationService: LocationService,
        private loggingService: LoggingService,
        private protocolService: ProtocolService,
        private sampleService: SampleService,
        private taskService: TaskService,
        private translationService: TranslationService,
        private viewAddTaskComponentService: ViewAddTaskComponentService,
        private vocabularyService: VocabularyService,
        private workflowLogicService: WorkflowLogicService,
        private workflowService: WorkflowService,
        private workspaceService: WorkspaceService,
        private reasonForChangeService: ReasonForChangeService,
        private saveChangesService: SaveChangesService) {
        // do nothing
    }

    ngOnInit() {
        this.setUpBreezeChangeDetection();
        this.initialize();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.parent && !changes.parent.firstChange) {
            this.initialize();
        }
    }

    ngOnDestroy() {
        // Clear all the subscriptions
        this.subs.unsubscribe();
    }


    // Breeze Changes
    setUpBreezeChangeDetection() {
        // refresh all grid cells on any breeze entity change
        this.subs.add(this.entityChangeService
            .onAnyChange((changes: any) => {

                // We don't want to call this
                //   100x times if many entities just got loaded.
                // Set a timeout to give some breathing room.
                clearTimeout(this.breezeUpdateInProgress);
                this.breezeUpdateInProgress = setTimeout(() => {
                    this.onBreezeUpdate();
                }, 300);

            })
        );
    }

    onBreezeUpdate() {
        this.setDerivedValues();
    }

    // Initializing
    initialize() {
        this.isFacetLevelSaveSupported = facetsForFacetLevelSave.some(facet => facet === this.logTag);
        this.setDefaults();

        this.getCVs();

        this.clearFillDowns();

        this.initColumnSelect();
    }

    private setDefaults() {
        this.setDefaultTableSort();

        this.taskPage = 1;

        if (this.parentLocked !== true) {
            this.parentLocked = false;
        }

        if (this.readonly !== true) {
            this.readonly = false;
        }

        if (this.studyAdministrator !== true) {
            this.studyAdministrator = false;
        }
    }

    private setDefaultTableSort() {
        if (!this.tableSort) {
            this.tableSort = new TableSort();
        }

        if (!this.tableSort.propertyPath) {
            this.tableSort.propertyPath = 'Sequence';
            this.tableSort.reverse = false;
        }
    }

    getCVs() {
        this.jobVocabService.taskStatuses$.subscribe((taskStatuses) => {
            this.taskStatuses = taskStatuses;
        });
        this.vocabularyService.ensureCVLoaded('cv_ScheduleTypes');
    }

    private clearFillDowns() {
        this.bulkDateDue = null;
        this.bulkTaskStatusKey = null;
        this.bulkAssignedToKey = null;
        this.bulkDeviation = null;
        this.locationService.getDefaultLocationOrRoot().then((location) => {
            this.bulkLocation = location;
        });

        // Clear the Bulk Input form
        this.resetBulkInput(true);
    }

    setDerivedValues() {
        this.setFilteredTaskMaterials();
        this.setTaskCohorts();

        // Update the Bulk Input form when tasks are changed externally
        this.initBulkInputTasks();

        this.updateTaskCount();
    }

    setFilteredTaskMaterials() {
        if (notEmpty(this.taskAssociations)) {
            // this.taskAssociations = this.taskAssociations.filter((item) => item.TaskInstance.WorkflowTask.cv_TaskType.TaskType === this.taskType);
            for (const taskAssociation of this.taskAssociations) {
                this.setFilteredMaterialsForTask(taskAssociation.TaskInstance);
            }
        }
    }

    updateTaskCount() {
        this.taskCount = this.taskAssociations.filter((item) => item.TaskInstance.WorkflowTask?.cv_TaskType?.TaskType === this.taskType).length;
    }

    setFilteredMaterialsForTask(task: any) {
        if (!task) {
            return;
        }

        const taskMaterials = task?.TaskMaterial;
        if (notEmpty(taskMaterials)) {
            const filteredAnimals = taskMaterials.filter((item: any) => {
                return getSafeProp(item, 'Material.Animal');
            });
            if (!arrayContainsAllObjects(task.FilteredAnimalMaterials, filteredAnimals)) {
                task.FilteredAnimalMaterials = filteredAnimals;
            }
            const filteredSamples = taskMaterials.filter((item: any) => {
                return getSafeProp(item, 'Material.Sample');
            });
            if (!arrayContainsAllObjects(task.FilteredSampleMaterials, filteredSamples)) {
                task.FilteredSampleMaterials = filteredSamples;
            }
        } else {
            task.FilteredAnimalMaterials = [];
            task.FilteredSampleMaterials = [];
        }

        if (!task.FilteredAnimalMaterials) {
            task.FilteredAnimalMaterials = [];
        }

        if (!task.FilteredSampleMaterials) {
            task.FilteredSampleMaterials = [];
        }
    }

    /**
     * Set DerivedCohorts on TaskJob instances
     *   Contains cohorts for all animals in the task
     */
    setTaskCohorts() {
        if (notEmpty(this.taskAssociations)) {
            for (const taskAssociation of this.taskAssociations) {
                const taskInstance = taskAssociation.TaskInstance;
                if (!taskInstance) {
                    continue;
                }
                taskInstance.DerivedCohorts = uniqueArrayFromPropertyPath(
                    taskInstance.FilteredAnimalMaterials,
                    'Material.CohortMaterial.Cohort'
                );
            }
        }
    }


    // Table State
    viewAnimalsPerTask() {
        this.taskAnimalsExpanded = true;
        this.taskAnimalsExpandedChange.emit(this.taskAnimalsExpanded);

        this.taskAnimalsLinesExpanded = false;
        this.taskAnimalsExpandedChange.emit(this.taskAnimalsExpanded);

        this.taskCohortsExpanded = false;
        this.taskCohortsExpandedChange.emit(this.taskCohortsExpanded);
    }

    viewCohortsPerTask() {
        this.taskAnimalsExpanded = false;
        this.taskAnimalsExpandedChange.emit(this.taskAnimalsExpanded);

        this.taskAnimalsLinesExpanded = false;
        this.taskAnimalsExpandedChange.emit(this.taskAnimalsExpanded);

        this.taskCohortsExpanded = true;
        this.taskCohortsExpandedChange.emit(this.taskCohortsExpanded);
    }

    viewAnimalsLinesPerTask() {       
        this.taskAnimalsExpanded = false;
        this.taskAnimalsExpandedChange.emit(this.taskAnimalsExpanded);

        this.taskAnimalsLinesExpanded = true;
        this.taskAnimalsExpandedChange.emit(this.taskAnimalsExpanded);

        this.taskCohortsExpanded = false;
        this.taskCohortsExpandedChange.emit(this.taskCohortsExpanded);
    }

    viewSampleNames() {
        this.taskSamplesExpanded = false;
        this.taskSamplesExpandedChange.emit(this.taskSamplesExpanded);
    }

    viewSamplesWithSources() {
        this.taskSamplesExpanded = true;
        this.taskSamplesExpandedChange.emit(this.taskSamplesExpanded);
    }

    toggleTasksExpanded() {
        this.tasksExpanded = !this.tasksExpanded;
        this.tasksExpandedChange.emit(this.tasksExpanded);
    }

    toggleInputsExpanded() {
        this.inputsExpanded = !this.inputsExpanded;
        this.inputsExpandedChange.emit(this.inputsExpanded);
    }

    toggleDeviationExpanded() {
        this.deviationExpanded = !this.deviationExpanded;
        this.deviationExpandedChange.emit(this.deviationExpanded);
    }

    changeTaskPage(newPage: number) {
        this.taskPage = newPage;
    }


    // Materials
    pasteMaterialsIntoAllTasks() {
        // ignore if there is nothing to copy
        if (!notEmpty(this.copyBufferService.buffer)) {
            return;
        }

        const confirmMessage = this._buildPasteAllMaterialsConfirmMessage(
            this.copyBufferService.bufferType,
            this.copyBufferService.buffer.length
        );
        const confirmOptions: ConfirmOptions = {
            title: 'Add to All Tasks',
            message: confirmMessage,
            yesButtonText: 'Yes',
            noButtonText: 'Cancel',
        };
        this.confirmService.confirm(confirmOptions).then(() => {
            const taskInstances = uniqueArrayFromPropertyPath(
                this.taskAssociations, 'TaskInstance'
            );
            // Paste materials into all tasks
            for (const taskInstance of taskInstances) {
                this.pasteMaterialsIntoTask(taskInstance);
            }
        }).catch(() => {
            // do nothing
        });
    }

    private _buildPasteAllMaterialsConfirmMessage(
        bufferType: string, bufferLength: number
    ): string {
        // pluralize if needed
        if (bufferLength > 1) {
            bufferType += 's';
        }
        return 'Add ' +
            bufferLength + ' ' + 
            bufferType +
            ' to all tasks?';
    }

    /**
     * Pastes materials from copyBufferService
     *   into all Unlocked tasks
     * @param taskInstance 
     */
    pasteMaterialsIntoTask(taskInstance: any) {
        // No pasting into locked TaskInstances
        if (!taskInstance || (taskInstance && taskInstance.IsLocked)) {
            return;
        }

        const taskInstanceKey = taskInstance.C_TaskInstance_key;

        if (this.copyBufferService.hasAnimals()) {
            const animals = this.copyBufferService.paste();
            this.addMaterialsToTask(taskInstanceKey, animals);
        }

        if (this.copyBufferService.hasCohorts()) {
            const cohorts = this.copyBufferService.paste();
            this.cohortService.ensureMaterialsExpanded(cohorts).then(() => {
                for (const cohort of cohorts) {
                    this.addMaterialsFromCohort(taskInstanceKey, cohort);
                    cohort.isSelected = false;
                }
            });
        }

        if (this.copyBufferService.hasSamples()) {
            const samples = this.copyBufferService.paste();
            this.addMaterialsToTask(taskInstanceKey, samples);
        }
    }

    /**
     * Create TaskInstances for pasted WorkflowTasks or Protocols
     */
    pasteTasksOrProtocols(): Promise<any> {
        if (this.copyBufferService.hasWorkflowTasks()) {
            // Copied WorkflowTasks
            const workflowTasks = this.copyBufferService.paste();
            return this.addTasks(workflowTasks);
        } else if (this.copyBufferService.hasProtocols()) {
            // Copied Protocols
            const protocols = this.copyBufferService.paste();
            return this.addProtocols(protocols);
        }
    }

    /**
     * Drop Animals, Samples, Cohorts into a Task
     * @param event 
     */
    onDropMaterialToTask(event: DroppableEvent) {
        const taskKey = event.dataKey;
        const taskInstance: any = this.getTaskInstanceByKey(taskKey);
        if (this.allowNoMaterial) {
            this.onDropAnimals(taskKey);
            this.onDropCohorts(taskKey);
            this.onDropSamples(taskKey);
        } else {
            if (!taskInstance.WorkflowTask.NoMaterials) {
                this.onDropAnimals(taskKey);
                this.onDropCohorts(taskKey);
                this.onDropSamples(taskKey);
            } else {
                this.animalService.draggedAnimals = [];
                this.cohortService.draggedCohorts = [];
                this.sampleService.draggedSamples = [];
                const message = "Animals cannot be added to No Material Tasks.";
                const showToastr = true;
                this.loggingService.logError(
                    message,
                    null,
                    this.logTag,
                    showToastr
                );
            }
        }
    }

    onDropAnimals(taskKey: number) {
        const animals = this.animalService.draggedAnimals;
        this.addMaterialsToTask(taskKey, animals);
        this.animalService.draggedAnimals = [];
    }

    onDropSamples(taskKey: number) {
        const samples = this.sampleService.draggedSamples;
        this.addMaterialsToTask(taskKey, samples);
        this.sampleService.draggedSamples = [];
    }

    onDropCohorts(taskKey: number) {
        const cohorts = this.cohortService.draggedCohorts;

        this.cohortService.ensureMaterialsExpanded(cohorts).then(() => {
            for (const cohort of cohorts) {
                this.addMaterialsFromCohort(taskKey, cohort);
                cohort.isSelected = false;
            }
        });
        this.cohortService.draggedCohorts = [];
    }

    addMaterialsFromCohort(taskInstanceKey: number, cohort: any) {
        this.addMaterialsToTask(taskInstanceKey, cohort.CohortMaterial);
    }

    addMaterialsToTask(taskInstanceKey: number, materials: any[]) {
        for (const material of materials) {
            this.taskService.createTaskMaterial({
                C_TaskInstance_key: taskInstanceKey,
                C_Material_key: material.C_Material_key
            });

            this.materialAdd.emit(material);
        }

        this.calculateMaterialDueDatesFromTaskKey(taskInstanceKey);
    }


    // Adding
    addTasksViaModal() {
        this.viewAddTaskComponentService.openComponent(this.taskType)
            .then((newTaskInstancesValues: any[]) => {
                let promise: Promise<void> = Promise.resolve();

                if (notEmpty(newTaskInstancesValues)) {
                    this.getDefaultTaskStatus().then((taskStatusDefault: any) => {
                        for (const taskInstanceValues of newTaskInstancesValues) {
                            if (taskStatusDefault) {
                                taskInstanceValues.C_TaskStatus_key =
                                    taskStatusDefault.C_TaskStatus_key;
                            }

                            promise = promise.then(() => {
                                return this.taskService.createTaskInstance(taskInstanceValues);
                            }).then((newTaskInstance) => {
                                this.taskInstanceAdd.emit(newTaskInstance);
                            });
                        }
                    });
                }

                return promise;
            });
    }

    onDropTask(event: DroppableEvent): Promise<any> {
        if (notEmpty(this.taskService.draggedTasks)) {
            // Dragged Tasks
            const draggedTasks = this.taskService.draggedTasks;
            this.taskService.draggedTasks = [];

            return this.addTasks(draggedTasks);
        } else if (notEmpty(this.protocolService.draggedProtocols)) {
            // Dragged Protocols
            const draggedProtocols = this.protocolService.draggedProtocols;
            this.protocolService.draggedProtocols = [];

            return this.addProtocols(draggedProtocols);
        }
    }

    /**
     * Add TaskInstances for WorkflowTasks
     *
     * @param workflowTasks array of WorkflowTask objects
     */
    private addTasks(workflowTasks: any[]): Promise<any> {
        return this.getDefaultTaskStatus().then((taskStatusDefault: any) => {
            let promise: Promise<any> = Promise.resolve();

            let invalidTasks = false;

            for (const task of workflowTasks) {
                // Skip task if wrong type
                if (!this.taskIsValidType(task)) {
                    invalidTasks = true;
                    continue;
                }

                const initialValues: any = {
                    C_WorkflowTask_key: task.C_WorkflowTask_key
                };
                if (taskStatusDefault) {
                    initialValues.C_TaskStatus_key = taskStatusDefault.C_TaskStatus_key;
                }

                promise = promise.then(() => {
                    return this.taskService.createTaskInstance(initialValues);
                }).then((newTaskInstance) => {
                    this.taskInstanceAdd.emit(newTaskInstance);
                });
            }

            if (invalidTasks) {
                this.showInvalidTaskWarning();
            }

            return promise;
        });
    }

    /**
     * Add TaskInstances for Protocols 
     * 
     * @param protocols array of Protocol objects
     */
    private addProtocols(protocols: any[]): Promise<any> {
        return this.getDefaultTaskStatus().then((taskStatusDefault: any) => {
            const initialValues: any = {};
            if (taskStatusDefault) {
                initialValues.C_TaskStatus_key = taskStatusDefault.C_TaskStatus_key;
            }

            let invalidTasks = false;

            // Start the chain of promises
            let promise: Promise<any> = Promise.resolve(true);

            for (const protocol of protocols) {
                promise = promise.then(() => {
                    const protocolInstances = uniqueArrayFromPropertyPath(
                        this.taskAssociations, 'TaskInstance.ProtocolInstance'
                    );
                    const protocolInitialValues = {
                        C_Protocol_key: protocol.C_Protocol_key,
                        ProtocolAlias: this.taskService.generateProtocolAlias(
                            protocolInstances, protocol
                        )
                    };
                    const protocolInstance = this.taskService.createProtocolInstance(
                        protocolInitialValues
                    );

                    return this.taskService.createProtocolTaskInstances(
                        protocol.C_Protocol_key,
                        protocolInstance.C_ProtocolInstance_key,
                        initialValues,
                        this.taskType
                    );
                }).then((newTaskInstances: any[]) => {
                    for (const newTaskInstance of newTaskInstances) {
                        this.taskInstanceAdd.emit(newTaskInstance);
                    }

                    if (newTaskInstances.length !== protocol.ProtocolTask.length) {
                        invalidTasks = true;
                    }

                    this.protocolAdded.emit(protocol);
                });
            }

            return promise.then(() => {
                if (invalidTasks) {
                    this.showInvalidTaskWarning();
                }
            });
        });
    }

    private getDefaultTaskStatus(): Promise<any> {
        const preferLocal = true;
        return this.vocabularyService.getCVDefault('cv_TaskStatuses', preferLocal);
    }

    private taskIsValidType(workflowTask: any): boolean {
        const thisTaskType = this.getWorkflowTaskType(workflowTask);

        return this.taskType === thisTaskType;
    }

    private getWorkflowTaskType(workflowTask: any): string {
        return getSafeProp(workflowTask, 'cv_TaskType.TaskType');
    }

    private showInvalidTaskWarning() {
        const taskType = this.translationService.translate(this.taskType);
        let determiner = 'a';
        if (startsWithVowel(taskType)) {
            determiner = 'an';
        }

        const message = 'Not all tasks were added: only ' +
            taskType +
            ' tasks can be added to ' + determiner + ' ' +
            taskType +
            '.';
        const showToastr = true;

        this.loggingService.logWarning(message, null, this.logTag, showToastr);
    }

    /**
     * Removes all animals from the task that are also members of a cohort
     * @param cohort
     */
    removeTaskCohort(taskInstance: any, cohort: any) {
        this.cohortService.ensureMaterialsExpanded([cohort]).then(() => {
            for (const cohortMaterial of cohort.CohortMaterial) {
                // find matching TaskMaterial to remove
                for (const taskMaterial of taskInstance.TaskMaterial) {
                    if (cohortMaterial.C_Material_key === taskMaterial.C_Material_key) {
                        this.taskService.deleteTaskMaterial(taskMaterial);
                        break;
                    }
                }
            }
        });
    }

    removeTaskMaterial(taskMaterial: any) {
        this.taskService.deleteTaskMaterial(taskMaterial);
        this.calculateMaterialDueDatesFromTaskKey(taskMaterial.C_TaskInstance_key);
    }

    async removeTaskInstance(taskInstance: any): Promise<void> {
        if (!this.isFacetLevelSaveSupported) {
            // TODO: remove this code when all facets use facet-level saving
            if (getSafeProp(taskInstance.TaskMaterial[0], "Material.Animal")) {
                this.reasonForChangeService.markModification([taskInstance.TaskMaterial[0].Material.Animal]);
            } else if (getSafeProp(taskInstance.TaskMaterial[0], "Material.Sample")) {
                this.reasonForChangeService.markModification([taskInstance.TaskMaterial[0].Material.Sample]);
            }
            this.saveChangesService.isLocked = true;
            this.workflowService.deleteTask(taskInstance);

            this.saveChangesService.saveChanges(this.logTag).catch((err) => {
                throw err;
            }).finally(() => {
                this.saveChangesService.isLocked = false;
                this.workflowService.refreshWorkflowFacet.next();
            });
        } else {
            this.taskInstanceRemove.emit(taskInstance);
            return;
        }
    }

    // Task Values

    /**
     * Calculate the DateDue based on Material field(s)
     * 
     * @param taskInstanceKey
     */
    calculateMaterialDueDatesFromTaskKey(taskInstanceKey: number) {
        const protocolDateCalculator = new ProtocolDateCalculator();
        const taskInstance = this.getTaskInstanceByKey(taskInstanceKey);
        const allTasks = this.getAllTaskInstances();
        const protocolInstanceTasks = this.getTasksInSameProtocol(taskInstance, allTasks);
        protocolDateCalculator.scheduleMaterialDueDates(protocolInstanceTasks, taskInstance);
    }

    /**
     * Calculate the DateDue for all dependent tasks
     * @param changedTask 
     */
    calculateDueDates(changedTask: any) {
        const protocolDateCalculator = new ProtocolDateCalculator();
        const allTasks = this.getAllTaskInstances();
        const protocolInstanceTasks = this.getTasksInSameProtocol(changedTask, allTasks);

        protocolDateCalculator.scheduleDependentDueDates(protocolInstanceTasks, changedTask);
    }

    getTaskInstanceByKey(taskInstanceKey: number): any[] {
        const allTasks = this.getAllTaskInstances();
        return allTasks.find((item) => {
            return item.C_TaskInstance_key === taskInstanceKey;
        });
    }

    getTasksInSameProtocol(taskInstance: any, allTasks: any[]): any[] {
        return allTasks.filter((task) => {
            return task.C_ProtocolInstance_key === taskInstance.C_ProtocolInstance_key;
        });
    }

    getAllTaskInstances(): any[] {
        return uniqueArrayFromPropertyPath(this.taskAssociations, 'TaskInstance');
    }

    recalculateValues(task: any) {
        for (const taskOutputSet of task.TaskOutputSet) {
            const taskOutputs = taskOutputSet.TaskOutput;

            const calculatedTaskOutputs = taskOutputs.filter((taskOutput: any) => {
                return taskOutput.Output.cv_DataType.DataType === 'Calculated';
            });

            const numericTaskOutputs = taskOutputs.filter((taskOutput: any) => {
                return taskOutput.Output.cv_DataType.DataType === 'Number';
            });

            const taskInputs = task.TaskInput;
            const numericTaskInputs = taskInputs.filter((taskInput: any) => {
                return taskInput.Input.cv_DataType.DataType === 'Number';
            });

            for (const calculatedTaskOutput of calculatedTaskOutputs) {
                const output = calculatedTaskOutput.Output;
                const calcOutExpressionArr = output.CalculatedOutputExpression;
                if (calcOutExpressionArr && calcOutExpressionArr.length) {
                    const calcOutExpression = calcOutExpressionArr[0];
                    const inputMappings = calcOutExpression.ExpressionInputMapping;
                    const outputMappings = calcOutExpression.ExpressionOutputMapping;
                    const expression = JSON.parse(calcOutExpression.OutputExpression);

                    calculatedTaskOutput.OutputValue = calculateExpressionResult(
                        expression,
                        inputMappings,
                        numericTaskInputs,
                        outputMappings,
                        numericTaskOutputs
                    );
                }
            }
        }
    }


    // Cost
    calculateAllTaskCosts() {
        for (const taskAssociation of this.taskAssociations) {
            this.calculateTaskCost(taskAssociation, false);
        }
    }

    calculateTaskCost(task: any, showWarning = true) {
        if (!task) {
            return;
        }

        const workflowTaskCost: number =
            getSafeProp(task, 'TaskInstance.WorkflowTask.Cost');

        // Abort if no WorkflowTask Cost defined
        if (!workflowTaskCost) {
            if (showWarning) {
                this.loggingService.logWarning(
                    "The cost cannot be calculated: " +
                    "please specify a cost in the task definition.",
                    "C_TaskInstance_key=" + getSafeProp(task, 'C_TaskInstance_key'),
                    this.logTag,
                    true
                );
            }

            return;
        }

        let cost = 0;

        // Calculate per-material or per-task
        const isPerMaterial: boolean =
            getSafeProp(task, 'TaskInstance.WorkflowTask.IsCostPerMaterial');
        if (isPerMaterial === true) {
            const materialCount: number = this.getTaskMaterialCount(task);
            cost = workflowTaskCost * materialCount;
        } else {
            cost = workflowTaskCost;
        }

        task.TaskInstance.Cost = cost;
    }

    private getTaskMaterialCount(task: any): number {
        const taskMaterials: any[] = getSafeProp(task, 'TaskInstance.TaskMaterial');

        if (notEmpty(taskMaterials)) {
            return taskMaterials.length;
        } else {
            return 0;
        }
    }


    // Duration
    calculateAllTaskDurations() {
        for (const taskAssociation of this.taskAssociations) {
            this.calculateTaskDuration(taskAssociation, false);
        }
    }

    calculateTaskDuration(task: any, showWarning = true) {
        if (!task) {
            return;
        }

        const workflowTaskDuration: number =
            getSafeProp(task, 'TaskInstance.WorkflowTask.Duration');

        // Abort if no WorkflowTask Duration defined
        if (!workflowTaskDuration) {
            if (showWarning) {
                this.loggingService.logWarning(
                    "The duration cannot be calculated: " +
                    "please specify a duration in the task definition.",
                    "C_TaskInstance_key=" + getSafeProp(task, 'C_TaskInstance_key'),
                    this.logTag,
                    true
                );
            }

            return;
        }

        let duration = 0;

        // Calculate per-material or per-task
        const isPerMaterial: boolean =
            getSafeProp(task, 'TaskInstance.WorkflowTask.IsDurationPerMaterial');
        if (isPerMaterial === true) {
            const materialCount: number = this.getTaskMaterialCount(task);
            duration = workflowTaskDuration * materialCount;
        } else {
            duration = workflowTaskDuration;
        }

        task.TaskInstance.Duration = duration;
    }


    // TaskInstance Locking
    toggleTaskInstanceLock(taskAssociation: any) {
        if (this.tableOptions.allowLocking && taskAssociation.TaskInstance) {
            taskAssociation.TaskInstance.IsLocked = !taskAssociation.TaskInstance.IsLocked;
        }
    }

    lockAllTaskInstances() {
        const isLocked = true;
        this.changeAllTaskInstanceLocks(isLocked);
    }

    unlockAllTaskInstances() {
        const isLocked = false;
        this.changeAllTaskInstanceLocks(isLocked);
    }

    changeAllTaskInstanceLocks(isLocked: boolean) {
        if (this.tableOptions.allowLocking) {
            for (const taskAssociation of this.taskAssociations) {
                if (taskAssociation.TaskInstance) {
                    taskAssociation.TaskInstance.IsLocked = isLocked;
                }
            }
        }
    }

    taskStatusChanged(changedTask: any) {
        // Update completed date, etc
        this.workflowLogicService.taskStatusChanged(changedTask);

        // Update relative due dates
        this.calculateDueDates(changedTask);
    }

    /**
     * Prepare the Task options in the Bulk Input fill down
     */
    initBulkInputTasks() {
        const tasks: any[] = [];
        const seen: any = {};

        // Find all the distinct tasks that have inputs
        if (notEmpty(this.taskAssociations)) {
            for (const taskAssociation of this.taskAssociations) {
                const task = taskAssociation.TaskInstance;
                if (!task) {
                    continue;
                }
                const key = task.C_WorkflowTask_key;
                if (seen[key]) {
                    // Already added
                    continue;
                }

                if (!task.TaskInput || (task.TaskInput.length === 0)) {
                    // No inputs
                    continue;
                }

                // This one is a keeper
                tasks.push(task);
                seen[key] = true;
            }
        }

        // Use these tasks
        this.bulkInputTasks = tasks;

        // If there is only one task, select it by default
        if (this.bulkInputTasks.length === 1) {
            this.bulkInputTask = this.bulkInputTasks[0];

            // Pretend like the user picked this task to update the related
            // values
            this.bulkInputTaskChanged();
        }
    }

    /**
     * Clear the Bulk Input fill down form.
     * 
     * @param clearTask If true, the selected task will also be cleared
     */
    resetBulkInput(clearTask = true) {
        if (clearTask) {
            this.bulkInputTask = null;
        }

        this.bulkInputTaskAliases = [];
        this.bulkInputTaskAlias = null;
        this.bulkInputInputs = [];
        this.bulkInputInput = null;
        this.bulkInputValue = null;
    }

    /**
     * The selection of the Bulk Input Task has changed
     */
    bulkInputTaskChanged() {
        // Clear the form, but keep the task selection
        this.resetBulkInput(false);

        if (!this.taskAssociations || !this.bulkInputTask) {
            // Nothing to do
            return;
        }

        // Find all the distinct aliases for this task.
        const aliases: string[] = [];
        const seen: any = {};
        for (const taskAssociation of this.taskAssociations) {
            const task = taskAssociation.TaskInstance;
            if (!task){
                continue;
            }

            if (task.C_WorkflowTask_key !== this.bulkInputTask.C_WorkflowTask_key) {
                // Not the selected task
                continue;
            }

            if (!task.TaskInput || (task.TaskInput.length === 0)) {
                // This task has no inputs
                continue;
            }

            const alias = task.TaskAlias;
            if (seen[alias]) {
                // Already added
                continue;
            }

            // This one is a keeper
            aliases.push(alias);
            seen[alias] = true;
        }

        if ((aliases.length === 1) && (aliases[0] === this.bulkInputTask.WorkflowTask.TaskName)) {
            // There are no aliases that are different the task name, so clear
            // the list.
            aliases.length = 0;
        }

        // Use these aliases
        this.bulkInputTaskAliases = aliases;

        // Copy the task inputs into the form
        if (this.bulkInputTask.TaskInput) {
            const inputs = this.bulkInputTask.TaskInput.map((taskInput: any) => {
                return taskInput.Input;
            });

            // Put the inputs in the right order
            inputs.sort((a: any, b: any) => a.SortOrder - b.SortOrder);

            // Use the inputs
            this.bulkInputInputs = inputs;

            // Select the first one by default
            this.bulkInputInput = this.bulkInputInputs[0];
        }
    }

    /**
     * The BUlk Input Input selection was changed
     */
    bulkInputInputChanged() {
        // Clear the prior value
        this.bulkInputValue = null;
    }

    /**
     * The Bulk Input "Update All" button was clicked.
     * 
     * Update the inputs for all the matching tasks.
     */
    bulkInputUpdated() {
        if (!this.taskAssociations || !this.bulkInputTask || !this.bulkInputInput) {
            // Nothing to do
            return;
        }

        // Find matching tasks and update the inputs
        for (const taskAssociation of this.taskAssociations) {
            const task = taskAssociation.TaskInstance;

            if (task.C_WorkflowTask_key !== this.bulkInputTask.C_WorkflowTask_key) {
                // Not the selected task
                continue;
            }

            if ((this.bulkInputTaskAlias !== null) &&
                (task.TaskAlias !== this.bulkInputTaskAlias)) {
                // Not the selected alias
                continue;
            }

            if (!task.TaskInput) {
                // No inputs to update
                continue;
            }

            // Check and update each input
            for (const taskInput of task.TaskInput) {
                if (taskInput.C_Input_key !== this.bulkInputInput.C_Input_key) {
                    // Not the selected input
                    continue;
                }

                // Set the value for this input
                taskInput.InputValue = this.bulkInputValue;
            }
        }

        // Clear the Bulk Input form
        this.resetBulkInput(true);
    }

    // Bulk edit fill downs
    bulkDateDueChanged() {
        for (const taskAssocation of this.taskAssociations) {
            if (taskAssocation.TaskInstance) {
                taskAssocation.TaskInstance.DateDue = this.bulkDateDue;
            }
        }
    }

    bulkTaskStatusChanged() {
        for (const taskAssocation of this.taskAssociations) {
            const task = taskAssocation.TaskInstance;
            if (task && (task.C_TaskStatus_key !== this.bulkTaskStatusKey)) {
                task.C_TaskStatus_key = this.bulkTaskStatusKey;

                // Update completed date, etc
                this.workflowLogicService.taskStatusChanged(task);
                // Update relative due dates
                this.calculateDueDates(task);
            }
        }
    }

    bulkAssignedToChanged() {
        for (const taskAssociation of this.taskAssociations) {
            if (taskAssociation.TaskInstance && !taskAssociation.TaskInstance.HasInvalidInputs) {
                taskAssociation.TaskInstance.C_AssignedTo_key = this.bulkAssignedToKey;
            }
        }
    }

    bulkDeviationChanged() {
        for (const taskAssocation of this.taskAssociations) {
            if (taskAssocation.TaskInstance) {
                taskAssocation.TaskInstance.Deviation = this.bulkDeviation;
            }
        }
    }

    bulkLocationChanged() {
        const locationKey = getSafeProp(this.bulkLocation, 'C_LocationPosition_key');
        for (const taskAssocation of this.taskAssociations) {
            if (taskAssocation.TaskInstance) {
                taskAssocation.TaskInstance.C_LocationPosition_key = locationKey;
            }
        }
    }

    // Vocab selects
    taskStatusKeyFormatter = (value: any) => {
        return value.C_TaskStatus_key;
    }
    taskStatusFormatter = (value: any) => {
        return value.TaskStatus;
    }

    /**
     * Initializes the column selection UI and visibility flags based on the
     * available columns and user selections.
     */
    initColumnSelect() {
        const opts = this.tableOptions;

        // Start by hiding columns restricted by the table options passed to
        // the component
        this.visible = {
            remove: !this.readonly && !this.parentLocked,
            lock: opts.allowLocking,
            protocol: true,
            taskAlias: true,
            taskName: false,
            sequence: true,
            pasteMaterials: true, 
            animals: opts.showAnimals,
            samples: opts.showSamples,
            inputs: true,
            dueDate: true,
            deviation: true,
            location: true,
            status: true,
            assignedTo: true,
            cost: true,
            duration: true,
        };

        // Assemble the list of all columns that can be selected
        const labels = [];

        if (opts.allowLocking) {
            labels.push({ key: 'lock', label: 'Lock' });
        }
        labels.push({
            key: 'protocol',
            label: this.translationService.translate('Protocol')
        });
        labels.push({ key: 'taskAlias', label: 'Task Name' });
        labels.push({ key: 'taskName', label: 'Task' });
        labels.push({ key: 'sequence', label: 'Sequence' });
        if (opts.showAnimals) {
            labels.push({ key: 'animals', label: 'Animals' });
        }
        if (opts.showSamples) {
            labels.push({ key: 'samples', label: 'Samples' });
        }
        labels.push({ key: 'inputs', label: 'Inputs' });
        labels.push({ key: 'dueDate', label: 'Due Date' });
        labels.push({ key: 'deviation', label: 'Allowance' });
        labels.push({ key: 'location', label: 'Location' });
        labels.push({ key: 'status', label: 'Status' });
        labels.push({ key: 'assignedTo', label: 'Assigned To' });
        labels.push({ key: 'cost', label: 'Cost' });
        labels.push({ key: 'duration', label: 'Duration' });

        // Assign the column options
        this.columnSelect.labels = labels;

        // Get the selected colummns from the user configuration
        const config = this.parseTaskGridConfiguration();
        this.columnSelect.model = labels.filter((item) => {
            const columnConfig = config.columns[item.key];

            if (!columnConfig) {
                // No user config yet, so rely on the default visibility
                return this.visible[item.key];
            }

            // Columns are visible unless explicitly hidden
            return (columnConfig.visible !== false);
        }).map((item) => item.key);

        // Update the column visiblility
        this.updateVisible();
    }

    /**
     * Update the column visibility flags.
     */
    updateVisible() {
        // Make a lookup table
        const selected = {};
        this.columnSelect.model.forEach((key) => {
            selected[key] = true;
        });

        // Update the visibilty based on the column selections
        this.columnSelect.labels.forEach((column) => {
            const key = column.key;
            this.visible[key] = (selected[key] === true);
        });

        // The Paste Materials columns should be available when either of the
        // materials columns are available.
        // But only when you can add more materials
        this.visible.pasteMaterials =
            !this.readonly && (this.visible.animals || this.visible.samples);

        // Count the visible columns so the header row is align correctly
        this.visibleCount =
            Object.keys(this.visible)
                .reduce((sum, key) => {
                    return sum + (this.visible[key] ? 1 : 0);
                }, 0);
    }

    /**
     * Parse the TaskGridConfiguration JSON string, or provide a blank config object
     */
    parseTaskGridConfiguration(): TaskGridConfig {
        try {
            if (this.facet.TaskGridConfiguration) {
                return JSON.parse(this.facet.TaskGridConfiguration);
            }
        } catch (e) {
            console.error('Could not parse TaskGridConfiguration', e);
        }

        return new TaskGridConfig();
    }

    /**
     * Column selections have changed
     */
    columnSelectChanged(current: string[]) {
        // Get the current selections
        this.columnSelect.model = current;

        // Update the column visibilty
        this.updateVisible();

        // Save the selection to the database
        this.saveTaskGridConfig();
    }

    /**
     * Save the column selections for this facet.
     */
    saveTaskGridConfig() {
        // Start from scratch
        const config = new TaskGridConfig();

        // Update each available column
        this.columnSelect.labels.forEach((column) => {
            const key = column.key;

            const columnConfig = new TaskGridColumnConfig();

            // Columns are visible unless explicitly hidden
            columnConfig.visible = (this.visible[key] !== false);

            config.columns[key] = columnConfig;
        });

        // Rebuild the TaskGridConfiguration JSON
        this.facet.TaskGridConfiguration = JSON.stringify(config);

        // Save just the TaskGridConfiguration value in the facet
        this.workspaceService.saveTaskGridConfiguration(this.facet);
    }

    validate() {
        return dateControlValidator(this.dateControls);
    }
}

import { ResultGrouper } from './result-grouper';
import { NestedColumn } from './nested-column';
import { 
    Component, 
    EventEmitter,
    Input,
    OnInit,
    OnChanges,
    Output
} from '@angular/core';
import { CustomSearchSchema } from './custom-search-schema';

@Component({
    selector: 'custom-search-results',
    templateUrl: './custom-search-results.component.html',
})
export class CustomSearchResultsComponent implements OnInit, OnChanges {
    @Input() customSearch: any;
    @Input() resultsData: any;

    @Output() clickPage: EventEmitter<number> = new EventEmitter<number>();

    resultGrouper: ResultGrouper;

    nestedColumns: NestedColumn[];
    results: any[];

    totalCount: number;
    totalPages: number;
    pageNumber: number;
    pageSize: number;
    rowsOnPage: number;

    constructor() {
        this.resultGrouper = new ResultGrouper();
    }

    ngOnInit() {
        this.initialize();
    }

    ngOnChanges(changes: any) {
        if (changes.customSearch && !changes.customSearch.firstChange) {
            this.initialize();
        }
        if (changes.resultsData && !changes.resultsData.firstChange) {
            this.initialize();
        }
    }

    initialize() {
        if (!this.customSearch || !this.resultsData) {
            return;
        }

        this.nestedColumns = this.resultGrouper.getNestedColumns(
            this.getSchema()
        );
        this.processResults();

        this.setPaginatorValues();
    }

    processResults() {
        this.results = [];

        if (!this.resultsData || 
            !this.resultsData.results ||
            !this.nestedColumns
        ) {
            return;
        }

        this.results = this.resultGrouper.groupResults(
            this.resultsData.results, this.nestedColumns
        );
    }

    getSchema(): CustomSearchSchema {
        return JSON.parse(this.customSearch.Schema);
    }

    setPaginatorValues() {
        this.totalCount = this.resultsData.totalCount || 0;
        this.pageNumber = this.resultsData.pageNumber || 1;
        this.pageSize = this.customSearch.RecordCount || 50;
        
        this.totalPages = this.totalCount ? Math.ceil(this.totalCount / this.pageSize) : 1;
        this.rowsOnPage = Math.min(this.pageNumber * this.pageSize, this.totalCount);
    }

    goToPage(pageNumber: number) {
        this.clickPage.emit(pageNumber);
    }
}


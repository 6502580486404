import { getSafeProp } from './get-safe-prop';
import { notEmpty } from './not-empty';
import { uniqueArray } from './unique-array';

interface MaterialPoolMaterials {
    Material: {
        Animal: Record<string, any>;
    };
}

/**
 * Returns all unique Materials from an array of MaterialPoolMaterials.
 * @param materialPoolMaterials - array of MaterialPoolMaterials
 */
export function uniqueHousingMaterials<T extends MaterialPoolMaterials>(materialPoolMaterials: T[]): T['Material'][] {
    let materials: T['Material'][] = [];

    if (notEmpty(materialPoolMaterials)) {
        const materialsAll: T['Material'][] = materialPoolMaterials
            .filter((materialPoolMaterial: T) => {
                const material = getSafeProp(materialPoolMaterial, 'Material');
                return material !== undefined && material !== null;
            }).map((materialPoolMaterial: T) => {
                return materialPoolMaterial.Material;
            });

        // Eliminate duplicates
        materials = uniqueArray(materialsAll);
    }

    return materials;
}

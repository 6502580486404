import type { Animal } from './animal.interface';
import type { Mating } from './mating.interface';

export interface cv_Generation {
    Animal: Animal[];
    C_Generation_key: number;
    C_VocabularySource_key: number | null;
    C_Workgroup_key: number;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    ElementID: string;
    Generation: string;
    IsActive: boolean;
    IsDefault: boolean;
    IsDeprecated: boolean;
    Mating: Mating[];
    ModifiedBy: string;
    SortOrder: number | null;
    Version: number;
}

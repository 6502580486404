
<ng-template #clinicalObservationChooser let-c="close">
    <observation-chooser [selectedObservations]="selectedObservation.ClinicalObservationDetail"
                         [selectedObservationForGLP]="selectedObservation.C_ClinicalObservation_key"
                         [selectedBodySystemForGLP]="selectedObservation.C_BodySystem_key"
                         (close)="c('Close')"
                         (onSelect)="onSelectObservations($event)">
    </observation-chooser>
</ng-template>

<ng-template #diagnosticObservationChooser let-c="close">
    <observation-chooser [diagnosticObservation]="true"
                         [selectedObservations]="selectedObservation.ClinicalObservationDetail"
                         [selectedObservationForGLP]="selectedObservation.C_ClinicalObservation_key"
                         [selectedBodySystemForGLP]="selectedObservation.C_BodySystem_key"
                         (close)="c('Close')"
                         (onSelect)="onSelectObservations($event)">
    </observation-chooser>
</ng-template>

<div class="col-md-12">
    <table class="table" id="observation-table">
        <thead>
            <tr class="detail-grid-header">
                <th style="width:35px;">
                    <a class="add-item-link detail-grid-button-label"
                       *ngIf="facet.Privilege!=='ReadOnly'" (click)="addObservation()">
                        <i [attr.data-automation-id]="'add-new-' + observationType + '-observation-button'" class="fa fa-plus-circle" title="Add new clinical observation"></i>
                    </a>
                </th>
                <th *ngIf="largeAnimalEnabled" colspan="9" id="observation-table-title">
                    <div class="detail-grid-button-label">{{observationType | titlecase}} Observations</div>
                </th>
                <th *ngIf="!largeAnimalEnabled" colspan="7" id="observation-table-title">
                    <div class="detail-grid-button-label">{{observationType | titlecase}} Observations</div>
                </th>
            </tr>
            <tr class="detail-grid-header" id="observation-table-headers">
                <th>&nbsp;</th>
                <th>{{'Job' | translate}} Name{{isGLP ? '' : 's'}}</th>
                <th>Date</th>
                <th>Observation Status</th>
                <th *ngIf="largeAnimalEnabled">Confirm</th>
                <th *ngIf="largeAnimalEnabled" style="width:210px">Last Confirmed Date and Time</th>
                <th [attr.colspan]="largeAnimalEnabled ? 2 : 1">Observations</th>
                <th *ngIf="!largeAnimalEnabled">&nbsp;</th>
                <th>Comments</th>
                <th>Observed By</th>
            </tr>
        </thead>
        <tbody>
            <ng-container *ngFor="let observation of observations | orderBy:'DateObserved':true; let i = index">
                <tr id="observation-table-row">
                    <td class="icon-cell">
                        <div class="form-control-plaintext">
                            <a (click)="removeObservation(observation)" *ngIf="facet.Privilege !== 'ReadOnly'">
                                <i class="fa fa-remove remove-item" title="Delete this observation"></i>
                            </a>
                        </div>
                    </td>
                    <td style="width: 140px">
                        {{healthRecord | clinicalJobNameFormat : 'observations' : observation.JobName : 0}}
                    </td>
                    <td style="width:70px;">
                        <climb-ngb-date
                                        [(ngModel)]="observation.DateObserved"
                                        [required]="true"
                                        ngDefaultControl>
                        </climb-ngb-date>
                    </td>
                    <td style="width:140px;">
                        <active-vocab-select [(model)]="observation.C_ClinicalObservationStatus_key"
                                             [fullWidth]="false"
                                             [dynamicWidth]="true"
                                             [vocabChoices]="clinicalObservationStatuses"
                                             [keyFormatter]="observationStatusKeyFormatter"
                                             [optionFormatter]="observationStatusFormatter"
                                             [nullable]="true">
                        </active-vocab-select>
                    </td>
                    <td *ngIf="largeAnimalEnabled" style="width:70px;display:flex;justify-content:center">
                        <clinical-confirm-button [isConfirmed]="observation.confirmStatus"
                                                 [isEndState]="observation.cv_ClinicalObservationStatus?.IsEndStatus"
                                                 (onConfirm)="confirmObservation(observation)"></clinical-confirm-button>
                    </td>
                    <td id="observation-table-review-date" *ngIf="largeAnimalEnabled" style="width: 140px">{{observation.ReviewDate | formatShortDateTime }}</td>
                    <td *ngIf="!largeAnimalEnabled" (click)="onObservationClicked(observation)">
                        <ng-container>
                            <div class="form-values-static mb-1">
                                <span *ngIf="observation.ClinicalObservationDetail | notEmpty">
                                    {{observation.ClinicalObservationDetail | map:'cv_ClinicalObservation' | map:'ClinicalObservation' | delimit:', '}}
                                </span>
                            </div>
                            <clinical-multiselect [selectedObservations]="observation.ClinicalObservationDetail"
                                                  (onSelect)="onSelectObservations($event)"></clinical-multiselect>
                        </ng-container>
                    </td>
                    <td *ngIf="largeAnimalEnabled" style="width:450px">
                        <div class="form-values-static mb-1">
                            <span *ngIf="largeAnimalEnabled">
                                {{observation.cv_BodySystem?.BodySystem}} {{observation.cv_BodySystem ? ':' : ''}} <strong>{{observation.cv_ClinicalObservation?.ClinicalObservation}}</strong>{{observation.cv_ClinicalObservation? ', ' : ''}}{{observation | clinicalObservationModifiersFormat}}
                            </span>
                        </div>
                    </td>
                    <td class="text-center" style="width:30px;vertical-align:top;">
                        <button type="button" class="btn btn-primary"
                                style="height:50px;width:25px;margin:1px 0;padding:0;"
                                (click)="openObservationChooser(observation)">
                            ...
                        </button>
                    </td>
                    <td style="width: 450px;">
                        <textarea name="comments-{{observation.C_AnimalClinicalObservation_key}}"
                                  [(ngModel)]="observation.Comments"
                                  style="min-height: 54px"
                                  class="form-control input-large"></textarea>
                    </td>
                    <td style="width:60px">
                        <active-vocab-select *ngIf="!isGLP"
                                             [(model)]="observation.C_Resource_key"
                                             [fullWidth]="false"
                                             [dynamicWidth]="true"
                                             [vocabChoices]="resources"
                                             [keyFormatter]="resourceKeyFormatter"
                                             [optionFormatter]="resourceNameFormatter"></active-vocab-select>
                        <div *ngIf="isGLP">
                            {{observation.ObservedByUsername}}
                        </div>
                    </td>
                </tr>
                <tr *ngIf="largeAnimalEnabled" id="observation-history-and-modifier-row">
                    <td>&nbsp;</td>
                    <td style="width:300px;">
                        <div class="form-group row mx-0 align-items-center">
                            <label class="col-form-label pt-0 mr-1"> Observation History</label>
                            <climb-expand [(model)]="observation.historyExpanded"
                                          label="History">
                            </climb-expand>
                        </div>
                    </td>
                    <td colspan="100">
                        <div class="row">
                            <div class="offset-md-4 col-md-2">
                                <label for="modifier1">Modifier1</label><br />
                                <climb-multi-select [(model)]="observation.C_Modifier1_key" 
                                                    [limit]="1"
                                                    [multipleSections]="true"
                                                    [values]="observation.modifier1VocabOptions"
                                                    [keyProperty]="'C_Modifier1_key'" 
                                                    [valueProperty]="'Modifier1'"
                                                    [placeholder]="'Select...'"
                                                    [disabled]="!observation.cv_ClinicalObservation"
                                                    [buttonClasses]="'limited-width'"></climb-multi-select>
                            </div>
                            <div class="col-md-2">
                                <label for="modifier2">Modifier2</label><br />
                                <climb-multi-select [(model)]="observation.C_Modifier2_key" 
                                                    [limit]="1"
                                                    [multipleSections]="true"
                                                    [values]="observation.modifier2VocabOptions"
                                                    [keyProperty]="'C_Modifier2_key'" 
                                                    [valueProperty]="'Modifier2'"
                                                    [placeholder]="'Select...'"
                                                    [disabled]="!observation.cv_ClinicalObservation"
                                                    [buttonClasses]="'limited-width'"></climb-multi-select>
                            </div>
                            <div class="col-md-2">
                                <label for="modifier3">Modifier3</label><br />
                                <climb-multi-select [(model)]="observation.C_Modifier3_key" 
                                                    [limit]="1"
                                                    [multipleSections]="true"
                                                    [values]="observation.modifier3VocabOptions"
                                                    [keyProperty]="'C_Modifier3_key'" 
                                                    [valueProperty]="'Modifier3'"
                                                    [placeholder]="'Select...'"
                                                    [disabled]="!observation.cv_ClinicalObservation"
                                                    [buttonClasses]="'limited-width'"></climb-multi-select>
                            </div>
                            <div class="col-md-2">
                                <label for="modifier4">Modifier4</label><br />
                                <climb-multi-select [(model)]="observation.C_Modifier4_key" 
                                                    [limit]="1"
                                                    [multipleSections]="true"
                                                    [values]="observation.modifier4VocabOptions"
                                                    [keyProperty]="'C_Modifier4_key'" 
                                                    [valueProperty]="'Modifier4'"
                                                    [placeholder]="'Select...'"
                                                    [disabled]="!observation.cv_ClinicalObservation"
                                                    [buttonClasses]="'limited-width'"></climb-multi-select>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr *ngIf="!largeAnimalEnabled">
                    <td>&nbsp;</td>
                    <td style="width:300px;">
                        <div class="form-group row mx-0 align-items-center">
                            <label class="col-form-label pt-0 mr-1"> Observation History</label>
                            <climb-expand [(model)]="observation.historyExpanded"
                                          label="History">
                            </climb-expand>
                        </div>
                    </td>
                    <td colspan="100">
                    </td>
                </tr>
                <tr *ngIf="observation.historyExpanded">
                    <td colspan="4" style="padding-left: 30px !important;">
                        <table class="table" id="observation-history-table" *ngIf="observation.Event.length > 0">
                            <thead>
                                <tr id="observation-history-table-headers">
                                    <th>Event</th>
                                    <th>Date</th>
                                    <th>By</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr id="observation-history-table-row" *ngFor="let event of observation.Event | orderBy:'DateOccurred':true">
                                    <td>
                                        <div class="form-control-plaintext">
                                            <span class="event-type-label"
                                                  [ngClass]="{'event-add': event.cv_EventType?.Label.includes('add'),
                                                    'event-delete': event.cv_EventType?.Label.includes('delete'),
                                                    'event-update': event.cv_EventType?.Label.includes('Reviewed')}">
                                                {{event.cv_EventType?.Label}}
                                            </span>
                                            <span class="event-label"
                                                  [ngClass]="{'event-add': event.cv_EventType?.Label.includes('add'),
                                                    'event-delete': event.cv_EventType?.Label.includes('delete'),
                                                    'event-update': event.cv_EventType?.Label.includes('Reviewed')}">

                                                {{event | validDate : false}}

                                            </span>
                                        </div>
                                    </td>
                                    <td style="width:240px">
                                        {{event.DateOccurred | formatShortDateTime}}
                                    </td>
                                    <td>
                                        <div class="form-control-plaintext">
                                            {{event.CreatedBy}}
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
            </ng-container>
        </tbody>
    </table>
</div>
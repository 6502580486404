import {
    Component,
    EventEmitter,
    Input,
    Output
} from '@angular/core';

import { JobService } from './job.service';

const MAX_SEARCH_RESULTS = 50;

@Component({
    selector: 'group-placeholder-multiselect',
    template: `
<!-- Selected Placeholders -->
<div *ngIf="model && model.length > 0">
    <table class="table table-sm table-borderless table-nonfluid">
        <tbody>
            <tr *ngFor="let item of model; let i = index;">
                <td class="icon-cell">
                    <a (click)="removePlaceholder(i)" title="Remove Placeholder">
                        <i class="fa fa-remove remove-item"></i>
                    </a>
                </td>
                <td>
                    {{item.PlaceholderName}}
                </td>
            </tr>
        </tbody>
    </table>
</div>

<!-- Placeholder typeahead -->
<climb-indirect-typeahead
        [search]="searchPlaceholders"
        [resultFormatter]="placeholderFormatter"
        placeholder="Name&hellip;"
        (selectItem)="selectPlaceholder($event)"></climb-indirect-typeahead>
`,
    styles: [`
        .table {
            margin-bottom: 0.25em;
        }
    `]
})
export class GroupPlaceholderMultiselectComponent {
    @Input() model: any[];
    @Output() modelChange: EventEmitter<any> = new EventEmitter<any>();

    constructor(
        private jobService: JobService
    ) { }

    removePlaceholder(index: number) {
        if (this.model && index >= 0 && index < this.model.length) {
            this.model.splice(index, 1);
        }
        this.modelChange.emit(this.model);
    }

    searchPlaceholders = (term: string): Promise<any> => {
        return this.jobService.getGroupPlaceholders({
            page: 1,
            size: MAX_SEARCH_RESULTS,
            sort: 'PlaceholderName asc',
            filter: {
                name: term
            }
        }).then((results: any) => {
            // Convert Breeze entity to simple object
            // so it can be serialized by consumers
            return results.results.map((placeholder: any) => ({
                C_Placeholder_key: placeholder.C_Placeholder_key,
                PlaceholderName: placeholder.PlaceholderName
            }));
        });
    }

    placeholderFormatter = (value: any) => {
        return value.PlaceholderName;
    }

    selectPlaceholder(placeholder: any) {
        if (this.model) {
            this.model.push(placeholder);
        } else {
            this.model = [placeholder];
        }

        this.modelChange.emit(this.model);
    }
}

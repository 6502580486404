import { Pipe, PipeTransform } from '@angular/core';
import { DateFormatterService } from '../util/date-time-formatting/date-formatter.service';

/*
* Return short formatted date with time component.
*   time is hidden if set to midnight
*/
@Pipe({ name: 'formatShortDateOrTime' })
export class FormatShortDateOrTimePipe implements PipeTransform {
    constructor(private dateFormatterService: DateFormatterService) {

    }

    transform(value: any): string {
        return this.dateFormatterService.formatDateOrTime(value);
    }
}

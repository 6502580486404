<div class="modal-header justify-content-center">
    <h4 class="modal-title">
        {{title}}
    </h4>
</div>
<div class="modal-body">
    <climb-column-select [columnLabels]="columnSelect"
                         class="old-fashioned float-right"
                         (onChange)="columnSelectChanged($event)">
    </climb-column-select>
    <table class="table table-bordered">
        <thead>
            <tr>
                <th *ngIf="visible.TaskAlias">Task Name</th>
                <th *ngIf="visible.Task">Task</th>
                <th *ngIf="visible.TaskStatus">
                    <ng-container *ngIf="readonly">Task Status</ng-container>
                    <ng-container *ngIf="!readonly">
                        <bulk-edit-header [headerText]="'Task Status'"
                                          (updateClicked)="fillDownStatus()">
                            <div modal-content>
                                <table class="table-borderless text-body">
                                    <tr>
                                        <td colspan="2" class="pb-0"><label>Task</label></td>
                                    </tr>
                                    <tr>
                                        <td colspan="2">
                                            <select [(ngModel)]="bulk.dateDueTask"
                                                    class="form-control input-medium"
                                                    (ngModelChange)="dueTaskChanged()"
                                                    name="dateDueTasks">
                                                <option *ngIf="bulk.dateDueTasks.length == 0"
                                                        value="null">
                                                    [ No valid tasks ]
                                                </option>
                                                <option *ngIf="bulk.dateDueTasks.length > 1" value="all-tasks-selection">
                                                    [ All ]
                                                </option>
                                                <option *ngFor="let task of bulk.dateDueTasks"
                                                        [value]="task">
                                                    {{task}}
                                                </option>
                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="2" class="pb-0"><label>Task Name</label></td>
                                    </tr>
                                    <tr>
                                        <td colspan="2">
                                            <select [(ngModel)]="bulk.dateDueTaskAlias"
                                                    class="form-control input-medium"
                                                    name="bulk_dateDueTaskAlias"
                                                    [disabled]="bulk.dateDueTaskAliases.length == 0">
                                                <option *ngIf="bulk.dateDueTaskAliases.length > 1" value="all-tasks-alias-selection">
                                                    [ All ]
                                                </option>
                                                <option *ngFor="let taskAlias of bulk.dateDueTaskAliases"
                                                        [value]="taskAlias">
                                                    {{taskAlias}}
                                                </option>
                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="2" class="pb-0"><label>Cohort</label></td>
                                    </tr>
                                    <tr>
                                        <td colspan="2">
                                            <select [(ngModel)]="bulk.dateDueCohort"
                                                    class="form-control input-medium"
                                                    name="dateDueCohort"
                                                    [disabled]="bulk.dateDueCohorts.length === 0"
                                                    (ngModelChange)="bulkCohortChanged()">
                                                <option *ngIf="bulk.dateDueCohorts.length > 1" value="all-cohorts-selection">
                                                    [ All ]
                                                </option>
                                                <option *ngIf="bulk.dateDueCohorts.length > 0" value="null">[ No Cohort ]</option>
                                                <option *ngFor="let cohort of bulk.dateDueCohorts"
                                                        [value]="cohort">
                                                    {{cohort}}
                                                </option>
                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="2" class="pb-0"><label>Animal</label></td>
                                    </tr>
                                    <tr>
                                        <td colspan="2">
                                            <select [(ngModel)]="bulk.dateDueAnimal"
                                                    class="form-control input-medium"
                                                    name="dateDueAnimal"
                                                    [disabled]="bulk.dateDueAnimals.length === 0">
                                                <option *ngIf="bulk.dateDueAnimals.length > 1" value="all-animals-selection">
                                                    [ All ]
                                                </option>
                                                <option *ngFor="let animal of bulk.dateDueAnimals"
                                                        [value]="animal">
                                                    {{animal}}
                                                </option>
                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="2" class="pb-0"><label>Task Status</label></td>
                                    </tr>
                                    <tr>
                                        <td colspan="2">
                                            <active-vocab-select [(model)]="fillDownTaskStatusKey"
                                                                 [vocabChoices]="taskStatuses"
                                                                 [keyFormatter]="taskStatusKeyFormatter"
                                                                 [optionFormatter]="taskStatusFormatter"></active-vocab-select>
                                        </td>
                                    </tr>

                                </table>
                            </div>
                        </bulk-edit-header>
                    </ng-container>
                </th>
                <th *ngIf="visible.AnimalName">Animal</th>
                <th *ngIf="visible.Cohort">Cohort</th>
                <th *ngIf="visible.HousingID">Housing ID</th>
                <th *ngIf="visible.SampleName">Output Sample</th>
                <th *ngIf="visible.DateDue">
                    <ng-container *ngIf="readonly">Date Due</ng-container>
                    <ng-container *ngIf="!readonly">
                        <bulk-edit-header [headerText]="'Date Due'"
                                          (updateClicked)="bulkDateDueChanged()">
                            <div modal-content>
                                <table class="table-borderless text-body">
                                    <tr>
                                        <td colspan="2" class="pb-0"><label>Task</label></td>
                                    </tr>
                                    <tr>
                                        <td colspan="2">
                                            <select [(ngModel)]="bulk.dateDueTask"
                                                    class="form-control input-medium"
                                                    (ngModelChange)="dueTaskChanged()"
                                                    name="dateDueTasks">
                                                <option *ngIf="bulk.dateDueTasks.length == 0"
                                                        value="null">
                                                    [ No valid tasks ]
                                                </option>
                                                <option *ngIf="bulk.dateDueTasks.length > 1" value="all-tasks-selection">
                                                    [ All ]
                                                </option>
                                                <option *ngFor="let task of bulk.dateDueTasks"
                                                        [value]="task">
                                                    {{task}}
                                                </option>
                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="2" class="pb-0"><label>Task Name</label></td>
                                    </tr>
                                    <tr>
                                        <td colspan="2">
                                            <select [(ngModel)]="bulk.dateDueTaskAlias"
                                                    class="form-control input-medium"
                                                    name="bulk_dateDueTaskAlias"
                                                    [disabled]="bulk.dateDueTaskAliases.length == 0">
                                                <option *ngIf="bulk.dateDueTaskAliases.length > 1" value="all-tasks-alias-selection">
                                                    [ All ]
                                                </option>
                                                <option *ngFor="let taskAlias of bulk.dateDueTaskAliases"
                                                        [value]="taskAlias">
                                                    {{taskAlias}}
                                                </option>
                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="2" class="pb-0"><label>Cohort</label></td>
                                    </tr>
                                    <tr>
                                        <td colspan="2">
                                            <select [(ngModel)]="bulk.dateDueCohort"
                                                    class="form-control input-medium"
                                                    name="dateDueCohort"
                                                    [disabled]="bulk.dateDueCohorts.length === 0"
                                                    (ngModelChange)="bulkCohortChanged()">
                                                <option *ngIf="bulk.dateDueCohorts.length > 1" value="all-cohorts-selection">
                                                    [ All ]
                                                </option>
                                                <option *ngIf="bulk.dateDueCohorts.length > 0" value="null">[ No Cohort ]</option>
                                                <option *ngFor="let cohort of bulk.dateDueCohorts"
                                                        [value]="cohort">
                                                    {{cohort}}
                                                </option>
                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="2" class="pb-0"><label>Animal</label></td>
                                    </tr>
                                    <tr>
                                        <td colspan="2">
                                            <select [(ngModel)]="bulk.dateDueAnimal"
                                                    class="form-control input-medium"
                                                    name="dateDueAnimal"
                                                    [disabled]="bulk.dateDueAnimals.length === 0">
                                                <option *ngIf="bulk.dateDueAnimals.length > 1" value="all-animals-selection">
                                                    [ All ]
                                                </option>
                                                <option *ngFor="let animal of bulk.dateDueAnimals"
                                                        [value]="animal">
                                                    {{animal}}
                                                </option>
                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="2" class="pb-0"><label>Starting at</label></td>
                                    </tr>
                                    <tr>
                                        <td colspan="2">
                                            <climb-ngb-date #dateControl="ngModel"
                                                            [(ngModel)]="bulk.dateDue"
                                                            [ngModelOptions]="{ standalone: true }"
                                                            [allowTime]="true"
                                                            [alwaysShowTime]="true">
                                            </climb-ngb-date>
                                        </td>
                                    </tr>

                                </table>
                            </div>
                        </bulk-edit-header>
                    </ng-container>
                </th>
                <th *ngIf="visible.TimeDue">
                    <ng-container *ngIf="readonly">Time Due</ng-container>
                    <ng-container *ngIf="!readonly">
                        <bulk-edit-header [headerText]="'Time Due'"
                                          (updateClicked)="bulkTimeDueChanged()">
                            <div modal-content>
                                <table class="table-borderless text-body">
                                    <tr>
                                        <td colspan="2" class="pb-0"><label>Time Due</label></td>
                                    </tr>
                                    <tr>
                                        <td colspan="2">
                                            <climb-ngb-date #dateControl="ngModel"
                                                            [(ngModel)]="bulk.dateDue"
                                                            [ngModelOptions]="{ standalone: true }"
                                                            [allowDate]="false"
                                                            [allowTime]="true"
                                                            [alwaysShowTime]="true">
                                            </climb-ngb-date>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </bulk-edit-header>
                    </ng-container>
                </th>
                <th>Assigned To</th>
                <th *ngIf="visible.CompleteTask">
                    <button class="btn btn-primary"
                            [disabled]="readonly"
                            (click)="completeAllTasks()"
                            title="Mark all tasks complete">
                        Complete<br />all tasks
                    </button>
                </th>
                <th *ngIf="visible.CompletedBy">
                    <ng-container *ngIf="readonly">Completed By</ng-container>
                    <ng-container *ngIf="!readonly">
                        <bulk-edit-header [headerText]="'Completed By'"
                                          (updateClicked)="bulkCompletedByChanged()">
                            <div modal-content>
                                <table class="table-borderless text-body">
                                    <tr>
                                        <td colspan="2" class="pb-0"><label>Completed By</label></td>
                                    </tr>
                                    <tr>
                                        <td colspan="2">
                                            <active-vocab-select [(model)]="bulk.completedByKey"
                                                                 [vocabChoices]="resources"
                                                                 [keyFormatter]="resourceKeyFormatter"
                                                                 [optionFormatter]="resourceNameFormatter"></active-vocab-select>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </bulk-edit-header>
                    </ng-container>
                </th>
                <th *ngIf="visible.CompletedDate">
                    <ng-container *ngIf="readonly">Completed Date</ng-container>
                    <ng-container *ngIf="!readonly">
                        <bulk-edit-header [headerText]="'Completed Date'"
                                          (updateClicked)="bulkCompletedDateChanged()">
                            <div modal-content>
                                <table class="table-borderless text-body">
                                    <tr>
                                        <td colspan="2" class="pb-0"><label>Completed Date</label></td>
                                    </tr>
                                    <tr>
                                        <td colspan="2">
                                            <climb-ngb-date #dateControl="ngModel"
                                                            [(ngModel)]="bulk.completedTime"
                                                            [ngModelOptions]="{ standalone: true }"></climb-ngb-date>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </bulk-edit-header>
                    </ng-container>
                </th>
                <th *ngIf="visible.CompletedTime">
                    <ng-container *ngIf="readonly">Completed Time</ng-container>
                    <ng-container *ngIf="!readonly">
                        <bulk-edit-header [headerText]="'Completed Time'"
                                          (updateClicked)="bulkCompletedTimeChanged()">
                            <div modal-content>
                                <table class="table-borderless text-body">
                                    <tr>
                                        <td colspan="2" class="pb-0"><label>Completed Time</label></td>
                                    </tr>
                                    <tr>
                                        <td colspan="2">
                                            <climb-ngb-date #dateControl="ngModel"
                                                            [(ngModel)]="bulk.completedTime"
                                                            [ngModelOptions]="{ standalone: true }"
                                                            [allowDate]="false"
                                                            [allowTime]="true"
                                                            [alwaysShowTime]="true">
                                            </climb-ngb-date>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </bulk-edit-header>
                    </ng-container>
                </th>
                <th *ngIf="visible.Lock">Lock</th>
            </tr>
        </thead>
        <tbody>
            <!-- fill down row -->
            <tr *ngFor="let task of tasks | paginate: { itemsPerPage: 30, currentPage: taskPage };">
                <td *ngIf="visible.TaskAlias">
                    <p class="form-control-plaintext">
                        {{task.TaskAlias}}
                    </p>
                </td>
                <td *ngIf="visible.Task">
                    <p class="form-control-plaintext">
                        {{task.WorkflowTask?.TaskName}}
                    </p>
                </td>
                <td *ngIf="visible.TaskStatus">
                    <active-vocab-select [(model)]="task.C_TaskStatus_key"
                                         [vocabChoices]="taskStatuses"
                                         [keyFormatter]="taskStatusKeyFormatter"
                                         [optionFormatter]="taskStatusFormatter"
                                         (modelChange)="taskStatusChanged(task)"
                                         [readonly]="readonly || task.IsWorkflowLocked"></active-vocab-select>
                </td>
                <td *ngIf="visible.AnimalName">
                    <p class="form-control-plaintext" *ngFor="let material of task.TaskMaterial">
                        <span *ngIf="material.Material && material.Material.Animal">
                            {{ material.Material.Animal.AnimalName }}
                        </span>
                    </p>
                </td>
                <td *ngIf="visible.Cohort">
                    <ng-container *ngFor="let tm of task.TaskMaterial">
                        <ng-container *ngFor="let cm of tm.Material.CohortMaterial">
                            <ng-container *ngFor="let jc of job.JobCohort">
                                <div *ngIf="cm.C_Cohort_key == jc.C_Cohort_key && cm.Cohort as cohort"
                                     class="cohort-badge"
                                     [ngStyle]="{ backgroundColor: cohort.BackgroundColor, color: cohort.ForegroundColor }">
                                    {{cohort.CohortName}}
                                </div>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </td>
                <td *ngIf="visible.HousingID">
                    <p class="form-control-plaintext" *ngFor="let material of task.TaskMaterial">
                        <span *ngIf="material.Material && material.Material.MaterialPoolMaterial && material.Material.MaterialPoolMaterial.length > 0">
                            <span *ngFor="let housing of material.Material.MaterialPoolMaterial">
                                <span *ngIf="housing.DateOut === null">
                                    {{ housing.MaterialPool?.MaterialPoolID }}
                                </span>
                            </span>
                        </span>
                    </p>
                </td>
                <td *ngIf="visible.SampleName">
                    <p class="form-control-plaintext" *ngFor="let material of task.TaskMaterial">
                        <span *ngIf="material.Material && material.Material.Sample">
                            {{ material.Material.Sample.SampleName }}
                        </span>
                    </p>
                </td>
                <td *ngIf="visible.DateDue">
                    <climb-ngb-date #dateControl="ngModel"
                                    [(ngModel)]="task.DateDue"
                                    [ngModelOptions]="{ standalone: true }"
                                    [disabled] ="readonly || task.IsWorkflowLocked"></climb-ngb-date>
                </td>
                <td *ngIf="visible.TimeDue">
                    <climb-ngb-date #dateControl="ngModel"
                                    [(ngModel)]="task.DateDue"
                                    [ngModelOptions]="{ standalone: true }"
                                    [allowDate]="false"
                                    [allowTime]="true"
                                    [alwaysShowTime]="true"
                                    [disabled]="readonly || task.IsWorkflowLocked">
                    </climb-ngb-date>
                </td>
                <td>
                    <p class="form-control-plaintext">
                        {{task.AssignedToResource?.ResourceName}}
                    </p>
                </td>
                <td class="text-center" *ngIf="visible.CompleteTask">
                    <task-complete-button [task]="task"
                                          [disabled]="readonly || task.IsWorkflowLocked"></task-complete-button>
                </td>
                <td *ngIf="visible.CompletedBy">
                    <active-vocab-select [(model)]="task.C_CompletedBy_key"
                                         [vocabChoices]="resources"
                                         [keyFormatter]="resourceKeyFormatter"
                                         [optionFormatter]="resourceNameFormatter"
                                         [readonly]="readonly || task.IsWorkflowLocked"></active-vocab-select>
                </td>
                <td *ngIf="visible.CompletedDate">
                    <climb-ngb-date #dateControl="ngModel"
                                    [(ngModel)]="task.DateComplete"
                                    [ngModelOptions]="{ standalone: true }"
                                    [disabled]="readonly || task.IsWorkflowLocked"></climb-ngb-date>
                </td>
                <td *ngIf="visible.CompletedTime">
                    <climb-ngb-date #dateControl="ngModel"
                                    [(ngModel)]="task.DateComplete"
                                    [ngModelOptions]="{ standalone: true }"
                                    [allowDate]="false"
                                    [allowTime]="true"
                                    [alwaysShowTime]="true"
                                    [disabled]="readonly || task.IsWorkflowLocked">
                    </climb-ngb-date>
                </td>
                <td *ngIf="visible.Lock"
                    class="icon-cell col-size-fit">
                    <lock-control [(model)]="task.IsWorkflowLocked"
                                    [readonly]="true"
                                    [largeText]="true"></lock-control>
                </td>
            </tr>
        </tbody>
    </table>
    <div style="position: relative">
        <pagination-controls (pageChange)="changeTaskPage($event)"
                             [autoHide]="true"
                             class="ngx-table-paginator"></pagination-controls>
    </div>
</div>
<div class="modal-footer">
    <span class="btn-set">
        <button type="button"
                class="btn btn-lg btn-success"
                (click)="close()">
            Save Changes
        </button>
        <button type="button"
                class="btn btn-lg btn-secondary"
                (click)="discard()">
            Discard Changes
        </button>
    </span>
</div>

import { SearchQueryDef } from '../../../search/search-query-def';
import { SearchService } from '../../../search/search.service';
import {
    Component,
    EventEmitter,
    Input,
    Output
} from '@angular/core';

/**
 * Selects only housing units of type 'Mating'
 * 
 * NOTE:
 *   Uses Matings search, because it includes the Housing 
 *   MaterialPoolID as 'HousingID'
 *   and _MaterialPool_key as 'MatingKey'
 */
@Component({
    selector: 'mating-housing-select',
    template: `
    <div class="housing-select">
        <climb-typeahead
            [(model)]="model"
            [resultFormatter]="housingIdFormatter"
            [keyFormatter]="housingKeyFormatter"
            [search]="searchHousingUnits"
            [keySearch]="searchHousingUnitsByKey"  
            [required]="required"
            [namespace]="'housing'"
            (selectItem)="selectItemHandler($event)"
            #typeahead
        ></climb-typeahead>
    </div>
    `,
    styles: [`
        .housing-select {
            min-width: 100px;
        }
    `]
})
export class MatingHousingSelectComponent {
    @Input() model: any;
    @Output() modelChange: EventEmitter<any> = new EventEmitter<any>();
    @Input() required: boolean;

    constructor(
        private searchService: SearchService
    ) {
        // nothing to do
    }

    selectItemHandler(item: any) {
        this.modelChange.emit(this.model);
    }

    searchHousingUnits = (search: string): Promise<any[]> => {

        const filter = {
            HousingID: search
        };


        let count = null;
        // if 2 characters or less, put a limit on number returned
        if (search.length < 3) {
            count = 50;
        }

        return this._searchHousingUnits(filter, count);
    }

    searchHousingUnitsByKey = (key: any): Promise<any[]> => {
        const filter = {
            MatingKey: key
        };
        return this._searchHousingUnits(filter, 1);
    }

    _searchHousingUnits(filter: any, count: number): Promise<any[]> {
        const searchQueryDef: SearchQueryDef = {
            entity: 'Matings',
            page: 1,
            size: count,
            sortColumn: 'HousingID',
            sortDirection: 'asc',
            filter
        };

        return this.searchService.getEntitiesBySearch(searchQueryDef)
            .then((queryResult: any) => {
                return queryResult.data;
            });
    }

    housingIdFormatter = (item: any) => {
        return item.HousingID;
    }

    housingKeyFormatter = (item: any) => {
        return item.MatingKey;
    }
}

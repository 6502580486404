import {
    Component,
    EventEmitter,
    OnInit,
    OnDestroy,
    Output,
    ChangeDetectorRef,
    Input,
    ChangeDetectionStrategy,
} from '@angular/core';

import { AppInsightsService } from '../../../services/app-insights.service';
import { FileService } from '../../../services/file.service';
import { ReportingService } from '../../reporting.service';

import { ReportSource, ReportTypeItem } from '../../models';
import { Subscription, Subject, from, timer, Observable, BehaviorSubject } from 'rxjs';
import { map, concatMap, takeUntil, takeWhile, switchMap, tap } from 'rxjs/operators';

import { ExportFileRequest, StoredFile, RequestStatus, isExportInProgress } from '@common/types';

@Component({
    selector: 'export-request-list',
    templateUrl: './export-request-list.component.html',
    styles: [`
        .intro {
            margin-bottom: 2em;
        }
        .list-toolbar {
            margin-bottom: 0.75em;
            text-align: right;
        }
    `],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExportRequestListComponent implements OnInit, OnDestroy {
    @Input() reportType: ReportTypeItem;
    
    @Output() addItem: EventEmitter<ReportSource> = new EventEmitter<ReportSource>();

    // Model
    exportFileRequests: ExportFileRequest[] = [];

    // State
    loading = true;

    // Export enum to template
    RequestStatus = RequestStatus;
    
    componentSubscription: Subscription;
    private onDestroy$ = new Subject<void>();
    private onLoad$ = new BehaviorSubject<string>('');

    private readonly DATA_POLLING_MS = 5000;


    constructor(
        private appInsightsService: AppInsightsService,
        private fileService: FileService,
        private ref: ChangeDetectorRef,
        private reportingService: ReportingService,
    ) {}

    ngOnInit(): void {
        this.subscribeToData();
    }

    private getExportRequests$(): Observable<ExportFileRequest[]> {
        return from(this.reportingService.getExportFileRequests(this.reportType?.value));
    }

    private subscribeToData(): void {
        this.onLoad$.pipe(
            takeUntil(this.onDestroy$),
            switchMap(() => timer(0, this.DATA_POLLING_MS).pipe(
                takeUntil(this.onDestroy$),
                tap(() => this.loading = true),
                concatMap(() => this.getExportRequests$()),
                map(data => data || []),
                takeWhile(data => data.some(isExportInProgress), true),
            )
        ))
        .subscribe(data => {
                this.exportFileRequests = data;
                this.loading = false;
                this.ref.detectChanges();
            },
            error => console.error(error)
        );
    }

    public onClickRefresh(): void {
        this.loading = true;
        this.onLoad$.next('');
    }

    // User Operations
    requestExport(source: string): void {
        if (source === "task") {
            this.addItem.emit(ReportSource.BulkExport);
        } else if (source === "animalCheck") {
            this.addItem.emit(ReportSource.AnimalCheck);
        }
    }

    downloadFile(storedFile: StoredFile): void {
        this.appInsightsService.trackEvent('export-file-download', {
            file: storedFile.FriendlyFileName || ''
        });

        this.componentSubscription = this.fileService.downloadFile(
            storedFile.FileName,
            storedFile.FriendlyFileName
        ).subscribe();
    }
    
    ngOnDestroy(): void {
        if (this.componentSubscription) {
            this.componentSubscription.unsubscribe();
        }

        this.onDestroy$.next();
        this.onDestroy$.complete();
    }
}

import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import { NgForm } from '@angular/forms';

import { ConstructService } from './construct.service';

import {
    BaseDetail,
    BaseDetailService,
    PageState
} from '../common/facet';

@Component({
    selector: 'construct-detail',
    templateUrl: './construct-detail.component.html'
})
export class ConstructDetailComponent extends BaseDetail
    implements OnChanges, OnInit {
    @Input() facet: any;
    @Input() construct: any;
    @Input() pageState: PageState;
    @Output() exit: EventEmitter<void> = new EventEmitter<void>();
    @Output() next: EventEmitter<void> = new EventEmitter<void>();
    @Output() previous: EventEmitter<void> = new EventEmitter<void>();

    @ViewChild("constructForm") constructForm: NgForm;

    // State
    lines: any[] = [];
    saving = false;

    readonly COMPONENT_LOG_TAG = 'construct-detail';


    constructor(
        private baseDetailService: BaseDetailService,
        private constructService: ConstructService
    ) {
        super(baseDetailService);
    }

    // lifecycle
    ngOnInit() {
        this.initialize();
    }

    ngOnChanges(changes: any) {
        if (changes.construct) {
            if (this.construct && !changes.construct.firstChange) {
                if (this.constructForm) {
                    this.constructForm.form.markAsPristine();
                }
                this.initialize();
            }
        }
    }

    initialize() {
        return this.getCVs().then(() => {
            return this.getDetails();
        });
    }

    private async getDetails(): Promise<any> {
        if (this.construct?.C_Construct_key > 0) {
            this.setLoading(true);

            const expands: string[] = [];

            try {
                await this.constructService.getConstruct(this.construct.C_Construct_key, expands);
                const lines = await this.constructService.getLines(this.construct.C_Construct_key);
                this.lines = lines;
            } catch (error) {
                console.error(error);
            } finally {
                this.setLoading(false);
            }
        }

        return Promise.resolve(this.construct);
    }

    private getCVs(): Promise<any> {
        // None
        return Promise.resolve();
    }

    onCancel() {
        this.constructService.cancelConstruct(this.construct);
    }
}

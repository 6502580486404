import { Component, Input } from '@angular/core';

@Component({
    selector: 'enumeration-filter',
    template: `
        <form class="form-inline">
            <select
                    [(ngModel)]="node.operator"
                    (ngModelChange)="changeOperator()"
                    [ngModelOptions]="{standalone: true}"
                    class="form-control">
                <option *ngFor="let op of operators" [ngValue]="op">{{ op }}</option>
            </select>
            
            <climb-multi-select
                    *ngIf="showValues()"
                    [(model)]="node.values"
                    [values]="node.enumerations"
                    [placeholder]="'Select filter values...'"></climb-multi-select>
        </form>
    `,
})
export class EnumerationFilterComponent {
    @Input() node: any = null;
    readonly operators = ['any in', 'has no value'];

    changeOperator() {
        if (this.node.operator === 'has no value') {
            this.node.searchNulls = true;
        } else {
            this.node.searchNulls = false;
        }
    }

    showValues(): boolean {
        return !this.node.searchNulls;
    }

    clear() {
        this.node.values = [];
        this.node.searchNulls = false;
        if (this.node.operator === 'has no value') {
            this.node.operator = 'any in';
        }
    }
}

import type { Resource } from './resource.interface';
import type { TreatmentPlan } from './treatment-plan.interface';

export interface TreatmentPlanDetail {
    AssignedToResource: Resource;
    C_AssignedTo_key: number | null;
    C_CompletedBy_key: number | null;
    C_TreatmentPlanDetail_key: number;
    C_TreatmentPlan_key: number;
    C_Workgroup_key: number;
    CompletedByResource: Resource;
    CreatedBy: string;
    DateComplete: Date | null;
    DateCreated: Date;
    DateDue: Date | null;
    DateModified: Date;
    Details: string;
    ModifiedBy: string;
    Notes: string;
    TreatmentPlan: TreatmentPlan;
    Version: number;
}

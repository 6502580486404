<loading-overlay *ngIf="loading" [message]="loadingMessage()"></loading-overlay>
<ng-container *ngIf="rendered">
    <dx-button stylingMode="contained" text="Weekly" [width]="100" (onClick)="changeScale('weeks')"></dx-button>
    <dx-button stylingMode="contained" text="Monthly" [width]="100" (onClick)="changeScale('months')"></dx-button>
    <dx-button stylingMode="contained" text="Quarterly" [width]="100" (onClick)="changeScale('quarters')"></dx-button>
    <dx-button stylingMode="contained" text="Yearly" [width]="100" (onClick)="changeScale('years')"></dx-button>
</ng-container>

<div *ngIf="errorMessage" class="text-danger help-container">
    <i class="fa fa-exclamation-triangle" aria-hidden="true"> </i>
    {{ errorMessage }}
</div>
<div *ngIf="warningMessage" class="text-warning help-container">
    <i class="fa fa-exclamation-triangle" aria-hidden="true"> </i>
    {{ warningMessage }}
</div>
<div *ngIf="rendered" class="help-container text-info text-right">
    <i class="fa fa-info-circle" aria-hidden="true"> </i> &nbsp;Click/touch and drag to navigate the chart.
</div>
<div *ngIf="!rendered" class="d-flex" style="justify-content: center">
    <h2>Data not yet selected. Please select data to view.</h2>
</div>
<dx-gantt #gantt
          *ngIf="!loading && rendered"
          scaleType="weeks"
          [allowSelection]="false"
          height="700"
          [taskListWidth]="500"
          [scaleType]="scaleType"
          taskTooltipContentTemplate="myTaskTooltipTemplate">
    <div *dxTemplate="let task of 'myTaskTooltipTemplate'" class="task-tooltip">
        <div class="job-name">
            <span class="tooltip-title">{{ "Job" | translate}}:&nbsp;</span>{{task.title}}
        </div>
        <div class="job-duration">
            <span class="tooltip-title">Duration:&nbsp;</span>{{getDurationInDays(task.start, task.end)}} Days
        </div>
        <div class="job-location" *ngIf="jobTaskMap[task.title].CurrentLocationPath">
            <span class="tooltip-title">Location:&nbsp;</span>{{jobTaskMap[task.title].CurrentLocationPath}}
        </div>
        <div class="job-lead-scientist" *ngIf="jobTaskMap[task.title].LeadScientist">
            <span class="tooltip-title">Lead Scientist:&nbsp;</span>{{jobTaskMap[task.title].LeadScientist}}
        </div>
        <div class="job-program" *ngIf="jobTaskMap[task.title].Study">
            <span class="tooltip-title">{{"Study" | translate}}:&nbsp;</span>{{jobTaskMap[task.title].Study.StudyName}}
        </div>
    </div>
    <dxo-tasks colorExpr="taskColor" [dataSource]="jobsData"></dxo-tasks>
    <dxo-context-menu [enabled]="false">
    </dxo-context-menu>
    <dxi-column dataField="title" caption="Subject" [width]="150"></dxi-column>
    <dxi-column dataField="start" caption="Start Date" [width]="100"></dxi-column>
    <dxi-column dataField="end" caption="End Date" [width]="100"></dxi-column>
    <dxo-toolbar>
        <dxi-item name="zoomIn"></dxi-item>
        <dxi-item name="zoomOut"></dxi-item>
        <dxi-item widget="dxButton" [options]="exportButtonOptions"> </dxi-item>
        <dxi-item widget="dxButton" *ngFor="let studyName of studyNames">
            <div *dxTemplate class="legend-item">
                <div class="legend-box" [style.backgroundColor]="deriveColor(studyName)"></div>
                <div class="legend-name">
                    {{studyName}}
                </div>
            </div>
        </dxi-item>
    </dxo-toolbar>
</dx-gantt>

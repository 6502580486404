/*
* Adds .finally() method to Promises. We use them often in tandem with breeze queries
*/

function _finally(callback: any) {
    const resolve = () => { 
        return this;
    };
    const fin = () => {
        const cbValue: any = callback();
        return Promise.resolve(cbValue).then(resolve);
    };
    return this.then(fin, fin);
}

/* eslint-disable-next-line */
Promise.prototype['finally'] = _finally;

import { Directive, Host, HostListener, Inject, Optional } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { DOWN_ARROW, UP_ARROW } from '@angular/cdk/keycodes';
import { TrapFocusDirective } from '@common/directives/trap-focus.directive';

@Directive({
    selector: '[climbArrowKeyAsTab]',
})
export class ArrowKeyAsTabDirective {
    @HostListener('keydown', ['$event'])
    onKeyDown(event: KeyboardEvent): void {
        const keyCode = event.keyCode;
        const isArrowKey = keyCode === DOWN_ARROW || keyCode === UP_ARROW;
        if (!isArrowKey) {
            return;
        }

        event.preventDefault();

        // If the current focused element is a -1 then we won't find
        // the index in the elements list, got to the start
        if (this.activeElement.tabIndex === -1) {
            this.parent.firstFocusableElement.focus();
            return;
        }

        const currentIndex = this.parent.focusableElementsList.findIndex((element) => element === this.activeElement);

        let nextIndex: number;
        if (keyCode === UP_ARROW) {
            nextIndex = currentIndex === 0 ? this.parent.focusableElementsList.length - 1 : currentIndex - 1;
        } else { // keyCode === DOWN_ARROW
            nextIndex = (currentIndex + 1) % this.parent.focusableElementsList.length; // "%" will loop the index around to 0
        }

        this.parent.focusableElementsList[nextIndex].focus();
    }

    private get activeElement(): HTMLElement {
        return this.document.activeElement as HTMLElement;
    }

    constructor(
        @Inject(DOCUMENT) private document: Document,
        @Optional() @Host() private parent: TrapFocusDirective,
    ) {
        if (!parent) {
            throw Error('TrapFocusDirective provider not found');
        }
    }
}

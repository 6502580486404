export const DEFAULT_VISIBLE_COLUMNS = Object.freeze({
    name: true,
    type: true,
    timePoint: true,
    status: true,
    harvestDate: true,
    expirationDate: true,
    preservation: true,
    container: true,
    source: true,
    location: true,
    subtype: true,
    processing: true,
    sendTo: true,
    analysis: true,
    specialInstructions: true
});
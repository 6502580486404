import {
    CellFormatterService,
    FormatterOptions,
    TableOptions
} from '../common/datatable';
import { TranslationService } from '../services/translation.service';
import { AuditService } from './audit.service';
import { AuditModifiedFieldComponent } from './audit-modified-fields/audit-modified-field.component';
import { AuditClassFactory } from './audit-class-maker';

export class AuditJobTestArticlesTableOptions {

    options: TableOptions;

    constructor(
        private cellFormatterService: CellFormatterService,
        private translationService: TranslationService,
        private isCRO: boolean,
    ) {
        this.options = this.getDefaultOptions();
    }

    getDefaultOptions(): TableOptions {

        const columns = [
            this.getUpdateTypeColumn(),
            this.getModifiedFieldsColumn(),
            this.getModifiedDateColumn(),
            this.getModifiedByColumn(),
            this.getJobNameColumn(),
            this.getTestArticleColumn(),
            this.getBatchColumn()
        ];

        if (this.isCRO) {
            columns.push(this.getVehicleColumn());
        }

        columns.push(this.getCreatedBy(), this.getDateCreated());

        return {
            csvFileName: this.translationService.translate('job').toLowerCase() + '-test-articles-audit.csv',
            enableDetailColumn: false,
            enableDraggable: false,
            enableSelectable: false,
            enableAuditColumns: false,
            columns
        };
    }


    private getUpdateTypeColumn(): any {
        return {
            displayName: 'Type',
            field: 'UpdateType',
            cellClass: AuditClassFactory.createDeletedClass,
        };
    }

    private getModifiedFieldsColumn(): any {
        return {
            displayName: 'Modified Fields',
            field: 'ModifiedFields',
            sortField: 'ModifiedFields',
            rendererComponent: AuditModifiedFieldComponent,
            exportFormatter: this.cellFormatterService.auditModifiedFieldsExportFormatter,
            cellClass: AuditClassFactory.createDeletedClass,
        };
    }

    private getModifiedDateColumn(): any {
        return {
            displayName: 'Date',
            field: 'ModifiedDate',
            formatter: this.cellFormatterService.dateTimeUTCFormatter,
            cellClass: AuditClassFactory.createDeletedClass,
        };
    }

    private getModifiedByColumn(): any {
        return {
            displayName: 'Modified By',
            field: 'ModifiedBy',
            formatter: this.cellFormatterService.userNameFormatter,
            cellClass: AuditClassFactory.createDeletedClass,
        };
    }

    private getJobNameColumn(): any {
        return {
            displayName: this.translationService.translate('Job') + ' Name',
            field: 'JobName',
            formatter: this.cellFormatterService.createAuditColumnFormatter('JobName', (this.translationService.translate('Job') + 'Name')),
            cellClass: AuditClassFactory.createCellClass((this.translationService.translate('Job') + 'Name').toLowerCase()),
        };
    }

    private getTestArticleColumn(): any {
        return {
            displayName: 'Test Article',
            field: 'TestArticle',
            formatter: this.cellFormatterService.createAuditColumnFormatter('TestArticle', 'TestArticle'),
            cellClass: AuditClassFactory.createCellClass('testarticle'),
        };
    }

    private getBatchColumn(): any {
        return {
            displayName: 'Batch',
            field: 'Batch',
            formatter: this.cellFormatterService.createAuditColumnFormatter('Batch', 'Batch'),
            cellClass: AuditClassFactory.createCellClass('batch'),
        };
    }

    private getVehicleColumn(): any {
        return {
            displayName: 'Vehicle',
            field: 'Vehicle',
            formatter: this.cellFormatterService.createAuditColumnFormatter('Vehicle', 'Vehicle'),
            cellClass: AuditClassFactory.createCellClass('vehicle'),
        };
    }

    private getCreatedBy(): any {
        return {
            displayName: 'Created By',
            field: 'CreatedBy',
            formatter: this.cellFormatterService.userNameFormatter,
            cellClass: AuditClassFactory.createDeletedClass,
        };
    }

    private getDateCreated(): any {
        return {
            displayName: 'Created Date',
            field: 'DateCreated',
            formatter: this.cellFormatterService.dateTimeUTCFormatter,
            cellClass: AuditClassFactory.createDeletedClass,
        };
    }
}

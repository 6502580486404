import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostBinding,
    Injector,
    Input,
    OnDestroy,
    OnInit,
    SkipSelf,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { SpinnerSize } from '@common/components/spinner/spinner.component';
import { MixinSurfaceModeClass } from '@common/mixins';

export type ButtonVariant = 'ghost' | 'ghost-negative' | 'secondary' | 'primary' | 'warning' | 'negative';
export type ButtonSize = 'sm' | 'md' | 'lg';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'button[climbButton], a[climbButton]',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent extends MixinSurfaceModeClass implements AfterViewInit, OnInit, OnDestroy {
    /**
     * TODO: After switching to Typescript 4.3, you can remove the current property
     * and improve the setter code by using the `BooleanInput` type instead of `boolean`.
     * @see https://www.typescriptlang.org/docs/handbook/release-notes/typescript-4-3.html
     */
    /* eslint-disable @typescript-eslint/naming-convention,no-underscore-dangle,id-blacklist,id-match */
    static ngAcceptInputType_onlyIcon: BooleanInput;
    /* eslint-enable @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match */

    @Input()
    get variant(): ButtonVariant {
        return this.buttonVariant;
    }
    set variant(buttonVariant: ButtonVariant) {
        this.buttonVariant = buttonVariant;
    }
    private buttonVariant: ButtonVariant = 'secondary';

    @Input()
    get size(): ButtonSize {
        return this.buttonSize;
    }
    set size(buttonSize: ButtonSize) {
        this.buttonSize = buttonSize;
    }
    private buttonSize: ButtonSize = 'md';

    @Input()
    get pending(): boolean {
        return this.isPending;
    }
    set pending(isLoading: boolean) {
        this.isPending = isLoading;
        this.setInlineStyle(isLoading);
    }
    private isPending = false;

    @ViewChild('buttonLabel')
    buttonLabel: ElementRef<HTMLSpanElement>;

    @Input()
    get onlyIcon(): boolean {
        if (this.isOnlyIcon !== undefined) {
            return this.isOnlyIcon;
        }
        return !this.getCaption();
    }
    set onlyIcon(isOnlyIcon: boolean) {
        this.isOnlyIcon = coerceBooleanProperty(isOnlyIcon);
    }
    private isOnlyIcon: boolean;

    @HostBinding('class')
    private get classes(): Record<string, boolean> {
        return {
            ['climb-button']: true,
            [`button-${this.size}`]: true,
            [`button-${this.variant}`]: true,
            ['button-icon-only']: this.onlyIcon,
            ['button-pending']: this.isPending,
        };
    }

    @HostBinding('style')
    private style: Record<string, string> | null = null;

    constructor(
        injector: Injector,
        private elementRef: ElementRef,
        @SkipSelf() private cdr: ChangeDetectorRef,
    ) {
        super(injector);
    }

    ngAfterViewInit(): void {
        // To improve component performance, it is better to explicitly set the onlyIcon flag.
        if (this.isOnlyIcon === undefined) {
            const hasContent = this.getCaption();
            this.onlyIcon = !hasContent;
            this.cdr.detectChanges();
        }
    }

    ngOnInit(): void {
        /**
         * The native listener is used instead of @HostListener('click', ['$event'])
         * to forbid the click events if the button state is "Pending" and the spinner is shown.
         * Set the 'useCapture' flag to **true** to dispatch our listener before being dispatched
         * to any EventTarget beneath it in the DOM tree.
         */
        this.element.addEventListener('click', this.onClick.bind(this), true);
    }

    ngOnDestroy(): void {
        this.element.removeEventListener('click', this.onClick, true);
    }

    /**
     * Gets the HTML element for the button.
     */
    get element(): HTMLButtonElement {
        return this.elementRef.nativeElement;
    }

    setInlineStyle(isSetStyle: boolean): void {
        if (!isSetStyle) {
            this.style = null;
            return;
        }
        const { width, height } = this.element.getBoundingClientRect();
        this.style = {
            width: `${width}px`,
            height: `${height}px`,
        };
    }

    spinnerSize(size: ButtonSize): SpinnerSize {
        return size === 'lg' ? 'md' : 'sm';
    }

    isLightSpinner(variant: ButtonVariant): boolean {
        const needLight: ButtonVariant[] = ['primary', 'negative'];
        return needLight.includes(variant);
    }

    onClick(event: PointerEvent): void {
        if (this.pending) {
            event.preventDefault();
            event.stopPropagation();
            event.stopImmediatePropagation();
        }
    }

    private getCaption(): string {
        return this.buttonLabel?.nativeElement.innerHTML.trim() ?? '';
    }
}

import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse
} from '@angular/common/http';
import { 
    Observable,
    of,
    throwError
 } from 'rxjs';
import { delay, mergeMap, retryWhen } from 'rxjs/operators';

@Injectable()
export class GlobalHttpInterceptor implements HttpInterceptor {

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        let networkRetryCount = 10;

        // retry HTTP connections that encounter network error
        return next.handle(request).pipe(retryWhen((errors) => {
            return errors.pipe(mergeMap((error) => {
                const isRetry = this._isNetworkError(error)
                    && networkRetryCount > 0
                    && !isSingleRequest(request);
                if (isRetry) {
                    networkRetryCount -= 1;
                    return of(error).pipe(delay(500));
                }

                return throwError(error.error ?? error.message);
            }));
        }));
    }

    private _isNetworkError(error: HttpErrorResponse): boolean {
        /* eslint-disable-next-line */
        return error && error.status == 0;
    }
}

function isSingleRequest(request: HttpRequest<any>): boolean {
    return Boolean(request.headers.get('x-single-request'));
}

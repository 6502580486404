import { Pipe, PipeTransform } from '@angular/core';
import { formatDataAutomationId } from '@common/util/format-data-automation-id';

@Pipe({ name: 'dataAutomationId' })
export class DataAutomationIdPipe implements PipeTransform {

    transform(value: string, prefix: string, suffix: string): string {
        if (value) {
            return formatDataAutomationId(value, prefix, suffix);
        }
        return "";
    }
}
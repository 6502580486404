import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

export type SwitchSize = 'sm' | 'md' | 'lg';

@Component({
    selector: 'climb-switch',
    templateUrl: './switch.component.html',
    styleUrls: ['./switch.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SwitchComponent {
    @Input() size: SwitchSize = 'sm';
    @Input() dataAutomationId: string;

    @HostBinding('class')
    private get classes(): Record<string, boolean> {
        return {
            ['climb-switch']: true,
            [`switch-${this.size}`]: true,
        };
    }
}

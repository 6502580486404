import { Injectable } from '@angular/core';
import { defer, Observable } from 'rxjs';

import { VocabularyService } from '../vocabularies/vocabulary.service';
import { take } from 'rxjs/operators';

@Injectable()
export class BirthVocabService {

    // vocab Observables
    public get animalStatuses$(): Observable<any[]>{
        return defer(() => this.vocabularyService.getCV('cv_AnimalStatuses')).pipe(take(1));
    }
    public get animalUses$(): Observable<any[]>{
        return defer(() => this.vocabularyService.getCV('cv_AnimalUses')).pipe(take(1));
    }
    public get birthStatuses$(): Observable<any[]>{
        return defer(() => this.vocabularyService.getCV('cv_BirthStatuses')).pipe(take(1));
    }
    public get generations$(): Observable<any[]>{
        return defer(() => this.vocabularyService.getCV('cv_Generations')).pipe(take(1));
    }
    public get materialPoolTypes$(): Observable<any[]>{
        return defer(() => this.vocabularyService.getCV('cv_MaterialPoolTypes')).pipe(take(1));
    }
    public get matingTypes$(): Observable<any[]>{
        return defer(() => this.vocabularyService.getCV('cv_MatingTypes')).pipe(take(1));
    }
    public get sexes$(): Observable<any[]>{
        return defer(() => this.vocabularyService.getCV('cv_Sexes')).pipe(take(1));
    }
    public get iacucProtocols$(): Observable<any[]>{
        return defer(() => this.vocabularyService.getCV('cv_IACUCProtocols')).pipe(take(1));
    }
    public get physicalMarkerTypes$(): Observable<any[]>{
        return defer(() => this.vocabularyService.getCV('cv_PhysicalMarkerTypes')).pipe(take(1));
    }
    public get taxons$(): Observable<any[]>{
        return defer(() => this.vocabularyService.getCV('cv_Taxons')).pipe(take(1));
    }
    public get breedingStatuses$(): Observable<any[]>{
        return defer(() => this.vocabularyService.getCV('cv_BreedingStatuses')).pipe(take(1));
    }
    public get materialOrigins$(): Observable<any[]>{
        return defer(() => this.vocabularyService.getCV('cv_MaterialOrigins')).pipe(take(1));
    }
    public get diets$(): Observable<any[]>{
        return defer(() => this.vocabularyService.getCV('cv_Diets')).pipe(take(1));
    }


    constructor(
        private vocabularyService: VocabularyService
    ) {}
}

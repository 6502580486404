<div class="sample-label-container">

    <p class="text-center">
        <button type="button"
                class="btn btn-secondary"
                (click)="printLabel()">
            <i class="fa fa-print" aria-hidden="true"></i>
            Print
        </button>
    </p>

    <div class="sample-label"
         id="{{containerId}}"
         [style.display]="showPreview? 'inherit' : 'none'">
        <table style="width:2.125in; font-size:7px;">
            <tr>
                <td colspan="2" style="text-align: center;">
                    <climb-barcode [value]="sample.Material?.Identifier"
                                   [options]="barcodeOptions"></climb-barcode>
                </td>
            </tr>
            <tr>
                <td style="width:50%">
                    <b>Sample ID</b>&nbsp;{{sample.Material?.Identifier}}
                </td>
                <td style="width:50%">
                    <b>Sample Name</b>&nbsp;{{sample.SampleName}}
                </td>
            </tr>
            <tr>
                <td style="width:50%">
                    <b>Exp Date</b>&nbsp;{{sample.DateExpiration | formatShortDate}}
                </td>
                <td style="width:50%">
                    <b>Source</b>&nbsp;<span *ngFor="let source of sample.Material.MaterialSourceMaterial; let last = last">{{source.SourceMaterial.Animal?.AnimalName}}{{source.SourceMaterial.Sample?.SampleName}}<span *ngIf="!last">, </span></span>
                </td>
            </tr>
            <tr>
                <td colspan="2">
                    <b>{{'Job' | translate}}</b>&nbsp;<span *ngFor="let jobMaterial of sample.Material.JobMaterial; let last = last">{{jobMaterial.Job?.JobID}}<span *ngIf="!last">, </span></span>
                </td>
            </tr>
            <tr>
                <td style="width:50%">
                    <span *ngIf="sample.SampleCharacteristicInstance?.length >= 1"><b>{{sample.SampleCharacteristicInstance[0].CharacteristicName}}</b>&nbsp;{{sample.SampleCharacteristicInstance[0].CharacteristicValue}}</span>
                </td>
                <td style="width:50%">
                    <span *ngIf="sample.SampleCharacteristicInstance?.length >= 2"><b>{{sample.SampleCharacteristicInstance[1].CharacteristicName}}</b>&nbsp;{{sample.SampleCharacteristicInstance[1].CharacteristicValue}}</span>
                </td>
            </tr>
            <tr>
                <td style="width:50%">
                    <span *ngIf="sample.SampleCharacteristicInstance?.length >= 3"><b>{{sample.SampleCharacteristicInstance[2].CharacteristicName}}</b>&nbsp;{{sample.SampleCharacteristicInstance[2].CharacteristicValue}}</span>
                </td>
                <td style="width:50%">
                    <span *ngIf="sample.SampleCharacteristicInstance?.length >= 4"><b>{{sample.SampleCharacteristicInstance[3].CharacteristicName}}</b>&nbsp;{{sample.SampleCharacteristicInstance[3].CharacteristicValue}}</span>
                </td>
            </tr>
        </table>
    </div> <!-- /.sample-label -->

</div>

import { Pipe, PipeTransform } from '@angular/core';
import { notEmpty } from '../util/not-empty';

/*
* Returns whether item is falsey or empty array
*
* NOTE: unit tested via util/notEmpty function
*/
@Pipe({
    name: 'notEmpty',
    pure: false
})
export class NotEmptyPipe implements PipeTransform {

    transform(value: any): boolean {
        return notEmpty(value);
    }
}

import {
    Component
} from "@angular/core";
import { ICellRendererParams } from "ag-grid-community";
import { ICellRendererAngularComp } from "ag-grid-angular";

@Component({
    selector: 'mating-litter-size-cell',
    template: `
    <span *ngIf="params">
        <mating-litter-size-sparkline [mating]="params.data" 
          [width]="100" 
          [height]="19"></mating-litter-size-sparkline>
    </span>
    `
})
export class MatingLitterSizeCellRendererComponent implements ICellRendererAngularComp {
    public params: ICellRendererParams;

    agInit(params: ICellRendererParams): void {
        this.params = params;
    }

    /**
     * Required by ICellRenderedAngularComp.
     *   Called on cell refresh by ag-grid
     * @param params
     */
    refresh(params: any): boolean {
        this.params = params;
        return true;
    }
}

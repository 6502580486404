import { TableOptions } from '../datatable/data-table.interface';
import { BulkAddResult } from './models';
import {
    Directive,
    OnDestroy,
    OnInit
} from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { WorkspaceFilterService } from '../../services/workspace-filter.service';
import { WsFilterEvent } from '../../services/ws-filter-event';
import { 
    randomId, 
    isSearchEmpty
} from '../util';
import { EntityState } from 'breeze-client';
import { DataTableCommService } from '../datatable/services/data-table-comm.service';
import {
    TableState,
    DataService
} from '../datatable/data-table.interface';
import { BaseDetailService } from './base-detail.service';
import { BaseFacetService } from './base-facet.service';
import { FacetLoadingStateService } from './facet-loading-state.service';
import { PrivilegeService } from '../../services/privilege.service';
import { PageState } from './page-state';
import { DetailPaginator } from './detail-paginator';
import { AppInsightsService } from '../../services/app-insights.service';
import { LoggingService } from '../../services/logging.service';
import { BulkAddExitReason } from './models/bulk-add-exit-reason.enum';
import { Provider } from '@angular/compiler/src/core';
import { takeUntil } from 'rxjs/operators';
import { FacetView } from './facet-view.enum';

export interface IFacet {
    FacetName: string;
    Privilege: string;
    GridState: string;
    GridFilter: string;
    BulkEditConfiguration: string;
    BulkDataConfiguration: string;
    TaskGridConfiguration: string;
    Row: number;
    Column: number;
    SizeX: number;
    SizeY: number;
}

/**
 * Base class for Angular 2 Facets
 * 
 * Contains logic for
 *   pagination
 *   workspace filtering
 */
@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class BaseFacet<T = any> implements OnDestroy, OnInit {
    /**
     * Base providers that should be shared
     *   per facet among all its child components
     */
    static readonly BASE_COMPONENT_PROVIDERS: Provider[] = [
        BaseDetailService,
        BaseFacetService,
        DataTableCommService,
        FacetLoadingStateService,
        PrivilegeService
    ];

    // Basic labels for facetView states
    // Implementing classes can add extras if needed
    readonly LIST_VIEW: FacetView = FacetView.LIST_VIEW;
    readonly DETAIL_VIEW: FacetView = FacetView.DETAIL_VIEW;
    readonly BULK_EDIT_VIEW: FacetView = FacetView.BULK_EDIT_VIEW;
    readonly BULK_ADD_VIEW: FacetView = FacetView.BULK_ADD_VIEW;
    readonly BULK_ADD_AND_EDIT_VIEW: FacetView = FacetView.BULK_ADD_AND_EDIT_VIEW;
    readonly facetId = 'facet-' + randomId();

    dataService: DataService;
    // array of items in current page of data-table
    data: T[];
    // total count of items in data-table
    totalCount: number;
    // current item edited in detail
    itemToEdit: T = {} as T;
    // current rows selected from data-table
    draggedRows: any[] = [];
    selectedRows: T[] = [];
    itemsToEdit: T[] = [];
    tableState: TableState;

    facet: IFacet;

    appInsightsService: AppInsightsService;
    facetLoadingState: FacetLoadingStateService;
    detailPaginator: DetailPaginator;
    dataTableComm: DataTableCommService;
    loggingService: LoggingService;
    privilegeService: PrivilegeService;
    workspaceFilterService: WorkspaceFilterService;

    // state variables
    // Current view state
    facetView: FacetView;
    supportedWorkspaceFilters: string[] = [];
    ignoreWorkspaceFilter = false;
    workspaceFilterActive = false;
    // filter object for list view queries 
    filter: any = {};
    // Whether to display large loading spinner
    loading = false;
    // optional loading message
    loadingMessage: string = null;
    componentDestroyed$: Subject<boolean> = new Subject();

    constructor(
        baseFacetService: BaseFacetService,
        workspaceFilterService?: WorkspaceFilterService
    ) {
        this.appInsightsService = baseFacetService.appInsightsService;
        this.facetLoadingState = baseFacetService.facetLoadingStateService;
        this.loggingService = baseFacetService.loggingService;
        this.privilegeService = baseFacetService.privilegeService;
        this.dataTableComm = baseFacetService.dataTableCommService;
        this.workspaceFilterService = workspaceFilterService;

        if (this.workspaceFilterService) {
            this.subscribeWorkspaceFilterChanges();
        }

        this.createPaginator();
    }

    ngOnInit() {
        if (this.facet) {
            this.privilegeService.setPrivilege(this.facet.Privilege);
        }
    }

    ngOnDestroy() {
        this.clearWorkspaceFilter();
        this.componentDestroyed$.next(true);
        this.componentDestroyed$.complete();
    }

    createPaginator() {
        this.detailPaginator = new DetailPaginator();
        if (this.dataService) {
            // eslint-disable-next-line
            const self = this;
            this.detailPaginator.listRefreshFunction = (pageNumber: number) => {
                return self.loadTableAtPageNumber(pageNumber);
            };
        }
    }

    loadTableAtPageNumber(pageNumber: number): Promise<any> {
        let onLoadCompleted: Subscription;
        return new Promise<void>((resolve, reject) => {
            // Trigger new page on data table,
            // wait until load is complete,
            // then resolve and cancel subscription
            onLoadCompleted = this.dataTableComm.onDataLoadCompleted$
            .subscribe(() => {
                resolve();
                onLoadCompleted.unsubscribe();
            });
            this.dataTableComm.loadSpecificPage(pageNumber);
        }).catch((error) => {
            if (onLoadCompleted) {
                onLoadCompleted.unsubscribe();
            }
            throw error;
        });
    }

    reloadTable() {
        this.dataTableComm.reloadTable();
    }

    /**
     * Change facetView to a new view state
     * @param viewLabel
     */
    changeView(viewLabel: FacetView) {
        this.facetView = viewLabel;
        if (viewLabel === this.DETAIL_VIEW) {
            this.updatePageState();
        }
        this.onViewChange(viewLabel);
    }

    setLoadingState(loadingMessage?: string) {
        this.loading = true;
        this.loadingMessage = loadingMessage;
    }

    stopLoading() {
        this.loading = false;
    }

    subscribeWorkspaceFilterChanges() {
        this.workspaceFilterService.filterChange$.pipe(
            takeUntil(this.componentDestroyed$)
        ).subscribe((wsFilterEvent) => {
            this.workspaceFilterChange(wsFilterEvent);
        });
    }

    /**
     * Does this facet support the current workspace filter?
     */
    isActiveWorkspaceFilterSupported(): boolean {
        const wsFilterKind = this.workspaceFilterService.getFilterKind();
        return this.workspaceFilterSupported(wsFilterKind);
    }

    /**
     * If any filters are affecting this facet
     */
    isFiltered(): boolean {
        return  !isSearchEmpty(this.filter) || 
            this.isFilteredByWorkspace();
           
    }

    /**
     * Is this facet being actively filtered by the workspace?
     */
    isFilteredByWorkspace(): boolean {
        return this.isActiveWorkspaceFilterSupported() && !this.ignoreWorkspaceFilter;
    }

    /**
     * Determines if the current workspace filter is supported by this facet.
     */
    workspaceFilterSupported(wsFilterKind: string): boolean {
        return this.supportedWorkspaceFilters.indexOf(wsFilterKind) !== -1;
    }

    clearWorkspaceFilter() {
        if (this.workspaceFilterActive) {
            this.workspaceFilterService.clearWorkspaceFilter();
        }
    }

    /**
     * respond to workspace filter notifications
     */
    workspaceFilterChange(wsFilterEvent: WsFilterEvent) {
        // if either the old or new filter types are supported we need to
        // refresh the table (unless we're ignoring workspace filters)
        this.workspaceFilterActive = wsFilterEvent.filterSourceId === this.facetId;
        const oldFilterSupported = this.workspaceFilterSupported(wsFilterEvent.oldFilterKind);
        const newFilterSupported = this.workspaceFilterSupported(wsFilterEvent.filterKind);
        if (!this.ignoreWorkspaceFilter && (oldFilterSupported || newFilterSupported)) {
            this.onWorkspaceFilterChange(wsFilterEvent);
        }
    }

    /**
     * If the workspace filter is active get that, otherwise return the local filter.
     */
    getActiveFilter(): any {
        // if the workspace filter is active use it
        const wsFilterKind = this.workspaceFilterService.getFilterKind();
        const wsFilterIds = this.workspaceFilterService.getFilterIds();
        if (this.isFilteredByWorkspace()) {
            const wsFilter = {};
            if (wsFilterIds.length) {
                wsFilter[wsFilterKind] = wsFilterIds;
            }
            return wsFilter;
        }

        // otherwise fall back to using the local filter
        return this.filter;
    }

    /**
     * respond to user click on "ignore workspace filter" button
     */
    clickIgnoreWorkspaceFilter() {
        this.toggleIgnoreWorkspaceFilter();
    }

    toggleIgnoreWorkspaceFilter() {
        this.ignoreWorkspaceFilter = !this.ignoreWorkspaceFilter;
    }

    /**
     * respond to "OK" clicked on filter
     */
    runFilter() {
        if (this.isActiveWorkspaceFilterSupported()) {
            this.ignoreWorkspaceFilter = true;
        }
        this.reloadTable();
        this.facet.GridFilter = JSON.stringify(this.filter);
    }

    /**
     * Replace sort column in sortString
     * @param sortString
     * @param valueToReplace
     * @param replacement
     */
    replaceSort(sortString: string, valueToReplace: string, replacement: string): string {
        if (!sortString) {
            return sortString;
        }
        return sortString.replace(valueToReplace + ' ', replacement + ' ');
    }


    /**
     * Optional hook to be implemented by child class.
     *   Called after this.changeView()
     */
    onViewChange(viewLabel: string) {
        // Not implemented
    }

    /**
     * Optional hook to be implemented by child class.
     * @param wsFilterChange
     */
    onWorkspaceFilterChange(wsFilterChange: WsFilterEvent) {
        // Not implemented
    }

    anyItemsAddedOrDeleted(items: any) {
        let itemsAdded = false;
        let itemsDeleted = false;

        for (const item of items) {
            if (this.data.indexOf(item) < 0) {
                itemsAdded = true;
                break;
            }
        }

        for (const item of this.data) {
            if (this.wasEntityDeleted(item)) {
                itemsDeleted = true;
                break;
            }
        }

        return itemsAdded || itemsDeleted;
    }

    wasEntityDeleted(entity: any): boolean {
        if (entity && entity.entityAspect) {
            return entity.entityAspect.entityState === EntityState.Detached;
        }
        return false;
    }
    
    /**
     * Must be called on every list view update
     * @param pageState
     */
    updatePageState() {
        if (!this.tableState) {
            return;
        }

        // set paginator pageState
        const pageState = new PageState({
            activeItem: this.itemToEdit,
            dataItems: this.data,
            listPage: this.tableState.pageNumber,
            pageSize: this.tableState.pageSize,
            totalCount: this.totalCount
        });
        this.detailPaginator.setPageStage(pageState);
    }

    /**
     * Optional hook to be implemented by child class.
     */
    deleteItemClick(itemClicked: any) {
        // Not implemented
    }

    detailLinkClick(itemClicked: any) {
        this.itemToEdit = itemClicked;
        this.onDetailLinkClick(itemClicked).then((view: FacetView) => {
            this.changeView(view ? view : this.DETAIL_VIEW);
        });
    }

    /**
     * Optional hook to be implemented by child class
     * Called before view is changed
     * @param itemClicked 
     */
    onDetailLinkClick(itemClicked: any): Promise<any> {
        return Promise.resolve();
    }

    detailNextClicked() {
        this.getNextDetail().then((detail: any) => {
            this.detailLinkClick(detail);
        });
    }
    
    getNextDetail(): Promise<any> {
        return this.detailPaginator.getNext();
    }

    detailPreviousClicked() {
        this.getPreviousDetail().then((detail: any) => {
            this.detailLinkClick(detail);
        });
    }

    getPreviousDetail(): Promise<any> {
        return this.detailPaginator.getPrevious();
    }

    exitDetail() {
        // check if new item was added before refreshing list
        const itemNotDeleted = this.itemToEdit && !this.wasEntityDeleted(this.itemToEdit);
        const itemWasAdded = this.data.indexOf(this.itemToEdit) < 0;
        if (itemNotDeleted && itemWasAdded) {
            this.selectedRows = [];
            this.reloadTable();
        }
        this.changeView(this.LIST_VIEW);
    }

    exitBulkEdit(editedItems: any[], alwaysReload = false) {
        if (alwaysReload ||
            this.anyItemsAddedOrDeleted(editedItems)
        ) {
            this.selectedRows = [];
            this.reloadTable();
        }
        this.changeView(this.LIST_VIEW);
    }

    sendSelectedToBulkEdit() {
        this.itemsToEdit = this.selectedRows;
        this.changeView(this.BULK_EDIT_VIEW);
    }

    handleBulkAddExit(result: BulkAddResult) {
        switch (result.reason) {
            case BulkAddExitReason.Cancel:
            case BulkAddExitReason.Save:
                this.changeView(this.LIST_VIEW);
                this.reloadTable();
                break;
            case BulkAddExitReason.Edit:
                this.itemsToEdit = result.newItems;
                this.changeView(this.BULK_EDIT_VIEW);
                break;
            case BulkAddExitReason.AddAndEdit:
                this.itemsToEdit = result.newItems;
                this.changeView(this.BULK_ADD_AND_EDIT_VIEW);
                break;
        }
    }

    getVisibleColumns(options: TableOptions): string[] {
        const visibleFields = this.getFacetVisibleColumns();
        if (visibleFields.length) {
            return visibleFields;
        }

        return this.getTableVisibleColumns(options);
    }

    getTableVisibleColumns({ columns }: TableOptions): string[] {
        return columns
            .filter(({ visible }) => visible)
            .map(({ field }) => field);
    }

    getFacetVisibleColumns(): string[] {
        if (!this.facet?.GridState) {
            return [];
        }
        try {
            const options = JSON.parse(this.facet.GridState) as TableOptions;
            return this.getTableVisibleColumns(options);
        } catch (e) {
            console.error(e);
            return [];
        }
    }
}

import { Component } from "@angular/core";
import { trashcan } from "@common/icons";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: 'multiple-session-modal',
    styles: [`
        .discard {
            display: flex;
            align-items: center;
            font-size: 16px;
        }
    `],
    template: `
        <div class="modal-header justify-content-center">
            <h4 class="modal-title">Multiple Active Sessions</h4>
        </div>

        <div class="modal-body" style="vertical-align:top;">
            <p>
                You already have an active Climb session in another tab. 
                Creating a new active session will <b>discard your unsaved changes</b> and end your previous session.
                Do you wish to continue? 
            </p>
            <br/>
            <p>
                You can <b>close this tab to preserve unsaved changes</b> in your previous session.
            </p>
        </div>
        <div class="modal-footer">
            <button climbButton
                    variant="warning"
                    class="discard"
                    (click)="continueClick()">
                <svg [climbIcon]="icons.trashcan"></svg>
                Discard and Continue
            </button>
        </div>
  `,
})
export class MultipleSessionModalComponent {

    icons = { trashcan };

    constructor(
        private activeModal: NgbActiveModal,
    ) {}

    continueClick() {
        this.activeModal.close();
    }
}

import {
    CellFormatterService,
    FormatterOptions,
    TableOptions
} from '../common/datatable';
import { TranslationService } from '../services/translation.service';
import { AuditModifiedFieldComponent } from './audit-modified-fields/audit-modified-field.component';
import { AuditClassFactory } from './audit-class-maker';

export class AuditJobGroupsTableOptions {

    options: TableOptions;

    constructor(
        private cellFormatterService: CellFormatterService,
        private translationService: TranslationService,
        private isCRL: boolean,
    ) {
        this.options = this.getDefaultOptions();
    }

    getDefaultOptions(): TableOptions {
        let columns = [
            this.getUpdateType(),
            this.getModifiedFields(),
            this.getModifiedDate(),
            this.getModifiedBy(),
            this.getJobNameColumn(),
            this.getGroup(),
            this.getNumber(),
            this.getTreatment(),
            this.getFormulationDose(),
            this.getActiveDose(),
            this.getActiveUnit(),
            this.getConcentration(),
            this.getRoute(),
            this.getDosingVolume(),
            this.getDosingUnit(),
            this.getSchedule(),
            this.getProtocol(),
        ];

        if (this.isCRL) {
            columns = [
                this.getUpdateType(),
                this.getModifiedFields(),
                this.getModifiedDate(),
                this.getModifiedBy(),
                this.getJobNameColumn(),
                this.getGroup(),
                this.getNumber(),
                this.getTreatment(),
                this.getFormulationDose(),
                this.getActiveDose(),
                this.getActiveUnit(),
                this.getRoute(),
                this.getDosingVolume(),
                this.getDosingUnit(),
                this.getSchedule(),
                this.getProtocol(),
            ];
        }

        columns.push(this.getCreatedBy(), this.getDateCreated());

        return {
            csvFileName: this.translationService.translate('job').toLowerCase() + '-dosing-table-audit.csv',
            enableDetailColumn: false,
            enableDraggable: false,
            enableSelectable: false,
            enableAuditColumns: false,
            columns
        };
    }

    private getUpdateType(): any {
        return {
            displayName: 'Type',
            field: 'UpdateType',
            cellClass: AuditClassFactory.createDeletedClass,
        };
    }

    private getModifiedFields(): any {
        return {
            displayName: 'Modified Fields',
            field: 'ModifiedFields',
            sortField: 'ModifiedFields',
            rendererComponent: AuditModifiedFieldComponent,
            exportFormatter: this.cellFormatterService.auditModifiedFieldsExportFormatter,
            cellClass: AuditClassFactory.createDeletedClass,
        };
    }

    private getModifiedDate(): any {
        return {
            displayName: 'Date',
            field: 'ModifiedDate',
            formatter: this.cellFormatterService.dateTimeUTCFormatter,
            cellClass: AuditClassFactory.createDeletedClass,
        };
    }

    private getModifiedBy(): any {
        return {
            displayName: 'Modified By',
            field: 'ModifiedBy',
            formatter: this.cellFormatterService.userNameFormatter,
            cellClass: AuditClassFactory.createDeletedClass,
        };
    }

    private getJobNameColumn(): any {
        return {
            displayName: this.translationService.translate('Job') + ' Name',
            field: 'JobName',
            formatter: this.cellFormatterService.createAuditColumnFormatter('JobName', (this.translationService.translate('Job') + 'Name')),
            cellClass: AuditClassFactory.createCellClass((this.translationService.translate('Job') + 'Name').toLowerCase()),
        };
    }

    private getGroup(): any {
        return {
            displayName: 'Group',
            field: 'Group',
            formatter: this.cellFormatterService.createAuditColumnFormatter('Group', 'Group'),
            cellClass: AuditClassFactory.createCellClass('group'),
        };
    }

    private getNumber(): any {
        return {
            displayName: 'Number',
            field: 'Number',
            formatter: this.cellFormatterService.createAuditColumnFormatter('Number', 'Number'),
            cellClass: AuditClassFactory.createCellClass('number'),
        };
    }

    private getTreatment(): any {
        return {
            displayName: 'Treatment',
            field: 'Treatment',
            formatter: this.cellFormatterService.createAuditColumnFormatter('Treatment', 'Treatment'),
            cellClass: AuditClassFactory.createCellClass('treatment'),
        };
    }

    private getFormulationDose(): any {
        return {
            displayName: 'Formulation Dose',
            field: 'FormulationDose',
            formatter: this.cellFormatterService.createAuditColumnFormatter('FormulationDose', 'FormulationDose'),
            cellClass: AuditClassFactory.createCellClass('formulationdose'),
        };
    }

    private getActiveDose(): any {
        return {
            displayName: 'Active Dose',
            field: 'ActiveDose',
            formatter: this.cellFormatterService.createAuditColumnFormatter('ActiveDose', 'ActiveDose'),
            cellClass: AuditClassFactory.createCellClass('activedose'),
        };
    }

    private getActiveUnit(): any {
        return {
            displayName: 'Active Unit',
            field: 'ActiveUnit',
            formatter: this.cellFormatterService.createAuditColumnFormatter('ActiveUnit', 'ActiveUnit'),
            cellClass: AuditClassFactory.createCellClass('activeunit'),
        };
    }

    private getConcentration(): any {
        return {
            displayName: 'Concentration',
            field: 'Concentration',
            formatter: this.cellFormatterService.createAuditColumnFormatter('Concentration', 'Concentration'),
            cellClass: AuditClassFactory.createCellClass('concentration'),
        };
    }

    private getRoute(): any {
        return {
            displayName: 'Route',
            field: 'Route',
            formatter: this.cellFormatterService.createAuditColumnFormatter('Route', 'Route'),
            cellClass: AuditClassFactory.createCellClass('route'),
        };
    }

    private getDosingVolume(): any {
        return {
            displayName: 'Dosing Volume',
            field: 'DosingVolume',
            formatter: this.cellFormatterService.createAuditColumnFormatter('DosingVolume', 'DosingVolume'),
            cellClass: AuditClassFactory.createCellClass('dosingvolume'),
        };
    }

    private getDosingUnit(): any {
        return {
            displayName: 'Dosing Unit',
            field: 'DosingUnit',
            formatter: this.cellFormatterService.createAuditColumnFormatter('DosingUnit', 'DosingUnit'),
            cellClass: AuditClassFactory.createCellClass('dosingunit'),
        };
    }

    private getSchedule(): any {
        return {
            displayName: 'Schedule',
            field: 'Schedule',
            formatter: this.cellFormatterService.createAuditColumnFormatter('Schedule', 'Schedule'),
            cellClass: AuditClassFactory.createCellClass('schedule'),
        };
    }

    private getProtocol(): any {
        return {
            displayName: 'Protocol',
            field: 'ProtocolAlias',
            formatter: this.cellFormatterService.createAuditColumnFormatter('ProtocolAlias', 'ProtocolAlias'),
            cellClass: AuditClassFactory.createCellClass('protocolalias'),
        };
    }

    private getCreatedBy(): any {
        return {
            displayName: 'Created By',
            field: 'CreatedBy',
            formatter: this.cellFormatterService.userNameFormatter,
            cellClass: AuditClassFactory.createDeletedClass,
        };
    }

    private getDateCreated(): any {
        return {
            displayName: 'Created Date',
            field: 'DateCreated',
            formatter: this.cellFormatterService.dateTimeUTCFormatter,
            cellClass: AuditClassFactory.createDeletedClass,
        };
    }
}

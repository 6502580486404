import {
    Component,
    ElementRef,
    Input,
    OnInit,
    ViewChild,
    forwardRef,
} from '@angular/core';
import {
    NgbInputDatepicker,
    NgbDateParserFormatter,
    NgbDateStruct
} from '@ng-bootstrap/ng-bootstrap';
import { DateTime } from 'luxon';

import { CustomDateParserFormatter } from '../custom-date-parser-formatter';
import { randomId, focusElementByQuery } from '@common/util';
import { DateFormatterService } from '@common/util/date-time-formatting';
import { ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, NgModel, ValidationErrors, Validator } from '@angular/forms';

// Is used to generate unique name for the date field if it's not passed
let uniqueCount = 1;

@Component({
    selector: 'climb-ngb-date',
    templateUrl: './climb-ngb-date.component.html',
    styleUrls: ['./climb-ngb-date.component.scss'],
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => ClimbNgbDateComponent),
            multi: true,
        },
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ClimbNgbDateComponent),
            multi: true
        },
        {
            // Set a new format for date display text in <input>
            provide: NgbDateParserFormatter,
            useClass: CustomDateParserFormatter,
        }
    ]
})
export class ClimbNgbDateComponent implements OnInit, ControlValueAccessor, Validator {
    @ViewChild('datePicker') datePicker: NgbInputDatepicker;
    @ViewChild('datePicketInput') datePicketInput: ElementRef<HTMLInputElement>;
    @Input() class: string;
    @Input() required = false;
    @Input() disabled: boolean;
    @Input()
    get name() {
        return this.fieldName;
    }
    set name(name: string) {
        this.fieldName = `${name.replace(/ /g, '_')}_${uniqueCount++}`;
        this.displayName = name;
    }
    @Input() id?: string;
    @Input() placeholder?: string = '';

    // enables the climb-timepicker
    @Input() allowDate = true;
    @Input() allowTime = false;
    @Input() alwaysShowTime = false;
    @Input() allowNow: boolean;
    @Input() container = 'body';
    @Input() defaultValue: Date;

    private displayName = 'Date';
    private fieldName = `date_${uniqueCount++}`;

    _modelStruct: NgbDateStruct | string | null;
    _timeComponent: string;
    // toggle visiblity of the timepicker input box
    showTimePickerInput = false;
    timepickerId: string;
    isShowTimePickerDropdown = false;

    luxonTimeFormat: string;
    prevTime: string;
    luxonInputDateTimeFormat: string;
    ngbDatePicker: HTMLElement;

    // TODO: check where I should put that fields
    value: Date | null;
    onChange = (value: Date | null) => {/*such empty*/};
    onTouched = () => {/*such empty*/};

    // Configuration - https://ng-bootstrap.github.io/#/components/datepicker/api
    // Start weeks on Sunday
    readonly FIRST_DAY_OF_WEEK: number = 7;

    constructor(private dateFormatterService: DateFormatterService) { }

    ngOnInit() {
        this.showTimePickerInput = false;
        this.timepickerId = randomId() + '_tp';
        
        this.luxonTimeFormat = this.dateFormatterService.resolveTimeFormat();
        this.luxonInputDateTimeFormat = this.allowTime ? this.dateFormatterService.resolveDateTimeFormat(false, false) : this.dateFormatterService.resolveDateFormat();

        this.initIncomingModel();

        if (this._timeComponent !== null && this.allowTime) {
            this.showTimePickerInput = true;
        }
    }

    /**
     * map incoming model date and time components to internal structures
     */
    initIncomingModel() {
        // convert Date input to NgdbDatepicker modelStruct
        this._modelStruct = this.dateToModelStruct(this.value);
        this._timeComponent = this.dateToTimeString(this.value);
    }

    dateToModelStruct(inputDate: Date): NgbDateStruct {
        if (inputDate && this.isDate(inputDate)) {
            return { day: inputDate.getDate(), month: inputDate.getMonth() + 1, year: inputDate.getFullYear() };
        }
        return null;
    }

    modelStructToDate(date: NgbDateStruct | string | null): Date | string | null {
        if (typeof date === 'string') {
            return null;
        }
        if (date && typeof date.year === 'number' && typeof date.month === 'number' && typeof date.day === 'number') {
            return new Date(
                date.year,
                date.month - 1,
                date.day
            );
        }
        return null;
    }

    onDatePickerOpen() {
        this.datePicker.toggle();

        const datePickerBulkEdit = document.querySelector('bulk-edit-header .show');
        if (datePickerBulkEdit) {
            this.initNgbDateListeners();
        }
    }

    handleClickOutsideCalendar = () => {
        // set opacity: 0 to avoid calendar's blink after closing it
        if (this.ngbDatePicker) {
            this.ngbDatePicker.style.opacity = '0';
            this.ngbDatePicker.onclick = null;
            document.body.removeEventListener('click', this.handleClickOutsideCalendar);
        }
    }

    initNgbDateListeners() {
        this.ngbDatePicker = document.querySelector('ngb-datepicker');
        if (this.ngbDatePicker) {
            this.ngbDatePicker.onclick = (event) => event.stopPropagation();
            document.body.addEventListener('click', this.handleClickOutsideCalendar);
            // set opacity: 1 to rewrite 0 and make sure it is done after handleClickOutsideCalendar call
            setTimeout(() => {
                this.ngbDatePicker.style.opacity = '1';
            });
        }
    }

    selectNow() {
        const value = new Date();
        if (!this.showTimePickerInput) {
            this.setMidnightTime(value);
        }
        this.value = value;
        this.onChange(value);
        this.initIncomingModel();
    }

    // TODO: delete that logic
    revertDateChange(prevDate: Date) {
        this.value = prevDate;
        this.onChange(prevDate);
    }

    /**
     * Calculate the new datetime and update the model if it has changed.
     */
    updateModel() {
        // Calculate the new date

        const newDate = this.modelStructToDate(this._modelStruct);
        if (newDate ===  null) {
            this.value = null;
            this.onChange(null);
            return;
        }
        const newModel = this.convertTimeComponentToDate(newDate, this._timeComponent, this.luxonInputDateTimeFormat);

        // Get the time values for easier comparison
        const oldTime = this.value && this.isDate(this.value) ? this.value.getTime() : 0;
        const newTime = newModel && this.isDate(newModel) ? newModel.getTime() : 0;

        if (newTime !== oldTime) {
            // The times are different, so actually update the model
            this.value = newModel;
            this.onChange(newModel);
        }
    }

    toggleTimeChooser() {
        this.showTimePickerInput = !this.showTimePickerInput;

        // focus on time picker input
        if (this.showTimePickerInput) {
            focusElementByQuery('#' + this.timepickerId + ' input', 700);
            this._timeComponent = this.prevTime;
            this.luxonInputDateTimeFormat = this.dateFormatterService.resolveDateTimeFormat(false, false);
        } else {
            this.prevTime = this._timeComponent;
            this._timeComponent = null;
            this.luxonInputDateTimeFormat = this.dateFormatterService.resolveDateFormat();
        }
        this.updateModel();
    }

    onToggleTimePicker(isShow: boolean) {
        this.isShowTimePickerDropdown = isShow;
    }
    
    onTimeChange(event: string) {
        // Update the model, maybe.
        this._timeComponent = event;
        this.updateModel();
    }

    /**
     * adds timeComponent to date
     * returns new Date object
     * @param date
     * @param timeComponent
     */
    convertTimeComponentToDate(date: Date | string | null, timeComponent: string | null, format: string): Date | null {
        if (!timeComponent) {
            return date === null ? null : new Date(date);
        }

        // start by clearing out time component of passed in date
        date = new Date(date ?? undefined);
        this.setMidnightTime(date);

        let dateParsed: DateTime = DateTime.fromJSDate(date);
        let newDateTime: DateTime;
        let formatter = format;

        if (timeComponent) {
            try {
                const getTimeInput = (time: string) => time.substring(0, 5);
                const shortTime = timeComponent.match(/\d:\d/);
                const fullTime = timeComponent.match(/\d\d:\d\d/);
                const timeInput = fullTime ? getTimeInput(fullTime.input) : getTimeInput(shortTime.input);
                const time = timeInput.split(':').map(timeUnit => +timeUnit);
                let hours = time[0];
                const minutes = time[1];
                if (timeComponent.includes('PM') && hours !== 12) {
                    hours += 12;
                } else if (timeComponent.includes('AM') && hours === 12) {
                    hours = 0;
                }

                dateParsed = dateParsed.plus({ hour: hours, minute: minutes });
                // do not miss format for time if only date has changed
                formatter = this.dateFormatterService.resolveDateTimeFormat(false, false);
            } catch {/*do nothing*/}
        }

        newDateTime = DateTime.fromObject({
            year: dateParsed.year || 0,
            month: dateParsed.month || 0,
            day: dateParsed.day || 0,
            hour: dateParsed.hour || 0,
            minute: dateParsed.minute || 0,
        });

        if (!this.allowTime) {
            newDateTime = newDateTime.set({hour: 12, minute: 0});
        }

        if (newDateTime.isValid) {
            date = DateTime.fromFormat(newDateTime.toFormat(formatter), formatter).toJSDate();
        }
        return date;
    }

    setMidnightTime(date: Date) {
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        date.setMilliseconds(0);
    }

    getCurrentTimeValue(time: string) {
        const [hours, minutes] = time.split(':');
        return `${hours}:${minutes}`;
    }

    clear(): void {
        this.value = null;
        this.onChange(null);
        this._timeComponent = null;

        // clear the inputbox
        if (this.datePicker) {
            this.datePicker.writeValue('');
        }
    }

    writeValue(value: Date | null): void {
        this.value = value ?? this.defaultValue;
        this.initIncomingModel();
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    validate(control: FormControl): ValidationErrors | null {
        if (typeof this._modelStruct === 'string' && !DateTime.fromFormat(this._modelStruct, this.luxonInputDateTimeFormat).isValid) {
            return { message: `Please enter a valid date in the ${this.displayName} field` };
        }

        if (this.required && !control.value) {
            return { message: `The ${this.displayName} field has been required`};
        }

        return null;
    }

    private isDate(value: unknown): value is Date {
        return value && value instanceof Date;
    }

    private dateToTimeString(date: Date) {
        return `${date ? date.getHours() : 0}:${date ? date.getMinutes() : 0}`;
    }
}

<!-- Name -->
<ng-template #nameHeaderTmpl let-cohortMaterial="item" let-default>
    <div droppable
        [dropDisabled]="readonly"
        (drop)="onDrop(cohortMaterial.Cohort)"
        class="min-width60"
        style="padding: 5px;"
        [attr.title]="readonly ? '' : 'Drop animals here'">
        <ng-container *ngTemplateOutlet="default"></ng-container>
    </div>
</ng-template>

<ng-template #nameRowTmpl let-cohortMaterial="item">
    <div data-drag-type="material"
        draggable
        (dragStart)="dragStart(cohortMaterial.Cohort)"
        (dragStop)="dragStop()"
        [dragDisabled]="!cohortMaterial.isSelected"
        id="drag-animals-individual-{{cohortMaterial.Material?.C_Material_key}}"
        class="individual-animal-name">
        {{cohortMaterial.Material?.Animal.AnimalName}}
    </div>
</ng-template>


<!-- Genotype -->
<ng-template #genotypeRowTmpl let-cohortMaterial="item">
    <ng-template [ngIf]="cohortMaterial.Material.Animal?.Genotype?.length > 0">
        <span>{{ cohortMaterial.Material.Animal?.Genotype | formatGenotypeNames}}</span>
    </ng-template>
</ng-template>

<!-- Housing ID -->
<ng-template #housingRowTmpl let-cohortMaterial="item">
    {{cohortMaterial.Material?.MaterialPoolMaterial | currentMaterialHousingID}}
</ng-template>

<!-- Prior Housing ID -->
<ng-template #priorHousingRowTmpl let-cohortMaterial="item">
    {{cohortMaterial.Material?.MaterialPoolMaterial | priorMaterialHousingID}}
</ng-template>

<ng-template #markerRowTmpl let-cohortMaterial="item">
    <animal-marker *ngIf="cohortMaterial.Material.Animal?.cv_PhysicalMarkerType?.Vendor; else markerText"
        [marker]="cohortMaterial.Material.Animal.PhysicalMarker"
        [markerType]="cohortMaterial.Material.Animal.cv_PhysicalMarkerType">
    </animal-marker>
    <ng-template #markerText>
        {{cohortMaterial.Material.Animal.PhysicalMarker}}
    </ng-template>
</ng-template>

<!-- Age (Days) -->
<ng-template #dateBornDaysRowTmpl let-cohortMaterial="item">
    {{cohortMaterial.Material.Animal.DateBorn | animalAgeDays: cohortMaterial.Material.Animal.DateExit | number}}
</ng-template>

<!-- Age (Weeks) -->
<ng-template #dateBornWeeksRowTmpl let-cohortMaterial="item">
    {{cohortMaterial.Material.Animal.DateBorn | animalAgeWeeks: cohortMaterial.Material.Animal.DateExit | number}}
</ng-template>

<!-- Age (Months) -->
<ng-template #dateBornMonthsRowTmpl let-cohortMaterial="item">
    {{cohortMaterial.Material.Animal.DateBorn | animalAgeMonths: cohortMaterial.Material.Animal.DateExit | number}}
</ng-template>

<!-- Scan -->
<ng-template #scanRowTmpl let-cohortMaterial="item">
    <input type="text"
           name="test"
           [(ngModel)]="cohortMaterial.Scan"
           [ngModelOptions]="{updateOn: 'blur'}"
           (ngModelChange)="onScanChange(cohortMaterial)" 
           class="input-small" />
    <ng-container *ngIf="cohortMaterial.Scan" >
        <i *ngIf="cohortMaterial.IsScanValid" class="fa fa-check text-success" > </i>
        <i *ngIf="!cohortMaterial.IsScanValid" class="fa fa-exclamation-triangle text-danger" > </i>
    </ng-container>
</ng-template>

import { ExportType } from './export-type';
import { CsvExporter } from './csv-exporter';
import { FileExporter } from './file-exporter';
import { PdfConfig, PdfExporter } from './pdf-exporter';

export class ExporterFactory {
    static create(type: ExportType, pdfConfig: PdfConfig = {}): FileExporter {
        return type === ExportType.CSV ? new CsvExporter() : new PdfExporter(pdfConfig);
    }
}

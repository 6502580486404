import { Injectable, OnDestroy } from '@angular/core';
import { Subject, Subscription, fromEvent } from 'rxjs';
import { MultipleTabModalOpenService } from './multiple-tab-modal-open.service';
import { DataContextService } from '../services/data-context.service';
import { ActivatedRoute, Router } from '@angular/router';
import { RouteEnum } from '../routing/route.enum';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DialogService } from '@common/dialog/dialog.service';
import { filter, map } from 'rxjs/operators';

enum TabActionsEnum {
  NONE,
  MAIN_CHECK,
  DISCARD_CHANGES,
  WORKGROUP_CHANGED,
  WORKSPACE_SAVED,
}

interface ITabAction {
  action: TabActionsEnum;
}

interface ITabWorkspaceAction extends ITabAction {
  workspaceKey: string;
}

@Injectable({
  providedIn: 'root'
})
export class TabStorageService implements OnDestroy {
  private readonly CHANNEL_NAME = 'tab-storage';

  private readonly channel = new BroadcastChannel(this.CHANNEL_NAME);
  private subscription = new Subscription();

  private isMain = true;
  private lastAction?: TabActionsEnum;
  private discardChanges$$ = new Subject();
  private closeModal$$ = new Subject();
  private modalRef?: NgbModalRef;

  discardChanges$ = this.discardChanges$$.asObservable();
  closeModal$ =  this.closeModal$$.asObservable();

  constructor(
    private dataContext: DataContextService,
    private router: Router,
    private route: ActivatedRoute,
    private multipleTabModal: MultipleTabModalOpenService,
    private dialogService: DialogService
  ) {}

  ngOnDestroy(): void {
    this.channel.close();
    this.subscription.unsubscribe();
  }

  private get workspaceKey() {
    const id = this.route.snapshot.paramMap.get('id');
    return id;
  }

  init() {
    this.subscription = new Subscription();
    this.initMessage();
    this.postMessage({ action: TabActionsEnum.MAIN_CHECK });
  }

  destroy() {
    this.subscription.unsubscribe();
  }

  workspaceSaved() {
    this.postMessage({ action: TabActionsEnum.WORKSPACE_SAVED, workspaceKey: this.workspaceKey } as ITabWorkspaceAction);
  }

  workgroupChanged() {
    this.postMessage({ action: TabActionsEnum.WORKGROUP_CHANGED });
  }

  private initMessage() {
    const sub = fromEvent<MessageEvent<ITabAction>>(this.channel, 'message')
      .pipe(
        map(event => event.data),
        filter(data => ![TabActionsEnum.WORKGROUP_CHANGED, data.action].includes(this.lastAction))
      )
      .subscribe(data => this.handleSessionModal(data));
    this.subscription.add(sub);
  }

  private postMessage(data: ITabAction) {
    this.channel.postMessage(data);
  }

  private async handleSessionModal(data: ITabAction) {
    if (data.action === TabActionsEnum.MAIN_CHECK && this.isMain) {
      this.postMessage({ action: TabActionsEnum.DISCARD_CHANGES });
      return;
    }

    this.closeModal();
    this.lastAction = data.action;
    try { 
      this.isMain = false;
      await this.commonReset();
      switch (data.action) {
        case TabActionsEnum.DISCARD_CHANGES:
          this.discardChanges$$.next();
          break;
        case TabActionsEnum.WORKSPACE_SAVED:
          if (this.router.url.includes(RouteEnum.Workspaces)) {
            const { workspaceKey } = data as ITabWorkspaceAction;
            if (workspaceKey === this.workspaceKey) {
              await this.multipleTabModal.openReloadModal();
              location.reload();
            }
          }
          break;
        case TabActionsEnum.WORKGROUP_CHANGED:
          location.reload();
          break;
      }
      this.isMain = true;
      this.lastAction = undefined;
    } catch {/* do nothing */}
  }

  private async commonReset() {
    this.dialogService.closeAll();
    this.modalRef = this.multipleTabModal.openMultipleSessionModal();
    await this.modalRef.result;
    this.dataContext.cancel(false);
    this.postMessage({ action: TabActionsEnum.DISCARD_CHANGES });
    this.closeModal$$.next();
  }

  private closeModal() {
    if (this.modalRef) {
      this.modalRef.dismiss();
    }
  }
}
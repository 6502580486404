import { ChangeDetectorRef, Directive, ElementRef, OnDestroy } from '@angular/core';
import { DragDrop, DropListRef } from '@angular/cdk/drag-drop';
import type { ContainerDropList, ItemDrag } from './drag-drop.interface';


@Directive()
export class BaseDropListDirective<I extends ItemDrag> implements ContainerDropList, OnDestroy {
    dropListRef: DropListRef<ContainerDropList>;

    /**
     * Keeps track of the items that are registered with this container.
     * We have the items register with the container and then sort them
     * based on their position in the DOM.
     */
    protected unsortedItems: Set<I> = new Set<I>();

    constructor(
        protected cdr: ChangeDetectorRef,
    ) { }

    ngOnDestroy(): void {
        this.unsortedItems.clear();
        this.dropListRef?.dispose();
    }

    initDropList(dragDrop: DragDrop, element: HTMLElement | ElementRef<HTMLElement>): void {
        this.dropListRef = dragDrop.createDropList(element);
        this.dropListRef.data = this;

        this.dropListRef.beforeStarted.subscribe(() => {
            this.syncItemsWithRef();
            this.cdr.markForCheck();
        });
    }

    /** Registers an items with the drop list. */
    addItem(item: I): void {
        this.unsortedItems.add(item);

        if (this.dropListRef.isDragging()) {
            this.syncItemsWithRef();
        }
    }

    /** Removes an item from the drop list. */
    removeItem(item: I): void {
        this.unsortedItems.delete(item);

        if (this.dropListRef?.isDragging()) {
            this.syncItemsWithRef();
        }
    }

    /** Gets the registered items in the list, sorted by their position in the DOM. */
    getSortedItems(): I[] {
        return Array.from(this.unsortedItems).sort((a: I, b: I) => {
            const aElement: HTMLElement = a.dragRef.getVisibleElement();
            const bElement: HTMLElement = b.dragRef.getVisibleElement();
            const documentPosition = aElement.compareDocumentPosition(bElement);

            // `compareDocumentPosition` returns a bitmask so we have to use a bitwise operator.
            // https://developer.mozilla.org/en-US/docs/Web/API/Node/compareDocumentPosition
            // eslint-disable-next-line no-bitwise
            return documentPosition & Node.DOCUMENT_POSITION_FOLLOWING ? -1 : 1;
        });
    }

    /** Syncs up the registered drag items with underlying drop list ref. */
    private syncItemsWithRef(): void {
        const draggableList = this.getSortedItems().map((item) => item.dragRef);
        this.dropListRef.withItems(draggableList);
    }
}

<!-- template to render Recipient search item -->
<ng-template #recipientTmpl let-r="result">
    <span *ngIf="r.type === ROLE_TYPE">
        <b>(All) {{r.recipientName}}</b>
    </span>
    <span *ngIf="r.type === USER_TYPE">
        {{r.recipientName}}
    </span>
</ng-template>

<div class="recipient-select">
    <div *ngIf="recipients">
        <table class="table table-sm table-borderless">
            <tbody>
                <tr *ngFor="let recipient of recipients">
                    <td class="icon-cell">
                        <a (click)="removeRecipient(recipient)"
                           title="Remove recipient">
                            <i class="fa fa-remove remove-item"></i>
                        </a>
                    </td>
                    <td>
                        <ng-template [ngTemplateOutlet]="recipientTmpl"
                                     [ngTemplateOutletContext]="{result : recipient}"></ng-template>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <climb-indirect-typeahead [search]="searchRecipients"
                              [resultTemplate]="recipientTmpl"
                              [resultFormatter]="recipientFormatter"
                              [id]="id"
                              [placeholder]="'Name or role&hellip;'"
                              (selectItem)="selectRecipient($event)"></climb-indirect-typeahead>
</div>

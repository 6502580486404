<div class="calculated-output-container">
    <div #variableStandardPhraseField class="variable-standard-phrase-field" [ngClass]="disabled ? 'variable-standard-phrase-field-disabled' : ''">
        <div *ngFor="let expressionSegment of expressionSegments; let last = last; let index = index"
             (blur)="segmentBlurred($event)"
             [contentEditable]="expressionSegment.isFreeformText && !disabled"
             class="expression-segment"
             [ngClass]="
                    {
                        'freeform-segment': expressionSegment.isFreeformText,
                        'variable-segment': expressionSegment.isStandardPhraseVariable
                    }
              ">{{expressionSegment.text}}<span *ngIf="!expressionSegment.isFreeformText">
                <a *ngIf="!disabled" (click)="removeVariableSegment(index)">
                    <i class="fa fa-times remove-item" role="button" title="Remove variable"></i>
                </a>
            </span>
        </div>
    </div>

    <div class="btn-toolbar">

        <!-- Variables dropdown -->
        <div class="btn-group">
            <button class="btn btn-secondary dropdown-toggle" type="button"
                    id="dropdownVariables"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    [disabled]="disabled">
                Insert Variable
            </button>
            <div class="dropdown-menu scrollable" aria-labelledby="dropdownVariables" dropdown-append-to-body>
                <h6 class="dropdown-header">Variables</h6>
                <a *ngFor="let variable of variablesList"
                   class="dropdown-item"
                   href="#" (click)="variableSelected(variable); false">
                    {{variable.StandardPhraseVariableType}}
                </a>
            </div>
        </div>
        <info-tooltip *ngIf="isUsed" class="tool-tip tool-tip-variable" [placement]="'right-bottom'" [text]="'Standard phrases used in ' + ('Jobs' | translate) + ' cannot be modified. To change the text or variable selection, please create a new standard phrase.'"></info-tooltip>
    </div>
</div>

<!-- Table for Locations -->
<div class="form-group row">
    <label class="col-md-2 col-form-label"
           [ngClass]="{'required-text' : required}">Locations</label>
    <div class="col-md-10">
        <table class="table">
            <thead>
                <tr>
                    <th class="icon-cell col-size-fit">
                        <a (click)="addLocation(order)"
                           class="add-item-link"
                           *ngIf="!readonly">
                            <i class="fa fa-plus-circle" title="Add Location"></i>
                        </a>
                    </th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let orderLocation of order.OrderLocation">
                    <td class="text-center align-middle">
                        <a *ngIf="!readonly" (click)="removeLocation(orderLocation)">
                            <i class="fa fa-fw fa-remove remove-item"
                               title="Delete Location"></i>
                        </a>
                    </td>
                    <td>
                        <location-chooser [(location)]="orderLocation.LocationPosition"
                                          (rootLocationChange)="locationChanged(orderLocation)"
                                          [required]="true"
                                          [readonly]="readonly"></location-chooser>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

import { Injectable } from '@angular/core';
import { defer, Observable } from 'rxjs';

import { VocabularyService } from '../vocabularies/vocabulary.service';
import { take } from 'rxjs/operators';

@Injectable()
export class StudyVocabService {

    // vocab Observables
    public get studyStatuses$(): Observable<any[]>{
        return defer(() => this.vocabularyService.getCV('cv_StudyStatuses')).pipe(take(1));
    }
    public get studyTypes$(): Observable<any[]>{
        return defer(() => this.vocabularyService.getCV('cv_StudyTypes')).pipe(take(1));
    }


    constructor(
        private vocabularyService: VocabularyService
    ) {}
}

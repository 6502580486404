import {
    Component,
    Input
} from '@angular/core';

@Component({
    selector: 'study-print-preview',
    templateUrl: './study-print-preview.component.html'
})
export class StudyPrintPreviewComponent {
    @Input() study: any;

    constructor() {
        // do nothing
    }

  }

import {
    CellFormatterService,
    TableOptions,
    FormatterOptions
} from '../common/datatable';
import { TranslationService } from '../services/translation.service';
import { AuditModifiedFieldComponent } from './audit-modified-fields/audit-modified-field.component';
import { AuditClassFactory } from './audit-class-maker';

export class AuditLinesTableOptions {

    options: TableOptions;

    constructor(
        private cellFormatterService: CellFormatterService,
        private translationService: TranslationService,
    ) {
        this.options = this.getDefaultOptions();
    }

    getDefaultOptions(): TableOptions {

        return {
            csvFileName: this.translationService.translate('lines').toLowerCase() + '-audit.csv',
            enableDetailColumn: false,
            enableDraggable: false,
            enableSelectable: false,
            enableAuditColumns: false,
            columns: [
                {
                    displayName: 'Type',
                    field: 'UpdateType',
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Modified Fields',
                    field: 'ModifiedFields',
                    sortField: 'ModifiedFields',
                    rendererComponent: AuditModifiedFieldComponent,
                    exportFormatter: this.cellFormatterService.auditModifiedFieldsExportFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Date',
                    field: 'ModifiedDate',
                    formatter: this.cellFormatterService.dateTimeUTCFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Modified By',
                    field: 'ModifiedBy',
                    formatter: this.cellFormatterService.userNameFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Active',
                    field: 'Active',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('Active', 'Active'),
                    cellClass: AuditClassFactory.createCellClass('active'),
                },
                {
                    displayName: 'Name',
                    field: 'LineName',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('LineName', this.translationService.translate('Line') + 'Name'),
                    cellClass: AuditClassFactory.createCellClass((this.translationService.translate('Line') + 'Name').toLowerCase()),
                },                
                {
                    displayName: 'Short Name',
                    field: 'StockNumber',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('StockNumber', 'StockNumber'),
                    cellClass: AuditClassFactory.createCellClass('stocknumber'),
                },
                {
                    displayName: 'Stock',
                    field: 'StockID',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('StockID', 'StockID'),
                    cellClass: AuditClassFactory.createCellClass('stockid'),
                },
                {
                    displayName: 'Type',
                    field: 'LineType',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('LineType', this.translationService.translate('Line') + 'Type'),
                    cellClass: AuditClassFactory.createCellClass((this.translationService.translate('Line') + 'Type').toLowerCase()),
                },
                {
                    displayName: 'Status',
                    field: 'LineStatus',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('LineStatus', this.translationService.translate('Line') + 'Status'),
                    cellClass: AuditClassFactory.createCellClass((this.translationService.translate('Line') + 'Status').toLowerCase()),
                },
                {
                    displayName: 'Species',
                    field: 'CommonName',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('CommonName', 'CommonName'),
                    cellClass: AuditClassFactory.createCellClass('commonname'),
                },
                {
                    displayName: 'Construct',
                    field: 'FullName',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('FullName', 'FullName'),
                    cellClass: AuditClassFactory.createCellClass('fullname'),
                },
                {
                    displayName: 'Technician',
                    field: 'Technician',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('Technician', 'Technician'),
                    cellClass: AuditClassFactory.createCellClass('technician'),
                },
                {
                    displayName: 'Parent ' + this.translationService.translate('Line'),
                    field: 'ParentLine',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('ParentLine', 'Parent' + this.translationService.translate('Line')),
                    cellClass: AuditClassFactory.createCellClass(('Parent' + this.translationService.translate('Line')).toLowerCase()),
                },
                {
                    displayName: 'Background ' + this.translationService.translate('Line'),
                    field: 'BackgroundLine',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('BackgroundLine', 'Background' + this.translationService.translate('Line')),
                    cellClass: AuditClassFactory.createCellClass(('Background' + this.translationService.translate('Line')).toLowerCase()),
                },
                {
                    displayName: 'Breeding Strategy',
                    field: 'BreedingStrategy',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('BreedingStrategy', 'BreedingStrategy'),
                    cellClass: AuditClassFactory.createCellClass('breedingstrategy'),
                },
                {
                    displayName: 'Development',
                    field: 'Development',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('Development', 'Development'),
                    cellClass: AuditClassFactory.createCellClass('development'),
                },
                {
                    displayName: 'External Link',
                    field: 'ExternalLink',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('ExternalLink', 'ExternalLink'),
                    cellClass: AuditClassFactory.createCellClass('externallink'),
                },
                {
                    displayName: 'References',
                    field: 'Reference',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('Reference', 'Reference'),
                    cellClass: AuditClassFactory.createCellClass('reference'),
                },
                {
                    displayName: 'Comment',
                    field: 'Comment',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('Comment', 'Comment'),
                    cellClass: AuditClassFactory.createCellClass('comment'),
                },
                {
                    displayName: 'Default Location',
                    field: 'PositionName',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('PositionName', 'PositionName'),
                    cellClass: AuditClassFactory.createCellClass('positionname'),
                },
                {
                    displayName: 'Created By',
                    field: 'CreatedBy',
                    formatter: this.cellFormatterService.userNameFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Created Date',
                    field: 'DateCreated',
                    formatter: this.cellFormatterService.dateTimeUTCFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                }
            ]
        };
    }
}

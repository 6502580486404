import {
    CellFormatterService,
    TableOptions,
    FormatterOptions
} from '../common/datatable';
import { AuditModifiedFieldComponent } from './audit-modified-fields/audit-modified-field.component';
import { AuditClassFactory } from './audit-class-maker';

export class AuditOrdersAnimalsTableOptions {

    options: TableOptions;

    constructor(
        private cellFormatterService: CellFormatterService
    ) {
        this.options = this.getDefaultOptions();
    }

    getDefaultOptions(): TableOptions {

        return {
            csvFileName: 'ordersAnimals-audit.csv',
            enableDetailColumn: false,
            enableDraggable: false,
            enableSelectable: false,
            enableAuditColumns: false,
            columns: [
                {
                    displayName: 'Type',
                    field: 'UpdateType',
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Modified Fields',
                    field: 'ModifiedFields',
                    rendererComponent: AuditModifiedFieldComponent,
                    exportFormatter: this.cellFormatterService.auditModifiedFieldsExportFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Date',
                    field: 'ModifiedDate',
                    formatter: this.cellFormatterService.dateTimeUTCFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Modified By',
                    field: 'ModifiedBy',
                    formatter: this.cellFormatterService.userNameFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Order ID',
                    field: 'OrderID',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('OrderID', 'OrderID'),
                    cellClass: AuditClassFactory.createCellClass('orderid'),
                },
                {
                    displayName: 'Number of Animals',
                    field: 'NumberOfAnimals',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('NumberOfAnimals', 'NumberOfAnimals'),
                    cellClass: AuditClassFactory.createCellClass('numberofanimals'),
                },
                {
                    displayName: 'Sex',
                    field: 'Sex',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('Sex', 'Sex'),
                    cellClass: AuditClassFactory.createCellClass('sex'),
                },
                {
                    displayName: 'Species',
                    field: 'Species',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('Species', 'Species'),
                    cellClass: AuditClassFactory.createCellClass('species'),
                },
                {
                    displayName: 'Animal Condition at Receipt',
                    field: 'AnimalConditionAtReceipt',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('AnimalConditionAtReceipt', 'AnimalConditionAtReceipt'),
                    cellClass: AuditClassFactory.createCellClass('animalconditionatreceipt'),
                },
                {
                    displayName: 'Shipment Container Condition',
                    field: 'ShipmentContainerCondition',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('ShipmentContainerCondition', 'ShipmentContainerCondition'),
                    cellClass: AuditClassFactory.createCellClass('shipmentcontainercondition'),
                },
                {
                    displayName: 'Comments',
                    field: 'Comments',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('Comments', 'Comments'),
                    cellClass: AuditClassFactory.createCellClass('comments'),
                },                
                {
                    displayName: 'Created By',
                    field: 'CreatedBy',
                    formatter: this.cellFormatterService.userNameFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Created Date',
                    field: 'DateCreated',
                    formatter: this.cellFormatterService.dateTimeUTCFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                }
            ]
        };
    }
}

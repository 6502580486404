import type { Animal } from './animal.interface';
import type { cv_AnimalCommentStatus } from './cv-animal-comment-status.interface';

export interface AnimalComment {
    Animal: Animal;
    C_AnimalCommentStatus_key: number | null;
    C_AnimalComment_key: number;
    C_Material_key: number;
    Comment: string;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    ModifiedBy: string;
    Version: number;
    cv_AnimalCommentStatus: cv_AnimalCommentStatus;
    trackId?: number;
}

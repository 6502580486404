import type { Allele } from './allele.interface';
import type { Line } from './line.interface';

export interface LineAllele {
    Allele: Allele;
    C_Allele_key: number;
    C_LineAllele_key: number;
    C_Line_key: number;
    C_Workgroup_key: number;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    Line: Line;
    ModifiedBy: string;
    Version: number;
}

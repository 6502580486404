import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';
import { WizardService } from '../wizard.service';

import { SDNameFormat } from '../models';

import { randomId } from '../../common/util';

@Component({
    selector: 'set-name-format',
    templateUrl: './set-name-format.component.html',
})
export class SetNameFormatComponent implements OnInit {
    @Input() nameFormat: SDNameFormat;
    @Input() formatName: string;
    // The name of the item to be named (e.g., "animal")
    @Input() itemName: string;
    @Output() nameFormatChange: EventEmitter<SDNameFormat> = new EventEmitter<SDNameFormat>();

    seedValue: SDNameFormat;

    domIdAddition: string;


    constructor(
        private wizardService: WizardService
    ) {
        // Nothing to do
    }

    ngOnInit() {
        this.initialize();
    }

    initialize() {
        this.domIdAddition = randomId();

        this.getSeedValue().then((value) => {
            this.seedValue = value;

            if (!this.nameFormat) {
                // Use seed value if there is no input value
                this.nameFormat = this.seedValue;
                setTimeout(() => {
                    this.nameFormatChange.emit(this.nameFormat);
                });
            }
        });
    }

    private getSeedValue(): Promise<SDNameFormat> {
        return this.wizardService.getNameFormatSeedValue(this.formatName);
    }

    onModelChange(event: Event) {
        this.nameFormatChange.emit(this.nameFormat);
    }
}

import {
    Component,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AuthService } from '@services/auth.service';
import { RoutingService } from '../../../routing/routing.service';
import { keepErrorValidator, matchValidator } from '@common/validators';
import { buildId } from '@config';
import { padlockClosed } from '@icons';
import { ActivatedRoute } from '@angular/router';
import { catchError, tap } from 'rxjs/operators';

interface ValidateResetResponse {
    IsSuccess: boolean;
    MessageText: string;
}

interface ValidateResetError {
    ClassName: string;
    Data: Record<string, unknown>;
    ExceptionMethod: string;
    HResult: number;
    HelpURL: string | null;
    InnerException: string | null;
    Message: string;
    RemoteStackIndex: number;
    RemoteStackTraceString: string | null;
    Source: 'CLIMB.Admin' | 'CLIMB.Data';
    StackTraceString: string;
}

@Component({
    selector: 'pwd-reset-view',
    templateUrl: './pwd-reset-view.component.html',
    styleUrls: ['./pwd-reset-view.component.scss'],
})
export class PwdResetViewComponent implements OnInit, OnDestroy {
    icons = { padlockClosed };
    buildId: string = buildId;

    isLoaded = false;
    isError = false;
    loadErrorMessage = '';
    sending = false;
    errorMessage = '';
    savedSuccessfully = false;

    subscriptions: Subscription = new Subscription();

    formData: FormGroup = this.fb.group({
        PwdRequestId: [this.route.snapshot.queryParamMap.get('resetid')],
        Password: ['', [Validators.required, Validators.minLength(8)]],
        PasswordConfirm: ['', [Validators.required, keepErrorValidator()]],
    }, {
        validator: matchValidator('Password', 'PasswordConfirm', { controlShowError: false }),
    });

    constructor(
        private route: ActivatedRoute,
        private fb: FormBuilder,
        private authService: AuthService,
        private routingService: RoutingService,
    ) { }

    ngOnInit() {
        this.validateResetRequest();
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    private validateResetRequest() {
        const pwdRequestId = this.formData.get('PwdRequestId').value;
        const validateRequest$ = this.authService.validatePasswordResetRequest(pwdRequestId)
            .pipe(
                tap((response: ValidateResetResponse) => {
                    this.isError = !response.IsSuccess;
                    this.loadErrorMessage = response.MessageText;
                    this.isLoaded = true;
                }),
                catchError((error: ValidateResetError) => {
                    this.isError = true;
                    this.loadErrorMessage = `We're sorry, but we could not validate your request: ${error.Message}`;
                    this.isLoaded = true;
                    throw error;
                }),
            );
        this.subscriptions.add(validateRequest$.subscribe());
    }

    resetPassword() {
        if (this.formData.invalid) {
            return;
        }

        this.errorMessage = '';
        this.loadErrorMessage = '';
        this.sending = true;

        this.authService.resetPassword(this.formData.value).subscribe(
            (response) => {
                this.savedSuccessfully = true;
                this.sending = false;
                startTimer();
            },
            (response) => {
                const errorsString = this.authService.authErrorsToString(response);
                this.sending = false;
                this.errorMessage = `We're sorry, but your password could not be reset: ${errorsString}`;
            },
        );

        const startTimer = () => {
            setTimeout(() => {
                this.routingService.navigateToLogin();
            }, 5000);
        };
    }
}

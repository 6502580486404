import type { Genotype } from './genotype.interface';
import type { Job } from './job.interface';
import type { PlateMaterial } from './plate-material.interface';
import type { cv_PlateStatus } from './cv-plate-status.interface';
import type { cv_PlateType } from './cv-plate-type.interface';

export interface Plate {
    C_Job_key: number | null;
    C_PlateStatus_key: number;
    C_PlateType_key: number;
    C_Plate_key: number;
    C_Workgroup_key: number;
    CreatedBy: string;
    DateCreated: Date;
    DateFilled: Date | null;
    DateModified: Date;
    DateProcessed: Date | null;
    ExternalPlateID: string;
    Genotype: Genotype[];
    Job: Job;
    ModifiedBy: string;
    PlateID: string;
    PlateMaterial: PlateMaterial[];
    Version: number;
    cv_PlateStatus: cv_PlateStatus;
    cv_PlateType: cv_PlateType;
}

<div class="pedigree-wrapper"
     *ngIf="ancestors && ancestors.length > 0">

    <!-- Current animal -->
    <span class="pedigree-label current-node">
        <i *ngIf="animal.cv_Sex"
           class="fa"
           [ngClass]="{'fa-venus': animal.cv_Sex.Sex == 'Female', 'fa-mars': animal.cv_Sex.Sex === 'Male'}"
           title="{{animal.cv_Sex.Sex}}"></i>
        {{animal.AnimalName}}
    </span>

    <!-- Ancestors -->
    <div class="branch">
        <div class="entry"
             [ngClass]="ancestors.length === 1 ? 'sole-sibling' : ''"
             *ngFor="let ParentAnimal of ancestors">
            <pedigree-node [animal]="ParentAnimal"></pedigree-node>
        </div>
    </div>

</div>

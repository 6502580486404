<div class="row chart-settings-container pt-2">
    <div class="col-md-8">

        <form>
            <div class="task-output-controls">
                <task-output-select (outputSelectionChange)="onSelectedOutputChange($event)"></task-output-select>
            </div><!-- /.task-output-controls -->

            <chart-filter-group-creator [chartFilterTypes]="filterTypes"
                                        [(model)]="filterGroups">
            </chart-filter-group-creator>

        </form>
    </div>

    <div class="col-md-4">
        <h4 class="chart-options-container">Chart Options</h4>

        <form class="chart-form">
            <div class="options-container">
                <h5>Animal Label:</h5>
                <div class="radio-selection-container" *ngFor="let item of labels">
                    <input class="radio-selection-item"
                            type="radio"
                            id="label-{{domIdAddition}}"
                            name={{item}}
                            [value]="item"
                            [ngModel]="label$ | async"
                            (ngModelChange)="selectLabel($event)"
                        />
                    <label class="radio-selection-label">{{item}}</label>
                </div>
            </div>
            <div class="btn-container">
                <button type="submit"
                        class="surface-light button-md button-primary climb-button"
                        [disabled]="!isInputValid()"
                        (click)="createChart()">
                    Create Chart
                </button>
            </div>
        </form>

    <!-- Input hints -->
    <div *ngIf="!taskOutput" class="help-container text-info mb-2">
        <i class="fa fa-info-circle" aria-hidden="true"></i>
        Please select a task output.
    </div>
    <div *ngIf="filterGroups.length < 1" class="help-container text-info">
        <i class="fa fa-info-circle" aria-hidden="true"></i>
        Please add at least one animal group.
    </div>
  </div>
</div> <!-- /.row -->

<div class="row">
    <div class="col-md-12">
        <div class="chart-container">
            <div *ngIf="errorMessage" class="text-danger help-container">
                <i class="fa fa-exclamation-triangle" aria-hidden="true"> </i>
                {{ errorMessage }}
            </div>
            <div *ngIf="warningMessage" class="text-warning help-container">
                <i class="fa fa-exclamation-triangle" aria-hidden="true"> </i>
                {{ warningMessage }}
            </div>
            <div *ngIf="dataIncomplete" class="text-warning help-container">
                <i class="fa fa-exclamation-triangle" aria-hidden="true"> </i>
                One or more of your groups had no related data.
            </div>
            <loading-overlay *ngIf="loading" [message]="loadingMessage()"></loading-overlay>
            <div *ngIf="!rendered && !loading" class="d-flex" style="justify-content: center">
                <h2>Data not yet selected. Please select data to view.</h2>
            </div>
            <dx-chart *ngIf="rendered" [dataSource]="dataSource" palette="Green Mist">

                <dxo-common-series-settings argumentField="TaskAlias"
                                            [type]="'bar'">
                </dxo-common-series-settings>
                <!--<dxo-zoom-and-pan argumentAxis="both"></dxo-zoom-and-pan>-->
                <dxi-series *ngFor="let seriesLabel of seriesLabelList" [valueField]="seriesLabel + 'value'" [name]="seriesLabel"></dxi-series>
                <dxi-value-axis *ngIf="taskOutput" [title]="taskOutput.OutputName"></dxi-value-axis>
                <dxo-argument-axis [title]="'Task Aliases'">
                    <dxo-label overlappingBehavior="rotate" [rotationAngle]="-45">

                    </dxo-label>
                </dxo-argument-axis>
                <!--<dxo-crosshair [enabled]="true" color="#949494" [width]="3" dashStyle="dot">
                    <dxo-label [visible]="true" backgroundColor="#949494">
                        <dxo-font color="#fff" [size]="12"> </dxo-font>
                    </dxo-label>
                </dxo-crosshair>-->
                <dxo-common-axis-settings>
                </dxo-common-axis-settings>
                <dxo-legend verticalAlignment="top" horizontalAlignment="right">
                </dxo-legend>
                <dxo-export [enabled]="true"></dxo-export>
                <dxo-tooltip [enabled]="true" [zIndex]="5000" [customizeTooltip]="customizeTooltip"></dxo-tooltip>
            </dx-chart>

        </div> <!-- /.chart-container -->
    </div> <!-- /.col -->
</div> <!-- /.row -->

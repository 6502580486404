const ELLIPSIS = '\u2026';

import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';

import { SearchService } from '../search/search.service';
import { TranslationService } from '../services/translation.service';

import { SearchQueryDef } from '../search/search-query-def';

@Component({
    selector: 'study-select',
    templateUrl: './study-select.component.html',
    styles: [`
        .study-select {
            min-width: 300px;
        }
    `]
})
export class StudySelectComponent implements OnInit {
    @Input() model: any;
    @Input() excludeInactive = false;
    @Input() excludeLocked = false;
    @Input() id: string;
    @Input() required: boolean;
    @Input() disabled: boolean;
    @Input() sendObject: boolean;

    @Output() modelChange: EventEmitter<any> = new EventEmitter<any>();

    placeholder: string;


    constructor(
        private searchService: SearchService,
        private translationService: TranslationService
    ) {
    }

    ngOnInit() {
        this.placeholder = this.translationService.translate('Study') +
            ' name' +
            ELLIPSIS;
    }


    selectItemHandler(item: any) {
        if (!this.sendObject) {
            this.modelChange.emit(this.model);
        } else {
            this.modelChange.emit(item);
        }
    }

    searchStudies = (search: string): Promise<any[]> => {
        const filter: any = {
            StudyName: search
        };

        if (this.excludeInactive) {
            filter.IsActive = true;
        }

        if (this.excludeLocked) {
            filter.IsLocked = false;
        }

        return this._searchStudies(filter, 50);
    }

    searchStudiesByKey = (key: any): Promise<any[]> => {
        const filter = {
            StudyKey: key
        };
        return this._searchStudies(filter, 1);
    }

    _searchStudies(thisFilter: any, count: number): Promise<any[]> {
        const searchQueryDef: SearchQueryDef = {
            entity: 'Studies',
            page: null,
            size: count,
            sortColumn: 'StudyName',
            sortDirection: 'asc',
            filter: thisFilter
        };
        return this.searchService.getEntitiesBySearch(searchQueryDef).then((results: any) => {
            return results.data;
        });
    }

    resultFormatter = (item: any) => {
        return item.StudyName;
    }

    keyFormatter = (item: any) => {
        return item.StudyKey;
    }
}

import { HeaderComponent } from './header.component';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { BaseDirectivesModule } from '@common/directives/base-directives.module';
import { BaseComponentsModule } from '@common/components/base-components.module';
import { IconModule } from '@icons';
import { UserMenuComponent } from './components/user-menu/user-menu.component';
import { InfoComponent } from './components/info/info.component';
import { SupportComponent } from './components/support/support.component';
import { MessageCenterComponent } from './components/message-center/message-center.component';
import { WorkgroupSwitchComponent } from './components/workgroup-switch/workgroup-switch.component';
import { WorkgroupSwitchModalComponent } from './components/workgroup-switch-modal/workgroup-switch-modal.component';
import { WorkspaceSelectorComponent } from './components/workspace-selector/workspace-selector.component';
import { LockToggleComponent } from './components/lock-toggle/lock-toggle.component';
import { AutoResizeComponent } from './components/auto-resize/auto-resize.component';
import { CopyBufferComponent } from './components/copy-buffer/copy-buffer.component';
import { CopyBufferPopoverComponent } from './components/copy-buffer-popover/copy-buffer-popover.component';
import { WorkspaceNameInputComponent } from './components/workspace-name-input/workspace-name-input.component';
import { SaveChangesButtonComponent } from './components/save-changes-button/save-changes-button.component';
import { ContactFormComponent } from './components/contact-form/contact-form.component';
import { MessagesSharedModule } from '../messages/messages-shared.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        MessagesSharedModule,
        BaseDirectivesModule,
        BaseComponentsModule,
        IconModule,
    ],
    declarations: [
        HeaderComponent,
        UserMenuComponent,
        InfoComponent,
        SupportComponent,
        MessageCenterComponent,
        WorkgroupSwitchComponent,
        WorkgroupSwitchModalComponent,
        WorkspaceSelectorComponent,
        LockToggleComponent,
        AutoResizeComponent,
        CopyBufferComponent,
        CopyBufferPopoverComponent,
        WorkspaceNameInputComponent,
        SaveChangesButtonComponent,
        ContactFormComponent,
    ],
    exports: [
        HeaderComponent
    ]
})
export class HeaderModule { }

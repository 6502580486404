import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app.module';

// Enable Application Insights
import { initializeAppInsights } from './config/app-insights';
initializeAppInsights();

/*
* Shim breeze to use ES6 Promises.
*/
import './services/es6-shim/breeze-es6-shim';


/*
 * Bootstrap Climb-App
 */
import { enableProdMode } from '@angular/core';

if (process.env.ENV === 'production') {
    enableProdMode();
}

const bootstrapPromise =  platformBrowserDynamic().bootstrapModule(
    AppModule,
    { preserveWhitespaces: true }
);
// Logging bootstrap information
bootstrapPromise.then((success) => {
    console.log(`Bootstrap success`);
}).catch((err) => {
    console.error(err);
});

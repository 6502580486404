<div class="wizard-body">

    <h4 class="wizard-heading">
        Let's choose the <span class="data-type">species</span> you will work with.
    </h4>

    <div class="intro-text">
        <p>
            If you need to use a species that is not listed here,
            <a href="{{supportUri}}" target="_blank" rel="noopener">Climb Support</a>
            can add it for you quickly.
        </p>
    </div>

    <form *ngIf="taxonValues | notEmpty">
        <div class="form-check"
             *ngFor="let taxon of taxonValues; let index = index">
            <label class="form-check-label">
                <input type="checkbox"
                       class="form-check-input"
                       name="selectedTaxa{{index}}"
                       [(ngModel)]="taxon.isSelected"
                       (ngModelChange)="onModelChange($event)" />
                {{taxon.cvValue}}
                <span *ngIf="taxon.commonName">({{taxon.commonName}})</span>
            </label>
        </div>

        <p class="text-warning"
           *ngIf="!(selectedTaxa | notEmpty)">
            <i class="fa fa-2x fa-exclamation-triangle" aria-hidden="true"></i>
            Please select at least one species.
        </p>
    </form>

</div>

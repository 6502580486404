import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';

import { randomId } from './util';

/**
 * Select control for filtering by IsActive values.
 */
@Component({
    selector: 'active-status-select',
    template: `
    <select [attr.name]="fieldName"
            [id]="id"
            class="form-control input-medium"
            [(ngModel)]="model"
            (ngModelChange)="modelChangeHandler()"
            [disabled]="readonly"
            [required]="required">
        <option *ngIf="nullable"></option>
        <option [value]="true">Active</option>
        <option [value]="false">Inactive</option>
    </select>
    `
})
export class ActiveStatusSelectComponent implements OnInit {
    @Input() model: boolean;
    @Input() id: string;
    @Input() nullable = true;
    @Input() readonly: boolean;
    @Input() required: boolean;
    @Output() modelChange: EventEmitter<any> = new EventEmitter<any>();

    // state
    fieldName: string;

    ngOnInit() {
        this.fieldName = "activeStatusSelect-" + randomId();

        if (!this.id) {
            this.id = this.fieldName;
        }
    }

    modelChangeHandler() {
        this.modelChange.emit(this.model);
    }
}

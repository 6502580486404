import {
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
    AfterViewInit
} from '@angular/core';

import {
    notEmpty
} from '@common/util';

import { TaskOutputsData } from '../../models';

type ChartData = {
    traces: Plotly.Data[];
    layout: Plotly.Layout;
    config: Partial<Plotly.Config>;
};

@Component({
    selector: 'cohort-task-outputs-chart',
    templateUrl: './cohort-task-outputs-chart.component.html',
    styles: [`
        .chart-container {
            min-height: 300px;
        }
        .help-container {
            margin-top: 1em;
        }
    `]

})
/**
 * Creates a histogram for the task output values for a set of materials.
 */
export class CohortTaskOutputsChartComponent implements OnInit, OnChanges, AfterViewInit {
    @Input() taskOutputsData: TaskOutputsData;

    // Include all or selected animals?
    materialsToInclude: string;
    // Let Ploty pick the bin count?
    useAutoBinCount: boolean;
    // The maximum number of desired histogram bins.
    binCountMax: number;
    // Is there data for the current chart?
    isChartDataExists: boolean;
    chartData: ChartData = {
        traces: [],
        layout: this._createLayout([]),
        config: { responsive: true },
    };

    // Chart config constants
    readonly CHART_TITLE = 'Task Output Distribution';
    readonly OVERLAY_X_AXIS_TITLE = 'Output Value';
    readonly Y_AXIS_TITLE = 'Animals';
    // Marker color for each output
    readonly MARKER_COLORS: string[] = ['#3360a5', '#73b864'];
    // Trace opacity for each output
    readonly TRACE_OPACITIES: number[] = [1, 0.8];

    // materialsToInclude options
    readonly ALL_MATERIALS = 'All';
    readonly SELECTED_MATERIALS = 'Selected';

    ngOnInit() {
        this.initialize();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.taskOutputsData) {
            this.updatePlot();
        }
    }

    ngAfterViewInit() {
        this.updatePlot();
    }

    /**
     * Updates the chart after changes in the configuration controls.
     *
     * @param event
     */
    onChartConfigChange(event: Event) {
        this.updatePlot();
    }

    /**
     * Sets defaults and clears values.
     */
    initialize() {
        // Handle empty inputs
        if (!this.taskOutputsData.cohortMaterials) {
            this.taskOutputsData.cohortMaterials = [];
        }
        if (!this.taskOutputsData.selectedCohortMaterials) {
            this.taskOutputsData.selectedCohortMaterials = [];
        }

        // Defaults
        this.materialsToInclude = this.ALL_MATERIALS;
        this.binCountMax = 2;
        this.useAutoBinCount = true;
    }

    /**
     * Creates or clears the plot.
     */
    updatePlot() {
        if (this.hasSelectedOutputs()) {
            this._createPlot();
        }
    }

    private _createPlot() {
        const traces: ChartData['traces'] = this._createTraces();
        const layout: ChartData['layout'] = this._createLayout(traces);
        this.isChartDataExists = notEmpty(traces);
        this.chartData = {
            ...this.chartData,
            traces,
            layout,
        };
    }

    /**
     * For each selectedOutput, creates a Plotly trace/data object.
     */
    private _createTraces(): any[] {
        const traces: any[] = [];

        let outputIndex = 0;
        for (const selectedOutput of this.taskOutputsData.selectedOutputs) {
            if (selectedOutput) {
                const traceData: number[] = this.getTraceData(outputIndex);

                if (notEmpty(traceData)) {
                    // Plotly histogram API: https://plot.ly/javascript/reference/#histogram
                    const trace: any = {
                        type: 'histogram',
                        autobinx: true,
                        nbinsx: 0,
                        x: traceData,
                        name: selectedOutput.OutputName,
                        marker: {
                            color: this.MARKER_COLORS[outputIndex]
                        },
                        opacity: this.TRACE_OPACITIES[outputIndex]
                    };

                    // Override bin count?
                    if (this.useAutoBinCount === false && this.binCountMax > 0) {
                        trace.nbinsx = this.binCountMax;
                    }

                    traces.push(trace);
                }
            }

            outputIndex++;
        }

        return traces;
    }

    /**
     * Creates a Plotly Layout object.
     *
     * @param traces
     */
    private _createLayout(traces: any[]): any {
        const layout: any = {
            title: this.CHART_TITLE,
            xaxis: {},
            yaxis: {
                title: this.Y_AXIS_TITLE,
                // Use whole-number steps
                tick0: 1,
                dtick: 1
            },
            hovermode: 'x',
        };

        layout.xaxis.title = traces[0]?.name ?? '';

        if (traces.length > 1) {
            layout.barmode = "overlay";
            layout.xaxis.title = this.OVERLAY_X_AXIS_TITLE;
        }

        return layout;
    }

    /**
     * Are there any *defined* selectedOutputs?
     */
    hasSelectedOutputs(): boolean {
        if (notEmpty(this.taskOutputsData.selectedOutputs)) {
            for (const selectedOutput of this.taskOutputsData.selectedOutputs) {
                if (selectedOutput) {
                    return true;
                }
            }
        }

        return false;
    }

    /**
     * Constructs the data for a trace using values in CohortMaterial's outputValues[].
     *
     * @param outputIndex
     */
    getTraceData(outputIndex: number): number[] {
        // Get output values based on which materials to use: all or selected
        let outputValues: number[] = [];
        if (this.materialsToInclude === this.ALL_MATERIALS) {
            outputValues = this._getOutputValues(
                this.taskOutputsData.cohortMaterials,
                outputIndex
            );
        } else {
            outputValues = this._getOutputValues(
                this.taskOutputsData.selectedCohortMaterials,
                outputIndex
            );
        }

        return outputValues;
    }

    /**
     * Gets an array of numeric OutputValues from the array of CohortMaterials.
     *
     * @param cohortMaterials
     * @param outputIndex
     */
    private _getOutputValues(cohortMaterials: any[], outputIndex: number): number[] {

        if (outputIndex === 0) {
            return cohortMaterials.map((cohortMaterial: any) => {
                return cohortMaterial.OutputValue1;
            }).filter((value: any) => {
                return this._isValidNumberForChart(value);
            });
        } else {
            return cohortMaterials.map((cohortMaterial: any) => {
                return cohortMaterial.OutputValue2;
            }).filter((value: any) => {
                return this._isValidNumberForChart(value);
            });
        }
    }

    private _isValidNumberForChart(value: any): boolean {
        return value !== undefined && value !== null && value !== '' && !isNaN(value);
    }
}

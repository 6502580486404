import {
    Component,
    Input,
} from '@angular/core';

@Component({
    selector: 'info-tooltip',
    template: `
<a ngbTooltip="{{text}}"
    container="body"
    placement="{{placement}}">
        <i class="fa fa-info-circle text-info" aria-hidden="true"></i>
        <span class="sr-only">{{text}}</span>
</a>
`
})
export class InfoTooltipComponent {
    @Input() text: string;
    /**
     * Location of the tooltip.
     * Valid values: https://ng-bootstrap.github.io/#/components/tooltip/api
     */
    @Input() placement = 'top';

    constructor() {
        // Nothing to do
    }
}

import type { TaskInstance } from './task-instance.interface';

export interface TaskTopAnimal {
    AnimalName: string;
    AnimalStatus: string;
    AnimalUse: string;
    C_TaskInstance_key: number;
    CurrentLocationPath: string;
    LineName: string;
    Owner: string;
    TaskInstance: TaskInstance;
}

import type { Resource } from './resource.interface';

export interface ResourceGroupMember {
    C_ParentResource_key: number;
    C_ResourceGroupMember_key: number;
    C_Resource_key: number;
    C_Workgroup_key: number;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    ModifiedBy: string;
    ParentResource: Resource;
    Resource: Resource;
    Version: number;
}

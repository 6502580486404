import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { chevronLeft, chevronRight, firstPage, lastPage } from '@icons';
import { getPageCount } from './paginator.util';
import type { PageState } from './paginator.interface';


@Component({
    selector: 'climb-paginator',
    templateUrl: './paginator.component.html',
    styleUrls: ['./paginator.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaginatorComponent {
    readonly icons = { chevronLeft, chevronRight, firstPage, lastPage };

    /** The zero-based page index of the displayed list of items. */
    @Input() pageIndex = 0;

    /** Number of items to display on a page. */
    @Input() pageSize = 50;

    /** The count of the total number of items that are being paginated. */
    @Input() itemsCount = 0;

    @Input() disabled = false;

    /** Event emitted when the paginator changes the page index. */
    @Output() readonly pageChanged: EventEmitter<PageState> = new EventEmitter<PageState>();

    /** Emits an event notifying that a change of the paginator's properties has been triggered. */
    private emitPageEvent(previousPageIndex: number): void {
        this.pageChanged.emit({
            previousPageIndex,
            pageIndex: this.pageIndex,
            pageSize: this.pageSize,
            itemsCount: this.itemsCount,
        });
    }

    /** Advances to the next page if it exists. */
    nextPage(): void {
        if (!this.hasNextPage()) {
            return;
        }

        const previousPageIndex = this.pageIndex;
        this.pageIndex++;
        this.emitPageEvent(previousPageIndex);
    }

    /** Move back to the previous page if it exists. */
    previousPage(): void {
        if (!this.hasPreviousPage()) {
            return;
        }

        const previousPageIndex = this.pageIndex;
        this.pageIndex--;
        this.emitPageEvent(previousPageIndex);
    }

    /** Move to the first page if not already there. */
    firstPage(): void {
        // hasPreviousPage being false implies at the start
        if (!this.hasPreviousPage()) {
            return;
        }

        const previousPageIndex = this.pageIndex;
        this.pageIndex = 0;
        this.emitPageEvent(previousPageIndex);
    }

    /** Move to the last page if not already there. */
    lastPage(): void {
        // hasNextPage being false implies at the end
        if (!this.hasNextPage()) {
            return;
        }

        const previousPageIndex = this.pageIndex;
        this.pageIndex = getPageCount(this.itemsCount, this.pageSize) - 1;
        this.emitPageEvent(previousPageIndex);
    }

    /** Whether there is a previous page. */
    hasPreviousPage(): boolean {
        return this.pageIndex >= 1 && this.pageSize !== 0;
    }

    /** Whether there is a next page. */
    hasNextPage(): boolean {
        const maxPageIndex = getPageCount(this.itemsCount, this.pageSize) - 1;
        return this.pageIndex < maxPageIndex && this.pageSize !== 0;
    }

    /** Checks whether the buttons for going forwards should be disabled. */
    nextButtonsDisabled() {
        return this.disabled || !this.hasNextPage();
    }

    /** Checks whether the buttons for going backwards should be disabled. */
    previousButtonsDisabled() {
        return this.disabled || !this.hasPreviousPage();
    }

    pageTotal([itemsCount, pageSize]: [number, number]): string {
        const pageCount = getPageCount(itemsCount, pageSize);
        return pageCount === 0 ? 'more' : pageCount.toString();
    }

    pageItemRange([pageIndex, pageSize, itemsCount = Infinity]: [number, number, number]): string {
        const pageLastItem = pageIndex * pageSize + pageSize;
        return `${pageIndex * pageSize + 1} – ${Math.min(pageLastItem, itemsCount)}`;
    }
}

import { Injectable } from '@angular/core';
import { cv_TestArticle } from '../common/types';

import { EnumerationService } from '../enumerations/enumeration.service';
import { VocabularyService } from '../vocabularies/vocabulary.service';

// Patterned off of housing/housing-vocab.service.ts
@Injectable()
export class CharacteristicVocabService {

    // cached vocabs
    private _dataTypes: any[];
    private _taxons: any[];
    private _sampleTypes: any[];
    private _jobTypes: any[];
    private _jobSubtypes: any[];
    private _iacucProtocols: any[];
    private _jobCharacteristicLinkTypes: any[];
    private _jobCharacteristicTypes: any[];
    private _studyTypes: any[];
    private _enumerationClasses: any[];
    private _testArticles: cv_TestArticle[];
    private _activePromise: Promise<any[]>;

    constructor(
        private enumerationService: EnumerationService,
        private vocabularyService: VocabularyService
    ) {
        this._activePromise = Promise.resolve([]);
    }

    /*
    * All vocabs resolve after the cache has finished
    *   loading, if cache load is in progress
    */
    getDataTypes(): Promise<any[]> {
        return this._resolveAfterCacheLoaded(() => this._dataTypes);
    }
    getTaxons(): Promise<any[]> {
        return this._resolveAfterCacheLoaded(() => this._taxons);
    }
    getSampleTypes(): Promise<any[]> {
        return this._resolveAfterCacheLoaded(() => this._sampleTypes);
    }
    getJobTypes(): Promise<any[]> {
        return this._resolveAfterCacheLoaded(() => this._jobTypes);
    }
    getJobSubtypes(): Promise<any[]> {
        return this._resolveAfterCacheLoaded(() => this._jobSubtypes);
    }
    getIACUCProtocols(): Promise<any[]> {
        return this._resolveAfterCacheLoaded(() => this._iacucProtocols);
    }
    getJobCharacteristicLinkTypes(): Promise<any[]> {
        return this._resolveAfterCacheLoaded(() => this._jobCharacteristicLinkTypes);
    }
    getJobCharacteristicTypes(): Promise<any[]> {
        return this._resolveAfterCacheLoaded(() => this._jobCharacteristicTypes);
    }
    getStudyTypes(): Promise<any[]> {
        return this._resolveAfterCacheLoaded(() => this._studyTypes);
    }
    getEnumerationClasses(): Promise<any[]> {
        return this._resolveAfterCacheLoaded(() => this._enumerationClasses);
    }
    getTestArticles(): Promise<cv_TestArticle[]> {
        return this._resolveAfterCacheLoaded(() => this._testArticles);
    }

    reloadCache(): Promise<any[]> {
        this._activePromise = this._getCVs();
        return this._activePromise;
    }

    private _getCVs(): Promise<any[]> {
        const cv1 = this.vocabularyService.getCV('cv_DataTypes').then((data) => {
            this._dataTypes = data;
        });
        const cv2 = this.vocabularyService.getCV('cv_Taxons').then((data) => {
            this._taxons = data;
        });
        const cv3 = this.vocabularyService.getCV('cv_SampleTypes').then((data) => {
            this._sampleTypes = data;
        });
        const cv4 = this.vocabularyService.getCV('cv_JobTypes').then((data) => {
            this._jobTypes = data;
        });
        const cv5 = this.vocabularyService.getCV('cv_StudyTypes').then((data) => {
            this._studyTypes = data;
        });
        const cv6 = this.enumerationService.getEnumerationClasses().then((data) => {
            this._enumerationClasses = data;
        });
        const cv7 = this.vocabularyService.getCV('cv_JobSubtypes').then((data) => {
            this._jobSubtypes = data;
        });
        const cv8 = this.vocabularyService.getCV('cv_JobCharacteristicTypes').then((data) => {
            this._jobCharacteristicTypes = data;
        });
        const cv9 = this.vocabularyService.getCV('cv_IACUCProtocols').then((data) => {
            this._iacucProtocols = data;
        });
        const cv10 = this.vocabularyService.getCV('cv_JobCharacteristicLinkTypes').then((data) => {
            this._jobCharacteristicLinkTypes = data;
        });
        const cv11 = this.vocabularyService.getCV('cv_TestArticles').then((data: cv_TestArticle[]) => {
            this._testArticles = data;
        });
        return Promise.all([cv1, cv2, cv3, cv4, cv5, cv6, cv7, cv8, cv9, cv10, cv11]);
    }

    private _resolveAfterCacheLoaded(cacheValueFunc: () => any[]): Promise<any[]> {
        return this._activePromise.then(cacheValueFunc);
    }
}

import {
    Injectable,
} from '@angular/core';

import {
    NgbModal,
} from '@ng-bootstrap/ng-bootstrap';

import { AddTaskComponent } from './add-task.component';
import { TaskType } from '../models';

/*
* Service to view the add-task modal.
*/
@Injectable()
export class ViewAddTaskComponentService {

    readonly EMPTY_RESULT: any[] = [];

    constructor(
        private modalService: NgbModal
    ) {
        //
    }

    openComponent(taskType?: TaskType): Promise<any[]> {
        const modalRef = this.modalService.open(AddTaskComponent);
        const component = modalRef.componentInstance as AddTaskComponent;
        if (taskType) {
            component.taskType = taskType;
        }

        return modalRef.result.then((result) => {
            // Closed
            if (result) {
                return result;
            } else {
                return this.EMPTY_RESULT;
            }
        }, (reason) => {
            // Dismissed
            return this.EMPTY_RESULT;
        });
    }
}

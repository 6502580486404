import { ExportStudyAuditService } from './audit/export-study-audit.service';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { ClimbCommonModule } from '../common/climb-common.module';
import { ClimbServicesModule } from '../services/climb-services.module';

import { StudyService } from './study.service';
import { StudyVocabService } from './study-vocab.service';
import { ExportStudyDetailService } from './export-study-detail.service';
import { StudySelectComponent } from './study-select.component';
import { StudyMultiselectComponent } from './study-multiselect.component';
import { StudySaveService } from './services/study-save.service';
import { StudyStateService } from './services/study-state.service';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ClimbCommonModule,
        ClimbServicesModule
    ],
    declarations: [
        StudySelectComponent,
        StudyMultiselectComponent
    ],
    exports: [
        StudySelectComponent,
        StudyMultiselectComponent
    ]
})
export class StudiesSharedModule {
    static forRoot(): ModuleWithProviders<StudiesSharedModule> {
        return {
          ngModule: StudiesSharedModule,
          providers: [
            StudyService,
            StudyVocabService,
            ExportStudyDetailService,
            ExportStudyAuditService,
            StudySaveService,
            StudyStateService,
          ]
        };
    }
}

import { Component, OnInit, ChangeDetectionStrategy, Injector, ElementRef, ChangeDetectorRef, SkipSelf } from '@angular/core';
import { ButtonComponent } from '@common/components/button/button.component';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'button[climbFacetAction]',
    template: '<ng-content></ng-content>',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FacetHeaderActionButtonComponent extends ButtonComponent implements OnInit {
    constructor(
        injector: Injector,
        elementRef: ElementRef,
        @SkipSelf() cdr: ChangeDetectorRef,
    ) {
        super(injector, elementRef, cdr);
    }
    
    ngOnInit(): void {
        super.ngOnInit();
        super.variant = 'ghost';
        super.size = 'sm';
    }
}

import { VocabularyService } from '../../../vocabularies/vocabulary.service';
import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnChanges,
    OnInit,
    Output,
    NgZone,
    ChangeDetectorRef,
} from '@angular/core';

import { AnimalService } from '../../../animals/services/animal.service';
import { CopyBufferService } from '@common/services/copy-buffer.service';

import {
    arrayContainsAllObjects,
    getSafeProp,
} from '@common/util';
import { TranslationService } from '@services/translation.service';

@Component({
    selector: 'workflow-animal-table',
    templateUrl: './workflow-animal-table.component.html'
})
export class WorkflowAnimalTableComponent implements OnChanges, OnDestroy, OnInit {
    @Input() task: any;
    @Input() taskMaterials: any[];
    @Input() readonly: boolean;
    @Output() addAnimalMaterials: EventEmitter<any[]> = new EventEmitter<any[]>();

    animalMaterials: any[] = [];
    filterMaterialInterval: any;

    animalStatuses: any[];
    bulkAnimalStatusKey: any;
    
    constructor(
        private ngZone: NgZone,
        private animalService: AnimalService,
        private ref: ChangeDetectorRef,
        private vocabularyService: VocabularyService,
        private copyBufferService: CopyBufferService,
        private translationService: TranslationService,
    ) {
        // do nothing
    }

    ngOnInit() {
        this.initialize();
        
        this.ngZone.runOutsideAngular(() => {
            this.filterMaterialInterval = setInterval(() => {
                this.filterAnimals();
            }, 500);
        });
    }

    ngOnDestroy() {
        clearInterval(this.filterMaterialInterval);
    }

    ngOnChanges(changes: any) {
        if (changes.task) {
            if (!changes.task.firstChange) {
                this.initialize();
            }
        }
    }

    initialize() {
        this.animalStatuses = [];

        this.setupCVs();
        this.filterAnimals();
    }

    setupCVs() {
        const preferLocal = true;
        this.vocabularyService.getCV('cv_AnimalStatuses', null, preferLocal).then((data) => {
            this.animalStatuses = data;
        });
    }

    filterAnimals() {
        if (!this.taskMaterials) {
            this.animalMaterials = [];
            this.ref.detectChanges();
            return;
        }

        const animalMaterials = this.taskMaterials.filter((material: any) => {
            return material && getSafeProp(material, 'Material.Animal');
        });

        if (!arrayContainsAllObjects(animalMaterials, this.animalMaterials)) {
            this.animalMaterials = animalMaterials;
            this.ref.detectChanges();
        }
    }

    selectAllNonTerminalAnimals(event: Event) {
        const checkbox = event.target as HTMLInputElement;

        for (const animalMaterial of this.animalMaterials) {
            const isExitStatus: boolean = getSafeProp(
                animalMaterial,
                'Material.Animal.cv_AnimalStatus.IsExitStatus'
            );

            if (!isExitStatus) {
                animalMaterial.isSelected = checkbox.checked;
            }
        }
    }

    addClicked() {
        const selectedAnimals = this.animalMaterials.filter((animalMaterial: any) => {
            return animalMaterial.isSelected;
        });
        this.addAnimalMaterials.emit(selectedAnimals);
    }


    // Copying
    copyAnimals() {
        const animalsToCopy: any[] = this.getAnimalsToCopy();

        this.copyBufferService.copy(animalsToCopy);
    }

    /**
     * Returns array of animals that are selected and valid, and, thus,
     * eligible for copying or dragging out.
     */
    private getAnimalsToCopy(): any[] {
        return this.animalMaterials.filter((animalMaterial) => {
            return animalMaterial.isSelected && animalMaterial.Material;
        }).map((animalMaterial) => {
            return animalMaterial.Material.Animal;
        });
    }

    // Changes
    animalStatusChanged(animal: any) {
        this.animalService.statusChangePostProcess(animal);
    }

    bulkAnimalStatusChanged() {
        // Update all the animals, not just the selected animals.
        const changedAnimals: any[] = [];
        for (const animalMaterial of this.animalMaterials) {
            animalMaterial.Material.Animal.C_AnimalStatus_key = this.bulkAnimalStatusKey;
            changedAnimals.push(animalMaterial.Material.Animal);
        }
        this.animalService.statusBulkChangePostProcess(changedAnimals, this.bulkAnimalStatusKey);
    }

    // Dragging Out
    dragAnimalsStart() {
        this.animalService.draggedAnimals = this.getAnimalsToCopy();
    }

    dragAnimalsStop() {
        setTimeout(() => {
            this.animalService.draggedAnimals = [];
        }, 500);
    }

    // <select> formatters
    animalStatusKeyFormatter = (value: any) => {
        return value.C_AnimalStatus_key;
    }
    animalStatusFormatter = (value: any) => {
        return value.AnimalStatus;
    }
}

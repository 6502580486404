<span *ngIf="params &&
            params.data &&
            params.data.Material &&
            params.data.Material.MaterialSourceMaterial">
    <span *ngFor="let sourceMaterial of params.data.Material.MaterialSourceMaterial; let last = last;">
        <span *ngIf="sourceMaterial.SourceMaterial?.Animal">
            <img src="/images/animals.png"
                 width="11" height="11"
                 alt="Animal"
                 title="Animal" />&nbsp;{{sourceMaterial.SourceMaterial.Animal.AnimalName}}<span *ngIf="!last">,</span>
        </span>
        <span *ngIf="sourceMaterial.SourceMaterial?.Sample">
            <img src="/images/samples.png"
                 width="11" height="11"
                 alt="Sample"
                 title="Sample" />&nbsp;{{sourceMaterial.SourceMaterial.Sample.SampleName}}<span *ngIf="!last">,</span>
        </span>
    </span>
</span>

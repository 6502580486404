export class TableSort {
    propertyPath: string;
    reverse: boolean;
    natural: boolean;
    properties: string[];
    nested: boolean;
    nestedReverse: any;
    nestedIndex: any;
    // default values
    constructor() {
        this.propertyPath = '';
        this.properties = [];
        this.reverse = false;
        this.natural = false;
        this.nested = false;
        this.nestedReverse = {};
        this.nestedIndex = {};
    }

    /**
     * Set propertyPath orderBy clause,
     *   Or toggle reverse if propertyPath is already set
     * @param sortPropertyPath 
     */
    toggleSort(sortPropertyPath: string) {
        if (this.nested) {
            if (!this.properties.includes(sortPropertyPath)) {
                if (this.properties.length === 0 && this.propertyPath !== "") {
                    this.properties.push(this.propertyPath);
                    this.nestedIndex = {};
                    this.nestedIndex[this.propertyPath] = 1;
                } else if (this.properties.length >= 3) {
                    this.properties.shift();
                    this.properties.push(sortPropertyPath);
                    this.nestedIndex = {};
                    this.properties.forEach((path, index) => {
                        this.nestedIndex[path] = index + 1;
                    });
                } else {
                    this.properties.push(sortPropertyPath);
                    this.nestedIndex[sortPropertyPath] = this.properties.length;
                }
            }
        } else {
            this.nestedIndex = {};
            this.properties = [];
        }
        if (sortPropertyPath === this.propertyPath) {
            // Existing sort: toggle reverse or reset if already reversed 
            if (!this.reverse) {
                this.reverse = true;
            } else {
                this.propertyPath = '';
                this.reverse = false;
            }
        } else if (sortPropertyPath) {
            // New sort: reset everything
            this.propertyPath = sortPropertyPath;
            this.reverse = false;
        }
        this.nestedReverse[sortPropertyPath] = this.reverse;
    }

    /**
     * Set setSortSetup
     * @param properties
     * @param reverses
     * @param indexes
     */
    setSortSetup(properties: any, reverses: any, indexes: any) {
        this.properties = properties;
        this.nestedReverse = reverses;
        this.nestedIndex = indexes;
    }

    /**
     * Set setSingleSortSetup
     * @param property
     * @param reverse
     */
    setSingleSortSetup(property: any, reverse: any) {
        this.propertyPath = property;
        this.reverse = reverse;
    }
}

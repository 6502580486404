<form role="form" class="form-label-right">

<div class="modal-header justify-content-center">
    <h4 class="modal-title">Matings Filter</h4>
</div>

<div class="modal-body">
    <div class="form-group row">
        <label class="col-md-2 col-form-label">Housing ID</label>
        <div class="col-md-4">
            <housing-multiselect [(model)]="filter.materialPoolIDs"
                                 data-auto-focus="housingID"></housing-multiselect>
        </div>
        <label class="col-md-2 col-form-label">Mating ID</label>
        <div class="col-md-4">
            <multi-paste-input [(model)]="filter.matingIDs"
                               [limit]="MULTI_PASTE_INPUT_LIMIT"
                               [rows]="3"></multi-paste-input>
            <small class="form-text text-muted">
                IDs separated by
                <code>,</code> or <code>Enter</code>.
                Limit: {{MULTI_PASTE_INPUT_LIMIT}}.
            </small>
        </div>
    </div>

    <div class="form-group row">
        <label class="col-md-2 col-form-label">Type</label>
        <div class="col-md-4">
            <select name="matingType"
                    [(ngModel)]="filter.C_MatingType_key"
                    class="form-control input-medium">
                <option></option>
                <option *ngFor="let item of matingTypes"
                        [value]="item.C_MatingType_key">
                    {{item.MatingType}}
                </option>
            </select>
        </div>
        <label class="col-md-2 col-form-label">Status</label>
        <div class="col-md-4">
            <select name="matingStatus"
                    [(ngModel)]="filter.C_MatingStatus_key"
                    class="form-control input-medium">
                <option></option>
                <option *ngFor="let item of matingStatuses"
                        [value]="item.C_MatingStatus_key">
                    {{item.MatingStatus}}
                </option>
            </select>
        </div>
    </div>

    <div class="form-group row">
        <label class="col-md-2 col-form-label">Construct</label>
        <div class="col-md-4">
            <construct-multiselect [(model)]="filter.constructs"></construct-multiselect>
        </div>
        <label class="col-md-2 col-form-label">Created By</label>
        <div class="col-md-4">
            <user-select [(model)]="filter.createdBy"
                         [forceValidSelection]="false"></user-select>
        </div>
    </div>

    <div class="form-group row">
        <label class="col-md-2 col-form-label">Mating Date Range</label>
        <div class="col-md-6">
            <climb-ngb-date #dateControl="ngModel"
                            name="Mating Date Start"
                            [(ngModel)]="filter.dateMatingStart"
                            [placeholder]="'From&hellip;'"
                            [required]="false"></climb-ngb-date>
        </div>
        <div class="col-md-4">
            <climb-ngb-date #dateControl="ngModel"
                            name="Mating Date End"
                            [(ngModel)]="filter.dateMatingEnd"
                            [placeholder]="'To&hellip;'"
                            [required]="false"></climb-ngb-date>
        </div>
    </div>

    <div class="form-group row">
        <label class="col-md-2 col-form-label">{{'Line' | translate}}</label>
        <div class="col-md-4">
            <line-multiselect [(model)]="filter.lines"></line-multiselect>
        </div>

        <label class="col-md-2 col-form-label">Generation (Offspring)</label>
        <div class="col-md-4">
            <climb-multi-select [(model)]="filter.C_Generation_keys"
                                [values]="generations"
                                [keyProperty]="'C_Generation_key'"
                                [valueProperty]="'Generation'"
                                [placeholder]="'Select generations...'"></climb-multi-select>
        </div>
    </div>

    <div class="form-group row">
        <label class="col-md-2 col-form-label">{{'Job' | translate}}</label>
        <div class="col-md-4">
            <job-multiselect [(model)]="filter.jobs"></job-multiselect>
        </div>
    </div>

    <fieldset class="fieldset-bordered">
        <legend>Sire/Dam</legend>
        <div class="form-group row">
            <div class="col-md-2">
                <label class="col-form-label" style="display: block;">Name</label>
                <div class="text-right">
                    <one-many-toggle #animalNameToggle></one-many-toggle>
                </div>
            </div>
            <div class="col-md-4">
                <multi-control-wrap [toggleComponent]="animalNameToggle"
                                    [(singleModel)]="filter.animalName"
                                    [(manyModel)]="filter.animalNames">
                    <multi-control name="single">
                        <input type="text" name="animalName"
                               [(ngModel)]="filter.animalName"
                               class="form-control input-medium" />
                        <small class="form-text text-muted">
                            Find names <em>containing</em> this value.
                        </small>
                    </multi-control>
                    <multi-control name="many">
                        <multi-paste-input [(model)]="filter.animalNames"
                                           [limit]="MULTI_PASTE_INPUT_LIMIT"
                                           [rows]="3"></multi-paste-input>
                        <small class="form-text text-muted">
                            Names separated by
                            <code>,</code> or <code>Enter</code>.
                            Limit: {{MULTI_PASTE_INPUT_LIMIT}}.
                        </small>
                    </multi-control>
                </multi-control-wrap>
            </div>
            <label class="col-md-2 col-form-label">ID</label>
            <div class="col-md-4">
                <multi-paste-input [(model)]="filter.animalIDs"
                                   [isNumeric]="true"
                                   [limit]="MULTI_PASTE_INPUT_LIMIT"
                                   [rows]="3"></multi-paste-input>
                <small class="form-text text-muted">
                    IDs separated by
                    <code>,</code> or <code>Enter</code>.
                    Limit: {{MULTI_PASTE_INPUT_LIMIT}}.
                </small>
            </div>

            <div class="col-md-12 row">
                <label class="col-md-2 col-form-label">
                    <span>Sire(s) Genotype(s)</span>
                    <span class="a_label_checkbox">
                        <br /><span class="subfilter-block">And</span>
                        <input type="checkbox"
                               [(ngModel)]="filter.genotypeMaleAnd"
                               name="genotypeMaleAnd">
                        <br />
                    </span>
                </label>
                <div class="col-md-4">
                    <genotype-combo-select [(model)]="filter.genotypeMaleCombos"></genotype-combo-select>
                </div>
            </div>

            <div class="col-md-12 row">
                <label class="col-md-2 col-form-label">
                    <span>Dam(s) Genotype(s)</span>
                    <span class="a_label_checkbox">
                        <br /><span class="subfilter-block">And</span>
                        <input type="checkbox"
                               [(ngModel)]="filter.genotypeFemaleAnd"
                               name="genotypeFemaleAnd">
                        <br />
                    </span>
                </label>
                <div class="col-md-4">
                    <genotype-combo-select [(model)]="filter.genotypeFemaleCombos"></genotype-combo-select>
                </div>
            </div>
        </div>
    </fieldset>

</div>

<div class="modal-footer">
    <span class="btn-set">
        <button type="submit"
                class="btn btn-fw-wide btn-lg btn-primary"
                (click)="filterClicked($event)">
            Filter
        </button>
        <button data-automation-id="clear-filter-button"
                type="button"
                class="btn btn-fw-wide btn-lg btn-secondary"
                (click)="clearClicked()">
            Clear Filter
        </button>
    </span>
    <span class="btn-set">
        <button type="button"
                class="btn btn-lg btn-secondary"
                (click)="closeClicked()">
            Close
        </button>
    </span>
</div>
</form>

import type { cv_JobType } from './cv-job-type.interface';
import type { cv_StandardPhrase } from './cv-standard-phrase.interface';

export interface cv_StandardPhraseJobType {
    C_JobType_key: number;
    C_StandardPhraseJobType_key: number;
    C_StandardPhrase_key: number;
    C_Workgroup_key: number;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    ModifiedBy: string;
    Version: number;
    cv_JobType: cv_JobType;
    cv_StandardPhrase: cv_StandardPhrase;
}

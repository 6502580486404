import { FacetSharedModule } from './../common/facet/facet-shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AgGridModule } from "ag-grid-angular";

import { ClimbCommonModule } from '../common/climb-common.module';
import { ClimbServicesModule } from '../services/climb-services.module';
import { WorkspacesSharedModule } from '../workspaces/workspaces-shared.module';

import { AdvancedSearchComponent } from './advanced-search.component';
import { BooleanFilterComponent } from './boolean-filter.component';
import { DateFilterComponent } from './date-filter.component';
import { EnumerationFilterComponent } from './enumeration-filter.component';
import { NumberFilterComponent } from './number-filter.component';
import { SearchFacetComponent } from './search-facet.component';
import { SearchNodeComponent } from './search-node.component';
import { TextFilterComponent } from './text-filter.component';

import { SearchSharedModule } from './search-shared.module';
import { VocabulariesSharedModule } from '../vocabularies/vocabularies-shared.module';

@NgModule({
    imports: [
        AgGridModule.withComponents([]),
        ClimbCommonModule,
        ClimbServicesModule,
        FacetSharedModule,
        CommonModule,
        FormsModule,
        NgbModule,
        SearchSharedModule,
        WorkspacesSharedModule,
        VocabulariesSharedModule
    ],
    declarations: [
        AdvancedSearchComponent,
        BooleanFilterComponent,
        DateFilterComponent,
        EnumerationFilterComponent,
        NumberFilterComponent,
        SearchFacetComponent,
        SearchNodeComponent,
        TextFilterComponent,
    ],
    exports: [
        SearchFacetComponent,
    ],
    providers: [
    ]
})
export class SearchModule { }

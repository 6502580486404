import type { Job } from './job.interface';

export interface cv_JobType {
    Abbreviation: string;
    C_JobType_key: number;
    C_VocabularySource_key: number | null;
    C_Workgroup_key: number;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    ElementID: string;
    IsActive: boolean;
    IsDefault: boolean;
    IsDeprecated: boolean;
    Job: Job[];
    JobType: string;
    ModifiedBy: string;
    SortOrder: number | null;
    Version: number;
}

import { Pipe, PipeTransform } from '@angular/core';
import { truncateSentence } from '../util/truncate-sentence';

/*
* Truncate string to specific number of characters. Ends on most recent space if it splits a word.
*/
@Pipe({ name: 'truncateSentence' })
export class TruncateSentencePipe implements PipeTransform {

    transform(value: string, numChar: number): string {
        return truncateSentence(value, numChar);
    }
}

import {
    Component,
    Input
} from '@angular/core';

import { TaskType } from '../../../tasks/models';
import { TaskInstance } from '@common/types';

interface TaskExtended {
    showMaterialOutputColumn: boolean;
    showAnimals: boolean;
    showSamples: boolean;
}

@Component({
    selector: 'workflow-print-preview',
    templateUrl: './workflow-print-preview.component.html'
})
export class WorkflowPrintPreviewComponent {
    @Input() task: TaskInstance & TaskExtended;
    @Input() taskType: TaskType;
    @Input() workflowTaskOutputs: any[];

    // export enum to template
    TaskType = TaskType;


    constructor() {
        // do nothing
    }

}

import { Observable, Subscription } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';
import { ChangeDetectorRef, Directive, ElementRef, EventEmitter, HostBinding, OnDestroy, OnInit, Output } from '@angular/core';

export const resizeObservable = (element: HTMLElement): Observable<ResizeObserverEntry[]> => {
    return new Observable((subscriber) => {
        const observer = new ResizeObserver((entries) => {
            subscriber.next(entries);
        });

        observer.observe(element);

        return function unsubscribe() {
            observer.unobserve(element);
        };
    });
};

/**
 * ResizeObserver is an API which allows us to react to element resizing.
 * @link https://web.dev/resize-observer/
 * @link https://developer.mozilla.org/en-US/docs/Web/API/ResizeObserver
 *
 * @example```
 *   ＠Component({
 *       template: '<div class="resizable" climbResizeObserver (resize)="onResize($event)">Content</div>'
 *   })
 *   export class AppComponent {
 *       onResize(entry: ResizeObserverEntry) {
 *           console.log('Element width:', entry.contentRect.width);
 *       }
 *   }
 * ```
 *
 * `ResizeObserverEntry` type structure see https://developer.mozilla.org/en-US/docs/Web/API/ResizeObserverEntry
 */
@Directive({
  selector: '[climbResizeObserver]',
})
export class ResizeObserverDirective implements OnInit, OnDestroy {
    @Output()
    resize: EventEmitter<ResizeObserverEntry> = new EventEmitter();

    @HostBinding('class.resizing')
    isResizing = false;

    private resizeObserver: Subscription;

    constructor(
        private host: ElementRef,
        private cdr: ChangeDetectorRef,
    ) { }

    ngOnInit(): void {
        this.resizeObserver = resizeObservable(this.host.nativeElement)
            .pipe(
                tap(() => this.setResizing(true)),
                tap((entries) => {
                    entries.forEach((entry) => this.resize.emit(entry));
                }),
                debounceTime(50),
                tap(() => this.setResizing(false)),
            )
            .subscribe();
    }

    ngOnDestroy() {
        this.resizeObserver.unsubscribe();
    }

    private setResizing(value: boolean): void {
        this.isResizing = value;
        this.cdr.markForCheck();
    }
}

import { LocalStorageKey } from './../config';
import {
    Injectable
} from '@angular/core';

export const PREFIX = 'ls.';

@Injectable()
export class LocalStorageService {

    isGLP = false;

    constructor(
    ) {
        this.setFeatureFlags();
    }

    setFeatureFlags() {
        const flags = this.get(LocalStorageKey.FEATURE_FLAGS);
        if (flags) {
            const glpKey = "IsGLP";
            const isGLPFlag = flags[glpKey];
            this.isGLP = (isGLPFlag && isGLPFlag.IsActive && isGLPFlag.Value.toLowerCase() === "true");
        }
    }

    get(key: LocalStorageKey | string): any {
        const fullKey = PREFIX + key;
        let value: string = null;

        // if IsGLP, check session storage for AUTH_DATA, otherwise check local storage
        if (this.isGLP && key === LocalStorageKey.AUTH_DATA) {
            value = sessionStorage.getItem(fullKey);
        } else {
            value = localStorage.getItem(fullKey);
        }

        return JSON.parse(value);
    }

    set(key: LocalStorageKey | string, item: any) {
        const fullKey = PREFIX + key;
        const value = JSON.stringify(item);
        localStorage.setItem(fullKey, value);

        // Store auth data in session storage (clears upon browser close)
        // Used for session GLP timeout
        if (key === LocalStorageKey.AUTH_DATA) {
            sessionStorage.setItem(fullKey, value);
        }
    }

    remove(key: LocalStorageKey | string) {
        const fullKey = PREFIX + key;
        localStorage.removeItem(fullKey);
        // Clear auth data from session storage
        if (key === LocalStorageKey.AUTH_DATA) {
            sessionStorage.removeItem(fullKey);
        }
    }

    removeSSO(key: LocalStorageKey | string) {
        localStorage.removeItem(key);
    }
}

<climb-dropdown caption="Export"
                [icon]="icons.arrowsBordersVertical">
    <button climbDropdownItem
            (click)="clickExport(exportTypes.CSV)"
            [appInsightsClick]="'click-list-view-export-csv' + componentName">
        CSV
    </button>
    <button climbDropdownItem
            (click)="clickExport(exportTypes.PDF)"
            [appInsightsClick]="'click-list-view-export-pdf' + componentName">
        PDF
    </button>
    <ng-content select="[climbDropdownItem]"></ng-content>
</climb-dropdown>

<form novalidate>
    <div class="modal-header justify-content-center">
        <h4 class="modal-title">Select a Task Output</h4>
    </div>

    <div class="modal-body">

        <task-output-select
            [allowOnlyNumeric]="true"
            (outputSelectionChange)="onSelectedOutputChange($event)"></task-output-select>

    </div><!-- /.modal-body -->

    <div class="modal-footer">
        <button type="button"
                class="btn btn-fw btn-lg btn-primary"
                (click)="onSubmit()"
                [disabled]="!selectedOutput">
            Select
        </button>
        <button type="button"
                class="btn btn-fw btn-lg btn-secondary"
                (click)="onCancel()">
            Cancel
        </button>
    </div>
</form>

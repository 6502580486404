import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
    selector: 'climb-insight-card',
    templateUrl: './insight-card.component.html',
    styleUrls: ['./insight-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InsightCardComponent {
    @Input() caption: string;
}

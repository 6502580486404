import {
    Injectable
} from '@angular/core';

import {
    NgbModal,
} from '@ng-bootstrap/ng-bootstrap';

import { SampleAuditReportComponent } from './sample-audit-report.component';

/*
* Service to view the sample audit-report modal.
*/
@Injectable()
export class ViewSampleAuditReportComponentService {

    constructor(
        private modalService: NgbModal
    ) {
        //
    }

    openComponent(materialKey: number) {
        const modalRef = this.modalService.open(SampleAuditReportComponent, { size: 'lg' });
        modalRef.componentInstance.materialKey = materialKey;
    }
}

/**
 * Compares two sets of primitive values.
 * @param array1
 * @param array2
 */
export function arrayContainsAllValues(
    array1: (string | number)[],
    array2: (string | number)[]
): boolean {
    const cmp1 = array1.slice();
    cmp1.sort();
    const cmp2 = array2.slice();
    cmp2.sort();
    return JSON.stringify(cmp1) === JSON.stringify(cmp2);
}

import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output
} from '@angular/core';

import { sortObjectArrayByProperty } from '../common/util/sort-array';
import { VocabularyService } from '../vocabularies/vocabulary.service';
import { uniqueArrayFromPropertyPath } from '../common/util';

@Component({
    selector: 'genotype-symbol-select',
    template: `
    <active-vocab-select
            [(model)]="model"
            (modelChange)="modelChangedHandler()"
            [vocabChoices]="_filteredSymbolChoices"
            [keyFormatter]="genotypeSymbolKeyFormatter"
            [optionFormatter]="genotypeSymbolFormatter"
            [readonly]="!parentAssayKey && !noFilterOnNullParent"
            [nullable]="true"
    ></active-vocab-select>
    `
})
export class GenotypeSymbolSelectComponent implements OnChanges, OnInit {
    @Input() model: any;
    @Output() modelChange: EventEmitter<any> = new EventEmitter<any>();
    @Input() parentAssayKey: any;
    @Input() symbolChoices: any[];
    @Input() noFilterOnNullParent: boolean;

    // state variables
    parentAssay: any;
    _filteredSymbolChoices: any[];

    // track if parent has been initialized yet
    _isParentInitialized: boolean;

    constructor(private vocabularyService: VocabularyService) {
        this._isParentInitialized = false;
    }

    ngOnInit() {
        this.refreshParentAssay().then(() => {
            this.refreshSymbolChoices();
        });
    }

    ngOnChanges(changes: any) {
        // re-trigger genotype symbol filter if
        //   inputs have changed
        if (changes.parentAssayKey) {
            this.refreshParentAssay().then(() => {
                this.refreshSymbolChoices();

                if (changes.parentAssayKey.currentValue !== null &&
                    changes.parentAssayKey.currentValue !== undefined
                ) {
                    this._isParentInitialized = true;
                }

                this.clearModelIfInvalid();
            });

        } else if (
            changes.model ||
            changes.assayChoices
        ) {
            this.refreshSymbolChoices();
            this.clearModelIfInvalid();
        }


    }

    // Ensure GenotypeAssay has GenotypeAssayGenotypeSymbol relationship
    refreshParentAssay(): Promise<void> {
        if (this.parentAssayKey) {
            return this.vocabularyService.getCVByFieldEquals(
                'cv_GenotypeAssays',
                'C_GenotypeAssay_key',
                this.parentAssayKey
            ).then((assays: any) => {
                this.parentAssay = assays.length > 0 ? assays[0] : 0;
            });
        }
        this.parentAssay = null;
        return Promise.resolve();
    }

    refreshSymbolChoices() {
        this._filteredSymbolChoices = this.filterSymbolChoices(
            this.symbolChoices,
            this.parentAssay,
            this.model,
            this.noFilterOnNullParent
        );
    }

    /*
    * Filter out assays not available in parent line
    */
    filterSymbolChoices(
        symbols: any[], 
        parentAssay: any, 
        modelKey: number = null, 
        noFilterOnNullAssay = false
    ) {
        if (!symbols) {
            return [];
        }

        if (!parentAssay && noFilterOnNullAssay) {
            return symbols;
        }

        if (!parentAssay && !noFilterOnNullAssay) {
            this.model = null;
            return [];
        }

        const availableSymbolKeys = uniqueArrayFromPropertyPath(
            parentAssay, 'GenotypeAssayGenotypeSymbol.C_GenotypeSymbol_key'
        );

        const symbolsSorted = sortObjectArrayByProperty(symbols, 'SortOrder');

        return symbolsSorted.filter((symbol: any) => {
            return availableSymbolKeys.indexOf(symbol.C_GenotypeSymbol_key) > -1;
        });
    }

    clearModelIfInvalid() {
        // Do not clear out current model if parent has
        //  not been initialized.
        // This would cause component to clear model on startup
        if (!this._isParentInitialized) {
            return;
        }

        if (!this.isModelValid(this.model, this._filteredSymbolChoices)
        ) {
            this.model = null;
            this.modelChange.emit(this.model);
        }
    }

    isModelValid(model: any, filteredChoices: any[]) {

        for (const symbol of filteredChoices) {
            // use == here to test string and integer
            /* eslint-disable-next-line */
            if (symbol.C_GenotypeSymbol_key == model) {
                return true;
            }
        }

        return false;
    }

    modelChangedHandler() {
        this.modelChange.emit(this.model);
    }

    // Formatters for <select> input
    genotypeSymbolKeyFormatter = (item: any) => {
        return item.C_GenotypeSymbol_key;
    }
    genotypeSymbolFormatter = (item: any) => {
        return item.GenotypeSymbol;
    }
}


import {
    Component,
    EventEmitter,
    Input,
    Output
} from '@angular/core';

import { SearchService } from '../search/search.service';
import { SearchQueryDef } from '../search/search-query-def';

@Component({
    selector: 'plate-select',
    template: `
    <div class="plate-select">
        <climb-typeahead
            [(model)]="model"
            [resultFormatter]="plateIdFormatter"
            [keyFormatter]="plateKeyFormatter"
            [search]="searchPlates"
            [keySearch]="searchPlatesByKey"  
            [required]="required"
            [disabled]="disabled"
            [namespace]="'plate'"
            (selectItem)="selectItemHandler($event)"
            #typeahead
        ></climb-typeahead>
    </div>
    `,
    styles: [`
        .plate-select {
            min-width: 300px;
        }
    `]
})
export class PlateSelectComponent {
    @Input() model: any;
    @Output() modelChange: EventEmitter<any> = new EventEmitter<any>();

    @Input() required: boolean;
    @Input() disabled: boolean;

    constructor(
        private searchService: SearchService
    ) {
    }

    selectItemHandler(item: any) {
        this.modelChange.emit(this.model);
    }

    searchPlates = (search: string): Promise<any[]> => {

        const filter = {
            PlateID: search
        };

        return this._searchPlates(filter, 50);
    }

    searchPlatesByKey = (key: any): Promise<any[]> => {
        const filter = {
            PlateKey: key
        };
        return this._searchPlates(filter, 1);
    }

    _searchPlates(thisFilter: any, count: number): Promise<any[]> {
        const searchQueryDef: SearchQueryDef = {
            entity: 'Plates',
            page: undefined,
            size: count,
            sortColumn: 'PlateID',
            sortDirection: 'asc',
            filter: thisFilter
        };
        return this.searchService.getEntitiesBySearch(searchQueryDef).then((results: any) => {
            return results.data;
        });
    }

    plateIdFormatter = (item: any) => {
        return item.PlateID;
    }

    plateKeyFormatter = (item: any) => {
        return item.PlateKey;
    }
}

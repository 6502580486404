<div class="detail-form-wrapper">
    <loading-overlay *ngIf="facetLoading$| async" [message]="loadingMessage"></loading-overlay>
    <div class="detail-header">
        <h3>Order</h3>

        <pager-toolbar [itemName]="'order'"
                       [logTag]="COMPONENT_LOG_TAG"
                       [first]="pageState.isFirst"
                       [last]="pageState.isLast"
                       (previous)="previousClicked()"
                       (next)="nextClicked()">
        </pager-toolbar>

        <detail-toolbar [facetPrivilege]="facet.Privilege"
                        [logTag]="COMPONENT_LOG_TAG"
                        [hasChanges]="hasChanges$ | async"
                        [loading]="facetLoading$ | async"
                        (save)="saveEntity()"
                        (exit)="exitClicked()">
        </detail-toolbar>
    </div>

    <form name="OrderForm" id="orderForm"
          class="detail-form"
          #orderForm="ngForm">
        <fieldset [disabled]="readonly">
            <div class="form-group row"
                [ngClass]="orderID.hasError('unique') && orderID.dirty ? 'has-warning' : ''">
                <label class="col-md-2 col-form-label">Order ID</label>
                <div class="col-md-10">
                    <input data-automation-id="order-id-input"
                           type="text" name="orderID"
                           class="form-control input-medium"
                           maxlength="200"
                           validateUnique
                           uniqueEntity="Orders"
                           uniqueProperty="OrderID"
                           [entityPK]="order.C_Order_key"
                           entityPKName="C_Order_key"
                           #orderID="ngModel"
                           [(ngModel)]="order.OrderID"
                           [disabled]="(order | isNew) && orderNamingActive"
                           [required]="!(order | isNew) || !orderNamingActive"/>
                    <small class="form-text text-muted"
                               *ngIf="orderID.errors?.unique && orderID.dirty">
                            <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                            This Order number already exists.
                        </small>
                        <small class="form-text text-muted"
                               *ngIf="(order | isNew) && orderNamingActive">
                         The {{'Order' | translate}} Number will be assigned automatically.
                     </small>
               </div>
            </div>

            <div class="form-group row" *ngIf="!isGLP && activeFields.includes('Order Type')">
                <label class="col-md-2 col-form-label">Order Type</label>
                <div class="col-md-10">
                    <active-vocab-select [(model)]="order.C_MaterialType_key"
                                         (modelChange)="orderTypeChanged()"
                                         [vocabChoices]="materialTypes"
                                         [keyFormatter]="materialTypeKeyFormatter"
                                         [optionFormatter]="materialTypeFormatter"
                                         [required]="requiredFields.includes('C_MaterialType_key')"
                                         [nullable]="!requiredFields.includes('C_MaterialType_key')">
                    </active-vocab-select>
                </div>
            </div>

            <div class="form-group row" *ngIf="activeFields.includes('Job')">
                <label class="col-md-2 col-form-label">{{'Job' | translate}}</label>
                <div class="col-md-10">
                    <job-select [excludeLocked]="true"
                                [(model)]="jobOrderKey"
                                (modelChange)="onSelectJob($event)"
                                [required]="requiredFields.includes('JobOrder[0].C_Job_key')">
                    </job-select>
                </div>
            </div>

            <order-location-table class="locations-table"
                                  *ngIf="activeFields.includes('Locations')"
                                  (firstLineChanged)="updateOrderID('location')"
                                  [order]="order"
                                  [readonly]="readonly"
                                  [required]="requiredFields.includes('OrderLocation[0]') && order.OrderLocation.length === 0">
            </order-location-table>

            <div class="form-group row" *ngIf="activeFields.includes('PO Number')">
                <label class="col-md-2 col-form-label">PO Number</label>
                <div class="col-md-10">
                    <input type="text" name="InvoiceNumber"
                           class="form-control input-medium"
                           maxlength="200"
                           #name="ngModel"
                           [(ngModel)]="order.InvoiceNumber"
                           [required]="requiredFields.includes('InvoiceNumber')" />
                </div>
            </div>

            <div class="form-group row" *ngIf="activeFields.includes('Species') && order.cv_MaterialType?.MaterialType === 'Animal'">
                <label class="col-md-2 col-form-label">Species</label>
                <div class="col-md-10">
                    <active-vocab-select [(model)]="order.C_Taxon_key"
                                         [vocabChoices]="taxons"
                                         [keyFormatter]="taxonKeyFormatter"
                                         [optionFormatter]="taxonFormatter"
                                         [required]="requiredFields.includes('C_Taxon_key')"
                                         [nullable]="!requiredFields.includes('C_Taxon_key')">
                    </active-vocab-select>
                </div>
            </div>

            <div class="form-group row" *ngIf="activeFields.includes('Farm/Vendor')">
                <label class="col-md-2 col-form-label">Farm/Vendor</label>
                <div class="col-md-10">
                    <select name="Institution"
                            class="form-control input-medium"
                            (change)="institutionChanged($event.target.value)"
                            [(ngModel)]="order.C_Institution_key"
                            [required]="requiredFields.includes('C_Institution_key')">
                        <option></option>
                        <option *ngFor="let item of institutions"
                                [value]="item.C_Institution_key">
                            {{item.Name}}
                        </option>
                    </select>
                </div>
            </div>

            <div class="form-group row" *ngIf="activeFields.includes('Country of Origin')">
                <label class="col-md-2 col-form-label">Country of Origin</label>
                <div class="col-md-10">
                    <active-vocab-select [(model)]="order.C_MaterialOrigin_key"
                                         [vocabChoices]="materialOrigins"
                                         [keyFormatter]="materialOriginKeyFormatter"
                                         [optionFormatter]="materialOriginFormatter"
                                         [required]="requiredFields.includes('C_MaterialOrigin_key')"
                                         [nullable]="!requiredFields.includes('C_MaterialOrigin_key')">
                    </active-vocab-select>
                </div>
            </div>

            <div class="form-group row" *ngIf="activeFields.includes('Vendor Order Number')">
                <label class="col-md-2 col-form-label">Vendor Order Number</label>
                <div class="col-md-10">
                    <input type="text" name="Vendor"
                           class="form-control input-medium"
                           maxlength="200"
                           #name="ngModel"
                           [(ngModel)]="order.VendorOrderNumber"
                           [required]="requiredFields.includes('VendorOrderNumber')" />
                </div>
            </div>

            <div class="form-group row" *ngIf="activeFields.includes('Number of Animals Ordered')">
                <label class="col-md-2 col-form-label">Number of Animals Ordered</label>
                <div class="col-md-10">
                    <input type="number" name="animalCount"
                           class="form-control input-number"
                           min="1"
                           step="1"
                           [(ngModel)]="order.AnimalCount"
                           [required]="requiredFields.includes('AnimalCount')" />
                    <small *ngIf="order.AnimalCount && tableAnimalCount != null && order.AnimalCount !== tableAnimalCount" class="form-text text-muted">
                        <i class="fa fa-exclamation-triangle alert-warning" aria-hidden="true"></i>
                        The number of animals ordered does not match the received number.
                    </small>
                </div>
            </div>

            <div class="form-group row" *ngIf="activeFields.includes('Quarantine Services Facility')">
                <label class="col-md-2 col-form-label">Quarantine Services Facility</label>
                <div class="col-md-10">
                    <active-vocab-select [(model)]="order.C_QuarantineFacility_key"
                                         [vocabChoices]="quarantineFacilities"
                                         [keyFormatter]="quarantineFacilityKeyFormatter"
                                         [optionFormatter]="quarantineFacilityFormatter"
                                         [required]="requiredFields.includes('C_QuarantineFacility_key')"
                                         [nullable]="!requiredFields.includes('C_QuarantineFacility_key')">
                    </active-vocab-select>
                </div>
            </div>

            <div class="form-group row" *ngIf="activeFields.includes('Vendor Room ID')">
                <label class="col-md-2 col-form-label">Vendor Room ID</label>
                <div class="col-md-10">
                    <input type="text" name="VendorRoom"
                           class="form-control input-medium"
                           maxlength="200"
                           #name="ngModel"
                           [(ngModel)]="order.VendorRoomId"
                           [required]="requiredFields.includes('VendorRoomId')" />
                </div>
            </div>

            <div class="form-group row" *ngIf="activeFields.includes('Date Ordered')">
                <label class="col-md-2 col-form-label">Date Ordered</label>
                <div class="col-md-10">
                    <climb-ngb-date #dateControl="ngModel"
                                    name="Date Ordered"
                                    [(ngModel)]="order.DateReceived"
                                    [required]="requiredFields.includes('DateReceived')">
                    </climb-ngb-date>
                </div>
            </div>

            <div class="form-group row" *ngIf="activeFields.includes('Projected Receipt Date')">
                <label class="col-md-2 col-form-label">Projected Receipt Date</label>
                <div class="col-md-10">
                    <climb-ngb-date #dateControl="ngModel"
                                    name="Projected Receipt Date"
                                    [(ngModel)]="order.DateProjectedReceipt"
                                    [required]="requiredFields.includes('DateProjectedReceipt')">
                    </climb-ngb-date>
                </div>
            </div>

            <div class="form-group row" *ngIf="activeFields.includes('Instructions')">
                <label class="col-md-2 col-form-label">Instructions</label>
                <div class="col-md-10">
                    <textarea name="Comments"
                              rows="4"
                              cols="50"
                              #name="ngModel"
                              [(ngModel)]="order.Comments"
                              [required]="requiredFields.includes('Comments')">
                    </textarea>
                </div>
            </div>

            <div class="form-group row" *ngIf="activeFields.includes('CITES')">
                <label class="col-md-2 col-form-label">CITES</label>
                <div class="col-md-10">
                    <input type="text" name="Cites"
                           class="form-control input-medium"
                           maxlength="200"
                           #name="ngModel"
                           [(ngModel)]="order.Cites"
                           [required]="requiredFields.includes('Cites')" />
                </div>
            </div>

            <div class="form-group row">
                <label class="col-md-2 col-form-label">Files</label>
                <div class="col-md-10">
                    <div class="form-control-plaintext">
                        <facet-detail-file-upload [pkName]="'C_Order_key'"
                                                  [pkValue]="order.C_Order_key"
                                                  [facetPrivilege]="facet.Privilege">
                        </facet-detail-file-upload>
                    </div>
                </div>
            </div>

            <ng-container *ngIf="subOrder && order.cv_MaterialType && order.cv_MaterialType.MaterialType === 'Sample'">
                <sample-order-detail [order]="order"
                                     [sampleOrder]="subOrder">
                </sample-order-detail>
            </ng-container>
            <order-animal-table *ngIf="order.cv_MaterialType?.MaterialType === 'Animal'"
                                [taxonKey]="order.C_Taxon_key"
                                (onAnimalCountChanged)="tableAnimalCount = $event"
                                [pkValue]="order.C_Order_key"
                                [entity]="order"
                                [activeFields]="animalOrderActiveFields"
                                [requiredFields]="animalOrderRequiredFields">
            </order-animal-table>
            <div class="animal-section form-group"
                 *ngIf="order.cv_MaterialType?.MaterialType === 'Animal' && activeFields.includes('Animal Receipt Check')">
                <div class="col-md-12">
                    <table class="table">
                        <thead>
                            <tr class="">
                                <th>Housing</th>
                                <th>Water Available</th>
                                <th>Food Available</th>
                                <th>Bedding Available</th>
                                <th>All Shipment Containers Confirmed Empty</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <input type="checkbox"
                                           (click)="onAnimalSectionClick('Single', 'AnimalHousing')"
                                           [checked]="order.AnimalHousing === 'Single'"
                                           id="single"
                                           name="housing" />
                                    <label for="single">Single</label>
                                </td>
                                <td>
                                    <input type="checkbox"
                                           (click)="onAnimalSectionClick(true, 'IsWaterAvailable')"
                                           [checked]="order.IsWaterAvailable === true"
                                           id="water_yes"
                                           name="water" />
                                    <label for="water_yes">Yes</label>
                                </td>
                                <td>
                                    <input type="checkbox"
                                           (click)="onAnimalSectionClick(true, 'IsFoodAvailable')"
                                           [checked]="order.IsFoodAvailable === true"
                                           id="food_yes"
                                           name="food" />
                                    <label for="food_yes">Yes</label>
                                </td>
                                <td>
                                    <input type="checkbox"
                                           (click)="onAnimalSectionClick(true, 'IsBeddingAvailable')"
                                           [checked]="order.IsBeddingAvailable === true"
                                           id="bedding_yes"
                                           name="bedding" />
                                    <label for="bedding_yes">Yes</label>
                                </td>
                                <td>
                                    <input type="checkbox"
                                           (click)="onAnimalSectionClick(true, 'IsAllShipmentContainersEmpty')"
                                           [checked]="order.IsAllShipmentContainersEmpty === true"
                                           id="shipping_yes"
                                           name="shipping" />
                                    <label for="shipping_yes">Yes</label>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <input type="checkbox"
                                           (click)="onAnimalSectionClick('Social', 'AnimalHousing')"
                                           [checked]="order.AnimalHousing === 'Social'"
                                           id="social"
                                           name="housing" />
                                    <label for="social">Social</label>
                                </td>
                                <td>
                                    <input type="checkbox"
                                           (click)="onAnimalSectionClick(false, 'IsWaterAvailable')"
                                           [checked]="order.IsWaterAvailable === false"
                                           id="water_no"
                                           name="water" />
                                    <label for="water_no">No</label>
                                </td>
                                <td>
                                    <input type="checkbox"
                                           (click)="onAnimalSectionClick(false, 'IsFoodAvailable')"
                                           [checked]="order.IsFoodAvailable === false"
                                           id="food_no"
                                           name="food" />
                                    <label for="food_no">No</label>
                                </td>
                                <td>
                                    <input type="checkbox"
                                           (click)="onAnimalSectionClick(false, 'IsBeddingAvailable')"
                                           [checked]="order.IsBeddingAvailable === false"
                                           id="bedding_no"
                                           name="bedding" />
                                    <label for="bedding_no">No</label>
                                </td>
                                <td>
                                    <input type="checkbox"
                                           (click)="onAnimalSectionClick(false, 'IsAllShipmentContainersEmpty')"
                                           [checked]="order.IsAllShipmentContainersEmpty === false"
                                           id="shipping_no"
                                           name="shipping" />
                                    <label for="shipping_no">No</label>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </fieldset>
    </form>
</div>

import { UsersSharedModule } from './../user/users-shared.module';
import { DashboardViewComponent } from './dashboard-view.component';
import { ChartsModule } from './../charts/charts.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ClimbCommonModule } from '../common/climb-common.module';
import { ClimbServicesModule } from '../services/climb-services.module';
import { FeatureFlagService } from '../services/feature-flags.service';
import { DashboardDataService } from './services/dashboard-data.service';
import { ChartLineComponent } from './components/chart-line/chart-line.component';
import { InsightCardComponent } from './components/insight-card/insight-card.component';
import { InsightsComponent } from './components/insights/insights.component';
import { WorkspaceComponent } from './components/workspace/workspace.component';
import { WorkspaceCardComponent } from './components/workspace-card/workspace-card.component';
import { ReactiveFormsModule } from '@angular/forms';
import { TitleDirective } from '@common/directives/title.directive';

@NgModule({
    imports: [
        ReactiveFormsModule,
        CommonModule,
        ClimbCommonModule,
        ClimbServicesModule,
        ChartsModule,
        UsersSharedModule,
    ],
    declarations: [
        TitleDirective,
        ChartLineComponent,
        InsightCardComponent,
        InsightsComponent,
        DashboardViewComponent,
        WorkspaceComponent,
        WorkspaceCardComponent,
    ],
    exports: [
        DashboardViewComponent
    ],
    providers: [
        DashboardDataService,
        FeatureFlagService
    ]
})
export class DashboardModule { }

import {
    Component,
    EventEmitter,
    Input,
    Output
} from '@angular/core';

import { SearchService } from '../search/search.service';
import { SearchQueryDef } from '../search/search-query-def';

@Component({
    selector: 'institution-select',
    template: `
    <div>
        <climb-typeahead
            [(model)]="model"
            [resultFormatter]="institutionNameFormatter"
            [keyFormatter]="institutionKeyFormatter"
            [search]="searchInstitutions"
            [keySearch]="searchInstitutionsByKey"  
            [required]="required"
            [disabled]="disabled"
            [namespace]="'institution'"
            (selectItem)="selectItemHandler($event)"
            #typeahead
        ></climb-typeahead>
    </div>
    `,
    styles: [`
        .institution-select {
            min-width: 300px;
        }
    `]
})
export class InstitutionSelectComponent {
    @Input() model: any;
    @Output() modelChange: EventEmitter<any> = new EventEmitter<any>();

    @Input() required: boolean;
    @Input() disabled: boolean;

    constructor(
        private searchService: SearchService,
    ) {
    }

    selectItemHandler(item: any) {
        this.modelChange.emit(this.model);
    }

    searchInstitutions = (search: string): Promise<any[]> => {

        const filter = {
            Name: search,
            IsActive: true
        };

        let count = null;
        // if 2 characters or less, put a limit on number returned
        if (search.length < 3) {
            count = 200;
        }

        return this._searchInstitutions(filter, count);
    }

    searchInstitutionsByKey = (key: any): Promise<any[]> => {
        const filter = {
            InstitutionKey: key
        };
        return this._searchInstitutions(filter, 1);
    }

    _searchInstitutions(thisFilter: any, count: number): Promise<any[]> {
        const searchQueryDef: SearchQueryDef = {
            entity: 'Institutions',
            page: null,
            size: count,
            sortColumn: 'Name',
            sortDirection: 'asc',
            filter: thisFilter
        };
        return this.searchService.getEntitiesBySearch(searchQueryDef).then((results: any) => {
            return results.data;
        });
    }

    institutionNameFormatter = (item: any) => {
        return item.Name;
    }

    institutionKeyFormatter = (item: any) => {
        return item.InstitutionKey;
    }
}


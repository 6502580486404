/**
 * Sub-utility for parsing path string to array
 * @param path 
 */
export function pathStringToArray(path: string): string[] {
    if (!path) {
        return [];
    }
    // convert indexes to properties
    path = path.replace(/\[(\w+)\]/g, '.$1');           
    // strip a leading dot
    path = path.replace(/^\./, '');
    return path.split('.');
}

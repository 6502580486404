<div class="facet">
    <facet-header
        caption="Devices"
        [facetId]="facetId">
        <button data-automation-id="refresh-data-button"
                *ngIf="facetView === LIST_VIEW"
                climbFacetAction
                (click)="refreshData()"
                title="Refresh Data">
            <svg [climbIcon]="icons.arrowClockwise"></svg>
        </button>
    </facet-header>
    <div class="facet-body" *ngIf="facet.Privilege !== 'None'">

        <ng-container *ngIf="facetView === LIST_VIEW">
            <climb-facet-toolbar>
                <climb-export-dropdown #exportDropdown
                                       [options]="deviceTableOptions.options"
                                       [dataService]="dataService"
                                       [componentName]="componentName">
                </climb-export-dropdown>

                <climb-column-select [columnDef]="dataTableColumns$ | async"
                                     (onChange)="dataTable.columnSelectChanged($event)">
                </climb-column-select>
            </climb-facet-toolbar>
            <climb-data-table #dataTable
                              [dataService]="dataService"
                              [options]="deviceTableOptions.options"
                              [(gridStateJson)]="facet.GridState"
                              [(selectedRows)]="selectedRows"
                              (selectedColumnsChange)="selectedColumnsChange($event)"
                              (dragStart)="dragStart()"
                              (dragStop)="dragStop()"
                              (detailLinkClick)="detailLinkClick($event)"
                              (columnStateChanged)="dataTableColumns.next($event)"
                              (stateChange)="exportDropdown.tableState = $event">
            </climb-data-table>
        </ng-container>

        <device-detail *ngIf="facetView === DETAIL_VIEW"
                       [facet]="facet"
                       [device]="itemToEdit"
                       [pageState]="detailPaginator.pageState"
                       (exit)="exitDetail()"
                       (previous)="detailPreviousClicked()"
                       (next)="detailNextClicked()"></device-detail>

    </div>
</div>

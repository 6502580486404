import { AuthService } from '@services/auth.service';
import { InactivityTimeoutService } from '@services/inactivity-timeout.service';
import { FeatureFlagService } from '@services/feature-flags.service';
import { SSOService } from './sso/sso.service';
import {
    Component,
    OnInit,
} from '@angular/core';
import { Route, Router } from '@angular/router';
import { WebApiService } from '@services/web-api.service';
import { LocalStorageService } from '@services/local-storage.service';
import { LocalStorageKey } from '@config';
import { ToastrService } from '@services/toastr.service';
import { LogLevel } from '@services/models';
import { OverlayService } from '@services/overlay-service';
import { WorkspaceService } from './workspaces/workspace.service';
import { datadogRum } from '@datadog/browser-rum';
import { RouteEnum } from "./routing/route.enum";

/**
 * This is the entry point for Climb
 */
@Component({
    selector: 'climb-app',
    templateUrl: './climb-app.component.html',
    styleUrls: ['./climb-app.component.scss'],
})
export class ClimbAppComponent implements OnInit {
    overlayShown$ = this.overlayService.overlayShown$;

    constructor(
        private router: Router,
        private authService: AuthService,
        private ssoService: SSOService,
        private webApiService: WebApiService,
        private localStorageService: LocalStorageService,
        private toastrService: ToastrService,
        private workspaceService: WorkspaceService,
        private inactivityTimeoutService: InactivityTimeoutService,
        private featureFlagService: FeatureFlagService,
        private overlayService: OverlayService,
    ) { }

    async ngOnInit() {
        await this.initialize();
        this.webApiService.callStatusApi().then((data) => {
            const messages = JSON.parse(data);
            let notifications = JSON.parse(this.localStorageService.get(LocalStorageKey.ADMIN_MESSAGES));
            if (!notifications) {
                notifications = [];
            }
            const notificationIds = notifications.map((notification: any) => notification.C_StatusIO_key);
            messages.forEach((message: any) => {
                if (!notificationIds.includes(message.C_StatusIO_key)) {
                    notifications.push(message);
                }
            });
            notifications.forEach((notification: any) => {
                if (!notification.disabled) {
                    this.toastrService.showBanner(notification.Message, notification.Title, LogLevel.Error, () => {
                        this.setNotificationDisabled(notification.C_StatusIO_key);
                    });
                }
            });
            this.localStorageService.set(LocalStorageKey.ADMIN_MESSAGES, JSON.stringify(notifications));
        });

        datadogRum.init({
            applicationId: '18194dfd-9592-4cfc-a390-c34167fe8a0f',
            clientToken: 'pub81ad0374035d4457e21fb1bbb2a0fce0',
            site: 'datadoghq.com',
            service: 'climb-dev-local',
            //  env: 'production',
            //  version: '1.0.0',
            sampleRate: 100,
            trackInteractions: true,
            beforeSend: (event: any) => {
                datadogRum.setUser({
                    name: this.authService.getCurrentUserName(),
                    workgroupName: this.localStorageService.get(LocalStorageKey.WORKGROUP_NAME),
                    workgroupKey: this.localStorageService.get(LocalStorageKey.WORKGROUP_KEY)
                });

                datadogRum.setRumGlobalContext({
                    workgroupName: this.localStorageService.get(LocalStorageKey.WORKGROUP_NAME),
                    workgroupKey: this.localStorageService.get(LocalStorageKey.WORKGROUP_KEY),
                    username: this.authService.getCurrentUserName(),
                    facets: this.getOpenFacetNames()
                });
            },
        });

        datadogRum.addRumGlobalContext('workgroupInfo', {
            workgroupName: this.localStorageService.get(LocalStorageKey.WORKGROUP_NAME),
            workgroupKey: this.localStorageService.get(LocalStorageKey.WORKGROUP_KEY),
            username: this.authService.getCurrentUserName(),
            facets: this.getOpenFacetNames()
        });
    }

    getOpenFacetNames(): any[] {
        const workspace = this.workspaceService.getCurrentWorkspace();
        if (workspace && workspace.WorkspaceDetail) {
            return workspace.WorkspaceDetail.map((w: any) => {
                return w.FacetName;
            });
        } else {
            return [];
        }
    }

    async initialize() {
        this.authService.fillAuthData();

        // Start Inactivity Timer if IsGLP
        if (this.featureFlagService.getIsGLP()) {
            this.inactivityTimeoutService.startInactivityTimeout();
        }

        await this.routeHashRedirect();
    }

    async routeHashRedirect(): Promise<void> {
        // Navigate from all URLs with hash to Routes without hash
        if (location.href.includes('/#/')) {
            /**
             * E.g.
             * `https://climb-domain.net/#/emailverification?EmailVerificationCode=884a5013-80d4-4ede-b717-cce25f71f2e0`
             * navigates to route
             * `/emailverification?EmailVerificationCode=884a5013-80d4-4ede-b717-cce25f71f2e0`
             */
            const isNavigated = await this.router.navigateByUrl(location.hash.replace('#', ''));
            if (isNavigated) {
                return;
            }
        }

        if (location.href.includes('/#')) {
            /**
             * E.g.
             * `https://climb-domain.net/#samljwt=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCIsImN0eSI6IkpXVCJ9...
             * navigates to route
             * `/samljwt?samljwt=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCIsImN0eSI6IkpXVCJ9...
             */
            const routePath = location.hash.substring(1, location.hash.indexOf('='));
            if (this.routeExists(routePath)) {
                await this.router.navigateByUrl(location.hash.replace('#', `/${routePath}?`));
                return;
            }
            await this.ssoLoginSequence(location.hash);
            return;
        }
    }

    async ssoLoginSequence(hashFragment: string) {
        if (!this.authService.isAuthenticated()) {
            // Make sure old sso session id_token is gone before getting new one
            this.authService.clearAuthData();
            this.authService.clearSSOData();
            // Initial login sequence if not already logged in
            await this.ssoService.runInitialLoginSequence(hashFragment);
        }
    }

    private routeExists(path: string): boolean {
        return this.router.config.some((route: Route) => route.path === path);
    }

    private setNotificationDisabled(key: any) {
        const notifications = JSON.parse(this.localStorageService.get(LocalStorageKey.ADMIN_MESSAGES));
        if (notifications && notifications.length > 0) {
            notifications.forEach((notification: any) => {
                if (notification.C_StatusIO_key === key) {
                    notification.disabled = true;
                }
            });
            this.localStorageService.set(LocalStorageKey.ADMIN_MESSAGES, JSON.stringify(notifications));
        }
    }
}

import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'climb-logo',
  templateUrl: './logo.component.svg',
  styleUrls: ['./logo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogoComponent {
    @Input() sign = true;

    @Input() text = true;

    @Input() fill = 'var(--logo-primary)';

    get viewBox(): string {
        const sign = this.sign;
        const text = this.text;

        switch (true) {
            case sign && text: return '0 0 99 24';
            case sign && !text: return '0 0 24 24';
            case !sign && text: return '32 2 67 20';
        }
    }
}

import { SubParameterItem } from './sub-parameter-item';
export class ParamGroupItem {
    key: any;
    text: string;
    valueKey: any;
    extractValue: any;
    subParams: SubParameterItem[];

    /**
     * Enable field initializer
     *   E.g. new ParamGroupItem({ field1: 'value', ...})
     * @param init
     */
    public constructor(init?: Partial<ParamGroupItem>) {
        this.subParams = [];

        Object.assign(this, init);
    }
}

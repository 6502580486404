import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';

import { BaseFilter } from '@common/facet';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
    focusElementByQuery,
} from '@common/util';
import { VocabularyService } from '../../../vocabularies/vocabulary.service';
import { CohortService } from '../../services/cohort.service';

@Component({
    selector: 'cohort-filter',
    templateUrl: './cohort-filter.component.html'
})
export class CohortFilterComponent extends BaseFilter implements OnInit {
    @Input() filter: any = {};
    @Output() onFilter = new EventEmitter<any>();
    @Output() close = new EventEmitter<any>();

    readonly MULTI_PASTE_INPUT_LIMIT = 100;

    // CVs
    matchingMethods: any[] = [];
    allowSplitting = false;

    constructor(
        private activeModal: NgbActiveModal,
        private vocabularyService: VocabularyService,
        private cohortService: CohortService
    ) {
        super(activeModal);
    }

    ngOnInit() {
        this.cloneFilter();

        focusElementByQuery('[data-auto-focus="animalName"]');
        this.allowSplitting = this.cohortService.getCohortSplittingMethodFlag();
        if (this.allowSplitting) {
            this.getCVs();
        }
    }

    private cloneFilter() {
        this.filter = this.copyFilter(this.filter);
    }

    filterClicked(event: Event) {
        event.preventDefault();
        this.onFilter.emit(this.filter);
        this.close.emit();
        this.activeModal.close();
    }

    closeClicked() {
        this.close.emit();
        this.activeModal.dismiss();
    }

    clearClicked() {
        this.filter = {};
        this.onFilter.emit(this.filter);
        this.close.emit();
        this.activeModal.close();
    }

    private getCVs() {
        this.vocabularyService.getCV('cv_MatchingMethods').then((data: any) => {
            this.matchingMethods = data;
        });
    }
}

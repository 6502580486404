import type { Mating } from './mating.interface';

export interface cv_MatingType {
    C_MatingType_key: number;
    C_VocabularySource_key: number | null;
    C_Workgroup_key: number;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    ElementID: string;
    IsActive: boolean;
    IsDefault: boolean;
    IsDeprecated: boolean;
    IsLineOptional: boolean;
    Mating: Mating[];
    MatingType: string;
    ModifiedBy: string;
    SortOrder: number | null;
    Version: number;
}

import {
    Component,
    EventEmitter,
    Input,
    Output
} from '@angular/core';

import {
    NgbActiveModal
} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'inactivity-timeout-modal',
    templateUrl: './inactivity-timeout-modal.component.html'
})
export class InactivityTimeoutModalComponent {

    @Input() countdown = 0;
    @Output() stayAndContinue = new EventEmitter();
    @Output() logoutNow = new EventEmitter();

    constructor(
        private activeModal: NgbActiveModal
    ) { }


    onLogoutNow() {
        this.logoutNow.emit();
    }

    onStay() {
        this.stayAndContinue.emit();
    }
}

<form role="form" (keyup.enter)="okClicked($event)" class="form-label-right">
    <div class="modal-header justify-content-center">
        <h4 class="modal-title">Characteristics Filter</h4>
    </div>

    <div class="modal-body">
        <div class="form-group row">
            <label class="col-md-3 col-form-label">Name</label>
            <div class="col-md-9">
                <input type="text"
                       name="name"
                       [(ngModel)]="filter.Name"
                       class="form-control"
                       data-auto-focus="characteristicsFilter" />
            </div>
        </div>

        <div class="form-group row" *ngIf="typeName === characteristicTypeName.Sample">
            <label class="col-md-3 col-form-label">Sample Type</label>
            <div class="col-md-9">
                <climb-multi-select [(model)]="filter.C_SampleType_keys"
                                    [values]="sampleTypes"
                                    [keyProperty]="'C_SampleType_key'"
                                    [valueProperty]="'SampleType'"
                                    [placeholder]="'Select Types...'"></climb-multi-select>
            </div>
        </div>

        <div class="form-group row" *ngIf="typeName === characteristicTypeName.Job">
            <label class="col-md-3 col-form-label">IACUC Protocol</label>
            <div class="col-md-9">
                <climb-multi-select [(model)]="filter.C_IACUCProtocol_keys" [values]="iacucProtocols"
                                    [keyProperty]="'C_IACUCProtocol_key'" [valueProperty]="'IACUCProtocol'"
                                    [placeholder]="'Select protocols...'"></climb-multi-select>
            </div>
        </div>

        <div class="form-group row" *ngIf="!isCRO && typeName === characteristicTypeName.Job">
            <label class="col-md-3 col-form-label">{{('Job' | translate) + ' Type'}}</label>
            <div class="col-md-9">
                <climb-multi-select [(model)]="filter.C_JobType_keys"
                                    [values]="jobTypes"
                                    [keyProperty]="'C_JobType_key'"
                                    [valueProperty]="'JobType'"
                                    [placeholder]="'Select Types...'"></climb-multi-select>
            </div>
        </div>

        <div class="form-group row" *ngIf="isCRO && typeName === characteristicTypeName.Job">
            <label class="col-md-3 col-form-label">{{('Job' | translate) + ' Subtype'}}</label>
            <div class="col-md-9">
                <climb-multi-select [(model)]="filter.C_JobSubtype_keys"
                                    [values]="jobSubtypes"
                                    [keyProperty]="'C_JobSubtype_key'"
                                    [valueProperty]="'JobSubtype'"
                                    [placeholder]="'Select Subtypes...'"></climb-multi-select>
            </div>
        </div>

        <div class="form-group row" *ngIf="typeName === characteristicTypeName.Study">
            <label class="col-md-3 col-form-label">{{('Study' | translate) + ' Type'}}</label>
            <div class="col-md-9">
                <climb-multi-select [(model)]="filter.C_StudyType_keys"
                                    [values]="studyTypes"
                                    [keyProperty]="'C_StudyType_key'"
                                    [valueProperty]="'StudyType'"
                                    [placeholder]="'Select Types...'"></climb-multi-select>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-3 col-form-label">Active</label>
            <div class="col-md-9">
                <climb-multi-select [(model)]="filter.Actives"
                                    [values]="booleanValues"
                                    [keyProperty]="'Value'"
                                    [valueProperty]="'Value'"
                                    [placeholder]="'Select...'"></climb-multi-select>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-3 col-form-label">Last Reviewed Range</label>
            <div class="col-md-4">
                <climb-ngb-date #dateControl="ngModel"
                                name="Last Reviewed Start"
                                [(ngModel)]="filter.DateLastReviewedStart"
                                placeholder="From&hellip;"
                                ngDefaultControl></climb-ngb-date>
            </div>
            <div class="col-md-4">
                <climb-ngb-date #dateControl="ngModel"
                                name="Last Reviewed End"
                                [(ngModel)]="filter.DateLastReviewedEnd"
                                placeholder="To&hellip;"
                                ngDefaultControl></climb-ngb-date>
            </div>
        </div>
    </div><!-- /.modal-body -->

    <div class="modal-footer">
        <span class="btn-set">
            <button type="button"
            class="btn btn-fw-wide btn-lg btn-primary"
            (click)="okClicked($event)">
            Filter
            </button>
            <button data-automation-id="clear-filter-button"
                    type="button"
                    class="btn btn-fw-wide btn-lg btn-secondary"
                    (click)="clearClicked()">
                Clear Filter
            </button>
        </span>
        <span class="btn-set">
            <button type="button"
                    class="btn btn-lg btn-secondary"
                    (click)="closeClicked()">
                Close
            </button>
        </span>
    </div>
</form>

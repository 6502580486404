import {
    Component,
    EventEmitter,
    Input,
    Output
} from '@angular/core';
import { SearchService } from '../search/search.service';

@Component({
    selector: 'location-multiselect',
    template: `
<!-- Selected Locations -->
<div *ngIf="model && model.length > 0">
    <table class="table table-sm table-borderless table-nonfluid">
        <tbody>
            <tr *ngFor="let item of model; let i = index;">
                <td class="icon-cell">
                    <a (click)="removeLocation(i)" title="Remove Location">
                        <i class="fa fa-remove remove-item"></i>
                    </a>
                </td>
                <td>
                    {{item.PositionName}}
                </td>
            </tr>
        </tbody>
    </table>
</div>

<!-- Location typeahead -->
<climb-indirect-typeahead
        [search]="searchLocations"
        [resultFormatter]="positionNameFormatter"
        placeholder="Location&hellip;"
        (selectItem)="selectLocation($event)"></climb-indirect-typeahead>
`,
    styles: [`
        .table {
            margin-bottom: 0.25em;
        }
    `]
})
export class LocationMultiselectComponent {
    @Input() model: any[];
    @Output() modelChange: EventEmitter<any> = new EventEmitter<any>();

    constructor(
        private searchService: SearchService
    ) { }

    removeLocation(index: number) {
        if (this.model && index >= 0 && index < this.model.length) {
            this.model.splice(index, 1);
        }
        this.modelChange.emit(this.model);
    }

    searchLocations = (term: string): Promise<any> => {
        return this.searchService.searchLocations(term).then((results) => {
            // Convert Breeze entity to simple object
            // so it can be serialized by consumers
            return results.data.map((location: any) => {
                return {
                    C_Location_key: location.C_Location_key,
                    PositionName: location.PositionName
                };
            });
        });
    }

    positionNameFormatter = (value: any) => {
        return value.PositionName;
    }

    selectLocation(location: any) {
        if (this.model) {
            this.model.push(location);
        } else {
            this.model = [location];
        }
        this.modelChange.emit(this.model);
    }
}

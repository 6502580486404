import { Component, ChangeDetectionStrategy, Input, HostBinding, AfterViewInit } from '@angular/core';
import { ModernAppService } from '@common/modern-app.service';
import { Facet, isWorkspaceDetails } from 'src/app/mf-store';

@Component({
  selector: 'climb-modern-facet-factory',
  template: '',
  styleUrls: ['./modern-facet-factory.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModernFacetFactoryComponent implements AfterViewInit {
  @Input() facet: Facet;

  @HostBinding('id')
  get elementId() {
    return this.facet.id;
  }

  constructor(private modernApp: ModernAppService) {}

  ngAfterViewInit(): void {
    const facet = this.facet.facet;
    const componentId = isWorkspaceDetails(facet) ? facet.FacetName : (facet as ModernFacet).componentId;
    this.modernApp.openFacet(this.elementId, componentId);
  }
}

<table class="table table-bordered job-pharma-animals-cohort">
    <thead>
        <tr class="detail-grid-header">
            <!-- Select -->
            <th *ngIf="!readonly" class="icon-cell  small-width">
                <input type="checkbox"
                       name="selectAll"
                       [(ngModel)]="allSelected"
                       (ngModelChange)="allSelectedChanged()"
                       title="Select/Clear all" />
            </th>

            <!-- Remove -->

            <th *ngIf="!readonly" class="icon-cell small-width">
                <a class="detail-grid-button"
                   (click)="onPaste()"
                >
                    <i data-automation-id="paste-cohorts-button"
                       class="fa fa-paste" 
                       title="Paste Cohorts"></i>
                </a>
            </th>

            <!-- Open Model -->

            <th *ngIf="!readonly" class="icon-cell  small-width">
                <button class="btn btn-link detail-grid-btn-icon"
                   (click)="openJobPharmaModal()"
                   [disabled]="taskNumber === 0 || jobCohorts.length === 0"
                   *ngIf="!readonly">
                    <i class="fa fa-arrows-alt" title="Assign cohorts to tasks"></i>
                </button>
            </th>

            <!-- Name -->
            <th *ngIf="visible.cohort"
                droppable
                (drop)="onDrop()"
                [dropDisabled]="readonly">
                Cohort
            </th>
            <th *ngIf="isCRO">Placeholder</th>
            <th *ngIf="visible.description">Description</th>
            <th *ngIf="visible.animals">Animals</th>
        </tr>
    </thead>
    <ng-template></ng-template>
    <loading-overlay *ngIf="loading" [message]="loadingMessage"></loading-overlay>
    <tbody *ngIf="!loading">
        <tr *ngFor="let jobCohort of jobCohorts; let index = index;trackBy: trackRow"
            [attr.data-key]="jobCohort.C_Cohort_key"
            [ngClass]="{'selected-row': jobCohort.isSelected}"
            class="cohort-row">
            <ng-container *ngIf="jobCohort.Cohort as cohort">

                <!-- Select -->
                <td *ngIf="!readonly"
                    class="icon-cell">
                    <input type="checkbox"
                           name="jobCohortIsSelected{{index}}"
                           [(ngModel)]="jobCohort.isSelected"
                           (ngModelChange)="isSelectedChanged()" />
                </td>

                <!-- Remove -->
                <td class="icon-cell"
                    *ngIf="!readonly">
                    <a (click)="removeJobCohort(jobCohort)" class="remove-cohort">
                        <i class="fa fa-remove remove-item" title="Remove Cohort"></i>
                    </a>
                </td>

                <td *ngIf="!readonly">&nbsp;</td>

                <!-- Cohort -->
                <td *ngIf="visible.cohort">
                    <div id="drag-animals-cohort-{{cohort.C_Cohort_key}}"
                         class="cohort-badge"
                         [ngStyle]="{ backgroundColor: cohort.BackgroundColor, color: cohort.ForegroundColor }"
                         draggable
                         data-drag-type="cohort"
                         (dragStart)="dragStart()"
                         (dragStop)="dragStop()"
                         [dragDisabled]="!jobCohort.isSelected">
                        {{cohort.CohortName}}
                    </div>
                </td>

                <!-- Placeholder -->
                <td *ngIf="isCRO">
                    <ng-container *ngFor="let placeholder of jobCohort.Placeholder">
                        <div class="cohort-badge">
                            {{placeholder?.PlaceholderName}}
                        </div>
                    </ng-container>
                </td>

                <!-- Description -->
                <td *ngIf="visible.description">
                    {{cohort.Description}}
                </td>

                <!-- Animals -->
                <td *ngIf="visible.animals" class="cohort-animal-count">
                    {{jobCohort.animalCounts?.inJob}}/{{jobCohort.animalCounts?.total}}
                </td>
            </ng-container>
        </tr>
    </tbody>
</table>

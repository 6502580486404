import {
    Component,
    Input,
    OnInit,
} from '@angular/core';

import { TableSort } from '../../common/models';

@Component({
    selector: 'location-animal-table',
    templateUrl: './location-animal-table.component.html'
})
export class LocationAnimalTableComponent implements OnInit {
    @Input() materialPools: any[];
    @Input() tableSort: TableSort;


    constructor() {
        // do nothing
    }

    ngOnInit() {
        this.initialize();
    }

    initialize() {
        if (!this.materialPools) {
            return;
        }
    }
}

import {
    Component,
    Input,
    OnInit
} from '@angular/core';

import {
    NgbActiveModal
} from '@ng-bootstrap/ng-bootstrap';

import { AuditService } from '../../audit/audit.service';
import { ExportAnimalAuditService } from './export-animal-audit.service';
import { AnimalService } from '../services/animal.service';
import { FeatureFlagService } from '../../services/feature-flags.service';

import {
    getSafeProp
} from '../../common/util/';

@Component({
    selector: 'animal-audit-report',
    templateUrl: './animal-audit-report.component.html'
})
export class AnimalAuditReportComponent implements OnInit {
    @Input() materialKey: any;

    animal: any;
    auditData: any[] = [];
    auditJobsData: any[] = [];
    auditGenotypesData: any[] = [];
    auditClinicalObservationsData: any[] = [];
    auditCurrentTasksData: any[] = [];

    loading = false;
    isGLP = false;

    readonly noDataMessage: string = 'No changes.';

    constructor(
        private activeModal: NgbActiveModal,
        private auditService: AuditService,
        private exportAnimalAuditService: ExportAnimalAuditService,
        private animalService: AnimalService,
        private featureFlagService: FeatureFlagService,
    ) {}

    ngOnInit() {
        this.initIsGLP();
        this.getData();
    }

    getData(): Promise<void> {

        const p1 = this.animalService.getAnimal(this.materialKey)
            .then((data: any) => {
                this.animal = data;
            });

        const p2 = this.auditService.getAnimalAuditData(this.materialKey)
            .then((response: any) => {
                this.auditData = getSafeProp(response, 'data.results') || [];
            });

        const p3 = this.auditService.getAnimalAuditJobsData(this.materialKey)
            .then((response: any) => {
                this.auditJobsData = getSafeProp(response, 'data.results') || [];
            });

        const p4 = this.auditService.getAnimalAuditGenotypesData(this.materialKey)
            .then((response: any) => {
                this.auditGenotypesData = getSafeProp(response, 'data.results') || [];
            });

        const p5 = this.auditService.getAnimalAuditClinicalObservationsData(this.materialKey)
            .then((response: any) => {
                this.auditClinicalObservationsData = getSafeProp(response, 'data.results') || [];
            });

        const p6 = this.auditService.getCurrentAnimalAuditTasksData(this.materialKey)
            .then((response: any) => {
                this.auditCurrentTasksData = getSafeProp(response, 'data.results') || [];
            });

        this.loading = true;
        return Promise.all([p1, p2, p3, p4, p5, p6]).then(() => {
            this.loading = false;
        }).catch((error) => {
            this.loading = false;
            throw error;
        });
    }

    onCancel(): void {
        this.activeModal.dismiss();
    }

    exportAudit(): void {
        this.exportAnimalAuditService.exportToCsv(
            this.auditData,
            this.auditJobsData,
            this.auditGenotypesData,
            this.auditClinicalObservationsData,
            this.auditCurrentTasksData,
            this.isGLP,
        );
    }

    
    initIsGLP() {
        this.isGLP = this.featureFlagService.isFlagOn('IsGLP');
    }
}

<ng-template #statusAddTmpl let-animal="item">
    <active-vocab-select [(model)]="animal.C_AnimalStatus_key"
                            [vocabChoices]="animalStatuses"
                            [keyFormatter]="animalStatusKeyFormatter"
                            [optionFormatter]="animalStatusFormatter">
    </active-vocab-select>
</ng-template>

<ng-template #healtTechTmpl let-animal="item">
    <active-vocab-select [(model)]="animal.C_Resource_key"
                         [vocabChoices]="resources"
                         [keyFormatter]="resourceKeyFormatter"
                         [optionFormatter]="resourceNameFormatter"
                         [defaultValue]="defaultResourceKey"
                         [loadDefaultValue]="true">
    </active-vocab-select>
</ng-template>


<ng-template #identifierTmpl let-treatment="item">
    {{treatment.AnimalHealthRecord.Animal.Material?.Identifier}}
</ng-template>

<ng-template #animalNameTmpl let-treatment="item">
    {{treatment.AnimalHealthRecord.Animal.AnimalName}}
</ng-template>

<ng-template #animalLocationTmpl let-treatment="item">
    {{treatment.AnimalHealthRecord.Animal.Material?.CurrentLocationPath}}
</ng-template>

<ng-template #animalStudyTmpl let-treatment="item">
    <span *ngIf="treatment.AnimalHealthRecord.Animal.Material?.JobMaterial | notEmpty">
        {{treatment.AnimalHealthRecord.Animal.Material?.JobMaterial | map:'Job' | map:'JobID' | delimit:', '}}
    </span>
</ng-template>

<ng-template #animalBirthDateTmpl let-treatment="item">
    {{treatment.AnimalHealthRecord.Animal.DateBorn | formatShortDate}}
</ng-template>

<ng-template #animalSexTmpl let-treatment="item">
    <span *ngIf="treatment.AnimalHealthRecord.Animal.cv_Sex">{{treatment.AnimalHealthRecord.Animal.cv_Sex.Sex}}</span>
</ng-template>

<ng-template #planTmpl let-treatment="item">
    <textarea *ngIf="treatment.TaskInstance.TaskInput | notEmpty"
                name="inputValue-{{treatment.TaskInstance.C_TaskInstance_key}}"
                [(ngModel)]="treatment.TaskInstance.TaskInput[0].InputValue"
                class="form-control
                input-large"></textarea>
</ng-template>

<ng-template #dueDateTmpl let-treatment="item">
    <climb-ngb-date #dateControl="ngModel"
                    name="Due Date"
                    [(ngModel)]="treatment.TaskInstance.DateDue"
                    [allowTime]="true"></climb-ngb-date>
</ng-template>

<ng-template #assignedToTmpl let-treatment="item">
    <active-vocab-select [(model)]="treatment.TaskInstance.C_AssignedTo_key"
                        [vocabChoices]="resources"
                        [keyFormatter]="resourceKeyFormatter"
                        [optionFormatter]="resourceNameFormatter"></active-vocab-select>
</ng-template>

<ng-template #commentsTmpl let-treatment="item">
    <textarea [(ngModel)]="treatment.TaskInstance.Comments"
                name="taskComments-{{treatment.C_TaskInstance_key}}"
                class="form-control input-large"></textarea>
</ng-template>

<ng-template #completeTmpl let-treatment="item" let-section="section">
    <ng-container *ngIf="section === BulkEditSection.EditHeader; else inputCell">
        Mark as Complete
        <input type="checkbox"
                   [(ngModel)]="complete"
                   name="complete" />
    </ng-container>
    <ng-template #inputCell>
        <task-complete-button *ngIf="treatment.TaskInstance" [task]="treatment.TaskInstance"></task-complete-button>
    </ng-template>
</ng-template>

<ng-template #completeDateTmpl let-treatment="item">
    <climb-ngb-date #dateControl="ngModel"
                    name="Date Complete"
                    [(ngModel)]="treatment.TaskInstance.DateComplete"
                    [allowTime]="true"></climb-ngb-date>
</ng-template>

<ng-template #completedByTmpl let-treatment="item">
    <active-vocab-select [(model)]="treatment.TaskInstance.C_CompletedBy_key"
                        [vocabChoices]="resources"
                        [keyFormatter]="resourceKeyFormatter"
                        [optionFormatter]="resourceNameFormatter"></active-vocab-select>
</ng-template>

<ng-template #taskStatusTmpl let-treatment="item">
    <active-vocab-select [(model)]="treatment.TaskInstance.C_TaskStatus_key"
                        [vocabChoices]="taskStatuses"
                        [keyFormatter]="taskStatusKeyFormatter"
                        [optionFormatter]="taskStatusFormatter"></active-vocab-select>
</ng-template>

<ng-template #animalSpeciesTmpl let-treatment="item">
    <span *ngIf="treatment.AnimalHealthRecord.Animal.Material?.cv_Taxon">{{treatment.AnimalHealthRecord.Animal.Material?.cv_Taxon.CommonName}}</span>
</ng-template>

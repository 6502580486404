import { NgxPaginationModule } from 'ngx-pagination';
import { UsersSharedModule } from './../user/users-shared.module';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { BaseComponentsModule } from '@common/components/base-components.module';
import { BasePipesModule } from '@common/pipes/base-pipes.module';
import { IconModule } from '@icons';
import { ClimbServicesModule } from '../services/climb-services.module';
import { TypeaheadModule } from '@common/typeahead/typeahead.module';

import { MessageCenterPanelComponent } from './message-center-panel.component';
import { MessageMapLabelComponent } from './message-map-label.component';
import { NewMessageComponent } from './new-message.component';
import { RecipientSelectComponent } from './recipient-select.component';

import { MessagePanelService } from './message-panel.service';
import { MessageService } from './message.service';

import { MessageCenterIconComponent } from './message-center-icon.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgbModule,
        NgxPaginationModule,
        ClimbServicesModule,
        BaseComponentsModule,
        BasePipesModule,
        IconModule,
        TypeaheadModule,
        UsersSharedModule
    ],
    declarations: [
        MessageCenterPanelComponent,
        MessageCenterIconComponent,
        MessageMapLabelComponent,
        NewMessageComponent,
        RecipientSelectComponent
    ],
    exports: [
        MessageCenterPanelComponent,
        MessageCenterIconComponent,
        MessageMapLabelComponent,
        NewMessageComponent,
        RecipientSelectComponent
    ]
})
export class MessagesSharedModule { 
    static forRoot(): ModuleWithProviders<MessagesSharedModule> {
        return {
          ngModule: MessagesSharedModule,
          providers: [
            MessagePanelService,
            MessageService
          ]
        };
    }
}

import { FacetSharedModule } from './../common/facet/facet-shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { ClimbCommonModule } from '../common/climb-common.module';
import { ClimbServicesModule } from '../services/climb-services.module';
import { WorkspacesSharedModule } from '../workspaces/workspaces-shared.module';

import { ImportFacetComponent } from './import-facet.component';
import { ImportDetailComponent } from './import-detail.component';
import { NewImportComponent } from './new-import.component';

import { ImportFilterComponent } from './import-filter.component';
import {
    ImportFileDefinitionEditComponent
} from './import-file-definition-edit.component';

import {
    ImportFileDefinitionSelectComponent
} from './forms/import-file-definition-select.component';
import { ImportFileFormatSelectComponent } from './forms/import-file-format-select.component';
import { ImportFileTypeSelectComponent } from './forms/import-file-type-select.component';

import { ImportService } from './import.service';
import { ImportVocabService } from './import-vocab.service';
import { ImportFileDefinitionService } from './import-file-definition.service';
import { ImportMappingTargetService } from './import-mapping-target.service';
import { ImportSharedModule } from './import-shared.module';
import { VocabulariesSharedModule } from '../vocabularies/vocabularies-shared.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgbModule,
        ClimbCommonModule,
        ClimbServicesModule,
        FacetSharedModule,
        WorkspacesSharedModule,
        ImportSharedModule,
        VocabulariesSharedModule
    ],
    declarations: [
        ImportFacetComponent,
        ImportDetailComponent,
        NewImportComponent,
        ImportFilterComponent,
        ImportFileDefinitionEditComponent,
        ImportFileDefinitionSelectComponent,
        ImportFileFormatSelectComponent,
        ImportFileTypeSelectComponent
    ],
    exports: [
        ImportFacetComponent
    ],
    providers: [
        ImportService,
        ImportVocabService,
        ImportFileDefinitionService,
        ImportMappingTargetService
    ]
})
export class ImportModule { }

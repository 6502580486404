import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import {
    EntityQuery,
    FilterQueryOp,
    Predicate,
} from 'breeze-client';

import { AdminManagerService } from '../services/admin-manager.service';
import { DataManagerService } from '../services/data-manager.service';
import { AuthService } from '../services/auth.service';
import { CurrentWorkgroupService } from '../services/current-workgroup.service';
import { BaseEntityService } from '../services/base-entity.service';
import { xCacheRefresh } from '@services/breeze-helpers';
import { batchArray } from '../common/util';

@Injectable()
export class MessageService extends BaseEntityService {

    private newMessageCountSource = new Subject<number>();
    newMessageCount$ = this.newMessageCountSource.asObservable();


    constructor(
        private adminManager: AdminManagerService,
        private dataManager: DataManagerService,
        private authService: AuthService,
        private currentWorkgroupService: CurrentWorkgroupService
    ) {
        super();
    }

    async getUserMessages(searchText: string, showDismissed: boolean): Promise<any[]> {
        const predicates: Predicate[] = [];

        const userId = this.authService.getCurrentUserId();
        predicates.push(Predicate.create('C_ToUser_key', '==', userId));
        const workgroupKey = this.currentWorkgroupService.getCurrentWorkgroupKey();
        predicates.push(Predicate.create('C_Workgroup_key', '==', workgroupKey));

        if (!showDismissed) {
            predicates.push(Predicate.create('IsDismissed', '==', false));
        }

        if (searchText.length > 0) {
            const predSubject = Predicate.create('MessageText', FilterQueryOp.Contains, { value: searchText });
            const predMessageText = Predicate.create('Subject', FilterQueryOp.Contains, { value: searchText });
            predicates.push(predSubject.or(predMessageText));
        }

        const expands = [
            'ToUser',
            'FromUser'
        ];

        const query = EntityQuery.from('Messages')
            .expand(expands.join(','))
            .where(Predicate.and(predicates));

        try {
            const response = await this.adminManager.executeQuery(query);
            const messages = response.results as any[];
            await this.getMessageMapData(messages);
            return messages;
        } catch (error) {
            return this.adminManager.queryFailed(error);
        }
    }


    getSentMessages(searchText: string): Promise<any[]> {
        const predicates: Predicate[] = [];

        const userId = this.authService.getCurrentUserId();
        predicates.push(Predicate.create('C_FromUser_key', '==', userId));
        const workgroupKey = this.currentWorkgroupService.getCurrentWorkgroupKey();
        predicates.push(Predicate.create('C_Workgroup_key', '==', workgroupKey));

        if (searchText.length > 0) {
            predicates.push(Predicate.create('MessageText', FilterQueryOp.Contains, { value: searchText }));
        }

        const expands = [
            'ToUser',
            'FromUser'
        ];

        const query = EntityQuery
            .from('Messages')
            .expand(expands.join(','))
            .where(Predicate.and(predicates))
            .orderBy("DateGenerated DESC");

        return this.adminManager.returnQueryResults(query);
    }

    async updateNewMessageCount(): Promise<void> {
        const count = await this.getNewMessageCount();
        this.newMessageCountSource.next(count);
    }

    getNewMessageCount(): Promise<number> {
        const predicates: Predicate[] = [];

        const userId = this.authService.getCurrentUserId();
        predicates.push(Predicate.create('C_ToUser_key', '==', userId));

        const workgroupKey = this.currentWorkgroupService.getCurrentWorkgroupKey();
        predicates.push(Predicate.create('C_Workgroup_key', '==', workgroupKey));

        predicates.push(Predicate.create('IsDismissed', '==', false));

        const query = EntityQuery
            .from('Messages')
            .where(Predicate.and(predicates))
            .take(0)
            .inlineCount(true)
            .withParameters(xCacheRefresh);

        return this.adminManager.returnQueryCount(query);
    }

    createMessage(toUserKey: number, subject: string, messageText: string): any {
        const newMessage: any = this.adminManager.createEntity('Message');

        newMessage.C_ToUser_key = toUserKey;
        newMessage.C_FromUser_key = this.authService.getCurrentUserId();
        newMessage.C_Workgroup_key = this.currentWorkgroupService.getCurrentWorkgroupKey();

        newMessage.Subject = subject;
        newMessage.MessageText = messageText;

        newMessage.DateGenerated = Date.now();
        newMessage.IsDismissed = false;

        return newMessage;
    }

    createMessageMap(initialValues: any): any {
        return this.dataManager.createEntity('MessageMap', initialValues);
    }


    /**
     * Queries any MessageMap records for each Message in messages.
     * Attaches matched MessageMap objects to each Message via
     * message.messageMaps property
     * @param messages
     */
    getMessageMapData(messages: any[]): Promise<void[]> {
        const batchSize = 50;
        const promises: Promise<void>[] = [];
        for (const batch of batchArray(messages, batchSize)) {
            this._getMessageMapDataBatch(batch);
        }

        return Promise.all(promises);
    }

    private async _getMessageMapDataBatch(messages: any[]): Promise<void> {
        const messageKeys: number[] = messages.map((message) => {
            return message.C_Message_key;
        });
        const predicates = Predicate.create('C_Message_key', 'in', messageKeys);

        const expands = [
            'Job',
            'Material.Animal',
            'Material.Sample',
            'Genotype',
            'TaskInstance',
            'Line'
        ];

        const query = EntityQuery
            .from('MessageMaps')
            .expand(expands.join(','))
            .where(Predicate.and(predicates));

        const results = await this.dataManager.returnQueryResults(query);
        // attach MessageMaps to original Message
        for (const message of messages) {
            message.messageMaps = results.filter((messageMap) => {
                return message.C_Message_key === messageMap.C_Message_key;
            });
        }
    }

}

import { Pipe, PipeTransform } from '@angular/core';

import { notEmpty } from '../../common/util';

import { SDLine } from '../models';

/**
 * Returns SDLines for a specified taxon
 */
@Pipe({
    name: 'lineTaxonFilter',
    pure: false
})
export class LineTaxonFilterPipe implements PipeTransform {

    transform(lines: SDLine[], taxon: string): any[] {
        let filtered: SDLine[] = [];

        if (notEmpty(lines)) {
            filtered = lines.filter((line: SDLine) => {
                return line.taxon === taxon;
            });
        }

        return filtered;
    }
}

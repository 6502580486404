import { Pipe, PipeTransform } from '@angular/core';

import { notEmpty } from '../util';

interface ProtocolTask {
    C_ProtocolTask_key: number;
}

/**
 * Returns protocol tasks not related to a parent task
 */
@Pipe({
    name: 'relativeTask',
    pure: false
})
export class RelativeTaskPipe implements PipeTransform {

    transform<T extends ProtocolTask>(items: T[], parentKey: number): T[] {
        let filtered: T[] = [];

        if (notEmpty(items)) {
            filtered = items.filter((protocolTask) => {
                return protocolTask.C_ProtocolTask_key > 0 &&
                    protocolTask.C_ProtocolTask_key !== parentKey;
            });
        }

        return filtered;
    }
}

import { notEmpty, getSafeProp } from "../../util";

/**
 * Mark sorted results by the groupBy property
 *   to determine rowSpans.
 * 
 *  Assigns dt_isDuplicate, and dt_rowSpan count for each row
 * @param results 
 */
export function applyGroupByMarkers(results: any[], groupBy: string) {
    if (!notEmpty(results)) {
        return results;
    }

    let groupRep = results[0];
    groupRep.dt_rowSpan = 1;
    groupRep.dt_isDuplicate = false;
    for (let i = 1; i < results.length; i++) {
        const nextRow = results[i];
        if (getSafeProp(nextRow, groupBy) === getSafeProp(groupRep, groupBy)) {
            nextRow.dt_isDuplicate = true;
            nextRow.dt_rowSpan = 0;
            groupRep.dt_rowSpan += 1;
        } else {
            groupRep = nextRow;
            groupRep.dt_rowSpan = 1;
            groupRep.dt_isDuplicate = false;
        }
    }
}

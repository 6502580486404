import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';

import { randomId } from './util';

/**
 * Select control for filtering by IsLocked values.
 */
@Component({
    selector: 'lock-status-select',
    template: `
    <select name="{{fieldName}}"
            [id]="id"
            class="form-control input-medium"
            [(ngModel)]="model"
            (ngModelChange)="modelChangeHandler()"
            [disabled]="readonly"
            [required]="required">
        <option *ngIf="nullable"></option>
        <option [value]="true">{{trueOptionText}}</option>
        <option [value]="false">{{falseOptionText}}</option>
    </select>
    `
})
export class LockStatusSelectComponent implements OnInit {
    @Input() model: boolean;
    @Input() trueOptionText: string;
    @Input() falseOptionText: string;
    @Input() id: string;
    @Input() nullable = true;
    @Input() readonly: boolean;
    @Input() required: boolean;
    @Output() modelChange: EventEmitter<any> = new EventEmitter<any>();

    // state
    fieldName: string;

    ngOnInit() {
        this.fieldName = "lockStatusSelect-" + randomId();

        if (!this.id) {
            this.id = this.fieldName;
        }

        if (!this.trueOptionText) {
            this.trueOptionText = 'Locked';
        }
        if (!this.falseOptionText) {
            this.falseOptionText = 'Unlocked';
        }
    }

    modelChangeHandler() {
        this.modelChange.emit(this.model);
    }
}

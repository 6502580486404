<table class="table table-bordered lots-table">
    <thead>
        <tr class="detail-grid-header">
            <th [attr.colspan]="readonly ? 10 : 11">
                <a *ngIf="!readonly"
                   (click)="createLot()">
                    <i class="fa fa-fw fa-plus-circle add-item-link" title="Add Lot"></i>
                </a>
                <span class="float-right">
                </span>
                Lots ({{ entity?.Lot?.length }})
                <a (click)="toggleLotsShown()" class="toggle-lots-link">
                    <i class="fa fa-fw"
                       [ngClass]="lotsShown ? 'fa-chevron-up' : 'fa-chevron-down'"
                       [title]="lotsShown ? 'Hide Lots' : 'Show Lots'"
                       aria-hidden="true"></i>
                </a>
            </th>
        </tr>
    </thead>
    <tbody *ngIf="lotsShown">
        <tr>
            <th>

            </th>
            <th>
                Lot Number
            </th>
            <th>
                Sample Type
            </th>
            <th>
                Species
            </th>
            <th>
                Pools
            </th>
            <th>
                Sample Count
            </th>
        </tr>
        <tr *ngFor="let lot of entity.Lot">
            <td>
                <a *ngIf="!readonly" (click)="removeLot(lot)">
                    <i class="fa fa-fw fa-remove remove-item"
                       title="Delete Lot"></i>
                </a>
            </td>
            <td>
                <input type="text"
                       name="LotNumber"
                       #name="ngModel"
                       [(ngModel)]="lot.LotNumber"
                       [readonly]="readonly"
                       class="form-control input-medium" />
            </td>
            <td>
                <active-vocab-select [(model)]="lot.C_SampleType_key"
                                     [vocabChoices]="sampleTypes"
                                     [keyFormatter]="sampleTypeKeyFormatter"
                                     [optionFormatter]="sampleTypeFormatter"
                                     [readonly]="readonly"
                                     [nullable]="true"></active-vocab-select>
            </td>
            <td>
                <active-vocab-select [(model)]="lot.C_Taxon_key"
                                     [vocabChoices]="taxons"
                                     [keyFormatter]="taxonKeyFormatter"
                                     [optionFormatter]="taxonFormatter"
                                     [readonly]="readonly"
                                     [nullable]="true"></active-vocab-select>
            </td>
            <td>
                <input type="number"
                       name="Pools"
                       #name="ngModel"
                       [(ngModel)]="lot.Pools"
                       [readonly]="readonly"
                       min="0"
                       class="form-control input-medium" />
            </td>
            <td>
                <input type="number"
                       name="SampleCount"
                       #name="ngModel"
                       [(ngModel)]="lot.SampleCount"
                       [readonly]="readonly"
                       min="0"
                       class="form-control input-medium" />
            </td>
        </tr>
    </tbody>
</table>

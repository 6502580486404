import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'climb-auth-footer',
  templateUrl: './auth-footer.component.html',
  styleUrls: ['./auth-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthFooterComponent {
    @Input() version = 'Info';
}

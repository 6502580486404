import { WipModalService } from './wip-modal.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { ClimbCommonModule } from '../common/climb-common.module';
import { ClimbServicesModule } from '../services/climb-services.module';
import { WipModalComponent } from './wip-modal.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgbModule,
        ClimbCommonModule,
        ClimbServicesModule,
    ],
    declarations: [
        WipModalComponent
    ],
    exports: [
        WipModalComponent
    ],
    providers: [
        WipModalService
    ]
})
export class WipModule { }

import { Pipe, PipeTransform } from '@angular/core';

import { getLatestMaterialLocation, MaterialLocation } from '../util';

/**
 * Returns the current material location
 */
@Pipe({
    name: 'currentMaterialLocation',
    pure: false
})
export class CurrentMaterialLocationPipe implements PipeTransform {

    transform<T extends MaterialLocation>(value: T[]): T[] {
        if (!value) {
            return [];
        }

        const location = getLatestMaterialLocation(value);

        // Return array so can be used in ngFors
        if (location) {
            return [location];
        } else {
            return [];
        }
    }
}

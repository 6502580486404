import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconModule } from '@icons';
import { BaseDirectivesModule } from '@common/directives/base-directives.module';
import { BaseComponentsModule } from '@common/components/base-components.module';
import { AppInsightsClickDirective } from '@common/directives/app-insights-click.directive';
import { DataTableExportService } from './services/data-table-export.service';
import { ToolbarExportDropdownComponent } from './components/toolbar-export-dropdown/toolbar-export-dropdown.component';

@NgModule({
    imports: [
        CommonModule,
        IconModule,
        BaseDirectivesModule,
        BaseComponentsModule,
    ],
    declarations: [
        ToolbarExportDropdownComponent,
    ],
    providers: [
        AppInsightsClickDirective,
        DataTableExportService,
    ],
    exports: [
        ToolbarExportDropdownComponent,
    ],
})
export class ExportModule {}

import type { Mating } from './mating.interface';
import type { MatingCharacteristic } from './mating-characteristic.interface';

export interface MatingCharacteristicInstance {
    C_EnumerationItem_key: number | null;
    C_MaterialPool_key: number;
    C_MatingCharacteristicInstance_key: number;
    C_MatingCharacteristic_key: number;
    C_Workgroup_key: number;
    CharacteristicValue: string;
    CreatedBy: string;
    DateCreated: Date;
    DateEnd: Date | null;
    DateModified: Date;
    DateStart: Date | null;
    Mating: Mating;
    MatingCharacteristic: MatingCharacteristic;
    ModifiedBy: string;
    Version: number;
}

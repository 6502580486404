<div class="modal-header justify-content-center">
    <h4 class="modal-title">
        Items need reason for change
    </h4>
</div>

<div class="modal-body">
    <p>
        Please enter a reason for the following changes:
    </p>
    <div style="height: 200px;overflow-y: auto">
        <table class="table" *ngIf="entitiesTable !== undefined">
            <thead style="position:sticky;top:0;background-color:white">
                <tr >
                    <th>Item</th>
                    <th>State</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let row of entitiesTable">
                    <td>{{row.entityName | translate}}</td>
                    <td>{{row.actionType}}</td>
                </tr>
            </tbody>

        </table>
    </div>

    <textarea name="reason"
              required
              [(ngModel)]="reasonForChange"
              maxlength="1000"
              class="form-control">
        </textarea>
</div>

<div class="modal-footer">
    <button type="button"
            class="btn btn-fw btn-lg btn-primary"
            (click)="closeModal()"
            [disabled]="confirmationButtonDisabled">
        Confirm
    </button>
</div>

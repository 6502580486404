import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import {
    EntityQuery,
    FilterQueryOp,
    Predicate,
} from 'breeze-client';

import {
    notEmpty,
    sortObjectArrayByProperty,
} from '../common/util';
import {
    CustomSearchRequest
} from './custom-search/custom-search-request';
import {
    ReportCategory,
    ReportTypeItem,
} from './models';

import { AuthService } from '../services/auth.service';
import { CurrentWorkgroupService } from '../services/current-workgroup.service';
import { DataManagerService } from '../services/data-manager.service';
import { EnvironmentService } from '../services/environment.service';
import { LoggingService } from '../services/logging.service';
import { TranslationService } from '../services/translation.service';
import { WebApiService } from '../services/web-api.service';
import { xCacheRefresh } from '@services/breeze-helpers';
import { ExportFileRequest } from '@common/types';

const ANIMAL_CARD_TYPE = 2;
const WEAN_CARD_TYPE = 3;
const MATING_CARD_TYPE = 4;
const JOB_CARD_TYPE = 5;
const IMPLANTPASSAGE_CARD_TYPE = 21;
const SAMPLE_LABEL_TYPE = 12;
const JOB_CRO_CLIENT_TYPE = 23;
const JOB_CRO_SUMMARY_TYPE = 18;
const JOB_PHARMA_SUMMARY_TYPE = 19;


@Injectable()
export class ReportingService {

    readonly DEFAULT_LOOKUP_COUNT = 100;
    readonly COMPONENT_LOG_TAG = 'reporting-service';
    readonly utcOffset: number = new Date().getTimezoneOffset() / 60;

    constructor(
        private dataManager: DataManagerService,
        private _authService: AuthService,
        private _currentWorkgroupService: CurrentWorkgroupService,
        private _environmentService: EnvironmentService,
        private _loggingService: LoggingService,
        private _translationService: TranslationService,
        private _webApiService: WebApiService,
    ) {
        // Nothing to do
    }


    editReport(reportVariationId: string): void {
        window.open(`/Reporting/EditDocument?id=${reportVariationId}`, '_blank');
    }

    runReportVariation(reportVariationId: any, params: any): void {
        this._requestReport(reportVariationId, params).then((reportId) => {
            this._viewReport(reportId);
        });
    }

    /* Accepts Material_keys */
    requestCageCardJobByAnimalKeys(animalKeysArray: any[]): void {
        const animalKeysCommaDelimited = animalKeysArray.join(',');
        this._viewReportByType(JOB_CARD_TYPE,
            { JobIds: '', SysAnimalIds: animalKeysCommaDelimited }
        );
    }

    /* Accepts Material_keys */
    requestCageCardImplantPassageByAnimalKeys(animalKeysArray: any[]): void {
        const animalKeysCommaDelimited = animalKeysArray.join(',');
        this._viewReportByType(IMPLANTPASSAGE_CARD_TYPE,
            { JobIds: '', SysAnimalIds: animalKeysCommaDelimited }
        );
    }

    /* Accepts Material_keys */
    requestCageCardAnimal(animalKeysArray: any[]): void {
        this._viewReportByType(ANIMAL_CARD_TYPE, { AnimalIds: animalKeysArray });
    }

    /* Accepts MaterialPool_keys */
    requestCageCardWean(weanKeysArray: any[]): void {
        this._viewReportByType(WEAN_CARD_TYPE, { WeanIds: weanKeysArray });
    }

    /* Accepts MaterialPool_keys */
    requestCageCardMating(matingKeysArray: any[]): void {
        this._viewReportByType(MATING_CARD_TYPE, { MatingIds: matingKeysArray });
    }

    /* Accepts Material_keys */
    requestSampleLabel(sampleKeysArray: any[]): void {
        this._viewReportByType(SAMPLE_LABEL_TYPE, { SampleKeys: sampleKeysArray });
    }

    /* Accepts Job_key */
    requestJobClientReportByJobKey(isCRO: boolean, jobKey: number): void {
        const jobClientType = isCRO ? JOB_CRO_CLIENT_TYPE : JOB_PHARMA_SUMMARY_TYPE;
        this._viewReportByType(jobClientType, { JobKey: jobKey });
    }

    /* Accepts Job_key */
    requestJobSummaryReportByJobKey(isCRO: boolean, jobKey: number): void {
        const jobSummaryType = isCRO ? JOB_CRO_SUMMARY_TYPE : JOB_PHARMA_SUMMARY_TYPE;
        this._viewReportByType(jobSummaryType, { JobKey: jobKey });
    }

    private _viewReportByType(reportTypeKey: number, params: any): void {

        // reportType parameter represents type of report, not the particular
        // layout for that report; for instance, if there were two different
        // layouts(i.e.variations) for the animal cage card, the report type
        // would be 2 for both of them.
        //
        // to determine what each reportType value represents, see the EnumValue
        // column in the cv_ReportType table

        // this retrieves all of the report layouts (i.e. variations) for a given report type
        this.getReportTypeVariations(reportTypeKey).then((variations: any) => {

            // catch error case
            if (!variations || variations.length === 0) {
                const msg = 'Could not locate report layouts for selected report type';
                this._loggingService.logError(
                    msg,
                    new Error(msg),
                    this.COMPONENT_LOG_TAG,
                    false
                );

                return;
            }

            // NOTE: we do not yet handle case where multiple report layouts exist
            const reportVariationKey = variations[0].C_ReportVariation_key;
            this._requestReport(reportVariationKey, params).then((reportId) => {
                this._viewReport(reportId);
            });
        });
    }

    private _buildKVPairParamsFromObject(paramsObject: any): string {
        const arrayToString = (arr: (number | string | Record<string, unknown>)[]) => `[arr:,${arr.join(',')}:]`;
        const keyValueToString = (key: string, value: any) => Array.isArray(value)
            ? `${key}=${arrayToString(value)}`
            : `${key}=${value}`;

        return Object.keys(paramsObject)
            .map((key) => keyValueToString(key, paramsObject[key]))
            .join(';');
    }

    private _requestReport(reportVariationId: any, paramsObject: any): Promise<string> {
        paramsObject = this._ensureStandardParamValues(paramsObject);

        let params: HttpParams = new HttpParams();
        params = params.set('rvid', reportVariationId);
        params = params.set('parameters', this._buildKVPairParamsFromObject(paramsObject));
        params = params.set('workgroupId', this._currentWorkgroupService.getCurrentWorkgroupKey());
        params = params.set('userName', this._authService.getCurrentUserName());

        // Request new report, and fetch its report ID
        const requestUrl = 'api/Reporting/GenerateReportRequest';
        return this._webApiService.callApi(requestUrl, params).then((response) => {
            return <string> response.data;
        });
    }

    private _ensureStandardParamValues(params: any): any {
        if (params == null) {
            params = {
                WorkgroupId: this._currentWorkgroupService.getCurrentWorkgroupKey(),
                EnvironmentId: this._environmentService.getEnvironmentId()
            };
        } else {
            if (!params.hasOwnProperty('WorkgroupId')) {
                params.WorkgroupId = this._currentWorkgroupService.getCurrentWorkgroupKey();
            }

            if (!params.hasOwnProperty('EnvironmentId')) {
                params.EnvironmentId = this._environmentService.getEnvironmentId();
            }
        }

        return params;
    }

    private _viewReport(reportRequestId: string): void {
        window.open(`/Reporting/ViewDocument?rrid=${reportRequestId}`, '_blank');
    }

    getActiveReportTypes(isCRO: boolean): Promise<ReportCategory[]> {
        const selects = [
            'ReportCategory',
            'cv_ReportType.ReportType',
            'cv_ReportType.EnumValue',
            'cv_ReportType.IsActive',
            'cv_ReportType.SortOrder',
        ];

        const query = EntityQuery
            .from('cv_ReportCategories')
            .expand('cv_ReportType')
            .where('IsActive', '==', true)
            .orderBy('SortOrder')
            .select(selects.join(','))
            .noTracking(true);

        return this.dataManager.returnQueryResults(query).then((results) => {
            const reportCategories: ReportCategory[] = [];

            if (notEmpty(results)) {
                for (const category of results) {
                    const nameTranslated = this._translationService.translateMessage(
                        category.ReportCategory
                    );

                    const reportTypes: ReportTypeItem[] = this._createReportTypesForCategory(
                        nameTranslated,
                        category,
                        isCRO
                    );

                    const reportCategory: ReportCategory = {
                        categoryName: nameTranslated,
                        reportTypes
                    };
                    reportCategories.push(reportCategory);
                }
            }

            return reportCategories;
        });
    }

    private _createReportTypesForCategory(
        categoryName: string,
        reportCategory: any,
        isCRO: boolean
    ): ReportTypeItem[] {
        const reportTypes: ReportTypeItem[] = [];

        // Filter out inactive then sort
        let activeCvReportTypes = reportCategory.cv_ReportType
            .filter((cvReportType: any) => {
                return cvReportType.IsActive === true;
            });
        activeCvReportTypes = sortObjectArrayByProperty(activeCvReportTypes, 'SortOrder');

        for (const cvReportType of activeCvReportTypes) {
            if (this._isCROReport(cvReportType.ReportType) && !isCRO) {
                continue;
            }

            const nameTranslated = this._translationService.translateMessage(
                cvReportType.ReportType
            );

            const reportType: ReportTypeItem = {
                text: nameTranslated,
                value: cvReportType.EnumValue,
                categoryName
            };
            reportTypes.push(reportType);
        }

        return reportTypes;
    }

    private _isCROReport(reportType: string): boolean {
        const croReports = ['cro summary'];
        return croReports.includes(reportType.toLowerCase());
    }

    getReportTypeVariations(reportTypeKey: number): Promise<any[]> {
        const query = EntityQuery
            .from('ReportVariations')
            .where('C_ReportType_key', '==', reportTypeKey)
            .orderBy('Name')
            .select('Name, C_ReportVariation_key')
            .noTracking(true);

        return this.dataManager.returnQueryResults(query);
    }

    getReportTypeVariationParameterNames(reportVariationKey: number): Promise<any[]> {
        const query = EntityQuery
            .from('ReportVariations')
            .where('C_ReportVariation_key', '==', reportVariationKey)
            .select('Parameters')
            .noTracking(true);

        return this.dataManager.returnQueryResults(query);
    }

    getLookupAnimalIds(filterText: string, limitCount?: number): Promise<any[]> {
        const predicate = Predicate.create('AnimalName', FilterQueryOp.Contains, { value: filterText ?? '' });

        return this._getLookupAnimalIdsByPred(
            predicate,
            limitCount ? limitCount : this.DEFAULT_LOOKUP_COUNT
        );
    }

    private _getLookupAnimalIdsByPred(predicate: Predicate, limitCount: number): Promise<any[]> {
        const query = EntityQuery
            .from('Animals')
            .where(predicate)
            .orderBy('AnimalNameSortable')
            .select('AnimalName, C_Material_key')
            .top(limitCount)
            .noTracking(true);

        return this.dataManager.returnQueryResults(query).then((results: any[]) => {
            return results.map((item: any) => {
                return {
                    text: item.AnimalName,
                    value: item.C_Material_key
                };
            });
        });
    }

    getLookupAnimalIdsByBirthId(birthKey: number, limitCount?: number): Promise<any[]> {
        const predicate = Predicate.create('C_Birth_key', '==', birthKey);

        return this._getLookupAnimalIdsByPred(
            predicate,
            limitCount ? limitCount : null
        );
    }

    getLookupBirthIds(filterText: string, limitCount?: number): Promise<any[]> {
        const predicate = Predicate.create('BirthID', FilterQueryOp.Contains, { value: filterText ?? '' });

        const query = EntityQuery
            .from('Births')
            .where(predicate)
            .orderBy('BirthID')
            .select('BirthID, C_Birth_key')
            .top(limitCount ? limitCount : this.DEFAULT_LOOKUP_COUNT)
            .noTracking(true);

        return this.dataManager.returnQueryResults(query).then((results: any[]) => {
            return results.map((item: any) => {
                return {
                    text: item.BirthID,
                    value: item.C_Birth_key
                };
            });
        });
    }

    getLookupWeanIds(filterText: string, limitCount?: number): Promise<any[]> {
        const p1 = Predicate.create('MaterialPoolID', FilterQueryOp.Contains, { value: filterText ?? '' });
        const p2 = Predicate.create("cv_MaterialPoolType.MaterialPoolType", "==", "Wean");
        const predicate = Predicate.and([p1, p2]);

        const query = EntityQuery
            .from('MaterialPools')
            .expand('cv_MaterialPoolType')
            .where(predicate)
            .select('MaterialPoolID, C_MaterialPool_key')
            .orderBy('MaterialPoolID')
            .top(limitCount ? limitCount : this.DEFAULT_LOOKUP_COUNT)
            .noTracking(true);

        return this.dataManager.returnQueryResults(query).then((results: any[]) => {
            return results.map((item: any) => {
                return {
                    text: item.MaterialPoolID,
                    value: item.C_MaterialPool_key
                };
            });
        });
    }

    getLookupMatingIds(filterText: string, limitCount?: number): Promise<any[]> {
        const predicate = Predicate.create(
            'MaterialPool.MaterialPoolID', FilterQueryOp.Contains, { value: filterText ?? '' },
        );

        const query = EntityQuery
            .from('Matings')
            .expand('MaterialPool')
            .where(predicate)
            .select('MaterialPool.MaterialPoolID, C_MaterialPool_key')
            .orderBy('MaterialPool.MaterialPoolID')
            .top(limitCount ? limitCount : this.DEFAULT_LOOKUP_COUNT)
            .noTracking(true);

        return this.dataManager.returnQueryResults(query).then((results: any[]) => {
            return results.map((item: any) => {
                return {
                    text: item.MaterialPool.MaterialPoolID,
                    value: item.C_MaterialPool_key
                };
            });
        });
    }

    getLookupJobIds(filterText: string, limitCount?: number): Promise<any[]> {
        const predicate = Predicate.create('JobID', FilterQueryOp.Contains, { value: filterText ?? '' });

        const query = EntityQuery
            .from('Jobs')
            .where(predicate)
            .select('JobID, C_Job_key')
            .orderBy('JobID')
            .top(limitCount ? limitCount : this.DEFAULT_LOOKUP_COUNT)
            .noTracking(true);

        return this.dataManager.returnQueryResults(query).then((results: any[]) => {
            return results.map((item: any) => {
                return {
                    text: item.JobID,
                    value: item.C_Job_key
                };
            });
        });
    }

    getLookupTaskNames(filterText: string, limitCount?: number): Promise<any[]> {
        const predicate = Predicate.create('TaskName', FilterQueryOp.Contains, { value: filterText ?? '' });

        const query = EntityQuery
            .from('WorkflowTasks')
            .where(predicate)
            .select('TaskName, C_WorkflowTask_key')
            .orderBy('TaskName')
            .top(limitCount ? limitCount : this.DEFAULT_LOOKUP_COUNT)
            .noTracking(true);

        return this.dataManager.returnQueryResults(query).then((results: any[]) => {
            return results.map((item: any) => {
                return {
                    text: item.TaskName,
                    value: item.C_WorkflowTask_key
                };
            });
        });
    }

    getLookupAnimalStatuses(filterText: string, limitCount?: number): Promise<any[]> {
        const predicate = Predicate.create('AnimalStatus', FilterQueryOp.Contains, { value: filterText ?? '' });

        const query = EntityQuery
            .from('cv_AnimalStatuses')
            .where(predicate)
            .select('AnimalStatus, C_AnimalStatus_key')
            .orderBy('AnimalStatus')
            .top(limitCount ? limitCount : this.DEFAULT_LOOKUP_COUNT)
            .noTracking(true);

        return this.dataManager.returnQueryResults(query).then((results: any[]) => {
            return results.map((item: any) => {
                return {
                    text: item.AnimalStatus,
                    value: item.C_AnimalStatus_key,
                };
            });
        });
    }

    getLookupIACUCProtocols(filterText: string, limitCount?: number): Promise<any[]> {
        const predicate = Predicate.create('IACUCProtocol', FilterQueryOp.Contains, { value: filterText ?? '' });

        const query = EntityQuery
            .from('cv_IACUCProtocols')
            .where(predicate)
            .select('IACUCProtocol, C_IACUCProtocol_key')
            .orderBy('IACUCProtocol')
            .top(limitCount ? limitCount : this.DEFAULT_LOOKUP_COUNT)
            .noTracking(true);

        return this.dataManager.returnQueryResults(query).then((results: any[]) => {
            return results.map((item: any) => {
                return {
                    text: item.IACUCProtocol,
                    value: item.C_IACUCProtocol_key,
                };
            });
        });


    }
    // Custom Searches
    getCustomSearches(expands?: string[]) {
        let query = EntityQuery.from('CustomSearchQueries')
            .orderBy('SearchName')
            .noTracking(true);

        if (notEmpty(expands)) {
            query = query.expand(expands.join(','));
        }

        return this.dataManager.getQueryResults(query);
    }

    getCustomSearch(customSearchQueryKey: number) {
        const expands = [
            'CustomSearchParameter.cv_DataType',
            'CustomSearchParameter.ControlledVocabulary',
            'CustomSearchParameter.EnumerationClass',
        ];
        const query = EntityQuery.from('CustomSearchQueries')
            .expand(expands.join(','))
            .where('C_CustomSearchQuery_key', '==', customSearchQueryKey)
            .noTracking(true);

        return this.dataManager.returnSingleQueryResult(query);
    }

    performCustomSearch(query: CustomSearchRequest): Promise<any> {
        const url = 'api/customsearch';

        query.pageNumber = query.pageNumber || 1;
        query.rowCount = query.rowCount || 100;
        return this._webApiService.postApi(url, query);
    }


    // Bulk Export
    createExportFileRequest(exportFileRequestType: string, paramsObject: any): Promise<any> {
        let params: HttpParams = this._webApiService.buildURLSearchParams(paramsObject);
        params = params.set('userName', this._authService.getCurrentUserName());
        params = params.set('utcOffset', this.utcOffset + '');

        const requestUrl = 'api/exportfilerequest/' + exportFileRequestType;
        return this._webApiService.callApi(requestUrl, params).then((response) => {
            return <string> response.data;
        });
    }

    createAnimalCheckFileRequest(params: HttpParams): Promise<any> {
        const requestUrl = 'api/exportfilerequest/animal';
        return this._webApiService.callApi(requestUrl, params).then((response) => {
            return <string> response.data;
        });
    }
    
    getExportFileRequests(reportType?: string): Promise<ExportFileRequest[]> {
        const userName = this._authService.getCurrentUserName();

        const predicates = [new Predicate("UserName", "eq", userName)];
        if (reportType === "animalCheck") {
            predicates.push(new Predicate("ReportType", "eq", "AnimalCheck"));
        } else if (reportType === "task") {
            predicates.push(new Predicate("ReportType", "eq", "BulkTask"));
        }

        const query = EntityQuery.from('ExportFileRequests')
            .expand('StoredFileMap.StoredFile')
            .where(Predicate.and(predicates))
            .select('C_ExportFileRequest_key, UserName, Status, DefaultFileName, ReportType, CreatedBy, DateCreated, ModifiedBy, DateModified, StoredFileMap')
            .orderBy('DateCreated DESC')
            .withParameters(xCacheRefresh);

        return this.dataManager.returnQueryResults(query);
    }
}

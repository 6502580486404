
<fieldset class="fieldset-bordered">
    <legend>
    Characteristics
        <button *ngIf="characteristicType === CHARACTERISTIC_TYPE_NAMES.Taxon && isRefreshNeeded"
                type="button"
                title="Apply relevant animal characteristics to the record"
                class="bg-color-warning btn"
                (click)="refreshCharacteristics()">
                <i class="fa fa-history"></i>
        </button>
    </legend>
    
    <ng-container *ngIf="!isLoading">
        <characteristic-instance-list
            [model]="model"
            [signalValue]="signalValue"
            [characteristicType]="characteristicType"
            (updateRefreshNeeded)="updateRefreshNeeded($event)">>
    
        </characteristic-instance-list>
    </ng-container>
    
</fieldset>

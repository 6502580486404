/* Calculates number of days between two dates */
import { LuxonInput } from './date-time-formatting/types/luxon-input.type';
import { convertValueToLuxon } from './date-time-formatting/convert-value-to-luxon';

/*
*  Days between date1 and date2
*  Accepts null/invalid input
*/
export function daysSinceAsString(laterDate: LuxonInput, earlierDate: LuxonInput): string {
    const value = daysSince(laterDate, earlierDate);
    if (value === null || value === undefined) {
        return '';
    }
    return '' + daysSince(laterDate, earlierDate);
}

export function daysSince(laterDate: LuxonInput, earlierDate: LuxonInput): number {

    let laterMoment = convertValueToLuxon(laterDate);
    let earlierMoment = convertValueToLuxon(earlierDate);

    if (!laterMoment || !earlierMoment) {
        return null;
    }

    // ignore time
    laterMoment = laterMoment.startOf('day');
    earlierMoment = earlierMoment.startOf('day');

    return laterMoment.diff(earlierMoment, 'days').as("day");
}

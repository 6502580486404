import { Component, Injector, OnInit } from '@angular/core';
import { arrowOutOfRectangle } from '@icons';
import { CurrentWorkgroupService } from '@services/current-workgroup.service';
import { DialogService } from '@common/dialog/dialog.service';
import { WorkgroupSwitchModalComponent } from '../workgroup-switch-modal/workgroup-switch-modal.component';
import { MixinUnsubscribeClass } from '@common/mixins';

@Component({
    selector: 'climb-workgroup-switch',
    templateUrl: 'workgroup-switch.component.html',
    styleUrls: ['workgroup-switch.component.scss'],
})
export class WorkgroupSwitchComponent extends MixinUnsubscribeClass implements OnInit {
    icons = { arrowOutOfRectangle };

    workgroupName = '';

    constructor(
        private currentWorkgroupService: CurrentWorkgroupService,
        private dialogService: DialogService,
        protected injector: Injector,
    ) {
        super(injector);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.workgroupName = this.currentWorkgroupService.getWorkgroupName();
        this.subscription = this.currentWorkgroupService.switchWorkgroup$.subscribe(() => {
            this.workgroupName = this.currentWorkgroupService.getWorkgroupName();
        })
    }

    openWorkgroupModal(showCloseButton = true): void {
        this.dialogService.open(WorkgroupSwitchModalComponent, {
            data: {
                showCloseButton,
            },
        });
    }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { ClimbCommonModule } from '@common/climb-common.module';
import { ClimbServicesModule } from '@services/climb-services.module';
import { RegistrationEmailViewComponent } from './components/registration-email-view/registration-email-view.component';
import { EmailVerificationViewComponent } from './components/email-verification-view/email-verification-view.component';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        ClimbCommonModule,
        ClimbServicesModule,
    ],
    declarations: [
        RegistrationEmailViewComponent,
        EmailVerificationViewComponent
    ],
    exports: [
        RegistrationEmailViewComponent,
        EmailVerificationViewComponent
    ],
    providers: [
    ]
})
export class RegisterModule { }

import { 
    Directive, 
    Input,
    HostListener,
} from '@angular/core';

import { AppInsightsService } from '@services/app-insights.service';

@Directive({ selector: '[appInsightsClick]' })
export class AppInsightsClickDirective {

    // unique name of this click event
    @Input() appInsightsClick: string;

    constructor(
        private appInsightsService: AppInsightsService
    ) {
        // do nothing
    }

    @HostListener('click', ['$event']) onClick($event: Event) {
        const eventName = this.appInsightsClick || 'generic-click-event';
        this.appInsightsService.trackEvent(eventName);
    }


}

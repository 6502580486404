export interface DependentTasksUpdated {
    C_TaskInstance_key: number;
    DateDue: Date;
}

export interface ICompleteTasksResponse {
    DependentTasksUpdated: DependentTasksUpdated[];
    Success: boolean;
    ErrorMessage: string;
}

<table class="table table-bordered animals-table">
    <thead>
        <tr class="detail-grid-header">
            <th [attr.colspan]="readonly ? 10 : 11">
                <a *ngIf="!readonly"
                   (click)="createAnimal()">
                    <i class="fa fa-fw fa-plus-circle add-item-link" title="Add Animal"></i>
                </a>
                <span class="float-right"></span>
                Animals ({{ entity?.AnimalOrder?.length }})
                <a (click)="toggleAnimalsShown()" class="toggle-animals-link">
                    <i class="fa fa-fw"
                       [ngClass]="animalsShown ? 'fa-chevron-up' : 'fa-chevron-down'"
                       [title]="animalsShown ? 'Hide Animals' : 'Show Animals'"
                       aria-hidden="true">
                    </i>
                </a>
            </th>
        </tr>
    </thead>
    <tbody *ngIf="animalsShown">
        <tr>
            <th></th>
            <th *ngIf="activeFields.includes('Number of Animals')">
                Number of Animals
            </th>
            <th *ngIf="activeFields.includes('Sex')">
                <bulk-edit-header [headerText]="'Sex'"
                                  [readonly]="readonly"
                                  (updateClicked)="bulkSexChanged()">
                    <div modal-content>
                        <active-vocab-select [(model)]="bulk.sexKey"
                                             [vocabChoices]="sexes"
                                             [keyFormatter]="sexKeyFormatter"
                                             [optionFormatter]="sexFormatter"
                                             [nullable]="true">
                        </active-vocab-select>
                        <p>
                            <label>
                                <input type="checkbox" name="bulk_Force"
                                       [(ngModel)]="bulk.force" />
                                Overwrite existing values
                            </label>
                        </p>
                    </div>
                </bulk-edit-header>
            </th>
            <th>
                Species
            </th>
            <th *ngIf="activeFields.includes('Line')">
                <bulk-edit-header [headerText]="'Line' | translate"
                                  [readonly]="readonly"
                                  (updateClicked)="bulkLineChanged()">
                    <div modal-content>
                        <line-select [(model)]="bulk.lineKey"
                                     (modelChange)="lineChanged($event, bulk)">
                        </line-select>
                        <p>
                            <label>
                                <input type="checkbox" name="bulk_Force"
                                       [(ngModel)]="bulk.force" />
                                Overwrite existing values
                            </label>
                        </p>
                    </div>
                </bulk-edit-header>
            </th>
            <th *ngIf="activeFields.includes('Animal Condition at Receipt')">
                <bulk-edit-header [headerText]="'Animal Condition at Receipt'"
                                  [readonly]="readonly"
                                  (updateClicked)="bulkShipmentAnimalChanged()">
                    <div modal-content>
                        <active-vocab-select [(model)]="bulk.shipmentAnimalKey"
                                             [vocabChoices]="shipmentAnimals"
                                             [keyFormatter]="shipmentAnimalKeyFormatter"
                                             [optionFormatter]="shipmentAnimalFormatter"
                                             [readonly]="readonly"
                                             [nullable]="true">
                        </active-vocab-select>
                        <p>
                            <label>
                                <input type="checkbox" name="bulk_Force"
                                       [(ngModel)]="bulk.force" />
                                Overwrite existing values
                            </label>
                        </p>
                    </div>
                </bulk-edit-header>
            </th>
            <th *ngIf="activeFields.includes('Shipment Container Condition')">
                <bulk-edit-header [headerText]="'Shipment Container Condition'"
                                  [readonly]="readonly"
                                  (updateClicked)="bulkShipmentContainerChanged()">
                    <div modal-content>
                        <active-vocab-select [(model)]="bulk.shipmentContainerKey"
                                             [vocabChoices]="shipmentContainers"
                                             [keyFormatter]="shipmentContainerKeyFormatter"
                                             [optionFormatter]="shipmentContainerFormatter"
                                             [readonly]="readonly"
                                             [nullable]="true">
                        </active-vocab-select>
                        <p>
                            <label>
                                <input type="checkbox" name="bulk_Force"
                                       [(ngModel)]="bulk.force" />
                                Overwrite existing values
                            </label>
                        </p>
                    </div>
                </bulk-edit-header>
            </th>
            <th *ngIf="activeFields.includes('Comments')">
                Comments
            </th>
        </tr>
        <tr *ngFor="let animal of entity.AnimalOrder; index as i">
            <td>
                <a *ngIf="!readonly"
                   (click)="removeAnimal(animal)">
                    <i class="fa fa-fw fa-remove remove-item"
                       title="Delete Animal">
                    </i>
                </a>
            </td>
            <td *ngIf="activeFields.includes('Number of Animals')">
                <input type="number" name="AnimalCount"
                       class="form-control input-medium"
                       #name="ngModel"
                       min="0"
                       [(ngModel)]="animal.AnimalCount"
                       (ngModelChange)="animalCountChanged()"
                       [readonly]="readonly"
                       [required]="requiredFields.includes('AnimalCount')" />
            </td>
            <td *ngIf="activeFields.includes('Sex')">
                <active-vocab-select [(model)]="animal.C_Sex_key"
                                     [vocabChoices]="sexes"
                                     [keyFormatter]="sexKeyFormatter"
                                     [optionFormatter]="sexFormatter"
                                     [readonly]="readonly"
                                     [required]="requiredFields.includes('C_Sex_key')"
                                     [nullable]="!requiredFields.includes('C_Sex_key')">
                </active-vocab-select>
            </td>
            <td>
                <active-vocab-select [(model)]="animal.C_Taxon_key"
                                     [vocabChoices]="taxons"
                                     [keyFormatter]="taxonKeyFormatter"
                                     [optionFormatter]="taxonFormatter"
                                     [defaultValue]="taxonKey"
                                     [loadDefaultValue]="true"
                                     [readonly]="true"
                                     [nullable]="true">
                </active-vocab-select>
            </td>
            <td *ngIf="activeFields.includes('Line')">
                <line-select [(model)]="animal.C_Line_key"
                             [filterTaxonKey]="animal.C_Taxon_key"
                             (modelChange)="lineChanged($event, animal)"
                             [required]="requiredFields.includes('C_Line_key')"
                             [disabled]="readonly">
                </line-select>
            </td>
            <td *ngIf="activeFields.includes('Animal Condition at Receipt')">
                <active-vocab-select [(model)]="animal.C_ShipmentAnimalCondition_key"
                                     [vocabChoices]="shipmentAnimals"
                                     [keyFormatter]="shipmentAnimalKeyFormatter"
                                     [optionFormatter]="shipmentAnimalFormatter"
                                     [readonly]="readonly"
                                     [required]="requiredFields.includes('C_ShipmentAnimalCondition_key')"
                                     [nullable]="!requiredFields.includes('C_ShipmentAnimalCondition_key')">
                </active-vocab-select>
            </td>
            <td *ngIf="activeFields.includes('Shipment Container Condition')">
                <active-vocab-select [(model)]="animal.C_ShipmentContainerCondition_key"
                                     [vocabChoices]="shipmentContainers"
                                     [keyFormatter]="shipmentContainerKeyFormatter"
                                     [optionFormatter]="shipmentContainerFormatter"
                                     [readonly]="readonly"
                                     [required]="requiredFields.includes('C_ShipmentContainerCondition_key')"
                                     [nullable]="!requiredFields.includes('C_ShipmentContainerCondition_key')">
                </active-vocab-select>
            </td>
            <td *ngIf="activeFields.includes('Comments')">
                <textarea name="comments-{{i}}"
                          class="form-control input-large"
                          rows="2"
                          maxlength="2000"
                          [(ngModel)]="animal.Comments"
                          [required]="requiredFields.includes('Comments')">
                </textarea>
            </td>
        </tr>
    </tbody>
</table>

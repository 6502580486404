import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChildren,
} from '@angular/core';

import { GenotypeVocabService } from './genotype-vocab.service';

import { BaseFilter } from '../common/facet';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
    focusElementByQuery
} from '../common/util/';
import { NgModel } from '@angular/forms';
import { LoggingService } from '@services/logging.service';
import { dateControlValidator } from '@common/util/date-control.validator';


@Component({
    selector: 'genotype-filter',
    templateUrl: './genotype-filter.component.html'
})
export class GenotypeFilterComponent extends BaseFilter implements OnInit {
    @ViewChildren('dateControl') dateControls: NgModel[];
    @Input() filter: any = {};
    @Output() onFilter = new EventEmitter<any>();
    @Output() close = new EventEmitter<any>();

    // CVs
    sexes: any[] = [];



    constructor(
        private genotypeVocabService: GenotypeVocabService,
        private activeModal: NgbActiveModal,
        private loggingService: LoggingService,
    ) {
        super(activeModal);
    }

    ngOnInit() {
        this.cloneFilter();

        this.getCVs();

        focusElementByQuery('[data-auto-focus="genotypeIdentifier"]');
    }

    private cloneFilter() {
        const dateGenotypeStart = this.filter.DateGenotypeStart;
        const dateGenotypeEnd = this.filter.DateGenotypeEnd;

        this.filter = this.copyFilter(this.filter);

        this.filter.DateGenotypeStart = dateGenotypeStart;
        this.filter.DateGenotypeEnd = dateGenotypeEnd;
    }

    private getCVs() {
        this.genotypeVocabService.sexes$
            .subscribe((data) => {
                this.sexes = data;
            });
    }

    filterClicked(event: Event) {
        const errMessage = dateControlValidator(this.dateControls);
        if (errMessage) {
            this.loggingService.logError(errMessage, null, '', true);
            return;
        }

        event.preventDefault();
        this.onFilter.emit(this.filter);
        this.close.emit();
        this.activeModal.close();
    }

    closeClicked() {
        this.close.emit();
        this.activeModal.dismiss();
    }

    clearClicked() {
        this.filter = {};
        this.onFilter.emit(this.filter);
        this.close.emit();
        this.activeModal.close();
    }
}

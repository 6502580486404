import type { JobCharacteristic } from './job-characteristic.interface';

export interface cv_JobCharacteristicLinkType {
    C_JobCharacteristicLinkType_key: number;
    C_VocabularySource_key: number | null;
    C_Workgroup_key: number;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    ElementID: string;
    IsActive: boolean;
    IsDefault: boolean;
    IsDeprecated: boolean;
    JobCharacteristic: JobCharacteristic[];
    JobCharacteristicLinkType: string;
    ModifiedBy: string;
    SortOrder: number | null;
    Version: number;
}

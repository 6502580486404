import { map } from 'rxjs/operators';
import { StudySharedLogic } from './study-shared-logic';
import { EnumerationService } from './../enumerations/enumeration.service';
import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    ViewChild,
    ViewChildren
} from '@angular/core';
import { NgForm } from '@angular/forms';

import { ExportStudyDetailService } from './export-study-detail.service';
import { StudyService } from './study.service';
import { StudyVocabService } from './study-vocab.service';

import {
    BaseDetailService,
    FacetView,
    GlpBaseDetail,
    IFacet,
    PageState
} from '../common/facet';import { ViewStudyAuditReportComponentService } from './audit';
import { PrintPreviewService } from '../common/services';
import { PrivilegeService } from '../services/privilege.service';
import { empty, randomId } from '../common/util';
import { UserService } from '../user/user.service';
import { FeatureFlagService } from '../services/feature-flags.service';
import { SettingService } from '../settings/setting.service';
import { Entity, Study } from '@common/types';
import { TranslationService } from '@services/translation.service';
import { CharacteristicInputComponent } from '../characteristics/characteristic-input/characteristic-input.component';
import { StudySaveService } from './services/study-save.service';
import { StudyStateService } from './services/study-state.service';
import { EntityChangeService } from '../entity-changes/entity-change.service';

@Component({
    selector: 'study-detail',
    templateUrl: './study-detail.component.html'
})
export class StudyDetailComponent extends GlpBaseDetail<Study> implements OnChanges, OnInit {
    @Input() facet: IFacet;
    @Input() facetView: FacetView;
    @Input() study: Entity<Study>;
    @Input() pageState: PageState;
    @Output() exit: EventEmitter<void> = new EventEmitter<void>();
    @Output() next: EventEmitter<void> = new EventEmitter<void>();
    @Output() previous: EventEmitter<void> = new EventEmitter<void>();

    @ViewChildren('characteristicInput') characteristicInputs: CharacteristicInputComponent[];
    @ViewChild("studyForm") studyForm: NgForm;

    // CVs
    studyTypes: any[] = [];
    studyStatuses: any[] = [];

    // State
    saving = false;

    studySharedLogic: StudySharedLogic;
    printPreviewId: string;

    // Active and required fields set by facet settings
    activeFields: string[] = [];
    requiredFields: string[] = [];

    readonly COMPONENT_LOG_TAG = 'study-detail';

    readwrite: boolean;
    readonly: boolean;
    studyAdministrator = false;

    isGLP: boolean;

    constructor(
        public baseDetailService: BaseDetailService,
        public entityChangeService: EntityChangeService,
        private exportStudyDetailService: ExportStudyDetailService,
        private privilegeService: PrivilegeService,
        private studyService: StudyService,
        private studyVocabService: StudyVocabService,
        private enumerationService: EnumerationService,
        private printPreviewService: PrintPreviewService,
        private viewStudyAuditReportComponentService: ViewStudyAuditReportComponentService,
        private featureFlagService: FeatureFlagService,
        private userService: UserService,
        private settingService: SettingService,
        private studySaveService: StudySaveService,
        private studyStateService: StudyStateService,
        private translationService: TranslationService
    ) {
        super(baseDetailService, studySaveService, studyStateService, entityChangeService);
        this.studySharedLogic = new StudySharedLogic(
            studyService,
            enumerationService
        );
    }

    // lifecycle
    ngOnInit() {
        super.ngOnInit();

        this.printPreviewId = 'studyPrint-' + randomId();

        this.initialize();
    }

    ngOnChanges(changes: any) {
        if (changes.study) {
            if (this.study && !changes.study.firstChange) {
                if (this.studyForm) {
                    this.studyForm.form.markAsPristine();
                }
                this.initialize();
            }
        }
    }

    initialize() {
        this.setLoading(true);
        this.initIsGLP();
        this.setPrivileges();
        this.getStudyAdminStatus();

        return this.settingService.getFacetSettingsByType('study', undefined, undefined).then((facetSettings) => {
            this.activeFields = this.settingService.getActiveFields(facetSettings);
            this.requiredFields = this.settingService.getRequiredFields(facetSettings);
        }).then(() => {
            return this.getCVs();
        }).then(() => {
            return this.getDetails();
        }).then(() => {
            return this.loadCharacteristics();
        }).then(() => {
            this.setLoading(false);
        }).catch((error) => {
            this.setLoading(false);
            throw error;
        });
    }


    private initIsGLP() {
        this.isGLP = this.featureFlagService.isFlagOn('IsGLP');
    }

    private getCVs(): Promise<any> {
        const p1 = this.studyVocabService.studyTypes$.pipe(map((data) => {
            this.studyTypes = data;
        })).toPromise();

        const p2 = this.studyVocabService.studyStatuses$.pipe(map((data) => {
            this.studyStatuses = data;
        })).toPromise();

        return Promise.all([p1, p2]);
    }

    private getDetails(): Promise<any> {
        if (this.study && this.study.C_Study_key > 0) {
            const expands: string[] = [
                "StudyLine.Line"
            ];

            return this.studyService.getStudy(this.study.C_Study_key, expands).then(() => {
                return this.studyService.getStudyAnimalCounts([this.study]);
            });
        }

        return Promise.resolve(this.study);
    }

    /**
     * Sets privilege variables.
     */
    private setPrivileges() {
        this.readonly = this.privilegeService.readonly;
        this.readwrite = this.privilegeService.readwrite;
    }

    private getStudyAdminStatus(): Promise<any> {
        if (this.isGLP) {
            this.userService.getThisWorkgroupUser().then((workgroupUser: any) => {
                this.studyAdministrator = workgroupUser.StudyAdministrator;
            });
        } else {
            return this.privilegeService.getCurrentUserStudyAdministratorStudies().then((studyAdminStudies: any[]) => {
                this.studyAdministrator = (studyAdminStudies.find((x) => x.C_Study_key === this.study.C_Study_key) != null) || (this.study.C_Study_key < 0);
            });
        }
    }

    async validate() {
        const translatedStudy = this.translationService.translate('Study');
        if (empty(this.study.StudyName)) {
            return `A ${translatedStudy} requires a Name.`;
        }
        if (empty(this.study.cv_StudyType)) {
            return `A ${translatedStudy} requires a Type.`;
        }

        return this.characteristicInputs.map(input => input.validate()).find(msg => msg)
            || this.settingService.validateRequiredFields(this.requiredFields, this.study, 'study');
            
    }

    async beforeSave(): Promise<void> {
        return;
    }

    destroy(): void {
        // do nothing when destroy
    }

    getEntityForSaving(): Entity<Study> {
        return this.study;
    }

    async onEntityChange(): Promise<void> {
        return;
    }

    onCancel() {
        this.studyService.cancelStudy(this.study);
    }

    addLine() {
        this.studyService.createStudyLine({
            C_Study_key: this.study.C_Study_key
        });
    }

    onLineChange() {
        this.studyService.ensureLineDataLoaded([this.study]);
    }

    removeStudyLine(studyLine: any) {
        this.studyService.deleteStudyLine(studyLine);
    }

    loadCharacteristics() {
        this.studyService.getStudyCharacteristics(this.study.C_Study_key).then((data) => {
            this.studySharedLogic.attachEnumerations(this.study);
        });
    }

    viewAuditReport() {
        this.viewStudyAuditReportComponentService.openComponent(this.study.C_Study_key);
    }

    // Export and print
    exportDetail() {
            this.exportStudyDetailService.exportToCsv(this.study);
    }

    printDetail() {
        this.printPreviewService.printFromId(this.printPreviewId);
    }

    // Formatters for <select> input
    studyTypeKeyFormatter = (value: any) => {
        return value.C_StudyType_key;
    }
    studyTypeFormatter = (value: any) => {
        return value.StudyType;
    }
    studyStatusKeyFormatter = (value: any) => {
        return value.C_StudyStatus_key;
    }
    studyStatusFormatter = (value: any) => {
        return value.StudyStatus;
    }
}

import type { Line } from './line.interface';
import type { Order } from './order.interface';
import type { cv_Sex } from './cv-sex.interface';
import type { cv_ShipmentAnimalCondition } from './cv-shipment-animal-condition.interface';
import type { cv_ShipmentContainerCondition } from './cv-shipment-container-condition.interface';
import type { cv_Taxon } from './cv-taxon.interface';

export interface AnimalOrder {
    AnimalCount: number | null;
    C_AnimalOrder_key: number;
    C_Line_key: number | null;
    C_Order_key: number;
    C_Sex_key: number | null;
    C_ShipmentAnimalCondition_key: number | null;
    C_ShipmentContainerCondition_key: number | null;
    C_Taxon_key: number | null;
    C_Workgroup_key: number;
    Comments: string;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    Line: Line;
    ModifiedBy: string;
    Order: Order;
    Version: number;
    cv_Sex: cv_Sex;
    cv_ShipmentAnimalCondition: cv_ShipmentAnimalCondition;
    cv_ShipmentContainerCondition: cv_ShipmentContainerCondition;
    cv_Taxon: cv_Taxon;
}

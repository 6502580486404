import {
    Component,
    ElementRef,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { DxChartComponent } from 'devextreme-angular';
import { DateTime } from 'luxon';
import { MONTH_DATE_FORMAT, MONTH_FORMAT, TIME_FORMATTER_FORMAT, YEAR_FORMAT } from '@common/util/date-time-formatting/date-format-constants';

interface OutputData {
    xVal: string;
    xValDate: Date;
    yVal: number;
}
@Component({
    selector: 'output-chart-renderer',
    template: `
      <dx-chart [palette]="palette" [dataSource]="outputData" *ngIf="hasCompletedTaskInstances">
            <dxo-size [height]="250"></dxo-size>
            <dxi-series [valueField]="'yVal'" [name]=""></dxi-series>
            <dxo-zoom-and-pan
                valueAxis="both"
                argumentAxis="both"
                [dragToZoom]="false"
                [allowMouseWheel]="false"
                panKey="shift"
            ></dxo-zoom-and-pan>
            <dxo-common-series-settings
                argumentField="xVal"
                [type]="'line'"
            >
            </dxo-common-series-settings>
            <dxo-margin [bottom]="20"></dxo-margin>
            <dxo-argument-axis
                [type]="argumentAxisType"
                [visible]="false"
            >
                <dxo-grid [visible]="true"></dxo-grid>
                <dxo-label wordWrap="none"></dxo-label>
            </dxo-argument-axis>
            <dxo-legend [visible]="false"></dxo-legend>
            <dxo-title 
                [text]="chartTitle"
                horizontalAlignment="left"
            >
                <dxo-font
                    [size]="16"
                    [weight]="600"
                >
                </dxo-font>
            </dxo-title>
            <dxo-tooltip [enabled]="true" [zIndex]="5000" [customizeTooltip]="customizeTooltip"></dxo-tooltip>
        </dx-chart>
    `,
})
export class OutputChartRendererComponent implements OnInit, OnDestroy, OnChanges {
    @Input() taskOutput: any;
    @Input() chartIndex: number;
    @Input() argumentAxisType = 'continious';
    @ViewChild(DxChartComponent, { static: false }) chartComponent: DxChartComponent;

    chartTitle: string;
    observer: any;

    outputData: OutputData[] = [];
    palette: any;
    hasCompletedTaskInstances: boolean;

    constructor(
        private el: ElementRef
    ) {}

    ngOnDestroy() {
        if (this.observer) {
            this.observer.unobserve(this.el.nativeElement);
        }
    }

    ngOnInit() {
        if (this.hasCompletedTaskInstances){
            this.observer = new ResizeObserver(() => {
                this.chartComponent.instance.render();
            });
            this.observer.observe(this.el.nativeElement);
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        this.hasCompletedTaskInstances = this.taskOutput.completed.includes(true)
        if (changes.taskOutput) {
            this.prepareData();
            this.chartTitle = this.taskOutput.OutputName;
        }
        if (changes.chartIndex) {
            this.palette = this.chartIndex > 0 ? 'Soft Blue' : 'Bright';
        }
    }

    private calculateData() {
        return (this.taskOutput.xValues as string[])
            .map((item, index) => {
                return {
                    xVal: new Date(item).getTime(),
                    yVal: this.taskOutput.yValues[index],
                    index,
                }
            })
            .sort((a, b) => a.xVal === b.xVal ? b.index - a.index : a.xVal - b.xVal)
    }

    private prepareData() {
        const data = this.calculateData();

        const format = this.getFormat(data);
        this.outputData = data.map(item => ({ xVal: DateTime.fromJSDate(new Date(item.xVal)).toFormat(format), xValDate: new Date(item.xVal), yVal: item.yVal }));
    }

    customizeTooltip(arg: any) {
        const date = arg.point.data.xValDate.toDateString();
        return {
            text: `<b>${date}, ${arg.valueText}`,
        };
    }

    private getFormat(data: { xVal: number, yVal: number }[]) {
        if (!data || !data.length) {
            return TIME_FORMATTER_FORMAT;
        }
        const minDate = DateTime.fromJSDate(new Date(data[0].xVal));
        const maxDate = DateTime.fromJSDate(new Date(data[data.length - 1].xVal));
        const diff = maxDate.diff(minDate, ['years', 'months', 'days']);

        if (diff.years >= 1) {
            return YEAR_FORMAT;
        } else if (diff.months >= 2) {
            return MONTH_FORMAT;
        } else if (diff.days >= 1) {
            return MONTH_DATE_FORMAT;
        }
        return TIME_FORMATTER_FORMAT;
    }
}


import {
    ChangeDetectorRef,
    Directive,
    ElementRef,
    EventEmitter,
    Host,
    HostBinding,
    HostListener,
    Injector,
    Input,
    OnDestroy,
    Optional,
    Output,
    ViewContainerRef
} from '@angular/core';
import { ConnectedPosition, Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import {
    DOWN_ARROW,
    ENTER,
    ESCAPE,
    SPACE,
    UP_ARROW,
    hasModifierKey,
} from '@angular/cdk/keycodes';
import { merge, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Popover } from './popover.interface';
import { PopoverOptions } from './popover-options.interface';
import { MixinUnsubscribeClass } from '@common/mixins';

@Directive({
    selector: '[popoverTriggerFor]',
})
export class PopoverTriggerForDirective extends MixinUnsubscribeClass implements OnDestroy {
    private isOpen = false;
    private overlayRef: OverlayRef;

    private get defaultPositions(): ConnectedPosition[] {
        const { right } = this.popoverHost.nativeElement.getBoundingClientRect();
        const popoverWidth = this.popoverOptions?.width ?? 164; // default value for popover panel width
        const horizontalPosition = right > popoverWidth ? 'end' : 'start';
        return [{
            originX: horizontalPosition,
            originY: 'bottom',
            overlayX: horizontalPosition,
            overlayY: 'top',
            offsetY: 4,
        }];
    }

    @Input('popoverTriggerFor') panel: Popover;
    @Input() popoverOptions: PopoverOptions;

    @HostListener('click')
    togglePanel(): void {
        this.isOpen ? this.destroyPanel() : this.openPanel();
    }

    @Output() toggle = new EventEmitter<boolean>();

    /** Gets whether the panel is expanded. */
    @HostBinding('attr.aria-expanded')
    get isExpanded(): boolean {
        return this.isOpen;
    }

    /** Handles all keydown events on the popover's navigation element. */
    @HostListener('keydown', ['$event'])
    onKeyDown(event: KeyboardEvent): void {
        type HTMLElementWithDisabled = HTMLElement & { disabled: boolean };
        const isDisabled = (this.popoverHost.nativeElement as HTMLElementWithDisabled).disabled ?? false;
        if (!isDisabled) {
            this.handleKeydown(event);
        }
    }

    constructor(
        private overlay: Overlay,
        private elementRef: ElementRef<HTMLElement>,
        private viewContainerRef: ViewContainerRef,
        private cdr: ChangeDetectorRef,
        public injector: Injector
    ) {
        super(injector);
    }

    ngOnDestroy(): void {
        if (this.overlayRef) {
            this.overlayRef.dispose();
        }
        super.ngOnDestroy();
    }

    get popoverHost(): ElementRef<HTMLElement> {
        return this.popoverOptions?.elementRef ?? this.elementRef;
    }

    openPanel(): void {
        this.isOpen = true;
        this.overlayRef = this.overlay.create({
            hasBackdrop: true,
            backdropClass: this.popoverOptions?.backdropClass ?? '',
            scrollStrategy: this.overlay.scrollStrategies.block(),
            positionStrategy: this.overlay
                .position()
                .flexibleConnectedTo(this.popoverHost)
                .withPositions(this.popoverOptions?.positions ?? this.defaultPositions)
                .withLockedPosition()
        });

        const templatePortal = new TemplatePortal(
            this.panel.templateRef,
            this.viewContainerRef
        );
        this.overlayRef.attach(templatePortal);

        this.subscribe(
            this.panelClosingActions().subscribe(
                () => this.destroyPanel()
            )
        );

        // wrap in setTimeout to properly handle the popover opening sequence
        setTimeout(() => {
            this.toggle.emit(true);
            this.toggleHostActiveClass(true);
            this.focusFirstElementInPopover();
            this.cdr.markForCheck();
        });
    }

    private panelClosingActions(): Observable<MouseEvent | KeyboardEvent | void> {
        const backdropClick$ = this.overlayRef.backdropClick();
        const detachment$ = this.overlayRef.detachments();
        const panelClose$ = this.panel.close$;
        const escapeKeydown$ = this.overlayRef.keydownEvents().pipe(
            filter((event) => event.keyCode === ESCAPE),
        );

        return merge(backdropClick$, detachment$, panelClose$, escapeKeydown$);
    }

    private destroyPanel(): void {
        if (!this.overlayRef || !this.isOpen) {
            return;
        }

        this.isOpen = false;
        this.overlayRef.detach();
        this.toggle.emit(false);
        this.toggleHostActiveClass(false);
        this.popoverHost.nativeElement.focus(); // return focus back to the host element
        this.cdr.markForCheck();
    }

    private handleKeydown(event: KeyboardEvent): void {
        const keyCode = event.keyCode;
        const isOpenKey = keyCode === ENTER || keyCode === SPACE;
        const isCloseKey = keyCode === ESCAPE;

        const isOpen = !this.isOpen && isOpenKey && !hasModifierKey(event);
        if (isOpen) {
            event.preventDefault();
            this.openPanel();
            return;
        }

        const isClose = this.isOpen && isCloseKey && !hasModifierKey(event);
        if (isClose) {
            event.preventDefault();
            this.destroyPanel();
        }
    }

    private focusFirstElementInPopover(): void {
        if (!this.overlayRef?.overlayElement) {
            return;
        }
        type Element = HTMLElement & { disabled: boolean };
        const focusableElements: NodeListOf<Element> = this.overlayRef.overlayElement.querySelectorAll(
            '[href], button, input, textarea, select, details, [tabindex]:not([tabindex="-1"])'
        );
        Array.from(focusableElements).find((element: Element) => !element.disabled)?.focus();
    }

    private toggleHostActiveClass(isActive: boolean): void {
        const activeClass = 'active';
        isActive
            ? this.popoverHost.nativeElement.classList.add(activeClass)
            : this.popoverHost.nativeElement.classList.remove(activeClass);
    }
}

@Directive({
    selector: '[popoverOpenWithKeyArrows]',
})
export class PopoverOpenWithKeyArrowsDirective {
    /** Handles arrows keydown events on the popover's navigation element. */
    @HostListener('keydown', ['$event'])
    onKeyDown(event: KeyboardEvent): void {
        type HTMLElementWithDisabled = HTMLElement & { disabled: boolean };
        const isDisabled = (this.elementRef.nativeElement as HTMLElementWithDisabled).disabled ?? false;
        const keyCode = event.keyCode;
        const isArrowKey = keyCode === DOWN_ARROW || keyCode === UP_ARROW;
        const isOpen = !isDisabled && !this.parent.isExpanded && isArrowKey;
        if (isOpen) {
            event.preventDefault();
            this.parent.openPanel();
        }
    }

    constructor(
        private elementRef: ElementRef<HTMLElement>,
        @Optional() @Host() private parent: PopoverTriggerForDirective,
    ) {
        if (!parent) {
            throw Error('PopoverTriggerForDirective provider not found');
        }
    }
}

import { CellFormatterService, TableColumnDef, TableOptions } from '@common/datatable';

export type AuditColumnsOptions = Required<Pick<TableOptions, 'enableAuditColumns' | 'useUtcDates'>>;
export const defaultAuditColumnsOptions: AuditColumnsOptions = {
    enableAuditColumns: true,
    useUtcDates: false,
};

export const addAuditColumnsToOptions = (
    cellFormatterService: CellFormatterService,
) => (
    options: TableOptions,
): TableOptions => {
    // Add additional columns to options before any further set-up
    const tableOptions: TableOptions = {
        ...defaultAuditColumnsOptions,
        ...options,
    };

    const dateFormatter = tableOptions.useUtcDates
        ? cellFormatterService.dateUtcFormatter
        : cellFormatterService.dateFormatter;

    const auditColumns: TableColumnDef[] = [
        {
            displayName: 'Created By',
            field: 'CreatedBy',
            formatter: cellFormatterService.userNameFormatter,
            visible: false,
        },
        {
            displayName: 'Created Date',
            field: 'DateCreated',
            formatter: dateFormatter,
            visible: false,
        },
        {
            displayName: 'Modified By',
            field: 'ModifiedBy',
            formatter: cellFormatterService.userNameFormatter,
            visible: false,
        },
        {
            displayName: 'Modified Date',
            field: 'DateModified',
            formatter: dateFormatter,
            visible: false,
        },
    ];

    tableOptions.columns.push(...auditColumns);
    return tableOptions;
};

import type { Birth } from './birth.interface';

export interface cv_BirthStatus {
    Abbreviation: string;
    Birth: Birth[];
    BirthStatus: string;
    C_BirthStatus_key: number;
    C_VocabularySource_key: number | null;
    C_Workgroup_key: number;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    ElementID: string;
    IsActive: boolean;
    IsDefault: boolean;
    IsDeprecated: boolean;
    ModifiedBy: string;
    SortOrder: number | null;
    Version: number;
}

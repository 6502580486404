import { Injectable } from '@angular/core';
import { defer, Observable } from 'rxjs';

import { UserService } from '../user/user.service';
import { VocabularyService } from '../vocabularies/vocabulary.service';
import { take } from 'rxjs/operators';

@Injectable()
export class ResourceVocabService {

    public get resourceTypes$(): Observable<any[]>{
        return defer(() => this.vocabularyService.getCV('cv_ResourceTypes')).pipe(take(1));
    }
    public get resourceGroups$(): Observable<any[]>{
        return defer(() => this.vocabularyService.getCV('cv_ResourceGroups')).pipe(take(1));
    }
    public get users$(): Observable<any[]>{
        return defer(() => this.userService.getWorkgroupUsers()).pipe(take(1));
    }


    constructor(
        private userService: UserService,
        private vocabularyService: VocabularyService
    ) {}
}

import { DataContextService } from './../services/data-context.service';
import { MaterialService } from './../services/material.service';
import { AnimalService } from '../animals/services/animal.service';
import { ConfirmService } from './../common/confirm/confirm.service';
import {
    Component,
    Input,
    OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { BirthService } from './birth.service';
import { BirthLogicService } from './birth-logic.service';
import { CellFormatterService, ColumnsState, TableColumnDef } from '@common/datatable';
import { TranslationService } from '../services/translation.service';
import { VocabularyService } from '../vocabularies/vocabulary.service';
import { WorkspaceFilterService } from '../services/workspace-filter.service';

import {
    BaseFacet,
    BaseFacetService
} from '../common/facet';

import { BirthBulkEditComponent } from './bulkedit';
import { BirthFilterComponent } from './birth-filter.component';
import { BirthTableOptions } from './birth-table-options';

import {
    TableState,
    DataResponse
} from '@common/datatable/data-table.interface';
import { WsFilterEvent } from '../services/ws-filter-event';
import { filterToDate, maxSequence, notEmpty, uniqueArrayFromPropertyPath } from '../common/util';
import { QueryDef } from '../services/query-def';
import { TaskType } from '../tasks/models';
import { ViewAddProtocolComponentService, ViewAddTaskComponentService } from '../tasks/add-task';
import { SaveChangesService } from '../services/save-changes.service';
import { TaskService } from '../tasks/task.service';
import { ProtocolDateCalculator } from '../tasks/tables/protocol-date-calculator';
import { ConfirmOptions } from '../common/confirm';
import { Animal, Birth, Entity, MaterialPool, TaskInstance, cv_BirthStatus } from '../common/types';
import { takeUntil } from "rxjs/operators";
import { arrowClockwise, brokenChain, chain, magnifier } from '@icons';

interface ExtendedBirth {
    birthAnimals: Animal[];
    pools: MaterialPool[];
}

@Component({
    selector: 'birth-facet',
    templateUrl: './birth-facet.component.html',
    providers: BaseFacet.BASE_COMPONENT_PROVIDERS
})
export class BirthFacetComponent extends BaseFacet<Entity<Birth>> implements OnInit, OnDestroy {
    @Input() facetId: string;
    @Input() facet: any;

    @ViewChild('bulkEdit') bulkEdit: BirthBulkEditComponent;

    readonly icons = { arrowClockwise, brokenChain, chain, magnifier };
    readonly componentName = 'birth';
    readonly COMPONENT_LOG_TAG = 'birth-facet';

    defaults: any = {};
    birthTableOptions: BirthTableOptions;
    addBirthInMatingSubscription: Subscription;
    componentFilterSubscription: Subscription;
    
    private notifier$ = new Subject<void>();

    dataTableColumns: BehaviorSubject<TableColumnDef[]>;
    dataTableColumns$: Observable<TableColumnDef[]>;

    constructor(
        private animalService: AnimalService,
        private dataContext: DataContextService,
        private materialService: MaterialService,
        private baseFacetService: BaseFacetService,
        private birthService: BirthService,
        private birthLogicService: BirthLogicService,
        private cellFormatterService: CellFormatterService,
        private confirmService: ConfirmService,
        private translationService: TranslationService,
        private vocabularyService: VocabularyService,
        private modalService: NgbModal,
        workspaceFilterService: WorkspaceFilterService,
        private viewAddTaskComponentService: ViewAddTaskComponentService,
        private viewAddProtocolComponentService: ViewAddProtocolComponentService,
        private saveChangesService: SaveChangesService,
        private taskService: TaskService
    ) {
        super(
            baseFacetService,
            workspaceFilterService
        );

        this.birthTableOptions = new BirthTableOptions(
            this.cellFormatterService,
            this.translationService
        );

        this.dataTableColumns = new BehaviorSubject(this.birthTableOptions.options.columns);
        this.dataTableColumns$ = this.dataTableColumns.asObservable();

        this.addBirthInMatingSubscription =
            this.birthTableOptions.addBirthInMatingClicked$.subscribe((row: any) => {
                this.addBirthInMating(row);
            });

        this.dataService = {
            run: (tableState: TableState) => {
                return this.loadItemsList(tableState);
            }
        };
    }

    // lifecycle
    ngOnInit() {
        super.ngOnInit();
        this.supportedWorkspaceFilters = ['animal-filter'];

        this.initialize();

        this.dataContext.onCancel$.pipe(takeUntil(this.notifier$)).subscribe(() => {
            this.changeView(this.LIST_VIEW);
        });
        
        this.createPaginator();
    }

    ngOnDestroy() {
        super.ngOnDestroy();
        if (this.addBirthInMatingSubscription) {
            this.addBirthInMatingSubscription.unsubscribe();
        }

        if (this.componentFilterSubscription) {
            this.componentFilterSubscription.unsubscribe();
        }

        this.notifier$.next();
        this.notifier$.complete();
    }

    async initialize() {
        this.restoreFilterState();
        await this.getDefaults();
        this.changeView(this.LIST_VIEW);
    }


    async getDefaults() {
        const value: cv_BirthStatus = await this.vocabularyService.getCVDefault('cv_BirthStatuses');
        if (value) {
            this.defaults.birthStatusKey = value.C_BirthStatus_key;
        }
    }

    refreshData() {
        this.initialize();
        this.reloadTable();
    }

    restoreFilterState() {

        // process any grid filters
        if (this.facet && this.facet.GridFilter) {
            try {
                this.filter = JSON.parse(this.facet.GridFilter);
            } catch (err) {
                console.error(err);
            }

            if (this.filter) {
                this.filter.DateBornStart = filterToDate(this.filter.DateBornStart);
                this.filter.DateBornEnd = filterToDate(this.filter.DateBornEnd);
                this.filter.DateMatingStart = filterToDate(this.filter.DateMatingStart);
                this.filter.DateMatingEnd = filterToDate(this.filter.DateMatingEnd);
                this.filter.DateWeanStart = filterToDate(this.filter.DateWeanStart);
                this.filter.DateWeanEnd = filterToDate(this.filter.DateWeanEnd);
            } else {
                this.filter = {};
            }
        }
    }

    async loadItemsList(tableState: TableState): Promise<DataResponse<Entity<Birth>>> {
        this.tableState = tableState;

        this.setLoadingState(tableState.loadingMessage);

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'DateCreated DESC';
        const queryDef: QueryDef = {
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter()
        };

        try {
            const response = await this.birthService.getBirths(queryDef);

            const visibleColumns = this.getVisibleColumns(this.birthTableOptions.options);
            await this.birthService.ensureVisibleColumnsDataLoaded(response.results, visibleColumns);

            this.stopLoading();

            this.data = response.results as Entity<Birth>[];
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        } finally {
            this.stopLoading();
        }
    }


    // Add Items
    private switchToDetailView(item: Entity<Birth>): void {
        this.itemToEdit = item;
        this.changeView(this.DETAIL_VIEW);
    }

    addItemClick(): void {
        if (this.privilegeService.readwrite) {
            this.setLoadingState();
            const birth = this.createNewBirth();
            this.switchToDetailView(birth);
            this.stopLoading();
        }
    }

    createNewBirth(): Entity<Birth> & ExtendedBirth {
        const birth = this.birthService.createBirth() as Entity<Birth> & ExtendedBirth;

        birth.birthAnimals = [];
        birth.pools = [];

        birth.C_BirthStatus_key = this.defaults.birthStatusKey;
        birth.DateBorn = this.birthLogicService.midnightTodayMilliseconds() as any as Date;
        birth.BirthID = '';
        birth.C_MaterialPool_key = null;

        return birth;
    }

    addBirthInMating = (sourceBirth: any) => {
        if (this.privilegeService.readwrite && sourceBirth) {
            const birth = this.createNewBirth();

            birth.C_MaterialPool_key = sourceBirth.Mating.C_MaterialPool_key;

            this.switchToDetailView(birth);
        }
    }


    // Filter
    openFilter() {
        const ref = this.modalService.open(BirthFilterComponent, { size: 'lg' });
        const component = ref.componentInstance as BirthFilterComponent;
        component.filter = this.filter;
        this.componentFilterSubscription = component.onFilter.subscribe((filter: any) => {
            this.filter = filter;
            this.runFilter();
        });
    }

    onWorkspaceFilterChange(wsFilterEvent: WsFilterEvent) {
        const oldFilterSupported = this.workspaceFilterSupported(wsFilterEvent.oldFilterKind);
        const newFilterSupported = this.workspaceFilterSupported(wsFilterEvent.filterKind);
        if (!this.ignoreWorkspaceFilter && (oldFilterSupported || newFilterSupported)) {
            this.reloadTable();
        }
    }

    doBulkDelete() {
        const itemsToDelete = this.selectedRows;
        if (itemsToDelete.length === 0) {
            return;
        }

        // Are there any associated animals that will be deleted, too?
        this.getAnimalsToDelete(itemsToDelete).then((data) => {
            const animalsToDelete: any[] = data;
            const animalsToDeleteCount = animalsToDelete.length;

            let modalTitle = '';
            let modalMessage = '';
            if (animalsToDeleteCount > 0) {
                modalTitle = 'Delete Births and Animals';
                modalMessage = 'Delete selected births and ' +
                    animalsToDeleteCount +
                    ' associated ' +
                    (animalsToDeleteCount === 1 ? 'animal' : 'animals') +
                    '? This action cannot be undone.';
            } else {
                modalTitle = 'Delete Births';
                modalMessage = 'Delete selected births? This action cannot be undone.';
            }

            return this.confirmService.confirmDelete(
                modalTitle,
                modalMessage
            ).then(
                // confirmed
                () => {
                    this.animalService.bulkDeleteAnimals(animalsToDelete).then((result) => {
                        if (result.data.HasAssociatedData) {
                            const title = 'Animals with Data';
                            const message = 'Births cannot be deleted because this ' + (result.data.Names.length === 1 ? 'animal has' : 'animals have') + ' associated data and cannot be removed:';
                            const details = result.data.Names;
                            const confirmOptions: ConfirmOptions = {
                                title,
                                message,
                                yesButtonText: 'OK',
                                onlyYes: true,
                                details
                            };

                            return this.confirmService.confirm(confirmOptions);
                        } else {
                            this.facetLoadingState.changeLoadingState(true);
                            if (animalsToDelete) {
                                for (const animal of animalsToDelete) {
                                    const material = animal.Material;
                                    this.animalService.deleteAnimal(animal);
                                    this.materialService.deleteMaterial(material);
                                }
                            }
                            const promises: Promise<any>[] = [];
                            for (const birth of itemsToDelete) {
                                promises.push(
                                    this.birthService.deleteBirth(birth)
                                );
                            }
                            Promise.all(promises).then(() => {
                                return this.dataContext.save();
                            }).then(() => {
                                this.reloadTable();
                            }).then(() => {
                                this.facetLoadingState.changeLoadingState(false);
                            }).catch((error) => {
                                this.facetLoadingState.changeLoadingState(false);
                                throw error;
                            });
                        }
                    });                    
                },
                // cancel
                () => { /* do nothing on cancel */ }
                );
        });
    }
       
    private getAnimalsToDelete(births: any[]): Promise<any[]> {
        return this.birthService.getAnimalsForBirths(births);
    }

    async clickBulkAssignTasks(): Promise<void> {
        try {
            const taskValues = await this.viewAddTaskComponentService.openComponent(TaskType.Birth);
            this.facetLoadingState.changeLoadingState(true);
    
            const newTaskInstances = await this.createTasksForSelectedBirths(taskValues);
    
            if (notEmpty(newTaskInstances)) {
                await this.saveChangesService.saveChanges(this.componentName);
                this.loggingService.logSuccess(
                    `Added new tasks for ${this.selectedRows.length} births`,
                    null,
                    this.componentName,
                    true
                );
            }
    
            this.facetLoadingState.changeLoadingState(false);
        } catch (error) {
            this.facetLoadingState.changeLoadingState(false);
            throw error;
        }
    }

    async clickBulkAssignProtocol(): Promise<void> {
        try {
            const taskValues = await this.viewAddProtocolComponentService.openComponent(TaskType.Birth);
            this.facetLoadingState.changeLoadingState(true);
    
            const newTaskInstances = await this.createTasksForSelectedBirths(taskValues);
    
            if (notEmpty(newTaskInstances)) {
                await this.saveChangesService.saveChanges(this.componentName);
                this.loggingService.logSuccess(
                    `Added new tasks for ${this.selectedRows.length} births`,
                    null,
                    this.componentName,
                    true
                );
            }
    
            this.facetLoadingState.changeLoadingState(false);
        } catch (error) {
            this.facetLoadingState.changeLoadingState(false);
            throw error;
        }
    }

    async createTasksForSelectedBirths(taskValues: any[]): Promise<TaskInstance[]> {
        if (!notEmpty(taskValues)) {
            return [];
        }
    
        await this.birthService.ensureTasksLoaded(this.selectedRows);
        const taskStatusDefault = await this.getDefaultTaskStatus();
        const taskInstances: TaskInstance[] = [];
    
        for (const birth of this.selectedRows) {
            let protocolInstance = null;
            if (notEmpty(taskValues) && taskValues[0].protocol) {
                protocolInstance = this.createProtocolInstance(taskValues[0].protocol, birth);
            }
    
            for (const taskInstanceValues of taskValues) {
                if (taskStatusDefault) {
                    taskInstanceValues.C_TaskStatus_key = taskStatusDefault.C_TaskStatus_key;
                }
    
                // copy values to edit for this birth
                const initialTaskValues = { ...taskInstanceValues };
                if (protocolInstance) {
                    initialTaskValues.C_ProtocolInstance_key = protocolInstance.C_ProtocolInstance_key;
                }
    
                const newTaskInstance = await this.taskService.createTaskInstance(initialTaskValues);
    
                const taskBirthValues = {
                    C_Birth_key: birth.C_Birth_key,
                    C_TaskInstance_key: newTaskInstance.C_TaskInstance_key,
                    Sequence: maxSequence(birth.TaskBirth) + 1
                };
                this.taskService.createTaskBirth(taskBirthValues);
    
                taskInstances.push(newTaskInstance);
            }
        }
    
        await this.ensureDataCalculatorCVsLoaded();
        this.calculateProtocolDates(taskInstances);
    
        return taskInstances;
    }

    createProtocolInstance(protocol: any, birth: any): any {
        const protocolInstances = uniqueArrayFromPropertyPath(
            birth, 'TaskBirth.TaskInstance.ProtocolInstance'
        );
        const protocolInitialValues = {
            C_Protocol_key: protocol.C_Protocol_key,
            ProtocolAlias: this.taskService.generateProtocolAlias(
                protocolInstances, protocol
            )
        };
        return this.taskService.createProtocolInstance(
            protocolInitialValues
        );
    }

    calculateProtocolDates(taskInstances: any[]) {
        if (!notEmpty(taskInstances) ||
            !taskInstances[0].C_ProtocolTask_key
        ) {
            // no protocol to process
            return;
        }
        const protocolDateCalculator = new ProtocolDateCalculator();
        for (const task of taskInstances) {
            protocolDateCalculator.scheduleMaterialDueDates(taskInstances, task);
        }
    }

    private async getDefaultTaskStatus(): Promise<any> {
        const preferLocal = true;
        return this.vocabularyService.getCVDefault('cv_TaskStatuses', preferLocal);
    }

    private async ensureDataCalculatorCVsLoaded(): Promise<any> {
        return Promise.all([
            this.vocabularyService.ensureCVLoaded('cv_ScheduleTypes'),
            this.vocabularyService.ensureCVLoaded('cv_TimeRelations'),
            this.vocabularyService.ensureCVLoaded('cv_TimeUnits'),
        ]);
    }

    async selectedColumnsChange({ visible }: ColumnsState) {
        try {
            this.facetLoadingState.changeLoadingState(true);
            await this.birthService.ensureVisibleColumnsDataLoaded(this.data, visible);
        } finally {
            this.facetLoadingState.changeLoadingState(false);
        }
    }
}

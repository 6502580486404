import { FacetSharedModule } from './../common/facet/facet-shared.module';
import { ChartFilterInputComponent } from './filters/chart-filter-input.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { ClimbServicesModule } from '../services/climb-services.module';

import { ClimbCommonModule } from '../common/climb-common.module';
import { JobsSharedModule } from '../jobs/jobs-shared.module';
import { GenotypesSharedModule } from '../genotypes/genotypes-shared.module';
import { LinesSharedModule } from '../lines/lines-shared.module';
import { SearchSharedModule } from '../search/search-shared.module';
import { TasksSharedModule } from '../tasks/tasks-shared.module';
import { UsersSharedModule } from '../user/users-shared.module';
import { WorkflowsSharedModule } from '../workflow/workflows-shared.module';
import { WorkspacesSharedModule } from '../workspaces/workspaces-shared.module';

import { ChartsFacetComponent } from './charts-facet.component';

import { BreedingEfficiencyChartComponent } from './breeding-efficiency-chart.component';
import {
    KaplanMeierChartComponent,
    KaplanMeierChartRendererComponent
} from './kaplan-meier';

import {
    TaskOutputChartRendererComponent,
    TaskOutputChartComponent
} from './task-output';

import {
    JobsGanttChartRendererComponent,
    JobsGanttChartComponent
} from './jobs-gantt';

import { ChartFilterGroupCreatorComponent } from './filters';

import { ChartService } from './chart.service';
import { VocabulariesSharedModule } from '../vocabularies/vocabularies-shared.module';
import { StudiesSharedModule } from '../studies/studies-shared.module';
import { DxChartModule } from 'devextreme-angular/ui/chart';

import { TaskOutputByNameChartComponent } from './task-output-by-name/task-output-by-name-chart.component';

import { DxGanttModule, DxButtonModule } from 'devextreme-angular';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgbModule,
        ClimbCommonModule,
        ClimbServicesModule,
        FacetSharedModule,
        GenotypesSharedModule,
        JobsSharedModule,
        LinesSharedModule,
        TasksSharedModule,
        UsersSharedModule,
        SearchSharedModule,
        WorkflowsSharedModule,
        WorkspacesSharedModule,
        VocabulariesSharedModule,
        DxGanttModule,
        DxButtonModule,
        StudiesSharedModule,
        DxChartModule
    ],
    declarations: [
        BreedingEfficiencyChartComponent,
        ChartsFacetComponent,
        ChartFilterGroupCreatorComponent,
        KaplanMeierChartComponent,
        KaplanMeierChartRendererComponent,
        TaskOutputChartComponent,
        TaskOutputChartRendererComponent,
        JobsGanttChartComponent,
        JobsGanttChartRendererComponent,
        ChartFilterInputComponent,
        TaskOutputByNameChartComponent
    ],
    exports: [
        ChartsFacetComponent,
        TaskOutputByNameChartComponent
    ],
    providers: [
        ChartService
    ]
})
export class ChartsModule { }

import { Component, Input } from '@angular/core';

@Component({
    selector: 'boolean-filter',
    template: `
        <form class="form-inline" *ngIf="node">
            <div class="form-check">
                <label class="form-check-label">
                    <input
                            type="radio"
                            class="form-check-input"
                            [(ngModel)]="node.value"
                            [ngModelOptions]="{standalone: true}"
                            [value]="null">
                    No filter
                </label>
            </div>
            <div class="form-check">
                <label class="form-check-label">
                    <input
                            type="radio"
                            class="form-check-input"
                            [(ngModel)]="node.value"
                            [ngModelOptions]="{standalone: true}"
                            [value]="true">
                    Filter on true
                </label>
            </div>
            <div class="form-check">
                <label class="form-check-label">
                    <input
                            type="radio"
                            class="form-check-input"
                            [(ngModel)]="node.value"
                            [ngModelOptions]="{standalone: true}"
                            [value]="false">
                    Filter on false
                </label>
            </div>
        </form>
    `,
})
export class BooleanFilterComponent {
    @Input() node: any = null;
}

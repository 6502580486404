import { BulkAddCommService } from './../common/facet/bulk-add-comm.service';
import { MatingBulkEditComponent } from './bulkedit/mating-bulk-edit.component';
import {
    Component,
    Input,
    OnInit,
    ViewChild,

    OnDestroy
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { MaterialPoolService } from '../services/material-pool.service';
import { MatingService } from './mating.service';
import { TranslationService } from '../services/translation.service';
import { VocabularyService } from '../vocabularies/vocabulary.service';
import { WorkspaceFilterService } from '../services/workspace-filter.service';

import { WsFilterEvent } from '../services/ws-filter-event';

import {
    BaseFacet,
    BaseFacetService
} from '../common/facet';

import { MatingTableOptions } from './mating-table-options';
import { MatingFilterComponent } from './mating-filter.component';

import {
    CellFormatterService,
    TableState,
    DataResponse,
    ColumnsState,
    TableColumnDef,
} from '@common/datatable';
import { QueryDef } from '../services/query-def';
import { DateFormatterService } from '@common/util/date-time-formatting';
import { filterToDate, maxSequence, notEmpty, uniqueArrayFromPropertyPath } from '@common/util';
import { ReportingService } from '../reporting/reporting.service';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { TaskType } from '../tasks/models';
import { ViewAddProtocolComponentService, ViewAddTaskComponentService } from '../tasks/add-task';
import { SaveChangesService } from '../services/save-changes.service';
import { TaskService } from '../tasks/task.service';
import { ProtocolDateCalculator } from '../tasks/tables/protocol-date-calculator';
import { Entity, Mating, TaskMaterialPool } from '../common/types';
import { SettingService } from '../settings/setting.service';
import { DataContextService } from '@services/data-context.service';
import { takeUntil } from 'rxjs/operators';
import { arrowClockwise, brokenChain, chain, magnifier } from '@icons';
interface ExtendedMatings extends Mating {
    TaskMaterialPool: TaskMaterialPool[];
}

@Component({
    selector: 'mating-facet',
    templateUrl: './mating-facet.component.html',
    providers: BaseFacet.BASE_COMPONENT_PROVIDERS.concat([
        BulkAddCommService
    ])
})
export class MatingFacetComponent extends BaseFacet<Entity<ExtendedMatings>> implements OnInit, OnDestroy {
    @Input() facetId: string;
    @Input() facet: any;

    @ViewChild('bulkEdit') bulkEdit: MatingBulkEditComponent;

    readonly icons = { arrowClockwise, brokenChain, chain, magnifier };

    componentName = 'mating';

    matingTableOptions: MatingTableOptions;

    // Active and required fields set by facet settings
    activeFields: string[] = [];
    inactiveFields: string[] = [];
    requiredFields: string[] = [];

    readonly COMPONENT_LOG_TAG = 'mating-facet';

    componentFilterSubscription: Subscription;
    private notifier$ = new Subject<void>();

    dataTableColumns: BehaviorSubject<TableColumnDef[]>;
    dataTableColumns$: Observable<TableColumnDef[]>;

    constructor(
        baseFacetService: BaseFacetService,
        private cellFormatterService: CellFormatterService,
        private materialPoolService: MaterialPoolService,
        private matingService: MatingService,
        private modalService: NgbModal,
        private translationService: TranslationService,
        private vocabularyService: VocabularyService,
        workspaceFilterService: WorkspaceFilterService,
        private reportingService: ReportingService,
        private viewAddTaskComponentService: ViewAddTaskComponentService,
        private viewAddProtocolComponentService: ViewAddProtocolComponentService,
        private saveChangesService: SaveChangesService,
        private dateFormatterService: DateFormatterService,
        private taskService: TaskService,
        private settingService: SettingService,
        private dataContext: DataContextService,
    ) {
        super(
            baseFacetService,
            workspaceFilterService
        );

        this.matingTableOptions = new MatingTableOptions(
            this.cellFormatterService,
            this.translationService,
            this.dateFormatterService
        );

        this.dataTableColumns = new BehaviorSubject(this.matingTableOptions.options.columns);
        this.dataTableColumns$ = this.dataTableColumns.asObservable();

        this.dataService = {
            run: (tableState: TableState) => {
                return this.loadItemsList(tableState);
            }
        };
    }

    // lifecycle
    ngOnInit() {
        super.ngOnInit();
        this.supportedWorkspaceFilters = ['animal-filter'];

        this.initialize();

        this.dataContext.onCancel$.pipe(takeUntil(this.notifier$)).subscribe(() => {
            this.changeView(this.LIST_VIEW);
        });
    }

    ngOnDestroy() {
        this.componentFilterSubscription?.unsubscribe();
        this.notifier$.next();
        this.notifier$.complete();
    }

    initialize() {
        this.restoreFilterState();
        this.changeView(this.LIST_VIEW);

        return this.settingService.getFacetSettingsByType('mating', undefined, undefined).then((facetSettings) => {
            this.activeFields = this.settingService.getActiveFields(facetSettings);
            this.inactiveFields = this.settingService.getInactiveFields(facetSettings);
            this.requiredFields = this.settingService.getRequiredFields(facetSettings);
        });
    }

    refreshData() {
        this.initialize();
        this.dataTableComm.reloadTable();
    }

    restoreFilterState() {

        // process any grid filters
        if (this.facet && this.facet.GridFilter) {
            try {
                this.filter = JSON.parse(this.facet.GridFilter);
            } catch (err) {
                console.error(err);
            }

            if (this.filter) {
                this.filter.dateMatingStart = filterToDate(this.filter.dateMatingStart);
                this.filter.dateMatingEnd = filterToDate(this.filter.dateMatingEnd);
            } else {
                this.filter = {};
            }
        }
    }

    async loadItemsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        this.setLoadingState(tableState.loadingMessage);

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'DateCreated DESC';
        const expands: string[] = [
            'MaterialPool.MaterialPoolMaterial.Material.Animal.Genotype.cv_GenotypeSymbol',
            'MaterialPool.MaterialPoolMaterial.Material.Animal.Genotype.cv_GenotypeAssay',
            'MaterialPool.MaterialPoolMaterial.Material.Animal.cv_Sex',
            'MaterialPool.Note'
        ];

        const queryDef: QueryDef = {
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter(),
            expands
        };

        try {
            const response = await this.matingService.getMatings(queryDef);

            const visibleColumns = this.getVisibleColumns(this.matingTableOptions.options);
            await this.matingService.ensureVisibleColumnsDataLoaded(response.results, visibleColumns);

            this.stopLoading();

            this.data = response.results as Entity<ExtendedMatings>[];
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        } finally {
            this.stopLoading();
        }
    }

    addItemClick() {
        this.setLoadingState();
        this.createNewItem().then((item) => {
            this.stopLoading();
            this.itemToEdit = item;
            this.changeView(this.DETAIL_VIEW);
        }).catch((error) => {
            this.loading = false;
            this.loggingService.logError("An unexpected error occurred. Please try again", error, this.componentName, true);
        });
    }

    async createNewItem(): Promise<Entity<ExtendedMatings>> {
        const materialPoolTypeDefault = await this.vocabularyService.getCVByFieldEquals('cv_MaterialPoolTypes', 'IsDefaultMating', 'true');

        return this.materialPoolService.createMaterialPoolAsType(materialPoolTypeDefault[0].MaterialPoolType)
            .then((newMaterialPool) => {
                const newMating = this.matingService.createMating();

                newMating.MaterialPool = newMaterialPool;
                newMating.DateMating = new Date();

                this.vocabularyService.getCVDefault('cv_MatingStatuses').then((value) => {
                    newMating.cv_MatingStatus = value;
                });
                this.vocabularyService.getCVDefault('cv_MatingTypes').then((value) => {
                    newMating.cv_MatingType = value;
                });
                this.vocabularyService.getCVDefault('cv_MatingPurposes').then((value) => {
                    newMating.cv_MatingPurpose = value;
                });
                this.vocabularyService.getCVDefault('cv_Generations').then((value) => {
                    newMating.cv_Generation = value;
                });

                return newMating;
            });
    }

    openFilter() {
        const ref = this.modalService.open(MatingFilterComponent, { size: 'lg' });
        const component = ref.componentInstance as MatingFilterComponent;
        component.filter = this.filter;
        this.componentFilterSubscription = component.onFilter.subscribe((filter: any) => {
            this.filter = filter;
            this.runFilter();
        });
    }

    onWorkspaceFilterChange(wsFilterEvent: WsFilterEvent) {
        const oldFilterSupported = this.workspaceFilterSupported(wsFilterEvent.oldFilterKind);
        const newFilterSupported = this.workspaceFilterSupported(wsFilterEvent.filterKind);
        if (!this.ignoreWorkspaceFilter && (oldFilterSupported || newFilterSupported)) {
            this.reloadTable();
        }
    }  

    // Cage Cards
    requestCageCards() {
        const matingKeys = this.selectedRows.map((matingUnit: any) => {
            return matingUnit.C_MaterialPool_key;
        });
        this.reportingService.requestCageCardMating(matingKeys);
    }

    clickBulkAssignTasks(): Promise<void> {
        return this.viewAddTaskComponentService.openComponent(TaskType.Mating)
            .then((taskValues) => {
                this.facetLoadingState.changeLoadingState(true);
                return this.createTasksForSelectedMatings(taskValues);
            }).then((newTaskInstances) => {
                if (notEmpty(newTaskInstances)) {
                    return this.saveChangesService.saveChanges(this.componentName).then(() => {
                        this.loggingService.logSuccess(
                            "Added new tasks for " +
                            this.selectedRows.length +
                            " matings",
                            null, this.componentName, true
                        );
                    });
                }
            }).then(() => {
                this.facetLoadingState.changeLoadingState(false);
            }).catch((error) => {
                this.facetLoadingState.changeLoadingState(false);
                throw error;
            });
    }

    clickBulkAssignProtocol(): Promise<void> {
        return this.viewAddProtocolComponentService.openComponent(TaskType.Mating)
            .then((taskValues) => {
                this.facetLoadingState.changeLoadingState(true);
                return this.createTasksForSelectedMatings(taskValues);
            }).then((newTaskInstances) => {
                if (notEmpty(newTaskInstances)) {
                    return this.saveChangesService.saveChanges(this.componentName).then(() => {
                        this.loggingService.logSuccess(
                            "Added new tasks for " +
                            this.selectedRows.length +
                            " matings",
                            null, this.componentName, true
                        );
                    });
                }
            }).then(() => {
                this.facetLoadingState.changeLoadingState(false);
            }).catch((error) => {
                this.facetLoadingState.changeLoadingState(false);
                throw error;
            });
    }

    createTasksForSelectedMatings(taskValues: any[]): Promise<any[]> {
        if (!notEmpty(taskValues)) {
            return Promise.resolve([]);
        }

        return this.matingService.ensureTasksLoaded(this.selectedRows).then(() => {
            return this.getDefaultTaskStatus();
        }).then((taskStatusDefault: any) => {
            const taskInstances: any[] = [];

            let promise: Promise<void> = Promise.resolve();

            for (const mating of this.selectedRows) {
                let protocolInstance: any = null;
                if (notEmpty(taskValues) && taskValues[0].protocol) {
                    protocolInstance = this.createProtocolInstance(
                        taskValues[0].protocol, mating
                    );
                }

                for (const taskInstanceValues of taskValues) {
                    if (taskStatusDefault) {
                        taskInstanceValues.C_TaskStatus_key =
                            taskStatusDefault.C_TaskStatus_key;
                    }

                    // copy values to edit for this mating
                    const initialTaskValues = { ...taskInstanceValues };
                    if (protocolInstance) {
                        initialTaskValues.C_ProtocolInstance_key =
                            protocolInstance.C_ProtocolInstance_key;
                    }

                    promise = promise.then(() => {
                        return this.taskService.createTaskInstance(initialTaskValues);
                    }).then((newTaskInstance) => {
                        const taskMaterialPoolValues = {
                            C_MaterialPool_key: mating.C_MaterialPool_key,
                            C_TaskInstance_key: newTaskInstance.C_TaskInstance_key,
                            Sequence: maxSequence(mating.TaskMaterialPool) + 1
                        };
                        this.taskService.createTaskMaterialPool(taskMaterialPoolValues);

                        taskInstances.push(newTaskInstance);
                    });
                }
            }

            return promise.then(() => {
                return taskInstances;
            });
        }).then((newTaskInstances) => {
            // need schedule types for calculating protocol dates
            return this.ensureDataCalculatorCVsLoaded().then(() => {
                this.calculateProtocolDates(newTaskInstances);

                return newTaskInstances;
            });
        });
    }

    createProtocolInstance(protocol: any, materialPool: any): any {
        const protocolInstances = uniqueArrayFromPropertyPath(
            materialPool, 'TaskMaterialPool.TaskInstance.ProtocolInstance'
        );
        const protocolInitialValues = {
            C_Protocol_key: protocol.C_Protocol_key,
            ProtocolAlias: this.taskService.generateProtocolAlias(
                protocolInstances, protocol
            )
        };
        return this.taskService.createProtocolInstance(
            protocolInitialValues
        );
    }

    calculateProtocolDates(taskInstances: any[]) {
        if (!notEmpty(taskInstances) ||
            !taskInstances[0].C_ProtocolTask_key
        ) {
            // no protocol to process
            return;
        }
        const protocolDateCalculator = new ProtocolDateCalculator();
        for (const task of taskInstances) {
            protocolDateCalculator.scheduleMaterialDueDates(taskInstances, task);
        }
    }

    private getDefaultTaskStatus(): Promise<any> {
        const preferLocal = true;
        return this.vocabularyService.getCVDefault('cv_TaskStatuses', preferLocal);
    }

    private ensureDataCalculatorCVsLoaded(): Promise<any> {
        return Promise.all([
            this.vocabularyService.ensureCVLoaded('cv_ScheduleTypes'),
            this.vocabularyService.ensureCVLoaded('cv_TimeRelations'),
            this.vocabularyService.ensureCVLoaded('cv_TimeUnits'),
        ]);
    }

    exitDetail() {
        this.reloadTable();
        this.changeView(this.LIST_VIEW);
    }

    async selectedColumnsChange({ visible }: ColumnsState) {
        try {
            this.facetLoadingState.changeLoadingState(true);
            await this.matingService.ensureVisibleColumnsDataLoaded(this.data, visible);
        } finally {
            this.facetLoadingState.changeLoadingState(false);
        }
    }
}

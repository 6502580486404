import {
    Component,
    Input,
    OnInit
} from '@angular/core';

import { TableSort } from '../../common/models';

@Component({
    selector: 'location-sample-table',
    templateUrl: './location-sample-table.component.html'
})
export class LocationSampleTableComponent implements OnInit {
    @Input() samples: any[];
    @Input() tableSort: TableSort;


    constructor() {
        // do nothing
    }

    ngOnInit() {
        this.initialize();
    }

    initialize() {
        if (!this.samples) {
            return;
        }
    }
}

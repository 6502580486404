import { Observable, of, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { xCacheRefreshKey } from '@services/breeze-helpers';
import { RequestCache } from './request-cache.service';

@Injectable()
export class CacheInterceptor implements HttpInterceptor {
    constructor(
        private readonly cache: RequestCache,
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!isCacheable(request)) {
            this.cache.clear();
            return next.handle(request);
        }

        if (!isOData(request)) {
            return next.handle(request);
        }

        // cache-then-refresh
        if (isNeedRefresh(request)) {
            const requestClone = request.clone({
                params: request.params.delete(xCacheRefreshKey),
            });
            const results$ = sendRequest(requestClone, next, this.cache);

            return results$;
        }

        // cache-or-fetch
        return this.cache.get(request) ?? sendRequest(request, next, this.cache);
    }
}

function isCacheable(request: HttpRequest<any>): boolean {
    // Only GET requests are cacheable
    return request.method === 'GET';
}

function isOData(request: HttpRequest<any>): boolean {
    return request.url.includes('/odata/');
}

function isNeedRefresh(request: HttpRequest<any>): boolean {
    return Boolean(request.headers.get('x-cache-refresh'))
        || Boolean(request.params.get(xCacheRefreshKey));
}

/**
 * Get server response observable by sending request to `next()`.
 * Will add the response to the cache on the way out.
 */
function sendRequest(
    request: HttpRequest<any>,
    next: HttpHandler,
    cache: RequestCache,
): Observable<HttpEvent<any>> {
    const pendingRequest = new Subject<HttpEvent<any>>();
    cache.set(request, pendingRequest.asObservable());
    return next.handle(request).pipe(
        tap((event) => {
            if (event instanceof HttpResponse) {
                pendingRequest.next(event);
                pendingRequest.complete();
                cache.set(request, of(event));
            }
        })
    );
}

import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { QueryResult } from "breeze-client";
import { CharacteristicService } from "../../characteristics/characteristic.service";
import { Entity, TaxonCharacteristic } from "../../common/types";
import { SettingService } from "../setting.service";
import { DataContextService } from "@services/data-context.service";
import { Subscription } from "rxjs";

@Component({
    selector: "workgroup-animal-characteristics",
    templateUrl: "./workgroup-animal-characteristics.component.html"
})
export class WorkgroupAnimalCharacteristicsComponent implements OnInit, OnDestroy {
    @Input() readonly: boolean;

    selectedCharacteristicKeys: number[];

    taxonCharacteristicMap: { [key: string]: TaxonCharacteristic } = {};
    originTaxonCharacteristicMap: { [key: string]: TaxonCharacteristic } = {};
    subs: Subscription = new Subscription();

    constructor(
        private settingService: SettingService,
        private characteristicService: CharacteristicService,
        private dataContext: DataContextService,
    ) {}

    ngOnInit(): void {
        this.subs.add(this.dataContext.onRejectEntityChange$.subscribe((entity: Entity<unknown>) => {
            if (entity.entityType.shortName === 'TaxonCharacteristic') {
                const taxonCharacteristic = entity as unknown as TaxonCharacteristic;
                const orderKey = taxonCharacteristic.ListViewOrder;
                this.taxonCharacteristicMap[orderKey] = this.originTaxonCharacteristicMap[orderKey];
            }
        }));
        this.subs.add(this.dataContext.onCancel$.subscribe(() => this.setTaxonCharacteristicMap()));
        this.dataContext.getChanges();
        this.selectedCharacteristicKeys = new Array<number>(6).fill(null);
        this.setTaxonCharacteristicMap();
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    /**
     * Gets the TaxonCharacteristics that have been set as Workgroup Characteristics and group them by their List View Order.
     */
    setTaxonCharacteristicMap(): void {
        this.originTaxonCharacteristicMap = {};
        this.taxonCharacteristicMap = {};
        this.settingService.getTaxonCharacteristicsShownInListView().then((results: TaxonCharacteristic[]) => {
            for (const taxonCharacteristic of results) {
                this.selectedCharacteristicKeys[taxonCharacteristic.ListViewOrder - 1] = taxonCharacteristic.C_TaxonCharacteristic_key;
                this.taxonCharacteristicMap[taxonCharacteristic.ListViewOrder.toString()] = taxonCharacteristic;
            }
            this.originTaxonCharacteristicMap = { ...this.taxonCharacteristicMap };
        });
    }

    /**
     * Resets the old TaxonCharacteristic's List View order while setting the new one's. 
     * @param taxonCharacteristic
     * @param listOrder
     */
    setTaxonCharacteristicListOrder(taxonCharacteristic: TaxonCharacteristic, listOrder: number): void {
        this.selectedCharacteristicKeys[listOrder - 1] = taxonCharacteristic ? taxonCharacteristic.C_TaxonCharacteristic_key : null;

        const oldTaxonCharacteristic = this.taxonCharacteristicMap[listOrder.toString()];

        if (oldTaxonCharacteristic) {
            oldTaxonCharacteristic.ListViewOrder = null;
        }

        if (taxonCharacteristic) {
            this.taxonCharacteristicMap[listOrder.toString()] = taxonCharacteristic;
            taxonCharacteristic.ListViewOrder = listOrder;
        } else {
            this.taxonCharacteristicMap[listOrder.toString()] = null;
        }

    }

    searchTaxonCharacteristics = (characteristicName: string): Promise<any> => {
        return this.characteristicService.getTaxonCharacteristics({ Name: characteristicName, IsActive: true }, false).then((result: QueryResult) => {
            const taxonCharacteristics = result.results;
            return taxonCharacteristics.filter((tc: any) => !this.selectedCharacteristicKeys.includes(tc.C_TaxonCharacteristic_key));
        });
    }

    searchTaxonCharacteristicsByKey = (characteristicKey: number): Promise<any> => {
        return this.characteristicService.getTaxonCharacteristics({ TaxonCharacteristicKey: characteristicKey, IsActive: true }, false).then((result: QueryResult) => {
            return result.results;
        });
    }
    taxonCharacteristicKeyFormatter = (value: TaxonCharacteristic) => {
        return value.C_TaxonCharacteristic_key;
    }

    taxonCharacteristicValueFormatter = (value: TaxonCharacteristic) => {
        return value.CharacteristicName;
    }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { ClimbCommonModule } from '../common/climb-common.module';

import { MatingSelectComponent } from './mating-select.component';
import { MatingService } from './mating.service';
import { MatingVocabService } from './mating-vocab.service';
import { MatingMultiselectComponent } from './mating-multiselect.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ClimbCommonModule
    ],
    declarations: [
        MatingSelectComponent,
        MatingMultiselectComponent
    ],
    exports: [
        MatingSelectComponent,
        MatingMultiselectComponent
    ],
    providers: [
        MatingService,
        MatingVocabService
    ]
})
export class MatingsSharedModule { }

import { orderBy } from '@lodash';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Component, ChangeDetectionStrategy } from '@angular/core';
import { DashboardDataService } from '../../services/dashboard-data.service';
import { TranslationService } from '@services/translation.service';

@Component({
    selector: 'climb-insights',
    templateUrl: './insights.component.html',
    styleUrls: ['./insights.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InsightsComponent {
    animalsByStrainMax = 0;
    animalsByStrain$: Observable<[string, number][]> = this.dashboardDataService.getColonyStatsByStrain().pipe(
        map((animals) => orderBy(animals, 1, 'desc')),
        tap(([animal]) => this.animalsByStrainMax = animal?.[1] ?? 0),
    );

    animalsByStatusMax = 0;
    animalsByStatus$: Observable<[string, number][]> = this.dashboardDataService.getColonyStats().pipe(
        map((animals) => orderBy(animals, 1, 'desc')),
        tap(([animal]) => this.animalsByStatusMax = animal?.[1] ?? 0),
    );

    jobsByTypeMax = 0;
    jobsByType$: Observable<[string, number][]> = this.dashboardDataService.currentJobsByType().pipe(
        map((jobs) => orderBy(jobs, 1, 'desc')),
        tap(([job]) => this.jobsByTypeMax = job?.[1] ?? 0),
    );

    tasksMax = 0;
    tasks$: Observable<[string, number][]> = this.dashboardDataService.getWorkflowStats().pipe(
        map((tasks) => orderBy(tasks, 1, 'desc')),
        tap(([task]) => this.tasksMax = task?.[1] ?? 0),
    );

    constructor(
        private dashboardDataService: DashboardDataService,
        private translationService: TranslationService
    ) { }

    translate(word: string) {
        return this.translationService.translate(word);
    }
}

<ng-container *ngIf="isAdmin === true">
    <seed-data-wizard></seed-data-wizard>
</ng-container>

<ng-container *ngIf="isAdmin === false">
    <div class="wizard-container container-fluid">

        <div class="card bg-success text-white">

            <div class="card-header">
                <h3 class="card-title">
                    Set Up Your Workgroup
                </h3>
            </div>

            <div class="card-body">

                <div class="text-warning">
                    <p>
                        <i class="fa fa-exclamation-triangle text" aria-hidden="true"></i>
                        This workgroup has not been set up yet.
                        Please contact the person in your organization responsible for setting up Climb.
                    </p>
                </div>

            </div><!-- /.card-body -->

        </div><!-- /.card -->

    </div><!-- /.wizard-container -->
</ng-container>

import type { LimitDefinition } from './limit-definition.interface';
import type { Line } from './line.interface';
import type { cv_Sex } from './cv-sex.interface';

export interface NumericLimit {
    AgeInWeeks: number | null;
    C_LimitDefinition_key: number;
    C_Line_key: number | null;
    C_NumericLimit_key: number;
    C_Output_key: number;
    C_Sex_key: number | null;
    C_Workgroup_key: number;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    HighLimit: number | null;
    LimitDefinition: LimitDefinition;
    Line: Line;
    LowLimit: number | null;
    ModifiedBy: string;
    Version: number;
    cv_Sex: cv_Sex;
}

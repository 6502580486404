import {
    CellFormatterService,
    TableOptions
} from '../common/datatable';
import { TranslationService } from '../services/translation.service';
import { notEmpty, getSafeProp } from '../common/util';
import { FeatureFlagService } from '../services/feature-flags.service';
import { AnimalOrder, JobOrder, Order, SampleOrder } from '@common/types';
import { addAuditColumnsToOptions, ignoreColumnsInOptions, pipe } from '@common/datatable/utils';

export class OrderTableOptions {
    options: TableOptions;
    isGLP = false;

    constructor(
        private cellFormatterService: CellFormatterService,
        private translationService: TranslationService,
        private featureFlagService: FeatureFlagService
    ) {
        this.initIsGLP();
        this.options = pipe(
            this.getDefaultOptions(),
            addAuditColumnsToOptions(cellFormatterService),
            ignoreColumnsInOptions(),
        );
    }

    getDefaultOptions(): TableOptions {
        return {
            csvFileName: 'order.csv',
            enableDetailColumn: true,
            enableDraggable: false,
            enableSelectable: false,
            refreshOnColumnChange: true,
            columnsForcedToExport: [
                {
                    displayName: 'Order Type',
                    field: 'cv_MaterialType.MaterialType',
                    forcedToExport: this.isGLP,
                    position: 2
                }
            ],
            columns: [
                {
                    displayName: 'Order ID',
                    field: 'OrderID'
                },
                {
                    displayName: 'Order Type',
                    field: 'cv_MaterialType.MaterialType',
                    ignore: this.isGLP
                },
                {
                    displayName: this.translationService.translate('Job'),
                    field: 'JobOrder.Job.JobID',
                    sortField: 'JobOrderJobSortable',
                    formatter: (row: Order, value: any) => {
                        const values = row.JobOrder.map((order: JobOrder) => {
                            return order.Job.JobID;
                        });
                        return values.join(', ');
                    }
                },
                {
                    displayName: 'Locations',
                    field: 'CurrentLocationPath'
                },
                {
                    displayName: 'PO Number',
                    field: 'InvoiceNumber'
                },
                {
                    displayName: 'Species',
                    field: 'cv_Taxon.CommonName'
                },
                {
                    displayName: 'Farm/Vendor',
                    field: 'Institution.Name'
                },
                {
                    displayName: 'Country of Origin',
                    field: 'cv_MaterialOrigin.MaterialOrigin'
                },
                {
                    displayName: 'Vendor Order Number',
                    field: 'VendorOrderNumber'
                },
                {
                    displayName: 'Vendor Room ID',
                    field: 'VendorRoomId',
                    visible: false
                },
                {
                    displayName: 'Number of Animals Ordered',
                    field: 'AnimalCount',
                    visible: false
                },
                {
                    displayName: 'Quarantine Services Facility',
                    field: 'cv_QuarantineFacility.QuarantineFacility',
                    visible: false
                },
                {
                    displayName: 'Shipment Animal Condition',
                    field: 'AnimalOrder.cv_ShipmentAnimalCondition.ShipmentAnimalCondition',
                    sortable: false,
                    visible: false,
                    formatter: (row: Order, value: any) => {
                        const values = row.AnimalOrder.map((order: AnimalOrder) => {
                            return order.cv_ShipmentAnimalCondition.ShipmentAnimalCondition;
                        });
                        return values.join(', ');
                    }
                },
                {
                    displayName: 'Shipment Container Condition',
                    field: 'AnimalOrder.cv_ShipmentContainerCondition.ShipmentContainerCondition',
                    sortable: false,
                    visible: false,
                    formatter: (row: Order, value: any) => {
                        const values = row.AnimalOrder.map((order: AnimalOrder) => {
                            return order.cv_ShipmentContainerCondition.ShipmentContainerCondition;
                        });
                        return values.join(', ');
                    }
                },
                {
                    displayName: 'Number of Animals',
                    field: 'AnimalOrder.AnimalCount',
                    sortable: false,
                    visible: false,
                    formatter: (row: Order, value: any) => {
                        const values = row.AnimalOrder
                                        .filter((order: AnimalOrder) => order.AnimalCount != null)
                                        .map((order: AnimalOrder) => {
                                            return order.AnimalCount;
                                        });
                        return values.join(', ');
                    }
                },
                {
                    displayName: 'Sex',
                    field: 'AnimalOrder.cv_Sex.Sex',
                    sortable: false,
                    visible: false,
                    formatter: (row: Order, value: any) => {
                        const values = row.AnimalOrder.map((order: AnimalOrder) => {
                            return order.cv_Sex.Sex;
                        });
                        return values.join(', ');
                    }
                },
                {
                    displayName: this.translationService.translate('Line'),
                    field: 'AnimalOrder.Line.LineName',
                    sortable: false,
                    visible: false,
                    formatter: (row: Order, value: any) => {
                        const values = row.AnimalOrder.map((order: AnimalOrder) => {
                            return order.Line.LineName;
                        });
                        return values.join(', ');
                    }
                },
                {
                    displayName: 'Sample Condition',
                    field: 'SampleOrder.cv_SampleCondition.SampleCondition',
                    sortField: 'SampleOrderSampleConditionSortable',
                    formatter: (row: Order, value: any) => {
                        const values = row.SampleOrder.map((sampleOrder: SampleOrder) => {
                            return sampleOrder.cv_SampleCondition.SampleCondition;
                        });
                        return values.join(', ');
                    }
                },
                {
                    displayName: 'CITES',
                    field: 'Cites',
                    visible: false
                },
                {
                    displayName: 'Date Ordered',
                    field: 'DateReceived',
                    visible: false,
                    formatter: this.cellFormatterService.dateFormatter
                },
                {
                    displayName: 'Projected Receipt Date',
                    field: 'DateProjectedReceipt',
                    visible: false,
                    formatter: this.cellFormatterService.dateFormatter
                },
            ]
        };
    }

    initIsGLP() {
        this.isGLP = this.featureFlagService.getIsGLP();
    }
}

import { RouteEnum } from './route.enum';
import { ClimbServicesModule } from './../services/climb-services.module';
import { AccessGuard } from './access.guard';
import { WorkgroupInitializerComponent } from './../wizard/workgroup-initializer.component';
import { WorkspaceViewComponent } from './../workspaces/workspace-view.component';
import { LoginViewComponent } from '../login/components/login-view/login-view.component';
import { LoginSamlComponent } from '../login/components/login-saml/login-saml.component';
import { DashboardViewComponent } from './../dashboard/dashboard-view.component';
import { WorkgroupModalViewComponent } from '../workgroup/workgroup-modal-view.component';
import { RegistrationEmailViewComponent } from '../register/components/registration-email-view/registration-email-view.component';
import { EmailVerificationViewComponent } from '../register/components/email-verification-view/email-verification-view.component';
import { PwdResetViewComponent } from '../pwdreset/components/pwd-reset-view/pwd-reset-view.component';
import { RequestPwdResetViewComponent } from '../pwdreset/components/request-pwd-reset-view/request-pwd-reset-view.component';
import { VersionsViewComponent } from '../versions/components/versions-view.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoggedOutViewComponent } from '../login/components/logged-out-view/logged-out-view.component';
import { RoutingService } from './routing.service';
import { MainLayoutComponent } from '@common/layouts';
import { OktaCallbackComponent } from "@okta/okta-angular";
import { LoginOktaComponent } from "../login/components/login-okta/login-okta.component";

const routes: Routes = [
    {
        path: '', // path for the Main Layout
        component: MainLayoutComponent,
        canActivate: [AccessGuard],
        children: [
            {
                path: RouteEnum.Dashboard,
                component: DashboardViewComponent,
            },
            {
                path: RouteEnum.Home,
                component: WorkgroupModalViewComponent,
            },
            {
                path: RouteEnum.Initialization,
                component: WorkgroupInitializerComponent,
            },
            {
                path: RouteEnum.Workspaces + '/:id',
                component: WorkspaceViewComponent,
            },
            {
                path: '',
                redirectTo: RouteEnum.Dashboard,
                pathMatch: 'full',
            },
        ],
    },
    {
        path: RouteEnum.Login,
        component: LoginViewComponent
    },
    {
        path: RouteEnum.SamlJwt,
        component: LoginSamlComponent,
    },
    {
        path: RouteEnum.PwdReset, 
        component: PwdResetViewComponent
    },
    {
        path: RouteEnum.RequestPwdReset, 
        component: RequestPwdResetViewComponent
    },
    {
        path: RouteEnum.PreRegistrationEmail,
        component: RegistrationEmailViewComponent
    },
    {
        path: RouteEnum.EmailVerification,
        component: EmailVerificationViewComponent
    },
    {
        path: RouteEnum.Versions,
        component: VersionsViewComponent,
    },
    {
        path: RouteEnum.LoginOkta,
        component: LoginOktaComponent,
    },
    {
        path: RouteEnum.LoginCallback,
        component: LoginOktaComponent,
    },
    // blank route
    { 
        path: '', 
        redirectTo: '/' + RouteEnum.Login, 
        pathMatch: 'full' 
    },
    {
        path: RouteEnum.LoggedOutRoute,
        component: LoggedOutViewComponent,
    },
    // route not found
    { 
        path: '**', 
        redirectTo: '/' + RouteEnum.Dashboard,
    },
];

@NgModule({
    imports: [
        ClimbServicesModule,
        RouterModule.forRoot(routes),
    ],
    exports: [RouterModule],
    providers: [
        AccessGuard,
        RoutingService,
    ],
})
export class ClimbRoutingModule {}

import { Injectable } from '@angular/core';

import { LineService } from '../lines/line.service';

/**
 * Common business logic for the Mating facet.
 */
@Injectable()
export class MatingLogicService {
    constructor(private lineService: LineService) {}
    
    onLineSelection(lineKey: number, mating: any): Promise<any> {
        if (!lineKey) {
            return Promise.resolve();
        }

        // Set mating taxon based on its line
        return this.getLineByKey(lineKey).then((fullLine) => {
            if (mating) {
                mating.Material.cv_Taxon = fullLine.cv_Taxon;
            }
        });
    }

    getLineByKey(lineKey: number): Promise<any> {
        return this.lineService.getLine(lineKey).then((data) => {
            return data;
        });
    }
}

<climb-auth-container>
    <div class="logged-out">
        <climb-alert-inline [message]="message"
                            status="error">
        </climb-alert-inline>

        <button climbButton
                variant="primary"
                size="lg"
                (click)="refresh()"
                type="submit">
            Refresh
        </button>
    </div>
</climb-auth-container>

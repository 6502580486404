import type { cv_ClinicalObservation } from './cv-clinical-observation.interface';
import type { cv_Modifier3 } from './cv-modifier3.interface';

export interface cv_ClinicalObservationModifier3 {
    C_ClinicalObservationModifier3_key: number;
    C_ClinicalObservation_key: number;
    C_Modifier3_key: number;
    C_Workgroup_key: number;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    ModifiedBy: string;
    Version: number;
    cv_ClinicalObservation: cv_ClinicalObservation;
    cv_Modifier3: cv_Modifier3;
}

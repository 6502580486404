import { CurrentWorkgroupService } from './current-workgroup.service';
import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';
import {
    EntityManager,
    EntityState
} from 'breeze-client';

import { EntityManagerFactoryService } from './entity-manager-factory.service';
import { BaseManagerService } from './base-manager.service';
import { LoggingService } from './logging.service';
import { LogoutService } from './logout.service';
import { TranslationService } from './translation.service';

/**
 * Entity Manager for the 'Data' database (used only for workspace tables)
 */
@Injectable()
export class WorkspaceManagerService extends BaseManagerService {

    // state variables
    private _managerPromise: Promise<void>;

    constructor(
        private authService: AuthService,
        private currentWorkgroupService: CurrentWorkgroupService,
        private entityManagerFactory: EntityManagerFactoryService,
        loggingService: LoggingService,
        logoutService: LogoutService,
        translationService: TranslationService,
    ) {
        super(loggingService, logoutService, translationService);
        this.entityManagerFactory = entityManagerFactory;
    }

    init() {
        if (!this._managerPromise) {
            this._managerPromise = this.entityManagerFactory.newDataManager()
                .then((manager) => {
                    this._manager = manager;
                });
        }
        return this._managerPromise;
    }

    getManager(): EntityManager {
        if (!this._manager) {
            throw new Error("Breeze entity manager for 'Workspace Data' not initialized.");
        }
        return this._manager;
    }

    /**
     * Save changes for a single property on a given entity
     */
    saveChangesToProperty(entity: any, propertyName: string): Promise<any> {
        const modifiedBy = this.authService.getCurrentUserName();
        return this._saveChangesToProperty(
            entity, propertyName, modifiedBy
        ).catch((error) => {
            this._adjustOdataErrorResponse(error);
            const logTag = 'workspace-manager';
            this.loggingService.logSaveError(error, logTag);
            throw error;
        });
    }

    save(): Promise<any> {
        let promise: Promise<any> = Promise.resolve();
        const saveBatches = this.prepareSaveBatches();

        for (const batch of saveBatches) {
            // ignore empty batches (except 'null' which means "save everything else")
            if (batch === null || batch.length > 0) {
                promise = promise.then(() => { 
                    return this._manager.saveChanges(batch); 
                });
            }
        }

        return promise.catch((error) => {
            this._adjustOdataErrorResponse(error);
            error.message = 'Save failed. ' + error.message || "";
            this.loggingService.logError(
                error.message, 
                error, 
                'workspace-manager', 
                false
            );
            throw error;
        });
    }

    prepareSaveBatches(): any[] {
        this.assignRequiredSystemValues();
        
        const manager = this._manager;
        const batches: any[] = [];
        batches.push(manager.getEntities(['WorkspaceDetail'], [EntityState.Deleted]));
        batches.push(manager.getEntities(['Workspace'], [EntityState.Deleted]));
        batches.push(manager.getEntities(['Workspace'], [EntityState.Added]));
        batches.push(manager.getEntities(['WorkspaceDetail'], [EntityState.Added]));
        // empty = save all remaining pending changes
        batches.push(null); 

        return batches;
    }

    assignRequiredSystemValues() {
        
        const currentUserName = this.authService.getCurrentUserName();
        const workgroupKey = this.currentWorkgroupService.getCurrentWorkgroupKey();
        const entities: any[] = this._manager.getEntities(
            null, [EntityState.Added, EntityState.Modified]
        );
        for (const entity of entities) {
            switch (entity.entityAspect.entityState) {
                case EntityState.Added:
                    entity.CreatedBy = currentUserName;
                    entity.DateCreated = Date.now();
                    entity.C_Workgroup_key = workgroupKey;

                    break;
                case EntityState.Modified:
                    entity.ModifiedBy = currentUserName;
                    entity.DateModified = Date.now();

                    break;
                default:
                    break;
            }
        }
    }
}

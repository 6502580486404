import { AnimalService } from '../../animals/services/animal.service';
import { VocabularyService } from './../../vocabularies/vocabulary.service';
import { PlateService } from './../../plates/plate.service';
import { GenotypeVocabService } from './../genotype-vocab.service';
import { GenotypeService } from './../genotype.service';
import { map } from 'rxjs/operators';
import { QueryDef } from './../../services/query-def';
import { 
    BulkEditOptions, 
    BulkEditSection 
} from '../../common/facet/models';
import {
    Component,
    Input,
    OnInit,
    TemplateRef,
    ViewChild,
    AfterViewInit,
    ViewChildren,
} from '@angular/core';

import { notEmpty, uniqueArrayFromPropertyPath, getSafeProp } from '../../common/util';

import { FacetLoadingStateService } from '../../common/facet';
import { TranslationService } from '../../services/translation.service';
import { cv_GenotypeAssay, cv_GenotypeSymbol, Entity, Genotype } from '../../common/types';
import { NgModel } from '@angular/forms';
import { dateControlValidator } from '@common/util/date-control.validator';

/**
 * Shared component and configuration templates
 * for BulkAdd and BulkEdit tables
 * 
 * 
 */
@Component({
    selector: 'genotype-bulk-templates',
    templateUrl: './genotype-bulk-templates.component.html'
})
export class GenotypeBulkTemplatesComponent implements OnInit, AfterViewInit {
    @Input() genotypes: Entity<Genotype>[];

    @ViewChildren('dateControl') dateControls: NgModel[];
    // bulk edit input templates
    @ViewChild('animalNameTmpl') animalNameTmpl: TemplateRef<any>;
    @ViewChild('sexTmpl') sexTmpl: TemplateRef<any>;
    @ViewChild('lineTmpl') lineTmpl: TemplateRef<any>;
    @ViewChild('dateTmpl') dateTmpl: TemplateRef<any>;
    @ViewChild('assayTmpl') assayTmpl: TemplateRef<any>;
    @ViewChild('symbolTmpl') symbolTmpl: TemplateRef<any>;
    @ViewChild('plateTmpl') plateTmpl: TemplateRef<any>;

    // CVs
    genotypeAssays: Entity<cv_GenotypeAssay>[] = null;
    genotypeSymbols: Entity<cv_GenotypeSymbol>[] = null;

    readonly COMPONENT_LOG_TAG = 'genotype-bulk-edit';

    bulkOptions: BulkEditOptions;
    BulkEditSection = BulkEditSection;

    constructor(
        private animalService: AnimalService,
        private genotypeService: GenotypeService,
        private genotypeVocabService: GenotypeVocabService,
        private plateService: PlateService,
        private facetLoadingState: FacetLoadingStateService,
        private vocabularyService: VocabularyService,
        private translationService: TranslationService
    ) {
    }

    // lifecycle
    ngOnInit() {
        this.initialize();
        this.getData();
    }

    /**
     * Configuration with TemplateRefs can only be assigned
     *   after "ngAfterViewInit".
     * Otherwise they will be undefined.
     */
    ngAfterViewInit() {
        // assign all the BulkAdd and BulkEdit configuration options
        this.bulkOptions = {
            itemTypeLabel: "Genotype",
            itemTypeLabelPlural: "Genotypes",
            clearForm: false,
            fields: [
                {
                    label: "Animal",
                    modelPath: 'C_Material_key',
                    template: this.animalNameTmpl,
                    hideFromAddScreen: true
                },
                {
                    label: "Sex",
                    modelPath: 'Animal._Sex_key',
                    template: this.sexTmpl,
                    hideFromAddScreen: true,
                    hideFromEditHeader: true
                },
                {
                    label: "Line",
                    modelPath: 'Animal.Material.C_Line_key',
                    template: this.lineTmpl,
                    hideFromAddScreen: true,
                    hideFromEditHeader: true
                },
                {
                    label: 'Date',
                    modelPath: 'DateGenotype',
                    template: this.dateTmpl,
                    onItemInit: (item: any) => {
                        const today = new Date();
                        today.setHours(0, 0, 0, 0);
                        item.DateGenotype = today;
                    }
                },
                {
                    label: 'Assay',
                    labelTooltip: `Values will only update if assay is valid for the` + this.translationService.translate('Lines') +
                        `. Valid assays are managed in the ` + this.translationService.translate('Line') + ` facet.`,
                    modelPath: 'C_GenotypeAssay_key',
                    template: this.assayTmpl,
                    onUpdateItem: (item, value) => {
                        this.fillDownAssay(value);
                    }
                },
                {
                    label: 'Genotype',
                    labelTooltip: `Values will only update if genotype is valid for the assay. 
                        Genotypes are managed in the Vocabularies facet.`,
                    modelPath: 'C_GenotypeSymbol_key',
                    template: this.symbolTmpl,
                    onUpdateItem: (item, value) => {
                        this.fillDownSymbol(value);
                    }
                },
                {
                    label: 'Plate',
                    modelPath: 'C_Plate_key',
                    template: this.plateTmpl,
                    hideFromAddScreen: true
                }
            ]
        };
    }

    initialize() {

         // Copy the input so we don't touch the grid data
        this.genotypes = this.genotypes.slice();
    }

    getData() {
        this.facetLoadingState.changeLoadingState(true);

        return this.getGenotypesDetails().then(() => {
            return this.getCVs();
        }).then(() => {
            this.facetLoadingState.changeLoadingState(false);
        }).catch((error) => {
            this.facetLoadingState.changeLoadingState(false);
            throw error;
        });
    }

    getGenotypesDetails(): Promise<Entity<Genotype>[]> {
        if (notEmpty(this.genotypes)) {
            const itemsFilter = {
                genotypeKeys: this.genotypes.map((genotype) => {
                    return genotype.C_Genotype_key;
                })
            };

            const queryDef: QueryDef = {
                page: 0,
                size: this.genotypes.length,
                filter: itemsFilter,
                inlineCount: false
            };

            return this.genotypeService.getGenotypes(queryDef).then((result) => {
                return result.results as Entity<Genotype>[];
            });
        }

        return Promise.resolve(this.genotypes);
    }

    getCVs(): Promise<[void, void, void]> {
        const p1: Promise<void> = this.genotypeVocabService.genotypeAssays$.pipe(map((data) => {
            this.genotypeAssays = data;
        })).toPromise();

        const p2: Promise<void> = this.genotypeVocabService.genotypeSymbols$.pipe(map((data) => {
            this.genotypeSymbols = data;
        })).toPromise();

        const p3 = this.vocabularyService.ensureCVLoaded('cv_Sexes');

        return Promise.all([p1, p2, p3]);
    }

    selectAnimal(materialKey: number) {
        if (materialKey) {
            this.getFullAnimal(materialKey);
        }
    }

    getFullAnimal(materialKey: number): Promise<any> {
        return this.animalService.getAnimal(materialKey).then((data) => {
            return data;
        });
    }

    selectPlate(plateKey: number) {
        if (plateKey) {
            return this.plateService.getPlateByKey(plateKey).then((data) => {
                return data;
            });
        }
    }

    fillDownAssay(assayKey: number) {
        
        for (const genotype of this.genotypes) {
            const line = getSafeProp(genotype, 'Animal.Material.Line');
            if (this.isAssayValid(line, assayKey)) {
                genotype.C_GenotypeAssay_key = assayKey;
            }
        }
    }

    isAssayValid(line: any, assayKey: number | string): boolean {
        if (!line || !assayKey) {
            return true;
        }

        if (assayKey) {
            assayKey = parseInt(<string> assayKey, 10);
        }

        const validAssayKeys = uniqueArrayFromPropertyPath(
            line, 'LineGenotypeAssay.C_GenotypeAssay_key'
        );

        return validAssayKeys.indexOf(assayKey) >= 0;
    }

    fillDownSymbol(symbolKey: number) {
        for (const genotype of this.genotypes) {
            if (this.isSymbolValid(genotype.cv_GenotypeAssay, symbolKey)) {
                genotype.C_GenotypeSymbol_key = symbolKey;
            }
        }
    }

    isSymbolValid(genotype: any, symbolKey: number | string): boolean {
        if (!genotype || !symbolKey) {
            return true;
        }

        if (symbolKey) {
            symbolKey = parseInt(<string> symbolKey, 10);
        }

        const validSymbolKeys = uniqueArrayFromPropertyPath(
            genotype, 'GenotypeAssayGenotypeSymbol.C_GenotypeSymbol_key'
        );

        return validSymbolKeys.indexOf(symbolKey) >= 0;
    }

    validate() {
        return dateControlValidator(this.dateControls);
    }
}

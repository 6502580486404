import { Injectable } from '@angular/core';

import { TranslationService } from '@services/translation.service';
import { UserNameService } from '../../user/user-name.service';
import * as exportUtils from '@common/export/utils';
import {
    getSafeProp,
    sortObjectArrayByAccessor,
    sortObjectArrayByProperty,
    weeksSinceAsString,
} from '@common/util';
import { DateFormatterService } from '@common/util/date-time-formatting';

import { TaskType } from '../../tasks/models';
import { formatTimePoint } from '../../samples/util';
import { ExporterFactory, ExportType } from '@common/export';
import { FileExporter } from '@common/export/file-exporter';
import { Sample, TaskInstance } from '@common/types';
import { EntityMap } from '../models/workflow-bulk-data';
import { TemplateRow } from '../workflow-bulk-data-entry/workflow-bulk-data-entry.component';

/*
* Export a taskInstance detail record to CSV
*/
@Injectable()
export class ExportWorkflowDetailService {
    exporter: FileExporter;

    constructor(
        private translationService: TranslationService,
        private userNameService: UserNameService,
        private dateFormatterService: DateFormatterService
    ) {
    }

    /*
    * Assumes task instance record has all relationships loaded
    */
    export(
        task: any,
        taskType: TaskType,
        workflowTaskOutputs: any[],
        exportType: ExportType
    ) {
        const filename = "TaskDetails.csv";
        const data: any[][] = this.buildExportData(task, taskType, workflowTaskOutputs, exportType);
        this.exporter = ExporterFactory.create(exportType, { isCustomContent: true });
        this.exporter.download(data, filename);
    }

    exportOutputsToCsv(
        workflowTaskOutputs: any[], templateRows: TemplateRow[], isGLP: boolean,
        includeAnimalNames: boolean, includeSampleNames: boolean, isBDE: boolean
        ) {
        let filename: string;
        if (isBDE) {
            filename = "Outputs-Workflow-Bulk-Data-Entry.csv";
        } else {
            filename = "Outputs-Workflow-Details.csv";
        }
        const data: any[][] = this.buildOutputExportData(workflowTaskOutputs, templateRows, isGLP, includeAnimalNames, includeSampleNames);
        this.exporter = ExporterFactory.create(ExportType.CSV);
        this.exporter.download(data, filename);
    }

    private buildExportData(
        task: TaskInstance,
        taskType: TaskType,
        workflowTaskOutputs: any[],
        exportType: ExportType
    ): any[] {
        let data: any[] = [];

        // Basic info
        data.push(
            ['Task Name', task.TaskAlias],
            ['Task', task.WorkflowTask.TaskName]
        );
        // For now only display locked for job tasks
        if (taskType === TaskType.Job) {
            data.push(['Locked', task.IsLocked]);
        }

        // Basic info by task type
        switch (taskType) {
            case TaskType.Animal:
                this.getTaskAnimalExportData(data, task);
                break;
            case TaskType.Birth:
                this.getTaskBirthExportData(data, task);
                break;
            case TaskType.Housing:
                this.getTaskHousingExportData(data, task);
                break;
            case TaskType.Job:
                this.getTaskJobExportData(data, task);
                break;
            case TaskType.Line:
                this.getTaskLineExportData(data, task);
                break;
            case TaskType.Mating:
                this.getTaskMatingExportData(data, task);
                break;
            default:
                console.error("Invalid task type: " + taskType);
                break;
        }

        // Remainder of basic info
        data.push(
            ['Due Date', this.dateFormatterService.formatDateOrTime(task.DateDue)],
            ['Complete Date', this.dateFormatterService.formatDateOrTime(task.DateComplete)],
            ['Cohorts Name', this.formatCohortsName(task.TaskCohort)],
            ['Status', getSafeProp(task, 'cv_TaskStatus.TaskStatus')],
            [
                'Assigned To',
                getSafeProp(task, 'AssignedToResource.ResourceName')
            ],
            ['Notes', this.getTaskNotesString(task)],
            ['Created By', this.userNameService.toFullName(task.CreatedBy)],
            [],
            [],
            // Task inputs
            ['Inputs'], 
            ['Name', 'Value']
        );

        const sortedTaskInputs = sortObjectArrayByAccessor(task.TaskInput, (item) => {
            return getSafeProp(item, 'Input.SortOrder');
        });
        for (const taskInput of sortedTaskInputs) {
            data.push([taskInput.Input.InputName, taskInput.InputValue]);
        }
        data.push.apply(data, [[], []]);

        // Task output header row
        data.push.apply(data, [['Outputs'], ]);
        let row: any[] = [];
        if (this.showAnimalData(taskType, task)) {
            row.push('Animals');
        }
        if (this.showSampleData(taskType, task)) {
            row.push('Samples');
        }

        // Animal data displayed for single animal per row tasks
        if (this.showAnimalData(taskType, task) && 
            task.WorkflowTask.SingleAnimal
        ) {
            row.push('Age (weeks)');
        }

        for (const workflowTaskOutput of sortObjectArrayByProperty(
            workflowTaskOutputs, 'SortOrder')
        ) {
            row.push(workflowTaskOutput.OutputName);

            row.push('Has Flag');
            row.push('Flags Messages');
        }
        row.push.apply(row,
            ['Collected On', 'Collected By']);
        data.push(row);

        // Actual task output data
        const outputSets = task.TaskOutputSet;
        sortObjectArrayByProperty(outputSets, 'DateCreated', true);

        for (const taskOutputSet of outputSets) {
            row = [];

            // Listing animals and samples involved in output
            const animals: any[] = exportUtils.materialTypeFilter(
                taskOutputSet.TaskOutputSetMaterial, 'Animal');
            const samples: any[] = exportUtils.materialTypeFilter(
                taskOutputSet.TaskOutputSetMaterial, 'Sample');
            if (this.showAnimalData(taskType, task)) {
                row.push(
                    animals
                    .map((a) => a.Material.Animal.AnimalName)
                    .join(', ')
                );
            }
            if (this.showSampleData(taskType, task)) {
                row.push(
                    samples
                    .map((a) => a.Material.Sample.SampleName)
                    .join(', ')
                );
            }

            // Animal data displayed for single animal per row tasks
            if (this.showAnimalData(taskType, task) &&
                task.WorkflowTask.SingleAnimal
            ) {
                let ageInWeeks = '';

                if (animals.length > 0) {
                    const animal = animals[0].Material.Animal;
                    ageInWeeks = weeksSinceAsString(
                        taskOutputSet.CollectionDateTime, animal.DateBorn
                    );
                }

                row.push(ageInWeeks);
            }

            // Individual output values
            const sortedTaskOutputs = sortObjectArrayByAccessor(taskOutputSet.TaskOutput, (item) => {
                return getSafeProp(item, 'Output.SortOrder');
            });
            for (const taskOutput of sortedTaskOutputs) {
                row.push(taskOutput.OutputValue === 'NaN' ? 0 : taskOutput.OutputValue);

                const hasFlag = taskOutput.HasFlag ? 'TRUE' : 'FALSE';
                row.push(hasFlag);
                row.push(taskOutput.FlagsMessages);
            }

            // Collection data
            row.push.apply(row, [
                this.dateFormatterService.formatDateOnly(taskOutputSet.CollectionDateTime),
                getSafeProp(taskOutputSet, 'Resource.ResourceName')
            ]);

            data.push(row);
        }
        data.push.apply(data, [[], []]);

        // Task animals
        if (this.showAnimalData(taskType, task)) {
            data.push.apply(data, [
                ['Animals'],
                ['Animal Name', 'Current Location', 'Sex', this.translationService.translate('Line'), 'Birth Date', 'Marker']
            ]);
            for (const animal of exportUtils.materialTypeFilter
                (task.TaskMaterial, 'Animal')) {
                data.push([
                    animal.Material.Animal.AnimalName,
                    animal.Material.CurrentLocationPath,
                    getSafeProp(animal.Material.Animal, "cv_Sex.Sex"),
                    getSafeProp(animal.Material, "Line.LineName"),
                    this.dateFormatterService.formatDateOnly(animal.Material.Animal.DateBorn),
                    animal.Material.Animal.PhysicalMarker
                ]);
            }
            data.push.apply(data, [[], []]);
        }

        // Task samples
        if (this.showSampleData(taskType, task)) {
            data.push.apply(data, [
                ['Sample'],
                [
                    'Sample Name',
                    'Current Location',
                    'Source Names',
                    'Sample Type',
                    'Time Point',
                    'Status',
                    this.translationService.translate('Line'),
                    'Preservation Method',
                    'Created By'
                ]
            ]);
            for (const sample of exportUtils.materialTypeFilter
                (task.TaskMaterial, 'Sample')) {
                let sourcesString = '';
                const sources: any[] = sample.Material.MaterialSourceMaterial;

                if (sources) {
                    sourcesString = sources
                        .map((s) => exportUtils.getMaterialName(s.SourceMaterial))
                        .join(', ');
                }

                data.push([
                    sample.Material.Sample.SampleName,
                    sample.Material.CurrentLocationPath,
                    sourcesString,
                    getSafeProp(sample.Material.Sample, "cv_SampleType.SampleType"),
                    formatTimePoint(
                        sample.Material.Sample.TimePoint, 
                        sample.Material.Sample.cv_TimeUnit
                    ),
                    getSafeProp(sample.Material.Sample, "cv_SampleStatus.SampleStatus"),
                    getSafeProp(sample.Material, "Line.LineName"),
                    getSafeProp(sample.Material.Sample, "cv_PreservationMethod.PreservationMethod"),
                    this.userNameService.toFullName(sample.Material.Sample.CreatedBy)
                ]);
            }
        }

        if (exportType === ExportType.PDF) {
            data = this.preparePDFData(data);
        }

        return data;
    }

    private preparePDFData(data: string[][]) {
        const maxColumns = Math.max.apply(null, data.map(item => item.length));

        return data.map(values => {
            const columns = Array.from({ length: maxColumns }, (_, index) => [
                {
                    text: values[index],
                    bold: index === 0,
                }
            ]);

            return {
                columns,
                columnGap: 0,
            };
        });
    }

    getTaskNotesString(task: any) {
        let noteString = '';
        if (task && task.Note && task.Note.length) {
            const notes = task.Note;
            noteString = notes.map((n: any) => `${n.NoteText} (${this.userNameService.toFullName(n.CreatedBy)} ${this.dateFormatterService.formatDateTime(n.DateCreated)})`).toString();
        }
        return noteString;
    }

    
    formatCohortsName(cohorts: any[]): string {
        let cohortsNames = "";
        for (const cohort of cohorts) {
            if (cohort.CohortName) {
                cohortsNames = cohortsNames + ", " + cohort.CohortName;
            }            
        }

        return cohortsNames;
    }

    getTaskAnimalExportData(data: any[][], task: any) {
        const animal = getSafeProp(task, 'TaskMaterial[0].Material.Animal');

        data.push.apply(data, [
            [
                'Animal Name',
                getSafeProp(animal, 'AnimalName')
            ],
            [
                'Animal ID',
                getSafeProp(animal, 'Material.Identifier')
            ],
            [
                'Marker',
                getSafeProp(animal, 'PhysicalMarker')
            ],
            [
                'Animal Status',
                getSafeProp(animal, 'cv_AnimalStatus.AnimalStatus')
            ],
            [
                this.translationService.translate('Line'),
                getSafeProp(animal, 'Material.Line.LineName')
            ],
            [
                'Sex',
                getSafeProp(animal, 'cv_Sex.Sex')
            ],
            [
                'Birth Date',
                this.dateFormatterService.formatDateOnly(
                    getSafeProp(animal, 'DateBorn')
                )
            ],
            [
                'Location',
                getSafeProp(animal, 'Material.CurrentLocationPath')
            ],
        ]);
    }

    getTaskBirthExportData(data: any[][], task: any) {
        const birth = getSafeProp(task, 'TaskBirth[0].Birth');

        data.push.apply(data, [
            [
                'Birth ID',
                getSafeProp(birth, 'BirthID')
            ],
            [
                'Mating ID',
                getSafeProp(birth, 'Mating.MatingID')
            ],
            [
                'Birth Status',
                getSafeProp(birth, 'cv_BirthStatus.BirthStatus')
            ],
            [
                this.translationService.translate('Line'),
                getSafeProp(birth, 'Mating.Line.LineName')
            ],
            [
                'Birth Date',
                this.dateFormatterService.formatDateOnly(
                    getSafeProp(birth, 'DateBorn')
                )
            ],
            [
                'Wean Date',
                this.dateFormatterService.formatDateOnly(
                    getSafeProp(birth, 'DateWean')
                )
            ],
            [
                'Housing ID',
                getSafeProp(birth, 'Mating.MaterialPool.MaterialPoolID')
            ],
            [
                'Location',
                getSafeProp(birth, 'Mating.location')
            ],
        ]);
    }

    getTaskHousingExportData(data: any[][], task: any) {
        const materialPool = getSafeProp(task, 'TaskMaterialPool[0].MaterialPool');

        data.push.apply(data, [
            [
                'Housing ID',
                getSafeProp(materialPool, 'MaterialPoolID')
            ],
            [
                'Type',
                getSafeProp(materialPool, 'cv_MaterialPoolType.MaterialPoolType')
            ],
            [
                'Location',
                getSafeProp(materialPool, 'location')
            ],
        ]);
    }

    getTaskJobExportData(data: any[][], task: any) {
        const job = getSafeProp(task, 'TaskJob[0].Job');

        data.push.apply(data, [
            [
                this.translationService.translate('Job'),
                getSafeProp(job, 'JobID')
            ],
        ]);
    }

    getTaskLineExportData(data: any[][], task: any) {
        const line = getSafeProp(task, 'TaskLine[0].Line');

        data.push.apply(data, [
            [
                this.translationService.translate('Line') + ' Name',
                getSafeProp(line, 'LineName')
            ],
            [
                this.translationService.translate('Line') + ' Short Name',
                getSafeProp(line, 'StockNumber')
            ],
            [
                this.translationService.translate('Line') + ' Type',
                getSafeProp(line, 'cv_LineType.LineType')
            ],
            [
                this.translationService.translate('Line') + ' Status',
                getSafeProp(line, 'cv_LineStatus.LineStatus')
            ],
            [
                'Species',
                getSafeProp(line, 'cv_Taxon.CommonName')
            ],
        ]);
    }

    getTaskMatingExportData(data: any[][], task: any) {
        const mating = getSafeProp(task, 'TaskMaterialPool[0].MaterialPool.Mating');

        data.push.apply(data, [
            [
                'Mating ID',
                getSafeProp(mating, 'MatingID')
            ],
            [
                'Mating Status',
                getSafeProp(mating, 'cv_MatingStatus.MatingStatus')
            ],
            [
                this.translationService.translate('Line'),
                getSafeProp(mating, 'Line.LineName')
            ],
            [
                'Mating Type',
                getSafeProp(mating, 'cv_MatingType.MatingType')
            ],
            [
                'Housing ID',
                getSafeProp(mating, 'MaterialPool.MaterialPoolID')
            ],
            [
                'Location',
                getSafeProp(mating, 'location')
            ],
        ]);
    }

    private buildOutputExportData(workflowTaskOutputs: any[], templateRows: TemplateRow[],
                                  isGLP: boolean, includeAnimalNames: boolean, includeSampleNames: boolean): any[][] {
        const data: any[][] = [];
        // Task output header - initialize with common values for all cases
        const row: any[] = ['Key', 'Collected Date', 'Collected By'];
        let tempRow: any[] = [];
        // Boilerplate fields
        if (includeAnimalNames) {
            // if include animal names is checked, push these names to the header row
            if (isGLP) {
                // if isGlp, push alternate physical id
                row.push('Alternate Physical ID');
            }
            row.push(...['Animal ID', 'Animal Name', 'Microchip ID', 'External ID']);
        } 
        if (includeSampleNames) {
            row.push(...['Sample ID', 'Sample Name']);
        }

        for (const workflowTaskOutput of sortObjectArrayByProperty(workflowTaskOutputs, 'SortOrder')) {
            row.push(workflowTaskOutput.OutputName);
        }
        // push header row to data 2d array
        data.push(row); 

        // iterates through templateRow objects list to fill in data
        for (const templateRow of templateRows) {
            // initialize default values
            tempRow = [templateRow.key, null, null];

            if (includeAnimalNames && includeSampleNames) {
                if (templateRow.animal) {
                    tempRow.push(...[templateRow.animal.Material.Identifier, templateRow.animal.AnimalName, templateRow.animal.Material.MicrochipIdentifier, 
                                    templateRow.animal.Material.ExternalIdentifier]);
                    if (isGLP) {
                        tempRow.push(templateRow.animal.AlternatePhysicalID);
                    }
                    // pads empty sample column
                    tempRow.push(...[null, null]);
                }
                else if (templateRow.sample) {
                    if (isGLP) {
                        tempRow.push(null);
                    }
                    tempRow.push(...[null, null, null, null, templateRow.sample.Material.Identifier, templateRow.sample.SampleName]);
                }
            }

            else if (includeAnimalNames && !includeSampleNames) {
                if (templateRow.animal) {
                    tempRow.push(...[templateRow.animal.Material.Identifier, templateRow.animal.AnimalName, templateRow.animal.Material.MicrochipIdentifier, 
                                    templateRow.animal.Material.ExternalIdentifier]);
                    if (isGLP) {
                        tempRow.push(templateRow.animal.AlternatePhysicalID);
                    }
                }
                else {
                    tempRow.push(...[null, null, null, null]);
                    if (isGLP) {
                        tempRow.push(null);
                    }
                }
            }

            else if (includeSampleNames && !includeAnimalNames) {
                if (templateRow.sample) {
                    tempRow.push(...[templateRow.sample.Material.Identifier, templateRow.sample.SampleName]);
                }
                else {
                    tempRow.push(...[null, null]);
                }
            }

            // padding null values for outputs
            for (const output of workflowTaskOutputs){
                tempRow.push(null);
            }

            data.push(tempRow);
        }

        return data;
    }

    private showAnimalData(taskType: string, task: any): boolean {
        return task.showAnimals;
    }

    private showSampleData(taskType: string, task: any): boolean {
        return task.showSamples;
    }

    getPrimarySampleFromTask(task: TaskInstance) {
        let sample: Sample;
        const materialLookup: EntityMap = {};
        for (const taskMaterial of task.TaskMaterial) {
            materialLookup[taskMaterial.C_Material_key] = taskMaterial;
        }
        const materialSourceMaterials = task.TaskMaterial.flatMap(tm => tm.Material.MaterialSourceMaterial);
        for (const materialSourceMaterial of materialSourceMaterials) {
            const taskMaterial = materialLookup[materialSourceMaterial.C_SourceMaterial_key];
            if (!taskMaterial) {
                sample = materialSourceMaterial.Material.Sample;
            }
            else {
                // padding array for cases where there is no primary sample
                sample = null;
            }
        }
        return sample;
    }
}

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { checkmark, listView } from '@icons';
import { TranslationService } from '@services/translation.service';
import { AuditGridView } from '../models/audit-grid-view.enum';
import type { IAuditDropdownOption } from '../audit-facet.component';

type TranslationFn = (value: string) => string;
const pipe = (
    initialValue: AuditGridView,
    ...fns: TranslationFn[]
) => fns.reduce(
    (previousValue, fn) => fn(previousValue),
    initialValue,
);

@Component({
    selector: 'climb-audit-type-dropdown',
    templateUrl: './toolbar-audit-type-dropdown.component.html',
    styleUrls: ['./toolbar-audit-type-dropdown.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToolbarAuditTypeDropdownComponent {
    @Input()
    options: IAuditDropdownOption[] = [];

    @Input()
    activeGridView: AuditGridView = AuditGridView.ChooseSection;

    @Input()
    isGLP = false;

    @Output()
    changeGridView: EventEmitter<AuditGridView> = new EventEmitter();

    readonly icons = { checkmark, listView };

    constructor(
        private translationService: TranslationService,
    ) { }

    translateViewName(gridView: AuditGridView): string {
        const translate = (searchValue: string) => (source: string): string => {
            return source.replace(searchValue, this.translationService.translate(searchValue));
        };

        const glpTranslate = (searchValue: string, resultValue: string) => (source: string): string => {
            return this.isGLP && source === searchValue
                ? resultValue
                : source;
        };

        return pipe(
            gridView,
            translate('Institutions'),
            translate('Institution'),
            translate('Studies'),
            translate('Study'),
            translate('Jobs'),
            translate('Job'),
            translate('Lines'),
            translate('Line'),
            glpTranslate('Clinical Observation Details', 'Health Observations'),
        );
    }
}

<div class="form-group row">
    <label class="col-md-3 col-form-label">Data Type</label>
    <div class="col-md-9">
        <active-vocab-select [(model)]="characteristic.C_DataType_key"
                             (modelChange)="dataChanged($event)"
                             [vocabChoices]="dataTypes"
                             [keyFormatter]="dataTypeKeyFormatter"
                             [optionFormatter]="dataTypeFormatter"
                             [readonly]="beingUsed"
                             [nullable]="false"
                             [tooltip]="tooltip"
                             [required]="true"></active-vocab-select>
    </div>
</div>
<ng-container *ngIf="characteristic.cv_DataType?.DataType === 'Number'">
    <div class="form-group row">
        <label class="col-md-3 col-form-label">Minimum Value</label>
        <div class="col-md-9">
            <input type="number"
                   name="validationMinimum"
                   [(ngModel)]="characteristic.ValidationMinimum"
                   [max]="characteristic.ValidationMaximum"
                   step="any"
                   class="form-control input-number" />
        </div>
    </div>
    <div class="form-group row">
        <label class="col-md-3 col-form-label">Maximum Value</label>
        <div class="col-md-9">
            <input type="number"
                   name="validationMaximum"
                   [(ngModel)]="characteristic.ValidationMaximum"
                   [min]="characteristic.ValidationMinimum"
                   step="any"
                   class="form-control input-number" />
        </div>
    </div>
    <div class="form-group row">
        <label class="col-md-3 col-form-label">Decimal Places</label>
        <div class="col-md-9">
            <input type="number"
                   name="numericScale"
                   [(ngModel)]="characteristic.NumericScale"
                   min="0"
                   step="1"
                   class="form-control input-number" />
        </div>
    </div>
</ng-container>

<div class="form-group row"
     *ngIf="characteristic.cv_DataType?.DataType === 'Enumeration' || characteristic.cv_DataType?.DataType === 'Long Enumeration'">
    <label class="col-md-3 col-form-label">Enumeration</label>
    <div class="col-md-9">
        <select name="enumerationClass"
                [(ngModel)]="characteristic.C_EnumerationClass_key"
                (ngModelChange)="onEnumerationChanged()"
                class="form-control"
                [required]="true">
            <option *ngFor="let enumerationClass of enumerationClasses"
                    [ngValue]="enumerationClass.C_EnumerationClass_key">
                {{enumerationClass.ClassName}}
            </option>
        </select>
    </div>
</div>

<div class="form-group row"
     *ngIf="characteristic.cv_DataType?.DataType === 'Vocabulary' && characteristicType === 'job'">
    <label class="col-md-3 col-form-label">Vocabulary</label>
    <div class="col-md-9">
        <select name="vocabularyClass"
                [(ngModel)]="characteristic.C_VocabularyClass_key"
                (ngModelChange)="onVocabularyChanged()"
                class="form-control"
                [required]="true">
            <option *ngFor="let vocabularyClass of vocabularyClasses"
                    [ngValue]="vocabularyClass.C_VocabularyClass_key">
                {{vocabularyClass.ClassName}}
            </option>
        </select>
    </div>
</div>

<div class="form-group row" *ngIf="characteristic.cv_DataType?.DataType === 'Long Text'">
    <label class="col-md-3 col-form-label">Number of Rows</label>
    <div class="col-md-9">
        <input type="number"
               name="textLineCount"
               [(ngModel)]="characteristic.TextLineCount"
               step="1"
               min="1"
               class="form-control input-number"
               [required]="true">
    </div>
</div>



import { FacetLoadingStateService } from './../../common/facet/facet-loading-state.service';
import { DataManagerService } from './../../services/data-manager.service';
import { DataContextService } from './../../services/data-context.service';
import {
    Component,
    Input,
    OnDestroy,
    OnChanges,
    OnInit,
} from '@angular/core';

import { CohortService } from '../../cohort/services/cohort.service';
import { CopyBufferService } from '../../common/services/copy-buffer.service';
import { JobService } from '../job.service';
import { 
    arrayContainsAllObjects,
    ExpireCache, 
    expireCache, 
    notEmpty, 
    uniqueArrayFromPropertyPath
} from '../../common/util';

@Component({
    selector: 'job-cohort-table',
    templateUrl: './job-cohort-table.component.html'
})
export class JobCohortTableComponent implements OnChanges, OnDestroy, OnInit {
    @Input() readonly: boolean;
    @Input() job: any;
    @Input() cohorts: any[];

    readonly COHORT_ANIMALS_IN_JOB_NS = 'cohort-animals-in-job';
    readonly COHORT_ANIMAL_TOTAL_COUNT_NS = 'cohort-animal-total-count';

    _countInJobCache: ExpireCache = expireCache;
    _totalCountCache: ExpireCache = expireCache;


    constructor(
        private cohortService: CohortService,
        private copyBufferService: CopyBufferService,
        private dataContext: DataContextService,
        private dataManager: DataManagerService,
        private facetLoadingStateService: FacetLoadingStateService,
        private jobService: JobService
    ) {
        // do nothing
    }

    ngOnInit() {
        this._setCaches();
        this.initialize();
    }

    ngOnDestroy() {
        if (notEmpty(this.cohorts)) {
            // reset cohort selections
            for (const cohort of this.cohorts) {
                cohort.isSelected = false;
            }
        }
    }

    ngOnChanges(changes: any) {
        if (changes.job) {
            this._setCaches();
            if (!changes.job.firstChange) {
                this.initialize();
            }
        } 

        if (changes.cohorts) {
            this._calculateAnimalInJobCounts();
            // only call server to get total counts if cohorts are actually different
            const previous = changes.cohorts.previousValue;
            const current = changes.cohorts.currentValue;
            if (!arrayContainsAllObjects(previous, current)) {
                this._calculateTotalAnimalCounts();
            }
        }
    }

    initialize() {
        this._calculateAnimalInJobCounts();
        this._calculateTotalAnimalCounts();
    }

    copyCohorts() {
        const cohortsToCopy = this.cohorts.filter((cohort) => {
            return cohort.isSelected;
        });
        this.copyBufferService.copy(cohortsToCopy);
    }

    selectAllCohorts($event: any) {
        const checkbox = $event.target;
        for (const cohort of this.cohorts) {
            cohort.isSelected = checkbox.checked;
        }
    }

    onDropCohortToJob() {
        const cohorts = this.cohortService.draggedCohorts;
        for (const cohort of cohorts) {
            this.addCohortToJob(cohort);
            cohort.isSelected = false;
        }
        this.cohortService.draggedCohorts = [];

        
        this.initializeCohorts(cohorts);
    }

    pasteCohortsIntoJob() {
        if (this.copyBufferService.hasCohorts()) {
            const pastedCohorts = this.copyBufferService.paste();
            for (const cohort of pastedCohorts) {
                this.addCohortToJob(cohort);
                cohort.isSelected = false;
            }
            this.initializeCohorts(pastedCohorts);
        }
    }

    addCohortToJob(cohort: any): Promise<any> {
        const jobCohort = this.jobService.createJobCohort({
            C_Job_key: this.job.C_Job_key,
            C_Cohort_key: cohort.C_Cohort_key
        });
        if (!jobCohort) {
            return Promise.resolve();
        }

        this.facetLoadingStateService.changeLoadingState(true);
        return this.dataContext.saveSingleRecordBatch([jobCohort]).then(() => {
            return this.refreshMaterialsFromCohort(cohort);
        }).then(() => {
            this.facetLoadingStateService.changeLoadingState(false);
        }).catch((error) => {
            this.facetLoadingStateService.changeLoadingState(false);
            throw error;
        });
    }

    /**
     * Removes JobCohort, which then removes all JobMaterial members of that cohort
     * @param cohort
     */
    removeCohort(cohort: any): Promise<any> {
        const jobCohort = cohort.JobCohort.find((item: any) => {
            return item.C_Job_key === this.job.C_Job_key;
        });
        if (!jobCohort) {
            console.warn(
                'could not find JobCohort record to delete for _Cohort_key = ' + 
                cohort.C_Cohort_key
            );
            return Promise.resolve();
        }
        
        this.jobService.deleteJobCohort(jobCohort);
        this.facetLoadingStateService.changeLoadingState(true);
        return this.dataContext.saveSingleRecordBatch([jobCohort]).then(() => {
            return this.refreshMaterialsFromCohort(cohort);
        }).then(() => {
            this.facetLoadingStateService.changeLoadingState(false);
        }).catch((error) => {
            this.facetLoadingStateService.changeLoadingState(false);
            throw error;
        });
    }

    /**
     * All cohort members get added to the job or removed from the job,
     *      so load them into memory and refresh them for display
     * @param cohort 
     */
    refreshMaterialsFromCohort(cohort: any): Promise<any> {
        return this.cohortService.ensureMaterialsExpanded([cohort]).then(() => {
            const materialKeys = uniqueArrayFromPropertyPath(
                cohort, 'CohortMaterial.C_Material_key'
            );
            return this.dataManager.refreshEntityCollection(
                'Material', 'JobMaterial', materialKeys
            );
        });
    }

    dragCohortsStart() {
        this.cohortService.draggedCohorts = this.cohorts.filter((cohort) => {
            return cohort.isSelected;
        });
    }

    dragCohortsStop() {
        setTimeout(() => {
            this.cohortService.draggedCohorts = [];
        }, 500);
    }

    initializeCohorts(cohorts: any[]) {
        this._calculateAnimalInJobCounts();
        const cohortKeys = cohorts.map((cohort) => {
            return cohort.C_Cohort_key;
        });
        this._calculateTotalAnimalCountByKeys(cohortKeys);
    }

    getTotalCount(cohortKey: number): number {
        return this._totalCountCache.get(cohortKey) || 0;
    }

    getCountInJob(cohortKey: number): number {
        return this._countInJobCache.get(cohortKey) || 0;
    }

    private _setCaches() {
        let jobKey = null;
        if (this.job) {
            jobKey = this.job.C_Job_key;
        } 
        this._countInJobCache = expireCache.namespace(
            this.COHORT_ANIMALS_IN_JOB_NS + '-' + jobKey,
            999999 // don't expire
        );

        this._totalCountCache = expireCache.namespace(
            this.COHORT_ANIMAL_TOTAL_COUNT_NS,
            999999 // don't expire
        );
    }

    private _calculateAnimalInJobCounts() {
        if (!this.cohorts || !this.job) {
            return;
        }

        const materialsInJob: any[] = this.job.JobMaterial.map((jobMaterial: any) => {
            return jobMaterial.Material;
        });

        for (const cohort of this.cohorts) {
            const materialsInCohort: any[] = cohort.CohortMaterial.map((cohortMaterial: any) => {
                return cohortMaterial.Material;
            });

            // find matching materials in job
            const matches = materialsInCohort.filter((material) => {
                return materialsInJob.indexOf(material) > -1;
            });

            const cohortKey = cohort.C_Cohort_key;
            const count = matches.length;
            this._totalCountCache.set(cohortKey, count);
        }
    }

    private _calculateTotalAnimalCounts(): Promise<void> {
        if (!notEmpty(this.cohorts)) {
            return Promise.resolve();
        }

        const cohortKeys = this.cohorts.map((cohort) => {
            return cohort.C_Cohort_key;
        });
        return this._calculateTotalAnimalCountByKeys(cohortKeys);
    }

    private _calculateTotalAnimalCountByKeys(cohortKeys: number[]): Promise<void> {
        return this.cohortService.getCohortCounts(cohortKeys).then((results) => {
            for (const result of results) {
                const key = result.key;
                const count = result.count;
                this._countInJobCache.set(key, count);
            }
        });
    }

}

<div class="modal-header justify-content-center">
    <h4 class="modal-title">Create Placeholder Association</h4>
</div>

<div class="modal-body">
    <div class="job-pharma-placeholder-association-create-wrapper">
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th>Placeholder</th>
                    <th>N</th>
                    <th>Cohort</th>
                    <th>Animals</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let placeholder of placeholders">
                    <td>{{ placeholder.PlaceholderName }}</td>
                    <td>{{ placeholder.JobGroup.Number }} <i *ngIf="placeholder.animalCount && placeholder.JobGroup.Number !== placeholder.animalCount" class="fa fa-exclamation-triangle text-danger"></i></td>
                    <td>
                        <select [ngModel]="placeholder.cohort"
                                (ngModelChange)="setPlaceholder(placeholder, $event)">
                            <option [value]="null" selected></option>
                            <ng-container *ngFor="let cohort of jobCohorts">
                                <option *ngIf="cohort.placeholder === null || cohort.placeholder === placeholder" [value]="cohort.C_JobCohort_key">{{ cohort.Cohort.CohortName }}</option>
                            </ng-container>
                        </select>
                    </td>
                    <td>{{ placeholder.animalCount }} <i *ngIf="placeholder.animalCount && placeholder.JobGroup.Number !== placeholder.animalCount" class="fa fa-exclamation-triangle text-danger"></i></td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
<div class="modal-footer">
    <p *ngIf="showWarning" class="text-danger text-align-center">
        <i class="fa fa-exclamation-triangle"></i> Some of the placeholders have an unequal number of Animal Placeholders (N) in relation to the selected cohort.
    </p>
    <button class="btn btn-primary"
            (click)="activeModal.close('create')">
        Link
    </button>
    <button class="btn btn-secondary"
            (click)="activeModal.dismiss()">
        Cancel
    </button>
</div>

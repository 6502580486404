import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import { NgForm } from '@angular/forms';

import { CurrentWorkgroupService } from '../../services/current-workgroup.service';
import { ProtocolService } from '../protocol.service';
import { ProtocolVocabService } from '../protocol-vocab.service';
import { IValidatable } from '../../services/save-changes.service';

import {
    GlpBaseDetail,
    BaseDetailService,
    FacetView,
    IFacet,
    PageState
} from '../../common/facet';
import { Subscription } from 'rxjs';
import { JobService } from '../../jobs/job.service';
import { SettingService } from '../../settings/setting.service';
import { BreezeEntityChangedEventArgs, Entity, Protocol } from '@common/types';
import { ProtocolSaveService } from '../services/protocol-save.service';
import { ProtocolStateService } from '../services/protocol-state.service';
import { EntityChangeService } from '../../entity-changes/entity-change.service';

@Component({
    selector: 'protocol-detail',
    templateUrl: './protocol-detail.component.html'
})
export class ProtocolDetailComponent extends GlpBaseDetail<Protocol>
    implements OnChanges, OnInit, IValidatable {
    @Input() facet: IFacet;
    @Input() facetView: FacetView;
    @Input() protocol: any;
    @Input() pageState: PageState;

    @Output() exit: EventEmitter<void> = new EventEmitter<void>();
    @Output() next: EventEmitter<void> = new EventEmitter<void>();
    @Output() previous: EventEmitter<void> = new EventEmitter<void>();
    @Output() modelCopy: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild("protocolForm") protocolForm: NgForm;

    // CVs
    timeRelations: any[] = [];
    timeUnits: any[] = [];
    userWorkgroups: any[] = [];
    freeTasks: any[] = [];
    sections: any[] = [];

    // State
    copying = false;
    isCRO = false;
    currentWorkgroupName = '';
    loadingMessage = '';

    // Active and required fields set by facet settings
    activeFields: string[] = [];
    requiredFields: string[] = [];

    readonly COMPONENT_LOG_TAG = 'protocol-detail';

    workgroupSubscription: Subscription;

    taskRowsOpen = false;

    constructor(
        baseDetailService: BaseDetailService,
        private currentWorkgroupService: CurrentWorkgroupService,
        private protocolService: ProtocolService,
        private protocolVocabService: ProtocolVocabService,
        private protocolSaveService: ProtocolSaveService,
        private protocolStateService: ProtocolStateService,
        private jobService: JobService,
        private settingService: SettingService,
        entityChangeService: EntityChangeService
    ) {
        super(baseDetailService, protocolSaveService, protocolStateService, entityChangeService);
    }

    // lifecycle
    ngOnInit() {
        super.ngOnInit();
        this.baseDetailService.saveChangesService.registerValidator(this);
        this.initialize();
    }

    ngOnChanges(changes: any) {
        if (changes.protocol) {
            if (this.protocol && !changes.protocol.firstChange) {
                if (this.protocolForm) {
                    this.protocolForm.form.markAsPristine();
                }
                this.initialize();
            }
        }
    }

    initialize() {
        this.currentWorkgroupName = this.currentWorkgroupService.getWorkgroupName();
        this.isCRO = this.jobService.getIsCroFlag();

        return this.settingService.getFacetSettingsByType('protocol', this.isCRO, undefined).then((facetSettings) => {
            this.activeFields = this.settingService.getActiveFields(facetSettings);
            this.requiredFields = this.settingService.getRequiredFields(facetSettings);
        }).then(() => {
            return this.getCVs();
        }).then(() => {
            return this.getDetails();
        });
    }

    private getCVs(): Promise<any> {
        const p1 = this.protocolVocabService.userWorkgroups$.subscribe((data) => {
            this.userWorkgroups = data;
        });

        this.workgroupSubscription = p1;

        return Promise.all([p1]);
    }

    private getDetails(): Promise<any> {
        if (this.protocol && this.protocol.C_Protocol_key > 0) {
            this.setLoading(true);

            const expands: string[] = [
                'ChildProtocolTaskSection',
                'ChildProtocolTaskSection.ProtocolTask',
                'ProtocolTask',
                'ProtocolTask.WorkflowTask',
                'ProtocolTask.SampleGroup'
            ];
            return this.protocolService.getProtocol(
                this.protocol.C_Protocol_key,
                expands
            ).then(() => {
                this.freeTasks = this.protocol.ProtocolTask.filter((task: any) => task.C_ProtocolTaskSection_key === null);
                this.sections = this.protocol.ChildProtocolTaskSection.slice();
                return this.protocolService.getLastUsedDate(this.protocol.C_Protocol_key)
                    .then((data: any) => {
                        this.protocol.lastUsedDate = data;
                    });
            }).then(() => {
                this.setLoading(false);
            }).catch((error: any) => {
                this.setLoading(false);
                console.error(error);
            });
        }

        return Promise.resolve(this.protocol);
    }

    onCancel() {
        this.protocolService.cancelProtocol(this.protocol);
    }

    // Copying protocols between workgroups can result in data loss
    // It has therefore been disabled until a permanent fix can be developed
    /*copyProtocol(workgroupKey: number) {
        if (this.hasInvalidSections()) {
            this.loggingService.logWarning("Please ensure that you do not have duplicated subsection names within the protocol before continuing.", null, this.COMPONENT_LOG_TAG, true);
        } else {
            this.copying = true;

            return this.protocolService.copyProtocol(workgroupKey,
                this.currentWorkgroupName,
                this.protocol.C_Protocol_key
            ).then(() => {
                this.copying = false;

                let showToast = true;
                this.loggingService.logSuccess(
                    this.translationService.translate('Protocol') + ' copied',
                    null,
                    this.COMPONENT_LOG_TAG,
                    showToast
                );


            }).catch((error: any) => {
                this.copying = false;

                let showToast = true;
                this.loggingService.logSuccess(
                    'There was an error copying workgroup data.',
                    null,
                    this.COMPONENT_LOG_TAG,
                    showToast
                );

                console.error(error);
            });
        }
    }*/

    async copyToCurrentWorkgroup() {
        if (this.hasInvalidSections()) {
            this.loggingService.logWarning("Please ensure that you do not have duplicated subsection names within the protocol before continuing.", null, this.COMPONENT_LOG_TAG, true);
        } else {
            if (this.hasChanges()) {
                const saveChangesModalResult = await this.viewUnsavedChangesModalService.openComponent(this.COMPONENT_LOG_TAG);
                if (saveChangesModalResult === 'save') {
                    await this.saveEntity();
                } else {
                    this.cancelAnyChanges();
                    return;
                }
            }
            this.copyProtocol();
        }
    }

    private copyProtocol() {
        const fromProtocol = this.protocol;
        // Copy from current protocol to a newly created protocol
        const toProtocol = this.protocolService.createProtocol();
        this.copying = true;
        this.setLoading(true);

        // Copy values
        this.protocolService.copyToCurrentWorkgroup(fromProtocol, toProtocol).then(() => {
            // Set the current protocol to the newly created protocol
            this.protocol = toProtocol;
            this.modelCopy.emit(this.protocol);
        }).finally(() => {
            this.setLoading(false);
            this.copying = false;
        });
    }

    async validate(): Promise<string> {

        //checking for circular reference in protocol tasks before saving
        // Using for and of approach instead of forEach because it need to be able to break out of the loop
        for (const protocolTask of this.protocol.ProtocolTask) {
            if (this.protocolService.detectCircularReference(protocolTask)) {
                return "Circular dependency detected";
            }
        }

        // Validate protocol name

        if (!this.protocolService.isNameValid(this.protocol)) {
            return this.protocolService.ERROR_MSG_INVALID_NAME;
        }

        // Validate fields required by facet settings
        return await this.settingService.validateRequiredFields(this.requiredFields, this.protocol, 'protocol');
    }

    hasInvalidSections() {
        const names = this.protocol.ChildProtocolTaskSection.map((section: any) => section.SectionName);
        const nameCounts = names.reduce((a: any, b: any) => ({
            ...a,
            [b]: (a[b] || 0) + 1
        }), {});
        const duplicates = Object.keys(nameCounts).filter((a) => nameCounts[a] > 1);
        return duplicates.length > 0;
    }

    openTaskRows() {
        this.taskRowsOpen = !this.taskRowsOpen;
    }

    async beforeSave(): Promise<void> {
        return;
    }

    destroy(): void {
        this.baseDetailService.saveChangesService.unregisterValidator(this);
        if (this.workgroupSubscription) {
            this.workgroupSubscription.unsubscribe();
        }
    }


    getEntityForSaving(): Entity<Protocol> {
        return this.protocol;
    }

    async onEntityChange(entityChange: BreezeEntityChangedEventArgs<Protocol>): Promise<void> {
        return;
    }
}

import { Injectable } from '@angular/core';

import { TranslationService } from '@services/translation.service';
import { UserNameService } from '../../user/user-name.service';
import { CsvExporter } from '@common/export/csv-exporter';
import { DateFormatterService } from '@common/util/date-time-formatting';


/**
 * Exports a workflow task audit report to CSV
 */
@Injectable()
export class ExportWorkflowAuditService {

    csvExporter: CsvExporter;

    constructor(
        private translationService: TranslationService,
        private userNameService: UserNameService,
        private dateFormatterService: DateFormatterService
    ) {
        this.csvExporter = new CsvExporter();
    }

    exportToCsv(
        workflowAuditData: any[],
        workflowAuditAnimalsData: any[],
        workflowAuditSamplesData: any[],
        workflowAuditInputsData: any[]
    ) {
        const EXPORTER_CSV_FILENAME =
            "WorkflowAudit.csv";

        const data: any[][] = this.buildExportData(
            workflowAuditData,
            workflowAuditAnimalsData,
            workflowAuditSamplesData,
            workflowAuditInputsData
        );

        this.csvExporter.download(data, EXPORTER_CSV_FILENAME);
    }

    buildExportData(
        workflowAuditData: any[],
        workflowAuditAnimalsData: any[],
        workflowAuditSamplesData: any[],
        workflowAuditInputsData: any[]
    ): any[][] {
        const data: any[][] = [];

        data.push(['Details']);
        // Job audit data
        data.push([
            'Action',
            'Modified Date',
            'Modified By',
            'Task Name',
            'Status',
            'Due Date',
            'Complete Date'
        ]);
        for (const row of workflowAuditData) {
            data.push([
                row.UpdateType,
                this.dateFormatterService.formatDateTimeUTC(row.ModifiedDate),
                this.userNameService.toFullName(row.ModifiedBy),
                row.TaskAlias,
                row.TaskStatus,
                this.dateFormatterService.formatDateTimeUTC(row.DateDue),
                this.dateFormatterService.formatDateTimeUTC(row.DateComplete)
            ]);
        }

        if (workflowAuditAnimalsData.length > 0) {
            data.push([]);
            data.push(['Animals']);
            // Animals added and deleted
            data.push([
                'Action',
                'Modified Date',
                'Modified By',
                'Animal Name'
            ]);
            for (const row of workflowAuditAnimalsData) {
                data.push([
                    row.UpdateType,
                    this.dateFormatterService.formatDateTimeUTC(row.ModifiedDate),
                    this.userNameService.toFullName(row.ModifiedBy),
                    row.MaterialName
                ]);
            }
        }

        if (workflowAuditSamplesData.length > 0) {
            data.push([]);
            data.push(['Samples']);
            // Samples added and deleted
            data.push([
                'Action',
                'Modified Date',
                'Modified By',
                'Sample Name'
            ]);
            for (const row of workflowAuditSamplesData) {
                data.push([
                    row.UpdateType,
                    this.dateFormatterService.formatDateTime(row.ModifiedDate),
                    this.userNameService.toFullName(row.ModifiedBy),
                    row.MaterialName
                ]);
            }
        }

        if (workflowAuditInputsData.length > 0) {
            data.push([]);
            data.push(['Inputs']);
            data.push([
                'Action',
                'Modified Date',
                'Modified By',
                'Input Name',
                'Value'
            ]);
            for (const row of workflowAuditInputsData) {
                data.push([
                    row.UpdateType,
                    this.dateFormatterService.formatDateTimeUTC(row.ModifiedDate),
                    this.userNameService.toFullName(row.ModifiedBy),
                    row.InputName,
                    row.InputValue
                ]);
            }
        }        

        return data;
    }
}

import type { AlertDevice } from './alert-device.interface';
import type { AlertTriggeredEvent } from './alert-triggered-event.interface';
import type { DeviceModel } from './device-model.interface';
import type { DeviceOutputValue } from './device-output-value.interface';
import type { DeviceTag } from './device-tag.interface';
import type { LocationPosition } from './location-position.interface';
import type { MaterialPool } from './material-pool.interface';

export interface Device {
    C_Device_key: string;
    C_DeviceModel_key: string;
    IsActive: boolean;
    C_LocationPosition_key: number | null;
    C_MaterialPool_key: number | null;
    CurrentLocationPath: string;
    DeviceModel: DeviceModel;
    DeviceTag: DeviceTag[];
    DeviceOutputValue: DeviceOutputValue[];
    AlertDevice: AlertDevice[];
    AlertTriggeredEvent: AlertTriggeredEvent[];
    LocationPosition: LocationPosition;
    MaterialPool: MaterialPool;
}

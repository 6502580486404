import {
    Component,
} from '@angular/core';

import { Subject } from 'rxjs';
import { BinaryToggleComponent } from './binary-toggle-component.interface';

@Component({
    selector: 'one-many-toggle',
    template: `
    <span *ngIf="!_toggledMany">
        One |
        <a (click)="setState(true)" class="one-toggle-link">Many</a>
    </span>
    <span *ngIf="_toggledMany">
        <a (click)="setState(false)" class="many-toggle-link">One</a> |
        Many
    </span>
    `
})
export class OneManyToggleComponent implements BinaryToggleComponent {

    // true = toggled to 'Many'
    // false = toggled to 'One'
    _toggledMany = false;
    private stateChangedSource = new Subject<any>();
    
    stateChanged$ = this.stateChangedSource.asObservable();

    setState(on: boolean) {
        this._toggledMany = on;
        this.stateChangedSource.next(on);
    }

    getState(): boolean {
        return this._toggledMany;
    }
}


<loading-overlay *ngIf="loading"></loading-overlay>

<div class="layout-container"
     *ngIf="reportVariations && reportVariations.length > 1">
    <label>Layout</label>

    <ul class="facet-contentlist">
        <li *ngFor="let item of reportVariations"
            class="facet-contentlistitem"
            [ngClass]="{'facet-contentlistitem-selected': item === selectedVariation}">
            <span *ngIf="item === selectedVariation">{{item.text}}</span>
            <a *ngIf="!(item === selectedVariation)"
               (click)="selectReportVariationItem(item)">{{item.text}}</a>
        </li>
    </ul>
</div>

<div *ngIf="parametersConfig">
    <div class="paramboxouter">
        <div *ngFor="let paramItem of parametersConfig.parameters">
            <!-- overarching groups text - e.g., "Choose animals by:" -->
            <div *ngIf="paramItem.groupSelectionText" class="mb-1">
                <b>{{paramItem.groupSelectionText}}:</b>
            </div>

            <!-- groups[] -->
            <div *ngFor="let group of paramItem.groups">
                <div *ngIf="group.text" style="vertical-align: middle;">
                    <input type="radio"
                           id="{{paramItem.key}}{{group.key}}"
                           [name]="paramItem.key + '' + group.key"
                           [checked]="paramItem.selectedGroup === group ? 'checked' : null"
                           (click)="selectGroup(paramItem, group)" />
                    <label for="{{paramItem.key}}{{group.key}}">{{group.text}}</label>
                </div>

                <ng-container *ngIf="paramItem.selectedGroup === group">
                    <ng-container *ngFor="let subParam of group.subParams">
                        <report-param-input [subParam]="subParam"></report-param-input>
                    </ng-container>
                </ng-container>
            </div>
        </div>
    </div>

    <div class="btn-container"
         *ngIf="parametersConfig.resultsValidated && !loading">
        <button type="button"
                class="btn btn-fw-xwide btn-primary"
                (click)="runReport()">
            Run Report
        </button>
    </div>
</div>

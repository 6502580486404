import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChildren,
} from '@angular/core';
import { BaseFilter } from '../../common/facet';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
    focusElementByQuery
} from '../../common/util';

import { VocabularyService } from '../../vocabularies/vocabulary.service';
import { NgModel } from '@angular/forms';
import { dateControlValidator } from '@common/util/date-control.validator';
import { LoggingService } from '@services/logging.service';
import { CharacteristicTypeNameEnum } from '../models';

@Component({
    selector: 'characteristics-filter',
    templateUrl: './characteristics-filter.component.html'
})
export class CharacteristicsFilterComponent extends BaseFilter implements OnInit {
    @ViewChildren('dateControl') dateControls: NgModel[];

    @Input() filter: any;
    @Input() isCRO = false;
    @Input() typeName: CharacteristicTypeNameEnum = CharacteristicTypeNameEnum.Taxon;
    @Output() onFilter: EventEmitter<any> = new EventEmitter<any>();
    @Output() close = new EventEmitter<any>();

    // CVs
    sampleTypes: any[] = [];
    iacucProtocols: any[] = [];
    jobTypes: any[] = [];
    jobSubtypes: any[] = [];
    studyTypes: any[] = [];    
    booleanValues: any[] = [];

    // Export enum to template
    characteristicTypeName = CharacteristicTypeNameEnum;

    constructor(
        private vocabularyService: VocabularyService,
        private activeModal: NgbActiveModal,
        private loggingService: LoggingService,
    ) {
        super(activeModal);
     }

    ngOnInit() {
        if (!this.filter) {
            this.filter = {};
        } else {
            // shallow copy passed in filters
            //  so we don't modify original data
            this.filter = { ...this.filter };
        }

        this.getCVs();
        this.loadBooleanValues();

        focusElementByQuery('[data-auto-focus=characteristicsFilter]');
    }

    private getCVs() {
        return Promise.all([
            this.vocabularyService.getCV('cv_SampleTypes').then((data) => {
                this.sampleTypes = data;
            }),            
            this.vocabularyService.getCV('cv_IACUCProtocols').then((data) => {
                this.iacucProtocols = data;
            }),
            this.vocabularyService.getCV('cv_JobTypes').then((data) => {
                this.jobTypes = data;
            }),
            this.vocabularyService.getCV('cv_JobSubtypes').then((data) => {
                this.jobSubtypes = data;
            }),
            this.vocabularyService.getCV('cv_StudyTypes').then((data) => {
                this.studyTypes = data;
            }),
        ]);
    }

    private loadBooleanValues(): void {
        const values: any[] = [];
        values.push({
            Value: true,
        });
        values.push({
            Value: false,
        });

        this.booleanValues = values;
    }

    closeClicked() {
        this.close.emit();
        this.activeModal.dismiss();
    }

    clearClicked() {
        this.filter = {};
        this.onFilter.emit(this.filter);
        this.close.emit();
        this.activeModal.close();
    }

    okClicked(event?: Event) {
        const errMessage = dateControlValidator(this.dateControls);
        if (errMessage) {
            this.loggingService.logError(errMessage, null, '', true);
            return;
        }

        event?.preventDefault();
        this.onFilter.emit(this.filter);
        this.close.emit();
        this.activeModal.close();
    }
}

import { Component, Input } from '@angular/core';

export const NUMBER_FILTER_COMPONENT_OPERATORS: string[] = ['between', '=', '<=', '>=', 'has no value'];

@Component({
    selector: 'number-filter',
    template: `
        <form class="form-inline">
            <select
                    [(ngModel)]="node.operator"
                    (ngModelChange)="changeOperator()"
                    [ngModelOptions]="{standalone: true}"
                    class="form-control">
                <option *ngFor="let op of operators" [ngValue]="op">{{ op }}</option>
            </select>
            <input
                *ngIf="showMinValue()"
                type="number"
                step="any"
                [(ngModel)]="node.minValue"
                [ngModelOptions]="{standalone: true}"
                class="form-control input-medium">
            <label *ngIf="node.operator === 'between'">and</label>
            <input
                *ngIf="showMaxValue()"
                type="number"
                step="any"
                [(ngModel)]="node.maxValue"
                [ngModelOptions]="{standalone: true}"
                class="form-control input-medium">
            <button
                type="button"
                class="form-control btn btn-secondary"
                (click)="clear()">Clear</button>
        </form>
    `,
})
export class NumberFilterComponent {
    @Input() node: any = null;
    readonly operators = NUMBER_FILTER_COMPONENT_OPERATORS;

    changeOperator() {
        if (this.node.operator === 'has no value') {
            this.node.searchNulls = true;
        } else {
            this.node.searchNulls = false;
        }
    }

    clear() {
        this.node.minValue = null;
        this.node.maxValue = null;
        this.node.searchNulls = false;
        if (this.node.operator === 'has no value') {
            this.node.operator = 'between';
        }
    }

    showMinValue(): boolean {
        if (this.node.searchNulls) {
            return false;
        }

        return this.node.operator === 'between' ||
            this.node.operator === '>=' ||
            this.node.operator === '=';
    }

    showMaxValue(): boolean {
        if (this.node.searchNulls) {
            return false;
        }

        return this.node.operator === 'between' || this.node.operator === '<=';
    }
}

import {
    Component
} from "@angular/core";
import { IHeaderParams } from "ag-grid-community";
import { IHeaderAngularComp } from "ag-grid-angular";
import { AgGridCommService } from '../services/ag-grid-comm.service';
import { plusCircleFilled } from '@icons';

@Component({
    selector: 'header-add-item',
    template: `
        <button data-automation-id="add-item-button"
                climbButton
                variant="ghost"
                size="sm"
                (click)="addItemClick()"
                title="Add item">
            <svg [climbIcon]="icons.plusCircleFilled"></svg>
        </button>
    `,
    styleUrls: ['./header-add-item.component.scss'],
})
export class HeaderAddItemComponent implements IHeaderAngularComp {
    public icons = { plusCircleFilled };

    public params: IHeaderParams;

    constructor(
        private agGridCommService: AgGridCommService,
    ) { }

    refresh(params: IHeaderParams): boolean {
        return false;
    }

    agInit(params: IHeaderParams): void {
        this.params = params;
    }

    addItemClick() {
        // pipe addItem click event to parent data-table
        this.agGridCommService.addItemClicked();
    }
}

import { TemplateRef } from '@angular/core';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

export interface DialogContent {
    title: string;
    bodyText?: string;
    bodyHtml?: string;
    bodyTemplate?: TemplateRef<any>;
    forceAnswer?: boolean;
}

export interface DialogOptions<T = any> extends DialogContent {
    actions: DialogActionButton<T>[];
    systemOptions?: NgbModalOptions;
}

export interface ComponentDialogOptions<T> {
    customizeComponent?: (component: T) => void;
    systemOptions?: NgbModalOptions;
}

export interface DialogActionButton<T> {
    title: string;
    value: T;
    style: ButtonStyle;
}

export enum ButtonStyle {
    Primary = 'btn-primary',
    Secondary = 'btn-secondary',
    Success = 'btn-success',
    Danger = 'btn-danger',
    Warning = 'btn-warning',
    Info = 'btn-info',
    Light = 'btn-light',
    Dark = 'btn-dark',
    Link = 'btn-link',
}

import { WizardService } from './wizard.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ClimbCommonModule } from '../common/climb-common.module';
import { ClimbServicesModule } from '../services/climb-services.module';

@NgModule({
    imports: [
        CommonModule,
        ClimbCommonModule,
        ClimbServicesModule
    ],
    declarations: [
    ],
    exports: [
    ],
    providers: [
        WizardService
    ]
})
export class WizardSharedModule { }

import {
    Component,
    Input,
    OnInit,
} from '@angular/core';

import { BarcodeOptions } from '../common/models';

/*
 * Displays and prints a sample label.
 */
@Component({
    selector: 'sample-label',
    templateUrl: './sample-label.component.html',
    styles: [`
        .sample-label {
            height: .875in;
            width: 1.75in;
            overflow: hidden;
            outline: 1px dotted #999;
            padding: 0 .25em;
            text-align: left;
        }
    `]
})
export class SampleLabelComponent implements OnInit {
    @Input() sample: any;
    @Input() showPreview: boolean;

    // state
    barcodeOptions: BarcodeOptions;
    containerId: string;


    ngOnInit() {
        this.containerId = 'sample-label-' + this.sample.C_Material_key;

        this.initBarcodeOptions();
    }

    /**
     * Options for barcode creator.
     * https://www.npmjs.com/package/ngx-barcode6
     */
    private initBarcodeOptions() {
        this.barcodeOptions = {
            format: 'CODE128B',
            elementType: 'svg',
            displayValue: false,
            font: 'monospace',
            fontSize: 10,
            height: 10,
            width: 2,
            margin: 0,
            marginTop: 0,
            marginBottom: 0,
            marginLeft: 0,
            marginRight: 0,
            lineColor: '#495461',
            background: '#ffffff',
        };
    }

    printLabel() {
        const printContents = document.getElementById(this.containerId).innerHTML;

        const styles = `
            <style>
                body {
                    margin-top: 0;
                    margin-bottom: 0;
                }
            </style>
        `;

        if (navigator.userAgent.toLowerCase().indexOf('chrome') > -1) {
            const popupWin = window.open(
                '',
                '_blank',
                'width=600,height=600,scrollbars=no,menubar=no,' +
                'toolbar=no,location=no,status=no,titlebar=no'
            );
            popupWin.window.focus();
            popupWin.document.write(
                '<!DOCTYPE html><html><head>' +
                styles +
                '</head><body onload="window.print()">' +
                printContents +
                '</body></html>'
            );
            popupWin.document.close();

            popupWin.onbeforeunload = (event: any) => {
                popupWin.close();
                return '.\n';
            };

            popupWin.onabort = (event: any) => {
                popupWin.document.close();
                popupWin.close();
            };
        } else {
            const popupWin = window.open('', '_blank', 'width=800,height=600');
            popupWin.document.open();
            popupWin.document.write(
                '<html><head>' +
                styles +
                '</head>' +
                '<body onload="window.print()">' +
                printContents +
                '</body></html>'
            );
            popupWin.document.close();
        }

        return true;
    }
}

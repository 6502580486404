import type { Animal } from './animal.interface';
import type { Resource } from './resource.interface';
import type { TreatmentPlanDetail } from './treatment-plan-detail.interface';

export interface TreatmentPlan {
    Animal: Animal;
    C_Material_key: number;
    C_Resource_key: number | null;
    C_TreatmentPlan_key: number;
    C_Workgroup_key: number;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    ModifiedBy: string;
    Resource: Resource;
    TreatmentPlanDetail: TreatmentPlanDetail[];
    Version: number;
}

import {
    Component,
    Input,
    OnInit,
} from '@angular/core';

import {
    NgbActiveModal
} from '@ng-bootstrap/ng-bootstrap';

import { AuditService } from '../../../audit/audit.service';
import { ExportWorkflowAuditService } from '../../services/export-workflow-audit.service';
import { WorkflowService } from '../../services/workflow.service';

import {
    getSafeProp,
} from '@common/util';

@Component({
    selector: 'workflow-audit-report',
    templateUrl: './workflow-audit-report.component.html'
})
export class WorkflowAuditReportComponent implements OnInit {
    @Input() taskInstanceKey: any;

    workflow: any;
    workflowAuditData: any[] = [];
    workflowAuditAnimalsData: any[] = [];
    workflowAuditSamplesData: any[] = [];
    workflowAuditInputsData: any[] = [];
    workflowAuditCohortsData: any[] = [];
    loading = false;

    readonly noDataMessage: string = 'No changes.';


    constructor(
        private activeModal: NgbActiveModal,
        private auditService: AuditService,
        private exportWorkflowAuditService: ExportWorkflowAuditService,
        private workflowService: WorkflowService
    ) {
    }

    ngOnInit() {
        this.getData();
    }

    private getData(): Promise<void> {

        const p1 = this.auditService.getWorkflowAuditData(this.taskInstanceKey)
            .then((response: any) => {
                this.workflowAuditData = getSafeProp(response, 'data.results') || [];
            });

        const p2 = this.auditService.getWorkflowAuditAnimalsData(this.taskInstanceKey)
            .then((response: any) => {
                this.workflowAuditAnimalsData = getSafeProp(response, 'data.results') || [];
            });

        const p3 = this.auditService.getWorkflowAuditSamplesData(this.taskInstanceKey)
            .then((response: any) => {
                this.workflowAuditSamplesData = getSafeProp(response, 'data.results') || [];
            });

        const p4 = this.auditService.getWorkflowAuditInputsData(this.taskInstanceKey)
            .then((response: any) => {
                this.workflowAuditInputsData = getSafeProp(response, 'data.results') || [];
            });
        
        const p5 = this.auditService.getWorkflowAuditCohortsData(this.taskInstanceKey)
            .then((response: any) => {
                this.workflowAuditCohortsData = getSafeProp(response, 'data.results') || [];
            });



        this.loading = true;
        return Promise.all([p1, p2, p3, p4, p5]).then(() => {
            this.loading = false;
        }).catch((error) => {
            this.loading = false;
            throw error;
        });
    }

    onCancel(): void {
        this.activeModal.dismiss();
    }

    exportAudit(): void {
        this.exportWorkflowAuditService.exportToCsv(
            this.workflowAuditData,
            this.workflowAuditAnimalsData,
            this.workflowAuditSamplesData,
            this.workflowAuditInputsData
        );
    }
}

<ng-template #clinicalModifier1 let-modifier1="modifier1">
    <input type="checkbox" [(ngModel)]="modifier1.isSelected" name="large-checkbox{{modifier1.C_Modifier1_key}}"
        id="large-checkbox{{modifier1.C_Modifier1_key}}" (ngModelChange)="isSelectedModifiers1Changed()"
        autocomplete="off" />

    <div class="btn-group" style="display:inline-flex;">
        <label for="large-checkbox{{modifier1.C_Modifier1_key}}" class="btn btn-secondary input-shape">
            <span class="fa fa-check"></span>
            <span> </span>
        </label>
        <label for="large-checkbox{{modifier1.C_Modifier1_key}}"
               class="observation-label">
            {{modifier1.Modifier1}}
        </label>
    </div>
</ng-template>

<!-- Modifier2 Template -->

<ng-template #clinicalModifier2 let-modifier2="modifier2">
    <input type="checkbox" [(ngModel)]="modifier2.isSelected" name="large-checkbox{{modifier2.C_Modifier2_key}}"
        id="large-checkbox{{modifier2.C_Modifier2_key}}" (ngModelChange)="isSelectedModifiers2Changed()"
        autocomplete="off" />

    <div class="btn-group" style="display:inline-flex;">
        <label for="large-checkbox{{modifier2.C_Modifier2_key}}" class="btn btn-secondary input-shape">
            <span class="fa fa-check"></span>
            <span> </span>
        </label>
        <label for="large-checkbox{{modifier2.C_Modifier2_key}}"
               class="observation-label">
            {{modifier2.Modifier2}}
        </label>
    </div>
</ng-template>

<!-- Modifier 3 Template -->

<ng-template #clinicalModifier3 let-modifier3="modifier3">
    <input type="checkbox" [(ngModel)]="modifier3.isSelected" name="large-checkbox{{modifier3.C_Modifier3_key}}"
        id="large-checkbox{{modifier3.C_Modifier3_key}}" (ngModelChange)="isSelectedModifiers3Changed()"
        autocomplete="off" />

    <div class="btn-group" style="display:inline-flex;">
        <label for="large-checkbox{{modifier3.C_Modifier3_key}}" class="btn btn-secondary input-shape">
            <span class="fa fa-check"></span>
            <span> </span>
        </label>
        <label for="large-checkbox{{modifier3.C_Modifier3_key}}"
               class="observation-label">
            {{modifier3.Modifier3}}
        </label>
    </div>
</ng-template>

<!-- Modifier 4 Template  -->

<ng-template #clinicalModifier4 let-modifier4="modifier4">
    <input type="checkbox" [(ngModel)]="modifier4.isSelected" name="large-checkbox{{modifier4.C_Modifier4_key}}"
        id="large-checkbox{{modifier4.C_Modifier4_key}}" (ngModelChange)="isSelectedModifiers4Changed()"
        autocomplete="off" />

    <div class="btn-group" style="display:inline-flex;">
        <label for="large-checkbox{{modifier4.C_Modifier4_key}}" class="btn btn-secondary input-shape">
            <span class="fa fa-check"></span>
            <span> </span>
        </label>
        <label for="large-checkbox{{modifier4.C_Modifier4_key}}"
               class="observation-label">
            {{modifier4.Modifier4}}
        </label>
    </div>
</ng-template>


<div class="modal-header justify-content-center">
    <h4 class="modal-title">Select Clinical Modifiers</h4>
</div>

<div class="modal-body" style="overflow-x: auto;">
    <loading-overlay *ngIf="loading"></loading-overlay>
    <nav ngbNav
         #nav="ngbNav"
         class="nav-tabs"
         [activeId]="'tab-vocab-modifier1-' + domIdAddition"
         (navChange)="tabChanged('vocabulary', $event)">

        <!-- Search Bar -->
        <ng-container [ngbNavItem]="'tab-vocab-modifier1-' + domIdAddition">
            <a ngbNavLink>Modifier 1</a>
            <ng-template ngbNavContent>
                <div class="col-md-12">
                    <input type="text" #searchbar 
                           [(ngModel)]="searchInputModifier1"
                           class="form-control input-medium"
                           (keyup)="searchModifier(searchbar.value)"
                           placeholder="Search Modifiers1..." />
                </div>

                <div *ngIf="!loading" class="d-flex mt-3">
                    <div class="col-md-6 checkbox-column">
                        <div *ngFor="let modifier1 of modifiers1 | half:'first'" class="checkbox-group">
                            <ng-template [ngTemplateOutlet]="clinicalModifier1"
                                         [ngTemplateOutletContext]="{
                                         modifier1 : modifier1
                                     }">
                            </ng-template>
                        </div>
                    </div>
                    <div class="col-md-6 checkbox-column">
                        <div *ngFor="let modifier1 of modifiers1 | half:'last'" class="checkbox-group">
                            <ng-template [ngTemplateOutlet]="clinicalModifier1"
                                         [ngTemplateOutletContext]="{
                                         modifier1 : modifier1
                                     }">
                            </ng-template>
                        </div>
                    </div>
                </div>
            </ng-template>
        </ng-container>

        <ng-container [ngbNavItem]="'tab-vocab-modifier2-' + domIdAddition">
            <a ngbNavLink>Modifier 2</a>
            <ng-template ngbNavContent>
                <div class="col-md-12">
                    <input type="text" #searchbar 
                           [(ngModel)]="searchInputModifier2"
                           class="form-control input-medium"
                           (keyup)="searchModifier(searchbar.value)"
                           placeholder="Search Modifiers2..." />
                </div>

                <div class="d-flex mt-3">
                    <div class="col-md-6 checkbox-column">
                        <div *ngFor="let modifier2 of modifiers2 | half:'first'" class="checkbox-group">
                            <ng-template [ngTemplateOutlet]="clinicalModifier2"
                                         [ngTemplateOutletContext]="{
                                             modifier2 : modifier2
                                         }">
                            </ng-template>
                        </div>
                    </div>
                    <div class="col-md-6 checkbox-column">
                        <div *ngFor="let modifier2 of modifiers2 | half:'last'" class="checkbox-group">
                            <ng-template [ngTemplateOutlet]="clinicalModifier2"
                                         [ngTemplateOutletContext]="{
                                             modifier2 : modifier2
                                         }">
                            </ng-template>
                        </div>
                    </div>
                </div>
            </ng-template>
        </ng-container>

        <ng-container [ngbNavItem]="'tab-vocab-modifier3-' + domIdAddition">
            <a ngbNavLink>Modifier 3</a>
            <ng-template ngbNavContent>
                <div class="col-md-12">
                    <input type="text" #searchbar 
                           [(ngModel)]="searchInputModifier3"
                           class="form-control input-medium"
                           (keyup)="searchModifier(searchbar.value)"
                           placeholder="Search Modifiers3..." />
                </div>

                <div class="d-flex mt-3">
                    <div class="col-md-6 checkbox-column">
                        <div *ngFor="let modifier3 of modifiers3 | half:'first'" class="checkbox-group">
                            <ng-template [ngTemplateOutlet]="clinicalModifier3"
                                         [ngTemplateOutletContext]="{
                                         modifier3 : modifier3
                                     }">
                            </ng-template>
                        </div>
                    </div>
                    <div class="col-md-6 checkbox-column">
                        <div *ngFor="let modifier3 of modifiers3 | half:'last'" class="checkbox-group">
                            <ng-template [ngTemplateOutlet]="clinicalModifier3"
                                         [ngTemplateOutletContext]="{
                                         modifier3 : modifier3
                                     }">
                            </ng-template>
                        </div>
                    </div>
                </div>
            </ng-template>
        </ng-container>

        <ng-container [ngbNavItem]="'tab-vocab-modifier4-' + domIdAddition">
            <a ngbNavLink>Modifier 4</a>
            <ng-template ngbNavContent>
                <div class="col-md-12">
                    <input type="text" #searchbar 
                           [(ngModel)]="searchInputModifier4"
                           class="form-control input-medium"
                           (keyup)="searchModifier(searchbar.value)"
                           placeholder="Search Modifiers4..." />
                </div>

                <div class="d-flex mt-3">
                    <div class="col-md-6 checkbox-column">
                        <div *ngFor="let modifier4 of modifiers4 | half:'first'" class="checkbox-group">
                            <ng-template [ngTemplateOutlet]="clinicalModifier4"
                                         [ngTemplateOutletContext]="{
                                         modifier4 : modifier4
                                     }">
                            </ng-template>
                        </div>
                    </div>
                    <div class="col-md-6 checkbox-column">
                        <div *ngFor="let modifier4 of modifiers4 | half:'last'" class="checkbox-group">
                            <ng-template [ngTemplateOutlet]="clinicalModifier4"
                                         [ngTemplateOutletContext]="{
                                         modifier4 : modifier4
                                     }">
                            </ng-template>
                        </div>
                    </div>
                </div>
            </ng-template>
        </ng-container>
    </nav>
    <div [ngbNavOutlet]="nav" class="mt-2"></div>
</div>

<div class="modal-footer flex-nowrap">
    <div class="col-md-6">
        <a (click)="selectAll()" class="btn btn-link">Select All</a>
        <a (click)="clearAll()" class="btn btn-link">Clear All</a>
    </div>
    <div class="col-md-6 d-flex justify-content-end">
        <button class="btn btn-primary ml-1" (click)="onSubmit()">
            Save
        </button>
        <button class="btn btn-danger ml-1" (click)="onCancel()">
            Cancel
        </button>
    </div>

</div>

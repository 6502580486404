<ng-container *ngIf="(hasChanges$ | async) && !(switchingWorkgroup$ | async)">
    <button class="global-save-icon" climbButton size="md" variant="warning" (click)="globalSave()">
        <svg [climbIcon]="icons.checkmark" size="sm"></svg>
    </button>
    <button class="changes-button"
            climbButton
            size="md"
            variant="warning"
            [popoverTriggerFor]="saveOptionsPopover"
            popoverOpenWithKeyArrows
            [popoverOptions]="popoverOptions"
            (toggle)="onTogglePopover($event)">
        Changes
        <svg iconPositionEnd [climbIcon]="isPopoverOpen ? icons.caretUpFilled : icons.caretDownFilled" size="sm"></svg>
    </button>
</ng-container>

<climb-popover #saveOptionsPopover>
    <div class="save-options-popover" climbTrapFocus climbArrowKeyAsTab>
        <button climb-menu-item (click)="openWipModal()">
            <svg [climbIcon]="icons.eyeOpened" size="sm"></svg>
            View unsaved changes
        </button>
        <ng-container *ngIf="showExtraOptions">
            <button climb-menu-item (click)="globalSave()">
                <svg [climbIcon]="icons.cloudOut" size="sm"></svg>
                Save changes
            </button>
            <button climb-menu-item (click)="discardChanges()">
                <svg [climbIcon]="icons.trashcan" size="sm"></svg>
                Discard all unsaved changes
            </button>
        </ng-container>
    </div>
</climb-popover>

import {
    CellFormatterService,
    TableOptions,
    FormatterOptions
} from '../common/datatable';
import { AuditModifiedFieldComponent } from './audit-modified-fields/audit-modified-field.component';
import { AuditClassFactory } from './audit-class-maker';

export class AuditResourcesTableOptions {

    options: TableOptions;

    constructor(
        private cellFormatterService: CellFormatterService
    ) {
        this.options = this.getDefaultOptions();
    }

    getDefaultOptions(): TableOptions {

        return {
            csvFileName: 'resource-audit.csv',
            enableDetailColumn: false,
            enableDraggable: false,
            enableSelectable: false,
            enableAuditColumns: false,
            columns: [
                {
                    displayName: 'Type',
                    field: 'UpdateType',
                    cellClass: AuditClassFactory.createDeletedClass
                },
                {
                    displayName: 'Modified Fields',
                    field: 'ModifiedFields',
                    sortField: 'ModifiedFields',
                    rendererComponent: AuditModifiedFieldComponent,
                    exportFormatter: this.cellFormatterService.auditModifiedFieldsExportFormatter,
                    cellClass: AuditClassFactory.createDeletedClass
                },
                {
                    displayName: 'Date',
                    field: 'ModifiedDate',
                    formatter: this.cellFormatterService.dateTimeUTCFormatter,
                    cellClass: AuditClassFactory.createDeletedClass
                },
                {
                    displayName: 'Modified By',
                    field: 'ModifiedBy',
                    formatter: this.cellFormatterService.userNameFormatter,
                    cellClass: AuditClassFactory.createDeletedClass
                },
                {
                    displayName: 'Name',
                    field: 'ResourceName',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('ResourceName', 'ResourceName'),
                    cellClass: AuditClassFactory.createCellClass('resourcename'),
                },
                {
                    displayName: 'Active',
                    field: 'IsActive',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('IsActive', 'IsActive'),
                    cellClass: AuditClassFactory.createCellClass('isactive'),
                },
                {
                    displayName: 'Email',
                    field: 'EmailAddress',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('EmailAddress', 'EmailAddress'),
                    cellClass: AuditClassFactory.createCellClass('emailaddress'),
                },
                {
                    displayName: 'Resource Type',
                    field: 'ResourceType',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('ResourceType', 'ResourceType'),
                    cellClass: AuditClassFactory.createCellClass('resourcetype'),
                },
                {
                    displayName: 'Climb ID',
                    field: 'UserName',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('UserName', 'UserName'),
                    cellClass: AuditClassFactory.createCellClass('username'),
                },
                {
                    displayName: 'Resource Group',
                    field: 'ResourceGroup',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('ResourceGroup', 'ResourceGroup'),
                    cellClass: AuditClassFactory.createCellClass('resourcegroup'),
                },
                {
                    displayName: 'Background Color',
                    field: 'BackgroundColor',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('BackgroundColor', 'BackgroundColor', this.cellFormatterService.colorFormatter),
                    exportFormatter: (row: any, value: any) => {
                        return this.cellFormatterService.auditColumnFormatter(row, value, { escapeHTML: true }, 'BackgroundColor', 'BackgroundColor', this.cellFormatterService.colorExportFormatter);
                    },
                    cellClass: AuditClassFactory.createCellClass('backgroundcolor'),
                },
                {
                    displayName: 'Foreground Color',
                    field: 'ForegroundColor',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('ForegroundColor', 'ForegroundColor', this.cellFormatterService.colorFormatter),
                    exportFormatter: (row: any, value: any) => {
                        return this.cellFormatterService.auditColumnFormatter(row, value, { escapeHTML: true }, 'ForegroundColor', 'ForegroundColor', this.cellFormatterService.colorExportFormatter);
                    },
                    cellClass: AuditClassFactory.createCellClass('foregroundcolor'),
                },
                {
                    displayName: 'Created By',
                    field: 'CreatedBy',
                    formatter: this.cellFormatterService.userNameFormatter,
                    cellClass: AuditClassFactory.createDeletedClass
                },
                {
                    displayName: 'Created Date',
                    field: 'DateCreated',
                    formatter: this.cellFormatterService.dateTimeUTCFormatter,
                    cellClass: AuditClassFactory.createDeletedClass
                }
            ]
        };
    }
}

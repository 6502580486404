import type { cv_GenotypeAssay } from './cv-genotype-assay.interface';
import type { cv_GenotypeSymbol } from './cv-genotype-symbol.interface';

export interface GenotypeAssayGenotypeSymbol {
    C_GenotypeAssayGenotypeSymbol_key: number;
    C_GenotypeAssay_key: number;
    C_GenotypeSymbol_key: number;
    C_Workgroup_key: number;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    ModifiedBy: string;
    Version: number;
    cv_GenotypeAssay: cv_GenotypeAssay;
    cv_GenotypeSymbol: cv_GenotypeSymbol;
}

import { Injectable } from '@angular/core';
import { defer, Observable } from 'rxjs';

import { LocationService } from '../locations/location.service';
import { VocabularyService } from '../vocabularies/vocabulary.service';
import { take } from 'rxjs/operators';

@Injectable()
export class MatingVocabService {

    // vocab Observables
    public get matingTypes$(): Observable<any[]> {
        return defer(() => this.vocabularyService.getCV('cv_MatingTypes')).pipe(take(1));
    }
    public get sexes$(): Observable<any[]> {
        return defer(() => this.vocabularyService.getCV('cv_Sexes')).pipe(take(1));
    }
    public get matingStatuses$(): Observable<any[]> {
        return defer(() => this.vocabularyService.getCV('cv_MatingStatuses')).pipe(take(1));
    }
    public get animalMatingStatuses$(): Observable<any[]> {
        return defer(() => this.vocabularyService.getCV('cv_AnimalMatingStatuses')).pipe(take(1));
    }
    public get matingPurposes$(): Observable<any[]> {
        return defer(() => this.vocabularyService.getCV('cv_MatingPurposes')).pipe(take(1));
    }
    public get generations$(): Observable<any[]> {
        return defer(() => this.vocabularyService.getCV('cv_Generations')).pipe(take(1));
    }
    public get containerTypes$(): Observable<any[]> {
        return defer(() => this.locationService.getContainerTypes('Animal')).pipe(take(1));
    }
    public get matingLines$(): Observable<any[]> {
        return defer(() => this.vocabularyService.getCV('cv_MaterialPoolTypes')).pipe(take(1));
    }

    constructor(
        private locationService: LocationService,
        private vocabularyService: VocabularyService
    ) {
        this._init();
    }
    
    _init() {
        // Loads data into Breeze for local lookup (maybe related to Animals?)
        // TODO (kevin.stone): not sure if this is necessary. 
        // At least could be candidate for refactor
        this.vocabularyService.ensureCVLoaded('cv_MaterialTypes');
        this.vocabularyService.ensureCVLoaded('cv_MaterialPoolTypes');
    }
}

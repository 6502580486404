import { Injectable } from "@angular/core";
import { LocalStorageKey } from "@config";
import { FeatureFlagService } from "@services/feature-flags.service";
import { LocalStorageService } from "@services/local-storage.service";
import { IWorkgroupTimeZoneData } from "./types/workgroup-time-zone-info.interface";
import { DateTime } from 'luxon';
import { LuxonInput } from "./types/luxon-input.type";
import { DATE_EN_GB, DATE_EN_US, TIME_EN_GB, TIME_EN_US } from "./date-format-constants";
import { convertValueToLuxon } from "./convert-value-to-luxon";


@Injectable()
export class DateFormatterService {
    timezoneData: IWorkgroupTimeZoneData;
    // the user's local UTC offset. 
    isGLP: boolean;
    constructor(private localStorageService: LocalStorageService,
                private featureFlagService: FeatureFlagService) {
        this.isGLP = this.featureFlagService.getIsGLP();
        this.timezoneData = this.localStorageService.get(LocalStorageKey.WORKGROUP_TIMEZONE_INFO);
    }

    /**
     * Formats the supplied date as both the date and time.
     * @param d
     */
    formatDateTime(d: LuxonInput): string {
        return this._formatDate(d);
    }

    /**
     * Formats the supplied date as both the date and time, converted to UTC.
     * @param value input date
     */
    formatDateTimeUTC(value: LuxonInput): string {
        return this._formatDate(value, false, true);
    }

    /**
     * Formats the supplied date as both a date and time, according to their local time. 
     * @param value input date
     * @returns 
     */
    formatDateTimeAsLocal(value: LuxonInput) {
        return this._formatDate(value, false, false, false, true);
    }

    /**
     * Formats the supplied date as a datetime or just a date if the time is midnight.
     * @param d
     */
    formatDateOrTime(d: LuxonInput): string {
        return this._formatDate(d, false, false, true);
    }
    /**
     * Formats the supplied date as a datetime or just a date if the time is midnight. Converts to UTC time. 
     * @param d
     */
    formatDateOrTimeUTC(d: LuxonInput): string {
        return this._formatDate(d, false, true, true);
    }

    /**
     * Formats the supplied date based on the workgroup feature flags.
     * No time zone conversion is done on date-only fields. 
     * @param d
     */
    formatDateOnly(d: LuxonInput): string {
        return this._formatDate(d, true, false, false, true);
    }
    /**
     * Formats the supplied date based on workgroup feature flags after converting it to UTC.
     * @param d
     */
    formatDateOnlyUTC(d: LuxonInput): string {
        return this._formatDate(d, true, true);
    }

    resolveTimeZone(convertUtc?: boolean): string {
        if (convertUtc) {
            return "UTC";
        } else if (this.timezoneData) {
            return this.timezoneData.TimeZoneIana;
        } else {
            return new Intl.DateTimeFormat().resolvedOptions().timeZone;
        }
    }

    resolveDateFormat(): string {
        if (this.isGLP) {
            return DATE_EN_GB;
        } else {
            return DATE_EN_US;
        }
    }

    resolveDateTimeFormat(dateOnly: boolean, convertUtc: boolean): string {
        if (dateOnly) {
            return this.resolveDateFormat();
        } else {
            let format = this.resolveDateFormat() + " " + this.resolveTimeFormat();
            if (convertUtc) {
                format = format.replace("ZZ", "");
            }
            return format;
        }
    }

    resolveTimeFormat(): string {
        if (this.isGLP) {
            return TIME_EN_GB;
        } else {
            return TIME_EN_US;
        }
    }

    /**
     * Converts a time string from the workflow facet into a UTC ISO representation. 
     * Workflow times need to be converted to UTC before going through formatting to ensure accuracy. 
     * TODO: Backend task, change the format so that these datetime strings are in ISO. 
     * @param value a time string from the workflow facet
     */
    convertWorkflowTimeToUTC(value: string) {
        try {
            const dt = DateTime.fromFormat(value, 'M/d/yyyy h:mm:ss a', {zone: "UTC"});
            return dt.toISO();
        } catch {
            return null;
        }
    }
    
    /**
     * Formats the Date or DateTime according to the specified options and workgroup feature flags. 
     * @param value the object that can be converted. Any type that can be converted via moment is valid. 
     * @param dateOnly optional, only show the date when formatting.
     * @param convertUtc optional, convert the date to UTC before formatting.
     * @param dateOnlyIfMidnight optional, hide the time if the date is midnight. 
     * @param noConversion optional, do not convert the date to the workgroup time zone
     */
    private _formatDate(value: LuxonInput, dateOnly?: boolean, convertUtc?: boolean, dateOnlyIfMidnight?: boolean, noConversion?: boolean): string {
        if (!value || value === "") {
            return "";
        }
        const luxonDate = convertValueToLuxon(value);

        if (!luxonDate.isValid) {
            return "INVALID DATE";
        }
        const isMidnight = (luxonDate.hour === 0 && luxonDate.minute === 0 && luxonDate.second === 0);
        if (dateOnlyIfMidnight && isMidnight) {
            dateOnly = true;
        }
    
        const timeZone = this.resolveTimeZone(convertUtc);
        const format = this.resolveDateTimeFormat(dateOnly, convertUtc);
        // do not set the time zone in non-GLP workgroups, only the date is displayed, or if the time is meant to be local.  
        if (!this.isGLP || !this.timezoneData || dateOnly || noConversion) {
            return luxonDate.toFormat(format);
        } else {
            return luxonDate.setZone(timeZone).toFormat(format);
        }
    }

    /**
     * Formats the Time according to the specified options and workgroup feature flags. 
     * @param value the object that can be converted. Any type that can be converted via moment is valid.
     * @param convertUtc optional, convert the time to UTC beore formatting. 
     */
    formatTime(value: string, convertUtc?: boolean): string {
        if (!value) {
            return "";
        }
        const timeFormat = this.resolveTimeFormat();
        const luxonTime = DateTime.fromFormat(value, "hh:mm a");
        const timeZone = this.resolveTimeZone(convertUtc);
        return luxonTime.setZone(timeZone).toFormat(timeFormat);
    }

    /**
     * Gets the Javascript Date object from an ISO string, with validity checks included. 
     * @param value the ISO string 
     * @returns 
     */
    getJSDateFromISO(value: string): Date {
        const dt = convertValueToLuxon(value);
        return dt ? dt.toJSDate() : null;
    }

    /**
     * @param d
     * @returns true if d is set to a midnight time.
     */
    public static isDateMidnight(d: Date): boolean {
        return d.getHours() === 0
            && d.getMinutes() === 0
            && d.getSeconds() === 0;
    }
}

import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { ClimbCommonModule } from '@common/climb-common.module';
import { ClimbServicesModule } from '@services/climb-services.module';

import { AnimalsSharedModule } from '../animals/animals-shared.module';
import { WorkflowService } from './services/workflow.service';
import { WorkflowLogicService } from './services/workflow-logic.service';
import { WorkflowVocabService } from './services/workflow-vocab.service';
import { ExportWorkflowAuditService } from './services/export-workflow-audit.service';
import { ExportWorkflowDetailService } from './services/export-workflow-detail.service';
import {
    WorkflowHealthRecordDetailsComponent,
    WorkflowLineDetailsComponent,
    WorkflowAnimalDetailsComponent, 
    WorkflowBirthDetailsComponent, 
    WorkflowHousingDetailsComponent, 
    WorkflowJobDetailsComponent, 
    WorkflowMatingDetailsComponent
} from './components';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ClimbCommonModule,
        ClimbServicesModule,
        AnimalsSharedModule
    ],
    declarations: [
        WorkflowAnimalDetailsComponent,
        WorkflowBirthDetailsComponent,
        WorkflowHealthRecordDetailsComponent,
        WorkflowHousingDetailsComponent,
        WorkflowJobDetailsComponent,
        WorkflowLineDetailsComponent,
        WorkflowMatingDetailsComponent
    ],
    exports: [
        WorkflowAnimalDetailsComponent,
        WorkflowBirthDetailsComponent,
        WorkflowHealthRecordDetailsComponent,
        WorkflowHousingDetailsComponent,
        WorkflowJobDetailsComponent,
        WorkflowLineDetailsComponent,
        WorkflowMatingDetailsComponent
    ]
})
export class WorkflowsSharedModule {
    static forRoot(): ModuleWithProviders<WorkflowsSharedModule> {
        return {
          ngModule: WorkflowsSharedModule,
          providers: [
            WorkflowService,
            WorkflowLogicService,
            WorkflowVocabService,
            ExportWorkflowDetailService,
            ExportWorkflowAuditService
          ]
        };
    }
}

import type { Animal } from './animal.interface';
import type { EventType } from './event.interface';
import type { Resource } from './resource.interface';
import type { cv_BodySystem } from './cv-body-system.interface';
import type { cv_ClinicalObservation } from './cv-clinical-observation.interface';
import type { cv_ClinicalObservationStatus } from './cv-clinical-observation-status.interface';
import type { cv_Modifier1 } from './cv-modifier1.interface';
import type { cv_Modifier2 } from './cv-modifier2.interface';
import type { cv_Modifier3 } from './cv-modifier3.interface';
import type { cv_Modifier4 } from './cv-modifier4.interface';

export interface AnimalDiagnosticObservation {
    Animal: Animal;
    C_AnimalDiagnosticObservation_key: number;
    C_BodySystem_key: number | null;
    C_ClinicalObservationStatus_key: number | null;
    C_ClinicalObservation_key: number | null;
    C_Material_key: number;
    C_Modifier1_key: number | null;
    C_Modifier2_key: number | null;
    C_Modifier3_key: number | null;
    C_Modifier4_key: number | null;
    C_Resource_key: number | null;
    C_Workgroup_key: number;
    ObservedByUsername: string;
    Comments: string;
    CreatedBy: string;
    DateCreated: Date;
    DateDue: Date | null;
    DateModified: Date;
    DateObserved: Date;
    Event: EventType[];
    JobName: string;
    ModifiedBy: string;
    Resource: Resource;
    ReviewDate: Date | null;
    Treatment: string;
    Version: number;
    cv_BodySystem: cv_BodySystem;
    cv_ClinicalObservation: cv_ClinicalObservation;
    cv_ClinicalObservationStatus: cv_ClinicalObservationStatus;
    cv_Modifier1: cv_Modifier1;
    cv_Modifier2: cv_Modifier2;
    cv_Modifier3: cv_Modifier3;
    cv_Modifier4: cv_Modifier4;
}

<form role="form" class="form-label-right">

    <div class="modal-header justify-content-center">
        <h4 class="modal-title">Orders Filter</h4>
    </div>

    <div class="modal-body">

        <div class="form-group row">
            <label class="col-md-4 col-form-label">Order ID</label>
            <div class="col-md-8">
                <input type="text"
                       name="OrderID"
                       #name="ngModel"
                       [(ngModel)]="filter.OrderID"
                       maxlength="200"
                       class="form-control input-medium" />
            </div>
        </div>

        <div *ngIf="!isGLP" class="form-group row">
            <label class="col-md-4 col-form-label">Order Type</label>
            <div class="col-md-8">
                <select [(ngModel)]="filter.C_MaterialType_key"
                    name="orderType">
                    <option></option>
                    <option *ngFor="let materialType of materialTypes"
                        [value]="materialType.C_MaterialType_key">{{materialType.MaterialType}}</option>
                </select>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-4 col-form-label">{{'Job' | translate}}</label>
            <div class="col-md-8">
                <job-multiselect [(model)]="filter.jobs"></job-multiselect>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-4 col-form-label">Locations</label>
            <div class="col-md-8">
                <input type="text"
                       name="location"
                       [(ngModel)]="filter.Location"
                       class="form-control input-medium" />
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-4 col-form-label">PO Number</label>
            <div class="col-md-8">
                <input type="text"
                       name="InvoiceNumber"
                       #name="ngModel"
                       [(ngModel)]="filter.InvoiceNumber"
                       maxlength="200"
                       class="form-control input-medium" />
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-4 col-form-label">Species</label>
            <div class="col-md-8">
                <climb-multi-select [(model)]="filter.C_Taxon_keys"
                                    [values]="taxons"
                                    [keyProperty]="'C_Taxon_key'"
                                    [valueProperty]="'CommonName'"
                                    [placeholder]="'Select Species...'"></climb-multi-select>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-4 col-form-label">Farm/Vendor</label>
            <div class="col-md-8">
                <climb-multi-select [(model)]="filter.C_Institution_keys"
                                    [values]="institutions"
                                    [keyProperty]="'C_Institution_key'"
                                    [valueProperty]="'Name'"
                                    [placeholder]="'Select ' + institutionTranslation + 's...'"></climb-multi-select>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-4 col-form-label">Country of Origin</label>
            <div class="col-md-8">
                <active-vocab-select [(model)]="filter.C_MaterialOrigin_key"
                                     [vocabChoices]="materialOrigins"
                                     [keyFormatter]="materialOriginKeyFormatter"
                                     [optionFormatter]="materialOriginFormatter"
                                     [nullable]="true">
                </active-vocab-select>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-4 col-form-label">Vendor Order Number</label>
            <div class="col-md-8">
                <input type="text"
                       name="VendorOrderNumber"
                       #name="ngModel"
                       [(ngModel)]="filter.VendorOrderNumber"
                       maxlength="200"
                       class="form-control input-medium" />
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-4 col-form-label">Vendor Room ID</label>
            <div class="col-md-8">
                <input type="text"
                       name="VendorRoom"
                       #name="ngModel"
                       [(ngModel)]="filter.VendorRoomId"
                       maxlength="200"
                       class="form-control input-medium" />
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-4 col-form-label">Number of Animals Ordered</label>
            <div class="col-md-8">
                <input type="number"
                        name="AnimalCount"
                        [(ngModel)]="filter.AnimalCount"
                        step="1"
                        min="1"
                        class="form-control input-number" />
            </div>
        </div>

        <!-- Waiting for more info about Lots grid
        <div class="form-group row">
            <label class="col-md-3 col-form-label">Lot Number</label>
            <div class="col-md-9">
                <input type="text"
                       name="LotNumber"
                       #name="ngModel"
                       [(ngModel)]="filter.LotNumber"
                       maxlength="200"
                       class="form-control input-medium" />
            </div>
        </div>
        -->

        <div class="form-group row">
            <label class="col-md-4 col-form-label">Quarantine Services Facility</label>
            <div class="col-md-8">
                <climb-multi-select [(model)]="filter.C_QuarantineFacility_keys"
                                    [values]="quarantineFacilities"
                                    [keyProperty]="'C_QuarantineFacility_key'"
                                    [valueProperty]="'QuarantineFacility'"
                                    [placeholder]="'Select Quarantine Services Facility...'"></climb-multi-select>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-4 col-form-label">Shipment Animal Condition</label>
            <div class="col-md-8">
                <climb-multi-select [(model)]="filter.C_ShipmentAnimalCondition_keys"
                                    [values]="shipmentAnimals"
                                    [keyProperty]="'C_ShipmentAnimalCondition_key'"
                                    [valueProperty]="'ShipmentAnimalCondition'"
                                    [placeholder]="'Select Shipment Animal Condition...'"></climb-multi-select>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-4 col-form-label">Shipment Container Condition</label>
            <div class="col-md-8">
                <climb-multi-select [(model)]="filter.C_ShipmentContainerCondition_keys"
                                    [values]="shipmentContainers"
                                    [keyProperty]="'C_ShipmentContainerCondition_key'"
                                    [valueProperty]="'ShipmentContainerCondition'"
                                    [placeholder]="'Select Shipment Container Condition...'"></climb-multi-select>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-4 col-form-label">Number of Animals</label>
            <div class="col-md-8">
                <input type="number"
                       name="AnimalOrderCount"
                       #name="ngModel"
                       [(ngModel)]="filter.AnimalOrderCount"
                       step="any"
                       min="0"
                       class="form-control input-medium" />
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-4 col-form-label">Sex</label>
            <div class="col-md-8">
                <select name="sexKey"
                        [(ngModel)]="filter.C_Sex_key"
                        class="form-control input-medium">
                    <option></option>
                    <option *ngFor="let item of sexes"
                            [value]="item.C_Sex_key">
                        {{item.Sex}}
                    </option>
                </select>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-4 col-form-label">{{'Line' | translate}}</label>
            <div class="col-md-8">
                <line-multiselect [(model)]="filter.lines"></line-multiselect>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-4 col-form-label">CITES</label>
            <div class="col-md-8">
                <input type="text"
                       name="Cites"
                       #name="ngModel"
                       [(ngModel)]="filter.Cites"
                       maxlength="200"
                       class="form-control input-medium" />
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-4 col-form-label">Date Ordered Range</label>
            <div class="col-md-4">
                <climb-ngb-date #dateControl="ngModel"
                                name="Date Ordered Start"
                                [(ngModel)]="filter.DateReceivedStart"
                                [required]="false"
                                placeholder="From&hellip;"></climb-ngb-date>
            </div>
            <div class="col-md-4">
                <climb-ngb-date #dateControl="ngModel"
                                [(ngModel)]="filter.DateReceivedEnd"
                                name="Date Ordered End"
                                [required]="false"
                                placeholder="To&hellip;"></climb-ngb-date>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-4 col-form-label">Projected Receipt Date Range</label>
            <div class="col-md-4">
                <climb-ngb-date dateControl="ngModel"
                                [(ngModel)]="filter.DateProjectedReceiptStart"
                                name="Projected Receipt Date Start"
                                [required]="false"
                                placeholder="From&hellip;"></climb-ngb-date>
            </div>
            <div class="col-md-4">
                <climb-ngb-date dateControl="ngModel"
                                [(ngModel)]="filter.DateProjectedReceiptEnd"
                                name="Projected Receipt Date End"
                                [required]="false"
                                placeholder="To&hellip;"></climb-ngb-date>
            </div>
        </div>

    </div>

    <div class="modal-footer">
        <span class="btn-set">
            <button type="submit"
                    class="btn btn-fw-wide btn-lg btn-primary"
                    (click)="filterClicked($event)">
                Filter
            </button>
            <button data-automation-id="clear-filter-button"
                    type="button"
                    class="btn btn-fw-wide btn-lg btn-secondary"
                    (click)="clearClicked()">
                Clear Filter
            </button>
        </span>
        <span class="btn-set">
            <button type="button"
                    class="btn btn-lg btn-secondary"
                    (click)="closeClicked()">
                Close
            </button>
        </span>
    </div>
</form>

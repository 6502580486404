import {
    Component,
    EventEmitter,
    Input,
    Output,
    OnInit,
    ViewChildren
} from '@angular/core';
import { LoggingService } from '../services/logging.service';
import { datesEqual } from '../common/util/';
import { NgModel } from '@angular/forms';
import { dateControlValidator } from '@common/util/date-control.validator';

@Component({
    selector: 'location-history',
    templateUrl: './location-history.component.html',
})
export class LocationHistoryComponent implements OnInit {
    @ViewChildren('dateControl') dateControls: NgModel[];
    @Input() facetPrivilege: string;
    @Input() materialLocations: any[];

    @Output() createMaterialLocation: EventEmitter<any> = new EventEmitter<any>();
    @Output() removeMaterialLocation: EventEmitter<any> = new EventEmitter<any>();

    previousMaterialLocationDates = new Map<number, Date[]>();

    readonly COMPONENT_LOG_TAG = 'Location_History';

    constructor(
        private loggingService: LoggingService
    ) { }

    ngOnInit() {
        this.initPreviousDateTracking();
    }

    initPreviousDateTracking() {
        this.materialLocations.forEach((materialLocation) => {
            const key = materialLocation.C_MaterialPoolLocation_key;
            const dateIn = materialLocation.DateIn;
            const dateOut = materialLocation.DateOut;
            this.previousMaterialLocationDates.set(key, [dateIn, dateOut]);
        });
    }

    updatePreviousDateIn(key: number, newDateIn: Date) {
        const prevDateOut = this.previousMaterialLocationDates[1];
        this.previousMaterialLocationDates.set(key, [newDateIn, prevDateOut]);
    }

    updatePreviousDateOut(key: number, newDateOut: Date) {
        const prevDateIn = this.previousMaterialLocationDates[0];
        this.previousMaterialLocationDates.set(key, [prevDateIn, newDateOut]);
    }

    editEnd(location: any, dateInInput: any, dateOutInput: any) {
        const key = this.materialLocations.indexOf(location);
        const dateIn = location.DateIn;
        const dateOut = location.DateOut;

        let errMsg;
        if (!dateIn) {
            errMsg = 'Location must have a Date In.';
        }

        if (!errMsg) {
            errMsg = this.validateDateRange(location, key, dateIn);
        }

        if (errMsg) {
            this.handleInvalidDateRange(errMsg, location, key, 'DateIn', dateInInput, dateOutInput, true);
            return;
        }

        errMsg = this.validateDateRange(location, key, dateOut);
        if (errMsg) {
            this.handleInvalidDateRange(errMsg, location, key, 'DateOut', dateInInput, dateOutInput, true);
            return;
        }

        location.selectingLocation = false;
    }

    dateChanged(location: any, changedProp: string, dateInInput: any, dateOutInput: any): void {
        const changedDate = changedProp === 'DateIn' ? location.DateIn : location.DateOut;
        const key = this.materialLocations.indexOf(location);
        const errMsg = this.validateDateRange(location, key, changedDate);

        if (errMsg) {
            this.handleInvalidDateRange(errMsg, location, key, changedProp, dateInInput, dateOutInput);
        } else {
            if (changedProp === 'DateIn') {
                this.updatePreviousDateIn(key, changedDate);
            } else {
                this.updatePreviousDateOut(key, changedDate);
            }
        }
    }

    validateDateRange(location: any, key: number, changedDate: Date): string {
        if (!changedDate) {
            return;
        }

        let errMsg;

        const dateIn = location.DateIn;
        const dateOut = location.DateOut;

        if (dateIn && dateOut && !datesEqual(dateIn, dateOut) && dateIn > dateOut) {
            errMsg = 'Date In cannot be after Date Out.';
        }

        if (!errMsg) {
            for (let i = 0; i < this.materialLocations.length; i++) {
                const currMaterialLocation = this.materialLocations[i];

                if (key === i) {
                    continue;
                }

                const currDateIn = currMaterialLocation.DateIn;
                const currDateOut = currMaterialLocation.DateOut;

                if (currDateIn && currDateOut) {
                    const isDateInSameAsOneDayRange = dateIn && datesEqual(dateIn, currDateIn) && datesEqual(dateIn, currDateOut);
                    const isDateOutSameAsOneDayRange = dateOut && datesEqual(dateOut, currDateIn) && datesEqual(dateOut, currDateOut);
                    const areBothRangesSame = datesEqual(dateIn, currDateIn) && datesEqual(dateOut, currDateOut);

                    if (isDateInSameAsOneDayRange && isDateOutSameAsOneDayRange) {
                        continue;
                    }

                    if (areBothRangesSame) {
                        errMsg = 'Dates cannot overlap for different location entries.';
                        break;
                    }
                }

                const isChangedDateInRange = this.isDateInRange(dateIn, currDateIn, currDateOut) || this.isDateInRange(dateOut, currDateIn, currDateOut);
                const isCompareDateInRange = this.isDateInRange(currDateIn, dateIn, dateOut) || this.isDateInRange(currDateOut, dateIn, dateOut);

                if (isChangedDateInRange || isCompareDateInRange) {
                    errMsg = 'Dates cannot overlap for different location entries.';
                    break;
                }
            }
        }

        return errMsg;
    }

    isDateInRange(checkDate: Date, compDateIn: Date, compDateOut: Date) {
        if (!checkDate || !compDateIn || !compDateOut) {
            return false;
        }

        return (!datesEqual(checkDate, compDateIn) && checkDate > compDateIn) &&
            (!datesEqual(checkDate, compDateOut) && checkDate < compDateOut);
    }

    handleInvalidDateRange(errMsg: string, location: any, key: any, changedProp: string, dateInInput: any, dateOutInput: any, logOnly = false): void {
        let prevDateIn;
        let prevDateOut;

        if (this.previousMaterialLocationDates.has(key)) {
            const prevDates = this.previousMaterialLocationDates.get(key);
            prevDateIn = prevDates[0];
            prevDateOut = prevDates[1];
        }

        const dateInSame = changedProp === 'DateIn' && location.DateIn && prevDateIn && datesEqual(prevDateIn, location.DateIn);
        const dateOutSame = changedProp === 'DateOut' && location.DateOut && prevDateOut && datesEqual(prevDateOut, location.DateOut);

        if (dateInSame || dateOutSame) {
            return;
        }
        
        this.loggingService.logError(errMsg, null, this.COMPONENT_LOG_TAG, true);

        if (logOnly) {
            return;
        }

        if (changedProp === 'DateIn') {
            if (this.previousMaterialLocationDates.has(key)) {
                if (prevDateIn) {
                    location.DateIn = prevDateIn;
                    dateInInput.revertDateChange(location.DateIn);
                } else {
                    location.DateIn = null;
                    dateInInput.clear();
                }
            } else {
                location.DateIn = null;
                dateInInput.clear();
            }
        } else {
            if (this.previousMaterialLocationDates.has(key)) {
                if (prevDateOut) {
                    location.DateOut = prevDateOut;
                    dateOutInput.revertDateChange(location.DateOut);
                } else {
                    location.DateOut = null;
                    dateOutInput.clear();
                }
            } else {
                location.DateOut = null;
                dateOutInput.clear();
            }
        }
    }

    create() {
        let allLocationsComplete = false;

        this.materialLocations.forEach((location) => {
            allLocationsComplete = location.DateIn === null || location.DateOut === null;
        });
        if (!allLocationsComplete) {
            this.createMaterialLocation.emit();
        } else {
            this.loggingService.logError('To add a new location first add a Date In and Date Out to previous location(s).', null, this.COMPONENT_LOG_TAG, true);
        }
    }

    remove(locationPosition: any): void {
        this.removeMaterialLocation.emit(locationPosition);
    }

    validate() {
        return dateControlValidator(this.dateControls);
    }
}

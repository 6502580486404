import type { AnimalPlaceholder } from './animal-placeholder.interface';
import type { Material } from './material.interface';
import type { SampleGroup } from './sample-group.interface';

export interface SampleGroupSourceMaterial {
    AnimalPlaceholder: AnimalPlaceholder;
    C_AnimalPlaceholder_key: number | null;
    C_Material_key: number | null;
    C_SampleGroupSourceMaterial_key: number;
    C_SampleGroup_key: number | null;
    C_Workgroup_key: number;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    Material: Material;
    ModifiedBy: string;
    SampleGroup: SampleGroup;
    Version: number;
}

import { isEqual } from '@lodash';
import { LoggingService } from './../../services/logging.service';
import { BulkAddCommService } from './bulk-add-comm.service';
import { NgForm } from '@angular/forms';
import { ViewUnsavedChangesModalService } from './../toolbars/view-unsaved-changes-modal.service';
import { BulkAddState, ExitReasonConfig } from './models/bulk-edit.classes';
import { Subscription } from 'rxjs';
import { FacetLoadingStateService } from './facet-loading-state.service';
import { BulkEditSharedLogic } from './bulk-edit.shared';
import {
    BulkEditOptions,
    BulkAddResult,
    BulkEditSection,
    BulkAddExitReason
} from './models';
import {
    Component,
    Input,
    Output,
    OnInit,
    OnChanges,
    EventEmitter,
    OnDestroy,
    ViewChild
} from '@angular/core';
import { ViewBulkAddPromptModalService } from './view-bulk-add-prompt-modal.service';
import { formatDataAutomationId } from '@common/util/format-data-automation-id';

interface ExitItem {
  exist: () => boolean;
  dateAutomationId: string;
  appInsights: () => string;
  label: string;
  click: () => void;
  disabled?: () => boolean;
  tooltip?: () => string;
}

@Component({
    selector: 'bulk-add',
    templateUrl: './bulk-add.component.html',
    styles: [`
        .form-group {
            margin-bottom: 4px;
        }
        .number-items-to-add {
            width: 90px;
            margin-left: 10px;
        }
        .spacer {
            height: 10px;
        }
        .dropdown-item {
            &:disabled {
                pointer-events: unset;
            }
        }
    `]
})
export class BulkAddComponent implements OnInit, OnChanges, OnDestroy {
    @Input() options: BulkEditOptions;
    @Input() dataAutomationId: string;
    @Input() COMPONENT_LOG_TAG = 'bulk-add-component';
    @Output() save: EventEmitter<BulkAddResult> = new EventEmitter<BulkAddResult>();
    @Output() exit: EventEmitter<BulkAddResult> = new EventEmitter<BulkAddResult>();
    @Output() itemsToAddChange: EventEmitter<number> = new EventEmitter<number>();
    @Output() formClear: EventEmitter<BulkEditOptions> = new EventEmitter<BulkEditOptions>();

    @ViewChild('bulkAddForm') bulkAddForm: NgForm;

    BulkEditSection = BulkEditSection;
    BulkAddExitReason = BulkAddExitReason;

    sharedLogic: BulkEditSharedLogic;

    originalObject: any = null;

    // options and functions to expose to optional "itemsToAddTemplate"
    addState: BulkAddState = {
        numberItemsToAdd: 1,
        limitNumberItemsToAdd: this.limitNumberItemsToAdd.bind(this),
    };

    readonly DEFAULT_MAX_ITEMS = 50;
    loading = false;
    saving = false;
    savingMessage = "";

    private _subscriptions: Subscription[] = [];

    private get exitReasonConfig(): ExitReasonConfig | undefined {
        return this.options?.exitReasonConfig;
    }

    exitItems: ExitItem[] = [
      {
          exist: this.getExitItemExist.bind(this, BulkAddExitReason.Save),
          dateAutomationId: 'save-item',
          appInsights: (): string => ('click-bulk-add-save' + this.options?.itemTypeLabel),
          label: 'Save',
          click: this.emitSave.bind(this),
          tooltip: this.getExitItemTooltip.bind(this, BulkAddExitReason.Save),
          disabled: this.canSave.bind(this),
      },
      {
          exist: this.getExitItemExist.bind(this, BulkAddExitReason.Save),
          dateAutomationId: 'save-item-and-clear',
          appInsights: (): string => 'click-bulk-add-save-and-clear' + this.options?.itemTypeLabel,
          label: 'Save and Clear Form',
          click: this.emitSaveAndClear.bind(this),
          tooltip: this.getExitItemTooltip.bind(this, BulkAddExitReason.Save),
          disabled: this.canSave.bind(this),
      },
      {
          exist: this.getExitItemExist.bind(this, BulkAddExitReason.Edit),
          dateAutomationId: 'save-item-and-edit',
          appInsights: (): string => 'click-bulk-add-save-and-edit' + this.options?.itemTypeLabel,
          label: 'Edit and Save Records',
          click: this.emitSaveAndEdit.bind(this),
          tooltip: this.getExitItemTooltip.bind(this, BulkAddExitReason.Edit),
          disabled: this.canEdit.bind(this),
      }
  ] ;

    constructor(
        private bulkAddCommService: BulkAddCommService,
        private facetLoadingStateService: FacetLoadingStateService,
        private loggingService: LoggingService,
        private viewUnsavedChangesModalService: ViewUnsavedChangesModalService,
        private viewBulkAddPromptModalService: ViewBulkAddPromptModalService,
    ) {
        this.sharedLogic = new BulkEditSharedLogic();
    }

    ngOnInit() {
        this.addState.numberItemsToAdd = 1;
        const s1 = this.facetLoadingStateService.changeLoadingState$
            .subscribe((loading) => {
                this.loading = loading;
            });
        this._subscriptions.push(s1);

        const s2 = this.bulkAddCommService.saveCanceled$.subscribe(() => {
            this.loading = false;
            this.saving = false;
            this.savingMessage = "";
        });
        this._subscriptions.push(s2);

        const s3 = this.bulkAddCommService.markFormPristine$.subscribe(() => {
            if (this.options) {
                this.originalObject = { ... this.options.__addObject };
            }
        });
        this._subscriptions.push(s3);
    }

    ngOnChanges(changes: any) {
        this.initializeBulkOptions(this.options);
    }

    ngOnDestroy() {
        for (const subscription of this._subscriptions) {
            subscription.unsubscribe();
        }
    }

    getExitItemExist(key: BulkAddExitReason): boolean {
      return this.exitReasonConfig?.[key]?.exist ?? true;
    }

    getExitItemDisabled(key: BulkAddExitReason): boolean {
      return this.exitReasonConfig?.[key]?.disabled ?? false;
    }

    getExitItemTooltip(key: BulkAddExitReason): string | undefined {
      return this.exitReasonConfig?.[key]?.tooltip;
    }

    initializeBulkOptions(options: BulkEditOptions) {
        this.sharedLogic.updateBulkOptions(options);
        if (options) {
            this.originalObject = { ...options.__addObject };
        }
    }

    limitNumberItemsToAdd() {
        const maxValue = this.options.maxNumberItemsToAdd || this.DEFAULT_MAX_ITEMS;

        if (this.addState.numberItemsToAdd > maxValue) {
            this.addState.numberItemsToAdd = maxValue;
        }
    }

    numberItemsToAddBlurred($event: any) {
        // First, make sure the number is within the limits
        this.limitNumberItemsToAdd();

        // Let the caller know the number of items has changed
        this.itemsToAddChange.emit(this.addState.numberItemsToAdd);
    }

    canSave(): boolean {
      if (!this.addState) {
          return false;
      }

      return !this.getExitItemDisabled(BulkAddExitReason.Save)
          && !this.loading
          && this.addState.numberItemsToAdd > 0
          && this.isValidToSave();
    }

    canEdit(): boolean {
        if (!this.addState) {
            return false;
        }

        return !this.getExitItemDisabled(BulkAddExitReason.Edit)
            && !this.loading
            && this.addState.numberItemsToAdd <= 200;
    }

   async exitClicked(): Promise<void> {
       if (this.formIsDirty()) {
           await this.promptForUnsavedChanges();
       } else {
           this.emitCancelAndExit();
       }
   }

    /**
     * Prompt user to save or discard changes.
     * If form cannot be saved, prompt to stay on form or discard changes.
     */
    async promptForUnsavedChanges(): Promise<void> {
        if (this.isValidToSave()) {
            // If there are unsaved changes, prompt the user to save or discard
            return this.viewUnsavedChangesModalService.openComponent(this.COMPONENT_LOG_TAG).then((result: string) => {
                if (result === 'save') {
                    return this.emitSaveAndExit();
                }

                return this.emitCancelAndExit();
            });
        } else {
            return this.viewBulkAddPromptModalService.openComponent().then((result: string) => {
                if (result === 'discard') {
                    return this.emitCancelAndExit();
                }

                // stay and complete the form
                return Promise.resolve();
            });
        }
    }

    // functions for emitting save/exit results
    emitSave() {
        this.afterNextSave(() => {
            this.bulkAddCommService.markFormPristine();
        });

        this._emitSave(BulkAddExitReason.Save, false);
    }

    emitSaveAndClear() {
        this.afterNextSave(() => {
            this.clearForm();
        });

        this._emitSave(BulkAddExitReason.Save, false, true);
    }

    emitCancelAndExit() {
        this.exit.emit({
            reason: BulkAddExitReason.Cancel,
            numberItemsToAdd: null,
            initialValues: {},
            clearForm: false
        });
    }

    emitSaveAndExit() {
        this._emitSave(BulkAddExitReason.Save, true);
    }

    emitSaveAndEdit() {
        this._emitSave(BulkAddExitReason.Edit, true);
    }

    _emitSave(reason: BulkAddExitReason, exitAfterSave = false, clearForm = false) {
        this.loading = true;
        this.saving = true;

        this.limitNumberItemsToAdd();

        const numberItemsToAdd = this.addState.numberItemsToAdd;
        const initialValues = this.options.__addObject;
        const result = {
            reason, numberItemsToAdd, initialValues, clearForm
        };

        this.savingMessage = "Saving";
        if (numberItemsToAdd >= this.DEFAULT_MAX_ITEMS) {
            this.savingMessage = "Saving. " +
                "It may take a minute or longer to save a large number of records";
        }

        this.afterNextSave(() => {
            this.loading = false;
            this.saving = false;

            // log saved items
            let logLabel = this.options.itemTypeLabel;
            if (numberItemsToAdd > 1) {
                logLabel = this.options.itemTypeLabelPlural;
            }
            if (result.reason === BulkAddExitReason.Save) {
                this.loggingService.logSuccess(
                    numberItemsToAdd + ' ' + logLabel + ' Saved',
                    '', 'bulk-add', true
                ).attr("data-automation-id", formatDataAutomationId(this.dataAutomationId, "", "-text"));
            }

            // send exit event
            if (exitAfterSave) {
                this.exit.emit(result);
            }
        });

        this.save.emit(result);
    }

    formIsDirty(): boolean {
        return !isEqual(this.originalObject, this.options.__addObject);
    }

    clearForm() {
        this.initializeBulkOptions(this.options);

        // Notify callers that any extra form elements should be cleared.
        this.formClear.emit(this.options);
    }

    isValidToSave(): boolean {
        if (this.options && this.options.saveButtonValidators && !!this.options.saveButtonValidators.length) {
            const validationResults = this.options.saveButtonValidators.map((x: () => boolean) => x());
            return !validationResults.some((x: boolean) => x === false);
        }
        return true;
    }

    /**
     * One-time callback that gets disposed of after the next saveComplete$ 
     *  or saveCanceled$ event 
     * @param callback 
     */
    afterNextSave(callback: () => void) {
        const subscriptions: Subscription[] = [];
        const s1 = this.bulkAddCommService.saveComplete$.subscribe(() => {
            callback();
            for (const sub of subscriptions) {
                sub.unsubscribe();
            }
        });

        const s2 = this.bulkAddCommService.saveCanceled$.subscribe(() => {
            for (const sub of subscriptions) {
                sub.unsubscribe();
            }
        });

        subscriptions.push(s1);
        subscriptions.push(s2);

        this._subscriptions.push(s1);
        this._subscriptions.push(s2);
    }
}

const ELLIPSIS = '\u2026';

import {
    Component,
    Input,
    OnInit,
} from '@angular/core';

import { SampleService } from './sample.service';
import { ConstructService } from '../constructs/construct.service';
import { SearchService } from '../search/search.service';

@Component({
    selector: 'sample-construct-select',
    templateUrl: './sample-construct-select.component.html',
    styles: [`
        .table {
            margin-bottom: 0.25em;
        }
    `]
})
export class SampleConstructSelectComponent implements OnInit {
    @Input() sample: any;
    @Input() readonly: boolean;

    placeholder: string;

    constructor(
        private sampleService: SampleService,
        private constructService: ConstructService,
        private searchService: SearchService
    ) {
        //
    }

    ngOnInit() {
        this.placeholder = 'Name' +
            ELLIPSIS;
    }

    addSampleConstruct(construct: any) {
        const materialKey = this.sample.C_Material_key;
        const constructKey = construct.ConstructKey;
        const newSampleConstruct = this.sampleService.createSampleConstruct({
            C_Material_key: materialKey,
            C_Construct_key: constructKey,
            Version: 0
        });

        // load job into breeze cache
        if (newSampleConstruct) {
            this.constructService.getConstruct(constructKey).then((data: any) => {
                newSampleConstruct.Construct = data;
            });
        }
    }

    removeSampleConstruct(sampleConstruct: any) {
        this.sampleService.deleteSampleConstruct(sampleConstruct);
    }

    searchConstructs = (term: string): Promise<any> => {
        return this.searchService.searchActiveConstructs(term).then((results: any) => {
            return results.data;
        });
    }

    fullNameFormatter = (value: any) => {
        return value.FullName;
    }
}

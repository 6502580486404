import {
    Component,
    Input,
    OnInit,
} from '@angular/core';

import {
    NgbActiveModal
} from '@ng-bootstrap/ng-bootstrap';
import { IFacet } from '../common/facet';
import { TaskService } from './task.service';
import { DialogService } from '@common/dialog/deprecated';
import { pluralize } from '@common/util/pluralize';

export enum TaskModalActionType {
    ApplyToAll = 'APPLY_TO_ALL',
    ApplyToTask = 'APPLY_TO_TASK',
}

export interface TaskModalAction {
    type: TaskModalActionType;
    task: any;
}

/**
 * Returns the selected Output belonging to a WorkflowTask.
 */
@Component({
    selector: 'task-modal',
    templateUrl: './task-modal.component.html'
})
export class TaskModalComponent implements OnInit {
    task: any;
    taskAlias: any;
    facet: IFacet;

    @Input() getTotalTasks: () => Promise<number>;

    constructor(
        private taskService: TaskService,
        private activeModal: NgbActiveModal,
        private dialogService: DialogService,
    ) {
        //
    }

    ngOnInit() {
        const fromTask = this.task;

        // Copy from current task to a newly created task
        const toTask = this.taskService.createTask();

        // Copy values
        this.taskService.copyTask(fromTask, toTask);

        toTask.IsHidden = true;
        toTask.C_ParentWorkflowTask_key = fromTask.C_WorkflowTask_key;
        toTask.TaskName = fromTask.TaskName;
        fromTask.Input.forEach((fromInput: any, index: number) => {
            toTask.Input[index].C_VocabularyClass_key = fromInput.C_VocabularyClass_key;
            toTask.Input[index].C_DosingTable_key = fromInput.C_DosingTable_key;
            toTask.Input[index].C_JobCharacteristicType_key = fromInput.C_JobCharacteristicType_key;
        });
        fromTask.Output.forEach((fromOutput: any, index: number) => {
            toTask.Output[index].C_InheritedFromOutput_key = fromOutput.C_InheritedFromOutput_key;
            toTask.Output[index].C_VocabularyClass_key = fromOutput.C_VocabularyClass_key;
            toTask.Output[index].FlagMaximum = fromOutput.FlagMaximum;
            toTask.Output[index].FlagMinimum = fromOutput.FlagMinimum;
            toTask.Output[index].AverageFlagMinimum = fromOutput.AverageFlagMinimum;
            toTask.Output[index].AverageFlagMaximum = fromOutput.AverageFlagMaximum;
            toTask.Output[index].MedianFlagMinimum = fromOutput.MedianFlagMinimum;
            toTask.Output[index].MedianFlagMaximum = fromOutput.MedianFlagMaximum;
            toTask.Output[index].StdDevFlagMinimum = fromOutput.StdDevFlagMinimum;
            toTask.Output[index].StdDevFlagMaximum = fromOutput.StdDevFlagMaximum;
            toTask.Output[index].HasCohortStatsFlag = fromOutput.HasCohortStatsFlag;
            toTask.Output[index].HasFlag = fromOutput.HasFlag;
        });

        // Set the current task to the newly created task
        this.task = toTask;
    }

    onCancel(): void {
        this.taskService.cancelWorkflowTask(this.task);
        this.activeModal.dismiss();
    }

    async onApplyToAll(task: any): Promise<void> {
        const totalTasks = await this.getTotalTasks();
        const isActionYes = await this.dialogService.confirmYesNo({
            title: 'Confirm apply to all',
            bodyText: `${totalTasks} task ${pluralize(totalTasks, 'instance')} will be affected`,
        });
        if (!isActionYes) {
            return;
        }

        const isContinue = await this.showEditingTaskRecordsDialog();
        if (!isContinue) {
            return;
        }

        this.activeModal.close({
            type: TaskModalActionType.ApplyToAll,
            task,
        });
    }

    async onApplyToTask(task: any): Promise<void> {
        const isContinue = await this.showEditingTaskRecordsDialog();
        if (!isContinue) {
            return;
        }

        this.activeModal.close({
            type: TaskModalActionType.ApplyToTask,
            task,
        });
    }

    private async showEditingTaskRecordsDialog() {
        return await this.dialogService.confirmYesNo({
            yesButtonTitle: 'Continue',
            noButtonTitle: 'Discard Changes',
            title: 'Editing Task Records',
            bodyText: 'This may take several minutes for the requested changes to be applied.\nDo you wish to save changes and continue?',
        });
    }
}

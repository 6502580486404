import {
    Component,
    Input,
    OnInit
} from '@angular/core';
import * as escapeStringRegexp from 'escape-string-regexp';

import { NoteService } from './note.service';

import {
    randomId
} from '../util';

/**
 * Displays a note log for an object detail.
 */
@Component({
    selector: 'note-log',
    templateUrl: './note-log.component.html',
    styleUrls: ['./note-log.component.scss'],
})
export class NoteLogComponent implements OnInit {
    /* The name of the parent object */
    @Input() objectName: string;
    /* The name of the parent object's type */
    @Input() objectType: string;
    /* The name of the object's primary key database field */
    @Input() pkName: string;
    /* The value of the object's primary key */
    @Input() pkValue: number;
    /* Is the parent object record new and unsaved? */
    @Input() isObjectNew = false;
    /* Is read only? */
    @Input() readonly: boolean;

    /* All notes */
    notes: any[];
    /* Notes, potentially filtered, that will be displayed */
    notesDisplay: any[];

    // State
    filterInputId: string;
    filterTerm: string;
    loading: boolean;
    newNoteTextInputId: string;
    newNoteTextExpanded: boolean;

    // New notes
    newNoteText: string;
    readonly MAX_NOTE_LENGTH = 10000;
    readonly CHARACTERS_REMAINING_WARN_THRESHOLD = 100;

    // Pagination
    paginationId: string;
    notePage: number;
    readonly ITEMS_PER_PAGE = 3;


    constructor(
        private noteService: NoteService
    ) { }

    ngOnInit() {
        this.initialize();
    }

    private initialize() {
        this.loading = false;

        const idSuffix = randomId();
        this.filterInputId = 'filterInput-' + idSuffix;
        this.newNoteTextInputId = 'newNoteText-' + idSuffix;
        this.paginationId = 'notes-' + idSuffix;

        this.filterTerm = '';

        this.initNewNote();
        this.initPagination();

        this.getNotes();
    }

    private initNewNote() {
        this.newNoteText = '';
        this.newNoteTextExpanded = false;
    }

    private initPagination() {
        this.notePage = 1;
    }

    private getNotes(): Promise<any> {
        this.notes = [];
        this.notesDisplay = [];

        if (this.pkName && this.pkValue) {
            this.loading = true;

            return this.noteService.getNotes(this.pkName, this.pkValue)
                .then((notes: any[]) => {
                    if (notes) {
                        this.notes = notes;
                    }

                    this.updateFilterList();
                    this.loading = false;
                })
                .catch((err: Error) => {
                    this.loading = false;

                    throw err;
                });
        }

        return Promise.resolve();
    }


    // Adding
    addNote(): Promise<any> {
        // Enforce length limit
        const noteTextLimited = this.newNoteText.substring(0, this.MAX_NOTE_LENGTH);

        const newNote = this.noteService.createNote(
            this.pkName,
            this.pkValue,
            noteTextLimited
        );

        this.notes.push(newNote);
        this.updateFilterList();

        // Save immediately
        return this.noteService.saveNotes().then((note: any) => {
            this.initNewNote();
        });
    }

    cancelNote() {
        this.initNewNote();
    }


    // New Note Controls
    onNewNoteTextFocus() {
        this.newNoteTextExpanded = true;
    }

    onNewNoteTextBlur() {
        // Contract only if the field is blank
        if (!this.newNoteText) {
            this.newNoteTextExpanded = false;
        }
    }

    getCharactersRemaining(): number {
        return this.MAX_NOTE_LENGTH - this.newNoteText.length;
    }

    showCharactersRemaining(): boolean {
        const charactersRemaining = this.getCharactersRemaining();

        return charactersRemaining <= this.CHARACTERS_REMAINING_WARN_THRESHOLD;
    }

    addButtonDisabled(): boolean {
        return (!this.newNoteText || this.newNoteText.length > this.MAX_NOTE_LENGTH);
    }


    // Filtering
    updateFilterList() {
        this.filterList(this.filterTerm);
    }

    filterList(term: string): void {
        if (term) {
            this.notesDisplay = this.notes.filter((note: any) => {
                return this.matchFilterTerm(note.NoteText, term);
            });
        } else {
            this.notesDisplay = this.notes.slice();
        }
    }

    private matchFilterTerm(textToSearch: string, query: string): boolean {
        const escapedQuery = escapeStringRegexp(query);
        return new RegExp(escapedQuery, 'gi').test(textToSearch);
    }
}

import type { CountResult } from '@services/models';
import type { cv_JobReport } from './cv-job-report.interface';
import type { cv_StandardPhraseCategory } from './cv-standard-phrase-category.interface';
import type { cv_StandardPhraseIACUCProtocol } from './cv-standard-phrase-iacuc-protocol.interface';
import type { cv_StandardPhraseJobSubtype } from './cv-standard-phrase-job-subtype.interface';
import type { cv_StandardPhraseJobType } from './cv-standard-phrase-job-type.interface';
import type { cv_StandardPhraseLinkType } from './cv-standard-phrase-link-type.interface';

export interface cv_StandardPhrase {
    C_StandardPhrase_key: number;
    StandardPhrase: string;
    StandardPhraseJSON: string;
    C_VocabularySource_key: number | null;
    IsActive: boolean;
    SortOrder: number | null;
    C_StandardPhraseCategory_key: number | null;
    C_StandardPhraseLinkType_key: number | null;
    C_JobReport_key: number | null;
    ElementID: string;
    IsDeprecated: boolean;
    C_Workgroup_key: number;
    CreatedBy: string;
    DateCreated: Date;
    ModifiedBy: string;
    DateModified: Date;
    Version: number;
    cv_StandardPhraseCategory: cv_StandardPhraseCategory;
    cv_StandardPhraseLinkType: cv_StandardPhraseLinkType;
    cv_JobReport: cv_JobReport;
    cv_StandardPhraseJobType: cv_StandardPhraseJobType[];
    cv_StandardPhraseJobSubtype: cv_StandardPhraseJobSubtype[];
    cv_StandardPhraseIACUCProtocol: cv_StandardPhraseIACUCProtocol[];
    JobType_keys?: number[];
    JobSubtype_keys?: number[];
    IACUCProtocol_keys?: number[];
    OriginalIACUCProtocol_keys?: number[];
    OriginalReport_key?: number;
    OriginalJobSubtype_keys?: number[];
    OriginalJobType_keys?: number[];
    IsUsed?: CountResult;
}

import { TaskTableModule } from './../tasks/task-table.module';
import { FacetSharedModule } from './../common/facet/facet-shared.module';
import { VocabulariesSharedModule } from './../vocabularies/vocabularies-shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { ClimbCommonModule } from '../common/climb-common.module';
import { ClimbServicesModule } from '../services/climb-services.module';
import { LinesSharedModule } from './lines-shared.module';
import { TasksSharedModule } from '../tasks/tasks-shared.module';
import { LocationsSharedModule } from '../locations/locations-shared.module';
import { WorkspacesSharedModule } from '../workspaces/workspaces-shared.module';

import {
    LineFilterComponent,
    LineFacetComponent,
    LineDetailComponent
} from '.';
import { SearchSharedModule } from '../search/search-shared.module';
import { UsersSharedModule } from '../user/users-shared.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgbModule,
        ClimbCommonModule,
        ClimbServicesModule,
        FacetSharedModule,
        LinesSharedModule,
        LocationsSharedModule,
        SearchSharedModule,
        TaskTableModule,
        TasksSharedModule,
        WorkspacesSharedModule,
        VocabulariesSharedModule,
        UsersSharedModule
    ],
    declarations: [
        LineDetailComponent,
        LineFilterComponent,
        LineFacetComponent
    ],
    exports: [
        LineFacetComponent
    ],
    providers: [
    ]
})
export class LinesModule { }

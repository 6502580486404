<div class="modal-header justify-content-center">
    <h4 class="modal-title">Scan</h4>
</div>

<div class="modal-body" style="vertical-align:top;">
    <div class="form-group text-danger" *ngIf="error">
        {{ error }}
    </div>
    <div class="form-group">
        <textarea name="censusScan"
                [(ngModel)]="scanValue"
                class="form-control">
        </textarea>
    </div>
</div>

<div class="modal-footer">
    <button type="button"
            class="btn btn-fw btn-lg btn-primary"
            (click)="okModal()"
            [disabled]="finding">
        <i class="fa fa-spinner fa-spin" title="Finding&hellip;"
           *ngIf="finding"></i>
        <span *ngIf="!finding">Find</span>
    </button>
    <button type="button"
            class="btn btn-fw btn-lg btn-secondary"
            (click)="cancelModal()"
            [disabled]="finding">
        Close
    </button>
</div>

<!-- Template for typeahead options -->
<ng-template #resultTemplate let-r="result">
    <div class="study-option">
        {{r.StudyName}}
    </div>
    <div class="additional-info small">
        Type: {{r.StudyType}} | Status: {{r.StudyStatus}}
    </div>
</ng-template>

<climb-typeahead [(model)]="model"
                    [resultTemplate]="resultTemplate"
                    [resultFormatter]="resultFormatter"
                    [keyFormatter]="keyFormatter"
                    [search]="searchStudies"
                    [keySearch]="searchStudiesByKey"
                    [id]="id"
                    [required]="required"
                    [disabled]="disabled"
                    [placeholder]="placeholder"
                    [namespace]="'study'"
                    (selectItem)="selectItemHandler($event)"
                    #typeahead></climb-typeahead>

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiResponse } from './models/api-response';
import { apiServiceBaseUriAdmin } from '../config/climb-web-settings';

@Injectable()
export class EmailService {

    private readonly API_URL = 'api/email/send';

    constructor(
        private httpClient: HttpClient,
    ) { }

    sendMessage(messagePayload: MessagePayload): Promise<ApiResponse> {
        const serviceUrl = apiServiceBaseUriAdmin + this.API_URL;

        return this.httpClient.post(serviceUrl, messagePayload).toPromise().then((response) => {
            return {
                data: response
            };
        });
    }
}

export interface MessagePayload {
    Recipient: string;
    Subject: string;
    HtmlContent: string;
    PlaintextContent: string;
}

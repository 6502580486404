import { Pipe, PipeTransform } from '@angular/core';
import { DateFormatterService } from '../util/date-time-formatting/date-formatter.service';

/*
* Return short formatted date, converted from UTC to local
*/
@Pipe({ name: 'formatShortDateTimeUtc' })
export class FormatShortDateTimeUtcPipe implements PipeTransform {
    constructor(private dateFormatterService: DateFormatterService) {

    }

    transform(value: any): string {
        return this.dateFormatterService.formatDateTimeUTC(value);
    }
}

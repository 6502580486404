import {DataManagerService} from './data-manager.service';
import {LoggingService} from './logging.service';
import {FeatureFlagService} from './feature-flags.service';
import {Entity as BreezeEntity} from 'breeze-client';
import {ReasonForChangeService} from '@common/reason-for-change/reason-for-change.service';
import {ToastrService} from '@services/toastr.service';
import {IGlpFacetStateServiceBulk} from '@services/interfaces/glp-facet-state-service.interface';
import {DeletionService} from '@services/deletion.service';
import { GlpOdataFacetSaveService } from './glp-odata-facet-save.service';


export abstract class GlpOdataFacetSaveServiceBulk extends GlpOdataFacetSaveService {
    protected constructor(
        protected dataManagerService: DataManagerService,
        protected deletionService: DeletionService,
        protected loggingService: LoggingService,
        protected featureFlagService: FeatureFlagService,
        protected reasonForChangeService: ReasonForChangeService,
        protected toastrService: ToastrService,
        protected facetStateService: IGlpFacetStateServiceBulk,
    ) {
        super(dataManagerService, deletionService, loggingService, featureFlagService, reasonForChangeService, toastrService, facetStateService)
    }

    async bulkSave(entities: BreezeEntity[], isAddOperation = false, saveSource: string = this.saveSource): Promise<void> {
        const entitiesWithRelated = [...this.facetStateService.getAllRelatedChangesBulk(entities)]

        super.bulkSave(entitiesWithRelated, isAddOperation, saveSource)
    }
}

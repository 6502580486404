import { FacetSharedModule } from './../common/facet/facet-shared.module';
import { EnumerationsSharedModule } from './../enumerations/enumerations-shared.module';
import { CharacteristicsSharedModule } from './characteristics-shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxPaginationModule } from "ngx-pagination";

import { DragulaModule } from 'ng2-dragula';

import { ClimbCommonModule } from '../common/climb-common.module';
import { ClimbServicesModule } from '../services/climb-services.module';
import { WorkspacesSharedModule } from '../workspaces/workspaces-shared.module';

import { CharacteristicsFacetComponent } from './characteristics-facet.component';
import { CharacteristicsFilterComponent } from './characteristics-filter/characteristics-filter.component';
import { CharacteristicTypeListComponent } from './characteristic-type-list/characteristic-type-list.component';

import { VocabulariesSharedModule } from '../vocabularies/vocabularies-shared.module';
import { JobsSharedModule } from '../jobs/jobs-shared.module';
import { TaxonCharacteristicEditComponent } from './characteristic-type-edit/taxon-characteristic-edit/taxon-characteristic-edit.component';
import { CharacteristicDataTypeComponent } from './characteristic-data-type/characteristic-data-type.component';
import { SampleCharacteristicEditComponent } from './characteristic-type-edit/sample-characteristic-edit/sample-characteristic-edit.component';
import { StudyCharacteristicEditComponent } from './characteristic-type-edit/study-characteristic-edit/study-characteristic-edit.component';
import { JobCharacteristicEditComponent } from './characteristic-type-edit/job-characteristic-edit/job-characteristic-edit.component';
import { CharacteristicLinksListPipe } from './pipes/characteristic-links-list.pipe';

@NgModule({
    imports: [
        DragulaModule,
        CommonModule,
        FormsModule,
        NgbModule,
        ClimbCommonModule,
        ClimbServicesModule,
        FacetSharedModule,
        CharacteristicsSharedModule,
        EnumerationsSharedModule,
        WorkspacesSharedModule,
        VocabulariesSharedModule,
        JobsSharedModule,
        NgxPaginationModule
    ],
    declarations: [
        CharacteristicsFacetComponent,
        CharacteristicsFilterComponent,
        CharacteristicLinksListPipe,
        CharacteristicTypeListComponent,
        TaxonCharacteristicEditComponent,
        SampleCharacteristicEditComponent,
        StudyCharacteristicEditComponent,
        JobCharacteristicEditComponent,

        CharacteristicDataTypeComponent
    ],
    exports: [
        // needed by ng1 or modals
        CharacteristicsFacetComponent,
        CharacteristicsFilterComponent
    ],
    providers: [
    ]
})
export class CharacteristicsModule { }

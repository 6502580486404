import { Injectable } from '@angular/core';
import {
    Predicate,
    QueryResult,
    EntityQuery,
    FilterQueryOp,
    EntityManagerOptions,
} from 'breeze-client';

import {
    notEmpty,
    randomInt,
    softCompare,
    uniqueArrayOnProperty
} from '@common/util';

import { DataManagerService } from '@services/data-manager.service';
import { QueryDef } from '@services/query-def';
import { BaseEntityService } from '@services/base-entity.service';
import { WebApiService } from '@services/web-api.service';
import { CountResult } from '@services/models';
import { FeatureFlagService } from '@services/feature-flags.service';
import { mergeWith, shuffle, mean } from '@lodash';
import { Cohort, CohortMaterial, Entity, Material } from '@common/types';
import { Selectable } from '@common/entitytable/entity-table.service';
import { CohortSplitTableOptions, CohortHousingOptions } from '../components/cohort-split-table/cohort-split-table-templates.component';
import { WorkspaceService } from '../../workspaces/workspace.service';
import { ColumnSelect, ColumnSelectLabel } from '@common/facet';
import { IFacet } from '@common/facet';
import { HttpParams } from '@angular/common/http';
import { ApiResponse } from '../../services/models/api-response';

export interface CohortSplitConfig {
    columnSelect: ColumnSelect;
    housingOptions: CohortHousingOptions;
}

export interface CohortGroup {
    count: string;
}

export interface IDraftCohorts {
    newCohortsChanges: string;
    excludedCohortChanges: string;
    selectedOutputsToRandomize: string[];
    groupsCount: { count: number }[];
}

export type ChangedCohortsData = { newCohorts: (Entity<Cohort> | Entity<CohortMaterial>)[], excludedCohorts: (Entity<Cohort> | Entity<CohortMaterial>)[] };

type ExtendedEntityManagerOptions = EntityManagerOptions & { entityGroupMap: { [key: string]: { entities: Entity<Cohort>[] | Entity<CohortMaterial>[] } } };
export type DraftCohortsChanges = (Entity<Cohort> | Entity<CohortMaterial> | CohortMaterial)[];

@Injectable()
export class CohortService extends BaseEntityService {

    draggedCohorts: any[] = [];

    draggedMaterials?: CohortMaterial[];
    draggedFromCohort?: Cohort;

    constructor(
        private dataManager: DataManagerService,
        private webApiService: WebApiService,
        private featureFlagService: FeatureFlagService,
        private workspaceService: WorkspaceService
    ) {
        super();
    }


    getCohorts(queryDef: QueryDef): Promise<QueryResult> {
        let query = this.buildDefaultQuery('Cohorts', queryDef);

        /*
         * Please declare expands clauses in calling function
         * instead of here
         */
        if (notEmpty(queryDef.expands)) {
            query = query.expand(queryDef.expands.join(','));
        }

        let predicates: Predicate[] = [];
        if (queryDef.filter) {
            predicates = predicates.concat(this.buildPredicates(queryDef.filter));
        }

        if (notEmpty(predicates)) {
            query = query.where(Predicate.and(predicates));
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed) as Promise<QueryResult>;
    }

    getCohort(cohortKey: number, expands?: string[]): Promise<any> {
        const queryDef = {
            expands,
            filter: {
                C_Cohort_key: cohortKey
            },
        };

        return this.getCohorts(queryDef).then((queryResults) => {
            return queryResults.results.length > 0 ? queryResults.results[0] : null;
        });
    }

    getCohortCounts(cohortKeys: number[]): Promise<CountResult[]> {
        return this.webApiService.callCountApi('api/counts/GetCohortCounts', cohortKeys);
    }

    buildPredicates(filter: any): Predicate[] {
        const predicates: Predicate[] = [];

        if (!filter) {
            return;
        }

        if (filter.C_Cohort_key) {
            predicates.push(Predicate.create('C_Cohort_key', '==', filter.C_Cohort_key));
        }

        if (filter.animalName) {
            predicates.push(Predicate.create(
                'CohortMaterial', FilterQueryOp.Any,
                'Material.Animal.AnimalName', FilterQueryOp.Contains, { value: filter.animalName },
            ));
        }
        if (notEmpty(filter.animalNames)) {
            predicates.push(Predicate.create(
                'CohortMaterial', 'any',
                'Material.Animal.AnimalName', 'in', filter.animalNames
            ));
        }

        if (filter.cohortName) {
            predicates.push(
                Predicate.create('CohortName', FilterQueryOp.Contains, { value: filter.cohortName })
            );
        }
        if (notEmpty(filter.cohortNames)) {
            predicates.push(
                Predicate.create('CohortName', 'in', filter.cohortNames)
            );
        }

        if (filter.createdBy) {
            predicates.push(Predicate.create('CreatedBy', 'eq', filter.createdBy));
        }
        if (notEmpty(filter.C_Study_key)) {
            const studyKeys = filter.C_Study_key.map((study: any) => {
                return study.StudyKey;
            });

            predicates.push(Predicate.create(
                'JobCohort', 'any',
                'Job.C_Study_key', 'in', studyKeys
            ));
        }
        if (notEmpty(filter.Methods)) {
            predicates.push(Predicate.create('cv_MatchingMethod.C_MatchingMethod_key', 'in', filter.Methods));
        }
        if (notEmpty(filter.jobs)) {
            const jobKeys = filter.jobs.map((job: any) => {
                return job.JobKey;
            });

            predicates.push(Predicate.create(
                'JobCohort', 'any',
                'C_Job_key', 'in', jobKeys
            ));
        }

        return predicates;
    }

    /**
     * Ensures all cohort.CohortMaterial are loaded
     * @param cohorts
     */
    ensureMaterialsExpanded(cohorts: any[]): Promise<void> {
        return this.dataManager.ensureRelationships(cohorts, ['CohortMaterial.Material.Animal']);
    }

    async ensureVisibleColumnsDataLoaded(cohorts: any[], visibleColumns: string[]): Promise<void> {
        const expands = this.generateExpandsFromVisibleColumns(cohorts[0], visibleColumns);
        return this.dataManager.ensureRelationships(cohorts, expands);
    }

    /**
     * create a Cohort entity
     */
    create(): any {
        return this.dataManager.createEntity('Cohort');
    }

    createCohortMaterial(initialValues: any): any {
        const manager = this.dataManager.getManager();
        const entityType = 'CohortMaterial';

        const initialCohortKey = initialValues.C_Cohort_key;
        const initialMaterialKey = initialValues.C_Material_key;

        // Check local entities for duplicates
        const cohortMaterials: any[] = this.getNonDeletedLocalEntities(manager, entityType);
        const duplicates = cohortMaterials.filter((cohortMaterial) => {
            return softCompare(cohortMaterial.C_Cohort_key, initialCohortKey) &&
                softCompare(cohortMaterial.C_Material_key, initialMaterialKey);
        });

        // Not a duplicate
        if (duplicates.length === 0) {
            return this.dataManager.createEntity(entityType, initialValues);
        }

        return null;
    }

    deleteCohort(cohort: any) {
        if (cohort.CohortMaterial) {
            while (cohort.CohortMaterial.length > 0) {
                this.dataManager.deleteEntity(cohort.CohortMaterial[0]);
            }
        }

        this.dataManager.deleteEntity(cohort);
    }

    deleteCohortMaterial(cohortMaterial: any) {
        this.dataManager.deleteEntity(cohortMaterial);
    }

    getNumberOfJobsForCohort(cohortKey: number): Promise<number> {
        const query = new EntityQuery('JobCohorts')
            .select('C_Job_key')
            .where('C_Cohort_key', 'eq', cohortKey);

        return this.dataManager.returnQueryCount(query);
    }

    /**
     * Refresh the Jobs.TaskJob collection for all jobs associated with the
     * Cohort.
     *
     * This is intended to be called after an Animal has been added/removed
     * from the cohort to update the entity cache and pick up entites create on
     * the back end.
     *
     * @param cohortKey Key of updated Cohort
     * @return number of Jobs associated with the Cohort
     */
    refreshCohortJobs(cohortKey: number): Promise<number> {
        const query = new EntityQuery('JobCohorts')
            .select('C_Job_key')
            .where('C_Cohort_key', 'eq', cohortKey);

        return this.dataManager.returnQueryResults(query).then((results) => {
            const jobKeys: number[] = results.map((result) => result.C_Job_key);

            if (jobKeys.length === 0) {
                return 0;
            }

            return Promise.all([
                this.dataManager.refreshEntityCollection(
                    'Job', 'TaskJob', jobKeys
                ),
                this.dataManager.refreshEntityCollection(
                    'Job', 'JobMaterial', jobKeys
                ),
            ]).then(() => {
                return jobKeys.length;
            });
        });
    }

    copyCohortWithSelectedMaterials(fromCohort: any, toCohort: any) {
        this._copyCohortDetails(fromCohort, toCohort);

        for (const cohortMaterial of fromCohort.CohortMaterial) {
            // Selected materials only
            if (cohortMaterial.isSelected) {
                const initialValues = {
                    C_Cohort_key: toCohort.C_Cohort_key,
                    C_Material_key: cohortMaterial.C_Material_key
                };
                this.createCohortMaterial(initialValues);
            }
        }
    }

    private _copyCohortDetails(fromCohort: any, toCohort: any) {
        // Start with blank name
        toCohort.CohortName = '';
        toCohort.Description = fromCohort.Description;
    }

    /**
     * 1. Create a number (numGroups) of new cohorts.
     * 2. Assign materialKeys randomly to each of the new cohorts.
     * 3. Each new cohort will not differ by 1 in the number of
     *    assigned materials.
     * @param numGroups - number of new cohorts to create
     * @param materialKeys
     * @param cohortMaterials
     */
    public createRandomCohorts(numGroups: number, materialKeys: number[], cohortMaterials: any[]): any[] {

        // make a copy to work with, since algorithm modifies array
        materialKeys = materialKeys.slice();

        // Make a dictionary mapping materialKey to outputValues
        const cohortMaterialOutputValues = {};
        for (const cohortMaterial of cohortMaterials) {
            cohortMaterialOutputValues[cohortMaterial.C_Material_key] = {
                outputValue1: cohortMaterial.OutputValue1,
                outputValue2: cohortMaterial.OutputValue2,
                outputValue3: cohortMaterial.OutputValue3
            };
        }

        const newCohorts: any[] = [];
        for (let i = 0; i < numGroups; i++) {
            newCohorts.push(
                this.create()
            );
        }

        let currentCohortIndex = 0;
        while (notEmpty(materialKeys)) {
            // pop 1 random material from the list
            const randomIndex = randomInt(0, materialKeys.length);
            const materialKey = materialKeys.splice(randomIndex, 1)[0];

            // assign (1 at a time) to next cohort
            const cohort = newCohorts[currentCohortIndex];
            this.createCohortMaterial({
                C_Cohort_key: cohort.C_Cohort_key,
                C_Material_key: materialKey,
                OutputValue1: cohortMaterialOutputValues[materialKey].outputValue1,
                OutputValue2: cohortMaterialOutputValues[materialKey].outputValue2,
                OutputValue3: cohortMaterialOutputValues[materialKey].outputValue3
            });

            currentCohortIndex += 1;
            if (currentCohortIndex >= newCohorts.length) {
                // loop back to zero to add next set of materials for each cohort
                currentCohortIndex = 0;
            }
        }

        return newCohorts;
    }

    /**
     * 1. Create a number (numGroups) of new cohorts.
     * 2. Assign materialKeys to each of the new cohorts using pair matching.
     * @param numGroups - number of new cohorts to create
     * @param categories - categories of pairs and selections
     * @param materialsForCohort - animals for cohort
     * @param cohortGroups - cohort group
     */
    public createPairMatchingCohorts(numGroups: number, categories: any[], materialsForCohort: any[], cohortGroups: any[]): any[] {
        // Make a dictionary mapping materialKey to outputValues
        const cohortMaterialOutputValues = {};
        for (const cohortMaterial of materialsForCohort) {
            cohortMaterialOutputValues[cohortMaterial.C_Material_key] = {
                outputValue1: cohortMaterial.OutputValue1,
                outputValue2: cohortMaterial.OutputValue2,
                outputValue3: cohortMaterial.OutputValue3
            };
        }

        const newCohorts: any[] = [];
        for (let i = 0; i < numGroups; i++) {
            newCohorts.push(
                this.create()
            );
        }

        const cohortKeys = newCohorts.map((cohort) => cohort.C_Cohort_key);
        // cohort groups with no animals set
        const emptyCohortKeys = cohortKeys.filter((_, index) => +cohortGroups[index].count < 1);
        const cohortMaterialMap = {};

        // set remaining field for each category so that groups with empty field will not get animals
        categories.forEach((category) => category.remaining = +category.selection);

        const categoriesWithSelection = shuffle(categories).filter((category) => category.selection > 0);

        const materialUsed: any = [];

        // create material map for cohort groups
        cohortGroups.forEach((cohortGroup, index) => {
            const count = +cohortGroup.count;
            if (count < 1) {
                return;
            }
            let cohortMaterialCount = 0;
            do {
                categoriesWithSelection.forEach((category) => {
                    if (category.remaining < 1 || cohortMaterialCount === count) {
                        return;
                    }
                    const materialKey = shuffle(category.animals)[0].C_Material_key;
                    if (materialUsed.indexOf(materialKey) > -1) {
                        return;
                    }
                    materialUsed.push(materialKey);
                    const cohort = cohortKeys[index];
                    if (!cohortMaterialMap.hasOwnProperty(category.name)) {
                        cohortMaterialMap[category.name] = {};
                    }
                    const cohortArray = cohortMaterialMap[category.name][cohort];
                    if (cohortArray) {
                        if (cohortArray.indexOf(materialKey) > -1) {
                            return;
                        }
                        cohortArray.push(materialKey);
                    } else {
                        cohortMaterialMap[category.name][cohort] = [materialKey];
                    }
                    category.remaining--;
                    cohortMaterialCount++;
                });
            } while (cohortMaterialCount < count);
        });

        if (emptyCohortKeys.length > 0) {
            categoriesWithSelection.forEach((category) => {
                if (category.remaining > 0) {
                    const materialKeys = uniqueArrayOnProperty(category.animals.slice(), 'C_Material_key').splice(0, category.remaining).map((item: any) => {
                        return item.C_Material_key;
                    });
                    const emptyCohortMap = this.assignCohortsPairMatching(materialKeys, emptyCohortKeys);
                    // merge group cohort map with empty cohort map
                    cohortMaterialMap[category.name] = {...(cohortMaterialMap[category.name] || {}), ...emptyCohortMap};
                }
            });
        }
        Object.keys(cohortMaterialMap).forEach((category) => {
            const categoryMap = cohortMaterialMap[category];
            Object.keys(categoryMap).forEach((cohort) => {
                const materials = categoryMap[cohort];
                materials.forEach((materialKey: any) => {
                    this.createCohortMaterial({
                        C_Cohort_key: cohort,
                        C_Material_key: materialKey,
                        OutputValue1: cohortMaterialOutputValues[materialKey].outputValue1,
                        OutputValue2: cohortMaterialOutputValues[materialKey].outputValue2,
                        OutputValue3: cohortMaterialOutputValues[materialKey].outputValue3
                    });
                });
            });
        });
        return newCohorts;
    }

    private assignCohortsPairMatching(materialKeys: number[], cohorts: any[]) {
        let cohortToMaterialMap = {};
        if (materialKeys.length < cohorts.length) {
            // Randomly add materials to cohorts
            while (notEmpty(materialKeys)) {
                // pop 1 random material from the list
                const randomIndex = randomInt(0, cohorts.length);
                const materialKey = materialKeys.splice(0, 1)[0];
                const cohort = cohorts[randomIndex];
                if (cohortToMaterialMap[cohort]) {
                    cohortToMaterialMap[cohort].push(materialKey);
                } else {
                    cohortToMaterialMap[cohort] = [materialKey];
                }
            }
            return cohortToMaterialMap;
        } else if (materialKeys.length === cohorts.length || materialKeys.length % cohorts.length === 0) {
            // Linearly add materials to cohorts
            const count = materialKeys.length / cohorts.length;
            const i = 0;
            let currentCohortIndex = 0;
            while (notEmpty(materialKeys)) {
                const materialsToCreate = materialKeys.splice(i, i + count);
                const cohort = cohorts[currentCohortIndex];
                currentCohortIndex += 1;
                if (cohortToMaterialMap[cohort]) {
                    cohortToMaterialMap[cohort] = cohortToMaterialMap[cohort].concat(materialsToCreate);
                } else {
                    cohortToMaterialMap[cohort] = materialsToCreate;
                }
            }
            return cohortToMaterialMap;
        } else {
            const firstGroupCohortToMaterialMap = {
                ...this.assignCohortsPairMatching(materialKeys.slice(0, cohorts.length), cohorts)
            };
            const secondGroupCohortToMaterialMap = {
                ...this.assignCohortsPairMatching(materialKeys.slice(cohorts.length, materialKeys.length), cohorts)
            };
            cohortToMaterialMap = mergeWith(firstGroupCohortToMaterialMap, secondGroupCohortToMaterialMap, (obj, src) => obj.concat(src));
        }
        return cohortToMaterialMap;
    }

    public createEnhancedDistributionCohorts(
        numGroups: number, 
        cohortGroups: CohortGroup[], 
        cohortMaterials: CohortMaterial[], 
        outputKeys: string[]
    ) {
        const newCohorts: Cohort[] = [];
        for (let i = 0; i < numGroups; i++) {
            newCohorts.push(this.create());
        }

        const excludedMaterials: CohortMaterial[] = cohortMaterials.filter(m => {
            for (const outputKey of outputKeys) {
                if (!m[outputKey]) {
                    return true;
                }
            }
            return false;
        });
        const includedMaterials: CohortMaterial[] = cohortMaterials.filter(m => {
            for (const outputKey of outputKeys) {
                if (!m[outputKey]) {
                    return false;
                }
            }
            return true;
        });

        if (includedMaterials.length) {
            const groups = this.calculateEnhancedDistribution(cohortGroups, includedMaterials, outputKeys);
            for (let i = 0; i < groups.length; i++) {
                /* eslint-disable-next-line */
                for (let t = 0; t < groups[i].length; t++) {
                    const index = includedMaterials.findIndex(cm => cm.C_Material_key === groups[i][t]?.C_Material_key);
                    if (index < 0) {
                        continue;
                    }
                    includedMaterials.splice(index, 1);
                    this.createCohortMaterial({
                        C_Cohort_key: newCohorts[i].C_Cohort_key,
                        C_Material_key: groups[i][t].C_Material_key,
                        OutputValue1: groups[i][t].OutputValue1,
                        OutputValue2: groups[i][t].OutputValue2,
                        OutputValue3: groups[i][t].OutputValue3
                    });
                }
            }
        }

        // Combine the unused included materials with the excluded materials
        for (const cm of includedMaterials) {
            excludedMaterials.push(cm);
        }

        let excludedCohort: Cohort;
        if (excludedMaterials.length > 0) {
            excludedCohort = this.create();
            for (const cm of excludedMaterials) {
                const cohortMaterial = this.createCohortMaterial({
                    C_Cohort_key: excludedCohort.C_Cohort_key,
                    C_Material_key: cm.C_Material_key,
                    OutputValue1: cm.OutputValue1,
                    OutputValue2: cm.OutputValue2,
                    OutputValue3: cm.OutputValue3
                });
                excludedCohort.CohortMaterial.push(cohortMaterial);
            }
        }

        return {
            newCohorts,
            excludedCohort
        };
    }

    /**
     * Calculates enhanced distribution algorithm:
     * - Sorts cohort materials by distance from mean
     * - Linearly allocates cohort materials to each cohort split
     * - During allocation, cohort splits are re-ordered so there is a randmoness factor
     * @param cohortGroups 
     * @param cohortMaterials 
     * @param outputKeys 
     * @returns 
     */
    private calculateEnhancedDistribution(cohortGroups: CohortGroup[], cohortMaterials: CohortMaterial[], outputKeys: string[]) {
        cohortMaterials = this.sortCohortMaterialsByDistanceFromMean(cohortMaterials, outputKeys);
        return this.createAndDistributeToCohortSplits(cohortGroups, cohortMaterials);
    }
    
    private sortCohortMaterialsByDistanceFromMean(cohortMaterials: CohortMaterial[], outputKeys: string[]) {
        // This is to create some randomness for numbers that are equally distant from the mean
        cohortMaterials = shuffle(cohortMaterials);

        const outputValueMap = {};
        cohortMaterials.forEach(cm => {
            const outputValues: number[] = [];
            for (const outputKey of outputKeys) {
                outputValues.push(parseFloat(cm[outputKey]));
            }
            outputValueMap[cm.C_CohortMaterial_key] = outputValues;
        });

        const outputMidpoint = this.calculateMidpoint(Object.values(outputValueMap));
        cohortMaterials.sort((a, b) => {
            const aDistance = this.calculateEuclideanDistance(outputValueMap[a.C_CohortMaterial_key], outputMidpoint);
            const bDistance = this.calculateEuclideanDistance(outputValueMap[b.C_CohortMaterial_key], outputMidpoint);
            return aDistance - bDistance;
        });

        return cohortMaterials;
    }

    private calculateMidpoint(numSets: number[][]) {
        const midpoint = [];
        let current = 0;
        while (current < numSets[0].length) {
            const valuesToBeCalculated: number[] = [];
            for (const nums of numSets) {
                valuesToBeCalculated.push(nums[current]);
            }
            midpoint.push(mean(valuesToBeCalculated));
            current++;
        }
        return midpoint;
    }

    private calculateEuclideanDistance(p1: number[], p2: number[]) {
        if (p1.length !== p2.length) {
            throw new Error("Input arrays are not the same size");
        }
        
        let sum = 0;
        for (let i = 0; i < p1.length; i ++) {
            sum += (p1[i] - p2[i]) ** 2;
        }

        return Math.sqrt(sum);
    }
    
    private createAndDistributeToCohortSplits(cohortGroups: CohortGroup[], cohortMaterials: CohortMaterial[]) {
        const cohortMaterialSplits: CohortMaterial[][] = cohortGroups.map(cg => new Array(parseInt(cg.count, 10)).fill(null));
        let cohortMaterialSplitsWithOpenSpace: CohortMaterial[][] = [...cohortMaterialSplits];
        const remainingCohortMaterials = [...cohortMaterials];
        let current = 0;
        while (cohortMaterialSplitsWithOpenSpace.length > 0) {
            if (!remainingCohortMaterials.length) {
                break;
            }

            cohortMaterialSplitsWithOpenSpace = shuffle(cohortMaterialSplitsWithOpenSpace);
            for (let i = cohortMaterialSplitsWithOpenSpace.length - 1; i >= 0; i--) {
                cohortMaterialSplitsWithOpenSpace[i][current] = remainingCohortMaterials.shift();
                if (cohortMaterialSplitsWithOpenSpace[i].every(cms => cms)) {
                    cohortMaterialSplitsWithOpenSpace.splice(i, 1);
                }
            }

            current++;
        }

        return cohortMaterialSplits;
    }

    areCohortsSafeToDelete(cohortKeys: number[]): Promise<boolean> {
        const query = EntityQuery.from('JobCohorts')
            .where('C_Cohort_key', 'in', cohortKeys);

        // safe if there are no JobCohorts
        return this.dataManager.returnQueryCount(query).then((count) => {
            return count === 0;
        });
    }

    /**
     * Cancel a newly added cohort record
     * @param cohort
     */
    cancelCohort(cohort: Entity<Cohort>): void {
        if (!cohort || !cohort.entityAspect.setDeleted) {
            return;
        }
        if (cohort.C_Cohort_key > 0) {
            this._cancelCohortEdits(cohort);
        } else {
            this._cancelNewCohort(cohort);
        }
    }

    private _cancelNewCohort(cohort: any) {
        try {
            this.deleteCohort(cohort);
        } catch (error) {
            console.error('Error cancelling cohort add: ' + error);
        }
    }

    private _cancelCohortEdits(cohort: any) {
        this.dataManager.rejectEntityAndRelatedPropertyChanges(cohort);
        this.dataManager.rejectChangesToEntityByFilter(
            'CohortMaterial', (item: any) => {
                return item.C_Cohort_key === cohort.C_Cohort_key;
            }
        );
    }

    getCohortSplittingMethodFlag(): boolean {
        const flag = this.featureFlagService.getFlag("CohortSplittingMethods");
        return flag && (flag.Value.toLowerCase() === 'true') && (flag.IsActive === true);
    }

    dragStart(fromCohort: Cohort, materialsToMove: CohortMaterial[]) {
        this.draggedFromCohort = fromCohort;
        this.draggedMaterials = materialsToMove;
    }

    dragStop() {
        this.draggedFromCohort = null;
        this.draggedMaterials = null;
    }

    handleDrop(toCohort: Cohort) {
        const fromCohort = this.draggedFromCohort;
        if (!fromCohort || fromCohort.C_Cohort_key === toCohort.C_Cohort_key) {
            return;
        }

        const cohortMaterials = this.draggedMaterials;
        if (!cohortMaterials || !cohortMaterials.length) {
            return;
        }

        for (const cm of cohortMaterials) {
            const index = fromCohort.CohortMaterial.findIndex(m => m.C_Material_key === cm.C_Material_key);
            fromCohort.CohortMaterial.splice(index, 1);
            toCohort.CohortMaterial.push(cm);
        }

        cohortMaterials.forEach((cm: CohortMaterial & Selectable) => cm.isSelected = false);
    }


    /**
     * The facet is set as a parameter rather than being saved in the state of this service
     * because this service is used as a singleton with potentially multiple facets accessing it.
     * This should only be used by components related to Cohort splitting.
     */
    async saveCohortSplitConfig(facet: IFacet, options: CohortSplitTableOptions) {
        const columnSelect: ColumnSelect = {
            model: options.columns.filter(c => c.visible).map(c => c.key),
            labels: options.columns.map(c => new ColumnSelectLabel(c.key, c.label))
        };

        const config: CohortSplitConfig = {
            columnSelect,
            housingOptions: options.housingOptions
        };

        facet.TaskGridConfiguration = JSON.stringify(config);
        await this.workspaceService.saveTaskGridConfiguration(facet);
    }

    parseCohortSplitConfig(facet: IFacet): CohortSplitConfig {
        try {
            if (facet && facet.TaskGridConfiguration) {
                return JSON.parse(facet.TaskGridConfiguration);
            }
        } catch (e) {
            console.error('Could not parse TaskGridConfiguration: ', e);
        }
    }

    public initDraftCohortsData(newCohortsChanges: string, excludedCohortChanges: string): { newCohorts: Entity<Cohort>[], excludedCohort: Entity<Cohort> } {
        let excludedCohort: Entity<Cohort>, excludedCohortMaterials: Entity<CohortMaterial>[];

        const parsedNewCohortsChanges: ExtendedEntityManagerOptions = JSON.parse(newCohortsChanges);
        const newCohorts = parsedNewCohortsChanges.entityGroupMap['Cohort:#CLIMB.Data'].entities as Entity<Cohort>[];
        const newCohortMaterialsData = parsedNewCohortsChanges.entityGroupMap['CohortMaterial:#CLIMB.Data'];
        const newCohortMaterials = ((newCohortMaterialsData && newCohortMaterialsData.entities) || []) as Entity<CohortMaterial>[];
        const manager = this.dataManager.getManager();

        if (excludedCohortChanges) {
            const parsedExcludedCohortsChanges: ExtendedEntityManagerOptions = JSON.parse(excludedCohortChanges);
            const excludedCohortMaterialsData = parsedExcludedCohortsChanges.entityGroupMap['CohortMaterial:#CLIMB.Data'];
            excludedCohort = parsedExcludedCohortsChanges.entityGroupMap['Cohort:#CLIMB.Data'].entities[0] as Entity<Cohort>;
            excludedCohortMaterials = ((excludedCohortMaterialsData && excludedCohortMaterialsData.entities) || []) as Entity<CohortMaterial>[];
        }

        for (const cohort of newCohorts) {
            const materials = newCohortMaterials.filter((item: CohortMaterial) => item.C_Cohort_key === cohort.C_Cohort_key);

            cohort.CohortMaterial = materials;

            for (const cohortMaterial of cohort.CohortMaterial) {
                const material = manager.getEntityByKey('Material', cohortMaterial.C_Material_key);
                cohortMaterial.Material = material as Entity<Material>;
                cohortMaterial.Cohort = cohort;
            }
        }

        if (excludedCohort) {
            excludedCohort.CohortMaterial = excludedCohortMaterials;

            for (const cohortMaterial of excludedCohort.CohortMaterial) {
                const material = manager.getEntityByKey('Material', cohortMaterial.C_Material_key) as Entity<Material>;
                cohortMaterial.Material = material;
                cohortMaterial.Cohort = excludedCohort;
            }
        }
        return { newCohorts, excludedCohort };
    }

    public createCohortEntitiesFromDraft(cohorts: Entity<Cohort>[], isExcludedCohort: boolean): DraftCohortsChanges {
        const newChanges = [];
        if (isExcludedCohort) {
            const excludedCohort = cohorts[0];
            for (const cohortMaterial of cohorts[0].CohortMaterial) {
                const initialValues = {
                    C_Cohort_key: cohortMaterial.C_Cohort_key,
                    C_CohortMaterial_key: cohortMaterial.C_CohortMaterial_key,
                    Material: cohortMaterial.Material,
                    OutputValue1: cohortMaterial.OutputValue1,
                    OutputValue2: cohortMaterial.OutputValue2,
                    OutputValue3: cohortMaterial.OutputValue3,
                };
                const entity: Entity<CohortMaterial> = this.dataManager.createEntity('CohortMaterial', initialValues);
                newChanges.push(entity);
            }
            const cohort: Entity<Cohort> = this.dataManager.createEntity('Cohort', { C_Cohort_key: excludedCohort.C_Cohort_key, CohortName: excludedCohort.CohortName });
            newChanges.push(cohort);
            return newChanges;
        }

        for (const cohort of cohorts) {
            const init = {
                C_Cohort_key: cohort.C_Cohort_key,
                CohortName: cohort.CohortName,
                Output1: cohort.Output1,
                Output2: cohort.Output2,
                Output3: cohort.Output3,
            }
            const newCohort: Entity<Cohort> = this.dataManager.createEntity('Cohort', init);
            newChanges.push(newCohort);
            for (const cohortMaterial of cohort.CohortMaterial) {
                const initialValues = {
                    C_CohortMaterial_key: cohortMaterial.C_CohortMaterial_key,
                    Cohort: newCohort,
                    Material: cohortMaterial.Material,
                    OutputValue1: cohortMaterial.OutputValue1,
                    OutputValue2: cohortMaterial.OutputValue2,
                    OutputValue3: cohortMaterial.OutputValue3,
                };
                const newCohortMaterial: Entity<CohortMaterial> = this.dataManager.createEntity('CohortMaterial', initialValues);
                newCohortMaterial.C_Cohort_key = newCohort.C_Cohort_key;
                newChanges.push(newCohortMaterial);
            }
        }
        return newChanges;
    }

    public cancelConfirmCohortChanges(changes: DraftCohortsChanges): void {
        for (const change of changes) {
            if ('C_Cohort_key' in change || 'C_CohortMaterial_key' in change) {
                this.dataManager.detachEntity(change);
            }
        }
    }

    public async getDraftCohortsString(cohort: Cohort): Promise<string> {
        let params = new HttpParams();
        params = params.set('cohortKey', cohort.C_Cohort_key.toString());
        // Remove 'text' to get deserialized JSON
        const response = await this.webApiService.callApi('api/draftCohorts', params, 'text');
        return response?.data;
    }

    public saveDraftCohortsString(cohort: Cohort, draftCohorts: string): Promise<ApiResponse> {
        return this.webApiService.postApi(`api/draftCohorts?cohortKey=${cohort.C_Cohort_key}`, draftCohorts, 'text', true, false);
    }
}

import { FacetLoadingStateService } from '@common/facet/facet-loading-state.service';
import { MatingService } from '../../../matings/mating.service';
import { VocabularyService } from '../../../vocabularies/vocabulary.service';
import {
    Component,
    Input,
    OnInit,
    OnChanges
} from '@angular/core';
import { LocationService } from '../../../locations/location.service';


@Component({
    selector: 'workflow-birth-details',
    templateUrl: './workflow-birth-details.component.html',
})
export class WorkflowBirthDetailsComponent implements OnInit, OnChanges {
    @Input() birth: any;
    @Input() useTableFormat: boolean;

    constructor(
        private facetLoadingStateService: FacetLoadingStateService,
        private locationService: LocationService,
        private matingService: MatingService,
        private vocabularyService: VocabularyService
    ) { }

    ngOnInit() {
        this.initialize();
    }

    ngOnChanges(changes: any) {
        if (changes.birth && !changes.birth.firstChange) {
            this.getBirthDetails();
        }
    }
    
    initialize(): Promise<any> {
        this.facetLoadingStateService.changeLoadingState(true);
        return this.getBirthDetails().then(() => {
            return this.getCVs();
        }).then(() => {
            this.facetLoadingStateService.changeLoadingState(false);
        }).catch((error: Error) => {
            this.facetLoadingStateService.changeLoadingState(false);
            throw error;
        });
    }

    getCVs(): Promise<any> {
        // // Ensure CVs loaded for samples in job-task details
        const p1 = this.vocabularyService.ensureCVLoaded('cv_BirthStatuses');

        return Promise.all([p1]);
    }

    getBirthDetails(): Promise<any> {
        if (!this.birth) {
            return Promise.resolve();
        }
        
        const expands = [
            'MaterialPool',
            'Line'
        ];
        return this.matingService.getMating(this.birth.C_MaterialPool_key, expands).then(() => {
            if (this.birth.Mating) {
                return this.locationService.getMaterialPoolLocationPath(
                    this.birth.Mating.C_MaterialPool_key
                ).then((path) => {
                    this.birth.Mating.location = path;
                    this.birth.generationKey = this.birth.Mating.C_Generation_key;
                });
            }
        });
    }
}

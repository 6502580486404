<ng-template #editMode>
    <input #inputField
        #inputState="ngModel"
        validateUnique
        [uniqueEntity]="uniqueEntity"
        [uniqueProperty]="uniqueProperty"
        [(ngModel)]="model"
        (ngModelChange)="onModelChange()"
        (blur)="stopEditing();"
        (keyup.enter)="stopEditing();"
        (keyup.escape)="cancelEditing();">
    <small *ngIf="inputState?.errors?.unique" class="form-text text-muted">
        <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
        This name already exists.
    </small>
</ng-template>

<ng-container *ngIf="!isEditing; else editMode">
    <span (click)="startEditing()"
        class="editable"
        [ngStyle]="!isEditable ? {'background-color': '#EEEEEE'} : index % 2 ? {'background-color': '#CAD6EC'} : {'background-color': '#E4EFE1'}"
        [ngClass]="inputState?.hasError('unique') ? 'has-error' : ''">
        {{ model }}  <i *ngIf="isEditable" class="fa fa-pencil"></i>
    </span>
</ng-container>
import { ChangeDetectionStrategy, Component, OnInit, } from '@angular/core';
import { Workspace } from '@common/types/models/workspace.interface';
import { WorkspaceService } from '../../../workspaces/workspace.service';

@Component({
    selector: 'climb-workspace-name-input',
    templateUrl: './workspace-name-input.component.html',
    styleUrls: ['./workspace-name-input.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WorkspaceNameInputComponent implements OnInit {
    currentWorkspace: Workspace;

    constructor(private workspaceService: WorkspaceService) {}

    ngOnInit(): void {
        this.currentWorkspace = this.workspaceService.currentWorkspace;
    }

    get currentWorkspaceNameLength() {
        return this.currentWorkspace?.WorkspaceName?.length;
    }
}

<ng-template #idTmpl let-materialPool="item">
    <input type="text"
        name="materialPoolID"
        [(ngModel)]="materialPool.MaterialPoolID"
        required
        class="form-control input-medium">
</ng-template>

<ng-template #typeTmpl let-materialPool="item">
    <active-vocab-select [(model)]="materialPool.C_MaterialPoolType_key"
        [vocabChoices]="materialPoolTypes"
        [keyFormatter]="materialPoolTypeKeyFormatter"
        [optionFormatter]="materialPoolTypeFormatter">
    </active-vocab-select>
</ng-template>

<ng-template #statusTmpl let-materialPool="item">
    <active-vocab-select [(model)]="materialPool.C_MaterialPoolStatus_key"
                         [vocabChoices]="materialPoolStatuses"
                         [keyFormatter]="materialPoolStatusKeyFormatter"
                         [optionFormatter]="materialPoolStatusFormatter"
                         [required]="requiredFields.includes('C_MaterialPoolStatus_key')"
                         [nullable]="!requiredFields.includes('C_MaterialPoolStatus_key')">
    </active-vocab-select>
</ng-template>

<ng-template #datePooledTmpl let-materialPool="item">
    <climb-ngb-date #dateControl="ngModel"
                    name="Date Pooled"
                    [(ngModel)]="materialPool.DatePooled"
                    [required]="requiredFields.includes('DatePooled')"></climb-ngb-date>
</ng-template>

<ng-template #ownerTmpl let-materialPool="item">
    <user-select [(model)]="materialPool.Owner"
        [forceValidSelection]="requiredFields.includes('Owner')"
        [required]="requiredFields.includes('Owner')"></user-select>
</ng-template>

<ng-template #locationTmpl let-materialPool="item" let-section="section">
    <!-- readonly location for input cells -->
    <ng-container *ngIf="section === BulkEditSection.InputCell">
        <div class="form-control-plaintext"
            *ngFor="let current of materialPool.MaterialLocation | currentMaterialLocation">
            <location-path [locationPositionKey]="current.C_LocationPosition_key"></location-path>
        </div>
    </ng-container>
    <!-- editable single location in edit header and add screen -->
    <ng-container *ngIf="section !== BulkEditSection.InputCell">
        <location-chooser [(location)]="materialPool.locationPosition"></location-chooser>
    </ng-container>
</ng-template>

<ng-template #containerTypeTmpl let-materialPool="item">
    <active-vocab-select [(model)]="materialPool.C_ContainerType_key"
                         [vocabChoices]="containerTypes"
                         [keyFormatter]="containerTypeKeyFormatter"
                         [optionFormatter]="containerTypeFormatter"
                         [required]="requiredFields.includes('C_ContainerType_key')"
                         [nullable]="!requiredFields.includes('C_ContainerType_key')"></active-vocab-select>
</ng-template>

<ng-template #commentsTmpl let-materialPool="item">
    <input class="form-control input-medium"
           type="text"
           [(ngModel)]="materialPool.Comments"
           name="comments"
           [required]="requiredFields.includes('Comments')">
</ng-template>

import { isEmpty } from '@lodash';
import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';


type Predicate = (value: any, matchingValue: any) => boolean;
const isEqual: Predicate = (value, matchingValue) => value === matchingValue;
const pathToString = (path: string | (string | number)[]): string => Array.isArray(path) ? path.join('.') : path;

const clearError = (control: AbstractControl, errorCode: string) => {
    if (!control.hasError(errorCode)) {
        return;
    }
    const errors = control.errors;
    delete errors[errorCode];
    control.setErrors(isEmpty(errors) ? null : errors);
};

type ValidatorOptions = {
    controlShowError: boolean;
    matchingControlShowError: boolean;
    errorCode: string; // The code of the error to identify the validator's error.
    predicate: Predicate; // The function to customize comparisons. By default, the equivalence of two values.
};
const validatorDefaultOptions: ValidatorOptions = {
    controlShowError: true,
    matchingControlShowError: true,
    errorCode: 'match',
    predicate: isEqual,
};

/**
 * Performs a comparison between two values of different form controls
 * to determine their compatibility by a given predicate function
 * which is invoked to compare values.
 *
 * @param controlPath - The first form control name or path to compare.
 * @param matchingControlPath - The second form control name or path to compare.
 * @param options - Validator options.
 */
export function matchValidator(
    controlPath: string | (string | number)[],
    matchingControlPath: string | (string | number)[],
    options: Partial<ValidatorOptions> = validatorDefaultOptions,
): ValidatorFn {
    return (formGroup: FormGroup): ValidationErrors | null => {
        const control = formGroup.get(controlPath);
        const matchingControl = formGroup.get(matchingControlPath);
        const config: ValidatorOptions = {
            ...validatorDefaultOptions,
            ...options,
        };

        const isError = control
            && matchingControl
            && !config.predicate(control.value, matchingControl.value);

        if (isError) {
            const controlName = pathToString(controlPath);
            const matchingControlName = pathToString(matchingControlPath);
            const errorData = {
                [controlName]: control.value,
                [matchingControlName]: matchingControl.value,
            };

            if (config.controlShowError) {
                control.setErrors({
                    ...(control.errors ?? {}),
                    [config.errorCode]: errorData,
                });
            }
            if (config.matchingControlShowError) {
                matchingControl.setErrors({
                    ...(matchingControl.errors ?? {}),
                    [config.errorCode]: errorData,
                });
            }

            return { [config.errorCode]: errorData };
        }

        clearError(control, config.errorCode);
        clearError(matchingControl, config.errorCode);

        return null;
    };
}

/**
 * Since the matching validation is carried out not in the field itself,
 * but at the field group level, due to the peculiarities of the work
 * of the Angular form validator, there may be a problem with the disappearance
 * of the field matching error. The problem becomes especially relevant
 * when an animated error message is displayed. Briefly deleting and adding
 * the error to a field constantly triggers the animation, which can annoy the user.
 * To keep the matching error at the field level until it is completely
 * resolved, use the keepErrorValidator.
 *
 * @example```
 *   formData: FormGroup = this.fb.group({
 *     Password: ['', [
 *       Validators.required,
 *     ]],
 *     PasswordConfirm: ['', [
 *       Validators.required,
 *       keepErrorValidator(),
 *     ]],
 *   }, {
 *     validators: [matchValidator('Password', 'PasswordConfirm')],
 *   });
 * ```
 *
 * @param errorCode - The code used in the matchValidator to identify the validator's error.
 */
export function keepErrorValidator(
    errorCode = 'match',
): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        if (!control.errors) {
            return null;
        }

        const errorData = control.errors[errorCode];

        return errorData
            ? { [errorCode]: errorData }
            : null;
    };
}

import { StoreWritable, EventCallable, createEvent, Subscription as EffectorSubscription } from 'effector';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

export interface Store<
  T = unknown,
  E extends Record<string, EventCallable<any>> = Record<string, EventCallable<any>>
> {
  // Don't use __store value. Only for debug purpose
  __store: StoreWritable<T>;
  events: E;
  getValue$: () => Observable<T>
  getValue: () => T;
}

export interface MFEvent<T> {
  readonly event: EventCallable<T>;

  emit(data: T): void;
  getValue(): Observable<T>;
}


export class Event<T> implements MFEvent<T> {
  readonly event = createEvent<T>()

  emit(data: T) {
    this.event(data);
  }

  getValue() {
    let subs: EffectorSubscription;
    return new Observable<T>(subscribe => {
      subs = this.event.watch(data => {
        subscribe.next(data);
      });
    }).pipe(finalize(() => subs()));
  }
}

import { GenotypeBulkAddComponent } from './bulkedit/genotype-bulk-add.component';
import { GenotypeBulkEditComponent } from './bulkedit/genotype-bulk-edit.component';
import { GenotypeBulkTemplatesComponent } from './bulkedit/genotype-bulk-templates.component';
import { FacetSharedModule } from './../common/facet/facet-shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { ClimbCommonModule } from '../common/climb-common.module';
import { ClimbServicesModule } from '../services/climb-services.module';

import { AnimalsSharedModule } from '../animals/animals-shared.module';
import { GenotypesSharedModule } from './genotypes-shared.module';
import { LinesSharedModule } from '../lines/lines-shared.module';
import { PlatesSharedModule } from '../plates/plates-shared.module';
import { WorkspacesSharedModule } from '../workspaces/workspaces-shared.module';

import { GenotypeFacetComponent } from './genotype-facet.component';
import { GenotypeFilterComponent } from './genotype-filter.component';
import { VocabulariesSharedModule } from '../vocabularies/vocabularies-shared.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgbModule,
        ClimbCommonModule,
        ClimbServicesModule,
        FacetSharedModule,
        AnimalsSharedModule,
        GenotypesSharedModule,
        LinesSharedModule,
        PlatesSharedModule,
        WorkspacesSharedModule,
        VocabulariesSharedModule
    ],
    declarations: [
        GenotypeBulkAddComponent,
        GenotypeBulkEditComponent,
        GenotypeBulkTemplatesComponent,
        GenotypeFacetComponent,
        GenotypeFilterComponent
    ],
    exports: [
        GenotypeFacetComponent,
    ],
    providers: [
    ]
})
export class GenotypesModule { }

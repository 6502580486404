import { Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';


@Injectable()
export class HttpCancelService {
    private cancelPendingRequests$: Subject<void> = new Subject<void>();

    /** Cancels all pending Http requests. */
    cancelPendingRequests(): void {
        this.cancelPendingRequests$.next();
    }

    onCancelPendingRequests(): Observable<void> {
        return this.cancelPendingRequests$.asObservable();
    }
}

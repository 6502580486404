import { AppInsightsService } from './services/app-insights.service';
import { 
    ErrorHandler, 
    Injectable, 
} from '@angular/core';


@Injectable()
export class GlobalErrorHandler extends ErrorHandler {

    constructor(
        private appInsightsService: AppInsightsService
    ) { 
        super();
    }

    handleError(error: Error) {
        super.handleError(error);
        this.appInsightsService.trackException(error);  
    }
  
}

import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild, ViewChildren } from "@angular/core";
import { AnimalService } from "../../animals/services/animal.service";
import { CopyBufferService } from "../../common/services/copy-buffer.service";
import { DroppableEvent } from "../../common/droppable-event";
import { BulkAddComponent, BulkAddResult, FacetLoadingStateService } from "../../common/facet";
import { BulkAddCommService } from "../../common/facet/bulk-add-comm.service";
import { notEmpty, uniqueArrayOnProperty } from "../../common/util";
import { ResourceService } from "../../resources";
import { DataContextService } from "../../services/data-context.service";
import { ClinicalVocabService } from "../clinical-vocab.service";
import { ClinicalService } from "../clinical.service";
import { TreatmentPlanBulkTemplatesComponent } from "./treatment-plan-bulk-templates.component";
import { LoggingService } from "@services/logging.service";
import { NgModel } from "@angular/forms";
import { dateControlValidator } from "@common/util/date-control.validator";

@Component({
    selector: 'treatment-plan-bulk-add',
    templateUrl: './treatment-plan-bulk-add.component.html',
    styles: [`
        h5 {
            font-weight: bold;
        }
        .dropzone {
            min-width: 300px;
            max-height: 160px;
            overflow-y: scroll;
        }
    `],
    providers: [
        BulkAddCommService
    ]
})
export class TreatmentPlanBulkAddComponent implements OnInit, AfterViewInit {
    @Input() facet: any;
    @Output() exit: EventEmitter<BulkAddResult> = new EventEmitter<BulkAddResult>();

    @ViewChild('itemsToAddTmpl') itemsToAddTmpl: TemplateRef<any>;
    @ViewChild('bulkAdd') bulkAdd: BulkAddComponent;
    @ViewChild('bulkTemplates') bulkTemplates: TreatmentPlanBulkTemplatesComponent;
    @ViewChildren('dateControl') dateControls: NgModel[];

    sourceMaterials: any[] = [];
    taskStatuses: any[];
    taskTypes: any[];
    resources: any[];
    tasks: any[] = [];
    plans: string[] = [];

    private taskID = 0;
    private taskDefaultStatus: any = null;

    constructor(
        private dataContext: DataContextService,
        private animalService: AnimalService,
        private clinicalService: ClinicalService,
        private resourceService: ResourceService,
        private copyBufferService: CopyBufferService,
        private facetLoadingState: FacetLoadingStateService,
        private bulkAddCommService: BulkAddCommService,
        private clinicalVocabService: ClinicalVocabService,
        private loggingService: LoggingService,
    ) { }

    ngAfterViewInit(): void {
        if (this.bulkTemplates) {
            this.bulkTemplates.bulkOptions.itemsToAddTemplate = this.itemsToAddTmpl;
        }
    }

    ngOnInit(): void {
        this.facetLoadingState.changeLoadingState(true);

        Promise.all([
            this.getCVs()
        ]).then(() => {
            this.facetLoadingState.changeLoadingState(false);
        }).catch((error) => {
            this.facetLoadingState.changeLoadingState(false);
            throw error;
        });
    }

    onDropSourceMaterials(event: DroppableEvent) {
        if (notEmpty(this.animalService.draggedAnimals)) {
            const draggedAnimals = this.animalService.draggedAnimals;
            this.addSourceMaterials(draggedAnimals);
            this.animalService.draggedAnimals = [];
        }
    }

    pasteSourceMaterials() {
        if (this.copyBufferService.hasAnimals()) {
            const pastedAnimals = this.copyBufferService.paste();
            this.addSourceMaterials(pastedAnimals);
        }
    }

    addSourceMaterials(newSourceMaterials: any[]) {
        newSourceMaterials = newSourceMaterials.slice();
        newSourceMaterials = this.sourceMaterials.concat(newSourceMaterials);
        this.sourceMaterials = uniqueArrayOnProperty(newSourceMaterials, 'C_Material_key');

        this.syncItemsToAdd();
    }

    /** Event handler for selecting animals in animal-multiselect component */
    onSelectSourceMaterials() {
        this.sourceMaterials = uniqueArrayOnProperty(this.sourceMaterials, 'C_Material_key');
        this.syncItemsToAdd();
    }

    /**
     * Set the numberItemsToAdd on BulkAddComponent.
     * It should be in sync with the sourceMaterials list
     */
    syncItemsToAdd() {
        if (!notEmpty(this.sourceMaterials) ||
            !this.bulkAdd
        ) {
            return;
        }

        this.bulkAdd.addState.numberItemsToAdd = this.sourceMaterials.length;
    }

    async saveClicked(result: BulkAddResult) {
        const errMessage = dateControlValidator(this.dateControls) ?? this.bulkTemplates.validate();
        if (errMessage) {
            this.loggingService.logError(errMessage, null, '', true);
            return;
        }

        this.facetLoadingState.changeLoadingState(true);

        this
            .replaceSearchObjectsWithBreezeEntities()
            .then(() => {
                return this.animalService.statusBulkChangePostProcess(this.sourceMaterials, result.initialValues.C_AnimalStatus_key);
            })
            .then(() => {
                return this.createNewTasks(result);
            })
            .then(() => {
                return this.dataContext.save();
            })
            .then(() => {
                if (result.clearForm) {
                    this.tasks = [];
                    this.sourceMaterials = [];
                    this.bulkAdd.addState.numberItemsToAdd = 1;
                }
                this.facetLoadingState.changeLoadingState(false);
                this.bulkAddCommService.saveComplete();
            })
            .catch((error) => {
                this.facetLoadingState.changeLoadingState(false);
                this.bulkAddCommService.saveCanceled();
                throw error;
            });
    }

    exitClicked(result: BulkAddResult) {
        this.exit.emit(result);
    }

    addTask(): void {
        this.tasks.push({
            tempID: ++this.taskID,
            DateDue: new Date(),
            C_TaskStatus_key: this.taskDefaultStatus
        });
        this.plans.push("");
    }

    removeTask(task: any, index: number): void {
        this.tasks = this.tasks.filter((x) => x.tempID !== task.tempID);
        this.plans.splice(index, 1);
    }

    private async createNewTasks(result: BulkAddResult): Promise<any> {
        const tasksPromises = [];
        const workflowTaskKey = this.taskTypes[0].C_WorkflowTask_key;
        const taskAlias = this.taskTypes[0].TaskName;

        // Make sure all AnimalHealthRecord are loaded
        const animalIdsWithNoHealthRecords = this.sourceMaterials.filter(animal => !animal.AnimalHealthRecord)
            .map(animal => animal.C_Material_key);

        await this.clinicalService.loadHealthRecords(animalIdsWithNoHealthRecords);

        for (const animal of this.sourceMaterials) {
            if (!animal.AnimalHealthRecord) {
                animal.AnimalHealthRecord = await this.clinicalService.createHealthRecord({
                    C_Material_key: animal.C_Material_key,
                    C_Resource_key: result.initialValues.C_Resource_key
                });
            } else {
                animal.AnimalHealthRecord.C_Resource_key = result.initialValues.C_Resource_key;
            }

            if (result.initialValues.C_AnimalStatus_key) {
                animal.C_AnimalStatus_key = result.initialValues.C_AnimalStatus_key;
            }

            for (let i = 0; i < this.tasks.length; i++) {
                const task = this.tasks[i];
                const p = this.clinicalService.createHealthRecordTask(animal.C_Material_key, {
                    C_WorkflowTask_key: workflowTaskKey,
                    C_TaskStatus_key: task.C_TaskStatus_key ? +task.C_TaskStatus_key : null,
                    C_AssignedTo_key: task.C_AssignedTo_key ? +task.C_AssignedTo_key : null,
                    C_CompletedBy_key: task.C_CompletedBy_key ? +task.C_CompletedBy_key : null,
                    TaskAlias: taskAlias,
                    DateDue: task.DateDue || null,
                    DateComplete: task.DateComplete || null,
                    Comments: task.Comments || null
                }, this.plans[i]);
                tasksPromises.push(p);
            }
        }

        return Promise.all(tasksPromises);
    }

    private replaceSearchObjectsWithBreezeEntities(): Promise<any> {
        const promises: Promise<any>[] = [];

        const length = this.sourceMaterials.length;
        for (const sourceMaterial of this.sourceMaterials) {
            if (sourceMaterial.entityAspect) {
                continue;
            }

            // swap search object for breeze entity
            const materialKey = sourceMaterial.C_Material_key;
            const p = this.animalService.getAnimal(materialKey).then((breezeAnimal) => {
                for (let i = 0; i < length; i++) {
                    if (this.sourceMaterials[i].C_Material_key === materialKey) {
                        this.sourceMaterials[i] = breezeAnimal;
                        break;
                    }
                }
            });
            promises.push(p);
        }

        return Promise.all(promises);
    }

    private getCVs(): Promise<any> {
        return Promise.all([
            this.clinicalVocabService.taskStatuses$.toPromise().then((taskStatuses) => {
                this.taskStatuses = taskStatuses;

                const defaultStatuses = taskStatuses.filter((status) => {
                    return status.IsDefault;
                });
                this.taskDefaultStatus = defaultStatuses.length > 0
                    ? defaultStatuses[0].C_TaskStatus_key
                    : null;
            }),
            this.clinicalVocabService.taskTypes$.toPromise().then((taskTypes) => {
                this.taskTypes = taskTypes;
            }),
            this.resourceService.getAllResources().then((resources) => {
                this.resources = resources;
            })
        ]);
    }

    // <select> formatters
    resourceKeyFormatter = (value: any) => {
        return value.C_Resource_key;
    }
    resourceNameFormatter = (value: any) => {
        return value.ResourceName;
    }
    taskStatusKeyFormatter = (value: any) => {
        return value.C_TaskStatus_key;
    }
    taskStatusFormatter = (value: any) => {
        return value.TaskStatus;
    }
}

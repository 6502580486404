<form novalidate>

    <div class="modal-header justify-content-center">
        <h4 class="modal-title">
            Files
        </h4>
    </div>

    <div class="modal-body">
        <facet-detail-file-upload [facetPrivilege]="facetPrivilege"
                                  [pkName]="pkName"
                                  [pkValue]="pkValue"></facet-detail-file-upload>
    </div>

    <div class="modal-footer">
        <button type="button"
                class="btn btn-lg btn-secondary"
                (click)="onClose()">
            Close
        </button>
    </div>

</form>

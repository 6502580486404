import type { CalculatedOutputExpression } from './calculated-output-expression.interface';
import type { Cohort } from './cohort.interface';
import type { EnumerationClass } from './enumeration-class.interface';
import type { ExpressionOutputMapping } from './expression-output-mapping.interface';
import type { OutputDefault } from './output-default.interface';
import type { OutputFlag } from './output-flag.interface';
import type { TaskOutput } from './task-output.interface';
import type { WorkflowTask } from './workflow-task.interface';
import type { cv_DataType } from './cv-data-type.interface';
import type { cv_InputOutputUnit } from './cv-input-output-unit.interface';

export interface Output {
    Abbreviation: string;
    AverageFlagMaximum: number | null;
    AverageFlagMinimum: number | null;
    C_DataType_key: number;
    C_EnumerationClass_key: number | null;
    C_InheritedFromOutput_key: number | null;
    C_InputOutputUnit_key: number | null;
    C_Output_key: number;
    C_VocabularyClass_key: number | null;
    C_WorkflowTask_key: number;
    C_Workgroup_key: number;
    CalculatedOutputExpression: CalculatedOutputExpression[];
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    DecimalPlaces: number | null;
    Description: string;
    EnumerationClass: EnumerationClass;
    ExpressionOutputMapping: ExpressionOutputMapping[];
    FlagMaximum: number | null;
    FlagMinimum: number | null;
    HasCohortStatsFlag: boolean;
    HasFlag: boolean;
    InheritedFromOutput: Output;
    IsActive: boolean;
    IsMultiSelectEnumeration: boolean;
    IsRequired: boolean;
    MedianFlagMaximum: number | null;
    MedianFlagMinimum: number | null;
    ModifiedBy: string;
    Output1Cohort: Cohort[];
    Output2Cohort: Cohort[];
    Output3Cohort: Cohort[];
    OutputDefault: OutputDefault[];
    OutputFlag: OutputFlag[];
    OutputName: string;
    SortOrder: number | null;
    StdDevFlagMaximum: number | null;
    StdDevFlagMinimum: number | null;
    TaskOutput: TaskOutput[];
    TextLineCount: number | null;
    TextValidationRegex: string;
    ValidationMax: string;
    ValidationMin: string;
    Version: number;
    WorkflowTask: WorkflowTask;
    cv_DataType: cv_DataType;
    cv_InputOutputUnit: cv_InputOutputUnit;
}

import {
    Component,
    Input,
    OnInit,
    OnChanges,
    ViewChildren,
} from '@angular/core';
import { ReasonForChangeService } from '../common/reason-for-change/reason-for-change.service';

import { EventService } from './event.service';
import { NgModel } from '@angular/forms';
import { dateControlValidator } from '@common/util/date-control.validator';

@Component({
    selector: 'event-history',
    templateUrl: './event-history.component.html',
    styles: [`
      .event-label {
        font-style: italic;
      }
    `]
})
export class EventHistoryComponent implements OnInit, OnChanges {
    @ViewChildren('dateControl') dateControls: NgModel[];
    @Input() material: any;
    @Input() facetPrivilege: string;

    // state
    events: any[] = [];

    constructor(
        private eventService: EventService,
        private reasonForChangeService: ReasonForChangeService,
    ) { }

    ngOnInit() {
        this.initialize();
    }

    ngOnChanges(changes: any) {
        if (changes.material) {
            if (!changes.material.firstChange) {
                this.initialize();
            }
        }
    }

    initialize() {
        if (!this.material) {
            return;
        }
        this.getData();
    }

    /**
     * Gets Events based on type of Input
     */
    getData(): Promise<any> {
        if (this.material) {
            return this.eventService.getMaterialEvents(this.material.C_Material_key)
                .then((data: any[]) => {
                    this.events = data;
                });
        }
    }

    /*
    addEvent(): any {
        let initialValues = {
            DateOccurred: new Date(),
            Description: '',
            Label: '',
            cv_EventType_key: cv_EventType_key
        };

        let newEvent: any = this.eventService.createEvent(initialValues);

        return newEvent;
    }
    */

    deleteEvent(event: any): void {
        const index = this.events.indexOf(event);
        this.events.splice(index, 1);
        this.reasonForChangeService.markModification([event.Animal]);
        this.eventService.deleteEvent(event);
    }

    validate() {
        return dateControlValidator(this.dateControls);
    }
}

import { Injectable } from '@angular/core';

import { TranslationService } from '../../services/translation.service';
import { UserNameService } from '../../user/user-name.service';
import { CsvExporter } from '../../common/export/csv-exporter';
import { DateFormatterService } from '@common/util/date-time-formatting';


/**
 * Exports a order audit report to CSV
 */
@Injectable()
export class ExportOrderAuditService {
    csvExporter: CsvExporter;

    constructor(
        private translationService: TranslationService,
        private userNameService: UserNameService,
        private dateFormatterService: DateFormatterService
    ) {
        this.csvExporter = new CsvExporter();
    }


    exportToCsv(
        auditData: any[]
    ) {
        const EXPORTER_CSV_FILENAME = 'OrderAudit.csv';

        const data: any[][] = this.buildExportData(
            auditData
        );

        this.csvExporter.download(data, EXPORTER_CSV_FILENAME);
    }

    buildExportData(
        auditData: any[]
    ): any[][] {
        const data: any[][] = [];

        data.push(['Details']);
        data.push([
            'Action',
            'Modified Date',
            'Modified By',
            'Order ID',
            'Job',
            'Institution',
            'Site',
            'Invoice Number',
            'Terms',
            'Courier',
            'Sample Condition',
            'Sample Count',
            'Instructions',
            'Received Date',
            'Received By',
            'Accession ID'
        ]);
        for (const row of auditData) {
            data.push([
                row.UpdateType,
                this.dateFormatterService.formatDateTimeUTC(row.ModifiedDate),
                this.userNameService.toFullName(row.ModifiedBy),
                row.OrderID,
                row.Job,
                row.Institution,
                row.Site,
                row.InvoiceNumber,
                row.PaymentTerm,
                row.Courier,
                row.SampleCondition,
                row.SampleCount,
                row.Comments,
                row.DateReceived,
                row.ReceivedBy,
                row.AccessionID
            ]);
        }


        return data;
    }
}

import { 
    ColorBoxCellRendererComponent 
} from '@common/colorbox/color-box-cell-renderer.component';
import {
    uniqueArrayFromPropertyPath
} from '@common/util';
import {
    CellFormatterService,
    TableOptions
} from '@common/datatable';

import { TranslationService } from '@services/translation.service';
import { addAuditColumnsToOptions } from '@common/datatable/utils';

export class CohortTableOptions {

    options: TableOptions;

    constructor(
        private cellFormatterService: CellFormatterService,
        private translationService: TranslationService
    ) {
        this.options = addAuditColumnsToOptions(cellFormatterService)(this.getDefaultOptions());
    }

    getDefaultOptions(): TableOptions {

        return {
            csvFileName: 'cohorts.csv',
            enableDetailColumn: true,
            enableDraggable: true,
            enableSelectable: true,
            columns: [
                {
                    displayName: 'Name', field: 'CohortName',
                },
                {
                    displayName: 'Description', field: 'Description',
                },
                {
                    displayName: 'Method', field: 'cv_MatchingMethod.MatchingMethod',
                },
                {
                    displayName: 'Background Color',
                    field: 'BackgroundColor',
                    visible: false,
                    sortable: false,
                    rendererComponent: <new () => ColorBoxCellRendererComponent>
                    ColorBoxCellRendererComponent
                },
                {
                    displayName: 'Foreground Color',
                    field: 'ForegroundColor',
                    visible: false,
                    sortable: false,
                    rendererComponent: <new () => ColorBoxCellRendererComponent>
                    ColorBoxCellRendererComponent
                },
                {
                    displayName: this.translationService.translate('Study'),
                    field: 'JobCohort.Job.Study',
                    sortable: false,
                    visible: false,
                    formatter: (row: any, value: any) => {
                        const uniqueValues = uniqueArrayFromPropertyPath(
                            row,
                            'JobCohort.Job.Study.StudyName'
                        );
                        return uniqueValues.join(', ');
                    }
                },
                {
                    displayName: this.translationService.translate('Job'),
                    field: 'JobCohort.Job',
                    sortable: false,
                    visible: false,
                    formatter: (row: any, value: any) => {
                        const uniqueValues = uniqueArrayFromPropertyPath(
                            row,
                            'JobCohort.Job.JobID'
                        );
                        return uniqueValues.join(', ');
                    }
                },
                {
                    displayName: 'Animals', field: 'CohortMaterial.length',
                    sortable: false,
                    headerClass: 'text-nowrap',
                    cellClass: 'num-cell'
                },
            ]
        };
    }
}

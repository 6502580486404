<ng-container [ngSwitch]="modifiedFieldView">
    <ng-container *ngSwitchCase="MODIFIED_FIELD_VIEWS.LINK">
        <audit-link
            [modifiedFields]="modifiedFields"
        ></audit-link>
    </ng-container>
    <ng-container *ngSwitchCase="MODIFIED_FIELD_VIEWS.TEXT">
        {{ value }}
    <info-tooltip *ngIf="isShowTooltip" [text]="tooltipText"></info-tooltip>
    </ng-container>
</ng-container>

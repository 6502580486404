/**
 * Gets the highest SortOrder in an object array.
 * @param items
 */
export function maxSortOrder(items: any[]) {
    let maxVal = 0;
    if (items) {
        for (const item of items) {
            if (item.SortOrder) {
                const currentVal = item.SortOrder;
                if (currentVal > maxVal) {
                    maxVal = currentVal;
                }
            }
        }
    }
    return maxVal;
}

import { Component, OnInit } from '@angular/core';

import { AuthService } from '../services/auth.service';
import { IoTService } from '../iot/iot.service';

@Component({
    selector: 'iot-monitoring-tab',
    templateUrl: './iot-monitoring-tab.component.html',
})
export class IoTMonitoringTabComponent implements OnInit {

    componentName = 'iot-monitoring-tab';
    iotAlertDefinitions: any[] = [];
    selectedRows: any[] = [];
    totalCount = 0;

    constructor(
        private iotService: IoTService,
        private authService: AuthService,
    ) { }

    ngOnInit() {
        this.getIoTAlertDefinitions();
    }

    getIoTAlertDefinitions(): void {
        this.iotService.getAlertDefinitions({
            filter: {},
        }).then((queryResult) => {
            const userName = this.authService.getCurrentUserName();
            this.iotAlertDefinitions = queryResult.results.map(
                (alertDef: any) => new IoTAlertDefinitionModelWrapper(
                    this.iotService, alertDef, userName));
        }).catch((err: Error) => {
            console.error(err);
        });
    }
}

class IoTAlertDefinitionModelWrapper {
    constructor(
        private iotService: IoTService,
        private iotAlertDefinition: any,
        private userName: string,
    ) {}

    get name(): string {
        return this.iotAlertDefinition.AlertDefinitionName;
    }

    get subscribedMessageCenter(): boolean {
        return this.isSubscribed('message center');
    }

    set subscribedMessageCenter(subscribed: boolean) {
        this.setSubscribed('message center', subscribed);
    }

    get subscribedEmail(): boolean {
        return this.isSubscribed('email');
    }

    set subscribedEmail(subscribed: boolean) {
        this.setSubscribed('email', subscribed);
    }

    get subscribedSMS(): boolean {
        return this.isSubscribed('SMS');
    }

    set subscribedSMS(subscribed: boolean) {
        this.setSubscribed('SMS', subscribed);
    }

    private isSubscribed(modalityName: string): boolean {
        // first we find the alert subscription for this user, then if it
        // exists we need to look for the subscription by modality
        const userSub = this.iotAlertDefinition.AlertSubscription.find((alertSub: any) => {
            return alertSub.UserName === this.userName;
        });

        if (userSub) {
            return userSub.AlertSubscriptionModality.some((currModality: any) => {
                return currModality.ModalityName === modalityName;
            });
        } else {
            return false;
        }
    }

    private setSubscribed(modalityName: string, subscribed: boolean): void {
        if (subscribed) {
            this.iotService.subscribeToAlertModality(
                this.iotAlertDefinition,
                this.userName,
                modalityName);
        } else {
            this.iotService.unsubscribeToAlertModality(
                this.iotAlertDefinition,
                this.userName,
                modalityName);
        }
    }
}

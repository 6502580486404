import {
    CellFormatterService,
    FormatterOptions,
    TableOptions
} from '../common/datatable';
import { TranslationService } from '../services/translation.service';
import { AuditModifiedFieldComponent } from './audit-modified-fields/audit-modified-field.component';
import { AuditClassFactory } from './audit-class-maker';

export class AuditMatingsTableOptions {

    options: TableOptions;

    constructor(
        private cellFormatterService: CellFormatterService,
        private translationService: TranslationService
    ) {
        this.options = this.getDefaultOptions();
    }

    getDefaultOptions(): TableOptions {

        return {
            csvFileName: 'matings-audit.csv',
            enableDetailColumn: false,
            enableDraggable: false,
            enableSelectable: false,
            enableAuditColumns: false,
            columns: [
                {
                    displayName: 'Type',
                    field: 'UpdateType',
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Modified Fields',
                    field: 'ModifiedFields',
                    sortField: 'ModifiedFields',
                    rendererComponent: AuditModifiedFieldComponent,
                    exportFormatter: this.cellFormatterService.auditModifiedFieldsExportFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Date',
                    field: 'ModifiedDate',
                    formatter: this.cellFormatterService.dateTimeUTCFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Modified By',
                    field: 'ModifiedBy',
                    formatter: this.cellFormatterService.userNameFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Housing ID',
                    field: 'HousingID',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('HousingID', 'HousingID'),
                    cellClass: AuditClassFactory.createCellClass('housingid'),
                },
                {
                    displayName: 'Mating ID',
                    field: 'MatingID',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('MatingID', 'MatingID'),
                    cellClass: AuditClassFactory.createCellClass('matingid'),
                },
                {
                    displayName: 'Status',
                    field: 'MatingStatus',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('MatingStatus', 'MatingStatus'),
                    cellClass: AuditClassFactory.createCellClass('matingstatus'),
                },
                {
                    displayName: this.translationService.translate('Line'),
                    field: 'LineName',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('LineName', (this.translationService.translate('Line') + 'Name')),
                    cellClass: AuditClassFactory.createCellClass((this.translationService.translate('Line') + 'Name').toLowerCase()),
                },
                {
                    displayName: this.translationService.translate('Line') + ' Short Name',
                    field: 'LineShortName',
                    visible: false,
                    formatter: this.cellFormatterService.createAuditColumnFormatter('LineShortName', this.translationService.translate('Line') + 'ShortName'),
                    cellClass: AuditClassFactory.createCellClass((this.translationService.translate('Line') + 'ShortName').toLowerCase()),
                },
                {
                    displayName: 'Mating Date',
                    field: 'DateMating',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('DateMating', 'DateMating', this.cellFormatterService.dateFormatter),
                    cellClass: AuditClassFactory.createCellClass('datemating'),
                },
                {
                    displayName: 'Mating Type',
                    field: 'MatingType',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('MatingType', 'MatingType'),
                    cellClass: AuditClassFactory.createCellClass('matingtype'),
                },
                {
                    displayName: 'Purpose',
                    field: 'MatingPurpose',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('MatingPurpose', 'MatingPurpose'),
                    cellClass: AuditClassFactory.createCellClass('matingpurpose'),
                },
                {
                    displayName: 'Generation',
                    field: 'Generation',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('Generation', 'Generation'),
                    cellClass: AuditClassFactory.createCellClass('generation'),
                },
                {
                    displayName: 'Comments',
                    field: 'Comments',
                    visible: false,
                    formatter: this.cellFormatterService.createAuditColumnFormatter('Comments', 'Comments'),
                    cellClass: AuditClassFactory.createCellClass('comments'),
                },
                {
                    displayName: 'Created By',
                    field: 'CreatedBy',
                    formatter: this.cellFormatterService.userNameFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Created Date',
                    field: 'DateCreated',
                    formatter: this.cellFormatterService.dateTimeUTCFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                }
            ]
        };
    }
}

import { Component, ChangeDetectionStrategy } from '@angular/core';
import { RoutingService } from '../../../routing/routing.service';
import { LoginService } from '../../services/login.service';

@Component({
    selector: 'logged-out-view',
    templateUrl: './logged-out-view.component.html',
    styleUrls: ['./logged-out-view.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoggedOutViewComponent {
    message = 'Session Expired. Please refresh the page.';

    constructor(
        private loginService: LoginService,
        private routingService: RoutingService,
    ) {
        if (this.loginService.isAuthenticated()) {
            this.routingService.navigateToDashboard();
        }
    }

    refresh(): void {
        this.routingService.navigateToLogin();
    }
}

import {
    CellFormatterService,
    FormatterOptions,
    TableOptions
} from '../common/datatable';
import { TranslationService } from '../services/translation.service';
import { AuditModifiedFieldComponent } from './audit-modified-fields/audit-modified-field.component';
import { AuditClassFactory } from './audit-class-maker';

export class AuditWorkflowOutputsTableOptions {
    options: TableOptions;

    constructor(
        private cellFormatterService: CellFormatterService,
        private translationService: TranslationService,
    ) {
        this.options = this.getDefaultOptions();
    }

    getDefaultOptions(): TableOptions {
        return {
            csvFileName: 'workflowOutput-audit.csv',
            enableDetailColumn: false,
            enableDraggable: false,
            enableSelectable: false,
            enableAuditColumns: false,
            columns: [
                {
                    displayName: 'Type',
                    field: 'UpdateType',
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Modified Fields',
                    field: 'ModifiedFields',
                    sortField: 'ModifiedFields',
                    rendererComponent: AuditModifiedFieldComponent,
                    exportFormatter: this.cellFormatterService.auditModifiedFieldsExportFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Edit Post Completion',
                    field: 'IsEditPostCompletion',
                    cellClass: (params: any) => {
                        const row = params.data;
                        const classes: string[] = [];
                        if (!row) {
                            return classes;
                        }
                        if ((row.IsEditPostCompletion && row.IsEditPostCompletion.toLowerCase() === 'true')
                            || (row.UpdateType && row.UpdateType.toLowerCase() === 'deleted')) {
                            classes.push('deleted-type'); // Make red if IsEditPostCompletion is true
                        }
                        return classes;
                    }
                },
                {
                    displayName: 'Date',
                    field: 'ModifiedDate',
                    formatter: this.cellFormatterService.dateTimeUTCFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Modified By',
                    field: 'ModifiedBy',
                    formatter: this.cellFormatterService.userNameFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Task Type',
                    field: 'TaskType',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('TaskType', 'TaskType'),
                    cellClass: AuditClassFactory.createCellClass('tasktype'),
                },
                {
                    displayName: 'Task Name',
                    field: 'TaskName',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('TaskName', 'TaskName'),
                    cellClass: AuditClassFactory.createCellClass('taskname'),
                },
                {
                    displayName: 'Task',
                    field: 'Task',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('Task', 'Task'),
                    cellClass: AuditClassFactory.createCellClass('task'),
                },
                {
                    displayName: this.translationService.translate('Job') + ' Name',
                    field: 'JobName',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('JobName', (this.translationService.translate('Job') + 'Name')),
                    cellClass: AuditClassFactory.createCellClass((this.translationService.translate('Job') + 'Name').toLowerCase()),
                },
                {
                    displayName: this.translationService.translate('Study') + ' Name',
                    field: 'StudyName',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('StudyName', (this.translationService.translate('Study') + 'Name')),
                    cellClass: AuditClassFactory.createCellClass((this.translationService.translate('Study') + 'Name').toLowerCase()),
                },
                {
                    displayName: 'Animal Name',
                    field: 'AnimalName',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('AnimalName', 'AnimalName'),
                    cellClass: AuditClassFactory.createCellClass('animalname'),
                },
                {
                    displayName: 'Sample Name',
                    field: 'SampleName',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('SampleName', 'SampleName'),
                    cellClass: AuditClassFactory.createCellClass('samplename'),
                },
                {
                    displayName: 'Birth ID',
                    field: 'BirthID',
                    visible: false,
                    formatter: this.cellFormatterService.createAuditColumnFormatter('BirthID', 'BirthID'),
                    cellClass: AuditClassFactory.createCellClass('birthid'),
                },
                {
                    displayName: 'Housing ID',
                    field: 'HousingID',
                    visible: false,
                    formatter: this.cellFormatterService.createAuditColumnFormatter('HousingID', 'HousingID'),
                    cellClass: AuditClassFactory.createCellClass('housingid'),
                },    
                {
                    displayName: this.translationService.translate('Line') + ' Name',
                    field: 'LineName',
                    visible: false,
                    formatter: this.cellFormatterService.createAuditColumnFormatter('LineName', (this.translationService.translate('Line') + 'Name')),
                    cellClass: AuditClassFactory.createCellClass((this.translationService.translate('Line') + 'Name').toLowerCase()),
                },
                {
                    displayName: 'Mating ID',
                    field: 'MatingID',
                    visible: false,
                    formatter: this.cellFormatterService.createAuditColumnFormatter('MatingID', 'MatingID'),
                    cellClass: AuditClassFactory.createCellClass('matingid'),
                },
                {
                    displayName: 'Name',
                    field: 'Name',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('Name', 'Name'),
                    cellClass: AuditClassFactory.createCellClass('name'),
                },
                {
                    displayName: 'Value',
                    field: 'Value',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('Value', 'Value'),
                    cellClass: AuditClassFactory.createCellClass('value'),
                },
                {
                    displayName: 'Created By',
                    field: 'CreatedBy',
                    formatter: this.cellFormatterService.userNameFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Created Date',
                    field: 'DateCreated',
                    formatter: this.cellFormatterService.dateTimeUTCFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                }
            ]
        };
    }
}

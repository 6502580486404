<ng-container *ngIf="line">
    <ng-template [ngTemplateOutlet]="labelValuePair"
        [ngTemplateOutletContext]="{
           label: ('Line' | translate) + ' Name',
           value: line.LineName }"></ng-template>

    <ng-template [ngTemplateOutlet]="labelValuePair"
        [ngTemplateOutletContext]="{
            label: 'LineShortName' | translate,
            value: line.StockNumber }"></ng-template>

    <ng-template [ngTemplateOutlet]="labelValuePair"
        [ngTemplateOutletContext]="{
            label: ('Line' | translate) + ' Type',
            value: line.cv_LineType?.LineType }"></ng-template>

    <ng-template [ngTemplateOutlet]="labelValuePair"
        [ngTemplateOutletContext]="{
            label: ('Line' | translate) + ' Status',
            value: line.cv_LineStatus?.LineStatus }"></ng-template>

    <ng-template [ngTemplateOutlet]="labelValuePair"
        [ngTemplateOutletContext]="{
            label: 'Species',
            value: line.cv_Taxon?.CommonName }"></ng-template>
</ng-container>


<ng-template #labelValuePair let-label="label" let-value="value">
    <div class="form-group row" *ngIf="!useTableFormat">
        <label class="col-md-2 col-form-label">{{label}}</label>
        <div class="col-md-10">
            <p class="form-control-plaintext">
                {{value}}
            </p>
        </div>
    </div>

    <tr *ngIf="useTableFormat">
        <td>{{label}}</td>
        <td>{{value}}</td>
    </tr>
</ng-template>

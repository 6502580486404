<div class="d-flex datepicker" [ngClass]="{'time-picker-opened': isShowTimePickerDropdown}">
    <div *ngIf="allowNow">
        <button class="btn btn-default now-button"
                [ngClass]="class"
                type="button"
                [disabled]="disabled"
                aria-label="Insert current date/time"
                (click)="selectNow()">
            <i class="fa fa-angle-double-right" title="Insert current date/time"></i>
        </button>
    </div>
    <div class="date-control" *ngIf="allowDate">
        <input class="form-control date-input"
               [ngClass]="class"
               name="{{name}}_date"
               ngbDatepicker
               [placeholder]="placeholder"
               [(ngModel)]="_modelStruct"
               [ngModelOptions]="{ standalone: true }"
               (blur)="updateModel()"
               (dateSelect)="updateModel()"
               [attr.id]="timepickerId"
               [required]="required"
               [disabled]="disabled"
               [firstDayOfWeek]="FIRST_DAY_OF_WEEK"
               [container]="container"
               #datePicker="ngbDatepicker"
               #datePicketInput />
    </div>
    <div *ngIf="allowDate">
        <button class="btn btn-default date-button"
                [ngClass]="class"
                type="button"
                [disabled]="disabled"
                aria-label="Show date chooser&hellip;"
                (click)="onDatePickerOpen()">
            <i class="fa fa-calendar" title="Show date chooser&hellip;"></i>
        </button>
    </div>
    <div *ngIf="showTimePickerInput">
        <climb-timepicker [time]="_timeComponent"
                          (selectTime)="onTimeChange($event)"
                          [appendToId]="timepickerId"
                          [externalDate]="value"
                          [timeFormat]="luxonTimeFormat"
                          [hide]="!showTimePickerInput"
                          [disabled]="disabled"
                          [extraClasses]="class"
                          (onOpened)="onToggleTimePicker(true)"
                          (onClosed)="onToggleTimePicker(false)">
        </climb-timepicker>
    </div>
    <div *ngIf="allowDate && allowTime">
        <button class="btn btn-default"
                [ngClass]="class"
                type="button"
                [disabled]="disabled"
                aria-label="Show time chooser&hellip;"
                (click)="toggleTimeChooser()">
            <i class="fa fa-clock-o" title="Show time chooser&hellip;"></i>
        </button>
    </div>
</div>

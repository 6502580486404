import {
    Component,
    EventEmitter,
    Input,
    Output,

} from '@angular/core';

@Component({
    selector: 'cohort-stats-flag',
    templateUrl: 'cohort-stats-flag.component.html',
    styles: [],
})
export class CohortStatsFlagComponent {
    @Input() disabled: boolean;
    @Input() avgFlagMinimum: number;
    @Input() mdnFlagMinimum: number;
    @Input() stDevFlagMinimum: number;
    @Input() avgFlagMaximum: number;
    @Input() mdnFlagMaximum: number;
    @Input() stDevFlagMaximum: number;
    @Input() flagIsChecked = false;
    
    @Output() flagIsCheckedChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() avgFlagMaximumChange: EventEmitter<number> = new EventEmitter<number>();
    @Output() avgFlagMinimumChange: EventEmitter<number> = new EventEmitter<number>();
    @Output() mdnFlagMaximumChange: EventEmitter<number> = new EventEmitter<number>();
    @Output() mdnFlagMinimumChange: EventEmitter<number> = new EventEmitter<number>();
    @Output() stDevFlagMaximumChange: EventEmitter<number> = new EventEmitter<number>();
    @Output() stDevFlagMinimumChange: EventEmitter<number> = new EventEmitter<number>();

    flagIsCheckedChangeHandler() {
        this.flagIsCheckedChange.emit(this.flagIsChecked);
    }

    avgFlagMaximumChangeHandler() {
        this.avgFlagMaximumChange.emit(this.avgFlagMaximum);
    }

    avgFlagMinimumChangeHandler() {
        this.avgFlagMinimumChange.emit(this.avgFlagMinimum);
    }

    mdnFlagMaximumChangeHandler() {
        this.mdnFlagMaximumChange.emit(this.mdnFlagMaximum);
    }

    mdnFlagMinimumChangeHandler() {
        this.mdnFlagMinimumChange.emit(this.mdnFlagMinimum);
    }

    stDevFlagMaximumChangeHandler() {
        this.stDevFlagMaximumChange.emit(this.stDevFlagMaximum);
    }

    stDevFlagMinimumChangeHandler() {
        this.stDevFlagMinimumChange.emit(this.stDevFlagMinimum);
    }
}

import { FacetSharedModule } from './../common/facet/facet-shared.module';
import { SearchSharedModule } from './../search/search-shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { ClimbCommonModule } from '../common/climb-common.module';
import { ClimbServicesModule } from '../services/climb-services.module';
import { WorkspacesSharedModule } from '../workspaces/workspaces-shared.module';
import { UsersSharedModule } from '../user/users-shared.module';

import {
    ConstructDetailComponent,
    ConstructFilterComponent,
    ConstructFacetComponent
} from '.';

import { ConstructsSharedModule } from './constructs-shared.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgbModule,
        ClimbCommonModule,
        ClimbServicesModule,
        FacetSharedModule,
        SearchSharedModule,
        WorkspacesSharedModule,
        UsersSharedModule,
        ConstructsSharedModule
    ],
    declarations: [
        ConstructDetailComponent,
        ConstructFilterComponent,
        ConstructFacetComponent
    ],
    exports: [
        ConstructFacetComponent
    ],
    providers: [
    ]
})
export class ConstructsModule { }

/**
 * Options passed when opening a confirmation modal
 */
export interface ConfirmOptions {
    title: string;
    message: string;
    yesButtonClass?: string;
    yesButtonText?: string;
    noButtonText?: string;
    isDanger?: boolean;
    titleDetails?: string;
    details?: string[];
    onlyYes?: boolean;
    isHtml?: boolean;
    extraButton?: boolean;
    extraButtonText?: string;
    forceAnswer?: boolean;
}

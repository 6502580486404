import type { LocationPosition } from './location-position.interface';
import type { Order } from './order.interface';

export interface OrderLocation {
    C_LocationPosition_key: number;
    C_OrderLocation_key: number;
    C_Order_key: number | null;
    C_Workgroup_key: number;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    LocationPosition: LocationPosition;
    ModifiedBy: string;
    Order: Order;
    Version: number;
}

import { Entity } from 'breeze-client';
import { Pipe, PipeTransform } from '@angular/core';
import { testBreezeIsNew } from '../util/index';


/**
 * Return whether or not object is a new/unsaved entity in Breeze.
 *  Returns false on null
 *  Returns true on Added state
 */
@Pipe({
    name: 'isNew',
    pure: false
})
export class IsNewPipe implements PipeTransform {

    transform(value: Entity): boolean {
        return testBreezeIsNew(value);
    }
}

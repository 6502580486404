import type { Material } from './material.interface';
import type { MaterialTypeContainerType } from './material-type-container-type.interface';
import type { cv_ContainerType } from './cv-container-type.interface';

export interface cv_MaterialType {
    C_MaterialType_key: number;
    C_VocabularySource_key: number | null;
    C_Workgroup_key: number;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    ElementID: string;
    IsActive: boolean;
    IsDefault: boolean;
    IsDeprecated: boolean;
    Material: Material[];
    MaterialType: string;
    MaterialTypeContainerType: MaterialTypeContainerType[];
    ModifiedBy: string;
    SortOrder: number | null;
    Version: number;
    cv_ContainerType: cv_ContainerType[];
}

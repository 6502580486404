import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { NgModel } from "@angular/forms";

@Component({
    selector: 'editable-label',
    templateUrl: './editable-label.component.html',
    styles: [
        `
        .editable {
            cursor: pointer;
            font-weight: bold;
            padding: .5rem 1rem .5rem 1rem;
            border-radius: 25px;
        }
        `,
    ]
})
export class EditableLabelComponent {
    @Input() model: string;
    @Input() index = 0;
    @Input() isEditable = true;
    @Input() uniqueEntity: string;
    @Input() uniqueProperty: string;
    @ViewChild('inputField') inputField: ElementRef;
    @ViewChild('inputState') inputState: NgModel;
    @Output() modelChange: EventEmitter<string> = new EventEmitter<string>();
    @Output() valid: EventEmitter<void> = new EventEmitter<void>();

    original: string;
    isEditing = false;

    startEditing() {
        if (this.isEditable) {
            this.isEditing = true;
            this.original = this.model;
            setTimeout(() => this.inputField.nativeElement.focus());
        }
    }

    stopEditing() {
        if (this.inputState?.errors?.unique) {
            this.inputField.nativeElement.focus();
            return;
        }
        this.isEditing = false;
        this.valid.emit();
    }

    cancelEditing() {
        this.isEditing = false;
        this.model = this.original;
    }

    onModelChange() {
        this.modelChange.emit(this.model);
    }
}

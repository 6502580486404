import { DateTime } from 'luxon';
import { LuxonInput } from './date-time-formatting/types/luxon-input.type';
import { convertValueToLuxon } from './date-time-formatting/convert-value-to-luxon';

/**
 * Calculates the age of an animal in weeks from the first to occur of
 * (a) exit date, and (b) today.
 *
 * @param birthDate
 * @param exitDate
 */
export function animalAgeWeeks(birthDate: LuxonInput, exitDate?: LuxonInput): string {
    if (!birthDate) {
        return '';
    }

    const todayMoment: DateTime = DateTime.now();
    let testMoment: DateTime;

    const exitDateMoment: DateTime = convertValueToLuxon(exitDate);
    if (exitDateMoment) {
        // There's an exit date, so test from the earlier of exit date and today.
        testMoment = DateTime.min(todayMoment, exitDateMoment);
    } else {
        // No exit date, so test from today
        testMoment = todayMoment;
    }

    let birthMoment: DateTime = convertValueToLuxon(birthDate);

    // ignore time
    birthMoment = birthMoment.startOf('day');
    testMoment = testMoment.startOf('day');

    const diffUnit = 'weeks';
    return '' + Math.round(testMoment.diff(birthMoment, diffUnit).as("weeks"));
}

<ng-container *ngIf="cohortMaterials | notEmpty; else noData">
    <table class="table table-sm table-borderless">
        <tbody>
            <tr *ngFor="let cohortMaterial of cohortMaterials | orderBy:'Cohort.CohortName'">
                <td>
                    {{cohortMaterial?.Cohort?.CohortName}}
                </td>
            </tr>
        </tbody>
    </table>
</ng-container>

<ng-template #noData>
    <em>None.</em>
</ng-template>

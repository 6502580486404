import type { JobGroup } from './job-group.interface';
import type { JobTestArticle } from './job-test-article.interface';
import type { ProtocolInstance } from './protocol-instance.interface';

export interface JobGroupTreatment {
    ActiveDose: string;
    ActiveUnit: string;
    C_JobGroupTreatment_key: number;
    C_JobGroup_key: number;
    C_JobTestArticle_key: number | null;
    C_ProtocolInstance_key: number | null;
    C_Workgroup_key: number;
    Concentration: string;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    DosingUnit: string;
    DosingVolume: string;
    FormulationDose: string;
    JobGroup: JobGroup;
    JobTestArticle: JobTestArticle;
    ModifiedBy: string;
    ProtocolInstance: ProtocolInstance;
    Route: string;
    Schedule: string;
    Treatment: string;
    Version: number;
}

import {
    ChangeDetectionStrategy,
    Component,
    Input,
} from '@angular/core';
import { compareDates } from '@common/date/compare-dates';

type ChartData = {
    traces: Plotly.Data[];
    layout: Plotly.Layout;
    config: Partial<Plotly.Config>;
};

@Component({
    selector: 'mating-litter-size-chart-renderer',
    template: `
        <plotly-plot [data]="chartData.traces" [layout]="chartData.layout" [config]="chartData.config"></plotly-plot>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MatingLitterSizeChartRendererComponent {
    private _mating: any;
    chartData: ChartData = {
        traces: [],
        layout: null,
        config: { responsive: true },
    };

    @Input('mating')
    get mating() {
        return this._mating;
    }

    set mating(_mating: any) {
        this._mating = _mating;
        this._renderChart();
    }

    private _renderChart() {
        if (this._mating) {

            // filter out undefined dates and sort births by date
            const births = this._mating.Birth
                .filter((birth: any) => birth.DateBorn !== null && birth.DateBorn !== undefined)
                .sort((birth1: any, birth2: any) => compareDates(birth1.DateBorn, birth2.DateBorn));

            const traces: Plotly.Data[] = [
                {
                    x: births.map((birth: any, i: number) => i + 1),
                    y: births.map((birth: any) => birth.LiveCount),
                    name: 'Live Count',
                    type: 'bar',
                },
                {
                    x: births.map((birth: any, i: number) => i + 1),
                    y: births.map((birth: any) => birth.StillbornCount),
                    name: 'Stillborn Count',
                    type: 'bar',
                },
            ];

            const layout: any = { barmode: 'stack', hovermode: 'x' };
            if (traces.length) {
                layout.title = 'Live/Stillborn Offspring Count';
                layout.xaxis = {
                    title: 'Birth Number',
                    tickmode: 'linear',
                    tick0: 1,
                    dtick: 1
                };
                layout.yaxis = { title: 'Counts Per Birth' };
            }

            this.chartData = {
                ...this.chartData,
                traces,
                layout,
            };
        }
    }
}

export interface cv_CensusStatus {
    C_CensusStatus_key: number;
    CensusStatus: string;
    IsActive: boolean;
    IsDefault: boolean;
    SortOrder: number | null;
    C_VocabularySource_key: number | null;
    ElementID: string;
    IsDeprecated: boolean;
    C_Workgroup_key: number;
    CreatedBy: string;
    DateCreated: string;
    ModifiedBy: string;
    DateModified: string;
    Version: number;
}

<form role="form" class="form-label-right">
    <div class="modal-header justify-content-center">
        <h4 class="modal-title">Census Filter</h4>
    </div>

    <div class="modal-body">
        <div class="form-group row">
            <div class="col-md-3">
                <label class="col-form-label" style="display: block;">ID</label>
                <div class="text-right">
                    <one-many-toggle #censusIDToggle></one-many-toggle>
                </div>
            </div>
            <div class="col-md-9">
                <multi-control-wrap [toggleComponent]="censusIDToggle"
                                    [(singleModel)]="filter.C_Census_key"
                                    [(manyModel)]="filter.censusIDs">
                    <multi-control name="single">
                        <input type="text" name="censusID"
                               [(ngModel)]="filter.C_Census_key"
                               class="form-control input-medium"
                               data-auto-focus="censusID" />
                        <small class="form-text text-muted">
                            Find names <em>containing</em> this value.
                        </small>
                    </multi-control>
                    <multi-control name="many">
                        <multi-paste-input [(model)]="filter.censusIDs"
                                           [limit]="MULTI_PASTE_INPUT_LIMIT"
                                           [rows]="3"></multi-paste-input>
                        <small class="form-text text-muted">
                            IDs separated by
                            <code>,</code> or <code>Enter</code>.
                            Limit: {{MULTI_PASTE_INPUT_LIMIT}}.
                        </small>
                    </multi-control>
                </multi-control-wrap>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-3 col-form-label">Status</label>
            <div class="col-md-9">
                <climb-multi-select [(model)]="filter.C_CensusStatus_key"
                                    [values]="censusStatuses"
                                    [keyProperty]="'C_CensusStatus_key'"
                                    [valueProperty]="'CensusStatus'"
                                    [placeholder]="'Select statuses...'"></climb-multi-select>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-3 col-form-label">Date Range</label>
            <div class="col-md-3">
                <climb-ngb-date #dateControl="ngModel"
                                [(ngModel)]="filter.DateStart"
                                name="Date Start"
                                placeholder="From&hellip;"></climb-ngb-date>
            </div>
            <div class="col-md-2"></div>
            <div class="col-md-3">
                <climb-ngb-date #dateControl="ngModel"
                                [(ngModel)]="filter.DateEnd"
                                name="Date End"
                                placeholder="To&hellip;"></climb-ngb-date>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-3 col-form-label">Location</label>
            <div class="col-md-9">
                <input type="text"
                       name="location"
                       [(ngModel)]="filter.Location"
                       class="form-control input-medium" />
            </div>
        </div>

    </div><!-- /.modal-body -->

    <div class="modal-footer">
        <span class="btn-set">
            <button type="submit"
                    class="btn btn-fw-wide btn-lg btn-primary"
                    (click)="filterClicked($event)">
                Filter
            </button>
            <button data-automation-id="clear-filter-button"
                    type="button"
                    class="btn btn-fw-wide btn-lg btn-secondary"
                    (click)="clearClicked()">
                Clear Filter
            </button>
        </span>
        <span class="btn-set">
            <button type="button"
                    class="btn btn-lg btn-secondary"
                    (click)="closeClicked()">
                Close
            </button>
        </span>
    </div>
</form>

import type { AlertDefinition } from './alert-definition.interface';
import type { AlertSubscriptionModality } from './alert-subscription-modality.interface';

export interface AlertSubscription {
    AlertDefinition: AlertDefinition;
    AlertSubscriptionModality: AlertSubscriptionModality[];
    C_AlertDefinition_key: number;
    C_AlertSubscription_key: number;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    ModifiedBy: string;
    UserName: string;
    Version: number;
}

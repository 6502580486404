import {
    AfterViewInit,
    Directive,
    ElementRef,
    HostBinding,
    Inject,
    OnDestroy,
    Optional,
    SkipSelf,
} from '@angular/core';
import { DragDrop } from '@angular/cdk/drag-drop';
import { BaseDragDirective } from './base-drag.directive';
import { CLIMB_COLUMN_DEF } from '../table.token';
import { HEADER_DROP_LIST } from './drag-drop.token';
import type { ClimbColumnDef, ColumnId } from '../table.interface';
import type { ContainerDropList, HeaderDrag } from './drag-drop.interface';


@Directive({
    selector: 'climb-header-cell, th[climbHeaderCell]',
})
export class HeaderDragDirective extends BaseDragDirective implements HeaderDrag, AfterViewInit, OnDestroy {
    @HostBinding('class')
    private get classes(): Record<string, boolean> {
        return {
            ['header-drag']: this.columnDef.draggable,
            ['header-dragging']: this.dragRef?.isDragging(),
        };
    }

    get columnId(): ColumnId {
        return this.columnDef.columnId;
    }

    constructor(
        @Inject(HEADER_DROP_LIST) @Optional() @SkipSelf() dropContainer: ContainerDropList,
        private dragDrop: DragDrop,
        @Inject(CLIMB_COLUMN_DEF) private columnDef: ClimbColumnDef,
        private readonly elementRef: ElementRef,
    ) {
        super(dropContainer);
    }

    ngAfterViewInit(): void {
        if (this.columnDef.draggable) {
            this.initDrag(this.dragDrop, this.elementRef);
        }
    }
}

/**
 * Used keys for local storage data
 */
export enum LocalStorageKey {
    AUTH_DATA = 'authorizationData',
    // TODO looks like CURRENT_POSITION_STORAGE_KEY value is never set
    CURRENT_POSITION_STORAGE_KEY = 'currentPositionKey',
    ENVIRONMENT_KEY = 'environmentKey',
    WORKGROUP_NAME = 'workgroupName',
    WORKGROUP_KEY = 'workgroupKey',
    WORKGROUP_TIMEZONE_INFO = 'workgroupTimezoneInfo',
    ADMIN_BREEZE_SCHEMA = 'admin.breezeSchema',
    ADMIN_SCHEMA_KEY = 'admin.schemaKey',
    DATA_BREEZE_SCHEMA = 'data.breezeSchema',
    DATA_SCHEMA_KEY = 'data.schemaKey',
    SSO_ID_TOKEN = 'id_token',
    FEATURE_FLAGS = 'admin.featureFlags',
    ADMIN_MESSAGES = 'admin.notifications',
    ANIMAL_SYNC_WARNING = 'animal.sync.warning',
    CLINIC_SYNC_WARNING = 'clinic.sync.warning',
    INACTIVITY_TIMEOUT_MINUTES = 'inactivityTimeoutMinutes',
    OKTA_TOKEN_STORAGE = 'okta-token-storage',
    OKTA_CACHE_STORAGE = 'okta-cache-storage',
}

import { intersection } from '@lodash';
import { Injectable } from '@angular/core';
import { VocabularyService } from './../vocabularies/vocabulary.service';
import {
    AutoGeneratedKeyType,
    EntityQuery,
    FilterQueryOp,
    Predicate,
    QueryResult
} from 'breeze-client';

import { notEmpty } from '../common/util/not-empty';

import { DataManagerService } from '../services/data-manager.service';
import { QueryDef } from '../services/query-def';
import { getDateRangePredicates } from '../services/queries';
import { BaseEntityService } from '../services/base-entity.service';

import { AnimalService } from '../animals/services/animal.service';
import { SampleService } from '../samples/sample.service';

import {
    buildGenotypeCombosFilter
} from '../services/queries';

@Injectable()
export class MatingService extends BaseEntityService {

    readonly ENTITY_TYPE = 'Matings';
    readonly ENTITY_NAME = 'Mating';

    constructor(
        private dataManager: DataManagerService,
        private animalService: AnimalService,
        private sampleService: SampleService,
        private vocabularyService: VocabularyService
    ) {
        super();
    }


    getMatings(queryDef: QueryDef): Promise<QueryResult> {
        let query = this.buildDefaultQuery(this.ENTITY_TYPE, queryDef);

        this.ensureDefExpanded(queryDef, 'Birth.Animal');
        this.ensureDefExpanded(queryDef, 'Line');
        this.ensureDefExpanded(queryDef, 'MaterialPool.JobMaterialPool.Job');
        this.ensureDefExpanded(queryDef, 'MaterialPool.MaterialLocation.LocationPosition');
        this.ensureDefExpanded(queryDef, 'MatingPlugDate');
        query = query.expand(queryDef.expands.join(','));

        let predicates: Predicate[] = [];
        if (queryDef.filter) {
            predicates = predicates.concat(this.buildPredicates(queryDef.filter));

            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed) as Promise<QueryResult>;
    }

    buildPredicates(filter: any): Predicate[] {
        let predicates: Predicate[] = [];
        if (!filter) {
            return predicates;
        }

        if (notEmpty(filter.materialPoolIDs)) {
            const materialPoolKeys = filter.materialPoolIDs.map((item: any) => {
                return item.C_MaterialPool_key;
            });

            predicates.push(
                Predicate.create('C_MaterialPool_key', 'in', materialPoolKeys)
            );
        }
        if (filter.matingID) {
            predicates.push(
                Predicate.create('MatingID', FilterQueryOp.Contains, { value: filter.matingID })
            );
        }
        if (notEmpty(filter.matingIDs)) {
            predicates.push(
                Predicate.create('MatingID', 'in', filter.matingIDs)
            );
        }
        if (filter.C_MaterialPool_key) {
            predicates.push(
                Predicate.create('C_MaterialPool_key', 'eq', filter.C_MaterialPool_key)
            );
        }
        if (filter.C_MatingStatus_key) {
            predicates.push(
                Predicate.create('C_MatingStatus_key', 'eq', filter.C_MatingStatus_key)
            );
        }
        if (filter.C_MatingType_key) {
            predicates.push(
                Predicate.create('C_MatingType_key', 'eq', filter.C_MatingType_key)
            );
        }
        if (notEmpty(filter.C_Generation_keys)) {
            predicates.push(
                Predicate.create('C_Generation_key', 'in', filter.C_Generation_keys)
            );
        }
        if (notEmpty(filter.lines)) {
            const lineKeys = filter.lines.map((line: any) => {
                return line.LineKey;
            });

            predicates.push(Predicate.create(
                'C_Line_key', 'in', lineKeys
            ));
        }

        if (notEmpty(filter.jobs)) {
            const jobKeys = filter.jobs.map((job: any) => {
                return job.JobKey;
            });

            predicates.push(Predicate.create(
                'MaterialPool.JobMaterialPool', 'any',
                'C_Job_key', 'in', jobKeys
            ));
        }

        if (filter.createdBy) {
            predicates.push(Predicate.create('CreatedBy', 'eq', filter.createdBy));
        }

        if (filter.dateMatingStart || filter.dateMatingEnd) {
            const datePredicates: Predicate[] = getDateRangePredicates(
                'DateMating',
                filter.dateMatingStart,
                filter.dateMatingEnd
            );
            if (notEmpty(datePredicates)) {
                predicates = predicates.concat(datePredicates);
            }
        }

        if (notEmpty(filter.animalIDs)) {
            predicates.push(
                Predicate.create('MaterialPool.MaterialPoolMaterial', 'any',
                    'Material.Identifier', 'in', filter.animalIDs)
            );
        }
        if (filter.animalName) {
            predicates.push(
                Predicate.create('MaterialPool.MaterialPoolMaterial', FilterQueryOp.Any,
                    'Material.Animal.AnimalName', FilterQueryOp.Contains, { value: filter.animalName })
            );
        }
        if (notEmpty(filter.animalNames)) {
            predicates.push(
                Predicate.create('MaterialPool.MaterialPoolMaterial', 'any',
                    'Material.Animal.AnimalName', 'in', filter.animalNames)
            );
        }

        if (notEmpty(filter.genotypeMaleCombos)) {             
            if (filter.genotypeMaleAnd) {                
                const comboarray = [];               
                for (const genotypeMaleCombo of filter.genotypeMaleCombos) {
                    const maleCombo = [genotypeMaleCombo];   
                    const subpredicate = Predicate.and([
                        Predicate.create('Material.Animal.Genotype', 'any', buildGenotypeCombosFilter(maleCombo)),
                        Predicate.create('Material.Animal.cv_Sex.Sex', 'eq', 'Male')
                    ]);
                    comboarray.push(Predicate.create('MaterialPool.MaterialPoolMaterial', 'any',
                        subpredicate));
                }              
                predicates.push(Predicate.and(comboarray));

            } else { 
                const subpredicate = Predicate.and([
                    Predicate.create('Material.Animal.Genotype', 'any', buildGenotypeCombosFilter(filter.genotypeMaleCombos)),
                    Predicate.create('Material.Animal.cv_Sex.Sex', 'eq', 'Male')
                ]);
                predicates.push(Predicate.create(
                    'MaterialPool.MaterialPoolMaterial', 'any', subpredicate
                ));              
            }
        }

        if (notEmpty(filter.genotypeFemaleCombos)) {            
            if (filter.genotypeFemaleAnd) {
                const comboarray = [];               
                for (const genotypeFemaleCombo of filter.genotypeFemaleCombos) {
                    const femaleCombo = [genotypeFemaleCombo];
                    const subpredicate = Predicate.and([
                        Predicate.create('Material.Animal.Genotype', 'any', buildGenotypeCombosFilter(femaleCombo)),
                        Predicate.create('Material.Animal.cv_Sex.Sex', 'eq', 'Female')
                    ]);
                    comboarray.push(Predicate.create('MaterialPool.MaterialPoolMaterial', 'any',
                        subpredicate));
                }                              
                predicates.push(Predicate.and(comboarray));

            } else {
                const subpredicate = Predicate.and([
                    Predicate.create('Material.Animal.Genotype', 'any', buildGenotypeCombosFilter(filter.genotypeFemaleCombos)),
                    Predicate.create('Material.Animal.cv_Sex.Sex', 'eq', 'Female')
                ]);
                predicates.push(Predicate.create(
                    'MaterialPool.MaterialPoolMaterial', 'any', subpredicate
                ));
            }
        }

        if (notEmpty(filter.constructs)) {
            const constructKeys = filter.constructs.map((construct: any) => {
                return construct.ConstructKey;
            });

            const subPredicate = Predicate.create(
                'Material.Sample.SampleConstruct', 'any',
                'C_Construct_key', 'in', constructKeys
            );

            predicates.push(Predicate.create(
                'MaterialPool.MaterialPoolMaterial', 'any',
                subPredicate
            ));
        }

        // handle workspace filters
        if ('animal-filter' in filter) {
            predicates.push(
                Predicate.create('MaterialPool.MaterialPoolMaterial', 'any',
                    'C_Material_key', 'in',
                    filter['animal-filter'])
            );
        }

        return predicates;
    }

    getMatingDetails(materialPoolKey: number): Promise<any> {
        const expands = [
            'Line.cv_Taxon',
            'MaterialPool.JobMaterialPool.Job',
            'MaterialPool.MaterialPoolMaterial.Material.Animal.cv_Sex',
            'MaterialPool.MaterialPoolMaterial.Material.cv_MaterialType',
            'MaterialPool.MaterialPoolMaterial.Material.Sample',
            'MaterialPool.MaterialPoolMaterial.Material.Animal.Genotype'
        ];
        
        return this.getMating(materialPoolKey, expands);
    }

    getMating(materialPoolKey: number, expands?: string[]): Promise<any> {
        let query = EntityQuery.from(this.ENTITY_TYPE)
            .where('C_MaterialPool_key', '==', materialPoolKey);

        if (notEmpty(expands)) {
            query = query.expand(expands.join(','));
        }

        return this.dataManager.getQueryResults(query).then((results) => {
            return notEmpty(results) ? results[0] : null;
        });
    }


    getMatingCharacteristics(matingKey: number): Promise<any[]> {
        const expandClauses = [
            'MatingCharacteristic.cv_DataType',
            'MatingCharacteristic.EnumerationClass.EnumerationItem'
        ];

        const query = EntityQuery.from('MatingCharacteristicInstances')
            .expand(expandClauses.join(','))
            .where('C_MaterialPool_key', '==', matingKey)
            .orderBy('MatingCharacteristic.SortOrder');

        return this.dataManager.getQueryResults(query);
    }

    getMatingTasks(matingKey: number, extraExpands?: string[]): Promise<any[]> {
        const predicates = [
            new Predicate('TaskMaterialPool', 'any', 'C_MaterialPool_key', '==', matingKey),
            new Predicate('WorkflowTask.cv_TaskType.TaskType', 'eq', '"Mating"')
        ];

        const query = EntityQuery.from('TaskInstances')
            .where(Predicate.and(predicates))
            .orderBy('ProtocolTask.SortOrder');

        let expandClauses = [
            'WorkflowTask',
            'ProtocolTask.Protocol',
            'ProtocolInstance.Protocol',
            'TaskInput.Input',
            'TaskMaterialPool.MaterialPool',
            'TaskMaterialPool.TaskInstance.WorkflowTask.cv_TaskType'
        ];

        if (notEmpty(extraExpands)) {
            expandClauses = expandClauses.concat(extraExpands);
        }

        let tasks: any[] = [];
        return this.dataManager.returnQueryResults(query).then((results) => {
            tasks = results;
            const promises: Promise<any>[] = [
                this.vocabularyService.ensureCVLoaded('cv_TimeUnits'),
                this.vocabularyService.ensureCVLoaded('cv_TimeRelations'),
                this.vocabularyService.ensureCVLoaded('cv_DataTypes'),
                this.vocabularyService.ensureCVLoaded('cv_TaskTypes')
            ];

            return Promise.all(promises);
        }).then(() => {
            return this.dataManager.ensureRelationships(tasks, expandClauses);
        }).then(() => {
            return tasks;
        });
    }

    createMatingCharacteristics(mating: any): Promise<any[]> {
        const expandClauses = [
            'cv_DataType',
            'EnumerationClass.EnumerationItem'
        ];

        const predicates: Predicate[] = [];
        predicates.push(Predicate.create('IsActive', '==', true));
        predicates.push(Predicate.create('C_MatingType_key', '==', mating.C_MatingType_key));

        const query = EntityQuery.from('MatingCharacteristics')
            .expand(expandClauses.join(','))
            .where(Predicate.and(predicates))
            .orderBy('SortOrder');

        return this.dataManager.executeQuery(query).then((data) => {
            const characteristicInstances: any[] = [];

            const characteristics = data.results as any[];
            for (const characteristic of characteristics) {
                const initialValues = {
                    C_MatingCharacteristic_key: characteristic.C_MatingCharacteristic_key,
                    C_MaterialPool_key: mating.C_MaterialPool_key
                };

                const characteristicInstance = this.dataManager.createEntity(
                    'MatingCharacteristicInstance', initialValues
                );
                characteristicInstances.push(characteristicInstance);
            }
            return characteristicInstances;
        }).catch(this.dataManager.queryFailed) as Promise<any[]>;
    }


    createMating(): any {
        const initialValues = { DateCreated: new Date() };
        return this.dataManager.createEntity(this.ENTITY_NAME, initialValues);
    }


    createTaskMating(
        matingKey: number,
        taskInstanceKey: number,
        sequence: number
    ): Promise<any> {
        const initialValues = {
            C_MaterialPool_key: matingKey,
            C_TaskInstance_key: taskInstanceKey,
            Sequence: sequence
        };

        return this.dataManager.createEntity('TaskMaterialPool', initialValues);
    }

    createBirth(mating: any): any {
        const entityName = 'Birth';
        const manager = this.dataManager.getManager();

        const objType: any = manager.metadataStore.getEntityType(entityName);
        // Cannot use superclass method because setting custom property
        objType.setProperties({
            autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
            C_MaterialPool_key: mating.C_MaterialPool_key
        });

        const initialValues = { DateCreated: new Date() };
        return manager.createEntity(entityName, initialValues);
    }

    createPlugDate(mating: any): any {
        const entityName = 'MatingPlugDate';
        const initialValues = {
            C_MatingMaterialPool_key: mating.C_MaterialPool_key,
            DatePlug: new Date(),
        };
        const plugDateEntity = this.dataManager.createEntity(entityName, initialValues);
        mating.MatingPlugDate.push(plugDateEntity);

        return plugDateEntity;
    }

    deletePlugDate(plugDate: any): any {
        plugDate.entityAspect.setDeleted();
    }

    cancelMating(mating: any) {
        if (!mating) {
            return;
        }

        if (mating.C_MaterialPool_key > 0) {
            this._cancelMatingEdits(mating);
        } else {
            this._cancelNewMating(mating);
        }
    }

    private _cancelNewMating(mating: any) {
        try {
            this._cancelMatingEdits(mating);
        } catch (error) {
            console.error('Error cancelling new mating: ' + error);
        }
    }

    private _cancelMatingEdits(mating: any) {
        const taskMaterialPools = this.dataManager.rejectChangesToEntityByFilter(
            'TaskMaterialPool', (item: any) => {
                return item.C_MaterialPool_key === mating.C_MaterialPool_key;
            }
        );
        for (const taskMaterialPool of taskMaterialPools) {
            const taskInstances = this.dataManager.rejectChangesToEntityByFilter(
                'TaskInstance', (item: any) => {
                    return item.C_TaskInstance_key === taskMaterialPool.C_TaskInstance_key;
                }
            );
            for (const taskInstance of taskInstances) {
                this.dataManager.rejectChangesToEntityByFilter(
                    'TaskInput', (item: any) => {
                        return item.C_TaskInstance_key === taskInstance.C_TaskInstance_key;
                    }
                );

            }
        }
        // also reject changes to materials in this pool
        if (mating.MaterialPool) {
            const materialPoolMaterials = mating.MaterialPool.MaterialPoolMaterial;
            for (const materialPoolMaterial of materialPoolMaterials) {
                if (materialPoolMaterial.Material) {
                    if (materialPoolMaterial.Material.Animal) {
                        const animal = materialPoolMaterial.Material.Animal;
                        this.animalService.cancelAnimal(animal);
                    } else if (materialPoolMaterial.Material.Sample) {
                        const sample = materialPoolMaterial.Material.Sample;
                        this.sampleService.cancelSample(sample);
                    }
                }
            }

            // Reject changes to the MaterialPoolMaterials affected when 
            // animals were removed from existing matings before being added
            for (const materialPoolMaterial of materialPoolMaterials) {
                this.dataManager.rejectChangesToEntityByFilter(
                    'MaterialPoolMaterial', (item: any) => {
                        return item.C_Material_key === materialPoolMaterial.C_Material_key;
                    }
                );
            }
        }

        this.dataManager.rejectChangesToEntityByFilter(
            'JobMaterialPool', (item: any) => {
                return item.C_MaterialPool_key === mating.C_MaterialPool_key;
            }
        );

        this.dataManager.rejectChangesToEntityByFilter(
            'MatingCharacteristicInstance', (item: any) => {
                return item.C_MaterialPool_key === mating.C_MaterialPool_key;
            }
        );

        this.dataManager.rejectChangesToEntityByFilter(
            'MaterialLocation', (item: any) => {
                return item.C_MaterialPool_key === mating.C_MaterialPool_key;
            }
        );

        this.dataManager.rejectChangesToEntityByFilter(
            'MaterialPoolMaterial', (item: any) => {
                return item.C_MaterialPool_key === mating.C_MaterialPool_key;
            }
        );

        this.dataManager.rejectChangesToEntityByFilter(
            'MaterialPool', (item: any) => {
                return item.C_MaterialPool_key === mating.C_MaterialPool_key;
            }
        );

        this.dataManager.rejectChangesToEntityByFilter(
            'MatingPlugDate', (item: any) => {
                return item.C_MatingMaterialPool_key === mating.C_MaterialPool_key;
            }
        );

        this.dataManager.rejectEntityAndRelatedPropertyChanges(mating);
    }

    deleteMatingCharacteristic(matingCharacteristic: any) {
        this.dataManager.deleteEntity(matingCharacteristic);
    }

    deleteMating(mating: any) {
        while (mating.MatingCharacteristicInstance.length > 0) {
            this.dataManager.deleteEntity(mating.MatingCharacteristicInstance[0]);
        }

        while (mating.JobMaterialPool.length > 0) {
            this.dataManager.deleteEntity(mating.JobMaterialPool[0]);
        }

        if (mating.MaterialPool) {
            this.dataManager.deleteEntity(mating.MaterialPool);
        }

        while (notEmpty(mating.MaterialPool.TaskMaterialPool)) {
            const taskMaterialPool = mating.MaterialPool.TaskMaterialPool[0];
            const task = taskMaterialPool.TaskInstance;

            this.dataManager.deleteEntity(taskMaterialPool);

            while (notEmpty(task.TaskInput)) {
                this.dataManager.deleteEntity(task.TaskInput[0]);
            }

            while (notEmpty(task.TaskOutputSet)) {

                const outputSet = task.TaskOutputSet[0];
                while (notEmpty(outputSet.TaskOutput)) {
                    this.dataManager.deleteEntity(outputSet.TaskOutput[0]);
                }

                this.dataManager.deleteEntity(task.TaskOutputSet[0]);
            }

            while (notEmpty(task.TaskMaterial)) {
                this.dataManager.deleteEntity(task.TaskMaterial[0]);
            }

            this.dataManager.detachEntity(task);
        }

        this.dataManager.deleteEntity(mating);
    }

    ensureTasksLoaded(matings: any[]): Promise<any[]> {
        const expands = [
            'MaterialPool.TaskMaterialPool.TaskInstance.ProtocolInstance',
            'MaterialPool.TaskMaterialPool.TaskInstance.ProtocolTask',
        ];
        return this.dataManager.ensureRelationships(matings, expands);
    }

    ensureBirthMaterialsLoaded(births: any[]): Promise<any[]> {
        const expands = [
            'BirthMaterial',
        ];
        return this.dataManager.ensureRelationships(births, expands);
    }

    async ensureVisibleColumnsDataLoaded(matings: any[], visibleColumns: string[]): Promise<void> {
        const expands = this.generateExpandsFromVisibleColumns(matings[0], visibleColumns);
        const isContainGenotypeSortable = intersection(visibleColumns, ['SireGenotypeSortable', 'DamGenotypeSortable']).length > 0;
        if (isContainGenotypeSortable) {
            await this.vocabularyService.getCV('cv_Sexes');
        }
        return this.dataManager.ensureRelationships(matings, expands);
    }
}

import { WebApiService } from './../services/web-api.service';
import { Injectable } from '@angular/core';
import {
    EntityQuery,
    FilterQueryOp,
    Predicate,
    QueryResult
} from 'breeze-client';
import {
    Entity as InternalEntity,
    Lot,
    SampleOrder,
} from '@common/types';
import { notEmpty } from '../common/util';
import { getDateRangePredicates } from '../services/queries';

import { DataManagerService } from '../services/data-manager.service';
import { QueryDef } from '../services/query-def';
import { BaseEntityService } from '../services/base-entity.service';
import { NamingService } from '../services/naming.service';
import { FeatureFlagService } from '../services/feature-flags.service';
import { OrderStateService } from './order-state.service';

@Injectable()
export class OrderService extends BaseEntityService {

    constructor(
        private dataManager: DataManagerService,
        private webApiService: WebApiService,
        private namingService: NamingService,
        private featureFlagService: FeatureFlagService,
        private orderStateService: OrderStateService
    ) {
        super();
    }

    getAllOrders(): Promise<any[]> {
        const query = EntityQuery.from('Orders');

        return this.dataManager.getQueryResults(query);
    }
    
    getOrders(queryDef: QueryDef): Promise<QueryResult> {
        let query = this.buildDefaultQuery('Orders', queryDef);

        this.ensureDefExpanded(queryDef, 'cv_MaterialOrigin');
        this.ensureDefExpanded(queryDef, 'cv_QuarantineFacility');
        this.ensureDefExpanded(queryDef, 'AnimalOrder');
        this.ensureDefExpanded(queryDef, 'cv_Taxon');
        this.ensureDefExpanded(queryDef, 'Job');
        this.ensureDefExpanded(queryDef, 'Institution');
        this.ensureDefExpanded(queryDef, 'Site');
        this.ensureDefExpanded(queryDef, 'SampleOrder');
        this.ensureDefExpanded(queryDef, 'OrderLocation.LocationPosition');
        this.ensureDefExpanded(queryDef, 'JobOrder');
        this.ensureDefExpanded(queryDef, 'JobOrder.Job');
        query = query.expand(queryDef.expands.join(','));

        let predicates: Predicate[] = [];
        if (queryDef.filter) {
            predicates = predicates.concat(this.buildPredicates(queryDef.filter));
        }

        if (notEmpty(predicates)) {
            query = query.where(Predicate.and(predicates));
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    buildPredicates(filter: any): Predicate[] {
        let predicates: Predicate[] = [];
        if (!filter) {
            return predicates;
        }
        
        if (notEmpty(filter.jobs)) {
            const jobKeys = filter.jobs.map((job: any) => {
                return job.JobKey;
            });
            predicates.push(Predicate.create('JobOrder', 'any', 'C_Job_key', 'in', jobKeys));
        }
        if (notEmpty(filter.C_Institution_keys)) {
            predicates.push(Predicate.create('C_Institution_key', 'in', filter.C_Institution_keys));
        }
        if (notEmpty(filter.C_MaterialOrigin_key)) {
            predicates.push(Predicate.create('C_MaterialOrigin_key', 'eq', filter.C_MaterialOrigin_key));
        }
        if (filter.InvoiceNumber) {
            predicates.push(Predicate.create('tolower(InvoiceNumber)', FilterQueryOp.Contains, { value: filter.InvoiceNumber.toLowerCase() }));
        }
        if (filter.VendorOrderNumber) {
            predicates.push(Predicate.create('tolower(VendorOrderNumber)', FilterQueryOp.Contains, { value: filter.VendorOrderNumber.toLowerCase() }));
        }
        if (filter.AnimalCount) {
            predicates.push(Predicate.create('AnimalCount', 'eq', filter.AnimalCount));
        }
        if (notEmpty(filter.C_Taxon_keys)) {
            predicates.push(Predicate.create('C_Taxon_key', 'in', filter.C_Taxon_keys));
        }
        if (filter.Location) {
            predicates.push(Predicate.create('CurrentLocationPath', FilterQueryOp.Contains, { value: filter.Location }));
        }
        // Waiting for more information about Lots grid
        // if (filter.LotNumber) {
        //     predicates.push(Predicate.create('SampleOrder.LotNumber',
        //                                      FilterQueryOp.Contains, { value: filter.LotNumber }));
        // }
        if (filter.C_MaterialType_key) {
            predicates.push(Predicate.create('C_MaterialType_key', '==',
                                             filter.C_MaterialType_key));
        }
        if (filter.OrderID) {
            predicates.push(Predicate.create('OrderID', FilterQueryOp.Contains, { value: filter.OrderID } ));
        }
        if (notEmpty(filter.C_ShipmentAnimalCondition_keys)) {
            predicates.push(Predicate.create(
                'AnimalOrder', 'any', 'C_ShipmentAnimalCondition_key', 'in', filter.C_ShipmentAnimalCondition_keys
            ));
        }
        if (notEmpty(filter.C_ShipmentContainerCondition_keys)) {
            predicates.push(Predicate.create(
                'AnimalOrder', 'any', 'C_ShipmentContainerCondition_key', 'in', filter.C_ShipmentContainerCondition_keys
            ));
        }
        if (notEmpty(filter.C_QuarantineFacility_keys)) {
            predicates.push(Predicate.create('C_QuarantineFacility_key', 'in', filter.C_QuarantineFacility_keys));
        }
        if (filter.VendorRoomId) {
            predicates.push(Predicate.create('VendorRoomId', FilterQueryOp.Contains, { value: filter.VendorRoomId }));
        }
        if (filter.DateReceivedStart || filter.DateReceivedEnd) {
            const datePredicates: Predicate[] = getDateRangePredicates(
                'DateReceived', filter.DateReceivedStart, filter.DateReceivedEnd
            );
            if (notEmpty(datePredicates)) {
                predicates = predicates.concat(datePredicates);
            }
        }

        if (filter.DateProjectedReceiptStart || filter.DateProjectedReceiptEnd) {
            const datePredicates: Predicate[] = getDateRangePredicates(
                'DateProjectedReceipt', filter.DateProjectedReceiptStart, filter.DateProjectedReceiptEnd
            );
            if (notEmpty(datePredicates)) {
                predicates = predicates.concat(datePredicates);
            }
        }
        if (filter.Cites) {
            predicates.push(Predicate.create('tolower(Cites)', FilterQueryOp.Contains, { value: filter.Cites.toLowerCase() }));
        }
        if (filter.AnimalOrderCount) {
            predicates.push(Predicate.create('AnimalOrder', 'any', 'AnimalCount', 'eq', filter.AnimalOrderCount));
        }
        if (filter.C_Sex_key) {
            predicates.push(Predicate.create('AnimalOrder', 'any', 'C_Sex_key', 'eq', filter.C_Sex_key));
        }
        if (notEmpty(filter.C_Taxon_keys)) {
            predicates.push(Predicate.create('AnimalOrder', 'any', 'C_Taxon_key', 'in', filter.C_Taxon_keys));
        }
        if (notEmpty(filter.lines)) {
            const lineKeys = filter.lines.map((line: any) => {
                return line.LineKey;
            });
            predicates.push(Predicate.create(
                'AnimalOrder', 'any', 'C_Line_key', 'in', lineKeys
            ));
        }

        return predicates;
    }

    getOrder(orderKey: number, expands?: string[]): Promise<any> {
        const query = EntityQuery.from('Orders')
            .where('C_Order_key', '==', orderKey);

        return this.dataManager.returnSingleQueryResult(query);
    }

    getSampleOrder(orderKey: number, expands?: string[]): Promise<any> {
        const query = EntityQuery.from('SampleOrders')
            .where('C_Order_key', '==', orderKey);

        return this.dataManager.returnSingleQueryResult(query);
    }

    getAnimalOrder(orderKey: number, expands?: string[]): Promise<any> {
        const query = EntityQuery.from('AnimalOrders')
            .where('C_Order_key', '==', orderKey);

        return this.dataManager.returnQueryResults(query);
    }

    getInstitutions(preferLocal = false): Promise<any> {
        const query = EntityQuery.from('Institutions')
            .expand('Site.ContactPerson');
        return this.dataManager.returnQueryResults(query, preferLocal);
    }

    getInstitutionSites(institutionKey: number): Promise<any> {
        const query = EntityQuery.from('Sites')
            .expand('ContactPerson')
            .where('C_Institution_key', '==', institutionKey);

        return this.dataManager.returnQueryResults(query);
    }

    getSites(preferLocal = false): Promise<any> {
        const query = EntityQuery.from('Sites');
        return this.dataManager.returnQueryResults(query, preferLocal);
    }

    getLots(pkName: string, pkValue: number): Promise<any> {
        const query = EntityQuery.from('Lots')
            .where(pkName, '==', pkValue);

        return this.dataManager.returnQueryResults(query);
    }

    getLotByKey(lotKey: number, expands?: string[]): Promise<any> {
        const query = EntityQuery.from('Lots')
            .where('C_Lot_key', '==', lotKey);

        return this.dataManager.returnSingleQueryResult(query);
    }

    createOrder(): any {
        const initialValues: any = {
            DateCreated: new Date()
        };
        return this.dataManager.createEntity('Order', initialValues);
    }

    createSampleOrder(orderKey: any) {
        const initialValues: any = {
            C_Order_key: orderKey,
            DateCreated: new Date()
        };
        return this.dataManager.createEntity('SampleOrder', initialValues);
    }

    createAnimalOrder(orderKey: any) {
        const initialValues: any = {
            C_Order_key: orderKey,
            DateCreated: new Date()
        };
        return this.dataManager.createEntity('AnimalOrder', initialValues);
    }

    deleteSampleOrder(sampleOrder: InternalEntity<SampleOrder>) {
        while (sampleOrder.Lot.length > 0) {
            this.deleteLot(sampleOrder.Lot[0]);
        }
        this.dataManager.deleteEntity(sampleOrder);
    }

    deleteAnimalOrder(animalOrder: any) {
        this.dataManager.deleteEntity(animalOrder);
    }

    createLot(pkName: string, pkValue: number): any {
        const initialValues: any = {
            [pkName]: pkValue
        };
        return this.dataManager.createEntity('Lot', initialValues);
    }

    deleteLot(lot: Lot) {
        this.dataManager.deleteEntity(lot);
    }

    cancelOrder(order: any) {
      this.orderStateService.discardChanges(order);
    }

    createOrderLocation(initialValues: any): any {
        return this.dataManager.createEntity('OrderLocation', initialValues);
    }
    
    
    deleteOrderLocation(orderLocation: any) {
        this.dataManager.deleteEntity(orderLocation);
    }

    getOrderPrefixField(): Promise<string> {
        return this.namingService.getNameFormat('Order').then((orderNameFormat: any) => {
            let prefixField = '';
            if (orderNameFormat && orderNameFormat.cv_OrderPrefixField) {
                prefixField = orderNameFormat.cv_OrderPrefixField.OrderPrefixField;
            }

            return Promise.resolve(prefixField);
        });
    }

    autoGenerateOrderID(order: any): Promise<string> {
        const url = 'api/namegenerator/generateOrderID';
        const request = {
            C_Order_key: order.C_Order_key,
            OrderID: order.OrderID,
            CurrentLocationPath: order.OrderLocation?.[0]?.LocationPosition?.PositionName ?? null
        };

        return this.webApiService.postApi(url, request).then((response: any) => {
            return response.data;
        });
    }

    getIsGLPFlag(): boolean {
        const flag = this.featureFlagService.getFlag("IsGLP");
        return flag && (flag.Value.toLowerCase() === 'true') && (flag.IsActive === true);
    }

    async validateOrderNamingField(order: any): Promise<string> {
        const orderPrefixField = await this.getOrderPrefixField();

        if (orderPrefixField === 'Location') {
            const isLocationValid = Array.isArray(order.OrderLocation) && order.OrderLocation[0]?.C_LocationPosition_key;
            return isLocationValid ? '' : 'Location';
        }

        return '';
    }

    async ensureVisibleColumnsDataLoaded(orders: any[], visibleColumns: string[]): Promise<void> {
        const expands = this.generateExpandsFromVisibleColumns(orders[0], visibleColumns);
        return this.dataManager.ensureRelationships(orders, expands);
    }
}

import { Pipe, PipeTransform } from "@angular/core";
import { getSafeProp } from "@common/util";

@Pipe({
  name: "getPropertyValue",
})
export class GetPropertyValuePipe implements PipeTransform {
    transform(obj: any, propertyPath: string): any {
        if (!obj || !propertyPath) {
            return null;
        }

        return getSafeProp(obj, propertyPath) || null;
    }
}

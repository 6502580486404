import type { Material } from './material.interface';

export interface MaterialExternalSync {
    C_MaterialExternalSync_key: number;
    C_Material_key: number;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    DateSync: Date;
    Material: Material;
    ModifiedBy: string;
}

import type { Resource } from './resource.interface';

export interface cv_ResourceType {
    C_ResourceType_key: number;
    C_VocabularySource_key: number | null;
    C_Workgroup_key: number;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    ElementID: string;
    IsActive: boolean;
    IsDefault: boolean;
    IsDeprecated: boolean;
    ModifiedBy: string;
    Resource: Resource[];
    ResourceType: string;
    SortOrder: number | null;
    Version: number;
}

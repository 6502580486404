import {
    Component,
    Input,
    OnChanges,
    OnInit,
} from '@angular/core';

import { IoTService } from './iot.service';
import { DroppableEvent } from '../common/droppable-event';

import { notEmpty } from '../common/util';

@Component({
    selector: 'device-multiselect',
    templateUrl: './device-multiselect.component.html',
    styles: [`
        .table {
            margin-bottom: 0.25em;
        }
    `]
})
export class DeviceMultiselectComponent implements OnInit, OnChanges {
    @Input() location: any;
    @Input() materialPool: any;
    @Input() facetPrivilege: string;
    @Input() disabled: boolean;

    devices: any[];

    constructor(
        private iotService: IoTService,
    ) { }

    ngOnInit() {
        this.initialize();
    }

    ngOnChanges(changes: any) {
        if (changes.location) {
            this.initialize();
        }

        if (changes.materialPool) {
            this.initialize();
        }
    }

    initialize() {
        if (this.location) {
            this.devices = this.location.Device;
        }

        if (this.materialPool) {
            this.devices = this.materialPool.Device;
        }
    }

    removeDevice(device: any) {
        device.C_LocationPosition_key = null;
        device.C_MaterialPool_key = null;
    }

    selectDevice(device: any) {
        if (this.facetPrivilege !== 'ReadWrite') {
            return;
        }

        if (this.disabled) {
            return;
        }
        
        if (this.location) {
            device.C_LocationPosition_key = this.location.C_LocationPosition_key;
            device.C_MaterialPool_key = null;
        }

        if (this.materialPool) {
            device.C_LocationPosition_key = null;
            device.C_MaterialPool_key = this.materialPool.C_MaterialPool_key;
        }
    }

    onDropDevice(event: DroppableEvent) {
        if (notEmpty(this.iotService.draggedDevices)) {
            for (const device of this.iotService.draggedDevices) {
                this.selectDevice(device);
            }
            
            this.iotService.draggedDevices = [];
        }
    }

    searchDevices = (term: string): Promise<any> => {
        return this.iotService.searchDevices(term);
    }

    deviceIdFormatter = (value: any) => {
        return value.C_Device_key;
    }
}

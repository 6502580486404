import { map } from 'rxjs/operators';
import { BirthVocabService } from './../birth-vocab.service';
import { BirthService } from './../birth.service';
import { QueryDef } from './../../services/query-def';
import { 
    BulkEditOptions, 
    BulkEditSection
} from '../../common/facet/models';
import {
    Component,
    Input,
    OnInit,
    TemplateRef,
    ViewChild,
    AfterViewInit,
    ViewChildren,
} from '@angular/core';

import { notEmpty } from '../../common/util';

import { FacetLoadingStateService } from '../../common/facet';
import { NgModel } from '@angular/forms';
import { dateControlValidator } from '@common/util/date-control.validator';

/**
 * Shared component and configuration templates
 * for BulkAdd and BulkEdit tables
 * 
 * 
 */
@Component({
    selector: 'birth-bulk-templates',
    templateUrl: './birth-bulk-templates.component.html'
})
export class BirthBulkTemplatesComponent implements OnInit, AfterViewInit {
    @ViewChildren('dateControl') dateControls: NgModel[];
    @Input() births: any[];

    // bulk edit input templates
    @ViewChild('birthIdTmpl') birthIdTmpl: TemplateRef<any>;
    @ViewChild('matingIdTmpl') matingIdTmpl: TemplateRef<any>;
    @ViewChild('housingIdTmpl') housingIdTmpl: TemplateRef<any>;
    @ViewChild('statusTmpl') statusTmpl: TemplateRef<any>;
    @ViewChild('birthDateTmpl') birthDateTmpl: TemplateRef<any>;
    @ViewChild('weanDateTmpl') weanDateTmpl: TemplateRef<any>;

    // vocabs
    birthStatuses: any[];

    readonly COMPONENT_LOG_TAG = 'birth-bulk-edit';

    bulkOptions: BulkEditOptions;
    BulkEditSection = BulkEditSection;

    constructor(
        private birthService: BirthService,
        private birthVocabService: BirthVocabService,
        private facetLoadingState: FacetLoadingStateService,
    ) {
    }

    // lifecycle
    ngOnInit() {
        this.initialize();
        this.getData();
    }

    /**
     * Configuration with TemplateRefs can only be assigned
     *   after "ngAfterViewInit".
     * Otherwise they will be undefined.
     */
    ngAfterViewInit() {
        // assign all the BulkAdd and BulkEdit configuration options
        this.bulkOptions = {
            itemTypeLabel: "Birth",
            itemTypeLabelPlural: "Births",
            clearForm: false,
            fields: [
                {
                    label: "Birth ID",
                    modelPath: 'BirthID',
                    template: this.birthIdTmpl,
                    hideFromAddScreen: true,
                    hideFromEditHeader: true
                },
                {
                    label: 'Mating ID',
                    modelPath: 'Mating.MatingID',
                    template: this.matingIdTmpl,
                    hideFromAddScreen: true,
                    hideFromEditHeader: true
                },
                {
                    label: 'Housing ID',
                    modelPath: 'Mating.MaterialPool.MaterialPoolID',
                    template: this.housingIdTmpl,
                    hideFromAddScreen: true,
                    hideFromEditHeader: true
                },
                {
                    label: 'Status',
                    modelPath: 'C_BirthStatus_key',
                    template: this.statusTmpl
                },
                {
                    label: 'Birth Date',
                    labelTooltip: `
                        Updates to this birth date do not change the birth dates 
                        of animals in the birth. You can edit animal attributes 
                        by viewing birth details.
                    `,
                    modelPath: 'DateBorn',
                    template: this.birthDateTmpl
                },
                {
                    label: 'Wean Date',
                    modelPath: 'DateWean',
                    template: this.weanDateTmpl
                }
            ]
        };
    }

    initialize() {

         // Copy the input so we don't touch the grid data
        this.births = this.births.slice();
    }

    getData() {
        this.facetLoadingState.changeLoadingState(true);

        return this.getDetails().then(() => {
            return this.getCVs();
        }).then(() => {
            this.facetLoadingState.changeLoadingState(false);
        }).catch((error) => {
            this.facetLoadingState.changeLoadingState(false);
            throw error;
        });
    }

    getDetails(): Promise<any[]> {
        if (notEmpty(this.births)) {
            const queryDef: QueryDef = {
                page: 0,
                size: this.births.length,
                filter: {
                    birthKeys: this.births.map((birth) => {
                        return birth.C_Birth_key;
                    })
                }
            };

            return this.birthService.getBirths(queryDef).then((result) => {
                return this.births;
            });
        }

        return Promise.resolve(this.births);
    }

    getCVs(): Promise<any> {
        const p1: Promise<any> = this.birthVocabService.birthStatuses$
            .pipe(map((data) => {
                this.birthStatuses = data;
            })).toPromise();

        return Promise.all([p1]);
    }


    // <select> formatters
    birthStatusKeyFormatter = (value: any) => {
        return value.C_BirthStatus_key;
    }
    birthStatusFormatter = (value: any) => {
        return value.BirthStatus;
    }

    validate() {
        return dateControlValidator(this.dateControls);
    }
}

import {
    Component,
    EventEmitter,
    Input,
    Output
} from '@angular/core';

import { SearchService } from '../search/search.service';


@Component({
    selector: 'sample-multiselect',
    template: `
<!-- Selected samples -->
<div *ngIf="model | notEmpty">
    <table class="table table-sm table-borderless table-nonfluid">
        <tbody>
            <tr *ngFor="let item of model; let i = index;">
                <td class="icon-cell">
                    <a (click)="removeItem(i)" title="Remove sample">
                        <i class="fa fa-remove remove-item"></i>
                    </a>
                </td>
                <td>
                    {{item.SampleName}}
                </td>
            </tr>
        </tbody>
    </table>
</div>

<!-- Sample typeahead -->
<climb-indirect-typeahead
        [search]="searchSamples"
        [resultFormatter]="sampleNameFormatter"
        placeholder="Sample name&hellip;"
        (selectItem)="selectSample($event)">
</climb-indirect-typeahead>
`,
    styles: [`
        .table {
            margin-bottom: 0.25em;
        }
    `]
})
export class SampleMultiselectComponent {
    @Input() model: any[];
    @Output() modelChange: EventEmitter<any> = new EventEmitter<any>();

    constructor(
        private searchService: SearchService,
    ) { }

    removeItem(index: number) {
        if (this.model && index >= 0 && index < this.model.length) {
            this.model.splice(index, 1);
        }
        this.modelChange.emit(this.model);
    }

    searchSamples = (term: string): Promise<any> => {
        return this.searchService.searchSamples(term)
            .then((results: any) => {
                return results.data;
            });
    }

    sampleNameFormatter = (value: any) => {
        return value.SampleName;
    }

    selectSample(sample: any) {
        if (this.model) {
            this.model.push(sample);
        } else {
            this.model = [sample];
        }
        this.modelChange.emit(this.model);
    }
}

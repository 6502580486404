<ng-template #cagecardmodal let-c="close">
    <cage-card-chooser [animals]="animalsInSelectedRow" [reportTypes]="cageCardReportTypes" (close)="c('Close')"></cage-card-chooser>
</ng-template>
<div class="detail-form-wrapper">
    <loading-overlay *ngIf="loading" [message]="loadingMessage"></loading-overlay>
    <div class="detail-header" *ngIf="!housingOpened">
        <h3>Census</h3>

        <pager-toolbar [itemName]="'census'"
                       [logTag]="COMPONENT_LOG_TAG"
                       [first]="pageState.isFirst"
                       [last]="pageState.isLast"
                       (previous)="previousClicked($event)"
                       (next)="nextClicked($event)"></pager-toolbar>

        <detail-toolbar [facetPrivilege]="facet.Privilege"
                        [logTag]="COMPONENT_LOG_TAG"
                        (exit)="exitClicked($event)">
            <div class="btn-group"
                 role="toolbar"
                 aria-label="Edit actions"
                 additional-groups
                 *ngIf="facet.Privilege === 'ReadWrite'">

                <button type="button"
                        class="btn btn-secondary"
                        (click)="showScanModal()">
                    <i class="fa fa-barcode" aria-hidden="true" title="Scan"></i>
                    Scan
                </button>

                <button type="button"
                        class="btn btn-secondary"
                        (click)="viewAuditReport()">
                    <i class="fa fa-flag" aria-hidden="true" title="Audit"></i>
                    <span class="gridster-d-sm-none gridster-d-md-inline-block">Audit</span>
                    <span class="sr-only">Audit</span>
                </button>

                <button type="button"
                        class="btn btn-secondary"
                        title="Print cage cards for selected housings"
                        [disabled]="selectedRows.length === 0"
                        (click)="openCageCardModal(cagecardmodal)">
                    <i class="fa fa-print" aria-hidden="true"></i>
                    <span class="gridster-d-sm-none gridster-d-md-inline-block">Cards</span>
                    <span class="sr-only">Print Cage Card</span>
                </button>
            </div>

        </detail-toolbar>
    </div>

    <div *ngIf="census | isDeleted"
         class="alert alert-warning"
         role="alert">
        <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
        This census has been deleted.
    </div>

    <form *ngIf="!(census | isDeleted) && !housingOpened"
          name="censusForm" id="censusForm"
          class="detail-form px-0 pb-0"
          #censusForm="ngForm">
        <fieldset [disabled]="facet.Privilege === 'ReadOnly'" class="mx-3">
            <div class="form-group row" *ngIf="census.C_Census_key > 0">
                <label class="col-md-2 col-form-label">ID</label>
                <div class="col-md-8">
                    <input type="text"
                           name="C_Census_key"
                           [(ngModel)]="census.C_Census_key"
                           [disabled]="true"
                           class="form-control input-medium" />
                </div>
            </div>

            <div class="form-group row">
                <label class="col-md-2 col-form-label">Status</label>
                <div class="col-md-8">
                    <active-vocab-select class="status-input"
                                         [(model)]="census.C_CensusStatus_key"
                                         [vocabChoices]="censusStatuses"
                                         [keyFormatter]="censusStatusKeyFormatter"
                                         [optionFormatter]="censusStatusFormatter"
                                         [required]="true"
                                         [defaultValue]="defaultCensusStatusKey"
                                         [loadDefaultValue]="true"></active-vocab-select>
                </div>
            </div>

            <div class="form-group row">
                <label class="col-md-2 col-form-label">Date</label>
                <div class="col-md-3">
                    <climb-ngb-date #dateControl="ngModel" name="Date" [(ngModel)]="census.Date" [required]="true"></climb-ngb-date>
                </div>
            </div>

            <div class="form-group row">
                <label class="col-md-2 col-form-label">Location</label>
                <div class="col-md-10 d-flex align-items-start flex-wrap">
                    <location-chooser *ngIf="census.LocationPosition === null"
                                      class="location-chooser-input mr-2"
                                      [(location)]="locationPosition"
                                      [required]="true"
                                      [readonly]="facet.Privilege === 'ReadOnly'"></location-chooser>

                    <location-path *ngIf="census.LocationPosition"
                                   class="pt-1"
                                   [(locationPositionKey)]="census.LocationPosition.C_LocationPosition_key"></location-path>
                    <button (click)="setLocation()"
                            class="btn btn-sm d-flex align-items-center"
                            [disabled]="census.LocationPosition !== null || locationPosition === null"
                            [ngClass]="census.LocationPosition === null && locationPosition !== null ? 'btn-primary' : 'disabled'">
                        <span>Confirm</span>
                        <i class="fa fa-info-circle ml-1" [title]="getTitle()"></i>
                    </button>

                </div>
            </div>

        </fieldset>

        <ng-container *ngIf="!housingOpened">
            <climb-facet-toolbar>
                <climb-export-dropdown #exportDropdown
                                       [options]="censusTableOptions.detailViewOptions"
                                       [dataService]="dataService"
                                       componentName="Census Detail">
                </climb-export-dropdown>

                <climb-column-select [columnDef]="dataTableColumns$ | async"
                                     (onChange)="dataTable.columnSelectChanged($event)">
                </climb-column-select>
            </climb-facet-toolbar>
            <climb-data-table #dataTable
                              [dataService]="dataService"
                              [options]="censusTableOptions.detailViewOptions"
                              [(gridStateJson)]="facet.GridState"
                              [(selectedRows)]="selectedRows"
                              (detailLinkClick)="detailLinkClick($event)"
                              (columnStateChanged)="dataTableColumns.next($event)"
                              (stateChange)="exportDropdown.tableState = $event">
            </climb-data-table>
        </ng-container>

    </form>

    <census-bulk-edit *ngIf="housingOpened"
                      class="h-100"
                      [facet]="facet"
                      [housing]="housing"
                      (exit)="exitClickd()"
                      (back)="backClicked()"
                      (updateSums)="updateSums($event)"></census-bulk-edit>
</div>

/**
 * Optional component elements to be used for
 *   content projection (I.e. transclusion) in multi-control-wrap
 */
import { Component } from '@angular/core';

@Component({
    selector: 'multi-control',
    template: '<ng-content></ng-content>'
})
export class MultiControlComponent { }

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AddWorkspaceModalService } from './add-workspace-modal.service';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ClimbCommonModule } from '../common/climb-common.module';
import { WorkspaceService } from './workspace.service';
import { AddWorkspaceComponent } from './components/add-workspace/add-workspace.component';
import { FacetSelectorComponent } from './components/facet-selector/facet-selector.component';

@NgModule({
    imports: [
        ClimbCommonModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule
    ],
    declarations: [
        AddWorkspaceComponent,
        FacetSelectorComponent,
    ],
    exports: [
        AddWorkspaceComponent,
        FacetSelectorComponent,
    ],
    
})
export class WorkspacesSharedModule {
    static forRoot(): ModuleWithProviders<WorkspacesSharedModule> {
        return {
          ngModule: WorkspacesSharedModule,
          providers: [
            WorkspaceService,
            AddWorkspaceModalService
          ]
        };
    }
}

import {
    Component,
    ChangeDetectionStrategy,
    Input,
    OnInit,
    HostBinding
} from '@angular/core';
import { caretRightFilled } from '@icons';

export type MenuItemVariant = 'accent' | 'neutral';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'button[climb-menu-item], a[climb-menu-item]',
    templateUrl: './menu-item.component.html',
    styleUrls: ['./menu-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuItemComponent implements OnInit {
    @Input()
    get variant(): MenuItemVariant {
        return this.menuItemVariant;
    }
    set variant(menuItemVariant: MenuItemVariant) {
        this.menuItemVariant = menuItemVariant;
        this.setClasses();
    }

    @Input()
    get selected(): boolean {
        return this.menuItemSelected;
    }
    set selected(menuItemSelected: boolean) {
        this.menuItemSelected = menuItemSelected;
        this.setClasses();
    }

    @HostBinding('class')
    classes: string[] = [];

    icons = { caretRightFilled };

    private menuItemVariant: MenuItemVariant = 'neutral';
    private menuItemSelected = false;

    ngOnInit(): void {
        this.setClasses();
    }

    setClasses(): void {
        this.classes = [];
        if (this.selected) {
            this.classes.push('selected');
        }
        this.classes.push(this.variant);
    }
}

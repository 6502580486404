import {
    Injectable,
} from '@angular/core';

import {
    NgbModal, NgbModalOptions,
} from '@ng-bootstrap/ng-bootstrap';
import { ScheduleEditModalComponent } from './schedule-edit-modal.component';
import { ReserveTimeModalComponent } from './reserve-time-modal.component';

/*
* Service view schedule modals
*/
@Injectable()
export class ScheduleModalService {

    constructor(
        private modalService: NgbModal
    ) {
        //
    }

    openScheduleEditModal(appointment: any, facet: any): Promise<any> {
        const modalOptions: NgbModalOptions = {
            backdrop: 'static',
            size: 'lg'
        };
        const modalRef = this.modalService.open(ScheduleEditModalComponent, modalOptions);
        const component = modalRef.componentInstance as ScheduleEditModalComponent;
        component.appointment = appointment;
        component.facet = facet;

        return modalRef.result;
    }

    openReserveTimeModal(scheduleNonTask: any, facet: any): Promise<any> {
        const modalRef = this.modalService.open(ReserveTimeModalComponent);
        const component = modalRef.componentInstance as ReserveTimeModalComponent;
        component.scheduleNonTask = scheduleNonTask;
        component.facet = facet;

        return modalRef.result;
    }
}

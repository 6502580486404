<table class="table table-bordered">
    <thead>
        <tr class="detail-grid-header"
            droppable
            [dropDataKey]="job.C_Job_key"
            [dropDisabled]="readonly"
            (drop)="onDropMaterialToJob()"
            [attr.title]="readonly ? '' : 'Drop animals here'">
            <th [attr.colspan]="readonly ? 10 : 11">
                <span class="float-right">
                    <a class="detail-grid-button"
                       (click)="pasteAnimalsIntoJob()"
                       *ngIf="!readonly">
                        <i data-automation-id="paste-animals-button"
                           class="fa fa-paste" 
                           title="Paste animals">
                        </i>
                    </a>
                    <a class="detail-grid-button"
                       (click)="copyAnimals()">
                        <i class="fa fa-copy" title="Copy selected animals"></i>
                    </a>
                </span>
                Animals
                <ng-container *ngIf="animalMaterials && animalMaterials.length > 0">
                    ({{animalMaterials.length}})
                    <a (click)="job.animalsExpanded = !job.animalsExpanded"
                       class="animals-toggle-link">
                        <i class="fa"
                           [ngClass]="job.animalsExpanded ? 'fa-chevron-up' : 'fa-chevron-down'"
                           [title]="job.animalsExpanded ? 'Hide Animals' : 'Show Animals'"
                           aria-hidden="true"></i>
                    </a>
                </ng-container>
            </th>
        </tr>
        <!-- closed -->
        <!-- NOT READY YET
            <ng-container *ngIf="job.AnimalSummary && job.AnimalSummary.length > 0 &&
                    !job.animalsExpanded">
            <tr class="detail-grid-header">
                <th>Count</th>
                <th>Cohort</th>
                <th>Sex</th>
                <th>{{'Line' | translate}}</th>
            </tr>
        </ng-container> -->
        <!-- open -->
        <tr *ngIf="(animalMaterials && animalMaterials.length > 0) &&
                    job.animalsExpanded"
            class="detail-grid-header">

            <!-- Select -->
            <th class="icon-cell">
                <input type="checkbox"
                       (click)="selectAllAnimals($event)"
                       title="Select/clear all" />
            </th>

            <!-- Remove -->
            <th *ngIf="!readonly">
                &nbsp;
            </th>

            <th>
                <a (click)="tableSort.toggleSort('Material.Identifier')"
                   class="header-sort-link"
                   title="Sort by ID">ID</a>
            </th>
            <th>
                <a (click)="tableSort.toggleSort('Material.Animal.AnimalNameSortable')"
                   class="header-sort-link"
                   title="Sort by name">Name</a>
            </th>
            <th>
                Genotypes
            </th>
            <th>
                <a (click)="tableSort.toggleSort('Material.Animal.cv_Sex.Sex')"
                   class="header-sort-link"
                   title="Sort by sex">Sex</a>
            </th>
            <th>
                <a (click)="tableSort.toggleSort('Material.Animal.cv_AnimalStatus.AnimalStatus')"
                   class="header-sort-link"
                   title="Sort by status">Status</a>
            </th>
            <th class="text-nowrap">
                Clinical
                <a (click)="showHideAllClinical()" class="clinical-toggle-link">
                    <i *ngIf="!showAllClinical"
                       class="fa fa-chevron-down"
                       title="Show all observations"></i>
                    <i *ngIf="showAllClinical"
                       class="fa fa-chevron-up"
                       title="Hide all observations"></i>
                </a>
            </th>
            <th>
                <a (click)="tableSort.toggleSort('Material.Animal.DateBorn')"
                   class="header-sort-link"
                   title="Sort by birth date">Birth Date</a>
            </th>
            <th>
                <a (click)="tableSort.toggleSort('Material.Line.LineName')"
                   class="header-sort-link"
                   title="Sort by {{'Line' | translate}}">{{'Line' | translate}}</a>
            </th>
            <th>
                Cohorts
            </th>
        </tr>
    </thead>
    <!-- closed -->
    <!-- NOT READY YET
        <tbody *ngIf="job.AnimalSummary && job.AnimalSummary.length > 0 &&
            !job.animalsExpanded">
        <tr *ngFor="let summary of job.AnimalSummary">
            <td>{{summary.count}}</td>
            <td>{{summary.item?.cohortName}}</td>
            <td>{{summary.item?.sex}}</td>
            <td>{{summary.item?.strain}}</td>
        </tr>
    </tbody> -->
    <!-- open -->
    <tbody *ngIf="job.animalsExpanded">
        <tr *ngFor="let jobMaterial of animalMaterials |
                orderBy:tableSort.propertyPath:tableSort.reverse;
                let animalIndex = index">
            <ng-container *ngIf="jobMaterial.Material?.Animal">

                <!-- Select -->
                <td class="icon-cell">
                    <input type="checkbox"
                           name="jobAnimalIsSelected{{animalIndex}}"
                           [(ngModel)]="jobMaterial.isSelected" />
                </td>

                <!-- Remove -->
                <td class="icon-cell"
                    *ngIf="!readonly">
                    <a (click)="removeJobMaterial(jobMaterial)">
                        <i class="fa fa-remove remove-item" title="Remove animal"></i>
                    </a>
                </td>

                <td>
                    <div style="cursor:move;"
                         data-drag-type="material"
                         draggable
                         (dragStart)="dragAnimalsStart()"
                         (dragStop)="dragAnimalsStop()"
                         id="{{jobMaterial?.Material?.C_Material_key}}">{{jobMaterial?.Material?.Identifier}}</div>
                </td>
                <td class="text-nowrap">
                    {{jobMaterial.Material?.Animal.AnimalName}}
                </td>
                <td>
                    <span *ngFor="let genotype of jobMaterial.Material?.Animal?.Genotype; let last = last">{{genotype.cv_GenotypeAssay?.GenotypeAssay}}&nbsp;{{genotype.cv_GenotypeSymbol?.GenotypeSymbol}}<span *ngIf="!last">, </span></span>
                </td>
                <td>
                    {{jobMaterial.Material?.Animal.cv_Sex?.Sex}}
                </td>
                <td>
                    <a (click)="getStatusHistory(jobMaterial)"
                       class="text-nowrap">
                        {{jobMaterial.Material?.Animal.cv_AnimalStatus?.AnimalStatus}}
                        <i *ngIf="!jobMaterial.statusExpanded"
                           class="fa fa-chevron-down"
                           title="Show history"></i>
                        <i *ngIf="jobMaterial.statusExpanded"
                           class="fa fa-chevron-up"
                           title="Hide history"></i>
                    </a>
                    <div *ngIf="jobMaterial.statusExpanded">
                        <p style="margin-top: 0.5em;"
                           *ngIf="!(jobMaterial.statusHistory | notEmpty)">
                            <em>No history.</em>
                        </p>
                        <table class="table table-sm"
                               *ngIf="jobMaterial.statusHistory | notEmpty">
                            <thead>
                                <tr>
                                    <th rowspan="2">Status</th>
                                    <th rowspan="2">Changed</th>
                                    <th colspan="2" class="text-center">Days Since</th>
                                </tr>
                                <tr>
                                    <th>Birth</th>
                                    <th>{{'Job' | translate}} Start</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let event of jobMaterial.statusHistory">
                                    <td>
                                        {{event.Label}}
                                    </td>
                                    <td>
                                        {{event.DateOccurred | formatShortDate}}
                                    </td>
                                    <td class="num-cell">
                                        {{event.DateOccurred | daysSince:jobMaterial.Material?.Animal.DateBorn}}
                                    </td>
                                    <td class="num-cell">
                                        {{event.DateOccurred | daysSince:job.DateStarted}}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </td>
                <td>
                    <a (click)="showHideClinical(jobMaterial)"
                       class="text-nowrap"
                       *ngIf="jobMaterial.clinicalHistory?.length > 0">
                        {{jobMaterial.clinicalHistory.length}}
                        <span *ngIf="jobMaterial.clinicalHistory.length > 1">observations</span>
                        <span *ngIf="jobMaterial.clinicalHistory.length === 1">observation</span>
                        <i *ngIf="!jobMaterial.clinicalExpanded"
                           class="fa fa-chevron-down"
                           title="Show observations"></i>
                        <i *ngIf="jobMaterial.clinicalExpanded"
                           class="fa fa-chevron-up"
                           title="Hide observations"></i>
                    </a>
                    <div *ngIf="jobMaterial.clinicalExpanded">
                        <table class="table table-sm" style="margin-top: 0.5em">
                            <tbody>
                                <tr *ngFor="let observation of jobMaterial.clinicalHistory">
                                    <td>
                                        {{observation.cv_ClinicalObservation?.ClinicalObservation}}
                                    </td>
                                    <td>
                                        {{observation.AnimalClinicalObservation?.DateObserved | formatShortDate}}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </td>
                <td>
                    {{jobMaterial.Material?.Animal.DateBorn | formatShortDate}}
                </td>
                <td>
                    {{jobMaterial.Material?.Line?.LineName}}
                </td>
                <td>
                    <span *ngFor="let cohortMaterial of jobMaterial.Material?.CohortMaterial |
                            orderBy:'Cohort.CohortName'; let last = last">{{cohortMaterial.Cohort?.CohortName}}<span *ngIf="!last">, </span></span>
                </td>
            </ng-container>
        </tr>
    </tbody>
</table>

import {
    CellFormatterService,
    FormatterOptions,
    TableOptions
} from '../common/datatable';
import { AuditModifiedFieldComponent } from './audit-modified-fields/audit-modified-field.component';
import { AuditClassFactory } from './audit-class-maker';

export class AuditMatingMaterialsTableOptions {

    options: TableOptions;

    constructor(
        private cellFormatterService: CellFormatterService
    ) {
        this.options = this.getDefaultOptions();
    }

    getDefaultOptions(): TableOptions {

        return {
            csvFileName: 'matingMaterials-audit.csv',
            enableDetailColumn: false,
            enableDraggable: false,
            enableSelectable: false,
            enableAuditColumns: false,
            columns: [
                {
                    displayName: 'Type',
                    field: 'UpdateType',
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Modified Fields',
                    field: 'ModifiedFields',
                    sortField: 'ModifiedFields',
                    rendererComponent: AuditModifiedFieldComponent,
                    exportFormatter: this.cellFormatterService.auditModifiedFieldsExportFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Date',
                    field: 'ModifiedDate',
                    formatter: this.cellFormatterService.dateTimeUTCFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Modified By',
                    field: 'ModifiedBy',
                    formatter: this.cellFormatterService.userNameFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Housing ID',
                    field: 'MaterialPoolID',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('MaterialPoolID', 'MaterialPoolID'),
                    cellClass: AuditClassFactory.createCellClass('housingid'),
                },
                {
                    displayName: 'Mating ID',
                    field: 'MatingID',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('MatingID', 'MatingID'),
                    cellClass: AuditClassFactory.createCellClass('matingid'),
                },
                {
                    displayName: 'Animal Mating Status',
                    field: 'AnimalMatingStatus',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('AnimalMatingStatus', 'AnimalMatingStatus'),
                    cellClass: AuditClassFactory.createCellClass('animalmatingstatus'),
                },
                {
                    displayName: 'Mating Type',
                    field: 'MatingType',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('MatingType', 'MatingType'),
                    cellClass: AuditClassFactory.createCellClass('matingtype'),
                },
                {
                    displayName: 'Mating Date',
                    field: 'DateMating',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('DateMating', 'DateMating', this.cellFormatterService.dateFormatter),
                    cellClass: AuditClassFactory.createCellClass('matingdate'),
                },
                {
                    displayName: 'Material Type',
                    field: 'MaterialType',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('MaterialType', 'MaterialType'),
                    cellClass: AuditClassFactory.createCellClass('materialtype'),
                },
                {
                    displayName: 'ID',
                    field: 'MaterialID',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('MaterialID', 'MaterialID'),
                    cellClass: AuditClassFactory.createCellClass('id'),
                },
                {
                    displayName: 'Name',
                    field: 'MaterialName',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('MaterialName', 'MaterialName'),
                    cellClass: AuditClassFactory.createCellClass('name'),
                },
                {
                    displayName: 'Status',
                    field: 'MaterialStatus',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('MaterialStatus', 'MaterialStatus'),
                    cellClass: AuditClassFactory.createCellClass('status'),
                },
                {
                    displayName: 'Exit Reason',
                    field: 'ExitReason',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('ExitReason', 'ExitReason'),
                    cellClass: AuditClassFactory.createCellClass('exitreason'),
                },
                {
                    displayName: 'Date In',
                    field: 'DateIn',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('DateIn', 'DateIn', this.cellFormatterService.dateFormatter),
                    cellClass: AuditClassFactory.createCellClass('datein'),
                },
                {
                    displayName: 'Date Out',
                    field: 'DateOut',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('DateOut', 'DateOut', this.cellFormatterService.dateFormatter),
                    cellClass: AuditClassFactory.createCellClass('dateout'),
                },
                {
                    displayName: 'Created By',
                    field: 'CreatedBy',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('CreatedBy', 'CreatedBy', this.cellFormatterService.userNameFormatter),
                    cellClass: AuditClassFactory.createCellClass('createdby'),
                },
                {
                    displayName: 'Created Date',
                    field: 'DateCreated',
                    formatter: this.cellFormatterService.dateTimeUTCFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                }
            ]
        };
    }
}

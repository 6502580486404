import { Injectable } from '@angular/core';

import { RoleService } from './role.service';

@Injectable()
export class PrivilegeService {

    privilege: string;
    studyAdministrator: boolean = null;

    readonly READONLY = 'ReadOnly';
    readonly READWRITE = 'ReadWrite';

    private studyAdminPromise: Promise<any>;


    constructor(
        private roleService: RoleService
    ) {
       // Nothing to do
    }


    setPrivilege(privilege: string) {
        this.privilege = privilege;
    }

    /**
     * Defaults to true
     */
    get readonly() {
        if (!this.privilege) {
            return true;
        }
        return this.privilege === this.READONLY;
    }

    /**
     * Defaults to false
     */
    get readwrite() {
        if (!this.privilege) {
            return false;
        }
        return this.privilege === this.READWRITE;
    }


    // Role Info
    getCurrentUserStudyAdministratorStudies(): Promise<any[]> {
        if (!this.studyAdminPromise) {
            this.studyAdminPromise = this.getStudyAdministrator();
        }

        return this.studyAdminPromise.then(() => {
            if (this.studyAdministrator) {
                return this.roleService.getCurrentUserStudyAdministratorStudies().then((data: any) => {
                    return data ? data : [this.studyAdministrator];
                });
            } else {
                return [];
            }
        });
    }

    private getStudyAdministrator(): Promise<any> {
        return this.roleService.isStudyAdministrator().then((value: boolean) => {
            if (value) {
                this.studyAdministrator = value;
            } else {
                this.studyAdministrator = false;
            }
        });
    }
}

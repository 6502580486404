import { Injectable } from '@angular/core';
import {
    NgbModal,
    NgbModalOptions
} from '@ng-bootstrap/ng-bootstrap';
import { CensusScanModalComponent } from './census-scan-modal.component';


@Injectable()
export class CensusScanModalService {

    modalOptions: NgbModalOptions;


    constructor(
        private modalService: NgbModal
    ) {
        this.init();
    }

    private init() {
        // NgbModalOptions: https://ng-bootstrap.github.io/#/components/modal/api#NgbModalOptions
        this.modalOptions = {
            backdrop: 'static',
            size: 'md'
        };
    }


    openCensusScanModal(census?: any): Promise<any> {
        // Open modal
        const modalRef = this.modalService.open(CensusScanModalComponent, this.modalOptions);
        const component = modalRef.componentInstance as CensusScanModalComponent;
        component.census = census;

        return modalRef.result;
    }
}

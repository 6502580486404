import { from, Subscription } from 'rxjs';
import {
    Component,
    Input,
    OnChanges,
    OnInit,
} from '@angular/core';

import { AnimalService } from './services/animal.service';

@Component({
    selector: 'pedigree-viewer',
    templateUrl: './pedigree-viewer.component.html',
    styleUrls: ['./pedigree-viewer.component.scss'],
})
export class PedigreeViewerComponent implements OnInit, OnChanges {
    @Input() animal: any;

    ancestors: any[];

    readonly GENERATION_COUNT: number = 3;

    dataSubscription: Subscription;


    constructor(
        private animalService: AnimalService
    ) {
        // Nothing to do
    }

    ngOnInit() {
        this.initialize();
    }

    ngOnChanges(changes: any) {
        if (changes.animal && !changes.animal.firstChange) {
            this.initialize();
        }
    }

    initialize() {
        // cancel previous data call if possible
        if (this.dataSubscription) {
            this.dataSubscription.unsubscribe();
        }

        this.ancestors = [];
        this.getData();
    }

    private getData(): Promise<any> {
        const promise = this.animalService.getPedigreeData(
            this.animal.C_Material_key, this.GENERATION_COUNT
        );

        this.dataSubscription = from(promise).subscribe((data) => {
            this.ancestors = data;
        });

        return promise;
    }
}

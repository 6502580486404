import type { AlertDefinition } from './alert-definition.interface';
import type { AlertTriggeredEventNotification } from './alert-triggered-event-notification.interface';
import type { Device } from './device.interface';

export interface AlertTriggeredEvent {
    AlertDefinition: AlertDefinition;
    AlertTriggeredEventNotification: AlertTriggeredEventNotification[];
    C_AlertDefinition_key: number;
    C_AlertTriggeredEvent_key: number;
    C_Device_key: string;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    Device: Device;
    EventTime: Date;
    ModifiedBy: string;
    Version: number;
}

import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { arrowUpRight, chat, questionCircleFilled } from '@icons';
import { supportUri } from '@config';
import { PopoverOptions } from '@common/components/popover/popover-options.interface';

@Component({
    selector: 'climb-support',
    templateUrl: 'support.component.html',
    styleUrls: ['support.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SupportComponent implements AfterViewInit {
    @Input() isGLP = false;
    @ViewChild('questionCircleIcon', { read: ElementRef }) questionCircleIconRef: ElementRef;

    popoverOptions: PopoverOptions;

    icons = { questionCircleFilled, arrowUpRight, chat };
    readonly supportUri = supportUri;

    ngAfterViewInit() {
        this.popoverOptions = {
            ...this.popoverOptions,
            elementRef: this.questionCircleIconRef
        };
    }
}

import { Pipe, PipeTransform } from '@angular/core';
import { DateFormatterService } from '../util/date-time-formatting/date-formatter.service';

/*
* Return short formatted date
*/
@Pipe({ name: 'formatShortDate' })
export class FormatShortDatePipe implements PipeTransform {

    constructor(private dateFormatterService: DateFormatterService) {

    }
    transform(value: any): string {
        return this.dateFormatterService.formatDateOnly(value);
    }
}

import { Injectable } from "@angular/core";
import { Entity } from "breeze-client";
import { AdminManagerService } from "./admin-manager.service";
import { AuthService } from "./auth.service";
import { BaseEntityService } from "./base-entity.service";
import { DataManagerService } from "./data-manager.service";
import { OBJECT_TYPE_MAPPING } from "./models";
import { OBJECT_TYPE_MAPPING_ADMIN } from "./models";
import { IModificationService } from '@services/interfaces/modification-service.interface';

@Injectable()
export class ModificationService extends BaseEntityService implements IModificationService {
    constructor(
        private dataManager: DataManagerService,
        private adminManager: AdminManagerService,
        private _authService: AuthService
    ) {
        super();
    }

    create(initialValues: any): any {
        return this.dataManager.createEntity("Modification", initialValues);
    }

    createAdmin(initialValues: any): any {
        return this.adminManager.createEntity("Modification", initialValues);
    }

    createModificationRecord(entity: Entity, reasonForChange: string, isAdmin = false): Entity {
        let modificationRecord: Entity = null;

        const recordKey = entity.entityAspect.getKey();
        const recordKeyValue = recordKey.values[0];

        if (isAdmin) {
            const objectTypeKey = this.getAdminObjectType(recordKey.entityType.shortName);
            modificationRecord = this.createAdmin({
                C_ObjectType_key: objectTypeKey,
                C_Record_key: recordKeyValue,
                ReasonForChange: reasonForChange,
                DateCreated: new Date(),
                CreatedBy: this._authService.getCurrentUserName()
            });
        } else {
            const objectTypeKey = this.getEntityObjectType(recordKey.entityType.shortName);
            modificationRecord = this.create({
                C_ObjectType_key: objectTypeKey,
                C_Record_key: recordKeyValue,
                ReasonForChange: reasonForChange,
                DateCreated: new Date(),
                CreatedBy: this._authService.getCurrentUserName()
            });
        }
        return modificationRecord;
    }

    getEntityObjectType(entityName: string): number {
        if (OBJECT_TYPE_MAPPING.hasOwnProperty(entityName.toLowerCase())) {
            return OBJECT_TYPE_MAPPING[entityName.toLowerCase()];
        }
        return 0;
    }

    getAdminObjectType(entityName: string): number {
        if (OBJECT_TYPE_MAPPING_ADMIN.hasOwnProperty(entityName.toLowerCase())) {
            return OBJECT_TYPE_MAPPING_ADMIN[entityName.toLowerCase()];
        }
        return 0;
    }
}

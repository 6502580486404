import { BulkEditCommService } from './../../common/facet/bulk-edit-comm.service';
import { MaterialPoolService } from './../../services/material-pool.service';
import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';

import { LoggingService } from '../../services/logging.service';
import {BulkEditToolbarComponent, ViewUnsavedChangesModalService} from '../../common/toolbars';
import { SettingService } from '../../settings/setting.service';
import { IValidatable, SaveChangesService } from '../../services/save-changes.service';
import { FacetView } from '../../common/facet';
import { HousingBulkTemplatesComponent } from './housing-bulk-templates.component';
import {ToastrService} from '@services/toastr.service';
import {LogLevel} from '@services/models';

@Component({
    selector: 'housing-bulk-edit',
    templateUrl: './housing-bulk-edit.component.html',
    providers: [
        BulkEditCommService
    ]
})
export class HousingBulkEditComponent implements OnInit, OnDestroy, IValidatable {
    @Input() facet: any;
    @Input() facetView: FacetView;
    @Input() materialPools: any[];
    // Active and required fields set by facet settings
    @Input() activeFields: string[] = [];
    @Input() inactiveFields: string[] = [];
    @Input() requiredFields: string[];
    @Output() exit: EventEmitter<any> = new EventEmitter<any[]>();
    @ViewChild('bulkEditToolbar') bulkEditToolBar: BulkEditToolbarComponent;
    @ViewChild('bulkTemplates') bulkTemplates: HousingBulkTemplatesComponent;

    readonly COMPONENT_LOG_TAG = 'housing-bulk-edit';
    saving = false;
    savingMessage = "";
    readonly DEFAULT_MAX_ITEMS = 50;
    validationErrorsPresent = false;
    constructor(
        private materialPoolService: MaterialPoolService,
        private loggingService: LoggingService,
        private settingService: SettingService,
        private saveChangesService: SaveChangesService,
        private toastrService: ToastrService,
        private viewUnsavedChangesModalService: ViewUnsavedChangesModalService,
    ) {
    }

    // lifecycle
    ngOnInit() {
        this.initialize();
        this.saveChangesService.registerValidator(this);
    }

    ngOnDestroy() {
        this.saveChangesService.unregisterValidator(this);
    }

    initialize() {
         // Copy the input so we don't touch the grid data
        this.materialPools = this.materialPools.slice();
    }

    async exitClicked(): Promise<void> {
        const validationPassed = await this.handleUnsavedChangesOnExit();
        if (validationPassed) {
            this.exit.emit(this.materialPools);
        }
    }
    
    async onSaveHousings() {
        await this.saveEditedHousings();
    }

    private async saveEditedHousings() {
        this.saving = true;
        const numberItemsToAdd = this.materialPools.length;
        if (numberItemsToAdd >= this.DEFAULT_MAX_ITEMS) { 
            this.savingMessage = `Saving. 
            It may take a minute or longer to save a large number of records`;
        } else {
            this.savingMessage = "Saving";
        }
        await this.bulkEditAndSave();
        this.saving = false;
    }

    private async bulkEditAndSave(): Promise<void> {
        const errorMessage = await this.validate();
        if (errorMessage) {
            this.validationErrorsPresent = true;
            this.toastrService.showToast(this.saveChangesService.generateSaveErrorMessage(this.facet.FacetName, this.facetView, errorMessage),
                LogLevel.Error);
            return;
        }
        this.validationErrorsPresent = false;
        await this.materialPoolService.bulkUpdate(this.materialPools);
    }
    
    async validate(): Promise<string> {
        const dateErrorMessage = this.bulkTemplates.validate();
        if (dateErrorMessage) {
            return dateErrorMessage;
        }
        const bulkEditFields = [
            'C_MaterialPoolStatus_key',
            'DatePooled',
            'Owner',
            'Location',
            'C_ContainerType_key',
            'Comments'
        ];

        const filteredRequiredFields = this.requiredFields.filter((field: string) => bulkEditFields.includes(field));

        return this.settingService.bulkValidate(this.materialPools, filteredRequiredFields, 'housing');
    }

    /**
     * Handles unsaved changes when the user attempts to exit
     * @returns {Promise<boolean>} - true if frontend validation passed, otherwise false
     */
    async handleUnsavedChangesOnExit(): Promise<boolean> {
        if (this.materialPoolService.materialPoolsHaveChanges(this.materialPools)) { 
            const result =
                await this.viewUnsavedChangesModalService.openComponent(
                    this.COMPONENT_LOG_TAG,
                );
            if (result === "save") {
                await this.saveEditedHousings();
                return !this.validationErrorsPresent;
            } else {
                this.cancelAnyChanges();
            }
        }
        return true;
    }

    private cancelAnyChanges() {
        this.materialPoolService.cancelBulkChanges(this.materialPools);
        // remove added, but un-saved housings
        this.materialPools = this.materialPools.filter((housing) => {
            return housing.C_MaterialPool_key > 0;
        });
        this.loggingService.logFacetUndoSuccess(this.COMPONENT_LOG_TAG);
    }
}

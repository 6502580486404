<dx-date-box
        [(value)]="date"
        type="time"
        height="25"
        [displayFormat]="timeFormat"
        [openOnFieldClick]="true"
        [buttons]="buttonOptions"
        [disabled]="disabled"
        *ngIf="!hide"
        [ngStyle]="{'width': (isGLP ? '13.5em' : '9em')}"
        (onValueChanged)="onSelectTime($event)"
        [dropDownOptions]="{'closeOnOutsideClick': true}"
        (onOpened)="onOpened.emit()"
        (onClosed)="onClosed.emit()">
</dx-date-box>
<climb-root></climb-root>
<climb-sidebar [opened]="workspaceService.isCustomizeWorkspaceActive">
    <facet-selector sidebar #sidebar>
    </facet-selector>

    <div content id="scroll-parent" class="workspace-container"
         (scroll)="scrollEvent($event)"
         [ngClass]="{'workspace': !workspaceService.isCustomizeWorkspaceActive, 'workspace-customizing': workspaceService.isCustomizeWorkspaceActive}">
        <loading-overlay *ngIf="loading" [message]="'Initializing Workspace'"></loading-overlay>

        <climb-onboarding-banner *ngIf="workspaceService.isCustomizeWorkspaceActive && (facetsEmpty$ | async)"></climb-onboarding-banner>

        <ngx-gridster
          #gridster
          *ngIf="workspace"
          [options]="gridsterOptions"
          [draggableOptions]="{ handlerClass: 'draggable-title' }"
        >
            <ngx-gridster-item
              *ngFor="let facet of (facets$ | async); trackBy: trackById"
              [x]="facet.x"
              (xChange)="changeDimension(facet.id, 'x', $event)"
              [y]="facet.y"
              (yChange)="changeDimension(facet.id, 'y', $event)"
              [w]="facet.w"
              (wChange)="changeDimension(facet.id, 'w', $event)"
              [h]="facet.h"
              (hChange)="changeDimension(facet.id, 'h', $event)"
              (end)="manuallyFacetChanged(facet.id)"
            >
              <!-- TODO: add condition for rendering correct factory! -->
              <facet-factory *ngIf="facet.type === 'old'; else modernTmp" [facetId]="facet.id" [facet]="facet.facet"></facet-factory>
              <ng-template #modernTmp>
                <climb-modern-facet-factory [facet]="facet"></climb-modern-facet-factory>
              </ng-template>
            </ngx-gridster-item>
        </ngx-gridster>
    </div>
</climb-sidebar>

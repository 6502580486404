import { Observable } from 'rxjs';
import { FacetLoadingStateService } from '@common/facet/facet-loading-state.service';
import {
    Component,
    Input,
    OnInit,
    Output,
    EventEmitter,
} from '@angular/core';
import { CLIMB_SURFACE_MODE } from '@common/tokens';
import { WorkspaceService } from '../../../../workspaces/workspace.service';
import { SaveChangesService } from '@services/save-changes.service';
import { arrowsIn, arrowsOut, crossBig, IconContent } from '@icons';
import { filter, map, tap } from 'rxjs/operators';
import { Facet, isWorkspaceDetails } from 'src/app/mf-store';

@Component({
    selector: 'facet-header',
    templateUrl: './facet-header.component.html',
    styleUrls: ['./facet-header.component.scss'],
    providers: [{ provide: CLIMB_SURFACE_MODE, useValue: 'dark' }],
})
export class FacetHeaderComponent implements OnInit {
    @Input() caption: string;
    @Input() facetId: string;

    @Output() close = new EventEmitter<void>();

    readonly icons = { arrowsIn, arrowsOut, crossBig };

    facet$ = new Observable<Facet>();
    facetExpanded$ = new Observable<boolean>();
    facetName$ = new Observable<string>();
    facetIcon$ = new Observable<IconContent>();
    private facetName: string;

    // can be triggered by child components in facet to indicate to user
    // that related data is still loading
    facetLoading: Observable<boolean>;

    get isCustomizeWorkspaceActive(): boolean {
        return this.workspaceService.isCustomizeWorkspaceActive;
    }

    constructor(
        private facetLoadingState: FacetLoadingStateService,
        private workspaceService: WorkspaceService,
        private saveChangesService: SaveChangesService
    ) {}

    ngOnInit() {
        this.facetLoading = this.facetLoadingState.changeLoadingState$;

        this.facet$ = this.workspaceService.getFacetById$(this.facetId);
    
        this.facetExpanded$ = this.facet$.pipe(
          map(facet => Boolean(facet?.expanded)),
        );
        
        this.facetName$ = this.facet$.pipe(
          map(facet => facet?.facet),
          filter(isWorkspaceDetails),
          map(facet => facet.FacetName),
          tap(name => this.facetName = name),
        );
    
        this.facetIcon$ = this.facetName$
          .pipe(map(name => this.workspaceService.facetIcons.get(name)?.header));
    }

    async onClose(): Promise<void> {
        // TODO: replace to lastValueFrom after upgrading version of rxjs (delete this.facetName field)
        const isAnimalsFacet = this.facetName === 'Animals';
        // TODO: left if statement here for backwards compatibility, needs to be removed in future
        if (isAnimalsFacet) {
            this.close.emit();
        } else {
            await this.saveChangesService.promptForUnsavedChanges(this.facetName);
            this.workspaceService.deleteWorkspaceDetail(this.facetId);
        }
    }

    onExpand(): void {
        this.workspaceService.expandFacet(this.facetId);
    }

    onCollapse(): void {
        this.workspaceService.collapseFacet(this.facetId);
    }
}

<span class="ancestor-container text-wrap"
      *ngFor="let ancestor of ancestors; let last = last">
    <a class="ancestor"
       (click)="selectAncestor(ancestor)"
       *ngIf="!readonly">{{ancestor.PositionName}}</a>
    <span *ngIf="readonly">
        {{ancestor.PositionName}}
    </span>

    <span class="ancestor-breadcrumb"
          *ngIf="!last || (locationList && locationList.length > 0)">&gt;</span>
</span>

<span class="current-container text-nowrap"
      *ngIf="locationList && locationList.length > 0">
    <select [(ngModel)]="location"
            (ngModelChange)="selectLocation($event)"
            [disabled]="readonly"
            [required]="required">
        <option *ngIf="!required && ancestors?.length === 0"
                [ngValue]="null"></option>
        <option *ngFor="let item of locationList"
                [ngValue]="item">
            {{item.PositionName}}
        </option>
    </select>
    <a class="add-child-control add-item-link"
       *ngIf="(childCount > 0) && !readonly && location"
       (click)="addLevel()">
        <i class="fa fa-plus-circle" title="Add child location"></i>
    </a>
</span>

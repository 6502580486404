import type { ContactPerson } from './contact-person.interface';
import type { Institution } from './institution.interface';
import type { JobInstitution } from './job-institution.interface';
import type { cv_Country } from './cv-country.interface';
import type { cv_SiteType } from './cv-site-type.interface';
import type { cv_State } from './cv-state.interface';

export interface Site {
    Address: string;
    AddressQualifier: string;
    C_Country_key: number | null;
    C_Institution_key: number;
    C_SiteType_key: number | null;
    C_Site_key: number;
    C_State_key: number | null;
    C_Workgroup_key: number;
    City: string;
    ContactPerson: ContactPerson[];
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    EmailAddress: string;
    GPSCoordinates: string;
    Identifier: string;
    Institution: Institution;
    JobInstitution: JobInstitution[];
    MobilePhone: string;
    ModifiedBy: string;
    Name: string;
    Phone: string;
    PostalCode: string;
    State: string;
    Version: number;
    cv_Country: cv_Country;
    cv_SiteType: cv_SiteType;
    cv_State: cv_State;
}

import { appErrorPrefix } from './../config/climb-web-settings';
import { LogoutService } from './logout.service';
import {
    AutoGeneratedKeyType,
    EntityManager,
    EntityQuery,
    EntityState,
    FetchStrategy,
    QueryResult,
    EntityType
} from 'breeze-client';

import {
    batchArray,
    expireCache,
    ExpireCache,
    getSafeProp,
    notEmpty,
    uniqueArray,
    uniqueArrayFromPropertyPath,
    arrayDifference
} from '@common/util';
import {
    getForeignKeyName,
    getResourceName,
    filterNotDeleted,
    getPrimaryKeyName,
    entityHasType,
} from './breeze-helpers';

import { 
    Observable, 
    defer,
    forkJoin,
    from
} from 'rxjs';
import { cacheWithExpiration } from '../common/observable';

import { LoggingService } from '../services/logging.service';
import { Entity as InternalEntity, Entity } from '@common/types';
import { LocalStorageKey } from '../config/local-storage-key.enum';
import { debug } from '../config/environment';
import { AUTH_ERROR_TAG, TranslationService, UNHANDLED_ERROR_MESSAGE } from './translation.service';
import { map } from 'rxjs/operators';

/**
 * Base class for our data Managers
 * Wraps common functions for executing queries and capturing errors
 */
export abstract class BaseManagerService {

    loggingService: LoggingService;
    translationService?: TranslationService;
    /*
     * Service from Angular 1 for logging out of Climb
     */
    logoutService: LogoutService;

    _manager: EntityManager;

    // 5 minutes
    readonly DEFAULT_CACHE_EXPIRATION = 300000;
    readonly QUERY_NS = 'queryCache';
    _queryCache: ExpireCache = expireCache.namespace(this.QUERY_NS);

    readonly COMPONENT_LOG_TAG = 'base-manager-service';

    readonly AUTH_ERROR_TAG = "Authorization has been denied for this request.";

    constructor(
        loggingService: LoggingService,
        logoutService: any,
        translationService?: TranslationService,
    ) {
        this.loggingService = loggingService;
        this.logoutService = logoutService;
        this.translationService = translationService;
    }

    abstract getManager(): EntityManager;

    entityHasType<T>(entity: Entity<T>, type: number) {
        return entityHasType(entity, type);
    }

    /**
     * Reload entity.collectionName, and detach any items
     *   that no longer exist on the server
     * 
     * Returns: entities that were refreshed
     * @param entity 
     * @param collectionName 
     */
    refreshEntityCollection(
        entityName: string,
        collectionName: string,
        pkValues: any[]
    ): Promise<any[]> {
        if (!entityName ||
            !collectionName ||
            !notEmpty(pkValues)
        ) {
            return Promise.resolve([]);
        }

        const pkName = getPrimaryKeyName(this.getManager(), entityName);
        const resourceName = getResourceName(this.getManager(), entityName);
        const query = new EntityQuery(resourceName).where(pkName, 'in', pkValues);

        let localEntities: any[] = [];
        // run query against cache first to retrieve local entities
        return this.executeQueryLocal(query).then((queryResult) => {
            localEntities = queryResult.results;
            // then manually load remote child collection
            return this._manualExpandQuery(
                localEntities, pkName, collectionName, [],
            ).toPromise();
        }).then((remoteCollection) => {

            const localCollection = uniqueArrayFromPropertyPath(localEntities, collectionName);

            const remotelyDeletedItems = arrayDifference(localCollection, remoteCollection);
            for (const deletedItem of remotelyDeletedItems) {
                this.detachEntity(deletedItem);
            }

            return localEntities;
        });
    }

    /**
     * Executes query and returns breeze QueryResults.results array
     * @param query
     * @param preferLocal - try querying from local cache first
     */
    returnQueryResults(query: EntityQuery, preferLocal?: boolean): Promise<any[]> {
        return this.executeQuery(query, preferLocal).then((response) => {
            return response.results !== undefined ? response.results : [];
        }).catch(this.queryFailed) as Promise<any[]>;
    }

    /**
     * Executes query and returns the first breeze QueryResults.results object, or null if none
     * @param query
     * @param preferLocal - try querying from local cache first
     */
    returnSingleQueryResult(query: EntityQuery, preferLocal?: boolean): Promise<any> {
        return this.returnQueryResults(query, preferLocal).then((records) => {
            return records.length > 0 ? records[0] : null;
        });
    }
    
    /**
     * @deprecated Use returnSingleQueryResult() instead.
     * Data is cached at the HttpClient level
     *
     * Executes query and returns the first Breeze QueryResults.results object, or null if none.
     *   Returns result of previously run query if the same query was run within
     *   the last expireMs milliseconds.
     * @param query 
     * @param expireMs 
     */
    returnSingleQueryResultCached(query: EntityQuery, expireMs?: number): Promise<any> {
        return this.getQueryResults(query).then((records) => {
            return records.length > 0 ? records[0] : null;
        });
    }

    /**
     * Executes query and returns breeze QueryResults.inlineCount value
     * @param query
     * @param preferLocal - try querying from local cache first
     */
    returnQueryCount(query: EntityQuery, preferLocal?: boolean): Promise<number> {
        query = query.inlineCount(true);
        query = query.take(0);
        return this.executeQuery(query, preferLocal).then((response) => {
            return response.inlineCount;
        }).catch(this.queryFailed);
    }

     /**
      * Executes query and returns breeze QueryResults.inlineCount value
      * Returns results of previously run query if the same query was run within
      *   the last expireMs milliseconds.
      * @param query
      * @param expireMs
      */
    returnCachedQueryCount(query: EntityQuery, expireMs?: number): Promise<number> {
        query = query.inlineCount(true);
        query = query.take(0);
        return this.executeCachedQuery(query, expireMs).then((response) => {
            return response.inlineCount;
        }).catch(this.queryFailed);
    }

    /**
     * Executes query and returns Breeze QueryResults.results array.
     *   Returns results of previously run query if the same query was run within
     *   the last expireMs milliseconds.
     * @param query 
     */
    getQueryResults<T = any>(query: EntityQuery): Promise<Entity<T>[]> {
        return this.executeQuery(query).then((queryResult: QueryResult) => {
            return filterNotDeleted(queryResult.results as any, this.getManager());
        });
    }

    /**
     * Executes query and returns Breeze QueryResults.
     *   Returns results of previously run query if the same query was run within
     *   the last expireMs milliseconds.
     * @param query 
     * @param expireMs 
     */
    executeCachedQuery(query: EntityQuery, expireMs?: number): Promise<QueryResult> {
        if (expireMs === null || 
            expireMs === undefined || 
            expireMs < 0
        ) {
            expireMs = this.DEFAULT_CACHE_EXPIRATION;
        }
        // pull results from observable, and store in cache
        //   to avoid multiple requests for same results
        const queryKey = JSON.stringify(query.toJSON()) + '-' + expireMs;
        let observable = this._queryCache.get(queryKey);
        if (!observable) {
            observable = this._cachedObservable(() => {
                return this.executeQuery(query);
            }, expireMs);
            this._queryCache.set(queryKey, observable);
        }
        return observable.toPromise();
    }
    
    /**
     * Ensure that all the relationships in
     *   expands list are loaded
     * @param items 
     * @param expands 
     */
    ensureRelationships(items: any, expands: string[]): Promise<any> {
        return this._recurseManualExpands(items, expands);
    }

    ensureRelationships$(items: any, expands: string[]): Observable<any> {
        return defer(() => this._recurseManualExpands(items, expands));
    }

    _recurseManualExpands(
        items: any[],
        expandClauses: string[]
    ): Promise<any[]> {

        const promises: Promise<any[]>[] = [];
        for (const expandClause of expandClauses) {
            const clauses = expandClause.split('.');
            const firstClause = clauses[0];

            if (notEmpty(items)) {
                const aspect = items.find((i: any) => i !== undefined).entityAspect;
                const group = aspect.entityGroup;
                const shortName = group.entityType.shortName;
                const clauseCount = clauses.length;

                // expand Material.Animal and Material.Sample
                //   for efficiency
                let extraExpands: string[] = [];
                if (clauseCount > 1 &&
                    (firstClause === 'Material' || firstClause === 'SourceMaterial') &&
                    (clauses[1] === 'Animal' || clauses[1] === 'Sample')
                ) {
                    extraExpands = [clauses[1]];
                }

                const fk = getForeignKeyName(this.getManager(), shortName, firstClause);
                let promise = this._manualExpandQuery(
                    items, fk, firstClause, extraExpands
                ).toPromise().then(() => {
                    // return all properties whether they were loaded by promises
                    // or were already loaded in memory
                    return uniqueArrayFromPropertyPath(items, firstClause);
                });

                if (clauseCount > 1) {
                    const nextClause = clauses.slice(1).join('.');
                    let childResults: any[] = [];
                    promise = promise.then((children: any) => {
                        childResults = children;
                        return this._recurseManualExpands(children, [nextClause]);
                    }).then(() => {
                        return childResults;
                    });
                }

                promises.push(promise);
            }
        }

        return Promise.all(promises);
    }


    _manualExpandQuery(
        items: any[],
        keyProp: string,
        tableName: string,
        expands?: string[],
    ): Observable<any[]> {

        const QUERY_BATCH_SIZE = 100;

        const resourceName = getResourceName(this.getManager(), tableName);
        if (!resourceName || !keyProp) {
            return from([[]]);
        }

        // accommodate inverse table relationships
        let remoteKey = keyProp;
        if (keyProp === 'C_GroupTaskInstance_key') {
            keyProp = 'C_TaskInstance_key';
            remoteKey = 'C_GroupTaskInstance_key';
        }
        
        // accommodate recursive table relationships
        if (keyProp === 'C_SourceMaterial_key') {
            remoteKey = 'C_Material_key';
        }

        let keys = items.map((item) => {
            return getSafeProp(item, keyProp);
        }).filter((key) => key);

        keys = uniqueArray(keys);

        const requests: Observable<any>[] = [];
        for (const batch of batchArray(keys, QUERY_BATCH_SIZE)) {
            
            let query = EntityQuery.from(resourceName)
                .where(remoteKey, 'in', batch);

            if (notEmpty(expands)) {
                query = query.expand(expands.join(','));
            }

            requests.push(defer(() => this.executeQuery(query)));
        }


        return forkJoin(requests).pipe(map((promiseResults) => {
            let allResults: any[] = [];
            for (const promiseResult of promiseResults) {
                allResults = allResults.concat(promiseResult);
            }
            return allResults;
        }));
    }

    protected _cachedObservable(
        deferredFunction: () => Promise<any>,
        expirationMs?: number
    ): Observable<any> {
        if (expirationMs === undefined) {
            expirationMs = this.DEFAULT_CACHE_EXPIRATION;
        }
        return defer(deferredFunction).pipe(
            cacheWithExpiration(expirationMs)
        );
    }

    /**
     * Execute standard breeze query and get back full response.
     * @param query
     * @param preferLocal - try querying from local cache first
     */
    executeQuery(query: EntityQuery, preferLocal?: boolean): Promise<QueryResult> {
        return this._executeQuery(query, preferLocal);
    }

    executeQueryLocal(query: EntityQuery): Promise<QueryResult> {
        query = query.using(FetchStrategy.FromLocalCache);
        return this._executeQuery(query);
    }

    queryFailed = (error: any) => {
        let errorMessage: string;
        // modeled after _catchNoConnectionError method in breeze
        if (this._isNetworkError(error)) {
            errorMessage = this.handleNetworkError(error);
        }

        if (this.isOdataError(error)) {
            errorMessage = this.handleOdataError(error);
        }
        
        if (this.isAuthorizationError(error)) {
            errorMessage = this.handleAuthorizationError();
        }

        if (!errorMessage || !errorMessage.length) {
            errorMessage = this.translationService?.translateSaveErrors(error) || UNHANDLED_ERROR_MESSAGE;
        }

        this.loggingService.logError(
            errorMessage, error, this.COMPONENT_LOG_TAG, true
        );

        throw error;
    }

    private _isNetworkError(error: any): boolean {
        // if there is no message and status code 0, likely could not reach server
        return error?.status === 0 && error?.message?.length === 0;
    }

    private handleNetworkError(error: any) {
        this.loggingService.logWarning(
            'There was an error retrieving data. ' +
            'Please check your connection and try again.',
            null, this.COMPONENT_LOG_TAG, true
        );

        if (error?.url) {
            // append url to message for logging connection failure
            return appErrorPrefix + "Error retrieving data for " + error.url;
        }
    }

    
    private isOdataError(error: any) {
        return error?.message 
        && error?.message?.includes?.('odata');
    }

    private handleOdataError(error: any) {
        try {
            error.message = JSON.parse(error.message);
        } catch (err) {
            this.loggingService.logError(
                'Error parsing odata error message',
                err,
                this.COMPONENT_LOG_TAG,
                false
            );
            return;
        }

        let odataErrorNode = error.message?.['odata.error'];
        if (!odataErrorNode) {
            return;
        }

        /**
         * This traverses the inner errors and adds them to the unique error messages set.
         * A set is used because some errors are identical and we only care about what is unique.
         */
        const uniqueErrorMessages = new Set();
        do {
            const message = odataErrorNode?.message?.value || typeof odataErrorNode?.message === 'string' ? odataErrorNode.message : '';
            if (message && message.length > 0) {
                uniqueErrorMessages.add(message);
            }
            odataErrorNode = odataErrorNode?.innererror;
        } while (odataErrorNode);
        error.message = Array.from(uniqueErrorMessages).join(' ');
        return this.isDebug ? error.message : '';
    }

    private get isDebug() {
        return debug;
    }

    private isAuthorizationError(error: any) {
        if (error?.message?.includes?.(this.AUTH_ERROR_TAG)) {
            return true;
        }
        let isUnauthorizedError: boolean;
        try {
            isUnauthorizedError = !localStorage.getItem("ls." + LocalStorageKey.AUTH_DATA) || error?.statusText === 'Unauthorized';
        } catch {
            isUnauthorizedError = true;
        }

        try {
            return isUnauthorizedError || JSON.parse(error?.statusText)['odata.error']?.message?.value === AUTH_ERROR_TAG;
        } catch {
            return isUnauthorizedError;
        }
    }

    private handleAuthorizationError() {
        this.logoutService.sessionExpired();
        return 'You are not authorized to make this request.';
    }


    /**
     * Create a breeze entity by name.
     * Sets autoGeneratedKeyType.
     * Returns newly created entity
     * @param entityName
     * @param initialValues - any initial values to be set on new entity
     */
    createEntity(entityName: string, initialValues?: any): any {
        const objType: any = this._manager.metadataStore.getEntityType(entityName);
        objType.setProperties({ autoGeneratedKeyType: AutoGeneratedKeyType.Identity });
        if (!initialValues) {
            initialValues = {};
        }
        return this._manager.createEntity(entityName, initialValues);
    }

    protected _saveChangesToProperty(
        entity: any, 
        propertyName: string,
        modifiedBy: string
    ): Promise<void> {
        // test if value has been modified
        const entityAspect = entity.entityAspect;
        const originalValues = entityAspect.originalValues;
        if (!originalValues.hasOwnProperty(propertyName)) {
            // nothing to save if property not modified
            return Promise.resolve();
        }

        // create temporary manager to issue the save
        const tempManager = this.newTemporaryManager(this._manager);

        // create copy of entity
        const entityType = entityAspect._entityKey.entityType;
        // set initial primary key values
        const copyInitialValues: any = {
            [propertyName]: entity[propertyName],
            DateModified: new Date(),
            ModifiedBy: modifiedBy
        };
        this.copyPrimaryKey(entity, copyInitialValues);
        const copy: any = tempManager.createEntity(entityType, copyInitialValues);

        // marker properties as modified
        copy.entityAspect.originalValues[propertyName] = originalValues[propertyName];
        copy.entityAspect.originalValues.DateModified = entity.DateModified;
        copy.entityAspect.originalValues.ModifiedBy = entity.ModifiedBy;

        // set entity state to modified
        copy.entityAspect.setModified();

        // issue the save
        return tempManager.saveChanges([copy]).then(() => {
            // if success, mark property as Unchanged on current entity
            delete entity.entityAspect.originalValues[propertyName];

            // if no other properties are modified, mark entity Unchanged
            if (Object.getOwnPropertyNames(entity.entityAspect.originalValues).length === 0) {
                entity.entityAspect.setUnchanged();
            }

            tempManager.clear();
        });
    }

    copyPrimaryKey(original: any, copy: any) {
        const entityType: EntityType = original.entityAspect._entityKey.entityType;
        for (const keyProperty of entityType.keyProperties) {
            copy[keyProperty.name] = original[keyProperty.name];
        }
    }
    
    /**
     * Creates a new temporary EntityManager based on configuration
     *   and metadata of managerToCopy
     * @param managerToCopy 
     */
    newTemporaryManager(managerToCopy: EntityManager): EntityManager {
        return new EntityManager({
            serviceName: managerToCopy.dataService.serviceName, 
            metadataStore: managerToCopy.metadataStore
        });
    }


     /**
      * Removes entity from breeze local cache
      * @param entity
      */
    detachEntity(entity: any) {
        entity.entityAspect.setDetached();
    }

    /**
     * Marks entity for deletion in breeze
     * @param entity
     */
    deleteEntity(entity: any) {
        if (entity.entityAspect.entityState === EntityState.Detached) {
            // cannot delete entity that is already detached
            return;
        }
        entity.entityAspect.setDeleted();
    }

    /**
     * Checks whether the value is unique for entityName.propertyName
     *   Verifies against remote data only.
     *
     * @param entityName
     * @param propertyName
     * @param propertyValue
     */
    async isPropertyValueUnique(
        entityName: string,
        propertyName: string,
        propertyValue: any,
        entityPK: number,
        entityPKName: string,
    ): Promise<boolean> {
        const query = EntityQuery.from(entityName)
            .where(propertyName, '==', propertyValue)
            .select(entityPKName)
            .inlineCount(true);

        // if the only entity that is found as a duplicate is itself, skip it.
        const primaryKeys = (await this.returnQueryResults(query, true)).filter((entity: any) => {
            return entity[entityPKName] !== entityPK;
        });
        return primaryKeys.length === 0;
    }

    /**
     * Reject all changes for this entity and all of its
     *  related navigation properties
     * @param entity
     */
    rejectEntityAndRelatedPropertyChanges<T = any>(entity: Entity<T>) {
        const seen: Set<Entity<T>> = new Set();

        /*
         * Using stack method of recursion
         *   to find and reject all related properties
         */
        let stack: Entity<T>[] = [entity];
        while (stack.length > 0) {
            const entityToReject = stack.pop();
            // check if this entity has been handled
            if (seen.has(entityToReject)) {
                continue;
            }

            // reject the changes
            entityToReject.entityAspect.rejectChanges();
            seen.add(entityToReject);

            // iterate navigation properties
            const navigationProps = this._getNavigationProps(entityToReject);

            stack = stack.concat(navigationProps);
        }
    }

    /**
     * Reject changes to all entities of entityName type,
     *  after filtering list of changed entities by filterFunc.
     *
     * Returns any entities matching the filter.
     * @param entityName
     * @param filterFunc
     */
    rejectChangesToEntityByFilter(
        entityName: string,
        filterFunc: (entity: Entity<any>) => boolean
    ): any[] {
        const changes = this.getChangesToEntityByFilter(entityName, filterFunc);
        for (const changedEntity of changes) {
            changedEntity.entityAspect.rejectChanges();
        }
        return changes;
    }

    getChangesToEntityByFilter<T = any>(
        entityName: string,
        filterFunc: (entity: Entity<T>) => boolean
    ): Entity<T>[] {
        const changes = this._manager.getChanges(entityName) as Entity<T>[];
        return changes.filter(filterFunc);
    }

    getChangesToEntityByKey<T = any>(
        entityName: string,
        key: string,
        value: any
    ): Entity<T>[] {
        return this.getChangesToEntityByFilter(entityName, (item: any) => item[key] === value);
    }

    /**
     * Return all navigation properties of entity.
     *   Including those in child arrays
     * @param entity
     */
    private _getNavigationProps<T = any>(entity: Entity<T>) {
        const props: any[] = [];
        /* eslint-disable-next-line */
        for (let prop in entity) {
            props.push(entity[prop]);
        }

        // check array children as well
        //   by adding them to the list of props to filter
        //
        // HACK: If you can find a simpler way to do this, be my guest.
        // NOTE: commenting out, because now I'm not sure we want to follow all
        //   array properties
        /*let arrayProps = props.filter((prop) => {
            return Array.isArray(prop);
        });
        for (let arrayProp of arrayProps) {
            for (let item of arrayProp) {
                props.push(item);
            }
        }
        */

        // filter all props that are Breeze entities
        return props.filter((prop) => {
            return prop && prop.entityAspect;
        });
    }

    /**
     * 
     * @param query
     * @param preferLocal - try querying from local cache first
     */
    private _executeQuery(
        query: EntityQuery, 
        preferLocal?: boolean,
        retryCount = 10
    ): Promise<QueryResult> {
        if (preferLocal) {
            const results = this._manager.executeQueryLocally(query);
            if (notEmpty(results)) {
                const queryResult: QueryResult = {
                    query,
                    results,
                    inlineCount: results.length,
                    httpResponse: null
                };
                return Promise.resolve(queryResult);
            }
        }

        // else query remote
        return this._manager.executeQuery(query)
            .catch((error: any) => {
                if (this._isNetworkError(error) && retryCount > 0) {
                    return new Promise((resolve, reject) => {
                        setTimeout(() => {
                            resolve(this._executeQuery(query, false, retryCount - 1));
                        }, 500);
                    });
                }
                throw error;
            })
            .catch(this.queryFailed) as any;
    }

    cancel(): boolean {
        const anyChanges = this.hasChanges();
        if (anyChanges) {
            this._manager.rejectChanges();
        }

        return anyChanges;
    }

    clear() {
        this._manager.clear();
    }

    hasChanges(): boolean {
        return this._manager.hasChanges();
    }

    getChangesCount(): number {
        return this._manager.getChanges().length;
    }

    getChanges(): any[] {
        return this._manager.getChanges();
    }

    /**
     * fix error message if there are InnerExceptions
     * @param error 
     */
    protected _adjustOdataErrorResponse(error: any) {
        if (error.message === "; " && error.body) {
            let exceptionNode = error.body;
            let newMessage = exceptionNode.Message;
            while (exceptionNode.InnerException) {
                exceptionNode = exceptionNode.InnerException;
                newMessage += "; " + exceptionNode.Message;
            }

            if (newMessage) {
                error.message = newMessage;
            }
        }
    }

    getAllEntityRelatedPropertyChanges<T = any>(entity: Entity<T>) {
        const seen: Set<Entity<T>> = new Set();

        /*
         * Using stack method of recursion
         *   to find all related properties for the entity
         */
        let stack: Entity<T>[] = [entity];
        while (stack.length > 0) {
            const entityToVisit = stack.pop();
            // check if this entity has been processed
            if (seen.has(entityToVisit)) {
                continue;
            }

            seen.add(entityToVisit);

            // iterate navigation properties
            const navigationProps = this._getNavigationProps(entityToVisit);

            stack = stack.concat(navigationProps);
        }

        return seen;
    }

    getChangesToRelatedCollections<T>(entityType: string, relationKeyName: keyof T, relationKeyValue: T[keyof T]): InternalEntity<T>[] {
        const entities = (this.getManager()
            .getChanges(entityType) ?? []) as InternalEntity<T>[];

        return entities.filter(entity => entity[relationKeyName] === relationKeyValue);
    }

    getChangesToRelatedDeletedEntityByKey<T>(
        entityType: string,
        relationKeyName: keyof T,
        relationKeyValue: T[keyof T]
    ):InternalEntity<T>[] {
        return this.getChangesToEntityByFilter(entityType, (item: InternalEntity<T>) => {
            if (item.entityAspect.entityState.isDeleted()) {
                return item[relationKeyName] === relationKeyValue || item.entityAspect.originalValues[relationKeyName.valueOf()] === relationKeyValue;
            }
        });
    }
}

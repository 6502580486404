import type { ColumnId } from '../table.interface';

export const getCellVariableName = (columnId: ColumnId): string => `--cell-drag-offset-${columnId}`;

/**
 * Moves an item from one array index to another, taking into account non-draggable elements.
 *
 * @param array Array in which to move the item.
 * @param fromIndex Starting index of the item.
 * @param toIndex Index to which the item should be moved.
 */
export function moveItemInArray<T extends { draggable: boolean } = any>(
    array: T[],
    fromIndex: number,
    toIndex: number,
): void {
    const from = clamp(fromIndex, array.length - 1);
    const to = clamp(toIndex, array.length - 1);

    if (from === to) {
        return;
    }

    const target = array[from];
    const delta = to < from ? -1 : 1;

    for (let i = from; i !== to; i += delta) { // NOSONAR
        if (!array[i].draggable) {
            continue;
        }

        let nextIndex = i + delta;
        while (!array[nextIndex].draggable) {
            nextIndex += delta;
        }
        if (nextIndex === to) {
            array[i] = array[to];
            break;
        }

        array[i] = array[nextIndex];
    }

    array[to] = target;
}

/** Clamps a number between zero and a maximum. */
function clamp(value: number, max: number): number {
    return Math.max(0, Math.min(max, value));
}

/**
 * Compares two sets of objects.
 * @param array1
 * @param array2
 */
export function arrayContainsAllObjects(
    array1: any[],
    array2: any[]
): boolean {
    // TODO: Anyone with good algorithm skills is free
    //   to try to speed up this code. 
    // The unit tests will act as a safety net.

    if (!array1 || !array2) {
        // false on one array undefined
        return false;
    }

    if (array1.length !== array2.length) {
        // false on unequal length arrays
        return false;
    }

    // brute force comparison
    for (const obj1 of array1) {
        let isContained = false;

        for (const obj2 of array2) {
            if (obj1 === obj2) {
                isContained = true;
            }
        }
        
        if (!isContained) {
            // false if object not found in other array
            return false;
        }
    }

    return true;
}

import { Injectable, OnDestroy } from '@angular/core';
import { Observable, fromEvent, merge, Subject, timer, Subscription } from 'rxjs';
import { SettingService } from '../settings/setting.service';
import { LogoutService } from './logout.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InactivityTimeoutModalComponent } from '../settings/modals/inactivity-timeout-modal.component';
import { LocalStorageKey } from './../config';
import { LocalStorageService } from './local-storage.service';
import { DataContextService } from './data-context.service';

// Based on bn-ng-idle NPM package: https://www.npmjs.com/package/bn-ng-idle
@Injectable()
export class InactivityTimeoutService implements OnDestroy {

    private idle$: Observable<any>;
    private timer$: Subscription;
    private countDownTimer$: Subscription;
    private timeOutMilliSeconds: number;
    private idleSubscription: Subscription;
    private inactivityTimeoutSubscription: Subscription;

    private expiredSource: Subject<boolean> = new Subject<boolean>();
    expired$ = this.expiredSource.asObservable();

    private countdownSource: Subject<boolean> = new Subject<boolean>();
    countdown$ = this.countdownSource.asObservable();

    modalShow = false;

    constructor(
        private settingService: SettingService,
        private logoutService: LogoutService,
        private modalService: NgbModal,
        private localStorageService: LocalStorageService,
        private dataContext: DataContextService,
    ) { }

    ngOnDestroy() {
        if (this.inactivityTimeoutSubscription) {
            this.inactivityTimeoutSubscription.unsubscribe();
        }
    }

    public startInactivityTimeout() {
        const timeoutMinutes = this.localStorageService.get(LocalStorageKey.INACTIVITY_TIMEOUT_MINUTES);
        if (timeoutMinutes) {
            const timeoutSeconds = timeoutMinutes * 60;
            this.stopTimer();
            this.startWatching(timeoutSeconds);
            this.inactivityTimeoutSubscription = this.expired$.subscribe(() => {
                if (!this.modalShow) {
                    this.showWarningModal();
                }
            });
        }
    }

    private showWarningModal() {
        this.modalShow = true;
        let countdown = 60;
        this.startCountdownTimer();

        const modalRef = this.modalService.open(InactivityTimeoutModalComponent, { backdrop: 'static', size: 'md', windowClass: 'reason-modal' });
        const component = modalRef.componentInstance as InactivityTimeoutModalComponent;
        component.countdown = countdown;

        const modalSub = this.countdown$.subscribe(() => {
            countdown = countdown - 1;
            component.countdown = countdown;
            if (countdown < 0) {
                modalRef.close();
                this.modalShow = false;
                this.stopCountdownTimer();
                modalSub.unsubscribe();
                this.stopTimer();
                this.logoutService.logout();
                this.dataContext.cancel();
            }
        });

        component.stayAndContinue.subscribe(() => {
            this.modalShow = false;
            modalRef.close();
            this.stopCountdownTimer();
            modalSub.unsubscribe();
            this.resetTimer();
        });

        component.logoutNow.subscribe(() => {
            this.modalShow = false;
            modalRef.close();
            this.stopCountdownTimer();
            modalSub.unsubscribe();
            this.stopTimer();
            this.logoutService.logout();
            this.dataContext.cancel();
        });
    }

    private startWatching(timeOutSeconds: number) {
        this.idle$ = merge(
            fromEvent(document, 'mousemove'),
            fromEvent(document, 'click'),
            fromEvent(document, 'mousedown'),
            fromEvent(document, 'keypress'),
            fromEvent(document, 'DOMMouseScroll'),
            fromEvent(document, 'mousewheel'),
            fromEvent(document, 'touchmove'),
            fromEvent(document, 'MSPointerMove'),
            fromEvent(window, 'mousemove'),
            fromEvent(window, 'resize'),
        );

        this.timeOutMilliSeconds = timeOutSeconds * 1000;

        this.idleSubscription = this.idle$.subscribe((res) => {
            this.resetTimer();
        });

        this.startTimer();
    }

    private startTimer() {
        this.timer$ = timer(this.timeOutMilliSeconds, this.timeOutMilliSeconds).subscribe((res) => {
            this.expiredSource.next(true);
        });
    }

    private startCountdownTimer() {
        this.countDownTimer$ = timer(1000, 1000).subscribe((res) => {
            this.countdownSource.next(true);
        });
    }

    private stopCountdownTimer() {
        if (this.countDownTimer$) {
            this.countDownTimer$.unsubscribe();
        }
    }

    public resetTimer() {
        if (this.timer$) {
            this.timer$.unsubscribe();
        }
        this.startTimer();
    }

    public stopTimer() {
        if (this.timer$) {
            this.timer$.unsubscribe();
        }
        if (this.idleSubscription) {
            this.idleSubscription.unsubscribe();
        }

        if (this.inactivityTimeoutSubscription) {
            this.inactivityTimeoutSubscription.unsubscribe();
        }
    }
}

import { merge, Observable } from 'rxjs';
import { AfterViewInit, Directive, ElementRef, HostListener, Inject, Injector } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { TAB } from '@angular/cdk/keycodes';
import { MixinUnsubscribeClass } from '@common/mixins';
import { debounceTime } from 'rxjs/operators';

type Element = HTMLElement & { disabled: boolean };

export const mutationObservable = (element: HTMLElement, options?: MutationObserverInit): Observable<MutationRecord[]> => {
    return new Observable((subscriber) => {
        const observer = new MutationObserver((mutationList) => {
            subscriber.next(mutationList);
        });

        observer.observe(element, options);

        return function unsubscribe() {
            observer.disconnect();
        };
    });
};

@Directive({
    selector: '[climbTrapFocus]',
})
export class TrapFocusDirective extends MixinUnsubscribeClass implements AfterViewInit {
    private focusableElements: HTMLElement[];
    get focusableElementsList(): HTMLElement[] {
        return this.focusableElements;
    }

    get firstFocusableElement(): HTMLElement {
        return this.focusableElements[0];
    }

    get lastFocusableElement(): HTMLElement {
        return this.focusableElements[this.focusableElements.length - 1];
    }

    @HostListener('keydown', ['$event'])
    onKeyDown(event: KeyboardEvent): void {
        const isTabPressed = event.keyCode === TAB;
        if (!isTabPressed) {
            return;
        }

        if (event.shiftKey) { /* shift + tab */
            if (this.document.activeElement === this.firstFocusableElement) {
                this.lastFocusableElement.focus();
                event.preventDefault();
            }
        } else { /* tab */
            if (this.document.activeElement === this.lastFocusableElement) {
                this.firstFocusableElement.focus();
                event.preventDefault();
            }
        }
    }

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private host: ElementRef,
        injector: Injector,
    ) {
        super(injector);
    }

    ngAfterViewInit(): void {
        this.setFocusableElements();

        const changedAttributeDisabled$ = mutationObservable(this.host.nativeElement, {
            subtree: true,
            attributeFilter: ['disabled'],
        });
        const changedChildNodesCount$ = mutationObservable(this.host.nativeElement, {
            childList: true,
        });

        this.subscription = merge(changedAttributeDisabled$, changedChildNodesCount$).pipe(
            debounceTime(100),
        ).subscribe(() => {
            this.setFocusableElements();
        });
    }

    private setFocusableElements(): void {
        this.focusableElements = this.findFocusableElements(this.host.nativeElement);
    }

    findFocusableElements(containerElement: HTMLElement): HTMLElement[] {
        const focusableNodes: NodeListOf<Element> = containerElement.querySelectorAll(
            '[href], button, input:not(input[type="hidden"]), textarea, select, details, [tabindex]:not([tabindex="-1"])'
        );
        return Array.from(focusableNodes).filter((element: Element) => !element.disabled);
    }
}

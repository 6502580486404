import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Inject,
    NgZone,
    Output,
    ViewChild,
    OnDestroy
} from '@angular/core';
import { DOCUMENT } from '@angular/common';

import {
    IMultiSelectOption,
    IMultiSelectSettings,
    IMultiSelectTexts,
    ɵa as NgxDropdownMultiselectComponent,
} from 'ngx-bootstrap-multiselect';

import { ResourceService } from '.';

import {
    arrayContainsAllValues,
    notEmpty,
    uniqueArrayFromPropertyPath
} from '../common/util';


/**
 * Selects 1 or many resources.
 * If many are selected a group resource is used behind the scenes as the model.
 *   (group resources are created automatically if they do not already exist)
 *
 *   Uses softsimon's Angular Multiselect Dropdown for Bootstrap component:
 *   https://github.com/softsimon/ngx-bootstrap-multiselect
 */
@Component({
    selector: 'climb-assigned-to-select',
    template: `
     <ngx-bootstrap-multiselect
        #dropDownComponent
        [settings]="settings" 
        [texts]="texts"
        [options]="options" 
        [(ngModel)]="selectedResourceKeys"
        (dropdownOpened)="dropdownOpened()"
        (dropdownClosed)="dropdownClosed()"
        [disabled]="disabled">
     </ngx-bootstrap-multiselect>
    `
})
export class ClimbAssignedToSelectComponent implements OnChanges, OnDestroy, OnInit {
    @ViewChild('dropDownComponent') dropDownComponent: NgxDropdownMultiselectComponent;
    @Input() model: number;
    @Input() disabled: boolean;

    @Output() modelChange: EventEmitter<any> = new EventEmitter<any>();

    options: IMultiSelectOption[];
    settings: IMultiSelectSettings;
    texts: IMultiSelectTexts;

    resources: any[] = [];
    private previousModel: number[] = [];
    selectedResourceKeys: number[] = [];


    constructor(
        private _element: ElementRef,
        private resourceService: ResourceService,
        private ngZone: NgZone,
        @Inject(DOCUMENT) private document: any
    ) { }

    ngOnInit() {
        this.settings = {
            buttonClasses: 'btn btn-secondary',
            checkedStyle: 'fontawesome',
            displayAllSelectedText: true
        };

        this.texts = {
            checked: 'resource selected',
            checkedPlural: 'resources selected',
            defaultTitle: 'Select...',
            allSelected: 'All selected',
        };

        this.getResources().then(() => {
            this.initializeSelection();
            this.options = this.toMultiSelectOptions(this.resources, this.selectedResourceKeys);
        });

        this.ngZone.runOutsideAngular(() => {
            this.document.addEventListener(
                'click',
                this.onDocumentClick
            );
        });
    }

    onDocumentClick(event: MouseEvent) {
        // Close if clicked outside of component
        if (event &&
            this._element &&
            !this._element.nativeElement.contains(event.target) &&
            this.dropDownComponent
        ) {
            this.dropdownClosed();
        }
    }

    ngOnDestroy() {
        this.document.removeEventListener('click', this.onDocumentClick);
    }

    ngOnChanges(changes: any) {
        if (changes.model && !changes.model.firstChange) {
            this.getResources().then(() => {
                this.initializeSelection();
                this.options = this.toMultiSelectOptions(this.resources, this.selectedResourceKeys);
            });
        }
    }

    getResources(): Promise<void> {
        return this.resourceService.getAllCachedResources().then((resources) => {
            this.resources = resources;
        });
    }

    initializeSelection() {
        if (!this.model) {
            this.selectedResourceKeys = [];
            this._forceModelUpdate();
            return;
        }

        if (!this.resources) {
            return;
        }

        // Gets object for assigned resource from key
        const assignedResource = this.resources.find((resource) => {
            return resource.C_Resource_key === this.model;
        });

        let newSelectedKeys: number[] = [];

        if (!assignedResource) {
            // we cannot select anything, because there is no matching resource
            newSelectedKeys = [];
        } else if (!assignedResource.IsGroup) {

            // Uses individual resource key if assigned resource is not a group
            newSelectedKeys = [this.model];

        } else {
            const members = this.getAllResourceGroupMembers();

            // Sets selection to list of resource keys for members of the assigned group
            newSelectedKeys = members.filter((member: any) => {
                return member.C_ParentResource_key === this.model;
            }).map((member: any) => {
                return member.C_Resource_key;
            });
        }

        this.selectedResourceKeys = newSelectedKeys;
        // force dropdown to recognize model change
        // See: https://github.com/softsimon/angular-2-dropdown-multiselect/issues/304
        this._forceModelUpdate();
    }

    getAllResourceGroupMembers(): any[] {
        return uniqueArrayFromPropertyPath(this.resources, 'ResourceGroupMember');
    }

    toMultiSelectOptions(items: any[], selectedKeys: number[]): IMultiSelectOption[] {
        let options: IMultiSelectOption[] = [];

        if (notEmpty(items)) {
            // Filters out resource groups from dropdown list
            items = items.filter((item) => {
                return !item.IsGroup;
            });

            items = this.filterActiveResource(items, selectedKeys);

            options = items.map((item: any) => {
                return {
                    id: item.C_Resource_key,
                    name: item.ResourceName
                };
            });
        }

        return options;
    }

    filterActiveResource(resources: any[], selectedKeys: number[]): any[] {
        if (!selectedKeys) {
            selectedKeys = [];
        }
        return resources.filter((resource) => {
            return resource.IsActive ||
                selectedKeys.indexOf(resource.C_Resource_key) >= 0;
        });
    }

    dropdownOpened() {
        // Sets previousModel for comparison at dropdownClosed()
        this.previousModel = [...this.selectedResourceKeys];
    }

    dropdownClosed() {
        if (!this.selectedResourceKeys ||
            arrayContainsAllValues(this.previousModel, this.selectedResourceKeys)
        ) {
            return;
        }

        let newValue: number;

        if (this.selectedResourceKeys.length === 0) {
            // No resource selected
            newValue = null;
        } else if (this.selectedResourceKeys.length === 1) {
            // 1 resource selected
            newValue = this.selectedResourceKeys[0];
        } else {
            // Multiple resources selected. Handle as a group.
            const resourceGroup = this.getSelectedResourceGroup();
            newValue = resourceGroup.C_Resource_key;
        }

        this.model = newValue;
        this.modelChange.emit(newValue);
    }

    /**
     * returns current selection as a single grouped resource.
     *   if matching resource group already exists, returns that,
     *   else creates new resource group
     */
    getSelectedResourceGroup(): any {
        const existingGroup = this.findExistingGroup();
        if (existingGroup) {
            return existingGroup;
        } else {
            const resourceGroup = this.createNewResourceGroup(this.selectedResourceKeys);
            this.selectedResourceKeys.forEach((resourceKey: any) => {
                this.createNewResourceGroupMember(
                    resourceKey,
                    resourceGroup.C_Resource_key
                );
            });

            return resourceGroup;
        }
    }

    findExistingGroup(): any {
        // Checks for existing group containing selected resources
        return this.resources.filter((resource) => {
            return resource.IsGroup;
        }).filter((group) => {
            const memberKeys = group.ParentResourceGroupMember.map((member: any) => {
                return member.C_Resource_key;
            });
            return arrayContainsAllValues(memberKeys, this.selectedResourceKeys);
        })[0];
    }

    createNewResourceGroup(resourceKeys: number[]) {
        let resourceNames: string[] = resourceKeys.map((resourceKey) => {
            return this.options.find((item) => item.id === resourceKey).name;
        });

        // sort names case-insensitive
        resourceNames = resourceNames.sort((a, b) => {
            return a.toLowerCase().localeCompare(b.toLowerCase());
        });

        const groupName = resourceNames.join(', ');
        const newResourceGroup = this.resourceService.createResourceGroup(groupName);

        // add to resource list for proper mapping
        this.resources.push(newResourceGroup);

        return newResourceGroup;
    }

    createNewResourceGroupMember(resourceKey: number, resourceGroupKey: number) {
        return this.resourceService.createResourceGroupMember(
            resourceKey,
            resourceGroupKey
        );
    }

    _forceModelUpdate() {
        if (!this.dropDownComponent) {
            return;
        }

        this.dropDownComponent.model = this.selectedResourceKeys;
    }
}

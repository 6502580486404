import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { OKTA_AUTH } from '@okta/okta-angular';
import { OktaAuth, Tokens, UserClaims } from '@okta/okta-auth-js';
import { LoginData } from "../../login.interface";
import { LoginService } from "../../services/login.service";
import { RoutingService } from "../../../routing/routing.service";
import { catchError, tap } from "rxjs/operators";
import { LogLevel } from "@services/models";
import { from } from "rxjs";
import { ToastrService } from "@services/toastr.service";
import { AuthService } from '@services/auth.service';


@Component({
    selector: 'climb-login-okta',
    templateUrl: './login-okta.component.html',
    styleUrls: ['./login-okta.component.scss']
})
export class LoginOktaComponent implements OnInit {
    readonly COMPONENT_LOG_TAG = 'login-okta';
    constructor(@Inject(OKTA_AUTH) public oktaAuth: OktaAuth, 
        private loginService: LoginService, 
        private routingService: RoutingService, 
        private toasterService: ToastrService,
        private authService: AuthService) {
    }

    async ngOnInit() {
        if (this.oktaAuth.isLoginRedirect()) {
            const tokens: Tokens = (await this.oktaAuth.token.parseFromUrl()).tokens;
            this.authService.setOktaTokens(tokens);
            const loginData = await this.loginSSOData(tokens);
            this.loginService.login(loginData).pipe(
                tap(() => {
                    this.routingService.navigateToHome();
                }),
                catchError((error) => {
                    const errorMessage = error.error_description ?? error.message ?? 'An unknown error has occurred.';
                    this.toasterService.showToast(errorMessage, LogLevel.Error);
                    return from(this.routingService.navigateToLogin());
                })
            ).subscribe();

        } else {
            this.oktaAuth.signInWithRedirect({
                scopes: ["openid", "email", "profile"],
            })
        }
    }

    private async loginSSOData(tokens: Tokens): Promise<LoginData> {
        const userInfo: UserClaims = await this.oktaAuth.token.getUserInfo(tokens.accessToken, tokens.idToken);
        return {
            userName: userInfo.preferred_username,
            password: '',
            email: userInfo.email,
            useRefreshTokens: false,
            sso: true,
            oktaToken: tokens.accessToken.accessToken
        };
    }
}

import { Injectable } from '@angular/core';
import { Entity as BreezeEntity } from 'breeze-client';
import {
    Animal,
    Entity as InternalEntity,
    Entity,
    Material,
    Resource,
    ResourceGroupMember,
    TaskInput,
    TaskInstance,
    TaskMaterial,
    TaskOutput,
    TaskOutputSet,
    TaskOutputSetMaterial
} from '@common/types';
import { DataManagerService } from '../../services/data-manager.service';
import { StoredFileService } from '../../common/file';
import {GlpBaseFacetStateService} from '@services/glp-base-facet-state.service';

@Injectable()
export class AnimalStateService extends GlpBaseFacetStateService<Animal> {

    constructor(protected dataManagerService: DataManagerService,
                private storedFileService: StoredFileService) {
        super(dataManagerService);
    }

    getRelatedCollectionChanges(animal: Entity<Animal>): BreezeEntity[] {
        const changes: any = [];

        changes.push(
            ...this.getChangesToEntityByKey('Event', 'C_Material_key', animal.C_Material_key),
            ...this.getChangesToEntityByKey('MaterialPoolMaterial', 'C_Material_key', animal.C_Material_key),
            ...this.getChangesToEntityByKey('JobMaterial', 'C_Material_key', animal.C_Material_key),
            ...this.getChangesToEntityByKey('TaxonCharacteristicInstance', 'C_Material_key', animal.C_Material_key),
            ...this.getChangesToEntityByKey('Genotype', 'C_Material_key', animal.C_Material_key),
            ...this.getChangesToEntityByKey('AnimalComment', 'C_Material_key', animal.C_Material_key),
            ...this.storedFileService.getChangesRelatedToMaterial(animal.C_Material_key),
        );
        const instancesKeys = new Set((animal as Animal)?.Material?.TaskMaterial?.map(material => material.C_TaskInstance_key) ?? []);
        const taskMaterials = this.getChangesToEntityByKey('TaskMaterial', 'C_Material_key', animal.C_Material_key);
        for (const material of (taskMaterials as Entity<TaskMaterial>[])) {
            instancesKeys.add(material.C_TaskInstance_key);
        }

        const taskInstances = this.getChangesToTaskInstances(instancesKeys);
        changes.push(
            ...taskMaterials,
            ...taskInstances,
            ...this.getChangesToTaskInput(instancesKeys),
        );

        const taskOutputSets = this.getChangesToTaskOutputSet(instancesKeys);

        if (taskOutputSets.length) {
            const taskOutputSetKeys = this.getRelatedTaskOutputSetsKeys(taskOutputSets);
            const taskOutputs = this.getChangesToTaskOutputs(taskOutputSetKeys);

            changes.push(...taskOutputSets, ...taskOutputs, ...this.getChangesToTaskOutputSetMaterial(taskOutputSetKeys));
        }

        const resources = this.getChangesToResources(taskInstances);
        changes.push(
            ...resources,
            ...this.getChangesToResourceGroups(resources)
        );

        return changes;
    }

    getDeleteNavPropertyChanges(entity: InternalEntity<Animal>): BreezeEntity[] {
        const changes: any = [];
        changes.push(...this.getChangesToDeletedMaterial(entity))
        return changes;
    }

    private getChangesToDeletedMaterial(animal: Animal): InternalEntity<Material>[] {
        return this.dataManagerService
            .getChangesToRelatedDeletedEntityByKey('Material', 'C_Material_key', animal.C_Material_key);
    }

    getRelatedTaskOutputSetsKeys(taskOutputSets: Entity<TaskOutputSet>[]): Set<number> {
        const taskOutputSetKeys = new Set<number>(taskOutputSets.map(taskOutputSet => taskOutputSet.C_TaskOutputSet_key));

        for (const taskOutputSet of taskOutputSets) {
            taskOutputSetKeys.add(taskOutputSet.C_TaskOutputSet_key);
        }

        return taskOutputSetKeys;
    }

    getChangesToEntityByKey(
        entityName: string,
        key: string,
        value: any
    ) {
        return this.dataManagerService.getChangesToEntityByFilter(entityName, (item: BreezeEntity) => {
            // Event and AnimalComment have different behavior if user delete it
            if (item.entityAspect.entityState.isDeleted()) {
                return item[key] === value || item.entityAspect.originalValues[key] === value;
            }
            return item[key] === value;
        });
    }

    getChangesToTaskInstances(instanceKeys: Set<number>): Entity<TaskInstance>[] {
        const taskInstances = (this.dataManagerService.getManager().getChanges('TaskInstance') ?? []) as Entity<TaskInstance>[];
        return taskInstances.filter(task => instanceKeys.has(task.C_TaskInstance_key));
    }

    getChangesToTaskInput(instanceKeys: Set<number>): Entity<TaskInput>[] {
        const taskInputs = (this.dataManagerService.getManager().getChanges('TaskInput') ?? []) as Entity<TaskInput>[];
        return taskInputs.filter(input => instanceKeys.has(input.C_TaskInstance_key));
    }

    getChangesToTaskOutputs(taskOutputSetKeys: Set<number>): Entity<TaskOutput>[] {
        const taskOutputs = (this.dataManagerService.getManager().getChanges('TaskOutput') ?? []) as Entity<TaskOutput>[];
        return taskOutputs.filter(taskOutput => taskOutputSetKeys.has(taskOutput.C_TaskOutputSet_key));
    }

    getChangesToTaskOutputSet(instanceKeys: Set<number>): Entity<TaskOutputSet>[] {
        const taskOutputSets = (this.dataManagerService.getManager().getChanges('TaskOutputSet') ?? []) as Entity<TaskOutputSet>[];
        return taskOutputSets.filter(taskOutputSet => instanceKeys.has(taskOutputSet.C_TaskInstance_key));
    }

    getChangesToTaskOutputSetMaterial(taskOutputSetKeys: Set<number>): Entity<TaskOutputSetMaterial>[] {
        const taskOutputSetMaterials = (this.dataManagerService.getManager().getChanges('TaskOutputSetMaterial') ?? []) as Entity<TaskOutputSetMaterial>[];
        return taskOutputSetMaterials.filter(taskOutputSetMaterial => taskOutputSetKeys.has(taskOutputSetMaterial.C_TaskOutputSet_key));
    }

    getChangesToResources(taskInstances: Entity<TaskInstance>[]) {
        const resources = (this.dataManagerService.getManager().getChanges('Resource') ?? []) as Entity<Resource>[];
        return resources.filter(resource => taskInstances.some(ti => ti.C_AssignedTo_key === resource.C_Resource_key));
    }

    getChangesToResourceGroups(resources: Entity<Resource>[]) {
        const resourceGroupMembers = (this.dataManagerService.getManager().getChanges('ResourceGroupMember') ?? []) as Entity<ResourceGroupMember>[];
        return resourceGroupMembers.filter(rgm => resources.some(r => r.C_Resource_key === rgm.C_ParentResource_key));
    }

    discardChanges(entity: Entity<Animal>): void {
        // TODO: implement discard
        throw new Error('Method not implemented.');
    }
}

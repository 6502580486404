import type { Allele } from './allele.interface';
import type { Line } from './line.interface';
import type { LineMarker } from './line-marker.interface';
import type { Primer } from './primer.interface';
import type { cv_AlleleClass } from './cv-allele-class.interface';
import type { cv_MarkerType } from './cv-marker-type.interface';
import type { cv_Taxon } from './cv-taxon.interface';

export interface Marker {
    Allele: Allele[];
    BuildNumber: string;
    C_AlleleClass_key: number | null;
    C_Line_key: number | null;
    C_MarkerType_key: number;
    C_Marker_key: number;
    C_Taxon_key: number;
    C_Workgroup_key: number;
    Chromosome: string;
    CmPostition: number | null;
    Comments: string;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    EndCoordinate: number | null;
    LabSymbol: string;
    Line: Line;
    LineMarker: LineMarker[];
    MarkerName: string;
    MarkerSymbol: string;
    MarkerSymbolMarkup: string;
    MbPosition: number | null;
    ModifiedBy: string;
    Primer1: Primer[];
    Primer: string;
    StartCoordinate: number | null;
    Strand: string;
    Version: number;
    cv_AlleleClass: cv_AlleleClass;
    cv_MarkerType: cv_MarkerType;
    cv_Taxon: cv_Taxon;
}

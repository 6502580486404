import { EntityChangesModule } from './../entity-changes/entity-changes.module';
import { DataContextService } from './data-context.service';
import { LogoutService } from './logout.service';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthService } from './auth.service';
import { AdminManagerService } from './admin-manager.service';
import { DataManagerService } from './data-manager.service';
import { EntityManagerFactoryService } from './entity-manager-factory.service';
import { FileService } from './file.service';
import { InactivityTimeoutService } from './inactivity-timeout.service';
import { LocalStorageService } from './local-storage.service';
import { LoggingService } from './logging.service';
import { NamingService } from './naming.service';
import { PrivilegeService } from './privilege.service';
import { RoleService } from './role.service';
import { SaveChangesService } from './save-changes.service';
import { ToastrService } from './toastr.service';
import { TranslationService } from './translation.service';
import { WebApiService } from './web-api.service';
import { WorkspaceManagerService } from './workspace-manager.service';
import { CurrentWorkgroupService } from './current-workgroup.service';
import { EnvironmentService } from './environment.service';
import { WorkspaceFilterService } from './workspace-filter.service';
import { SlackService } from './slack.service';

import { DeletionService } from './deletion.service';
import { MaterialService } from './material.service';
import { MaterialPoolService } from './material-pool.service';

import { AppInsightsService } from './app-insights.service';
import { OverlayService } from './overlay-service';
import { EmailService } from './email.service';
import { ModificationService } from './modification.service';
import { TabStorageService } from '../multiply-tab/tab-storage.service';
import { HttpCancelService } from './http-cancel.service';
import { TaskInstanceService } from './task-instance.service';

/**
 * Provides core data services for Climb
 */
@NgModule({
    imports: [
        CommonModule,
        EntityChangesModule
    ]
})
export class ClimbServicesModule { 
    static forRoot(): ModuleWithProviders<ClimbServicesModule> {
        return {
            ngModule: ClimbServicesModule,
            providers: [
                AppInsightsService,
                AuthService,
                AdminManagerService,
                CurrentWorkgroupService,
                DataContextService,
                DataManagerService,
                DeletionService,
                EntityManagerFactoryService,
                EnvironmentService,
                FileService,
                HttpCancelService,
                InactivityTimeoutService,
                LocalStorageService,
                LoggingService,
                LogoutService,
                MaterialPoolService,
                MaterialService,
                NamingService,
                PrivilegeService,
                RoleService,
                SaveChangesService,
                SlackService,
                ToastrService,
                TranslationService,
                WebApiService,
                WorkspaceManagerService,
                WorkspaceFilterService,
                OverlayService,
                EmailService,
                ModificationService,
                TabStorageService,
                TaskInstanceService,
            ]
        };
    }
}

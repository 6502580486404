<loading-overlay *ngIf="loading"></loading-overlay>

<ng-container *ngIf="selectedSearch">

    <ng-container *ngIf="searchParams | notEmpty">
        <ng-container *ngFor="let searchParam of searchParams; let paramIndex = index">
            <data-type-input [id]="'param-' + paramIndex + '-' + domIdAddition"
                                [ioObject]="searchParam"
                                [(value)]="searchParam.Value"
                                [inputLabel]="searchParam.ParameterName"
                                [forceInputOnNewLine]="true"></data-type-input>
        </ng-container>
    </ng-container>

    <div class="form-group">
        <div class="btn-container report-run-button">
            <button class="btn btn-fw-xwide btn-primary"
                    type="submit"
                    [disabled]="searching"
                    (click)="performSearch()">
                <i *ngIf="searching"
                   class="fa fa-spinner fa-spin"
                   title="Running report&hellip;"></i>
                <span *ngIf="!searching">Run Report</span>
            </button>
        </div>
    </div>


    <div class="results-container"
         *ngIf="searchPerformed">
        <div class="export-btn-container">
            <button type="button"
                    class="btn btn-secondary"
                    title="Export results to CSV"
                    (click)='exportToCsv()'>
                <i class="fa fa-fw fa-download" aria-hidden="true"></i>Export Results
            </button>
        </div>

        <div class="results-table-wrapper">
            <custom-search-results [customSearch]="selectedSearch"
                                   [resultsData]="resultsData"
                                   (clickPage)="clickPage($event)"></custom-search-results>
        </div>
    </div>

</ng-container>

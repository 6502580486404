import { LocalStorageKey } from './../config';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { LocalStorageService } from './local-storage.service';
import {
    apiServiceBaseUriAdmin,
    webBase,
} from '../config/climb-web-settings';
import { AccessToken, RefreshToken, Token, Tokens } from '@okta/okta-auth-js';

export interface AuthenticationData {
    token: string;
    userName: string;
    userId: any;
    refreshToken: string;
    useRefreshTokens: boolean;
    isOktaSSO: boolean;
}

@Injectable()
export class AuthService {
    authentication = {
        isAuth: false,
        userName: "",
        useRefreshTokens: false,
        isOktaSSO: false
    };

    private authenticationSuccessSource = new Subject<void>();
    authenticationSuccessSource$ = this.authenticationSuccessSource.asObservable();

    private oktaTokens: Tokens;

    constructor(
        private _http: HttpClient,
        private _localStorageService: LocalStorageService,
    ) { }

    getCurrentToken(): string | null {
        try {
            const authData: AuthenticationData = this._localStorageService.get(LocalStorageKey.AUTH_DATA);
            return authData.token ?? null;
        } catch {
            return null;
        }
    }

    setOktaTokens(tokens: Tokens): void {
        this.oktaTokens = tokens;
    } 

    getOktaRefreshToken(): RefreshToken {
        return this.oktaTokens?.refreshToken;
    }

    getOktaAccessToken(): AccessToken {
        return this.oktaTokens?.accessToken;
    }
    getCurrentUserId() {
        try {
            const authData: AuthenticationData = this._localStorageService.get(LocalStorageKey.AUTH_DATA);
            return authData.userId ?? '';
        } catch {
            return '';
        }
    }

    getCurrentUserName(): string {
        try {
            const authData: AuthenticationData = this._localStorageService.get(LocalStorageKey.AUTH_DATA);
            return authData.userName ?? '';
        } catch {
            return '';
        }
    }

    setAuthorizationData(data: AuthenticationData): void {
        this._localStorageService.set(LocalStorageKey.AUTH_DATA, data);

        this.authentication.isAuth = true;
        this.authentication.userName = data.userName;
        this.authentication.useRefreshTokens = data.useRefreshTokens;
        this.authentication.isOktaSSO = data.isOktaSSO;
    }

    isAuthenticated(): boolean {
        return this.authentication ? this.authentication.isAuth && this.getCurrentToken() !== null : false;
    }

    isAuthenticatedOkta(): boolean {
        return this.authentication?.isOktaSSO ?? false;
    }

    saveRegistration(registration: any): Observable<any> {
        this.clearAuthData();

        debug("registering user");
        const regUrl = `${apiServiceBaseUriAdmin}api/account/register`;
        return this._http.post(regUrl, registration).pipe(tap(() => {
            debug("done registering user");
        }));
    }

    registrationEmail(registration: any): Observable<any> {

        this.clearAuthData();
        registration.ResetUrl = webBase.concat('emailverification');

        const regUrl = `${apiServiceBaseUriAdmin}api/Account/registrationEmail`;
        return this._http.post(regUrl, registration).pipe(tap(() => {
            debug("pre Registration email done");
        }));
    }

    verificationEmail(verification: any): Observable<any> {

        this.clearAuthData();

        const regUrl = `${apiServiceBaseUriAdmin}api/Account/emailVerification?emailVerificationCode=` + verification;
        return this._http.post(regUrl, verification).pipe(tap(() => {
            debug("verification email done");
        }));
    }

    completeRegistration(registrationData: any, verification: any): Observable<any> {

        this.clearAuthData();

        const regUrl = `${apiServiceBaseUriAdmin}api/Account/completeRegistration?emailVerificationCode=` + verification;
        return this._http.post(regUrl, registrationData).pipe(tap(() => {
            debug("registration process done");
        }));
    }

    clearAuthData(): void {
        this._localStorageService.remove(LocalStorageKey.AUTH_DATA);
        this.authentication.isAuth = false;
        this.authentication.userName = "";
        this.authentication.useRefreshTokens = false;
    }

    clearWorkgroupData(): void {
        this._localStorageService.remove(LocalStorageKey.WORKGROUP_KEY);
        this._localStorageService.remove(LocalStorageKey.WORKGROUP_NAME);
    }

    clearSSOData(): void {
        this._localStorageService.removeSSO(LocalStorageKey.SSO_ID_TOKEN);
        this._localStorageService.removeSSO(LocalStorageKey.OKTA_TOKEN_STORAGE);
        this._localStorageService.removeSSO(LocalStorageKey.OKTA_CACHE_STORAGE);
        this._localStorageService.removeSSO(LocalStorageKey.SSO_ID_TOKEN);
    }

    clearFeatureFlagData(): void {
        this._localStorageService.remove(LocalStorageKey.FEATURE_FLAGS);
    }

    clearSessionData(): void {
        const localstorageKeysToClear = [
            LocalStorageKey.CURRENT_POSITION_STORAGE_KEY,
            LocalStorageKey.ANIMAL_SYNC_WARNING,
            LocalStorageKey.CLINIC_SYNC_WARNING,
            LocalStorageKey.WORKGROUP_KEY,
            LocalStorageKey.WORKGROUP_NAME,
            LocalStorageKey.WORKGROUP_TIMEZONE_INFO,
            LocalStorageKey.INACTIVITY_TIMEOUT_MINUTES,
        ];
        for (const key of localstorageKeysToClear) {
            this._localStorageService.remove(key);
        }
    }

    fillAuthData(): void {
        const authData: AuthenticationData = this._localStorageService.get(LocalStorageKey.AUTH_DATA);
        if (authData) {
            this.authentication.isAuth = true;
            this.authentication.userName = authData.userName;
            this.authentication.useRefreshTokens = authData.useRefreshTokens;
            this.authenticationSuccessSource.next();
        }
    }

    resetPassword(resetPasswordParams: any): Observable<any> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        const options = { headers };
        const resetPassURL = `${apiServiceBaseUriAdmin}api/account/resetPassword`;

        return this._http.post(resetPassURL, resetPasswordParams, options);
    }

    requestPasswordReset(passwordResetRequest: any): Observable<any> {
        passwordResetRequest.RequestingUser = "system";
        passwordResetRequest.ResetUrl = webBase.concat("pwdreset");
        const requestResetURL = `${apiServiceBaseUriAdmin}api/account/RequestPasswordReset`;

        return this._http.post(requestResetURL, passwordResetRequest);
    }

    validatePasswordResetRequest(resetRequestId: any): Observable<any> {
        const serviceUrl = apiServiceBaseUriAdmin +
            'api/account/ValidateResetRequest?resetRequestId=' +
            resetRequestId;

        return this._http.get(serviceUrl);
    }

    authErrorsToString(errorData: any): string {
        if (errorData) {
            const errors = [];
            for (const key in errorData.ModelState) {
                if (!errorData.ModelState.hasOwnProperty(key)) {
                    continue;
                }

                for (const modelStateItem of errorData.ModelState[key]) {
                    errors.push(modelStateItem);
                }
            }

            return errors.join(' ');
        } else {
            return '';
        }
    }
}

// TODO: figure out proper logging in Angular 2
function debug(text: string) {
    // eslint-disable-next-line
    console.log(text);
}

<div class="detail-form-wrapper">

    <div class="detail-header">
        <h3>{{taskType | translate}} Task</h3>

        <pager-toolbar [itemName]="'task'"
                       [logTag]="COMPONENT_LOG_TAG"
                       [first]="pageState.isFirst"
                       [last]="pageState.isLast"
                       (previous)="previousClicked($event)"
                       (next)="nextClicked($event)"></pager-toolbar>

        <detail-toolbar [facetPrivilege]="facet.Privilege"
                        [logTag]="COMPONENT_LOG_TAG"
                        (exit)="exitClicked($event)"
                        [emitOnSave]="true"
                        (genericSaveEmitter)="onSaveTaskInstance()">

            <div class="btn-group" role="group" aria-label="Edit actions" additional-groups>

                <!-- Enclose Notes in btn-group b/c NG component tag messes up Bootstrap -->
                <div class="btn-group" view-notes-button
                    [readonly]="facet.Privilege ==='ReadOnly'"
                    [pkName]="'C_TaskInstance_key'"
                    [pkValue]="task.C_TaskInstance_key"
                    [objectName]="task.TaskAlias"
                    [objectType]="'Task'"
                    [isObjectNew]="task | isNew"
                    appInsightsClick="click-notes-from-taskinstance">
                </div>

                <button type="button"
                        class="btn btn-secondary"
                        (click)="viewAuditReport()"
                        [appInsightsClick]="'click-workflow-audit'">
                    <i class="fa fa-flag" aria-hidden="true" title="Audit"></i>
                    <span class="gridster-d-sm-none gridster-d-md-inline-block">Audit</span>
                    <span class="sr-only">Audit</span>
                </button>

                <div class="btn-group" role="group">
                    <button type="button"
                            class="btn btn-secondary dropdown-toggle"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false">
                        <i class="fa fa-download" aria-hidden="true" title="Export data to CSV or PDF"></i>
                        <span class="gridster-d-sm-none gridster-d-md-inline-block">Export</span>
                        <span class="sr-only">Export</span>
                    </button>
                    <div class="dropdown-menu">
                        <a (click)="exportWorkflow(exportTypes.CSV)"
                            class="dropdown-item"
                            appInsightsClick="click-list-view-export-csv">
                            CSV
                        </a>
                        <a (click)="exportWorkflow(exportTypes.PDF)"
                            class="dropdown-item"
                            appInsightsClick="click-list-view-export-pdf">
                            PDF
                        </a>

                    </div>
                </div>

                <button type="button"
                        class="btn btn-secondary"
                        (click)="printWorkflow()"
                        [appInsightsClick]="'click-workflow-print'">
                    <i class="fa fa-print" aria-hidden="true" title="Print"></i>
                    <span class="gridster-d-sm-none gridster-d-md-inline-block">Print</span>
                    <span class="sr-only">Print</span>
                </button>
            </div>

        </detail-toolbar>
    </div>
    <loading-overlay *ngIf="loading" [message]="loadingMessage"></loading-overlay>
    <form *ngIf="task && task.WorkflowTask"
          class="detail-form">

        <fieldset [disabled]="facet.Privilege === 'ReadOnly'">

            <!-- For now only display lock icon for job tasks -->
            <div class="form-group row" *ngIf="taskType === TaskType.Job">
                <label class="col-md-2 col-form-label">{{'Job' | translate}} Lock</label>
                <div class="col-md-10">
                    <p>
                        <lock-control [(model)]="task.IsLocked"
                                      [readonly]="true"
                                      [largeText]="true"></lock-control>
                    </p>
                </div>
            </div>

            <div class="form-group row">
                <label class="col-md-2 col-form-label">Task Name</label>
                <div class="col-md-10">
                    <p class="form-control-plaintext">{{task.TaskAlias}}</p>
                </div>
            </div>

            <div *ngIf="showTaskName" class="form-group row">
                <label class="col-md-2 col-form-label">Task</label>
                <div class="col-md-10">
                    <p class="form-control-plaintext">{{task.WorkflowTask.TaskName}}</p>
                </div>
            </div>

            <div class="form-group row">
                <label class="col-md-2 col-form-label">Task Location</label>
                <div class="col-md-10">
                    <p class="form-control-plaintext">{{task.CurrentLocationPath}}</p>
                </div>
            </div>

            <!-- Core details by taskType -->
            <ng-container [ngSwitch]="taskType">
                <!-- Animal -->
                <ng-container *ngSwitchCase="TaskType.Animal">
                    <ng-container *ngIf="task.TaskMaterial | taskFilter:TaskType.Animal | notEmpty">
                        <workflow-animal-details [animal]="task.TaskMaterial[0].Material?.Animal"></workflow-animal-details>
                    </ng-container>
                </ng-container>

                <!-- Birth -->
                <ng-container *ngSwitchCase="TaskType.Birth">
                    <ng-container *ngIf="task.TaskBirth | notEmpty">
                        <workflow-birth-details [birth]="task.TaskBirth[0].Birth"></workflow-birth-details>
                    </ng-container>
                </ng-container>

                <!-- Housing -->
                <ng-container *ngSwitchCase="TaskType.Housing">
                    <ng-container *ngIf="task.TaskMaterialPool | notEmpty">
                        <workflow-housing-details [materialPool]="task.TaskMaterialPool[0].MaterialPool"></workflow-housing-details>
                    </ng-container>
                </ng-container>

                <!-- Job -->
                <ng-container *ngSwitchCase="TaskType.Job">
                    <ng-container *ngIf="task.TaskJob | notEmpty">
                        <workflow-job-details [job]="task.TaskJob[0].Job"></workflow-job-details>
                    </ng-container>
                </ng-container>

                <!-- Line -->
                <ng-container *ngSwitchCase="TaskType.Line">
                    <ng-container *ngIf="task.TaskLine | notEmpty">
                        <workflow-line-details [line]="task.TaskLine[0].Line"></workflow-line-details>
                    </ng-container>
                </ng-container>

                <!-- Mating -->
                <ng-container *ngSwitchCase="TaskType.Mating">
                    <ng-container *ngIf="task.TaskMaterialPool | notEmpty">
                        <workflow-mating-details [mating]="task.TaskMaterialPool[0].MaterialPool?.Mating"></workflow-mating-details>
                    </ng-container>
                </ng-container>

                <!-- Invalid Type -->
                <div class="form-group text-error" *ngSwitchDefault>
                    Invalid task type: {{taskType}}
                </div>
            </ng-container><!-- /Core details by taskType -->

            <div class="form-group row">
                <label class="col-md-2 col-form-label">Due Date</label>
                <div class="col-md-6">
                    <climb-ngb-date #dateControl="ngModel"
                                    name="Due Date"
                                    [(ngModel)]="task.DateDue"
                                    [disabled]="task.IsLocked || task.IsWorkflowLocked"
                                    [allowTime]="true"
                                    [allowNow]="true"
                                    (ngModelChange)="dueDateChanged()"></climb-ngb-date>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-md-2 col-form-label">Allowance</label>
                <div class="col-md-6">
                    <p class="form-control-plaintext">{{task.Deviation}}</p>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-md-2 col-form-label">Cohorts</label>
                <div class="col-md-6">
                    <div *ngFor="let cohort of cohorts">
                        <div id="drag-animals-cohort-{{cohort.C_Cohort_key}}"
                             class="cohort-badge"
                             [ngStyle]="{ backgroundColor: cohort.BackgroundColor, color: cohort.ForegroundColor }">
                            {{cohort.CohortName}}
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-md-2 col-form-label">Duration</label>
                <div class="col-md-6">
                    <input type="text" name="taskDuration"
                           [(ngModel)]="task.Duration"
                           [disabled]="task.IsLocked || task.IsWorkflowLocked"
                           class="form-control input-medium" />
                </div>
            </div>
            <div class="form-group row">
                <label class="col-md-2 col-form-label">Complete Date</label>
                <div class="col-md-6">
                    <climb-ngb-date #dateControl="ngModel"
                                    name="Complete Date"
                                    [(ngModel)]="task.DateComplete"
                                    [allowTime]="true"
                                    [allowNow]="true"
                                    (ngModelChange)="completeDateChanged($event)"
                                    [disabled]="task.IsWorkflowLocked"></climb-ngb-date>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-md-2 col-form-label">Status</label>
                <div class="col-md-10">
                    <active-vocab-select [(model)]="task.C_TaskStatus_key"
                                         [vocabChoices]="taskStatuses"
                                         [keyFormatter]="taskStatusKeyFormatter"
                                         [optionFormatter]="taskStatusFormatter"
                                         (modelChange)="taskStatusChanged()"
                                         [readonly]="task.IsWorkflowLocked"></active-vocab-select>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-md-2 col-form-label">Assigned To</label>
                <div class="col-md-10">
                    <climb-assigned-to-select [(model)]="task.C_AssignedTo_key"
                                              [disabled]="task.IsLocked || task.IsWorkflowLocked"></climb-assigned-to-select>
                </div>
            </div>
            <div class="form-group row"
                 *ngIf="task.CreatedBy">
                <label class="col-md-2 col-form-label">Created</label>
                <div class="col-md-10">
                    <p class="form-control-plaintext">
                        {{task.CreatedBy | userFullName}}
                        &middot;
                        {{task.DateCreated | formatShortDate}}
                    </p>
                </div>
            </div>
        </fieldset>

        <!-- Fieldset that is never disabled -->
        <fieldset>
            <div class="form-group row">
                <label class="col-md-2 col-form-label">Files</label>
                <div class="col-md-10">
                    <div class="form-control-plaintext">
                        <facet-detail-file-upload [pkName]="'C_TaskInstance_key'"
                                                  [pkValue]="task.C_TaskInstance_key"
                                                  [facetPrivilege]="facet.Privilege"></facet-detail-file-upload>
                    </div>
                </div>
            </div>
            <br />

            <div class="form-group row" *ngIf="task.showAnimals">
                <div class="col-md-12">
                    <workflow-animal-table [task]="task"
                                           [taskMaterials]="task.TaskMaterial"
                                           [readonly]="facet.Privilege === 'ReadOnly' || !areInputsValid || task.IsWorkflowLocked"
                                           (addAnimalMaterials)="addOutputsForAnimals($event)"></workflow-animal-table>
                </div>
            </div>

            <div class="form-group row" *ngIf="task.showSamples">
                <div class="col-md-12" *ngIf="primarySamples !== null">
                    <workflow-sample-table [task]="task"
                                           [taskMaterials]="task.TaskMaterial"
                                           [primarySamples]="primarySamples"
                                           [readonly]="facet.Privilege === 'ReadOnly' || !areInputsValid || task.IsWorkflowLocked"
                                           (addSampleMaterials)="addOutputsForSamples($event)"></workflow-sample-table>
                </div>
            </div>

            <div class="form-group row">
                <div class="col-md-12">
                    <table class="table table-bordered">
                        <thead>
                            <tr class="detail-grid-header">
                                <th [attr.colspan]="task.TaskInput?.length">
                                    Inputs
                                </th>
                            </tr>
                            <tr class="detail-grid-header">
                                <th *ngFor="let taskInput of task.TaskInput | orderBy:'Input.SortOrder'"
                                    [ngClass]="{'num-cell': (taskInput.Input?.cv_DataType?.DataType === 'Number')}"
                                    scope="col">
                                    {{taskInput.Input?.InputName}}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td *ngFor="let taskInput of taskInputs | orderBy:'Input.SortOrder'"
                                    [ngClass]="{'num-cell': (taskInput.Input?.cv_DataType?.DataType === 'Number')}">
                                    <span *ngIf="taskInput.Input?.cv_DataType?.DataType === 'Date'">
                                        {{taskInput.InputValue | formatShortDate}}
                                    </span>
                                    <span *ngIf="taskInput.Input?.cv_DataType?.DataType !== 'Date'">
                                        {{taskInput.InputValue || '[No value specified]'}}
                                    </span>
                                    <small *ngIf="taskInput.Input?.RequiresValidation && !taskInput.InputValue"
                                            class="form-text text-muted">
                                        <i class="fa fa-exclamation-triangle text-danger"></i>
                                            Input cannot be validated
                                    </small>
                                </td>
                            </tr>
                            <tr *ngIf="requiresInputValidationRow()">
                                <td *ngFor="let taskInput of task.TaskInput | orderBy:'Input.SortOrder'; index as i">
                                    <ng-container *ngIf="taskInput.Input?.RequiresValidation && taskInput.InputValue">
                                        <div class="col-md-11" style="padding-left:0;">
                                            <data-type-input
                                                    [id]="'tii-' + i + '-' + taskInput.C_Input_key + taskInput.C_TaskInput_key"
                                                    [ioObject]="taskInput.Input"
                                                    [(value)]="taskInput.ValidatedInputValue"
                                                    [ngClass]="!isInputValid(taskInput) ? 'has-error' : ''"
                                                    (valueChange)="inputValidationChanged()"></data-type-input>
                                        </div>
                                        <div class="col-md-1" style="padding-left:0;padding-top:5px;">
                                            <warning-tooltip
                                                *ngIf="!isInputValid(taskInput); else validInputTmpl"
                                                [text]="'Validation is required.  This value must match the input above.'">
                                            </warning-tooltip>
                                            <ng-template #validInputTmpl>
                                                <i class="fa fa-check text-success"></i>
                                            </ng-template>
                                        </div>
                                    </ng-container>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="form-group row" *ngIf="workflowTaskOutputs | notEmpty">
                <div class="col-md-12" *ngIf="primarySamples !== null">
                    <workflow-output-table #workflowOutputTable
                                           [task]="task"
                                           [primarySamples]="primarySamples"
                                           [facetPrivilege]="facet.Privilege"
                                           [readonly]="facet.Privilege==='ReadOnly' || areInputsValid === false || task.IsWorkflowLocked"
                                           (clickedImport)="scrollToImport()"></workflow-output-table>
                </div>
            </div>

            <!--Import data file-->
            <div class="form-group row"
                 *ngIf="!isGLP && facet.Privilege === 'ReadWrite' && (workflowTaskOutputs | notEmpty) && !task.IsWorkflowLocked">
                <div class="col-12">
                    <h4 class="my-2">
                        <a (click)="importDetailsExpanded = !importDetailsExpanded"
                           class="import-outputs-link"
                           [attr.title]="importDetailsExpanded  ? 'Hide import controls' : 'Show  import controls'">
                            Import Outputs<i class="fa fa-fw"
                                             [ngClass]="importDetailsExpanded ? 'fa-chevron-up' : 'fa-chevron-down'"
                                             [attr.title]="importDetailsExpanded  ? 'Hide import controls' : 'Show  import controls'"
                                             aria-hidden="true"></i>
                        </a>
                    </h4>
                </div>
                <div class="col-12 col-md-6 mb-3" *ngIf="importDetailsExpanded">
                    <h4>Download Template</h4>
                    <label>
                        <input type="checkbox"
                               [(ngModel)]="includeAnimalNames"
                               name="includeAnimalNames">
                        Include "Animal" in download template
                        <info-tooltip [text]="'Pre-populate the animal details in the Template form'"></info-tooltip>
                    </label>
                    <br />
                    <label>
                        <input type="checkbox"
                               [(ngModel)]="includeSampleNames"
                               name="includeSampleNames">
                        Include "Sample" in download template
                        <info-tooltip [text]="'Pre-populate the sample details in the Template form'"></info-tooltip>
                    </label>
                    <br />
                    <h4>Import File</h4>
                    <label>
                        <input type="checkbox"
                               [(ngModel)]="attachImportedFile"
                               name="attachImportedFile">
                        Attach Imported File
                    </label>
                    <br />
                    <br />
                    <import-file-upload [readonly]="false"
                                        [facetPrivilege]="facet.Privilege"
                                        (fileChange)="fileUpdate($event)">
                    </import-file-upload>
                </div>
                <div class="col-12 col-md-6 mb-3" *ngIf="importDetailsExpanded">
                    <h4>File Format</h4>
                    <table class="table table-sm">
                        <thead>
                            <tr>
                                <th>Column</th>
                                <th>Description</th>
                                <th>Read Only</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td><code>Collected Date</code></td>
                                <td>The date the value was collected</td>
                                <td>false</td>
                            </tr>
                            <tr>
                                <td><code>Collected By</code></td>
                                <td>The resource name of the person who collected the value</td>
                                <td>false</td>
                            </tr>
                            <tr>
                                <td><code>Animal ID</code></td>
                                <td>The animal ID of the animal associated with the output</td>
                                <td>true</td>
                            </tr>
                            <tr>
                                <td><code>Animal Name</code></td>
                                <td>The name of the animal associated with the output</td>
                                <td>true</td>
                            </tr>
                            <tr>
                                <td><code>Microchip ID</code></td>
                                <td>The microchip ID of the animal associated with the output</td>
                                <td>true</td>
                            </tr>
                            <tr>
                                <td><code>External ID</code></td>
                                <td>The external ID of the animal associated with the output</td>
                                <td>true</td>
                            </tr>
                            <tr *ngIf="isGLP">
                                <td><code>Alternate Physical ID</code></td>
                                <td>The alternate physical ID assigned to the animal</td>
                                <td>true</td>
                            </tr>
                            <tr>
                                <td><code>Sample ID</code></td>
                                <td>The sample ID of the sample associated with the output</td>
                                <td>true</td>
                            </tr>
                            <tr>
                                <td><code>Sample Name</code></td>
                                <td>The name of the sample associated with the output</td>
                                <td>true</td>
                            </tr>
                            <tr *ngFor="let workflowTaskOutput of workflowTaskOutputs | orderBy : 'SortOrder'">
                                <td><code>{{workflowTaskOutput?.OutputName}}</code></td>
                                <td>Output value</td>
                                <td>false</td>
                            </tr>
                        </tbody>
                    </table>
                    <p class="float-right">
                        <button type="button"
                                class="btn btn-primary"
                                [disabled]="!filenameInCloud || importInProgress"
                                (click)="importOutputs()">
                            <i *ngIf="!importInProgress" class="fa fa-upload" aria-hidden="true"></i>
                            <i *ngIf="importInProgress" class="fa fa-2x fa-spinner fa-spin" aria-hidden="true"></i>
                            Import
                        </button>
                        <button type="button"
                                class="btn btn-secondary"
                                (click)="exportOutputsForImport()">
                            <i class="fa fa-download" aria-hidden="true" title="Download Template"></i>
                            Download Template
                        </button>
                    </p>
                </div>
                <!-- div anchor for scrolling -->
                <div id="{{importDivId}}"></div>
            </div>

        </fieldset>
    </form>
</div>

<div class="d-none" id="{{printPreviewId}}">
    <workflow-print-preview [task]="task"
                            [taskType]="taskType"
                            [workflowTaskOutputs]="workflowTaskOutputs"></workflow-print-preview>
</div>

const ELLIPSIS = '\u2026';

import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';

import { SearchService } from '../search/search.service';
import { SearchQueryDef } from '../search/search-query-def';
import { TranslationService } from '../services/translation.service';

@Component({
    selector: 'job-select',
    template: `
    <div class="job-select">
        <climb-typeahead
            [(model)]="model"
            [resultFormatter]="jobIdFormatter"
            [keyFormatter]="jobKeyFormatter"
            [search]="searchJobs"
            [keySearch]="searchJobsByKey"  
            [required]="required"
            [disabled]="disabled"
            [placeholder]="placeholder"
            [namespace]="'job'"
            (selectItem)="selectItemHandler($event)"
            #typeahead
        ></climb-typeahead>
    </div>
    `,
    styles: [`
        .job-select {
            min-width: 300px;
        }
    `]
})
export class JobSelectComponent implements OnInit {
    @Input() model: any;
    @Input() excludeLocked = false;
    @Input() required: boolean;
    @Input() disabled: boolean;

    @Output() modelChange: EventEmitter<any> = new EventEmitter<any>();

    placeholder: string;

    constructor(
        private searchService: SearchService,
        private translationService: TranslationService
    ) {
    }

    ngOnInit() {
        this.placeholder = this.translationService.translate('Job') +
            ' name' +
            ELLIPSIS;
    }

    selectItemHandler(item: any) {
        this.modelChange.emit(this.model);
    }

    searchJobs = (search: string): Promise<any[]> => {

        const filter: any = {
            JobID: search
        };

        if (this.excludeLocked) {
            filter.IsLocked = false;
        }

        return this._searchJobs(filter, 50);
    }

    searchJobsByKey = (key: any): Promise<any[]> => {
        const filter = {
            JobKey: key
        };
        return this._searchJobs(filter, 1);
    }

    _searchJobs(thisFilter: any, count: number): Promise<any[]> {
        const searchQueryDef: SearchQueryDef = {
            entity: 'Jobs',
            page: null,
            size: count,
            sortColumn: 'JobID',
            sortDirection: 'asc',
            filter: thisFilter
        };
        return this.searchService.getEntitiesBySearch(searchQueryDef).then((results: any) => {
            return results.data;
        });
    }

    jobIdFormatter = (item: any) => {
        return item.JobID;
    }

    jobKeyFormatter = (item: any) => {
        return item.JobKey;
    }
}

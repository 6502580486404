<ng-template #jobIdTmpl let-task="item">
    <p class="form-control-plaintext">
        {{task.TaskJob[0]?.Job?.JobID}}
    </p>
</ng-template>

<ng-template #taskAliasTmpl let-task="item">
    <p class="form-control-plaintext">
        {{task.TaskAlias}}
    </p>
</ng-template>

<ng-template #taskNameTmpl let-task="item">
    <p class="form-control-plaintext">
        {{task.WorkflowTask?.TaskName}}
    </p>
</ng-template>

<ng-template #assignedToTmpl let-task="item">
    <climb-assigned-to-select [(model)]="task.C_AssignedTo_key"
                              [disabled]="task.IsLocked"></climb-assigned-to-select>
</ng-template>

<ng-template #dateDueTmpl let-task="item">
    <climb-ngb-date #dateControl="ngModel"
                    [(ngModel)]="task.DateDue"
                    [ngModelOptions]="{ standalone: true }"
                    (ngModelChange)="onDueDateChange(task)"
                    [disabled]="task.IsLocked"></climb-ngb-date>
</ng-template>

<ng-template #dateCompleteTmpl let-task="item">
    <climb-ngb-date #dateControl="ngModel"
                    [(ngModel)]="task.DateComplete"
                    [ngModelOptions]="{ standalone: true }"
                    (ngModelChange)="completedDataChanged(task, $event)"></climb-ngb-date>
</ng-template>

<ng-template #statusTmpl let-task="item">
    <active-vocab-select [(model)]="task.C_TaskStatus_key"
        [vocabChoices]="taskStatuses"
        [keyFormatter]="taskStatusKeyFormatter"
        [optionFormatter]="taskStatusFormatter"
        (modelChange)="taskStatusChanged(task)"></active-vocab-select>
</ng-template>

<ng-template #isLockedTmpl let-task="item">
    <p class="form-control-plaintext">
        <lock-control [(model)]="task.IsLocked"
                        [readonly]="true"></lock-control>
    </p>
</ng-template>

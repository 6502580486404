import {
    Pipe,
    PipeTransform
} from '@angular/core';

/**
 * Returns shortened resources or # of resources if more than 2
 */
@Pipe({ name: 'resourceNameDisplay' })
export class ResourceNameDisplayPipe implements PipeTransform {

    transform(assignedTo: string | null): string {
        if (assignedTo === null) {
            return '';
        }

        if (assignedTo.length === 0) {
            return 'Unassigned';
        }

        const names = assignedTo.split(', ');
        if (names.length > 2) {
            return `${names.length} resources assigned`;
        }
        return assignedTo;
    }
}

import type { Container } from './container.interface';
import type { MaterialTypeContainerType } from './material-type-container-type.interface';
import type { cv_MaterialType } from './cv-material-type.interface';
import type { cv_VolumeUnit } from './cv-volume-unit.interface';

export interface cv_ContainerType {
    C_ContainerType_key: number;
    C_MaterialType_key: number | null;
    C_VocabularySource_key: number | null;
    C_VolumeUnit_key: number | null;
    C_Workgroup_key: number;
    Container: Container[];
    ContainerType: string;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    ElementID: string;
    IsActive: boolean;
    IsAnimal: boolean;
    IsDefault: boolean;
    IsDefaultAnimal: boolean;
    IsDefaultSample: boolean;
    IsDeprecated: boolean;
    MaterialTypeContainerType: MaterialTypeContainerType[];
    ModifiedBy: string;
    SortOrder: number | null;
    Version: number;
    Volume: number | null;
    cv_MaterialType: cv_MaterialType;
    cv_VolumeUnit: cv_VolumeUnit;
}

import { FacetSharedModule } from './../common/facet/facet-shared.module';
import { GenotypesSharedModule } from './../genotypes/genotypes-shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { ClimbCommonModule } from '../common/climb-common.module';
import { ClimbServicesModule } from '../services/climb-services.module';
import { WorkspacesSharedModule } from '../workspaces/workspaces-shared.module';

import { DragulaModule } from "ng2-dragula";
import { VocabulariesSharedModule } from './vocabularies-shared.module';
import { VocabulariesFacetComponent } from './vocabularies-facet.component';
import { VariableStandardPhraseComponent } from './variable-standard-phrase.component';
import { VocabulariesModifiersModalComponent } from './Modals/vocabularies-modifiers-modal.component';

@NgModule({
    imports: [
        CommonModule,
        DragulaModule,
        FormsModule,
        NgbModule,
        ClimbCommonModule,
        ClimbServicesModule,
        FacetSharedModule,
        GenotypesSharedModule,
        VocabulariesSharedModule,
        WorkspacesSharedModule,
    ],
    declarations: [
        VocabulariesFacetComponent,
        VariableStandardPhraseComponent,
        VocabulariesModifiersModalComponent
    ],
    exports: [
        VocabulariesFacetComponent,
        VariableStandardPhraseComponent,
        VocabulariesModifiersModalComponent
    ],
})
export class VocabulariesModule { }

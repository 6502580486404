import { datesEqual } from './dates-equal';

/**
 *  Is the input date today (ignoring time)?
 *
 *  Accepts null/invalid input.
 */
export function isToday(inputDate: any): boolean {
    if (!inputDate) {
        return false;
    }

    const today = new Date();

    return datesEqual(inputDate, today);
}

import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';

import {
    SaveChangesService, UnsavedChanges,
} from '../../services/save-changes.service';
import { facetsForFacetLevelSave } from '../facet/constants/facet-level-save.const';

@Component({
    selector: 'pager-toolbar',
    templateUrl: './pager-toolbar.component.html',
    styles: [`
        .pager-toolbar {
            display: flex;
            margin: 0 10px 5px 0;
            white-space: nowrap;
        }
    `]
})
export class PagerToolbarComponent implements OnInit {
    @Input() logTag: string;
    @Input() containerClasses: string;
    @Input() itemName: string;
    @Input() promptForSave = true;
    @Input() first = false;
    @Input() last = false;

    @Output() previous: EventEmitter<UnsavedChanges> = new EventEmitter<UnsavedChanges>();
    @Output() next: EventEmitter<UnsavedChanges> = new EventEmitter<UnsavedChanges>();

    saveChangesService: SaveChangesService;

    readonly CONTAINER_CLASSES_DEFAULT = '';
    readonly ITEM_NAME_DEFAULT = 'item';
    private isFacetLevelSaveSupported = false;

    constructor(
        saveChangesService: SaveChangesService
    ) {
        this.saveChangesService = saveChangesService;
    }

    ngOnInit() {
        // TODO: Remove backwards compatibility flag once all facets have separate saving/discard
        this.isFacetLevelSaveSupported = facetsForFacetLevelSave.some(facet => facet === this.logTag);
        this.initData();
    }

    private initData(): void {
        if (!this.containerClasses) {
            this.containerClasses = this.CONTAINER_CLASSES_DEFAULT;
        }

        if (!this.itemName) {
            this.itemName = this.ITEM_NAME_DEFAULT;
        }
    }

    previousClicked(): void {
        if (this.isFacetLevelSaveSupported) {
            this.previous.emit(UnsavedChanges.noChanges);
        } else {
            if (!this.promptForSave) {
                this.previous.emit(UnsavedChanges.noChanges);
                return;
            }

            this.saveChangesService.promptForUnsavedChanges(this.logTag).then((result) => {
                this.previous.emit(result);
            });
        }
    }

    nextClicked(): void {
        if (this.isFacetLevelSaveSupported) {
            this.next.emit(UnsavedChanges.noChanges);
        } else {
            if (!this.promptForSave) {
                this.next.emit(UnsavedChanges.noChanges);
                return;
            }

            this.saveChangesService.promptForUnsavedChanges(this.logTag).then((result) => {
                this.next.emit(result);
            });
        }
    }
}

import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    OnDestroy
} from '@angular/core';

import { AnimalService } from '../animals/services/animal.service';
import { MaterialService } from '../services/material.service';
import { SampleVocabService } from './sample-vocab.service';
import { SampleService } from './sample.service';
import { getSafeProp, lowerCaseCompare } from '../common/util';
import { Subscription } from 'rxjs';

/*
* Component to select Animal or Sample
*/
@Component({
    selector: 'material-multiselect',
    templateUrl: './material-multiselect.component.html'
})
export class MaterialMultiselectComponent implements OnInit, OnDestroy {
    @Input() model: any[] = [];

    // whether use has readonly privilege for this widget.
    //   If true all editing will be disabled
    @Input() readonly: boolean;
    // optional validator on selected material.
    //   false return value prevents the selection
    @Input() selectionValidator: (item: any) => boolean;
    @Input() enableStatusColumn: boolean;
    @Input() required: boolean;

    @Output() selectMaterial = new EventEmitter<any>();
    @Output() removeMaterial = new EventEmitter<any>();
    @Output() changeAnimalStatus = new EventEmitter<any>();


    // state variables
    animalStatuses: any[];
    sampleStatuses: any[];

    _subs: Subscription[] = [];

    constructor(
        private animalService: AnimalService,
        private materialService: MaterialService,
        private sampleService: SampleService,
        private sampleVocabService: SampleVocabService
    ) {
      
    }

    ngOnInit() {

        if (this.readonly === null) {
            this.readonly = false;
        }

        if (!this.selectionValidator) {
            this.selectionValidator = (item: any) => {
                return true;
            };
        }

        this.setupCVs();
    }

    ngOnDestroy() {
        for (const s of this._subs) {
            s.unsubscribe();
        }
    }

    private setupCVs() {
        this.sampleVocabService.animalStatuses$.subscribe((data) => {
            this.animalStatuses = data;
        });

        this.sampleVocabService.sampleStatuses$.subscribe((data) => {
            this.sampleStatuses = data;
        });
    }

    selectMaterialAction(material: any) {
        if (this.selectionValidator(material)) {
            // Populate Breeze Material object from selection
            this.getMaterialEntity(material)
                .then((sourceMaterial: any) => {
                    this.selectMaterial.emit(sourceMaterial);
                });
        } else {
            console.warn("selectMaterial: Invalid material selection.");
        }
    }

    private getMaterialEntity(material: any): Promise<any> {
        const materialKey = material.C_Material_key;
        const expands = ['Material.JobMaterial'];

        if (material.Animal) {
            return this.animalService.getAnimal(materialKey, expands);
        } else if (material.Sample) {
            return this.sampleService.getSample(materialKey, expands);
        } else {
            console.error("getMaterialEntity: Unsupported material type.");
            return null;
        }
    }

    removeMaterialSourceMaterial(material: any) {
        this.removeMaterial.emit(material);
    }

    animalStatusChanged(animal: any): void {
        this.animalService.statusChangePostProcess(animal);
    }

    sampleStatusChanged(sample: any): void {
        // Nothing to do
    }

    searchMaterials = (term: string): Promise<any> => {
        const materialFilter = {
            MaterialName: term
        };
        const queryDef = {
            page: 1,
            size: 50,
            // Sort so that animals come first
            sort: 'Sample.SampleNameSortable, Animal.AnimalNameSortable',
            filter: materialFilter,
            inlineCount: false
        };

        return this.materialService.searchMaterials(queryDef)
            .then((response: any) => {
                return response.results;
            });
    }

    sourceResultFormatter = (value: any) => {
        if (value.Animal) {
            return value.Animal.AnimalName;
        } else if (value.Sample) {
            return value.Sample.SampleName;
        } else {
            return "";
        }
    }

    /**
     * Alter exact match behavior to include matching on MicrochipIdentifer
     */
    isMaterialExactMatch = (data: any[], term: string) => {
        if (term &&
            data &&
            data.length === 1
        ) {
            return lowerCaseCompare(getSafeProp(data[0], 'Animal.AnimalName'), term) ||
                lowerCaseCompare(getSafeProp(data[0], 'Sample.SampleName'), term) ||
                lowerCaseCompare(data[0].MicrochipIdentifier, term);
        }
        return false;
    }

    animalStatusKeyFormatter = (value: any) => {
        return value.C_AnimalStatus_key;
    }

    animalStatusFormatter = (value: any) => {
        return value.AnimalStatus;
    }

    sampleStatusKeyFormatter = (value: any) => {
        return value.C_SampleStatus_key;
    }

    sampleStatusFormatter = (value: any) => {
        return value.SampleStatus;
    }
}

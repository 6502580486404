/**
 * Create a new event to be used with dispatchEvent()
 * @param eventName
 */
export function createNewEvent(eventName: string): Event {
    let event: Event;
    if (typeof (Event) === 'function') {
        event = new Event(eventName);
    } else {
        event = document.createEvent('Event');
        event.initEvent(eventName, true, true);
    }

    return event;
}

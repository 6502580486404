import { FacetLoadingStateService } from './../common/facet/facet-loading-state.service';
import { map } from 'rxjs/operators';
import {
    Component,
    Input,
    OnInit,
    OnChanges,
} from '@angular/core';

import { InstitutionService } from './institution.service';
import { InstitutionVocabService } from './institution-vocab.service';

import { PrivilegeService } from '../services/privilege.service';
import { ConfirmService } from '../common/confirm';
import { VocabularyService } from './../vocabularies/vocabulary.service';
import { DataContextService } from '../services/data-context.service';

@Component({
    selector: 'contacts-table',
    templateUrl: './contacts-table.component.html',
    styles: [`
        .contacts-table {
            margin-top: 10px;
           }
    `]
})
export class ContactsTableComponent implements OnInit, OnChanges {
    /* The name of the object's primary key database field */
    @Input() pkName: string;
    /* The value of the object's primary key */
    @Input() pkValue: number;
    /* The entity to assign contacts to e.g. institution or site */
    @Input() entity: any;
    @Input() isCRO: any;

    // CVs
    contactTypes: any[] = [];

    // State
    readonly COMPONENT_LOG_TAG = 'contacts-table';

    readwrite: boolean;
    readonly: boolean;
    contactsShown: boolean;

    constructor(
        private privilegeService: PrivilegeService,
        private facetLoadingStateService: FacetLoadingStateService,
        private institutionService: InstitutionService,
        private institutionVocabService: InstitutionVocabService,
        private confirmService: ConfirmService,
        private vocabularyService: VocabularyService,
        private dataContext: DataContextService
    ) { }

    // lifecycle
    ngOnInit() {

        this.initialize();
    }

    ngOnChanges(changes: any) {
        if (changes.entity && !changes.entity.firstChange) {
            this.initialize();
        }
    }

    initialize() {
        this.facetLoadingStateService.changeLoadingState(true);

        this.setPrivileges();

        return this.getCVs().then(() => {
            this.getContactDetails();
            this.facetLoadingStateService.changeLoadingState(false);
        }).catch((error) => {
            this.facetLoadingStateService.changeLoadingState(false);
            throw error;
        });
    }

    private getCVs(): Promise<any> {

        const p1 = this.institutionVocabService.contactTypes$.pipe(map((data) => {
            this.contactTypes = data;
        })).toPromise();

        return Promise.all([p1]);
    }

    /**
     * Sets privilege variables.
     */
    private setPrivileges() {
        this.readonly = this.privilegeService.readonly;
        this.readwrite = this.privilegeService.readwrite;
    }

    // Formatters for <select> input
    contactTypeKeyFormatter = (value: any) => {
        return value.C_ContactPersonType_key;
    }
    contactTypeFormatter = (value: any) => {
        return value.ContactPersonType;
    }

    createContact(): Promise<any> {
            const newContact = this.institutionService.createContact(this.pkName, this.pkValue);

            const p1 = this.vocabularyService.getCVDefault('cv_ContactPersonTypes').then((value) => {
                newContact.cv_ContactPersonType = value;
            });

            return Promise.all([p1]).then(() => {
                this.contactsShown = true;
                return newContact;
            });
    }

    private getContactDetails(): Promise<any> {
        if (this.pkName && this.pkValue > 0) {
            return this.institutionService.getContacts(this.pkName, this.pkValue).then(
                (items) => {
                    if (items.length > 0) {
                        this.contactsShown = true;
                    }
                });
        }
        return Promise.resolve(this.pkName);
    }

    /**
     * Delete Contact on "x" click
     */
    removeContact(contact: any) {
        if (!this.readonly) {
            return this.confirmService.confirmDelete(
                'Delete Contact',
                'Are you sure you want to delete this contact?'
            ).then(
                // confirmed
                () => {
                    this.institutionService.deleteContact(contact);
                    if (this.pkValue > 0 &&
                        contact.C_ContactPerson_key > 0
                    ) {
                        this.dataContext.save();
                    }
                },
                // cancel
                () => { /* do nothing on cancel */ }
            );
        }
    }

    /**
     * Hide/show contacts table contents
     */
    toggleContactsShown() {
        this.contactsShown = !this.contactsShown;
    }
}

import type { cv_ClinicalObservation } from './cv-clinical-observation.interface';
import type { cv_Modifier4 } from './cv-modifier4.interface';

export interface cv_ClinicalObservationModifier4 {
    C_ClinicalObservationModifier4_key: number;
    C_ClinicalObservation_key: number;
    C_Modifier4_key: number;
    C_Workgroup_key: number;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    ModifiedBy: string;
    Version: number;
    cv_ClinicalObservation: cv_ClinicalObservation;
    cv_Modifier4: cv_Modifier4;
}

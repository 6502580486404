<form role="form" class="form-label-right">
    <div class="modal-header justify-content-center">
        <h4 class="modal-title">Constructs Filter</h4>
    </div>

    <div class="modal-body">

        <div class="form-group row">
            <label class="col-md-3 col-form-label">ID</label>
            <div class="col-md-9">
                <input type="text"
                       name="constructID"
                       [(ngModel)]="filter.ConstructID"
                       class="form-control input-medium"
                       data-auto-focus="constructID" />
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-3 col-form-label">Name</label>
            <div class="col-md-9">
                <input type="text"
                       name="fullName"
                       [(ngModel)]="filter.FullName"
                       class="form-control input-medium" />
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-3 col-form-label">Active</label>
            <div class="col-md-9">
                <active-status-select [(model)]="filter.IsActive"
                                      [nullable]="true"></active-status-select>
            </div>
        </div>

    </div><!-- /.modal-body -->

    <div class="modal-footer">
        <span class="btn-set">
            <button type="submit"
                    class="btn btn-fw-wide btn-lg btn-primary"
                    (click)="filterClicked($event)">
                Filter
            </button>
            <button data-automation-id="clear-filter-button"
                    type="button"
                    class="btn btn-fw-wide btn-lg btn-secondary"
                    (click)="clearClicked()">
                Clear Filter
            </button>
        </span>
        <span class="btn-set">
            <button type="button"
                    class="btn btn-lg btn-secondary"
                    (click)="closeClicked()">
                Close
            </button>
        </span>
    </div>
</form>

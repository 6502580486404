import {
    CellFormatterService,
    TableOptions,
    FormatterOptions
} from '../common/datatable';
import { TranslationService } from '../services/translation.service';
import { AuditModifiedFieldComponent } from './audit-modified-fields/audit-modified-field.component';
import { AuditClassFactory } from './audit-class-maker';

export class AuditJobsTableOptions {

    options: TableOptions;

    constructor(
        private cellFormatterService: CellFormatterService,
        private translationService: TranslationService,
        private isCRO: boolean,
        private isCRL: boolean,
        private isGLP: boolean
    ) {
        this.options = this.getDefaultOptions();
    }

    getDefaultOptions(): TableOptions {
        let columns: any[] = [];
        if (this.isCRO) {
            if (this.isCRL) {
                columns = [
                    this.getTypeColumn(),
                    this.getModifiedfieldsColumn(),
                    this.getModifiedDateColumn(),
                    this.getModifiedByColumn(),
                    this.getJobNameColumn(),
                    this.getCodeColumn(),
                    this.getResearchDirectorColumn(),
                    this.getClientManagerColumn(),
                    this.getStudyDirectorColumn(),
                    this.getIACUCProtocolColumn(),
                    this.getComplianceColumn(),
                    this.getStatusColumn(),
                    this.getStudyColumn(),
                    this.getJobTypeColumn(),
                    this.getJobSubtypeColumn(),
                    this.getImagingColumn(),
                    this.getTitleColumn(),
                    this.getDescriptionColumn(),
                    this.getOverageColumn(),
                    this.getJobReportColumn(),
                    this.getProjectedImplantDateColumn(),
                    this.getImplantedDateColumn(),
                    this.getProjectedStartDateColumn(),
                    this.getDateStartedColumn(),
                    this.getDurationDaysColumn(),
                    this.getDateEndedColumn(),
                    this.getLockedColumn(),
                    this.getCreatedByColumn(),
                    this.getCreatedDateColumn()
                ];
            } else {
                columns = [
                    this.getTypeColumn(),
                    this.getModifiedfieldsColumn(),
                    this.getModifiedDateColumn(),
                    this.getModifiedByColumn(),
                    this.getJobNameColumn(),
                    this.getCodeColumn(),
                    this.getResearchDirectorColumn(),
                    this.getClientManagerColumn(),
                    this.getStudyDirectorColumn(),
                    this.getIACUCProtocolColumn(),
                    this.getComplianceColumn(),
                    this.getStatusColumn(),
                    this.getStudyColumn(),
                    this.getJobTypeColumn(),
                    this.getJobSubtypeColumn(),
                    this.getTitleColumn(),
                    this.getDescriptionColumn(),
                    this.getOverageColumn(),
                    this.getProjectedImplantDateColumn(),
                    this.getImplantedDateColumn(),
                    this.getProjectedStartDateColumn(),
                    this.getDateStartedColumn(),
                    this.getDurationDaysColumn(),
                    this.getDateEndedColumn(),
                    this.getLockedColumn(),
                    this.getCreatedByColumn(),
                    this.getCreatedDateColumn()
                ];
            }
        } else {
            columns = [
                this.getTypeColumn(),
                this.getModifiedfieldsColumn(),
                this.getModifiedDateColumn(),
                this.getModifiedByColumn(),
                this.getJobNameColumn(),
                this.getCodeColumn(),
                this.getStudyColumn(),
                this.getTitleColumn(),
                this.getLeadScientistColumn(),
                this.getStudyMonitorColumn(),
                this.getDescriptionColumn(),
                this.getIACUCProtocolColumn(),
                this.getComplianceColumn(),
                this.getStatusColumn(),
                this.getJobTypeColumn(),
                this.getProjectedStartDateColumn(),
                this.getDateStartedColumn(),
                this.getDurationDaysColumn(),
                this.getDateEndedColumn(),
                this.getCostColumn(),
                this.getDurationColumn(),
                this.getLockedColumn(),
                this.getCreatedByColumn(),
                this.getCreatedDateColumn()
            ];
        }

        return {
            csvFileName: this.translationService.translate('job').toLowerCase() + '-audit.csv',
            enableDetailColumn: false,
            enableDraggable: false,
            enableSelectable: false,
            enableAuditColumns: false,
            columns
        };
    }

    private getTypeColumn(): any {
        return {
            displayName: 'Type',
            field: 'UpdateType',
            cellClass: AuditClassFactory.createDeletedClass,
        };
    }

    private getModifiedfieldsColumn(): any {
        return {
            displayName: 'Modified Fields',
            field: 'ModifiedFields',
            sortField: 'ModifiedFields',
            rendererComponent: AuditModifiedFieldComponent,
            exportFormatter: this.cellFormatterService.auditModifiedFieldsExportFormatter,
            cellClass: AuditClassFactory.createDeletedClass,
        };
    }

    private getModifiedDateColumn(): any {
        return {
            displayName: 'Date',
            field: 'ModifiedDate',
            formatter: this.cellFormatterService.dateTimeUTCFormatter,
            cellClass: AuditClassFactory.createDeletedClass,
        };
    }

    private getModifiedByColumn(): any {
        return {
            displayName: 'Modified By',
            field: 'ModifiedBy',
            formatter: this.cellFormatterService.userNameFormatter,
            cellClass: AuditClassFactory.createDeletedClass,
        };
    }

    private getJobNameColumn(): any {
        return {
            displayName: this.translationService.translate('Job') + ' Name',
            field: 'JobName',
            formatter: this.cellFormatterService.createAuditColumnFormatter('JobName', (this.translationService.translate('Job') + 'Name')),
            cellClass: AuditClassFactory.createCellClass((this.translationService.translate('Job') + 'Name').toLowerCase()),
        };
    }

    private getCodeColumn(): any {
        return {
            displayName: 'Code',
            field: 'JobCode',
            visible: false,
            formatter: this.cellFormatterService.createAuditColumnFormatter('JobCode', (this.translationService.translate('Job') + 'Code')),
            cellClass: AuditClassFactory.createCellClass((this.translationService.translate('Job') + 'Code').toLowerCase()),
        };
    }

    private getResearchDirectorColumn(): any {
        return {
            displayName: 'Research Director',
            field: 'ResearchDirector',
            visible: false,
            formatter: this.cellFormatterService.createAuditColumnFormatter('ResearchDirector', 'ResearchDirector'),
            cellClass: AuditClassFactory.createCellClass('researchdirector'),
        };
    }

    private getClientManagerColumn(): any {
        return {
            displayName: !this.isGLP ? 'Client Manager' : 'Alternate Contact',
            field: 'ClientManager',
            visible: false,
            formatter: this.cellFormatterService.createAuditColumnFormatter('ClientManager', !this.isGLP ? 'ClientManager' : 'AlternateContact'),
            cellClass: AuditClassFactory.createCellClass(!this.isGLP ? 'clientmanager' : 'alternatecontact'),
        };
    }

    private getStudyDirectorColumn(): any {
        return {
            displayName: 'Study Director',
            field: 'StudyDirector',
            visible: false,
            formatter: this.cellFormatterService.createAuditColumnFormatter('StudyDirector', 'StudyDirector'),
            cellClass: AuditClassFactory.createCellClass('studydirector'),
        };
    }

    private getStudyColumn(): any {
        return {
            displayName: this.translationService.translate('Study'),
            field: 'Study',
            formatter: this.cellFormatterService.createAuditColumnFormatter('Study', this.translationService.translate('Study')),
            cellClass: AuditClassFactory.createCellClass(this.translationService.translate('Study').toLowerCase()),
        };
    }

    private getTitleColumn(): any {
        return {
            displayName: 'Title',
            field: 'Title',
            visible: false,
            formatter: this.cellFormatterService.createAuditColumnFormatter('Title', 'Title'),
            cellClass: AuditClassFactory.createCellClass('title'),
        };
    }

    private getProjectedStartDateColumn(): any {
        return {
            displayName: 'Projected Start Date',
            field: 'ProjectedStartDate',
            formatter: this.cellFormatterService.createAuditColumnFormatter('ProjectedStartDate', 'ProjectedStartDate', this.cellFormatterService.dateFormatter),
            cellClass: AuditClassFactory.createCellClass('projectedstartdate'),
        };
    }

    private getDateStartedColumn(): any {
        return {
            displayName: 'Start Date',
            field: 'DateStarted',
            formatter: this.cellFormatterService.createAuditColumnFormatter('DateStarted', 'DateStarted', this.cellFormatterService.dateFormatter),
            cellClass: AuditClassFactory.createCellClass('datestarted'),
        };
    }

    private getDurationDaysColumn(): any {
        return {
            displayName: 'Duration Days',
            field: 'DurationDays',
            formatter: this.cellFormatterService.createAuditColumnFormatter('DurationDays', 'DurationDays'),
            cellClass: AuditClassFactory.createCellClass('durationdays'),
        };
    }

    private getDurationColumn(): any {
        return {
            displayName: 'Duration',
            field: 'Duration',
            formatter: this.cellFormatterService.createAuditColumnFormatter('Duration', 'Duration'),
            cellClass: AuditClassFactory.createCellClass('duration'),
        };
    }

    private getDateEndedColumn(): any {
        return {
            displayName: 'End Date',
            field: 'DateEnded',
            formatter: this.cellFormatterService.createAuditColumnFormatter('DateEnded', 'DateEnded', this.cellFormatterService.dateFormatter),
            cellClass: AuditClassFactory.createCellClass('dateended'),
        };
    }

    private getStatusColumn(): any {
        return {
            displayName: 'Status',
            field: 'Status',
            visible: false,
            formatter: this.cellFormatterService.createAuditColumnFormatter('Status', 'Status'),
            cellClass: AuditClassFactory.createCellClass('status'),
        };
    }

    private getJobTypeColumn(): any {
        return {
            displayName: this.translationService.translate('Job') + ' Type',
            field: 'JobType',
            visible: false,
            formatter: this.cellFormatterService.createAuditColumnFormatter('JobType', this.translationService.translate('Job') + 'Type'),
            cellClass: AuditClassFactory.createCellClass((this.translationService.translate('Job') + 'Type').toLowerCase()),
        };
    }

    private getJobSubtypeColumn(): any {
        return {
            displayName: this.translationService.translate('Job') + ' Subtype',
            field: 'JobSubtype',
            visible: false,
            formatter: this.cellFormatterService.createAuditColumnFormatter('JobSubtype', this.translationService.translate('Job') + 'Subtype'),
            cellClass: AuditClassFactory.createCellClass((this.translationService.translate('Job') + 'Subtype').toLowerCase()),
        };
    }

    private getStudyMonitorColumn(): any {
        return {
            displayName: 'Study Monitor',
            field: 'StudyMonitor',
            visible: false,
            formatter: this.cellFormatterService.createAuditColumnFormatter('StudyMonitor', 'StudyMonitor'),
            cellClass: AuditClassFactory.createCellClass('studymonitor'),
        };
    }

    private getLeadScientistColumn(): any {
        return {
            displayName: 'Lead Scientist',
            field: 'LeadScientist',
            visible: false,
            formatter: this.cellFormatterService.createAuditColumnFormatter('LeadScientist', 'LeadScientist'),
            cellClass: AuditClassFactory.createCellClass('leadscientist'),
        };
    }

    private getIACUCProtocolColumn(): any {
        return {
            displayName: 'IACUC Protocol',
            field: 'IACUCProtocol',
            visible: false,
            formatter: this.cellFormatterService.createAuditColumnFormatter('IACUCProtocol', 'IACUCProtocol'),
            cellClass: AuditClassFactory.createCellClass('iacucprotocol'),
        };
    }

    private getComplianceColumn(): any {
        return {
            displayName: 'Compliance',
            field: 'Compliance',
            visible: false,
            formatter: this.cellFormatterService.createAuditColumnFormatter('Compliance', 'Compliance'),
            cellClass: AuditClassFactory.createCellClass('compliance'),
        };
    }

    private getCostColumn(): any {
        return {
            displayName: 'Cost',
            field: 'Cost',
            visible: false,
            formatter: this.cellFormatterService.createAuditColumnFormatter('Cost', 'Cost'),
            cellClass: AuditClassFactory.createCellClass('cost'),
        };
    }

    private getJobReportColumn(): any {
        return {
            displayName: this.translationService.translate('Job') + ' Report',
            field: 'JobReport',
            visible: false,
            formatter: this.cellFormatterService.createAuditColumnFormatter('JobReport', this.translationService.translate('Job') + 'Report'),
            cellClass: AuditClassFactory.createCellClass((this.translationService.translate('Job') + 'Report').toLowerCase()),
        };
    }

    private getImagingColumn(): any {
        return {
            displayName: 'Imaging',
            field: 'Imaging',
            visible: false,
            formatter: this.cellFormatterService.createAuditColumnFormatter('Imaging', 'Imaging'),
            cellClass: AuditClassFactory.createCellClass('imaging'),
        };
    }

    private getDescriptionColumn(): any {
        return {
            displayName: 'Description',
            field: 'Notes',
            visible: false,
            formatter: this.cellFormatterService.createAuditColumnFormatter('Notes', 'Notes'),
            cellClass: AuditClassFactory.createCellClass('notes'),
        };
    }

    private getOverageColumn(): any {
        return {
            displayName: 'Overage (%)',
            field: 'Overage',
            visible: false,
            formatter: this.cellFormatterService.createAuditColumnFormatter('Overage', 'Overage'),
            cellClass: AuditClassFactory.createCellClass('overage'),
        };
    }

    private getProjectedImplantDateColumn(): any {
        return {
            displayName: 'Projected Implant Date',
            field: 'ProjectImplantDate',
            visible: false,
            formatter: this.cellFormatterService.createAuditColumnFormatter('ProjectImplantDate', 'ProjectImplantDate', this.cellFormatterService.dateFormatter),
            cellClass: AuditClassFactory.createCellClass('projectimplantdate'),
        };
    }

    private getImplantedDateColumn(): any {
        return {
            displayName: 'Implanted Date',
            field: 'ImplantedDate',
            visible: false,
            formatter: this.cellFormatterService.createAuditColumnFormatter('ImplantedDate', 'ImplantedDate', this.cellFormatterService.dateFormatter),
            cellClass: AuditClassFactory.createCellClass('implanteddate'),
        };
    }

    private getLockedColumn(): any {
        return {
            displayName: 'Locked',
            field: 'IsLocked',
            visible: false,
            formatter: this.cellFormatterService.createAuditColumnFormatter('IsLocked', 'IsLocked'),
            cellClass: AuditClassFactory.createCellClass('islocked'),
        };
    }

    private getCreatedByColumn(): any {
        return {
            displayName: 'Created By',
            field: 'CreatedBy',
            formatter: this.cellFormatterService.userNameFormatter,
            cellClass: AuditClassFactory.createDeletedClass,
        };
    }

    private getCreatedDateColumn(): any {
        return {
            displayName: 'Created Date',
            field: 'DateCreated',
            formatter: this.cellFormatterService.dateTimeUTCFormatter,
            cellClass: AuditClassFactory.createDeletedClass,
        };
    }
}

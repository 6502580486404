import { TaskTableCommService } from '../../services/task-table-comm.service';
import {
    Component,
    Input,
    OnInit,
    OnChanges,
    Output,
    EventEmitter,
    OnDestroy,
} from '@angular/core';

import { LoggingService } from '@services/logging.service';
import { WorkflowService } from '../../services/workflow.service';

import {
    uniqueArrayFromPropertyPath, getSafeProp, lowerCaseCompare
} from '@common/util';
import * as escapeStringRegexp from 'escape-string-regexp';
import { Subscription } from 'rxjs';

/**
 * Component to select multiple TaskOutputSetMaterials.
 */
@Component({
    selector: 'task-output-set-material-select',
    templateUrl: './task-output-set-material-select.component.html',
    styles: [`
        .table {
            margin-bottom: 2px;
        }
        .material-selector-wrapper {
            min-width: 15em;
        }
    `]
})
export class TaskOutputSetMaterialSelectComponent implements OnInit, OnChanges, OnDestroy {
    @Input() task: any;
    @Input() taskOutputSet: any;
    @Input() fieldName: string;
    @Input() readonly: boolean;
    // Select only a single material rather than multiple
    @Input() singleMaterialOnly: boolean;
    // Samples assigned to the task (not output samples)
    @Input() primarySamples: any[];
    @Output() selectionChange: EventEmitter<string> = new EventEmitter<string>();

    readonly MAX_MATERIAL_RESULTS = 50;
    readonly COMPONENT_LOG_TAG = 'task-output-grid';

    // Model for single material mode
    singleMaterialKey: any;

    selectedMaterial: any;

    refreshSubscription: Subscription;

    constructor(
        private loggingService: LoggingService,
        private workflowService: WorkflowService,
        private taskTableComm: TaskTableCommService
    ) {
        // Nothing to do
    }

    ngOnInit() {
        if (this.readonly === null) {
            this.readonly = false;
        }

        this.resetSingleMaterialKey();

        this.refreshSubscription = this.taskTableComm.refreshMaterialSelects$.subscribe(() => {
            this.resetSingleMaterialKey();
        });
    }

    ngOnDestroy() {
        if (this.refreshSubscription) {
            this.refreshSubscription.unsubscribe();
        }
    }

    ngOnChanges() {
        this.resetSingleMaterialKey();
    }

    /**
     * Reset the model for typeahead in singleMaterialOnly mode
     * This always needs to be done in case mode switches while in use.
     */
    private resetSingleMaterialKey() {
        const outputMaterials = this.getCurrentOutputSetMaterials();
        if (outputMaterials.length > 0) {
            this.singleMaterialKey = outputMaterials[0].C_Material_key;
        } else {
            this.singleMaterialKey = null;
        }
    }

    private emitSelectionChange() {
        this.selectionChange.emit(this.selectedMaterial);
    }

    searchMaterials = (text: string): Promise<any[]> => {
        let materialsToSearch = this.getMaterialsToSearch();
        if (this.isSingleOutputPerMaterial()) {
            materialsToSearch = this.filterDuplicates(materialsToSearch);
        }
        let results = materialsToSearch.filter((item) => {
            let name = '';
            let microchipId = '';
            if (item) {
                name = item.AnimalName || item.SampleName;
                microchipId = getSafeProp(item, 'Material.MicrochipIdentifier');
            }

            return this.matchTypeaheadChoice(text, name) ||
                this.matchTypeaheadChoice(text, microchipId);
        });

        if (results.length === 0 && this.isSingleOutputPerMaterial()) {
            const isDuplicateMatch = this.findDuplicateMatch(text);
            if (isDuplicateMatch) {
                const message = 'This animal/sample has already been selected.';
                const showToast = true;
                this.loggingService.logWarning(
                    message,
                    null,
                    this.COMPONENT_LOG_TAG,
                    showToast
                );
            }
        }

        if (results.length > this.MAX_MATERIAL_RESULTS) {
            results = results.splice(0, this.MAX_MATERIAL_RESULTS);
        }

        return Promise.resolve(results);
    }

    isSingleOutputPerMaterial(): boolean {
        return getSafeProp(this.task, 'WorkflowTask.SingleOutputPerAnimal');
    }

    searchMaterialsByKey = (key: any): Promise<any[]> => {
        const materialsToSearch = this.getMaterialsToSearch();

        const results = materialsToSearch.filter((item) => {
           return item.C_Material_key === key;
        });

        return Promise.resolve(results);
    }

    private getMaterialsToSearch(): any[] {
        const animals = uniqueArrayFromPropertyPath(
            this.task, 'TaskMaterial.Material.Animal'
        );
        const samples = this.primarySamples;

        return animals.concat(samples);
    }

    private matchTypeaheadChoice(text: string, choice: string): boolean {
        const escapedSearch = escapeStringRegexp(text);
        return new RegExp(escapedSearch, 'gi').test(choice);
    }

    private filterDuplicates(materials: any[]): any[] {
        const filteredArray: any[] = [];
        const selectedAnimals = uniqueArrayFromPropertyPath(
            this.task.TaskOutputSet, 'TaskOutputSetMaterial.Material.Animal.AnimalName'
        );
        const selectedSamples = uniqueArrayFromPropertyPath(
            this.task.TaskOutputSet, 'TaskOutputSetMaterial.Material.Sample.SampleName'
        );

        for (const material of materials) {
            const foundAnimal = selectedAnimals.find((item) => {
                return item === material.AnimalName;
            });
            const foundSample = selectedSamples.find((item) => {
                return item === material.SampleName;
            });
            if (!foundAnimal && !foundSample) {
                filteredArray.push(material);
            }
        }

        return filteredArray;
    }

    private findDuplicateMatch(term: string): boolean {
        const selectedAnimal = this.getMaterialsToSearch().find((item) => {
            return lowerCaseCompare(item.AnimalName, term);
        });
        const selectedSample = this.getMaterialsToSearch().find((item) => {
            return lowerCaseCompare(item.SampleName, term);
        });
        if (selectedAnimal || selectedSample) {
            return true;
        } else {
            return false;
        }
    }

    selectMaterial(material: any) {
        if (!material) {
            return;
        }

        const initialValues = {
            C_TaskOutputSet_key: this.taskOutputSet.C_TaskOutputSet_key,
            C_Material_key: material.C_Material_key
        };

        this.workflowService.createTaskOutputSetMaterial(initialValues);
        this.selectedMaterial = material;
        this.emitSelectionChange();
        this.resetSingleMaterialKey();
    }

    selectSingleMaterial(material: any) {
        // remove existing material(s) before selecting
        const outputMaterials = this.getCurrentOutputSetMaterials();
        while (outputMaterials.length > 0) {
            this.workflowService.deleteTaskOutputSetMaterial(outputMaterials.pop());
        }

        this.selectMaterial(material);
    }

    private getCurrentOutputSetMaterials(): any[] {
        let outputMaterials: any[] = [];
        if (this.taskOutputSet && this.taskOutputSet.TaskOutputSetMaterial) {
            outputMaterials = this.taskOutputSet.TaskOutputSetMaterial;
        }
        return outputMaterials;
    }

    keyFormatter = (value: any) => {
        return value.C_Material_key;
    }

    resultFormatter = (value: any) => {
        if (value.AnimalName) {
            return value.AnimalName;
        } else if (value.SampleName) {
            return value.SampleName;
        } else {
            return "";
        }
    }

    /**
     * Alter exact match behavior to include matching on MicrochipIdentifer
     */
    isMaterialExactMatch = (data: any[], term: string) => {
        if (term &&
            data &&
            data.length === 1
        ) {
            return lowerCaseCompare(data[0].AnimalName, term) ||
                lowerCaseCompare(data[0].SampleName, term) ||
                lowerCaseCompare(getSafeProp(data[0], 'Material.MicrochipIdentifier'), term);
        }
        return false;
    }

    removeJobTaskOutputSetMaterial(taskOutputSetMaterial: any) {
        this.workflowService.deleteTaskOutputSetMaterial(taskOutputSetMaterial);
    }

    onBlurHandler(event: Event) {
        setTimeout(() => {
            this.resetSingleMaterialKey();
        });
    }
}

import { Subject } from 'rxjs';

import { 
    CellFormatterService,
    NO_DRAG_CLASS,
    TableOptions 
} from '../common/datatable';

import { TranslationService } from '../services/translation.service';

import { BirthHousingCellRendererComponent } from './components/birth-housing-cell-renderer/birth-housing-cell-renderer.component';
import { addAuditColumnsToOptions } from '@common/datatable/utils';

export class BirthTableOptions {

    options: TableOptions;

    // Emit Observable for any add-birth-in-mating click
    private addBirthInMatingClickedSource = new Subject<any>();
    addBirthInMatingClicked$ = this.addBirthInMatingClickedSource.asObservable();


    constructor(
        private cellFormatterService: CellFormatterService,
        private translationService: TranslationService
    ) {
        this.options = addAuditColumnsToOptions(cellFormatterService)(this.getDefaultOptions());
    }

    getDefaultOptions(): TableOptions {
        return {
            csvFileName: 'births.csv',
            enableDetailColumn: true,
            enableDraggable: false,
            enableSelectable: true,
            columns: [
                {
                    displayName: 'Birth ID',
                    field: 'BirthID'
                },
                {
                    displayName: 'Mating ID',
                    field: 'Mating.MatingID'
                },
                {
                    displayName: 'Housing ID',
                    field: 'Mating.MaterialPool.MaterialPoolID',
                    cellClass: NO_DRAG_CLASS,
                    rendererComponent: <new () => BirthHousingCellRendererComponent>
                        BirthHousingCellRendererComponent,
                    outputClick: (row) => {
                        this.addBirthInMatingClickedSource.next(row);
                    }
                },
                {
                    displayName: 'Status',
                    field: 'cv_BirthStatus.BirthStatus',
                    visible: false
                },
                {
                    displayName: this.translationService.translate('Line'),
                    field: 'Mating.Line.LineName',
                    visible: false
                },
                {
                    displayName: 'Birth Date',
                    field: 'DateBorn',
                    formatter: this.cellFormatterService.dateFormatter
                },
                {
                    displayName: 'Wean Date',
                    field: 'DateWean',
                    formatter: this.cellFormatterService.dateFormatter
                },
                {
                    displayName: 'Mating Date',
                    field: 'Mating.DateMating',
                    formatter: this.cellFormatterService.dateFormatter
                },
                {
                    displayName: 'Live Count',
                    field: 'LiveCount',
                    headerClass: 'text-nowrap',
                    cellClass: 'num-cell'
                },
                {
                    displayName: 'Stillborn Count',
                    field: 'StillbornCount',
                    headerClass: 'text-nowrap',
                    cellClass: 'num-cell',
                    visible: false
                },
                {
                    displayName: 'Dead Count',
                    field: 'DeadCount',
                    headerClass: 'text-nowrap',
                    cellClass: 'num-cell',
                    visible: false
                },
                {
                    displayName: 'Foster', field: 'IsFoster',
                    formatter: this.cellFormatterService.booleanFormatter,
                    exportFormatter: this.cellFormatterService.booleanExportFormatter,
                    maxWidth: 80
                },
                {
                    displayName: 'Foster Housing ID', 
                    field: 'MaterialPool.MaterialPoolID'
                },
                {
                    displayName: 'Species',
                    field: 'Mating.Line.cv_Taxon.CommonName',
                    visible: false
                },
                {
                    displayName: 'Location',
                    field: 'Mating.MaterialPool.CurrentLocationPath',
                    visible: false
                },
                {
                    displayName: 'Notes',
                    field: 'Note',
                    visible: false,
                    formatter: this.cellFormatterService.noteFormatter,
                    sortable: false
                },
            ]
        };
    }
}

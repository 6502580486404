import type { ContactPerson } from './contact-person.interface';

export interface cv_ContactPersonType {
    Abbreviation: string;
    C_ContactPersonType_key: number;
    C_Workgroup_key: number;
    ContactPerson: ContactPerson[];
    ContactPersonType: string;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    IsActive: boolean;
    IsDefault: boolean;
    ModifiedBy: string;
    SortOrder: number | null;
    Version: number;
}

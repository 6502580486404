import {
    NgModule,
    ModuleWithProviders
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { ClimbCommonModule } from '../common/climb-common.module';
import { ClimbServicesModule } from '../services/climb-services.module';

import { DotmaticsTestArticleTableComponent } from './dotmatics-test-article-table.component';
import { DotmaticsTestArticleSelectComponent } from './dotmatics-test-article-select.component';
import { DotmaticsService } from './dotmatics.service';



@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgbModule,
        ClimbCommonModule,
        ClimbServicesModule,
    ],
    declarations: [
        DotmaticsTestArticleTableComponent,
        DotmaticsTestArticleSelectComponent
    ],
    exports: [
        DotmaticsTestArticleTableComponent,
        DotmaticsTestArticleSelectComponent
    ],
})
export class DotmaticsModule {
    static forRoot(): ModuleWithProviders<DotmaticsModule> {
        return {
            ngModule: DotmaticsModule,
            providers: [
                DotmaticsService
            ]
        };
    }
}

import { BulkEditCommService } from './../../common/facet/bulk-edit-comm.service';
import { DataManagerService } from '../../services/data-manager.service';
import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';

import { LoggingService } from '../../services/logging.service';
import { BulkEditToolbarComponent } from '../../common/toolbars';
import { ClinicalService } from '../clinical.service';
import { TreatmentPlanBulkTemplatesComponent } from './treatment-plan-bulk-templates.component';


@Component({
    selector: 'treatment-bulk-edit',
    templateUrl: './treatment-bulk-edit.component.html',
    providers: [
        BulkEditCommService
    ]
})
export class TreatmentBulkEditComponent implements OnInit {
    @Input() facet: any;
    @Input() animalHealthRecords: any[];
    @Output() exit: EventEmitter<any> = new EventEmitter<any[]>();
    @ViewChild('bulkEditToolbar', { static: false }) bulkEditToolBar: BulkEditToolbarComponent;
    @ViewChild('bulkTemplates') bulkTemplates: TreatmentPlanBulkTemplatesComponent;

    treatments: any[] = [];
    loading: boolean;

    readonly COMPONENT_LOG_TAG = 'treatment-bulk-edit';

    constructor(
        private clinicalService: ClinicalService,
        private loggingService: LoggingService,
        private dataManager: DataManagerService,
    ) {
    }

    // lifecycle
    ngOnInit() {
        this.initialize();
    }

    initialize() {
        this.loading = true;
        this.dataManager.ensureRelationships(this.animalHealthRecords, ['TaskAnimalHealthRecord.TaskInstance.TaskInput.Input']).then(() => {
            // Copy the input so we don't touch the grid data
            this.animalHealthRecords = this.animalHealthRecords.slice();

            for (const record of this.animalHealthRecords) {
                this.treatments = this.treatments.concat(record.TaskAnimalHealthRecord);
            }
            this.loading = false;
        });
    }

    exitClicked() {
        if (this.dataManager.hasChanges()) {
            for (const healthRecord of this.animalHealthRecords) {
                this.clinicalService.cancelHealthRecord(healthRecord);
            }

            this.loggingService.logFacetUndoSuccess(this.COMPONENT_LOG_TAG);
        }

        this.exit.emit(this.treatments);
    }

    async validate() {
        return this.bulkTemplates.validate();
    }

}

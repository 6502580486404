<div class="modal-header justify-content-center">
    <h3 class="modal-title">
        Animals Already in Housing
    </h3>
</div>

<div class="modal-body">
    <p>
        {{ confirmMessage }}
    </p>
    <p>
        {{ animalString }}
    </p>
</div>

<div class="modal-footer">
    <button type="button"
            class="btn btn-lg btn-fw-xwide btn-primary"
            (click)="moveAnimals()">
        Move {{ buttonText }}
    </button>
    <button type="button"
            class="btn btn-lg btn-fw-xwide btn-secondary"
            (click)="skipAnimals()">
        Skip {{ buttonText }}
    </button>
    <button type="button"
            class="btn btn-lg btn-fw-xwide btn-secondary"
            (click)="cancel()">
        Cancel
    </button>
</div>

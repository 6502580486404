<table data-automation-id="individual-table"
       class="table table-bordered job-pharma-animals-individual">
    <thead>
        <tr class="detail-grid-header">

            <!-- Drag Handle -->
            <th *ngIf="!readonly" class="icon-cell"></th>

            <!-- Select -->
            <th *ngIf="!readonly" class="icon-cell">
                <input type="checkbox"
                       name="selectAll"
                       [(ngModel)]="allSelected"
                       (ngModelChange)="allSelectedChanged()"
                       title="Select/Clear all" />
            </th>

            <!-- Paste/Remove -->
            <th *ngIf="!readonly">
                <a class="detail-grid-button"
                   (click)="onPaste()">
                    <i data-automation-id="paste-animals-button"
                       class="fa fa-paste" 
                       title="Paste Animals">
                    </i>
                </a>
            </th>

            <!-- Name -->
            <th *ngIf="visible.name"
                droppable
                [dropDisabled]="readonly"
                (drop)="onDrop()"
                [attr.title]="readonly ? '' : 'Drop animals here'">
                <div class="d-md-inline-block min-width60">
                    <a (click)="sortColumn('Material.Animal.AnimalNameSortable', $event)"
                       class="header-sort-link"
                       title="Sort by Name">
                        Name {{ tableSort.nested ? tableSort.nestedIndex['Material.Animal.AnimalNameSortable'] : '' }}
                        <i class="fa"
                           [ngClass]="{
                                      'fa-arrow-down': tableSort.nested ? tableSort.nestedReverse['Material.Animal.AnimalNameSortable'] : tableSort.reverse,
                                      'fa-arrow-up': tableSort.nested ? !tableSort.nestedReverse['Material.Animal.AnimalNameSortable'] : !tableSort.reverse
                                    }"
                           style="font-size: 10px !important;"
                           *ngIf="tableSort.propertyPath === 'Material.Animal.AnimalNameSortable' || tableSort.nested && tableSort.properties.includes('Material.Animal.AnimalNameSortable')"></i>
                    </a>
                </div>
                <div class="d-md-inline-block">
                    <ng-container *ngIf="!readonly">
                        <bulk-edit-header [headerText]="'Rename'"
                                          (updateClicked)="bulkNameUpdated()"
                                          [disabled]="!bulkNameCounter">
                            <div modal-content class="bulk-name d-flex text-body">
                                <div class="form-group mr-1 mb-0">
                                    <label>Prefix</label>
                                    <input type="text"
                                           class="form-control"
                                           [(ngModel)]="bulkNamePrefix"
                                           name="bulkNamePrefix" />
                                </div>
                                <div class="form-group mr-1 mb-0">
                                    <label>Counter</label>
                                    <input type="number"
                                           class="form-control"
                                           [(ngModel)]="bulkNameCounter"
                                           [required]="true"
                                           name="bulkNameCounter" />
                                </div>
                                <div class="form-group mb-0">
                                    <label>Suffix</label>
                                    <input type="text"
                                           class="form-control"
                                           [(ngModel)]="bulkNameSuffix"
                                           name="bulkNameSuffix" />
                                </div>
                            </div>
                        </bulk-edit-header>
                        <span class="float-right">
                        </span>
                    </ng-container>
                </div>
            </th>
            <th *ngIf="visible.line">
                <a (click)="sortColumn('Material.Line.LineName', $event)"
                   class="header-sort-link"
                   title="Sort by {{'Line' | translate}}">
                    {{'Line' | translate}} {{ tableSort.nested ? tableSort.nestedIndex['Material.Line.LineName'] : '' }}
                    <i class="fa"
                       [ngClass]="{
                                      'fa-arrow-down': tableSort.nested ? tableSort.nestedReverse['Material.Line.LineName'] : tableSort.reverse,
                                      'fa-arrow-up': tableSort.nested ? !tableSort.nestedReverse['Material.Line.LineName'] : !tableSort.reverse
                                    }"
                       style="font-size: 10px !important;"
                       *ngIf="tableSort.propertyPath === 'Material.Line.LineName' || tableSort.nested && tableSort.properties.includes('Material.Line.LineName')"></i>
                </a>
            </th>
            <th *ngIf="visible.genotype">
                <a (click)="sortColumn('Material.Animal.GenotypesSortable', $event)"
                   class="header-sort-link"
                   title="Sort by Genotype">
                    Genotype {{ tableSort.nested ? tableSort.nestedIndex['Material.Animal.GenotypesSortable'] : '' }}
                    <i class="fa"
                       [ngClass]="{
                                      'fa-arrow-down': tableSort.nested ? tableSort.nestedReverse['Material.Animal.GenotypesSortable'] : tableSort.reverse,
                                      'fa-arrow-up': tableSort.nested ? !tableSort.nestedReverse['Material.Animal.GenotypesSortable'] : !tableSort.reverse
                                    }"
                       style="font-size: 10px !important;"
                       *ngIf="tableSort.propertyPath === 'Material.Animal.GenotypesSortable' || tableSort.nested && tableSort.properties.includes('Material.Animal.GenotypesSortable')"></i>
                </a>
            </th>
            <th *ngIf="visible.sex">
                <a (click)="sortColumn('Material.Animal.cv_Sex.Sex', $event)"
                   class="header-sort-link"
                   title="Sort by Sex">
                    Sex {{ tableSort.nested ? tableSort.nestedIndex['Material.Animal.cv_Sex.Sex'] : '' }}
                    <i class="fa"
                       [ngClass]="{
                                      'fa-arrow-down': tableSort.nested ? tableSort.nestedReverse['Material.Animal.cv_Sex.Sex'] : tableSort.reverse,
                                      'fa-arrow-up': tableSort.nested ? !tableSort.nestedReverse['Material.Animal.cv_Sex.Sex'] : !tableSort.reverse
                                    }"
                       style="font-size: 10px !important;"
                       *ngIf="tableSort.propertyPath === 'Material.Animal.cv_Sex.Sex' || tableSort.nested && tableSort.properties.includes('Material.Animal.cv_Sex.Sex')"></i>
                </a>
            </th>
            <th *ngIf="visible.birthDate">
                <a (click)="sortColumn('DateBornSortable', $event)"
                   class="header-sort-link"
                   title="Sort by Birth Date">
                    Birth Date {{ tableSort.nested ? tableSort.nestedIndex['DateBornSortable'] : '' }}
                    <i class="fa"
                       [ngClass]="{
                                      'fa-arrow-down': tableSort.nested ? tableSort.nestedReverse['DateBornSortable'] : tableSort.reverse,
                                      'fa-arrow-up': tableSort.nested ? !tableSort.nestedReverse['DateBornSortable'] : !tableSort.reverse
                                    }"
                       style="font-size: 10px !important;"
                       *ngIf="tableSort.propertyPath === 'DateBornSortable' || tableSort.nested && tableSort.properties.includes('DateBornSortable')"></i>
                </a>
            </th>
            <th *ngIf="visible.status">
                <a (click)="sortColumn('Material.Animal.cv_AnimalStatus.AnimalStatus', $event)"
                   class="header-sort-link"
                   title="Sort by Name">
                    Status {{ tableSort.nested ? tableSort.nestedIndex['Material.Animal.cv_AnimalStatus.AnimalStatus'] : '' }}
                    <i class="fa"
                       [ngClass]="{
                                      'fa-arrow-down': tableSort.nested ? tableSort.nestedReverse['Material.Animal.cv_AnimalStatus.AnimalStatus'] : tableSort.reverse,
                                      'fa-arrow-up': tableSort.nested ? !tableSort.nestedReverse['Material.Animal.cv_AnimalStatus.AnimalStatus'] : !tableSort.reverse
                                    }"
                       style="font-size: 10px !important;"
                       *ngIf="tableSort.propertyPath === 'Material.Animal.cv_AnimalStatus.AnimalStatus' || tableSort.nested && tableSort.properties.includes('Material.Animal.cv_AnimalStatus.AnimalStatus')"></i>
                </a>
            </th>
            <th *ngIf="visible.heldFor && isGLP">
                <a (click)="sortColumn('Material.Animal.HeldFor', $event)"
                   class="header-sort-link"
                   title="Sort by Held For">
                    Held For {{ tableSort.nested ? tableSort.nestedIndex['Material.Animal.HeldFor'] : '' }}
                    <i class="fa"
                       [ngClass]="{
                                      'fa-arrow-down': tableSort.nested ? tableSort.nestedReverse['Material.Animal.HeldFor'] : tableSort.reverse,
                                      'fa-arrow-up': tableSort.nested ? !tableSort.nestedReverse['Material.Animal.HeldFor'] : !tableSort.reverse
                                    }"
                       style="font-size: 10px !important;"
                       *ngIf="tableSort.propertyPath === 'Material.Animal.HeldFor' || tableSort.nested && tableSort.properties.includes('Material.Animal.HeldFor')"></i>
                </a>
            </th>
            <th *ngIf="visible.housing">
                <a (click)="sortColumn('Material.Animal.Material.MaterialPoolMaterial[0].MaterialPool.MaterialPoolIDSortable', $event)"
                   class="header-sort-link"
                   title="Sort by Housing ID">
                    Housing ID {{ tableSort.nested ? tableSort.nestedIndex['Material.Animal.Material.MaterialPoolMaterial[0].MaterialPool.MaterialPoolIDSortable'] : '' }}
                    <i class="fa"
                       [ngClass]="{
                         'fa-arrow-down': tableSort.nested ? tableSort.nestedReverse['Material.Animal.Material.MaterialPoolMaterial[0].MaterialPool.MaterialPoolIDSortable'] : tableSort.reverse,
                         'fa-arrow-up': tableSort.nested ? !tableSort.nestedReverse['Material.Animal.Material.MaterialPoolMaterial[0].MaterialPool.MaterialPoolIDSortable'] : !tableSort.reverse
                       }"
                       style="font-size: 10px !important;"
                       *ngIf="tableSort.propertyPath === 'Material.Animal.Material.MaterialPoolMaterial[0].MaterialPool.MaterialPoolIDSortable' || tableSort.nested && tableSort.properties.includes('Material.Animal.Material.MaterialPoolMaterial[0].MaterialPool.MaterialPoolIDSortable')">
                    </i>
                </a>
            </th>
            <th *ngIf="visible.microchipIdentifier">
                <a (click)="sortColumn('Material.MicrochipIdentifier', $event)"
                   class="header-sort-link"
                   title="Sort by Microchip ID">
                    Microchip ID {{ tableSort.nested ? tableSort.nestedIndex['Material.MicrochipIdentifier'] : '' }}
                    <i class="fa"
                       [ngClass]="{
                                      'fa-arrow-down': tableSort.nested ? tableSort.nestedReverse['Material.MicrochipIdentifier'] : tableSort.reverse,
                                      'fa-arrow-up': tableSort.nested ? !tableSort.nestedReverse['Material.MicrochipIdentifier'] : !tableSort.reverse
                                    }"
                       style="font-size: 10px !important;"
                       *ngIf="tableSort.propertyPath === 'Material.MicrochipIdentifier' || tableSort.nested && tableSort.properties.includes('Material.MicrochipIdentifier')"></i>
                </a>
            </th>
            <th *ngIf="visible.alternatePhysicalID && isGLP">
                <a (click)="sortColumn('Material.Animal.AlternatePhysicalID', $event)"
                   class="header-sort-link"
                   title="Sort by Alternate Physical ID">
                    Alternate Physical ID {{ tableSort.nested ? tableSort.nestedIndex['Material.Animal.AlternatePhysicalID'] : '' }}
                    <i class="fa"
                       [ngClass]="{
                                      'fa-arrow-down': tableSort.nested ? tableSort.nestedReverse['Material.Animal.AlternatePhysicalID'] : tableSort.reverse,
                                      'fa-arrow-up': tableSort.nested ? !tableSort.nestedReverse['Material.Animal.AlternatePhysicalID'] : !tableSort.reverse
                                    }"
                       style="font-size: 10px !important;"
                       *ngIf="tableSort.propertyPath === 'Material.Animal.AlternatePhysicalID' || tableSort.nested && tableSort.properties.includes('Material.Animal.AlternatePhysicalID')"></i>
                </a>
            </th>
            <th *ngIf="visible.externalIdentifier">
                <a (click)="sortColumn('Material.ExternalIdentifier', $event)"
                   class="header-sort-link"
                   title="Sort by External ID">
                    External ID {{ tableSort.nested ? tableSort.nestedIndex['Material.ExternalIdentifier'] : '' }}
                    <i class="fa"
                       [ngClass]="{
                                      'fa-arrow-down': tableSort.nested ? tableSort.nestedReverse['Material.ExternalIdentifier'] : tableSort.reverse,
                                      'fa-arrow-up': tableSort.nested ? !tableSort.nestedReverse['Material.ExternalIdentifier'] : !tableSort.reverse
                                    }"
                       style="font-size: 10px !important;"
                       *ngIf="tableSort.propertyPath === 'Material.ExternalIdentifier' || tableSort.nested && tableSort.properties.includes('Material.ExternalIdentifier')"></i>
                </a>
            </th>
            <th *ngIf="visible.cITESNumber && isGLP">
                <a (click)="sortColumn('Material.Animal.CITESNumber', $event)"
                   class="header-sort-link"
                   title="Sort by CITES Number">
                    CITES Number {{ tableSort.nested ? tableSort.nestedIndex['Material.Animal.CITESNumber'] : '' }}
                    <i class="fa"
                       [ngClass]="{
                                      'fa-arrow-down': tableSort.nested ? tableSort.nestedReverse['Material.Animal.CITESNumber'] : tableSort.reverse,
                                      'fa-arrow-up': tableSort.nested ? !tableSort.nestedReverse['Material.Animal.CITESNumber'] : !tableSort.reverse
                                    }"
                       style="font-size: 10px !important;"
                       *ngIf="tableSort.propertyPath === 'Material.Animal.CITESNumber' || tableSort.nested && tableSort.properties.includes('Material.Animal.CITESNumber')"></i>
                </a>
            </th>
            <th *ngIf="visible.classification && isGLP">
                <a (click)="sortColumn('Material.Animal.cv_Classification.Classification', $event)"
                   class="header-sort-link"
                   title="Sort by External ID">
                    Classification {{ tableSort.nested ? tableSort.nestedIndex['Material.Animal.cv_Classification.Classification'] : '' }}
                    <i class="fa"
                       [ngClass]="{
                                      'fa-arrow-down': tableSort.nested ? tableSort.nestedReverse['Material.Animal.cv_Classification.Classification'] : tableSort.reverse,
                                      'fa-arrow-up': tableSort.nested ? !tableSort.nestedReverse['Material.Animal.cv_Classification.Classification'] : !tableSort.reverse
                                    }"
                       style="font-size: 10px !important;"
                       *ngIf="tableSort.propertyPath === 'Material.Animal.cv_Classification.Classification' || tableSort.nested && tableSort.properties.includes('Material.Animal.cv_Classification.Classification')"></i>
                </a>
            </th>
            <th *ngIf="visible.ageDays">
                <a (click)="sortColumn('Material.Animal.DateBorn', $event)"
                   class="header-sort-link"
                   title="Sort by Age">
                    Age (Days) {{ tableSort.nested ? tableSort.nestedIndex['Material.Animal.DateBorn'] : '' }}
                    <i class="fa"
                       [ngClass]="{
                                      'fa-arrow-down': tableSort.nested ? tableSort.nestedReverse['Material.Animal.DateBorn'] : tableSort.reverse,
                                      'fa-arrow-up': tableSort.nested ? !tableSort.nestedReverse['Material.Animal.DateBorn'] : !tableSort.reverse
                                    }"
                       style="font-size: 10px !important;"
                       *ngIf="tableSort.propertyPath === 'Material.Animal.DateBorn' || tableSort.nested && tableSort.properties.includes('Material.Animal.DateBorn')"></i>
                </a>
            </th>
            <th *ngIf="visible.ageWeeks">
                <a (click)="sortColumn('Material.Animal.DateBorn', $event)"
                   class="header-sort-link"
                   title="Sort by Age">
                    Age (Weeks) {{ tableSort.nested ? tableSort.nestedIndex['Material.Animal.DateBorn'] : '' }}
                    <i class="fa"
                       [ngClass]="{
                                      'fa-arrow-down': tableSort.nested ? tableSort.nestedReverse['Material.Animal.DateBorn'] : tableSort.reverse,
                                      'fa-arrow-up': tableSort.nested ? !tableSort.nestedReverse['Material.Animal.DateBorn'] : !tableSort.reverse
                                    }"
                       style="font-size: 10px !important;"
                       *ngIf="tableSort.propertyPath === 'Material.Animal.DateBorn' || tableSort.nested && tableSort.properties.includes('Material.Animal.DateBorn')"></i>
                </a>
            </th>
            <th *ngIf="visible.ageMonths">
                <a (click)="sortColumn('Material.Animal.DateBorn', $event)"
                   class="header-sort-link"
                   title="Sort by Age">
                    Age (Months) {{ tableSort.nested ? tableSort.nestedIndex['Material.Animal.DateBorn'] : '' }}
                    <i class="fa"
                       [ngClass]="{
                                      'fa-arrow-down': tableSort.nested ? tableSort.nestedReverse['Material.Animal.DateBorn'] : tableSort.reverse,
                                      'fa-arrow-up': tableSort.nested ? !tableSort.nestedReverse['Material.Animal.DateBorn'] : !tableSort.reverse
                                    }"
                       style="font-size: 10px !important;"
                       *ngIf="tableSort.propertyPath === 'Material.Animal.DateBorn' || tableSort.nested && tableSort.properties.includes('Material.Animal.DateBorn')"></i>
                </a>
            </th>
            <th *ngIf="visible.cohort">
                <div class="text-nowrap dropdown-wrapper">
                    <div class="d-md-inline-block min-width130" *ngIf="primaryCohortsExpanded">
                        <a (click)="sortColumn('PrimaryCohortsSortable', $event)"
                           class="header-sort-link"
                           title="Sort by Primary Cohort">
                            Primary Cohorts {{ tableSort.nested ? tableSort.nestedIndex['PrimaryCohortsSortable'] : '' }}
                            <i class="fa"
                               [ngClass]="{
                                      'fa-arrow-down': tableSort.nested ? tableSort.nestedReverse['PrimaryCohortsSortable'] : tableSort.reverse,
                                      'fa-arrow-up': tableSort.nested ? !tableSort.nestedReverse['PrimaryCohortsSortable'] : !tableSort.reverse
                                    }"
                               style="font-size: 10px !important;"
                               *ngIf="tableSort.propertyPath === 'PrimaryCohortsSortable'"></i>
                        </a>
                    </div>
                    <div class="d-md-inline-block min-width100" *ngIf="!primaryCohortsExpanded">
                        <a (click)="sortColumn('Material.Animal.CohortsSortable', $event)"
                           class="header-sort-link"
                           title="Sort by Cohort">
                            All Cohort {{ tableSort.nested ? tableSort.nestedIndex['Material.Animal.CohortsSortable'] : '' }}
                            <i class="fa"
                               [ngClass]="{
                                      'fa-arrow-down': tableSort.nested ? tableSort.nestedReverse['Material.Animal.CohortsSortable'] : tableSort.reverse,
                                      'fa-arrow-up': tableSort.nested ? !tableSort.nestedReverse['Material.Animal.CohortsSortable'] : !tableSort.reverse
                                    }"
                               style="font-size: 10px !important;"
                               *ngIf="tableSort.propertyPath === 'Material.Animal.CohortsSortable' || tableSort.nested && tableSort.properties.includes('Material.Animal.CohortsSortable')"></i>
                        </a>
                    </div>
                    <div class="d-md-inline-block">
                        <a class="dropdown-toggle"
                           data-toggle="dropdown"
                           aria-haspopup="true"
                           aria-expanded="false">
                            View
                        </a>
                        <div class="dropdown-menu">
                            <a class="dropdown-item" (click)="viewPrimaryCohorts()">View Primary Cohorts</a>
                            <a class="dropdown-item" (click)="viewAllCohorts()">View All Cohorts</a>
                        </div>
                    </div>
                </div>
            </th>
            <th *ngIf="visible.sequence">Sequence</th>
        </tr>
    </thead>
    <!-- open -->
    <loading-overlay *ngIf="loading" [message]="loadingMessage"></loading-overlay>
    <tbody *ngIf="!loading" 
            [dragula]="dragulaBagName"
           [(dragulaModel)]="animalMaterials">
        <tr *ngFor="let jobMaterial of animalMaterials |
                paginate: { itemsPerPage: 50, currentPage: animalPage, id: 'animal-individual-pagination' };
                let index = index;
                trackBy: trackRow"
            [attr.data-key]="jobMaterial.C_Material_key"
            [ngClass]="{'selected-row': jobMaterial.isSelected}"
            class="individual-animal-row">
            <ng-container *ngIf="jobMaterial.Material?.Animal as animal">

                <!-- Drag Handle -->
                <td *ngIf="!readonly"
                    class="icon-cell">
                    <i class="fa fa-sort text-muted"
                       [ngClass]="{
                         'draggable': !anySelected || jobMaterial.isSelected
                       }"
                       title="Drag to reorder"></i>
                </td>

                <!-- Select -->
                <td *ngIf="!readonly"
                    class="icon-cell">
                    <input type="checkbox"
                           name="select-animals-individual-{{index}}"
                           [(ngModel)]="jobMaterial.isSelected"
                           (ngModelChange)="isSelectedChanged()" />
                </td>

                <!-- Remove -->
                <td class="icon-cell"
                    *ngIf="!readonly">
                    <a (click)="removeJobMaterial(jobMaterial)" class="remove-individual-animal">
                        <i class="fa fa-remove remove-item" title="Remove Animal"></i>
                    </a>
                </td>

                <!-- Name -->
                <td *ngIf="visible.name">
                    <div data-drag-type="material"
                         draggable
                         (dragStart)="dragStart()"
                         (dragStop)="dragStop()"
                         [dragDisabled]="!jobMaterial.isSelected"
                         id="drag-animals-individual-{{animal.C_Material_key}}"
                         class="individual-animal-name">
                        {{animal.AnimalName}}
                    </div>
                </td>

                <!-- Line -->
                <td *ngIf="visible.line">
                    {{jobMaterial.Material.Line?.LineName}}
                </td>

                <!-- Genotype -->
                <td *ngIf="visible.genotype">
                    <ng-template [ngIf]="animal?.Genotype?.length > 0">
                        <span>{{ animal?.Genotype | formatGenotypeNames}}</span>
                    </ng-template>
                </td>

                <!-- Sex -->
                <td *ngIf="visible.sex">
                    {{animal.cv_Sex?.Sex}}
                </td>

                <!-- Birth Date -->
                <td *ngIf="visible.birthDate">
                    {{animal.DateBorn | formatShortDate}}
                </td>

                <!-- Status -->
                <td *ngIf="visible.status">
                    {{animal.cv_AnimalStatus?.AnimalStatus}}
                </td>

                <!-- Held For -->
                <td *ngIf="visible.heldFor && isGLP">
                    {{animal.HeldFor}}
                </td>

                <!-- Housing ID -->
                <td *ngIf="visible.housing">
                    {{jobMaterial.Material?.MaterialPoolMaterial | currentMaterialHousingID}}
                </td>

                <!-- Microchip ID -->
                <td *ngIf="visible.microchipIdentifier ">
                    {{animal.Material.MicrochipIdentifier}}
                </td>

                <!-- AlternatePhysical ID -->
                <td *ngIf="visible.alternatePhysicalID && isGLP">
                    {{animal.AlternatePhysicalID}}
                </td>

                <!-- External ID -->
                <td *ngIf="visible.externalIdentifier">
                    {{animal.Material.ExternalIdentifier}}
                </td>

                <!-- CITES Number-->
                <td *ngIf="visible.cITESNumber && isGLP">
                    {{animal.CITESNumber}}
                </td>

                <!-- Classification -->
                <td *ngIf="visible.classification && isGLP">
                    {{animal.cv_Classification?.Classification}}
                </td>

                <!-- Age (Days) -->
                <td *ngIf="visible.ageDays">
                    {{animal.DateBorn | animalAgeDays:animal.DateExit | number}}
                </td>

                <!-- Age (Weeks) -->
                <td *ngIf="visible.ageWeeks">
                    {{animal.DateBorn | animalAgeWeeks:animal.DateExit | number}}
                </td>

                <!-- Age (Months) -->
                <td *ngIf="visible.ageMonths">
                    {{animal.DateBorn | animalAgeMonths:animal.DateExit | number}}
                </td>

                <!-- Primary Cohorts -->
                <td *ngIf="visible.cohort && primaryCohortsExpanded">
                    <ng-container *ngFor="let cm of jobMaterial.Material.CohortMaterial | orderBy:'Cohort.CohortName'; let last = last">
                        <ng-container *ngFor="let jc of jobMaterial.Job.JobCohort | orderBy:'Cohort.CohortName'; let last = last">
                            <div *ngIf="cm.C_Cohort_key == jc.C_Cohort_key && cm.Cohort as cohort"
                                 class="cohort-badge"
                                 [ngStyle]="{ backgroundColor: cohort.BackgroundColor, color: cohort.ForegroundColor }">
                                {{cohort.CohortName}}
                            </div>
                        </ng-container>
                    </ng-container>
                </td>

                <!-- All Cohorts -->
                <td *ngIf="visible.cohort && !primaryCohortsExpanded">
                    <ng-container *ngFor="let cm of jobMaterial.Material.CohortMaterial | orderBy:'Cohort.CohortName'; let last = last">
                        <div *ngIf="cm.Cohort as cohort"
                             class="cohort-badge"
                             [ngStyle]="{ backgroundColor: cohort.BackgroundColor, color: cohort.ForegroundColor }">
                            {{cohort.CohortName}}
                        </div>
                    </ng-container>
                </td>

                <!-- Sequence -->
                <td *ngIf="visible.sequence">
                    {{jobMaterial.Sequence}}
                </td>
            </ng-container>
        </tr>
    </tbody>
</table>

<pagination-controls (pageChange)="changeAnimalPage($event)"
                     [autoHide]="true"
                     class="ngx-table-paginator"
                     id="animal-individual-pagination"></pagination-controls>

<div class="facet">
    <facet-header caption="Locations"
        [facetId]="facetId">
        <button data-automation-id="refresh-data-button"
                climbFacetAction
                (click)="refresh()"
                title="Refresh Data">
            <svg [climbIcon]="icons.arrowClockwise"></svg>
        </button>

        <button *ngIf="facetView === DETAIL_VIEW"
                climbFacetAction
                (click)="editEnd()"
                title="Return to main view">
            <svg [climbIcon]="icons.listView"></svg>
        </button>
    </facet-header>

    <div *ngIf="facet.Privilege!=='None'" class="facet-body"
         style="overflow-x:hidden !important;overflow-y:hidden !important;">

        <div class="detail-form-wrapper">
            <climb-facet-toolbar>
                <button *ngIf="facet.Privilege === 'ReadWrite'"
                        climbSaveButton
                        [disabled]="!hasFacetChanges()"
                        (click)="onSaveLocation()">
                </button>
            </climb-facet-toolbar>
            <div class="detail-header d-none">
                <detail-toolbar #detailToolbar
                                [showListViewButton] ="false"
                                [facetPrivilege]="facet.Privilege"
                                [logTag]="COMPONENT_LOG_TAG"
                                [addLocation]="true"
                                (saveLocation)="onSaveLocation()">
                </detail-toolbar>
            </div>
            <div *ngIf="facetView === HOME_VIEW"
                 class="detail-form pt-0">
                <div class="row flex-row-reverse mx-0 px-3">
                    <div class="location-nav-select">
                        <location-select [(model)]="selectedLocationPositionKey"
                                         (modelChange)="selectPositionKey($event)"
                                         [disabled]="addingMaterials"></location-select>
                    </div>
                    <ol *ngIf="position?.ancestors"
                        class="breadcrumb locations-breadcrumb mr-auto">
                        <li *ngFor="let position of position.ancestors"
                            class="breadcrumb-item"
                            [ngClass]="{'active': position?.TreeLevel === 1}">
                            <!-- Prevent changing the location while adding new materials -->
                            <span *ngIf="position?.TreeLevel > 1 && addingMaterials">{{position?.PositionName}}</span>
                            <a *ngIf="position?.TreeLevel > 1 && !addingMaterials"
                               class="location-position-link"
                               (click)="retrieveAndSetCurrentLocationPosition(position?.C_LocationPosition_key)">
                                {{position?.PositionName}}
                            </a>
                            <span *ngIf="position?.TreeLevel === 1">{{position?.PositionName}}</span>
                        </li>
                    </ol>
                </div>

                <div class="location-layout-container">
                    <div class="row current-node-row">
                        <div class="col-md-4">
                            <div class="location-card"
                                 droppable
                                 (drop)="onDrop()">
                                <div class="location-card-heading">
                                    <h3>{{position?.PositionName}}</h3>
                                    <div class="small" *ngIf="position?.cv_LocationType || position?.cv_MaterialType">
                                        <span *ngIf="position?.cv_LocationType">
                                            <em>{{position?.cv_LocationType?.LocationType}}</em>
                                        </span>
                                        <span *ngIf="position?.cv_MaterialType">
                                            <em>({{position?.cv_MaterialType?.MaterialType}})</em>
                                        </span>
                                    </div>
                                </div>
                                <div class="location-card-body">
                                    <table *ngIf="position?.materialStats"
                                           class="table table-sm table-borderless table-nonfluid mb-1">

                                        <tbody>
                                            <tr *ngIf="position?.materialStats?.TotalUnitsInLocation > 0">
                                                <td>Housing Units:</td>
                                                <td class="num-cell">
                                                    {{position?.materialStats?.TotalUnitsInLocation}}
                                                </td>
                                            </tr>
                                            <tr *ngIf="position?.materialStats?.TotalAnimalsInLocation > 0">
                                                <td>Animals:</td>
                                                <td class="num-cell">
                                                    {{position?.materialStats?.TotalAnimalsInLocation}}
                                                </td>
                                            </tr>
                                            <tr *ngIf="position?.materialStats?.TotalSamplesInLocation > 0">
                                                <td>Samples:</td>
                                                <td class="num-cell">
                                                    {{position?.materialStats?.TotalSamplesInLocation}}
                                                </td>
                                            </tr>
                                            <tr *ngIf="!isGLP && position?.deviceStats?.TotalDevicesInLocation > 0">
                                                <td>Devices:</td>
                                                <td class="num-cell">
                                                    {{position?.deviceStats?.TotalDevicesInLocation}}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div *ngIf="facet.Privilege === 'ReadWrite'"
                                     class="location-card-footer text-right">
                                    <button type="button" class="btn btn-secondary mt-1"
                                            (click)="editBegin(position)">
                                        <i class="fa fa-pencil" title="Edit"></i>
                                        Edit
                                    </button>
                                    <button type="button" class="btn btn-secondary mt-1"
                                            (click)="addChildLocationPosition(position)">
                                        <i class="fa fa-plus-circle" title="Add child location"></i>
                                    </button>
                                    <button type="button" class="btn btn-secondary remove-item mt-1"
                                            (click)="deleteLocationPosition(position)"
                                            [disabled]="position?.children?.length > 0">
                                        <i class="fa fa-remove" title="Delete"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row" *ngIf="locationDetail?.childLocations?.length > 0 || locationTableHasFilter()">
                        <div class="col-md-12">
                            <location-child-table [childLocations]="locationDetail.childLocations"
                                                  [tableSort]="locationTableSort"
                                                  [filter]="locationDetail.filter"
                                                  (clickLocation)="selectLocation($event)"></location-child-table>
                        </div>
                    </div>
                </div>

                <div class="material-tables-wrap">
                    <div *ngIf="locationDetail?.materialPools?.length > 0"
                         class="row">
                        <div class="col-md-12">
                            <h3>{{position?.PositionName}}: Animals</h3>
                            <small class="form-text text-muted" *ngIf="locationDetail.materialPools.length >= MAX_MATERIALPOOLS">
                                Only the first {{MAX_MATERIALPOOLS}} housing units have been loaded
                            </small>
                            <location-animal-table [materialPools]="locationDetail.materialPools"
                                                   [tableSort]="animalTableSort"></location-animal-table>
                        </div>
                    </div>

                    <div *ngIf="locationDetail?.samples?.length > 0"
                         class="row">
                        <div class="col-md-12">
                            <h3>{{position?.PositionName}}: Samples</h3>
                            <small class="form-text text-muted" *ngIf="locationDetail.samples.length >= MAX_SAMPLES">
                                Only the first {{MAX_SAMPLES}} samples have been loaded
                            </small>
                            <location-sample-table [samples]="locationDetail.samples"
                                                   [tableSort]="sampleTableSort"></location-sample-table>
                        </div>
                    </div>

                </div>

            </div>


            <ng-container *ngIf="facetView === DETAIL_VIEW">
                <location-detail [position]="position"
                                 [facetPrivilege]="facet.Privilege"
                                 class="detail-form-wrapper"
                                 (exitClicked)="editEnd()"></location-detail>
            </ng-container>

        </div>
    </div>
</div>

import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChildren
} from '@angular/core';

import { ImportVocabService } from './import-vocab.service';
import { TranslationService } from '@services/translation.service';

import {
    focusElementByQuery
} from '../common/util';
import { NgModel } from '@angular/forms';
import { dateControlValidator } from '@common/util/date-control.validator';
import { LoggingService } from '@services/logging.service';

@Component({
    selector: 'import-filter',
    templateUrl: './import-filter.component.html'
})
export class ImportFilterComponent implements OnInit {
    @ViewChildren('dateControls') dateControls: NgModel[];
    @Input() filter: any;

    @Output() onFilter: EventEmitter<any> = new EventEmitter<any>();
    @Output() close: EventEmitter<void> = new EventEmitter<void>();

    // CV choices
    importFileFormats: any[];
    importFileTypes: any[];

    constructor(
        private importVocabService: ImportVocabService,
        private translationService: TranslationService,
        private loggingService: LoggingService,
    ) { }

    async ngOnInit() {
        if (!this.filter) {
            this.filter = {};
        } else {
            // shallow copy passed in filters
            //  so we don't modify original data
            this.filter = { ...this.filter };
        }
        await this.importVocabService.reloadCache();
        await this.setupCVs();
        focusElementByQuery('[data-auto-focus="import-filter"]');
    }

    setupCVs(): Promise<any> {
        const cv1 = this.importVocabService.getImportFileFormats()
            .then((importFileFormats: any[]) => {
                this.importFileFormats = importFileFormats;
            });
        const cv2 = this.importVocabService.getImportFileTypes()
            .then((importFileTypes: any[]) => {
                this.importFileTypes = importFileTypes;
                this.importFileTypes.forEach((importFileType) => {
                    importFileType.ImportFileTypeTranslated = this.translationService.translate(importFileType.ImportFileType);
                });
            });

        return Promise.all([cv1, cv2]);
    }

    closeClicked() {
        this.close.emit();
    }

    clearClicked() {
        this.filter = {};
        this.onFilter.emit(this.filter);
        this.close.emit();
    }

    okClicked() {
        const errMessage = dateControlValidator(this.dateControls);
        if (errMessage) {
            this.loggingService.logError(errMessage, null, '', true);
            return;
        }
        
        this.onFilter.emit(this.filter);
        this.close.emit();
    }
}

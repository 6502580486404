// For grouping data on multiple characteristic types
// into one entity smoothly.
export class CharacteristicCollection<T> {
    job: T;
    sample: T;
    study: T;
    taxon: T;

    constructor() {
        this.job = null;
        this.sample = null;
        this.study = null;
        this.taxon = null;
    }
}

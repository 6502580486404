import {
    Injectable
} from '@angular/core';

import {
    NgbModal,
} from '@ng-bootstrap/ng-bootstrap';

import { NoteLogModalComponent } from './note-log-modal.component';

/**
 * Service to view a note-log modal.
 */
@Injectable()
export class ViewNoteLogModalService {

    constructor(
        private modalService: NgbModal
    ) { }


    openComponent(
        objectName: string,
        objectType: string,
        pkName: string,
        pkValue: number,
        isObjectNew: boolean,
        readonly: boolean
    ): Promise<any> {
        const modalRef = this.modalService.open(NoteLogModalComponent, { size: 'lg' });

        const component = modalRef.componentInstance as NoteLogModalComponent;
        component.objectName = objectName;
        component.objectType = objectType;
        component.pkName = pkName;
        component.pkValue = pkValue;
        component.isObjectNew = isObjectNew;
        component.readonly = readonly;

        return modalRef.result.then(
            (result) => {
                return result;
            },
            (reason) => {
                return reason;
            });
    }
}

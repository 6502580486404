<h2>Insights</h2>

<ng-container [ngTemplateOutlet]="insightCards"
              [ngTemplateOutletContext]="{
                  animalsByStatus: animalsByStatus$ | async,
                  animalsByStrain: animalsByStrain$ | async,
                  tasks: tasks$ | async,
                  jobsByType: jobsByType$ | async
              }">
</ng-container>

<ng-template #insightCards
             let-animalsByStatus="animalsByStatus"
             let-animalsByStrain="animalsByStrain"
             let-tasks="tasks"
             let-jobsByType="jobsByType">
    <ng-template #spinner>
        <climb-spinner size="xl"></climb-spinner>
    </ng-template>

    <div *ngIf="animalsByStatus && animalsByStrain && tasks && jobsByType; else spinner"
         class="insight-cards">

        <ng-container
            [ngTemplateOutlet]="card"
            [ngTemplateOutletContext]="{ caption: 'Live Animals by Status', class: 'green-lines', items: animalsByStatus, maxValue: animalsByStatusMax }"
        ></ng-container>

        <ng-container
            [ngTemplateOutlet]="card"
            [ngTemplateOutletContext]="{ caption: 'Animals by ' + translate('Line'), class: 'violet-lines', items: animalsByStrain, maxValue: animalsByStrainMax }"
        ></ng-container>

        <ng-container
            [ngTemplateOutlet]="card"
            [ngTemplateOutletContext]="{ caption: 'Tasks', class: 'blue-lines', items: tasks, maxValue: tasksMax }"
        ></ng-container>

        <ng-container
            [ngTemplateOutlet]="card"
            [ngTemplateOutletContext]="{ caption: translate('Jobs') + ' by Type', class: 'yellow-lines', items: jobsByType, maxValue: jobsByTypeMax }"
        ></ng-container>

        <ng-template #card let-caption="caption" let-class="class" let-items="items" let-maxValue="maxValue">
            <climb-insight-card [caption]="caption" [class]="class">
                <climb-chart-line *ngFor="let item of items"
                                  [label]="item[0]"
                                  [value]="item[1]"
                                  [maxValue]="maxValue">
                </climb-chart-line>
                <span *ngIf="items.length === 0" class="empty">There are no data to display</span>
            </climb-insight-card>
        </ng-template>
    </div>
</ng-template>

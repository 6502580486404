import { FormatterOptions } from '../data-table.interface';
import { Injectable } from '@angular/core';

import { UserNameService } from '../../../user/user-name.service';

import { FeatureFlagService } from '@services/feature-flags.service';
import { SettingService } from '../../../settings/setting.service';
import { DateFormatterService } from '../../util/date-time-formatting/date-formatter.service';
import { DateTime } from 'luxon';

const compress = (icon: string) => icon.replace(/>\s+</g, '><').trim();
const checkmarkCircleIcon = (title: string) => compress(`
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16">
        <title>${title}</title>
        <path fill="#228530" d="M15.67 8a7 7 0 1 1-14 0 7 7 0 0 1 14 0Zm-7.88 3.4 5.04-5.05-.7-.7-4.34 4.33-2.27-2.27-.7.7 2.97 2.98Z"/>
    </svg>
`);
const errorCircleIcon = (title: string) => compress(`
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16">
        <title>${title}</title>
        <path fill="#D13400" fill-rule="evenodd" d="M8.33 1a7 7 0 1 1 0 14 7 7 0 0 1 0-14Zm.62 3v5H7.7V4h1.25Zm-.62 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2Z"/>
    </svg>
`);
const lockIcon = (title: string) => compress(`
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16">
        <title>${title}</title>
        <path fill="#0F1011" fill-rule="evenodd" d="M6 5a2 2 0 1 1 4 0v2H6V5ZM5 7V5a3 3 0 0 1 6 0v2h2v7H3V7h2Zm2.5 4V9h1v2h-1Z"/>
    </svg>
`);
const crossCircleIcon = (title: string) => compress(`
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16">
        <title>${title}</title>
        <path fill="#D13400" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14Z"/>
        <path fill="#fff" d="m8 8.7 2.5 2.5.7-.7L8.7 8l2.5-2.5-.7-.7L8 7.3 5.5 4.8l-.7.7L7.3 8l-2.5 2.5.7.7L8 8.7Z"/>
    </svg>
`);

/**
 * Defines cell-formatter functions for ag-Grids
 */
@Injectable()
export class CellFormatterService {

    isGLP: boolean;

    constructor(
        private userNameService: UserNameService,
        private featureFlagService: FeatureFlagService,
        private settingService: SettingService,
        private dateFormatterService: DateFormatterService,
    ) {}

    importBooleanFormatter = (row: any, value: any, formatterOptions: FormatterOptions) => {
        formatterOptions.escapeHTML = false;

        if (value === true) {
            return checkmarkCircleIcon('True');
        } else {
            return errorCircleIcon('Urgent');
        }
    }

    booleanFormatter = (row: any, value: any, formatterOptions: FormatterOptions) => {
        formatterOptions.escapeHTML = false;

        if (value === true) {
            return checkmarkCircleIcon('True');
        }
        return '';
    }

    booleanExportFormatter = (row: any, value: any) => {
        return value;
    }

    dateFormatter = (row: any, value: any, formatterOptions: FormatterOptions) => {
        formatterOptions.escapeHTML = false;
        return this.dateFormatterService.formatDateOnly(value);
    }

    dateUtcFormatter = (row: any, value: any, formatterOptions: FormatterOptions) => {
        formatterOptions.escapeHTML = false;
        return this.dateFormatterService.formatDateOnlyUTC(value);
    }

    // Hides time component if set to midnight
    dateOrTimeFormatter = (row: any, value: any, formatterOptions: FormatterOptions) => {
        formatterOptions.escapeHTML = false;
        return this.dateFormatterService.formatDateOrTime(value);
    }

    // Hides time component if set to midnight
    dateOrTimeUtcFormatter = (row: any, value: any, formatterOptions: FormatterOptions) => {
        formatterOptions.escapeHTML = false;
        return this.dateFormatterService.formatDateOrTimeUTC(value);
    }
    
    dateTimeUTCFormatter = (row: any, value: any, formatterOptions: FormatterOptions) => {
        return this.dateFormatterService.formatDateTimeUTC(value);
    }
    dateTimeFormatter = (row: any, value: any, formatterOptions: FormatterOptions) => {
        return this.dateFormatterService.formatDateTime(value);
    }
    
    // Formats list of Note entites (given as value) in a comma separated string with note text, CreatedBy, and DateCreated
    noteFormatter = (row: any, value: any, formatterOptions: FormatterOptions) => {
        formatterOptions.escapeHTML = false;
        let noteString = '';
        if (value && value.length) {
            noteString = value.map((n: any) => `${n.NoteText} (${this.userNameService.toFullName(n.CreatedBy)} ${this.dateFormatterService.formatDateTime(n.DateCreated)})`).toString();
        }
        return noteString;
    }

    auditModifiedFieldsExportFormatter = (row: any, value: any) => {
        return value;
    }

    auditColumnFormatter = (
        row: any,
        value: any,
        formatterOptions: FormatterOptions,
        fieldName: string,
        translatedFieldName: string,
        preformatter: ( (row: any, value: any, formatterOptions: FormatterOptions) => string ) = null
    ) => {
        if (row && row.UpdateType.toLowerCase() === 'modified' && row.ModifiedFields.toLowerCase().split(', ').indexOf(translatedFieldName.toLowerCase()) > -1) {
            const previousFieldName = 'Previous' + fieldName;
            let previousVal = row[previousFieldName];
            let val = row[fieldName];
            // Format values using value formatter
            if (preformatter) {
                previousVal = preformatter(row, previousVal, formatterOptions);
                val = preformatter(row, val, formatterOptions);
            }

            // Show null values as 'NULL'
            previousVal = !this.isEmpty(previousVal) ? previousVal : 'NULL';
            val = !this.isEmpty(val) ? val : 'NULL';

            // Return prviousValue -> currentValue
            return `${previousVal} → ${val}`;
        } else if (preformatter) {
            // Return formatted value
            return preformatter(row, value, formatterOptions);
        } else {
            if (this.isEmpty(value)) {
                return '';
            }
            // Return original value
            return value;
        }
    }

    createAuditColumnFormatter = (
        fieldName: string,
        translatedFieldName: string,
        preformatter: ((row: any, value: any, formatterOptions: FormatterOptions) => string ) = null
    ) => (row: any, value: any, formatterOptions: FormatterOptions) => 
        this.auditColumnFormatter(row, value, formatterOptions, fieldName, translatedFieldName, preformatter);

    facetSettingAuditFormatter = (
        row: any,
        value: any,
        formatterOptions: FormatterOptions,
        fieldName: string,
        translatedFieldName: string,
        preformatter: ((row: any, value: any, formatterOptions: FormatterOptions) => string) = null
    ) => {
        value = this.settingService.translateFacetSettingDisplayName(value);
        return this.auditColumnFormatter(row, value, formatterOptions, fieldName, translatedFieldName, preformatter);
    }

    createFacetSettingAuditFormatter = (
        fieldName: string,
        translatedFieldName: string,
        preformatter: ((row: any, value: any, formatterOptions: FormatterOptions) => string ) = null
    ) => (row: any, value: any, formatterOptions: FormatterOptions) => 
        this.facetSettingAuditFormatter(row, value, formatterOptions, fieldName, translatedFieldName, preformatter);

    timeFromDateTimeFormatter = (row: any, value: any, formatterOptions: FormatterOptions) => {
        formatterOptions.escapeHTML = false;
        return this.dateFormatterService.formatTime(value);
    }

    deviationFormatter = (row: any, value: any, formatterOptions: FormatterOptions) => {
        formatterOptions.escapeHTML = false;
        if (value === '') {
            return value;
        } 
        if (!value) {
            return;
        } 

        const localDate = DateTime.fromJSDate(row.DateDue, {zone: "UTC"}).toJSDate();
        const isAllDay = DateFormatterService.isDateMidnight(localDate);
        let timeOverdue = '';
        let daysOverdue;
        let hoursOverdue;
        const time = parseInt(value, undefined);
        const absoluteTime = Math.abs(time);
        
        // Check if the due date has a time component and calculate deviation in days only
        if (isAllDay === true) {
            if (time < 0) {
                timeOverdue = '-';
                daysOverdue = Math.ceil(absoluteTime / 24 / 60);
            } else {
                daysOverdue = Math.floor(absoluteTime / 24 / 60);
            }
            timeOverdue += daysOverdue + ' d ';
            return timeOverdue;
        }

        // If due date has a time component, calculate deviation in d, h, min format
        // Add negative sign 
        if (time < 0) {
            timeOverdue = '-';
        }
        // days
        if (absoluteTime >= 1440) {
            daysOverdue = Math.floor(absoluteTime / 24 / 60);
            if (daysOverdue > 0) {
                timeOverdue += daysOverdue + ' d ';
            }
        }
        // hours
        if (absoluteTime >= 60) {
            hoursOverdue = Math.floor(absoluteTime / 60 % 24);
            if (hoursOverdue > 0) {
                timeOverdue += hoursOverdue + ' h ';
            }
        }

        // minutes
        const minOverdue = absoluteTime % 60;
        if (minOverdue > 0 || absoluteTime === 0) {
            timeOverdue += minOverdue + ' min';
        }
        
        return timeOverdue;
    }

    lockedFormatter = (row: any, value: any, formatterOptions: FormatterOptions) => {
        formatterOptions.escapeHTML = false;
        // Use with booleanExportFormatter
        if (value === true) {
            return lockIcon('Locked');
        }
        return '';
    }

    urgentFormatter = (row: any, value: any, formatterOptions: FormatterOptions) => {
        formatterOptions.escapeHTML = false;
        // Use with booleanExportFormatter
        if (value === true) {
            return errorCircleIcon('Urgent');
        }
        return '';
    }

    userNameFormatter = (row: any, value: any) => {
        if (this.isGLP === undefined) {
            this.initGLP();
        }
        if (this.isGLP) {
            if (!value) {
                return '';
            } else {
                return value;
            }
        } else {
            return value ? this.userNameService.toFullName(value) : '';
        }
    }

    userFirstNameFormatter = (row: any, value: any) => this.userNameService.getFirstNameByKey(value);

    userLastNameFormatter = (row: any, value: any) => this.userNameService.getLastNameByKey(value);

    userUserNameFormatter = (row: any, value: any) => this.userNameService.getUserNameByKey(value);

    sumCount = (row: any, value: any, formatterOptions: FormatterOptions) => {
        formatterOptions.escapeHTML = false;
        if (value && value > 0) {
            return `${errorCircleIcon('Has Discrepancies')}<span class="text-danger">${value}</span>`;
        }
        return '0';
    }

    sumCountExportFormatter = (row: any, value: any) => {
        return (value && value > 0) ? value : 0;
    }

    isCompleteCensusObservation = (row: any, value: any, formatterOptions: FormatterOptions) => {
        formatterOptions.escapeHTML = false;
        if (value) {
            return checkmarkCircleIcon('Completed');
        }
        return crossCircleIcon('Not Completed');
    }

    colorFormatter = (row: any, value: any, formatterOptions: FormatterOptions) => {
        formatterOptions.escapeHTML = false;
        return value ? `<span class="color-cell" style="background-color: ${value}"></span>` : '';
    }

    formulaCalculator(row: any, value: any, formatterOptions: FormatterOptions) {
        if (!value) {
            return '';
        } else {
            try {
                const parsedExpression = JSON.parse(value);
                let previousFormula = "NULL";
                const currentFormula = this._parseExpressionSegments(parsedExpression.expressionSegments);
                if (row.PreviousFormula) {
                    const previousParsedExpression = JSON.parse(row.PreviousFormula);
                    previousFormula = this._parseExpressionSegments(previousParsedExpression.expressionSegments);
                    return `${previousFormula} → ${currentFormula}`;
                } else {
                    return currentFormula;
                }
            } catch (e) {
                return '';
            }
        }
    }

    private _parseExpressionSegments(expressionSegments: any[]) {
        return expressionSegments.map((es: any) => es.text).join('');
    }

    colorExportFormatter = (row: any, value: any) => value;

    private isEmpty = (value: any) => value === '' || value === null || value === undefined;

    private initGLP() {
        const flag = this.featureFlagService.getFlag("IsGLP");
        this.isGLP = (flag && flag.IsActive && flag.Value.toLowerCase() === "true");
    }
    // phoneNumberFormatter = (row: any, value: any, formatterOptions: FormatterOptions) => {
    //    formatterOptions.escapeHTML = false;
    //    let formatValue = '(' + value.slice(0, 3) + ') ' + value.slice(3, 6) + '-'
    //                     + value.slice(6, 11);
    //    return formatValue;
    // }

    // postalCodeFormatter = (row: any, value: any, formatterOptions: FormatterOptions) => {
    //    formatterOptions.escapeHTML = false;
    //    if (value.length > 5) {
    //        let formatValue = value.slice(0, 5) + '-' + value.slice(5, 9);
    //        return formatValue;
    //    }
    //    return value;
    // }
}

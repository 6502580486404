import { DataContextService } from './../../services/data-context.service';
import { BulkAddCommService } from './../../common/facet/bulk-add-comm.service';
import { BulkAddComponent } from './../../common/facet/bulk-add.component';
import { DroppableEvent } from './../../common/droppable-event';
import { AnimalService } from '../../animals/services/animal.service';
import { LocationService } from './../../locations/location.service';
import { SampleLogicService } from './../sample-logic.service';
import { FacetLoadingStateService } from '../../common/facet/facet-loading-state.service';
import { BulkAddResult } from '../../common/facet/models/bulk-edit.classes';
import { BulkAddExitReason } from '../../common/facet/models/bulk-add-exit-reason.enum';
import { BulkEditSection } from '../../common/facet/models/bulk-edit-section.enum';
import {
    Component,
    EventEmitter,
    Input,
    Output,
    TemplateRef,
    ViewChild,
    AfterViewInit,
    OnInit
} from '@angular/core';

import { JobService } from '../../jobs/job.service';
import { MaterialService } from '../../services/material.service';
import { SampleService } from '../sample.service';
import { notEmpty, uniqueArray, uniqueArrayFromPropertyPath, setSafeProp } from '../../common/util';
import { SampleBulkTemplatesComponent } from './sample-bulk-templates.component';
import { CopyBufferService } from '../../common/services/copy-buffer.service';
import { SettingService } from '../../settings/setting.service';
import { LoggingService } from '../../services/logging.service';
import { NamingService } from '../../services/naming.service';


@Component({
    selector: 'sample-bulk-add',
    templateUrl: './sample-bulk-add.component.html',
    styles: [`
        h5 {
            font-weight: bold;
        }
        .number-items-to-add-label {
            font-weight: normal;
        }
        .dropzone {
            max-height: 180px;
            overflow-y: scroll;
            padding: 10px;
        }
    `]
})
export class SampleBulkAddComponent implements OnInit, AfterViewInit {
    @Input() facet: any;
    @Input() requiredFields: string[] = [];
    @Input() activeFields: string[] = [];

    @Output() exit: EventEmitter<BulkAddResult> = new EventEmitter<BulkAddResult>();
    @ViewChild('itemsToAddTmpl') itemsToAddTmpl: TemplateRef<any>;
    @ViewChild('bulkAdd') bulkAdd: BulkAddComponent;
    @ViewChild('bulkTemplates') bulkTemplates: SampleBulkTemplatesComponent;

    readonly COMPONENT_LOG_TAG = 'sample-bulk-add';

    sampleNamingActive = false;
    BulkEditSection = BulkEditSection;
    sourceMaterials: any[] = [];

    constructor(
        private animalService: AnimalService,
        private bulkAddCommService: BulkAddCommService,
        private copyBufferService: CopyBufferService,
        private sampleService: SampleService,
        private faceLoadingStateService: FacetLoadingStateService,
        private jobService: JobService,
        private locationService: LocationService,
        private materialService: MaterialService,
        private sampleLogicService: SampleLogicService,
        private dataContext: DataContextService,
        private settingService: SettingService,
        private loggingService: LoggingService,
        private namingService: NamingService
    ) {
    }

    ngOnInit(): void {
        this.initialize();
    }

    /**
     * AfterViewInit is when bulkTemplates is fully initialized
     */
    ngAfterViewInit() {
        if (this.bulkTemplates?.bulkOptions) {
            this.bulkTemplates.bulkOptions.itemsToAddTemplate = this.itemsToAddTmpl;
            this.bulkTemplates.bulkOptions.saveButtonValidators = [() => {
                return this.sampleNamingActive;
            }];
        }
    }

    initialize() {
        this.namingService.isSampleNamingActive().then((active: boolean) => {
            this.sampleNamingActive = active;
        });
    }

    onDropSourceMaterials(event: DroppableEvent) {
        if (notEmpty(this.animalService.draggedAnimals)) {
            const draggedAnimals = this.animalService.draggedAnimals;
            this.addSourceMaterials(draggedAnimals);
            this.animalService.draggedAnimals = [];
        }

        if (notEmpty(this.sampleService.draggedSamples)) {
            const draggedSamples = this.sampleService.draggedSamples;
            this.addSourceMaterials(draggedSamples);
            this.sampleService.draggedSamples = [];
        }
    }

    pasteSourceMaterials() {
        if (this.copyBufferService.hasAnimals()) {
            const pastedAnimals = this.copyBufferService.paste();
            this.addSourceMaterials(pastedAnimals);
        }

        if (this.copyBufferService.hasSamples()) {
            const pastedSamples = this.copyBufferService.paste();
            this.addSourceMaterials(pastedSamples);
        }
    }

    addSourceMaterials(newSourceMaterials: any[]) {
        newSourceMaterials = newSourceMaterials.slice();
        newSourceMaterials = this.sourceMaterials.concat(newSourceMaterials);
        this.sourceMaterials = uniqueArray(newSourceMaterials);

        this.syncItemsToAdd();

        this.sampleService.ensureSourceMaterialsExpanded(
            this.sourceMaterials
        ).then(() => {
            // set C_Line_key
            const uniqueLineKeys = uniqueArrayFromPropertyPath(
                this.sourceMaterials, 'Material.C_Line_key'
            );
            if (uniqueLineKeys.length === 1 && this.bulkTemplates) {
                setSafeProp(
                    this.bulkTemplates.bulkOptions.__addObject,
                    'Material.C_Line_key',
                    uniqueLineKeys[0]
                );
            }
        });
    }

    selectSourceMaterial(material: any) {
        if (!material) {
            return;
        }
        this.sourceMaterials.push(material);
        this.sourceMaterials = uniqueArray(this.sourceMaterials);
        this.syncItemsToAdd();
    }

    removeSourceMaterial(material: any) {
        if (!material) {
            return;
        }
        this.sourceMaterials = this.sourceMaterials.filter((item) => {
            return item.C_Material_key !== material.C_Material_key;
        });
        this.syncItemsToAdd();
    }

    /**
     * Set the numberItemsToAdd on BulkAddComponent.
     * It should be in sync with the sourceMaterials list
     */
    syncItemsToAdd() {
        if (!notEmpty(this.sourceMaterials) ||
            !this.bulkAdd
        ) {
            return;
        }

        this.bulkAdd.addState.numberItemsToAdd = this.sourceMaterials.length;
    }

    async saveClicked(result: BulkAddResult) {
        result.newItems = [];
        let promise = Promise.resolve([]);
        switch (result.reason) {
            case BulkAddExitReason.Cancel:
                // do nothing on cancel
                break;
            case BulkAddExitReason.Save:
                // the field names (as they would show up in a facet setting) that show in the bulk add view.
                const bulkAddColumns = [
                    "TimePoint",
                    "DateExpiration",
                    "DateHarvest",
                    "C_PreservationMethod_key",
                    "Material.C_MaterialOrigin_key",
                    "Material.C_ContainerType_key",
                    "Volume",
                    "C_Unit_key",
                    "C_SampleSubtype_key",
                    "C_SampleProcessingMethod_key",
                    "SendTo",
                    "C_SampleAnalysisMethod_key",
                    "Location",
                    "LotNumber",
                    "SpecialInstructions",
                    "Description"
                ];
                const filteredRequiredFields = this.requiredFields.filter((field: string) => bulkAddColumns.includes(field));
                let errorMessage = await this.settingService.validateRequiredFields(filteredRequiredFields, result.initialValues, 'sample') || this.bulkTemplates.validate();
                if (this.requiredFields.includes("Material.MaterialSourceMaterial[0]") && this.sourceMaterials.length === 0) {
                    errorMessage = 'At least one Source is required. Ensure that all required fields are filled.';
                }

                if (errorMessage) {
                    this.loggingService.logError(errorMessage, undefined, "BULK_ADD_SAMPLE", true);
                    promise = Promise.reject([]);
                    break;
                } else {
                    promise = this.createNewSamples(
                        result.numberItemsToAdd,
                        result.initialValues
                    ).then((newSamples) => {
                        this.faceLoadingStateService.changeLoadingState(true);
                        return this.dataContext.save().then(() => {
                            this.faceLoadingStateService.changeLoadingState(false);
                            return newSamples;
                        }).catch((error) => {
                            this.faceLoadingStateService.changeLoadingState(false);
                            for (const sample of newSamples) {
                                this.sampleService.cancelSample(sample);
                            }
                            throw error;
                        });
                    });
                }
     
                break;
            case BulkAddExitReason.Edit:
                promise = this.createNewSamples(
                    result.numberItemsToAdd,
                    result.initialValues
                );
                break;
        }

        promise.then((newItems) => {
            result.newItems = newItems;
            this.bulkAddCommService.saveComplete();
        }).catch((error) => {
            this.bulkAddCommService.saveCanceled();
            throw error;
        });
    }

    exitClicked(result: BulkAddResult) {
        this.exit.emit(result);
    }

    createNewSamples(numberItemsToAdd: number, initialValues: any): Promise<any[]> {
        const samples: any[] = [];
        const promises: Promise<any>[] = [];

        // If we have source materials, add 1 sample per material
        if (notEmpty(this.sourceMaterials)) {
            const p = this.replaceSearchObjectsWithBreezeEntities().then(() => {
                return this.sampleService.ensureSourceMaterialsExpanded(this.sourceMaterials);
            }).then(() => {
                const promises2: Promise<any>[] = [];
                for (const parentMaterial of this.sourceMaterials) {
                    const p2 = this.createNewSample(
                        initialValues, parentMaterial
                    ).then((newSample) => {
                        samples.push(newSample);
                    });
                    promises2.push(p2);
                }
                return Promise.all(promises2);
            });
            promises.push(p);
            
        // otherwise use the numberItemsToAdd count for each sample
        } else {
            for (let i = 0; i < numberItemsToAdd; i++) {
                const p = this.createNewSample(initialValues).then((newSample) => {
                    samples.push(newSample);
                });
                promises.push(p);
            }
    }
        
        return Promise.all(promises).then(() => {
          return samples;  
        });
    }



    createNewSample(initialValues: any, parentMaterial?: any): Promise<any> {

        const materialValues = {
            C_Line_key: initialValues.Material.C_Line_key,
            C_MaterialOrigin_key: initialValues.Material.C_MaterialOrigin_key,
            C_ContainerType_key: initialValues.Material.C_ContainerType_key,
            MicrochipIdentifier: initialValues.Material.MicrochipIdentifier
        };

        return this.materialService.createAsType('Sample', materialValues).then((newMaterial) => {
            const promises: Promise<any>[] = [];

            const newSample = this.sampleService.create();

            newSample.Material = newMaterial;
            newSample.SampleName = null;
            newSample.C_PreservationMethod_key = initialValues.C_PreservationMethod_key;
            newSample.C_SampleStatus_key = initialValues.C_SampleStatus_key;
            newSample.C_ContainerType_key = initialValues.C_ContainerType_key;
            newSample.C_SampleSource_key = initialValues.C_SampleSource_key;
            newSample.C_SampleType_key = initialValues.C_SampleType_key;
            newSample.TimePoint = initialValues.TimePoint;
            newSample.C_TimeUnit_key = initialValues.C_TimeUnit_key;
            newSample.Volume = initialValues.Volume;
            newSample.C_Unit_key = initialValues.C_Unit_key;
            newSample.DateHarvest = initialValues.DateHarvest;
            newSample.DateExpiration = initialValues.DateExpiration;
            newSample.SampleCharacteristics = [];
            newSample.C_SampleOrder_key = initialValues.C_SampleOrder_key;
            newSample.LotNumber = initialValues.LotNumber;
            newSample.SpecialInstructions = initialValues.SpecialInstructions;
            newSample.C_SampleSubtype_key = initialValues.C_SampleSubtype_key;
            newSample.C_SampleProcessingMethod_key = initialValues.C_SampleProcessingMethod_key;
            newSample.SendTo = initialValues.SendTo;
            newSample.C_SampleAnalysisMethod_key = initialValues.C_SampleAnalysisMethod_key;
            newSample.Description = initialValues.Description;

            if (parentMaterial) {
                // Add material source to new sample
                this.addMaterialSourceMaterial(newSample, parentMaterial);
                // Treat it like selection event so dependent data is set
                const p1 = this.sampleLogicService.onSourceSelection(newSample, parentMaterial);
                promises.push(p1);
            } else if (initialValues.Material.C_Line_key) {
                newMaterial.C_Line_key = initialValues.Material.C_Line_key;
                // Set taxon based on line
                const p2 = this.sampleLogicService.getLineByKey(
                    newMaterial.C_Line_key
                ).then((line) => {
                    newMaterial.cv_Taxon = line.cv_Taxon;
                });
                promises.push(p2);
            }

            if (initialValues.JobKey) {
                const p3 = this.sampleLogicService.addJobMaterial(initialValues.JobKey, newSample);
                promises.push(p3);
            }

            if (initialValues.locationPosition) {
                promises.push(this.locationService.processSampleLocationChange(
                    newSample,
                    initialValues.locationPosition
                ));
            }

            const p4 = this.sampleLogicService.createCharacteristics(newSample).then(() => {
                this.setCharacteristicDefaults(newSample, initialValues);
            });
            promises.push(p4);

            return Promise.all(promises).then(() => {
                return newSample;
            });
        });
    }
    
    private replaceSearchObjectsWithBreezeEntities(): Promise<any> {
        const promises: Promise<any>[] = [];

        const length = this.sourceMaterials.length;
        for (const sourceMaterial of this.sourceMaterials) {
            if (sourceMaterial.entityAspect) {
                continue;
            }

            // swap search object for breeze entity
            const materialKey = sourceMaterial.C_Material_key;
            const p = this.animalService.getAnimal(materialKey).then((breezeAnimal) => {
                for (let i = 0; i < length; i++) {
                    if (this.sourceMaterials[i].C_Material_key === materialKey) {
                        this.sourceMaterials[i] = breezeAnimal;
                        break;
                    }
                }
            });
            promises.push(p);
        }

        return Promise.all(promises);
    }

    private addMaterialSourceMaterial(sample: any, sourceMaterial: any) {
        const initialValues = {
            C_Material_key: sample.C_Material_key,
            C_SourceMaterial_key: sourceMaterial.C_Material_key
        };
        const newEntity = this.sampleService.createMaterialSourceMaterial(initialValues);

        if (newEntity) {
            sample.Material.MaterialSourceMaterial.push(newEntity);
        }
    }

    addJobMaterial(animal: any, jobKey: number) {
        const materialKey = animal.C_Material_key;
        this.jobService.createJobMaterial({
            C_Material_key: materialKey,
            C_Job_key: jobKey,
            Version: 0
        });
    }

    setCharacteristicDefaults(sample: any, initialValues: any) {
        if (!notEmpty(sample.SampleCharacteristicInstance) ||
            !notEmpty(initialValues.SampleCharacteristicInstance)
        ) {
            return;
        }

        for (const characteristic of sample.SampleCharacteristicInstance) {
            const initialCharacteristic = initialValues.SampleCharacteristicInstance
                .find((item: any) => {
                    return item.C_SampleCharacteristic_key === 
                        characteristic.C_SampleCharacteristic_key;
                });
            if (initialCharacteristic &&
                initialCharacteristic.CharacteristicValue) {
                characteristic.CharacteristicValue = initialCharacteristic.CharacteristicValue;
            }
        }
    }
}

import { getSafeProp } from './get-safe-prop';
import { notEmpty } from './not-empty';
import { sortObjectArrayByProperty } from './sort-array';

export interface MaterialPoolMaterial {
    DateOut?: Date | string | null;
    DateIn?: Date | string | null;
    MaterialPool: {
        MaterialPoolID: string;
    };
}

/**
 * Returns the current MaterialPoolID from a list of MaterialPoolMaterials
 *   (i.e., the object with the latest DateIn and no DateOut).
 * @param materialPoolMaterials - list of MaterialPoolMaterials
 */
export function currentMaterialHousingID(materialPoolMaterials: MaterialPoolMaterial[]): string {
    let housingID = '';

    if (notEmpty(materialPoolMaterials)) {
        const openPools = getOpenMaterialPoolMaterials(materialPoolMaterials);

        if (notEmpty(openPools)) {
            housingID = getLatestMaterialPoolID(openPools);
        }
    }

    return housingID;
}

function getOpenMaterialPoolMaterials(materialPoolMaterials: MaterialPoolMaterial[]): MaterialPoolMaterial[] {
    // Items with a DateIn but no DateOut
    return materialPoolMaterials.filter((materialPoolMaterial) => {
        return (!materialPoolMaterial.DateOut && materialPoolMaterial.DateIn);
    });
}

function getLatestMaterialPoolID(materialPoolMaterials: MaterialPoolMaterial[]): string {
    // Sort by DateIn descending and use the first item
    const sorted = sortObjectArrayByProperty(materialPoolMaterials, 'DateIn', true);

    const out = getSafeProp(sorted[0], 'MaterialPool.MaterialPoolID');
    return out !== undefined ? out : '';
}

import { Observable } from 'rxjs';
import { EventEmitter, Injectable } from '@angular/core';
import type { ColumnSortState, ItemSortState, SortState } from './sort.interface';


@Injectable()
export class SortService {
    private readonly sortMap: Map<string, ItemSortState> = new Map();

    private readonly sortState: EventEmitter<SortState> = new EventEmitter();
    readonly sortState$: Observable<SortState> = this.sortState.asObservable();

    getState(): SortState {
        return {
            state: Object.fromEntries(this.sortMap),
            isMultipleSort: this.sortMap.size > 1,
        };
    }

    clearState(): void {
        this.sortMap.clear();
    }

    emitState(): void {
        this.sortState.emit(this.getState());
    }

    addSortColumn(columnState: ColumnSortState): void {
        if (this.sortMap.has(columnState.columnId)) {
            const prevSortOrder = this.sortMap.get(columnState.columnId).sortOrder;
            this.sortMap.delete(columnState.columnId);
            this.sortMap.forEach((item) => {
                const orderShift = item.sortOrder < prevSortOrder ? 0 : -1;
                item.sortOrder = item.sortOrder + orderShift;
            });
        }

        if (columnState.direction === '') {
            return;
        }

        this.sortMap.set(columnState.columnId, {
            ...columnState,
            sortOrder: this.sortMap.size + 1,
        });
    }
}

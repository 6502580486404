import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { ClimbIndirectTypeaheadComponent } from './climb-indirect-typeahead.component';
import { ClimbTypeaheadComponent } from './climb-typeahead.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgbTypeaheadModule,
    ],
    declarations: [
        ClimbTypeaheadComponent,
        ClimbIndirectTypeaheadComponent,
    ],
    exports: [
        ClimbTypeaheadComponent,
        ClimbIndirectTypeaheadComponent,
    ]
})
export class TypeaheadModule { }

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { ClimbCommonModule } from '../common/climb-common.module';
import { ClimbServicesModule } from '../services/climb-services.module';

import { ConstructSelectComponent } from './construct-select.component';
import { ConstructMultiselectComponent } from './construct-multiselect.component';
import { ConstructService } from './construct.service';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ClimbCommonModule,
        ClimbServicesModule
    ],
    declarations: [
        ConstructMultiselectComponent,
        ConstructSelectComponent
    ],
    exports: [
        ConstructMultiselectComponent,
        ConstructSelectComponent
    ],
    providers: [
        ConstructService
    ]
})
export class ConstructsSharedModule { }

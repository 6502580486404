import { Injectable } from '@angular/core';

import { getMaterialName } from '../../common/export';
import { getSafeProp } from '../../common/util';

import { BaseExportPlateDetailService } from './base-export-plate-detail.service';
import { getLineValue } from './export-utils';

/**
 * Exports a plate detail record to a Transnetyx-formatted CSV.
 *
 * Assumes plate record has all relationships loaded.
 */
@Injectable()
export class ExportPlateDetailTyxService extends BaseExportPlateDetailService {


    constructor() {
        super();
    }


    protected getFileName(plate: any): string {
        return 'plate-tyx.csv';
    }

    protected getColumnHeaders(): string[] {
        return [
            'Well',
            'WellPlate',
            'SampleName',
            'StrainName',
            'IsControl',
            'Omit',
            'ControlResult'
        ];
    }

    protected getDataRow(plate: any, plateMaterial: any): any[] {
        let materialDisplay = '';
        let lineDisplay = '';
        if (plateMaterial.Material) {
            const material = plateMaterial.Material;
            materialDisplay = getMaterialName(material);
            lineDisplay = getLineValue(material);
        }

        return [
            // Well
            getSafeProp(plateMaterial, 'PlatePosition.PositionName'),
            // WellPlate
            getSafeProp(plate, 'ExternalPlateID'),
            // SampleName
            materialDisplay,
            // StrainName
            lineDisplay,
            // IsControl
            '',
            // Omit
            '',
            // ControlResult
            '',
        ];
    }
}

import { BulkEditCommService } from './bulk-edit-comm.service';
import { BulkEditSharedLogic } from './bulk-edit.shared';
import { 
    BulkEditOptions, 
    BulkEditField,
    BulkEditSection 
} from './models';
import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output
} from '@angular/core';
import { 
    getSafeProp, 
    setSafeProp 
} from '../util';
import { IButtonBulkEdit } from '@common/bulk-edit-header.component';

@Component({
    selector: 'bulk-edit-table',
    templateUrl: './bulk-edit-table.component.html',
    styles: [`
        .bulk-edit-table thead th:last-child {
            min-width: 350px;
        }
    `]
})
export class BulkEditTableComponent implements OnChanges {
    @Input() facet: any;
    @Input() options: BulkEditOptions;
    @Input() itemsToEdit: any[];
    @Input() bulkEditCustomButtons: IButtonBulkEdit[] = [];
    @Output() updateCustomButtonClicked: EventEmitter<unknown> = new EventEmitter<unknown>();

    BulkEditSection = BulkEditSection;

    sharedLogic: BulkEditSharedLogic;
    
    constructor(
        private bulkEditCommService: BulkEditCommService
    ) {
        this.sharedLogic = new BulkEditSharedLogic();
    }

    ngOnChanges(changes: any) {
        this.sharedLogic.updateBulkOptions(this.options, this.facet);
        this.bulkEditCommService.optionsUpdated();
    }

    updateClicked(field: BulkEditField) {
        if (!field.__updateObject) {
            return;
        }

        const value = getSafeProp(field.__updateObject, field.modelPath);
        if (field.onUpdateItem) {
            field.onUpdateItem(field.__updateObject, value);
        } else {
            for (const item of this.itemsToEdit) {
                setSafeProp(item, field.modelPath, value);
            }
        }

        // clear update object
        this.sharedLogic.initUpdateObject(field);
    }
}

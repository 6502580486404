/**
 * values in cv_DataType
 */
export enum DataType {
    BOOLEAN = 'Boolean',
    CALCULATED = 'Calculated',
    DATE = 'Date', 
    DATETIME = 'DateTime',
    VOCABULARY = 'Vocabulary',
    ENUMERATION = 'Enumeration',
    NUMBER = 'Number',
    LONG_ENUMERATION = 'Long Enumeration',
    LONG_TEXT = 'Long Text',
    TEXT = 'Text',
    CV_SELECT = 'Controlled Vocabulary',
    CV_MULTISELECT = 'Controlled Vocabulary Multiselect',
    COHORT_SELECT = 'Cohort Select',
    CONSTRUCT_SELECT = 'Construct Select',
    JOB_SELECT = 'Job Select',
    LINE_SELECT = 'Line Select',
    OUTPUT_SELECT = 'Output Select',
    STUDY_SELECT = 'Study Select',
    USER_SELECT = 'User Select',
    TASK_SELECT = 'Task Select',
    INHERITED_MOST_RECENT = 'Inherited (Most Recent Value)',
    INHERITED_SECOND_MOST_RECENT = 'Inherited (Second Most Recent Value)',
    INHERITED_THIRD_MOST_RECENT = 'Inherited (Third Most Recent Value)',
    INHERITED_FIRST_OCCURRENCE = 'Inherited (First Occurrence)',
    DOSING_TABLE = 'Dosing Table',
    JOB_CHARACTERISTIC = 'Job Characteristic',
}

<ng-template #standardphrasecheck let-standardPhraseCV="standardPhraseCV">
    <input type="checkbox" [(ngModel)]="standardPhraseCV.IsSelected"
           name="large-checkbox{{standardPhraseCV.C_StandardPhrase_key}}"
           id="large-checkbox{{standardPhraseCV.C_StandardPhrase_key}}"
           autocomplete="off"
           class="standard-phrase-button"/>
    <div class="btn-group">
        <label for="large-checkbox{{standardPhraseCV.C_StandardPhrase_key}}"
               class="btn btn-secondary input-shape">
            <span class="fa fa-check"></span>
            <span> </span>
        </label>
        <label for="large-checkbox{{standardPhraseCV.C_StandardPhrase_key}}"
               class="phrase-label">
            {{standardPhraseCV.StandardPhrase | truncateSentence:200}}
        </label>
    </div>
</ng-template>

<ng-template #standardphrase let-standardPhrases="standardPhrases">
    <div class="row">
        <div class="col-md-6 checkbox-column">
            <div *ngFor="let standardPhraseCV of standardPhrases | half:'first'"
                 class="checkbox-group">
                <ng-template [ngTemplateOutlet]="standardphrasecheck"
                             [ngTemplateOutletContext]="{
                        standardPhraseCV : standardPhraseCV
                        }"
                ></ng-template>
            </div>
        </div>
        <div class="col-md-6 checkbox-column">
            <div *ngFor="let standardPhraseCV of standardPhrases | half:'last'"
                 class="checkbox-group">
                <ng-template [ngTemplateOutlet]="standardphrasecheck"
                             [ngTemplateOutletContext]="{
                        standardPhraseCV : standardPhraseCV
                        }"
                ></ng-template>
            </div>
        </div>
    </div>
</ng-template>

<div class="modal-header justify-content-center">
    <h4 class="modal-title">Select Standard Phrases</h4>
</div>
<div class="modal-body">
    <form role="form">
        <h4 class="mb-3">Select Standard Phrases and Click "Select" to Confirm Selection</h4>
        <ng-container *ngIf="!isCRL; else categoryPhrases">
            <ng-template [ngTemplateOutlet]="standardphrase"
                        [ngTemplateOutletContext]="{
                            standardPhrases : standardPhrases
                        }"
            ></ng-template>
        </ng-container>

        <ng-template #categoryPhrases>
            <nav ngbNav #nav="ngbNav" class="nav-tabs p-0" [activeId]="0">
                <ng-container *ngFor="let category of categoriesWithPhrases; index as id" [ngbNavItem]="id">
                    <a ngbNavLink>{{ category.categoryName }}</a>
                    <ng-template ngbNavContent>
                        <ng-template [ngTemplateOutlet]="standardphrase"
                                     [ngTemplateOutletContext]="{
                                    standardPhrases : category.phrases
                                }"
                        ></ng-template>
                    </ng-template>
                </ng-container>
            </nav>
            <div [ngbNavOutlet]="nav" class="mt-2"></div>
        </ng-template>
    </form>
</div>
<div class="modal-footer">
    <button type="submit" class="btn btn-fw btn-lg btn-primary" (click)="okClicked($event)">Select</button>
    <button type="button" class="btn btn-fw btn-lg btn-secondary" (click)="closeClicked()">Cancel</button>
</div>

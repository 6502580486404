import {
    Component,
    Input,
} from '@angular/core';

import { SearchService } from '../search/search.service';
import { SearchQueryDef } from '../search/search-query-def';
import { LoggingService } from '../services/logging.service';
import { TranslationService } from '../services/translation.service';
import { JobOrderService } from './job-order.service';

const MAX_SEARCH_RESULTS = 50;

@Component({
    selector: 'job-order-multiselect',
    template: `
<!-- Selected orders -->
<div *ngIf="job.JobOrder && job.JobOrder.length > 0">
    <table class="table table-sm table-borderless table-nonfluid">
        <tbody>
            <tr *ngFor="let jobOrder of job.JobOrder">
                <td *ngIf="!readonly" class="icon-cell">
                    <a (click)="removeJobOrder(jobOrder)" title="Remove">
                        <i class="fa fa-remove remove-item"></i>
                    </a>
                </td>
                <td>
                    {{jobOrder.Order.OrderID}}
                </td>
            </tr>
        </tbody>
    </table>
</div>

<!-- Order typeahead -->
<climb-indirect-typeahead
        [search]="searchOrders"
        [resultFormatter]="orderIdFormatter"
        [required]="isRequired"
        [placeholder]="'Order ID...'"
        (selectItem)="selectJobOrder($event)"></climb-indirect-typeahead>
`,
    styles: [`
        .table {
            margin-bottom: 0.25em;
        }
    `]
})
export class JobOrderMultiselectComponent  {
    @Input() job: any;
    @Input() readonly: boolean;
    @Input() required: boolean;

    constructor(
        private _searchService: SearchService,
        private jobOrderService: JobOrderService,
        private loggingService: LoggingService,
        private translationService: TranslationService,
    ) {

    }

    /**
     * Removes the required option if at least one JobOrder value is set
     */
    get isRequired(): boolean {
        return this.required && !(this.job.JobOrder?.length > 0);
    }

    searchOrders = (term: string): Promise<any> => {

        let count = null;
        if (term.length < 3) {
            count = MAX_SEARCH_RESULTS;
        }

        const orderFilter = {
            OrderID: term
        };

        const searchQueryDef: SearchQueryDef = {
            entity: 'Orders',
            page: 1,
            size: count,
            sortColumn: 'OrderID',
            sortDirection: 'asc',
            filter: orderFilter
        };

        return this._searchService.getEntitiesBySearch(searchQueryDef)
            .then((results: any) => {
                return results.data;
            });
    }

    orderIdFormatter = (value: any) => {
        return value.OrderID;
    }

    async selectJobOrder(jobOrder: any) {
        if (this.readonly) {
            return;
        }
        return this.jobOrderService.getJobOrderByKey(jobOrder.OrderKey).then(async (results: any) => {
            if (results) {
                this.loggingService.logError(
                    `Please select an Order ID that has not been already assigned to a ` + this.translationService.translate('Job'),
                    null, 'job-pharma-detail', true);
                return;
            }
            const initialValues = {
                C_Job_key: this.job.C_Job_key,
                C_Order_key: jobOrder.OrderKey,
            };
            await this.jobOrderService.createJobOrder(initialValues);
        });
    }

    async removeJobOrder(jobOrder: any) {
        if (this.readonly) {
            return;
        }
        await this.jobOrderService.deleteJobOrder(jobOrder);
    }
}

import type { Line } from './line.interface';
import type { Marker } from './marker.interface';
import type { Material } from './material.interface';
import type { TaxonCharacteristic } from './taxon-characteristic.interface';

export interface cv_Taxon {
    C_Taxon_key: number;
    C_VocabularySource_key: number | null;
    C_Workgroup_key: number;
    CommonName: string;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    DaysToWean: number | null;
    ElementID: string;
    IsActive: boolean;
    IsDefault: boolean;
    IsDeprecated: boolean;
    Line: Line[];
    Marker: Marker[];
    Material: Material[];
    ModifiedBy: string;
    SortOrder: number | null;
    Taxon: string;
    TaxonCharacteristic: TaxonCharacteristic[];
    Version: number;
}

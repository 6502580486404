import {
    Component,
    EventEmitter,
    Input,
    Output
} from '@angular/core';

@Component({
    selector: 'lock-control',
    templateUrl: './lock-control.component.html',
    styles: [`
        .unlocked-indicator {
            opacity: 0.5;
        }
        a .unlocked-indicator {
            opacity: 0.7;
        }
        a .unlocked-indicator:hover {
            opacity: 1;
        }
        .large-text .fa {
            font-size: 200% !important;
        }
    `]
})
export class LockControlComponent {
    @Input() model: boolean;
    @Input() administrator = false;
    @Input() readonly = false;
    @Input() largeText = false;

    @Output() modelChange: EventEmitter<boolean> = new EventEmitter<boolean>();


    constructor() {
        // Nothing to do
    }


    toggleLock() {
        this.model = !this.model;
        this.modelChange.emit(this.model);
    }
}

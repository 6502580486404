/**
 * It is used in a `switch` statement.
 * If the possible options of the `switch` operator are changed,
 * the TypeScript compiler crashes with an error.
 * For example, if an additional `case` variant is added
 * to the existing options. When we do this, we will immediately see
 * TypeScript compiler errors in all places where we used
 * the `switch` + `notReachable` construction. It allows us to add
 * the required option and be sure we do not forget to change it
 * in all places.
 */
export function notReachable(_: never): never {
    throw new Error(`Should never be reached ${_}`);
}

<table class="table table-bordered job-pharma-animals-individual-placeholder">
    <thead>
        <tr class="detail-grid-header">
            <!-- Select -->
            <th *ngIf="!readonly" class="icon-cell">
                <input type="checkbox"
                       name="selectAll"
                       [(ngModel)]="allSelected"
                       (ngModelChange)="allSelectedChanged()"
                       title="Select/Clear all" />
            </th>
            <th>
                <a (click)="sortColumn('Name', $event)"
                   class="header-sort-link"
                   title="Sort by Name">
                    Placeholder {{ tableSort.nested ? tableSort.nestedIndex['Name'] : '' }}
                    <i class="fa"
                        [ngClass]="{
                            'fa-arrow-down': tableSort.nested ? tableSort.nestedReverse['Name'] : tableSort.reverse,
                            'fa-arrow-up': tableSort.nested ? !tableSort.nestedReverse['Name'] : !tableSort.reverse
                            }"
                        style="font-size: 10px !important;"
                        *ngIf="tableSort.propertyPath === 'Name'
                        || tableSort.nested && tableSort.properties.includes('Name')"
                    ></i>
                </a>
            </th>
            <th>
                <a (click)="sortColumn('Material.Animal.AnimalNameSortable', $event)"
                   class="header-sort-link"
                   title="Sort by Animal Name">
                    Animal {{ tableSort.nested ? tableSort.nestedIndex['Material.Animal.AnimalNameSortable'] : '' }}
                    <i class="fa"
                        [ngClass]="{
                            'fa-arrow-down': tableSort.nested ? tableSort.nestedReverse['Material.Animal.AnimalNameSortable'] : tableSort.reverse,
                            'fa-arrow-up': tableSort.nested ? !tableSort.nestedReverse['Material.Animal.AnimalNameSortable'] : !tableSort.reverse
                            }"
                        style="font-size: 10px !important;"
                        *ngIf="tableSort.propertyPath === 'Material.Animal.AnimalNameSortable'
                        || tableSort.nested && tableSort.properties.includes('Material.Animal.AnimalNameSortable')"
                    ></i>
                </a>
            </th>
            <th>
                <a (click)="sortColumn('Placeholder.PlaceholderName', $event)"
                   class="header-sort-link"
                   title="Sort by Group Placeholder Name">
                    Group Placeholder {{ tableSort.nested ? tableSort.nestedIndex['Placeholder.PlaceholderName'] : '' }}
                    <i class="fa"
                        [ngClass]="{
                            'fa-arrow-down': tableSort.nested ? tableSort.nestedReverse['Placeholder.PlaceholderName'] : tableSort.reverse,
                            'fa-arrow-up': tableSort.nested ? !tableSort.nestedReverse['Placeholder.PlaceholderName'] : !tableSort.reverse
                            }"
                        style="font-size: 10px !important;"
                        *ngIf="tableSort.propertyPath === 'Placeholder.PlaceholderName'
                        || tableSort.nested && tableSort.properties.includes('Placeholder.PlaceholderName')"
                    ></i>
                </a>
            </th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let placeholder of animalPlaceholders; index as animalIndex"
            [ngClass]="{'selected-row': placeholder.isSelected}"
            class="cohort-row">
            <!-- Select -->
            <td *ngIf="!readonly"
                class="icon-cell">
                <input type="checkbox"
                        name="placeholderIsSelected{{animalIndex}}"
                        [(ngModel)]="placeholder.isSelected"
                        (ngModelChange)="isSelectedChanged()" />
            </td>

            <!-- Placeholder -->
            <td>
                <div draggable
                     data-drag-type="individual-placeholder"
                     (dragStart)="dragStart()"
                     (dragStop)="dragStop()"
                     [dragDisabled]="!placeholder.isSelected">
                    {{placeholder.Name}}
                </div>
            </td>

            <!-- Animal -->
            <td>
                <span *ngIf="placeholder.C_Material_key"
                      id="drag-animals-individual-{{placeholder.C_Material_key}}">
                      {{ placeholder.Material.Animal.AnimalName }}
                </span>
            </td>

            <!-- Group Placeholder -->
            <td>
                {{ placeholder.Placeholder.PlaceholderName }}
            </td>
        </tr>
    </tbody>
</table>

export class AuditClassFactory {

  static createCellClass(field: string) {
    return (params: any) => {
        const row = params?.data;
        if (!row || !row.UpdateType) {
            return [];
        }
        const classes = AuditClassFactory.createDeletedClass(params);
        if (params?.data?.ModifiedFields?.toLowerCase().split(', ').indexOf(field) > -1) {
            classes.push('modified-field');
        }
        return classes;
    }
  }

  static createDeletedClass(params: any) {
      const classes: string[] = [];
      if (params?.data?.UpdateType?.toLowerCase() === 'deleted') {
          classes.push('deleted-type');
      }
      return classes;
  }
}
import {
    CellFormatterService,
    FormatterOptions,
    TableOptions
} from '../common/datatable';
import { TranslationService } from '../services/translation.service';
import { AuditModifiedFieldComponent } from './audit-modified-fields/audit-modified-field.component';
import { AuditClassFactory } from './audit-class-maker';

export class AuditJobSamplesTableOptions {

    options: TableOptions;

    constructor(
        private cellFormatterService: CellFormatterService,
        private translationService: TranslationService,
    ) {
        this.options = this.getDefaultOptions();
    }

    getDefaultOptions(): TableOptions {

        return {
            csvFileName:
            this.translationService.translate('job').toLowerCase() + '-samples-audit.csv',
            enableDetailColumn: false,
            enableDraggable: false,
            enableSelectable: false,
            enableAuditColumns: false,
            columns: [
                {
                    displayName: 'Type',
                    field: 'UpdateType',
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Modified Fields',
                    field: 'ModifiedFields',
                    sortField: 'ModifiedFields',
                    rendererComponent: AuditModifiedFieldComponent,
                    exportFormatter: this.cellFormatterService.auditModifiedFieldsExportFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Date',
                    field: 'ModifiedDate',
                    formatter: this.cellFormatterService.dateTimeUTCFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Modified By',
                    field: 'ModifiedBy',
                    formatter: this.cellFormatterService.userNameFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: this.translationService.translate('Job') + ' Name',
                    field: 'JobName',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('JobName', (this.translationService.translate('Job') + 'Name')),
                    cellClass: AuditClassFactory.createCellClass((this.translationService.translate('Job') + 'Name').toLowerCase()),
                },
                {
                    displayName: 'Sample ID',
                    field: 'Identifier',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('Identifier', 'Identifier'),
                    cellClass: AuditClassFactory.createCellClass('identifier'),
                },
                {
                    displayName: 'Name',
                    field: 'Name',
                    visible: false,
                    formatter: this.cellFormatterService.createAuditColumnFormatter('Name', 'Name'),
                    cellClass: AuditClassFactory.createCellClass('name'),
                },
                {
                    displayName: 'Sample Type',
                    field: 'SampleType',
                    visible: false,
                    formatter: this.cellFormatterService.createAuditColumnFormatter('SampleType', 'SampleType'),
                    cellClass: AuditClassFactory.createCellClass('sampletype'),
                },
                {
                    displayName: 'Status',
                    field: 'SampleStatus',
                    visible: false,
                    formatter: this.cellFormatterService.createAuditColumnFormatter('SampleStatus', 'SampleStatus'),
                    cellClass: AuditClassFactory.createCellClass('samplestatus'),
                },
                {
                    displayName: this.translationService.translate('Line'),
                    field: 'Line',
                    visible: false,
                    formatter: this.cellFormatterService.createAuditColumnFormatter('Line', this.translationService.translate('Line')),
                    cellClass: AuditClassFactory.createCellClass(this.translationService.translate('Line').toLowerCase()),
                },
                {
                    displayName: this.translationService.translate('Line') + ' Short Name',
                    field: 'LineShortName',
                    visible: false,
                    formatter: this.cellFormatterService.createAuditColumnFormatter('LineShortName', this.translationService.translate('Line') + 'ShortName'),
                    cellClass: AuditClassFactory.createCellClass((this.translationService.translate('Line') + 'ShortName').toLowerCase()),
                },
                {
                    displayName: 'Created By',
                    field: 'CreatedBy',
                    formatter: this.cellFormatterService.userNameFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Created Date',
                    field: 'DateCreated',
                    formatter: this.cellFormatterService.dateTimeUTCFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                }
            ]
        };
    }
}

import { Pipe, PipeTransform } from '@angular/core';

import {
    getSafeProp,
    notEmpty,
    uniqueArrayOnProperty
} from '../../common/util';

/**
 * Returns protocol instances from a list of TaskJob.
 */
@Pipe({
    name: 'protocols',
    pure: false
})
export class ProtocolsPipe implements PipeTransform {

    readonly TARGET_PROPERTY = 'TaskInstance.ProtocolInstance';

    transform(items: any[]): any[] {
        let mapped: any[] = [];

        if (notEmpty(items)) {
            mapped = items
                .map((taskJob) => {
                    return (
                        getSafeProp(taskJob, this.TARGET_PROPERTY)
                    );
                })
                .filter((taskJob) => {
                    return !!taskJob;
                });
        }

        if (notEmpty(mapped)) {
            mapped = uniqueArrayOnProperty(mapped, 'C_ProtocolInstance_key');
        }

        return mapped;
    }
}

import {
    Component,
    Input,
    OnChanges,
    OnInit,
} from '@angular/core';
import { MatingService } from '../mating.service';

@Component({
    selector: 'mating-birth-table',
    templateUrl: './mating-birth-table.component.html'
})
export class MatingBirthTableComponent implements OnChanges, OnInit {
    @Input() mating: any;
    @Input() births: any[];
    @Input() facetPrivilege: string;

    constructor(
        private matingService: MatingService
    ) {
        //
    }

    ngOnInit() {
        this.initialize();
    }

    ngOnChanges(changes: any) {
        if (this.mating && !changes.mating.firstChange) {
            this.initialize();
        }
    }

    initialize() {
        this.matingService.ensureBirthMaterialsLoaded(this.births);
    }

    isBirthMaterial(materialKey: number, birthMaterials: any): boolean {
        for (const birthMaterial of birthMaterials) {
            if (birthMaterial.C_Material_key === materialKey) {
                return true;
            }
        }
        return false;
    }
}

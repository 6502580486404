export interface cv_StandardPhraseVariableType {
    C_StandardPhraseVariableType_key: number;
    StandardPhraseVariableType: string;
    IsActive: boolean;
    IsDefault: boolean | null;
    SortOrder: number;
    IsDeprecated: boolean | null;
    C_Workgroup_key: number;
    CreatedBy: string;
    DateCreated: string;
    ModifiedBy: string;
    DateModified: string;
    Version: number;
}

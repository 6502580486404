export const facetsForFacetLevelSave = [
    'animal-detail',
    'animal-bulk-edit',
    'animal-bulk-add',
    'order-detail',
    'housing-detail',
    'housing-bulk-edit',
    'protocol-detail',
    'schedule-detail',
    'task-detail',
    'study-detail',
    'location-detail',
    'cohort-detail',
    'line-detail',
    'enumerations-facet',
] as const;


<ng-template #idTmpl let-sample="item">
    <ng-container *ngIf="sample.Material">
        <input type="text"
            *ngIf="!(sample | isNew)"
            [(ngModel)]="sample.Material.Identifier"
            class="form-control input-medium"
            readonly="readonly"/>
    </ng-container>
</ng-template>

<ng-template #nameTmpl let-sample="item" let-section="section">

    <multi-paste-input *ngIf="section === BulkEditSection.EditHeader"
                    [(model)]="sample.SampleNames"
                    [limit]="MULTI_PASTE_INPUT_LIMIT"
                    [rows]="10"></multi-paste-input>

    <input type="text" *ngIf="section === BulkEditSection.InputCell"
        [(ngModel)]="sample.SampleName"
        [disabled]="((sample | isNew) && sampleNamingActive) || section == BulkEditSection.AddScreen"
        [required]="!(sample | isNew) || !sampleNamingActive"
        class="form-control input-medium" />
    <ng-container *ngIf="section === BulkEditSection.AddScreen">
        <small class="form-text text-muted" *ngIf="!sampleNamingActive">
            Edit and Save Records to enter names
        </small>
    </ng-container>
</ng-template>

<ng-template #sourceTmpl let-sample="item" let-section="section">

    <div ng-container  style="min-width: 300px;">
        <material-multiselect *ngIf="section === BulkEditSection.EditHeader"
                              [model]="sample.Material.MaterialSourceMaterial"
                              [enableStatusColumn]="true"
                              [required]="requiredFields.includes('Material.MaterialSourceMaterial[0]') && sample?.Material?.MaterialSourceMaterial?.length === 0"
                              (selectMaterial)="selectMaterial(sample, $event)"
                              (removeMaterial)="removeMaterial(sample, $event)">
        </material-multiselect>

        <sample-source-select *ngIf="section === BulkEditSection.InputCell"
                              [sample]="sample"
                              [required]="requiredFields.includes('Material.MaterialSourceMaterial[0]') && sample?.Material?.MaterialSourceMaterial?.length === 0"
                              (selectSource)="onSourceSelection(sample, $event)">
        </sample-source-select>
    </div>
</ng-template>

<ng-template #sampleTypeTmpl let-sample="item" let-section="section">
    <!-- SampleType is required in AddScreen and InputCell -->
    <active-vocab-select [(model)]="sample.C_SampleType_key"
        (modelChange)="onTypeChange(sample, section)"
        [vocabChoices]="sampleTypes"
        [keyFormatter]="sampleTypeKeyFormatter"
        [optionFormatter]="sampleTypeFormatter"
        [required]="section!==BulkEditSection.EditHeader"
        [loadDefaultValue]="section === BulkEditSection.AddScreen"
        [defaultValue]="sampleTypeDefaultKey"></active-vocab-select>
</ng-template>

<ng-template #timePointTmpl let-sample="item" let-section="section">
    <div class="d-flex">
        <input type="number"
               min="0"
               step="any"
               name="timePoint"
               [(ngModel)]="sample.TimePoint"
               decimalFormatter
               [decimalPlaces]="3"
               class="form-control input-small timePoint-input"
               [required]="requiredFields.includes('TimePoint')" />
        <active-vocab-select [(model)]="sample.C_TimeUnit_key"
                             [vocabChoices]="timeUnits"
                             [keyFormatter]="timeUnitKeyFormatter"
                             [optionFormatter]="timeUnitFormatter"
                             [required]="requiredFields.includes('TimePoint')"
                             [nullable]="!requiredFields.includes('TimePoint')"></active-vocab-select>
    </div>
</ng-template>

<ng-template #harvestDateTmpl let-sample="item">
    <climb-ngb-date #dateControl="ngModel"
                    [(ngModel)]="sample.DateHarvest"
                    [ngModelOptions]="{ standalone: true }"
                    [required]="requiredFields.includes('DateHarvest')"></climb-ngb-date>
</ng-template>

<ng-template #expirationDateTmpl let-sample="item">
    <climb-ngb-date #dateControl="ngModel"
                    [(ngModel)]="sample.DateExpiration"
                    [ngModelOptions]="{ standalone: true }"
                    [required]="requiredFields.includes('DateExpiration')"></climb-ngb-date>
</ng-template>

<ng-template #lineTmpl let-sample="item" let-section="section">
    <div style="min-width: 300px;" *ngIf="sample.Material">
        <ng-container *ngIf="section===BulkEditSection.EditHeader">
            <line-select [(model)]="sample.Material.C_Line_key"></line-select>
        </ng-container>
        <!-- line is required in AddScreen and InputCell -->
        <ng-container *ngIf="section===BulkEditSection.InputCell">
            <line-select [(model)]="sample.Material.C_Line_key"
                [required]="false"
                (modelChange)="onLineSelection($event, sample)"></line-select>
        </ng-container>
        <ng-container  *ngIf="section===BulkEditSection.AddScreen">
            <line-select [(model)]="sample.Material.C_Line_key"
                [disabled]="addLineDisabled"
                (modelChange)="onLineSelection($event, sample)"></line-select>
            <small class="form-text text-muted" *ngIf="addLineDisabled">
                {{'Line' | translate}} for each sample will be inherited from source.
            </small>
        </ng-container>
    </div>
</ng-template>
<ng-template #statusTmpl let-sample="item" let-section="section">
    <!-- SampleStatus is required in AddScreen and InputCell -->
    <active-vocab-select [(model)]="sample.C_SampleStatus_key"
        [vocabChoices]="sampleStatuses"
        [keyFormatter]="sampleStatusKeyFormatter"
        [optionFormatter]="sampleStatusFormatter"
        [required]="section!==BulkEditSection.EditHeader"
        [loadDefaultValue]="section === BulkEditSection.AddScreen"
        [defaultValue]="sampleStatusDefaultKey"></active-vocab-select>
</ng-template>

<ng-template #preservationTmpl let-sample="item" let-section="section">
    <active-vocab-select [(model)]="sample.C_PreservationMethod_key"
        [vocabChoices]="preservationMethods"
        [keyFormatter]="preservationMethodKeyFormatter"
        [optionFormatter]="preservationMethodFormatter"
        [required]="requiredFields.includes('C_PreservationMethod_key')"
        [nullable]="!requiredFields.includes('C_PreservationMethod_key')"
        [loadDefaultValue]="section === BulkEditSection.AddScreen"
        [defaultValue]="preservationMethodDefaultKey"></active-vocab-select>
</ng-template>

<ng-template #originTmpl let-sample="item" let-section="section">
    <active-vocab-select [(model)]="sample.Material.C_MaterialOrigin_key"
        [vocabChoices]="materialOrigins"
        [keyFormatter]="materialOriginKeyFormatter"
        [optionFormatter]="materialOriginFormatter"
        [required]="requiredFields.includes('Material.C_MaterialOrigin_key')"
        [nullable]="!requiredFields.includes('Material.C_MaterialOrigin_key')"
        [loadDefaultValue]="section === BulkEditSection.AddScreen"
        [defaultValue]="materialOriginDefaultKey"></active-vocab-select>
</ng-template>

<ng-template #externalIDTmpl let-sample="item" let-section="section">
    <multi-paste-input *ngIf="section === BulkEditSection.EditHeader"
        [(model)]="sample.ExternalIdentifiers"
        [limit]="MULTI_PASTE_INPUT_LIMIT"
        [rows]="10"></multi-paste-input>

    <input type="text"  *ngIf="section === BulkEditSection.InputCell && !isDotmatics"
        [(ngModel)]="sample.Material.ExternalIdentifier"
        class="form-control input-medium"
        name="externalIdentifier" 
        [required]="requiredFields.includes('Material.ExternalIdentifier')"/>
    <input type="text"  *ngIf="section === BulkEditSection.InputCell && isDotmatics"
        [(ngModel)]="sample.Material.ExternalIdentifier"
        class="form-control input-medium"
        name="externalIdentifier"
        readonly="readonly"/>
</ng-template>

<ng-template #microchipIdTmpl let-sample="item"  let-section="section">
    <multi-paste-input *ngIf="section === BulkEditSection.EditHeader"
        [(model)]="sample.MicrochipIdentifiers"
        [limit]="MULTI_PASTE_INPUT_LIMIT"
        [rows]="10"></multi-paste-input>

    <input type="text"  *ngIf="section === BulkEditSection.InputCell"
        [(ngModel)]="sample.Material.MicrochipIdentifier"
        class="form-control input-medium"
        name="microchipIdentifier" 
        [required]="requiredFields.includes('Material.MicrochipIdentifier')"/>
</ng-template>

<ng-template #measurementTmpl let-sample="item">
    <input type="number"
           min="0"
           step="any"
           name="volume"
           [(ngModel)]="sample.Volume"
           class="form-control input-medium"
           [required]="requiredFields.includes('Volume')"/>
</ng-template>

<ng-template #unitTmpl let-sample="item" let-section="section">
    <active-vocab-select [(model)]="sample.C_Unit_key"
                         [vocabChoices]="units"
                         [keyFormatter]="unitKeyFormatter"
                         [optionFormatter]="unitFormatter"
                         [required]="requiredFields.includes('C_Unit_key')"
                         [nullable]="!requiredFields.includes('C_Unit_key')"
                         [loadDefaultValue]="section === BulkEditSection.AddScreen"
                         [defaultValue]="unitDefaultKey"></active-vocab-select>
</ng-template>

<ng-template #sampleSubtypeTmpl let-sample="item" let-section="section">
    <active-vocab-select [(model)]="sample.C_SampleSubtype_key"
                         [vocabChoices]="sampleSubtypes"
                         [keyFormatter]="sampleSubtypeKeyFormatter"
                         [optionFormatter]="sampleSubtypeFormatter"
                         [required]="requiredFields.includes('C_SampleSubtype_key')"
                         [nullable]="!requiredFields.includes('C_SampleSubtype_key')"
                         [loadDefaultValue]="section === BulkEditSection.AddScreen"
                         [defaultValue]="sampleSubtypeDefaultKey"></active-vocab-select>
</ng-template>

<ng-template #sampleProcessingMethodTmpl let-sample="item" let-section="section">
    <active-vocab-select [(model)]="sample.C_SampleProcessingMethod_key"
                         [vocabChoices]="sampleProcessingMethods"
                         [keyFormatter]="sampleProcessingMethodKeyFormatter"
                         [optionFormatter]="sampleProcessingMethodFormatter"
                         [required]="requiredFields.includes('C_SampleProcessingMethod_key')"
                         [nullable]="!requiredFields.includes('C_SampleProcessingMethod_key')"
                         [loadDefaultValue]="section === BulkEditSection.AddScreen"
                         [defaultValue]="sampleProcessingMethodDefaultKey"></active-vocab-select>
</ng-template>

<ng-template #sendToTmpl let-sample="item">
    <input type="text"
           [(ngModel)]="sample.SendTo"
           maxlength="150"
           class="form-control input-medium"
           name="sendTo" 
           [required]="requiredFields.includes('SendTo')"/>
</ng-template>

<ng-template #sampleAnalysisMethodTmpl let-sample="item" let-section="section">
    <active-vocab-select [(model)]="sample.C_SampleAnalysisMethod_key"
                         [vocabChoices]="sampleAnalysisMethods"
                         [keyFormatter]="sampleAnalysisMethodKeyFormatter"
                         [optionFormatter]="sampleAnalysisMethodFormatter"
                         [required]="requiredFields.includes('C_SampleAnalysisMethod_key')"
                         [nullable]="!requiredFields.includes('C_SampleAnalysisMethod_key')"
                         [loadDefaultValue]="section === BulkEditSection.AddScreen"
                         [defaultValue]="sampleAnalysisMethodDefaultKey"></active-vocab-select>
</ng-template>

<ng-template #containerTmpl let-sample="item" let-section="section">
    <active-vocab-select [(model)]="sample.Material.C_ContainerType_key"
                         [vocabChoices]="containerTypes"
                         [keyFormatter]="containerTypeKeyFormatter"
                         [optionFormatter]="containerTypeFormatter"
                         [required]="requiredFields.includes('Material.C_ContainerType_key')"
                         [nullable]="!requiredFields.includes('Material.C_ContainerType_key')"
                         [loadDefaultValue]="section === BulkEditSection.AddScreen"
                         [defaultValue]="containerTypeDefaultKey"></active-vocab-select>
</ng-template>

<ng-template #jobTmpl let-sample="item" let-section="section">
    <div style="min-width: 200px;"
        *ngIf="sample.Material && section === BulkEditSection.InputCell">
        <material-job-select 
                             [material]="sample.Material"
                             [required]="requiredFields.includes('Material.JobMaterial[0]')"></material-job-select>
    </div>
    <ng-container *ngIf="section !== BulkEditSection.InputCell">
        <job-select 
                    [excludeLocked]="true"
                    [(model)]="sample.JobKey"
                    [required]="requiredFields.includes('Material.JobMaterial[0]')"></job-select>
    </ng-container>
</ng-template>

<ng-template #locationTmpl let-sample="item" let-section="section">
    <!-- readonly location for input cells -->
    <ng-container *ngIf="section === BulkEditSection.InputCell">
        <div class="form-control-plaintext"
            *ngFor="let current of sample?.Material?.MaterialLocation | currentMaterialLocation">
            <location-path [locationPositionKey]="current.C_LocationPosition_key"></location-path>
        </div>
    </ng-container>
    <!-- editable single location in edit header and add screen -->
    <ng-container *ngIf="section !== BulkEditSection.InputCell">
        <location-chooser 
                          [(location)]="sample.locationPosition"
                          [required]="requiredFields.includes('Location')"></location-chooser>
    </ng-container>
</ng-template>

<ng-template #descriptionTmpl let-sample="item">
    <input type="text"
        [(ngModel)]="sample.Description"
        class="form-control input-medium"
        name="description" 
        [required]="requiredFields.includes('Description')"/>
</ng-template>

<ng-template #lotNumberTmpl let-sample="item">
    <input type="text"
           [(ngModel)]="sample.LotNumber"
           class="form-control input-medium"
           name="lotNumber" 
           [required]="requiredFields.includes('LotNumber')"/>
</ng-template>

<ng-template #specialInstructionsTmpl let-sample="item">
    <textarea 
        name="bulkAddSpecialInstructions"
        class="form-control input-medium"
        maxlength="1000"
        [(ngModel)]="sample.SpecialInstructions"
        [required]="requiredFields.includes('SpecialInstructions')">
    </textarea>
</ng-template>

<ng-template #labelTmpl let-sample="item">
    <sample-label [sample]="sample"></sample-label>
</ng-template>

<ng-template #characteristicTmpl let-sample="item" let-section="section">
    <ng-container *ngIf="section === BulkEditSection.InputCell; else verticalLayout;">
        <table class="table table-sm table-borderless">
            <tbody>
                <tr>
                    <th *ngFor="let characteristic of sample.SampleCharacteristicInstance |
                orderBy:'SampleCharacteristic.SortOrder'">
                        <label class="col-form-label">
                            {{characteristic?.CharacteristicName}}
                        </label>
                    </th>
                </tr>
                <tr>
                    <td *ngFor="let characteristic of sample.SampleCharacteristicInstance |
                orderBy:'SampleCharacteristic.SortOrder'">
                        <characteristic-input #characteristicInput
                                              [characteristic]="characteristic"
                                              [(value)]="characteristic.CharacteristicValue"
                                              [cssClass]="'input-small'"></characteristic-input>
                    </td>
                </tr>
            </tbody>
        </table>
    </ng-container>

    <ng-template #verticalLayout>
        <div class="form-group"
            *ngFor="let characteristic of sample.SampleCharacteristicInstance |
                    orderBy:'SampleCharacteristic.SortOrder'">
            <label class="col-md-12 col-form-label">
                {{characteristic?.SampleCharacteristic?.CharacteristicName}}
            </label>
            <div class="col-md-12">
                <characteristic-input #characteristicInput
                                      [characteristic]="characteristic"
                                      [(value)]="characteristic.CharacteristicValue"
                                      [cssClass]="'input-small'">
                </characteristic-input>
            </div>
        </div>
    </ng-template>
</ng-template>


import { Injectable } from '@angular/core';

import { TranslationService } from '../../services/translation.service';
import { UserNameService } from '../../user/user-name.service';
import { CsvExporter } from '../../common/export/csv-exporter';
import { DateFormatterService } from '@common/util/date-time-formatting';


/**
 * Exports an animal audit report to CSV
 */
@Injectable()
export class ExportAnimalAuditService {
    csvExporter: CsvExporter;

    constructor(
        private translationService: TranslationService,
        private userNameService: UserNameService,
        private dateFormatterService: DateFormatterService
    ) {
        this.csvExporter = new CsvExporter();
    }
    
    exportToCsv(
        auditData: any[],
        auditJobsData: any[],
        auditGenotypesData: any[],
        auditClinicalObservationsData: any[],
        auditCurrentTasksData: any[],
        isGLP: boolean,

    ) {
        const EXPORTER_CSV_FILENAME = "AnimalAudit.csv";

        const data: any[][] = this.buildExportData(
            auditData,
            auditJobsData,
            auditGenotypesData,
            auditClinicalObservationsData,
            auditCurrentTasksData,
            isGLP,
        );

        this.csvExporter.download(data, EXPORTER_CSV_FILENAME);
    }

    buildExportData(
        auditData: any[],
        auditJobsData: any[],
        auditGenotypesData: any[],
        auditClinicalObservationsData: any[],
        auditCurrentTasksData: any[],
        isGLP: boolean,
    ): any[][] {
        return [
            ...this.prepareAuditData(auditData, isGLP),
            ...this.prepareAuditJobsData(auditJobsData),
            ...this.prepareAuditGenotypesData(auditGenotypesData),
            ...this.prepareAuditClinicalObservationsData(auditClinicalObservationsData),
            ...this.prepareAuditCurrentTasksData(auditCurrentTasksData),
        ];
    }
    
    prepareAuditData(auditData: any[], isGLP: boolean) {
        const data: any[][] = [];
        
        data.push(['Details']);
        if (isGLP) {
            data.push([
                'Action',
                'Modified Date',
                'Modified By',
                'ID',
                'Name',
                this.translationService.translate('Line'),
                'Species',
                'Status',
                'Held For',
                'Mating Status',
                'Breeding Status',
                'Classification',
                'Owner',
                'Use',
                'Origin',
                'Sex',
                'Generation',
                'Diet',
                'Shipment',
                'Vendor ID',
                'Order ID',
                'CITES Number',
                'Arrival Date',
                'Birth Date',
                'Death/Exit Date',
                'Exit Reason',
                'Marker Type',
                'Marker',
                'Microchip ID',
                'External ID',
                'Alternate Physical ID'
            ]);
            for (const row of auditData) {
                data.push([
                    row.UpdateType,
                    this.dateFormatterService.formatDateTimeUTC(row.ModifiedDate),
                    this.userNameService.toFullName(row.ModifiedBy),
                    row.Identifier,
                    row.AnimalName,
                    row.LineName,
                    row.Species,
                    row.AnimalStatus,
                    row.HeldFor,
                    row.AnimalMatingStatus,
                    row.BreedingStatus,
                    row.AnimalClassification,
                    row.Owner,
                    row.AnimalUse,
                    row.MaterialOrigin,
                    row.Sex,
                    row.Generation,
                    row.Diet,
                    row.ShipmentID,
                    row.VendorID,
                    row.OrderID,
                    row.CITESNumber,
                    this.dateFormatterService.formatDateOnlyUTC(row.DateOrigin),
                    this.dateFormatterService.formatDateOnlyUTC(row.DateBorn),
                    this.dateFormatterService.formatDateOnlyUTC(row.DateExit),
                    row.ExitReason,
                    row.PhysicalMarkerType,
                    row.PhysicalMarker,
                    row.MicrochipIdentifier,
                    row.ExternalIdentifier,
                    row.AlternatePhysicalID
                ]);
            }
        } else {
            data.push([
                'Action',
                'Modified Date',
                'Modified By',
                'ID',
                'Name',
                this.translationService.translate('Line'),
                'Species',
                'Status',
                'Mating Status',
                'Breeding Status',
                'Owner',
                'Use',
                'Origin',
                'Sex',
                'Generation',
                'Diet',
                'Shipment',
                'Vendor ID',
                'Order ID',
                'Arrival Date',
                'Birth Date',
                'Death/Exit Date',
                'Exit Reason',
                'Marker Type',
                'Marker',
                'Microchip ID',
                'External ID'
            ]);
            for (const row of auditData) {
                data.push([
                    row.UpdateType,
                    this.dateFormatterService.formatDateTimeUTC(row.ModifiedDate),
                    this.userNameService.toFullName(row.ModifiedBy),
                    row.Identifier,
                    row.AnimalName,
                    row.LineName,
                    row.Species,
                    row.AnimalStatus,
                    row.AnimalMatingStatus,
                    row.BreedingStatus,
                    row.Owner,
                    row.AnimalUse,
                    row.MaterialOrigin,
                    row.Sex,
                    row.Generation,
                    row.Diet,
                    row.ShipmentID,
                    row.VendorID,
                    row.OrderID,
                    this.dateFormatterService.formatDateOnlyUTC(row.DateOrigin),
                    this.dateFormatterService.formatDateOnlyUTC(row.DateBorn),
                    this.dateFormatterService.formatDateOnlyUTC(row.DateExit),
                    row.ExitReason,
                    row.PhysicalMarkerType,
                    row.PhysicalMarker,
                    row.MicrochipIdentifier,
                    row.ExternalIdentifier
                ]);
            }
        }
    
        return data;
    }

    prepareAuditJobsData(auditJobsData: any[]) {
        const data: any[][] = [];
        if (auditJobsData.length > 0) {
            data.push([]);
            data.push([this.translationService.translate('Jobs')]);
            data.push([
                'Action',
                'Modified Date',
                'Modified By',
                this.translationService.translate('Job') + ' Name'
            ]);
            for (const row of auditJobsData) {
                data.push([
                    row.JobID,
                    row.UpdateType,
                    this.userNameService.toFullName(row.ModifiedBy),
                    this.dateFormatterService.formatDateTimeUTC(row.ModifiedDate)
                ]);
            }
        }
        return data;
    }

    prepareAuditGenotypesData(auditGenotypesData: any[]) {
        const data: any[] = [];
        if (auditGenotypesData.length > 0) {
            data.push([]);
            data.push(['Genotypes']);
            data.push([
                'Action',
                'Modified Date',
                'Modified By',
                'Genotype'
            ]);
            for (const row of auditGenotypesData) {
                data.push([
                    row.UpdateType,
                    this.dateFormatterService.formatDateTimeUTC(row.ModifiedDate),
                    this.userNameService.toFullName(row.ModifiedBy),
                    row.Genotype
                ]);
            }
        }
        return data;
    }

    prepareAuditClinicalObservationsData(auditClinicalObservationsData: any[]) {
        const data: any[] = [];

        if (auditClinicalObservationsData.length > 0) {
            data.push([]);
            data.push(['Clinical Observations']);
            data.push([
                'Action',
                'Modified Date',
                'Modified By',
                'Observation Status',
                'Date',
                'By',
                'Comments'
            ]);
            for (const row of auditClinicalObservationsData) {
                data.push([
                    row.UpdateType,
                    this.dateFormatterService.formatDateTimeUTC(row.ModifiedDate),
                    this.userNameService.toFullName(row.ModifiedBy),
                    row.ClinicalObservationStatus,
                    this.dateFormatterService.formatDateOnlyUTC(row.DateObserved),
                    row.ObservedBy,
                    row.Comments
                ]);
                data.push(['Clinical Observation Details']);
                data.push([
                    'Action',
                    'Modified Date',
                    'Modified By',
                    'Observation'
                ]);
                for (const detail of row.ClinicalObservationDetails) {
                    data.push([
                        detail.UpdateType,
                        this.dateFormatterService.formatDateTimeUTC(detail.ModifiedDate),
                        this.userNameService.toFullName(detail.ModifiedBy),
                        detail.ClinicalObservation
                    ]);
                }
                data.push([]);
            }
        }
        
        return data;
    }

    prepareAuditCurrentTasksData(auditCurrentTasksData: any[]) {
        const data: any[] = [];

        if (auditCurrentTasksData.length > 0) {
            data.push([]);
            data.push(['Tasks']);
            data.push([
                'Action',
                'Modified Date',
                'Modified By',
                'Task Name',
                'Task',
                'Sequence',
                'Due Date',
                'Deviation',
                'Task Location',
                'Complete Date',
                'Protocol',
                'Task Status',
                'Assigned To',
                'Cost',
                'Duration'
            ]);
            for (const row of auditCurrentTasksData) {
                data.push([
                    row.UpdateType,
                    this.dateFormatterService.formatDateTimeUTC(row.ModifiedDate),
                    this.userNameService.toFullName(row.ModifiedBy),
                    row.TaskAlias,
                    row.TaskName,
                    row.Sequence,
                    this.dateFormatterService.formatDateOnlyUTC(row.DateDue),
                    row.Deviation,
                    row.CurrentLocationPath,
                    this.dateFormatterService.formatDateOnlyUTC(row.DateComplete),
                    row.ProtocolName,
                    row.TaskStatus,
                    row.AssignedTo,
                    row.Cost,
                    row.Duration,
                ]);
            }
        }

        return data;
    }
}

import { Injectable } from '@angular/core';
import { defer, merge, Observable } from 'rxjs';

import { ClinicalService } from './clinical.service';
import { ResourceService } from '../resources/resource.service';
import { VocabularyService } from '../vocabularies/vocabulary.service';
import { take } from 'rxjs/operators';

@Injectable()
export class ClinicalVocabService {

    public get animalStatuses$(): Observable<any[]>{
        return defer(() => this.vocabularyService.getCV('cv_AnimalStatuses')).pipe(take(1));
    }
    public get bodyConditionScores$(): Observable<any[]>{
        return defer(() => this.vocabularyService.getCV('cv_BodyConditionScores')).pipe(take(1));
    }
    public get clinicalObservations$(): Observable<any[]>{
        return defer(() => this.vocabularyService.getCV('cv_ClinicalObservations')).pipe(take(1));
    }
    public get clinicalObservationStatuses$(): Observable<any[]>{
        return defer(() => this.vocabularyService.getCV('cv_ClinicalObservationStatuses')).pipe(take(1));
    }
    public get resources$(): Observable<any[]>{
        return defer(() => this.resourceService.getAllResources()).pipe(take(1));
    }
    public get taskTypes$(): Observable<any[]>{
        return defer(() => this.clinicalService.getHealthRecordTasks()).pipe(take(1));
    }
    public get taskStatuses$(): Observable<any[]>{
        return defer(() => this.vocabularyService.getCV('cv_TaskStatuses')).pipe(take(1));
    }
    public get exitReasons$(): Observable<any[]>{
        return defer(() => this.vocabularyService.getCV('cv_ExitReasons')).pipe(take(1));
    }
    public get bodySystems$(): Observable<any[]>{
        return defer(() => this.vocabularyService.getCV('cv_BodySystems')).pipe(take(1));
    }

    constructor(
        private clinicalService: ClinicalService,
        private resourceService: ResourceService,
        private vocabularyService: VocabularyService
    ) {}


    /**
     * They are used to load data into the Workflow details component.
     * It needs to remove this method and load only the necessary data
     * directly subscribing to the particular observable.
     */
    preloadCVs() {
        merge(
            this.animalStatuses$,
            this.bodyConditionScores$,
            this.clinicalObservations$,
            this.clinicalObservationStatuses$,
            this.resources$,
            this.taskTypes$,
            this.taskStatuses$,
            this.exitReasons$,
            this.bodySystems$,
        ).pipe(take(1)).subscribe();
    }
}

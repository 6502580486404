import type { Animal } from './animal.interface';
import type { CohortMaterial } from './cohort-material.interface';
import type { JobMaterial } from './job-material.interface';
import type { Line } from './line.interface';
import type { MaterialExternalSync } from './material-external-sync.interface';
import type { MaterialLocation } from './material-location.interface';
import type { MaterialPoolMaterial } from './material-pool-material.interface';
import type { MaterialPriorMaterialPool } from './material-prior-material-pool.interface';
import type { MaterialSourceMaterial } from './material-source-material.interface';
import type { MaterialTopGenotype } from './material-top-genotype.interface';
import type { MaterialTopMaterialPool } from './material-top-material-pool.interface';
import type { MessageMap } from './message-map.interface';
import type { Note } from './note.interface';
import type { PlateMaterial } from './plate-material.interface';
import type { Sample } from './sample.interface';
import type { SampleGroupSourceMaterial } from './sample-group-source-material.interface';
import type { StoredFileMap } from './stored-file-map.interface';
import type { StudyMaterial } from './study-material.interface';
import type { TaskMaterial } from './task-material.interface';
import type { TaskOutputSetMaterial } from './task-output-set-material.interface';
import type { cv_ContainerType } from './cv-container-type.interface';
import type { cv_MaterialOrigin } from './cv-material-origin.interface';
import type { cv_MaterialType } from './cv-material-type.interface';
import type { cv_Taxon } from './cv-taxon.interface';

export interface Material {
    Animal: Animal;
    C_ContainerType_key: number | null;
    C_Line_key: number | null;
    C_MaterialOrigin_key: number | null;
    C_MaterialType_key: number;
    C_Material_key: number;
    C_ParentMaterial_key: number | null;
    C_Taxon_key: number | null;
    C_Workgroup_key: number;
    CohortMaterial: CohortMaterial[];
    CreatedBy: string;
    CurrentLocationPath: string;
    DateCreated: Date | null;
    DateModified: Date | null;
    ExternalIdentifier: string;
    Identifier: number;
    JobMaterial: JobMaterial[];
    Line: Line;
    MaterialExternalSync: MaterialExternalSync[];
    MaterialLocation: MaterialLocation[];
    MaterialPoolMaterial: MaterialPoolMaterial[];
    MaterialPriorMaterialPool: MaterialPriorMaterialPool;
    MaterialSourceMaterial: MaterialSourceMaterial[];
    MaterialTopGenotype: MaterialTopGenotype;
    MaterialTopMaterialPool: MaterialTopMaterialPool;
    MessageMap: MessageMap[];
    MicrochipIdentifier: string;
    ModifiedBy: string;
    Note: Note[];
    ParentMaterial: Material;
    PlateMaterial: PlateMaterial[];
    Sample: Sample;
    SampleGroupSourceMaterial: SampleGroupSourceMaterial[];
    SourceMaterialMap: MaterialSourceMaterial[];
    StoredFileMap: StoredFileMap[];
    StudyMaterial: StudyMaterial[];
    TaskMaterial: TaskMaterial[];
    TaskOutputSetMaterial: TaskOutputSetMaterial[];
    Version: number;
    cv_ContainerType: cv_ContainerType;
    cv_MaterialOrigin: cv_MaterialOrigin;
    cv_MaterialType: cv_MaterialType;
    cv_Taxon: cv_Taxon;
}

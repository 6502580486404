import { range } from '@lodash';
import { ChangeDetectorRef, Component } from "@angular/core";
import { IHeaderParams, RowNode } from "ag-grid-community";
import { IHeaderAngularComp } from "ag-grid-angular";

@Component({
    selector: 'header-select-all',
    template: `
        <climb-checkbox>
            <input type="checkbox" 
                   climbCheckbox 
                   [(ngModel)]="isChecked"
                   (click)="toggleCheck()"
                   [indeterminate]="isIndeterminate"
                   [title]="(isChecked || isIndeterminate) | mapFn : getTitle" />
        </climb-checkbox>
    `,
    styleUrls: ['./header-select-all.component.scss'],
})
export class HeaderSelectAllComponent implements IHeaderAngularComp {
    public params: IHeaderParams;

    public isChecked = false;
    public isIndeterminate = false;

    constructor(
        private cdr: ChangeDetectorRef,
    ) { }

    agInit(params: IHeaderParams): void {
        this.params = params;

        this.params.api.addEventListener('selectionChanged', () => this.setCheckboxState());
        this.params.api.addEventListener('paginationChanged', () => this.setCheckboxState());
    }

    refresh(params: IHeaderParams): boolean {
        return false;
    }

    toggleCheck() {
        this.isChecked = this.isIndeterminate ? false : !this.isChecked;
        this.setSelectedForAllVisible(this.isChecked);
    }

    setSelectedForAllVisible(selected: boolean) {
        for (const row of this.getAllVisibleRows()) {
            if (row) {
                row.setSelected(selected);
            }
        }
    }

    getAllVisibleRows(): RowNode[] {
        const rowNodes: RowNode[] = [];

        const api = this.params.api;

        // find which rows are visible
        const currentPage = api.paginationGetCurrentPage() || 0;
        const pageStates = api.getCacheBlockState();
        const currentPageState = pageStates[currentPage];

        const startIndex = currentPageState.startRow;
        const endIndex = currentPageState.endRow - 1;
        for (let i = startIndex; i <= endIndex; i++) {
            const row = api.getDisplayedRowAtIndex(i);
            rowNodes.push(row);
        }

        return rowNodes;
    }

    private setCheckboxState(): void {
        this.isIndeterminate = false;

        const gridApi = this.params.api;

        const currentPage = gridApi.paginationGetCurrentPage();
        const totalRows = gridApi.paginationGetRowCount();
        const pageSize = gridApi.paginationGetPageSize();
        const pageStartRow = currentPage * pageSize + 1;
        const pageEndRow = Math.min(pageStartRow + pageSize - 1, totalRows);
        const pageRowsCount = pageEndRow - pageStartRow + 1;

        const selectedRowsCount = range(pageStartRow - 1, pageEndRow)
            .filter((index) => gridApi.getDisplayedRowAtIndex(index)?.isSelected())
            .length;

        this.isChecked = selectedRowsCount > 0 && selectedRowsCount === pageRowsCount;
        this.isIndeterminate = selectedRowsCount > 0 && !this.isChecked;

        this.cdr.detectChanges();
    }

    getTitle(isSelect: boolean): string {
        return isSelect ? 'Unselect all' : 'Select all';
    }
}

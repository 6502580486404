import { Injectable } from '@angular/core';
import { BaseEntityService } from '../services/base-entity.service';
import { WebApiService } from '../services/web-api.service';
import { DataManagerService } from '../services/data-manager.service';
import { HttpParams } from '@angular/common/http';
import { FeatureFlagService } from '../services/feature-flags.service';


@Injectable()
export class DotmaticsService extends BaseEntityService {

    constructor(
        private webApiService: WebApiService,
        private dataManager: DataManagerService,
        private featureFlagService: FeatureFlagService
    ) {
        super();
    }

    // Fetch Dotmatics TestArticles list
    getDotmaticsTestArticles(jobKey: any, institutionKey: any): Promise<any> {
        let params = new HttpParams();
        params = params.set('jobKey', jobKey);
        params = params.set('institutionKey', institutionKey);
        return this.webApiService.callApi('api/dotmatics/testarticles', params);
    }

    // Fetch Dotmatics Batches for a test article
    getDotmaticsBatches(testArticleKey: any, jobKey: any): Promise<any> {
        let params = new HttpParams();
        params = params.set('testArticleKey', testArticleKey);
        params = params.set('jobKey', jobKey);
        return this.webApiService.callApi('api/dotmatics/batches', params);
    }

    // Fetch properties for a single test article
    getDotmaticsTestArticle(testArticleKey: any): Promise<any> {
        let params = new HttpParams();
        params = params.set('testArticleKey', testArticleKey);
        return this.webApiService.callApi('api/dotmatics/testarticle', params);
    }

    createTestArticle(name: string): any {
        const initialValues = {
            TestArticle: name,
            DateCreated: new Date(),
            IsActive: true
        };
        return this.dataManager.createEntity("cv_TestArticle", initialValues);
    }

    postDotmaticsAnimal(materialKey: any): Promise<any> {
        let params = new HttpParams();
        params = params.set('materialKey', materialKey);
        return this.webApiService.callApi('api/dotmatics/animal', params);
    }

    postDotmaticsSample(materialKey: any): Promise<any> {
        let params = new HttpParams();
        params = params.set('materialKey', materialKey);
        return this.webApiService.callApi('api/dotmatics/postsample', params);
    }

    updateDotmaticsSample(materialKey: any): Promise<any> {
        let params = new HttpParams();
        params = params.set('materialKey', materialKey);
        return this.webApiService.callApi('api/dotmatics/updatesample', params);
    }
    
    syncDotmaticsJobAnimals(job: any): Promise<any> {
        let params = new HttpParams();
        params = params.set('jobKey', job.C_Job_key);
        return this.webApiService.callApi('api/dotmatics/syncjobanimals', params);
    }

    postDotmaticsJobAnimals(job: any): Promise<any> {
        let params = new HttpParams();
        params = params.set('jobKey', job.C_Job_key);
        return this.webApiService.callApi('api/dotmatics/postjobanimals', params);
    }

    syncDotmaticsJobSamples(job: any): Promise<any> {
        let params = new HttpParams();
        params = params.set('jobKey', job.C_Job_key);
        return this.webApiService.callApi('api/dotmatics/syncjobsamples', params);
    }

    /**
     * Call internal api to post job report via Dotmatics studies api
     * @param job
     */
    sendDotmaticsJobReport(job: any): Promise<any> {
        let params = new HttpParams();
        params = params.set('jobKey', job.C_Job_key);
        return this.webApiService.callApi('api/dotmatics/poststudyreport', params);
    }

    createDotmaticsExperiment(job: any): Promise<any> {
        let params = new HttpParams();
        params = params.set('jobKey', job.C_Job_key);
        return this.webApiService.callApi('api/dotmatics/createexperiment', params);
    }

    addExperimentFile(job: any): Promise<any> {
        let params = new HttpParams();
        params = params.set('jobKey', job.C_Job_key);
        return this.webApiService.callApi('api/dotmatics/uploadstudydata', params);
    }

    setIsDotmatics() {
        let isDotmatics = false;
        const flag = this.featureFlagService.getFlag("IsDotmatics");
        if (flag) {
            isDotmatics = (flag.Value.toLowerCase() === 'true') && (flag.IsActive === true);
        }
        return isDotmatics;
    }

    filterOutputSamples(task: any) {
        return task.TaskMaterial.filter((material: any) => {
            // is a sample
            if (!material.Material.Sample) {
                return false;
            }
            // only has one source
            if (material.Material.MaterialSourceMaterial.length !== 1) {
                return false;
            }
            // source material is an animal
            if (!material.Material.MaterialSourceMaterial[0].SourceMaterial.Animal) {
                return false;
            }
            // source material has same task instance key on taskMaterial
            const sameTaskInstance = material.Material.MaterialSourceMaterial[0].SourceMaterial.TaskMaterial.find((item: any) => {
                return item.C_TaskInstance_key === material.C_TaskInstance_key;
            });
            if (!sameTaskInstance) {
                return false;
            }
            // source material has already been synced with dotmatics
            if (!material.Material.MaterialSourceMaterial[0].SourceMaterial.ExternalIdentifier) {
                return false;
            }
            return true;
        });
    }
}

import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnChanges,
    OnInit,
    Output,
    NgZone,
    ChangeDetectorRef,
} from '@angular/core';
import {
    getSafeProp,
    arrayContainsAllObjects
} from '@common/util';

@Component({
    selector: 'workflow-sample-table',
    templateUrl: './workflow-sample-table.component.html'
})
export class WorkflowSampleTableComponent implements OnChanges, OnDestroy, OnInit {
    @Input() task: any;
    @Input() taskMaterials: any[];
    // Samples assigned to the task (not output samples)
    @Input() primarySamples: any[];
    @Input() readonly: boolean;
    @Output() addSampleMaterials: EventEmitter<any[]> = new EventEmitter<any[]>();

    sampleMaterials: any[] = [];
    filterMaterialInterval: any;

    constructor(
        private ngZone: NgZone,
        private ref: ChangeDetectorRef,
    ) {
        // do nothing
    }

    ngOnInit() {
        this.initialize();

        this.ngZone.runOutsideAngular(() => {
            this.filterMaterialInterval = setInterval(() => {
                this.filterSamples();
            }, 500);
        });
    }

    ngOnDestroy() {
        clearInterval(this.filterMaterialInterval);
    }

    ngOnChanges(changes: any) {
        if (changes.task) {
            if (!changes.task.firstChange) {
                this.initialize();
            }
        } 
    }

    initialize() {
        this.filterSamples();
    }

    filterSamples() {
        if (!this.taskMaterials) {
            this.sampleMaterials = [];
            this.ref.detectChanges();
            return;
        }

        const sampleMaterials = this.taskMaterials.filter((material: any) => {
            return material && getSafeProp(material, 'Material.Sample');
        });

        if (!arrayContainsAllObjects(sampleMaterials, this.sampleMaterials)) {
            this.sampleMaterials = sampleMaterials;
            this.ref.detectChanges();
        }
    }

    selectAllSamples(event: Event) {
        const checkbox = event.target as HTMLInputElement;
        for (const sampleMaterial of this.sampleMaterials) {
            if (this.primarySamples.indexOf(sampleMaterial.Material.Sample) > -1) {
                sampleMaterial.isSelected = checkbox.checked;
            }
        }
    }

    addClicked() {
        const sampleMaterials = this.sampleMaterials.filter((sampleMaterial: any) => {
            return sampleMaterial.isSelected;
        });
        this.addSampleMaterials.emit(sampleMaterials);
    }

  }

import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Animal, Cohort, CohortMaterial, Entity } from "@common/types";
import { Column, Selectable } from "@common/entitytable/entity-table.service";
import { IFacet } from "@common/facet";
import { CopyBufferService } from "@common/services";
import { ICohortMaterialChangeEvent } from "@common/entitytable/entity-table.component";

@Component({
    selector: 'cohort-split-table',
    templateUrl: './cohort-split-table.component.html',
    providers: []
})
export class CohortSplitTableComponent {
    @Input() facet: IFacet;
    @Input() cohort: Entity<Cohort>;
    @Input() columns: Column[];

    @Output() cohortChange = new EventEmitter();
    @Output() columnSelectChange = new EventEmitter();

    constructor(
        private copyBufferService: CopyBufferService
    ) { }

    onCopy() {
        const animalsToCopy: Animal[] = [];
        this.cohort.CohortMaterial.forEach((cm: CohortMaterial & Selectable) => {
            if (cm.isSelected && cm.Material.Animal) {
                animalsToCopy.push(cm.Material.Animal);
            }
        });
        this.copyBufferService.copy(animalsToCopy);
    }

    onRemove(event: ICohortMaterialChangeEvent): void {
        this.cohortChange.emit(event);
    }

    
    onColumnSelectChanged() {
        this.columnSelectChange.emit();
    }
}

<fieldset class="group-controls">
    <legend>Animal Groups</legend>

    <p *ngIf="!(model | notEmpty)">
        Use the controls below to define groups of animals.
    </p>

    <table *ngIf="model | notEmpty"
           class="table table-sm table-borderless">
        <thead>
            <tr class="border-bottom">
                <th></th>
                <th>Type</th>
                <th>Filter</th>
                <th>Chart Label</th>
            </tr>
        </thead>
        <tbody>
            <ng-container *ngFor="let filterGroup of model; let index = index;">
                <tr *ngFor="let filter of filterGroup.filters; let first = first;"
                    [ngClass]="first ? 'group-start' : ''">
                    <td class="icon-cell" *ngIf="first"
                        [attr.rowspan]="filterGroup.filters.length">
                        <div class="form-control-plaintext">
                            <a (click)="removeFilterGroup(index)">
                                <i class="fa fa-remove remove-item" title="Remove group"></i>
                            </a>
                        </div>
                    </td>
                    <td>
                        <div class="form-control-plaintext">
                            {{filter.label | translate}}
                        </div>
                    </td>
                    <td class="constrained-width-cell">
                        <div class="form-control-plaintext">
                            {{filter.displayValue}}
                        </div>
                    </td>
                    <td *ngIf="first && !containsCohortIndividualAnimalsFilter(filterGroup)"
                        [attr.rowspan]="filterGroup.filters.length">
                        <input class="form-control" type="text"
                               name="chart-label-{{index}}"
                               style="min-width:50px;"
                               required
                               (ngModelChange)="refreshModel()"
                               [(ngModel)]="filterGroup.label" />
                    </td>
                    <td *ngIf="first && containsCohortIndividualAnimalsFilter(filterGroup)"
                        [attr.rowspan]="filterGroup.filters.length">
                        <input class="form-control" type="text"
                               name="chart-label-{{index}}"
                               style="min-width:50px;"
                               (ngModelChange)="refreshModel()"
                               [(ngModel)]="filterGroup.label"
                               disabled />
                    </td>

                </tr>
            </ng-container>
        </tbody>
    </table>

    <fieldset class="fieldset-bordered add-group-controls">
        <legend>Add a Group</legend>

        <p>
            Add filters to define a group of animals.
        </p>

        <table class="table table-sm table-borderless">
            <thead>
                <tr class="border-bottom">
                    <th class="icon-cell">
                        <a class="add-item-link"
                           (click)="addFilterClicked()">
                            <i class="fa fa-plus-circle" title="Add a filter"></i>
                        </a>
                    </th>
                    <th>
                        Type
                    </th>
                    <th>
                        Filter
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let newFilter of newFilters; let fIndex = index;">

                    <td class="icon-cell">
                        <div class="form-control-plaintext">
                            <a (click)="removeNewFilterClicked(newFilter)">
                                <i class="fa fa-remove remove-item" title="Remove filter"></i>
                            </a>
                        </div>
                    </td>

                    <td>
                        <label for="filterType-{{fIndex}}-{{domIdAddition}}"
                               class="sr-only">Filter Type</label>
                        <select id="filterType-{{fIndex}}-{{domIdAddition}}"
                                name="filterType-{{fIndex}}"
                                [(ngModel)]="newFilter.type"
                                (ngModelChange)="selectFilterType(newFilter, $event)"
                                class="form-control input-medium">
                            <option *ngFor="let item of chartFilterTypes"
                                    [ngValue]="item">
                                {{item.label | translate}}
                            </option>
                        </select>
                    </td>

                    <td class="col-size-fill">
                        <chart-filter-input [newFilter]="newFilter"></chart-filter-input>
                    </td>
                </tr>
            </tbody>
        </table>

        <p *ngIf="displayAnimalBirthDatesMessage === true">
            <b>Note:</b> Animal birth dates are required to chart task outputs.
        </p>

        <div>
            <button type="button"
                    class="btn btn-secondary"
                    (click)="addGroupClicked()"
                    [disabled]="newFilters.length === 0">
                Add Group
            </button>
        </div>
    </fieldset><!-- /.add-group-controls -->

</fieldset><!-- /.group-controls -->

import type { Cohort } from './cohort.interface';
import type { Job } from './job.interface';
import type { Placeholder } from './placeholder.interface';

export interface JobCohort {
    C_Cohort_key: number;
    C_JobCohort_key: number;
    C_Job_key: number;
    C_Workgroup_key: number;
    Cohort: Cohort;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    Job: Job;
    ModifiedBy: string;
    Placeholder: Placeholder[];
    Sequence: number;
    Version: number;
}

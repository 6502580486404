import type { ContactPerson } from './contact-person.interface';
import type { Institution } from './institution.interface';
import type { Job } from './job.interface';
import type { JobInstitutionBillingContact } from './job-institution-billing-contact.interface';
import type { Site } from './site.interface';

export interface JobInstitution {
    AuthorizationContactPerson: ContactPerson;
    BillingContactPerson: ContactPerson;
    C_AuthorizationContactPerson_key: number | null;
    C_BillingContactPerson_key: number | null;
    C_Institution_key: number;
    C_JobInstitution_key: number;
    C_Job_key: number;
    C_ScientificContactPerson_key: number | null;
    C_Site_key: number | null;
    C_Workgroup_key: number;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    Institution: Institution;
    Job: Job;
    JobInstitutionBillingContact: JobInstitutionBillingContact[];
    ModifiedBy: string;
    ScientificContactPerson: ContactPerson;
    Site: Site;
    Version: number;
}

import type { Animal } from './animal.interface';

export interface cv_ExitReason {
    Animal: Animal[];
    C_ExitReason_key: number;
    C_VocabularySource_key: number | null;
    C_Workgroup_key: number;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    ElementID: string;
    ExitReason: string;
    IsActive: boolean;
    IsDefault: boolean;
    IsDeprecated: boolean;
    ModifiedBy: string;
    SortOrder: number | null;
    Version: number;
}

import { ClimbColorpickerComponent } from './colorpicker/climb-colorpicker.component';
import { DxColorBoxModule } from 'devextreme-angular/ui/color-box';
import { DxSchedulerModule } from 'devextreme-angular/ui/scheduler';
import { DxDateBoxModule } from 'devextreme-angular/ui/date-box';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';


@NgModule({
    imports: [
        CommonModule,
        DxSchedulerModule,
        DxColorBoxModule,
        DxDateBoxModule,
    ],
    declarations: [
        ClimbColorpickerComponent
    ],
    exports: [
        DxSchedulerModule,
        DxDateBoxModule,
        ClimbColorpickerComponent
    ]
})
export class DevExtremeWrapperModule { }

import { Subscription } from 'rxjs';
import { Directive, OnDestroy } from '@angular/core';
import { Base, BaseConstructor, Constructor } from './mixin-boilerplate';

export interface CanUnsubscribe {
  subscribe: (...subscription: Subscription[]) => void;
  subscription: Subscription;
}

export type CanUnsubscribeMixin = Constructor<CanUnsubscribe>;

export const MixinUnsubscribeClass = mixinUnsubscribe(Base);

/**
 * Mixin to augment a component for unsubscribing
 * from all observables when onDestroy firing.
 */
export function mixinUnsubscribe<T extends BaseConstructor = BaseConstructor>(SuperClass: T): CanUnsubscribeMixin & T {
    @Directive()
    class Unsubscribe extends SuperClass implements OnDestroy {
        private subscriptions = new Subscription();

        /**
         * Adds a subscription to subscription list through setter.
         */
        set subscription(subscription: Subscription) {
            this.subscriptions.add(subscription);
        }

        /**
         * Adds multiple subscriptions to subscription list.
         */
        subscribe(...subscriptions: Subscription[]): void {
            subscriptions.forEach((subscription) => {
                this.subscriptions.add(subscription);
            });
        }

        /**
         * Destroys all RxJs subscriptions.
         */
        ngOnDestroy(): void {
            super.ngOnDestroy();
            this.subscriptions.unsubscribe();
        }
    }

    return Unsubscribe;
}

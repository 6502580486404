import { debounce } from '@lodash';
import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    NgZone,
    ViewChild,
    HostBinding,
    ChangeDetectorRef,
} from '@angular/core';
import { Subject, Subscription, defer, Observable } from 'rxjs';
import { distinctUntilChanged, map, tap, throttleTime } from 'rxjs/operators';

import {
    DragStoppedEvent,
    GridOptions,
} from 'ag-grid-community';
import { AgGridAngular } from 'ag-grid-angular';
import { IGetRowsParams } from 'ag-grid-community/dist/lib/interfaces/iDatasource';
import { ColumnState } from 'ag-grid-community/dist/lib/columns/columnModel';
import { ColDef } from 'ag-grid-community/dist/lib/entities/colDef';

import { EntityChangeService } from '../../entity-changes/entity-change.service';
import { DataTableCommService } from './services/data-table-comm.service';
import {
    DataService,
    DataResponse,
    FormatterOptions,
    TableOptions,
    TableColumnDef,
    TableState,
    SortItem,
    RestoreColumn,
    RestoreState,
    ColumnsState,
    Formatter,
    ClickCallback,
    SortDirection,
} from './data-table.interface';

import { CellFormatterService } from './services/cell-formatter.service';
import { PrivilegeService } from '../../services/privilege.service';

import { HeaderSelectAllComponent } from './components/header-select-all.component';
import { HeaderAddItemComponent } from './components/header-add-item.component';
import { CellDeleteLinkComponent } from './components/cell-delete-link.component';
import { CellDetailLinkComponent } from './components/cell-detail-link.component';
import { AgGridCommService } from './services/ag-grid-comm.service';

import { applyGroupByMarkers, getUniqueId, formatCell } from './utils';
import {
    notEmpty,
} from '../util';
import { ColumnSelectLabel } from '../facet';
import { cacheWithExpiration } from '../observable';


/**
 * This css class can be used on cells to exclude them
 * as drag handles on selected rows
 */
export const NO_DRAG_CLASS = 'no-ui-drag';
/**
 * This css class identifies a row in end/inactive state.
 * Also excludes row from drag handles if dragOnlyActiveRows is enabled
 */
export const END_STATE_CLASS = 'end-state';
export const ICON_CELL_CLASS = 'icon-cell';
export const URGENT_STATE_CLASS = 'text-danger';

/**
 * special column constants for configuring data-table
 */
export const SELECT_ALL_COLUMN: TableColumnDef = {
    displayName: 'Checkbox',
    field: 'Checkbox',
};

type SortModel = {
    uniqueColumnId: string;
    sortField: string;
    sortDirection: SortDirection;
};

export class ColumnsSelectOption {
    constructor( {colId, field, displayName, visible}: {
                        colId?: string, 
                        field: string, 
                        displayName: string, 
                        visible?: boolean}){
        this.colId = colId;
        this.field = field;
        this.displayName = displayName;
        this.visible = !!visible;
    }
    get uniqueId() { return getUniqueId(this); }
    colId?: string;
    field: string;
    displayName: string;
    visible: boolean;
}

interface ColDefExtended extends ColDef {
    formatter?: Formatter;
    outputClick?: ClickCallback;
    isGrouped?: boolean;
    position?: number;
}

@Component({
    selector: 'climb-data-table',
    templateUrl: './climb-data-table.component.html',
    styleUrls: ['./climb-data-table.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        AgGridCommService
    ]
})
export class ClimbDataTableComponent implements OnChanges, OnDestroy, OnInit {
    @Input() dataService: DataService;
    @Input() options: TableOptions;
    @Input() gridStateJson: string;
    @Input() selectedRows: any[];
    @Input() draggedRows: any[];
    @Input() allowMovableColumns = true;

    @Output() gridStateJsonChange: EventEmitter<any> = new EventEmitter<any>();
    @Output() selectedColumnsChange: EventEmitter<ColumnsState> = new EventEmitter<ColumnsState>();
    @Output() selectedRowsChange: EventEmitter<any[]> = new EventEmitter<any[]>();
    @Output() draggedRowsChange: EventEmitter<any[]> = new EventEmitter<any[]>();
    @Output() stateChange: EventEmitter<TableState & { totalCount: number }> = new EventEmitter();
    @Output() dragStart: EventEmitter<void> = new EventEmitter<void>();
    @Output() dragStop: EventEmitter<void> = new EventEmitter<void>();
    @Output() addItemClick: EventEmitter<void> = new EventEmitter<void>();
    @Output() deleteItemClick: EventEmitter<any> = new EventEmitter<any>();
    @Output() detailLinkClick: EventEmitter<any> = new EventEmitter<any>();
    @Output() columnStateChanged: EventEmitter<ColumnsSelectOption[]> = new EventEmitter();

    rowClass: any;
    @ViewChild('gridWrap') gridWrap: ElementRef;

    // state variables
    data: any[];
    totalCount: number;
    pageNumber: number;
    pageSize: number;
    rowOffset: number;
    sort: string;
    sorts: SortItem[];
    gridOptions: GridOptions;
    rowHeight: number;


    columnsSelectOptions: ColumnsSelectOption[] = [];
    columnSelect: {
        /// Selected columns
        model: string[],
        // Column labels
        labels: ColumnSelectLabel[],
    } = {model: [], labels: [] };

    breezeChangeEvent: any;
    gridRefreshInProgress: any;
    attachDragHandleTimeout: any;
    attachDragHandleInterval: any;

    private _subscriptions: Subscription[] = [];

    @ViewChild(AgGridAngular) private agGrid: AgGridAngular;

    readonly SORTABLE_COLUMN_TOOLTIP =
    'To sort by multiple columns, hold down Shift while clicking the column headers.';

    readonly COMPONENT_LOG_TAG = 'climb-data-table';

    @HostBinding('class.loading')
    private isLoading = false;

    gridWrapResize: Subject<ResizeObserverEntry> = new Subject();
    private gridWrapResize$: Observable<number> = this.gridWrapResize.asObservable().pipe(
        map((entry: ResizeObserverEntry) => entry.contentRect.width),
        distinctUntilChanged(),
        throttleTime(50, undefined, { leading: true, trailing: true }),
        tap(() => this.sizeToFit()),
    );

    constructor(
        private ngZone: NgZone,
        private agGridCommService: AgGridCommService,
        private cellFormatterService: CellFormatterService,
        private entityChangeService: EntityChangeService,
        private dataTableCommService: DataTableCommService,
        private privilegeService: PrivilegeService,
        private cdr: ChangeDetectorRef,
    ) {
        // https://www.ag-grid.com/javascript-grid-properties/?framework=angular#gsc.tab=0
        this.gridOptions = {
            cacheBlockSize: 50,
            maxBlocksInCache: 0,
            suppressDragLeaveHidesColumns: true,
            defaultColDef: {
                sortable: true,
                filter: false,
                resizable: true,
                unSortIcon: true,
            },
            onGridReady: () => {
                this.sizeToFit();
            },
            onModelUpdated: () => {
                this.sizeToFit();
            },
            rowSelection: 'multiple',
            suppressCellFocus: true,
            suppressClickEdit: true,
            suppressPropertyNamesCheck: true,
            suppressRowHoverHighlight: true,
            suppressRowClickSelection: true,
            // suppressRowHoverClass: true,
            localeText: {
                to: '&#8211;'
            }
        };

        // We don't want to refresh the grid
        //   100x times if many entities just got loaded.
        this._refreshGrid = debounce(this._refreshGrid, 2000);
    }

    ngOnInit() {
        this.pageNumber = 1;
        this.pageSize = 50;
        this.rowOffset = 0;
        this.selectedRows = [];
        this.rowHeight = 32;

        this.gridOptions.cacheBlockSize = this.pageSize;
        this.gridOptions.suppressMovableColumns = !this.allowMovableColumns;

        this.setUpBreezeChangeDetection();

        this.restoreState();
        this.columnStateChanged.emit(this.columnsSelectOptions);

        this.setDefaultOptions();

        this.wireUpCommService();

        this.updateColumnSelect();

        if (this.options.enableDraggable) {
            this.ngZone.runOutsideAngular(() => {
                this.attachDragHandleInterval = setInterval(() => {
                    this.reattachDragHandlesIfNotExist();
                }, 1000);
            });
        }

        this._subscriptions.push(this.gridWrapResize$.subscribe());
    }

    ngOnDestroy(): void {
        if (this.attachDragHandleInterval) {
            clearInterval(this.attachDragHandleInterval);
        }
        this.removeBreezeChangeDetection();

        for (const subscription of this._subscriptions) {
            subscription.unsubscribe();
        }
    }

    ngOnChanges(changes: any) {
        if (changes.options) {
            this.restoreState();
            this.setDefaultOptions();
            this.updateColumnSelect();
            this.columnStateChanged.emit(this.columnsSelectOptions);
        }
    }

    setUpBreezeChangeDetection() {
        // refresh all grid cells on any breeze entity change
        this.breezeChangeEvent = this.entityChangeService
            .onAnyChange((changes: any) => {
                this.refreshDataCellsInGrid();
            });
    }

    removeBreezeChangeDetection() {
        if (this.breezeChangeEvent) {
            this.breezeChangeEvent.unsubscribe();
        }
    }

    setDefaultOptions() {
        if (this.options) {
            if (this.options.rowClass) {
                this.gridOptions.getRowClass = this.options.rowClass;
            }

            if (this.options.enableAddButton !== false &&
                this.options.enableAddButton !== true
            ) {
                // If not set, default to privilege for current facet
                this.options.enableAddButton = this.privilegeService.readwrite;
            }

            if (this.options.enableDeleteColumn !== true) {
                this.options.enableDeleteColumn = false;
            }

            if (this.options.enableDetailColumn !== false) {
                this.options.enableDetailColumn = true;
            }

            if (this.options.enableSelectable !== false) {
                this.options.enableSelectable = true;
            }

            if (this.options.enableDraggable !== false) {
                this.options.enableDraggable = true;
            }



            for (const column of this.options.columns) {

                // default sortable to true
                if (column.sortable !== false) {
                    column.sortable = true;
                }

                // default visible to true
                if (column.visible !== false) {
                    column.visible = true;
                }

                // default ignore to false
                if (column.ignore !== true) {
                    column.ignore = false;
                }

                // default forcedToExport to false
                if (column.forcedToExport !== true) {
                    column.forcedToExport = false;
                }

                // default exportable to true
                if (column.exportable !== false && column.ignore === false) {
                    column.exportable = true;
                }
            }

            // ignored but forced to be exported
            if (this.options.columnsForcedToExport !== undefined) {
                this.options.columnsForcedToExport = this.options.columnsForcedToExport.filter((item) => {
                    return item.forcedToExport;
                });
            }

            let selectableColumnIndex = 0;

            this.gridOptions.columnDefs = this.options.columns
                .map((item: TableColumnDef, index: number) => {
                    if (item === SELECT_ALL_COLUMN) {
                        item.visible = false;
                        selectableColumnIndex = index;
                    }

                    const columnDef: ColDefExtended = {
                        headerName: item.displayName,
                        colId: item.colId,
                        field: item.field,
                        cellRenderer: this.cellRenderer,
                        formatter: item.formatter,
                        isGrouped: item.isGrouped,
                        hide: !item.visible,
                        sortable: item.sortable,
                        sort: item.sortDir,
                        sortIndex: item.sortedAt,
                        headerClass: item.headerClass,
                        cellClass: item.cellClass,
                        maxWidth: item.maxWidth,
                        minWidth: item.minWidth,
                        width: item.width,

                        position: item.position || (index + 1),
                    };

                    if (item.rendererComponent) {
                        columnDef.cellRenderer = undefined;
                        columnDef.cellRendererFramework = item.rendererComponent;
                        columnDef.outputClick = item.outputClick;
                    }

                    if (item.comparator) {
                        columnDef.comparator = item.comparator;
                    }

                    // Add tooltip for sortable columns
                    if (item.sortable) {
                        columnDef.headerTooltip = this.SORTABLE_COLUMN_TOOLTIP;
                    }

                    return columnDef;
                });

            if (this.options.groupBy) {
                this.gridOptions.suppressRowTransform = true;
            }

            if (this.options.enableDeleteColumn) {
                this.addDeleteColumn();
            }

            if (this.options.enableDetailColumn) {
                this.addDetailsColumn();
            }

            if (this.options.enableSelectable) {
                this.addSelectableColumn(selectableColumnIndex);
            }

            if (!this.gridOptions.datasource) {
                this.gridOptions.datasource = {
                    getRows: (params: IGetRowsParams): void => {
                        this.runGridDataSource(params);
                    }
                };
            }

        }
    }

    addDeleteColumn() {
        if (this.privilegeService.readwrite) {
            const deleteColumn: ColDef = {
                headerName: "",
                headerClass: "ag-header-cell-center",
                cellClass: [NO_DRAG_CLASS, ICON_CELL_CLASS],
                field: "_deleteItem",
                lockPosition: true,
                resizable: false,
                suppressSizeToFit: true,
                width: 28,
                minWidth: 28,
                sortable: false,
                cellRenderer:
                <new() => CellDeleteLinkComponent> CellDeleteLinkComponent
            };

            this.gridOptions.columnDefs.unshift(deleteColumn);
        }
    }

    addDetailsColumn() {
        const columnWidth = this.options.enableSelectable ? 35 : 42;
        // add details column before other data
        const detailsColumn: ColDef = {
            headerName: "",
            headerClass: "ag-header-cell-center",
            cellClass: [NO_DRAG_CLASS, ICON_CELL_CLASS],
            field: "_detailsLink",
            lockPosition: true,
            resizable: false,
            suppressSizeToFit: true,
            width: columnWidth,
            minWidth: columnWidth,
            sortable: false,
            cellRenderer:
            <new() => CellDetailLinkComponent> CellDetailLinkComponent
        };

        if (this.options.enableAddButton) {
            detailsColumn.headerComponent =
                <new() => HeaderAddItemComponent> HeaderAddItemComponent;
        }

        this.gridOptions.columnDefs.unshift(detailsColumn);
    }

    addSelectableColumn(atIndex: number) {
        // add selectable column at specified index
        this.gridOptions.columnDefs.splice(atIndex, 0, {
            headerName: "",
            headerClass: "ag-header-cell-center",
            cellClass: NO_DRAG_CLASS,
            field: "_rowSelector",
            lockPosition: true,
            resizable: false,
            suppressSizeToFit: true,
            width: 36,
            sortable: false,
            checkboxSelection: true,
            headerComponent:
            <new() => HeaderSelectAllComponent> HeaderSelectAllComponent
        });
    }

    wireUpCommService() {
        const s1 = this.dataTableCommService.reloadTable$
            .subscribe(() => {
                this.reloadDataSource();
            });
        this._subscriptions.push(s1);

        // wire up hooks for child cell renderers to call
        const s2 = this.agGridCommService.addItemClicked$.subscribe(() => {
            this.addItemClick.emit();
        });
        this._subscriptions.push(s2);

        const s3 = this.agGridCommService.deleteItemClicked$.subscribe((item) => {
            this.deleteItemClick.emit(item);
        });
        this._subscriptions.push(s3);

        const s4 = this.agGridCommService.detailLinkClicked$.subscribe((item) => {
            this.detailLinkClick.emit(item);
        });
        this._subscriptions.push(s4);

        // hook to load specific pageNumber of table
        const s5 = this.dataTableCommService.loadSpecificPage$
            .subscribe((pageNumber) => {
                this.loadSpecificPage(pageNumber);
            });
        this._subscriptions.push(s5);

        const s6 = this.dataTableCommService.redrawTableCells$
            .subscribe(() => {
                this.refreshDataCellsInGrid();
            });
        this._subscriptions.push(s6);

        // hook to make count column visible in workflow grid
        const s7 = this.dataTableCommService.showColumn$
            .subscribe((field) => {
                const visibleOptionIds = this.columnsSelectOptions
                    .filter(x => x.visible)
                    .map(x => x.uniqueId);

                if (visibleOptionIds.indexOf(field) < 0) {
                    visibleOptionIds.push(field);
                }

                // manually updating the column selection model
                this.columnSelectChanged(visibleOptionIds);
                this.updateColumnSelect();
            });
        this._subscriptions.push(s7);
    }

    selectionChanged() {
        this.selectedRows = this.getSelectedRows();
        this.selectedRowsChange.emit(this.selectedRows);
        this.reattachDragHandles();
    }

    getSelectedRows(): any[] {
        return this.agGrid?.api.getSelectedRows() ?? [];
    }

    getDraggedRows(): any[] {
        let draggedRows = this.getSelectedRows();

        if (this.options.dragOnlyActiveRows) {
            draggedRows = this._filterActiveRows(draggedRows);
        }
        return draggedRows;
    }

    private _filterActiveRows(rows: any[]): any[] {
        return rows.filter((row) => {
            return row && row.IsActive;
        });
    }

    reattachDragHandlesIfNotExist() {
        // if we have drag enabled, and selected rows to drag
        if (!this.options.enableDraggable || this.getSelectedRows().length === 0) {
            return;
        }

        // sometimes we lose the draggable handles. Recreate if they don't exist
        const jqEl: any = jQuery(this.gridWrap.nativeElement);
        const draggables = jqEl.find('.ui-draggable');
        if (!draggables || draggables.length === 0) {
            this.reattachDragHandles();
        }
    }

    reattachDragHandles() {
        if (!this.options.enableDraggable) {
            return;
        }

        // eslint-disable-next-line
        const self = this;
        // debounce the timeout, because selection change fires for every new selection
        //  when select-all is toggled, which issues many calls
        if (this.attachDragHandleTimeout) {
            clearTimeout(this.attachDragHandleTimeout);
        }

        this.attachDragHandleTimeout = setTimeout(() => {

            // reset draggedRows
            self.draggedRowsChange.emit([]);

            // re-attach drag handles
            const jqEl: any = jQuery(self.gridWrap.nativeElement);

            let activeRowSelector = '';
            if (this.options.dragOnlyActiveRows) {
                activeRowSelector = ':not(.' + END_STATE_CLASS + ')';
            }

            const currentDraggableSelector = '.ui-draggable';
            const newDragSelector = '.ag-row.ag-row-selected' +
                activeRowSelector +
                ' .ag-cell:not(.' + NO_DRAG_CLASS + ')';

            // remove all previous drag handles
            jqEl.find(currentDraggableSelector).draggable('destroy');

            jqEl.find(newDragSelector).draggable({
                animate: true,
                appendTo: ".workspace-container",
                containment: ".workspace-container",
                cursor: "move",
                cursorAt: { top: 40, left: 50 },
                helper: () => {
                    const count = self.getDraggedRows().length;
                    const text = count + " items";
                    return $(`<div class='ui-draggable-helper'>
                                <b>${text}</b><br/>
                                <i>Drag to another facet</i>
                            </div>`);
                },
                revert: "invalid",
                start(event: any, ui: any) {
                    self.draggedRowsChange.emit(self.getDraggedRows());
                    self.dragStart.emit();
                },
                stop(event: any) {
                    // reset draggedRows
                    self.draggedRowsChange.emit([]);
                    self.dragStop.emit();
                },
                zIndex: 9999
            });
        }, 200);
    }

    /**
     * Update the Column Select state
     */
    updateColumnSelect() {
        this.columnsSelectOptions = this.options.columns
            .filter(column => column !== SELECT_ALL_COLUMN)
            .map(column => new ColumnsSelectOption(column));
        
        this.updateColumnSelectModelsAndLabels();
    }

    updateColumnSelectModelsAndLabels() {
        this.columnSelect.model = this.columnsSelectOptions
            .filter(x => x.visible)
            .map(x => x.uniqueId);
        this.columnSelect.labels = this.columnsSelectOptions
            .map(x => new ColumnSelectLabel(x.uniqueId, x.displayName));
    }

    /**
     * Column selections have changed
     */
    columnSelectChanged(visibleOptionIds: string[]) {
        this.options.columns.forEach((column) => {
            column.visible = visibleOptionIds.some((id: string) => id === getUniqueId(column));
        });
        this.updateColumnSelect();

        const visibleOptions = this.columnsSelectOptions
            .filter(x => x.visible);
        const hiddenOptions = this.columnsSelectOptions
            .filter(x => !x.visible);

        const visibleFields = visibleOptions
            .map(x => x.field);
        const hiddenFields = hiddenOptions
            .map(x => x.field);

        const visibleIds = visibleOptions
            .map(x => x.uniqueId);
        const hiddenIds = hiddenOptions
            .map(x => x.uniqueId);

        // Update the JSON GridState
        this.saveState();

        this.selectedColumnsChange.emit({
            visible: visibleFields,
            hidden: hiddenFields,
        });

        // Update the grid columns
        this.gridOptions.columnApi.setColumnsVisible(visibleIds, true);
        this.gridOptions.columnApi.setColumnsVisible(hiddenIds, false);
        this.sizeToFit();

        if (this.options.refreshOnColumnChange) {
            // Reload the table data
            this.dataTableCommService.reloadTable();
        }
    }

    sortChanged() {
        // apply to our column defs
        const sorts = this.agGrid.columnApi.getColumnState();
        const sortItems = sorts
            .filter(({ sort }) => Boolean(sort))
            .map((sort) => ({
                field: sort.colId,
                dir: sort.sort
            }));
        this._setOptionsSorts(sortItems);

        this.saveState();
    }

    runGridDataSource(params: IGetRowsParams) {
        // configure pagination state
        this.rowOffset = params.startRow;
        this.pageNumber = (Math.ceil(params.startRow / this.pageSize) + 1) || 1;

        // configure sort state
        const sortModel = this._getSortModel(params);
        this.sorts = this._sortModelToSortItems(sortModel);
        this.sort = this._sortModelToString(sortModel);

        this.saveState();

        this.reloadData().then((response: DataResponse) => {
            this.totalCount = response.inlineCount;
            // reset row selection
            this.selectionChanged();

            params.successCallback(
                response.results,
                response.inlineCount
            );

            this.stateChange.emit({
                ...this._getTableState(),
                totalCount: this.totalCount,
            });
        }).then(() => {
            this.dataTableCommService.onDataLoadCompleted();
        });
    }

    /**
     * Return sortModel
     * Convert any fields to their pre-defined sortField values
     * (optionally use grid API params object to search for sortModel)
     * @param params
     */
    private _getSortModel(params?: IGetRowsParams): SortModel[] {
        const getSortField = (colId: string): string => {
            const column: TableColumnDef = this.options.columns.find((col: TableColumnDef) => getUniqueId(col) === colId);
            return column.sortField || column.field;
        };

        // map any sortFields
        const generateSortModel = (data: Partial<ColumnState>[]) => data
            .filter(({ sort }) => Boolean(sort))
            .map<SortModel>((model) => ({
                uniqueColumnId: model.colId,
                sortField: getSortField(model.colId),
                sortDirection: model.sort
            }));

        // default sort model may not be initialized correctly
        // see: https://github.com/ceolter/ag-grid/issues/1746
        // probably the issue is no longer relevant and the method can be refactored.
        const sortModel: ColumnState[] = params.sortModel;
        if (notEmpty(sortModel)) {
            return generateSortModel(sortModel);
        }

        const columnState = this.agGrid.columnApi.getColumnState();
        if (notEmpty(columnState)) {
            return generateSortModel(columnState);
        }

        return [];
    }

    private _sortModelToSortItems(sortModel: SortModel[]): SortItem[] {
        if (notEmpty(sortModel)) {
            return sortModel.map(sortCol => {
                const sortItem: SortItem = {
                    field: sortCol.sortField,
                    dir: sortCol.sortDirection,
                    originalField: sortCol.uniqueColumnId
                };

                return sortItem;
            });
        } else {
            return [];
        }
    }

    private _sortModelToString(sortModel: SortModel[]): string {
        if (notEmpty(sortModel)) {
            const delimiter = ', ';

            return sortModel.map(sortCol => {
                return sortCol.sortField + ' ' + sortCol.sortDirection;
            }).join(delimiter);
        } else {
            return '';
        }
    }

    reloadData(): Promise<DataResponse> {
        this.isLoading = true;
        return this.$reloadData.toPromise().finally(() => {
            this.isLoading = false;
            this.cdr.markForCheck();
        });
    }

    // prevent multiple reload calls within 100 ms
    $reloadData = defer(() => {
        return this._reloadData();
    }).pipe(cacheWithExpiration(100));

    private _reloadData(): Promise<DataResponse> {
        const state = this._getTableState();
        return this.runDataService(state);
    }

    runDataService(state: TableState): Promise<DataResponse> {
        if (this.options.groupBy) {
            return this.dataService.run(state).then((dataResponse) => {
                applyGroupByMarkers(dataResponse.results, this.options.groupBy);
                return dataResponse;
            });
        }
        return this.dataService.run(state);
    }
    reloadDataSource() {
        this.agGrid?.api.setDatasource(this.gridOptions.datasource);
    }

    loadSpecificPage(pageNumber: number) {
        // ag-grid has 0 based page numbers
        pageNumber -= 1;
        this.agGrid?.api.paginationGoToPage(pageNumber);
    }

    cellRenderer = (params: any) => {
        const colDef = params.colDef;
        let formatter = colDef.formatter;

        if (colDef.isGrouped) {
            // wrap formatter when doing groupBy
            formatter = (row: any, value: any, options: FormatterOptions) => {
                const formatted = colDef.formatter ? colDef.formatter(row, value, options) : value;
                return formatted && !row.dt_isDuplicate ? formatted : '';
            };
        }

        return formatCell(params.data,
            params.value,
            formatter
        );
    }

    sizeToFit() {
        this.agGrid?.api.sizeColumnsToFit();
    }

    refreshDataCellsInGrid() {
        this._refreshGrid();
    }

    private _refreshGrid() {
        // redrawRows() is more expensive,
        // but is necessary to update rowClass function
        this.options.rowClass
            ? this.agGrid?.api.redrawRows()
            : this.agGrid?.api.refreshCells({ force: true });
    }

    saveState() {
        const state = this._getRestoreState();
        const stateJson = JSON.stringify(state);
        if (stateJson !== this.gridStateJson) {
            this.gridStateJson = stateJson;
            this.gridStateJsonChange.emit(this.gridStateJson);
        }
    }

    restoreState() {
        let gridState = null;
        try {
            if (this.gridStateJson) {
                gridState = JSON.parse(this.gridStateJson);
            }
        } catch (e) {
            console.error(e);
        }
        if (!gridState) {
            if (this.allowMovableColumns) {
                // Sort the columns by position
                this.sortColumnsByPosition();
            }
            return;
        }

        // restore visible columns
        let restoreColumns: RestoreColumn[] = [];
        try {
            restoreColumns = <RestoreColumn[]> gridState.columns;
        } catch (e) {
            console.error(e);
        }
        if (restoreColumns) {
            for (const restoreColumn of restoreColumns) {
                const matches = this.options.columns.filter((column) => {
                    return getUniqueId(column) === getUniqueId(restoreColumn);
                });
                for (const match of matches) {
                    match.visible = restoreColumn.visible;
                    match.position = restoreColumn.position;
                }
            }
        }

        if (this.allowMovableColumns) {
            // Sort the columns by position
            this.sortColumnsByPosition();
        }

        // restore sort
        let sortItems: SortItem[] = [];
        try {
            sortItems = <SortItem[]> gridState.sorts;
        } catch (e) {
            console.error(e);
        }
        this._setOptionsSorts(sortItems);
    }

    private _setOptionsSorts(sortItems: SortItem[]) {
        if (sortItems) {
            // reset any existing sorts
            for (const column of this.options.columns) {
                column.sortDir = null;
                column.sortedAt = null;
            }

            let index = 1;
            for (const sortItem of sortItems) {
                const columnId = sortItem.originalField || sortItem.field;
                const matches = this.options.columns.filter((column) => {
                    return getUniqueId(column) === columnId
                            && column.sortable !== false;
                });
                for (const match of matches) {
                    match.sortDir = sortItem.dir;
                    match.sortedAt = index;
                    index += 1;
                }
            }
        }
    }


    _getRestoreState(): RestoreState {
        const tableState = this._getTableState();
        const columns: RestoreColumn[] = this.options.columns.map((column) => {
            return {
                colId: column.colId,
                field: column.field,
                visible: column.visible,
                position: column.position,
            };
        });
        const sorts: SortItem[] = tableState.sorts.slice();

        return {
            columns,
            sorts
        };
    }

    _getTableState(): TableState {
        return {
            pageNumber: this.pageNumber,
            pageSize: this.pageSize,
            rowOffset: this.rowOffset,
            sort: this.sort,
            sorts: this.sorts,
        };
    }

    /**
     * Sort the column options by the 'position' value.
     */
    sortColumnsByPosition() {
        // Assign default Positions
        this.options.columns.forEach((column, index) => {
            if (!column.position) {
                column.position = index + 1;
            }
        });

        // Sort the columns by position
        // Note: the in-place sort may not be noticed as a change by other components
        this.options.columns.sort((a, b) => a.position - b.position);
    }

    /**
     * A column has been dragged to a new position
     * @param event DragStopped event
     */
    columnDragStopped(event: DragStoppedEvent) {
        // Sort columns by their position in grid
        event.columnApi.getAllGridColumns().forEach((col, index) => {
            const colDef = col.getColDef();

            let colFromOption: TableColumnDef | undefined;
            // If columns has the id, try to find this column by id
            if (colDef.colId) {
                colFromOption = this.options.columns.find(item => item.colId === colDef.colId);
            }
            // if id doesn't exist or can't find by id, try to find by field
            if (!colFromOption) {
                colFromOption = this.options.columns.find(item => item.field === colDef.field);
            }
            // if column doesn't exist don't do anything
            if (!colFromOption) {
                return;
            }
            colFromOption.position = index + 1;
        });

        // Re-sort the column options by the new positions
        this.sortColumnsByPosition();

        // Save the new positions
        this.saveState();

        // Update the order in the column selection UI.
        this.updateColumnSelect();

        this.columnStateChanged.emit(this.columnsSelectOptions);
    }
}

import {
    Component,
    Input, OnDestroy,
    OnInit,
} from '@angular/core';

import { ResourceService } from './resource.service';
import { WorkspaceFilterService } from '../services/workspace-filter.service';

import {
    BaseFacet,
    BaseFacetService,
    IFacet
} from '../common/facet';

import { ResourceTableOptions } from './resource-table-options';

import {
    CellFormatterService,
    TableState,
    DataResponse,
    ColumnsState,
    TableColumnDef,
} from '@common/datatable';
import { QueryDef } from '../services/query-def';
import { VocabularyService } from '../vocabularies/vocabulary.service';
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { DataContextService } from "@services/data-context.service";
import { arrowClockwise } from '@icons';

@Component({
    selector: 'resource-facet',
    templateUrl: './resource-facet.component.html',
    providers: BaseFacet.BASE_COMPONENT_PROVIDERS
})
export class ResourceFacetComponent extends BaseFacet
    implements OnInit, OnDestroy  {
    @Input() facetId: string;
    @Input() facet: IFacet;

    readonly icons = { arrowClockwise };

    componentName = 'resource';

    resourceTableOptions: ResourceTableOptions;

    readonly COMPONENT_LOG_TAG = 'resource-facet';

    private notifier$ = new Subject<void>();

    dataTableColumns: BehaviorSubject<TableColumnDef[]>;
    dataTableColumns$: Observable<TableColumnDef[]>;

    constructor(
        private baseFacetService: BaseFacetService,
        private resourceService: ResourceService,
        private cellFormatterService: CellFormatterService,
        workspaceFilterService: WorkspaceFilterService,
        private vocabularyService: VocabularyService,
        private dataContext: DataContextService,
    ) {
        super(
            baseFacetService,
            workspaceFilterService
        );

        this.resourceTableOptions = new ResourceTableOptions(this.cellFormatterService);

        this.dataTableColumns = new BehaviorSubject(this.resourceTableOptions.options.columns);
        this.dataTableColumns$ = this.dataTableColumns.asObservable();

        this.dataService = {
            run: (tableState: TableState) => {
                return this.loadItemList(tableState);
            }
        };
    }

    // lifecycle
    ngOnInit() {
        super.ngOnInit();
        this.initialize();
        this.dataContext.onCancel$.pipe(takeUntil(this.notifier$)).subscribe(() => {
            this.changeView(this.LIST_VIEW);
        });
    }
    
    ngOnDestroy() {
        this.notifier$.next();
        this.notifier$.complete();
    }

    initialize() {
        this.restoreFilterState();
        this.changeView(this.LIST_VIEW);
    }

    refreshData() {
        this.initialize();
        this.dataTableComm.reloadTable();
    }

    restoreFilterState() {
        // process any grid filters
        if (this.facet && this.facet.GridFilter) {
            try {
                this.filter = JSON.parse(this.facet.GridFilter);
            } catch (err) {
                console.error(err);
            }

            if (!this.filter) {
                this.filter = {};
            }
        }
    }

    async loadItemList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'DateCreated DESC';
        const expands: string[] = [];

        this.setLoadingState(tableState.loadingMessage);
        const filter: any = this.getActiveFilter();
        filter.IsGroup = false;

        const queryDef: QueryDef = {
            page,
            size: pageSize,
            sort,
            filter,
            expands
        };

        try {
            const response = await this.resourceService.getResources(queryDef);

            const visibleColumns = this.getVisibleColumns(this.resourceTableOptions.options);
            await this.resourceService.ensureVisibleColumnsDataLoaded(response.results, visibleColumns);

            this.stopLoading();
            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        } finally {
            this.stopLoading();
        }
    }

    addItemClick() {
        this.setLoadingState();
        this.createNewResource().then((resource) => {
            this.stopLoading();
            this.itemToEdit = resource;
            this.changeView(this.DETAIL_VIEW);
        }).catch((error) => {
            this.loading = false;
            this.loggingService.logError("An unexpected error occurred. Please try again", error, this.componentName, true);
        });
    }

    createNewResource(): Promise<any> {
        const newResource = this.resourceService.createResource();
        this.vocabularyService.getCVDefault('cv_ResourceTypes').then((value) => {
            newResource.cv_ResourceType = value;
        });
        this.vocabularyService.getCVDefault('cv_ResourceGroups').then((value) => {
            newResource.cv_ResourceGroup = value;
        });
        return Promise.resolve(newResource);
    }

    async selectedColumnsChange({ visible }: ColumnsState) {
        try {
            this.facetLoadingState.changeLoadingState(true);
            await this.resourceService.ensureVisibleColumnsDataLoaded(this.data, visible);
        } finally {
            this.facetLoadingState.changeLoadingState(false);
        }
    }
}

/**
 * Represent a Message from Admin DB,
 *   when used in the message center inbox
 */
export class InboxMessage {
    /* eslint-disable-next-line */
    C_FromUser_key: string;
    /* eslint-disable-next-line */
    DateGenerated: Date;
    /* eslint-disable-next-line */
    IsDismissed: boolean;
    /* eslint-disable-next-line */
    Subject: string;
    /* eslint-disable-next-line */
    MessageText: string;
    /* eslint-disable-next-line */
    ReplyText: string;
    
    // non-DB fields
    messageMaps: any[];
    sendingReply: boolean;
}

import {
    Component,
    Input,
    OnInit
} from '@angular/core';

import { contactSupportUri } from '../config/climb-web-settings';

import { ReportingService } from './reporting.service';
import { TranslationService } from '../services/translation.service';
import { FeatureFlagService } from '../services/feature-flags.service';

import {
    ReportCategory,
    ReportSource,
    ReportTypeItem,
} from './models';

import {
    BaseFacet,
    BaseFacetService,
    FacetView
} from '../common/facet';

import {
    notEmpty
} from '../common/util';
import { WorkspaceFilterService } from '../services/workspace-filter.service';
import { arrowClockwise } from '@icons';

@Component({
    selector: 'reporting-facet',
    templateUrl: './reporting-facet.component.html',
    styles: [`
        .report-menu-container .list-unstyled {
            margin-bottom: 1em;
        }
        .report-menu-container li {
            margin-bottom: 0.4em;
        }
        .no-custom-reports-container {
            font-style: italic;
            line-height: 1.5em;
        }
    `]
})
export class ReportingFacetComponent extends BaseFacet implements OnInit {
    @Input() facetId: string;
    @Input() facet: any;

    readonly icons = { arrowClockwise };

    reportCategories: ReportCategory[] = [];
    // CustomSearchQueries marked IsForAll=1
    globalReportTypes: ReportTypeItem[] = [];
    // CustomSearchQueries marked IsForAll=0
    customReportTypes: ReportTypeItem[] = [];
    bulkExportTypes: ReportTypeItem[] = [];

    selectedReportSource: ReportSource;
    selectedReportType: ReportTypeItem;

    // Current view state
    facetView: FacetView;
    loading = false;

    contactSupportUri: string = contactSupportUri;

    readonly BULK_EXPORT_CATEGORY_NAME = 'Bulk Export';

    // Export enum to template
    FacetView = FacetView;

    private isCRO: boolean;

    constructor(
        baseFacetService: BaseFacetService,
        workspaceFilterService: WorkspaceFilterService,
        private reportingService: ReportingService,
        private translationService: TranslationService,
        private featureFlagService: FeatureFlagService,
    ) {
        super(
            baseFacetService,
            workspaceFilterService
        );
    }

    ngOnInit() {
        this.initIsCRO();
        this.initialize();
    }

    initialize() {
        this.initSelection();
        this.refreshData();
        this.changeView(this.LIST_VIEW);
    }

    refresh() {
        this.initialize();
    }

    private initSelection() {
        this.selectedReportType = null;
        this.selectedReportSource = null;
    }

    private refreshData() {
        this.loading = true;

        const p1 = this.getReportTypes();
        const p2 = this.getCustomReportTypes();
        this.getBulkExportTypes();

        return Promise.all([p1, p2])
            .then(() => {
                this.loading = false;
            })
            .catch((err: Error) => {
                this.loading = false;

                throw err;
            });
    }

    private getReportTypes(): Promise<void> {
        return this.reportingService.getActiveReportTypes(this.isCRO)
            .then((reportCategories) => {
                this.reportCategories = reportCategories;
            });
    }

    private getCustomReportTypes(): Promise<any> {
        return this.reportingService.getCustomSearches().then((data) => {
            this.customReportTypes = [];
            this.globalReportTypes = [];

            if (notEmpty(data)) {
                for (const item of data) {
                    const textTranslated = this.translationService.translateMessage(
                        item.SearchName
                    );

                    const reportType: ReportTypeItem = {
                        text: textTranslated,
                        value: item
                    };

                    if (item.IsForAll) {
                        this.globalReportTypes.push(reportType);
                    } else {
                        this.customReportTypes.push(reportType);
                    }
                }
            }


        });
    }

    private getBulkExportTypes() {
        this.bulkExportTypes = [
            <ReportTypeItem> {
                categoryName: this.BULK_EXPORT_CATEGORY_NAME,
                text: 'Task',
                value: 'task'
            },
            {
                categoryName: this.BULK_EXPORT_CATEGORY_NAME,
                text: 'Animal Check',
                value: 'animalCheck'
            }
        ];
    }

    private initIsCRO() {
        const flag = this.featureFlagService.getFlag("IsCRO");
        this.isCRO = (flag && flag.IsActive && flag.Value.toLowerCase() === "true");
    }

    selectReportType(reportType: ReportTypeItem) {
        this.selectedReportType = reportType;
        this.selectedReportSource = ReportSource.Report;

        this.changeView(this.DETAIL_VIEW);
    }

    selectBulkExport(reportType: ReportTypeItem) {
        if (reportType.value === "task") {
            this.selectedReportType = reportType;
            this.selectedReportSource = ReportSource.BulkExport;
        }
        else if (reportType.value === "animalCheck") {
            this.selectedReportType = reportType;
            this.selectedReportSource = ReportSource.AnimalCheck;
        } else {
            throw new Error("Report " + reportType.value + " not found");
        }

        this.changeView(this.DETAIL_VIEW);
    }

    selectCustomSearch(reportType: ReportTypeItem) {
        this.selectedReportType = reportType;
        this.selectedReportSource = ReportSource.CustomSearch;

        this.changeView(this.DETAIL_VIEW);
    }

    exitDetail() {
        this.initSelection();

        this.changeView(this.LIST_VIEW);
    }
}

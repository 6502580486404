import {
    Component,
    Input,
    OnInit
} from '@angular/core';

import {
    ReportSource,
    ReportTypeItem,
} from '../models';

import { FacetView } from '../../common/facet';

@Component({
    selector: 'bulk-export-runner',
    templateUrl: './bulk-export-runner.component.html',
})
export class BulkExportRunnerComponent implements OnInit {
    @Input() reportType: ReportTypeItem;

    facetView: string;
    loading = false;

    // Export enum to template
    FacetView = FacetView;

    constructor() {
        //
    }

    ngOnInit() {
        this.initialize();
    }

    initialize() {
        this.changeView(FacetView.LIST_VIEW);
    }

    // View
    addItemClicked(source: ReportSource) {
        if (source === ReportSource.AnimalCheck) {
            this.changeView("ANIMAL_CHECK");
        } else if (source === ReportSource.BulkExport) {
            this.changeView("BULK_EXPORT");
        }
    }

    exitRequesterClicked() {
        this.changeView(FacetView.LIST_VIEW);
    }

    private changeView(newView: string) {
        this.facetView = newView;
    }
}

/*
 * Contains functions for retrieving (not editing)
 * import mapping targets from their hard-coded
 * space in the Data app. Does not use Breeze queries
 * as these are not database objects, but rather API
 * calls to the Data app instead.
 */
import { Injectable } from '@angular/core';

import { LoggingService } from '../services/logging.service';
import { WebApiService } from '../services/web-api.service';

@Injectable()
export class ImportMappingTargetService {

    readonly COMPONENT_LOG_TAG = 'import-mapping-target-service';


    constructor(
        private loggingService: LoggingService,
        private webApiService: WebApiService
    ) {
        // Nothing to do
    }

    // Return all target lists
    getImportMappingTargetLists(): Promise<any[]> {
        return this.webApiService.callApi('api/file/import-mapping/alltargets')
            .then((response) => {
                const data: any[] = response.data;
                return data;
            });
    }

    // Return list of targets associated with a particular
    // import file type
    getImportMappingTargets(fileType: string, importFileDefinitionKey: string): Promise<any[]> {
        return this.webApiService.callApi(
            'api/file/import-mapping/targets/' + fileType + '/' + importFileDefinitionKey)
            .then((response) => {
                const data: any[] = response.data;
                return data;
            })
            .catch((error) => {
                this.loggingService.logError(error._body, null,
                    this.COMPONENT_LOG_TAG, true);
                return null;
            });
    }

    // Return target with specific name in specific file type
    getImportMappingTarget(fileType: string, targetName: string): Promise<any> {
        return this.webApiService.callApi(
            'api/file/import-mapping/singletarget/' + fileType + '/' + targetName)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                this.loggingService.logError(error._body, null,
                    this.COMPONENT_LOG_TAG, true);
                return null;
            });
    }
}

const ELLIPSIS = '\u2026';

import {
    Component,
    EventEmitter,
    Input,
    Output
} from '@angular/core';

import { SearchService } from '../search/search.service';
import { SearchQueryDef } from '../search/search-query-def';
import { TranslationService } from '../services/translation.service';

@Component({
    selector: 'line-select',
    template: `
    <div class="line-select">
        <climb-typeahead 
            data-automation-id="line-select-typeahead"
            [(model)]="model"
            [resultFormatter]="lineNameFormatter"
            [keyFormatter]="lineKeyFormatter"
            [search]="searchLines"
            [keySearch]="searchLinesByKey"  
            [placeholder]="placeholder"
            [required]="required"
            [disabled]="disabled"
            [namespace]="'line'"
            (selectItem)="selectItemHandler($event)"
            #typeahead
        ></climb-typeahead>
    </div>
    `,
    styles: [`
        .line-select {
            min-width: 300px;
        }
    `]
})
export class LineSelectComponent {
    @Input() model: any;
    @Output() modelChange: EventEmitter<any> = new EventEmitter<any>();
    @Input() placeholder: string;
    @Input() required: boolean;
    @Input() disabled: boolean;
    @Input() filterTaxonKey: any;

    constructor(
        private searchService: SearchService,
        private translationService: TranslationService
    ) {
        this.placeholder = this.translationService.translate('Line') + ' name' + ELLIPSIS;
    }

    selectItemHandler(item: any) {
        this.modelChange.emit(this.model);
    }

    searchLines = (search: string): Promise<any[]> => {

        const filter = {
            LineName: search,
            TaxonKey: this.filterTaxonKey,
            IsActive: true
        };

        let count = null;
        // if 2 characters or less, put a limit on number returned
        if (search.length < 3) {
            count = 200;
        }

        return this._searchLines(filter, count);
    }

    searchLinesByKey = (key: any): Promise<any[]> => {
        const filter = {
            LineKey: key
        };
        return this._searchLines(filter, 1);
    }

    _searchLines(thisFilter: any, count: number): Promise<any[]> {
        const searchQueryDef: SearchQueryDef = {
            entity: 'Lines',
            page: null,
            size: count,
            sortColumn: 'LineName',
            sortDirection: 'asc',
            filter: thisFilter
        };
        return this.searchService.getEntitiesBySearch(searchQueryDef).then((results: any) => {
            return results.data;
        });
    }

    lineNameFormatter = (item: any) => {
        return item.LineName;
    }

    lineKeyFormatter = (item: any) => {
        return item.LineKey;
    }
}

import type { EnumerationClass } from './enumeration-class.interface';
import type { StudyCharacteristicInstance } from './study-characteristic-instance.interface';
import type { cv_DataType } from './cv-data-type.interface';
import type { cv_Unit } from './cv-unit.interface';
import type { StudyCharacteristicStudyType } from './study-characteristic-study-type.interface';

export interface StudyCharacteristic {
    C_DataType_key: number;
    C_EnumerationClass_key: number | null;
    C_StudyCharacteristic_key: number;
    C_Unit_key: number | null;
    C_Workgroup_key: number;
    CharacteristicName: string;
    CreatedBy: string;
    DateCreated: Date;
    DateLastReviewed: Date | null;
    DateModified: Date;
    Description: string;
    EnumerationClass: EnumerationClass;
    HasStudyType: boolean;
    StudyTypes: string;
    IsActive: boolean;
    ModifiedBy: string;
    NumericScale: number | null;
    SortOrder: number | null;
    StudyCharacteristicInstance: StudyCharacteristicInstance[];
    TextLineCount: number | null;
    ValidationMaximum: string;
    ValidationMinimum: string;
    Version: number;
    cv_DataType: cv_DataType;
    cv_Unit: cv_Unit;
    StudyCharacteristicStudyType: StudyCharacteristicStudyType[];
}

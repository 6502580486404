<div class="detail-form-wrapper">

    <div class="detail-header">
        <h3>Add Clinical Record</h3>
    </div>

    <form name="newHealthRecordForm"
          class="detail-form">
        <fieldset>

            <div class="form-group row">
                <label class="col-md-3 col-form-label">Animal ID</label>
                <div class="col-md-9" data-auto-focus="clinical-animal">
                    <animal-select [(model)]="animalKey"
                                   [selectByAnimalID] ="true"
                                   (modelChange)="selectAnimal()">
                    </animal-select>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-md-3 col-form-label">Animal Name</label>
                <div class="col-md-9" data-auto-focus="clinical-animal">
                    <animal-select [(model)]="animalKey"
                                   (modelChange)="selectAnimal()">
                    </animal-select>
                </div>
            </div>

            <ng-container *ngIf="healthRecord && healthRecord.Animal">
                <div class="form-group row">
                    <label class="col-md-3 col-form-label">Animal Status</label>
                    <div class="col-md-9">
                        <active-vocab-select [(model)]="healthRecord.Animal.C_AnimalStatus_key"
                                             (modelChange)="animalStatusChanged(healthRecord.Animal)"
                                             [vocabChoices]="animalStatuses"
                                             [keyFormatter]="animalStatusKeyFormatter"
                                             [optionFormatter]="animalStatusFormatter">
                        </active-vocab-select>

                        <small class="form-text text-muted"
                           *ngIf="animalStatusChangedToDefault">
                            <span class="text-info">
                                <i class="fa fa-info-circle" aria-hidden="true"></i>
                                The status has been changed to the clinical default.
                            </span>
                        </small>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-md-3 col-form-label">Health Tech</label>
                    <div class="col-md-9">
                        <active-vocab-select [(model)]="healthRecord.C_Resource_key"
                                             [vocabChoices]="resources"
                                             [keyFormatter]="resourceKeyFormatter"
                                             [optionFormatter]="resourceNameFormatter">
                        </active-vocab-select>

                        <small class="form-text text-muted"
                           *ngIf="healthTechChangedToDefault">
                            <span class="text-info">
                                <i class="fa fa-info-circle text-info" aria-hidden="true"></i>
                                The health tech has been set to the default.
                            </span>
                        </small>
                    </div>
                </div>
            </ng-container>

            <div class="btn-container">
                <button type="button"
                        *ngIf="healthRecord"
                        class="btn btn-fw btn-primary"
                        (click)="createHealthRecord()">
                    Create
                </button>
                <button type="button"
                        class="btn btn-fw btn-secondary"
                        (click)="cancelHealthRecord()">
                    Cancel
                </button>
            </div>

        </fieldset>
    </form>
</div>

<div class="form-group row" 
    *ngFor="let characteristic of characteristics|
    orderBy : sortOrderPath">

    <div class="col-md-12" *ngIf="characteristic && characteristic[characteristicEntityName]">
    <label>
        {{characteristic[characteristicEntityName]["CharacteristicName"]}}
    </label>
    <characteristic-input 
                            #characteristicInput
                            [characteristic]="characteristic"
                            [readonly]="readonly"
                            [(value)]="characteristic.CharacteristicValue">
    </characteristic-input>
    </div>
</div>
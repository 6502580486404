import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxPaginationModule } from 'ngx-pagination';

import { FacetSharedModule } from '@common/facet/facet-shared.module';
import { ClimbCommonModule } from '@common/climb-common.module';
import { ClimbServicesModule } from '@services/climb-services.module';

import { WorkflowBulkDataExportService } from './services/workflow-bulk-data-export.service';
import { WorkflowBulkEditComponent } from './workflow-bulk-edit/workflow-bulk-edit.component';
import { DataTypeModule } from '../data-type/data-type.module';
import { UsersSharedModule } from '../user/users-shared.module';
import { ViewWorkflowAuditReportComponentService } from './services/view-workflow-audit-report-component.service';
import { ResourcesSharedModule } from '../resources/resources-shared.module';
import { JobsSharedModule } from '../jobs/jobs-shared.module';
import { EnumerationsSharedModule } from '../enumerations/enumerations-shared.module';
import { TaskTableCommService } from './services/task-table-comm.service';
import { WorkflowFacetComponent } from './workflow-facet.component';
import { ClinicalSharedModule } from '../clinical/clinical-shared.module';
import {
    AnimalCommentsModalComponent,
    DataChangedModalComponent,
    TaskOutputSetMaterialSelectComponent,
    WorkflowAnimalTableComponent,
    WorkflowBulkDataPrintComponent,
    WorkflowBulkTemplatesComponent,
    WorkflowPrintPreviewComponent,
    WorkflowAuditReportComponent,
    WorkflowFilterComponent,
    WorkflowOutputTableComponent,
    WorkflowSampleTableComponent,
} from './components';
import { WorkflowBulkDataEntryComponent } from './workflow-bulk-data-entry/workflow-bulk-data-entry.component';
import { WorkflowsSharedModule } from './workflows-shared.module';
import { AuditSharedModule } from '../audit/audit-shared.module';
import { HousingSharedModule } from '../housing/housing-shared.module';
import { LinesSharedModule } from '../lines/lines-shared.module';
import { TasksSharedModule } from '../tasks/tasks-shared.module';
import { WorkspacesSharedModule } from '../workspaces/workspaces-shared.module';
import { VocabulariesSharedModule } from '../vocabularies/vocabularies-shared.module';
import { WorkflowDetailComponent } from './workflow-detail/workflow-detail.component';
import { ImportSharedModule } from '../import/import-shared.module';
import { AnimalsSharedModule } from '../animals/animals-shared.module';
import { SamplesSharedModule } from '../samples/samples-shared.module';
import { StudiesSharedModule } from '../studies/studies-shared.module';
import { CohortSharedModule } from '../cohort/cohort-shared.module';
import { NgxBootstrapMultiselectModule } from 'ngx-bootstrap-multiselect';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgbModule,
        NgxPaginationModule,
        NgxBootstrapMultiselectModule,
        ClimbCommonModule,
        ClimbServicesModule,
        FacetSharedModule,
        AnimalsSharedModule,
        ClinicalSharedModule,
        EnumerationsSharedModule,
        WorkflowsSharedModule,
        AuditSharedModule,
        DataTypeModule,
        HousingSharedModule,
        ImportSharedModule,
        JobsSharedModule,
        LinesSharedModule,
        ResourcesSharedModule,
        SamplesSharedModule,
        TasksSharedModule,
        VocabulariesSharedModule,
        WorkspacesSharedModule,
        UsersSharedModule,
        StudiesSharedModule,
        CohortSharedModule,
    ],
    declarations: [
        WorkflowBulkDataEntryComponent,
        WorkflowBulkDataPrintComponent,
        WorkflowBulkEditComponent,
        WorkflowBulkTemplatesComponent,
        WorkflowFilterComponent,
        TaskOutputSetMaterialSelectComponent,
        WorkflowAuditReportComponent,
        WorkflowFacetComponent,
        WorkflowDetailComponent,
        WorkflowOutputTableComponent,
        WorkflowPrintPreviewComponent,
        WorkflowAnimalTableComponent,
        WorkflowSampleTableComponent,
        DataChangedModalComponent,
        AnimalCommentsModalComponent
    ],
    exports: [
        WorkflowAuditReportComponent,
        WorkflowFilterComponent,
        WorkflowFacetComponent,
        WorkflowBulkDataEntryComponent,
    ],
    providers: [
        WorkflowBulkDataExportService,
        TaskTableCommService,
        ViewWorkflowAuditReportComponentService
    ]
})
export class WorkflowsModule { }

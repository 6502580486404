import { Injectable } from '@angular/core';
import { defer, Observable } from 'rxjs';

import { ResourceService } from '../resources/resource.service';
import { VocabularyService } from '../vocabularies/vocabulary.service';
import { WorkflowService } from '../workflow/services/workflow.service';
import { take } from 'rxjs/operators';

@Injectable()
export class ScheduleVocabService {

    // vocab Observables
    public get workflowTasks$(): Observable<any[]>{
        return defer(() => this.workflowService.getWorkflowTaskList()).pipe(take(1));
    }
    public get resources$(): Observable<any[]>{
        return defer(() => this.resourceService.getAllResources()).pipe(take(1));
    }
    public get taskStatuses$(): Observable<any[]>{
        return defer(() => this.vocabularyService.getCV('cv_TaskStatuses')).pipe(take(1));
    }
    public get jobStatuses$(): Observable<any[]>{
        return defer(() => this.vocabularyService.getCV('cv_JobStatuses')).pipe(take(1));
    }
    public get resourceGroups$(): Observable<any[]>{
        return defer(() => this.vocabularyService.getCV('cv_ResourceGroups')).pipe(take(1));
    }

    constructor(
        private resourceService: ResourceService,
        private vocabularyService: VocabularyService,
        private workflowService: WorkflowService
    ) {}
}

import type { Cohort } from './cohort.interface';

export interface cv_MatchingMethod {
    C_MatchingMethod_key: number;
    C_VocabularySource_key: number | null;
    C_Workgroup_key: number;
    Cohort: Cohort[];
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    ElementID: string;
    IsActive: boolean;
    IsDefault: boolean;
    IsDeprecated: boolean;
    MatchingMethod: string;
    ModifiedBy: string;
    SortOrder: number | null;
    Version: number;
}

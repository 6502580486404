<div>
    <div class="form-group row align-items-center no-gutters">
        <div class="col-8 col-md-8">
            <label class="mb-0">Animals per unit</label>
        </div>
        <div class="col-4 col-md-4">
            <div>
                <input 
                    type="number"
                    step="1"
                    class="housing-size"
                    name="housing_size"
                    style="width: 55px;"
                    [(ngModel)]="materialsPerUnit"
                    (ngModelChange)="onMaterialsPerUnitChange()"
                    [disabled]="disabled"
                    integerInput
                    [min]="1"
                    [max]="99"
                    />
            </div>
        </div>
    </div>

    <div class="form-group row align-items-center no-gutters">
        <div class="col-8 col-md-8">
            <label class="mb-0">Unhoused Animals</label>
        </div>
        <div class="d-flex align-items-center col-4 col-md-4">
            {{unhousedMaterialCount}}
            <a *ngIf="unhousedMaterialCount" [ngClass]="!disabled ? 'add-item-link' : 'add-item-link-disabled'" (click)="onAddHousingUnit()">
                <i class="fa fa-plus-circle" [title]="!disabled ? 'Add Unit' : ''"></i>
            </a>
        </div>
    </div>

    <div class="form-group row no-gutters mt-2">
        <div class="col-4 col-md-4 d-flex align-items-center">
            <label class="mb-0">Unit Naming</label>
        </div>
        <div class="housing-units pl-2 col-7 col-md-7 d-flex justify-content-center">
            <text-slider-button *ngIf="isHousingNamingActive; else manualText"
                [(model)]="autoNaming"
                (modelChange)="onAutoNamingChanged()"
                [disabled]="disabled"
                trueText="Automatic"
                falseText="Manual">
            </text-slider-button>
            <ng-template #manualText>
                <span class="p-1 align-self-center">Manual</span>
            </ng-template>
        </div>
    </div>
    <div *ngFor="let unit of units; let index = index;" 
        class="input-group mb-2" 
        style="max-width: 100%;">
        <div class="d-flex flex-column">
            <div class="d-flex">
                <div class="input-group-prepend">
                    <span class="input-group-text px-2">Unit {{index + 1}}</span>
                </div>
                <input
                    type="text"
                    name="materialPoolID"
                    #materialPoolID="ngModel"
                    class="form-control housing-unit-id w-100"
                    [style.display]="autoNaming ? 'none' : 'unset'"
                    name="housing_unit_{{index}}_id"
                    placeholder="ID"
                    [required]="true"
                    [disabled]="disabled"
                    [(ngModel)]="unit.id" 
                    (ngModelChange)="updateNamingValid()"
                    validateUnique
                    uniqueEntity="MaterialPools"
                    uniqueProperty="MaterialPoolID"
                    maxlength="75"/>
                <input 
                    type="number"
                    step="1"
                    class="form-control housing-unit-size"
                    [ngStyle]="{'max-width': !autoNaming ? '55px' : '100%'}"
                    name="housing_unit_{{index}}_size"
                    [(ngModel)]="unit.size"
                    (ngModelChange)="onHousingUnitSizeChange(unit)"
                    [disabled]="disabled" 
                    integerInput
                    [min]="0"
                    [max]="99"/>
                <a *ngIf="!unit.size"
                    class="d-flex ml-1 align-items-center"
                    (click)="onRemoveHousingUnit(index)">
                    <i class="fa fa-remove remove-item" title="Delete item"></i>
                </a>
            </div>
            <small class="form-text text-muted" *ngIf="!autoNaming && materialPoolID.errors?.unique && materialPoolID.dirty">
                <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                This ID already exists.
            </small>
        </div>

    </div>

    <div class="input-group mt-2">
        <div class="input-group-prepend">
            <span class="input-group-text px-2">Add Unit</span>
        </div>
        <div class="input-group-append">
            <a class="input-group-text px-3 border-left-0"
                (click)="onAddHousingUnit()"
                [ngClass]="!disabled ? 'add-item-link' : 'add-item-link-disabled'">
                <i class="fa fa-plus-circle" [title]="!disabled ? 'Add Unit' : ''"></i>
            </a>
        </div>
    </div>

    <div class="form-group row align-items-center no-gutters mt-3">
        <div class="col-12 col-md-12">
            <label>Housing Type</label>
        </div>
        <div class="col-auto">
            <active-vocab-select 
                [(model)]="materialPoolTypeKey"
                [vocabChoices]="materialPoolTypes"
                [keyFormatter]="materialPoolTypeKeyFormatter"
                [optionFormatter]="materialPoolTypeFormatter"
                [readonly]="disabled"
                [required]="true">
            </active-vocab-select>
        </div>
    </div>

    <div *ngIf="showDate" class="form-group row align-items-center no-gutters mt-3">
        <div class="col-12 col-md-12">
            <label>Date</label>
        </div>
        <div class="col-auto">
            <climb-ngb-date 
                [disabled]="disabled"
                [(model)]="housingDate">
            </climb-ngb-date>
        </div>
    </div>

    <div *ngIf="showOwner" class="form-group row align-items-center no-gutters mt-3">
        <div class="col-12 col-md-12">
            <label>Owner</label>
        </div>
        <div class="col-auto">
            <user-select 
                [(model)]="housingOwner" 
                [disabled]="disabled"
                [forceValidSelection]="false">
            </user-select>
        </div>
    </div>

    <div class="form-group row align-items-center no-gutters mt-3">
        <div class="col-12 col-md-12">
            <label>Location</label>
        </div>
        <div class="col-auto">
            <location-chooser 
                [(location)]="housingLocation"
                [readonly]="disabled">
            </location-chooser>
        </div>
    </div>

    <div *ngIf="showContainerType" class="form-group row align-items-center no-gutters mt-3">
        <div class="col-12 col-md-12">
            <label>Container Type</label>
        </div>
        <div class="col-auto">
            <active-vocab-select 
                [(model)]="containerTypeKey"
                [vocabChoices]="containerTypes"
                [keyFormatter]="containerTypeKeyFormatter"
                [optionFormatter]="containerTypeFormatter"
                [readonly]="disabled"
                [nullable]="true">
            </active-vocab-select>
        </div>
    </div>
</div>
<table class="table table-bordered">
    <thead>
        <tr class="detail-grid-header">
            <th colspan="8">
                <span class="float-right"
                      *ngIf="animalMaterials.length > 0">
                    <a class="detail-grid-button"
                       (click)="copyAnimals()">
                        <i class="fa fa-copy" title="Copy selected animals"></i>
                    </a>
                </span>

                <a class="add-item-link detail-grid-button-label"
                   (click)="addClicked()"
                   *ngIf="!readonly && animalMaterials.length > 0">
                    <i class="fa fa-plus-circle" title="Add outputs for selected animals"></i>
                </a>

                <span class="detail-grid-button-label">
                    Animals
                    <span *ngIf="animalMaterials.length > 0">
                        ({{animalMaterials.length}})
                        <a (click)="task.animalsExpanded = !task.animalsExpanded"  class="toggle-animals-link">
                            <i class="fa"
                               [ngClass]="task.animalsExpanded ? 'fa-chevron-up' : 'fa-chevron-down'"
                               [attr.title]="task.animalsExpanded ? 'Hide Animals' : 'Show Animals'"></i>
                        </a>
                    </span>
                </span>
            </th>
        </tr>

        <tr *ngIf="animalMaterials.length > 0 && task.animalsExpanded"
            class="detail-grid-header">
            <th style="width:40px;"
                class="text-center">
                <input type="checkbox"
                       (click)="selectAllNonTerminalAnimals($event)"
                       title="Select all non-terminal animals" />
            </th>
            <th>ID</th>
            <th>Name</th>
            <th class="text-nowrap">
                <bulk-edit-header [headerText]="'Status'"
                                  (updateClicked)="bulkAnimalStatusChanged()"
                                  [readonly]="readonly">
                    <div modal-content>
                        <active-vocab-select [(model)]="bulkAnimalStatusKey"
                                             [vocabChoices]="animalStatuses"
                                             [keyFormatter]="animalStatusKeyFormatter"
                                             [optionFormatter]="animalStatusFormatter"></active-vocab-select>
                    </div>
                </bulk-edit-header>
            </th>
            <th>Sex</th>
            <th>Birth Date</th>
            <th>{{'Line' | translate}}</th>
            <th>Location</th>
        </tr>
    </thead>

    <tbody *ngIf="task.animalsExpanded">
        <tr *ngFor="let animalMaterial of animalMaterials"
            [ngClass]="{
                'end-state': animalMaterial?.Material?.Animal?.cv_AnimalStatus?.IsExitStatus,
                'end-state-animal': animalMaterial?.Material?.Animal?.cv_AnimalStatus?.IsExitStatus
            }">
            <td style="width:40px;"
                class="text-center">
                <input type="checkbox"
                       [(ngModel)]="animalMaterial.isSelected" />
            </td>
            <td class="text-nowrap">
                <div class="draggable"
                     draggable
                     data-drag-type="material"
                     (dragStart)="dragAnimalsStart()"
                     (dragStop)="dragAnimalsStop()"
                     id="{{animalMaterial?.Material?.Identifier}}">
                    {{animalMaterial?.Material?.Identifier}}
                </div>
            </td>
            <td class="text-nowrap">
                {{animalMaterial?.Material?.Animal?.AnimalName}}
            </td>
            <td>
                <active-vocab-select *ngIf="animalMaterial?.Material?.Animal"
                                     [(model)]="animalMaterial.Material.Animal.C_AnimalStatus_key"
                                     (modelChange)="animalStatusChanged(animalMaterial.Material.Animal)"
                                     [vocabChoices]="animalStatuses"
                                     [keyFormatter]="animalStatusKeyFormatter"
                                     [optionFormatter]="animalStatusFormatter"
                                     [readonly]="readonly"></active-vocab-select>
            </td>
            <td>
                {{animalMaterial?.Material?.Animal?.cv_Sex?.Sex}}
            </td>
            <td>
                {{animalMaterial?.Material?.Animal?.DateBorn | formatShortDate}}
            </td>
            <td>
                {{animalMaterial?.Material?.Line?.LineName}}
            </td>
            <td>
                {{animalMaterial?.Material?.CurrentLocationPath}}
            </td>
        </tr>
    </tbody>
</table>

<form role="form" class="form-label-right">
    <div class="modal-header justify-content-center">
        <h4 class="modal-title">{{'Lines' | translate}} Filter</h4>
    </div>

    <div class="modal-body">

        <div class="form-group row">
            <label class="col-md-3 col-form-label">Name</label>
            <div class="col-md-9">
                <input type="text"
                       name="lineName"
                       [(ngModel)]="filter.LineName"
                       class="form-control input-medium"
                       data-auto-focus="lineName" />
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-3 col-form-label">{{'LineShortName' | translate}}</label>
            <div class="col-md-9">
                <input type="text"
                       name="stockNumber"
                       [(ngModel)]="filter.StockNumber"
                       class="form-control input-medium" />
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-3 col-form-label">Stock</label>
            <div class="col-md-9">
                <multi-paste-input [(model)]="filter.stockIDs"
                                   [isNumeric]="false"
                                   [limit]="MULTI_PASTE_INPUT_LIMIT"
                                   [rows]="3"></multi-paste-input>
                <small class="form-text text-muted">
                    IDs separated by
                    <code>,</code> or <code>Enter</code>.
                    Limit: {{MULTI_PASTE_INPUT_LIMIT}}.
                </small>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-3 col-form-label">Species</label>
            <div class="col-md-9">
                <select name="taxon"
                        [(ngModel)]="filter.C_Taxon_key"
                        class="form-control input-medium">
                    <option></option>
                    <option *ngFor="let item of taxons"
                            [value]="item.C_Taxon_key">
                        {{item.CommonName}}
                    </option>
                </select>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-3 col-form-label">Type</label>
            <div class="col-md-9">
                <select name="lineType"
                        [(ngModel)]="filter.C_LineType_key"
                        class="form-control input-medium">
                    <option></option>
                    <option *ngFor="let item of lineTypes"
                            [value]="item.C_LineType_key">
                        {{item.LineType}}
                    </option>
                </select>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-3 col-form-label">Status</label>
            <div class="col-md-9">
                <select name="lineStatus"
                        [(ngModel)]="filter.C_LineStatus_key"
                        class="form-control input-medium">
                    <option></option>
                    <option *ngFor="let item of lineStatuses"
                            [value]="item.C_LineStatus_key">
                        {{item.LineStatus}}
                    </option>
                </select>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-3 col-form-label">Active</label>
            <div class="col-md-9">
                <active-status-select [(model)]="filter.IsActive"
                                        [nullable]="true"></active-status-select>
            </div>
        </div>

        <div class="form-group row">
                <label class="col-md-3 col-form-label">Technician</label>
                <div class="col-md-9">
                    <user-select [(model)]="filter.Technician" [forceValidSelection]="false"></user-select>
                </div>
            </div>

    </div><!-- /.modal-body -->

    <div class="modal-footer">
        <span class="btn-set">
            <button type="submit"
                    class="btn btn-fw-wide btn-lg btn-primary"
                    (click)="filterClicked($event)">
                Filter
            </button>
            <button data-automation-id="clear-filter-button"
                    type="button"
                    class="btn btn-fw-wide btn-lg btn-secondary"
                    (click)="clearClicked()">
                Clear Filter
            </button>
        </span>
        <span class="btn-set">
            <button type="button"
                    class="btn btn-lg btn-secondary"
                    (click)="closeClicked()">
                Close
            </button>
        </span>
    </div>
</form>

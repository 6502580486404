import { Component, ChangeDetectionStrategy, Input, HostBinding } from '@angular/core';

export type SpinnerSize = 'sm' | 'md' | 'lg' | 'xl' | 'xxl';

@Component({
    selector: 'climb-spinner',
    template: '',
    styleUrls: ['./spinner.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpinnerComponent {
    @Input()
    size: SpinnerSize = 'md';

    @HostBinding('attr.title')
    @Input()
    title = 'Loading';

    @HostBinding('class.spinner-light')
    @Input()
    light = false;

    @HostBinding('class')
    get classes(): string {
        return `spinner-${this.size}`;
    }
}

import {
    Pipe,
    PipeTransform
} from '@angular/core';

@Pipe({ name: 'truncate' })
export class TruncatePipe implements PipeTransform {

    transform(value: string, maxLength: number, suffix = '...'): string {
        if (!value ||
            !maxLength || 
            value.length <= maxLength
        ) {
            // return input value if conditions not met
            return value || "";
        } 

        // truncate with suffix
        const truncateLength = maxLength - suffix.length;
        return value.slice(0, truncateLength) + suffix;
    }
}

import {
    CellFormatterService,
    TableOptions,
    FormatterOptions
} from '../common/datatable';
import { TranslationService } from '../services/translation.service';
import { AuditModifiedFieldComponent } from './audit-modified-fields/audit-modified-field.component';
import { AuditClassFactory } from './audit-class-maker';

export class AuditTaskOutputsTableOptions {
    options: TableOptions;

    constructor(
        private cellFormatterService: CellFormatterService,
        private translationService: TranslationService
    ) {
        this.options = this.getDefaultOptions();
    }

    getDefaultOptions(): TableOptions {
        return {
            csvFileName: 'task-outputs-audit.csv',
            enableDetailColumn: false,
            enableDraggable: false,
            enableSelectable: false,
            enableAuditColumns: false,
            columns: [
                {
                    displayName: 'Type',
                    field: 'UpdateType',
                    cellClass:AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Modified Fields',
                    field: 'ModifiedFields',
                    sortField: 'ModifiedFields',
                    rendererComponent: AuditModifiedFieldComponent,
                    exportFormatter: this.cellFormatterService.auditModifiedFieldsExportFormatter,
                    cellClass:AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Date',
                    field: 'ModifiedDate',
                    formatter: this.cellFormatterService.dateTimeUTCFormatter,
                    cellClass:AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Modified By',
                    field: 'ModifiedBy',
                    formatter: this.cellFormatterService.userNameFormatter,
                    cellClass:AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Output Name',
                    field: 'OutputName',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('OutputName', 'OutputName'),
                    cellClass: AuditClassFactory.createCellClass('outputname')
                },
                {
                    displayName: 'Task',
                    field: 'Task',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('Task', 'Task'),
                    cellClass: AuditClassFactory.createCellClass('task')
                },
                {
                    displayName: 'Active',
                    field: 'IsActive',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('IsActive', 'IsActive'),
                    cellClass: AuditClassFactory.createCellClass('isactive')
                },                
                {
                    displayName: 'Data Type',
                    field: 'DataType',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('DataType', 'DataType'),
                    cellClass: AuditClassFactory.createCellClass('datatype')
                },
                {
                    displayName: 'Minimum',
                    field: 'ValidationMin',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('ValidationMin', 'ValidationMin'),
                    cellClass: AuditClassFactory.createCellClass('validationmin'),
                    visible: false
                },
                {
                    displayName: 'Maximum',
                    field: 'ValidationMax',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('ValidationMax', 'ValidationMax'),
                    cellClass: AuditClassFactory.createCellClass('validationmax'),
                    visible: false
                },
                {
                    displayName: 'Cohort Stats Flag',
                    field: 'HasCohortStatsFlag',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('HasCohortStatsFlag', 'HasCohortStatsFlag'),
                    cellClass: AuditClassFactory.createCellClass('hascohortstatsflag'),
                    visible: false
                },
                {
                    displayName: 'Decimal Places',
                    field: 'DecimalPlaces',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('DecimalPlaces', 'DecimalPlaces'),
                    cellClass: AuditClassFactory.createCellClass('decimalplaces'),
                    visible: false
                },
                {
                    displayName: 'Number of Rows',
                    field: 'TextLineCount',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('TextLineCount', 'TextLineCount'),
                    cellClass: AuditClassFactory.createCellClass('textlinecount'),
                    visible: false
                },
                {
                    displayName: 'Enumeration',
                    field: 'Enumeration',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('Enumeration', 'Enumeration'),
                    cellClass: AuditClassFactory.createCellClass('enumeration'),
                    visible: false
                },
                {
                    displayName: 'Vocabulary',
                    field: 'Vocabulary',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('Vocabulary', 'Vocabulary'),
                    cellClass: AuditClassFactory.createCellClass('vocabulary'),
                    visible: false
                },
                {
                    displayName: 'Formula',
                    field: 'Formula',
                    formatter: (row: any, value: any, formatterOptions: FormatterOptions) => {
                        return this.cellFormatterService.formulaCalculator(row, value, formatterOptions);
                    },
                    cellClass: AuditClassFactory.createCellClass('formula'),
                    visible: false
                },
                {
                    displayName: 'Inherited From Task',
                    field: 'InheritedFromTask',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('InheritedFromTask', 'InheritedFromTask'),
                    cellClass: AuditClassFactory.createCellClass('inheritedfromtask'),
                    visible: false
                },
                {
                    displayName: 'Inherited From Output',
                    field: 'InheritedFromOutput',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('InheritedFromOutput', 'InheritedFromOutput'),
                    cellClass: AuditClassFactory.createCellClass('inheritedfromoutput'),
                    visible: false
                },
                {
                    displayName: 'Sort Order',
                    field: 'SortOrder',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('SortOrder', 'SortOrder'),
                    cellClass: AuditClassFactory.createCellClass('sortorder'),
                    visible: false
                },
                {
                    displayName: 'Created By',
                    field: 'CreatedBy',
                    formatter: this.cellFormatterService.userNameFormatter,
                    cellClass:AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Created Date',
                    field: 'DateCreated',
                    formatter: this.cellFormatterService.dateTimeUTCFormatter,
                    cellClass:AuditClassFactory.createDeletedClass,
                }
            ]
        };
    }
}

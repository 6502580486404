import type { Job } from './job.interface';
import type { MaterialPool } from './material-pool.interface';

export interface JobMaterialPool {
    C_JobMaterialPool_key: number;
    C_Job_key: number;
    C_MaterialPool_key: number;
    C_Workgroup_key: number;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    Job: Job;
    MaterialPool: MaterialPool;
    ModifiedBy: string;
    Version: number;
}

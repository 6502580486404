import { Component, EventEmitter, Input, Output, OnInit, } from '@angular/core';

@Component({
    selector: 'import-detail',
    templateUrl: './import-detail.component.html'
})
export class ImportDetailComponent implements OnInit {
    @Input() importLog: any;
    @Output() cancel: EventEmitter<any> = new EventEmitter<any>();
    resultDetailArray: string[] = [];
   

    ngOnInit() {
        // TODO
        this.resultDetailArray = this.importLog.ResultDetails.split(",");       
    }

    cancelClicked() {
        this.cancel.emit();
    }

    // TODO: A lot, but mainly listing all objects tied to import
}

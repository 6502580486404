<div class="facet">
    <facet-header caption="Settings"
        [facetId]="facetId">
        <button data-automation-id="refresh-data-button"
                climbFacetAction
                (click)="refreshData()"
                title="Refresh Data">
            <svg [climbIcon]="icons.arrowClockwise"></svg>
        </button>
    </facet-header>

    <div class="facet-body" style="overflow-y:hidden !important;"
         *ngIf="facet.Privilege !== 'None'">

        <loading-overlay *ngIf="loading"></loading-overlay>

        <climb-facet-toolbar>
            <button *ngIf="facet.Privilege === 'ReadWrite'"
                    climbSaveButton
                    [disabled]="detailToolbar.isSaveDisabled"
                    (click)="onSave()">
            </button>
        </climb-facet-toolbar>
        <div class="detail-header d-none">
            <detail-toolbar #detailToolbar
                            [showListViewButton]="false"
                            [facetPrivilege]="facet.Privilege"
                            [logTag]="COMPONENT_LOG_TAG">
            </detail-toolbar>
        </div>
        <div style="overflow: auto;
                    height: calc(100% - 40px) !important;">
            <form class="facet-form">
                <fieldset [disabled]="facet.Privilege === 'ReadOnly'">

                    <!-- Settings -->
                    <div class="settings-container form-label-right"
                         *ngIf="defaultHealthTechSetting">

                        <div class="form-group row mx-0">
                            <label class="col-md-3 col-form-label">
                                Default Health
                                <span class="text-nowrap">
                                    Tech
                                    <info-tooltip [text]="'You can add user resources via the Resources facet.'"></info-tooltip>
                                </span>
                            </label>
                            <div class="col-md-9">
                                <active-vocab-select [(model)]="defaultHealthTechSetting.C_DefaultHealthTechnician_key"
                                                     [vocabChoices]="resources"
                                                     [keyFormatter]="resourceKeyFormatter"
                                                     [optionFormatter]="resourceNameFormatter"
                                                     [nullable]="true">
                                </active-vocab-select>
                            </div>
                        </div>

                    </div>

                    <!-- Name Formats -->
                    <div class="row mx-0">
                        <div class="col-md-12">
                            <h4>Name Formats</h4>

                            <table class="table table-bordered">
                                <thead>
                                    <tr>
                                        <th>Enabled</th>
                                        <th>Type</th>
                                        <th>Prefix</th>
                                        <th>Number</th>
                                        <th>Suffix</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let nameFormat of nameFormats; let i = index">
                                        <td class="text-center">
                                            <input type="checkbox"
                                                   [name]="'isActive'+i"
                                                   class="mt-1"
                                                   [(ngModel)]="nameFormat.IsActive" />
                                        </td>
                                        <th scope="row">
                                            <p class="form-control-plaintext">
                                                {{nameFormat.FormatName | translate}}
                                            </p>
                                        </th>
                                        <td>
                                            <active-vocab-select *ngIf="nameFormat.FormatName.toLowerCase() === 'job'"
                                                                 [(model)]="nameFormat.C_JobPrefixField_key"
                                                                 [vocabChoices]="jobPrefixFields"
                                                                 [keyFormatter]="jobPrefixFieldKeyFormatter"
                                                                 [optionFormatter]="jobPrefixFieldFormatter"
                                                                 [nullable]="false"
                                                                 [defaultValue]="defaultJobPrefixFieldKey"
                                                                 [loadDefaultValue]="true">
                                            </active-vocab-select>
                                            <active-vocab-select *ngIf="nameFormat.FormatName.toLowerCase() === 'animal'"
                                                                 [(model)]="nameFormat.C_AnimalPrefixField_key"
                                                                 [vocabChoices]="animalPrefixFields"
                                                                 [keyFormatter]="animalPrefixFieldKeyFormatter"
                                                                 [optionFormatter]="animalPrefixFieldFormatter"
                                                                 [nullable]="false"
                                                                 [defaultValue]="defaultAnimalPrefixFieldKey"
                                                                 [loadDefaultValue]="true">
                                            </active-vocab-select>
                                            <active-vocab-select *ngIf="nameFormat.FormatName.toLowerCase() === 'order'"
                                                                 [(model)]="nameFormat.C_OrderPrefixField_key"
                                                                 [vocabChoices]="orderPrefixFields"
                                                                 [keyFormatter]="orderPrefixFieldKeyFormatter"
                                                                 [optionFormatter]="orderPrefixFieldFormatter"
                                                                 [nullable]="false"
                                                                 [defaultValue]="defaultOrderPrefixFieldKey"
                                                                 [loadDefaultValue]="true">
                                            </active-vocab-select>

                                            <input *ngIf="(!nameFormat.cv_JobPrefixField || nameFormat.cv_JobPrefixField.JobPrefixField.toLowerCase() === 'text')
                                                   && (!nameFormat.cv_AnimalPrefixField || nameFormat.cv_AnimalPrefixField.AnimalPrefixField.toLowerCase() === 'text')
                                                   && (!nameFormat.cv_OrderPrefixField || nameFormat.cv_OrderPrefixField.OrderPrefixField.toLowerCase() === 'text')"
                                                   type="text"
                                                   [name]="'prefix'+i"
                                                   [(ngModel)]="nameFormat.Prefix"
                                                   class="form-control input-medium" />
                                        </td>
                                        <td>
                                            <ng-container *ngIf="nameFormat.FormatName === 'Animal' || nameFormat.FormatName === 'Sample'">
                                                <label>
                                                    <input type="radio"
                                                           class="mt-1"
                                                           [name]="'numberChoice'+i"
                                                           [(ngModel)]="nameFormat.UseIdForCounter"
                                                           [value]="true">
                                                    Use {{nameFormat.FormatName}} ID
                                                    <info-tooltip [text]="'Use '+ nameFormat.FormatName + ' Identifier as unique counter'"></info-tooltip>
                                                </label>
                                                <br>
                                                <label>
                                                    <input type="radio"
                                                           [name]="'numberChoice'+i"
                                                           [(ngModel)]="nameFormat.UseIdForCounter"
                                                           [value]="false">
                                                    Counter
                                                </label>
                                            </ng-container>

                                            <input type="number"
                                                   step="any"
                                                   [name]="'counter'+i"
                                                   [(ngModel)]="nameFormat.Counter"
                                                   [disabled]="nameFormat.UseIdForCounter"
                                                   [required]="!nameFormat.UseIdForCounter"
                                                   class="form-control input-medium" />
                                        </td>
                                        <td>
                                            <active-vocab-select *ngIf="nameFormat.FormatName.toLowerCase() === 'job'"
                                                                 [(model)]="nameFormat.C_JobSuffixField_key"
                                                                 [vocabChoices]="jobSuffixFields"
                                                                 [keyFormatter]="jobSuffixFieldKeyFormatter"
                                                                 [optionFormatter]="jobSuffixFieldFormatter"
                                                                 [nullable]="false"
                                                                 [defaultValue]="defaultJobSuffixFieldKey"
                                                                 [loadDefaultValue]="true">
                                            </active-vocab-select>
                                            <active-vocab-select *ngIf="nameFormat.FormatName.toLowerCase() === 'order'"
                                                                 [(model)]="nameFormat.C_OrderSuffixField_key"
                                                                 [vocabChoices]="orderSuffixFields"
                                                                 [keyFormatter]="orderSuffixFieldKeyFormatter"
                                                                 [optionFormatter]="orderSuffixFieldFormatter"
                                                                 [nullable]="false"
                                                                 [defaultValue]="defaultOrderSuffixFieldKey"
                                                                 [loadDefaultValue]="true">
                                            </active-vocab-select>
                                            <input *ngIf="(!nameFormat.cv_JobSuffixField && !nameFormat.cv_OrderSuffixField)  || nameFormat.cv_JobSuffixField?.JobSuffixField.toLowerCase() == 'text'"
                                                   type="text"
                                                   [name]="'suffix'+i"
                                                   [(ngModel)]="nameFormat.Suffix"
                                                   class="form-control input-medium" />

                                            <input *ngIf=" nameFormat.cv_OrderSuffixField?.OrderSuffixField.toLowerCase() === 'text'"
                                                   type="text"
                                                   [name]="'suffix'+i"
                                                   [(ngModel)]="nameFormat.Suffix"
                                                   class="form-control input-medium" />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div class="row-mx-0">
                        <div class="col-md-12">
                            <workgroup-animal-characteristics [readonly]="facet.Privilege === 'ReadOnly'">
                            </workgroup-animal-characteristics>
                        </div>
                    </div>

                    <div *ngIf="isGLP" class="row mx-0">
                        <div class="col-md-12 form-label-right">
                            <h4>Timeouts</h4>
                            <div class="form-group row">
                                <label class="col-md-3 col-form-label">
                                    Inactivity Timeout
                                    <info-tooltip [text]="'Must be between 5 and 60 minutes.'"></info-tooltip>
                                </label>

                                <div class="col-md-9">
                                    <input *ngIf="inactivityTimeoutSetting"
                                           type="number"
                                           name="inactivityTimeout"
                                           min="5"
                                           max="60"
                                           [(ngModel)]="inactivityTimeoutSetting.TimeoutMinutes" />
                                    <span style="display:inline-block">Minutes</span>
                                </div>
                            </div>
                        </div>
                    </div>

                </fieldset>
                <div class="row mx-0">
                    <div class="col-md-12">
                        <facet-settings [readonly]="facet.Privilege === 'ReadOnly'"></facet-settings>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

/**
 * Return sourceString with newValue appended to comma
 *   separated list
 * @param sourceString
 * @param newValue
 */
export function concatToStringList(sourceString: string, newValue: string | number): string {
    const delimiter = ',';
    let sourceArray: any[] = [];
    if (sourceString) {
        sourceArray = sourceString.split(delimiter);
    }
    sourceArray.push(newValue);

    return sourceArray.join(delimiter);
}

<form>
    <loading-overlay *ngIf="loading"></loading-overlay>

    <div class="modal-header justify-content-center">
        <h4 class="modal-title">{{MODAL_TITLE}}</h4>
    </div>

    <div class="modal-body">
        <new-message [defaultValues]="defaultMessage"
                     (save)="sendClicked()"
                     (cancel)="cancelClicked()"
                     *ngIf="!loading"></new-message>
    </div>
</form>

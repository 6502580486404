<table class="table table-bordered">
    <thead>
        <tr class="detail-grid-header"
            droppable
            [dropDisabled]="readonly"
            (drop)="onDropCohortToJob()"
            [attr.title]="readonly ? '' : 'Drop cohorts here'">
            <th [attr.colspan]="readonly ? 3 : 4">
                <span class="float-right">
                    <a class="detail-grid-button"
                       (click)="pasteCohortsIntoJob()"
                       *ngIf="!readonly">
                        <i data-automation-id="paste-cohorts-button"
                           class="fa fa-paste" 
                           title="Paste cohorts"></i>
                    </a>
                    <a class="detail-grid-button"
                       (click)="copyCohorts()">
                        <i class="fa fa-copy" title="Copy selected cohorts"></i>
                    </a>
                </span>
                Cohorts
                <ng-container *ngIf="cohorts && cohorts.length > 0">
                    ({{cohorts.length}})
                    <a (click)="job.cohortsExpanded = !job.cohortsExpanded"
                       class="cohort-toggle-link">
                        <i class="fa"
                           [ngClass]="job.cohortsExpanded ? 'fa-chevron-up' : 'fa-chevron-down'"
                           [title]="job.cohortsExpanded ? 'Hide Cohorts' : 'Show Cohorts'"
                           aria-hidden="true"></i>
                    </a>
                </ng-container>
            </th>
        </tr>
        <tr *ngIf="(cohorts && cohorts.length > 0) &&
                    job.cohortsExpanded"
            class="detail-grid-header">

            <!-- Select -->
            <th class="icon-cell">
                <input type="checkbox"
                       (click)="selectAllCohorts($event)"
                       title="Select/clear all" />
            </th>

            <!-- Remove -->
            <th *ngIf="!readonly">
                &nbsp;
            </th>

            <th>
                Cohort
            </th>
            <th>
                Animals in {{'Job' | translate}}
            </th>
        </tr>
    </thead>
    <tbody *ngIf="job.cohortsExpanded">
        <tr *ngFor="let cohort of cohorts | orderBy:'CohortName'; let cohortIndex = index">
            <ng-container *ngIf="cohort">

                <!-- Select -->
                <td class="icon-cell">
                    <input type="checkbox"
                           name="cohortIsSelected{{cohortIndex}}"
                           [(ngModel)]="cohort.isSelected" />
                </td>

                <!-- Remove -->
                <td class="icon-cell"
                    *ngIf="!readonly">
                    <a (click)="removeCohort(cohort)">
                        <i class="fa fa-remove remove-item"
                           title="Remove cohort and its animals"></i>
                    </a>
                </td>

                <td class="col-size-fill">
                    <div style="cursor:move;"
                         data-drag-type="cohort"
                         draggable
                         (dragStart)="dragCohortsStart()"
                         (dragStop)="dragCohortsStop()"
                         id="{{cohort.C_Cohort_key}}">{{cohort.CohortName}}</div>
                </td>
                <td class="num-cell col-size-fit">
                    {{getTotalCount(cohort.C_Cohort_key)}}/{{getCountInJob(cohort.C_Cohort_key)}}
                </td>
            </ng-container>
        </tr>
    </tbody>
</table>

import {
    CellFormatterService,
    FormatterOptions,
    TableOptions
} from '../common/datatable';
import { AuditModifiedFieldComponent } from './audit-modified-fields/audit-modified-field.component';
import { AuditClassFactory } from './audit-class-maker';

export class AuditAnimalClinicalObservationsTableOptions {
    options: TableOptions;

    constructor(
        private cellFormatterService: CellFormatterService,
    ) {
        this.options = this.getDefaultOptions();
    }
    getDefaultOptions(): TableOptions {
        return {
            csvFileName: 'animalClinicalObservations-audit.csv',
            enableDetailColumn: false,
            enableDraggable: false,
            enableSelectable: false,
            enableAuditColumns: false,
            columns: [
                {
                    displayName: 'Type',
                    field: 'UpdateType',
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Modified Fields',
                    field: 'ModifiedFields',
                    sortField: 'ModifiedFields',
                    rendererComponent: AuditModifiedFieldComponent,
                    exportFormatter: this.cellFormatterService.auditModifiedFieldsExportFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Date',
                    field: 'ModifiedDate',
                    formatter: this.cellFormatterService.dateTimeUTCFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Modified By',
                    field: 'ModifiedBy',
                    formatter: this.cellFormatterService.userNameFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },   
                {
                    displayName: 'Animal ID',
                    field: 'AnimalID',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('AnimalID', 'AnimalID'),
                    cellClass: AuditClassFactory.createCellClass('animalid'),
                },
                {
                    displayName: 'Animal Name',
                    field: 'AnimalName',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('AnimalName', 'AnimalName'),
                    cellClass: AuditClassFactory.createCellClass('animalname'),
                },
                {
                    displayName: 'Observed Date',
                    field: 'DateObserved',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('DateObserved', 'DateObserved', this.cellFormatterService.dateFormatter),
                    cellClass: AuditClassFactory.createCellClass('dateobserved'),
                },
                {
                    displayName: 'Observed By',
                    field: 'ResourceName',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('ResourceName', 'ResourceName'),
                    cellClass: AuditClassFactory.createCellClass('resourcename'),
                },
                {
                    displayName: 'Observation Status',
                    field: 'ClinicalObservationStatus',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('ClinicalObservationStatus', 'ClinicalObservationStatus'),
                    cellClass: AuditClassFactory.createCellClass('clinicalobservationstatus'),
                },
                {
                    displayName: 'Comments',
                    field: 'Comments',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('Comments', 'Comments'),
                    cellClass: AuditClassFactory.createCellClass('comments'),
                },
                {
                    displayName: 'Created By',
                    field: 'CreatedBy',
                    formatter: this.cellFormatterService.userNameFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Created Date',
                    field: 'DateCreated',
                    formatter: this.cellFormatterService.dateTimeUTCFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                }
            ]
        };
    }
}

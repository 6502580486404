<div class="bulk-edit-wrapper">
    <bulk-edit-toolbar [logTag]="COMPONENT_LOG_TAG"
                       (exit)="exitClicked()"
                       (save)="saveClicked()"
                       [options]="bulkTemplates.bulkOptions"
                       [facet]="facet"
                       [inactiveFields]="inactiveFields">
        <span additional-groups>
            <button type="button"
                    class="btn btn-secondary"
                    title="Print cage cards"
                    (click)="openCageCardModal(cagecardmodal)"
                    [appInsightsClick]="'click-animal-bulkedit-cagecards'">
                <i class="fa fa-print" aria-hidden="true"></i>
                <span class="gridster-d-sm-none gridster-d-md-inline-block">Cards</span>
                <span class="sr-only">Print Cage Card</span>
            </button>
        </span>
    </bulk-edit-toolbar>

    <div class="bulk-edit-grid">
        <loading-overlay *ngIf="saving" [message]="savingMessage"></loading-overlay>
        <bulk-edit-table [options]="bulkTemplates.bulkOptions"
                         [bulkEditCustomButtons]="animalCommentsBulkActionsButtons"
                         [facet]="facet"
                         (updateCustomButtonClicked)="updateAnimals($event)"
                         [itemsToEdit]="animals">
        </bulk-edit-table>
    </div>
</div>

<ng-template #cagecardmodal let-c="close">
    <cage-card-chooser [animals]="animals"
                       (close)="c('Close')">
    </cage-card-chooser>
</ng-template>

<animal-bulk-templates #bulkTemplates
                       [animals]="animals"
                       [bulkFillAnimalCommentFieldConfig]="bulkFillAnimalCommentFieldConfig"
                       (onAnimalCommentsUpdate)="onAnimalCommentsControlsStatusUpdate()"
                       [loadDefaults]="false"
                       [activeFields]="activeFields"
                       [requiredFields]="requiredFields">
</animal-bulk-templates>

<div class="modal-header justify-content-center">
    <h4 class="modal-title">{{ modalTitle }}</h4>
</div>

<div class="modal-body">
    <form role="form" (keyup.enter)="okClicked()" class="form-label-right">
        <div class="form-group row">
            <label class="col-md-4 col-form-label">Name</label>
            <div class="col-md-8 display-detail">
                <span *ngIf="mode==='view'">{{ fileDefinition?.DefinitionNameTranslated }}</span>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-4 col-form-label">Import File Type</label>
            <div class="col-md-8 display-detail">
                <span *ngIf="mode==='view'">{{ fileDefinition?.cv_ImportFileType?.ImportFileTypeTranslated }}</span>
            </div>
        </div>

        <!-- To be displayed later when we have more than one format -->
        <!--<div class="form-group">
            <label class="col-md-3 col-form-label">Import File Format</label>
            <div class="col-md-9" style="padding-top: 5px;">
                <span *ngIf="mode==='view'">{{ fileDefinition?.cv_ImportFileFormat?.ImportFileFormat }}</span>
            </div>
        </div>-->

        <div class="row component-table-wrapper"
             *ngIf="mode === 'view'">
            <div class="col-md-12">
                <table class="table">
                    <thead>
                        <tr>
                            <th>Column</th>
                            <th class="text-nowrap pr-2">Data Type</th>
                            <th>Description</th>
                            <th>Required</th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let component of componentList">
                            <tr *ngFor="let target of component.Targets; let firstTarget = first">
                                <th scope="row"
                                    class="component-cell text-nowrap"
                                    [attr.rowspan]="component?.Targets?.length"
                                    *ngIf="firstTarget">
                                    <code>{{component?.ComponentNameTranslated}}</code>
                                </th>
                                <td class="datatype-cell">
                                    {{target?.Target?.DataTypeDisplay}}
                                </td>
                                <td class="description-cell">
                                    {{target?.Target?.Description}}
                                </td>
                                <td class="description-cell">
                                    <span *ngIf="target?.Target?.Required">{{target?.Target?.Required}}</span>
                                    <span *ngIf="target?.Target?.RequiredDescription !== null">* true</span>
                                    <p *ngIf="target?.Target?.RequiredDescription !== null">{{target?.Target?.RequiredDescription}}</p>
                                </td>
                            </tr>
                        </ng-container>
                        <tr *ngIf="characteristicTargets && characteristicTargets.length > 0">
                            <th class="text-nowrap">
                                Workgroup Characteristics
                            </th>
                        </tr>

                        <tr *ngFor="let characteristic of characteristicTargets;let i=index">

                            <th class="component-cell text-nowrap">
                                <code>{{characteristic.DisplayName}}</code>
                            </th>
                            <td class="datatype-cell">
                                dynamic
                            </td>
                            <td class="datatype-cell">
                                {{characteristic.Description}}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </form>
</div>

<div class="modal-footer">
    <button type="button" class="btn btn-fw-wide btn-lg btn-primary"
            *ngIf="mode==='create' || mode==='edit'"
            (click)="okClicked()">
        Submit Changes
    </button>
    <button type="button"
            class="btn btn-lg btn-secondary"
            (click)="closeClicked()">
        Close
    </button>
</div>

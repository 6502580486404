import {
    CellFormatterService,
    FormatterOptions,
    TableOptions
} from '../common/datatable';
import { TranslationService } from '../services/translation.service';
import { AuditModifiedFieldComponent } from './audit-modified-fields/audit-modified-field.component';
import { AuditClassFactory } from './audit-class-maker';

export class AuditStudyLinesTableOptions {

    options: TableOptions;

    constructor(
        private cellFormatterService: CellFormatterService,
        private translationService: TranslationService
    ) {
        this.options = this.getDefaultOptions();
    }

    getDefaultOptions(): TableOptions {

        return {
            csvFileName: this.translationService.translate('study') + '-' + this.translationService.translate('lines') + '-audit.csv',
            enableDetailColumn: false,
            enableDraggable: false,
            enableSelectable: false,
            enableAuditColumns: false,
            columns: [
                {
                    displayName: 'Type',
                    field: 'UpdateType',
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Modified Fields',
                    field: 'ModifiedFields',
                    sortField: 'ModifiedFields',
                    rendererComponent: AuditModifiedFieldComponent,
                    exportFormatter: this.cellFormatterService.auditModifiedFieldsExportFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Date',
                    field: 'ModifiedDate',
                    formatter: this.cellFormatterService.dateTimeUTCFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Modified By',
                    field: 'ModifiedBy',
                    formatter: this.cellFormatterService.userNameFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: this.translationService.translate('Study'),
                    field: 'StudyName',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('StudyName', (this.translationService.translate('Study') + 'Name')),
                    cellClass: AuditClassFactory.createCellClass((this.translationService.translate('Study') + 'Name').toLowerCase()),
                },
                {
                    displayName: this.translationService.translate('Line'),
                    field: 'LineName',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('LineName', this.translationService.translate('Line') + 'Name'),
                    cellClass: AuditClassFactory.createCellClass((this.translationService.translate('Line') + 'Name').toLowerCase()),
                },
                {
                    displayName: 'Animal Count',
                    field: 'AnimalCount',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('AnimalCount', 'AnimalCount'),
                    cellClass: AuditClassFactory.createCellClass('animalcount'),
                },
                {
                    displayName: 'Created By',
                    field: 'CreatedBy',
                    formatter: this.cellFormatterService.userNameFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Created Date',
                    field: 'DateCreated',
                    formatter: this.cellFormatterService.dateTimeUTCFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                }
            ]
        };
    }
}

import type { Cohort } from './cohort.interface';
import type { TaskCohortInput } from './task-cohort-input.interface';
import type { TaskInstance } from './task-instance.interface';

export interface TaskCohort {
    C_Cohort_key: number;
    C_TaskCohort_key: number;
    C_TaskInstance_key: number;
    C_Workgroup_key: number;
    Cohort: Cohort;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    ModifiedBy: string;
    Sequence: number;
    TaskCohortInput: TaskCohortInput[];
    TaskInstance: TaskInstance;
    Version: number;
}

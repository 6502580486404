import { map } from 'rxjs/operators';
import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
    ViewChildren
} from '@angular/core';
import { NgForm, NgModel } from '@angular/forms';

import { LocationService } from '../../locations/location.service';
import { NamingService } from '../../services/naming.service';
import { SampleService } from '../sample.service';
import { SampleLogicService } from '../sample-logic.service';
import { SampleVocabService } from '../sample-vocab.service';
import { ViewSampleAuditReportComponentService } from '../audit';

import {
    BaseDetail,
    BaseDetailService,
    FacetView,
    IFacet,
    PageState,
} from '../../common/facet';

import { IValidatable, SaveChangesService, UnsavedChanges } from '../../services/save-changes.service';
import { DotmaticsService } from '../../dotmatics/dotmatics.service';
import { SettingService } from '../../settings/setting.service';
import { FeatureFlagService } from '../../services/feature-flags.service';
import { dateControlValidator } from '@common/util/date-control.validator';
import { CharacteristicInputComponent } from 'src/app/characteristics/characteristic-input/characteristic-input.component';
import { LocationHistoryComponent } from 'src/app/locations/location-history.component';

@Component({
    selector: 'sample-detail',
    templateUrl: './sample-detail.component.html'
})
export class SampleDetailComponent extends BaseDetail implements OnChanges, OnInit, OnDestroy, IValidatable {
    @ViewChildren('characteristicInput') characteristicInputs: CharacteristicInputComponent[];
    @ViewChildren('locationHistory') locationHistories: LocationHistoryComponent[];
    @ViewChildren('dateControl') dateControls: NgModel[];
    @Input() facet: IFacet;
    @Input() sample: any;
    @Input() pageState: PageState;
    @Input() requiredFields: string[] = [];
    @Input() activeFields: string[] = [];

    @Output() exit: EventEmitter<void> = new EventEmitter<void>();
    @Output() next: EventEmitter<void> = new EventEmitter<void>();
    @Output() previous: EventEmitter<void> = new EventEmitter<void>();

    @ViewChild("sampleForm") sampleForm: NgForm;

    facetView: FacetView = FacetView.DETAIL_VIEW;

    // CVs
    containerTypes: any[];
    materialOrigins: any[];
    preservationMethods: any[];
    sampleStatuses: any[];
    sampleTypes: any[];
    timeUnits: any[];
    units: any[];
    sampleSubtypes: any[];
    sampleProcessingMethods: any[];
    sampleAnalysisMethods: any[];
    sampleOrders: any[];

    // State
    sampleNamingActive = false;

    readonly COMPONENT_LOG_TAG = 'sample-detail';

    // Dotmatics workgroup flag
    isDotmatics: boolean;

    isGLP = false;

    constructor(
        private baseDetailService: BaseDetailService,
        private locationService: LocationService,
        private namingService: NamingService,
        private sampleService: SampleService,
        private sampleLogicService: SampleLogicService,
        private sampleVocabService: SampleVocabService,
        private viewSampleAuditReportComponentService: ViewSampleAuditReportComponentService,
        private saveChangesService: SaveChangesService,
        private dotmaticsService: DotmaticsService,
        private settingService: SettingService,
        private featureFlagService: FeatureFlagService,
    ) {
        super(baseDetailService);

    }

    // lifecycle
    ngOnInit() {
        this.saveChangesService.registerValidator(this);
        this.initialize();
    }

    ngOnChanges(changes: any) {
        if (changes.sample) {
            if (this.sample && !changes.sample.firstChange) {
                if (this.sampleForm) {
                    this.sampleForm.form.markAsPristine();
                }
                this.initialize();
            }
        }
    }

    ngOnDestroy(): void {
        this.saveChangesService.unregisterValidator(this);
    }

    initialize() {
        this.setLoading(true);
        this.setIsDotmatics();
        this.initIsGLP();

        this.getCVs().then(() => {
            this.getSampleOrders();
            return this.isNamingActive();
        }).then(() => {
            return this.getDetails();
        }).then(() => {
            this.setLoading(false);
        }).catch((error) => {
            this.setLoading(false);
            throw error;
        });
    }

    private getCVs(): Promise<any> {
        const p1: Promise<any> = this.sampleVocabService.containerTypes$.pipe(map((data) => {
            this.containerTypes = data;
        })).toPromise();

        const p2: Promise<any> = this.sampleVocabService.materialOrigins$.pipe(map((data) => {
            this.materialOrigins = data;
        })).toPromise();

        const p3: Promise<any> = this.sampleVocabService.preservationMethods$.pipe(map((data) => {
            this.preservationMethods = data;
        })).toPromise();

        const p4: Promise<any> = this.sampleVocabService.sampleStatuses$.pipe(map((data) => {
            this.sampleStatuses = data;
        })).toPromise();

        const p5: Promise<any> = this.sampleVocabService.sampleTypes$.pipe(map((data) => {
            this.sampleTypes = data;
        })).toPromise();

        const p6: Promise<any> = this.sampleVocabService.units$.pipe(map((data) => {
            this.units = data;
        })).toPromise();

        const p7: Promise<any> = this.sampleVocabService.timeUnits$.pipe(map((data) => {
            this.timeUnits = data;
        })).toPromise();

        const p8: Promise<any> = this.sampleVocabService.sampleSubtypes$.pipe(map((data) => {
            this.sampleSubtypes = data;
        })).toPromise();

        const p9: Promise<any> = this.sampleVocabService.sampleProcessingMethods$.pipe(map((data) => {
            this.sampleProcessingMethods = data;
        })).toPromise();

        const p10: Promise<any> = this.sampleVocabService.sampleAnalysisMethods$.pipe(map((data) => {
            this.sampleAnalysisMethods = data;
        })).toPromise();

        return Promise.all([p1, p2, p3, p4, p5, p6, p7, p8, p9, p10]);
    }

    private isNamingActive(): Promise<any> {
        return this.namingService.isSampleNamingActive()
            .then((active: boolean) => {
                this.sampleNamingActive = active;
            });
    }

    private getDetails(): Promise<any> {
        if (this.sample && this.sample.C_Material_key > 0) {
            const expands: string[] = [];

            return this.sampleService.getSample(this.sample.C_Material_key, expands)
                .then(() => {
                    return this.getSampleCharacteristics();
                });
        } else {
            return Promise.resolve(this.sample);
        }
    }

    private getSampleCharacteristics(): Promise<any> {
        if (!this.sample.SampleCharacteristics) {
            return this.sampleLogicService.loadCharacteristics(this.sample);
        } else {
            return Promise.resolve();
        }
    }

    getSampleOrders() {
        this.sampleService.getSampleOrders().then((data) => {
            this.sampleOrders = data;
        });
    }

    // Model change events
    onLineSelection(lineKey: number): Promise<any> {
        return this.sampleLogicService.onLineSelection(lineKey, this.sample);
    }

    onSourceSelection(sourceMaterial: any) {
        return this.sampleLogicService.onSourceSelection(this.sample, sourceMaterial);
    }
    
    // Location
    createMaterialLocation(): any {
        this.locationService.getDefaultLocation().then((defaultLocation) => {
            const initialValues = {
                C_LocationPosition_key: defaultLocation.C_LocationPosition_key,
                C_Material_key: this.sample.C_Material_key
            };

            return this.locationService.createMaterialLocation(initialValues);
        });
    }

    removeLocation(materialLocation: any) {
        this.locationService.deleteMaterialLocation(materialLocation);
    }


    // Source
    getSourceAgeInWeeksAtHarvest(): string {
        return this.sampleLogicService.getSourceAgeInWeeksAtHarvest(this.sample);
    }


    // Audit
    viewAuditReport() {
        this.viewSampleAuditReportComponentService.openComponent(
            this.sample.C_Material_key
        );
    }

    onCancel() {
        this.sampleService.cancelSample(this.sample);
    }

    async validate(): Promise<string> {
        let message = '';
        message = await this.settingService.validateRequiredFields(this.requiredFields, this.sample, 'sample');

        return message;
    }

    async onSaveSample() {
        const errMsg = await this.validateSample();

        if (errMsg) {
            this.loggingService.logError(errMsg, 'Validation Error', this.COMPONENT_LOG_TAG, true);
            return;
        }

        await this.saveChangesService.saveChanges(this.COMPONENT_LOG_TAG);

        if (this.isDotmatics && this.sample.Material.MaterialExternalSync.length > 0) {
            this.dotmaticsService.updateDotmaticsSample(this.sample.C_Material_key);
        }
    }

    /**
     * Sets isDotmatics flag
     */
    private setIsDotmatics() {
        this.isDotmatics = this.dotmaticsService.setIsDotmatics();
    }

    private async validateSample(): Promise<string> {
        let errorMessage = this.characteristicInputs.map(input => input.validate()).find(msg => msg)
            || this.locationHistories.map(input => input.validate()).find(msg => msg)
            || dateControlValidator(this.dateControls)
            || await this.settingService.validateRequiredFields(this.requiredFields, this.sample, 'sample');

        if (!errorMessage && !this.locationService.locationsValid(this.sample.Material.MaterialLocation)) {
            errorMessage = 'Locations dates are not valid.';
        }

        return errorMessage;
    }

    initIsGLP() {
        this.isGLP = this.featureFlagService.isFlagOn('IsGLP');
    }

    onSwitchView(buttonName: string): any {
        if (this.saveChangesService.hasChanges) {
            // If there are unsaved changes, prompt the user to save or discard
            return this.viewUnsavedChangesModalService.openComponent().then((result: string) => {
                if (result === 'save') {
                    // Throw error & don't save if location dates are invalid
                    if (this.locationService.locationsValid(this.sample.Material.MaterialLocation)) {
                        return this.saveChangesService.saveChanges(this.COMPONENT_LOG_TAG).then(() => {
                            // Emits reload event when save a new record by clicking on next
                            if (buttonName === 'next') {
                                this.reload.emit();
                            }
                            this.emitViewChange(buttonName, UnsavedChanges.save);
                        });
                    } else {
                        this.loggingService.logError("Locations dates are not valid.", null, this.COMPONENT_LOG_TAG, true);
                    }
                } else {
                    if (this.dataContext.hasChanges()) {
                        this.onCancel();
                        this.loggingService.logFacetUndoSuccess(this.COMPONENT_LOG_TAG);
                    }
                    this.emitViewChange(buttonName, UnsavedChanges.discard);
                }
            });
        } else {
            this.emitViewChange(buttonName, UnsavedChanges.noChanges);
        }
    }

    private emitViewChange(buttonName: string, changes: UnsavedChanges) {
        switch (buttonName) {
            case 'previous':
                super.previousClicked(changes);
                this.previous.emit();
                break;
            case 'next':
                super.nextClicked(changes);
                this.next.emit();
                break;
            case 'exit':
                super.exitClicked(changes);
                this.exit.emit();
                break;
            default:
                break;
        }
    }


    // <select> formatters
    sampleTypeKeyFormatter = (value: any) => {
        return value.C_SampleType_key;
    }
    sampleTypeFormatter = (value: any) => {
        return value.SampleType;
    }
    sampleStatusKeyFormatter = (value: any) => {
        return value.C_SampleStatus_key;
    }
    sampleStatusFormatter = (value: any) => {
        return value.SampleStatus;
    }
    preservationMethodKeyFormatter = (value: any) => {
        return value.C_PreservationMethod_key;
    }
    preservationMethodFormatter = (value: any) => {
        return value.PreservationMethod;
    }
    materialOriginKeyFormatter = (value: any) => {
        return value.C_MaterialOrigin_key;
    }
    materialOriginFormatter = (value: any) => {
        return value.MaterialOrigin;
    }
    containerTypeKeyFormatter = (value: any) => {
        return value.C_ContainerType_key;
    }
    containerTypeFormatter = (value: any) => {
        return value.ContainerType;
    }
    timeUnitKeyFormatter = (value: any) => {
        return value.C_TimeUnit_key;
    }
    timeUnitFormatter = (value: any) => {
        return value.TimeUnit;
    }
    unitKeyFormatter = (value: any) => {
        return value.C_Unit_key;
    }
    unitFormatter = (value: any) => {
        return value.Unit;
    }
    sampleSubtypeKeyFormatter = (value: any) => {
        return value.C_SampleSubtype_key;
    }
    sampleSubtypeFormatter = (value: any) => {
        return value.SampleSubtype;
    }
    sampleProcessingMethodKeyFormatter = (value: any) => {
        return value.C_SampleProcessingMethod_key;
    }
    sampleProcessingMethodFormatter = (value: any) => {
        return value.SampleProcessingMethod;
    }
    sampleAnalysisMethodKeyFormatter = (value: any) => {
        return value.C_SampleAnalysisMethod_key;
    }
    sampleAnalysisMethodFormatter = (value: any) => {
        return value.SampleAnalysisMethod;
    }
}

<div class="bulk-edit-wrapper">

    <bulk-edit-toolbar [logTag]="COMPONENT_LOG_TAG"
                       (exit)="exitClicked()"
                       [options]="bulkTemplates.bulkOptions"
                       [facet]="facet"
                       #bulkEditToolbar>
    </bulk-edit-toolbar>

    <div class="bulk-edit-grid">
        <bulk-edit-table [options]="bulkTemplates.bulkOptions"
                         [facet]="facet"
                         [itemsToEdit]="samples"></bulk-edit-table>
    </div>
</div>
    
<sample-bulk-templates 
                       [samples]="samples" 
                       [requiredFields]="requiredFields"
                       [activeFields]="activeFields"
                       #bulkTemplates></sample-bulk-templates>

import { BulkEditCommService } from './../../common/facet/bulk-edit-comm.service';
import { BirthService } from './../birth.service';
import { DataManagerService } from '../../services/data-manager.service';
import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';

import { LoggingService } from '../../services/logging.service';
import { BulkEditToolbarComponent } from '../../common/toolbars';
import { BirthBulkTemplatesComponent } from './birth-bulk-templates.component';


@Component({
    selector: 'birth-bulk-edit',
    templateUrl: './birth-bulk-edit.component.html',
    providers: [
        BulkEditCommService
    ]
})
export class BirthBulkEditComponent implements OnInit {
    @Input() facet: any;
    @Input() births: any[];
    @Output() exit: EventEmitter<any> = new EventEmitter<any[]>();
    @ViewChild('bulkEditToolbar') bulkEditToolBar: BulkEditToolbarComponent;
    @ViewChild('bulkTemplates') bulkTemplates: BirthBulkTemplatesComponent;

    readonly COMPONENT_LOG_TAG = 'birth-bulk-edit';

    constructor(
        private birthService: BirthService,
        private loggingService: LoggingService,
        private dataManager: DataManagerService
    ) {
    }

    // lifecycle
    ngOnInit() {
        this.initialize();
    }

    initialize() {
         // Copy the input so we don't touch the grid data
        this.births = this.births.slice();
    }

    exitClicked() {
        if (this.dataManager.hasChanges()) {
            for (const birth of this.births) {
                this.birthService.cancelBirth(birth);
            }

            // Remove added but un-saved rows
            this.births = this.births.filter((birth) => {
                return birth.C_Birth_key > 0;
            });

            this.loggingService.logFacetUndoSuccess(this.COMPONENT_LOG_TAG);
        }

        this.exit.emit(this.births);
    }

    async validate(): Promise<string> {
        return this.bulkTemplates.validate();
    }
}

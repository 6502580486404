import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { ImportVocabService } from '../import-vocab.service';

@Component({
    selector: 'import-file-format-select',
    template: `
        <active-vocab-select [(model)]="model"
                             [vocabChoices]="importFileFormats"
                             [keyFormatter]="importFileFormatKeyFormatter"
                             [optionFormatter]="importFileFormatFormatter"
                             [readonly]="readOnly"
                             [nullable]="nullable"
                             (modelChange)="selectItemHandler()"></active-vocab-select>
`
})
export class ImportFileFormatSelectComponent implements OnInit {
    @Input() model: any;
    @Input() readOnly: boolean;
    @Input() nullable: boolean;

    @Output() modelChange: EventEmitter<any> = new EventEmitter<any>();

    importFileFormats: any[];

    constructor(private importVocabService: ImportVocabService) { }

    ngOnInit() {
        this.setupCVs();
    }

    setupCVs(): Promise<any> {
        const cv1 = this.importVocabService.getImportFileFormats()
            .then((importFileFormats: any[]) => {
                this.importFileFormats = importFileFormats;
            });
        return Promise.all([cv1]);
    }

    // Formatters for active vocab selects
    importFileFormatKeyFormatter = (item: any) => {
        return item.C_ImportFileFormat_key;
    }
    importFileFormatFormatter = (item: any) => {
        return item.ImportFileFormat;
    }

    selectItemHandler() {
        this.modelChange.emit(this.model);
    }
}

/* 
* Common functions for CSV detail export
*/

// Try to get animal name, then sample name, then identifier
export function getMaterialName(material: any): string {
    let value = '';

    if (material.Animal && material.Animal.AnimalName) {
        value = material.Animal.AnimalName;
    } else if (material.Sample && material.Sample.SampleName) {
        value = material.Sample.SampleName;
    } else if (material) {
        value = material.Identifier;
    }

    return value;
}

// Equivalent of (animal/sample)Filter pipes in ng1 front-end
export function materialTypeFilter(items: any[], materialType: string): any[] {
    const filtered: any[] = [];
    if (items) {
        for (const item of items) {
            if (item.Material) {
                if (item.Material[materialType]) {
                    filtered.push(item);
                }
            }
        }
    }
    return filtered;
}

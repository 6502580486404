import {
    CellFormatterService,
    FormatterOptions,
    TableOptions
} from '../common/datatable';
import { AuditModifiedFieldComponent } from './audit-modified-fields/audit-modified-field.component';
import { AuditClassFactory } from './audit-class-maker';

export class AuditAnimalCharacteristicsTableOptions {

    options: TableOptions;

    constructor(
        private cellFormatterService: CellFormatterService,
    ) {
        this.options = this.getDefaultOptions();
    }

    getDefaultOptions(): TableOptions {

        return {
            csvFileName: 'animal-characteristic-audit.csv',
            enableDetailColumn: false,
            enableDraggable: false,
            enableSelectable: false,
            enableAuditColumns: false,
            columns: [
                {
                    displayName: 'Type',
                    field: 'UpdateType',
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Modified Fields',
                    field: 'ModifiedFields',
                    sortField: 'ModifiedFields',
                    rendererComponent: AuditModifiedFieldComponent,
                    exportFormatter: this.cellFormatterService.auditModifiedFieldsExportFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Date',
                    field: 'ModifiedDate',
                    formatter: this.cellFormatterService.dateTimeUTCFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Modified By',
                    field: 'ModifiedBy',
                    formatter: this.cellFormatterService.userNameFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Animal ID',
                    field: 'AnimalID',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('AnimalID', 'AnimalID'),
                    cellClass: AuditClassFactory.createCellClass('animalid'),
                },
                {
                    displayName: 'Animal Name',
                    field: 'AnimalName',
                    visible: true,
                    formatter: this.cellFormatterService.createAuditColumnFormatter('AnimalName', 'AnimalName'),
                    cellClass: AuditClassFactory.createCellClass('animalname'),
                },
                {
                    displayName: 'Characteristic Name',
                    field: 'CharacteristicName',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('CharacteristicName', 'CharacteristicName'),
                    cellClass: AuditClassFactory.createCellClass('characteristicname'),
                },
                {
                    displayName: 'Characteristic Value',
                    field: 'CharacteristicValue',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('CharacteristicValue', 'CharacteristicValue'),
                    cellClass: AuditClassFactory.createCellClass('characteristicvalue'),
                },
                {
                    displayName: 'Created By',
                    field: 'CreatedBy',
                    formatter: this.cellFormatterService.userNameFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Created Date',
                    field: 'DateCreated',
                    formatter: this.cellFormatterService.dateTimeUTCFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                }
            ]
        };
    }
}

import { Injectable } from '@angular/core';
import {
    EntityQuery,
} from 'breeze-client';

import { DataManagerService } from '../services/data-manager.service';
import { BaseEntityService } from '../services/base-entity.service';

@Injectable()
export class NamingService extends BaseEntityService {

    readonly ENTITY_TYPE = 'NameFormats';
    readonly ENTITY_NAME = 'NameFormat';

    // Name formats
    readonly ANIMAL_NAME_FORMAT = 'Animal';
    readonly BIRTH_NAME_FORMAT = 'Birth';
    readonly JOB_NAME_FORMAT = 'Job';
    readonly ORDER_NAME_FORMAT = 'Order';
    readonly HOUSING_NAME_FORMAT = 'Housing';
    readonly MATING_NAME_FORMAT = 'Mating';
    readonly SAMPLE_NAME_FORMAT = 'Sample';


    constructor(
        private dataManager: DataManagerService
    ) {
        super();
    }


    getNameFormats(): Promise<any[]> {
        const query = EntityQuery.from(this.ENTITY_TYPE);

        return this.dataManager.returnQueryResults(query);
    }

    // Get name format (assumes FormatName is unique, although not defined so in the db)
    getNameFormat(formatName: string): Promise<any> {
        let query = EntityQuery.from(this.ENTITY_TYPE)
            .where('FormatName', '==', formatName);

        if (formatName.toLowerCase() === 'job') {
            query = query.expand(['cv_JobPrefixField']);
        }

        if (formatName.toLowerCase() === 'order') {
            query = query.expand(['cv_OrderPrefixField']);
        }

        if (formatName.toLowerCase() === 'animal') {
            query = query.expand(['cv_AnimalPrefixField']);
        }

        return this.dataManager.returnSingleQueryResult(query);
    }

    private isNamingActive(formatName: string): Promise<boolean> {
        return this.getNameFormat(formatName).then((nameFormat: any) => {
            return nameFormat.IsActive === true;
        });
    }

    isAnimalNamingActive(): Promise<boolean> {
        return this.isNamingActive(this.ANIMAL_NAME_FORMAT);
    }

    isBirthNamingActive(): Promise<boolean> {
        return this.isNamingActive(this.BIRTH_NAME_FORMAT);
    }

    isJobNamingActive(): Promise<boolean> {
        return this.isNamingActive(this.JOB_NAME_FORMAT);
    }

    isHousingNamingActive(): Promise<boolean> {
        return this.isNamingActive(this.HOUSING_NAME_FORMAT);
    }

    isMatingNamingActive(): Promise<boolean> {
        return this.isNamingActive(this.MATING_NAME_FORMAT);
    }

    isSampleNamingActive(): Promise<boolean> {
        return this.isNamingActive(this.SAMPLE_NAME_FORMAT);
    }

    isOrderNamingActive(): Promise<boolean> {
        return this.isNamingActive(this.ORDER_NAME_FORMAT);
    }
}

import { AsyncSubject, asyncScheduler, Observable, OperatorFunction } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NgZone } from '@angular/core';

/**
 * Add Observable.cacheWithExpiration
 *   Allows you to return cached values.
 *   Cache expires after expirationMs
 * 
 *  Optionally provide an NgZone instance to disable scheduler firing inside the Zone.
 *    This is mostly important for asynchronous unit tests that use fixture.whenStable()
 */
export function cacheWithExpiration(
    expirationMs: number,
    ngZone?: NgZone
): OperatorFunction<any, any> {
    let scheduler: typeof asyncScheduler;
    return (source$: Observable<any>): Observable<any> => {
        let cachedData: AsyncSubject<any>;

        scheduler = scheduler || asyncScheduler;

        return new Observable((observer: any) => {
            if (!cachedData) {
                // The data is not cached.
                // create a subject to hold the result
                cachedData = new AsyncSubject();

                // subscribe to the query
                source$.pipe(catchError((error) => {
                    cachedData = null;
                    throw error;
                })).subscribe(cachedData);

                // when the query completes, start a timer which will expire the cache
                cachedData.subscribe((data) => {
                    if (ngZone) {
                        ngZone.runOutsideAngular(() => {
                            scheduler.schedule(() => {
                                cachedData = null;
                            }, expirationMs);
                        });
                    } else {
                        scheduler.schedule(() => {
                            cachedData = null;
                        }, expirationMs);
                    }
                });
            }

            // subscribe the observer to the cached data
            return cachedData.subscribe(observer);
        });
    };
}

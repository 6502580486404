import { Directive, HostBinding } from '@angular/core';
import { CLIMB_SURFACE_MODE, SurfaceMode } from '@common/tokens';
import { Base, BaseConstructor, Constructor } from './mixin-boilerplate';

export type AddSurfaceModeMixin = Constructor<Record<string, any>>;

export const MixinSurfaceModeClass = mixinSurfaceMode(Base);
/**
 * The mixin checks the CLIMB_SURFACE_MODE token
 * and adds the appropriate CSS class to the component.
 */
export function mixinSurfaceMode<T extends BaseConstructor>(SuperClass: T): AddSurfaceModeMixin & T {
    @Directive()
    class Mode extends SuperClass {
        @HostBinding('class')
        private get surfaceModeClass(): string {
            const surfaceMode: SurfaceMode = this.injector.get(CLIMB_SURFACE_MODE) ?? 'light';
            return `surface-${surfaceMode}`;
        }
    }

    return Mode;
}

import { ParamGroupItem } from './param-group-item';
export class ParameterItem {
    key: any;
    groupSelectionText: string;
    selectedGroup: ParamGroupItem;
    groups: ParamGroupItem[];
    
    /**
     * Enable field initializer
     *   E.g. new ParameterItem({ field1: 'value', ...})
     * @param init
     */
    public constructor(init?: Partial<ParameterItem>) {
        this.groups = [];

        Object.assign(this, init);
    }
}

import { FacetSharedModule } from './../common/facet/facet-shared.module';
import { SearchSharedModule } from './../search/search-shared.module';
import { 
    ViewStudyAuditReportComponentService 
} from './audit/view-study-audit-report-component.service';
import { EnumerationsSharedModule } from './../enumerations/enumerations-shared.module';
import { CharacteristicsSharedModule } from './../characteristics/characteristics-shared.module';
import { StudyDetailComponent } from './study-detail.component';
import { StudyFacetComponent } from './study-facet.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { ClimbCommonModule } from '../common/climb-common.module';
import { ClimbServicesModule } from '../services/climb-services.module';

import { StudiesSharedModule } from './studies-shared.module';

import { AuditSharedModule } from '../audit/audit-shared.module';
import { LinesSharedModule } from '../lines/lines-shared.module';
import { UsersSharedModule } from '../user/users-shared.module';
import { WorkspacesSharedModule } from '../workspaces/workspaces-shared.module';
import { VocabulariesSharedModule } from '../vocabularies/vocabularies-shared.module';

import { StudyAuditReportComponent } from './audit';
import { StudyFilterComponent } from './study-filter.component';
import { StudyPrintPreviewComponent } from './study-print-preview.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgbModule,
        CharacteristicsSharedModule,
        ClimbCommonModule,
        ClimbServicesModule,
        FacetSharedModule,
        StudiesSharedModule,
        AuditSharedModule,
        EnumerationsSharedModule,
        LinesSharedModule,
        SearchSharedModule,
        UsersSharedModule,
        VocabulariesSharedModule,
        WorkspacesSharedModule
    ],
    declarations: [
        StudyAuditReportComponent,
        StudyFilterComponent,
        StudyFacetComponent,
        StudyDetailComponent,
        StudyPrintPreviewComponent
    ],
    exports: [
        StudyAuditReportComponent,
        StudyFilterComponent,
        StudyFacetComponent
    ],
    providers: [
        ViewStudyAuditReportComponentService
    ]
})
export class StudiesModule { }

import type { Birth } from './birth.interface';
import type { TaskInstance } from './task-instance.interface';

export interface TaskBirth {
    Birth: Birth;
    C_Birth_key: number;
    C_TaskBirth_key: number;
    C_TaskInstance_key: number;
    C_Workgroup_key: number;
    CreatedBy: string;
    DateCreated: Date;
    DateModified: Date;
    ModifiedBy: string;
    Sequence: number;
    TaskInstance: TaskInstance;
    Version: number;
}
